import { useEffect, useMemo } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";

type BreakPoint = "lg" | "md" | "sm";

type LayoutItemCommon = {
  // w: number;
  h: number;
  // x: number;
  y: number;
  i: string;
  minW?: number;
  minH?: number;
  maxW?: number;
  maxH?: number;
  moved?: boolean;
  static?: boolean;
  isDraggable?: boolean;
  isResizable?: boolean;
  resizeHandles?: Array<"s" | "w" | "e" | "n" | "sw" | "nw" | "se" | "ne">;
  isBounded?: boolean;
};

type LayoutItemLarge = LayoutItemCommon & {
  w: number;
  x: number;
};

type LayoutItemSmall = LayoutItemCommon & {
  w: 1;
  x: 1;
};

export type Layout = {
  lg: LayoutItemLarge[];
  md: LayoutItemLarge[];
  sm: LayoutItemSmall[];
};

const breakpointsList: BreakPoint[] = ["lg", "sm"];

const breakpoints = { lg: 996, sm: 480 };

export function ResponsiveGrid({
  currentDashboard,
  editMode,
  layout,
  setLayout,
  children,
}: any) {
  const ResponsiveGridLayout = useMemo(() => WidthProvider(Responsive), []);

  useEffect(() => {
    const layoutCopy = structuredClone(layout);
    for (var key in layoutCopy) {
      //@ts-ignore
      for (let item of layoutCopy[key]) {
        item.static = !editMode;
      }
    }

    setLayout(layoutCopy);
  }, [editMode]);

  useEffect(() => {
    if (currentDashboard) {
      const { configuration } = currentDashboard;
      const parsedConfig = JSON.parse(configuration);
      const newLayout: Layout = breakpointsList.reduce(
        (obj: any, bp: BreakPoint) => {
          obj[bp] = parsedConfig.widgets.map((object: any, index: any) => {
            const { dataGrid } = object;
            return {
              ...(dataGrid[bp] ? dataGrid[bp] : dataGrid["lg"]),
              i: Number(index + 1).toString(),
              static: !editMode,
            };
          });
          return obj;
        },
        {}
      );

      setLayout(newLayout);
    }
  }, [currentDashboard, setLayout]);

  if (!currentDashboard || !layout) return null;
  return (
    <ResponsiveGridLayout
      className="layout"
      layouts={layout}
      breakpoints={breakpoints}
      cols={{ lg: 12, sm: 1 /*,  xs: 1, xxs: 1 */ }}
      rowHeight={20}
      onLayoutChange={(newLayout, layouts) => {
        //@ts-ignore
        setLayout(layouts);
      }}
      resizeHandles={["se"]}
      // onBreakpointChange={(newBreakpoint: BreakPoint, newCols: number) => {
      //   setBreakpoint(newBreakpoint);
      // }}
      //   resizeHandle={
      //     <div
      //       style={{
      //         position: "absolute",
      //         right: "5px",
      //         bottom: "5px",
      //         height: "20px",
      //         width: "20px",
      //         borderRight: "solid black 3px",
      //         borderBottom: "solid black 3px",
      //       }}
      //     ></div>
      //   }
    >
      {children}
    </ResponsiveGridLayout>
  );
}
