import {
  Stack,
  TextInput,
  Title,
  FileButton,
  Group,
  ColorSwatch,
  CheckIcon,
  Text,
  Button,
  Image,
  Box,
} from "@mantine/core";
import { useAppearanceSection } from "./useAppearanceSection";

export default function AppearanceSection() {
  const {
    onSystemTitleChange,
    systemTitle,
    logo,
    darkLogo,
    saveFile,
    theme,
    updateColor,
    primaryColor,
  } = useAppearanceSection();

  const swatches = Object.keys(theme.colors).map((color) => (
    <ColorSwatch
      key={color}
      color={theme.colors[color][6]}
      component="button"
      onClick={() => updateColor(color)}
      sx={{ cursor: "pointer" }}
    >
      {color === primaryColor && <CheckIcon width={10} color={"white"} />}
    </ColorSwatch>
  ));

  return (
    <Stack mb={"md"} sx={{ marginLeft: "2em" }}>
      <Title order={3}>System Title</Title>
      <TextInput
        onChange={(e) => onSystemTitleChange({ newValue: e.target.value })}
        value={systemTitle}
      />
      <Title order={3} mt={"md"}>
        Organisation Logos
      </Title>
      <Group>
        <Text>Light Mode Logo</Text>
        <FileButton onChange={(e) => saveFile(e, "_light")}>
          {(props) => <Button {...props}>Upload image</Button>}
        </FileButton>
        <Box
          sx={(theme) => ({
            maxHeight: "50px",
            height: "50px",
            overflow: "hidden",
            backgroundColor: "white",
            paddingLeft: "16px",
            paddingRight: "16px",
            display: "flex",
            alignItems: "center",
            borderRadius: "16px",
          })}
        >
          <Image src={logo} style={{ maxWidth: "200px" }} withPlaceholder />
        </Box>
      </Group>
      <Group>
        <Text>Dark Mode Logo</Text>
        <FileButton onChange={(e) => saveFile(e, "_dark")}>
          {(props) => <Button {...props}>Upload image</Button>}
        </FileButton>
        <Box
          sx={(theme) => ({
            maxHeight: "50px",
            height: "50px",
            overflow: "hidden",
            backgroundColor: theme.colors.dark[7],
            paddingLeft: "16px",
            paddingRight: "16px",
            display: "flex",
            alignItems: "center",
            borderRadius: "16px",
          })}
        >
          <Image src={darkLogo} style={{ maxWidth: "200px" }} withPlaceholder />
        </Box>
      </Group>
      <Title order={3} mt={"md"}>
        Primary Colour
      </Title>
      <Group position="center" spacing="xs">
        {swatches}
      </Group>
    </Stack>
  );
}
