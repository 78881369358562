function filterDataWithFilterObject(
  data: any[],
  filterObject: any,
  dataSchema: any
) {
  const { searchBar, otherFilters } = filterObject;
  const searchTerms = searchBar.toLowerCase().split(" ");

  return data.filter((row: any) => {
    for (const field in otherFilters) {
      if (otherFilters[field] !== "All") {
        const { accessorFunction } = dataSchema[field];
        const value = accessorFunction ? accessorFunction(row) : row[field];
        if (value !== otherFilters[field]) {
          return false;
        }
      }
    }
    if (searchBar) {
      const { id, ...remaining } = row;
      const allValueString = Object.values(remaining).join(" ").toLowerCase();
      return searchTerms.every((term: any) => allValueString.includes(term));
    }
    return true;
  });
}

export default function extractCurrentRecord(
  id: string | undefined,
  data: any[]
) {
  if (!id) return null;
  if (id === "create") return "create";
  const selectedRecordArray = id
    ? data.filter(
        (record: any) => record.id === id || record.integrationSystemId === id
      )
    : null;

  const selectedRecord =
    selectedRecordArray && selectedRecordArray.length > 0
      ? selectedRecordArray[0]
      : null;

  return selectedRecord;
}

export { filterDataWithFilterObject, extractCurrentRecord };
