import { ISchema } from "../SchemaTypes";
import common from "./commonSchema";

const userTypeSchema: ISchema = {
  id: {
    title: "ID",
    field: "id",
    type: "text",
    excludeFromForm: true,
    formOptions: { required: false },
    enableGlobalFilter: false,
  },
  name: {
    title: "Name",
    field: "name",
    formOptions: { required: true },
  },
};

export default userTypeSchema;
