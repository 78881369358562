import {
  NumberInput,
  createStyles,
  Checkbox,
  NativeSelect,
  TextInput,
} from "@mantine/core";
import { IChartProps, IChartType } from "../Types/ChartPropsTypes";
import { useMemo } from "react";

const useStyles = createStyles((theme) => ({
  control: {
    minWidth: 150,
  },
}));

type IControlObject = {
  prop: string;
  chartTypes: IChartType[];
  control: React.ReactElement;
};

export default function ChartBuilderControls({
  chartProps,
  updateChartProps,
}: {
  chartProps: IChartProps;
  updateChartProps: (obj: { [key: string]: any }) => void;
}) {
  const { classes } = useStyles();
  const { chartType } = chartProps;

  const controls: IControlObject[] = useMemo(
    () => [
      // {
      //   prop: "dateField",
      //   chartTypes: [
      //     "Bar Chart",
      //     "Column Chart",
      //     "Pie Chart",
      //     "Area Chart",
      //     "Line Chart",
      //     "Treemap",
      //   ],
      //   control: (
      //     <TextInput
      //       label="Date Field"
      //       value={chartProps.dateField}
      //       onChange={(e) => updateChartProps({ dateField: e.target.value })}
      //       className={classes.control}
      //     />
      //   ),
      // },
      {
        prop: "disableChartInteractions",
        chartTypes: [
          "Bar Chart",
          "Column Chart",
          "Pie Chart",
          "Area Chart",
          "Line Chart",
          "Radar Chart",
          "Treemap",
          "Box Plot",
        ],
        control: (
          <Checkbox
            label="Disable Chart Interactions"
            checked={chartProps.disableChartInteractions}
            onChange={(e) =>
              updateChartProps({ disableChartInteractions: e.target.checked })
            }
            className={classes.control}
          />
        ),
      },
      {
        prop: "disableTooltip",
        chartTypes: [
          "Bar Chart",
          "Column Chart",
          "Pie Chart",
          "Area Chart",
          "Line Chart",
          "Treemap",
          "Radar Chart",
          "Box Plot",
        ],
        control: (
          <Checkbox
            label="Disable Tooltip"
            checked={chartProps.disableTooltip}
            onChange={(e) =>
              updateChartProps({ disableTooltip: e.target.checked })
            }
            className={classes.control}
          />
        ),
      },
      {
        prop: "disableAnimations",
        chartTypes: [
          "Bar Chart",
          "Column Chart",
          "Pie Chart",
          "Area Chart",
          "Line Chart",
          "Treemap",
          "Radar Chart",
          "Box Plot",
        ],
        control: (
          <Checkbox
            label="Disable Animations"
            checked={chartProps.disableAnimations}
            onChange={(e) =>
              updateChartProps({ disableAnimations: e.target.checked })
            }
            className={classes.control}
          />
        ),
      },
      {
        prop: "limitRows",
        chartTypes: [
          "Bar Chart",
          "Column Chart",
          "Pie Chart",
          "Area Chart",
          "Line Chart",
          "Treemap",
          "Box Plot",
        ],
        control: (
          <NumberInput
            label={"Limit Rows"}
            value={chartProps.limitRows}
            onChange={(value) => updateChartProps({ limitRows: value })}
            className={classes.control}
          />
        ),
      },
      {
        prop: "limitRowsEnd",
        chartTypes: [
          "Bar Chart",
          "Column Chart",
          "Pie Chart",
          "Area Chart",
          "Line Chart",
          "Treemap",
          "Box Plot",
        ],
        control: (
          <NumberInput
            label={"Limit Rows (End)"}
            value={chartProps.limitRowsEnd}
            onChange={(value) => updateChartProps({ limitRowsEnd: value })}
            className={classes.control}
          />
        ),
      },
      {
        prop: "yAxisWidth",
        chartTypes: [
          "Bar Chart",
          "Column Chart",
          "Scatter Chart",
          "Area Chart",
          "Line Chart",
          "Box Plot",
        ],
        control: (
          <NumberInput
            label={"Y-Axis Width"}
            value={chartProps.yAxisWidth}
            onChange={(value) => updateChartProps({ yAxisWidth: value })}
            className={classes.control}
          />
        ),
      },
      {
        prop: "xAxisHeight",
        chartTypes: [
          "Bar Chart",
          "Column Chart",
          "Scatter Chart",
          "Area Chart",
          "Line Chart",
          "Box Plot",
        ],
        control: (
          <NumberInput
            label={"X-Axis Height"}
            value={chartProps.xAxisHeight}
            onChange={(value) => updateChartProps({ xAxisHeight: value })}
            className={classes.control}
          />
        ),
      },
      {
        prop: "yAxisFontSize",
        chartTypes: [
          "Bar Chart",
          "Column Chart",
          "Scatter Chart",
          "Area Chart",
          "Line Chart",
          "Box Plot",
        ],
        control: (
          <NumberInput
            label={"Y-Axis Font Size"}
            step={0.1}
            precision={1}
            value={chartProps.yAxisFontSize}
            onChange={(value) => updateChartProps({ yAxisFontSize: value })}
            className={classes.control}
          />
        ),
      },
      {
        prop: "includeXAxis",
        chartTypes: [
          "Bar Chart",
          "Column Chart",
          "Scatter Chart",
          "Area Chart",
          "Line Chart",
          "Box Plot",
        ],
        control: (
          <Checkbox
            label="Include X-axis"
            checked={chartProps.includeXAxis}
            onChange={(e) =>
              updateChartProps({ includeXAxis: e.target.checked })
            }
            className={classes.control}
          />
        ),
      },
      {
        prop: "includeYAxis",
        chartTypes: [
          "Bar Chart",
          "Column Chart",
          "Scatter Chart",
          "Area Chart",
          "Line Chart",
          "Box Plot",
        ],
        control: (
          <Checkbox
            label="Include Y-axis"
            checked={chartProps.includeYAxis}
            onChange={(e) =>
              updateChartProps({ includeYAxis: e.target.checked })
            }
            className={classes.control}
          />
        ),
      },
      {
        prop: "xAxisFontSize",
        chartTypes: [
          "Bar Chart",
          "Column Chart",
          "Scatter Chart",
          "Area Chart",
          "Line Chart",
          "Box Plot",
        ],
        control: (
          <NumberInput
            label={"X-Axis Font Size"}
            step={0.1}
            precision={1}
            value={chartProps.xAxisFontSize}
            onChange={(value) => updateChartProps({ xAxisFontSize: value })}
            className={classes.control}
          />
        ),
      },
      {
        prop: "yAxisMin",
        chartTypes: [
          "Bar Chart",
          "Column Chart",
          "Scatter Chart",
          "Area Chart",
          "Line Chart",
        ],
        control: (
          <NumberInput
            label={"Y-Axis Min"}
            value={chartProps.yAxisMin}
            onChange={(value) => updateChartProps({ yAxisMin: value })}
            className={classes.control}
          />
        ),
      },
      {
        prop: "yAxisMax",
        chartTypes: [
          "Bar Chart",
          "Column Chart",
          "Scatter Chart",
          "Area Chart",
          "Line Chart",
        ],
        control: (
          <NumberInput
            label={"Y-Axis Max"}
            value={chartProps.yAxisMax}
            onChange={(value) => updateChartProps({ yAxisMax: value })}
            className={classes.control}
          />
        ),
      },
      {
        prop: "grid",
        chartTypes: [
          "Bar Chart",
          "Column Chart",
          "Scatter Chart",
          "Area Chart",
          "Line Chart",
          "Box Plot",
        ],
        control: (
          <Checkbox
            label="Show Grid"
            checked={chartProps.grid}
            onChange={(e) => updateChartProps({ grid: e.target.checked })}
            className={classes.control}
          />
        ),
      },
      {
        prop: "dataLabels",
        chartTypes: [
          "Bar Chart",
          "Column Chart",
          "Map",
          "Pie Chart",
          "Scatter Chart",
          "Area Chart",
          "Line Chart",
          "Treemap",
        ],
        control: (
          <Checkbox
            label="Include data labels"
            checked={chartProps.dataLabels}
            onChange={(e) => updateChartProps({ dataLabels: e.target.checked })}
            className={classes.control}
          />
        ),
      },
      {
        prop: "dataLabelFontSize",
        chartTypes: [
          "Bar Chart",
          "Column Chart",
          "Map",
          "Pie Chart",
          "Scatter Chart",
          "Area Chart",
          "Line Chart",
          "Treemap",
        ],
        control: (
          <NumberInput
            label={"Data Label Font Size"}
            step={0.1}
            precision={1}
            value={chartProps.dataLabelFontSize}
            onChange={(value) => updateChartProps({ dataLabelFontSize: value })}
            className={classes.control}
          />
        ),
      },
      {
        prop: "legend",
        chartTypes: [
          "Bar Chart",
          "Column Chart",
          "Map",
          "Pie Chart",
          "Scatter Chart",
          "Radar Chart",
          "Area Chart",
          "Line Chart",
        ],
        control: (
          <Checkbox
            label="Legend"
            checked={chartProps.legend}
            onChange={(e) => updateChartProps({ legend: e.target.checked })}
            className={classes.control}
          />
        ),
      },
      {
        prop: "formatFunction",
        chartTypes: [
          "Bar Chart",
          "Column Chart",
          "Map",
          "Pie Chart",
          "Scatter Chart",
          "Area Chart",
          "Line Chart",
          "Scorecard",
          "Pivot Table",
          "Radar Chart",
          "Treemap",
          "Box Plot",
        ],
        control: (
          <NativeSelect
            label={"Format Function"}
            value={chartProps.formatFunction}
            data={[
              { label: "None", value: "None" },
              { label: "Format Currency ($)", value: "Format Currency ($)" },
              { label: "Format Currency (£)", value: "Format Currency (£)" },
              { label: "Format %", value: "Format %" },
            ]}
            onChange={(e) =>
              updateChartProps({ formatFunction: e.target.value })
            }
            className={classes.control}
          />
        ),
      },
      {
        prop: "condensedNumbers",
        chartTypes: [
          "Bar Chart",
          "Column Chart",
          "Map",
          "Pie Chart",
          "Scatter Chart",
          "Scorecard",
          "Area Chart",
          "Line Chart",
          "Pivot Table",
          "Radar Chart",
          "Treemap",
          "Box Plot",
        ],
        control: (
          <Checkbox
            label="Condensed Numbers"
            checked={chartProps.condensedNumbers}
            onChange={(e) =>
              updateChartProps({ condensedNumbers: e.target.checked })
            }
            className={classes.control}
          />
        ),
      },
      {
        prop: "decimals",
        chartTypes: [
          "Bar Chart",
          "Column Chart",
          "Map",
          "Pie Chart",
          "Scatter Chart",
          "Scorecard",
          "Area Chart",
          "Line Chart",
          "Pivot Table",
          "Radar Chart",
          "Treemap",
          "Box Plot",
        ],
        control: (
          <NumberInput
            label={"Decimals"}
            step={1}
            value={chartProps.decimals}
            onChange={(value) => updateChartProps({ decimals: value })}
            className={classes.control}
          />
        ),
      },
      {
        prop: "titleOrder",
        chartTypes: ["Title"],
        control: (
          <NativeSelect
            label={"Title Order"}
            data={["1", "2", "3", "4", "5"]}
            onChange={(e) => {
              updateChartProps({ titleOrder: Number(e.target.value) });
            }}
            value={chartProps.titleOrder}
          />
        ),
      },

      {
        prop: "positive",
        chartTypes: ["Scorecard"],
        control: (
          <NativeSelect
            label={"Positive Direction"}
            data={["Increase", "Decrease"]}
            onChange={(e) => {
              updateChartProps({ positive: e.target.value });
            }}
            value={chartProps.positive}
          />
        ),
      },

      {
        prop: "innerRadius",
        chartTypes: ["Pie Chart"],
        control: (
          <NumberInput
            label={"Inner Radius"}
            step={5}
            // precision={2}
            max={100}
            min={0}
            value={chartProps.innerRadius}
            onChange={(value) => updateChartProps({ innerRadius: value })}
            className={classes.control}
          />
        ),
      },
      {
        prop: "labelPosition",
        chartTypes: ["Pie Chart"],
        control: (
          <NativeSelect
            label={"Label Position"}
            data={["Inside", "Outside"]}
            onChange={(e) => {
              updateChartProps({ labelPosition: e.target.value });
            }}
            value={chartProps.labelPosition}
            className={classes.control}
          />
        ),
      },
      {
        prop: "labelType",
        chartTypes: ["Pie Chart"],
        control: (
          <NativeSelect
            label={"Label Type"}
            data={["Value", "% of Total"]}
            onChange={(e) => {
              updateChartProps({ labelType: e.target.value });
            }}
            value={chartProps.labelType}
            className={classes.control}
          />
        ),
      },
      {
        prop: "percentageChange",
        chartTypes: ["Scorecard"],
        control: (
          <Checkbox
            label="Percentage Change"
            checked={chartProps.percentageChange}
            onChange={(e) =>
              updateChartProps({ percentageChange: e.target.checked })
            }
            className={classes.control}
          />
        ),
      },
      {
        prop: "lineOfBestFit",
        chartTypes: ["Scatter Chart"],
        control: (
          <Checkbox
            label="Line of best fit"
            checked={chartProps.lineOfBestFit}
            onChange={(e) =>
              updateChartProps({ lineOfBestFit: e.target.checked })
            }
            className={classes.control}
          />
        ),
      },
      {
        prop: "mapScale",
        chartTypes: ["Map"],
        control: (
          <NumberInput
            label={"Map Scale"}
            step={0.1}
            precision={1}
            max={10}
            min={1}
            value={chartProps.mapScale}
            onChange={(value) => updateChartProps({ mapScale: value })}
            className={classes.control}
          />
        ),
      },
      {
        prop: "width",
        chartTypes: ["Map"],
        control: (
          <NumberInput
            label={"Width"}
            step={1}
            value={chartProps.width}
            onChange={(value) => updateChartProps({ width: value })}
            className={classes.control}
          />
        ),
      },
      {
        prop: "height",
        chartTypes: ["Map"],
        control: (
          <NumberInput
            label={"Height"}
            step={1}
            value={chartProps.height}
            onChange={(value) => updateChartProps({ height: value })}
            className={classes.control}
          />
        ),
      },
      {
        prop: "longitude",
        chartTypes: ["Map"],
        control: (
          <NumberInput
            label={"Y Translate"}
            step={1}
            value={chartProps.longitude}
            onChange={(value) => updateChartProps({ longitude: value })}
            className={classes.control}
          />
        ),
      },
      {
        prop: "latitude",
        chartTypes: ["Map"],
        control: (
          <NumberInput
            label={"X Translate"}
            step={1}
            value={chartProps.latitude}
            onChange={(value) => updateChartProps({ latitude: value })}
            className={classes.control}
          />
        ),
      },
      {
        prop: "enableZoom",
        chartTypes: ["Map"],
        control: (
          <Checkbox
            label="Enable Zoom"
            checked={chartProps.enableZoom}
            onChange={(e) => updateChartProps({ enableZoom: e.target.checked })}
            className={classes.control}
          />
        ),
      },
    ],
    [updateChartProps, chartProps]
  );

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        gap: 10,
      }}
    >
      {controls
        .filter((controlObject) => {
          return controlObject.chartTypes.includes(chartType);
        })
        .map((controlObject) => {
          return <div key={controlObject.prop}>{controlObject.control}</div>;
        })}
    </div>
  );
}
