//@ts-nocheck

import {
  Box,
  Button,
  Divider,
  Flex,
  Group,
  Stack,
  Switch,
  Text,
  Title,
  Badge,
  useMantineTheme,
  Space,
} from "@mantine/core";
import { useState } from "react";

export function PricingCards() {
  const theme = useMantineTheme();
  const [monthly, setMonthly] = useState(false);

  const handleChange = () => {
    setMonthly(!monthly);
  };

  return (
    <>
      <Group sx={{ zIndex: 10 }} p="xl">
        <Stack spacing={40}>
          {/** header section */}
          <Flex direction="column" gap={10} align="center" justify="start">
            <Title
              order={2}
              color={
                theme.colorScheme === "dark" ? "white" : "hsl(233, 13%, 49%)"
              }
            >
              Our Pricing
            </Title>

            <Text
              ta={"center"}
              c={"dimmed"}
              fz={20}
              fw={700}
              sx={{
                "@media (max-width: 765px)": {
                  marginLeft: "1rem", // Reduce left and right margins for very small screens
                  marginRight: "1rem",
                  maxWidth: "100%", // Ensure it takes full width on small screens
                },
                "@media (min-width: 765px) and (max-width: 1200px)": {
                  marginLeft: "2rem",
                  marginRight: "2rem",
                  maxWidth: "50%", // Slightly narrower for readability
                },
                "@media (min-width: 1200px)": {
                  marginLeft: "auto",
                  marginRight: "auto",
                  maxWidth: "1000px", // Fixed width centered for larger screens
                },
              }}
            >
              As the managed service is tailored to your specific business
              needs, a separate project scope and delivery service is required.
              This will take the form of a consultation with our business
              experts.
            </Text>
            <Space w={"xl"} />

            <Text
              ta={"center"}
              c={"dimmed"}
              fz={20}
              mb={"md"}
              sx={{
                "@media (max-width: 765px)": {
                  marginLeft: "1rem", // Reduce left and right margins for very small screens
                  marginRight: "1rem",
                  maxWidth: "100%", // Ensure it takes full width on small screens
                },
                "@media (min-width: 765px) and (max-width: 1200px)": {
                  marginLeft: "2rem",
                  marginRight: "2rem",
                  maxWidth: "50%", // Slightly narrower for readability
                },
                "@media (min-width: 1200px)": {
                  marginLeft: "auto",
                  marginRight: "auto",
                  maxWidth: "1000px", // Fixed width centered for larger screens
                },
              }}
            >
              The project delivery will be provided as a no obligation quotation
              for the completion of your requirements of your very own
              Smart/sync business suite. Once you are happy with the completed
              set up and configuration, the managed service will start with your
              selected package.
            </Text>

            <Box
              mb={"md"}
              sx={{
                fontWeight: 700,
                color:
                  theme.colorScheme === "dark"
                    ? theme.colors.dark[1]
                    : "hsl(234, 14%, 74%)",
                display: "flex",
                alignItems: "center",
                gap: 19,
              }}
            >
              <Text fz={"sm"}>12 Month Plan</Text>

              <Switch
                styles={{
                  track: {
                    backgroundColor: theme.colors["lime"][7],
                  },
                }}
                color="lime"
                onChange={handleChange}
              />
              <Text fz={"sm"}>6 Month Plan</Text>
            </Box>
          </Flex>
          {/** cards section */}
          <Group>
            <Flex
              ml={"auto"}
              mr={"auto"}
              align={"center"}
              direction={{ base: "column", sm: "row" }}
              color={"hsl(232, 13%, 33%)"}
              gap={{ base: "1.5rem" }}
            >
              <Box
                mb={"md"}
                sx={{
                  boxShadow: "0px 30px 50px -7px rgba(0,0,0,0.1)",
                  height: "25rem",
                  width: "20rem",
                  paddingInline: "1.5rem",
                  backgroundColor:
                    theme.colorScheme === "dark"
                      ? theme.colors.dark[5]
                      : "white",
                  borderRadius: "0.7rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                  overflow: "hidden",

                  "@media (max-width: 755px)": {
                    width: "19rem",
                    borderRadius: "0.7rem",
                  },
                  "@media (min-width: 756px) and (max-width: 820px)": {
                    width: "15rem",
                    borderRadius: "0.7rem",
                  },
                }}
              >
                <Box
                  mb={"md"}
                  sx={{
                    position: "absolute",
                    width: "20rem",
                    height: "3rem",
                    top: "-0.1rem",
                    background:
                      "linear-gradient(to bottom, #c76c0d, transparent)",
                    borderTopLeftRadius: "0.7rem",
                    borderTopRightRadius: "0.7rem",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Stack w={"100%"} align={"center"} spacing={20}>
                    <Text
                      sx={{
                        fontWeight: 700,
                        color: "white",
                        textAlign: "center",
                      }}
                      fz={"md"}
                    >
                      Bronze
                    </Text>
                  </Stack>
                </Box>
                <Stack w={"100%"} align={"center"} spacing={20}>
                  <Title
                    order={2}
                    align="center"
                    sx={{
                      color:
                        theme.colorScheme === "dark"
                          ? "white"
                          : "hsl(232, 13%, 33%)",
                      fontSize: 25,
                      display: "flex",
                      gap: 5,
                    }}
                  >
                    {/* <Text fz={"2rem"}>£</Text> */}
                    {/* {monthly ? "19.99" : "199.99"} */}
                    Included In Active Projects
                  </Title>
                  <Stack
                    w={"100%"}
                    align="center"
                    spacing={10}
                    sx={{
                      color:
                        theme.colorScheme === "light" && "hsl(233, 13%, 49%)",
                    }}
                  >
                    <Divider
                      sx={{
                        width: "100%",
                        borderColor: theme.colorScheme === "dark" && "gray",
                        opacity: theme.colorScheme === "dark" && 0.7,
                      }}
                    />
                    <Text fz={"sm"} fw={600}>
                      For Active Clients
                    </Text>
                    <Divider
                      sx={{
                        width: "100%",
                        borderColor: theme.colorScheme === "dark" && "gray",
                        opacity: theme.colorScheme === "dark" && 0.7,
                      }}
                    />
                    <Text fz={"sm"} fw={600}>
                      9-5 Support
                    </Text>
                    <Divider
                      sx={{
                        width: "100%",
                        borderColor: theme.colorScheme === "dark" && "gray",
                        opacity: theme.colorScheme === "dark" && 0.7,
                      }}
                    />
                    <Text fz={"sm"} fw={600}>
                      Active Projects Only
                    </Text>
                    <Divider
                      sx={{
                        width: "100%",
                        borderColor: theme.colorScheme === "dark" && "gray",
                        opacity: theme.colorScheme === "dark" && 0.7,
                      }}
                    />
                    <Text fz={"sm"} fw={600}>
                      No Service Credits
                    </Text>
                    <Divider
                      sx={{
                        width: "100%",
                        borderColor: theme.colorScheme === "dark" && "gray",
                        opacity: theme.colorScheme === "dark" && 0.7,
                      }}
                    />
                  </Stack>
                  {/* <Button
                    variant="gradient"
                    gradient={{
                      from: theme.colors["lime"][8],
                      to: theme.colors["lime"][9],
                    }}
                    w="100%"
                  >
                    LEARN MORE
                  </Button> */}
                </Stack>
              </Box>
              <Box
                mb={"md"}
                sx={{
                  boxShadow: "0px 30px 50px -7px rgba(0,0,0,0.1)",
                  height: "25rem",
                  width: "20rem",
                  paddingInline: "1.5rem",
                  backgroundColor:
                    theme.colorScheme === "dark"
                      ? theme.colors.dark[5]
                      : "white",
                  borderRadius: "0.7rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                  overflow: "hidden",

                  "@media (max-width: 755px)": {
                    width: "19rem",
                    borderRadius: "0.7rem",
                  },
                  "@media (min-width: 756px) and (max-width: 820px)": {
                    width: "15rem",
                    borderRadius: "0.7rem",
                  },
                }}
              >
                <Box
                  mb={"md"}
                  sx={{
                    position: "absolute",
                    width: "20rem",
                    height: "3rem",
                    top: "-0.1rem",
                    background:
                      "linear-gradient(to bottom, #9a9a9a, transparent)",
                    borderTopLeftRadius: "0.7rem",
                    borderTopRightRadius: "0.7rem",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Stack w={"100%"} align={"center"} spacing={20}>
                    <Text
                      sx={{
                        fontWeight: 700,
                        color: "white",
                      }}
                      fz={"md"}
                    >
                      Silver
                    </Text>
                  </Stack>
                </Box>
                <Stack w={"100%"} align={"center"} spacing={20}>
                  <Title
                    order={2}
                    sx={{
                      color:
                        theme.colorScheme === "dark"
                          ? "white"
                          : "hsl(232, 13%, 33%)",
                      fontSize: 50,
                      display: "flex",
                      alignItems: "center",
                      gap: 5,
                    }}
                  >
                    <Text fz={"2rem"}>£</Text>
                    {monthly ? "1,500" : "1,200"}
                    <Text fz={"1rem"}>/ month</Text>
                  </Title>
                  <Stack
                    w={"100%"}
                    align="center"
                    spacing={10}
                    sx={{
                      color:
                        theme.colorScheme === "light" && "hsl(233, 13%, 49%)",
                    }}
                  >
                    <Divider
                      sx={{
                        width: "100%",
                        borderColor: theme.colorScheme === "dark" && "gray",
                        opacity: theme.colorScheme === "dark" && 0.7,
                      }}
                    />
                    <Text fz={"sm"} fw={600}>
                      Up To 5 Users
                    </Text>
                    <Divider
                      sx={{
                        width: "100%",
                        borderColor: theme.colorScheme === "dark" && "gray",
                        opacity: theme.colorScheme === "dark" && 0.7,
                      }}
                    />
                    <Text fz={"sm"} fw={600}>
                      9-5 Support with 24 Hour SLA
                    </Text>
                    <Divider
                      sx={{
                        width: "100%",
                        borderColor: theme.colorScheme === "dark" && "gray",
                        opacity: theme.colorScheme === "dark" && 0.7,
                      }}
                    />
                    <Text fz={"sm"} fw={600}>
                      Integration with SSO/MFA
                    </Text>
                    <Divider
                      sx={{
                        width: "100%",
                        borderColor: theme.colorScheme === "dark" && "gray",
                        opacity: theme.colorScheme === "dark" && 0.7,
                      }}
                    />
                    <Text fz={"sm"} fw={600}>
                      2 Service credits
                    </Text>
                    <Divider
                      sx={{
                        width: "100%",
                        borderColor: theme.colorScheme === "dark" && "gray",
                        opacity: theme.colorScheme === "dark" && 0.7,
                      }}
                    />
                  </Stack>
                  {/* <Button
                    variant="gradient"
                    gradient={{
                      from: theme.colors["lime"][8],
                      to: theme.colors["lime"][9],
                    }}
                    w="100%"
                  >
                    LEARN MORE
                  </Button> */}
                </Stack>
              </Box>
              <Box
                mb={"md"}
                sx={{
                  boxShadow: "0px 30px 50px -7px rgba(0,0,0,0.1)",
                  overflow: "hidden",
                  position: "relative",
                  height: "28rem",
                  width: "23rem",
                  paddingInline: "1.5rem",
                  backgroundColor:
                    theme.colorScheme === "dark"
                      ? theme.colors.dark[5]
                      : "white",
                  borderRadius: "0.7rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: `0.3rem solid ${theme.colors["lime"][8]}`,

                  "@media (min-width: 756px) and (max-width: 820px)": {
                    width: "15rem",
                    borderRadius: "0.7rem",
                  },
                }}
              >
                <Box
                  mb={"md"}
                  sx={{
                    position: "absolute",
                    width: "23rem",
                    height: "4.5rem",
                    top: "-0.1rem",
                    background:
                      "linear-gradient(to bottom, #ffb01b, transparent)",
                    borderTopLeftRadius: "0.7rem",
                    borderTopRightRadius: "0.7rem",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Badge
                    variant="filled"
                    sx={{
                      position: "absolute",
                      top: "0.5rem",
                      left: "0.5rem",
                      color:
                        theme.colorScheme === "dark"
                          ? "white"
                          : "hsl(232, 13%, 33%)",
                    }}
                  >
                    Best Value
                  </Badge>
                  <Text
                    sx={{
                      fontWeight: 700,
                      color: "white",
                      textAlign: "center",
                    }}
                    fz={"md"}
                  >
                    Gold
                  </Text>
                </Box>
                <Stack w={"100%"} align={"center"} spacing={20}>
                  <Title
                    order={2}
                    sx={{
                      color:
                        theme.colorScheme === "dark"
                          ? "white"
                          : "hsl(232, 13%, 33%)",
                      fontSize: 50,
                      display: "flex",
                      alignItems: "center",
                      gap: 5,
                    }}
                  >
                    <Text fz={"2rem"}>£</Text>
                    {monthly ? "1,800" : "1,500"}
                    <Text fz={"1rem"}>/ month</Text>
                  </Title>
                  <Stack w={"100%"} align="center" spacing={10}>
                    <Divider
                      sx={{
                        width: "100%",
                        borderColor: theme.colorScheme === "dark" && "white",
                        opacity: theme.colorScheme === "dark" && 0.6,
                      }}
                    />
                    <Text fz={"sm"} fw={600}>
                      Up To 20 Users
                    </Text>
                    <Divider
                      sx={{
                        width: "100%",
                        borderColor: theme.colorScheme === "dark" && "white",
                        opacity: theme.colorScheme === "dark" && 0.6,
                      }}
                    />
                    <Text fz={"sm"} fw={600}>
                      9-5 Support With 12 Hour SLA
                    </Text>
                    <Divider
                      sx={{
                        width: "100%",
                        borderColor: theme.colorScheme === "dark" && "white",
                        opacity: theme.colorScheme === "dark" && 0.6,
                      }}
                    />
                    <Text fz={"sm"} fw={600}>
                      Up to 3 3rd Party Client Partner Access
                    </Text>
                    <Divider
                      sx={{
                        width: "100%",
                        borderColor: theme.colorScheme === "dark" && "white",
                        opacity: theme.colorScheme === "dark" && 0.6,
                      }}
                    />
                    <Text fz={"sm"} fw={600}>
                      4 Service Credits
                    </Text>
                    <Divider
                      sx={{
                        width: "100%",
                        borderColor: theme.colorScheme === "dark" && "white",
                        opacity: theme.colorScheme === "dark" && 0.6,
                      }}
                    />
                  </Stack>
                  {/* <Button
                    variant="gradient"
                    gradient={{
                      from: theme.colors["lime"][8],
                      to: theme.colors["lime"][9],
                    }}
                    w="100%"
                  >
                    LEARN MORE
                  </Button> */}
                </Stack>
              </Box>
              <Box
                mb={"md"}
                sx={{
                  boxShadow: "0px 30px 50px -7px rgba(0,0,0,0.1)",
                  height: "25rem",
                  width: "20rem",
                  paddingInline: "1.5rem",
                  backgroundColor:
                    theme.colorScheme === "dark"
                      ? theme.colors.dark[5]
                      : "white",
                  borderRadius: "0.7rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                  overflow: "hidden",

                  "@media (max-width: 755px)": {
                    width: "19rem",
                    borderRadius: "0.7rem",
                  },
                  "@media (min-width: 756px) and (max-width: 820px)": {
                    width: "15rem",
                    borderRadius: "0.7rem",
                  },
                }}
              >
                <Box
                  mb={"md"}
                  sx={{
                    position: "absolute",
                    width: "20rem",
                    height: "3rem",
                    top: "-0.1rem",
                    background:
                      "linear-gradient(to bottom, #656a7e, transparent)",
                    borderTopLeftRadius: "0.7rem",
                    borderTopRightRadius: "0.7rem",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Stack w={"100%"} align={"center"} spacing={20}>
                    <Text
                      sx={{
                        fontWeight: 700,
                        color: "white",
                      }}
                      fz={"md"}
                    >
                      Platinum
                    </Text>
                  </Stack>
                </Box>
                <Stack w={"100%"} align={"center"} spacing={20}>
                  <Title
                    order={2}
                    sx={{
                      color:
                        theme.colorScheme === "dark"
                          ? "white"
                          : "hsl(232, 13%, 33%)",
                      fontSize: 50,
                      display: "flex",
                      alignItems: "center",
                      gap: 5,
                    }}
                  >
                    {/* <Text fz={"2rem"}>£</Text> */}
                    {/* {monthly ? "39.99" : "399.99"} */}
                    <Text fz={"2rem"}>Contact Us</Text>
                  </Title>
                  <Stack
                    w={"100%"}
                    align="center"
                    spacing={10}
                    sx={{
                      color:
                        theme.colorScheme === "light" && "hsl(233, 13%, 49%)",
                    }}
                  >
                    <Divider
                      sx={{
                        width: "100%",
                        borderColor: theme.colorScheme === "dark" && "gray",
                        opacity: theme.colorScheme === "dark" && 0.7,
                      }}
                    />
                    <Text fz={"sm"} fw={600}>
                      Unlimited Users
                    </Text>
                    <Divider
                      sx={{
                        width: "100%",
                        borderColor: theme.colorScheme === "dark" && "gray",
                        opacity: theme.colorScheme === "dark" && 0.7,
                      }}
                    />
                    <Text fz={"sm"} fw={600}>
                      9-5 Support With 8 Hour SLA
                    </Text>
                    <Divider
                      sx={{
                        width: "100%",
                        borderColor: theme.colorScheme === "dark" && "gray",
                        opacity: theme.colorScheme === "dark" && 0.7,
                      }}
                    />
                    <Text fz={"sm"} fw={600} align="center">
                      Unlimited 3rd Party Client Partner Access
                    </Text>
                    <Divider
                      sx={{
                        width: "100%",
                        borderColor: theme.colorScheme === "dark" && "gray",
                        opacity: theme.colorScheme === "dark" && 0.7,
                      }}
                    />
                    <Text fz={"sm"} fw={600}>
                      8 Service Credits
                    </Text>
                    <Divider
                      sx={{
                        width: "100%",
                        borderColor: theme.colorScheme === "dark" && "gray",
                        opacity: theme.colorScheme === "dark" && 0.7,
                      }}
                    />
                  </Stack>
                  {/* <Button
                    variant="gradient"
                    gradient={{
                      from: theme.colors["lime"][8],
                      to: theme.colors["lime"][9],
                    }}
                    w="100%"
                  >
                    LEARN MORE
                  </Button> */}
                </Stack>
              </Box>
            </Flex>
          </Group>
        </Stack>
      </Group>
    </>
  );
}
