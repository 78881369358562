import {
  SimpleGrid,
  Card,
  Image,
  Text,
  Container,
  AspectRatio,
} from "@mantine/core";
import useStyles from "./Blog.styles";

const mockdata = [
  {
    title: "Supercharge your project and business data with smart/sync",
    image:
      "https://media.licdn.com/dms/image/v2/D4E12AQEu5GBItf2X1g/article-cover_image-shrink_720_1280/article-cover_image-shrink_720_1280/0/1724425980688?e=1733961600&v=beta&t=WWAeElXMvygzJuUIHLH3QwebmezuX9XtndC0_GjzDAk",
    date: "August 18, 2022",
    link: "https://www.linkedin.com/feed/update/urn:li:activity:7216395212808404993",
  },
  {
    title:
      "Streamline your business operations: how smart/sync transforms reporting and decision making",
    image:
      "https://media.licdn.com/dms/image/v2/D4E12AQFx4dfQdAIa8w/article-cover_image-shrink_423_752/article-cover_image-shrink_423_752/0/1720451610093?e=1733961600&v=beta&t=axfaapW7E1cn7ocEWVeSXjwNEjj8gARRdvSa8pC_Yt4",
    date: "August 27, 2022",
    link: "https://www.linkedin.com/pulse/streamline-your-business-operations-how-smartsync-transforms-lfcge/?trackingId=AC0BElrkJx1rRGelJAHitg%3D%3D",
  },
];

export function Blog() {
  const { classes } = useStyles();
  const cards = mockdata.map((article) => (
    <Card
      key={article.title}
      p="md"
      radius="md"
      component="a"
      href="#"
      className={classes.card}
      onClick={() => {
        window.location.href = article.link;
      }}
    >
      <AspectRatio ratio={1920 / 1080}>
        <Image src={article.image} />
      </AspectRatio>
      {/* <Text c="dimmed" size="xs" tt="uppercase" fw={700} mt="md">
        {article.date}
      </Text> */}
      <Text className={classes.title} mt={5}>
        {article.title}
      </Text>
    </Card>
  ));

  return (
    <Container py="xl">
      <SimpleGrid
        cols={2}
        breakpoints={[
          { maxWidth: "md", cols: 2, spacing: "md" },
          { maxWidth: "sm", cols: 1, spacing: "sm" },
        ]}
      >
        {cards}
      </SimpleGrid>
    </Container>
  );
}
