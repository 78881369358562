import { useContext, useMemo } from "react";
import {
  Group,
  Text,
  Paper,
  createStyles,
  Progress,
  Skeleton,
} from "@mantine/core";
// import MetricContainer from "../common/MetricContainer";
import AppContext from "../../context/AppContext";
import { nFormatter } from "../common/functions/formatFunctions";
import Placeholder from "../common/Placeholder";
import useLoadingStatus from "../../hooks/useLoadingStatus";
import WidgetFrame from "../common/WidgetFrame";
import { createFilterFunction } from "../common/functions/createFilterFunction";
import { useElementSize } from "@mantine/hooks";
import { calculatePercentageOfDurationPassed } from "../common/functions/dateFunctions";
import { IconCalendar } from "@tabler/icons-react";

const useStyles = createStyles((theme) => ({
  root: {
    //padding: theme.spacing.xl * 1.5,
  },

  value: {
    fontSize: 24,
    fontWeight: 700,
    lineHeight: 1,
  },

  diff: {
    lineHeight: 1,
    display: "flex",
    alignItems: "center",
  },

  icon: {
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[3]
        : theme.colors.gray[4],
  },

  title: {
    fontWeight: 700,
    textTransform: "uppercase",
  },

  paper: {
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[6]
        : theme.colors.gray[0],
  },

  progressBar: {
    "&:not(:first-of-type)": {
      borderLeft: `3px solid ${
        theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white
      }`,
    },
  },
}));

export default function FinanceVisual({
  dashboardFilterObjectList,
  startDate,
  endDate,
}: any) {
  const { classes, theme } = useStyles();
  const { ref, width, height } = useElementSize();
  const { dataObject, currentProgrammes } = useContext(AppContext);
  const { projects = [] } = dataObject;

  const localFilterFunction = useMemo(
    () =>
      createFilterFunction(
        [dashboardFilterObjectList],
        "startDate",
        startDate,
        endDate
      ),
    [dashboardFilterObjectList, startDate, endDate]
  );

  const filteredProjects = useMemo(() => {
    return projects
      .filter(
        (project: any) =>
          project.period === "Live" &&
          currentProgrammes.includes(project.programmeProjectsId)
      )
      .filter(localFilterFunction);
  }, [projects, localFilterFunction, currentProgrammes]);

  const loading = useLoadingStatus(["projects"]);

  const projectObject = useMemo(
    () =>
      filteredProjects.reduce(
        (object: any, project: any) => {
          object.total += project.revenueSold;
          // object[project.programme.verticalProgrammesId].consumed +=
          //   project.consumed;
          // object[project.programme.verticalProgrammesId].paid +=
          //   project.revenueActual;

          object.consumed += project?.statements?.items?.reduce(
            (total: any, statement: any) => {
              total += statement?.invoices?.items.reduce(
                (total: any, invoice: any) => {
                  // if (!invoice.paid) {
                  total += invoice.amount;
                  // }
                  return total;
                },
                0
              );
              return total;
            },
            0
          );

          object.paid += project?.statements?.items?.reduce(
            (total: any, statement: any) => {
              total += statement?.invoices?.items.reduce(
                (total: any, invoice: any) => {
                  if (invoice.paid) {
                    total += invoice.amount;
                  }
                  return total;
                },
                0
              );
              return total;
            },
            0
          );
          return object;
        },
        { total: 0, paid: 0, consumed: 0 }
      ),
    [filteredProjects]
  );

  //End new

  const paid = projectObject.paid;
  const consumed = projectObject.consumed;
  const total = projectObject.total;

  const surplusConsumed = Math.max(0, consumed - paid);
  const remainingValue = total - surplusConsumed - paid;

  const paidPercentage = (paid / total) * 100;
  const consumedSurplusPercentage = (surplusConsumed / total) * 100;

  const remainingPercentage = (remainingValue / total) * 100;

  const PercentageTimePassedWidth = useMemo(() => {
    if (filteredProjects.length === 1) {
      const { startDate, endDate } = filteredProjects[0];

      const percentage = calculatePercentageOfDurationPassed({
        startDate,
        endDate,
      });

      return (
        <div
          style={{
            position: "absolute",
            left: percentage * (ref.current?.offsetWidth ?? 0),
            top: 0,
            width: 2,
            height: "100%",
            backgroundColor: "white",
          }}
        ></div>
      );
    } else {
      <></>;
    }
  }, [filteredProjects, ref]);

  return (
    <WidgetFrame title={"Project Revenue"} icon={IconCalendar}>
      <Group align="flex-end" spacing="xs" sx={{ width: "100%" }}>
        <Group position="apart" sx={{ width: "100%" }}>
          <Text size="xs" color="teal" weight={700}>
            Revenue Claimed
          </Text>
          <Text size="xs" color="orange" weight={700}>
            Revenue Recognised
          </Text>
          <Text size="xs" color="red" weight={700}>
            Revenue Forecast
          </Text>
        </Group>
        <Group position="apart" sx={{ width: "100%" }}>
          <Text size="xl" color="teal" weight={700}>
            £{nFormatter(paid, 1)}{" "}
            {total > 0 ? `(${paidPercentage.toFixed(0)}%)` : ""}
          </Text>
          <Text size="xl" color="orange" weight={700}>
            £{nFormatter(surplusConsumed, 1)}{" "}
            {total > 0 ? `(${consumedSurplusPercentage.toFixed(0)}%)` : ""}
          </Text>
          <Text size="xl" color="red" weight={700}>
            £{nFormatter(remainingValue, 1)}{" "}
            {total > 0 ? `(${remainingPercentage.toFixed(0)}%)` : ""}
          </Text>
        </Group>
        {loading && <Skeleton sx={{ width: "100%", height: 20 }} />}
        {!loading && (
          <div style={{ width: "100%", position: "relative" }}>
            <Progress
              ref={ref}
              classNames={{ bar: classes.progressBar }}
              sx={{ width: "100%", height: 20 }}
              sections={[
                {
                  value: paidPercentage,
                  color:
                    theme.colorScheme === "dark"
                      ? theme.colors.teal[9]
                      : theme.colors.teal[6],
                },
                {
                  value: consumedSurplusPercentage,
                  color: "orange",
                },
                {
                  value: remainingPercentage,
                  color:
                    theme.colorScheme === "dark"
                      ? theme.colors.red[9]
                      : theme.colors.red[6],
                },
              ]}
            />
            {PercentageTimePassedWidth}
          </div>
        )}
      </Group>
    </WidgetFrame>
  );
}
