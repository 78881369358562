import dayjs from "dayjs";

export function createFilterFunction(
  filterObjs: any[],
  dateField: string = "",
  startDate: any = null,
  endDate: any = null
) {
  return function (item: any) {
    return (
      (filterObjs.length === 0 ||
        filterObjs.some((filterObj: any) => {
          return filterObj.every((comparisonObj: any) => {
            const { operand, value, includeExclude, field } = comparisonObj;
            // if (!item.hasOwnProperty(field)) return true;
            const include = includeExclude === "Include";
            switch (operand) {
              case "Equal To":
                return include
                  ? item[field] === value
                  : !(item[field] === value);
              case "Contains":
                return include
                  ? item[field]?.includes(value)
                  : !item[field]?.includes(value);
              case "Is Null":
                return include ? item[field] === null : !(item[field] === null);
              case "Isn't Null":
                return include ? item[field] !== null : !(item[field] !== null);
              case "Is Blank":
                return include ? item[field] === "" : !(item[field] === "");
              case "Isn't Blank":
                return include ? item[field] !== "" : !(item[field] !== "");
              case "Greater Than":
                return include ? item[field] > value : !(item[field] > value);
              case "Less Than":
                return include ? item[field] < value : !(item[field] < value);
              case "Not Equal To":
                return include
                  ? item[field] !== value
                  : !(item[field] !== value);
              case "Between":
                return include
                  ? item[field] >= value[0] && item[field] <= value[1]
                  : !(item[field] >= value[0] && item[field] <= value[1]);
              case "Includes":
                return include
                  ? value.includes(item[field])
                  : !value.includes(item[field]);
            }
            return false;
          });
        })) &&
      filterRecordByDateField(item, dateField, startDate, endDate)
    );
  };
}

const filterRecordByDateField = (
  item: any,
  dateField: string,
  startDate: any,
  endDate: any
) => {
  if (!dateField) return true;
  if (!startDate && !endDate) return true;
  const date = dayjs(item[dateField]);
  if (startDate && date.isBefore(startDate)) return false;
  if (endDate && date.isAfter(endDate)) return false;
  return true;
};
