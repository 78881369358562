import { useState, useEffect, useCallback } from "react";
import { API } from "aws-amplify";
import { getUserPreference } from "../graphql/queries";
import {
  createUserPreference,
  updateUserPreference,
} from "../graphql/mutations";
import { IUserPreferenceObject } from "../types/systemPreferencesTypes";

const defaultPreferenceObject: IUserPreferenceObject = {
  appearance: {
    systemTitle: "",
    primaryColor: "blue",
  },
  modules: {
    Accounts: {
      active: true,
      dataSource: "database",
      dataKey: "accounts",
    },
    Risks: {
      active: true,
      dataSource: "database",
      dataKey: "risks",
    },
    People: {
      active: true,
      dataSource: "database",
      dataKey: "people",
    },
    Projects: {
      active: true,
      dataSource: "database",
      dataKey: "projects",
    },
    "Statements of Work": {
      active: true,
      dataSource: "database",
      dataKey: "statements",
    },
    Deliverables: {
      active: true,
      dataSource: "database",
      dataKey: "deliverables",
    },
    "Change Requests": {
      active: true,
      dataSource: "database",
      dataKey: "changes",
    },
    CSATs: {
      active: true,
      dataSource: "database",
      dataKey: "csats",
    },
    "Weekly Updates": {
      active: true,
      dataSource: "database",
      dataKey: "weeklyUpdates",
    },
    MRRs: {
      active: true,
      dataSource: "database",
      dataKey: "mrrs",
    },
    Costs: {
      active: true,
      dataSource: "database",
      dataKey: "costs",
    },
    PLs: {
      active: true,
      dataSource: "database",
      dataKey: "pls",
    },
    Programmes: {
      active: true,
      dataSource: "database",
      dataKey: "programmes",
    },
    Verticals: {
      active: true,
      dataSource: "database",
      dataKey: "verticals",
    },
  },
};

export const useUserPreferences = ({
  ownerEmail,
}: {
  ownerEmail: string | null;
}) => {
  const [preferenceObject, setPreferenceObject] =
    useState<IUserPreferenceObject | null>(null);

  const updatePreferenceObject = useCallback(async () => {
    const queryObject: any = {
      query: getUserPreference,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: { ownerEmail },
    };
    const response: any = await API.graphql(queryObject);

    if (!response.data.getUserPreference) {
      await API.graphql({
        query: createUserPreference,
        authMode: "AMAZON_COGNITO_USER_POOLS",
        variables: {
          input: {
            ownerEmail,
            preferenceObject: JSON.stringify(defaultPreferenceObject),
          },
        },
      });
      setPreferenceObject(defaultPreferenceObject);
    } else {
      setPreferenceObject(
        JSON.parse(response.data.getUserPreference.preferenceObject)
      );
    }
  }, [ownerEmail]);

  useEffect(() => {
    if (ownerEmail) {
      updatePreferenceObject();
    }
  }, [ownerEmail]);

  return { preferenceObject, setPreferenceObject };
};
