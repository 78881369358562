import React, { useMemo } from "react";
import CrudScreen from "../common/CrudScreen";
import { useCustomDatasets } from "./useCustomDatasets";
import {
  createCustomDataset as createQuery,
  updateCustomDataset as updateQuery,
  deleteCustomDataset as deleteQuery,
} from "../../graphql/mutations";
import customDatasetSchema from "../../context/schemas/customDatasetSchema";

export function CustomDatasets() {
  const { records } = useCustomDatasets();

  const screenProps = useMemo(
    () => ({
      data: records,
      schema: customDatasetSchema,
      title: "Custom Datasets",
      quickStats: null,
      tableColumns: ["name"],
      filterFields: [],
      recordName: "Custom Dataset",
      pagePath: "custom-datasets",
      dataObjectKey: "customDatasets",
      modalFullScreen: true,
      //   actionIconColumnWidth: 130,
      formProps: {
        createQuery,
        updateQuery,
        deleteQuery,
      },
      //   recordScreenTabs: {
      //     edit: { tabName: "Form" },
      //   },
      //   hideModalTabs: true,
      modulesToLoad: ["Verticals", "Accounts", "Programmes", "Custom Datasets"],
      //   CustomForm: CustomDatasetForm,
      //   createButtonFunction: () =>
      //     navigate(`/integrations/create/select-system`),
    }),
    [records]
  );
  return <CrudScreen {...screenProps} />;
}
