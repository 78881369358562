import { ISchema } from "../SchemaTypes";
import common from "./commonSchema";
// import { formatDateString } from "../../components/common/functions/formatFunctions";

const tenantsSchema: ISchema = {
  name: { title: "Name", field: "name", formOptions: { required: true } },

  active: {
    title: "Active",
    field: "active",
    formOptions: {
      required: true,
      options: {
        list: ["Active", "Inactive"],
      },
    },
    type: "select",
  },

  verticals: {
    title: "Verticals",
    field: "verticals",
    type: "hasMany",
    accessorFunction: (row: any) => row.verticals.length,
    formOptions: { required: true },
    excludeFromForm: true,
  },

  accounts: {
    title: "Accounts",
    field: "accounts",
    type: "hasMany",
    accessorFunction: (row: any) => row.accounts.length,
    formOptions: { required: true },
    excludeFromForm: true,
  },

  people: {
    title: "People",
    field: "people",
    type: "hasMany",
    accessorFunction: (row: any) => row.people.length,
    formOptions: { required: true },
    excludeFromForm: true,
  },

  roleTypes: {
    title: "Role Types",
    field: "roleTypes",
    type: "hasMany",
    accessorFunction: (row: any) => row.verticals.length,
    formOptions: { required: true },
    excludeFromForm: true,
  },

  stages: {
    title: "Stages",
    field: "stages",
    type: "hasMany",
    accessorFunction: (row: any) => row.verticals.length,
    formOptions: { required: true },
    excludeFromForm: true,
  },

  ...common,
};

export default tenantsSchema;
