import common from "./commonSchema";
import { ISchema } from "../SchemaTypes";
// import { countryNames } from "../data";
import {
  listDeliveryManagerProjects,
  listPersonRisks,
  listPersonTasks,
  listProjectManagerProjects,
  listRoleTypePeople,
} from "../../graphql/queries";
import {
  createDeliveryManagerProjects,
  createPersonRisks,
  createPersonTasks,
  createProjectManagerProjects,
  createRoleTypePeople,
  deleteDeliveryManagerProjects,
  deletePersonRisks,
  deletePersonTasks,
  deleteProjectManagerProjects,
  deleteRoleTypePeople,
  updateDeliveryManagerProjects,
  updatePersonRisks,
  updatePersonTasks,
  updateProjectManagerProjects,
  updateRoleTypePeople,
} from "../../graphql/mutations";
import { TenantQuestion } from "../../components/common/TenantQuestion";

const peopleSchema: ISchema = {
  roleTypes: {
    title: "Role Types",
    field: "roleTypes",
    formOptions: {
      required: false,
      childIdName: "roleTypeID",
      parentIdName: "personID",
      listKey: "roleType",
      listQuery: listRoleTypePeople,
      updateQuery: updateRoleTypePeople,
      createQuery: createRoleTypePeople,
      deleteQuery: deleteRoleTypePeople,
      options: {
        dataSource: "roleTypes",
        addBlank: true,
        accessorFunction: (dataSource: any) =>
          dataSource.map((row: any) => {
            return { value: row.id, label: row.name };
          }),
      },
    },
    type: "manyToMany",
    accessorFunction: (row: any) => "",
  },

  projectsDeliveryManaged: {
    title: "Projects Delivery Managed",
    field: "projectsDeliveryManaged",
    excludeFromForm: true,
    formOptions: {
      required: false,
      childIdName: "projectID",
      parentIdName: "personID",
      listKey: "project",
      listQuery: listDeliveryManagerProjects,
      updateQuery: updateDeliveryManagerProjects,
      createQuery: createDeliveryManagerProjects,
      deleteQuery: deleteDeliveryManagerProjects,
      options: {
        dataSource: "projects",
        addBlank: true,
        accessorFunction: (dataSource: any) =>
          dataSource.map((row: any) => {
            return { value: row.id, label: row.name };
          }),
      },
    },
    type: "manyToMany",
    accessorFunction: (row: any) => "",
  },

  projectsProjectManaged: {
    title: "Projects Project Managed",
    field: "projectsProjectManaged",
    excludeFromForm: true,
    formOptions: {
      required: false,
      childIdName: "projectID",
      parentIdName: "personID",
      listKey: "project",
      listQuery: listProjectManagerProjects,
      updateQuery: updateProjectManagerProjects,
      createQuery: createProjectManagerProjects,
      deleteQuery: deleteProjectManagerProjects,
      options: {
        dataSource: "projects",
        addBlank: true,
        accessorFunction: (dataSource: any) =>
          dataSource.map((row: any) => {
            return { value: row.id, label: row.name };
          }),
      },
    },
    type: "manyToMany",
    accessorFunction: (row: any) => "",
  },

  tasks: {
    title: "Tasks",
    field: "tasks",
    excludeFromForm: true,
    formOptions: {
      required: false,
      childIdName: "taskID",
      parentIdName: "personID",
      listKey: "task",
      listQuery: listPersonTasks,
      updateQuery: updatePersonTasks,
      createQuery: createPersonTasks,
      deleteQuery: deletePersonTasks,
      options: {
        dataSource: "tasks",
        addBlank: true,
        accessorFunction: (dataSource: any) =>
          dataSource.map((row: any) => {
            return { value: row.id, label: row.name };
          }),
      },
    },
    type: "manyToMany",
    accessorFunction: (row: any) => "",
  },

  risks: {
    title: "Risks",
    field: "risks",
    excludeFromForm: true,
    formOptions: {
      required: false,
      childIdName: "riskD",
      parentIdName: "personID",
      listKey: "risk",
      listQuery: listPersonRisks,
      updateQuery: updatePersonRisks,
      createQuery: createPersonRisks,
      deleteQuery: deletePersonRisks,
      options: {
        dataSource: "risks",
        addBlank: true,
        accessorFunction: (dataSource: any) =>
          dataSource.map((row: any) => {
            return { value: row.id, label: row.name };
          }),
      },
    },
    type: "manyToMany",
    accessorFunction: (row: any) => "",
  },

  firstName: {
    title: "First Name",
    field: "firstName",
    formOptions: { required: true },
  },
  lastName: {
    title: "Last Name",
    field: "lastName",
    formOptions: { required: true },
  },
  fullName: {
    title: "Full Name",
    field: "fullName",
    accessorFunction: (row: any) => `${row.firstName} ${row.lastName}`,
    excludeFromForm: true,
    formOptions: { required: false },
  },
  // abreviation: {
  //   title: "Abreviation",
  //   field: "abreviation",
  //   formOptions: { required: false },
  // },
  email: {
    title: "Email",
    field: "email",
    formOptions: { required: false },
  },
  position: {
    title: "Position",
    field: "position",
    formOptions: { required: false },
  },
  skills: {
    title: "Skills",
    field: "skills",
    excludeFromForm: true,
    formOptions: {
      required: false,
      defaultValue: [],
      options: {
        list: [
          "Business Analysis",
          "Project Management",
          "Data Analysis",
          "Data Engineering",
        ],
      },
    },
    type: "multiSelect",
  },
  // workstream: {
  //   title: "Workstream",
  //   field: "workstream",
  //   formOptions: { required: false },
  // },
  // location: {
  //   title: "Location",
  //   field: "location",
  //   formOptions: {
  //     required: false,
  //     options: {
  //       list: [""].concat(countryNames),
  //     },
  //   },
  //   type: "select",
  // },
  // timezone: {
  //   title: "Timezone",
  //   field: "timezone",
  //   formOptions: { required: false },
  // },
  // raci: { title: "RACI", field: "raci", formOptions: { required: false } },
  // interest: {
  //   title: "Interest",
  //   field: "interest",
  //   formOptions: { required: false, min: 0, max: 10, defaultValue: 0, step: 1 },
  //   type: "number",
  // },

  hoursPerWeek: {
    title: "Hours Per Week",
    field: "hoursPerWeek",
    formOptions: { required: false, min: 0, precision: 1 },
    type: "number",
  },
  // programmeReportingLine: {
  //   title: "Assigned To",
  //   field: "programmeReportingLine",
  //   formOptions: { required: true },
  // },
  currentlyActive: {
    title: "Currently Active",
    field: "currentlyActive",
    formOptions: {
      required: false,
      defaultValue: "Yes",
      options: { list: ["Yes", "No"] },
    },
    filterOptions: {
      defaultValue: "Yes",
      includeAll: true,
    },
    type: "select",
  },

  tenant: {
    title: "Tenant",
    field: "tenant",
    accessorFunction: (value: any) => value?.tenant?.name,
    formatFunction: (value: any) => value,
    formOptions: { required: true },
    excludeFromForm: true,
    type: "belongsTo",
  },
  tenantPeopleId: {
    title: "Tenant",
    field: "tenantPeopleId",
    formOptions: {
      required: true,
      CustomQuestion: (props: any) => (
        <TenantQuestion {...props} fieldName="tenantPeopleId" />
      ),
      options: {
        dataSource: "tenants",
        addBlank: true,
        accessorFunction: (dataSource: any) =>
          dataSource.map((row: any) => {
            return { value: row.id, label: row.name };
          }),
      },
      order: 1,
    },
    type: "select",
  },

  integrationSystemId: {
    title: "System Unique ID",
    field: "integrationSystemId",
    formOptions: { required: false },
    excludeFromForm: true,
  },

  employeeSatisfactionRecords: {
    title: "Employee Satisfaction Records",
    field: "employeeSatisfactionRecords",
    type: "hasMany",
    accessorFunction: (row: any) => row.employeeSatisfactionRecords.length,
    formOptions: { required: true },
    excludeFromForm: true,
  },

  projectAllocations: {
    title: "Project Allocations",
    field: "projectAllocations",
    type: "hasMany",
    accessorFunction: (row: any) => row.projectAllocations.length,
    formOptions: { required: true },
    excludeFromForm: true,
  },

  timesheets: {
    title: "Time Sheets",
    field: "timesheets",
    type: "hasMany",
    accessorFunction: (row: any) => row.timesheets.length,
    formOptions: { required: true },
    excludeFromForm: true,
  },

  ...common,
};

export default peopleSchema;
