import { Button, Group, Modal } from "@mantine/core";
import { useState } from "react";

export default function ConfirmDeleteDashboardModal({
  opened,
  onClose,
  deleteDashboardFunction,
  currentDashboard,
}: any) {
  const [submitting, setSubmitting] = useState(false);
  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title="Are you sure you want to delete this dashboard?"
      closeOnClickOutside={false}
      centered
    >
      <Group>
        <Button
          color="red"
          disabled={submitting}
          onClick={() => {
            setSubmitting(true);
            deleteDashboardFunction(currentDashboard.id);
            setSubmitting(false);
          }}
        >
          Delete
        </Button>
        <Button variant="outline" style={{ flexGrow: 1 }}>
          Cancel
        </Button>
      </Group>
    </Modal>
  );
}
