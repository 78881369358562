/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_mobile_analytics_app_id": "0c7310ff705c425a8d5da64ec1260d25",
    "aws_mobile_analytics_app_region": "eu-west-2",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "0c7310ff705c425a8d5da64ec1260d25",
            "region": "eu-west-2"
        }
    },
    "aws_appsync_graphqlEndpoint": "https://mf3ej5jbije55ftljooguwxvea.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-v2xg4lbljzcllnerzx6iz2d5xe",
    "aws_cognito_identity_pool_id": "eu-west-2:522ee20c-586b-4ff5-b7b7-fac6590695ec",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_ZbI6ItCHw",
    "aws_user_pools_web_client_id": "4ccmu839o78j8mrkjcj73lfov6",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "logos151216-main",
    "aws_user_files_s3_bucket_region": "eu-west-2"
};


export default awsmobile;
