import {
  ActionIcon,
  Button,
  Group,
  NativeSelect,
  Stack,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  FormInputTypes,
  IFieldObject,
  ISchema,
} from "../../../context/SchemaTypes";
import CustomPaper from "../../common/CustomPaper";
import common from "../../../context/schemas/commonSchema";
import { SelectOptions } from "./SelectOptions";
import { IconLock, IconPlus, IconTrash } from "@tabler/icons-react";
import { PermissionQuestion } from "../PermissionsQuestion";

const typeOptipns: { value: FormInputTypes; label: string }[] = [
  { value: "text", label: "Text" },
  { value: "number", label: "Number" },
  { value: "select", label: "Select" },
  { value: "date", label: "Date" },
  { value: "boolean", label: "Boolean" },
  { value: "currency", label: "Currency" },
  { value: "textarea", label: "Text Area" },
  { value: "belongsTo", label: "Belongs To" },
];

const moduleOptions = ["", { value: "people", label: "Person" }];

function SchemaField(
  props: IFieldObject & { parsed: ISchema; updateSchema: any }
) {
  const [showPermissions, setShowPermisisons] = useState(false);
  const { title, field, formOptions, parsed, updateSchema, type, form } = props;
  const newParsed = useMemo(() => structuredClone(parsed), [parsed]);

  const updateForm = useCallback(
    (updatedRules: any) => {
      const currentValue = JSON.parse(form.values.permissionsConfig);

      if (updatedRules.length > 0) {
        form.setValues({
          permissionsConfig: JSON.stringify({
            ...currentValue,
            fieldPermissions: { [field]: updatedRules },
          }),
        });
      } else {
        const updatedValue = structuredClone(currentValue);
        if (currentValue.fieldPermissions[field]) {
          delete updatedValue.fieldPermissions[field];
        }
        form.setValues({
          permissionsConfig: JSON.stringify({
            ...updatedValue,
          }),
        });
      }
    },
    [form, field]
  );

  return (
    <div>
      <CustomPaper>
        <Group position="apart">
          <Text>{field}</Text>
          <ActionIcon color="red">
            <IconTrash />
          </ActionIcon>
        </Group>
        <Group>
          <TextInput
            label="Title"
            value={title}
            onChange={(e) => {
              newParsed[field].title = e.target.value;
              updateSchema(newParsed);
            }}
          />
          <NativeSelect
            label="Type"
            data={typeOptipns}
            value={type}
            onChange={(e) => {
              //@ts-ignore
              newParsed[field].type = e.target.value;
              updateSchema(newParsed);
            }}
          />
          {newParsed[field].type === "select" &&
            newParsed[field].formOptions.options?.dataSource && (
              <NativeSelect
                label="Select Module"
                data={moduleOptions}
                value={newParsed[field].formOptions.options?.dataSource}
                onChange={(e) => {}}
              />
            )}
          <Group>
            {newParsed[field].type === "select" &&
              newParsed[field].formOptions.options?.list && (
                <SelectOptions
                  newParsed={newParsed}
                  updateSchema={updateSchema}
                  field={field}
                />
              )}
          </Group>
        </Group>
        <Button
          variant="subtle"
          leftIcon={<IconLock />}
          onClick={() => setShowPermisisons(!showPermissions)}
        >
          {showPermissions ? "Hide Permissions" : "Edit Permissions"}
        </Button>
        {showPermissions && (
          <PermissionQuestion
            updateForm={updateForm}
            initialRules={
              JSON.parse(form.values.permissionsConfig).fieldPermissions[
                field
              ] ?? []
            }
          />
        )}
      </CustomPaper>
    </div>
  );
}

function getFormOptions({ type, module, selectType }: any) {
  switch (type) {
    case "select":
      switch (selectType) {
        case "list":
          return {
            required: false,
            options: {
              list: [],
              addBlank: true,
            },
          };
        default:
          return {
            required: false,
            options: {
              dataSource: module,
              addBlank: true,
            },
          };
      }

    default:
      return { required: false };
  }
}

export function SchemaQuestion({ form, editable }: any) {
  const [newField, setNewField] = useState("");
  const [formattedField, setFormattedField] = useState("");
  const [module, setModule] = useState("");
  const [newType, setNewType] = useState<FormInputTypes>("text");
  const [selectType, setSelectType] = useState<"list" | "dataSource">("list");
  const [parsed, setParsed] = useState<ISchema>(JSON.parse(form.values.schema));

  useEffect(() => {
    const parsed = JSON.parse(form.values.schema);
    setParsed(parsed);
  }, [form.values.schema]);

  const updateSchema = useCallback(
    (newParsed: ISchema) => {
      form.setValues({ schema: JSON.stringify(newParsed) });
    },
    [form]
  );

  const rows = useMemo(() => {
    return Object.keys(parsed).map((field: any, index: number) => {
      if (common[field] || field === "tenantCustomRecordsId") return null;
      return (
        <SchemaField
          key={index}
          {...parsed[field]}
          updateSchema={updateSchema}
          parsed={parsed}
          form={form}
        />
      );
    });
  }, [parsed, updateSchema, form]);

  const addNewField = useCallback(() => {
    const newParsed = structuredClone(parsed);
    newParsed[formattedField] = {
      title: newField,
      field: formattedField,
      formOptions: getFormOptions({ type: newType, module, selectType }),
      type: newType === "belongsTo" ? "select" : newType,
    };
    form.setValues({ schema: JSON.stringify(newParsed) });
    setNewField("");
    setFormattedField("");
    setNewType("text");
    setSelectType("list");
  }, [form, newField, parsed, formattedField, newType, module]);

  return (
    <div>
      <Stack>
        <Title order={3}>Schema</Title>
        <Stack>
          <Title order={4}>Fields</Title>
          {rows}
          <CustomPaper>
            <Stack>
              <Group position="apart">
                <Text>{formattedField}</Text>
                <ActionIcon
                  color="green"
                  onClick={addNewField}
                  disabled={!!parsed[formattedField] || !newField}
                  variant="light"
                >
                  <IconPlus />
                </ActionIcon>
              </Group>
              <Group>
                <TextInput
                  label="Title"
                  value={newField}
                  onChange={(e) => {
                    setNewField(e.target.value);
                    setFormattedField(
                      e.target.value.toLowerCase().replace(/\s+/g, "_")
                    );
                  }}
                />
                <NativeSelect
                  label="Type"
                  data={typeOptipns}
                  value={newType}
                  onChange={(e) => {
                    //@ts-ignore
                    setNewType(e.target.value);
                  }}
                />

                {newType === "select" && (
                  <NativeSelect
                    label="Select Type"
                    data={[
                      { value: "list", label: "List" },
                      { value: "dataSource", label: "Data Source" },
                    ]}
                    value={selectType}
                    //@ts-ignore
                    onChange={(e) => setSelectType(e.target.value)}
                  />
                )}

                {selectType === "dataSource" && (
                  <NativeSelect
                    label="Select Module"
                    data={moduleOptions}
                    value={module}
                    onChange={(e) => setModule(e.target.value)}
                  />
                )}
              </Group>
            </Stack>
          </CustomPaper>
        </Stack>
      </Stack>
    </div>
  );
}
