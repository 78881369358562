import React from "react";
import useStyles from "./RadarChart.styles";
import { useRadarChart } from "./useRadarChart";
import {
  Legend,
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  Radar,
  ResponsiveContainer,
  RadarChart as ReRadarChart,
  Tooltip,
} from "recharts";
import { CustomTooltip, getChartColors } from "../ChartUtilities";
import { useMantineTheme } from "@mantine/core";

export type RadarChartProps = {};

export function RadarChart(props: any) {
  const { classes } = useStyles();

  const {
    data,
    dataLabels,
    dataLabelFontSize,
    values,
    chartVariant,
    legend,
    updateDashboardFilter,
    rows,
    formatFunction,
    comparisonFieldString,
    disableTooltip,
    disableChartInteractions,
    cols,
    schema,
    disableAnimations,
    colKeys,
  } = props;

  const theme = useMantineTheme();
  const colors = getChartColors(theme);

  const colorField = colKeys.length > 0 ? colKeys : values;

  // const { data } = useRadarChart();
  return (
    <ResponsiveContainer
      width="100%"
      height="99%"
      style={{ overflow: "hidden" }}
    >
      <ReRadarChart cx="50%" cy="50%" outerRadius="80%" data={data}>
        <defs>
          {colorField.map((value: string, index: number) => {
            return (
              <linearGradient
                id={`colorValue${index}`}
                x1="1"
                y1="0"
                x2="0"
                y2="0"
                key={`colorValue${index}`}
              >
                <stop offset="5%" stopColor={colors[index]} stopOpacity={0.5} />
                <stop
                  offset="95%"
                  stopColor={colors[index]}
                  stopOpacity={0.5}
                />
              </linearGradient>
            );
          })}
        </defs>

        <PolarGrid />
        <PolarAngleAxis dataKey="name" />
        <PolarRadiusAxis />
        {/* <Radar
          name="Mike"
          dataKey="A"
          stroke="#8884d8"
          fill="#8884d8"
          fillOpacity={0.6}
        />
        <Radar
          name="Lily"
          dataKey="B"
          stroke="#82ca9d"
          fill="#82ca9d"
          fillOpacity={0.6}
        /> */}
        {values.map((value: string, index: number) => {
          if (colKeys.length > 0) {
            return colKeys.map((col: string, colIndex: number) => {
              return (
                <React.Fragment key={`${value}-${col[0]}`}>
                  <Radar
                    isAnimationActive={!disableAnimations}
                    dataKey={col[0]}
                    name={col[0] ? schema[col[0]]?.title : "No Data"}
                    fill={`url(#colorValue${colIndex})`}
                    // cursor={disableChartInteractions ? undefined : "pointer"}
                    // onClick={(input) => {
                    //   if (disableChartInteractions) return;
                    //   if (rows[0]) {
                    //     updateDashboardFilter(rows[0], input.name);
                    //   } else {
                    //     updateDashboardFilter(
                    //       cols[0],
                    //       input.tooltipPayload[0].dataKey
                    //     );
                    //   }
                    // }}
                  />
                </React.Fragment>
              );
            });
          } else {
            return (
              <React.Fragment key={value}>
                <Radar
                  isAnimationActive={!disableAnimations}
                  dataKey={value}
                  name={value ? schema[value]?.title : "No Data"}
                  fill={`url(#colorValue${index})`}
                  // cursor={disableChartInteractions ? undefined : "pointer"}
                  // onClick={(input) => {
                  //   if (disableChartInteractions) return;
                  //   updateDashboardFilter(rows[0], input.name);
                  // }}
                />
              </React.Fragment>
            );
          }
        })}
        {legend && <Legend />}
        {!disableTooltip && (
          <Tooltip
            cursor={false}
            content={
              <CustomTooltip
                formatFunction={formatFunction}
                comparisonFieldString={comparisonFieldString}
              />
            }
          />
        )}
      </ReRadarChart>
    </ResponsiveContainer>
  );
}
