import React, { useCallback, useContext, useState } from "react";
import {
  Alert,
  Button,
  Select,
  Stack,
  Textarea,
  TextInput,
  Title,
  useMantineTheme,
} from "@mantine/core";
import { contactSupport } from "../../../graphql/queries";
import { API } from "aws-amplify";
import AppContext from "../../../context/AppContext";
import { IconAlertCircle, IconCheck } from "@tabler/icons-react";

type Status = "UNSENT" | "SENDING" | "SUCCESS" | "FAILED";

export function ContactSupportForm() {
  const theme = useMantineTheme();
  const { currentTenant, ownerEmail } = useContext(AppContext);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [category, setCategory] = useState<string | null>(null);
  const [status, setStatus] = useState<Status>("UNSENT");

  const sendMessage = useCallback(async () => {
    setStatus("SENDING");
    const response = await API.graphql({
      query: contactSupport,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: {
        input: JSON.stringify({
          subject,
          message,
          category,
          user: ownerEmail,
          location: window.location.href,
          tenant: currentTenant,
        }),
      },
    });
    //@ts-ignore
    if (response && response.data && response.data.contactSupport) {
      //@ts-ignore
      const status = JSON.parse(response.data.contactSupport);

      if (status.statusCode === 200) {
        setStatus("SUCCESS");
      } else {
        setStatus("FAILED");
      }
    }
  }, [subject, message, currentTenant, ownerEmail, category]);

  return (
    <Stack miw={300}>
      <Title order={5}>Contact Support</Title>
      {["UNSENT", "SENDING"].includes(status) && (
        <>
          <Select
            label="Category"
            data={["Bug", "Feature Request", "New Visual Request", "Other"]}
            value={category}
            onChange={(value) => setCategory(value)}
            disabled={status === "SENDING"}
            styles={{
              input: {
                backgroundColor:
                  theme.colorScheme === "dark"
                    ? theme.colors.dark[8]
                    : undefined,
              },
            }}
          />
          <TextInput
            label="Subject"
            value={subject}
            disabled={status === "SENDING"}
            onChange={(e) => setSubject(e.target.value)}
            styles={{
              input: {
                backgroundColor:
                  theme.colorScheme === "dark"
                    ? theme.colors.dark[8]
                    : undefined,
              },
            }}
          />
          <Textarea
            label="Message"
            value={message}
            disabled={status === "SENDING"}
            onChange={(e) => setMessage(e.target.value)}
            styles={{
              input: {
                backgroundColor:
                  theme.colorScheme === "dark"
                    ? theme.colors.dark[8]
                    : undefined,
              },
            }}
          />
          <Button
            onClick={sendMessage}
            disabled={status !== "UNSENT" || !subject || !message || !category}
          >
            Send
          </Button>
        </>
      )}
      {status === "SUCCESS" && (
        <Alert title="Success" color="green" icon={<IconCheck size="1rem" />}>
          You will have received an email.
        </Alert>
      )}
      {status === "FAILED" && (
        <Alert
          title="Failed"
          color="red"
          icon={<IconAlertCircle size="1rem" />}
        >
          Your message has failed to send. Please try again.
        </Alert>
      )}
    </Stack>
  );
}
