import { getStylesRef, createStyles } from "@mantine/core";
import { useContext, useMemo } from "react";
import AppContext from "../../../../context/AppContext";
import { ProgrammeTile } from "./ProgrammeTile";

import WidgetFrame from "../../WidgetFrame";
import { Carousel } from "@mantine/carousel";
import useLoadingStatus from "../../../../hooks/useLoadingStatus";
import { createFilterFunction } from "../../functions/createFilterFunction";
import useFetchData from "../../../../hooks/useFetchData";

const useStyles = createStyles(() => ({
  controls: {
    ref: getStylesRef("controls"),
    transition: "opacity 150ms ease",
    opacity: 0,
  },

  root: {
    "&:hover": {
      [`& .${getStylesRef("controls")}`]: {
        opacity: 1,
      },
    },
  },
}));

export default function ProgrammeSummary(props: any) {
  const { dashboardFilterObjectList, updateDashboardFilter } = props;
  const { classes } = useStyles();
  const {
    dataObject: { programmes = [], pls = [], risks = [] },
    currentTenant,
    currentProgramme,
    currentProgrammes,
    setCurrentProgramme,
  } = useContext(AppContext);

  useFetchData(["Accounts", "PLs", "Programmes", "Risks"], ["Live"]);

  const loading = useLoadingStatus(["accounts", "pls", "programmes", "risks"]);

  const localFilterFunction = useMemo(
    () => createFilterFunction([dashboardFilterObjectList]),
    [dashboardFilterObjectList]
  );

  const permittedProgrammeList =
    currentProgramme === "All" ? currentProgrammes : [currentProgramme];

  const filteredProgrammes = programmes.filter((programme: any) =>
    permittedProgrammeList.includes(programme.id)
  );

  const filteredPls = useMemo(() => {
    return pls
      .filter((pl: any) => pl.period === "Live" && pl.status !== "Closed")
      .filter(localFilterFunction);
  }, [pls, localFilterFunction]);

  const plObject = useMemo(
    () =>
      filteredPls.reduce((object: any, pl: any) => {
        if (!object[pl.programme.id]) {
          object[pl.programme.id] = { total: 0, paid: 0, consumed: 0 };
        }
        object[pl.programme.id].total += pl.revenueSoldUSD;
        object[pl.programme.id].consumed += pl.revenueRecognizedUSD;
        object[pl.programme.id].paid += pl.billedRedeemedUSD;
        return object;
      }, {}),
    [filteredPls]
  );

  const totalsObject = useMemo(() => {
    return pls.reduce(
      (object: any, pl: any) => {
        object.total += pl.revenueSoldUSD;
        object.consumed += pl.revenueRecognizedUSD;
        object.paid += pl.billedRedeemedUSD;
        return object;
      },
      { total: 0, paid: 0, consumed: 0 }
    );
  }, [pls]);

  return (
    <WidgetFrame title={"Programmes Summary (Open Engagements)"}>
      <Carousel
        mx="auto"
        align="start"
        slideGap="md"
        controlsOffset="xs"
        controlSize={21}
        px={"md"}
        // withIndicators
        classNames={classes}
      >
        {filteredProgrammes
          .sort((a: any, b: any) => {
            return plObject[a.id]?.total ?? 0 - plObject[b.id]?.total ?? 0;
          })
          .reverse()
          .map((programme: any) => {
            return (
              <Carousel.Slide size={400} key={programme.id} p={5}>
                <ProgrammeTile
                  name={programme.name}
                  id={programme.id}
                  tenant={currentTenant}
                  currentProgramme={currentProgramme}
                  setCurrentProgramme={setCurrentProgramme}
                  loading={loading}
                  completed={3600000}
                  total={100}
                  totalsObject={plObject[programme.id] ?? null}
                  stats={[
                    {
                      value: plObject[programme.id]
                        ? plObject[programme.id].length
                        : 0,
                      label: "Projects",
                    },
                    {
                      value: risks.filter(
                        (risk: any) => risk.programme.id === programme.id
                      ).length,
                      label: "Risks",
                    },
                  ]}
                />
              </Carousel.Slide>
            );
          })}
      </Carousel>
    </WidgetFrame>
  );
}
