import {
  useMantineTheme,
  createStyles,
  Title,
  Paper,
  Badge,
  Group,
  TextInput,
} from "@mantine/core";
import CustomPaper from "../../CustomPaper";
import { StrictModeDroppable } from "./StrictModeDroppable";
import { Draggable } from "react-beautiful-dnd";
import { Icon123, IconAbc, IconCalendarTime } from "@tabler/icons-react";
import { useState } from "react";
import { useDebouncedValue } from "@mantine/hooks";

const useStyles = createStyles((theme) => ({
  horizontal: {
    display: "flex",
    flexWrap: "wrap",
    gap: "5px",
  },
}));

function getIcon(type: string) {
  if (!type) return IconAbc;
  const iconLookup = {
    number: Icon123,
    date: IconCalendarTime,
  };
  //@ts-ignore
  return iconLookup[type] ? iconLookup[type] : IconAbc;
}

export default function DraggableArea(props: any) {
  const { classes } = useStyles();
  const theme = useMantineTheme();

  const [searchTerm, setSearchTerm] = useState("");
  const [debounced] = useDebouncedValue(searchTerm, 200);

  const {
    columns,
    column,
    direction = "vertical",
    title,
    schema,
    controlsComponent,
    includeSearch = false,
  } = props;

  return (
    <CustomPaper>
      <div
        style={{
          display: "flex",
          gap: "0.5em",
          flexDirection: direction === "vertical" ? "column" : "row",
          height: "100%",
          maxHeight: "100%",
          overflowY: "scroll",
          paddingRight: "10px",
        }}
      >
        <Group position="apart">
          <Title order={5} color="dimmed">
            {title}
          </Title>
          {controlsComponent}
        </Group>
        {includeSearch && (
          <TextInput
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search"
          />
        )}
        <div style={{ flexGrow: 1 }}>
          <StrictModeDroppable droppableId={column} direction={direction}>
            {(provided: any, snapshot: any) => (
              <Paper
                sx={{
                  padding: "0.5em",
                  height: "100%",
                  // maxHeight: 330,
                  // overflowY: "scroll",
                  overflow: "hidden",
                }}
              >
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={{
                    minHeight: "40px",
                    overflow: "hidden",
                  }}
                  className={
                    direction === "horizontal" ? classes.horizontal : null
                  }
                >
                  {columns[column].map((id: any, index: number) => {
                    const item = schema[id];
                    if (!item?.title?.toLowerCase().includes(debounced))
                      return null;
                    return (
                      <Draggable
                        key={item.field}
                        draggableId={item.field}
                        index={index}
                        isDragDisabled={false}
                      >
                        {(provided: any, snapshot: any) => {
                          const Icon = getIcon(item.type);
                          return (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <Badge
                                variant={
                                  item.type === "number"
                                    ? "outline"
                                    : item.type === "date"
                                    ? "light"
                                    : "filled"
                                }
                                color={theme.primaryColor}
                                sx={{ margin: 0 }}
                                leftSection={<Icon size={"1.5em"} />}
                              >
                                {item.title}
                              </Badge>
                            </div>
                          );
                        }}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              </Paper>
            )}
          </StrictModeDroppable>
        </div>
      </div>
    </CustomPaper>
  );
}
