import { createStyles } from "@mantine/core";

export default createStyles((theme) => ({
  builder: {
    display: "grid",
    gridTemplateAreas: `
      "toolbar toolbar toolbar" 
      "unused rows controls" 
      "unused columns graph" 
      "unused values graph"
      `,
    gridTemplateColumns: "250px 250px 1fr",
    gridTemplateRows: "auto auto auto 1fr",
    minHeight: "85vh",
    maxHeight: "90vh",
  },
  controls: {
    gridArea: "controls",
    padding: "0.5em",
    overflow: "scroll",
  },
  unused: {
    gridArea: "unused",
    padding: "0.5em",
    maxHeight: "100%",
  },
  columns: {
    gridArea: "columns",
    padding: "0.5em",
  },
  rows: {
    gridArea: "rows",
    padding: "0.5em",
  },
  values: {
    gridArea: "values",
    padding: "0.5em",
  },
  toolbar: {
    gridArea: "toolbar",
    padding: "0.5em",
    display: "flex",
    gap: "1em",
  },
  graph: {
    gridArea: "graph",
    padding: "0.5em",
    overflow: "scroll",
  },

  horizontal: {
    display: "flex",
    flexWrap: "wrap",
    gap: "5px",
  },
  control: {
    minWidth: 150,
  },
}));
