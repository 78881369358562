import common from "./commonSchema";
import { ISchema } from "../SchemaTypes";
import { formatDateString } from "../../components/common/functions/formatFunctions";

const mrrSchema: ISchema = {
  programme: {
    title: "Programme",
    field: "programme",
    accessorFunction: (value: any) => value.programme?.name,
    formOptions: { required: true },
    excludeFromForm: true,
    type: "belongsTo",
  },
  programmeMrrsId: {
    title: "Programme ID",
    field: "programmeMrrsId",
    formOptions: {
      required: true,
      options: {
        dataSource: "programmes",
        addBlank: true,
        accessorFunction: (dataSource: any) =>
          dataSource?.map((row: any) => {
            return { value: row.id, label: row.name };
          }) ?? [],
      },
      order: 1,
    },
    type: "select",
  },
  fiscalYear: {
    title: "Fiscal Year",
    field: "fiscalYear",
    formOptions: { required: false },
  },
  period: {
    title: "Period",
    field: "period",
    formOptions: { required: false },
    equivalentFields: { all: "period" },
  },
  geo: {
    title: "Geo",
    field: "geo",
    formOptions: { required: false },
  },
  region: {
    title: "Region",
    field: "region",
    formOptions: { required: false },
    equivalentFields: { pls: "region", costs: "region" },
  },
  subRegion: {
    title: "Sub Region",
    field: "subRegion",
    formOptions: { required: false },
    equivalentFields: { pls: "subRegion", costs: "subRegion" },
  },
  territory: {
    title: "Territory",
    field: "territory",
    formOptions: { required: false },
  },
  engagementName: {
    title: "Engagement Name",
    field: "engagementName",
    formOptions: { required: false },
    equivalentFields: { pls: "engagementName", costs: "engagementName" },
  },
  engagementId: {
    title: "Engagement ID",
    field: "engagementId",
    formOptions: { required: false },
  },
  engagementStatus: {
    title: "Engagement Status",
    field: "engagementStatus",
    formOptions: { required: false },
    equivalentFields: { pls: "engagementStatus", costs: "engagementStatus" },
  },
  engagementType: {
    title: "Engagement Type",
    field: "engagementType",
    formOptions: { required: false },
  },
  businessUnit: {
    title: "Business Unit",
    field: "businessUnit",
    formOptions: { required: false },
  },
  billToCustomer: {
    title: "Bill to Customer",
    field: "billToCustomer",
    formOptions: { required: false },
  },
  endCustomer: {
    title: "End Customer",
    field: "endCustomer",
    formOptions: { required: false },
  },
  budgetedHoursTotal: {
    title: "Budgeted Hours Total",
    field: "budgetedHoursTotal",
    type: "number",
    formOptions: { required: false },
  },
  plannedHoursTotal: {
    title: "Planned Hours Total",
    field: "plannedHoursTotal",
    type: "number",
    formOptions: { required: false },
  },
  createdOn: {
    title: "Created On",
    field: "createdOn",
    formOptions: { required: false },
  },
  paymentType: {
    title: "Payment Type",
    field: "paymentType",
    formOptions: { required: false },
  },
  revRecType: {
    title: "Rev Rec Type",
    field: "revRecType",
    formOptions: { required: false },
  },
  billingSKU: {
    title: "Billing SKU",
    field: "billingSKU",
    formOptions: { required: false },
  },
  engagementAmount: {
    title: "Engagement Amount",
    field: "engagementAmount",
    type: "number",
    formOptions: { required: false },
  },
  billingCurrency: {
    title: "Billing Currency",
    field: "billingCurrency",
    formOptions: { required: false },
  },
  totalBilledRedeemed: {
    title: "Total Billed Redeemed",
    field: "totalBilledRedeemed",
    type: "number",
    formOptions: { required: false },
  },
  remainingAmount: {
    title: "Remaining Amount",
    field: "remainingAmount",
    type: "number",
    formOptions: { required: false },
  },
  operationAnalysts: {
    title: "Operation Analysts",
    field: "operationAnalysts",
    formOptions: { required: false },
  },
  timeApprover: {
    title: "Time Approver",
    field: "timeApprover",
    formOptions: { required: false },
  },
  billedRedeemedByDateRange: {
    title: "Billed Redeemed by Date Range",
    field: "billedRedeemedByDateRange",
    type: "number",
    formOptions: { required: false },
  },
  remainingForecastByDateRange: {
    title: "Remaining Forecast by Date Range",
    field: "remainingForecastByDateRange",
    type: "number",
    formOptions: { required: false },
  },
  forecastHrsThisQtr: {
    title: "Forecast Hrs This Qtr",
    field: "forecastHrsThisQtr",
    type: "number",
    formOptions: { required: false },
  },
  forecastRevenueOfCurrentQtr: {
    title: "Forecast Revenue of Current Qtr",
    field: "forecastRevenueOfCurrentQtr",
    type: "number",
    formOptions: { required: false },
  },
  forecastRevenueOfCurrentQtrUSD: {
    title: "Forecast Revenue of Current Qtr USD",
    field: "forecastRevenueOfCurrentQtrUSD",
    type: "number",
    formOptions: { required: false },
  },
  totalBillableHoursPriorQtrEnd: {
    title: "Total Billable Hours Prior Qtr End",
    field: "totalBillableHoursPriorQtrEnd",
    type: "number",
    formOptions: { required: false },
  },
  totalBillableHoursThisQtr: {
    title: "Total Billable Hours This Qtr",
    field: "totalBillableHoursThisQtr",
    type: "number",
    formOptions: { required: false },
  },
  mgmtRevAsOfPriorQtr: {
    title: "Mgmt Rev As of Prior Qtr",
    field: "mgmtRevAsOfPriorQtr",
    type: "number",
    formOptions: { required: false },
  },
  mgmtRevAsOfPriorQtrUSD: {
    title: "Mgmt Rev As of Prior Qtr USD",
    field: "mgmtRevAsOfPriorQtrUSD",
    type: "number",
    formOptions: { required: false },
  },
  qtrManagementRevenueToDate: {
    title: "Qtr Management Revenue to Date",
    field: "qtrManagementRevenueToDate",
    type: "number",
    formOptions: { required: false },
  },
  qtrManagementRevenueToDateUSD: {
    title: "Qtr Management Revenue to Date USD",
    field: "qtrManagementRevenueToDateUSD",
    type: "number",
    formOptions: { required: false },
  },
  internalHoursQTD: {
    title: "Internal Hours QTD",
    field: "internalHoursQTD",
    type: "number",
    formOptions: { required: false },
  },
  subbingHoursQTD: {
    title: "Subbing Hours QTD",
    field: "subbingHoursQTD",
    type: "number",
    formOptions: { required: false },
  },
  expensesBilledRedeemedByDateRange: {
    title: "Expenses Billed Redeemed by Date Range",
    field: "expensesBilledRedeemedByDateRange",
    type: "number",
    formOptions: { required: false },
  },
  contractStatus: {
    title: "Contract Status",
    field: "contractStatus",
    formOptions: { required: false },
  },
  poNumber: {
    title: "PO Number",
    field: "poNumber",
    formOptions: { required: false },
  },
  billedRedeemedByDateRangeUSD: {
    title: "Billed Redeemed by Date Range USD",
    field: "billedRedeemedByDateRangeUSD",
    type: "number",
    formOptions: { required: false },
  },
  lastTimeEntryDate: {
    title: "Last Time Entry Date",
    field: "lastTimeEntryDate",
    formOptions: { required: false },
  },
  statusDuringDateRange: {
    title: "Status During Date Range",
    field: "statusDuringDateRange",
    formOptions: { required: false },
  },
  totalBilledRedeemedUSD: {
    title: "Total Billed Redeemed USD",
    field: "totalBilledRedeemedUSD",
    type: "number",
    formOptions: { required: false },
  },
  engagementAmountInUSD: {
    title: "Engagement Amount in USD",
    field: "engagementAmountInUSD",
    type: "number",
    formOptions: { required: false },
  },
  totalActualHours: {
    title: "Total Actual Hours",
    field: "totalActualHours",
    type: "number",
    formOptions: { required: false },
  },
  hoursBasedForecastRevenueOfCurrentQtr: {
    title: "Hours Based Forecast Revenue of Current Qtr",
    field: "hoursBasedForecastRevenueOfCurrentQtr",
    type: "number",
    formOptions: { required: false },
  },
  hoursBasedForecastRevenueOfCurrentQtrUSD: {
    title: "Hours Based Forecast Revenue of Current Qtr USD",
    field: "hoursBasedForecastRevenueOfCurrentQtrUSD",
    type: "number",
    formOptions: { required: false },
  },
  plannedHrsForQtr: {
    title: "Planned Hrs for Qtr",
    field: "plannedHrsForQtr",
    type: "number",
    formOptions: { required: false },
  },
  program: {
    title: "Program",
    field: "program",
    formOptions: { required: false },
  },
  projectProfile: {
    title: "Project Profile",
    field: "projectProfile",
    formOptions: { required: false },
  },
  ssm: {
    title: "SSM",
    field: "ssm",
    formOptions: { required: false },
  },
  projectManager: {
    title: "Project Manager",
    field: "projectManager",
    formOptions: { required: false },
    equivalentFields: { pls: "projectManager", costs: "projectManager" },
  },
  pmAccess: {
    title: "PM Access",
    field: "pmAccess",
    formOptions: {
      required: false,
      options: {
        dataSource: "people",
        addBlank: true,
        accessorFunction: (dataSource: any) =>
          dataSource?.map((row: any) => {
            return { value: row.id, label: `${row.firstName} ${row.lastName}` };
          }) ?? [],
      },
    },
    type: "select",
  },
  dmAccess: {
    title: "DM Access",
    field: "dmAccess",
    formOptions: {
      required: false,
      options: {
        dataSource: "people",
        addBlank: true,
        accessorFunction: (dataSource: any) =>
          dataSource?.map((row: any) => {
            return { value: row.id, label: `${row.firstName} ${row.lastName}` };
          }) ?? [],
      },
    },
    type: "select",
  },
  engagementClosedDate: {
    title: "Engagement Closed Date",
    field: "engagementClosedDate",
    formOptions: { required: false },
  },
  agreementNumber: {
    title: "Agreement Number",
    field: "agreementNumber",
    formOptions: { required: false },
  },
  opportunityNumber: {
    title: "Opportunity Number",
    field: "opportunityNumber",
    formOptions: { required: false },
  },
  psoSubRegion2: {
    title: "PSO Sub Region2",
    field: "psoSubRegion2",
    formOptions: { required: false },
  },
  revenueRecognized: {
    title: "Revenue Recognized",
    field: "revenueRecognized",
    type: "number",
    formOptions: { required: false },
  },
  unbilled: {
    title: "Unbilled",
    field: "unbilled",
    type: "number",
    formOptions: { required: false },
  },
  ...common,
  audit: {
    title: "Audit",
    field: "audit",
    type: "text",
    excludeFromForm: false,
    excludeFromDataView: true,
    excludeFromChartBuilder: true,
    formOptions: { required: false },
    enableGlobalFilter: false,
  },
  createdAt: {
    title: "Created At",
    field: "createdAt",
    type: "date",
    formatFunction: formatDateString,
    excludeFromForm: false,
    formOptions: { required: false },
  },
  updatedAt: {
    title: "Updated At",
    field: "updatedAt",
    type: "date",
    formatFunction: formatDateString,
    excludeFromForm: false,
    formOptions: { required: false },
  },
};

export default mrrSchema;
