import { Paper, useMantineTheme } from "@mantine/core";
import React, { Fragment, useMemo } from "react";
import {
  ScatterChart as ReScatterChart,
  Scatter,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
  ZAxis,
} from "recharts";
import {
  CustomTooltip,
  formatNumericAxis,
  getChartColors,
} from "./ChartUtilities";
import { linearRegression } from "../functions/regressionFunctions";

function regressionFormula(data: any, values: any) {
  if (!values[0] || !values[1]) return { slope: 0, intercept: 0 };
  const x: number[] = [];
  const y: number[] = [];

  let max = 0;

  for (let row of data) {
    x.push(row[values[0]]);
    y.push(row[values[1]]);
    if (row[values[0]] > max) {
      max = row[values[0]];
    }
  }

  const { slope, intercept } = linearRegression(x, y);

  return { slope, intercept, max };
}

export default function ScatterChart(props: any) {
  const {
    data,
    // dataLabels,
    values,
    yAxisWidth = 60,
    // dataLabelFontSize,
    legend,
    yAxisFontSize,
    formatFunction,
    rows,
    schema,
    grid,
    lineOfBestFit,
    xAxisHeight,
    disableTooltip,
    comparisonFieldString,
  } = props;
  const theme = useMantineTheme();
  const COLORS = getChartColors(theme);

  const sortedData = useMemo(() => {
    return data.sort((a: any, b: any) => b[values[0]] - a[values[1]]);
  }, [data, values]);

  const finalData = useMemo(() => {
    if (rows[0]) {
      //@ts-ignore
      return Object.groupBy(sortedData, (row: any) => row[rows[0]]);
    } else {
      return sortedData;
    }
  }, [sortedData, rows]);

  const scatters = useMemo(() => {
    // const max = sortedData[0][values[0]];
    if (rows[0]) {
      // let overallMax = 0;
      return Object.keys(finalData).map((key, index) => {
        // const { slope, intercept } = regressionFormula(finalData[key], values);

        // const bestFitLine = [
        //   { [values[1]]: intercept, [values[0]]: 0 }, //@ts-ignore
        //   { [values[1]]: max * slope + intercept, [values[0]]: max },
        // ];
        return (
          <Fragment key={key}>
            <Scatter
              name={key}
              data={finalData[key]}
              fill={COLORS[index]}
              line={
                lineOfBestFit
                  ? {
                      stroke: COLORS[index],
                      strokeWidth: 1,
                    }
                  : undefined
              }
              lineType={lineOfBestFit ? "fitting" : undefined}
            />
            {/* <Scatter
              data={bestFitLine}
              line={{
                stroke: COLORS[index],
                strokeWidth: 1,
              }}
              shape={() => <></>}
            /> */}
          </Fragment>
        );
      });
    } else {
      // const { slope, intercept } = regressionFormula(finalData, values);
      // const bestFitLine = [
      //   { [values[1]]: intercept, [values[0]]: 0 }, //@ts-ignore
      //   { [values[1]]: max * slope + intercept, [values[0]]: max },
      // ];
      return (
        <>
          <Scatter
            data={finalData}
            fill={theme.colors[theme.primaryColor][7]}
            line={
              lineOfBestFit
                ? {
                    stroke: theme.colors[theme.primaryColor][4],
                    strokeWidth: 1,
                  }
                : undefined
            }
            lineType={lineOfBestFit ? "fitting" : undefined}
          />
          {/* <Scatter
            data={bestFitLine}
            line={{
              stroke: theme.colors[theme.primaryColor][4],
              strokeWidth: 1,
            }}
            shape={() => <></>}
          /> */}
        </>
      );
    }
  }, [finalData, rows, COLORS, theme, lineOfBestFit]);

  return (
    <ResponsiveContainer width="100%" height="95%">
      <ReScatterChart
        width={100}
        height={100}
        // margin={{
        //   top: 20,
        //   right: 20,
        //   bottom: 20,
        //   left: 20,
        // }}
      >
        {/* <CartesianGrid strokeDasharray="3 3" /> */}
        <XAxis
          dataKey={values[0]}
          type="number"
          label={{
            value: values[0] ? schema[values[0]].title : "",
            position: "insideBottomCenter",
            dy: 20,
          }}
          tickFormatter={(value) => formatNumericAxis(value, formatFunction)}
          tick={{
            fontSize: `${yAxisFontSize}em`,
            fill: theme.colorScheme === "dark" ? "#ffffff" : "#666",
          }}
          height={xAxisHeight}
        />
        <YAxis
          dataKey={values[1]}
          type="number"
          width={yAxisWidth}
          label={{
            value: values[1] ? schema[values[1]].title : "",
            position: "insideCenter",
            angle: -90,
            dx: -30,
          }}
          tickFormatter={(value) => formatNumericAxis(value, formatFunction)}
          tick={{
            fontSize: `${yAxisFontSize}em`,
            fill: theme.colorScheme === "dark" ? "#ffffff" : "#666",
          }}
        />
        {values[2] && (
          <ZAxis
            type="number"
            dataKey={values[2]}
            range={[10, 500]}
            name={schema[values[2]].title}
          />
        )}
        {!disableTooltip && (
          <Tooltip
            cursor={false}
            content={
              <CustomTooltip
                formatFunction={formatFunction}
                comparisonFieldString={comparisonFieldString}
                includeTotal={false}
              />
            }
          />
        )}
        {grid && (
          <CartesianGrid
            strokeDasharray="3 3"
            stroke={
              theme.colorScheme === "dark"
                ? theme.colors.gray[7]
                : theme.colors.gray[4]
            }
          />
        )}
        {legend && <Legend verticalAlign="bottom" />}
        {scatters}
      </ReScatterChart>
    </ResponsiveContainer>
  );
}
