/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getSystemPreference = /* GraphQL */ `
  query GetSystemPreference($ownerEmail: String!) {
    getSystemPreference(ownerEmail: $ownerEmail) {
      ownerEmail
      tenantAccessString
      preferenceObject
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listSystemPreferences = /* GraphQL */ `
  query ListSystemPreferences(
    $ownerEmail: String
    $filter: ModelSystemPreferenceFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSystemPreferences(
      ownerEmail: $ownerEmail
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        ownerEmail
        tenantAccessString
        preferenceObject
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getUserPreference = /* GraphQL */ `
  query GetUserPreference($ownerEmail: String!) {
    getUserPreference(ownerEmail: $ownerEmail) {
      ownerEmail
      preferenceObject
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listUserPreferences = /* GraphQL */ `
  query ListUserPreferences(
    $ownerEmail: String
    $filter: ModelUserPreferenceFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUserPreferences(
      ownerEmail: $ownerEmail
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        ownerEmail
        preferenceObject
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getIntegration = /* GraphQL */ `
  query GetIntegration($id: ID!) {
    getIntegration(id: $id) {
      id
      module
      name
      status
      dataLastUpdated
      system
      tenantAccessString
      tenantUserAccessString
      dataObject
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listIntegrations = /* GraphQL */ `
  query ListIntegrations(
    $filter: ModelIntegrationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIntegrations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        module
        name
        status
        active
        dataLastUpdated
        system
        tenantAccessString
        tenantUserAccessString
        dataObject
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getDashboard = /* GraphQL */ `
  query GetDashboard($id: ID!) {
    getDashboard(id: $id) {
      id
      name
      order
      tenantAccessString
      userTypeAccessStrings
      configuration
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listDashboards = /* GraphQL */ `
  query ListDashboards(
    $filter: ModelDashboardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDashboards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        order
        tenantAccessString
        userTypeAccessStrings
        configuration
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getTenant = /* GraphQL */ `
  query GetTenant($id: ID!) {
    getTenant(id: $id) {
      id
      tenantAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      stages {
        items {
          id
          tenantAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          order
          name
          createdAt
          updatedAt
          tenantStagesId
          owner
        }
        nextToken
      }
      verticals {
        items {
          id
          tenantAccessString
          verticalAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          createdAt
          updatedAt
          tenantVerticalsId
          owner
        }
        nextToken
      }
      accounts {
        items {
          id
          tenantAccessString
          accountAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          createdAt
          updatedAt
          tenantAccountsId
          owner
        }
        nextToken
      }
      roleTypes {
        items {
          id
          tenantAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          createdAt
          updatedAt
          tenantRoleTypesId
          owner
        }
        nextToken
      }
      people {
        items {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          firstName
          lastName
          email
          position
          location
          currentlyActive
          skills
          createdAt
          updatedAt
          tenantPeopleId
          owner
        }
        nextToken
      }
      periods {
        items {
          id
          tenantAccessString
          name
          period
          audit
          createdAt
          updatedAt
          tenantPeriodsId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listTenants = /* GraphQL */ `
  query ListTenants(
    $filter: ModelTenantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTenants(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantAccessString
        dataSource
        system
        integrationSystemId
        integrationId
        audit
        period
        name
        stages {
          nextToken
        }
        verticals {
          nextToken
        }
        accounts {
          nextToken
        }
        roleTypes {
          nextToken
        }
        people {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPeriod = /* GraphQL */ `
  query GetPeriod($id: ID!) {
    getPeriod(id: $id) {
      id
      tenantAccessString
      name
      period
      audit
      tenant {
        id
        tenantAccessString
        dataSource
        system
        integrationSystemId
        integrationId
        audit
        period
        name
        stages {
          nextToken
        }
        verticals {
          nextToken
        }
        accounts {
          nextToken
        }
        roleTypes {
          nextToken
        }
        people {
          nextToken
        }
        periods {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      tenantPeriodsId
    }
  }
`;
export const listPeriods = /* GraphQL */ `
  query ListPeriods(
    $filter: ModelPeriodFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPeriods(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantAccessString
        name
        period
        audit
        tenant {
          id
          tenantAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        tenantPeriodsId
      }
      nextToken
    }
  }
`;
export const getVertical = /* GraphQL */ `
  query GetVertical($id: ID!) {
    getVertical(id: $id) {
      id
      tenantAccessString
      verticalAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      tenant {
        id
        tenantAccessString
        dataSource
        system
        integrationSystemId
        integrationId
        audit
        period
        name
        stages {
          nextToken
        }
        verticals {
          nextToken
        }
        accounts {
          nextToken
        }
        roleTypes {
          nextToken
        }
        people {
          nextToken
        }
        periods {
          nextToken
        }
        createdAt
        updatedAt
      }
      programmes {
        items {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          createdAt
          updatedAt
          verticalProgrammesId
          accountProgrammesId
          owner
        }
        nextToken
      }
      risks {
        items {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          description
          actionOwner
          status
          impactDate
          cause
          effect
          impactDescription
          impactType
          riskResponse
          actionMitigation
          targetResolutionDate
          probability
          impact
          estimatedImpactCost
          escalatedToPortfolio
          detailedUpdate
          dateRaised
          dateOfNextReview
          createdAt
          updatedAt
          verticalRisksId
          accountRisksId
          programmeRisksId
          projectRisksId
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      tenantVerticalsId
      owner
    }
  }
`;
export const listVerticals = /* GraphQL */ `
  query ListVerticals(
    $filter: ModelVerticalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVerticals(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantAccessString
        verticalAccessString
        dataSource
        system
        integrationSystemId
        integrationId
        audit
        period
        name
        tenant {
          id
          tenantAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          createdAt
          updatedAt
        }
        programmes {
          nextToken
        }
        risks {
          nextToken
        }
        createdAt
        updatedAt
        tenantVerticalsId
        owner
      }
      nextToken
    }
  }
`;
export const getAccount = /* GraphQL */ `
  query GetAccount($id: ID!) {
    getAccount(id: $id) {
      id
      tenantAccessString
      accountAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      tenant {
        id
        tenantAccessString
        dataSource
        system
        integrationSystemId
        integrationId
        audit
        period
        name
        stages {
          nextToken
        }
        verticals {
          nextToken
        }
        accounts {
          nextToken
        }
        roleTypes {
          nextToken
        }
        people {
          nextToken
        }
        periods {
          nextToken
        }
        createdAt
        updatedAt
      }
      programmes {
        items {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          createdAt
          updatedAt
          verticalProgrammesId
          accountProgrammesId
          owner
        }
        nextToken
      }
      pipelines {
        items {
          id
          tenantAccessString
          accountAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          amount
          closeDate
          closedNotes
          closingDate
          currency
          dealName
          description
          grossMarginLength
          grossMarginPerMonth
          incomeType
          intro
          managingPartner
          numberOfDeliveryDays
          opportunityLead
          pipeline
          serviceGoLiveDate
          sourceOfferingOpportunity
          totalGrossProfitValue
          totalRevenueValue
          typeOfSale
          createdAt
          updatedAt
          accountPipelinesId
          stagePipelinesId
          owner
        }
        nextToken
      }
      risks {
        items {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          description
          actionOwner
          status
          impactDate
          cause
          effect
          impactDescription
          impactType
          riskResponse
          actionMitigation
          targetResolutionDate
          probability
          impact
          estimatedImpactCost
          escalatedToPortfolio
          detailedUpdate
          dateRaised
          dateOfNextReview
          createdAt
          updatedAt
          verticalRisksId
          accountRisksId
          programmeRisksId
          projectRisksId
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      tenantAccountsId
      owner
    }
  }
`;
export const listAccounts = /* GraphQL */ `
  query ListAccounts(
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantAccessString
        accountAccessString
        dataSource
        system
        integrationSystemId
        integrationId
        audit
        period
        name
        tenant {
          id
          tenantAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          createdAt
          updatedAt
        }
        programmes {
          nextToken
        }
        pipelines {
          nextToken
        }
        risks {
          nextToken
        }
        createdAt
        updatedAt
        tenantAccountsId
        owner
      }
      nextToken
    }
  }
`;
export const getProgramme = /* GraphQL */ `
  query GetProgramme($id: ID!) {
    getProgramme(id: $id) {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      account {
        id
        tenantAccessString
        accountAccessString
        dataSource
        system
        integrationSystemId
        integrationId
        audit
        period
        name
        tenant {
          id
          tenantAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          createdAt
          updatedAt
        }
        programmes {
          nextToken
        }
        pipelines {
          nextToken
        }
        risks {
          nextToken
        }
        createdAt
        updatedAt
        tenantAccountsId
        owner
      }
      vertical {
        id
        tenantAccessString
        verticalAccessString
        dataSource
        system
        integrationSystemId
        integrationId
        audit
        period
        name
        tenant {
          id
          tenantAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          createdAt
          updatedAt
        }
        programmes {
          nextToken
        }
        risks {
          nextToken
        }
        createdAt
        updatedAt
        tenantVerticalsId
        owner
      }
      projects {
        items {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          tcv
          margin
          workstream
          currency
          budget
          spend
          country
          status
          startDate
          endDate
          revenueSold
          costSold
          revenueActual
          costActual
          consumed
          createdAt
          updatedAt
          programmeProjectsId
          regionProjectsId
          owner
        }
        nextToken
      }
      csats {
        items {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          score
          comments
          date
          createdAt
          updatedAt
          programmeCsatsId
          owner
        }
        nextToken
      }
      risks {
        items {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          description
          actionOwner
          status
          impactDate
          cause
          effect
          impactDescription
          impactType
          riskResponse
          actionMitigation
          targetResolutionDate
          probability
          impact
          estimatedImpactCost
          escalatedToPortfolio
          detailedUpdate
          dateRaised
          dateOfNextReview
          createdAt
          updatedAt
          verticalRisksId
          accountRisksId
          programmeRisksId
          projectRisksId
          owner
        }
        nextToken
      }
      weeklyUpdates {
        items {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          score
          comments
          date
          audit
          createdAt
          updatedAt
          programmeWeeklyUpdatesId
          owner
        }
        nextToken
      }
      mrrs {
        items {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          fiscalYear
          geo
          region
          subRegion
          territory
          engagementName
          engagementId
          engagementStatus
          engagementType
          businessUnit
          billToCustomer
          endCustomer
          budgetedHoursTotal
          plannedHoursTotal
          createdOn
          paymentType
          revRecType
          billingSKU
          engagementAmount
          billingCurrency
          totalBilledRedeemed
          remainingAmount
          operationAnalysts
          timeApprover
          billedRedeemedByDateRange
          remainingForecastByDateRange
          forecastHrsThisQtr
          forecastRevenueOfCurrentQtr
          forecastRevenueOfCurrentQtrUSD
          totalBillableHoursPriorQtrEnd
          totalBillableHoursThisQtr
          mgmtRevAsOfPriorQtr
          mgmtRevAsOfPriorQtrUSD
          qtrManagementRevenueToDate
          qtrManagementRevenueToDateUSD
          internalHoursQTD
          subbingHoursQTD
          expensesBilledRedeemedByDateRange
          contractStatus
          poNumber
          billedRedeemedByDateRangeUSD
          lastTimeEntryDate
          statusDuringDateRange
          totalBilledRedeemedUSD
          engagementAmountInUSD
          totalActualHours
          hoursBasedForecastRevenueOfCurrentQtr
          hoursBasedForecastRevenueOfCurrentQtrUSD
          plannedHrsForQtr
          program
          projectProfile
          ssm
          projectManager
          engagementClosedDate
          agreementNumber
          opportunityNumber
          psoSubRegion2
          revenueRecognized
          unbilled
          audit
          period
          createdAt
          updatedAt
          programmeMrrsId
          owner
        }
        nextToken
      }
      costs {
        items {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          geo
          region
          subRegion
          engagementNumber
          engagementName
          revRecType
          paymentType
          engagementStatus
          engagementCreatedOn
          engagementAmount
          engagementCurrency
          roleName
          billingRateForRole
          budgetedHours
          costRateAverageForResources
          budgetedCostForRole
          plannedHours
          actualHours
          internalPlannedCost
          internalActualCost
          partnerPlannedCostForRole
          partnerActualCostForRole
          audit
          period
          createdAt
          updatedAt
          programmeCostsId
          owner
        }
        nextToken
      }
      pls {
        items {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          engId
          engagementName
          status
          gar
          projectManager
          subcontractor
          lastInvoice
          budgetMarginType
          impactedByAllInCostChange
          deAuditMessageUSD
          billingCurrency
          budgetComments
          budgetGar
          csatComments
          csatGar
          engagementStatus
          engagementType
          fisYear2QtrName
          lastBillingEntry
          mitigationAction
          projectGarComments
          geo
          region
          subRegion
          scheduleComments
          scheduleGar
          deliveryManager
          deAuditResultsUSD
          revenueSoldUSD
          revenueRecognizedUSD
          billedRedeemedUSD
          costSoldUSD
          marginSold
          revisedCostSoldUSD
          revisedMarginSold
          percentOfHoursBeforeAllInChange
          revenueForecastExitUSD
          costForecastExitUSD
          marginForecastExit
          revenuePlannedUSD
          costPlannedUSD
          marginPlanned
          revenueActualUSD
          costActualUSD
          marginActual
          hoursSold
          hoursPlanned
          hoursActual
          percentCompleteHoursSold
          revenueSoldBC
          maxExchangeRateUsed
          billedRedeemedBC
          costForecastExitBC
          costSoldBC
          revenueForecastExitBC
          revenueRecognizedBC
          audit
          period
          createdAt
          updatedAt
          programmePlsId
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      verticalProgrammesId
      accountProgrammesId
      owner
    }
  }
`;
export const listProgrammes = /* GraphQL */ `
  query ListProgrammes(
    $filter: ModelProgrammeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProgrammes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantAccessString
        verticalAccessString
        accountAccessString
        programmeAccessString
        dataSource
        system
        integrationSystemId
        integrationId
        audit
        period
        name
        account {
          id
          tenantAccessString
          accountAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          createdAt
          updatedAt
          tenantAccountsId
          owner
        }
        vertical {
          id
          tenantAccessString
          verticalAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          createdAt
          updatedAt
          tenantVerticalsId
          owner
        }
        projects {
          nextToken
        }
        csats {
          nextToken
        }
        risks {
          nextToken
        }
        weeklyUpdates {
          nextToken
        }
        mrrs {
          nextToken
        }
        costs {
          nextToken
        }
        pls {
          nextToken
        }
        createdAt
        updatedAt
        verticalProgrammesId
        accountProgrammesId
        owner
      }
      nextToken
    }
  }
`;
export const getProject = /* GraphQL */ `
  query GetProject($id: ID!) {
    getProject(id: $id) {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      deliveryManagers {
        items {
          id
          projectID
          personID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      projectManagers {
        items {
          id
          projectID
          personID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      name
      tcv
      margin
      workstream
      currency
      budget
      spend
      country
      status
      startDate
      endDate
      revenueSold
      costSold
      revenueActual
      costActual
      consumed
      region {
        id
        continent
        subregion
        name
        projects {
          nextToken
        }
        createdAt
        updatedAt
      }
      programme {
        id
        tenantAccessString
        verticalAccessString
        accountAccessString
        programmeAccessString
        dataSource
        system
        integrationSystemId
        integrationId
        audit
        period
        name
        account {
          id
          tenantAccessString
          accountAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          createdAt
          updatedAt
          tenantAccountsId
          owner
        }
        vertical {
          id
          tenantAccessString
          verticalAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          createdAt
          updatedAt
          tenantVerticalsId
          owner
        }
        projects {
          nextToken
        }
        csats {
          nextToken
        }
        risks {
          nextToken
        }
        weeklyUpdates {
          nextToken
        }
        mrrs {
          nextToken
        }
        costs {
          nextToken
        }
        pls {
          nextToken
        }
        createdAt
        updatedAt
        verticalProgrammesId
        accountProgrammesId
        owner
      }
      tasks {
        items {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          complete
          createdAt
          updatedAt
          projectTasksId
          owner
        }
        nextToken
      }
      statements {
        items {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          agreement
          description
          date
          contractType
          days
          durationDays
          location
          valueCredits
          valueUsd
          consumed
          workPackages
          paid
          stage
          completedDate
          createdAt
          updatedAt
          projectStatementsId
          pipelineSowRecordsId
          owner
        }
        nextToken
      }
      projectRoles {
        items {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          fte
          description
          createdAt
          updatedAt
          projectProjectRolesId
          owner
        }
        nextToken
      }
      risks {
        items {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          description
          actionOwner
          status
          impactDate
          cause
          effect
          impactDescription
          impactType
          riskResponse
          actionMitigation
          targetResolutionDate
          probability
          impact
          estimatedImpactCost
          escalatedToPortfolio
          detailedUpdate
          dateRaised
          dateOfNextReview
          createdAt
          updatedAt
          verticalRisksId
          accountRisksId
          programmeRisksId
          projectRisksId
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      programmeProjectsId
      regionProjectsId
      owner
    }
  }
`;
export const listProjects = /* GraphQL */ `
  query ListProjects(
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantAccessString
        verticalAccessString
        accountAccessString
        programmeAccessString
        dataSource
        system
        integrationSystemId
        integrationId
        audit
        period
        deliveryManagers {
          nextToken
        }
        projectManagers {
          nextToken
        }
        name
        tcv
        margin
        workstream
        currency
        budget
        spend
        country
        status
        startDate
        endDate
        revenueSold
        costSold
        revenueActual
        costActual
        consumed
        region {
          id
          continent
          subregion
          name
          createdAt
          updatedAt
        }
        programme {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          createdAt
          updatedAt
          verticalProgrammesId
          accountProgrammesId
          owner
        }
        tasks {
          nextToken
        }
        statements {
          nextToken
          items {
            id
            agreement
            invoices {
              nextToken
            }
          }
        }
        projectRoles {
          nextToken
        }
        risks {
          nextToken
        }
        createdAt
        updatedAt
        programmeProjectsId
        regionProjectsId
        owner
      }
      nextToken
    }
  }
`;
export const getRisk = /* GraphQL */ `
  query GetRisk($id: ID!) {
    getRisk(id: $id) {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      description
      actionOwner
      status
      impactDate
      cause
      effect
      impactDescription
      impactType
      riskResponse
      actionMitigation
      targetResolutionDate
      probability
      impact
      estimatedImpactCost
      escalatedToPortfolio
      detailedUpdate
      dateRaised
      dateOfNextReview
      riskOwners {
        items {
          id
          riskID
          personID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      vertical {
        id
        tenantAccessString
        verticalAccessString
        dataSource
        system
        integrationSystemId
        integrationId
        audit
        period
        name
        tenant {
          id
          tenantAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          createdAt
          updatedAt
        }
        programmes {
          nextToken
        }
        risks {
          nextToken
        }
        createdAt
        updatedAt
        tenantVerticalsId
        owner
      }
      account {
        id
        tenantAccessString
        accountAccessString
        dataSource
        system
        integrationSystemId
        integrationId
        audit
        period
        name
        tenant {
          id
          tenantAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          createdAt
          updatedAt
        }
        programmes {
          nextToken
        }
        pipelines {
          nextToken
        }
        risks {
          nextToken
        }
        createdAt
        updatedAt
        tenantAccountsId
        owner
      }
      programme {
        id
        tenantAccessString
        verticalAccessString
        accountAccessString
        programmeAccessString
        dataSource
        system
        integrationSystemId
        integrationId
        audit
        period
        name
        account {
          id
          tenantAccessString
          accountAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          createdAt
          updatedAt
          tenantAccountsId
          owner
        }
        vertical {
          id
          tenantAccessString
          verticalAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          createdAt
          updatedAt
          tenantVerticalsId
          owner
        }
        projects {
          nextToken
        }
        csats {
          nextToken
        }
        risks {
          nextToken
        }
        weeklyUpdates {
          nextToken
        }
        mrrs {
          nextToken
        }
        costs {
          nextToken
        }
        pls {
          nextToken
        }
        createdAt
        updatedAt
        verticalProgrammesId
        accountProgrammesId
        owner
      }
      project {
        id
        tenantAccessString
        verticalAccessString
        accountAccessString
        programmeAccessString
        dataSource
        system
        integrationSystemId
        integrationId
        audit
        period
        deliveryManagers {
          nextToken
        }
        projectManagers {
          nextToken
        }
        name
        tcv
        margin
        workstream
        currency
        budget
        spend
        country
        status
        startDate
        endDate
        revenueSold
        costSold
        revenueActual
        costActual
        consumed
        region {
          id
          continent
          subregion
          name
          createdAt
          updatedAt
        }
        programme {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          createdAt
          updatedAt
          verticalProgrammesId
          accountProgrammesId
          owner
        }
        tasks {
          nextToken
        }
        statements {
          nextToken
        }
        projectRoles {
          nextToken
        }
        risks {
          nextToken
        }
        createdAt
        updatedAt
        programmeProjectsId
        regionProjectsId
        owner
      }
      createdAt
      updatedAt
      verticalRisksId
      accountRisksId
      programmeRisksId
      projectRisksId
      owner
    }
  }
`;
export const listRisks = /* GraphQL */ `
  query ListRisks(
    $filter: ModelRiskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRisks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantAccessString
        verticalAccessString
        accountAccessString
        programmeAccessString
        dataSource
        system
        integrationSystemId
        integrationId
        audit
        period
        name
        description
        actionOwner
        status
        impactDate
        cause
        effect
        impactDescription
        impactType
        riskResponse
        actionMitigation
        targetResolutionDate
        probability
        impact
        estimatedImpactCost
        escalatedToPortfolio
        detailedUpdate
        dateRaised
        dateOfNextReview
        riskOwners {
          nextToken
        }
        vertical {
          id
          tenantAccessString
          verticalAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          createdAt
          updatedAt
          tenantVerticalsId
          owner
        }
        account {
          id
          tenantAccessString
          accountAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          createdAt
          updatedAt
          tenantAccountsId
          owner
        }
        programme {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          createdAt
          updatedAt
          verticalProgrammesId
          accountProgrammesId
          owner
        }
        project {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          tcv
          margin
          workstream
          currency
          budget
          spend
          country
          status
          startDate
          endDate
          revenueSold
          costSold
          revenueActual
          costActual
          consumed
          createdAt
          updatedAt
          programmeProjectsId
          regionProjectsId
          owner
        }
        createdAt
        updatedAt
        verticalRisksId
        accountRisksId
        programmeRisksId
        projectRisksId
        owner
      }
      nextToken
    }
  }
`;
export const getTask = /* GraphQL */ `
  query GetTask($id: ID!) {
    getTask(id: $id) {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      complete
      assignees {
        items {
          id
          taskID
          personID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      project {
        id
        tenantAccessString
        verticalAccessString
        accountAccessString
        programmeAccessString
        dataSource
        system
        integrationSystemId
        integrationId
        audit
        period
        deliveryManagers {
          nextToken
        }
        projectManagers {
          nextToken
        }
        name
        tcv
        margin
        workstream
        currency
        budget
        spend
        country
        status
        startDate
        endDate
        revenueSold
        costSold
        revenueActual
        costActual
        consumed
        region {
          id
          continent
          subregion
          name
          createdAt
          updatedAt
        }
        programme {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          createdAt
          updatedAt
          verticalProgrammesId
          accountProgrammesId
          owner
        }
        tasks {
          nextToken
        }
        statements {
          nextToken
        }
        projectRoles {
          nextToken
        }
        risks {
          nextToken
        }
        createdAt
        updatedAt
        programmeProjectsId
        regionProjectsId
        owner
      }
      createdAt
      updatedAt
      projectTasksId
      owner
    }
  }
`;
export const listTasks = /* GraphQL */ `
  query ListTasks(
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTasks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantAccessString
        verticalAccessString
        accountAccessString
        programmeAccessString
        dataSource
        system
        integrationSystemId
        integrationId
        audit
        period
        name
        type
        complete
        status
        completedDate
        startDate
        endDate
        category
        assignees {
          nextToken
          items {
            id
            person {
              id
              firstName
              lastName
            }
          }
        }
        project {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          tcv
          margin
          workstream
          currency
          budget
          spend
          country
          status
          startDate
          endDate
          revenueSold
          costSold
          revenueActual
          costActual
          consumed
          createdAt
          updatedAt
          programmeProjectsId
          regionProjectsId
          owner
        }
        createdAt
        updatedAt
        projectTasksId
        owner
      }
      nextToken
    }
  }
`;
export const getCsat = /* GraphQL */ `
  query GetCsat($id: ID!) {
    getCsat(id: $id) {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      score
      comments
      date
      programme {
        id
        tenantAccessString
        verticalAccessString
        accountAccessString
        programmeAccessString
        dataSource
        system
        integrationSystemId
        integrationId
        audit
        period
        name
        account {
          id
          tenantAccessString
          accountAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          createdAt
          updatedAt
          tenantAccountsId
          owner
        }
        vertical {
          id
          tenantAccessString
          verticalAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          createdAt
          updatedAt
          tenantVerticalsId
          owner
        }
        projects {
          nextToken
        }
        csats {
          nextToken
        }
        risks {
          nextToken
        }
        weeklyUpdates {
          nextToken
        }
        mrrs {
          nextToken
        }
        costs {
          nextToken
        }
        pls {
          nextToken
        }
        createdAt
        updatedAt
        verticalProgrammesId
        accountProgrammesId
        owner
      }
      createdAt
      updatedAt
      programmeCsatsId
      owner
    }
  }
`;
export const listCsats = /* GraphQL */ `
  query ListCsats(
    $filter: ModelCsatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCsats(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantAccessString
        verticalAccessString
        accountAccessString
        programmeAccessString
        dataSource
        system
        integrationSystemId
        integrationId
        audit
        period
        score
        comments
        date
        programme {
          id
          account {
            name
          }
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          createdAt
          updatedAt
          verticalProgrammesId
          accountProgrammesId
          owner
        }
        createdAt
        updatedAt
        programmeCsatsId
        owner
      }
      nextToken
    }
  }
`;
export const getStage = /* GraphQL */ `
  query GetStage($id: ID!) {
    getStage(id: $id) {
      id
      tenant {
        id
        tenantAccessString
        dataSource
        system
        integrationSystemId
        integrationId
        audit
        period
        name
        stages {
          nextToken
        }
        verticals {
          nextToken
        }
        accounts {
          nextToken
        }
        roleTypes {
          nextToken
        }
        people {
          nextToken
        }
        periods {
          nextToken
        }
        createdAt
        updatedAt
      }
      tenantAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      order
      name
      pipelines {
        items {
          id
          tenantAccessString
          accountAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          amount
          closeDate
          closedNotes
          closingDate
          currency
          dealName
          description
          grossMarginLength
          grossMarginPerMonth
          incomeType
          intro
          managingPartner
          numberOfDeliveryDays
          opportunityLead
          pipeline
          serviceGoLiveDate
          sourceOfferingOpportunity
          totalGrossProfitValue
          totalRevenueValue
          typeOfSale
          createdAt
          updatedAt
          accountPipelinesId
          stagePipelinesId
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      tenantStagesId
      owner
    }
  }
`;
export const listStages = /* GraphQL */ `
  query ListStages(
    $filter: ModelStageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenant {
          id
          tenantAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          createdAt
          updatedAt
        }
        tenantAccessString
        dataSource
        system
        integrationSystemId
        integrationId
        audit
        period
        order
        name
        probability
        pipelines {
          nextToken
        }
        createdAt
        updatedAt
        tenantStagesId
        owner
      }
      nextToken
    }
  }
`;
export const getPipeline = /* GraphQL */ `
  query GetPipeline($id: ID!) {
    getPipeline(id: $id) {
      id
      tenantAccessString
      accountAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      amount
      closeDate
      closedNotes
      closingDate
      currency
      dealName
      description
      grossMarginLength
      grossMarginPerMonth
      incomeType
      intro
      managingPartner
      numberOfDeliveryDays
      opportunityLead
      pipeline
      serviceGoLiveDate
      sourceOfferingOpportunity
      totalGrossProfitValue
      totalRevenueValue
      typeOfSale
      stage {
        id
        tenant {
          id
          tenantAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          createdAt
          updatedAt
        }
        tenantAccessString
        dataSource
        system
        integrationSystemId
        integrationId
        audit
        period
        order
        name
        pipelines {
          nextToken
        }
        createdAt
        updatedAt
        tenantStagesId
        owner
      }
      account {
        id
        tenantAccessString
        accountAccessString
        dataSource
        system
        integrationSystemId
        integrationId
        audit
        period
        name
        tenant {
          id
          tenantAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          createdAt
          updatedAt
        }
        programmes {
          nextToken
        }
        pipelines {
          nextToken
        }
        risks {
          nextToken
        }
        createdAt
        updatedAt
        tenantAccountsId
        owner
      }
      sowRecords {
        items {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          agreement
          description
          date
          contractType
          days
          durationDays
          location
          valueCredits
          valueUsd
          consumed
          workPackages
          paid
          stage
          completedDate
          createdAt
          updatedAt
          projectStatementsId
          pipelineSowRecordsId
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      accountPipelinesId
      stagePipelinesId
      owner
    }
  }
`;
export const listPipelines = /* GraphQL */ `
  query ListPipelines(
    $filter: ModelPipelineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPipelines(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantAccessString
        accountAccessString
        dataSource
        system
        integrationSystemId
        integrationId
        audit
        period
        amount
        closeDate
        closedNotes
        closingDate
        currency
        dealName
        description
        grossMarginLength
        grossMarginPerMonth
        incomeType
        intro
        managingPartner
        numberOfDeliveryDays
        opportunityLead
        pipeline
        serviceGoLiveDate
        sourceOfferingOpportunity
        totalGrossProfitValue
        totalRevenueValue
        typeOfSale
        stage {
          id
          tenantAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          order
          name
          probability
          createdAt
          updatedAt
          tenantStagesId
          owner
        }
        account {
          id
          tenantAccessString
          accountAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          createdAt
          updatedAt
          tenantAccountsId
          owner
        }
        vertical {
          id
          tenantAccessString
          verticalAccessString
          audit
          period
          name
          createdAt
          updatedAt
          tenantVerticalsId
          owner
        }
        sowRecords {
          nextToken
        }
        createdAt
        updatedAt
        accountPipelinesId
        stagePipelinesId
        owner
      }
      nextToken
    }
  }
`;
export const getStatement = /* GraphQL */ `
  query GetStatement($id: ID!) {
    getStatement(id: $id) {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      agreement
      description
      date
      contractType
      days
      durationDays
      location
      valueCredits
      valueUsd
      consumed
      workPackages
      paid
      stage
      completedDate
      project {
        id
        tenantAccessString
        verticalAccessString
        accountAccessString
        programmeAccessString
        dataSource
        system
        integrationSystemId
        integrationId
        audit
        period
        deliveryManagers {
          nextToken
        }
        projectManagers {
          nextToken
        }
        name
        tcv
        margin
        workstream
        currency
        budget
        spend
        country
        status
        startDate
        endDate
        revenueSold
        costSold
        revenueActual
        costActual
        consumed
        region {
          id
          continent
          subregion
          name
          createdAt
          updatedAt
        }
        programme {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          createdAt
          updatedAt
          verticalProgrammesId
          accountProgrammesId
          owner
        }
        tasks {
          nextToken
        }
        statements {
          nextToken
        }
        projectRoles {
          nextToken
        }
        risks {
          nextToken
        }
        createdAt
        updatedAt
        programmeProjectsId
        regionProjectsId
        owner
      }
      pipeline {
        id
        tenantAccessString
        accountAccessString
        dataSource
        system
        integrationSystemId
        integrationId
        audit
        period
        amount
        closeDate
        closedNotes
        closingDate
        currency
        dealName
        description
        grossMarginLength
        grossMarginPerMonth
        incomeType
        intro
        managingPartner
        numberOfDeliveryDays
        opportunityLead
        pipeline
        serviceGoLiveDate
        sourceOfferingOpportunity
        totalGrossProfitValue
        totalRevenueValue
        typeOfSale
        stage {
          id
          tenantAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          order
          name
          createdAt
          updatedAt
          tenantStagesId
          owner
        }
        account {
          id
          tenantAccessString
          accountAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          createdAt
          updatedAt
          tenantAccountsId
          owner
        }
        sowRecords {
          nextToken
        }
        createdAt
        updatedAt
        accountPipelinesId
        stagePipelinesId
        owner
      }
      deliverables {
        items {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          artifact
          deliverableOwner
          progress
          startDate
          dueDate
          eta
          actualDate
          comments
          createdAt
          updatedAt
          statementDeliverablesId
          owner
        }
        nextToken
      }
      invoices {
        items {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          invoiceNumber
          amount
          invoiceDate
          reference
          billingAddress
          billingEmail
          VATRegNumber
          supplierNumber
          supplierAddress
          description
          unitPrice
          quantity
          taxAmount
          totalAmount
          dueDate
          paymentTerms
          bankName
          accountName
          accountNumber
          sortCode
          routingNumber
          createdAt
          updatedAt
          statementInvoicesId
          owner
        }
        nextToken
      }
      changes {
        items {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          title
          requesterName
          requesterEmail
          organization
          requestType
          requestTitle
          changeRequestArea
          programName
          requestCategory
          requestDetail
          priority
          effectiveDate
          impactDeltaDays
          requestTypeLocation
          financialImpact
          emergencyRequest
          deliveryApproval
          dateDeliveryTeamApproved
          deliveryTeamApprovalNotes
          sltApproval
          sltApprovalDate
          sltApprovalNotes
          impactStatement
          additionalInfo
          newSow
          pmoAdmin
          customerNotified
          audit
          createdAt
          updatedAt
          statementChangesId
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      projectStatementsId
      pipelineSowRecordsId
      owner
    }
  }
`;
export const listStatements = /* GraphQL */ `
  query ListStatements(
    $filter: ModelStatementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStatements(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantAccessString
        verticalAccessString
        accountAccessString
        programmeAccessString
        dataSource
        system
        integrationSystemId
        integrationId
        audit
        period
        agreement
        description
        date
        contractType
        days
        durationDays
        location
        valueCredits
        valueUsd
        consumed
        workPackages
        paid
        stage
        completedDate
        project {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          tcv
          margin
          workstream
          currency
          budget
          spend
          country
          status
          startDate
          endDate
          revenueSold
          costSold
          revenueActual
          costActual
          consumed
          createdAt
          updatedAt
          programmeProjectsId
          regionProjectsId
          owner
        }
        pipeline {
          id
          tenantAccessString
          accountAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          amount
          closeDate
          closedNotes
          closingDate
          currency
          dealName
          description
          grossMarginLength
          grossMarginPerMonth
          incomeType
          intro
          managingPartner
          numberOfDeliveryDays
          opportunityLead
          pipeline
          serviceGoLiveDate
          sourceOfferingOpportunity
          totalGrossProfitValue
          totalRevenueValue
          typeOfSale
          createdAt
          updatedAt
          accountPipelinesId
          stagePipelinesId
          owner
        }
        deliverables {
          nextToken
        }
        invoices {
          nextToken
        }
        changes {
          nextToken
        }
        createdAt
        updatedAt
        projectStatementsId
        pipelineSowRecordsId
        owner
      }
      nextToken
    }
  }
`;
export const getDeliverable = /* GraphQL */ `
  query GetDeliverable($id: ID!) {
    getDeliverable(id: $id) {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      artifact
      deliverableOwner
      progress
      startDate
      dueDate
      eta
      actualDate
      comments
      sow {
        id
        tenantAccessString
        verticalAccessString
        accountAccessString
        programmeAccessString
        dataSource
        system
        integrationSystemId
        integrationId
        audit
        period
        agreement
        description
        date
        contractType
        days
        durationDays
        location
        valueCredits
        valueUsd
        consumed
        workPackages
        paid
        stage
        completedDate
        project {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          tcv
          margin
          workstream
          currency
          budget
          spend
          country
          status
          startDate
          endDate
          revenueSold
          costSold
          revenueActual
          costActual
          consumed
          createdAt
          updatedAt
          programmeProjectsId
          regionProjectsId
          owner
        }
        pipeline {
          id
          tenantAccessString
          accountAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          amount
          closeDate
          closedNotes
          closingDate
          currency
          dealName
          description
          grossMarginLength
          grossMarginPerMonth
          incomeType
          intro
          managingPartner
          numberOfDeliveryDays
          opportunityLead
          pipeline
          serviceGoLiveDate
          sourceOfferingOpportunity
          totalGrossProfitValue
          totalRevenueValue
          typeOfSale
          createdAt
          updatedAt
          accountPipelinesId
          stagePipelinesId
          owner
        }
        deliverables {
          nextToken
        }
        invoices {
          nextToken
        }
        changes {
          nextToken
        }
        createdAt
        updatedAt
        projectStatementsId
        pipelineSowRecordsId
        owner
      }
      createdAt
      updatedAt
      statementDeliverablesId
      owner
    }
  }
`;
export const listDeliverables = /* GraphQL */ `
  query ListDeliverables(
    $filter: ModelDeliverableFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeliverables(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantAccessString
        verticalAccessString
        accountAccessString
        programmeAccessString
        dataSource
        system
        integrationSystemId
        integrationId
        audit
        period
        artifact
        deliverableOwner
        progress
        startDate
        dueDate
        eta
        actualDate
        comments
        sow {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          agreement
          description
          date
          contractType
          days
          durationDays
          location
          valueCredits
          valueUsd
          consumed
          workPackages
          paid
          stage
          completedDate
          createdAt
          updatedAt
          projectStatementsId
          pipelineSowRecordsId
          owner
        }
        createdAt
        updatedAt
        statementDeliverablesId
        owner
      }
      nextToken
    }
  }
`;
export const getInvoice = /* GraphQL */ `
  query GetInvoice($id: ID!) {
    getInvoice(id: $id) {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      invoiceNumber
      amount
      invoiceDate
      reference
      billingAddress
      billingEmail
      VATRegNumber
      supplierNumber
      supplierAddress
      description
      unitPrice
      quantity
      taxAmount
      totalAmount
      dueDate
      paymentTerms
      bankName
      accountName
      accountNumber
      sortCode
      routingNumber
      sow {
        id
        tenantAccessString
        verticalAccessString
        accountAccessString
        programmeAccessString
        dataSource
        system
        integrationSystemId
        integrationId
        audit
        period
        agreement
        description
        date
        contractType
        days
        durationDays
        location
        valueCredits
        valueUsd
        consumed
        workPackages
        paid
        stage
        completedDate
        project {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          tcv
          margin
          workstream
          currency
          budget
          spend
          country
          status
          startDate
          endDate
          revenueSold
          costSold
          revenueActual
          costActual
          consumed
          createdAt
          updatedAt
          programmeProjectsId
          regionProjectsId
          owner
        }
        pipeline {
          id
          tenantAccessString
          accountAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          amount
          closeDate
          closedNotes
          closingDate
          currency
          dealName
          description
          grossMarginLength
          grossMarginPerMonth
          incomeType
          intro
          managingPartner
          numberOfDeliveryDays
          opportunityLead
          pipeline
          serviceGoLiveDate
          sourceOfferingOpportunity
          totalGrossProfitValue
          totalRevenueValue
          typeOfSale
          createdAt
          updatedAt
          accountPipelinesId
          stagePipelinesId
          owner
        }
        deliverables {
          nextToken
        }
        invoices {
          nextToken
        }
        changes {
          nextToken
        }
        createdAt
        updatedAt
        projectStatementsId
        pipelineSowRecordsId
        owner
      }
      createdAt
      updatedAt
      statementInvoicesId
      owner
    }
  }
`;
export const listInvoices = /* GraphQL */ `
  query ListInvoices(
    $filter: ModelInvoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvoices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantAccessString
        verticalAccessString
        accountAccessString
        programmeAccessString
        dataSource
        system
        integrationSystemId
        integrationId
        audit
        period
        invoiceNumber
        amount
        invoiceDate
        reference
        billingAddress
        billingEmail
        VATRegNumber
        supplierNumber
        supplierAddress
        description
        unitPrice
        quantity
        taxAmount
        totalAmount
        dueDate
        paymentTerms
        bankName
        accountName
        accountNumber
        sortCode
        routingNumber
        paid
        sow {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          agreement
          description
          date
          contractType
          days
          durationDays
          location
          valueCredits
          valueUsd
          consumed
          workPackages
          paid
          stage
          completedDate
          createdAt
          updatedAt
          projectStatementsId
          pipelineSowRecordsId
          project {
            id
            name
          }
          owner
        }
        timesheet {
          id
        }
        timesheetInvoicesId
        createdAt
        updatedAt
        statementInvoicesId
        owner
      }
      nextToken
    }
  }
`;
export const getRegion = /* GraphQL */ `
  query GetRegion($id: ID!) {
    getRegion(id: $id) {
      id
      continent
      subregion
      name
      projects {
        items {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          tcv
          margin
          workstream
          currency
          budget
          spend
          country
          status
          startDate
          endDate
          revenueSold
          costSold
          revenueActual
          costActual
          consumed
          createdAt
          updatedAt
          programmeProjectsId
          regionProjectsId
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listRegions = /* GraphQL */ `
  query ListRegions(
    $filter: ModelRegionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRegions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        continent
        subregion
        name
        projects {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listAnnualLeaves = /* GraphQL */ `
  query ListAnnualLeaves(
    $filter: ModelAnnualLeaveFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAnnualLeaves(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantAccessString
        startDate
        endDate
        numberOfDays
        status
        period
        audit
        createdAt
        updatedAt
        personAnnualLeaveId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRoleType = /* GraphQL */ `
  query GetRoleType($id: ID!) {
    getRoleType(id: $id) {
      id
      tenantAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      tenant {
        id
        tenantAccessString
        dataSource
        system
        integrationSystemId
        integrationId
        audit
        period
        name
        stages {
          nextToken
        }
        verticals {
          nextToken
        }
        accounts {
          nextToken
        }
        roleTypes {
          nextToken
        }
        people {
          nextToken
        }
        periods {
          nextToken
        }
        createdAt
        updatedAt
      }
      people {
        items {
          id
          roleTypeID
          personID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      projectRoles {
        items {
          id
          roleTypeID
          projectRoleID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      tenantRoleTypesId
      owner
    }
  }
`;
export const listRoleTypes = /* GraphQL */ `
  query ListRoleTypes(
    $filter: ModelRoleTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRoleTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantAccessString
        dataSource
        system
        integrationSystemId
        integrationId
        audit
        period
        name
        tenant {
          id
          tenantAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          createdAt
          updatedAt
        }
        people {
          nextToken
        }
        projectRoles {
          nextToken
        }
        createdAt
        updatedAt
        tenantRoleTypesId
        owner
      }
      nextToken
    }
  }
`;
export const getPerson = /* GraphQL */ `
  query GetPerson($id: ID!) {
    getPerson(id: $id) {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      firstName
      lastName
      email
      position
      location
      currentlyActive
      skills
      tenant {
        id
        tenantAccessString
        dataSource
        system
        integrationSystemId
        integrationId
        audit
        period
        name
        stages {
          nextToken
        }
        verticals {
          nextToken
        }
        accounts {
          nextToken
        }
        roleTypes {
          nextToken
        }
        people {
          nextToken
        }
        periods {
          nextToken
        }
        createdAt
        updatedAt
      }
      employeeSatisfactionRecords {
        items {
          id
          tenantAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          date
          score
          comment
          createdAt
          updatedAt
          personEmployeeSatisfactionRecordsId
          owner
        }
        nextToken
      }
      timesheets {
        items {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          startDate
          endDate
          timeSheetData
          createdAt
          updatedAt
          personTimesheetsId
          projectRoleTimesheetsId
          owner
        }
        nextToken
      }
      projectAllocations {
        items {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          startDate
          endDate
          createdAt
          updatedAt
          personProjectAllocationsId
          projectRoleProjectAllocationsId
          owner
        }
        nextToken
      }
      roleTypes {
        items {
          id
          roleTypeID
          personID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      risks {
        items {
          id
          riskID
          personID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      tasks {
        items {
          id
          taskID
          personID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      projectsDeliveryManaged {
        items {
          id
          projectID
          personID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      projectsProjectManaged {
        items {
          id
          projectID
          personID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      tenantPeopleId
      owner
    }
  }
`;
export const listPeople = /* GraphQL */ `
  query ListPeople(
    $filter: ModelPersonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPeople(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantAccessString
        verticalAccessString
        accountAccessString
        programmeAccessString
        dataSource
        system
        integrationSystemId
        integrationId
        audit
        period
        firstName
        lastName
        email
        position
        location
        currentlyActive
        skills
        tenant {
          id
          tenantAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          createdAt
          updatedAt
        }
        employeeSatisfactionRecords {
          nextToken
        }
        timesheets {
          nextToken
        }
        projectAllocations {
          nextToken
        }
        roleTypes {
          nextToken
        }
        risks {
          nextToken
        }
        tasks {
          nextToken
        }
        projectsDeliveryManaged {
          nextToken
        }
        projectsProjectManaged {
          nextToken
        }
        createdAt
        updatedAt
        tenantPeopleId
        owner
      }
      nextToken
    }
  }
`;
export const getProjectRole = /* GraphQL */ `
  query GetProjectRole($id: ID!) {
    getProjectRole(id: $id) {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      fte
      description
      project {
        id
        tenantAccessString
        verticalAccessString
        accountAccessString
        programmeAccessString
        dataSource
        system
        integrationSystemId
        integrationId
        audit
        period
        deliveryManagers {
          nextToken
        }
        projectManagers {
          nextToken
        }
        name
        tcv
        margin
        workstream
        currency
        budget
        spend
        country
        status
        startDate
        endDate
        revenueSold
        costSold
        revenueActual
        costActual
        consumed
        region {
          id
          continent
          subregion
          name
          createdAt
          updatedAt
        }
        programme {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          createdAt
          updatedAt
          verticalProgrammesId
          accountProgrammesId
          owner
        }
        tasks {
          nextToken
        }
        statements {
          nextToken
        }
        projectRoles {
          nextToken
        }
        risks {
          nextToken
        }
        createdAt
        updatedAt
        programmeProjectsId
        regionProjectsId
        owner
      }
      projectAllocations {
        items {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          startDate
          endDate
          createdAt
          updatedAt
          personProjectAllocationsId
          projectRoleProjectAllocationsId
          owner
        }
        nextToken
      }
      roleTypes {
        items {
          id
          roleTypeID
          projectRoleID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      timesheets {
        items {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          startDate
          endDate
          timeSheetData
          createdAt
          updatedAt
          personTimesheetsId
          projectRoleTimesheetsId
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      projectProjectRolesId
      owner
    }
  }
`;
export const listProjectRoles = /* GraphQL */ `
  query ListProjectRoles(
    $filter: ModelProjectRoleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjectRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantAccessString
        verticalAccessString
        accountAccessString
        programmeAccessString
        dataSource
        system
        integrationSystemId
        integrationId
        audit
        period
        fte
        unit
        plannedUnits
        unitRate
        description
        project {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          tcv
          margin
          workstream
          currency
          budget
          spend
          country
          status
          startDate
          endDate
          revenueSold
          costSold
          revenueActual
          costActual
          consumed
          createdAt
          updatedAt
          programmeProjectsId
          regionProjectsId
          owner
        }
        pipeline {
          id
          tenantAccessString
          accountAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          amount
          closeDate
          closedNotes
          closingDate
          currency
          dealName
          description
          grossMarginLength
          grossMarginPerMonth
          incomeType
          intro
          managingPartner
          numberOfDeliveryDays
          opportunityLead
          pipeline
          serviceGoLiveDate
          sourceOfferingOpportunity
          totalGrossProfitValue
          totalRevenueValue
          typeOfSale
          createdAt
          updatedAt
          accountPipelinesId
          stagePipelinesId
          owner
        }
        projectAllocations {
          nextToken
          items {
            id
            person {
              id
              firstName
              lastName
            }
          }
        }
        roleTypes {
          nextToken
        }
        timesheets {
          nextToken
        }
        createdAt
        updatedAt
        projectProjectRolesId
        pipelineProjectRolesId
        owner
      }
      nextToken
    }
  }
`;
export const getProjectAllocation = /* GraphQL */ `
  query GetProjectAllocation($id: ID!) {
    getProjectAllocation(id: $id) {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      startDate
      endDate
      person {
        id
        tenantAccessString
        verticalAccessString
        accountAccessString
        programmeAccessString
        dataSource
        system
        integrationSystemId
        integrationId
        audit
        period
        firstName
        lastName
        email
        position
        location
        currentlyActive
        skills
        tenant {
          id
          tenantAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          createdAt
          updatedAt
        }
        employeeSatisfactionRecords {
          nextToken
        }
        timesheets {
          nextToken
        }
        projectAllocations {
          nextToken
        }
        roleTypes {
          nextToken
        }
        risks {
          nextToken
        }
        tasks {
          nextToken
        }
        projectsDeliveryManaged {
          nextToken
        }
        projectsProjectManaged {
          nextToken
        }
        createdAt
        updatedAt
        tenantPeopleId
        owner
      }
      projectRole {
        id
        tenantAccessString
        verticalAccessString
        accountAccessString
        programmeAccessString
        dataSource
        system
        integrationSystemId
        integrationId
        audit
        period
        fte
        description
        project {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          tcv
          margin
          workstream
          currency
          budget
          spend
          country
          status
          startDate
          endDate
          revenueSold
          costSold
          revenueActual
          costActual
          consumed
          createdAt
          updatedAt
          programmeProjectsId
          regionProjectsId
          owner
        }
        projectAllocations {
          nextToken
        }
        roleTypes {
          nextToken
        }
        timesheets {
          nextToken
        }
        createdAt
        updatedAt
        projectProjectRolesId
        owner
      }
      createdAt
      updatedAt
      personProjectAllocationsId
      projectRoleProjectAllocationsId
      owner
    }
  }
`;
export const listProjectAllocations = /* GraphQL */ `
  query ListProjectAllocations(
    $filter: ModelProjectAllocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjectAllocations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantAccessString
        verticalAccessString
        accountAccessString
        programmeAccessString
        dataSource
        system
        integrationSystemId
        integrationId
        audit
        period
        startDate
        endDate
        person {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          firstName
          lastName
          email
          position
          location
          currentlyActive
          skills
          createdAt
          updatedAt
          tenantPeopleId
          owner
        }
        projectRole {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          fte
          description
          createdAt
          updatedAt
          projectProjectRolesId
          owner
        }
        createdAt
        updatedAt
        personProjectAllocationsId
        projectRoleProjectAllocationsId
        owner
      }
      nextToken
    }
  }
`;
export const getTimesheet = /* GraphQL */ `
  query GetTimesheet($id: ID!) {
    getTimesheet(id: $id) {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      startDate
      endDate
      timeSheetData
      person {
        id
        tenantAccessString
        verticalAccessString
        accountAccessString
        programmeAccessString
        dataSource
        system
        integrationSystemId
        integrationId
        audit
        period
        firstName
        lastName
        email
        position
        location
        currentlyActive
        skills
        tenant {
          id
          tenantAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          createdAt
          updatedAt
        }
        employeeSatisfactionRecords {
          nextToken
        }
        timesheets {
          nextToken
        }
        projectAllocations {
          nextToken
        }
        roleTypes {
          nextToken
        }
        risks {
          nextToken
        }
        tasks {
          nextToken
        }
        projectsDeliveryManaged {
          nextToken
        }
        projectsProjectManaged {
          nextToken
        }
        createdAt
        updatedAt
        tenantPeopleId
        owner
      }
      projectRole {
        id
        tenantAccessString
        verticalAccessString
        accountAccessString
        programmeAccessString
        dataSource
        system
        integrationSystemId
        integrationId
        audit
        period
        fte
        description
        project {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          tcv
          margin
          workstream
          currency
          budget
          spend
          country
          status
          startDate
          endDate
          revenueSold
          costSold
          revenueActual
          costActual
          consumed
          createdAt
          updatedAt
          programmeProjectsId
          regionProjectsId
          owner
        }
        projectAllocations {
          nextToken
        }
        roleTypes {
          nextToken
        }
        timesheets {
          nextToken
        }
        createdAt
        updatedAt
        projectProjectRolesId
        owner
      }
      createdAt
      updatedAt
      personTimesheetsId
      projectRoleTimesheetsId
      owner
    }
  }
`;
export const listTimesheets = /* GraphQL */ `
  query ListTimesheets(
    $filter: ModelTimesheetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTimesheets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantAccessString
        verticalAccessString
        accountAccessString
        programmeAccessString
        dataSource
        system
        integrationSystemId
        integrationId
        audit
        period
        startDate
        endDate
        timeSheetData
        unit
        unitAmount
        timesheetRef
        person {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          firstName
          lastName
          email
          position
          location
          currentlyActive
          skills
          createdAt
          updatedAt
          tenantPeopleId
          owner
        }
        projectRole {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          fte
          description
          createdAt
          updatedAt
          projectProjectRolesId
          owner
        }
        invoices {
          nextToken
          items {
            id
          }
        }
        createdAt
        updatedAt
        personTimesheetsId
        projectRoleTimesheetsId
        owner
      }
      nextToken
    }
  }
`;
export const getEmployeeSatisfaction = /* GraphQL */ `
  query GetEmployeeSatisfaction($id: ID!) {
    getEmployeeSatisfaction(id: $id) {
      id
      tenantAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      date
      score
      comment
      person {
        id
        tenantAccessString
        verticalAccessString
        accountAccessString
        programmeAccessString
        dataSource
        system
        integrationSystemId
        integrationId
        audit
        period
        firstName
        lastName
        email
        position
        location
        currentlyActive
        skills
        tenant {
          id
          tenantAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          createdAt
          updatedAt
        }
        employeeSatisfactionRecords {
          nextToken
        }
        timesheets {
          nextToken
        }
        projectAllocations {
          nextToken
        }
        roleTypes {
          nextToken
        }
        risks {
          nextToken
        }
        tasks {
          nextToken
        }
        projectsDeliveryManaged {
          nextToken
        }
        projectsProjectManaged {
          nextToken
        }
        createdAt
        updatedAt
        tenantPeopleId
        owner
      }
      createdAt
      updatedAt
      personEmployeeSatisfactionRecordsId
      owner
    }
  }
`;
export const listEmployeeSatisfactions = /* GraphQL */ `
  query ListEmployeeSatisfactions(
    $filter: ModelEmployeeSatisfactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmployeeSatisfactions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantAccessString
        dataSource
        system
        integrationSystemId
        integrationId
        audit
        period
        date
        score
        comment
        person {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          firstName
          lastName
          email
          position
          location
          currentlyActive
          skills
          createdAt
          updatedAt
          tenantPeopleId
          owner
        }
        createdAt
        updatedAt
        personEmployeeSatisfactionRecordsId
        owner
      }
      nextToken
    }
  }
`;
export const getWeeklyUpdate = /* GraphQL */ `
  query GetWeeklyUpdate($id: ID!) {
    getWeeklyUpdate(id: $id) {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      programme {
        id
        tenantAccessString
        verticalAccessString
        accountAccessString
        programmeAccessString
        dataSource
        system
        integrationSystemId
        integrationId
        audit
        period
        name
        account {
          id
          tenantAccessString
          accountAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          createdAt
          updatedAt
          tenantAccountsId
          owner
        }
        vertical {
          id
          tenantAccessString
          verticalAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          createdAt
          updatedAt
          tenantVerticalsId
          owner
        }
        projects {
          nextToken
        }
        csats {
          nextToken
        }
        risks {
          nextToken
        }
        weeklyUpdates {
          nextToken
        }
        mrrs {
          nextToken
        }
        costs {
          nextToken
        }
        pls {
          nextToken
        }
        createdAt
        updatedAt
        verticalProgrammesId
        accountProgrammesId
        owner
      }
      score
      comments
      date
      audit
      createdAt
      updatedAt
      programmeWeeklyUpdatesId
      owner
    }
  }
`;
export const listWeeklyUpdates = /* GraphQL */ `
  query ListWeeklyUpdates(
    $filter: ModelWeeklyUpdateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWeeklyUpdates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantAccessString
        verticalAccessString
        accountAccessString
        programmeAccessString
        programme {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          createdAt
          updatedAt
          verticalProgrammesId
          accountProgrammesId
          owner
        }
        score
        comments
        date
        audit
        createdAt
        updatedAt
        programmeWeeklyUpdatesId
        owner
      }
      nextToken
    }
  }
`;
export const getChange = /* GraphQL */ `
  query GetChange($id: ID!) {
    getChange(id: $id) {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      statementOfWork {
        id
        tenantAccessString
        verticalAccessString
        accountAccessString
        programmeAccessString
        dataSource
        system
        integrationSystemId
        integrationId
        audit
        period
        agreement
        description
        date
        contractType
        days
        durationDays
        location
        valueCredits
        valueUsd
        consumed
        workPackages
        paid
        stage
        completedDate
        project {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          tcv
          margin
          workstream
          currency
          budget
          spend
          country
          status
          startDate
          endDate
          revenueSold
          costSold
          revenueActual
          costActual
          consumed
          createdAt
          updatedAt
          programmeProjectsId
          regionProjectsId
          owner
        }
        pipeline {
          id
          tenantAccessString
          accountAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          amount
          closeDate
          closedNotes
          closingDate
          currency
          dealName
          description
          grossMarginLength
          grossMarginPerMonth
          incomeType
          intro
          managingPartner
          numberOfDeliveryDays
          opportunityLead
          pipeline
          serviceGoLiveDate
          sourceOfferingOpportunity
          totalGrossProfitValue
          totalRevenueValue
          typeOfSale
          createdAt
          updatedAt
          accountPipelinesId
          stagePipelinesId
          owner
        }
        deliverables {
          nextToken
        }
        invoices {
          nextToken
        }
        changes {
          nextToken
        }
        createdAt
        updatedAt
        projectStatementsId
        pipelineSowRecordsId
        owner
      }
      title
      requesterName
      requesterEmail
      organization
      requestType
      requestTitle
      changeRequestArea
      programName
      requestCategory
      requestDetail
      priority
      effectiveDate
      impactDeltaDays
      requestTypeLocation
      financialImpact
      emergencyRequest
      deliveryApproval
      dateDeliveryTeamApproved
      deliveryTeamApprovalNotes
      sltApproval
      sltApprovalDate
      sltApprovalNotes
      impactStatement
      additionalInfo
      newSow
      pmoAdmin
      customerNotified
      audit
      createdAt
      updatedAt
      statementChangesId
      owner
    }
  }
`;
export const listChanges = /* GraphQL */ `
  query ListChanges(
    $filter: ModelChangeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChanges(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantAccessString
        verticalAccessString
        accountAccessString
        programmeAccessString
        statementOfWork {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          agreement
          description
          date
          contractType
          days
          durationDays
          location
          valueCredits
          valueUsd
          consumed
          workPackages
          paid
          stage
          completedDate
          createdAt
          updatedAt
          projectStatementsId
          pipelineSowRecordsId
          owner
        }
        title
        requesterName
        requesterEmail
        organization
        requestType
        requestTitle
        changeRequestArea
        programName
        requestCategory
        requestDetail
        priority
        effectiveDate
        impactDeltaDays
        requestTypeLocation
        financialImpact
        emergencyRequest
        deliveryApproval
        dateDeliveryTeamApproved
        deliveryTeamApprovalNotes
        sltApproval
        sltApprovalDate
        sltApprovalNotes
        impactStatement
        additionalInfo
        newSow
        pmoAdmin
        customerNotified
        audit
        createdAt
        updatedAt
        statementChangesId
        owner
      }
      nextToken
    }
  }
`;
export const getMRR = /* GraphQL */ `
  query GetMRR($id: ID!) {
    getMRR(id: $id) {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      programme {
        id
        tenantAccessString
        verticalAccessString
        accountAccessString
        programmeAccessString
        dataSource
        system
        integrationSystemId
        integrationId
        audit
        period
        name
        account {
          id
          tenantAccessString
          accountAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          createdAt
          updatedAt
          tenantAccountsId
          owner
        }
        vertical {
          id
          tenantAccessString
          verticalAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          createdAt
          updatedAt
          tenantVerticalsId
          owner
        }
        projects {
          nextToken
        }
        csats {
          nextToken
        }
        risks {
          nextToken
        }
        weeklyUpdates {
          nextToken
        }
        mrrs {
          nextToken
        }
        costs {
          nextToken
        }
        pls {
          nextToken
        }
        createdAt
        updatedAt
        verticalProgrammesId
        accountProgrammesId
        owner
      }
      fiscalYear
      geo
      region
      subRegion
      territory
      engagementName
      engagementId
      engagementStatus
      engagementType
      businessUnit
      billToCustomer
      endCustomer
      budgetedHoursTotal
      plannedHoursTotal
      createdOn
      paymentType
      revRecType
      billingSKU
      engagementAmount
      billingCurrency
      totalBilledRedeemed
      remainingAmount
      operationAnalysts
      timeApprover
      billedRedeemedByDateRange
      remainingForecastByDateRange
      forecastHrsThisQtr
      forecastRevenueOfCurrentQtr
      forecastRevenueOfCurrentQtrUSD
      totalBillableHoursPriorQtrEnd
      totalBillableHoursThisQtr
      mgmtRevAsOfPriorQtr
      mgmtRevAsOfPriorQtrUSD
      qtrManagementRevenueToDate
      qtrManagementRevenueToDateUSD
      internalHoursQTD
      subbingHoursQTD
      expensesBilledRedeemedByDateRange
      contractStatus
      poNumber
      billedRedeemedByDateRangeUSD
      lastTimeEntryDate
      statusDuringDateRange
      totalBilledRedeemedUSD
      engagementAmountInUSD
      totalActualHours
      hoursBasedForecastRevenueOfCurrentQtr
      hoursBasedForecastRevenueOfCurrentQtrUSD
      plannedHrsForQtr
      program
      projectProfile
      ssm
      projectManager
      engagementClosedDate
      agreementNumber
      opportunityNumber
      psoSubRegion2
      revenueRecognized
      unbilled
      audit
      period
      createdAt
      updatedAt
      programmeMrrsId
      owner
    }
  }
`;
export const listMRRS = /* GraphQL */ `
  query ListMRRS(
    $filter: ModelMRRFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMRRS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantAccessString
        verticalAccessString
        accountAccessString
        programmeAccessString
        programme {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          createdAt
          updatedAt
          verticalProgrammesId
          accountProgrammesId
          owner
        }
        fiscalYear
        geo
        region
        subRegion
        territory
        engagementName
        engagementId
        engagementStatus
        engagementType
        businessUnit
        billToCustomer
        endCustomer
        budgetedHoursTotal
        plannedHoursTotal
        createdOn
        paymentType
        revRecType
        billingSKU
        engagementAmount
        billingCurrency
        totalBilledRedeemed
        remainingAmount
        operationAnalysts
        timeApprover
        billedRedeemedByDateRange
        remainingForecastByDateRange
        forecastHrsThisQtr
        forecastRevenueOfCurrentQtr
        forecastRevenueOfCurrentQtrUSD
        totalBillableHoursPriorQtrEnd
        totalBillableHoursThisQtr
        mgmtRevAsOfPriorQtr
        mgmtRevAsOfPriorQtrUSD
        qtrManagementRevenueToDate
        qtrManagementRevenueToDateUSD
        internalHoursQTD
        subbingHoursQTD
        expensesBilledRedeemedByDateRange
        contractStatus
        poNumber
        billedRedeemedByDateRangeUSD
        lastTimeEntryDate
        statusDuringDateRange
        totalBilledRedeemedUSD
        engagementAmountInUSD
        totalActualHours
        hoursBasedForecastRevenueOfCurrentQtr
        hoursBasedForecastRevenueOfCurrentQtrUSD
        plannedHrsForQtr
        program
        projectProfile
        ssm
        projectManager
        pmAccess
        dmAccess
        engagementClosedDate
        agreementNumber
        opportunityNumber
        psoSubRegion2
        revenueRecognized
        unbilled
        audit
        period
        createdAt
        updatedAt
        programmeMrrsId
        owner
      }
      nextToken
    }
  }
`;
export const getCost = /* GraphQL */ `
  query GetCost($id: ID!) {
    getCost(id: $id) {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      programme {
        id
        tenantAccessString
        verticalAccessString
        accountAccessString
        programmeAccessString
        dataSource
        system
        integrationSystemId
        integrationId
        audit
        period
        name
        account {
          id
          tenantAccessString
          accountAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          createdAt
          updatedAt
          tenantAccountsId
          owner
        }
        vertical {
          id
          tenantAccessString
          verticalAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          createdAt
          updatedAt
          tenantVerticalsId
          owner
        }
        projects {
          nextToken
        }
        csats {
          nextToken
        }
        risks {
          nextToken
        }
        weeklyUpdates {
          nextToken
        }
        mrrs {
          nextToken
        }
        costs {
          nextToken
        }
        pls {
          nextToken
        }
        createdAt
        updatedAt
        verticalProgrammesId
        accountProgrammesId
        owner
      }
      geo
      region
      subRegion
      engagementNumber
      engagementName
      revRecType
      paymentType
      engagementStatus
      engagementCreatedOn
      engagementAmount
      engagementCurrency
      roleName
      billingRateForRole
      budgetedHours
      costRateAverageForResources
      budgetedCostForRole
      plannedHours
      actualHours
      internalPlannedCost
      internalActualCost
      partnerPlannedCostForRole
      partnerActualCostForRole
      audit
      period
      createdAt
      updatedAt
      programmeCostsId
      owner
    }
  }
`;
export const listUserTypes = /* GraphQL */ `
  query ListUserTypes(
    $filter: ModelUserTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        tenantAccessString
        permissionsObject
        audit
        period
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listCosts = /* GraphQL */ `
  query ListCosts(
    $filter: ModelCostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantAccessString
        verticalAccessString
        accountAccessString
        programmeAccessString
        projectManager
        pmAccess
        dmAccess
        programme {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          createdAt
          updatedAt
          verticalProgrammesId
          accountProgrammesId
          owner
        }
        geo
        region
        subRegion
        engagementNumber
        engagementName
        revRecType
        paymentType
        engagementStatus
        engagementCreatedOn
        engagementAmount
        engagementCurrency
        roleName
        billingRateForRole
        budgetedHours
        costRateAverageForResources
        budgetedCostForRole
        plannedHours
        actualHours
        internalPlannedCost
        internalActualCost
        partnerPlannedCostForRole
        partnerActualCostForRole
        audit
        period
        createdAt
        updatedAt
        programmeCostsId
        owner
      }
      nextToken
    }
  }
`;
export const getPL = /* GraphQL */ `
  query GetPL($id: ID!) {
    getPL(id: $id) {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      programme {
        id
        tenantAccessString
        verticalAccessString
        accountAccessString
        programmeAccessString
        dataSource
        system
        integrationSystemId
        integrationId
        audit
        period
        name
        account {
          id
          tenantAccessString
          accountAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          createdAt
          updatedAt
          tenantAccountsId
          owner
        }
        vertical {
          id
          tenantAccessString
          verticalAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          createdAt
          updatedAt
          tenantVerticalsId
          owner
        }
        projects {
          nextToken
        }
        csats {
          nextToken
        }
        risks {
          nextToken
        }
        weeklyUpdates {
          nextToken
        }
        mrrs {
          nextToken
        }
        costs {
          nextToken
        }
        pls {
          nextToken
        }
        createdAt
        updatedAt
        verticalProgrammesId
        accountProgrammesId
        owner
      }
      engId
      engagementName
      status
      gar
      projectManager
      subcontractor
      lastInvoice
      budgetMarginType
      impactedByAllInCostChange
      deAuditMessageUSD
      billingCurrency
      budgetComments
      budgetGar
      csatComments
      csatGar
      engagementStatus
      engagementType
      fisYear2QtrName
      lastBillingEntry
      mitigationAction
      projectGarComments
      geo
      region
      subRegion
      scheduleComments
      scheduleGar
      deliveryManager
      deAuditResultsUSD
      revenueSoldUSD
      revenueRecognizedUSD
      billedRedeemedUSD
      costSoldUSD
      marginSold
      revisedCostSoldUSD
      revisedMarginSold
      percentOfHoursBeforeAllInChange
      revenueForecastExitUSD
      costForecastExitUSD
      marginForecastExit
      revenuePlannedUSD
      costPlannedUSD
      marginPlanned
      revenueActualUSD
      costActualUSD
      marginActual
      hoursSold
      hoursPlanned
      hoursActual
      percentCompleteHoursSold
      revenueSoldBC
      maxExchangeRateUsed
      billedRedeemedBC
      costForecastExitBC
      costSoldBC
      revenueForecastExitBC
      revenueRecognizedBC
      audit
      period
      createdAt
      updatedAt
      programmePlsId
      owner
    }
  }
`;
export const listPLS = /* GraphQL */ `
  query ListPLS($filter: ModelPLFilterInput, $limit: Int, $nextToken: String) {
    listPLS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantAccessString
        verticalAccessString
        accountAccessString
        programmeAccessString
        pmAccess
        dmAccess
        programme {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          createdAt
          updatedAt
          verticalProgrammesId
          accountProgrammesId
          owner
        }
        engId
        engagementName
        status
        gar
        projectManager
        subcontractor
        lastInvoice
        budgetMarginType
        impactedByAllInCostChange
        deAuditMessageUSD
        billingCurrency
        budgetComments
        budgetGar
        csatComments
        csatGar
        engagementStatus
        engagementType
        fisYear2QtrName
        lastBillingEntry
        mitigationAction
        projectGarComments
        geo
        region
        subRegion
        scheduleComments
        scheduleGar
        deliveryManager
        deAuditResultsUSD
        revenueSoldUSD
        revenueRecognizedUSD
        billedRedeemedUSD
        costSoldUSD
        marginSold
        revisedCostSoldUSD
        revisedMarginSold
        percentOfHoursBeforeAllInChange
        revenueForecastExitUSD
        costForecastExitUSD
        marginForecastExit
        revenuePlannedUSD
        costPlannedUSD
        marginPlanned
        revenueActualUSD
        costActualUSD
        marginActual
        hoursSold
        hoursPlanned
        hoursActual
        percentCompleteHoursSold
        revenueSoldBC
        maxExchangeRateUsed
        billedRedeemedBC
        costForecastExitBC
        costSoldBC
        revenueForecastExitBC
        revenueRecognizedBC
        audit
        period
        createdAt
        updatedAt
        programmePlsId
        owner
      }
      nextToken
    }
  }
`;
export const getDeliveryManagerProjects = /* GraphQL */ `
  query GetDeliveryManagerProjects($id: ID!) {
    getDeliveryManagerProjects(id: $id) {
      id
      projectID
      personID
      project {
        id
        tenantAccessString
        verticalAccessString
        accountAccessString
        programmeAccessString
        dataSource
        system
        integrationSystemId
        integrationId
        audit
        period
        deliveryManagers {
          nextToken
        }
        projectManagers {
          nextToken
        }
        name
        tcv
        margin
        workstream
        currency
        budget
        spend
        country
        status
        startDate
        endDate
        revenueSold
        costSold
        revenueActual
        costActual
        consumed
        region {
          id
          continent
          subregion
          name
          createdAt
          updatedAt
        }
        programme {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          createdAt
          updatedAt
          verticalProgrammesId
          accountProgrammesId
          owner
        }
        tasks {
          nextToken
        }
        statements {
          nextToken
        }
        projectRoles {
          nextToken
        }
        risks {
          nextToken
        }
        createdAt
        updatedAt
        programmeProjectsId
        regionProjectsId
        owner
      }
      person {
        id
        tenantAccessString
        verticalAccessString
        accountAccessString
        programmeAccessString
        dataSource
        system
        integrationSystemId
        integrationId
        audit
        period
        firstName
        lastName
        email
        position
        location
        currentlyActive
        skills
        tenant {
          id
          tenantAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          createdAt
          updatedAt
        }
        employeeSatisfactionRecords {
          nextToken
        }
        timesheets {
          nextToken
        }
        projectAllocations {
          nextToken
        }
        roleTypes {
          nextToken
        }
        risks {
          nextToken
        }
        tasks {
          nextToken
        }
        projectsDeliveryManaged {
          nextToken
        }
        projectsProjectManaged {
          nextToken
        }
        createdAt
        updatedAt
        tenantPeopleId
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listDeliveryManagerProjects = /* GraphQL */ `
  query ListDeliveryManagerProjects(
    $filter: ModelDeliveryManagerProjectsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeliveryManagerProjects(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        projectID
        personID
        project {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          tcv
          margin
          workstream
          currency
          budget
          spend
          country
          status
          startDate
          endDate
          revenueSold
          costSold
          revenueActual
          costActual
          consumed
          createdAt
          updatedAt
          programmeProjectsId
          regionProjectsId
          owner
        }
        person {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          firstName
          lastName
          email
          position
          location
          currentlyActive
          skills
          createdAt
          updatedAt
          tenantPeopleId
          owner
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getProjectManagerProjects = /* GraphQL */ `
  query GetProjectManagerProjects($id: ID!) {
    getProjectManagerProjects(id: $id) {
      id
      projectID
      personID
      project {
        id
        tenantAccessString
        verticalAccessString
        accountAccessString
        programmeAccessString
        dataSource
        system
        integrationSystemId
        integrationId
        audit
        period
        deliveryManagers {
          nextToken
        }
        projectManagers {
          nextToken
        }
        name
        tcv
        margin
        workstream
        currency
        budget
        spend
        country
        status
        startDate
        endDate
        revenueSold
        costSold
        revenueActual
        costActual
        consumed
        region {
          id
          continent
          subregion
          name
          createdAt
          updatedAt
        }
        programme {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          createdAt
          updatedAt
          verticalProgrammesId
          accountProgrammesId
          owner
        }
        tasks {
          nextToken
        }
        statements {
          nextToken
        }
        projectRoles {
          nextToken
        }
        risks {
          nextToken
        }
        createdAt
        updatedAt
        programmeProjectsId
        regionProjectsId
        owner
      }
      person {
        id
        tenantAccessString
        verticalAccessString
        accountAccessString
        programmeAccessString
        dataSource
        system
        integrationSystemId
        integrationId
        audit
        period
        firstName
        lastName
        email
        position
        location
        currentlyActive
        skills
        tenant {
          id
          tenantAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          createdAt
          updatedAt
        }
        employeeSatisfactionRecords {
          nextToken
        }
        timesheets {
          nextToken
        }
        projectAllocations {
          nextToken
        }
        roleTypes {
          nextToken
        }
        risks {
          nextToken
        }
        tasks {
          nextToken
        }
        projectsDeliveryManaged {
          nextToken
        }
        projectsProjectManaged {
          nextToken
        }
        createdAt
        updatedAt
        tenantPeopleId
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listProjectManagerProjects = /* GraphQL */ `
  query ListProjectManagerProjects(
    $filter: ModelProjectManagerProjectsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjectManagerProjects(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        projectID
        personID
        project {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          tcv
          margin
          workstream
          currency
          budget
          spend
          country
          status
          startDate
          endDate
          revenueSold
          costSold
          revenueActual
          costActual
          consumed
          createdAt
          updatedAt
          programmeProjectsId
          regionProjectsId
          owner
        }
        person {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          firstName
          lastName
          email
          position
          location
          currentlyActive
          skills
          createdAt
          updatedAt
          tenantPeopleId
          owner
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getPersonRisks = /* GraphQL */ `
  query GetPersonRisks($id: ID!) {
    getPersonRisks(id: $id) {
      id
      riskID
      personID
      risk {
        id
        tenantAccessString
        verticalAccessString
        accountAccessString
        programmeAccessString
        dataSource
        system
        integrationSystemId
        integrationId
        audit
        period
        name
        description
        actionOwner
        status
        impactDate
        cause
        effect
        impactDescription
        impactType
        riskResponse
        actionMitigation
        targetResolutionDate
        probability
        impact
        estimatedImpactCost
        escalatedToPortfolio
        detailedUpdate
        dateRaised
        dateOfNextReview
        riskOwners {
          nextToken
        }
        vertical {
          id
          tenantAccessString
          verticalAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          createdAt
          updatedAt
          tenantVerticalsId
          owner
        }
        account {
          id
          tenantAccessString
          accountAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          createdAt
          updatedAt
          tenantAccountsId
          owner
        }
        programme {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          createdAt
          updatedAt
          verticalProgrammesId
          accountProgrammesId
          owner
        }
        project {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          tcv
          margin
          workstream
          currency
          budget
          spend
          country
          status
          startDate
          endDate
          revenueSold
          costSold
          revenueActual
          costActual
          consumed
          createdAt
          updatedAt
          programmeProjectsId
          regionProjectsId
          owner
        }
        createdAt
        updatedAt
        verticalRisksId
        accountRisksId
        programmeRisksId
        projectRisksId
        owner
      }
      person {
        id
        tenantAccessString
        verticalAccessString
        accountAccessString
        programmeAccessString
        dataSource
        system
        integrationSystemId
        integrationId
        audit
        period
        firstName
        lastName
        email
        position
        location
        currentlyActive
        skills
        tenant {
          id
          tenantAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          createdAt
          updatedAt
        }
        employeeSatisfactionRecords {
          nextToken
        }
        timesheets {
          nextToken
        }
        projectAllocations {
          nextToken
        }
        roleTypes {
          nextToken
        }
        risks {
          nextToken
        }
        tasks {
          nextToken
        }
        projectsDeliveryManaged {
          nextToken
        }
        projectsProjectManaged {
          nextToken
        }
        createdAt
        updatedAt
        tenantPeopleId
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listPersonRisks = /* GraphQL */ `
  query ListPersonRisks(
    $filter: ModelPersonRisksFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPersonRisks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        riskID
        personID
        risk {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          description
          actionOwner
          status
          impactDate
          cause
          effect
          impactDescription
          impactType
          riskResponse
          actionMitigation
          targetResolutionDate
          probability
          impact
          estimatedImpactCost
          escalatedToPortfolio
          detailedUpdate
          dateRaised
          dateOfNextReview
          createdAt
          updatedAt
          verticalRisksId
          accountRisksId
          programmeRisksId
          projectRisksId
          owner
        }
        person {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          firstName
          lastName
          email
          position
          location
          currentlyActive
          skills
          createdAt
          updatedAt
          tenantPeopleId
          owner
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getPersonTasks = /* GraphQL */ `
  query GetPersonTasks($id: ID!) {
    getPersonTasks(id: $id) {
      id
      taskID
      personID
      task {
        id
        tenantAccessString
        verticalAccessString
        accountAccessString
        programmeAccessString
        dataSource
        system
        integrationSystemId
        integrationId
        audit
        period
        name
        complete
        assignees {
          nextToken
        }
        project {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          tcv
          margin
          workstream
          currency
          budget
          spend
          country
          status
          startDate
          endDate
          revenueSold
          costSold
          revenueActual
          costActual
          consumed
          createdAt
          updatedAt
          programmeProjectsId
          regionProjectsId
          owner
        }
        createdAt
        updatedAt
        projectTasksId
        owner
      }
      person {
        id
        tenantAccessString
        verticalAccessString
        accountAccessString
        programmeAccessString
        dataSource
        system
        integrationSystemId
        integrationId
        audit
        period
        firstName
        lastName
        email
        position
        location
        currentlyActive
        skills
        tenant {
          id
          tenantAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          createdAt
          updatedAt
        }
        employeeSatisfactionRecords {
          nextToken
        }
        timesheets {
          nextToken
        }
        projectAllocations {
          nextToken
        }
        roleTypes {
          nextToken
        }
        risks {
          nextToken
        }
        tasks {
          nextToken
        }
        projectsDeliveryManaged {
          nextToken
        }
        projectsProjectManaged {
          nextToken
        }
        createdAt
        updatedAt
        tenantPeopleId
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listPersonTasks = /* GraphQL */ `
  query ListPersonTasks(
    $filter: ModelPersonTasksFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPersonTasks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        taskID
        personID
        task {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          complete
          createdAt
          updatedAt
          projectTasksId
          owner
        }
        person {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          firstName
          lastName
          email
          position
          location
          currentlyActive
          skills
          createdAt
          updatedAt
          tenantPeopleId
          owner
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getRoleTypePeople = /* GraphQL */ `
  query GetRoleTypePeople($id: ID!) {
    getRoleTypePeople(id: $id) {
      id
      roleTypeID
      personID
      roleType {
        id
        tenantAccessString
        dataSource
        system
        integrationSystemId
        integrationId
        audit
        period
        name
        tenant {
          id
          tenantAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          createdAt
          updatedAt
        }
        people {
          nextToken
        }
        projectRoles {
          nextToken
        }
        createdAt
        updatedAt
        tenantRoleTypesId
        owner
      }
      person {
        id
        tenantAccessString
        verticalAccessString
        accountAccessString
        programmeAccessString
        dataSource
        system
        integrationSystemId
        integrationId
        audit
        period
        firstName
        lastName
        email
        position
        location
        currentlyActive
        skills
        tenant {
          id
          tenantAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          createdAt
          updatedAt
        }
        employeeSatisfactionRecords {
          nextToken
        }
        timesheets {
          nextToken
        }
        projectAllocations {
          nextToken
        }
        roleTypes {
          nextToken
        }
        risks {
          nextToken
        }
        tasks {
          nextToken
        }
        projectsDeliveryManaged {
          nextToken
        }
        projectsProjectManaged {
          nextToken
        }
        createdAt
        updatedAt
        tenantPeopleId
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listRoleTypePeople = /* GraphQL */ `
  query ListRoleTypePeople(
    $filter: ModelRoleTypePeopleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRoleTypePeople(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        roleTypeID
        personID
        roleType {
          id
          tenantAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          createdAt
          updatedAt
          tenantRoleTypesId
          owner
        }
        person {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          firstName
          lastName
          email
          position
          location
          currentlyActive
          skills
          createdAt
          updatedAt
          tenantPeopleId
          owner
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getRoleTypeProjectRoles = /* GraphQL */ `
  query GetRoleTypeProjectRoles($id: ID!) {
    getRoleTypeProjectRoles(id: $id) {
      id
      roleTypeID
      projectRoleID
      roleType {
        id
        tenantAccessString
        dataSource
        system
        integrationSystemId
        integrationId
        audit
        period
        name
        tenant {
          id
          tenantAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          createdAt
          updatedAt
        }
        people {
          nextToken
        }
        projectRoles {
          nextToken
        }
        createdAt
        updatedAt
        tenantRoleTypesId
        owner
      }
      projectRole {
        id
        tenantAccessString
        verticalAccessString
        accountAccessString
        programmeAccessString
        dataSource
        system
        integrationSystemId
        integrationId
        audit
        period
        fte
        description
        project {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          tcv
          margin
          workstream
          currency
          budget
          spend
          country
          status
          startDate
          endDate
          revenueSold
          costSold
          revenueActual
          costActual
          consumed
          createdAt
          updatedAt
          programmeProjectsId
          regionProjectsId
          owner
        }
        projectAllocations {
          nextToken
        }
        roleTypes {
          nextToken
        }
        timesheets {
          nextToken
        }
        createdAt
        updatedAt
        projectProjectRolesId
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listRoleTypeProjectRoles = /* GraphQL */ `
  query ListRoleTypeProjectRoles(
    $filter: ModelRoleTypeProjectRolesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRoleTypeProjectRoles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        roleTypeID
        projectRoleID
        roleType {
          id
          tenantAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          createdAt
          updatedAt
          tenantRoleTypesId
          owner
        }
        projectRole {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          fte
          description
          createdAt
          updatedAt
          projectProjectRolesId
          owner
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const csvUpload = /* GraphQL */ `
  query CsvUpload($input: AWSJSON!) {
    csvUpload(input: $input)
  }
`;
export const integrations = /* GraphQL */ `
  query Integrations($input: AWSJSON!) {
    integrations(input: $input)
  }
`;
export const updateIntegrationData = /* GraphQL */ `
  query UpdateIntegrationData($input: AWSJSON!) {
    updateIntegrationData(input: $input)
  }
`;
