import { useContext, useMemo, useState } from "react";
import AppContext from "../../../../context/AppContext";
import { MRR } from "../../../../API";
import { Box, Progress, Text } from "@mantine/core";
import {
  MRT_ColumnDef,
  type MRT_Cell,
  type MRT_Column,
  type MRT_Row,
  type MRT_TableInstance,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import WidgetFrame from "../../WidgetFrame";
import Placeholder from "../../Placeholder";

const AggregatedCell = ({
  cell,
  table,
}: {
  cell: MRT_Cell<MRR>;
  column: MRT_Column<MRR>;
  row: MRT_Row<MRR>;
  table: MRT_TableInstance<MRR>;
}) => (
  <>
    <Box sx={{ fontWeight: "bold" }}>{Math.round(cell.getValue<number>())}</Box>
  </>
);

const AggregatedCellCurrency = ({
  cell,
  table,
}: {
  cell: MRT_Cell<MRR>;
  column: MRT_Column<MRR>;
  row: MRT_Row<MRR>;
  table: MRT_TableInstance<MRR>;
}) => (
  <>
    <Box sx={{ fontWeight: "bold" }}>
      {cell.getValue<number>()?.toLocaleString?.("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })}
    </Box>
  </>
);

const AggregatedCellPercent = ({
  cell,
  table,
  row,
}: {
  cell: MRT_Cell<MRR>;
  column: MRT_Column<MRR>;
  row: MRT_Row<MRR>;
  table: MRT_TableInstance<MRR>;
}) => {
  const value = cell.getValue<number>();
  const rounded = value ? Math.round(value * 100) : 0;
  const color = value > 0.1 ? "green" : value > 0.05 ? "orange" : "red";
  return (
    <>
      <Box sx={{ color, fontWeight: "bold" }}>
        {rounded}%
        <Progress value={rounded} color={color} />
      </Box>
    </>
  );
};

const AggregatedCellPercentHours = ({
  cell,
  table,
  row,
}: {
  cell: MRT_Cell<MRR>;
  column: MRT_Column<MRR>;
  row: MRT_Row<MRR>;
  table: MRT_TableInstance<MRR>;
}) => {
  const value = cell.getValue<number>();
  const rounded = value ? Math.round(value * 100) : 0;
  const color = value > 1 ? "red" : value > 0.8 ? "orange" : "Green";
  return (
    <>
      <Box sx={{ color, fontWeight: "bold" }}>
        {rounded}%
        <Progress value={rounded} color={color} />
      </Box>
    </>
  );
};

const Cell = ({
  cell,
}: {
  cell: MRT_Cell<MRR>;
  // renderedCellValue: React.ReactNode;
  // column: MRT_Column<MRR>;
  // row: MRT_Row<MRR>;
  // rowRef?: React.RefObject<HTMLTableRowElement> | undefined;
  // table: MRT_TableInstance<...>;
}) => <>{Math.round(cell.getValue<number>())}</>;

const CellCurrency = ({
  cell,
}: {
  cell: MRT_Cell<MRR>;
  // renderedCellValue: React.ReactNode;
  // column: MRT_Column<MRR>;
  // row: MRT_Row<MRR>;
  // rowRef?: React.RefObject<HTMLTableRowElement> | undefined;
  // table: MRT_TableInstance<...>;
}) => (
  <>
    {cell.getValue<number>()?.toLocaleString?.("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })}
  </>
);

const CellPercent = ({
  cell,
}: {
  cell: MRT_Cell<MRR>;
  // renderedCellValue: React.ReactNode;
  // column: MRT_Column<MRR>;
  // row: MRT_Row<MRR>;
  // rowRef?: React.RefObject<HTMLTableRowElement> | undefined;
  // table: MRT_TableInstance<...>;
}) => {
  const value = cell.getValue<number>();
  const rounded = value ? Math.round(value * 100) : 0;
  const color = value > 0.1 ? "green" : value > 0.05 ? "orange" : "red";
  return (
    <Box sx={{ color }}>
      {rounded}%
      <Progress value={rounded} color={color} />
    </Box>
  );
};

const Footer = ({ sum }: any) => <Box pb={"xs"}>{Math.round(sum)}</Box>;

const FooterCurrency = ({ sum }: any) => (
  <Box pb={"xs"}>
    {sum?.toLocaleString?.("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })}
  </Box>
);

const FooterPercent = ({ sum }: any) => (
  <Box pb={"xs"}>{Math.round(sum * 100)}%</Box>
);

const plannedMarginAgg: any = (leafRowString: any, childRows: any) => {
  const totalsObject = childRows.reduce(
    (obj: any, row: any) => {
      obj.revenuePlannedUSD += row.original.revenuePlannedUSD;
      obj.costPlannedUSD += row.original.costPlannedUSD;
      return obj;
    },
    {
      revenuePlannedUSD: 0,
      costPlannedUSD: 0,
    }
  );
  const { revenuePlannedUSD, costPlannedUSD } = totalsObject;
  return revenuePlannedUSD
    ? (revenuePlannedUSD - costPlannedUSD) / revenuePlannedUSD
    : 0;
};

const actualMarginAgg: any = (leafRowString: any, childRows: any) => {
  const totalsObject = childRows.reduce(
    (obj: any, row: any) => {
      obj.revenueActualUSD += row.original.revenueActualUSD;
      obj.costActualUSD += row.original.costActualUSD;
      return obj;
    },
    {
      revenueActualUSD: 0,
      costActualUSD: 0,
    }
  );
  const { revenueActualUSD, costActualUSD } = totalsObject;
  return revenueActualUSD
    ? (revenueActualUSD - costActualUSD) / revenueActualUSD
    : 0;
};

const hoursBurnedAgg: any = (leafRowString: any, childRows: any) => {
  const totalsObject = childRows.reduce(
    (obj: any, row: any) => {
      obj.hoursSold += row.original.hoursSold;
      obj.hoursActual += row.original.hoursActual;
      return obj;
    },
    {
      hoursSold: 0,
      hoursActual: 0,
    }
  );
  const { hoursSold, hoursActual } = totalsObject;
  return hoursSold ? hoursActual / hoursSold : 0;
};

export default function AtRiskMRR() {
  const { dataObject, currentProgramme, currentProgrammes, period }: any =
    useContext(AppContext);
  const { mrrs = [] }: { mrrs: MRR[] } = dataObject;
  //   const theme = useMantineTheme();

  const permittedProgrammeList = useMemo(
    () => (currentProgramme === "All" ? currentProgrammes : [currentProgramme]),
    [currentProgramme, currentProgrammes]
  );

  const filteredMrrs: MRR[] = useMemo(
    () =>
      mrrs.filter((row: any) => {
        return (
          (currentProgramme === "All" ||
            permittedProgrammeList.includes(row["programmeMrrsId"])) &&
          row.period === "Live" &&
          row.engagementStatus === "At Risk"
        );
      }),
    [mrrs, currentProgramme, period, permittedProgrammeList]
  );

  const totalObject: { [key: string]: number } = useMemo(() => {
    const fields: string[] = ["engagementAmount"];
    return filteredMrrs.reduce((obj, curr) => {
      fields.forEach((field) => {
        //@ts-ignore
        if (!obj[field]) obj[field] = 0;
        //@ts-ignore
        obj[field] += curr[field];
      });
      return obj;
    }, {});
  }, [filteredMrrs]);

  const columns = useMemo<MRT_ColumnDef<MRR>[]>(
    () => [
      {
        header: "Project Manager",
        accessorKey: "projectManager",
        enableGrouping: true,
      },
      {
        header: "Engagement Name",
        accessorKey: "engagementName",
        size: 0,
        enableGrouping: false,
        enableSorting: false,
        Cell: ({ cell }: { cell: MRT_Cell<MRR> }) => {
          return (
            <>
              <Text size={"xs"}>{cell.getValue<number>()}</Text>
            </>
          );
        },
      },

      {
        header: "Engagement Amount",
        accessorKey: "engagementAmount",
        aggregationFn: "sum",
        enableGrouping: false,
        AggregatedCell: AggregatedCellCurrency,
        Cell: CellCurrency,
        Footer: <FooterCurrency sum={totalObject["engagementAmount"]} />,
      },
    ],
    [totalObject]
  );

  const table = useMantineReactTable({
    columns,
    data: filteredMrrs,
    enableColumnResizing: false,
    enableGrouping: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    groupedColumnMode: false,
    enablePagination: false,
    enablePinning: true,
    enableColumnActions: false,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    mantineTableBodyCellProps: { sx: { textWrap: "wrap" } },
    initialState: {
      density: "xs",
      //@ts-ignore
      expanded: false,
      grouping: [],
      //   pagination: { pageIndex: 0, pageSize: 100 },
      sorting: [{ id: "revenuePlannedUSD", desc: true }],
      columnPinning: { left: [], right: [] },
    },
    state: {
      //@ts-ignore
      //   expanded: false,
      columnVisibility: {},
    },
    mantineToolbarAlertBannerBadgeProps: { color: "blue", variant: "outline" },
    mantineTableContainerProps: { sx: {} },
    mantineTableProps: { style: { height: "100%" } },
    mantinePaperProps: {
      style: {
        border: "none",
        boxShadow: "none",
        maxHeight: "100%",
        height: "100%",
        display: "grid",
        gridTemplateRows: "1fr 0px ",
      },
    },
  });

  //   if (mrrs.length === 0) return null;
  return (
    <WidgetFrame title={"At Risk Status"}>
      <Box
        style={{
          height: "99%",
          minHeight: "99%",
          maxHeight: "99%",
        }}
      >
        {mrrs.length === 0 ? (
          <Placeholder placeholderText="No Data" />
        ) : (
          <MantineReactTable table={table} />
        )}
      </Box>
    </WidgetFrame>
  );
}
