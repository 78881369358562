import React from "react";
import { Alert } from "@mantine/core";
import useFetchData from "../../hooks/useFetchData";
import { ContactSupportForm } from "./ContactSupport";

function ErrorComponent() {
  useFetchData([]);
  return (
    <Alert title={"Something went wrong"} mt={"0.5em"}>
      <ContactSupportForm />
    </Alert>
  );
}

class ErrorBoundary extends React.Component {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    console.log({ error, errorInfo });
  }

  render() {
    //@ts-ignore
    if (this.state.hasError) {
      return <ErrorComponent />;
    }
    //@ts-ignore
    return this.props.children;
  }
}

export default ErrorBoundary;
