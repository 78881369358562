import { useMantineTheme } from "@mantine/core";
import {
  Treemap as ReTreemap,
  //   Pie,
  //   Legend,
  //   Tooltip,
  ResponsiveContainer,
  Tooltip,
  //   Cell,
} from "recharts";
import { CustomTooltip, getChartColors } from "../ChartUtilities";
import { PureComponent, useMemo } from "react";
import Legend from "../Legend";

class CustomizedContent extends PureComponent {
  render() {
    const {
      root,
      depth,
      x,
      y,
      width,
      height,
      index,
      payload,
      colors,
      theme,
      rank,
      name,
      disableChartInteractions,
      updateDashboardFilter,
      rows,
      cols,
      dataLabels,
      dataLabelFontSize,
    }: any = this.props;

    return (
      <g>
        <defs>
          <linearGradient
            id={`colorValue${index}`}
            x1="0"
            y1="0"
            x2="0"
            y2="1"
            key={`colorValue${index}`}
          >
            <stop offset="5%" stopColor={colors[index]} stopOpacity={0.8} />
            <stop offset="95%" stopColor={colors[index]} stopOpacity={0.4} />
          </linearGradient>
        </defs>
        <rect
          x={x}
          y={y}
          width={width}
          height={height}
          fill={depth < 2 ? `url(#colorValue${index})` : "#ffffff00"}
          style={{
            stroke: theme.colorScheme === "dark" ? "#25262b" : "#f8f9fa",
            strokeWidth: 2 / (depth + 1e-10),
            strokeOpacity: 1 / (depth + 1e-10),
            cursor: disableChartInteractions ? "" : "pointer",
          }}
          onClick={(input) => {
            if (disableChartInteractions) return;
            if (cols[0]) {
              //@ts-ignore
              updateDashboardFilter(cols[0], name);
            } else {
              updateDashboardFilter(rows[0], name);
            }
          }}
        />
        {dataLabels && depth === 1 ? (
          <text
            x={x + width / 2}
            y={y + height / 2 + 7 * dataLabelFontSize}
            textAnchor="middle"
            fill="#fff"
            fontSize={dataLabelFontSize + "em"}
          >
            {name}
          </text>
        ) : null}
      </g>
    );
  }
}

export default function Treemap(props: any) {
  const {
    data,
    dataLabels,
    dataLabelFontSize,
    values,
    chartVariant,
    legend,
    updateDashboardFilter,
    rows,
    formatFunction,
    comparisonFieldString,
    disableTooltip,
    disableChartInteractions,
    cols,
  } = props;
  const theme = useMantineTheme();
  const colors = getChartColors(theme);

  const updatedData = useMemo(() => {
    return data.map((row: any) => {
      if (!row[values[0]]) {
        const { name } = row;
        return {
          name: name,
          children: Object.keys(row)
            .filter((key) => key !== "name")
            .map((key: any) => {
              return {
                name: key,
                [values[0]]: row[key],
              };
            }),
        };
      } else {
        return row;
      }
    });
  }, [data, values]);

  return (
    <ResponsiveContainer
      width="100%"
      height="99%"
      style={{ overflow: "hidden" }}
    >
      <ReTreemap
        // width={400}
        // height={200}
        data={updatedData}
        animationDuration={300}
        dataKey={values[0]}
        // aspectRatio={4 / 3}
        // stroke="#fff"
        // fill="#8884d8"
        content={
          <CustomizedContent
            //@ts-ignore
            colors={colors}
            theme={theme}
            comparisonFieldString={comparisonFieldString}
            disableChartInteractions={disableChartInteractions}
            rows={rows}
            cols={cols}
            updateDashboardFilter={updateDashboardFilter}
            dataLabels={dataLabels}
            dataLabelFontSize={dataLabelFontSize}
          />
        }
      >
        <Legend />
        {!disableTooltip && (
          <Tooltip
            cursor={false}
            content={
              <CustomTooltip
                formatFunction={formatFunction}
                comparisonFieldString={comparisonFieldString}
              />
            }
          />
        )}
      </ReTreemap>
    </ResponsiveContainer>
  );
}
