import { useContext, useMemo } from "react";
import CrudScreen from "../../common/CrudScreen";
import AppContext from "../../../context/AppContext";
import { usersSchema as schema } from "../../../context/dataSchema";
// import {
//   createPipeline as createQuery,
//   updatePipeline as updateQuery,
//   deletePipeline as deleteQuery,
// } from "../../../graphql/mutations";
import { userFunctions } from "../../../graphql/queries";
import { API } from "aws-amplify";

const customFunction = (action: string) => {
  return async ({
    recordObject,
    dataObject,
    setDataObject,
    currentTenant,
    onSubmissionFunction,
    setLoading,
  }: any) => {
    delete recordObject.period;
    delete recordObject.audit;
    const usersResponse = await API.graphql({
      query: userFunctions,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: {
        input: JSON.stringify({ currentTenant, action, recordObject }),
      },
    });

    const newDataObject = structuredClone(dataObject);

    switch (action) {
      case "createUser":
        const user = JSON.parse(
          //@ts-ignore
          JSON.parse(usersResponse.data.userFunctions).body
        );
        newDataObject.users.push(user);
        setDataObject(newDataObject);
        onSubmissionFunction("delete");
        break;
      case "deleteUser":
        newDataObject.users = newDataObject.users.filter((user: any) => {
          return user.id !== recordObject.id;
        });
        setDataObject(newDataObject);
        onSubmissionFunction("delete");
        break;
      default:
        newDataObject.users = newDataObject.users.map((user: any) => {
          if (user.id === recordObject.id) {
            return recordObject;
          }
          return user;
        });
        setDataObject(newDataObject);
        onSubmissionFunction("update");
        break;
    }
    setLoading(false);
  };
};

export default function Users() {
  const {
    dataObject: { users: data = [] },
  } = useContext(AppContext);

  const screenProps = useMemo(
    () => ({
      data,
      schema,
      title: "Manage Users",
      quickStats: null,
      tableColumns: ["email"],
      filterFields: [],
      recordName: "User",
      pagePath: "manage-users",
      dataObjectKey: "users",
      formProps: {
        // createQuery,
        // updateQuery,
        // deleteQuery,
        dataObjectKey: "users",
        customUpdateFunction: customFunction("updateUser"),
        customDeleteFunction: customFunction("deleteUser"),
        customCreateFunction: customFunction("createUser"),
      },
      modulesToLoad: [
        "Tenants",
        "People",
        "Verticals",
        "Programmes",
        "Accounts",
        "User Types",
      ],
    }),
    [data]
  );
  return <CrudScreen {...screenProps} />;
}
