import {
  getPreviousDays,
  getPreviousMonthsLastDay,
  getPreviousQuartersLastDay,
} from "../../common/functions/dateFunctions";

export default function getFinalPeriods(period: string) {
  switch (period) {
    case "Last 30 Days":
      return ["Live", ...getPreviousDays(30)];
    case "Last 4 Quarters":
      return ["Live", ...getPreviousQuartersLastDay(4)];
    case "Last 12 Months":
    case "All":
      return ["Live", ...getPreviousMonthsLastDay(12)];
    default:
      return ["Live"];
  }
}
