import { useContext } from "react";
import CrudScreen from "../common/CrudScreen";
import AppContext from "../../context/AppContext";
import { changesSchema } from "../../context/dataSchema";
import ChangeQuickStats from "./ChangeQuickStats";
import {
  createChange,
  updateChange,
  deleteChange,
} from "../../graphql/mutations";

export default function Changes(props: any) {
  const { dataObject, currentProgrammes, currentProgramme } =
    useContext(AppContext);
  const { changes = [] } = dataObject;

  const permittedProgrammeList =
    currentProgramme === "All" ? currentProgrammes : [currentProgramme];

  const globalFilteredData = changes.filter((row: any) => {
    return permittedProgrammeList.includes(row.programmeChangesId);
  });

  const screenProps = {
    data: globalFilteredData,
    schema: changesSchema,
    title: "Change Management",
    quickStats: <ChangeQuickStats changes={changes} />,
    tableColumns: [
      "title",
      "requesterName",
      "requestCategory",
      "requestDetail",
    ],
    filterFields: [],
    recordName: "Change",
    pagePath: "change-management",
    dataObjectKey: "changes",
    formProps: {
      createQuery: createChange,
      updateQuery: updateChange,
      deleteQuery: deleteChange,
    },
    widerTitle: true,
    modulesToLoad: [
      "Verticals",
      "Accounts",
      "Programmes",
      "Statements of Work",
      "Change Requests",
    ],
  };
  return <CrudScreen {...screenProps} />;
}
