import {
  Box,
  Button,
  Center,
  Chip,
  Grid,
  Group,
  Image,
  ScrollArea,
  Stack,
  Text,
  TextInput,
  ThemeIcon,
  useMantineTheme,
} from "@mantine/core";
import { useDeferredValue, useMemo, useState } from "react";
import { systemsData } from "./systems/systemsData";
import CustomPaper from "../common/CustomPaper";
import { IconCsv } from "@tabler/icons-react";

function IntegrationTile(props: any) {
  const { name, lightLogoUrl, darkLogoUrl, path, system, form, setPath } =
    props;
  const theme = useMantineTheme();

  return (
    <Grid.Col sm={4} sx={{ cursor: "pointer" }}>
      <CustomPaper
        onClick={() => {
          form.setValues({ system });
          setPath(path);
        }}
      >
        <Stack spacing={"xl"}>
          <Text align={"center"}>{name}</Text>
          {system === "csv" ? (
            <Center>
              <ThemeIcon size="xl">
                <IconCsv size="2rem" />
              </ThemeIcon>
            </Center>
          ) : (
            <Image
              src={theme.colorScheme === "dark" ? darkLogoUrl : lightLogoUrl}
              height={50}
              fit="contain"
              withPlaceholder
            />
          )}
          {/* <Center> */}
          <Group position="center" h={20}>
            {system === form.values.system ? (
              <>
                <Chip
                  defaultChecked
                  color={theme.primaryColor}
                  variant="filled"
                >
                  Selected
                </Chip>
              </>
            ) : null}
          </Group>
        </Stack>
      </CustomPaper>
      {/* </a> */}
    </Grid.Col>
  );
}

export default function SystemQuestion(props: any) {
  const { form, setActiveTab } = props;
  const [query, setQuery] = useState("");
  const [path, setPath] = useState(null);
  const deferredQuery = useDeferredValue(query.toLowerCase());

  const filteredSystems = useMemo(
    () =>
      Object.values(systemsData).filter((systemObject: any) => {
        return systemObject.name.toLowerCase().includes(deferredQuery);
      }),
    [deferredQuery]
  );

  return (
    <Box>
      <Stack>
        <TextInput
          placeholder="Search available systems"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
        <ScrollArea h={"50vh"} offsetScrollbars>
          <Grid>
            {filteredSystems.map((systemObject: any) => {
              return (
                <IntegrationTile
                  key={systemObject.name}
                  {...systemObject}
                  form={form}
                  setPath={setPath}
                />
              );
            })}
          </Grid>
        </ScrollArea>
      </Stack>
      <Group position="right" mt="xl">
        <Button
          disabled={!path}
          onClick={() => {
            if (path) {
              if (path === "csv") {
                form.setValues({ system: "csv" });
                setActiveTab(1);
              } else {
                window.location.replace(path);
              }
            }
          }}
        >
          Connect
        </Button>
      </Group>
    </Box>
  );
}
