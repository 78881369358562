export function getStatistics(arr: number[], name: string) {
  //   if (arr.length === 0) return null; // Handle empty array

  // Sort the array in ascending order
  const sorted = arr.slice().sort((a, b) => a - b);

  const min = sorted[0];
  const max = sorted[sorted.length - 1];

  // Helper function to calculate the median of a sorted array
  function getMedian(arr: number[]) {
    const mid = Math.floor(arr.length / 2);
    return arr.length % 2 !== 0 ? arr[mid] : (arr[mid - 1] + arr[mid]) / 2;
  }

  // Quartile calculation
  const median = getMedian(sorted); // Q2

  const lowerQuartile = getMedian(
    sorted.slice(0, Math.floor(sorted.length / 2))
  ); // Q1
  const upperQuartile = getMedian(sorted.slice(Math.ceil(sorted.length / 2))); // Q3

  // Calculate the average
  const average = sorted.reduce((sum, num) => sum + num, 0) / sorted.length;

  return {
    min: min,
    lowerQuartile: lowerQuartile,
    median: median,
    upperQuartile: upperQuartile,
    max: max,
    average: average,
    name,
  };
}
