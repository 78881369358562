import { useContext, useEffect, useMemo, useRef, useState } from "react";
import * as dayjs from "dayjs";
import AppContext from "../../../../context/AppContext";
import { Pipeline } from "../../../../API";
import {
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Scatter,
  ResponsiveContainer,
  Cell,
  ReferenceLine,
} from "recharts";
import {
  Alert,
  Grid,
  Group,
  Loader,
  LoadingOverlay,
  SegmentedControl,
  Stack,
  Table,
  Text,
  useMantineTheme,
} from "@mantine/core";
import WidgetFrame from "../../WidgetFrame";
import { nFormatter } from "../../functions/formatFunctions";
import { createFilterFunction } from "../../functions/createFilterFunction";
import useLoadingStatus from "../../../../hooks/useLoadingStatus";
import { IconAlertCircle, IconCalendar } from "@tabler/icons-react";
import { CustomTooltip } from "./CustomTooltip";
// import { formatCurrency } from "../../functions/formatFunctions";

const formatPercentage = (value: number) => {
  return `${Math.round(value * 100)}%`;
};

// const filterFunctionLookup = {
//   "All Time": (value: any) => true,
//   "This Year": (value: any) => {
//     //@ts-ignore
//     const date = dayjs(value.closeDate); //@ts-ignore
//     return date.isSame(dayjs(), "year");
//   },
//   "Last Year": (value: any) => {
//     //@ts-ignore
//     const date = dayjs(value.closeDate); //@ts-ignore
//     const previousYear = dayjs().subtract(1, "year").year();
//     return date.year() === previousYear;
//   },
//   "Previous 365 Days": (value: any) => {
//     //@ts-ignore
//     const date = dayjs(value.closeDate); //@ts-ignore
//     const today = dayjs();
//     const differenceInDays = today.diff(date, "day");
//     return differenceInDays <= 365 && differenceInDays >= 0;
//   },
// };

export default function ParetoAnalysisPipeline({
  dashboardFilterObjectList,
  globalFilteredData = [],
  startDate,
  endDate,
}: any) {
  // const { dataObject }: any = useContext(AppContext);
  // const { pipelines = [] }: { pipelines: Pipeline[] } = dataObject;
  const [vitalFew, setVitalFew] = useState<any>([]);
  const [trivialMany, setTrivialMany] = useState<any>([]);
  const [mappedGroups, setMappedGroups] = useState<any>([]);
  const [timePeriod, setTimePeriod] = useState<any>("All Time");
  const loading = useLoadingStatus(["pipelines"]);

  const theme = useMantineTheme();
  //   const field = "revenueActualUSD";
  const field = "amount";

  const localFilterFunction = useMemo(
    () =>
      createFilterFunction(
        [dashboardFilterObjectList],
        "closeDate",
        startDate,
        endDate
      ),
    [dashboardFilterObjectList, startDate, endDate]
  );

  // const [total, setTotal] = useState<any>([]);

  const filteredPipelines: Pipeline[] = useMemo(() => {
    if (!globalFilteredData) return [];
    return globalFilteredData
      .filter(
        (pipeline: any) =>
          pipeline.period === "Live" && pipeline?.stageName === "Closed Won" //&& //@ts-ignore
        // filterFunctionLookup[timePeriod](pipeline)
        //   pipeline.programmePipelinesId === "9249edbd-d5d1-4682-988e-2a83d66cf077"
      )
      .filter(localFilterFunction);
  }, [globalFilteredData, localFilterFunction]);

  const total = useMemo(() => {
    return filteredPipelines.reduce((total: any, pipeline: any) => {
      total += pipeline.amount;
      return total;
    }, 0);
  }, [filteredPipelines]);

  let refLine: any = useRef("");

  useEffect(() => {
    const newVitalFew: any = [];
    const newTrivialMany: any = [];

    let inVitalFew = true;
    let percentageRunningTotal: number = 0;

    const groupTotalsObject = filteredPipelines.reduce(
      (totalsObject: any, pipeline: any) => {
        const accountName = pipeline?.account?.name;
        if (!totalsObject[accountName]) totalsObject[accountName] = 0;
        totalsObject[accountName] += Number(pipeline[field]) ?? 0;
        return totalsObject;
      },
      {}
    );

    const sortedGroupKeys: any[] = Object.keys(groupTotalsObject).sort(
      (a: any, b: any) => {
        return groupTotalsObject[b] - groupTotalsObject[a];
      }
    );

    const newMappedGroups = sortedGroupKeys.map((groupName, index) => {
      const groupObject: any = {
        groupName,
        value: groupTotalsObject[groupName],
      };
      if (percentageRunningTotal < 0.8) {
        newVitalFew.push(groupObject);
        groupObject.paretoClassification = "Vital Few";
      } else {
        if (inVitalFew) {
          refLine.current = sortedGroupKeys[index - 1];
          inVitalFew = false;
        }
        newTrivialMany.push(groupObject);
        groupObject.paretoClassification = "Trivial Many";
      }

      const val = groupTotalsObject[groupName] ?? 0;
      const addValue = val < 0 ? 0 : val;

      const percentageOfTotal = addValue / total;
      percentageRunningTotal += percentageOfTotal;
      groupObject.runningPercentageTotal = percentageRunningTotal;
      return groupObject;
    });

    setMappedGroups(newMappedGroups);
    setVitalFew(newVitalFew);
    setTrivialMany(newTrivialMany);
  }, [filteredPipelines, total]);

  // const groupedAll = groupByField(mappedPipelines, "region");

  return (
    <WidgetFrame
      title="Customer Pareto Analysis"
      icon={IconCalendar}
      // controls={
      //   <SegmentedControl
      //     value={timePeriod}
      //     onChange={(value) => setTimePeriod(value)}
      //     data={["All Time", "This Year", "Last Year", "Previous 365 Days"]}
      //   />
      // }
    >
      {loading && <LoadingOverlay visible={true} overlayOpacity={0} />}
      {!loading && (
        <Grid sx={{ height: "100%", width: "100%" }}>
          <Grid.Col md={6} sx={{ height: "100%" }}>
            <Stack sx={{ width: "100%" }}>
              <Group>
                <Text>
                  <Text span fw={700} size={"lg"}>
                    {Math.round((vitalFew.length * 100) / mappedGroups.length)}%
                  </Text>{" "}
                  of customers are responsible for{" "}
                  <Text span fw={700} size={"lg"}>
                    80%
                  </Text>{" "}
                  of sales revenue
                </Text>
                {/* <div>
                Trivial many: {trivialMany.length} (
                {Math.round((trivialMany.length * 100) / mappedGroups.length)}
                %)
              </div> */}
              </Group>
              <Alert
                styles={(theme) => ({
                  label: {
                    width: "100%",
                  },
                })}
                title={
                  <Group position="apart">
                    <Text>
                      Vital Few ({`${vitalFew.length} / ${mappedGroups.length}`}
                      )
                    </Text>
                    <Text>
                      £
                      {nFormatter(
                        vitalFew.reduce((total: any, obj: any) => {
                          total += obj.value;
                          return total;
                        }, 0),
                        1
                      )}
                    </Text>
                  </Group>
                }
              >
                {vitalFew.map((groupObject: any, index: number) => {
                  return (
                    <Group position="apart" key={index}>
                      <Text>{groupObject.groupName}</Text>

                      <Group>
                        {/* {groupObject.groupName === "VMware UK Ltd" && (
                          <Alert
                            icon={<IconAlertCircle size="1rem" />}
                            title="2 Very High Risks"
                            color="red"
                            px={5}
                            py={0}
                            my={1}
                            radius={"lg"}
                          >
                            <></>
                          </Alert>
                        )}
                        {groupObject.groupName ===
                          "DXC/Synergize Consulting Ltd" && (
                          <Alert
                            icon={<IconAlertCircle size="1rem" />}
                            title="CSAT Decreased by 27"
                            color="red"
                            px={5}
                            py={0}
                            my={1}
                            radius={"lg"}
                          >
                            <></>
                          </Alert>
                        )} */}
                        <Text fw={700}>
                          £{nFormatter(groupObject.value, 1)}
                        </Text>
                      </Group>
                    </Group>
                  );
                })}
              </Alert>
              <Alert
                styles={(theme) => ({
                  label: {
                    width: "100%",
                  },
                })}
                title={
                  <Group position="apart">
                    <Text>
                      Trivial Many (
                      {`${trivialMany.length} / ${mappedGroups.length}`})
                    </Text>
                    <Text>
                      £
                      {nFormatter(
                        trivialMany.reduce((total: any, obj: any) => {
                          total += obj.value;
                          return total;
                        }, 0),
                        1
                      )}
                    </Text>
                  </Group>
                }
              >
                <></>
              </Alert>
            </Stack>
          </Grid.Col>
          <Grid.Col md={6} sx={{ height: "100%" }}>
            <div style={{ height: "100%", width: "100%", maxHeight: "100vh" }}>
              <ResponsiveContainer width="100%" height="100%">
                <ComposedChart
                  width={500}
                  height={400}
                  data={mappedGroups}
                  margin={{
                    top: 20,
                    right: 20,
                    bottom: 20,
                    left: 20,
                  }}
                >
                  {/* <CartesianGrid stroke="#f5f5f5" /> */}
                  <XAxis dataKey="groupName" tick={false} />
                  <YAxis
                    yAxisId="left-axis"
                    domain={[0, "auto"]}
                    tick={{
                      fontSize: `1em`,
                      fill: theme.colorScheme === "dark" ? "#ffffff" : "#666",
                    }}
                    tickFormatter={(value) => `£${nFormatter(value, 0)}`}
                  />
                  <YAxis
                    yAxisId="right-axis"
                    orientation="right"
                    tick={{
                      fontSize: `1em`,
                      fill: theme.colorScheme === "dark" ? "#ffffff" : "#666",
                    }}
                    tickFormatter={(value) => formatPercentage(value)}
                  />
                  <ReferenceLine
                    yAxisId="right-axis"
                    y={0.8}
                    label="80%"
                    strokeDasharray="3 3"
                    stroke={theme.colorScheme === "dark" ? "#ffffff" : "#666"}
                  />
                  <ReferenceLine
                    x={refLine}
                    label="Vital Few Trivial Many"
                    strokeDasharray="3 3"
                    yAxisId="right-axis"
                  />
                  <Tooltip cursor={false} content={<CustomTooltip />} />
                  {/* <Legend /> */}
                  <Bar
                    dataKey={"value"}
                    //   barSize={20}
                    //   fill={theme.colors[theme.primaryColor][7]}
                    isAnimationActive={false}
                    yAxisId="left-axis"
                  >
                    {mappedGroups.map((pipeline: any, index: any) => {
                      return (
                        <Cell
                          key={`cell-${index}`}
                          fill={
                            theme.colors[theme.primaryColor][
                              pipeline.paretoClassification === "Vital Few"
                                ? 7
                                : 2
                            ]
                          }
                        />
                      );
                    })}
                  </Bar>
                  <Line
                    type="monotone"
                    dataKey="runningPercentageTotal"
                    stroke={theme.colors[theme.primaryColor][9]}
                    yAxisId="right-axis"
                    dot={false}
                  />
                </ComposedChart>
              </ResponsiveContainer>
            </div>
          </Grid.Col>
        </Grid>
      )}
    </WidgetFrame>
  );
}
