import { formatDateString } from "../../components/common/functions/formatFunctions";
import { ISchema } from "../SchemaTypes";

const common: ISchema = {
  integrationSystemId: {
    title: "Integration System ID",
    field: "integrationSystemId",
    excludeFromForm: true,
    excludeFromChartBuilder: true,
    formOptions: {
      required: false,
    },
  },
  id: {
    title: "ID",
    field: "id",
    type: "text",
    excludeFromForm: true,
    formOptions: { required: false },
    enableGlobalFilter: false,
  },
  period: {
    title: "Period",
    field: "period",
    formOptions: { required: false },
    excludeFromForm: true,
    equivalentFields: { all: "period" },
  },
  audit: {
    title: "Audit",
    field: "audit",
    type: "text",
    excludeFromForm: true,
    excludeFromDataView: true,
    excludeFromChartBuilder: true,
    formOptions: { required: false },
    enableGlobalFilter: false,
  },
  createdAt: {
    title: "Created At",
    field: "createdAt",
    type: "date",
    formatFunction: formatDateString,
    excludeFromForm: true,
    formOptions: { required: false },
  },
  updatedAt: {
    title: "Updated At",
    field: "updatedAt",
    type: "date",
    formatFunction: formatDateString,
    excludeFromForm: true,
    formOptions: { required: false },
  },
};

export default common;
