import { useContext, useState } from "react";
import {
  createStyles,
  MultiSelect,
  NativeSelect,
  Stack,
  Grid,
} from "@mantine/core";
//@ts-ignore
import { DatePickerInput } from "@mantine/dates";
import Table from "../../common/Table";
import AppContext from "../../../context/AppContext";
import {
  risksSchema,
  changesSchema,
  peopleSchema,
  csatsSchema,
  weeklyUpdatesSchema,
  deliverablesSchema,
  statementsSchema,
  projectsSchema,
} from "../../../context/dataSchema";
import getDatasetAtPointInTime from "../../common/functions/getDatasetAtPointInTime";

const useStyles = createStyles((theme) => ({
  grid: {
    display: "grid",
    gridTemplateRows: "auto 1fr",
    gap: 16,
    maxWidth: "100vw",
  },
}));

export default function DataView() {
  const { dataObject } = useContext(AppContext);
  const {
    // accounts,
    risks = [],
    statements = [],
    changes = [],
    csats = [],
    people = [],
    weeklyUpdates = [],
    deliverables = [],
    projects = [],
  } = dataObject;

  const dataSourceObject: any = {
    Risks: {
      data: risks,
      columns: Object.values(risksSchema),
    },
    "Statements of Work": {
      data: statements,
      columns: Object.values(statementsSchema),
    },
    People: {
      data: people,
      columns: Object.values(peopleSchema),
    },
    Deliverables: {
      data: deliverables,
      columns: Object.values(deliverablesSchema),
    },
    "Change Requests": {
      data: changes,
      columns: Object.values(changesSchema),
    },
    CSATS: {
      data: csats,
      columns: Object.values(csatsSchema),
    },
    "Weekly Updates": {
      data: weeklyUpdates,
      columns: Object.values(weeklyUpdatesSchema),
    },
    Projects: {
      data: projects,
      columns: Object.values(projectsSchema),
    },
  };

  const columnFilterFunction = (column: any) => {
    return !column.excludeFromDataView;
  };

  const [dataSource, setDataSource] = useState("Risks");
  const [selectedFields, setSelectedFields] = useState(
    dataSourceObject[dataSource].columns
      .filter(columnFilterFunction)
      .map((column: any) => column.field)
  );
  const [viewDate, setViewDate] = useState(new Date());
  const { classes } = useStyles();

  const { data: selectedData, columns: allColumns } =
    dataSourceObject[dataSource];

  const filteredData = getDatasetAtPointInTime(selectedData, viewDate);

  return (
    <div className={classes.grid}>
      <div>
        <Grid grow>
          <Grid.Col md={2}>
            <Stack>
              <NativeSelect
                data={Object.keys(dataSourceObject)}
                label="Select datasource"
                value={dataSource}
                onChange={(e) => {
                  setDataSource(e.target.value);
                  setSelectedFields(
                    dataSourceObject[e.target.value].columns
                      .filter(columnFilterFunction)
                      .map((column: any) => column.field)
                  );
                }}
              />
            </Stack>
          </Grid.Col>
          <Grid.Col md={9}>
            <MultiSelect
              data={allColumns.map((columnObject: any) => {
                return { value: columnObject.field, label: columnObject.title };
              })}
              label="Select Fields"
              value={selectedFields}
              onChange={(e) => setSelectedFields(e)}
            />
          </Grid.Col>
          <Grid.Col md={1}>
            <DatePickerInput
              label="View As Of"
              value={viewDate}
              maxDate={new Date()}
              valueFormat="DD/MM/YYYY"
              clearable={false}
              onChange={(date: any) => {
                setViewDate(date);
              }}
            />
          </Grid.Col>
        </Grid>
      </div>
      <div style={{ overflow: "scroll", height: "100%" }}>
        <Table
          data={filteredData}
          columns={allColumns.filter((column: any) =>
            selectedFields.includes(column.field)
          )}
        />
      </div>
    </div>
  );
}
