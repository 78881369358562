import { useContext } from "react";
import CrudScreen from "../common/CrudScreen";
import AppContext from "../../context/AppContext";
import { projectsSchema } from "../../context/dataSchema";
// import ProjectQuickStats from "./ProjectQuickStats";
import { createProject, deleteProject } from "../../graphql/mutations";
import { updateProject } from "../../graphql-custom/updatedMutations";

export default function Projects(props: any) {
  const { dataObject } = useContext(AppContext);
  const { projects: data = [] } = dataObject;

  const screenProps = {
    data,
    schema: projectsSchema,
    title: "Projects",
    quickStats: /*<ProjectQuickStats projects={projects} />*/ null,
    tableColumns: ["name", "startDate", "endDate", "status"],
    filterFields: [],
    recordName: "Project",
    pagePath: "projects",
    formProps: {
      createQuery: createProject,
      updateQuery: updateProject,
      deleteQuery: deleteProject,
    },
    dataObjectKey: "projects",
    modulesToLoad: [
      "Verticals",
      "Accounts",
      "Programmes",
      "People",
      "Regions",
      "Projects",
    ],
  };
  return <CrudScreen {...screenProps} />;
}
