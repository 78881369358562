import { useCallback, useContext, useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
import {
  createStyles,
  Header,
  // Menu,
  Group,
  // Center,
  Burger,
  Container,
  Text,
  MediaQuery,
  Image,
  Breadcrumbs,
  Badge,
  useMantineColorScheme,
  // Box,
  // Anchor,
} from "@mantine/core";
// import { ChevronDown } from "tabler-icons-react";
import { ColorSchemeToggle } from "./ColorSchemeToggle";

// import { exportToExcel } from "../common/functions/excelExport";

import AppContext from "../../context/AppContext";
import { Storage } from "aws-amplify";

const useStyles = createStyles((theme) => ({
  inner: {
    height: 56,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  links: {
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },

  burger: {
    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },

  link: {
    display: "block",
    lineHeight: 1,
    padding: "8px 12px",
    borderRadius: theme.radius.sm,
    textDecoration: "none",
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[7],
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    },
  },

  linkLabel: {
    marginRight: 5,
  },

  header: {
    "&::after": {
      content: '""',
      position: "absolute",
      bottom: 0,
      left: 0,
      right: 0,
      width: "100%",
      // height: "6px",
      height: "6px",
      // backgroundColor: theme.colors[theme.primaryColor][9],
      // backgroundImage:
      //   "linear-gradient(to right,#6fbe2e 19%,#00bbd6 43%,#0f61a9 57%,#5a39a0 81%)",
      backgroundImage: `linear-gradient(to right,${
        theme.colors[theme.primaryColor][8]
      } 19%,${theme.colors[theme.primaryColor][4]} 43%,${
        theme.colors[theme.primaryColor][3]
      } 57%,${theme.colors[theme.primaryColor][6]} 81%)`,
    },
  },
}));

export function DashboardHeader({
  // links,
  opened,
  setOpened,
}: // user,
// signOut,
any) {
  const { classes, theme } = useStyles();

  const {
    systemPreferenceObject: preferenceObject,
    // logo,
    // darkLogo,
    account,
    viewType,
    currentVertical,
    currentAccount,
    currentProgramme,
    currentTenant,
    dataObject: { verticals, accounts, programmes, tenants },
  } = useContext(AppContext);
  const { colorScheme } = useMantineColorScheme();
  const [logo, setLogo] = useState<null | string>(null);

  const updateLogo = useCallback(async (tenant: any, scheme: any) => {
    const newLogo = await Storage.get(tenant + "_" + scheme);
    setLogo(newLogo);
  }, []);

  useEffect(() => {
    updateLogo(
      viewType === "Internal" ? currentTenant : `${currentTenant}_${account}`,
      colorScheme
    );
  }, [currentTenant, colorScheme, updateLogo, viewType, account]);

  const title = preferenceObject
    ? viewType === "Customer"
      ? preferenceObject.customers[account]?.systemTitle
      : preferenceObject.appearance?.systemTitle
    : "";

  return (
    <Header height={60} mb={120} className={classes.header}>
      <Container sx={{ maxWidth: "100%", overflow: "hidden" }}>
        <div className={classes.inner}>
          <Group spacing={5}>
            <Group
              style={{
                maxWidth: "200px",
                minWidth: "200px",
                minHeight: 56,
              }}
            >
              {logo && (
                <Image
                  src={logo}
                  alt="Logo"
                  mr={"md"}
                  withPlaceholder
                  // pb={5}
                  // pt={5}
                  // style={{ maxHeight: 45 }}
                  styles={{
                    //@ts-ignore
                    image: { maxHeight: 45, objectFit: "contain !important" },
                  }}
                />
              )}
            </Group>

            <MediaQuery smallerThan={1200} styles={{ display: "none" }}>
              <Text
                size="xl"
                weight={"bolder"}
                color="dimmed"
                sx={{ fontFamily: `"Cabin", sans-serif`, cursor: "default" }}
              >
                {title}
              </Text>
            </MediaQuery>
          </Group>

          <Group spacing={"md"}>
            {currentVertical &&
              currentAccount &&
              currentProgramme &&
              verticals &&
              accounts &&
              programmes && (
                <MediaQuery smallerThan={1200} styles={{ display: "none" }}>
                  <Group>
                    {/* <MediaQuery smallerThan={1200} styles={{ display: "none" }}> */}
                    <Breadcrumbs>
                      <Text
                        color={theme.primaryColor}
                        fw={500}
                        sx={{ cursor: "default" }}
                      >
                        {
                          tenants.filter((v: any) => v.id === currentTenant)[0]
                            .name
                        }
                      </Text>
                      <Text
                        color={
                          currentVertical === "All"
                            ? undefined
                            : theme.primaryColor
                        }
                        fw={500}
                        sx={{ cursor: "default" }}
                      >
                        {currentVertical === "All"
                          ? "All Verticals"
                          : verticals.filter(
                              (v: any) => v.id === currentVertical
                            )[0].name}
                      </Text>

                      <Text
                        color={
                          currentProgramme === "All"
                            ? undefined
                            : theme.primaryColor
                        }
                        fw={500}
                        sx={{ cursor: "default" }}
                      >
                        {currentProgramme === "All"
                          ? "All Programmes"
                          : programmes.filter(
                              (p: any) => p.id === currentProgramme
                            )[0].name}
                      </Text>
                    </Breadcrumbs>
                    {/* </MediaQuery> */}
                    <Badge
                      // color={
                      //   currentAccount === "All"
                      //     ? undefined
                      //     : theme.primaryColor
                      // }
                      // fw={500}
                      sx={{ cursor: "default" }}
                    >
                      {currentAccount === "All"
                        ? "All Accounts"
                        : accounts.filter(
                            (a: any) => a.id === currentAccount
                          )[0].name}
                    </Badge>
                  </Group>
                </MediaQuery>
              )}
            <ColorSchemeToggle />
            <Burger
              opened={opened}
              onClick={() => setOpened(!opened)}
              className={classes.burger}
              size="sm"
            />
          </Group>
        </div>
      </Container>
    </Header>
  );
}
