import { createStyles } from "@mantine/core";

export default createStyles((theme) => ({
  tabPanel: {
    // height: "97%",
  },
  grid: {
    display: "grid",
    height: "100%",
    gridTemplateRows: "auto 1fr",
  },
  tab: {
    zIndex: 100,
  },
  tabLabel: {
    zIndex: -1,
  },
}));
