import { useContext } from "react";
import CrudScreen from "../common/CrudScreen";
import AppContext from "../../context/AppContext";
import { projectRoleSchema as schema } from "../../context/dataSchema";
import {
  createProjectRole as createQuery,
  updateProjectRole as updateQuery,
  deleteProjectRole as deleteQuery,
} from "../../graphql/mutations";

export default function ProjectRoles() {
  const { dataObject } = useContext(AppContext);
  const { projectRoles: data = [] } = dataObject;

  const screenProps = {
    data,
    schema,
    title: "Project Roles",
    quickStats: null,
    tableColumns: ["project", "description"],
    filterFields: [],
    recordName: "Project Role",
    pagePath: "project-roles",
    dataObjectKey: "projectRoles",
    formProps: {
      createQuery,
      updateQuery,
      deleteQuery,
      dataObjectKey: "projectRoles",
    },
    modulesToLoad: [
      "Verticals",
      "Projects",
      "Pipelines",
      "Role Types",
      "Project Roles",
    ],
  };
  return <CrudScreen {...screenProps} />;
}
