import { DragDropContext } from "react-beautiful-dnd";
import { useEffect, useMemo, useState, useContext, useCallback } from "react";
import AppContext from "../../../../context/AppContext";
import {
  ActionIcon,
  Button,
  Modal,
  NativeSelect,
  TextInput,
  createStyles,
  useMantineTheme,
} from "@mantine/core";
import DraggableArea from "./DraggableArea";
import CustomWidget from "./CustomWidget";
import { getDataSchema } from "../../functions/getDataSchema";
import {
  IconFilter,
  IconSort09,
  IconSort90,
  IconSortAZ,
} from "@tabler/icons-react";
import { useDisclosure } from "@mantine/hooks";
import WidgetFilterCreation from "./WidgetFilterCreation";
import WidgetFrame from "../../WidgetFrame";
import { defaultChartProps } from "../ChartUtilities";
import { IChartType } from "../Types/ChartPropsTypes";
// import { ISchema } from "../../../../context/SchemaTypes";
import ChartBuilderControls from "./ChartBuilderControls";
import ChartErrorBoundary from "./ChartErrorBoundary";
import getFinalPeriods from "../../../dashboard/functions/getFinalPeriods";
import getComparisonPeriodString from "../../../dashboard/functions/getComparisonPeriodString";
import { CustomDataset } from "../../../../API";
// import useFetchData from "../../../../hooks/useFetchData";
import useStyles from "./ChartBuilder.styles";

type OrderObject = {
  value_z_to_a: {
    icon: JSX.Element;
    nextValue: "value_a_to_z";
  };
  value_a_to_z: {
    icon: JSX.Element;
    nextValue: "key_a_to_z";
  };
  key_a_to_z: {
    icon: JSX.Element;
    nextValue: "value_z_to_a";
  };
};

const orderObject: OrderObject = {
  value_z_to_a: {
    icon: <IconSort90 />,
    nextValue: "value_a_to_z",
  },
  value_a_to_z: {
    icon: <IconSort09 />,
    nextValue: "key_a_to_z",
  },
  key_a_to_z: {
    icon: <IconSortAZ />,
    nextValue: "value_z_to_a",
  },
};

type IVariantLookup = {
  [chartType: string]: string[];
};

const variantLookup: IVariantLookup = {
  "Pivot Table": [
    "Table",
    "Table Heatmap",
    "Table Col Heatmap",
    "Table Row Heatmap",
  ],
  "Data Table": ["Standard"],
  "Bar Chart": ["Standard", "Stacked Bar"],
  "Column Chart": ["Standard", "Stacked Column"],
  "Area Chart": ["Standard", "Stacked Area"],
  "Line Chart": ["Standard"],
  "Pie Chart": ["Standard", "RAG"],
  "Scatter Chart": ["Standard"],
  Scorecard: ["Standard"],
  "Box Plot": ["Standard"],
  Treemap: ["Standard"],
  "Radar Chart": ["Standard"],
  Map: ["Choropleth"],
  Title: ["Left", "Center", "Right"],
};

const chartTypes: IChartType[] = [
  "Bar Chart",
  "Column Chart",
  "Area Chart",
  "Line Chart",
  "Pie Chart",
  "Pivot Table",
  "Data Table",
  "Scatter Chart",
  "Box Plot",
  "Scorecard",
  "Treemap",
  "Radar Chart",
  "Map",
  "Title",
];

export default function ChartBuilder(props: any) {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const { systemPreferenceObject, dataObject = {} } = useContext(AppContext);
  const { customDatasets = [] } = dataObject ?? {};

  const periods = ["Live", "Last 12 Months", "Last 4 Quarters", "Last 30 Days"];

  const [
    filterModalOpened,
    { open: openFilterModal, close: closeFilterModal },
  ] = useDisclosure(false);
  const {
    addWidget,
    currentWidget,
    updateWidget,
    currentWidgetIndex,
    setCurrentWidgetIndex,
    setCurrentWidget,
    onClose,
    setView,
    chartProps,
    setChartProps,
    dataSourceObject,
    globalDashboardFilteredDataObject,
  } = props;

  const [dataSourceKey, setDataSourceKey] = useState<string>(
    currentWidget && currentWidget.configuration
      ? currentWidget.configuration.dataSourceKey
      : "projects"
  );

  useEffect(() => {
    setChartProps((chartProps: any) =>
      currentWidget
        ? { ...chartProps, ...currentWidget.configuration }
        : chartProps
    );
    setDataSourceKey(
      currentWidget && currentWidget.configuration
        ? currentWidget.configuration.dataSourceKey
        : "projects"
    );
  }, [currentWidget, setChartProps]);

  const schema = useMemo(
    () => getDataSchema(dataSourceKey, customDatasets),
    [dataSourceKey, customDatasets]
  );

  const validKeys = useMemo(() => {
    return Object.keys(schema)
      .filter((field: string) => {
        return !schema[field].excludeFromChartBuilder;
      })
      .sort((a: any, b: any) => {
        return schema[a].type === schema[b].type
          ? 0 //@ts-ignore
          : schema[a].type > schema[b]?.type
          ? 1
          : -1;
      });
  }, [schema]);

  const [data, setData] = useState<any>({
    unused: validKeys.filter((field: string) => {
      return (
        !currentWidget?.configuration?.rows.includes(field) &&
        !currentWidget?.configuration?.cols.includes(field) &&
        !currentWidget?.configuration?.values.includes(field)
      );
    }),
    rows: currentWidget ? currentWidget.configuration?.rows : [],
    columns: currentWidget ? currentWidget.configuration?.cols : [],
    values: currentWidget ? currentWidget.configuration?.values : [],
  });

  // useFetchData(
  //   dataKeyToModule[dataSourceKey] ? [dataKeyToModule[dataSourceKey]] : [],
  //   []
  // );

  const updateChartProps: (obj: { [key: string]: any }) => void = useCallback(
    (object: { [key: string]: any }) => {
      // const oldPropsCopy = structuredClone(chartProps);
      // const newChartProps = { ...oldPropsCopy, ...object };
      setChartProps((chartProps: any) => ({ ...chartProps, ...object }));
    },
    [chartProps]
  );

  const OrderToggle = useCallback(
    (props: { currentValue: string; setValueFunction: any }) => {
      const { currentValue, setValueFunction } = props;

      const currentObject: {
        icon: JSX.Element;
        nextValue: "value_a_to_z" | "key_a_to_z" | "value_z_to_a";
      } = orderObject[currentValue as keyof OrderObject];

      return (
        <ActionIcon
          color={theme.primaryColor}
          onClick={() => setValueFunction(currentObject.nextValue)}
        >
          {currentObject.icon}
        </ActionIcon>
      );
    },
    [theme.primaryColor]
  );

  useEffect(() => {
    if (
      !currentWidget ||
      currentWidget.configuration?.dataSourceKey !== dataSourceKey
    ) {
      setData({
        unused: validKeys,
        rows: [],
        columns: [],
        values: [],
      });
    }
    updateChartProps({ dataSourceKey });
  }, [validKeys]);

  useEffect(() => {
    updateChartProps({ dataSourceKey });
  }, [dataSourceKey]);

  useEffect(() => {
    updateChartProps({
      cols: data.columns,
      rows: data.rows,
      values: data.values,
    });
  }, [data]);

  const onDragEnd = useCallback(
    (result: any) => {
      const { source, destination, draggableId } = result;
      if (!destination) {
        return;
      }
      const { droppableId: startList, index: startIndex } = source;
      const { droppableId: endList, index: endIndex } = destination;
      const newColumns = JSON.parse(JSON.stringify(data));

      newColumns[startList].splice(startIndex, 1);
      newColumns[endList].splice(endIndex, 0, draggableId);

      setData(newColumns);
    },
    [data]
  );

  const variants = useMemo(
    () =>
      chartProps
        ? variantLookup[chartProps.chartType as keyof IVariantLookup]
        : [],
    [chartProps]
  );

  const dataSourceOptions = useMemo(() => {
    return [
      // { label: "MRRs", value: "mrrs" },
      // { label: "Costs", value: "costs" },
      // { label: "PLs", value: "pls" },
      { label: "Projects", value: "projects" },
      { label: "Risks", value: "risks" },
      { label: "Statements of Work", value: "statements" },
      { label: "CSATs", value: "csats" },

      { label: "Accounts", value: "accounts" },
      { label: "People", value: "people" },

      { label: "Deliverables", value: "deliverables" },
      // { label: "Change Requests", value: "changes" },
      // { label: "Weekly Updates", value: "csats" },

      { label: "Verticals", value: "verticals" },
      { label: "Programmes", value: "programmes" },

      { label: "Invoices", value: "invoices" },
      {
        label: "Employee Satisfaction",
        value: "employeeSatisfaction",
      },
      { label: "Pipelines", value: "pipelines" },
      { label: "Project Allocations", value: "projectAllocations" },
      { label: "Project Roles", value: "projectRoles" },
      // { label: "Regions", value: "regions" },
      { label: "Role Types", value: "roleTypes" },
      { label: "Tasks", value: "tasks" },
      { label: "Tenants", value: "tenants" },
      { label: "Time Sheets", value: "timesheets" },
    ]
      .filter((row: any) => systemPreferenceObject?.modules[row.label].active)
      .concat(
        customDatasets.map((customDataset: CustomDataset) => {
          return { value: customDataset.id, label: customDataset.name };
        })
      );
  }, [systemPreferenceObject, customDatasets]);

  const globalFilteredData = useMemo(() => {
    const chartPeriods = getFinalPeriods(chartProps.period);

    return chartPeriods.reduce((data: any, period: any) => {
      data = data.concat(
        globalDashboardFilteredDataObject &&
          globalDashboardFilteredDataObject[dataSourceKey] &&
          globalDashboardFilteredDataObject[dataSourceKey][period]
          ? globalDashboardFilteredDataObject[dataSourceKey][period]
          : []
      );
      return data;
    }, []);
  }, [dataSourceKey, globalDashboardFilteredDataObject, chartProps.period]);

  const comparisonPeriodObject = useMemo(
    () =>
      getComparisonPeriodString({
        comparisonPeriod: chartProps.comparisonPeriod,
      }),
    [chartProps.comparisonPeriod]
  );

  const comparisonGlobalFilteredData = useMemo(() => {
    const comparisonPeriods = [
      comparisonPeriodObject.date.toISOString().slice(0, 10),
    ];
    return comparisonPeriods.reduce((data: any, period: any) => {
      data = data.concat(
        globalDashboardFilteredDataObject &&
          globalDashboardFilteredDataObject[dataSourceKey] &&
          globalDashboardFilteredDataObject[dataSourceKey][period]
          ? globalDashboardFilteredDataObject[dataSourceKey][period]
          : []
      );
      return data;
    }, []);
  }, [
    dataSourceKey,
    globalDashboardFilteredDataObject,
    comparisonPeriodObject,
  ]);

  const dateFields = useMemo(() => {
    return Object.values(schema)
      .filter((obj) => obj.type === "date")
      .map((obj) => {
        return { label: obj.title, value: obj.field };
      });
  }, [schema]);

  if (!chartProps) return null;
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className={classes.builder}>
        <div className={classes.toolbar}>
          <NativeSelect
            label="Data Source"
            data={dataSourceOptions}
            value={dataSourceKey}
            onChange={(e) => setDataSourceKey(e.target.value)}
          />
          <NativeSelect
            label="Chart Type"
            data={chartTypes}
            value={chartProps.chartType}
            onChange={(e) => {
              updateChartProps({
                chartType: e.target.value,
                //@ts-ignore
                chartVariant: variantLookup[e.target.value][0],
              });
            }}
          />
          <NativeSelect
            label="Type"
            data={variants}
            value={chartProps.chartVariant}
            onChange={(e) => updateChartProps({ chartVariant: e.target.value })}
          />
          <TextInput
            value={chartProps.chartTitle}
            onChange={(e) => updateChartProps({ chartTitle: e.target.value })}
            label="Chart Title"
            sx={{ flexGrow: 1 }}
          />
          <NativeSelect
            label="Date Field"
            value={chartProps.dateField}
            onChange={(e) => updateChartProps({ dateField: e.target.value })}
            data={[{ label: "None", value: "" }].concat(dateFields)}
            className={classes.control}
          />
          <NativeSelect
            label="Period"
            data={periods}
            value={chartProps.period}
            onChange={(e) => {
              updateChartProps({
                period: e.target.value,
              });
            }}
          />
          <NativeSelect
            label="Comparison Period"
            data={[
              "None",
              "Yesterday",
              "Last Week",
              "Last Month",
              "Last Quarter",
              "Six Months Ago",
              "Twelve Months Ago",
            ]}
            value={chartProps.comparisonPeriod}
            onChange={(e) => {
              updateChartProps({
                comparisonPeriod: e.target.value,
              });
            }}
          />
          <Button
            variant="outline"
            leftIcon={<IconFilter />}
            onClick={openFilterModal}
          >
            Filter
          </Button>
          <Button
            onClick={() => {
              if (currentWidget) {
                updateWidget(
                  { ...currentWidget, configuration: chartProps },
                  currentWidgetIndex
                );
              } else {
                addWidget({
                  widget: "custom",
                  configuration: chartProps,
                  dataGrid: {
                    lg: { x: 0, y: 0, w: 6, h: 12 },
                    md: { x: 0, y: 0, w: 6, h: 12 },
                    sm: { x: 0, y: 0, w: 1, h: 6 },
                  },
                });
              }
              setCurrentWidget(null);
              setCurrentWidgetIndex(null);
              setChartProps(defaultChartProps);
              setView("Add Widget");
              onClose();
            }}
          >
            Save
          </Button>
        </div>
        <div className={classes.controls}>
          <ChartBuilderControls
            chartProps={chartProps}
            updateChartProps={updateChartProps}
          />
        </div>
        <div className={classes.unused}>
          <DraggableArea
            columns={data}
            column={"unused"}
            // direction="horizontal"
            title={"Unused"}
            schema={schema}
            includeSearch={true}
          />
        </div>
        <div className={classes.values}>
          <DraggableArea
            columns={data}
            column={"values"}
            title={"Values"}
            schema={schema}
            controlsComponent={
              <NativeSelect
                // label="Aggregation Type"
                data={[
                  "Sum",
                  "Count",
                  "Count Unique Values",
                  "Average",
                  "Median",
                  "Maximum",
                  "Minimum",
                  "Sample Variance",
                  "Sample Standard Deviation",
                  "Last",
                  "First",
                  "Difference (Sum)",
                  "Sum over Sum",
                ]}
                value={chartProps.aggregatorName}
                onChange={(e) =>
                  updateChartProps({ aggregatorName: e.target.value })
                }
              />
            }
          />
        </div>
        <div className={classes.rows}>
          <DraggableArea
            columns={data}
            column={"rows"}
            title={"Rows"}
            schema={schema}
            controlsComponent={
              <OrderToggle
                currentValue={chartProps.rowOrder}
                setValueFunction={(value: any) =>
                  updateChartProps({ rowOrder: value })
                }
              />
            }
          />
        </div>
        <div className={classes.columns}>
          <DraggableArea
            columns={data}
            column={"columns"}
            // direction="horizontal"
            title={"Columns"}
            schema={schema}
            controlsComponent={
              <OrderToggle
                currentValue={chartProps.colOrder}
                setValueFunction={(value: any) =>
                  updateChartProps({ colOrder: value })
                }
              />
            }
          />
        </div>
        <div className={classes.graph}>
          <ChartErrorBoundary>
            {chartProps.chartType === "Title" ? (
              <CustomWidget {...chartProps} />
            ) : (
              <WidgetFrame title={chartProps.chartTitle}>
                <CustomWidget
                  {...chartProps}
                  globalFilteredData={globalFilteredData}
                  comparisonGlobalFilteredData={comparisonGlobalFilteredData}
                  comparisonFieldString={comparisonPeriodObject.comparisonText}
                />
              </WidgetFrame>
            )}
          </ChartErrorBoundary>
        </div>
      </div>
      <Modal
        opened={filterModalOpened}
        onClose={closeFilterModal}
        title="Widget Filters"
        zIndex={301}
        size={"xl"}
        overlayProps={{ style: { zIndex: 300 } }}
      >
        <WidgetFilterCreation
          sourceFields={[{ value: "", label: "" }].concat(
            Object.values(schema).map((fieldObject: any) => ({
              value: fieldObject.field,
              label: fieldObject.title,
            }))
          )}
          updateChartProps={updateChartProps}
          closeFilterModal={closeFilterModal}
          currentFilters={chartProps.filterObjects}
        />
      </Modal>
    </DragDropContext>
  );
}
