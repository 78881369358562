import { useContext, useMemo, useState } from "react";
import AppContext from "../../../../context/AppContext";
import { Project } from "../../../../API";
import { Box, Center, Progress, Switch, Text } from "@mantine/core";
import {
  MRT_ColumnDef,
  type MRT_Cell,
  type MRT_Column,
  type MRT_Row,
  type MRT_TableInstance,
  type MRT_AggregationFn,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import WidgetFrame from "../../WidgetFrame";
import PercentStat from "./PercentStat";
import { createFilterFunction } from "../../functions/createFilterFunction";

function formatDateAsMMMYY(date: Date) {
  const formattedDate = new Intl.DateTimeFormat("en-US", {
    year: "2-digit",
    month: "short",
  }).format(date);

  return formattedDate;
}

const AggregatedCell = ({
  cell,
  table,
}: {
  cell: MRT_Cell<Project>;
  column: MRT_Column<Project>;
  row: MRT_Row<Project>;
  table: MRT_TableInstance<Project>;
}) => (
  <>
    <Box sx={{ fontWeight: "bold" }}>{Math.round(cell.getValue<number>())}</Box>
  </>
);

const AggregatedCellCurrency = ({
  cell,
  table,
}: {
  cell: MRT_Cell<Project>;
  column: MRT_Column<Project>;
  row: MRT_Row<Project>;
  table: MRT_TableInstance<Project>;
}) => (
  <>
    <Box sx={{ fontWeight: "bold" }}>
      {cell.getValue<number>()?.toLocaleString?.("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })}
    </Box>
  </>
);

const AggregatedCellPercent = ({
  cell,
  table,
  row,
}: {
  cell: MRT_Cell<Project>;
  column: MRT_Column<Project>;
  row: MRT_Row<Project>;
  table: MRT_TableInstance<Project>;
}) => {
  const value = cell.getValue<number>();
  const rounded = value ? Math.round(value * 100) : 0;
  const color = value > 0.1 ? "green" : value > 0.05 ? "orange" : "red";
  return (
    <>
      <Box sx={{ color, fontWeight: "bold" }}>
        <Center>
          <PercentStat value={rounded} />
        </Center>
      </Box>
    </>
  );
};

const Cell = ({
  cell,
}: {
  cell: MRT_Cell<Project>;
  // renderedCellValue: React.ReactNode;
  // column: MRT_Column<Project>;
  // row: MRT_Row<Project>;
  // rowRef?: React.RefObject<HTMLTableRowElement> | undefined;
  // table: MRT_TableInstance<...>;
}) => <>{Math.round(cell.getValue<number>())}</>;

const CellCurrency = ({
  cell,
}: {
  cell: MRT_Cell<Project>;
  // renderedCellValue: React.ReactNode;
  // column: MRT_Column<Project>;
  // row: MRT_Row<Project>;
  // rowRef?: React.RefObject<HTMLTableRowElement> | undefined;
  // table: MRT_TableInstance<...>;
}) => (
  <>
    {cell.getValue<number>()?.toLocaleString?.("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })}
  </>
);

const CellPercent = ({
  cell,
}: {
  cell: MRT_Cell<Project>;
  // renderedCellValue: React.ReactNode;
  // column: MRT_Column<Project>;
  // row: MRT_Row<Project>;
  // rowRef?: React.RefObject<HTMLTableRowElement> | undefined;
  // table: MRT_TableInstance<...>;
}) => {
  const value = cell.getValue<number>();
  const rounded = value ? Math.round(value * 100) : 0;
  const color = value > 0.1 ? "green" : value > 0.05 ? "orange" : "red";
  return (
    <Box sx={{ color }}>
      <Center>
        <PercentStat value={rounded} />
      </Center>
    </Box>
  );
};

const Footer = ({ sum }: any) => <Box pb={"xs"}>{Math.round(sum)}</Box>;

const FooterCurrency = ({ sum }: any) => (
  <Box pb={"xs"}>
    {sum?.toLocaleString?.("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })}
  </Box>
);

const FooterPercent = ({ sum }: any) => (
  <Box>
    <Center>
      <PercentStat value={sum} />
    </Center>
  </Box>
);

type IPLFields = Project & { startMonth: string };

export default function RemainingPayment(props: any) {
  const { dashboardFilterObjectList, updateDashboardFilter } = props;
  const [includePaid, setIncludePaid] = useState(false);
  const { dataObject, currentProgramme, currentProgrammes, period }: any =
    useContext(AppContext);
  const { projects = [] }: { projects: Project[] } = dataObject;
  // const [view, setView] = useState("financials");
  //   const theme = useMantineTheme();

  const localFilterFunction = useMemo(
    () => createFilterFunction([dashboardFilterObjectList]),
    [dashboardFilterObjectList]
  );

  const permittedProgrammeList = useMemo(
    () => (currentProgramme === "All" ? currentProgrammes : [currentProgramme]),
    [currentProgramme, currentProgrammes]
  );

  const filteredProjects: Project[] = useMemo(
    () =>
      projects
        .filter((row: any) => {
          return (
            (currentProgramme === "All" ||
              permittedProgrammeList.includes(row["programmeProjectsId"])) &&
            row.period === "Live"
          );
        })
        .filter(localFilterFunction),
    [
      projects,
      currentProgramme,
      period,
      permittedProgrammeList,
      localFilterFunction,
    ]
  );

  const fieldAddedProjects: IPLFields[] = useMemo(
    () =>
      filteredProjects
        .map((pl) => {
          const copiedPl = structuredClone(pl);

          const startMonth = copiedPl.startDate
            ? formatDateAsMMMYY(new Date(copiedPl.startDate))
            : "";

          const newPl: IPLFields = { ...copiedPl, startMonth };

          return newPl;
        })
        .sort((a: any, b: any) => {
          return (
            new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
          );
        }),
    [filteredProjects]
  );

  const totalObject: { [key: string]: number } = useMemo(() => {
    const fields: string[] = ["revenueSold", "revenueActual"];
    return fieldAddedProjects.reduce((obj, curr) => {
      fields.forEach((field) => {
        //@ts-ignore
        if (!obj[field]) obj[field] = 0;
        //@ts-ignore
        obj[field] += curr[field];
      });
      return obj;
    }, {});
  }, [fieldAddedProjects]);

  const percentageUsingTotals: any = (leafRowString: any, childRows: any) => {
    const totalsObject = childRows.reduce(
      (obj: any, row: any) => {
        obj.revenueSold += row.original.revenueSold;
        obj.revenueActual += row.original.revenueActual;
        return obj;
      },
      {
        revenueSold: 0,
        revenueActual: 0,
      }
    );
    const { revenueSold, revenueActual } = totalsObject;
    return (revenueSold - revenueActual) / revenueSold;
  };

  const columns = useMemo<MRT_ColumnDef<Project>[]>(
    () => [
      {
        header: "Month",
        accessorKey: "startMonth",
        enableGrouping: true,
        size: 0,
      },
      {
        header: "",
        accessorKey: "name",
        size: 0,
        enableGrouping: true,
        enableSorting: false,
        Cell: ({ cell }: { cell: MRT_Cell<Project> }) => {
          return (
            <>
              <Text size={"xs"}>{cell.getValue<number>()}</Text>
            </>
          );
        },
      },
      {
        header: "Revenue Sold",
        accessorKey: "revenueSold",
        aggregationFn: "sum",
        enableGrouping: false,
        AggregatedCell: AggregatedCellCurrency,
        Cell: CellCurrency,
        Footer: <FooterCurrency sum={totalObject["revenueSold"]} />,
      },
      {
        header: "Billed Redeemed",
        accessorKey: "revenueActual",
        aggregationFn: "sum",
        enableGrouping: false,
        AggregatedCell: AggregatedCellCurrency,
        Cell: CellCurrency,
        Footer: <FooterCurrency sum={totalObject["revenueActual"]} />,
      },
      {
        header: "Remaining Amount",
        accessorFn: (row) => (row.revenueSold ?? 0) - (row.consumed ?? 0),
        aggregationFn: "sum",
        enableGrouping: false,
        AggregatedCell: AggregatedCellCurrency,
        Cell: CellCurrency,
        Footer: (
          <FooterCurrency
            sum={totalObject["revenueSold"] - totalObject["revenueActual"]}
          />
        ),
      },
      {
        header: "Remaining Amount %",
        accessorFn: (row) =>
          ((row.revenueSold ?? 0) - (row.consumed ?? 0)) /
          (row.revenueSold ?? 0),
        aggregationFn: percentageUsingTotals,
        enableGrouping: false,
        AggregatedCell: AggregatedCellPercent,
        Cell: CellPercent,
        Footer: (
          <FooterPercent
            sum={Math.round(
              ((totalObject["revenueSold"] - totalObject["revenueActual"]) *
                100) /
                totalObject["revenueSold"]
            )}
          />
        ),
      },
    ],
    [totalObject]
  );

  const finalData = useMemo(() => {
    return fieldAddedProjects.filter(
      (row) => includePaid || (row.revenueSold ?? 0) - (row.consumed ?? 0) !== 0
    );
  }, [fieldAddedProjects, includePaid]);

  const table = useMantineReactTable({
    columns,
    data: finalData,
    enableColumnResizing: false,
    enableGrouping: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    groupedColumnMode: false,
    enablePagination: false,
    enablePinning: true,
    enableColumnActions: false,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    mantineTableBodyCellProps: { sx: { textWrap: "wrap" } },
    initialState: {
      density: "xs",
      //@ts-ignore
      expanded: false,
      grouping: ["startMonth"],
      //   pagination: { pageIndex: 0, pageSize: 100 },
      // sorting: [{ id: "startDate", desc: false }],
      columnPinning: {
        left: ["startMonth", "name"],
        right: [],
      },
    },
    mantineToolbarAlertBannerBadgeProps: { color: "blue", variant: "outline" },
    mantineTableContainerProps: { sx: {} },
    mantineTableProps: { style: { height: "100%" } },
    mantinePaperProps: {
      style: {
        border: "none",
        boxShadow: "none",
        maxHeight: "100%",
        height: "100%",
        display: "grid",
        gridTemplateRows: "1fr 0px ",
      },
    },
  });

  return (
    <WidgetFrame
      title={"Outstanding Amounts"}
      controls={
        <Switch
          label="Include fully paid projects"
          checked={includePaid}
          onChange={(e) => setIncludePaid(e.target.checked)}
        />
      }
    >
      <Box
        style={{
          height: "99%",
          minHeight: "99%",
          maxHeight: "99%",
        }}
      >
        {projects.length === 0 ? null : <MantineReactTable table={table} />}
      </Box>
    </WidgetFrame>
  );
}
