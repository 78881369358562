import {
  BackgroundImage,
  Box,
  Group,
  Image,
  SimpleGrid,
  Stack,
  Text,
  ThemeIcon,
  Title,
  createStyles,
  keyframes,
} from "@mantine/core";
import image from "./scroll-image.png";
import light from "../layout/smarttasking.png";
import dark from "../layout/black_logo.png";
import {
  IconGraph,
  IconHierarchy3,
  IconListCheck,
  IconRocket,
} from "@tabler/icons-react";

const animatedBackground = keyframes({
  "0%": {
    backgroundPosition: "0 0",
  },
  "100%": {
    backgroundPosition: "0 -10000px",
  },
});

const useStyles = createStyles((theme) => ({
  outer: {
    position: "relative",
    [theme.fn.largerThan("md")]: {
      height: "calc(100vh - 1em)",
    },
    [theme.fn.smallerThan("md")]: {
      paddingBottom: "2em",
    },
    width: "100%",
    maxWidth: "100vw",
  },
  container: {
    display: "grid",
    gridTemplateRows: "1fr",
    gridTemplateColumns: "3fr 4fr",
    overflow: "hidden",
    height: "100%",
    width: "100%",
    maxWidth: "100vw",
  },
  imageContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "120%",
  },
  overlay: {
    backgroundImage: `linear-gradient(45deg, ${
      theme.colorScheme === "dark" ? "#141517" : "#e9ecef"
    } 25%, transparent 95%)`,
    zIndex: 1,
    height: "100%",
    width: "100%",
    position: "absolute",
  },
  image: {
    animation: `${animatedBackground} 500s linear infinite`,
    transformOrigin: "50% 10%",
    // backgroundImage: `url("https://images.unsplash.com/photo-1419242902214-272b3f66ee7a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=720&q=80")`,
    backgroundRepeat: "repeat",
    backgroundPosition: "0 0",
    backgroundSize: "auto 60%",
    transform: "rotate(-15deg)",
    width: "200%",
    height: "200%",
  },
  titleBox: {
    zIndex: 10,
    position: "relative",
    [theme.fn.largerThan("sm")]: {
      width: "130%",
    },
  },
  title: {
    color: "rgb(0,256,0)", //`${theme.colors.green[9]}`,
    fontSize: "9em",
    [theme.fn.smallerThan("md")]: {
      fontSize: "5em",
    },
  },
  subTitle: {
    fontSize: "2em",

    [theme.fn.smallerThan("md")]: {
      fontSize: "1.5em",
    },
  },
  stLogo: {
    width: 250,
    paddingTop: "0.25em",
    [theme.fn.smallerThan("md")]: {
      width: 160,
    },
  },
}));

export default function HeroBanner() {
  const { classes, theme } = useStyles();
  return (
    <div className={classes.outer}>
      <div className={classes.overlay}></div>
      <div className={classes.container}>
        <Box className={classes.titleBox} pt={100} ml={"2em"}>
          <Title
            className={classes.title}
            sx={{ fontFamily: `"Cabin", sans-serif`, cursor: "default" }}
          >
            smart
            <Text span style={{ color: "white" }}>
              /
            </Text>
            sync
          </Title>

          <Group sx={{ alignItems: "center" }} className={classes.subTitle}>
            <Text weight={"bolder"}>PMO service from</Text>

            <Box className={classes.stLogo}>
              <Image
                src={theme.colorScheme === "dark" ? light : dark}
                // width={230}
                // pt={"0.5em"}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  window.location.href = "https://www.smarttasking.com/";
                }}
              />
            </Box>
          </Group>
          <SimpleGrid
            cols={3}
            breakpoints={[
              { maxWidth: "md", cols: 3, spacing: "xl" },
              { maxWidth: "sm", cols: 1, spacing: "sm" },
            ]}
            spacing={"xl"}
            mt={100}
          >
            <Stack>
              <ThemeIcon color={"lime"} radius={"md"} size={44}>
                <IconHierarchy3 />
              </ThemeIcon>
              <Text weight={"bold"} size={"lg"}>
                Synthesise
              </Text>
              <Text size={"sm"} color="dimmed">
                All your project management data in one place. Sync up data from
                tools such as financial, resource management, billable
                utilisation, project planning and stakeholder management.
              </Text>
            </Stack>
            <Stack>
              <ThemeIcon color={"lime"} radius={"md"} size={44}>
                <IconListCheck />
              </ThemeIcon>
              <Text weight={"bold"} size={"lg"}>
                Standardise
              </Text>
              <Text size={"sm"} color="dimmed">
                Our professionally designed PMO data model is ready to
                standardise varying datasets, tables and legacy systems into a
                robust, reliable and scalable PMO information set that aligns to
                your business maturity.
              </Text>
            </Stack>
            <Stack>
              <ThemeIcon color={"lime"} radius={"md"} size={44}>
                <IconRocket />
              </ThemeIcon>
              <Text weight={"bold"} size={"lg"}>
                Summarise
              </Text>
              <Text size={"sm"} color="dimmed">
                Once your data has been synchonised, standardised, it's ready to
                be summarised. Our dashboards take your data and turn it into
                information that is ready for actionable insights that grow your
                business.
              </Text>
            </Stack>
          </SimpleGrid>
        </Box>
        <Box className={classes.imageContainer}>
          <BackgroundImage className={classes.image} src={image} />
        </Box>
      </div>
    </div>
  );
}
