import React, { useCallback, useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import {
  ActionIcon,
  Group,
  Popover,
  SimpleGrid,
  Stack,
  rem,
  useMantineTheme,
  Chip,
  NativeSelect,
  Divider,
  Button,
  Badge,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { IconCalendar, IconX } from "@tabler/icons-react";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
dayjs.extend(quarterOfYear);

function getPresetDates(preset: string | null) {
  switch (preset) {
    case "Last Year":
      return [
        dayjs().subtract(1, "year").startOf("year"),
        dayjs().subtract(1, "year").endOf("year"),
      ];
    case "This Year":
      return [dayjs().startOf("year"), dayjs().endOf("year")];

    case "Next Year":
      return [
        dayjs().add(1, "year").startOf("year"),
        dayjs().add(1, "year").endOf("year"),
      ];

    case "This Month":
      return [dayjs().startOf("month"), dayjs().endOf("month")];

    case "Last Month":
      return [
        dayjs().subtract(1, "month").startOf("month"),
        dayjs().subtract(1, "month").endOf("month"),
      ];

    case "Next Month":
      return [
        dayjs().add(1, "month").startOf("month"),
        dayjs().add(1, "month").endOf("month"),
      ];
    case "Today":
      return [dayjs().startOf("day"), dayjs().endOf("day")];

    case "Yesterday":
      return [
        dayjs().subtract(1, "day").startOf("day"),
        dayjs().subtract(1, "day").endOf("day"),
      ];
    case "Tomorrow":
      return [
        dayjs().add(1, "day").startOf("day"),
        dayjs().add(1, "day").endOf("day"),
      ];

    case "Last Quarter":
      return [
        dayjs().subtract(1, "quarter").startOf("quarter"),
        dayjs().subtract(1, "quarter").endOf("quarter"),
      ];

    case "This Quarter":
      return [dayjs().startOf("quarter"), dayjs().endOf("quarter")];

    case "Next Quarter":
      return [
        dayjs().add(1, "quarter").startOf("quarter"),
        dayjs().add(1, "quarter").endOf("quarter"),
      ];

    default:
      return [undefined, undefined];
  }
}

export function DateFilter({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  comparisonPeriod,
  setComparisonPeriod,
  editMode,

  updateDashboardFunction,
  setCurrentDashboard,
  currentDashboard,
}: any) {
  const theme = useMantineTheme();

  const [preset, setPreset] = useState<null | string>(null);

  const updateDefaultDates = useCallback(async () => {
    const updatedDashboard = structuredClone(currentDashboard);
    const newConfig = JSON.parse(updatedDashboard.configuration);
    newConfig.defaultDateSettings = {
      preset,
      startDate: startDate ? startDate.format("YYYY-MM-DD") : "",
      endDate: endDate ? endDate.format("YYYY-MM-DD") : "",
    };
    updatedDashboard.configuration = JSON.stringify(newConfig);
    await updateDashboardFunction(updatedDashboard);
    setCurrentDashboard(updatedDashboard);
  }, [
    endDate,
    startDate,
    preset,
    setCurrentDashboard,
    currentDashboard,
    updateDashboardFunction,
  ]);

  useEffect(() => {
    const { preset, startDate, endDate } =
      JSON.parse(currentDashboard.configuration)?.defaultDateSettings ?? {};
    if (preset) {
      setPreset(preset);
    } else {
      if (startDate) {
        setStartDate(dayjs(startDate));
      }
      if (endDate) {
        setEndDate(dayjs(endDate));
      }
    }
  }, []);

  useEffect(() => {
    if (preset) {
      const [newStartDate, newEndDate] = getPresetDates(preset);
      setStartDate(newStartDate);
      setEndDate(newEndDate);
    }
  }, [preset, setEndDate, setStartDate]);

  const presets = useMemo(() => {
    return [
      "This Year",
      "Last Year",
      "Next Year",
      "This Month",
      "Last Month",
      "Next Month",
      "Today",
      "Yesterday",
      "Tomorrow",
      "Last Quarter",
      "This Quarter",
      "Next Quarter",
    ].map((title) => {
      return (
        <Chip
          key={title}
          checked={preset === title}
          onClick={() => setPreset(title)}
          variant="light"
        >
          {title}
        </Chip>
      );
    });
  }, [preset]);

  return (
    <Group>
      <Popover
        withArrow
        shadow="md"
        position="bottom-start"
        styles={{
          dropdown: {
            minWidth: "45rem",
            maxWidth: "45rem",
            backgroundColor:
              theme.colorScheme === "dark" ? theme.colors.dark[9] : undefined,
          },
        }}
      >
        <Popover.Target>
          <ActionIcon
            size="lg"
            color={theme.primaryColor}
            variant="light"
            radius={"md"}
            // variant="transparent"
          >
            <IconCalendar size={rem(24)} />
          </ActionIcon>
        </Popover.Target>

        <Popover.Dropdown>
          <Stack>
            {editMode && (
              <Group position="right">
                <Button onClick={updateDefaultDates}>Set as default</Button>
              </Group>
            )}
            <Group position="apart">
              <DatePickerInput
                label="Start Date"
                value={startDate ? startDate.toDate() : null}
                labelProps={{ c: theme.primaryColor }}
                maxDate={endDate ? dayjs(endDate).toDate() : undefined}
                onChange={(date) => {
                  setPreset(null);
                  setStartDate(dayjs(date));
                }}
                style={{ flexGrow: 1 }}
                styles={{
                  input:
                    theme.colorScheme === "dark"
                      ? { backgroundColor: theme.colors.dark[4] }
                      : {},
                }}
              />
              <DatePickerInput
                label="End Date"
                value={endDate ? endDate.toDate() : null}
                labelProps={{ c: theme.primaryColor }}
                minDate={startDate ? dayjs(startDate).toDate() : undefined}
                onChange={(date) => {
                  setPreset(null);
                  setEndDate(dayjs(date));
                }}
                style={{ flexGrow: 1 }}
                styles={{
                  input:
                    theme.colorScheme === "dark"
                      ? { backgroundColor: theme.colors.dark[4] }
                      : {},
                }}
              />
            </Group>
            <SimpleGrid cols={4}>
              <Chip
                checked={false}
                onClick={() => {
                  setPreset(null);
                  setStartDate(undefined);
                  setEndDate(undefined);
                }}
                styles={{
                  input:
                    theme.colorScheme === "dark"
                      ? { backgroundColor: theme.colors.dark[4] }
                      : {},
                }}
                variant="outline"
              >
                Clear
              </Chip>
              {presets}
            </SimpleGrid>
          </Stack>
          <Divider my={"md"} />
          <NativeSelect
            label="Comparison Period"
            labelProps={{ c: theme.primaryColor }}
            data={[
              { value: "Default", label: "Default" },
              { value: "Yesterday", label: "Yesterday" },
              { value: "Last Week", label: "Last Week" },
              { value: "Last Month", label: "Last Month" },
              { value: "Last Quarter", label: "Last Quarter" },
              { value: "Six Months Ago", label: "Six Months Ago" },
              { value: "Twelve Months Ago", label: "Twelve Months Ago" },
            ]}
            value={comparisonPeriod}
            onChange={(e) => setComparisonPeriod(e.target.value)}
            styles={{
              input:
                theme.colorScheme === "dark"
                  ? { backgroundColor: theme.colors.dark[4] }
                  : {},
            }}
          />
        </Popover.Dropdown>
      </Popover>
      {(preset || startDate || endDate) && (
        <Badge
          rightSection={
            <ActionIcon
              size="xs"
              color={theme.primaryColor}
              radius="xl"
              variant="transparent"
              onClick={() => {
                setPreset(null);
                setStartDate(undefined);
                setEndDate(undefined);
              }}
            >
              <IconX size={rem(16)} />
            </ActionIcon>
          }
        >
          {preset ? preset : null}
          {startDate && !endDate ? "After " : null}
          {!startDate && endDate ? "Before " : null}
          {!preset && startDate ? dayjs(startDate).format("DD/MM/YYYY") : null}
          {startDate && endDate && !preset ? " - " : null}
          {!preset && endDate ? dayjs(endDate).format("DD/MM/YYYY") : null}
        </Badge>
      )}
    </Group>
  );
}
