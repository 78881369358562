import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Button,
  Group,
  Select,
  TextInput,
  Box,
  MultiSelect,
  ActionIcon,
  Alert,
  NativeSelect,
} from "@mantine/core";
import AppContext from "../../../context/AppContext";
import { IconTrash } from "@tabler/icons-react";

// Define types for permission rules
type RuleType = "user" | "role" | "field";
type LogicType = "AND" | "OR" | "NOT";

interface BaseRule {
  type: RuleType;
  values: string[];
  fieldName?: string;
}

interface AndRule {
  logic: "AND";
  rules: PermissionRule[];
}

interface OrRule {
  logic: "OR";
  rules: PermissionRule[];
}

interface NotRule {
  logic: "NOT";
  rules: PermissionRule[];
}

type PermissionRule = BaseRule | AndRule | OrRule | NotRule;

// Type guard functions to narrow down the types
const isBaseRule = (rule: PermissionRule): rule is BaseRule => {
  return (rule as BaseRule).type !== undefined;
};

const isLogicRule = (
  rule: PermissionRule
): rule is AndRule | OrRule | NotRule => {
  return (rule as AndRule).logic !== undefined;
};

// Recursive component to render rules
const PermissionRuleForm: React.FC<{
  rule: PermissionRule;
  onChange: (updatedRule: PermissionRule) => void;
  removeRule: () => void;
}> = ({ rule, onChange, removeRule }) => {
  // const [selectedRuleType, setSelectedRuleType] = useState<string | null>(null);
  const {
    dataObject: { users = [], userTypes = [] },
  } = useContext(AppContext);

  const addNestedRule = (selectedRuleType: RuleType | LogicType) => {
    if (!selectedRuleType) return;

    let newRule: PermissionRule;

    if (
      selectedRuleType === "user" ||
      selectedRuleType === "role" ||
      selectedRuleType === "field"
    ) {
      newRule = { type: selectedRuleType as RuleType, values: [] };
    } else {
      newRule = { logic: selectedRuleType as LogicType, rules: [] };
    }

    const updatedLogicRule = { ...rule } as AndRule | OrRule | NotRule;
    updatedLogicRule.rules.push(newRule);

    onChange(updatedLogicRule);
    // setSelectedRuleType(null); // Reset selection
  };

  const handleNestedRuleChange = (
    index: number,
    updatedNestedRule: PermissionRule
  ) => {
    const updatedLogicRule = { ...rule } as AndRule | OrRule | NotRule;
    updatedLogicRule.rules[index] = updatedNestedRule;
    onChange(updatedLogicRule);
  };

  const handleRemoveNestedRule = (index: number) => {
    const updatedLogicRule = { ...rule } as AndRule | OrRule | NotRule;
    updatedLogicRule.rules.splice(index, 1);
    onChange(updatedLogicRule);
  };

  const renderBaseRule = (baseRule: BaseRule) => (
    <Group>
      <Select
        label="Type"
        value={baseRule.type}
        onChange={(value) => onChange({ ...baseRule, type: value as RuleType })}
        data={[
          { value: "user", label: "User" },
          { value: "role", label: "Role" },
          { value: "field", label: "Field" },
        ]}
      />
      {baseRule.type === "field" && (
        <TextInput
          label="Field Name"
          value={baseRule.fieldName || ""}
          onChange={(e) => onChange({ ...baseRule, fieldName: e.target.value })}
        />
      )}
      {baseRule.type === "user" && (
        <MultiSelect
          style={{ flexGrow: 1 }}
          label="Select users"
          value={baseRule.values}
          data={users.map((user: any) => ({
            value: user.id,
            label: user.email,
          }))}
          onChange={(values) => {
            onChange({
              ...baseRule,
              values: values,
            });
          }}
        />
      )}
      {baseRule.type === "role" && (
        <MultiSelect
          style={{ flexGrow: 1 }}
          label="Select role types"
          value={baseRule.values}
          data={userTypes.map((userType: any) => ({
            value: userType.id,
            label: userType.name,
          }))}
          onChange={(values) => {
            onChange({
              ...baseRule,
              values: values,
            });
          }}
        />
      )}
    </Group>
  );

  const renderLogicRule = (logicRule: AndRule | OrRule | NotRule) => (
    <Group>
      <Select
        label="Logic"
        value={logicRule.logic}
        onChange={(value) =>
          onChange({ ...logicRule, logic: value as LogicType })
        }
        data={[
          { value: "AND", label: "AND" },
          { value: "OR", label: "OR" },
          { value: "NOT", label: "NOT" },
        ]}
      />

      <Group spacing="sm" mt="md">
        <Button onClick={() => addNestedRule("AND")}>And</Button>
        <Button onClick={() => addNestedRule("OR")}>Or</Button>
        <Button onClick={() => addNestedRule("user")}>Add Rule</Button>
      </Group>

      {logicRule.rules.map((nestedRule, index) => (
        <Box key={index} mt="md" pl="md">
          <PermissionRuleForm
            rule={nestedRule}
            onChange={(updatedRule) =>
              handleNestedRuleChange(index, updatedRule)
            }
            removeRule={() => handleRemoveNestedRule(index)}
          />
        </Box>
      ))}
    </Group>
  );

  return (
    <Box p="md" sx={{ borderRadius: "4px" }}>
      <Alert style={{ overflow: "visible" }} radius={"xl"}>
        <Group>
          {isBaseRule(rule)
            ? renderBaseRule(rule)
            : renderLogicRule(rule as AndRule | OrRule | NotRule)}
          <ActionIcon
            variant="outline"
            color="red"
            mt="sm"
            onClick={removeRule}
          >
            <IconTrash />
          </ActionIcon>
        </Group>
      </Alert>
    </Box>
  );
};

// Main component
const PermissionForm = ({ updateForm, initialRules }: any) => {
  const [rules, setRules] = useState<PermissionRule[]>([]);
  // const [selectedRootRuleType, setSelectedRootRuleType] = useState<
  //   string | null
  // >(null);

  useEffect(() => {
    setRules(
      //JSON.parse(form.values.permissionsConfig).datasetPermissions.rules
      initialRules
    );
  }, [initialRules]);

  // const updateForm = useCallback(
  //   (updatedRules: any) => {
  //     const currentValue = JSON.parse(form.values.permissionsConfig);
  //     form.setValues({
  //       permissionsConfig: JSON.stringify({
  //         ...currentValue,
  //         datasetPermissions: { rules: updatedRules },
  //       }),
  //     });
  //   },
  //   [form]
  // );

  const handleRuleChange = useCallback(
    (index: number, updatedRule: PermissionRule) => {
      const updatedRules = [...rules];
      updatedRules[index] = updatedRule;
      // setRules(updatedRules);
      updateForm(updatedRules);
    },
    [rules, updateForm]
  );

  const handleRemoveRule = useCallback(
    (index: number) => {
      const updatedRules = [...rules];
      updatedRules.splice(index, 1);
      // setRules(updatedRules);
      updateForm(updatedRules);
    },
    [rules, updateForm]
  );

  const addNewRule = useCallback(
    (ruleType: RuleType | LogicType) => {
      if (!ruleType) return;

      let newRule: PermissionRule;

      if (ruleType === "user" || ruleType === "role" || ruleType === "field") {
        newRule = { type: ruleType as RuleType, values: [] };
      } else {
        newRule = { logic: ruleType as LogicType, rules: [] };
      }

      // setRules([...rules, newRule]);
      updateForm([...rules, newRule]);
      // setSelectedRootRuleType(null); // Reset selection
    },
    [rules, updateForm]
  );

  return (
    <Alert
      p="md"
      radius={"xl"}
      style={{ overflow: "visible" }}
      title={
        <Group spacing="sm" mb="md">
          <Button onClick={() => addNewRule("AND")}>Add And</Button>
          <Button onClick={() => addNewRule("OR")}>Add Or</Button>
          {/* <Button onClick={() => addNewRule("user")}>Add Rule</Button> */}
        </Group>
      }
    >
      {rules.map((rule, index) => (
        <PermissionRuleForm
          key={index}
          rule={rule}
          onChange={(updatedRule) => handleRuleChange(index, updatedRule)}
          removeRule={() => handleRemoveRule(index)}
        />
      ))}
    </Alert>
  );
};

export { PermissionForm as PermissionQuestion };
