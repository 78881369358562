import {
  ActionIcon,
  Alert,
  Badge,
  Box,
  Button,
  Group,
  MultiSelect,
  NativeSelect,
  Popover,
  ScrollArea,
  Stack,
  rem,
  useMantineTheme,
} from "@mantine/core";
import {
  IconFilter,
  IconFilterOff,
  IconTrash,
  IconX,
} from "@tabler/icons-react";
import { createFilterFunction } from "../../common/functions/createFilterFunction";
import { useCallback, useContext, useMemo, useState } from "react";
import AppContext from "../../../context/AppContext";
import { getDataSchema } from "../../common/functions/getDataSchema";
import { color, style } from "d3";

interface IFilterInfo {
  field: string;
  dataSource: string;
  title: string;
}

function truncateString(value: string, maxLength: number) {
  if (value === null || value === undefined) return "No Data";
  const str = value.toString();
  if (str.length <= maxLength) {
    return str;
  } else {
    return str.slice(0, maxLength - 3) + "...";
  }
}

function getFilterOptions(
  filterInfo: IFilterInfo,
  dashboardFilterObject: any,
  globalDashboardFilteredDataObject: any
) {
  const { field, dataSource } = filterInfo;

  const globalFilteredData =
    globalDashboardFilteredDataObject[dataSource] &&
    globalDashboardFilteredDataObject[dataSource]["Live"]
      ? globalDashboardFilteredDataObject[dataSource]["Live"]
      : [];

  const ignoreCurrentField = structuredClone(dashboardFilterObject);

  delete ignoreCurrentField[field];

  const localFilterFunction = createFilterFunction([
    Object.values(ignoreCurrentField),
  ]);

  return globalFilteredData
    .filter(localFilterFunction)
    .reduce((list: any, row: any) => {
      if (!list.includes(row[field])) list.push(row[field]);
      return list;
    }, [])
    .map((value: any) => {
      if (value === null) return { value: null, label: "No Data" };
      return { value, label: truncateString(value, 50) };
    });
}

export default function DashboardFilter({
  // filterInfoList,
  editMode,
  dashboardFilterObject,
  setDashboardFilterObject,
  // comparisonPeriod,
  // setComparisonPeriod,
  // globalDashboardFilteredDataObject,
  dashboardDataObject,
  currentDashboard,
  updateDashboardFunction,
  setCurrentDashboard,
}: {
  filterInfoList: IFilterInfo[];
  editMode: boolean;
  dashboardFilterObject: any;
  setDashboardFilterObject: any;
  comparisonPeriod: string;
  setComparisonPeriod: any;
  globalDashboardFilteredDataObject: any;
  dashboardDataObject: any;
  currentDashboard: any;
  updateDashboardFunction: any;
  setCurrentDashboard: any;
}) {
  const theme = useMantineTheme();
  const { systemPreferenceObject = {} } = useContext(AppContext);
  const { modules } = systemPreferenceObject ?? {};

  const [dataSource, setDataSource] = useState("");
  const [field, setField] = useState("");

  const { filters: originalFilters = [] }: { filters: IFilterInfo[] } =
    JSON.parse(currentDashboard.configuration);

  const [filters, setFilters] = useState(originalFilters);

  const deleteFilter = useCallback(
    async (index: number) => {
      const newFilters = structuredClone(filters);
      newFilters.splice(index, 1);
      setFilters(newFilters);
      const updatedDashboard = structuredClone(currentDashboard);
      const newConfig = JSON.parse(updatedDashboard.configuration);
      newConfig.filters = newFilters;
      updatedDashboard.configuration = JSON.stringify(newConfig);
      await updateDashboardFunction(updatedDashboard);
      setCurrentDashboard(updatedDashboard);
    },
    [filters, setFilters, currentDashboard, updateDashboardFunction]
  );

  const addFilter = useCallback(
    async (newFilter: any) => {
      const newFilters = structuredClone(filters);
      newFilters.push(newFilter);
      setFilters(newFilters);
      const updatedDashboard = structuredClone(currentDashboard);
      const newConfig = JSON.parse(updatedDashboard.configuration);
      newConfig.filters = newFilters;
      updatedDashboard.configuration = JSON.stringify(newConfig);
      await updateDashboardFunction(updatedDashboard);
      setCurrentDashboard(updatedDashboard);
    },
    [filters, currentDashboard, updateDashboardFunction]
  );

  const fieldOptions = useMemo(() => {
    if (!dataSource) return [];
    const schema = getDataSchema(dataSource);
    return Object.values(schema).map((obj) => ({
      value: obj.field,
      label: obj.title,
    }));
  }, [dataSource]);

  return (
    <Group>
      <Popover
        withArrow
        shadow="md"
        position="bottom-start"
        styles={{
          dropdown: {
            minWidth: "30rem",
            maxWidth: "30rem",
            backgroundColor:
              theme.colorScheme === "dark" ? theme.colors.dark[9] : undefined,
          },
        }}
      >
        <Popover.Target>
          <Box>
            {!editMode && filters.length > 0 && (
              <ActionIcon
                size="lg"
                color={theme.primaryColor}
                variant="light"
                radius={"md"}
                // variant="transparent"
              >
                <IconFilter size={rem(24)} />
              </ActionIcon>
            )}
            {editMode && (
              <Button leftIcon={<IconFilter />} variant="outline">
                Edit Filters
              </Button>
            )}
          </Box>
        </Popover.Target>

        <Popover.Dropdown>
          {/* <ScrollArea.Autosize mah={"60vh"}> */}
          {filters.map((filterInfo, index) => {
            const { title, field, dataSource } = filterInfo;

            const filterOptions = getFilterOptions(
              filterInfo,
              dashboardFilterObject,
              dashboardDataObject
            );

            if (filterOptions.length === 0) return null;

            if (filterOptions[0] && filterOptions[0].value === undefined)
              return null;

            return (
              <Group key={title} mt={"xs"}>
                {editMode && (
                  <ActionIcon color="red" onClick={() => deleteFilter(index)}>
                    <IconTrash />
                  </ActionIcon>
                )}
                <MultiSelect
                  data={filterOptions}
                  style={{ width: editMode ? "80%" : "100%" }}
                  labelProps={{ c: theme.primaryColor }}
                  styles={{
                    input:
                      theme.colorScheme === "dark"
                        ? { backgroundColor: theme.colors.dark[4] }
                        : {},
                  }}
                  placeholder="All"
                  searchable
                  label={title}
                  value={dashboardFilterObject[field]?.value ?? []}
                  onChange={(value) => {
                    const copy = structuredClone(dashboardFilterObject);
                    if (value.length === 0) {
                      delete copy[field];
                    } else {
                      copy[field] = {
                        field,
                        includeExclude: "Include",
                        operand: "Includes",
                        value,
                        dataSource,
                      };
                    }
                    setDashboardFilterObject(copy);
                  }}
                />
              </Group>
            );
          })}
          {editMode && (
            <Alert title="Add New Filter" mt={"md"}>
              <Stack>
                <NativeSelect
                  label={"Data Source"}
                  onChange={(e) => setDataSource(e.target.value)}
                  data={[{ value: "", label: "" }].concat(
                    Object.keys(modules)
                      .filter((key: string) => modules[key].active)
                      .map((key: string) => {
                        return { value: modules[key].dataKey, label: key };
                      })
                  )}
                />
                <NativeSelect
                  label={"Field"}
                  onChange={(e) => setField(e.target.value)}
                  data={[{ value: "", label: "" }].concat(fieldOptions)}
                  disabled={!dataSource}
                />

                <Button
                  disabled={!dataSource || !field}
                  onClick={() => {
                    const schema = getDataSchema(dataSource);
                    addFilter({
                      field,
                      dataSource,
                      title: schema[field].title,
                    });
                  }}
                >
                  +
                </Button>
              </Stack>
            </Alert>
          )}
          {/* </ScrollArea.Autosize> */}
        </Popover.Dropdown>
      </Popover>
      {Object.keys(dashboardFilterObject).length > 0 ? (
        <ActionIcon onClick={() => setDashboardFilterObject({})}>
          <IconFilterOff size={rem(18)} />
        </ActionIcon>
      ) : null}
      {Object.keys(dashboardFilterObject).map((key: any) => {
        const obj = dashboardFilterObject[key];
        return (
          <Badge
            key={key}
            rightSection={
              <ActionIcon
                size="xs"
                color={theme.primaryColor}
                radius="xl"
                variant="transparent"
                onClick={() => {
                  const copy = structuredClone(dashboardFilterObject);
                  delete copy[key];
                  setDashboardFilterObject(copy);
                }}
              >
                <IconX size={rem(16)} />
              </ActionIcon>
            }
          >{`${obj.field}: ${obj.value
            ?.map((val: string) => truncateString(val, 20))
            .join(", ")}`}</Badge>
        );
      })}
    </Group>
  );
}
