import WidgetFrame from "../../WidgetFrame";

import { DateInput } from "@mantine/dates";
import { MantineReactTable } from "mantine-react-table";
import "./ResourcingTable.css";
import {
  Group,
  LoadingOverlay,
  MultiSelect,
  Select,
  Stack,
  Switch,
  TextInput,
} from "@mantine/core";
import { IconSearch } from "@tabler/icons-react";
import { useResourcingTable } from "./useResourcingTable";

export default function ResourcingTable(props: any) {
  const {
    value,
    setValue,
    theme,
    roleTypes,
    roleTypesFilter,
    setRoleTypesFilter,
    setPeriod,
    period,
    status,
    setStatus,
    endDate,
    setEndDate,
    includeLeave,
    setIncludeLeave,
    startDate,
    loading,
    activeOnly,
    setActiveOnly,
    table,
  } = useResourcingTable(props);

  return (
    <WidgetFrame
      title="Resourcing Table"
      controls={
        <Group spacing={"xl"} style={{ width: "100%" }} ml={"xl"}>
          <TextInput
            size="xs"
            icon={<IconSearch />}
            label="Search"
            defaultValue={value}
            onChange={(event) => setValue(event.currentTarget.value)}
            styles={{
              input: {
                backgroundColor:
                  theme.colorScheme === "dark"
                    ? theme.colors.dark[8]
                    : undefined,
              },
            }}
            style={{ flexGrow: 1 }}
          />
          <MultiSelect
            size="xs"
            data={roleTypes.map((roleType: any) => ({
              value: roleType.id,
              label: roleType.name,
            }))}
            label="Role Type"
            value={roleTypesFilter}
            onChange={(value) => setRoleTypesFilter(value)}
            styles={{
              input: {
                backgroundColor:
                  theme.colorScheme === "dark"
                    ? theme.colors.dark[8]
                    : undefined,
              },
            }}
            style={{ flexGrow: 1 }}
          />
          <Select
            size="xs"
            data={[
              { value: "week", label: "Week" },
              { value: "month", label: "Month" },
              { value: "quarter", label: "Quarter" },
            ]}
            label="Period"
            value={period} //@ts-ignore
            onChange={(value) => setPeriod(value)}
            styles={{
              input: {
                backgroundColor:
                  theme.colorScheme === "dark"
                    ? theme.colors.dark[8]
                    : undefined,
              },
            }}
          />
          <Select
            size="xs"
            data={[
              { value: "", label: "All" },
              { value: "Billable", label: "Billable" },
              { value: "Non Billable", label: "Non Billable" },
            ]}
            label="Billable"
            value={status} //@ts-ignore
            onChange={(value) => setStatus(value)}
            styles={{
              input: {
                backgroundColor:
                  theme.colorScheme === "dark"
                    ? theme.colors.dark[8]
                    : undefined,
              },
            }}
          />

          {/* <DateInput
            value={startDate}
            onChange={setStartDate}
            label="Start Date"
          /> */}
          <DateInput
            size="xs"
            value={endDate}
            onChange={setEndDate}
            label="End Date"
            minDate={startDate ? startDate : undefined}
            styles={{
              input: {
                backgroundColor:
                  theme.colorScheme === "dark"
                    ? theme.colors.dark[8]
                    : undefined,
              },
            }}
          />
          <Stack spacing={"xs"}>
            <Switch
              size="xs"
              checked={activeOnly}
              onChange={(e) => setActiveOnly(e.target.checked)}
              label="Active Only"
            />
            <Switch
              size="xs"
              checked={includeLeave}
              onChange={(e) => setIncludeLeave(e.target.checked)}
              label="Include Leave"
            />
          </Stack>
        </Group>
      }
    >
      <LoadingOverlay visible={loading} />

      {!loading && <MantineReactTable table={table} />}
    </WidgetFrame>
  );
}
