import {
  formatDateString,
  formatDateToYYYYMM,
} from "../../components/common/functions/formatFunctions";
import { ISchema } from "../SchemaTypes";
import common from "./commonSchema";

const csatsSchema: ISchema = {
  programme: {
    title: "Programme",
    field: "programme",
    accessorFunction: (value: any) => value.programme?.name,
    formOptions: { required: true },
    excludeFromForm: true,
    excludeFromChartBuilder: true,
    type: "belongsTo",
  },
  programmeName: {
    title: "Programme Name",
    field: "programmeName",
    accessorFunction: (value: any) => value.programme?.name,
    formOptions: { required: true },
    excludeFromForm: true,
    type: "belongsTo",
  },
  accountName: {
    title: "Account Name",
    field: "accountName",
    accessorFunction: (value: any) => value.programme?.account?.name,
    formOptions: { required: true },
    excludeFromForm: true,
    type: "belongsTo",
  },
  programmeCsatsId: {
    title: "Programme",
    field: "programmeCsatsId",
    excludeFromChartBuilder: true,
    formOptions: {
      required: true,
      options: {
        dataSource: "programmes",
        addBlank: true,
        accessorFunction: (dataSource: any) =>
          dataSource?.map((row: any) => {
            return { value: row.id, label: row.name };
          }) ?? [],
      },
      order: 1,
    },
    type: "select",
  },

  score: {
    title: "Score",
    field: "score",
    formOptions: {
      required: true,
      min: 0,
      max: 100,
      step: 1,
      description: "Between 0 and 100",
    },
    type: "number",
  },
  comments: {
    title: "Comments",
    field: "comments",
    type: "textarea",
    formOptions: { required: false },
  },
  date: {
    title: "Date",
    field: "date",
    type: "date",
    formatFunction: formatDateString,
    formOptions: { required: true, defaultValue: new Date() },
  },
  submissionMonth: {
    title: "Submission Month",
    field: "submissionMonth",
    type: "belongsTo",
    accessorFunction: (row: any) => formatDateToYYYYMM(new Date(row.date)),
    formOptions: { required: false },
    excludeFromForm: true,
  },
  ...common,
};

export default csatsSchema;
