import { useContext, useMemo } from "react";
import {
  Group,
  Text,
  Paper,
  createStyles,
  Progress,
  Skeleton,
} from "@mantine/core";
// import MetricContainer from "../common/MetricContainer";
import AppContext from "../../../../context/AppContext";

// import Placeholder from "../../Placeholder";
import useLoadingStatus from "../../../../hooks/useLoadingStatus";
import WidgetFrame from "../../WidgetFrame";
import { createFilterFunction } from "../../functions/createFilterFunction";
import { useElementSize } from "@mantine/hooks";
import { calculatePercentageOfDurationPassed } from "../../functions/dateFunctions";
import { IconCalendar } from "@tabler/icons-react";

const useStyles = createStyles((theme) => ({
  root: {
    //padding: theme.spacing.xl * 1.5,
  },

  value: {
    fontSize: 24,
    fontWeight: 700,
    lineHeight: 1,
  },

  diff: {
    lineHeight: 1,
    display: "flex",
    alignItems: "center",
  },

  icon: {
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[3]
        : theme.colors.gray[4],
  },

  title: {
    fontWeight: 700,
    textTransform: "uppercase",
  },

  paper: {
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[6]
        : theme.colors.gray[0],
  },

  progressBar: {
    "&:not(:first-of-type)": {
      borderLeft: `3px solid ${
        theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white
      }`,
    },
  },
}));

export default function UnitsUsedVisual({
  dashboardFilterObjectList,
  startDate,
  endDate,
}: any) {
  const { classes } = useStyles();
  const { ref, width, height } = useElementSize();
  const { dataObject, currentProgrammes } = useContext(AppContext);
  const { projects = [] } = dataObject;

  const localFilterFunction = useMemo(
    () =>
      createFilterFunction(
        [dashboardFilterObjectList],
        "startDate",
        startDate,
        endDate
      ),
    [dashboardFilterObjectList, startDate, endDate]
  );

  const filteredProjects = useMemo(() => {
    return projects
      .filter(
        (project: any) =>
          project.period === "Live" &&
          currentProgrammes.includes(project.programmeProjectsId)
      )
      .filter(localFilterFunction);
  }, [projects, localFilterFunction, currentProgrammes]);

  const loading = useLoadingStatus(["projects"]);

  const PercentageTimePassedWidth = useMemo(() => {
    if (filteredProjects.length === 1) {
      const { startDate, endDate } = filteredProjects[0];

      const percentage = calculatePercentageOfDurationPassed({
        startDate,
        endDate,
      });

      return (
        <div
          style={{
            position: "absolute",
            left: percentage * (ref.current?.offsetWidth ?? 0),
            top: 0,
            width: 2,
            height: "100%",
            backgroundColor: "white",
          }}
        ></div>
      );
    } else {
      <></>;
    }
  }, [filteredProjects, ref]);

  const percentageUsed = useMemo(() => {
    const totalObject = filteredProjects.reduce(
      (obj: any, project: any) => {
        for (const projectRole of project.projectRoles.items) {
          obj.planned +=
            projectRole.plannedUnits * (projectRole.unit === "Days" ? 8 : 1);

          for (const timesheet of projectRole.timesheets.items) {
            obj.actual +=
              timesheet.unitAmount * (timesheet.unit === "Days" ? 8 : 1);
          }
        }
        return obj;
      },
      { planned: 0, actual: 0 }
    );
    return (totalObject.actual * 100) / totalObject.planned;
  }, [filteredProjects]);

  return (
    <WidgetFrame title={"Hours Consumed"} icon={IconCalendar}>
      <Group align="flex-end" spacing="xs" sx={{ width: "100%" }}>
        <Group position="apart" sx={{ width: "100%" }}>
          <Text size="xs" weight={700}>
            {" "}
          </Text>
        </Group>
        <Group position="apart" sx={{ width: "100%" }}>
          <Text size="xl" weight={700}>
            {isNaN(percentageUsed) ? 0 : percentageUsed.toFixed(0)}%
          </Text>
        </Group>
        {loading && <Skeleton sx={{ width: "100%", height: 20 }} />}
        {!loading && (
          <div style={{ width: "100%", position: "relative" }}>
            <Progress
              ref={ref}
              classNames={{ bar: classes.progressBar }}
              sx={{ width: "100%", height: 20 }}
              value={percentageUsed}
            />
            {PercentageTimePassedWidth}
          </div>
        )}
      </Group>
    </WidgetFrame>
  );
}
