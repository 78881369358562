import {
  createStyles,
  Text,
  Card,
  RingProgress,
  Group,
  Skeleton,
} from "@mantine/core";
import { useMemo } from "react";
import { nFormatter } from "../../functions/formatFunctions";
// import AppContext from "../../../../context/AppContext";

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
    height: "100%",
    cursor: "pointer",
  },

  selected: {
    boxShadow: `0px 0px 1px 1px ${theme.colors[theme.primaryColor][9]}`,
  },

  label: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 700,
    lineHeight: 1,
  },

  lead: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 700,
    fontSize: "2rem",
    lineHeight: 1,
  },

  inner: {
    display: "flex",

    // [theme.fn.smallerThan("xs")]: {
    //   flexDirection: "column",
    // },
  },

  ring: {
    flex: 1,
    display: "flex",
    justifyContent: "flex-end",

    [theme.fn.smallerThan("xs")]: {
      justifyContent: "center",
      marginTop: theme.spacing.md,
    },
  },
}));

interface StatsRingCardProps {
  name: string;
  tenant: string;
  completed: number;
  total: number;
  loading: boolean;
  stats: {
    value: number;
    label: string;
  }[];
  totalsObject: any;
  currentProgramme: any;
  setCurrentProgramme: any;
  id: any;
}

export function ProgrammeTile({
  name,
  id,
  tenant,
  completed,
  total,
  stats,
  loading,
  totalsObject = null,
  currentProgramme,
  setCurrentProgramme,
}: StatsRingCardProps) {
  const { classes } = useStyles();

  // const totalsObject = useMemo(() => {
  //   return pls.reduce(
  //     (object: any, pl: any) => {
  //       object.total += pl.revenueSoldUSD;
  //       object.consumed += pl.revenueRecognizedUSD;
  //       object.paid += pl.billedRedeemedUSD;
  //       return object;
  //     },
  //     { total: 0, paid: 0, consumed: 0 }
  //   );
  // }, [pls]);

  const percentagePaid = useMemo(() => {
    if (!totalsObject || totalsObject.total === 0) return "-";
    return `${Math.round((totalsObject.paid * 100) / totalsObject.total)}%`;
  }, [totalsObject]);

  const items = useMemo(
    () =>
      stats.map((stat) => (
        <div key={stat.label}>
          {loading ? (
            <Skeleton height={16} />
          ) : (
            <Text className={classes.label}>{stat.value}</Text>
          )}
          <Text size="xs" color="dimmed">
            {stat.label}
          </Text>
        </div>
      )),
    [stats]
  );

  return (
    <Card
      withBorder
      p="xl"
      radius="md"
      className={`${classes.card} ${
        currentProgramme === id ? classes.selected : ""
      }`}
      onClick={(e) => {
        e.stopPropagation();
        setCurrentProgramme(currentProgramme === id ? "All" : id);
      }}
    >
      <div className={classes.inner}>
        <div>
          <Text className={classes.label}>{name}</Text>
          {/* <Image
            src={mode.colorScheme === "light" ? logo : darkLogo}
            style={{ maxWidth: "150px" }}
          /> */}
          <div>
            {loading ? (
              <Skeleton height={54} mt={"xs"} />
            ) : (
              <Text className={classes.lead} mt={30}>
                ${nFormatter(totalsObject ? totalsObject.total : 0, 1)}
              </Text>
            )}
            <Text color="dimmed">Total Value</Text>
          </div>
          <Group mt="lg">{items}</Group>
        </div>

        <div className={classes.ring}>
          {loading && <Skeleton circle height={150} />}
          {!loading && (
            <RingProgress
              roundCaps
              thickness={10}
              size={150}
              sections={
                !totalsObject || totalsObject.total === 0
                  ? []
                  : [
                      {
                        value: Math.round(
                          (totalsObject.paid * 100) / totalsObject.total
                        ),
                        color: "green",
                      },
                      {
                        value: Math.round(
                          ((totalsObject.consumed - totalsObject.paid) * 100) /
                            totalsObject.total
                        ),
                        color: "orange",
                      },
                    ]
              }
              label={
                <div>
                  <Text align="center" className={classes.label}>
                    {percentagePaid}
                  </Text>
                  <Text align="center" color="dimmed">
                    Revenue Claimed
                  </Text>
                </div>
              }
            />
          )}
        </div>
      </div>
    </Card>
  );
}
