import { useMantineTheme, Paper } from "@mantine/core";
import React from "react";
import {
  BarChart as ReBarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
  ReferenceLine,
} from "recharts";
import {
  formatCategoryAxis,
  formatNumericAxis,
  RenderCustomizedLabel,
  CustomTooltip,
  getChartColors,
} from "./ChartUtilities";

interface IColumnChartProps {
  data: { [key: string]: string | number }[];
  // dataKey: string;
  xAxis?: { visible: boolean; dataKey: string };
  yAxis?: { visible: boolean; dataKey: string };
  dataLabels?: boolean;
  legend?: boolean;
  values: string[];
  yAxisWidth: number;
  dataLabelFontSize: number;
  yAxisFontSize: number;
  xAxisFontSize: number;
  formatFunction: any;
  chartVariant: string;
  colKeys: string[];
  rowKeys: string[];
  includeYAxis: boolean;
  includeXAxis: boolean;
  updateDashboardFilter: any;
  rows: any;
  cols: any;
  schema: any;
  yAxisMin: number | "";
  yAxisMax: number | "";
  grid: boolean;
  comparisonFieldString: string;
  disableChartInteractions: boolean;
  disableTooltip: boolean;
  disableAnimations: boolean;
}

export default function ColumnChart(props: IColumnChartProps) {
  const {
    data,
    dataLabels,
    values,
    yAxisWidth = 60,
    dataLabelFontSize,
    yAxisFontSize,
    xAxisFontSize,
    formatFunction,
    legend,
    chartVariant,
    colKeys,
    rowKeys,
    includeYAxis,
    includeXAxis,
    updateDashboardFilter,
    rows,
    cols,
    schema,
    yAxisMin,
    yAxisMax,
    grid,
    comparisonFieldString,
    disableChartInteractions,
    disableTooltip,
    disableAnimations,
  } = props;
  const theme = useMantineTheme();
  const colors = getChartColors(theme);

  const colorField = colKeys.length > 0 ? colKeys : values;

  return (
    <ResponsiveContainer width="100%" height="99%">
      <ReBarChart width={100} height={100} data={data} barGap={1}>
        {grid && (
          <CartesianGrid
            strokeDasharray="3 3"
            stroke={
              theme.colorScheme === "dark"
                ? theme.colors.gray[7]
                : theme.colors.gray[4]
            }
          />
        )}
        <defs>
          {colorField.map((value: string, index: number) => {
            return (
              <linearGradient
                id={`colorValue${index}`}
                x1="0"
                y1="0"
                x2="0"
                y2="1"
                key={`colorValue${index}`}
              >
                <stop offset="5%" stopColor={colors[index]} stopOpacity={0.9} />
                <stop
                  offset="95%"
                  stopColor={colors[index]}
                  stopOpacity={0.5}
                />
              </linearGradient>
            );
          })}
        </defs>
        {includeXAxis && (
          <XAxis
            dataKey="name"
            type="category"
            tickFormatter={formatCategoryAxis}
            tick={{
              fontSize: `${xAxisFontSize}em`,
              fill: theme.colorScheme === "dark" ? "#ffffff" : "#666",
            }}
          />
        )}

        {includeYAxis && (
          <YAxis
            type="number"
            width={yAxisWidth}
            tickFormatter={(value) => formatNumericAxis(value, formatFunction)}
            tick={{
              fontSize: `${yAxisFontSize}em`,
              fill: theme.colorScheme === "dark" ? "#ffffff" : "#666",
            }}
            // domain={[yAxisMin ?? 0, yAxisMax ?? "auto"]}
          />
        )}
        {!disableTooltip && (
          <Tooltip
            cursor={false}
            content={
              <CustomTooltip
                formatFunction={formatFunction}
                comparisonFieldString={comparisonFieldString}
              />
            }
          />
        )}
        {includeXAxis && <ReferenceLine y={0} />}
        {legend && <Legend />}
        {values.map((value: string, index: number) => {
          if (colKeys.length > 0) {
            return colKeys.map((col: string, colIndex: number) => {
              return (
                <React.Fragment key={`${value}-${col[0]}`}>
                  <Bar
                    isAnimationActive={!disableAnimations}
                    dataKey={col[0]}
                    name={col[0] ? schema[col[0]]?.title : "No Data"}
                    fill={`url(#colorValue${colIndex})`}
                    cursor={disableChartInteractions ? undefined : "pointer"}
                    stackId={chartVariant === "Standard" ? undefined : value}
                    onClick={(input) => {
                      if (disableChartInteractions) return;
                      if (rows[0]) {
                        updateDashboardFilter(rows[0], input.name);
                      } else {
                        updateDashboardFilter(
                          cols[0],
                          input.tooltipPayload[0].dataKey
                        );
                      }
                    }}
                  >
                    {dataLabels && (
                      <LabelList
                        dataKey={col[0]}
                        position="inside"
                        fill={"white"}
                        content={(props: any) => (
                          <RenderCustomizedLabel
                            {...props}
                            dataLabelFontSize={dataLabelFontSize}
                            formatFunction={formatFunction}
                          />
                        )}
                      />
                    )}
                  </Bar>
                </React.Fragment>
              );
            });
          } else {
            return (
              <React.Fragment key={value}>
                <Bar
                  isAnimationActive={!disableAnimations}
                  dataKey={value}
                  name={value ? schema[value]?.title : "No Data"}
                  fill={`url(#colorValue${index})`}
                  cursor={disableChartInteractions ? undefined : "pointer"}
                  onClick={(input) => {
                    if (disableChartInteractions) return;
                    updateDashboardFilter(rows[0], input.name);
                  }}
                >
                  {dataLabels && (
                    <LabelList
                      dataKey={value}
                      position="inside"
                      fill={"white"}
                      content={(props: any) => (
                        <RenderCustomizedLabel
                          {...props}
                          dataLabelFontSize={dataLabelFontSize}
                          formatFunction={formatFunction}
                        />
                      )}
                    />
                  )}
                </Bar>
              </React.Fragment>
            );
          }
        })}
      </ReBarChart>
    </ResponsiveContainer>
  );
}
