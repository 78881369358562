import {
  formatDateString,
  formatDateToYYYYMM,
} from "../../components/common/functions/formatFunctions";
import { ISchema } from "../SchemaTypes";
import common from "./commonSchema";

const employeeSatisfactionSchema: ISchema = {
  person: {
    title: "Person",
    field: "person",
    accessorFunction: (value: any) =>
      `${value.person?.firstName} ${value.person?.lastName}`,
    formatFunction: (value: any) => value,
    formOptions: { required: false },
    excludeFromForm: true,
    type: "belongsTo",
  },

  personEmployeeSatisfactionRecordsId: {
    title: "Person",
    field: "personEmployeeSatisfactionRecordsId",
    formOptions: {
      required: false,
      options: {
        dataSource: "people",
        addBlank: true,
        accessorFunction: (dataSource: any) =>
          dataSource.map((row: any) => {
            return { value: row.id, label: `${row.firstName} ${row.lastName}` };
          }),
      },
      order: 1,
    },
    type: "select",
  },

  score: {
    title: "Score",
    field: "score",
    formOptions: { required: false, min: 0, max: 10, defaultValue: 0, step: 1 },
    type: "number",
  },

  comment: {
    title: "Comment",
    field: "comment",
    type: "textarea",
    formOptions: { required: false },
  },

  date: {
    title: "Date",
    field: "date",
    type: "date",
    formatFunction: formatDateString,
    formOptions: { required: true, defaultValue: new Date() },
  },
  submissionMonth: {
    title: "Submission Month",
    field: "submissionMonth",
    type: "belongsTo",
    accessorFunction: (row: any) => formatDateToYYYYMM(new Date(row.date)),
    formOptions: { required: false },
    excludeFromForm: true,
  },

  ...common,
};

export default employeeSatisfactionSchema;
