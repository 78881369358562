import { TenantQuestion } from "../../components/common/TenantQuestion";
import { ISchema } from "../SchemaTypes";
import common from "./commonSchema";

const stagesSchema: ISchema = {
  tenant: {
    title: "Tenant",
    field: "tenant",
    accessorFunction: (row: any) => row.tenant?.name,
    formOptions: { required: true },
    excludeFromForm: true,
    type: "belongsTo",
  },
  tenantStagesId: {
    title: "Tenant",
    field: "tenantStagesId",
    formOptions: {
      required: true,
      CustomQuestion: (props: any) => (
        <TenantQuestion {...props} fieldName="tenantStagesId" />
      ),
      options: {
        dataSource: "tenants",
        addBlank: true,
        accessorFunction: (dataSource: any) =>
          dataSource.map((row: any) => {
            return { value: row.id, label: row.name };
          }),
      },
      order: 1,
    },
    type: "select",
  },
  name: { title: "Name", field: "name", formOptions: { required: true } },

  order: {
    title: "Order",
    field: "order",
    type: "number",
    formOptions: { required: true },
  },

  probability: {
    title: "Probability",
    field: "probability",
    type: "number",
    formOptions: { required: true, min: 0, max: 1, step: 0.1, precision: 2 },
  },

  pipelines: {
    title: "Deals",
    field: "pipelines",
    type: "hasMany",
    accessorFunction: (row: any) => row.pipelines.length,
    formOptions: { required: true },
    excludeFromForm: true,
  },

  ...common,
};

export default stagesSchema;
