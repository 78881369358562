import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  Title,
  MediaQuery,
  Modal,
  Tabs,
  useMantineTheme,
  Box,
  ActionIcon,
  Button,
  Grid,
  Center,
  LoadingOverlay,
  Text,
} from "@mantine/core";
import extractCurrentRecord from "./functions/mainScreenHelpers";
import Form from "./Form";
import useFetchData from "../../hooks/useFetchData";
import { MantineReactTable } from "mantine-react-table";
import { IconEdit, IconPlus } from "@tabler/icons-react";
import useLoadingStatus from "../../hooks/useLoadingStatus";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import AppContext from "../../context/AppContext";
import RecordActivity from "./RecordActivity";

const TOP_PERCENTAGE = 25;

export default function CrudScreen(props: any) {
  const location = useLocation();
  const {
    columnVisibility: initialColumnVisibility,
    sorting: initialSorting,
    globalFilter: initialGlobalFilter,
    columnOrder: initialColumnOrder,
    showColumnFilters: initialShowColumnFilters,
    columnFilters: initialColumnFilters,
  } = location.state ?? {};
  const [columnVisibility, setColumnVisibility] = useState<any>(
    initialColumnVisibility
  );
  const [sorting, setSorting] = useState<any>(initialSorting ?? []);
  const [columnOrder, setColumnOrder] = useState<any>(initialColumnOrder ?? []);
  const [showColumnFilters, setShowColumnFilters] = useState<any>(
    initialShowColumnFilters ?? false
  );
  const [globalFilter, setGlobalFilter] = useState<any>(
    initialGlobalFilter ?? ""
  );
  const [columnFilters, setColumnFilters] = useState<any>(
    initialColumnFilters ?? []
  );
  const { loading: globalLoading } = useContext(AppContext);
  const navigate = useNavigate();
  const {
    data,
    schema,
    title,
    quickStats,
    tableColumns,
    formProps,
    pagePath,
    recordName,
    widerTitle = false,
    dataObjectKey,
    defaultSortColumn,
    showCreateButton = true,
    recordScreenTabs = {
      edit: { tabName: "Form" },
      activity: { tabName: "Record Activity", component: RecordActivity },
    },
    hideModalTabs = false,
    modalWidth = "xl",
    actionIconColumnWidth = 0,
    // modalFullScreen = false,
    modulesToLoad,
    CustomForm = null,
    createButtonFunction = () =>
      navigate(`./create`, {
        relative: "path",
        state: {
          columnVisibility,
          sorting,
          globalFilter,
          columnOrder,
          showColumnFilters,
          columnFilters,
        },
      }),
    rowActions = ({ row }: any) => (
      <Box>
        <ActionIcon
          // onClick={() => {
          //   navigate(
          //     `/${pagePath}/${
          //       row.original.integrationSystemId
          //         ? row.original.integrationSystemId
          //         : row.original.id
          //     }/edit`
          //   );
          // }}
          onClick={() => {
            navigate(`./${row.original.id}/edit`, {
              relative: "path",
              state: {
                columnVisibility,
                sorting,
                globalFilter,
                columnOrder,
                showColumnFilters,
                columnFilters,
              },
            });
          }}
          color={theme.primaryColor}
        >
          <IconEdit />
        </ActionIcon>
        {/* <ActionIcon onClick={() => console.info("Delete")}>
          <IconTrash />
        </ActionIcon> */}
      </Box>
    ),
  } = props;

  const loading = useLoadingStatus([dataObjectKey]);

  useFetchData(modulesToLoad);

  const theme = useMantineTheme();
  // const { "*": id } = useParams();
  const { recordId: id, tabPath } = useParams();

  const selectedRecord = useMemo(
    () => extractCurrentRecord(id, data),
    [data, id]
  );

  useEffect(() => {
    if (!location.state) {
      const initialColumnVisibility: any = Object.values(schema)
        .filter(
          (fieldObject: any) =>
            !fieldObject.excludeFromDataView ||
            fieldObject.type === "manyToMany"
        )
        .reduce((returnObject, fieldObject) => {
          //@ts-ignore
          returnObject[fieldObject.field] = tableColumns.includes(
            //@ts-ignore
            fieldObject.field
          );
          return returnObject;
        }, {});

      const initialColumnOrder = Object.values(schema)
        .filter(
          (fieldObject: any) =>
            !fieldObject.excludeFromDataView ||
            fieldObject.type === "manyToMany"
        )
        .map((fieldObject: any) => fieldObject?.field);

      setColumnOrder(["mrt-row-actions", ...initialColumnOrder]);
      setColumnVisibility(initialColumnVisibility);
    }
  }, []);

  const tableColumnsList = useMemo(
    () =>
      Object.values(schema)
        .filter(
          (fieldObject: any) => !fieldObject.excludeFromDataView //||
          // fieldObject.type === "manyToMany"
        )
        .map((fieldObject: any) => {
          const columnObject = {
            accessorKey: fieldObject?.field,
            accessorFn: fieldObject?.accessorFunction,
            header: fieldObject?.title,
            enableGlobalFilter: fieldObject.enableGlobalFilter
              ? fieldObject.enableGlobalFilter
              : true,
          };

          if (fieldObject.formatFunction) {
            //@ts-ignore
            columnObject.Cell = ({ renderedCellValue, row }) => (
              <>{fieldObject.formatFunction(renderedCellValue)}</>
            );
          }

          return columnObject;
        }),
    [schema]
  );

  const completeFormProps = useMemo(
    () => ({
      ...formProps,
      recordTableName: recordName,
      fields: Object.values(schema),
      onSubmissionFunction: (method: string) =>
        navigate(`/${pagePath}`, {
          state: {
            columnVisibility,
            sorting,
            globalFilter,
            columnOrder,
            showColumnFilters,
            columnFilters,
          },
        }),
      selectedRecord,
      sortFields: true,
      windowHeight: "60vh",
      dataObjectKey,
    }),
    [
      dataObjectKey,
      formProps,
      // navigate,
      // pagePath,
      recordName,
      schema,
      selectedRecord,
    ]
  );

  const tabKeys = Object.keys(recordScreenTabs);

  const onModalCloseFunction = () =>
    navigate(`/${pagePath}`, {
      state: {
        columnVisibility,
        sorting,
        globalFilter,
        columnOrder,
        showColumnFilters,
        columnFilters,
      },
    });

  return (
    <Box
      sx={{
        maxHeight:
          "calc(100vh - var(--mantine-footer-height, 0px) - var(--mantine-header-height, 0px) )",
        height:
          "calc(100vh - var(--mantine-footer-height, 0px) - var(--mantine-header-height, 0px) )",
        overflow: "hidden",
        paddingRight: "1em",
      }}
      // pt={"0.5em"}
    >
      <Box
        style={{
          height: `${TOP_PERCENTAGE}%`,
          maxHeight: `${TOP_PERCENTAGE}%`,
          width: "100%",
        }}
      >
        <Grid pt={"0.5em"}>
          <Grid.Col md={widerTitle ? 3 : 2}>
            <Title
              order={1}
              mb={"md"}
              color={theme.colors[theme.primaryColor][9]}
            >
              {title}
            </Title>
          </Grid.Col>
          <MediaQuery
            smallerThan={theme.breakpoints.md}
            styles={{ display: "none" }}
          >
            <Grid.Col md={widerTitle ? 9 : 10}>{quickStats}</Grid.Col>
          </MediaQuery>
        </Grid>
      </Box>
      <Box
        style={{
          height: `${100 - TOP_PERCENTAGE}%`,
          maxHeight: `${100 - TOP_PERCENTAGE}%`,
        }}
      >
        <MantineReactTable
          columns={tableColumnsList}
          state={{
            //@ts-ignore
            // showLoadingOverlay: !globalLoading && loading,
            showLoadingOverlay: false,
            showProgressBars: false,
            // showProgressBars: !globalLoading && loading,
            showSkeletons: false,
            //@ts-ignore
            // showSkeletons: !globalLoading && loading,
            columnVisibility,
            sorting,
            globalFilter,
            columnOrder,
            showColumnFilters,
            columnFilters,
          }}
          onSortingChange={setSorting}
          onColumnVisibilityChange={setColumnVisibility}
          onGlobalFilterChange={setGlobalFilter}
          onColumnOrderChange={setColumnOrder}
          onShowColumnFiltersChange={setShowColumnFilters}
          onColumnFiltersChange={setColumnFilters}
          // showProgressBars={false}
          enableRowActions
          renderRowActions={rowActions}
          displayColumnDefOptions={{
            "mrt-row-actions": {
              header: "",
              size: actionIconColumnWidth,
            },
          }}
          data={tableColumnsList ? data : []}
          enableColumnOrdering
          enableFullScreenToggle={false}
          enableDensityToggle={false}
          enableColumnActions={false}
          enableColumnResizing={true}
          enableMultiSort={true}
          renderEmptyRowsFallback={() =>
            globalLoading ? (
              <></>
            ) : loading ? (
              <LoadingOverlay zIndex={0} visible={true} />
            ) : (
              <Center>
                <Text color={"dimmed"}>No records to display</Text>
              </Center>
            )
          }
          //@ts-ignore
          mantineTableBodyCellProps={{ style: { textWrap: "wrap" } }}
          enableStickyHeader
          mantinePaginationProps={{
            showRowsPerPage: false,
          }}
          //   enableStickyFooter
          initialState={{
            showGlobalFilter: true,
            density: "xs",
            pagination: { pageIndex: 0, pageSize: 25 },

            sorting: defaultSortColumn
              ? [{ id: defaultSortColumn, desc: true }]
              : [],
            // sorting: defaultSortColumn
            // ? [{ id: defaultSortColumn, desc: true }]
            // : [],
            // showProgressBars: false,
          }}
          renderTopToolbarCustomActions={({ table }) => {
            return showCreateButton ? (
              <Button onClick={createButtonFunction} leftIcon={<IconPlus />}>
                Create New
              </Button>
            ) : null;
          }}
          // mantineTableBodyRowProps={{ style: { cursor: "pointer" } }}
          // positionGlobalFilter="left"
          mantineTableHeadCellProps={{
            sx: {
              "& .mantine-TableHeadCell-Content": {
                justifyContent: "space-between",
              },
            },
          }}
          mantineTableHeadRowProps={{
            style: {
              boxShadow: `4px 0 ${theme.colorScheme === "dark" ? "8" : "4"}px ${
                theme.colors[theme.primaryColor][9]
              }`,
            },
          }}
          mantineBottomToolbarProps={{
            style: {
              borderTop: `${
                theme.colors[theme.primaryColor][
                  theme.colorScheme === "dark" ? 9 : 2
                ]
              } 1px solid`,
            },
          }}
          mantineTableContainerProps={{
            style: {
              border: "none",
              boxShadow: "none",
            },
          }}
          mantineLoadingOverlayProps={{ zIndex: 200 }}
          mantineTableProps={{ style: { height: "100%" } }}
          mantineTopToolbarProps={{
            sx: {
              border: "none",
              boxShadow: "none",
              "& div:nth-of-type(2)": {
                flexDirection: "row-reverse",
              },
            },
          }}
          mantinePaperProps={{
            style: {
              border: "none",
              boxShadow: "none",
              maxHeight: "100%",
              height: "100%",
              display: "grid",
              // gridTemplateRows: "3.5rem 1fr 2.5rem ",
              gridTemplateRows: "auto 1fr 2.5rem ",
            },
          }}
        />
      </Box>
      <Modal
        opened={selectedRecord ? true : false}
        onClose={onModalCloseFunction}
        title={
          !tabPath ||
          !recordScreenTabs[tabPath] ||
          recordScreenTabs[tabPath].tabName === "Form" ? (
            <b>
              {selectedRecord === "create" || !selectedRecord
                ? `Create ${recordName}`
                : `Update ${recordName}`}
            </b>
          ) : (
            <b>{recordScreenTabs[tabPath].tabName}</b>
          )
        }
        padding="xl"
        //size={"80vw"}

        size={(tabPath && recordScreenTabs[tabPath]?.modalWidth) ?? modalWidth}
        fullScreen={(tabPath && recordScreenTabs[tabPath]?.fullScreen) ?? false}
        closeOnClickOutside={false}
        closeOnEscape={true}
        // position={"right"}
        styles={(theme) => ({
          content: {
            // height: "90vh",
          },
        })}
      >
        <Tabs
          /*defaultValue={tabKeys[0]}*/ value={
            id === "create" ? "edit" : tabPath
          }
          onTabChange={(value) =>
            navigate(`/${pagePath}/${id}/${value}`, {
              state: {
                columnVisibility,
                sorting,
                globalFilter,
                columnOrder,
                showColumnFilters,
                columnFilters,
              },
            })
          }
          keepMounted={false}
        >
          <Tabs.List
            sx={
              tabKeys.length === 1 || hideModalTabs || id === "create"
                ? { display: "none" }
                : {}
            }
          >
            {tabKeys.map((key: any) => {
              return (
                <Tabs.Tab value={key} key={key}>
                  {recordScreenTabs[key].tabName}
                </Tabs.Tab>
              );
            })}
          </Tabs.List>

          {tabKeys.map((key: any, index: any) => {
            const { component: Component, extraComponents = {} } =
              recordScreenTabs[key];
            return (
              <Tabs.Panel value={key} pt="xs" key={key + index}>
                {Component ? (
                  <Component
                    selectedRecord={selectedRecord}
                    onModalCloseFunction={onModalCloseFunction}
                    schema={schema}
                    {...extraComponents}
                  />
                ) : CustomForm ? (
                  <CustomForm {...completeFormProps} />
                ) : (
                  <Form {...completeFormProps} />
                )}
              </Tabs.Panel>
            );
          })}
        </Tabs>
      </Modal>
    </Box>
  );
}
