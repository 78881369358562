import { useContext } from "react";
import CrudScreen from "../common/CrudScreen";
import AppContext from "../../context/AppContext";
import { invoiceSchema as schema } from "../../context/dataSchema";
import {
  createInvoice as createQuery,
  updateInvoice as updateQuery,
  deleteInvoice as deleteQuery,
} from "../../graphql/mutations";

export default function Invoices() {
  const { dataObject } = useContext(AppContext);
  const { invoices: data = [] } = dataObject;

  const screenProps = {
    data,
    schema,
    title: "Invoices",
    quickStats: null,
    tableColumns: ["sow", "invoiceNumber", "amount"],
    filterFields: [],
    recordName: "Invoice",
    pagePath: "invoices",
    dataObjectKey: "invoices",
    formProps: {
      createQuery,
      updateQuery,
      deleteQuery,
      dataObjectKey: "invoices",
    },
    modulesToLoad: ["Verticals", "Statements of Work", "Invoices"],
  };
  return <CrudScreen {...screenProps} />;
}
