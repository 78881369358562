import { Button, Modal, NumberInput, TextInput, Title } from "@mantine/core";

export default function AddDashboardModal({
  opened,
  onClose,
  addDashboard,
  dashboardFormData,
  setDashboardFormData,
  updateDashboardFunction,
}: any) {
  return (
    <Modal
      opened={opened}
      onClose={() => {
        onClose();
        setDashboardFormData({ id: null, name: "", order: "" });
      }}
      title={
        <Title order={2}>
          {dashboardFormData.id ? "Update" : "New"} Dashboard
        </Title>
      }
      closeOnClickOutside={false}
    >
      <TextInput
        placeholder="Dashboard Name"
        mb={"md"}
        value={dashboardFormData.name}
        onChange={(e) => {
          const newDashboardFormData = structuredClone(dashboardFormData);
          setDashboardFormData({
            ...newDashboardFormData,
            name: e.target.value,
          });
        }}
      />
      <NumberInput
        placeholder="Order"
        mb={"md"}
        value={dashboardFormData.order}
        onChange={(value) => {
          const newDashboardFormData = structuredClone(dashboardFormData);
          setDashboardFormData({ ...newDashboardFormData, order: value });
        }}
      />
      <Button
        disabled={!dashboardFormData.name || !dashboardFormData.order}
        onClick={() => {
          if (dashboardFormData.id) {
            updateDashboardFunction(dashboardFormData);
          } else {
            addDashboard(dashboardFormData.name, dashboardFormData.order);
          }
          onClose();
          setDashboardFormData({ id: null, name: "", order: "" });
        }}
      >
        {dashboardFormData.id ? "Update" : "Create"} Dashboard
      </Button>
    </Modal>
  );
}
