import React, { useMemo } from "react";
import WidgetFrame from "../../WidgetFrame";
import { Group, LoadingOverlay, useMantineTheme } from "@mantine/core";
import ScoreCard from "../ScoreCard";
import StatsRing from "../StatsRing";
import { IconCheck } from "@tabler/icons-react";
import useLoadingStatus from "../../../../hooks/useLoadingStatus";

export default function TasksSummary({ globalFilteredData }: any) {
  const theme = useMantineTheme();
  const loading = useLoadingStatus(["tasks"]);
  const completed = useMemo(
    () => globalFilteredData.filter((task: any) => task.complete),
    [globalFilteredData]
  );

  return (
    <WidgetFrame title="Tasks">
      <Group spacing={"xl"}>
        <LoadingOverlay visible={loading} overlayOpacity={0} />
        {!loading && (
          <>
            <ScoreCard
              value={globalFilteredData.length}
              comparisonValue={globalFilteredData.length}
              comparisonText={"None"}
              formatFunction={(val) => val.toString()}
              percentageChange={false}
            />
            <StatsRing
              label={"% Completed"}
              stats={`${Math.round(
                (completed.length * 100) / globalFilteredData.length
              )}%`}
              progress={Math.round(
                (completed.length * 100) / globalFilteredData.length
              )}
              color={theme.primaryColor}
              icon={IconCheck}
            />
          </>
        )}
      </Group>
    </WidgetFrame>
  );
}
