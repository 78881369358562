import { useContext } from "react";
import CrudScreen from "../common/CrudScreen";
import AppContext from "../../context/AppContext";
import { employeeSatisfactionSchema as schema } from "../../context/dataSchema";
import {
  createEmployeeSatisfaction as createQuery,
  updateEmployeeSatisfaction as updateQuery,
  deleteEmployeeSatisfaction as deleteQuery,
} from "../../graphql/mutations";

export default function EmployeeSatisfaction() {
  const { dataObject } = useContext(AppContext);
  const { employeeSatisfaction: data = [] } = dataObject;

  const screenProps = {
    data,
    schema,
    title: "Employee Satisfaction",
    quickStats: null,
    tableColumns: ["person", "score", "comment", "createdAt"],
    filterFields: [],
    recordName: "Employee Satisfaction",
    pagePath: "employee-satisfaction",
    dataObjectKey: "employeeSatisfaction",
    formProps: {
      createQuery,
      updateQuery,
      deleteQuery,
      dataObjectKey: "employeeSatisfaction",
    },
    modulesToLoad: ["Verticals", "People", "Employee Satisfaction"],
  };
  return <CrudScreen {...screenProps} />;
}
