const getRiskBrag = (risk: any) => {
  const riskFactor = risk.probability * risk.impact;
  if (riskFactor <= 5) return "Low";
  if (riskFactor <= 14) return "Medium";
  if (riskFactor <= 22) return "High";
  return "Very High";
};

const getBragColor = (crag: any) => {
  switch (crag) {
    case "Low":
      return "green";
    case "Medium":
      return "yellow";
    case "High":
      return "orange";
    default:
      return "red";
  }
};

export { getRiskBrag, getBragColor };
