export const listPeople = /* GraphQL */ `
  query ListPeople(
    $filter: ModelPersonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPeople(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantAccessString
        verticalAccessString
        accountAccessString
        programmeAccessString
        dataSource
        system
        integrationSystemId
        integrationId
        audit
        period
        firstName
        lastName
        position
        email
        hoursPerWeek
        currentlyActive
        skills
        tenant {
          id
          tenantAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          createdAt
          updatedAt
        }
        employeeSatisfactionRecords {
          nextToken
          items {
            id
            score
            date
          }
        }
        annualLeave {
          nextToken
          items {
            id
            startDate
            endDate
            numberOfDays
          }
        }
        timesheets {
          nextToken
        }
        projectAllocations {
          nextToken
          items {
            id
            startDate
            endDate
            projectRole {
              id
              description
              unit
              startDate
              endDate
              plannedUnits
              status
              fte
              project {
                id
                name
              }
              pipeline {
                id
                dealName
              }
            }
          }
        }
        roleTypes {
          nextToken
          items {
            id
            roleType {
              id
              name
            }
          }
        }
        risks {
          nextToken
          items {
            id
            risk {
              id
              name
            }
          }
        }
        tasks {
          nextToken
          items {
            id
            task {
              id
              name
            }
          }
        }
        projectsDeliveryManaged {
          nextToken
          items {
            id
            project {
              id
              name
            }
          }
        }
        projectsProjectManaged {
          nextToken
          items {
            id
            project {
              id
              name
            }
          }
        }
        createdAt
        updatedAt
        tenantPeopleId
        owner
      }
      nextToken
    }
  }
`;

export const listRisks = /* GraphQL */ `
  query ListRisks(
    $filter: ModelRiskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRisks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantAccessString
        verticalAccessString
        accountAccessString
        programmeAccessString
        dataSource
        system
        integrationSystemId
        integrationId
        audit
        period
        name
        description
        actionOwner
        status
        impactDate
        cause
        effect
        impactDescription
        impactType
        riskResponse
        actionMitigation
        targetResolutionDate
        probability
        impact
        estimatedImpactCost
        escalatedToPortfolio
        detailedUpdate
        dateRaised
        dateOfNextReview
        riskOwners {
          nextToken
          items {
            id
            person {
              id
              firstName
              lastName
            }
          }
        }
        vertical {
          id
          tenantAccessString
          verticalAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          createdAt
          updatedAt
          tenantVerticalsId
          owner
        }
        account {
          id
          tenantAccessString
          accountAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          createdAt
          updatedAt
          tenantAccountsId
          owner
        }
        programme {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          createdAt
          updatedAt
          accountProgrammesId
          owner
        }
        project {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          tcv
          margin
          workstream
          currency
          budget
          spend
          country
          status
          startDate
          endDate
          createdAt
          updatedAt
          programmeProjectsId
          regionProjectsId
          owner
        }
        createdAt
        updatedAt
        verticalRisksId
        accountRisksId
        programmeRisksId
        projectRisksId
        owner
      }
      nextToken
    }
  }
`;

export const listProjects = /* GraphQL */ `
  query ListProjects(
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantAccessString
        verticalAccessString
        accountAccessString
        programmeAccessString
        dataSource
        system
        integrationSystemId
        integrationId
        audit
        period
        deliveryManagers {
          nextToken
          items {
            id
            person {
              id
              firstName
              lastName
            }
          }
        }
        projectManagers {
          nextToken
          items {
            id
            person {
              id
              firstName
              lastName
            }
          }
        }
        name
        tcv
        margin
        workstream
        currency
        budget
        spend
        revenueSold
        consumed
        costSold
        revenueActual
        costActual
        country
        status
        startDate
        endDate
        region {
          id
          name
          continent
          subregion
          createdAt
          updatedAt
        }

        programme {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          createdAt
          updatedAt
          accountProgrammesId
          owner
          verticalProgrammesId
          vertical {
            name
          }
          account {
            name
          }
        }
        tasks {
          nextToken
          items {
            id
            complete
            name
            startDate
            endDate
            type
          }
        }
        statements {
          nextToken
          items {
            id
            invoices {
              nextToken
              items {
                id
                amount
                invoiceDate
                paid
                createdAt
                audit
              }
            }
            createdAt
            audit
          }
        }
        projectRoles {
          nextToken
          items {
            id
            description
            unit
            unitRate
            plannedUnits
            actualUnits
            startDate
            endDate
            billingFrequency
            timesheets {
              nextToken
              items {
                id
                unit
                unitAmount
              }
            }
            createdAt
            audit
          }
        }
        risks {
          nextToken
          items {
            id
            impact
            probability
          }
        }
        createdAt
        updatedAt
        programmeProjectsId
        regionProjectsId
        owner
      }
      nextToken
    }
  }
`;

export const listRoleTypes = /* GraphQL */ `
  query ListRoleTypes(
    $filter: ModelRoleTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRoleTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantAccessString
        dataSource
        system
        integrationSystemId
        integrationId
        audit
        period
        name
        tenant {
          id
          tenantAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          createdAt
          updatedAt
        }
        people {
          nextToken
          items {
            id
            person {
              id
              firstName
              lastName
            }
          }
        }
        projectRoles {
          nextToken
          items {
            id
            projectRole {
              id
              description
            }
          }
        }
        createdAt
        updatedAt
        tenantRoleTypesId
        owner
      }
      nextToken
    }
  }
`;

export const listProjectRoles = /* GraphQL */ `
  query ListProjectRoles(
    $filter: ModelProjectRoleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjectRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantAccessString
        verticalAccessString
        accountAccessString
        programmeAccessString
        dataSource
        system
        integrationSystemId
        integrationId
        audit
        period
        status
        fte
        unit
        unitRate
        plannedUnits
        actualUnits
        description
        startDate
        endDate
        billingFrequency
        project {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          tcv
          margin
          workstream
          currency
          budget
          spend
          country
          status
          startDate
          endDate
          createdAt
          updatedAt
          programmeProjectsId
          regionProjectsId
          owner
        }
        pipeline {
          id
          tenantAccessString
          accountAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          amount
          closeDate
          closedNotes
          closingDate
          currency
          dealName
          description
          grossMarginLength
          grossMarginPerMonth
          incomeType
          intro
          managingPartner
          numberOfDeliveryDays
          opportunityLead
          pipeline
          serviceGoLiveDate
          sourceOfferingOpportunity
          totalGrossProfitValue
          totalRevenueValue
          typeOfSale
          createdAt
          updatedAt
          accountPipelinesId
          stagePipelinesId
          owner
        }
        projectAllocations {
          nextToken
          items {
            id
            person {
              id
              firstName
              lastName
            }
          }
        }
        roleTypes {
          nextToken
          items {
            id
            roleType {
              id
              name
            }
          }
        }
        timesheets {
          nextToken
          items {
            id
            unit
            unitAmount
            timesheetRef
            invoices {
              nextToken
              items {
                id
                amount
                paid
              }
            }
          }
        }
        createdAt
        updatedAt
        projectProjectRolesId
        pipelineProjectRolesId
        owner
      }
      nextToken
    }
  }
`;
