import React from "react";
import { Alert, Center, Stack, Text } from "@mantine/core";
import { IconSettingsCancel } from "@tabler/icons-react";
// import useFetchData from "../../hooks/useFetchData";

function ErrorComponent() {
  //   useFetchData([]);
  return (
    <Center style={{ height: "100%" }}>
      <Stack spacing={"xs"}>
        <Center>
          <IconSettingsCancel />
        </Center>
        <Text color="dimmed" size={"xl"}>
          Chart Configuration Error
        </Text>
      </Stack>
    </Center>
  );
}

class ChartErrorBoundary extends React.Component {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    console.log({ error, errorInfo });
  }

  render() {
    //@ts-ignore
    if (this.state.hasError) {
      return <ErrorComponent />;
    }
    //@ts-ignore
    return this.props.children;
  }
}

export default ChartErrorBoundary;
