import { useContext, useEffect, useState } from "react";
import AppContext from "../context/AppContext";

export default function useLoadingStatus(dataKeys: string[]) {
  const { dataObject } = useContext(AppContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (dataKeys.every((dataKey) => dataObject[dataKey])) {
      setLoading(false);
    }
  }, [dataObject, setLoading, dataKeys]);

  return loading;
}
