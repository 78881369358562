import { useContext, useEffect, useMemo, useState } from "react";
import AppContext from "../../../../context/AppContext";
import { PL } from "../../../../API";
import {
  Box,
  useMantineTheme,
  Progress,
  SegmentedControl,
  Text,
} from "@mantine/core";
import {
  MRT_ColumnDef,
  type MRT_Cell,
  type MRT_Column,
  type MRT_Row,
  type MRT_TableInstance,
  type MRT_AggregationFn,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import WidgetFrame from "../../WidgetFrame";
import { createFilterFunction } from "../../functions/createFilterFunction";

const AggregatedCell = ({
  cell,
  table,
}: {
  cell: MRT_Cell<PL>;
  column: MRT_Column<PL>;
  row: MRT_Row<PL>;
  table: MRT_TableInstance<PL>;
}) => (
  <>
    <Box sx={{ fontWeight: "bold" }}>{Math.round(cell.getValue<number>())}</Box>
  </>
);

const AggregatedCellCurrency = ({
  cell,
  table,
}: {
  cell: MRT_Cell<PL>;
  column: MRT_Column<PL>;
  row: MRT_Row<PL>;
  table: MRT_TableInstance<PL>;
}) => (
  <>
    <Box sx={{ fontWeight: "bold" }}>
      {cell.getValue<number>()?.toLocaleString?.("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })}
    </Box>
  </>
);

const AggregatedCellPercent = ({
  cell,
  table,
  row,
}: {
  cell: MRT_Cell<PL>;
  column: MRT_Column<PL>;
  row: MRT_Row<PL>;
  table: MRT_TableInstance<PL>;
}) => {
  const value = cell.getValue<number>();
  const rounded = value ? Math.round(value * 100) : 0;
  const color = value > 0.1 ? "green" : value > 0.05 ? "orange" : "red";
  return (
    <>
      <Box sx={{ color, fontWeight: "bold" }}>
        {rounded}%
        <Progress value={rounded} color={color} />
      </Box>
    </>
  );
};

const AggregatedCellPercentHours = ({
  cell,
  table,
  row,
}: {
  cell: MRT_Cell<PL>;
  column: MRT_Column<PL>;
  row: MRT_Row<PL>;
  table: MRT_TableInstance<PL>;
}) => {
  const value = cell.getValue<number>();
  const rounded = value ? Math.round(value * 100) : 0;
  const color = value > 1 ? "red" : value > 0.8 ? "orange" : "Green";
  return (
    <>
      <Box sx={{ color, fontWeight: "bold" }}>
        {rounded}%
        <Progress value={rounded} color={color} />
      </Box>
    </>
  );
};

const Cell = ({
  cell,
}: {
  cell: MRT_Cell<PL>;
  // renderedCellValue: React.ReactNode;
  // column: MRT_Column<PL>;
  // row: MRT_Row<PL>;
  // rowRef?: React.RefObject<HTMLTableRowElement> | undefined;
  // table: MRT_TableInstance<...>;
}) => <>{Math.round(cell.getValue<number>())}</>;

const CellCurrency = ({
  cell,
}: {
  cell: MRT_Cell<PL>;
  // renderedCellValue: React.ReactNode;
  // column: MRT_Column<PL>;
  // row: MRT_Row<PL>;
  // rowRef?: React.RefObject<HTMLTableRowElement> | undefined;
  // table: MRT_TableInstance<...>;
}) => (
  <>
    {cell.getValue<number>()?.toLocaleString?.("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })}
  </>
);

const CellPercent = ({
  cell,
}: {
  cell: MRT_Cell<PL>;
  // renderedCellValue: React.ReactNode;
  // column: MRT_Column<PL>;
  // row: MRT_Row<PL>;
  // rowRef?: React.RefObject<HTMLTableRowElement> | undefined;
  // table: MRT_TableInstance<...>;
}) => {
  const value = cell.getValue<number>();
  const rounded = value ? Math.round(value * 100) : 0;
  const color = value > 0.1 ? "green" : value > 0.05 ? "orange" : "red";
  return (
    <Box sx={{ color }}>
      {rounded}%
      <Progress value={rounded} color={color} />
    </Box>
  );
};

const Footer = ({ sum }: any) => <Box pb={"xs"}>{Math.round(sum)}</Box>;

const FooterCurrency = ({ sum }: any) => (
  <Box pb={"xs"}>
    {sum?.toLocaleString?.("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })}
  </Box>
);

const FooterPercent = ({ sum }: any) => (
  <Box pb={"xs"}>{Math.round(sum * 100)}%</Box>
);

type IPLFields = PL & {
  hoursBurned: number;
  revenuePlannedActualVariance: number;
  costPlannedActualVariance: number;
  plannedProfit: number;
  actualProfit: number;
};

const visibleFields = {
  financials: {
    region: true,
    subRegion: true,
    projectManager: true,
    engagementName: true,
    revenuePlannedUSD: true,
    revenueActualUSD: true,
    costPlannedUSD: true,
    costActualUSD: true,
    marginPlanned: true,
    marginActual: true,
    hoursSold: false,
    hoursPlanned: false,
    hoursActual: false,
    hoursBurned: false,
    revenuePlannedActualVariance: true,
    costPlannedActualVariance: true,
    plannedProfit: true,
    actualProfit: true,
  },
  hours: {
    region: true,
    subRegion: true,
    projectManager: true,
    engagementName: true,
    revenuePlannedUSD: false,
    revenueActualUSD: false,
    costPlannedUSD: false,
    costActualUSD: false,
    marginPlanned: false,
    marginActual: false,
    hoursSold: true,
    hoursPlanned: true,
    hoursActual: true,
    hoursBurned: true,
    revenuePlannedActualVariance: false,
    costPlannedActualVariance: false,
    plannedProfit: false,
    actualProfit: false,
  },
  all: {
    region: true,
    subRegion: true,
    projectManager: true,
    engagementName: true,
    revenuePlannedUSD: true,
    revenueActualUSD: true,
    costPlannedUSD: true,
    costActualUSD: true,
    marginPlanned: true,
    marginActual: true,
    hoursSold: true,
    hoursPlanned: true,
    hoursActual: true,
    hoursBurned: true,
    revenuePlannedActualVariance: true,
    costPlannedActualVariance: true,
    plannedProfit: true,
    actualProfit: true,
  },
};

const plannedMarginAgg: any = (leafRowString: any, childRows: any) => {
  const totalsObject = childRows.reduce(
    (obj: any, row: any) => {
      obj.revenuePlannedUSD += row.original.revenuePlannedUSD;
      obj.costPlannedUSD += row.original.costPlannedUSD;
      return obj;
    },
    {
      revenuePlannedUSD: 0,
      costPlannedUSD: 0,
    }
  );
  const { revenuePlannedUSD, costPlannedUSD } = totalsObject;
  return revenuePlannedUSD
    ? (revenuePlannedUSD - costPlannedUSD) / revenuePlannedUSD
    : 0;
};

const actualMarginAgg: any = (leafRowString: any, childRows: any) => {
  const totalsObject = childRows.reduce(
    (obj: any, row: any) => {
      obj.revenueActualUSD += row.original.revenueActualUSD;
      obj.costActualUSD += row.original.costActualUSD;
      return obj;
    },
    {
      revenueActualUSD: 0,
      costActualUSD: 0,
    }
  );
  const { revenueActualUSD, costActualUSD } = totalsObject;
  return revenueActualUSD
    ? (revenueActualUSD - costActualUSD) / revenueActualUSD
    : 0;
};

const hoursBurnedAgg: any = (leafRowString: any, childRows: any) => {
  const totalsObject = childRows.reduce(
    (obj: any, row: any) => {
      obj.hoursSold += row.original.hoursSold;
      obj.hoursActual += row.original.hoursActual;
      return obj;
    },
    {
      hoursSold: 0,
      hoursActual: 0,
    }
  );
  const { hoursSold, hoursActual } = totalsObject;
  return hoursSold ? hoursActual / hoursSold : 0;
};

export default function DeliveryTable(props: any) {
  const { dashboardFilterObjectList, updateDashboardFilter } = props;
  const { dataObject, currentProgramme, currentProgrammes, period }: any =
    useContext(AppContext);
  const { pls = [] }: { pls: PL[] } = dataObject;
  const [view, setView] = useState("financials");
  //   const theme = useMantineTheme();

  const localFilterFunction = useMemo(
    () => createFilterFunction([dashboardFilterObjectList]),
    [dashboardFilterObjectList]
  );

  const permittedProgrammeList = useMemo(
    () => (currentProgramme === "All" ? currentProgrammes : [currentProgramme]),
    [currentProgramme, currentProgrammes]
  );

  const filteredPls: PL[] = useMemo(
    () =>
      pls
        .filter((row: any) => {
          return (
            (currentProgramme === "All" ||
              permittedProgrammeList.includes(row["programmePlsId"])) &&
            row.period === "Live" &&
            row.status !== "Closed"
          );
        })
        .filter(localFilterFunction),
    [pls, currentProgramme, period, permittedProgrammeList, localFilterFunction]
  );

  const fieldAddedPls: IPLFields[] = useMemo(
    () =>
      filteredPls.map((pl) => {
        const newPl: IPLFields = structuredClone(pl);
        newPl.hoursBurned = pl.hoursSold
          ? (pl.hoursActual ?? 0) / pl.hoursSold
          : 0;
        newPl.revenuePlannedActualVariance =
          (pl.revenuePlannedUSD ?? 0) - (pl.revenueActualUSD ?? 0);
        newPl.costPlannedActualVariance =
          (pl.costPlannedUSD ?? 0) - (pl.costActualUSD ?? 0);

        newPl.plannedProfit =
          (pl.revenuePlannedUSD ?? 0) - (pl.costPlannedUSD ?? 0);
        newPl.actualProfit =
          (pl.revenueActualUSD ?? 0) - (pl.costActualUSD ?? 0);
        return newPl;
      }),
    [filteredPls]
  );

  const totalObject: { [key: string]: number } = useMemo(() => {
    const fields: string[] = [
      "revenuePlannedUSD",
      "revenueActualUSD",
      "costPlannedUSD",
      "costActualUSD",
      "marginPlanned",
      "marginActual",
      "hoursSold",
      "hoursPlanned",
      "hoursActual",
      "hoursBurned",
      "revenuePlannedActualVariance",
      "costPlannedActualVariance",
      "plannedProfit",
      "actualProfit",
    ];
    return fieldAddedPls.reduce((obj, curr) => {
      fields.forEach((field) => {
        //@ts-ignore
        if (!obj[field]) obj[field] = 0;
        //@ts-ignore
        obj[field] += curr[field];
      });
      return obj;
    }, {});
  }, [fieldAddedPls]);

  const columns = useMemo<MRT_ColumnDef<PL>[]>(
    () => [
      {
        header: "Region",
        accessorKey: "region",
        enableGrouping: true,
      },
      {
        header: "Sub Region",
        accessorKey: "subRegion",
        enableGrouping: true,
      },
      {
        header: "Project Manager",
        accessorKey: "projectManager",
        enableGrouping: true,
      },
      {
        header: "",
        accessorKey: "engagementName",
        size: 0,
        enableGrouping: false,
        enableSorting: false,
        Cell: ({ cell }: { cell: MRT_Cell<PL> }) => {
          return (
            <>
              <Text size={"xs"}>{cell.getValue<number>()}</Text>
            </>
          );
        },
      },
      {
        header: "Revenue Planned",
        accessorKey: "revenuePlannedUSD",
        aggregationFn: "sum",
        enableGrouping: false,
        AggregatedCell: AggregatedCellCurrency,
        Cell: CellCurrency,
        Footer: <FooterCurrency sum={totalObject["revenuePlannedUSD"]} />,
      },
      {
        header: "Revenue Actual",
        accessorKey: "revenueActualUSD",
        aggregationFn: "sum",
        enableGrouping: false,
        AggregatedCell: AggregatedCellCurrency,
        Cell: CellCurrency,
        Footer: <FooterCurrency sum={totalObject["revenueActualUSD"]} />,
      },
      {
        header: "Cost Planned",
        accessorKey: "costPlannedUSD",
        aggregationFn: "sum",
        enableGrouping: false,
        AggregatedCell: AggregatedCellCurrency,
        Cell: CellCurrency,
        Footer: <FooterCurrency sum={totalObject["costPlannedUSD"]} />,
      },
      {
        header: "Cost Actual",
        accessorKey: "costActualUSD",
        aggregationFn: "sum",
        enableGrouping: false,
        AggregatedCell: AggregatedCellCurrency,
        Cell: CellCurrency,
        Footer: <FooterCurrency sum={totalObject["costActualUSD"]} />,
      },
      {
        header: "Margin Planned",
        accessorKey: "marginPlanned",
        aggregationFn: plannedMarginAgg,
        enableGrouping: false,
        AggregatedCell: AggregatedCellPercent,
        Cell: CellPercent,
        Footer: (
          <FooterPercent
            sum={totalObject["marginPlanned"] / filteredPls.length}
          />
        ),
      },
      {
        header: "Margin Actual",
        accessorKey: "marginActual",
        aggregationFn: actualMarginAgg,
        enableGrouping: false,
        AggregatedCell: AggregatedCellPercent,
        Cell: CellPercent,
        Footer: (
          <FooterPercent
            sum={totalObject["marginActual"] / filteredPls.length}
          />
        ),
      },
      {
        header: "Hours Sold",
        accessorKey: "hoursSold",
        aggregationFn: "sum",
        enableGrouping: false,
        AggregatedCell,
        Cell,
        Footer: <Footer sum={totalObject["hoursSold"]} />,
      },
      {
        header: "Hours Planned",
        accessorKey: "hoursPlanned",
        aggregationFn: "sum",
        enableGrouping: false,
        AggregatedCell,
        Cell,
        Footer: <Footer sum={totalObject["hoursPlanned"]} />,
      },
      {
        header: "Hours Actual",
        accessorKey: "hoursActual",
        aggregationFn: "sum",
        enableGrouping: false,
        AggregatedCell,
        Cell,
        Footer: <Footer sum={totalObject["hoursActual"]} />,
      },
      {
        header: "% Hours Burned",
        // accessorFn: (row) =>
        //   row.hoursSold ? (row.hoursActual ?? 0) / row.hoursSold : 0,
        //@ts-ignore
        accessorKey: "hoursBurned",
        aggregationFn: hoursBurnedAgg,
        enableGrouping: false,
        AggregatedCell: AggregatedCellPercentHours,
        Cell: CellPercent,
        Footer: (
          <FooterPercent
            sum={totalObject["hoursBurned"] / filteredPls.length}
          />
        ),
      },
      {
        header: "Revenue Planned vs Actual Variance",
        //@ts-ignore
        accessorKey: "revenuePlannedActualVariance",
        aggregationFn: "sum",
        enableGrouping: false,
        AggregatedCell: AggregatedCellCurrency,
        Cell: CellCurrency,
        Footer: (
          <FooterCurrency sum={totalObject["revenuePlannedActualVariance"]} />
        ),
      },
      {
        header: "Costs Planned Vs Actual Variance",
        //@ts-ignore
        accessorKey: "costPlannedActualVariance",
        aggregationFn: "sum",
        enableGrouping: false,
        AggregatedCell: AggregatedCellCurrency,
        Cell: CellCurrency,
        Footer: (
          <FooterCurrency sum={totalObject["costPlannedActualVariance"]} />
        ),
      },
      {
        header: "Planned Profit",
        //@ts-ignore
        accessorKey: "plannedProfit",
        aggregationFn: "sum",
        enableGrouping: false,
        AggregatedCell: AggregatedCellCurrency,
        Cell: CellCurrency,
        Footer: <FooterCurrency sum={totalObject["plannedProfit"]} />,
      },
      {
        header: "Actual Profit",
        //@ts-ignore
        accessorKey: "actualProfit",
        aggregationFn: "sum",
        enableGrouping: false,
        AggregatedCell: AggregatedCellCurrency,
        Cell: CellCurrency,
        Footer: <FooterCurrency sum={totalObject["actualProfit"]} />,
      },
    ],
    [totalObject]
  );

  const table = useMantineReactTable({
    columns,
    data: fieldAddedPls,
    enableColumnResizing: false,
    enableGrouping: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    groupedColumnMode: false,
    enablePagination: false,
    enablePinning: true,
    enableColumnActions: false,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    mantineTableBodyCellProps: { sx: { textWrap: "wrap" } },
    // mantineTableBodyRowProps: ({ row }) => ({
    //   onClick: (event) => {
    //     console.info(event, row.id);
    //   },
    //   sx: {
    //     cursor: "pointer", //you might want to change the cursor too when adding an onClick
    //   },
    // }),
    initialState: {
      density: "xs",
      //@ts-ignore
      expanded: false,
      grouping: ["region", "subRegion", "projectManager"],
      //   pagination: { pageIndex: 0, pageSize: 100 },
      sorting: [{ id: "revenuePlannedUSD", desc: true }],
      columnPinning: {
        left: ["region", "subRegion", "projectManager", "engagementName"],
        right: [],
      },
    },
    state: {
      //@ts-ignore
      //   expanded: false,
      columnVisibility: {
        //@ts-ignore
        ...visibleFields[view],
        // engagementName: !(expanded === 0),
      },
    },
    mantineToolbarAlertBannerBadgeProps: { color: "blue", variant: "outline" },
    mantineTableContainerProps: { sx: {} },
    mantineTableProps: { style: { height: "100%" } },
    mantinePaperProps: {
      style: {
        border: "none",
        boxShadow: "none",
        maxHeight: "100%",
        height: "100%",
        display: "grid",
        gridTemplateRows: "1fr 0px ",
      },
    },
  });

  return (
    <WidgetFrame
      title={"Performance By Project Manager (Open Engagements)"}
      controls={
        <SegmentedControl
          value={view}
          onChange={(value) => setView(value)}
          data={[
            { label: "Financials", value: "financials" },
            { label: "Hours", value: "hours" },
            { label: "Both", value: "both" },
          ]}
        />
      }
    >
      {pls.length === 0 ? null : (
        <Box
          style={{
            height: "99%",
            minHeight: "99%",
            maxHeight: "99%",
          }}
        >
          <MantineReactTable table={table} />
        </Box>
      )}
    </WidgetFrame>
  );
}
