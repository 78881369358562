import common from "./commonSchema";
import { ISchema } from "../SchemaTypes";
import { TenantQuestion } from "../../components/common/TenantQuestion";

const accountsSchema: ISchema = {
  name: {
    title: "Name",
    field: "name",
    formOptions: { required: true },
    equivalentFields: { csats: "accountName" },
  },
  tenant: {
    title: "Tenant",
    field: "tenant",
    accessorFunction: (row: any) => row.tenant?.name,
    formOptions: { required: true },
    excludeFromForm: true,
    type: "belongsTo",
  },
  tenantAccountsId: {
    title: "Tenant",
    field: "tenantAccountsId",
    formOptions: {
      required: true,
      CustomQuestion: (props: any) => (
        <TenantQuestion {...props} fieldName="tenantAccountsId" />
      ),
      options: {
        dataSource: "tenants",
        addBlank: true,
        accessorFunction: (dataSource: any) =>
          dataSource.map((row: any) => {
            return { value: row.id, label: row.name };
          }),
      },
      order: 1,
    },
    type: "select",
  },

  programmes: {
    title: "Programmes",
    field: "programmes",
    type: "hasMany",
    accessorFunction: (row: any) => row.programmes?.length,
    formOptions: { required: true },
    excludeFromForm: true,
  },

  pipelines: {
    title: "Pipelines",
    field: "pipelines",
    type: "hasMany",
    accessorFunction: (row: any) => row.verticals?.length,
    formOptions: { required: true },
    excludeFromForm: true,
  },

  risks: {
    title: "Risks",
    field: "risks",
    type: "hasMany",
    accessorFunction: (row: any) => row.verticals?.length,
    formOptions: { required: true },
    excludeFromForm: true,
  },
  ...common,
};

export default accountsSchema;
