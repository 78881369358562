import { Box, Title, createStyles, useMantineTheme } from "@mantine/core";
import React, { useEffect } from "react";
import "./ContactForm.styles.css";

const useStyles = createStyles((theme) => ({
  outer: {
    paddingTop: "5em",
    paddingBottom: "5em",
    paddingLeft: "10em",
    paddingRight: "10em",
    [theme.fn.smallerThan("md")]: {
      paddingLeft: "1em",
      paddingRight: "1em",
    },
  },
}));

export const ContactForm = () => {
  const { classes, theme } = useStyles();
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      // @ts-ignore
      if (window.hbspt) {
        // @ts-ignore
        window.hbspt.forms.create({
          portalId: "141203430",
          formId: "484c6be6-ad85-4bfc-86d2-ba9dcc147932",
          target: "#hubspotForm",
        });
      }
    });
  }, []);

  return (
    <Box
      //   px={"10em"}
      //   py={"5em"}
      className={classes.outer}
      sx={{
        backgroundColor:
          theme.colorScheme === "dark"
            ? theme.colors.dark[8]
            : theme.colors.gray[2],
      }}
    >
      <Title order={2} size="h1" fw={900} ta="center" mb="md">
        Get in touch
      </Title>
      <div id="hubspotForm" style={{ minHeight: 294 }}></div>
    </Box>
  );
};
