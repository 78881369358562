import {
  Box,
  Button,
  Divider,
  Modal,
  MultiSelect,
  Stack,
  Title,
  useMantineTheme,
} from "@mantine/core";
import { useContext, useEffect, useMemo, useState } from "react";
import AppContext from "../../context/AppContext";
import { IconLock, IconUsers, IconWorld } from "@tabler/icons-react";

const buttonList = [
  { value: "me", icon: <IconLock />, label: "Only Me" },
  { value: "org", icon: <IconWorld />, label: "Whole Organisation" },
  { value: "custom", icon: <IconUsers />, label: "Custom" },
];

export default function SharingModal({
  opened,
  onClose,
  currentDashboard,
  updateDashboardFunction,
  setCurrentDashboard,
}: any) {
  const [selectedSetting, setSelectedSetting] = useState<any>("me");
  const [submissionValue, setSubmissionValue] = useState<any>([]);
  const [selectedUserTypes, setSelectedUserTypes] = useState<any>([]);
  const [selectedUsers, setSelectedUsers] = useState<any>([]);
  const {
    dataObject: { users = [], userTypes = [] },
    currentTenant,
  } = useContext(AppContext);
  const theme = useMantineTheme();

  const userTypeIds = useMemo(
    () => userTypes.map((userType: any) => userType.id),
    [userTypes]
  );

  useEffect(() => {
    if (!currentDashboard) return;
    const { userTypeAccessStrings } = currentDashboard;

    if (userTypeAccessStrings.length === 0) {
      setSelectedSetting("me");
      setSelectedUserTypes([]);
      setSelectedUsers([]);
      return;
    }

    if (
      userTypeAccessStrings.length === 1 &&
      userTypeAccessStrings[0] === currentTenant
    ) {
      setSelectedSetting("org");
      setSelectedUserTypes([]);
      setSelectedUsers([]);
      return;
    }

    const newTypes: any = [];
    const newUsers: any = [];

    userTypeAccessStrings.forEach((accessGroup: any) => {
      if (userTypeIds.includes(accessGroup)) {
        newTypes.push(accessGroup);
      } else {
        newUsers.push(accessGroup);
      }
    });

    setSelectedSetting("custom");
    setSelectedUserTypes(newTypes);
    setSelectedUsers(newUsers);
  }, [currentDashboard, currentTenant, userTypeIds]);

  useEffect(() => {
    switch (selectedSetting) {
      case "org":
        setSubmissionValue([currentTenant]);
        break;
      case "custom":
        setSubmissionValue([...selectedUserTypes, ...selectedUsers]);
        break;
      default:
        setSubmissionValue([]);
    }
  }, [
    selectedSetting,
    selectedUserTypes,
    selectedUsers,
    setSubmissionValue,
    currentTenant,
  ]);

  const userEmails = users.map((user: any) => user.email);

  return (
    <Modal
      opened={opened}
      onClose={() => {
        onClose();
      }}
      title={<Title order={2}>Who can view this dashboard?</Title>}
      closeOnClickOutside={false}
    >
      <Stack mb={"md"}>
        {buttonList.map((buttonObject: any) => {
          const { value, label, icon } = buttonObject;
          return (
            <Button
              size={"lg"}
              radius={"xl"}
              key={label}
              variant={selectedSetting === value ? "light" : "outline"}
              leftIcon={icon}
              color={theme.primaryColor}
              value={value}
              onClick={() => setSelectedSetting(value)}
            >
              {label}
            </Button>
          );
        })}
      </Stack>

      {selectedSetting === "custom" && (
        <Box mb={"md"}>
          <MultiSelect
            label="User Types That Can View"
            data={userTypes.map((userType: any) => {
              return { value: userType.id, label: userType.name };
            })}
            value={selectedUserTypes}
            onChange={(value) => setSelectedUserTypes(value)}
            mb={"md"}
          />
          <MultiSelect
            label="Users That Can View"
            data={userEmails}
            value={selectedUsers}
            onChange={(value) => setSelectedUsers(value)}
          />
        </Box>
      )}
      <Divider mb={"md"} />
      <Stack>
        <Button
          onClick={() => {
            const newDashboard = structuredClone(currentDashboard);
            newDashboard.userTypeAccessStrings = submissionValue;
            updateDashboardFunction(newDashboard);
            setCurrentDashboard(newDashboard);
            onClose();
          }}
        >
          Save Sharing Settings
        </Button>
      </Stack>
    </Modal>
  );
}
