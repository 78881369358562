function levenshteinDistance(str1: string, str2: string) {
  const m = str1.length;
  const n = str2.length;
  const dp = new Array(m + 1).fill(null).map(() => new Array(n + 1).fill(0));

  for (let i = 0; i <= m; i++) {
    for (let j = 0; j <= n; j++) {
      if (i === 0) {
        dp[i][j] = j;
      } else if (j === 0) {
        dp[i][j] = i;
      } else {
        const cost = str1[i - 1] === str2[j - 1] ? 0 : 1;
        dp[i][j] = Math.min(
          dp[i - 1][j] + 1,
          dp[i][j - 1] + 1,
          dp[i - 1][j - 1] + cost
        );
      }
    }
  }

  return dp[m][n];
}

function mostSimilarElement(
  query: string,
  targetValues: { value: string; label: string }[],
  tolerance: number = 10
): { value: string; label: string } | null {
  let minDistance = Infinity;
  let mostSimilarElement = null;

  for (const targetValue of targetValues) {
    const distance = levenshteinDistance(query, targetValue.label);
    if (distance < minDistance) {
      minDistance = distance;
      mostSimilarElement = targetValue;
    }
  }

  if (minDistance > tolerance) {
    return null;
  }

  return mostSimilarElement;
}

export const autoMap = (
  mappingObject: any,
  targetValues: { value: string; label: string }[],
  setMappingObject: any,
  tolerance: number = 10
) => {
  const mappingObjectCopy = structuredClone(mappingObject);
  const { unmapped } = mappingObjectCopy;

  for (var i = 0; i < unmapped.length; i++) {
    const valueObject: { value: string; label: string } = unmapped[i];
    const autoMatch = mostSimilarElement(
      valueObject.value,
      targetValues,
      tolerance
    );
    if (autoMatch) {
      if (!mappingObjectCopy[autoMatch.value]) {
        mappingObjectCopy[autoMatch.value] = [];
      }
      const removedElement = unmapped.splice(i, 1)[0];
      mappingObjectCopy[autoMatch.value].unshift(removedElement);
      i--;
    }
  }
  mappingObjectCopy.unmapped = unmapped;

  setMappingObject(mappingObjectCopy);
};
