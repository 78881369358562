import { useContext } from "react";
import AppContext from "../../context/AppContext";
import {
  AlertTriangle,
  ArrowUpRight,
  ArrowDownRight,
} from "tabler-icons-react";
import { Grid, Group, Text, Paper, createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  value: {
    fontSize: 24,
    fontWeight: 700,
    lineHeight: 1,
  },

  diff: {
    lineHeight: 1,
    display: "flex",
    alignItems: "center",
  },

  icon: {
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[3]
        : theme.colors.gray[4],
  },

  title: {
    fontWeight: 700,
    textTransform: "uppercase",
  },

  paper: {
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[6]
        : theme.colors.gray[0],
  },
}));

export default function PersonQuickStats({ people }: any) {
  const { classes } = useStyles();
  return (
    <div>
      <Grid>
        <Grid.Col xs={12} md={3}>
          <Paper withBorder p="md" radius="md" className={classes.paper}>
            <Group position="apart">
              <Text size="xs" color="dimmed" className={classes.title}>
                Active People
              </Text>
              <AlertTriangle className={classes.icon} size={22} />
            </Group>

            <Group align="flex-end" spacing="xs" mt={25}>
              <Text className={classes.value}>
                {
                  people.filter(
                    (person: any) => person.currentlyActive === "Yes"
                  ).length
                }
              </Text>
              {/* <Text
                color={10 > 0 ? "teal" : "red"}
                size="sm"
                weight={500}
                className={classes.diff}
              >
                <span>{10}%</span>
                
                {10 > 0 ? (
                  <ArrowUpRight size={16} />
                ) : (
                  <ArrowDownRight size={16} />
                )}
              </Text> */}
            </Group>

            {/* <Text size="xs" color="dimmed" mt={7}>
              Compared to previous month
            </Text> */}
          </Paper>
        </Grid.Col>
      </Grid>
    </div>
  );
}
