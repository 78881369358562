import { Box } from "@mantine/core";
import useFetchData from "../../hooks/useFetchData";
// import WidgetFrame from "./WidgetFrame";
// import { TemperamentsReport } from "./ClientComponents/Connect/TemperamentsReport";
import { RadarChart } from "./graphs/RadarChart";

export default function TestDev(props: any) {
  useFetchData([]);

  return (
    <Box pt={"md"} h={600}>
      <RadarChart />
    </Box>
  );
}
