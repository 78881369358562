import React, { useCallback } from "react";
import DashboardFilter from "../DashboardFilter/DashboardFilter";
import { Group, Button } from "@mantine/core";
import { Apps, DeviceFloppy, Edit, UserPlus } from "tabler-icons-react";
import { DateFilter } from "../DateFilter";

export function DashboardToolbar({
  currentDashboard,
  editMode,
  setCurrentDashboard,
  updateDashboardFunction,
  dashboardFilterObject,
  setDashboardFilterObject,
  comparisonPeriodOverride,
  setComparisonPeriodOverride,
  globalDashboardFilteredDataObject,
  dashboardDataObject,
  setEditMode,
  open,
  canEdit,
  openSharingModal,
  layout,
  os,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  openDeleteModal,
}: any) {
  const saveDashboardLayout = useCallback(
    (layout: any) => {
      const updatedDashboard = structuredClone(currentDashboard);
      updatedDashboard.configuration = JSON.parse(
        updatedDashboard.configuration
      );
      for (let key in layout) {
        for (let i = 0; i < layout[key].length; i++) {
          const { x, y, w, h } = layout[key][i];
          updatedDashboard.configuration.widgets[i].dataGrid[key] = {
            x,
            y,
            w,
            h,
          };
        }
      }
      updatedDashboard.configuration = JSON.stringify(
        updatedDashboard.configuration
      );
      setCurrentDashboard(updatedDashboard);
      updateDashboardFunction(updatedDashboard);
    },
    [currentDashboard, updateDashboardFunction]
  );

  return (
    <Group
      position={["ios", "android"].includes(os) ? "right" : "apart"}
      mt={"xs"}
    >
      <Group>
        {editMode && !["ios", "android"].includes(os) && (
          <Button variant={"outline"} color={"red"} onClick={openDeleteModal}>
            Delete Dashboard
          </Button>
        )}
        <DashboardFilter
          editMode={editMode}
          currentDashboard={currentDashboard}
          setCurrentDashboard={setCurrentDashboard}
          updateDashboardFunction={updateDashboardFunction}
          filterInfoList={[]}
          dashboardFilterObject={dashboardFilterObject}
          setDashboardFilterObject={setDashboardFilterObject}
          comparisonPeriod={comparisonPeriodOverride}
          setComparisonPeriod={setComparisonPeriodOverride}
          globalDashboardFilteredDataObject={globalDashboardFilteredDataObject}
          dashboardDataObject={dashboardDataObject}
        />
        <DateFilter
          editMode={editMode}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          comparisonPeriod={comparisonPeriodOverride}
          setComparisonPeriod={setComparisonPeriodOverride}
          updateDashboardFunction={updateDashboardFunction}
          setCurrentDashboard={setCurrentDashboard}
          currentDashboard={currentDashboard}
        />
      </Group>

      {canEdit && !editMode && (
        <Group>
          <Button
            variant={"outline"}
            radius={"xl"}
            leftIcon={<UserPlus />}
            onClick={openSharingModal}
          >
            Sharing Settings
          </Button>
          <Button leftIcon={<Edit />} onClick={() => setEditMode(!editMode)}>
            Edit
          </Button>
        </Group>
      )}

      {editMode && (
        <>
          <Group>
            {!["ios", "android"].includes(os) && (
              <>
                <Button leftIcon={<Apps />} onClick={open} variant="outline">
                  Add Widget
                </Button>
              </>
            )}
            <Button
              leftIcon={<DeviceFloppy />}
              onClick={() => {
                saveDashboardLayout(layout);
                setEditMode(!editMode);
              }}
            >
              Save
            </Button>
          </Group>
        </>
      )}
    </Group>
  );
}
