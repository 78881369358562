export const updatePerson = /* GraphQL */ `
  mutation UpdatePerson(
    $input: UpdatePersonInput!
    $condition: ModelPersonConditionInput
  ) {
    updatePerson(input: $input, condition: $condition) {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      firstName
      lastName
      email
      position
      currentlyActive
      skills
      tenant {
        id
        tenantAccessString
        dataSource
        system
        integrationSystemId
        integrationId
        audit
        period
        name
        stages {
          nextToken
        }
        verticals {
          nextToken
        }
        roleTypes {
          nextToken
        }
        people {
          nextToken
        }
        createdAt
        updatedAt
      }
      employeeSatisfactionRecords {
        items {
          id
          tenantAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          score
          comment
          createdAt
          updatedAt
          personEmployeeSatisfactionRecordsId
          owner
        }
        nextToken
      }
      timesheets {
        items {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          startDate
          endDate
          timeSheetData
          createdAt
          updatedAt
          personTimesheetsId
          projectRoleTimesheetsId
          owner
        }
        nextToken
      }
      projectAllocations {
        items {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          startDate
          endDate
          createdAt
          updatedAt
          personProjectAllocationsId
          projectRoleProjectAllocationsId
          owner
        }
        nextToken
      }
      roleTypes {
        items {
          id
          roleTypeID
          roleType {
            id
            name
          }
          personID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      risks {
        items {
          id
          riskID
          personID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      tasks {
        items {
          id
          taskID
          personID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      projectsDeliveryManaged {
        items {
          id
          projectID
          personID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      projectsProjectManaged {
        items {
          id
          projectID
          personID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      tenantPeopleId
      owner
    }
  }
`;
export const updateRisk = /* GraphQL */ `
  mutation UpdateRisk(
    $input: UpdateRiskInput!
    $condition: ModelRiskConditionInput
  ) {
    updateRisk(input: $input, condition: $condition) {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      description
      actionOwner
      status
      impactDate
      cause
      effect
      impactDescription
      impactType
      riskResponse
      actionMitigation
      targetResolutionDate
      probability
      impact
      estimatedImpactCost
      escalatedToPortfolio
      detailedUpdate
      dateRaised
      dateOfNextReview
      riskOwners {
        items {
          id
          riskID
          personID
          person {
            id
            firstName
            lastName
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      vertical {
        id
        tenantAccessString
        verticalAccessString
        dataSource
        system
        integrationSystemId
        integrationId
        audit
        period
        name
        tenant {
          id
          tenantAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          createdAt
          updatedAt
        }
        programmes {
          nextToken
        }
        risks {
          nextToken
        }
        createdAt
        updatedAt
        tenantVerticalsId
        owner
      }
      account {
        id
        tenantAccessString
        accountAccessString
        dataSource
        system
        integrationSystemId
        integrationId
        audit
        period
        name
        tenant {
          id
          tenantAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          createdAt
          updatedAt
        }
        programmes {
          nextToken
        }
        pipelines {
          nextToken
        }
        risks {
          nextToken
        }
        createdAt
        updatedAt
        tenantAccountsId
        owner
      }
      programme {
        id
        tenantAccessString
        verticalAccessString
        accountAccessString
        programmeAccessString
        dataSource
        system
        integrationSystemId
        integrationId
        audit
        period
        name
        account {
          id
          tenantAccessString
          accountAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          createdAt
          updatedAt
          owner
        }
        projects {
          nextToken
        }
        csats {
          nextToken
        }
        risks {
          nextToken
        }
        weeklyUpdates {
          nextToken
        }
        mrrs {
          nextToken
        }
        costs {
          nextToken
        }
        pls {
          nextToken
        }
        createdAt
        updatedAt
        accountProgrammesId
        owner
      }
      project {
        id
        tenantAccessString
        verticalAccessString
        accountAccessString
        programmeAccessString
        dataSource
        system
        integrationSystemId
        integrationId
        audit
        period
        deliveryManagers {
          nextToken
        }
        projectManagers {
          nextToken
        }
        name
        tcv
        margin
        workstream
        currency
        budget
        spend
        country
        status
        startDate
        endDate
        region {
          id
          name
          createdAt
          updatedAt
        }
        programme {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          createdAt
          updatedAt
          accountProgrammesId
          owner
        }
        tasks {
          nextToken
        }
        statements {
          nextToken
        }
        projectRoles {
          nextToken
        }
        risks {
          nextToken
        }
        createdAt
        updatedAt
        programmeProjectsId
        regionProjectsId
        owner
      }
      createdAt
      updatedAt
      verticalRisksId
      accountRisksId
      programmeRisksId
      projectRisksId
      owner
    }
  }
`;

export const updateProject = /* GraphQL */ `
  mutation UpdateProject(
    $input: UpdateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    updateProject(input: $input, condition: $condition) {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      deliveryManagers {
        items {
          id
          projectID
          personID
          person {
            id
            firstName
            lastName
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      projectManagers {
        items {
          id
          projectID
          personID
          person {
            id
            firstName
            lastName
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      name
      tcv
      margin
      workstream
      currency
      budget
      spend
      country
      revenueSold
      consumed
      costSold
      revenueActual
      costActual
      status
      startDate
      endDate
      region {
        id
        name
        projects {
          nextToken
        }
        createdAt
        updatedAt
      }
      programme {
        id
        tenantAccessString
        verticalAccessString
        accountAccessString
        programmeAccessString
        dataSource
        system
        integrationSystemId
        integrationId
        audit
        period
        name
        verticalProgrammesId
        vertical {
          id
          name
        }
        account {
          id
          tenantAccessString
          accountAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          createdAt
          updatedAt
          owner
        }
        projects {
          nextToken
        }
        csats {
          nextToken
        }
        risks {
          nextToken
        }
        weeklyUpdates {
          nextToken
        }
        mrrs {
          nextToken
        }
        costs {
          nextToken
        }
        pls {
          nextToken
        }
        createdAt
        updatedAt
        accountProgrammesId
        owner
      }
      tasks {
        items {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          createdAt
          updatedAt
          projectTasksId
          owner
        }
        nextToken
      }
      statements {
        items {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          agreement
          description
          date
          contractType
          days
          durationDays
          location
          valueCredits
          valueUsd
          consumed
          workPackages
          paid
          stage
          completedDate
          createdAt
          updatedAt
          projectStatementsId
          pipelineSowRecordsId
          owner
        }
        nextToken
      }
      projectRoles {
        items {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          description
          createdAt
          updatedAt
          projectProjectRolesId
          owner
        }
        nextToken
      }
      risks {
        items {
          id
          tenantAccessString
          verticalAccessString
          accountAccessString
          programmeAccessString
          dataSource
          system
          integrationSystemId
          integrationId
          audit
          period
          name
          description
          actionOwner
          status
          impactDate
          cause
          effect
          impactDescription
          impactType
          riskResponse
          actionMitigation
          targetResolutionDate
          probability
          impact
          estimatedImpactCost
          escalatedToPortfolio
          detailedUpdate
          dateRaised
          dateOfNextReview
          createdAt
          updatedAt
          verticalRisksId
          accountRisksId
          programmeRisksId
          projectRisksId
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      programmeProjectsId
      regionProjectsId
      owner
    }
  }
`;

export const updateRoleType = /* GraphQL */ `
  mutation UpdateRoleType(
    $input: UpdateRoleTypeInput!
    $condition: ModelRoleTypeConditionInput
  ) {
    updateRoleType(input: $input, condition: $condition) {
      id
      tenantAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      tenant {
        id
        tenantAccessString
        dataSource
        system
        integrationSystemId
        integrationId
        audit
        period
        name
        stages {
          nextToken
        }
        verticals {
          nextToken
        }
        roleTypes {
          nextToken
        }
        people {
          nextToken
        }
        createdAt
        updatedAt
      }
      people {
        items {
          id
          roleTypeID
          personID
          person {
            id
            firstName
            lastName
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      projectRoles {
        items {
          id
          roleTypeID
          projectRoleID
          projectRole {
            id
            description
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      tenantRoleTypesId
      owner
    }
  }
`;
