import { getStylesRef, createStyles, Group, Switch } from "@mantine/core";
import { useContext, useEffect, useMemo, useState } from "react";
import AppContext from "../../../../context/AppContext";
import { ProgrammeTile } from "./ProgrammeTile";

import WidgetFrame from "../../WidgetFrame";
import { Carousel, Embla } from "@mantine/carousel";
import useLoadingStatus from "../../../../hooks/useLoadingStatus";
import { createFilterFunction } from "../../functions/createFilterFunction";
import useFetchData from "../../../../hooks/useFetchData";
import { IconCalendar } from "@tabler/icons-react";

const useStyles = createStyles((theme) => ({
  controls: {
    ref: getStylesRef("controls"),
    transition: "opacity 150ms ease",
    opacity: 0,
  },

  root: {
    "&:hover": {
      [`& .${getStylesRef("controls")}`]: {
        opacity: 1,
      },
    },
  },
}));

export default function ProgrammeSummary(props: any) {
  const {
    dashboardFilterObjectList,
    updateDashboardFilter,
    startDate,
    endDate,
  } = props;
  const { classes } = useStyles();
  const {
    dataObject: { programmes = [], projects = [], risks = [] },
    currentTenant,
    currentProgramme,
    currentProgrammes,
    setCurrentProgramme,
  } = useContext(AppContext);
  const [embla, setEmbla] = useState<Embla | null>(null);
  const [visibleSlides, setVisibleSlides] = useState<number[]>([]);
  const [currentSlide, setCurrentSlide] = useState<number | null>(0);
  const [showRevenue, setShowRevenue] = useState<boolean>(false);

  useFetchData(["Accounts", "Projects", "Programmes", "Risks"], ["Live"]);

  const loading = useLoadingStatus([
    "accounts",
    "projects",
    "programmes",
    "risks",
  ]);

  const localFilterFunction = useMemo(
    () =>
      createFilterFunction(
        [dashboardFilterObjectList],
        "startDate",
        startDate,
        endDate
      ),
    [dashboardFilterObjectList]
  );

  const permittedProgrammeList = useMemo(
    () => (currentProgramme === "All" ? currentProgrammes : [currentProgramme]),
    [currentProgramme, currentProgrammes]
  );

  const filteredProgrammes = useMemo(
    () =>
      programmes.filter(
        (programme: any) =>
          programme.period === "Live" &&
          permittedProgrammeList.includes(programme.id)
      ),
    [programmes, permittedProgrammeList]
  );

  const filteredProjects = useMemo(() => {
    return projects
      .filter(
        (project: any) =>
          project.period === "Live" &&
          currentProgrammes.includes(project.programmeProjectsId)
      )
      .filter(localFilterFunction);
  }, [projects, localFilterFunction, currentProgrammes]);

  const projectObject = useMemo(
    () =>
      filteredProjects.reduce((object: any, project: any) => {
        if (!object[project.programme.id]) {
          object[project.programme.id] = { total: 0, paid: 0, consumed: 0 };
        }
        object[project.programme.id].total += project.revenueSold;
        // object[project.programme.id].consumed += project.consumed;
        // object[project.programme.id].paid += project.revenueActual;

        object[project.programme.id].consumed +=
          project?.statements?.items?.reduce((total: any, statement: any) => {
            total += statement?.invoices?.items.reduce(
              (total: any, invoice: any) => {
                // if (!invoice.paid) {
                total += invoice.amount;
                // }
                return total;
              },
              0
            );
            return total;
          }, 0);

        object[project.programme.id].paid += project?.statements?.items?.reduce(
          (total: any, statement: any) => {
            total += statement?.invoices?.items.reduce(
              (total: any, invoice: any) => {
                if (invoice.paid) {
                  total += invoice.amount;
                }
                return total;
              },
              0
            );
            return total;
          },
          0
        );
        return object;
      }, {}),
    [filteredProjects]
  );

  // const totalsObject = useMemo(() => {
  //   return filteredProjects.reduce(
  //     (object: any, project: any) => {
  //       object.total += project.revenueSold;
  //       object.consumed += project.consumed;
  //       object.paid += project.billedRedeemedUSD;
  //       return object;
  //     },
  //     { total: 0, paid: 0, consumed: 0 }
  //   );
  // }, [filteredProjects]);

  const sortedProgrammes = useMemo(() => {
    return filteredProgrammes
      .sort((a: any, b: any) => {
        return a.name === b.name ? 0 : a.name > b.name ? -1 : 1;
      })
      .sort((a: any, b: any) => {
        return (
          (projectObject[a.id]?.total ?? 0) - (projectObject[b.id]?.total ?? 0)
        );
      })
      .reverse();
  }, [filteredProgrammes, projectObject]);

  useEffect(() => {
    if (embla) {
      const slidesInView = embla.slidesInView() ?? [0];
      setVisibleSlides([
        slidesInView[0] - 1,
        ...slidesInView, //@ts-ignore
        slidesInView.at(-1) + 1,
      ]);
    }
  }, [embla, filteredProgrammes, currentSlide]);

  return (
    <WidgetFrame
      title={"Programmes Summary"}
      icon={IconCalendar}
      controls={
        <Group>
          <Switch
            label="Show Revenue"
            checked={showRevenue}
            onChange={(e) => setShowRevenue(e.target.checked)}
          />
        </Group>
      }
    >
      <Carousel
        // mx="auto"
        align="start"
        slideGap="md"
        controlsOffset="xs"
        controlSize={21}
        px={"md"}
        // withIndicators
        classNames={classes}
        getEmblaApi={setEmbla}
        onSlideChange={setCurrentSlide}
        style={{ overflow: "hidden" }}
      >
        {sortedProgrammes.map((programme: any, index: number) => {
          return (
            <Carousel.Slide
              size={showRevenue ? 400 : 250}
              key={programme.id}
              p={5}
            >
              {visibleSlides.includes(index) ? (
                <ProgrammeTile
                  name={programme.name}
                  id={programme.id}
                  tenant={currentTenant}
                  loading={loading}
                  completed={3600000}
                  total={100}
                  setCurrentProgramme={setCurrentProgramme}
                  currentProgramme={currentProgramme}
                  totalsObject={projectObject[programme.id] ?? null}
                  showRevenue={showRevenue}
                  stats={[
                    {
                      value: filteredProjects.filter(
                        (project: any) =>
                          project.programme?.id === programme.id &&
                          project.period === "Live"
                      ).length,
                      label: "Projects",
                    },
                    // {
                    //   value: risks.filter(
                    //     (risk: any) =>
                    //       risk.programmeRisksId === programme.id &&
                    //       risk.period === "Live"
                    //   ).length,
                    //   label: "Risks",
                    // },
                    {
                      value: risks.filter((risk: any) => {
                        return (
                          risk.programmeAccessString === programme.id &&
                          risk.status === "Open" &&
                          risk.period === "Live" &&
                          risk.impact * risk.probability >= 20
                        );
                      }).length,
                      label: "High Risks",
                    },
                  ]}
                />
              ) : null}
            </Carousel.Slide>
          );
        })}
      </Carousel>
    </WidgetFrame>
  );
}
