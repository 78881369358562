// import { useContext, useEffect, useMemo, useState } from "react";
// import AppContext from "../../../../context/AppContext";
// import { PL } from "../../../../API";
import {
  Box,
  useMantineTheme,
  // useMantineTheme,
  // Progress,
  // SegmentedControl,
  // Text,
} from "@mantine/core";
import {
  // MRT_ColumnDef,
  // type MRT_Cell,
  // type MRT_Column,
  // type MRT_Row,
  // type MRT_TableInstance,
  // type MRT_AggregationFn,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import { useMemo } from "react";
// import WidgetFrame from "../../WidgetFrame";

export default function DataTable({
  data,
  schema,
  cols,
  updateDashboardFilter,
}: any) {
  const theme = useMantineTheme();
  const columns = useMemo(() => {
    return cols.map((fieldName: string) => {
      const fieldObject = schema[fieldName];
      const columnObject: any = {
        accessorKey: fieldObject?.field,
        header: fieldObject?.title,
        enableGlobalFilter: fieldObject?.enableGlobalFilter ?? true,
      };

      if (fieldObject?.formatFunction) {
        columnObject.Cell = ({ renderedCellValue }: any) => (
          <>{fieldObject.formatFunction(renderedCellValue)}</>
        );
      }

      return columnObject;
    });
  }, [cols, schema]);

  const identifierColumn = useMemo(() => {
    //@ts-ignore
    return (
      Object.values(schema).find((field: any) => field.identifier) ?? {
        field: "id",
        title: "ID",
      }
    );
  }, [schema]);

  const table = useMantineReactTable({
    columns,
    data,
    enableColumnResizing: false,
    enableGrouping: true,
    enableStickyHeader: true,
    enableColumnDragging: false,
    enableStickyFooter: true,
    groupedColumnMode: false,
    enablePagination: false,
    enablePinning: true,
    enableColumnActions: false,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    mantineTableBodyRowProps: ({ row }) => ({
      //@ts-ignore
      onClick: () =>
        updateDashboardFilter(
          //@ts-ignore
          identifierColumn.field, //@ts-ignore
          row.original[identifierColumn.field]
        ),
      sx: { cursor: "pointer" },
    }),
    mantineTableBodyCellProps: { sx: { textWrap: "wrap" } },
    initialState: {
      density: "xs",
      //@ts-ignore
      expanded: false,
      grouping: [],
      //   pagination: { pageIndex: 0, pageSize: 100 },
      sorting: [],
      columnPinning: {},
    },
    state: {
      //@ts-ignore
      //   expanded: false,
      columnVisibility: {
        //@ts-ignore
        // ...visibleFields[view],
        // engagementName: !(expanded === 0),
      },
    },
    mantineToolbarAlertBannerBadgeProps: { color: "blue", variant: "outline" },
    mantineTableContainerProps: { sx: {} },
    mantineTableProps: { style: { height: "100%" } },
    mantineTableHeadRowProps: {
      style: {
        boxShadow: `4px 0 ${theme.colorScheme === "dark" ? "8" : "4"}px ${
          theme.colors[theme.primaryColor][9]
        }`,
      },
    },
    mantinePaperProps: {
      style: {
        border: "none",
        boxShadow: "none",
        maxHeight: "100%",
        height: "100%",
        display: "grid",
        gridTemplateRows: "1fr 0px ",
      },
    },
  });

  if (data.length === 0) return null;
  return (
    <Box
      style={{
        height: "100%",
        minHeight: "100%",
        maxHeight: "100%",
      }}
    >
      <MantineReactTable table={table} />
    </Box>
  );
}
