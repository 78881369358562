import { useSystemPreferencesScreen } from "./useSystemPreferencesScreen";
import CustomPaper from "../common/CustomPaper";
import {
  Grid,
  Switch,
  Text,
  Title,
  Accordion,
  useMantineTheme,
  Box,
} from "@mantine/core";
import AppearanceSection from "./AppearanceSection";

export default function SystemPreferences(props: any) {
  const { modules, onSwitchChange, dataFieldsObject, columnFilterFunction } =
    useSystemPreferencesScreen();
  const theme = useMantineTheme();

  return (
    <Box pt={"0.5em"}>
      <Title order={1} mb={"md"} color={theme.colors[theme.primaryColor][9]}>
        System Preferences
      </Title>
      <Box ml={"2em"}>
        <Accordion variant="separated" radius="md" defaultValue="appearance">
          <Accordion.Item value="appearance">
            <Accordion.Control>
              <Title order={2} color={theme.colors[theme.primaryColor][9]}>
                Appearance
              </Title>
            </Accordion.Control>
            <Accordion.Panel>
              <AppearanceSection />
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item value="modules">
            <Accordion.Control>
              <Title order={2} color={theme.colors[theme.primaryColor][9]}>
                Modules
              </Title>
            </Accordion.Control>
            <Accordion.Panel>
              <Grid ml={"md"}>
                {Object.keys(modules).map((moduleName: string) => {
                  const { active, dataSource, integrationData } =
                    modules[moduleName];
                  // const fields = dataFieldsObject[moduleName]
                  //   ? dataFieldsObject[moduleName].columns.map(
                  //       (columnObject: any) => {
                  //         return {
                  //           value: columnObject.field,
                  //           label: columnObject.title,
                  //         };
                  //       }
                  //     )
                  //   : [];
                  return (
                    <Grid.Col sm={12} key={moduleName}>
                      <CustomPaper ml={"md"} key={moduleName}>
                        <Grid>
                          <Grid.Col sm={4}>
                            <Title order={3} color={active ? "" : "dimmed"}>
                              {moduleName}
                            </Title>
                          </Grid.Col>
                          <Grid.Col sm={4}>
                            <Switch
                              label="Active"
                              checked={active}
                              onChange={(e) =>
                                onSwitchChange({
                                  moduleName,
                                  newValue: e.target.checked,
                                })
                              }
                            />
                          </Grid.Col>
                          {active && (
                            <Grid.Col sm={4}>
                              <Text color={"dimmed"}>Data Source</Text>
                              <Text>
                                {dataSource === "integration"
                                  ? integrationData.system
                                  : "Input Database"}
                              </Text>
                            </Grid.Col>
                          )}
                          {/* {active && (
                          <Grid.Col sm={12}>
                            <MultiSelect
                              data={fields}
                              label="Active Fields"
                              value={fields.map((field: any) => field.value)}
                              onChange={(e) => console.log(e)}
                            />
                          </Grid.Col>
                        )} */}
                        </Grid>
                      </CustomPaper>
                    </Grid.Col>
                  );
                })}
              </Grid>
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      </Box>
    </Box>
  );
}
