import React, { useContext, useEffect, useMemo, useState } from "react";
import AppContext from "../../../../context/AppContext";

import dayjs, { Dayjs } from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import advancedFormat from "dayjs/plugin/advancedFormat";
import weekday from "dayjs/plugin/weekday";
import utc from "dayjs/plugin/utc";
import useLoadingStatus from "../../../../hooks/useLoadingStatus";
import { Invoice, Project, ProjectRole } from "../../../../API";
import ColumnChart from "../ColumnChart";
import { LoadingOverlay, NativeSelect, useMantineTheme } from "@mantine/core";
import { nFormatter } from "../../functions/formatFunctions";
import WidgetFrame from "../../WidgetFrame";
import { createFilterFunction } from "../../functions/createFilterFunction";

// Extend dayjs with the required plugins
dayjs.extend(isBetween);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(utc);

const chartProps = {
  dataLabels: true,
  comparisonFieldString: "None",
  legend: true,
  values: ["forecast", "actual"],
  yAxisWidth: 60,
  dataLabelFontSize: 1,
  yAxisFontSize: 1,
  xAxisFontSize: 1,
  chartVariant: "Standard",
  rowKeys: [["forecast"], ["actual"]],
  colKeys: [],
  includeYAxis: true,
  includeXAxis: true,
  rows: ["name"],
  cols: [],
  schema: {
    name: {
      title: "Unit",
      field: "name",
      formOptions: {
        required: false,
      },
    },
    forecast: {
      title: "Forecast",
      field: "forecast",
      formOptions: {
        required: false,
      },
    },
    actual: {
      title: "Actual",
      field: "actual",
      formOptions: {
        required: false,
      },
    },
  },
  yAxisMax: "",
  yAxisMin: "",
  grid: true,
  disableChartInteractions: false,
  disableTooltip: false,
  disableAnimations: false,
};

type Frequency = "Weekly" | "Monthly";

const formatDate = (date: Dayjs, unit: Frequency) => {
  return unit === "Weekly"
    ? `${date.format("YYYY")} ${date.week()}`
    : date.format("YYYY-MM");
};

function getDates(
  startDate: Dayjs | string,
  endDate: Dayjs | string,
  frequency: Frequency
): Dayjs[] {
  let current = dayjs(startDate).utc();
  const end = dayjs(endDate).utc();
  let dates: Dayjs[] = [];

  if (frequency === "Weekly") {
    // Find the first Monday on or after the start date
    if (current.weekday() !== 1) {
      current = current.add(1, "week").weekday(1);
    }

    // Collect all Mondays until the end date
    while (current.isBefore(end) || current.isSame(end, "day")) {
      dates.push(current);
      current = current.add(1, "week");
    }
  } else if (frequency === "Monthly") {
    // Collect the last date of each month until the end date
    while (current.isBefore(end) || current.isSame(end, "day")) {
      dates.push(current.endOf("month"));
      current = current.add(1, "month").startOf("month");
    }
  } else if (frequency === "At End") {
    dates.push(end.endOf("month"));
  }

  //   const formatedEnd = formatDate(end, frequency);
  //   if (!dates.includes(formatedEnd)) {
  //     dates.push(formatedEnd);
  //   }

  return dates;
}

export function RevenueForecast({
  dashboardFilterObjectList,
  updateDashboardFilter,
  globalFilteredData,
  comparisonGlobalFilteredData,
  comparisonFieldString,
  startDate,
  endDate,
}: any) {
  const theme = useMantineTheme();
  const [visualStartDate, setVisualStartDate] = useState<Dayjs>(
    dayjs().startOf("year")
  );
  const [visualEndDate, setVisualEndDate] = useState<Dayjs>(
    dayjs().endOf("year")
  );

  const [view, setView] = useState<Frequency>("Monthly");
  const [visualDates, setVisualDates] = useState<Dayjs[]>([]);

  useEffect(() => {
    const newVisualDates = getDates(visualStartDate, visualEndDate, view);
    setVisualDates(newVisualDates);
  }, [visualStartDate, visualEndDate, view]);

  const {
    dataObject: { projects = [] },
    currentProgrammes,
  } = useContext(AppContext);

  const loading = useLoadingStatus(["projects"]);

  const localFilterFunction = useMemo(
    () =>
      createFilterFunction(
        [dashboardFilterObjectList],
        "startDate",
        startDate,
        endDate
      ),
    [dashboardFilterObjectList, startDate, endDate]
  );

  const filteredProjects = useMemo(() => {
    return projects
      .filter((project: any) =>
        currentProgrammes.includes(project.programmeProjectsId)
      )
      .filter(localFilterFunction);
  }, [projects, localFilterFunction, currentProgrammes]);

  //   if (loading) return null;

  //   const testProject = projects.find(
  //     (project: any) => project.id === "7a61d620-513d-4f5f-be95-573310a4e359"
  //   );

  const graphValuesObject = useMemo(() => {
    return filteredProjects.reduce((object: any, project: any) => {
      const {
        startDate: projectStartDate,
        endDate: projectEndDate,
        projectRoles: { items: projectRoles = [] },
        statements: { items: statements = [] },
      } = project;

      const invoices = statements.reduce((array: any, statement: any) => {
        const {
          invoices: { items: invoices },
        } = statement;
        array = array.concat(invoices);
        return array;
      }, []);

      const forecastValuesObject = projectRoles.reduce(
        (obj: any, projectRole: ProjectRole) => {
          //@ts-ignore
          const {
            billingFrequency = "Weekly",
            startDate,
            endDate,
          } = projectRole;

          if (billingFrequency && startDate && endDate) {
            //@ts-ignore
            const dates = getDates(startDate, endDate, billingFrequency);
            const totalValue =
              (projectRole.unitRate ?? 0) * (projectRole.plannedUnits ?? 0);

            const unitValue = totalValue / dates.length;

            for (let date of dates) {
              const formatedDate = formatDate(dayjs(date), view);
              if (!obj[formatedDate]) {
                obj[formatedDate] = {
                  name: formatedDate,
                  forecast: 0,
                  actual: 0,
                };
              }
              obj[formatedDate].forecast += unitValue;
            }
          }

          return obj;
        },
        object
      );

      return invoices.reduce((obj: any, invoice: Invoice) => {
        if (invoice.invoiceDate) {
          const date = formatDate(dayjs(invoice.invoiceDate), view);
          if (!obj[date]) obj[date] = { name: date, forecast: 0, actual: 0 };
          obj[date].actual += invoice.amount ?? 0;
        }
        return obj;
      }, forecastValuesObject);
    }, {});
  }, [filteredProjects, view]);

  //@ts-ignore
  const graphValues: { [key: string]: string | number }[] = Object.values(
    graphValuesObject
  ).sort((a: any, b: any) =>
    a.name === b.name ? 0 : a.name > b.name ? 1 : -1
  );

  return (
    <WidgetFrame
      title={"Revenue Forecast Timeline"}
      controls={
        <NativeSelect
          data={[
            { label: "Monthly", value: "Monthly" },
            { label: "Weekly", value: "Weekly" },
          ]}
          value={view} //@ts-ignore
          onChange={(e) => setView(e.target.value)}
          styles={{
            input: {
              backgroundColor:
                theme.colorScheme === "dark" ? theme.colors.dark[8] : undefined,
            },
          }}
        />
      }
    >
      <LoadingOverlay visible={loading} overlayOpacity={0} />
      {!loading && ( //@ts-ignore
        <ColumnChart
          formatFunction={(value: any) => `£${nFormatter(value, 1)}`}
          updateDashboardFilter={() => null}
          data={graphValues}
          {...chartProps}
        />
      )}
    </WidgetFrame>
  );
}
