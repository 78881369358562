import { useContext, useMemo } from "react";
import AppContext from "../../../../context/AppContext";
import {
  ResponsiveContainer,
  Funnel,
  LabelList,
  FunnelChart,
  Tooltip,
} from "recharts";
import { LoadingOverlay, useMantineTheme } from "@mantine/core";
import WidgetFrame from "../../WidgetFrame";
import { createFilterFunction } from "../../functions/createFilterFunction";
import useLoadingStatus from "../../../../hooks/useLoadingStatus";
import Placeholder from "../../Placeholder";

export default function PipelineFunnel(props: any) {
  const {
    dashboardFilterObjectList,
    updateDashboardFilter,
    globalFilteredData,
  } = props;
  const { dataObject, currentAccount } = useContext(AppContext);
  const { pipelines = [] } = dataObject;

  const localFilterFunction = useMemo(
    () => createFilterFunction([dashboardFilterObjectList]),
    [dashboardFilterObjectList]
  );
  const loading = useLoadingStatus(["pipelines"]);

  const theme = useMantineTheme();

  const colors = theme.colors[theme.primaryColor];

  const filteredPipelines = useMemo(
    () =>
      globalFilteredData
        .filter((pipeline: any) => {
          return (
            pipeline.stage &&
            !["Closed Won", "Closed Lost"].includes(pipeline.stageName) &&
            (currentAccount === "All" ||
              pipeline.accountPipelinesId === currentAccount)
          );
        })
        .filter(localFilterFunction)
        .sort((a: any, b: any) => {
          return a.stage.order - b.stage.order;
        })
        .reduce((object: any, pipeline: any, index: number) => {
          if (!object[pipeline.stageName])
            object[pipeline.stageName] = {
              name: pipeline.stageName,
              count: 0,
            };
          object[pipeline.stageName].count += 1;
          return object;
        }, {}),
    [currentAccount, globalFilteredData, localFilterFunction]
  );

  return (
    // <ResponsiveContainer>
    <WidgetFrame title={`Sales Funnel`}>
      {loading && <LoadingOverlay visible={true} overlayOpacity={0} />}
      {!loading && (
        <>
          {Object.values(filteredPipelines).length === 0 && (
            <Placeholder placeholderText="No Data" />
          )}
          {Object.values(filteredPipelines).length > 0 && (
            <ResponsiveContainer width="100%" height="99%">
              <FunnelChart width={730} height={250}>
                <Tooltip />
                <Funnel
                  dataKey="count"
                  data={Object.values(filteredPipelines).map(
                    (p: any, i: number) => {
                      p.fill = colors[i + 3];
                      return p;
                    }
                  )}
                  isAnimationActive
                >
                  <LabelList
                    position="center"
                    fill={theme.colorScheme === "dark" ? "white" : "black"}
                    stroke="none"
                    dataKey="name"
                  />
                </Funnel>
              </FunnelChart>
            </ResponsiveContainer>
          )}
        </>
      )}
    </WidgetFrame>
  );
}
