import React, { useMemo } from "react";
import dayjs from "dayjs";
import styles from "./task-list-table.module.css";
import { Task } from "../../types/public-types";
import { useMantineTheme } from "@mantine/core";

// const localeDateStringCache = {};
// const toLocaleDateStringFactory =
//   (locale: string) =>
//   (date: Date, dateTimeOptions: Intl.DateTimeFormatOptions) => {
//     const key = date.toString();
//     let lds = localeDateStringCache[key];
//     if (!lds) {
//       lds = date.toLocaleDateString(locale, dateTimeOptions);
//       localeDateStringCache[key] = lds;
//     }
//     return lds;
//   };
// const dateTimeOptions: Intl.DateTimeFormatOptions = {
//   weekday: "short",
//   year: "numeric",
//   month: "long",
//   day: "numeric",
// };

export const TaskListTableDefault: React.FC<{
  rowHeight: number;
  rowWidth: string;
  fontFamily: string;
  fontSize: string;
  locale: string;
  tasks: Task[];
  selectedTaskId: string;
  setSelectedTask: (taskId: string) => void;
  onExpanderClick: (task: Task) => void;
}> = ({
  rowHeight,
  rowWidth,
  tasks,
  fontFamily,
  fontSize,
  locale,
  onExpanderClick,
}) => {
  const theme = useMantineTheme();
  // const toLocaleDateString = useMemo(
  //   () => toLocaleDateStringFactory(locale),
  //   [locale]
  // );

  return (
    <div
      className={styles.taskListWrapper}
      style={{
        fontFamily: fontFamily,
        fontSize: fontSize,
      }}
    >
      {tasks.map((t, i) => {
        let expanderSymbol = "";
        if (t.hideChildren === false) {
          expanderSymbol = "▼";
        } else if (t.hideChildren === true) {
          expanderSymbol = "▶";
        }

        return (
          <div
            className={styles.taskListTableRow}
            // style={{  }}
            key={`${t.id}row`}
            style={{
              height: rowHeight,
              fill:
                theme.colorScheme === "dark"
                  ? i % 2 === 1
                    ? "#ebeff230"
                    : "transparent"
                  : "transparent",
            }}
          >
            <div
              className={styles.taskListCell}
              style={{
                // minWidth: rowWidth,
                // maxWidth: rowWidth,
                minWidth: 300,
                maxWidth: 300,
              }}
              title={t.name}
            >
              <div className={styles.taskListNameWrapper}>
                <div
                  className={
                    expanderSymbol
                      ? styles.taskListExpander
                      : styles.taskListEmptyExpander
                  }
                  onClick={() => onExpanderClick(t)}
                >
                  {expanderSymbol}
                </div>
                <div>{t.name}</div>
              </div>
            </div>
            <div
              className={styles.taskListCell}
              style={{
                // minWidth: rowWidth,
                // maxWidth: rowWidth,
                minWidth: 90,
                maxWidth: 90,
              }}
            >
              &nbsp;{dayjs(t.start).format("DD/MM/YYYY")}
            </div>
            <div
              className={styles.taskListCell}
              style={{
                // minWidth: rowWidth,
                // maxWidth: rowWidth,
                minWidth: 90,
                maxWidth: 90,
              }}
            >
              &nbsp;{dayjs(t.end).format("DD/MM/YYYY")}
            </div>
          </div>
        );
      })}
    </div>
  );
};
