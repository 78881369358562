import { useMantineTheme } from "@mantine/core";
import {
  PieChart as RePieChart,
  Pie,
  Legend,
  Tooltip,
  ResponsiveContainer,
  Cell,
} from "recharts";
import { CustomTooltip, getChartColors } from "./ChartUtilities";
import { useCallback, useMemo } from "react";

const ragColors = {
  Green: "green",
  Amber: "orange",
  Red: "red",
  G: "green",
  A: "orange",
  R: "red",
  L: "green",
  M: "yellow",
  H: "orange",
  V: "red",
};

export default function PieChart(props: any) {
  const {
    data,
    dataLabels,
    values,
    chartVariant,
    legend,
    updateDashboardFilter,
    rows,
    formatFunction,
    comparisonFieldString,
    innerRadius,
    labelPosition,
    labelType,
    disableChartInteractions,
    disableTooltip,
    disableAnimations,
  } = props;
  const theme = useMantineTheme();
  const COLORS = getChartColors(theme);

  const cleanedData = useMemo(
    () =>
      data.map((row: any) => {
        if (!row.name) row.name = "No Data";
        return row;
      }),
    [data]
  );

  const renderCustomizedLabel = useCallback(
    (input: any) => {
      const {
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        percent,
        value,
        x,
        y,
        fill,
      } = input;
      const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
      const RADIAN = Math.PI / 180;
      const newx = cx + radius * Math.cos(-midAngle * RADIAN);
      const newy = cy + radius * Math.sin(-midAngle * RADIAN);

      const val =
        labelType === "Value"
          ? formatFunction(value)
          : `${(percent * 100).toFixed(0)}%`;

      if (labelPosition === "Outside")
        return (
          <text
            x={x}
            y={y}
            fill={fill}
            textAnchor={x > cx ? "start" : "end"}
            dominantBaseline="central"
          >
            {val}
          </text>
        );

      return (
        <text
          x={newx}
          y={newy}
          fill="white"
          textAnchor={x > cx ? "start" : "end"}
          dominantBaseline="central"
        >
          {val}
        </text>
      );
    },
    [labelPosition, labelType, formatFunction]
  );

  return (
    <ResponsiveContainer width="100%" height="99%">
      <RePieChart>
        <Pie
          dataKey={values[0]}
          isAnimationActive={!disableAnimations}
          animationBegin={0}
          animationDuration={500}
          data={cleanedData}
          cx="50%"
          cy="50%"
          // outerRadius={80}
          innerRadius={`${innerRadius}%`}
          fill={theme.colors[theme.primaryColor][7]}
          legendType="rect"
          labelLine={false}
          // labelLine={labelPosition === "Outside"}
          label={dataLabels ? renderCustomizedLabel : false}
          onClick={(input) => {
            if (disableChartInteractions) return;
            updateDashboardFilter(rows[0], input.name);
          }}
          cursor={disableChartInteractions ? undefined : "pointer"}
          // startAngle={90}
          // endAngle={-270}
        >
          {data.map((entry: any, index: any) => {
            const fill =
              chartVariant === "RAG"
                ? //@ts-ignore
                  ragColors[entry.name[0]]
                  ? //@ts-ignore
                    theme.colors[ragColors[entry.name[0]]][8]
                  : COLORS[index % COLORS.length]
                : COLORS[index % COLORS.length];
            return <Cell key={`cell-${index}`} fill={fill} />;
          })}
        </Pie>
        {legend && <Legend verticalAlign="top" />}
        {!disableTooltip && (
          <Tooltip
            cursor={false}
            content={
              <CustomTooltip
                formatFunction={formatFunction}
                comparisonFieldString={comparisonFieldString}
              />
            }
          />
        )}
      </RePieChart>
    </ResponsiveContainer>
  );
}
