import { createStyles } from "@mantine/core";

export default createStyles((theme) => ({
  card: {
    transition: `transform 150ms ease,
      box-shadow 150ms ease`,

    "&:hover": {
      transform: "scale(1.01)",
      boxShadow: "var(--mantine-shadow-md)",
    },
  },

  title: {
    fontWeight: "bold",
  },
}));
