import {
  Center,
  Group,
  Paper,
  SimpleGrid,
  Text,
  createStyles,
} from "@mantine/core";
import NewRisksChart from "./NewRisksChart";

const useStyles = createStyles((theme) => ({
  root: {
    //padding: theme.spacing.xl * 1.5,
  },

  value: {
    fontSize: 24,
    fontWeight: 700,
    lineHeight: 1,
  },

  diff: {
    lineHeight: 1,
    display: "flex",
    alignItems: "center",
  },

  icon: {
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[3]
        : theme.colors.gray[4],
  },

  title: {
    fontWeight: 700,
    textTransform: "uppercase",
  },

  paper: {
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[6]
        : theme.colors.gray[0],
  },
}));

export default function RiskQuickStats(props: any) {
  const { risks } = props;
  const filterFunction = (risk: any) => {
    return risk.status === "Open";
  };

  const filteredRisks = risks.filter(filterFunction);

  const { classes } = useStyles();
  return (
    <div className={classes.root}>
      <SimpleGrid
        cols={4}
        breakpoints={[
          { maxWidth: "md", cols: 2 },
          { maxWidth: "xs", cols: 1 },
        ]}
      >
        <Paper withBorder p="md" radius="md" className={classes.paper}>
          <Group sx={{ position: "absolute" }}>
            <Text size="xs" color="dimmed" className={classes.title}>
              BRAG
            </Text>
          </Group>
          <Center>
            <NewRisksChart data={filteredRisks} />
          </Center>
        </Paper>
      </SimpleGrid>
    </div>
  );
}
