import { Button, Checkbox, Group } from "@mantine/core";
import { useCallback, useContext, useState } from "react";
import { updateIntegration } from "./systems/updateIntegration";
import { notifications } from "@mantine/notifications";
import { IconCheck, IconX } from "@tabler/icons-react";
import AppContext from "../../context/AppContext";
import { dataKeyToModule as moduleToModuleLookup } from "../../context/references";

export default function DeleteIntegration({
  selectedRecord,
  onModalCloseFunction,
}: any) {
  const [deleteIntegration, setDeleteIntegration] = useState(false);
  const [deleteData, setDeleteData] = useState(false);
  const { dataObject, setDataObject, queuedModules } = useContext(AppContext);

  const deleteFunction = useCallback(async () => {
    onModalCloseFunction();
    notifications.show({
      id: selectedRecord.id,
      title: `Deleting Integration`,
      message: `Updating ${selectedRecord.name}`,
      loading: true,
      withBorder: true,
      autoClose: false,
    });

    try {
      const response = await updateIntegration(selectedRecord, {
        action: "delete",
        deleteAllRecords: deleteData,
        deleteIntegration,
      });

      const dataObjectCopy = structuredClone(dataObject);

      if (deleteIntegration) {
        dataObjectCopy.integrations = dataObjectCopy.integrations.filter(
          (integration: any) => {
            return integration.id !== selectedRecord.id;
          }
        );
        delete dataObjectCopy[selectedRecord.module];
        setDataObject(dataObjectCopy);
      }

      queuedModules.current = queuedModules.current.filter(
        (module: string) =>
          !module.includes(moduleToModuleLookup[selectedRecord.module])
      );

      notifications.update({
        id: selectedRecord.id,
        title: "Success",
        message: `${selectedRecord.name} successfully deleted`,
        icon: <IconCheck size="1rem" />,
        loading: false,
        withBorder: true,
      });
    } catch (e) {
      console.log({ e });
      notifications.update({
        id: selectedRecord.id,
        title: "Error",
        message: `Error deleting ${selectedRecord.name}`,
        icon: <IconX size="1rem" />,
        loading: false,
        withBorder: true,
        color: "red",
        autoClose: false,
      });
    }
  }, [
    dataObject,
    deleteData,
    onModalCloseFunction,
    queuedModules,
    selectedRecord,
    setDataObject,
    deleteIntegration,
  ]);

  return (
    <div>
      <Checkbox
        checked={deleteIntegration}
        onChange={(e) => setDeleteIntegration(e.target.checked)}
        label="Delete integration"
        mb={"md"}
      />
      <Checkbox
        checked={deleteData}
        onChange={(e) => setDeleteData(e.target.checked)}
        label="Delete all associated data"
        mb={"md"}
      />
      <Group mt="md">
        <Button color="red" onClick={() => deleteFunction()}>
          Delete
        </Button>
        <Button variant="outline">Cancel</Button>
      </Group>
    </div>
  );
}
