function formatDateString(dateString: string) {
  if (!dateString) return "";
  return new Date(dateString).toLocaleDateString("en-GB");
}

function formatDateTimeString(dateString: string) {
  if (!dateString) return "";
  const date = new Date(dateString);
  return `${date.toLocaleDateString("en-GB")} ${date.toLocaleTimeString(
    "en-GB"
  )}`;
}

function formatCurrency(number: any, currency = "USD", decimals = 2) {
  if (number === null || number === undefined) return "";
  let currencyString;
  switch (currency) {
    case "EUR":
      currencyString = "EUR";
      break;
    case "GBP":
      currencyString = "GBP";
      break;
    case "CHF":
      currencyString = "CHF";
      break;
    default:
      currencyString = "USD";
      break;
  }
  return number.toLocaleString("en-US", {
    style: "currency",
    currency: currencyString,
    minimumFractionDigits: decimals,
  });
}

function nFormatter(num: any, digits: any) {
  let negative = num < 0;
  if (negative) {
    num = num * -1;
  }
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (negative ? "-" : "") +
        (num / item.value).toFixed(digits).replace(rx, "$1") +
        item.symbol
    : "0";
}

function formatPercentage(value: number, decimals: number = 0) {
  return `${
    Math.round(value * Math.pow(10, decimals + 2)) / Math.pow(10, decimals)
  }%`;
}

function formatDateToYYYYMM(date: Date) {
  // Get the year, month, and day components of the date
  const year = date.getFullYear();
  let month = date.getMonth() + 1; // Months are zero-based

  // Add leading zero if the month is a single digit
  //@ts-ignore
  month = month < 10 ? "0" + month : month;

  // Concatenate the components in the desired format
  const formattedDate = `${year}-${month}`;

  return formattedDate;
}

export {
  formatDateString,
  formatCurrency,
  nFormatter,
  formatDateTimeString,
  formatPercentage,
  formatDateToYYYYMM,
};
