import { useState, useContext, useRef } from "react";
import AppContext from "../../context/AppContext";
import { API } from "aws-amplify";
import { csvUpload } from "../../graphql/queries";
import useFetchData from "../../hooks/useFetchData";
import { createAccessStrings } from "./createAccessStrings";
import { notifications } from "@mantine/notifications";
import { IconCheck, IconX } from "@tabler/icons-react";
import { dataKeyLookup } from "../../context/references";
//@ts-ignore
const Papa = window.Papa;

const templates: { value: string; label: string }[] = [
  { value: "", label: "" },
  { value: "Projects", label: "Projects" },
  { value: "People", label: "People" },
  { value: "Risks", label: "Risks" },
  { value: "CSATs", label: "CSATs" },
  { value: "Weekly Updates", label: "Weekly Updates" },
  { value: "Accounts", label: "Accounts" },
  { value: "Verticals", label: "Verticals" },
  { value: "Programmes", label: "Programmes" },
  { value: "Integrations", label: "Integrations" },
  { value: "Statements of Work", label: "Statements of Work" },
  { value: "Stages", label: "Stages" },
  { value: "Invoices", label: "Invoices" },
  { value: "Employee Satisfaction", label: "Employee Satisfaction" },
  { value: "Pipelines", label: "Pipelines" },
  { value: "Project Allocations", label: "Project Allocations" },
  { value: "Project Roles", label: "Project Roles" },
  { value: "Regions", label: "Regions" },
  { value: "Role Types", label: "Role Types" },
  { value: "Tasks", label: "Tasks" },
  { value: "Time Sheets", label: "Time Sheets" },
  { value: "Deliverables", label: "Deliverables" },
  { value: "MRRs", label: "MRRs" },
  { value: "Costs", label: "Costs" },
  { value: "PLs", label: "Profit & Loss" },
];

// const dataKeyLookup = {
//   MRRs: "mrrs",
//   People: "people",
//   Projects: "projects",
//   Costs: "costs",
//   PLs: "pls",
//   Risks: "risks",
//   CSATs: "csats",
//   "Weekly Updates": "weeklyUpdates",
//   Accounts: "accounts",
//   Verticals: "verticals",
//   Programmes: "programmes",
//   "Statements of Work": "statements",
//   Tenants: "tenants",
//   Stages: "stages",
//   Invoices: "invoices",
//   "Employee Satisfaction": "employeeSatisfaction",
//   Pipelines: "pipelines",
//   "Project Allocations": "projectAllocations",
//   "Project Roles": "projectRoles",
//   Regions: "regions",
//   "Role Types": "roleTypes",
//   Tasks: "tasks",
//   "Time Sheets": "timesheets",
//   Deliverables: "deliverables",
// };

function chunkArray(arr: any[], chunkSize: number) {
  const chunks = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    chunks.push(arr.slice(i, i + chunkSize));
  }
  return chunks;
}

export default function useDataUpload() {
  const [dataSource, setDataSource] = useState<string>(templates[0].label);
  const [entryMethod, setEntryMethod] = useState<string>("");
  const [file, setFile] = useState<File | null>(null);
  const { dataObject, currentTenant, setDataObject, queuedModules } =
    useContext(AppContext);

  const currentUpload = useRef(crypto.randomUUID());

  useFetchData([]);

  const uploadButtonClicked = () => {
    // setLoading(true);

    notifications.show({
      id: currentUpload.current,
      title: `Processing Data Import`,
      message: `Processing Data Import`,
      loading: true,
      withBorder: true,
      autoClose: false,
    });

    try {
      const reader = new FileReader();

      if (file) {
        reader.onload = function (event) {
          if (event && event.target) {
            const contents = event.target.result;
            parseCSV(contents);
          }
        };

        reader.readAsText(file);
      }
    } catch (e) {
      notifications.update({
        id: currentUpload.current,
        title: "Error",
        message: `Error deleting processing upload`,
        icon: <IconX size="1rem" />,
        loading: false,
        withBorder: true,
        color: "red",
        autoClose: false,
      });
      currentUpload.current = crypto.randomUUID();
    }
  };

  function parseCSV(csvText: any) {
    Papa.parse(csvText, {
      header: true,
      delimiter: ",",
      skipEmptyLines: true,
      complete: async function (results: any) {
        const { data } = results;

        const processedData = data.map((row: any) => {
          if (!row.id) {
            row.id = crypto.randomUUID();
          }

          if (["PLs", "MRRs", "Costs"].includes(dataSource)) {
            const accessStringObject = createAccessStrings({
              //@ts-ignore
              dataKey: dataKeyLookup[dataSource],
              formValues: row,
              tenant: currentTenant,
              dataObject,
            });

            row = { ...row, ...accessStringObject };
          }

          delete row[""];
          return row;
        });

        const chunks = chunkArray(processedData, 1000);

        await API.graphql({
          query: csvUpload,
          authMode: "AMAZON_COGNITO_USER_POOLS",
          variables: {
            input: JSON.stringify({
              dataSource,
              data: chunks[0],
              entryMethod,
              tenantAccessString: currentTenant,
            }),
          },
        });

        for (var i = 1; i < chunks.length; i++) {
          await API.graphql({
            query: csvUpload,
            authMode: "AMAZON_COGNITO_USER_POOLS",
            variables: {
              input: JSON.stringify({
                dataSource,
                data: chunks[i],
                entryMethod: "Append",
                tenantAccessString: currentTenant,
              }),
            },
          });
        }

        const dataObjectCopy = structuredClone(dataObject);

        //@ts-ignore
        delete dataObjectCopy[dataKeyLookup[dataSource]];

        setDataObject(dataObjectCopy);

        queuedModules.current = queuedModules.current.filter(
          (module: string) => !module.includes(dataSource)
        );

        notifications.update({
          id: currentUpload.current,
          title: "Success",
          message: `Your upload was successfully processed`,
          icon: <IconCheck size="1rem" />,
          loading: false,
          withBorder: true,
        });
        currentUpload.current = crypto.randomUUID();

        setFile(null);
        setDataSource("");
        setEntryMethod("");

        // setLoading(false);
      },
    });
  }

  return {
    file,
    setFile,
    dataSource,
    setDataSource,
    entryMethod,
    setEntryMethod,
    templates,
    uploadButtonClicked,
  };
}

/*
notifications.show({
              id: selectedRecord.id,
              title: `Deleting Integration`,
              message: `Updating ${selectedRecord.name}`,
              loading: true,
              withBorder: true,
              autoClose: false,
            });

notifications.update({
                id: selectedRecord.id,
                title: "Success",
                message: `${selectedRecord.name} successfully deleted`,
                icon: <IconCheck size="1rem" />,
                loading: false,
                withBorder: true,
              });
*/
