import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  DefaultProps,
  MantineNumberSize,
  useComponentDefaultProps,
} from "@mantine/styles";
import { useDebouncedValue, useUncontrolled } from "@mantine/hooks";
import { RenderList, RenderListStylesNames } from "./RenderList/RenderList";
// import { SelectScrollArea } from '../Select/SelectScrollArea/SelectScrollArea';
import { SelectScrollArea } from "./SelectScrollArea/SelectScrollArea";
import { SegmentedControl } from "./SegmentedControl";
import { DefaultItem } from "./DefaultItem/DefaultItem";
import { autoMap } from "./autoMap";
// import { SimpleGrid } from '../SimpleGrid';
import {
  Badge,
  Box,
  Button,
  Center,
  Group,
  NumberInput,
  ScrollArea,
  SimpleGrid,
  Text,
  TextInput,
} from "@mantine/core";
import {
  useSelectionState,
  Selection,
} from "./use-selection-state/use-selection-state";
import {
  TransferListData,
  TransferListItemComponent,
  TransferListItem,
} from "./types";
import { IconAffiliate } from "@tabler/icons-react";

export type TransferListStylesNames = RenderListStylesNames;

export interface TransferListProps
  extends DefaultProps<TransferListStylesNames>,
    Omit<
      React.ComponentPropsWithoutRef<"div">,
      "value" | "onChange" | "placeholder"
    > {
  variant?: string;

  /** Current value */
  value: TransferListData;

  rulesProps: { sourceField: null | string; targetField: null | string };

  sourceFieldsObject: any;

  targetValues: any;

  onValueMappingUpdate: any;

  initialMappingValues: any;

  /** Called when value changes */
  onChange(value: TransferListData): void;

  /** Initial items selection */
  initialSelection?: Selection;

  /** Custom item component */
  itemComponent?: TransferListItemComponent;

  /** Controlled search queries */
  searchValues?: [string, string];

  /** Called when one of the search queries changes */
  onSearch?(value: [string, string]): void;

  /** Search fields placeholder */
  searchPlaceholder?: string | [string, string];

  /** Nothing found message */
  nothingFound?: React.ReactNode | [React.ReactNode, React.ReactNode];

  /** Displayed when a list is empty and there is no search query */
  placeholder?: React.ReactNode | [React.ReactNode, React.ReactNode];

  /** Function to filter search results */
  filter?(query: string, item: TransferListItem): boolean;

  /** Lists titles */
  titles?: [string, string];

  /** List items height */
  listHeight?: number;

  /** Change list component, can be used to add custom scrollbars */
  listComponent?: any;

  /** Breakpoint at which list will collapse to single column layout */
  breakpoint?: MantineNumberSize;

  /** Key of theme.radius or any valid CSS value to set border-radius, theme.defaultRadius by default */
  radius?: MantineNumberSize;

  /** Whether to hide the transfer all button */
  showTransferAll?: boolean;

  /** Limit amount of items showed at a time */
  limit?: number;

  /** Change icon used for the transfer selected control */
  transferIcon?: React.FunctionComponent<{ reversed: boolean }>;

  /** Change icon used for the transfer all control */
  transferAllIcon?: React.FunctionComponent<{ reversed: boolean }>;

  /** Whether to transfer only items matching {@link filter} when clicking the transfer all control */
  transferAllMatchingFilter?: boolean;
}

export function defaultFilter(query: string, item: TransferListItem) {
  return item.label
    .toString()
    .toLowerCase()
    .trim()
    .includes(query.toLowerCase().trim());
}

const defaultProps: Partial<TransferListProps> = {
  itemComponent: DefaultItem,
  filter: defaultFilter,
  //@ts-ignore
  titles: [null, null],
  placeholder: [null, null],
  listHeight: 150,
  listComponent: SelectScrollArea,
  showTransferAll: true,
  limit: Infinity,
  transferAllMatchingFilter: false,
};

export const CreateRules = forwardRef<HTMLDivElement, TransferListProps>(
  (props, ref) => {
    const {
      value,
      onChange,
      itemComponent,
      searchPlaceholder,
      searchValues,
      onSearch,
      filter,
      nothingFound,
      placeholder,
      titles,
      initialSelection,
      listHeight,
      listComponent,
      showTransferAll,
      breakpoint,
      radius,
      classNames,
      styles,
      limit,
      unstyled,
      transferIcon,
      transferAllIcon,
      variant,
      transferAllMatchingFilter,
      rulesProps,
      sourceFieldsObject,
      targetValues,
      onValueMappingUpdate,
      initialMappingValues,
      ...others
    } = useComponentDefaultProps("TransferList", defaultProps, props);
    const [threshold, setThreshold] = useState<number>(0);
    const [selectedTargetValue, setSelectedTargetValue] = useState<any>(null);

    useEffect(() => {
      if (targetValues && targetValues[0]) {
        setSelectedTargetValue(targetValues[0].value);
      }
    }, [targetValues]);

    const [mappingObject, setMappingObject] = useState({
      unmapped: [],
    });

    useEffect(() => {
      const newMappingObject = { unmapped: [] };

      //@ts-ignore
      sourceFieldsObject[rulesProps.sourceField].forEach((value) => {
        if (initialMappingValues[value]) {
          //@ts-ignore
          if (!newMappingObject[initialMappingValues[value]]) {
            //@ts-ignore
            newMappingObject[initialMappingValues[value]] = [];
          } //@ts-ignore
          newMappingObject[initialMappingValues[value]].push({
            value: value.toString(),
            label: value.toString(),
          });
        } else {
          //@ts-ignore
          newMappingObject.unmapped.push({
            value: value.toString(),
            label: value.toString(),
          });
        }
      });
      setMappingObject(newMappingObject);
    }, [initialMappingValues, sourceFieldsObject, rulesProps.sourceField]);

    const [selection, handlers] = useSelectionState(initialSelection);
    const [search, handleSearch] = useUncontrolled({
      value: searchValues,
      defaultValue: ["", ""],
      finalValue: ["", ""],
      onChange: onSearch,
    });

    const [targetSearch, setTargetSearch] = useState("");
    const [debounced] = useDebouncedValue(targetSearch, 300);

    const handleMoveAllOld = (listIndex: 0 | 1) => {
      const items: TransferListData = Array(2) as any;
      const moveToIndex = listIndex === 0 ? 1 : 0;

      if (transferAllMatchingFilter) {
        const query = search[listIndex];
        const shownItems = value[listIndex]
          //@ts-ignore
          .filter((item) => filter(query, item))
          .slice(0, limit);
        const hiddenItems = value[listIndex].filter(
          //@ts-ignore
          (item) => !filter(query, item)
        );

        items[listIndex] = hiddenItems;
        items[moveToIndex] = [...value[moveToIndex], ...shownItems];
      } else {
        items[listIndex] = [];
        items[moveToIndex] = [...value[moveToIndex], ...value[listIndex]];
      }

      onChange(items);
      handlers.deselectAll(listIndex);
    };

    const handleMoveAll = (sourceKey: string, destinationKey: string) => {
      const indexList = sourceKey === "unmapped" ? 0 : 1;
      const mappingObjectCopy: any = structuredClone(mappingObject);

      if (!mappingObjectCopy[destinationKey])
        mappingObjectCopy[destinationKey] = [];

      if (transferAllMatchingFilter) {
        const query = search[indexList];
        const shownItems = mappingObjectCopy[sourceKey]
          //@ts-ignore
          .filter((item) => filter(query, item))
          .slice(0, limit);
        const hiddenItems = mappingObjectCopy[sourceKey].filter(
          //@ts-ignore
          (item) => !filter(query, item)
        );

        mappingObjectCopy[sourceKey] = hiddenItems;
        mappingObjectCopy[destinationKey] = [
          ...shownItems,
          ...mappingObjectCopy[destinationKey],
        ];
      } else {
        mappingObjectCopy[sourceKey] = [];
        mappingObjectCopy[destinationKey] = [
          ...mappingObjectCopy[sourceKey],
          ...mappingObjectCopy[destinationKey],
        ];
      }

      setMappingObject(mappingObjectCopy);

      // onChange(items);
      handlers.deselectAll(indexList);
    };

    const handleMoveOld = (listIndex: 0 | 1) => {
      const moveToIndex = listIndex === 0 ? 1 : 0;
      const items: TransferListData = Array(2) as any;
      const transferData = value[listIndex].reduce(
        (acc, item) => {
          if (!selection[listIndex].includes(item.value)) {
            //@ts-ignore
            acc.filtered.push(item);
          } else {
            //@ts-ignore
            acc.current.push(item);
          }
          return acc;
        },
        { filtered: [], current: [] }
      );
      items[listIndex] = transferData.filtered;
      items[moveToIndex] = [...transferData.current, ...value[moveToIndex]];
      onChange(items);
      handlers.deselectAll(listIndex);
    };

    const handleMove = (sourceKey: string, destinationKey: string) => {
      const indexList = sourceKey === "unmapped" ? 0 : 1;
      const mappingObjectCopy: any = structuredClone(mappingObject);

      for (let i = 0; i < mappingObjectCopy[sourceKey].length; i++) {
        const valueObject = mappingObjectCopy[sourceKey][i];
        if (selection[indexList].includes(valueObject.value)) {
          const removedElement = mappingObjectCopy[sourceKey].splice(i, 1)[0];
          if (!mappingObjectCopy[destinationKey]) {
            mappingObjectCopy[destinationKey] = [];
          }
          mappingObjectCopy[destinationKey].unshift(removedElement);
          i--;
        }
      }

      setMappingObject(mappingObjectCopy);
      handlers.deselectAll(indexList);
    };

    const breakpoints = breakpoint ? [{ maxWidth: breakpoint, cols: 1 }] : [];
    const sharedListProps = {
      itemComponent,
      listComponent,
      transferIcon,
      transferAllIcon,
      filter,
      height: listHeight,
      showTransferAll,
      classNames,
      styles,
      limit,
      radius,
    };

    const unmappedList = mappingObject.unmapped ?? [];

    const saveValueMapping = useCallback(() => {
      const savedValueMappingObject = {};
      for (const key in mappingObject) {
        if (key !== "unmapped") {
          //@ts-ignore
          mappingObject[key].forEach((value: any) => {
            //@ts-ignore
            savedValueMappingObject[value.value] = key;
          });
        }
      }

      onValueMappingUpdate(rulesProps.targetField, savedValueMappingObject);
    }, [mappingObject]);

    const targetOptionsList = useMemo(() => {
      return targetValues
        .filter(
          (obj: any) =>
            !debounced ||
            obj.label?.toLowerCase().includes(debounced.toLowerCase())
        )
        .sort((a: any, b: any) => {
          return a.label === b.label ? 0 : a.label > b.label ? 1 : -1;
        });
    }, [debounced, targetValues]);

    const targetOptionsListValues = useMemo(() => {
      return targetOptionsList.map((obj: any) => obj.value);
    }, [targetOptionsList]);

    const targetOptions = useMemo(() => {
      return targetOptionsList.map((value: any, index: number) => {
        return {
          value: value.value,
          label: (
            <Center key={value.label + index}>
              <Box mr={10}>{value.label}</Box>
              {
                //@ts-ignore
                (mappingObject[value.value] ?? []).length > 0 && (
                  <Badge>
                    {
                      //@ts-ignore
                      mappingObject[value.value].length
                    }
                  </Badge>
                )
              }
            </Center>
          ),
        };
      });
    }, [mappingObject, targetOptionsList]);

    const control = useMemo(() => {
      return (
        <SegmentedControl
          data={targetOptions}
          value={
            targetOptionsListValues.includes(selectedTargetValue)
              ? selectedTargetValue
              : undefined
          }
          onChange={(value) => setSelectedTargetValue(value)}
          fullWidth
          orientation="vertical"
        />
      );
    }, [selectedTargetValue, targetOptions, targetOptionsListValues]);

    return (
      <>
        <SimpleGrid
          cols={2}
          spacing="xl"
          breakpoints={breakpoints}
          ref={ref}
          unstyled={unstyled}
          {...others}
        >
          <RenderList
            {...sharedListProps}
            data={unmappedList}
            selection={selection[0]}
            onSelect={(val) => handlers.select(0, val)}
            onMoveAll={() => handleMoveAll("unmapped", selectedTargetValue)}
            onMove={() => handleMove("unmapped", selectedTargetValue)}
            title={
              <Group position="apart">
                <Group>
                  <Text>Unmapped</Text>
                  <Badge>{unmappedList.length}</Badge>
                </Group>
                <NumberInput
                  value={threshold}
                  onChange={(number) =>
                    setThreshold(number === "" ? 0 : number)
                  }
                />
                <Button
                  size="xs"
                  leftIcon={<IconAffiliate />}
                  onClick={() =>
                    autoMap(
                      mappingObject,
                      targetValues,
                      setMappingObject,
                      threshold
                    )
                  }
                  variant="subtle"
                >
                  Auto Map
                </Button>
              </Group>
            }
            height={400}
            placeholder={
              Array.isArray(placeholder) ? placeholder[0] : placeholder
            }
            //@ts-ignore
            searchPlaceholder={
              Array.isArray(searchPlaceholder)
                ? searchPlaceholder[0]
                : searchPlaceholder
            }
            nothingFound={
              Array.isArray(nothingFound) ? nothingFound[0] : nothingFound
            }
            query={search[0]}
            onSearch={(query) => handleSearch([query, search[1]])}
            unstyled={unstyled}
            //@ts-ignore
            variant={variant}
            //@ts-ignore
            transferAllMatchingFilter={transferAllMatchingFilter}
          />

          <Box>
            <RenderList
              {...sharedListProps}
              //@ts-ignore
              data={mappingObject[selectedTargetValue] ?? []}
              selection={selection[1]}
              onSelect={(val) => handlers.select(1, val)}
              onMoveAll={() => handleMoveAll(selectedTargetValue, "unmapped")}
              onMove={() => handleMove(selectedTargetValue, "unmapped")}
              //@ts-ignore
              title={
                <Group>
                  <Text>
                    {targetValues.filter(
                      (value: any) => value.value === selectedTargetValue
                    )[0]?.label ?? ""}
                  </Text>
                  <Badge>
                    {
                      //@ts-ignore
                      (mappingObject[selectedTargetValue] ?? []).length
                    }
                  </Badge>
                </Group>
              }
              placeholder={
                Array.isArray(placeholder) ? placeholder[1] : placeholder
              }
              //@ts-ignore
              searchPlaceholder={
                Array.isArray(searchPlaceholder)
                  ? searchPlaceholder[1]
                  : searchPlaceholder
              }
              nothingFound={
                Array.isArray(nothingFound) ? nothingFound[1] : nothingFound
              }
              query={search[1]}
              onSearch={(query) => handleSearch([search[0], query])}
              reversed
              unstyled={unstyled}
              //@ts-ignore
              variant={variant}
              //@ts-ignore
              transferAllMatchingFilter={transferAllMatchingFilter}
            />
            <Text mt={"md"} mb={"xs"}>
              Available Options
            </Text>
            <TextInput
              mb={"xs"}
              placeholder="Search"
              value={targetSearch}
              onChange={(e) => setTargetSearch(e.target.value)}
            />
            <ScrollArea.Autosize mah={250}>{control}</ScrollArea.Autosize>
          </Box>
        </SimpleGrid>
        <Group position="right" mt={"md"}>
          <Button onClick={saveValueMapping}>Save</Button>
        </Group>
      </>
    );
  }
);

// TransferList.displayName = "@mantine/core/TransferList";
