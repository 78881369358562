import { useContext } from "react";
import CrudScreen from "../common/CrudScreen";
import AppContext from "../../context/AppContext";
// import { taskSchema as schema } from "../../context/dataSchema";
import {
  createTask as createQuery,
  updateTask as updateQuery,
  deleteTask as deleteQuery,
} from "../../graphql/mutations";
import { Badge } from "@mantine/core";

export default function Automations() {
  // const { dataObject } = useContext(AppContext);
  // const { tasks: data = [] } = dataObject;

  const data = [
    {
      event: "When risk added to Project",
      action: "Notify Project Manager",
      status: "Active",
    },
  ];

  const schema = {
    event: {
      title: "Event",
      field: "event",
      formatFunction: (value: any) => <Badge color="gray">{value}</Badge>,
      formOptions: { required: false },
    },
    action: {
      title: "Action",
      field: "action",
      formatFunction: (value: any) => <Badge color="gray">{value}</Badge>,
      formOptions: { required: false },
    },
    status: {
      title: "Status",
      field: "status",
      formatFunction: (value: any) => <Badge color="green">{value}</Badge>,
      formOptions: { required: false },
    },
  };

  const screenProps = {
    data,
    schema,
    title: "Task Genie",
    quickStats: null,
    tableColumns: ["event", "action", "status"],
    filterFields: [],
    recordName: "Automation",
    pagePath: "task-genie",
    dataObjectKey: "tasks",
    formProps: {
      createQuery,
      updateQuery,
      deleteQuery,
      dataObjectKey: "tasks",
    },
    modulesToLoad: ["Verticals", "People", "Projects", "Tasks"],
  };
  return <CrudScreen {...screenProps} />;
}
