import {
  formatDateString,
  formatCurrency,
} from "../../components/common/functions/formatFunctions";
import {
  getRiskBrag,
  getBragColor,
} from "../../components/common/functions/riskFunctions";
import { Badge } from "@mantine/core";
import common from "./commonSchema";
import { ISchema } from "../SchemaTypes";
import { listPersonRisks } from "../../graphql/queries";
import {
  createPersonRisks,
  deletePersonRisks,
  updatePersonRisks,
} from "../../graphql/mutations";

const risksSchema: ISchema = {
  type: {
    title: "Type",
    field: "type",
    accessorFunction: (value: any) => {
      if (value.verticalRisksId) return "Vertical";
      if (value.accountRisksId) return "Account";
      if (value.programmeRisksId) return "Programme";
      if (value.projectRisksId) return "Project";
      return "";
    },
    formOptions: { required: false },
    excludeFromForm: true,
    type: "belongsTo",
  },

  typeName: {
    title: "Type Name",
    field: "typeName",
    accessorFunction: (value: any) => {
      if (value.verticalRisksId) return value.vertical?.name;
      if (value.accountRisksId) return value.account?.name;
      if (value.programmeRisksId) return value.programme?.name;
      if (value.projectRisksId) return value.project?.name;
      return "";
    },
    formOptions: { required: false },
    excludeFromForm: true,
    type: "belongsTo",
  },

  programmeName: {
    title: "Programme Name",
    field: "programmeName",
    accessorFunction: (value: any) => {
      return value.programme?.name;
    },
    // formatFunction: (value: any) => value.name,
    formOptions: { required: false },
    excludeFromForm: true,
    type: "belongsTo",
  },

  vertical: {
    title: "Vertical Name",
    field: "vertical",
    accessorFunction: (value: any) => {
      return value.vertical?.name ?? "Not Assigned";
    },
    formatFunction: (value: any) => value,
    formOptions: { required: false },
    excludeFromForm: true,
    type: "belongsTo",
  },
  verticalRisksId: {
    title: "Vertical",
    field: "verticalRisksId",
    isKey: true,
    formOptions: {
      required: false,
      options: {
        dataSource: "verticals",
        addBlank: true,
        accessorFunction: (dataSource: any) =>
          dataSource?.map((row: any) => {
            return { value: row.id, label: row.name };
          }) ?? [],
      },
      order: 1,
    },
    type: "select",
  },

  account: {
    title: "Account Name",
    field: "account",
    accessorFunction: (value: any) => {
      return value.account?.name;
    },
    formatFunction: (value: any) => value,
    formOptions: { required: false },
    excludeFromForm: true,
    type: "belongsTo",
  },
  accountRisksId: {
    title: "Account",
    field: "accountRisksId",
    isKey: true,
    formOptions: {
      required: false,
      options: {
        dataSource: "accounts",
        addBlank: true,
        accessorFunction: (dataSource: any) =>
          dataSource?.map((row: any) => {
            return { value: row.id, label: row?.name };
          }) ?? [],
      },
      order: 1,
    },
    type: "select",
  },

  programme: {
    title: "Programme Name",
    field: "programme",
    accessorFunction: (value: any) => {
      return value.programme?.name;
    },
    formatFunction: (value: any) => value,
    formOptions: { required: false },
    excludeFromForm: true,
    type: "belongsTo",
  },
  programmeRisksId: {
    title: "Programme",
    field: "programmeRisksId",
    isKey: true,
    formOptions: {
      required: false,
      options: {
        dataSource: "programmes",
        addBlank: true,
        accessorFunction: (dataSource: any) =>
          dataSource?.map((row: any) => {
            return { value: row.id, label: row.name };
          }) ?? [],
      },
      order: 1,
    },
    type: "select",
  },

  project: {
    title: "Project Name",
    field: "project",
    accessorFunction: (value: any) => {
      return value.project?.name;
    },
    formatFunction: (value: any) => value,
    formOptions: { required: false },
    excludeFromForm: true,
    type: "belongsTo",
  },
  projectRisksId: {
    title: "Project ID",
    field: "projectRisksId",
    isKey: true,
    formOptions: {
      required: false,
      options: {
        dataSource: "projects",
        addBlank: true,
        accessorFunction: (dataSource: any) =>
          dataSource?.map((row: any) => {
            return { value: row.id, label: row.name };
          }) ?? [],
      },
      order: 1,
    },
    type: "select",
  },

  name: { title: "Name", field: "name", formOptions: { required: true } },
  description: {
    title: "Description",
    field: "description",
    type: "textarea",
    formOptions: { required: false },
  },
  // riskOwner: {
  //   title: "Risk Owner",
  //   field: "riskOwner",
  //   formOptions: { required: false },
  // },
  // actionOwner: {
  //   title: "Action Owner",
  //   field: "actionOwner",
  //   formOptions: { required: false },
  // },
  status: {
    title: "Status",
    field: "status",
    type: "select",
    formOptions: {
      required: false,
      defaultValue: "Open",
      options: { list: ["Open", "Closed"] },
    },
    filterOptions: { defaultValue: "Open" },
  },
  impactDate: {
    title: "Impact Date",
    field: "impactDate",
    type: "date",
    formatFunction: formatDateString,
    formOptions: { required: false },
  },
  cause: {
    title: "Cause",
    field: "cause",
    type: "textarea",
    formOptions: { required: false },
  },
  effect: {
    title: "Effect",
    field: "effect",
    type: "textarea",
    formOptions: { required: false },
  },
  impactDescription: {
    title: "Impact Description",
    field: "impactDescription",
    type: "textarea",
    formOptions: { required: false },
  },
  impactType: {
    title: "RAID Classification",
    field: "impactType",
    formOptions: { required: false },
  },
  riskResponse: {
    title: "Risk Response",
    field: "riskResponse",
    type: "textarea",
    formOptions: { required: false },
  },
  actionMitigation: {
    title: "Action Mitigation",
    field: "actionMitigation",
    type: "textarea",
    formOptions: { required: false },
  },
  dateRaised: {
    title: "Date Raised",
    field: "dateRaised",
    type: "date",
    formatFunction: formatDateString,
    formOptions: { required: false, defaultValue: new Date() },
  },
  dateOfNextReview: {
    title: "Date of Next Review",
    field: "dateOfNextReview",
    type: "date",
    formatFunction: formatDateString,
    formOptions: { required: false },
  },
  targetResolutionDate: {
    title: "Target Resolution Date",
    field: "targetResolutionDate",
    formatFunction: formatDateString,
    type: "date",
    formOptions: { required: false },
  },
  probability: {
    title: "Probability",
    field: "probability",
    type: "select",
    formOptions: {
      required: false,
      defaultValue: "1",
      options: {
        list: [
          "1",
          "2",
          "3",
          "4",
          "5",
          // { label: "1", value: "1" },
          // { label: "2", value: "2" },
          // { label: "3", value: "3" },
          // { label: "4", value: "4" },
          // { label: "5", value: "5" },
        ],
      },
    },
  },
  impact: {
    title: "Impact",
    field: "impact",
    type: "select",
    formOptions: {
      required: false,
      defaultValue: "1",
      options: {
        list: [
          "1",
          "2",
          "3",
          "4",
          "5",
          // { label: "1", value: "1" },
          // { label: "2", value: "2" },
          // { label: "3", value: "3" },
          // { label: "4", value: "4" },
          // { label: "5", value: "5" },
        ],
      },
    },
    // type: "number",
    // formOptions: { required: false, min: 1, max: 5, step: 1, defaultValue: 1 },
  },
  estimatedImpactCost: {
    title: "Estimated Impact Cost",
    field: "estimatedImpactCost",
    formatFunction: formatCurrency,
    type: "currency",
    formOptions: { required: false },
  },
  escalatedToPortfolio: {
    title: "Escalated To Portfolio",
    field: "escalatedToPortfolio",
    type: "boolean",

    formOptions: { required: false, defaultValue: false },
  },
  detailedUpdate: {
    title: "Detailed Update",
    field: "detailedUpdate",
    formOptions: { required: false },
  },
  riskFactor: {
    title: "Risk Factor",
    field: "riskFactor",
    accessorFunction: (row: any) => row.impact * row.probability,
    formOptions: { required: false },
    excludeFromForm: true,
  },
  brag: {
    title: "BRAG",
    field: "brag",
    accessorFunction: (row: any) => getRiskBrag(row),
    formatFunction: (value: any) => (
      <Badge color={getBragColor(value)}>{value}</Badge>
    ),
    disableSorting: true,
    formOptions: { required: false },
    excludeFromForm: true,
    type: "belongsTo",
  },

  // riskOwner: {
  //   title: "Risk Owner",
  //   field: "riskOwner",
  //   formOptions: {
  //     required: false,
  //     options: {
  //       dataSource: "people",
  //       addBlank: true,
  //       accessorFunction: (dataSource: any) =>
  //         dataSource.map((row: any) => {
  //           return { value: row.id, label: `${row.firstName} ${row.lastName}` };
  //         }),
  //     },
  //     order: 2,
  //   },
  //   type: "select",
  // },

  riskOwners: {
    title: "Risk Owners",
    field: "riskOwners",
    formOptions: {
      required: false,
      childIdName: "personID",
      parentIdName: "riskID",
      listQuery: listPersonRisks,
      updateQuery: updatePersonRisks,
      createQuery: createPersonRisks,
      deleteQuery: deletePersonRisks,
      listKey: "person",
      options: {
        dataSource: "people",
        addBlank: true,
        accessorFunction: (dataSource: any) =>
          dataSource
            ?.sort((a: any, b: any) => {
              return `${a.firstName} ${a.lastName}` ===
                `${b.firstName} ${b.lastName}`
                ? 0
                : `${a.firstName} ${a.lastName}` >
                  `${b.firstName} ${b.lastName}`
                ? 1
                : -1;
            })
            ?.map((row: any) => {
              return {
                value: row.id,
                label: `${row.firstName} ${row.lastName}`,
              };
            }) ?? [],
      },
      order: 2,
    },

    type: "manyToMany",
    accessorFunction: (row: any) => "",
  },

  // actionOwner: {
  //   title: "Action Owner",
  //   field: "actionOwner",
  //   formOptions: {
  //     required: false,
  //     options: {
  //       dataSource: "people",
  //       addBlank: true,
  //       accessorFunction: (dataSource: any) =>
  //         dataSource.map((row: any) => {
  //           return { value: row.id, label: `${row.firstName} ${row.lastName}` };
  //         }),
  //     },
  //     order: 2,
  //   },
  //   type: "select",
  // },

  ...common,
};

export default risksSchema;
