import { useContext } from "react";
import CrudScreen from "../common/CrudScreen";
import AppContext from "../../context/AppContext";
import { weeklyUpdatesSchema as schema } from "../../context/dataSchema";
import {
  createWeeklyUpdate as createQuery,
  updateWeeklyUpdate as updateQuery,
  deleteWeeklyUpdate as deleteQuery,
} from "../../graphql/mutations";

export default function WeeklyUpdates(props: any) {
  const { dataObject, currentProgrammes, currentProgramme } =
    useContext(AppContext);
  const { weeklyUpdates: records = [] } = dataObject;

  const permittedProgrammeList =
    currentProgramme === "All" ? currentProgrammes : [currentProgramme];

  const globalFilteredData = records.filter((row: any) => {
    return (
      currentProgramme === "All" ||
      permittedProgrammeList.includes(row.programmeWeeklyUpdateId)
    );
  });

  const screenProps = {
    data: globalFilteredData,
    schema,
    title: "Weekly Updates",
    quickStats: null,
    widerTitle: true,
    tableColumns: ["score", "comments", "date"],
    filterFields: [],
    recordName: "Weekly Update",
    pagePath: "weekly-updates",
    formProps: {
      createQuery,
      updateQuery,
      deleteQuery,
    },
    dataObjectKey: "weeklyUpdates",
    modulesToLoad: ["Verticals", "Accounts", "Programmes", "Weekly Updates"],
  };
  return <CrudScreen {...screenProps} />;
}
