import {
  Button,
  FileInput,
  NativeSelect,
  Title,
  Divider,
  useMantineTheme,
  Box,
} from "@mantine/core";
import { Upload } from "tabler-icons-react";
import useDataUpload from "./useDataUpload";

export default function DataUpload(props: any) {
  const {
    file,
    setFile,
    dataSource,
    setDataSource,
    templates,
    uploadButtonClicked,
    entryMethod,
    setEntryMethod,
  } = useDataUpload();
  const theme = useMantineTheme();
  return (
    <Box pt={"0.5em"}>
      <Title order={1} mb={"md"} color={theme.colors[theme.primaryColor][9]}>
        Data Upload
      </Title>
      <Box sx={{ width: "66%" }} ml={"2em"}>
        <NativeSelect
          mb={"md"}
          value={dataSource}
          label="Select data source"
          onChange={(e) => setDataSource(e.target.value)}
          data={templates}
        />
        <NativeSelect
          mb={"md"}
          value={entryMethod}
          label="Append or replace"
          onChange={(e) => setEntryMethod(e.target.value)}
          data={["", "Append", "Replace"]}
        />
        <FileInput
          mb={"md"}
          label="Data Template To Upload"
          placeholder="Your file"
          icon={<Upload />}
          accept="text/csv"
          value={file}
          onChange={setFile}
        />
        <Divider mb={"md"} />
        <Button
          onClick={uploadButtonClicked}
          disabled={!file || !dataSource || !entryMethod}
        >
          Upload
        </Button>
      </Box>
    </Box>
  );
}
