export const worldGeo = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 3,
        labelrank: 2,
        sovereignt: "Indonesia",
        sov_a3: "IDN",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Indonesia",
        adm0_a3: "IDN",
        geou_dif: 0,
        geounit: "Indonesia",
        gu_a3: "IDN",
        su_dif: 0,
        subunit: "Indonesia",
        su_a3: "IDN",
        brk_diff: 0,
        name: "Indonesia",
        name_long: "Indonesia",
        brk_a3: "IDN",
        brk_name: "Indonesia",
        brk_group: null,
        abbrev: "Indo.",
        postal: "INDO",
        formal_en: "Republic of Indonesia",
        formal_fr: null,
        name_ciawf: "Indonesia",
        note_adm0: null,
        note_brk: null,
        name_sort: "Indonesia",
        name_alt: null,
        mapcolor7: 6,
        mapcolor8: 6,
        mapcolor9: 6,
        mapcolor13: 11,
        pop_est: 270625568,
        pop_rank: 17,
        pop_year: 2019,
        gdp_md: 1119190,
        gdp_year: 2019,
        economy: "4. Emerging region: MIKT",
        income_grp: "4. Lower middle income",
        fips_10: "ID",
        iso_a2: "ID",
        iso_a2_eh: "ID",
        iso_a3: "IDN",
        iso_a3_eh: "IDN",
        iso_n3: "360",
        iso_n3_eh: "360",
        un_a3: "360",
        wb_a2: "ID",
        wb_a3: "IDN",
        woe_id: 23424846,
        woe_id_eh: 23424846,
        woe_note: "Exact WOE match as country",
        adm0_iso: "IDN",
        adm0_diff: null,
        adm0_tlc: "IDN",
        adm0_a3_us: "IDN",
        adm0_a3_fr: "IDN",
        adm0_a3_ru: "IDN",
        adm0_a3_es: "IDN",
        adm0_a3_cn: "IDN",
        adm0_a3_tw: "IDN",
        adm0_a3_in: "IDN",
        adm0_a3_np: "IDN",
        adm0_a3_pk: "IDN",
        adm0_a3_de: "IDN",
        adm0_a3_gb: "IDN",
        adm0_a3_br: "IDN",
        adm0_a3_il: "IDN",
        adm0_a3_ps: "IDN",
        adm0_a3_sa: "IDN",
        adm0_a3_eg: "IDN",
        adm0_a3_ma: "IDN",
        adm0_a3_pt: "IDN",
        adm0_a3_ar: "IDN",
        adm0_a3_jp: "IDN",
        adm0_a3_ko: "IDN",
        adm0_a3_vn: "IDN",
        adm0_a3_tr: "IDN",
        adm0_a3_id: "IDN",
        adm0_a3_pl: "IDN",
        adm0_a3_gr: "IDN",
        adm0_a3_it: "IDN",
        adm0_a3_nl: "IDN",
        adm0_a3_se: "IDN",
        adm0_a3_bd: "IDN",
        adm0_a3_ua: "IDN",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "South-Eastern Asia",
        region_wb: "East Asia & Pacific",
        name_len: 9,
        long_len: 9,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 1.7,
        max_label: 6.7,
        label_x: 101.892949,
        label_y: -0.954404,
        ne_id: 1159320845,
        wikidataid: "Q252",
        name_ar: "إندونيسيا",
        name_bn: "ইন্দোনেশিয়া",
        name_de: "Indonesien",
        name_en: "Indonesia",
        name_es: "Indonesia",
        name_fa: "اندونزی",
        name_fr: "Indonésie",
        name_el: "Ινδονησία",
        name_he: "אינדונזיה",
        name_hi: "इंडोनेशिया",
        name_hu: "Indonézia",
        name_id: "Indonesia",
        name_it: "Indonesia",
        name_ja: "インドネシア",
        name_ko: "인도네시아",
        name_nl: "Indonesië",
        name_pl: "Indonezja",
        name_pt: "Indonésia",
        name_ru: "Индонезия",
        name_sv: "Indonesien",
        name_tr: "Endonezya",
        name_uk: "Індонезія",
        name_ur: "انڈونیشیا",
        name_vi: "Indonesia",
        name_zh: "印度尼西亚",
        name_zht: "印度尼西亞",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "IDN.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [97.48154296875003, 1.465087890625],
              [97.69833984375003, 1.183740234374994],
              [97.78642578124999, 1.145898437499994],
              [97.90322265625002, 1.018261718749997],
              [97.93193359374999, 0.973925781249989],
              [97.90205078125001, 0.884228515624997],
              [97.87646484375, 0.628320312499994],
              [97.82041015625003, 0.564453125],
              [97.68398437500002, 0.59609375],
              [97.68251953125002, 0.641064453124997],
              [97.60390625000002, 0.833886718749994],
              [97.46123046874999, 0.94140625],
              [97.40537109375003, 0.946972656249997],
              [97.36884765625001, 1.056933593749989],
              [97.296875, 1.187353515624991],
              [97.07919921875003, 1.425488281249997],
              [97.24423828125003, 1.423632812499989],
              [97.32441406250001, 1.481640625],
              [97.3427734375, 1.527929687499991],
              [97.35595703125, 1.539746093749997],
              [97.48154296875003, 1.465087890625],
            ],
          ],
          [
            [
              [99.16386718749999, -1.777929687500006],
              [99.07177734375, -1.783496093750003],
              [98.87431640624999, -1.663671875],
              [98.82773437500003, -1.609960937500006],
              [98.81630859375002, -1.538281250000011],
              [98.626953125, -1.261328125],
              [98.60175781250001, -1.197851562500006],
              [98.67607421874999, -0.970507812500003],
              [98.86904296875002, -0.915625],
              [98.9326171875, -0.954003906250009],
              [98.95478515625001, -1.05625],
              [99.06503906250003, -1.24072265625],
              [99.10146484375002, -1.340136718750003],
              [99.12890625, -1.384179687500009],
              [99.14042968749999, -1.41845703125],
              [99.13066406249999, -1.4423828125],
              [99.21035156250002, -1.559277343750011],
              [99.26728515625001, -1.627734375],
              [99.271484375, -1.738476562500011],
              [99.16386718749999, -1.777929687500006],
            ],
          ],
          [
            [
              [116.64082031250001, -8.613867187500006],
              [116.51425781250003, -8.820996093750011],
              [116.559375, -8.854394531250009],
              [116.58652343750003, -8.886132812500009],
              [116.37724609374999, -8.929003906250003],
              [116.28984374999999, -8.906152343750009],
              [116.23935546875003, -8.912109375],
              [116.02675781250002, -8.873144531250006],
              [115.87460937500003, -8.825585937500009],
              [115.85732421875002, -8.787890625],
              [115.86933593750001, -8.742773437500006],
              [115.91445312500002, -8.758007812500011],
              [116.03164062500002, -8.765234375],
              [116.07646484374999, -8.744921875],
              [116.07773437500003, -8.611328125],
              [116.06113281250003, -8.437402343750009],
              [116.21982421875003, -8.295214843750003],
              [116.30429687500003, -8.237988281250011],
              [116.40156250000001, -8.204199218750006],
              [116.64697265625, -8.28271484375],
              [116.6875, -8.304101562500009],
              [116.71894531250001, -8.336035156250006],
              [116.73408203125001, -8.386914062500011],
              [116.64082031250001, -8.613867187500006],
            ],
          ],
          [
            [
              [115.44785156250003, -8.155175781250009],
              [115.54941406250003, -8.208300781250003],
              [115.69091796875, -8.363574218750003],
              [115.70429687500001, -8.407128906250009],
              [115.66142578124999, -8.4482421875],
              [115.55996093750002, -8.51416015625],
              [115.33378906249999, -8.61572265625],
              [115.29501953125003, -8.663671875],
              [115.24716796875003, -8.757519531250011],
              [115.23613281249999, -8.797558593750011],
              [115.22021484375, -8.819531250000011],
              [115.19423828125002, -8.83544921875],
              [115.14492187500002, -8.849023437500009],
              [115.09150390625001, -8.829394531250003],
              [115.13974609375003, -8.768945312500009],
              [115.1416015625, -8.696875],
              [115.10566406250001, -8.629492187500006],
              [115.05507812500002, -8.573046875],
              [114.95205078125002, -8.496386718750003],
              [114.84208984374999, -8.428515625],
              [114.73134765625002, -8.393945312500009],
              [114.61318359375002, -8.378320312500009],
              [114.57089843750003, -8.345410156250011],
              [114.50175781249999, -8.260839843750006],
              [114.47890625000002, -8.214746093750009],
              [114.46757812499999, -8.166308593750003],
              [114.47529296875001, -8.119433593750003],
              [114.50429687500002, -8.116601562500009],
              [114.62001953125002, -8.127734375],
              [114.8330078125, -8.1826171875],
              [114.9384765625, -8.187109375],
              [114.99814453125003, -8.174414062500006],
              [115.15400390625001, -8.065722656250003],
              [115.19101562500003, -8.067480468750006],
              [115.34023437500002, -8.115429687500011],
              [115.44785156250003, -8.155175781250009],
            ],
          ],
          [
            [
              [106.04570312499999, -1.66943359375],
              [106.080078125, -1.73828125],
              [106.12714843750001, -1.800195312500009],
              [106.16171875000003, -1.866992187500003],
              [106.20878906249999, -2.188671875000011],
              [106.36591796875001, -2.46484375],
              [106.81845703125003, -2.573339843750006],
              [106.74433593750001, -2.61796875],
              [106.70664062500003, -2.658007812500003],
              [106.67880859375003, -2.704003906250009],
              [106.61201171875001, -2.8955078125],
              [106.61855468750002, -2.936132812500006],
              [106.65761718750002, -3.001171875000011],
              [106.66718750000001, -3.07177734375],
              [106.61054687500001, -3.071386718750006],
              [106.54677734375002, -3.055566406250009],
              [106.49609375, -3.029003906250011],
              [106.44873046875, -2.994238281250006],
              [106.39736328125002, -2.966601562500003],
              [106.34160156249999, -2.94873046875],
              [106.25009765625003, -2.89404296875],
              [106.12587890625002, -2.855371093750009],
              [105.99873046875001, -2.824902343750011],
              [105.93720703125001, -2.743554687500009],
              [105.90800781249999, -2.643261718750011],
              [105.93906250000003, -2.493457031250003],
              [105.90761718750002, -2.451953125],
              [105.86240234375003, -2.415429687500009],
              [105.80683593750001, -2.307421875],
              [105.78583984375001, -2.181347656250011],
              [105.70527343750001, -2.132617187500003],
              [105.59902343750002, -2.103125],
              [105.552734375, -2.079003906250009],
              [105.34287109375003, -2.125097656250006],
              [105.29257812500003, -2.1142578125],
              [105.24765625000003, -2.079394531250003],
              [105.13339843750003, -2.042578125],
              [105.1376953125, -1.97265625],
              [105.19101562500003, -1.916894531250009],
              [105.31621093749999, -1.860546875000011],
              [105.37480468749999, -1.813183593750011],
              [105.38652343749999, -1.75078125],
              [105.3642578125, -1.705078125],
              [105.37314453125003, -1.657324218750006],
              [105.41269531250003, -1.611035156250011],
              [105.45957031250003, -1.57470703125],
              [105.58544921875, -1.526757812500009],
              [105.64042968749999, -1.610449218750006],
              [105.66757812500003, -1.680371093750011],
              [105.70087890625001, -1.731054687500006],
              [105.75449218750003, -1.65869140625],
              [105.72041015625001, -1.533886718750011],
              [105.81611328125001, -1.506054687500011],
              [105.91005859375002, -1.504980468750006],
              [105.98095703125, -1.539160156250006],
              [106.02734375, -1.593164062500009],
              [106.04570312499999, -1.66943359375],
            ],
          ],
          [
            [
              [123.17978515625003, -4.551171875],
              [123.20302734375002, -4.766210937500006],
              [123.19570312500002, -4.82265625],
              [123.13945312499999, -4.739941406250011],
              [123.11923828125003, -4.7234375],
              [123.10380859374999, -4.739941406250011],
              [123.08388671875002, -4.7490234375],
              [123.05517578125, -4.748242187500011],
              [123.01796875000002, -4.831738281250011],
              [123.0146484375, -4.910253906250006],
              [122.98652343750001, -4.963085937500011],
              [122.9716796875, -5.138476562500003],
              [122.98105468750003, -5.185742187500011],
              [123.02460937500001, -5.162402343750003],
              [123.05146484375001, -5.156445312500011],
              [123.14990234375, -5.224023437500009],
              [123.20195312499999, -5.273339843750009],
              [123.18730468749999, -5.3330078125],
              [123.12070312500003, -5.393164062500006],
              [123.04335937500002, -5.419335937500009],
              [122.98574218750002, -5.3935546875],
              [122.96875, -5.40576171875],
              [122.93466796875003, -5.436718750000011],
              [122.90878906250003, -5.477441406250009],
              [122.91621093750001, -5.519335937500003],
              [122.85019531250003, -5.637988281250003],
              [122.81210937500003, -5.671289062500009],
              [122.73310546875001, -5.634960937500011],
              [122.684375, -5.666210937500011],
              [122.64501953125, -5.663378906250003],
              [122.5849609375, -5.544628906250011],
              [122.58642578125, -5.488867187500006],
              [122.64218750000003, -5.42626953125],
              [122.642578125, -5.381152343750003],
              [122.67011718750001, -5.330859375],
              [122.7314453125, -5.261914062500011],
              [122.76650390625002, -5.210156250000011],
              [122.767578125, -5.17724609375],
              [122.79365234375001, -5.052441406250011],
              [122.80380859375003, -5.000097656250006],
              [122.82148437500001, -4.944433593750006],
              [122.84941406249999, -4.83125],
              [122.85332031249999, -4.618359375000011],
              [122.946875, -4.442675781250003],
              [123.03828125000001, -4.394726562500011],
              [123.07460937500002, -4.386914062500011],
              [123.06894531250003, -4.43359375],
              [123.17978515625003, -4.551171875],
            ],
          ],
          [
            [
              [122.64511718750003, -5.269433593750009],
              [122.61933593750001, -5.335839843750009],
              [122.56386718750002, -5.3875],
              [122.51972656250001, -5.391210937500006],
              [122.4736328125, -5.380664062500003],
              [122.39199218750002, -5.33544921875],
              [122.37128906250001, -5.383105468750003],
              [122.30703125000002, -5.380957031250006],
              [122.28310546875002, -5.319531250000011],
              [122.32900390625002, -5.1376953125],
              [122.39628906249999, -5.06982421875],
              [122.39003906250002, -4.99853515625],
              [122.33447265625, -4.846582031250009],
              [122.36894531249999, -4.7671875],
              [122.5244140625, -4.707128906250006],
              [122.65996093749999, -4.633886718750006],
              [122.70195312499999, -4.61865234375],
              [122.73974609375, -4.675],
              [122.75986328125003, -4.933886718750003],
              [122.61406249999999, -5.138671875],
              [122.64511718750003, -5.269433593750009],
            ],
          ],
          [
            [
              [108.31601562500003, 3.689648437499997],
              [108.17958984375002, 3.653076171875],
              [108.10039062499999, 3.704541015624997],
              [108.18613281250003, 3.76796875],
              [108.21640625000003, 3.772167968749997],
              [108.23613281249999, 3.784570312499994],
              [108.24326171875003, 3.810351562499989],
              [108.08847656250003, 3.852099609374989],
              [108.04453125000003, 3.888964843749989],
              [108.00234375000002, 3.982861328124997],
              [108.00351562500003, 4.042578125],
              [108.20195312499999, 4.200488281249989],
              [108.24833984374999, 4.217138671874991],
              [108.25556640625001, 4.151757812499994],
              [108.39287109374999, 3.986181640624991],
              [108.39882812500002, 3.8759765625],
              [108.3935546875, 3.836181640625],
              [108.31601562500003, 3.689648437499997],
            ],
          ],
          [
            [
              [108.20722656250001, -2.99765625],
              [108.19179687500002, -3.10302734375],
              [108.16728515624999, -3.142773437500011],
              [108.08359375000003, -3.194921875],
              [108.05527343750003, -3.226855468750003],
              [107.97714843750003, -3.221777343750006],
              [107.96728515625, -3.166601562500006],
              [107.94111328125001, -3.129296875],
              [107.85820312499999, -3.086328125],
              [107.83662109375001, -3.0966796875],
              [107.82177734375, -3.160742187500006],
              [107.65957031250002, -3.20556640625],
              [107.61445312500001, -3.209375],
              [107.63671875, -3.124804687500003],
              [107.59492187500001, -3.058398437500003],
              [107.59160156249999, -2.9765625],
              [107.58388671875002, -2.940722656250003],
              [107.5634765625, -2.920117187500011],
              [107.60488281250002, -2.863085937500003],
              [107.59814453125, -2.799707031250009],
              [107.6416015625, -2.731542968750006],
              [107.66630859374999, -2.566308593750009],
              [107.83779296875002, -2.5302734375],
              [107.87470703125001, -2.559667968750006],
              [108.07441406250001, -2.596972656250003],
              [108.21513671874999, -2.696972656250011],
              [108.290625, -2.829980468750009],
              [108.20722656250001, -2.99765625],
            ],
          ],
          [
            [
              [135.3830078125, -0.6513671875],
              [135.595703125, -0.6904296875],
              [135.67324218750002, -0.68828125],
              [135.7490234375, -0.732519531250006],
              [135.84121093750002, -0.711621093750011],
              [135.8935546875, -0.725781250000011],
              [136.06875, -0.877734375],
              [136.15468750000002, -0.978320312500003],
              [136.28261718750002, -1.064648437500011],
              [136.37529296875005, -1.094042968750003],
              [136.30537109375, -1.173144531250003],
              [136.16474609375, -1.214746093750009],
              [136.1103515625, -1.216796875],
              [136.00253906250003, -1.169726562500003],
              [135.9150390625, -1.178417968750011],
              [135.83876953125002, -1.119433593750003],
              [135.82558593750002, -1.0283203125],
              [135.7470703125, -0.823046875],
              [135.645703125, -0.881933593750006],
              [135.52382812500002, -0.787304687500011],
              [135.49111328125002, -0.785058593750009],
              [135.4833984375, -0.801074218750003],
              [135.431640625, -0.768847656250003],
              [135.3876953125, -0.704882812500003],
              [135.3830078125, -0.6513671875],
            ],
          ],
          [
            [
              [135.47421875000003, -1.591796875],
              [135.869140625, -1.641992187500009],
              [135.97617187500003, -1.635546875],
              [136.20156250000002, -1.654980468750011],
              [136.3896484375, -1.721582031250009],
              [136.71855468750005, -1.733984375],
              [136.81669921875005, -1.753808593750009],
              [136.892578125, -1.799707031250009],
              [136.70859375000003, -1.837695312500003],
              [136.621875, -1.873046875],
              [136.46083984375002, -1.890429687500003],
              [136.32607421875002, -1.872460937500009],
              [136.228125, -1.893652343750006],
              [136.192578125, -1.859179687500003],
              [136.04921875000002, -1.824121093750009],
              [135.86572265625, -1.752148437500011],
              [135.48759765625005, -1.668359375],
              [135.4697265625, -1.6162109375],
              [135.47421875000003, -1.591796875],
            ],
          ],
          [
            [
              [130.81328125000005, -0.004101562500011],
              [130.9865234375, -0.046582031250011],
              [131.02578125000002, -0.039941406250009],
              [131.27685546875, -0.149804687500009],
              [131.31689453125, -0.204296875000011],
              [131.302734375, -0.241113281250009],
              [131.33974609375002, -0.290332031250003],
              [131.25751953125, -0.36572265625],
              [131.21787109375003, -0.374121093750006],
              [131.177734375, -0.345996093750003],
              [131.09775390625003, -0.330078125],
              [131.00537109375, -0.360742187500009],
              [130.946484375, -0.337597656250011],
              [130.8966796875, -0.268457031250009],
              [130.80839843750005, -0.226464843750009],
              [130.68349609375002, -0.080664062500006],
              [130.62216796875003, -0.0859375],
              [130.63828125000003, -0.14296875],
              [130.69130859375002, -0.180566406250009],
              [130.76132812500003, -0.29140625],
              [130.80156250000005, -0.302148437500009],
              [130.84316406250002, -0.29833984375],
              [130.89921875000005, -0.344433593750011],
              [130.89628906250005, -0.416015625],
              [130.7501953125, -0.44384765625],
              [130.69980468750003, -0.3916015625],
              [130.688671875, -0.296582031250011],
              [130.60654296875003, -0.32861328125],
              [130.57490234375, -0.36181640625],
              [130.55078125, -0.366406250000011],
              [130.4962890625, -0.267382812500003],
              [130.34052734375, -0.262304687500006],
              [130.23662109375005, -0.209667968750011],
              [130.28769531250003, -0.1546875],
              [130.294921875, -0.101464843750009],
              [130.3625, -0.072851562500006],
              [130.43095703125005, -0.098486328125006],
              [130.49960937500003, -0.060107421875003],
              [130.54833984375, -0.069921875],
              [130.58427734375005, -0.04541015625],
              [130.72236328125, -0.029833984375003],
              [130.81328125000005, -0.004101562500011],
            ],
          ],
          [
            [
              [128.45390625000005, 2.0517578125],
              [128.2958984375, 2.034716796874989],
              [128.2599609375, 2.08251953125],
              [128.21796875, 2.297460937499991],
              [128.33037109375005, 2.469335937499991],
              [128.47207031250002, 2.570507812499997],
              [128.56865234375005, 2.59609375],
              [128.60214843750003, 2.597607421874997],
              [128.6884765625, 2.473681640624989],
              [128.6232421875, 2.224414062499989],
              [128.54755859375, 2.097070312499994],
              [128.45390625000005, 2.0517578125],
            ],
          ],
          [
            [
              [128.15302734375, -1.660546875],
              [128.091796875, -1.701171875],
              [128.06123046875, -1.71240234375],
              [127.91376953125001, -1.68515625],
              [127.74101562500005, -1.690820312500009],
              [127.56162109375003, -1.728515625],
              [127.45761718750003, -1.696679687500009],
              [127.39218750000003, -1.644824218750003],
              [127.39501953125, -1.58984375],
              [127.45673828125001, -1.453710937500006],
              [127.591796875, -1.350781250000011],
              [127.64667968750001, -1.332421875],
              [127.74296875000005, -1.360253906250009],
              [127.90507812500005, -1.4390625],
              [128.03281250000003, -1.531640625],
              [128.14873046875005, -1.603710937500011],
              [128.15302734375, -1.660546875],
            ],
          ],
          [
            [
              [126.05507812500002, -2.451269531250006],
              [126.03789062499999, -2.469433593750011],
              [125.97792968750002, -2.415429687500009],
              [125.93759765625003, -2.262792968750006],
              [125.90322265625002, -2.22216796875],
              [125.86289062500003, -2.0771484375],
              [125.87324218750001, -2.0359375],
              [125.92275390625002, -1.974804687500011],
              [125.96279296875002, -1.975781250000011],
              [125.99267578125, -2.011816406250006],
              [125.97597656250002, -2.168066406250006],
              [126.06572265624999, -2.365820312500006],
              [126.05507812500002, -2.451269531250006],
            ],
          ],
          [
            [
              [126.02421874999999, -1.789746093750011],
              [126.33173828125001, -1.822851562500006],
              [126.2880859375, -1.85888671875],
              [125.95644531250002, -1.916601562500006],
              [125.8388671875, -1.906152343750009],
              [125.47919921875001, -1.940039062500006],
              [125.4326171875, -1.938085937500006],
              [125.42597656250001, -1.882226562500009],
              [125.38720703125, -1.843066406250003],
              [125.44472656250002, -1.808984375],
              [125.52089843750002, -1.800878906250006],
              [125.72031250000003, -1.813769531250003],
              [126.02421874999999, -1.789746093750011],
            ],
          ],
          [
            [
              [124.96953124999999, -1.70546875],
              [125.06298828125, -1.741015625],
              [125.09589843750001, -1.740820312500006],
              [125.12675781249999, -1.699316406250006],
              [125.14580078124999, -1.692578125000011],
              [125.18789062500002, -1.712890625],
              [125.19765625000002, -1.7802734375],
              [125.25820312500002, -1.770898437500009],
              [125.30537109375001, -1.7939453125],
              [125.32021484375002, -1.81005859375],
              [125.31406250000003, -1.877148437500011],
              [125.134765625, -1.888964843750003],
              [125.00673828125002, -1.943066406250011],
              [124.83447265625, -1.894433593750009],
              [124.63916015625, -1.978222656250011],
              [124.52060546875003, -2.006933593750006],
              [124.41777343749999, -2.005175781250003],
              [124.32968750000003, -1.85888671875],
              [124.380859375, -1.6875],
              [124.41757812500003, -1.659277343750006],
              [124.48300781250003, -1.644335937500003],
              [124.66396484375002, -1.635937500000011],
              [124.96953124999999, -1.70546875],
            ],
          ],
          [
            [
              [131.32558593750002, -7.99951171875],
              [131.30917968750003, -8.010839843750006],
              [131.18496093750002, -7.997851562500003],
              [131.11376953125, -7.997363281250003],
              [131.12343750000002, -7.921875],
              [131.08681640625002, -7.865039062500003],
              [131.13681640625003, -7.78173828125],
              [131.13779296875003, -7.684863281250003],
              [131.19003906250003, -7.671875],
              [131.19736328125003, -7.61669921875],
              [131.26005859375005, -7.470507812500003],
              [131.296875, -7.438085937500006],
              [131.34921875000003, -7.425390625],
              [131.41103515625002, -7.340136718750003],
              [131.44619140625002, -7.315332031250009],
              [131.4826171875, -7.250683593750011],
              [131.53525390625003, -7.220605468750009],
              [131.53085937500003, -7.165136718750006],
              [131.5607421875, -7.1357421875],
              [131.64345703125002, -7.11279296875],
              [131.70078125000003, -7.140234375],
              [131.73613281250005, -7.197070312500003],
              [131.64384765625005, -7.266894531250003],
              [131.69111328125, -7.438867187500009],
              [131.62441406250002, -7.626171875000011],
              [131.5802734375, -7.682226562500006],
              [131.49843750000002, -7.730664062500011],
              [131.47353515625002, -7.776660156250003],
              [131.37705078125003, -7.869140625],
              [131.34775390625003, -7.948046875],
              [131.34345703125, -7.9814453125],
              [131.32558593750002, -7.99951171875],
            ],
          ],
          [
            [
              [126.80097656250001, -7.667871093750009],
              [126.814453125, -7.716503906250011],
              [126.81269531250001, -7.737890625],
              [126.69287109375, -7.753515625],
              [126.57734375000001, -7.8076171875],
              [126.51816406250003, -7.869921875],
              [126.47207031250002, -7.950390625000011],
              [126.31289062500002, -7.917675781250011],
              [126.17109375000001, -7.912304687500011],
              [126.1083984375, -7.883984375000011],
              [126.0400390625, -7.885839843750006],
              [125.95156250000002, -7.9109375],
              [125.826171875, -7.979296875],
              [125.79824218750002, -7.984570312500011],
              [125.80839843749999, -7.880664062500003],
              [125.84316406250002, -7.816699218750003],
              [125.97529296875001, -7.663378906250003],
              [126.08535156250002, -7.697363281250006],
              [126.21367187499999, -7.706738281250011],
              [126.359375, -7.6767578125],
              [126.462890625, -7.6078125],
              [126.60957031250001, -7.57177734375],
              [126.72636718749999, -7.662207031250006],
              [126.80097656250001, -7.667871093750009],
            ],
          ],
          [
            [
              [124.57558593750002, -8.140820312500011],
              [124.599609375, -8.201757812500006],
              [124.67685546875003, -8.168066406250006],
              [124.75224609374999, -8.159570312500009],
              [124.92412109374999, -8.166015625],
              [125.05029296875, -8.179589843750009],
              [125.12460937500003, -8.204785156250011],
              [125.13173828125002, -8.326464843750003],
              [125.09677734375003, -8.352832031250003],
              [124.44423828125002, -8.444628906250003],
              [124.38066406249999, -8.415136718750006],
              [124.35556640625003, -8.385937500000011],
              [124.42597656250001, -8.295800781250009],
              [124.3935546875, -8.253027343750006],
              [124.4306640625, -8.183203125],
              [124.50859374999999, -8.135449218750011],
              [124.57558593750002, -8.140820312500011],
            ],
          ],
          [
            [
              [131.00185546875002, -1.315527343750006],
              [130.96660156250005, -1.343457031250011],
              [130.84511718750002, -1.317285156250009],
              [130.78232421875003, -1.25546875],
              [130.73935546875003, -1.172558593750011],
              [130.712109375, -1.104394531250009],
              [130.70439453125005, -1.050195312500009],
              [130.66796875, -0.983984375],
              [130.67294921875003, -0.959765625],
              [130.89716796875, -0.890039062500009],
              [130.939453125, -0.915332031250003],
              [131.03300781250005, -0.917578125],
              [131.07392578125, -0.96826171875],
              [131.04619140625005, -1.188183593750011],
              [131.00185546875002, -1.315527343750006],
            ],
          ],
          [
            [
              [96.49257812500002, 5.229345703124991],
              [96.615234375, 5.22021484375],
              [96.84267578125002, 5.274462890624989],
              [96.9677734375, 5.269140625],
              [97.08574218749999, 5.229931640624997],
              [97.1904296875, 5.207324218749989],
              [97.451171875, 5.236035156249997],
              [97.50019531250001, 5.228320312499989],
              [97.54716796874999, 5.205859374999989],
              [97.5875, 5.170361328124997],
              [97.70673828125001, 5.040136718749991],
              [97.90839843750001, 4.879980468749991],
              [97.96660156249999, 4.777490234374994],
              [97.99980468749999, 4.662255859374994],
              [98.02070312500001, 4.635205078124997],
              [98.24843750000002, 4.41455078125],
              [98.27333984375002, 4.322314453124989],
              [98.2412109375, 4.19453125],
              [98.30732421875001, 4.092871093749991],
              [98.5283203125, 3.99755859375],
              [98.65869140625, 3.928125],
              [98.6865234375, 3.885546874999989],
              [98.70576171875001, 3.834765624999989],
              [98.77792968750003, 3.759423828124994],
              [98.86865234375, 3.710351562499994],
              [99.15117187499999, 3.58125],
              [99.521484375, 3.311181640624994],
              [99.73232421875002, 3.183056640624997],
              [99.90664062500002, 2.988183593749994],
              [99.96943359375001, 2.894921875],
              [100.02128906249999, 2.794238281249989],
              [100.12724609374999, 2.647607421874994],
              [100.30722656250003, 2.466601562499989],
              [100.35273437500001, 2.411474609374991],
              [100.40117187499999, 2.331640625],
              [100.45703125, 2.257421875],
              [100.52382812500002, 2.189160156249997],
              [100.60361328125003, 2.136962890625],
              [100.68525390625001, 2.120068359374997],
              [100.81679687500002, 1.9892578125],
              [100.88789062500001, 1.9482421875],
              [100.87666015625001, 2.050585937499989],
              [100.81689453125, 2.140185546874989],
              [100.81777343750002, 2.194238281249994],
              [100.82822265625003, 2.242578125],
              [100.87705078125003, 2.283300781249991],
              [100.93593750000002, 2.294726562499989],
              [101.04619140624999, 2.257470703124994],
              [101.22519531250003, 2.102246093749997],
              [101.30078125, 2.011816406249991],
              [101.35761718750001, 1.887011718749989],
              [101.40507812499999, 1.757421875],
              [101.47666015625003, 1.693066406249997],
              [101.575, 1.670556640624994],
              [101.68427734375001, 1.661230468749991],
              [101.78476562500003, 1.621386718749989],
              [102.01992187500002, 1.442138671875],
              [102.09804687500002, 1.35791015625],
              [102.1572265625, 1.258886718749991],
              [102.19794921875001, 1.141699218749991],
              [102.22333984375001, 1.018701171874994],
              [102.23906249999999, 0.990332031249991],
              [102.38994140624999, 0.841992187499997],
              [102.46923828125, 0.779296875],
              [102.56640625, 0.748828124999989],
              [102.84941406249999, 0.715478515624994],
              [102.94931640625003, 0.664208984374994],
              [103.03183593750003, 0.578906249999989],
              [103.06650390625003, 0.491992187499989],
              [103.00751953125001, 0.415332031249989],
              [102.78632812500001, 0.297753906249994],
              [102.55, 0.216455078124994],
              [102.77958984374999, 0.244482421874991],
              [102.89589843750002, 0.278613281249989],
              [103.00283203125002, 0.331982421874997],
              [103.10869140624999, 0.399804687499994],
              [103.27656250000001, 0.49453125],
              [103.33896484375003, 0.513720703124989],
              [103.41230468750001, 0.506933593749991],
              [103.47890625000002, 0.480175781249997],
              [103.57871093750003, 0.387060546874991],
              [103.67265624999999, 0.288916015624991],
              [103.74277343750003, 0.174414062499991],
              [103.78671875000003, 0.046972656249991],
              [103.70644531250002, -0.019580078125003],
              [103.58945312500003, -0.06875],
              [103.42851562499999, -0.191796875],
              [103.41162109375, -0.240429687500011],
              [103.44443359375003, -0.271679687500011],
              [103.40517578125002, -0.362207031250009],
              [103.49541015624999, -0.418066406250006],
              [103.50917968750002, -0.465527343750011],
              [103.43115234375, -0.53359375],
              [103.43857421875003, -0.575585937500009],
              [103.53271484375, -0.7546875],
              [103.57753906250002, -0.795703125],
              [103.72109375000002, -0.88671875],
              [103.94003906250003, -0.979101562500006],
              [104.06113281250003, -1.021386718750009],
              [104.19853515624999, -1.054296875],
              [104.25751953125001, -1.053417968750011],
              [104.36054687500001, -1.038378906250003],
              [104.38125, -1.07421875],
              [104.42568359375002, -1.250683593750011],
              [104.446875, -1.362402343750006],
              [104.47832031249999, -1.60009765625],
              [104.5185546875, -1.69873046875],
              [104.51591796874999, -1.819433593750006],
              [104.56875, -1.921777343750009],
              [104.67636718750003, -1.987207031250009],
              [104.791015625, -2.040820312500003],
              [104.84521484375, -2.092968750000011],
              [104.84453124999999, -2.171777343750009],
              [104.82607421875002, -2.234179687500003],
              [104.78730468750001, -2.28271484375],
              [104.66845703125, -2.385546875],
              [104.64726562499999, -2.429882812500011],
              [104.63056640625001, -2.543359375],
              [104.65078125000002, -2.59521484375],
              [104.69833984375003, -2.59814453125],
              [104.73574218750002, -2.570898437500006],
              [104.87841796875, -2.418847656250009],
              [104.9169921875, -2.3921875],
              [104.97080078125003, -2.370898437500003],
              [105.02587890625, -2.357519531250006],
              [105.28652343750002, -2.35625],
              [105.39697265625, -2.380175781250003],
              [105.49531250000001, -2.4296875],
              [105.58203125, -2.491992187500003],
              [105.89912109375001, -2.887792968750006],
              [106.04433593750002, -3.10625],
              [106.05576171875003, -3.16064453125],
              [106.05839843749999, -3.2171875],
              [106.03369140625, -3.260937500000011],
              [105.90146484375003, -3.410058593750009],
              [105.88505859374999, -3.451269531250006],
              [105.84375, -3.613671875],
              [105.8515625, -3.730566406250006],
              [105.8955078125, -3.7796875],
              [105.93046874999999, -3.8330078125],
              [105.927734375, -3.88134765625],
              [105.840625, -4.121777343750011],
              [105.83144531250002, -4.162890625],
              [105.88652343749999, -4.553906250000011],
              [105.89052734375002, -4.659765625],
              [105.87929687500002, -4.793652343750011],
              [105.88720703125, -5.009570312500003],
              [105.81611328125001, -5.6765625],
              [105.802734375, -5.71640625],
              [105.74833984374999, -5.818261718750009],
              [105.67656249999999, -5.817578125000011],
              [105.61855468750002, -5.799609375],
              [105.57792968749999, -5.760644531250009],
              [105.55556640625002, -5.712304687500009],
              [105.52265625000001, -5.672753906250009],
              [105.34941406249999, -5.549511718750011],
              [105.30400390624999, -5.570019531250011],
              [105.128125, -5.722851562500011],
              [105.08134765624999, -5.745507812500009],
              [105.02265625000001, -5.726855468750003],
              [104.93027343750003, -5.68115234375],
              [104.63955078125002, -5.520410156250009],
              [104.62167968750003, -5.57177734375],
              [104.6181640625, -5.641503906250009],
              [104.67597656250001, -5.816210937500003],
              [104.68398437500002, -5.892675781250006],
              [104.63105468750001, -5.907910156250011],
              [104.6015625, -5.904589843750003],
              [104.48085937500002, -5.803125],
              [104.36953125000002, -5.690722656250003],
              [104.24296874999999, -5.538867187500003],
              [104.15048828125003, -5.466601562500003],
              [104.06679687500002, -5.385937500000011],
              [103.83144531250002, -5.07958984375],
              [103.77031249999999, -5.0328125],
              [103.40566406250002, -4.81640625],
              [103.33212890625003, -4.765234375],
              [103.23886718750003, -4.675683593750009],
              [103.138671875, -4.59619140625],
              [102.9189453125, -4.470703125],
              [102.53769531250003, -4.152148437500003],
              [102.37197265625002, -3.96923828125],
              [102.18769531250001, -3.674511718750011],
              [102.12753906250003, -3.59921875],
              [101.81787109375, -3.378027343750006],
              [101.64902343750003, -3.244042968750009],
              [101.57861328125, -3.1669921875],
              [101.41425781250001, -2.898828125],
              [101.3662109375, -2.808496093750009],
              [101.3056640625, -2.728710937500011],
              [101.20625, -2.663964843750009],
              [101.11855468750002, -2.587792968750009],
              [100.94443359375003, -2.34521484375],
              [100.88955078125002, -2.24853515625],
              [100.84804687500002, -2.143945312500009],
              [100.85527343749999, -1.934179687500006],
              [100.48652343750001, -1.299121093750003],
              [100.39394531250002, -1.101269531250011],
              [100.30820312500003, -0.82666015625],
              [100.2890625, -0.798828125],
              [100.087890625, -0.552929687500011],
              [100.01669921875003, -0.47421875],
              [99.9306640625, -0.400195312500003],
              [99.86005859375001, -0.313769531250003],
              [99.72128906250003, -0.032958984375],
              [99.66982421875002, 0.045068359374994],
              [99.59765625, 0.102441406249994],
              [99.33457031250003, 0.20859375],
              [99.23642578125003, 0.267773437499997],
              [99.1591796875, 0.351757812499997],
              [99.11171875000002, 0.458935546874997],
              [99.0595703125, 0.686376953124991],
              [98.935546875, 1.031933593749997],
              [98.79638671875, 1.49462890625],
              [98.70253906250002, 1.701953124999989],
              [98.59531250000003, 1.864599609374991],
              [98.56425781249999, 1.902148437499989],
              [98.08652343750003, 2.195068359375],
              [98.00507812500001, 2.238183593749994],
              [97.91855468750003, 2.264208984374989],
              [97.79501953125003, 2.282861328124994],
              [97.70078125000003, 2.358544921874994],
              [97.66201171875002, 2.494287109374994],
              [97.640625, 2.676416015624994],
              [97.61679687500003, 2.785107421874997],
              [97.5908203125, 2.846582031249994],
              [97.39130859375001, 2.975292968749997],
              [97.31318359375001, 3.077050781249994],
              [97.24794921875002, 3.189013671874989],
              [97.18837890625002, 3.275732421874991],
              [96.96894531250001, 3.575146484374997],
              [96.89394531250002, 3.653710937499994],
              [96.80097656250001, 3.708544921874989],
              [96.525390625, 3.7666015625],
              [96.44472656250002, 3.816308593749994],
              [96.31083984374999, 3.986328125],
              [96.23007812500003, 4.07275390625],
              [95.98798828125001, 4.26328125],
              [95.57861328125, 4.661962890624991],
              [95.49472656250003, 4.761376953124994],
              [95.43193359374999, 4.865039062499989],
              [95.38125, 4.976171875],
              [95.20664062500003, 5.284033203124991],
              [95.220703125, 5.346240234374989],
              [95.2470703125, 5.410791015624994],
              [95.24296874999999, 5.464306640624997],
              [95.22382812500001, 5.51708984375],
              [95.22783203124999, 5.564794921874991],
              [95.27958984374999, 5.592871093749991],
              [95.39609375000003, 5.628808593749994],
              [95.51699218750002, 5.624609375],
              [95.62890625, 5.609082031249997],
              [95.7373046875, 5.579296875],
              [95.84130859375, 5.514501953124991],
              [96.02734375, 5.351171875],
              [96.13330078125, 5.294287109374991],
              [96.25087890625002, 5.266992187499994],
              [96.49257812500002, 5.229345703124991],
            ],
          ],
          [
            [
              [122.78291015625001, -8.61171875],
              [122.64150390625002, -8.647265625],
              [122.55380859375003, -8.680957031250003],
              [122.47021484375, -8.725488281250009],
              [122.41728515624999, -8.734667968750003],
              [122.32148437500001, -8.73828125],
              [122.18574218750001, -8.730273437500003],
              [122.09414062500002, -8.744726562500006],
              [121.83867187499999, -8.8603515625],
              [121.73828125, -8.870410156250003],
              [121.6513671875, -8.898730468750003],
              [121.62128906250001, -8.853808593750003],
              [121.58457031250003, -8.820605468750003],
              [121.49960937500003, -8.812207031250011],
              [121.41464843750003, -8.81484375],
              [121.32832031250001, -8.916894531250009],
              [121.19082031250002, -8.8955078125],
              [121.1375, -8.904492187500011],
              [121.08613281250001, -8.925976562500011],
              [121.03525390625003, -8.935449218750009],
              [120.98183593750002, -8.928320312500006],
              [120.78095703125001, -8.848828125000011],
              [120.55048828125001, -8.801855468750006],
              [120.31953125000001, -8.8203125],
              [120.12089843749999, -8.776953125],
              [120.01210937500002, -8.81015625],
              [119.909375, -8.857617187500011],
              [119.87910156250001, -8.8076171875],
              [119.84140625000003, -8.763574218750009],
              [119.80791015624999, -8.69765625],
              [119.80703125000002, -8.622949218750009],
              [119.81816406249999, -8.570507812500011],
              [119.84765625, -8.522851562500009],
              [119.86611328125002, -8.47314453125],
              [119.87480468749999, -8.419824218750009],
              [119.91826171874999, -8.445117187500003],
              [119.96376953125002, -8.435546875],
              [120.09921875000003, -8.377539062500006],
              [120.23115234375001, -8.28984375],
              [120.35410156250003, -8.2578125],
              [120.42490234375003, -8.248925781250009],
              [120.48554687500001, -8.26611328125],
              [120.54716796874999, -8.259863281250006],
              [120.61025390625002, -8.240429687500011],
              [120.70957031250003, -8.307812500000011],
              [120.75136718750002, -8.321484375000011],
              [120.88613281250002, -8.32666015625],
              [121.00869140625002, -8.365527343750003],
              [121.1181640625, -8.423535156250011],
              [121.27666015624999, -8.477929687500009],
              [121.37197265625002, -8.550878906250006],
              [121.44453125000001, -8.577832031250011],
              [121.49843750000002, -8.585156250000011],
              [121.54794921875003, -8.575292968750006],
              [121.6103515625, -8.526171875],
              [121.68339843749999, -8.505859375],
              [121.7470703125, -8.506640625],
              [121.86289062500003, -8.493945312500003],
              [121.91171875000003, -8.482128906250011],
              [121.96650390625001, -8.455175781250006],
              [122.02011718750003, -8.471875],
              [122.06708984375001, -8.496679687500006],
              [122.26308593750002, -8.624902343750009],
              [122.3232421875, -8.628320312500009],
              [122.43349609375002, -8.600781250000011],
              [122.46660156249999, -8.56640625],
              [122.48359375000001, -8.513574218750009],
              [122.51376953125003, -8.469628906250009],
              [122.55585937500001, -8.431542968750009],
              [122.603515625, -8.402441406250006],
              [122.75, -8.353125],
              [122.85048828125002, -8.304394531250011],
              [122.91914062500001, -8.221875],
              [122.75859374999999, -8.1859375],
              [122.79238281250002, -8.1265625],
              [122.845703125, -8.09326171875],
              [122.9169921875, -8.105566406250006],
              [122.97832031249999, -8.151953125],
              [123.00595703125003, -8.3291015625],
              [122.95546875000002, -8.354101562500006],
              [122.92363281249999, -8.380957031250006],
              [122.90214843749999, -8.416308593750003],
              [122.81113281250003, -8.481152343750011],
              [122.84677734375003, -8.562207031250011],
              [122.82001953125001, -8.595703125],
              [122.78291015625001, -8.61171875],
            ],
          ],
          [
            [
              [120.0125, -9.374707031250011],
              [120.0576171875, -9.419726562500003],
              [120.22109375000002, -9.50634765625],
              [120.248046875, -9.542871093750009],
              [120.25830078125, -9.603125],
              [120.29111328125003, -9.647851562500009],
              [120.36474609375, -9.6546875],
              [120.44365234374999, -9.645605468750006],
              [120.50371093749999, -9.674023437500011],
              [120.55556640625002, -9.719042968750003],
              [120.63261718749999, -9.806445312500003],
              [120.70039062500001, -9.903125],
              [120.78447265624999, -9.95703125],
              [120.83261718750003, -10.0375],
              [120.80419921875, -10.108496093750006],
              [120.69804687499999, -10.206640625],
              [120.64042968749999, -10.227929687500009],
              [120.56171875000001, -10.235644531250003],
              [120.43916015625001, -10.294042968750006],
              [120.39453125, -10.263476562500003],
              [120.25546875000003, -10.242285156250006],
              [120.14482421874999, -10.200097656250009],
              [120.05195312500001, -10.122851562500003],
              [119.99843750000002, -10.039746093750011],
              [119.9306640625, -9.966503906250011],
              [119.81279296874999, -9.91748046875],
              [119.60107421875, -9.773535156250006],
              [119.47031250000003, -9.760546875],
              [119.41650390625, -9.77109375],
              [119.36259765624999, -9.771777343750003],
              [119.08544921875, -9.706933593750009],
              [119.04238281250002, -9.669042968750006],
              [119.00839843750003, -9.620507812500009],
              [118.97734374999999, -9.572851562500006],
              [118.95878906249999, -9.519335937500003],
              [118.994140625, -9.472070312500009],
              [119.03144531250001, -9.440234375],
              [119.18564453125003, -9.384472656250011],
              [119.2958984375, -9.3671875],
              [119.42392578125003, -9.369824218750011],
              [119.61474609375, -9.352441406250009],
              [119.79511718750001, -9.38046875],
              [119.85078125000001, -9.359570312500011],
              [119.94208984375001, -9.301464843750011],
              [119.97382812500001, -9.321582031250003],
              [120.0125, -9.374707031250011],
            ],
          ],
          [
            [
              [118.24238281250001, -8.317773437500009],
              [118.29238281250002, -8.357226562500003],
              [118.337890625, -8.353515625],
              [118.43320312500003, -8.293261718750003],
              [118.490625, -8.271484375],
              [118.55214843750002, -8.270410156250009],
              [118.61191406250003, -8.280664062500009],
              [118.67060546875001, -8.323437500000011],
              [118.69179687500002, -8.393457031250009],
              [118.7138671875, -8.414941406250009],
              [118.74833984374999, -8.331152343750006],
              [118.79423828124999, -8.305859375000011],
              [118.845703125, -8.293066406250006],
              [118.92617187500002, -8.29765625],
              [118.98779296875, -8.337695312500003],
              [119.04384765625002, -8.456738281250011],
              [119.04208984375003, -8.5609375],
              [119.0625, -8.599804687500011],
              [119.10107421875, -8.628222656250003],
              [119.12968749999999, -8.668164062500011],
              [119.10419921875001, -8.7099609375],
              [119.07890624999999, -8.73046875],
              [119.00625, -8.749609375],
              [118.97148437499999, -8.7412109375],
              [118.93935546875002, -8.713085937500011],
              [118.9033203125, -8.702734375],
              [118.82119140625002, -8.712109375000011],
              [118.74589843749999, -8.735449218750006],
              [118.75625, -8.773632812500011],
              [118.81806640625001, -8.790820312500003],
              [118.83671874999999, -8.808886718750003],
              [118.83261718750003, -8.833398437500009],
              [118.80830078125001, -8.83828125],
              [118.72792968750002, -8.805273437500006],
              [118.67363281249999, -8.811914062500009],
              [118.47861328125003, -8.8564453125],
              [118.42695312500001, -8.85546875],
              [118.39785156250002, -8.813378906250009],
              [118.39990234375, -8.703710937500006],
              [118.37890625, -8.674609375],
              [118.23398437500003, -8.807812500000011],
              [118.18994140625, -8.840527343750011],
              [118.13154296875001, -8.85595703125],
              [118.07070312500002, -8.8505859375],
              [117.86123046875002, -8.931445312500003],
              [117.79541015625, -8.920117187500011],
              [117.73164062500001, -8.919921875],
              [117.50791015625003, -9.007519531250011],
              [117.38789062500001, -9.031933593750011],
              [117.32636718750001, -9.03369140625],
              [117.26503906250002, -9.026171875],
              [117.21025390624999, -9.034082031250009],
              [117.16123046875003, -9.069238281250009],
              [117.06132812499999, -9.099023437500009],
              [116.95820312500001, -9.076367187500011],
              [116.87109375, -9.046191406250003],
              [116.78847656250002, -9.00634765625],
              [116.76796875000002, -8.95546875],
              [116.77207031250003, -8.894335937500003],
              [116.80693359374999, -8.8109375],
              [116.78310546875002, -8.664648437500006],
              [116.80126953125, -8.597949218750003],
              [116.83505859375003, -8.532421875000011],
              [116.88623046875, -8.50830078125],
              [116.953125, -8.50341796875],
              [117.06367187500001, -8.444433593750006],
              [117.16484374999999, -8.3671875],
              [117.2236328125, -8.37451171875],
              [117.35664062500001, -8.428515625],
              [117.4345703125, -8.434960937500009],
              [117.56708984375001, -8.426367187500006],
              [117.62177734375001, -8.459570312500006],
              [117.64335937499999, -8.535546875],
              [117.6728515625, -8.56328125],
              [117.71210937500001, -8.582617187500006],
              [117.80605468750002, -8.711132812500011],
              [117.89316406250003, -8.704394531250003],
              [117.96953124999999, -8.72802734375],
              [118.10410156250003, -8.650292968750009],
              [118.20595703125002, -8.652148437500003],
              [118.23486328125, -8.591894531250006],
              [118.17402343750001, -8.527539062500011],
              [118.10048828125002, -8.475195312500006],
              [118.06103515625, -8.464257812500009],
              [118.01787109374999, -8.467382812500006],
              [117.97910156250003, -8.458886718750009],
              [117.81484375000002, -8.342089843750003],
              [117.76640624999999, -8.279003906250011],
              [117.73837890625003, -8.20458984375],
              [117.75527343750002, -8.149511718750006],
              [117.86826171875003, -8.100878906250003],
              [117.92099609375003, -8.089062500000011],
              [118.11748046874999, -8.122265625000011],
              [118.15068359374999, -8.15],
              [118.20283203125001, -8.267285156250011],
              [118.24238281250001, -8.317773437500009],
            ],
          ],
          [
            [
              [124.88886718750001, 0.9953125],
              [124.69814453125002, 0.825585937499994],
              [124.63984375000001, 0.743554687499994],
              [124.58906250000001, 0.6552734375],
              [124.51406250000002, 0.55712890625],
              [124.42753906249999, 0.470605468749994],
              [124.384375, 0.444970703124994],
              [124.27802734375001, 0.3984375],
              [124.216796875, 0.38037109375],
              [124.10136718749999, 0.374560546874989],
              [123.75380859375002, 0.305517578124991],
              [123.6396484375, 0.297460937499991],
              [123.52597656250003, 0.300341796874989],
              [123.31044921875002, 0.317578125],
              [123.26542968749999, 0.326611328124997],
              [123.17949218749999, 0.41552734375],
              [123.08251953125, 0.48583984375],
              [122.996875, 0.493505859374991],
              [122.90957031250002, 0.485986328124994],
              [122.28076171875, 0.481054687499991],
              [122.06093750000002, 0.468017578125],
              [121.84199218750001, 0.436572265624989],
              [121.72275390625003, 0.450878906249997],
              [121.60458984375003, 0.486132812499989],
              [121.51572265625003, 0.4984375],
              [121.42578125, 0.494824218749997],
              [121.01298828124999, 0.441699218749989],
              [120.9091796875, 0.44677734375],
              [120.70039062500001, 0.514697265624989],
              [120.57900390625002, 0.5283203125],
              [120.4599609375, 0.510302734374989],
              [120.34902343750002, 0.44921875],
              [120.30703125000002, 0.408251953124989],
              [120.19228515625002, 0.268505859374997],
              [120.12734375000002, 0.166552734374989],
              [120.07832031250001, 0.039746093749997],
              [120.03603515625002, -0.089941406250006],
              [120.01328125000003, -0.196191406250009],
              [120.01210937500002, -0.30712890625],
              [120.03173828125, -0.43203125],
              [120.06289062500002, -0.555566406250009],
              [120.09746093749999, -0.64990234375],
              [120.240625, -0.868261718750006],
              [120.26982421874999, -0.89921875],
              [120.42539062500003, -0.960644531250011],
              [120.517578125, -1.039453125],
              [120.60507812500003, -1.258496093750011],
              [120.66738281250002, -1.3701171875],
              [120.72861328125003, -1.371484375],
              [120.79697265625003, -1.363671875],
              [120.91582031249999, -1.377832031250009],
              [121.03369140625, -1.406542968750003],
              [121.14853515625003, -1.339453125],
              [121.21259765625001, -1.2125],
              [121.27685546875, -1.1181640625],
              [121.43134765625001, -0.938574218750006],
              [121.51933593749999, -0.855566406250006],
              [121.57558593750002, -0.828515625],
              [121.63271484375002, -0.84033203125],
              [121.68115234375, -0.887890625000011],
              [121.73769531250002, -0.925683593750009],
              [121.853125, -0.945996093750011],
              [121.96962890625002, -0.933300781250011],
              [122.09365234375002, -0.875],
              [122.13808593750002, -0.839257812500009],
              [122.17490234375003, -0.79375],
              [122.27998046875001, -0.757031250000011],
              [122.52968750000002, -0.756640625],
              [122.65878906250003, -0.769824218750003],
              [122.88876953125003, -0.755175781250003],
              [122.88554687499999, -0.722070312500009],
              [122.84111328124999, -0.68701171875],
              [122.82949218750002, -0.658886718750011],
              [122.87226562500001, -0.640722656250006],
              [123.02041015625002, -0.599804687500011],
              [123.17148437500003, -0.570703125],
              [123.28144531250001, -0.591503906250011],
              [123.37968749999999, -0.648535156250006],
              [123.41738281250002, -0.707421875],
              [123.43417968750003, -0.778222656250009],
              [123.39628906249999, -0.961621093750011],
              [123.3779296875, -1.004101562500011],
              [123.29960937499999, -1.026074218750011],
              [123.22578125000001, -1.001757812500003],
              [123.15273437500002, -0.90703125],
              [123.04941406250003, -0.872363281250003],
              [122.90283203125, -0.900976562500006],
              [122.8525390625, -0.928125],
              [122.80742187499999, -0.966015625000011],
              [122.724609375, -1.064257812500003],
              [122.65566406250002, -1.175195312500009],
              [122.50664062499999, -1.347851562500011],
              [122.33417968750001, -1.497851562500003],
              [122.25068359375001, -1.555273437500006],
              [122.15761718750002, -1.593945312500011],
              [121.85859375000001, -1.693261718750009],
              [121.77988281250003, -1.766992187500009],
              [121.71875, -1.86279296875],
              [121.65097656250003, -1.895410156250009],
              [121.57265625000002, -1.90576171875],
              [121.51386718750001, -1.887792968750006],
              [121.39472656250001, -1.833789062500003],
              [121.35546875, -1.878222656250003],
              [121.34882812500001, -1.945996093750011],
              [121.40751953124999, -1.970117187500009],
              [121.501953125, -2.045019531250006],
              [121.575, -2.15087890625],
              [121.621875, -2.173632812500003],
              [121.72597656250002, -2.2080078125],
              [121.76972656250001, -2.240917968750011],
              [121.84824218750003, -2.33154296875],
              [121.971875, -2.542382812500009],
              [122.01396484374999, -2.656445312500011],
              [122.08261718750003, -2.74951171875],
              [122.29169921875001, -2.907617187500009],
              [122.30332031250003, -2.952246093750006],
              [122.29042968750002, -3.004199218750003],
              [122.30654296875002, -3.0515625],
              [122.38125, -3.142382812500003],
              [122.39902343750003, -3.200878906250011],
              [122.31728515625002, -3.275097656250011],
              [122.31279296874999, -3.382714843750009],
              [122.2626953125, -3.527441406250006],
              [122.25136718750002, -3.576269531250006],
              [122.2529296875, -3.620410156250003],
              [122.2880859375, -3.66162109375],
              [122.3291015625, -3.694238281250009],
              [122.38535156250003, -3.71142578125],
              [122.4345703125, -3.73984375],
              [122.52919921875002, -3.852636718750006],
              [122.57861328125, -3.88232421875],
              [122.60996093750003, -3.9234375],
              [122.60673828124999, -3.984667968750003],
              [122.64990234375, -4.0205078125],
              [122.68964843750001, -4.08447265625],
              [122.75039062500002, -4.1],
              [122.77880859375, -4.081640625],
              [122.79824218750002, -4.05419921875],
              [122.84794921874999, -4.064550781250006],
              [122.87734375000002, -4.109082031250011],
              [122.89433593749999, -4.166308593750003],
              [122.89980468750002, -4.229394531250009],
              [122.89736328125002, -4.34912109375],
              [122.87226562500001, -4.391992187500009],
              [122.81757812500001, -4.389941406250003],
              [122.7197265625, -4.340722656250009],
              [122.71503906250001, -4.376269531250003],
              [122.7216796875, -4.410742187500006],
              [122.671875, -4.422167968750003],
              [122.61474609375, -4.417382812500009],
              [122.47138671875001, -4.422070312500011],
              [122.20712890625003, -4.496386718750003],
              [122.1142578125, -4.540234375000011],
              [122.05419921875, -4.6201171875],
              [122.05, -4.67529296875],
              [122.0732421875, -4.791699218750011],
              [122.0380859375, -4.832421875],
              [121.9169921875, -4.847949218750003],
              [121.748046875, -4.816699218750003],
              [121.64570312500001, -4.78564453125],
              [121.58867187499999, -4.759570312500003],
              [121.51435546875001, -4.68125],
              [121.48652343750001, -4.5810546875],
              [121.54121093750001, -4.282910156250011],
              [121.55673828125003, -4.24462890625],
              [121.58339843750002, -4.210546875],
              [121.61152343750001, -4.156347656250006],
              [121.61806640625002, -4.092675781250009],
              [121.53740234374999, -4.014843750000011],
              [121.41582031249999, -3.984277343750009],
              [121.31269531250001, -3.91943359375],
              [120.91425781250001, -3.555761718750006],
              [120.89179687500001, -3.520605468750006],
              [120.89091796874999, -3.460351562500009],
              [120.90693359375001, -3.404003906250011],
              [121.03789062499999, -3.205175781250006],
              [121.05429687500003, -3.167089843750006],
              [121.0703125, -3.01015625],
              [121.06679687500002, -2.880957031250006],
              [121.05214843750002, -2.751660156250011],
              [120.99013671875002, -2.6703125],
              [120.87939453125, -2.645605468750006],
              [120.76503906250002, -2.6416015625],
              [120.65361328124999, -2.667578125],
              [120.5439453125, -2.732617187500011],
              [120.34140625000003, -2.86962890625],
              [120.26103515624999, -2.949316406250006],
              [120.25410156250001, -3.052832031250006],
              [120.30048828125001, -3.154296875],
              [120.36044921875003, -3.246875],
              [120.39238281249999, -3.34814453125],
              [120.43662109375003, -3.707324218750003],
              [120.43515625000003, -3.747851562500003],
              [120.38300781250001, -3.85234375],
              [120.3625, -4.085742187500003],
              [120.38457031249999, -4.415136718750006],
              [120.42011718750001, -4.617382812500011],
              [120.40498046875001, -4.727246093750011],
              [120.31015625000003, -4.963183593750003],
              [120.28144531250001, -5.092675781250009],
              [120.279296875, -5.14609375],
              [120.39091796874999, -5.392578125],
              [120.41660156250003, -5.490039062500003],
              [120.43037109375001, -5.591015625000011],
              [120.31162109375003, -5.541601562500006],
              [120.25644531250003, -5.544140625000011],
              [120.20078125000003, -5.559375],
              [120.07705078125002, -5.575488281250003],
              [119.95156250000002, -5.57763671875],
              [119.90761718750002, -5.596289062500006],
              [119.81845703125003, -5.661816406250011],
              [119.76445312499999, -5.68828125],
              [119.71728515625, -5.693359375],
              [119.55742187499999, -5.611035156250011],
              [119.46308593750001, -5.521679687500011],
              [119.37617187500001, -5.4248046875],
              [119.3603515625, -5.314160156250011],
              [119.390625, -5.200585937500009],
              [119.43359375, -5.079199218750006],
              [119.51953125, -4.87734375],
              [119.51552734375002, -4.741894531250011],
              [119.544921875, -4.630859375],
              [119.59404296874999, -4.523144531250011],
              [119.61171875000002, -4.423535156250011],
              [119.62363281250003, -4.034375],
              [119.61142578125003, -3.999804687500003],
              [119.49365234375, -3.7685546875],
              [119.48007812500003, -3.729785156250003],
              [119.47929687499999, -3.667382812500009],
              [119.49199218749999, -3.6078125],
              [119.49453125000002, -3.554101562500009],
              [119.46748046875001, -3.512988281250003],
              [119.41982421875002, -3.475390625],
              [119.36210937499999, -3.458984375],
              [119.24003906249999, -3.475292968750011],
              [118.99462890625, -3.53759765625],
              [118.92216796874999, -3.482714843750003],
              [118.86767578125, -3.398046875],
              [118.83281249999999, -3.280175781250009],
              [118.8125, -3.156640625],
              [118.821875, -3.040625],
              [118.85810546875001, -2.928515625],
              [118.82890624999999, -2.85009765625],
              [118.78369140625, -2.764746093750006],
              [118.78330078125003, -2.720800781250006],
              [118.80898437500002, -2.682324218750011],
              [118.85332031249999, -2.650195312500003],
              [118.90751953124999, -2.631445312500006],
              [118.95820312500001, -2.597460937500003],
              [119.09218750000002, -2.48291015625],
              [119.13535156250003, -2.38232421875],
              [119.13818359375, -2.258496093750011],
              [119.17226562500002, -2.140039062500009],
              [119.24082031250003, -2.030957031250011],
              [119.321875, -1.9296875],
              [119.34824218750003, -1.825292968750006],
              [119.30830078125001, -1.65966796875],
              [119.32412109375002, -1.584277343750003],
              [119.31035156249999, -1.495703125],
              [119.30898437500002, -1.408203125],
              [119.35917968749999, -1.243457031250003],
              [119.50820312500002, -0.90673828125],
              [119.65351562500001, -0.727929687500009],
              [119.71132812500002, -0.680761718750006],
              [119.78671875000003, -0.763964843750003],
              [119.84433593750003, -0.861914062500006],
              [119.84521484375, -0.773242187500003],
              [119.82988281249999, -0.686328125],
              [119.77167968750001, -0.483593750000011],
              [119.721875, -0.088476562500006],
              [119.73583984375, -0.051025390625],
              [119.78652343750002, -0.056982421875006],
              [119.83828125000002, -0.022119140625009],
              [119.865625, 0.040087890624989],
              [119.81171875000001, 0.186914062499994],
              [119.80927734375001, 0.238671875],
              [119.91328125000001, 0.445068359375],
              [119.998046875, 0.520214843749997],
              [120.03515625, 0.566601562499997],
              [120.05644531249999, 0.692529296874994],
              [120.1005859375, 0.740136718749994],
              [120.15654296874999, 0.774169921875],
              [120.22978515624999, 0.861230468749994],
              [120.26953125, 0.970800781249991],
              [120.29384765625002, 0.979150390624994],
              [120.32246093750001, 0.983154296875],
              [120.36650390624999, 0.887548828124991],
              [120.416015625, 0.848681640624989],
              [120.5166015625, 0.817529296874994],
              [120.6025390625, 0.854394531249994],
              [120.62646484375, 0.902392578124989],
              [120.65888671875001, 0.943652343749989],
              [120.71103515625003, 0.986669921874991],
              [120.7548828125, 1.03564453125],
              [120.80361328125002, 1.149267578124991],
              [120.86796874999999, 1.252832031249994],
              [120.912109375, 1.288964843749994],
              [120.96542968750003, 1.311816406249989],
              [121.02460937500001, 1.32578125],
              [121.08173828125001, 1.32763671875],
              [121.20839843750002, 1.2625],
              [121.28173828125, 1.249804687499989],
              [121.35673828124999, 1.254541015624994],
              [121.40410156249999, 1.243603515624997],
              [121.44003906250003, 1.214404296874989],
              [121.47275390625003, 1.155517578125],
              [121.51328125000003, 1.104736328125],
              [121.55068359375002, 1.0796875],
              [121.591796875, 1.06796875],
              [121.86738281250001, 1.088525390624994],
              [122.10820312499999, 1.031152343749994],
              [122.43662109375003, 1.01806640625],
              [122.54931640625, 0.984472656249991],
              [122.65742187500001, 0.940576171874994],
              [122.78984374999999, 0.862890625],
              [122.83828125000002, 0.845703125],
              [122.89248046875002, 0.85],
              [122.96005859375003, 0.922998046874994],
              [123.01279296875003, 0.93896484375],
              [123.06650390625003, 0.941796875],
              [123.278125, 0.928076171874991],
              [123.8466796875, 0.838183593749989],
              [123.93076171875003, 0.850439453124991],
              [124.27363281250001, 1.022265624999989],
              [124.41083984375001, 1.185107421874989],
              [124.53369140625, 1.23046875],
              [124.57539062500001, 1.304052734374991],
              [124.60019531250003, 1.392431640624991],
              [124.64375, 1.416162109374994],
              [124.74667968750003, 1.44140625],
              [124.78769531250003, 1.467578124999989],
              [124.86064453124999, 1.576025390624991],
              [124.94707031249999, 1.672167968749989],
              [124.9892578125, 1.701025390624991],
              [125.11093750000003, 1.685693359374994],
              [125.16484374999999, 1.643652343749991],
              [125.23378906250002, 1.502294921874991],
              [125.2216796875, 1.478710937499997],
              [125.14091796874999, 1.408398437499997],
              [125.11748046874999, 1.37890625],
              [125.02802734375001, 1.180224609374989],
              [124.966796875, 1.082617187499991],
              [124.88886718750001, 0.9953125],
            ],
          ],
          [
            [
              [107.37392578125002, -6.007617187500003],
              [107.47470703125003, -6.121777343750011],
              [107.56298828125, -6.182714843750006],
              [107.66679687499999, -6.2158203125],
              [107.77607421875001, -6.218945312500011],
              [107.8837890625, -6.233300781250009],
              [108.0087890625, -6.276953125],
              [108.13759765625002, -6.296679687500003],
              [108.19746093750001, -6.2890625],
              [108.25449218750003, -6.2666015625],
              [108.29501953125003, -6.265039062500009],
              [108.33017578125003, -6.286035156250009],
              [108.41914062500001, -6.3828125],
              [108.51591796874999, -6.47119140625],
              [108.53798828125002, -6.516210937500006],
              [108.60361328125003, -6.729199218750011],
              [108.67783203125003, -6.79052734375],
              [108.77968750000002, -6.808300781250011],
              [108.8994140625, -6.808398437500003],
              [109.01835937499999, -6.817285156250009],
              [109.29423828124999, -6.866992187500003],
              [109.40371093750002, -6.86015625],
              [109.50058593750003, -6.81015625],
              [109.5869140625, -6.842578125],
              [109.82099609375001, -6.902441406250006],
              [109.93623046875001, -6.915820312500003],
              [110.06708984375001, -6.898730468750003],
              [110.19843750000001, -6.895117187500006],
              [110.26093750000001, -6.912402343750003],
              [110.32109374999999, -6.938378906250009],
              [110.37275390625001, -6.94775390625],
              [110.42626953125, -6.947265625],
              [110.52089843750002, -6.897265625],
              [110.58359375000003, -6.8056640625],
              [110.63427734375, -6.690136718750011],
              [110.67402343750001, -6.56982421875],
              [110.70078125000003, -6.51806640625],
              [110.73691406250003, -6.472363281250011],
              [110.7841796875, -6.442675781250003],
              [110.83476562499999, -6.42421875],
              [110.97226562500003, -6.435644531250006],
              [111.00068359375001, -6.464746093750009],
              [111.15439453125003, -6.669042968750006],
              [111.18154296875002, -6.686718750000011],
              [111.34208984374999, -6.699511718750003],
              [111.38652343749999, -6.69287109375],
              [111.48447265625003, -6.65185546875],
              [111.54033203124999, -6.648242187500003],
              [111.6435546875, -6.69873046875],
              [111.68808593750003, -6.74169921875],
              [111.73759765624999, -6.7734375],
              [111.98984375000003, -6.805957031250003],
              [112.08730468750002, -6.893359375],
              [112.13671875, -6.905078125],
              [112.31230468749999, -6.894433593750009],
              [112.43359375, -6.903027343750011],
              [112.53925781250001, -6.926464843750011],
              [112.5869140625, -7.050585937500003],
              [112.6259765625, -7.178027343750003],
              [112.64873046874999, -7.221289062500006],
              [112.751953125, -7.265039062500009],
              [112.79433593750002, -7.304492187500003],
              [112.78291015625001, -7.431640625],
              [112.79453125000003, -7.552441406250011],
              [113.01357421875002, -7.65771484375],
              [113.24843750000002, -7.718164062500009],
              [113.49765625000003, -7.723828125000011],
              [113.74746093750002, -7.703027343750009],
              [113.87626953124999, -7.67724609375],
              [114.03730468750001, -7.632128906250003],
              [114.07070312500002, -7.633007812500011],
              [114.38271484375002, -7.77109375],
              [114.40927734375003, -7.79248046875],
              [114.44423828125002, -7.895605468750006],
              [114.44326171875002, -8.004589843750011],
              [114.38496093750001, -8.26328125],
              [114.38134765625, -8.334277343750003],
              [114.38691406250001, -8.405175781250009],
              [114.44882812500003, -8.559277343750011],
              [114.48173828124999, -8.603808593750003],
              [114.59501953124999, -8.684765625000011],
              [114.59921875000003, -8.727246093750011],
              [114.58378906249999, -8.769628906250006],
              [114.45917968750001, -8.740527343750003],
              [114.38320312500002, -8.705371093750003],
              [114.33925781250002, -8.647363281250009],
              [114.27695312500003, -8.614648437500009],
              [114.15966796875, -8.62646484375],
              [113.94033203125002, -8.568359375],
              [113.69257812500001, -8.47802734375],
              [113.25332031250002, -8.28671875],
              [113.13369140625002, -8.288281250000011],
              [113.01894531250002, -8.312695312500011],
              [112.89775390624999, -8.361425781250006],
              [112.77167968750001, -8.39609375],
              [112.67880859375003, -8.4091796875],
              [112.58603515625003, -8.399609375000011],
              [112.3515625, -8.353613281250006],
              [112.11513671875002, -8.323925781250011],
              [111.50996093750001, -8.305078125],
              [111.33857421875001, -8.26171875],
              [111.05537109375001, -8.239550781250003],
              [110.83017578125003, -8.201953125],
              [110.60722656249999, -8.1494140625],
              [110.03867187500003, -7.890527343750009],
              [109.85263671875003, -7.828417968750003],
              [109.28164062500002, -7.704882812500003],
              [109.19355468750001, -7.694921875],
              [108.98671875000002, -7.7041015625],
              [108.85625, -7.667871093750009],
              [108.7412109375, -7.667089843750006],
              [108.57050781250001, -7.707226562500011],
              [108.51796875000002, -7.736035156250011],
              [108.45175781250003, -7.796972656250006],
              [108.33554687500003, -7.794042968750006],
              [108.22050781249999, -7.782324218750006],
              [107.91748046875, -7.72412109375],
              [107.80439453125001, -7.688378906250009],
              [107.69580078125, -7.635546875],
              [107.59785156250001, -7.566699218750003],
              [107.546875, -7.541894531250009],
              [107.28496093749999, -7.4716796875],
              [107.07119140625002, -7.447460937500011],
              [106.63144531250003, -7.41552734375],
              [106.53535156250001, -7.394238281250011],
              [106.45527343750001, -7.36865234375],
              [106.41132812500001, -7.311718750000011],
              [106.41689453125002, -7.239355468750006],
              [106.44843750000001, -7.1767578125],
              [106.49150390624999, -7.113867187500006],
              [106.51972656250001, -7.0537109375],
              [106.1982421875, -6.927832031250006],
              [105.9443359375, -6.858984375],
              [105.83476562499999, -6.845800781250006],
              [105.72480468750001, -6.84609375],
              [105.60097656250002, -6.8603515625],
              [105.47841796875002, -6.853710937500011],
              [105.42080078125002, -6.833203125000011],
              [105.36191406250003, -6.826171875],
              [105.30292968750001, -6.841015625000011],
              [105.25546875000003, -6.835253906250003],
              [105.2431640625, -6.778027343750011],
              [105.2734375, -6.729394531250009],
              [105.33564453125001, -6.674121093750003],
              [105.37089843749999, -6.664355468750003],
              [105.38701171874999, -6.75078125],
              [105.40468750000002, -6.76796875],
              [105.45976562499999, -6.786914062500003],
              [105.48369140624999, -6.781542968750003],
              [105.58085937499999, -6.670996093750006],
              [105.60800781250003, -6.61669921875],
              [105.65507812499999, -6.46953125],
              [105.7060546875, -6.497949218750009],
              [105.75742187500003, -6.480371093750009],
              [105.78691406249999, -6.456933593750009],
              [105.86826171875003, -6.116406250000011],
              [105.93613281250003, -6.016992187500009],
              [106.02880859375, -5.934277343750011],
              [106.075, -5.914160156250006],
              [106.16582031249999, -5.964746093750009],
              [106.34970703125003, -5.984082031250011],
              [106.45908203125003, -6.017578125],
              [106.56875, -6.021875],
              [106.67587890625003, -6.038378906250003],
              [106.8251953125, -6.098242187500006],
              [106.8779296875, -6.091992187500011],
              [106.931640625, -6.073437500000011],
              [107.01162109375002, -6.008496093750011],
              [107.04628906250002, -5.904199218750009],
              [107.162109375, -5.957128906250006],
              [107.33183593749999, -5.978125],
              [107.37392578125002, -6.007617187500003],
            ],
          ],
          [
            [
              [109.62890625, 2.027539062499997],
              [109.53896484375002, 1.896191406249997],
              [109.54892578125003, 1.848339843749997],
              [109.57080078125, 1.806298828124994],
              [109.63583984375003, 1.776660156249989],
              [109.65400390625001, 1.614892578124994],
              [109.73574218750002, 1.52294921875],
              [109.81806640625001, 1.43896484375],
              [109.87851562500003, 1.397851562499994],
              [109.94492187500003, 1.338037109374994],
              [109.99169921875, 1.282568359374991],
              [110.04082031249999, 1.235742187499994],
              [110.11474609375, 1.190136718749997],
              [110.31523437499999, 0.995996093749994],
              [110.39902343750003, 0.9390625],
              [110.46142578125, 0.882080078125],
              [110.50576171875002, 0.861962890624994],
              [110.61474609375, 0.878125],
              [110.93808593750003, 1.017333984375],
              [110.99609375, 1.0263671875],
              [111.10136718749999, 1.050537109375],
              [111.28671875000003, 1.043212890625],
              [111.48320312499999, 0.995751953124994],
              [111.54667968749999, 0.994335937499997],
              [111.607421875, 1.022607421874994],
              [111.69130859375002, 1.014208984374989],
              [111.76972656250001, 0.999462890624997],
              [111.80898437500002, 1.011669921874997],
              [111.92314453124999, 1.11328125],
              [112.07851562500002, 1.143359374999989],
              [112.12861328125001, 1.243603515624997],
              [112.16738281250002, 1.338183593749989],
              [112.18574218750001, 1.4390625],
              [112.25068359375001, 1.479638671874994],
              [112.34160156249999, 1.514746093749991],
              [112.47617187500003, 1.55908203125],
              [112.94296875000003, 1.566992187499991],
              [112.98828125, 1.547558593749997],
              [112.998046875, 1.496240234374994],
              [112.98828125, 1.457128906249991],
              [113.00654296875001, 1.433886718749989],
              [113.06865234374999, 1.431787109374994],
              [113.12626953124999, 1.408105468749994],
              [113.35898437500003, 1.3271484375],
              [113.45820312500001, 1.302148437499994],
              [113.51318359375, 1.308398437499989],
              [113.62226562500001, 1.2359375],
              [113.681640625, 1.260595703124991],
              [113.76035156250003, 1.311376953124991],
              [113.83525390624999, 1.3798828125],
              [113.90234375, 1.434277343749997],
              [114, 1.455273437499997],
              [114.1259765625, 1.45234375],
              [114.27470703124999, 1.470898437499997],
              [114.38710937500002, 1.500048828124989],
              [114.5125, 1.452001953124991],
              [114.5458984375, 1.467138671874991],
              [114.56748046875003, 1.51416015625],
              [114.63222656250002, 1.617041015624991],
              [114.66093749999999, 1.686279296875],
              [114.68613281250003, 1.819042968749997],
              [114.70351562500002, 1.85078125],
              [114.75107421875003, 1.868994140624991],
              [114.8, 1.893945312499994],
              [114.81269531250001, 1.933789062499997],
              [114.83056640625, 1.980029296874989],
              [114.81582031250002, 2.018945312499994],
              [114.78798828125002, 2.051611328124991],
              [114.75869140625002, 2.162402343749989],
              [114.76835937499999, 2.212939453124989],
              [114.78642578124999, 2.25048828125],
              [114.83632812500002, 2.269384765624991],
              [114.96914062500002, 2.350830078125],
              [115.08652343750003, 2.446142578124991],
              [115.15078125000002, 2.492919921875],
              [115.17910156250002, 2.523193359375],
              [115.18085937500001, 2.56689453125],
              [115.1298828125, 2.612402343749991],
              [115.08076171875001, 2.634228515624997],
              [115.07705078125002, 2.68701171875],
              [115.07890624999999, 2.723437499999989],
              [115.09365234375002, 2.7578125],
              [115.08652343750003, 2.791210937499997],
              [115.08632812500002, 2.841113281249989],
              [115.11757812500002, 2.894873046874991],
              [115.18994140625, 2.974462890624991],
              [115.24697265625002, 3.025927734374989],
              [115.31015625000003, 2.993945312499989],
              [115.38417968750002, 3.008740234374997],
              [115.45439453124999, 3.034326171874994],
              [115.4931640625, 3.128125],
              [115.49912109375003, 3.173144531249989],
              [115.48974609375, 3.208642578124994],
              [115.51425781250003, 3.342382812499991],
              [115.51992187500002, 3.361669921874991],
              [115.56611328125001, 3.445751953124997],
              [115.57070312500002, 3.502294921874991],
              [115.54453125000003, 3.633691406249994],
              [115.56093750000002, 3.733056640624994],
              [115.56845703125003, 3.938769531249989],
              [115.59609375000002, 3.975537109374997],
              [115.62753906250003, 4.081982421874997],
              [115.67880859375003, 4.193017578124994],
              [115.78242187500001, 4.253759765624991],
              [115.83681640625002, 4.333300781249989],
              [115.86074218750002, 4.348046875],
              [115.89619140625001, 4.348681640624989],
              [116.02158203125003, 4.290673828124994],
              [116.13447265625001, 4.355175781249997],
              [116.23623046875002, 4.362548828125],
              [116.3203125, 4.353710937499997],
              [116.36767578125, 4.32734375],
              [116.41455078125, 4.308203125],
              [116.51474609375003, 4.370800781249997],
              [116.553125, 4.35986328125],
              [116.58906250000001, 4.338427734374989],
              [116.638671875, 4.339111328125],
              [116.69785156250003, 4.35498046875],
              [116.84355468749999, 4.340136718749989],
              [117.1005859375, 4.337060546874994],
              [117.27753906250001, 4.29931640625],
              [117.45087890625001, 4.19287109375],
              [117.53730468750001, 4.17138671875],
              [117.57441406250001, 4.170605468749997],
              [117.56621093749999, 4.162304687499997],
              [117.49746093750002, 4.133398437499991],
              [117.46533203125, 4.076074218749994],
              [117.559375, 3.988330078124989],
              [117.56601562500003, 3.929931640625],
              [117.63906250000002, 3.877978515624989],
              [117.72822265625001, 3.796728515624991],
              [117.73173828124999, 3.770263671875],
              [117.76201171874999, 3.73388671875],
              [117.77724609375002, 3.689257812499989],
              [117.71445312500003, 3.644824218749989],
              [117.6298828125, 3.636328125],
              [117.5673828125, 3.678271484374989],
              [117.50966796875002, 3.730371093749994],
              [117.49492187499999, 3.665576171874989],
              [117.45039062500001, 3.628515625],
              [117.28789062499999, 3.639306640624994],
              [117.17158203125001, 3.638964843749989],
              [117.05595703124999, 3.62265625],
              [117.11386718750003, 3.612646484374991],
              [117.16640625000002, 3.591992187499997],
              [117.34628906250003, 3.426611328124991],
              [117.38466796875002, 3.365380859374994],
              [117.321875, 3.243554687499994],
              [117.35244140625002, 3.19375],
              [117.42207031250001, 3.165185546874994],
              [117.5068359375, 3.104589843749991],
              [117.56718749999999, 3.098486328124991],
              [117.61064453124999, 3.064355468749994],
              [117.61240234375003, 3.0048828125],
              [117.63789062500001, 2.950830078124994],
              [117.56914062499999, 2.929296875],
              [117.63720703125, 2.914941406249994],
              [117.69765625000002, 2.887304687499991],
              [117.66455078125, 2.859277343749994],
              [117.63886718750001, 2.825292968749991],
              [117.66679687499999, 2.806933593749989],
              [117.74970703125001, 2.775585937499997],
              [117.78593749999999, 2.746777343749997],
              [117.80488281250001, 2.6689453125],
              [117.8857421875, 2.541748046875],
              [118.0341796875, 2.377636718749997],
              [118.06660156250001, 2.317822265624997],
              [118.06630859375002, 2.262744140624989],
              [118.04160156250003, 2.215429687499991],
              [117.95703125, 2.159960937499989],
              [117.88925781250003, 2.087011718749991],
              [117.88105468750001, 2.060644531249991],
              [117.78925781250001, 2.02685546875],
              [117.83125, 2.002001953124989],
              [117.86464843750002, 1.968408203124994],
              [117.92841796875001, 1.866796875],
              [118.08037109374999, 1.701855468749997],
              [118.15683593750003, 1.640332031249997],
              [118.4716796875, 1.416455078124997],
              [118.63896484374999, 1.318994140624994],
              [118.8525390625, 1.095849609374994],
              [118.96347656250003, 1.044287109374991],
              [118.98496093750003, 0.982128906249997],
              [118.89238281249999, 0.886865234374994],
              [118.75742187500003, 0.839208984374991],
              [118.53476562500003, 0.813525390624989],
              [118.3115234375, 0.847070312499994],
              [118.19609374999999, 0.874365234374991],
              [118.09550781249999, 0.929150390624997],
              [118.01630859375001, 1.039160156249991],
              [117.91162109375, 1.098681640624989],
              [117.95195312499999, 1.031982421875],
              [117.97734374999999, 0.963818359374997],
              [117.96425781250002, 0.889550781249994],
              [117.92304687500001, 0.831347656249989],
              [117.8525390625, 0.788671875],
              [117.77695312500003, 0.754003906249991],
              [117.7451171875, 0.729638671874994],
              [117.55332031250003, 0.341015625],
              [117.52216796875001, 0.235888671874989],
              [117.46376953125002, -0.200488281250003],
              [117.462890625, -0.32373046875],
              [117.54892578125003, -0.554394531250011],
              [117.55683593750001, -0.67529296875],
              [117.57382812500003, -0.7275390625],
              [117.5625, -0.770898437500009],
              [117.52177734374999, -0.796679687500003],
              [117.35712890625001, -0.8671875],
              [117.24072265625, -0.925683593750009],
              [117.146484375, -1.008984375000011],
              [117.07021484375002, -1.112695312500009],
              [117.00322265624999, -1.187695312500011],
              [116.91396484375002, -1.2236328125],
              [116.84941406249999, -1.21826171875],
              [116.79707031250001, -1.183789062500011],
              [116.76054687499999, -1.1171875],
              [116.73984375000003, -1.044238281250003],
              [116.72617187500003, -1.09814453125],
              [116.72871093750001, -1.15078125],
              [116.75927734375, -1.207128906250006],
              [116.77099609375, -1.2666015625],
              [116.75341796875, -1.327343750000011],
              [116.71523437500002, -1.37578125],
              [116.61162109374999, -1.428613281250009],
              [116.55449218749999, -1.473925781250003],
              [116.54511718750001, -1.553125],
              [116.517578125, -1.598046875],
              [116.47792968750002, -1.6328125],
              [116.33212890625003, -1.7125],
              [116.29960937499999, -1.744335937500011],
              [116.27548828125003, -1.784863281250011],
              [116.35322265625001, -1.778613281250003],
              [116.42431640625, -1.784863281250011],
              [116.42958984375002, -1.864160156250009],
              [116.45195312499999, -1.923144531250003],
              [116.42353515625001, -2.052539062500003],
              [116.31396484375, -2.139843750000011],
              [116.36865234375, -2.158203125],
              [116.41816406250001, -2.186718750000011],
              [116.528125, -2.207910156250009],
              [116.5654296875, -2.299707031250009],
              [116.54921875000002, -2.410839843750011],
              [116.529296875, -2.510546875],
              [116.45039062500001, -2.538281250000011],
              [116.40126953125002, -2.519824218750003],
              [116.3525390625, -2.521582031250006],
              [116.31679687500002, -2.551855468750006],
              [116.30722656250003, -2.603320312500003],
              [116.37548828125, -2.578027343750009],
              [116.37167968750003, -2.706835937500003],
              [116.35322265625001, -2.832714843750011],
              [116.33066406250003, -2.902148437500003],
              [116.28886718749999, -2.958789062500003],
              [116.22578125000001, -2.976953125],
              [116.16630859374999, -2.9345703125],
              [116.15410156249999, -2.983789062500009],
              [116.17226562500002, -3.025292968750009],
              [116.25722656250002, -3.126367187500009],
              [116.205078125, -3.148535156250006],
              [116.16708984375003, -3.183007812500009],
              [116.15, -3.233203125],
              [116.05751953125002, -3.348242187500006],
              [116.01669921875003, -3.432812500000011],
              [115.99941406250002, -3.523339843750009],
              [115.95615234375003, -3.595019531250003],
              [115.25820312500002, -3.906835937500006],
              [114.69355468750001, -4.169726562500003],
              [114.65253906250001, -4.15185546875],
              [114.62529296874999, -4.11171875],
              [114.60595703125, -3.703320312500011],
              [114.5361328125, -3.494433593750003],
              [114.52558593750001, -3.376660156250011],
              [114.44599609375001, -3.481835937500009],
              [114.39716796875001, -3.47119140625],
              [114.34433593750003, -3.444433593750006],
              [114.30458984375002, -3.410058593750009],
              [114.30166015625002, -3.36474609375],
              [114.34433593750003, -3.23515625],
              [114.29267578125001, -3.30625],
              [114.236328125, -3.361132812500003],
              [114.17792968750001, -3.354394531250009],
              [114.12763671875001, -3.327246093750006],
              [114.10898437500003, -3.28515625],
              [114.08222656250001, -3.27890625],
              [113.95878906249999, -3.394335937500003],
              [113.79580078125002, -3.45625],
              [113.705078125, -3.455273437500011],
              [113.63359374999999, -3.419921875],
              [113.63730468750003, -3.33203125],
              [113.63007812500001, -3.24609375],
              [113.61005859375001, -3.195703125],
              [113.56630859375002, -3.177734375],
              [113.52597656250003, -3.18408203125],
              [113.40898437499999, -3.22890625],
              [113.3671875, -3.2236328125],
              [113.34316406250002, -3.246484375],
              [113.03398437499999, -2.933496093750009],
              [112.97148437499999, -3.187109375],
              [112.75800781250001, -3.322167968750009],
              [112.60029296875001, -3.400488281250006],
              [112.44394531250003, -3.37109375],
              [112.28496093749999, -3.320996093750011],
              [112.12666015625001, -3.381445312500006],
              [111.95488281249999, -3.5296875],
              [111.90742187500001, -3.552539062500003],
              [111.85810546875001, -3.551855468750006],
              [111.82207031249999, -3.532519531250003],
              [111.834375, -3.420117187500011],
              [111.8359375, -3.307714843750006],
              [111.82304687499999, -3.057226562500006],
              [111.809375, -3.008007812500011],
              [111.76015625000002, -2.939160156250011],
              [111.69472656250002, -2.889453125],
              [111.65830078125003, -2.92578125],
              [111.62548828125, -2.975488281250009],
              [111.49492187499999, -2.973339843750011],
              [111.36757812500002, -2.933691406250006],
              [111.25917968750002, -2.956445312500009],
              [111.04433593750002, -3.055761718750006],
              [110.93007812500002, -3.07109375],
              [110.86875, -3.048730468750009],
              [110.82968750000003, -2.9951171875],
              [110.85205078125, -2.946191406250009],
              [110.89931640625002, -2.90859375],
              [110.81113281250003, -2.9384765625],
              [110.73583984375, -2.988671875],
              [110.703125, -3.020898437500009],
              [110.66816406250001, -3.004785156250009],
              [110.57402343749999, -2.89140625],
              [110.37753906250003, -2.933789062500011],
              [110.35097656250002, -2.94677734375],
              [110.30253906249999, -2.9853515625],
              [110.25605468750001, -2.966113281250003],
              [110.23261718750001, -2.925097656250003],
              [110.22431640625001, -2.688671875000011],
              [110.12441406250002, -2.23388671875],
              [110.09658203125002, -2.001367187500009],
              [110.075, -1.946386718750006],
              [109.95986328125002, -1.86279296875],
              [109.96376953125002, -1.742871093750011],
              [110.0234375, -1.642578125],
              [110.0361328125, -1.525683593750003],
              [110.01923828125001, -1.398828125],
              [109.98330078125002, -1.274804687500009],
              [109.93808593750003, -1.18115234375],
              [109.87343750000002, -1.10107421875],
              [109.78740234374999, -1.011328125],
              [109.68173828125003, -0.944238281250009],
              [109.45380859375001, -0.86875],
              [109.33349609375, -0.875390625],
              [109.28886718749999, -0.845800781250006],
              [109.2587890625, -0.807421875],
              [109.27099609375, -0.73203125],
              [109.31171875000001, -0.68017578125],
              [109.36630859375003, -0.667382812500009],
              [109.37275390625001, -0.63818359375],
              [109.25703125000001, -0.577441406250003],
              [109.16054687500002, -0.494921875],
              [109.13027343750002, -0.445410156250006],
              [109.12109375, -0.390917968750003],
              [109.12177734375001, -0.265039062500009],
              [109.14960937500001, -0.185546875],
              [109.16474609375001, -0.142480468750009],
              [109.19462890624999, -0.009423828125009],
              [109.25751953125001, 0.031152343749994],
              [109.24726562500001, 0.055761718749991],
              [109.22021484375, 0.073828125],
              [109.18076171875003, 0.117480468749989],
              [109.14853515625003, 0.167675781249997],
              [109.07480468750003, 0.252832031249994],
              [108.94453125000001, 0.355664062499997],
              [108.92275390625002, 0.5328125],
              [108.90585937500003, 0.7939453125],
              [108.91679687499999, 0.912646484374989],
              [108.95859375000003, 1.134619140624991],
              [109.03085937500003, 1.204492187499994],
              [109.08847656250003, 1.223925781249989],
              [109.13154296875001, 1.253857421874997],
              [109.09609375000002, 1.258154296874991],
              [109.0654296875, 1.247167968749991],
              [109.01025390625, 1.239648437499994],
              [109.05546874999999, 1.4384765625],
              [109.07587890625001, 1.495898437499989],
              [109.16669921875001, 1.607080078124994],
              [109.27314453125001, 1.70546875],
              [109.31816406249999, 1.821093749999989],
              [109.37851562500003, 1.922705078124991],
              [109.62890625, 2.027539062499997],
            ],
          ],
          [
            [
              [127.73271484375005, 0.84814453125],
              [127.80537109375001, 0.825927734375],
              [127.88105468750001, 0.832128906249991],
              [127.91865234375001, 0.876806640624991],
              [127.92910156250002, 0.934716796874994],
              [127.96728515625, 1.042578125],
              [128.05527343750003, 1.115625],
              [128.11699218750005, 1.127050781249991],
              [128.16074218750003, 1.1578125],
              [128.153125, 1.237890625],
              [128.157421875, 1.316601562499997],
              [128.22246093750005, 1.400634765625],
              [128.42412109375005, 1.517529296874997],
              [128.5392578125, 1.559228515624994],
              [128.68837890625002, 1.572558593749989],
              [128.70517578125003, 1.527734375],
              [128.68808593750003, 1.463720703124991],
              [128.71689453125003, 1.367285156249991],
              [128.70263671875, 1.106396484374997],
              [128.66875, 1.069433593749991],
              [128.51455078125002, 0.979248046875],
              [128.34599609375005, 0.907128906249994],
              [128.298828125, 0.876806640624991],
              [128.25722656250002, 0.804980468749989],
              [128.26064453125002, 0.733789062499994],
              [128.39794921875, 0.638818359374994],
              [128.61123046875002, 0.549951171874994],
              [128.6552734375, 0.508251953124997],
              [128.6837890625, 0.4384765625],
              [128.6916015625, 0.3603515625],
              [128.74326171875003, 0.3232421875],
              [128.8154296875, 0.305371093749997],
              [128.86328125, 0.268359374999989],
              [128.899609375, 0.216259765624997],
              [128.54042968750002, 0.337890625],
              [128.446484375, 0.391552734374997],
              [128.33281250000005, 0.39794921875],
              [128.22060546875002, 0.414257812499997],
              [128.10605468750003, 0.460888671874997],
              [127.98310546875001, 0.471875],
              [127.92441406250003, 0.438085937499991],
              [127.9013671875, 0.372265625],
              [127.88740234375001, 0.29833984375],
              [127.91464843750003, 0.206298828125],
              [127.91220703125003, 0.150537109374994],
              [127.88896484375005, 0.049511718749997],
              [127.97783203125005, -0.248339843750003],
              [128.08945312500003, -0.485253906250009],
              [128.25351562500003, -0.731640625000011],
              [128.33457031250003, -0.816308593750009],
              [128.42548828125, -0.892675781250006],
              [128.278125, -0.870019531250009],
              [128.2333984375, -0.787695312500006],
              [128.04638671875, -0.7060546875],
              [128.01083984375003, -0.657324218750006],
              [127.88896484375005, -0.423535156250011],
              [127.85332031250005, -0.3798828125],
              [127.74082031250003, -0.300390625],
              [127.69160156250001, -0.241894531250011],
              [127.6748046875, -0.162890625],
              [127.68740234375002, -0.079931640625006],
              [127.68134765625001, 0.034863281249997],
              [127.68544921875002, 0.149023437499991],
              [127.70869140625001, 0.2880859375],
              [127.66865234375001, 0.336767578124991],
              [127.6162109375, 0.382910156249991],
              [127.55537109375001, 0.489648437499994],
              [127.537109375, 0.610888671874989],
              [127.54179687500005, 0.6806640625],
              [127.56699218750003, 0.742529296874991],
              [127.60068359375003, 0.796044921874994],
              [127.60800781250003, 0.848242187499991],
              [127.52041015625002, 0.924023437499997],
              [127.42851562500005, 1.139990234374991],
              [127.42031250000002, 1.251953125],
              [127.537109375, 1.467480468749997],
              [127.53466796875, 1.572070312499989],
              [127.55791015625005, 1.634228515624997],
              [127.57070312500002, 1.700146484374997],
              [127.63173828125002, 1.843701171874997],
              [127.7314453125, 1.966113281249989],
              [127.89990234375, 2.137353515624994],
              [127.96425781250002, 2.174707031249994],
              [128.03642578125005, 2.199023437499989],
              [128.04277343750005, 2.157080078124991],
              [128.03125, 2.119873046875],
              [127.90673828125, 1.945654296874991],
              [127.89013671875, 1.906298828124989],
              [127.88681640625003, 1.832958984374997],
              [127.94648437500001, 1.789648437499991],
              [128.0109375, 1.701220703124989],
              [128.02373046875005, 1.58349609375],
              [128.02587890625, 1.458105468749991],
              [128.01171875, 1.331738281249997],
              [127.98769531250002, 1.289599609374989],
              [127.88535156250003, 1.162792968749997],
              [127.65283203125, 1.013867187499997],
              [127.63300781250001, 0.977197265624994],
              [127.634375, 0.936132812499991],
              [127.67744140625001, 0.886572265624991],
              [127.73271484375005, 0.84814453125],
            ],
          ],
          [
            [
              [129.75468750000005, -2.865820312500006],
              [129.984375, -2.976660156250006],
              [130.10341796875002, -2.99296875],
              [130.30361328125002, -2.978515625],
              [130.3791015625, -2.989355468750006],
              [130.56992187500003, -3.130859375],
              [130.62558593750003, -3.22802734375],
              [130.64169921875003, -3.311914062500009],
              [130.67109375, -3.391503906250009],
              [130.71806640625005, -3.411328125000011],
              [130.7734375, -3.41875],
              [130.84560546875002, -3.533300781250006],
              [130.85996093750003, -3.5703125],
              [130.80507812500002, -3.857714843750003],
              [130.58037109375005, -3.748828125],
              [130.36308593750005, -3.625195312500011],
              [130.2697265625, -3.579296875000011],
              [130.01953125, -3.474707031250006],
              [129.98115234375, -3.438867187500009],
              [129.953125, -3.3916015625],
              [129.84414062500002, -3.3271484375],
              [129.62666015625, -3.3171875],
              [129.54501953125003, -3.31884765625],
              [129.51171875, -3.328515625],
              [129.52041015625002, -3.363183593750009],
              [129.5216796875, -3.433691406250006],
              [129.46767578125002, -3.453222656250006],
              [129.33281250000005, -3.40869140625],
              [129.212109375, -3.392675781250006],
              [129.1076171875, -3.34921875],
              [128.96748046875, -3.326074218750009],
              [128.95205078125002, -3.30419921875],
              [128.9640625, -3.271679687500011],
              [128.95781250000005, -3.241113281250009],
              [128.92539062500003, -3.229296875],
              [128.8625, -3.234960937500006],
              [128.8017578125, -3.265625],
              [128.75126953125005, -3.300488281250011],
              [128.676953125, -3.396582031250006],
              [128.63896484375005, -3.433398437500003],
              [128.5166015625, -3.449121093750009],
              [128.46591796875003, -3.43984375],
              [128.41923828125005, -3.416015625],
              [128.27998046875, -3.240527343750003],
              [128.23300781250003, -3.20263671875],
              [128.1806640625, -3.171679687500003],
              [128.13203125, -3.157421875000011],
              [128.08212890625003, -3.18408203125],
              [128.05576171875003, -3.238574218750003],
              [128.0439453125, -3.303320312500006],
              [128.03007812500005, -3.340527343750011],
              [127.97001953125005, -3.4443359375],
              [127.92041015625, -3.506054687500011],
              [127.90234375, -3.496289062500011],
              [127.92783203125003, -3.397265625],
              [127.92792968750001, -3.34140625],
              [127.89716796875001, -3.282324218750006],
              [127.8779296875, -3.222070312500009],
              [128.11337890625003, -2.9345703125],
              [128.19853515625005, -2.865917968750011],
              [128.56982421875, -2.8421875],
              [128.79052734375, -2.856640625000011],
              [128.91074218750003, -2.849609375],
              [128.99111328125002, -2.828515625],
              [129.05771484375003, -2.838476562500006],
              [129.07431640625003, -2.895117187500006],
              [129.11630859375003, -2.93701171875],
              [129.17441406250003, -2.933496093750009],
              [129.27958984375005, -2.8890625],
              [129.37109375, -2.820507812500011],
              [129.42734375000003, -2.790722656250011],
              [129.48417968750005, -2.785742187500006],
              [129.54296875, -2.790332031250003],
              [129.60048828125002, -2.80615234375],
              [129.75468750000005, -2.865820312500006],
            ],
          ],
          [
            [
              [126.86113281249999, -3.087890625],
              [127.02548828125003, -3.166015625],
              [127.06289062500002, -3.216992187500011],
              [127.09238281250003, -3.277539062500011],
              [127.12470703125001, -3.310839843750003],
              [127.16347656250002, -3.338085937500011],
              [127.22734375000005, -3.391015625],
              [127.24423828125003, -3.47109375],
              [127.22958984375003, -3.633007812500011],
              [127.15517578125002, -3.647265625],
              [127.08505859375003, -3.6708984375],
              [126.94091796875, -3.764550781250009],
              [126.86992187499999, -3.782910156250011],
              [126.79414062500001, -3.789160156250006],
              [126.74033203125003, -3.813671875000011],
              [126.68632812499999, -3.823632812500009],
              [126.54667968749999, -3.771679687500011],
              [126.4111328125, -3.710644531250011],
              [126.21455078125001, -3.605175781250011],
              [126.17832031250003, -3.579394531250003],
              [126.14667968750001, -3.522753906250003],
              [126.05654296875002, -3.420996093750006],
              [126.03398437499999, -3.355859375],
              [126.02646484375003, -3.170507812500006],
              [126.05009765624999, -3.128125],
              [126.08828125000002, -3.10546875],
              [126.21962890625002, -3.148144531250011],
              [126.30625, -3.103222656250011],
              [126.55507812500002, -3.065234375],
              [126.80830078125001, -3.069140625],
              [126.86113281249999, -3.087890625],
            ],
          ],
          [
            [
              [124.92226562500002, -8.942480468750006],
              [124.9150390625, -9.031542968750003],
              [124.93681640624999, -9.053417968750011],
              [124.97324218750003, -9.064257812500003],
              [125.10039062499999, -9.004003906250006],
              [125.12441406250002, -9.015429687500003],
              [125.14902343750003, -9.042578125],
              [125.1494140625, -9.122949218750009],
              [125.10048828125002, -9.18984375],
              [124.9775390625, -9.194921875],
              [124.96015625000001, -9.213769531250009],
              [124.95859375000003, -9.2546875],
              [124.96826171875, -9.294238281250003],
              [124.99697265625002, -9.325976562500003],
              [125.03359375000002, -9.3818359375],
              [125.06816406249999, -9.511914062500011],
              [124.99794921875002, -9.565332031250009],
              [124.96308593750001, -9.665625],
              [124.841796875, -9.759765625],
              [124.70839843750002, -9.914160156250006],
              [124.60185546874999, -9.99296875],
              [124.50820312500002, -10.086132812500011],
              [124.42753906249999, -10.148632812500011],
              [124.32675781250003, -10.169824218750009],
              [124.17597656250001, -10.183300781250011],
              [123.97109375000002, -10.294824218750009],
              [123.85761718750001, -10.343554687500003],
              [123.74726562500001, -10.34716796875],
              [123.64414062500003, -10.3109375],
              [123.60478515624999, -10.270117187500006],
              [123.61406249999999, -10.215039062500011],
              [123.64824218749999, -10.167773437500003],
              [123.69013671875001, -10.128808593750009],
              [123.71640625000003, -10.07861328125],
              [123.59941406249999, -10.01513671875],
              [123.58925781250002, -9.966796875],
              [123.6357421875, -9.838085937500011],
              [123.66582031249999, -9.705273437500011],
              [123.709375, -9.61484375],
              [123.87675781249999, -9.453125],
              [123.97714843750003, -9.372949218750009],
              [124.03632812500001, -9.341601562500003],
              [124.05244140625001, -9.375390625],
              [124.09013671874999, -9.41640625],
              [124.11552734374999, -9.423144531250003],
              [124.13457031249999, -9.413867187500003],
              [124.28232421875003, -9.427929687500011],
              [124.3193359375, -9.413769531250011],
              [124.37568359375001, -9.349902343750003],
              [124.41298828125002, -9.314355468750009],
              [124.43828124999999, -9.238574218750003],
              [124.44443359375003, -9.190332031250009],
              [124.57548828124999, -9.155371093750006],
              [124.64589843750002, -9.11669921875],
              [124.70820312500001, -9.061816406250003],
              [124.88974609375003, -8.968457031250011],
              [124.92226562500002, -8.942480468750006],
            ],
          ],
          [
            [
              [134.74697265625002, -5.70703125],
              [134.73906250000005, -5.74560546875],
              [134.73837890625003, -5.816796875],
              [134.75498046875003, -5.882714843750009],
              [134.71220703125005, -5.94970703125],
              [134.7521484375, -6.050097656250003],
              [134.75810546875005, -6.1],
              [134.755859375, -6.170605468750011],
              [134.74443359375005, -6.202343750000011],
              [134.71416015625005, -6.295117187500011],
              [134.68388671875005, -6.328125],
              [134.6611328125, -6.337304687500009],
              [134.63759765625002, -6.365332031250006],
              [134.44111328125, -6.334863281250009],
              [134.35615234375, -6.2705078125],
              [134.28046875, -6.20078125],
              [134.26445312500005, -6.171679687500003],
              [134.17539062500003, -6.09033203125],
              [134.15488281250003, -6.062890625],
              [134.153125, -6.01953125],
              [134.22509765625, -6.008496093750011],
              [134.301953125, -6.009765625],
              [134.29863281250005, -5.970703125],
              [134.34306640625005, -5.8330078125],
              [134.22617187500003, -5.744433593750003],
              [134.20537109375005, -5.707226562500011],
              [134.247265625, -5.681933593750003],
              [134.34130859375, -5.712890625],
              [134.45634765625005, -5.557519531250009],
              [134.49033203125003, -5.525097656250011],
              [134.50644531250003, -5.4384765625],
              [134.57080078125, -5.42734375],
              [134.61650390625005, -5.438574218750006],
              [134.64609375000003, -5.492382812500011],
              [134.65781250000003, -5.539257812500011],
              [134.6455078125, -5.581347656250003],
              [134.70078125000003, -5.60302734375],
              [134.74697265625002, -5.70703125],
            ],
          ],
          [
            [
              [134.53681640625, -6.442285156250009],
              [134.52041015625002, -6.5126953125],
              [134.50429687500002, -6.59140625],
              [134.4125, -6.6796875],
              [134.35595703125, -6.81484375],
              [134.32275390625, -6.848730468750006],
              [134.2, -6.908789062500006],
              [134.0908203125, -6.833789062500003],
              [134.05917968750003, -6.769335937500003],
              [134.10703125000003, -6.471582031250009],
              [134.15419921875002, -6.4814453125],
              [134.184765625, -6.479296875],
              [134.19462890625005, -6.459765625],
              [134.12460937500003, -6.426464843750011],
              [134.11123046875002, -6.25537109375],
              [134.11464843750002, -6.190820312500009],
              [134.16806640625003, -6.17626953125],
              [134.23417968750005, -6.226367187500003],
              [134.31777343750002, -6.316113281250011],
              [134.4150390625, -6.38671875],
              [134.53681640625, -6.442285156250009],
            ],
          ],
          [
            [
              [138.5353515625, -8.273632812500011],
              [138.29628906250002, -8.405175781250009],
              [137.98281250000002, -8.381933593750006],
              [137.871875, -8.3796875],
              [137.6876953125, -8.41171875],
              [137.650390625, -8.386132812500009],
              [137.68515625000003, -8.26220703125],
              [137.83251953125, -7.932226562500006],
              [138.00751953125, -7.6416015625],
              [138.08183593750005, -7.566210937500003],
              [138.18535156250005, -7.495312500000011],
              [138.29550781250003, -7.4384765625],
              [138.54384765625002, -7.379589843750011],
              [138.76982421875005, -7.390429687500003],
              [138.801953125, -7.414648437500006],
              [138.8994140625, -7.511621093750009],
              [138.96259765625, -7.587988281250006],
              [138.98906250000005, -7.69609375],
              [138.89296875000002, -7.882128906250003],
              [138.78593750000005, -8.05908203125],
              [138.61171875000002, -8.198339843750006],
              [138.5353515625, -8.273632812500011],
            ],
          ],
          [
            [
              [140.97343750000005, -2.609765625],
              [140.97343750000005, -2.613574218750003],
              [140.97343750000005, -2.681054687500009],
              [140.97353515625002, -2.803417968750011],
              [140.9736328125, -3.006640625],
              [140.97373046875003, -3.2099609375],
              [140.973828125, -3.413281250000011],
              [140.97392578125005, -3.616601562500009],
              [140.97402343750002, -3.81982421875],
              [140.97402343750002, -4.023144531250011],
              [140.97421875000003, -4.226464843750009],
              [140.97421875000003, -4.429785156250006],
              [140.97431640625, -4.633007812500011],
              [140.97441406250005, -4.836328125],
              [140.97451171875002, -5.039648437500006],
              [140.974609375, -5.24296875],
              [140.974609375, -5.446191406250009],
              [140.97470703125003, -5.649511718750006],
              [140.9748046875, -5.852832031250003],
              [140.97490234375005, -6.05615234375],
              [140.975, -6.259375],
              [140.975, -6.34609375],
              [140.94404296875, -6.452246093750006],
              [140.87460937500003, -6.611523437500011],
              [140.8623046875, -6.740039062500003],
              [140.91953125000003, -6.840039062500011],
              [140.97519531250003, -6.905371093750006],
              [140.97519531250003, -7.072558593750003],
              [140.97529296875, -7.27587890625],
              [140.97539062500005, -7.479199218750011],
              [140.97548828125002, -7.682519531250009],
              [140.9755859375, -7.8857421875],
              [140.9755859375, -8.089062500000011],
              [140.97568359375003, -8.292382812500009],
              [140.97578125, -8.495703125],
              [140.97587890625005, -8.698925781250011],
              [140.97597656250002, -8.902246093750009],
              [140.97597656250002, -9.105566406250006],
              [140.97617187500003, -9.11875],
              [140.92460937500005, -9.085058593750006],
              [140.78652343750002, -8.973730468750006],
              [140.66152343750002, -8.846777343750006],
              [140.5810546875, -8.728320312500003],
              [140.48974609375, -8.620410156250003],
              [140.10166015625003, -8.300585937500003],
              [140.0029296875, -8.195507812500011],
              [139.98330078125002, -8.16650390625],
              [139.99257812500002, -8.139355468750011],
              [140.03740234375005, -8.083984375],
              [140.11699218750005, -7.923730468750009],
              [140.03378906250003, -8.022753906250003],
              [139.934765625, -8.101171875],
              [139.79082031250005, -8.106347656250009],
              [139.6494140625, -8.125390625],
              [139.5185546875, -8.172753906250009],
              [139.38564453125002, -8.1890625],
              [139.31914062500005, -8.165820312500003],
              [139.27910156250005, -8.10693359375],
              [139.25830078125, -8.046582031250011],
              [139.24882812500005, -7.982421875],
              [139.19296875000003, -8.086132812500011],
              [139.083203125, -8.142871093750003],
              [138.93349609375002, -8.262402343750011],
              [138.890625, -8.23779296875],
              [138.86474609375, -8.192285156250009],
              [138.85615234375, -8.145117187500006],
              [138.88505859375005, -8.0947265625],
              [138.90546875, -8.041210937500011],
              [138.93593750000002, -7.9130859375],
              [139.00302734375003, -7.837597656250011],
              [139.04570312500005, -7.69140625],
              [139.07363281250002, -7.639257812500006],
              [139.08798828125003, -7.587207031250003],
              [139.04892578125003, -7.5283203125],
              [138.98300781250003, -7.508203125],
              [138.93789062500002, -7.472460937500003],
              [138.88554687500005, -7.373242187500011],
              [138.853125, -7.339648437500003],
              [138.79365234375, -7.298925781250006],
              [138.74794921875002, -7.25146484375],
              [138.79843750000003, -7.215722656250009],
              [138.86484375000003, -7.201367187500011],
              [138.91933593750002, -7.20361328125],
              [139.01796875000002, -7.225878906250003],
              [139.0625, -7.227148437500006],
              [139.17685546875003, -7.1904296875],
              [139.11259765625005, -7.201757812500006],
              [139.0490234375, -7.200585937500009],
              [138.845703125, -7.136328125],
              [138.72001953125005, -7.06982421875],
              [138.60136718750005, -6.9365234375],
              [138.60019531250003, -6.910742187500006],
              [138.6837890625, -6.886523437500003],
              [138.86455078125005, -6.8583984375],
              [138.80849609375002, -6.790429687500009],
              [138.72666015625003, -6.731152343750011],
              [138.69814453125002, -6.625683593750011],
              [138.64218750000003, -6.560449218750009],
              [138.52158203125003, -6.453808593750011],
              [138.438671875, -6.343359375],
              [138.368359375, -6.118554687500009],
              [138.29628906250002, -5.949023437500003],
              [138.31386718750002, -5.8875],
              [138.37460937500003, -5.843652343750009],
              [138.28281250000003, -5.838574218750011],
              [138.19960937500002, -5.80703125],
              [138.24355468750002, -5.724414062500003],
              [138.33964843750005, -5.675683593750009],
              [138.2521484375, -5.688183593750011],
              [138.16650390625, -5.712011718750006],
              [138.12744140625, -5.716503906250011],
              [138.087109375, -5.709179687500011],
              [138.06591796875, -5.675976562500011],
              [138.06308593750003, -5.62890625],
              [138.07558593750002, -5.545800781250009],
              [138.06083984375005, -5.465234375],
              [137.98496093750003, -5.427636718750009],
              [137.92226562500002, -5.3701171875],
              [137.88681640625003, -5.348828125000011],
              [137.84033203125, -5.350488281250009],
              [137.79521484375005, -5.31201171875],
              [137.75908203125005, -5.256152343750003],
              [137.306640625, -5.014355468750011],
              [137.27978515625, -4.945410156250006],
              [137.23789062500003, -4.975683593750006],
              [137.19589843750003, -4.990429687500011],
              [137.14375, -4.95078125],
              [137.08925781250002, -4.924414062500006],
              [137.02968750000002, -4.9287109375],
              [136.974609375, -4.907324218750006],
              [136.9169921875, -4.895117187500006],
              [136.85683593750002, -4.893164062500006],
              [136.61884765625, -4.81875],
              [136.39375, -4.701269531250006],
              [136.21064453125, -4.650683593750003],
              [136.09746093750005, -4.584765625],
              [135.9796875, -4.530859375],
              [135.71660156250005, -4.478417968750009],
              [135.4501953125, -4.443066406250011],
              [135.35390625000002, -4.441796875],
              [135.27314453125, -4.453125],
              [135.19560546875005, -4.45068359375],
              [134.75419921875005, -4.195410156250006],
              [134.6796875, -4.0791015625],
              [134.68691406250002, -4.011132812500009],
              [134.70654296875, -3.954785156250011],
              [134.88652343750005, -3.9384765625],
              [134.759765625, -3.922167968750003],
              [134.70761718750003, -3.929882812500011],
              [134.60341796875002, -3.97607421875],
              [134.546875, -3.979296875],
              [134.46718750000002, -3.948632812500009],
              [134.39101562500002, -3.909960937500003],
              [134.26621093750003, -3.94580078125],
              [134.20234375, -3.887011718750003],
              [134.18046875000005, -3.825097656250009],
              [134.14707031250003, -3.796777343750009],
              [134.1, -3.799707031250009],
              [134.03691406250005, -3.821972656250011],
              [133.973828125, -3.81796875],
              [133.93320312500003, -3.775585937500011],
              [133.90400390625, -3.720117187500009],
              [133.86074218750002, -3.680371093750011],
              [133.80849609375002, -3.65],
              [133.72304687500002, -3.577929687500003],
              [133.67832031250003, -3.4794921875],
              [133.68339843750005, -3.309179687500006],
              [133.69716796875002, -3.248144531250006],
              [133.78164062500002, -3.14892578125],
              [133.84150390625, -3.054785156250006],
              [133.76738281250005, -3.044335937500009],
              [133.700390625, -3.0875],
              [133.67197265625003, -3.1318359375],
              [133.66074218750003, -3.185546875],
              [133.653125, -3.364355468750006],
              [133.59941406250005, -3.416113281250006],
              [133.51816406250003, -3.411914062500003],
              [133.54228515625005, -3.51640625],
              [133.50917968750002, -3.615527343750003],
              [133.41513671875003, -3.732128906250011],
              [133.4072265625, -3.78515625],
              [133.42226562500002, -3.842578125],
              [133.40087890625, -3.899023437500006],
              [133.24873046875, -4.062304687500003],
              [133.19804687500005, -4.070117187500003],
              [133.08515625, -4.069042968750011],
              [132.96855468750005, -4.094921875000011],
              [132.91445312500002, -4.056933593750003],
              [132.8701171875, -4.007421875],
              [132.837109375, -3.948925781250011],
              [132.79091796875002, -3.828125],
              [132.75390625, -3.70361328125],
              [132.86972656250003, -3.550976562500011],
              [132.82978515625, -3.412988281250009],
              [132.75136718750002, -3.294628906250011],
              [132.55351562500005, -3.130664062500003],
              [132.34824218750003, -2.97509765625],
              [132.25498046875003, -2.943457031250006],
              [132.10205078125, -2.929589843750009],
              [132.05390625, -2.91455078125],
              [132.00634765625, -2.856054687500006],
              [131.97119140625, -2.78857421875],
              [132.06689453125, -2.759570312500003],
              [132.2306640625, -2.680371093750011],
              [132.32333984375003, -2.684179687500006],
              [132.57548828125005, -2.727148437500006],
              [132.65292968750003, -2.766210937500006],
              [132.725, -2.7890625],
              [132.89726562500005, -2.658203125],
              [133.03378906250003, -2.487402343750006],
              [133.11884765625, -2.450292968750006],
              [133.19101562500003, -2.437792968750003],
              [133.26494140625005, -2.454296875000011],
              [133.41142578125005, -2.513964843750003],
              [133.5265625, -2.541699218750011],
              [133.60869140625005, -2.547167968750003],
              [133.6515625, -2.6005859375],
              [133.70009765625002, -2.624609375],
              [133.7109375, -2.544042968750006],
              [133.75332031250002, -2.45068359375],
              [133.83466796875, -2.421679687500003],
              [133.87763671875, -2.4150390625],
              [133.90488281250003, -2.390917968750003],
              [133.89892578125, -2.304492187500003],
              [133.791015625, -2.293652343750011],
              [133.84970703125003, -2.219628906250009],
              [133.90244140625003, -2.18359375],
              [133.92050781250003, -2.1474609375],
              [133.92158203125, -2.10205078125],
              [133.71035156250002, -2.189160156250011],
              [133.48779296875, -2.2255859375],
              [133.35625, -2.215722656250009],
              [133.22490234375005, -2.214453125],
              [132.96279296875002, -2.272558593750006],
              [132.86328125, -2.270214843750011],
              [132.6310546875, -2.246679687500006],
              [132.50263671875, -2.218457031250011],
              [132.4033203125, -2.240429687500011],
              [132.3076171875, -2.242285156250006],
              [132.20742187500002, -2.17578125],
              [132.12216796875003, -2.092382812500006],
              [132.07988281250005, -2.033203125],
              [132.0234375, -1.990332031250006],
              [131.99843750000002, -1.932519531250009],
              [131.93613281250003, -1.714941406250006],
              [131.93037109375, -1.559667968750006],
              [131.82978515625, -1.556542968750009],
              [131.7314453125, -1.541210937500011],
              [131.29375, -1.393457031250009],
              [131.24082031250003, -1.4296875],
              [131.17919921875, -1.448339843750006],
              [131.11777343750003, -1.455273437500011],
              [131.05673828125003, -1.44765625],
              [130.99589843750005, -1.424707031250009],
              [131.0009765625, -1.383984375000011],
              [131.04619140625005, -1.284082031250009],
              [131.09052734375, -1.247265625000011],
              [131.15185546875, -1.218847656250006],
              [131.19082031250002, -1.165820312500003],
              [131.2541015625, -1.006933593750006],
              [131.258984375, -0.95263671875],
              [131.25205078125003, -0.897167968750011],
              [131.25722656250002, -0.85546875],
              [131.29638671875, -0.83359375],
              [131.46152343750003, -0.781835937500006],
              [131.80429687500003, -0.703808593750011],
              [131.89091796875005, -0.657128906250009],
              [131.96240234375, -0.582421875],
              [132.04599609375003, -0.537011718750009],
              [132.08447265625, -0.491113281250009],
              [132.12841796875, -0.4541015625],
              [132.39375, -0.35546875],
              [132.5080078125, -0.347460937500003],
              [132.62509765625003, -0.35888671875],
              [132.8564453125, -0.417382812500009],
              [133.0771484375, -0.511816406250006],
              [133.26845703125002, -0.6357421875],
              [133.47265625, -0.726171875],
              [133.7236328125, -0.741406250000011],
              [133.85029296875, -0.7314453125],
              [133.97451171875002, -0.744335937500011],
              [134.02490234375, -0.769726562500011],
              [134.1115234375, -0.846777343750006],
              [134.08671875000005, -0.897363281250009],
              [134.07197265625, -1.001855468750009],
              [134.1162109375, -1.102441406250009],
              [134.18828125000005, -1.203125],
              [134.24716796875003, -1.310546875],
              [134.25957031250005, -1.362988281250011],
              [134.23720703125002, -1.47412109375],
              [134.2169921875, -1.529101562500003],
              [134.14541015625002, -1.620800781250011],
              [134.10585937500002, -1.720996093750003],
              [134.13125, -1.84453125],
              [134.14541015625002, -1.96875],
              [134.1427734375, -2.082910156250009],
              [134.15566406250002, -2.195214843750009],
              [134.19482421875, -2.30908203125],
              [134.36210937500005, -2.620996093750009],
              [134.4599609375, -2.832324218750003],
              [134.4912109375, -2.714257812500009],
              [134.48330078125002, -2.5830078125],
              [134.51796875000002, -2.53564453125],
              [134.56689453125, -2.510449218750011],
              [134.62744140625, -2.53671875],
              [134.6447265625, -2.58984375],
              [134.64902343750003, -2.705859375],
              [134.7021484375, -2.93359375],
              [134.76982421875005, -2.944042968750011],
              [134.84335937500003, -2.9091796875],
              [134.85537109375002, -2.978808593750003],
              [134.852734375, -3.107617187500011],
              [134.88681640625003, -3.209863281250009],
              [134.9171875, -3.249902343750009],
              [135.03740234375005, -3.333105468750006],
              [135.09218750000002, -3.348535156250009],
              [135.25156250000003, -3.368554687500009],
              [135.37158203125, -3.374902343750009],
              [135.48662109375005, -3.345117187500009],
              [135.5607421875, -3.26875],
              [135.62773437500005, -3.18603515625],
              [135.85917968750005, -2.995312500000011],
              [135.92617187500002, -2.904101562500003],
              [135.99072265625, -2.764257812500006],
              [136.01298828125005, -2.734277343750009],
              [136.24326171875003, -2.583105468750006],
              [136.26953125, -2.529492187500011],
              [136.30253906250005, -2.425683593750009],
              [136.35244140625002, -2.3251953125],
              [136.38994140625005, -2.273339843750009],
              [136.6123046875, -2.224316406250011],
              [136.84326171875, -2.19765625],
              [137.07207031250005, -2.105078125],
              [137.17109375, -2.025488281250006],
              [137.17578125, -1.97314453125],
              [137.12548828125, -1.88125],
              [137.12343750000002, -1.840917968750006],
              [137.17646484375, -1.802148437500009],
              [137.38056640625, -1.685644531250006],
              [137.61660156250002, -1.565820312500009],
              [137.80625, -1.483203125],
              [137.9111328125, -1.483789062500009],
              [138.0078125, -1.556542968750009],
              [138.11093750000003, -1.615917968750011],
              [138.64980468750002, -1.791113281250006],
              [138.73613281250005, -1.845507812500003],
              [138.81142578125002, -1.917773437500003],
              [138.919140625, -1.967871093750006],
              [139.03945312500002, -1.992089843750009],
              [139.14882812500002, -2.038867187500003],
              [139.25263671875, -2.09921875],
              [139.48183593750002, -2.211816406250009],
              [139.78955078125, -2.348242187500006],
              [139.868359375, -2.3564453125],
              [140.15458984375005, -2.35],
              [140.20400390625002, -2.375683593750011],
              [140.2509765625, -2.412011718750009],
              [140.29462890625, -2.42041015625],
              [140.62255859375, -2.44580078125],
              [140.673046875, -2.472070312500009],
              [140.72050781250005, -2.508105468750003],
              [140.74746093750002, -2.607128906250011],
              [140.97343750000005, -2.609765625],
            ],
          ],
          [
            [
              [138.89511718750003, -8.388671875],
              [138.84550781250005, -8.401757812500009],
              [138.59423828125, -8.371484375],
              [138.56718750000005, -8.330273437500011],
              [138.56337890625002, -8.30908203125],
              [138.62099609375002, -8.268457031250009],
              [138.67666015625002, -8.19921875],
              [138.7626953125, -8.1734375],
              [138.79619140625005, -8.173632812500003],
              [138.89765625, -8.3375],
              [138.89511718750003, -8.388671875],
            ],
          ],
          [
            [
              [101.70810546875003, 2.078417968749989],
              [101.76230468750003, 1.996533203124997],
              [101.77353515625003, 1.943457031249991],
              [101.73408203125001, 1.882568359375],
              [101.71943359375001, 1.789160156249991],
              [101.60273437500001, 1.715722656249994],
              [101.50078124999999, 1.733203124999989],
              [101.4677734375, 1.759375],
              [101.40341796875003, 1.901318359374997],
              [101.40966796875, 2.021679687499997],
              [101.45029296875003, 2.067822265624997],
              [101.54472656249999, 2.060742187499997],
              [101.64072265625003, 2.126708984375],
              [101.70810546875003, 2.078417968749989],
            ],
          ],
          [
            [
              [102.42714843750002, 0.990136718749994],
              [102.380859375, 0.959765624999989],
              [102.32529296875003, 1.00703125],
              [102.27958984374999, 1.07568359375],
              [102.25546875000003, 1.147167968749997],
              [102.23417968749999, 1.263964843749989],
              [102.22861328125003, 1.347851562499997],
              [102.25634765625, 1.397070312499991],
              [102.27646484375003, 1.395263671875],
              [102.35859375000001, 1.345654296874997],
              [102.41289062499999, 1.260791015624989],
              [102.44287109375, 1.234228515624991],
              [102.44882812500003, 1.15625],
              [102.42890625000001, 1.067285156249994],
              [102.42714843750002, 0.990136718749994],
            ],
          ],
          [
            [
              [102.49189453125001, 1.459179687499997],
              [102.49941406250002, 1.330908203124991],
              [102.42519531250002, 1.364453125],
              [102.36689453125001, 1.415478515624997],
              [102.27421874999999, 1.453125],
              [102.16132812500001, 1.465429687499991],
              [102.07871093750003, 1.498583984374989],
              [102.02089843750002, 1.558203125],
              [102.01835937499999, 1.585644531249997],
              [102.02402343750003, 1.607958984374989],
              [102.04218750000001, 1.625390625],
              [102.46953124999999, 1.510058593749989],
              [102.49189453125001, 1.459179687499997],
            ],
          ],
          [
            [
              [103.02753906250001, 0.746630859374989],
              [103.0087890625, 0.708105468749991],
              [102.97148437499999, 0.736523437499997],
              [102.77626953125002, 0.779589843749989],
              [102.71054687500003, 0.784375],
              [102.54160156250003, 0.831591796874989],
              [102.49042968750001, 0.856640625],
              [102.45390624999999, 0.889501953124991],
              [102.46640625000003, 0.950341796874994],
              [102.49140625000001, 0.986865234374989],
              [102.50664062499999, 1.088769531249994],
              [102.54921875000002, 1.130224609374991],
              [102.63320312500002, 1.054394531249997],
              [102.72617187500003, 0.989208984374997],
              [102.78007812499999, 0.959375],
              [102.94414062499999, 0.892724609374994],
              [103.00244140625, 0.859277343749994],
              [103.02753906250001, 0.746630859374989],
            ],
          ],
          [
            [
              [103.16640625000002, 0.870166015624989],
              [103.13720703125, 0.841650390624991],
              [103.08671874999999, 0.84814453125],
              [103.03339843750001, 0.88203125],
              [102.96396484375003, 0.942675781249989],
              [102.88632812500003, 0.996777343749997],
              [102.78798828125002, 1.030957031249997],
              [102.72646484375002, 1.041259765625],
              [102.70185546875001, 1.0537109375],
              [102.7255859375, 1.158837890624994],
              [102.79013671875003, 1.165478515624997],
              [102.99941406250002, 1.067773437499994],
              [103.06757812500001, 1.014746093749991],
              [103.16640625000002, 0.870166015624989],
            ],
          ],
          [
            [
              [103.28447265624999, 0.541943359374997],
              [103.17216796874999, 0.536181640624989],
              [103.13955078125002, 0.549072265625],
              [103.1533203125, 0.643115234374989],
              [103.18740234375002, 0.699755859374989],
              [103.23818359375002, 0.698632812499994],
              [103.29511718750001, 0.613964843749997],
              [103.28447265624999, 0.541943359374997],
            ],
          ],
          [
            [
              [103.4501953125, 0.664453125],
              [103.4296875, 0.65087890625],
              [103.34443359375001, 0.777880859374989],
              [103.36572265625, 0.851123046874989],
              [103.38613281250002, 0.869580078124997],
              [103.43310546875, 0.825],
              [103.47031250000003, 0.778125],
              [103.49746093750002, 0.722705078124989],
              [103.4501953125, 0.664453125],
            ],
          ],
          [
            [
              [104.02480468750002, 1.180566406249994],
              [104.08808593750001, 1.137011718749989],
              [104.13984375000001, 1.165576171874989],
              [104.13779296875003, 1.128222656249989],
              [104.12734375000002, 1.092382812499991],
              [104.06611328125001, 0.989550781249989],
              [103.96357421875001, 1.013232421874989],
              [103.93984375000002, 1.046484375],
              [103.93222656250003, 1.071386718749991],
              [103.94697265625001, 1.087011718749991],
              [103.95537109374999, 1.137451171875],
              [103.99980468749999, 1.137255859374989],
              [104.02480468750002, 1.180566406249994],
            ],
          ],
          [
            [
              [104.58535156250002, 1.216113281249989],
              [104.59101562500001, 1.141064453124997],
              [104.64814453125001, 1.104589843749991],
              [104.66289062499999, 1.049511718749997],
              [104.65283203125, 0.961035156249991],
              [104.59912109375, 0.858984375],
              [104.5751953125, 0.831933593749994],
              [104.50429687500002, 0.852636718749991],
              [104.48066406250001, 0.886767578124989],
              [104.47119140625, 0.913476562499994],
              [104.48105468750003, 0.932519531249994],
              [104.42861328125002, 0.956494140624997],
              [104.46240234375, 0.995556640624997],
              [104.43925781249999, 1.050439453124994],
              [104.2939453125, 1.01611328125],
              [104.251953125, 1.014892578125],
              [104.24423828125003, 1.077392578125],
              [104.25019531250001, 1.102636718749991],
              [104.36181640625, 1.181494140624991],
              [104.42841796875001, 1.196044921875],
              [104.50009765625003, 1.180224609374989],
              [104.58535156250002, 1.216113281249989],
            ],
          ],
          [
            [
              [104.77861328124999, -0.175976562500011],
              [104.80751953125002, -0.192480468750006],
              [104.84316406250002, -0.140625],
              [104.90898437499999, -0.21171875],
              [104.94970703125, -0.247265625000011],
              [105.00537109375, -0.2828125],
              [104.95058593750002, -0.284472656250003],
              [104.92851562499999, -0.316992187500006],
              [104.91425781250001, -0.323339843750006],
              [104.70224609375003, -0.208691406250011],
              [104.56660156250001, -0.24560546875],
              [104.47353515625002, -0.212109375000011],
              [104.44707031249999, -0.189160156250011],
              [104.4970703125, -0.126367187500009],
              [104.54267578125001, 0.017724609374994],
              [104.63564453125002, -0.018457031250009],
              [104.65839843750001, -0.062841796875006],
              [104.65273437500002, -0.076025390625006],
              [104.71347656250003, -0.10302734375],
              [104.77861328124999, -0.175976562500011],
            ],
          ],
          [
            [
              [104.47421875000003, -0.334667968750011],
              [104.56777343750002, -0.431835937500011],
              [104.59013671874999, -0.466601562500003],
              [104.5439453125, -0.5205078125],
              [104.50654296875001, -0.5966796875],
              [104.4853515625, -0.612890625],
              [104.41386718749999, -0.583691406250011],
              [104.36318359375002, -0.65859375],
              [104.32978515625001, -0.5390625],
              [104.25712890624999, -0.46328125],
              [104.30234375000003, -0.3857421875],
              [104.31875, -0.380175781250003],
              [104.34072265625002, -0.382617187500003],
              [104.36357421874999, -0.40283203125],
              [104.47421875000003, -0.334667968750011],
            ],
          ],
          [
            [
              [103.73652343750001, -0.347949218750003],
              [103.60634765625002, -0.382910156250006],
              [103.46132812500002, -0.357617187500011],
              [103.47900390625, -0.297460937500006],
              [103.54892578125003, -0.2275390625],
              [103.61093750000003, -0.230566406250006],
              [103.72392578124999, -0.276660156250003],
              [103.76425781250003, -0.317773437500009],
              [103.73652343750001, -0.347949218750003],
            ],
          ],
          [
            [
              [109.71025390624999, -1.1806640625],
              [109.51083984375003, -1.2828125],
              [109.46367187499999, -1.277539062500011],
              [109.428125, -1.2412109375],
              [109.45029296875003, -1.044140625000011],
              [109.47597656250002, -0.9853515625],
              [109.61464843750002, -0.979101562500006],
              [109.69951171874999, -1.00732421875],
              [109.74335937500001, -1.039355468750003],
              [109.76054687499999, -1.105175781250011],
              [109.75078124999999, -1.14501953125],
              [109.71025390624999, -1.1806640625],
            ],
          ],
          [
            [
              [113.84453124999999, -7.105371093750009],
              [113.82558593750002, -7.119921875],
              [113.65585937500003, -7.11171875],
              [113.54638671875, -7.193359375],
              [113.470703125, -7.218457031250011],
              [113.19843750000001, -7.218359375],
              [113.166015625, -7.207324218750003],
              [113.14189453124999, -7.207617187500006],
              [113.126953125, -7.22412109375],
              [113.04042968750002, -7.211816406250009],
              [112.76376953125003, -7.1396484375],
              [112.72587890624999, -7.07275390625],
              [112.76875, -7.001269531250003],
              [112.86806640625002, -6.89990234375],
              [113.0673828125, -6.879980468750006],
              [113.97470703125003, -6.873046875],
              [114.07363281250002, -6.960156250000011],
              [114.0830078125, -6.989355468750006],
              [113.88535156250003, -7.049023437500011],
              [113.84453124999999, -7.105371093750009],
            ],
          ],
          [
            [
              [134.96533203125, -1.116015625],
              [134.91738281250002, -1.13427734375],
              [134.86171875000002, -1.114160156250009],
              [134.80888671875005, -1.03759765625],
              [134.82792968750005, -0.978808593750003],
              [134.88925781250003, -0.9384765625],
              [134.94082031250002, -0.97890625],
              [134.95673828125, -1.030566406250003],
              [134.9962890625, -1.034082031250009],
              [134.96533203125, -1.116015625],
            ],
          ],
          [
            [
              [127.24990234375002, -0.495312500000011],
              [127.18730468749999, -0.521191406250011],
              [127.119140625, -0.5205078125],
              [127.10439453125002, -0.413867187500003],
              [127.12646484375, -0.278613281250003],
              [127.18964843750001, -0.255761718750009],
              [127.2900390625, -0.284375],
              [127.25302734375003, -0.318652343750003],
              [127.28056640625005, -0.391015625],
              [127.24990234375002, -0.495312500000011],
            ],
          ],
          [
            [
              [127.56699218750003, -0.318945312500006],
              [127.68242187500005, -0.468359375],
              [127.60498046875, -0.61015625],
              [127.65859375000002, -0.689453125],
              [127.80429687500003, -0.694433593750006],
              [127.837890625, -0.72412109375],
              [127.86328125, -0.759863281250006],
              [127.88017578125005, -0.808691406250006],
              [127.84228515625, -0.847753906250006],
              [127.76113281250002, -0.883691406250009],
              [127.66757812500003, -0.83203125],
              [127.64287109375005, -0.783984375],
              [127.62382812500005, -0.766015625],
              [127.49785156250005, -0.802441406250011],
              [127.46269531250005, -0.805957031250003],
              [127.43828125000005, -0.7390625],
              [127.46865234375002, -0.64296875],
              [127.38056640625001, -0.599609375],
              [127.3, -0.500292968750003],
              [127.29707031250001, -0.460253906250003],
              [127.32949218750002, -0.390917968750003],
              [127.32509765625002, -0.335839843750009],
              [127.37119140625003, -0.331640625],
              [127.45517578125003, -0.406347656250006],
              [127.49169921875, -0.3359375],
              [127.52734375, -0.306640625],
              [127.56699218750003, -0.318945312500006],
            ],
          ],
          [
            [
              [132.92626953125, -5.902050781250011],
              [132.84501953125005, -5.987988281250011],
              [132.92167968750005, -5.785253906250006],
              [132.9376953125, -5.6826171875],
              [133.0087890625, -5.621386718750003],
              [133.11464843750002, -5.310644531250006],
              [133.13847656250005, -5.31787109375],
              [133.1728515625, -5.34814453125],
              [133.11962890625, -5.575976562500003],
              [132.97109375000002, -5.73583984375],
              [132.92626953125, -5.902050781250011],
            ],
          ],
          [
            [
              [132.80712890625, -5.850781250000011],
              [132.7462890625, -5.947070312500003],
              [132.70488281250005, -5.9130859375],
              [132.68144531250005, -5.91259765625],
              [132.66728515625005, -5.856054687500006],
              [132.68134765625, -5.738867187500006],
              [132.63017578125005, -5.60703125],
              [132.69785156250003, -5.608984375],
              [132.71650390625, -5.648339843750009],
              [132.73779296875, -5.66171875],
              [132.80429687500003, -5.788867187500003],
              [132.80712890625, -5.850781250000011],
            ],
          ],
          [
            [
              [128.2755859375, -3.674609375],
              [128.24990234375002, -3.711132812500011],
              [128.19179687500002, -3.735253906250009],
              [128.14316406250003, -3.732714843750003],
              [128.15898437500005, -3.69765625],
              [128.146875, -3.677148437500009],
              [128.11083984375, -3.686425781250009],
              [128.05224609375, -3.714550781250011],
              [127.97802734375, -3.77099609375],
              [127.934375, -3.743066406250009],
              [127.925, -3.699316406250006],
              [127.92753906250005, -3.679394531250011],
              [128.01621093750003, -3.600878906250003],
              [128.119140625, -3.5875],
              [128.26435546875, -3.512304687500006],
              [128.3291015625, -3.515917968750003],
              [128.313671875, -3.563671875000011],
              [128.291015625, -3.59765625],
              [128.27744140625003, -3.633203125],
              [128.2755859375, -3.674609375],
            ],
          ],
          [
            [
              [126.81660156250001, 4.033496093749989],
              [126.77626953125002, 4.012597656249994],
              [126.71123046874999, 4.020263671875],
              [126.70449218750002, 4.070996093749997],
              [126.77011718750003, 4.162207031249991],
              [126.81357421875003, 4.258496093749997],
              [126.76728515625001, 4.282568359374991],
              [126.72207031250002, 4.344189453124997],
              [126.72050781249999, 4.415820312499989],
              [126.75732421875, 4.547900390624989],
              [126.8125, 4.537207031249991],
              [126.86513671875002, 4.479833984374991],
              [126.88671875, 4.372509765624997],
              [126.92109375000001, 4.291015625],
              [126.84765625, 4.179980468749989],
              [126.81660156250001, 4.033496093749989],
            ],
          ],
          [
            [
              [125.65810546875002, 3.43603515625],
              [125.63320312500002, 3.405419921874994],
              [125.51152343749999, 3.461132812499997],
              [125.517578125, 3.549609374999989],
              [125.50117187500001, 3.593212890624997],
              [125.46855468749999, 3.639111328124997],
              [125.45527343750001, 3.684179687499991],
              [125.46884765625003, 3.733251953124991],
              [125.54345703125, 3.67041015625],
              [125.58564453125001, 3.571093749999989],
              [125.6435546875, 3.476513671874997],
              [125.65810546875002, 3.43603515625],
            ],
          ],
          [
            [
              [130.86220703125002, -8.31875],
              [130.77519531250005, -8.349902343750003],
              [130.83339843750002, -8.270800781250003],
              [131.02011718750003, -8.09130859375],
              [131.08740234375, -8.12451171875],
              [131.17636718750003, -8.130761718750009],
              [131.04375, -8.212011718750006],
              [130.90810546875002, -8.245703125],
              [130.86220703125002, -8.31875],
            ],
          ],
          [
            [
              [129.8388671875, -7.95458984375],
              [129.77978515625, -8.046484375],
              [129.71347656250003, -8.040722656250011],
              [129.59189453125003, -7.917382812500009],
              [129.59873046875003, -7.831347656250003],
              [129.60898437500003, -7.803417968750011],
              [129.65546875, -7.794824218750009],
              [129.81298828125, -7.819726562500009],
              [129.84355468750005, -7.889355468750011],
              [129.8388671875, -7.95458984375],
            ],
          ],
          [
            [
              [127.82343750000001, -8.098828125000011],
              [127.99843750000002, -8.1390625],
              [128.098828125, -8.134863281250006],
              [128.11923828125003, -8.170703125],
              [128.02353515625003, -8.25537109375],
              [127.82089843750003, -8.190234375],
              [127.78623046875003, -8.120312500000011],
              [127.82343750000001, -8.098828125000011],
            ],
          ],
          [
            [
              [130.35332031250005, -1.690527343750006],
              [130.3654296875, -1.749804687500003],
              [130.425, -1.804589843750009],
              [130.404296875, -1.889843750000011],
              [130.38056640625, -1.902636718750003],
              [130.39335937500005, -1.941601562500011],
              [130.41884765625002, -1.971289062500006],
              [130.37265625000003, -1.991894531250011],
              [130.33896484375003, -1.981835937500009],
              [130.2841796875, -2.009375],
              [130.248046875, -2.047753906250009],
              [130.13349609375, -2.063867187500009],
              [130.09335937500003, -2.0283203125],
              [129.88652343750005, -1.986425781250006],
              [129.75439453125, -1.894433593750009],
              [129.73769531250002, -1.866894531250011],
              [129.99365234375, -1.758886718750006],
              [130.10576171875005, -1.73046875],
              [130.19960937500002, -1.732226562500003],
              [130.31796875000003, -1.691992187500006],
              [130.35332031250005, -1.690527343750006],
            ],
          ],
          [
            [
              [102.3671875, -5.478710937500011],
              [102.28593749999999, -5.483496093750006],
              [102.13554687499999, -5.360546875000011],
              [102.11074218750002, -5.322558593750003],
              [102.15351562500001, -5.286230468750006],
              [102.19843750000001, -5.288867187500003],
              [102.37177734375001, -5.366406250000011],
              [102.40546875000001, -5.40478515625],
              [102.3671875, -5.478710937500011],
            ],
          ],
          [
            [
              [100.42509765624999, -3.182910156250003],
              [100.46513671874999, -3.328515625],
              [100.34609375000002, -3.229199218750011],
              [100.34843749999999, -3.158789062500006],
              [100.33203125, -3.113085937500003],
              [100.25996093750001, -3.056933593750003],
              [100.20429687500001, -2.98681640625],
              [100.17929687500003, -2.820214843750009],
              [100.19853515624999, -2.785546875],
              [100.24560546875, -2.783203125],
              [100.45458984375, -3.001953125],
              [100.46884765625003, -3.038964843750009],
              [100.46425781250002, -3.116894531250011],
              [100.43388671874999, -3.141308593750011],
              [100.42509765624999, -3.182910156250003],
            ],
          ],
          [
            [
              [100.2041015625, -2.741015625],
              [100.13271484375002, -2.821386718750006],
              [100.01494140624999, -2.819726562500009],
              [99.99189453125001, -2.769824218750003],
              [99.996875, -2.649316406250009],
              [99.96816406250002, -2.609765625],
              [99.96933593750003, -2.594140625],
              [99.98789062500003, -2.525390625],
              [100.01191406250001, -2.51025390625],
              [100.20195312499999, -2.6796875],
              [100.2041015625, -2.741015625],
            ],
          ],
          [
            [
              [99.84306640624999, -2.343066406250003],
              [99.84785156250001, -2.369726562500006],
              [99.68515625000003, -2.28173828125],
              [99.60703125000003, -2.257519531250011],
              [99.53740234374999, -2.161523437500009],
              [99.55888671874999, -2.115429687500011],
              [99.56181640624999, -2.051171875],
              [99.57216796875002, -2.02578125],
              [99.6220703125, -2.0166015625],
              [99.68642578125002, -2.063378906250009],
              [99.73476562500002, -2.177734375],
              [99.81572265624999, -2.284375],
              [99.84306640624999, -2.343066406250003],
            ],
          ],
          [
            [
              [98.45927734374999, -0.530468750000011],
              [98.39970703124999, -0.576855468750011],
              [98.30966796875003, -0.531835937500006],
              [98.33994140625003, -0.467871093750006],
              [98.35478515624999, -0.379296875],
              [98.40878906250003, -0.308984375],
              [98.42714843750002, -0.226464843750009],
              [98.32294921875001, -0.00078125],
              [98.37451171875, 0.007080078125],
              [98.41542968750002, -0.017529296875011],
              [98.484375, -0.167675781250011],
              [98.54414062500001, -0.257617187500003],
              [98.52011718750003, -0.3796875],
              [98.45927734374999, -0.530468750000011],
            ],
          ],
          [
            [
              [96.46367187499999, 2.360009765624994],
              [96.40097656250003, 2.350683593749991],
              [96.340625, 2.3720703125],
              [96.29042968750002, 2.429589843749994],
              [96.02197265625, 2.595751953124989],
              [95.9384765625, 2.598437499999989],
              [95.87978515625002, 2.640917968749989],
              [95.80859375, 2.655615234374991],
              [95.73300781250003, 2.766503906249994],
              [95.71718750000002, 2.825976562499989],
              [95.77216796875001, 2.85498046875],
              [95.80625, 2.916015625],
              [95.89580078124999, 2.8890625],
              [95.99785156249999, 2.781396484374994],
              [96.1015625, 2.7412109375],
              [96.12978515625002, 2.720898437499997],
              [96.17998046874999, 2.661328125],
              [96.41728515624999, 2.515185546874989],
              [96.44306640625001, 2.465625],
              [96.459375, 2.415820312499989],
              [96.46367187499999, 2.360009765624994],
            ],
          ],
          [
            [
              [122.04296875, -5.43798828125],
              [121.97958984375003, -5.464746093750009],
              [121.859375, -5.350292968750011],
              [121.80849609375002, -5.256152343750003],
              [121.82070312500002, -5.202929687500003],
              [121.85664062500001, -5.15625],
              [121.87373046875001, -5.144628906250006],
              [121.86630859375003, -5.095996093750003],
              [121.91367187500003, -5.072265625],
              [121.96572265625002, -5.075585937500009],
              [121.99990234375002, -5.140820312500011],
              [122.041015625, -5.158789062500006],
              [122.06181640624999, -5.221289062500006],
              [122.04296875, -5.43798828125],
            ],
          ],
          [
            [
              [123.21230468750002, -1.171289062500009],
              [123.23427734375002, -1.233691406250003],
              [123.19804687499999, -1.287695312500006],
              [123.23779296875, -1.389355468750011],
              [123.33857421875001, -1.254003906250006],
              [123.43476562500001, -1.23681640625],
              [123.48935546875003, -1.25927734375],
              [123.52685546875, -1.286035156250009],
              [123.54726562500002, -1.33740234375],
              [123.51191406250001, -1.447363281250006],
              [123.44873046875, -1.498828125],
              [123.36699218749999, -1.507128906250003],
              [123.32861328125, -1.443066406250011],
              [123.27490234375, -1.437207031250011],
              [123.23740234375003, -1.576953125],
              [123.22050781249999, -1.598339843750011],
              [123.17294921875003, -1.616015625],
              [123.13037109375, -1.577441406250003],
              [123.12294921875002, -1.556054687500009],
              [123.18291015624999, -1.492773437500006],
              [123.150390625, -1.304492187500003],
              [123.10517578125001, -1.33984375],
              [122.984375, -1.510644531250009],
              [122.89042968749999, -1.587207031250003],
              [122.85849609375003, -1.548242187500009],
              [122.81083984374999, -1.43212890625],
              [122.83222656250001, -1.283007812500003],
              [122.90800781249999, -1.182226562500006],
              [122.97246093749999, -1.189160156250011],
              [123.15830078125003, -1.157519531250003],
              [123.21230468750002, -1.171289062500009],
            ],
          ],
          [
            [
              [121.86435546875003, -0.406835937500006],
              [121.90683593750003, -0.451269531250006],
              [121.88125, -0.502636718750011],
              [121.846875, -0.48984375],
              [121.75605468750001, -0.490820312500006],
              [121.72177734375003, -0.494726562500006],
              [121.68095703124999, -0.525],
              [121.6552734375, -0.526171875],
              [121.67236328125, -0.478808593750003],
              [121.74931640624999, -0.40703125],
              [121.79736328125, -0.417675781250011],
              [121.86435546875003, -0.406835937500006],
            ],
          ],
          [
            [
              [120.5283203125, -6.2984375],
              [120.4873046875, -6.46484375],
              [120.46796875000001, -6.406152343750009],
              [120.46074218749999, -6.254003906250006],
              [120.435546875, -6.18017578125],
              [120.45156250000002, -6.094921875000011],
              [120.44648437500001, -5.876269531250003],
              [120.47734374999999, -5.775292968750009],
              [120.5341796875, -5.90380859375],
              [120.54921875000002, -5.96923828125],
              [120.5283203125, -6.2984375],
            ],
          ],
          [
            [
              [115.37705078125003, -6.970800781250006],
              [115.29580078125002, -6.98779296875],
              [115.22031250000003, -6.952539062500009],
              [115.22216796875, -6.905175781250009],
              [115.24052734374999, -6.861230468750009],
              [115.35371093750001, -6.838476562500006],
              [115.41445312500002, -6.839746093750009],
              [115.47919921875001, -6.870214843750006],
              [115.52421874999999, -6.90185546875],
              [115.54609375000001, -6.938671875000011],
              [115.42412109374999, -6.940625],
              [115.37705078125003, -6.970800781250006],
            ],
          ],
          [
            [
              [116.30332031250003, -3.8681640625],
              [116.09335937500003, -4.054101562500009],
              [116.05878906250001, -4.006933593750006],
              [116.07695312499999, -3.817480468750006],
              [116.01835937499999, -3.699902343750011],
              [116.0224609375, -3.612402343750006],
              [116.06357421875003, -3.457910156250009],
              [116.11738281250001, -3.339550781250011],
              [116.23935546875003, -3.260351562500006],
              [116.26972656250001, -3.251074218750006],
              [116.26210937500002, -3.394824218750003],
              [116.28652343750002, -3.448828125],
              [116.29511718750001, -3.495019531250009],
              [116.28203124999999, -3.534765625],
              [116.30517578125, -3.718554687500003],
              [116.31865234374999, -3.762988281250003],
              [116.28925781250001, -3.820898437500006],
              [116.30332031250003, -3.8681640625],
            ],
          ],
          [
            [
              [122.94892578125001, -10.909277343750006],
              [122.85585937500002, -10.90966796875],
              [122.826171875, -10.899121093750011],
              [122.81845703125003, -10.81103515625],
              [122.845703125, -10.761816406250006],
              [123.06142578125002, -10.698437500000011],
              [123.14580078124999, -10.639941406250003],
              [123.26542968749999, -10.518164062500006],
              [123.33964843749999, -10.486230468750009],
              [123.35849609375003, -10.472460937500003],
              [123.37109375, -10.474902343750003],
              [123.38310546874999, -10.567578125000011],
              [123.41289062499999, -10.62265625],
              [123.41816406250001, -10.651269531250009],
              [123.31074218750001, -10.698437500000011],
              [123.21484375, -10.80615234375],
              [123.00527343750002, -10.876367187500009],
              [122.94892578125001, -10.909277343750006],
            ],
          ],
          [
            [
              [124.28662109375, -8.329492187500009],
              [124.22578125000001, -8.391308593750011],
              [124.184375, -8.498730468750011],
              [124.14667968750001, -8.531445312500011],
              [124.06572265624999, -8.551660156250009],
              [124.01728515625001, -8.44384765625],
              [123.927734375, -8.448925781250011],
              [123.97148437499999, -8.354101562500006],
              [124.01376953125003, -8.318652343750003],
              [124.06875, -8.317773437500009],
              [124.09580078125003, -8.356152343750011],
              [124.11054687500001, -8.3642578125],
              [124.23955078124999, -8.203417968750003],
              [124.265625, -8.201757812500006],
              [124.287109375, -8.208691406250011],
              [124.30449218749999, -8.228808593750003],
              [124.28662109375, -8.329492187500009],
            ],
          ],
          [
            [
              [123.9248046875, -8.2724609375],
              [123.78388671875001, -8.299609375],
              [123.69785156250003, -8.424414062500006],
              [123.62919921874999, -8.422460937500006],
              [123.59160156249999, -8.477929687500009],
              [123.58261718750003, -8.501660156250011],
              [123.587890625, -8.523828125],
              [123.58017578125003, -8.544921875],
              [123.55302734374999, -8.566796875],
              [123.48867187500002, -8.532324218750006],
              [123.43378906250001, -8.576074218750009],
              [123.41074218750003, -8.586621093750011],
              [123.32998046875002, -8.53564453125],
              [123.25332031250002, -8.53857421875],
              [123.23007812500003, -8.530664062500009],
              [123.325, -8.4390625],
              [123.45458984375, -8.353710937500011],
              [123.47587890624999, -8.322265625],
              [123.42519531250002, -8.313378906250009],
              [123.39492187500002, -8.300585937500003],
              [123.39121093750003, -8.280468750000011],
              [123.47324218750003, -8.26708984375],
              [123.52998046875001, -8.265234375],
              [123.57314453125002, -8.29150390625],
              [123.6005859375, -8.291308593750003],
              [123.77597656250003, -8.1904296875],
              [123.84550781249999, -8.21337890625],
              [123.89609375000003, -8.2392578125],
              [123.9248046875, -8.2724609375],
            ],
          ],
          [
            [
              [123.31748046875003, -8.354785156250003],
              [123.29726562500002, -8.398632812500011],
              [123.025, -8.3955078125],
              [123.03261718750002, -8.337792968750009],
              [123.10830078125002, -8.274804687500009],
              [123.13349609375001, -8.253808593750009],
              [123.21708984374999, -8.235449218750006],
              [123.33603515625003, -8.26904296875],
              [123.31748046875003, -8.354785156250003],
            ],
          ],
          [
            [
              [134.71611328125005, -6.549414062500006],
              [134.66083984375, -6.558886718750003],
              [134.63369140625002, -6.477246093750011],
              [134.67910156250002, -6.4560546875],
              [134.728515625, -6.505859375],
              [134.71611328125005, -6.549414062500006],
            ],
          ],
          [
            [
              [103.42392578125003, 1.04833984375],
              [103.4296875, 0.993359375],
              [103.36328125, 1.0068359375],
              [103.3154296875, 1.0712890625],
              [103.35498046875, 1.117236328124989],
              [103.37998046875003, 1.133642578124991],
              [103.40488281250003, 1.072558593749989],
              [103.42392578125003, 1.04833984375],
            ],
          ],
          [
            [
              [103.82861328125, 0.801025390625],
              [103.833984375, 0.772216796875],
              [103.74238281250001, 0.829980468749994],
              [103.74003906249999, 0.871826171875],
              [103.751953125, 0.891357421875],
              [103.806640625, 0.846337890624994],
              [103.82861328125, 0.801025390625],
            ],
          ],
          [
            [
              [104.23935546875003, 0.833984375],
              [104.1767578125, 0.804882812499997],
              [104.09814453125, 0.896240234375],
              [104.10107421875, 0.91748046875],
              [104.10830078125002, 0.933544921874997],
              [104.12275390625001, 0.943994140624994],
              [104.17050781250003, 0.896728515625],
              [104.22705078125, 0.8798828125],
              [104.23935546875003, 0.833984375],
            ],
          ],
          [
            [
              [104.68925781249999, 0.059521484374997],
              [104.69814453125002, 0.03466796875],
              [104.65087890625, 0.062695312499997],
              [104.62236328124999, 0.079638671874989],
              [104.603515625, 0.09521484375],
              [104.49921875000001, 0.232080078124994],
              [104.54384765625002, 0.223291015624994],
              [104.65986328125001, 0.103076171874989],
              [104.68925781249999, 0.059521484374997],
            ],
          ],
          [
            [
              [106.28525390625003, 3.157128906249994],
              [106.28369140625, 3.088232421874991],
              [106.21455078125001, 3.128564453124994],
              [106.20097656249999, 3.204882812499989],
              [106.22373046875003, 3.229589843749991],
              [106.27119140625001, 3.21630859375],
              [106.28525390625003, 3.157128906249994],
            ],
          ],
          [
            [
              [105.76035156250003, 2.863037109375],
              [105.71855468749999, 2.859179687499989],
              [105.70615234375003, 2.888867187499997],
              [105.70791015625002, 2.940087890624994],
              [105.70419921875003, 2.980908203124997],
              [105.69218749999999, 3.011328125],
              [105.69218749999999, 3.0625],
              [105.73066406250001, 3.036962890624991],
              [105.76035156250003, 3.013037109374991],
              [105.79453125000003, 2.995947265624991],
              [105.82216796875002, 2.984375],
              [105.83671874999999, 2.976513671874997],
              [105.809375, 2.903955078124994],
              [105.76035156250003, 2.863037109375],
            ],
          ],
          [
            [
              [108.8875, 2.905419921874994],
              [108.8388671875, 2.85302734375],
              [108.78652343750002, 2.885644531249994],
              [108.86708984375002, 2.991894531249997],
              [108.8857421875, 2.998974609374997],
              [108.8875, 2.905419921874994],
            ],
          ],
          [
            [
              [108.953125, -1.61962890625],
              [108.837890625, -1.66162109375],
              [108.8037109375, -1.567773437500009],
              [108.87724609374999, -1.53984375],
              [108.95683593749999, -1.5640625],
              [108.953125, -1.61962890625],
            ],
          ],
          [
            [
              [107.47333984375001, -2.899511718750006],
              [107.43281250000001, -2.92529296875],
              [107.40927734375003, -2.900585937500011],
              [107.40244140625003, -2.872949218750009],
              [107.41933593750002, -2.838085937500011],
              [107.47441406249999, -2.834667968750011],
              [107.49970703125001, -2.845019531250003],
              [107.47333984375001, -2.899511718750006],
            ],
          ],
          [
            [
              [106.88642578125001, -3.005273437500009],
              [106.86972656250003, -3.025292968750009],
              [106.81425781249999, -3.014453125],
              [106.77431640625002, -2.98681640625],
              [106.74921875000001, -2.96044921875],
              [106.74287109375001, -2.932812500000011],
              [106.796875, -2.89892578125],
              [106.91064453125, -2.933984375],
              [106.88642578125001, -3.005273437500009],
            ],
          ],
          [
            [
              [114.41259765625, -7.133496093750011],
              [114.39765625000001, -7.173144531250003],
              [114.346875, -7.163281250000011],
              [114.298828125, -7.097558593750009],
              [114.32216796875002, -7.080371093750003],
              [114.34892578124999, -7.073437500000011],
              [114.38359374999999, -7.080664062500006],
              [114.41259765625, -7.133496093750011],
            ],
          ],
          [
            [
              [112.71943359375001, -5.81103515625],
              [112.69794921875001, -5.846484375],
              [112.60214843750003, -5.843652343750009],
              [112.58603515625003, -5.803613281250009],
              [112.64853515625003, -5.730859375],
              [112.69003906250003, -5.726171875],
              [112.72734374999999, -5.752734375],
              [112.71943359375001, -5.81103515625],
            ],
          ],
          [
            [
              [105.25283203125002, -6.640429687500003],
              [105.1904296875, -6.6625],
              [105.14277343750001, -6.64306640625],
              [105.12138671874999, -6.614941406250011],
              [105.19228515625002, -6.545605468750011],
              [105.22568359375003, -6.529101562500003],
              [105.26054687499999, -6.52392578125],
              [105.27744140625003, -6.561425781250009],
              [105.25283203125002, -6.640429687500003],
            ],
          ],
          [
            [
              [97.33417968750001, 2.075634765624997],
              [97.32832031250001, 2.053271484374989],
              [97.22509765625, 2.158496093749989],
              [97.10830078125002, 2.216894531249991],
              [97.15664062500002, 2.232226562499989],
              [97.25283203125002, 2.216015625],
              [97.29140625000002, 2.200830078124994],
              [97.32871093750003, 2.148535156249991],
              [97.33417968750001, 2.075634765624997],
            ],
          ],
          [
            [
              [95.36210937499999, 5.812402343749994],
              [95.34257812499999, 5.784130859374997],
              [95.283203125, 5.798535156249997],
              [95.21767578125002, 5.889501953124991],
              [95.24199218749999, 5.907031249999989],
              [95.28251953124999, 5.897753906249989],
              [95.35917968749999, 5.876757812499989],
              [95.36601562499999, 5.842675781249994],
              [95.36210937499999, 5.812402343749994],
            ],
          ],
          [
            [
              [123.59755859375002, -1.704296875000011],
              [123.52861328124999, -1.710839843750009],
              [123.48251953125003, -1.681445312500003],
              [123.48662109374999, -1.534863281250011],
              [123.52851562500001, -1.502832031250009],
              [123.54853515625001, -1.508203125],
              [123.56132812499999, -1.551855468750006],
              [123.58203125, -1.590917968750006],
              [123.61640625000001, -1.62744140625],
              [123.59755859375002, -1.704296875000011],
            ],
          ],
          [
            [
              [123.24238281250001, -4.112988281250011],
              [123.14453125, -4.233300781250009],
              [123.076171875, -4.227148437500006],
              [122.99472656250003, -4.148046875],
              [122.97089843750001, -4.061328125],
              [122.96904296874999, -4.029980468750011],
              [123.02490234375, -3.98095703125],
              [123.2119140625, -3.99755859375],
              [123.24697265625002, -4.040917968750009],
              [123.24238281250001, -4.112988281250011],
            ],
          ],
          [
            [
              [123.84824218750003, -1.95546875],
              [123.86601562499999, -1.995703125],
              [123.80351562499999, -1.994335937500011],
              [123.77724609375002, -1.918652343750011],
              [123.78349609374999, -1.878320312500009],
              [123.84824218750003, -1.95546875],
            ],
          ],
          [
            [
              [123.15253906250001, -1.816503906250006],
              [123.07880859375001, -1.89892578125],
              [123.07089843750003, -1.854882812500009],
              [123.08583984375002, -1.81484375],
              [123.1064453125, -1.78671875],
              [123.1375, -1.772656250000011],
              [123.15253906250001, -1.816503906250006],
            ],
          ],
          [
            [
              [119.46406250000001, -8.741015625],
              [119.42490234375003, -8.75048828125],
              [119.38554687499999, -8.736035156250011],
              [119.40166015624999, -8.647070312500006],
              [119.37890625, -8.586523437500006],
              [119.419921875, -8.5390625],
              [119.43017578125, -8.454980468750009],
              [119.44648437500001, -8.42919921875],
              [119.47050781249999, -8.455664062500006],
              [119.48173828124999, -8.472949218750003],
              [119.50214843750001, -8.481054687500006],
              [119.54697265625003, -8.482617187500011],
              [119.55722656250003, -8.518847656250003],
              [119.55546874999999, -8.553417968750011],
              [119.53632812500001, -8.58935546875],
              [119.48281250000002, -8.628222656250003],
              [119.44404296875001, -8.671777343750009],
              [119.46406250000001, -8.741015625],
            ],
          ],
          [
            [
              [119.07382812500003, -8.238867187500006],
              [119.02998046875001, -8.240039062500003],
              [119.02089843750002, -8.199902343750011],
              [119.03662109375, -8.1578125],
              [119.07871093750003, -8.140234375],
              [119.09775390625003, -8.13916015625],
              [119.12832031250002, -8.177148437500009],
              [119.13486328125003, -8.197070312500003],
              [119.10673828124999, -8.2234375],
              [119.07382812500003, -8.238867187500006],
            ],
          ],
          [
            [
              [115.60996093750003, -8.769824218750003],
              [115.58193359375002, -8.80419921875],
              [115.50087890625002, -8.742871093750011],
              [115.48046875, -8.715429687500006],
              [115.540625, -8.675390625],
              [115.56142578125002, -8.669921875],
              [115.61328125, -8.713183593750003],
              [115.60996093750003, -8.769824218750003],
            ],
          ],
          [
            [
              [117.64902343750003, 4.168994140624989],
              [117.74541015624999, 4.166943359374997],
              [117.884765625, 4.186132812499991],
              [117.91787109375002, 4.090527343749997],
              [117.9228515625, 4.054296875],
              [117.73681640625, 4.004003906249991],
              [117.62509765625003, 4.121484375],
              [117.64902343750003, 4.168994140624989],
            ],
          ],
          [
            [
              [117.65839843750001, 3.280517578125],
              [117.64580078124999, 3.247753906249997],
              [117.56035156249999, 3.328222656249991],
              [117.5375, 3.386376953124994],
              [117.5478515625, 3.431982421874991],
              [117.63671875, 3.436083984374989],
              [117.68085937500001, 3.407519531249989],
              [117.65839843750001, 3.280517578125],
            ],
          ],
          [
            [
              [124.05126953125, -5.973730468750006],
              [124.04208984375003, -6.021582031250006],
              [124.00566406249999, -5.966699218750009],
              [123.97226562500003, -5.939355468750009],
              [123.97578125000001, -5.880175781250003],
              [124.02294921875, -5.902148437500003],
              [124.05126953125, -5.973730468750006],
            ],
          ],
          [
            [
              [123.62675781249999, -5.271582031250006],
              [123.62275390625001, -5.373046875],
              [123.58261718750003, -5.367382812500011],
              [123.55009765624999, -5.331835937500003],
              [123.54091796875002, -5.29833984375],
              [123.54277343749999, -5.27109375],
              [123.56064453125003, -5.249804687500003],
              [123.62675781249999, -5.271582031250006],
            ],
          ],
          [
            [
              [120.7744140625, -7.118945312500003],
              [120.67236328125, -7.124707031250011],
              [120.64082031250001, -7.115820312500006],
              [120.63339843750003, -7.018261718750011],
              [120.74550781250002, -7.06015625],
              [120.78173828125, -7.063085937500006],
              [120.7744140625, -7.118945312500003],
            ],
          ],
          [
            [
              [117.55634765625001, -8.367285156250006],
              [117.53359375000002, -8.36796875],
              [117.49042968750001, -8.348730468750006],
              [117.50595703125003, -8.30703125],
              [117.48212890625001, -8.2392578125],
              [117.49052734374999, -8.183398437500003],
              [117.54609375000001, -8.151953125],
              [117.6650390625, -8.148242187500003],
              [117.66923828124999, -8.189257812500003],
              [117.55634765625001, -8.367285156250006],
            ],
          ],
          [
            [
              [116.42412109374999, -3.464453125],
              [116.38779296875003, -3.63671875],
              [116.32656250000002, -3.5390625],
              [116.39531249999999, -3.42333984375],
              [116.42695312500001, -3.39990234375],
              [116.42412109374999, -3.464453125],
            ],
          ],
          [
            [
              [127.37265625000003, 0.791308593749989],
              [127.33837890625, 0.758447265624994],
              [127.30605468750002, 0.769433593749994],
              [127.28642578125005, 0.811914062499994],
              [127.29277343750005, 0.842480468749997],
              [127.31982421875, 0.862011718749997],
              [127.35380859375005, 0.847460937499989],
              [127.37265625000003, 0.791308593749989],
            ],
          ],
          [
            [
              [134.37421875, -2.12353515625],
              [134.34521484375, -2.138769531250006],
              [134.33505859375003, -2.09521484375],
              [134.35078125, -2.036914062500003],
              [134.36953125000002, -2.027636718750003],
              [134.39101562500002, -2.03076171875],
              [134.41904296875003, -2.0517578125],
              [134.37421875, -2.12353515625],
            ],
          ],
          [
            [
              [133.57080078125, -4.245898437500003],
              [133.621875, -4.29931640625],
              [133.5029296875, -4.257421875],
              [133.3330078125, -4.169628906250011],
              [133.32089843750003, -4.111035156250011],
              [133.46435546875, -4.199804687500006],
              [133.57080078125, -4.245898437500003],
            ],
          ],
          [
            [
              [130.9052734375, -0.777441406250006],
              [130.87978515625002, -0.828417968750003],
              [130.83242187500002, -0.862890625],
              [130.40244140625003, -0.923925781250006],
              [130.43906250000003, -0.887402343750011],
              [130.45732421875005, -0.851171875],
              [130.48427734375002, -0.83251953125],
              [130.52695312500003, -0.837304687500009],
              [130.54814453125005, -0.826269531250006],
              [130.56953125, -0.821875],
              [130.59375, -0.82666015625],
              [130.63544921875, -0.811621093750006],
              [130.72324218750003, -0.822460937500011],
              [130.8134765625, -0.813867187500009],
              [130.80703125000002, -0.765039062500009],
              [130.9052734375, -0.777441406250006],
            ],
          ],
          [
            [
              [130.62666015625, -0.528710937500009],
              [130.56914062500005, -0.529980468750011],
              [130.46542968750003, -0.486523437500011],
              [130.52587890625, -0.44873046875],
              [130.56416015625, -0.44091796875],
              [130.59746093750005, -0.418261718750003],
              [130.61591796875, -0.417285156250003],
              [130.65693359375, -0.4365234375],
              [130.68427734375, -0.469140625],
              [130.62666015625, -0.528710937500009],
            ],
          ],
          [
            [
              [129.54892578125003, -0.18701171875],
              [129.50566406250005, -0.18984375],
              [129.46923828125, -0.131445312500006],
              [129.3701171875, -0.06640625],
              [129.3087890625, 0.04541015625],
              [129.5419921875, -0.139257812500006],
              [129.54892578125003, -0.18701171875],
            ],
          ],
          [
            [
              [127.45341796875005, -0.005859375],
              [127.44863281250002, -0.03662109375],
              [127.41787109375002, 0.00634765625],
              [127.39677734375005, 0.0166015625],
              [127.41953125000003, 0.124414062499994],
              [127.43134765625001, 0.142578125],
              [127.44941406250001, 0.068994140624994],
              [127.45341796875005, -0.005859375],
            ],
          ],
          [
            [
              [127.41972656250005, 0.64208984375],
              [127.38398437500001, 0.631005859374994],
              [127.37363281250003, 0.634863281249991],
              [127.36289062500003, 0.675146484374991],
              [127.38261718750005, 0.743554687499994],
              [127.4248046875, 0.744384765625],
              [127.44257812500001, 0.733447265624989],
              [127.44589843750003, 0.683300781249997],
              [127.41972656250005, 0.64208984375],
            ],
          ],
          [
            [
              [127.30039062500003, -0.780957031250011],
              [127.2890625, -0.8015625],
              [127.1845703125, -0.775292968750009],
              [127.15644531250001, -0.760937500000011],
              [127.20908203125003, -0.619335937500011],
              [127.25820312500002, -0.6234375],
              [127.30126953125, -0.758398437500006],
              [127.30039062500003, -0.780957031250011],
            ],
          ],
          [
            [
              [128.72246093750005, -3.546875],
              [128.72011718750002, -3.589160156250003],
              [128.71328125000002, -3.6025390625],
              [128.65878906250003, -3.587792968750009],
              [128.61953125000002, -3.588574218750011],
              [128.58515625, -3.51220703125],
              [128.594921875, -3.494824218750011],
              [128.66650390625, -3.516699218750006],
              [128.6935546875, -3.524511718750006],
              [128.72246093750005, -3.546875],
            ],
          ],
          [
            [
              [128.56259765625003, -3.58544921875],
              [128.3916015625, -3.637890625000011],
              [128.42832031250003, -3.540429687500009],
              [128.45156250000002, -3.514746093750006],
              [128.536328125, -3.541308593750003],
              [128.56259765625003, -3.58544921875],
            ],
          ],
          [
            [
              [126.71933593750003, 3.874658203124994],
              [126.72177734375003, 3.83251953125],
              [126.66123046875003, 3.928417968749997],
              [126.6375, 4.041943359374997],
              [126.685546875, 4.001416015624997],
              [126.73964843750002, 3.917724609375],
              [126.71933593750003, 3.874658203124994],
            ],
          ],
          [
            [
              [126.85185546874999, 3.768457031249994],
              [126.83554687500003, 3.756933593749991],
              [126.79960937499999, 3.783886718749997],
              [126.77753906250001, 3.813427734374997],
              [126.77890625000003, 3.843164062499994],
              [126.80449218749999, 3.85791015625],
              [126.85703125000003, 3.812402343749994],
              [126.85781250000002, 3.787207031249991],
              [126.85185546874999, 3.768457031249994],
            ],
          ],
          [
            [
              [125.40742187500001, 2.651611328125],
              [125.39726562499999, 2.629541015624994],
              [125.36005859375001, 2.746826171875],
              [125.39082031250001, 2.805371093749997],
              [125.43525390625001, 2.783886718749997],
              [125.44648437500001, 2.762988281249989],
              [125.40390625000003, 2.70703125],
              [125.40742187500001, 2.651611328125],
            ],
          ],
          [
            [
              [131.98203125000003, -7.202050781250009],
              [131.96953125000005, -7.251367187500009],
              [131.92685546875003, -7.225],
              [131.88447265625, -7.16748046875],
              [131.82285156250003, -7.1591796875],
              [131.7775390625, -7.143945312500009],
              [131.75078125000005, -7.116796875],
              [131.92226562500002, -7.1044921875],
              [131.98203125000003, -7.202050781250009],
            ],
          ],
          [
            [
              [128.6701171875, -7.183300781250011],
              [128.625, -7.20859375],
              [128.55019531250002, -7.156347656250006],
              [128.52978515625, -7.134570312500003],
              [128.57734375, -7.083203125000011],
              [128.62773437500005, -7.06875],
              [128.65830078125003, -7.091113281250003],
              [128.67324218750002, -7.113378906250006],
              [128.66689453125002, -7.137988281250003],
              [128.6701171875, -7.183300781250011],
            ],
          ],
          [
            [
              [127.41943359375, -7.623046875],
              [127.35527343750005, -7.646484375],
              [127.375, -7.572460937500011],
              [127.37070312500003, -7.512792968750006],
              [127.47519531250003, -7.531054687500003],
              [127.47402343750002, -7.578515625],
              [127.46396484375003, -7.596875],
              [127.41943359375, -7.623046875],
            ],
          ],
          [
            [
              [123.41621093750001, -10.302636718750009],
              [123.32597656249999, -10.3375],
              [123.32558593750002, -10.26416015625],
              [123.39531249999999, -10.17138671875],
              [123.45878906249999, -10.139941406250003],
              [123.49394531249999, -10.176953125000011],
              [123.49677734375001, -10.193945312500006],
              [123.40507812499999, -10.227148437500006],
              [123.41621093750001, -10.302636718750009],
            ],
          ],
          [
            [
              [121.88300781250001, -10.59033203125],
              [121.83310546875003, -10.602148437500006],
              [121.72617187500003, -10.573144531250009],
              [121.70468750000003, -10.5556640625],
              [121.79628906250002, -10.507421875],
              [121.86699218749999, -10.438867187500009],
              [121.94951171874999, -10.433007812500009],
              [121.99833984374999, -10.446972656250011],
              [121.98134765625002, -10.528417968750006],
              [121.88300781250001, -10.59033203125],
            ],
          ],
          [
            [
              [134.81953125, -6.434179687500006],
              [134.7951171875, -6.4423828125],
              [134.79531250000002, -6.39306640625],
              [134.82294921875, -6.349609375],
              [134.85185546875005, -6.324609375],
              [134.88583984375003, -6.323535156250003],
              [134.81953125, -6.434179687500006],
            ],
          ],
          [
            [
              [134.67441406250003, -6.749804687500003],
              [134.657421875, -6.765332031250011],
              [134.63144531250003, -6.73291015625],
              [134.6291015625, -6.712792968750009],
              [134.66347656250002, -6.65771484375],
              [134.69765625000002, -6.625683593750011],
              [134.73574218750002, -6.623339843750003],
              [134.72607421875, -6.668652343750011],
              [134.67441406250003, -6.749804687500003],
            ],
          ],
          [
            [
              [127.98789062500003, -2.9365234375],
              [127.93769531250001, -3.02001953125],
              [127.849609375, -3.016308593750011],
              [127.83427734375005, -3.00439453125],
              [127.93837890625002, -2.952343750000011],
              [127.98789062500003, -2.9365234375],
            ],
          ],
          [
            [
              [127.60625, -3.315136718750011],
              [127.62929687500002, -3.359179687500003],
              [127.53105468750005, -3.331347656250003],
              [127.48769531250002, -3.288183593750006],
              [127.53046875000001, -3.261523437500003],
              [127.55449218750005, -3.254296875],
              [127.60625, -3.315136718750011],
            ],
          ],
          [
            [
              [122.97734374999999, -8.545214843750003],
              [122.94550781250001, -8.60400390625],
              [122.88779296875003, -8.587304687500009],
              [122.90351562500001, -8.530664062500009],
              [122.93281250000001, -8.4970703125],
              [123.01054687499999, -8.448339843750006],
              [123.08945312500003, -8.43984375],
              [123.13789062500001, -8.456933593750009],
              [123.153125, -8.475781250000011],
              [123.03007812499999, -8.494824218750011],
              [122.97734374999999, -8.545214843750003],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Malaysia",
        sov_a3: "MYS",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Malaysia",
        adm0_a3: "MYS",
        geou_dif: 0,
        geounit: "Malaysia",
        gu_a3: "MYS",
        su_dif: 0,
        subunit: "Malaysia",
        su_a3: "MYS",
        brk_diff: 0,
        name: "Malaysia",
        name_long: "Malaysia",
        brk_a3: "MYS",
        brk_name: "Malaysia",
        brk_group: null,
        abbrev: "Malay.",
        postal: "MY",
        formal_en: "Malaysia",
        formal_fr: null,
        name_ciawf: "Malaysia",
        note_adm0: null,
        note_brk: null,
        name_sort: "Malaysia",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 4,
        mapcolor9: 3,
        mapcolor13: 6,
        pop_est: 31949777,
        pop_rank: 15,
        pop_year: 2019,
        gdp_md: 364681,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        fips_10: "MY",
        iso_a2: "MY",
        iso_a2_eh: "MY",
        iso_a3: "MYS",
        iso_a3_eh: "MYS",
        iso_n3: "458",
        iso_n3_eh: "458",
        un_a3: "458",
        wb_a2: "MY",
        wb_a3: "MYS",
        woe_id: 23424901,
        woe_id_eh: 23424901,
        woe_note: "Exact WOE match as country",
        adm0_iso: "MYS",
        adm0_diff: null,
        adm0_tlc: "MYS",
        adm0_a3_us: "MYS",
        adm0_a3_fr: "MYS",
        adm0_a3_ru: "MYS",
        adm0_a3_es: "MYS",
        adm0_a3_cn: "MYS",
        adm0_a3_tw: "MYS",
        adm0_a3_in: "MYS",
        adm0_a3_np: "MYS",
        adm0_a3_pk: "MYS",
        adm0_a3_de: "MYS",
        adm0_a3_gb: "MYS",
        adm0_a3_br: "MYS",
        adm0_a3_il: "MYS",
        adm0_a3_ps: "MYS",
        adm0_a3_sa: "MYS",
        adm0_a3_eg: "MYS",
        adm0_a3_ma: "MYS",
        adm0_a3_pt: "MYS",
        adm0_a3_ar: "MYS",
        adm0_a3_jp: "MYS",
        adm0_a3_ko: "MYS",
        adm0_a3_vn: "MYS",
        adm0_a3_tr: "MYS",
        adm0_a3_id: "MYS",
        adm0_a3_pl: "MYS",
        adm0_a3_gr: "MYS",
        adm0_a3_it: "MYS",
        adm0_a3_nl: "MYS",
        adm0_a3_se: "MYS",
        adm0_a3_bd: "MYS",
        adm0_a3_ua: "MYS",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "South-Eastern Asia",
        region_wb: "East Asia & Pacific",
        name_len: 8,
        long_len: 8,
        abbrev_len: 6,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: 113.83708,
        label_y: 2.528667,
        ne_id: 1159321083,
        wikidataid: "Q833",
        name_ar: "ماليزيا",
        name_bn: "মালয়েশিয়া",
        name_de: "Malaysia",
        name_en: "Malaysia",
        name_es: "Malasia",
        name_fa: "مالزی",
        name_fr: "Malaisie",
        name_el: "Μαλαισία",
        name_he: "מלזיה",
        name_hi: "मलेशिया",
        name_hu: "Malajzia",
        name_id: "Malaysia",
        name_it: "Malaysia",
        name_ja: "マレーシア",
        name_ko: "말레이시아",
        name_nl: "Maleisië",
        name_pl: "Malezja",
        name_pt: "Malásia",
        name_ru: "Малайзия",
        name_sv: "Malaysia",
        name_tr: "Malezya",
        name_uk: "Малайзія",
        name_ur: "ملائیشیا",
        name_vi: "Malaysia",
        name_zh: "马来西亚",
        name_zht: "馬來西亞",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "MYS.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [100.119140625, 6.441992187499991],
              [100.13798828124999, 6.488671875],
              [100.16123046875003, 6.6416015625],
              [100.1767578125, 6.671826171874997],
              [100.21660156249999, 6.686621093749991],
              [100.26142578125001, 6.682714843749991],
              [100.34541015625001, 6.549902343749991],
              [100.56386718750002, 6.467529296875],
              [100.62949218750003, 6.447998046875],
              [100.715625, 6.480664062499997],
              [100.75449218750003, 6.460058593749991],
              [100.79375, 6.426171875],
              [100.81650390625003, 6.331640625],
              [100.87392578125002, 6.245410156249989],
              [100.98876953125, 6.257666015624991],
              [101.02939453125003, 6.2453125],
              [101.05351562499999, 6.242578125],
              [101.07597656249999, 6.166064453124989],
              [101.08652343750003, 6.03369140625],
              [101.07558593750002, 5.956494140624997],
              [100.99277343750003, 5.84619140625],
              [100.98164062500001, 5.771044921874989],
              [101.02519531249999, 5.724511718749994],
              [101.08173828125001, 5.674902343749991],
              [101.11396484375001, 5.636767578124989],
              [101.14765625000001, 5.64306640625],
              [101.190625, 5.66875],
              [101.22978515624999, 5.733691406249989],
              [101.25703125000001, 5.789355468749989],
              [101.40419921875002, 5.851660156249991],
              [101.55605468750002, 5.907763671874989],
              [101.57675781250003, 5.902001953124994],
              [101.60136718749999, 5.877148437499997],
              [101.65, 5.795996093749991],
              [101.67841796875001, 5.77880859375],
              [101.71953124999999, 5.770605468749991],
              [101.79072265625001, 5.779345703124989],
              [101.87363281250003, 5.825292968749991],
              [101.91718750000001, 5.911376953125],
              [101.93613281250003, 5.979345703124991],
              [102.05517578125, 6.0966796875],
              [102.068359375, 6.184667968749991],
              [102.10107421875, 6.242236328124989],
              [102.27402343750003, 6.203417968749989],
              [102.34013671874999, 6.172021484374994],
              [102.534375, 5.862548828125],
              [102.79023437500001, 5.644921875],
              [102.89853515625003, 5.563769531249989],
              [102.982421875, 5.524951171874989],
              [103.09707031250002, 5.408447265625],
              [103.19697265625001, 5.262158203124997],
              [103.41582031249999, 4.850292968749997],
              [103.45390624999999, 4.669482421874989],
              [103.46875, 4.393261718749997],
              [103.42050781250003, 3.976855468749989],
              [103.36201171875001, 3.769140625],
              [103.37333984374999, 3.67109375],
              [103.45351562500002, 3.520605468749991],
              [103.42949218749999, 3.378564453124994],
              [103.44501953125001, 3.260595703124991],
              [103.439453125, 2.93310546875],
              [103.48515624999999, 2.836572265624994],
              [103.53730468750001, 2.774755859374991],
              [103.81220703125001, 2.58046875],
              [103.83232421874999, 2.508496093749997],
              [103.9677734375, 2.26123046875],
              [104.21855468749999, 1.722851562499997],
              [104.28847656250002, 1.480664062499997],
              [104.28037109375003, 1.415576171874989],
              [104.25009765625003, 1.388574218749994],
              [104.17636718750003, 1.364892578124994],
              [104.11494140625001, 1.412255859374994],
              [104.09423828125, 1.446191406249994],
              [104.1005859375, 1.488330078124989],
              [104.076171875, 1.52978515625],
              [104.01601562500002, 1.579296875],
              [103.9814453125, 1.623632812499991],
              [103.9912109375, 1.550048828125],
              [103.99150390624999, 1.454785156249997],
              [103.91513671875003, 1.446679687499994],
              [103.81679687500002, 1.4765625],
              [103.69453125000001, 1.449658203124997],
              [103.5498046875, 1.332812499999989],
              [103.48027343749999, 1.329492187499994],
              [103.42734375000003, 1.429833984374994],
              [103.4, 1.497851562499989],
              [103.35683593750002, 1.546142578125],
              [102.896875, 1.792333984374991],
              [102.72714843750003, 1.855566406249991],
              [102.54824218750002, 2.042382812499994],
              [102.14560546875003, 2.248486328124997],
              [101.88994140624999, 2.449414062499997],
              [101.78125, 2.573583984374991],
              [101.51972656250001, 2.683642578124989],
              [101.40683593750003, 2.8134765625],
              [101.35136718749999, 2.838964843749991],
              [101.29550781250003, 2.885205078124997],
              [101.35429687499999, 3.011132812499994],
              [101.33017578125003, 3.142480468749994],
              [101.29990234375003, 3.253271484374991],
              [101.11542968750001, 3.472021484374991],
              [101.02480468750002, 3.624707031249997],
              [100.85126953125001, 3.776708984374991],
              [100.78183593750003, 3.864453125],
              [100.71542968750003, 3.966210937499994],
              [100.75703125000001, 4.001806640624991],
              [100.79550781250003, 4.023388671874997],
              [100.76025390625, 4.097216796874989],
              [100.66103515625002, 4.225732421874994],
              [100.61455078124999, 4.3734375],
              [100.61455078124999, 4.652246093749994],
              [100.47343749999999, 5.044287109374991],
              [100.35263671875003, 5.587695312499989],
              [100.3740234375, 5.777978515624994],
              [100.34326171875, 5.984179687499989],
              [100.26328125000003, 6.182519531249994],
              [100.15839843750001, 6.32421875],
              [100.119140625, 6.441992187499991],
            ],
          ],
          [
            [
              [117.57441406250001, 4.170605468749997],
              [117.53730468750001, 4.17138671875],
              [117.45087890625001, 4.19287109375],
              [117.27753906250001, 4.29931640625],
              [117.1005859375, 4.337060546874994],
              [116.84355468749999, 4.340136718749989],
              [116.69785156250003, 4.35498046875],
              [116.638671875, 4.339111328125],
              [116.58906250000001, 4.338427734374989],
              [116.553125, 4.35986328125],
              [116.51474609375003, 4.370800781249997],
              [116.41455078125, 4.308203125],
              [116.36767578125, 4.32734375],
              [116.3203125, 4.353710937499997],
              [116.23623046875002, 4.362548828125],
              [116.13447265625001, 4.355175781249997],
              [116.02158203125003, 4.290673828124994],
              [115.89619140625001, 4.348681640624989],
              [115.86074218750002, 4.348046875],
              [115.83681640625002, 4.333300781249989],
              [115.78242187500001, 4.253759765624991],
              [115.67880859375003, 4.193017578124994],
              [115.62753906250003, 4.081982421874997],
              [115.59609375000002, 3.975537109374997],
              [115.56845703125003, 3.938769531249989],
              [115.56093750000002, 3.733056640624994],
              [115.54453125000003, 3.633691406249994],
              [115.57070312500002, 3.502294921874991],
              [115.56611328125001, 3.445751953124997],
              [115.51992187500002, 3.361669921874991],
              [115.51425781250003, 3.342382812499991],
              [115.48974609375, 3.208642578124994],
              [115.49912109375003, 3.173144531249989],
              [115.4931640625, 3.128125],
              [115.45439453124999, 3.034326171874994],
              [115.38417968750002, 3.008740234374997],
              [115.31015625000003, 2.993945312499989],
              [115.24697265625002, 3.025927734374989],
              [115.18994140625, 2.974462890624991],
              [115.11757812500002, 2.894873046874991],
              [115.08632812500002, 2.841113281249989],
              [115.08652343750003, 2.791210937499997],
              [115.09365234375002, 2.7578125],
              [115.07890624999999, 2.723437499999989],
              [115.07705078125002, 2.68701171875],
              [115.08076171875001, 2.634228515624997],
              [115.1298828125, 2.612402343749991],
              [115.18085937500001, 2.56689453125],
              [115.17910156250002, 2.523193359375],
              [115.15078125000002, 2.492919921875],
              [115.08652343750003, 2.446142578124991],
              [114.96914062500002, 2.350830078125],
              [114.83632812500002, 2.269384765624991],
              [114.78642578124999, 2.25048828125],
              [114.76835937499999, 2.212939453124989],
              [114.75869140625002, 2.162402343749989],
              [114.78798828125002, 2.051611328124991],
              [114.81582031250002, 2.018945312499994],
              [114.83056640625, 1.980029296874989],
              [114.81269531250001, 1.933789062499997],
              [114.8, 1.893945312499994],
              [114.75107421875003, 1.868994140624991],
              [114.70351562500002, 1.85078125],
              [114.68613281250003, 1.819042968749997],
              [114.66093749999999, 1.686279296875],
              [114.63222656250002, 1.617041015624991],
              [114.56748046875003, 1.51416015625],
              [114.5458984375, 1.467138671874991],
              [114.5125, 1.452001953124991],
              [114.38710937500002, 1.500048828124989],
              [114.27470703124999, 1.470898437499997],
              [114.1259765625, 1.45234375],
              [114, 1.455273437499997],
              [113.90234375, 1.434277343749997],
              [113.83525390624999, 1.3798828125],
              [113.76035156250003, 1.311376953124991],
              [113.681640625, 1.260595703124991],
              [113.62226562500001, 1.2359375],
              [113.51318359375, 1.308398437499989],
              [113.45820312500001, 1.302148437499994],
              [113.35898437500003, 1.3271484375],
              [113.12626953124999, 1.408105468749994],
              [113.06865234374999, 1.431787109374994],
              [113.00654296875001, 1.433886718749989],
              [112.98828125, 1.457128906249991],
              [112.998046875, 1.496240234374994],
              [112.98828125, 1.547558593749997],
              [112.94296875000003, 1.566992187499991],
              [112.47617187500003, 1.55908203125],
              [112.34160156249999, 1.514746093749991],
              [112.25068359375001, 1.479638671874994],
              [112.18574218750001, 1.4390625],
              [112.16738281250002, 1.338183593749989],
              [112.12861328125001, 1.243603515624997],
              [112.07851562500002, 1.143359374999989],
              [111.92314453124999, 1.11328125],
              [111.80898437500002, 1.011669921874997],
              [111.76972656250001, 0.999462890624997],
              [111.69130859375002, 1.014208984374989],
              [111.607421875, 1.022607421874994],
              [111.54667968749999, 0.994335937499997],
              [111.48320312499999, 0.995751953124994],
              [111.28671875000003, 1.043212890625],
              [111.10136718749999, 1.050537109375],
              [110.99609375, 1.0263671875],
              [110.93808593750003, 1.017333984375],
              [110.61474609375, 0.878125],
              [110.50576171875002, 0.861962890624994],
              [110.46142578125, 0.882080078125],
              [110.39902343750003, 0.9390625],
              [110.31523437499999, 0.995996093749994],
              [110.11474609375, 1.190136718749997],
              [110.04082031249999, 1.235742187499994],
              [109.99169921875, 1.282568359374991],
              [109.94492187500003, 1.338037109374994],
              [109.87851562500003, 1.397851562499994],
              [109.81806640625001, 1.43896484375],
              [109.73574218750002, 1.52294921875],
              [109.65400390625001, 1.614892578124994],
              [109.63583984375003, 1.776660156249989],
              [109.57080078125, 1.806298828124994],
              [109.54892578125003, 1.848339843749997],
              [109.53896484375002, 1.896191406249997],
              [109.62890625, 2.027539062499997],
              [109.6943359375, 1.888769531249991],
              [109.71962890625002, 1.8578125],
              [109.86484375000003, 1.764453124999989],
              [109.98457031250001, 1.717626953124991],
              [110.11406249999999, 1.698583984374991],
              [110.24589843749999, 1.694726562499994],
              [110.29833984375, 1.701171875],
              [110.34921875000003, 1.7197265625],
              [110.39951171875003, 1.699853515624994],
              [110.67519531250002, 1.548046875],
              [110.78203124999999, 1.520849609374991],
              [110.89492187500002, 1.532470703125],
              [110.93994140625, 1.517333984375],
              [111.09843749999999, 1.40087890625],
              [111.14521484375001, 1.386962890625],
              [111.22324218750003, 1.395849609374991],
              [111.12343750000002, 1.449023437499989],
              [111.05800781250002, 1.486669921874991],
              [111.02871093750002, 1.5578125],
              [111.04658203125001, 1.633642578124991],
              [111.11015624999999, 1.68408203125],
              [111.15419921875002, 1.73876953125],
              [111.17001953125003, 1.902294921874997],
              [111.19804687499999, 1.985107421875],
              [111.25087890625002, 2.063867187499994],
              [111.26816406250003, 2.139746093749991],
              [111.20888671875002, 2.19765625],
              [111.19550781250001, 2.297167968749989],
              [111.20859375000003, 2.379638671875],
              [111.2421875, 2.435742187499997],
              [111.2958984375, 2.398779296874991],
              [111.35136718749999, 2.364453125],
              [111.40615234375002, 2.367871093749997],
              [111.44384765625, 2.381542968749997],
              [111.45078125000003, 2.424072265625],
              [111.4404296875, 2.498095703124989],
              [111.44326171875002, 2.634326171874989],
              [111.5125, 2.743017578124991],
              [111.62324218750001, 2.81796875],
              [111.72773437500001, 2.853808593749989],
              [112.11884765625001, 2.914697265624994],
              [112.7373046875, 3.070458984374994],
              [112.92050781250003, 3.130712890624991],
              [112.98789062500003, 3.161914062499989],
              [113.04472656249999, 3.205224609374994],
              [113.14023437500003, 3.343505859375],
              [113.32011718749999, 3.561474609374997],
              [113.44609374999999, 3.740576171874991],
              [113.71210937500001, 4.001416015624997],
              [113.92392578125003, 4.243212890624989],
              [113.95253906250002, 4.288720703124994],
              [113.98779296875, 4.420703124999989],
              [113.99042968750001, 4.4828125],
              [113.98427734375002, 4.545800781249994],
              [114.0125, 4.575244140624989],
              [114.05361328125002, 4.592871093749991],
              [114.06386718750002, 4.592675781249994],
              [114.09511718750002, 4.565234374999989],
              [114.16884765625002, 4.526953125],
              [114.22412109375, 4.477880859374991],
              [114.26103515624999, 4.414257812499997],
              [114.28759765625, 4.354736328125],
              [114.28964843750003, 4.30419921875],
              [114.32294921875001, 4.262792968749991],
              [114.41660156250003, 4.255859375],
              [114.44707031249999, 4.203564453124997],
              [114.51220703125, 4.113574218749989],
              [114.57177734375, 4.049072265625],
              [114.60830078125002, 4.023974609374989],
              [114.65410156249999, 4.037646484374989],
              [114.725, 4.096533203124991],
              [114.77617187499999, 4.168798828124991],
              [114.81044921875002, 4.266503906249994],
              [114.78349609374999, 4.28076171875],
              [114.8310546875, 4.3544921875],
              [114.84023437500002, 4.393212890624994],
              [114.81826171875002, 4.428759765624989],
              [114.79013671875003, 4.463916015624989],
              [114.779296875, 4.553027343749989],
              [114.75996093750001, 4.66650390625],
              [114.74667968750003, 4.718066406249989],
              [114.7841796875, 4.754833984374997],
              [114.86455078124999, 4.8017578125],
              [114.94472656250002, 4.85625],
              [115.02675781250002, 4.899707031249989],
              [115.02880859375, 4.821142578124991],
              [115.02675781250002, 4.691357421874997],
              [115.05156249999999, 4.582666015624994],
              [115.10703125000003, 4.390429687499989],
              [115.17060546875001, 4.364208984374997],
              [115.24667968750003, 4.347216796874989],
              [115.290625, 4.352587890624989],
              [115.31923828125002, 4.365283203124989],
              [115.32675781250003, 4.380761718749994],
              [115.279296875, 4.456347656249989],
              [115.26669921875003, 4.633984375],
              [115.22792968750002, 4.750585937499991],
              [115.16845703125, 4.86669921875],
              [115.14003906250002, 4.899755859374991],
              [115.37490234375002, 4.932763671874994],
              [115.42763671875002, 4.969189453124997],
              [115.51982421874999, 5.048925781249991],
              [115.55449218749999, 5.093554687499989],
              [115.58203125, 5.194140624999989],
              [115.46689453125003, 5.254101562499997],
              [115.42167968749999, 5.330517578124997],
              [115.41904296875003, 5.413183593749991],
              [115.55644531249999, 5.566699218749989],
              [115.60390625000002, 5.603417968749994],
              [115.62451171875, 5.548876953124989],
              [115.68505859375, 5.535107421874997],
              [115.74082031250003, 5.533007812499989],
              [115.796875, 5.5361328125],
              [115.87714843750001, 5.613525390625],
              [115.91845703125, 5.724951171874991],
              [116.05976562500001, 5.882373046874989],
              [116.11005859375001, 6.003271484374991],
              [116.13837890625001, 6.129541015624994],
              [116.49472656250003, 6.521679687499997],
              [116.53828125000001, 6.582714843749997],
              [116.74980468749999, 6.977099609374989],
              [116.77617187499999, 6.990234375],
              [116.8330078125, 6.952050781249994],
              [116.84980468750001, 6.826708984374989],
              [116.84199218750001, 6.772070312499991],
              [116.80771484375003, 6.691064453124994],
              [116.7880859375, 6.606103515624994],
              [116.81240234375002, 6.60791015625],
              [116.91328125000001, 6.65966796875],
              [117.0185546875, 6.79736328125],
              [117.07792968749999, 6.916845703124991],
              [117.12851562500003, 6.968896484374994],
              [117.22988281250002, 6.939990234374989],
              [117.25244140625, 6.919238281249989],
              [117.24531250000001, 6.833398437499994],
              [117.25498046875003, 6.783447265625],
              [117.29404296875003, 6.676904296874994],
              [117.38037109375, 6.612255859374997],
              [117.49921875000001, 6.571484375],
              [117.60966796874999, 6.512646484374997],
              [117.64570312500001, 6.473681640624989],
              [117.66962890625001, 6.4267578125],
              [117.69375, 6.35],
              [117.69560546874999, 6.272314453124991],
              [117.61591796875001, 6.196533203125],
              [117.64980468750002, 6.073583984374991],
              [117.64453125, 6.001855468749994],
              [117.6171875, 5.940722656249989],
              [117.50117187500001, 5.884667968749994],
              [117.81767578124999, 5.9404296875],
              [117.89580078124999, 5.972265625],
              [118.00380859375002, 6.053320312499991],
              [118.06171875000001, 5.983447265624989],
              [118.11582031250003, 5.8625],
              [118.072265625, 5.832080078124989],
              [117.93476562500001, 5.7875],
              [117.92802734374999, 5.769189453124994],
              [117.9736328125, 5.70625],
              [118.03115234375002, 5.712109375],
              [118.14462890625003, 5.754199218749989],
              [118.24912109375003, 5.820556640625],
              [118.2998046875, 5.819726562499994],
              [118.353125, 5.806054687499994],
              [118.45634765624999, 5.763427734375],
              [118.51416015625, 5.72890625],
              [118.56308593750003, 5.684521484374997],
              [118.59482421875003, 5.592089843749989],
              [118.71367187499999, 5.558544921874997],
              [118.95732421874999, 5.429003906249989],
              [119.00253906250003, 5.417822265624991],
              [119.05, 5.415234375],
              [119.17841796875001, 5.430908203125],
              [119.22343749999999, 5.412646484374989],
              [119.25556640625001, 5.365917968749997],
              [119.26630859375001, 5.30810546875],
              [119.26279296875003, 5.245898437499989],
              [119.24970703125001, 5.19873046875],
              [119.21962890625002, 5.159814453124994],
              [119.13222656250002, 5.100488281249994],
              [118.9125, 5.022900390624997],
              [118.67207031250001, 4.9640625],
              [118.55136718750003, 4.968115234374991],
              [118.3818359375, 5.018505859374997],
              [118.32001953125001, 5.012011718749989],
              [118.26054687499999, 4.988867187499991],
              [118.18535156249999, 4.828515625],
              [118.32421875, 4.668701171875],
              [118.5625, 4.502148437499997],
              [118.59511718750002, 4.460644531249997],
              [118.58632812500002, 4.40966796875],
              [118.54833984375, 4.379248046874991],
              [118.498046875, 4.362353515624989],
              [118.36406249999999, 4.335742187499989],
              [118.22871093750001, 4.316015625],
              [118.11728515625003, 4.28759765625],
              [118.00820312500002, 4.250244140625],
              [117.89560546875003, 4.262939453125],
              [117.74101562499999, 4.337548828124994],
              [117.69648437500001, 4.342822265624989],
              [117.64980468750002, 4.304492187499989],
              [117.60380859374999, 4.2],
              [117.57441406250001, 4.170605468749997],
            ],
          ],
          [
            [
              [104.22158203125002, 2.731738281249989],
              [104.17333984375, 2.721337890624994],
              [104.146875, 2.728222656249997],
              [104.12910156250001, 2.767236328124994],
              [104.16982421875002, 2.856835937499994],
              [104.18476562500001, 2.871728515624994],
              [104.22324218750003, 2.774218749999989],
              [104.22158203125002, 2.731738281249989],
            ],
          ],
          [
            [
              [100.28896484375002, 5.294726562499989],
              [100.26376953125003, 5.266992187499994],
              [100.19101562500003, 5.282861328124994],
              [100.20390624999999, 5.446875],
              [100.24550781250002, 5.4677734375],
              [100.31015625000003, 5.437939453124997],
              [100.3388671875, 5.410058593749994],
              [100.28896484375002, 5.294726562499989],
            ],
          ],
          [
            [
              [99.84804687500002, 6.465722656249994],
              [99.91865234375001, 6.35859375],
              [99.88339843750003, 6.310839843749989],
              [99.86582031250003, 6.297070312499997],
              [99.8232421875, 6.312744140625],
              [99.78261718750002, 6.271582031249991],
              [99.74375, 6.26328125],
              [99.70468750000003, 6.337548828124994],
              [99.65664062500002, 6.367138671874997],
              [99.64628906249999, 6.418359375],
              [99.71054687500003, 6.42734375],
              [99.74921875000001, 6.409619140624997],
              [99.82167968750002, 6.445019531249997],
              [99.84804687500002, 6.465722656249994],
            ],
          ],
          [
            [
              [117.1416015625, 7.168212890625],
              [117.08066406250003, 7.115283203124989],
              [117.06015625000003, 7.178857421874994],
              [117.06425781249999, 7.260693359374997],
              [117.146875, 7.337011718749991],
              [117.26406250000002, 7.351660156249991],
              [117.28076171875, 7.290625],
              [117.26679687500001, 7.220800781249991],
              [117.23935546875003, 7.184765625],
              [117.1416015625, 7.168212890625],
            ],
          ],
          [
            [
              [101.31855468750001, 2.988476562499997],
              [101.26806640625, 2.970410156249997],
              [101.26542968749999, 2.996484375],
              [101.27421874999999, 3.0328125],
              [101.31123046875001, 3.0673828125],
              [101.32841796874999, 3.047607421875],
              [101.31855468750001, 2.988476562499997],
            ],
          ],
          [
            [
              [111.38925781250003, 2.415332031249989],
              [111.35869140624999, 2.402197265624991],
              [111.3115234375, 2.437597656249991],
              [111.30039062500003, 2.741162109374997],
              [111.33349609375, 2.768310546875],
              [111.35507812500003, 2.764453124999989],
              [111.37832031250002, 2.709326171874991],
              [111.37626953124999, 2.576318359374994],
              [111.38046875000003, 2.458935546874997],
              [111.38925781250003, 2.415332031249989],
            ],
          ],
          [
            [
              [117.884765625, 4.186132812499991],
              [117.74541015624999, 4.166943359374997],
              [117.64902343750003, 4.168994140624989],
              [117.66679687499999, 4.204003906249994],
              [117.662109375, 4.250195312499997],
              [117.7080078125, 4.262402343749997],
              [117.76142578125001, 4.25234375],
              [117.884765625, 4.186132812499991],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 5,
        sovereignt: "Cyprus",
        sov_a3: "CYP",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Cyprus",
        adm0_a3: "CYP",
        geou_dif: 0,
        geounit: "Cyprus",
        gu_a3: "CYP",
        su_dif: 0,
        subunit: "Cyprus",
        su_a3: "CYP",
        brk_diff: 0,
        name: "Cyprus",
        name_long: "Cyprus",
        brk_a3: "CYP",
        brk_name: "Cyprus",
        brk_group: null,
        abbrev: "Cyp.",
        postal: "CY",
        formal_en: "Republic of Cyprus",
        formal_fr: null,
        name_ciawf: "Cyprus",
        note_adm0: null,
        note_brk: null,
        name_sort: "Cyprus",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 2,
        mapcolor9: 3,
        mapcolor13: 7,
        pop_est: 1198575,
        pop_rank: 12,
        pop_year: 2019,
        gdp_md: 24948,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "2. High income: nonOECD",
        fips_10: "CY",
        iso_a2: "CY",
        iso_a2_eh: "CY",
        iso_a3: "CYP",
        iso_a3_eh: "CYP",
        iso_n3: "196",
        iso_n3_eh: "196",
        un_a3: "196",
        wb_a2: "CY",
        wb_a3: "CYP",
        woe_id: -90,
        woe_id_eh: 23424994,
        woe_note: "WOE lists as subunit of united Cyprus",
        adm0_iso: "CYP",
        adm0_diff: null,
        adm0_tlc: "CYP",
        adm0_a3_us: "CYP",
        adm0_a3_fr: "CYP",
        adm0_a3_ru: "CYP",
        adm0_a3_es: "CYP",
        adm0_a3_cn: "CYP",
        adm0_a3_tw: "CYP",
        adm0_a3_in: "CYP",
        adm0_a3_np: "CYP",
        adm0_a3_pk: "CYP",
        adm0_a3_de: "CYP",
        adm0_a3_gb: "CYP",
        adm0_a3_br: "CYP",
        adm0_a3_il: "CYP",
        adm0_a3_ps: "CYP",
        adm0_a3_sa: "CYP",
        adm0_a3_eg: "CYP",
        adm0_a3_ma: "CYP",
        adm0_a3_pt: "CYP",
        adm0_a3_ar: "CYP",
        adm0_a3_jp: "CYP",
        adm0_a3_ko: "CYP",
        adm0_a3_vn: "CYP",
        adm0_a3_tr: "CYP",
        adm0_a3_id: "CYP",
        adm0_a3_pl: "CYP",
        adm0_a3_gr: "CYP",
        adm0_a3_it: "CYP",
        adm0_a3_nl: "CYP",
        adm0_a3_se: "CYP",
        adm0_a3_bd: "CYP",
        adm0_a3_ua: "CYP",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Western Asia",
        region_wb: "Europe & Central Asia",
        name_len: 6,
        long_len: 6,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4.5,
        max_label: 9.5,
        label_x: 33.084182,
        label_y: 34.913329,
        ne_id: 1159320533,
        wikidataid: "Q229",
        name_ar: "قبرص",
        name_bn: "সাইপ্রাস",
        name_de: "Republik Zypern",
        name_en: "Cyprus",
        name_es: "Chipre",
        name_fa: "قبرس",
        name_fr: "Chypre",
        name_el: "Κύπρος",
        name_he: "קפריסין",
        name_hi: "साइप्रस",
        name_hu: "Ciprus",
        name_id: "Siprus",
        name_it: "Cipro",
        name_ja: "キプロス",
        name_ko: "키프로스",
        name_nl: "Cyprus",
        name_pl: "Cypr",
        name_pt: "Chipre",
        name_ru: "Кипр",
        name_sv: "Cypern",
        name_tr: "Kıbrıs Cumhuriyeti",
        name_uk: "Кіпр",
        name_ur: "قبرص",
        name_vi: "Cộng hòa Síp",
        name_zh: "塞浦路斯",
        name_zht: "賽普勒斯",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "CYP.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [32.71269531250002, 35.171044921874994],
            [32.72021484375, 35.145361328125],
            [32.78408203125002, 35.115771484374996],
            [32.86943359375002, 35.089404296874996],
            [32.91953125, 35.087841796875],
            [32.9859375, 35.11640625],
            [33.07753906250002, 35.14619140625],
            [33.191015625, 35.173144531249996],
            [33.24833984375002, 35.15693359375],
            [33.32558593750002, 35.153613281249996],
            [33.3837890625, 35.1626953125],
            [33.42421875000002, 35.140917968749996],
            [33.45595703125002, 35.101416015625],
            [33.4638671875, 35.004931640624996],
            [33.47578125000001, 35.000341796875],
            [33.52568359375002, 35.038671875],
            [33.61445312500001, 35.022753906249996],
            [33.675390625, 35.017871093749996],
            [33.72578125000001, 35.0373046875],
            [33.756933593750006, 35.03974609375],
            [33.79228515625002, 35.048193359375],
            [33.83203125, 35.0671875],
            [33.86640625000001, 35.093603515625],
            [33.9033203125, 35.08544921875],
            [33.96572265625002, 35.056787109374994],
            [34.004492187500006, 35.065234375],
            [34.02363281250001, 35.045556640624994],
            [34.05019531250002, 34.98837890625],
            [33.9365234375, 34.971484375],
            [33.82246093750001, 34.96591796875],
            [33.75898437500001, 34.9732421875],
            [33.69941406250001, 34.969873046874994],
            [33.51445312500002, 34.806445312499996],
            [33.41494140625002, 34.750878906249994],
            [33.29658203125001, 34.717724609375],
            [33.17607421875002, 34.698046875],
            [33.11552734375002, 34.695556640625],
            [33.06230468750002, 34.6748046875],
            [33.02490234375, 34.6369140625],
            [33.02392578125, 34.6],
            [33.007910156250006, 34.569580078125],
            [32.94179687500002, 34.57587890625],
            [32.91425781250001, 34.635498046875],
            [32.8671875, 34.6611328125],
            [32.750097656250006, 34.647802734375],
            [32.69296875, 34.649365234375],
            [32.50556640625001, 34.70625],
            [32.44902343750002, 34.729443359375],
            [32.41376953125001, 34.77802734375],
            [32.31718750000002, 34.9533203125],
            [32.30097656250001, 35.082958984375],
            [32.39091796875002, 35.0498046875],
            [32.475, 35.089990234374994],
            [32.55595703125002, 35.15576171875],
            [32.65234375, 35.182666015624996],
            [32.71269531250002, 35.171044921874994],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 2,
        sovereignt: "India",
        sov_a3: "IND",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "India",
        adm0_a3: "IND",
        geou_dif: 0,
        geounit: "India",
        gu_a3: "IND",
        su_dif: 0,
        subunit: "India",
        su_a3: "IND",
        brk_diff: 0,
        name: "India",
        name_long: "India",
        brk_a3: "IND",
        brk_name: "India",
        brk_group: null,
        abbrev: "India",
        postal: "IND",
        formal_en: "Republic of India",
        formal_fr: null,
        name_ciawf: "India",
        note_adm0: null,
        note_brk: null,
        name_sort: "India",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 3,
        mapcolor9: 2,
        mapcolor13: 2,
        pop_est: 1366417754,
        pop_rank: 18,
        pop_year: 2019,
        gdp_md: 2868929,
        gdp_year: 2019,
        economy: "3. Emerging region: BRIC",
        income_grp: "4. Lower middle income",
        fips_10: "IN",
        iso_a2: "IN",
        iso_a2_eh: "IN",
        iso_a3: "IND",
        iso_a3_eh: "IND",
        iso_n3: "356",
        iso_n3_eh: "356",
        un_a3: "356",
        wb_a2: "IN",
        wb_a3: "IND",
        woe_id: 23424848,
        woe_id_eh: 23424848,
        woe_note: "Exact WOE match as country",
        adm0_iso: "IND",
        adm0_diff: null,
        adm0_tlc: "IND",
        adm0_a3_us: "IND",
        adm0_a3_fr: "IND",
        adm0_a3_ru: "IND",
        adm0_a3_es: "IND",
        adm0_a3_cn: "IND",
        adm0_a3_tw: "IND",
        adm0_a3_in: "IND",
        adm0_a3_np: "IND",
        adm0_a3_pk: "IND",
        adm0_a3_de: "IND",
        adm0_a3_gb: "IND",
        adm0_a3_br: "IND",
        adm0_a3_il: "IND",
        adm0_a3_ps: "IND",
        adm0_a3_sa: "IND",
        adm0_a3_eg: "IND",
        adm0_a3_ma: "IND",
        adm0_a3_pt: "IND",
        adm0_a3_ar: "IND",
        adm0_a3_jp: "IND",
        adm0_a3_ko: "IND",
        adm0_a3_vn: "IND",
        adm0_a3_tr: "IND",
        adm0_a3_id: "IND",
        adm0_a3_pl: "IND",
        adm0_a3_gr: "IND",
        adm0_a3_it: "IND",
        adm0_a3_nl: "IND",
        adm0_a3_se: "IND",
        adm0_a3_bd: "IND",
        adm0_a3_ua: "IND",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Southern Asia",
        region_wb: "South Asia",
        name_len: 5,
        long_len: 5,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 1.7,
        max_label: 6.7,
        label_x: 79.358105,
        label_y: 22.686852,
        ne_id: 1159320847,
        wikidataid: "Q668",
        name_ar: "الهند",
        name_bn: "ভারত",
        name_de: "Indien",
        name_en: "India",
        name_es: "India",
        name_fa: "هند",
        name_fr: "Inde",
        name_el: "Ινδία",
        name_he: "הודו",
        name_hi: "भारत",
        name_hu: "India",
        name_id: "India",
        name_it: "India",
        name_ja: "インド",
        name_ko: "인도",
        name_nl: "India",
        name_pl: "Indie",
        name_pt: "Índia",
        name_ru: "Индия",
        name_sv: "Indien",
        name_tr: "Hindistan",
        name_uk: "Індія",
        name_ur: "بھارت",
        name_vi: "Ấn Độ",
        name_zh: "印度",
        name_zht: "印度",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "IND.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [68.1650390625, 23.857324218749994],
              [68.23417968750002, 23.900537109374994],
              [68.28251953125002, 23.927978515625],
              [68.38125, 23.950878906249997],
              [68.48867187500002, 23.967236328124997],
              [68.58662109375001, 23.966601562500003],
              [68.72412109375, 23.964697265625034],
              [68.728125, 24.265625],
              [68.73964843750002, 24.2919921875],
              [68.75898437499995, 24.307226562500034],
              [68.78115234375002, 24.313720703125],
              [68.8, 24.309082031250057],
              [68.82832031250001, 24.26401367187499],
              [68.86347656250001, 24.266503906249994],
              [68.90078125000002, 24.292431640624997],
              [68.98457031250001, 24.273095703124994],
              [69.05156250000002, 24.286328125],
              [69.11953125000002, 24.26865234374999],
              [69.23505859374995, 24.268261718750068],
              [69.44345703124998, 24.275390625000114],
              [69.5591796875, 24.273095703124994],
              [69.63417968750002, 24.22519531249999],
              [69.71621093750002, 24.172607421875],
              [69.80517578125, 24.165234375],
              [69.93378906250001, 24.17138671875],
              [70.02109375, 24.191552734374994],
              [70.06513671874995, 24.24057617187492],
              [70.09824218749998, 24.2875],
              [70.2890625, 24.35629882812499],
              [70.4892578125, 24.412158203125003],
              [70.54677734375002, 24.41831054687499],
              [70.5650390625, 24.385791015625003],
              [70.55585937500001, 24.331103515625003],
              [70.57929687500001, 24.279052734375],
              [70.65947265625002, 24.24609375],
              [70.71630859375, 24.237988281249997],
              [70.76728515625001, 24.245410156250045],
              [70.80507812500002, 24.261962890625],
              [70.88623046875, 24.34375],
              [70.928125, 24.362353515625003],
              [70.98281250000002, 24.361035156249997],
              [71.04404296875, 24.400097656249997],
              [71.04531250000002, 24.42998046874999],
              [71.00625, 24.4443359375],
              [70.9732421875, 24.48740234374999],
              [70.97929687500002, 24.5224609375],
              [70.96982421875, 24.571875],
              [70.97636718750002, 24.61875],
              [71.00234375000002, 24.65390625],
              [71.0478515625, 24.687744140625057],
              [71.02070312500001, 24.75766601562499],
              [70.95087890625001, 24.8916015625],
              [70.87773437500002, 25.06298828125],
              [70.80048828125001, 25.205859375],
              [70.70253906249997, 25.331054687500057],
              [70.65205078125001, 25.422900390625102],
              [70.6572265625, 25.62578125],
              [70.6484375, 25.666943359375068],
              [70.61484375, 25.69189453125],
              [70.56953125000001, 25.705957031249994],
              [70.505859375, 25.685302734375],
              [70.44853515625002, 25.681347656249997],
              [70.3251953125, 25.685742187499997],
              [70.2646484375, 25.70654296875],
              [70.1001953125, 25.910058593749994],
              [70.07861328125, 25.990039062500003],
              [70.077734375, 26.071972656249997],
              [70.13261718750002, 26.214794921874997],
              [70.14921875000002, 26.347558593749994],
              [70.1568359375, 26.471435546875],
              [70.14765625000001, 26.5064453125],
              [70.11464843750002, 26.548046875],
              [70.059375, 26.578759765624994],
              [69.91142578125002, 26.586132812499997],
              [69.7359375, 26.62705078125],
              [69.6005859375, 26.699121093750023],
              [69.50693359374992, 26.742675781250057],
              [69.48125, 26.77099609375],
              [69.47001953125002, 26.804443359375],
              [69.49453125000002, 26.954150390624996],
              [69.53701171875002, 27.122949218749994],
              [69.56796874999992, 27.174609375000102],
              [69.62158203125, 27.228076171874996],
              [69.66132812500001, 27.264501953125],
              [69.72480468750001, 27.312695312499997],
              [69.89628906250002, 27.4736328125],
              [70.0498046875, 27.694726562499994],
              [70.14453125, 27.849023437500023],
              [70.1939453125, 27.894873046875],
              [70.24433593750001, 27.934130859374996],
              [70.31845703125, 27.981640625],
              [70.40371093750002, 28.025048828124966],
              [70.48857421875002, 28.023144531249997],
              [70.56923828125002, 27.983789062500136],
              [70.62910156250001, 27.937451171874997],
              [70.64912109375001, 27.835351562499994],
              [70.69160156250001, 27.768994140624997],
              [70.73740234374998, 27.729003906250057],
              [70.79794921875, 27.709619140624994],
              [70.87490234375002, 27.714453125],
              [71.18476562500001, 27.831640625],
              [71.29013671874998, 27.855273437500045],
              [71.54296874999994, 27.869873046875],
              [71.71669921875002, 27.915087890624996],
              [71.87031250000001, 27.9625],
              [71.88886718750001, 28.0474609375],
              [71.94804687500002, 28.177294921874996],
              [72.128515625, 28.346337890624994],
              [72.17919921875, 28.421777343749994],
              [72.23388671875, 28.565820312499994],
              [72.29199218749994, 28.697265625],
              [72.34189453125, 28.751904296874997],
              [72.6255859375, 28.896142578124994],
              [72.9033203125, 29.028759765624997],
              [72.94873046875, 29.088818359374997],
              [73.12832031250002, 29.36391601562508],
              [73.23115234375001, 29.55063476562509],
              [73.2578125, 29.610693359375],
              [73.31728515625002, 29.772998046874996],
              [73.38164062500002, 29.934375],
              [73.46748046875001, 29.971679687500114],
              [73.65800781250002, 30.033203125],
              [73.80917968749998, 30.093359375000034],
              [73.88652343750002, 30.162011718749994],
              [73.93339843750002, 30.222070312499994],
              [73.92460937500002, 30.281640625],
              [73.88271484375002, 30.3521484375],
              [73.8916015625, 30.39404296875],
              [73.89931640625002, 30.435351562500045],
              [74.00898437500001, 30.519677734375023],
              [74.215625, 30.768994140624997],
              [74.33935546875, 30.8935546875],
              [74.38037109375, 30.893408203125],
              [74.509765625, 30.959667968749997],
              [74.6328125, 31.03466796875],
              [74.62578125000002, 31.06875],
              [74.6103515625, 31.112841796874996],
              [74.53974609375001, 31.132666015625],
              [74.51767578125, 31.185595703124996],
              [74.53496093750002, 31.261376953124994],
              [74.59394531249995, 31.465380859375102],
              [74.58183593750002, 31.52392578125],
              [74.50996093750001, 31.712939453124996],
              [74.52597656249998, 31.765136718750057],
              [74.55556640625002, 31.818554687499997],
              [74.63574218749994, 31.889746093750034],
              [74.73945312500001, 31.948828125],
              [75.07148437500001, 32.08935546875],
              [75.13876953124998, 32.10478515624999],
              [75.25410156250001, 32.140332031250125],
              [75.32470703125, 32.215283203125125],
              [75.33349609375, 32.279199218749966],
              [75.30263671875002, 32.318896484374996],
              [75.23369140625002, 32.372119140624996],
              [75.10410156249998, 32.420361328124955],
              [74.9873046875, 32.46220703124999],
              [74.78886718750002, 32.4578125],
              [74.68574218750001, 32.49379882812502],
              [74.6578125, 32.518945312499994],
              [74.64335937500002, 32.607714843749996],
              [74.66328125000001, 32.757666015625],
              [74.63242187499998, 32.770898437500136],
              [74.58828125000002, 32.753222656249996],
              [74.4833984375, 32.77099609375],
              [74.35458984375, 32.768701171874994],
              [74.30546874999999, 32.81044921875002],
              [74.32998046875002, 32.86083984375],
              [74.32275390625, 32.927978515625],
              [74.30361328124997, 32.991796875000034],
              [74.28359374999997, 33.005126953125114],
              [74.22207031250002, 33.0203125],
              [74.12626953125002, 33.075439453125],
              [74.04912109374999, 33.143408203125034],
              [74.00380859375002, 33.189453125],
              [73.98984375, 33.22119140625],
              [73.99423828125, 33.2421875],
              [74.050390625, 33.30126953125],
              [74.11777343749998, 33.384130859375034],
              [74.142578125, 33.455371093749996],
              [74.15, 33.50698242187502],
              [74.13125, 33.545068359374994],
              [74.06972656250002, 33.591699218749994],
              [74.00400390625, 33.632421875],
              [73.9775390625, 33.66782226562509],
              [73.97646484375002, 33.7212890625],
              [74.00097656249994, 33.78818359375009],
              [74.07841796875002, 33.838671875],
              [74.215625, 33.886572265625],
              [74.25087890625002, 33.94609375],
              [74.24648437500002, 33.990185546875],
              [74.208984375, 34.00341796875],
              [74.11259765625002, 34.003710937499996],
              [73.94990234375001, 34.018798828125],
              [73.92236328125, 34.04306640625],
              [73.90410156250002, 34.07568359375],
              [73.90390625, 34.1080078125],
              [73.93828125000002, 34.144775390625],
              [73.9794921875, 34.191308593749994],
              [73.97236328125001, 34.23662109374999],
              [73.92460937500002, 34.287841796875],
              [73.80996093750002, 34.325341796874994],
              [73.79453125, 34.378222656249996],
              [73.812109375, 34.42236328125],
              [73.85009765624994, 34.48530273437501],
              [73.88310546875002, 34.529052734375],
              [73.96123046875002, 34.653466796874994],
              [74.05585937500001, 34.6806640625],
              [74.17197265624998, 34.72089843750007],
              [74.300390625, 34.765380859375],
              [74.49794921875002, 34.73203125],
              [74.59414062500002, 34.71577148437507],
              [74.78876953125001, 34.677734375],
              [74.95185546875001, 34.645849609375],
              [75.11845703125002, 34.63681640625],
              [75.1875, 34.639013671875],
              [75.26406250000002, 34.601367187499996],
              [75.45253906250002, 34.53671875],
              [75.60556640625, 34.502734375],
              [75.70917968750001, 34.50307617187508],
              [75.86210937500002, 34.56025390625],
              [75.93828125000002, 34.612548828125],
              [76.041015625, 34.669921875],
              [76.17246093750003, 34.667724609375],
              [76.45673828125001, 34.756103515625],
              [76.50996093750001, 34.740869140624994],
              [76.59443359375001, 34.73583984375],
              [76.6962890625, 34.78691406249999],
              [76.7490234375, 34.847558593749994],
              [76.75751953125001, 34.877832031249994],
              [76.78291015625001, 34.900195312499996],
              [76.89169921875003, 34.938720703125],
              [77.00087890625002, 34.991992187499996],
              [77.03066406250002, 35.062353515625],
              [77.04863281249999, 35.109912109374996],
              [77.16855468750003, 35.171533203124994],
              [77.29296875, 35.235546875],
              [77.42343749999998, 35.30258789062509],
              [77.57158203124999, 35.378759765625],
              [77.69697265625001, 35.443261718749994],
              [77.79941406250003, 35.495898437500045],
              [77.80253906249999, 35.4927734375],
              [77.81093750000002, 35.484521484374994],
              [77.8515625, 35.460791015625],
              [77.89492187500002, 35.449023437499996],
              [77.94589843750003, 35.47163085937507],
              [78.00947265625001, 35.490234375000114],
              [78.04267578125001, 35.4797851562501],
              [78.04746093750003, 35.4494140625],
              [78.00917968750002, 35.306933593749996],
              [78.01220703125, 35.251025390624996],
              [78.07578125000003, 35.134912109374994],
              [78.15849609374999, 34.94648437500001],
              [78.23613281249999, 34.769824218750045],
              [78.28203124999999, 34.65390625],
              [78.32695312499999, 34.606396484375],
              [78.51572265625003, 34.557958984375],
              [78.67080078125002, 34.5181640625],
              [78.76308593750002, 34.452929687499996],
              [78.86484375000003, 34.39033203125],
              [78.93642578125002, 34.35195312500002],
              [78.97011718750002, 34.302636718749994],
              [78.97695312500002, 34.258105468749996],
              [78.97060546875002, 34.22822265625],
              [78.93173828125003, 34.18896484375],
              [78.75302734375003, 34.087695312499996],
              [78.73173828124999, 34.055566406249994],
              [78.72666015625003, 34.01337890625],
              [78.76171874999994, 33.88759765625008],
              [78.78378906250003, 33.8087890625],
              [78.78994140625002, 33.650341796875],
              [78.80185546875003, 33.49970703125],
              [78.86503906249999, 33.431103515625],
              [78.91669921875001, 33.386767578124996],
              [78.94843750000001, 33.346533203125034],
              [79.01259765625002, 33.291455078125],
              [79.06650390625003, 33.250390625],
              [79.1125, 33.22626953125001],
              [79.13515625000002, 33.17192382812499],
              [79.12167968750003, 33.10810546875],
              [79.10283203124999, 33.05253906249999],
              [79.10859375000001, 33.02265625],
              [79.1455078125, 33.00146484375006],
              [79.20224609375003, 32.946044921875114],
              [79.20957031250003, 32.86484375],
              [79.20556640625, 32.809033203125125],
              [79.22792968750002, 32.7587890625],
              [79.23388671875, 32.703076171875],
              [79.21650390625001, 32.564013671874996],
              [79.21904296874999, 32.507568359375],
              [79.21933593750003, 32.50107421875],
              [79.169921875, 32.49721679687502],
              [79.12734375000002, 32.47578125],
              [79.06699218750003, 32.38818359375],
              [78.99765625000003, 32.365136718749994],
              [78.9189453125, 32.3582031250001],
              [78.837890625, 32.411962890625],
              [78.77128906249999, 32.468066406249996],
              [78.75351562500003, 32.499267578125],
              [78.73671875000002, 32.558398437500045],
              [78.70087890625001, 32.597021484375],
              [78.63154296875001, 32.578955078125],
              [78.5263671875, 32.57080078125006],
              [78.4125, 32.55771484375],
              [78.39169921875003, 32.544726562499996],
              [78.3896484375, 32.51987304687498],
              [78.41748046875, 32.46669921875002],
              [78.44130859375002, 32.397363281249994],
              [78.45527343750001, 32.300341796874996],
              [78.48613281249993, 32.236230468750136],
              [78.49589843749999, 32.215771484375],
              [78.677734375, 32.023046875],
              [78.7255859375, 31.983789062499994],
              [78.73544921875003, 31.957958984374997],
              [78.7197265625, 31.887646484374997],
              [78.68701171875, 31.80551757812492],
              [78.69345703125003, 31.740380859374994],
              [78.75390625, 31.66835937500008],
              [78.80292968750001, 31.618066406249994],
              [78.75507812500001, 31.55029296875],
              [78.72675781250001, 31.471826171874994],
              [78.75859374999999, 31.436572265624996],
              [78.74355468750002, 31.323779296875045],
              [78.7578125, 31.302490234375],
              [78.79160156250003, 31.293652343750125],
              [78.84453124999999, 31.301513671875],
              [78.89951171875003, 31.331347656249996],
              [78.94599609375001, 31.337207031249996],
              [78.97392578124999, 31.32861328125],
              [79.01113281250002, 31.414111328124996],
              [79.04375, 31.426220703124997],
              [79.10712890625001, 31.402636718749996],
              [79.23261718749995, 31.241748046875045],
              [79.33876953125002, 31.105712890625],
              [79.36962890625, 31.07993164062509],
              [79.38847656249999, 31.064208984375],
              [79.4931640625, 30.993701171874996],
              [79.5654296875, 30.949072265625],
              [79.66425781250001, 30.965234375],
              [79.79462890625001, 30.96826171875],
              [79.871875, 30.924609375000045],
              [79.91660156250003, 30.894189453124994],
              [79.91855468750009, 30.889892578125114],
              [79.92451171875001, 30.88876953125],
              [80.08144531250002, 30.781933593749997],
              [80.1494140625, 30.78984375],
              [80.1943359375, 30.759228515625125],
              [80.20712890625003, 30.683740234374994],
              [80.18623046875001, 30.605322265625],
              [80.19121093749999, 30.568408203124996],
              [80.26093750000001, 30.561328125],
              [80.40957031250002, 30.509472656249997],
              [80.541015625, 30.463525390624994],
              [80.60888671875, 30.448876953124994],
              [80.68212890625, 30.41484375],
              [80.74677734375001, 30.360400390624996],
              [80.87353515624994, 30.290576171875045],
              [80.98544921875003, 30.237109375],
              [81.01025390625, 30.164501953124997],
              [80.96611328124999, 30.180029296875],
              [80.90761718749997, 30.171923828125045],
              [80.84814453125, 30.13974609375],
              [80.81992187500003, 30.119335937499955],
              [80.68408203125, 29.994335937499997],
              [80.61289062500003, 29.955859375],
              [80.54902343750001, 29.899804687499994],
              [80.40185546875, 29.730273437499996],
              [80.31689453125, 29.572070312499996],
              [80.2548828125, 29.42333984375],
              [80.25595703125003, 29.318017578124994],
              [80.23300781250003, 29.194628906250045],
              [80.16953125000003, 29.124316406249996],
              [80.13046875000003, 29.100390625],
              [80.08457031249998, 28.994189453125102],
              [80.05166015625002, 28.8703125],
              [80.07070312500002, 28.830175781250034],
              [80.14960937500001, 28.776074218749997],
              [80.22656249999994, 28.723339843750125],
              [80.32480468750003, 28.66640625],
              [80.41855468749998, 28.61201171875001],
              [80.47910156250003, 28.604882812499994],
              [80.49580078125001, 28.635791015624996],
              [80.51787109374999, 28.665185546874994],
              [80.58701171875003, 28.649609375],
              [80.67128906250002, 28.596240234374996],
              [80.72617187500003, 28.55390625],
              [80.75078124999999, 28.539697265624994],
              [80.89609375000003, 28.468554687499996],
              [81.0166015625, 28.409570312499966],
              [81.1689453125, 28.335009765624996],
              [81.20625, 28.289404296875],
              [81.23896484375001, 28.24086914062508],
              [81.31083984374999, 28.1763671875],
              [81.48603515625001, 28.062207031249997],
              [81.63554687499993, 27.980468749999943],
              [81.75722656250002, 27.913818359375],
              [81.85263671875003, 27.867089843749994],
              [81.896875, 27.874462890624997],
              [81.94521484374997, 27.89926757812492],
              [81.98769531250002, 27.913769531249997],
              [82.03701171875002, 27.90058593750001],
              [82.11191406250003, 27.864941406249997],
              [82.28769531250003, 27.756542968749997],
              [82.45136718750001, 27.671826171874997],
              [82.6298828125, 27.687060546874996],
              [82.67734375000003, 27.6734375],
              [82.71083984375002, 27.5966796875],
              [82.7333984375, 27.518994140624997],
              [82.93281250000001, 27.467675781249994],
              [83.06406250000003, 27.44453125000001],
              [83.2138671875, 27.402294921875125],
              [83.28974609375001, 27.370996093749994],
              [83.36943359374999, 27.41025390625],
              [83.38398437500001, 27.44482421875],
              [83.44716796875002, 27.46533203125],
              [83.55166015625002, 27.456347656249996],
              [83.74697265625002, 27.395947265624997],
              [83.82880859375001, 27.377832031249994],
              [83.89716796875001, 27.435107421874996],
              [84.02480468749997, 27.461669921874943],
              [84.09101562499995, 27.491357421875136],
              [84.22978515624999, 27.427832031249977],
              [84.48085937500002, 27.348193359375102],
              [84.61015624999999, 27.298681640625],
              [84.64072265625003, 27.249853515624977],
              [84.65478515625, 27.203662109374996],
              [84.65380859375, 27.091699218749994],
              [84.68535156249999, 27.041015625],
              [84.93720703125001, 26.926904296874994],
              [85.02011718750003, 26.878515625],
              [85.08730468750002, 26.862939453124994],
              [85.12539062500002, 26.860986328124994],
              [85.15156250000001, 26.846630859374997],
              [85.17412109374999, 26.78154296874999],
              [85.19179687500002, 26.766552734374997],
              [85.240234375, 26.750341796875],
              [85.29296875, 26.741015625],
              [85.45644531250002, 26.797216796875],
              [85.56845703125003, 26.839843750000114],
              [85.6484375, 26.829003906249994],
              [85.69990234375001, 26.781640625],
              [85.70742187500002, 26.712646484375],
              [85.7373046875, 26.63974609375],
              [85.79453125000003, 26.604150390624994],
              [85.85566406250001, 26.6001953125],
              [86.00732421875, 26.649365234374997],
              [86.12939453125, 26.61171875],
              [86.24160156250002, 26.597998046875],
              [86.36611328125002, 26.574414062499997],
              [86.41445312500002, 26.556298828124994],
              [86.54365234375001, 26.495996093749994],
              [86.70136718750001, 26.43505859375],
              [86.7625, 26.441943359375045],
              [87.01640624999999, 26.555419921875057],
              [87.03789062499999, 26.5416015625],
              [87.08955078124995, 26.433203125000034],
              [87.16679687499999, 26.394238281249997],
              [87.28740234374993, 26.360302734375125],
              [87.41357421875, 26.42294921875],
              [87.51308593750002, 26.404980468749997],
              [87.63339843750003, 26.399121093749997],
              [87.74882812499999, 26.429296875],
              [87.84921875000003, 26.43691406250008],
              [87.9951171875, 26.382373046874996],
              [88.02695312500003, 26.39501953125],
              [88.05488281250001, 26.43002929687492],
              [88.11152343750001, 26.58642578125],
              [88.16152343750002, 26.724804687500125],
              [88.1572265625, 26.807324218749997],
              [88.11103515625001, 26.928466796875],
              [87.9931640625, 27.086083984374994],
              [87.984375, 27.133935546874994],
              [88.02412109375001, 27.408886718749997],
              [88.06787109375, 27.5673828125],
              [88.10556640625003, 27.642431640625],
              [88.14697265625, 27.74921875],
              [88.154296875, 27.798681640625],
              [88.15029296875002, 27.843310546874996],
              [88.10976562499997, 27.870605468749943],
              [88.09892578124999, 27.904541015625],
              [88.10898437499998, 27.93300781250008],
              [88.14111328125, 27.948925781249997],
              [88.27519531249999, 27.96884765625],
              [88.42597656250001, 28.011669921874997],
              [88.48613281249999, 28.034472656249996],
              [88.53164062500002, 28.057373046875],
              [88.57792968749999, 28.093359375000034],
              [88.62109375, 28.091845703124996],
              [88.75625, 28.039697265624994],
              [88.80371093749994, 28.006933593750034],
              [88.82861328125, 27.907275390624996],
              [88.84882812500001, 27.86865234375],
              [88.82988281249999, 27.767382812499996],
              [88.7490234375, 27.521875000000136],
              [88.76484375000001, 27.429882812499997],
              [88.83251953124994, 27.362841796875102],
              [88.89140624999999, 27.316064453124994],
              [88.88164062499999, 27.2974609375],
              [88.76035156250003, 27.218115234375],
              [88.73876953125, 27.175585937499996],
              [88.765625, 27.134228515624997],
              [88.81357421875003, 27.099023437499966],
              [88.83515624999995, 27.06557617187508],
              [88.85761718750001, 26.961474609374996],
              [88.91914062500001, 26.9322265625],
              [89.04091796875002, 26.865039062499996],
              [89.14824218749999, 26.816162109375114],
              [89.33212890625003, 26.8486328125],
              [89.38417968750002, 26.8265625],
              [89.474609375, 26.803417968749997],
              [89.54511718750001, 26.79624023437492],
              [89.58613281250001, 26.778955078124994],
              [89.60917968749999, 26.76220703125],
              [89.60615234375001, 26.741113281249994],
              [89.60996093750003, 26.719433593749997],
              [89.7109375, 26.713916015624996],
              [89.76386718750001, 26.701562500000023],
              [89.94316406249999, 26.723925781249996],
              [90.12294921875002, 26.754589843749997],
              [90.2060546875, 26.847509765625],
              [90.24238281250001, 26.854150390624994],
              [90.34589843750001, 26.890332031249997],
              [90.44765625000002, 26.85078125000001],
              [90.55986328124999, 26.796582031249997],
              [90.62031250000001, 26.780224609375068],
              [90.73964843750002, 26.771679687500068],
              [90.85576171874999, 26.777734375],
              [91.13388671875003, 26.803417968749955],
              [91.28652343750002, 26.789941406249994],
              [91.4267578125, 26.867089843749994],
              [91.45585937499999, 26.866894531249997],
              [91.517578125, 26.807324218749997],
              [91.67158203124995, 26.802001953125057],
              [91.75371093749999, 26.830761718750068],
              [91.84208984374999, 26.852978515624997],
              [91.89863281250001, 26.860058593749997],
              [91.94375, 26.86083984375],
              [91.99833984374999, 26.85498046875],
              [92.04970703125002, 26.874853515625],
              [92.07343750000001, 26.91484375],
              [92.06816406249993, 26.975195312499977],
              [92.03085937500003, 27.040820312499996],
              [91.99863281249998, 27.079296875000068],
              [91.99228515625003, 27.099902343749996],
              [92.00253906250003, 27.147363281249994],
              [92.03115234375002, 27.214306640624997],
              [92.08339843750002, 27.290625],
              [92.044921875, 27.364697265625125],
              [91.99082031249998, 27.450195312500114],
              [91.95097656249999, 27.458300781249996],
              [91.85126953125001, 27.438623046874994],
              [91.74306640625002, 27.442529296874994],
              [91.65810546875002, 27.493603515624997],
              [91.5947265625, 27.557666015624996],
              [91.57929687500001, 27.61142578125],
              [91.59765625, 27.677001953125],
              [91.62587890625002, 27.7373046875],
              [91.63193359375003, 27.759960937499997],
              [91.71259765625001, 27.759814453124996],
              [91.82470703125, 27.746435546875],
              [91.909375, 27.7296875],
              [91.97763671875003, 27.730371093749994],
              [92.10126953125001, 27.8076171875],
              [92.15761718750002, 27.812255859375],
              [92.22226562500003, 27.826953125],
              [92.25048828124994, 27.841503906250068],
              [92.27011718750003, 27.830224609374994],
              [92.34101562500001, 27.820751953124997],
              [92.41484374999999, 27.824609375],
              [92.48066406250001, 27.845947265625],
              [92.54667968749999, 27.879199218749996],
              [92.66435546874999, 27.948925781249997],
              [92.68779296874999, 27.988964843749997],
              [92.6875, 28.025732421875],
              [92.665625, 28.049853515624996],
              [92.64345703125002, 28.0615234375],
              [92.65253906250001, 28.093359375],
              [92.70185546875001, 28.147119140624994],
              [92.8818359375, 28.228125],
              [93.03496093749999, 28.32763671875],
              [93.11923828125003, 28.402294921874997],
              [93.15781250000003, 28.492724609374996],
              [93.20654296875, 28.5908203125],
              [93.251953125, 28.6294921875],
              [93.36054687500001, 28.654052734375057],
              [93.66494140625002, 28.690234375],
              [93.7607421875, 28.729785156250045],
              [93.90224609375002, 28.80322265625],
              [93.9736328125, 28.860791015625068],
              [94.01328125000003, 28.907519531249996],
              [94.01767578125003, 28.959521484374996],
              [94.11152343750001, 28.975878906250102],
              [94.19345703125003, 29.059912109375],
              [94.29326171874999, 29.14462890625],
              [94.46806640624999, 29.216210937499994],
              [94.623046875, 29.312402343749994],
              [94.67705078124999, 29.297021484375136],
              [94.7333984375, 29.251611328124994],
              [94.76308593750002, 29.20126953125],
              [94.76943359375002, 29.17587890625],
              [94.96748046874995, 29.144042968750057],
              [94.99882812499999, 29.149169921875],
              [95.14472656250001, 29.104052734374996],
              [95.27910156249999, 29.049560546875],
              [95.353125, 29.035888671875],
              [95.38925781250003, 29.037402343749996],
              [95.42021484374999, 29.054296875],
              [95.45654296874994, 29.102294921875057],
              [95.49375, 29.137011718749996],
              [95.51699218750002, 29.151171875000102],
              [95.51582031250001, 29.206347656249996],
              [95.71035156250002, 29.313818359375],
              [95.88505859374999, 29.390917968749996],
              [96.03535156250001, 29.447167968749994],
              [96.07958984375, 29.424121093749996],
              [96.12851562500003, 29.381396484374996],
              [96.19472656250002, 29.2724609375],
              [96.23496093750003, 29.245800781249997],
              [96.33720703124999, 29.260986328125],
              [96.35585937500002, 29.249072265624932],
              [96.33974609375002, 29.209814453125],
              [96.2705078125, 29.16123046875],
              [96.18085937500001, 29.11767578125],
              [96.12236328124999, 29.082080078124996],
              [96.14140624999999, 28.9634765625],
              [96.13710937500002, 28.922607421875],
              [96.16220703125003, 28.909716796874996],
              [96.346875, 29.02744140625009],
              [96.43574218750001, 29.050683593749994],
              [96.46708984374993, 29.022265625000102],
              [96.47714843750003, 28.959326171875],
              [96.55, 28.829589843750114],
              [96.58085937499999, 28.763671875],
              [96.39560546875003, 28.60654296875],
              [96.32734375000001, 28.525390625],
              [96.32988281249999, 28.496826171875],
              [96.326171875, 28.468554687499996],
              [96.27890625000003, 28.428173828124997],
              [96.28144531249995, 28.412060546875125],
              [96.31982421875, 28.386523437499996],
              [96.36640625000001, 28.36728515625],
              [96.38906250000002, 28.367919921875057],
              [96.427734375, 28.406005859375],
              [96.60263671875003, 28.459912109375125],
              [96.65283203124994, 28.44975585937499],
              [96.77578125000002, 28.367041015625],
              [96.8330078125, 28.36240234375],
              [96.98085937500002, 28.337695312499996],
              [97.07539062500001, 28.368945312499996],
              [97.14511718750003, 28.34033203125],
              [97.28945312499997, 28.236816406249943],
              [97.32246093750001, 28.21796875],
              [97.31025390625001, 28.155224609374997],
              [97.302734375, 28.08598632812499],
              [97.33916015624999, 28.03085937500009],
              [97.34355468749999, 27.982324218749966],
              [97.33515625000001, 27.937744140625],
              [97.30615234375, 27.907080078125],
              [97.22607421875, 27.890039062499994],
              [97.15781250000003, 27.836865234374997],
              [97.04970703124997, 27.760009765625],
              [96.96279296875002, 27.698291015625045],
              [96.89970703124999, 27.643847656249996],
              [96.87685546875002, 27.58671875],
              [96.88359374999999, 27.51484375],
              [96.90195312500003, 27.439599609374994],
              [97.10371093749995, 27.163330078125114],
              [97.10205078125, 27.115429687500125],
              [97.0380859375, 27.10205078125],
              [96.95341796874999, 27.13330078125],
              [96.88027343750002, 27.17783203125],
              [96.7978515625, 27.29619140624999],
              [96.73164062500001, 27.331494140625068],
              [96.66572265625001, 27.339257812499994],
              [96.27421874999999, 27.278369140624996],
              [96.19082031249997, 27.26127929687499],
              [96.06142578125002, 27.217089843749996],
              [95.97089843750001, 27.128076171874994],
              [95.9052734375, 27.046630859375],
              [95.83730468750002, 27.013818359374994],
              [95.73837890624998, 26.950439453125114],
              [95.4638671875, 26.756054687499997],
              [95.30507812500002, 26.67226562500008],
              [95.20146484374999, 26.64140625],
              [95.12871093749999, 26.59726562500009],
              [95.08945312500003, 26.52548828125],
              [95.05976562500001, 26.473974609375],
              [95.05087890625003, 26.347265625],
              [95.06894531250003, 26.191113281249997],
              [95.1083984375, 26.09140625],
              [95.12929687500002, 26.07041015625],
              [95.13242187500003, 26.041259765625],
              [95.09296875000001, 25.9873046875],
              [95.04072265624995, 25.941308593750023],
              [95.01523437500003, 25.91293945312499],
              [94.99199218749999, 25.770458984374997],
              [94.94570312499997, 25.700244140624932],
              [94.86113281249999, 25.597216796875102],
              [94.78583984375001, 25.519335937500003],
              [94.66777343749999, 25.458886718749994],
              [94.62285156249999, 25.41000976562499],
              [94.57988281249999, 25.31982421875],
              [94.55439453125001, 25.24345703124999],
              [94.55302734374999, 25.215722656249994],
              [94.56650390624998, 25.191503906250034],
              [94.615625, 25.164599609375003],
              [94.67529296874994, 25.13857421875008],
              [94.70371093750003, 25.097851562499997],
              [94.70761718750003, 25.048730468749994],
              [94.66328125000001, 24.93100585937499],
              [94.58408203125003, 24.767236328125136],
              [94.4931640625, 24.637646484374997],
              [94.3994140625, 24.5140625],
              [94.37724609374999, 24.473730468749977],
              [94.29306640625003, 24.321875],
              [94.2197265625, 24.113183593749994],
              [94.17031250000002, 23.97265625],
              [94.12763671875001, 23.87646484375],
              [94.07480468750003, 23.8720703125],
              [94.01083984375003, 23.90292968749999],
              [93.85546875, 23.943896484375003],
              [93.755859375, 23.97690429687499],
              [93.68339843749999, 24.006542968749997],
              [93.63330078125, 24.00537109375],
              [93.56406250000003, 23.986083984374943],
              [93.49375, 23.972851562499997],
              [93.4521484375, 23.987402343750034],
              [93.35556640625003, 24.074121093749994],
              [93.32626953125003, 24.064208984375],
              [93.30732421874995, 24.021875000000136],
              [93.37255859375, 23.774169921875],
              [93.41494140625002, 23.682080078124997],
              [93.40810546875002, 23.528027343749997],
              [93.39130859375001, 23.339160156250003],
              [93.36601562499999, 23.132519531249997],
              [93.34941406249999, 23.0849609375],
              [93.30800781250002, 23.03037109374999],
              [93.25351562500003, 23.01547851562499],
              [93.20390624999999, 23.037011718749994],
              [93.16416015625003, 23.03203125],
              [93.15097656250003, 22.997314453125],
              [93.1625, 22.907958984375],
              [93.1142578125, 22.805712890625003],
              [93.07871093750003, 22.718212890624997],
              [93.08818359374999, 22.633251953124997],
              [93.10507812500003, 22.547119140625],
              [93.16201171875002, 22.360205078125034],
              [93.16240234374999, 22.291894531249994],
              [93.15117187499999, 22.23061523437508],
              [93.12148437500002, 22.20517578124999],
              [93.07060546874999, 22.209423828124997],
              [93.04296875, 22.183984375],
              [93.02197265625, 22.145703125],
              [92.96455078125001, 22.00375976562499],
              [92.90947265624999, 21.988916015624994],
              [92.85429687499999, 22.01015625],
              [92.77138671875002, 22.104785156250003],
              [92.72099609374999, 22.132421875],
              [92.68896484375, 22.13095703124999],
              [92.67470703125002, 22.106005859375003],
              [92.65263671874999, 22.049316406250114],
              [92.63037109375, 22.011328125],
              [92.57490234374995, 21.97807617187499],
              [92.56123046875001, 22.048046875],
              [92.53183593750003, 22.410302734374994],
              [92.50957031249999, 22.525683593750003],
              [92.49140625000001, 22.68540039062499],
              [92.46445312500003, 22.734423828125003],
              [92.43046874999999, 22.821826171875003],
              [92.39316406250003, 22.897021484375003],
              [92.36162109374999, 22.929003906250003],
              [92.34121093750002, 23.06982421875],
              [92.33378906249999, 23.242382812499997],
              [92.33417968750001, 23.323828125],
              [92.28935546874999, 23.492480468750003],
              [92.24609374999994, 23.683593749999943],
              [92.18710937500003, 23.675537109375],
              [92.15234375, 23.721875],
              [92.12705078125003, 23.720996093750003],
              [92.04404296875003, 23.677783203125045],
              [91.978515625, 23.69199218750009],
              [91.92958984375002, 23.685986328124997],
              [91.92949218749999, 23.59824218749999],
              [91.93789062500002, 23.5046875],
              [91.91914062500001, 23.47104492187499],
              [91.7900390625, 23.361035156249997],
              [91.75419921874999, 23.287304687499997],
              [91.75791015625003, 23.20981445312499],
              [91.77382812500002, 23.10610351562508],
              [91.7509765625, 23.053515625000045],
              [91.69492187500003, 23.004833984374997],
              [91.61953125000002, 22.979687500000068],
              [91.55351562499999, 22.99155273437499],
              [91.51123046875, 23.033691406250057],
              [91.47138671875001, 23.141259765624994],
              [91.43623046875001, 23.199902343749997],
              [91.3994140625, 23.2138671875],
              [91.37060546875, 23.197998046875],
              [91.36679687500003, 23.13046875],
              [91.36865234375, 23.07456054687499],
              [91.359375, 23.068359375000057],
              [91.3388671875, 23.07700195312499],
              [91.31523437499999, 23.104394531249994],
              [91.25380859375002, 23.37363281249999],
              [91.16552734375, 23.5810546875],
              [91.16044921874999, 23.66064453125],
              [91.19248046875003, 23.762890625],
              [91.23203125000003, 23.920458984375003],
              [91.33642578125, 24.018798828125],
              [91.35019531250003, 24.060498046874997],
              [91.36708984375002, 24.093505859375],
              [91.39267578124998, 24.100097656250114],
              [91.5263671875, 24.090771484374997],
              [91.57138671874998, 24.106591796874966],
              [91.61113281249999, 24.15283203125],
              [91.66875, 24.190087890624994],
              [91.7265625, 24.205078125],
              [91.7724609375, 24.210644531249997],
              [91.84619140625, 24.17529296875],
              [91.876953125, 24.1953125],
              [91.89902343750003, 24.260693359374997],
              [91.93105468750002, 24.32553710937499],
              [91.95166015625, 24.356738281250003],
              [92.00107421875003, 24.370898437500003],
              [92.06416015625001, 24.374365234375034],
              [92.08505859375003, 24.386181640625125],
              [92.10195312500002, 24.40805664062499],
              [92.11748046874999, 24.493945312500003],
              [92.19804687499999, 24.685742187499997],
              [92.22666015625003, 24.77099609375],
              [92.23056640624998, 24.78623046875009],
              [92.22832031249999, 24.88134765625],
              [92.25126953124993, 24.895068359375045],
              [92.38496093750001, 24.848779296874994],
              [92.44316406249999, 24.84941406249999],
              [92.475, 24.86850585937499],
              [92.48544921875003, 24.903320312500114],
              [92.46835937500003, 24.944140625],
              [92.37343750000002, 25.01513671875],
              [92.20468750000003, 25.1109375],
              [92.04970703125002, 25.169482421875003],
              [91.76347656249999, 25.16064453125],
              [91.47968750000001, 25.142138671875003],
              [91.39667968750001, 25.151611328125],
              [91.29316406249995, 25.177978515625114],
              [91.03828125000001, 25.174072265625],
              [90.73017578125001, 25.159472656250102],
              [90.61308593749999, 25.167724609375],
              [90.55527343750003, 25.16660156249999],
              [90.43935546875002, 25.15771484375],
              [90.25039062499997, 25.184960937500136],
              [90.11962890625, 25.219970703125],
              [90.00380859375002, 25.258349609375003],
              [89.86630859375003, 25.293164062499997],
              [89.83330078124999, 25.292773437500003],
              [89.81406250000003, 25.305371093749997],
              [89.80087890625003, 25.336132812499997],
              [89.79628906250002, 25.37583007812492],
              [89.82490234375001, 25.56015625],
              [89.79960937499999, 25.839599609375114],
              [89.82294921875001, 25.94140625],
              [89.70986328125002, 26.171240234374977],
              [89.6708984375, 26.213818359374997],
              [89.61904296875002, 26.215673828125],
              [89.58574218749999, 26.18603515625],
              [89.57275390624994, 26.132324218750057],
              [89.59140625000003, 26.072412109374994],
              [89.54990234375003, 26.005273437499994],
              [89.46689453125003, 25.983544921874994],
              [89.36972656250003, 26.006103515625],
              [89.28925781250001, 26.03759765625],
              [89.18642578125002, 26.10595703125],
              [89.10830078125002, 26.20224609375009],
              [89.10195312500002, 26.308349609375],
              [89.06679687500002, 26.376904296874997],
              [89.01865234375003, 26.410253906249977],
              [88.9833984375, 26.41953124999992],
              [88.95195312499999, 26.412109375],
              [88.92412109374999, 26.37509765625],
              [88.9482421875, 26.33798828125],
              [88.98154296875003, 26.2861328125],
              [88.97041015625001, 26.250878906249994],
              [88.94072265624999, 26.245361328125],
              [88.896484375, 26.260498046875],
              [88.82802734375002, 26.252197265625],
              [88.76191406249995, 26.279394531249977],
              [88.72216796875, 26.2818359375],
              [88.68281250000001, 26.29169921875001],
              [88.6806640625, 26.352978515624997],
              [88.6201171875, 26.4306640625],
              [88.51826171875001, 26.517773437499997],
              [88.41816406250001, 26.571533203125],
              [88.36992187499999, 26.564111328124994],
              [88.34589843750001, 26.504785156249994],
              [88.35146484375002, 26.482568359374994],
              [88.38623046875, 26.471533203125034],
              [88.43671874999995, 26.437109374999977],
              [88.44785156250003, 26.401025390624994],
              [88.4404296875, 26.369482421875],
              [88.37802734375003, 26.31201171875],
              [88.333984375, 26.257519531249955],
              [88.23515624999993, 26.17807617187492],
              [88.15078124999997, 26.087158203125057],
              [88.12900390624998, 26.01821289062508],
              [88.09736328125001, 25.956347656250003],
              [88.08457031249998, 25.888232421875045],
              [88.10664062500001, 25.841113281250003],
              [88.1474609375, 25.811425781249994],
              [88.2529296875, 25.789794921875057],
              [88.36308593749999, 25.698193359374997],
              [88.45234375000001, 25.574414062499997],
              [88.50244140625, 25.537011718749994],
              [88.59345703125001, 25.4953125],
              [88.76914062500003, 25.490478515625],
              [88.79541015625, 25.45625],
              [88.82031249999994, 25.36552734374999],
              [88.85478515624999, 25.333544921875003],
              [88.94414062499999, 25.290771484375],
              [88.95166015625, 25.25927734375],
              [88.92978515625003, 25.22299804687499],
              [88.89013671875, 25.194384765625102],
              [88.81728515625002, 25.176220703124997],
              [88.74755859375, 25.1689453125],
              [88.67753906249999, 25.18046875],
              [88.57382812500003, 25.187890625],
              [88.45625, 25.188427734374997],
              [88.37294921875002, 24.96152343749999],
              [88.31337890625002, 24.8818359375],
              [88.27949218750001, 24.88193359374999],
              [88.18886718750002, 24.920605468749997],
              [88.14980468750002, 24.91464843749999],
              [88.04511718750001, 24.713037109374994],
              [88.0302734375, 24.664453125],
              [88.0234375, 24.627832031250136],
              [88.0791015625, 24.54990234374999],
              [88.1455078125, 24.485791015624997],
              [88.225, 24.46064453125001],
              [88.287109375, 24.479736328125],
              [88.3375, 24.453857421875],
              [88.39697265625, 24.38925781249999],
              [88.49853515624994, 24.346630859375068],
              [88.64228515625001, 24.325976562500003],
              [88.72353515625002, 24.27490234375],
              [88.73359375000001, 24.230908203124997],
              [88.7265625, 24.186230468749997],
              [88.71376953125002, 24.069628906250003],
              [88.69980468750003, 24.00253906249999],
              [88.62255859375, 23.826367187500125],
              [88.5673828125, 23.67441406249999],
              [88.59599609374999, 23.602197265624994],
              [88.61640625000001, 23.572753906250114],
              [88.6357421875, 23.55],
              [88.69765625000002, 23.493017578125034],
              [88.74082031250003, 23.43662109374999],
              [88.70400390625002, 23.29282226562499],
              [88.72441406249999, 23.254980468750034],
              [88.8076171875, 23.2296875],
              [88.89707031250003, 23.210400390624997],
              [88.928125, 23.18662109374999],
              [88.8505859375, 23.04052734375],
              [88.86699218749999, 22.938867187499994],
              [88.89970703124999, 22.843505859375057],
              [88.92343750000003, 22.687548828125102],
              [88.92695312500001, 22.671142578125114],
              [88.92070312499999, 22.632031249999955],
              [88.97148437499999, 22.5109375],
              [89.05, 22.274609375],
              [89.05585937500001, 22.186230468750068],
              [89.05146484375001, 22.093164062500136],
              [89.02792968750015, 21.93720703125001],
              [88.94931640625015, 21.937939453125125],
              [89.01962890625009, 21.833642578125023],
              [89.0419921875, 21.758691406250023],
              [89.05166015625002, 21.654101562500045],
              [88.96708984375005, 21.641357421875114],
              [88.90742187500013, 21.653076171875],
              [88.85751953125009, 21.744677734375045],
              [88.834375, 21.661376953125114],
              [88.74501953125008, 21.584375],
              [88.71298828125015, 21.62197265625008],
              [88.69472656250014, 21.662402343749932],
              [88.69121093749993, 21.733496093750034],
              [88.740234375, 22.005419921875045],
              [88.7302734375001, 22.036083984375068],
              [88.70830078125005, 22.056152343750057],
              [88.65957031250008, 22.066943359374932],
              [88.6416015625, 22.121972656250136],
              [88.56679687500002, 21.83212890625009],
              [88.59980468750001, 21.713769531250136],
              [88.58466796875013, 21.659716796874932],
              [88.44599609375007, 21.614257812500114],
              [88.30546875000005, 21.723339843750125],
              [88.28750000000014, 21.758203125000136],
              [88.27919921875002, 21.696875],
              [88.25371093749999, 21.622314453124943],
              [88.1220703125, 21.635791015625045],
              [88.05683593750001, 21.694140625000045],
              [88.09941406249999, 21.793554687500034],
              [88.18105468750014, 22.032910156250125],
              [88.1962890625, 22.139550781250023],
              [88.08710937500001, 22.217724609375125],
              [87.9944335937501, 22.265673828125102],
              [87.94140624999994, 22.374316406250045],
              [87.96162109375001, 22.25502929687508],
              [88.01074218750006, 22.212646484374943],
              [88.08300781250006, 22.18271484375009],
              [88.15927734375015, 22.12172851562508],
              [88.10410156250009, 22.047363281250057],
              [88.05078125000006, 22.001074218750034],
              [87.94843749999995, 21.825439453124943],
              [87.82373046875, 21.727343750000045],
              [87.67822265625, 21.653515625000125],
              [87.20068359375006, 21.54487304687501],
              [87.10068359375009, 21.500781250000045],
              [86.95410156250011, 21.365332031250034],
              [86.85957031250001, 21.23671875000008],
              [86.84228515625006, 21.106347656250023],
              [86.8958007812501, 20.965576171875114],
              [86.93935546875008, 20.745068359375125],
              [86.97548828125002, 20.70014648437501],
              [86.92451171875013, 20.619775390625023],
              [86.8359375, 20.534326171875023],
              [86.7625, 20.419140624999955],
              [86.76923828125007, 20.355908203125068],
              [86.75039062500014, 20.313232421875057],
              [86.49873046875007, 20.171630859375],
              [86.44580078125011, 20.088916015625045],
              [86.37656250000003, 20.006738281249966],
              [86.29365234375001, 20.05375976562499],
              [86.24521484375009, 20.05302734374999],
              [86.31191406250002, 19.987792968750057],
              [86.30292968750013, 19.944677734374977],
              [86.27949218750013, 19.919433593749943],
              [86.21621093750002, 19.895800781249932],
              [85.85292968750008, 19.791748046875057],
              [85.575, 19.69291992187499],
              [85.496875, 19.69692382812508],
              [85.51113281250008, 19.726904296874977],
              [85.55976562500007, 19.753466796875102],
              [85.55507812500002, 19.866894531250068],
              [85.50410156250007, 19.887695312500057],
              [85.4599609375, 19.895898437500136],
              [85.24863281250009, 19.757666015625034],
              [85.16279296875001, 19.620898437500045],
              [85.18076171875015, 19.59487304687508],
              [85.22851562500006, 19.601318359375],
              [85.37089843749993, 19.67890625000001],
              [85.43691406250008, 19.656884765625136],
              [85.44160156249995, 19.626562499999977],
              [85.2255859375, 19.508349609375045],
              [84.77099609375011, 19.125390625000023],
              [84.7498046875001, 19.050097656249932],
              [84.69082031250014, 18.964697265624977],
              [84.609375, 18.884326171875102],
              [84.46279296875002, 18.689746093750102],
              [84.18173828125015, 18.400585937499955],
              [84.10410156250015, 18.29267578125001],
              [83.654296875, 18.069873046875102],
              [83.572265625, 18.003613281250125],
              [83.3879882812501, 17.786669921874932],
              [83.19833984375003, 17.608984374999977],
              [82.97685546874999, 17.461816406250023],
              [82.59316406250002, 17.273925781250114],
              [82.35957031250001, 17.09619140625],
              [82.28652343750008, 16.978076171875045],
              [82.28193359375001, 16.936083984375045],
              [82.30722656250003, 16.878564453125023],
              [82.35, 16.8251953125],
              [82.35976562500008, 16.78281250000009],
              [82.33867187500005, 16.70654296875],
              [82.3271484375, 16.664355468750102],
              [82.25878906250011, 16.559863281249932],
              [82.14150390625002, 16.485351562500057],
              [81.76191406250013, 16.32949218750008],
              [81.71171875000005, 16.334472656250057],
              [81.40185546875011, 16.365234375000114],
              [81.2861328125, 16.337060546875023],
              [81.2385742187501, 16.263964843750045],
              [81.13212890625005, 15.961767578124977],
              [81.03007812500005, 15.88144531249992],
              [80.99345703125005, 15.80874023437508],
              [80.97871093750007, 15.758349609375102],
              [80.9177734375001, 15.759667968750023],
              [80.86474609375011, 15.7822265625],
              [80.82597656250005, 15.765917968750045],
              [80.78183593750009, 15.86733398437508],
              [80.70781249999993, 15.88808593750008],
              [80.64658203125009, 15.895019531250057],
              [80.38486328125009, 15.792773437499989],
              [80.29345703125011, 15.710742187499989],
              [80.10107421875, 15.323632812499966],
              [80.05341796875007, 15.074023437499932],
              [80.0986328125, 14.79824218750008],
              [80.16542968750014, 14.577832031250125],
              [80.1787109375, 14.478320312500102],
              [80.17011718750001, 14.349414062500102],
              [80.13623046875006, 14.286572265625125],
              [80.11171875000002, 14.212207031250045],
              [80.14365234375015, 14.058935546875091],
              [80.22441406250005, 13.858203125000045],
              [80.244140625, 13.773486328125045],
              [80.24580078124995, 13.685839843750102],
              [80.30654296875008, 13.485058593750068],
              [80.26562500000011, 13.521289062499989],
              [80.23339843750011, 13.605761718749932],
              [80.15625, 13.713769531250136],
              [80.06210937500003, 13.60625],
              [80.1142578125, 13.52871093750008],
              [80.29033203124999, 13.436718749999955],
              [80.34238281250009, 13.361328125000057],
              [80.22910156250015, 12.690332031249966],
              [80.14306640625, 12.452001953125091],
              [80.0375, 12.29580078125008],
              [79.98173828124999, 12.235449218750034],
              [79.85849609375015, 11.988769531250057],
              [79.77138671875014, 11.690234374999989],
              [79.75410156250001, 11.575292968750034],
              [79.79335937500002, 11.44667968750008],
              [79.74892578125002, 11.370605468750057],
              [79.69316406249999, 11.312548828124932],
              [79.79902343750007, 11.338671874999932],
              [79.83525390624999, 11.268847656250102],
              [79.84863281250006, 11.196875],
              [79.85019531250003, 10.768847656250045],
              [79.83818359375005, 10.322558593750045],
              [79.75693359375003, 10.30434570312508],
              [79.66738281250008, 10.299707031250023],
              [79.58857421875001, 10.31235351562492],
              [79.53164062500014, 10.329638671875102],
              [79.39052734375002, 10.305957031250045],
              [79.31455078125015, 10.256689453124977],
              [79.25361328125001, 10.1748046875],
              [79.25781249999994, 10.035205078124932],
              [78.99628906249995, 9.68310546875],
              [78.93994140625006, 9.565771484375091],
              [78.91914062500007, 9.452880859375],
              [78.953125, 9.393798828125],
              [79.01992187499997, 9.333349609374977],
              [79.10703125000015, 9.308935546874977],
              [79.27548828125003, 9.284619140625011],
              [79.35634765625014, 9.252148437500125],
              [79.41142578124999, 9.1923828125],
              [79.21289062500006, 9.256005859375023],
              [78.97958984375015, 9.268554687500114],
              [78.42148437500009, 9.105029296874989],
              [78.27451171875003, 8.990185546875068],
              [78.19248046874998, 8.890869140625057],
              [78.13603515625005, 8.663378906250102],
              [78.12636718750002, 8.511328125000034],
              [78.06015625000003, 8.384570312499932],
              [77.77031249999999, 8.18984375000008],
              [77.58720703124999, 8.129882812500114],
              [77.517578125, 8.078320312500068],
              [77.30146484375001, 8.145312500000045],
              [77.06591796875, 8.31591796875],
              [76.96689453125009, 8.407275390624932],
              [76.61728515625009, 8.847070312500023],
              [76.55341796875001, 8.902783203125011],
              [76.48291015625006, 9.090771484375125],
              [76.47177734375009, 9.160839843750011],
              [76.45234375000007, 9.188769531249989],
              [76.41904296875003, 9.207812499999989],
              [76.403125, 9.23681640625],
              [76.32460937500014, 9.452099609375011],
              [76.29238281250014, 9.676464843750068],
              [76.24238281250007, 9.927099609374977],
              [76.28466796875, 9.909863281250011],
              [76.34306640624999, 9.827343750000068],
              [76.37226562500007, 9.707373046874977],
              [76.37558593750003, 9.53989257812492],
              [76.41953125000009, 9.520458984375068],
              [76.4587890625001, 9.536230468750091],
              [76.34648437499999, 9.922119140625],
              [76.24873046875013, 10.017968750000023],
              [76.22275390625015, 10.024267578125034],
              [76.19560546874999, 10.086132812500011],
              [76.1926757812501, 10.163769531250011],
              [76.2014648437501, 10.200634765625068],
              [76.12333984374999, 10.327001953125091],
              [76.09609375000014, 10.402246093750136],
              [75.92255859375013, 10.784082031250136],
              [75.84462890625002, 11.057568359375068],
              [75.72382812500013, 11.361767578125011],
              [75.64609375000009, 11.46840820312508],
              [75.52451171875003, 11.703125000000114],
              [75.42265625000005, 11.812207031250125],
              [75.31464843750007, 11.958447265625068],
              [75.22978515625005, 12.023339843749966],
              [75.19667968750002, 12.057519531250023],
              [74.94550781250001, 12.56455078124992],
              [74.86826171875009, 12.844580078124977],
              [74.80292968750013, 12.976855468750102],
              [74.77050781250006, 13.077343750000125],
              [74.68232421875007, 13.506933593750091],
              [74.68164062500006, 13.583740234375114],
              [74.6708984375, 13.667626953125136],
              [74.60849609375009, 13.849658203125102],
              [74.49853515625006, 14.046337890625011],
              [74.46669921874997, 14.16884765625008],
              [74.46699218750007, 14.216503906250011],
              [74.39716796875001, 14.407421875000011],
              [74.38222656250002, 14.494726562500034],
              [74.33505859374998, 14.575439453125],
              [74.28037109375003, 14.649511718749977],
              [74.22304687500008, 14.708886718749966],
              [74.08876953125008, 14.90219726562492],
              [74.040625, 14.949365234375136],
              [73.94921875000006, 15.074755859375102],
              [73.88427734375, 15.306445312500045],
              [73.80078125000006, 15.39697265625],
              [73.9319335937501, 15.39697265625],
              [73.85195312499997, 15.482470703125045],
              [73.81386718750002, 15.538574218750057],
              [73.77177734375016, 15.573046874999989],
              [73.83281250000016, 15.659375],
              [73.73281250000002, 15.656933593750011],
              [73.67988281250013, 15.708886718750136],
              [73.60771484375016, 15.871093749999943],
              [73.47607421874994, 16.05424804687499],
              [73.45371093750003, 16.152099609375],
              [73.33759765625001, 16.459863281250023],
              [73.23916015625002, 17.198535156250045],
              [73.14902343749998, 17.52744140625009],
              [73.15605468750016, 17.621923828124977],
              [73.04716796875016, 17.906738281250057],
              [72.99394531250016, 18.097705078125102],
              [72.97207031250014, 18.259277343749943],
              [72.9431640625001, 18.365625000000136],
              [72.91718749999995, 18.57612304687501],
              [72.87548828125, 18.642822265625114],
              [72.87089843750016, 18.683056640624955],
              [72.8987304687501, 18.778955078125136],
              [72.97685546875005, 18.927197265624955],
              [73.00556640625001, 19.02109375000009],
              [72.97207031250014, 19.1533203125],
              [72.90068359375016, 19.014501953124977],
              [72.83466796875001, 18.975585937500057],
              [72.80302734375016, 19.079296875000068],
              [72.80273437500011, 19.21875],
              [72.79453125000003, 19.25209960937508],
              [72.81162109374998, 19.298925781250034],
              [72.98720703125002, 19.27744140625009],
              [72.7878906250001, 19.362988281250125],
              [72.76396484375005, 19.413183593750034],
              [72.75644531250009, 19.450537109374977],
              [72.79941406249998, 19.519824218750102],
              [72.72656250000006, 19.57827148437508],
              [72.69746093749995, 19.757128906250102],
              [72.67597656250001, 19.79794921875009],
              [72.66777343750016, 19.83095703125008],
              [72.708984375, 20.078027343750136],
              [72.8811523437501, 20.56318359375001],
              [72.89375, 20.672753906250136],
              [72.87890625, 20.82851562500008],
              [72.84052734375001, 20.95249023437509],
              [72.82431640625003, 21.083593749999977],
              [72.81386718750008, 21.117187500000057],
              [72.75156249999998, 21.129150390625057],
              [72.6923828125, 21.177636718750136],
              [72.62382812500005, 21.371972656250136],
              [72.68652343750006, 21.435742187499955],
              [72.73476562500014, 21.470800781249977],
              [72.66835937500008, 21.455908203124977],
              [72.61328125000006, 21.461816406250136],
              [72.71757812500016, 21.551269531250057],
              [72.81054687500011, 21.61992187499999],
              [73.02246093750011, 21.69960937500008],
              [73.1125, 21.750439453125125],
              [72.97910156249998, 21.704687500000034],
              [72.83974609375002, 21.687255859375057],
              [72.54306640624998, 21.69658203124999],
              [72.59248046875007, 21.87758789062508],
              [72.64404296875011, 21.937988281249943],
              [72.7001953125, 21.971923828124943],
              [72.61748046875005, 21.96171874999999],
              [72.52226562500016, 21.976220703125136],
              [72.55302734375005, 22.159960937500045],
              [72.6279296875, 22.199609374999966],
              [72.70878906250016, 22.20717773437508],
              [72.80917968749998, 22.23330078125008],
              [72.70195312500016, 22.263623046875068],
              [72.59013671875016, 22.278125],
              [72.45595703125014, 22.248095703125102],
              [72.33261718750009, 22.270214843749955],
              [72.18281250000013, 22.269726562500068],
              [72.24257812500002, 22.245166015625045],
              [72.30644531250005, 22.189208984375],
              [72.27441406250011, 22.089746093749966],
              [72.24433593750001, 22.027636718750102],
              [72.16171875000009, 21.984814453125068],
              [72.09443359375007, 21.919970703125045],
              [72.07558593750014, 21.862988281249955],
              [72.03720703125009, 21.82304687499999],
              [72.10292968750002, 21.79458007812508],
              [72.1708984375, 21.774316406250023],
              [72.21035156250002, 21.728222656250068],
              [72.25664062500005, 21.66123046875009],
              [72.25400390625003, 21.531005859375],
              [72.07656250000014, 21.22407226562501],
              [72.01523437500009, 21.155712890625125],
              [71.57109374999999, 20.970556640625034],
              [71.39648437499994, 20.869775390625136],
              [71.02460937500007, 20.73886718750009],
              [70.87968749999999, 20.714501953125136],
              [70.71933593750003, 20.740429687500068],
              [70.48505859375007, 20.840185546874977],
              [70.12734375000002, 21.094677734375125],
              [70.03437500000013, 21.178808593750034],
              [69.74843750000014, 21.505712890625034],
              [69.5419921875, 21.678564453125034],
              [69.38544921875007, 21.83955078125001],
              [69.19169921875005, 21.991503906250045],
              [69.00878906250006, 22.196777343750114],
              [68.96992187500013, 22.290283203125],
              [68.98349609375009, 22.385400390624966],
              [69.05166015625008, 22.437304687500102],
              [69.13134765625, 22.416259765625057],
              [69.19423828125002, 22.33608398437508],
              [69.23886718750003, 22.300195312500023],
              [69.27656250000007, 22.28549804687509],
              [69.54921875000002, 22.408398437500068],
              [69.65517578125014, 22.403515625000125],
              [69.72753906250011, 22.46518554687509],
              [69.81904296875013, 22.451757812500034],
              [70.005859375, 22.547705078125034],
              [70.08417968750013, 22.553515624999932],
              [70.17724609375006, 22.572753906249943],
              [70.32773437500003, 22.81577148437509],
              [70.44042968750006, 22.970312499999977],
              [70.51347656249999, 23.002490234374932],
              [70.509375, 23.040136718749977],
              [70.48925781250006, 23.08950195312508],
              [70.43457031250011, 23.077099609375068],
              [70.3962890625001, 23.03012695312509],
              [70.36796875000005, 22.97348632812509],
              [70.33945312500009, 22.939746093749932],
              [70.25117187500007, 22.970898437500068],
              [70.19169921874999, 22.96567382812509],
              [70.11826171874998, 22.947021484375],
              [69.84980468750001, 22.856445312500057],
              [69.73964843750008, 22.77519531249999],
              [69.66464843750009, 22.759082031250102],
              [69.23593749999998, 22.848535156250023],
              [68.81708984375007, 23.053710937500057],
              [68.64072265625003, 23.189941406250114],
              [68.52919921875008, 23.364062500000045],
              [68.41748046875006, 23.571484375000068],
              [68.45380859374995, 23.629492187499977],
              [68.62714843750013, 23.754150390624943],
              [68.77675781250002, 23.85209960937499],
              [68.64238281250005, 23.808496093750023],
              [68.496875, 23.747998046875125],
              [68.42490234374998, 23.70556640625],
              [68.34335937500003, 23.616845703125023],
              [68.23496093749998, 23.596972656250102],
              [68.19199218750009, 23.728906250000136],
              [68.1650390625, 23.857324218749994],
            ],
          ],
          [
            [
              [93.89003906250002, 6.8310546875],
              [93.82880859375001, 6.748681640624994],
              [93.70927734374999, 7.000683593749997],
              [93.65800781249999, 7.016064453124997],
              [93.65634765625003, 7.13623046875],
              [93.68417968750003, 7.18359375],
              [93.82246093750001, 7.236621093749989],
              [93.85898437500003, 7.206835937499989],
              [93.92958984375002, 6.973486328124991],
              [93.89003906250002, 6.8310546875],
            ],
          ],
          [
            [
              [93.73359375000001, 7.356494140624989],
              [93.63847656249999, 7.261865234374994],
              [93.59726562500003, 7.31875],
              [93.6142578125, 7.358105468749997],
              [93.65468750000002, 7.379931640624989],
              [93.69248046875003, 7.410595703124997],
              [93.73359375000001, 7.356494140624989],
            ],
          ],
          [
            [
              [93.14072265625003, 8.24951171875],
              [93.17060546875001, 8.212060546874994],
              [93.115234375, 8.218505859375],
              [93.06425781249999, 8.274951171874989],
              [93.07753906250002, 8.327880859375],
              [93.09697265624999, 8.349365234375],
              [93.14072265625003, 8.24951171875],
            ],
          ],
          [
            [
              [93.44257812500001, 7.877832031249994],
              [93.36503906249999, 7.8765625],
              [93.34199218750001, 7.919335937499994],
              [93.309375, 7.964013671874994],
              [93.33447265625, 8.006933593749991],
              [93.37548828125, 8.017919921874991],
              [93.43369140625003, 7.948388671874994],
              [93.44736328125003, 7.899121093749997],
              [93.44257812500001, 7.877832031249994],
            ],
          ],
          [
            [
              [93.53691406249999, 8.056640625],
              [93.49003906249999, 8.019433593749994],
              [93.47822265625001, 8.024462890624989],
              [93.47177734375003, 8.052685546874997],
              [93.4697265625, 8.07265625],
              [93.46123046874999, 8.10859375],
              [93.45644531250002, 8.171875],
              [93.49404296875002, 8.224658203124989],
              [93.53164062500002, 8.213769531249994],
              [93.51162109375002, 8.159765625],
              [93.53691406249999, 8.056640625],
            ],
          ],
          [
            [
              [92.7875, 9.136669921874997],
              [92.74355468750002, 9.130957031249991],
              [92.71660156249999, 9.165087890624989],
              [92.71328125000002, 9.204882812499989],
              [92.73857421874999, 9.230664062499997],
              [92.76210937500002, 9.243896484375],
              [92.78574218750003, 9.240527343749989],
              [92.80927734375001, 9.173388671874989],
              [92.7875, 9.136669921874997],
            ],
          ],
          [
            [
              [92.50283203125002, 10.554882812499997],
              [92.47265625, 10.520751953125],
              [92.36953125000002, 10.547412109374989],
              [92.37714843750001, 10.650585937499997],
              [92.35283203124999, 10.751123046874994],
              [92.37070312500003, 10.793505859374989],
              [92.44785156250003, 10.865527343749989],
              [92.51035156250003, 10.8974609375],
              [92.55400390624999, 10.7998046875],
              [92.57431640625003, 10.704248046874994],
              [92.50283203125002, 10.554882812499997],
            ],
          ],
          [
            [
              [92.69316406249999, 11.381152343749989],
              [92.64453125, 11.361328125],
              [92.595703125, 11.386425781249997],
              [92.63388671875003, 11.4267578125],
              [92.64023437500003, 11.509130859374991],
              [92.69003906250003, 11.463427734374989],
              [92.68720703125001, 11.411230468749991],
              [92.69316406249999, 11.381152343749989],
            ],
          ],
          [
            [
              [92.72275390625003, 11.536083984374997],
              [92.70078125000003, 11.512548828124991],
              [92.66835937500002, 11.538720703124994],
              [92.57558593750002, 11.718212890624997],
              [92.55966796875003, 11.833447265624997],
              [92.53388671875001, 11.873388671874991],
              [92.56650390625003, 11.930517578124991],
              [92.60751953125003, 11.949511718749989],
              [92.6318359375, 12.013867187499997],
              [92.640625, 12.112207031249994],
              [92.67646484375001, 12.1923828125],
              [92.69472656250002, 12.214697265624991],
              [92.76923828125001, 12.215576171875],
              [92.78828125000001, 12.22578125],
              [92.77763671874999, 12.302539062499989],
              [92.73408203125001, 12.3359375],
              [92.71894531250001, 12.357324218749994],
              [92.720703125, 12.541259765625],
              [92.73203125000003, 12.615625],
              [92.75917968750002, 12.669091796874994],
              [92.74003906249999, 12.779638671874991],
              [92.753125, 12.820898437499991],
              [92.80703125000002, 12.87890625],
              [92.83085937499999, 13.002636718749997],
              [92.80898437500002, 13.039599609374989],
              [92.86015624999999, 13.230566406249991],
              [92.85732421875002, 13.358105468749997],
              [92.92460937499999, 13.48583984375],
              [93.02939453125003, 13.543847656249994],
              [93.06230468749999, 13.545458984375003],
              [93.06669921874999, 13.436474609374997],
              [93.07666015625, 13.400683593750003],
              [93.01601562500002, 13.336181640625],
              [93.07382812500003, 13.252099609374994],
              [93.06611328125001, 13.221582031249994],
              [93.04296875, 13.154882812499991],
              [93.00468749999999, 13.08935546875],
              [92.95136718750001, 13.0625],
              [92.90996093749999, 12.975195312499991],
              [92.88623046875, 12.942285156249994],
              [92.96503906250001, 12.850488281249994],
              [92.990234375, 12.538525390624997],
              [92.9326171875, 12.453076171874997],
              [92.86367187500002, 12.43603515625],
              [92.87949218750003, 12.227929687499994],
              [92.8671875, 12.181445312499989],
              [92.798828125, 12.079248046874994],
              [92.78623046875003, 12.03466796875],
              [92.74765625000003, 11.992773437499991],
              [92.76396484374999, 11.9404296875],
              [92.79677734375002, 11.917529296874989],
              [92.79755859375001, 11.874658203124994],
              [92.76699218750002, 11.7646484375],
              [92.7646484375, 11.63916015625],
              [92.72275390625003, 11.536083984374997],
            ],
          ],
          [
            [
              [93.01738281249999, 12.036816406249997],
              [93.06210937500003, 11.8994140625],
              [92.98173828124999, 11.95947265625],
              [92.95537109374999, 12.00244140625],
              [92.99580078125001, 12.031787109374989],
              [93.01738281249999, 12.036816406249997],
            ],
          ],
          [
            [
              [92.71757812499999, 12.864892578124994],
              [92.68574218750001, 12.799951171874994],
              [92.6796875, 12.939257812499989],
              [92.69443359375003, 12.956787109375],
              [92.71064453125001, 12.961572265624994],
              [92.73085937500002, 12.948535156249989],
              [92.71757812499999, 12.864892578124994],
            ],
          ],
          [
            [
              [72.78037109375, 11.202490234374991],
              [72.773046875, 11.196093749999989],
              [72.7724609375, 11.214257812499994],
              [72.7818359375, 11.243310546874994],
              [72.79267578125001, 11.262744140624989],
              [72.7958984375, 11.260449218749997],
              [72.79287109375002, 11.241552734374991],
              [72.78789062500002, 11.215917968749991],
              [72.78037109375, 11.202490234374991],
            ],
          ],
          [
            [
              [73.0673828125, 8.269091796874989],
              [73.0533203125, 8.256689453124991],
              [73.03886718750002, 8.251953125],
              [73.02851562500001, 8.253515625],
              [73.0234375, 8.265917968749989],
              [73.02607421875001, 8.275292968749994],
              [73.03896484375002, 8.26484375],
              [73.05585937500001, 8.274560546874994],
              [73.0751953125, 8.306347656249997],
              [73.07949218750002, 8.316503906249991],
              [73.08359375, 8.31103515625],
              [73.07978515625001, 8.293066406249991],
              [73.0673828125, 8.269091796874989],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 3,
        labelrank: 2,
        sovereignt: "China",
        sov_a3: "CH1",
        adm0_dif: 1,
        level: 2,
        type: "Country",
        tlc: "1",
        admin: "China",
        adm0_a3: "CHN",
        geou_dif: 0,
        geounit: "China",
        gu_a3: "CHN",
        su_dif: 0,
        subunit: "China",
        su_a3: "CHN",
        brk_diff: 0,
        name: "China",
        name_long: "China",
        brk_a3: "CHN",
        brk_name: "China",
        brk_group: null,
        abbrev: "China",
        postal: "CN",
        formal_en: "People's Republic of China",
        formal_fr: null,
        name_ciawf: "China",
        note_adm0: null,
        note_brk: null,
        name_sort: "China",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 4,
        mapcolor9: 4,
        mapcolor13: 3,
        pop_est: 1397715000,
        pop_rank: 18,
        pop_year: 2019,
        gdp_md: 14342903,
        gdp_year: 2019,
        economy: "3. Emerging region: BRIC",
        income_grp: "3. Upper middle income",
        fips_10: "CH",
        iso_a2: "CN",
        iso_a2_eh: "CN",
        iso_a3: "CHN",
        iso_a3_eh: "CHN",
        iso_n3: "156",
        iso_n3_eh: "156",
        un_a3: "156",
        wb_a2: "CN",
        wb_a3: "CHN",
        woe_id: 23424781,
        woe_id_eh: 23424781,
        woe_note: "Exact WOE match as country",
        adm0_iso: "CHN",
        adm0_diff: null,
        adm0_tlc: "CHN",
        adm0_a3_us: "CHN",
        adm0_a3_fr: "CHN",
        adm0_a3_ru: "CHN",
        adm0_a3_es: "CHN",
        adm0_a3_cn: "CHN",
        adm0_a3_tw: "TWN",
        adm0_a3_in: "CHN",
        adm0_a3_np: "CHN",
        adm0_a3_pk: "CHN",
        adm0_a3_de: "CHN",
        adm0_a3_gb: "CHN",
        adm0_a3_br: "CHN",
        adm0_a3_il: "CHN",
        adm0_a3_ps: "CHN",
        adm0_a3_sa: "CHN",
        adm0_a3_eg: "CHN",
        adm0_a3_ma: "CHN",
        adm0_a3_pt: "CHN",
        adm0_a3_ar: "CHN",
        adm0_a3_jp: "CHN",
        adm0_a3_ko: "CHN",
        adm0_a3_vn: "CHN",
        adm0_a3_tr: "CHN",
        adm0_a3_id: "CHN",
        adm0_a3_pl: "CHN",
        adm0_a3_gr: "CHN",
        adm0_a3_it: "CHN",
        adm0_a3_nl: "CHN",
        adm0_a3_se: "CHN",
        adm0_a3_bd: "CHN",
        adm0_a3_ua: "CHN",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Eastern Asia",
        region_wb: "East Asia & Pacific",
        name_len: 5,
        long_len: 5,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 1.7,
        max_label: 5.7,
        label_x: 106.337289,
        label_y: 32.498178,
        ne_id: 1159320471,
        wikidataid: "Q148",
        name_ar: "الصين",
        name_bn: "গণচীন",
        name_de: "Volksrepublik China",
        name_en: "People's Republic of China",
        name_es: "China",
        name_fa: "جمهوری خلق چین",
        name_fr: "République populaire de Chine",
        name_el: "Λαϊκή Δημοκρατία της Κίνας",
        name_he: "הרפובליקה העממית של סין",
        name_hi: "चीनी जनवादी गणराज्य",
        name_hu: "Kína",
        name_id: "Republik Rakyat Tiongkok",
        name_it: "Cina",
        name_ja: "中華人民共和国",
        name_ko: "중화인민공화국",
        name_nl: "Volksrepubliek China",
        name_pl: "Chińska Republika Ludowa",
        name_pt: "China",
        name_ru: "Китайская Народная Республика",
        name_sv: "Kina",
        name_tr: "Çin Halk Cumhuriyeti",
        name_uk: "Китайська Народна Республіка",
        name_ur: "عوامی جمہوریہ چین",
        name_vi: "Trung Quốc",
        name_zh: "中华人民共和国",
        name_zht: "中華人民共和國",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: "Unrecognized",
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "CHN.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [118.18300781250002, 24.496289062499997],
              [118.14951171875003, 24.43613281249999],
              [118.09052734375001, 24.44614257812499],
              [118.08876953125002, 24.48886718749999],
              [118.07675781250003, 24.501416015624997],
              [118.09296875000001, 24.541210937499997],
              [118.10380859374999, 24.55234375],
              [118.17070312499999, 24.518505859374997],
              [118.18300781250002, 24.496289062499997],
            ],
          ],
          [
            [
              [121.86269531250002, 31.49228515625],
              [121.78046875000001, 31.463769531249994],
              [121.51992187500002, 31.549609375],
              [121.33642578125, 31.64375],
              [121.22685546874999, 31.758105468749996],
              [121.21113281250001, 31.805371093749997],
              [121.33896484375003, 31.79736328125],
              [121.46416015624999, 31.7564453125],
              [121.49179687500003, 31.693652343749996],
              [121.54228515624999, 31.67392578125],
              [121.57656250000002, 31.6373046875],
              [121.80830078125001, 31.552148437499994],
              [121.84365234375002, 31.5263671875],
              [121.86269531250002, 31.49228515625],
            ],
          ],
          [
            [
              [122.2958984375, 29.963427734374996],
              [122.28154296874999, 29.94384765625],
              [122.15781250000003, 30.001269531249996],
              [122.02402343750003, 30.013330078124994],
              [121.97783203124999, 30.063818359375],
              [121.96943359375001, 30.143115234374996],
              [122.11054687500001, 30.13974609375],
              [122.28447265624999, 30.068017578124994],
              [122.322265625, 30.031396484374994],
              [122.2958984375, 29.963427734374996],
            ],
          ],
          [
            [
              [122.17255859375001, 29.679003906249996],
              [122.16904296875003, 29.66025390625],
              [122.08378906249999, 29.725341796875],
              [122.04267578125001, 29.7359375],
              [122.06230468749999, 29.772753906249996],
              [122.11962890625, 29.7822265625],
              [122.1650390625, 29.70078125],
              [122.17255859375001, 29.679003906249996],
            ],
          ],
          [
            [
              [122.40390625000003, 29.892382812499996],
              [122.39404296875, 29.84609375],
              [122.36757812500002, 29.852685546874994],
              [122.33183593749999, 29.934960937499994],
              [122.35097656250002, 29.955224609374994],
              [122.40156250000001, 29.950244140624996],
              [122.40390625000003, 29.892382812499996],
            ],
          ],
          [
            [
              [119.82089843750003, 25.456982421874997],
              [119.74667968750003, 25.410693359375003],
              [119.70029296875003, 25.43271484374999],
              [119.69941406250001, 25.49472656249999],
              [119.72304687500002, 25.550585937500003],
              [119.69599609375001, 25.590869140625003],
              [119.72255859375002, 25.638818359374994],
              [119.77792968750003, 25.65317382812499],
              [119.79746093750003, 25.623242187499997],
              [119.82871093750003, 25.607373046874997],
              [119.83837890625, 25.591064453125],
              [119.83867187499999, 25.55966796874999],
              [119.80908203125, 25.5078125],
              [119.83242187500002, 25.47958984374999],
              [119.82089843750003, 25.456982421874997],
            ],
          ],
          [
            [
              [110.38515625000002, 21.093164062499994],
              [110.42236328125, 21.05859375],
              [110.52158203125003, 21.08310546874999],
              [110.53955078125, 21.039013671874997],
              [110.53886718749999, 21.018457031249994],
              [110.50390625, 20.967724609374997],
              [110.421875, 21.006884765625003],
              [110.33994140625003, 20.997753906249997],
              [110.28095703125001, 21.001171875],
              [110.2646484375, 21.025195312500003],
              [110.30986328124999, 21.074755859375003],
              [110.38515625000002, 21.093164062499994],
            ],
          ],
          [
            [
              [121.25136718750002, 28.08642578125],
              [121.16425781250001, 28.0625],
              [121.13154296875001, 28.06259765625],
              [121.13398437500001, 28.13525390625],
              [121.20546875000002, 28.204394531249996],
              [121.234375, 28.181298828124994],
              [121.2509765625, 28.145214843749997],
              [121.25136718750002, 28.08642578125],
            ],
          ],
          [
            [
              [113.55527343750003, 22.80419921875],
              [113.56367187500001, 22.75791015624999],
              [113.48564453124999, 22.828320312499997],
              [113.46337890625, 22.83237304687499],
              [113.42607421874999, 22.85859375],
              [113.40439453125003, 22.90283203125],
              [113.46494140625003, 22.904541015625],
              [113.5205078125, 22.85205078125],
              [113.55527343750003, 22.80419921875],
            ],
          ],
          [
            [
              [112.79023437500001, 21.601855468750003],
              [112.77109375000003, 21.581835937500003],
              [112.74199218749999, 21.618066406249994],
              [112.73349609375003, 21.669921875],
              [112.71269531249999, 21.697949218749997],
              [112.76054687499999, 21.73325195312499],
              [112.78203124999999, 21.772265625],
              [112.83906250000001, 21.76450195312499],
              [112.86259765624999, 21.752636718749997],
              [112.81259765625003, 21.712158203125],
              [112.80068359375002, 21.694873046875003],
              [112.79023437500001, 21.601855468750003],
            ],
          ],
          [
            [
              [112.64375, 21.6396484375],
              [112.54560546875001, 21.61850585937499],
              [112.525, 21.623046875],
              [112.55898437500002, 21.674755859374997],
              [112.64765625000001, 21.710253906250003],
              [112.64375, 21.6396484375],
            ],
          ],
          [
            [
              [107.97265625, 21.507958984374994],
              [107.90839843750001, 21.56040039062499],
              [107.80205078124999, 21.645166015624994],
              [107.75927734375, 21.655029296875],
              [107.64101562500002, 21.613916015624994],
              [107.47138671875001, 21.598339843749997],
              [107.43349609375002, 21.642285156249997],
              [107.35117187500003, 21.60888671875],
              [107.27207031250003, 21.710644531249997],
              [107.17851562499999, 21.717089843750003],
              [107.06162109375003, 21.794189453125],
              [107.01982421874999, 21.834863281249994],
              [107.00644531250003, 21.89340820312499],
              [106.97099609374999, 21.92392578124999],
              [106.92519531250002, 21.920117187499997],
              [106.87451171875, 21.95126953124999],
              [106.79414062500001, 21.981982421875003],
              [106.7294921875, 22.00034179687499],
              [106.69765625000002, 21.98618164062499],
              [106.66357421875, 21.97890625],
              [106.65771484375, 22.018212890624994],
              [106.66005859375002, 22.136474609375],
              [106.65419921875002, 22.241455078125],
              [106.63652343749999, 22.288623046875003],
              [106.59316406250002, 22.324511718750003],
              [106.55361328125002, 22.341699218749994],
              [106.53632812500001, 22.395410156249994],
              [106.55039062500003, 22.501367187499994],
              [106.58242187500002, 22.5732421875],
              [106.63310546874999, 22.58603515624999],
              [106.70156250000002, 22.637744140625003],
              [106.736328125, 22.7109375],
              [106.7802734375, 22.77890625],
              [106.6240234375, 22.874267578125],
              [106.54179687499999, 22.908349609374994],
              [106.45087890625001, 22.89389648437499],
              [106.33808593750001, 22.863476562499997],
              [106.27900390625001, 22.857470703125003],
              [106.24941406250002, 22.869433593750003],
              [106.18398437500002, 22.955126953125003],
              [106.1484375, 22.97006835937499],
              [106.06845703125003, 22.975537109374997],
              [106.0009765625, 22.974755859374994],
              [105.96230468750002, 22.937451171874997],
              [105.90263671874999, 22.924951171874994],
              [105.84296875000001, 22.922802734374997],
              [105.78232421875003, 22.96933593749999],
              [105.69121093749999, 23.029931640624994],
              [105.54814453124999, 23.07265625],
              [105.53085937500003, 23.121972656249994],
              [105.49453125000002, 23.180859375],
              [105.44013671875001, 23.2353515625],
              [105.35048828125002, 23.307666015625003],
              [105.275390625, 23.34521484375],
              [105.23876953125, 23.32211914062499],
              [105.18906250000003, 23.281054687500003],
              [104.99570312500003, 23.1943359375],
              [104.91015625, 23.160546875],
              [104.86474609375, 23.136376953124994],
              [104.82656250000002, 23.10019531249999],
              [104.81474609374999, 23.010791015625003],
              [104.79570312499999, 22.9111328125],
              [104.74003906249999, 22.860498046874994],
              [104.68730468749999, 22.822216796874997],
              [104.63173828125002, 22.81821289062499],
              [104.57753906250002, 22.820019531249997],
              [104.52685546875, 22.804101562499994],
              [104.37177734375001, 22.704052734374997],
              [104.29833984375, 22.71201171874999],
              [104.23828125, 22.768505859374997],
              [104.2125, 22.80942382812499],
              [104.14306640625, 22.80014648437499],
              [104.05390625000001, 22.75229492187499],
              [104.0126953125, 22.66635742187499],
              [103.99082031250003, 22.586132812499997],
              [103.97138671875001, 22.550488281249997],
              [103.94150390625003, 22.540087890625003],
              [103.9150390625, 22.538232421874994],
              [103.63730468750003, 22.77001953125],
              [103.62021484375003, 22.78203125],
              [103.57070312500002, 22.734423828125003],
              [103.525390625, 22.611572265625],
              [103.49296874999999, 22.58798828124999],
              [103.47099609374999, 22.597412109375],
              [103.35605468750003, 22.7546875],
              [103.32666015625, 22.769775390625],
              [103.30058593749999, 22.764404296875],
              [103.26630859375001, 22.713525390624994],
              [103.193359375, 22.63852539062499],
              [103.13759765625002, 22.59296875],
              [103.13632812500003, 22.542236328125],
              [103.07587890625001, 22.497509765624997],
              [103.00537109375, 22.45297851562499],
              [102.98193359375, 22.4482421875],
              [102.93515625000003, 22.46616210937499],
              [102.87421875000001, 22.525390625],
              [102.830078125, 22.587158203125],
              [102.72099609374999, 22.648486328125003],
              [102.59853515625002, 22.700390625],
              [102.51718750000003, 22.741015625],
              [102.47089843750001, 22.750927734374997],
              [102.42792968750001, 22.7328125],
              [102.40644531250001, 22.7080078125],
              [102.37578124999999, 22.646630859374994],
              [102.30224609375, 22.54599609374999],
              [102.23701171875001, 22.466015625],
              [102.17597656250001, 22.41464843749999],
              [102.12744140625, 22.379199218750003],
              [102.09150390625001, 22.412255859374994],
              [102.0244140625, 22.439208984375],
              [101.94541015625003, 22.439404296874997],
              [101.841796875, 22.388476562500003],
              [101.75996093750001, 22.490332031250034],
              [101.73876953125, 22.495263671874994],
              [101.70751953125, 22.486572265625],
              [101.67148437500003, 22.462304687499994],
              [101.64619140625001, 22.405419921874994],
              [101.61992187499999, 22.327441406250003],
              [101.56787109375, 22.2763671875],
              [101.52451171874998, 22.253662109375],
              [101.53730468750001, 22.209863281249994],
              [101.56181640624999, 22.162402343750045],
              [101.56025390625001, 22.120898437500102],
              [101.57578125000003, 22.05527343749999],
              [101.60292968749997, 21.98969726562501],
              [101.69960937500002, 21.882470703124994],
              [101.73652343750001, 21.826513671874977],
              [101.74394531249999, 21.777978515624994],
              [101.74726562500001, 21.605761718750003],
              [101.74345703124999, 21.533837890624994],
              [101.72421875000003, 21.39501953125],
              [101.72294921874999, 21.31494140625],
              [101.76308593750002, 21.27890625],
              [101.80205078124999, 21.235986328124994],
              [101.80058593749999, 21.212597656249997],
              [101.78349609374999, 21.204150390625045],
              [101.728125, 21.156396484374994],
              [101.70478515625001, 21.150146484375],
              [101.66855468750003, 21.169628906249997],
              [101.62167968750003, 21.18442382812499],
              [101.58388671875002, 21.203564453125068],
              [101.54238281250002, 21.234277343749994],
              [101.44355468750001, 21.23081054687499],
              [101.28144531250001, 21.184130859375003],
              [101.24785156249993, 21.197314453125045],
              [101.22441406249999, 21.22373046874999],
              [101.21181640625002, 21.278222656249994],
              [101.21992187500001, 21.342431640624994],
              [101.20556640625, 21.38330078125],
              [101.17539062500003, 21.407519531250003],
              [101.19667968750002, 21.52207031249999],
              [101.13886718750001, 21.56748046874999],
              [101.14726562499999, 21.581640625],
              [101.128125, 21.705126953125003],
              [101.130859375, 21.735546875],
              [101.12070312500003, 21.74609375],
              [101.07978515625001, 21.755859375],
              [101.01933593749999, 21.736376953125003],
              [100.83515625000001, 21.655175781249994],
              [100.67714843750002, 21.504931640625003],
              [100.60458984375003, 21.47177734374999],
              [100.53134765625003, 21.45810546874999],
              [100.44570312500002, 21.484082031249997],
              [100.3505859375, 21.501025390625003],
              [100.21474609375002, 21.46298828124999],
              [100.14765625000001, 21.480517578125003],
              [100.11679687500003, 21.511181640624997],
              [100.08925781250002, 21.557910156250003],
              [100.10576171874999, 21.61704101562499],
              [100.09550781249999, 21.66064453125],
              [100.04121093750001, 21.682763671874994],
              [99.97822265625001, 21.701611328124997],
              [99.94072265624999, 21.758740234374997],
              [99.92558593749999, 21.82080078125],
              [99.9404296875, 21.901611328125],
              [99.94785156250003, 21.988330078125003],
              [99.91767578125001, 22.028027343749997],
              [99.82539062500001, 22.049707031249994],
              [99.59267578125002, 22.089160156250003],
              [99.388671875, 22.110791015624997],
              [99.303125, 22.100634765625003],
              [99.2333984375, 22.11015625],
              [99.19296875000003, 22.1259765625],
              [99.17343750000003, 22.1533203125],
              [99.17236328125, 22.19248046874999],
              [99.20537109374999, 22.28256835937499],
              [99.24306640625002, 22.370361328125],
              [99.33769531249999, 22.498046875],
              [99.34316406250002, 22.58652343749999],
              [99.33828125000002, 22.688671875],
              [99.38515625000002, 22.825097656249994],
              [99.466796875, 22.927294921875003],
              [99.50712890624999, 22.959130859374994],
              [99.49726562500001, 23.004589843749997],
              [99.46455078125001, 23.04624023437499],
              [99.41806640625003, 23.069238281249994],
              [99.3408203125, 23.095898437499997],
              [99.22031250000003, 23.103320312500003],
              [99.05507812500002, 23.130566406249997],
              [98.86376953125, 23.19125976562499],
              [98.88554687499999, 23.30747070312499],
              [98.88261718749999, 23.380322265624997],
              [98.85888671875, 23.440087890624994],
              [98.81972656250002, 23.48251953124999],
              [98.7978515625, 23.520410156249994],
              [98.83222656250001, 23.62436523437499],
              [98.78769531250003, 23.737841796875003],
              [98.73505859375001, 23.783105468749994],
              [98.68085937500001, 23.841796875],
              [98.6767578125, 23.905078125],
              [98.70156250000002, 23.9640625],
              [98.833984375, 24.090576171875],
              [98.83505859375003, 24.12119140624999],
              [98.80234375000003, 24.118701171875003],
              [98.76435546875001, 24.11606445312499],
              [98.58339843750002, 24.06982421875],
              [98.56416015625001, 24.098828125],
              [98.49941406250002, 24.115673828124997],
              [98.36728515625003, 24.119042968749994],
              [98.2125, 24.110644531250003],
              [98.01689453124999, 24.0654296875],
              [97.83769531249999, 23.986279296874997],
              [97.75566406249999, 23.931884765625],
              [97.68603515625, 23.898095703124994],
              [97.62968749999999, 23.887158203124997],
              [97.56455078125003, 23.911035156249994],
              [97.56826171875002, 23.988476562499997],
              [97.690625, 24.130810546874997],
              [97.70820312500001, 24.228759765625],
              [97.67070312499999, 24.312744140625],
              [97.66660156250003, 24.37998046874999],
              [97.62363281250003, 24.42294921874999],
              [97.56328124999999, 24.44384765625],
              [97.53144531250001, 24.49169921875],
              [97.52939453125003, 24.63120117187499],
              [97.58330078124999, 24.774804687499994],
              [97.67070312499999, 24.820117187500003],
              [97.72382812500001, 24.841992187499997],
              [97.73789062500003, 24.869873046875],
              [97.71074218749999, 24.970361328124994],
              [97.71494140625003, 25.034326171874994],
              [97.76738281249999, 25.15805664062499],
              [97.81953125000001, 25.251855468749994],
              [97.91796875, 25.236132812500003],
              [97.96201171875003, 25.259326171875003],
              [98.0107421875, 25.292529296875003],
              [98.06406250000003, 25.34897460937499],
              [98.099609375, 25.415722656249997],
              [98.14287109374999, 25.57109375],
              [98.17255859375001, 25.59453125],
              [98.29658203125001, 25.56884765625],
              [98.33378906249999, 25.58676757812499],
              [98.40166015624999, 25.677978515625],
              [98.46552734375001, 25.788867187500003],
              [98.55839843749999, 25.8232421875],
              [98.62539062500002, 25.826708984375003],
              [98.65625, 25.863574218750003],
              [98.65468750000002, 25.917773437500003],
              [98.59101562500001, 26.003710937499996],
              [98.56406250000003, 26.072412109374994],
              [98.57197265625001, 26.1140625],
              [98.66318359375003, 26.139453125],
              [98.685546875, 26.189355468749994],
              [98.671875, 26.298535156249997],
              [98.70947265625, 26.4296875],
              [98.73183593750002, 26.583398437499994],
              [98.73935546875003, 26.698144531249994],
              [98.73847656250001, 26.7857421875],
              [98.7294921875, 26.877392578124997],
              [98.71650390625001, 27.044921875],
              [98.6748046875, 27.190625],
              [98.68242187499999, 27.2453125],
              [98.6767578125, 27.421923828124996],
              [98.65117187499999, 27.572460937499997],
              [98.59980468750001, 27.598828125],
              [98.50449218750003, 27.64765625],
              [98.45253906250002, 27.6572265625],
              [98.40888671875001, 27.639453125],
              [98.39238281249999, 27.587060546874994],
              [98.35048828125002, 27.5380859375],
              [98.298828125, 27.550097656249996],
              [98.27421874999999, 27.599072265624997],
              [98.24101562499999, 27.66318359375],
              [98.13046875000003, 27.967578125],
              [98.11835937500001, 28.055224609374996],
              [98.09892578124999, 28.142285156249997],
              [98.06162109375003, 28.185888671875],
              [98.02226562499999, 28.2115234375],
              [97.93408203125, 28.313818359375],
              [97.88759765625002, 28.356494140624996],
              [97.86494140625001, 28.363574218749996],
              [97.81650390625003, 28.356347656249994],
              [97.76904296875, 28.356152343749997],
              [97.73007812500003, 28.407128906249994],
              [97.69462890624999, 28.4693359375],
              [97.65888671875001, 28.5],
              [97.59921875000003, 28.517041015624997],
              [97.53789062499999, 28.510205078124997],
              [97.50214843750001, 28.456347656249996],
              [97.47773437500001, 28.425634765625],
              [97.43144531249999, 28.35390625],
              [97.3564453125, 28.2544921875],
              [97.32246093750001, 28.21796875],
              [97.28945312499997, 28.236816406249943],
              [97.14511718750003, 28.34033203125],
              [97.07539062500001, 28.368945312499996],
              [96.98085937500002, 28.337695312499996],
              [96.8330078125, 28.36240234375],
              [96.77578125000002, 28.367041015625],
              [96.65283203124994, 28.44975585937499],
              [96.60263671875003, 28.459912109375125],
              [96.427734375, 28.406005859375],
              [96.38906250000002, 28.367919921875057],
              [96.36640625000001, 28.36728515625],
              [96.31982421875, 28.386523437499996],
              [96.28144531249995, 28.412060546875125],
              [96.27890625000003, 28.428173828124997],
              [96.326171875, 28.468554687499996],
              [96.32988281249999, 28.496826171875],
              [96.32734375000001, 28.525390625],
              [96.39560546875003, 28.60654296875],
              [96.58085937499999, 28.763671875],
              [96.55, 28.829589843750114],
              [96.47714843750003, 28.959326171875],
              [96.46708984374993, 29.022265625000102],
              [96.43574218750001, 29.050683593749994],
              [96.346875, 29.02744140625009],
              [96.16220703125003, 28.909716796874996],
              [96.13710937500002, 28.922607421875],
              [96.14140624999999, 28.9634765625],
              [96.12236328124999, 29.082080078124996],
              [96.18085937500001, 29.11767578125],
              [96.2705078125, 29.16123046875],
              [96.33974609375002, 29.209814453125],
              [96.35585937500002, 29.249072265624932],
              [96.33720703124999, 29.260986328125],
              [96.23496093750003, 29.245800781249997],
              [96.19472656250002, 29.2724609375],
              [96.12851562500003, 29.381396484374996],
              [96.07958984375, 29.424121093749996],
              [96.03535156250001, 29.447167968749994],
              [95.88505859374999, 29.390917968749996],
              [95.71035156250002, 29.313818359375],
              [95.51582031250001, 29.206347656249996],
              [95.51699218750002, 29.151171875000102],
              [95.49375, 29.137011718749996],
              [95.45654296874994, 29.102294921875057],
              [95.42021484374999, 29.054296875],
              [95.38925781250003, 29.037402343749996],
              [95.353125, 29.035888671875],
              [95.27910156249999, 29.049560546875],
              [95.14472656250001, 29.104052734374996],
              [94.99882812499999, 29.149169921875],
              [94.96748046874995, 29.144042968750057],
              [94.76943359375002, 29.17587890625],
              [94.76308593750002, 29.20126953125],
              [94.7333984375, 29.251611328124994],
              [94.67705078124999, 29.297021484375136],
              [94.623046875, 29.312402343749994],
              [94.46806640624999, 29.216210937499994],
              [94.29326171874999, 29.14462890625],
              [94.19345703125003, 29.059912109375],
              [94.11152343750001, 28.975878906250102],
              [94.01767578125003, 28.959521484374996],
              [94.01328125000003, 28.907519531249996],
              [93.9736328125, 28.860791015625068],
              [93.90224609375002, 28.80322265625],
              [93.7607421875, 28.729785156250045],
              [93.66494140625002, 28.690234375],
              [93.36054687500001, 28.654052734375057],
              [93.251953125, 28.6294921875],
              [93.20654296875, 28.5908203125],
              [93.15781250000003, 28.492724609374996],
              [93.11923828125003, 28.402294921874997],
              [93.03496093749999, 28.32763671875],
              [92.8818359375, 28.228125],
              [92.70185546875001, 28.147119140624994],
              [92.65253906250001, 28.093359375],
              [92.64345703125002, 28.0615234375],
              [92.665625, 28.049853515624996],
              [92.6875, 28.025732421875],
              [92.68779296874999, 27.988964843749997],
              [92.66435546874999, 27.948925781249997],
              [92.54667968749999, 27.879199218749996],
              [92.48066406250001, 27.845947265625],
              [92.41484374999999, 27.824609375],
              [92.34101562500001, 27.820751953124997],
              [92.27011718750003, 27.830224609374994],
              [92.25048828124994, 27.841503906250068],
              [92.22226562500003, 27.826953125],
              [92.15761718750002, 27.812255859375],
              [92.10126953125001, 27.8076171875],
              [91.97763671875003, 27.730371093749994],
              [91.909375, 27.7296875],
              [91.82470703125, 27.746435546875],
              [91.71259765625001, 27.759814453124996],
              [91.63193359375003, 27.759960937499997],
              [91.62939453125, 27.80087890625],
              [91.64189453124999, 27.923242187499994],
              [91.60556640625003, 27.951708984374996],
              [91.49335937500001, 27.981787109375],
              [91.36757812500002, 28.021630859374994],
              [91.30683593750001, 28.064013671874996],
              [91.27304687500003, 28.078369140625],
              [91.22587890624999, 28.071240234374997],
              [91.14990234375, 28.026757812499994],
              [91.07773437500003, 27.974462890625],
              [91.02080078124999, 27.970068359375],
              [90.9625, 27.994580078124997],
              [90.90664062500002, 28.026513671874994],
              [90.71572265625002, 28.071728515624997],
              [90.63007812500001, 28.078564453124997],
              [90.47734374999999, 28.070849609374996],
              [90.35273437500001, 28.080224609374994],
              [90.33310546875003, 28.093994140625],
              [90.33378906249999, 28.119140625],
              [90.35214843750003, 28.168164062499997],
              [90.36298828125001, 28.216503906249997],
              [90.34824218750003, 28.243945312499996],
              [90.22080078125003, 28.277734375],
              [90.1044921875, 28.302050781249996],
              [89.98105468750003, 28.311181640624994],
              [89.89785156250002, 28.294140625],
              [89.81689453125, 28.256298828124997],
              [89.74980468749999, 28.188183593749997],
              [89.65273437500002, 28.15830078125],
              [89.53691406249999, 28.107421875],
              [89.48066406250001, 28.059960937499994],
              [89.39589843750002, 27.958154296874994],
              [89.27265625000001, 27.833154296874994],
              [89.16044921874999, 27.711279296875],
              [89.10234374999999, 27.592578125],
              [89.02548828125003, 27.517871093749996],
              [88.94755859374999, 27.464013671874994],
              [88.89140624999999, 27.316064453124994],
              [88.83251953124994, 27.362841796875102],
              [88.76484375000001, 27.429882812499997],
              [88.7490234375, 27.521875000000136],
              [88.82988281249999, 27.767382812499996],
              [88.84882812500001, 27.86865234375],
              [88.82861328125, 27.907275390624996],
              [88.80371093749994, 28.006933593750034],
              [88.75625, 28.039697265624994],
              [88.62109375, 28.091845703124996],
              [88.57792968749999, 28.093359375000034],
              [88.53164062500002, 28.057373046875],
              [88.48613281249999, 28.034472656249996],
              [88.42597656250001, 28.011669921874997],
              [88.27519531249999, 27.96884765625],
              [88.14111328125, 27.948925781249997],
              [88.10898437499998, 27.93300781250008],
              [88.09892578124999, 27.904541015625],
              [88.10976562499997, 27.870605468749943],
              [88.02333984375002, 27.8833984375],
              [87.93339843749999, 27.890820312499997],
              [87.86074218750002, 27.886083984375],
              [87.68271484375003, 27.82138671875],
              [87.62255859375, 27.815185546875],
              [87.55527343750003, 27.821826171874996],
              [87.46416015624999, 27.823828125],
              [87.29072265625001, 27.821923828124994],
              [87.14140624999999, 27.838330078124997],
              [87.02011718750003, 27.928662109374997],
              [86.93378906250001, 27.968457031249997],
              [86.84238281250003, 27.999169921874994],
              [86.75039062500002, 28.0220703125],
              [86.71962890625002, 28.070654296875],
              [86.69052734375003, 28.094921875],
              [86.61445312500001, 28.10302734375],
              [86.55449218749999, 28.085205078125],
              [86.51689453124999, 27.963525390624994],
              [86.48496093750003, 27.93955078125],
              [86.40869140625, 27.928662109374997],
              [86.32861328125, 27.959521484374996],
              [86.21796875000001, 28.0220703125],
              [86.17421875000002, 28.091699218749994],
              [86.13701171874999, 28.11435546875],
              [86.07871093750003, 28.08359375],
              [86.07548828124999, 27.994580078124997],
              [86.06416015625001, 27.934716796874994],
              [85.99453125000002, 27.910400390625],
              [85.9541015625, 27.92822265625],
              [85.92167968749999, 27.989697265624997],
              [85.84023437500002, 28.1353515625],
              [85.75947265625001, 28.220654296874997],
              [85.67832031250003, 28.27744140625],
              [85.41064453125, 28.276025390624994],
              [85.21210937500001, 28.292626953124994],
              [85.12246093750002, 28.315966796874996],
              [85.08857421875001, 28.372265625],
              [85.12148437500002, 28.484277343749994],
              [85.16015625, 28.571875],
              [85.15908203125002, 28.592236328124997],
              [85.12636718750002, 28.60263671875],
              [85.06914062499999, 28.609667968749996],
              [84.85507812500003, 28.553613281249994],
              [84.796875, 28.560205078124994],
              [84.759375, 28.579248046874994],
              [84.71425781250002, 28.595556640625],
              [84.6767578125, 28.621533203124997],
              [84.65058593750001, 28.659570312499994],
              [84.46542968750003, 28.7529296875],
              [84.41074218750003, 28.80390625],
              [84.31210937500003, 28.868115234374997],
              [84.22871093750001, 28.911767578124994],
              [84.17558593749999, 29.036376953125],
              [84.12783203125002, 29.156298828124996],
              [84.10136718749999, 29.219970703125],
              [84.02197265625, 29.253857421874997],
              [83.93593750000002, 29.279492187499997],
              [83.79042968750002, 29.227441406249994],
              [83.67109375000001, 29.18759765625],
              [83.58349609375, 29.18359375],
              [83.45664062500003, 29.306347656249997],
              [83.35517578125001, 29.439160156249997],
              [83.23515624999999, 29.554589843749994],
              [83.15546875000001, 29.612646484375],
              [83.01396484374999, 29.618066406249994],
              [82.85429687499999, 29.683398437499996],
              [82.64082031250001, 29.831201171874994],
              [82.48652343750001, 29.94150390625],
              [82.220703125, 30.063867187499994],
              [82.15898437499999, 30.115185546874997],
              [82.13535156250003, 30.158984375],
              [82.09892578124999, 30.245068359374997],
              [82.04335937500002, 30.3267578125],
              [81.85488281250002, 30.36240234375],
              [81.64189453124999, 30.3875],
              [81.41718750000001, 30.337597656249997],
              [81.25507812500001, 30.093310546874996],
              [81.17714843750002, 30.039892578125],
              [81.1103515625, 30.036816406249997],
              [81.05556640625002, 30.098974609375],
              [81.01025390625, 30.164501953124997],
              [80.98544921875003, 30.237109375],
              [80.87353515624994, 30.290576171875045],
              [80.74677734375001, 30.360400390624996],
              [80.68212890625, 30.41484375],
              [80.60888671875, 30.448876953124994],
              [80.541015625, 30.463525390624994],
              [80.40957031250002, 30.509472656249997],
              [80.26093750000001, 30.561328125],
              [80.19121093749999, 30.568408203124996],
              [80.18623046875001, 30.605322265625],
              [80.20712890625003, 30.683740234374994],
              [80.1943359375, 30.759228515625125],
              [80.1494140625, 30.78984375],
              [80.08144531250002, 30.781933593749997],
              [79.92451171875001, 30.88876953125],
              [79.91855468750009, 30.889892578125114],
              [79.91660156250003, 30.894189453124994],
              [79.871875, 30.924609375000045],
              [79.79462890625001, 30.96826171875],
              [79.66425781250001, 30.965234375],
              [79.5654296875, 30.949072265625],
              [79.4931640625, 30.993701171874996],
              [79.38847656249999, 31.064208984375],
              [79.36962890625, 31.07993164062509],
              [79.33876953125002, 31.105712890625],
              [79.23261718749995, 31.241748046875045],
              [79.10712890625001, 31.402636718749996],
              [79.04375, 31.426220703124997],
              [79.01113281250002, 31.414111328124996],
              [78.97392578124999, 31.32861328125],
              [78.94599609375001, 31.337207031249996],
              [78.89951171875003, 31.331347656249996],
              [78.84453124999999, 31.301513671875],
              [78.79160156250003, 31.293652343750125],
              [78.7578125, 31.302490234375],
              [78.74355468750002, 31.323779296875045],
              [78.75859374999999, 31.436572265624996],
              [78.72675781250001, 31.471826171874994],
              [78.75507812500001, 31.55029296875],
              [78.80292968750001, 31.618066406249994],
              [78.75390625, 31.66835937500008],
              [78.69345703125003, 31.740380859374994],
              [78.68701171875, 31.80551757812492],
              [78.7197265625, 31.887646484374997],
              [78.73544921875003, 31.957958984374997],
              [78.7255859375, 31.983789062499994],
              [78.677734375, 32.023046875],
              [78.49589843749999, 32.215771484375],
              [78.48613281249993, 32.236230468750136],
              [78.45527343750001, 32.300341796874996],
              [78.44130859375002, 32.397363281249994],
              [78.41748046875, 32.46669921875002],
              [78.3896484375, 32.51987304687498],
              [78.39169921875003, 32.544726562499996],
              [78.4125, 32.55771484375],
              [78.5263671875, 32.57080078125006],
              [78.63154296875001, 32.578955078125],
              [78.70087890625001, 32.597021484375],
              [78.73671875000002, 32.558398437500045],
              [78.75351562500003, 32.499267578125],
              [78.77128906249999, 32.468066406249996],
              [78.837890625, 32.411962890625],
              [78.9189453125, 32.3582031250001],
              [78.99765625000003, 32.365136718749994],
              [79.06699218750003, 32.38818359375],
              [79.12734375000002, 32.47578125],
              [79.169921875, 32.49721679687502],
              [79.21933593750003, 32.50107421875],
              [79.21904296874999, 32.507568359375],
              [79.21650390625001, 32.564013671874996],
              [79.23388671875, 32.703076171875],
              [79.22792968750002, 32.7587890625],
              [79.20556640625, 32.809033203125125],
              [79.20957031250003, 32.86484375],
              [79.20224609375003, 32.946044921875114],
              [79.1455078125, 33.00146484375006],
              [79.10859375000001, 33.02265625],
              [79.10283203124999, 33.05253906249999],
              [79.12167968750003, 33.10810546875],
              [79.13515625000002, 33.17192382812499],
              [79.1125, 33.22626953125001],
              [79.06650390625003, 33.250390625],
              [79.01259765625002, 33.291455078125],
              [78.94843750000001, 33.346533203125034],
              [78.91669921875001, 33.386767578124996],
              [78.86503906249999, 33.431103515625],
              [78.80185546875003, 33.49970703125],
              [78.78994140625002, 33.650341796875],
              [78.78378906250003, 33.8087890625],
              [78.76171874999994, 33.88759765625008],
              [78.72666015625003, 34.01337890625],
              [78.73173828124999, 34.055566406249994],
              [78.75302734375003, 34.087695312499996],
              [78.93173828125003, 34.18896484375],
              [78.97060546875002, 34.22822265625],
              [78.97695312500002, 34.258105468749996],
              [78.97011718750002, 34.302636718749994],
              [78.93642578125002, 34.35195312500002],
              [78.86484375000003, 34.39033203125],
              [78.76308593750002, 34.452929687499996],
              [78.67080078125002, 34.5181640625],
              [78.51572265625003, 34.557958984375],
              [78.32695312499999, 34.606396484375],
              [78.28203124999999, 34.65390625],
              [78.23613281249999, 34.769824218750045],
              [78.15849609374999, 34.94648437500001],
              [78.07578125000003, 35.134912109374994],
              [78.01220703125, 35.251025390624996],
              [78.00917968750002, 35.306933593749996],
              [78.04746093750003, 35.4494140625],
              [78.04267578125001, 35.4797851562501],
              [78.00947265625001, 35.490234375000114],
              [77.94589843750003, 35.47163085937507],
              [77.89492187500002, 35.449023437499996],
              [77.8515625, 35.460791015625],
              [77.81093750000002, 35.484521484374994],
              [77.80253906249999, 35.4927734375],
              [77.79941406250003, 35.495898437500045],
              [77.72402343750002, 35.48056640625],
              [77.57255859374999, 35.471826171874994],
              [77.52001953125, 35.4734375],
              [77.44648437500001, 35.4755859375],
              [77.29482421875002, 35.508154296875],
              [77.09003906250001, 35.552050781249996],
              [76.87890625, 35.61328125],
              [76.76689453124999, 35.66171875],
              [76.7275390625, 35.678662109375],
              [76.6318359375, 35.729394531249994],
              [76.5634765625, 35.772998046874996],
              [76.55126953125, 35.887060546875],
              [76.50205078125003, 35.878222656249996],
              [76.3857421875, 35.837158203125],
              [76.25166015625001, 35.8109375],
              [76.17783203125003, 35.810546875],
              [76.14785156250002, 35.829003906249994],
              [76.10332031249999, 35.94921875],
              [76.07089843750003, 35.9830078125],
              [76.01044921875001, 35.996337890625],
              [75.94511718750002, 36.017578125],
              [75.91230468750001, 36.048974609374994],
              [75.9048828125, 36.0884765625],
              [75.93408203125, 36.133935546874994],
              [75.96865234375002, 36.168847656249994],
              [75.97441406250002, 36.382421875],
              [75.95185546875001, 36.45810546875],
              [75.93300781250002, 36.52158203125],
              [75.88496093750001, 36.600732421874994],
              [75.84023437500002, 36.649707031249996],
              [75.77216796875001, 36.694921875],
              [75.66718750000001, 36.741992187499996],
              [75.57373046875, 36.759326171874996],
              [75.46025390625002, 36.725048828125],
              [75.42421875000002, 36.738232421875],
              [75.37685546875002, 36.883691406249994],
              [75.3466796875, 36.913476562499994],
              [75.14521484375001, 36.9732421875],
              [75.05390625000001, 36.987158203125],
              [74.94912109375002, 36.968359375],
              [74.8892578125, 36.952441406249996],
              [74.84121093750002, 36.9791015625],
              [74.76601562500002, 37.012744140624996],
              [74.69218750000002, 37.0357421875],
              [74.6005859375, 37.036669921874996],
              [74.54140625000002, 37.02216796875],
              [74.52646484375, 37.030664062499994],
              [74.49794921875002, 37.0572265625],
              [74.37617187500001, 37.137353515624994],
              [74.37216796875, 37.15771484375],
              [74.55898437500002, 37.236621093749996],
              [74.6689453125, 37.26669921875],
              [74.72666015625, 37.29072265625],
              [74.73896484375001, 37.28564453125],
              [74.76738281250002, 37.249169921874994],
              [74.84023437500002, 37.225048828125],
              [74.89130859375001, 37.231640625],
              [74.91816406250001, 37.25],
              [75.0083984375, 37.2935546875],
              [75.07900390625002, 37.344042968749996],
              [75.11875, 37.385693359375],
              [75.09746093750002, 37.45126953125],
              [74.98642578125, 37.53037109375],
              [74.91582031250002, 37.572802734374996],
              [74.89423828125001, 37.601416015625],
              [74.91230468750001, 37.687304687499996],
              [74.93828125000002, 37.772509765624996],
              [74.92128906250002, 37.804980468749996],
              [74.90029296875002, 37.83271484375],
              [74.89082031250001, 37.92578125],
              [74.84248046875001, 38.0380859375],
              [74.7896484375, 38.10361328125],
              [74.77509765625001, 38.19189453125],
              [74.7720703125, 38.274755859375],
              [74.8359375, 38.404296875],
              [74.81230468750002, 38.460302734375],
              [74.74501953125002, 38.510009765625],
              [74.51406250000002, 38.6],
              [74.27744140625, 38.659765625],
              [74.18730468750002, 38.657519531249996],
              [74.13134765625, 38.661181640624996],
              [74.06533203125002, 38.60849609375],
              [74.02558593750001, 38.53984375],
              [73.97001953125002, 38.53369140625],
              [73.869140625, 38.562890625],
              [73.80166015625002, 38.606884765625],
              [73.75410156250001, 38.69892578125],
              [73.716796875, 38.817236328125],
              [73.69609375000002, 38.854296875],
              [73.70683593750002, 38.88623046875],
              [73.72998046875, 38.914697265624994],
              [73.79453125, 38.941308593749994],
              [73.8052734375, 38.968652343749994],
              [73.79560546875001, 39.0021484375],
              [73.74375, 39.04453125],
              [73.6904296875, 39.104541015624996],
              [73.60732421875002, 39.22919921875],
              [73.62314453125, 39.2978515625],
              [73.636328125, 39.3966796875],
              [73.63164062500002, 39.448876953124994],
              [73.71572265625002, 39.462255859375],
              [73.82294921875001, 39.48896484375],
              [73.87275390625001, 39.53330078125],
              [73.90712890625002, 39.578515625],
              [73.9146484375, 39.606494140624996],
              [73.88251953125001, 39.71455078125],
              [73.83974609375002, 39.762841796874994],
              [73.83535156250002, 39.800146484375],
              [73.85625, 39.828662109374996],
              [73.88457031250002, 39.8779296875],
              [73.93876953125002, 39.978808593749996],
              [73.99160156250002, 40.043115234374994],
              [74.0205078125, 40.059375],
              [74.08515625000001, 40.07431640625],
              [74.24267578125, 40.092041015625],
              [74.41191406250002, 40.13720703125],
              [74.61308593750002, 40.27216796875],
              [74.67988281250001, 40.310595703124996],
              [74.76777343750001, 40.329882812499996],
              [74.83046875000002, 40.328515625],
              [74.841796875, 40.344970703125],
              [74.80126953125, 40.428515625],
              [74.8111328125, 40.458789062499996],
              [74.83515625000001, 40.4826171875],
              [74.865625, 40.493505859375],
              [75.0044921875, 40.449511718749996],
              [75.111328125, 40.4541015625],
              [75.24101562500002, 40.480273437499996],
              [75.52080078125002, 40.6275390625],
              [75.55556640625002, 40.6251953125],
              [75.58349609375, 40.605322265625],
              [75.61738281250001, 40.5166015625],
              [75.65595703125001, 40.329248046874994],
              [75.67714843750002, 40.305810546874994],
              [75.87197265625002, 40.30322265625],
              [76.00429687500002, 40.371435546875],
              [76.06230468749999, 40.387548828125],
              [76.15664062500002, 40.37646484375],
              [76.2060546875, 40.4083984375],
              [76.25830078125, 40.43076171875],
              [76.31855468750001, 40.35224609375],
              [76.39638671875002, 40.389794921874994],
              [76.48017578125001, 40.449511718749996],
              [76.52089843750002, 40.51123046875],
              [76.57792968749999, 40.577880859375],
              [76.62216796875003, 40.662353515625],
              [76.63984375000001, 40.742236328124996],
              [76.6611328125, 40.779638671875],
              [76.70839843750002, 40.818115234375],
              [76.82402343749999, 40.982324218749994],
              [76.90771484375, 41.024169921875],
              [76.98662109374999, 41.03916015625],
              [77.18203125000002, 41.0107421875],
              [77.28398437499999, 41.01435546875],
              [77.58173828125001, 40.9927734375],
              [77.71933593750003, 41.024316406249994],
              [77.81523437499999, 41.055615234375],
              [77.95644531250002, 41.050683593749994],
              [78.12343750000002, 41.075634765625],
              [78.34628906250003, 41.2814453125],
              [78.34882812500001, 41.3251953125],
              [78.36240234375003, 41.371630859374996],
              [78.44287109375, 41.417529296874996],
              [78.54316406250001, 41.4595703125],
              [78.74257812500002, 41.56005859375],
              [79.1484375, 41.719140625],
              [79.29355468750003, 41.7828125],
              [79.35439453125002, 41.781054687499996],
              [79.50390625, 41.82099609375],
              [79.76611328125, 41.898876953125],
              [79.84042968750003, 41.995751953124994],
              [79.90966796875, 42.014990234375],
              [80.21621093750002, 42.032421875],
              [80.23515624999999, 42.04345703125],
              [80.24619140625003, 42.059814453125],
              [80.22919921875001, 42.129833984375],
              [80.209375, 42.1900390625],
              [80.23300781250003, 42.2078125],
              [80.25908203124999, 42.235400390624996],
              [80.25507812500001, 42.274169921875],
              [80.20576171875001, 42.3994140625],
              [80.17929687500003, 42.518359375],
              [80.16191406249999, 42.625537109374996],
              [80.1650390625, 42.66552734375],
              [80.20224609375003, 42.73447265625],
              [80.25029296874999, 42.797265625],
              [80.42402343750001, 42.855761718749996],
              [80.53896484375002, 42.873486328125],
              [80.54375, 42.91171875],
              [80.45068359375, 42.935546875],
              [80.38339843750003, 42.973779296874994],
              [80.37128906250001, 42.99560546875],
              [80.37451171875, 43.020410156249994],
              [80.39023437500003, 43.043115234374994],
              [80.50703125000001, 43.085791015625],
              [80.61699218749999, 43.128271484375],
              [80.75117187500001, 43.102490234375],
              [80.77773437500002, 43.118945312499996],
              [80.78574218750003, 43.161572265625],
              [80.75703125000001, 43.204345703125],
              [80.72978515624999, 43.274267578125],
              [80.66777343749999, 43.31005859375],
              [80.66542968750002, 43.352978515625],
              [80.70380859375001, 43.427050781249996],
              [80.65078125000002, 43.56416015625],
              [80.59345703125001, 43.685107421874996],
              [80.49599609375002, 43.89208984375],
              [80.43154296875002, 43.9517578125],
              [80.39580078124999, 44.047167968749996],
              [80.35527343749999, 44.097265625],
              [80.35898437500003, 44.171289062499994],
              [80.36533203125003, 44.223291015624994],
              [80.35488281250002, 44.326513671875],
              [80.33632812500002, 44.438378906249994],
              [80.35507812500003, 44.552001953125],
              [80.39101562500002, 44.626806640625],
              [80.38144531250003, 44.655419921874994],
              [80.40058593750001, 44.676904296874994],
              [80.45546875000002, 44.68408203125],
              [80.48154296875003, 44.714648437499996],
              [80.45546875000002, 44.74609375],
              [80.36083984375, 44.7703125],
              [80.25507812500001, 44.80810546875],
              [80.12783203125002, 44.803759765624996],
              [79.99716796875003, 44.797216796875],
              [79.93212890625, 44.8251953125],
              [79.87529296874999, 44.86083984375],
              [79.871875, 44.8837890625],
              [79.9501953125, 44.944091796875],
              [80.05917968750003, 45.0064453125],
              [80.22822265625001, 45.033984375],
              [80.41494140625002, 45.075097656249994],
              [80.50917968750002, 45.10498046875],
              [80.634765625, 45.126513671874996],
              [80.78007812499999, 45.135546875],
              [80.85332031249999, 45.129296875],
              [81.04033203124999, 45.169140625],
              [81.33476562499999, 45.24619140625],
              [81.60205078125, 45.310839843749996],
              [81.69199218750003, 45.349365234375],
              [81.75888671875003, 45.310839843749996],
              [81.78964843750003, 45.226025390625],
              [81.86748046874999, 45.182080078125],
              [81.94492187500003, 45.16083984375],
              [81.9892578125, 45.161865234375],
              [82.12275390625001, 45.194873046874996],
              [82.2666015625, 45.219091796875],
              [82.32343750000001, 45.205859375],
              [82.39667968750001, 45.162451171875],
              [82.47871093750001, 45.123583984374996],
              [82.521484375, 45.12548828125],
              [82.55898437500002, 45.155419921874994],
              [82.59697265624999, 45.215966796874994],
              [82.62109375, 45.293115234374994],
              [82.62578124999999, 45.374414062499994],
              [82.61162109374999, 45.424267578125],
              [82.58251953125, 45.442578125],
              [82.45166015625, 45.471972656249996],
              [82.32666015625, 45.519921875],
              [82.31220703125001, 45.563720703125],
              [82.31523437499999, 45.594921875],
              [82.34814453125, 45.671533203124994],
              [82.4296875, 45.811914062499994],
              [82.51171875, 46.005810546875],
              [82.55507812500002, 46.15869140625],
              [82.69218749999999, 46.386669921875],
              [82.8, 46.624462890625],
              [82.97490234374999, 46.966015625],
              [83.00410156250001, 47.033496093749996],
              [83.02011718750003, 47.141455078125],
              [83.02949218750001, 47.1859375],
              [83.09033203125, 47.209375],
              [83.19306640625001, 47.186572265624996],
              [83.44355468750001, 47.108642578125],
              [83.63408203124999, 47.043212890625],
              [83.71396484375003, 47.021044921874996],
              [83.83261718750003, 46.997851562499996],
              [84.01601562500002, 46.970507812499996],
              [84.1220703125, 46.97861328125],
              [84.21513671874999, 46.9947265625],
              [84.3388671875, 46.996142578124996],
              [84.53242187500001, 46.97578125],
              [84.59228515625, 46.974951171875],
              [84.66660156250003, 46.97236328125],
              [84.71953124999999, 46.939355468749994],
              [84.74599609375002, 46.86435546875],
              [84.7861328125, 46.830712890624994],
              [84.85820312499999, 46.843164062499994],
              [85.01220703125, 46.909228515624996],
              [85.11054687500001, 46.961230468749996],
              [85.23349609375003, 47.036376953125],
              [85.35537109375002, 47.046728515625],
              [85.48476562500002, 47.063525390624996],
              [85.52968750000002, 47.10078125],
              [85.57724609375003, 47.1884765625],
              [85.65664062500002, 47.254638671875],
              [85.66982421875002, 47.33837890625],
              [85.64179687500001, 47.397412109375],
              [85.58662109375001, 47.49365234375],
              [85.58828125000002, 47.558496093749994],
              [85.56162109375003, 47.746484375],
              [85.52597656250003, 47.915625],
              [85.56230468749999, 48.05185546875],
              [85.62636718750002, 48.204003906249994],
              [85.65156250000001, 48.250537109374996],
              [85.69218749999999, 48.311816406249996],
              [85.74941406250002, 48.385058593749996],
              [85.82988281249999, 48.408056640625],
              [86.05615234375, 48.423730468749994],
              [86.265625, 48.454541015625],
              [86.37255859375, 48.486230468749994],
              [86.48330078125002, 48.50537109375],
              [86.54941406250003, 48.528613281249996],
              [86.66376953125001, 48.635546875],
              [86.71796875000001, 48.697167968749994],
              [86.7578125, 48.860742187499994],
              [86.72861328125003, 48.939355468749994],
              [86.753125, 49.008837890624996],
              [86.80830078125001, 49.049707031249994],
              [86.88593750000001, 49.090576171875],
              [86.93798828125, 49.097558593749994],
              [87.04853515625001, 49.109912109374996],
              [87.22998046875, 49.105859375],
              [87.32285156250003, 49.085791015625],
              [87.41669921875001, 49.076611328125],
              [87.47617187500003, 49.091455078124994],
              [87.51582031250001, 49.122412109375],
              [87.57656250000002, 49.132373046874996],
              [87.66835937500002, 49.147216796875],
              [87.7625, 49.165820312499996],
              [87.81425781249999, 49.1623046875],
              [87.8251953125, 49.11630859375],
              [87.81630859375002, 49.0802734375],
              [87.83466796875001, 49.03193359375],
              [87.87216796875003, 49.000146484374994],
              [87.85986328125, 48.96552734375],
              [87.80683593750001, 48.9455078125],
              [87.75468749999999, 48.9185546875],
              [87.7431640625, 48.881640625],
              [87.80917968750003, 48.835742187499996],
              [87.83183593749999, 48.791650390624994],
              [87.94218749999999, 48.765283203124994],
              [88.02792968750003, 48.735595703125],
              [88.06005859375, 48.707177734374994],
              [88.05019531250002, 48.675048828125],
              [88.01064453125002, 48.640429687499996],
              [87.97226562500003, 48.603320312499996],
              [87.96738281250003, 48.5810546875],
              [87.97968750000001, 48.555126953125],
              [88.06259765625003, 48.537841796875],
              [88.158203125, 48.509082031249996],
              [88.30996093750002, 48.472070312499994],
              [88.41396484375002, 48.40341796875],
              [88.51708984375, 48.38447265625],
              [88.56679687500002, 48.317431640624996],
              [88.57597656249999, 48.220166015625],
              [88.68183593750001, 48.170556640624994],
              [88.83828125000002, 48.101708984374994],
              [88.91777343749999, 48.089013671874994],
              [88.97109375000002, 48.049951171874994],
              [89.04765624999999, 48.0025390625],
              [89.115625, 47.987695312499994],
              [89.1962890625, 47.980908203125],
              [89.32988281249999, 48.024853515625],
              [89.47919921875001, 48.029052734375],
              [89.56093750000002, 48.003955078124996],
              [89.63847656249999, 47.909082031249994],
              [89.69316406249999, 47.879150390625],
              [89.7255859375, 47.852490234375],
              [89.778125, 47.827001953125],
              [89.83134765624999, 47.823291015624996],
              [89.91044921874999, 47.8443359375],
              [89.95869140625001, 47.886328125],
              [90.02792968750003, 47.877685546875],
              [90.05390625000001, 47.850488281249994],
              [90.06660156250001, 47.803564453125],
              [90.10322265625001, 47.745410156249996],
              [90.19101562500003, 47.702099609375],
              [90.31328124999999, 47.676171875],
              [90.33066406250003, 47.655175781249994],
              [90.34746093749999, 47.596972656249996],
              [90.38066406249999, 47.556640625],
              [90.42519531250002, 47.5041015625],
              [90.46748046875001, 47.408154296875],
              [90.47646484375002, 47.32880859375],
              [90.49619140625003, 47.28515625],
              [90.55292968750001, 47.214013671874994],
              [90.64335937499999, 47.10029296875],
              [90.71552734375001, 47.003857421875],
              [90.79902343750001, 46.98515625],
              [90.86992187499999, 46.954492187499994],
              [90.91054687500002, 46.883251953125],
              [90.98574218750002, 46.7490234375],
              [90.99785156249999, 46.661083984375],
              [91.00429687500002, 46.595751953124996],
              [91.02890625000003, 46.566064453124994],
              [91.03388671875001, 46.52900390625],
              [90.97148437499999, 46.387988281249996],
              [90.91826171874999, 46.324267578124996],
              [90.91152343750002, 46.270654296874994],
              [90.94755859374999, 46.177294921874996],
              [90.99677734375001, 46.10498046875],
              [91.00175781249999, 46.035791015624994],
              [90.95976562499999, 45.98505859375],
              [90.88710937500002, 45.921630859375],
              [90.85244140625002, 45.885400390624994],
              [90.7958984375, 45.853515625],
              [90.70966796875001, 45.730810546875],
              [90.67070312499999, 45.595166015625],
              [90.66181640625001, 45.525244140625],
              [90.69443359375003, 45.474658203124996],
              [90.74960937500003, 45.4189453125],
              [90.76318359375, 45.370654296874996],
              [90.85322265625001, 45.262890625],
              [90.87724609374999, 45.19609375],
              [90.91396484375002, 45.1939453125],
              [90.95361328125, 45.21591796875],
              [91.05, 45.217431640624994],
              [91.1376953125, 45.1939453125],
              [91.22177734375003, 45.14453125],
              [91.31210937500003, 45.118115234375],
              [91.44101562500003, 45.124755859375],
              [91.51005859374999, 45.0982421875],
              [91.584375, 45.076513671875],
              [91.73779296875, 45.0689453125],
              [91.85283203124999, 45.0693359375],
              [92.02978515625, 45.068505859374994],
              [92.17265624999999, 45.03525390625],
              [92.423828125, 45.008935546874994],
              [92.57890624999999, 45.010986328125],
              [92.78789062499999, 45.0357421875],
              [92.916015625, 45.020166015624994],
              [93.29433593750002, 44.983154296875],
              [93.51621093750003, 44.944482421874994],
              [93.65644531250001, 44.9009765625],
              [93.75527343750002, 44.831933593749994],
              [93.8681640625, 44.72421875],
              [93.95791015625002, 44.674951171874994],
              [94.19931640625003, 44.645166015624994],
              [94.36474609375, 44.519482421875],
              [94.49433593750001, 44.472509765625],
              [94.71201171875003, 44.350830078125],
              [94.86601562499999, 44.3033203125],
              [95.0498046875, 44.259423828124994],
              [95.35029296875001, 44.278076171875],
              [95.36679687500003, 44.261523437499996],
              [95.34365234375002, 44.19541015625],
              [95.32558593750002, 44.104882812499994],
              [95.32558593750002, 44.039355468749996],
              [95.3564453125, 44.00595703125],
              [95.47128906250003, 43.986181640625],
              [95.52558593750001, 43.953955078125],
              [95.56718749999999, 43.892236328124994],
              [95.59121093750002, 43.85361328125],
              [95.68730468749999, 43.6640625],
              [95.84199218750001, 43.383691406249994],
              [95.85957031250001, 43.2759765625],
              [95.9125, 43.206494140625],
              [96.08027343750001, 43.096142578125],
              [96.16845703125, 43.014501953125],
              [96.29951171875001, 42.9287109375],
              [96.34248046875001, 42.84931640625],
              [96.35234374999999, 42.74677734375],
              [96.38544921875001, 42.720361328124994],
              [96.62529296874999, 42.74384765625],
              [96.8330078125, 42.76025390625],
              [97.20566406250003, 42.789794921875],
              [97.71894531250001, 42.736279296875],
              [98.24824218750001, 42.684521484375],
              [98.71630859375, 42.638720703124996],
              [98.946875, 42.6162109375],
              [99.46787109375003, 42.568212890625],
              [99.75742187500003, 42.629443359374996],
              [99.98378906250002, 42.67734375],
              [100.08632812500002, 42.670751953125],
              [100.51904296875, 42.616796875],
              [100.77255859375003, 42.587792968749994],
              [101.09199218750001, 42.551318359374996],
              [101.31376953124999, 42.537890625],
              [101.49531250000001, 42.53876953125],
              [101.5791015625, 42.52353515625],
              [101.65996093749999, 42.500048828124996],
              [101.7138671875, 42.4658203125],
              [101.8798828125, 42.292333984375],
              [101.97294921874999, 42.215869140624996],
              [102.15664062500002, 42.158105468749994],
              [102.5751953125, 42.092089843749996],
              [102.80683593750001, 42.052001953125],
              [103.07285156250003, 42.00595703125],
              [103.24785156249999, 41.936572265624996],
              [103.44970703125, 41.855859375],
              [103.71113281250001, 41.751318359375],
              [103.99726562500001, 41.79697265625],
              [104.30517578125, 41.846142578125],
              [104.49824218750001, 41.877001953124996],
              [104.49824218750001, 41.65869140625],
              [104.77363281250001, 41.641162109374996],
              [104.8603515625, 41.64375],
              [104.98203125000003, 41.595507812499996],
              [105.05058593749999, 41.61591796875],
              [105.11542968750001, 41.66328125],
              [105.19707031249999, 41.738037109375],
              [105.31435546875002, 41.770898437499994],
              [105.51708984375, 41.854736328125],
              [105.56640625, 41.87509765625],
              [105.86757812500002, 41.993994140625],
              [106.31718749999999, 42.140576171875],
              [106.51875, 42.211572265624994],
              [106.5791015625, 42.22734375],
              [106.69316406249999, 42.263574218749994],
              [106.77001953125, 42.288720703124994],
              [106.90605468749999, 42.308886718749996],
              [107.09072265625002, 42.321533203125],
              [107.29238281250002, 42.349267578124994],
              [107.74873046875001, 42.4009765625],
              [107.80595703124999, 42.405859375],
              [108.06230468749999, 42.427197265625],
              [108.17119140624999, 42.447314453124996],
              [108.333984375, 42.436767578125],
              [108.54648437500003, 42.429296875],
              [108.68730468749999, 42.41611328125],
              [108.87451171875, 42.42646484375],
              [109.13164062499999, 42.440576171874994],
              [109.33984375, 42.438378906249994],
              [109.44316406249999, 42.455957031249994],
              [109.59550781249999, 42.510546875],
              [109.69804687499999, 42.55380859375],
              [109.85878906250002, 42.60625],
              [110.05800781250002, 42.660595703125],
              [110.196875, 42.710009765624996],
              [110.28886718749999, 42.742724609374996],
              [110.400390625, 42.773681640625],
              [110.42958984375002, 42.81357421875],
              [110.46171874999999, 42.844140625],
              [110.52089843750002, 42.895263671875],
              [110.62753906250003, 42.990527343749996],
              [110.70859375000003, 43.073876953124994],
              [110.74853515625, 43.110791015625],
              [110.83955078125001, 43.194091796875],
              [110.91328125000001, 43.256884765624996],
              [111.00722656250002, 43.34140625],
              [111.08652343750003, 43.36875],
              [111.18681640624999, 43.391992187499994],
              [111.45107421875002, 43.474902343749996],
              [111.50351562500003, 43.4927734375],
              [111.54736328125, 43.4962890625],
              [111.64082031250001, 43.56318359375],
              [111.7197265625, 43.621142578124996],
              [111.77109375000003, 43.664599609374996],
              [111.878125, 43.68017578125],
              [111.93320312500003, 43.71142578125],
              [111.94287109375, 43.75244140625],
              [111.93173828125003, 43.81494140625],
              [111.88027343750002, 43.87890625],
              [111.8369140625, 43.93466796875],
              [111.68378906250001, 44.04111328125],
              [111.60263671875003, 44.10712890625],
              [111.51972656250001, 44.19189453125],
              [111.48623046875002, 44.271630859374994],
              [111.42958984375002, 44.32236328125],
              [111.40224609375002, 44.36728515625],
              [111.41093749999999, 44.419189453125],
              [111.48945312500001, 44.511572265625],
              [111.51474609375003, 44.56982421875],
              [111.54746093750003, 44.672900390624996],
              [111.62128906250001, 44.8271484375],
              [111.68144531249999, 44.899169921875],
              [111.75107421875003, 44.96953125],
              [111.89804687500003, 45.0640625],
              [112.03261718750002, 45.081640625],
              [112.11289062500003, 45.062939453125],
              [112.29208984375003, 45.063037109374996],
              [112.41132812500001, 45.058203125],
              [112.49931640624999, 45.0109375],
              [112.59677734375003, 44.91767578125],
              [112.70673828125001, 44.883447265624994],
              [113.04941406250003, 44.810351562499996],
              [113.19609374999999, 44.794824218749994],
              [113.30097656250001, 44.791650390624994],
              [113.45566406250003, 44.767431640625],
              [113.50791015625003, 44.762353515624994],
              [113.58701171875003, 44.745703125],
              [113.65263671874999, 44.763476562499996],
              [113.75214843750001, 44.825927734375],
              [113.87705078125003, 44.89619140625],
              [113.93085937500001, 44.9123046875],
              [114.0302734375, 44.942578125],
              [114.08027343750001, 44.971142578125],
              [114.16738281250002, 45.049853515624996],
              [114.28105468749999, 45.110888671874996],
              [114.41914062500001, 45.202587890625],
              [114.4873046875, 45.271728515625],
              [114.50224609374999, 45.316308593749994],
              [114.51718750000003, 45.364599609375],
              [114.56015625000003, 45.389990234375],
              [114.64433593749999, 45.41328125],
              [114.73876953125, 45.41962890625],
              [114.91923828124999, 45.378271484375],
              [115.16259765625, 45.390234375],
              [115.21748046875001, 45.39619140625],
              [115.439453125, 45.419970703124996],
              [115.53945312500002, 45.439501953124996],
              [115.68105468750002, 45.458251953125],
              [115.78916015625003, 45.534814453124994],
              [115.93417968750003, 45.626171875],
              [116.03955078125, 45.676953125],
              [116.10986328125, 45.68671875],
              [116.19765625000002, 45.73935546875],
              [116.240625, 45.79599609375],
              [116.22910156250003, 45.845751953124996],
              [116.21298828125003, 45.8869140625],
              [116.26455078125002, 45.963037109374994],
              [116.35761718750001, 46.096582031249994],
              [116.44482421875, 46.1587890625],
              [116.51669921875003, 46.20908203125],
              [116.56259765625003, 46.289794921875],
              [116.61933593750001, 46.3130859375],
              [116.68886718750002, 46.32197265625],
              [116.78701171875002, 46.37666015625],
              [116.85908203125001, 46.387939453125],
              [116.97880859374999, 46.361767578125],
              [117.15595703125001, 46.355078125],
              [117.26904296875, 46.35224609375],
              [117.33339843750002, 46.36201171875],
              [117.35693359375, 46.39130859375],
              [117.35634765625002, 46.436669921874994],
              [117.39218750000003, 46.53759765625],
              [117.40556640624999, 46.5708984375],
              [117.43808593750003, 46.586230468749996],
              [117.546875, 46.58828125],
              [117.62050781250002, 46.552001953125],
              [117.67109375000001, 46.5220703125],
              [117.7412109375, 46.5181640625],
              [117.8134765625, 46.5376953125],
              [117.91044921874999, 46.6193359375],
              [118.0712890625, 46.6666015625],
              [118.15683593750003, 46.678564453125],
              [118.30869140625003, 46.717041015625],
              [118.40439453125003, 46.703173828124996],
              [118.58046875000002, 46.69189453125],
              [118.64873046874999, 46.70166015625],
              [118.72294921874999, 46.69189453125],
              [118.79033203124999, 46.7470703125],
              [118.84394531250001, 46.760205078125],
              [118.95712890625003, 46.73486328125],
              [119.02851562500001, 46.6921875],
              [119.162109375, 46.638671875],
              [119.33183593749999, 46.613818359374996],
              [119.47402343750002, 46.62666015625],
              [119.62021484375003, 46.603955078125],
              [119.70664062500003, 46.606005859374996],
              [119.74746093750002, 46.627197265625],
              [119.8671875, 46.672167968749996],
              [119.89589843750002, 46.732861328125],
              [119.88417968750002, 46.791455078125],
              [119.89785156250002, 46.8578125],
              [119.86269531250002, 46.906591796875],
              [119.78847656250002, 46.978808593749996],
              [119.75986328125003, 47.027001953124994],
              [119.75722656250002, 47.0900390625],
              [119.71113281250001, 47.15],
              [119.60019531250003, 47.222460937499996],
              [119.52695312500003, 47.255908203124996],
              [119.37666015625001, 47.380859375],
              [119.32597656249999, 47.41015625],
              [119.30859375, 47.430712890624996],
              [119.29082031249999, 47.47265625],
              [119.23525390625002, 47.492578125],
              [119.16240234374999, 47.525195312499996],
              [119.12294921875002, 47.558496093749994],
              [119.09726562500003, 47.616259765624996],
              [119.08193359375002, 47.654150390625],
              [119.017578125, 47.685351562499996],
              [118.953125, 47.702929687499996],
              [118.88027343750002, 47.72509765625],
              [118.75996093750001, 47.757617187499996],
              [118.69052734375003, 47.822265625],
              [118.56777343750002, 47.943261718749994],
              [118.49843750000002, 47.983984375],
              [118.23964843750002, 47.99951171875],
              [118.14707031250003, 48.02890625],
              [118.04189453125002, 48.018945312499994],
              [117.97919921875001, 47.999609375],
              [117.84042968750003, 47.999853515625],
              [117.76835937499999, 47.987890625],
              [117.67666015625002, 47.90830078125],
              [117.55537109375001, 47.8046875],
              [117.455078125, 47.741357421874994],
              [117.38398437500001, 47.675732421875],
              [117.35078125000001, 47.652197265625],
              [117.28593749999999, 47.666357421875],
              [117.19707031249999, 47.740283203124996],
              [117.06972656250002, 47.806396484375],
              [116.95166015625, 47.836572265624994],
              [116.90117187499999, 47.853076171874996],
              [116.76054687499999, 47.869775390624994],
              [116.65195312500003, 47.864501953125],
              [116.51347656249999, 47.83955078125],
              [116.37822265624999, 47.844042968749996],
              [116.31718749999999, 47.85986328125],
              [116.23115234375001, 47.858203125],
              [116.07480468750003, 47.78955078125],
              [115.99384765625001, 47.711328125],
              [115.89824218749999, 47.686914062499994],
              [115.81171875000001, 47.738232421875],
              [115.71171874999999, 47.79892578125],
              [115.61640625000001, 47.874804687499996],
              [115.5576171875, 47.94501953125],
              [115.52509765625001, 48.130859375],
              [115.63945312499999, 48.18623046875],
              [115.78554687500002, 48.2482421875],
              [115.79658203125001, 48.346337890624994],
              [115.79169921875001, 48.455712890624994],
              [115.82050781250001, 48.57724609375],
              [115.95380859375001, 48.689355468749994],
              [116.02548828125003, 48.782275390624996],
              [116.034375, 48.8400390625],
              [116.09824218750003, 48.9361328125],
              [116.15966796875, 49.037451171875],
              [116.24335937500001, 49.170361328125],
              [116.40214843749999, 49.406201171875],
              [116.58974609375002, 49.684814453125],
              [116.68330078125001, 49.823779296874996],
              [116.88896484374999, 49.73779296875],
              [117.02167968750001, 49.69296875],
              [117.24560546875, 49.624853515625],
              [117.47714843750003, 49.609423828124996],
              [117.69843750000001, 49.53583984375],
              [117.81259765625003, 49.513525390625],
              [117.87343750000002, 49.513476562499996],
              [118.18662109375003, 49.692773437499994],
              [118.45156250000002, 49.844482421875],
              [118.75595703125003, 49.962841796875],
              [118.9794921875, 49.978857421875],
              [119.1474609375, 50.01337890625],
              [119.25986328125003, 50.06640625],
              [119.32607421875002, 50.154931640624994],
              [119.34628906250003, 50.278955078124994],
              [119.30156249999999, 50.35390625],
              [119.19189453125, 50.379833984375],
              [119.16367187500003, 50.406005859375],
              [119.21669921875002, 50.432519531249994],
              [119.255859375, 50.484179687499996],
              [119.28066406250002, 50.560986328125],
              [119.34404296874999, 50.63388671875],
              [119.44570312500002, 50.70283203125],
              [119.50175781249999, 50.779248046875],
              [119.51230468750003, 50.863134765625],
              [119.57343750000001, 50.94677734375],
              [119.68496093750002, 51.030126953125],
              [119.74599609375002, 51.107714843749996],
              [119.75664062499999, 51.179492187499996],
              [119.81318359375001, 51.267041015625],
              [119.96699218750001, 51.422119140625],
              [120.06689453125, 51.60068359375],
              [120.23701171875001, 51.722998046875],
              [120.51054687499999, 51.84853515625],
              [120.68144531249999, 51.973046875],
              [120.74980468749999, 52.096533203125],
              [120.74453125000002, 52.20546875],
              [120.66542968750002, 52.29990234375],
              [120.650390625, 52.3958984375],
              [120.69921875, 52.493603515625],
              [120.65615234375002, 52.566650390625],
              [120.52109375000003, 52.615039062499996],
              [120.36005859375001, 52.627001953124996],
              [120.17275390625002, 52.602490234375],
              [120.06757812500001, 52.63291015625],
              [120.04433593750002, 52.718212890625],
              [120.09453124999999, 52.78720703125],
              [120.21816406250002, 52.839892578124996],
              [120.42128906250002, 52.968066406249996],
              [120.7041015625, 53.171826171875],
              [120.98544921875003, 53.2845703125],
              [121.40546875000001, 53.317041015625],
              [121.74394531249999, 53.38359375],
              [122.0888671875, 53.451464843749996],
              [122.33779296875002, 53.485009765625],
              [122.38017578124999, 53.4625],
              [122.51582031250001, 53.456982421875],
              [122.74472656250003, 53.468505859375],
              [122.95761718750003, 53.497705078125],
              [123.15410156249999, 53.544580078125],
              [123.3095703125, 53.555615234375],
              [123.42402343750001, 53.53076171875],
              [123.48945312500001, 53.529443359375],
              [123.53476562500003, 53.52646484375],
              [123.55976562500001, 53.526660156249996],
              [123.60781250000002, 53.546533203125],
              [123.74091796875001, 53.510986328125],
              [123.99472656250003, 53.405615234375],
              [124.154296875, 53.358691406249996],
              [124.21992187500001, 53.3701171875],
              [124.29140625000002, 53.340869140624996],
              [124.369140625, 53.270947265625],
              [124.46591796875003, 53.229638671875],
              [124.63984375000001, 53.21064453125],
              [124.81230468749999, 53.133837890624996],
              [124.88212890624999, 53.129736328125],
              [124.90664062500002, 53.17265625],
              [124.97089843750001, 53.197314453124996],
              [125.075, 53.203662109374996],
              [125.2255859375, 53.165820312499996],
              [125.42246093750003, 53.083740234375],
              [125.54599609375003, 53.047607421875],
              [125.59599609374999, 53.057470703125],
              [125.64902343750003, 53.042285156249996],
              [125.69169921874999, 53.003710937499996],
              [125.6953125, 52.956298828125],
              [125.68076171875003, 52.930810546875],
              [125.728125, 52.89072265625],
              [125.78281250000003, 52.89072265625],
              [125.871875, 52.871533203125],
              [125.94160156250001, 52.80068359375],
              [126.00429687500002, 52.767871093749996],
              [126.04814453124999, 52.739453125],
              [126.05605468750002, 52.715869140624996],
              [126.06015625000003, 52.6919921875],
              [126.04707031250001, 52.673486328125],
              [126.02324218749999, 52.643017578125],
              [126.01601562500002, 52.610205078125],
              [126.0458984375, 52.57333984375],
              [126.15664062500002, 52.546630859375],
              [126.19443359375003, 52.519140625],
              [126.20292968749999, 52.483837890625],
              [126.23759765624999, 52.44482421875],
              [126.31289062500002, 52.399755859375],
              [126.34169921875002, 52.36201171875],
              [126.32421875, 52.331640625],
              [126.34628906250003, 52.30625],
              [126.38349609375001, 52.2865234375],
              [126.39150390625002, 52.214501953125],
              [126.39482421874999, 52.172998046875],
              [126.45556640625, 52.12646484375],
              [126.46806640624999, 52.031298828124996],
              [126.51054687499999, 51.925830078124996],
              [126.65371093750002, 51.781298828124996],
              [126.70078125000003, 51.70302734375],
              [126.68867187500001, 51.609912109374996],
              [126.70917968750001, 51.566308593749994],
              [126.77451171875003, 51.545068359374994],
              [126.80546874999999, 51.505664062499996],
              [126.8017578125, 51.448046875],
              [126.82734375000001, 51.412255859374994],
              [126.84775390625003, 51.374169921874994],
              [126.83378906249999, 51.314892578125],
              [126.85439453125002, 51.261376953124994],
              [126.8876953125, 51.230126953124994],
              [126.91152343750002, 51.172314453125],
              [126.9248046875, 51.100146484374996],
              [127.02041015625002, 50.985888671874996],
              [127.1982421875, 50.829443359375],
              [127.30703125000002, 50.707958984375],
              [127.346875, 50.621337890625],
              [127.34716796875, 50.550097656249996],
              [127.30820312500003, 50.494189453124996],
              [127.30605468750002, 50.453515625],
              [127.3408203125, 50.428076171875],
              [127.35117187500003, 50.393603515624996],
              [127.33720703125005, 50.350146484374996],
              [127.39531250000005, 50.298583984375],
              [127.59023437500002, 50.208984375],
              [127.51230468750003, 50.071679687499994],
              [127.49179687500003, 49.975048828125],
              [127.50244140625, 49.8734375],
              [127.55078125, 49.801806640624996],
              [127.63671875, 49.760205078125],
              [127.69013671875001, 49.716748046875],
              [127.71113281250001, 49.671533203124994],
              [127.81425781250005, 49.622119140624996],
              [127.99960937500003, 49.568603515625],
              [128.23710937500005, 49.55927734375],
              [128.52675781250002, 49.59423828125],
              [128.70400390625002, 49.600146484374996],
              [128.76904296875, 49.576953125],
              [128.791015625, 49.541845703125],
              [128.77031250000005, 49.4947265625],
              [128.8193359375, 49.463769531249994],
              [128.93828125000005, 49.44892578125],
              [129.02031250000005, 49.419238281249996],
              [129.06513671875, 49.374658203124994],
              [129.1201171875, 49.362060546875],
              [129.18515625000003, 49.381396484374996],
              [129.24843750000002, 49.378662109375],
              [129.30986328125005, 49.353857421875],
              [129.35009765625, 49.362353515624996],
              [129.38466796875002, 49.389453125],
              [129.44072265625005, 49.389453125],
              [129.49814453125003, 49.388818359374994],
              [129.53369140625, 49.3234375],
              [129.59140625000003, 49.286669921874996],
              [129.67109375, 49.278515625],
              [129.79257812500003, 49.198876953124994],
              [130.037109375, 48.972265625],
              [130.19599609375, 48.891650390624996],
              [130.35527343750005, 48.866357421874994],
              [130.553125, 48.861181640625],
              [130.6171875, 48.773193359375],
              [130.565625, 48.680126953125],
              [130.55214843750002, 48.602490234375],
              [130.59726562500003, 48.574658203125],
              [130.6591796875, 48.4833984375],
              [130.746875, 48.43037109375],
              [130.76347656250005, 48.388427734375],
              [130.80429687500003, 48.34150390625],
              [130.78720703125003, 48.25458984375],
              [130.712109375, 48.12763671875],
              [130.7326171875, 48.01923828125],
              [130.8486328125, 47.929443359375],
              [130.91542968750002, 47.842919921874994],
              [130.9328125, 47.759814453124996],
              [130.9619140625, 47.709326171875],
              [131.00273437500005, 47.691455078124996],
              [131.121875, 47.69765625],
              [131.3193359375, 47.727832031249996],
              [131.46425781250002, 47.722607421875],
              [131.55673828125003, 47.68203125],
              [131.78525390625003, 47.680517578125],
              [132.14980468750002, 47.71796875],
              [132.38017578125005, 47.7294921875],
              [132.47626953125, 47.714990234374994],
              [132.56191406250002, 47.768505859375],
              [132.6369140625, 47.890087890625],
              [132.7072265625, 47.947265625],
              [132.77285156250002, 47.940087890624994],
              [132.8771484375, 47.9791015625],
              [133.02011718750003, 48.064404296875],
              [133.14404296875, 48.1056640625],
              [133.30117187500002, 48.101513671875],
              [133.46835937500003, 48.09716796875],
              [133.5732421875, 48.1330078125],
              [133.67177734375002, 48.20771484375],
              [133.84218750000002, 48.273730468749996],
              [134.20585937500005, 48.359912109374996],
              [134.29335937500002, 48.3734375],
              [134.3349609375, 48.36884765625],
              [134.45615234375003, 48.355322265625],
              [134.56357421875003, 48.321728515625],
              [134.665234375, 48.25390625],
              [134.680859375, 48.21044921875],
              [134.66933593750002, 48.1533203125],
              [134.64726562500005, 48.120166015624996],
              [134.60537109375002, 48.082910156249994],
              [134.56601562500003, 48.022509765624996],
              [134.59130859375, 47.9751953125],
              [134.65029296875002, 47.874267578125],
              [134.69863281250002, 47.801416015624994],
              [134.75234375000002, 47.7154296875],
              [134.728125, 47.684472656249994],
              [134.69580078125, 47.624853515625],
              [134.59619140625, 47.523876953125],
              [134.54189453125002, 47.48515625],
              [134.48349609375003, 47.44736328125],
              [134.38251953125, 47.438232421875],
              [134.33945312500003, 47.429492187499996],
              [134.29082031250005, 47.41357421875],
              [134.26005859375005, 47.377734375],
              [134.22519531250003, 47.35263671875],
              [134.16767578125, 47.302197265625],
              [134.16298828125002, 47.258740234375],
              [134.18925781250005, 47.194238281249994],
              [134.2021484375, 47.128076171874994],
              [134.1369140625, 47.068994140624994],
              [134.08642578125, 46.978125],
              [134.07138671875003, 46.95078125],
              [134.04599609375003, 46.881982421874994],
              [134.03857421875, 46.858154296875],
              [134.02265625, 46.713183593749996],
              [133.95751953125, 46.6142578125],
              [133.86660156250002, 46.49912109375],
              [133.88671875, 46.430566406249994],
              [133.90273437500002, 46.366943359375],
              [133.88027343750002, 46.33603515625],
              [133.87480468750005, 46.30908203125],
              [133.861328125, 46.24775390625],
              [133.83281250000005, 46.224267578124994],
              [133.7501953125, 46.1859375],
              [133.70068359375, 46.13974609375],
              [133.7111328125, 46.069628906249996],
              [133.6857421875, 46.008935546874994],
              [133.64785156250002, 45.955224609374994],
              [133.60800781250003, 45.9203125],
              [133.55117187500002, 45.897802734375],
              [133.51308593750002, 45.878808593749994],
              [133.48466796875005, 45.810449218749994],
              [133.47578125, 45.757666015625],
              [133.44912109375002, 45.705078125],
              [133.465625, 45.651220703125],
              [133.43642578125002, 45.6046875],
              [133.35546875, 45.572216796875],
              [133.3095703125, 45.553076171875],
              [133.26699218750002, 45.545263671875],
              [133.18603515625, 45.49482421875],
              [133.11337890625003, 45.321435546874994],
              [133.096875, 45.220458984375],
              [133.1134765625, 45.130712890625],
              [133.01171875, 45.074560546875],
              [132.93603515625, 45.029931640624994],
              [132.88876953125003, 45.046044921874994],
              [132.83867187500005, 45.0611328125],
              [132.72314453125, 45.08056640625],
              [132.665625, 45.093701171875],
              [132.5490234375, 45.122802734375],
              [132.36298828125, 45.159960937499996],
              [132.18134765625, 45.203271484374994],
              [132.0673828125, 45.225976562499994],
              [131.9775390625, 45.243994140625],
              [131.90927734375003, 45.273730468749996],
              [131.85185546875005, 45.32685546875],
              [131.794921875, 45.3052734375],
              [131.74208984375002, 45.242626953125],
              [131.65400390625, 45.205371093749996],
              [131.61396484375, 45.136572265625],
              [131.57871093750003, 45.083642578124994],
              [131.4875, 45.013134765625],
              [131.446875, 44.984033203124994],
              [131.26826171875, 44.9361328125],
              [131.22792968750002, 44.920166015625],
              [131.08232421875005, 44.910009765625],
              [131.03300781250005, 44.8888671875],
              [130.981640625, 44.8443359375],
              [130.9677734375, 44.799951171874994],
              [131.00390625, 44.753222656249996],
              [131.06064453125003, 44.65966796875],
              [131.0869140625, 44.595654296875],
              [131.12578125000005, 44.469189453125],
              [131.25527343750002, 44.071582031249996],
              [131.21328125000002, 44.0029296875],
              [131.17421875000002, 43.704736328124994],
              [131.18359375, 43.65087890625],
              [131.18007812500002, 43.56708984375],
              [131.18242187500005, 43.50556640625],
              [131.2091796875, 43.4904296875],
              [131.24394531250005, 43.469042968749996],
              [131.26181640625003, 43.433056640625],
              [131.25732421875, 43.378076171874994],
              [131.23935546875003, 43.337646484375],
              [131.2119140625, 43.257763671875],
              [131.17558593750005, 43.1421875],
              [131.13554687500005, 43.097607421875],
              [131.10898437500003, 43.062451171875],
              [131.0861328125, 43.0380859375],
              [131.08349609375, 42.956298828125],
              [131.0685546875, 42.902246093749994],
              [131.00556640625, 42.883105468749996],
              [130.94287109375, 42.8517578125],
              [130.86855468750002, 42.863330078124996],
              [130.80332031250003, 42.856835937499994],
              [130.72246093750005, 42.835839843749994],
              [130.57724609375003, 42.81162109375],
              [130.49296875000005, 42.779101562499996],
              [130.45273437500003, 42.755419921874996],
              [130.4248046875, 42.72705078125],
              [130.419921875, 42.699853515624994],
              [130.43925781250005, 42.685546875],
              [130.52060546875003, 42.67431640625],
              [130.57656250000002, 42.6232421875],
              [130.58447265625, 42.567333984375],
              [130.52695312500003, 42.535400390625],
              [130.4982421875, 42.5705078125],
              [130.45029296875003, 42.581689453124994],
              [130.36074218750002, 42.630859375],
              [130.29560546875, 42.684960937499994],
              [130.24667968750003, 42.74482421875],
              [130.24882812500005, 42.872607421874996],
              [130.24033203125003, 42.891796875],
              [130.15126953125002, 42.91796875],
              [130.12480468750005, 42.956005859375],
              [130.08261718750003, 42.974169921874996],
              [130.02226562500005, 42.96259765625],
              [129.97695312500002, 42.974853515625],
              [129.94121093750005, 42.995654296874996],
              [129.89824218750005, 42.99814453125],
              [129.86103515625, 42.965087890625],
              [129.84150390625, 42.89423828125],
              [129.77919921875002, 42.7765625],
              [129.7734375, 42.70546875],
              [129.74648437500002, 42.603808593749996],
              [129.7197265625, 42.475],
              [129.69785156250003, 42.448144531249994],
              [129.6279296875, 42.444287109375],
              [129.60390625000002, 42.435888671875],
              [129.567578125, 42.39208984375],
              [129.52373046875005, 42.384667968749994],
              [129.48486328125, 42.410302734374994],
              [129.42363281250005, 42.435888671875],
              [129.36582031250003, 42.439208984375],
              [129.313671875, 42.41357421875],
              [129.25253906250003, 42.357861328125],
              [129.2177734375, 42.3126953125],
              [129.20537109375005, 42.270556640624996],
              [129.1955078125, 42.21845703125],
              [129.13369140625002, 42.168505859374996],
              [129.07724609375003, 42.142382812499996],
              [128.96064453125, 42.068798828125],
              [128.92343750000003, 42.038232421874994],
              [128.83984375, 42.037841796875],
              [128.7490234375, 42.040673828124994],
              [128.62675781250005, 42.020849609375],
              [128.42724609375, 42.0107421875],
              [128.3078125, 42.025634765625],
              [128.16015625, 42.011621093749994],
              [128.04521484375005, 41.9875],
              [128.02871093750002, 41.951611328125],
              [128.03291015625, 41.898486328124996],
              [128.05605468750002, 41.86376953125],
              [128.0841796875, 41.840576171875],
              [128.13193359375003, 41.769140625],
              [128.18173828125003, 41.700048828125],
              [128.2578125, 41.65537109375],
              [128.2892578125, 41.607421875],
              [128.29091796875002, 41.562792968749996],
              [128.2548828125, 41.50654296875],
              [128.20029296875003, 41.433007812499994],
              [128.1494140625, 41.387744140624996],
              [128.11123046875002, 41.3892578125],
              [128.052734375, 41.415625],
              [128.01308593750002, 41.448681640625],
              [127.91865234375001, 41.4611328125],
              [127.68769531250001, 41.439990234374996],
              [127.57216796875002, 41.454736328124994],
              [127.51699218750002, 41.481738281249996],
              [127.42031250000002, 41.483789062499994],
              [127.27080078125005, 41.519824218749996],
              [127.1796875, 41.53134765625],
              [127.13671875, 41.554541015625],
              [127.12841796875, 41.607421875],
              [127.08535156250002, 41.643798828125],
              [127.06132812499999, 41.687353515625],
              [127.00693359375003, 41.742041015625],
              [126.95478515625001, 41.769482421875],
              [126.90351562500001, 41.781054687499996],
              [126.84726562500003, 41.747998046875],
              [126.78769531250003, 41.718212890625],
              [126.74306640625002, 41.724853515625],
              [126.72158203125002, 41.716552734375],
              [126.69697265625001, 41.69189453125],
              [126.60126953125001, 41.640966796875],
              [126.57832031250001, 41.5943359375],
              [126.54013671875003, 41.495556640625],
              [126.51357421875002, 41.393994140625],
              [126.49042968750001, 41.358056640624994],
              [126.45146484374999, 41.351855468749996],
              [126.41181640625001, 41.321337890624996],
              [126.32871093750003, 41.22568359375],
              [126.25361328125001, 41.13779296875],
              [126.14453125, 41.078271484374994],
              [126.09316406250002, 41.023681640625],
              [126.06679687500002, 40.974072265625],
              [125.98906249999999, 40.904638671875],
              [125.87490234375002, 40.892236328124994],
              [125.78398437499999, 40.872021484375],
              [125.72832031249999, 40.86669921875],
              [125.68828124999999, 40.838671875],
              [125.6591796875, 40.7958984375],
              [125.64511718750003, 40.778955078124994],
              [125.59384765625003, 40.778955078124994],
              [125.54257812500003, 40.742578125],
              [125.41689453125002, 40.659912109375],
              [125.314453125, 40.64462890625],
              [125.18593750000002, 40.589404296874996],
              [125.07294921875001, 40.5474609375],
              [125.02597656250003, 40.523876953125],
              [125.01337890625001, 40.497851562499996],
              [124.996875, 40.464746093749994],
              [124.94228515625002, 40.458154296874994],
              [124.88935546875001, 40.459814453125],
              [124.77197265625, 40.383740234375],
              [124.71240234375, 40.319238281249994],
              [124.48105468750003, 40.181640625],
              [124.38662109375002, 40.104248046875],
              [124.36210937499999, 40.004052734374994],
              [124.35, 40.011572265625],
              [124.26748046875002, 39.924169921875],
              [124.10576171874999, 39.841015625],
              [123.76015625000002, 39.822412109374994],
              [123.65087890625, 39.881591796875],
              [123.61123046875002, 39.8408203125],
              [123.58066406250003, 39.7861328125],
              [123.49003906249999, 39.767871093749996],
              [123.34814453125, 39.762939453125],
              [123.26894531250002, 39.726904296875],
              [123.2265625, 39.68662109375],
              [123.0322265625, 39.67353515625],
              [122.9609375, 39.619921875],
              [122.84003906250001, 39.600830078125],
              [122.33486328125002, 39.366113281249994],
              [122.225, 39.267333984375],
              [122.12089843749999, 39.151904296874996],
              [122.04765624999999, 39.093798828124996],
              [121.98232421875002, 39.053173828125],
              [121.92265624999999, 39.036523437499994],
              [121.86435546875003, 38.996484375],
              [121.80517578125, 38.99140625],
              [121.74482421875001, 39.009667968749994],
              [121.67724609375, 39.00341796875],
              [121.6328125, 38.954833984375],
              [121.67041015625, 38.891796875],
              [121.64990234375, 38.865087890625],
              [121.51718750000003, 38.83076171875],
              [121.32011718749999, 38.808203125],
              [121.236328125, 38.766943359375],
              [121.20742187500002, 38.743505859375],
              [121.16357421875, 38.731640625],
              [121.12167968750003, 38.81328125],
              [121.10673828124999, 38.920800781249994],
              [121.18828124999999, 38.946679687499994],
              [121.26328125000003, 38.960253906249996],
              [121.67988281250001, 39.108691406249996],
              [121.62763671875001, 39.220166015625],
              [121.66455078125, 39.26875],
              [121.7578125, 39.347558593749994],
              [121.81845703125003, 39.386523437499996],
              [121.78544921874999, 39.400830078125],
              [121.5125, 39.374853515625],
              [121.35566406250001, 39.376806640625],
              [121.27548828125003, 39.384765625],
              [121.2998046875, 39.452197265624996],
              [121.28632812500001, 39.519433593749994],
              [121.26748046875002, 39.544677734375],
              [121.40644531250001, 39.621240234374994],
              [121.46953124999999, 39.64013671875],
              [121.517578125, 39.638964843749996],
              [121.51425781250003, 39.68525390625],
              [121.47421875000003, 39.7548828125],
              [121.51738281249999, 39.84482421875],
              [121.80097656250001, 39.950537109375],
              [121.86894531249999, 40.04638671875],
              [121.98281250000002, 40.13583984375],
              [122.19091796875, 40.358251953125],
              [122.20332031250001, 40.396044921874996],
              [122.26386718750001, 40.5001953125],
              [122.275, 40.541845703125],
              [122.1787109375, 40.602734375],
              [122.14042968749999, 40.68818359375],
              [121.85878906250002, 40.842089843749996],
              [121.83486328125002, 40.974267578124994],
              [121.80859375, 40.968505859375],
              [121.765625, 40.875878906249994],
              [121.72929687499999, 40.846142578125],
              [121.59892578124999, 40.843408203124994],
              [121.537109375, 40.87841796875],
              [121.17451171875001, 40.901269531249994],
              [121.0859375, 40.841601562499996],
              [121.0029296875, 40.74912109375],
              [120.92226562500002, 40.68310546875],
              [120.84130859375, 40.64921875],
              [120.77070312500001, 40.5890625],
              [120.47910156250003, 40.23095703125],
              [120.36894531249999, 40.203857421875],
              [119.85039062499999, 39.987451171874994],
              [119.59111328124999, 39.902636718749996],
              [119.39111328125, 39.752490234374996],
              [119.32236328125003, 39.66162109375],
              [119.26132812500003, 39.560888671875],
              [119.224609375, 39.408056640625],
              [119.04013671875003, 39.22236328125],
              [118.97695312500002, 39.182568359375],
              [118.91230468750001, 39.16640625],
              [118.82646484374999, 39.172119140625],
              [118.75244140625, 39.160498046875],
              [118.62636718750002, 39.17685546875],
              [118.47197265624999, 39.118017578125],
              [118.2978515625, 39.06708984375],
              [118.14785156250002, 39.195068359375],
              [118.04091796875002, 39.2267578125],
              [117.86572265625, 39.191259765625],
              [117.78466796875, 39.13447265625],
              [117.61669921875, 38.852880859375],
              [117.55380859375003, 38.691455078124996],
              [117.55781250000001, 38.625146484374994],
              [117.65605468749999, 38.42421875],
              [117.76669921875003, 38.311669921874994],
              [118.01494140624999, 38.183398437499996],
              [118.54326171874999, 38.094921875],
              [118.66708984375003, 38.126367187499994],
              [118.8, 38.12666015625],
              [118.94003906250003, 38.042773437499996],
              [119.02753906250001, 37.90400390625],
              [119.03564453125, 37.8091796875],
              [119.03847656250002, 37.776513671874994],
              [119.0703125, 37.748583984374996],
              [119.08916015624999, 37.700732421874996],
              [119.03349609374999, 37.661035156249994],
              [118.99082031250003, 37.641357421875],
              [118.95488281249999, 37.494091796875],
              [118.95263671875, 37.33115234375],
              [118.99814453125003, 37.277099609375],
              [119.11181640625, 37.201171875],
              [119.28740234374999, 37.13828125],
              [119.44990234375001, 37.124755859375],
              [119.76054687499999, 37.155078125],
              [119.8875, 37.253369140625],
              [119.87998046875003, 37.295800781249994],
              [119.88291015625003, 37.350830078125],
              [120.15585937500003, 37.495019531249994],
              [120.3115234375, 37.622705078124994],
              [120.287109375, 37.656494140625],
              [120.25722656250002, 37.679003906249996],
              [120.28466796875, 37.69208984375],
              [120.3701171875, 37.701025390625],
              [120.75, 37.833935546875],
              [121.04902343750001, 37.7251953125],
              [121.21953124999999, 37.600146484374996],
              [121.38808593750002, 37.578955078125],
              [121.50527343750002, 37.515039062499994],
              [121.64023437500003, 37.460351562499994],
              [121.81640625, 37.456640625],
              [121.96484375, 37.4453125],
              [122.01015625000002, 37.495751953124994],
              [122.056640625, 37.52890625],
              [122.10957031250001, 37.522314453125],
              [122.16914062500001, 37.45615234375],
              [122.33769531249999, 37.4052734375],
              [122.49326171875003, 37.407958984375],
              [122.60234374999999, 37.426416015624994],
              [122.6669921875, 37.40283203125],
              [122.57333984375003, 37.317919921874996],
              [122.58730468750002, 37.181103515625],
              [122.51552734375002, 37.137841796874994],
              [122.44667968750002, 37.068115234375],
              [122.48740234375003, 37.022265625],
              [122.5234375, 37.00263671875],
              [122.51972656250001, 36.946826171874996],
              [122.45703125, 36.91513671875],
              [122.34091796875003, 36.8322265625],
              [122.27421874999999, 36.833837890625],
              [122.24228515625003, 36.849853515625],
              [122.2197265625, 36.879541015624994],
              [122.20322265625003, 36.927197265625],
              [122.16240234374999, 36.958642578124994],
              [122.04951171875001, 36.970751953124996],
              [121.93271484375003, 36.95947265625],
              [121.66962890625001, 36.836376953125],
              [121.4130859375, 36.73837890625],
              [121.14404296875, 36.660449218749996],
              [121.05380859375003, 36.611376953124996],
              [120.98994140625001, 36.597949218749996],
              [120.87851562500003, 36.63515625],
              [120.81083984374999, 36.6328125],
              [120.79667968749999, 36.607226562499996],
              [120.88261718749999, 36.538916015625],
              [120.90498046875001, 36.485302734375],
              [120.89580078124999, 36.444140625],
              [120.84707031250002, 36.426074218749996],
              [120.77617187499999, 36.456298828125],
              [120.71152343750003, 36.41328125],
              [120.68222656250003, 36.340722656249994],
              [120.68095703124999, 36.168359375],
              [120.63789062500001, 36.129931640624996],
              [120.51933593749999, 36.108691406249996],
              [120.39306640625, 36.053857421874994],
              [120.34824218750003, 36.07919921875],
              [120.33027343750001, 36.110107421875],
              [120.34345703125001, 36.189453125],
              [120.32773437500003, 36.228173828124994],
              [120.27011718750003, 36.226171875],
              [120.18330078125001, 36.202441406249996],
              [120.11699218749999, 36.150292968749994],
              [120.09414062500002, 36.118896484375],
              [120.18144531249999, 36.017480468749994],
              [120.26474609375003, 36.007226562499994],
              [120.28476562500003, 35.984423828124996],
              [120.21904296874999, 35.934912109375],
              [120.0546875, 35.861132812499996],
              [120.02744140625003, 35.799365234374996],
              [119.97871093750001, 35.740234375],
              [119.91171875000003, 35.693212890625],
              [119.8662109375, 35.64365234375],
              [119.810546875, 35.617724609374996],
              [119.7197265625, 35.588720703125],
              [119.6083984375, 35.469873046874994],
              [119.52646484375003, 35.35859375],
              [119.4296875, 35.301416015624994],
              [119.35283203124999, 35.113818359374996],
              [119.2158203125, 35.011767578124996],
              [119.16533203124999, 34.848828125],
              [119.20097656249999, 34.7484375],
              [119.35136718749999, 34.749414062499994],
              [119.4267578125, 34.714160156249996],
              [119.58291015625002, 34.5822265625],
              [119.76972656250001, 34.49619140625],
              [119.96367187499999, 34.447802734374996],
              [120.20146484374999, 34.32568359375],
              [120.26669921875003, 34.2740234375],
              [120.32265625000002, 34.168994140624996],
              [120.42568359375002, 33.86630859375],
              [120.49980468749999, 33.716455078124994],
              [120.50478515625002, 33.63818359375],
              [120.615625, 33.490527343749996],
              [120.73447265625003, 33.236621093749996],
              [120.87109375, 33.016503906249994],
              [120.89736328125002, 32.843212890625],
              [120.85302734375, 32.764111328125],
              [120.85322265625001, 32.661376953125],
              [120.98994140625001, 32.567041015624994],
              [121.29335937500002, 32.457324218749996],
              [121.34169921875002, 32.425048828125],
              [121.40097656250003, 32.371923828125],
              [121.40390625000003, 32.20625],
              [121.45078125000003, 32.1533203125],
              [121.49052734374999, 32.12109375],
              [121.67421875000002, 32.051025390625],
              [121.75107421875003, 31.992871093749997],
              [121.83242187500002, 31.899755859375],
              [121.85634765625002, 31.816455078124996],
              [121.86630859375003, 31.703564453124997],
              [121.76357421875002, 31.699511718749996],
              [121.68085937500001, 31.712158203125],
              [121.35195312500002, 31.858789062499994],
              [121.26640624999999, 31.862695312499994],
              [121.14580078124999, 31.842333984374996],
              [120.97353515625002, 31.869384765625],
              [120.79169921875001, 32.03173828125],
              [120.66054687500002, 32.0810546875],
              [120.52011718750003, 32.105859375],
              [120.18408203125, 31.966162109375],
              [120.09873046875003, 31.975976562499994],
              [120.07392578125001, 31.960253906249996],
              [120.03593749999999, 31.936279296875],
              [120.19160156250001, 31.90634765625],
              [120.34746093749999, 31.952099609374997],
              [120.49716796875003, 32.019824218749996],
              [120.71552734375001, 31.983740234375],
              [120.75224609374999, 31.9228515625],
              [120.78779296875001, 31.819775390624997],
              [120.9375, 31.750195312499997],
              [121.05537109375001, 31.719433593749997],
              [121.20488281249999, 31.628076171874994],
              [121.35097656250002, 31.4853515625],
              [121.66064453125, 31.319726562499994],
              [121.78593749999999, 31.162890625],
              [121.83447265625, 31.06162109375],
              [121.8779296875, 30.9169921875],
              [121.76943359375002, 30.870361328125],
              [121.67519531250002, 30.86376953125],
              [121.52753906250001, 30.840966796874994],
              [121.4189453125, 30.789794921875],
              [121.30996093750002, 30.69970703125],
              [120.99765625000003, 30.558251953124994],
              [120.93828124999999, 30.4697265625],
              [120.8974609375, 30.392626953124996],
              [120.82148437500001, 30.354638671874994],
              [120.62998046875003, 30.390869140625],
              [120.44980468750003, 30.387841796874994],
              [120.24550781250002, 30.283544921875],
              [120.19462890624999, 30.24130859375],
              [120.228515625, 30.249560546874996],
              [120.26054687499999, 30.263037109375],
              [120.3525390625, 30.247412109375],
              [120.49453125000002, 30.303076171875],
              [120.63339843750003, 30.133154296875],
              [120.90449218750001, 30.16064453125],
              [121.159375, 30.3017578125],
              [121.25800781250001, 30.304101562499994],
              [121.340625, 30.282373046874994],
              [121.43271484375003, 30.22666015625],
              [121.67792968750001, 29.9791015625],
              [121.81230468749999, 29.9521484375],
              [121.9443359375, 29.894091796874996],
              [122.01728515625001, 29.8876953125],
              [122.08291015625002, 29.870361328125],
              [121.90576171875, 29.7796875],
              [121.67656249999999, 29.583789062499996],
              [121.57460937500002, 29.537011718749994],
              [121.50625, 29.484570312499997],
              [121.6904296875, 29.510986328125],
              [121.821875, 29.604638671874994],
              [121.88798828124999, 29.627783203125],
              [121.94121093749999, 29.605908203124997],
              [121.96835937500003, 29.490625],
              [121.91777343749999, 29.135009765625],
              [121.853515625, 29.12890625],
              [121.79082031249999, 29.22568359375],
              [121.71748046875001, 29.25634765625],
              [121.65595703125001, 29.236132812499996],
              [121.53369140625, 29.23671875],
              [121.48710937499999, 29.193164062499996],
              [121.44765625000002, 29.13134765625],
              [121.52089843750002, 29.118457031249996],
              [121.66494140625002, 29.010595703125],
              [121.6796875, 28.953125],
              [121.64101562500002, 28.915917968749994],
              [121.5400390625, 28.931884765625],
              [121.6625, 28.851416015625],
              [121.63007812500001, 28.767919921875],
              [121.59033203125, 28.734814453124997],
              [121.51914062500003, 28.713671875],
              [121.47519531250003, 28.64140625],
              [121.5380859375, 28.52109375],
              [121.60205078125, 28.366601562499994],
              [121.60996093750003, 28.292138671874994],
              [121.50996093750001, 28.324267578124996],
              [121.35458984375003, 28.229882812499994],
              [121.27226562499999, 28.222119140624997],
              [121.216796875, 28.34619140625],
              [121.14570312500001, 28.32666015625],
              [121.09843749999999, 28.29052734375],
              [121.03544921874999, 28.157275390624996],
              [120.95859375000003, 28.037011718749994],
              [120.89248046875002, 28.00390625],
              [120.81298828125, 28.013378906249997],
              [120.74765625000003, 28.009960937499997],
              [120.76347656249999, 27.977441406249994],
              [120.8330078125, 27.937792968749996],
              [120.8330078125, 27.891455078125],
              [120.68515625000003, 27.744580078124997],
              [120.66132812500001, 27.687890625],
              [120.66484374999999, 27.639453125],
              [120.5875, 27.580761718749997],
              [120.62910156250001, 27.482128906249997],
              [120.60751953125003, 27.412402343749996],
              [120.53984374999999, 27.318359375],
              [120.46865234375002, 27.25625],
              [120.38457031249999, 27.155517578125],
              [120.27871093750002, 27.097070312499994],
              [120.13857421875002, 26.886132812499994],
              [120.09746093749999, 26.780664062499994],
              [120.08671874999999, 26.671582031249997],
              [120.04296875, 26.633837890624996],
              [119.9677734375, 26.586376953124997],
              [119.88222656250002, 26.61044921875],
              [119.87949218750003, 26.683007812499994],
              [119.84238281250003, 26.689306640625],
              [119.8212890625, 26.7369140625],
              [119.81513671875001, 26.797607421875],
              [119.82421875, 26.846386718749997],
              [119.78867187500003, 26.831494140624997],
              [119.76669921875003, 26.774707031249996],
              [119.71044921875, 26.728662109374994],
              [119.65156250000001, 26.747265625],
              [119.58818359374999, 26.784960937499996],
              [119.58994140625003, 26.73046875],
              [119.62363281250003, 26.67587890625],
              [119.63818359375, 26.62119140625],
              [119.72597656250002, 26.609423828124996],
              [119.78476562500003, 26.546630859375],
              [119.83115234375003, 26.4501953125],
              [119.84033203125, 26.41416015625],
              [119.87646484375, 26.370947265625],
              [119.88105468750001, 26.334179687499997],
              [119.79726562500002, 26.300146484375],
              [119.69267578124999, 26.23642578125],
              [119.56708984375001, 26.12734375],
              [119.46308593750001, 26.0546875],
              [119.36972656250003, 26.054052734375],
              [119.31308593750003, 26.062548828124996],
              [119.23212890625001, 26.104394531249994],
              [119.13945312499999, 26.121777343749997],
              [119.26376953125003, 25.974804687499997],
              [119.33203125, 25.94873046875],
              [119.41777343749999, 25.954345703125],
              [119.50087890625002, 26.009179687499994],
              [119.61875, 26.003564453124994],
              [119.64824218749999, 25.918701171875],
              [119.61689453125001, 25.822900390624994],
              [119.55283203125003, 25.698681640624997],
              [119.53945312500002, 25.591259765624997],
              [119.619140625, 25.437451171874997],
              [119.62246093750002, 25.391162109375003],
              [119.5927734375, 25.36801757812499],
              [119.49921875000001, 25.408642578124997],
              [119.42177734375002, 25.459619140624994],
              [119.34375, 25.4462890625],
              [119.26308593750002, 25.468017578125],
              [119.18007812500002, 25.44980468749999],
              [119.14628906249999, 25.414306640625],
              [119.16933593750002, 25.355712890625],
              [119.24355468750002, 25.30703125],
              [119.28554687500002, 25.232226562500003],
              [119.23554687500001, 25.205957031249994],
              [119.02460937500001, 25.2234375],
              [118.9775390625, 25.209277343750003],
              [118.91445312500002, 25.12680664062499],
              [118.95566406250003, 25.004785156249994],
              [118.90908203125002, 24.92890625],
              [118.82207031249999, 24.9111328125],
              [118.70751953125, 24.849804687499997],
              [118.63691406250001, 24.835546875],
              [118.64023437500003, 24.80908203125],
              [118.69179687500002, 24.78232421874999],
              [118.71914062500002, 24.746142578125003],
              [118.65703124999999, 24.62143554687499],
              [118.56035156249999, 24.580371093750003],
              [118.41201171875002, 24.600732421874994],
              [118.29531250000002, 24.57275390625],
              [118.19453125000001, 24.62583007812499],
              [118.08710937500001, 24.627001953125003],
              [118.01386718750001, 24.55991210937499],
              [118.00595703125003, 24.481982421875003],
              [117.93505859375, 24.47421875],
              [117.896875, 24.47983398437499],
              [117.84267578125002, 24.474316406249997],
              [117.84824218750003, 24.43247070312499],
              [117.87900390625003, 24.395898437499994],
              [118.02421874999999, 24.379638671875],
              [118.05058593749999, 24.3271484375],
              [118.05605468750002, 24.24609375],
              [117.90410156249999, 24.1064453125],
              [117.83945312500003, 24.01230468749999],
              [117.74169921875, 24.014794921874994],
              [117.66787109375002, 23.939257812500003],
              [117.62822265624999, 23.83671875],
              [117.57919921875003, 23.856982421875003],
              [117.46640625000003, 23.840576171875],
              [117.43310546875, 23.791699218749997],
              [117.45957031250003, 23.771484375],
              [117.46220703124999, 23.736230468749994],
              [117.4169921875, 23.620996093749994],
              [117.36767578125, 23.588623046875],
              [117.3466796875, 23.6357421875],
              [117.33076171875001, 23.708789062500003],
              [117.29082031249999, 23.71435546875],
              [117.225, 23.647021484375003],
              [117.14814453125001, 23.598779296874994],
              [117.08251953125, 23.578759765624994],
              [117.03281250000003, 23.6234375],
              [116.91064453125, 23.646679687499997],
              [116.86093750000003, 23.453076171874997],
              [116.75957031249999, 23.382519531249997],
              [116.71210937500001, 23.360498046874994],
              [116.62939453125, 23.35385742187499],
              [116.68232421875001, 23.327392578125],
              [116.69882812500003, 23.277783203124997],
              [116.66914062500001, 23.228173828124994],
              [116.58642578125, 23.21826171875],
              [116.53828125000001, 23.1796875],
              [116.51982421874999, 23.006591796875],
              [116.470703125, 22.94589843749999],
              [116.34550781249999, 22.941064453124994],
              [116.25185546875002, 22.981347656249994],
              [116.22207031250002, 22.94956054687499],
              [116.20634765624999, 22.918652343749997],
              [116.15742187500001, 22.887451171875],
              [116.06259765625003, 22.879101562499997],
              [115.85214843750003, 22.8015625],
              [115.755859375, 22.823925781249997],
              [115.64042968749999, 22.853417968749994],
              [115.56113281250003, 22.82470703125],
              [115.53466796875, 22.765185546875003],
              [115.49833984374999, 22.71884765624999],
              [115.38251953125001, 22.71884765624999],
              [115.28994140625002, 22.77597656249999],
              [115.19580078125, 22.817285156249994],
              [115.09150390625001, 22.781689453124997],
              [115.01210937500002, 22.708935546874997],
              [114.91445312500002, 22.684619140625003],
              [114.89638671875002, 22.63950195312499],
              [114.85380859374999, 22.616796875],
              [114.75039062500002, 22.62631835937499],
              [114.71113281250001, 22.738720703124997],
              [114.65166015624999, 22.755273437499994],
              [114.5927734375, 22.6984375],
              [114.57197265625001, 22.654052734375],
              [114.54443359375, 22.62060546875],
              [114.55419921875, 22.52890625],
              [114.49619140625003, 22.527050781249997],
              [114.42011718750001, 22.583251953125],
              [114.340625, 22.593212890624997],
              [114.26601562500002, 22.540966796874997],
              [114.22822265625001, 22.553955078125],
              [114.18818359375001, 22.564990234375003],
              [114.12285156249999, 22.564990234375003],
              [114.09785156250001, 22.55126953125],
              [114.05039062500003, 22.54296875],
              [114.01826171875001, 22.514453125],
              [114.01542968749999, 22.511914062499997],
              [113.93115234375, 22.531054687500003],
              [113.82832031250001, 22.607226562500003],
              [113.75449218750003, 22.733642578125],
              [113.6611328125, 22.801660156249994],
              [113.61962890625, 22.86142578124999],
              [113.60341796875002, 22.968896484374994],
              [113.58632812500002, 23.02001953125],
              [113.59218750000002, 23.076953125],
              [113.62050781250002, 23.127490234375003],
              [113.51972656250001, 23.102099609375003],
              [113.4453125, 23.055078125],
              [113.46035156250002, 22.995703125],
              [113.44189453125, 22.940576171874994],
              [113.3310546875, 22.912011718749994],
              [113.33779296875002, 22.888818359374994],
              [113.34482421875003, 22.86459960937499],
              [113.43203125000002, 22.78940429687499],
              [113.44980468750003, 22.726123046875003],
              [113.48476562500002, 22.6923828125],
              [113.55302734374999, 22.594042968750003],
              [113.55146484375001, 22.40415039062499],
              [113.5888671875, 22.350488281249994],
              [113.57646484374999, 22.297265625],
              [113.54912109374999, 22.22519531249999],
              [113.54677734375002, 22.22412109375],
              [113.52705078125001, 22.24594726562499],
              [113.494140625, 22.24155273437499],
              [113.48105468750003, 22.217480468749997],
              [113.47890625000002, 22.195556640625],
              [113.47343749999999, 22.19443359374999],
              [113.41572265625001, 22.178369140624994],
              [113.36738281250001, 22.16484375],
              [113.32773437500003, 22.145410156249994],
              [113.26640624999999, 22.088769531249994],
              [113.14902343750003, 22.075],
              [113.08876953125002, 22.207958984374997],
              [113.00820312500002, 22.119335937499997],
              [112.98378906250002, 21.938232421875],
              [112.95390624999999, 21.90732421874999],
              [112.90380859375, 21.88144531249999],
              [112.80859375, 21.944628906250003],
              [112.72539062499999, 21.90234375],
              [112.66074218750003, 21.85947265624999],
              [112.63408203124999, 21.819873046875003],
              [112.58632812500002, 21.77685546875],
              [112.49472656250003, 21.818310546874997],
              [112.42128906250002, 21.880615234375],
              [112.439453125, 21.92734375],
              [112.42929687500003, 21.95810546874999],
              [112.39609375000003, 21.981347656249994],
              [112.35966796874999, 21.97802734375],
              [112.37744140625, 21.91748046875],
              [112.38974609375003, 21.801220703124997],
              [112.3564453125, 21.767578125],
              [112.30498046874999, 21.74169921875],
              [112.193359375, 21.763134765624997],
              [112.1171875, 21.80649414062499],
              [112.02519531249999, 21.843017578125],
              [111.94394531250003, 21.849658203125003],
              [111.92646484375001, 21.776269531249994],
              [111.87343750000002, 21.71713867187499],
              [111.82460937500002, 21.709765625],
              [111.77597656250003, 21.71923828125],
              [111.7119140625, 21.655224609374997],
              [111.681640625, 21.60849609374999],
              [111.60273437500001, 21.55908203125],
              [111.39238281249999, 21.535107421874997],
              [111.31914062499999, 21.486132812500003],
              [111.22060546875002, 21.493896484375],
              [111.14423828125001, 21.482226562500003],
              [111.1005859375, 21.48471679687499],
              [111.06113281250003, 21.510986328125],
              [111.01689453124999, 21.51171875],
              [110.99677734375001, 21.43027343749999],
              [110.87802734375003, 21.395947265624997],
              [110.77109375000003, 21.386523437500003],
              [110.65214843749999, 21.279101562500003],
              [110.56718749999999, 21.2140625],
              [110.50429687500002, 21.207421875],
              [110.4580078125, 21.23056640624999],
              [110.4345703125, 21.326904296875],
              [110.41093749999999, 21.338134765625],
              [110.3974609375, 21.247705078124994],
              [110.37460937500003, 21.17236328125],
              [110.33115234375003, 21.13134765625],
              [110.19355468750001, 21.037646484375003],
              [110.15400390625001, 20.944628906250003],
              [110.18037109375001, 20.85859375],
              [110.36542968750001, 20.837597656249997],
              [110.38847656249999, 20.79052734375],
              [110.37050781250002, 20.75205078124999],
              [110.326171875, 20.719921875],
              [110.31308593750003, 20.671679687500003],
              [110.51152343749999, 20.518261718749997],
              [110.517578125, 20.460009765625003],
              [110.48691406250003, 20.42685546874999],
              [110.44951171874999, 20.355419921874997],
              [110.3447265625, 20.294824218749994],
              [110.12314453125003, 20.263720703125003],
              [109.9384765625, 20.295117187499997],
              [109.88251953125001, 20.3640625],
              [109.88583984375003, 20.413134765625003],
              [109.931640625, 20.398876953124997],
              [109.98388671875, 20.403271484374997],
              [109.96835937500003, 20.448144531249994],
              [109.94638671875003, 20.474365234375],
              [109.86103515625001, 20.514306640624994],
              [109.7919921875, 20.621875],
              [109.80527343750003, 20.711474609375003],
              [109.76738281249999, 20.780712890624997],
              [109.72626953125001, 20.838769531249994],
              [109.68476562500001, 20.87363281249999],
              [109.66259765625, 20.916894531249994],
              [109.70449218750002, 21.052734375],
              [109.68125, 21.131640625],
              [109.76015625000002, 21.22836914062499],
              [109.77958984374999, 21.337451171875003],
              [109.92109375000001, 21.37646484375],
              [109.93076171875003, 21.48056640624999],
              [109.82958984375, 21.48359375],
              [109.759375, 21.56005859375],
              [109.74335937500001, 21.527978515624994],
              [109.68691406250002, 21.524609375],
              [109.59433593750003, 21.67197265624999],
              [109.56640625, 21.690576171874994],
              [109.521484375, 21.693408203125003],
              [109.54404296875003, 21.53793945312499],
              [109.435546875, 21.4794921875],
              [109.3466796875, 21.45395507812499],
              [109.22041015625001, 21.443408203125003],
              [109.14863281250001, 21.425537109375],
              [109.08154296875, 21.44028320312499],
              [109.09814453125, 21.487353515625003],
              [109.13349609375001, 21.543603515624994],
              [109.10175781250001, 21.590478515624994],
              [109.03056640624999, 21.626513671875003],
              [108.92177734375002, 21.624414062499994],
              [108.84638671875001, 21.634472656249997],
              [108.77167968750001, 21.63046875],
              [108.74394531249999, 21.651269531249994],
              [108.67451171875001, 21.724658203125003],
              [108.61582031250003, 21.770458984374997],
              [108.58935546875, 21.815966796875003],
              [108.61582031250003, 21.868896484375],
              [108.59375, 21.901025390624994],
              [108.47988281250002, 21.90463867187499],
              [108.48085937500002, 21.828808593749997],
              [108.49257812500002, 21.739404296874994],
              [108.52568359374999, 21.67138671875],
              [108.50214843750001, 21.633447265624994],
              [108.4443359375, 21.607324218749994],
              [108.3828125, 21.67919921875],
              [108.35458984375003, 21.696923828124994],
              [108.32480468750003, 21.693505859374994],
              [108.30214843750002, 21.62192382812499],
              [108.24628906250001, 21.558398437500003],
              [108.14560546875003, 21.565185546875],
              [108.0673828125, 21.52597656249999],
              [107.97265625, 21.507958984374994],
            ],
          ],
          [
            [
              [110.88876953125003, 19.991943359375],
              [110.93828124999999, 19.947558593750003],
              [110.970703125, 19.88330078125],
              [110.99765625000003, 19.764697265625003],
              [111.013671875, 19.65546875],
              [110.91269531250003, 19.586083984374994],
              [110.822265625, 19.557910156250003],
              [110.64091796874999, 19.291210937499997],
              [110.603125, 19.20703125],
              [110.57216796875002, 19.171875],
              [110.5625, 19.13515625],
              [110.56601562500003, 19.098535156249994],
              [110.51933593749999, 18.97021484375],
              [110.47763671875003, 18.81259765624999],
              [110.45126953125003, 18.747949218749994],
              [110.39951171875003, 18.69833984374999],
              [110.33369140625001, 18.673291015624997],
              [110.29082031249999, 18.66953125],
              [110.25175781249999, 18.65576171875],
              [110.15625, 18.56982421875],
              [110.04853515625001, 18.50522460937499],
              [110.06640625, 18.475634765625003],
              [110.0673828125, 18.447558593750003],
              [110.02021484375001, 18.416259765625],
              [109.96767578125002, 18.422070312499997],
              [109.815625, 18.396679687499997],
              [109.759765625, 18.348291015624994],
              [109.70273437500003, 18.25913085937499],
              [109.68105468750002, 18.247119140625003],
              [109.58955078125001, 18.226318359375],
              [109.51933593749999, 18.21826171875],
              [109.40009765625001, 18.28110351562499],
              [109.34091796875003, 18.299609375],
              [109.18320312500003, 18.325146484374997],
              [109.02988281250003, 18.36777343749999],
              [108.92226562500002, 18.41611328124999],
              [108.70156250000002, 18.53525390624999],
              [108.67607421874999, 18.750244140625],
              [108.63808593750002, 18.86630859374999],
              [108.63564453125002, 18.90771484375],
              [108.65, 19.265039062499994],
              [108.66552734375, 19.304101562499994],
              [108.69355468750001, 19.33828125],
              [108.791015625, 19.418164062499997],
              [108.90283203125, 19.481347656249994],
              [109.06289062500002, 19.613574218750003],
              [109.17910156250002, 19.674121093750003],
              [109.27666015624999, 19.761132812499994],
              [109.21953124999999, 19.757470703124994],
              [109.17744140625001, 19.768457031249994],
              [109.21894531250001, 19.842822265625003],
              [109.26347656249999, 19.88266601562499],
              [109.31484375000002, 19.90439453124999],
              [109.41816406250001, 19.888818359374994],
              [109.513671875, 19.904248046874997],
              [109.58427734374999, 19.9703125],
              [109.6513671875, 19.984375],
              [109.90625, 19.96274414062499],
              [110.0830078125, 19.992919921875],
              [110.17158203125001, 20.0537109375],
              [110.21337890625, 20.056054687499994],
              [110.34394531250001, 20.038818359375],
              [110.39228515625001, 19.9755859375],
              [110.38798828124999, 20.018017578124997],
              [110.3935546875, 20.059228515624994],
              [110.41757812500003, 20.054736328125003],
              [110.58818359374999, 19.976367187500003],
              [110.58876953125002, 20.072460937499997],
              [110.59833984375001, 20.097607421874997],
              [110.65175781250002, 20.137744140625003],
              [110.67851562499999, 20.13706054687499],
              [110.74453125000002, 20.059472656249994],
              [110.80908203125, 20.014404296875],
              [110.88876953125003, 19.991943359375],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 4,
        sovereignt: "Israel",
        sov_a3: "IS1",
        adm0_dif: 1,
        level: 2,
        type: "Disputed",
        tlc: "1",
        admin: "Israel",
        adm0_a3: "ISR",
        geou_dif: 0,
        geounit: "Israel",
        gu_a3: "ISR",
        su_dif: 0,
        subunit: "Israel",
        su_a3: "ISR",
        brk_diff: 1,
        name: "Israel",
        name_long: "Israel",
        brk_a3: "ISR",
        brk_name: "Israel",
        brk_group: null,
        abbrev: "Isr.",
        postal: "IS",
        formal_en: "State of Israel",
        formal_fr: null,
        name_ciawf: "Israel",
        note_adm0: null,
        note_brk: null,
        name_sort: "Israel",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 2,
        mapcolor9: 5,
        mapcolor13: 9,
        pop_est: 9053300,
        pop_rank: 13,
        pop_year: 2019,
        gdp_md: 394652,
        gdp_year: 2019,
        economy: "2. Developed region: nonG7",
        income_grp: "1. High income: OECD",
        fips_10: "-99",
        iso_a2: "IL",
        iso_a2_eh: "IL",
        iso_a3: "ISR",
        iso_a3_eh: "ISR",
        iso_n3: "376",
        iso_n3_eh: "376",
        un_a3: "376",
        wb_a2: "IL",
        wb_a3: "ISR",
        woe_id: 23424852,
        woe_id_eh: 23424852,
        woe_note: "Exact WOE match as country",
        adm0_iso: "ISR",
        adm0_diff: null,
        adm0_tlc: "ISR",
        adm0_a3_us: "ISR",
        adm0_a3_fr: "ISR",
        adm0_a3_ru: "ISR",
        adm0_a3_es: "ISR",
        adm0_a3_cn: "ISR",
        adm0_a3_tw: "ISR",
        adm0_a3_in: "ISR",
        adm0_a3_np: "ISR",
        adm0_a3_pk: "PSX",
        adm0_a3_de: "ISR",
        adm0_a3_gb: "ISR",
        adm0_a3_br: "ISR",
        adm0_a3_il: "ISR",
        adm0_a3_ps: "ISR",
        adm0_a3_sa: "PSX",
        adm0_a3_eg: "ISR",
        adm0_a3_ma: "ISR",
        adm0_a3_pt: "ISR",
        adm0_a3_ar: "ISR",
        adm0_a3_jp: "ISR",
        adm0_a3_ko: "ISR",
        adm0_a3_vn: "ISR",
        adm0_a3_tr: "ISR",
        adm0_a3_id: "ISR",
        adm0_a3_pl: "ISR",
        adm0_a3_gr: "ISR",
        adm0_a3_it: "ISR",
        adm0_a3_nl: "ISR",
        adm0_a3_se: "ISR",
        adm0_a3_bd: "PSX",
        adm0_a3_ua: "ISR",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Western Asia",
        region_wb: "Middle East & North Africa",
        name_len: 6,
        long_len: 6,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: 34.847915,
        label_y: 30.911148,
        ne_id: 1159320895,
        wikidataid: "Q801",
        name_ar: "إسرائيل",
        name_bn: "ইসরায়েল",
        name_de: "Israel",
        name_en: "Israel",
        name_es: "Israel",
        name_fa: "اسرائیل",
        name_fr: "Israël",
        name_el: "Ισραήλ",
        name_he: "ישראל",
        name_hi: "इज़राइल",
        name_hu: "Izrael",
        name_id: "Israel",
        name_it: "Israele",
        name_ja: "イスラエル",
        name_ko: "이스라엘",
        name_nl: "Israël",
        name_pl: "Izrael",
        name_pt: "Israel",
        name_ru: "Израиль",
        name_sv: "Israel",
        name_tr: "İsrail",
        name_uk: "Ізраїль",
        name_ur: "اسرائیل",
        name_vi: "Israel",
        name_zh: "以色列",
        name_zht: "以色列",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: "Unrecognized",
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: "Unrecognized",
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: "Unrecognized",
        fclass_ua: null,
        filename: "ISR.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [35.84072265625002, 33.415673828124994],
            [35.81612458460434, 33.36187853338469],
            [35.7925310942343, 33.33410710518994],
            [35.785024074571105, 33.29108871216229],
            [35.80003811389749, 33.256120642614626],
            [35.80861756494114, 33.20857749231844],
            [35.8268488984089, 33.19511719176682],
            [35.83328348669164, 33.16370177763126],
            [35.817197015984796, 33.133173164418636],
            [35.82470403564798, 33.10802397833988],
            [35.84508023187666, 33.08466471531837],
            [35.85902183982259, 32.98936659098219],
            [35.88797748709491, 32.94437888348711],
            [35.841862937735286, 32.87595371086475],
            [35.83328348669164, 32.8200940242287],
            [35.78730468750001, 32.734912109374996],
            [35.734472656250006, 32.72890625],
            [35.61123046875002, 32.682080078125],
            [35.59453125000002, 32.668017578124996],
            [35.572851562500006, 32.640869140625],
            [35.56904296875001, 32.619873046875],
            [35.55146484375001, 32.3955078125],
            [35.484375, 32.401660156249996],
            [35.40263671875002, 32.450634765625],
            [35.38671875, 32.493017578125],
            [35.36210937500002, 32.507470703124994],
            [35.303808593750006, 32.512939453125],
            [35.19326171875002, 32.534423828125],
            [35.065039062500006, 32.46044921875],
            [35.01054687500002, 32.338183593749996],
            [34.99951171875, 32.281054687499996],
            [34.95595703125002, 32.1609375],
            [34.97138671875001, 32.087109375],
            [34.97880859375002, 31.991601562499994],
            [34.98974609375, 31.91328125],
            [34.97832031250002, 31.86640625],
            [34.95380859375001, 31.841259765624997],
            [34.96113281250001, 31.82333984375],
            [34.983007812500006, 31.816796875],
            [35.05322265625, 31.837939453124996],
            [35.12714843750001, 31.816748046875],
            [35.19804687500002, 31.776318359374997],
            [35.203710937500006, 31.75],
            [35.153417968750006, 31.73447265625],
            [35.03466796875, 31.673242187499994],
            [34.95097656250002, 31.602294921875],
            [34.92919921875, 31.536572265624997],
            [34.87275390625001, 31.396875],
            [34.88046875, 31.3681640625],
            [34.9078125, 31.351318359375],
            [35.101171875, 31.3662109375],
            [35.27666015625002, 31.422802734374997],
            [35.40869140625, 31.48291015625],
            [35.45058593750002, 31.479296875],
            [35.4228515625, 31.325390625],
            [35.42353515625001, 31.324853515624994],
            [35.40068359375002, 31.230517578124996],
            [35.40966796875, 31.214453125],
            [35.43925781250002, 31.132421875],
            [35.38300781250001, 30.982275390625],
            [35.32011718750002, 30.860205078125],
            [35.2978515625, 30.80224609375],
            [35.23662109375002, 30.673486328124994],
            [35.17402343750001, 30.52392578125],
            [35.140625, 30.4208984375],
            [35.14814453125001, 30.384326171874996],
            [35.13261718750002, 30.1953125],
            [35.1416015625, 30.14169921875],
            [35.06816406250002, 29.977880859375],
            [35.05341796875001, 29.896923828124997],
            [35.02392578125, 29.787060546874997],
            [34.97343750000002, 29.555029296875],
            [34.904296875, 29.47734375],
            [34.86982421875001, 29.563916015624997],
            [34.791113281250006, 29.812109375],
            [34.73505859375001, 29.98203125],
            [34.65859375000002, 30.191455078124996],
            [34.52968750000002, 30.446044921875],
            [34.51777343750001, 30.507373046874996],
            [34.48994140625001, 30.5962890625],
            [34.400976562500006, 30.827832031249997],
            [34.32851562500002, 30.995019531249994],
            [34.24531250000001, 31.208300781249996],
            [34.34833984375001, 31.292919921874997],
            [34.350195312500006, 31.362744140624997],
            [34.52558593750001, 31.525634765625],
            [34.52412109375001, 31.541650390624994],
            [34.47734375000002, 31.584863281249994],
            [34.483984375, 31.59228515625],
            [34.67841796875001, 31.895703125],
            [34.803808593750006, 32.196337890624996],
            [34.921875, 32.6140625],
            [35.005859375, 32.826611328125],
            [35.07705078125002, 32.9671875],
            [35.10859375000001, 33.08369140625],
            [35.22333984375001, 33.0919921875],
            [35.30888671875002, 33.079541015625],
            [35.411230468750006, 33.07568359375],
            [35.4931640625, 33.119482421875],
            [35.53251953125002, 33.25048828125],
            [35.57929687500001, 33.271484375],
            [35.60292968750002, 33.240625],
            [35.62724609375002, 33.275048828124994],
            [35.734472656250006, 33.3326171875],
            [35.7875, 33.369775390624994],
            [35.84072265625002, 33.415673828124994],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 5,
        sovereignt: "Israel",
        sov_a3: "IS1",
        adm0_dif: 1,
        level: 2,
        type: "Indeterminate",
        tlc: "1",
        admin: "Palestine",
        adm0_a3: "PSX",
        geou_dif: 0,
        geounit: "Palestine",
        gu_a3: "PSX",
        su_dif: 0,
        subunit: "Palestine",
        su_a3: "PSX",
        brk_diff: 0,
        name: "Palestine",
        name_long: "Palestine",
        brk_a3: "PSX",
        brk_name: "Palestine",
        brk_group: null,
        abbrev: "Pal.",
        postal: "PAL",
        formal_en: "West Bank and Gaza",
        formal_fr: null,
        name_ciawf: null,
        note_adm0: null,
        note_brk: "Partial self-admin.",
        name_sort: "Palestine (West Bank and Gaza)",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 2,
        mapcolor9: 5,
        mapcolor13: 8,
        pop_est: 4685306,
        pop_rank: 12,
        pop_year: 2019,
        gdp_md: 16276,
        gdp_year: 2018,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        fips_10: "-99",
        iso_a2: "PS",
        iso_a2_eh: "PS",
        iso_a3: "PSE",
        iso_a3_eh: "PSE",
        iso_n3: "275",
        iso_n3_eh: "275",
        un_a3: "275",
        wb_a2: "GZ",
        wb_a3: "WBG",
        woe_id: 28289408,
        woe_id_eh: 28289408,
        woe_note: "Exact WOE match as country",
        adm0_iso: "PSX",
        adm0_diff: null,
        adm0_tlc: "PSX",
        adm0_a3_us: "PSX",
        adm0_a3_fr: "PSX",
        adm0_a3_ru: "PSX",
        adm0_a3_es: "PSX",
        adm0_a3_cn: "PSX",
        adm0_a3_tw: "PSX",
        adm0_a3_in: "PSX",
        adm0_a3_np: "PSX",
        adm0_a3_pk: "PSX",
        adm0_a3_de: "PSX",
        adm0_a3_gb: "PSX",
        adm0_a3_br: "PSX",
        adm0_a3_il: "PSX",
        adm0_a3_ps: "PSX",
        adm0_a3_sa: "PSX",
        adm0_a3_eg: "PSX",
        adm0_a3_ma: "PSX",
        adm0_a3_pt: "PSX",
        adm0_a3_ar: "PSX",
        adm0_a3_jp: "PSX",
        adm0_a3_ko: "PSX",
        adm0_a3_vn: "PSX",
        adm0_a3_tr: "PSX",
        adm0_a3_id: "PSX",
        adm0_a3_pl: "PSX",
        adm0_a3_gr: "PSX",
        adm0_a3_it: "PSX",
        adm0_a3_nl: "PSX",
        adm0_a3_se: "PSX",
        adm0_a3_bd: "PSX",
        adm0_a3_ua: "PSX",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Western Asia",
        region_wb: "Middle East & North Africa",
        name_len: 9,
        long_len: 9,
        abbrev_len: 4,
        tiny: -99,
        homepart: -99,
        min_zoom: 7,
        min_label: 4.5,
        max_label: 9.5,
        label_x: 35.291341,
        label_y: 32.047431,
        ne_id: 1159320899,
        wikidataid: "Q23792",
        name_ar: "فلسطين",
        name_bn: "ফিলিস্তিন অঞ্চল",
        name_de: "Palästina",
        name_en: "Palestine",
        name_es: "Palestina",
        name_fa: "فلسطین",
        name_fr: "Palestine",
        name_el: "Παλαιστίνη",
        name_he: "ארץ ישראל",
        name_hi: "फ़िलिस्तीनी राज्यक्षेत्र",
        name_hu: "Palesztina",
        name_id: "Palestina",
        name_it: "Palestina",
        name_ja: "パレスチナ",
        name_ko: "팔레스타인",
        name_nl: "Palestina",
        name_pl: "Palestyna",
        name_pt: "Palestina",
        name_ru: "Палестина",
        name_sv: "Palestina",
        name_tr: "Filistin",
        name_uk: "Палестина",
        name_ur: "فلسطین",
        name_vi: "Palestine",
        name_zh: "巴勒斯坦",
        name_zht: "巴勒斯坦地區",
        fclass_iso: "Admin-0 dependency",
        tlc_diff: null,
        fclass_tlc: "Admin-0 dependency",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: "Admin-0 country",
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: "Admin-0 country",
        fclass_sa: "Admin-0 country",
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: "Admin-0 country",
        fclass_ua: null,
        filename: "PSE.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [34.47734375000002, 31.584863281249994],
              [34.52412109375001, 31.541650390624994],
              [34.52558593750001, 31.525634765625],
              [34.350195312500006, 31.362744140624997],
              [34.34833984375001, 31.292919921874997],
              [34.24531250000001, 31.208300781249996],
              [34.2125, 31.292285156249996],
              [34.19814453125002, 31.322607421875],
              [34.387304687500006, 31.483789062499994],
              [34.47734375000002, 31.584863281249994],
            ],
          ],
          [
            [
              [35.55146484375001, 32.3955078125],
              [35.57207031250002, 32.237890625],
              [35.534765625, 32.10302734375],
              [35.53144531250001, 31.984912109374996],
              [35.55898437500002, 31.765527343749994],
              [35.49941406250002, 31.67236328125],
              [35.465429687500006, 31.562353515625],
              [35.45058593750002, 31.479296875],
              [35.40869140625, 31.48291015625],
              [35.27666015625002, 31.422802734374997],
              [35.101171875, 31.3662109375],
              [34.9078125, 31.351318359375],
              [34.88046875, 31.3681640625],
              [34.87275390625001, 31.396875],
              [34.92919921875, 31.536572265624997],
              [34.95097656250002, 31.602294921875],
              [35.03466796875, 31.673242187499994],
              [35.153417968750006, 31.73447265625],
              [35.203710937500006, 31.75],
              [35.19804687500002, 31.776318359374997],
              [35.12714843750001, 31.816748046875],
              [35.05322265625, 31.837939453124996],
              [34.983007812500006, 31.816796875],
              [34.96113281250001, 31.82333984375],
              [34.95380859375001, 31.841259765624997],
              [34.97832031250002, 31.86640625],
              [34.98974609375, 31.91328125],
              [34.97880859375002, 31.991601562499994],
              [34.97138671875001, 32.087109375],
              [34.95595703125002, 32.1609375],
              [34.99951171875, 32.281054687499996],
              [35.01054687500002, 32.338183593749996],
              [35.065039062500006, 32.46044921875],
              [35.19326171875002, 32.534423828125],
              [35.303808593750006, 32.512939453125],
              [35.36210937500002, 32.507470703124994],
              [35.38671875, 32.493017578125],
              [35.40263671875002, 32.450634765625],
              [35.484375, 32.401660156249996],
              [35.55146484375001, 32.3955078125],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 5,
        sovereignt: "Lebanon",
        sov_a3: "LBN",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Lebanon",
        adm0_a3: "LBN",
        geou_dif: 0,
        geounit: "Lebanon",
        gu_a3: "LBN",
        su_dif: 0,
        subunit: "Lebanon",
        su_a3: "LBN",
        brk_diff: 0,
        name: "Lebanon",
        name_long: "Lebanon",
        brk_a3: "LBN",
        brk_name: "Lebanon",
        brk_group: null,
        abbrev: "Leb.",
        postal: "LB",
        formal_en: "Lebanese Republic",
        formal_fr: null,
        name_ciawf: "Lebanon",
        note_adm0: null,
        note_brk: null,
        name_sort: "Lebanon",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 4,
        mapcolor9: 4,
        mapcolor13: 12,
        pop_est: 6855713,
        pop_rank: 13,
        pop_year: 2019,
        gdp_md: 51991,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        fips_10: "LE",
        iso_a2: "LB",
        iso_a2_eh: "LB",
        iso_a3: "LBN",
        iso_a3_eh: "LBN",
        iso_n3: "422",
        iso_n3_eh: "422",
        un_a3: "422",
        wb_a2: "LB",
        wb_a3: "LBN",
        woe_id: 23424873,
        woe_id_eh: 23424873,
        woe_note: "Exact WOE match as country",
        adm0_iso: "LBN",
        adm0_diff: null,
        adm0_tlc: "LBN",
        adm0_a3_us: "LBN",
        adm0_a3_fr: "LBN",
        adm0_a3_ru: "LBN",
        adm0_a3_es: "LBN",
        adm0_a3_cn: "LBN",
        adm0_a3_tw: "LBN",
        adm0_a3_in: "LBN",
        adm0_a3_np: "LBN",
        adm0_a3_pk: "LBN",
        adm0_a3_de: "LBN",
        adm0_a3_gb: "LBN",
        adm0_a3_br: "LBN",
        adm0_a3_il: "LBN",
        adm0_a3_ps: "LBN",
        adm0_a3_sa: "LBN",
        adm0_a3_eg: "LBN",
        adm0_a3_ma: "LBN",
        adm0_a3_pt: "LBN",
        adm0_a3_ar: "LBN",
        adm0_a3_jp: "LBN",
        adm0_a3_ko: "LBN",
        adm0_a3_vn: "LBN",
        adm0_a3_tr: "LBN",
        adm0_a3_id: "LBN",
        adm0_a3_pl: "LBN",
        adm0_a3_gr: "LBN",
        adm0_a3_it: "LBN",
        adm0_a3_nl: "LBN",
        adm0_a3_se: "LBN",
        adm0_a3_bd: "LBN",
        adm0_a3_ua: "LBN",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Western Asia",
        region_wb: "Middle East & North Africa",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: 4,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: 35.992892,
        label_y: 34.133368,
        ne_id: 1159321013,
        wikidataid: "Q822",
        name_ar: "لبنان",
        name_bn: "লেবানন",
        name_de: "Libanon",
        name_en: "Lebanon",
        name_es: "Líbano",
        name_fa: "لبنان",
        name_fr: "Liban",
        name_el: "Λίβανος",
        name_he: "לבנון",
        name_hi: "लेबनान",
        name_hu: "Libanon",
        name_id: "Lebanon",
        name_it: "Libano",
        name_ja: "レバノン",
        name_ko: "레바논",
        name_nl: "Libanon",
        name_pl: "Liban",
        name_pt: "Líbano",
        name_ru: "Ливан",
        name_sv: "Libanon",
        name_tr: "Lübnan",
        name_uk: "Ліван",
        name_ur: "لبنان",
        name_vi: "Liban",
        name_zh: "黎巴嫩",
        name_zht: "黎巴嫩",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "LBN.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [35.97626953125001, 34.629199218749996],
            [36.15107421875001, 34.62861328125],
            [36.26357421875002, 34.632861328124996],
            [36.29628906250002, 34.6787109375],
            [36.383886718750006, 34.65791015625],
            [36.43300781250002, 34.6134765625],
            [36.388671875, 34.56689453125],
            [36.326269531250006, 34.513330078124994],
            [36.32988281250002, 34.499609375],
            [36.37646484375, 34.495166015624996],
            [36.45556640625, 34.466162109375],
            [36.50439453125, 34.432373046875],
            [36.5849609375, 34.221240234374996],
            [36.53515625, 34.134326171874996],
            [36.45751953125, 34.0568359375],
            [36.4228515625, 34.049853515624996],
            [36.35488281250002, 34.011328125],
            [36.2978515625, 33.958642578124994],
            [36.27783203125, 33.92529296875],
            [36.2822265625, 33.894189453124994],
            [36.36279296875, 33.855126953124994],
            [36.36503906250002, 33.83935546875],
            [36.34853515625002, 33.827050781249994],
            [36.28339843750001, 33.835595703124994],
            [36.19941406250001, 33.83955078125],
            [36.14980468750002, 33.839501953124994],
            [36.09218750000002, 33.831591796874996],
            [36.01884765625002, 33.783935546875],
            [35.98613281250002, 33.75263671875],
            [35.96845703125001, 33.732421875],
            [35.9423828125, 33.667578125],
            [35.9716796875, 33.623095703124996],
            [36.02666015625002, 33.597949218749996],
            [36.03447265625002, 33.58505859375],
            [36.02226562500002, 33.5625],
            [35.96757812500002, 33.534570312499994],
            [35.92656250000002, 33.500292968749996],
            [35.91474609375001, 33.465380859374996],
            [35.869140625, 33.43173828125],
            [35.84072265625002, 33.415673828124994],
            [35.7875, 33.369775390624994],
            [35.734472656250006, 33.3326171875],
            [35.62724609375002, 33.275048828124994],
            [35.60292968750002, 33.240625],
            [35.57929687500001, 33.271484375],
            [35.53251953125002, 33.25048828125],
            [35.4931640625, 33.119482421875],
            [35.411230468750006, 33.07568359375],
            [35.30888671875002, 33.079541015625],
            [35.22333984375001, 33.0919921875],
            [35.10859375000001, 33.08369140625],
            [35.15507812500002, 33.160009765625],
            [35.20351562500002, 33.258984375],
            [35.25136718750002, 33.392626953124996],
            [35.33574218750002, 33.503466796874996],
            [35.510839843750006, 33.879736328125],
            [35.61181640625, 34.032177734375],
            [35.64785156250002, 34.2482421875],
            [35.804296875, 34.437402343749994],
            [35.92138671875, 34.493310546874994],
            [35.97792968750002, 34.547412109374996],
            [35.97626953125001, 34.629199218749996],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Syria",
        sov_a3: "SYR",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Syria",
        adm0_a3: "SYR",
        geou_dif: 0,
        geounit: "Syria",
        gu_a3: "SYR",
        su_dif: 0,
        subunit: "Syria",
        su_a3: "SYR",
        brk_diff: 0,
        name: "Syria",
        name_long: "Syria",
        brk_a3: "SYR",
        brk_name: "Syria",
        brk_group: null,
        abbrev: "Syria",
        postal: "SYR",
        formal_en: "Syrian Arab Republic",
        formal_fr: null,
        name_ciawf: "Syria",
        note_adm0: null,
        note_brk: null,
        name_sort: "Syrian Arab Republic",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 6,
        mapcolor9: 2,
        mapcolor13: 6,
        pop_est: 17070135,
        pop_rank: 14,
        pop_year: 2019,
        gdp_md: 98830,
        gdp_year: 2015,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        fips_10: "SY",
        iso_a2: "SY",
        iso_a2_eh: "SY",
        iso_a3: "SYR",
        iso_a3_eh: "SYR",
        iso_n3: "760",
        iso_n3_eh: "760",
        un_a3: "760",
        wb_a2: "SY",
        wb_a3: "SYR",
        woe_id: 23424956,
        woe_id_eh: 23424956,
        woe_note: "Exact WOE match as country",
        adm0_iso: "SYR",
        adm0_diff: null,
        adm0_tlc: "SYR",
        adm0_a3_us: "SYR",
        adm0_a3_fr: "SYR",
        adm0_a3_ru: "SYR",
        adm0_a3_es: "SYR",
        adm0_a3_cn: "SYR",
        adm0_a3_tw: "SYR",
        adm0_a3_in: "SYR",
        adm0_a3_np: "SYR",
        adm0_a3_pk: "SYR",
        adm0_a3_de: "SYR",
        adm0_a3_gb: "SYR",
        adm0_a3_br: "SYR",
        adm0_a3_il: "SYR",
        adm0_a3_ps: "SYR",
        adm0_a3_sa: "SYR",
        adm0_a3_eg: "SYR",
        adm0_a3_ma: "SYR",
        adm0_a3_pt: "SYR",
        adm0_a3_ar: "SYR",
        adm0_a3_jp: "SYR",
        adm0_a3_ko: "SYR",
        adm0_a3_vn: "SYR",
        adm0_a3_tr: "SYR",
        adm0_a3_id: "SYR",
        adm0_a3_pl: "SYR",
        adm0_a3_gr: "SYR",
        adm0_a3_it: "SYR",
        adm0_a3_nl: "SYR",
        adm0_a3_se: "SYR",
        adm0_a3_bd: "SYR",
        adm0_a3_ua: "SYR",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Western Asia",
        region_wb: "Middle East & North Africa",
        name_len: 5,
        long_len: 5,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: 38.277783,
        label_y: 35.006636,
        ne_id: 1159321295,
        wikidataid: "Q858",
        name_ar: "سوريا",
        name_bn: "সিরিয়া",
        name_de: "Syrien",
        name_en: "Syria",
        name_es: "Siria",
        name_fa: "سوریه",
        name_fr: "Syrie",
        name_el: "Συρία",
        name_he: "סוריה",
        name_hi: "सीरिया",
        name_hu: "Szíria",
        name_id: "Suriah",
        name_it: "Siria",
        name_ja: "シリア",
        name_ko: "시리아",
        name_nl: "Syrië",
        name_pl: "Syria",
        name_pt: "Síria",
        name_ru: "Сирия",
        name_sv: "Syrien",
        name_tr: "Suriye",
        name_uk: "Сирія",
        name_ur: "سوریہ",
        name_vi: "Syria",
        name_zh: "叙利亚",
        name_zht: "敘利亞",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "SYR.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [35.892675781250006, 35.916552734374996],
            [35.96757812500002, 35.910058593749994],
            [36.12734375000002, 35.831445312499994],
            [36.15361328125002, 35.833886718749994],
            [36.20195312500002, 35.937548828124996],
            [36.24882812500002, 35.972705078124996],
            [36.34755859375002, 36.003515625],
            [36.37539062500002, 36.171240234375],
            [36.421484375, 36.203466796875],
            [36.47705078125, 36.220703125],
            [36.56240234375002, 36.223925781249996],
            [36.63671875, 36.233984375],
            [36.64140625000002, 36.263525390625],
            [36.5375, 36.457421875],
            [36.54667968750002, 36.50634765625],
            [36.596875, 36.7013671875],
            [36.62841796875, 36.777685546875],
            [36.65859375000002, 36.802539062499996],
            [36.77656250000001, 36.79267578125],
            [36.94179687500002, 36.7583984375],
            [36.9853515625, 36.702392578125],
            [37.06621093750002, 36.652636718749996],
            [37.18740234375002, 36.655908203124994],
            [37.32705078125002, 36.64658203125],
            [37.43632812500002, 36.643310546875],
            [37.523535156250006, 36.6783203125],
            [37.7203125, 36.743701171874996],
            [37.81796875, 36.765576171875],
            [37.90664062500002, 36.79462890625],
            [38.19169921875002, 36.9015625],
            [38.30585937500001, 36.893359375],
            [38.38398437500001, 36.879248046875],
            [38.44375, 36.862255859375],
            [38.57802734375002, 36.789111328124996],
            [38.68886718750002, 36.715087890625],
            [38.7666015625, 36.693115234375],
            [38.90644531250001, 36.694677734375],
            [39.1083984375, 36.680566406249994],
            [39.35664062500001, 36.681591796875],
            [39.50146484375, 36.70224609375],
            [39.6865234375, 36.738623046875],
            [40.01640625000002, 36.826074218749994],
            [40.45039062500001, 37.00888671875],
            [40.705664062500006, 37.097705078124996],
            [40.815625, 37.108154296875],
            [40.95888671875002, 37.109179687499996],
            [41.102148437500006, 37.085888671875],
            [41.2646484375, 37.0693359375],
            [41.33955078125001, 37.07080078125],
            [41.51552734375002, 37.089160156249996],
            [41.74355468750002, 37.126123046874994],
            [41.886816406250006, 37.156396484374994],
            [42.05986328125002, 37.2060546875],
            [42.16787109375002, 37.288623046874996],
            [42.202734375, 37.297265625],
            [42.24755859375, 37.2822265625],
            [42.2685546875, 37.2765625],
            [42.31289062500002, 37.22958984375],
            [42.358984375, 37.10859375],
            [42.35908203125001, 37.095019531249996],
            [42.35009765625, 37.060595703124996],
            [42.2373046875, 36.9611328125],
            [42.083984375, 36.826025390625],
            [41.97402343750002, 36.7408203125],
            [41.78857421875, 36.59716796875],
            [41.65019531250002, 36.56640625],
            [41.41679687500002, 36.5146484375],
            [41.35419921875001, 36.464404296874996],
            [41.295996093750006, 36.383349609374996],
            [41.261816406250006, 36.2724609375],
            [41.25175781250002, 36.203027343749994],
            [41.24560546875, 36.073388671874994],
            [41.30019531250002, 35.93896484375],
            [41.352636718750006, 35.809960937499994],
            [41.359375, 35.724609375],
            [41.354101562500006, 35.640429687499996],
            [41.303320312500006, 35.550634765625],
            [41.24833984375002, 35.427490234375],
            [41.21640625, 35.28818359375],
            [41.19960937500002, 35.027392578124996],
            [41.19921875, 34.805322265624994],
            [41.19472656250002, 34.768994140625],
            [41.09902343750002, 34.6123046875],
            [40.98701171875001, 34.429052734375],
            [40.93505859375, 34.386572265625],
            [40.689453125, 34.33203125],
            [40.421484375, 34.19775390625],
            [40.12197265625002, 34.04765625],
            [39.85, 33.911376953125],
            [39.564453125, 33.768359375],
            [39.26835937500002, 33.620019531249994],
            [39.056738281250006, 33.514013671875],
            [38.773535156250006, 33.372216796874994],
            [38.515625, 33.236621093749996],
            [38.25429687500002, 33.09921875],
            [38.055761718750006, 32.994873046875],
            [37.75410156250001, 32.829833984375],
            [37.57744140625002, 32.733056640624994],
            [37.31757812500001, 32.590771484375],
            [37.088964843750006, 32.46552734375],
            [36.818359375, 32.317285156249994],
            [36.47919921875001, 32.361328125],
            [36.3720703125, 32.3869140625],
            [36.284277343750006, 32.457470703125],
            [36.2197265625, 32.4951171875],
            [36.05947265625002, 32.5337890625],
            [35.95644531250002, 32.66669921875],
            [35.89472656250001, 32.713769531249994],
            [35.78730468750001, 32.734912109374996],
            [35.83328348669164, 32.8200940242287],
            [35.841862937735286, 32.87595371086475],
            [35.88797748709491, 32.94437888348711],
            [35.85902183982259, 32.98936659098219],
            [35.84508023187666, 33.08466471531837],
            [35.82470403564798, 33.10802397833988],
            [35.817197015984796, 33.133173164418636],
            [35.83328348669164, 33.16370177763126],
            [35.8268488984089, 33.19511719176682],
            [35.80861756494114, 33.20857749231844],
            [35.80003811389749, 33.256120642614626],
            [35.785024074571105, 33.29108871216229],
            [35.7925310942343, 33.33410710518994],
            [35.81612458460434, 33.36187853338469],
            [35.84073774592113, 33.41566091565706],
            [35.869140625, 33.43173828125],
            [35.91474609375001, 33.465380859374996],
            [35.92656250000002, 33.500292968749996],
            [35.96757812500002, 33.534570312499994],
            [36.02226562500002, 33.5625],
            [36.03447265625002, 33.58505859375],
            [36.02666015625002, 33.597949218749996],
            [35.9716796875, 33.623095703124996],
            [35.9423828125, 33.667578125],
            [35.96845703125001, 33.732421875],
            [35.98613281250002, 33.75263671875],
            [36.01884765625002, 33.783935546875],
            [36.09218750000002, 33.831591796874996],
            [36.14980468750002, 33.839501953124994],
            [36.19941406250001, 33.83955078125],
            [36.28339843750001, 33.835595703124994],
            [36.34853515625002, 33.827050781249994],
            [36.36503906250002, 33.83935546875],
            [36.36279296875, 33.855126953124994],
            [36.2822265625, 33.894189453124994],
            [36.27783203125, 33.92529296875],
            [36.2978515625, 33.958642578124994],
            [36.35488281250002, 34.011328125],
            [36.4228515625, 34.049853515624996],
            [36.45751953125, 34.0568359375],
            [36.53515625, 34.134326171874996],
            [36.5849609375, 34.221240234374996],
            [36.50439453125, 34.432373046875],
            [36.45556640625, 34.466162109375],
            [36.37646484375, 34.495166015624996],
            [36.32988281250002, 34.499609375],
            [36.326269531250006, 34.513330078124994],
            [36.388671875, 34.56689453125],
            [36.43300781250002, 34.6134765625],
            [36.383886718750006, 34.65791015625],
            [36.29628906250002, 34.6787109375],
            [36.26357421875002, 34.632861328124996],
            [36.15107421875001, 34.62861328125],
            [35.97626953125001, 34.629199218749996],
            [35.89931640625002, 34.852099609374996],
            [35.88789062500001, 34.948632812499994],
            [35.88994140625002, 35.060302734375],
            [35.94306640625001, 35.223828125],
            [35.918066406250006, 35.29951171875],
            [35.916015625, 35.350537109375],
            [35.902441406250006, 35.420703125],
            [35.76445312500002, 35.571582031249996],
            [35.83964843750002, 35.84921875],
            [35.892675781250006, 35.916552734374996],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 2,
        sovereignt: "South Korea",
        sov_a3: "KOR",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "South Korea",
        adm0_a3: "KOR",
        geou_dif: 0,
        geounit: "South Korea",
        gu_a3: "KOR",
        su_dif: 0,
        subunit: "South Korea",
        su_a3: "KOR",
        brk_diff: 0,
        name: "South Korea",
        name_long: "Republic of Korea",
        brk_a3: "KOR",
        brk_name: "Republic of Korea",
        brk_group: null,
        abbrev: "S.K.",
        postal: "KR",
        formal_en: "Republic of Korea",
        formal_fr: null,
        name_ciawf: "Korea, South",
        note_adm0: null,
        note_brk: null,
        name_sort: "Korea, Rep.",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 1,
        mapcolor9: 1,
        mapcolor13: 5,
        pop_est: 51709098,
        pop_rank: 16,
        pop_year: 2019,
        gdp_md: 1646739,
        gdp_year: 2019,
        economy: "4. Emerging region: MIKT",
        income_grp: "1. High income: OECD",
        fips_10: "KS",
        iso_a2: "KR",
        iso_a2_eh: "KR",
        iso_a3: "KOR",
        iso_a3_eh: "KOR",
        iso_n3: "410",
        iso_n3_eh: "410",
        un_a3: "410",
        wb_a2: "KR",
        wb_a3: "KOR",
        woe_id: 23424868,
        woe_id_eh: 23424868,
        woe_note: "Exact WOE match as country",
        adm0_iso: "KOR",
        adm0_diff: null,
        adm0_tlc: "KOR",
        adm0_a3_us: "KOR",
        adm0_a3_fr: "KOR",
        adm0_a3_ru: "KOR",
        adm0_a3_es: "KOR",
        adm0_a3_cn: "KOR",
        adm0_a3_tw: "KOR",
        adm0_a3_in: "KOR",
        adm0_a3_np: "KOR",
        adm0_a3_pk: "KOR",
        adm0_a3_de: "KOR",
        adm0_a3_gb: "KOR",
        adm0_a3_br: "KOR",
        adm0_a3_il: "KOR",
        adm0_a3_ps: "KOR",
        adm0_a3_sa: "KOR",
        adm0_a3_eg: "KOR",
        adm0_a3_ma: "KOR",
        adm0_a3_pt: "KOR",
        adm0_a3_ar: "KOR",
        adm0_a3_jp: "KOR",
        adm0_a3_ko: "KOR",
        adm0_a3_vn: "KOR",
        adm0_a3_tr: "KOR",
        adm0_a3_id: "KOR",
        adm0_a3_pl: "KOR",
        adm0_a3_gr: "KOR",
        adm0_a3_it: "KOR",
        adm0_a3_nl: "KOR",
        adm0_a3_se: "KOR",
        adm0_a3_bd: "KOR",
        adm0_a3_ua: "KOR",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Eastern Asia",
        region_wb: "East Asia & Pacific",
        name_len: 11,
        long_len: 17,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 2.5,
        max_label: 7,
        label_x: 128.129504,
        label_y: 36.384924,
        ne_id: 1159320985,
        wikidataid: "Q884",
        name_ar: "كوريا الجنوبية",
        name_bn: "দক্ষিণ কোরিয়া",
        name_de: "Südkorea",
        name_en: "South Korea",
        name_es: "Corea del Sur",
        name_fa: "کره جنوبی",
        name_fr: "Corée du Sud",
        name_el: "Νότια Κορέα",
        name_he: "קוריאה הדרומית",
        name_hi: "दक्षिण कोरिया",
        name_hu: "Dél-Korea",
        name_id: "Korea Selatan",
        name_it: "Corea del Sud",
        name_ja: "大韓民国",
        name_ko: "대한민국",
        name_nl: "Zuid-Korea",
        name_pl: "Korea Południowa",
        name_pt: "Coreia do Sul",
        name_ru: "Республика Корея",
        name_sv: "Sydkorea",
        name_tr: "Güney Kore",
        name_uk: "Південна Корея",
        name_ur: "جنوبی کوریا",
        name_vi: "Hàn Quốc",
        name_zh: "大韩民国",
        name_zht: "大韓民國",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "KOR.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [126.63388671875003, 37.7818359375],
              [126.66455078125, 37.800732421875],
              [126.66650390625, 37.827929687499996],
              [126.66679687499999, 37.9171875],
              [126.75429687500002, 37.978955078125],
              [126.87890625, 38.1060546875],
              [126.94003906250003, 38.175585937499996],
              [127.00966796875002, 38.240527343749996],
              [127.09033203125, 38.28388671875],
              [127.16953125000003, 38.304541015625],
              [127.29404296875003, 38.31328125],
              [127.53271484375, 38.304980468749996],
              [127.57949218750002, 38.3125],
              [127.74550781250002, 38.319238281249994],
              [127.78466796875, 38.30771484375],
              [127.9052734375, 38.300439453124994],
              [128.03896484375002, 38.308544921875],
              [128.10625, 38.32734375],
              [128.16865234375, 38.359326171875],
              [128.22314453125, 38.4169921875],
              [128.279296875, 38.523779296875],
              [128.33945312500003, 38.607861328125],
              [128.37460937500003, 38.6234375],
              [128.61884765625, 38.176074218749996],
              [128.85244140625002, 37.887060546875],
              [129.05156250000005, 37.677636718749994],
              [129.33515625, 37.274560546874994],
              [129.41826171875005, 37.059033203125],
              [129.42617187500002, 36.925537109375],
              [129.47343750000005, 36.74189453125],
              [129.43300781250002, 36.636621093749994],
              [129.44501953125, 36.470703125],
              [129.42714843750002, 36.385498046875],
              [129.392578125, 36.322705078125],
              [129.39130859375, 36.2021484375],
              [129.40244140625003, 36.137646484375],
              [129.403515625, 36.052148437499994],
              [129.42578125, 36.018798828125],
              [129.45830078125005, 36.0064453125],
              [129.509765625, 36.03759765625],
              [129.57285156250003, 36.050537109375],
              [129.56171875, 35.94765625],
              [129.48544921875003, 35.687402343749994],
              [129.419140625, 35.497851562499996],
              [129.32900390625002, 35.332763671875],
              [129.21416015625005, 35.1818359375],
              [129.07675781250003, 35.122705078124994],
              [128.98007812500003, 35.101513671875],
              [128.79570312500005, 35.093896484374994],
              [128.642578125, 35.119580078125],
              [128.5109375, 35.100976562499994],
              [128.45810546875003, 35.06943359375],
              [128.41884765625002, 35.015673828124996],
              [128.44765625000002, 34.932080078125],
              [128.44394531250003, 34.870361328125],
              [128.3876953125, 34.87509765625],
              [128.27597656250003, 34.910986328125],
              [128.15234375, 34.915869140625],
              [128.09453125000005, 34.93359375],
              [128.03623046875003, 35.02197265625],
              [127.97675781250001, 35.018701171874994],
              [127.87324218750001, 34.96630859375],
              [127.71484375, 34.9546875],
              [127.65908203125002, 34.9263671875],
              [127.63935546875001, 34.889697265624996],
              [127.6625, 34.843408203124994],
              [127.7421875, 34.782568359375],
              [127.71542968750003, 34.721044921875],
              [127.63242187500003, 34.690234375],
              [127.5654296875, 34.765917968749996],
              [127.52363281250001, 34.840087890625],
              [127.47695312500002, 34.844287109374996],
              [127.404296875, 34.823095703125],
              [127.3896484375, 34.743017578125],
              [127.42343750000003, 34.6884765625],
              [127.47910156250003, 34.625244140625],
              [127.40117187500005, 34.552539062499996],
              [127.38056640625001, 34.500634765624994],
              [127.32460937500002, 34.46328125],
              [127.17343750000003, 34.546142578125],
              [127.19492187500003, 34.605029296874996],
              [127.26054687500005, 34.661669921874996],
              [127.26865234375003, 34.720361328124994],
              [127.2470703125, 34.755126953125],
              [127.03076171875, 34.606884765625],
              [126.8974609375, 34.438867187499994],
              [126.82626953125003, 34.451074218749994],
              [126.79648437500003, 34.494287109374994],
              [126.75478515625002, 34.511865234374994],
              [126.61083984375, 34.403515625],
              [126.584375, 34.317529296874994],
              [126.53144531250001, 34.314257812499996],
              [126.50830078125, 34.350634765624996],
              [126.50644531250003, 34.428369140624994],
              [126.48173828124999, 34.493945312499996],
              [126.33261718750003, 34.589648437499996],
              [126.26445312499999, 34.673242187499994],
              [126.30107421874999, 34.719970703125],
              [126.42558593749999, 34.694580078125],
              [126.52451171875003, 34.697900390624994],
              [126.50498046875003, 34.737548828125],
              [126.47285156250001, 34.75634765625],
              [126.53857421875, 34.778662109375],
              [126.59335937500003, 34.824365234374994],
              [126.54794921875003, 34.836767578125],
              [126.478515625, 34.810351562499996],
              [126.42070312499999, 34.823388671874994],
              [126.39785156250002, 34.9328125],
              [126.32744140624999, 35.0451171875],
              [126.29111328125003, 35.154150390625],
              [126.36054687500001, 35.21689453125],
              [126.39589843750002, 35.314404296875],
              [126.46044921875, 35.455615234374996],
              [126.49277343750003, 35.501269531249996],
              [126.58222656250001, 35.534472656249996],
              [126.61406249999999, 35.57099609375],
              [126.56494140625, 35.589746093749994],
              [126.48652343750001, 35.606347656249994],
              [126.48847656250001, 35.6470703125],
              [126.54189453125002, 35.669335937499994],
              [126.6015625, 35.714208984375],
              [126.71738281250003, 35.768847656249996],
              [126.75302734375003, 35.871972656249994],
              [126.71962890625002, 35.897900390625],
              [126.6474609375, 35.922412109374996],
              [126.66367187500003, 35.974511718749994],
              [126.69345703125003, 36.01416015625],
              [126.68232421875001, 36.037939453125],
              [126.59707031250002, 36.105029296874996],
              [126.54042968750002, 36.166162109374994],
              [126.55722656250003, 36.23583984375],
              [126.54423828124999, 36.341210937499994],
              [126.55195312500001, 36.4296875],
              [126.54824218750002, 36.47763671875],
              [126.50664062499999, 36.58564453125],
              [126.48769531250002, 36.693798828125],
              [126.43300781250002, 36.678027343749996],
              [126.38876953125003, 36.651171875],
              [126.23066406250001, 36.689257812499996],
              [126.18085937500001, 36.6916015625],
              [126.16054687500002, 36.771923828125],
              [126.21718750000002, 36.870947265625],
              [126.3515625, 36.958203125],
              [126.4287109375, 36.969042968749996],
              [126.48701171875001, 37.007470703124994],
              [126.57773437500003, 37.019580078124996],
              [126.68671875000001, 36.960351562499994],
              [126.78447265624999, 36.9484375],
              [126.83876953125002, 36.84609375],
              [126.8720703125, 36.824462890625],
              [126.87910156250001, 36.862060546875],
              [126.9580078125, 36.906152343749994],
              [126.97685546874999, 36.939404296875],
              [126.95976562499999, 36.9576171875],
              [126.86894531249999, 36.975732421874994],
              [126.78740234374999, 37.102734375],
              [126.77607421875001, 37.158203125],
              [126.74638671874999, 37.1935546875],
              [126.79052734375, 37.294921875],
              [126.69619140625002, 37.410693359374996],
              [126.65029296875002, 37.447119140625],
              [126.65683593750003, 37.551171875],
              [126.60761718750001, 37.617431640625],
              [126.58017578125003, 37.653759765625],
              [126.56337890625002, 37.71650390625],
              [126.57773437500003, 37.7447265625],
              [126.62070312500003, 37.75546875],
              [126.63388671875003, 37.7818359375],
            ],
          ],
          [
            [
              [128.74101562500005, 34.79853515625],
              [128.6466796875, 34.736865234374996],
              [128.51953125, 34.819580078125],
              [128.4892578125, 34.865283203124996],
              [128.5859375, 34.932275390624994],
              [128.66796875, 35.0087890625],
              [128.721875, 35.013574218749994],
              [128.74101562500005, 34.79853515625],
            ],
          ],
          [
            [
              [128.06582031250002, 34.805859375],
              [128.0546875, 34.708056640624996],
              [127.98398437500003, 34.70322265625],
              [127.94179687500002, 34.766259765624994],
              [127.896875, 34.735498046874994],
              [127.87343750000002, 34.7349609375],
              [127.83828125000002, 34.813330078125],
              [127.83222656250001, 34.87451171875],
              [127.91542968750002, 34.92099609375],
              [127.965625, 34.893017578125],
              [128.03798828125002, 34.87861328125],
              [128.06582031250002, 34.805859375],
            ],
          ],
          [
            [
              [126.23369140624999, 34.370507812499994],
              [126.16972656249999, 34.35517578125],
              [126.1337890625, 34.389599609375],
              [126.10859375000001, 34.398730468749996],
              [126.12285156249999, 34.4439453125],
              [126.22705078125, 34.53271484375],
              [126.24746093750002, 34.563330078125],
              [126.34384765625003, 34.544921875],
              [126.3798828125, 34.497949218749994],
              [126.33544921875, 34.426416015624994],
              [126.23369140624999, 34.370507812499994],
            ],
          ],
          [
            [
              [126.52070312500001, 37.73681640625],
              [126.51601562500002, 37.6046875],
              [126.46083984375002, 37.6103515625],
              [126.42333984375, 37.6236328125],
              [126.4072265625, 37.6494140625],
              [126.36933593750001, 37.772021484374996],
              [126.41162109375, 37.82265625],
              [126.49355468750002, 37.782568359375],
              [126.52070312500001, 37.73681640625],
            ],
          ],
          [
            [
              [126.75302734375003, 34.343994140625],
              [126.76992187500002, 34.296435546874996],
              [126.68906250000003, 34.305419921875],
              [126.64609375000003, 34.351123046874996],
              [126.65185546875, 34.39033203125],
              [126.7, 34.395898437499994],
              [126.75302734375003, 34.343994140625],
            ],
          ],
          [
            [
              [127.79902343750001, 34.615039062499996],
              [127.78779296875001, 34.58408203125],
              [127.7373046875, 34.630908203124996],
              [127.787109375, 34.68212890625],
              [127.79902343750001, 34.615039062499996],
            ],
          ],
          [
            [
              [126.41757812500003, 36.492578125],
              [126.40380859375, 36.427880859374994],
              [126.3375, 36.470556640625],
              [126.31855468750001, 36.612548828125],
              [126.38662109375002, 36.571142578125],
              [126.41757812500003, 36.492578125],
            ],
          ],
          [
            [
              [126.17197265625003, 34.73115234375],
              [126.15878906250003, 34.706982421875],
              [126.115234375, 34.714208984375],
              [126.07060546874999, 34.783056640625],
              [126.05205078124999, 34.837548828124994],
              [126.00751953125001, 34.867480468749996],
              [126.07841796874999, 34.91484375],
              [126.16855468750003, 34.8296875],
              [126.17197265625003, 34.73115234375],
            ],
          ],
          [
            [
              [130.916015625, 37.478466796875],
              [130.87060546875, 37.44873046875],
              [130.81679687500002, 37.478466796875],
              [130.81025390625, 37.509912109374994],
              [130.83837890625, 37.53720703125],
              [130.90371093750002, 37.5537109375],
              [130.93427734375, 37.529736328125],
              [130.916015625, 37.478466796875],
            ],
          ],
          [
            [
              [126.32695312499999, 33.2236328125],
              [126.28203124999999, 33.201513671875],
              [126.240234375, 33.21484375],
              [126.22900390625, 33.225244140624994],
              [126.1787109375, 33.282568359375],
              [126.165625, 33.31201171875],
              [126.19941406250001, 33.368066406249994],
              [126.33769531249999, 33.460400390625],
              [126.69550781250001, 33.54931640625],
              [126.75986328125003, 33.55322265625],
              [126.90117187499999, 33.51513671875],
              [126.93125, 33.44384765625],
              [126.90537109375003, 33.382373046874996],
              [126.87285156249999, 33.341162109375],
              [126.70917968750001, 33.2716796875],
              [126.58173828125001, 33.238330078124996],
              [126.32695312499999, 33.2236328125],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "North Korea",
        sov_a3: "PRK",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "North Korea",
        adm0_a3: "PRK",
        geou_dif: 0,
        geounit: "North Korea",
        gu_a3: "PRK",
        su_dif: 0,
        subunit: "North Korea",
        su_a3: "PRK",
        brk_diff: 0,
        name: "North Korea",
        name_long: "Dem. Rep. Korea",
        brk_a3: "PRK",
        brk_name: "Dem. Rep. Korea",
        brk_group: null,
        abbrev: "N.K.",
        postal: "KP",
        formal_en: "Democratic People's Republic of Korea",
        formal_fr: null,
        name_ciawf: "Korea, North",
        note_adm0: null,
        note_brk: null,
        name_sort: "Korea, Dem. Rep.",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 5,
        mapcolor9: 3,
        mapcolor13: 9,
        pop_est: 25666161,
        pop_rank: 15,
        pop_year: 2019,
        gdp_md: 40000,
        gdp_year: 2016,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        fips_10: "KN",
        iso_a2: "KP",
        iso_a2_eh: "KP",
        iso_a3: "PRK",
        iso_a3_eh: "PRK",
        iso_n3: "408",
        iso_n3_eh: "408",
        un_a3: "408",
        wb_a2: "KP",
        wb_a3: "PRK",
        woe_id: 23424865,
        woe_id_eh: 23424865,
        woe_note: "Exact WOE match as country",
        adm0_iso: "PRK",
        adm0_diff: null,
        adm0_tlc: "PRK",
        adm0_a3_us: "PRK",
        adm0_a3_fr: "PRK",
        adm0_a3_ru: "PRK",
        adm0_a3_es: "PRK",
        adm0_a3_cn: "PRK",
        adm0_a3_tw: "PRK",
        adm0_a3_in: "PRK",
        adm0_a3_np: "PRK",
        adm0_a3_pk: "PRK",
        adm0_a3_de: "PRK",
        adm0_a3_gb: "PRK",
        adm0_a3_br: "PRK",
        adm0_a3_il: "PRK",
        adm0_a3_ps: "PRK",
        adm0_a3_sa: "PRK",
        adm0_a3_eg: "PRK",
        adm0_a3_ma: "PRK",
        adm0_a3_pt: "PRK",
        adm0_a3_ar: "PRK",
        adm0_a3_jp: "PRK",
        adm0_a3_ko: "PRK",
        adm0_a3_vn: "PRK",
        adm0_a3_tr: "PRK",
        adm0_a3_id: "PRK",
        adm0_a3_pl: "PRK",
        adm0_a3_gr: "PRK",
        adm0_a3_it: "PRK",
        adm0_a3_nl: "PRK",
        adm0_a3_se: "PRK",
        adm0_a3_bd: "PRK",
        adm0_a3_ua: "PRK",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Eastern Asia",
        region_wb: "East Asia & Pacific",
        name_len: 11,
        long_len: 15,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: 126.444516,
        label_y: 39.885252,
        ne_id: 1159321181,
        wikidataid: "Q423",
        name_ar: "كوريا الشمالية",
        name_bn: "উত্তর কোরিয়া",
        name_de: "Nordkorea",
        name_en: "North Korea",
        name_es: "Corea del Norte",
        name_fa: "کره شمالی",
        name_fr: "Corée du Nord",
        name_el: "Βόρεια Κορέα",
        name_he: "קוריאה הצפונית",
        name_hi: "उत्तर कोरिया",
        name_hu: "Észak-Korea",
        name_id: "Korea Utara",
        name_it: "Corea del Nord",
        name_ja: "朝鮮民主主義人民共和国",
        name_ko: "조선민주주의인민공화국",
        name_nl: "Noord-Korea",
        name_pl: "Korea Północna",
        name_pt: "Coreia do Norte",
        name_ru: "КНДР",
        name_sv: "Nordkorea",
        name_tr: "Kuzey Kore",
        name_uk: "Корейська Народно-Демократична Республіка",
        name_ur: "شمالی کوریا",
        name_vi: "Cộng hòa Dân chủ Nhân dân Triều Tiên",
        name_zh: "朝鲜民主主义人民共和国",
        name_zht: "朝鮮民主主義人民共和國",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "PRK.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [128.37460937500003, 38.6234375],
              [128.33945312500003, 38.607861328125],
              [128.279296875, 38.523779296875],
              [128.22314453125, 38.4169921875],
              [128.16865234375, 38.359326171875],
              [128.10625, 38.32734375],
              [128.03896484375002, 38.308544921875],
              [127.9052734375, 38.300439453124994],
              [127.78466796875, 38.30771484375],
              [127.74550781250002, 38.319238281249994],
              [127.57949218750002, 38.3125],
              [127.53271484375, 38.304980468749996],
              [127.29404296875003, 38.31328125],
              [127.16953125000003, 38.304541015625],
              [127.09033203125, 38.28388671875],
              [127.00966796875002, 38.240527343749996],
              [126.94003906250003, 38.175585937499996],
              [126.87890625, 38.1060546875],
              [126.75429687500002, 37.978955078125],
              [126.66679687499999, 37.9171875],
              [126.66650390625, 37.827929687499996],
              [126.66455078125, 37.800732421875],
              [126.63388671875003, 37.7818359375],
              [126.62324218750001, 37.790185546874994],
              [126.57275390625, 37.796826171875],
              [126.36992187499999, 37.878369140625],
              [126.203125, 37.828515625],
              [126.16103515625002, 37.763720703124996],
              [126.11669921875, 37.742919921875],
              [126.05029296875, 37.86982421875],
              [125.94169921874999, 37.873681640624994],
              [125.76914062500003, 37.9853515625],
              [125.69501953125001, 37.962695312499996],
              [125.67617187500002, 37.917724609375],
              [125.58154296875, 37.8150390625],
              [125.44931640625003, 37.730224609375],
              [125.40664062500002, 37.719042968749996],
              [125.35781250000002, 37.7248046875],
              [125.36484375000003, 37.7482421875],
              [125.31074218750001, 37.843505859375],
              [125.10195312500002, 37.882080078125],
              [125.02675781250002, 37.922607421875],
              [124.98876953125, 37.931445312499996],
              [125.19316406249999, 38.03779296875],
              [125.24667968750003, 38.0568359375],
              [125.20673828125001, 38.08154296875],
              [125.16259765625, 38.093652343749994],
              [124.99501953125002, 38.07783203125],
              [124.90703124999999, 38.112646484375],
              [124.77949218750001, 38.101513671875],
              [124.69091796875, 38.129199218749996],
              [124.87451171875, 38.2333984375],
              [124.88271484375002, 38.294970703124996],
              [124.88056640625001, 38.341650390625],
              [124.97373046875003, 38.480126953124994],
              [125.0673828125, 38.55673828125],
              [125.30966796875003, 38.665380859375],
              [125.41533203124999, 38.680419921875],
              [125.49179687500003, 38.676123046875],
              [125.55449218749999, 38.68623046875],
              [125.48867187500002, 38.727783203125],
              [125.42421875000002, 38.746875],
              [125.29892578125003, 38.74296875],
              [125.16884765625002, 38.805517578125],
              [125.15732421875003, 38.871533203125],
              [125.40966796875, 39.288378906249996],
              [125.41318359375003, 39.32626953125],
              [125.37363281250003, 39.427636718749994],
              [125.36083984375, 39.526611328125],
              [125.18007812500002, 39.58349609375],
              [125.10009765625, 39.59033203125],
              [124.86787109375001, 39.701806640624994],
              [124.77529296875002, 39.758056640625],
              [124.73886718750003, 39.741503906249996],
              [124.73222656249999, 39.652197265625],
              [124.69921875, 39.632373046874996],
              [124.63828125000003, 39.615087890625],
              [124.60761718750001, 39.716943359374994],
              [124.55742187499999, 39.790576171874996],
              [124.40380859375, 39.865527343749996],
              [124.3486328125, 39.906884765624994],
              [124.37509765625003, 39.996142578124996],
              [124.36210937499999, 40.004052734374994],
              [124.38662109375002, 40.104248046875],
              [124.48105468750003, 40.181640625],
              [124.71240234375, 40.319238281249994],
              [124.77197265625, 40.383740234375],
              [124.88935546875001, 40.459814453125],
              [124.94228515625002, 40.458154296874994],
              [124.996875, 40.464746093749994],
              [125.01337890625001, 40.497851562499996],
              [125.02597656250003, 40.523876953125],
              [125.07294921875001, 40.5474609375],
              [125.18593750000002, 40.589404296874996],
              [125.314453125, 40.64462890625],
              [125.41689453125002, 40.659912109375],
              [125.54257812500003, 40.742578125],
              [125.59384765625003, 40.778955078124994],
              [125.64511718750003, 40.778955078124994],
              [125.6591796875, 40.7958984375],
              [125.68828124999999, 40.838671875],
              [125.72832031249999, 40.86669921875],
              [125.78398437499999, 40.872021484375],
              [125.87490234375002, 40.892236328124994],
              [125.98906249999999, 40.904638671875],
              [126.06679687500002, 40.974072265625],
              [126.09316406250002, 41.023681640625],
              [126.14453125, 41.078271484374994],
              [126.25361328125001, 41.13779296875],
              [126.32871093750003, 41.22568359375],
              [126.41181640625001, 41.321337890624996],
              [126.45146484374999, 41.351855468749996],
              [126.49042968750001, 41.358056640624994],
              [126.51357421875002, 41.393994140625],
              [126.54013671875003, 41.495556640625],
              [126.57832031250001, 41.5943359375],
              [126.60126953125001, 41.640966796875],
              [126.69697265625001, 41.69189453125],
              [126.72158203125002, 41.716552734375],
              [126.74306640625002, 41.724853515625],
              [126.78769531250003, 41.718212890625],
              [126.84726562500003, 41.747998046875],
              [126.90351562500001, 41.781054687499996],
              [126.95478515625001, 41.769482421875],
              [127.00693359375003, 41.742041015625],
              [127.06132812499999, 41.687353515625],
              [127.08535156250002, 41.643798828125],
              [127.12841796875, 41.607421875],
              [127.13671875, 41.554541015625],
              [127.1796875, 41.53134765625],
              [127.27080078125005, 41.519824218749996],
              [127.42031250000002, 41.483789062499994],
              [127.51699218750002, 41.481738281249996],
              [127.57216796875002, 41.454736328124994],
              [127.68769531250001, 41.439990234374996],
              [127.91865234375001, 41.4611328125],
              [128.01308593750002, 41.448681640625],
              [128.052734375, 41.415625],
              [128.11123046875002, 41.3892578125],
              [128.1494140625, 41.387744140624996],
              [128.20029296875003, 41.433007812499994],
              [128.2548828125, 41.50654296875],
              [128.29091796875002, 41.562792968749996],
              [128.2892578125, 41.607421875],
              [128.2578125, 41.65537109375],
              [128.18173828125003, 41.700048828125],
              [128.13193359375003, 41.769140625],
              [128.0841796875, 41.840576171875],
              [128.05605468750002, 41.86376953125],
              [128.03291015625, 41.898486328124996],
              [128.02871093750002, 41.951611328125],
              [128.04521484375005, 41.9875],
              [128.16015625, 42.011621093749994],
              [128.3078125, 42.025634765625],
              [128.42724609375, 42.0107421875],
              [128.62675781250005, 42.020849609375],
              [128.7490234375, 42.040673828124994],
              [128.83984375, 42.037841796875],
              [128.92343750000003, 42.038232421874994],
              [128.96064453125, 42.068798828125],
              [129.07724609375003, 42.142382812499996],
              [129.13369140625002, 42.168505859374996],
              [129.1955078125, 42.21845703125],
              [129.20537109375005, 42.270556640624996],
              [129.2177734375, 42.3126953125],
              [129.25253906250003, 42.357861328125],
              [129.313671875, 42.41357421875],
              [129.36582031250003, 42.439208984375],
              [129.42363281250005, 42.435888671875],
              [129.48486328125, 42.410302734374994],
              [129.52373046875005, 42.384667968749994],
              [129.567578125, 42.39208984375],
              [129.60390625000002, 42.435888671875],
              [129.6279296875, 42.444287109375],
              [129.69785156250003, 42.448144531249994],
              [129.7197265625, 42.475],
              [129.74648437500002, 42.603808593749996],
              [129.7734375, 42.70546875],
              [129.77919921875002, 42.7765625],
              [129.84150390625, 42.89423828125],
              [129.86103515625, 42.965087890625],
              [129.89824218750005, 42.99814453125],
              [129.94121093750005, 42.995654296874996],
              [129.97695312500002, 42.974853515625],
              [130.02226562500005, 42.96259765625],
              [130.08261718750003, 42.974169921874996],
              [130.12480468750005, 42.956005859375],
              [130.15126953125002, 42.91796875],
              [130.24033203125003, 42.891796875],
              [130.24882812500005, 42.872607421874996],
              [130.24667968750003, 42.74482421875],
              [130.29560546875, 42.684960937499994],
              [130.36074218750002, 42.630859375],
              [130.45029296875003, 42.581689453124994],
              [130.4982421875, 42.5705078125],
              [130.52695312500003, 42.535400390625],
              [130.55410156250002, 42.47470703125],
              [130.61796875000005, 42.415625],
              [130.6515625, 42.372509765625],
              [130.65800781250005, 42.327783203124994],
              [130.68730468750005, 42.302539062499996],
              [130.63652343750005, 42.274853515625],
              [130.56923828125002, 42.29169921875],
              [130.45751953125, 42.301708984375],
              [130.31474609375005, 42.214111328125],
              [130.23574218750002, 42.183203125],
              [130.1798828125, 42.096972656249996],
              [130.06826171875002, 42.045751953125],
              [130.00732421875, 41.991162109375],
              [129.92822265625, 41.896728515625],
              [129.87636718750002, 41.805517578125],
              [129.75634765625, 41.712255859375],
              [129.68632812500005, 41.594970703125],
              [129.68242187500005, 41.4943359375],
              [129.758984375, 41.391503906249994],
              [129.7658203125, 41.303857421874994],
              [129.712109375, 41.123681640624994],
              [129.74199218750005, 40.932275390624994],
              [129.70869140625, 40.857324218749994],
              [129.34111328125005, 40.726318359375],
              [129.2451171875, 40.661035156249994],
              [129.10976562500002, 40.491064453125],
              [128.94521484375002, 40.427880859374994],
              [128.84296875, 40.35849609375],
              [128.7013671875, 40.317529296874994],
              [128.61074218750002, 40.197900390624994],
              [128.51123046875, 40.130224609375],
              [128.39296875000002, 40.08896484375],
              [128.30449218750005, 40.0359375],
              [128.10634765625002, 40.032568359375],
              [127.96660156250005, 39.99560546875],
              [127.86708984375002, 39.895947265625],
              [127.56816406250005, 39.781982421875],
              [127.52744140625003, 39.695703125],
              [127.54726562500002, 39.562792968749996],
              [127.54892578125003, 39.461083984374994],
              [127.52285156250002, 39.377392578125],
              [127.45742187500002, 39.4009765625],
              [127.42226562500002, 39.373583984374996],
              [127.38349609375001, 39.296142578125],
              [127.39453125, 39.207910156249994],
              [127.49697265625002, 39.179492187499996],
              [127.58095703125002, 39.14326171875],
              [127.69892578125001, 39.125048828124996],
              [127.7861328125, 39.084130859374994],
              [127.9716796875, 38.897998046874996],
              [128.123046875, 38.81640625],
              [128.1625, 38.7861328125],
              [128.24941406250002, 38.74521484375],
              [128.32949218750002, 38.680908203125],
              [128.37460937500003, 38.6234375],
            ],
          ],
          [
            [
              [124.9052734375, 39.536279296874994],
              [124.84892578124999, 39.507568359375],
              [124.84609375000002, 39.558886718749996],
              [124.88955078125002, 39.602099609374996],
              [124.9345703125, 39.6078125],
              [124.9052734375, 39.536279296874994],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 5,
        sovereignt: "Bhutan",
        sov_a3: "BTN",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Bhutan",
        adm0_a3: "BTN",
        geou_dif: 0,
        geounit: "Bhutan",
        gu_a3: "BTN",
        su_dif: 0,
        subunit: "Bhutan",
        su_a3: "BTN",
        brk_diff: 0,
        name: "Bhutan",
        name_long: "Bhutan",
        brk_a3: "BTN",
        brk_name: "Bhutan",
        brk_group: null,
        abbrev: "Bhutan",
        postal: "BT",
        formal_en: "Kingdom of Bhutan",
        formal_fr: null,
        name_ciawf: "Bhutan",
        note_adm0: null,
        note_brk: null,
        name_sort: "Bhutan",
        name_alt: null,
        mapcolor7: 5,
        mapcolor8: 6,
        mapcolor9: 1,
        mapcolor13: 8,
        pop_est: 763092,
        pop_rank: 11,
        pop_year: 2019,
        gdp_md: 2530,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "4. Lower middle income",
        fips_10: "BT",
        iso_a2: "BT",
        iso_a2_eh: "BT",
        iso_a3: "BTN",
        iso_a3_eh: "BTN",
        iso_n3: "064",
        iso_n3_eh: "064",
        un_a3: "064",
        wb_a2: "BT",
        wb_a3: "BTN",
        woe_id: 23424770,
        woe_id_eh: 23424770,
        woe_note: "Exact WOE match as country",
        adm0_iso: "BTN",
        adm0_diff: null,
        adm0_tlc: "BTN",
        adm0_a3_us: "BTN",
        adm0_a3_fr: "BTN",
        adm0_a3_ru: "BTN",
        adm0_a3_es: "BTN",
        adm0_a3_cn: "BTN",
        adm0_a3_tw: "BTN",
        adm0_a3_in: "BTN",
        adm0_a3_np: "BTN",
        adm0_a3_pk: "BTN",
        adm0_a3_de: "BTN",
        adm0_a3_gb: "BTN",
        adm0_a3_br: "BTN",
        adm0_a3_il: "BTN",
        adm0_a3_ps: "BTN",
        adm0_a3_sa: "BTN",
        adm0_a3_eg: "BTN",
        adm0_a3_ma: "BTN",
        adm0_a3_pt: "BTN",
        adm0_a3_ar: "BTN",
        adm0_a3_jp: "BTN",
        adm0_a3_ko: "BTN",
        adm0_a3_vn: "BTN",
        adm0_a3_tr: "BTN",
        adm0_a3_id: "BTN",
        adm0_a3_pl: "BTN",
        adm0_a3_gr: "BTN",
        adm0_a3_it: "BTN",
        adm0_a3_nl: "BTN",
        adm0_a3_se: "BTN",
        adm0_a3_bd: "BTN",
        adm0_a3_ua: "BTN",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Southern Asia",
        region_wb: "South Asia",
        name_len: 6,
        long_len: 6,
        abbrev_len: 6,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: 90.040294,
        label_y: 27.536685,
        ne_id: 1159320453,
        wikidataid: "Q917",
        name_ar: "بوتان",
        name_bn: "ভুটান",
        name_de: "Bhutan",
        name_en: "Bhutan",
        name_es: "Bután",
        name_fa: "بوتان",
        name_fr: "Bhoutan",
        name_el: "Μπουτάν",
        name_he: "בהוטן",
        name_hi: "भूटान",
        name_hu: "Bhután",
        name_id: "Bhutan",
        name_it: "Bhutan",
        name_ja: "ブータン",
        name_ko: "부탄",
        name_nl: "Bhutan",
        name_pl: "Bhutan",
        name_pt: "Butão",
        name_ru: "Бутан",
        name_sv: "Bhutan",
        name_tr: "Bhutan",
        name_uk: "Бутан",
        name_ur: "بھوٹان",
        name_vi: "Bhutan",
        name_zh: "不丹",
        name_zht: "不丹",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "BTN.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [91.63193359375003, 27.759960937499997],
            [91.62587890625002, 27.7373046875],
            [91.59765625, 27.677001953125],
            [91.57929687500001, 27.61142578125],
            [91.5947265625, 27.557666015624996],
            [91.65810546875002, 27.493603515624997],
            [91.74306640625002, 27.442529296874994],
            [91.85126953125001, 27.438623046874994],
            [91.95097656249999, 27.458300781249996],
            [91.99082031249998, 27.450195312500114],
            [92.044921875, 27.364697265625125],
            [92.08339843750002, 27.290625],
            [92.03115234375002, 27.214306640624997],
            [92.00253906250003, 27.147363281249994],
            [91.99228515625003, 27.099902343749996],
            [91.99863281249998, 27.079296875000068],
            [92.03085937500003, 27.040820312499996],
            [92.06816406249993, 26.975195312499977],
            [92.07343750000001, 26.91484375],
            [92.04970703125002, 26.874853515625],
            [91.99833984374999, 26.85498046875],
            [91.94375, 26.86083984375],
            [91.89863281250001, 26.860058593749997],
            [91.84208984374999, 26.852978515624997],
            [91.75371093749999, 26.830761718750068],
            [91.67158203124995, 26.802001953125057],
            [91.517578125, 26.807324218749997],
            [91.45585937499999, 26.866894531249997],
            [91.4267578125, 26.867089843749994],
            [91.28652343750002, 26.789941406249994],
            [91.13388671875003, 26.803417968749955],
            [90.85576171874999, 26.777734375],
            [90.73964843750002, 26.771679687500068],
            [90.62031250000001, 26.780224609375068],
            [90.55986328124999, 26.796582031249997],
            [90.44765625000002, 26.85078125000001],
            [90.34589843750001, 26.890332031249997],
            [90.24238281250001, 26.854150390624994],
            [90.2060546875, 26.847509765625],
            [90.12294921875002, 26.754589843749997],
            [89.94316406249999, 26.723925781249996],
            [89.76386718750001, 26.701562500000023],
            [89.7109375, 26.713916015624996],
            [89.60996093750003, 26.719433593749997],
            [89.60615234375001, 26.741113281249994],
            [89.60917968749999, 26.76220703125],
            [89.58613281250001, 26.778955078124994],
            [89.54511718750001, 26.79624023437492],
            [89.474609375, 26.803417968749997],
            [89.38417968750002, 26.8265625],
            [89.33212890625003, 26.8486328125],
            [89.14824218749999, 26.816162109375114],
            [89.04091796875002, 26.865039062499996],
            [88.91914062500001, 26.9322265625],
            [88.85761718750001, 26.961474609374996],
            [88.83515624999995, 27.06557617187508],
            [88.81357421875003, 27.099023437499966],
            [88.765625, 27.134228515624997],
            [88.73876953125, 27.175585937499996],
            [88.76035156250003, 27.218115234375],
            [88.88164062499999, 27.2974609375],
            [88.89140624999999, 27.316064453124994],
            [88.94755859374999, 27.464013671874994],
            [89.02548828125003, 27.517871093749996],
            [89.10234374999999, 27.592578125],
            [89.16044921874999, 27.711279296875],
            [89.27265625000001, 27.833154296874994],
            [89.39589843750002, 27.958154296874994],
            [89.48066406250001, 28.059960937499994],
            [89.53691406249999, 28.107421875],
            [89.65273437500002, 28.15830078125],
            [89.74980468749999, 28.188183593749997],
            [89.81689453125, 28.256298828124997],
            [89.89785156250002, 28.294140625],
            [89.98105468750003, 28.311181640624994],
            [90.1044921875, 28.302050781249996],
            [90.22080078125003, 28.277734375],
            [90.34824218750003, 28.243945312499996],
            [90.36298828125001, 28.216503906249997],
            [90.35214843750003, 28.168164062499997],
            [90.33378906249999, 28.119140625],
            [90.33310546875003, 28.093994140625],
            [90.35273437500001, 28.080224609374994],
            [90.47734374999999, 28.070849609374996],
            [90.63007812500001, 28.078564453124997],
            [90.71572265625002, 28.071728515624997],
            [90.90664062500002, 28.026513671874994],
            [90.9625, 27.994580078124997],
            [91.02080078124999, 27.970068359375],
            [91.07773437500003, 27.974462890625],
            [91.14990234375, 28.026757812499994],
            [91.22587890624999, 28.071240234374997],
            [91.27304687500003, 28.078369140625],
            [91.30683593750001, 28.064013671874996],
            [91.36757812500002, 28.021630859374994],
            [91.49335937500001, 27.981787109375],
            [91.60556640625003, 27.951708984374996],
            [91.64189453124999, 27.923242187499994],
            [91.62939453125, 27.80087890625],
            [91.63193359375003, 27.759960937499997],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 3,
        labelrank: 4,
        sovereignt: "Oman",
        sov_a3: "OMN",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Oman",
        adm0_a3: "OMN",
        geou_dif: 0,
        geounit: "Oman",
        gu_a3: "OMN",
        su_dif: 0,
        subunit: "Oman",
        su_a3: "OMN",
        brk_diff: 0,
        name: "Oman",
        name_long: "Oman",
        brk_a3: "OMN",
        brk_name: "Oman",
        brk_group: null,
        abbrev: "Oman",
        postal: "OM",
        formal_en: "Sultanate of Oman",
        formal_fr: null,
        name_ciawf: "Oman",
        note_adm0: null,
        note_brk: null,
        name_sort: "Oman",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 4,
        mapcolor9: 1,
        mapcolor13: 6,
        pop_est: 4974986,
        pop_rank: 12,
        pop_year: 2019,
        gdp_md: 76331,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "2. High income: nonOECD",
        fips_10: "MU",
        iso_a2: "OM",
        iso_a2_eh: "OM",
        iso_a3: "OMN",
        iso_a3_eh: "OMN",
        iso_n3: "512",
        iso_n3_eh: "512",
        un_a3: "512",
        wb_a2: "OM",
        wb_a3: "OMN",
        woe_id: 23424898,
        woe_id_eh: 23424898,
        woe_note: "Exact WOE match as country",
        adm0_iso: "OMN",
        adm0_diff: null,
        adm0_tlc: "OMN",
        adm0_a3_us: "OMN",
        adm0_a3_fr: "OMN",
        adm0_a3_ru: "OMN",
        adm0_a3_es: "OMN",
        adm0_a3_cn: "OMN",
        adm0_a3_tw: "OMN",
        adm0_a3_in: "OMN",
        adm0_a3_np: "OMN",
        adm0_a3_pk: "OMN",
        adm0_a3_de: "OMN",
        adm0_a3_gb: "OMN",
        adm0_a3_br: "OMN",
        adm0_a3_il: "OMN",
        adm0_a3_ps: "OMN",
        adm0_a3_sa: "OMN",
        adm0_a3_eg: "OMN",
        adm0_a3_ma: "OMN",
        adm0_a3_pt: "OMN",
        adm0_a3_ar: "OMN",
        adm0_a3_jp: "OMN",
        adm0_a3_ko: "OMN",
        adm0_a3_vn: "OMN",
        adm0_a3_tr: "OMN",
        adm0_a3_id: "OMN",
        adm0_a3_pl: "OMN",
        adm0_a3_gr: "OMN",
        adm0_a3_it: "OMN",
        adm0_a3_nl: "OMN",
        adm0_a3_se: "OMN",
        adm0_a3_bd: "OMN",
        adm0_a3_ua: "OMN",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Western Asia",
        region_wb: "Middle East & North Africa",
        name_len: 4,
        long_len: 4,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: 57.336553,
        label_y: 22.120427,
        ne_id: 1159321151,
        wikidataid: "Q842",
        name_ar: "سلطنة عمان",
        name_bn: "ওমান",
        name_de: "Oman",
        name_en: "Oman",
        name_es: "Omán",
        name_fa: "عمان",
        name_fr: "Oman",
        name_el: "Ομάν",
        name_he: "עומאן",
        name_hi: "ओमान",
        name_hu: "Omán",
        name_id: "Oman",
        name_it: "Oman",
        name_ja: "オマーン",
        name_ko: "오만",
        name_nl: "Oman",
        name_pl: "Oman",
        name_pt: "Omã",
        name_ru: "Оман",
        name_sv: "Oman",
        name_tr: "Umman",
        name_uk: "Оман",
        name_ur: "عمان",
        name_vi: "Oman",
        name_zh: "阿曼",
        name_zht: "阿曼",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "OMN.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [58.72207031250002, 20.21875],
              [58.65908203125002, 20.20361328125],
              [58.64091796875002, 20.210693359375],
              [58.641210937500006, 20.337353515624997],
              [58.78798828125002, 20.49658203125],
              [58.884375, 20.680566406249994],
              [58.95078125, 20.516162109375003],
              [58.83515625000001, 20.42392578124999],
              [58.77226562500002, 20.266845703125],
              [58.72207031250002, 20.21875],
            ],
          ],
          [
            [
              [53.08564453125001, 16.648388671874997],
              [53.025, 16.780224609374997],
              [52.96435546875, 16.912060546874997],
              [52.90371093750002, 17.043847656249994],
              [52.84296875000001, 17.175683593749994],
              [52.80058593750002, 17.26791992187499],
              [52.72919921875001, 17.300390625],
              [52.68593750000002, 17.39794921875],
              [52.641699218750006, 17.497851562500003],
              [52.59736328125001, 17.59775390624999],
              [52.553125, 17.69760742187499],
              [52.508886718750006, 17.797509765624994],
              [52.46455078125001, 17.897412109374997],
              [52.42031250000002, 17.997314453125],
              [52.376074218750006, 18.09716796875],
              [52.33173828125001, 18.197070312500003],
              [52.2875, 18.296923828125003],
              [52.243261718750006, 18.39682617187499],
              [52.19902343750002, 18.49667968749999],
              [52.15468750000002, 18.596582031249994],
              [52.110449218750006, 18.696484375],
              [52.06621093750002, 18.79638671875],
              [52.021875, 18.896289062500003],
              [51.977636718750006, 18.996142578125003],
              [52.11855468750002, 19.043164062499997],
              [52.290625, 19.100488281249994],
              [52.46269531250002, 19.1578125],
              [52.63466796875002, 19.215136718750003],
              [52.806738281250006, 19.2724609375],
              [52.97871093750001, 19.329785156249997],
              [53.15078125000002, 19.387158203124997],
              [53.322851562500006, 19.444482421874994],
              [53.49482421875001, 19.50180664062499],
              [53.66689453125002, 19.559130859375003],
              [53.8388671875, 19.616503906250003],
              [54.01093750000001, 19.673828125],
              [54.18300781250002, 19.731152343749997],
              [54.35498046875, 19.788476562499994],
              [54.52705078125001, 19.84580078124999],
              [54.69902343750002, 19.903125],
              [54.87109375, 19.960498046875003],
              [54.97734375000002, 19.99594726562499],
              [55.021484375, 20.12924804687499],
              [55.058203125, 20.239941406249997],
              [55.0947265625, 20.350634765625003],
              [55.131445312500006, 20.461328125],
              [55.168066406250006, 20.572021484375],
              [55.20458984375, 20.68271484374999],
              [55.2412109375, 20.793408203124997],
              [55.277929687500006, 20.904101562500003],
              [55.314453125, 21.014794921874994],
              [55.35107421875, 21.125537109375003],
              [55.3876953125, 21.236230468749994],
              [55.42431640625, 21.346923828125],
              [55.4609375, 21.45761718749999],
              [55.49755859375, 21.568310546874997],
              [55.5341796875, 21.679003906250003],
              [55.57080078125, 21.789697265624994],
              [55.607421875, 21.900390625],
              [55.64101562500002, 22.001855468749994],
              [55.577734375, 22.099511718749994],
              [55.492773437500006, 22.230664062499997],
              [55.40380859375, 22.367822265624994],
              [55.32011718750002, 22.49692382812499],
              [55.25927734375, 22.59091796874999],
              [55.18583984375002, 22.7041015625],
              [55.19404296875001, 22.85],
              [55.19218750000002, 22.92294921874999],
              [55.19990234375001, 23.034765625],
              [55.27021484375001, 23.18994140625],
              [55.35322265625001, 23.387451171875],
              [55.41386718750002, 23.51875],
              [55.46630859375, 23.63291015624999],
              [55.50849609375001, 23.724609375],
              [55.53164062500002, 23.819042968749997],
              [55.51933593750002, 23.885498046875],
              [55.491796875, 23.90966796875],
              [55.46845703125001, 23.941113281249997],
              [55.5478515625, 23.991357421874994],
              [55.69658203125002, 24.024121093749997],
              [55.77910156250002, 24.01708984375],
              [55.894140625, 24.04140625],
              [55.98515625000002, 24.063378906249994],
              [55.9921875, 24.09296875],
              [55.96630859375, 24.142626953125003],
              [55.92861328125002, 24.215136718750003],
              [55.79970703125002, 24.22265625],
              [55.760839843750006, 24.24267578125],
              [55.8056640625, 24.349804687499997],
              [55.80400390625002, 24.383544921875],
              [55.78681640625001, 24.423535156249997],
              [55.768164062500006, 24.490625],
              [55.77753906250001, 24.57734375],
              [55.80390625000001, 24.63623046875],
              [55.80419921875, 24.68359375],
              [55.791601562500006, 24.781298828125003],
              [55.79570312500002, 24.868115234374997],
              [55.822851562500006, 24.911279296874994],
              [55.870703125, 24.951416015625],
              [55.91582031250002, 24.97177734374999],
              [55.96308593750001, 24.970263671875003],
              [56.000585937500006, 24.95322265624999],
              [56.016699218750006, 24.90771484375],
              [56.00634765625, 24.876416015624997],
              [55.97968750000001, 24.8720703125],
              [55.9703125, 24.858935546875003],
              [56.008398437500006, 24.798242187499994],
              [56.06386718750002, 24.73876953125],
              [56.106542968750006, 24.748681640624994],
              [56.15449218750001, 24.79550781249999],
              [56.2046875, 24.833300781250003],
              [56.26787109375002, 24.86669921875],
              [56.313574218750006, 24.931298828124994],
              [56.35292968750002, 24.973291015624994],
              [56.38798828125002, 24.979199218749997],
              [56.48984375, 24.716357421875003],
              [56.640625, 24.4703125],
              [56.77412109375001, 24.33457031249999],
              [56.9125, 24.150195312500003],
              [57.123046875, 23.980712890625],
              [57.219824218750006, 23.922753906249994],
              [57.611328125, 23.803662109374997],
              [57.82509765625002, 23.75913085937499],
              [58.12041015625002, 23.716552734375],
              [58.32451171875002, 23.623828125],
              [58.393164062500006, 23.6181640625],
              [58.5, 23.645654296874994],
              [58.57802734375002, 23.643457031249994],
              [58.773046875, 23.5171875],
              [58.83037109375002, 23.3974609375],
              [58.91152343750002, 23.334179687499997],
              [58.9833984375, 23.23471679687499],
              [59.029882812500006, 23.130566406249997],
              [59.19472656250002, 22.971875],
              [59.31093750000002, 22.793359375],
              [59.42939453125001, 22.660839843749997],
              [59.53515625, 22.578515625],
              [59.69560546875002, 22.546142578125],
              [59.8232421875, 22.508984375],
              [59.8375, 22.420556640624994],
              [59.82441406250001, 22.30517578125],
              [59.8, 22.219921875],
              [59.68085937500001, 22.05380859374999],
              [59.65253906250001, 21.951367187499997],
              [59.517578125, 21.78232421874999],
              [59.37148437500002, 21.498828125],
              [59.30449218750002, 21.435351562500003],
              [59.06875, 21.2890625],
              [58.89570312500001, 21.11279296875],
              [58.6904296875, 20.80712890625],
              [58.5341796875, 20.50390625],
              [58.47421875, 20.406884765624994],
              [58.348730468750006, 20.386914062499997],
              [58.26601562500002, 20.395458984374997],
              [58.208984375, 20.423974609374994],
              [58.23164062500001, 20.5068359375],
              [58.24501953125002, 20.59921875],
              [58.16943359375, 20.589501953124994],
              [58.10292968750002, 20.570361328125003],
              [57.94716796875002, 20.34360351562499],
              [57.86181640625, 20.244140625],
              [57.84365234375002, 20.117724609375003],
              [57.80214843750002, 19.95458984375],
              [57.7412109375, 19.804492187500003],
              [57.71416015625002, 19.678417968749997],
              [57.71513671875002, 19.60693359375],
              [57.760839843750006, 19.43222656249999],
              [57.76396484375002, 19.253320312499994],
              [57.79033203125002, 19.145947265624997],
              [57.811621093750006, 19.01708984375],
              [57.73847656250001, 18.97734375],
              [57.67578125, 18.95786132812499],
              [57.42792968750001, 18.943798828124997],
              [57.17656250000002, 18.902587890625],
              [56.95722656250001, 18.827832031249997],
              [56.82597656250002, 18.753515625],
              [56.65507812500002, 18.587353515624997],
              [56.55078125, 18.165966796874997],
              [56.38349609375001, 17.987988281249997],
              [56.27031250000002, 17.95078125],
              [55.99765625, 17.935205078124994],
              [55.613867187500006, 17.88608398437499],
              [55.479101562500006, 17.84326171875],
              [55.25537109375, 17.585644531249997],
              [55.23818359375002, 17.50473632812499],
              [55.28144531250001, 17.446240234374997],
              [55.29560546875001, 17.381591796875],
              [55.27519531250002, 17.32089843749999],
              [55.17373046875002, 17.157617187499994],
              [55.06416015625001, 17.03891601562499],
              [54.771875, 16.964648437500003],
              [54.664648437500006, 17.00888671874999],
              [54.566503906250006, 17.03125],
              [54.376953125, 17.033642578124997],
              [54.06816406250002, 17.005517578124994],
              [53.95439453125002, 16.91782226562499],
              [53.775390625, 16.855712890625],
              [53.60986328125, 16.759960937499997],
              [53.29775390625002, 16.723339843749997],
              [53.08564453125001, 16.648388671874997],
            ],
          ],
          [
            [
              [56.2978515625, 25.650683593750003],
              [56.27851562500001, 25.627734375],
              [56.24951171875, 25.625390625],
              [56.18359375, 25.644921875],
              [56.144628906250006, 25.69052734374999],
              [56.151953125, 25.74609375],
              [56.15410156250002, 25.84848632812499],
              [56.17255859375001, 25.94516601562499],
              [56.16748046875, 26.0474609375],
              [56.11650390625002, 26.068164062499996],
              [56.08046875000002, 26.062646484374994],
              [56.16445312500002, 26.20703125],
              [56.197265625, 26.229199218749997],
              [56.22841796875002, 26.219775390624996],
              [56.30556640625002, 26.235205078125],
              [56.34648437500002, 26.313623046874994],
              [56.37871093750002, 26.356347656249994],
              [56.4130859375, 26.351171875],
              [56.429785156250006, 26.327197265624996],
              [56.41777343750002, 26.208154296874994],
              [56.41640625000002, 26.108740234375],
              [56.373632812500006, 25.804589843749994],
              [56.32929687500001, 25.751953125],
              [56.307226562500006, 25.70932617187499],
              [56.2978515625, 25.650683593750003],
            ],
          ],
          [
            [
              [56.281835937500006, 25.235546875],
              [56.240234375, 25.20883789062499],
              [56.210546875, 25.21328125],
              [56.21650390625001, 25.26669921874999],
              [56.23427734375002, 25.30380859374999],
              [56.27734375, 25.30087890624999],
              [56.28779296875001, 25.278613281250003],
              [56.281835937500006, 25.235546875],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Uzbekistan",
        sov_a3: "UZB",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Uzbekistan",
        adm0_a3: "UZB",
        geou_dif: 0,
        geounit: "Uzbekistan",
        gu_a3: "UZB",
        su_dif: 0,
        subunit: "Uzbekistan",
        su_a3: "UZB",
        brk_diff: 0,
        name: "Uzbekistan",
        name_long: "Uzbekistan",
        brk_a3: "UZB",
        brk_name: "Uzbekistan",
        brk_group: null,
        abbrev: "Uzb.",
        postal: "UZ",
        formal_en: "Republic of Uzbekistan",
        formal_fr: null,
        name_ciawf: "Uzbekistan",
        note_adm0: null,
        note_brk: null,
        name_sort: "Uzbekistan",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 3,
        mapcolor9: 5,
        mapcolor13: 4,
        pop_est: 33580650,
        pop_rank: 15,
        pop_year: 2019,
        gdp_md: 57921,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        fips_10: "UZ",
        iso_a2: "UZ",
        iso_a2_eh: "UZ",
        iso_a3: "UZB",
        iso_a3_eh: "UZB",
        iso_n3: "860",
        iso_n3_eh: "860",
        un_a3: "860",
        wb_a2: "UZ",
        wb_a3: "UZB",
        woe_id: 23424980,
        woe_id_eh: 23424980,
        woe_note: "Exact WOE match as country",
        adm0_iso: "UZB",
        adm0_diff: null,
        adm0_tlc: "UZB",
        adm0_a3_us: "UZB",
        adm0_a3_fr: "UZB",
        adm0_a3_ru: "UZB",
        adm0_a3_es: "UZB",
        adm0_a3_cn: "UZB",
        adm0_a3_tw: "UZB",
        adm0_a3_in: "UZB",
        adm0_a3_np: "UZB",
        adm0_a3_pk: "UZB",
        adm0_a3_de: "UZB",
        adm0_a3_gb: "UZB",
        adm0_a3_br: "UZB",
        adm0_a3_il: "UZB",
        adm0_a3_ps: "UZB",
        adm0_a3_sa: "UZB",
        adm0_a3_eg: "UZB",
        adm0_a3_ma: "UZB",
        adm0_a3_pt: "UZB",
        adm0_a3_ar: "UZB",
        adm0_a3_jp: "UZB",
        adm0_a3_ko: "UZB",
        adm0_a3_vn: "UZB",
        adm0_a3_tr: "UZB",
        adm0_a3_id: "UZB",
        adm0_a3_pl: "UZB",
        adm0_a3_gr: "UZB",
        adm0_a3_it: "UZB",
        adm0_a3_nl: "UZB",
        adm0_a3_se: "UZB",
        adm0_a3_bd: "UZB",
        adm0_a3_ua: "UZB",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Central Asia",
        region_wb: "Europe & Central Asia",
        name_len: 10,
        long_len: 10,
        abbrev_len: 4,
        tiny: 5,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: 64.005429,
        label_y: 41.693603,
        ne_id: 1159321405,
        wikidataid: "Q265",
        name_ar: "أوزبكستان",
        name_bn: "উজবেকিস্তান",
        name_de: "Usbekistan",
        name_en: "Uzbekistan",
        name_es: "Uzbekistán",
        name_fa: "ازبکستان",
        name_fr: "Ouzbékistan",
        name_el: "Ουζμπεκιστάν",
        name_he: "אוזבקיסטן",
        name_hi: "उज़्बेकिस्तान",
        name_hu: "Üzbegisztán",
        name_id: "Uzbekistan",
        name_it: "Uzbekistan",
        name_ja: "ウズベキスタン",
        name_ko: "우즈베키스탄",
        name_nl: "Oezbekistan",
        name_pl: "Uzbekistan",
        name_pt: "Uzbequistão",
        name_ru: "Узбекистан",
        name_sv: "Uzbekistan",
        name_tr: "Özbekistan",
        name_uk: "Узбекистан",
        name_ur: "ازبکستان",
        name_vi: "Uzbekistan",
        name_zh: "乌兹别克斯坦",
        name_zht: "烏茲別克",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "UZB.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [70.94677734375, 42.248681640624994],
              [70.97900390625, 42.266552734375],
              [71.03603515625002, 42.28466796875],
              [71.12998046875, 42.25],
              [71.21269531250002, 42.206445312499994],
              [71.23232421875002, 42.186279296875],
              [71.228515625, 42.162890625],
              [71.0322265625, 42.077783203124994],
              [70.91035156250001, 42.037988281249994],
              [70.85664062500001, 42.030810546874996],
              [70.84189453125, 42.01962890625],
              [70.8033203125, 41.92265625],
              [70.72773437500001, 41.905224609375],
              [70.630859375, 41.87548828125],
              [70.56289062500002, 41.830810546875],
              [70.45498046875002, 41.725048828125],
              [70.18095703125002, 41.571435546874994],
              [70.17695312500001, 41.539990234375],
              [70.20087890625001, 41.514453125],
              [70.2900390625, 41.496826171875],
              [70.4078125, 41.449560546875],
              [70.47138671875001, 41.412646484374996],
              [70.64589843750002, 41.460351562499994],
              [70.68886718750002, 41.4498046875],
              [70.734375, 41.400537109374994],
              [70.78242187500001, 41.2625],
              [70.86044921875, 41.224902343749996],
              [70.96259765625001, 41.19599609375],
              [71.0259765625, 41.186572265624996],
              [71.11074218750002, 41.152636718749996],
              [71.22343750000002, 41.139941406249996],
              [71.298828125, 41.152490234374994],
              [71.39306640625, 41.123388671875],
              [71.40839843750001, 41.136035156249996],
              [71.4208984375, 41.34189453125],
              [71.5, 41.307470703125],
              [71.54560546875001, 41.308056640625],
              [71.585546875, 41.333251953125],
              [71.60625, 41.367431640625],
              [71.61962890625, 41.435449218749994],
              [71.60224609375001, 41.503271484375],
              [71.6375, 41.5341796875],
              [71.66494140625002, 41.5412109375],
              [71.68515625, 41.533007812499996],
              [71.697265625, 41.515576171875],
              [71.70068359375, 41.454003906249994],
              [71.75771484375002, 41.428027343749996],
              [71.79248046875, 41.413134765624996],
              [71.82578125, 41.36103515625],
              [71.8580078125, 41.311376953125],
              [71.87861328125001, 41.19501953125],
              [71.95849609375, 41.187060546874996],
              [72.05244140625001, 41.16474609375],
              [72.11542968750001, 41.186572265624996],
              [72.16425781250001, 41.173730468749994],
              [72.18095703125002, 41.118457031249996],
              [72.1806640625, 41.066845703125],
              [72.18730468750002, 41.025927734374996],
              [72.21308593750001, 41.0142578125],
              [72.294921875, 41.039941406249994],
              [72.36406250000002, 41.04345703125],
              [72.42734375, 41.018945312499994],
              [72.50595703125, 40.981689453125],
              [72.62041015625002, 40.8837890625],
              [72.65830078125, 40.869921875],
              [72.83095703125002, 40.862158203125],
              [72.86660156250002, 40.842333984374996],
              [72.92597656250001, 40.842431640624994],
              [72.99003906250002, 40.860107421875],
              [73.13212890625002, 40.828515625],
              [73.13691406250001, 40.81064453125],
              [73.112890625, 40.786035156249994],
              [72.77382812500002, 40.650390625],
              [72.74882812500002, 40.608691406249996],
              [72.67958984375002, 40.555615234375],
              [72.6041015625, 40.525439453124996],
              [72.56748046875, 40.524365234375],
              [72.40205078125001, 40.578076171875],
              [72.38261718750002, 40.56513671875],
              [72.36904296875002, 40.54345703125],
              [72.3697265625, 40.5197265625],
              [72.40595703125001, 40.4630859375],
              [72.3892578125, 40.427392578124994],
              [72.35771484375002, 40.401660156249996],
              [72.25400390625, 40.42421875],
              [72.23466796875002, 40.438623046874994],
              [72.23281250000002, 40.454394531249996],
              [72.19287109375, 40.454443359375],
              [72.13125, 40.438623046874994],
              [72.01259765625002, 40.340722656249994],
              [71.97109375000002, 40.289501953125],
              [71.9556640625, 40.25859375],
              [71.90273437500002, 40.240966796875],
              [71.84541015625001, 40.234326171875],
              [71.77265625000001, 40.188037109374996],
              [71.69248046875, 40.15234375],
              [71.66679687500002, 40.178613281249994],
              [71.65087890625, 40.2080078125],
              [71.6298828125, 40.217138671875],
              [71.58046875000002, 40.210253906249996],
              [71.52041015625002, 40.208984375],
              [71.45742187500002, 40.241992187499996],
              [71.37617187500001, 40.275195312499996],
              [71.3046875, 40.286914062499996],
              [71.09453125000002, 40.271240234375],
              [70.990625, 40.2548828125],
              [70.9580078125, 40.2388671875],
              [70.8994140625, 40.2345703125],
              [70.653125, 40.201171875],
              [70.60273437500001, 40.214160156249996],
              [70.56582031250002, 40.267138671874996],
              [70.53359375000002, 40.324511718749996],
              [70.46992187500001, 40.345361328124994],
              [70.39824218750002, 40.361376953124996],
              [70.37158203125, 40.384130859375],
              [70.3697265625, 40.412011718749994],
              [70.37714843750001, 40.439257812499996],
              [70.38261718750002, 40.453515625],
              [70.548828125, 40.562792968749996],
              [70.69833984375, 40.661181640624996],
              [70.71201171875, 40.669091796874994],
              [70.7255859375, 40.687792968749996],
              [70.75107421875, 40.72177734375],
              [70.7509765625, 40.739599609375],
              [70.63916015625, 40.778564453125],
              [70.634765625, 40.79658203125],
              [70.65732421875, 40.815087890624994],
              [70.65732421875, 40.839648437499996],
              [70.57822265625, 40.911474609375],
              [70.44150390625, 41.0234375],
              [70.401953125, 41.035107421875],
              [70.37265625, 41.027636718749996],
              [70.3189453125, 40.919238281249996],
              [70.29208984375, 40.89169921875],
              [70.136328125, 40.82041015625],
              [70.00566406250002, 40.771435546875],
              [69.77324218750002, 40.68427734375],
              [69.712890625, 40.656982421875],
              [69.67080078125002, 40.661962890625],
              [69.62841796875, 40.679052734375],
              [69.49824218750001, 40.76708984375],
              [69.41386718750002, 40.797167968749996],
              [69.35722656250002, 40.767382812499996],
              [69.309375, 40.723925781249996],
              [69.31396484375, 40.634765625],
              [69.25996093750001, 40.587646484375],
              [69.20625, 40.566552734374994],
              [69.30419921875, 40.327392578125],
              [69.29443359375, 40.29658203125],
              [69.21953125000002, 40.288134765624996],
              [69.27490234375, 40.198095703125],
              [69.22832031250002, 40.18759765625],
              [69.1103515625, 40.208740234375],
              [68.9517578125, 40.222607421875],
              [68.65253906250001, 40.182666015624996],
              [68.63066406250002, 40.16708984375],
              [68.62246093750002, 40.147265625],
              [68.63974609375, 40.129199218749996],
              [68.78457031250002, 40.127099609374994],
              [68.92685546875, 40.136328125],
              [68.96601562500001, 40.119580078125],
              [68.97207031250002, 40.08994140625],
              [68.9556640625, 40.071337890624996],
              [68.90849609375002, 40.068212890625],
              [68.8046875, 40.050341796874996],
              [68.79277343750002, 40.031494140625],
              [68.78945312500002, 40.013330078124994],
              [68.82441406250001, 39.960791015625],
              [68.8638671875, 39.92734375],
              [68.86875, 39.907470703125],
              [68.85224609375001, 39.890966796875],
              [68.83242187500002, 39.884326171874996],
              [68.79765625000002, 39.909130859375],
              [68.77783203125, 39.904199218749994],
              [68.76796875000002, 39.8818359375],
              [68.75820312500002, 39.85556640625],
              [68.73525390625002, 39.836230468749996],
              [68.68691406250002, 39.8462890625],
              [68.63896484375002, 39.8388671875],
              [68.6103515625, 39.74326171875],
              [68.58613281250001, 39.6349609375],
              [68.50693359375, 39.562792968749996],
              [68.46328125000002, 39.53671875],
              [68.3990234375, 39.528857421874996],
              [68.30302734375002, 39.5376953125],
              [68.24492187500002, 39.548291015625],
              [68.0771484375, 39.56416015625],
              [67.90859375000002, 39.593798828124996],
              [67.71904296875002, 39.621386718749996],
              [67.54248046875, 39.5576171875],
              [67.49169921875, 39.51875],
              [67.4595703125, 39.482421875],
              [67.42617187500002, 39.465576171875],
              [67.349609375, 39.242089843749994],
              [67.35761718750001, 39.216699218749994],
              [67.400390625, 39.196679687499994],
              [67.61650390625002, 39.150292968749994],
              [67.64833984375002, 39.1310546875],
              [67.66728515625002, 39.109179687499996],
              [67.67656250000002, 39.00849609375],
              [67.69443359375, 38.99462890625],
              [67.7685546875, 38.982226562499996],
              [67.87568359375001, 38.9830078125],
              [67.9595703125, 38.992919921875],
              [68.04433593750002, 38.98359375],
              [68.103515625, 38.96201171875],
              [68.13251953125001, 38.927636718749994],
              [68.14853515625, 38.890625],
              [68.0478515625, 38.669287109375],
              [68.05595703125002, 38.588916015624996],
              [68.08720703125002, 38.473535156249994],
              [68.144140625, 38.383105468749996],
              [68.25136718750002, 38.29453125],
              [68.33310546875, 38.23779296875],
              [68.35029296875001, 38.21103515625],
              [68.3544921875, 38.16953125],
              [68.34121093750002, 38.116796875],
              [68.29404296875, 38.03291015625],
              [68.23652343750001, 37.95966796875],
              [68.17402343750001, 37.92841796875],
              [68.08759765625001, 37.83544921875],
              [68.01093750000001, 37.720947265625],
              [67.86357421875002, 37.570703125],
              [67.81435546875002, 37.48701171875],
              [67.79804687500001, 37.244970703125],
              [67.75898437500001, 37.172216796875],
              [67.7529296875, 37.1998046875],
              [67.7, 37.22724609375],
              [67.607421875, 37.222509765625],
              [67.546484375, 37.235644531249996],
              [67.51728515625001, 37.266650390624996],
              [67.44169921875002, 37.2580078125],
              [67.31972656250002, 37.2095703125],
              [67.19550781250001, 37.235205078125],
              [67.06884765625, 37.334814453125],
              [66.827734375, 37.3712890625],
              [66.52226562500002, 37.348486328125],
              [66.51064453125002, 37.45869140625],
              [66.511328125, 37.599169921874996],
              [66.52558593750001, 37.7857421875],
              [66.62929687500002, 37.93203125],
              [66.62636718750002, 37.959863281249994],
              [66.60625, 37.98671875],
              [66.57451171875002, 38.010791015624996],
              [66.38974609375, 38.050927734374994],
              [66.33535156250002, 38.072167968749994],
              [66.263671875, 38.118066406249994],
              [66.17314453125002, 38.16669921875],
              [66.09482421875, 38.200146484375],
              [65.97119140625, 38.24423828125],
              [65.85712890625001, 38.26875],
              [65.79023437500001, 38.250048828124996],
              [65.728515625, 38.226367187499996],
              [65.6708984375, 38.225732421874994],
              [65.612890625, 38.238574218749996],
              [65.39960937500001, 38.348828125],
              [65.07666015625, 38.539453125],
              [64.82070312500002, 38.6724609375],
              [64.65996093750002, 38.73603515625],
              [64.621875, 38.7564453125],
              [64.53164062500002, 38.816210937499996],
              [64.30996093750002, 38.977294921875],
              [64.16279296875001, 38.95361328125],
              [63.95253906250002, 39.058349609375],
              [63.763671875, 39.160546875],
              [63.720800781250006, 39.188134765624994],
              [63.50605468750001, 39.377099609374994],
              [63.29189453125002, 39.49951171875],
              [63.05810546875, 39.633154296875],
              [62.906835937500006, 39.716796875],
              [62.65068359375002, 39.85849609375],
              [62.525488281250006, 39.944091796875],
              [62.48320312500002, 39.975634765624996],
              [62.44160156250001, 40.03623046875],
              [62.375, 40.332080078124996],
              [62.29804687500001, 40.46748046875],
              [62.1884765625, 40.5412109375],
              [62.09501953125002, 40.68330078125],
              [62.017578125, 40.893798828125],
              [61.95351562500002, 41.030615234375],
              [61.90283203125, 41.093701171875],
              [61.799902343750006, 41.163427734375],
              [61.64453125, 41.23984375],
              [61.49697265625002, 41.27607421875],
              [61.44365234375002, 41.274609375],
              [61.41738281250002, 41.26513671875],
              [61.3875, 41.2521484375],
              [61.32890625000002, 41.195117187499996],
              [61.24238281250001, 41.189208984375],
              [61.179296875, 41.190576171874994],
              [61.11992187500002, 41.210888671875],
              [60.933203125, 41.22900390625],
              [60.8671875, 41.248681640624994],
              [60.7548828125, 41.245751953124994],
              [60.51357421875002, 41.216162109375],
              [60.45498046875002, 41.221630859375],
              [60.2, 41.348974609375],
              [60.08964843750002, 41.3994140625],
              [60.0673828125, 41.42734375],
              [60.06875, 41.476220703124994],
              [60.106054687500006, 41.545214843749996],
              [60.13798828125002, 41.594140625],
              [60.1240234375, 41.644970703125],
              [60.07558593750002, 41.700537109375],
              [60.07558593750002, 41.759667968749994],
              [60.10859375000001, 41.79267578125],
              [60.176367187500006, 41.782275390624996],
              [60.20078125, 41.803125],
              [60.19208984375001, 41.834423828125],
              [60.15556640625002, 41.85703125],
              [60.10693359375, 41.907421875],
              [59.96259765625001, 41.954394531249996],
              [59.94179687500002, 41.973535156249994],
              [59.949316406250006, 41.995410156249996],
              [59.97412109375, 42.018798828125],
              [59.97919921875001, 42.06806640625],
              [59.98164062500001, 42.131738281249994],
              [60.00078125000002, 42.16474609375],
              [60.00605468750001, 42.190820312499994],
              [59.98515625000002, 42.21171875],
              [59.9365234375, 42.23603515625],
              [59.85830078125002, 42.295166015625],
              [59.76259765625002, 42.3015625],
              [59.45107421875002, 42.29951171875],
              [59.35429687500002, 42.323291015624996],
              [59.27656250000001, 42.35615234375],
              [59.19912109375002, 42.481689453125],
              [59.15957031250002, 42.51142578125],
              [59.123144531250006, 42.523779296875],
              [59.03583984375001, 42.528125],
              [58.93085937500001, 42.540283203125],
              [58.876953125, 42.561474609375],
              [58.72998046875, 42.676171875],
              [58.58906250000001, 42.778466796874994],
              [58.532324218750006, 42.681933593749996],
              [58.477148437500006, 42.662841796875],
              [58.353125, 42.671728515625],
              [58.25966796875002, 42.6880859375],
              [58.20644531250002, 42.666308593749996],
              [58.15156250000001, 42.628076171874994],
              [58.16201171875002, 42.602978515625],
              [58.2041015625, 42.5763671875],
              [58.288671875, 42.527294921875],
              [58.41816406250001, 42.406689453125],
              [58.47695312500002, 42.340136718749996],
              [58.48583984375, 42.316845703125],
              [58.47441406250002, 42.299365234374996],
              [58.45703125, 42.291796875],
              [58.43144531250002, 42.29208984375],
              [58.397070312500006, 42.29248046875],
              [58.37714843750001, 42.312451171875],
              [58.37050781250002, 42.34677734375],
              [58.327246093750006, 42.39892578125],
              [58.28291015625001, 42.428857421874994],
              [58.23408203125001, 42.447705078125],
              [58.165625, 42.461572265624994],
              [58.07548828125002, 42.4865234375],
              [58.02890625, 42.487646484375],
              [57.983496093750006, 42.458789062499996],
              [57.94570312500002, 42.42001953125],
              [57.9234375, 42.335205078125],
              [57.85595703125, 42.2310546875],
              [57.81425781250002, 42.18984375],
              [57.686132812500006, 42.164794921875],
              [57.38173828125002, 42.156298828124996],
              [57.290625, 42.123779296875],
              [57.22880859375002, 42.08447265625],
              [57.11357421875002, 41.95712890625],
              [57.03369140625, 41.91484375],
              [56.96406250000001, 41.85654296875],
              [56.98486328125, 41.669335937499994],
              [57.018164062500006, 41.450585937499994],
              [57.07666015625, 41.389990234375],
              [57.113867187500006, 41.37177734375],
              [57.11884765625001, 41.35029296875],
              [57.094824218750006, 41.331298828125],
              [57.06425781250002, 41.307275390624994],
              [57.01796875000002, 41.263476562499996],
              [56.9658203125, 41.26513671875],
              [56.86083984375, 41.276123046875],
              [56.77363281250001, 41.287988281249994],
              [56.47988281250002, 41.300634765625],
              [56.24199218750002, 41.310839843749996],
              [55.97744140625002, 41.322216796875],
              [55.97734375000002, 41.5517578125],
              [55.977148437500006, 41.78134765625],
              [55.97705078125, 42.010888671874994],
              [55.97695312500002, 42.2404296875],
              [55.97685546875002, 42.469970703125],
              [55.97675781250001, 42.699511718749996],
              [55.976660156250006, 42.929052734375],
              [55.9765625, 43.15859375],
              [55.97646484375002, 43.388085937499994],
              [55.97636718750002, 43.617626953125],
              [55.97626953125001, 43.847216796874996],
              [55.976171875, 44.0767578125],
              [55.97607421875, 44.306298828124994],
              [55.97597656250002, 44.53583984375],
              [55.97578125000001, 44.765380859375],
              [55.975683593750006, 44.994921875],
              [56.10048828125002, 45.023388671875],
              [56.257910156250006, 45.059326171875],
              [56.4091796875, 45.093798828124996],
              [56.58876953125002, 45.134765625],
              [56.79189453125002, 45.181054687499994],
              [56.96503906250001, 45.220605468749994],
              [57.17167968750002, 45.267724609374994],
              [57.32929687500001, 45.303662109375],
              [57.47734375000002, 45.337451171874996],
              [57.66669921875001, 45.37744140625],
              [57.961035156250006, 45.439697265625],
              [58.12519531250001, 45.474365234375],
              [58.291113281250006, 45.509423828124994],
              [58.44941406250001, 45.542919921875],
              [58.555273437500006, 45.55537109375],
              [58.6689453125, 45.507568359375],
              [58.80703125000002, 45.441796875],
              [58.94511718750002, 45.3759765625],
              [59.08339843750002, 45.310205078124994],
              [59.22148437500002, 45.244433593749996],
              [59.35957031250001, 45.178613281249994],
              [59.49785156250002, 45.112841796874996],
              [59.63593750000001, 45.0470703125],
              [59.774023437500006, 44.981298828125],
              [59.912207031250006, 44.915576171874996],
              [60.05029296875, 44.849755859374994],
              [60.1884765625, 44.783984375],
              [60.32666015625, 44.718212890625],
              [60.46474609375002, 44.65244140625],
              [60.60292968750002, 44.58662109375],
              [60.74111328125002, 44.520849609375],
              [60.87919921875002, 44.455078125],
              [61.007910156250006, 44.393798828125],
              [61.06533203125002, 44.348388671875],
              [61.09707031250002, 44.2482421875],
              [61.160742187500006, 44.168603515624994],
              [61.271484375, 44.082275390625],
              [61.38505859375002, 43.993945312499996],
              [61.52587890625, 43.877197265625],
              [61.623632812500006, 43.796191406249996],
              [61.723242187500006, 43.71357421875],
              [61.88759765625002, 43.57724609375],
              [61.990234375, 43.492138671875],
              [62.07197265625001, 43.48935546875],
              [62.237890625, 43.509570312499996],
              [62.459375, 43.53662109375],
              [62.63447265625001, 43.558007812499994],
              [62.84619140625, 43.583886718749994],
              [63.04765625000002, 43.60849609375],
              [63.20703125, 43.627978515624996],
              [63.44482421875, 43.613232421875],
              [63.6796875, 43.5986328125],
              [63.84814453125, 43.588134765625],
              [64.01328125, 43.57783203125],
              [64.20878906250002, 43.565722656249996],
              [64.31816406250002, 43.558935546875],
              [64.44316406250002, 43.551171875],
              [64.49609375, 43.571630859375],
              [64.6041015625, 43.6134765625],
              [64.7060546875, 43.652978515624994],
              [64.81181640625002, 43.6939453125],
              [64.90546875000001, 43.714697265625],
              [65.003125, 43.649072265624994],
              [65.08486328125002, 43.573681640625],
              [65.1708984375, 43.494189453124996],
              [65.2705078125, 43.417529296874996],
              [65.36650390625002, 43.372021484375],
              [65.49619140625, 43.310546875],
              [65.57070312500002, 43.20517578125],
              [65.67021484375002, 43.064599609374994],
              [65.73564453125002, 42.972119140625],
              [65.80302734375002, 42.876953125],
              [65.90107421875001, 42.914501953125],
              [66.00566406250002, 42.95458984375],
              [66.10029296875001, 42.9908203125],
              [66.0888671875, 42.873388671875],
              [66.07851562500002, 42.766650390624996],
              [66.06269531250001, 42.60517578125],
              [66.0498046875, 42.47275390625],
              [66.01552734375002, 42.314794921875],
              [66.01318359375, 42.194482421874994],
              [66.01123046875, 42.088769531249994],
              [66.00957031250002, 42.0048828125],
              [66.19316406250002, 42.001123046874994],
              [66.32880859375001, 41.998339843749996],
              [66.4986328125, 41.994873046875],
              [66.51503906250002, 41.889404296875],
              [66.53789062500002, 41.741259765624996],
              [66.57255859375002, 41.606982421874996],
              [66.60166015625, 41.4943359375],
              [66.64531250000002, 41.3486328125],
              [66.66865234375001, 41.270751953125],
              [66.70966796875001, 41.179150390625],
              [66.74980468750002, 41.157080078125],
              [66.81425781250002, 41.142382812499996],
              [67.038671875, 41.1533203125],
              [67.225, 41.162353515625],
              [67.37158203125, 41.16953125],
              [67.52802734375001, 41.177148437499994],
              [67.73505859375001, 41.187255859375],
              [67.80507812500002, 41.163916015625],
              [67.86572265625, 41.1802734375],
              [67.93574218750001, 41.196582031249996],
              [67.99140625000001, 41.130029296874994],
              [68.01972656250001, 41.096240234374996],
              [68.059375, 41.061279296875],
              [68.11308593750002, 41.028613281249996],
              [68.09033203125, 40.960253906249996],
              [68.05703125000002, 40.860595703125],
              [68.04765625000002, 40.80927734375],
              [68.1123046875, 40.754052734374994],
              [68.16025390625, 40.72177734375],
              [68.29189453125002, 40.656103515625],
              [68.4150390625, 40.619433593749996],
              [68.495703125, 40.608642578125],
              [68.57265625000002, 40.62265625],
              [68.60068359375, 40.659960937499996],
              [68.59365234375002, 40.711279296875],
              [68.55654296875002, 40.76513671875],
              [68.55927734375001, 40.829296875],
              [68.58408203125, 40.876269531249996],
              [68.66279296875001, 40.9615234375],
              [68.73710937500002, 41.041894531249994],
              [68.851171875, 41.123828125],
              [68.9869140625, 41.205029296875],
              [69.04345703125, 41.264111328125],
              [69.06494140625, 41.366943359375],
              [69.15361328125002, 41.425244140625],
              [69.24931640625002, 41.460253906249996],
              [69.36835937500001, 41.490576171875],
              [69.4009765625, 41.541894531249994],
              [69.56513671875001, 41.629052734374994],
              [69.66386718750002, 41.672119140625],
              [69.7880859375, 41.697314453124996],
              [69.9599609375, 41.754052734374994],
              [70.09560546875002, 41.8205078125],
              [70.22587890625002, 41.94599609375],
              [70.32890625000002, 42.027978515624994],
              [70.416015625, 42.078564453125],
              [70.48906250000002, 42.0802734375],
              [70.54013671875, 42.039453125],
              [70.58427734375002, 42.036035156249994],
              [70.61328125, 42.054736328124996],
              [70.6625, 42.107470703124996],
              [70.71523437500002, 42.16865234375],
              [70.76455078125002, 42.194189453125],
              [70.8603515625, 42.2072265625],
              [70.94677734375, 42.248681640624994],
            ],
            [
              [70.65253906250001, 40.93662109375],
              [70.64921875000002, 40.960839843749994],
              [70.61835937500001, 41.00166015625],
              [70.57207031250002, 41.024804687499994],
              [70.55, 41.014892578125],
              [70.56875, 40.981835937499994],
              [70.62275390625001, 40.934423828125],
              [70.65253906250001, 40.93662109375],
            ],
          ],
          [
            [
              [71.20615234375, 39.892578125],
              [71.15625, 39.883447265624994],
              [71.06425781250002, 39.884912109374994],
              [71.01171875, 39.8951171875],
              [71.04365234375001, 39.976318359375],
              [71.04482421875002, 39.992529296875],
              [71.041015625, 39.994921875],
              [71.01445312500002, 40.005761718749994],
              [70.97441406250002, 40.038867187499996],
              [70.96064453125001, 40.08798828125],
              [70.97626953125001, 40.133251953125],
              [71.00546875, 40.152294921875],
              [71.02412109375001, 40.149169921875],
              [71.08037109375002, 40.079882812499996],
              [71.13027343750002, 40.05966796875],
              [71.22871093750001, 40.048144531249996],
              [71.179296875, 39.979833984375],
              [71.215625, 39.906787109374996],
              [71.20615234375, 39.892578125],
            ],
          ],
          [
            [
              [71.77968750000002, 39.950244140624996],
              [71.7529296875, 39.907128906249994],
              [71.70585937500002, 39.917431640625],
              [71.6689453125, 39.94609375],
              [71.68125, 39.968652343749994],
              [71.73652343750001, 39.98095703125],
              [71.76533203125001, 39.99326171875],
              [71.78994140625002, 39.9953125],
              [71.77968750000002, 39.950244140624996],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 5,
        labelrank: 3,
        sovereignt: "Kazakhstan",
        sov_a3: "KA1",
        adm0_dif: 1,
        level: 1,
        type: "Sovereignty",
        tlc: "1",
        admin: "Kazakhstan",
        adm0_a3: "KAZ",
        geou_dif: 0,
        geounit: "Kazakhstan",
        gu_a3: "KAZ",
        su_dif: 0,
        subunit: "Kazakhstan",
        su_a3: "KAZ",
        brk_diff: 0,
        name: "Kazakhstan",
        name_long: "Kazakhstan",
        brk_a3: "KAZ",
        brk_name: "Kazakhstan",
        brk_group: null,
        abbrev: "Kaz.",
        postal: "KZ",
        formal_en: "Republic of Kazakhstan",
        formal_fr: null,
        name_ciawf: "Kazakhstan",
        note_adm0: null,
        note_brk: null,
        name_sort: "Kazakhstan",
        name_alt: null,
        mapcolor7: 6,
        mapcolor8: 1,
        mapcolor9: 6,
        mapcolor13: 1,
        pop_est: 18513930,
        pop_rank: 14,
        pop_year: 2019,
        gdp_md: 181665,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        fips_10: "KZ",
        iso_a2: "KZ",
        iso_a2_eh: "KZ",
        iso_a3: "KAZ",
        iso_a3_eh: "KAZ",
        iso_n3: "398",
        iso_n3_eh: "398",
        un_a3: "398",
        wb_a2: "KZ",
        wb_a3: "KAZ",
        woe_id: -90,
        woe_id_eh: 23424871,
        woe_note: "Includes Baykonur Cosmodrome as an Admin-1 states provinces",
        adm0_iso: "KAZ",
        adm0_diff: null,
        adm0_tlc: "KAZ",
        adm0_a3_us: "KAZ",
        adm0_a3_fr: "KAZ",
        adm0_a3_ru: "KAZ",
        adm0_a3_es: "KAZ",
        adm0_a3_cn: "KAZ",
        adm0_a3_tw: "KAZ",
        adm0_a3_in: "KAZ",
        adm0_a3_np: "KAZ",
        adm0_a3_pk: "KAZ",
        adm0_a3_de: "KAZ",
        adm0_a3_gb: "KAZ",
        adm0_a3_br: "KAZ",
        adm0_a3_il: "KAZ",
        adm0_a3_ps: "KAZ",
        adm0_a3_sa: "KAZ",
        adm0_a3_eg: "KAZ",
        adm0_a3_ma: "KAZ",
        adm0_a3_pt: "KAZ",
        adm0_a3_ar: "KAZ",
        adm0_a3_jp: "KAZ",
        adm0_a3_ko: "KAZ",
        adm0_a3_vn: "KAZ",
        adm0_a3_tr: "KAZ",
        adm0_a3_id: "KAZ",
        adm0_a3_pl: "KAZ",
        adm0_a3_gr: "KAZ",
        adm0_a3_it: "KAZ",
        adm0_a3_nl: "KAZ",
        adm0_a3_se: "KAZ",
        adm0_a3_bd: "KAZ",
        adm0_a3_ua: "KAZ",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Central Asia",
        region_wb: "Europe & Central Asia",
        name_len: 10,
        long_len: 10,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 2.7,
        max_label: 7,
        label_x: 68.685548,
        label_y: 49.054149,
        ne_id: 1159320967,
        wikidataid: "Q232",
        name_ar: "كازاخستان",
        name_bn: "কাজাখস্তান",
        name_de: "Kasachstan",
        name_en: "Kazakhstan",
        name_es: "Kazajistán",
        name_fa: "قزاقستان",
        name_fr: "Kazakhstan",
        name_el: "Καζακστάν",
        name_he: "קזחסטן",
        name_hi: "कज़ाख़िस्तान",
        name_hu: "Kazahsztán",
        name_id: "Kazakhstan",
        name_it: "Kazakistan",
        name_ja: "カザフスタン",
        name_ko: "카자흐스탄",
        name_nl: "Kazachstan",
        name_pl: "Kazachstan",
        name_pt: "Cazaquistão",
        name_ru: "Казахстан",
        name_sv: "Kazakstan",
        name_tr: "Kazakistan",
        name_uk: "Казахстан",
        name_ur: "قازقستان",
        name_vi: "Kazakhstan",
        name_zh: "哈萨克斯坦",
        name_zht: "哈薩克",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "KAZ.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [50.18447265625002, 44.854638671874994],
              [50.14873046875002, 44.826464843749996],
              [50.0953125, 44.830615234374996],
              [49.9951171875, 44.936962890625],
              [50.023046875, 45.044726562499996],
              [50.059375, 45.066796875],
              [50.10986328125, 45.081933593749994],
              [50.11660156250002, 45.058251953124994],
              [50.04531250000002, 45.010009765625],
              [50.03886718750002, 44.949121093749994],
              [50.09814453125, 44.88154296875],
              [50.18447265625002, 44.854638671874994],
            ],
          ],
          [
            [
              [50.31171875000001, 44.972070312499994],
              [50.27724609375002, 44.95859375],
              [50.25615234375002, 45.022412109375],
              [50.294921875, 45.075927734375],
              [50.349707031250006, 45.0830078125],
              [50.33085937500002, 44.9984375],
              [50.31171875000001, 44.972070312499994],
            ],
          ],
          [
            [
              [52.68242187500002, 45.41181640625],
              [52.66484375000002, 45.401318359375],
              [52.59833984375001, 45.428173828125],
              [52.554296875, 45.473974609375],
              [52.60888671875, 45.52802734375],
              [52.65957031250002, 45.51806640625],
              [52.69296875, 45.460742187499996],
              [52.68242187500002, 45.41181640625],
            ],
          ],
          [
            [
              [70.94677734375, 42.248681640624994],
              [70.8603515625, 42.2072265625],
              [70.76455078125002, 42.194189453125],
              [70.71523437500002, 42.16865234375],
              [70.6625, 42.107470703124996],
              [70.61328125, 42.054736328124996],
              [70.58427734375002, 42.036035156249994],
              [70.54013671875, 42.039453125],
              [70.48906250000002, 42.0802734375],
              [70.416015625, 42.078564453125],
              [70.32890625000002, 42.027978515624994],
              [70.22587890625002, 41.94599609375],
              [70.09560546875002, 41.8205078125],
              [69.9599609375, 41.754052734374994],
              [69.7880859375, 41.697314453124996],
              [69.66386718750002, 41.672119140625],
              [69.56513671875001, 41.629052734374994],
              [69.4009765625, 41.541894531249994],
              [69.36835937500001, 41.490576171875],
              [69.24931640625002, 41.460253906249996],
              [69.15361328125002, 41.425244140625],
              [69.06494140625, 41.366943359375],
              [69.04345703125, 41.264111328125],
              [68.9869140625, 41.205029296875],
              [68.851171875, 41.123828125],
              [68.73710937500002, 41.041894531249994],
              [68.66279296875001, 40.9615234375],
              [68.58408203125, 40.876269531249996],
              [68.55927734375001, 40.829296875],
              [68.55654296875002, 40.76513671875],
              [68.59365234375002, 40.711279296875],
              [68.60068359375, 40.659960937499996],
              [68.57265625000002, 40.62265625],
              [68.495703125, 40.608642578125],
              [68.4150390625, 40.619433593749996],
              [68.29189453125002, 40.656103515625],
              [68.16025390625, 40.72177734375],
              [68.1123046875, 40.754052734374994],
              [68.04765625000002, 40.80927734375],
              [68.05703125000002, 40.860595703125],
              [68.09033203125, 40.960253906249996],
              [68.11308593750002, 41.028613281249996],
              [68.059375, 41.061279296875],
              [68.01972656250001, 41.096240234374996],
              [67.99140625000001, 41.130029296874994],
              [67.93574218750001, 41.196582031249996],
              [67.86572265625, 41.1802734375],
              [67.80507812500002, 41.163916015625],
              [67.73505859375001, 41.187255859375],
              [67.52802734375001, 41.177148437499994],
              [67.37158203125, 41.16953125],
              [67.225, 41.162353515625],
              [67.038671875, 41.1533203125],
              [66.81425781250002, 41.142382812499996],
              [66.74980468750002, 41.157080078125],
              [66.70966796875001, 41.179150390625],
              [66.66865234375001, 41.270751953125],
              [66.64531250000002, 41.3486328125],
              [66.60166015625, 41.4943359375],
              [66.57255859375002, 41.606982421874996],
              [66.53789062500002, 41.741259765624996],
              [66.51503906250002, 41.889404296875],
              [66.4986328125, 41.994873046875],
              [66.32880859375001, 41.998339843749996],
              [66.19316406250002, 42.001123046874994],
              [66.00957031250002, 42.0048828125],
              [66.01123046875, 42.088769531249994],
              [66.01318359375, 42.194482421874994],
              [66.01552734375002, 42.314794921875],
              [66.0498046875, 42.47275390625],
              [66.06269531250001, 42.60517578125],
              [66.07851562500002, 42.766650390624996],
              [66.0888671875, 42.873388671875],
              [66.10029296875001, 42.9908203125],
              [66.00566406250002, 42.95458984375],
              [65.90107421875001, 42.914501953125],
              [65.80302734375002, 42.876953125],
              [65.73564453125002, 42.972119140625],
              [65.67021484375002, 43.064599609374994],
              [65.57070312500002, 43.20517578125],
              [65.49619140625, 43.310546875],
              [65.36650390625002, 43.372021484375],
              [65.2705078125, 43.417529296874996],
              [65.1708984375, 43.494189453124996],
              [65.08486328125002, 43.573681640625],
              [65.003125, 43.649072265624994],
              [64.90546875000001, 43.714697265625],
              [64.81181640625002, 43.6939453125],
              [64.7060546875, 43.652978515624994],
              [64.6041015625, 43.6134765625],
              [64.49609375, 43.571630859375],
              [64.44316406250002, 43.551171875],
              [64.31816406250002, 43.558935546875],
              [64.20878906250002, 43.565722656249996],
              [64.01328125, 43.57783203125],
              [63.84814453125, 43.588134765625],
              [63.6796875, 43.5986328125],
              [63.44482421875, 43.613232421875],
              [63.20703125, 43.627978515624996],
              [63.04765625000002, 43.60849609375],
              [62.84619140625, 43.583886718749994],
              [62.63447265625001, 43.558007812499994],
              [62.459375, 43.53662109375],
              [62.237890625, 43.509570312499996],
              [62.07197265625001, 43.48935546875],
              [61.990234375, 43.492138671875],
              [61.88759765625002, 43.57724609375],
              [61.723242187500006, 43.71357421875],
              [61.623632812500006, 43.796191406249996],
              [61.52587890625, 43.877197265625],
              [61.38505859375002, 43.993945312499996],
              [61.271484375, 44.082275390625],
              [61.160742187500006, 44.168603515624994],
              [61.09707031250002, 44.2482421875],
              [61.06533203125002, 44.348388671875],
              [61.007910156250006, 44.393798828125],
              [60.87919921875002, 44.455078125],
              [60.74111328125002, 44.520849609375],
              [60.60292968750002, 44.58662109375],
              [60.46474609375002, 44.65244140625],
              [60.32666015625, 44.718212890625],
              [60.1884765625, 44.783984375],
              [60.05029296875, 44.849755859374994],
              [59.912207031250006, 44.915576171874996],
              [59.774023437500006, 44.981298828125],
              [59.63593750000001, 45.0470703125],
              [59.49785156250002, 45.112841796874996],
              [59.35957031250001, 45.178613281249994],
              [59.22148437500002, 45.244433593749996],
              [59.08339843750002, 45.310205078124994],
              [58.94511718750002, 45.3759765625],
              [58.80703125000002, 45.441796875],
              [58.6689453125, 45.507568359375],
              [58.555273437500006, 45.55537109375],
              [58.44941406250001, 45.542919921875],
              [58.291113281250006, 45.509423828124994],
              [58.12519531250001, 45.474365234375],
              [57.961035156250006, 45.439697265625],
              [57.66669921875001, 45.37744140625],
              [57.47734375000002, 45.337451171874996],
              [57.32929687500001, 45.303662109375],
              [57.17167968750002, 45.267724609374994],
              [56.96503906250001, 45.220605468749994],
              [56.79189453125002, 45.181054687499994],
              [56.58876953125002, 45.134765625],
              [56.4091796875, 45.093798828124996],
              [56.257910156250006, 45.059326171875],
              [56.10048828125002, 45.023388671875],
              [55.975683593750006, 44.994921875],
              [55.97578125000001, 44.765380859375],
              [55.97597656250002, 44.53583984375],
              [55.97607421875, 44.306298828124994],
              [55.976171875, 44.0767578125],
              [55.97626953125001, 43.847216796874996],
              [55.97636718750002, 43.617626953125],
              [55.97646484375002, 43.388085937499994],
              [55.9765625, 43.15859375],
              [55.976660156250006, 42.929052734375],
              [55.97675781250001, 42.699511718749996],
              [55.97685546875002, 42.469970703125],
              [55.97695312500002, 42.2404296875],
              [55.97705078125, 42.010888671874994],
              [55.977148437500006, 41.78134765625],
              [55.97734375000002, 41.5517578125],
              [55.97744140625002, 41.322216796875],
              [55.93496093750002, 41.324121093749994],
              [55.83906250000001, 41.310791015625],
              [55.67861328125002, 41.27880859375],
              [55.54521484375002, 41.262744140624996],
              [55.48701171875001, 41.272265625],
              [55.434375, 41.296289062499994],
              [55.38837890625001, 41.346923828125],
              [55.31972656250002, 41.4083984375],
              [55.249609375, 41.45810546875],
              [55.16230468750001, 41.56025390625],
              [55.10185546875002, 41.638720703124996],
              [54.95234375000001, 41.810009765625],
              [54.931640625, 41.864013671875],
              [54.90371093750002, 41.919091796874994],
              [54.85380859375002, 41.965185546875],
              [54.67792968750001, 42.07822265625],
              [54.47285156250001, 42.18017578125],
              [54.271875, 42.27998046875],
              [54.214941406250006, 42.30419921875],
              [54.12099609375002, 42.335205078125],
              [54.00517578125002, 42.335888671875],
              [53.926367187500006, 42.32978515625],
              [53.68535156250002, 42.296875],
              [53.50078125000002, 42.258251953125],
              [53.250097656250006, 42.205859375],
              [53.05585937500001, 42.147753906249996],
              [53.0125, 42.130712890625],
              [52.87050781250002, 42.060595703124996],
              [52.696875, 41.944384765624996],
              [52.49384765625001, 41.78037109375],
              [52.46757812500002, 41.885888671874994],
              [52.45859375, 42.04833984375],
              [52.46210937500001, 42.100634765624996],
              [52.5171875, 42.237158203125],
              [52.5732421875, 42.330859375],
              [52.61835937500001, 42.42822265625],
              [52.63847656250002, 42.5556640625],
              [52.59658203125002, 42.76015625],
              [52.55, 42.80546875],
              [52.49394531250002, 42.820263671875],
              [52.43427734375001, 42.824462890625],
              [52.32441406250001, 42.816162109375],
              [52.273046875, 42.7998046875],
              [52.183691406250006, 42.86875],
              [52.07558593750002, 42.879785156249994],
              [52.0185546875, 42.860546875],
              [51.96074218750002, 42.8505859375],
              [51.89824218750002, 42.86962890625],
              [51.84414062500002, 42.910449218749996],
              [51.81103515625, 42.954443359375],
              [51.78515625, 43.004345703125],
              [51.70039062500001, 43.104052734374996],
              [51.61601562500002, 43.158447265625],
              [51.51406250000002, 43.1705078125],
              [51.34785156250001, 43.167382812499994],
              [51.29541015625, 43.174121093749996],
              [51.29238281250002, 43.230712890625],
              [51.31337890625002, 43.3556640625],
              [51.31386718750002, 43.420849609375],
              [51.3017578125, 43.482373046875],
              [51.27412109375001, 43.53291015625],
              [51.23896484375001, 43.576708984374996],
              [51.1396484375, 43.648779296875],
              [51.06484375000002, 43.750146484374994],
              [50.93984375000002, 43.958544921874996],
              [50.83076171875001, 44.192773437499994],
              [50.78261718750002, 44.22802734375],
              [50.68496093750002, 44.265087890625],
              [50.471777343750006, 44.294775390625],
              [50.331152343750006, 44.325488281249996],
              [50.27558593750001, 44.355126953124994],
              [50.252539062500006, 44.406494140625],
              [50.2529296875, 44.4615234375],
              [50.26455078125002, 44.5265625],
              [50.297460937500006, 44.58154296875],
              [50.40947265625002, 44.6240234375],
              [50.652441406250006, 44.633349609374996],
              [50.8603515625, 44.628759765625],
              [51.048828125, 44.53046875],
              [51.11074218750002, 44.5078125],
              [51.17714843750002, 44.501367187499994],
              [51.31074218750001, 44.532421875],
              [51.37666015625001, 44.5412109375],
              [51.543554687500006, 44.531005859375],
              [51.49375, 44.577539062499994],
              [51.43105468750002, 44.601953125],
              [51.366308593750006, 44.599853515625],
              [51.31025390625001, 44.61875],
              [51.21816406250002, 44.708984375],
              [51.05791015625002, 44.811572265624996],
              [51.02070312500001, 44.85400390625],
              [51.009375, 44.921826171875],
              [51.04033203125002, 44.980322265625],
              [51.15371093750002, 45.040234375],
              [51.24990234375002, 45.1216796875],
              [51.294042968750006, 45.229785156249996],
              [51.33339843750002, 45.279589843749996],
              [51.41572265625001, 45.357861328125],
              [51.539648437500006, 45.34287109375],
              [51.73261718750001, 45.399462890624996],
              [52.04873046875002, 45.38837890625],
              [52.4267578125, 45.404638671875],
              [52.53105468750002, 45.3986328125],
              [52.77197265625, 45.343505859375],
              [52.910742187500006, 45.319726562499994],
              [53.07890625000002, 45.307519531249994],
              [53.20039062500001, 45.331982421875],
              [53.08574218750002, 45.407373046874994],
              [52.8375, 45.496728515624994],
              [52.77382812500002, 45.57275390625],
              [52.8875, 45.779541015625],
              [53.041601562500006, 45.96787109375],
              [53.13525390625, 46.191650390625],
              [53.108984375, 46.4140625],
              [53.06396484375, 46.47529296875],
              [53.07851562500002, 46.5474609375],
              [53.132421875, 46.608349609375],
              [53.17021484375002, 46.66904296875],
              [53.1375, 46.742041015625],
              [53.069433593750006, 46.8560546875],
              [53.03457031250002, 46.892919921875],
              [52.916015625, 46.954394531249996],
              [52.67763671875002, 46.95712890625],
              [52.48320312500002, 46.990673828125],
              [52.42031250000002, 46.963671875],
              [52.384863281250006, 46.922119140625],
              [52.34033203125, 46.894775390625],
              [52.18876953125002, 46.839501953124994],
              [52.13828125, 46.82861328125],
              [52.085546875, 46.839599609375],
              [52.01113281250002, 46.901904296874996],
              [51.94511718750002, 46.894873046875],
              [51.74453125000002, 46.933740234374994],
              [51.65009765625001, 47.01806640625],
              [51.615234375, 47.029931640624994],
              [51.29082031250002, 47.097314453124994],
              [51.17802734375002, 47.11015625],
              [50.920019531250006, 47.040673828124994],
              [50.73271484375002, 46.95166015625],
              [50.67988281250001, 46.938720703125],
              [50.58291015625002, 46.882275390625],
              [50.528417968750006, 46.873291015625],
              [50.472265625, 46.88291015625],
              [50.41933593750002, 46.8794921875],
              [50.30625, 46.794921875],
              [50.1015625, 46.696435546874994],
              [49.99980468750002, 46.63427734375],
              [49.886328125, 46.595654296875],
              [49.76054687500002, 46.571484375],
              [49.63154296875001, 46.567578125],
              [49.584375, 46.545214843749996],
              [49.43720703125001, 46.537255859374994],
              [49.34746093750002, 46.519140625],
              [49.34423828125, 46.485546875],
              [49.36210937500002, 46.410205078124996],
              [49.28583984375001, 46.436816406249996],
              [49.205664062500006, 46.385693359375],
              [49.23222656250002, 46.337158203125],
              [49.18427734375001, 46.348828125],
              [48.958984375, 46.442138671875],
              [48.77431640625002, 46.507958984374994],
              [48.61015625000002, 46.566455078124996],
              [48.586035156250006, 46.577099609375],
              [48.54121093750001, 46.605615234374994],
              [48.50917968750002, 46.649951171874996],
              [48.50234375000002, 46.698632812499994],
              [48.5185546875, 46.734326171875],
              [48.55839843750002, 46.757128906249996],
              [48.60527343750002, 46.765917968749996],
              [48.647070312500006, 46.758691406249994],
              [48.69355468750001, 46.73681640625],
              [48.7763671875, 46.710351562499994],
              [48.88359375000002, 46.705419921875],
              [48.950292968750006, 46.72578125],
              [48.959375, 46.774609375],
              [48.83183593750002, 46.954931640625],
              [48.71435546875, 47.100488281249994],
              [48.600683593750006, 47.2623046875],
              [48.55253906250002, 47.32099609375],
              [48.4130859375, 47.456494140625],
              [48.27568359375002, 47.58994140625],
              [48.1669921875, 47.708789062499996],
              [48.109960937500006, 47.745410156249996],
              [47.934667968750006, 47.760693359375],
              [47.600195312500006, 47.789990234375],
              [47.48193359375, 47.80390625],
              [47.387304687500006, 47.76865234375],
              [47.29238281250002, 47.74091796875],
              [47.20205078125002, 47.79248046875],
              [47.13076171875002, 47.876757812499996],
              [47.09326171875, 47.947705078125],
              [47.11152343750001, 48.0201171875],
              [47.11904296875002, 48.127001953124996],
              [47.06464843750001, 48.232470703124996],
              [47.00429687500002, 48.284472656249996],
              [46.853125, 48.323583984375],
              [46.66093750000002, 48.412255859374994],
              [46.60917968750002, 48.573876953124994],
              [46.70263671875, 48.805566406249994],
              [46.85292968750002, 48.969628906249994],
              [46.96220703125002, 49.038330078125],
              [47.014257812500006, 49.09833984375],
              [47.031347656250006, 49.150292968749994],
              [47.018164062500006, 49.19990234375],
              [46.95341796875002, 49.252587890624994],
              [46.85292968750002, 49.303857421874994],
              [46.80205078125002, 49.367089843749994],
              [46.82314453125002, 49.502246093749996],
              [46.88955078125002, 49.69697265625],
              [46.99199218750002, 49.852734375],
              [47.12958984375001, 49.9390625],
              [47.24833984375002, 50.000878906249994],
              [47.29521484375002, 50.058496093749994],
              [47.29765625000002, 50.140234375],
              [47.29472656250002, 50.21748046875],
              [47.32646484375002, 50.27353515625],
              [47.37636718750002, 50.318115234375],
              [47.42919921875, 50.357958984374996],
              [47.50361328125001, 50.402734375],
              [47.599609375, 50.41357421875],
              [47.70576171875001, 50.377978515624996],
              [47.849609375, 50.28232421875],
              [48.06074218750001, 50.093603515625],
              [48.18134765625001, 49.970019531249996],
              [48.22480468750001, 49.931933593749996],
              [48.3349609375, 49.858251953125],
              [48.43427734375001, 49.828515625],
              [48.6, 49.87470703125],
              [48.75898437500001, 49.9283203125],
              [48.81025390625001, 49.96240234375],
              [48.84326171875, 50.013134765625],
              [48.81796875, 50.099853515625],
              [48.784765625, 50.1564453125],
              [48.74941406250002, 50.228466796875],
              [48.70048828125002, 50.353759765625],
              [48.666015625, 50.550341796874996],
              [48.625097656250006, 50.612695312499994],
              [48.65517578125002, 50.619873046875],
              [48.73476562500002, 50.606884765625],
              [48.80839843750002, 50.601318359375],
              [48.91376953125001, 50.644580078124996],
              [49.058691406250006, 50.72607421875],
              [49.32343750000001, 50.851708984374994],
              [49.379492187500006, 50.93466796875],
              [49.42460937500002, 51.027001953124994],
              [49.498046875, 51.08359375],
              [49.66630859375002, 51.102294921875],
              [49.822265625, 51.131884765624996],
              [49.93232421875001, 51.197167968749994],
              [50.10488281250002, 51.25458984375],
              [50.246875, 51.289501953125],
              [50.30927734375001, 51.321582031249996],
              [50.35371093750001, 51.3697265625],
              [50.51630859375001, 51.505615234375],
              [50.64394531250002, 51.589160156249996],
              [50.75615234375002, 51.675146484375],
              [50.7939453125, 51.72919921875],
              [50.882421875, 51.719189453125],
              [51.01787109375002, 51.681640625],
              [51.16347656250002, 51.6474609375],
              [51.26992187500002, 51.594482421875],
              [51.29072265625001, 51.540185546874994],
              [51.30107421875002, 51.497412109375],
              [51.34453125000002, 51.475341796875],
              [51.39599609375, 51.4712890625],
              [51.47343750000002, 51.48203125],
              [51.60908203125001, 51.483984375],
              [51.775390625, 51.554248046874996],
              [52.00712890625002, 51.672705078125],
              [52.21914062500002, 51.709375],
              [52.3310546875, 51.681298828125],
              [52.42304687500001, 51.59423828125],
              [52.496191406250006, 51.512158203125],
              [52.57119140625002, 51.481640625],
              [52.617773437500006, 51.480761718749996],
              [52.63515625000002, 51.479541015624996],
              [52.728125, 51.49814453125],
              [52.73505859375001, 51.497900390625],
              [52.82050781250001, 51.494580078125],
              [52.90263671875002, 51.466943359374994],
              [53.03837890625002, 51.463720703125],
              [53.22734375000002, 51.4849609375],
              [53.24726562500001, 51.493603515625],
              [53.33808593750001, 51.482373046875],
              [53.44863281250002, 51.44453125],
              [53.53466796875, 51.399560546874994],
              [53.688085937500006, 51.251806640625],
              [53.776464843750006, 51.213720703125],
              [53.95683593750002, 51.161181640624996],
              [54.04150390625, 51.115185546875],
              [54.139746093750006, 51.040771484375],
              [54.19111328125001, 50.995703125],
              [54.2978515625, 50.9140625],
              [54.421484375, 50.780322265624996],
              [54.443359375, 50.67392578125],
              [54.47148437500002, 50.583789062499996],
              [54.51738281250002, 50.541162109374994],
              [54.555273437500006, 50.535791015624994],
              [54.59619140625, 50.550683593749994],
              [54.63613281250002, 50.591601562499996],
              [54.65, 50.66015625],
              [54.63789062500001, 50.781054687499996],
              [54.60625, 50.8798828125],
              [54.565625, 50.911279296874994],
              [54.54609375000001, 50.946044921875],
              [54.57294921875001, 50.990234375],
              [54.6416015625, 51.011572265625],
              [54.727148437500006, 50.998095703124996],
              [54.86796875000002, 50.941357421875],
              [55.01484375000001, 50.869775390624994],
              [55.19521484375002, 50.7447265625],
              [55.36113281250002, 50.665283203125],
              [55.54228515625002, 50.601806640625],
              [55.68623046875001, 50.582861328125],
              [55.79765625000002, 50.60205078125],
              [55.92919921875, 50.653759765625],
              [56.04970703125002, 50.713525390624994],
              [56.1044921875, 50.776269531249994],
              [56.14394531250002, 50.844628906249994],
              [56.32558593750002, 50.936083984374996],
              [56.49140625000001, 51.01953125],
              [56.56689453125, 51.0044921875],
              [56.620214843750006, 50.980859375],
              [56.79033203125002, 51.031591796875],
              [56.849609375, 51.045556640624994],
              [57.01171875, 51.065185546875],
              [57.17900390625002, 51.036035156249994],
              [57.3125, 50.946533203125],
              [57.44218750000002, 50.8888671875],
              [57.55781250000001, 50.895556640624996],
              [57.65380859375, 50.925146484375],
              [57.71699218750001, 50.98095703125],
              [57.76484375000001, 51.046875],
              [57.82890625000002, 51.089013671874994],
              [57.8388671875, 51.091650390625],
              [58.04511718750001, 51.06884765625],
              [58.17470703125002, 51.072265625],
              [58.1884765625, 51.08173828125],
              [58.35917968750002, 51.063818359375],
              [58.547460937500006, 50.971044921875],
              [58.66455078125, 50.868310546874994],
              [58.8140625, 50.737207031249994],
              [58.88369140625002, 50.69443359375],
              [58.98486328125, 50.676123046875],
              [59.06435546875002, 50.668212890625],
              [59.1708984375, 50.647900390625],
              [59.45234375000001, 50.620410156249996],
              [59.4951171875, 50.604296875],
              [59.52392578125, 50.5828125],
              [59.49785156250002, 50.511083984375],
              [59.523046875, 50.49287109375],
              [59.75117187500001, 50.5439453125],
              [59.81240234375002, 50.58203125],
              [59.887792968750006, 50.690185546875],
              [59.955175781250006, 50.799267578125],
              [60.00527343750002, 50.839697265625],
              [60.05859375, 50.85029296875],
              [60.11210937500002, 50.8341796875],
              [60.18671875000001, 50.769775390625],
              [60.2880859375, 50.704150390624996],
              [60.4248046875, 50.679150390625],
              [60.50849609375001, 50.669189453125],
              [60.63798828125002, 50.663720703124994],
              [60.94228515625002, 50.6955078125],
              [61.22685546875002, 50.774804687499994],
              [61.38945312500002, 50.86103515625],
              [61.46503906250001, 50.990234375],
              [61.51220703125, 51.137011718749996],
              [61.585058593750006, 51.2296875],
              [61.5546875, 51.324609375],
              [61.41132812500001, 51.41474609375],
              [61.36308593750002, 51.44189453125],
              [61.01484375000001, 51.4923828125],
              [60.99335937500001, 51.528710937499994],
              [60.97353515625002, 51.537060546875],
              [60.63037109375, 51.616943359375],
              [60.46474609375002, 51.651171875],
              [60.41835937500002, 51.70390625],
              [60.3875, 51.772998046874996],
              [60.280371093750006, 51.834619140625],
              [60.067480468750006, 51.890625],
              [60.0302734375, 51.933251953125],
              [60.065527343750006, 51.97646484375],
              [60.23369140625002, 52.02451171875],
              [60.42548828125001, 52.1255859375],
              [60.49931640625002, 52.146337890625],
              [60.67031250000002, 52.150830078125],
              [60.82841796875002, 52.2333984375],
              [60.937597656250006, 52.280566406249996],
              [60.99453125000002, 52.336865234375],
              [60.9794921875, 52.394775390625],
              [60.8212890625, 52.56982421875],
              [60.7744140625, 52.67578125],
              [60.80234375, 52.7447265625],
              [60.89326171875001, 52.81943359375],
              [60.94472656250002, 52.86015625],
              [61.00654296875001, 52.933349609375],
              [61.047460937500006, 52.972460937499996],
              [61.20693359375002, 52.9890625],
              [61.40078125000002, 52.99599609375],
              [61.53359375000002, 52.978515625],
              [61.719335937500006, 52.969384765625],
              [61.88857421875002, 52.955908203125],
              [61.97421875, 52.94375],
              [62.037109375, 52.966113281249996],
              [62.08271484375001, 53.005419921874996],
              [62.0810546875, 53.057421875],
              [62.0146484375, 53.107861328125],
              [61.766210937500006, 53.17392578125],
              [61.65986328125001, 53.228466796875],
              [61.576171875, 53.222460937499996],
              [61.43681640625002, 53.239404296875],
              [61.31093750000002, 53.275195312499996],
              [61.19921875, 53.287158203124996],
              [61.16279296875001, 53.336767578125],
              [61.18593750000002, 53.406201171875],
              [61.22890625000002, 53.4458984375],
              [61.311621093750006, 53.46572265625],
              [61.400976562500006, 53.455810546875],
              [61.49853515625, 53.484667968749996],
              [61.52656250000001, 53.5015625],
              [61.53496093750002, 53.523291015625],
              [61.519140625, 53.554492187499996],
              [61.47412109375, 53.5802734375],
              [61.40996093750002, 53.587060546875],
              [61.33613281250001, 53.565185546875],
              [61.24794921875002, 53.5509765625],
              [61.09853515625002, 53.58310546875],
              [60.9794921875, 53.621728515625],
              [60.98554687500001, 53.657421875],
              [61.07353515625002, 53.71044921875],
              [61.11318359375002, 53.753466796874996],
              [61.11318359375002, 53.81298828125],
              [61.11318359375002, 53.882470703125],
              [61.14375, 53.963818359375],
              [61.231054687500006, 54.019482421875],
              [61.33369140625001, 54.049267578125],
              [61.59814453125, 53.994921875],
              [61.9287109375, 53.946484375],
              [61.98564453125002, 53.954394531249996],
              [62.00234375000002, 53.979931640625],
              [62.04023437500001, 54.00263671875],
              [62.4990234375, 54.01318359375],
              [62.58828125000002, 54.04443359375],
              [62.63271484375002, 54.069287109375],
              [63.07392578125001, 54.105224609375],
              [63.1265625, 54.1392578125],
              [63.19130859375002, 54.171044921875],
              [63.29267578125001, 54.170458984374996],
              [63.413671875, 54.183203125],
              [63.58203125, 54.221923828125],
              [63.70136718750001, 54.243212890624996],
              [63.72119140625, 54.24501953125],
              [63.84707031250002, 54.236474609375],
              [64.00390625, 54.26708984375],
              [64.03740234375002, 54.279736328125],
              [64.06289062500002, 54.3029296875],
              [64.19941406250001, 54.347412109375],
              [64.46123046875002, 54.3841796875],
              [64.52509765625001, 54.362158203125],
              [64.64990234375, 54.35224609375],
              [64.80927734375001, 54.3685546875],
              [64.9267578125, 54.396630859375],
              [64.99541015625002, 54.36875],
              [65.08837890625, 54.340185546875],
              [65.1578125, 54.364404296875],
              [65.19218750000002, 54.44111328125],
              [65.23740234375, 54.516064453125],
              [65.31591796875, 54.5515625],
              [65.378125, 54.564453125],
              [65.434375, 54.593310546874996],
              [65.47695312500002, 54.623291015625],
              [65.70781250000002, 54.618701171874996],
              [65.91425781250001, 54.693310546875],
              [65.9546875, 54.659521484375],
              [66.22265625, 54.6673828125],
              [66.55537109375001, 54.7154296875],
              [66.7544921875, 54.737890625],
              [67.09833984375001, 54.78818359375],
              [67.25732421875, 54.82880859375],
              [67.48466796875002, 54.8544921875],
              [67.693359375, 54.872412109375],
              [67.82988281250002, 54.9435546875],
              [67.93994140625, 54.9537109375],
              [68.073828125, 54.9595703125],
              [68.155859375, 54.976708984375],
              [68.209375, 55.00302734375],
              [68.24404296875002, 55.05244140625],
              [68.22529296875001, 55.115234375],
              [68.20625, 55.1609375],
              [68.30195312500001, 55.1865234375],
              [68.4384765625, 55.19443359375],
              [68.52480468750002, 55.204833984375],
              [68.712890625, 55.30849609375],
              [68.84296875000001, 55.358349609375],
              [68.97724609375001, 55.389599609375],
              [69.24697265625002, 55.372509765625],
              [69.49326171875, 55.356884765625],
              [69.740234375, 55.307373046875],
              [69.87021484375, 55.245654296874996],
              [69.98173828125002, 55.199072265625],
              [70.08740234375, 55.1767578125],
              [70.18242187500002, 55.162451171875],
              [70.29335937500002, 55.18359375],
              [70.37148437500002, 55.212255859375],
              [70.41718750000001, 55.253173828125],
              [70.486328125, 55.282373046875],
              [70.73808593750002, 55.30517578125],
              [70.79033203125002, 55.2611328125],
              [70.91015625, 55.127978515624996],
              [70.991796875, 54.950488281249996],
              [71.12626953125002, 54.7150390625],
              [71.185546875, 54.59931640625],
              [71.1591796875, 54.538623046874996],
              [71.159765625, 54.455419921875],
              [71.15214843750002, 54.3640625],
              [71.052734375, 54.260498046875],
              [71.09316406250002, 54.212207031249996],
              [71.33642578125, 54.158349609375],
              [71.67714843750002, 54.178027343749996],
              [71.88740234375001, 54.221484375],
              [72.0044921875, 54.2056640625],
              [72.065625, 54.231640625],
              [72.10537109375002, 54.308447265625],
              [72.18603515625, 54.325634765625],
              [72.269140625, 54.272119140625],
              [72.32949218750002, 54.181445312499996],
              [72.3873046875, 54.123046875],
              [72.38300781250001, 54.053662109375],
              [72.404296875, 53.964453125],
              [72.44677734375, 53.941845703125],
              [72.5302734375, 53.97578125],
              [72.5859375, 53.995947265625],
              [72.59921875, 54.023046875],
              [72.57558593750002, 54.056494140625],
              [72.56425781250002, 54.0904296875],
              [72.58271484375001, 54.12158203125],
              [72.62226562500001, 54.134326171874996],
              [72.74101562500002, 54.12451171875],
              [72.9140625, 54.10732421875],
              [73.11933593750001, 53.980761718749996],
              [73.22988281250002, 53.9578125],
              [73.27656250000001, 53.955615234374996],
              [73.38066406250002, 53.962841796875],
              [73.50566406250002, 53.999316406249996],
              [73.58994140625, 54.044970703124996],
              [73.61796875000002, 54.0673828125],
              [73.66640625000002, 54.0634765625],
              [73.71240234375, 54.0423828125],
              [73.71552734375001, 53.99619140625],
              [73.67890625000001, 53.929443359375],
              [73.55419921875, 53.868310546875],
              [73.3994140625, 53.811474609375],
              [73.3056640625, 53.7072265625],
              [73.2857421875, 53.598388671875],
              [73.32685546875001, 53.5431640625],
              [73.3619140625, 53.506201171875],
              [73.371875, 53.454394531249996],
              [73.40693359375001, 53.447558593749996],
              [73.46992187500001, 53.468896484375],
              [73.64296875000002, 53.57626953125],
              [73.73115234375001, 53.602783203125],
              [73.858984375, 53.6197265625],
              [74.06865234375002, 53.61142578125],
              [74.2099609375, 53.576464843749996],
              [74.27734375, 53.527734375],
              [74.3515625, 53.487646484375],
              [74.40273437500002, 53.504443359374996],
              [74.429296875, 53.550732421875],
              [74.43046875000002, 53.6037109375],
              [74.45195312500002, 53.647265625],
              [74.68144531250002, 53.75439453125],
              [74.83417968750001, 53.82568359375],
              [74.88681640625, 53.834033203124996],
              [74.98896484375001, 53.81923828125],
              [75.05214843750002, 53.826708984374996],
              [75.22021484375, 53.893798828125],
              [75.37705078125, 53.9701171875],
              [75.39238281250002, 54.021728515625],
              [75.39814453125001, 54.068505859375],
              [75.43720703125001, 54.089648437499996],
              [75.6568359375, 54.106005859374996],
              [75.69287109375, 54.114794921874996],
              [75.88066406250002, 54.16796875],
              [76.14052734375002, 54.258544921875],
              [76.2666015625, 54.311962890625],
              [76.49648437500002, 54.335693359375],
              [76.53916015625003, 54.35107421875],
              [76.61552734374999, 54.387109375],
              [76.759375, 54.436865234375],
              [76.83730468750002, 54.4423828125],
              [76.78896484375002, 54.321875],
              [76.70302734375002, 54.182470703125],
              [76.65458984374999, 54.145263671875],
              [76.42167968749999, 54.151513671875],
              [76.42207031250001, 54.113525390625],
              [76.45859375000003, 54.0552734375],
              [76.48476562500002, 54.02255859375],
              [76.51308593750002, 53.993212890624996],
              [76.57568359375, 53.942529296875],
              [76.82070312500002, 53.82265625],
              [77.13242187500003, 53.6701171875],
              [77.46923828125, 53.498779296875],
              [77.70439453124999, 53.379150390625],
              [77.79941406250003, 53.317431640624996],
              [77.85996093750003, 53.269189453125],
              [78.03349609374999, 53.094970703125],
              [78.19804687499999, 52.9296875],
              [78.47548828125002, 52.638427734375],
              [78.72148437499999, 52.35703125],
              [78.99208984375002, 52.047412109374996],
              [79.14873046874999, 51.868115234375],
              [79.46884765625003, 51.493115234375],
              [79.55429687500003, 51.377978515624996],
              [79.71640625000003, 51.160009765625],
              [79.85966796874999, 50.95546875],
              [79.98623046875002, 50.774560546874994],
              [80.06591796875, 50.758203125],
              [80.07207031249999, 50.807275390624994],
              [80.08632812500002, 50.839990234374994],
              [80.12724609374999, 50.858349609375],
              [80.22021484375, 50.911767578124994],
              [80.27041015625002, 50.924609375],
              [80.34521484375, 50.919091796874994],
              [80.42363281249999, 50.9462890625],
              [80.45224609375003, 50.997607421874996],
              [80.43359375, 51.092626953125],
              [80.42148437500003, 51.136376953124994],
              [80.44804687499999, 51.183349609375],
              [80.49101562499999, 51.2017578125],
              [80.55068359375002, 51.216601562499996],
              [80.60546875, 51.22421875],
              [80.65048828125003, 51.27734375],
              [80.73525390625002, 51.293408203125],
              [80.81308593750003, 51.283496093749996],
              [80.87734375000002, 51.2814453125],
              [80.93408203125, 51.2427734375],
              [80.965625, 51.189794921875],
              [81.02675781250002, 51.185693359374994],
              [81.12724609374999, 51.191064453124994],
              [81.14101562500002, 51.14658203125],
              [81.11240234375003, 51.07236328125],
              [81.07753906250002, 51.014941406249996],
              [81.07148437500001, 50.96875],
              [81.12460937500003, 50.9462890625],
              [81.31914062499999, 50.96640625],
              [81.38828125000003, 50.956494140625],
              [81.41015625, 50.909765625],
              [81.43769531250001, 50.871044921875],
              [81.45156250000002, 50.823681640625],
              [81.43144531249999, 50.771142578124994],
              [81.46591796875003, 50.73984375],
              [81.63388671875003, 50.739111328125],
              [81.75205078125003, 50.764404296875],
              [81.93369140625003, 50.766357421875],
              [82.09804687500002, 50.710839843749994],
              [82.2119140625, 50.71943359375],
              [82.32636718750001, 50.74189453125],
              [82.49394531249999, 50.727587890624996],
              [82.61171875000002, 50.771484375],
              [82.69296875000003, 50.826318359374994],
              [82.71855468749999, 50.869482421875],
              [82.76083984375003, 50.893359375],
              [82.91904296875003, 50.893115234374996],
              [83.01923828125001, 50.897265625],
              [83.0927734375, 50.960595703124994],
              [83.16025390625003, 50.989208984375],
              [83.27373046874999, 50.994580078125],
              [83.35732421875002, 50.994580078125],
              [83.58144531250002, 50.9357421875],
              [83.7177734375, 50.887158203125],
              [83.85976562500002, 50.818017578124994],
              [83.94511718749999, 50.774658203125],
              [84.00234375000002, 50.67685546875],
              [84.09931640625001, 50.604736328125],
              [84.17597656250001, 50.520556640624996],
              [84.19453125000001, 50.437451171875],
              [84.2578125, 50.288232421874994],
              [84.3232421875, 50.239160156249994],
              [84.40097656250003, 50.239160156249994],
              [84.4990234375, 50.21875],
              [84.60732421875002, 50.202392578125],
              [84.83896484375003, 50.09130859375],
              [84.92402343750001, 50.08798828125],
              [84.98945312500001, 50.061425781249994],
              [84.99970703125001, 50.010302734374996],
              [84.97519531250003, 49.951074218749994],
              [85.00078124999999, 49.894140625],
              [85.07646484374999, 49.821630859375],
              [85.13652343749999, 49.750732421875],
              [85.21015625000001, 49.66484375],
              [85.23261718750001, 49.6158203125],
              [85.29189453125002, 49.599462890625],
              [85.37158203125, 49.623925781249994],
              [85.49843750000002, 49.605371093749994],
              [85.88046875000003, 49.556542968749994],
              [85.93359375, 49.550439453124994],
              [85.97441406249999, 49.499316406249996],
              [86.02958984374999, 49.50341796875],
              [86.09296875000001, 49.50546875],
              [86.18085937500001, 49.499316406249996],
              [86.2421875, 49.546337890625],
              [86.29238281250002, 49.5875],
              [86.41796875, 49.638476562499996],
              [86.52226562499999, 49.707763671875],
              [86.61015624999999, 49.769140625],
              [86.67548828125001, 49.777294921875],
              [86.72871093750001, 49.748681640624994],
              [86.73066406250001, 49.695556640625],
              [86.66533203124999, 49.656689453125],
              [86.6142578125, 49.609716796875],
              [86.62646484375, 49.5626953125],
              [86.71435546875, 49.55859375],
              [86.81210937500003, 49.487890625],
              [86.95292968749999, 49.322070312499996],
              [87.0009765625, 49.2873046875],
              [87.07060546874999, 49.25458984375],
              [87.14804687500003, 49.239794921874996],
              [87.23369140624999, 49.216162109375],
              [87.296875, 49.14765625],
              [87.32285156250003, 49.085791015625],
              [87.22998046875, 49.105859375],
              [87.04853515625001, 49.109912109374996],
              [86.93798828125, 49.097558593749994],
              [86.88593750000001, 49.090576171875],
              [86.80830078125001, 49.049707031249994],
              [86.753125, 49.008837890624996],
              [86.72861328125003, 48.939355468749994],
              [86.7578125, 48.860742187499994],
              [86.71796875000001, 48.697167968749994],
              [86.66376953125001, 48.635546875],
              [86.54941406250003, 48.528613281249996],
              [86.48330078125002, 48.50537109375],
              [86.37255859375, 48.486230468749994],
              [86.265625, 48.454541015625],
              [86.05615234375, 48.423730468749994],
              [85.82988281249999, 48.408056640625],
              [85.74941406250002, 48.385058593749996],
              [85.69218749999999, 48.311816406249996],
              [85.65156250000001, 48.250537109374996],
              [85.62636718750002, 48.204003906249994],
              [85.56230468749999, 48.05185546875],
              [85.52597656250003, 47.915625],
              [85.56162109375003, 47.746484375],
              [85.58828125000002, 47.558496093749994],
              [85.58662109375001, 47.49365234375],
              [85.64179687500001, 47.397412109375],
              [85.66982421875002, 47.33837890625],
              [85.65664062500002, 47.254638671875],
              [85.57724609375003, 47.1884765625],
              [85.52968750000002, 47.10078125],
              [85.48476562500002, 47.063525390624996],
              [85.35537109375002, 47.046728515625],
              [85.23349609375003, 47.036376953125],
              [85.11054687500001, 46.961230468749996],
              [85.01220703125, 46.909228515624996],
              [84.85820312499999, 46.843164062499994],
              [84.7861328125, 46.830712890624994],
              [84.74599609375002, 46.86435546875],
              [84.71953124999999, 46.939355468749994],
              [84.66660156250003, 46.97236328125],
              [84.59228515625, 46.974951171875],
              [84.53242187500001, 46.97578125],
              [84.3388671875, 46.996142578124996],
              [84.21513671874999, 46.9947265625],
              [84.1220703125, 46.97861328125],
              [84.01601562500002, 46.970507812499996],
              [83.83261718750003, 46.997851562499996],
              [83.71396484375003, 47.021044921874996],
              [83.63408203124999, 47.043212890625],
              [83.44355468750001, 47.108642578125],
              [83.19306640625001, 47.186572265624996],
              [83.09033203125, 47.209375],
              [83.02949218750001, 47.1859375],
              [83.02011718750003, 47.141455078125],
              [83.00410156250001, 47.033496093749996],
              [82.97490234374999, 46.966015625],
              [82.8, 46.624462890625],
              [82.69218749999999, 46.386669921875],
              [82.55507812500002, 46.15869140625],
              [82.51171875, 46.005810546875],
              [82.4296875, 45.811914062499994],
              [82.34814453125, 45.671533203124994],
              [82.31523437499999, 45.594921875],
              [82.31220703125001, 45.563720703125],
              [82.32666015625, 45.519921875],
              [82.45166015625, 45.471972656249996],
              [82.58251953125, 45.442578125],
              [82.61162109374999, 45.424267578125],
              [82.62578124999999, 45.374414062499994],
              [82.62109375, 45.293115234374994],
              [82.59697265624999, 45.215966796874994],
              [82.55898437500002, 45.155419921874994],
              [82.521484375, 45.12548828125],
              [82.47871093750001, 45.123583984374996],
              [82.39667968750001, 45.162451171875],
              [82.32343750000001, 45.205859375],
              [82.2666015625, 45.219091796875],
              [82.12275390625001, 45.194873046874996],
              [81.9892578125, 45.161865234375],
              [81.94492187500003, 45.16083984375],
              [81.86748046874999, 45.182080078125],
              [81.78964843750003, 45.226025390625],
              [81.75888671875003, 45.310839843749996],
              [81.69199218750003, 45.349365234375],
              [81.60205078125, 45.310839843749996],
              [81.33476562499999, 45.24619140625],
              [81.04033203124999, 45.169140625],
              [80.85332031249999, 45.129296875],
              [80.78007812499999, 45.135546875],
              [80.634765625, 45.126513671874996],
              [80.50917968750002, 45.10498046875],
              [80.41494140625002, 45.075097656249994],
              [80.22822265625001, 45.033984375],
              [80.05917968750003, 45.0064453125],
              [79.9501953125, 44.944091796875],
              [79.871875, 44.8837890625],
              [79.87529296874999, 44.86083984375],
              [79.93212890625, 44.8251953125],
              [79.99716796875003, 44.797216796875],
              [80.12783203125002, 44.803759765624996],
              [80.25507812500001, 44.80810546875],
              [80.36083984375, 44.7703125],
              [80.45546875000002, 44.74609375],
              [80.48154296875003, 44.714648437499996],
              [80.45546875000002, 44.68408203125],
              [80.40058593750001, 44.676904296874994],
              [80.38144531250003, 44.655419921874994],
              [80.39101562500002, 44.626806640625],
              [80.35507812500003, 44.552001953125],
              [80.33632812500002, 44.438378906249994],
              [80.35488281250002, 44.326513671875],
              [80.36533203125003, 44.223291015624994],
              [80.35898437500003, 44.171289062499994],
              [80.35527343749999, 44.097265625],
              [80.39580078124999, 44.047167968749996],
              [80.43154296875002, 43.9517578125],
              [80.49599609375002, 43.89208984375],
              [80.59345703125001, 43.685107421874996],
              [80.65078125000002, 43.56416015625],
              [80.70380859375001, 43.427050781249996],
              [80.66542968750002, 43.352978515625],
              [80.66777343749999, 43.31005859375],
              [80.72978515624999, 43.274267578125],
              [80.75703125000001, 43.204345703125],
              [80.78574218750003, 43.161572265625],
              [80.77773437500002, 43.118945312499996],
              [80.75117187500001, 43.102490234375],
              [80.61699218749999, 43.128271484375],
              [80.50703125000001, 43.085791015625],
              [80.39023437500003, 43.043115234374994],
              [80.37451171875, 43.020410156249994],
              [80.37128906250001, 42.99560546875],
              [80.38339843750003, 42.973779296874994],
              [80.45068359375, 42.935546875],
              [80.54375, 42.91171875],
              [80.53896484375002, 42.873486328125],
              [80.42402343750001, 42.855761718749996],
              [80.25029296874999, 42.797265625],
              [80.20224609375003, 42.73447265625],
              [80.1650390625, 42.66552734375],
              [80.16191406249999, 42.625537109374996],
              [80.17929687500003, 42.518359375],
              [80.20576171875001, 42.3994140625],
              [80.25507812500001, 42.274169921875],
              [80.25908203124999, 42.235400390624996],
              [80.23300781250003, 42.2078125],
              [80.209375, 42.1900390625],
              [80.0712890625, 42.302978515625],
              [79.92109375000001, 42.413134765624996],
              [79.80341796875001, 42.4384765625],
              [79.59843749999999, 42.456640625],
              [79.49013671875002, 42.457568359374996],
              [79.42822265625, 42.48349609375],
              [79.36777343750003, 42.547216796875],
              [79.29550781250003, 42.604833984375],
              [79.20302734375002, 42.666015625],
              [79.16484374999999, 42.759033203125],
              [79.12666015625001, 42.775732421875],
              [79.05986328124999, 42.763818359374994],
              [78.94794921875001, 42.76669921875],
              [78.88515625000002, 42.77490234375],
              [78.79150390625, 42.790820312499996],
              [78.64228515625001, 42.8287109375],
              [78.52421874999999, 42.864648437499994],
              [78.3759765625, 42.871484375],
              [78.2900390625, 42.86435546875],
              [78.02314453125001, 42.85751953125],
              [77.80166015625002, 42.89521484375],
              [77.62246093750002, 42.902246093749994],
              [77.51220703125, 42.900048828124994],
              [77.45927734374999, 42.904736328125],
              [77.36855468750002, 42.904443359374994],
              [77.23554687500001, 42.912646484374996],
              [77.05732421875001, 42.970654296875],
              [76.98808593749999, 42.973583984375],
              [76.94404296875001, 42.971484375],
              [76.646484375, 42.92880859375],
              [76.50917968750002, 42.918896484375],
              [76.21816406250002, 42.923730468749994],
              [75.9322265625, 42.928515625],
              [75.84033203125, 42.9375],
              [75.78955078125, 42.932910156249996],
              [75.68173828125, 42.83046875],
              [75.63564453125002, 42.814599609374994],
              [75.3662109375, 42.836962890624996],
              [75.04765625000002, 42.90439453125],
              [74.81757812500001, 42.978173828124994],
              [74.62226562500001, 43.056201171874996],
              [74.3638671875, 43.179443359375],
              [74.20908203125, 43.240380859374994],
              [74.18681640625002, 43.2052734375],
              [74.14589843750002, 43.194091796875],
              [74.08623046875002, 43.188623046874994],
              [73.94921875, 43.19501953125],
              [73.88603515625002, 43.132568359375],
              [73.71855468750002, 43.087890625],
              [73.61201171875001, 43.047900390624996],
              [73.55625, 43.002783203125],
              [73.4501953125, 42.703027343749994],
              [73.421875, 42.593505859375],
              [73.49296875000002, 42.409033203125],
              [73.41162109375, 42.419775390625],
              [73.316015625, 42.4669921875],
              [73.28291015625001, 42.5041015625],
              [73.19082031250002, 42.52685546875],
              [72.855078125, 42.5611328125],
              [72.79238281250002, 42.603466796875],
              [72.7529296875, 42.637890625],
              [72.66611328125, 42.660400390625],
              [72.54316406250001, 42.677734375],
              [72.27578125000002, 42.757666015625],
              [72.16181640625001, 42.760693359375],
              [71.81679687500002, 42.822167968749994],
              [71.76054687500002, 42.821484375],
              [71.73476562500002, 42.818896484374996],
              [71.60078125000001, 42.778662109375],
              [71.5142578125, 42.766943359375],
              [71.42207031250001, 42.783154296875],
              [71.25664062500002, 42.733544921874994],
              [71.16738281250002, 42.667431640625],
              [71.09355468750002, 42.5865234375],
              [71.02275390625002, 42.535449218749996],
              [71.001953125, 42.45908203125],
              [70.95234375000001, 42.419384765625],
              [70.89287109375002, 42.339990234374994],
              [70.89287109375002, 42.293701171875],
              [70.94677734375, 42.248681640624994],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 3,
        labelrank: 2,
        sovereignt: "Vietnam",
        sov_a3: "VNM",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Vietnam",
        adm0_a3: "VNM",
        geou_dif: 0,
        geounit: "Vietnam",
        gu_a3: "VNM",
        su_dif: 0,
        subunit: "Vietnam",
        su_a3: "VNM",
        brk_diff: 0,
        name: "Vietnam",
        name_long: "Vietnam",
        brk_a3: "VNM",
        brk_name: "Vietnam",
        brk_group: null,
        abbrev: "Viet.",
        postal: "VN",
        formal_en: "Socialist Republic of Vietnam",
        formal_fr: null,
        name_ciawf: "Vietnam",
        note_adm0: null,
        note_brk: null,
        name_sort: "Vietnam",
        name_alt: null,
        mapcolor7: 5,
        mapcolor8: 6,
        mapcolor9: 5,
        mapcolor13: 4,
        pop_est: 96462106,
        pop_rank: 16,
        pop_year: 2019,
        gdp_md: 261921,
        gdp_year: 2019,
        economy: "5. Emerging region: G20",
        income_grp: "4. Lower middle income",
        fips_10: "VM",
        iso_a2: "VN",
        iso_a2_eh: "VN",
        iso_a3: "VNM",
        iso_a3_eh: "VNM",
        iso_n3: "704",
        iso_n3_eh: "704",
        un_a3: "704",
        wb_a2: "VN",
        wb_a3: "VNM",
        woe_id: 23424984,
        woe_id_eh: 23424984,
        woe_note: "Exact WOE match as country",
        adm0_iso: "VNM",
        adm0_diff: null,
        adm0_tlc: "VNM",
        adm0_a3_us: "VNM",
        adm0_a3_fr: "VNM",
        adm0_a3_ru: "VNM",
        adm0_a3_es: "VNM",
        adm0_a3_cn: "VNM",
        adm0_a3_tw: "VNM",
        adm0_a3_in: "VNM",
        adm0_a3_np: "VNM",
        adm0_a3_pk: "VNM",
        adm0_a3_de: "VNM",
        adm0_a3_gb: "VNM",
        adm0_a3_br: "VNM",
        adm0_a3_il: "VNM",
        adm0_a3_ps: "VNM",
        adm0_a3_sa: "VNM",
        adm0_a3_eg: "VNM",
        adm0_a3_ma: "VNM",
        adm0_a3_pt: "VNM",
        adm0_a3_ar: "VNM",
        adm0_a3_jp: "VNM",
        adm0_a3_ko: "VNM",
        adm0_a3_vn: "VNM",
        adm0_a3_tr: "VNM",
        adm0_a3_id: "VNM",
        adm0_a3_pl: "VNM",
        adm0_a3_gr: "VNM",
        adm0_a3_it: "VNM",
        adm0_a3_nl: "VNM",
        adm0_a3_se: "VNM",
        adm0_a3_bd: "VNM",
        adm0_a3_ua: "VNM",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "South-Eastern Asia",
        region_wb: "East Asia & Pacific",
        name_len: 7,
        long_len: 7,
        abbrev_len: 5,
        tiny: 2,
        homepart: 1,
        min_zoom: 0,
        min_label: 2,
        max_label: 7,
        label_x: 105.387292,
        label_y: 21.715416,
        ne_id: 1159321417,
        wikidataid: "Q881",
        name_ar: "فيتنام",
        name_bn: "ভিয়েতনাম",
        name_de: "Vietnam",
        name_en: "Vietnam",
        name_es: "Vietnam",
        name_fa: "ویتنام",
        name_fr: "Viêt Nam",
        name_el: "Βιετνάμ",
        name_he: "וייטנאם",
        name_hi: "वियतनाम",
        name_hu: "Vietnám",
        name_id: "Vietnam",
        name_it: "Vietnam",
        name_ja: "ベトナム",
        name_ko: "베트남",
        name_nl: "Vietnam",
        name_pl: "Wietnam",
        name_pt: "Vietname",
        name_ru: "Вьетнам",
        name_sv: "Vietnam",
        name_tr: "Vietnam",
        name_uk: "В'єтнам",
        name_ur: "ویتنام",
        name_vi: "Việt Nam",
        name_zh: "越南",
        name_zht: "越南",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "VNM.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [104.06396484375, 10.390820312499997],
              [104.0830078125, 10.341113281249989],
              [104.07578125000003, 10.224853515625],
              [104.03681640625001, 10.110742187499994],
              [104.04833984375, 10.06103515625],
              [104.01845703125002, 10.029199218749994],
              [103.9521484375, 10.242919921875],
              [103.86796874999999, 10.335400390624997],
              [103.84951171875002, 10.37109375],
              [103.8984375, 10.368505859374991],
              [103.98583984375, 10.426953125],
              [104.02773437500002, 10.428369140624994],
              [104.06396484375, 10.390820312499997],
            ],
          ],
          [
            [
              [107.97265625, 21.507958984374994],
              [107.92578125, 21.498925781249994],
              [107.80908203125, 21.497119140625003],
              [107.70722656250001, 21.405859375],
              [107.63671875, 21.368066406249994],
              [107.52695312500003, 21.336230468750003],
              [107.40996093749999, 21.284814453124994],
              [107.37617187500001, 21.194140625],
              [107.37333984374999, 21.128466796875003],
              [107.35429687499999, 21.05517578125],
              [107.16474609375001, 20.94873046875],
              [107.11171875000002, 20.95957031249999],
              [107.0751953125, 20.999267578125],
              [107.01923828125001, 20.9912109375],
              [106.9814453125, 20.971386718749997],
              [106.93642578125002, 20.974072265624997],
              [106.88623046875, 20.95],
              [106.82060546874999, 20.95751953125],
              [106.76025390625, 20.991113281249994],
              [106.72519531250003, 20.999902343749994],
              [106.68339843749999, 21.000292968750003],
              [106.67548828125001, 20.960498046875003],
              [106.7373046875, 20.80615234375],
              [106.75341796875, 20.735058593749997],
              [106.55078125, 20.5265625],
              [106.57285156250003, 20.3921875],
              [106.51796875000002, 20.288867187500003],
              [106.3955078125, 20.20590820312499],
              [106.16572265625001, 19.99204101562499],
              [106.06220703125001, 19.987353515625003],
              [105.98408203125001, 19.9390625],
              [105.81396484375, 19.587451171875003],
              [105.81210937500003, 19.466992187499997],
              [105.78535156250001, 19.378857421874997],
              [105.79111328125003, 19.294189453125],
              [105.71640625000003, 19.12778320312499],
              [105.63906250000002, 19.057177734375003],
              [105.62177734375001, 18.96630859375],
              [105.73203125000003, 18.779296875],
              [105.74423828125003, 18.746289062499997],
              [105.80820312500003, 18.64584960937499],
              [105.83925781250002, 18.574169921874997],
              [105.88828125000003, 18.502490234375003],
              [106.065625, 18.316357421874997],
              [106.14453125, 18.259423828124994],
              [106.23955078124999, 18.220703125],
              [106.41191406249999, 18.053173828124997],
              [106.4990234375, 17.946435546874994],
              [106.459375, 17.873681640624994],
              [106.47890625000002, 17.71958007812499],
              [106.35585937500002, 17.765039062499994],
              [106.37050781250002, 17.746875],
              [106.51679687500001, 17.662792968749997],
              [106.73574218750002, 17.3671875],
              [106.92617187500002, 17.221386718749997],
              [107.11992187499999, 17.05551757812499],
              [107.18037109375001, 16.89794921875],
              [107.35507812500003, 16.79375],
              [107.54931640625, 16.642578125],
              [107.54082031249999, 16.608642578125],
              [107.59345703125001, 16.568066406249997],
              [107.72412109375, 16.487841796875003],
              [107.803125, 16.403076171875],
              [107.83378906249999, 16.322460937499997],
              [107.88203125000001, 16.309619140625003],
              [107.93632812499999, 16.329394531250003],
              [107.99072265625, 16.337109375],
              [108.02939453125003, 16.331103515625003],
              [108.08798828125003, 16.242724609375003],
              [108.16972656249999, 16.163671875],
              [108.208984375, 16.091064453125],
              [108.24082031250003, 16.10078125],
              [108.26738281249999, 16.089794921874997],
              [108.27402343750003, 16.029052734375],
              [108.28603515625002, 15.9890625],
              [108.39531249999999, 15.872460937499994],
              [108.44746093750001, 15.7626953125],
              [108.57783203125001, 15.584716796875],
              [108.67421875000002, 15.48359375],
              [108.74277343750003, 15.426611328124991],
              [108.8212890625, 15.3779296875],
              [108.89824218749999, 15.180517578124991],
              [108.93994140625, 15.00146484375],
              [109.0224609375, 14.802832031249991],
              [109.08486328125002, 14.716162109374991],
              [109.08701171875003, 14.552587890624991],
              [109.13730468750003, 14.384130859374991],
              [109.19140625, 14.270458984374997],
              [109.20732421874999, 14.154296875],
              [109.22392578124999, 14.0966796875],
              [109.24462890625, 14.053417968749997],
              [109.30332031250003, 13.8564453125],
              [109.2880859375, 13.765039062499994],
              [109.2470703125, 13.854736328125],
              [109.25205078125003, 13.590527343749997],
              [109.2880859375, 13.45078125],
              [109.271875, 13.279345703125003],
              [109.3095703125, 13.219189453124997],
              [109.37675781249999, 13.025488281249991],
              [109.42392578125003, 12.955957031249994],
              [109.42001953125003, 12.719042968749989],
              [109.44492187500003, 12.599609375],
              [109.38144531250003, 12.670751953124991],
              [109.33554687500003, 12.751904296874997],
              [109.27402343750003, 12.709033203124989],
              [109.21894531250001, 12.645800781249989],
              [109.3046875, 12.391162109374989],
              [109.20683593749999, 12.415380859374991],
              [109.21572265625002, 12.072900390624994],
              [109.25625, 11.992871093749997],
              [109.25917968750002, 11.954541015624997],
              [109.24726562500001, 11.90869140625],
              [109.22021484375, 11.958837890624991],
              [109.21455078125001, 12.010449218749997],
              [109.19912109375002, 11.9990234375],
              [109.19990234375001, 11.972460937499989],
              [109.16728515624999, 11.912011718749994],
              [109.15751953124999, 11.837109375],
              [109.19267578124999, 11.7734375],
              [109.19863281250002, 11.724853515625],
              [109.17324218750002, 11.664746093749997],
              [109.13251953125001, 11.60107421875],
              [109.03964843750003, 11.592675781249994],
              [109.01845703125002, 11.468359375],
              [108.98671875000002, 11.336376953124997],
              [108.82080078125, 11.3154296875],
              [108.70029296875003, 11.199267578124989],
              [108.55126953125, 11.155957031249997],
              [108.41855468750003, 11.040722656249997],
              [108.27167968750001, 10.934277343749997],
              [108.17617187500002, 10.920166015625],
              [108.09492187500001, 10.897265624999989],
              [108.00136718750002, 10.720361328124994],
              [107.84511718750002, 10.700097656249994],
              [107.564453125, 10.555468749999989],
              [107.47031250000003, 10.48583984375],
              [107.38447265625001, 10.458642578124994],
              [107.26152343749999, 10.398388671874997],
              [107.23505859375001, 10.419873046874997],
              [107.19414062499999, 10.471582031249994],
              [107.08779296875002, 10.498339843749989],
              [107.03574218750003, 10.556298828124994],
              [107.02070312500001, 10.630957031249991],
              [107.00664062499999, 10.660546875],
              [106.98369140624999, 10.618310546874994],
              [106.96611328124999, 10.440722656249989],
              [106.94746093750001, 10.400341796874997],
              [106.90205078125001, 10.3828125],
              [106.81269531250001, 10.433300781249997],
              [106.72734374999999, 10.53564453125],
              [106.60585937500002, 10.464941406249991],
              [106.64306640625, 10.45625],
              [106.69843750000001, 10.462060546874994],
              [106.7412109375, 10.444384765624989],
              [106.77753906250001, 10.376123046874994],
              [106.77626953125002, 10.338964843749991],
              [106.75742187500003, 10.295800781249994],
              [106.6435546875, 10.288916015624991],
              [106.49169921875, 10.304101562499994],
              [106.46406250000001, 10.298291015624997],
              [106.60244140625002, 10.231738281249989],
              [106.72900390625, 10.193310546874997],
              [106.78525390625003, 10.151171874999989],
              [106.78525390625003, 10.116455078125],
              [106.71416015624999, 10.060205078124994],
              [106.6591796875, 9.99140625],
              [106.65810546875002, 9.94873046875],
              [106.65683593750003, 9.901074218749997],
              [106.59560546875002, 9.85986328125],
              [106.55732421875001, 9.868066406249994],
              [106.44912109375002, 9.939648437499997],
              [106.13642578125001, 10.2216796875],
              [106.18359375, 10.14208984375],
              [106.50742187500003, 9.821240234374997],
              [106.56435546875002, 9.715625],
              [106.57246093750001, 9.64111328125],
              [106.53916015625003, 9.603564453124989],
              [106.48408203125001, 9.559423828124991],
              [106.37802734375003, 9.556103515624997],
              [106.20400390625002, 9.675439453124994],
              [105.92568359375002, 9.961718749999989],
              [105.83095703125002, 10.000732421875],
              [106.1125, 9.673583984375],
              [106.15859375000002, 9.594140625],
              [106.20615234375003, 9.50234375],
              [106.19257812500001, 9.447802734374989],
              [106.16835937500002, 9.396728515625],
              [105.5009765625, 9.093212890624997],
              [105.4013671875, 8.96240234375],
              [105.322265625, 8.801123046874991],
              [105.19121093749999, 8.711328125],
              [105.11435546875003, 8.629199218749989],
              [104.89189453124999, 8.583251953125],
              [104.77041015625002, 8.59765625],
              [104.89628906249999, 8.746630859374989],
              [104.81855468750001, 8.801855468749991],
              [104.81464843750001, 9.185498046874997],
              [104.84521484375, 9.606152343749997],
              [104.90322265625002, 9.816259765624991],
              [104.98710937499999, 9.86865234375],
              [105.09257812499999, 9.900976562499991],
              [105.09492187500001, 9.945263671874997],
              [105.08447265625, 9.995703125],
              [105.02783203125, 10.067431640624989],
              [104.9658203125, 10.1005859375],
              [104.87324218750001, 10.114794921874989],
              [104.80195312500001, 10.202734375],
              [104.74765625000003, 10.199121093749994],
              [104.66347656250002, 10.169921875],
              [104.61269531250002, 10.207666015624994],
              [104.59404296874999, 10.266894531249989],
              [104.51611328125, 10.339990234374994],
              [104.42636718750003, 10.411230468749991],
              [104.46699218750001, 10.42236328125],
              [104.51406250000002, 10.463330078124997],
              [104.56425781249999, 10.515966796874991],
              [104.68964843750001, 10.523242187499989],
              [104.8154296875, 10.520800781249989],
              [104.8505859375, 10.534472656249989],
              [104.90126953125002, 10.590234375],
              [104.98388671875, 10.661914062499989],
              [105.04638671875, 10.70166015625],
              [105.06113281250003, 10.733789062499994],
              [105.0361328125, 10.809375],
              [105.02226562499999, 10.886865234374994],
              [105.04570312499999, 10.911376953125],
              [105.15947265624999, 10.897558593749991],
              [105.28427734375003, 10.861474609374994],
              [105.31464843750001, 10.845166015624997],
              [105.38652343749999, 10.940087890624994],
              [105.40576171875, 10.951611328124997],
              [105.45273437500003, 10.951416015625],
              [105.57656250000002, 10.968896484374994],
              [105.69775390625, 10.994042968749994],
              [105.75507812500001, 10.989990234375],
              [105.81074218750001, 10.926074218749989],
              [105.85332031249999, 10.863574218749989],
              [105.87519531250001, 10.858496093749991],
              [105.93818359375001, 10.88515625],
              [105.99013671875002, 10.851806640625],
              [106.09882812500001, 10.797265625],
              [106.16396484375002, 10.794921875],
              [106.13154296875001, 10.921972656249991],
              [106.16796875, 11.012304687499991],
              [106.16093749999999, 11.037109375],
              [106.09951171875002, 11.078662109374989],
              [105.8916015625, 11.244824218749997],
              [105.85605468750003, 11.294287109374991],
              [105.86093750000003, 11.372412109374991],
              [105.85400390625, 11.487060546875],
              [105.83535156250002, 11.559130859374989],
              [105.83847656250003, 11.601318359375],
              [105.85146484375002, 11.635009765625],
              [105.88984375000001, 11.648388671874997],
              [105.92656249999999, 11.652929687499991],
              [105.95625, 11.682470703124991],
              [106.00605468750001, 11.758007812499997],
              [106.10292968750002, 11.751269531249989],
              [106.23916015625002, 11.708349609374991],
              [106.33984375, 11.681835937499997],
              [106.39921874999999, 11.68701171875],
              [106.4125, 11.697802734374989],
              [106.41074218750003, 11.738378906249991],
              [106.41777343749999, 11.91171875],
              [106.41386718749999, 11.9484375],
              [106.49960937500003, 11.965527343749997],
              [106.63095703125003, 11.969189453124997],
              [106.70009765625002, 11.979296874999989],
              [106.7646484375, 12.05234375],
              [106.9306640625, 12.077490234374991],
              [107.05068359375002, 12.175878906249991],
              [107.15898437499999, 12.277050781249997],
              [107.21210937500001, 12.304003906249989],
              [107.27968750000002, 12.321582031249989],
              [107.330078125, 12.319042968749997],
              [107.39335937499999, 12.260498046875],
              [107.44599609375001, 12.295703124999989],
              [107.50644531250003, 12.364550781249989],
              [107.5380859375, 12.431787109374994],
              [107.55546874999999, 12.539990234374997],
              [107.54355468750003, 12.705908203124991],
              [107.51152343749999, 12.835742187499989],
              [107.48154296875003, 12.93310546875],
              [107.47539062499999, 13.030371093749991],
              [107.54550781250003, 13.225439453124991],
              [107.60546875, 13.437792968750003],
              [107.59394531250001, 13.521679687499997],
              [107.52861328124999, 13.654199218749994],
              [107.46230468750002, 13.815625],
              [107.38945312499999, 13.993017578124991],
              [107.36210937499999, 14.019482421874997],
              [107.34257812499999, 14.068896484375003],
              [107.33144531250002, 14.126611328124994],
              [107.3603515625, 14.307861328125],
              [107.36445312500001, 14.368701171875003],
              [107.44843750000001, 14.451220703125003],
              [107.4931640625, 14.545751953124991],
              [107.53525390625003, 14.649951171875003],
              [107.51943359375002, 14.705078125],
              [107.51376953124998, 14.817382812500057],
              [107.52451171875003, 14.871826171874943],
              [107.50468749999999, 14.915917968749994],
              [107.48037109375002, 14.979882812499994],
              [107.49628906250001, 15.021435546874997],
              [107.55527343750003, 15.05703125],
              [107.58964843749999, 15.118457031250003],
              [107.63369140625002, 15.18984375],
              [107.653125, 15.255224609374991],
              [107.62167968750003, 15.309863281250003],
              [107.56425781249999, 15.3916015625],
              [107.45957031250003, 15.4658203125],
              [107.33876953125002, 15.560498046875125],
              [107.27939453125003, 15.618701171875003],
              [107.23261718750001, 15.678076171874991],
              [107.18955078125003, 15.747265625],
              [107.16591796875002, 15.802490234375],
              [107.18886718750002, 15.838623046875],
              [107.36064453124999, 15.921728515624991],
              [107.39199218750002, 15.95166015625],
              [107.41015625, 15.997851562500003],
              [107.39638671875002, 16.043017578125003],
              [107.35009765625, 16.0673828125],
              [107.29648437500003, 16.084033203125003],
              [107.21738281250003, 16.136328124999977],
              [107.06972656250002, 16.279833984375003],
              [107.001953125, 16.311816406250045],
              [106.9306640625, 16.353125],
              [106.89277343750001, 16.396533203125003],
              [106.85107421875, 16.515625],
              [106.83242187500002, 16.526269531249994],
              [106.79160156250003, 16.49033203124999],
              [106.73955078124999, 16.452539062500136],
              [106.69609374999999, 16.458984375],
              [106.65644531250001, 16.492626953124997],
              [106.6375, 16.53793945312492],
              [106.59365234375002, 16.60009765625],
              [106.54619140624999, 16.65073242187499],
              [106.53369140625, 16.821044921875],
              [106.52597656250003, 16.876611328125023],
              [106.50224609374999, 16.9541015625],
              [106.46533203125, 16.981835937499994],
              [106.42597656250001, 17.00253906249999],
              [106.33339843750002, 17.14370117187508],
              [106.26953125, 17.216796875000057],
              [106.00625, 17.415283203125],
              [105.97353515625002, 17.446972656249997],
              [105.90273437500002, 17.52866210937499],
              [105.77949218750001, 17.644433593749994],
              [105.69140625, 17.737841796874932],
              [105.62724609374999, 17.834423828124997],
              [105.59765625, 17.918261718750003],
              [105.58847656250003, 17.983691406250003],
              [105.5185546875, 18.077441406250003],
              [105.45820312500001, 18.154296875],
              [105.4, 18.179248046875003],
              [105.33349609375, 18.189648437499997],
              [105.27324218749999, 18.2353515625],
              [105.16328125000001, 18.33872070312499],
              [105.11455078124999, 18.4052734375],
              [105.08583984375002, 18.450097656249994],
              [105.08701171875003, 18.496240234374994],
              [105.11347656250001, 18.573046875],
              [105.14541015625002, 18.616796875],
              [105.146484375, 18.650976562499977],
              [105.11513671875002, 18.678857421875023],
              [104.9931640625, 18.728320312500003],
              [104.71650390624995, 18.803417968749955],
              [104.61328125, 18.860644531250003],
              [104.51796875000002, 18.934082031250114],
              [104.44580078125, 18.983837890624997],
              [104.10859375000001, 19.195556640625],
              [104.00634765625, 19.230908203124997],
              [103.91835937500002, 19.268505859374997],
              [103.89160156249994, 19.30498046874999],
              [103.89638671875002, 19.339990234375023],
              [103.93203125000002, 19.366064453125034],
              [104.02753906250001, 19.420458984375003],
              [104.06289062500002, 19.482568359375136],
              [104.05156249999999, 19.564160156249997],
              [104.01347656249999, 19.646484375],
              [104.03203124999999, 19.67514648437499],
              [104.06279296874999, 19.678417968749997],
              [104.12714843750001, 19.68085937500001],
              [104.25986328125003, 19.685498046874997],
              [104.54628906250002, 19.610546875],
              [104.587890625, 19.61875],
              [104.7431640625, 19.75473632812499],
              [104.8017578125, 19.836132812499997],
              [104.81513671875001, 19.904003906249997],
              [104.84580078125003, 19.94716796875008],
              [104.92792968750001, 20.018115234375003],
              [104.92919921874994, 20.082812500000045],
              [104.888671875, 20.169091796874994],
              [104.84785156250001, 20.202441406250003],
              [104.81269531250001, 20.21684570312499],
              [104.69873046875, 20.205322265625],
              [104.67695312500001, 20.22470703124999],
              [104.66191406249999, 20.289013671874997],
              [104.65644531250001, 20.328515625],
              [104.61884765624995, 20.374511718750114],
              [104.49619140625003, 20.413671875],
              [104.39218750000003, 20.424755859374997],
              [104.36777343750003, 20.44140625],
              [104.40781250000003, 20.485742187499994],
              [104.47861328124998, 20.529589843750102],
              [104.53271484375, 20.554882812500125],
              [104.57519531249994, 20.60024414062508],
              [104.58320312500001, 20.646679687499955],
              [104.53037109375003, 20.68798828125],
              [104.46142578125, 20.73374023437499],
              [104.349609375, 20.82109374999999],
              [104.1953125, 20.913964843749994],
              [104.10136718749999, 20.945507812499997],
              [104.05205078124999, 20.941210937500102],
              [103.88203125000001, 20.86142578124999],
              [103.79052734375, 20.809521484374997],
              [103.71445312500003, 20.716943359374994],
              [103.63505859374999, 20.697070312500003],
              [103.5546875, 20.737841796875003],
              [103.46357421874995, 20.779833984375102],
              [103.21074218749999, 20.840625],
              [103.1044921875, 20.891650390625003],
              [102.8837890625, 21.202587890624997],
              [102.85117187500003, 21.265917968750003],
              [102.87226562500001, 21.3375],
              [102.8875, 21.43994140625],
              [102.90957031250002, 21.50634765625],
              [102.94863281250002, 21.569775390624997],
              [102.95917968750001, 21.626220703125],
              [102.94960937500002, 21.681347656249997],
              [102.91767578125001, 21.712939453125003],
              [102.87617187500001, 21.72226562500009],
              [102.84521484375, 21.734765625],
              [102.81591796875, 21.807373046875],
              [102.79824218750002, 21.79794921874999],
              [102.77109375000003, 21.709667968749997],
              [102.73857421874999, 21.677929687499997],
              [102.69531249999994, 21.662109375000057],
              [102.66201171875002, 21.676025390625],
              [102.64082031250001, 21.71142578125],
              [102.63125, 21.77133789062499],
              [102.60966796874999, 21.851757812499997],
              [102.58251953125, 21.904296875],
              [102.4875, 21.957763671875],
              [102.44267578124999, 22.02714843749999],
              [102.30136718749998, 22.178173828125068],
              [102.18300781250002, 22.28403320312492],
              [102.12744140625, 22.379199218750003],
              [102.17597656250001, 22.41464843749999],
              [102.23701171875001, 22.466015625],
              [102.30224609375, 22.54599609374999],
              [102.37578124999999, 22.646630859374994],
              [102.40644531250001, 22.7080078125],
              [102.42792968750001, 22.7328125],
              [102.47089843750001, 22.750927734374997],
              [102.51718750000003, 22.741015625],
              [102.59853515625002, 22.700390625],
              [102.72099609374999, 22.648486328125003],
              [102.830078125, 22.587158203125],
              [102.87421875000001, 22.525390625],
              [102.93515625000003, 22.46616210937499],
              [102.98193359375, 22.4482421875],
              [103.00537109375, 22.45297851562499],
              [103.07587890625001, 22.497509765624997],
              [103.13632812500003, 22.542236328125],
              [103.13759765625002, 22.59296875],
              [103.193359375, 22.63852539062499],
              [103.26630859375001, 22.713525390624994],
              [103.30058593749999, 22.764404296875],
              [103.32666015625, 22.769775390625],
              [103.35605468750003, 22.7546875],
              [103.47099609374999, 22.597412109375],
              [103.49296874999999, 22.58798828124999],
              [103.525390625, 22.611572265625],
              [103.57070312500002, 22.734423828125003],
              [103.62021484375003, 22.78203125],
              [103.63730468750003, 22.77001953125],
              [103.9150390625, 22.538232421874994],
              [103.94150390625003, 22.540087890625003],
              [103.97138671875001, 22.550488281249997],
              [103.99082031250003, 22.586132812499997],
              [104.0126953125, 22.66635742187499],
              [104.05390625000001, 22.75229492187499],
              [104.14306640625, 22.80014648437499],
              [104.2125, 22.80942382812499],
              [104.23828125, 22.768505859374997],
              [104.29833984375, 22.71201171874999],
              [104.37177734375001, 22.704052734374997],
              [104.52685546875, 22.804101562499994],
              [104.57753906250002, 22.820019531249997],
              [104.63173828125002, 22.81821289062499],
              [104.68730468749999, 22.822216796874997],
              [104.74003906249999, 22.860498046874994],
              [104.79570312499999, 22.9111328125],
              [104.81474609374999, 23.010791015625003],
              [104.82656250000002, 23.10019531249999],
              [104.86474609375, 23.136376953124994],
              [104.91015625, 23.160546875],
              [104.99570312500003, 23.1943359375],
              [105.18906250000003, 23.281054687500003],
              [105.23876953125, 23.32211914062499],
              [105.275390625, 23.34521484375],
              [105.35048828125002, 23.307666015625003],
              [105.44013671875001, 23.2353515625],
              [105.49453125000002, 23.180859375],
              [105.53085937500003, 23.121972656249994],
              [105.54814453124999, 23.07265625],
              [105.69121093749999, 23.029931640624994],
              [105.78232421875003, 22.96933593749999],
              [105.84296875000001, 22.922802734374997],
              [105.90263671874999, 22.924951171874994],
              [105.96230468750002, 22.937451171874997],
              [106.0009765625, 22.974755859374994],
              [106.06845703125003, 22.975537109374997],
              [106.1484375, 22.97006835937499],
              [106.18398437500002, 22.955126953125003],
              [106.24941406250002, 22.869433593750003],
              [106.27900390625001, 22.857470703125003],
              [106.33808593750001, 22.863476562499997],
              [106.45087890625001, 22.89389648437499],
              [106.54179687499999, 22.908349609374994],
              [106.6240234375, 22.874267578125],
              [106.7802734375, 22.77890625],
              [106.736328125, 22.7109375],
              [106.70156250000002, 22.637744140625003],
              [106.63310546874999, 22.58603515624999],
              [106.58242187500002, 22.5732421875],
              [106.55039062500003, 22.501367187499994],
              [106.53632812500001, 22.395410156249994],
              [106.55361328125002, 22.341699218749994],
              [106.59316406250002, 22.324511718750003],
              [106.63652343749999, 22.288623046875003],
              [106.65419921875002, 22.241455078125],
              [106.66005859375002, 22.136474609375],
              [106.65771484375, 22.018212890624994],
              [106.66357421875, 21.97890625],
              [106.69765625000002, 21.98618164062499],
              [106.7294921875, 22.00034179687499],
              [106.79414062500001, 21.981982421875003],
              [106.87451171875, 21.95126953124999],
              [106.92519531250002, 21.920117187499997],
              [106.97099609374999, 21.92392578124999],
              [107.00644531250003, 21.89340820312499],
              [107.01982421874999, 21.834863281249994],
              [107.06162109375003, 21.794189453125],
              [107.17851562499999, 21.717089843750003],
              [107.27207031250003, 21.710644531249997],
              [107.35117187500003, 21.60888671875],
              [107.43349609375002, 21.642285156249997],
              [107.47138671875001, 21.598339843749997],
              [107.64101562500002, 21.613916015624994],
              [107.75927734375, 21.655029296875],
              [107.80205078124999, 21.645166015624994],
              [107.90839843750001, 21.56040039062499],
              [107.97265625, 21.507958984374994],
            ],
          ],
          [
            [
              [106.61748046874999, 8.6828125],
              [106.58925781250002, 8.680517578124991],
              [106.56796875000003, 8.700927734375],
              [106.65859375000002, 8.766357421875],
              [106.64951171875003, 8.722998046874991],
              [106.65253906250001, 8.701123046874997],
              [106.61748046874999, 8.6828125],
            ],
          ],
          [
            [
              [107.16767578125001, 10.397167968749997],
              [107.08378906249999, 10.336572265624994],
              [107.07792968749999, 10.3875],
              [107.15087890625, 10.4203125],
              [107.17656249999999, 10.446191406249994],
              [107.19492187500003, 10.445703125],
              [107.16767578125001, 10.397167968749997],
            ],
          ],
          [
            [
              [106.865625, 20.815722656250003],
              [106.85410156250003, 20.79638671875],
              [106.803125, 20.84375],
              [106.76943359375002, 20.864208984374997],
              [106.79531250000002, 20.927929687499997],
              [106.85507812500003, 20.85825195312499],
              [106.865625, 20.815722656250003],
            ],
          ],
          [
            [
              [107.60273437500001, 21.216796875],
              [107.45869140625001, 21.09165039062499],
              [107.40351562500001, 21.093652343749994],
              [107.45253906250002, 21.235302734374997],
              [107.47626953125001, 21.268945312499994],
              [107.56269531250001, 21.220410156249997],
              [107.60273437500001, 21.216796875],
            ],
          ],
          [
            [
              [107.52128906249999, 20.92661132812499],
              [107.46552734375001, 20.900537109374994],
              [107.39921874999999, 20.903466796874994],
              [107.47861328125003, 20.95234375],
              [107.51894531250002, 21.012841796874994],
              [107.55126953125, 21.03403320312499],
              [107.55107421874999, 20.981201171875],
              [107.52128906249999, 20.92661132812499],
            ],
          ],
          [
            [
              [107.03134765625003, 20.747021484374997],
              [106.99003906249999, 20.743066406249994],
              [106.91064453125, 20.82421875],
              [106.95341796874999, 20.86704101562499],
              [107.04375, 20.836816406249994],
              [107.064453125, 20.817285156249994],
              [107.06396484375, 20.799755859374997],
              [107.04228515624999, 20.761035156250003],
              [107.03134765625003, 20.747021484374997],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Cambodia",
        sov_a3: "KHM",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Cambodia",
        adm0_a3: "KHM",
        geou_dif: 0,
        geounit: "Cambodia",
        gu_a3: "KHM",
        su_dif: 0,
        subunit: "Cambodia",
        su_a3: "KHM",
        brk_diff: 0,
        name: "Cambodia",
        name_long: "Cambodia",
        brk_a3: "KHM",
        brk_name: "Cambodia",
        brk_group: null,
        abbrev: "Camb.",
        postal: "KH",
        formal_en: "Kingdom of Cambodia",
        formal_fr: null,
        name_ciawf: "Cambodia",
        note_adm0: null,
        note_brk: null,
        name_sort: "Cambodia",
        name_alt: null,
        mapcolor7: 6,
        mapcolor8: 3,
        mapcolor9: 6,
        mapcolor13: 5,
        pop_est: 16486542,
        pop_rank: 14,
        pop_year: 2019,
        gdp_md: 27089,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        fips_10: "CB",
        iso_a2: "KH",
        iso_a2_eh: "KH",
        iso_a3: "KHM",
        iso_a3_eh: "KHM",
        iso_n3: "116",
        iso_n3_eh: "116",
        un_a3: "116",
        wb_a2: "KH",
        wb_a3: "KHM",
        woe_id: 23424776,
        woe_id_eh: 23424776,
        woe_note: "Exact WOE match as country",
        adm0_iso: "KHM",
        adm0_diff: null,
        adm0_tlc: "KHM",
        adm0_a3_us: "KHM",
        adm0_a3_fr: "KHM",
        adm0_a3_ru: "KHM",
        adm0_a3_es: "KHM",
        adm0_a3_cn: "KHM",
        adm0_a3_tw: "KHM",
        adm0_a3_in: "KHM",
        adm0_a3_np: "KHM",
        adm0_a3_pk: "KHM",
        adm0_a3_de: "KHM",
        adm0_a3_gb: "KHM",
        adm0_a3_br: "KHM",
        adm0_a3_il: "KHM",
        adm0_a3_ps: "KHM",
        adm0_a3_sa: "KHM",
        adm0_a3_eg: "KHM",
        adm0_a3_ma: "KHM",
        adm0_a3_pt: "KHM",
        adm0_a3_ar: "KHM",
        adm0_a3_jp: "KHM",
        adm0_a3_ko: "KHM",
        adm0_a3_vn: "KHM",
        adm0_a3_tr: "KHM",
        adm0_a3_id: "KHM",
        adm0_a3_pl: "KHM",
        adm0_a3_gr: "KHM",
        adm0_a3_it: "KHM",
        adm0_a3_nl: "KHM",
        adm0_a3_se: "KHM",
        adm0_a3_bd: "KHM",
        adm0_a3_ua: "KHM",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "South-Eastern Asia",
        region_wb: "East Asia & Pacific",
        name_len: 8,
        long_len: 8,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: 104.50487,
        label_y: 12.647584,
        ne_id: 1159320979,
        wikidataid: "Q424",
        name_ar: "كمبوديا",
        name_bn: "কম্বোডিয়া",
        name_de: "Kambodscha",
        name_en: "Cambodia",
        name_es: "Camboya",
        name_fa: "کامبوج",
        name_fr: "Cambodge",
        name_el: "Καμπότζη",
        name_he: "קמבודיה",
        name_hi: "कम्बोडिया",
        name_hu: "Kambodzsa",
        name_id: "Kamboja",
        name_it: "Cambogia",
        name_ja: "カンボジア",
        name_ko: "캄보디아",
        name_nl: "Cambodja",
        name_pl: "Kambodża",
        name_pt: "Camboja",
        name_ru: "Камбоджа",
        name_sv: "Kambodja",
        name_tr: "Kamboçya",
        name_uk: "Камбоджа",
        name_ur: "کمبوڈیا",
        name_vi: "Campuchia",
        name_zh: "柬埔寨",
        name_zht: "柬埔寨",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "KHM.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [103.04511718750001, 11.285058593749994],
              [103.02734375, 11.275488281249991],
              [103.01054687499999, 11.27578125],
              [102.99335937500001, 11.290429687499994],
              [102.99501953125002, 11.348095703124997],
              [103.00751953125001, 11.38330078125],
              [103.03681640625001, 11.389941406249989],
              [103.04511718750001, 11.285058593749994],
            ],
          ],
          [
            [
              [103.31777343750002, 10.718505859375],
              [103.28125, 10.6796875],
              [103.22294921874999, 10.759570312499989],
              [103.22343749999999, 10.781982421875],
              [103.31777343750002, 10.718505859375],
            ],
          ],
          [
            [
              [107.51943359375002, 14.705078125],
              [107.53525390625003, 14.649951171875003],
              [107.4931640625, 14.545751953124991],
              [107.44843750000001, 14.451220703125003],
              [107.36445312500001, 14.368701171875003],
              [107.3603515625, 14.307861328125],
              [107.33144531250002, 14.126611328124994],
              [107.34257812499999, 14.068896484375003],
              [107.36210937499999, 14.019482421874997],
              [107.38945312499999, 13.993017578124991],
              [107.46230468750002, 13.815625],
              [107.52861328124999, 13.654199218749994],
              [107.59394531250001, 13.521679687499997],
              [107.60546875, 13.437792968750003],
              [107.54550781250003, 13.225439453124991],
              [107.47539062499999, 13.030371093749991],
              [107.48154296875003, 12.93310546875],
              [107.51152343749999, 12.835742187499989],
              [107.54355468750003, 12.705908203124991],
              [107.55546874999999, 12.539990234374997],
              [107.5380859375, 12.431787109374994],
              [107.50644531250003, 12.364550781249989],
              [107.44599609375001, 12.295703124999989],
              [107.39335937499999, 12.260498046875],
              [107.330078125, 12.319042968749997],
              [107.27968750000002, 12.321582031249989],
              [107.21210937500001, 12.304003906249989],
              [107.15898437499999, 12.277050781249997],
              [107.05068359375002, 12.175878906249991],
              [106.9306640625, 12.077490234374991],
              [106.7646484375, 12.05234375],
              [106.70009765625002, 11.979296874999989],
              [106.63095703125003, 11.969189453124997],
              [106.49960937500003, 11.965527343749997],
              [106.41386718749999, 11.9484375],
              [106.41777343749999, 11.91171875],
              [106.41074218750003, 11.738378906249991],
              [106.4125, 11.697802734374989],
              [106.39921874999999, 11.68701171875],
              [106.33984375, 11.681835937499997],
              [106.23916015625002, 11.708349609374991],
              [106.10292968750002, 11.751269531249989],
              [106.00605468750001, 11.758007812499997],
              [105.95625, 11.682470703124991],
              [105.92656249999999, 11.652929687499991],
              [105.88984375000001, 11.648388671874997],
              [105.85146484375002, 11.635009765625],
              [105.83847656250003, 11.601318359375],
              [105.83535156250002, 11.559130859374989],
              [105.85400390625, 11.487060546875],
              [105.86093750000003, 11.372412109374991],
              [105.85605468750003, 11.294287109374991],
              [105.8916015625, 11.244824218749997],
              [106.09951171875002, 11.078662109374989],
              [106.16093749999999, 11.037109375],
              [106.16796875, 11.012304687499991],
              [106.13154296875001, 10.921972656249991],
              [106.16396484375002, 10.794921875],
              [106.09882812500001, 10.797265625],
              [105.99013671875002, 10.851806640625],
              [105.93818359375001, 10.88515625],
              [105.87519531250001, 10.858496093749991],
              [105.85332031249999, 10.863574218749989],
              [105.81074218750001, 10.926074218749989],
              [105.75507812500001, 10.989990234375],
              [105.69775390625, 10.994042968749994],
              [105.57656250000002, 10.968896484374994],
              [105.45273437500003, 10.951416015625],
              [105.40576171875, 10.951611328124997],
              [105.38652343749999, 10.940087890624994],
              [105.31464843750001, 10.845166015624997],
              [105.28427734375003, 10.861474609374994],
              [105.15947265624999, 10.897558593749991],
              [105.04570312499999, 10.911376953125],
              [105.02226562499999, 10.886865234374994],
              [105.0361328125, 10.809375],
              [105.06113281250003, 10.733789062499994],
              [105.04638671875, 10.70166015625],
              [104.98388671875, 10.661914062499989],
              [104.90126953125002, 10.590234375],
              [104.8505859375, 10.534472656249989],
              [104.8154296875, 10.520800781249989],
              [104.68964843750001, 10.523242187499989],
              [104.56425781249999, 10.515966796874991],
              [104.51406250000002, 10.463330078124997],
              [104.46699218750001, 10.42236328125],
              [104.42636718750003, 10.411230468749991],
              [104.26240234375001, 10.541259765625],
              [103.93710937500003, 10.586621093749997],
              [103.90175781250002, 10.643945312499994],
              [103.87050781250002, 10.655126953124991],
              [103.84052734375001, 10.58056640625],
              [103.66191406249999, 10.508935546874994],
              [103.58710937500001, 10.552197265624997],
              [103.5322265625, 10.604638671874994],
              [103.54042968750002, 10.668701171875],
              [103.59208984374999, 10.721044921874991],
              [103.68085937500001, 10.758593749999989],
              [103.721875, 10.89013671875],
              [103.654296875, 11.058691406249991],
              [103.59501953124999, 11.107763671874991],
              [103.53242187500001, 11.146679687499997],
              [103.46669921875002, 11.083984375],
              [103.41132812500001, 10.976757812499997],
              [103.35361328125003, 10.921582031249997],
              [103.27216796875001, 10.909277343749991],
              [103.15283203125, 10.913720703124994],
              [103.1064453125, 11.073779296874989],
              [103.09111328124999, 11.211083984374994],
              [103.107421875, 11.367773437499991],
              [103.12548828125, 11.460644531249997],
              [103.01054687499999, 11.588671874999989],
              [103.00419921874999, 11.710595703124994],
              [102.94863281250002, 11.773486328124989],
              [102.93232421875001, 11.74169921875],
              [102.93388671874999, 11.706689453124994],
              [102.91806640625003, 11.732080078124994],
              [102.73662109374999, 12.089794921874997],
              [102.70625, 12.255664062499989],
              [102.73740234375003, 12.383398437499991],
              [102.75566406249999, 12.42626953125],
              [102.70332031250001, 12.493505859374991],
              [102.62968749999999, 12.569921875],
              [102.49960937500003, 12.669970703124989],
              [102.49072265625, 12.828320312499997],
              [102.46171874999999, 13.015039062499994],
              [102.42265624999999, 13.077978515624991],
              [102.36298828125001, 13.19296875],
              [102.33076171875001, 13.288232421874994],
              [102.31972656250002, 13.539990234374997],
              [102.33632812500002, 13.560302734375],
              [102.42851562499999, 13.567578125],
              [102.546875, 13.585693359375],
              [102.56552734375003, 13.626367187499994],
              [102.54472656249999, 13.659960937500003],
              [102.62041015624999, 13.716943359374994],
              [102.72890625000002, 13.841894531249991],
              [102.81279296874999, 13.972460937500003],
              [102.87324218750001, 14.054882812499997],
              [102.90927734375003, 14.13671875],
              [103.03105468749999, 14.252539062499991],
              [103.19941406250001, 14.332617187499991],
              [103.3134765625, 14.351318359375],
              [103.43242187499999, 14.378613281249997],
              [103.54638671875, 14.417431640624997],
              [103.60039062499999, 14.42109375],
              [103.74189453125001, 14.374169921874994],
              [103.818359375, 14.362158203124991],
              [103.89863281250001, 14.36279296875],
              [103.98183593750002, 14.35791015625],
              [104.05429687500003, 14.362744140624997],
              [104.22773437500001, 14.3955078125],
              [104.41162109375, 14.369580078124997],
              [104.57578125000003, 14.390039062499994],
              [104.77900390625001, 14.427832031249991],
              [104.87880859375002, 14.404003906249997],
              [104.9697265625, 14.366113281249994],
              [104.982421875, 14.289453125],
              [105.00341796875, 14.254443359375003],
              [105.03369140625, 14.227392578124991],
              [105.07412109375002, 14.227441406249994],
              [105.1259765625, 14.280957031249997],
              [105.16914062500001, 14.336083984374994],
              [105.18330078125001, 14.346240234375003],
              [105.18554687499994, 14.319091796875],
              [105.20703125, 14.259375],
              [105.24570312500003, 14.200537109374991],
              [105.28486328125001, 14.161474609374991],
              [105.35019531250003, 14.109570312499997],
              [105.39267578125003, 14.107080078124994],
              [105.53154296874999, 14.156152343749994],
              [105.73974609375, 14.0849609375],
              [105.76406250000002, 14.049072265625057],
              [105.83144531250002, 13.976611328125003],
              [105.90449218750001, 13.924511718749997],
              [106.06679687500002, 13.921191406250003],
              [106.12470703124995, 14.049121093750045],
              [106.0966796875, 14.127099609374994],
              [106.00410156250001, 14.262890625],
              [105.97890625000002, 14.343017578125],
              [106.00839843750003, 14.357177734375],
              [106.16523437500001, 14.372363281250003],
              [106.19072265624999, 14.388134765624997],
              [106.22539062499999, 14.476220703124994],
              [106.26796875000002, 14.466210937499994],
              [106.35498046875, 14.454785156250068],
              [106.44697265625001, 14.515039062499994],
              [106.50146484375, 14.578222656250034],
              [106.53115234375002, 14.549414062499977],
              [106.56367187500001, 14.505078125],
              [106.59921875000003, 14.479394531249994],
              [106.66542968750002, 14.441308593750023],
              [106.73818359375002, 14.387744140625003],
              [106.78349609374999, 14.335107421874994],
              [106.81992187500003, 14.314697265624943],
              [106.91318359375003, 14.329394531250003],
              [106.93808593750003, 14.32734375],
              [106.99218749999994, 14.39101562500008],
              [107.03017578125002, 14.425683593749994],
              [107.06240234375002, 14.415771484375],
              [107.109375, 14.416699218749997],
              [107.20664062500003, 14.497900390624991],
              [107.26230468750003, 14.572119140624991],
              [107.29267578125001, 14.592382812499991],
              [107.3798828125, 14.555322265624994],
              [107.41474609375001, 14.562890625],
              [107.46513671874999, 14.664990234374997],
              [107.51943359375002, 14.705078125],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 4,
        sovereignt: "Tajikistan",
        sov_a3: "TJK",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Tajikistan",
        adm0_a3: "TJK",
        geou_dif: 0,
        geounit: "Tajikistan",
        gu_a3: "TJK",
        su_dif: 0,
        subunit: "Tajikistan",
        su_a3: "TJK",
        brk_diff: 0,
        name: "Tajikistan",
        name_long: "Tajikistan",
        brk_a3: "TJK",
        brk_name: "Tajikistan",
        brk_group: null,
        abbrev: "Tjk.",
        postal: "TJ",
        formal_en: "Republic of Tajikistan",
        formal_fr: null,
        name_ciawf: "Tajikistan",
        note_adm0: null,
        note_brk: null,
        name_sort: "Tajikistan",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 6,
        mapcolor9: 2,
        mapcolor13: 5,
        pop_est: 9321018,
        pop_rank: 13,
        pop_year: 2019,
        gdp_md: 8116,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "5. Low income",
        fips_10: "TI",
        iso_a2: "TJ",
        iso_a2_eh: "TJ",
        iso_a3: "TJK",
        iso_a3_eh: "TJK",
        iso_n3: "762",
        iso_n3_eh: "762",
        un_a3: "762",
        wb_a2: "TJ",
        wb_a3: "TJK",
        woe_id: 23424961,
        woe_id_eh: 23424961,
        woe_note: "Exact WOE match as country",
        adm0_iso: "TJK",
        adm0_diff: null,
        adm0_tlc: "TJK",
        adm0_a3_us: "TJK",
        adm0_a3_fr: "TJK",
        adm0_a3_ru: "TJK",
        adm0_a3_es: "TJK",
        adm0_a3_cn: "TJK",
        adm0_a3_tw: "TJK",
        adm0_a3_in: "TJK",
        adm0_a3_np: "TJK",
        adm0_a3_pk: "TJK",
        adm0_a3_de: "TJK",
        adm0_a3_gb: "TJK",
        adm0_a3_br: "TJK",
        adm0_a3_il: "TJK",
        adm0_a3_ps: "TJK",
        adm0_a3_sa: "TJK",
        adm0_a3_eg: "TJK",
        adm0_a3_ma: "TJK",
        adm0_a3_pt: "TJK",
        adm0_a3_ar: "TJK",
        adm0_a3_jp: "TJK",
        adm0_a3_ko: "TJK",
        adm0_a3_vn: "TJK",
        adm0_a3_tr: "TJK",
        adm0_a3_id: "TJK",
        adm0_a3_pl: "TJK",
        adm0_a3_gr: "TJK",
        adm0_a3_it: "TJK",
        adm0_a3_nl: "TJK",
        adm0_a3_se: "TJK",
        adm0_a3_bd: "TJK",
        adm0_a3_ua: "TJK",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Central Asia",
        region_wb: "Europe & Central Asia",
        name_len: 10,
        long_len: 10,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: 72.587276,
        label_y: 38.199835,
        ne_id: 1159321307,
        wikidataid: "Q863",
        name_ar: "طاجيكستان",
        name_bn: "তাজিকিস্তান",
        name_de: "Tadschikistan",
        name_en: "Tajikistan",
        name_es: "Tayikistán",
        name_fa: "تاجیکستان",
        name_fr: "Tadjikistan",
        name_el: "Τατζικιστάν",
        name_he: "טג׳יקיסטן",
        name_hi: "ताजिकिस्तान",
        name_hu: "Tádzsikisztán",
        name_id: "Tajikistan",
        name_it: "Tagikistan",
        name_ja: "タジキスタン",
        name_ko: "타지키스탄",
        name_nl: "Tadzjikistan",
        name_pl: "Tadżykistan",
        name_pt: "Tajiquistão",
        name_ru: "Таджикистан",
        name_sv: "Tadzjikistan",
        name_tr: "Tacikistan",
        name_uk: "Таджикистан",
        name_ur: "تاجکستان",
        name_vi: "Tajikistan",
        name_zh: "塔吉克斯坦",
        name_zht: "塔吉克",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "TJK.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [67.75898437500001, 37.172216796875],
              [67.79804687500001, 37.244970703125],
              [67.81435546875002, 37.48701171875],
              [67.86357421875002, 37.570703125],
              [68.01093750000001, 37.720947265625],
              [68.08759765625001, 37.83544921875],
              [68.17402343750001, 37.92841796875],
              [68.23652343750001, 37.95966796875],
              [68.29404296875, 38.03291015625],
              [68.34121093750002, 38.116796875],
              [68.3544921875, 38.16953125],
              [68.35029296875001, 38.21103515625],
              [68.33310546875, 38.23779296875],
              [68.25136718750002, 38.29453125],
              [68.144140625, 38.383105468749996],
              [68.08720703125002, 38.473535156249994],
              [68.05595703125002, 38.588916015624996],
              [68.0478515625, 38.669287109375],
              [68.14853515625, 38.890625],
              [68.13251953125001, 38.927636718749994],
              [68.103515625, 38.96201171875],
              [68.04433593750002, 38.98359375],
              [67.9595703125, 38.992919921875],
              [67.87568359375001, 38.9830078125],
              [67.7685546875, 38.982226562499996],
              [67.69443359375, 38.99462890625],
              [67.67656250000002, 39.00849609375],
              [67.66728515625002, 39.109179687499996],
              [67.64833984375002, 39.1310546875],
              [67.61650390625002, 39.150292968749994],
              [67.400390625, 39.196679687499994],
              [67.35761718750001, 39.216699218749994],
              [67.349609375, 39.242089843749994],
              [67.42617187500002, 39.465576171875],
              [67.4595703125, 39.482421875],
              [67.49169921875, 39.51875],
              [67.54248046875, 39.5576171875],
              [67.71904296875002, 39.621386718749996],
              [67.90859375000002, 39.593798828124996],
              [68.0771484375, 39.56416015625],
              [68.24492187500002, 39.548291015625],
              [68.30302734375002, 39.5376953125],
              [68.3990234375, 39.528857421874996],
              [68.46328125000002, 39.53671875],
              [68.50693359375, 39.562792968749996],
              [68.58613281250001, 39.6349609375],
              [68.6103515625, 39.74326171875],
              [68.63896484375002, 39.8388671875],
              [68.68691406250002, 39.8462890625],
              [68.73525390625002, 39.836230468749996],
              [68.75820312500002, 39.85556640625],
              [68.76796875000002, 39.8818359375],
              [68.77783203125, 39.904199218749994],
              [68.79765625000002, 39.909130859375],
              [68.83242187500002, 39.884326171874996],
              [68.85224609375001, 39.890966796875],
              [68.86875, 39.907470703125],
              [68.8638671875, 39.92734375],
              [68.82441406250001, 39.960791015625],
              [68.78945312500002, 40.013330078124994],
              [68.79277343750002, 40.031494140625],
              [68.8046875, 40.050341796874996],
              [68.90849609375002, 40.068212890625],
              [68.9556640625, 40.071337890624996],
              [68.97207031250002, 40.08994140625],
              [68.96601562500001, 40.119580078125],
              [68.92685546875, 40.136328125],
              [68.78457031250002, 40.127099609374994],
              [68.63974609375, 40.129199218749996],
              [68.62246093750002, 40.147265625],
              [68.63066406250002, 40.16708984375],
              [68.65253906250001, 40.182666015624996],
              [68.9517578125, 40.222607421875],
              [69.1103515625, 40.208740234375],
              [69.22832031250002, 40.18759765625],
              [69.27490234375, 40.198095703125],
              [69.21953125000002, 40.288134765624996],
              [69.29443359375, 40.29658203125],
              [69.30419921875, 40.327392578125],
              [69.20625, 40.566552734374994],
              [69.25996093750001, 40.587646484375],
              [69.31396484375, 40.634765625],
              [69.309375, 40.723925781249996],
              [69.35722656250002, 40.767382812499996],
              [69.41386718750002, 40.797167968749996],
              [69.49824218750001, 40.76708984375],
              [69.62841796875, 40.679052734375],
              [69.67080078125002, 40.661962890625],
              [69.712890625, 40.656982421875],
              [69.77324218750002, 40.68427734375],
              [70.00566406250002, 40.771435546875],
              [70.136328125, 40.82041015625],
              [70.29208984375, 40.89169921875],
              [70.3189453125, 40.919238281249996],
              [70.37265625, 41.027636718749996],
              [70.401953125, 41.035107421875],
              [70.44150390625, 41.0234375],
              [70.57822265625, 40.911474609375],
              [70.65732421875, 40.839648437499996],
              [70.65732421875, 40.815087890624994],
              [70.634765625, 40.79658203125],
              [70.63916015625, 40.778564453125],
              [70.7509765625, 40.739599609375],
              [70.75107421875, 40.72177734375],
              [70.7255859375, 40.687792968749996],
              [70.71201171875, 40.669091796874994],
              [70.69833984375, 40.661181640624996],
              [70.548828125, 40.562792968749996],
              [70.38261718750002, 40.453515625],
              [70.37714843750001, 40.439257812499996],
              [70.3697265625, 40.412011718749994],
              [70.37158203125, 40.384130859375],
              [70.39824218750002, 40.361376953124996],
              [70.46992187500001, 40.345361328124994],
              [70.53359375000002, 40.324511718749996],
              [70.56582031250002, 40.267138671874996],
              [70.60273437500001, 40.214160156249996],
              [70.653125, 40.201171875],
              [70.8994140625, 40.2345703125],
              [70.9580078125, 40.2388671875],
              [70.9609375, 40.220654296875],
              [70.94638671875, 40.18759765625],
              [70.73857421875002, 40.131152343749996],
              [70.64433593750002, 40.083447265625],
              [70.62412109375, 39.998974609375],
              [70.59921875, 39.974511718749994],
              [70.55683593750001, 39.954492187499994],
              [70.51513671875, 39.94990234375],
              [70.45136718750001, 40.04921875],
              [70.37890625, 40.069873046874996],
              [70.2744140625, 40.104833984375],
              [70.07148437500001, 40.172753906249994],
              [69.966796875, 40.20224609375],
              [69.765234375, 40.158007812499996],
              [69.5302734375, 40.097314453124994],
              [69.49365234375, 40.060351562499996],
              [69.46875, 40.020751953125],
              [69.47099609375002, 39.990625],
              [69.487890625, 39.950439453125],
              [69.47626953125001, 39.919726562499996],
              [69.43193359375002, 39.909765625],
              [69.36542968750001, 39.947070312499996],
              [69.3072265625, 39.968554687499996],
              [69.27880859375, 39.917773437499996],
              [69.2447265625, 39.827099609375],
              [69.2291015625, 39.761083984375],
              [69.2802734375, 39.665869140625],
              [69.29765625000002, 39.524804687499994],
              [69.39150390625002, 39.532470703125],
              [69.46328125000002, 39.532080078125],
              [69.59882812500001, 39.573779296874996],
              [69.6669921875, 39.57490234375],
              [69.7720703125, 39.55673828125],
              [69.95595703125002, 39.553076171875],
              [70.10166015625, 39.560595703124996],
              [70.13681640625, 39.557568359375],
              [70.17109375000001, 39.5841796875],
              [70.20927734375002, 39.575],
              [70.24482421875001, 39.542626953124994],
              [70.39208984375, 39.581884765625],
              [70.50117187500001, 39.587353515625],
              [70.56796875, 39.57587890625],
              [70.60781250000002, 39.564404296875],
              [70.67861328125002, 39.4712890625],
              [70.73310546875001, 39.41328125],
              [70.79931640625, 39.3947265625],
              [71.0048828125, 39.411865234375],
              [71.0650390625, 39.493408203125],
              [71.11806640625002, 39.513574218749994],
              [71.202734375, 39.519824218749996],
              [71.27285156250002, 39.535302734374994],
              [71.32851562500002, 39.568701171875],
              [71.404296875, 39.5978515625],
              [71.4703125, 39.603662109374994],
              [71.50302734375, 39.582177734374994],
              [71.51738281250002, 39.553857421874994],
              [71.505859375, 39.51708984375],
              [71.50332031250002, 39.478808593749996],
              [71.54628906250002, 39.453076171875],
              [71.67265625000002, 39.447070312499996],
              [71.73222656250002, 39.422998046874994],
              [71.7353515625, 39.377734375],
              [71.72568359375, 39.306591796875],
              [71.77861328125002, 39.277978515624994],
              [71.80595703125002, 39.2755859375],
              [71.99101562500002, 39.350927734375],
              [72.04277343750002, 39.3521484375],
              [72.08417968750001, 39.31064453125],
              [72.14736328125002, 39.2607421875],
              [72.22998046875, 39.20751953125],
              [72.24980468750002, 39.215673828125],
              [72.28720703125, 39.273730468749996],
              [72.35771484375002, 39.336865234375],
              [72.490234375, 39.357373046875],
              [72.56337890625002, 39.377197265625],
              [72.63994140625002, 39.385986328125],
              [72.87246093750002, 39.360400390624996],
              [72.94941406250001, 39.357080078124994],
              [73.10927734375002, 39.3619140625],
              [73.2349609375, 39.374560546874996],
              [73.33613281250001, 39.412353515625],
              [73.38740234375001, 39.442724609375],
              [73.47041015625001, 39.460595703124994],
              [73.57558593750002, 39.4576171875],
              [73.63164062500002, 39.448876953124994],
              [73.636328125, 39.3966796875],
              [73.62314453125, 39.2978515625],
              [73.60732421875002, 39.22919921875],
              [73.6904296875, 39.104541015624996],
              [73.74375, 39.04453125],
              [73.79560546875001, 39.0021484375],
              [73.8052734375, 38.968652343749994],
              [73.79453125, 38.941308593749994],
              [73.72998046875, 38.914697265624994],
              [73.70683593750002, 38.88623046875],
              [73.69609375000002, 38.854296875],
              [73.716796875, 38.817236328125],
              [73.75410156250001, 38.69892578125],
              [73.80166015625002, 38.606884765625],
              [73.869140625, 38.562890625],
              [73.97001953125002, 38.53369140625],
              [74.02558593750001, 38.53984375],
              [74.06533203125002, 38.60849609375],
              [74.13134765625, 38.661181640624996],
              [74.18730468750002, 38.657519531249996],
              [74.27744140625, 38.659765625],
              [74.51406250000002, 38.6],
              [74.74501953125002, 38.510009765625],
              [74.81230468750002, 38.460302734375],
              [74.8359375, 38.404296875],
              [74.7720703125, 38.274755859375],
              [74.77509765625001, 38.19189453125],
              [74.7896484375, 38.10361328125],
              [74.84248046875001, 38.0380859375],
              [74.89082031250001, 37.92578125],
              [74.90029296875002, 37.83271484375],
              [74.92128906250002, 37.804980468749996],
              [74.93828125000002, 37.772509765624996],
              [74.91230468750001, 37.687304687499996],
              [74.89423828125001, 37.601416015625],
              [74.91582031250002, 37.572802734374996],
              [74.98642578125, 37.53037109375],
              [75.09746093750002, 37.45126953125],
              [75.11875, 37.385693359375],
              [75.07900390625002, 37.344042968749996],
              [75.0083984375, 37.2935546875],
              [74.91816406250001, 37.25],
              [74.89130859375001, 37.231640625],
              [74.87539062500002, 37.241992187499996],
              [74.83046875000002, 37.2859375],
              [74.73056640625, 37.35703125],
              [74.659375, 37.394482421875],
              [74.52421875000002, 37.382373046874996],
              [74.444921875, 37.39560546875],
              [74.34902343750002, 37.41875],
              [74.25966796875002, 37.415429687499994],
              [74.20351562500002, 37.372460937499994],
              [74.16708984375, 37.329443359375],
              [74.077734375, 37.316210937499996],
              [73.948828125, 37.283154296875],
              [73.749609375, 37.231787109375],
              [73.65351562500001, 37.23935546875],
              [73.6275390625, 37.261572265625],
              [73.64882812500002, 37.2912109375],
              [73.71728515625, 37.329443359375],
              [73.73378906250002, 37.37578125],
              [73.72060546875002, 37.41875],
              [73.65712890625002, 37.43046875],
              [73.63261718750002, 37.43720703125],
              [73.60468750000001, 37.446044921875],
              [73.48134765625002, 37.4716796875],
              [73.38291015625, 37.462255859375],
              [73.21113281250001, 37.408496093749996],
              [72.8955078125, 37.267529296875],
              [72.75703125000001, 37.172705078125],
              [72.65742187500001, 37.029052734375],
              [72.35878906250002, 36.98291015625],
              [72.15351562500001, 36.900537109374994],
              [71.9419921875, 36.766455078125],
              [71.80205078125002, 36.694287109375],
              [71.73378906250002, 36.684033203125],
              [71.665625, 36.696923828124994],
              [71.59746093750002, 36.73291015625],
              [71.530859375, 36.845117187499994],
              [71.471875, 37.015087890625],
              [71.43291015625002, 37.1275390625],
              [71.45478515625001, 37.271826171875],
              [71.47968750000001, 37.43603515625],
              [71.50507812500001, 37.602929687499994],
              [71.54619140625002, 37.795654296875],
              [71.58037109375002, 37.8642578125],
              [71.58222656250001, 37.910107421875],
              [71.55195312500001, 37.933154296874996],
              [71.48779296875, 37.931884765625],
              [71.3896484375, 37.906298828124996],
              [71.319921875, 37.90185546875],
              [71.27851562500001, 37.918408203125],
              [71.2828125, 38.00791015625],
              [71.33271484375001, 38.170263671875],
              [71.255859375, 38.306982421875],
              [71.05214843750002, 38.417871093749994],
              [70.87890625, 38.456396484375],
              [70.7359375, 38.42255859375],
              [70.6158203125, 38.334423828125],
              [70.5185546875, 38.1919921875],
              [70.41777343750002, 38.075439453125],
              [70.31328125000002, 37.984814453125],
              [70.23876953125, 37.941210937499996],
              [70.21464843750002, 37.9244140625],
              [70.19941406250001, 37.886035156249996],
              [70.25498046875, 37.765380859375],
              [70.25146484375, 37.66416015625],
              [70.18867187500001, 37.582470703125],
              [70.11982421875001, 37.543505859374996],
              [70.04472656250002, 37.547216796875],
              [69.9849609375, 37.566162109375],
              [69.940625, 37.60029296875],
              [69.8208984375, 37.6095703125],
              [69.62578125000002, 37.594042968749996],
              [69.49208984375002, 37.553076171875],
              [69.42011718750001, 37.48671875],
              [69.39921875000002, 37.399316406249994],
              [69.4296875, 37.290869140625],
              [69.41445312500002, 37.207763671875],
              [69.35380859375002, 37.150048828124994],
              [69.30390625000001, 37.116943359375],
              [69.26484375000001, 37.1083984375],
              [69.18017578125, 37.15830078125],
              [69.05, 37.266503906249994],
              [68.96044921875, 37.325048828125],
              [68.91181640625001, 37.333935546875],
              [68.88525390625, 37.328076171875],
              [68.85537109375002, 37.316845703125],
              [68.8384765625, 37.30283203125],
              [68.82373046875, 37.270703125],
              [68.78203125000002, 37.2580078125],
              [68.7232421875, 37.268017578125],
              [68.66914062500001, 37.2583984375],
              [68.63701171875002, 37.224462890625],
              [68.546484375, 37.183447265625],
              [68.38691406250001, 37.1375],
              [68.29951171875001, 37.088427734374996],
              [68.284765625, 37.036328125],
              [68.26093750000001, 37.013085937499994],
              [68.21210937500001, 37.021533203124996],
              [68.06777343750002, 36.9498046875],
              [67.9580078125, 36.972021484375],
              [67.83447265625, 37.064208984375],
              [67.76601562500002, 37.14013671875],
              [67.75898437500001, 37.172216796875],
            ],
          ],
          [
            [
              [70.65253906250001, 40.93662109375],
              [70.62275390625001, 40.934423828125],
              [70.56875, 40.981835937499994],
              [70.55, 41.014892578125],
              [70.57207031250002, 41.024804687499994],
              [70.61835937500001, 41.00166015625],
              [70.64921875000002, 40.960839843749994],
              [70.65253906250001, 40.93662109375],
            ],
          ],
          [
            [
              [70.70166015625, 39.82529296875],
              [70.61210937500002, 39.786767578124994],
              [70.5595703125, 39.790917968749994],
              [70.51865234375, 39.828173828124996],
              [70.4892578125, 39.863037109375],
              [70.48281250000002, 39.882714843749994],
              [70.49775390625001, 39.882421875],
              [70.56708984375001, 39.866601562499994],
              [70.66416015625, 39.85546875],
              [70.6982421875, 39.845849609374994],
              [70.70166015625, 39.82529296875],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 4,
        sovereignt: "United Arab Emirates",
        sov_a3: "ARE",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "United Arab Emirates",
        adm0_a3: "ARE",
        geou_dif: 0,
        geounit: "United Arab Emirates",
        gu_a3: "ARE",
        su_dif: 0,
        subunit: "United Arab Emirates",
        su_a3: "ARE",
        brk_diff: 0,
        name: "United Arab Emirates",
        name_long: "United Arab Emirates",
        brk_a3: "ARE",
        brk_name: "United Arab Emirates",
        brk_group: null,
        abbrev: "U.A.E.",
        postal: "AE",
        formal_en: "United Arab Emirates",
        formal_fr: null,
        name_ciawf: "United Arab Emirates",
        note_adm0: null,
        note_brk: null,
        name_sort: "United Arab Emirates",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 1,
        mapcolor9: 3,
        mapcolor13: 3,
        pop_est: 9770529,
        pop_rank: 13,
        pop_year: 2019,
        gdp_md: 421142,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "2. High income: nonOECD",
        fips_10: "AE",
        iso_a2: "AE",
        iso_a2_eh: "AE",
        iso_a3: "ARE",
        iso_a3_eh: "ARE",
        iso_n3: "784",
        iso_n3_eh: "784",
        un_a3: "784",
        wb_a2: "AE",
        wb_a3: "ARE",
        woe_id: 23424738,
        woe_id_eh: 23424738,
        woe_note: "Exact WOE match as country",
        adm0_iso: "ARE",
        adm0_diff: null,
        adm0_tlc: "ARE",
        adm0_a3_us: "ARE",
        adm0_a3_fr: "ARE",
        adm0_a3_ru: "ARE",
        adm0_a3_es: "ARE",
        adm0_a3_cn: "ARE",
        adm0_a3_tw: "ARE",
        adm0_a3_in: "ARE",
        adm0_a3_np: "ARE",
        adm0_a3_pk: "ARE",
        adm0_a3_de: "ARE",
        adm0_a3_gb: "ARE",
        adm0_a3_br: "ARE",
        adm0_a3_il: "ARE",
        adm0_a3_ps: "ARE",
        adm0_a3_sa: "ARE",
        adm0_a3_eg: "ARE",
        adm0_a3_ma: "ARE",
        adm0_a3_pt: "ARE",
        adm0_a3_ar: "ARE",
        adm0_a3_jp: "ARE",
        adm0_a3_ko: "ARE",
        adm0_a3_vn: "ARE",
        adm0_a3_tr: "ARE",
        adm0_a3_id: "ARE",
        adm0_a3_pl: "ARE",
        adm0_a3_gr: "ARE",
        adm0_a3_it: "ARE",
        adm0_a3_nl: "ARE",
        adm0_a3_se: "ARE",
        adm0_a3_bd: "ARE",
        adm0_a3_ua: "ARE",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Western Asia",
        region_wb: "Middle East & North Africa",
        name_len: 20,
        long_len: 20,
        abbrev_len: 6,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: 54.547256,
        label_y: 23.466285,
        ne_id: 1159320329,
        wikidataid: "Q878",
        name_ar: "الإمارات العربية المتحدة",
        name_bn: "সংযুক্ত আরব আমিরাত",
        name_de: "Vereinigte Arabische Emirate",
        name_en: "United Arab Emirates",
        name_es: "Emiratos Árabes Unidos",
        name_fa: "امارات متحده عربی",
        name_fr: "Émirats arabes unis",
        name_el: "Ηνωμένα Αραβικά Εμιράτα",
        name_he: "איחוד האמירויות הערביות",
        name_hi: "संयुक्त अरब अमीरात",
        name_hu: "Egyesült Arab Emírségek",
        name_id: "Uni Emirat Arab",
        name_it: "Emirati Arabi Uniti",
        name_ja: "アラブ首長国連邦",
        name_ko: "아랍에미리트",
        name_nl: "Verenigde Arabische Emiraten",
        name_pl: "Zjednoczone Emiraty Arabskie",
        name_pt: "Emirados Árabes Unidos",
        name_ru: "Объединённые Арабские Эмираты",
        name_sv: "Förenade Arabemiraten",
        name_tr: "Birleşik Arap Emirlikleri",
        name_uk: "Об'єднані Арабські Емірати",
        name_ur: "متحدہ عرب امارات",
        name_vi: "Các Tiểu vương quốc Ả Rập Thống nhất",
        name_zh: "阿拉伯联合酋长国",
        name_zht: "阿拉伯聯合大公國",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "ARE.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [56.2978515625, 25.650683593750003],
              [56.36347656250001, 25.569384765625003],
              [56.37285156250002, 25.018310546875],
              [56.38798828125002, 24.979199218749997],
              [56.35292968750002, 24.973291015624994],
              [56.313574218750006, 24.931298828124994],
              [56.26787109375002, 24.86669921875],
              [56.2046875, 24.833300781250003],
              [56.15449218750001, 24.79550781249999],
              [56.106542968750006, 24.748681640624994],
              [56.06386718750002, 24.73876953125],
              [56.008398437500006, 24.798242187499994],
              [55.9703125, 24.858935546875003],
              [55.97968750000001, 24.8720703125],
              [56.00634765625, 24.876416015624997],
              [56.016699218750006, 24.90771484375],
              [56.000585937500006, 24.95322265624999],
              [55.96308593750001, 24.970263671875003],
              [55.91582031250002, 24.97177734374999],
              [55.870703125, 24.951416015625],
              [55.822851562500006, 24.911279296874994],
              [55.79570312500002, 24.868115234374997],
              [55.791601562500006, 24.781298828125003],
              [55.80419921875, 24.68359375],
              [55.80390625000001, 24.63623046875],
              [55.77753906250001, 24.57734375],
              [55.768164062500006, 24.490625],
              [55.78681640625001, 24.423535156249997],
              [55.80400390625002, 24.383544921875],
              [55.8056640625, 24.349804687499997],
              [55.760839843750006, 24.24267578125],
              [55.79970703125002, 24.22265625],
              [55.92861328125002, 24.215136718750003],
              [55.96630859375, 24.142626953125003],
              [55.9921875, 24.09296875],
              [55.98515625000002, 24.063378906249994],
              [55.894140625, 24.04140625],
              [55.77910156250002, 24.01708984375],
              [55.69658203125002, 24.024121093749997],
              [55.5478515625, 23.991357421874994],
              [55.46845703125001, 23.941113281249997],
              [55.491796875, 23.90966796875],
              [55.51933593750002, 23.885498046875],
              [55.53164062500002, 23.819042968749997],
              [55.50849609375001, 23.724609375],
              [55.46630859375, 23.63291015624999],
              [55.41386718750002, 23.51875],
              [55.35322265625001, 23.387451171875],
              [55.27021484375001, 23.18994140625],
              [55.19990234375001, 23.034765625],
              [55.19218750000002, 22.92294921874999],
              [55.19404296875001, 22.85],
              [55.18583984375002, 22.7041015625],
              [55.11943359375002, 22.623925781249994],
              [55.10429687500002, 22.621484375],
              [55.025, 22.631152343750003],
              [54.99824218750001, 22.634375],
              [54.922460937500006, 22.64365234374999],
              [54.80488281250001, 22.658007812500003],
              [54.65224609375002, 22.676660156249994],
              [54.4716796875, 22.69873046875],
              [54.270117187500006, 22.723339843749997],
              [54.05458984375002, 22.749658203124994],
              [53.832128906250006, 22.776806640624997],
              [53.60957031250001, 22.804003906250003],
              [53.39404296875, 22.830322265625],
              [53.1923828125, 22.854931640624997],
              [53.01191406250001, 22.877001953125003],
              [52.85927734375002, 22.89560546874999],
              [52.74160156250002, 22.91000976562499],
              [52.66591796875002, 22.91928710937499],
              [52.63916015625, 22.922509765624994],
              [52.55507812500002, 22.9328125],
              [52.50957031250002, 22.986962890624994],
              [52.45458984375, 23.052441406249997],
              [52.39960937500001, 23.11796875],
              [52.34453125000002, 23.183496093749994],
              [52.28955078125, 23.248974609374997],
              [52.23457031250001, 23.314453125],
              [52.17949218750002, 23.37998046874999],
              [52.12451171875, 23.445458984374994],
              [52.069433593750006, 23.510986328125],
              [52.01445312500002, 23.576464843750003],
              [51.95947265625, 23.641992187499994],
              [51.904394531250006, 23.70751953125],
              [51.84941406250002, 23.772998046875003],
              [51.79433593750002, 23.83847656249999],
              [51.739355468750006, 23.904003906249997],
              [51.684375, 23.96953125],
              [51.62929687500002, 24.03500976562499],
              [51.59257812500002, 24.078857421875],
              [51.57216796875002, 24.128320312499994],
              [51.568359375, 24.25791015624999],
              [51.568359375, 24.286181640625003],
              [51.60546875, 24.338427734375003],
              [51.623144531250006, 24.301074218750003],
              [51.66455078125, 24.250439453124997],
              [51.73476562500002, 24.26279296874999],
              [51.767578125, 24.25439453125],
              [51.79169921875001, 24.074755859375003],
              [51.84316406250002, 24.010888671874994],
              [51.90605468750002, 23.9853515625],
              [52.11855468750002, 23.97109375],
              [52.25087890625002, 23.99521484374999],
              [52.51142578125001, 24.1125],
              [52.64824218750002, 24.15463867187499],
              [53.0263671875, 24.14731445312499],
              [53.32958984375, 24.0984375],
              [53.8017578125, 24.069482421874994],
              [53.89335937500002, 24.077050781249994],
              [54.14794921875, 24.171191406250003],
              [54.304296875, 24.254296875],
              [54.397070312500006, 24.27817382812499],
              [54.45839843750002, 24.35825195312499],
              [54.49882812500002, 24.462695312500003],
              [54.53466796875, 24.530957031249997],
              [54.58046875000002, 24.563525390625003],
              [54.624121093750006, 24.621289062499997],
              [54.65898437500002, 24.715527343749997],
              [54.74677734375001, 24.810449218749994],
              [55.09814453125, 25.04160156249999],
              [55.30351562500002, 25.23681640625],
              [55.32167968750002, 25.2998046875],
              [55.43339843750002, 25.394482421874997],
              [55.52285156250002, 25.49814453124999],
              [55.94121093750002, 25.793994140625003],
              [56.02519531250002, 25.916015625],
              [56.07460937500002, 26.052783203124996],
              [56.08046875000002, 26.062646484374994],
              [56.11650390625002, 26.068164062499996],
              [56.16748046875, 26.0474609375],
              [56.17255859375001, 25.94516601562499],
              [56.15410156250002, 25.84848632812499],
              [56.151953125, 25.74609375],
              [56.144628906250006, 25.69052734374999],
              [56.18359375, 25.644921875],
              [56.24951171875, 25.625390625],
              [56.27851562500001, 25.627734375],
              [56.2978515625, 25.650683593750003],
            ],
            [
              [56.281835937500006, 25.235546875],
              [56.28779296875001, 25.278613281250003],
              [56.27734375, 25.30087890624999],
              [56.23427734375002, 25.30380859374999],
              [56.21650390625001, 25.26669921874999],
              [56.210546875, 25.21328125],
              [56.240234375, 25.20883789062499],
              [56.281835937500006, 25.235546875],
            ],
          ],
          [
            [
              [53.33222656250001, 24.25859375],
              [53.25830078125, 24.2529296875],
              [53.19091796875, 24.290917968749994],
              [53.33251953125, 24.341601562500003],
              [53.37089843750002, 24.364453125],
              [53.41240234375002, 24.411035156249994],
              [53.4453125, 24.37119140624999],
              [53.40898437500002, 24.307910156250003],
              [53.38261718750002, 24.280859375],
              [53.33222656250001, 24.25859375],
            ],
          ],
          [
            [
              [52.61689453125001, 24.28857421875],
              [52.6, 24.281298828125003],
              [52.58222656250001, 24.335253906250003],
              [52.58359375, 24.35234375],
              [52.62939453125, 24.376757812500003],
              [52.65761718750002, 24.33261718749999],
              [52.61689453125001, 24.28857421875],
            ],
          ],
          [
            [
              [53.927832031250006, 24.177197265624997],
              [53.928125, 24.143359375],
              [53.82636718750001, 24.153125],
              [53.79912109375002, 24.135546875],
              [53.7158203125, 24.1453125],
              [53.63447265625001, 24.16977539062499],
              [53.68964843750001, 24.21079101562499],
              [53.83378906250002, 24.258935546874994],
              [53.89375, 24.215136718750003],
              [53.927832031250006, 24.177197265624997],
            ],
          ],
          [
            [
              [54.465429687500006, 24.442773437499994],
              [54.456640625, 24.42333984375],
              [54.42841796875001, 24.425097656250003],
              [54.35771484375002, 24.442773437499994],
              [54.33476562500002, 24.47104492187499],
              [54.37890625, 24.504589843749997],
              [54.39833984375002, 24.50634765625],
              [54.42656250000002, 24.47104492187499],
              [54.465429687500006, 24.442773437499994],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Mongolia",
        sov_a3: "MNG",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Mongolia",
        adm0_a3: "MNG",
        geou_dif: 0,
        geounit: "Mongolia",
        gu_a3: "MNG",
        su_dif: 0,
        subunit: "Mongolia",
        su_a3: "MNG",
        brk_diff: 0,
        name: "Mongolia",
        name_long: "Mongolia",
        brk_a3: "MNG",
        brk_name: "Mongolia",
        brk_group: null,
        abbrev: "Mong.",
        postal: "MN",
        formal_en: "Mongolia",
        formal_fr: null,
        name_ciawf: "Mongolia",
        note_adm0: null,
        note_brk: null,
        name_sort: "Mongolia",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 5,
        mapcolor9: 5,
        mapcolor13: 6,
        pop_est: 3225167,
        pop_rank: 12,
        pop_year: 2019,
        gdp_md: 13996,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        fips_10: "MG",
        iso_a2: "MN",
        iso_a2_eh: "MN",
        iso_a3: "MNG",
        iso_a3_eh: "MNG",
        iso_n3: "496",
        iso_n3_eh: "496",
        un_a3: "496",
        wb_a2: "MN",
        wb_a3: "MNG",
        woe_id: 23424887,
        woe_id_eh: 23424887,
        woe_note: "Exact WOE match as country",
        adm0_iso: "MNG",
        adm0_diff: null,
        adm0_tlc: "MNG",
        adm0_a3_us: "MNG",
        adm0_a3_fr: "MNG",
        adm0_a3_ru: "MNG",
        adm0_a3_es: "MNG",
        adm0_a3_cn: "MNG",
        adm0_a3_tw: "MNG",
        adm0_a3_in: "MNG",
        adm0_a3_np: "MNG",
        adm0_a3_pk: "MNG",
        adm0_a3_de: "MNG",
        adm0_a3_gb: "MNG",
        adm0_a3_br: "MNG",
        adm0_a3_il: "MNG",
        adm0_a3_ps: "MNG",
        adm0_a3_sa: "MNG",
        adm0_a3_eg: "MNG",
        adm0_a3_ma: "MNG",
        adm0_a3_pt: "MNG",
        adm0_a3_ar: "MNG",
        adm0_a3_jp: "MNG",
        adm0_a3_ko: "MNG",
        adm0_a3_vn: "MNG",
        adm0_a3_tr: "MNG",
        adm0_a3_id: "MNG",
        adm0_a3_pl: "MNG",
        adm0_a3_gr: "MNG",
        adm0_a3_it: "MNG",
        adm0_a3_nl: "MNG",
        adm0_a3_se: "MNG",
        adm0_a3_bd: "MNG",
        adm0_a3_ua: "MNG",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Eastern Asia",
        region_wb: "East Asia & Pacific",
        name_len: 8,
        long_len: 8,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 7,
        label_x: 104.150405,
        label_y: 45.997488,
        ne_id: 1159321071,
        wikidataid: "Q711",
        name_ar: "منغوليا",
        name_bn: "মঙ্গোলিয়া",
        name_de: "Mongolei",
        name_en: "Mongolia",
        name_es: "Mongolia",
        name_fa: "مغولستان",
        name_fr: "Mongolie",
        name_el: "Μογγολία",
        name_he: "מונגוליה",
        name_hi: "मंगोलिया",
        name_hu: "Mongólia",
        name_id: "Mongolia",
        name_it: "Mongolia",
        name_ja: "モンゴル国",
        name_ko: "몽골",
        name_nl: "Mongolië",
        name_pl: "Mongolia",
        name_pt: "Mongólia",
        name_ru: "Монголия",
        name_sv: "Mongoliet",
        name_tr: "Moğolistan",
        name_uk: "Монголія",
        name_ur: "منگولیا",
        name_vi: "Mông Cổ",
        name_zh: "蒙古国",
        name_zht: "蒙古國",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "MNG.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [87.81425781249999, 49.1623046875],
            [87.81826171875002, 49.162109375],
            [87.93476562500001, 49.16455078125],
            [87.98808593749999, 49.186914062499994],
            [88.02851562500001, 49.219775390624996],
            [88.11572265625, 49.256298828125],
            [88.13427734375, 49.2984375],
            [88.13554687499999, 49.381494140624994],
            [88.19257812500001, 49.451708984374996],
            [88.33779296875002, 49.472558593749994],
            [88.39335937499999, 49.482861328125],
            [88.45244140624999, 49.472705078124996],
            [88.54433593750002, 49.482568359374994],
            [88.63320312500002, 49.486132812499996],
            [88.68271484375003, 49.46455078125],
            [88.74785156249999, 49.446240234375],
            [88.83164062500003, 49.4484375],
            [88.8603515625, 49.48154296875],
            [88.86386718750003, 49.527636718749996],
            [88.90019531249999, 49.539697265624994],
            [88.94541015625003, 49.507666015625],
            [88.97060546875002, 49.483740234375],
            [89.00839843750003, 49.472802734374994],
            [89.10947265625003, 49.501367187499994],
            [89.17998046874999, 49.5322265625],
            [89.20292968749999, 49.595703125],
            [89.24394531249999, 49.62705078125],
            [89.29921875000002, 49.611132812499996],
            [89.39560546875003, 49.6115234375],
            [89.475, 49.660546875],
            [89.57919921875003, 49.69970703125],
            [89.65410156249999, 49.71748046875],
            [89.66953125000003, 49.75048828125],
            [89.63427734375, 49.823291015624996],
            [89.64384765624999, 49.90302734375],
            [89.74423828125003, 49.948095703125],
            [89.87802734375003, 49.953515625],
            [89.97734374999999, 49.984326171875],
            [90.00498046875003, 50.069287109375],
            [90.0537109375, 50.09375],
            [90.10371093750001, 50.103320312499996],
            [90.22451171875002, 50.11669921875],
            [90.31132812499999, 50.151171875],
            [90.36484375000003, 50.166894531249994],
            [90.51689453124999, 50.213330078125],
            [90.65507812499999, 50.22236328125],
            [90.71435546875, 50.259423828124994],
            [90.7607421875, 50.305957031249996],
            [90.83808593750001, 50.32373046875],
            [90.91718750000001, 50.364160156249994],
            [91.02158203125003, 50.415478515625],
            [91.06279296874999, 50.422607421875],
            [91.23378906250002, 50.452392578125],
            [91.30058593749999, 50.46337890625],
            [91.3408203125, 50.470068359375],
            [91.4150390625, 50.468017578125],
            [91.44648437500001, 50.52216796875],
            [91.52167968750001, 50.56201171875],
            [91.596875, 50.575537109375],
            [91.63417968750002, 50.615136718749994],
            [91.70634765624999, 50.66552734375],
            [91.80429687500003, 50.693603515625],
            [91.95654296875, 50.697607421875],
            [92.10400390625, 50.6919921875],
            [92.1923828125, 50.700585937499994],
            [92.26533203125001, 50.775195312499996],
            [92.27900390625001, 50.81220703125],
            [92.29580078125002, 50.8498046875],
            [92.35478515624999, 50.864160156249994],
            [92.42636718750003, 50.803076171875],
            [92.48642578125003, 50.765087890625],
            [92.57890624999999, 50.725439453125],
            [92.62666015625001, 50.68828125],
            [92.68134765625001, 50.683203125],
            [92.73867187500002, 50.7109375],
            [92.779296875, 50.778662109375],
            [92.8564453125, 50.789111328124996],
            [92.94130859375002, 50.778222656249994],
            [92.96357421875001, 50.744921875],
            [92.970703125, 50.7125],
            [93.00986328125003, 50.654541015625],
            [93.103125, 50.60390625],
            [93.22255859375002, 50.60654296875],
            [93.2705078125, 50.615576171875],
            [93.38681640625003, 50.60849609375],
            [93.50107421875003, 50.597460937499996],
            [93.62558593750003, 50.585546875],
            [93.66201171875002, 50.58369140625],
            [93.79541015625, 50.57763671875],
            [93.98984375000003, 50.56884765625],
            [94.07578125000003, 50.5728515625],
            [94.25107421875003, 50.556396484375],
            [94.28701171875002, 50.511376953124994],
            [94.3193359375, 50.4048828125],
            [94.346875, 50.30341796875],
            [94.35468750000001, 50.221826171874994],
            [94.40019531249999, 50.179638671875],
            [94.45849609375, 50.16572265625],
            [94.496875, 50.1328125],
            [94.56464843750001, 50.087939453124996],
            [94.61474609375, 50.023730468749996],
            [94.67548828125001, 50.028076171875],
            [94.71806640624999, 50.043261718749996],
            [94.81123046875001, 50.048193359375],
            [94.93027343750003, 50.04375],
            [95.01289062500001, 50.008251953125],
            [95.04433593750002, 49.961572265624994],
            [95.11142578125003, 49.935449218749994],
            [95.16621093750001, 49.94384765625],
            [95.32949218750002, 49.944140625],
            [95.38564453125002, 49.941210937499996],
            [95.44179687500002, 49.91552734375],
            [95.52265625000001, 49.91123046875],
            [95.56718749999999, 49.94384765625],
            [95.70781249999999, 49.966015625],
            [95.78935546874999, 50.0125],
            [95.85195312500002, 50.012939453125],
            [95.8994140625, 49.990576171875],
            [95.93574218750001, 49.960009765624996],
            [95.98955078124999, 49.973583984375],
            [96.0185546875, 49.998779296875],
            [96.06552734375003, 49.99873046875],
            [96.11171875000002, 49.982470703124996],
            [96.22968750000001, 49.9541015625],
            [96.31503906250003, 49.901123046875],
            [96.38115234374999, 49.896044921874996],
            [96.46640625000003, 49.911523437499994],
            [96.50576171875002, 49.918701171875],
            [96.54326171874999, 49.892529296875],
            [96.59843749999999, 49.87841796875],
            [96.64023437500003, 49.897851562499994],
            [96.71171874999999, 49.911572265625],
            [96.98574218750002, 49.8828125],
            [97.04912109374999, 49.829882812499996],
            [97.09765625, 49.805029296875],
            [97.13691406250001, 49.76171875],
            [97.20859375000003, 49.730810546875],
            [97.35976562500002, 49.741455078125],
            [97.41835937500002, 49.773046875],
            [97.54082031249999, 49.843115234375],
            [97.58935546875, 49.911474609375],
            [97.65097656250003, 49.93359375],
            [97.720703125, 49.944628906249996],
            [97.78554687500002, 49.94453125],
            [97.85390625000002, 49.94677734375],
            [97.93662109375003, 49.99677734375],
            [98.00390625, 50.0142578125],
            [98.10341796875002, 50.07783203125],
            [98.12197265625002, 50.106591796874994],
            [98.17011718750001, 50.180566406249994],
            [98.2, 50.227685546874994],
            [98.25029296874999, 50.30244140625],
            [98.27734375, 50.422998046874994],
            [98.29267578125001, 50.486962890624994],
            [98.27949218750001, 50.533251953124996],
            [98.22050781249999, 50.557177734374996],
            [98.14501953125, 50.5685546875],
            [98.07890624999999, 50.603808593749996],
            [98.02978515625, 50.64462890625],
            [98.00117187500001, 50.702050781249994],
            [97.9619140625, 50.769140625],
            [97.96416015624999, 50.817675781249996],
            [97.953125, 50.85517578125],
            [97.91982421875002, 50.887158203125],
            [97.85615234375001, 50.943359375],
            [97.82529296875003, 50.985253906249994],
            [97.83574218749999, 51.051660156249994],
            [97.91083984375001, 51.165185546874994],
            [97.91787109375002, 51.21787109375],
            [97.92734375000003, 51.250732421875],
            [97.92324218750002, 51.28046875],
            [97.946875, 51.3484375],
            [97.98916015625002, 51.37705078125],
            [98.03759765625, 51.449951171875],
            [98.103125, 51.483544921874994],
            [98.18466796875003, 51.485742187499994],
            [98.21992187500001, 51.505615234375],
            [98.2375, 51.578417968749996],
            [98.27685546875, 51.634570312499996],
            [98.303125, 51.674267578125],
            [98.35273437500001, 51.717626953125],
            [98.64052734375002, 51.801171875],
            [98.76015625000002, 51.905078125],
            [98.80253906249999, 51.957470703125],
            [98.8486328125, 52.070068359375],
            [98.89316406250003, 52.11728515625],
            [98.95810546875003, 52.101708984375],
            [99.03427734375003, 52.035400390625],
            [99.09140625000003, 52.03486328125],
            [99.17617187500002, 51.998876953125],
            [99.40703124999999, 51.92353515625],
            [99.53232421875003, 51.89990234375],
            [99.61289062500003, 51.892529296875],
            [99.71923828125, 51.871630859374996],
            [99.78789062499999, 51.8275390625],
            [99.92167968749999, 51.755517578125],
            [100.03457031250002, 51.737109375],
            [100.23037109375002, 51.729833984375],
            [100.46894531250001, 51.72607421875],
            [100.53623046875003, 51.7134765625],
            [100.71074218749999, 51.661572265625],
            [100.90361328124999, 51.604248046875],
            [101.08535156250002, 51.553027343749996],
            [101.22324218750003, 51.51328125],
            [101.30449218749999, 51.474755859374994],
            [101.38125, 51.45263671875],
            [101.46435546875, 51.471484375],
            [101.57089843750003, 51.4671875],
            [101.82119140625002, 51.421044921874994],
            [101.97919921875001, 51.382226562499994],
            [102.11152343750001, 51.353466796875],
            [102.15566406250002, 51.313769531249996],
            [102.16005859375002, 51.26083984375],
            [102.14238281249999, 51.216064453125],
            [102.15195312500003, 51.10751953125],
            [102.19453125000001, 51.050683593749994],
            [102.21025390624999, 50.97431640625],
            [102.22617187500003, 50.90146484375],
            [102.21503906250001, 50.829443359375],
            [102.23505859375001, 50.7912109375],
            [102.27656250000001, 50.768701171874994],
            [102.31660156250001, 50.71845703125],
            [102.30332031250003, 50.66552734375],
            [102.28574218750003, 50.634667968749994],
            [102.28837890624999, 50.585107421874994],
            [102.33642578125, 50.544238281249996],
            [102.40683593750003, 50.536181640624996],
            [102.46943359375001, 50.525683593749996],
            [102.54628906250002, 50.461328125],
            [102.68330078125001, 50.387158203125],
            [102.76542968749999, 50.366552734375],
            [102.85966796874999, 50.333251953125],
            [103.03945312500002, 50.300634765625],
            [103.16171875000003, 50.29072265625],
            [103.23378906250002, 50.2642578125],
            [103.30439453125001, 50.20029296875],
            [103.42119140624999, 50.187060546874996],
            [103.49628906250001, 50.164941406249994],
            [103.63291015625003, 50.138574218749994],
            [103.72324218750003, 50.153857421874996],
            [103.80263671875002, 50.176074218749996],
            [103.85615234375001, 50.171826171875],
            [103.95849609375, 50.157275390624996],
            [104.07871093750003, 50.154248046875],
            [104.1796875, 50.16943359375],
            [104.25996093750001, 50.214453125],
            [104.35390625000002, 50.275292968749994],
            [104.46630859375, 50.30615234375],
            [104.59638671875001, 50.3171875],
            [104.68535156249999, 50.341845703124996],
            [104.97695312500002, 50.38291015625],
            [105.0947265625, 50.389941406249996],
            [105.18593750000002, 50.429589843749994],
            [105.26669921875003, 50.460498046874996],
            [105.38359374999999, 50.47373046875],
            [105.54160156250003, 50.441259765625],
            [105.69257812500001, 50.41416015625],
            [105.87519531250001, 50.40537109375],
            [105.99648437500002, 50.367919921875],
            [106.08251953125, 50.332568359374996],
            [106.21787109375003, 50.304589843749994],
            [106.36845703124999, 50.317578125],
            [106.57441406250001, 50.32880859375],
            [106.71113281250001, 50.31259765625],
            [106.85371093750001, 50.248291015625],
            [106.94130859375002, 50.196679687499994],
            [107.04023437500001, 50.086474609374996],
            [107.14306640625, 50.033007812499996],
            [107.23330078125002, 49.989404296874994],
            [107.34707031250002, 49.986669921875],
            [107.63095703125003, 49.98310546875],
            [107.78681640625001, 49.960009765624996],
            [107.91660156250003, 49.947802734374996],
            [107.94785156250003, 49.924707031249994],
            [107.93486328124999, 49.849023437499994],
            [107.93876953124999, 49.74072265625],
            [107.93671875000001, 49.691015625],
            [107.96542968750003, 49.653515625],
            [108.00957031249999, 49.646875],
            [108.03378906250003, 49.593994140625],
            [108.09804687500002, 49.562646484374994],
            [108.21308593750001, 49.524804687499994],
            [108.40693359375001, 49.396386718749994],
            [108.5224609375, 49.34150390625],
            [108.61367187500002, 49.322802734374996],
            [108.73300781250003, 49.33564453125],
            [108.919921875, 49.335351562499994],
            [109.23671875000002, 49.334912109375],
            [109.45371093750003, 49.296337890625],
            [109.52871093750002, 49.269873046875],
            [109.75039062500002, 49.239306640624996],
            [109.99453125000002, 49.205615234374996],
            [110.19990234375001, 49.17041015625],
            [110.32138671875003, 49.215869140624996],
            [110.42783203125003, 49.219970703125],
            [110.52958984374999, 49.187060546874996],
            [110.63105468750001, 49.137597656249994],
            [110.70976562499999, 49.14296875],
            [110.82792968749999, 49.166162109374994],
            [111.20419921875003, 49.304296875],
            [111.33662109375001, 49.355859375],
            [111.42929687500003, 49.342626953125],
            [111.51191406250001, 49.3609375],
            [111.57480468750003, 49.376416015625],
            [111.73554687500001, 49.397753906249996],
            [111.83339843750002, 49.403613281249996],
            [111.93447265625002, 49.416015625],
            [112.07968750000003, 49.42421875],
            [112.37519531250001, 49.514599609375],
            [112.49492187499999, 49.53232421875],
            [112.69736328125003, 49.507275390625],
            [112.80644531249999, 49.523583984374994],
            [112.91484374999999, 49.569238281249994],
            [113.05556640625002, 49.616259765624996],
            [113.09208984374999, 49.692529296874994],
            [113.16416015625003, 49.797167968749996],
            [113.31904296875001, 49.874316406249996],
            [113.44550781250001, 49.9416015625],
            [113.57421875, 50.00703125],
            [113.732421875, 50.0615234375],
            [113.88115234374999, 50.101123046874996],
            [114.07070312500002, 50.204736328124994],
            [114.22177734375003, 50.257275390625],
            [114.29707031250001, 50.2744140625],
            [114.38632812500003, 50.25546875],
            [114.55400390624999, 50.241455078125],
            [114.67490234375003, 50.245703125],
            [114.7431640625, 50.233691406249996],
            [114.87958984375001, 50.183056640625],
            [115.00332031250002, 50.138574218749994],
            [115.09804687500002, 50.059423828125],
            [115.27451171875003, 49.948876953124994],
            [115.36503906249999, 49.911767578124994],
            [115.42919921875, 49.896484375],
            [115.58798828125003, 49.886035156249996],
            [115.7177734375, 49.880615234375],
            [115.79521484374999, 49.905908203124994],
            [115.92597656250001, 49.9521484375],
            [116.13457031249999, 50.010791015624996],
            [116.216796875, 50.00927734375],
            [116.35117187500003, 49.978076171874996],
            [116.55117187500002, 49.9203125],
            [116.63154296875001, 49.87705078125],
            [116.68330078125001, 49.823779296874996],
            [116.58974609375002, 49.684814453125],
            [116.40214843749999, 49.406201171875],
            [116.24335937500001, 49.170361328125],
            [116.15966796875, 49.037451171875],
            [116.09824218750003, 48.9361328125],
            [116.034375, 48.8400390625],
            [116.02548828125003, 48.782275390624996],
            [115.95380859375001, 48.689355468749994],
            [115.82050781250001, 48.57724609375],
            [115.79169921875001, 48.455712890624994],
            [115.79658203125001, 48.346337890624994],
            [115.78554687500002, 48.2482421875],
            [115.63945312499999, 48.18623046875],
            [115.52509765625001, 48.130859375],
            [115.5576171875, 47.94501953125],
            [115.61640625000001, 47.874804687499996],
            [115.71171874999999, 47.79892578125],
            [115.81171875000001, 47.738232421875],
            [115.89824218749999, 47.686914062499994],
            [115.99384765625001, 47.711328125],
            [116.07480468750003, 47.78955078125],
            [116.23115234375001, 47.858203125],
            [116.31718749999999, 47.85986328125],
            [116.37822265624999, 47.844042968749996],
            [116.51347656249999, 47.83955078125],
            [116.65195312500003, 47.864501953125],
            [116.76054687499999, 47.869775390624994],
            [116.90117187499999, 47.853076171874996],
            [116.95166015625, 47.836572265624994],
            [117.06972656250002, 47.806396484375],
            [117.19707031249999, 47.740283203124996],
            [117.28593749999999, 47.666357421875],
            [117.35078125000001, 47.652197265625],
            [117.38398437500001, 47.675732421875],
            [117.455078125, 47.741357421874994],
            [117.55537109375001, 47.8046875],
            [117.67666015625002, 47.90830078125],
            [117.76835937499999, 47.987890625],
            [117.84042968750003, 47.999853515625],
            [117.97919921875001, 47.999609375],
            [118.04189453125002, 48.018945312499994],
            [118.14707031250003, 48.02890625],
            [118.23964843750002, 47.99951171875],
            [118.49843750000002, 47.983984375],
            [118.56777343750002, 47.943261718749994],
            [118.69052734375003, 47.822265625],
            [118.75996093750001, 47.757617187499996],
            [118.88027343750002, 47.72509765625],
            [118.953125, 47.702929687499996],
            [119.017578125, 47.685351562499996],
            [119.08193359375002, 47.654150390625],
            [119.09726562500003, 47.616259765624996],
            [119.12294921875002, 47.558496093749994],
            [119.16240234374999, 47.525195312499996],
            [119.23525390625002, 47.492578125],
            [119.29082031249999, 47.47265625],
            [119.30859375, 47.430712890624996],
            [119.32597656249999, 47.41015625],
            [119.37666015625001, 47.380859375],
            [119.52695312500003, 47.255908203124996],
            [119.60019531250003, 47.222460937499996],
            [119.71113281250001, 47.15],
            [119.75722656250002, 47.0900390625],
            [119.75986328125003, 47.027001953124994],
            [119.78847656250002, 46.978808593749996],
            [119.86269531250002, 46.906591796875],
            [119.89785156250002, 46.8578125],
            [119.88417968750002, 46.791455078125],
            [119.89589843750002, 46.732861328125],
            [119.8671875, 46.672167968749996],
            [119.74746093750002, 46.627197265625],
            [119.70664062500003, 46.606005859374996],
            [119.62021484375003, 46.603955078125],
            [119.47402343750002, 46.62666015625],
            [119.33183593749999, 46.613818359374996],
            [119.162109375, 46.638671875],
            [119.02851562500001, 46.6921875],
            [118.95712890625003, 46.73486328125],
            [118.84394531250001, 46.760205078125],
            [118.79033203124999, 46.7470703125],
            [118.72294921874999, 46.69189453125],
            [118.64873046874999, 46.70166015625],
            [118.58046875000002, 46.69189453125],
            [118.40439453125003, 46.703173828124996],
            [118.30869140625003, 46.717041015625],
            [118.15683593750003, 46.678564453125],
            [118.0712890625, 46.6666015625],
            [117.91044921874999, 46.6193359375],
            [117.8134765625, 46.5376953125],
            [117.7412109375, 46.5181640625],
            [117.67109375000001, 46.5220703125],
            [117.62050781250002, 46.552001953125],
            [117.546875, 46.58828125],
            [117.43808593750003, 46.586230468749996],
            [117.40556640624999, 46.5708984375],
            [117.39218750000003, 46.53759765625],
            [117.35634765625002, 46.436669921874994],
            [117.35693359375, 46.39130859375],
            [117.33339843750002, 46.36201171875],
            [117.26904296875, 46.35224609375],
            [117.15595703125001, 46.355078125],
            [116.97880859374999, 46.361767578125],
            [116.85908203125001, 46.387939453125],
            [116.78701171875002, 46.37666015625],
            [116.68886718750002, 46.32197265625],
            [116.61933593750001, 46.3130859375],
            [116.56259765625003, 46.289794921875],
            [116.51669921875003, 46.20908203125],
            [116.44482421875, 46.1587890625],
            [116.35761718750001, 46.096582031249994],
            [116.26455078125002, 45.963037109374994],
            [116.21298828125003, 45.8869140625],
            [116.22910156250003, 45.845751953124996],
            [116.240625, 45.79599609375],
            [116.19765625000002, 45.73935546875],
            [116.10986328125, 45.68671875],
            [116.03955078125, 45.676953125],
            [115.93417968750003, 45.626171875],
            [115.78916015625003, 45.534814453124994],
            [115.68105468750002, 45.458251953125],
            [115.53945312500002, 45.439501953124996],
            [115.439453125, 45.419970703124996],
            [115.21748046875001, 45.39619140625],
            [115.16259765625, 45.390234375],
            [114.91923828124999, 45.378271484375],
            [114.73876953125, 45.41962890625],
            [114.64433593749999, 45.41328125],
            [114.56015625000003, 45.389990234375],
            [114.51718750000003, 45.364599609375],
            [114.50224609374999, 45.316308593749994],
            [114.4873046875, 45.271728515625],
            [114.41914062500001, 45.202587890625],
            [114.28105468749999, 45.110888671874996],
            [114.16738281250002, 45.049853515624996],
            [114.08027343750001, 44.971142578125],
            [114.0302734375, 44.942578125],
            [113.93085937500001, 44.9123046875],
            [113.87705078125003, 44.89619140625],
            [113.75214843750001, 44.825927734375],
            [113.65263671874999, 44.763476562499996],
            [113.58701171875003, 44.745703125],
            [113.50791015625003, 44.762353515624994],
            [113.45566406250003, 44.767431640625],
            [113.30097656250001, 44.791650390624994],
            [113.19609374999999, 44.794824218749994],
            [113.04941406250003, 44.810351562499996],
            [112.70673828125001, 44.883447265624994],
            [112.59677734375003, 44.91767578125],
            [112.49931640624999, 45.0109375],
            [112.41132812500001, 45.058203125],
            [112.29208984375003, 45.063037109374996],
            [112.11289062500003, 45.062939453125],
            [112.03261718750002, 45.081640625],
            [111.89804687500003, 45.0640625],
            [111.75107421875003, 44.96953125],
            [111.68144531249999, 44.899169921875],
            [111.62128906250001, 44.8271484375],
            [111.54746093750003, 44.672900390624996],
            [111.51474609375003, 44.56982421875],
            [111.48945312500001, 44.511572265625],
            [111.41093749999999, 44.419189453125],
            [111.40224609375002, 44.36728515625],
            [111.42958984375002, 44.32236328125],
            [111.48623046875002, 44.271630859374994],
            [111.51972656250001, 44.19189453125],
            [111.60263671875003, 44.10712890625],
            [111.68378906250001, 44.04111328125],
            [111.8369140625, 43.93466796875],
            [111.88027343750002, 43.87890625],
            [111.93173828125003, 43.81494140625],
            [111.94287109375, 43.75244140625],
            [111.93320312500003, 43.71142578125],
            [111.878125, 43.68017578125],
            [111.77109375000003, 43.664599609374996],
            [111.7197265625, 43.621142578124996],
            [111.64082031250001, 43.56318359375],
            [111.54736328125, 43.4962890625],
            [111.50351562500003, 43.4927734375],
            [111.45107421875002, 43.474902343749996],
            [111.18681640624999, 43.391992187499994],
            [111.08652343750003, 43.36875],
            [111.00722656250002, 43.34140625],
            [110.91328125000001, 43.256884765624996],
            [110.83955078125001, 43.194091796875],
            [110.74853515625, 43.110791015625],
            [110.70859375000003, 43.073876953124994],
            [110.62753906250003, 42.990527343749996],
            [110.52089843750002, 42.895263671875],
            [110.46171874999999, 42.844140625],
            [110.42958984375002, 42.81357421875],
            [110.400390625, 42.773681640625],
            [110.28886718749999, 42.742724609374996],
            [110.196875, 42.710009765624996],
            [110.05800781250002, 42.660595703125],
            [109.85878906250002, 42.60625],
            [109.69804687499999, 42.55380859375],
            [109.59550781249999, 42.510546875],
            [109.44316406249999, 42.455957031249994],
            [109.33984375, 42.438378906249994],
            [109.13164062499999, 42.440576171874994],
            [108.87451171875, 42.42646484375],
            [108.68730468749999, 42.41611328125],
            [108.54648437500003, 42.429296875],
            [108.333984375, 42.436767578125],
            [108.17119140624999, 42.447314453124996],
            [108.06230468749999, 42.427197265625],
            [107.80595703124999, 42.405859375],
            [107.74873046875001, 42.4009765625],
            [107.29238281250002, 42.349267578124994],
            [107.09072265625002, 42.321533203125],
            [106.90605468749999, 42.308886718749996],
            [106.77001953125, 42.288720703124994],
            [106.69316406249999, 42.263574218749994],
            [106.5791015625, 42.22734375],
            [106.51875, 42.211572265624994],
            [106.31718749999999, 42.140576171875],
            [105.86757812500002, 41.993994140625],
            [105.56640625, 41.87509765625],
            [105.51708984375, 41.854736328125],
            [105.31435546875002, 41.770898437499994],
            [105.19707031249999, 41.738037109375],
            [105.11542968750001, 41.66328125],
            [105.05058593749999, 41.61591796875],
            [104.98203125000003, 41.595507812499996],
            [104.8603515625, 41.64375],
            [104.77363281250001, 41.641162109374996],
            [104.49824218750001, 41.65869140625],
            [104.49824218750001, 41.877001953124996],
            [104.30517578125, 41.846142578125],
            [103.99726562500001, 41.79697265625],
            [103.71113281250001, 41.751318359375],
            [103.44970703125, 41.855859375],
            [103.24785156249999, 41.936572265624996],
            [103.07285156250003, 42.00595703125],
            [102.80683593750001, 42.052001953125],
            [102.5751953125, 42.092089843749996],
            [102.15664062500002, 42.158105468749994],
            [101.97294921874999, 42.215869140624996],
            [101.8798828125, 42.292333984375],
            [101.7138671875, 42.4658203125],
            [101.65996093749999, 42.500048828124996],
            [101.5791015625, 42.52353515625],
            [101.49531250000001, 42.53876953125],
            [101.31376953124999, 42.537890625],
            [101.09199218750001, 42.551318359374996],
            [100.77255859375003, 42.587792968749994],
            [100.51904296875, 42.616796875],
            [100.08632812500002, 42.670751953125],
            [99.98378906250002, 42.67734375],
            [99.75742187500003, 42.629443359374996],
            [99.46787109375003, 42.568212890625],
            [98.946875, 42.6162109375],
            [98.71630859375, 42.638720703124996],
            [98.24824218750001, 42.684521484375],
            [97.71894531250001, 42.736279296875],
            [97.20566406250003, 42.789794921875],
            [96.8330078125, 42.76025390625],
            [96.62529296874999, 42.74384765625],
            [96.38544921875001, 42.720361328124994],
            [96.35234374999999, 42.74677734375],
            [96.34248046875001, 42.84931640625],
            [96.29951171875001, 42.9287109375],
            [96.16845703125, 43.014501953125],
            [96.08027343750001, 43.096142578125],
            [95.9125, 43.206494140625],
            [95.85957031250001, 43.2759765625],
            [95.84199218750001, 43.383691406249994],
            [95.68730468749999, 43.6640625],
            [95.59121093750002, 43.85361328125],
            [95.56718749999999, 43.892236328124994],
            [95.52558593750001, 43.953955078125],
            [95.47128906250003, 43.986181640625],
            [95.3564453125, 44.00595703125],
            [95.32558593750002, 44.039355468749996],
            [95.32558593750002, 44.104882812499994],
            [95.34365234375002, 44.19541015625],
            [95.36679687500003, 44.261523437499996],
            [95.35029296875001, 44.278076171875],
            [95.0498046875, 44.259423828124994],
            [94.86601562499999, 44.3033203125],
            [94.71201171875003, 44.350830078125],
            [94.49433593750001, 44.472509765625],
            [94.36474609375, 44.519482421875],
            [94.19931640625003, 44.645166015624994],
            [93.95791015625002, 44.674951171874994],
            [93.8681640625, 44.72421875],
            [93.75527343750002, 44.831933593749994],
            [93.65644531250001, 44.9009765625],
            [93.51621093750003, 44.944482421874994],
            [93.29433593750002, 44.983154296875],
            [92.916015625, 45.020166015624994],
            [92.78789062499999, 45.0357421875],
            [92.57890624999999, 45.010986328125],
            [92.423828125, 45.008935546874994],
            [92.17265624999999, 45.03525390625],
            [92.02978515625, 45.068505859374994],
            [91.85283203124999, 45.0693359375],
            [91.73779296875, 45.0689453125],
            [91.584375, 45.076513671875],
            [91.51005859374999, 45.0982421875],
            [91.44101562500003, 45.124755859375],
            [91.31210937500003, 45.118115234375],
            [91.22177734375003, 45.14453125],
            [91.1376953125, 45.1939453125],
            [91.05, 45.217431640624994],
            [90.95361328125, 45.21591796875],
            [90.91396484375002, 45.1939453125],
            [90.87724609374999, 45.19609375],
            [90.85322265625001, 45.262890625],
            [90.76318359375, 45.370654296874996],
            [90.74960937500003, 45.4189453125],
            [90.69443359375003, 45.474658203124996],
            [90.66181640625001, 45.525244140625],
            [90.67070312499999, 45.595166015625],
            [90.70966796875001, 45.730810546875],
            [90.7958984375, 45.853515625],
            [90.85244140625002, 45.885400390624994],
            [90.88710937500002, 45.921630859375],
            [90.95976562499999, 45.98505859375],
            [91.00175781249999, 46.035791015624994],
            [90.99677734375001, 46.10498046875],
            [90.94755859374999, 46.177294921874996],
            [90.91152343750002, 46.270654296874994],
            [90.91826171874999, 46.324267578124996],
            [90.97148437499999, 46.387988281249996],
            [91.03388671875001, 46.52900390625],
            [91.02890625000003, 46.566064453124994],
            [91.00429687500002, 46.595751953124996],
            [90.99785156249999, 46.661083984375],
            [90.98574218750002, 46.7490234375],
            [90.91054687500002, 46.883251953125],
            [90.86992187499999, 46.954492187499994],
            [90.79902343750001, 46.98515625],
            [90.71552734375001, 47.003857421875],
            [90.64335937499999, 47.10029296875],
            [90.55292968750001, 47.214013671874994],
            [90.49619140625003, 47.28515625],
            [90.47646484375002, 47.32880859375],
            [90.46748046875001, 47.408154296875],
            [90.42519531250002, 47.5041015625],
            [90.38066406249999, 47.556640625],
            [90.34746093749999, 47.596972656249996],
            [90.33066406250003, 47.655175781249994],
            [90.31328124999999, 47.676171875],
            [90.19101562500003, 47.702099609375],
            [90.10322265625001, 47.745410156249996],
            [90.06660156250001, 47.803564453125],
            [90.05390625000001, 47.850488281249994],
            [90.02792968750003, 47.877685546875],
            [89.95869140625001, 47.886328125],
            [89.91044921874999, 47.8443359375],
            [89.83134765624999, 47.823291015624996],
            [89.778125, 47.827001953125],
            [89.7255859375, 47.852490234375],
            [89.69316406249999, 47.879150390625],
            [89.63847656249999, 47.909082031249994],
            [89.56093750000002, 48.003955078124996],
            [89.47919921875001, 48.029052734375],
            [89.32988281249999, 48.024853515625],
            [89.1962890625, 47.980908203125],
            [89.115625, 47.987695312499994],
            [89.04765624999999, 48.0025390625],
            [88.97109375000002, 48.049951171874994],
            [88.91777343749999, 48.089013671874994],
            [88.83828125000002, 48.101708984374994],
            [88.68183593750001, 48.170556640624994],
            [88.57597656249999, 48.220166015625],
            [88.56679687500002, 48.317431640624996],
            [88.51708984375, 48.38447265625],
            [88.41396484375002, 48.40341796875],
            [88.30996093750002, 48.472070312499994],
            [88.158203125, 48.509082031249996],
            [88.06259765625003, 48.537841796875],
            [87.97968750000001, 48.555126953125],
            [87.96738281250003, 48.5810546875],
            [87.97226562500003, 48.603320312499996],
            [88.01064453125002, 48.640429687499996],
            [88.05019531250002, 48.675048828125],
            [88.06005859375, 48.707177734374994],
            [88.02792968750003, 48.735595703125],
            [87.94218749999999, 48.765283203124994],
            [87.83183593749999, 48.791650390624994],
            [87.80917968750003, 48.835742187499996],
            [87.7431640625, 48.881640625],
            [87.75468749999999, 48.9185546875],
            [87.80683593750001, 48.9455078125],
            [87.85986328125, 48.96552734375],
            [87.87216796875003, 49.000146484374994],
            [87.83466796875001, 49.03193359375],
            [87.81630859375002, 49.0802734375],
            [87.8251953125, 49.11630859375],
            [87.81425781249999, 49.1623046875],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 5,
        sovereignt: "Georgia",
        sov_a3: "GEO",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Georgia",
        adm0_a3: "GEO",
        geou_dif: 0,
        geounit: "Georgia",
        gu_a3: "GEO",
        su_dif: 0,
        subunit: "Georgia",
        su_a3: "GEO",
        brk_diff: 0,
        name: "Georgia",
        name_long: "Georgia",
        brk_a3: "GEO",
        brk_name: "Georgia",
        brk_group: null,
        abbrev: "Geo.",
        postal: "GE",
        formal_en: "Georgia",
        formal_fr: null,
        name_ciawf: "Georgia",
        note_adm0: null,
        note_brk: null,
        name_sort: "Georgia",
        name_alt: null,
        mapcolor7: 5,
        mapcolor8: 1,
        mapcolor9: 3,
        mapcolor13: 2,
        pop_est: 3720382,
        pop_rank: 12,
        pop_year: 2019,
        gdp_md: 17477,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        fips_10: "GG",
        iso_a2: "GE",
        iso_a2_eh: "GE",
        iso_a3: "GEO",
        iso_a3_eh: "GEO",
        iso_n3: "268",
        iso_n3_eh: "268",
        un_a3: "268",
        wb_a2: "GE",
        wb_a3: "GEO",
        woe_id: 23424823,
        woe_id_eh: 23424823,
        woe_note: "Exact WOE match as country",
        adm0_iso: "GEO",
        adm0_diff: null,
        adm0_tlc: "GEO",
        adm0_a3_us: "GEO",
        adm0_a3_fr: "GEO",
        adm0_a3_ru: "GEO",
        adm0_a3_es: "GEO",
        adm0_a3_cn: "GEO",
        adm0_a3_tw: "GEO",
        adm0_a3_in: "GEO",
        adm0_a3_np: "GEO",
        adm0_a3_pk: "GEO",
        adm0_a3_de: "GEO",
        adm0_a3_gb: "GEO",
        adm0_a3_br: "GEO",
        adm0_a3_il: "GEO",
        adm0_a3_ps: "GEO",
        adm0_a3_sa: "GEO",
        adm0_a3_eg: "GEO",
        adm0_a3_ma: "GEO",
        adm0_a3_pt: "GEO",
        adm0_a3_ar: "GEO",
        adm0_a3_jp: "GEO",
        adm0_a3_ko: "GEO",
        adm0_a3_vn: "GEO",
        adm0_a3_tr: "GEO",
        adm0_a3_id: "GEO",
        adm0_a3_pl: "GEO",
        adm0_a3_gr: "GEO",
        adm0_a3_it: "GEO",
        adm0_a3_nl: "GEO",
        adm0_a3_se: "GEO",
        adm0_a3_bd: "GEO",
        adm0_a3_ua: "GEO",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Western Asia",
        region_wb: "Europe & Central Asia",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: 43.735724,
        label_y: 41.870087,
        ne_id: 1159320779,
        wikidataid: "Q230",
        name_ar: "جورجيا",
        name_bn: "জর্জিয়া",
        name_de: "Georgien",
        name_en: "Georgia",
        name_es: "Georgia",
        name_fa: "گرجستان",
        name_fr: "Géorgie",
        name_el: "Γεωργία",
        name_he: "גאורגיה",
        name_hi: "जॉर्जिया",
        name_hu: "Grúzia",
        name_id: "Georgia",
        name_it: "Georgia",
        name_ja: "ジョージア",
        name_ko: "조지아",
        name_nl: "Georgië",
        name_pl: "Gruzja",
        name_pt: "Geórgia",
        name_ru: "Грузия",
        name_sv: "Georgien",
        name_tr: "Gürcistan",
        name_uk: "Грузія",
        name_ur: "جارجیا",
        name_vi: "Gruzia",
        name_zh: "格鲁吉亚",
        name_zht: "喬治亞",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "GEO.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [43.439453125, 41.10712890625],
            [43.44160156250001, 41.1259765625],
            [43.43339843750002, 41.155517578125],
            [43.40234375, 41.1765625],
            [43.358984375, 41.19013671875],
            [43.279296875, 41.185205078124994],
            [43.20546875000002, 41.199169921875],
            [43.15283203125, 41.23642578125],
            [43.14101562500002, 41.26484375],
            [43.17128906250002, 41.287939453125],
            [43.149023437500006, 41.30712890625],
            [43.05712890625, 41.352832031249996],
            [42.90673828125, 41.466845703124996],
            [42.82167968750002, 41.4923828125],
            [42.78789062500002, 41.563720703125],
            [42.75410156250001, 41.57890625],
            [42.68242187500002, 41.585742187499996],
            [42.60683593750002, 41.57880859375],
            [42.590429687500006, 41.570703125],
            [42.5673828125, 41.55927734375],
            [42.507910156250006, 41.470068359375],
            [42.46640625, 41.43984375],
            [42.364355468750006, 41.454003906249994],
            [42.27998046875001, 41.475],
            [42.21113281250001, 41.48671875],
            [42.077734375, 41.494091796875],
            [41.92578125, 41.495654296874996],
            [41.82353515625002, 41.432373046875],
            [41.779394531250006, 41.44052734375],
            [41.701757812500006, 41.471582031249994],
            [41.57656250000002, 41.497314453125],
            [41.51005859375002, 41.517480468749994],
            [41.701757812500006, 41.705419921875],
            [41.7587890625, 41.817138671875],
            [41.7607421875, 41.88486328125],
            [41.76298828125002, 41.970019531249996],
            [41.66328125000001, 42.146875],
            [41.577734375, 42.397851562499994],
            [41.48876953125, 42.659326171874994],
            [41.41943359375, 42.737646484375],
            [41.12871093750002, 42.828125],
            [41.061621093750006, 42.930859375],
            [40.83662109375001, 43.0634765625],
            [40.524023437500006, 43.121044921875],
            [40.46210937500001, 43.145703125],
            [40.190625, 43.312402343749994],
            [39.97832031250002, 43.419824218749994],
            [40.02373046875002, 43.48486328125],
            [40.084570312500006, 43.553125],
            [40.15019531250002, 43.569775390625],
            [40.34228515625, 43.542724609375],
            [40.51894531250002, 43.512011718749996],
            [40.648046875, 43.53388671875],
            [40.80166015625002, 43.479931640625],
            [40.941992187500006, 43.41806640625],
            [41.083105468750006, 43.374462890625],
            [41.35820312500002, 43.333398437499994],
            [41.46074218750002, 43.276318359375],
            [41.58056640625, 43.21923828125],
            [42.05, 43.19013671875],
            [42.08779296875002, 43.199121093749994],
            [42.12226562500001, 43.207324218749996],
            [42.27968750000002, 43.228076171874996],
            [42.419042968750006, 43.22421875],
            [42.566015625, 43.155126953125],
            [42.660253906250006, 43.159082031249994],
            [42.76064453125002, 43.169580078124994],
            [42.89003906250002, 43.132617187499996],
            [42.99160156250002, 43.09150390625],
            [43.00019531250001, 43.049658203125],
            [43.08916015625002, 42.9890625],
            [43.34794921875002, 42.8966796875],
            [43.55781250000001, 42.844482421875],
            [43.623046875, 42.80771484375],
            [43.78261718750002, 42.747021484375],
            [43.79873046875002, 42.727783203125],
            [43.79541015625, 42.702978515625],
            [43.74990234375002, 42.657519531249996],
            [43.738378906250006, 42.616992187499996],
            [43.759863281250006, 42.59384765625],
            [43.82597656250002, 42.571533203125],
            [43.95742187500002, 42.566552734374994],
            [44.00468750000002, 42.595605468749994],
            [44.10273437500001, 42.616357421874994],
            [44.19970703125, 42.653613281249996],
            [44.32949218750002, 42.703515625],
            [44.505859375, 42.7486328125],
            [44.57646484375002, 42.748486328125],
            [44.64433593750002, 42.734716796875],
            [44.69179687500002, 42.709619140624994],
            [44.77109375, 42.616796875],
            [44.85048828125002, 42.746826171875],
            [44.87099609375002, 42.756396484374996],
            [44.943359375, 42.730273437499996],
            [45.07158203125002, 42.694140625],
            [45.160253906250006, 42.675],
            [45.20820312500001, 42.648242187499996],
            [45.34375, 42.52978515625],
            [45.56289062500002, 42.5357421875],
            [45.65556640625002, 42.51767578125],
            [45.70527343750001, 42.498095703124996],
            [45.7275390625, 42.475048828125],
            [45.68837890625002, 42.357373046875],
            [45.63427734375, 42.234716796875],
            [45.63857421875002, 42.205078125],
            [45.7265625, 42.15888671875],
            [45.84599609375002, 42.1099609375],
            [45.91035156250001, 42.070703125],
            [45.95400390625002, 42.035400390625],
            [46.0484375, 42.008740234375],
            [46.159765625, 41.992041015625],
            [46.21269531250002, 41.989892578124994],
            [46.26777343750001, 41.960351562499994],
            [46.41152343750002, 41.904638671875],
            [46.42988281250001, 41.890966796875],
            [46.40546875000001, 41.855078125],
            [46.348242187500006, 41.790185546874994],
            [46.30253906250002, 41.757080078125],
            [46.25185546875002, 41.751757812499996],
            [46.20185546875001, 41.736865234374996],
            [46.18427734375001, 41.7021484375],
            [46.18212890625, 41.657080078125],
            [46.190527343750006, 41.624853515625],
            [46.20351562500002, 41.612597656249996],
            [46.25468750000002, 41.6021484375],
            [46.30546875000002, 41.507714843749994],
            [46.38496093750001, 41.459863281249994],
            [46.5087890625, 41.405566406249996],
            [46.61894531250002, 41.34375],
            [46.67255859375001, 41.28681640625],
            [46.66240234375002, 41.245507812499994],
            [46.62636718750002, 41.15966796875],
            [46.534375, 41.08857421875],
            [46.45791015625002, 41.070214843749994],
            [46.43095703125002, 41.077050781249994],
            [46.38076171875002, 41.09931640625],
            [46.27998046875001, 41.154443359374994],
            [46.17070312500002, 41.1978515625],
            [46.086523437500006, 41.183837890625],
            [46.03125, 41.167285156249996],
            [45.921972656250006, 41.18671875],
            [45.79277343750002, 41.224414062499996],
            [45.72548828125002, 41.261621093749994],
            [45.69570312500002, 41.289013671875],
            [45.715625, 41.337646484375],
            [45.42226562500002, 41.42529296875],
            [45.28095703125001, 41.449560546875],
            [45.21718750000002, 41.423193359375],
            [45.00136718750002, 41.290966796875],
            [44.97587890625002, 41.277490234374994],
            [44.81132812500002, 41.259375],
            [44.81093750000002, 41.248583984374996],
            [44.84853515625002, 41.220166015625],
            [44.84140625, 41.211376953125],
            [44.56484375000002, 41.208203125],
            [44.47304687500002, 41.191015625],
            [44.22734375000002, 41.213330078125],
            [44.146484375, 41.203369140625],
            [44.077246093750006, 41.182519531249994],
            [43.9091796875, 41.158984375],
            [43.79316406250001, 41.131103515625],
            [43.64501953125, 41.116650390625],
            [43.49199218750002, 41.115527343749996],
            [43.439453125, 41.10712890625],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 5,
        sovereignt: "Azerbaijan",
        sov_a3: "AZE",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Azerbaijan",
        adm0_a3: "AZE",
        geou_dif: 0,
        geounit: "Azerbaijan",
        gu_a3: "AZE",
        su_dif: 0,
        subunit: "Azerbaijan",
        su_a3: "AZE",
        brk_diff: 0,
        name: "Azerbaijan",
        name_long: "Azerbaijan",
        brk_a3: "AZE",
        brk_name: "Azerbaijan",
        brk_group: null,
        abbrev: "Aze.",
        postal: "AZ",
        formal_en: "Republic of Azerbaijan",
        formal_fr: null,
        name_ciawf: "Azerbaijan",
        note_adm0: null,
        note_brk: null,
        name_sort: "Azerbaijan",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 6,
        mapcolor9: 5,
        mapcolor13: 8,
        pop_est: 10023318,
        pop_rank: 14,
        pop_year: 2019,
        gdp_md: 48047,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        fips_10: "AJ",
        iso_a2: "AZ",
        iso_a2_eh: "AZ",
        iso_a3: "AZE",
        iso_a3_eh: "AZE",
        iso_n3: "031",
        iso_n3_eh: "031",
        un_a3: "031",
        wb_a2: "AZ",
        wb_a3: "AZE",
        woe_id: 23424741,
        woe_id_eh: 23424741,
        woe_note: "Exact WOE match as country",
        adm0_iso: "AZE",
        adm0_diff: null,
        adm0_tlc: "AZE",
        adm0_a3_us: "AZE",
        adm0_a3_fr: "AZE",
        adm0_a3_ru: "AZE",
        adm0_a3_es: "AZE",
        adm0_a3_cn: "AZE",
        adm0_a3_tw: "AZE",
        adm0_a3_in: "AZE",
        adm0_a3_np: "AZE",
        adm0_a3_pk: "AZE",
        adm0_a3_de: "AZE",
        adm0_a3_gb: "AZE",
        adm0_a3_br: "AZE",
        adm0_a3_il: "AZE",
        adm0_a3_ps: "AZE",
        adm0_a3_sa: "AZE",
        adm0_a3_eg: "AZE",
        adm0_a3_ma: "AZE",
        adm0_a3_pt: "AZE",
        adm0_a3_ar: "AZE",
        adm0_a3_jp: "AZE",
        adm0_a3_ko: "AZE",
        adm0_a3_vn: "AZE",
        adm0_a3_tr: "AZE",
        adm0_a3_id: "AZE",
        adm0_a3_pl: "AZE",
        adm0_a3_gr: "AZE",
        adm0_a3_it: "AZE",
        adm0_a3_nl: "AZE",
        adm0_a3_se: "AZE",
        adm0_a3_bd: "AZE",
        adm0_a3_ua: "AZE",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Western Asia",
        region_wb: "Europe & Central Asia",
        name_len: 10,
        long_len: 10,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: 47.210994,
        label_y: 40.402387,
        ne_id: 1159320381,
        wikidataid: "Q227",
        name_ar: "أذربيجان",
        name_bn: "আজারবাইজান",
        name_de: "Aserbaidschan",
        name_en: "Azerbaijan",
        name_es: "Azerbaiyán",
        name_fa: "جمهوری آذربایجان",
        name_fr: "Azerbaïdjan",
        name_el: "Αζερμπαϊτζάν",
        name_he: "אזרבייג'ן",
        name_hi: "अज़रबैजान",
        name_hu: "Azerbajdzsán",
        name_id: "Azerbaijan",
        name_it: "Azerbaigian",
        name_ja: "アゼルバイジャン",
        name_ko: "아제르바이잔",
        name_nl: "Azerbeidzjan",
        name_pl: "Azerbejdżan",
        name_pt: "Azerbaijão",
        name_ru: "Азербайджан",
        name_sv: "Azerbajdzjan",
        name_tr: "Azerbaycan",
        name_uk: "Азербайджан",
        name_ur: "آذربائیجان",
        name_vi: "Azerbaijan",
        name_zh: "阿塞拜疆",
        name_zht: "亞塞拜然",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "AZE.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [44.81718750000002, 39.650439453124996],
              [44.78339843750001, 39.68466796875],
              [44.76826171875001, 39.703515625],
              [44.8671875, 39.719140625],
              [45.03164062500002, 39.76513671875],
              [45.07646484375002, 39.742822265624994],
              [45.124609375, 39.696337890624996],
              [45.14863281250001, 39.656591796875],
              [45.15283203125, 39.582666015624994],
              [45.17255859375001, 39.570605468749996],
              [45.252539062500006, 39.595458984375],
              [45.28828125000001, 39.565576171874994],
              [45.34990234375002, 39.5298828125],
              [45.45683593750002, 39.494482421875],
              [45.61074218750002, 39.5498046875],
              [45.68740234375002, 39.5640625],
              [45.75048828125, 39.562939453125],
              [45.78447265625002, 39.54560546875],
              [45.796484375, 39.488134765625],
              [45.7841796875, 39.417236328125],
              [45.76630859375001, 39.378466796874996],
              [45.79863281250002, 39.3501953125],
              [45.925, 39.28193359375],
              [45.97744140625002, 39.243896484375],
              [45.95185546875001, 39.178125],
              [46.0458984375, 39.017529296875],
              [46.07744140625002, 38.954882812499996],
              [46.11445312500001, 38.877783203125],
              [45.921875, 38.90791015625],
              [45.575, 38.972802734374994],
              [45.47968750000001, 39.00625],
              [45.389257812500006, 39.0958984375],
              [45.335546875, 39.13916015625],
              [45.255957031250006, 39.194677734375],
              [45.190625, 39.215625],
              [45.141210937500006, 39.254296875],
              [45.11308593750002, 39.311572265624996],
              [45.07167968750002, 39.362890625],
              [45.00019531250001, 39.42353515625],
              [44.83818359375002, 39.6291015625],
              [44.81718750000002, 39.650439453124996],
            ],
          ],
          [
            [
              [48.86875, 38.435498046875],
              [48.84033203125, 38.437255859375],
              [48.63554687500002, 38.398730468749996],
              [48.59267578125002, 38.411083984375],
              [48.41738281250002, 38.586230468749996],
              [48.38125, 38.605615234374994],
              [48.30556640625002, 38.6134765625],
              [48.261328125, 38.64228515625],
              [48.225195312500006, 38.689208984375],
              [48.2046875, 38.72412109375],
              [48.02324218750002, 38.81904296875],
              [47.99648437500002, 38.853759765625],
              [47.99267578125, 38.88427734375],
              [48.01933593750002, 38.91181640625],
              [48.05009765625002, 38.935009765625],
              [48.13857421875002, 38.958642578124994],
              [48.24199218750002, 38.978955078125],
              [48.27509765625001, 38.993603515625],
              [48.292089843750006, 39.018847656249996],
              [48.291015625, 39.05927734375],
              [48.27412109375001, 39.09912109375],
              [48.12548828125, 39.171630859375],
              [48.10917968750002, 39.20283203125],
              [48.10439453125002, 39.241113281249994],
              [48.112890625, 39.281103515625],
              [48.13603515625002, 39.312353515625],
              [48.25722656250002, 39.35498046875],
              [48.32216796875002, 39.399072265624994],
              [48.28173828125, 39.44833984375],
              [48.15107421875001, 39.560546875],
              [47.99589843750002, 39.683935546875],
              [47.89228515625001, 39.68505859375],
              [47.77285156250002, 39.648583984374994],
              [47.58183593750002, 39.543359375],
              [47.476171875, 39.498339843749996],
              [47.338476562500006, 39.423876953124996],
              [47.18837890625002, 39.340966796874994],
              [47.0654296875, 39.252880859375],
              [46.988867187500006, 39.18017578125],
              [46.8525390625, 39.1484375],
              [46.783203125, 39.08740234375],
              [46.554785156250006, 38.90439453125],
              [46.490625, 38.906689453125],
              [46.48671875000002, 38.997460937499994],
              [46.48984375, 39.06943359375],
              [46.47539062500002, 39.110888671874996],
              [46.401464843750006, 39.16767578125],
              [46.40029296875002, 39.1921875],
              [46.42031250000002, 39.207373046875],
              [46.477148437500006, 39.198193359375],
              [46.55, 39.201416015625],
              [46.58476562500002, 39.223681640624996],
              [46.50664062500002, 39.29853515625],
              [46.43730468750002, 39.348535156249994],
              [46.37841796875, 39.382275390625],
              [46.365234375, 39.402490234374994],
              [46.36513671875002, 39.416796875],
              [46.37763671875001, 39.433886718749996],
              [46.478125, 39.47509765625],
              [46.48808593750002, 39.512841796874994],
              [46.4814453125, 39.55517578125],
              [46.32167968750002, 39.617431640625],
              [46.20205078125002, 39.594482421875],
              [46.094824218750006, 39.664453125],
              [46.02587890625, 39.718554687499996],
              [45.93994140625, 39.7765625],
              [45.86318359375002, 39.808349609375],
              [45.789648437500006, 39.881103515625],
              [45.66181640625001, 39.956201171874994],
              [45.57978515625001, 39.9775390625],
              [45.58095703125002, 39.989013671875],
              [45.59599609375002, 40.002832031249994],
              [45.63017578125002, 40.014208984374996],
              [45.85810546875001, 40.011279296874996],
              [45.88593750000001, 40.024853515625],
              [45.90009765625001, 40.057080078125],
              [45.93125, 40.1046875],
              [45.96757812500002, 40.1748046875],
              [45.96464843750002, 40.233789062499994],
              [45.73574218750002, 40.3291015625],
              [45.56953125000001, 40.416845703125],
              [45.45439453125002, 40.532373046874994],
              [45.37617187500001, 40.638085937499994],
              [45.37890625, 40.673583984375],
              [45.4013671875, 40.70712890625],
              [45.57939453125002, 40.804492187499996],
              [45.59140625, 40.829736328124994],
              [45.5875, 40.846923828125],
              [45.524023437500006, 40.896728515625],
              [45.44423828125002, 40.947998046875],
              [45.41914062500001, 40.985693359375],
              [45.36894531250002, 41.0048828125],
              [45.2734375, 41.00625],
              [45.106054687500006, 41.0693359375],
              [45.07050781250001, 41.075585937499994],
              [45.062597656250006, 41.088134765625],
              [45.07070312500002, 41.100830078125],
              [45.19023437500002, 41.126367187499994],
              [45.188574218750006, 41.147412109375],
              [45.15234375, 41.175146484375],
              [45.08476562500002, 41.195458984374994],
              [45.02294921875, 41.245703125],
              [45.00136718750002, 41.290966796875],
              [45.21718750000002, 41.423193359375],
              [45.28095703125001, 41.449560546875],
              [45.42226562500002, 41.42529296875],
              [45.715625, 41.337646484375],
              [45.69570312500002, 41.289013671875],
              [45.72548828125002, 41.261621093749994],
              [45.79277343750002, 41.224414062499996],
              [45.921972656250006, 41.18671875],
              [46.03125, 41.167285156249996],
              [46.086523437500006, 41.183837890625],
              [46.17070312500002, 41.1978515625],
              [46.27998046875001, 41.154443359374994],
              [46.38076171875002, 41.09931640625],
              [46.43095703125002, 41.077050781249994],
              [46.45791015625002, 41.070214843749994],
              [46.534375, 41.08857421875],
              [46.62636718750002, 41.15966796875],
              [46.66240234375002, 41.245507812499994],
              [46.67255859375001, 41.28681640625],
              [46.61894531250002, 41.34375],
              [46.5087890625, 41.405566406249996],
              [46.38496093750001, 41.459863281249994],
              [46.30546875000002, 41.507714843749994],
              [46.25468750000002, 41.6021484375],
              [46.20351562500002, 41.612597656249996],
              [46.190527343750006, 41.624853515625],
              [46.18212890625, 41.657080078125],
              [46.18427734375001, 41.7021484375],
              [46.20185546875001, 41.736865234374996],
              [46.25185546875002, 41.751757812499996],
              [46.30253906250002, 41.757080078125],
              [46.348242187500006, 41.790185546874994],
              [46.40546875000001, 41.855078125],
              [46.42988281250001, 41.890966796875],
              [46.537695312500006, 41.870410156249996],
              [46.55214843750002, 41.812304687499996],
              [46.5712890625, 41.800097656249996],
              [46.61601562500002, 41.806933593749996],
              [46.69033203125002, 41.831347656249996],
              [46.74931640625002, 41.81259765625],
              [46.82558593750002, 41.743408203125],
              [46.93085937500001, 41.67041015625],
              [46.98779296875, 41.621386718749996],
              [47.01015625000002, 41.5875],
              [47.06396484375, 41.5546875],
              [47.142578125, 41.516064453125],
              [47.20527343750001, 41.455615234374996],
              [47.26113281250002, 41.315087890624994],
              [47.31767578125002, 41.282421875],
              [47.520605468750006, 41.229052734374996],
              [47.591796875, 41.218115234375],
              [47.791015625, 41.199267578124996],
              [47.86113281250002, 41.212744140625],
              [47.96367187500002, 41.333984375],
              [48.05605468750002, 41.45869140625],
              [48.14228515625001, 41.484765625],
              [48.29814453125002, 41.54501953125],
              [48.39140625000002, 41.601904296875],
              [48.4306640625, 41.663330078125],
              [48.518652343750006, 41.779345703124996],
              [48.572851562500006, 41.844482421875],
              [48.664648437500006, 41.78662109375],
              [48.82392578125001, 41.62958984375],
              [49.050878906250006, 41.373974609375],
              [49.10664062500001, 41.301708984375],
              [49.14326171875001, 41.2177734375],
              [49.17470703125002, 41.116113281249994],
              [49.22646484375002, 41.026220703125],
              [49.45673828125001, 40.799853515624996],
              [49.55615234375, 40.71630859375],
              [49.718359375, 40.60810546875],
              [49.775976562500006, 40.583984375],
              [49.85175781250001, 40.577197265624996],
              [49.990625, 40.576806640624994],
              [50.119140625, 40.534521484375],
              [50.18251953125002, 40.504785156249994],
              [50.248046875, 40.461767578125],
              [50.30683593750001, 40.41220703125],
              [50.36591796875001, 40.2794921875],
              [50.143164062500006, 40.3232421875],
              [49.91884765625002, 40.31640625],
              [49.7919921875, 40.287890625],
              [49.669042968750006, 40.2490234375],
              [49.55117187500002, 40.194140625],
              [49.47734375000002, 40.087255859375],
              [49.415136718750006, 39.83984375],
              [49.32441406250001, 39.608349609375],
              [49.32753906250002, 39.501220703125],
              [49.36738281250001, 39.398388671875],
              [49.36279296875, 39.349560546875],
              [49.32119140625002, 39.32890625],
              [49.26933593750002, 39.28515625],
              [49.199804687500006, 39.07265625],
              [49.16533203125002, 39.0302734375],
              [49.12099609375002, 39.00390625],
              [49.10869140625002, 39.029052734375],
              [49.111328125, 39.084716796875],
              [49.01347656250002, 39.133984375],
              [48.96171875000002, 39.078759765624994],
              [48.92617187500002, 38.961767578125],
              [48.8544921875, 38.838818359375],
              [48.85087890625002, 38.815332031249994],
              [48.86875, 38.435498046875],
            ],
            [
              [45.55234375, 40.616064453125],
              [45.56230468750002, 40.649169921875],
              [45.5341796875, 40.664013671875],
              [45.504492187500006, 40.66484375],
              [45.47880859375002, 40.648339843749994],
              [45.47880859375002, 40.606982421874996],
              [45.51435546875001, 40.599560546875],
              [45.55234375, 40.616064453125],
            ],
          ],
          [
            [
              [45.02363281250001, 41.027246093749994],
              [45.002050781250006, 41.0158203125],
              [44.96904296875002, 41.027246093749994],
              [44.95888671875002, 41.052636718749994],
              [44.96142578125, 41.079248046874994],
              [44.99433593750001, 41.085595703124994],
              [45.02109375, 41.077978515625],
              [45.02871093750002, 41.053857421874994],
              [45.02363281250001, 41.027246093749994],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 3,
        labelrank: 2,
        sovereignt: "Turkey",
        sov_a3: "TUR",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Turkey",
        adm0_a3: "TUR",
        geou_dif: 0,
        geounit: "Turkey",
        gu_a3: "TUR",
        su_dif: 0,
        subunit: "Turkey",
        su_a3: "TUR",
        brk_diff: 0,
        name: "Turkey",
        name_long: "Turkey",
        brk_a3: "TUR",
        brk_name: "Turkey",
        brk_group: null,
        abbrev: "Tur.",
        postal: "TR",
        formal_en: "Republic of Turkey",
        formal_fr: null,
        name_ciawf: "Turkey",
        note_adm0: null,
        note_brk: null,
        name_sort: "Turkey",
        name_alt: null,
        mapcolor7: 6,
        mapcolor8: 3,
        mapcolor9: 8,
        mapcolor13: 4,
        pop_est: 83429615,
        pop_rank: 16,
        pop_year: 2019,
        gdp_md: 761425,
        gdp_year: 2019,
        economy: "4. Emerging region: MIKT",
        income_grp: "3. Upper middle income",
        fips_10: "TU",
        iso_a2: "TR",
        iso_a2_eh: "TR",
        iso_a3: "TUR",
        iso_a3_eh: "TUR",
        iso_n3: "792",
        iso_n3_eh: "792",
        un_a3: "792",
        wb_a2: "TR",
        wb_a3: "TUR",
        woe_id: 23424969,
        woe_id_eh: 23424969,
        woe_note: "Exact WOE match as country",
        adm0_iso: "TUR",
        adm0_diff: null,
        adm0_tlc: "TUR",
        adm0_a3_us: "TUR",
        adm0_a3_fr: "TUR",
        adm0_a3_ru: "TUR",
        adm0_a3_es: "TUR",
        adm0_a3_cn: "TUR",
        adm0_a3_tw: "TUR",
        adm0_a3_in: "TUR",
        adm0_a3_np: "TUR",
        adm0_a3_pk: "TUR",
        adm0_a3_de: "TUR",
        adm0_a3_gb: "TUR",
        adm0_a3_br: "TUR",
        adm0_a3_il: "TUR",
        adm0_a3_ps: "TUR",
        adm0_a3_sa: "TUR",
        adm0_a3_eg: "TUR",
        adm0_a3_ma: "TUR",
        adm0_a3_pt: "TUR",
        adm0_a3_ar: "TUR",
        adm0_a3_jp: "TUR",
        adm0_a3_ko: "TUR",
        adm0_a3_vn: "TUR",
        adm0_a3_tr: "TUR",
        adm0_a3_id: "TUR",
        adm0_a3_pl: "TUR",
        adm0_a3_gr: "TUR",
        adm0_a3_it: "TUR",
        adm0_a3_nl: "TUR",
        adm0_a3_se: "TUR",
        adm0_a3_bd: "TUR",
        adm0_a3_ua: "TUR",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Western Asia",
        region_wb: "Europe & Central Asia",
        name_len: 6,
        long_len: 6,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 2,
        max_label: 7,
        label_x: 34.508268,
        label_y: 39.345388,
        ne_id: 1159321331,
        wikidataid: "Q43",
        name_ar: "تركيا",
        name_bn: "তুরস্ক",
        name_de: "Türkei",
        name_en: "Turkey",
        name_es: "Turquía",
        name_fa: "ترکیه",
        name_fr: "Turquie",
        name_el: "Τουρκία",
        name_he: "טורקיה",
        name_hi: "तुर्की",
        name_hu: "Törökország",
        name_id: "Turki",
        name_it: "Turchia",
        name_ja: "トルコ",
        name_ko: "터키",
        name_nl: "Turkije",
        name_pl: "Turcja",
        name_pt: "Turquia",
        name_ru: "Турция",
        name_sv: "Turkiet",
        name_tr: "Türkiye",
        name_uk: "Туреччина",
        name_ur: "ترکی",
        name_vi: "Thổ Nhĩ Kỳ",
        name_zh: "土耳其",
        name_zht: "土耳其",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "TUR.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [25.970019531250017, 40.136328125],
              [25.740234375, 40.10546875],
              [25.6689453125, 40.135888671874994],
              [25.74091796875001, 40.1962890625],
              [25.874804687500017, 40.233691406249996],
              [25.918359375000023, 40.23798828125],
              [25.97705078125, 40.17783203125],
              [25.970019531250017, 40.136328125],
            ],
          ],
          [
            [
              [41.51005859375002, 41.517480468749994],
              [41.57656250000002, 41.497314453125],
              [41.701757812500006, 41.471582031249994],
              [41.779394531250006, 41.44052734375],
              [41.82353515625002, 41.432373046875],
              [41.92578125, 41.495654296874996],
              [42.077734375, 41.494091796875],
              [42.21113281250001, 41.48671875],
              [42.27998046875001, 41.475],
              [42.364355468750006, 41.454003906249994],
              [42.46640625, 41.43984375],
              [42.507910156250006, 41.470068359375],
              [42.5673828125, 41.55927734375],
              [42.590429687500006, 41.570703125],
              [42.60683593750002, 41.57880859375],
              [42.68242187500002, 41.585742187499996],
              [42.75410156250001, 41.57890625],
              [42.78789062500002, 41.563720703125],
              [42.82167968750002, 41.4923828125],
              [42.90673828125, 41.466845703124996],
              [43.05712890625, 41.352832031249996],
              [43.149023437500006, 41.30712890625],
              [43.17128906250002, 41.287939453125],
              [43.14101562500002, 41.26484375],
              [43.15283203125, 41.23642578125],
              [43.20546875000002, 41.199169921875],
              [43.279296875, 41.185205078124994],
              [43.358984375, 41.19013671875],
              [43.40234375, 41.1765625],
              [43.43339843750002, 41.155517578125],
              [43.44160156250001, 41.1259765625],
              [43.439453125, 41.10712890625],
              [43.45527343750001, 41.064697265625],
              [43.51748046875002, 41.004833984375],
              [43.59169921875002, 40.968212890625],
              [43.63164062500002, 40.929003906249996],
              [43.69648437500001, 40.794140625],
              [43.72265625, 40.71953125],
              [43.712890625, 40.647753906249996],
              [43.66787109375002, 40.574072265625],
              [43.5693359375, 40.482373046875],
              [43.59375, 40.44404296875],
              [43.615820312500006, 40.393310546875],
              [43.6083984375, 40.356591796874994],
              [43.678125, 40.239306640624996],
              [43.70986328125002, 40.16650390625],
              [43.68330078125001, 40.149658203125],
              [43.66621093750001, 40.126367187499994],
              [43.79169921875001, 40.070263671875],
              [43.941992187500006, 40.02314453125],
              [44.00537109375, 40.014111328125],
              [44.17802734375002, 40.0357421875],
              [44.28925781250001, 40.040380859375],
              [44.39960937500001, 39.995751953124994],
              [44.56044921875002, 39.887597656249994],
              [44.73378906250002, 39.746484375],
              [44.76826171875001, 39.703515625],
              [44.78339843750001, 39.68466796875],
              [44.81718750000002, 39.650439453124996],
              [44.78212890625002, 39.65107421875],
              [44.725, 39.68173828125],
              [44.58710937500001, 39.7685546875],
              [44.516699218750006, 39.73125],
              [44.45595703125002, 39.666748046875],
              [44.38935546875001, 39.422119140625],
              [44.33544921875, 39.396044921874996],
              [44.24042968750001, 39.396777343749996],
              [44.1240234375, 39.405224609375],
              [44.0439453125, 39.39296875],
              [44.02324218750002, 39.37744140625],
              [44.033789062500006, 39.351025390625],
              [44.05751953125002, 39.310839843749996],
              [44.074316406250006, 39.2599609375],
              [44.0791015625, 39.218310546874996],
              [44.12128906250001, 39.180615234375],
              [44.17802734375002, 39.144824218749996],
              [44.18056640625002, 39.108056640624994],
              [44.171875, 39.05625],
              [44.158789062500006, 39.016748046874994],
              [44.14453125, 38.994384765625],
              [44.17080078125002, 38.934375],
              [44.232421875, 38.863232421875],
              [44.27167968750001, 38.83603515625],
              [44.25703125000001, 38.700634765625],
              [44.28017578125002, 38.640673828124996],
              [44.2978515625, 38.5578125],
              [44.29082031250002, 38.4201171875],
              [44.29853515625001, 38.386279296874996],
              [44.31962890625002, 38.37470703125],
              [44.37578125000002, 38.369580078125],
              [44.43085937500001, 38.356787109375],
              [44.44990234375001, 38.334228515625],
              [44.44960937500002, 38.317773437499994],
              [44.380859375, 38.25458984375],
              [44.37275390625001, 38.209716796875],
              [44.34892578125002, 38.146484375],
              [44.32939453125002, 38.109277343749994],
              [44.26796875000002, 38.038818359375],
              [44.22890625000002, 37.9671875],
              [44.21132812500002, 37.908056640625],
              [44.22294921875002, 37.880175781249996],
              [44.33623046875002, 37.87177734375],
              [44.39775390625002, 37.829248046874994],
              [44.56123046875001, 37.74462890625],
              [44.589941406250006, 37.710351562499994],
              [44.54531250000002, 37.658154296875],
              [44.54609375000001, 37.636328125],
              [44.56718750000002, 37.608642578125],
              [44.5771484375, 37.560205078124994],
              [44.57314453125002, 37.506396484374996],
              [44.57402343750002, 37.435400390625],
              [44.604101562500006, 37.423730468749994],
              [44.71513671875002, 37.35712890625],
              [44.79414062500001, 37.290380859375],
              [44.79677734375002, 37.269775390625],
              [44.75830078125, 37.217089843749996],
              [44.766699218750006, 37.15634765625],
              [44.76513671875, 37.142431640625],
              [44.73095703125, 37.165283203125],
              [44.66933593750002, 37.173583984375],
              [44.60595703125, 37.176025390625],
              [44.566015625, 37.158251953124996],
              [44.49599609375002, 37.110546875],
              [44.401953125, 37.058496093749994],
              [44.32558593750002, 37.0107421875],
              [44.281835937500006, 36.97802734375],
              [44.245703125, 36.983300781249994],
              [44.21748046875001, 37.011865234374994],
              [44.20166015625, 37.051806640624996],
              [44.20839843750002, 37.20263671875],
              [44.19179687500002, 37.249853515625],
              [44.15625, 37.282958984375],
              [44.11445312500001, 37.30185546875],
              [44.06464843750001, 37.312451171875],
              [44.01318359375, 37.313525390624996],
              [43.940039062500006, 37.269287109375],
              [43.83642578125, 37.223535156249994],
              [43.67578125, 37.22724609375],
              [43.56796875, 37.23583984375],
              [43.51582031250001, 37.24453125],
              [43.306738281250006, 37.3146484375],
              [43.26308593750002, 37.31650390625],
              [43.18515625, 37.344873046874994],
              [43.09248046875001, 37.3673828125],
              [42.936621093750006, 37.324755859374996],
              [42.869140625, 37.334912109375],
              [42.77460937500001, 37.371875],
              [42.74111328125002, 37.3619140625],
              [42.63544921875001, 37.249267578125],
              [42.45585937500002, 37.128710937499996],
              [42.358984375, 37.10859375],
              [42.31289062500002, 37.22958984375],
              [42.2685546875, 37.2765625],
              [42.24755859375, 37.2822265625],
              [42.202734375, 37.297265625],
              [42.16787109375002, 37.288623046874996],
              [42.05986328125002, 37.2060546875],
              [41.886816406250006, 37.156396484374994],
              [41.74355468750002, 37.126123046874994],
              [41.51552734375002, 37.089160156249996],
              [41.33955078125001, 37.07080078125],
              [41.2646484375, 37.0693359375],
              [41.102148437500006, 37.085888671875],
              [40.95888671875002, 37.109179687499996],
              [40.815625, 37.108154296875],
              [40.705664062500006, 37.097705078124996],
              [40.45039062500001, 37.00888671875],
              [40.01640625000002, 36.826074218749994],
              [39.6865234375, 36.738623046875],
              [39.50146484375, 36.70224609375],
              [39.35664062500001, 36.681591796875],
              [39.1083984375, 36.680566406249994],
              [38.90644531250001, 36.694677734375],
              [38.7666015625, 36.693115234375],
              [38.68886718750002, 36.715087890625],
              [38.57802734375002, 36.789111328124996],
              [38.44375, 36.862255859375],
              [38.38398437500001, 36.879248046875],
              [38.30585937500001, 36.893359375],
              [38.19169921875002, 36.9015625],
              [37.90664062500002, 36.79462890625],
              [37.81796875, 36.765576171875],
              [37.7203125, 36.743701171874996],
              [37.523535156250006, 36.6783203125],
              [37.43632812500002, 36.643310546875],
              [37.32705078125002, 36.64658203125],
              [37.18740234375002, 36.655908203124994],
              [37.06621093750002, 36.652636718749996],
              [36.9853515625, 36.702392578125],
              [36.94179687500002, 36.7583984375],
              [36.77656250000001, 36.79267578125],
              [36.65859375000002, 36.802539062499996],
              [36.62841796875, 36.777685546875],
              [36.596875, 36.7013671875],
              [36.54667968750002, 36.50634765625],
              [36.5375, 36.457421875],
              [36.64140625000002, 36.263525390625],
              [36.63671875, 36.233984375],
              [36.56240234375002, 36.223925781249996],
              [36.47705078125, 36.220703125],
              [36.421484375, 36.203466796875],
              [36.37539062500002, 36.171240234375],
              [36.34755859375002, 36.003515625],
              [36.24882812500002, 35.972705078124996],
              [36.20195312500002, 35.937548828124996],
              [36.15361328125002, 35.833886718749994],
              [36.12734375000002, 35.831445312499994],
              [35.96757812500002, 35.910058593749994],
              [35.892675781250006, 35.916552734374996],
              [35.95693359375002, 35.99814453125],
              [35.88710937500002, 36.159082031249994],
              [35.81093750000002, 36.309863281249996],
              [35.8828125, 36.40634765625],
              [36.03173828125, 36.522705078125],
              [36.1884765625, 36.658984375],
              [36.18818359375001, 36.743066406249994],
              [36.18007812500002, 36.8072265625],
              [36.13515625000002, 36.851611328124996],
              [36.048925781250006, 36.910595703125],
              [35.90458984375002, 36.847607421875],
              [35.80156250000002, 36.778076171875],
              [35.73427734375002, 36.763964843749996],
              [35.6611328125, 36.72431640625],
              [35.625585937500006, 36.652783203125],
              [35.53740234375002, 36.597021484375],
              [35.393164062500006, 36.5751953125],
              [35.17617187500002, 36.63486328125],
              [34.94316406250002, 36.72568359375],
              [34.81123046875001, 36.799267578125],
              [34.70361328125, 36.816796875],
              [34.60136718750002, 36.784472656249996],
              [34.29960937500002, 36.60419921875],
              [34.0234375, 36.340771484375],
              [33.95488281250002, 36.295214843749996],
              [33.69472656250002, 36.181982421875],
              [33.52275390625002, 36.143994140625],
              [33.44179687500002, 36.15283203125],
              [33.09951171875002, 36.102978515625],
              [32.92949218750002, 36.095703125],
              [32.79482421875002, 36.035888671875],
              [32.533789062500006, 36.100732421874994],
              [32.37773437500002, 36.183642578124996],
              [32.283789062500006, 36.267871093749996],
              [32.13056640625001, 36.449121093749994],
              [32.02197265625, 36.535302734374994],
              [31.777929687500006, 36.61279296875],
              [31.3525390625, 36.801074218749996],
              [31.240625, 36.821728515625],
              [30.950292968750006, 36.848681640624996],
              [30.64404296875, 36.865673828125],
              [30.58203125, 36.797167968749996],
              [30.558496093750023, 36.525830078125],
              [30.50605468750001, 36.451123046875],
              [30.48359375000001, 36.310400390625],
              [30.446093750000017, 36.269873046875],
              [30.387304687500006, 36.24326171875],
              [30.29541015625, 36.2876953125],
              [30.23164062500001, 36.30732421875],
              [30.08320312500001, 36.249365234375],
              [29.78925781250001, 36.16806640625],
              [29.6890625, 36.156689453125],
              [29.34833984375001, 36.258837890624996],
              [29.2236328125, 36.324462890625],
              [29.14326171875001, 36.397216796875],
              [29.116113281250023, 36.5201171875],
              [29.065527343750006, 36.590087890625],
              [29.05810546875, 36.638134765625],
              [29.03828125000001, 36.69345703125],
              [28.969628906250023, 36.71533203125],
              [28.895898437500023, 36.673583984375],
              [28.81689453125, 36.67529296875],
              [28.717675781250023, 36.70087890625],
              [28.48359375000001, 36.80380859375],
              [28.3037109375, 36.811962890625],
              [28.195605468750017, 36.686328125],
              [28.11152343750001, 36.646386718749994],
              [28.019433593750023, 36.63447265625],
              [28.01416015625, 36.670214843749996],
              [28.083984375, 36.75146484375],
              [27.803808593750006, 36.736474609374994],
              [27.655859375, 36.674609375],
              [27.540429687500023, 36.684228515624994],
              [27.453906250000017, 36.712158203125],
              [27.466894531250006, 36.746337890625],
              [27.5546875, 36.75888671875],
              [27.630859375, 36.786669921874996],
              [27.934472656250023, 36.80927734375],
              [28.00537109375, 36.831982421875],
              [28.0830078125, 36.920263671875],
              [28.224414062500017, 36.996386718749996],
              [28.24238281250001, 37.029052734375],
              [28.133691406250023, 37.0294921875],
              [27.668359375000023, 37.007421875],
              [27.348925781250017, 37.019580078124996],
              [27.31103515625, 36.981884765625],
              [27.262988281250017, 36.9765625],
              [27.24970703125001, 37.079150390624996],
              [27.300195312500023, 37.126855468749994],
              [27.3681640625, 37.122412109375],
              [27.535058593750023, 37.163867187499996],
              [27.520117187500006, 37.24912109375],
              [27.40058593750001, 37.30673828125],
              [27.376269531250017, 37.340722656249994],
              [27.28955078125, 37.348681640624996],
              [27.21923828125, 37.38916015625],
              [27.203906250000017, 37.49140625],
              [27.14794921875, 37.60361328125],
              [27.06796875, 37.65791015625],
              [27.07783203125001, 37.6876953125],
              [27.224414062500017, 37.725439453125],
              [27.254785156250023, 37.88232421875],
              [27.232421875, 37.978662109374994],
              [27.15869140625, 37.986865234374996],
              [26.94384765625, 38.062890625],
              [26.87861328125001, 38.05478515625],
              [26.807421875000017, 38.138330078124994],
              [26.68281250000001, 38.19833984375],
              [26.62109375, 38.1763671875],
              [26.582421875000023, 38.149267578125],
              [26.524707031250017, 38.162255859374994],
              [26.42792968750001, 38.21435546875],
              [26.332910156250023, 38.242480468749996],
              [26.29072265625001, 38.277197265625],
              [26.343652343750023, 38.370068359375],
              [26.416406250000023, 38.36787109375],
              [26.4296875, 38.440625],
              [26.37226562500001, 38.561914062499994],
              [26.377832031250023, 38.624169921874994],
              [26.441308593750023, 38.6412109375],
              [26.513574218750023, 38.6294921875],
              [26.586523437500006, 38.55703125],
              [26.6103515625, 38.4869140625],
              [26.595019531250017, 38.418603515624994],
              [26.64130859375001, 38.352441406249994],
              [26.674218750000023, 38.335742187499996],
              [26.696386718750006, 38.40537109375],
              [26.727343750000017, 38.418603515624994],
              [26.769921875000023, 38.38818359375],
              [26.861425781250006, 38.372949218749994],
              [27.0986328125, 38.41572265625],
              [27.14423828125001, 38.451953125],
              [26.97041015625001, 38.4478515625],
              [26.906835937500006, 38.481738281249996],
              [26.837792968750023, 38.557568359375],
              [26.795312500000023, 38.626416015625],
              [26.787695312500006, 38.660205078124996],
              [26.763671875, 38.709619140624994],
              [26.790136718750006, 38.736083984375],
              [26.9091796875, 38.77578125],
              [27.013671875, 38.886865234374994],
              [26.970117187500023, 38.91904296875],
              [26.920312500000023, 38.934228515624994],
              [26.8662109375, 38.92294921875],
              [26.81494140625, 38.960986328124996],
              [26.80830078125001, 39.013916015625],
              [26.84931640625001, 39.05673828125],
              [26.853613281250006, 39.115625],
              [26.719335937500006, 39.260644531249994],
              [26.68183593750001, 39.292236328125],
              [26.710742187500017, 39.339648437499996],
              [26.813281250000017, 39.41904296875],
              [26.910937500000017, 39.517333984375],
              [26.899218750000017, 39.549658203125],
              [26.827050781250023, 39.562890625],
              [26.48408203125001, 39.520703125],
              [26.35078125000001, 39.48408203125],
              [26.113085937500017, 39.4673828125],
              [26.095996093750017, 39.520800781249996],
              [26.101367187500017, 39.5689453125],
              [26.154687500000023, 39.656640625],
              [26.149804687500023, 39.872851562499996],
              [26.18134765625001, 39.990087890625],
              [26.313378906250023, 40.025],
              [26.475390625000017, 40.197265625],
              [26.738085937500017, 40.400244140625],
              [27.012109375000023, 40.396337890625],
              [27.121679687500006, 40.45234375],
              [27.284570312500023, 40.455615234374996],
              [27.31416015625001, 40.414892578125],
              [27.332617187500006, 40.375927734375],
              [27.4755859375, 40.319921875],
              [27.72802734375, 40.32880859375],
              [27.789355468750017, 40.350878906249996],
              [27.848535156250023, 40.381738281249994],
              [27.731835937500023, 40.481494140624996],
              [27.769140625, 40.509619140625],
              [27.874902343750023, 40.512939453125],
              [27.989550781250017, 40.489453125],
              [27.99482421875001, 40.466601562499996],
              [27.96435546875, 40.435302734375],
              [27.92890625000001, 40.380419921874996],
              [27.96259765625001, 40.369873046875],
              [28.2890625, 40.40302734375],
              [28.630273437500023, 40.37646484375],
              [28.738867187500006, 40.390869140625],
              [29.007128906250017, 40.38974609375],
              [29.05517578125, 40.424169921875],
              [28.974023437500023, 40.4673828125],
              [28.894628906250006, 40.482421875],
              [28.841210937500023, 40.503466796874996],
              [28.787890625000017, 40.534033203125],
              [28.9580078125, 40.63056640625],
              [29.054101562500023, 40.64912109375],
              [29.507617187500017, 40.708398437499994],
              [29.84492187500001, 40.738085937499996],
              [29.84921875, 40.760107421875],
              [29.800585937500017, 40.76015625],
              [29.36474609375, 40.80927734375],
              [29.259765625, 40.847314453124994],
              [29.113867187500006, 40.937841796875],
              [29.08222656250001, 40.963427734374996],
              [29.045507812500006, 41.007568359375],
              [29.0673828125, 41.10166015625],
              [29.094335937500006, 41.17724609375],
              [29.14814453125001, 41.221044921875],
              [29.322265625, 41.227734375],
              [29.919335937500023, 41.150830078125],
              [30.34492187500001, 41.196923828124994],
              [30.81005859375, 41.084863281249994],
              [31.2548828125, 41.1076171875],
              [31.3466796875, 41.15791015625],
              [31.4580078125, 41.32001953125],
              [32.08642578125, 41.589208984375],
              [32.30644531250002, 41.72958984375],
              [32.54218750000001, 41.806396484375],
              [32.94667968750002, 41.891748046874994],
              [33.284765625, 42.00458984375],
              [33.38134765625, 42.017578125],
              [34.19296875, 41.963671875],
              [34.75048828125, 41.956835937499996],
              [35.006445312500006, 42.06328125],
              [35.154882812500006, 42.0275390625],
              [35.14101562500002, 41.989501953125],
              [35.11406250000002, 41.956982421875],
              [35.1220703125, 41.89111328125],
              [35.20917968750001, 41.794384765625],
              [35.29775390625002, 41.728515625],
              [35.55800781250002, 41.634033203125],
              [35.91982421875002, 41.713720703125],
              [35.978125, 41.704833984375],
              [36.0517578125, 41.682568359375],
              [36.17919921875, 41.4265625],
              [36.278417968750006, 41.3361328125],
              [36.405371093750006, 41.274609375],
              [36.50966796875002, 41.2625],
              [36.58710937500001, 41.32666015625],
              [36.647070312500006, 41.3525390625],
              [36.77773437500002, 41.3634765625],
              [36.99199218750002, 41.275390625],
              [37.06621093750002, 41.184423828125],
              [37.43095703125002, 41.114111328125],
              [37.765625, 41.07890625],
              [37.91005859375002, 41.001904296875],
              [38.38105468750001, 40.92451171875],
              [38.55693359375002, 40.9365234375],
              [38.852148437500006, 41.01767578125],
              [39.426367187500006, 41.1064453125],
              [39.80791015625002, 40.98251953125],
              [39.9111328125, 40.966455078124994],
              [40.00019531250001, 40.9771484375],
              [40.12841796875, 40.943017578124994],
              [40.265234375, 40.961328125],
              [40.6875, 41.107421875],
              [40.81953125000001, 41.190234375],
              [40.95947265625, 41.21162109375],
              [41.08359375, 41.261181640625],
              [41.41435546875002, 41.423632812499996],
              [41.51005859375002, 41.517480468749994],
            ],
          ],
          [
            [
              [28.014453125000017, 41.969042968749996],
              [27.9873046875, 41.854882812499994],
              [28.05029296875, 41.729150390624994],
              [28.197851562500006, 41.554492187499996],
              [28.34638671875001, 41.466357421874996],
              [28.94677734375, 41.248388671875],
              [29.057226562500006, 41.229736328125],
              [29.032128906250023, 41.140478515625],
              [28.995996093750023, 41.0611328125],
              [28.95625, 41.008203125],
              [28.780371093750006, 40.974169921874996],
              [28.294921875, 41.071484375],
              [28.172167968750017, 41.080712890624994],
              [28.085546875, 41.061328125],
              [27.925195312500023, 40.990576171875],
              [27.747363281250017, 41.01328125],
              [27.499414062500023, 40.97314453125],
              [27.43017578125, 40.83994140625],
              [27.25800781250001, 40.687353515625],
              [26.974609375, 40.564013671874996],
              [26.772070312500006, 40.498046875],
              [26.46796875000001, 40.261474609375],
              [26.329980468750023, 40.123388671875],
              [26.271777343750017, 40.096582031249994],
              [26.202734375, 40.075390625],
              [26.225976562500023, 40.14169921875],
              [26.260156250000023, 40.202392578125],
              [26.252343750000023, 40.24814453125],
              [26.253808593750023, 40.314697265625],
              [26.355273437500017, 40.390234375],
              [26.44746093750001, 40.44501953125],
              [26.7203125, 40.544238281249996],
              [26.792089843750006, 40.626611328124994],
              [26.578125, 40.624658203124994],
              [26.3609375, 40.606347656249994],
              [26.22421875, 40.618066406249994],
              [26.10546875, 40.611328125],
              [26.067773437500023, 40.683398437499996],
              [26.038964843750023, 40.7267578125],
              [26.069726562500023, 40.740283203124996],
              [26.109179687500017, 40.749658203124994],
              [26.17890625000001, 40.826513671875],
              [26.2412109375, 40.883203125],
              [26.3310546875, 40.954492187499994],
              [26.354101562500006, 40.9970703125],
              [26.354101562500006, 41.036767578124994],
              [26.332617187500006, 41.064306640625],
              [26.328417968750017, 41.097021484375],
              [26.32568359375, 41.14326171875],
              [26.330664062500006, 41.23876953125],
              [26.536425781250017, 41.343115234375],
              [26.602343750000017, 41.354150390624994],
              [26.624902343750023, 41.401757812499994],
              [26.609765625000023, 41.512158203125],
              [26.581347656250017, 41.60126953125],
              [26.54453125, 41.607226562499996],
              [26.495019531250023, 41.633251953125],
              [26.4625, 41.663378906249996],
              [26.410546875000023, 41.696337890624996],
              [26.320898437500006, 41.716552734375],
              [26.31796875, 41.744677734374996],
              [26.327246093750006, 41.772802734375],
              [26.3603515625, 41.8015625],
              [26.51142578125001, 41.8263671875],
              [26.529296875, 41.8466796875],
              [26.549707031250023, 41.896728515625],
              [26.5796875, 41.94794921875],
              [26.615332031250006, 41.964892578124996],
              [26.67919921875, 41.963330078125],
              [26.800390625, 41.975146484374996],
              [26.884863281250006, 41.991845703124994],
              [26.96875, 42.02685546875],
              [27.01171875, 42.058642578124996],
              [27.193359375, 42.077099609375],
              [27.24433593750001, 42.09326171875],
              [27.294921875, 42.079541015625],
              [27.362890625, 42.025048828124994],
              [27.47480468750001, 41.946875],
              [27.53486328125001, 41.920800781249994],
              [27.579882812500017, 41.932910156249996],
              [27.6611328125, 41.961328125],
              [27.738867187500006, 41.9615234375],
              [27.801660156250023, 41.95654296875],
              [27.831933593750023, 41.981298828125],
              [27.879199218750017, 41.986621093749996],
              [28.014453125000017, 41.969042968749996],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 4,
        sovereignt: "Laos",
        sov_a3: "LAO",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Laos",
        adm0_a3: "LAO",
        geou_dif: 0,
        geounit: "Laos",
        gu_a3: "LAO",
        su_dif: 0,
        subunit: "Laos",
        su_a3: "LAO",
        brk_diff: 0,
        name: "Laos",
        name_long: "Lao PDR",
        brk_a3: "LAO",
        brk_name: "Laos",
        brk_group: null,
        abbrev: "Laos",
        postal: "LA",
        formal_en: "Lao People's Democratic Republic",
        formal_fr: null,
        name_ciawf: "Laos",
        note_adm0: null,
        note_brk: null,
        name_sort: "Lao PDR",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 1,
        mapcolor9: 1,
        mapcolor13: 9,
        pop_est: 7169455,
        pop_rank: 13,
        pop_year: 2019,
        gdp_md: 18173,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "4. Lower middle income",
        fips_10: "LA",
        iso_a2: "LA",
        iso_a2_eh: "LA",
        iso_a3: "LAO",
        iso_a3_eh: "LAO",
        iso_n3: "418",
        iso_n3_eh: "418",
        un_a3: "418",
        wb_a2: "LA",
        wb_a3: "LAO",
        woe_id: 23424872,
        woe_id_eh: 23424872,
        woe_note: "Exact WOE match as country",
        adm0_iso: "LAO",
        adm0_diff: null,
        adm0_tlc: "LAO",
        adm0_a3_us: "LAO",
        adm0_a3_fr: "LAO",
        adm0_a3_ru: "LAO",
        adm0_a3_es: "LAO",
        adm0_a3_cn: "LAO",
        adm0_a3_tw: "LAO",
        adm0_a3_in: "LAO",
        adm0_a3_np: "LAO",
        adm0_a3_pk: "LAO",
        adm0_a3_de: "LAO",
        adm0_a3_gb: "LAO",
        adm0_a3_br: "LAO",
        adm0_a3_il: "LAO",
        adm0_a3_ps: "LAO",
        adm0_a3_sa: "LAO",
        adm0_a3_eg: "LAO",
        adm0_a3_ma: "LAO",
        adm0_a3_pt: "LAO",
        adm0_a3_ar: "LAO",
        adm0_a3_jp: "LAO",
        adm0_a3_ko: "LAO",
        adm0_a3_vn: "LAO",
        adm0_a3_tr: "LAO",
        adm0_a3_id: "LAO",
        adm0_a3_pl: "LAO",
        adm0_a3_gr: "LAO",
        adm0_a3_it: "LAO",
        adm0_a3_nl: "LAO",
        adm0_a3_se: "LAO",
        adm0_a3_bd: "LAO",
        adm0_a3_ua: "LAO",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "South-Eastern Asia",
        region_wb: "East Asia & Pacific",
        name_len: 4,
        long_len: 7,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: 102.533912,
        label_y: 19.431821,
        ne_id: 1159321011,
        wikidataid: "Q819",
        name_ar: "لاوس",
        name_bn: "লাওস",
        name_de: "Laos",
        name_en: "Laos",
        name_es: "Laos",
        name_fa: "لائوس",
        name_fr: "Laos",
        name_el: "Λάος",
        name_he: "לאוס",
        name_hi: "लाओस",
        name_hu: "Laosz",
        name_id: "Laos",
        name_it: "Laos",
        name_ja: "ラオス",
        name_ko: "라오스",
        name_nl: "Laos",
        name_pl: "Laos",
        name_pt: "Laos",
        name_ru: "Лаос",
        name_sv: "Laos",
        name_tr: "Laos",
        name_uk: "Лаос",
        name_ur: "لاؤس",
        name_vi: "Lào",
        name_zh: "老挝",
        name_zht: "寮國",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "LAO.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [102.12744140625, 22.379199218750003],
            [102.18300781250002, 22.28403320312492],
            [102.30136718749998, 22.178173828125068],
            [102.44267578124999, 22.02714843749999],
            [102.4875, 21.957763671875],
            [102.58251953125, 21.904296875],
            [102.60966796874999, 21.851757812499997],
            [102.63125, 21.77133789062499],
            [102.64082031250001, 21.71142578125],
            [102.66201171875002, 21.676025390625],
            [102.69531249999994, 21.662109375000057],
            [102.73857421874999, 21.677929687499997],
            [102.77109375000003, 21.709667968749997],
            [102.79824218750002, 21.79794921874999],
            [102.81591796875, 21.807373046875],
            [102.84521484375, 21.734765625],
            [102.87617187500001, 21.72226562500009],
            [102.91767578125001, 21.712939453125003],
            [102.94960937500002, 21.681347656249997],
            [102.95917968750001, 21.626220703125],
            [102.94863281250002, 21.569775390624997],
            [102.90957031250002, 21.50634765625],
            [102.8875, 21.43994140625],
            [102.87226562500001, 21.3375],
            [102.85117187500003, 21.265917968750003],
            [102.8837890625, 21.202587890624997],
            [103.1044921875, 20.891650390625003],
            [103.21074218749999, 20.840625],
            [103.46357421874995, 20.779833984375102],
            [103.5546875, 20.737841796875003],
            [103.63505859374999, 20.697070312500003],
            [103.71445312500003, 20.716943359374994],
            [103.79052734375, 20.809521484374997],
            [103.88203125000001, 20.86142578124999],
            [104.05205078124999, 20.941210937500102],
            [104.10136718749999, 20.945507812499997],
            [104.1953125, 20.913964843749994],
            [104.349609375, 20.82109374999999],
            [104.46142578125, 20.73374023437499],
            [104.53037109375003, 20.68798828125],
            [104.58320312500001, 20.646679687499955],
            [104.57519531249994, 20.60024414062508],
            [104.53271484375, 20.554882812500125],
            [104.47861328124998, 20.529589843750102],
            [104.40781250000003, 20.485742187499994],
            [104.36777343750003, 20.44140625],
            [104.39218750000003, 20.424755859374997],
            [104.49619140625003, 20.413671875],
            [104.61884765624995, 20.374511718750114],
            [104.65644531250001, 20.328515625],
            [104.66191406249999, 20.289013671874997],
            [104.67695312500001, 20.22470703124999],
            [104.69873046875, 20.205322265625],
            [104.81269531250001, 20.21684570312499],
            [104.84785156250001, 20.202441406250003],
            [104.888671875, 20.169091796874994],
            [104.92919921874994, 20.082812500000045],
            [104.92792968750001, 20.018115234375003],
            [104.84580078125003, 19.94716796875008],
            [104.81513671875001, 19.904003906249997],
            [104.8017578125, 19.836132812499997],
            [104.7431640625, 19.75473632812499],
            [104.587890625, 19.61875],
            [104.54628906250002, 19.610546875],
            [104.25986328125003, 19.685498046874997],
            [104.12714843750001, 19.68085937500001],
            [104.06279296874999, 19.678417968749997],
            [104.03203124999999, 19.67514648437499],
            [104.01347656249999, 19.646484375],
            [104.05156249999999, 19.564160156249997],
            [104.06289062500002, 19.482568359375136],
            [104.02753906250001, 19.420458984375003],
            [103.93203125000002, 19.366064453125034],
            [103.89638671875002, 19.339990234375023],
            [103.89160156249994, 19.30498046874999],
            [103.91835937500002, 19.268505859374997],
            [104.00634765625, 19.230908203124997],
            [104.10859375000001, 19.195556640625],
            [104.44580078125, 18.983837890624997],
            [104.51796875000002, 18.934082031250114],
            [104.61328125, 18.860644531250003],
            [104.71650390624995, 18.803417968749955],
            [104.9931640625, 18.728320312500003],
            [105.11513671875002, 18.678857421875023],
            [105.146484375, 18.650976562499977],
            [105.14541015625002, 18.616796875],
            [105.11347656250001, 18.573046875],
            [105.08701171875003, 18.496240234374994],
            [105.08583984375002, 18.450097656249994],
            [105.11455078124999, 18.4052734375],
            [105.16328125000001, 18.33872070312499],
            [105.27324218749999, 18.2353515625],
            [105.33349609375, 18.189648437499997],
            [105.4, 18.179248046875003],
            [105.45820312500001, 18.154296875],
            [105.5185546875, 18.077441406250003],
            [105.58847656250003, 17.983691406250003],
            [105.59765625, 17.918261718750003],
            [105.62724609374999, 17.834423828124997],
            [105.69140625, 17.737841796874932],
            [105.77949218750001, 17.644433593749994],
            [105.90273437500002, 17.52866210937499],
            [105.97353515625002, 17.446972656249997],
            [106.00625, 17.415283203125],
            [106.26953125, 17.216796875000057],
            [106.33339843750002, 17.14370117187508],
            [106.42597656250001, 17.00253906249999],
            [106.46533203125, 16.981835937499994],
            [106.50224609374999, 16.9541015625],
            [106.52597656250003, 16.876611328125023],
            [106.53369140625, 16.821044921875],
            [106.54619140624999, 16.65073242187499],
            [106.59365234375002, 16.60009765625],
            [106.6375, 16.53793945312492],
            [106.65644531250001, 16.492626953124997],
            [106.69609374999999, 16.458984375],
            [106.73955078124999, 16.452539062500136],
            [106.79160156250003, 16.49033203124999],
            [106.83242187500002, 16.526269531249994],
            [106.85107421875, 16.515625],
            [106.89277343750001, 16.396533203125003],
            [106.9306640625, 16.353125],
            [107.001953125, 16.311816406250045],
            [107.06972656250002, 16.279833984375003],
            [107.21738281250003, 16.136328124999977],
            [107.29648437500003, 16.084033203125003],
            [107.35009765625, 16.0673828125],
            [107.39638671875002, 16.043017578125003],
            [107.41015625, 15.997851562500003],
            [107.39199218750002, 15.95166015625],
            [107.36064453124999, 15.921728515624991],
            [107.18886718750002, 15.838623046875],
            [107.16591796875002, 15.802490234375],
            [107.18955078125003, 15.747265625],
            [107.23261718750001, 15.678076171874991],
            [107.27939453125003, 15.618701171875003],
            [107.33876953125002, 15.560498046875125],
            [107.45957031250003, 15.4658203125],
            [107.56425781249999, 15.3916015625],
            [107.62167968750003, 15.309863281250003],
            [107.653125, 15.255224609374991],
            [107.63369140625002, 15.18984375],
            [107.58964843749999, 15.118457031250003],
            [107.55527343750003, 15.05703125],
            [107.49628906250001, 15.021435546874997],
            [107.48037109375002, 14.979882812499994],
            [107.50468749999999, 14.915917968749994],
            [107.52451171875003, 14.871826171874943],
            [107.51376953124998, 14.817382812500057],
            [107.51943359375002, 14.705078125],
            [107.46513671874999, 14.664990234374997],
            [107.41474609375001, 14.562890625],
            [107.3798828125, 14.555322265624994],
            [107.29267578125001, 14.592382812499991],
            [107.26230468750003, 14.572119140624991],
            [107.20664062500003, 14.497900390624991],
            [107.109375, 14.416699218749997],
            [107.06240234375002, 14.415771484375],
            [107.03017578125002, 14.425683593749994],
            [106.99218749999994, 14.39101562500008],
            [106.93808593750003, 14.32734375],
            [106.91318359375003, 14.329394531250003],
            [106.81992187500003, 14.314697265624943],
            [106.78349609374999, 14.335107421874994],
            [106.73818359375002, 14.387744140625003],
            [106.66542968750002, 14.441308593750023],
            [106.59921875000003, 14.479394531249994],
            [106.56367187500001, 14.505078125],
            [106.53115234375002, 14.549414062499977],
            [106.50146484375, 14.578222656250034],
            [106.44697265625001, 14.515039062499994],
            [106.35498046875, 14.454785156250068],
            [106.26796875000002, 14.466210937499994],
            [106.22539062499999, 14.476220703124994],
            [106.19072265624999, 14.388134765624997],
            [106.16523437500001, 14.372363281250003],
            [106.00839843750003, 14.357177734375],
            [105.97890625000002, 14.343017578125],
            [106.00410156250001, 14.262890625],
            [106.0966796875, 14.127099609374994],
            [106.12470703124995, 14.049121093750045],
            [106.06679687500002, 13.921191406250003],
            [105.90449218750001, 13.924511718749997],
            [105.83144531250002, 13.976611328125003],
            [105.76406250000002, 14.049072265625057],
            [105.73974609375, 14.0849609375],
            [105.53154296874999, 14.156152343749994],
            [105.39267578125003, 14.107080078124994],
            [105.35019531250003, 14.109570312499997],
            [105.28486328125001, 14.161474609374991],
            [105.24570312500003, 14.200537109374991],
            [105.20703125, 14.259375],
            [105.18554687499994, 14.319091796875],
            [105.18330078125001, 14.346240234375003],
            [105.24365234375, 14.367871093749997],
            [105.34218750000002, 14.416699218749997],
            [105.42265624999993, 14.471630859374955],
            [105.4755859375, 14.530126953124991],
            [105.49736328124999, 14.590673828124991],
            [105.50019531250001, 14.661230468749991],
            [105.52304687500003, 14.843310546875003],
            [105.54667968749999, 14.932470703125034],
            [105.53339843750001, 15.041601562499991],
            [105.49042968750001, 15.127587890624994],
            [105.49042968750001, 15.256591796875],
            [105.505859375, 15.319628906250045],
            [105.51318359375, 15.360888671875003],
            [105.57373046875, 15.413232421874994],
            [105.615625, 15.48828125],
            [105.63886718750001, 15.5859375],
            [105.64101562500002, 15.656542968749932],
            [105.6220703125, 15.699951171875],
            [105.56240234375002, 15.741259765625003],
            [105.46201171875003, 15.780419921874994],
            [105.39892578125, 15.829882812500003],
            [105.37324218750001, 15.889697265625045],
            [105.37558593750003, 15.9421875],
            [105.40625, 15.987451171875023],
            [105.33066406250003, 16.037890625],
            [105.14873046874999, 16.093554687500003],
            [105.04716796874999, 16.16025390624999],
            [105.02578125000002, 16.237988281249997],
            [104.94990234375001, 16.33994140624999],
            [104.81933593749994, 16.466064453125057],
            [104.75058593750003, 16.64755859374999],
            [104.74355468750002, 16.884375],
            [104.75898437500001, 17.0771484375],
            [104.81601562499998, 17.30029296875],
            [104.73964843750002, 17.461669921875],
            [104.65585937500003, 17.54672851562499],
            [104.53925781250001, 17.609277343749994],
            [104.428125, 17.698974609375057],
            [104.32265625000002, 17.815820312499994],
            [104.19619140625002, 17.98837890624999],
            [104.04873046875002, 18.216699218749966],
            [103.94960937500002, 18.318994140624994],
            [103.89882812500002, 18.295312500000023],
            [103.79228515624999, 18.31650390625009],
            [103.62968749999999, 18.382568359375],
            [103.48798828124995, 18.41816406250001],
            [103.36699218749999, 18.42333984375],
            [103.28828124999995, 18.408398437499955],
            [103.25175781249999, 18.373486328125125],
            [103.24892578125002, 18.33896484374999],
            [103.27958984374999, 18.304980468750045],
            [103.26318359375, 18.278466796874994],
            [103.19970703125, 18.259472656249997],
            [103.14853515625003, 18.221728515625003],
            [103.09121093750002, 18.138232421875003],
            [103.05136718750003, 18.028515625],
            [102.99140625000001, 17.986230468749994],
            [102.89863281250001, 17.97690429687499],
            [102.80742187499999, 17.945556640625],
            [102.71757812499999, 17.892236328124994],
            [102.67519531250002, 17.851757812499997],
            [102.68007812500002, 17.824121093749994],
            [102.66064453125, 17.81796875],
            [102.61679687500003, 17.83334960937499],
            [102.59609375000002, 17.86962890625],
            [102.59824218750003, 17.9267578125],
            [102.55253906249999, 17.965087890625],
            [102.45878906249999, 17.984619140625],
            [102.35185546874999, 18.045947265625045],
            [102.23164062500001, 18.148974609375045],
            [102.14824218749999, 18.203857421875],
            [102.10146484375002, 18.210644531249997],
            [102.03457031250002, 18.169824218749994],
            [101.94746093750001, 18.081494140624955],
            [101.87548828125, 18.046435546875003],
            [101.81865234374999, 18.064648437499997],
            [101.77480468750002, 18.033398437500125],
            [101.744140625, 17.952685546875045],
            [101.6875, 17.889404296875114],
            [101.56367187500001, 17.820507812499997],
            [101.55507812500002, 17.81235351562499],
            [101.41367187500003, 17.71875],
            [101.29970703125002, 17.625],
            [101.16748046875, 17.4990234375],
            [101.10517578125001, 17.479541015625102],
            [101.04570312499999, 17.509960937499997],
            [100.95585937499999, 17.54111328124999],
            [100.90849609374999, 17.583886718749994],
            [100.9990234375, 17.797167968750003],
            [101.11328125, 18.03354492187499],
            [101.14394531250002, 18.142626953125003],
            [101.14873046874999, 18.22216796875],
            [101.1375, 18.286865234375],
            [101.0927734375, 18.354541015625003],
            [101.05058593749999, 18.40703125],
            [101.04697265625003, 18.441992187500034],
            [101.06044921875002, 18.47900390625],
            [101.10634765625002, 18.53354492187499],
            [101.16552734375, 18.618310546874994],
            [101.22050781249999, 18.792773437500003],
            [101.28632812499995, 18.977148437500034],
            [101.27988281250003, 19.088916015625003],
            [101.2265625, 19.21152343749999],
            [101.19755859374999, 19.327929687500003],
            [101.22080078125003, 19.486621093750003],
            [101.2119140625, 19.54833984375],
            [101.15468750000002, 19.57919921874999],
            [100.96650390625001, 19.610791015624997],
            [100.90605468749993, 19.60537109375008],
            [100.85820312499999, 19.58505859374999],
            [100.80683593750001, 19.541943359374955],
            [100.74394531249999, 19.51474609374999],
            [100.62548828125, 19.49985351562509],
            [100.51357421875002, 19.553466796875],
            [100.42011718750001, 19.644482421874997],
            [100.39765625000001, 19.756103515625],
            [100.46621093750002, 19.888916015625],
            [100.51455078125002, 19.996337890625],
            [100.54306640625003, 20.088671875],
            [100.53994140625002, 20.132373046875003],
            [100.51953125, 20.177929687499997],
            [100.49160156250002, 20.18408203125],
            [100.43154296875002, 20.24072265625],
            [100.37314453125003, 20.340380859375003],
            [100.31796875000003, 20.385888671874994],
            [100.26601562500002, 20.37729492187499],
            [100.21806640624999, 20.339599609375],
            [100.17412109374999, 20.272753906250003],
            [100.13974609375003, 20.245410156250003],
            [100.11494140625001, 20.25766601562499],
            [100.12246093750002, 20.316650390625],
            [100.12968749999999, 20.372216796874994],
            [100.18388671874999, 20.589111328125],
            [100.24931640624999, 20.730273437500003],
            [100.32607421875002, 20.795703125],
            [100.40742187499995, 20.823242187500057],
            [100.49335937500001, 20.81298828125],
            [100.56513671875001, 20.825097656249994],
            [100.62294921875002, 20.859570312499997],
            [100.61767578125, 20.87924804687499],
            [100.54931640625, 20.884228515624997],
            [100.52226562499999, 20.921923828125003],
            [100.5361328125, 20.992382812499997],
            [100.56660156250001, 21.03818359374999],
            [100.61367187500002, 21.059326171875],
            [100.6591796875, 21.13037109375],
            [100.703125, 21.251367187499994],
            [100.75664062499999, 21.312646484374994],
            [100.81953125000001, 21.314208984375],
            [100.92753906249999, 21.3662109375],
            [101.08037109374999, 21.468652343749994],
            [101.13886718750001, 21.56748046874999],
            [101.19667968750002, 21.52207031249999],
            [101.17539062500003, 21.407519531250003],
            [101.20556640625, 21.38330078125],
            [101.21992187500001, 21.342431640624994],
            [101.21181640625002, 21.278222656249994],
            [101.22441406249999, 21.22373046874999],
            [101.24785156249993, 21.197314453125045],
            [101.28144531250001, 21.184130859375003],
            [101.44355468750001, 21.23081054687499],
            [101.54238281250002, 21.234277343749994],
            [101.58388671875002, 21.203564453125068],
            [101.62167968750003, 21.18442382812499],
            [101.66855468750003, 21.169628906249997],
            [101.70478515625001, 21.150146484375],
            [101.728125, 21.156396484374994],
            [101.78349609374999, 21.204150390625045],
            [101.80058593749999, 21.212597656249997],
            [101.80205078124999, 21.235986328124994],
            [101.76308593750002, 21.27890625],
            [101.72294921874999, 21.31494140625],
            [101.72421875000003, 21.39501953125],
            [101.74345703124999, 21.533837890624994],
            [101.74726562500001, 21.605761718750003],
            [101.74394531249999, 21.777978515624994],
            [101.73652343750001, 21.826513671874977],
            [101.69960937500002, 21.882470703124994],
            [101.60292968749997, 21.98969726562501],
            [101.57578125000003, 22.05527343749999],
            [101.56025390625001, 22.120898437500102],
            [101.56181640624999, 22.162402343750045],
            [101.53730468750001, 22.209863281249994],
            [101.52451171874998, 22.253662109375],
            [101.56787109375, 22.2763671875],
            [101.61992187499999, 22.327441406250003],
            [101.64619140625001, 22.405419921874994],
            [101.67148437500003, 22.462304687499994],
            [101.70751953125, 22.486572265625],
            [101.73876953125, 22.495263671874994],
            [101.75996093750001, 22.490332031250034],
            [101.841796875, 22.388476562500003],
            [101.94541015625003, 22.439404296874997],
            [102.0244140625, 22.439208984375],
            [102.09150390625001, 22.412255859374994],
            [102.12744140625, 22.379199218750003],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 4,
        sovereignt: "Kyrgyzstan",
        sov_a3: "KGZ",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Kyrgyzstan",
        adm0_a3: "KGZ",
        geou_dif: 0,
        geounit: "Kyrgyzstan",
        gu_a3: "KGZ",
        su_dif: 0,
        subunit: "Kyrgyzstan",
        su_a3: "KGZ",
        brk_diff: 0,
        name: "Kyrgyzstan",
        name_long: "Kyrgyzstan",
        brk_a3: "KGZ",
        brk_name: "Kyrgyzstan",
        brk_group: null,
        abbrev: "Kgz.",
        postal: "KG",
        formal_en: "Kyrgyz Republic",
        formal_fr: null,
        name_ciawf: "Kyrgyzstan",
        note_adm0: null,
        note_brk: null,
        name_sort: "Kyrgyz Republic",
        name_alt: null,
        mapcolor7: 5,
        mapcolor8: 7,
        mapcolor9: 7,
        mapcolor13: 6,
        pop_est: 6456900,
        pop_rank: 13,
        pop_year: 2019,
        gdp_md: 8454,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "5. Low income",
        fips_10: "KG",
        iso_a2: "KG",
        iso_a2_eh: "KG",
        iso_a3: "KGZ",
        iso_a3_eh: "KGZ",
        iso_n3: "417",
        iso_n3_eh: "417",
        un_a3: "417",
        wb_a2: "KG",
        wb_a3: "KGZ",
        woe_id: 23424864,
        woe_id_eh: 23424864,
        woe_note: "Exact WOE match as country",
        adm0_iso: "KGZ",
        adm0_diff: null,
        adm0_tlc: "KGZ",
        adm0_a3_us: "KGZ",
        adm0_a3_fr: "KGZ",
        adm0_a3_ru: "KGZ",
        adm0_a3_es: "KGZ",
        adm0_a3_cn: "KGZ",
        adm0_a3_tw: "KGZ",
        adm0_a3_in: "KGZ",
        adm0_a3_np: "KGZ",
        adm0_a3_pk: "KGZ",
        adm0_a3_de: "KGZ",
        adm0_a3_gb: "KGZ",
        adm0_a3_br: "KGZ",
        adm0_a3_il: "KGZ",
        adm0_a3_ps: "KGZ",
        adm0_a3_sa: "KGZ",
        adm0_a3_eg: "KGZ",
        adm0_a3_ma: "KGZ",
        adm0_a3_pt: "KGZ",
        adm0_a3_ar: "KGZ",
        adm0_a3_jp: "KGZ",
        adm0_a3_ko: "KGZ",
        adm0_a3_vn: "KGZ",
        adm0_a3_tr: "KGZ",
        adm0_a3_id: "KGZ",
        adm0_a3_pl: "KGZ",
        adm0_a3_gr: "KGZ",
        adm0_a3_it: "KGZ",
        adm0_a3_nl: "KGZ",
        adm0_a3_se: "KGZ",
        adm0_a3_bd: "KGZ",
        adm0_a3_ua: "KGZ",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Central Asia",
        region_wb: "Europe & Central Asia",
        name_len: 10,
        long_len: 10,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: 74.532637,
        label_y: 41.66854,
        ne_id: 1159320977,
        wikidataid: "Q813",
        name_ar: "قيرغيزستان",
        name_bn: "কিরগিজস্তান",
        name_de: "Kirgisistan",
        name_en: "Kyrgyzstan",
        name_es: "Kirguistán",
        name_fa: "قرقیزستان",
        name_fr: "Kirghizistan",
        name_el: "Κιργιζία",
        name_he: "קירגיזסטן",
        name_hi: "किर्गिज़स्तान",
        name_hu: "Kirgizisztán",
        name_id: "Kirgizstan",
        name_it: "Kirghizistan",
        name_ja: "キルギス",
        name_ko: "키르기스스탄",
        name_nl: "Kirgizië",
        name_pl: "Kirgistan",
        name_pt: "Quirguistão",
        name_ru: "Киргизия",
        name_sv: "Kirgizistan",
        name_tr: "Kırgızistan",
        name_uk: "Киргизстан",
        name_ur: "کرغیزستان",
        name_vi: "Kyrgyzstan",
        name_zh: "吉尔吉斯斯坦",
        name_zht: "吉爾吉斯",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "KGZ.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [70.9580078125, 40.2388671875],
            [70.990625, 40.2548828125],
            [71.09453125000002, 40.271240234375],
            [71.3046875, 40.286914062499996],
            [71.37617187500001, 40.275195312499996],
            [71.45742187500002, 40.241992187499996],
            [71.52041015625002, 40.208984375],
            [71.58046875000002, 40.210253906249996],
            [71.6298828125, 40.217138671875],
            [71.65087890625, 40.2080078125],
            [71.66679687500002, 40.178613281249994],
            [71.69248046875, 40.15234375],
            [71.77265625000001, 40.188037109374996],
            [71.84541015625001, 40.234326171875],
            [71.90273437500002, 40.240966796875],
            [71.9556640625, 40.25859375],
            [71.97109375000002, 40.289501953125],
            [72.01259765625002, 40.340722656249994],
            [72.13125, 40.438623046874994],
            [72.19287109375, 40.454443359375],
            [72.23281250000002, 40.454394531249996],
            [72.23466796875002, 40.438623046874994],
            [72.25400390625, 40.42421875],
            [72.35771484375002, 40.401660156249996],
            [72.3892578125, 40.427392578124994],
            [72.40595703125001, 40.4630859375],
            [72.3697265625, 40.5197265625],
            [72.36904296875002, 40.54345703125],
            [72.38261718750002, 40.56513671875],
            [72.40205078125001, 40.578076171875],
            [72.56748046875, 40.524365234375],
            [72.6041015625, 40.525439453124996],
            [72.67958984375002, 40.555615234375],
            [72.74882812500002, 40.608691406249996],
            [72.77382812500002, 40.650390625],
            [73.112890625, 40.786035156249994],
            [73.13691406250001, 40.81064453125],
            [73.13212890625002, 40.828515625],
            [72.99003906250002, 40.860107421875],
            [72.92597656250001, 40.842431640624994],
            [72.86660156250002, 40.842333984374996],
            [72.83095703125002, 40.862158203125],
            [72.65830078125, 40.869921875],
            [72.62041015625002, 40.8837890625],
            [72.50595703125, 40.981689453125],
            [72.42734375, 41.018945312499994],
            [72.36406250000002, 41.04345703125],
            [72.294921875, 41.039941406249994],
            [72.21308593750001, 41.0142578125],
            [72.18730468750002, 41.025927734374996],
            [72.1806640625, 41.066845703125],
            [72.18095703125002, 41.118457031249996],
            [72.16425781250001, 41.173730468749994],
            [72.11542968750001, 41.186572265624996],
            [72.05244140625001, 41.16474609375],
            [71.95849609375, 41.187060546874996],
            [71.87861328125001, 41.19501953125],
            [71.8580078125, 41.311376953125],
            [71.82578125, 41.36103515625],
            [71.79248046875, 41.413134765624996],
            [71.75771484375002, 41.428027343749996],
            [71.70068359375, 41.454003906249994],
            [71.697265625, 41.515576171875],
            [71.68515625, 41.533007812499996],
            [71.66494140625002, 41.5412109375],
            [71.6375, 41.5341796875],
            [71.60224609375001, 41.503271484375],
            [71.61962890625, 41.435449218749994],
            [71.60625, 41.367431640625],
            [71.585546875, 41.333251953125],
            [71.54560546875001, 41.308056640625],
            [71.5, 41.307470703125],
            [71.4208984375, 41.34189453125],
            [71.40839843750001, 41.136035156249996],
            [71.39306640625, 41.123388671875],
            [71.298828125, 41.152490234374994],
            [71.22343750000002, 41.139941406249996],
            [71.11074218750002, 41.152636718749996],
            [71.0259765625, 41.186572265624996],
            [70.96259765625001, 41.19599609375],
            [70.86044921875, 41.224902343749996],
            [70.78242187500001, 41.2625],
            [70.734375, 41.400537109374994],
            [70.68886718750002, 41.4498046875],
            [70.64589843750002, 41.460351562499994],
            [70.47138671875001, 41.412646484374996],
            [70.4078125, 41.449560546875],
            [70.2900390625, 41.496826171875],
            [70.20087890625001, 41.514453125],
            [70.17695312500001, 41.539990234375],
            [70.18095703125002, 41.571435546874994],
            [70.45498046875002, 41.725048828125],
            [70.56289062500002, 41.830810546875],
            [70.630859375, 41.87548828125],
            [70.72773437500001, 41.905224609375],
            [70.8033203125, 41.92265625],
            [70.84189453125, 42.01962890625],
            [70.85664062500001, 42.030810546874996],
            [70.91035156250001, 42.037988281249994],
            [71.0322265625, 42.077783203124994],
            [71.228515625, 42.162890625],
            [71.23232421875002, 42.186279296875],
            [71.21269531250002, 42.206445312499994],
            [71.12998046875, 42.25],
            [71.03603515625002, 42.28466796875],
            [70.97900390625, 42.266552734375],
            [70.94677734375, 42.248681640624994],
            [70.89287109375002, 42.293701171875],
            [70.89287109375002, 42.339990234374994],
            [70.95234375000001, 42.419384765625],
            [71.001953125, 42.45908203125],
            [71.02275390625002, 42.535449218749996],
            [71.09355468750002, 42.5865234375],
            [71.16738281250002, 42.667431640625],
            [71.25664062500002, 42.733544921874994],
            [71.42207031250001, 42.783154296875],
            [71.5142578125, 42.766943359375],
            [71.60078125000001, 42.778662109375],
            [71.73476562500002, 42.818896484374996],
            [71.76054687500002, 42.821484375],
            [71.81679687500002, 42.822167968749994],
            [72.16181640625001, 42.760693359375],
            [72.27578125000002, 42.757666015625],
            [72.54316406250001, 42.677734375],
            [72.66611328125, 42.660400390625],
            [72.7529296875, 42.637890625],
            [72.79238281250002, 42.603466796875],
            [72.855078125, 42.5611328125],
            [73.19082031250002, 42.52685546875],
            [73.28291015625001, 42.5041015625],
            [73.316015625, 42.4669921875],
            [73.41162109375, 42.419775390625],
            [73.49296875000002, 42.409033203125],
            [73.421875, 42.593505859375],
            [73.4501953125, 42.703027343749994],
            [73.55625, 43.002783203125],
            [73.61201171875001, 43.047900390624996],
            [73.71855468750002, 43.087890625],
            [73.88603515625002, 43.132568359375],
            [73.94921875, 43.19501953125],
            [74.08623046875002, 43.188623046874994],
            [74.14589843750002, 43.194091796875],
            [74.18681640625002, 43.2052734375],
            [74.20908203125, 43.240380859374994],
            [74.3638671875, 43.179443359375],
            [74.62226562500001, 43.056201171874996],
            [74.81757812500001, 42.978173828124994],
            [75.04765625000002, 42.90439453125],
            [75.3662109375, 42.836962890624996],
            [75.63564453125002, 42.814599609374994],
            [75.68173828125, 42.83046875],
            [75.78955078125, 42.932910156249996],
            [75.84033203125, 42.9375],
            [75.9322265625, 42.928515625],
            [76.21816406250002, 42.923730468749994],
            [76.50917968750002, 42.918896484375],
            [76.646484375, 42.92880859375],
            [76.94404296875001, 42.971484375],
            [76.98808593749999, 42.973583984375],
            [77.05732421875001, 42.970654296875],
            [77.23554687500001, 42.912646484374996],
            [77.36855468750002, 42.904443359374994],
            [77.45927734374999, 42.904736328125],
            [77.51220703125, 42.900048828124994],
            [77.62246093750002, 42.902246093749994],
            [77.80166015625002, 42.89521484375],
            [78.02314453125001, 42.85751953125],
            [78.2900390625, 42.86435546875],
            [78.3759765625, 42.871484375],
            [78.52421874999999, 42.864648437499994],
            [78.64228515625001, 42.8287109375],
            [78.79150390625, 42.790820312499996],
            [78.88515625000002, 42.77490234375],
            [78.94794921875001, 42.76669921875],
            [79.05986328124999, 42.763818359374994],
            [79.12666015625001, 42.775732421875],
            [79.16484374999999, 42.759033203125],
            [79.20302734375002, 42.666015625],
            [79.29550781250003, 42.604833984375],
            [79.36777343750003, 42.547216796875],
            [79.42822265625, 42.48349609375],
            [79.49013671875002, 42.457568359374996],
            [79.59843749999999, 42.456640625],
            [79.80341796875001, 42.4384765625],
            [79.92109375000001, 42.413134765624996],
            [80.0712890625, 42.302978515625],
            [80.209375, 42.1900390625],
            [80.22919921875001, 42.129833984375],
            [80.24619140625003, 42.059814453125],
            [80.23515624999999, 42.04345703125],
            [80.21621093750002, 42.032421875],
            [79.90966796875, 42.014990234375],
            [79.84042968750003, 41.995751953124994],
            [79.76611328125, 41.898876953125],
            [79.50390625, 41.82099609375],
            [79.35439453125002, 41.781054687499996],
            [79.29355468750003, 41.7828125],
            [79.1484375, 41.719140625],
            [78.74257812500002, 41.56005859375],
            [78.54316406250001, 41.4595703125],
            [78.44287109375, 41.417529296874996],
            [78.36240234375003, 41.371630859374996],
            [78.34882812500001, 41.3251953125],
            [78.34628906250003, 41.2814453125],
            [78.12343750000002, 41.075634765625],
            [77.95644531250002, 41.050683593749994],
            [77.81523437499999, 41.055615234375],
            [77.71933593750003, 41.024316406249994],
            [77.58173828125001, 40.9927734375],
            [77.28398437499999, 41.01435546875],
            [77.18203125000002, 41.0107421875],
            [76.98662109374999, 41.03916015625],
            [76.90771484375, 41.024169921875],
            [76.82402343749999, 40.982324218749994],
            [76.70839843750002, 40.818115234375],
            [76.6611328125, 40.779638671875],
            [76.63984375000001, 40.742236328124996],
            [76.62216796875003, 40.662353515625],
            [76.57792968749999, 40.577880859375],
            [76.52089843750002, 40.51123046875],
            [76.48017578125001, 40.449511718749996],
            [76.39638671875002, 40.389794921874994],
            [76.31855468750001, 40.35224609375],
            [76.25830078125, 40.43076171875],
            [76.2060546875, 40.4083984375],
            [76.15664062500002, 40.37646484375],
            [76.06230468749999, 40.387548828125],
            [76.00429687500002, 40.371435546875],
            [75.87197265625002, 40.30322265625],
            [75.67714843750002, 40.305810546874994],
            [75.65595703125001, 40.329248046874994],
            [75.61738281250001, 40.5166015625],
            [75.58349609375, 40.605322265625],
            [75.55556640625002, 40.6251953125],
            [75.52080078125002, 40.6275390625],
            [75.24101562500002, 40.480273437499996],
            [75.111328125, 40.4541015625],
            [75.0044921875, 40.449511718749996],
            [74.865625, 40.493505859375],
            [74.83515625000001, 40.4826171875],
            [74.8111328125, 40.458789062499996],
            [74.80126953125, 40.428515625],
            [74.841796875, 40.344970703125],
            [74.83046875000002, 40.328515625],
            [74.76777343750001, 40.329882812499996],
            [74.67988281250001, 40.310595703124996],
            [74.61308593750002, 40.27216796875],
            [74.41191406250002, 40.13720703125],
            [74.24267578125, 40.092041015625],
            [74.08515625000001, 40.07431640625],
            [74.0205078125, 40.059375],
            [73.99160156250002, 40.043115234374994],
            [73.93876953125002, 39.978808593749996],
            [73.88457031250002, 39.8779296875],
            [73.85625, 39.828662109374996],
            [73.83535156250002, 39.800146484375],
            [73.83974609375002, 39.762841796874994],
            [73.88251953125001, 39.71455078125],
            [73.9146484375, 39.606494140624996],
            [73.90712890625002, 39.578515625],
            [73.87275390625001, 39.53330078125],
            [73.82294921875001, 39.48896484375],
            [73.71572265625002, 39.462255859375],
            [73.63164062500002, 39.448876953124994],
            [73.57558593750002, 39.4576171875],
            [73.47041015625001, 39.460595703124994],
            [73.38740234375001, 39.442724609375],
            [73.33613281250001, 39.412353515625],
            [73.2349609375, 39.374560546874996],
            [73.10927734375002, 39.3619140625],
            [72.94941406250001, 39.357080078124994],
            [72.87246093750002, 39.360400390624996],
            [72.63994140625002, 39.385986328125],
            [72.56337890625002, 39.377197265625],
            [72.490234375, 39.357373046875],
            [72.35771484375002, 39.336865234375],
            [72.28720703125, 39.273730468749996],
            [72.24980468750002, 39.215673828125],
            [72.22998046875, 39.20751953125],
            [72.14736328125002, 39.2607421875],
            [72.08417968750001, 39.31064453125],
            [72.04277343750002, 39.3521484375],
            [71.99101562500002, 39.350927734375],
            [71.80595703125002, 39.2755859375],
            [71.77861328125002, 39.277978515624994],
            [71.72568359375, 39.306591796875],
            [71.7353515625, 39.377734375],
            [71.73222656250002, 39.422998046874994],
            [71.67265625000002, 39.447070312499996],
            [71.54628906250002, 39.453076171875],
            [71.50332031250002, 39.478808593749996],
            [71.505859375, 39.51708984375],
            [71.51738281250002, 39.553857421874994],
            [71.50302734375, 39.582177734374994],
            [71.4703125, 39.603662109374994],
            [71.404296875, 39.5978515625],
            [71.32851562500002, 39.568701171875],
            [71.27285156250002, 39.535302734374994],
            [71.202734375, 39.519824218749996],
            [71.11806640625002, 39.513574218749994],
            [71.0650390625, 39.493408203125],
            [71.0048828125, 39.411865234375],
            [70.79931640625, 39.3947265625],
            [70.73310546875001, 39.41328125],
            [70.67861328125002, 39.4712890625],
            [70.60781250000002, 39.564404296875],
            [70.56796875, 39.57587890625],
            [70.50117187500001, 39.587353515625],
            [70.39208984375, 39.581884765625],
            [70.24482421875001, 39.542626953124994],
            [70.20927734375002, 39.575],
            [70.17109375000001, 39.5841796875],
            [70.13681640625, 39.557568359375],
            [70.10166015625, 39.560595703124996],
            [69.95595703125002, 39.553076171875],
            [69.7720703125, 39.55673828125],
            [69.6669921875, 39.57490234375],
            [69.59882812500001, 39.573779296874996],
            [69.46328125000002, 39.532080078125],
            [69.39150390625002, 39.532470703125],
            [69.29765625000002, 39.524804687499994],
            [69.2802734375, 39.665869140625],
            [69.2291015625, 39.761083984375],
            [69.2447265625, 39.827099609375],
            [69.27880859375, 39.917773437499996],
            [69.3072265625, 39.968554687499996],
            [69.36542968750001, 39.947070312499996],
            [69.43193359375002, 39.909765625],
            [69.47626953125001, 39.919726562499996],
            [69.487890625, 39.950439453125],
            [69.47099609375002, 39.990625],
            [69.46875, 40.020751953125],
            [69.49365234375, 40.060351562499996],
            [69.5302734375, 40.097314453124994],
            [69.765234375, 40.158007812499996],
            [69.966796875, 40.20224609375],
            [70.07148437500001, 40.172753906249994],
            [70.2744140625, 40.104833984375],
            [70.37890625, 40.069873046874996],
            [70.45136718750001, 40.04921875],
            [70.51513671875, 39.94990234375],
            [70.55683593750001, 39.954492187499994],
            [70.59921875, 39.974511718749994],
            [70.62412109375, 39.998974609375],
            [70.64433593750002, 40.083447265625],
            [70.73857421875002, 40.131152343749996],
            [70.94638671875, 40.18759765625],
            [70.9609375, 40.220654296875],
            [70.9580078125, 40.2388671875],
          ],
          [
            [71.20615234375, 39.892578125],
            [71.215625, 39.906787109374996],
            [71.179296875, 39.979833984375],
            [71.22871093750001, 40.048144531249996],
            [71.13027343750002, 40.05966796875],
            [71.08037109375002, 40.079882812499996],
            [71.02412109375001, 40.149169921875],
            [71.00546875, 40.152294921875],
            [70.97626953125001, 40.133251953125],
            [70.96064453125001, 40.08798828125],
            [70.97441406250002, 40.038867187499996],
            [71.01445312500002, 40.005761718749994],
            [71.041015625, 39.994921875],
            [71.04482421875002, 39.992529296875],
            [71.04365234375001, 39.976318359375],
            [71.01171875, 39.8951171875],
            [71.06425781250002, 39.884912109374994],
            [71.15625, 39.883447265624994],
            [71.20615234375, 39.892578125],
          ],
          [
            [71.77968750000002, 39.950244140624996],
            [71.78994140625002, 39.9953125],
            [71.76533203125001, 39.99326171875],
            [71.73652343750001, 39.98095703125],
            [71.68125, 39.968652343749994],
            [71.6689453125, 39.94609375],
            [71.70585937500002, 39.917431640625],
            [71.7529296875, 39.907128906249994],
            [71.77968750000002, 39.950244140624996],
          ],
          [
            [70.70166015625, 39.82529296875],
            [70.6982421875, 39.845849609374994],
            [70.66416015625, 39.85546875],
            [70.56708984375001, 39.866601562499994],
            [70.49775390625001, 39.882421875],
            [70.48281250000002, 39.882714843749994],
            [70.4892578125, 39.863037109375],
            [70.51865234375, 39.828173828124996],
            [70.5595703125, 39.790917968749994],
            [70.61210937500002, 39.786767578124994],
            [70.70166015625, 39.82529296875],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 6,
        sovereignt: "Armenia",
        sov_a3: "ARM",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Armenia",
        adm0_a3: "ARM",
        geou_dif: 0,
        geounit: "Armenia",
        gu_a3: "ARM",
        su_dif: 0,
        subunit: "Armenia",
        su_a3: "ARM",
        brk_diff: 0,
        name: "Armenia",
        name_long: "Armenia",
        brk_a3: "ARM",
        brk_name: "Armenia",
        brk_group: null,
        abbrev: "Arm.",
        postal: "ARM",
        formal_en: "Republic of Armenia",
        formal_fr: null,
        name_ciawf: "Armenia",
        note_adm0: null,
        note_brk: null,
        name_sort: "Armenia",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 1,
        mapcolor9: 2,
        mapcolor13: 10,
        pop_est: 2957731,
        pop_rank: 12,
        pop_year: 2019,
        gdp_md: 13672,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        fips_10: "AM",
        iso_a2: "AM",
        iso_a2_eh: "AM",
        iso_a3: "ARM",
        iso_a3_eh: "ARM",
        iso_n3: "051",
        iso_n3_eh: "051",
        un_a3: "051",
        wb_a2: "AM",
        wb_a3: "ARM",
        woe_id: 23424743,
        woe_id_eh: 23424743,
        woe_note: "Exact WOE match as country",
        adm0_iso: "ARM",
        adm0_diff: null,
        adm0_tlc: "ARM",
        adm0_a3_us: "ARM",
        adm0_a3_fr: "ARM",
        adm0_a3_ru: "ARM",
        adm0_a3_es: "ARM",
        adm0_a3_cn: "ARM",
        adm0_a3_tw: "ARM",
        adm0_a3_in: "ARM",
        adm0_a3_np: "ARM",
        adm0_a3_pk: "ARM",
        adm0_a3_de: "ARM",
        adm0_a3_gb: "ARM",
        adm0_a3_br: "ARM",
        adm0_a3_il: "ARM",
        adm0_a3_ps: "ARM",
        adm0_a3_sa: "ARM",
        adm0_a3_eg: "ARM",
        adm0_a3_ma: "ARM",
        adm0_a3_pt: "ARM",
        adm0_a3_ar: "ARM",
        adm0_a3_jp: "ARM",
        adm0_a3_ko: "ARM",
        adm0_a3_vn: "ARM",
        adm0_a3_tr: "ARM",
        adm0_a3_id: "ARM",
        adm0_a3_pl: "ARM",
        adm0_a3_gr: "ARM",
        adm0_a3_it: "ARM",
        adm0_a3_nl: "ARM",
        adm0_a3_se: "ARM",
        adm0_a3_bd: "ARM",
        adm0_a3_ua: "ARM",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Western Asia",
        region_wb: "Europe & Central Asia",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 5,
        max_label: 10,
        label_x: 44.800564,
        label_y: 40.459077,
        ne_id: 1159320333,
        wikidataid: "Q399",
        name_ar: "أرمينيا",
        name_bn: "আর্মেনিয়া",
        name_de: "Armenien",
        name_en: "Armenia",
        name_es: "Armenia",
        name_fa: "ارمنستان",
        name_fr: "Arménie",
        name_el: "Αρμενία",
        name_he: "ארמניה",
        name_hi: "आर्मीनिया",
        name_hu: "Örményország",
        name_id: "Armenia",
        name_it: "Armenia",
        name_ja: "アルメニア",
        name_ko: "아르메니아",
        name_nl: "Armenië",
        name_pl: "Armenia",
        name_pt: "Arménia",
        name_ru: "Армения",
        name_sv: "Armenien",
        name_tr: "Ermenistan",
        name_uk: "Вірменія",
        name_ur: "آرمینیا",
        name_vi: "Armenia",
        name_zh: "亚美尼亚",
        name_zht: "亞美尼亞",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "ARM.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [44.76826171875001, 39.703515625],
              [44.73378906250002, 39.746484375],
              [44.56044921875002, 39.887597656249994],
              [44.39960937500001, 39.995751953124994],
              [44.28925781250001, 40.040380859375],
              [44.17802734375002, 40.0357421875],
              [44.00537109375, 40.014111328125],
              [43.941992187500006, 40.02314453125],
              [43.79169921875001, 40.070263671875],
              [43.66621093750001, 40.126367187499994],
              [43.68330078125001, 40.149658203125],
              [43.70986328125002, 40.16650390625],
              [43.678125, 40.239306640624996],
              [43.6083984375, 40.356591796874994],
              [43.615820312500006, 40.393310546875],
              [43.59375, 40.44404296875],
              [43.5693359375, 40.482373046875],
              [43.66787109375002, 40.574072265625],
              [43.712890625, 40.647753906249996],
              [43.72265625, 40.71953125],
              [43.69648437500001, 40.794140625],
              [43.63164062500002, 40.929003906249996],
              [43.59169921875002, 40.968212890625],
              [43.51748046875002, 41.004833984375],
              [43.45527343750001, 41.064697265625],
              [43.439453125, 41.10712890625],
              [43.49199218750002, 41.115527343749996],
              [43.64501953125, 41.116650390625],
              [43.79316406250001, 41.131103515625],
              [43.9091796875, 41.158984375],
              [44.077246093750006, 41.182519531249994],
              [44.146484375, 41.203369140625],
              [44.22734375000002, 41.213330078125],
              [44.47304687500002, 41.191015625],
              [44.56484375000002, 41.208203125],
              [44.84140625, 41.211376953125],
              [44.84853515625002, 41.220166015625],
              [44.81093750000002, 41.248583984374996],
              [44.81132812500002, 41.259375],
              [44.97587890625002, 41.277490234374994],
              [45.00136718750002, 41.290966796875],
              [45.02294921875, 41.245703125],
              [45.08476562500002, 41.195458984374994],
              [45.15234375, 41.175146484375],
              [45.188574218750006, 41.147412109375],
              [45.19023437500002, 41.126367187499994],
              [45.07070312500002, 41.100830078125],
              [45.062597656250006, 41.088134765625],
              [45.07050781250001, 41.075585937499994],
              [45.106054687500006, 41.0693359375],
              [45.2734375, 41.00625],
              [45.36894531250002, 41.0048828125],
              [45.41914062500001, 40.985693359375],
              [45.44423828125002, 40.947998046875],
              [45.524023437500006, 40.896728515625],
              [45.5875, 40.846923828125],
              [45.59140625, 40.829736328124994],
              [45.57939453125002, 40.804492187499996],
              [45.4013671875, 40.70712890625],
              [45.37890625, 40.673583984375],
              [45.37617187500001, 40.638085937499994],
              [45.45439453125002, 40.532373046874994],
              [45.56953125000001, 40.416845703125],
              [45.73574218750002, 40.3291015625],
              [45.96464843750002, 40.233789062499994],
              [45.96757812500002, 40.1748046875],
              [45.93125, 40.1046875],
              [45.90009765625001, 40.057080078125],
              [45.88593750000001, 40.024853515625],
              [45.85810546875001, 40.011279296874996],
              [45.63017578125002, 40.014208984374996],
              [45.59599609375002, 40.002832031249994],
              [45.58095703125002, 39.989013671875],
              [45.57978515625001, 39.9775390625],
              [45.66181640625001, 39.956201171874994],
              [45.789648437500006, 39.881103515625],
              [45.86318359375002, 39.808349609375],
              [45.93994140625, 39.7765625],
              [46.02587890625, 39.718554687499996],
              [46.094824218750006, 39.664453125],
              [46.20205078125002, 39.594482421875],
              [46.32167968750002, 39.617431640625],
              [46.4814453125, 39.55517578125],
              [46.48808593750002, 39.512841796874994],
              [46.478125, 39.47509765625],
              [46.37763671875001, 39.433886718749996],
              [46.36513671875002, 39.416796875],
              [46.365234375, 39.402490234374994],
              [46.37841796875, 39.382275390625],
              [46.43730468750002, 39.348535156249994],
              [46.50664062500002, 39.29853515625],
              [46.58476562500002, 39.223681640624996],
              [46.55, 39.201416015625],
              [46.477148437500006, 39.198193359375],
              [46.42031250000002, 39.207373046875],
              [46.40029296875002, 39.1921875],
              [46.401464843750006, 39.16767578125],
              [46.47539062500002, 39.110888671874996],
              [46.48984375, 39.06943359375],
              [46.48671875000002, 38.997460937499994],
              [46.490625, 38.906689453125],
              [46.31777343750002, 38.912646484374996],
              [46.17011718750001, 38.869042968749994],
              [46.11445312500001, 38.877783203125],
              [46.07744140625002, 38.954882812499996],
              [46.0458984375, 39.017529296875],
              [45.95185546875001, 39.178125],
              [45.97744140625002, 39.243896484375],
              [45.925, 39.28193359375],
              [45.79863281250002, 39.3501953125],
              [45.76630859375001, 39.378466796874996],
              [45.7841796875, 39.417236328125],
              [45.796484375, 39.488134765625],
              [45.78447265625002, 39.54560546875],
              [45.75048828125, 39.562939453125],
              [45.68740234375002, 39.5640625],
              [45.61074218750002, 39.5498046875],
              [45.45683593750002, 39.494482421875],
              [45.34990234375002, 39.5298828125],
              [45.28828125000001, 39.565576171874994],
              [45.252539062500006, 39.595458984375],
              [45.17255859375001, 39.570605468749996],
              [45.15283203125, 39.582666015624994],
              [45.14863281250001, 39.656591796875],
              [45.124609375, 39.696337890624996],
              [45.07646484375002, 39.742822265624994],
              [45.03164062500002, 39.76513671875],
              [44.8671875, 39.719140625],
              [44.76826171875001, 39.703515625],
            ],
            [
              [45.02363281250001, 41.027246093749994],
              [45.02871093750002, 41.053857421874994],
              [45.02109375, 41.077978515625],
              [44.99433593750001, 41.085595703124994],
              [44.96142578125, 41.079248046874994],
              [44.95888671875002, 41.052636718749994],
              [44.96904296875002, 41.027246093749994],
              [45.002050781250006, 41.0158203125],
              [45.02363281250001, 41.027246093749994],
            ],
          ],
          [
            [
              [45.55234375, 40.616064453125],
              [45.51435546875001, 40.599560546875],
              [45.47880859375002, 40.606982421874996],
              [45.47880859375002, 40.648339843749994],
              [45.504492187500006, 40.66484375],
              [45.5341796875, 40.664013671875],
              [45.56230468750002, 40.649169921875],
              [45.55234375, 40.616064453125],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 3,
        labelrank: 2,
        sovereignt: "Iran",
        sov_a3: "IRN",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Iran",
        adm0_a3: "IRN",
        geou_dif: 0,
        geounit: "Iran",
        gu_a3: "IRN",
        su_dif: 0,
        subunit: "Iran",
        su_a3: "IRN",
        brk_diff: 0,
        name: "Iran",
        name_long: "Iran",
        brk_a3: "IRN",
        brk_name: "Iran",
        brk_group: null,
        abbrev: "Iran",
        postal: "IRN",
        formal_en: "Islamic Republic of Iran",
        formal_fr: null,
        name_ciawf: "Iran",
        note_adm0: null,
        note_brk: null,
        name_sort: "Iran, Islamic Rep.",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 3,
        mapcolor9: 4,
        mapcolor13: 13,
        pop_est: 82913906,
        pop_rank: 16,
        pop_year: 2019,
        gdp_md: 453996,
        gdp_year: 2018,
        economy: "5. Emerging region: G20",
        income_grp: "3. Upper middle income",
        fips_10: "IR",
        iso_a2: "IR",
        iso_a2_eh: "IR",
        iso_a3: "IRN",
        iso_a3_eh: "IRN",
        iso_n3: "364",
        iso_n3_eh: "364",
        un_a3: "364",
        wb_a2: "IR",
        wb_a3: "IRN",
        woe_id: 23424851,
        woe_id_eh: 23424851,
        woe_note: "Exact WOE match as country",
        adm0_iso: "IRN",
        adm0_diff: null,
        adm0_tlc: "IRN",
        adm0_a3_us: "IRN",
        adm0_a3_fr: "IRN",
        adm0_a3_ru: "IRN",
        adm0_a3_es: "IRN",
        adm0_a3_cn: "IRN",
        adm0_a3_tw: "IRN",
        adm0_a3_in: "IRN",
        adm0_a3_np: "IRN",
        adm0_a3_pk: "IRN",
        adm0_a3_de: "IRN",
        adm0_a3_gb: "IRN",
        adm0_a3_br: "IRN",
        adm0_a3_il: "IRN",
        adm0_a3_ps: "IRN",
        adm0_a3_sa: "IRN",
        adm0_a3_eg: "IRN",
        adm0_a3_ma: "IRN",
        adm0_a3_pt: "IRN",
        adm0_a3_ar: "IRN",
        adm0_a3_jp: "IRN",
        adm0_a3_ko: "IRN",
        adm0_a3_vn: "IRN",
        adm0_a3_tr: "IRN",
        adm0_a3_id: "IRN",
        adm0_a3_pl: "IRN",
        adm0_a3_gr: "IRN",
        adm0_a3_it: "IRN",
        adm0_a3_nl: "IRN",
        adm0_a3_se: "IRN",
        adm0_a3_bd: "IRN",
        adm0_a3_ua: "IRN",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Southern Asia",
        region_wb: "Middle East & North Africa",
        name_len: 4,
        long_len: 4,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 2.5,
        max_label: 6.7,
        label_x: 54.931495,
        label_y: 32.166225,
        ne_id: 1159320881,
        wikidataid: "Q794",
        name_ar: "إيران",
        name_bn: "ইরান",
        name_de: "Iran",
        name_en: "Iran",
        name_es: "Irán",
        name_fa: "ایران",
        name_fr: "Iran",
        name_el: "Ιράν",
        name_he: "איראן",
        name_hi: "ईरान",
        name_hu: "Irán",
        name_id: "Iran",
        name_it: "Iran",
        name_ja: "イラン",
        name_ko: "이란",
        name_nl: "Iran",
        name_pl: "Iran",
        name_pt: "Irão",
        name_ru: "Иран",
        name_sv: "Iran",
        name_tr: "İran",
        name_uk: "Іран",
        name_ur: "ایران",
        name_vi: "Iran",
        name_zh: "伊朗",
        name_zht: "伊朗",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "IRN.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [56.18798828125, 26.921142578125],
              [56.09492187500001, 26.801171875],
              [55.95429687500001, 26.701123046874997],
              [55.894140625, 26.732275390625],
              [55.84765625, 26.730810546875],
              [55.74726562500001, 26.69248046875],
              [55.67460937500002, 26.685839843749996],
              [55.54316406250001, 26.617529296875],
              [55.42373046875002, 26.58310546875],
              [55.340429687500006, 26.585742187499996],
              [55.3115234375, 26.592626953125],
              [55.295019531250006, 26.639208984374996],
              [55.296484375, 26.657568359375],
              [55.34697265625002, 26.64794921875],
              [55.53173828125, 26.710009765624996],
              [55.76259765625002, 26.811962890624997],
              [55.78457031250002, 26.857177734375],
              [55.74746093750002, 26.930957031249996],
              [55.75761718750002, 26.94765625],
              [55.90712890625002, 26.909814453124994],
              [56.07412109375002, 26.983349609374997],
              [56.213964843750006, 27.003271484375],
              [56.279394531250006, 26.952099609374997],
              [56.18798828125, 26.921142578125],
            ],
          ],
          [
            [
              [53.914160156250006, 37.343554687499996],
              [54.19160156250001, 37.332470703125],
              [54.2998046875, 37.35361328125],
              [54.45869140625001, 37.407568359375],
              [54.57890625000002, 37.440234375],
              [54.6396484375, 37.444726562499994],
              [54.69941406250001, 37.470166015625],
              [54.745214843750006, 37.501904296875],
              [54.8486328125, 37.72265625],
              [54.90009765625001, 37.7779296875],
              [55.07558593750002, 37.902490234374994],
              [55.224707031250006, 37.981347656249994],
              [55.380859375, 38.051123046875],
              [55.57841796875002, 38.099755859374994],
              [55.84130859375, 38.094628906249994],
              [56.05029296875, 38.077539062499994],
              [56.17119140625002, 38.078369140625],
              [56.22880859375002, 38.073388671874994],
              [56.272070312500006, 38.080419921875],
              [56.296972656250006, 38.09482421875],
              [56.32412109375002, 38.19111328125],
              [56.36689453125001, 38.222509765625],
              [56.440625, 38.249414062499994],
              [56.544042968750006, 38.249609375],
              [56.669921875, 38.256640625],
              [56.77460937500001, 38.250048828124996],
              [56.90664062500002, 38.213037109374994],
              [57.07900390625002, 38.2099609375],
              [57.19355468750001, 38.21640625],
              [57.26015625000002, 38.179589843749994],
              [57.30810546875, 38.13037109375],
              [57.33144531250002, 38.089306640625],
              [57.33671875000002, 38.03291015625],
              [57.33574218750002, 37.98994140625],
              [57.35371093750001, 37.97333984375],
              [57.423828125, 37.947705078125],
              [57.52099609375, 37.928466796875],
              [57.710546875, 37.9052734375],
              [57.88818359375, 37.86083984375],
              [57.980566406250006, 37.83046875],
              [58.10878906250002, 37.783056640625],
              [58.26162109375002, 37.665820312499996],
              [58.31816406250002, 37.647216796875],
              [58.38671875, 37.6353515625],
              [58.43574218750001, 37.638525390625],
              [58.55048828125001, 37.68818359375],
              [58.65019531250002, 37.6515625],
              [58.70078125, 37.65625],
              [58.8154296875, 37.683496093749994],
              [58.93720703125001, 37.649658203125],
              [59.240820312500006, 37.520751953125],
              [59.27412109375001, 37.523730468749996],
              [59.3017578125, 37.510644531249994],
              [59.32695312500002, 37.48115234375],
              [59.3447265625, 37.444726562499994],
              [59.36738281250001, 37.333740234375],
              [59.45498046875002, 37.252832031249994],
              [59.56220703125001, 37.17890625],
              [59.68720703125001, 37.138476562499996],
              [59.94863281250002, 37.0416015625],
              [60.06279296875002, 36.962890625],
              [60.178320312500006, 36.829443359375],
              [60.32070312500002, 36.653564453125],
              [60.34130859375, 36.637646484375],
              [60.70791015625002, 36.64296875],
              [61.11962890625, 36.642578125],
              [61.169921875, 36.572265625],
              [61.16035156250001, 36.43271484375],
              [61.17509765625002, 36.289697265624994],
              [61.212011718750006, 36.19052734375],
              [61.21240234375, 36.09912109375],
              [61.1826171875, 36.05283203125],
              [61.15947265625002, 35.999902343749994],
              [61.152929687500006, 35.9767578125],
              [61.20585937500002, 35.943701171875],
              [61.25214843750001, 35.867626953125],
              [61.25869140625002, 35.76181640625],
              [61.23554687500001, 35.70556640625],
              [61.238867187500006, 35.65927734375],
              [61.26201171875002, 35.619580078125],
              [61.281835937500006, 35.55341796875],
              [61.27851562500001, 35.51376953125],
              [61.24550781250002, 35.474072265625],
              [61.225683593750006, 35.424462890624994],
              [61.19921875, 35.361621093749996],
              [61.18925781250002, 35.31201171875],
              [61.1396484375, 35.288867187499996],
              [61.1, 35.272314453125],
              [61.10664062500001, 35.20947265625],
              [61.12646484375, 35.156542968749996],
              [61.14960937500001, 35.09375],
              [61.123144531250006, 35.050732421875],
              [61.10664062500001, 35.001123046874994],
              [61.07021484375002, 34.921728515625],
              [61.080078125, 34.855615234374994],
              [61.04042968750002, 34.799365234374996],
              [60.990820312500006, 34.749755859375],
              [60.95781250000002, 34.71005859375],
              [60.951171875, 34.653857421874996],
              [60.91474609375001, 34.633984375],
              [60.8453125, 34.587695312499996],
              [60.80234375, 34.554638671875],
              [60.73945312500001, 34.544726562499996],
              [60.72626953125001, 34.51826171875],
              [60.73613281250002, 34.491796875],
              [60.76259765625002, 34.475244140624994],
              [60.80390625000001, 34.418017578124996],
              [60.88945312500002, 34.31943359375],
              [60.642675781250006, 34.307177734374996],
              [60.57021484375002, 34.219628906249994],
              [60.48574218750002, 34.094775390624996],
              [60.52705078125001, 33.8419921875],
              [60.4859375, 33.7119140625],
              [60.510839843750006, 33.638916015625],
              [60.573828125, 33.588330078125],
              [60.65458984375002, 33.560400390625],
              [60.80644531250002, 33.55869140625],
              [60.90693359375001, 33.538964843749994],
              [60.9169921875, 33.505224609375],
              [60.85927734375002, 33.45625],
              [60.76689453125002, 33.363818359374996],
              [60.71806640625002, 33.323535156249996],
              [60.560546875, 33.137841796874994],
              [60.56191406250002, 33.0587890625],
              [60.57656250000002, 32.994873046875],
              [60.64453125, 32.794384765625],
              [60.71044921875, 32.6],
              [60.82929687500001, 32.249414062499994],
              [60.827246093750006, 32.16796875],
              [60.78994140625002, 31.987109375],
              [60.7875, 31.877197265625],
              [60.804296875, 31.73447265625],
              [60.791601562500006, 31.660595703124997],
              [60.82070312500002, 31.495166015624996],
              [60.854101562500006, 31.483251953125],
              [61.11074218750002, 31.451123046874997],
              [61.34648437500002, 31.421630859375],
              [61.66015625, 31.382421875],
              [61.75507812500001, 31.285302734374994],
              [61.81425781250002, 31.072558593749996],
              [61.81083984375002, 30.91328125],
              [61.7841796875, 30.831933593749994],
              [61.55947265625002, 30.599365234375],
              [61.331640625, 30.363720703124997],
              [61.104101562500006, 30.12841796875],
              [60.843359375, 29.858691406249996],
              [61.0341796875, 29.663427734375],
              [61.15214843750002, 29.542724609375],
              [61.318359375, 29.372607421874996],
              [61.339453125, 29.331787109375],
              [61.337890625, 29.264990234375],
              [61.50859375000002, 29.006054687499997],
              [61.56875, 28.870898437499996],
              [61.623046875, 28.7916015625],
              [61.75800781250001, 28.667675781249997],
              [61.88984375000001, 28.546533203124994],
              [62.03300781250002, 28.491015625],
              [62.13056640625001, 28.478808593749996],
              [62.35302734375, 28.414746093749997],
              [62.43388671875002, 28.3638671875],
              [62.564550781250006, 28.23515625],
              [62.71757812500002, 28.252783203125],
              [62.74941406250002, 28.252880859374997],
              [62.75800781250001, 28.243554687499994],
              [62.7625, 28.202050781249994],
              [62.73974609375, 28.00205078125],
              [62.782324218750006, 27.800537109375],
              [62.81201171875, 27.497021484374997],
              [62.800878906250006, 27.44453125],
              [62.764257812500006, 27.356738281249996],
              [62.7625, 27.300195312499994],
              [62.75273437500002, 27.265625],
              [62.76298828125002, 27.250195312499997],
              [62.811621093750006, 27.229443359374997],
              [62.91542968750002, 27.218408203124994],
              [63.16679687500002, 27.252490234374996],
              [63.19609375000002, 27.243945312499996],
              [63.25625, 27.207910156249994],
              [63.30156250000002, 27.15146484375],
              [63.30517578125, 27.124560546874996],
              [63.24208984375002, 27.077685546874996],
              [63.2314453125, 26.99814453125],
              [63.25039062500002, 26.879248046875],
              [63.24160156250002, 26.86474609375],
              [63.186132812500006, 26.837597656249997],
              [63.168066406250006, 26.665576171874996],
              [63.1578125, 26.649755859375],
              [63.09296875000001, 26.63232421875],
              [62.78662109375, 26.643896484375],
              [62.7515625, 26.63916015625],
              [62.63642578125001, 26.593652343749994],
              [62.43925781250002, 26.56103515625],
              [62.38505859375002, 26.542626953124994],
              [62.31230468750002, 26.490869140624994],
              [62.25966796875002, 26.427490234375],
              [62.249609375, 26.36923828125],
              [62.239355468750006, 26.35703125],
              [62.1259765625, 26.368994140625],
              [62.08906250000001, 26.318261718749994],
              [61.86982421875001, 26.242431640625],
              [61.842382812500006, 26.225927734375],
              [61.80996093750002, 26.165283203125],
              [61.78076171875, 25.995849609375],
              [61.75439453125, 25.843359375],
              [61.73769531250002, 25.82109375],
              [61.66865234375001, 25.768994140624997],
              [61.66181640625001, 25.751269531250003],
              [61.67138671875, 25.6923828125],
              [61.64013671875, 25.584619140624994],
              [61.61542968750001, 25.2861328125],
              [61.587890625, 25.20234375],
              [61.53310546875002, 25.195507812499997],
              [61.490332031250006, 25.15366210937499],
              [61.412207031250006, 25.102099609375003],
              [61.24296875000002, 25.141992187499994],
              [61.10859375000001, 25.183886718750003],
              [60.66386718750002, 25.2822265625],
              [60.61513671875002, 25.329833984375],
              [60.5875, 25.413525390624997],
              [60.51054687500002, 25.437060546875003],
              [60.40019531250002, 25.311572265625003],
              [60.02470703125002, 25.38413085937499],
              [59.897070312500006, 25.36181640625],
              [59.818359375, 25.40087890625],
              [59.61601562500002, 25.403271484374997],
              [59.4560546875, 25.481494140625003],
              [59.22724609375001, 25.427734375],
              [59.04609375000001, 25.417285156250003],
              [58.7978515625, 25.554589843749994],
              [58.530859375, 25.592431640624994],
              [58.31425781250002, 25.580859375],
              [58.20292968750002, 25.591601562500003],
              [58.02236328125002, 25.640820312499997],
              [57.936621093750006, 25.691650390625],
              [57.79609375000001, 25.653027343749997],
              [57.732519531250006, 25.724902343750003],
              [57.334570312500006, 25.791552734375003],
              [57.26093750000001, 25.918847656249994],
              [57.20556640625, 26.03720703125],
              [57.20136718750001, 26.158837890624994],
              [57.10429687500002, 26.371435546875],
              [57.07197265625001, 26.680078125],
              [57.03603515625002, 26.800683593749994],
              [56.98222656250002, 26.90546875],
              [56.91044921875002, 26.994580078124997],
              [56.81289062500002, 27.089990234374994],
              [56.728125, 27.127685546875],
              [56.35615234375001, 27.200244140624996],
              [56.284375, 27.190625],
              [56.11806640625002, 27.143115234374996],
              [55.94111328125001, 27.03759765625],
              [55.65029296875002, 26.9775390625],
              [55.59160156250002, 26.93212890625],
              [55.5185546875, 26.829931640625],
              [55.42402343750001, 26.770556640624996],
              [55.29414062500001, 26.7859375],
              [55.15458984375002, 26.725390625],
              [54.89580078125002, 26.556689453124996],
              [54.75927734375, 26.505078125],
              [54.64492187500002, 26.508935546874994],
              [54.522070312500006, 26.589160156249996],
              [54.2470703125, 26.696630859375],
              [54.0693359375, 26.732373046874997],
              [53.82255859375002, 26.707714843749997],
              [53.70576171875001, 26.7255859375],
              [53.50712890625002, 26.851757812499997],
              [53.45498046875002, 26.943261718749994],
              [53.34169921875002, 27.0044921875],
              [52.982519531250006, 27.141943359375],
              [52.69160156250001, 27.323388671874994],
              [52.63818359375, 27.391992187499994],
              [52.602636718750006, 27.493359375],
              [52.47587890625002, 27.616503906249996],
              [52.19189453125, 27.71728515625],
              [52.03076171875, 27.824414062499997],
              [51.84199218750001, 27.8482421875],
              [51.66630859375002, 27.844970703125],
              [51.58906250000001, 27.864208984374997],
              [51.5185546875, 27.910009765625],
              [51.27890625, 28.13134765625],
              [51.27607421875001, 28.21884765625],
              [51.12841796875, 28.43515625],
              [51.093847656250006, 28.512109375],
              [51.06201171875, 28.726123046874996],
              [51.02119140625001, 28.782080078125],
              [50.86699218750002, 28.870166015624996],
              [50.84296875000001, 28.92783203125],
              [50.87578125000002, 29.00439453125],
              [50.87578125000002, 29.062695312499997],
              [50.795507812500006, 29.117431640625],
              [50.67519531250002, 29.14658203125],
              [50.64609375, 29.212207031249996],
              [50.66796875, 29.33984375],
              [50.64960937500001, 29.420068359374994],
              [50.543554687500006, 29.547998046874994],
              [50.38691406250001, 29.679052734375],
              [50.23017578125001, 29.872900390625],
              [50.1689453125, 29.921240234375],
              [50.12890625, 30.048095703125],
              [50.07158203125002, 30.198535156249996],
              [49.98310546875001, 30.209375],
              [49.55488281250001, 30.028955078124994],
              [49.42998046875002, 30.13046875],
              [49.054296875, 30.306933593749996],
              [49.028125, 30.333447265624997],
              [49.001953125, 30.373925781249994],
              [49.04902343750001, 30.397265625],
              [49.09619140625, 30.406787109374996],
              [49.19033203125002, 30.375390625],
              [49.24726562500001, 30.4125],
              [49.22451171875002, 30.472314453124994],
              [49.13037109375, 30.509423828124994],
              [49.001953125, 30.506542968749997],
              [49.037109375, 30.450488281249996],
              [48.91679687500002, 30.397265625],
              [48.891210937500006, 30.32763671875],
              [48.90869140625, 30.241455078125],
              [48.91914062500001, 30.120898437499996],
              [48.8701171875, 30.062402343749994],
              [48.83242187500002, 30.035498046875],
              [48.6708984375, 30.0283203125],
              [48.59550781250002, 29.975048828124997],
              [48.546484375, 29.962353515624997],
              [48.478515625, 30.003808593749994],
              [48.4345703125, 30.03759765625],
              [48.39863281250001, 30.109619140625],
              [48.38759765625002, 30.159863281249997],
              [48.4013671875, 30.188330078125],
              [48.38261718750002, 30.230175781249997],
              [48.3310546875, 30.285449218749996],
              [48.27890625, 30.315820312499994],
              [48.226171875, 30.321337890624996],
              [48.18242187500002, 30.355029296874996],
              [48.147558593750006, 30.416845703125],
              [48.06611328125001, 30.457666015624994],
              [48.01494140625002, 30.465625],
              [48.01347656250002, 30.656445312499997],
              [48.01201171875002, 30.823632812499994],
              [48.01064453125002, 30.989794921874996],
              [47.83632812500002, 30.996435546875],
              [47.67949218750002, 31.002392578124997],
              [47.67949218750002, 31.141503906249994],
              [47.67949218750002, 31.400585937499997],
              [47.75390625, 31.601367187499996],
              [47.82998046875002, 31.79443359375],
              [47.71455078125001, 31.936425781249994],
              [47.59150390625001, 32.08798828125],
              [47.51191406250001, 32.150830078125],
              [47.41816406250001, 32.340087890625],
              [47.37128906250001, 32.423730468749994],
              [47.32978515625001, 32.455517578125],
              [47.28515625, 32.474023437499994],
              [47.12138671875002, 32.466601562499996],
              [46.96855468750002, 32.568408203124996],
              [46.7890625, 32.68798828125],
              [46.569921875, 32.833935546875],
              [46.377050781250006, 32.929248046874996],
              [46.29824218750002, 32.950244140624996],
              [46.11279296875, 32.957666015624994],
              [46.09306640625002, 32.975878906249996],
              [46.08046875000002, 33.028222656249994],
              [46.08076171875001, 33.0865234375],
              [46.14111328125, 33.1744140625],
              [46.14589843750002, 33.229638671874994],
              [46.01992187500002, 33.41572265625],
              [45.981054687500006, 33.470117187499994],
              [45.87324218750001, 33.491992187499996],
              [45.89472656250001, 33.545654296875],
              [45.89472656250001, 33.58154296875],
              [45.87939453125, 33.609765625],
              [45.8544921875, 33.623339843749996],
              [45.822851562500006, 33.624804687499996],
              [45.73828125, 33.602832031249996],
              [45.67373046875002, 33.686669921874994],
              [45.473242187500006, 33.92548828125],
              [45.40898437500002, 33.954492187499994],
              [45.397070312500006, 33.970849609374994],
              [45.44609375000002, 34.04404296875],
              [45.52861328125002, 34.1525390625],
              [45.54277343750002, 34.21552734375],
              [45.52685546875, 34.28466796875],
              [45.437597656250006, 34.41513671875],
              [45.459375, 34.470361328124994],
              [45.49775390625001, 34.53388671875],
              [45.50078125000002, 34.581591796874996],
              [45.56083984375002, 34.574511718749996],
              [45.6375, 34.573828125],
              [45.66152343750002, 34.612695312499994],
              [45.66005859375002, 34.748779296875],
              [45.678125, 34.7984375],
              [45.9208984375, 35.028515625],
              [46.04179687500002, 35.08017578125],
              [46.1337890625, 35.12763671875],
              [46.15468750000002, 35.196728515625],
              [46.1357421875, 35.232275390625],
              [46.117773437500006, 35.28427734375],
              [46.11210937500002, 35.321679687499994],
              [46.01064453125002, 35.4248046875],
              [45.97539062500002, 35.476806640625],
              [45.97109375000002, 35.524169921875],
              [45.99501953125002, 35.60810546875],
              [46.03740234375002, 35.673144531249996],
              [46.18095703125002, 35.711376953125],
              [46.2625, 35.744140625],
              [46.2734375, 35.773242187499996],
              [46.16748046875, 35.820556640625],
              [45.94140625, 35.835400390625],
              [45.7763671875, 35.821826171874996],
              [45.72343750000002, 35.836669921875],
              [45.64501953125, 35.928369140624994],
              [45.561621093750006, 35.977197265624994],
              [45.48378906250002, 36.008544921875],
              [45.40771484375, 36.002783203125],
              [45.36162109375002, 36.01533203125],
              [45.35087890625002, 36.054638671875],
              [45.24111328125002, 36.35595703125],
              [45.20654296875, 36.39716796875],
              [45.1552734375, 36.407373046874994],
              [45.112402343750006, 36.40927734375],
              [45.08378906250002, 36.430029296875],
              [45.053125, 36.471630859375],
              [45.03105468750002, 36.52607421875],
              [45.029394531250006, 36.597558593749994],
              [45.03398437500002, 36.65888671875],
              [45.01923828125001, 36.698388671874994],
              [44.9814453125, 36.737695312499994],
              [44.927832031250006, 36.765917968749996],
              [44.880859375, 36.79931640625],
              [44.7984375, 37.063867187499994],
              [44.76542968750002, 37.135009765625],
              [44.76513671875, 37.142431640625],
              [44.766699218750006, 37.15634765625],
              [44.75830078125, 37.217089843749996],
              [44.79677734375002, 37.269775390625],
              [44.79414062500001, 37.290380859375],
              [44.71513671875002, 37.35712890625],
              [44.604101562500006, 37.423730468749994],
              [44.57402343750002, 37.435400390625],
              [44.57314453125002, 37.506396484374996],
              [44.5771484375, 37.560205078124994],
              [44.56718750000002, 37.608642578125],
              [44.54609375000001, 37.636328125],
              [44.54531250000002, 37.658154296875],
              [44.589941406250006, 37.710351562499994],
              [44.56123046875001, 37.74462890625],
              [44.39775390625002, 37.829248046874994],
              [44.33623046875002, 37.87177734375],
              [44.22294921875002, 37.880175781249996],
              [44.21132812500002, 37.908056640625],
              [44.22890625000002, 37.9671875],
              [44.26796875000002, 38.038818359375],
              [44.32939453125002, 38.109277343749994],
              [44.34892578125002, 38.146484375],
              [44.37275390625001, 38.209716796875],
              [44.380859375, 38.25458984375],
              [44.44960937500002, 38.317773437499994],
              [44.44990234375001, 38.334228515625],
              [44.43085937500001, 38.356787109375],
              [44.37578125000002, 38.369580078125],
              [44.31962890625002, 38.37470703125],
              [44.29853515625001, 38.386279296874996],
              [44.29082031250002, 38.4201171875],
              [44.2978515625, 38.5578125],
              [44.28017578125002, 38.640673828124996],
              [44.25703125000001, 38.700634765625],
              [44.27167968750001, 38.83603515625],
              [44.232421875, 38.863232421875],
              [44.17080078125002, 38.934375],
              [44.14453125, 38.994384765625],
              [44.158789062500006, 39.016748046874994],
              [44.171875, 39.05625],
              [44.18056640625002, 39.108056640624994],
              [44.17802734375002, 39.144824218749996],
              [44.12128906250001, 39.180615234375],
              [44.0791015625, 39.218310546874996],
              [44.074316406250006, 39.2599609375],
              [44.05751953125002, 39.310839843749996],
              [44.033789062500006, 39.351025390625],
              [44.02324218750002, 39.37744140625],
              [44.0439453125, 39.39296875],
              [44.1240234375, 39.405224609375],
              [44.24042968750001, 39.396777343749996],
              [44.33544921875, 39.396044921874996],
              [44.38935546875001, 39.422119140625],
              [44.45595703125002, 39.666748046875],
              [44.516699218750006, 39.73125],
              [44.58710937500001, 39.7685546875],
              [44.725, 39.68173828125],
              [44.78212890625002, 39.65107421875],
              [44.81718750000002, 39.650439453124996],
              [44.83818359375002, 39.6291015625],
              [45.00019531250001, 39.42353515625],
              [45.07167968750002, 39.362890625],
              [45.11308593750002, 39.311572265624996],
              [45.141210937500006, 39.254296875],
              [45.190625, 39.215625],
              [45.255957031250006, 39.194677734375],
              [45.335546875, 39.13916015625],
              [45.389257812500006, 39.0958984375],
              [45.47968750000001, 39.00625],
              [45.575, 38.972802734374994],
              [45.921875, 38.90791015625],
              [46.11445312500001, 38.877783203125],
              [46.17011718750001, 38.869042968749994],
              [46.31777343750002, 38.912646484374996],
              [46.490625, 38.906689453125],
              [46.554785156250006, 38.90439453125],
              [46.783203125, 39.08740234375],
              [46.8525390625, 39.1484375],
              [46.988867187500006, 39.18017578125],
              [47.0654296875, 39.252880859375],
              [47.18837890625002, 39.340966796874994],
              [47.338476562500006, 39.423876953124996],
              [47.476171875, 39.498339843749996],
              [47.58183593750002, 39.543359375],
              [47.77285156250002, 39.648583984374994],
              [47.89228515625001, 39.68505859375],
              [47.99589843750002, 39.683935546875],
              [48.15107421875001, 39.560546875],
              [48.28173828125, 39.44833984375],
              [48.32216796875002, 39.399072265624994],
              [48.25722656250002, 39.35498046875],
              [48.13603515625002, 39.312353515625],
              [48.112890625, 39.281103515625],
              [48.10439453125002, 39.241113281249994],
              [48.10917968750002, 39.20283203125],
              [48.12548828125, 39.171630859375],
              [48.27412109375001, 39.09912109375],
              [48.291015625, 39.05927734375],
              [48.292089843750006, 39.018847656249996],
              [48.27509765625001, 38.993603515625],
              [48.24199218750002, 38.978955078125],
              [48.13857421875002, 38.958642578124994],
              [48.05009765625002, 38.935009765625],
              [48.01933593750002, 38.91181640625],
              [47.99267578125, 38.88427734375],
              [47.99648437500002, 38.853759765625],
              [48.02324218750002, 38.81904296875],
              [48.2046875, 38.72412109375],
              [48.225195312500006, 38.689208984375],
              [48.261328125, 38.64228515625],
              [48.30556640625002, 38.6134765625],
              [48.38125, 38.605615234374994],
              [48.41738281250002, 38.586230468749996],
              [48.59267578125002, 38.411083984375],
              [48.63554687500002, 38.398730468749996],
              [48.84033203125, 38.437255859375],
              [48.86875, 38.435498046875],
              [48.870703125, 38.392529296875],
              [48.9013671875, 38.14365234375],
              [48.92509765625002, 38.01513671875],
              [48.9599609375, 37.89013671875],
              [49.01533203125001, 37.77607421875],
              [49.08095703125002, 37.667578125],
              [49.17119140625002, 37.6005859375],
              [49.37246093750002, 37.519970703125],
              [49.47011718750002, 37.4966796875],
              [49.72695312500002, 37.480517578124996],
              [49.98066406250001, 37.444873046874996],
              [50.13046875, 37.407128906249994],
              [50.17626953125, 37.380517578124994],
              [50.21406250000001, 37.339599609375],
              [50.337890625, 37.149169921875],
              [50.533203125, 37.013671875],
              [50.92744140625001, 36.810205078124994],
              [51.11855468750002, 36.742578125],
              [51.76201171875002, 36.614501953125],
              [52.19013671875001, 36.621728515624994],
              [53.374121093750006, 36.86875],
              [53.767675781250006, 36.930322265624994],
              [53.91542968750002, 36.930322265624994],
              [53.82744140625002, 36.881201171875],
              [53.67949218750002, 36.853125],
              [53.76875, 36.81845703125],
              [53.90625, 36.8126953125],
              [53.97011718750002, 36.818310546875],
              [54.016210937500006, 36.849658203124996],
              [54.02382812500002, 36.901318359375],
              [54.0171875, 36.952490234375],
              [53.95195312500002, 37.18173828125],
              [53.914160156250006, 37.343554687499996],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Iraq",
        sov_a3: "IRQ",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Iraq",
        adm0_a3: "IRQ",
        geou_dif: 0,
        geounit: "Iraq",
        gu_a3: "IRQ",
        su_dif: 0,
        subunit: "Iraq",
        su_a3: "IRQ",
        brk_diff: 0,
        name: "Iraq",
        name_long: "Iraq",
        brk_a3: "IRQ",
        brk_name: "Iraq",
        brk_group: null,
        abbrev: "Iraq",
        postal: "IRQ",
        formal_en: "Republic of Iraq",
        formal_fr: null,
        name_ciawf: "Iraq",
        note_adm0: null,
        note_brk: null,
        name_sort: "Iraq",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 4,
        mapcolor9: 3,
        mapcolor13: 1,
        pop_est: 39309783,
        pop_rank: 15,
        pop_year: 2019,
        gdp_md: 234094,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        fips_10: "IZ",
        iso_a2: "IQ",
        iso_a2_eh: "IQ",
        iso_a3: "IRQ",
        iso_a3_eh: "IRQ",
        iso_n3: "368",
        iso_n3_eh: "368",
        un_a3: "368",
        wb_a2: "IQ",
        wb_a3: "IRQ",
        woe_id: 23424855,
        woe_id_eh: 23424855,
        woe_note: "Exact WOE match as country",
        adm0_iso: "IRQ",
        adm0_diff: null,
        adm0_tlc: "IRQ",
        adm0_a3_us: "IRQ",
        adm0_a3_fr: "IRQ",
        adm0_a3_ru: "IRQ",
        adm0_a3_es: "IRQ",
        adm0_a3_cn: "IRQ",
        adm0_a3_tw: "IRQ",
        adm0_a3_in: "IRQ",
        adm0_a3_np: "IRQ",
        adm0_a3_pk: "IRQ",
        adm0_a3_de: "IRQ",
        adm0_a3_gb: "IRQ",
        adm0_a3_br: "IRQ",
        adm0_a3_il: "IRQ",
        adm0_a3_ps: "IRQ",
        adm0_a3_sa: "IRQ",
        adm0_a3_eg: "IRQ",
        adm0_a3_ma: "IRQ",
        adm0_a3_pt: "IRQ",
        adm0_a3_ar: "IRQ",
        adm0_a3_jp: "IRQ",
        adm0_a3_ko: "IRQ",
        adm0_a3_vn: "IRQ",
        adm0_a3_tr: "IRQ",
        adm0_a3_id: "IRQ",
        adm0_a3_pl: "IRQ",
        adm0_a3_gr: "IRQ",
        adm0_a3_it: "IRQ",
        adm0_a3_nl: "IRQ",
        adm0_a3_se: "IRQ",
        adm0_a3_bd: "IRQ",
        adm0_a3_ua: "IRQ",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Western Asia",
        region_wb: "Middle East & North Africa",
        name_len: 4,
        long_len: 4,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 7.5,
        label_x: 43.26181,
        label_y: 33.09403,
        ne_id: 1159320887,
        wikidataid: "Q796",
        name_ar: "العراق",
        name_bn: "ইরাক",
        name_de: "Irak",
        name_en: "Iraq",
        name_es: "Irak",
        name_fa: "عراق",
        name_fr: "Irak",
        name_el: "Ιράκ",
        name_he: "עיראק",
        name_hi: "इराक",
        name_hu: "Irak",
        name_id: "Irak",
        name_it: "Iraq",
        name_ja: "イラク",
        name_ko: "이라크",
        name_nl: "Irak",
        name_pl: "Irak",
        name_pt: "Iraque",
        name_ru: "Ирак",
        name_sv: "Irak",
        name_tr: "Irak",
        name_uk: "Ірак",
        name_ur: "عراق",
        name_vi: "Iraq",
        name_zh: "伊拉克",
        name_zht: "伊拉克",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "IRQ.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [42.358984375, 37.10859375],
            [42.45585937500002, 37.128710937499996],
            [42.63544921875001, 37.249267578125],
            [42.74111328125002, 37.3619140625],
            [42.77460937500001, 37.371875],
            [42.869140625, 37.334912109375],
            [42.936621093750006, 37.324755859374996],
            [43.09248046875001, 37.3673828125],
            [43.18515625, 37.344873046874994],
            [43.26308593750002, 37.31650390625],
            [43.306738281250006, 37.3146484375],
            [43.51582031250001, 37.24453125],
            [43.56796875, 37.23583984375],
            [43.67578125, 37.22724609375],
            [43.83642578125, 37.223535156249994],
            [43.940039062500006, 37.269287109375],
            [44.01318359375, 37.313525390624996],
            [44.06464843750001, 37.312451171875],
            [44.11445312500001, 37.30185546875],
            [44.15625, 37.282958984375],
            [44.19179687500002, 37.249853515625],
            [44.20839843750002, 37.20263671875],
            [44.20166015625, 37.051806640624996],
            [44.21748046875001, 37.011865234374994],
            [44.245703125, 36.983300781249994],
            [44.281835937500006, 36.97802734375],
            [44.32558593750002, 37.0107421875],
            [44.401953125, 37.058496093749994],
            [44.49599609375002, 37.110546875],
            [44.566015625, 37.158251953124996],
            [44.60595703125, 37.176025390625],
            [44.66933593750002, 37.173583984375],
            [44.73095703125, 37.165283203125],
            [44.76513671875, 37.142431640625],
            [44.76542968750002, 37.135009765625],
            [44.7984375, 37.063867187499994],
            [44.880859375, 36.79931640625],
            [44.927832031250006, 36.765917968749996],
            [44.9814453125, 36.737695312499994],
            [45.01923828125001, 36.698388671874994],
            [45.03398437500002, 36.65888671875],
            [45.029394531250006, 36.597558593749994],
            [45.03105468750002, 36.52607421875],
            [45.053125, 36.471630859375],
            [45.08378906250002, 36.430029296875],
            [45.112402343750006, 36.40927734375],
            [45.1552734375, 36.407373046874994],
            [45.20654296875, 36.39716796875],
            [45.24111328125002, 36.35595703125],
            [45.35087890625002, 36.054638671875],
            [45.36162109375002, 36.01533203125],
            [45.40771484375, 36.002783203125],
            [45.48378906250002, 36.008544921875],
            [45.561621093750006, 35.977197265624994],
            [45.64501953125, 35.928369140624994],
            [45.72343750000002, 35.836669921875],
            [45.7763671875, 35.821826171874996],
            [45.94140625, 35.835400390625],
            [46.16748046875, 35.820556640625],
            [46.2734375, 35.773242187499996],
            [46.2625, 35.744140625],
            [46.18095703125002, 35.711376953125],
            [46.03740234375002, 35.673144531249996],
            [45.99501953125002, 35.60810546875],
            [45.97109375000002, 35.524169921875],
            [45.97539062500002, 35.476806640625],
            [46.01064453125002, 35.4248046875],
            [46.11210937500002, 35.321679687499994],
            [46.117773437500006, 35.28427734375],
            [46.1357421875, 35.232275390625],
            [46.15468750000002, 35.196728515625],
            [46.1337890625, 35.12763671875],
            [46.04179687500002, 35.08017578125],
            [45.9208984375, 35.028515625],
            [45.678125, 34.7984375],
            [45.66005859375002, 34.748779296875],
            [45.66152343750002, 34.612695312499994],
            [45.6375, 34.573828125],
            [45.56083984375002, 34.574511718749996],
            [45.50078125000002, 34.581591796874996],
            [45.49775390625001, 34.53388671875],
            [45.459375, 34.470361328124994],
            [45.437597656250006, 34.41513671875],
            [45.52685546875, 34.28466796875],
            [45.54277343750002, 34.21552734375],
            [45.52861328125002, 34.1525390625],
            [45.44609375000002, 34.04404296875],
            [45.397070312500006, 33.970849609374994],
            [45.40898437500002, 33.954492187499994],
            [45.473242187500006, 33.92548828125],
            [45.67373046875002, 33.686669921874994],
            [45.73828125, 33.602832031249996],
            [45.822851562500006, 33.624804687499996],
            [45.8544921875, 33.623339843749996],
            [45.87939453125, 33.609765625],
            [45.89472656250001, 33.58154296875],
            [45.89472656250001, 33.545654296875],
            [45.87324218750001, 33.491992187499996],
            [45.981054687500006, 33.470117187499994],
            [46.01992187500002, 33.41572265625],
            [46.14589843750002, 33.229638671874994],
            [46.14111328125, 33.1744140625],
            [46.08076171875001, 33.0865234375],
            [46.08046875000002, 33.028222656249994],
            [46.09306640625002, 32.975878906249996],
            [46.11279296875, 32.957666015624994],
            [46.29824218750002, 32.950244140624996],
            [46.377050781250006, 32.929248046874996],
            [46.569921875, 32.833935546875],
            [46.7890625, 32.68798828125],
            [46.96855468750002, 32.568408203124996],
            [47.12138671875002, 32.466601562499996],
            [47.28515625, 32.474023437499994],
            [47.32978515625001, 32.455517578125],
            [47.37128906250001, 32.423730468749994],
            [47.41816406250001, 32.340087890625],
            [47.51191406250001, 32.150830078125],
            [47.59150390625001, 32.08798828125],
            [47.71455078125001, 31.936425781249994],
            [47.82998046875002, 31.79443359375],
            [47.75390625, 31.601367187499996],
            [47.67949218750002, 31.400585937499997],
            [47.67949218750002, 31.141503906249994],
            [47.67949218750002, 31.002392578124997],
            [47.83632812500002, 30.996435546875],
            [48.01064453125002, 30.989794921874996],
            [48.01201171875002, 30.823632812499994],
            [48.01347656250002, 30.656445312499997],
            [48.01494140625002, 30.465625],
            [48.06611328125001, 30.457666015624994],
            [48.147558593750006, 30.416845703125],
            [48.18242187500002, 30.355029296874996],
            [48.226171875, 30.321337890624996],
            [48.27890625, 30.315820312499994],
            [48.3310546875, 30.285449218749996],
            [48.38261718750002, 30.230175781249997],
            [48.4013671875, 30.188330078125],
            [48.38759765625002, 30.159863281249997],
            [48.39863281250001, 30.109619140625],
            [48.4345703125, 30.03759765625],
            [48.478515625, 30.003808593749994],
            [48.546484375, 29.962353515624997],
            [48.454199218750006, 29.9384765625],
            [48.354589843750006, 29.956738281249997],
            [48.141699218750006, 30.040917968749994],
            [48.07275390625, 30.043212890625],
            [47.982519531250006, 30.011328125],
            [47.97871093750001, 29.9828125],
            [47.75390625, 30.076611328124997],
            [47.67275390625002, 30.095605468749994],
            [47.64375, 30.097314453124994],
            [47.51484375000001, 30.096484375],
            [47.33134765625002, 30.0796875],
            [47.223242187500006, 30.04150390625],
            [47.14824218750002, 30.0009765625],
            [47.114355468750006, 29.961328125],
            [47.10205078125, 29.939990234374996],
            [47.04365234375001, 29.822998046875],
            [46.97597656250002, 29.6728515625],
            [46.905859375, 29.5375],
            [46.76933593750002, 29.347460937499996],
            [46.69375, 29.259667968749994],
            [46.53144531250001, 29.096240234374996],
            [46.3564453125, 29.063671875],
            [45.94970703125, 29.095849609374994],
            [45.49892578125002, 29.131542968749997],
            [45.05029296875, 29.16708984375],
            [44.71650390625001, 29.193603515625],
            [44.69082031250002, 29.20234375],
            [44.36074218750002, 29.435253906249997],
            [44.099609375, 29.619335937499997],
            [43.77373046875002, 29.84921875],
            [43.44082031250002, 30.083984375],
            [43.103125, 30.322216796874997],
            [42.85771484375002, 30.49521484375],
            [42.55976562500001, 30.7177734375],
            [42.28857421875, 30.92041015625],
            [42.07441406250001, 31.080371093749996],
            [41.79970703125002, 31.220361328124994],
            [41.585058593750006, 31.329736328124994],
            [41.2724609375, 31.489013671875],
            [41.0224609375, 31.616357421874994],
            [40.80839843750002, 31.725439453125],
            [40.47890625000002, 31.893359375],
            [40.36933593750001, 31.93896484375],
            [40.02783203125, 31.995019531249994],
            [39.7041015625, 32.042529296874996],
            [39.36865234375, 32.091748046875],
            [39.14541015625002, 32.12451171875],
            [39.29277343750002, 32.24384765625],
            [39.24746093750002, 32.350976562499994],
            [39.14003906250002, 32.331201171874994],
            [39.04140625000002, 32.3056640625],
            [38.98164062500001, 32.472558593749994],
            [39.05781250000001, 32.4931640625],
            [38.987402343750006, 32.710693359375],
            [38.91484375000002, 32.93466796875],
            [38.84501953125002, 33.15087890625],
            [38.773535156250006, 33.372216796874994],
            [39.056738281250006, 33.514013671875],
            [39.26835937500002, 33.620019531249994],
            [39.564453125, 33.768359375],
            [39.85, 33.911376953125],
            [40.12197265625002, 34.04765625],
            [40.421484375, 34.19775390625],
            [40.689453125, 34.33203125],
            [40.93505859375, 34.386572265625],
            [40.98701171875001, 34.429052734375],
            [41.09902343750002, 34.6123046875],
            [41.19472656250002, 34.768994140625],
            [41.19921875, 34.805322265624994],
            [41.19960937500002, 35.027392578124996],
            [41.21640625, 35.28818359375],
            [41.24833984375002, 35.427490234375],
            [41.303320312500006, 35.550634765625],
            [41.354101562500006, 35.640429687499996],
            [41.359375, 35.724609375],
            [41.352636718750006, 35.809960937499994],
            [41.30019531250002, 35.93896484375],
            [41.24560546875, 36.073388671874994],
            [41.25175781250002, 36.203027343749994],
            [41.261816406250006, 36.2724609375],
            [41.295996093750006, 36.383349609374996],
            [41.35419921875001, 36.464404296874996],
            [41.41679687500002, 36.5146484375],
            [41.65019531250002, 36.56640625],
            [41.78857421875, 36.59716796875],
            [41.97402343750002, 36.7408203125],
            [42.083984375, 36.826025390625],
            [42.2373046875, 36.9611328125],
            [42.35009765625, 37.060595703124996],
            [42.35908203125001, 37.095019531249996],
            [42.358984375, 37.10859375],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 5,
        sovereignt: "Qatar",
        sov_a3: "QAT",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Qatar",
        adm0_a3: "QAT",
        geou_dif: 0,
        geounit: "Qatar",
        gu_a3: "QAT",
        su_dif: 0,
        subunit: "Qatar",
        su_a3: "QAT",
        brk_diff: 0,
        name: "Qatar",
        name_long: "Qatar",
        brk_a3: "QAT",
        brk_name: "Qatar",
        brk_group: null,
        abbrev: "Qatar",
        postal: "QA",
        formal_en: "State of Qatar",
        formal_fr: null,
        name_ciawf: "Qatar",
        note_adm0: null,
        note_brk: null,
        name_sort: "Qatar",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 6,
        mapcolor9: 2,
        mapcolor13: 4,
        pop_est: 2832067,
        pop_rank: 12,
        pop_year: 2019,
        gdp_md: 175837,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "2. High income: nonOECD",
        fips_10: "QA",
        iso_a2: "QA",
        iso_a2_eh: "QA",
        iso_a3: "QAT",
        iso_a3_eh: "QAT",
        iso_n3: "634",
        iso_n3_eh: "634",
        un_a3: "634",
        wb_a2: "QA",
        wb_a3: "QAT",
        woe_id: 23424930,
        woe_id_eh: 23424930,
        woe_note: "Exact WOE match as country",
        adm0_iso: "QAT",
        adm0_diff: null,
        adm0_tlc: "QAT",
        adm0_a3_us: "QAT",
        adm0_a3_fr: "QAT",
        adm0_a3_ru: "QAT",
        adm0_a3_es: "QAT",
        adm0_a3_cn: "QAT",
        adm0_a3_tw: "QAT",
        adm0_a3_in: "QAT",
        adm0_a3_np: "QAT",
        adm0_a3_pk: "QAT",
        adm0_a3_de: "QAT",
        adm0_a3_gb: "QAT",
        adm0_a3_br: "QAT",
        adm0_a3_il: "QAT",
        adm0_a3_ps: "QAT",
        adm0_a3_sa: "QAT",
        adm0_a3_eg: "QAT",
        adm0_a3_ma: "QAT",
        adm0_a3_pt: "QAT",
        adm0_a3_ar: "QAT",
        adm0_a3_jp: "QAT",
        adm0_a3_ko: "QAT",
        adm0_a3_vn: "QAT",
        adm0_a3_tr: "QAT",
        adm0_a3_id: "QAT",
        adm0_a3_pl: "QAT",
        adm0_a3_gr: "QAT",
        adm0_a3_it: "QAT",
        adm0_a3_nl: "QAT",
        adm0_a3_se: "QAT",
        adm0_a3_bd: "QAT",
        adm0_a3_ua: "QAT",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Western Asia",
        region_wb: "Middle East & North Africa",
        name_len: 5,
        long_len: 5,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: 51.143509,
        label_y: 25.237383,
        ne_id: 1159321197,
        wikidataid: "Q846",
        name_ar: "قطر",
        name_bn: "কাতার",
        name_de: "Katar",
        name_en: "Qatar",
        name_es: "Catar",
        name_fa: "قطر",
        name_fr: "Qatar",
        name_el: "Κατάρ",
        name_he: "קטר",
        name_hi: "क़तर",
        name_hu: "Katar",
        name_id: "Qatar",
        name_it: "Qatar",
        name_ja: "カタール",
        name_ko: "카타르",
        name_nl: "Qatar",
        name_pl: "Katar",
        name_pt: "Catar",
        name_ru: "Катар",
        name_sv: "Qatar",
        name_tr: "Katar",
        name_uk: "Катар",
        name_ur: "قطر",
        name_vi: "Qatar",
        name_zh: "卡塔尔",
        name_zht: "卡達",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "QAT.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [51.26796875000002, 24.607226562500003],
            [51.17802734375002, 24.58671875],
            [51.093359375, 24.564648437499997],
            [51.02275390625002, 24.565234375],
            [50.96601562500001, 24.573925781249997],
            [50.928320312500006, 24.595117187499994],
            [50.85566406250001, 24.679638671874997],
            [50.80439453125001, 24.789257812499997],
            [50.8359375, 24.850390625],
            [50.846777343750006, 24.888574218749994],
            [50.77734375, 25.177441406249997],
            [50.75458984375001, 25.39926757812499],
            [50.76289062500001, 25.444726562499994],
            [50.80263671875002, 25.4970703125],
            [50.86865234375, 25.612695312499994],
            [50.90380859375, 25.724072265624997],
            [51.003125, 25.9814453125],
            [51.10810546875001, 26.08056640625],
            [51.262304687500006, 26.153271484374997],
            [51.38906250000002, 26.011132812499994],
            [51.543066406250006, 25.902392578125003],
            [51.572265625, 25.781005859375],
            [51.526953125, 25.68212890625],
            [51.4853515625, 25.524707031250003],
            [51.51025390625, 25.45234375],
            [51.51953125, 25.38974609374999],
            [51.56142578125002, 25.284472656250003],
            [51.60195312500002, 25.14794921875],
            [51.60888671875, 25.052880859374994],
            [51.5869140625, 24.96484375],
            [51.53339843750001, 24.890869140625],
            [51.42792968750001, 24.668261718750003],
            [51.396484375, 24.64511718749999],
            [51.26796875000002, 24.607226562500003],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 3,
        labelrank: 6,
        sovereignt: "Kuwait",
        sov_a3: "KWT",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Kuwait",
        adm0_a3: "KWT",
        geou_dif: 0,
        geounit: "Kuwait",
        gu_a3: "KWT",
        su_dif: 0,
        subunit: "Kuwait",
        su_a3: "KWT",
        brk_diff: 0,
        name: "Kuwait",
        name_long: "Kuwait",
        brk_a3: "KWT",
        brk_name: "Kuwait",
        brk_group: null,
        abbrev: "Kwt.",
        postal: "KW",
        formal_en: "State of Kuwait",
        formal_fr: null,
        name_ciawf: "Kuwait",
        note_adm0: null,
        note_brk: null,
        name_sort: "Kuwait",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 2,
        mapcolor9: 2,
        mapcolor13: 2,
        pop_est: 4207083,
        pop_rank: 12,
        pop_year: 2019,
        gdp_md: 134628,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "2. High income: nonOECD",
        fips_10: "KU",
        iso_a2: "KW",
        iso_a2_eh: "KW",
        iso_a3: "KWT",
        iso_a3_eh: "KWT",
        iso_n3: "414",
        iso_n3_eh: "414",
        un_a3: "414",
        wb_a2: "KW",
        wb_a3: "KWT",
        woe_id: 23424870,
        woe_id_eh: 23424870,
        woe_note: "Exact WOE match as country",
        adm0_iso: "KWT",
        adm0_diff: null,
        adm0_tlc: "KWT",
        adm0_a3_us: "KWT",
        adm0_a3_fr: "KWT",
        adm0_a3_ru: "KWT",
        adm0_a3_es: "KWT",
        adm0_a3_cn: "KWT",
        adm0_a3_tw: "KWT",
        adm0_a3_in: "KWT",
        adm0_a3_np: "KWT",
        adm0_a3_pk: "KWT",
        adm0_a3_de: "KWT",
        adm0_a3_gb: "KWT",
        adm0_a3_br: "KWT",
        adm0_a3_il: "KWT",
        adm0_a3_ps: "KWT",
        adm0_a3_sa: "KWT",
        adm0_a3_eg: "KWT",
        adm0_a3_ma: "KWT",
        adm0_a3_pt: "KWT",
        adm0_a3_ar: "KWT",
        adm0_a3_jp: "KWT",
        adm0_a3_ko: "KWT",
        adm0_a3_vn: "KWT",
        adm0_a3_tr: "KWT",
        adm0_a3_id: "KWT",
        adm0_a3_pl: "KWT",
        adm0_a3_gr: "KWT",
        adm0_a3_it: "KWT",
        adm0_a3_nl: "KWT",
        adm0_a3_se: "KWT",
        adm0_a3_bd: "KWT",
        adm0_a3_ua: "KWT",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Western Asia",
        region_wb: "Middle East & North Africa",
        name_len: 6,
        long_len: 6,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 5,
        max_label: 10,
        label_x: 47.313999,
        label_y: 29.413628,
        ne_id: 1159321009,
        wikidataid: "Q817",
        name_ar: "الكويت",
        name_bn: "কুয়েত",
        name_de: "Kuwait",
        name_en: "Kuwait",
        name_es: "Kuwait",
        name_fa: "کویت",
        name_fr: "Koweït",
        name_el: "Κουβέιτ",
        name_he: "כווית",
        name_hi: "कुवैत",
        name_hu: "Kuvait",
        name_id: "Kuwait",
        name_it: "Kuwait",
        name_ja: "クウェート",
        name_ko: "쿠웨이트",
        name_nl: "Koeweit",
        name_pl: "Kuwejt",
        name_pt: "Kuwait",
        name_ru: "Кувейт",
        name_sv: "Kuwait",
        name_tr: "Kuveyt",
        name_uk: "Кувейт",
        name_ur: "کویت",
        name_vi: "Kuwait",
        name_zh: "科威特",
        name_zht: "科威特",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "KWT.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [48.275390625, 29.624316406249996],
              [48.21826171875, 29.601953125],
              [48.1796875, 29.61142578125],
              [48.142578125, 29.665283203125],
              [48.08144531250002, 29.79892578125],
              [48.11474609375, 29.848779296874994],
              [48.11347656250001, 29.87021484375],
              [48.1201171875, 29.886328125],
              [48.13886718750001, 29.89658203125],
              [48.15859375000002, 29.9595703125],
              [48.18476562500001, 29.978857421875],
              [48.22773437500001, 29.936328125],
              [48.348242187500006, 29.782666015624997],
              [48.34736328125001, 29.719970703125],
              [48.34023437500002, 29.694726562499994],
              [48.275390625, 29.624316406249996],
            ],
          ],
          [
            [
              [48.442480468750006, 28.542919921874997],
              [48.26875, 28.54052734375],
              [48.04960937500002, 28.5375],
              [47.87197265625002, 28.535449218749996],
              [47.67128906250002, 28.533154296874997],
              [47.583105468750006, 28.627978515624996],
              [47.55322265625, 28.73154296875],
              [47.52128906250002, 28.837841796874997],
              [47.433203125, 28.989550781249996],
              [47.138769531250006, 29.026171875],
              [46.98222656250002, 29.045654296875],
              [46.72480468750001, 29.074609375],
              [46.53144531250001, 29.096240234374996],
              [46.69375, 29.259667968749994],
              [46.76933593750002, 29.347460937499996],
              [46.905859375, 29.5375],
              [46.97597656250002, 29.6728515625],
              [47.04365234375001, 29.822998046875],
              [47.10205078125, 29.939990234374996],
              [47.114355468750006, 29.961328125],
              [47.14824218750002, 30.0009765625],
              [47.223242187500006, 30.04150390625],
              [47.33134765625002, 30.0796875],
              [47.51484375000001, 30.096484375],
              [47.64375, 30.097314453124994],
              [47.67275390625002, 30.095605468749994],
              [47.75390625, 30.076611328124997],
              [47.97871093750001, 29.9828125],
              [47.9736328125, 29.9458984375],
              [48.00566406250002, 29.835791015625],
              [48.07734375000001, 29.715576171875],
              [48.13613281250002, 29.618115234374997],
              [48.14345703125002, 29.572460937499997],
              [48.089453125, 29.5791015625],
              [48.04833984375, 29.597509765625],
              [47.96962890625002, 29.61669921875],
              [47.817480468750006, 29.48740234375],
              [47.72529296875001, 29.416943359374997],
              [47.72265625, 29.393017578124997],
              [47.8453125, 29.36572265625],
              [47.93535156250002, 29.366601562499994],
              [47.998144531250006, 29.385546875],
              [48.05146484375001, 29.355371093749994],
              [48.08632812500002, 29.27548828125],
              [48.10039062500002, 29.210742187499996],
              [48.18378906250001, 28.979394531249994],
              [48.2529296875, 28.901269531249994],
              [48.33925781250002, 28.76328125],
              [48.37128906250001, 28.691845703124997],
              [48.3896484375, 28.631591796875],
              [48.442480468750006, 28.542919921874997],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 4,
        labelrank: 2,
        sovereignt: "Saudi Arabia",
        sov_a3: "SAU",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Saudi Arabia",
        adm0_a3: "SAU",
        geou_dif: 0,
        geounit: "Saudi Arabia",
        gu_a3: "SAU",
        su_dif: 0,
        subunit: "Saudi Arabia",
        su_a3: "SAU",
        brk_diff: 0,
        name: "Saudi Arabia",
        name_long: "Saudi Arabia",
        brk_a3: "SAU",
        brk_name: "Saudi Arabia",
        brk_group: null,
        abbrev: "Saud.",
        postal: "SA",
        formal_en: "Kingdom of Saudi Arabia",
        formal_fr: null,
        name_ciawf: "Saudi Arabia",
        note_adm0: null,
        note_brk: null,
        name_sort: "Saudi Arabia",
        name_alt: null,
        mapcolor7: 6,
        mapcolor8: 1,
        mapcolor9: 6,
        mapcolor13: 7,
        pop_est: 34268528,
        pop_rank: 15,
        pop_year: 2019,
        gdp_md: 792966,
        gdp_year: 2019,
        economy: "2. Developed region: nonG7",
        income_grp: "2. High income: nonOECD",
        fips_10: "SA",
        iso_a2: "SA",
        iso_a2_eh: "SA",
        iso_a3: "SAU",
        iso_a3_eh: "SAU",
        iso_n3: "682",
        iso_n3_eh: "682",
        un_a3: "682",
        wb_a2: "SA",
        wb_a3: "SAU",
        woe_id: 23424938,
        woe_id_eh: 23424938,
        woe_note: "Exact WOE match as country",
        adm0_iso: "SAU",
        adm0_diff: null,
        adm0_tlc: "SAU",
        adm0_a3_us: "SAU",
        adm0_a3_fr: "SAU",
        adm0_a3_ru: "SAU",
        adm0_a3_es: "SAU",
        adm0_a3_cn: "SAU",
        adm0_a3_tw: "SAU",
        adm0_a3_in: "SAU",
        adm0_a3_np: "SAU",
        adm0_a3_pk: "SAU",
        adm0_a3_de: "SAU",
        adm0_a3_gb: "SAU",
        adm0_a3_br: "SAU",
        adm0_a3_il: "SAU",
        adm0_a3_ps: "SAU",
        adm0_a3_sa: "SAU",
        adm0_a3_eg: "SAU",
        adm0_a3_ma: "SAU",
        adm0_a3_pt: "SAU",
        adm0_a3_ar: "SAU",
        adm0_a3_jp: "SAU",
        adm0_a3_ko: "SAU",
        adm0_a3_vn: "SAU",
        adm0_a3_tr: "SAU",
        adm0_a3_id: "SAU",
        adm0_a3_pl: "SAU",
        adm0_a3_gr: "SAU",
        adm0_a3_it: "SAU",
        adm0_a3_nl: "SAU",
        adm0_a3_se: "SAU",
        adm0_a3_bd: "SAU",
        adm0_a3_ua: "SAU",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Western Asia",
        region_wb: "Middle East & North Africa",
        name_len: 12,
        long_len: 12,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 1.7,
        max_label: 7,
        label_x: 44.6996,
        label_y: 23.806908,
        ne_id: 1159321225,
        wikidataid: "Q851",
        name_ar: "السعودية",
        name_bn: "সৌদি আরব",
        name_de: "Saudi-Arabien",
        name_en: "Saudi Arabia",
        name_es: "Arabia Saudita",
        name_fa: "عربستان سعودی",
        name_fr: "Arabie saoudite",
        name_el: "Σαουδική Αραβία",
        name_he: "ערב הסעודית",
        name_hi: "सउदी अरब",
        name_hu: "Szaúd-Arábia",
        name_id: "Arab Saudi",
        name_it: "Arabia Saudita",
        name_ja: "サウジアラビア",
        name_ko: "사우디아라비아",
        name_nl: "Saoedi-Arabië",
        name_pl: "Arabia Saudyjska",
        name_pt: "Arábia Saudita",
        name_ru: "Саудовская Аравия",
        name_sv: "Saudiarabien",
        name_tr: "Suudi Arabistan",
        name_uk: "Саудівська Аравія",
        name_ur: "سعودی عرب",
        name_vi: "Ả Rập Saudi",
        name_zh: "沙特阿拉伯",
        name_zht: "沙烏地阿拉伯",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "SAU.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [36.90166015625002, 25.383056640625],
              [36.87519531250001, 25.383056640625],
              [36.80507812500002, 25.450732421875003],
              [36.76386718750001, 25.50058593749999],
              [36.72207031250002, 25.53403320312499],
              [36.5302734375, 25.6015625],
              [36.50429687500002, 25.64511718749999],
              [36.53359375000002, 25.688720703125],
              [36.55410156250002, 25.64536132812499],
              [36.58876953125002, 25.619824218749997],
              [36.74755859375, 25.558740234374994],
              [36.924414062500006, 25.425537109375],
              [36.95478515625001, 25.41464843749999],
              [36.90166015625002, 25.383056640625],
            ],
          ],
          [
            [
              [41.98769531250002, 16.715625],
              [42.065039062500006, 16.71005859374999],
              [42.033203125, 16.741943359375],
              [42.0263671875, 16.75766601562499],
              [42.05996093750002, 16.803515625],
              [42.17041015625, 16.708642578124994],
              [42.16718750000001, 16.596386718749997],
              [42.1578125, 16.570703125],
              [42.12773437500002, 16.59482421874999],
              [42.1083984375, 16.618457031250003],
              [42.10234375000002, 16.643945312499994],
              [42.07177734375, 16.671484375],
              [41.96416015625002, 16.653466796874994],
              [41.89726562500002, 16.684277343749997],
              [41.80156250000002, 16.778759765624997],
              [41.77607421875001, 16.846875],
              [41.81611328125001, 16.86015625],
              [41.85820312500002, 16.89291992187499],
              [41.88496093750001, 16.946826171875003],
              [41.860449218750006, 17.00253906249999],
              [41.91728515625002, 16.99365234375],
              [41.94794921875001, 16.936425781249994],
              [41.95390625000002, 16.80625],
              [41.9625, 16.77866210937499],
              [41.94667968750002, 16.748925781249994],
              [41.98769531250002, 16.715625],
            ],
          ],
          [
            [
              [51.977636718750006, 18.996142578125003],
              [51.74296875000002, 18.964550781249997],
              [51.51494140625002, 18.933886718750003],
              [51.258398437500006, 18.899365234374997],
              [50.95, 18.857861328124997],
              [50.70820312500001, 18.82529296874999],
              [50.35527343750002, 18.777783203124997],
              [50.03896484375002, 18.735253906249994],
              [49.74208984375002, 18.6953125],
              [49.44511718750002, 18.655322265625003],
              [49.1923828125, 18.621337890625],
              [49.0419921875, 18.581787109375],
              [48.86484375, 18.49521484374999],
              [48.59296875000001, 18.36240234374999],
              [48.31582031250002, 18.22705078125],
              [48.17216796875002, 18.156933593749997],
              [48.02167968750001, 17.976953125],
              [47.94550781250001, 17.88583984374999],
              [47.80781250000001, 17.72109375],
              [47.703710937500006, 17.596826171874994],
              [47.57958984375, 17.44833984374999],
              [47.525390625, 17.316113281249997],
              [47.44179687500002, 17.111865234375003],
              [47.36962890625, 17.06040039062499],
              [47.25126953125002, 16.993945312500003],
              [47.1435546875, 16.946679687499994],
              [46.975683593750006, 16.95346679687499],
              [46.879980468750006, 17.079003906249994],
              [46.77851562500001, 17.212109375],
              [46.727636718750006, 17.265576171874997],
              [46.68203125000002, 17.2685546875],
              [46.51347656250002, 17.251660156249997],
              [46.31035156250002, 17.23129882812499],
              [46.07080078125, 17.253173828125],
              [45.79443359375, 17.27841796874999],
              [45.53535156250001, 17.302050781250003],
              [45.40654296875002, 17.319775390624997],
              [45.23662109375002, 17.406201171874997],
              [45.19277343750002, 17.423388671875003],
              [45.148046875, 17.427441406249997],
              [44.94648437500001, 17.429589843749994],
              [44.74677734375001, 17.431689453125003],
              [44.546484375, 17.404345703125003],
              [44.35468750000001, 17.414355468750003],
              [44.15595703125001, 17.39853515624999],
              [44.0859375, 17.365527343750003],
              [44.00820312500002, 17.367480468750003],
              [43.95966796875001, 17.338330078124997],
              [43.9169921875, 17.32470703125],
              [43.86640625000001, 17.349609375],
              [43.804296875, 17.344140625],
              [43.712988281250006, 17.365527343750003],
              [43.653417968750006, 17.421875],
              [43.597265625, 17.471435546875],
              [43.53925781250001, 17.498730468749997],
              [43.47421875, 17.515917968750003],
              [43.41796875, 17.516259765624994],
              [43.34609375000002, 17.486035156249997],
              [43.30214843750002, 17.456787109375],
              [43.19091796875, 17.359375],
              [43.18632812500002, 17.32470703125],
              [43.236914062500006, 17.26645507812499],
              [43.22138671875001, 17.2392578125],
              [43.15595703125001, 17.205029296874997],
              [43.13593750000001, 17.112988281249997],
              [43.12617187500001, 17.062451171874997],
              [43.11650390625002, 16.94199218749999],
              [43.145605468750006, 16.84677734374999],
              [43.18447265625002, 16.811816406250003],
              [43.18632812500002, 16.77099609375],
              [43.1650390625, 16.689404296874997],
              [43.10478515625002, 16.66416015624999],
              [43.06074218750001, 16.586621093749997],
              [43.03359375000002, 16.550390625],
              [42.986328125, 16.509082031250003],
              [42.79931640625, 16.371777343749997],
              [42.78984375000002, 16.4515625],
              [42.73066406250001, 16.56982421875],
              [42.72636718750002, 16.6533203125],
              [42.698828125, 16.736962890624994],
              [42.6474609375, 16.80136718749999],
              [42.55292968750001, 16.868457031250003],
              [42.54414062500001, 16.959667968749997],
              [42.475, 17.049853515625003],
              [42.38330078125, 17.122460937499994],
              [42.33242187500002, 17.256640625],
              [42.2939453125, 17.434960937499994],
              [42.05224609375, 17.669335937499994],
              [41.75, 17.8857421875],
              [41.65800781250002, 18.00766601562499],
              [41.50761718750002, 18.256103515625],
              [41.431738281250006, 18.452441406250003],
              [41.2294921875, 18.678417968749997],
              [41.220800781250006, 18.765234375],
              [41.19082031250002, 18.87119140624999],
              [41.144140625, 18.9890625],
              [41.11601562500002, 19.082177734374994],
              [40.91328125000001, 19.490136718749994],
              [40.84785156250001, 19.55527343749999],
              [40.791601562500006, 19.646386718749994],
              [40.77705078125001, 19.71689453124999],
              [40.75917968750002, 19.75546875],
              [40.61591796875001, 19.82236328124999],
              [40.48222656250002, 19.993457031250003],
              [40.080664062500006, 20.265917968750003],
              [39.88408203125002, 20.29296875],
              [39.72832031250002, 20.390332031249997],
              [39.61367187500002, 20.51767578124999],
              [39.4912109375, 20.737011718749997],
              [39.27607421875001, 20.97397460937499],
              [39.09355468750002, 21.310351562500003],
              [39.15068359375002, 21.432763671874994],
              [39.147070312500006, 21.518994140624997],
              [39.09101562500001, 21.663964843749994],
              [39.02978515625, 21.77597656249999],
              [38.987890625, 21.881738281249994],
              [39.02119140625001, 22.033447265625],
              [39.03398437500002, 22.203369140625],
              [39.069921875, 22.293652343749997],
              [39.09589843750001, 22.392773437499997],
              [39.06201171875, 22.5921875],
              [39.00136718750002, 22.698974609375],
              [39.007421875, 22.770068359375003],
              [38.93876953125002, 22.80478515624999],
              [38.882910156250006, 22.88203125],
              [38.94111328125001, 22.8818359375],
              [38.835546875, 22.9890625],
              [38.796875, 23.048583984375],
              [38.75703125000001, 23.194287109374997],
              [38.7060546875, 23.30551757812499],
              [38.54228515625002, 23.557910156250003],
              [38.46416015625002, 23.711865234374997],
              [38.28886718750002, 23.91098632812499],
              [38.0986328125, 24.058007812499994],
              [37.97783203125002, 24.124560546875003],
              [37.91972656250002, 24.18540039062499],
              [37.82099609375001, 24.1875],
              [37.71337890625, 24.2744140625],
              [37.63818359375, 24.277734375],
              [37.543066406250006, 24.291650390624994],
              [37.43095703125002, 24.459033203125003],
              [37.338476562500006, 24.61582031249999],
              [37.18085937500001, 24.820019531249997],
              [37.22041015625001, 24.873339843750003],
              [37.26630859375001, 24.96005859374999],
              [37.24345703125002, 25.0734375],
              [37.218359375, 25.150683593750003],
              [37.14882812500002, 25.29111328124999],
              [36.92070312500002, 25.641162109375003],
              [36.86015625000002, 25.69248046874999],
              [36.7626953125, 25.75131835937499],
              [36.70253906250002, 25.902880859375003],
              [36.67519531250002, 26.038867187499996],
              [36.51875, 26.104882812499994],
              [36.249609375, 26.594775390624996],
              [36.09375, 26.765820312499997],
              [36.03203125000002, 26.881005859374994],
              [35.851660156250006, 27.070458984374994],
              [35.76298828125002, 27.2587890625],
              [35.58134765625002, 27.432470703125],
              [35.423828125, 27.733789062499994],
              [35.18046875000002, 28.034863281249997],
              [35.07832031250001, 28.08701171875],
              [34.82753906250002, 28.10859375],
              [34.72207031250002, 28.130664062499996],
              [34.625, 28.064501953124996],
              [34.6162109375, 28.148339843749994],
              [34.68330078125001, 28.264111328124997],
              [34.779882812500006, 28.50732421875],
              [34.79912109375002, 28.720507812499996],
              [34.95078125, 29.353515625],
              [35.16376953125001, 29.320947265624994],
              [35.33916015625002, 29.294091796874994],
              [35.5953125, 29.2548828125],
              [35.8603515625, 29.214257812499994],
              [36.01542968750002, 29.190478515624996],
              [36.068457031250006, 29.200537109375],
              [36.2828125, 29.355371093749994],
              [36.47607421875, 29.4951171875],
              [36.591796875, 29.66611328125],
              [36.70390625000002, 29.831640625],
              [36.75527343750002, 29.866015625],
              [36.92705078125002, 29.8970703125],
              [37.19941406250001, 29.9462890625],
              [37.46923828125, 29.995068359374997],
              [37.49072265625, 30.01171875],
              [37.55361328125002, 30.144580078124996],
              [37.63359375000002, 30.31328125],
              [37.64990234375, 30.330957031249994],
              [37.66972656250002, 30.34814453125],
              [37.862890625, 30.442626953125],
              [37.980078125, 30.5],
              [37.81298828125, 30.669287109375],
              [37.65546875000001, 30.828955078125],
              [37.47900390625, 31.007763671874997],
              [37.32949218750002, 31.146826171875],
              [37.10527343750002, 31.355175781249997],
              [36.95859375, 31.491503906249996],
              [37.215625, 31.556103515624997],
              [37.49335937500001, 31.625878906249994],
              [37.77382812500002, 31.696337890624996],
              [38.111425781250006, 31.781152343749994],
              [38.37548828125, 31.847460937499996],
              [38.769628906250006, 31.946484375],
              [38.96230468750002, 31.994921875],
              [38.9970703125, 32.007470703124994],
              [39.14541015625002, 32.12451171875],
              [39.36865234375, 32.091748046875],
              [39.7041015625, 32.042529296874996],
              [40.02783203125, 31.995019531249994],
              [40.36933593750001, 31.93896484375],
              [40.47890625000002, 31.893359375],
              [40.80839843750002, 31.725439453125],
              [41.0224609375, 31.616357421874994],
              [41.2724609375, 31.489013671875],
              [41.585058593750006, 31.329736328124994],
              [41.79970703125002, 31.220361328124994],
              [42.07441406250001, 31.080371093749996],
              [42.28857421875, 30.92041015625],
              [42.55976562500001, 30.7177734375],
              [42.85771484375002, 30.49521484375],
              [43.103125, 30.322216796874997],
              [43.44082031250002, 30.083984375],
              [43.77373046875002, 29.84921875],
              [44.099609375, 29.619335937499997],
              [44.36074218750002, 29.435253906249997],
              [44.69082031250002, 29.20234375],
              [44.71650390625001, 29.193603515625],
              [45.05029296875, 29.16708984375],
              [45.49892578125002, 29.131542968749997],
              [45.94970703125, 29.095849609374994],
              [46.3564453125, 29.063671875],
              [46.53144531250001, 29.096240234374996],
              [46.72480468750001, 29.074609375],
              [46.98222656250002, 29.045654296875],
              [47.138769531250006, 29.026171875],
              [47.433203125, 28.989550781249996],
              [47.52128906250002, 28.837841796874997],
              [47.55322265625, 28.73154296875],
              [47.583105468750006, 28.627978515624996],
              [47.67128906250002, 28.533154296874997],
              [47.87197265625002, 28.535449218749996],
              [48.04960937500002, 28.5375],
              [48.26875, 28.54052734375],
              [48.442480468750006, 28.542919921874997],
              [48.49853515625, 28.448876953124994],
              [48.523046875, 28.355029296874996],
              [48.62636718750002, 28.132568359375],
              [48.77373046875002, 27.95908203125],
              [48.80898437500002, 27.895898437499994],
              [48.83281250000002, 27.800683593749994],
              [48.807226562500006, 27.765283203124994],
              [48.79716796875002, 27.724316406249997],
              [48.90644531250001, 27.629052734374994],
              [49.0869140625, 27.548583984375],
              [49.15751953125002, 27.528222656249994],
              [49.2375, 27.492724609374996],
              [49.17509765625002, 27.437646484374994],
              [49.28154296875002, 27.310498046874997],
              [49.4052734375, 27.180957031249996],
              [49.537695312500006, 27.151757812499994],
              [49.71650390625001, 26.955859375],
              [49.98613281250002, 26.82890625],
              [50.14980468750002, 26.662646484374996],
              [50.13466796875002, 26.659521484375],
              [50.08662109375001, 26.676416015624994],
              [50.0263671875, 26.69921875],
              [50.00810546875002, 26.678515625],
              [50.011328125, 26.608789062499994],
              [50.02734375, 26.52685546875],
              [50.11074218750002, 26.455957031249994],
              [50.18496093750002, 26.404931640624994],
              [50.2138671875, 26.308496093749994],
              [50.15546875000001, 26.100537109374997],
              [50.13525390625, 26.10068359375],
              [50.09599609375002, 26.118701171874996],
              [50.05390625000001, 26.122851562499996],
              [50.03164062500002, 26.110986328124994],
              [50.0810546875, 25.961376953124997],
              [50.13027343750002, 25.846630859374997],
              [50.18964843750001, 25.755810546874997],
              [50.23896484375001, 25.622851562500003],
              [50.28125, 25.566113281249997],
              [50.455175781250006, 25.4248046875],
              [50.50849609375001, 25.306689453125003],
              [50.55791015625002, 25.086669921875],
              [50.66689453125002, 24.963818359374997],
              [50.7255859375, 24.869384765625],
              [50.80439453125001, 24.789257812499997],
              [50.85566406250001, 24.679638671874997],
              [50.928320312500006, 24.595117187499994],
              [50.96601562500001, 24.573925781249997],
              [51.02275390625002, 24.565234375],
              [51.093359375, 24.564648437499997],
              [51.17802734375002, 24.58671875],
              [51.26796875000002, 24.607226562500003],
              [51.338476562500006, 24.564355468749994],
              [51.411230468750006, 24.57080078125],
              [51.41835937500002, 24.530957031249997],
              [51.36992187500002, 24.47690429687499],
              [51.30986328125002, 24.340380859375003],
              [51.39521484375001, 24.31884765625],
              [51.47675781250001, 24.308203125],
              [51.534765625, 24.286328125],
              [51.568359375, 24.286181640625003],
              [51.568359375, 24.25791015624999],
              [51.57216796875002, 24.128320312499994],
              [51.59257812500002, 24.078857421875],
              [51.62929687500002, 24.03500976562499],
              [51.684375, 23.96953125],
              [51.739355468750006, 23.904003906249997],
              [51.79433593750002, 23.83847656249999],
              [51.84941406250002, 23.772998046875003],
              [51.904394531250006, 23.70751953125],
              [51.95947265625, 23.641992187499994],
              [52.01445312500002, 23.576464843750003],
              [52.069433593750006, 23.510986328125],
              [52.12451171875, 23.445458984374994],
              [52.17949218750002, 23.37998046874999],
              [52.23457031250001, 23.314453125],
              [52.28955078125, 23.248974609374997],
              [52.34453125000002, 23.183496093749994],
              [52.39960937500001, 23.11796875],
              [52.45458984375, 23.052441406249997],
              [52.50957031250002, 22.986962890624994],
              [52.55507812500002, 22.9328125],
              [52.63916015625, 22.922509765624994],
              [52.66591796875002, 22.91928710937499],
              [52.74160156250002, 22.91000976562499],
              [52.85927734375002, 22.89560546874999],
              [53.01191406250001, 22.877001953125003],
              [53.1923828125, 22.854931640624997],
              [53.39404296875, 22.830322265625],
              [53.60957031250001, 22.804003906250003],
              [53.832128906250006, 22.776806640624997],
              [54.05458984375002, 22.749658203124994],
              [54.270117187500006, 22.723339843749997],
              [54.4716796875, 22.69873046875],
              [54.65224609375002, 22.676660156249994],
              [54.80488281250001, 22.658007812500003],
              [54.922460937500006, 22.64365234374999],
              [54.99824218750001, 22.634375],
              [55.025, 22.631152343750003],
              [55.10429687500002, 22.621484375],
              [55.11943359375002, 22.623925781249994],
              [55.18583984375002, 22.7041015625],
              [55.25927734375, 22.59091796874999],
              [55.32011718750002, 22.49692382812499],
              [55.40380859375, 22.367822265624994],
              [55.492773437500006, 22.230664062499997],
              [55.577734375, 22.099511718749994],
              [55.64101562500002, 22.001855468749994],
              [55.607421875, 21.900390625],
              [55.57080078125, 21.789697265624994],
              [55.5341796875, 21.679003906250003],
              [55.49755859375, 21.568310546874997],
              [55.4609375, 21.45761718749999],
              [55.42431640625, 21.346923828125],
              [55.3876953125, 21.236230468749994],
              [55.35107421875, 21.125537109375003],
              [55.314453125, 21.014794921874994],
              [55.277929687500006, 20.904101562500003],
              [55.2412109375, 20.793408203124997],
              [55.20458984375, 20.68271484374999],
              [55.168066406250006, 20.572021484375],
              [55.131445312500006, 20.461328125],
              [55.0947265625, 20.350634765625003],
              [55.058203125, 20.239941406249997],
              [55.021484375, 20.12924804687499],
              [54.97734375000002, 19.99594726562499],
              [54.87109375, 19.960498046875003],
              [54.69902343750002, 19.903125],
              [54.52705078125001, 19.84580078124999],
              [54.35498046875, 19.788476562499994],
              [54.18300781250002, 19.731152343749997],
              [54.01093750000001, 19.673828125],
              [53.8388671875, 19.616503906250003],
              [53.66689453125002, 19.559130859375003],
              [53.49482421875001, 19.50180664062499],
              [53.322851562500006, 19.444482421874994],
              [53.15078125000002, 19.387158203124997],
              [52.97871093750001, 19.329785156249997],
              [52.806738281250006, 19.2724609375],
              [52.63466796875002, 19.215136718750003],
              [52.46269531250002, 19.1578125],
              [52.290625, 19.100488281249994],
              [52.11855468750002, 19.043164062499997],
              [51.977636718750006, 18.996142578125003],
            ],
          ],
          [
            [
              [36.59550781250002, 25.712792968749994],
              [36.58613281250001, 25.69921875],
              [36.5439453125, 25.734277343749994],
              [36.546484375, 25.81162109374999],
              [36.58271484375001, 25.855517578125003],
              [36.57988281250002, 25.79541015625],
              [36.59560546875002, 25.73486328125],
              [36.59550781250002, 25.712792968749994],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 4,
        labelrank: 3,
        sovereignt: "Thailand",
        sov_a3: "THA",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Thailand",
        adm0_a3: "THA",
        geou_dif: 0,
        geounit: "Thailand",
        gu_a3: "THA",
        su_dif: 0,
        subunit: "Thailand",
        su_a3: "THA",
        brk_diff: 0,
        name: "Thailand",
        name_long: "Thailand",
        brk_a3: "THA",
        brk_name: "Thailand",
        brk_group: null,
        abbrev: "Thai.",
        postal: "TH",
        formal_en: "Kingdom of Thailand",
        formal_fr: null,
        name_ciawf: "Thailand",
        note_adm0: null,
        note_brk: null,
        name_sort: "Thailand",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 6,
        mapcolor9: 8,
        mapcolor13: 1,
        pop_est: 69625582,
        pop_rank: 16,
        pop_year: 2019,
        gdp_md: 543548,
        gdp_year: 2019,
        economy: "5. Emerging region: G20",
        income_grp: "3. Upper middle income",
        fips_10: "TH",
        iso_a2: "TH",
        iso_a2_eh: "TH",
        iso_a3: "THA",
        iso_a3_eh: "THA",
        iso_n3: "764",
        iso_n3_eh: "764",
        un_a3: "764",
        wb_a2: "TH",
        wb_a3: "THA",
        woe_id: 23424960,
        woe_id_eh: 23424960,
        woe_note: "Exact WOE match as country",
        adm0_iso: "THA",
        adm0_diff: null,
        adm0_tlc: "THA",
        adm0_a3_us: "THA",
        adm0_a3_fr: "THA",
        adm0_a3_ru: "THA",
        adm0_a3_es: "THA",
        adm0_a3_cn: "THA",
        adm0_a3_tw: "THA",
        adm0_a3_in: "THA",
        adm0_a3_np: "THA",
        adm0_a3_pk: "THA",
        adm0_a3_de: "THA",
        adm0_a3_gb: "THA",
        adm0_a3_br: "THA",
        adm0_a3_il: "THA",
        adm0_a3_ps: "THA",
        adm0_a3_sa: "THA",
        adm0_a3_eg: "THA",
        adm0_a3_ma: "THA",
        adm0_a3_pt: "THA",
        adm0_a3_ar: "THA",
        adm0_a3_jp: "THA",
        adm0_a3_ko: "THA",
        adm0_a3_vn: "THA",
        adm0_a3_tr: "THA",
        adm0_a3_id: "THA",
        adm0_a3_pl: "THA",
        adm0_a3_gr: "THA",
        adm0_a3_it: "THA",
        adm0_a3_nl: "THA",
        adm0_a3_se: "THA",
        adm0_a3_bd: "THA",
        adm0_a3_ua: "THA",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "South-Eastern Asia",
        region_wb: "East Asia & Pacific",
        name_len: 8,
        long_len: 8,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 2.7,
        max_label: 8,
        label_x: 101.073198,
        label_y: 15.45974,
        ne_id: 1159321305,
        wikidataid: "Q869",
        name_ar: "تايلاند",
        name_bn: "থাইল্যান্ড",
        name_de: "Thailand",
        name_en: "Thailand",
        name_es: "Tailandia",
        name_fa: "تایلند",
        name_fr: "Thaïlande",
        name_el: "Ταϊλάνδη",
        name_he: "תאילנד",
        name_hi: "थाईलैण्ड",
        name_hu: "Thaiföld",
        name_id: "Thailand",
        name_it: "Thailandia",
        name_ja: "タイ王国",
        name_ko: "태국",
        name_nl: "Thailand",
        name_pl: "Tajlandia",
        name_pt: "Tailândia",
        name_ru: "Таиланд",
        name_sv: "Thailand",
        name_tr: "Tayland",
        name_uk: "Таїланд",
        name_ur: "تھائی لینڈ",
        name_vi: "Thái Lan",
        name_zh: "泰国",
        name_zht: "泰國",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "THA.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [102.6064453125, 11.676513671875],
              [102.58994140625003, 11.572167968749994],
              [102.53281250000003, 11.614941406249997],
              [102.54648437500003, 11.667773437499989],
              [102.56894531250003, 11.691699218749989],
              [102.6064453125, 11.676513671875],
            ],
          ],
          [
            [
              [102.4267578125, 11.988720703124997],
              [102.42998046874999, 11.964746093749994],
              [102.378125, 11.982958984374989],
              [102.35996093750003, 11.974414062499989],
              [102.30195312500001, 11.980810546874991],
              [102.27333984375002, 12.119335937499997],
              [102.27744140625003, 12.15185546875],
              [102.31884765625, 12.141650390624989],
              [102.378125, 12.072851562499991],
              [102.40839843750001, 12.025097656249997],
              [102.4267578125, 11.988720703124997],
            ],
          ],
          [
            [
              [98.40908203125002, 7.902050781249997],
              [98.3984375, 7.828417968749989],
              [98.357421875, 7.829443359374991],
              [98.315625, 7.782324218749991],
              [98.29628906250002, 7.776074218749997],
              [98.26230468750003, 7.926074218749989],
              [98.30136718750003, 8.13623046875],
              [98.32207031249999, 8.166308593749989],
              [98.35097656250002, 8.110644531249989],
              [98.43496093750002, 8.085644531249997],
              [98.39882812500002, 7.964550781249997],
              [98.40908203125002, 7.902050781249997],
            ],
          ],
          [
            [
              [100.12246093750002, 20.316650390625],
              [100.11494140625001, 20.25766601562499],
              [100.13974609375003, 20.245410156250003],
              [100.17412109374999, 20.272753906250003],
              [100.21806640624999, 20.339599609375],
              [100.26601562500002, 20.37729492187499],
              [100.31796875000003, 20.385888671874994],
              [100.37314453125003, 20.340380859375003],
              [100.43154296875002, 20.24072265625],
              [100.49160156250002, 20.18408203125],
              [100.51953125, 20.177929687499997],
              [100.53994140625002, 20.132373046875003],
              [100.54306640625003, 20.088671875],
              [100.51455078125002, 19.996337890625],
              [100.46621093750002, 19.888916015625],
              [100.39765625000001, 19.756103515625],
              [100.42011718750001, 19.644482421874997],
              [100.51357421875002, 19.553466796875],
              [100.62548828125, 19.49985351562509],
              [100.74394531249999, 19.51474609374999],
              [100.80683593750001, 19.541943359374955],
              [100.85820312499999, 19.58505859374999],
              [100.90605468749993, 19.60537109375008],
              [100.96650390625001, 19.610791015624997],
              [101.15468750000002, 19.57919921874999],
              [101.2119140625, 19.54833984375],
              [101.22080078125003, 19.486621093750003],
              [101.19755859374999, 19.327929687500003],
              [101.2265625, 19.21152343749999],
              [101.27988281250003, 19.088916015625003],
              [101.28632812499995, 18.977148437500034],
              [101.22050781249999, 18.792773437500003],
              [101.16552734375, 18.618310546874994],
              [101.10634765625002, 18.53354492187499],
              [101.06044921875002, 18.47900390625],
              [101.04697265625003, 18.441992187500034],
              [101.05058593749999, 18.40703125],
              [101.0927734375, 18.354541015625003],
              [101.1375, 18.286865234375],
              [101.14873046874999, 18.22216796875],
              [101.14394531250002, 18.142626953125003],
              [101.11328125, 18.03354492187499],
              [100.9990234375, 17.797167968750003],
              [100.90849609374999, 17.583886718749994],
              [100.95585937499999, 17.54111328124999],
              [101.04570312499999, 17.509960937499997],
              [101.10517578125001, 17.479541015625102],
              [101.16748046875, 17.4990234375],
              [101.29970703125002, 17.625],
              [101.41367187500003, 17.71875],
              [101.55507812500002, 17.81235351562499],
              [101.56367187500001, 17.820507812499997],
              [101.6875, 17.889404296875114],
              [101.744140625, 17.952685546875045],
              [101.77480468750002, 18.033398437500125],
              [101.81865234374999, 18.064648437499997],
              [101.87548828125, 18.046435546875003],
              [101.94746093750001, 18.081494140624955],
              [102.03457031250002, 18.169824218749994],
              [102.10146484375002, 18.210644531249997],
              [102.14824218749999, 18.203857421875],
              [102.23164062500001, 18.148974609375045],
              [102.35185546874999, 18.045947265625045],
              [102.45878906249999, 17.984619140625],
              [102.55253906249999, 17.965087890625],
              [102.59824218750003, 17.9267578125],
              [102.59609375000002, 17.86962890625],
              [102.61679687500003, 17.83334960937499],
              [102.66064453125, 17.81796875],
              [102.68007812500002, 17.824121093749994],
              [102.67519531250002, 17.851757812499997],
              [102.71757812499999, 17.892236328124994],
              [102.80742187499999, 17.945556640625],
              [102.89863281250001, 17.97690429687499],
              [102.99140625000001, 17.986230468749994],
              [103.05136718750003, 18.028515625],
              [103.09121093750002, 18.138232421875003],
              [103.14853515625003, 18.221728515625003],
              [103.19970703125, 18.259472656249997],
              [103.26318359375, 18.278466796874994],
              [103.27958984374999, 18.304980468750045],
              [103.24892578125002, 18.33896484374999],
              [103.25175781249999, 18.373486328125125],
              [103.28828124999995, 18.408398437499955],
              [103.36699218749999, 18.42333984375],
              [103.48798828124995, 18.41816406250001],
              [103.62968749999999, 18.382568359375],
              [103.79228515624999, 18.31650390625009],
              [103.89882812500002, 18.295312500000023],
              [103.94960937500002, 18.318994140624994],
              [104.04873046875002, 18.216699218749966],
              [104.19619140625002, 17.98837890624999],
              [104.32265625000002, 17.815820312499994],
              [104.428125, 17.698974609375057],
              [104.53925781250001, 17.609277343749994],
              [104.65585937500003, 17.54672851562499],
              [104.73964843750002, 17.461669921875],
              [104.81601562499998, 17.30029296875],
              [104.75898437500001, 17.0771484375],
              [104.74355468750002, 16.884375],
              [104.75058593750003, 16.64755859374999],
              [104.81933593749994, 16.466064453125057],
              [104.94990234375001, 16.33994140624999],
              [105.02578125000002, 16.237988281249997],
              [105.04716796874999, 16.16025390624999],
              [105.14873046874999, 16.093554687500003],
              [105.33066406250003, 16.037890625],
              [105.40625, 15.987451171875023],
              [105.37558593750003, 15.9421875],
              [105.37324218750001, 15.889697265625045],
              [105.39892578125, 15.829882812500003],
              [105.46201171875003, 15.780419921874994],
              [105.56240234375002, 15.741259765625003],
              [105.6220703125, 15.699951171875],
              [105.64101562500002, 15.656542968749932],
              [105.63886718750001, 15.5859375],
              [105.615625, 15.48828125],
              [105.57373046875, 15.413232421874994],
              [105.51318359375, 15.360888671875003],
              [105.505859375, 15.319628906250045],
              [105.49042968750001, 15.256591796875],
              [105.49042968750001, 15.127587890624994],
              [105.53339843750001, 15.041601562499991],
              [105.54667968749999, 14.932470703125034],
              [105.52304687500003, 14.843310546875003],
              [105.50019531250001, 14.661230468749991],
              [105.49736328124999, 14.590673828124991],
              [105.4755859375, 14.530126953124991],
              [105.42265624999993, 14.471630859374955],
              [105.34218750000002, 14.416699218749997],
              [105.24365234375, 14.367871093749997],
              [105.18330078125001, 14.346240234375003],
              [105.16914062500001, 14.336083984374994],
              [105.1259765625, 14.280957031249997],
              [105.07412109375002, 14.227441406249994],
              [105.03369140625, 14.227392578124991],
              [105.00341796875, 14.254443359375003],
              [104.982421875, 14.289453125],
              [104.9697265625, 14.366113281249994],
              [104.87880859375002, 14.404003906249997],
              [104.77900390625001, 14.427832031249991],
              [104.57578125000003, 14.390039062499994],
              [104.41162109375, 14.369580078124997],
              [104.22773437500001, 14.3955078125],
              [104.05429687500003, 14.362744140624997],
              [103.98183593750002, 14.35791015625],
              [103.89863281250001, 14.36279296875],
              [103.818359375, 14.362158203124991],
              [103.74189453125001, 14.374169921874994],
              [103.60039062499999, 14.42109375],
              [103.54638671875, 14.417431640624997],
              [103.43242187499999, 14.378613281249997],
              [103.3134765625, 14.351318359375],
              [103.19941406250001, 14.332617187499991],
              [103.03105468749999, 14.252539062499991],
              [102.90927734375003, 14.13671875],
              [102.87324218750001, 14.054882812499997],
              [102.81279296874999, 13.972460937500003],
              [102.72890625000002, 13.841894531249991],
              [102.62041015624999, 13.716943359374994],
              [102.54472656249999, 13.659960937500003],
              [102.56552734375003, 13.626367187499994],
              [102.546875, 13.585693359375],
              [102.42851562499999, 13.567578125],
              [102.33632812500002, 13.560302734375],
              [102.31972656250002, 13.539990234374997],
              [102.33076171875001, 13.288232421874994],
              [102.36298828125001, 13.19296875],
              [102.42265624999999, 13.077978515624991],
              [102.46171874999999, 13.015039062499994],
              [102.49072265625, 12.828320312499997],
              [102.49960937500003, 12.669970703124989],
              [102.62968749999999, 12.569921875],
              [102.70332031250001, 12.493505859374991],
              [102.75566406249999, 12.42626953125],
              [102.73740234375003, 12.383398437499991],
              [102.70625, 12.255664062499989],
              [102.73662109374999, 12.089794921874997],
              [102.91806640625003, 11.732080078124994],
              [102.93388671874999, 11.706689453124994],
              [102.91230468750001, 11.703857421875],
              [102.88369140625002, 11.772753906249989],
              [102.79160156250003, 11.888623046874997],
              [102.76298828124999, 12.012451171875],
              [102.65488281250003, 12.148828125],
              [102.59414062500002, 12.203027343749994],
              [102.57480468750003, 12.1578125],
              [102.54023437500001, 12.109228515624991],
              [102.43408203125, 12.179248046874989],
              [102.34316406250002, 12.252587890624994],
              [102.25908203124999, 12.394335937499989],
              [102.24843750000002, 12.361425781249991],
              [102.22958984375003, 12.331640625],
              [102.13417968750002, 12.443017578124994],
              [102.034375, 12.531884765624994],
              [101.94453125000001, 12.563671875],
              [101.88906250000002, 12.59326171875],
              [101.83574218749999, 12.640380859375],
              [101.7236328125, 12.689355468749994],
              [101.44492187500003, 12.618945312499989],
              [101.09023437500002, 12.673632812499989],
              [100.95371093750003, 12.621240234374994],
              [100.89775390624999, 12.65380859375],
              [100.86328125, 12.714501953124994],
              [100.89638671875002, 12.818164062499989],
              [100.90390625000003, 13.034912109375],
              [100.94609374999999, 13.187255859375],
              [100.92626953125, 13.303027343750003],
              [100.94697265625001, 13.357568359374994],
              [100.96269531249999, 13.431982421874991],
              [100.90654296874999, 13.46240234375],
              [100.65605468749999, 13.521289062500003],
              [100.60292968750002, 13.568164062500003],
              [100.53642578124999, 13.514453125],
              [100.23564453124999, 13.484472656249991],
              [100.12236328124999, 13.439550781249991],
              [100.01748046875002, 13.353173828124994],
              [99.99052734374999, 13.243457031250003],
              [100.05107421874999, 13.171240234374991],
              [100.08994140625003, 13.045654296875],
              [99.98203125000003, 12.771484375],
              [99.96396484375003, 12.690039062499991],
              [100.00566406249999, 12.354736328125],
              [99.98906249999999, 12.170800781249994],
              [99.93027343750003, 12.047460937499991],
              [99.83710937500001, 11.936621093749991],
              [99.79873046875002, 11.748779296875],
              [99.72548828125002, 11.661767578124994],
              [99.62734375000002, 11.462890625],
              [99.56132812499999, 11.215185546874991],
              [99.51435546875001, 11.1005859375],
              [99.48691406250003, 10.889550781249994],
              [99.28476562500003, 10.569140624999989],
              [99.2373046875, 10.388134765624997],
              [99.1650390625, 10.31982421875],
              [99.19033203125002, 10.265869140625],
              [99.19462890624999, 10.175439453124994],
              [99.16933593750002, 9.934179687499991],
              [99.16074218750003, 9.734033203124994],
              [99.19130859375002, 9.627148437499997],
              [99.28828125000001, 9.414599609374989],
              [99.26503906250002, 9.352978515624997],
              [99.25390625, 9.265234375],
              [99.33544921875, 9.225439453124991],
              [99.39384765624999, 9.213720703124991],
              [99.72382812500001, 9.314208984375],
              [99.83554687500003, 9.288378906249989],
              [99.87753906250003, 9.194628906249989],
              [99.90468750000002, 9.112890625],
              [99.96064453125001, 8.671240234374991],
              [99.98955078124999, 8.589208984374991],
              [100.05625, 8.511132812499994],
              [100.12929687500002, 8.428076171874991],
              [100.15410156249999, 8.44296875],
              [100.15888671875001, 8.473779296874994],
              [100.16347656250002, 8.508398437499991],
              [100.22871093750001, 8.424707031249994],
              [100.27939453125003, 8.268505859374997],
              [100.45351562500002, 7.442285156249994],
              [100.50371093749999, 7.337304687499994],
              [100.54521484374999, 7.226904296874991],
              [100.43935546875002, 7.28076171875],
              [100.41074218750003, 7.464306640624997],
              [100.38037109375, 7.54150390625],
              [100.34296875000001, 7.552880859374994],
              [100.28378906250003, 7.551513671875],
              [100.27998046875001, 7.584326171874991],
              [100.32431640625003, 7.644189453124994],
              [100.3173828125, 7.715966796874994],
              [100.25664062499999, 7.77490234375],
              [100.158203125, 7.728125],
              [100.16074218750003, 7.599267578124994],
              [100.20488281249999, 7.500537109374989],
              [100.37138671874999, 7.280126953124991],
              [100.42353515625001, 7.187841796874991],
              [100.48974609375, 7.161376953125],
              [100.58623046874999, 7.175976562499997],
              [100.70166015625, 7.081982421874997],
              [100.79257812500003, 6.994677734374989],
              [101.01787109374999, 6.8609375],
              [101.15439453125003, 6.875146484374994],
              [101.30195312500001, 6.908300781249991],
              [101.40087890625, 6.899560546874994],
              [101.49794921875002, 6.865283203124989],
              [101.6142578125, 6.753955078124989],
              [101.79921875000002, 6.474609375],
              [102.10107421875, 6.242236328124989],
              [102.068359375, 6.184667968749991],
              [102.05517578125, 6.0966796875],
              [101.93613281250003, 5.979345703124991],
              [101.91718750000001, 5.911376953125],
              [101.87363281250003, 5.825292968749991],
              [101.79072265625001, 5.779345703124989],
              [101.71953124999999, 5.770605468749991],
              [101.67841796875001, 5.77880859375],
              [101.65, 5.795996093749991],
              [101.60136718749999, 5.877148437499997],
              [101.57675781250003, 5.902001953124994],
              [101.55605468750002, 5.907763671874989],
              [101.40419921875002, 5.851660156249991],
              [101.25703125000001, 5.789355468749989],
              [101.22978515624999, 5.733691406249989],
              [101.190625, 5.66875],
              [101.14765625000001, 5.64306640625],
              [101.11396484375001, 5.636767578124989],
              [101.08173828125001, 5.674902343749991],
              [101.02519531249999, 5.724511718749994],
              [100.98164062500001, 5.771044921874989],
              [100.99277343750003, 5.84619140625],
              [101.07558593750002, 5.956494140624997],
              [101.08652343750003, 6.03369140625],
              [101.07597656249999, 6.166064453124989],
              [101.05351562499999, 6.242578125],
              [101.02939453125003, 6.2453125],
              [100.98876953125, 6.257666015624991],
              [100.87392578125002, 6.245410156249989],
              [100.81650390625003, 6.331640625],
              [100.79375, 6.426171875],
              [100.75449218750003, 6.460058593749991],
              [100.715625, 6.480664062499997],
              [100.62949218750003, 6.447998046875],
              [100.56386718750002, 6.467529296875],
              [100.34541015625001, 6.549902343749991],
              [100.26142578125001, 6.682714843749991],
              [100.21660156249999, 6.686621093749991],
              [100.1767578125, 6.671826171874997],
              [100.16123046875003, 6.6416015625],
              [100.13798828124999, 6.488671875],
              [100.119140625, 6.441992187499991],
              [99.86865234375, 6.749902343749994],
              [99.69599609375001, 6.876660156249997],
              [99.72031250000003, 7.106201171875],
              [99.66777343749999, 7.15087890625],
              [99.60244140625002, 7.155322265624989],
              [99.55302734374999, 7.218798828124989],
              [99.59697265624999, 7.355615234374994],
              [99.52910156249999, 7.329492187499994],
              [99.43515625000003, 7.334375],
              [99.35859375000001, 7.372216796874994],
              [99.30039062500003, 7.561328124999989],
              [99.263671875, 7.619042968749994],
              [99.18339843749999, 7.718066406249989],
              [99.07763671875, 7.718066406249989],
              [99.04267578125001, 7.765625],
              [99.05107421874999, 7.887841796874994],
              [98.97392578124999, 7.962792968749994],
              [98.87246093750002, 8.02392578125],
              [98.78867187500003, 8.059814453125],
              [98.70351562500002, 8.256738281249994],
              [98.63632812500003, 8.305029296874991],
              [98.57919921875003, 8.344287109374989],
              [98.49980468749999, 8.317822265624997],
              [98.47402343750002, 8.246923828124991],
              [98.42099609375003, 8.17822265625],
              [98.36074218750002, 8.186962890624997],
              [98.30546874999999, 8.226220703124994],
              [98.23818359375002, 8.423095703125],
              [98.22695312500002, 8.543652343749997],
              [98.24179687500003, 8.767871093749989],
              [98.32597656249999, 8.968945312499997],
              [98.37138671874999, 9.29052734375],
              [98.44316406249999, 9.492822265624994],
              [98.49296874999999, 9.561425781249994],
              [98.56191406250002, 9.8375],
              [98.70253906250002, 10.190380859374997],
              [98.71845703125001, 10.266015625],
              [98.746875, 10.350830078125],
              [98.76835937499999, 10.430859375],
              [98.775390625, 10.55703125],
              [98.75722656250002, 10.623583984374989],
              [98.75722656250002, 10.660937499999989],
              [98.78691406249999, 10.708447265624997],
              [98.88710937500002, 10.788330078125],
              [99.025390625, 10.919970703124989],
              [99.19013671875001, 11.105273437499989],
              [99.35878906250002, 11.389453124999989],
              [99.44267578124999, 11.554394531249997],
              [99.47792968750002, 11.6125],
              [99.51523437500003, 11.630664062499989],
              [99.57285156250003, 11.687158203124994],
              [99.6125, 11.749658203124994],
              [99.61474609375, 11.781201171874997],
              [99.52294921875, 12.089648437499989],
              [99.462890625, 12.190234374999989],
              [99.43242187499999, 12.309033203124997],
              [99.41630859374999, 12.394824218749989],
              [99.39423828125001, 12.4736328125],
              [99.40507812499999, 12.547900390624989],
              [99.37197265625002, 12.59423828125],
              [99.29736328125, 12.652880859374989],
              [99.21982421875003, 12.73974609375],
              [99.17353515625001, 12.881933593749991],
              [99.17353515625001, 12.961328125],
              [99.12392578125002, 13.03076171875],
              [99.107421875, 13.103515625],
              [99.13710937500002, 13.172998046874994],
              [99.17617187500002, 13.233056640624994],
              [99.17167968749999, 13.496923828124991],
              [99.15605468749999, 13.57578125],
              [99.13681640625003, 13.716699218749994],
              [99.08623046874999, 13.82275390625],
              [99.0146484375, 13.947167968749994],
              [98.93359375, 14.049853515625003],
              [98.72119140625, 14.235742187499994],
              [98.57001953125001, 14.359912109375003],
              [98.49501953125002, 14.472900390625],
              [98.40019531249999, 14.602978515624997],
              [98.33212890625003, 14.696484375],
              [98.24599609375002, 14.814746093750003],
              [98.2021484375, 14.975927734374991],
              [98.17792968750001, 15.147412109374997],
              [98.19101562500003, 15.2041015625],
              [98.23222656249999, 15.241357421874994],
              [98.2861328125, 15.271582031249991],
              [98.32939453124999, 15.278564453125],
              [98.4521484375, 15.357373046874997],
              [98.53730468750001, 15.350683593749991],
              [98.55693359374999, 15.36767578125],
              [98.56523437499999, 15.403564453125],
              [98.55449218749999, 15.559765625],
              [98.55820312500003, 15.768603515625003],
              [98.57402343749999, 15.938623046874994],
              [98.59238281250003, 16.050683593749994],
              [98.81796875000003, 16.180810546874994],
              [98.86552734374999, 16.237060546875],
              [98.88847656249999, 16.298095703125],
              [98.88828125000003, 16.35190429687499],
              [98.86933593750001, 16.394189453124994],
              [98.83544921875, 16.417578125],
              [98.68925781249999, 16.305419921875],
              [98.66074218750003, 16.33041992187499],
              [98.59365234375002, 16.514794921874994],
              [98.56474609374999, 16.570947265624994],
              [98.52314453125001, 16.63818359375],
              [98.478125, 16.732226562500003],
              [98.47119140625, 16.89501953125],
              [98.43886718750002, 16.97568359374999],
              [98.25654296875001, 17.14765625],
              [98.17460937499999, 17.239892578124994],
              [98.06308593750003, 17.373291015625],
              [97.92929687500003, 17.53330078124999],
              [97.79296875, 17.68125],
              [97.72910156250003, 17.775830078124997],
              [97.70644531250002, 17.797119140625],
              [97.69853515624999, 17.833544921875003],
              [97.73994140625001, 17.935302734375],
              [97.7197265625, 18.037402343750003],
              [97.65156250000001, 18.173730468749994],
              [97.62246093750002, 18.258007812499997],
              [97.63222656250002, 18.290332031250003],
              [97.59931640625001, 18.302978515625],
              [97.52382812500002, 18.2958984375],
              [97.45078125000003, 18.359667968750003],
              [97.38066406249999, 18.494287109374994],
              [97.37392578125002, 18.51796875],
              [97.39707031250003, 18.517529296874997],
              [97.48496093750003, 18.49423828124999],
              [97.51513671875, 18.497753906249997],
              [97.57734375000001, 18.528710937499994],
              [97.67158203125001, 18.561230468749997],
              [97.72773437500001, 18.572021484375],
              [97.74589843749999, 18.588183593750003],
              [97.75400390625003, 18.620800781249997],
              [97.7060546875, 18.931787109374994],
              [97.71416015624999, 18.996484375],
              [97.80390625000001, 19.13046875],
              [97.79355468750003, 19.265869140625],
              [97.81679687500002, 19.4599609375],
              [97.91640625000002, 19.59287109374999],
              [97.9912109375, 19.653710937499994],
              [98.01503906250002, 19.74951171875],
              [98.04902343750001, 19.769726562499997],
              [98.11103515625001, 19.762158203124997],
              [98.23906249999999, 19.690673828125],
              [98.29365234375001, 19.687255859375],
              [98.37128906250001, 19.689160156249997],
              [98.45498046875002, 19.69443359374999],
              [98.49384765625001, 19.701318359374994],
              [98.76064453125002, 19.77109375],
              [98.81953125000001, 19.778466796874994],
              [98.87578124999999, 19.769580078125003],
              [98.91669921875001, 19.772900390624997],
              [98.9580078125, 19.804931640625],
              [98.98740234375003, 19.861376953125003],
              [99.02070312500001, 20.041796875],
              [99.03974609375001, 20.073632812499994],
              [99.07421875, 20.099365234375],
              [99.13076171875002, 20.116601562499994],
              [99.196875, 20.115136718749994],
              [99.28369140625, 20.08041992187499],
              [99.337890625, 20.07890625],
              [99.39921874999999, 20.093457031249997],
              [99.45156250000002, 20.118310546874994],
              [99.48593750000003, 20.149853515624997],
              [99.50166015625001, 20.187744140625],
              [99.4875, 20.260644531249994],
              [99.44794921875001, 20.35205078125],
              [99.45888671875002, 20.363037109375],
              [99.53164062500002, 20.342822265625003],
              [99.638671875, 20.320458984374994],
              [99.72011718750002, 20.325439453125],
              [99.77333984375002, 20.34130859375],
              [99.8251953125, 20.384472656249997],
              [99.89033203125001, 20.42441406249999],
              [99.95429687500001, 20.415429687499994],
              [100.00361328125001, 20.379589843749997],
              [100.12246093750002, 20.316650390625],
            ],
          ],
          [
            [
              [100.07412109375002, 9.696679687499994],
              [100.064453125, 9.679980468749989],
              [100.02568359374999, 9.711718749999989],
              [99.998046875, 9.747607421874989],
              [99.9833984375, 9.793554687499991],
              [100.04345703125, 9.791650390624994],
              [100.07304687499999, 9.749121093749991],
              [100.07412109375002, 9.696679687499994],
            ],
          ],
          [
            [
              [100.07070312500002, 9.586035156249991],
              [100.07529296875003, 9.529443359374994],
              [100.0537109375, 9.46142578125],
              [99.96240234375, 9.421630859375],
              [99.93125, 9.47607421875],
              [99.93955078125003, 9.559960937499994],
              [99.95361328125, 9.581005859374997],
              [100.04296875, 9.576855468749997],
              [100.07070312500002, 9.586035156249991],
            ],
          ],
          [
            [
              [99.6630859375, 6.521923828124997],
              [99.64404296875, 6.51611328125],
              [99.60664062500001, 6.596826171874994],
              [99.65400390625001, 6.714111328125],
              [99.70136718750001, 6.570556640625],
              [99.6630859375, 6.521923828124997],
            ],
          ],
          [
            [
              [98.59199218750001, 7.933935546874991],
              [98.57998046875002, 7.917041015624989],
              [98.52939453125003, 8.108544921874994],
              [98.60429687499999, 8.057324218749997],
              [98.59199218750001, 7.933935546874991],
            ],
          ],
          [
            [
              [98.30751953125002, 9.051464843749997],
              [98.25078124999999, 9.040820312499989],
              [98.25839843750003, 9.095410156249997],
              [98.27363281250001, 9.1298828125],
              [98.30117187500002, 9.139111328124997],
              [98.3125, 9.080371093749989],
              [98.30751953125002, 9.051464843749997],
            ],
          ],
          [
            [
              [99.07841796874999, 7.591845703124989],
              [99.10439453125002, 7.471289062499991],
              [99.06787109375, 7.495898437499989],
              [99.03769531250003, 7.548486328124994],
              [99.0380859375, 7.625732421875],
              [99.04511718750001, 7.636523437499989],
              [99.07841796874999, 7.591845703124989],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 2,
        sovereignt: "Pakistan",
        sov_a3: "PAK",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Pakistan",
        adm0_a3: "PAK",
        geou_dif: 0,
        geounit: "Pakistan",
        gu_a3: "PAK",
        su_dif: 0,
        subunit: "Pakistan",
        su_a3: "PAK",
        brk_diff: 0,
        name: "Pakistan",
        name_long: "Pakistan",
        brk_a3: "PAK",
        brk_name: "Pakistan",
        brk_group: null,
        abbrev: "Pak.",
        postal: "PK",
        formal_en: "Islamic Republic of Pakistan",
        formal_fr: null,
        name_ciawf: "Pakistan",
        note_adm0: null,
        note_brk: null,
        name_sort: "Pakistan",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 2,
        mapcolor9: 3,
        mapcolor13: 11,
        pop_est: 216565318,
        pop_rank: 17,
        pop_year: 2019,
        gdp_md: 278221,
        gdp_year: 2019,
        economy: "5. Emerging region: G20",
        income_grp: "4. Lower middle income",
        fips_10: "PK",
        iso_a2: "PK",
        iso_a2_eh: "PK",
        iso_a3: "PAK",
        iso_a3_eh: "PAK",
        iso_n3: "586",
        iso_n3_eh: "586",
        un_a3: "586",
        wb_a2: "PK",
        wb_a3: "PAK",
        woe_id: 23424922,
        woe_id_eh: 23424922,
        woe_note: "Exact WOE match as country",
        adm0_iso: "PAK",
        adm0_diff: null,
        adm0_tlc: "PAK",
        adm0_a3_us: "PAK",
        adm0_a3_fr: "PAK",
        adm0_a3_ru: "PAK",
        adm0_a3_es: "PAK",
        adm0_a3_cn: "PAK",
        adm0_a3_tw: "PAK",
        adm0_a3_in: "PAK",
        adm0_a3_np: "PAK",
        adm0_a3_pk: "PAK",
        adm0_a3_de: "PAK",
        adm0_a3_gb: "PAK",
        adm0_a3_br: "PAK",
        adm0_a3_il: "PAK",
        adm0_a3_ps: "PAK",
        adm0_a3_sa: "PAK",
        adm0_a3_eg: "PAK",
        adm0_a3_ma: "PAK",
        adm0_a3_pt: "PAK",
        adm0_a3_ar: "PAK",
        adm0_a3_jp: "PAK",
        adm0_a3_ko: "PAK",
        adm0_a3_vn: "PAK",
        adm0_a3_tr: "PAK",
        adm0_a3_id: "PAK",
        adm0_a3_pl: "PAK",
        adm0_a3_gr: "PAK",
        adm0_a3_it: "PAK",
        adm0_a3_nl: "PAK",
        adm0_a3_se: "PAK",
        adm0_a3_bd: "PAK",
        adm0_a3_ua: "PAK",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Southern Asia",
        region_wb: "South Asia",
        name_len: 8,
        long_len: 8,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 2.7,
        max_label: 7,
        label_x: 68.545632,
        label_y: 29.328389,
        ne_id: 1159321153,
        wikidataid: "Q843",
        name_ar: "باكستان",
        name_bn: "পাকিস্তান",
        name_de: "Pakistan",
        name_en: "Pakistan",
        name_es: "Pakistán",
        name_fa: "پاکستان",
        name_fr: "Pakistan",
        name_el: "Πακιστάν",
        name_he: "פקיסטן",
        name_hi: "पाकिस्तान",
        name_hu: "Pakisztán",
        name_id: "Pakistan",
        name_it: "Pakistan",
        name_ja: "パキスタン",
        name_ko: "파키스탄",
        name_nl: "Pakistan",
        name_pl: "Pakistan",
        name_pt: "Paquistão",
        name_ru: "Пакистан",
        name_sv: "Pakistan",
        name_tr: "Pakistan",
        name_uk: "Пакистан",
        name_ur: "پاکستان",
        name_vi: "Pakistan",
        name_zh: "巴基斯坦",
        name_zht: "巴基斯坦",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "PAK.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [76.76689453124999, 35.66171875],
            [76.81279296874999, 35.571826171874996],
            [76.88222656250002, 35.4357421875],
            [76.927734375, 35.346630859375],
            [76.97890625000002, 35.246435546875],
            [77.00449218750003, 35.196337890624996],
            [77.04863281249999, 35.109912109374996],
            [77.03066406250002, 35.062353515625],
            [77.00087890625002, 34.991992187499996],
            [76.89169921875003, 34.938720703125],
            [76.78291015625001, 34.900195312499996],
            [76.75751953125001, 34.877832031249994],
            [76.7490234375, 34.847558593749994],
            [76.6962890625, 34.78691406249999],
            [76.59443359375001, 34.73583984375],
            [76.50996093750001, 34.740869140624994],
            [76.45673828125001, 34.756103515625],
            [76.17246093750003, 34.667724609375],
            [76.041015625, 34.669921875],
            [75.93828125000002, 34.612548828125],
            [75.86210937500002, 34.56025390625],
            [75.70917968750001, 34.50307617187508],
            [75.60556640625, 34.502734375],
            [75.45253906250002, 34.53671875],
            [75.26406250000002, 34.601367187499996],
            [75.1875, 34.639013671875],
            [75.11845703125002, 34.63681640625],
            [74.95185546875001, 34.645849609375],
            [74.78876953125001, 34.677734375],
            [74.59414062500002, 34.71577148437507],
            [74.49794921875002, 34.73203125],
            [74.300390625, 34.765380859375],
            [74.17197265624998, 34.72089843750007],
            [74.05585937500001, 34.6806640625],
            [73.96123046875002, 34.653466796874994],
            [73.88310546875002, 34.529052734375],
            [73.85009765624994, 34.48530273437501],
            [73.812109375, 34.42236328125],
            [73.79453125, 34.378222656249996],
            [73.80996093750002, 34.325341796874994],
            [73.92460937500002, 34.287841796875],
            [73.97236328125001, 34.23662109374999],
            [73.9794921875, 34.191308593749994],
            [73.93828125000002, 34.144775390625],
            [73.90390625, 34.1080078125],
            [73.90410156250002, 34.07568359375],
            [73.92236328125, 34.04306640625],
            [73.94990234375001, 34.018798828125],
            [74.11259765625002, 34.003710937499996],
            [74.208984375, 34.00341796875],
            [74.24648437500002, 33.990185546875],
            [74.25087890625002, 33.94609375],
            [74.215625, 33.886572265625],
            [74.07841796875002, 33.838671875],
            [74.00097656249994, 33.78818359375009],
            [73.97646484375002, 33.7212890625],
            [73.9775390625, 33.66782226562509],
            [74.00400390625, 33.632421875],
            [74.06972656250002, 33.591699218749994],
            [74.13125, 33.545068359374994],
            [74.15, 33.50698242187502],
            [74.142578125, 33.455371093749996],
            [74.11777343749998, 33.384130859375034],
            [74.050390625, 33.30126953125],
            [73.99423828125, 33.2421875],
            [73.98984375, 33.22119140625],
            [74.00380859375002, 33.189453125],
            [74.04912109374999, 33.143408203125034],
            [74.12626953125002, 33.075439453125],
            [74.22207031250002, 33.0203125],
            [74.28359374999997, 33.005126953125114],
            [74.30361328124997, 32.991796875000034],
            [74.32275390625, 32.927978515625],
            [74.32998046875002, 32.86083984375],
            [74.30546874999999, 32.81044921875002],
            [74.35458984375, 32.768701171874994],
            [74.4833984375, 32.77099609375],
            [74.58828125000002, 32.753222656249996],
            [74.63242187499998, 32.770898437500136],
            [74.66328125000001, 32.757666015625],
            [74.64335937500002, 32.607714843749996],
            [74.6578125, 32.518945312499994],
            [74.68574218750001, 32.49379882812502],
            [74.78886718750002, 32.4578125],
            [74.9873046875, 32.46220703124999],
            [75.10410156249998, 32.420361328124955],
            [75.23369140625002, 32.372119140624996],
            [75.30263671875002, 32.318896484374996],
            [75.33349609375, 32.279199218749966],
            [75.32470703125, 32.215283203125125],
            [75.25410156250001, 32.140332031250125],
            [75.13876953124998, 32.10478515624999],
            [75.07148437500001, 32.08935546875],
            [74.73945312500001, 31.948828125],
            [74.63574218749994, 31.889746093750034],
            [74.55556640625002, 31.818554687499997],
            [74.52597656249998, 31.765136718750057],
            [74.50996093750001, 31.712939453124996],
            [74.58183593750002, 31.52392578125],
            [74.59394531249995, 31.465380859375102],
            [74.53496093750002, 31.261376953124994],
            [74.51767578125, 31.185595703124996],
            [74.53974609375001, 31.132666015625],
            [74.6103515625, 31.112841796874996],
            [74.62578125000002, 31.06875],
            [74.6328125, 31.03466796875],
            [74.509765625, 30.959667968749997],
            [74.38037109375, 30.893408203125],
            [74.33935546875, 30.8935546875],
            [74.215625, 30.768994140624997],
            [74.00898437500001, 30.519677734375023],
            [73.89931640625002, 30.435351562500045],
            [73.8916015625, 30.39404296875],
            [73.88271484375002, 30.3521484375],
            [73.92460937500002, 30.281640625],
            [73.93339843750002, 30.222070312499994],
            [73.88652343750002, 30.162011718749994],
            [73.80917968749998, 30.093359375000034],
            [73.65800781250002, 30.033203125],
            [73.46748046875001, 29.971679687500114],
            [73.38164062500002, 29.934375],
            [73.31728515625002, 29.772998046874996],
            [73.2578125, 29.610693359375],
            [73.23115234375001, 29.55063476562509],
            [73.12832031250002, 29.36391601562508],
            [72.94873046875, 29.088818359374997],
            [72.9033203125, 29.028759765624997],
            [72.6255859375, 28.896142578124994],
            [72.34189453125, 28.751904296874997],
            [72.29199218749994, 28.697265625],
            [72.23388671875, 28.565820312499994],
            [72.17919921875, 28.421777343749994],
            [72.128515625, 28.346337890624994],
            [71.94804687500002, 28.177294921874996],
            [71.88886718750001, 28.0474609375],
            [71.87031250000001, 27.9625],
            [71.71669921875002, 27.915087890624996],
            [71.54296874999994, 27.869873046875],
            [71.29013671874998, 27.855273437500045],
            [71.18476562500001, 27.831640625],
            [70.87490234375002, 27.714453125],
            [70.79794921875, 27.709619140624994],
            [70.73740234374998, 27.729003906250057],
            [70.69160156250001, 27.768994140624997],
            [70.64912109375001, 27.835351562499994],
            [70.62910156250001, 27.937451171874997],
            [70.56923828125002, 27.983789062500136],
            [70.48857421875002, 28.023144531249997],
            [70.40371093750002, 28.025048828124966],
            [70.31845703125, 27.981640625],
            [70.24433593750001, 27.934130859374996],
            [70.1939453125, 27.894873046875],
            [70.14453125, 27.849023437500023],
            [70.0498046875, 27.694726562499994],
            [69.89628906250002, 27.4736328125],
            [69.72480468750001, 27.312695312499997],
            [69.66132812500001, 27.264501953125],
            [69.62158203125, 27.228076171874996],
            [69.56796874999992, 27.174609375000102],
            [69.53701171875002, 27.122949218749994],
            [69.49453125000002, 26.954150390624996],
            [69.47001953125002, 26.804443359375],
            [69.48125, 26.77099609375],
            [69.50693359374992, 26.742675781250057],
            [69.6005859375, 26.699121093750023],
            [69.7359375, 26.62705078125],
            [69.91142578125002, 26.586132812499997],
            [70.059375, 26.578759765624994],
            [70.11464843750002, 26.548046875],
            [70.14765625000001, 26.5064453125],
            [70.1568359375, 26.471435546875],
            [70.14921875000002, 26.347558593749994],
            [70.13261718750002, 26.214794921874997],
            [70.077734375, 26.071972656249997],
            [70.07861328125, 25.990039062500003],
            [70.1001953125, 25.910058593749994],
            [70.2646484375, 25.70654296875],
            [70.3251953125, 25.685742187499997],
            [70.44853515625002, 25.681347656249997],
            [70.505859375, 25.685302734375],
            [70.56953125000001, 25.705957031249994],
            [70.61484375, 25.69189453125],
            [70.6484375, 25.666943359375068],
            [70.6572265625, 25.62578125],
            [70.65205078125001, 25.422900390625102],
            [70.70253906249997, 25.331054687500057],
            [70.80048828125001, 25.205859375],
            [70.87773437500002, 25.06298828125],
            [70.95087890625001, 24.8916015625],
            [71.02070312500001, 24.75766601562499],
            [71.0478515625, 24.687744140625057],
            [71.00234375000002, 24.65390625],
            [70.97636718750002, 24.61875],
            [70.96982421875, 24.571875],
            [70.97929687500002, 24.5224609375],
            [70.9732421875, 24.48740234374999],
            [71.00625, 24.4443359375],
            [71.04531250000002, 24.42998046874999],
            [71.04404296875, 24.400097656249997],
            [70.98281250000002, 24.361035156249997],
            [70.928125, 24.362353515625003],
            [70.88623046875, 24.34375],
            [70.80507812500002, 24.261962890625],
            [70.76728515625001, 24.245410156250045],
            [70.71630859375, 24.237988281249997],
            [70.65947265625002, 24.24609375],
            [70.57929687500001, 24.279052734375],
            [70.55585937500001, 24.331103515625003],
            [70.5650390625, 24.385791015625003],
            [70.54677734375002, 24.41831054687499],
            [70.4892578125, 24.412158203125003],
            [70.2890625, 24.35629882812499],
            [70.09824218749998, 24.2875],
            [70.06513671874995, 24.24057617187492],
            [70.02109375, 24.191552734374994],
            [69.93378906250001, 24.17138671875],
            [69.80517578125, 24.165234375],
            [69.71621093750002, 24.172607421875],
            [69.63417968750002, 24.22519531249999],
            [69.5591796875, 24.273095703124994],
            [69.44345703124998, 24.275390625000114],
            [69.23505859374995, 24.268261718750068],
            [69.11953125000002, 24.26865234374999],
            [69.05156250000002, 24.286328125],
            [68.98457031250001, 24.273095703124994],
            [68.90078125000002, 24.292431640624997],
            [68.86347656250001, 24.266503906249994],
            [68.82832031250001, 24.26401367187499],
            [68.8, 24.309082031250057],
            [68.78115234375002, 24.313720703125],
            [68.75898437499995, 24.307226562500034],
            [68.73964843750002, 24.2919921875],
            [68.728125, 24.265625],
            [68.72412109375, 23.964697265625034],
            [68.58662109375001, 23.966601562500003],
            [68.48867187500002, 23.967236328124997],
            [68.38125, 23.950878906249997],
            [68.28251953125002, 23.927978515625],
            [68.23417968750002, 23.900537109374994],
            [68.1650390625, 23.857324218749994],
            [68.14882812500002, 23.79721679687499],
            [68.11552734375002, 23.753369140624997],
            [68.06777343750002, 23.818359375],
            [68.03701171875002, 23.84824218749999],
            [68.00146484375, 23.826074218749994],
            [67.95097656250002, 23.82861328125],
            [67.8599609375, 23.90268554687499],
            [67.81904296875001, 23.828076171874997],
            [67.66845703125, 23.810986328124997],
            [67.64951171875, 23.86728515624999],
            [67.64580078125002, 23.919873046874997],
            [67.5630859375, 23.8818359375],
            [67.50361328125001, 23.94003906249999],
            [67.47685546875002, 24.018261718749997],
            [67.45390625000002, 24.03989257812499],
            [67.42763671875002, 24.06484375],
            [67.365234375, 24.091601562500003],
            [67.309375, 24.1748046875],
            [67.304296875, 24.262890625],
            [67.288671875, 24.36777343749999],
            [67.171484375, 24.756103515625],
            [67.1005859375, 24.791943359374997],
            [66.70302734375002, 24.8609375],
            [66.6822265625, 24.928857421874994],
            [66.70986328125002, 25.111328125],
            [66.69863281250002, 25.226318359375],
            [66.569921875, 25.378515625],
            [66.53388671875001, 25.484375],
            [66.42861328125002, 25.575341796874994],
            [66.32421875, 25.601806640625],
            [66.21904296875002, 25.589892578125003],
            [66.16230468750001, 25.55390625],
            [66.13115234375002, 25.49326171874999],
            [66.3564453125, 25.507373046875003],
            [66.40712890625002, 25.485058593749997],
            [66.46767578125002, 25.4453125],
            [66.4029296875, 25.446826171875003],
            [66.32832031250001, 25.465771484374997],
            [66.23466796875002, 25.46435546875],
            [65.88359375000002, 25.419628906249997],
            [65.6796875, 25.355273437500003],
            [65.40625, 25.374316406250003],
            [65.06132812500002, 25.311083984375003],
            [64.77666015625002, 25.307324218749997],
            [64.65898437500002, 25.18408203125],
            [64.59404296875002, 25.206298828125],
            [64.54375, 25.23666992187499],
            [64.15205078125001, 25.333447265624997],
            [64.12490234375002, 25.373925781249994],
            [64.059375, 25.40292968749999],
            [63.9873046875, 25.351171875],
            [63.935546875, 25.342529296875],
            [63.72089843750001, 25.385888671874994],
            [63.556640625, 25.353173828124994],
            [63.495703125, 25.297509765624994],
            [63.49140625000001, 25.210839843749994],
            [63.285742187500006, 25.227587890625003],
            [63.170019531250006, 25.2548828125],
            [63.01503906250002, 25.224658203125003],
            [62.66474609375001, 25.264794921874994],
            [62.57246093750001, 25.25473632812499],
            [62.44472656250002, 25.197265625],
            [62.391210937500006, 25.152539062499997],
            [62.31533203125002, 25.134912109374994],
            [62.24873046875001, 25.19736328124999],
            [62.19863281250002, 25.224853515625],
            [62.15214843750002, 25.206640625],
            [62.089453125, 25.155322265625003],
            [61.90791015625001, 25.131298828124997],
            [61.74365234375, 25.13818359375],
            [61.56689453125, 25.186328125],
            [61.587890625, 25.20234375],
            [61.61542968750001, 25.2861328125],
            [61.64013671875, 25.584619140624994],
            [61.67138671875, 25.6923828125],
            [61.66181640625001, 25.751269531250003],
            [61.66865234375001, 25.768994140624997],
            [61.73769531250002, 25.82109375],
            [61.75439453125, 25.843359375],
            [61.78076171875, 25.995849609375],
            [61.80996093750002, 26.165283203125],
            [61.842382812500006, 26.225927734375],
            [61.86982421875001, 26.242431640625],
            [62.08906250000001, 26.318261718749994],
            [62.1259765625, 26.368994140625],
            [62.239355468750006, 26.35703125],
            [62.249609375, 26.36923828125],
            [62.25966796875002, 26.427490234375],
            [62.31230468750002, 26.490869140624994],
            [62.38505859375002, 26.542626953124994],
            [62.43925781250002, 26.56103515625],
            [62.63642578125001, 26.593652343749994],
            [62.7515625, 26.63916015625],
            [62.78662109375, 26.643896484375],
            [63.09296875000001, 26.63232421875],
            [63.1578125, 26.649755859375],
            [63.168066406250006, 26.665576171874996],
            [63.186132812500006, 26.837597656249997],
            [63.24160156250002, 26.86474609375],
            [63.25039062500002, 26.879248046875],
            [63.2314453125, 26.99814453125],
            [63.24208984375002, 27.077685546874996],
            [63.30517578125, 27.124560546874996],
            [63.30156250000002, 27.15146484375],
            [63.25625, 27.207910156249994],
            [63.19609375000002, 27.243945312499996],
            [63.16679687500002, 27.252490234374996],
            [62.91542968750002, 27.218408203124994],
            [62.811621093750006, 27.229443359374997],
            [62.76298828125002, 27.250195312499997],
            [62.75273437500002, 27.265625],
            [62.7625, 27.300195312499994],
            [62.764257812500006, 27.356738281249996],
            [62.800878906250006, 27.44453125],
            [62.81201171875, 27.497021484374997],
            [62.782324218750006, 27.800537109375],
            [62.73974609375, 28.00205078125],
            [62.7625, 28.202050781249994],
            [62.75800781250001, 28.243554687499994],
            [62.74941406250002, 28.252880859374997],
            [62.71757812500002, 28.252783203125],
            [62.564550781250006, 28.23515625],
            [62.43388671875002, 28.3638671875],
            [62.35302734375, 28.414746093749997],
            [62.13056640625001, 28.478808593749996],
            [62.03300781250002, 28.491015625],
            [61.88984375000001, 28.546533203124994],
            [61.75800781250001, 28.667675781249997],
            [61.623046875, 28.7916015625],
            [61.56875, 28.870898437499996],
            [61.50859375000002, 29.006054687499997],
            [61.337890625, 29.264990234375],
            [61.339453125, 29.331787109375],
            [61.318359375, 29.372607421874996],
            [61.15214843750002, 29.542724609375],
            [61.0341796875, 29.663427734375],
            [60.843359375, 29.858691406249996],
            [61.22441406250002, 29.749414062499994],
            [61.521484375, 29.665673828124994],
            [62.0009765625, 29.530419921874994],
            [62.37343750000002, 29.425390625],
            [62.4765625, 29.408349609374994],
            [63.56757812500001, 29.497998046874997],
            [63.97099609375002, 29.430078125],
            [64.09873046875, 29.391943359375],
            [64.11796875000002, 29.414257812499997],
            [64.17216796875002, 29.460351562499994],
            [64.26611328125, 29.50693359375],
            [64.39375, 29.544335937499994],
            [64.52109375, 29.564501953124996],
            [64.70351562500002, 29.567138671875],
            [64.82734375000001, 29.564160156249997],
            [64.9189453125, 29.552783203124996],
            [65.09550781250002, 29.559472656249994],
            [65.18046875000002, 29.57763671875],
            [65.47099609375002, 29.6515625],
            [65.66621093750001, 29.701318359374994],
            [65.96162109375001, 29.77890625],
            [66.17705078125002, 29.835595703124994],
            [66.23125, 29.86572265625],
            [66.28691406250002, 29.92001953125],
            [66.31337890625002, 29.968554687499996],
            [66.24716796875, 30.043505859374996],
            [66.23847656250001, 30.109619140625],
            [66.2818359375, 30.19345703125],
            [66.30546875000002, 30.321142578125],
            [66.30097656250001, 30.502978515624996],
            [66.28691406250002, 30.60791015625],
            [66.346875, 30.802783203124996],
            [66.39716796875001, 30.91220703125],
            [66.49736328125002, 30.964550781249997],
            [66.56679687500002, 30.99658203125],
            [66.59580078125, 31.019970703124997],
            [66.62421875000001, 31.046044921874994],
            [66.73134765625002, 31.19453125],
            [66.82929687500001, 31.263671875],
            [66.92431640625, 31.305615234374997],
            [67.02773437500002, 31.300244140624997],
            [67.11591796875001, 31.242919921875],
            [67.28730468750001, 31.217822265624996],
            [67.45283203125001, 31.234619140625],
            [67.59638671875001, 31.277685546875],
            [67.66152343750002, 31.31298828125],
            [67.737890625, 31.343945312499997],
            [67.73349609375, 31.379248046875],
            [67.6470703125, 31.409960937499996],
            [67.59755859375002, 31.453320312499997],
            [67.57822265625, 31.506494140624994],
            [67.62675781250002, 31.538769531249997],
            [67.73984375, 31.548193359375],
            [68.0171875, 31.677978515625],
            [68.13017578125002, 31.76328125],
            [68.16103515625002, 31.802978515625],
            [68.21396484375, 31.807373046875],
            [68.31982421875, 31.76767578125],
            [68.44326171875002, 31.7544921875],
            [68.52070312500001, 31.794140625],
            [68.59765625, 31.802978515625],
            [68.67324218750002, 31.759716796874997],
            [68.71367187500002, 31.708056640624996],
            [68.78232421875, 31.646435546874997],
            [68.86894531250002, 31.634228515624997],
            [68.97343750000002, 31.667382812499994],
            [69.08310546875, 31.738476562499997],
            [69.18691406250002, 31.838085937499997],
            [69.279296875, 31.936816406249996],
            [69.25654296875001, 32.249462890625],
            [69.24140625000001, 32.433544921875],
            [69.28994140625002, 32.530566406249996],
            [69.35947265625, 32.59033203125],
            [69.40537109375, 32.68271484375],
            [69.40458984375002, 32.7642578125],
            [69.453125, 32.8328125],
            [69.5015625, 33.020068359374996],
            [69.56777343750002, 33.06416015625],
            [69.7037109375, 33.0947265625],
            [69.92011718750001, 33.1125],
            [70.09023437500002, 33.198095703125],
            [70.26113281250002, 33.289013671875],
            [70.2841796875, 33.369042968749994],
            [70.2197265625, 33.4546875],
            [70.13417968750002, 33.620751953124994],
            [70.056640625, 33.719873046874994],
            [69.86806640625002, 33.89765625],
            [69.8896484375, 34.007275390625],
            [69.9947265625, 34.051806640624996],
            [70.25361328125001, 33.975976562499994],
            [70.32568359375, 33.9611328125],
            [70.41572265625001, 33.950439453125],
            [70.65400390625001, 33.952294921874994],
            [70.84843750000002, 33.981884765625],
            [71.05156250000002, 34.049707031249994],
            [71.09130859375, 34.120263671874994],
            [71.08906250000001, 34.204052734375],
            [71.0923828125, 34.273242187499996],
            [71.095703125, 34.369433593749996],
            [71.02294921875, 34.43115234375],
            [70.97890625000002, 34.486279296875],
            [70.965625, 34.53037109375],
            [71.01630859375001, 34.554638671875],
            [71.065625, 34.599609375],
            [71.11328125, 34.681591796875],
            [71.22578125000001, 34.779541015625],
            [71.29414062500001, 34.867724609374996],
            [71.35810546875001, 34.909619140625],
            [71.455078125, 34.966943359374994],
            [71.51708984375, 35.051123046875],
            [71.5455078125, 35.101416015625],
            [71.60166015625, 35.150683593749996],
            [71.62050781250002, 35.183007812499994],
            [71.60527343750002, 35.211767578125],
            [71.57724609375, 35.247998046875],
            [71.5455078125, 35.288867187499996],
            [71.5455078125, 35.328515625],
            [71.57197265625001, 35.370410156249996],
            [71.6005859375, 35.40791015625],
            [71.58740234375, 35.460839843749994],
            [71.57197265625001, 35.546826171875],
            [71.51904296875, 35.597509765625],
            [71.48359375000001, 35.714599609375],
            [71.42753906250002, 35.833740234375],
            [71.39755859375, 35.880175781249996],
            [71.34287109375, 35.938525390624996],
            [71.22021484375, 36.00068359375],
            [71.18505859375, 36.04208984375],
            [71.23291015625, 36.12177734375],
            [71.31259765625, 36.171191406249996],
            [71.46328125000002, 36.293261718749996],
            [71.5458984375, 36.377685546875],
            [71.62050781250002, 36.436474609375],
            [71.71640625, 36.4265625],
            [71.77265625000001, 36.4318359375],
            [71.822265625, 36.486083984375],
            [71.92070312500002, 36.5341796875],
            [72.09560546875002, 36.633740234375],
            [72.15673828125, 36.70087890625],
            [72.24980468750002, 36.734716796875],
            [72.32695312500002, 36.7423828125],
            [72.43115234375, 36.7658203125],
            [72.53134765625, 36.802001953125],
            [72.62285156250002, 36.82958984375],
            [72.7662109375, 36.835009765624996],
            [72.99375, 36.851611328124996],
            [73.116796875, 36.868554687499994],
            [73.4111328125, 36.881689453125],
            [73.73183593750002, 36.88779296875],
            [73.769140625, 36.888476562499996],
            [73.9078125, 36.852929687499994],
            [74.00185546875002, 36.823095703125],
            [74.03886718750002, 36.825732421874996],
            [74.19472656250002, 36.896875],
            [74.43105468750002, 36.983691406249996],
            [74.54140625000002, 37.02216796875],
            [74.6005859375, 37.036669921874996],
            [74.69218750000002, 37.0357421875],
            [74.76601562500002, 37.012744140624996],
            [74.84121093750002, 36.9791015625],
            [74.8892578125, 36.952441406249996],
            [74.94912109375002, 36.968359375],
            [75.05390625000001, 36.987158203125],
            [75.14521484375001, 36.9732421875],
            [75.3466796875, 36.913476562499994],
            [75.37685546875002, 36.883691406249994],
            [75.42421875000002, 36.738232421875],
            [75.46025390625002, 36.725048828125],
            [75.57373046875, 36.759326171874996],
            [75.66718750000001, 36.741992187499996],
            [75.77216796875001, 36.694921875],
            [75.84023437500002, 36.649707031249996],
            [75.88496093750001, 36.600732421874994],
            [75.93300781250002, 36.52158203125],
            [75.95185546875001, 36.45810546875],
            [75.97441406250002, 36.382421875],
            [75.96865234375002, 36.168847656249994],
            [75.93408203125, 36.133935546874994],
            [75.9048828125, 36.0884765625],
            [75.91230468750001, 36.048974609374994],
            [75.94511718750002, 36.017578125],
            [76.01044921875001, 35.996337890625],
            [76.07089843750003, 35.9830078125],
            [76.10332031249999, 35.94921875],
            [76.14785156250002, 35.829003906249994],
            [76.17783203125003, 35.810546875],
            [76.25166015625001, 35.8109375],
            [76.3857421875, 35.837158203125],
            [76.50205078125003, 35.878222656249996],
            [76.55126953125, 35.887060546875],
            [76.5634765625, 35.772998046874996],
            [76.6318359375, 35.729394531249994],
            [76.7275390625, 35.678662109375],
            [76.76689453124999, 35.66171875],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 4,
        labelrank: 5,
        sovereignt: "East Timor",
        sov_a3: "TLS",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "East Timor",
        adm0_a3: "TLS",
        geou_dif: 0,
        geounit: "East Timor",
        gu_a3: "TLS",
        su_dif: 0,
        subunit: "East Timor",
        su_a3: "TLS",
        brk_diff: 0,
        name: "Timor-Leste",
        name_long: "Timor-Leste",
        brk_a3: "TLS",
        brk_name: "Timor-Leste",
        brk_group: null,
        abbrev: "T.L.",
        postal: "TL",
        formal_en: "Democratic Republic of Timor-Leste",
        formal_fr: null,
        name_ciawf: "Timor-Leste",
        note_adm0: null,
        note_brk: null,
        name_sort: "Timor-Leste",
        name_alt: "East Timor",
        mapcolor7: 2,
        mapcolor8: 2,
        mapcolor9: 4,
        mapcolor13: 3,
        pop_est: 1293119,
        pop_rank: 12,
        pop_year: 2019,
        gdp_md: 2017,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "4. Lower middle income",
        fips_10: "TT",
        iso_a2: "TL",
        iso_a2_eh: "TL",
        iso_a3: "TLS",
        iso_a3_eh: "TLS",
        iso_n3: "626",
        iso_n3_eh: "626",
        un_a3: "626",
        wb_a2: "TP",
        wb_a3: "TMP",
        woe_id: 23424968,
        woe_id_eh: 23424968,
        woe_note: "Exact WOE match as country",
        adm0_iso: "TLS",
        adm0_diff: null,
        adm0_tlc: "TLS",
        adm0_a3_us: "TLS",
        adm0_a3_fr: "TLS",
        adm0_a3_ru: "TLS",
        adm0_a3_es: "TLS",
        adm0_a3_cn: "TLS",
        adm0_a3_tw: "TLS",
        adm0_a3_in: "TLS",
        adm0_a3_np: "TLS",
        adm0_a3_pk: "TLS",
        adm0_a3_de: "TLS",
        adm0_a3_gb: "TLS",
        adm0_a3_br: "TLS",
        adm0_a3_il: "TLS",
        adm0_a3_ps: "TLS",
        adm0_a3_sa: "TLS",
        adm0_a3_eg: "TLS",
        adm0_a3_ma: "TLS",
        adm0_a3_pt: "TLS",
        adm0_a3_ar: "TLS",
        adm0_a3_jp: "TLS",
        adm0_a3_ko: "TLS",
        adm0_a3_vn: "TLS",
        adm0_a3_tr: "TLS",
        adm0_a3_id: "TLS",
        adm0_a3_pl: "TLS",
        adm0_a3_gr: "TLS",
        adm0_a3_it: "TLS",
        adm0_a3_nl: "TLS",
        adm0_a3_se: "TLS",
        adm0_a3_bd: "TLS",
        adm0_a3_ua: "TLS",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "South-Eastern Asia",
        region_wb: "East Asia & Pacific",
        name_len: 11,
        long_len: 11,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: 125.854679,
        label_y: -8.803705,
        ne_id: 1159321313,
        wikidataid: "Q574",
        name_ar: "تيمور الشرقية",
        name_bn: "পূর্ব তিমুর",
        name_de: "Osttimor",
        name_en: "East Timor",
        name_es: "Timor Oriental",
        name_fa: "تیمور شرقی",
        name_fr: "Timor oriental",
        name_el: "Ανατολικό Τιμόρ",
        name_he: "מזרח טימור",
        name_hi: "पूर्वी तिमोर",
        name_hu: "Kelet-Timor",
        name_id: "Timor Leste",
        name_it: "Timor Est",
        name_ja: "東ティモール",
        name_ko: "동티모르",
        name_nl: "Oost-Timor",
        name_pl: "Timor Wschodni",
        name_pt: "Timor-Leste",
        name_ru: "Восточный Тимор",
        name_sv: "Östtimor",
        name_tr: "Doğu Timor",
        name_uk: "Східний Тимор",
        name_ur: "مشرقی تیمور",
        name_vi: "Đông Timor",
        name_zh: "东帝汶",
        name_zht: "東帝汶",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "TLS.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [125.64609375000003, -8.139941406250003],
              [125.57949218750002, -8.311816406250003],
              [125.50712890624999, -8.275097656250011],
              [125.58408203125003, -8.178613281250009],
              [125.62109375, -8.15],
              [125.64609375000003, -8.139941406250003],
            ],
          ],
          [
            [
              [125.06816406249999, -9.511914062500011],
              [125.03359375000002, -9.3818359375],
              [124.99697265625002, -9.325976562500003],
              [124.96826171875, -9.294238281250003],
              [124.95859375000003, -9.2546875],
              [124.96015625000001, -9.213769531250009],
              [124.9775390625, -9.194921875],
              [125.10048828125002, -9.18984375],
              [125.1494140625, -9.122949218750009],
              [125.14902343750003, -9.042578125],
              [125.12441406250002, -9.015429687500003],
              [125.10039062499999, -9.004003906250006],
              [124.97324218750003, -9.064257812500003],
              [124.93681640624999, -9.053417968750011],
              [124.9150390625, -9.031542968750003],
              [124.92226562500002, -8.942480468750006],
              [125.02695312500003, -8.859082031250011],
              [125.11572265625, -8.7080078125],
              [125.17802734374999, -8.647851562500009],
              [125.32314453125002, -8.59130859375],
              [125.3818359375, -8.575390625000011],
              [125.80429687500003, -8.4921875],
              [125.90507812499999, -8.486523437500011],
              [126.1728515625, -8.488964843750011],
              [126.53105468749999, -8.470800781250006],
              [126.61972656250003, -8.45947265625],
              [126.73457031250001, -8.422753906250009],
              [126.845703125, -8.37734375],
              [126.90468750000002, -8.341601562500003],
              [126.96640625000003, -8.315722656250003],
              [127.05849609375002, -8.348242187500006],
              [127.21484375, -8.372949218750009],
              [127.25703125000001, -8.39453125],
              [127.29609375000001, -8.424511718750011],
              [127.11455078124999, -8.58359375],
              [126.91523437500001, -8.715234375],
              [126.79248046875, -8.755078125000011],
              [126.66542968750002, -8.78203125],
              [126.56855468750001, -8.832910156250009],
              [126.48691406250003, -8.912695312500006],
              [126.38251953125001, -8.957617187500006],
              [126.26474609375003, -8.972753906250006],
              [126.16425781250001, -8.996679687500006],
              [126.07304687499999, -9.043554687500006],
              [125.94609374999999, -9.123925781250009],
              [125.89472656250001, -9.132128906250003],
              [125.84033203125, -9.130175781250003],
              [125.73515624999999, -9.1609375],
              [125.40800781249999, -9.27578125],
              [125.21025390624999, -9.403515625000011],
              [125.06816406249999, -9.511914062500011],
            ],
          ],
          [
            [
              [124.03632812500001, -9.341601562500003],
              [124.19814453125002, -9.256152343750003],
              [124.44443359375003, -9.190332031250009],
              [124.43828124999999, -9.238574218750003],
              [124.41298828125002, -9.314355468750009],
              [124.37568359375001, -9.349902343750003],
              [124.3193359375, -9.413769531250011],
              [124.28232421875003, -9.427929687500011],
              [124.13457031249999, -9.413867187500003],
              [124.11552734374999, -9.423144531250003],
              [124.09013671874999, -9.41640625],
              [124.05244140625001, -9.375390625],
              [124.03632812500001, -9.341601562500003],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 6,
        sovereignt: "Brunei",
        sov_a3: "BRN",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Brunei",
        adm0_a3: "BRN",
        geou_dif: 0,
        geounit: "Brunei",
        gu_a3: "BRN",
        su_dif: 0,
        subunit: "Brunei",
        su_a3: "BRN",
        brk_diff: 0,
        name: "Brunei",
        name_long: "Brunei Darussalam",
        brk_a3: "BRN",
        brk_name: "Brunei",
        brk_group: null,
        abbrev: "Brunei",
        postal: "BN",
        formal_en: "Negara Brunei Darussalam",
        formal_fr: null,
        name_ciawf: "Brunei",
        note_adm0: null,
        note_brk: null,
        name_sort: "Brunei",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 6,
        mapcolor9: 6,
        mapcolor13: 12,
        pop_est: 433285,
        pop_rank: 10,
        pop_year: 2019,
        gdp_md: 13469,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "2. High income: nonOECD",
        fips_10: "BX",
        iso_a2: "BN",
        iso_a2_eh: "BN",
        iso_a3: "BRN",
        iso_a3_eh: "BRN",
        iso_n3: "096",
        iso_n3_eh: "096",
        un_a3: "096",
        wb_a2: "BN",
        wb_a3: "BRN",
        woe_id: 23424773,
        woe_id_eh: 23424773,
        woe_note: "Exact WOE match as country",
        adm0_iso: "BRN",
        adm0_diff: null,
        adm0_tlc: "BRN",
        adm0_a3_us: "BRN",
        adm0_a3_fr: "BRN",
        adm0_a3_ru: "BRN",
        adm0_a3_es: "BRN",
        adm0_a3_cn: "BRN",
        adm0_a3_tw: "BRN",
        adm0_a3_in: "BRN",
        adm0_a3_np: "BRN",
        adm0_a3_pk: "BRN",
        adm0_a3_de: "BRN",
        adm0_a3_gb: "BRN",
        adm0_a3_br: "BRN",
        adm0_a3_il: "BRN",
        adm0_a3_ps: "BRN",
        adm0_a3_sa: "BRN",
        adm0_a3_eg: "BRN",
        adm0_a3_ma: "BRN",
        adm0_a3_pt: "BRN",
        adm0_a3_ar: "BRN",
        adm0_a3_jp: "BRN",
        adm0_a3_ko: "BRN",
        adm0_a3_vn: "BRN",
        adm0_a3_tr: "BRN",
        adm0_a3_id: "BRN",
        adm0_a3_pl: "BRN",
        adm0_a3_gr: "BRN",
        adm0_a3_it: "BRN",
        adm0_a3_nl: "BRN",
        adm0_a3_se: "BRN",
        adm0_a3_bd: "BRN",
        adm0_a3_ua: "BRN",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "South-Eastern Asia",
        region_wb: "East Asia & Pacific",
        name_len: 6,
        long_len: 17,
        abbrev_len: 6,
        tiny: 2,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: 114.551943,
        label_y: 4.448298,
        ne_id: 1159320451,
        wikidataid: "Q921",
        name_ar: "بروناي",
        name_bn: "ব্রুনাই",
        name_de: "Brunei",
        name_en: "Brunei",
        name_es: "Brunéi",
        name_fa: "برونئی",
        name_fr: "Brunei",
        name_el: "Μπρουνέι",
        name_he: "ברוניי",
        name_hi: "ब्रुनेई",
        name_hu: "Brunei",
        name_id: "Brunei Darussalam",
        name_it: "Brunei",
        name_ja: "ブルネイ",
        name_ko: "브루나이",
        name_nl: "Brunei",
        name_pl: "Brunei",
        name_pt: "Brunei",
        name_ru: "Бруней",
        name_sv: "Brunei",
        name_tr: "Brunei",
        name_uk: "Бруней",
        name_ur: "برونائی دار السلام",
        name_vi: "Brunei",
        name_zh: "文莱",
        name_zht: "汶萊",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "BRN.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [115.14003906250002, 4.899755859374991],
              [115.16845703125, 4.86669921875],
              [115.22792968750002, 4.750585937499991],
              [115.26669921875003, 4.633984375],
              [115.279296875, 4.456347656249989],
              [115.32675781250003, 4.380761718749994],
              [115.31923828125002, 4.365283203124989],
              [115.290625, 4.352587890624989],
              [115.24667968750003, 4.347216796874989],
              [115.17060546875001, 4.364208984374997],
              [115.10703125000003, 4.390429687499989],
              [115.05156249999999, 4.582666015624994],
              [115.02675781250002, 4.691357421874997],
              [115.02880859375, 4.821142578124991],
              [115.02675781250002, 4.899707031249989],
              [115.14003906250002, 4.899755859374991],
            ],
          ],
          [
            [
              [115.02675781250002, 4.899707031249989],
              [114.94472656250002, 4.85625],
              [114.86455078124999, 4.8017578125],
              [114.7841796875, 4.754833984374997],
              [114.74667968750003, 4.718066406249989],
              [114.75996093750001, 4.66650390625],
              [114.779296875, 4.553027343749989],
              [114.79013671875003, 4.463916015624989],
              [114.81826171875002, 4.428759765624989],
              [114.84023437500002, 4.393212890624994],
              [114.8310546875, 4.3544921875],
              [114.78349609374999, 4.28076171875],
              [114.81044921875002, 4.266503906249994],
              [114.77617187499999, 4.168798828124991],
              [114.725, 4.096533203124991],
              [114.65410156249999, 4.037646484374989],
              [114.60830078125002, 4.023974609374989],
              [114.57177734375, 4.049072265625],
              [114.51220703125, 4.113574218749989],
              [114.44707031249999, 4.203564453124997],
              [114.41660156250003, 4.255859375],
              [114.32294921875001, 4.262792968749991],
              [114.28964843750003, 4.30419921875],
              [114.28759765625, 4.354736328125],
              [114.26103515624999, 4.414257812499997],
              [114.22412109375, 4.477880859374991],
              [114.16884765625002, 4.526953125],
              [114.09511718750002, 4.565234374999989],
              [114.06386718750002, 4.592675781249994],
              [114.17792968750001, 4.590966796874994],
              [114.29941406250003, 4.607177734375],
              [114.42441406250003, 4.660400390625],
              [114.54472656249999, 4.724560546874997],
              [114.64589843750002, 4.798144531249989],
              [114.74082031250003, 4.881005859374994],
              [114.840625, 4.946386718749991],
              [114.99541015624999, 5.022363281249994],
              [115.04765624999999, 5.016357421875],
              [115.04707031250001, 4.962451171874989],
              [115.02675781250002, 4.899707031249989],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Bangladesh",
        sov_a3: "BGD",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Bangladesh",
        adm0_a3: "BGD",
        geou_dif: 0,
        geounit: "Bangladesh",
        gu_a3: "BGD",
        su_dif: 0,
        subunit: "Bangladesh",
        su_a3: "BGD",
        brk_diff: 0,
        name: "Bangladesh",
        name_long: "Bangladesh",
        brk_a3: "BGD",
        brk_name: "Bangladesh",
        brk_group: null,
        abbrev: "Bang.",
        postal: "BD",
        formal_en: "People's Republic of Bangladesh",
        formal_fr: null,
        name_ciawf: "Bangladesh",
        note_adm0: null,
        note_brk: null,
        name_sort: "Bangladesh",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 4,
        mapcolor9: 7,
        mapcolor13: 7,
        pop_est: 163046161,
        pop_rank: 17,
        pop_year: 2019,
        gdp_md: 302571,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        fips_10: "BG",
        iso_a2: "BD",
        iso_a2_eh: "BD",
        iso_a3: "BGD",
        iso_a3_eh: "BGD",
        iso_n3: "050",
        iso_n3_eh: "050",
        un_a3: "050",
        wb_a2: "BD",
        wb_a3: "BGD",
        woe_id: 23424759,
        woe_id_eh: 23424759,
        woe_note: "Exact WOE match as country",
        adm0_iso: "BGD",
        adm0_diff: null,
        adm0_tlc: "BGD",
        adm0_a3_us: "BGD",
        adm0_a3_fr: "BGD",
        adm0_a3_ru: "BGD",
        adm0_a3_es: "BGD",
        adm0_a3_cn: "BGD",
        adm0_a3_tw: "BGD",
        adm0_a3_in: "BGD",
        adm0_a3_np: "BGD",
        adm0_a3_pk: "BGD",
        adm0_a3_de: "BGD",
        adm0_a3_gb: "BGD",
        adm0_a3_br: "BGD",
        adm0_a3_il: "BGD",
        adm0_a3_ps: "BGD",
        adm0_a3_sa: "BGD",
        adm0_a3_eg: "BGD",
        adm0_a3_ma: "BGD",
        adm0_a3_pt: "BGD",
        adm0_a3_ar: "BGD",
        adm0_a3_jp: "BGD",
        adm0_a3_ko: "BGD",
        adm0_a3_vn: "BGD",
        adm0_a3_tr: "BGD",
        adm0_a3_id: "BGD",
        adm0_a3_pl: "BGD",
        adm0_a3_gr: "BGD",
        adm0_a3_it: "BGD",
        adm0_a3_nl: "BGD",
        adm0_a3_se: "BGD",
        adm0_a3_bd: "BGD",
        adm0_a3_ua: "BGD",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Southern Asia",
        region_wb: "South Asia",
        name_len: 10,
        long_len: 10,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: 89.684963,
        label_y: 24.214956,
        ne_id: 1159320407,
        wikidataid: "Q902",
        name_ar: "بنغلاديش",
        name_bn: "বাংলাদেশ",
        name_de: "Bangladesch",
        name_en: "Bangladesh",
        name_es: "Bangladés",
        name_fa: "بنگلادش",
        name_fr: "Bangladesh",
        name_el: "Μπανγκλαντές",
        name_he: "בנגלדש",
        name_hi: "बांग्लादेश",
        name_hu: "Banglades",
        name_id: "Bangladesh",
        name_it: "Bangladesh",
        name_ja: "バングラデシュ",
        name_ko: "방글라데시",
        name_nl: "Bangladesh",
        name_pl: "Bangladesz",
        name_pt: "Bangladesh",
        name_ru: "Бангладеш",
        name_sv: "Bangladesh",
        name_tr: "Bangladeş",
        name_uk: "Бангладеш",
        name_ur: "بنگلہ دیش",
        name_vi: "Bangladesh",
        name_zh: "孟加拉国",
        name_zht: "孟加拉",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "BGD.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [89.05146484375001, 22.093164062500136],
              [89.05585937500001, 22.186230468750068],
              [89.05, 22.274609375],
              [88.97148437499999, 22.5109375],
              [88.92070312499999, 22.632031249999955],
              [88.92695312500001, 22.671142578125114],
              [88.92343750000003, 22.687548828125102],
              [88.89970703124999, 22.843505859375057],
              [88.86699218749999, 22.938867187499994],
              [88.8505859375, 23.04052734375],
              [88.928125, 23.18662109374999],
              [88.89707031250003, 23.210400390624997],
              [88.8076171875, 23.2296875],
              [88.72441406249999, 23.254980468750034],
              [88.70400390625002, 23.29282226562499],
              [88.74082031250003, 23.43662109374999],
              [88.69765625000002, 23.493017578125034],
              [88.6357421875, 23.55],
              [88.61640625000001, 23.572753906250114],
              [88.59599609374999, 23.602197265624994],
              [88.5673828125, 23.67441406249999],
              [88.62255859375, 23.826367187500125],
              [88.69980468750003, 24.00253906249999],
              [88.71376953125002, 24.069628906250003],
              [88.7265625, 24.186230468749997],
              [88.73359375000001, 24.230908203124997],
              [88.72353515625002, 24.27490234375],
              [88.64228515625001, 24.325976562500003],
              [88.49853515624994, 24.346630859375068],
              [88.39697265625, 24.38925781249999],
              [88.3375, 24.453857421875],
              [88.287109375, 24.479736328125],
              [88.225, 24.46064453125001],
              [88.1455078125, 24.485791015624997],
              [88.0791015625, 24.54990234374999],
              [88.0234375, 24.627832031250136],
              [88.0302734375, 24.664453125],
              [88.04511718750001, 24.713037109374994],
              [88.14980468750002, 24.91464843749999],
              [88.18886718750002, 24.920605468749997],
              [88.27949218750001, 24.88193359374999],
              [88.31337890625002, 24.8818359375],
              [88.37294921875002, 24.96152343749999],
              [88.45625, 25.188427734374997],
              [88.57382812500003, 25.187890625],
              [88.67753906249999, 25.18046875],
              [88.74755859375, 25.1689453125],
              [88.81728515625002, 25.176220703124997],
              [88.89013671875, 25.194384765625102],
              [88.92978515625003, 25.22299804687499],
              [88.95166015625, 25.25927734375],
              [88.94414062499999, 25.290771484375],
              [88.85478515624999, 25.333544921875003],
              [88.82031249999994, 25.36552734374999],
              [88.79541015625, 25.45625],
              [88.76914062500003, 25.490478515625],
              [88.59345703125001, 25.4953125],
              [88.50244140625, 25.537011718749994],
              [88.45234375000001, 25.574414062499997],
              [88.36308593749999, 25.698193359374997],
              [88.2529296875, 25.789794921875057],
              [88.1474609375, 25.811425781249994],
              [88.10664062500001, 25.841113281250003],
              [88.08457031249998, 25.888232421875045],
              [88.09736328125001, 25.956347656250003],
              [88.12900390624998, 26.01821289062508],
              [88.15078124999997, 26.087158203125057],
              [88.23515624999993, 26.17807617187492],
              [88.333984375, 26.257519531249955],
              [88.37802734375003, 26.31201171875],
              [88.4404296875, 26.369482421875],
              [88.44785156250003, 26.401025390624994],
              [88.43671874999995, 26.437109374999977],
              [88.38623046875, 26.471533203125034],
              [88.35146484375002, 26.482568359374994],
              [88.34589843750001, 26.504785156249994],
              [88.36992187499999, 26.564111328124994],
              [88.41816406250001, 26.571533203125],
              [88.51826171875001, 26.517773437499997],
              [88.6201171875, 26.4306640625],
              [88.6806640625, 26.352978515624997],
              [88.68281250000001, 26.29169921875001],
              [88.72216796875, 26.2818359375],
              [88.76191406249995, 26.279394531249977],
              [88.82802734375002, 26.252197265625],
              [88.896484375, 26.260498046875],
              [88.94072265624999, 26.245361328125],
              [88.97041015625001, 26.250878906249994],
              [88.98154296875003, 26.2861328125],
              [88.9482421875, 26.33798828125],
              [88.92412109374999, 26.37509765625],
              [88.95195312499999, 26.412109375],
              [88.9833984375, 26.41953124999992],
              [89.01865234375003, 26.410253906249977],
              [89.06679687500002, 26.376904296874997],
              [89.10195312500002, 26.308349609375],
              [89.10830078125002, 26.20224609375009],
              [89.18642578125002, 26.10595703125],
              [89.28925781250001, 26.03759765625],
              [89.36972656250003, 26.006103515625],
              [89.46689453125003, 25.983544921874994],
              [89.54990234375003, 26.005273437499994],
              [89.59140625000003, 26.072412109374994],
              [89.57275390624994, 26.132324218750057],
              [89.58574218749999, 26.18603515625],
              [89.61904296875002, 26.215673828125],
              [89.6708984375, 26.213818359374997],
              [89.70986328125002, 26.171240234374977],
              [89.82294921875001, 25.94140625],
              [89.79960937499999, 25.839599609375114],
              [89.82490234375001, 25.56015625],
              [89.79628906250002, 25.37583007812492],
              [89.80087890625003, 25.336132812499997],
              [89.81406250000003, 25.305371093749997],
              [89.83330078124999, 25.292773437500003],
              [89.86630859375003, 25.293164062499997],
              [90.00380859375002, 25.258349609375003],
              [90.11962890625, 25.219970703125],
              [90.25039062499997, 25.184960937500136],
              [90.43935546875002, 25.15771484375],
              [90.55527343750003, 25.16660156249999],
              [90.61308593749999, 25.167724609375],
              [90.73017578125001, 25.159472656250102],
              [91.03828125000001, 25.174072265625],
              [91.29316406249995, 25.177978515625114],
              [91.39667968750001, 25.151611328125],
              [91.47968750000001, 25.142138671875003],
              [91.76347656249999, 25.16064453125],
              [92.04970703125002, 25.169482421875003],
              [92.20468750000003, 25.1109375],
              [92.37343750000002, 25.01513671875],
              [92.46835937500003, 24.944140625],
              [92.48544921875003, 24.903320312500114],
              [92.475, 24.86850585937499],
              [92.44316406249999, 24.84941406249999],
              [92.38496093750001, 24.848779296874994],
              [92.25126953124993, 24.895068359375045],
              [92.22832031249999, 24.88134765625],
              [92.23056640624998, 24.78623046875009],
              [92.22666015625003, 24.77099609375],
              [92.19804687499999, 24.685742187499997],
              [92.11748046874999, 24.493945312500003],
              [92.10195312500002, 24.40805664062499],
              [92.08505859375003, 24.386181640625125],
              [92.06416015625001, 24.374365234375034],
              [92.00107421875003, 24.370898437500003],
              [91.95166015625, 24.356738281250003],
              [91.93105468750002, 24.32553710937499],
              [91.89902343750003, 24.260693359374997],
              [91.876953125, 24.1953125],
              [91.84619140625, 24.17529296875],
              [91.7724609375, 24.210644531249997],
              [91.7265625, 24.205078125],
              [91.66875, 24.190087890624994],
              [91.61113281249999, 24.15283203125],
              [91.57138671874998, 24.106591796874966],
              [91.5263671875, 24.090771484374997],
              [91.39267578124998, 24.100097656250114],
              [91.36708984375002, 24.093505859375],
              [91.35019531250003, 24.060498046874997],
              [91.33642578125, 24.018798828125],
              [91.23203125000003, 23.920458984375003],
              [91.19248046875003, 23.762890625],
              [91.16044921874999, 23.66064453125],
              [91.16552734375, 23.5810546875],
              [91.25380859375002, 23.37363281249999],
              [91.31523437499999, 23.104394531249994],
              [91.3388671875, 23.07700195312499],
              [91.359375, 23.068359375000057],
              [91.36865234375, 23.07456054687499],
              [91.36679687500003, 23.13046875],
              [91.37060546875, 23.197998046875],
              [91.3994140625, 23.2138671875],
              [91.43623046875001, 23.199902343749997],
              [91.47138671875001, 23.141259765624994],
              [91.51123046875, 23.033691406250057],
              [91.55351562499999, 22.99155273437499],
              [91.61953125000002, 22.979687500000068],
              [91.69492187500003, 23.004833984374997],
              [91.7509765625, 23.053515625000045],
              [91.77382812500002, 23.10610351562508],
              [91.75791015625003, 23.20981445312499],
              [91.75419921874999, 23.287304687499997],
              [91.7900390625, 23.361035156249997],
              [91.91914062500001, 23.47104492187499],
              [91.93789062500002, 23.5046875],
              [91.92949218749999, 23.59824218749999],
              [91.92958984375002, 23.685986328124997],
              [91.978515625, 23.69199218750009],
              [92.04404296875003, 23.677783203125045],
              [92.12705078125003, 23.720996093750003],
              [92.15234375, 23.721875],
              [92.18710937500003, 23.675537109375],
              [92.24609374999994, 23.683593749999943],
              [92.28935546874999, 23.492480468750003],
              [92.33417968750001, 23.323828125],
              [92.33378906249999, 23.242382812499997],
              [92.34121093750002, 23.06982421875],
              [92.36162109374999, 22.929003906250003],
              [92.39316406250003, 22.897021484375003],
              [92.43046874999999, 22.821826171875003],
              [92.46445312500003, 22.734423828125003],
              [92.49140625000001, 22.68540039062499],
              [92.50957031249999, 22.525683593750003],
              [92.53183593750003, 22.410302734374994],
              [92.56123046875001, 22.048046875],
              [92.57490234374995, 21.97807617187499],
              [92.58281249999999, 21.940332031249994],
              [92.58427734374999, 21.609033203124994],
              [92.59345703125001, 21.467333984375003],
              [92.62529296874999, 21.350732421874994],
              [92.63164062499999, 21.306201171875003],
              [92.59980468750001, 21.270166015624994],
              [92.56855468750001, 21.263330078124994],
              [92.53916015625003, 21.31982421875],
              [92.471875, 21.362988281249997],
              [92.37265625000003, 21.40903320312499],
              [92.33056640625, 21.43979492187499],
              [92.27968750000002, 21.42758789062499],
              [92.20820312500001, 21.357861328124997],
              [92.17958984375002, 21.293115234374994],
              [92.19199218750003, 21.20224609374999],
              [92.21474609375002, 21.112695312499994],
              [92.26445312499999, 21.061474609374997],
              [92.26845703125002, 21.0046875],
              [92.28623046875003, 20.931591796874997],
              [92.31191406250002, 20.864453125],
              [92.32412109375002, 20.79184570312499],
              [92.30781250000001, 20.790429687499994],
              [92.24814453125003, 20.88359375],
              [92.19462890624999, 20.984277343749994],
              [92.05605468750002, 21.1748046875],
              [92.01093750000001, 21.516259765624994],
              [92.00800781250001, 21.684765625],
              [91.91318359375003, 21.883056640625],
              [91.85, 22.157373046874994],
              [91.82480468750003, 22.228662109374994],
              [91.85781250000002, 22.317333984374997],
              [91.86337890625003, 22.350488281249994],
              [91.84541015625001, 22.34311523437499],
              [91.79707031250001, 22.29746093749999],
              [91.73408203125001, 22.406689453124997],
              [91.69296875000003, 22.504785156249994],
              [91.52968750000002, 22.707666015624994],
              [91.48212890625001, 22.797412109375003],
              [91.48007812500003, 22.884814453125003],
              [91.40957031250002, 22.797021484374994],
              [91.31376953124999, 22.73515625],
              [91.21621093750002, 22.642236328124994],
              [91.1513671875, 22.6140625],
              [90.94560546874999, 22.59702148437499],
              [90.82675781250003, 22.721386718749997],
              [90.65625, 23.02548828124999],
              [90.63359374999999, 23.09423828125],
              [90.65605468749999, 23.273046875],
              [90.615625, 23.442333984374997],
              [90.61611328125002, 23.531640625],
              [90.60400390625, 23.591357421875003],
              [90.57343750000001, 23.578125],
              [90.56162109375003, 23.537109375],
              [90.56806640625001, 23.474267578124994],
              [90.5556640625, 23.421533203124994],
              [90.40800781249999, 23.431884765625],
              [90.26914062500003, 23.455859375],
              [90.39150390625002, 23.366943359375],
              [90.52275390624999, 23.346142578124997],
              [90.59091796875003, 23.26640625],
              [90.59921875000003, 23.204150390625003],
              [90.59511718750002, 23.133935546874994],
              [90.52773437500002, 23.0849609375],
              [90.46601562500001, 23.05390625],
              [90.4775390625, 22.986767578124997],
              [90.55224609375, 22.90488281249999],
              [90.46162109375001, 22.881787109374997],
              [90.43691406250002, 22.828173828125003],
              [90.43505859375, 22.751904296874997],
              [90.48066406250001, 22.68466796874999],
              [90.49843750000002, 22.634814453125003],
              [90.48740234375003, 22.58872070312499],
              [90.53173828125, 22.539306640625],
              [90.59550781249999, 22.435839843750003],
              [90.61611328125002, 22.36215820312499],
              [90.58945312500003, 22.258447265624994],
              [90.55283203125003, 22.218164062499994],
              [90.494140625, 22.17890625],
              [90.35576171874999, 22.048242187499994],
              [90.28818359375003, 21.8994140625],
              [90.23056640625003, 21.829785156249997],
              [90.15878906250003, 21.816845703124997],
              [90.13076171875002, 21.847412109375],
              [90.07119140625002, 21.887255859375003],
              [90.07001953125001, 21.959912109374997],
              [90.087890625, 22.017480468749994],
              [90.20957031250003, 22.15659179687499],
              [90.14345703125002, 22.137890625],
              [90.06855468750001, 22.098193359375003],
              [89.95419921875003, 22.022851562499994],
              [89.91806640625003, 22.116162109374997],
              [89.89404296875, 22.202587890624997],
              [89.89384765624999, 22.308398437500003],
              [89.98515624999999, 22.46640625],
              [89.8818359375, 22.387597656249994],
              [89.85322265625001, 22.288964843749994],
              [89.86582031250003, 22.173046875],
              [89.8525390625, 22.09091796874999],
              [89.81191406250002, 21.98349609374999],
              [89.7568359375, 21.91904296874999],
              [89.66777343749999, 21.877685546875],
              [89.628125, 21.814160156249997],
              [89.56855468750001, 21.76743164062499],
              [89.56660156250001, 21.860595703125],
              [89.54746093750003, 21.983691406250003],
              [89.48320312499999, 22.275537109374994],
              [89.46933593750003, 22.212939453125003],
              [89.50253906250003, 22.031884765624994],
              [89.50058593750003, 21.914355468750003],
              [89.45195312499999, 21.82109375],
              [89.35371093750001, 21.72109375],
              [89.27861328124999, 21.706982421874997],
              [89.23427734375002, 21.722363281249997],
              [89.16708984375003, 21.78427734374999],
              [89.09394531250001, 21.872753906249997],
              [89.08164062500003, 22.014941406250003],
              [89.05146484375001, 22.093164062500136],
            ],
          ],
          [
            [
              [91.15078125000002, 22.175195312499994],
              [91.04472656249999, 22.105175781249997],
              [91.07949218750002, 22.519726562499997],
              [91.15830078125003, 22.365429687499997],
              [91.17822265625, 22.283007812500003],
              [91.15078125000002, 22.175195312499994],
            ],
          ],
          [
            [
              [91.55673828125003, 22.382226562499994],
              [91.51044921875001, 22.352783203125],
              [91.46689453125003, 22.37841796875],
              [91.41132812500001, 22.47568359374999],
              [91.43886718750002, 22.598828125],
              [91.4560546875, 22.616503906250003],
              [91.48398437500003, 22.5765625],
              [91.52304687500003, 22.49072265625],
              [91.54833984375, 22.425390625],
              [91.55673828125003, 22.382226562499994],
            ],
          ],
          [
            [
              [90.77763671874999, 22.089306640624997],
              [90.60361328125003, 22.05419921875],
              [90.51503906250002, 22.065136718749997],
              [90.68046874999999, 22.32749023437499],
              [90.67490234375003, 22.444970703124994],
              [90.64921874999999, 22.540673828124994],
              [90.56494140625, 22.617626953124997],
              [90.56035156249999, 22.672558593749997],
              [90.52255859375003, 22.747509765624997],
              [90.5029296875, 22.835351562499994],
              [90.59648437499999, 22.863525390625],
              [90.67226562500002, 22.813183593749997],
              [90.68300781250002, 22.785302734374994],
              [90.69921875, 22.713525390624994],
              [90.73691406250003, 22.638720703125003],
              [90.8681640625, 22.48486328125],
              [90.86582031250003, 22.390576171874997],
              [90.82988281249999, 22.159960937500003],
              [90.77763671874999, 22.089306640624997],
            ],
          ],
          [
            [
              [91.87382812499999, 21.83212890624999],
              [91.83759765625001, 21.750244140625],
              [91.81972656250002, 21.809814453125],
              [91.83515625000001, 21.88535156249999],
              [91.85068359375003, 21.927050781250003],
              [91.861328125, 21.926660156249994],
              [91.88251953125001, 21.88364257812499],
              [91.87382812499999, 21.83212890624999],
            ],
          ],
          [
            [
              [91.94921875, 21.508056640625],
              [91.88886718750001, 21.503320312499994],
              [91.85947265625003, 21.532958984375],
              [91.87324218750001, 21.574414062499997],
              [91.85703125000003, 21.708789062500003],
              [91.90771484375, 21.722949218750003],
              [91.93398437500002, 21.72216796875],
              [91.94863281250002, 21.682568359374997],
              [91.9619140625, 21.609765625],
              [91.94921875, 21.508056640625],
            ],
          ],
          [
            [
              [90.64179687500001, 22.96298828124999],
              [90.65957031250002, 22.92001953124999],
              [90.60390625000002, 22.945556640625],
              [90.56230468749999, 22.97543945312499],
              [90.53632812500001, 23.014892578125],
              [90.57988281249999, 23.035449218750003],
              [90.64179687500001, 22.96298828124999],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 4,
        labelrank: 4,
        sovereignt: "Turkmenistan",
        sov_a3: "TKM",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Turkmenistan",
        adm0_a3: "TKM",
        geou_dif: 0,
        geounit: "Turkmenistan",
        gu_a3: "TKM",
        su_dif: 0,
        subunit: "Turkmenistan",
        su_a3: "TKM",
        brk_diff: 0,
        name: "Turkmenistan",
        name_long: "Turkmenistan",
        brk_a3: "TKM",
        brk_name: "Turkmenistan",
        brk_group: null,
        abbrev: "Turkm.",
        postal: "TM",
        formal_en: "Turkmenistan",
        formal_fr: null,
        name_ciawf: "Turkmenistan",
        note_adm0: null,
        note_brk: null,
        name_sort: "Turkmenistan",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 2,
        mapcolor9: 1,
        mapcolor13: 9,
        pop_est: 5942089,
        pop_rank: 13,
        pop_year: 2019,
        gdp_md: 40761,
        gdp_year: 2018,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        fips_10: "TX",
        iso_a2: "TM",
        iso_a2_eh: "TM",
        iso_a3: "TKM",
        iso_a3_eh: "TKM",
        iso_n3: "795",
        iso_n3_eh: "795",
        un_a3: "795",
        wb_a2: "TM",
        wb_a3: "TKM",
        woe_id: 23424972,
        woe_id_eh: 23424972,
        woe_note: "Exact WOE match as country",
        adm0_iso: "TKM",
        adm0_diff: null,
        adm0_tlc: "TKM",
        adm0_a3_us: "TKM",
        adm0_a3_fr: "TKM",
        adm0_a3_ru: "TKM",
        adm0_a3_es: "TKM",
        adm0_a3_cn: "TKM",
        adm0_a3_tw: "TKM",
        adm0_a3_in: "TKM",
        adm0_a3_np: "TKM",
        adm0_a3_pk: "TKM",
        adm0_a3_de: "TKM",
        adm0_a3_gb: "TKM",
        adm0_a3_br: "TKM",
        adm0_a3_il: "TKM",
        adm0_a3_ps: "TKM",
        adm0_a3_sa: "TKM",
        adm0_a3_eg: "TKM",
        adm0_a3_ma: "TKM",
        adm0_a3_pt: "TKM",
        adm0_a3_ar: "TKM",
        adm0_a3_jp: "TKM",
        adm0_a3_ko: "TKM",
        adm0_a3_vn: "TKM",
        adm0_a3_tr: "TKM",
        adm0_a3_id: "TKM",
        adm0_a3_pl: "TKM",
        adm0_a3_gr: "TKM",
        adm0_a3_it: "TKM",
        adm0_a3_nl: "TKM",
        adm0_a3_se: "TKM",
        adm0_a3_bd: "TKM",
        adm0_a3_ua: "TKM",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Central Asia",
        region_wb: "Europe & Central Asia",
        name_len: 12,
        long_len: 12,
        abbrev_len: 6,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: 58.676647,
        label_y: 39.855246,
        ne_id: 1159321309,
        wikidataid: "Q874",
        name_ar: "تركمانستان",
        name_bn: "তুর্কমেনিস্তান",
        name_de: "Turkmenistan",
        name_en: "Turkmenistan",
        name_es: "Turkmenistán",
        name_fa: "ترکمنستان",
        name_fr: "Turkménistan",
        name_el: "Τουρκμενιστάν",
        name_he: "טורקמניסטן",
        name_hi: "तुर्कमेनिस्तान",
        name_hu: "Türkmenisztán",
        name_id: "Turkmenistan",
        name_it: "Turkmenistan",
        name_ja: "トルクメニスタン",
        name_ko: "투르크메니스탄",
        name_nl: "Turkmenistan",
        name_pl: "Turkmenistan",
        name_pt: "Turquemenistão",
        name_ru: "Туркмения",
        name_sv: "Turkmenistan",
        name_tr: "Türkmenistan",
        name_uk: "Туркменістан",
        name_ur: "ترکمانستان",
        name_vi: "Turkmenistan",
        name_zh: "土库曼斯坦",
        name_zht: "土庫曼",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "TKM.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [53.10957031250001, 38.803076171875],
              [53.100195312500006, 38.756152343749996],
              [53.0458984375, 38.897216796875],
              [53.0185546875, 39.052734375],
              [53.053320312500006, 39.096582031249994],
              [53.09218750000002, 39.094091796875],
              [53.05517578125, 39.037939453125],
              [53.10957031250001, 38.803076171875],
            ],
          ],
          [
            [
              [55.97744140625002, 41.322216796875],
              [56.24199218750002, 41.310839843749996],
              [56.47988281250002, 41.300634765625],
              [56.77363281250001, 41.287988281249994],
              [56.86083984375, 41.276123046875],
              [56.9658203125, 41.26513671875],
              [57.01796875000002, 41.263476562499996],
              [57.06425781250002, 41.307275390624994],
              [57.094824218750006, 41.331298828125],
              [57.11884765625001, 41.35029296875],
              [57.113867187500006, 41.37177734375],
              [57.07666015625, 41.389990234375],
              [57.018164062500006, 41.450585937499994],
              [56.98486328125, 41.669335937499994],
              [56.96406250000001, 41.85654296875],
              [57.03369140625, 41.91484375],
              [57.11357421875002, 41.95712890625],
              [57.22880859375002, 42.08447265625],
              [57.290625, 42.123779296875],
              [57.38173828125002, 42.156298828124996],
              [57.686132812500006, 42.164794921875],
              [57.81425781250002, 42.18984375],
              [57.85595703125, 42.2310546875],
              [57.9234375, 42.335205078125],
              [57.94570312500002, 42.42001953125],
              [57.983496093750006, 42.458789062499996],
              [58.02890625, 42.487646484375],
              [58.07548828125002, 42.4865234375],
              [58.165625, 42.461572265624994],
              [58.23408203125001, 42.447705078125],
              [58.28291015625001, 42.428857421874994],
              [58.327246093750006, 42.39892578125],
              [58.37050781250002, 42.34677734375],
              [58.37714843750001, 42.312451171875],
              [58.397070312500006, 42.29248046875],
              [58.43144531250002, 42.29208984375],
              [58.45703125, 42.291796875],
              [58.47441406250002, 42.299365234374996],
              [58.48583984375, 42.316845703125],
              [58.47695312500002, 42.340136718749996],
              [58.41816406250001, 42.406689453125],
              [58.288671875, 42.527294921875],
              [58.2041015625, 42.5763671875],
              [58.16201171875002, 42.602978515625],
              [58.15156250000001, 42.628076171874994],
              [58.20644531250002, 42.666308593749996],
              [58.25966796875002, 42.6880859375],
              [58.353125, 42.671728515625],
              [58.477148437500006, 42.662841796875],
              [58.532324218750006, 42.681933593749996],
              [58.58906250000001, 42.778466796874994],
              [58.72998046875, 42.676171875],
              [58.876953125, 42.561474609375],
              [58.93085937500001, 42.540283203125],
              [59.03583984375001, 42.528125],
              [59.123144531250006, 42.523779296875],
              [59.15957031250002, 42.51142578125],
              [59.19912109375002, 42.481689453125],
              [59.27656250000001, 42.35615234375],
              [59.35429687500002, 42.323291015624996],
              [59.45107421875002, 42.29951171875],
              [59.76259765625002, 42.3015625],
              [59.85830078125002, 42.295166015625],
              [59.9365234375, 42.23603515625],
              [59.98515625000002, 42.21171875],
              [60.00605468750001, 42.190820312499994],
              [60.00078125000002, 42.16474609375],
              [59.98164062500001, 42.131738281249994],
              [59.97919921875001, 42.06806640625],
              [59.97412109375, 42.018798828125],
              [59.949316406250006, 41.995410156249996],
              [59.94179687500002, 41.973535156249994],
              [59.96259765625001, 41.954394531249996],
              [60.10693359375, 41.907421875],
              [60.15556640625002, 41.85703125],
              [60.19208984375001, 41.834423828125],
              [60.20078125, 41.803125],
              [60.176367187500006, 41.782275390624996],
              [60.10859375000001, 41.79267578125],
              [60.07558593750002, 41.759667968749994],
              [60.07558593750002, 41.700537109375],
              [60.1240234375, 41.644970703125],
              [60.13798828125002, 41.594140625],
              [60.106054687500006, 41.545214843749996],
              [60.06875, 41.476220703124994],
              [60.0673828125, 41.42734375],
              [60.08964843750002, 41.3994140625],
              [60.2, 41.348974609375],
              [60.45498046875002, 41.221630859375],
              [60.51357421875002, 41.216162109375],
              [60.7548828125, 41.245751953124994],
              [60.8671875, 41.248681640624994],
              [60.933203125, 41.22900390625],
              [61.11992187500002, 41.210888671875],
              [61.179296875, 41.190576171874994],
              [61.24238281250001, 41.189208984375],
              [61.32890625000002, 41.195117187499996],
              [61.3875, 41.2521484375],
              [61.41738281250002, 41.26513671875],
              [61.44365234375002, 41.274609375],
              [61.49697265625002, 41.27607421875],
              [61.64453125, 41.23984375],
              [61.799902343750006, 41.163427734375],
              [61.90283203125, 41.093701171875],
              [61.95351562500002, 41.030615234375],
              [62.017578125, 40.893798828125],
              [62.09501953125002, 40.68330078125],
              [62.1884765625, 40.5412109375],
              [62.29804687500001, 40.46748046875],
              [62.375, 40.332080078124996],
              [62.44160156250001, 40.03623046875],
              [62.48320312500002, 39.975634765624996],
              [62.525488281250006, 39.944091796875],
              [62.65068359375002, 39.85849609375],
              [62.906835937500006, 39.716796875],
              [63.05810546875, 39.633154296875],
              [63.29189453125002, 39.49951171875],
              [63.50605468750001, 39.377099609374994],
              [63.720800781250006, 39.188134765624994],
              [63.763671875, 39.160546875],
              [63.95253906250002, 39.058349609375],
              [64.16279296875001, 38.95361328125],
              [64.30996093750002, 38.977294921875],
              [64.53164062500002, 38.816210937499996],
              [64.621875, 38.7564453125],
              [64.65996093750002, 38.73603515625],
              [64.82070312500002, 38.6724609375],
              [65.07666015625, 38.539453125],
              [65.39960937500001, 38.348828125],
              [65.612890625, 38.238574218749996],
              [65.6708984375, 38.225732421874994],
              [65.728515625, 38.226367187499996],
              [65.79023437500001, 38.250048828124996],
              [65.85712890625001, 38.26875],
              [65.97119140625, 38.24423828125],
              [66.09482421875, 38.200146484375],
              [66.17314453125002, 38.16669921875],
              [66.263671875, 38.118066406249994],
              [66.33535156250002, 38.072167968749994],
              [66.38974609375, 38.050927734374994],
              [66.57451171875002, 38.010791015624996],
              [66.60625, 37.98671875],
              [66.62636718750002, 37.959863281249994],
              [66.62929687500002, 37.93203125],
              [66.52558593750001, 37.7857421875],
              [66.511328125, 37.599169921874996],
              [66.51064453125002, 37.45869140625],
              [66.52226562500002, 37.348486328125],
              [66.471875, 37.3447265625],
              [66.35029296875001, 37.3681640625],
              [66.1083984375, 37.41474609375],
              [65.90068359375002, 37.508105468749996],
              [65.76503906250002, 37.569140625],
              [65.74384765625001, 37.560839843749996],
              [65.68300781250002, 37.519140625],
              [65.6412109375, 37.467822265624996],
              [65.6080078125, 37.368408203125],
              [65.55498046875002, 37.251171875],
              [65.30361328125002, 37.24677734375],
              [65.08964843750002, 37.237939453124994],
              [64.95156250000002, 37.1935546875],
              [64.81630859375002, 37.132080078125],
              [64.78242187500001, 37.05927734375],
              [64.753125, 36.964794921875],
              [64.67431640625, 36.7501953125],
              [64.6025390625, 36.554541015625],
              [64.56582031250002, 36.427587890625],
              [64.51103515625002, 36.340673828125],
              [64.3580078125, 36.22607421875],
              [64.184375, 36.14892578125],
              [64.09218750000002, 36.112695312499994],
              [64.0513671875, 36.067626953125],
              [64.04238281250002, 36.02509765625],
              [64.00966796875002, 36.012109375],
              [63.938085937500006, 36.0197265625],
              [63.8625, 36.012353515624994],
              [63.69658203125002, 35.967822265624996],
              [63.51699218750002, 35.913134765624996],
              [63.30166015625002, 35.8583984375],
              [63.17890625000001, 35.858447265624996],
              [63.129980468750006, 35.84619140625],
              [63.10859375000001, 35.818701171875],
              [63.129980468750006, 35.766748046874994],
              [63.15078125000002, 35.728271484375],
              [63.16972656250002, 35.678125],
              [63.11933593750001, 35.637548828125],
              [63.08417968750001, 35.56806640625],
              [63.056640625, 35.44580078125],
              [62.98027343750002, 35.4091796875],
              [62.858007812500006, 35.349658203124996],
              [62.72265625, 35.271337890625],
              [62.688085937500006, 35.255322265625],
              [62.61054687500001, 35.233154296875],
              [62.53310546875002, 35.239892578124994],
              [62.462890625, 35.251367187499994],
              [62.38662109375002, 35.23125],
              [62.30781250000001, 35.170800781249994],
              [62.27119140625001, 35.189111328124994],
              [62.25283203125002, 35.250244140625],
              [62.21308593750001, 35.289941406249994],
              [62.08964843750002, 35.3796875],
              [61.98388671875, 35.443701171875],
              [61.938085937500006, 35.447900390624994],
              [61.84101562500001, 35.431494140625],
              [61.7197265625, 35.41943359375],
              [61.62099609375002, 35.43232421875],
              [61.54277343750002, 35.457861328125],
              [61.42177734375002, 35.545800781249994],
              [61.37773437500002, 35.593115234375],
              [61.3447265625, 35.6294921875],
              [61.26201171875002, 35.619580078125],
              [61.238867187500006, 35.65927734375],
              [61.23554687500001, 35.70556640625],
              [61.25869140625002, 35.76181640625],
              [61.25214843750001, 35.867626953125],
              [61.20585937500002, 35.943701171875],
              [61.152929687500006, 35.9767578125],
              [61.15947265625002, 35.999902343749994],
              [61.1826171875, 36.05283203125],
              [61.21240234375, 36.09912109375],
              [61.212011718750006, 36.19052734375],
              [61.17509765625002, 36.289697265624994],
              [61.16035156250001, 36.43271484375],
              [61.169921875, 36.572265625],
              [61.11962890625, 36.642578125],
              [60.70791015625002, 36.64296875],
              [60.34130859375, 36.637646484375],
              [60.32070312500002, 36.653564453125],
              [60.178320312500006, 36.829443359375],
              [60.06279296875002, 36.962890625],
              [59.94863281250002, 37.0416015625],
              [59.68720703125001, 37.138476562499996],
              [59.56220703125001, 37.17890625],
              [59.45498046875002, 37.252832031249994],
              [59.36738281250001, 37.333740234375],
              [59.3447265625, 37.444726562499994],
              [59.32695312500002, 37.48115234375],
              [59.3017578125, 37.510644531249994],
              [59.27412109375001, 37.523730468749996],
              [59.240820312500006, 37.520751953125],
              [58.93720703125001, 37.649658203125],
              [58.8154296875, 37.683496093749994],
              [58.70078125, 37.65625],
              [58.65019531250002, 37.6515625],
              [58.55048828125001, 37.68818359375],
              [58.43574218750001, 37.638525390625],
              [58.38671875, 37.6353515625],
              [58.31816406250002, 37.647216796875],
              [58.26162109375002, 37.665820312499996],
              [58.10878906250002, 37.783056640625],
              [57.980566406250006, 37.83046875],
              [57.88818359375, 37.86083984375],
              [57.710546875, 37.9052734375],
              [57.52099609375, 37.928466796875],
              [57.423828125, 37.947705078125],
              [57.35371093750001, 37.97333984375],
              [57.33574218750002, 37.98994140625],
              [57.33671875000002, 38.03291015625],
              [57.33144531250002, 38.089306640625],
              [57.30810546875, 38.13037109375],
              [57.26015625000002, 38.179589843749994],
              [57.19355468750001, 38.21640625],
              [57.07900390625002, 38.2099609375],
              [56.90664062500002, 38.213037109374994],
              [56.77460937500001, 38.250048828124996],
              [56.669921875, 38.256640625],
              [56.544042968750006, 38.249609375],
              [56.440625, 38.249414062499994],
              [56.36689453125001, 38.222509765625],
              [56.32412109375002, 38.19111328125],
              [56.296972656250006, 38.09482421875],
              [56.272070312500006, 38.080419921875],
              [56.22880859375002, 38.073388671874994],
              [56.17119140625002, 38.078369140625],
              [56.05029296875, 38.077539062499994],
              [55.84130859375, 38.094628906249994],
              [55.57841796875002, 38.099755859374994],
              [55.380859375, 38.051123046875],
              [55.224707031250006, 37.981347656249994],
              [55.07558593750002, 37.902490234374994],
              [54.90009765625001, 37.7779296875],
              [54.8486328125, 37.72265625],
              [54.745214843750006, 37.501904296875],
              [54.69941406250001, 37.470166015625],
              [54.6396484375, 37.444726562499994],
              [54.57890625000002, 37.440234375],
              [54.45869140625001, 37.407568359375],
              [54.2998046875, 37.35361328125],
              [54.19160156250001, 37.332470703125],
              [53.914160156250006, 37.343554687499996],
              [53.89785156250002, 37.41357421875],
              [53.84785156250001, 37.669580078124994],
              [53.82353515625002, 37.9279296875],
              [53.8251953125, 38.046923828124996],
              [53.854101562500006, 38.28564453125],
              [53.85185546875002, 38.405908203124994],
              [53.84003906250001, 38.514941406249996],
              [53.8515625, 38.62177734375],
              [53.87373046875001, 38.741943359375],
              [53.885351562500006, 38.8640625],
              [53.86865234375, 38.949267578124996],
              [53.81494140625, 39.018017578125],
              [53.72412109375, 39.103076171874996],
              [53.70976562500002, 39.15341796875],
              [53.70458984375, 39.2095703125],
              [53.61757812500002, 39.215966796874994],
              [53.53945312500002, 39.274072265624994],
              [53.475, 39.305712890624996],
              [53.33632812500002, 39.3408203125],
              [53.26679687500001, 39.342626953125],
              [53.20332031250001, 39.316796875],
              [53.15664062500002, 39.264990234375],
              [53.1240234375, 39.3466796875],
              [53.12480468750002, 39.432080078125],
              [53.23564453125002, 39.608544921874994],
              [53.30498046875002, 39.557080078125],
              [53.3896484375, 39.536425781249996],
              [53.49736328125002, 39.53330078125],
              [53.603125, 39.54697265625],
              [53.58242187500002, 39.607421875],
              [53.533300781250006, 39.641748046874994],
              [53.472265625, 39.668798828125],
              [53.45048828125002, 39.74853515625],
              [53.45830078125002, 39.831201171874994],
              [53.4873046875, 39.909375],
              [53.45429687500001, 39.940869140625],
              [53.40419921875002, 39.960351562499994],
              [53.28857421875, 39.9580078125],
              [53.13857421875002, 39.978662109374994],
              [52.9875, 39.987597656249996],
              [52.9521484375, 39.895458984375],
              [53.03554687500002, 39.7744140625],
              [52.96484375, 39.833886718749994],
              [52.89824218750002, 39.9125],
              [52.8046875, 40.054003906249996],
              [52.74443359375002, 40.219775390624996],
              [52.73369140625002, 40.398730468749996],
              [52.784765625, 40.546728515625],
              [52.84990234375002, 40.68564453125],
              [52.889257812500006, 40.8634765625],
              [52.943457031250006, 41.0380859375],
              [52.99765625, 40.959863281249994],
              [53.0595703125, 40.88974609375],
              [53.14521484375001, 40.824951171875],
              [53.191992187500006, 40.809472656249994],
              [53.33291015625002, 40.78271484375],
              [53.42363281250002, 40.792773437499996],
              [53.52031250000002, 40.8310546875],
              [53.615234375, 40.818505859374994],
              [53.69375, 40.746435546875],
              [53.763769531250006, 40.665673828124994],
              [53.87001953125002, 40.648681640625],
              [54.0888671875, 40.707080078124996],
              [54.19296875, 40.72041015625],
              [54.283300781250006, 40.693701171875],
              [54.32988281250002, 40.688769531249996],
              [54.37734375000002, 40.693261718749994],
              [54.33623046875002, 40.764941406249996],
              [54.319433593750006, 40.8345703125],
              [54.37441406250002, 40.871386718749996],
              [54.54707031250001, 40.832275390625],
              [54.65703125000002, 40.858349609375],
              [54.68505859375, 40.873046875],
              [54.710058593750006, 40.89111328125],
              [54.723242187500006, 40.95126953125],
              [54.71796875000001, 41.012988281249996],
              [54.703710937500006, 41.071142578125],
              [54.671484375, 41.12216796875],
              [54.59218750000002, 41.1935546875],
              [54.28457031250002, 41.363720703125],
              [54.18105468750002, 41.431591796875],
              [54.094824218750006, 41.519384765625],
              [54.03984375000002, 41.643359375],
              [53.995214843750006, 41.77255859375],
              [53.95380859375001, 41.868457031249996],
              [53.84648437500002, 42.091162109375],
              [53.8046875, 42.117626953125],
              [53.75234375000002, 42.12939453125],
              [53.62490234375002, 42.136376953124994],
              [53.49589843750002, 42.120166015624996],
              [53.28496093750002, 42.081835937499996],
              [53.164160156250006, 42.093798828124996],
              [53.10830078125002, 42.070068359375],
              [52.97001953125002, 41.976220703124994],
              [52.9052734375, 41.895751953125],
              [52.81484375000002, 41.711816406249994],
              [52.88349609375001, 41.6525390625],
              [52.88222656250002, 41.613671875],
              [52.830175781250006, 41.34189453125],
              [52.86181640625, 41.21005859375],
              [52.85039062500002, 41.20029296875],
              [52.82558593750002, 41.230859375],
              [52.74726562500001, 41.3654296875],
              [52.609375, 41.529443359374994],
              [52.49384765625001, 41.78037109375],
              [52.696875, 41.944384765624996],
              [52.87050781250002, 42.060595703124996],
              [53.0125, 42.130712890625],
              [53.05585937500001, 42.147753906249996],
              [53.250097656250006, 42.205859375],
              [53.50078125000002, 42.258251953125],
              [53.68535156250002, 42.296875],
              [53.926367187500006, 42.32978515625],
              [54.00517578125002, 42.335888671875],
              [54.12099609375002, 42.335205078125],
              [54.214941406250006, 42.30419921875],
              [54.271875, 42.27998046875],
              [54.47285156250001, 42.18017578125],
              [54.67792968750001, 42.07822265625],
              [54.85380859375002, 41.965185546875],
              [54.90371093750002, 41.919091796874994],
              [54.931640625, 41.864013671875],
              [54.95234375000001, 41.810009765625],
              [55.10185546875002, 41.638720703124996],
              [55.16230468750001, 41.56025390625],
              [55.249609375, 41.45810546875],
              [55.31972656250002, 41.4083984375],
              [55.38837890625001, 41.346923828125],
              [55.434375, 41.296289062499994],
              [55.48701171875001, 41.272265625],
              [55.54521484375002, 41.262744140624996],
              [55.67861328125002, 41.27880859375],
              [55.83906250000001, 41.310791015625],
              [55.93496093750002, 41.324121093749994],
              [55.97744140625002, 41.322216796875],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Afghanistan",
        sov_a3: "AFG",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Afghanistan",
        adm0_a3: "AFG",
        geou_dif: 0,
        geounit: "Afghanistan",
        gu_a3: "AFG",
        su_dif: 0,
        subunit: "Afghanistan",
        su_a3: "AFG",
        brk_diff: 0,
        name: "Afghanistan",
        name_long: "Afghanistan",
        brk_a3: "AFG",
        brk_name: "Afghanistan",
        brk_group: null,
        abbrev: "Afg.",
        postal: "AF",
        formal_en: "Islamic State of Afghanistan",
        formal_fr: null,
        name_ciawf: "Afghanistan",
        note_adm0: null,
        note_brk: null,
        name_sort: "Afghanistan",
        name_alt: null,
        mapcolor7: 5,
        mapcolor8: 6,
        mapcolor9: 8,
        mapcolor13: 7,
        pop_est: 38041754,
        pop_rank: 15,
        pop_year: 2019,
        gdp_md: 19291,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        fips_10: "AF",
        iso_a2: "AF",
        iso_a2_eh: "AF",
        iso_a3: "AFG",
        iso_a3_eh: "AFG",
        iso_n3: "004",
        iso_n3_eh: "004",
        un_a3: "004",
        wb_a2: "AF",
        wb_a3: "AFG",
        woe_id: 23424739,
        woe_id_eh: 23424739,
        woe_note: "Exact WOE match as country",
        adm0_iso: "AFG",
        adm0_diff: null,
        adm0_tlc: "AFG",
        adm0_a3_us: "AFG",
        adm0_a3_fr: "AFG",
        adm0_a3_ru: "AFG",
        adm0_a3_es: "AFG",
        adm0_a3_cn: "AFG",
        adm0_a3_tw: "AFG",
        adm0_a3_in: "AFG",
        adm0_a3_np: "AFG",
        adm0_a3_pk: "AFG",
        adm0_a3_de: "AFG",
        adm0_a3_gb: "AFG",
        adm0_a3_br: "AFG",
        adm0_a3_il: "AFG",
        adm0_a3_ps: "AFG",
        adm0_a3_sa: "AFG",
        adm0_a3_eg: "AFG",
        adm0_a3_ma: "AFG",
        adm0_a3_pt: "AFG",
        adm0_a3_ar: "AFG",
        adm0_a3_jp: "AFG",
        adm0_a3_ko: "AFG",
        adm0_a3_vn: "AFG",
        adm0_a3_tr: "AFG",
        adm0_a3_id: "AFG",
        adm0_a3_pl: "AFG",
        adm0_a3_gr: "AFG",
        adm0_a3_it: "AFG",
        adm0_a3_nl: "AFG",
        adm0_a3_se: "AFG",
        adm0_a3_bd: "AFG",
        adm0_a3_ua: "AFG",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Southern Asia",
        region_wb: "South Asia",
        name_len: 11,
        long_len: 11,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 7,
        label_x: 66.496586,
        label_y: 34.164262,
        ne_id: 1159320319,
        wikidataid: "Q889",
        name_ar: "أفغانستان",
        name_bn: "আফগানিস্তান",
        name_de: "Afghanistan",
        name_en: "Afghanistan",
        name_es: "Afganistán",
        name_fa: "افغانستان",
        name_fr: "Afghanistan",
        name_el: "Αφγανιστάν",
        name_he: "אפגניסטן",
        name_hi: "अफ़्गानिस्तान",
        name_hu: "Afganisztán",
        name_id: "Afganistan",
        name_it: "Afghanistan",
        name_ja: "アフガニスタン",
        name_ko: "아프가니스탄",
        name_nl: "Afghanistan",
        name_pl: "Afganistan",
        name_pt: "Afeganistão",
        name_ru: "Афганистан",
        name_sv: "Afghanistan",
        name_tr: "Afganistan",
        name_uk: "Афганістан",
        name_ur: "افغانستان",
        name_vi: "Afghanistan",
        name_zh: "阿富汗",
        name_zht: "阿富汗",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "AFG.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [66.52226562500002, 37.348486328125],
            [66.827734375, 37.3712890625],
            [67.06884765625, 37.334814453125],
            [67.19550781250001, 37.235205078125],
            [67.31972656250002, 37.2095703125],
            [67.44169921875002, 37.2580078125],
            [67.51728515625001, 37.266650390624996],
            [67.546484375, 37.235644531249996],
            [67.607421875, 37.222509765625],
            [67.7, 37.22724609375],
            [67.7529296875, 37.1998046875],
            [67.75898437500001, 37.172216796875],
            [67.76601562500002, 37.14013671875],
            [67.83447265625, 37.064208984375],
            [67.9580078125, 36.972021484375],
            [68.06777343750002, 36.9498046875],
            [68.21210937500001, 37.021533203124996],
            [68.26093750000001, 37.013085937499994],
            [68.284765625, 37.036328125],
            [68.29951171875001, 37.088427734374996],
            [68.38691406250001, 37.1375],
            [68.546484375, 37.183447265625],
            [68.63701171875002, 37.224462890625],
            [68.66914062500001, 37.2583984375],
            [68.7232421875, 37.268017578125],
            [68.78203125000002, 37.2580078125],
            [68.82373046875, 37.270703125],
            [68.8384765625, 37.30283203125],
            [68.85537109375002, 37.316845703125],
            [68.88525390625, 37.328076171875],
            [68.91181640625001, 37.333935546875],
            [68.96044921875, 37.325048828125],
            [69.05, 37.266503906249994],
            [69.18017578125, 37.15830078125],
            [69.26484375000001, 37.1083984375],
            [69.30390625000001, 37.116943359375],
            [69.35380859375002, 37.150048828124994],
            [69.41445312500002, 37.207763671875],
            [69.4296875, 37.290869140625],
            [69.39921875000002, 37.399316406249994],
            [69.42011718750001, 37.48671875],
            [69.49208984375002, 37.553076171875],
            [69.62578125000002, 37.594042968749996],
            [69.8208984375, 37.6095703125],
            [69.940625, 37.60029296875],
            [69.9849609375, 37.566162109375],
            [70.04472656250002, 37.547216796875],
            [70.11982421875001, 37.543505859374996],
            [70.18867187500001, 37.582470703125],
            [70.25146484375, 37.66416015625],
            [70.25498046875, 37.765380859375],
            [70.19941406250001, 37.886035156249996],
            [70.21464843750002, 37.9244140625],
            [70.23876953125, 37.941210937499996],
            [70.31328125000002, 37.984814453125],
            [70.41777343750002, 38.075439453125],
            [70.5185546875, 38.1919921875],
            [70.6158203125, 38.334423828125],
            [70.7359375, 38.42255859375],
            [70.87890625, 38.456396484375],
            [71.05214843750002, 38.417871093749994],
            [71.255859375, 38.306982421875],
            [71.33271484375001, 38.170263671875],
            [71.2828125, 38.00791015625],
            [71.27851562500001, 37.918408203125],
            [71.319921875, 37.90185546875],
            [71.3896484375, 37.906298828124996],
            [71.48779296875, 37.931884765625],
            [71.55195312500001, 37.933154296874996],
            [71.58222656250001, 37.910107421875],
            [71.58037109375002, 37.8642578125],
            [71.54619140625002, 37.795654296875],
            [71.50507812500001, 37.602929687499994],
            [71.47968750000001, 37.43603515625],
            [71.45478515625001, 37.271826171875],
            [71.43291015625002, 37.1275390625],
            [71.471875, 37.015087890625],
            [71.530859375, 36.845117187499994],
            [71.59746093750002, 36.73291015625],
            [71.665625, 36.696923828124994],
            [71.73378906250002, 36.684033203125],
            [71.80205078125002, 36.694287109375],
            [71.9419921875, 36.766455078125],
            [72.15351562500001, 36.900537109374994],
            [72.35878906250002, 36.98291015625],
            [72.65742187500001, 37.029052734375],
            [72.75703125000001, 37.172705078125],
            [72.8955078125, 37.267529296875],
            [73.21113281250001, 37.408496093749996],
            [73.38291015625, 37.462255859375],
            [73.48134765625002, 37.4716796875],
            [73.60468750000001, 37.446044921875],
            [73.63261718750002, 37.43720703125],
            [73.65712890625002, 37.43046875],
            [73.72060546875002, 37.41875],
            [73.73378906250002, 37.37578125],
            [73.71728515625, 37.329443359375],
            [73.64882812500002, 37.2912109375],
            [73.6275390625, 37.261572265625],
            [73.65351562500001, 37.23935546875],
            [73.749609375, 37.231787109375],
            [73.948828125, 37.283154296875],
            [74.077734375, 37.316210937499996],
            [74.16708984375, 37.329443359375],
            [74.20351562500002, 37.372460937499994],
            [74.25966796875002, 37.415429687499994],
            [74.34902343750002, 37.41875],
            [74.444921875, 37.39560546875],
            [74.52421875000002, 37.382373046874996],
            [74.659375, 37.394482421875],
            [74.73056640625, 37.35703125],
            [74.83046875000002, 37.2859375],
            [74.87539062500002, 37.241992187499996],
            [74.89130859375001, 37.231640625],
            [74.84023437500002, 37.225048828125],
            [74.76738281250002, 37.249169921874994],
            [74.73896484375001, 37.28564453125],
            [74.72666015625, 37.29072265625],
            [74.6689453125, 37.26669921875],
            [74.55898437500002, 37.236621093749996],
            [74.37216796875, 37.15771484375],
            [74.37617187500001, 37.137353515624994],
            [74.49794921875002, 37.0572265625],
            [74.52646484375, 37.030664062499994],
            [74.54140625000002, 37.02216796875],
            [74.43105468750002, 36.983691406249996],
            [74.19472656250002, 36.896875],
            [74.03886718750002, 36.825732421874996],
            [74.00185546875002, 36.823095703125],
            [73.9078125, 36.852929687499994],
            [73.769140625, 36.888476562499996],
            [73.73183593750002, 36.88779296875],
            [73.4111328125, 36.881689453125],
            [73.116796875, 36.868554687499994],
            [72.99375, 36.851611328124996],
            [72.7662109375, 36.835009765624996],
            [72.62285156250002, 36.82958984375],
            [72.53134765625, 36.802001953125],
            [72.43115234375, 36.7658203125],
            [72.32695312500002, 36.7423828125],
            [72.24980468750002, 36.734716796875],
            [72.15673828125, 36.70087890625],
            [72.09560546875002, 36.633740234375],
            [71.92070312500002, 36.5341796875],
            [71.822265625, 36.486083984375],
            [71.77265625000001, 36.4318359375],
            [71.71640625, 36.4265625],
            [71.62050781250002, 36.436474609375],
            [71.5458984375, 36.377685546875],
            [71.46328125000002, 36.293261718749996],
            [71.31259765625, 36.171191406249996],
            [71.23291015625, 36.12177734375],
            [71.18505859375, 36.04208984375],
            [71.22021484375, 36.00068359375],
            [71.34287109375, 35.938525390624996],
            [71.39755859375, 35.880175781249996],
            [71.42753906250002, 35.833740234375],
            [71.48359375000001, 35.714599609375],
            [71.51904296875, 35.597509765625],
            [71.57197265625001, 35.546826171875],
            [71.58740234375, 35.460839843749994],
            [71.6005859375, 35.40791015625],
            [71.57197265625001, 35.370410156249996],
            [71.5455078125, 35.328515625],
            [71.5455078125, 35.288867187499996],
            [71.57724609375, 35.247998046875],
            [71.60527343750002, 35.211767578125],
            [71.62050781250002, 35.183007812499994],
            [71.60166015625, 35.150683593749996],
            [71.5455078125, 35.101416015625],
            [71.51708984375, 35.051123046875],
            [71.455078125, 34.966943359374994],
            [71.35810546875001, 34.909619140625],
            [71.29414062500001, 34.867724609374996],
            [71.22578125000001, 34.779541015625],
            [71.11328125, 34.681591796875],
            [71.065625, 34.599609375],
            [71.01630859375001, 34.554638671875],
            [70.965625, 34.53037109375],
            [70.97890625000002, 34.486279296875],
            [71.02294921875, 34.43115234375],
            [71.095703125, 34.369433593749996],
            [71.0923828125, 34.273242187499996],
            [71.08906250000001, 34.204052734375],
            [71.09130859375, 34.120263671874994],
            [71.05156250000002, 34.049707031249994],
            [70.84843750000002, 33.981884765625],
            [70.65400390625001, 33.952294921874994],
            [70.41572265625001, 33.950439453125],
            [70.32568359375, 33.9611328125],
            [70.25361328125001, 33.975976562499994],
            [69.9947265625, 34.051806640624996],
            [69.8896484375, 34.007275390625],
            [69.86806640625002, 33.89765625],
            [70.056640625, 33.719873046874994],
            [70.13417968750002, 33.620751953124994],
            [70.2197265625, 33.4546875],
            [70.2841796875, 33.369042968749994],
            [70.26113281250002, 33.289013671875],
            [70.09023437500002, 33.198095703125],
            [69.92011718750001, 33.1125],
            [69.7037109375, 33.0947265625],
            [69.56777343750002, 33.06416015625],
            [69.5015625, 33.020068359374996],
            [69.453125, 32.8328125],
            [69.40458984375002, 32.7642578125],
            [69.40537109375, 32.68271484375],
            [69.35947265625, 32.59033203125],
            [69.28994140625002, 32.530566406249996],
            [69.24140625000001, 32.433544921875],
            [69.25654296875001, 32.249462890625],
            [69.279296875, 31.936816406249996],
            [69.18691406250002, 31.838085937499997],
            [69.08310546875, 31.738476562499997],
            [68.97343750000002, 31.667382812499994],
            [68.86894531250002, 31.634228515624997],
            [68.78232421875, 31.646435546874997],
            [68.71367187500002, 31.708056640624996],
            [68.67324218750002, 31.759716796874997],
            [68.59765625, 31.802978515625],
            [68.52070312500001, 31.794140625],
            [68.44326171875002, 31.7544921875],
            [68.31982421875, 31.76767578125],
            [68.21396484375, 31.807373046875],
            [68.16103515625002, 31.802978515625],
            [68.13017578125002, 31.76328125],
            [68.0171875, 31.677978515625],
            [67.73984375, 31.548193359375],
            [67.62675781250002, 31.538769531249997],
            [67.57822265625, 31.506494140624994],
            [67.59755859375002, 31.453320312499997],
            [67.6470703125, 31.409960937499996],
            [67.73349609375, 31.379248046875],
            [67.737890625, 31.343945312499997],
            [67.66152343750002, 31.31298828125],
            [67.59638671875001, 31.277685546875],
            [67.45283203125001, 31.234619140625],
            [67.28730468750001, 31.217822265624996],
            [67.11591796875001, 31.242919921875],
            [67.02773437500002, 31.300244140624997],
            [66.92431640625, 31.305615234374997],
            [66.82929687500001, 31.263671875],
            [66.73134765625002, 31.19453125],
            [66.62421875000001, 31.046044921874994],
            [66.59580078125, 31.019970703124997],
            [66.56679687500002, 30.99658203125],
            [66.49736328125002, 30.964550781249997],
            [66.39716796875001, 30.91220703125],
            [66.346875, 30.802783203124996],
            [66.28691406250002, 30.60791015625],
            [66.30097656250001, 30.502978515624996],
            [66.30546875000002, 30.321142578125],
            [66.2818359375, 30.19345703125],
            [66.23847656250001, 30.109619140625],
            [66.24716796875, 30.043505859374996],
            [66.31337890625002, 29.968554687499996],
            [66.28691406250002, 29.92001953125],
            [66.23125, 29.86572265625],
            [66.17705078125002, 29.835595703124994],
            [65.96162109375001, 29.77890625],
            [65.66621093750001, 29.701318359374994],
            [65.47099609375002, 29.6515625],
            [65.18046875000002, 29.57763671875],
            [65.09550781250002, 29.559472656249994],
            [64.9189453125, 29.552783203124996],
            [64.82734375000001, 29.564160156249997],
            [64.70351562500002, 29.567138671875],
            [64.52109375, 29.564501953124996],
            [64.39375, 29.544335937499994],
            [64.26611328125, 29.50693359375],
            [64.17216796875002, 29.460351562499994],
            [64.11796875000002, 29.414257812499997],
            [64.09873046875, 29.391943359375],
            [63.97099609375002, 29.430078125],
            [63.56757812500001, 29.497998046874997],
            [62.4765625, 29.408349609374994],
            [62.37343750000002, 29.425390625],
            [62.0009765625, 29.530419921874994],
            [61.521484375, 29.665673828124994],
            [61.22441406250002, 29.749414062499994],
            [60.843359375, 29.858691406249996],
            [61.104101562500006, 30.12841796875],
            [61.331640625, 30.363720703124997],
            [61.55947265625002, 30.599365234375],
            [61.7841796875, 30.831933593749994],
            [61.81083984375002, 30.91328125],
            [61.81425781250002, 31.072558593749996],
            [61.75507812500001, 31.285302734374994],
            [61.66015625, 31.382421875],
            [61.34648437500002, 31.421630859375],
            [61.11074218750002, 31.451123046874997],
            [60.854101562500006, 31.483251953125],
            [60.82070312500002, 31.495166015624996],
            [60.791601562500006, 31.660595703124997],
            [60.804296875, 31.73447265625],
            [60.7875, 31.877197265625],
            [60.78994140625002, 31.987109375],
            [60.827246093750006, 32.16796875],
            [60.82929687500001, 32.249414062499994],
            [60.71044921875, 32.6],
            [60.64453125, 32.794384765625],
            [60.57656250000002, 32.994873046875],
            [60.56191406250002, 33.0587890625],
            [60.560546875, 33.137841796874994],
            [60.71806640625002, 33.323535156249996],
            [60.76689453125002, 33.363818359374996],
            [60.85927734375002, 33.45625],
            [60.9169921875, 33.505224609375],
            [60.90693359375001, 33.538964843749994],
            [60.80644531250002, 33.55869140625],
            [60.65458984375002, 33.560400390625],
            [60.573828125, 33.588330078125],
            [60.510839843750006, 33.638916015625],
            [60.4859375, 33.7119140625],
            [60.52705078125001, 33.8419921875],
            [60.48574218750002, 34.094775390624996],
            [60.57021484375002, 34.219628906249994],
            [60.642675781250006, 34.307177734374996],
            [60.88945312500002, 34.31943359375],
            [60.80390625000001, 34.418017578124996],
            [60.76259765625002, 34.475244140624994],
            [60.73613281250002, 34.491796875],
            [60.72626953125001, 34.51826171875],
            [60.73945312500001, 34.544726562499996],
            [60.80234375, 34.554638671875],
            [60.8453125, 34.587695312499996],
            [60.91474609375001, 34.633984375],
            [60.951171875, 34.653857421874996],
            [60.95781250000002, 34.71005859375],
            [60.990820312500006, 34.749755859375],
            [61.04042968750002, 34.799365234374996],
            [61.080078125, 34.855615234374994],
            [61.07021484375002, 34.921728515625],
            [61.10664062500001, 35.001123046874994],
            [61.123144531250006, 35.050732421875],
            [61.14960937500001, 35.09375],
            [61.12646484375, 35.156542968749996],
            [61.10664062500001, 35.20947265625],
            [61.1, 35.272314453125],
            [61.1396484375, 35.288867187499996],
            [61.18925781250002, 35.31201171875],
            [61.19921875, 35.361621093749996],
            [61.225683593750006, 35.424462890624994],
            [61.24550781250002, 35.474072265625],
            [61.27851562500001, 35.51376953125],
            [61.281835937500006, 35.55341796875],
            [61.26201171875002, 35.619580078125],
            [61.3447265625, 35.6294921875],
            [61.37773437500002, 35.593115234375],
            [61.42177734375002, 35.545800781249994],
            [61.54277343750002, 35.457861328125],
            [61.62099609375002, 35.43232421875],
            [61.7197265625, 35.41943359375],
            [61.84101562500001, 35.431494140625],
            [61.938085937500006, 35.447900390624994],
            [61.98388671875, 35.443701171875],
            [62.08964843750002, 35.3796875],
            [62.21308593750001, 35.289941406249994],
            [62.25283203125002, 35.250244140625],
            [62.27119140625001, 35.189111328124994],
            [62.30781250000001, 35.170800781249994],
            [62.38662109375002, 35.23125],
            [62.462890625, 35.251367187499994],
            [62.53310546875002, 35.239892578124994],
            [62.61054687500001, 35.233154296875],
            [62.688085937500006, 35.255322265625],
            [62.72265625, 35.271337890625],
            [62.858007812500006, 35.349658203124996],
            [62.98027343750002, 35.4091796875],
            [63.056640625, 35.44580078125],
            [63.08417968750001, 35.56806640625],
            [63.11933593750001, 35.637548828125],
            [63.16972656250002, 35.678125],
            [63.15078125000002, 35.728271484375],
            [63.129980468750006, 35.766748046874994],
            [63.10859375000001, 35.818701171875],
            [63.129980468750006, 35.84619140625],
            [63.17890625000001, 35.858447265624996],
            [63.30166015625002, 35.8583984375],
            [63.51699218750002, 35.913134765624996],
            [63.69658203125002, 35.967822265624996],
            [63.8625, 36.012353515624994],
            [63.938085937500006, 36.0197265625],
            [64.00966796875002, 36.012109375],
            [64.04238281250002, 36.02509765625],
            [64.0513671875, 36.067626953125],
            [64.09218750000002, 36.112695312499994],
            [64.184375, 36.14892578125],
            [64.3580078125, 36.22607421875],
            [64.51103515625002, 36.340673828125],
            [64.56582031250002, 36.427587890625],
            [64.6025390625, 36.554541015625],
            [64.67431640625, 36.7501953125],
            [64.753125, 36.964794921875],
            [64.78242187500001, 37.05927734375],
            [64.81630859375002, 37.132080078125],
            [64.95156250000002, 37.1935546875],
            [65.08964843750002, 37.237939453124994],
            [65.30361328125002, 37.24677734375],
            [65.55498046875002, 37.251171875],
            [65.6080078125, 37.368408203125],
            [65.6412109375, 37.467822265624996],
            [65.68300781250002, 37.519140625],
            [65.74384765625001, 37.560839843749996],
            [65.76503906250002, 37.569140625],
            [65.90068359375002, 37.508105468749996],
            [66.1083984375, 37.41474609375],
            [66.35029296875001, 37.3681640625],
            [66.471875, 37.3447265625],
            [66.52226562500002, 37.348486328125],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Myanmar",
        sov_a3: "MMR",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Myanmar",
        adm0_a3: "MMR",
        geou_dif: 0,
        geounit: "Myanmar",
        gu_a3: "MMR",
        su_dif: 0,
        subunit: "Myanmar",
        su_a3: "MMR",
        brk_diff: 0,
        name: "Myanmar",
        name_long: "Myanmar",
        brk_a3: "MMR",
        brk_name: "Myanmar",
        brk_group: null,
        abbrev: "Myan.",
        postal: "MM",
        formal_en: "Republic of the Union of Myanmar",
        formal_fr: null,
        name_ciawf: "Burma",
        note_adm0: null,
        note_brk: null,
        name_sort: "Myanmar",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 2,
        mapcolor9: 5,
        mapcolor13: 13,
        pop_est: 54045420,
        pop_rank: 16,
        pop_year: 2019,
        gdp_md: 76085,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        fips_10: "BM",
        iso_a2: "MM",
        iso_a2_eh: "MM",
        iso_a3: "MMR",
        iso_a3_eh: "MMR",
        iso_n3: "104",
        iso_n3_eh: "104",
        un_a3: "104",
        wb_a2: "MM",
        wb_a3: "MMR",
        woe_id: 23424763,
        woe_id_eh: 23424763,
        woe_note: "Exact WOE match as country",
        adm0_iso: "MMR",
        adm0_diff: null,
        adm0_tlc: "MMR",
        adm0_a3_us: "MMR",
        adm0_a3_fr: "MMR",
        adm0_a3_ru: "MMR",
        adm0_a3_es: "MMR",
        adm0_a3_cn: "MMR",
        adm0_a3_tw: "MMR",
        adm0_a3_in: "MMR",
        adm0_a3_np: "MMR",
        adm0_a3_pk: "MMR",
        adm0_a3_de: "MMR",
        adm0_a3_gb: "MMR",
        adm0_a3_br: "MMR",
        adm0_a3_il: "MMR",
        adm0_a3_ps: "MMR",
        adm0_a3_sa: "MMR",
        adm0_a3_eg: "MMR",
        adm0_a3_ma: "MMR",
        adm0_a3_pt: "MMR",
        adm0_a3_ar: "MMR",
        adm0_a3_jp: "MMR",
        adm0_a3_ko: "MMR",
        adm0_a3_vn: "MMR",
        adm0_a3_tr: "MMR",
        adm0_a3_id: "MMR",
        adm0_a3_pl: "MMR",
        adm0_a3_gr: "MMR",
        adm0_a3_it: "MMR",
        adm0_a3_nl: "MMR",
        adm0_a3_se: "MMR",
        adm0_a3_bd: "MMR",
        adm0_a3_ua: "MMR",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "South-Eastern Asia",
        region_wb: "East Asia & Pacific",
        name_len: 7,
        long_len: 7,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: 95.804497,
        label_y: 21.573855,
        ne_id: 1159321067,
        wikidataid: "Q836",
        name_ar: "ميانمار",
        name_bn: "মিয়ানমার",
        name_de: "Myanmar",
        name_en: "Myanmar",
        name_es: "Birmania",
        name_fa: "میانمار",
        name_fr: "Birmanie",
        name_el: "Μιανμάρ",
        name_he: "מיאנמר",
        name_hi: "म्यान्मार",
        name_hu: "Mianmar",
        name_id: "Myanmar",
        name_it: "Birmania",
        name_ja: "ミャンマー",
        name_ko: "미얀마",
        name_nl: "Myanmar",
        name_pl: "Mjanma",
        name_pt: "Myanmar",
        name_ru: "Мьянма",
        name_sv: "Myanmar",
        name_tr: "Myanmar",
        name_uk: "М'янма",
        name_ur: "میانمار",
        name_vi: "Myanma",
        name_zh: "缅甸",
        name_zht: "緬甸",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "MMR.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [100.12246093750002, 20.316650390625],
              [100.00361328125001, 20.379589843749997],
              [99.95429687500001, 20.415429687499994],
              [99.89033203125001, 20.42441406249999],
              [99.8251953125, 20.384472656249997],
              [99.77333984375002, 20.34130859375],
              [99.72011718750002, 20.325439453125],
              [99.638671875, 20.320458984374994],
              [99.53164062500002, 20.342822265625003],
              [99.45888671875002, 20.363037109375],
              [99.44794921875001, 20.35205078125],
              [99.4875, 20.260644531249994],
              [99.50166015625001, 20.187744140625],
              [99.48593750000003, 20.149853515624997],
              [99.45156250000002, 20.118310546874994],
              [99.39921874999999, 20.093457031249997],
              [99.337890625, 20.07890625],
              [99.28369140625, 20.08041992187499],
              [99.196875, 20.115136718749994],
              [99.13076171875002, 20.116601562499994],
              [99.07421875, 20.099365234375],
              [99.03974609375001, 20.073632812499994],
              [99.02070312500001, 20.041796875],
              [98.98740234375003, 19.861376953125003],
              [98.9580078125, 19.804931640625],
              [98.91669921875001, 19.772900390624997],
              [98.87578124999999, 19.769580078125003],
              [98.81953125000001, 19.778466796874994],
              [98.76064453125002, 19.77109375],
              [98.49384765625001, 19.701318359374994],
              [98.45498046875002, 19.69443359374999],
              [98.37128906250001, 19.689160156249997],
              [98.29365234375001, 19.687255859375],
              [98.23906249999999, 19.690673828125],
              [98.11103515625001, 19.762158203124997],
              [98.04902343750001, 19.769726562499997],
              [98.01503906250002, 19.74951171875],
              [97.9912109375, 19.653710937499994],
              [97.91640625000002, 19.59287109374999],
              [97.81679687500002, 19.4599609375],
              [97.79355468750003, 19.265869140625],
              [97.80390625000001, 19.13046875],
              [97.71416015624999, 18.996484375],
              [97.7060546875, 18.931787109374994],
              [97.75400390625003, 18.620800781249997],
              [97.74589843749999, 18.588183593750003],
              [97.72773437500001, 18.572021484375],
              [97.67158203125001, 18.561230468749997],
              [97.57734375000001, 18.528710937499994],
              [97.51513671875, 18.497753906249997],
              [97.48496093750003, 18.49423828124999],
              [97.39707031250003, 18.517529296874997],
              [97.37392578125002, 18.51796875],
              [97.38066406249999, 18.494287109374994],
              [97.45078125000003, 18.359667968750003],
              [97.52382812500002, 18.2958984375],
              [97.59931640625001, 18.302978515625],
              [97.63222656250002, 18.290332031250003],
              [97.62246093750002, 18.258007812499997],
              [97.65156250000001, 18.173730468749994],
              [97.7197265625, 18.037402343750003],
              [97.73994140625001, 17.935302734375],
              [97.69853515624999, 17.833544921875003],
              [97.70644531250002, 17.797119140625],
              [97.72910156250003, 17.775830078124997],
              [97.79296875, 17.68125],
              [97.92929687500003, 17.53330078124999],
              [98.06308593750003, 17.373291015625],
              [98.17460937499999, 17.239892578124994],
              [98.25654296875001, 17.14765625],
              [98.43886718750002, 16.97568359374999],
              [98.47119140625, 16.89501953125],
              [98.478125, 16.732226562500003],
              [98.52314453125001, 16.63818359375],
              [98.56474609374999, 16.570947265624994],
              [98.59365234375002, 16.514794921874994],
              [98.66074218750003, 16.33041992187499],
              [98.68925781249999, 16.305419921875],
              [98.83544921875, 16.417578125],
              [98.86933593750001, 16.394189453124994],
              [98.88828125000003, 16.35190429687499],
              [98.88847656249999, 16.298095703125],
              [98.86552734374999, 16.237060546875],
              [98.81796875000003, 16.180810546874994],
              [98.59238281250003, 16.050683593749994],
              [98.57402343749999, 15.938623046874994],
              [98.55820312500003, 15.768603515625003],
              [98.55449218749999, 15.559765625],
              [98.56523437499999, 15.403564453125],
              [98.55693359374999, 15.36767578125],
              [98.53730468750001, 15.350683593749991],
              [98.4521484375, 15.357373046874997],
              [98.32939453124999, 15.278564453125],
              [98.2861328125, 15.271582031249991],
              [98.23222656249999, 15.241357421874994],
              [98.19101562500003, 15.2041015625],
              [98.17792968750001, 15.147412109374997],
              [98.2021484375, 14.975927734374991],
              [98.24599609375002, 14.814746093750003],
              [98.33212890625003, 14.696484375],
              [98.40019531249999, 14.602978515624997],
              [98.49501953125002, 14.472900390625],
              [98.57001953125001, 14.359912109375003],
              [98.72119140625, 14.235742187499994],
              [98.93359375, 14.049853515625003],
              [99.0146484375, 13.947167968749994],
              [99.08623046874999, 13.82275390625],
              [99.13681640625003, 13.716699218749994],
              [99.15605468749999, 13.57578125],
              [99.17167968749999, 13.496923828124991],
              [99.17617187500002, 13.233056640624994],
              [99.13710937500002, 13.172998046874994],
              [99.107421875, 13.103515625],
              [99.12392578125002, 13.03076171875],
              [99.17353515625001, 12.961328125],
              [99.17353515625001, 12.881933593749991],
              [99.21982421875003, 12.73974609375],
              [99.29736328125, 12.652880859374989],
              [99.37197265625002, 12.59423828125],
              [99.40507812499999, 12.547900390624989],
              [99.39423828125001, 12.4736328125],
              [99.41630859374999, 12.394824218749989],
              [99.43242187499999, 12.309033203124997],
              [99.462890625, 12.190234374999989],
              [99.52294921875, 12.089648437499989],
              [99.61474609375, 11.781201171874997],
              [99.6125, 11.749658203124994],
              [99.57285156250003, 11.687158203124994],
              [99.51523437500003, 11.630664062499989],
              [99.47792968750002, 11.6125],
              [99.44267578124999, 11.554394531249997],
              [99.35878906250002, 11.389453124999989],
              [99.19013671875001, 11.105273437499989],
              [99.025390625, 10.919970703124989],
              [98.88710937500002, 10.788330078125],
              [98.78691406249999, 10.708447265624997],
              [98.75722656250002, 10.660937499999989],
              [98.75722656250002, 10.623583984374989],
              [98.775390625, 10.55703125],
              [98.76835937499999, 10.430859375],
              [98.746875, 10.350830078125],
              [98.71845703125001, 10.266015625],
              [98.70253906250002, 10.190380859374997],
              [98.65800781249999, 10.179052734374991],
              [98.56259765625003, 10.034960937499989],
              [98.52128906249999, 10.107226562499989],
              [98.496875, 10.182519531249994],
              [98.52304687500003, 10.353125],
              [98.46494140625003, 10.675830078124989],
              [98.5009765625, 10.718945312499997],
              [98.53564453125, 10.740673828124997],
              [98.59882812500001, 10.864404296874994],
              [98.67558593749999, 10.986914062499991],
              [98.6826171875, 11.133105468749989],
              [98.74472656250003, 11.240380859374994],
              [98.73007812500003, 11.329980468749994],
              [98.73330078125002, 11.435253906249997],
              [98.74638671874999, 11.521289062499989],
              [98.74140625000001, 11.591699218749994],
              [98.79072265625001, 11.665087890624989],
              [98.8759765625, 11.7197265625],
              [98.84023437500002, 11.7392578125],
              [98.80478515625003, 11.779248046874997],
              [98.69365234374999, 11.718359375],
              [98.63632812500003, 11.738378906249991],
              [98.62490234375002, 11.801464843749997],
              [98.63906250000002, 11.869140625],
              [98.64492187500002, 11.910302734374994],
              [98.689453125, 11.956738281249997],
              [98.68632812499999, 12.047119140625],
              [98.66386718749999, 12.126708984375],
              [98.6962890625, 12.225244140624994],
              [98.63056640625001, 12.225488281249994],
              [98.60029296875001, 12.2453125],
              [98.619140625, 12.3],
              [98.6787109375, 12.348486328124991],
              [98.62441406250002, 12.440722656249989],
              [98.66464843750003, 12.539941406249994],
              [98.66318359375003, 12.662402343749989],
              [98.63564453125002, 12.7705078125],
              [98.63710937500002, 12.848242187499991],
              [98.59511718750002, 12.986035156249997],
              [98.57597656249999, 13.161914062499989],
              [98.48710937499999, 13.293066406249991],
              [98.42128906250002, 13.483789062499994],
              [98.24541015624999, 13.733496093749991],
              [98.24843750000002, 13.840380859375003],
              [98.23896484375001, 13.934472656249994],
              [98.20039062500001, 13.980175781249997],
              [98.14951171875003, 13.647607421874994],
              [98.11064453124999, 13.712890625],
              [98.09824218750003, 13.898339843749994],
              [98.07265625000002, 13.986474609374994],
              [98.10019531250003, 14.161523437499994],
              [97.99843750000002, 14.335302734374991],
              [97.9765625, 14.461474609375003],
              [97.90976562500003, 14.652685546874991],
              [97.92929687500003, 14.695556640625],
              [98.01875, 14.652587890625],
              [97.9365234375, 14.763916015625],
              [97.869140625, 14.738720703124997],
              [97.81230468749999, 14.858935546875003],
              [97.7998046875, 15.184912109374991],
              [97.74375, 15.306787109374994],
              [97.77421874999999, 15.430957031250003],
              [97.71035156250002, 15.875537109375003],
              [97.58427734374999, 16.019580078125003],
              [97.60927734375002, 16.143847656250003],
              [97.640625, 16.253857421874997],
              [97.63369140625002, 16.457666015624994],
              [97.66464843750003, 16.520458984374997],
              [97.72597656250002, 16.568554687499997],
              [97.66845703125, 16.55161132812499],
              [97.61962890625, 16.53720703124999],
              [97.50507812500001, 16.525292968749994],
              [97.37587890625002, 16.52294921875],
              [97.3310546875, 16.671777343749994],
              [97.26748046875002, 16.743115234374997],
              [97.21171874999999, 16.892578125],
              [97.17832031250003, 17.06201171875],
              [97.2001953125, 17.095410156249997],
              [97.10019531250003, 17.16455078125],
              [97.07451171874999, 17.206933593749994],
              [96.97011718750002, 17.317333984374997],
              [96.85146484375002, 17.401025390624994],
              [96.87773437499999, 17.3421875],
              [96.90976562500003, 17.304833984374994],
              [96.85087890624999, 17.202929687500003],
              [96.90859375000002, 17.030957031249997],
              [96.85800781250003, 16.921191406250003],
              [96.81064453125003, 16.778369140625003],
              [96.76542968749999, 16.710351562499994],
              [96.62246093750002, 16.563916015624997],
              [96.50664062499999, 16.514355468749997],
              [96.43115234375, 16.504931640625003],
              [96.36435546875003, 16.5205078125],
              [96.2822265625, 16.595996093750003],
              [96.26210937500002, 16.659130859374997],
              [96.24892578125002, 16.765332031249997],
              [96.22031250000003, 16.780566406250003],
              [96.18906250000003, 16.768310546875],
              [96.23769531250002, 16.63125],
              [96.23671875000002, 16.567431640625003],
              [96.32431640625003, 16.44443359374999],
              [96.29306640625003, 16.410058593749994],
              [96.13505859374999, 16.342529296875],
              [96.08095703125002, 16.35336914062499],
              [96.04287109375002, 16.33994140624999],
              [96.03212890625002, 16.284619140624997],
              [96.01230468750003, 16.253710937500003],
              [95.76328125000003, 16.16904296874999],
              [95.71142578125, 16.073388671874994],
              [95.67949218749999, 15.976757812499997],
              [95.5556640625, 15.837841796874997],
              [95.38955078125002, 15.722753906249991],
              [95.34843749999999, 15.729296875],
              [95.30146484375001, 15.756152343750003],
              [95.30781250000001, 15.880419921875003],
              [95.36474609375, 15.985449218749991],
              [95.34677734375003, 16.097607421874997],
              [95.3330078125, 16.033251953125003],
              [95.22587890624999, 15.876806640624991],
              [95.17695312500001, 15.82568359375],
              [95.07832031250001, 15.839160156250003],
              [94.94257812500001, 15.818261718749994],
              [94.89121093750003, 15.979101562499991],
              [94.89218750000003, 16.03818359374999],
              [94.88222656250002, 16.087939453125003],
              [94.89785156250002, 16.140820312499997],
              [94.89316406250003, 16.1828125],
              [94.86015624999999, 16.102441406249994],
              [94.84775390625003, 16.032861328124994],
              [94.79814453124999, 15.97109375],
              [94.66152343750002, 15.904394531249991],
              [94.65625, 15.98876953125],
              [94.6513671875, 16.06484375],
              [94.68076171875003, 16.13330078125],
              [94.67656249999999, 16.24204101562499],
              [94.71992187500001, 16.398730468750003],
              [94.71660156249999, 16.45249023437499],
              [94.70332031250001, 16.511914062499997],
              [94.67900390624999, 16.425585937500003],
              [94.66523437500001, 16.336132812499997],
              [94.6376953125, 16.30908203125],
              [94.5875, 16.288818359375],
              [94.49570312500003, 16.18613281249999],
              [94.44160156250001, 16.094384765624994],
              [94.29902343750001, 16.007617187500003],
              [94.22382812500001, 16.01645507812499],
              [94.21425781250002, 16.126611328124994],
              [94.27128906249999, 16.517285156249997],
              [94.32734375000001, 16.572167968749994],
              [94.35341796875002, 16.639941406250003],
              [94.4, 16.8681640625],
              [94.45244140624999, 16.954492187499994],
              [94.47314453125, 17.135449218749997],
              [94.49433593750001, 17.166552734375003],
              [94.564453125, 17.308544921874997],
              [94.58896484375003, 17.5693359375],
              [94.56005859375, 17.698974609375],
              [94.49433593750001, 17.824609375],
              [94.43076171875003, 18.20166015625],
              [94.26582031250001, 18.507226562499994],
              [94.25214843750001, 18.609179687500003],
              [94.17070312499999, 18.732421875],
              [94.24570312500003, 18.741162109374997],
              [94.09130859375, 18.84921875],
              [94.07001953125001, 18.89340820312499],
              [94.03896484375002, 19.146191406249997],
              [94.044921875, 19.287402343750003],
              [94.0224609375, 19.268798828125],
              [94.00156250000003, 19.181787109374994],
              [93.94101562500003, 19.14609375],
              [93.96806640624999, 18.995068359374997],
              [93.96132812500002, 18.958398437499994],
              [93.92919921875, 18.899658203125],
              [93.80009765624999, 18.960595703124994],
              [93.70546875000002, 19.026904296875003],
              [93.59814453125, 19.1884765625],
              [93.49306640625002, 19.36948242187499],
              [93.53056640624999, 19.39755859374999],
              [93.57861328125, 19.401171875],
              [93.72802734375, 19.266503906249994],
              [93.82490234375001, 19.238476562499997],
              [93.88613281250002, 19.271923828124997],
              [93.96201171875003, 19.329345703125],
              [93.99814453125003, 19.440869140624997],
              [93.96074218749999, 19.481689453125],
              [93.88789062500001, 19.50390625],
              [93.83955078125001, 19.534130859374997],
              [93.76992187500002, 19.609570312499997],
              [93.76103515624999, 19.648046875],
              [93.73955078124999, 19.697265625],
              [93.66875, 19.731982421875003],
              [93.61171875000002, 19.776074218749997],
              [93.65986328125001, 19.854150390624994],
              [93.70703125, 19.912158203125003],
              [93.58183593749999, 19.909570312499994],
              [93.43906250000003, 20.009423828124994],
              [93.40957031250002, 20.038330078125],
              [93.3623046875, 20.058300781249997],
              [93.25, 20.070117187500003],
              [93.15664062500002, 20.040771484375],
              [93.19902343749999, 19.898339843749994],
              [93.190625, 19.851220703124994],
              [93.12949218750003, 19.85800781249999],
              [93.001953125, 20.074853515624994],
              [93.04033203124999, 20.129785156249994],
              [93.09550781249999, 20.181347656249997],
              [93.068359375, 20.188671875],
              [93.01513671875, 20.185253906249997],
              [93.06679687500002, 20.377636718749997],
              [93.03535156250001, 20.406152343749994],
              [93.01875, 20.34604492187499],
              [92.99072265625, 20.287988281249994],
              [92.88212890624999, 20.152148437500003],
              [92.82832031250001, 20.17758789062499],
              [92.79121093750001, 20.21142578125],
              [92.84355468749999, 20.282617187499994],
              [92.87167968750003, 20.3017578125],
              [92.89111328125, 20.34033203125],
              [92.85068359375003, 20.41484375],
              [92.78691406249999, 20.469042968750003],
              [92.73564453124999, 20.562695312499997],
              [92.708984375, 20.56396484375],
              [92.73261718750001, 20.453369140625],
              [92.72285156250001, 20.295605468749997],
              [92.60800781250003, 20.469873046874994],
              [92.37832031250002, 20.717578125],
              [92.32412109375002, 20.79184570312499],
              [92.31191406250002, 20.864453125],
              [92.28623046875003, 20.931591796874997],
              [92.26845703125002, 21.0046875],
              [92.26445312499999, 21.061474609374997],
              [92.21474609375002, 21.112695312499994],
              [92.19199218750003, 21.20224609374999],
              [92.17958984375002, 21.293115234374994],
              [92.20820312500001, 21.357861328124997],
              [92.27968750000002, 21.42758789062499],
              [92.33056640625, 21.43979492187499],
              [92.37265625000003, 21.40903320312499],
              [92.471875, 21.362988281249997],
              [92.53916015625003, 21.31982421875],
              [92.56855468750001, 21.263330078124994],
              [92.59980468750001, 21.270166015624994],
              [92.63164062499999, 21.306201171875003],
              [92.62529296874999, 21.350732421874994],
              [92.59345703125001, 21.467333984375003],
              [92.58427734374999, 21.609033203124994],
              [92.58281249999999, 21.940332031249994],
              [92.57490234374995, 21.97807617187499],
              [92.63037109375, 22.011328125],
              [92.65263671874999, 22.049316406250114],
              [92.67470703125002, 22.106005859375003],
              [92.68896484375, 22.13095703124999],
              [92.72099609374999, 22.132421875],
              [92.77138671875002, 22.104785156250003],
              [92.85429687499999, 22.01015625],
              [92.90947265624999, 21.988916015624994],
              [92.96455078125001, 22.00375976562499],
              [93.02197265625, 22.145703125],
              [93.04296875, 22.183984375],
              [93.07060546874999, 22.209423828124997],
              [93.12148437500002, 22.20517578124999],
              [93.15117187499999, 22.23061523437508],
              [93.16240234374999, 22.291894531249994],
              [93.16201171875002, 22.360205078125034],
              [93.10507812500003, 22.547119140625],
              [93.08818359374999, 22.633251953124997],
              [93.07871093750003, 22.718212890624997],
              [93.1142578125, 22.805712890625003],
              [93.1625, 22.907958984375],
              [93.15097656250003, 22.997314453125],
              [93.16416015625003, 23.03203125],
              [93.20390624999999, 23.037011718749994],
              [93.25351562500003, 23.01547851562499],
              [93.30800781250002, 23.03037109374999],
              [93.34941406249999, 23.0849609375],
              [93.36601562499999, 23.132519531249997],
              [93.39130859375001, 23.339160156250003],
              [93.40810546875002, 23.528027343749997],
              [93.41494140625002, 23.682080078124997],
              [93.37255859375, 23.774169921875],
              [93.30732421874995, 24.021875000000136],
              [93.32626953125003, 24.064208984375],
              [93.35556640625003, 24.074121093749994],
              [93.4521484375, 23.987402343750034],
              [93.49375, 23.972851562499997],
              [93.56406250000003, 23.986083984374943],
              [93.63330078125, 24.00537109375],
              [93.68339843749999, 24.006542968749997],
              [93.755859375, 23.97690429687499],
              [93.85546875, 23.943896484375003],
              [94.01083984375003, 23.90292968749999],
              [94.07480468750003, 23.8720703125],
              [94.12763671875001, 23.87646484375],
              [94.17031250000002, 23.97265625],
              [94.2197265625, 24.113183593749994],
              [94.29306640625003, 24.321875],
              [94.37724609374999, 24.473730468749977],
              [94.3994140625, 24.5140625],
              [94.4931640625, 24.637646484374997],
              [94.58408203125003, 24.767236328125136],
              [94.66328125000001, 24.93100585937499],
              [94.70761718750003, 25.048730468749994],
              [94.70371093750003, 25.097851562499997],
              [94.67529296874994, 25.13857421875008],
              [94.615625, 25.164599609375003],
              [94.56650390624998, 25.191503906250034],
              [94.55302734374999, 25.215722656249994],
              [94.55439453125001, 25.24345703124999],
              [94.57988281249999, 25.31982421875],
              [94.62285156249999, 25.41000976562499],
              [94.66777343749999, 25.458886718749994],
              [94.78583984375001, 25.519335937500003],
              [94.86113281249999, 25.597216796875102],
              [94.94570312499997, 25.700244140624932],
              [94.99199218749999, 25.770458984374997],
              [95.01523437500003, 25.91293945312499],
              [95.04072265624995, 25.941308593750023],
              [95.09296875000001, 25.9873046875],
              [95.13242187500003, 26.041259765625],
              [95.12929687500002, 26.07041015625],
              [95.1083984375, 26.09140625],
              [95.06894531250003, 26.191113281249997],
              [95.05087890625003, 26.347265625],
              [95.05976562500001, 26.473974609375],
              [95.08945312500003, 26.52548828125],
              [95.12871093749999, 26.59726562500009],
              [95.20146484374999, 26.64140625],
              [95.30507812500002, 26.67226562500008],
              [95.4638671875, 26.756054687499997],
              [95.73837890624998, 26.950439453125114],
              [95.83730468750002, 27.013818359374994],
              [95.9052734375, 27.046630859375],
              [95.97089843750001, 27.128076171874994],
              [96.06142578125002, 27.217089843749996],
              [96.19082031249997, 27.26127929687499],
              [96.27421874999999, 27.278369140624996],
              [96.66572265625001, 27.339257812499994],
              [96.73164062500001, 27.331494140625068],
              [96.7978515625, 27.29619140624999],
              [96.88027343750002, 27.17783203125],
              [96.95341796874999, 27.13330078125],
              [97.0380859375, 27.10205078125],
              [97.10205078125, 27.115429687500125],
              [97.10371093749995, 27.163330078125114],
              [96.90195312500003, 27.439599609374994],
              [96.88359374999999, 27.51484375],
              [96.87685546875002, 27.58671875],
              [96.89970703124999, 27.643847656249996],
              [96.96279296875002, 27.698291015625045],
              [97.04970703124997, 27.760009765625],
              [97.15781250000003, 27.836865234374997],
              [97.22607421875, 27.890039062499994],
              [97.30615234375, 27.907080078125],
              [97.33515625000001, 27.937744140625],
              [97.34355468749999, 27.982324218749966],
              [97.33916015624999, 28.03085937500009],
              [97.302734375, 28.08598632812499],
              [97.31025390625001, 28.155224609374997],
              [97.32246093750001, 28.21796875],
              [97.3564453125, 28.2544921875],
              [97.43144531249999, 28.35390625],
              [97.47773437500001, 28.425634765625],
              [97.50214843750001, 28.456347656249996],
              [97.53789062499999, 28.510205078124997],
              [97.59921875000003, 28.517041015624997],
              [97.65888671875001, 28.5],
              [97.69462890624999, 28.4693359375],
              [97.73007812500003, 28.407128906249994],
              [97.76904296875, 28.356152343749997],
              [97.81650390625003, 28.356347656249994],
              [97.86494140625001, 28.363574218749996],
              [97.88759765625002, 28.356494140624996],
              [97.93408203125, 28.313818359375],
              [98.02226562499999, 28.2115234375],
              [98.06162109375003, 28.185888671875],
              [98.09892578124999, 28.142285156249997],
              [98.11835937500001, 28.055224609374996],
              [98.13046875000003, 27.967578125],
              [98.24101562499999, 27.66318359375],
              [98.27421874999999, 27.599072265624997],
              [98.298828125, 27.550097656249996],
              [98.35048828125002, 27.5380859375],
              [98.39238281249999, 27.587060546874994],
              [98.40888671875001, 27.639453125],
              [98.45253906250002, 27.6572265625],
              [98.50449218750003, 27.64765625],
              [98.59980468750001, 27.598828125],
              [98.65117187499999, 27.572460937499997],
              [98.6767578125, 27.421923828124996],
              [98.68242187499999, 27.2453125],
              [98.6748046875, 27.190625],
              [98.71650390625001, 27.044921875],
              [98.7294921875, 26.877392578124997],
              [98.73847656250001, 26.7857421875],
              [98.73935546875003, 26.698144531249994],
              [98.73183593750002, 26.583398437499994],
              [98.70947265625, 26.4296875],
              [98.671875, 26.298535156249997],
              [98.685546875, 26.189355468749994],
              [98.66318359375003, 26.139453125],
              [98.57197265625001, 26.1140625],
              [98.56406250000003, 26.072412109374994],
              [98.59101562500001, 26.003710937499996],
              [98.65468750000002, 25.917773437500003],
              [98.65625, 25.863574218750003],
              [98.62539062500002, 25.826708984375003],
              [98.55839843749999, 25.8232421875],
              [98.46552734375001, 25.788867187500003],
              [98.40166015624999, 25.677978515625],
              [98.33378906249999, 25.58676757812499],
              [98.29658203125001, 25.56884765625],
              [98.17255859375001, 25.59453125],
              [98.14287109374999, 25.57109375],
              [98.099609375, 25.415722656249997],
              [98.06406250000003, 25.34897460937499],
              [98.0107421875, 25.292529296875003],
              [97.96201171875003, 25.259326171875003],
              [97.91796875, 25.236132812500003],
              [97.81953125000001, 25.251855468749994],
              [97.76738281249999, 25.15805664062499],
              [97.71494140625003, 25.034326171874994],
              [97.71074218749999, 24.970361328124994],
              [97.73789062500003, 24.869873046875],
              [97.72382812500001, 24.841992187499997],
              [97.67070312499999, 24.820117187500003],
              [97.58330078124999, 24.774804687499994],
              [97.52939453125003, 24.63120117187499],
              [97.53144531250001, 24.49169921875],
              [97.56328124999999, 24.44384765625],
              [97.62363281250003, 24.42294921874999],
              [97.66660156250003, 24.37998046874999],
              [97.67070312499999, 24.312744140625],
              [97.70820312500001, 24.228759765625],
              [97.690625, 24.130810546874997],
              [97.56826171875002, 23.988476562499997],
              [97.56455078125003, 23.911035156249994],
              [97.62968749999999, 23.887158203124997],
              [97.68603515625, 23.898095703124994],
              [97.75566406249999, 23.931884765625],
              [97.83769531249999, 23.986279296874997],
              [98.01689453124999, 24.0654296875],
              [98.2125, 24.110644531250003],
              [98.36728515625003, 24.119042968749994],
              [98.49941406250002, 24.115673828124997],
              [98.56416015625001, 24.098828125],
              [98.58339843750002, 24.06982421875],
              [98.76435546875001, 24.11606445312499],
              [98.80234375000003, 24.118701171875003],
              [98.83505859375003, 24.12119140624999],
              [98.833984375, 24.090576171875],
              [98.70156250000002, 23.9640625],
              [98.6767578125, 23.905078125],
              [98.68085937500001, 23.841796875],
              [98.73505859375001, 23.783105468749994],
              [98.78769531250003, 23.737841796875003],
              [98.83222656250001, 23.62436523437499],
              [98.7978515625, 23.520410156249994],
              [98.81972656250002, 23.48251953124999],
              [98.85888671875, 23.440087890624994],
              [98.88261718749999, 23.380322265624997],
              [98.88554687499999, 23.30747070312499],
              [98.86376953125, 23.19125976562499],
              [99.05507812500002, 23.130566406249997],
              [99.22031250000003, 23.103320312500003],
              [99.3408203125, 23.095898437499997],
              [99.41806640625003, 23.069238281249994],
              [99.46455078125001, 23.04624023437499],
              [99.49726562500001, 23.004589843749997],
              [99.50712890624999, 22.959130859374994],
              [99.466796875, 22.927294921875003],
              [99.38515625000002, 22.825097656249994],
              [99.33828125000002, 22.688671875],
              [99.34316406250002, 22.58652343749999],
              [99.33769531249999, 22.498046875],
              [99.24306640625002, 22.370361328125],
              [99.20537109374999, 22.28256835937499],
              [99.17236328125, 22.19248046874999],
              [99.17343750000003, 22.1533203125],
              [99.19296875000003, 22.1259765625],
              [99.2333984375, 22.11015625],
              [99.303125, 22.100634765625003],
              [99.388671875, 22.110791015624997],
              [99.59267578125002, 22.089160156250003],
              [99.82539062500001, 22.049707031249994],
              [99.91767578125001, 22.028027343749997],
              [99.94785156250003, 21.988330078125003],
              [99.9404296875, 21.901611328125],
              [99.92558593749999, 21.82080078125],
              [99.94072265624999, 21.758740234374997],
              [99.97822265625001, 21.701611328124997],
              [100.04121093750001, 21.682763671874994],
              [100.09550781249999, 21.66064453125],
              [100.10576171874999, 21.61704101562499],
              [100.08925781250002, 21.557910156250003],
              [100.11679687500003, 21.511181640624997],
              [100.14765625000001, 21.480517578125003],
              [100.21474609375002, 21.46298828124999],
              [100.3505859375, 21.501025390625003],
              [100.44570312500002, 21.484082031249997],
              [100.53134765625003, 21.45810546874999],
              [100.60458984375003, 21.47177734374999],
              [100.67714843750002, 21.504931640625003],
              [100.83515625000001, 21.655175781249994],
              [101.01933593749999, 21.736376953125003],
              [101.07978515625001, 21.755859375],
              [101.12070312500003, 21.74609375],
              [101.130859375, 21.735546875],
              [101.128125, 21.705126953125003],
              [101.14726562499999, 21.581640625],
              [101.13886718750001, 21.56748046874999],
              [101.08037109374999, 21.468652343749994],
              [100.92753906249999, 21.3662109375],
              [100.81953125000001, 21.314208984375],
              [100.75664062499999, 21.312646484374994],
              [100.703125, 21.251367187499994],
              [100.6591796875, 21.13037109375],
              [100.61367187500002, 21.059326171875],
              [100.56660156250001, 21.03818359374999],
              [100.5361328125, 20.992382812499997],
              [100.52226562499999, 20.921923828125003],
              [100.54931640625, 20.884228515624997],
              [100.61767578125, 20.87924804687499],
              [100.62294921875002, 20.859570312499997],
              [100.56513671875001, 20.825097656249994],
              [100.49335937500001, 20.81298828125],
              [100.40742187499995, 20.823242187500057],
              [100.32607421875002, 20.795703125],
              [100.24931640624999, 20.730273437500003],
              [100.18388671874999, 20.589111328125],
              [100.12968749999999, 20.372216796874994],
              [100.12246093750002, 20.316650390625],
            ],
          ],
          [
            [
              [98.20976562499999, 10.952734375],
              [98.29345703125, 10.7796875],
              [98.284375, 10.753125],
              [98.271484375, 10.739892578124994],
              [98.25175781249999, 10.744433593749989],
              [98.21816406250002, 10.837744140624991],
              [98.15537109375003, 10.89794921875],
              [98.08046875000002, 10.886621093749994],
              [98.142578125, 10.963134765625],
              [98.16728515624999, 10.980322265624991],
              [98.20976562499999, 10.952734375],
            ],
          ],
          [
            [
              [98.1826171875, 9.933447265624991],
              [98.134375, 9.875390625],
              [98.11806640625002, 9.877880859374997],
              [98.14023437500003, 9.974658203124989],
              [98.220703125, 10.045214843749989],
              [98.29169921875001, 10.051318359374989],
              [98.28339843750001, 10.007617187499989],
              [98.23125, 9.953955078124991],
              [98.1826171875, 9.933447265624991],
            ],
          ],
          [
            [
              [98.2216796875, 11.478222656249997],
              [98.21621093750002, 11.455761718749997],
              [98.209375, 11.45654296875],
              [98.18730468749999, 11.472412109375],
              [98.20107421875002, 11.567187499999989],
              [98.23906249999999, 11.644726562499997],
              [98.278125, 11.758398437499991],
              [98.29960937499999, 11.783007812499989],
              [98.30751953125002, 11.722900390625],
              [98.28378906250003, 11.594091796874991],
              [98.26328125000003, 11.523632812499997],
              [98.2216796875, 11.478222656249997],
            ],
          ],
          [
            [
              [98.51601562500002, 11.905029296875],
              [98.47431640625001, 11.8994140625],
              [98.45449218750002, 12.061279296875],
              [98.46621093750002, 12.084277343749989],
              [98.52529296875002, 12.005175781249989],
              [98.60957031250001, 11.956640625],
              [98.57646484374999, 11.925097656249989],
              [98.51601562500002, 11.905029296875],
            ],
          ],
          [
            [
              [98.55380859375003, 11.744873046875],
              [98.52841796875003, 11.538671875],
              [98.46484375, 11.567187499999989],
              [98.43476562500001, 11.567089843749997],
              [98.396875, 11.683544921874997],
              [98.39951171875003, 11.71484375],
              [98.37646484375, 11.79150390625],
              [98.52353515625003, 11.804931640625],
              [98.55380859375003, 11.744873046875],
            ],
          ],
          [
            [
              [98.41396484375002, 12.597949218749989],
              [98.43642578125002, 12.570507812499997],
              [98.46826171875, 12.571337890624989],
              [98.45947265625, 12.473730468749991],
              [98.380859375, 12.353662109374994],
              [98.33447265625, 12.336181640625],
              [98.31386718750002, 12.335986328124989],
              [98.33144531250002, 12.511425781249997],
              [98.30253906249999, 12.611572265625],
              [98.31210937500003, 12.678173828124997],
              [98.396484375, 12.647119140624994],
              [98.41396484375002, 12.597949218749989],
            ],
          ],
          [
            [
              [98.13671875, 12.150439453124989],
              [98.12509765625003, 12.144873046874991],
              [98.10849609375003, 12.148095703124994],
              [98.07539062500001, 12.164453125],
              [98.03730468750001, 12.232470703124989],
              [98.05732421875001, 12.280078124999989],
              [98.07138671875003, 12.291796874999989],
              [98.10488281250002, 12.287792968749997],
              [98.12246093750002, 12.278710937499994],
              [98.12841796875, 12.26123046875],
              [98.11845703124999, 12.223388671875],
              [98.1201171875, 12.191308593749994],
              [98.13671875, 12.150439453124989],
            ],
          ],
          [
            [
              [94.47675781250001, 15.945947265624994],
              [94.41191406249999, 15.848388671875],
              [94.38789062500001, 15.994140625],
              [94.49375, 16.075341796874994],
              [94.54599609375003, 16.15283203125],
              [94.60126953125001, 16.205517578124997],
              [94.61865234375, 16.141308593749997],
              [94.56611328125001, 16.019287109375],
              [94.47675781250001, 15.945947265624994],
            ],
          ],
          [
            [
              [97.575, 16.253222656250003],
              [97.53720703125003, 16.240136718749994],
              [97.48037109375002, 16.305712890625003],
              [97.46914062500002, 16.46103515624999],
              [97.51640624999999, 16.496875],
              [97.5419921875, 16.505078125],
              [97.57900390625002, 16.486035156249997],
              [97.59326171875, 16.46079101562499],
              [97.599609375, 16.42954101562499],
              [97.58935546875, 16.397363281249994],
              [97.575, 16.253222656250003],
            ],
          ],
          [
            [
              [93.69082031250002, 18.684277343749997],
              [93.67402343750001, 18.675683593749994],
              [93.56992187500003, 18.759570312500003],
              [93.4875, 18.86752929687499],
              [93.61826171875003, 18.888818359374994],
              [93.74472656250003, 18.865527343750003],
              [93.74550781250002, 18.808056640624997],
              [93.71835937500003, 18.715722656249994],
              [93.69082031250002, 18.684277343749997],
            ],
          ],
          [
            [
              [93.49179687500003, 19.892578125],
              [93.51328125000003, 19.754785156249994],
              [93.44462890624999, 19.806445312500003],
              [93.41953125000003, 19.877587890624994],
              [93.41289062499999, 19.950341796874994],
              [93.49179687500003, 19.892578125],
            ],
          ],
          [
            [
              [93.71484375, 19.558251953124994],
              [93.82949218750002, 19.475292968749997],
              [93.87470703125001, 19.48105468749999],
              [93.94570312500002, 19.428613281249994],
              [93.94746093750001, 19.408154296874997],
              [93.93398437500002, 19.365429687499997],
              [93.90195312500003, 19.33203125],
              [93.81523437499999, 19.29868164062499],
              [93.755859375, 19.32568359375],
              [93.73232421875002, 19.416308593750003],
              [93.66220703125003, 19.458935546874997],
              [93.64404296875, 19.495068359374997],
              [93.68837890625002, 19.54443359375],
              [93.71484375, 19.558251953124994],
            ],
          ],
          [
            [
              [98.54169921875001, 10.961523437499991],
              [98.51894531250002, 10.959375],
              [98.498046875, 10.964257812499994],
              [98.47744140625002, 10.979736328125],
              [98.52656250000001, 11.086962890624989],
              [98.54169921875001, 10.961523437499991],
            ],
          ],
          [
            [
              [98.07548828124999, 11.6923828125],
              [98.08359375000003, 11.636816406249991],
              [98.02109375000003, 11.695898437499991],
              [98.01035156250003, 11.860253906249994],
              [98.0595703125, 11.756689453124991],
              [98.08076171875001, 11.733203124999989],
              [98.07548828124999, 11.6923828125],
            ],
          ],
          [
            [
              [98.06611328125001, 12.389794921874994],
              [98.06035156249999, 12.353515625],
              [98.00234375000002, 12.279003906249997],
              [97.95175781250003, 12.322314453124989],
              [97.93867187500001, 12.34609375],
              [97.990234375, 12.393798828125],
              [98.04511718750001, 12.387011718749989],
              [98.05986328124999, 12.397851562499994],
              [98.06611328125001, 12.389794921874994],
            ],
          ],
          [
            [
              [98.3154296875, 13.099072265624997],
              [98.30917968750003, 12.934716796874994],
              [98.25927734375, 13.014013671874991],
              [98.25078124999999, 13.104394531249994],
              [98.25458984375001, 13.188574218749991],
              [98.26533203125001, 13.202246093749991],
              [98.2685546875, 13.189355468749994],
              [98.29863281249999, 13.151660156249989],
              [98.3154296875, 13.099072265624997],
            ],
          ],
          [
            [
              [94.80488281250001, 15.8193359375],
              [94.784375, 15.793847656249994],
              [94.74335937500001, 15.812109375],
              [94.73349609375003, 15.823046875],
              [94.82802734375002, 15.933007812499994],
              [94.83818359374999, 15.89208984375],
              [94.80488281250001, 15.8193359375],
            ],
          ],
          [
            [
              [93.01015625000002, 19.92392578124999],
              [93.02324218749999, 19.828857421875],
              [92.97519531250003, 19.86801757812499],
              [92.91269531250003, 19.999804687500003],
              [92.91464843750003, 20.086474609375003],
              [92.95957031250003, 20.046191406250003],
              [93.01015625000002, 19.92392578124999],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 4,
        sovereignt: "Jordan",
        sov_a3: "JOR",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Jordan",
        adm0_a3: "JOR",
        geou_dif: 0,
        geounit: "Jordan",
        gu_a3: "JOR",
        su_dif: 0,
        subunit: "Jordan",
        su_a3: "JOR",
        brk_diff: 0,
        name: "Jordan",
        name_long: "Jordan",
        brk_a3: "JOR",
        brk_name: "Jordan",
        brk_group: null,
        abbrev: "Jord.",
        postal: "J",
        formal_en: "Hashemite Kingdom of Jordan",
        formal_fr: null,
        name_ciawf: "Jordan",
        note_adm0: null,
        note_brk: null,
        name_sort: "Jordan",
        name_alt: null,
        mapcolor7: 5,
        mapcolor8: 3,
        mapcolor9: 4,
        mapcolor13: 4,
        pop_est: 10101694,
        pop_rank: 14,
        pop_year: 2019,
        gdp_md: 44502,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        fips_10: "JO",
        iso_a2: "JO",
        iso_a2_eh: "JO",
        iso_a3: "JOR",
        iso_a3_eh: "JOR",
        iso_n3: "400",
        iso_n3_eh: "400",
        un_a3: "400",
        wb_a2: "JO",
        wb_a3: "JOR",
        woe_id: 23424860,
        woe_id_eh: 23424860,
        woe_note: "Exact WOE match as country",
        adm0_iso: "JOR",
        adm0_diff: null,
        adm0_tlc: "JOR",
        adm0_a3_us: "JOR",
        adm0_a3_fr: "JOR",
        adm0_a3_ru: "JOR",
        adm0_a3_es: "JOR",
        adm0_a3_cn: "JOR",
        adm0_a3_tw: "JOR",
        adm0_a3_in: "JOR",
        adm0_a3_np: "JOR",
        adm0_a3_pk: "JOR",
        adm0_a3_de: "JOR",
        adm0_a3_gb: "JOR",
        adm0_a3_br: "JOR",
        adm0_a3_il: "JOR",
        adm0_a3_ps: "JOR",
        adm0_a3_sa: "JOR",
        adm0_a3_eg: "JOR",
        adm0_a3_ma: "JOR",
        adm0_a3_pt: "JOR",
        adm0_a3_ar: "JOR",
        adm0_a3_jp: "JOR",
        adm0_a3_ko: "JOR",
        adm0_a3_vn: "JOR",
        adm0_a3_tr: "JOR",
        adm0_a3_id: "JOR",
        adm0_a3_pl: "JOR",
        adm0_a3_gr: "JOR",
        adm0_a3_it: "JOR",
        adm0_a3_nl: "JOR",
        adm0_a3_se: "JOR",
        adm0_a3_bd: "JOR",
        adm0_a3_ua: "JOR",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Western Asia",
        region_wb: "Middle East & North Africa",
        name_len: 6,
        long_len: 6,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: 36.375991,
        label_y: 30.805025,
        ne_id: 1159320935,
        wikidataid: "Q810",
        name_ar: "الأردن",
        name_bn: "জর্ডান",
        name_de: "Jordanien",
        name_en: "Jordan",
        name_es: "Jordania",
        name_fa: "اردن",
        name_fr: "Jordanie",
        name_el: "Ιορδανία",
        name_he: "ירדן",
        name_hi: "जॉर्डन",
        name_hu: "Jordánia",
        name_id: "Yordania",
        name_it: "Giordania",
        name_ja: "ヨルダン",
        name_ko: "요르단",
        name_nl: "Jordanië",
        name_pl: "Jordania",
        name_pt: "Jordânia",
        name_ru: "Иордания",
        name_sv: "Jordanien",
        name_tr: "Ürdün",
        name_uk: "Йорданія",
        name_ur: "اردن",
        name_vi: "Jordan",
        name_zh: "约旦",
        name_zht: "約旦",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "JOR.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [35.78730468750001, 32.734912109374996],
            [35.89472656250001, 32.713769531249994],
            [35.95644531250002, 32.66669921875],
            [36.05947265625002, 32.5337890625],
            [36.2197265625, 32.4951171875],
            [36.284277343750006, 32.457470703125],
            [36.3720703125, 32.3869140625],
            [36.47919921875001, 32.361328125],
            [36.818359375, 32.317285156249994],
            [37.088964843750006, 32.46552734375],
            [37.31757812500001, 32.590771484375],
            [37.57744140625002, 32.733056640624994],
            [37.75410156250001, 32.829833984375],
            [38.055761718750006, 32.994873046875],
            [38.25429687500002, 33.09921875],
            [38.515625, 33.236621093749996],
            [38.773535156250006, 33.372216796874994],
            [38.84501953125002, 33.15087890625],
            [38.91484375000002, 32.93466796875],
            [38.987402343750006, 32.710693359375],
            [39.05781250000001, 32.4931640625],
            [38.98164062500001, 32.472558593749994],
            [39.04140625000002, 32.3056640625],
            [39.14003906250002, 32.331201171874994],
            [39.24746093750002, 32.350976562499994],
            [39.29277343750002, 32.24384765625],
            [39.14541015625002, 32.12451171875],
            [38.9970703125, 32.007470703124994],
            [38.96230468750002, 31.994921875],
            [38.769628906250006, 31.946484375],
            [38.37548828125, 31.847460937499996],
            [38.111425781250006, 31.781152343749994],
            [37.77382812500002, 31.696337890624996],
            [37.49335937500001, 31.625878906249994],
            [37.215625, 31.556103515624997],
            [36.95859375, 31.491503906249996],
            [37.10527343750002, 31.355175781249997],
            [37.32949218750002, 31.146826171875],
            [37.47900390625, 31.007763671874997],
            [37.65546875000001, 30.828955078125],
            [37.81298828125, 30.669287109375],
            [37.980078125, 30.5],
            [37.862890625, 30.442626953125],
            [37.66972656250002, 30.34814453125],
            [37.64990234375, 30.330957031249994],
            [37.63359375000002, 30.31328125],
            [37.55361328125002, 30.144580078124996],
            [37.49072265625, 30.01171875],
            [37.46923828125, 29.995068359374997],
            [37.19941406250001, 29.9462890625],
            [36.92705078125002, 29.8970703125],
            [36.75527343750002, 29.866015625],
            [36.70390625000002, 29.831640625],
            [36.591796875, 29.66611328125],
            [36.47607421875, 29.4951171875],
            [36.2828125, 29.355371093749994],
            [36.068457031250006, 29.200537109375],
            [36.01542968750002, 29.190478515624996],
            [35.8603515625, 29.214257812499994],
            [35.5953125, 29.2548828125],
            [35.33916015625002, 29.294091796874994],
            [35.16376953125001, 29.320947265624994],
            [34.95078125, 29.353515625],
            [34.98222656250002, 29.48447265625],
            [34.97343750000002, 29.555029296875],
            [35.02392578125, 29.787060546874997],
            [35.05341796875001, 29.896923828124997],
            [35.06816406250002, 29.977880859375],
            [35.1416015625, 30.14169921875],
            [35.13261718750002, 30.1953125],
            [35.14814453125001, 30.384326171874996],
            [35.140625, 30.4208984375],
            [35.17402343750001, 30.52392578125],
            [35.23662109375002, 30.673486328124994],
            [35.2978515625, 30.80224609375],
            [35.32011718750002, 30.860205078125],
            [35.38300781250001, 30.982275390625],
            [35.43925781250002, 31.132421875],
            [35.40966796875, 31.214453125],
            [35.40068359375002, 31.230517578124996],
            [35.42353515625001, 31.324853515624994],
            [35.4228515625, 31.325390625],
            [35.45058593750002, 31.479296875],
            [35.465429687500006, 31.562353515625],
            [35.49941406250002, 31.67236328125],
            [35.55898437500002, 31.765527343749994],
            [35.53144531250001, 31.984912109374996],
            [35.534765625, 32.10302734375],
            [35.57207031250002, 32.237890625],
            [35.55146484375001, 32.3955078125],
            [35.56904296875001, 32.619873046875],
            [35.572851562500006, 32.640869140625],
            [35.59453125000002, 32.668017578124996],
            [35.61123046875002, 32.682080078125],
            [35.734472656250006, 32.72890625],
            [35.78730468750001, 32.734912109374996],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Nepal",
        sov_a3: "NPL",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Nepal",
        adm0_a3: "NPL",
        geou_dif: 0,
        geounit: "Nepal",
        gu_a3: "NPL",
        su_dif: 0,
        subunit: "Nepal",
        su_a3: "NPL",
        brk_diff: 0,
        name: "Nepal",
        name_long: "Nepal",
        brk_a3: "NPL",
        brk_name: "Nepal",
        brk_group: null,
        abbrev: "Nepal",
        postal: "NP",
        formal_en: "Nepal",
        formal_fr: null,
        name_ciawf: "Nepal",
        note_adm0: null,
        note_brk: null,
        name_sort: "Nepal",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 2,
        mapcolor9: 3,
        mapcolor13: 12,
        pop_est: 28608710,
        pop_rank: 15,
        pop_year: 2019,
        gdp_md: 30641,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        fips_10: "NP",
        iso_a2: "NP",
        iso_a2_eh: "NP",
        iso_a3: "NPL",
        iso_a3_eh: "NPL",
        iso_n3: "524",
        iso_n3_eh: "524",
        un_a3: "524",
        wb_a2: "NP",
        wb_a3: "NPL",
        woe_id: 23424911,
        woe_id_eh: 23424911,
        woe_note: "Exact WOE match as country",
        adm0_iso: "NPL",
        adm0_diff: null,
        adm0_tlc: "NPL",
        adm0_a3_us: "NPL",
        adm0_a3_fr: "NPL",
        adm0_a3_ru: "NPL",
        adm0_a3_es: "NPL",
        adm0_a3_cn: "NPL",
        adm0_a3_tw: "NPL",
        adm0_a3_in: "NPL",
        adm0_a3_np: "NPL",
        adm0_a3_pk: "NPL",
        adm0_a3_de: "NPL",
        adm0_a3_gb: "NPL",
        adm0_a3_br: "NPL",
        adm0_a3_il: "NPL",
        adm0_a3_ps: "NPL",
        adm0_a3_sa: "NPL",
        adm0_a3_eg: "NPL",
        adm0_a3_ma: "NPL",
        adm0_a3_pt: "NPL",
        adm0_a3_ar: "NPL",
        adm0_a3_jp: "NPL",
        adm0_a3_ko: "NPL",
        adm0_a3_vn: "NPL",
        adm0_a3_tr: "NPL",
        adm0_a3_id: "NPL",
        adm0_a3_pl: "NPL",
        adm0_a3_gr: "NPL",
        adm0_a3_it: "NPL",
        adm0_a3_nl: "NPL",
        adm0_a3_se: "NPL",
        adm0_a3_bd: "NPL",
        adm0_a3_ua: "NPL",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Southern Asia",
        region_wb: "South Asia",
        name_len: 5,
        long_len: 5,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: 83.639914,
        label_y: 28.297925,
        ne_id: 1159321121,
        wikidataid: "Q837",
        name_ar: "نيبال",
        name_bn: "নেপাল",
        name_de: "Nepal",
        name_en: "Nepal",
        name_es: "Nepal",
        name_fa: "نپال",
        name_fr: "Népal",
        name_el: "Νεπάλ",
        name_he: "נפאל",
        name_hi: "नेपाल",
        name_hu: "Nepál",
        name_id: "Nepal",
        name_it: "Nepal",
        name_ja: "ネパール",
        name_ko: "네팔",
        name_nl: "Nepal",
        name_pl: "Nepal",
        name_pt: "Nepal",
        name_ru: "Непал",
        name_sv: "Nepal",
        name_tr: "Nepal",
        name_uk: "Непал",
        name_ur: "نیپال",
        name_vi: "Nepal",
        name_zh: "尼泊尔",
        name_zht: "尼泊爾",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "NPL.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [88.10976562499997, 27.870605468749943],
            [88.15029296875002, 27.843310546874996],
            [88.154296875, 27.798681640625],
            [88.14697265625, 27.74921875],
            [88.10556640625003, 27.642431640625],
            [88.06787109375, 27.5673828125],
            [88.02412109375001, 27.408886718749997],
            [87.984375, 27.133935546874994],
            [87.9931640625, 27.086083984374994],
            [88.11103515625001, 26.928466796875],
            [88.1572265625, 26.807324218749997],
            [88.16152343750002, 26.724804687500125],
            [88.11152343750001, 26.58642578125],
            [88.05488281250001, 26.43002929687492],
            [88.02695312500003, 26.39501953125],
            [87.9951171875, 26.382373046874996],
            [87.84921875000003, 26.43691406250008],
            [87.74882812499999, 26.429296875],
            [87.63339843750003, 26.399121093749997],
            [87.51308593750002, 26.404980468749997],
            [87.41357421875, 26.42294921875],
            [87.28740234374993, 26.360302734375125],
            [87.16679687499999, 26.394238281249997],
            [87.08955078124995, 26.433203125000034],
            [87.03789062499999, 26.5416015625],
            [87.01640624999999, 26.555419921875057],
            [86.7625, 26.441943359375045],
            [86.70136718750001, 26.43505859375],
            [86.54365234375001, 26.495996093749994],
            [86.41445312500002, 26.556298828124994],
            [86.36611328125002, 26.574414062499997],
            [86.24160156250002, 26.597998046875],
            [86.12939453125, 26.61171875],
            [86.00732421875, 26.649365234374997],
            [85.85566406250001, 26.6001953125],
            [85.79453125000003, 26.604150390624994],
            [85.7373046875, 26.63974609375],
            [85.70742187500002, 26.712646484375],
            [85.69990234375001, 26.781640625],
            [85.6484375, 26.829003906249994],
            [85.56845703125003, 26.839843750000114],
            [85.45644531250002, 26.797216796875],
            [85.29296875, 26.741015625],
            [85.240234375, 26.750341796875],
            [85.19179687500002, 26.766552734374997],
            [85.17412109374999, 26.78154296874999],
            [85.15156250000001, 26.846630859374997],
            [85.12539062500002, 26.860986328124994],
            [85.08730468750002, 26.862939453124994],
            [85.02011718750003, 26.878515625],
            [84.93720703125001, 26.926904296874994],
            [84.68535156249999, 27.041015625],
            [84.65380859375, 27.091699218749994],
            [84.65478515625, 27.203662109374996],
            [84.64072265625003, 27.249853515624977],
            [84.61015624999999, 27.298681640625],
            [84.48085937500002, 27.348193359375102],
            [84.22978515624999, 27.427832031249977],
            [84.09101562499995, 27.491357421875136],
            [84.02480468749997, 27.461669921874943],
            [83.89716796875001, 27.435107421874996],
            [83.82880859375001, 27.377832031249994],
            [83.74697265625002, 27.395947265624997],
            [83.55166015625002, 27.456347656249996],
            [83.44716796875002, 27.46533203125],
            [83.38398437500001, 27.44482421875],
            [83.36943359374999, 27.41025390625],
            [83.28974609375001, 27.370996093749994],
            [83.2138671875, 27.402294921875125],
            [83.06406250000003, 27.44453125000001],
            [82.93281250000001, 27.467675781249994],
            [82.7333984375, 27.518994140624997],
            [82.71083984375002, 27.5966796875],
            [82.67734375000003, 27.6734375],
            [82.6298828125, 27.687060546874996],
            [82.45136718750001, 27.671826171874997],
            [82.28769531250003, 27.756542968749997],
            [82.11191406250003, 27.864941406249997],
            [82.03701171875002, 27.90058593750001],
            [81.98769531250002, 27.913769531249997],
            [81.94521484374997, 27.89926757812492],
            [81.896875, 27.874462890624997],
            [81.85263671875003, 27.867089843749994],
            [81.75722656250002, 27.913818359375],
            [81.63554687499993, 27.980468749999943],
            [81.48603515625001, 28.062207031249997],
            [81.31083984374999, 28.1763671875],
            [81.23896484375001, 28.24086914062508],
            [81.20625, 28.289404296875],
            [81.1689453125, 28.335009765624996],
            [81.0166015625, 28.409570312499966],
            [80.89609375000003, 28.468554687499996],
            [80.75078124999999, 28.539697265624994],
            [80.72617187500003, 28.55390625],
            [80.67128906250002, 28.596240234374996],
            [80.58701171875003, 28.649609375],
            [80.51787109374999, 28.665185546874994],
            [80.49580078125001, 28.635791015624996],
            [80.47910156250003, 28.604882812499994],
            [80.41855468749998, 28.61201171875001],
            [80.32480468750003, 28.66640625],
            [80.22656249999994, 28.723339843750125],
            [80.14960937500001, 28.776074218749997],
            [80.07070312500002, 28.830175781250034],
            [80.05166015625002, 28.8703125],
            [80.08457031249998, 28.994189453125102],
            [80.13046875000003, 29.100390625],
            [80.16953125000003, 29.124316406249996],
            [80.23300781250003, 29.194628906250045],
            [80.25595703125003, 29.318017578124994],
            [80.2548828125, 29.42333984375],
            [80.31689453125, 29.572070312499996],
            [80.40185546875, 29.730273437499996],
            [80.54902343750001, 29.899804687499994],
            [80.61289062500003, 29.955859375],
            [80.68408203125, 29.994335937499997],
            [80.81992187500003, 30.119335937499955],
            [80.84814453125, 30.13974609375],
            [80.90761718749997, 30.171923828125045],
            [80.96611328124999, 30.180029296875],
            [81.01025390625, 30.164501953124997],
            [81.05556640625002, 30.098974609375],
            [81.1103515625, 30.036816406249997],
            [81.17714843750002, 30.039892578125],
            [81.25507812500001, 30.093310546874996],
            [81.41718750000001, 30.337597656249997],
            [81.64189453124999, 30.3875],
            [81.85488281250002, 30.36240234375],
            [82.04335937500002, 30.3267578125],
            [82.09892578124999, 30.245068359374997],
            [82.13535156250003, 30.158984375],
            [82.15898437499999, 30.115185546874997],
            [82.220703125, 30.063867187499994],
            [82.48652343750001, 29.94150390625],
            [82.64082031250001, 29.831201171874994],
            [82.85429687499999, 29.683398437499996],
            [83.01396484374999, 29.618066406249994],
            [83.15546875000001, 29.612646484375],
            [83.23515624999999, 29.554589843749994],
            [83.35517578125001, 29.439160156249997],
            [83.45664062500003, 29.306347656249997],
            [83.58349609375, 29.18359375],
            [83.67109375000001, 29.18759765625],
            [83.79042968750002, 29.227441406249994],
            [83.93593750000002, 29.279492187499997],
            [84.02197265625, 29.253857421874997],
            [84.10136718749999, 29.219970703125],
            [84.12783203125002, 29.156298828124996],
            [84.17558593749999, 29.036376953125],
            [84.22871093750001, 28.911767578124994],
            [84.31210937500003, 28.868115234374997],
            [84.41074218750003, 28.80390625],
            [84.46542968750003, 28.7529296875],
            [84.65058593750001, 28.659570312499994],
            [84.6767578125, 28.621533203124997],
            [84.71425781250002, 28.595556640625],
            [84.759375, 28.579248046874994],
            [84.796875, 28.560205078124994],
            [84.85507812500003, 28.553613281249994],
            [85.06914062499999, 28.609667968749996],
            [85.12636718750002, 28.60263671875],
            [85.15908203125002, 28.592236328124997],
            [85.16015625, 28.571875],
            [85.12148437500002, 28.484277343749994],
            [85.08857421875001, 28.372265625],
            [85.12246093750002, 28.315966796874996],
            [85.21210937500001, 28.292626953124994],
            [85.41064453125, 28.276025390624994],
            [85.67832031250003, 28.27744140625],
            [85.75947265625001, 28.220654296874997],
            [85.84023437500002, 28.1353515625],
            [85.92167968749999, 27.989697265624997],
            [85.9541015625, 27.92822265625],
            [85.99453125000002, 27.910400390625],
            [86.06416015625001, 27.934716796874994],
            [86.07548828124999, 27.994580078124997],
            [86.07871093750003, 28.08359375],
            [86.13701171874999, 28.11435546875],
            [86.17421875000002, 28.091699218749994],
            [86.21796875000001, 28.0220703125],
            [86.32861328125, 27.959521484374996],
            [86.40869140625, 27.928662109374997],
            [86.48496093750003, 27.93955078125],
            [86.51689453124999, 27.963525390624994],
            [86.55449218749999, 28.085205078125],
            [86.61445312500001, 28.10302734375],
            [86.69052734375003, 28.094921875],
            [86.71962890625002, 28.070654296875],
            [86.75039062500002, 28.0220703125],
            [86.84238281250003, 27.999169921874994],
            [86.93378906250001, 27.968457031249997],
            [87.02011718750003, 27.928662109374997],
            [87.14140624999999, 27.838330078124997],
            [87.29072265625001, 27.821923828124994],
            [87.46416015624999, 27.823828125],
            [87.55527343750003, 27.821826171874996],
            [87.62255859375, 27.815185546875],
            [87.68271484375003, 27.82138671875],
            [87.86074218750002, 27.886083984375],
            [87.93339843749999, 27.890820312499997],
            [88.02333984375002, 27.8833984375],
            [88.10976562499997, 27.870605468749943],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 3,
        labelrank: 4,
        sovereignt: "China",
        sov_a3: "CH1",
        adm0_dif: 1,
        level: 2,
        type: "Country",
        tlc: "1",
        admin: "Hong Kong S.A.R.",
        adm0_a3: "HKG",
        geou_dif: 0,
        geounit: "Hong Kong S.A.R.",
        gu_a3: "HKG",
        su_dif: 0,
        subunit: "Hong Kong S.A.R.",
        su_a3: "HKG",
        brk_diff: 0,
        name: "Hong Kong",
        name_long: "Hong Kong",
        brk_a3: "HKG",
        brk_name: "Hong Kong",
        brk_group: null,
        abbrev: "H.K.",
        postal: "HK",
        formal_en: "Hong Kong Special Administrative Region, PRC",
        formal_fr: null,
        name_ciawf: "Hong Kong",
        note_adm0: "Cn.",
        note_brk: "China",
        name_sort: "Hong Kong SAR, China",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 4,
        mapcolor9: 4,
        mapcolor13: 3,
        pop_est: 7507400,
        pop_rank: 13,
        pop_year: 2019,
        gdp_md: 365711,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "2. High income: nonOECD",
        fips_10: "HK",
        iso_a2: "HK",
        iso_a2_eh: "HK",
        iso_a3: "HKG",
        iso_a3_eh: "HKG",
        iso_n3: "344",
        iso_n3_eh: "344",
        un_a3: "344",
        wb_a2: "HK",
        wb_a3: "HKG",
        woe_id: 24865698,
        woe_id_eh: 24865698,
        woe_note: "Exact WOE match as country",
        adm0_iso: "HKG",
        adm0_diff: null,
        adm0_tlc: "HKG",
        adm0_a3_us: "HKG",
        adm0_a3_fr: "HKG",
        adm0_a3_ru: "HKG",
        adm0_a3_es: "HKG",
        adm0_a3_cn: "HKG",
        adm0_a3_tw: "HKG",
        adm0_a3_in: "HKG",
        adm0_a3_np: "HKG",
        adm0_a3_pk: "HKG",
        adm0_a3_de: "HKG",
        adm0_a3_gb: "HKG",
        adm0_a3_br: "HKG",
        adm0_a3_il: "HKG",
        adm0_a3_ps: "HKG",
        adm0_a3_sa: "HKG",
        adm0_a3_eg: "HKG",
        adm0_a3_ma: "HKG",
        adm0_a3_pt: "HKG",
        adm0_a3_ar: "HKG",
        adm0_a3_jp: "HKG",
        adm0_a3_ko: "HKG",
        adm0_a3_vn: "HKG",
        adm0_a3_tr: "HKG",
        adm0_a3_id: "HKG",
        adm0_a3_pl: "HKG",
        adm0_a3_gr: "HKG",
        adm0_a3_it: "HKG",
        adm0_a3_nl: "HKG",
        adm0_a3_se: "HKG",
        adm0_a3_bd: "HKG",
        adm0_a3_ua: "HKG",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Eastern Asia",
        region_wb: "East Asia & Pacific",
        name_len: 9,
        long_len: 9,
        abbrev_len: 4,
        tiny: -99,
        homepart: -99,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: 114.097769,
        label_y: 22.448829,
        ne_id: 1159320473,
        wikidataid: "Q8646",
        name_ar: "هونغ كونغ",
        name_bn: "হংকং",
        name_de: "Hongkong",
        name_en: "Hong Kong",
        name_es: "Hong Kong",
        name_fa: "هنگ کنگ",
        name_fr: "Hong Kong",
        name_el: "Χονγκ Κονγκ",
        name_he: "הונג קונג",
        name_hi: "हांगकांग",
        name_hu: "Hongkong",
        name_id: "Hong Kong",
        name_it: "Hong Kong",
        name_ja: "香港",
        name_ko: "홍콩",
        name_nl: "Hongkong",
        name_pl: "Hongkong",
        name_pt: "Hong Kong",
        name_ru: "Гонконг",
        name_sv: "Hongkong",
        name_tr: "Hong Kong",
        name_uk: "Гонконг",
        name_ur: "ہانگ کانگ",
        name_vi: "Hồng Kông",
        name_zh: "香港",
        name_zht: "香港",
        fclass_iso: "Admin-0 dependency",
        tlc_diff: null,
        fclass_tlc: "Admin-0 dependency",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: "Admin-1 region",
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "HKG.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.01542968749999, 22.511914062499997],
              [114.01826171875001, 22.514453125],
              [114.05039062500003, 22.54296875],
              [114.09785156250001, 22.55126953125],
              [114.12285156249999, 22.564990234375003],
              [114.18818359375001, 22.564990234375003],
              [114.22822265625001, 22.553955078125],
              [114.26601562500002, 22.540966796874997],
              [114.26962890625003, 22.536767578124994],
              [114.29111328125003, 22.499462890624997],
              [114.28457031250002, 22.45761718749999],
              [114.3251953125, 22.437402343749994],
              [114.33525390624999, 22.396240234375],
              [114.29052734375, 22.373779296875],
              [114.28789062499999, 22.32529296874999],
              [114.26796875000002, 22.295556640624994],
              [114.13906250000002, 22.3484375],
              [114.03281250000003, 22.375878906249994],
              [113.93730468749999, 22.364990234375],
              [113.90253906250001, 22.39609375],
              [113.896484375, 22.428173828124997],
              [114.00673828125002, 22.484033203124994],
              [114.01542968749999, 22.511914062499997],
            ],
          ],
          [
            [
              [114.23203125000003, 22.210546875],
              [114.20722656250001, 22.19516601562499],
              [114.13876953125003, 22.268359375],
              [114.13447265625001, 22.292236328125],
              [114.18740234375002, 22.296630859375],
              [114.246875, 22.263574218749994],
              [114.24355468750002, 22.233544921874994],
              [114.23203125000003, 22.210546875],
            ],
          ],
          [
            [
              [113.99775390625001, 22.210498046875003],
              [113.87734375000002, 22.21044921875],
              [113.8515625, 22.220458984375],
              [113.8388671875, 22.24169921875],
              [113.88154296875001, 22.2802734375],
              [114.0439453125, 22.333398437499994],
              [114.00332031250002, 22.277539062499997],
              [113.99775390625001, 22.210498046875003],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 6,
        sovereignt: "Northern Cyprus",
        sov_a3: "CYN",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Northern Cyprus",
        adm0_a3: "CYN",
        geou_dif: 0,
        geounit: "Northern Cyprus",
        gu_a3: "CYN",
        su_dif: 0,
        subunit: "Northern Cyprus",
        su_a3: "CYN",
        brk_diff: 0,
        name: "N. Cyprus",
        name_long: "Northern Cyprus",
        brk_a3: "CYN",
        brk_name: "N. Cyprus",
        brk_group: null,
        abbrev: "N. Cy.",
        postal: "CN",
        formal_en: "Turkish Republic of Northern Cyprus",
        formal_fr: null,
        name_ciawf: null,
        note_adm0: "Self admin.",
        note_brk: "Self admin.; Claimed by Cyprus",
        name_sort: "Cyprus, Northern",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 1,
        mapcolor9: 4,
        mapcolor13: 8,
        pop_est: 326000,
        pop_rank: 10,
        pop_year: 2017,
        gdp_md: 3600,
        gdp_year: 2013,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        fips_10: "-99",
        iso_a2: "-99",
        iso_a2_eh: "-99",
        iso_a3: "-99",
        iso_a3_eh: "-99",
        iso_n3: "-99",
        iso_n3_eh: "-99",
        un_a3: "-099",
        wb_a2: "-99",
        wb_a3: "-99",
        woe_id: -90,
        woe_id_eh: 23424995,
        woe_note: "WOE lists as subunit of united Cyprus",
        adm0_iso: "CYP",
        adm0_diff: "1",
        adm0_tlc: "CYN",
        adm0_a3_us: "CYP",
        adm0_a3_fr: "CYP",
        adm0_a3_ru: "CYP",
        adm0_a3_es: "CYP",
        adm0_a3_cn: "CYP",
        adm0_a3_tw: "CYP",
        adm0_a3_in: "CYP",
        adm0_a3_np: "CYP",
        adm0_a3_pk: "CYP",
        adm0_a3_de: "CYP",
        adm0_a3_gb: "CYP",
        adm0_a3_br: "CYP",
        adm0_a3_il: "CYP",
        adm0_a3_ps: "CYP",
        adm0_a3_sa: "CYP",
        adm0_a3_eg: "CYP",
        adm0_a3_ma: "CYP",
        adm0_a3_pt: "CYP",
        adm0_a3_ar: "CYP",
        adm0_a3_jp: "CYP",
        adm0_a3_ko: "CYP",
        adm0_a3_vn: "CYP",
        adm0_a3_tr: "CYN",
        adm0_a3_id: "CYP",
        adm0_a3_pl: "CYP",
        adm0_a3_gr: "CYP",
        adm0_a3_it: "CYP",
        adm0_a3_nl: "CYP",
        adm0_a3_se: "CYP",
        adm0_a3_bd: "CYP",
        adm0_a3_ua: "CYP",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Western Asia",
        region_wb: "Europe & Central Asia",
        name_len: 9,
        long_len: 15,
        abbrev_len: 6,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 6,
        max_label: 10,
        label_x: 33.692434,
        label_y: 35.216071,
        ne_id: 1159320531,
        wikidataid: "Q23681",
        name_ar: "قبرص الشمالية",
        name_bn: "উত্তর সাইপ্রাস",
        name_de: "Türkische Republik Nordzypern",
        name_en: "Turkish Republic of Northern Cyprus",
        name_es: "República Turca del Norte de Chipre",
        name_fa: "جمهوری ترک قبرس شمالی",
        name_fr: "Chypre du Nord",
        name_el: "Τουρκική Δημοκρατία της Βόρειας Κύπρου",
        name_he: "הרפובליקה הטורקית של צפון קפריסין",
        name_hi: "उत्तरी साइप्रस",
        name_hu: "Észak-Ciprus",
        name_id: "Republik Turki Siprus Utara",
        name_it: "Cipro del Nord",
        name_ja: "北キプロス・トルコ共和国",
        name_ko: "북키프로스",
        name_nl: "Noord-Cyprus",
        name_pl: "Cypr Północny",
        name_pt: "República Turca do Chipre do Norte",
        name_ru: "Турецкая Республика Северного Кипра",
        name_sv: "Nordcypern",
        name_tr: "Kuzey Kıbrıs Türk Cumhuriyeti",
        name_uk: "Турецька Республіка Північного Кіпру",
        name_ur: "ترک جمہوریہ شمالی قبرص",
        name_vi: "Bắc Síp",
        name_zh: "北塞浦路斯土耳其共和国",
        name_zht: "北賽普勒斯土耳其共和國",
        fclass_iso: "Unrecognized",
        tlc_diff: "1",
        fclass_tlc: "Admin-0 country",
        fclass_us: "Admin-0 breakaway and disputed",
        fclass_fr: "Unrecognized",
        fclass_ru: "Unrecognized",
        fclass_es: "Unrecognized",
        fclass_cn: "Unrecognized",
        fclass_tw: "Unrecognized",
        fclass_in: "Unrecognized",
        fclass_np: "Unrecognized",
        fclass_pk: "Unrecognized",
        fclass_de: "Unrecognized",
        fclass_gb: "Unrecognized",
        fclass_br: "Unrecognized",
        fclass_il: "Unrecognized",
        fclass_ps: "Unrecognized",
        fclass_sa: "Unrecognized",
        fclass_eg: "Unrecognized",
        fclass_ma: "Unrecognized",
        fclass_pt: "Unrecognized",
        fclass_ar: "Unrecognized",
        fclass_jp: "Unrecognized",
        fclass_ko: "Unrecognized",
        fclass_vn: "Unrecognized",
        fclass_tr: "Admin-0 country",
        fclass_id: "Unrecognized",
        fclass_pl: "Unrecognized",
        fclass_gr: "Unrecognized",
        fclass_it: "Unrecognized",
        fclass_nl: "Unrecognized",
        fclass_se: "Unrecognized",
        fclass_bd: "Unrecognized",
        fclass_ua: "Unrecognized",
        filename: "CYN.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [34.004492187500006, 35.065234375],
            [33.96572265625002, 35.056787109374994],
            [33.9033203125, 35.08544921875],
            [33.86640625000001, 35.093603515625],
            [33.83203125, 35.0671875],
            [33.79228515625002, 35.048193359375],
            [33.756933593750006, 35.03974609375],
            [33.72578125000001, 35.0373046875],
            [33.675390625, 35.017871093749996],
            [33.61445312500001, 35.022753906249996],
            [33.52568359375002, 35.038671875],
            [33.47578125000001, 35.000341796875],
            [33.4638671875, 35.004931640624996],
            [33.45595703125002, 35.101416015625],
            [33.42421875000002, 35.140917968749996],
            [33.3837890625, 35.1626953125],
            [33.32558593750002, 35.153613281249996],
            [33.24833984375002, 35.15693359375],
            [33.191015625, 35.173144531249996],
            [33.07753906250002, 35.14619140625],
            [32.9859375, 35.11640625],
            [32.91953125, 35.087841796875],
            [32.86943359375002, 35.089404296874996],
            [32.78408203125002, 35.115771484374996],
            [32.72021484375, 35.145361328125],
            [32.71269531250002, 35.171044921874994],
            [32.77236328125002, 35.159570312499994],
            [32.8798828125, 35.180566406249994],
            [32.926367187500006, 35.278076171875],
            [32.94160156250001, 35.390429687499996],
            [33.12343750000002, 35.358203125],
            [33.30781250000001, 35.34150390625],
            [33.45878906250002, 35.335888671875],
            [33.60761718750001, 35.354150390624994],
            [34.0634765625, 35.473974609375],
            [34.192480468750006, 35.545703125],
            [34.27236328125002, 35.569970703124994],
            [34.4111328125, 35.629296875],
            [34.55605468750002, 35.662060546875],
            [34.46318359375002, 35.593505859375],
            [33.941992187500006, 35.292041015624996],
            [33.90791015625001, 35.202392578125],
            [33.93125, 35.140380859375],
            [34.004492187500006, 35.065234375],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Yemen",
        sov_a3: "YEM",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Yemen",
        adm0_a3: "YEM",
        geou_dif: 0,
        geounit: "Yemen",
        gu_a3: "YEM",
        su_dif: 0,
        subunit: "Yemen",
        su_a3: "YEM",
        brk_diff: 0,
        name: "Yemen",
        name_long: "Yemen",
        brk_a3: "YEM",
        brk_name: "Yemen",
        brk_group: null,
        abbrev: "Yem.",
        postal: "YE",
        formal_en: "Republic of Yemen",
        formal_fr: null,
        name_ciawf: "Yemen",
        note_adm0: null,
        note_brk: null,
        name_sort: "Yemen, Rep.",
        name_alt: null,
        mapcolor7: 5,
        mapcolor8: 3,
        mapcolor9: 3,
        mapcolor13: 11,
        pop_est: 29161922,
        pop_rank: 15,
        pop_year: 2019,
        gdp_md: 22581,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "4. Lower middle income",
        fips_10: "YM",
        iso_a2: "YE",
        iso_a2_eh: "YE",
        iso_a3: "YEM",
        iso_a3_eh: "YEM",
        iso_n3: "887",
        iso_n3_eh: "887",
        un_a3: "887",
        wb_a2: "RY",
        wb_a3: "YEM",
        woe_id: 23425002,
        woe_id_eh: 23425002,
        woe_note: "Exact WOE match as country",
        adm0_iso: "YEM",
        adm0_diff: null,
        adm0_tlc: "YEM",
        adm0_a3_us: "YEM",
        adm0_a3_fr: "YEM",
        adm0_a3_ru: "YEM",
        adm0_a3_es: "YEM",
        adm0_a3_cn: "YEM",
        adm0_a3_tw: "YEM",
        adm0_a3_in: "YEM",
        adm0_a3_np: "YEM",
        adm0_a3_pk: "YEM",
        adm0_a3_de: "YEM",
        adm0_a3_gb: "YEM",
        adm0_a3_br: "YEM",
        adm0_a3_il: "YEM",
        adm0_a3_ps: "YEM",
        adm0_a3_sa: "YEM",
        adm0_a3_eg: "YEM",
        adm0_a3_ma: "YEM",
        adm0_a3_pt: "YEM",
        adm0_a3_ar: "YEM",
        adm0_a3_jp: "YEM",
        adm0_a3_ko: "YEM",
        adm0_a3_vn: "YEM",
        adm0_a3_tr: "YEM",
        adm0_a3_id: "YEM",
        adm0_a3_pl: "YEM",
        adm0_a3_gr: "YEM",
        adm0_a3_it: "YEM",
        adm0_a3_nl: "YEM",
        adm0_a3_se: "YEM",
        adm0_a3_bd: "YEM",
        adm0_a3_ua: "YEM",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Western Asia",
        region_wb: "Middle East & North Africa",
        name_len: 5,
        long_len: 5,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: 45.874383,
        label_y: 15.328226,
        ne_id: 1159321425,
        wikidataid: "Q805",
        name_ar: "اليمن",
        name_bn: "ইয়েমেন",
        name_de: "Jemen",
        name_en: "Yemen",
        name_es: "Yemen",
        name_fa: "یمن",
        name_fr: "Yémen",
        name_el: "Υεμένη",
        name_he: "תימן",
        name_hi: "यमन",
        name_hu: "Jemen",
        name_id: "Yaman",
        name_it: "Yemen",
        name_ja: "イエメン",
        name_ko: "예멘",
        name_nl: "Jemen",
        name_pl: "Jemen",
        name_pt: "Iémen",
        name_ru: "Йемен",
        name_sv: "Jemen",
        name_tr: "Yemen",
        name_uk: "Ємен",
        name_ur: "یمن",
        name_vi: "Yemen",
        name_zh: "也门",
        name_zht: "葉門",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "YEM.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [53.08564453125001, 16.648388671874997],
              [52.58144531250002, 16.470361328124994],
              [52.44843750000001, 16.391259765624994],
              [52.327734375, 16.29355468749999],
              [52.2373046875, 16.17138671875],
              [52.17402343750001, 15.956835937500003],
              [52.22207031250002, 15.760595703124991],
              [52.21748046875001, 15.655517578125],
              [52.08730468750002, 15.5859375],
              [51.9658203125, 15.535693359375003],
              [51.83076171875001, 15.459277343750003],
              [51.748632812500006, 15.440136718749997],
              [51.68154296875002, 15.379101562499997],
              [51.60371093750001, 15.336816406249994],
              [51.32246093750001, 15.226269531249997],
              [51.01513671875, 15.140771484374994],
              [50.52705078125001, 15.038183593749991],
              [50.33857421875001, 14.927197265624997],
              [50.16689453125002, 14.851025390624997],
              [49.906347656250006, 14.828125],
              [49.54863281250002, 14.722412109375],
              [49.34990234375002, 14.637792968749991],
              [49.10292968750002, 14.500048828125003],
              [49.04804687500001, 14.456445312499994],
              [49.00468750000002, 14.355029296875003],
              [48.9287109375, 14.267480468749994],
              [48.77998046875001, 14.123876953124991],
              [48.66835937500002, 14.050146484374991],
              [48.59375, 14.046240234374991],
              [48.44902343750002, 14.005908203125003],
              [48.27783203125, 13.99765625],
              [47.98994140625001, 14.048095703125],
              [47.916015625, 14.012841796874994],
              [47.855078125, 13.956933593749994],
              [47.633398437500006, 13.858447265625003],
              [47.40771484375, 13.66162109375],
              [47.24257812500002, 13.609375],
              [46.975683593750006, 13.547460937499991],
              [46.78886718750002, 13.465576171875],
              [46.66347656250002, 13.432714843749991],
              [46.501953125, 13.415576171875003],
              [46.203125, 13.423828125],
              [45.91972656250002, 13.394287109375],
              [45.657324218750006, 13.338720703124991],
              [45.53398437500002, 13.233496093749991],
              [45.3935546875, 13.067041015624994],
              [45.16386718750002, 12.998291015625],
              [45.10976562500002, 12.938574218749991],
              [45.038671875, 12.815869140624997],
              [44.88984375000001, 12.7841796875],
              [44.75527343750002, 12.763769531249991],
              [44.617773437500006, 12.817236328124991],
              [44.358496093750006, 12.669140625],
              [44.260351562500006, 12.644628906249991],
              [44.11152343750001, 12.638671875],
              [44.005859375, 12.607666015625],
              [43.929785156250006, 12.616503906249989],
              [43.83535156250002, 12.674414062499991],
              [43.634375, 12.744482421874991],
              [43.48759765625002, 12.698828125],
              [43.47529296875001, 12.839013671874994],
              [43.23193359375, 13.26708984375],
              [43.28261718750002, 13.63984375],
              [43.28242187500001, 13.692529296874994],
              [43.23408203125001, 13.858935546875003],
              [43.08906250000001, 14.010986328125],
              [43.093359375, 14.203662109375003],
              [43.04482421875002, 14.341552734375],
              [43.00625, 14.483105468749997],
              [43.01875, 14.520800781250003],
              [43.02109375, 14.554882812499997],
              [42.94697265625001, 14.773144531249997],
              [42.92216796875002, 14.8173828125],
              [42.91298828125002, 14.863085937500003],
              [42.93730468750002, 14.898046875],
              [42.93642578125002, 14.938574218749991],
              [42.897070312500006, 15.005566406249997],
              [42.85566406250001, 15.132958984374994],
              [42.6578125, 15.2328125],
              [42.697851562500006, 15.326318359374994],
              [42.736425781250006, 15.293554687499991],
              [42.78847656250002, 15.265722656249991],
              [42.79902343750001, 15.326269531249991],
              [42.799902343750006, 15.371630859375003],
              [42.71718750000002, 15.654638671874991],
              [42.83964843750002, 16.03203125],
              [42.79931640625, 16.371777343749997],
              [42.986328125, 16.509082031250003],
              [43.03359375000002, 16.550390625],
              [43.06074218750001, 16.586621093749997],
              [43.10478515625002, 16.66416015624999],
              [43.1650390625, 16.689404296874997],
              [43.18632812500002, 16.77099609375],
              [43.18447265625002, 16.811816406250003],
              [43.145605468750006, 16.84677734374999],
              [43.11650390625002, 16.94199218749999],
              [43.12617187500001, 17.062451171874997],
              [43.13593750000001, 17.112988281249997],
              [43.15595703125001, 17.205029296874997],
              [43.22138671875001, 17.2392578125],
              [43.236914062500006, 17.26645507812499],
              [43.18632812500002, 17.32470703125],
              [43.19091796875, 17.359375],
              [43.30214843750002, 17.456787109375],
              [43.34609375000002, 17.486035156249997],
              [43.41796875, 17.516259765624994],
              [43.47421875, 17.515917968750003],
              [43.53925781250001, 17.498730468749997],
              [43.597265625, 17.471435546875],
              [43.653417968750006, 17.421875],
              [43.712988281250006, 17.365527343750003],
              [43.804296875, 17.344140625],
              [43.86640625000001, 17.349609375],
              [43.9169921875, 17.32470703125],
              [43.95966796875001, 17.338330078124997],
              [44.00820312500002, 17.367480468750003],
              [44.0859375, 17.365527343750003],
              [44.15595703125001, 17.39853515624999],
              [44.35468750000001, 17.414355468750003],
              [44.546484375, 17.404345703125003],
              [44.74677734375001, 17.431689453125003],
              [44.94648437500001, 17.429589843749994],
              [45.148046875, 17.427441406249997],
              [45.19277343750002, 17.423388671875003],
              [45.23662109375002, 17.406201171874997],
              [45.40654296875002, 17.319775390624997],
              [45.53535156250001, 17.302050781250003],
              [45.79443359375, 17.27841796874999],
              [46.07080078125, 17.253173828125],
              [46.31035156250002, 17.23129882812499],
              [46.51347656250002, 17.251660156249997],
              [46.68203125000002, 17.2685546875],
              [46.727636718750006, 17.265576171874997],
              [46.77851562500001, 17.212109375],
              [46.879980468750006, 17.079003906249994],
              [46.975683593750006, 16.95346679687499],
              [47.1435546875, 16.946679687499994],
              [47.25126953125002, 16.993945312500003],
              [47.36962890625, 17.06040039062499],
              [47.44179687500002, 17.111865234375003],
              [47.525390625, 17.316113281249997],
              [47.57958984375, 17.44833984374999],
              [47.703710937500006, 17.596826171874994],
              [47.80781250000001, 17.72109375],
              [47.94550781250001, 17.88583984374999],
              [48.02167968750001, 17.976953125],
              [48.17216796875002, 18.156933593749997],
              [48.31582031250002, 18.22705078125],
              [48.59296875000001, 18.36240234374999],
              [48.86484375, 18.49521484374999],
              [49.0419921875, 18.581787109375],
              [49.1923828125, 18.621337890625],
              [49.44511718750002, 18.655322265625003],
              [49.74208984375002, 18.6953125],
              [50.03896484375002, 18.735253906249994],
              [50.35527343750002, 18.777783203124997],
              [50.70820312500001, 18.82529296874999],
              [50.95, 18.857861328124997],
              [51.258398437500006, 18.899365234374997],
              [51.51494140625002, 18.933886718750003],
              [51.74296875000002, 18.964550781249997],
              [51.977636718750006, 18.996142578125003],
              [52.021875, 18.896289062500003],
              [52.06621093750002, 18.79638671875],
              [52.110449218750006, 18.696484375],
              [52.15468750000002, 18.596582031249994],
              [52.19902343750002, 18.49667968749999],
              [52.243261718750006, 18.39682617187499],
              [52.2875, 18.296923828125003],
              [52.33173828125001, 18.197070312500003],
              [52.376074218750006, 18.09716796875],
              [52.42031250000002, 17.997314453125],
              [52.46455078125001, 17.897412109374997],
              [52.508886718750006, 17.797509765624994],
              [52.553125, 17.69760742187499],
              [52.59736328125001, 17.59775390624999],
              [52.641699218750006, 17.497851562500003],
              [52.68593750000002, 17.39794921875],
              [52.72919921875001, 17.300390625],
              [52.80058593750002, 17.26791992187499],
              [52.84296875000001, 17.175683593749994],
              [52.90371093750002, 17.043847656249994],
              [52.96435546875, 16.912060546874997],
              [53.025, 16.780224609374997],
              [53.08564453125001, 16.648388671874997],
            ],
          ],
          [
            [
              [42.59023437500002, 15.303417968749997],
              [42.558691406250006, 15.281201171874997],
              [42.54902343750001, 15.320068359375],
              [42.56972656250002, 15.407324218749991],
              [42.60234375000002, 15.432519531249994],
              [42.62451171875, 15.36796875],
              [42.610449218750006, 15.332275390625],
              [42.59023437500002, 15.303417968749997],
            ],
          ],
          [
            [
              [42.755859375, 13.704296875],
              [42.68974609375002, 13.673632812500003],
              [42.734960937500006, 13.752978515625003],
              [42.78125, 13.769287109375],
              [42.79414062500001, 13.76611328125],
              [42.755859375, 13.704296875],
            ],
          ],
          [
            [
              [42.78740234375002, 13.971484375],
              [42.77421875000002, 13.950244140625003],
              [42.75605468750001, 13.954882812500003],
              [42.69404296875001, 14.007910156249991],
              [42.76210937500002, 14.067480468749991],
              [42.79833984375, 14.012255859375003],
              [42.78740234375002, 13.971484375],
            ],
          ],
          [
            [
              [53.76318359375, 12.636816406249991],
              [53.824804687500006, 12.624804687499989],
              [53.918554687500006, 12.659423828125],
              [54.18740234375002, 12.664013671874997],
              [54.51113281250002, 12.552783203124989],
              [54.45, 12.5234375],
              [54.41376953125001, 12.483300781249994],
              [54.27128906250002, 12.446630859374991],
              [54.129492187500006, 12.360644531249989],
              [53.718847656250006, 12.318994140624994],
              [53.59833984375001, 12.34228515625],
              [53.49941406250002, 12.425341796874989],
              [53.31582031250002, 12.533154296874997],
              [53.38847656250002, 12.601855468749989],
              [53.40390625, 12.633349609374989],
              [53.43095703125002, 12.66357421875],
              [53.53496093750002, 12.715771484374997],
              [53.63847656250002, 12.707373046874991],
              [53.76318359375, 12.636816406249991],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 5,
        sovereignt: "Kashmir",
        sov_a3: "KAS",
        adm0_dif: 0,
        level: 2,
        type: "Indeterminate",
        tlc: null,
        admin: "Siachen Glacier",
        adm0_a3: "KAS",
        geou_dif: 0,
        geounit: "Siachen Glacier",
        gu_a3: "KAS",
        su_dif: 0,
        subunit: "Siachen Glacier",
        su_a3: "KAS",
        brk_diff: 1,
        name: "Siachen Glacier",
        name_long: "Siachen Glacier",
        brk_a3: "B45",
        brk_name: "Siachen Glacier",
        brk_group: "Jammu and Kashmir",
        abbrev: "Siachen",
        postal: "SG",
        formal_en: null,
        formal_fr: null,
        name_ciawf: null,
        note_adm0: null,
        note_brk: "Claimed by Pakistan and India",
        name_sort: "Kashmir",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 7,
        mapcolor9: 6,
        mapcolor13: -99,
        pop_est: 6000,
        pop_rank: 5,
        pop_year: 2013,
        gdp_md: 15,
        gdp_year: 2013,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        fips_10: "-99",
        iso_a2: "-99",
        iso_a2_eh: "-99",
        iso_a3: "-99",
        iso_a3_eh: "-99",
        iso_n3: "-99",
        iso_n3_eh: "-99",
        un_a3: "-099",
        wb_a2: "-99",
        wb_a3: "-99",
        woe_id: 23424928,
        woe_id_eh: 23424928,
        woe_note: "Exact WOE match as country",
        adm0_iso: "-99",
        adm0_diff: null,
        adm0_tlc: "-99",
        adm0_a3_us: "B45",
        adm0_a3_fr: "IND",
        adm0_a3_ru: "IND",
        adm0_a3_es: "IND",
        adm0_a3_cn: "IND",
        adm0_a3_tw: "IND",
        adm0_a3_in: "IND",
        adm0_a3_np: "IND",
        adm0_a3_pk: "PAK",
        adm0_a3_de: "IND",
        adm0_a3_gb: "IND",
        adm0_a3_br: "IND",
        adm0_a3_il: "IND",
        adm0_a3_ps: "IND",
        adm0_a3_sa: "IND",
        adm0_a3_eg: "IND",
        adm0_a3_ma: "IND",
        adm0_a3_pt: "IND",
        adm0_a3_ar: "IND",
        adm0_a3_jp: "IND",
        adm0_a3_ko: "IND",
        adm0_a3_vn: "IND",
        adm0_a3_tr: "PAK",
        adm0_a3_id: "IND",
        adm0_a3_pl: "IND",
        adm0_a3_gr: "IND",
        adm0_a3_it: "IND",
        adm0_a3_nl: "IND",
        adm0_a3_se: "IND",
        adm0_a3_bd: "IND",
        adm0_a3_ua: "IND",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Southern Asia",
        region_wb: "South Asia",
        name_len: 15,
        long_len: 15,
        abbrev_len: 7,
        tiny: -99,
        homepart: 1,
        min_zoom: 5,
        min_label: 6.5,
        max_label: 9.5,
        label_x: 77.129553,
        label_y: 35.340606,
        ne_id: 1159320963,
        wikidataid: "Q333946",
        name_ar: "نهر سياتشن الجليدي",
        name_bn: "সিয়াচেন হিমবাহ",
        name_de: "Siachen-Gletscher",
        name_en: "Siachen Glacier",
        name_es: "Glaciar de Siachen",
        name_fa: "یخچال سیاچن",
        name_fr: "glacier de Siachen",
        name_el: "Παγετώνας Σιατσέν",
        name_he: "קרחון סיאצ'ן",
        name_hi: "सियाचीन",
        name_hu: "Siachen-gleccser",
        name_id: "Siachen Glacier",
        name_it: "ghiacciaio Siachen",
        name_ja: "シアチェン氷河",
        name_ko: "시아첸 빙하",
        name_nl: "Siachengletsjer",
        name_pl: "Lodowiec Siachen",
        name_pt: "Glaciar de Siachen",
        name_ru: "Сиачен",
        name_sv: "Siachen Glaciären",
        name_tr: "Siachen Buzulu",
        name_uk: "Сіачен",
        name_ur: "سیاچن گلیشیر",
        name_vi: "Sông băng Siachen",
        name_zh: "锡亚琴冰川",
        name_zht: "錫亞琴冰川",
        fclass_iso: "Unrecognized",
        tlc_diff: null,
        fclass_tlc: "Unrecognized",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: "Unrecognized",
        fclass_es: "Unrecognized",
        fclass_cn: null,
        fclass_tw: "Unrecognized",
        fclass_in: "Unrecognized",
        fclass_np: "Unrecognized",
        fclass_pk: "Unrecognized",
        fclass_de: "Unrecognized",
        fclass_gb: "Unrecognized",
        fclass_br: "Unrecognized",
        fclass_il: "Unrecognized",
        fclass_ps: "Unrecognized",
        fclass_sa: "Unrecognized",
        fclass_eg: "Unrecognized",
        fclass_ma: "Unrecognized",
        fclass_pt: "Unrecognized",
        fclass_ar: "Unrecognized",
        fclass_jp: "Unrecognized",
        fclass_ko: "Unrecognized",
        fclass_vn: "Unrecognized",
        fclass_tr: "Unrecognized",
        fclass_id: "Unrecognized",
        fclass_pl: "Unrecognized",
        fclass_gr: "Unrecognized",
        fclass_it: "Unrecognized",
        fclass_nl: "Unrecognized",
        fclass_se: "Unrecognized",
        fclass_bd: "Unrecognized",
        fclass_ua: "Unrecognized",
        filename: "siachenglacier.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [77.04863281249999, 35.109912109374996],
            [77.00449218750003, 35.196337890624996],
            [76.97890625000002, 35.246435546875],
            [76.927734375, 35.346630859375],
            [76.88222656250002, 35.4357421875],
            [76.81279296874999, 35.571826171874996],
            [76.76689453124999, 35.66171875],
            [76.87890625, 35.61328125],
            [77.09003906250001, 35.552050781249996],
            [77.29482421875002, 35.508154296875],
            [77.44648437500001, 35.4755859375],
            [77.52001953125, 35.4734375],
            [77.57255859374999, 35.471826171874994],
            [77.72402343750002, 35.48056640625],
            [77.79941406250003, 35.495898437500045],
            [77.69697265625001, 35.443261718749994],
            [77.57158203124999, 35.378759765625],
            [77.42343749999998, 35.30258789062509],
            [77.29296875, 35.235546875],
            [77.16855468750003, 35.171533203124994],
            [77.04863281249999, 35.109912109374996],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 3,
        labelrank: 2,
        sovereignt: "Japan",
        sov_a3: "JPN",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Japan",
        adm0_a3: "JPN",
        geou_dif: 0,
        geounit: "Japan",
        gu_a3: "JPN",
        su_dif: 0,
        subunit: "Japan",
        su_a3: "JPN",
        brk_diff: 0,
        name: "Japan",
        name_long: "Japan",
        brk_a3: "JPN",
        brk_name: "Japan",
        brk_group: null,
        abbrev: "Japan",
        postal: "J",
        formal_en: "Japan",
        formal_fr: null,
        name_ciawf: "Japan",
        note_adm0: null,
        note_brk: null,
        name_sort: "Japan",
        name_alt: null,
        mapcolor7: 5,
        mapcolor8: 3,
        mapcolor9: 5,
        mapcolor13: 4,
        pop_est: 126264931,
        pop_rank: 17,
        pop_year: 2019,
        gdp_md: 5081769,
        gdp_year: 2019,
        economy: "1. Developed region: G7",
        income_grp: "1. High income: OECD",
        fips_10: "JA",
        iso_a2: "JP",
        iso_a2_eh: "JP",
        iso_a3: "JPN",
        iso_a3_eh: "JPN",
        iso_n3: "392",
        iso_n3_eh: "392",
        un_a3: "392",
        wb_a2: "JP",
        wb_a3: "JPN",
        woe_id: 23424856,
        woe_id_eh: 23424856,
        woe_note: "Exact WOE match as country",
        adm0_iso: "JPN",
        adm0_diff: null,
        adm0_tlc: "JPN",
        adm0_a3_us: "JPN",
        adm0_a3_fr: "JPN",
        adm0_a3_ru: "JPN",
        adm0_a3_es: "JPN",
        adm0_a3_cn: "JPN",
        adm0_a3_tw: "JPN",
        adm0_a3_in: "JPN",
        adm0_a3_np: "JPN",
        adm0_a3_pk: "JPN",
        adm0_a3_de: "JPN",
        adm0_a3_gb: "JPN",
        adm0_a3_br: "JPN",
        adm0_a3_il: "JPN",
        adm0_a3_ps: "JPN",
        adm0_a3_sa: "JPN",
        adm0_a3_eg: "JPN",
        adm0_a3_ma: "JPN",
        adm0_a3_pt: "JPN",
        adm0_a3_ar: "JPN",
        adm0_a3_jp: "JPN",
        adm0_a3_ko: "JPN",
        adm0_a3_vn: "JPN",
        adm0_a3_tr: "JPN",
        adm0_a3_id: "JPN",
        adm0_a3_pl: "JPN",
        adm0_a3_gr: "JPN",
        adm0_a3_it: "JPN",
        adm0_a3_nl: "JPN",
        adm0_a3_se: "JPN",
        adm0_a3_bd: "JPN",
        adm0_a3_ua: "JPN",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Eastern Asia",
        region_wb: "East Asia & Pacific",
        name_len: 5,
        long_len: 5,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 1.7,
        max_label: 7,
        label_x: 138.44217,
        label_y: 36.142538,
        ne_id: 1159320937,
        wikidataid: "Q17",
        name_ar: "اليابان",
        name_bn: "জাপান",
        name_de: "Japan",
        name_en: "Japan",
        name_es: "Japón",
        name_fa: "ژاپن",
        name_fr: "Japon",
        name_el: "Ιαπωνία",
        name_he: "יפן",
        name_hi: "जापान",
        name_hu: "Japán",
        name_id: "Jepang",
        name_it: "Giappone",
        name_ja: "日本",
        name_ko: "일본",
        name_nl: "Japan",
        name_pl: "Japonia",
        name_pt: "Japão",
        name_ru: "Япония",
        name_sv: "Japan",
        name_tr: "Japonya",
        name_uk: "Японія",
        name_ur: "جاپان",
        name_vi: "Nhật Bản",
        name_zh: "日本",
        name_zht: "日本",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "JPN.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [133.37050781250002, 36.203857421875],
              [133.32470703125, 36.16650390625],
              [133.2392578125, 36.178759765624996],
              [133.18994140625, 36.2326171875],
              [133.20615234375003, 36.293408203125],
              [133.29570312500005, 36.340136718749996],
              [133.38125, 36.246386718749996],
              [133.37050781250002, 36.203857421875],
            ],
          ],
          [
            [
              [138.34404296875005, 37.822119140625],
              [138.2490234375, 37.819580078125],
              [138.22519531250003, 37.829394531249996],
              [138.28281250000003, 37.85419921875],
              [138.28789062500005, 37.895800781249996],
              [138.322265625, 37.96953125],
              [138.32167968750002, 37.9908203125],
              [138.24619140625003, 37.994580078125],
              [138.25, 38.078466796875],
              [138.30634765625, 38.1611328125],
              [138.46132812500002, 38.291455078125],
              [138.50361328125, 38.31591796875],
              [138.51005859375005, 38.258984375],
              [138.46279296875002, 38.124316406249996],
              [138.45361328125, 38.07568359375],
              [138.5751953125, 38.06552734375],
              [138.49697265625002, 37.90390625],
              [138.34404296875005, 37.822119140625],
            ],
          ],
          [
            [
              [134.9328125, 34.288134765624996],
              [134.8244140625, 34.202929687499996],
              [134.7306640625, 34.208886718749994],
              [134.68349609375002, 34.246972656249994],
              [134.66787109375002, 34.294140625],
              [134.75722656250002, 34.3681640625],
              [134.83427734375005, 34.47265625],
              [134.90410156250005, 34.519091796874996],
              [134.96074218750005, 34.544921875],
              [135.00468750000005, 34.54404296875],
              [134.90546875, 34.398291015625],
              [134.9328125, 34.288134765624996],
            ],
          ],
          [
            [
              [130.62275390625, 30.262988281249996],
              [130.50820312500002, 30.24140625],
              [130.44560546875005, 30.264697265624996],
              [130.38808593750002, 30.38818359375],
              [130.49716796875003, 30.465527343749997],
              [130.6435546875, 30.388964843749996],
              [130.67324218750002, 30.366894531249997],
              [130.62275390625, 30.262988281249996],
            ],
          ],
          [
            [
              [130.95976562500005, 30.396923828124997],
              [130.87216796875003, 30.386328125],
              [130.8703125, 30.444238281249994],
              [130.93994140625, 30.575097656249994],
              [130.94736328125003, 30.671191406249996],
              [131.01220703125, 30.792285156249996],
              [131.03984375000005, 30.818896484374996],
              [131.06035156250005, 30.828466796875],
              [131.08261718750003, 30.790869140625],
              [131.05742187500005, 30.642480468749994],
              [130.99257812500002, 30.529980468749997],
              [130.95976562500005, 30.396923828124997],
            ],
          ],
          [
            [
              [130.08251953125, 32.2296875],
              [130.00351562500003, 32.193994140624994],
              [129.993359375, 32.228173828124994],
              [129.96015625, 32.24375],
              [130.01728515625, 32.291845703125],
              [130.01533203125, 32.313671875],
              [129.97929687500005, 32.34619140625],
              [130.02128906250005, 32.46884765625],
              [130.009765625, 32.521630859374994],
              [130.16777343750005, 32.5412109375],
              [130.19667968750002, 32.491601562499994],
              [130.19951171875005, 32.340576171875],
              [130.08251953125, 32.2296875],
            ],
          ],
          [
            [
              [129.2794921875, 34.123388671875],
              [129.21445312500003, 34.0828125],
              [129.18642578125002, 34.14501953125],
              [129.21484375, 34.320654296875],
              [129.337109375, 34.284765625],
              [129.33505859375003, 34.230810546875],
              [129.2794921875, 34.123388671875],
            ],
          ],
          [
            [
              [129.38564453125002, 34.353662109374994],
              [129.36533203125003, 34.305517578125],
              [129.29746093750003, 34.339599609375],
              [129.26669921875003, 34.370458984375],
              [129.32939453125005, 34.521875],
              [129.32207031250005, 34.579296875],
              [129.32587890625, 34.607275390625],
              [129.45107421875002, 34.686572265624996],
              [129.47246093750005, 34.671337890625],
              [129.48017578125, 34.649462890624996],
              [129.46914062500002, 34.615527343749996],
              [129.47539062500005, 34.540429687499994],
              [129.38144531250003, 34.416455078125],
              [129.38564453125002, 34.353662109374994],
            ],
          ],
          [
            [
              [128.66533203125005, 32.78388671875],
              [128.7041015625, 32.756884765624996],
              [128.76103515625005, 32.772363281249994],
              [128.80605468750002, 32.7759765625],
              [128.83857421875, 32.762890625],
              [128.87939453125, 32.693310546875],
              [128.89453125, 32.652148437499996],
              [128.8212890625, 32.646337890625],
              [128.79042968750002, 32.63671875],
              [128.75048828125, 32.5861328125],
              [128.69296875000003, 32.604736328125],
              [128.65732421875003, 32.62841796875],
              [128.64912109375, 32.662011718749994],
              [128.66533203125005, 32.78388671875],
            ],
          ],
          [
            [
              [130.3810546875, 32.423730468749994],
              [130.29257812500003, 32.419335937499994],
              [130.2560546875, 32.431005859375],
              [130.24169921875, 32.462792968749994],
              [130.3654296875, 32.527197265625],
              [130.46142578125, 32.51572265625],
              [130.41855468750003, 32.45771484375],
              [130.3810546875, 32.423730468749994],
            ],
          ],
          [
            [
              [141.07275390625, 45.332861328125],
              [141.03398437500005, 45.269335937499996],
              [140.98212890625, 45.36376953125],
              [140.9716796875, 45.465478515624994],
              [141.00166015625, 45.46484375],
              [141.05673828125003, 45.449560546875],
              [141.06992187500003, 45.4],
              [141.07275390625, 45.332861328125],
            ],
          ],
          [
            [
              [141.29541015625, 45.1193359375],
              [141.22597656250002, 45.112207031249994],
              [141.14531250000005, 45.15390625],
              [141.13535156250003, 45.206201171874994],
              [141.19375, 45.247851562499996],
              [141.25185546875002, 45.232470703124996],
              [141.31005859375, 45.178564453125],
              [141.32919921875003, 45.15048828125],
              [141.29541015625, 45.1193359375],
            ],
          ],
          [
            [
              [139.48125, 42.081005859375],
              [139.45839843750002, 42.075634765625],
              [139.4345703125, 42.08408203125],
              [139.41152343750002, 42.15966796875],
              [139.43134765625, 42.199560546875],
              [139.49580078125, 42.227441406249994],
              [139.55839843750005, 42.235205078125],
              [139.505078125, 42.09638671875],
              [139.48125, 42.081005859375],
            ],
          ],
          [
            [
              [134.35185546875005, 34.483642578125],
              [134.333203125, 34.463769531249994],
              [134.31533203125002, 34.4689453125],
              [134.25185546875002, 34.423046875],
              [134.23808593750005, 34.467041015625],
              [134.18828125000005, 34.496337890625],
              [134.18212890625, 34.51923828125],
              [134.32597656250005, 34.534375],
              [134.372265625, 34.522363281249994],
              [134.35185546875005, 34.483642578125],
            ],
          ],
          [
            [
              [139.45644531250002, 34.726513671875],
              [139.44570312500002, 34.679541015625],
              [139.39238281250005, 34.689892578125],
              [139.36689453125, 34.720507812499996],
              [139.37001953125002, 34.775439453124996],
              [139.42617187500002, 34.77587890625],
              [139.45644531250002, 34.726513671875],
            ],
          ],
          [
            [
              [129.07695312500005, 32.840283203125],
              [129.051953125, 32.829492187499994],
              [129.01962890625003, 32.91962890625],
              [128.997265625, 32.95185546875],
              [129.03496093750005, 32.969091796875],
              [129.10976562500002, 33.132568359375],
              [129.12363281250003, 33.067675781249996],
              [129.15273437500002, 33.003320312499994],
              [129.18193359375005, 32.993115234375],
              [129.153515625, 32.946191406249994],
              [129.11162109375005, 32.928857421874994],
              [129.07695312500005, 32.840283203125],
            ],
          ],
          [
            [
              [129.49179687500003, 33.223046875],
              [129.42138671875, 33.175830078124996],
              [129.37041015625005, 33.176025390625],
              [129.4169921875, 33.231103515624994],
              [129.42314453125005, 33.257373046874996],
              [129.4619140625, 33.33125],
              [129.53798828125002, 33.357763671875],
              [129.56992187500003, 33.36103515625],
              [129.50810546875005, 33.284326171874994],
              [129.49179687500003, 33.223046875],
            ],
          ],
          [
            [
              [129.79570312500005, 33.748828125],
              [129.7265625, 33.707324218749996],
              [129.6748046875, 33.739697265625],
              [129.7, 33.82890625],
              [129.71728515625, 33.8583984375],
              [129.7763671875, 33.82919921875],
              [129.79570312500005, 33.748828125],
            ],
          ],
          [
            [
              [132.57841796875005, 34.115185546875],
              [132.54941406250003, 34.075097656249994],
              [132.4609375, 34.087255859375],
              [132.4962890625, 34.121972656249994],
              [132.52353515625003, 34.1640625],
              [132.54345703125, 34.17265625],
              [132.56015625000003, 34.126904296875],
              [132.57841796875005, 34.115185546875],
            ],
          ],
          [
            [
              [132.26601562500002, 33.945166015625],
              [132.31455078125003, 33.90859375],
              [132.43046875000005, 33.92353515625],
              [132.44492187500003, 33.91318359375],
              [132.41103515625002, 33.879931640624996],
              [132.35996093750003, 33.847021484375],
              [132.26728515625, 33.871484375],
              [132.20878906250005, 33.872851562499996],
              [132.20058593750002, 33.927783203124996],
              [132.2080078125, 33.947802734374996],
              [132.26601562500002, 33.945166015625],
            ],
          ],
          [
            [
              [129.71796875, 31.657128906249994],
              [129.68681640625005, 31.6396484375],
              [129.70683593750005, 31.71826171875],
              [129.7873046875, 31.787109375],
              [129.79365234375, 31.742480468749996],
              [129.71796875, 31.657128906249994],
            ],
          ],
          [
            [
              [142.18818359375, 26.616503906249996],
              [142.169921875, 26.615673828124997],
              [142.10712890625, 26.721533203125],
              [142.12529296875005, 26.726464843749994],
              [142.16171875000003, 26.7099609375],
              [142.2021484375, 26.648779296875],
              [142.18818359375, 26.616503906249996],
            ],
          ],
          [
            [
              [143.82431640625003, 44.116992187499996],
              [143.94951171875005, 44.1119140625],
              [144.00546875000003, 44.116650390625],
              [144.10136718750005, 44.1015625],
              [144.48183593750002, 43.949560546875],
              [144.5966796875, 43.930224609374996],
              [144.71523437500002, 43.927978515625],
              [144.79853515625, 43.940234375],
              [144.871875, 43.98193359375],
              [145.1015625, 44.166162109374994],
              [145.3427734375, 44.333886718749994],
              [145.36953125000002, 44.327392578125],
              [145.36962890625, 44.281298828124996],
              [145.35195312500002, 44.229785156249996],
              [145.24521484375003, 44.076171875],
              [145.12636718750002, 43.869384765625],
              [145.10107421875, 43.764550781249994],
              [145.1396484375, 43.6625],
              [145.2140625, 43.57822265625],
              [145.27294921875, 43.462890625],
              [145.3408203125, 43.302539062499996],
              [145.43613281250003, 43.2822265625],
              [145.48789062500003, 43.279736328125],
              [145.58330078125005, 43.327783203124994],
              [145.67363281250005, 43.3888671875],
              [145.75126953125005, 43.396289062499996],
              [145.8330078125, 43.3859375],
              [145.7255859375, 43.34345703125],
              [145.62421875, 43.291308593749996],
              [145.53740234375005, 43.192675781249996],
              [145.505078125, 43.17421875],
              [145.40478515625, 43.1802734375],
              [145.34746093750005, 43.176708984375],
              [145.23007812500003, 43.135498046875],
              [145.1271484375, 43.0888671875],
              [145.02880859375, 43.031640625],
              [144.92138671875, 43.000927734375],
              [144.80712890625, 42.993701171874996],
              [144.63076171875002, 42.946923828124994],
              [144.51621093750003, 42.943603515625],
              [144.30156250000005, 42.984423828124996],
              [144.197265625, 42.9736328125],
              [143.96933593750003, 42.881396484374996],
              [143.76210937500002, 42.74814453125],
              [143.58095703125002, 42.59873046875],
              [143.42949218750005, 42.418896484375],
              [143.36865234375, 42.325146484375],
              [143.33212890625003, 42.220361328124994],
              [143.3271484375, 42.151025390624994],
              [143.313671875, 42.084326171875],
              [143.27871093750002, 42.037841796875],
              [143.2365234375, 42.0001953125],
              [143.11171875000002, 42.022216796875],
              [142.90634765625003, 42.118359375],
              [142.50820312500002, 42.257958984374994],
              [142.087890625, 42.471728515624996],
              [141.85136718750005, 42.579052734375],
              [141.40664062500002, 42.546923828124996],
              [140.98613281250005, 42.342138671875],
              [140.9484375, 42.3595703125],
              [140.78759765625, 42.5],
              [140.70976562500005, 42.555615234375],
              [140.61679687500003, 42.571337890624996],
              [140.54765625000005, 42.56953125],
              [140.48046875, 42.559375],
              [140.38544921875, 42.487158203125],
              [140.3505859375, 42.435107421874996],
              [140.32353515625005, 42.37607421875],
              [140.31523437500005, 42.334277343749996],
              [140.32666015625, 42.293359375],
              [140.41660156250003, 42.200732421874996],
              [140.5275390625, 42.131787109375],
              [140.57773437500003, 42.11865234375],
              [140.68427734375, 42.123486328125],
              [140.73378906250002, 42.116357421874994],
              [140.9123046875, 41.977783203125],
              [141.10771484375005, 41.848046875],
              [141.15097656250003, 41.805078125],
              [141.07871093750003, 41.759814453124996],
              [140.99951171875, 41.73740234375],
              [140.90751953125005, 41.74326171875],
              [140.81640625, 41.760400390624994],
              [140.65986328125, 41.815576171874994],
              [140.59296875, 41.7685546875],
              [140.48916015625002, 41.672167968749996],
              [140.431640625, 41.5673828125],
              [140.3849609375, 41.519287109375],
              [140.27011718750003, 41.456005859375],
              [140.1486328125, 41.423242187499994],
              [140.08515625, 41.43408203125],
              [140.03662109375, 41.473779296874994],
              [140.00917968750002, 41.521337890625],
              [139.9953125, 41.576416015625],
              [140.02128906250005, 41.695751953125],
              [140.0841796875, 41.80322265625],
              [140.1083984375, 41.912939453125],
              [140.0568359375, 42.067333984375],
              [140.02412109375, 42.099560546875],
              [139.89511718750003, 42.1900390625],
              [139.83544921875, 42.278076171875],
              [139.82089843750003, 42.387597656249994],
              [139.82851562500002, 42.448144531249994],
              [139.86015625000005, 42.58173828125],
              [139.89111328125, 42.64921875],
              [139.95058593750002, 42.671435546874996],
              [140.01503906250002, 42.684765625],
              [140.11464843750002, 42.732958984374996],
              [140.22412109375, 42.7955078125],
              [140.32861328125, 42.866845703124994],
              [140.43222656250003, 42.9541015625],
              [140.48642578125003, 43.04990234375],
              [140.3974609375, 43.167333984375],
              [140.37929687500002, 43.237109375],
              [140.39238281250005, 43.303125],
              [140.48691406250003, 43.338183593749996],
              [140.58457031250003, 43.31171875],
              [140.78066406250002, 43.214990234374994],
              [140.81914062500005, 43.20546875],
              [140.95380859375, 43.200976562499996],
              [141.13818359375, 43.179931640625],
              [141.24501953125002, 43.18505859375],
              [141.29628906250002, 43.199658203125],
              [141.37412109375003, 43.279638671875],
              [141.4123046875, 43.381494140624994],
              [141.39833984375002, 43.5125],
              [141.39765625, 43.642626953124996],
              [141.44677734375, 43.7486328125],
              [141.60068359375003, 43.918994140624996],
              [141.6447265625, 44.019433593749994],
              [141.66083984375, 44.263623046875],
              [141.71630859375, 44.37119140625],
              [141.7609375, 44.48251953125],
              [141.7822265625, 44.716357421874996],
              [141.71904296875005, 44.941064453124994],
              [141.65576171875, 45.051220703125],
              [141.5830078125, 45.15595703125],
              [141.59375, 45.25595703125],
              [141.6525390625, 45.3486328125],
              [141.65400390625, 45.3765625],
              [141.66796875, 45.401269531249994],
              [141.778125, 45.418896484375],
              [141.82949218750002, 45.438769531249996],
              [141.87871093750005, 45.483300781249994],
              [141.9376953125, 45.509521484375],
              [141.98085937500002, 45.48349609375],
              [142.01640625000005, 45.437939453125],
              [142.17158203125, 45.325634765625],
              [142.416015625, 45.125],
              [142.7041015625, 44.819189453125],
              [142.884765625, 44.6701171875],
              [143.07509765625002, 44.534912109375],
              [143.28857421875, 44.396630859374994],
              [143.5119140625, 44.2775390625],
              [143.65458984375005, 44.221337890624994],
              [143.75908203125005, 44.131640625],
              [143.82431640625003, 44.116992187499996],
            ],
          ],
          [
            [
              [131.17460937500005, 33.602587890624996],
              [131.30917968750003, 33.57275390625],
              [131.36601562500005, 33.5708984375],
              [131.41875, 33.584423828125],
              [131.49882812500005, 33.623583984374996],
              [131.5830078125, 33.652392578124996],
              [131.64306640625, 33.63779296875],
              [131.6962890625, 33.602832031249996],
              [131.72421875000003, 33.55380859375],
              [131.71064453125, 33.50234375],
              [131.61552734375005, 33.391845703125],
              [131.53740234375005, 33.274072265624994],
              [131.71708984375005, 33.252099609374994],
              [131.89658203125003, 33.25458984375],
              [131.8546875, 33.181640625],
              [131.8478515625, 33.118066406249994],
              [131.90273437500002, 33.087792968749994],
              [131.94931640625003, 33.0470703125],
              [131.93720703125, 33.01015625],
              [131.91044921875005, 32.973681640624996],
              [132.00859375000005, 32.91904296875],
              [132.0021484375, 32.882373046874996],
              [131.97666015625003, 32.8439453125],
              [131.73212890625, 32.592822265624996],
              [131.6603515625, 32.465625],
              [131.6103515625, 32.325488281249996],
              [131.5646484375, 32.223046875],
              [131.53115234375002, 32.116748046874996],
              [131.50566406250005, 32.001953125],
              [131.46025390625005, 31.883496093749997],
              [131.47529296875, 31.77841796875],
              [131.4599609375, 31.670800781249994],
              [131.33720703125005, 31.4046875],
              [131.24970703125, 31.409619140624997],
              [131.13974609375003, 31.441845703124997],
              [131.07080078125, 31.436865234375],
              [131.03515625, 31.377685546875],
              [131.09843750000005, 31.256152343749996],
              [130.90224609375002, 31.112060546875],
              [130.6857421875, 31.01513671875],
              [130.68417968750003, 31.059277343749997],
              [130.70449218750002, 31.094091796875],
              [130.73574218750002, 31.1220703125],
              [130.75839843750003, 31.155810546874996],
              [130.78974609375, 31.269091796874996],
              [130.77421875000005, 31.383203125],
              [130.708984375, 31.526074218749997],
              [130.70419921875003, 31.577441406249996],
              [130.74941406250002, 31.598193359374996],
              [130.77978515625, 31.6041015625],
              [130.79628906250002, 31.624072265624996],
              [130.796875, 31.671289062499994],
              [130.77626953125002, 31.706298828125],
              [130.71455078125, 31.717675781249994],
              [130.65507812500005, 31.718408203124994],
              [130.6134765625, 31.665429687499994],
              [130.55605468750002, 31.5630859375],
              [130.528125, 31.459667968749997],
              [130.54042968750002, 31.403076171875],
              [130.56591796875, 31.352392578125],
              [130.64453125, 31.267480468749994],
              [130.62148437500002, 31.217529296875],
              [130.58876953125002, 31.178515625],
              [130.31064453125003, 31.266894531249996],
              [130.25058593750003, 31.273193359375],
              [130.20068359375, 31.291894531249994],
              [130.14726562500005, 31.408496093749996],
              [130.26054687500005, 31.436572265624996],
              [130.294140625, 31.45068359375],
              [130.306640625, 31.48779296875],
              [130.32197265625, 31.601464843749994],
              [130.26894531250002, 31.696337890624996],
              [130.22421875000003, 31.730078125],
              [130.18789062500002, 31.768847656249996],
              [130.2109375, 31.848974609375],
              [130.19580078125, 31.949853515624994],
              [130.19443359375003, 32.090771484375],
              [130.2140625, 32.115039062499996],
              [130.31914062500005, 32.143505859375],
              [130.39492187500002, 32.218994140625],
              [130.46201171875003, 32.304931640625],
              [130.56035156250005, 32.4560546875],
              [130.64052734375002, 32.61923828125],
              [130.56328125000005, 32.626367187499994],
              [130.49785156250005, 32.65693359375],
              [130.56943359375003, 32.734130859375],
              [130.54726562500002, 32.831591796874996],
              [130.4404296875, 32.9513671875],
              [130.38173828125002, 33.092578125],
              [130.2873046875, 33.15478515625],
              [130.2375, 33.177636718749994],
              [130.17685546875003, 33.14453125],
              [130.12685546875002, 33.104833984375],
              [130.17314453125005, 33.012988281249996],
              [130.16777343750005, 32.931787109374994],
              [130.175, 32.851318359375],
              [130.22216796875, 32.846826171874994],
              [130.28007812500005, 32.866845703124994],
              [130.32646484375005, 32.85263671875],
              [130.353515625, 32.810351562499996],
              [130.36083984375, 32.755859375],
              [130.34042968750003, 32.70185546875],
              [130.29765625000005, 32.675],
              [130.24550781250002, 32.677148437499994],
              [130.19296875000003, 32.706298828125],
              [130.15205078125, 32.747851562499996],
              [130.05410156250002, 32.770800781249996],
              [129.95078125000003, 32.721728515624996],
              [129.8525390625, 32.621728515624994],
              [129.7685546875, 32.57099609375],
              [129.80810546875, 32.645263671875],
              [129.82675781250003, 32.725341796875],
              [129.78593750000005, 32.781640625],
              [129.69003906250003, 32.875244140625],
              [129.66777343750005, 32.92939453125],
              [129.6623046875, 32.994921875],
              [129.67910156250002, 33.059960937499994],
              [129.77773437500002, 32.985546875],
              [129.8283203125, 32.89267578125],
              [129.90078125000002, 32.851904296875],
              [129.99169921875, 32.8515625],
              [129.921875, 32.98798828125],
              [129.89677734375005, 33.022363281249994],
              [129.79873046875002, 33.08359375],
              [129.6650390625, 33.18662109375],
              [129.580078125, 33.236279296875],
              [129.61015625000005, 33.343652343749994],
              [129.65996093750005, 33.364990234375],
              [129.7021484375, 33.359814453125],
              [129.84414062500002, 33.32177734375],
              [129.85751953125003, 33.375244140625],
              [129.83662109375, 33.40380859375],
              [129.82568359375, 33.43701171875],
              [129.919140625, 33.48349609375],
              [130.07207031250005, 33.521777343749996],
              [130.10341796875002, 33.539697265624994],
              [130.13056640625, 33.578173828124996],
              [130.16796875, 33.598291015624994],
              [130.27509765625, 33.597705078124996],
              [130.36503906250005, 33.63447265625],
              [130.439453125, 33.734228515625],
              [130.4572265625, 33.788964843749994],
              [130.48378906250002, 33.834619140624994],
              [130.66953125000003, 33.915478515625],
              [130.715625, 33.927783203124996],
              [130.83964843750005, 33.917773437499996],
              [130.953125, 33.872021484375],
              [131.00908203125005, 33.775830078125],
              [131.05810546875, 33.6728515625],
              [131.17460937500005, 33.602587890624996],
            ],
          ],
          [
            [
              [134.357421875, 34.25634765625],
              [134.49570312500003, 34.214746093749994],
              [134.6375, 34.226611328124996],
              [134.63525390625, 34.0439453125],
              [134.65537109375003, 33.9826171875],
              [134.6953125, 33.927734375],
              [134.6748046875, 33.847802734374994],
              [134.73886718750003, 33.8205078125],
              [134.54873046875002, 33.729296875],
              [134.37705078125003, 33.6083984375],
              [134.30654296875002, 33.526806640625],
              [134.24267578125, 33.439453125],
              [134.20566406250003, 33.346972656249996],
              [134.181640625, 33.247216796874994],
              [134.12412109375003, 33.286767578124994],
              [133.95869140625, 33.44833984375],
              [133.85400390625, 33.49267578125],
              [133.68564453125003, 33.51630859375],
              [133.63203125, 33.510986328125],
              [133.28593750000005, 33.3599609375],
              [133.23994140625, 33.249609375],
              [133.14560546875003, 33.083154296874994],
              [133.10087890625005, 33.028222656249994],
              [133.05117187500002, 33.012451171875],
              [133.01601562500002, 32.98388671875],
              [132.97724609375, 32.8419921875],
              [132.86992187500005, 32.75458984375],
              [132.80429687500003, 32.752001953124996],
              [132.69218750000005, 32.775927734374996],
              [132.641796875, 32.762451171875],
              [132.708984375, 32.902490234374994],
              [132.60195312500002, 32.91953125],
              [132.4951171875, 32.9166015625],
              [132.49257812500002, 33.007666015625],
              [132.42783203125003, 33.059375],
              [132.47578125, 33.12646484375],
              [132.47714843750003, 33.18115234375],
              [132.50527343750002, 33.211279296875],
              [132.51503906250002, 33.25537109375],
              [132.51142578125, 33.29306640625],
              [132.44541015625003, 33.304589843749994],
              [132.40517578125002, 33.33125],
              [132.41279296875, 33.43046875],
              [132.37490234375002, 33.43408203125],
              [132.28105468750005, 33.416796875],
              [132.08583984375002, 33.340136718749996],
              [132.03261718750002, 33.339990234374994],
              [132.114453125, 33.394580078124996],
              [132.28789062500005, 33.46953125],
              [132.36591796875, 33.512451171875],
              [132.53603515625002, 33.63291015625],
              [132.64306640625, 33.68994140625],
              [132.69892578125, 33.790917968749994],
              [132.71621093750002, 33.85224609375],
              [132.75234375000002, 33.906152343749994],
              [132.78427734375003, 33.992431640625],
              [132.83945312500003, 34.021240234375],
              [132.93515625000003, 34.0953125],
              [132.99013671875002, 34.088134765625],
              [133.05126953125, 33.997119140624996],
              [133.13369140625002, 33.927294921874996],
              [133.19306640625, 33.933203125],
              [133.298828125, 33.968994140625],
              [133.3498046875, 33.97705078125],
              [133.47207031250002, 33.972802734374994],
              [133.58203125, 34.017138671874996],
              [133.62675781250005, 34.069384765624996],
              [133.64345703125002, 34.134667968749994],
              [133.60263671875003, 34.24384765625],
              [133.65556640625005, 34.232861328125],
              [133.70625, 34.237353515624996],
              [133.82558593750002, 34.3068359375],
              [133.94833984375003, 34.348046875],
              [134.07587890625, 34.3583984375],
              [134.21923828125, 34.31904296875],
              [134.357421875, 34.25634765625],
            ],
          ],
          [
            [
              [141.22929687500005, 41.37265625],
              [141.26875, 41.353808593749996],
              [141.45546875000002, 41.404736328125],
              [141.419921875, 41.251171875],
              [141.4, 41.096337890624994],
              [141.41367187500003, 40.83935546875],
              [141.43046875000005, 40.72333984375],
              [141.46279296875002, 40.611132812499996],
              [141.54228515625005, 40.530712890625],
              [141.64628906250005, 40.4736328125],
              [141.7970703125, 40.291162109374994],
              [141.8779296875, 40.067236328125],
              [141.93505859375, 39.95849609375],
              [141.97783203125005, 39.84443359375],
              [141.99082031250003, 39.792236328125],
              [141.99189453125, 39.739892578124994],
              [141.97910156250003, 39.668359375],
              [141.9931640625, 39.610546875],
              [141.97695312500002, 39.42880859375],
              [141.90947265625005, 39.218701171875],
              [141.90078125000002, 39.111328125],
              [141.84208984375005, 39.0900390625],
              [141.80654296875002, 39.040429687499994],
              [141.77617187500005, 39.017431640625],
              [141.74248046875005, 38.999609375],
              [141.6935546875, 38.995166015624996],
              [141.65859375000002, 38.974853515625],
              [141.6447265625, 38.917919921875],
              [141.622265625, 38.865136718749994],
              [141.57968750000003, 38.81650390625],
              [141.54628906250002, 38.762841796874994],
              [141.51875, 38.63203125],
              [141.5087890625, 38.497851562499996],
              [141.46748046875, 38.404150390625],
              [141.3681640625, 38.379736328125],
              [141.25429687500002, 38.381396484374996],
              [141.1083984375, 38.337939453124996],
              [141.07734375, 38.312548828124996],
              [140.962109375, 38.148876953125],
              [140.92900390625005, 38.052880859374994],
              [140.9279296875, 37.949609375],
              [140.9599609375, 37.822607421875],
              [141.00341796875, 37.6984375],
              [141.036328125, 37.467236328125],
              [141.00166015625, 37.114648437499994],
              [140.96835937500003, 37.00205078125],
              [140.89511718750003, 36.925732421875],
              [140.83964843750005, 36.89033203125],
              [140.79179687500005, 36.846875],
              [140.72988281250002, 36.731884765625],
              [140.62734375000002, 36.502783203125],
              [140.61884765625, 36.4453125],
              [140.61923828125003, 36.385595703125],
              [140.59160156250005, 36.3078125],
              [140.57353515625005, 36.231347656249994],
              [140.59042968750003, 36.142431640625],
              [140.62197265625002, 36.059228515624994],
              [140.75957031250005, 35.845703125],
              [140.8134765625, 35.782519531249996],
              [140.8740234375, 35.724951171875],
              [140.63925781250003, 35.661279296874994],
              [140.596875, 35.63203125],
              [140.45742187500002, 35.51025390625],
              [140.41289062500005, 35.394775390625],
              [140.41650390625, 35.266992187499994],
              [140.39296875000002, 35.221142578125],
              [140.3546875, 35.181445312499996],
              [140.31474609375005, 35.155029296875],
              [140.15888671875, 35.096484375],
              [140.05917968750003, 35.03828125],
              [139.95976562500005, 34.947314453124996],
              [139.92041015625, 34.899609375],
              [139.8439453125, 34.914892578125],
              [139.79921875000002, 34.956933593749994],
              [139.84326171875, 35.00986328125],
              [139.82968750000003, 35.072167968749994],
              [139.85146484375002, 35.232324218749994],
              [139.82646484375005, 35.296679687499996],
              [139.90615234375002, 35.345263671874996],
              [139.94414062500005, 35.422998046874994],
              [140.02714843750005, 35.485205078125],
              [140.08632812500002, 35.540429687499994],
              [140.096875, 35.58515625],
              [140.04365234375, 35.633349609374996],
              [139.9875, 35.668212890625],
              [139.90976562500003, 35.668359375],
              [139.83476562500005, 35.658056640625],
              [139.786328125, 35.612109375],
              [139.77011718750003, 35.549560546875],
              [139.77392578125, 35.520361328125],
              [139.7677734375, 35.49482421875],
              [139.65, 35.409130859375],
              [139.66552734375, 35.319482421874994],
              [139.7, 35.273974609374996],
              [139.74404296875002, 35.252392578125],
              [139.73085937500002, 35.221533203125],
              [139.675, 35.149267578125],
              [139.6359375, 35.142138671874996],
              [139.56406250000003, 35.24326171875],
              [139.47441406250005, 35.29853515625],
              [139.3634765625, 35.298095703125],
              [139.24941406250002, 35.27802734375],
              [139.16269531250003, 35.210742187499996],
              [139.13408203125005, 35.1548828125],
              [139.11582031250003, 35.097119140625],
              [139.12197265625002, 34.956494140625],
              [139.08603515625003, 34.839160156249996],
              [139.015625, 34.73603515625],
              [138.9826171875, 34.698388671874994],
              [138.8966796875, 34.62841796875],
              [138.8375, 34.61923828125],
              [138.7951171875, 34.651025390624994],
              [138.76103515625005, 34.69921875],
              [138.80449218750005, 34.875732421875],
              [138.802734375, 34.9748046875],
              [138.90361328125005, 35.025244140625],
              [138.82089843750003, 35.095703125],
              [138.71962890625002, 35.124072265624996],
              [138.5771484375, 35.086474609374996],
              [138.53701171875002, 35.044140625],
              [138.50957031250005, 34.987158203125],
              [138.43310546875, 34.915185546874994],
              [138.3486328125, 34.847705078124996],
              [138.25322265625005, 34.732666015625],
              [138.18906250000003, 34.596337890624994],
              [137.97900390625, 34.640917968749996],
              [137.8642578125, 34.65087890625],
              [137.74853515625, 34.647412109375],
              [137.54335937500002, 34.664208984374994],
              [137.31806640625, 34.636376953124994],
              [137.06171875, 34.5828125],
              [137.07705078125002, 34.621435546875],
              [137.28779296875, 34.703515625],
              [137.29541015625, 34.727587890624996],
              [137.27519531250005, 34.772509765624996],
              [137.22265625, 34.774707031249996],
              [137.09658203125002, 34.759033203125],
              [137.0322265625, 34.765917968749996],
              [137.00595703125003, 34.814111328124994],
              [136.96328125000002, 34.834912109375],
              [136.934765625, 34.815185546875],
              [136.94414062500005, 34.721533203125],
              [136.91289062500005, 34.709033203124996],
              [136.8712890625, 34.73310546875],
              [136.88457031250005, 34.805859375],
              [136.85615234375, 34.9125],
              [136.85292968750002, 34.9787109375],
              [136.89707031250003, 35.035546875],
              [136.85185546875005, 35.059521484375],
              [136.80419921875, 35.05029296875],
              [136.74355468750002, 35.022998046874996],
              [136.69003906250003, 34.984130859375],
              [136.57695312500005, 34.78955078125],
              [136.53300781250005, 34.678369140624994],
              [136.61582031250003, 34.5890625],
              [136.84160156250005, 34.464208984375],
              [136.88027343750002, 34.43359375],
              [136.88115234375005, 34.38046875],
              [136.8537109375, 34.324072265625],
              [136.7921875, 34.299267578125],
              [136.54443359375, 34.257714843749994],
              [136.32988281250005, 34.17685546875],
              [136.26787109375005, 34.094873046874994],
              [136.07255859375005, 33.778222656249994],
              [135.9162109375, 33.56171875],
              [135.6953125, 33.486962890624994],
              [135.45283203125, 33.553369140624994],
              [135.39423828125, 33.628759765625],
              [135.34677734375003, 33.721972656249996],
              [135.25664062500005, 33.80625],
              [135.17539062500003, 33.898046875],
              [135.1279296875, 34.006982421874994],
              [135.13535156250003, 34.1826171875],
              [135.10009765625, 34.288378906249996],
              [135.13193359375003, 34.316552734374994],
              [135.265625, 34.380810546875],
              [135.30927734375, 34.416796875],
              [135.384765625, 34.500439453125],
              [135.41181640625, 34.54697265625],
              [135.41591796875002, 34.617480468749996],
              [135.35517578125, 34.654296875],
              [135.1982421875, 34.6529296875],
              [135.04169921875, 34.631005859374994],
              [134.9298828125, 34.66181640625],
              [134.78496093750005, 34.7470703125],
              [134.74003906250005, 34.765234375],
              [134.58378906250005, 34.77060546875],
              [134.47226562500003, 34.754785156249994],
              [134.36269531250002, 34.723681640624996],
              [134.246875, 34.7138671875],
              [134.20830078125005, 34.69765625],
              [134.07451171875005, 34.593115234375],
              [133.96826171875, 34.527294921875],
              [133.87636718750002, 34.49462890625],
              [133.6779296875, 34.485888671874996],
              [133.57861328125, 34.464697265625],
              [133.47441406250005, 34.430126953125],
              [133.4453125, 34.433154296874996],
              [133.33564453125, 34.3853515625],
              [133.20976562500005, 34.343994140625],
              [133.14238281250005, 34.30244140625],
              [133.01894531250002, 34.32958984375],
              [132.774609375, 34.255224609375],
              [132.65654296875005, 34.24609375],
              [132.53447265625005, 34.287060546875],
              [132.42128906250002, 34.353369140625],
              [132.31259765625003, 34.324951171875],
              [132.23808593750005, 34.227001953125],
              [132.20195312500005, 34.03203125],
              [132.159375, 33.944238281249994],
              [132.146484375, 33.838769531249994],
              [132.09023437500002, 33.85546875],
              [131.76318359375, 34.045263671875],
              [131.74052734375005, 34.052050781249996],
              [131.47617187500003, 34.019384765625],
              [131.40791015625, 34.00361328125],
              [131.32275390625, 33.965185546875],
              [131.2326171875, 33.947998046875],
              [131.150390625, 33.975634765624996],
              [131.071875, 34.020654296874994],
              [130.99638671875005, 34.007275390625],
              [130.91884765625002, 33.975732421874994],
              [130.88925781250003, 34.26181640625],
              [130.904296875, 34.299560546875],
              [130.95185546875, 34.34970703125],
              [131.00419921875005, 34.392578125],
              [131.13222656250002, 34.407373046874994],
              [131.26181640625003, 34.393457031249994],
              [131.35439453125002, 34.41318359375],
              [131.43251953125002, 34.46982421875],
              [131.51503906250002, 34.550146484375],
              [131.60800781250003, 34.615478515625],
              [131.73408203125, 34.66708984375],
              [131.85605468750003, 34.726318359375],
              [131.9630859375, 34.809375],
              [132.06474609375005, 34.9],
              [132.158203125, 34.96650390625],
              [132.25957031250005, 35.022314453125],
              [132.4140625, 35.156298828124996],
              [132.61904296875002, 35.3068359375],
              [132.69765625000002, 35.418310546875],
              [132.74658203125, 35.449023437499996],
              [132.92294921875003, 35.511279296874996],
              [133.15693359375, 35.558837890625],
              [133.26718750000003, 35.556542968749994],
              [133.37646484375, 35.458837890625],
              [133.43535156250005, 35.472216796874996],
              [133.49492187500005, 35.497460937499994],
              [133.6154296875, 35.51142578125],
              [133.73935546875003, 35.495263671874994],
              [133.86025390625002, 35.494873046875],
              [133.98125, 35.507226562499994],
              [134.2140625, 35.5392578125],
              [134.33652343750003, 35.577929687499996],
              [134.4560546875, 35.6279296875],
              [134.88222656250002, 35.663232421874994],
              [135.17431640625, 35.7470703125],
              [135.22050781250005, 35.741113281249994],
              [135.26542968750005, 35.72177734375],
              [135.26875, 35.65966796875],
              [135.23203125000003, 35.59189453125],
              [135.2677734375, 35.55087890625],
              [135.32695312500005, 35.525537109374994],
              [135.60185546875005, 35.517724609374994],
              [135.68027343750003, 35.503125],
              [135.79501953125003, 35.54951171875],
              [135.903125, 35.606884765625],
              [136.01621093750003, 35.682519531249994],
              [136.09531250000003, 35.767626953124996],
              [136.02226562500005, 35.87412109375],
              [136.00625, 35.990576171875],
              [136.06748046875003, 36.116845703124994],
              [136.15625, 36.22333984375],
              [136.26181640625003, 36.2876953125],
              [136.35898437500003, 36.361767578125],
              [136.555859375, 36.57197265625],
              [136.69814453125002, 36.742041015625],
              [136.74931640625005, 36.951025390625],
              [136.71923828125, 37.198388671874994],
              [136.84345703125, 37.382128906249996],
              [136.96230468750002, 37.413671875],
              [137.19863281250002, 37.497460937499994],
              [137.32265625000002, 37.5220703125],
              [137.34121093750002, 37.48544921875],
              [137.3375, 37.437451171875],
              [137.15205078125, 37.283154296875],
              [137.04580078125002, 37.2197265625],
              [136.98222656250005, 37.200048828125],
              [136.9240234375, 37.17197265625],
              [136.89990234375, 37.11767578125],
              [136.99443359375005, 37.026757812499994],
              [137.0185546875, 36.959619140624994],
              [137.0126953125, 36.8951171875],
              [137.01669921875003, 36.837207031249996],
              [137.12373046875, 36.774072265624994],
              [137.2462890625, 36.753173828125],
              [137.29765625000005, 36.753759765625],
              [137.34257812500005, 36.770361328125],
              [137.4826171875, 36.924755859375],
              [137.51406250000002, 36.9515625],
              [137.91318359375003, 37.064599609374994],
              [138.1095703125, 37.15107421875],
              [138.21806640625005, 37.173388671874996],
              [138.31992187500003, 37.218408203124994],
              [138.54833984375, 37.392138671874996],
              [138.6328125, 37.47216796875],
              [138.709375, 37.56064453125],
              [138.77060546875003, 37.663427734375],
              [138.81884765625, 37.774707031249996],
              [138.88505859375005, 37.8439453125],
              [139.24716796875003, 38.009082031249996],
              [139.36386718750003, 38.099023437499994],
              [139.40097656250003, 38.142578125],
              [139.44580078125, 38.26875],
              [139.4767578125, 38.399804687499994],
              [139.52080078125005, 38.5025390625],
              [139.58017578125003, 38.598876953125],
              [139.65976562500003, 38.697021484375],
              [139.74912109375003, 38.788134765624996],
              [139.801953125, 38.881591796875],
              [139.87861328125, 39.104931640625],
              [139.9123046875, 39.228564453124996],
              [139.93857421875003, 39.27314453125],
              [139.97714843750003, 39.31064453125],
              [140.01083984375003, 39.358056640624994],
              [140.03652343750002, 39.4111328125],
              [140.04814453125005, 39.463720703125],
              [140.06474609375005, 39.624414062499994],
              [140.0546875, 39.749267578125],
              [139.99472656250003, 39.855078125],
              [139.94521484375002, 39.885107421875],
              [139.89121093750003, 39.886865234374994],
              [139.81035156250005, 39.877734375],
              [139.74150390625005, 39.920849609375],
              [139.75546875000003, 39.958935546875],
              [139.82568359375, 39.966015625],
              [139.87363281250003, 39.985693359375],
              [139.90800781250005, 40.021728515625],
              [139.97246093750005, 40.136962890625],
              [140.01113281250002, 40.2603515625],
              [140.01445312500005, 40.314892578125],
              [139.9640625, 40.414306640625],
              [139.92392578125003, 40.53388671875],
              [139.9228515625, 40.5984375],
              [139.96669921875002, 40.672753906249994],
              [140.029296875, 40.733154296875],
              [140.08535156250002, 40.747363281249996],
              [140.14609375000003, 40.7515625],
              [140.20126953125003, 40.77490234375],
              [140.25234375000002, 40.8087890625],
              [140.28125, 40.84609375],
              [140.32626953125003, 40.94765625],
              [140.34355468750005, 41.005664062499996],
              [140.31523437500005, 41.160888671875],
              [140.34443359375, 41.2033203125],
              [140.3859375, 41.229785156249996],
              [140.44130859375002, 41.20966796875],
              [140.498046875, 41.2056640625],
              [140.56416015625, 41.211816406249994],
              [140.62763671875, 41.19541015625],
              [140.6396484375, 41.155615234375],
              [140.67939453125, 40.89326171875],
              [140.70244140625005, 40.8578125],
              [140.74863281250003, 40.830322265625],
              [140.80078125, 40.834326171875],
              [140.84580078125003, 40.875146484374994],
              [140.876171875, 40.929541015625],
              [140.93603515625, 40.940771484375],
              [141.11855468750002, 40.882275390625],
              [141.18320312500003, 40.9240234375],
              [141.22539062500005, 40.9884765625],
              [141.26210937500002, 41.102685546874994],
              [141.24423828125003, 41.205615234374996],
              [141.200390625, 41.243603515625],
              [141.15507812500005, 41.23671875],
              [141.11503906250005, 41.20849609375],
              [141.07041015625003, 41.19306640625],
              [140.80058593750005, 41.138818359374994],
              [140.80185546875003, 41.253662109375],
              [140.8595703125, 41.425439453124994],
              [140.89150390625002, 41.479785156249996],
              [140.93691406250002, 41.50556640625],
              [141.05019531250002, 41.475732421874994],
              [141.10585937500002, 41.455859375],
              [141.22929687500005, 41.37265625],
            ],
          ],
          [
            [
              [124.29316406250001, 24.515917968750003],
              [124.23427734375002, 24.358056640624994],
              [124.18564453125003, 24.33505859374999],
              [124.1357421875, 24.347607421874997],
              [124.08476562499999, 24.435839843750003],
              [124.12041015624999, 24.469628906249994],
              [124.17021484374999, 24.451855468749997],
              [124.21054687500003, 24.458642578124994],
              [124.30195312500001, 24.587109375],
              [124.32402343749999, 24.566357421874997],
              [124.29316406250001, 24.515917968750003],
            ],
          ],
          [
            [
              [123.888671875, 24.28012695312499],
              [123.82558593750002, 24.266064453124997],
              [123.74980468749999, 24.28330078124999],
              [123.6806640625, 24.288037109374997],
              [123.67978515625003, 24.317773437499994],
              [123.75234375000002, 24.34848632812499],
              [123.75371093749999, 24.391308593749997],
              [123.771484375, 24.414453125],
              [123.93486328124999, 24.362011718749997],
              [123.928125, 24.323632812499994],
              [123.888671875, 24.28012695312499],
            ],
          ],
          [
            [
              [125.44414062499999, 24.7431640625],
              [125.359375, 24.717089843750003],
              [125.26894531250002, 24.73251953124999],
              [125.28359375000002, 24.87192382812499],
              [125.31494140625, 24.85239257812499],
              [125.33457031250003, 24.8046875],
              [125.40185546875, 24.77685546875],
              [125.44414062499999, 24.7431640625],
            ],
          ],
          [
            [
              [128.2587890625, 26.652783203124997],
              [128.1625, 26.60693359375],
              [128.126953125, 26.55224609375],
              [128.03789062500005, 26.53359375],
              [127.95126953125003, 26.456494140624997],
              [127.86708984375002, 26.44248046875],
              [127.86923828125003, 26.380566406249997],
              [127.90478515625, 26.328125],
              [127.84873046875003, 26.3189453125],
              [127.79013671875003, 26.255078125],
              [127.78554687500002, 26.208691406249997],
              [127.80644531250005, 26.171240234375],
              [127.80361328125002, 26.152539062499997],
              [127.72939453125002, 26.09716796875],
              [127.653125, 26.0947265625],
              [127.64970703125005, 26.154492187499997],
              [127.65488281250003, 26.199169921874997],
              [127.72705078125, 26.307910156249996],
              [127.72890625000002, 26.433935546875],
              [127.7958984375, 26.448535156249996],
              [127.82041015625003, 26.466064453125],
              [127.92597656250001, 26.555712890624996],
              [127.94550781250001, 26.593945312499997],
              [127.89082031250001, 26.631054687499997],
              [127.89482421875005, 26.674951171874994],
              [127.9072265625, 26.693603515625],
              [127.99433593750001, 26.679443359375],
              [128.02968750000002, 26.646875],
              [128.04677734375002, 26.643310546875],
              [128.09765625, 26.667773437499996],
              [128.12158203125, 26.71142578125],
              [128.21650390625, 26.796875],
              [128.2548828125, 26.881884765624996],
              [128.33164062500003, 26.812109375],
              [128.31093750000002, 26.720703125],
              [128.2587890625, 26.652783203124997],
            ],
          ],
          [
            [
              [128.99814453125003, 27.72080078125],
              [128.95625, 27.702490234375],
              [128.9, 27.727783203125],
              [128.8828125, 27.842431640624994],
              [128.90761718750002, 27.897998046874996],
              [128.95166015625, 27.91025390625],
              [128.98974609375, 27.8111328125],
              [129.01640625000005, 27.770214843749997],
              [128.99814453125003, 27.72080078125],
            ],
          ],
          [
            [
              [129.45253906250002, 28.208984375],
              [129.36640625, 28.127734375],
              [129.27490234375, 28.200878906249997],
              [129.16464843750003, 28.249755859375],
              [129.21708984375005, 28.262939453125],
              [129.24785156250005, 28.282519531249996],
              [129.25087890625002, 28.31357421875],
              [129.3224609375, 28.359619140625],
              [129.46455078125, 28.395263671875],
              [129.50966796875002, 28.397509765624996],
              [129.560546875, 28.431054687499994],
              [129.5771484375, 28.461279296875],
              [129.59804687500002, 28.475878906249996],
              [129.68955078125003, 28.517480468749994],
              [129.71464843750005, 28.469628906249994],
              [129.71044921875, 28.43212890625],
              [129.64169921875003, 28.411279296874994],
              [129.57460937500002, 28.361181640625],
              [129.5126953125, 28.298730468749994],
              [129.45673828125, 28.272314453125],
              [129.43906250000003, 28.254785156249994],
              [129.45253906250002, 28.208984375],
            ],
          ],
          [
            [
              [129.32402343750005, 28.104931640624997],
              [129.33056640625, 28.081591796874996],
              [129.232421875, 28.101123046874996],
              [129.19248046875003, 28.19248046875],
              [129.25742187500003, 28.176171875],
              [129.27734375, 28.144726562499997],
              [129.32402343750005, 28.104931640624997],
            ],
          ],
          [
            [
              [139.84111328125005, 33.056054687499994],
              [139.82382812500003, 33.045458984374996],
              [139.77568359375005, 33.07822265625],
              [139.76894531250002, 33.107177734375],
              [139.77744140625003, 33.125146484374994],
              [139.80888671875005, 33.129248046875],
              [139.87363281250003, 33.093505859375],
              [139.84111328125005, 33.056054687499994],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 2,
        sovereignt: "Philippines",
        sov_a3: "PHL",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Philippines",
        adm0_a3: "PHL",
        geou_dif: 0,
        geounit: "Philippines",
        gu_a3: "PHL",
        su_dif: 0,
        subunit: "Philippines",
        su_a3: "PHL",
        brk_diff: 0,
        name: "Philippines",
        name_long: "Philippines",
        brk_a3: "PHL",
        brk_name: "Philippines",
        brk_group: null,
        abbrev: "Phil.",
        postal: "PH",
        formal_en: "Republic of the Philippines",
        formal_fr: null,
        name_ciawf: "Philippines",
        note_adm0: null,
        note_brk: null,
        name_sort: "Philippines",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 2,
        mapcolor9: 2,
        mapcolor13: 8,
        pop_est: 108116615,
        pop_rank: 17,
        pop_year: 2019,
        gdp_md: 376795,
        gdp_year: 2019,
        economy: "5. Emerging region: G20",
        income_grp: "4. Lower middle income",
        fips_10: "RP",
        iso_a2: "PH",
        iso_a2_eh: "PH",
        iso_a3: "PHL",
        iso_a3_eh: "PHL",
        iso_n3: "608",
        iso_n3_eh: "608",
        un_a3: "608",
        wb_a2: "PH",
        wb_a3: "PHL",
        woe_id: 23424934,
        woe_id_eh: 23424934,
        woe_note: "Exact WOE match as country",
        adm0_iso: "PHL",
        adm0_diff: null,
        adm0_tlc: "PHL",
        adm0_a3_us: "PHL",
        adm0_a3_fr: "PHL",
        adm0_a3_ru: "PHL",
        adm0_a3_es: "PHL",
        adm0_a3_cn: "PHL",
        adm0_a3_tw: "PHL",
        adm0_a3_in: "PHL",
        adm0_a3_np: "PHL",
        adm0_a3_pk: "PHL",
        adm0_a3_de: "PHL",
        adm0_a3_gb: "PHL",
        adm0_a3_br: "PHL",
        adm0_a3_il: "PHL",
        adm0_a3_ps: "PHL",
        adm0_a3_sa: "PHL",
        adm0_a3_eg: "PHL",
        adm0_a3_ma: "PHL",
        adm0_a3_pt: "PHL",
        adm0_a3_ar: "PHL",
        adm0_a3_jp: "PHL",
        adm0_a3_ko: "PHL",
        adm0_a3_vn: "PHL",
        adm0_a3_tr: "PHL",
        adm0_a3_id: "PHL",
        adm0_a3_pl: "PHL",
        adm0_a3_gr: "PHL",
        adm0_a3_it: "PHL",
        adm0_a3_nl: "PHL",
        adm0_a3_se: "PHL",
        adm0_a3_bd: "PHL",
        adm0_a3_ua: "PHL",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "South-Eastern Asia",
        region_wb: "East Asia & Pacific",
        name_len: 11,
        long_len: 11,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 2.5,
        max_label: 7,
        label_x: 122.465,
        label_y: 11.198,
        ne_id: 1159321169,
        wikidataid: "Q928",
        name_ar: "الفلبين",
        name_bn: "ফিলিপাইন",
        name_de: "Philippinen",
        name_en: "Philippines",
        name_es: "Filipinas",
        name_fa: "فیلیپین",
        name_fr: "Philippines",
        name_el: "Φιλιππίνες",
        name_he: "הפיליפינים",
        name_hi: "फ़िलीपीन्स",
        name_hu: "Fülöp-szigetek",
        name_id: "Filipina",
        name_it: "Filippine",
        name_ja: "フィリピン",
        name_ko: "필리핀",
        name_nl: "Filipijnen",
        name_pl: "Filipiny",
        name_pt: "Filipinas",
        name_ru: "Филиппины",
        name_sv: "Filippinerna",
        name_tr: "Filipinler",
        name_uk: "Філіппіни",
        name_ur: "فلپائن",
        name_vi: "Philippines",
        name_zh: "菲律宾",
        name_zht: "菲律賓",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "PHL.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [121.1015625, 18.615283203125003],
              [121.25449218750003, 18.563427734374997],
              [121.59296875000001, 18.37646484375],
              [121.716796875, 18.330078125],
              [121.84560546875002, 18.29541015625],
              [121.94755859374999, 18.28515625],
              [122.03847656250002, 18.327929687500003],
              [122.07695312499999, 18.37167968749999],
              [122.14667968750001, 18.486572265625],
              [122.22119140625, 18.500634765624994],
              [122.26552734375002, 18.45883789062499],
              [122.2998046875, 18.402783203124997],
              [122.31503906250003, 18.3203125],
              [122.29384765625002, 18.234277343749994],
              [122.22285156250001, 18.157128906249994],
              [122.17949218749999, 18.064257812500003],
              [122.15097656250003, 17.756494140624994],
              [122.15234375, 17.66440429687499],
              [122.17519531250002, 17.57568359375],
              [122.23681640625, 17.434863281250003],
              [122.26904296875, 17.395263671875],
              [122.3623046875, 17.344873046874994],
              [122.3875, 17.306787109374994],
              [122.39287109374999, 17.23837890624999],
              [122.40751953124999, 17.178125],
              [122.46787109375003, 17.15512695312499],
              [122.51914062500003, 17.12485351562499],
              [122.5, 17.058007812499994],
              [122.46796875000001, 16.990039062500003],
              [122.42578125, 16.82265625],
              [122.22587890624999, 16.435205078124994],
              [122.21416015624999, 16.351513671874997],
              [122.13515625000002, 16.184814453125],
              [121.97470703125003, 16.157910156249997],
              [121.78867187500003, 16.077441406250003],
              [121.68515625000003, 16.01474609374999],
              [121.59531250000003, 15.933251953124994],
              [121.56093750000002, 15.826757812499991],
              [121.59042968750003, 15.778027343749997],
              [121.60917968749999, 15.726025390624997],
              [121.60703125000003, 15.669824218749994],
              [121.57919921875003, 15.623193359374994],
              [121.48984375000003, 15.509521484375],
              [121.45205078125002, 15.416650390624994],
              [121.41191406249999, 15.375048828125003],
              [121.39228515625001, 15.324414062499997],
              [121.39892578125, 15.2666015625],
              [121.43496093750002, 15.21630859375],
              [121.5439453125, 14.999169921874994],
              [121.66054687500002, 14.789501953124997],
              [121.68564453125003, 14.765429687500003],
              [121.69541015625003, 14.7373046875],
              [121.62656250000003, 14.681738281249991],
              [121.6279296875, 14.581152343749991],
              [121.64853515625003, 14.481494140625003],
              [121.75185546875002, 14.234179687500003],
              [121.7666015625, 14.168066406249991],
              [121.80048828125001, 14.113867187499991],
              [121.85332031249999, 14.063085937499991],
              [121.91171875000003, 14.020410156249994],
              [122.07958984375, 13.947119140624991],
              [122.14433593749999, 13.932714843749991],
              [122.21171874999999, 13.93017578125],
              [122.22841796875002, 13.9794921875],
              [122.2875, 13.996191406249991],
              [122.2744140625, 14.044726562500003],
              [122.20253906250002, 14.111669921874991],
              [122.19970703125, 14.148046875],
              [122.2375, 14.175048828125],
              [122.28261718750002, 14.190820312499994],
              [122.38369140625002, 14.263867187499997],
              [122.49082031250003, 14.322363281249991],
              [122.62714843750001, 14.317529296874994],
              [122.76103515624999, 14.284863281249997],
              [122.85605468750003, 14.25078125],
              [122.93417968750003, 14.188085937499991],
              [123.01455078125002, 14.079833984375],
              [123.07099609375001, 13.9599609375],
              [123.07070312500002, 13.902734375],
              [123.05693359374999, 13.845458984375],
              [123.05996093750002, 13.788769531249997],
              [123.10195312500002, 13.750244140625],
              [123.2314453125, 13.747363281250003],
              [123.29697265625003, 13.83642578125],
              [123.30537109375001, 13.936572265625003],
              [123.25927734375, 13.975439453124991],
              [123.28046875000001, 14.024804687499994],
              [123.3203125, 14.061669921874994],
              [123.37744140625, 14.028662109374991],
              [123.43232421875001, 13.966259765624997],
              [123.6328125, 13.898486328125003],
              [123.68408203125, 13.897021484375003],
              [123.72597656250002, 13.884326171875003],
              [123.81572265624999, 13.837109375],
              [123.85761718750001, 13.799609375],
              [123.80625, 13.721728515625003],
              [123.60712890625001, 13.704443359374991],
              [123.54960937499999, 13.645751953125],
              [123.60810546875001, 13.528076171875],
              [123.70361328125, 13.431591796874997],
              [123.76484375000001, 13.353515625],
              [123.81923828125002, 13.269482421874997],
              [123.81660156250001, 13.191601562499997],
              [123.78515625, 13.110546875],
              [123.87275390625001, 13.116992187499989],
              [123.95517578125003, 13.099707031249991],
              [124.06914062499999, 13.031933593749997],
              [124.10458984375003, 13.025],
              [124.14277343750001, 13.035791015624994],
              [124.13730468750003, 12.791162109374994],
              [124.05976562500001, 12.567089843749997],
              [123.96171874999999, 12.594970703125],
              [123.87783203125002, 12.689697265625],
              [123.89492187500002, 12.804980468749989],
              [123.94853515624999, 12.91640625],
              [123.91796875, 12.93994140625],
              [123.86386718750003, 12.9306640625],
              [123.80234375000003, 12.905566406249989],
              [123.73603515625001, 12.896923828124997],
              [123.62675781249999, 12.911767578124994],
              [123.40234375, 13.033105468749994],
              [123.31093750000002, 13.044091796874994],
              [123.29042968750002, 13.099023437499994],
              [123.29550781250003, 13.215576171875],
              [123.20595703125002, 13.353515625],
              [123.19160156250001, 13.402880859375003],
              [123.16328125000001, 13.441748046874991],
              [122.89619140625001, 13.591943359374994],
              [122.86347656250001, 13.617236328125003],
              [122.78134765625003, 13.737060546875],
              [122.59521484375, 13.907617187499994],
              [122.54306640625003, 13.925048828125],
              [122.486328125, 13.929980468750003],
              [122.46796875000001, 13.88671875],
              [122.49375, 13.820214843749994],
              [122.50419921874999, 13.763085937499994],
              [122.50019531250001, 13.703173828125003],
              [122.50800781250001, 13.656835937499991],
              [122.59619140625, 13.56201171875],
              [122.609375, 13.517138671875003],
              [122.66787109375002, 13.395361328124991],
              [122.67509765624999, 13.253173828125],
              [122.59990234374999, 13.194140624999989],
              [122.51523437500003, 13.260009765625],
              [122.5125, 13.313623046874994],
              [122.49794921875002, 13.363525390625],
              [122.40693359375001, 13.492773437499991],
              [122.37656250000003, 13.520605468749991],
              [122.20527343750001, 13.648242187500003],
              [122.07275390625, 13.788378906250003],
              [121.77792968750003, 13.937646484374994],
              [121.74287109375001, 13.945849609375003],
              [121.69169921874999, 13.9345703125],
              [121.64345703125002, 13.915966796874997],
              [121.50107421875003, 13.8421875],
              [121.45078125000003, 13.790771484375],
              [121.4462890625, 13.711865234374997],
              [121.34414062500002, 13.649121093749997],
              [121.20351562500002, 13.640283203124994],
              [121.09550781249999, 13.679492187500003],
              [121.00615234374999, 13.758105468750003],
              [120.93232421875001, 13.761865234374994],
              [120.84072265625002, 13.884716796874997],
              [120.72910156250003, 13.900537109374994],
              [120.63710937500002, 13.804492187500003],
              [120.61738281250001, 13.9953125],
              [120.61679687500003, 14.188037109375003],
              [120.64267578125003, 14.244335937499997],
              [120.68828124999999, 14.291210937499997],
              [120.92207031250001, 14.493115234374997],
              [120.95156250000002, 14.557958984374991],
              [120.94130859375002, 14.645068359375003],
              [120.88808593750002, 14.715771484374997],
              [120.80449218749999, 14.7587890625],
              [120.70791015625002, 14.776611328125],
              [120.63828125000003, 14.816162109375],
              [120.58369140625001, 14.88125],
              [120.54677734375002, 14.76611328125],
              [120.58271484375001, 14.594628906249994],
              [120.58867187499999, 14.483105468749997],
              [120.5556640625, 14.441357421874997],
              [120.49570312500003, 14.440185546875],
              [120.43876953124999, 14.453369140625],
              [120.39609375000003, 14.493310546874994],
              [120.365234375, 14.608300781249994],
              [120.28388671875001, 14.684375],
              [120.25078124999999, 14.793310546874991],
              [120.2138671875, 14.808789062499997],
              [120.13798828124999, 14.800390625],
              [120.08212890625003, 14.85107421875],
              [120.04453125000003, 14.978125],
              [120.03662109375, 15.114550781250003],
              [120.00498046875003, 15.229248046875],
              [119.959375, 15.340234375],
              [119.93281250000001, 15.430908203125],
              [119.8916015625, 15.837695312500003],
              [119.88144531250003, 15.875],
              [119.85966796874999, 15.90576171875],
              [119.80820312500003, 15.951953125],
              [119.76894531250002, 16.008447265624994],
              [119.76181640625003, 16.054980468750003],
              [119.77255859375003, 16.255126953125],
              [119.79023437500001, 16.30332031249999],
              [119.83076171875001, 16.3265625],
              [119.88613281250002, 16.287402343750003],
              [119.93037109375001, 16.23876953125],
              [119.98515624999999, 16.21542968749999],
              [120.03339843750001, 16.1845703125],
              [120.1240234375, 16.066210937500003],
              [120.15976562500003, 16.04765625],
              [120.27128906249999, 16.051416015624994],
              [120.33701171875003, 16.066455078125003],
              [120.36875, 16.109570312499997],
              [120.38876953125003, 16.1609375],
              [120.38925781250003, 16.221630859374997],
              [120.325, 16.400341796874997],
              [120.30527343750003, 16.529248046874997],
              [120.30439453125001, 16.645458984374997],
              [120.32119140625002, 16.761865234374994],
              [120.40888671875001, 16.955615234375003],
              [120.42011718750001, 17.090087890625],
              [120.41171875000003, 17.269921875],
              [120.42714843750002, 17.376904296874997],
              [120.42451171875001, 17.43833007812499],
              [120.3720703125, 17.535107421874997],
              [120.3583984375, 17.63818359375],
              [120.50507812500001, 18.162646484375003],
              [120.55097656250001, 18.2640625],
              [120.58447265625, 18.36875],
              [120.59970703125003, 18.507861328125003],
              [120.709375, 18.545947265625003],
              [120.81376953124999, 18.603417968749994],
              [120.86777343750003, 18.598925781250003],
              [120.925, 18.585107421874994],
              [121.05136718750003, 18.613671875],
              [121.1015625, 18.615283203125003],
            ],
          ],
          [
            [
              [117.31113281250003, 8.439599609374994],
              [117.21855468749999, 8.367285156249991],
              [117.228515625, 8.456689453124994],
              [117.255859375, 8.540966796874997],
              [117.34990234374999, 8.713574218749997],
              [117.41777343749999, 8.766650390624989],
              [117.52998046875001, 8.902587890625],
              [117.59326171875, 8.968310546874989],
              [117.74492187499999, 9.098242187499991],
              [117.884765625, 9.240673828124997],
              [117.93154296875002, 9.251269531249989],
              [117.98300781250003, 9.25341796875],
              [118.02382812500002, 9.269775390625],
              [118.11484375000003, 9.3466796875],
              [118.34394531250001, 9.602783203125],
              [118.53339843750001, 9.793652343749997],
              [118.7275390625, 10.035009765624991],
              [118.82011718749999, 10.105322265624991],
              [118.84511718750002, 10.131298828124997],
              [119.02382812500002, 10.353564453124989],
              [119.07988281249999, 10.385839843749991],
              [119.14306640625, 10.409277343749991],
              [119.18603515625, 10.439453125],
              [119.22382812500001, 10.477294921875],
              [119.28701171875002, 10.574023437499989],
              [119.31269531250001, 10.687109375],
              [119.29667968749999, 10.7509765625],
              [119.26113281250002, 10.845166015624997],
              [119.3056640625, 10.9736328125],
              [119.34072265625002, 11.032910156249997],
              [119.46533203125, 11.293798828124991],
              [119.50126953124999, 11.346435546875],
              [119.55332031250003, 11.313525390624989],
              [119.56025390625001, 11.266796875],
              [119.53457031250002, 11.156835937499991],
              [119.53261718750002, 11.101611328124989],
              [119.56191406250002, 11.045507812499991],
              [119.52666015624999, 10.953173828124989],
              [119.61611328125002, 10.707373046874991],
              [119.684375, 10.551708984374997],
              [119.68691406250002, 10.500341796874991],
              [119.59521484375, 10.407421875],
              [119.54052734375, 10.379345703124997],
              [119.42246093750003, 10.354394531249994],
              [119.36933593750001, 10.327294921874994],
              [119.28476562500003, 10.251708984375],
              [119.23193359375, 10.152148437499989],
              [119.21855468749999, 10.100683593749991],
              [119.19150390625003, 10.061083984374989],
              [118.94863281250002, 9.993457031249989],
              [118.83466796875001, 9.949316406249991],
              [118.78212890625002, 9.916113281249991],
              [118.75498046875003, 9.862109374999989],
              [118.77382812500002, 9.766796875],
              [118.56962890624999, 9.422753906249994],
              [118.50449218750003, 9.332666015624994],
              [118.43496093750002, 9.256005859374994],
              [118.349609375, 9.201464843749989],
              [118.22929687499999, 9.16796875],
              [118.13408203124999, 9.101367187499989],
              [118.06943359375003, 8.983544921874994],
              [117.98955078124999, 8.877099609374994],
              [117.88857421875002, 8.798242187499994],
              [117.77978515625, 8.728613281249991],
              [117.67988281250001, 8.677832031249991],
              [117.57216796875002, 8.641992187499994],
              [117.53964843750003, 8.595605468749994],
              [117.5166015625, 8.538330078125],
              [117.46914062500002, 8.511376953124994],
              [117.4125, 8.495849609375],
              [117.31113281250003, 8.439599609374994],
            ],
          ],
          [
            [
              [122.49619140625003, 11.615087890624991],
              [122.61269531250002, 11.564160156249997],
              [122.72626953125001, 11.60791015625],
              [122.83808593750001, 11.595654296874997],
              [122.93125, 11.529296875],
              [122.90078125000002, 11.487353515624989],
              [122.89453125, 11.441308593749994],
              [123.10273437500001, 11.541455078124997],
              [123.15830078125003, 11.535546875],
              [123.15644531250001, 11.442529296874994],
              [123.14414062500003, 11.363574218749989],
              [123.11953125000002, 11.286816406249997],
              [123.07548828124999, 11.196875],
              [123.01650390625002, 11.116503906249989],
              [122.93876953124999, 11.058154296874989],
              [122.8466796875, 11.0224609375],
              [122.80292968750001, 10.990039062499989],
              [122.78945312500002, 10.941210937499989],
              [122.79111328125003, 10.879736328124991],
              [122.76992187500002, 10.823828125],
              [122.67314453124999, 10.800927734374994],
              [122.52207031250003, 10.69189453125],
              [122.19765625000002, 10.622900390624991],
              [122.10859375000001, 10.575537109374991],
              [122.0517578125, 10.5140625],
              [121.98837890625003, 10.458300781249989],
              [121.95400390625002, 10.444384765624989],
              [121.93828124999999, 10.470898437499997],
              [121.93378906250001, 10.49365234375],
              [121.98007812500003, 10.638574218749994],
              [121.97236328125001, 10.698876953124994],
              [121.95029296875003, 10.757373046874989],
              [121.96435546875, 10.871679687499991],
              [122.02070312500001, 10.979101562499991],
              [122.05087890625003, 11.097363281249997],
              [122.05966796875003, 11.32568359375],
              [122.103515625, 11.642919921874991],
              [122.10136718749999, 11.680859375],
              [122.06699218750003, 11.723730468749991],
              [121.94082031250002, 11.75830078125],
              [121.89121093750003, 11.790869140624991],
              [121.916015625, 11.854345703124991],
              [121.96367187499999, 11.897363281249994],
              [122.02919921875002, 11.895410156249994],
              [122.08681640625002, 11.855078125],
              [122.29072265625001, 11.772021484374989],
              [122.39921874999999, 11.702197265624989],
              [122.49619140625003, 11.615087890624991],
            ],
          ],
          [
            [
              [123.130859375, 9.064111328124994],
              [123.06464843750001, 9.053369140624994],
              [122.99472656250003, 9.058837890625],
              [122.94785156250003, 9.107958984374989],
              [122.86660156250002, 9.31982421875],
              [122.7724609375, 9.371337890625],
              [122.66455078125, 9.410351562499997],
              [122.6103515625, 9.443212890624991],
              [122.5625, 9.4828125],
              [122.41093749999999, 9.693896484374989],
              [122.39951171875003, 9.823046874999989],
              [122.42558593749999, 9.89609375],
              [122.47148437499999, 9.961523437499991],
              [122.52324218749999, 9.979199218749997],
              [122.64824218749999, 9.981542968749991],
              [122.71298828125003, 9.990136718749994],
              [122.85556640625003, 10.0869140625],
              [122.86582031250003, 10.125],
              [122.86650390624999, 10.284033203124991],
              [122.85234374999999, 10.395263671875],
              [122.81699218750003, 10.503808593749994],
              [122.85556640625003, 10.553417968749997],
              [122.90585937500003, 10.6025390625],
              [122.95839843750002, 10.698339843749991],
              [122.96875, 10.765722656249991],
              [122.9697265625, 10.836181640625],
              [122.98330078125002, 10.886621093749994],
              [123.0244140625, 10.911816406249997],
              [123.22177734375003, 10.988671875],
              [123.25664062499999, 10.993945312499989],
              [123.51064453125002, 10.923046875],
              [123.5625, 10.816064453124994],
              [123.56757812500001, 10.78076171875],
              [123.52773437500002, 10.662011718749994],
              [123.49287109375001, 10.582324218749989],
              [123.40693359375001, 10.458984375],
              [123.34355468749999, 10.325390625],
              [123.29609375000001, 10.12451171875],
              [123.26621093750003, 10.059033203124997],
              [123.18662109375003, 9.933300781249997],
              [123.16201171875002, 9.8642578125],
              [123.16269531250003, 9.714648437499989],
              [123.1494140625, 9.659326171874994],
              [123.14980468750002, 9.606152343749997],
              [123.30839843749999, 9.356982421874989],
              [123.321875, 9.317480468749991],
              [123.32050781250001, 9.27294921875],
              [123.29335937500002, 9.21728515625],
              [123.22871093750001, 9.121386718749989],
              [123.19248046875003, 9.087890625],
              [123.130859375, 9.064111328124994],
            ],
          ],
          [
            [
              [124.57460937500002, 11.343066406249989],
              [124.64433593749999, 11.30810546875],
              [124.72431640625001, 11.322070312499989],
              [124.82109374999999, 11.401416015624989],
              [124.92998046874999, 11.372851562499989],
              [124.99394531249999, 11.255908203124989],
              [125.02656250000001, 11.211718749999989],
              [125.04433593750002, 11.13525390625],
              [125.03974609375001, 10.951904296875],
              [125.01318359375, 10.785693359374989],
              [125.03378906250003, 10.75146484375],
              [125.08388671875002, 10.721582031249994],
              [125.12753906250003, 10.684716796874994],
              [125.16416015625003, 10.637451171875],
              [125.18769531250001, 10.584863281249994],
              [125.19716796875002, 10.457226562499997],
              [125.26005859374999, 10.349609375],
              [125.26845703125002, 10.307714843749991],
              [125.25332031250002, 10.263818359374994],
              [125.1484375, 10.272412109374997],
              [125.14003906250002, 10.2353515625],
              [125.142578125, 10.189453125],
              [125.10537109375002, 10.218310546874989],
              [125.0439453125, 10.3234375],
              [124.9875, 10.367578125],
              [125.0048828125, 10.197070312499989],
              [125.02353515625003, 10.115283203124989],
              [125.02656250000001, 10.033105468749994],
              [124.92910156250002, 10.095898437499997],
              [124.81279296874999, 10.134619140624991],
              [124.78076171875, 10.168066406249991],
              [124.79169921875001, 10.274560546874994],
              [124.78955078125, 10.327539062499994],
              [124.73769531250002, 10.439746093749989],
              [124.79863281249999, 10.682226562499991],
              [124.79716796874999, 10.731787109374991],
              [124.78671875000003, 10.781396484374994],
              [124.73867187500002, 10.879736328124991],
              [124.66269531250003, 10.961962890624989],
              [124.61611328125002, 10.962207031249989],
              [124.50283203125002, 10.904443359374994],
              [124.44550781250001, 10.923583984375],
              [124.41171875000003, 11.150341796874997],
              [124.36601562499999, 11.370703125],
              [124.33095703125002, 11.427099609374991],
              [124.30820312500003, 11.486181640624991],
              [124.33066406250003, 11.535205078124989],
              [124.37412109375003, 11.514990234374991],
              [124.43593750000002, 11.457226562499997],
              [124.51093750000001, 11.423876953124989],
              [124.54824218750002, 11.39501953125],
              [124.57460937500002, 11.343066406249989],
            ],
          ],
          [
            [
              [125.23955078124999, 12.527880859374989],
              [125.31035156249999, 12.4462890625],
              [125.32753906250002, 12.38720703125],
              [125.32021484375002, 12.321826171874989],
              [125.35224609375001, 12.292773437499989],
              [125.40878906250003, 12.284863281249997],
              [125.48125, 12.251953125],
              [125.53564453125, 12.19140625],
              [125.50332031250002, 12.135791015624989],
              [125.51337890625001, 12.054589843749994],
              [125.45654296875, 11.952539062499994],
              [125.46425781250002, 11.771582031249991],
              [125.496875, 11.713769531249994],
              [125.5, 11.655419921874994],
              [125.49179687500003, 11.594335937499991],
              [125.50576171875002, 11.544238281249989],
              [125.59296875000001, 11.378222656249989],
              [125.60898437500003, 11.323046874999989],
              [125.58232421874999, 11.279492187499997],
              [125.57353515624999, 11.238232421874997],
              [125.62734375000002, 11.23388671875],
              [125.70400390625002, 11.164794921875],
              [125.74912109375003, 11.073583984374991],
              [125.73564453124999, 11.049609374999989],
              [125.67441406250003, 11.120800781249997],
              [125.628125, 11.13203125],
              [125.43183593750001, 11.112597656249989],
              [125.3115234375, 11.142285156249997],
              [125.2333984375, 11.145068359374989],
              [125.15585937500003, 11.267041015624997],
              [125.087890625, 11.287353515625],
              [125.03427734375003, 11.341259765624997],
              [124.9453125, 11.479150390624994],
              [124.9169921875, 11.558398437499989],
              [124.97890625000002, 11.638476562499989],
              [124.99824218750001, 11.70234375],
              [124.99501953125002, 11.764941406249989],
              [124.93564453125003, 11.754638671875],
              [124.88427734375, 11.775488281249991],
              [124.82109374999999, 11.852099609374989],
              [124.79580078125002, 11.896337890624991],
              [124.74980468749999, 11.933349609375],
              [124.6767578125, 12.020898437499994],
              [124.571875, 12.055126953124997],
              [124.52910156249999, 12.079199218749991],
              [124.44570312500002, 12.152783203124997],
              [124.38486328125003, 12.243994140624991],
              [124.32578125000003, 12.40380859375],
              [124.29472656249999, 12.5693359375],
              [124.56582031250002, 12.526220703124991],
              [124.84013671874999, 12.534570312499994],
              [125.15019531249999, 12.572558593749989],
              [125.23955078124999, 12.527880859374989],
            ],
          ],
          [
            [
              [120.70439453124999, 13.4794921875],
              [120.75537109375, 13.470996093750003],
              [120.91533203124999, 13.501074218749991],
              [120.98076171874999, 13.485986328124994],
              [121.02470703124999, 13.4287109375],
              [121.07929687500001, 13.410742187499991],
              [121.12246093750002, 13.38125],
              [121.20273437500003, 13.432324218749997],
              [121.284375, 13.374121093749991],
              [121.35683593750002, 13.265478515624991],
              [121.44218749999999, 13.188427734374997],
              [121.52275390624999, 13.131201171874991],
              [121.53867187500003, 13.0888671875],
              [121.48974609375, 13.019580078124989],
              [121.47480468750001, 12.931591796874997],
              [121.47968750000001, 12.837109375],
              [121.540625, 12.63818359375],
              [121.51923828125001, 12.584228515625],
              [121.4580078125, 12.507958984374994],
              [121.41230468750001, 12.423046875],
              [121.41816406250001, 12.388769531249991],
              [121.40009765625001, 12.360742187499994],
              [121.39433593749999, 12.300585937499989],
              [121.35683593750002, 12.313085937499991],
              [121.32236328125003, 12.303613281249994],
              [121.28886718749999, 12.276708984374991],
              [121.23671875000002, 12.218798828124989],
              [121.15546875000001, 12.236328125],
              [121.11699218749999, 12.25341796875],
              [121.10761718750001, 12.303613281249994],
              [121.08339843750002, 12.338964843749991],
              [121.04853515625001, 12.359960937499991],
              [120.9625, 12.446533203125],
              [120.92216796874999, 12.511621093749994],
              [120.92148437500003, 12.581103515624989],
              [120.8994140625, 12.645849609374991],
              [120.85478515624999, 12.703662109374989],
              [120.79599609375003, 12.747998046874997],
              [120.7763671875, 12.790576171874989],
              [120.76875, 12.840917968749991],
              [120.763671875, 12.969824218749991],
              [120.68027343750003, 13.130615234375],
              [120.6513671875, 13.169140625],
              [120.57314453125002, 13.208886718749994],
              [120.50830078125, 13.260058593750003],
              [120.48066406250001, 13.31103515625],
              [120.45546875000002, 13.393505859374997],
              [120.43808593750003, 13.405419921874994],
              [120.3875, 13.401660156250003],
              [120.33847656250003, 13.412353515625],
              [120.35273437500001, 13.472949218750003],
              [120.40126953125002, 13.517041015624997],
              [120.46835937500003, 13.522412109374997],
              [120.6533203125, 13.497607421875003],
              [120.70439453124999, 13.4794921875],
            ],
          ],
          [
            [
              [126.00595703125003, 9.320947265624994],
              [126.08759765625001, 9.2607421875],
              [126.193359375, 9.276708984374991],
              [126.19199218750003, 9.124902343749994],
              [126.20908203125003, 9.08056640625],
              [126.30458984375002, 8.952050781249994],
              [126.31953125000001, 8.8447265625],
              [126.26298828124999, 8.743945312499989],
              [126.22021484375, 8.6962890625],
              [126.1416015625, 8.627294921874991],
              [126.13955078125002, 8.595654296874997],
              [126.17304687500001, 8.56005859375],
              [126.28232421875003, 8.539306640625],
              [126.36533203125003, 8.48388671875],
              [126.37978515625002, 8.326757812499991],
              [126.45869140625001, 8.202832031249997],
              [126.45664062500003, 8.148779296874991],
              [126.42529296875, 7.927441406249997],
              [126.43535156249999, 7.832812499999989],
              [126.49443359374999, 7.756982421874994],
              [126.54443359375, 7.724804687499997],
              [126.57011718749999, 7.67724609375],
              [126.59335937500003, 7.546777343749994],
              [126.58925781250002, 7.325146484374997],
              [126.58154296875, 7.247753906249997],
              [126.54667968749999, 7.175830078124989],
              [126.43906250000003, 7.012353515624994],
              [126.29404296875003, 6.88232421875],
              [126.21689453125003, 6.891015625],
              [126.19208984375001, 6.8525390625],
              [126.240234375, 6.73388671875],
              [126.22119140625, 6.4833984375],
              [126.18935546875002, 6.309667968749991],
              [126.14248046875002, 6.397558593749991],
              [126.10976562500002, 6.489648437499994],
              [126.080078125, 6.733349609374997],
              [126.04306640625003, 6.843164062499994],
              [125.98496093750003, 6.943554687499997],
              [125.96162109375001, 7.033203125],
              [125.90117187499999, 7.116992187499989],
              [125.82441406250001, 7.333300781249989],
              [125.77363281250001, 7.322167968749994],
              [125.68925781249999, 7.263037109374991],
              [125.67021484374999, 7.222314453124994],
              [125.66025390625003, 7.160595703124997],
              [125.64072265625003, 7.105078125],
              [125.54218750000001, 7.0166015625],
              [125.46474609375002, 6.9111328125],
              [125.40097656250003, 6.795751953124991],
              [125.38066406249999, 6.68994140625],
              [125.43291015624999, 6.607128906249997],
              [125.48662109374999, 6.57373046875],
              [125.56455078125003, 6.499609375],
              [125.58847656250003, 6.465771484374997],
              [125.67070312499999, 6.225],
              [125.66796875, 5.978662109374994],
              [125.60781250000002, 5.870166015624989],
              [125.45585937499999, 5.664257812499997],
              [125.34648437499999, 5.598974609374991],
              [125.28789062499999, 5.632275390624997],
              [125.24101562499999, 5.756933593749991],
              [125.23320312499999, 5.808300781249997],
              [125.26494140624999, 5.925585937499989],
              [125.26845703125002, 6.033154296874997],
              [125.23154296875003, 6.06953125],
              [125.19101562500003, 6.0625],
              [125.17402343750001, 6.046972656249991],
              [125.076171875, 5.90625],
              [125.03535156250001, 5.870654296874989],
              [124.97519531250003, 5.86572265625],
              [124.92734375000003, 5.875341796874991],
              [124.63632812500003, 5.998193359374994],
              [124.39882812500002, 6.119726562499991],
              [124.21279296875002, 6.233251953124991],
              [124.078125, 6.404443359374994],
              [124.04970703125002, 6.532568359374991],
              [124.04814453124999, 6.666552734374989],
              [123.98789062500003, 6.862988281249997],
              [123.98085937500002, 6.9296875],
              [123.98525390625002, 6.993701171874989],
              [124.04511718750001, 7.114111328124991],
              [124.11757812500002, 7.175097656249989],
              [124.158203125, 7.218798828124989],
              [124.19072265624999, 7.267333984375],
              [124.212890625, 7.332128906249991],
              [124.20664062500003, 7.396435546874997],
              [124.18242187499999, 7.43671875],
              [124.06796875000003, 7.577880859375],
              [123.96845703125001, 7.664648437499991],
              [123.76474609375003, 7.742626953124997],
              [123.71738281250003, 7.785400390625],
              [123.66582031249999, 7.817773437499994],
              [123.60888671875, 7.831640625],
              [123.55322265625, 7.832128906249991],
              [123.49306640625002, 7.807910156249989],
              [123.47744140625002, 7.75634765625],
              [123.48164062500001, 7.710253906249989],
              [123.47636718749999, 7.665380859374991],
              [123.39091796874999, 7.407519531249989],
              [123.28203124999999, 7.464111328125],
              [123.17822265625, 7.529443359374994],
              [123.15068359374999, 7.5751953125],
              [123.13876953125003, 7.629931640624989],
              [123.12119140625003, 7.666894531249994],
              [123.0966796875, 7.700439453125],
              [123.04892578125003, 7.614355468749991],
              [122.98955078124999, 7.546289062499994],
              [122.91689453125002, 7.530517578125],
              [122.84296875000001, 7.529296875],
              [122.81875, 7.558496093749994],
              [122.79179687499999, 7.722460937499989],
              [122.71396484375003, 7.774121093749997],
              [122.6162109375, 7.763134765624997],
              [122.49794921875002, 7.672753906249994],
              [122.47441406249999, 7.638964843749989],
              [122.44863281250002, 7.561132812499991],
              [122.31972656250002, 7.340234375],
              [122.25146484375, 7.170019531249991],
              [122.17617187500002, 7.004199218749989],
              [122.14248046875002, 6.949658203124997],
              [122.09814453125, 6.913720703124994],
              [122.02763671874999, 6.928613281249994],
              [121.96425781250002, 6.968212890624997],
              [121.90419921875002, 7.0751953125],
              [121.92460937499999, 7.199511718749989],
              [121.99111328125002, 7.278759765624997],
              [122.04716796874999, 7.363574218749989],
              [122.11484375000003, 7.659912109375],
              [122.11992187499999, 7.765380859375],
              [122.1318359375, 7.810498046874997],
              [122.24335937500001, 7.945117187499989],
              [122.33710937500001, 8.028417968749991],
              [122.38671875, 8.0458984375],
              [122.58945312500003, 8.093310546874989],
              [122.67294921875003, 8.133105468749989],
              [122.80439453125001, 8.133691406249994],
              [122.9111328125, 8.156445312499997],
              [122.99628906250001, 8.220507812499989],
              [123.00273437499999, 8.286914062499989],
              [122.99882812499999, 8.356054687499991],
              [123.017578125, 8.398339843749994],
              [123.05058593749999, 8.433935546874991],
              [123.09589843750001, 8.480810546874991],
              [123.14716796875001, 8.516015625],
              [123.29287109375002, 8.541455078124997],
              [123.34121093750002, 8.570410156249991],
              [123.38017578124999, 8.615625],
              [123.4345703125, 8.703320312499997],
              [123.49892578125002, 8.681542968749994],
              [123.56367187500001, 8.6474609375],
              [123.68007812500002, 8.62060546875],
              [123.78339843750001, 8.547705078124991],
              [123.84921875000003, 8.432714843749991],
              [123.86054687500001, 8.376074218749991],
              [123.87744140625, 8.188818359374991],
              [123.85341796875002, 8.145117187499991],
              [123.753125, 8.058251953124994],
              [123.79941406250003, 8.049121093749989],
              [123.93115234375, 8.12841796875],
              [123.996875, 8.158984374999989],
              [124.159375, 8.201464843749989],
              [124.19765625000002, 8.229541015624989],
              [124.22578125000001, 8.271386718749994],
              [124.283203125, 8.385986328125],
              [124.3251953125, 8.508447265624994],
              [124.35791015625, 8.559423828124991],
              [124.40488281250003, 8.599853515625],
              [124.45126953125003, 8.606347656249994],
              [124.62177734375001, 8.52265625],
              [124.73115234375001, 8.56298828125],
              [124.76171875, 8.689794921874991],
              [124.78681640625001, 8.874121093749991],
              [124.80615234375, 8.924023437499997],
              [124.86894531249999, 8.972265625],
              [124.94384765625, 8.956689453124994],
              [125.04638671875, 8.890527343749994],
              [125.14101562500002, 8.86875],
              [125.17617187500002, 8.922070312499997],
              [125.20966796875001, 9.027148437499989],
              [125.24785156249999, 9.0265625],
              [125.37558593750003, 8.991796875],
              [125.49873046875001, 9.014746093749991],
              [125.53339843750001, 9.140917968749989],
              [125.51015625000002, 9.27587890625],
              [125.41396484375002, 9.669189453125],
              [125.47128906250003, 9.756787109374997],
              [125.52089843750002, 9.759130859374991],
              [125.64248046875002, 9.654492187499997],
              [125.87666015625001, 9.513134765624997],
              [125.95468750000003, 9.426660156249994],
              [126.00595703125003, 9.320947265624994],
            ],
          ],
          [
            [
              [123.37031250000001, 9.449609375],
              [123.33173828125001, 9.422949218749991],
              [123.31601562500003, 9.488964843749997],
              [123.32705078125002, 9.578076171874997],
              [123.40371093750002, 9.889257812499991],
              [123.38623046875, 9.967089843749989],
              [123.51435546875001, 10.140332031249997],
              [123.59287109375003, 10.302929687499997],
              [123.71142578125, 10.473681640624989],
              [123.72646484375002, 10.562207031249997],
              [123.83154296875, 10.731005859374989],
              [123.92988281250001, 10.963818359374997],
              [123.92460937499999, 11.040917968749994],
              [123.95009765625002, 11.079150390624989],
              [123.96406250000001, 11.137451171875],
              [123.96718750000002, 11.186914062499994],
              [124.03886718749999, 11.273535156249991],
              [124.05791015624999, 11.217236328124997],
              [124.03652343750002, 11.106689453125],
              [124.03984374999999, 11.053613281249994],
              [124.05253906249999, 11.028759765624997],
              [124.05332031250003, 10.92578125],
              [124.02753906250001, 10.767871093749989],
              [124.05126953125, 10.585595703124994],
              [124.00498046875003, 10.400097656249997],
              [123.9521484375, 10.316601562499997],
              [123.87392578125002, 10.257714843749994],
              [123.78867187500003, 10.220800781249991],
              [123.70048828124999, 10.128320312499994],
              [123.64335937499999, 10.020214843749997],
              [123.63398437500001, 9.921728515624991],
              [123.49355468750002, 9.589306640624997],
              [123.37031250000001, 9.449609375],
            ],
          ],
          [
            [
              [121.92167968749999, 18.894726562499997],
              [121.85820312499999, 18.822900390624994],
              [121.8251953125, 18.842724609374997],
              [121.86074218750002, 18.912548828124997],
              [121.85976562500002, 18.936767578125],
              [121.88886718750001, 18.99155273437499],
              [121.943359375, 19.010449218749997],
              [121.98789062500003, 18.956640625],
              [121.92167968749999, 18.894726562499997],
            ],
          ],
          [
            [
              [121.52089843750002, 19.361962890624994],
              [121.53125, 19.27133789062499],
              [121.47207031250002, 19.273339843749994],
              [121.38291015625003, 19.32846679687499],
              [121.37460937500003, 19.35629882812499],
              [121.3759765625, 19.3796875],
              [121.3916015625, 19.399365234374997],
              [121.52089843750002, 19.361962890624994],
            ],
          ],
          [
            [
              [121.96005859375003, 20.365869140624994],
              [121.94130859375002, 20.353710937499997],
              [121.9140625, 20.359423828125003],
              [121.94121093749999, 20.45371093749999],
              [121.9912109375, 20.47958984374999],
              [122.03115234375002, 20.469384765624994],
              [121.96005859375003, 20.365869140624994],
            ],
          ],
          [
            [
              [121.878125, 20.781884765624994],
              [121.82958984375, 20.70029296874999],
              [121.790625, 20.701171875],
              [121.79648437500003, 20.746630859375003],
              [121.84785156250001, 20.841259765624997],
              [121.86699218749999, 20.83920898437499],
              [121.878125, 20.781884765624994],
            ],
          ],
          [
            [
              [121.159375, 6.075634765624997],
              [121.2138671875, 6.003515625],
              [121.28251953124999, 6.022265624999989],
              [121.39150390625002, 6.002099609374994],
              [121.41464843750003, 5.964501953124994],
              [121.41103515625002, 5.93984375],
              [121.29443359375, 5.869970703124991],
              [121.21816406250002, 5.942724609374991],
              [121.0830078125, 5.893017578124997],
              [121.0185546875, 5.922949218749991],
              [120.9306640625, 5.896191406249997],
              [120.87636718750002, 5.95263671875],
              [120.89824218749999, 6.006933593749991],
              [121.03769531250003, 6.095996093749989],
              [121.159375, 6.075634765624997],
            ],
          ],
          [
            [
              [117.07988281249999, 7.883398437499991],
              [117.0283203125, 7.807519531249994],
              [116.96953124999999, 7.894921875],
              [116.97578125000001, 8.016650390624989],
              [116.99355468750002, 8.050537109375],
              [117.07705078125002, 8.069140624999989],
              [117.07988281249999, 7.883398437499991],
            ],
          ],
          [
            [
              [119.91621093750001, 10.485986328124994],
              [119.79316406250001, 10.455273437499997],
              [119.76445312499999, 10.551611328124991],
              [119.85205078125, 10.64013671875],
              [119.9501953125, 10.604785156249989],
              [120.00839843750003, 10.570117187499989],
              [119.98115234375001, 10.538720703124994],
              [119.91621093750001, 10.485986328124994],
            ],
          ],
          [
            [
              [120.1, 12.167675781249997],
              [120.15468750000002, 12.152392578124989],
              [120.19375, 12.167041015624989],
              [120.22822265625001, 12.219824218749991],
              [120.26054687499999, 12.141748046874994],
              [120.34140625000003, 12.077441406249989],
              [120.31455078125003, 12.012402343749997],
              [120.24345703124999, 12.004785156249994],
              [120.17363281249999, 12.019628906249991],
              [120.10009765625, 11.99375],
              [120.01054687499999, 12.008251953124997],
              [119.95703125, 12.069238281249994],
              [119.89609375000003, 12.178759765624989],
              [119.86591796875001, 12.199023437499989],
              [119.86962890625, 12.243994140624991],
              [119.89179687500001, 12.272509765624989],
              [119.88007812500001, 12.279882812499991],
              [119.8857421875, 12.299853515624989],
              [119.89667968750001, 12.313427734374997],
              [119.91640625000002, 12.319091796875],
              [119.9638671875, 12.270410156249994],
              [120.07753906250002, 12.19775390625],
              [120.1, 12.167675781249997],
            ],
          ],
          [
            [
              [120.03876953125001, 11.703320312499997],
              [119.9638671875, 11.669384765624997],
              [119.94492187500003, 11.690722656249989],
              [119.93173828125003, 11.740332031249991],
              [119.93281250000001, 11.774462890624989],
              [119.86093750000003, 11.953955078124991],
              [119.916015625, 11.981347656249994],
              [119.95654296875, 11.960253906249989],
              [119.99785156249999, 11.93212890625],
              [120.03593749999999, 11.917236328125],
              [120.07070312500002, 11.860546875],
              [120.06240234375002, 11.821337890624989],
              [120.07314453125002, 11.783496093749989],
              [120.03876953125001, 11.703320312499997],
            ],
          ],
          [
            [
              [122.64951171875003, 10.472705078124989],
              [122.621875, 10.459033203124989],
              [122.59716796875, 10.461035156249991],
              [122.53837890624999, 10.424951171874994],
              [122.51669921875003, 10.492529296874991],
              [122.5375, 10.607568359374994],
              [122.62578124999999, 10.695019531249997],
              [122.6484375, 10.722509765624991],
              [122.67255859375001, 10.738818359374989],
              [122.70126953125003, 10.740625],
              [122.72919921875001, 10.706396484374991],
              [122.73720703125002, 10.654589843749989],
              [122.68125, 10.498242187499997],
              [122.64951171875003, 10.472705078124989],
            ],
          ],
          [
            [
              [124.59384765625003, 9.787207031249991],
              [124.58427734374999, 9.75048828125],
              [124.50566406249999, 9.753515625],
              [124.4775390625, 9.747900390624991],
              [124.40341796875003, 9.654101562499989],
              [124.35986328125, 9.630224609374991],
              [124.12246093750002, 9.599316406249997],
              [123.93564453125003, 9.623974609374997],
              [123.87167968750003, 9.675732421874997],
              [123.82998046875002, 9.761132812499994],
              [123.81718749999999, 9.8173828125],
              [123.86386718750003, 9.878808593749994],
              [123.90888671875001, 9.919628906249997],
              [124.05976562500001, 10.000195312499997],
              [124.09384765625003, 10.061328124999989],
              [124.1728515625, 10.135205078124997],
              [124.33574218749999, 10.159912109375],
              [124.3515625, 10.141357421875],
              [124.37324218750001, 10.129589843749997],
              [124.40585937500003, 10.126416015624997],
              [124.486328125, 10.065478515624989],
              [124.5771484375, 10.026708984374991],
              [124.55507812500002, 9.879199218749989],
              [124.58222656250001, 9.82958984375],
              [124.59384765625003, 9.787207031249991],
            ],
          ],
          [
            [
              [120.25039062500002, 5.256591796875],
              [120.22324218750003, 5.196240234374997],
              [120.19160156250001, 5.168310546874991],
              [120.15, 5.18408203125],
              [120.11835937500001, 5.215380859374989],
              [120.1005859375, 5.168994140624989],
              [120.01328125000003, 5.151123046875],
              [119.95810546875003, 5.079541015624997],
              [119.87753906250003, 5.060205078124994],
              [119.82148437500001, 5.06953125],
              [119.82734375000001, 5.133154296874991],
              [119.98271484374999, 5.228417968749994],
              [120.07968750000003, 5.263623046874997],
              [120.16523437500001, 5.332421875],
              [120.2080078125, 5.340087890625],
              [120.22939453125002, 5.284082031249994],
              [120.25039062500002, 5.256591796875],
            ],
          ],
          [
            [
              [122.09287109375003, 6.428320312499991],
              [121.99140625000001, 6.41455078125],
              [121.95917968750001, 6.415820312499989],
              [121.8798828125, 6.517578125],
              [121.87246093750002, 6.562744140625],
              [121.80869140625003, 6.613720703124997],
              [121.83203125, 6.6640625],
              [121.91494140625002, 6.676220703124997],
              [122.05830078125001, 6.74072265625],
              [122.2880859375, 6.638916015625],
              [122.32353515624999, 6.602246093749997],
              [122.25175781249999, 6.579785156249997],
              [122.20097656249999, 6.48291015625],
              [122.09287109375003, 6.428320312499991],
            ],
          ],
          [
            [
              [126.059375, 9.766210937499991],
              [126.04677734375002, 9.760791015624989],
              [125.9912109375, 9.838525390624994],
              [125.99863281250003, 9.927050781249989],
              [126.07382812500003, 10.059228515624994],
              [126.12949218750003, 9.943554687499997],
              [126.12890625, 9.89111328125],
              [126.12080078125001, 9.865185546874997],
              [126.17255859375001, 9.799951171874994],
              [126.13691406250001, 9.767773437499997],
              [126.059375, 9.766210937499991],
            ],
          ],
          [
            [
              [125.69023437499999, 9.914453125],
              [125.67255859375001, 9.886474609375],
              [125.64863281250001, 9.944091796875],
              [125.59052734375001, 9.998193359374994],
              [125.53447265624999, 10.090087890625],
              [125.49482421875001, 10.118701171874989],
              [125.52197265625, 10.191503906249991],
              [125.52460937500001, 10.309716796874994],
              [125.58017578125003, 10.363671875],
              [125.60585937500002, 10.379589843749997],
              [125.64794921875, 10.436816406249989],
              [125.66679687499999, 10.440136718749997],
              [125.6845703125, 10.392041015624997],
              [125.64667968750001, 10.245410156249989],
              [125.70332031250001, 10.07177734375],
              [125.684375, 9.963183593749989],
              [125.69248046875003, 9.939013671874989],
              [125.69023437499999, 9.914453125],
            ],
          ],
          [
            [
              [120.27128906249999, 13.750683593749997],
              [120.27285156250002, 13.682958984374991],
              [120.10419921875001, 13.782373046874994],
              [120.09941406249999, 13.816943359375003],
              [120.10341796875002, 13.842529296875],
              [120.12070312500003, 13.858056640624994],
              [120.21142578125, 13.820654296874991],
              [120.27128906249999, 13.750683593749997],
            ],
          ],
          [
            [
              [121.91484374999999, 13.540332031250003],
              [121.9765625, 13.537402343750003],
              [121.99570312500003, 13.546777343749994],
              [122.11455078124999, 13.463183593750003],
              [122.10732421875002, 13.420849609374997],
              [122.12236328124999, 13.365136718749994],
              [122.0546875, 13.268652343749991],
              [122.04238281250002, 13.236181640624991],
              [122.0048828125, 13.204980468749994],
              [121.87587890625002, 13.28173828125],
              [121.82919921875003, 13.32861328125],
              [121.81503906250003, 13.424462890624994],
              [121.8662109375, 13.566162109375],
              [121.91484374999999, 13.540332031250003],
            ],
          ],
          [
            [
              [122.09404296874999, 12.354882812499994],
              [122.01396484374999, 12.105615234374994],
              [121.96015625000001, 12.19140625],
              [121.98193359375, 12.2453125],
              [121.93564453125003, 12.290380859374991],
              [121.92324218750002, 12.331298828125],
              [121.94101562500003, 12.385400390624994],
              [121.98945312500001, 12.435302734375],
              [122.00156250000003, 12.598535156249994],
              [122.10380859374999, 12.650634765625],
              [122.14501953125, 12.652636718749989],
              [122.13027343750002, 12.612597656249989],
              [122.13164062499999, 12.537548828124997],
              [122.09404296874999, 12.354882812499994],
            ],
          ],
          [
            [
              [122.65449218750001, 12.309033203124997],
              [122.60332031249999, 12.285595703124997],
              [122.49931640624999, 12.383691406249994],
              [122.43886718750002, 12.429492187499989],
              [122.42294921875003, 12.455078125],
              [122.471875, 12.491943359375],
              [122.60361328125003, 12.491601562499994],
              [122.67363281249999, 12.424267578124997],
              [122.68330078125001, 12.38232421875],
              [122.65449218750001, 12.309033203124997],
            ],
          ],
          [
            [
              [123.28183593750003, 12.853417968749994],
              [123.3671875, 12.700830078124994],
              [123.27421874999999, 12.805078125],
              [123.16640625000002, 12.875878906249994],
              [123.05419921875, 12.993457031249989],
              [122.97343749999999, 13.034716796874989],
              [122.94902343749999, 13.058691406249991],
              [122.95751953125, 13.107177734375],
              [123.01708984375, 13.116162109374997],
              [123.04355468750003, 13.113378906249991],
              [123.20625, 12.905419921874994],
              [123.28183593750003, 12.853417968749994],
            ],
          ],
          [
            [
              [123.775390625, 12.453906249999989],
              [123.77910156249999, 12.366259765624989],
              [123.74150390624999, 12.398535156249991],
              [123.62060546875, 12.570507812499997],
              [123.58720703124999, 12.63330078125],
              [123.62148437500002, 12.674902343749991],
              [123.70869140625001, 12.610791015624997],
              [123.775390625, 12.453906249999989],
            ],
          ],
          [
            [
              [123.71660156249999, 12.287353515625],
              [123.90830078125003, 12.169091796874994],
              [124.04033203124999, 11.966796875],
              [124.0556640625, 11.811572265624989],
              [124.04550781250003, 11.75244140625],
              [123.98271484374999, 11.818896484374989],
              [123.84775390625003, 11.91357421875],
              [123.75400390625003, 11.934472656249994],
              [123.72519531250003, 11.9515625],
              [123.73603515625001, 12.002636718749997],
              [123.6748046875, 12.05],
              [123.66757812500003, 12.0693359375],
              [123.61201171875001, 12.090234375],
              [123.53105468749999, 12.196630859374991],
              [123.47373046875003, 12.216650390624991],
              [123.41884765625002, 12.194238281249994],
              [123.29267578125001, 12.036376953125],
              [123.15781250000003, 11.925634765624991],
              [123.15585937500003, 11.96796875],
              [123.21054687500003, 12.106591796874994],
              [123.24531250000001, 12.328027343749994],
              [123.26718750000003, 12.395458984374997],
              [123.23984375000003, 12.494677734374989],
              [123.23642578125003, 12.58349609375],
              [123.33701171875003, 12.542382812499994],
              [123.46298828125003, 12.501220703125],
              [123.55898437500002, 12.44482421875],
              [123.57480468750003, 12.406933593749997],
              [123.71660156249999, 12.287353515625],
            ],
          ],
          [
            [
              [124.35361328125003, 13.632226562499994],
              [124.32705078125002, 13.5673828125],
              [124.29453125000003, 13.59033203125],
              [124.24824218750001, 13.586669921875],
              [124.17539062500003, 13.531542968750003],
              [124.05703125000002, 13.605566406249991],
              [124.03886718749999, 13.663134765625003],
              [124.12373046875001, 13.790478515624997],
              [124.12285156249999, 13.9796875],
              [124.15371093750002, 14.026171875],
              [124.18623046875001, 14.059521484374997],
              [124.22490234374999, 14.077587890624997],
              [124.30830078125001, 13.946972656249997],
              [124.33671874999999, 13.931103515624997],
              [124.41718750000001, 13.871044921874997],
              [124.39628906249999, 13.750097656249991],
              [124.40400390625001, 13.679443359375],
              [124.35361328125003, 13.632226562499994],
            ],
          ],
          [
            [
              [122.17539062500003, 14.048828125],
              [122.17226562500002, 14.008007812499997],
              [121.95625, 14.156054687500003],
              [121.94638671875003, 14.181494140624991],
              [121.94599609375001, 14.205126953125003],
              [121.95917968750001, 14.228759765625],
              [122.17539062500003, 14.048828125],
            ],
          ],
          [
            [
              [122.03349609374999, 15.005029296874994],
              [122.05156249999999, 14.969873046874994],
              [122.03173828125, 14.971630859374997],
              [122.01728515625001, 14.965283203124997],
              [121.97031250000003, 14.89296875],
              [122.02167968750001, 14.759423828124994],
              [121.98964843750002, 14.662158203125003],
              [121.93300781250002, 14.656054687500003],
              [121.91064453125, 14.66650390625],
              [121.92216796874999, 14.714550781249997],
              [121.9345703125, 14.736621093750003],
              [121.92304687500001, 14.8],
              [121.88925781250003, 14.83984375],
              [121.8623046875, 14.9171875],
              [121.8203125, 14.963574218749997],
              [121.83984375, 15.038134765625003],
              [121.9716796875, 15.04638671875],
              [122.03349609374999, 15.005029296874994],
            ],
          ],
          [
            [
              [124.31621093749999, 10.606005859374989],
              [124.28847656250002, 10.601464843749994],
              [124.33466796875001, 10.706689453124994],
              [124.37109375, 10.691357421874997],
              [124.38232421875, 10.679833984374994],
              [124.38134765625, 10.632568359375],
              [124.31621093749999, 10.606005859374989],
            ],
          ],
          [
            [
              [125.28076171875, 9.982177734375],
              [125.28769531250003, 9.932714843749991],
              [125.15898437499999, 10.062939453124997],
              [125.13300781250001, 10.155029296875],
              [125.17587890625003, 10.151074218749997],
              [125.23095703125, 10.115673828124997],
              [125.28076171875, 9.982177734375],
            ],
          ],
          [
            [
              [122.93710937500003, 7.409130859374997],
              [122.94804687499999, 7.3857421875],
              [122.94365234374999, 7.361035156249997],
              [122.83955078125001, 7.314599609374994],
              [122.8046875, 7.315966796874989],
              [122.79658203125001, 7.393359374999989],
              [122.82216796875002, 7.428466796875],
              [122.87119140625003, 7.397314453124991],
              [122.91484374999999, 7.433398437499989],
              [122.93710937500003, 7.409130859374997],
            ],
          ],
          [
            [
              [125.78457031250002, 6.962744140624991],
              [125.76894531250002, 6.90576171875],
              [125.70751953125, 7.039990234374997],
              [125.68300781250002, 7.073193359374997],
              [125.71445312500003, 7.185546875],
              [125.78339843750001, 7.130664062499989],
              [125.78457031250002, 6.962744140624991],
            ],
          ],
          [
            [
              [124.6083984375, 11.4921875],
              [124.48349609375003, 11.48583984375],
              [124.42880859375003, 11.53173828125],
              [124.3603515625, 11.665917968749994],
              [124.43740234375002, 11.695019531249997],
              [124.51093750000001, 11.687109375],
              [124.56494140625, 11.639697265624989],
              [124.62226562500001, 11.549560546875],
              [124.6083984375, 11.4921875],
            ],
          ],
          [
            [
              [124.806640625, 9.142626953124989],
              [124.77792968750003, 9.083105468749991],
              [124.66582031249999, 9.13232421875],
              [124.63906250000002, 9.175097656249989],
              [124.6533203125, 9.225830078125],
              [124.70810546875003, 9.243017578124991],
              [124.73681640625, 9.2431640625],
              [124.79023437500001, 9.190087890624994],
              [124.806640625, 9.142626953124989],
            ],
          ],
          [
            [
              [121.25224609374999, 19.082421875],
              [121.24667968750003, 19.015185546875003],
              [121.19609374999999, 19.050683593749994],
              [121.18486328124999, 19.10141601562499],
              [121.18994140625, 19.138916015625],
              [121.21318359374999, 19.18359375],
              [121.24472656250003, 19.143017578124997],
              [121.25224609374999, 19.082421875],
            ],
          ],
          [
            [
              [119.86142578125003, 11.525341796874997],
              [119.88291015625003, 11.472412109375],
              [119.85488281250002, 11.39306640625],
              [119.83066406250003, 11.375683593749997],
              [119.79863281249999, 11.408740234374989],
              [119.72998046875, 11.431933593749989],
              [119.7255859375, 11.474658203124989],
              [119.76142578125001, 11.4736328125],
              [119.82675781250003, 11.515429687499989],
              [119.86142578125003, 11.525341796874997],
            ],
          ],
          [
            [
              [123.75703125000001, 11.283300781249991],
              [123.815625, 11.150732421874991],
              [123.73671875000002, 11.151464843749991],
              [123.70761718750003, 11.247998046874997],
              [123.74140625000001, 11.279150390624991],
              [123.75703125000001, 11.283300781249991],
            ],
          ],
          [
            [
              [122.31083984374999, 12.52880859375],
              [122.27978515625, 12.498291015625],
              [122.26093750000001, 12.503076171874994],
              [122.24785156249999, 12.556933593749989],
              [122.27802734375001, 12.592919921874994],
              [122.2875, 12.589257812499994],
              [122.31083984374999, 12.52880859375],
            ],
          ],
          [
            [
              [125.97050781249999, 9.593554687499989],
              [125.95244140624999, 9.56796875],
              [125.92207031250001, 9.621484375],
              [125.94853515624999, 9.739208984374997],
              [125.9677734375, 9.759082031249989],
              [125.99296874999999, 9.6845703125],
              [125.97050781249999, 9.593554687499989],
            ],
          ],
          [
            [
              [124.85439453125002, 11.594775390624989],
              [124.8359375, 11.543310546874991],
              [124.806640625, 11.557568359374997],
              [124.78105468749999, 11.580761718749997],
              [124.74365234375, 11.658544921874991],
              [124.73085937500002, 11.71533203125],
              [124.78837890624999, 11.68310546875],
              [124.82148437500001, 11.626611328124994],
              [124.85439453125002, 11.594775390624989],
            ],
          ],
          [
            [
              [117.35527343749999, 8.214648437499989],
              [117.28701171875002, 8.191015625],
              [117.27226562499999, 8.253515625],
              [117.28085937500003, 8.314990234374989],
              [117.32958984375, 8.308496093749994],
              [117.35371093750001, 8.289257812499997],
              [117.35527343749999, 8.214648437499989],
            ],
          ],
          [
            [
              [123.69765625000002, 9.2373046875],
              [123.70625, 9.133544921875],
              [123.61445312500001, 9.103320312499989],
              [123.54072265625001, 9.129736328124991],
              [123.49345703124999, 9.192089843749997],
              [123.49355468750002, 9.215527343749997],
              [123.53515625, 9.213574218749997],
              [123.62607421875003, 9.268261718749997],
              [123.65488281250003, 9.278759765624997],
              [123.69765625000002, 9.2373046875],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 4,
        labelrank: 3,
        sovereignt: "Sri Lanka",
        sov_a3: "LKA",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Sri Lanka",
        adm0_a3: "LKA",
        geou_dif: 0,
        geounit: "Sri Lanka",
        gu_a3: "LKA",
        su_dif: 0,
        subunit: "Sri Lanka",
        su_a3: "LKA",
        brk_diff: 0,
        name: "Sri Lanka",
        name_long: "Sri Lanka",
        brk_a3: "LKA",
        brk_name: "Sri Lanka",
        brk_group: null,
        abbrev: "Sri L.",
        postal: "LK",
        formal_en: "Democratic Socialist Republic of Sri Lanka",
        formal_fr: null,
        name_ciawf: "Sri Lanka",
        note_adm0: null,
        note_brk: null,
        name_sort: "Sri Lanka",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 5,
        mapcolor9: 4,
        mapcolor13: 9,
        pop_est: 21803000,
        pop_rank: 15,
        pop_year: 2019,
        gdp_md: 84008,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        fips_10: "CE",
        iso_a2: "LK",
        iso_a2_eh: "LK",
        iso_a3: "LKA",
        iso_a3_eh: "LKA",
        iso_n3: "144",
        iso_n3_eh: "144",
        un_a3: "144",
        wb_a2: "LK",
        wb_a3: "LKA",
        woe_id: 23424778,
        woe_id_eh: 23424778,
        woe_note: "Exact WOE match as country",
        adm0_iso: "LKA",
        adm0_diff: null,
        adm0_tlc: "LKA",
        adm0_a3_us: "LKA",
        adm0_a3_fr: "LKA",
        adm0_a3_ru: "LKA",
        adm0_a3_es: "LKA",
        adm0_a3_cn: "LKA",
        adm0_a3_tw: "LKA",
        adm0_a3_in: "LKA",
        adm0_a3_np: "LKA",
        adm0_a3_pk: "LKA",
        adm0_a3_de: "LKA",
        adm0_a3_gb: "LKA",
        adm0_a3_br: "LKA",
        adm0_a3_il: "LKA",
        adm0_a3_ps: "LKA",
        adm0_a3_sa: "LKA",
        adm0_a3_eg: "LKA",
        adm0_a3_ma: "LKA",
        adm0_a3_pt: "LKA",
        adm0_a3_ar: "LKA",
        adm0_a3_jp: "LKA",
        adm0_a3_ko: "LKA",
        adm0_a3_vn: "LKA",
        adm0_a3_tr: "LKA",
        adm0_a3_id: "LKA",
        adm0_a3_pl: "LKA",
        adm0_a3_gr: "LKA",
        adm0_a3_it: "LKA",
        adm0_a3_nl: "LKA",
        adm0_a3_se: "LKA",
        adm0_a3_bd: "LKA",
        adm0_a3_ua: "LKA",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Southern Asia",
        region_wb: "South Asia",
        name_len: 9,
        long_len: 9,
        abbrev_len: 6,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: 80.704823,
        label_y: 7.581097,
        ne_id: 1159321025,
        wikidataid: "Q854",
        name_ar: "سريلانكا",
        name_bn: "শ্রীলঙ্কা",
        name_de: "Sri Lanka",
        name_en: "Sri Lanka",
        name_es: "Sri Lanka",
        name_fa: "سریلانکا",
        name_fr: "Sri Lanka",
        name_el: "Σρι Λάνκα",
        name_he: "סרי לנקה",
        name_hi: "श्रीलंका",
        name_hu: "Srí Lanka",
        name_id: "Sri Lanka",
        name_it: "Sri Lanka",
        name_ja: "スリランカ",
        name_ko: "스리랑카",
        name_nl: "Sri Lanka",
        name_pl: "Sri Lanka",
        name_pt: "Sri Lanka",
        name_ru: "Шри-Ланка",
        name_sv: "Sri Lanka",
        name_tr: "Sri Lanka",
        name_uk: "Шрі-Ланка",
        name_ur: "سری لنکا",
        name_vi: "Sri Lanka",
        name_zh: "斯里兰卡",
        name_zht: "斯里蘭卡",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "LKA.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [79.96953124999999, 9.630664062499989],
              [79.90683593750003, 9.619824218749997],
              [79.857421875, 9.686376953124991],
              [79.845703125, 9.714648437499989],
              [79.85859375000001, 9.734375],
              [79.87226562500001, 9.744335937499997],
              [79.88847656249999, 9.741162109374997],
              [79.91191406249999, 9.679150390624997],
              [79.96953124999999, 9.630664062499989],
            ],
          ],
          [
            [
              [79.87480468749999, 9.050732421874997],
              [79.90371093750002, 8.975],
              [79.82109374999999, 9.02685546875],
              [79.76679687500001, 9.069775390624997],
              [79.74765625000003, 9.104589843749991],
              [79.85996093750003, 9.065722656249989],
              [79.87480468749999, 9.050732421874997],
            ],
          ],
          [
            [
              [79.98232421875002, 9.812695312499997],
              [80.07841796874999, 9.807470703124991],
              [80.18095703124999, 9.810009765624997],
              [80.25283203125002, 9.796337890624997],
              [80.3759765625, 9.642333984375],
              [80.71113281250001, 9.366357421874994],
              [80.89345703125002, 9.085888671874997],
              [80.91005859375002, 9.024511718749991],
              [80.93544921875002, 8.971484374999989],
              [80.97919921875001, 8.956933593749994],
              [81.01601562500002, 8.9326171875],
              [81.1982421875, 8.661962890624991],
              [81.21923828125, 8.6083984375],
              [81.21621093750002, 8.549414062499991],
              [81.22695312500002, 8.505517578124994],
              [81.27460937500001, 8.48359375],
              [81.333984375, 8.472070312499994],
              [81.37285156249999, 8.431445312499989],
              [81.42216796874999, 8.215234375],
              [81.42216796874999, 8.147851562499994],
              [81.43593750000002, 8.118896484375],
              [81.66542968750002, 7.782470703125],
              [81.6787109375, 7.741552734374991],
              [81.67626953125, 7.7109375],
              [81.68291015624999, 7.684472656249994],
              [81.72734374999999, 7.625],
              [81.79667968749999, 7.464794921874997],
              [81.83203125, 7.428417968749997],
              [81.87412109375003, 7.288330078125],
              [81.876953125, 7.020458984374997],
              [81.86142578125003, 6.901269531249994],
              [81.81855468750001, 6.756201171874991],
              [81.76777343750001, 6.614306640624989],
              [81.71269531249999, 6.511865234374994],
              [81.63740234375001, 6.425146484374991],
              [81.37998046875003, 6.240917968749997],
              [81.30625, 6.203857421875],
              [80.97109375000002, 6.08837890625],
              [80.72412109375, 5.979052734374989],
              [80.49580078125001, 5.949365234374994],
              [80.26738281249999, 6.009765625],
              [80.09531250000003, 6.153173828124991],
              [80.00722656250002, 6.364404296874994],
              [79.94697265625001, 6.584521484374989],
              [79.859375, 6.829296875],
              [79.79208984375003, 7.585205078125],
              [79.75996093750001, 7.796484375],
              [79.70781249999999, 8.065673828125],
              [79.71298828125003, 8.182324218749997],
              [79.74980468749999, 8.294238281249989],
              [79.74970703125001, 8.048876953124989],
              [79.78349609374999, 8.018457031249994],
              [79.80888671874999, 8.05],
              [79.83193359375002, 8.304052734374991],
              [79.85087890624999, 8.411572265624997],
              [79.94179687500002, 8.691503906249991],
              [79.94365234374999, 8.741162109374997],
              [79.92792968750001, 8.846435546875],
              [79.92890625000001, 8.899218749999989],
              [80.06484375000002, 9.095654296874997],
              [80.099609375, 9.2099609375],
              [80.11835937500001, 9.326855468749997],
              [80.11093750000003, 9.453271484374994],
              [80.08632812500002, 9.577832031249997],
              [80.19609374999999, 9.538134765624989],
              [80.25644531250003, 9.494775390624994],
              [80.31796875000003, 9.465429687499991],
              [80.36796874999999, 9.48046875],
              [80.42832031250003, 9.48095703125],
              [80.38535156250003, 9.548779296874997],
              [80.25761718749999, 9.611279296874997],
              [80.04580078125002, 9.64990234375],
              [79.9794921875, 9.699365234374994],
              [79.95400390625002, 9.742333984374994],
              [79.96699218750001, 9.792626953124994],
              [79.98232421875002, 9.812695312499997],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 5,
        labelrank: 5,
        sovereignt: "Australia",
        sov_a3: "AU1",
        adm0_dif: 1,
        level: 2,
        type: "Dependency",
        tlc: "1",
        admin: "Indian Ocean Territories",
        adm0_a3: "IOA",
        geou_dif: 0,
        geounit: "Indian Ocean Territories",
        gu_a3: "IOA",
        su_dif: 0,
        subunit: "Indian Ocean Territories",
        su_a3: "IOA",
        brk_diff: 0,
        name: "Indian Ocean Ter.",
        name_long: "Indian Ocean Territories",
        brk_a3: "IOA",
        brk_name: "Indian Ocean Ter.",
        brk_group: null,
        abbrev: "Ind. Oc. Ter.",
        postal: "IOT",
        formal_en: null,
        formal_fr: null,
        name_ciawf: null,
        note_adm0: "Auz.",
        note_brk: null,
        name_sort: "Indian Ocean Territories",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 2,
        mapcolor9: 2,
        mapcolor13: 7,
        pop_est: 2387,
        pop_rank: 4,
        pop_year: 2016,
        gdp_md: 35,
        gdp_year: 2016,
        economy: "2. Developed region: nonG7",
        income_grp: "2. High income: nonOECD",
        fips_10: "-99",
        iso_a2: "-99",
        iso_a2_eh: "AU",
        iso_a3: "-99",
        iso_a3_eh: "AUS",
        iso_n3: "-99",
        iso_n3_eh: "036",
        un_a3: "-099",
        wb_a2: "-99",
        wb_a3: "-99",
        woe_id: -90,
        woe_id_eh: 23424869,
        woe_note:
          "Grouping of Christmas Island (23424869) and Cocos or Keeling Islands (23424784)",
        adm0_iso: "AUS",
        adm0_diff: "1",
        adm0_tlc: "IOA",
        adm0_a3_us: "IOA",
        adm0_a3_fr: "IOA",
        adm0_a3_ru: "IOA",
        adm0_a3_es: "IOA",
        adm0_a3_cn: "IOA",
        adm0_a3_tw: "IOA",
        adm0_a3_in: "IOA",
        adm0_a3_np: "IOA",
        adm0_a3_pk: "IOA",
        adm0_a3_de: "IOA",
        adm0_a3_gb: "IOA",
        adm0_a3_br: "IOA",
        adm0_a3_il: "IOA",
        adm0_a3_ps: "IOA",
        adm0_a3_sa: "IOA",
        adm0_a3_eg: "IOA",
        adm0_a3_ma: "IOA",
        adm0_a3_pt: "IOA",
        adm0_a3_ar: "IOA",
        adm0_a3_jp: "IOA",
        adm0_a3_ko: "IOA",
        adm0_a3_vn: "IOA",
        adm0_a3_tr: "IOA",
        adm0_a3_id: "IOA",
        adm0_a3_pl: "IOA",
        adm0_a3_gr: "IOA",
        adm0_a3_it: "IOA",
        adm0_a3_nl: "IOA",
        adm0_a3_se: "IOA",
        adm0_a3_bd: "IOA",
        adm0_a3_ua: "IOA",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Africa",
        subregion: "Seven seas (open ocean)",
        region_wb: "East Asia & Pacific",
        name_len: 17,
        long_len: 24,
        abbrev_len: 13,
        tiny: -99,
        homepart: -99,
        min_zoom: 0,
        min_label: 5,
        max_label: 9.5,
        label_x: 105.67259,
        label_y: -10.490789,
        ne_id: 1159320363,
        wikidataid: "Q4824275",
        name_ar: "أقاليم المحيط الهندي الأسترالية",
        name_bn: "অস্ট্রেলীয় ভারত মহাসাগর অঞ্চল",
        name_de: "Australische Territorien im Indischen Ozean",
        name_en: "Australian Indian Ocean Territories",
        name_es: "Territorios Australianos del Océano Índico",
        name_fa: "سرزمینهای اقیانوس هند استرالیا",
        name_fr: "Territoires extérieurs australiens de l'Océan Indien",
        name_el: "Αυστραλέζικο Έδαφος Ινδικού Ωκεανού",
        name_he: "טריטוריה של האוקיינוס ההודי",
        name_hi: "हिंद महासागर के ऑस्ट्रेलियाई क्षेत्र",
        name_hu: "Ausztrál Indiai-óceáni Terület",
        name_id: "Wilayah Samudra Hindia Australia",
        name_it: "Australian Indian Ocean Territories",
        name_ja: "オーストラリア領インド洋地域",
        name_ko: "호주령 인도양 지역",
        name_nl: "Australische territoria van de Indische Oceaan",
        name_pl: "Australijskie Terytorium Oceanu Indyjskiego",
        name_pt: "Territórios australianos do Oceano Índico",
        name_ru: "Австралийские территории в Индийском океане",
        name_sv: "Australienska indiska oceanens havsområde",
        name_tr: "Australian Indian Ocean Territories",
        name_uk: "Австралійські території в Індійському океані",
        name_ur: "آسْٹْریلِیَن انڈین اوشین تیریتورییس",
        name_vi: "Vùng lãnh thổ Ấn Độ Dương thuộc Úc",
        name_zh: "澳屬印度洋領地",
        name_zht: "澳屬印度洋領地",
        fclass_iso: "Unrecognized",
        tlc_diff: "1",
        fclass_tlc: "Admin-0 dependency",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "indianoceanter.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [105.72539062499999, -10.49296875],
              [105.696875, -10.564160156250011],
              [105.64433593749999, -10.525],
              [105.58408203125003, -10.5125],
              [105.595703125, -10.459667968750011],
              [105.6455078125, -10.452246093750006],
              [105.66982421875002, -10.449414062500011],
              [105.70546875000002, -10.4306640625],
              [105.72539062499999, -10.49296875],
            ],
          ],
          [
            [
              [96.84042968750003, -12.181835937500011],
              [96.85195312500002, -12.186816406250003],
              [96.86738281250001, -12.181445312500003],
              [96.87363281250003, -12.187695312500011],
              [96.84951171875002, -12.197363281250006],
              [96.83486328125002, -12.1796875],
              [96.82773437500003, -12.150683593750003],
              [96.82587890625001, -12.126171875000011],
              [96.83261718750003, -12.126171875000011],
              [96.83261718750003, -12.136035156250003],
              [96.83417968750001, -12.144140625],
              [96.83945312500003, -12.160253906250006],
              [96.83564453125001, -12.171289062500009],
              [96.84042968750003, -12.181835937500011],
            ],
          ],
          [
            [
              [96.91826171874999, -12.194140625],
              [96.90654296874999, -12.199804687500006],
              [96.89677734374999, -12.195507812500011],
              [96.89394531250002, -12.192578125000011],
              [96.89296875000002, -12.187207031250011],
              [96.90439453125003, -12.1865234375],
              [96.91337890624999, -12.181835937500011],
              [96.9189453125, -12.17333984375],
              [96.92050781250003, -12.161523437500009],
              [96.92529296875, -12.173242187500009],
              [96.92431640625, -12.184667968750006],
              [96.91826171874999, -12.194140625],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Taiwan",
        sov_a3: "TWN",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Taiwan",
        adm0_a3: "TWN",
        geou_dif: 0,
        geounit: "Taiwan",
        gu_a3: "TWN",
        su_dif: 0,
        subunit: "Taiwan",
        su_a3: "TWN",
        brk_diff: 0,
        name: "Taiwan",
        name_long: "Taiwan",
        brk_a3: "TWN",
        brk_name: "Taiwan",
        brk_group: null,
        abbrev: "Taiwan",
        postal: "TW",
        formal_en: null,
        formal_fr: null,
        name_ciawf: "Taiwan",
        note_adm0: null,
        note_brk: "Self admin.; Claimed by China",
        name_sort: "Taiwan",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 5,
        mapcolor9: 7,
        mapcolor13: 2,
        pop_est: 23568378,
        pop_rank: 15,
        pop_year: 2020,
        gdp_md: 1127000,
        gdp_year: 2016,
        economy: "2. Developed region: nonG7",
        income_grp: "2. High income: nonOECD",
        fips_10: "TW",
        iso_a2: "CN-TW",
        iso_a2_eh: "TW",
        iso_a3: "TWN",
        iso_a3_eh: "TWN",
        iso_n3: "158",
        iso_n3_eh: "158",
        un_a3: "-099",
        wb_a2: "-99",
        wb_a3: "-99",
        woe_id: 23424971,
        woe_id_eh: 23424971,
        woe_note: "Exact WOE match as country",
        adm0_iso: "TWN",
        adm0_diff: null,
        adm0_tlc: "TWN",
        adm0_a3_us: "TWN",
        adm0_a3_fr: "TWN",
        adm0_a3_ru: "CHN",
        adm0_a3_es: "TWN",
        adm0_a3_cn: "CHN",
        adm0_a3_tw: "TWN",
        adm0_a3_in: "TWN",
        adm0_a3_np: "CHN",
        adm0_a3_pk: "CHN",
        adm0_a3_de: "TWN",
        adm0_a3_gb: "TWN",
        adm0_a3_br: "TWN",
        adm0_a3_il: "TWN",
        adm0_a3_ps: "TWN",
        adm0_a3_sa: "TWN",
        adm0_a3_eg: "CHN",
        adm0_a3_ma: "CHN",
        adm0_a3_pt: "TWN",
        adm0_a3_ar: "TWN",
        adm0_a3_jp: "TWN",
        adm0_a3_ko: "TWN",
        adm0_a3_vn: "TWN",
        adm0_a3_tr: "TWN",
        adm0_a3_id: "CHN",
        adm0_a3_pl: "TWN",
        adm0_a3_gr: "TWN",
        adm0_a3_it: "TWN",
        adm0_a3_nl: "TWN",
        adm0_a3_se: "TWN",
        adm0_a3_bd: "CHN",
        adm0_a3_ua: "TWN",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Eastern Asia",
        region_wb: "East Asia & Pacific",
        name_len: 6,
        long_len: 6,
        abbrev_len: 6,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4.5,
        max_label: 8,
        label_x: 120.868204,
        label_y: 23.652408,
        ne_id: 1159321335,
        wikidataid: "Q865",
        name_ar: "تايوان",
        name_bn: "তাইওয়ান",
        name_de: "Republik China",
        name_en: "Taiwan",
        name_es: "República de China",
        name_fa: "تایوان",
        name_fr: "Taïwan",
        name_el: "Δημοκρατία της Κίνας",
        name_he: "טאיוואן",
        name_hi: "चीनी गणराज्य",
        name_hu: "Kínai Köztársaság",
        name_id: "Taiwan",
        name_it: "Taiwan",
        name_ja: "中華民国",
        name_ko: "중화민국",
        name_nl: "Taiwan",
        name_pl: "Republika Chińska",
        name_pt: "Taiwan",
        name_ru: "Тайвань",
        name_sv: "Taiwan",
        name_tr: "Çin Cumhuriyeti",
        name_uk: "Республіка Китай",
        name_ur: "تائیوان",
        name_vi: "Đài Loan",
        name_zh: "中华民国",
        name_zht: "中華民國",
        fclass_iso: "Admin-1 states provinces",
        tlc_diff: "1",
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: "Admin-1 states provinces",
        fclass_tw: "Admin-0 country",
        fclass_in: null,
        fclass_np: "Admin-1 states provinces",
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: "Admin-1 states provinces",
        fclass_ma: "Admin-1 states provinces",
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: "Admin-1 states provinces",
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: "Admin-1 states provinces",
        fclass_ua: null,
        filename: "TWN.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [121.0087890625, 22.620361328125],
              [120.946875, 22.503076171874994],
              [120.89736328125002, 22.379150390625],
              [120.87734375000002, 22.26220703125],
              [120.87841796875, 22.141552734374997],
              [120.8642578125, 22.032666015624997],
              [120.83984375, 21.925],
              [120.74277343750003, 21.956005859374997],
              [120.69013671875001, 22.033105468749994],
              [120.67802734374999, 22.15966796875],
              [120.60761718750001, 22.312548828125003],
              [120.58125, 22.356396484374997],
              [120.47978515624999, 22.44189453125],
              [120.38759765625002, 22.484521484374994],
              [120.31621093749999, 22.547607421875],
              [120.32558593750002, 22.542431640624997],
              [120.27285156250002, 22.62744140625],
              [120.23281250000002, 22.717919921874994],
              [120.15009765625001, 22.974902343750003],
              [120.12158203125, 23.037011718749994],
              [120.08339843750002, 23.093701171874997],
              [120.07246093750001, 23.14975585937499],
              [120.08554687500003, 23.212060546874994],
              [120.12119140625003, 23.30517578125],
              [120.14296875000002, 23.399072265624994],
              [120.12539062500002, 23.526611328125],
              [120.13212890624999, 23.65292968749999],
              [120.15898437499999, 23.709033203125003],
              [120.62968749999999, 24.478515625],
              [120.75742187500003, 24.642285156249997],
              [120.8359375, 24.72265625],
              [120.90156250000001, 24.81328125],
              [120.96406250000001, 24.927978515625],
              [121.040625, 25.0328125],
              [121.09541015625001, 25.065087890624994],
              [121.36542968750001, 25.1591796875],
              [121.44960937500002, 25.2490234375],
              [121.51708984375, 25.276904296875003],
              [121.59365234375002, 25.275341796874997],
              [121.64306640625, 25.232421875],
              [121.68710937500003, 25.181591796874997],
              [121.73330078125002, 25.154101562500003],
              [121.85283203124999, 25.104443359374997],
              [121.90517578125002, 25.056445312500003],
              [121.92900390624999, 24.97373046874999],
              [121.85625, 24.895263671875],
              [121.82011718749999, 24.824511718750003],
              [121.81337890625002, 24.746337890625],
              [121.82636718750001, 24.640527343749994],
              [121.82802734375002, 24.534375],
              [121.73701171875001, 24.28525390624999],
              [121.63935546875001, 24.130078125],
              [121.61308593749999, 24.052734375],
              [121.58339843750002, 23.860888671875003],
              [121.52607421875001, 23.668261718750003],
              [121.47714843750003, 23.424072265625],
              [121.3974609375, 23.172509765624994],
              [121.35224609375001, 23.067285156249994],
              [121.2958984375, 22.966601562500003],
              [121.16123046875003, 22.7763671875],
              [121.0087890625, 22.620361328125],
            ],
          ],
          [
            [
              [118.40742187500001, 24.522119140624994],
              [118.451171875, 24.45556640625],
              [118.43271484375003, 24.414355468750003],
              [118.29511718750001, 24.436328125],
              [118.28730468750001, 24.476611328125003],
              [118.33935546875, 24.469140625],
              [118.40742187500001, 24.522119140624994],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 3,
        labelrank: 6,
        sovereignt: "Singapore",
        sov_a3: "SGP",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Singapore",
        adm0_a3: "SGP",
        geou_dif: 0,
        geounit: "Singapore",
        gu_a3: "SGP",
        su_dif: 0,
        subunit: "Singapore",
        su_a3: "SGP",
        brk_diff: 0,
        name: "Singapore",
        name_long: "Singapore",
        brk_a3: "SGP",
        brk_name: "Singapore",
        brk_group: null,
        abbrev: "Sing.",
        postal: "SG",
        formal_en: "Republic of Singapore",
        formal_fr: null,
        name_ciawf: "Singapore",
        note_adm0: null,
        note_brk: null,
        name_sort: "Singapore",
        name_alt: null,
        mapcolor7: 5,
        mapcolor8: 3,
        mapcolor9: 7,
        mapcolor13: 3,
        pop_est: 5703569,
        pop_rank: 13,
        pop_year: 2019,
        gdp_md: 372062,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "2. High income: nonOECD",
        fips_10: "SN",
        iso_a2: "SG",
        iso_a2_eh: "SG",
        iso_a3: "SGP",
        iso_a3_eh: "SGP",
        iso_n3: "702",
        iso_n3_eh: "702",
        un_a3: "702",
        wb_a2: "SG",
        wb_a3: "SGP",
        woe_id: 23424948,
        woe_id_eh: 23424948,
        woe_note: "Exact WOE match as country",
        adm0_iso: "SGP",
        adm0_diff: null,
        adm0_tlc: "SGP",
        adm0_a3_us: "SGP",
        adm0_a3_fr: "SGP",
        adm0_a3_ru: "SGP",
        adm0_a3_es: "SGP",
        adm0_a3_cn: "SGP",
        adm0_a3_tw: "SGP",
        adm0_a3_in: "SGP",
        adm0_a3_np: "SGP",
        adm0_a3_pk: "SGP",
        adm0_a3_de: "SGP",
        adm0_a3_gb: "SGP",
        adm0_a3_br: "SGP",
        adm0_a3_il: "SGP",
        adm0_a3_ps: "SGP",
        adm0_a3_sa: "SGP",
        adm0_a3_eg: "SGP",
        adm0_a3_ma: "SGP",
        adm0_a3_pt: "SGP",
        adm0_a3_ar: "SGP",
        adm0_a3_jp: "SGP",
        adm0_a3_ko: "SGP",
        adm0_a3_vn: "SGP",
        adm0_a3_tr: "SGP",
        adm0_a3_id: "SGP",
        adm0_a3_pl: "SGP",
        adm0_a3_gr: "SGP",
        adm0_a3_it: "SGP",
        adm0_a3_nl: "SGP",
        adm0_a3_se: "SGP",
        adm0_a3_bd: "SGP",
        adm0_a3_ua: "SGP",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "South-Eastern Asia",
        region_wb: "East Asia & Pacific",
        name_len: 9,
        long_len: 9,
        abbrev_len: 5,
        tiny: 3,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: 103.816925,
        label_y: 1.366587,
        ne_id: 1159321247,
        wikidataid: "Q334",
        name_ar: "سنغافورة",
        name_bn: "সিঙ্গাপুর",
        name_de: "Singapur",
        name_en: "Singapore",
        name_es: "Singapur",
        name_fa: "سنگاپور",
        name_fr: "Singapour",
        name_el: "Σιγκαπούρη",
        name_he: "סינגפור",
        name_hi: "सिंगापुर",
        name_hu: "Szingapúr",
        name_id: "Singapura",
        name_it: "Singapore",
        name_ja: "シンガポール",
        name_ko: "싱가포르",
        name_nl: "Singapore",
        name_pl: "Singapur",
        name_pt: "Singapura",
        name_ru: "Сингапур",
        name_sv: "Singapore",
        name_tr: "Singapur",
        name_uk: "Сінгапур",
        name_ur: "سنگاپور",
        name_vi: "Singapore",
        name_zh: "新加坡",
        name_zht: "新加坡",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "SGP.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [103.9697265625, 1.331445312499994],
            [103.81992187500003, 1.265380859375],
            [103.65019531249999, 1.325537109374991],
            [103.70527343750001, 1.4234375],
            [103.81796875000003, 1.447070312499989],
            [103.90898437499999, 1.415966796874997],
            [103.96083984375002, 1.392236328124994],
            [103.99638671874999, 1.365234375],
            [103.9697265625, 1.331445312499994],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 4,
        sovereignt: "Bahrain",
        sov_a3: "BHR",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Bahrain",
        adm0_a3: "BHR",
        geou_dif: 0,
        geounit: "Bahrain",
        gu_a3: "BHR",
        su_dif: 0,
        subunit: "Bahrain",
        su_a3: "BHR",
        brk_diff: 0,
        name: "Bahrain",
        name_long: "Bahrain",
        brk_a3: "BHR",
        brk_name: "Bahrain",
        brk_group: null,
        abbrev: "Bahr.",
        postal: "BH",
        formal_en: "Kingdom of Bahrain",
        formal_fr: null,
        name_ciawf: "Bahrain",
        note_adm0: null,
        note_brk: null,
        name_sort: "Bahrain",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 1,
        mapcolor9: 1,
        mapcolor13: 9,
        pop_est: 1641172,
        pop_rank: 12,
        pop_year: 2019,
        gdp_md: 38574,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "2. High income: nonOECD",
        fips_10: "BA",
        iso_a2: "BH",
        iso_a2_eh: "BH",
        iso_a3: "BHR",
        iso_a3_eh: "BHR",
        iso_n3: "048",
        iso_n3_eh: "048",
        un_a3: "048",
        wb_a2: "BH",
        wb_a3: "BHR",
        woe_id: 23424753,
        woe_id_eh: 23424753,
        woe_note: "Exact WOE match as country",
        adm0_iso: "BHR",
        adm0_diff: null,
        adm0_tlc: "BHR",
        adm0_a3_us: "BHR",
        adm0_a3_fr: "BHR",
        adm0_a3_ru: "BHR",
        adm0_a3_es: "BHR",
        adm0_a3_cn: "BHR",
        adm0_a3_tw: "BHR",
        adm0_a3_in: "BHR",
        adm0_a3_np: "BHR",
        adm0_a3_pk: "BHR",
        adm0_a3_de: "BHR",
        adm0_a3_gb: "BHR",
        adm0_a3_br: "BHR",
        adm0_a3_il: "BHR",
        adm0_a3_ps: "BHR",
        adm0_a3_sa: "BHR",
        adm0_a3_eg: "BHR",
        adm0_a3_ma: "BHR",
        adm0_a3_pt: "BHR",
        adm0_a3_ar: "BHR",
        adm0_a3_jp: "BHR",
        adm0_a3_ko: "BHR",
        adm0_a3_vn: "BHR",
        adm0_a3_tr: "BHR",
        adm0_a3_id: "BHR",
        adm0_a3_pl: "BHR",
        adm0_a3_gr: "BHR",
        adm0_a3_it: "BHR",
        adm0_a3_nl: "BHR",
        adm0_a3_se: "BHR",
        adm0_a3_bd: "BHR",
        adm0_a3_ua: "BHR",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Western Asia",
        region_wb: "Middle East & North Africa",
        name_len: 7,
        long_len: 7,
        abbrev_len: 5,
        tiny: 2,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: 50.554816,
        label_y: 26.055972,
        ne_id: 1159320413,
        wikidataid: "Q398",
        name_ar: "البحرين",
        name_bn: "বাহরাইন",
        name_de: "Bahrain",
        name_en: "Bahrain",
        name_es: "Baréin",
        name_fa: "بحرین",
        name_fr: "Bahreïn",
        name_el: "Μπαχρέιν",
        name_he: "בחריין",
        name_hi: "बहरीन",
        name_hu: "Bahrein",
        name_id: "Bahrain",
        name_it: "Bahrein",
        name_ja: "バーレーン",
        name_ko: "바레인",
        name_nl: "Bahrein",
        name_pl: "Bahrajn",
        name_pt: "Bahrein",
        name_ru: "Бахрейн",
        name_sv: "Bahrain",
        name_tr: "Bahreyn",
        name_uk: "Бахрейн",
        name_ur: "بحرین",
        name_vi: "Bahrain",
        name_zh: "巴林",
        name_zht: "巴林",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "BHR.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [50.60722656250002, 25.883105468750003],
            [50.57490234375001, 25.806787109374994],
            [50.544042968750006, 25.83349609375],
            [50.465917968750006, 25.965527343749997],
            [50.48945312500001, 26.058447265625],
            [50.45244140625002, 26.190820312499994],
            [50.46992187500001, 26.228955078124997],
            [50.5640625, 26.246435546875],
            [50.5859375, 26.24072265625],
            [50.55781250000001, 26.198291015624996],
            [50.60976562500002, 26.124462890624997],
            [50.61748046875002, 26.00234375],
            [50.60722656250002, 25.883105468750003],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 3,
        labelrank: 4,
        sovereignt: "China",
        sov_a3: "CH1",
        adm0_dif: 1,
        level: 2,
        type: "Country",
        tlc: "1",
        admin: "Macao S.A.R",
        adm0_a3: "MAC",
        geou_dif: 0,
        geounit: "Macao S.A.R",
        gu_a3: "MAC",
        su_dif: 0,
        subunit: "Macao S.A.R",
        su_a3: "MAC",
        brk_diff: 0,
        name: "Macao",
        name_long: "Macao",
        brk_a3: "MAC",
        brk_name: "Macao",
        brk_group: null,
        abbrev: "Mac.",
        postal: "MO",
        formal_en: "Macao Special Administrative Region, PRC",
        formal_fr: null,
        name_ciawf: "Macau",
        note_adm0: "Cn.",
        note_brk: "China",
        name_sort: "Macao SAR, China",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 4,
        mapcolor9: 4,
        mapcolor13: 3,
        pop_est: 640445,
        pop_rank: 11,
        pop_year: 2019,
        gdp_md: 53859,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "2. High income: nonOECD",
        fips_10: "MC",
        iso_a2: "MO",
        iso_a2_eh: "MO",
        iso_a3: "MAC",
        iso_a3_eh: "MAC",
        iso_n3: "446",
        iso_n3_eh: "446",
        un_a3: "446",
        wb_a2: "MO",
        wb_a3: "MAC",
        woe_id: 20070017,
        woe_id_eh: 20070017,
        woe_note: "Exact WOE match as country",
        adm0_iso: "MAC",
        adm0_diff: null,
        adm0_tlc: "MAC",
        adm0_a3_us: "MAC",
        adm0_a3_fr: "MAC",
        adm0_a3_ru: "MAC",
        adm0_a3_es: "MAC",
        adm0_a3_cn: "MAC",
        adm0_a3_tw: "MAC",
        adm0_a3_in: "MAC",
        adm0_a3_np: "MAC",
        adm0_a3_pk: "MAC",
        adm0_a3_de: "MAC",
        adm0_a3_gb: "MAC",
        adm0_a3_br: "MAC",
        adm0_a3_il: "MAC",
        adm0_a3_ps: "MAC",
        adm0_a3_sa: "MAC",
        adm0_a3_eg: "MAC",
        adm0_a3_ma: "MAC",
        adm0_a3_pt: "MAC",
        adm0_a3_ar: "MAC",
        adm0_a3_jp: "MAC",
        adm0_a3_ko: "MAC",
        adm0_a3_vn: "MAC",
        adm0_a3_tr: "MAC",
        adm0_a3_id: "MAC",
        adm0_a3_pl: "MAC",
        adm0_a3_gr: "MAC",
        adm0_a3_it: "MAC",
        adm0_a3_nl: "MAC",
        adm0_a3_se: "MAC",
        adm0_a3_bd: "MAC",
        adm0_a3_ua: "MAC",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Eastern Asia",
        region_wb: "East Asia & Pacific",
        name_len: 5,
        long_len: 5,
        abbrev_len: 4,
        tiny: -99,
        homepart: -99,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: 113.556038,
        label_y: 22.129735,
        ne_id: 1159320475,
        wikidataid: "Q14773",
        name_ar: "ماكاو",
        name_bn: "মাকাও",
        name_de: "Macau",
        name_en: "Macau",
        name_es: "Macao",
        name_fa: "ماکائو",
        name_fr: "Macao",
        name_el: "Μακάου",
        name_he: "מקאו",
        name_hi: "मकाउ",
        name_hu: "Makaó",
        name_id: "Makau",
        name_it: "Macao",
        name_ja: "マカオ",
        name_ko: "마카오",
        name_nl: "Macau",
        name_pl: "Makau",
        name_pt: "Macau",
        name_ru: "Макао",
        name_sv: "Macao",
        name_tr: "Makao",
        name_uk: "Аоминь",
        name_ur: "مکاؤ",
        name_vi: "Ma Cao",
        name_zh: "澳门",
        name_zht: "澳門",
        fclass_iso: "Admin-0 dependency",
        tlc_diff: null,
        fclass_tlc: "Admin-0 dependency",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: "Admin-1 region",
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "MAC.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [113.47890625000002, 22.195556640625],
            [113.48105468750003, 22.217480468749997],
            [113.494140625, 22.24155273437499],
            [113.52705078125001, 22.24594726562499],
            [113.54814453124999, 22.222607421874997],
            [113.54550781250003, 22.221484375],
            [113.49882812499999, 22.20166015625],
            [113.48417968749999, 22.19775390625],
            [113.47890625000002, 22.195556640625],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Bolivia",
        sov_a3: "BOL",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Bolivia",
        adm0_a3: "BOL",
        geou_dif: 0,
        geounit: "Bolivia",
        gu_a3: "BOL",
        su_dif: 0,
        subunit: "Bolivia",
        su_a3: "BOL",
        brk_diff: 0,
        name: "Bolivia",
        name_long: "Bolivia",
        brk_a3: "BOL",
        brk_name: "Bolivia",
        brk_group: null,
        abbrev: "Bolivia",
        postal: "BO",
        formal_en: "Plurinational State of Bolivia",
        formal_fr: null,
        name_ciawf: "Bolivia",
        note_adm0: null,
        note_brk: null,
        name_sort: "Bolivia",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 5,
        mapcolor9: 2,
        mapcolor13: 3,
        pop_est: 11513100,
        pop_rank: 14,
        pop_year: 2019,
        gdp_md: 40895,
        gdp_year: 2019,
        economy: "5. Emerging region: G20",
        income_grp: "4. Lower middle income",
        fips_10: "BL",
        iso_a2: "BO",
        iso_a2_eh: "BO",
        iso_a3: "BOL",
        iso_a3_eh: "BOL",
        iso_n3: "068",
        iso_n3_eh: "068",
        un_a3: "068",
        wb_a2: "BO",
        wb_a3: "BOL",
        woe_id: 23424762,
        woe_id_eh: 23424762,
        woe_note: "Exact WOE match as country",
        adm0_iso: "BOL",
        adm0_diff: null,
        adm0_tlc: "BOL",
        adm0_a3_us: "BOL",
        adm0_a3_fr: "BOL",
        adm0_a3_ru: "BOL",
        adm0_a3_es: "BOL",
        adm0_a3_cn: "BOL",
        adm0_a3_tw: "BOL",
        adm0_a3_in: "BOL",
        adm0_a3_np: "BOL",
        adm0_a3_pk: "BOL",
        adm0_a3_de: "BOL",
        adm0_a3_gb: "BOL",
        adm0_a3_br: "BOL",
        adm0_a3_il: "BOL",
        adm0_a3_ps: "BOL",
        adm0_a3_sa: "BOL",
        adm0_a3_eg: "BOL",
        adm0_a3_ma: "BOL",
        adm0_a3_pt: "BOL",
        adm0_a3_ar: "BOL",
        adm0_a3_jp: "BOL",
        adm0_a3_ko: "BOL",
        adm0_a3_vn: "BOL",
        adm0_a3_tr: "BOL",
        adm0_a3_id: "BOL",
        adm0_a3_pl: "BOL",
        adm0_a3_gr: "BOL",
        adm0_a3_it: "BOL",
        adm0_a3_nl: "BOL",
        adm0_a3_se: "BOL",
        adm0_a3_bd: "BOL",
        adm0_a3_ua: "BOL",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "South America",
        region_un: "Americas",
        subregion: "South America",
        region_wb: "Latin America & Caribbean",
        name_len: 7,
        long_len: 7,
        abbrev_len: 7,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 7.5,
        label_x: -64.593433,
        label_y: -16.666015,
        ne_id: 1159320439,
        wikidataid: "Q750",
        name_ar: "بوليفيا",
        name_bn: "বলিভিয়া",
        name_de: "Bolivien",
        name_en: "Bolivia",
        name_es: "Bolivia",
        name_fa: "بولیوی",
        name_fr: "Bolivie",
        name_el: "Βολιβία",
        name_he: "בוליביה",
        name_hi: "बोलिविया",
        name_hu: "Bolívia",
        name_id: "Bolivia",
        name_it: "Bolivia",
        name_ja: "ボリビア",
        name_ko: "볼리비아",
        name_nl: "Bolivia",
        name_pl: "Boliwia",
        name_pt: "Bolívia",
        name_ru: "Боливия",
        name_sv: "Bolivia",
        name_tr: "Bolivya",
        name_uk: "Болівія",
        name_ur: "بولیویا",
        name_vi: "Bolivia",
        name_zh: "玻利维亚",
        name_zht: "玻利維亞",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "BOL.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-69.5109375, -17.50605468750001],
            [-69.51108398437499, -17.5048828125],
            [-69.510986328125, -17.46035156250001],
            [-69.521923828125, -17.388964843750003],
            [-69.56381835937499, -17.33291015625001],
            [-69.62587890625, -17.29443359375],
            [-69.645703125, -17.24853515625],
            [-69.62485351562499, -17.2001953125],
            [-69.5033203125, -17.104785156250003],
            [-69.43833007812499, -17.08837890625],
            [-69.42109375, -17.0400390625],
            [-69.38154296875, -17.00136718750001],
            [-69.267236328125, -16.8609375],
            [-69.19980468749999, -16.76845703125001],
            [-69.13251953125, -16.71308593750001],
            [-69.05454101562499, -16.67431640625],
            [-69.020703125, -16.6421875],
            [-69.03837890624999, -16.54267578125001],
            [-69.03291015625, -16.47597656250001],
            [-69.00625, -16.433691406250006],
            [-68.92802734374999, -16.3890625],
            [-68.8578125, -16.354785156250003],
            [-68.8427734375, -16.337890625],
            [-68.848828125, -16.312792968750003],
            [-68.9134765625, -16.26191406250001],
            [-69.04624023437499, -16.21767578125001],
            [-69.1341796875, -16.221972656250003],
            [-69.18798828125, -16.18281250000001],
            [-69.21757812499999, -16.14912109375001],
            [-69.39189453124999, -15.736914062500006],
            [-69.4208984375, -15.640625],
            [-69.41850585937499, -15.603417968750009],
            [-69.301904296875, -15.3994140625],
            [-69.254296875, -15.332910156250009],
            [-69.17246093749999, -15.236621093750003],
            [-69.187109375, -15.19873046875],
            [-69.330712890625, -15.038964843750009],
            [-69.37470703125, -14.962988281250006],
            [-69.37373046875, -14.8875],
            [-69.35947265624999, -14.7953125],
            [-69.276025390625, -14.745898437500003],
            [-69.25234375, -14.671093750000011],
            [-69.23491210937499, -14.597070312500009],
            [-69.19926757812499, -14.572558593750003],
            [-69.16269531249999, -14.530957031250011],
            [-69.11972656249999, -14.4703125],
            [-69.05278320312499, -14.417578125],
            [-69.013134765625, -14.377246093750003],
            [-69.00449218749999, -14.265039062500009],
            [-68.97177734374999, -14.234375],
            [-68.880322265625, -14.198828125],
            [-68.87089843749999, -14.169726562500003],
            [-68.89169921874999, -14.094335937500006],
            [-68.937451171875, -14.0146484375],
            [-68.974267578125, -13.975976562500009],
            [-69.023046875, -13.7802734375],
            [-69.07412109375, -13.682812500000011],
            [-69.05283203124999, -13.643945312500009],
            [-69.017529296875, -13.594433593750011],
            [-68.98344726562499, -13.496386718750003],
            [-68.972265625, -13.38232421875],
            [-68.98051757812499, -12.962597656250011],
            [-68.97861328124999, -12.880078125000011],
            [-68.933740234375, -12.822070312500003],
            [-68.86767578125, -12.755175781250003],
            [-68.81181640624999, -12.729589843750006],
            [-68.75908203124999, -12.687207031250011],
            [-68.762890625, -12.607714843750003],
            [-68.728125, -12.560742187500011],
            [-68.68525390625, -12.501953125],
            [-68.81870117187499, -12.270410156250009],
            [-68.93603515625, -12.066796875],
            [-69.04619140624999, -11.875683593750011],
            [-69.17373046875, -11.654296875],
            [-69.25771484375, -11.50859375],
            [-69.36201171875, -11.327539062500009],
            [-69.45361328125, -11.16875],
            [-69.57861328125, -10.951757812500006],
            [-69.462548828125, -10.948144531250009],
            [-69.228515625, -10.955664062500006],
            [-69.00166015625, -10.994335937500011],
            [-68.84833984375, -11.011132812500009],
            [-68.78408203125, -11.044628906250011],
            [-68.769921875, -11.09765625],
            [-68.727490234375, -11.122460937500009],
            [-68.678369140625, -11.11279296875],
            [-68.62265625, -11.109179687500003],
            [-68.49833984374999, -11.054785156250006],
            [-68.39799804687499, -11.01875],
            [-68.31113281249999, -10.975195312500006],
            [-68.2666015625, -10.93310546875],
            [-68.158642578125, -10.785058593750009],
            [-68.0716796875, -10.703125],
            [-67.99169921875, -10.674414062500006],
            [-67.83500976562499, -10.662792968750011],
            [-67.78569335937499, -10.68603515625],
            [-67.72177734374999, -10.68310546875],
            [-67.666650390625, -10.598925781250003],
            [-67.582421875, -10.505957031250006],
            [-67.416943359375, -10.389843750000011],
            [-67.33271484375, -10.35791015625],
            [-67.28046875, -10.317285156250009],
            [-67.19047851562499, -10.311425781250009],
            [-67.1115234375, -10.268945312500009],
            [-66.72998046875, -9.975488281250009],
            [-66.575341796875, -9.89990234375],
            [-66.47890625, -9.886132812500009],
            [-66.39921874999999, -9.8681640625],
            [-66.26357421875, -9.826074218750009],
            [-65.92470703125, -9.785449218750003],
            [-65.706787109375, -9.768457031250009],
            [-65.637109375, -9.80908203125],
            [-65.55869140624999, -9.797460937500006],
            [-65.49199218749999, -9.731738281250003],
            [-65.436767578125, -9.71044921875],
            [-65.396142578125, -9.71240234375],
            [-65.337890625, -9.790234375000011],
            [-65.309326171875, -9.87265625],
            [-65.328125, -9.935546875],
            [-65.32456054687499, -10.026953125],
            [-65.298583984375, -10.146777343750003],
            [-65.31308593749999, -10.253027343750006],
            [-65.395458984375, -10.392285156250011],
            [-65.4369140625, -10.449023437500003],
            [-65.44711914062499, -10.507421875],
            [-65.43999023437499, -10.586230468750003],
            [-65.402294921875, -10.714746093750009],
            [-65.33403320312499, -10.892773437500011],
            [-65.32377929687499, -11.024804687500009],
            [-65.37158203125, -11.1103515625],
            [-65.39360351562499, -11.184277343750011],
            [-65.389892578125, -11.246289062500011],
            [-65.37285156249999, -11.289941406250009],
            [-65.34238281249999, -11.315039062500006],
            [-65.32548828124999, -11.36474609375],
            [-65.322021484375, -11.439160156250011],
            [-65.28227539062499, -11.511035156250003],
            [-65.206201171875, -11.58056640625],
            [-65.175390625, -11.646875],
            [-65.18974609374999, -11.710058593750006],
            [-65.1857421875, -11.74951171875],
            [-65.16337890624999, -11.76513671875],
            [-65.14267578124999, -11.75234375],
            [-65.11513671875, -11.735058593750011],
            [-65.090283203125, -11.7412109375],
            [-65.037109375, -11.829394531250003],
            [-65.0302734375, -11.847363281250011],
            [-65.001220703125, -11.920019531250006],
            [-64.99252929687499, -11.975195312500006],
            [-64.91435546874999, -12.005957031250006],
            [-64.82988281249999, -12.0302734375],
            [-64.783447265625, -12.059375],
            [-64.69003906249999, -12.146484375],
            [-64.61166992187499, -12.20390625],
            [-64.513427734375, -12.2509765625],
            [-64.48076171874999, -12.326171875],
            [-64.42050781249999, -12.439746093750003],
            [-64.255029296875, -12.483300781250009],
            [-64.06162109374999, -12.505078125000011],
            [-63.93857421874999, -12.5296875],
            [-63.7880859375, -12.469433593750011],
            [-63.68857421874999, -12.47802734375],
            [-63.58564453125, -12.518945312500009],
            [-63.541894531249994, -12.546679687500003],
            [-63.465234375, -12.605175781250011],
            [-63.3466796875, -12.680078125],
            [-63.249755859375, -12.707910156250009],
            [-63.1806640625, -12.666210937500011],
            [-63.116796875, -12.651660156250003],
            [-63.06748046874999, -12.669140625000011],
            [-63.04135742187499, -12.750390625],
            [-63.01518554687499, -12.805566406250009],
            [-62.957910156249994, -12.847070312500009],
            [-62.83515625, -12.953710937500006],
            [-62.76547851562499, -12.997265625000011],
            [-62.68706054687499, -12.994335937500011],
            [-62.525537109374994, -13.064257812500003],
            [-62.35283203124999, -13.132421875],
            [-62.263916015625, -13.143652343750006],
            [-62.17607421874999, -13.133691406250009],
            [-62.11801757812499, -13.159765625],
            [-62.09477539062499, -13.241992187500003],
            [-61.944726562499994, -13.40625],
            [-61.87412109374999, -13.470410156250011],
            [-61.789941406249994, -13.525585937500011],
            [-61.57568359375, -13.524804687500009],
            [-61.51157226562499, -13.541210937500011],
            [-61.41606445312499, -13.526562500000011],
            [-61.129150390625, -13.49853515625],
            [-61.07700195312499, -13.48974609375],
            [-60.914501953125, -13.561425781250009],
            [-60.72236328125, -13.664355468750003],
            [-60.5953125, -13.745312500000011],
            [-60.506591796875, -13.78984375],
            [-60.46015625, -13.862402343750006],
            [-60.42236328125, -13.93798828125],
            [-60.40498046875, -14.019238281250011],
            [-60.42807617187499, -14.1],
            [-60.46298828124999, -14.132421875],
            [-60.47465820312499, -14.184765625000011],
            [-60.46015625, -14.263085937500009],
            [-60.396240234375, -14.3328125],
            [-60.372705078124994, -14.41875],
            [-60.338037109374994, -14.570507812500011],
            [-60.29887695312499, -14.618554687500009],
            [-60.273339843749994, -15.088769531250009],
            [-60.402001953124994, -15.0927734375],
            [-60.583203125, -15.098339843750011],
            [-60.53046875, -15.143164062500006],
            [-60.38046875, -15.318261718750009],
            [-60.242333984374994, -15.479589843750006],
            [-60.22041015625, -15.738671875],
            [-60.206640625, -15.901953125],
            [-60.18720703125, -16.132128906250003],
            [-60.17558593749999, -16.269335937500003],
            [-59.83115234374999, -16.28173828125],
            [-59.43427734375, -16.295996093750006],
            [-58.957275390625, -16.31318359375001],
            [-58.53793945312499, -16.328222656250006],
            [-58.49658203125, -16.32666015625],
            [-58.42368164062499, -16.307910156250003],
            [-58.375390625, -16.28359375],
            [-58.345605468749994, -16.284375],
            [-58.340576171875, -16.339941406250006],
            [-58.35078125, -16.410253906250006],
            [-58.35039062499999, -16.490820312500006],
            [-58.470605468749994, -16.650195312500003],
            [-58.478125, -16.70068359375],
            [-58.45981445312499, -16.910742187500006],
            [-58.417382812499994, -17.08056640625],
            [-58.39599609375, -17.23427734375001],
            [-58.34775390624999, -17.28212890625001],
            [-58.20556640625, -17.363085937500003],
            [-57.99091796875, -17.51289062500001],
            [-57.905029296875, -17.532324218750006],
            [-57.832470703125, -17.512109375],
            [-57.78886718749999, -17.573046875],
            [-57.780175781249994, -17.67177734375001],
            [-57.66166992187499, -17.947363281250006],
            [-57.58647460937499, -18.12226562500001],
            [-57.55205078124999, -18.18310546875],
            [-57.49565429687499, -18.214648437500003],
            [-57.50615234374999, -18.2373046875],
            [-57.553125, -18.246484375],
            [-57.57402343749999, -18.279296875],
            [-57.63916015625, -18.475],
            [-57.725, -18.733203125],
            [-57.783105468749994, -18.91425781250001],
            [-57.730859375, -18.91718750000001],
            [-57.72861328124999, -18.967382812500006],
            [-57.716796875, -19.044042968750006],
            [-57.7814453125, -19.053515625],
            [-57.800390625, -19.08095703125001],
            [-57.87451171875, -19.2294921875],
            [-57.9716796875, -19.42421875],
            [-58.072021484375, -19.625292968750003],
            [-58.131494140624994, -19.74453125],
            [-58.029931640624994, -19.83271484375001],
            [-57.860742187499994, -19.979589843750006],
            [-57.887597656249994, -20.02041015625001],
            [-57.96015625, -20.04072265625001],
            [-58.021142578124994, -20.05517578125],
            [-58.067626953125, -20.1103515625],
            [-58.09375, -20.15107421875001],
            [-58.159765625, -20.164648437500006],
            [-58.13994140624999, -19.998828125],
            [-58.160058593749994, -19.85488281250001],
            [-58.18017578125, -19.81787109375],
            [-58.47421875, -19.64609375],
            [-58.741113281249994, -19.490234375],
            [-59.09052734375, -19.286230468750006],
            [-59.54086914062499, -19.291796875],
            [-60.00737304687499, -19.29755859375001],
            [-60.451611328125, -19.388769531250006],
            [-60.88876953124999, -19.478515625],
            [-61.09599609374999, -19.52099609375],
            [-61.51181640624999, -19.6064453125],
            [-61.7568359375, -19.6453125],
            [-61.82089843749999, -19.80947265625001],
            [-61.916943359375, -20.05537109375001],
            [-62.01181640624999, -20.199023437500003],
            [-62.12163085937499, -20.349902343750003],
            [-62.276318359375, -20.5625],
            [-62.276513671874994, -20.82080078125],
            [-62.27666015624999, -21.066015625],
            [-62.38544921875, -21.41171875],
            [-62.47783203124999, -21.70527343750001],
            [-62.56694335937499, -21.988671875],
            [-62.628515625, -22.183984375],
            [-62.65097656249999, -22.233691406250003],
            [-62.665283203125, -22.21796875000001],
            [-62.744580078125, -22.15986328125001],
            [-62.815087890624994, -22.049609375],
            [-62.83427734374999, -21.999121093750006],
            [-62.843359375, -21.99726562500001],
            [-63.2671875, -22.000585937500006],
            [-63.67534179687499, -22.004296875],
            [-63.716943359374994, -22.02753906250001],
            [-63.7755859375, -22.02724609375001],
            [-63.81865234374999, -22.00546875],
            [-63.86103515625, -22.00722656250001],
            [-63.92167968749999, -22.028613281250003],
            [-63.97612304687499, -22.072558593750003],
            [-64.1318359375, -22.365820312500006],
            [-64.20908203124999, -22.491308593750006],
            [-64.26640624999999, -22.603320312500003],
            [-64.30791015624999, -22.7953125],
            [-64.32529296874999, -22.82763671875],
            [-64.373974609375, -22.761035156250003],
            [-64.4455078125, -22.58535156250001],
            [-64.477734375, -22.4853515625],
            [-64.5236328125, -22.37158203125],
            [-64.60551757812499, -22.228808593750003],
            [-64.70009765625, -22.185546875],
            [-64.75864257812499, -22.17128906250001],
            [-64.84306640624999, -22.14394531250001],
            [-64.992626953125, -22.109667968750003],
            [-65.0578125, -22.10273437500001],
            [-65.48486328125, -22.09814453125],
            [-65.518798828125, -22.09453125],
            [-65.686181640625, -22.11025390625001],
            [-65.77104492187499, -22.099609375],
            [-65.86015624999999, -22.01972656250001],
            [-66.05859375, -21.879492187500006],
            [-66.098583984375, -21.835058593750006],
            [-66.17465820312499, -21.8056640625],
            [-66.220166015625, -21.802539062500003],
            [-66.24760742187499, -21.83046875],
            [-66.28212890625, -21.94746093750001],
            [-66.3224609375, -22.053125],
            [-66.365185546875, -22.11376953125],
            [-66.506982421875, -22.15839843750001],
            [-66.63901367187499, -22.205371093750003],
            [-66.71171874999999, -22.21630859375],
            [-66.750634765625, -22.269335937500003],
            [-66.76748046875, -22.343066406250003],
            [-66.80029296875, -22.40966796875],
            [-66.99111328125, -22.509863281250006],
            [-67.03354492187499, -22.55224609375],
            [-67.055419921875, -22.65087890625],
            [-67.16191406249999, -22.773828125],
            [-67.19487304687499, -22.82167968750001],
            [-67.362255859375, -22.85517578125001],
            [-67.57993164062499, -22.891699218750006],
            [-67.70732421874999, -22.88916015625],
            [-67.79443359375, -22.879492187500006],
            [-67.8205078125, -22.857714843750003],
            [-67.87944335937499, -22.82294921875001],
            [-67.88916015625, -22.7841796875],
            [-67.88999023437499, -22.72919921875001],
            [-67.87373046875, -22.63056640625001],
            [-67.88173828125, -22.49335937500001],
            [-67.950390625, -22.33369140625001],
            [-67.944921875, -22.2822265625],
            [-67.95390624999999, -22.20400390625001],
            [-67.98837890624999, -22.05712890625],
            [-68.07675781249999, -21.9828125],
            [-68.101806640625, -21.860644531250003],
            [-68.11215820312499, -21.753027343750006],
            [-68.18642578125, -21.61855468750001],
            [-68.19853515624999, -21.447265625],
            [-68.197021484375, -21.30029296875],
            [-68.3138671875, -21.1296875],
            [-68.435498046875, -20.9482421875],
            [-68.533837890625, -20.923632812500003],
            [-68.558251953125, -20.901953125],
            [-68.56894531249999, -20.84980468750001],
            [-68.571044921875, -20.769140625],
            [-68.56318359375, -20.72011718750001],
            [-68.48740234374999, -20.640722656250006],
            [-68.484326171875, -20.62841796875],
            [-68.49985351562499, -20.61201171875001],
            [-68.69580078125, -20.49296875],
            [-68.74516601562499, -20.45859375],
            [-68.76054687499999, -20.41621093750001],
            [-68.759228515625, -20.378027343750006],
            [-68.7123046875, -20.338964843750006],
            [-68.68857421874999, -20.31005859375],
            [-68.7345703125, -20.225195312500006],
            [-68.73002929687499, -20.1484375],
            [-68.75932617187499, -20.115527343750003],
            [-68.755810546875, -20.0908203125],
            [-68.727490234375, -20.069628906250003],
            [-68.60019531249999, -20.044921875],
            [-68.560693359375, -19.967089843750003],
            [-68.559375, -19.90234375],
            [-68.578271484375, -19.856542968750006],
            [-68.69619140625, -19.74072265625],
            [-68.69829101562499, -19.72109375],
            [-68.57529296874999, -19.56015625],
            [-68.48701171875, -19.454394531250003],
            [-68.462890625, -19.43281250000001],
            [-68.470166015625, -19.409960937500003],
            [-68.49199218749999, -19.381933593750006],
            [-68.5478515625, -19.341113281250003],
            [-68.620556640625, -19.296679687500003],
            [-68.6806640625, -19.24238281250001],
            [-68.75908203124999, -19.162207031250006],
            [-68.85795898437499, -19.093359375],
            [-68.93100585937499, -19.025195312500003],
            [-68.96831054687499, -18.96796875000001],
            [-68.96909179687499, -18.90966796875],
            [-68.97885742187499, -18.81298828125],
            [-69.026806640625, -18.65625],
            [-69.03940429687499, -18.550097656250003],
            [-69.06015625, -18.43300781250001],
            [-69.08095703125, -18.35664062500001],
            [-69.09228515625, -18.28242187500001],
            [-69.1263671875, -18.202441406250003],
            [-69.145458984375, -18.14404296875],
            [-69.11806640625, -18.10273437500001],
            [-69.09042968749999, -18.070703125],
            [-69.0939453125, -18.05048828125001],
            [-69.28232421874999, -17.96484375],
            [-69.31337890625, -17.943164062500003],
            [-69.35800781249999, -17.77167968750001],
            [-69.49501953125, -17.61953125],
            [-69.5109375, -17.50605468750001],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 4,
        labelrank: 2,
        sovereignt: "Chile",
        sov_a3: "CHL",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Chile",
        adm0_a3: "CHL",
        geou_dif: 0,
        geounit: "Chile",
        gu_a3: "CHL",
        su_dif: 0,
        subunit: "Chile",
        su_a3: "CHL",
        brk_diff: 0,
        name: "Chile",
        name_long: "Chile",
        brk_a3: "CHL",
        brk_name: "Chile",
        brk_group: null,
        abbrev: "Chile",
        postal: "CL",
        formal_en: "Republic of Chile",
        formal_fr: null,
        name_ciawf: "Chile",
        note_adm0: null,
        note_brk: null,
        name_sort: "Chile",
        name_alt: null,
        mapcolor7: 5,
        mapcolor8: 1,
        mapcolor9: 5,
        mapcolor13: 9,
        pop_est: 18952038,
        pop_rank: 14,
        pop_year: 2019,
        gdp_md: 282318,
        gdp_year: 2019,
        economy: "5. Emerging region: G20",
        income_grp: "3. Upper middle income",
        fips_10: "CI",
        iso_a2: "CL",
        iso_a2_eh: "CL",
        iso_a3: "CHL",
        iso_a3_eh: "CHL",
        iso_n3: "152",
        iso_n3_eh: "152",
        un_a3: "152",
        wb_a2: "CL",
        wb_a3: "CHL",
        woe_id: 23424782,
        woe_id_eh: 23424782,
        woe_note: "Exact WOE match as country",
        adm0_iso: "CHL",
        adm0_diff: null,
        adm0_tlc: "CHL",
        adm0_a3_us: "CHL",
        adm0_a3_fr: "CHL",
        adm0_a3_ru: "CHL",
        adm0_a3_es: "CHL",
        adm0_a3_cn: "CHL",
        adm0_a3_tw: "CHL",
        adm0_a3_in: "CHL",
        adm0_a3_np: "CHL",
        adm0_a3_pk: "CHL",
        adm0_a3_de: "CHL",
        adm0_a3_gb: "CHL",
        adm0_a3_br: "CHL",
        adm0_a3_il: "CHL",
        adm0_a3_ps: "CHL",
        adm0_a3_sa: "CHL",
        adm0_a3_eg: "CHL",
        adm0_a3_ma: "CHL",
        adm0_a3_pt: "CHL",
        adm0_a3_ar: "CHL",
        adm0_a3_jp: "CHL",
        adm0_a3_ko: "CHL",
        adm0_a3_vn: "CHL",
        adm0_a3_tr: "CHL",
        adm0_a3_id: "CHL",
        adm0_a3_pl: "CHL",
        adm0_a3_gr: "CHL",
        adm0_a3_it: "CHL",
        adm0_a3_nl: "CHL",
        adm0_a3_se: "CHL",
        adm0_a3_bd: "CHL",
        adm0_a3_ua: "CHL",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "South America",
        region_un: "Americas",
        subregion: "South America",
        region_wb: "Latin America & Caribbean",
        name_len: 5,
        long_len: 5,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 1.7,
        max_label: 6.7,
        label_x: -72.318871,
        label_y: -38.151771,
        ne_id: 1159320493,
        wikidataid: "Q298",
        name_ar: "تشيلي",
        name_bn: "চিলি",
        name_de: "Chile",
        name_en: "Chile",
        name_es: "Chile",
        name_fa: "شیلی",
        name_fr: "Chili",
        name_el: "Χιλή",
        name_he: "צ'ילה",
        name_hi: "चिली",
        name_hu: "Chile",
        name_id: "Chili",
        name_it: "Cile",
        name_ja: "チリ",
        name_ko: "칠레",
        name_nl: "Chili",
        name_pl: "Chile",
        name_pt: "Chile",
        name_ru: "Чили",
        name_sv: "Chile",
        name_tr: "Şili",
        name_uk: "Чилі",
        name_ur: "چلی",
        name_vi: "Chile",
        name_zh: "智利",
        name_zht: "智利",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "CHL.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-109.27998046875, -27.140429687500003],
              [-109.434130859375, -27.17128906250001],
              [-109.429150390625, -27.1162109375],
              [-109.39047851562499, -27.068359375],
              [-109.27646484374999, -27.09589843750001],
              [-109.2228515625, -27.10107421875],
              [-109.27998046875, -27.140429687500003],
            ],
          ],
          [
            [
              [-78.804150390625, -33.646484375],
              [-78.983349609375, -33.6677734375],
              [-78.989453125, -33.66171875],
              [-78.97929687499999, -33.644140625],
              [-78.938134765625, -33.61357421875],
              [-78.88828125, -33.57636718750001],
              [-78.87744140625, -33.5751953125],
              [-78.859033203125, -33.578125],
              [-78.83818359374999, -33.585058593750006],
              [-78.78466796875, -33.61015625],
              [-78.7689453125, -33.62734375],
              [-78.77470703124999, -33.6416015625],
              [-78.804150390625, -33.646484375],
            ],
          ],
          [
            [
              [-70.41826171874999, -18.34560546875001],
              [-70.37749023437499, -18.33359375],
              [-70.28227539062499, -18.32539062500001],
              [-70.1837890625, -18.3251953125],
              [-70.05908203125, -18.283496093750003],
              [-69.92636718749999, -18.2060546875],
              [-69.83969726562499, -18.09345703125001],
              [-69.80258789062499, -17.990234375],
              [-69.80244140625, -17.9],
              [-69.84150390625, -17.78515625],
              [-69.85209960937499, -17.70380859375001],
              [-69.806103515625, -17.66494140625001],
              [-69.684765625, -17.64980468750001],
              [-69.58642578125, -17.5732421875],
              [-69.5109375, -17.50605468750001],
              [-69.49501953125, -17.61953125],
              [-69.35800781249999, -17.77167968750001],
              [-69.31337890625, -17.943164062500003],
              [-69.28232421874999, -17.96484375],
              [-69.0939453125, -18.05048828125001],
              [-69.09042968749999, -18.070703125],
              [-69.11806640625, -18.10273437500001],
              [-69.145458984375, -18.14404296875],
              [-69.1263671875, -18.202441406250003],
              [-69.09228515625, -18.28242187500001],
              [-69.08095703125, -18.35664062500001],
              [-69.06015625, -18.43300781250001],
              [-69.03940429687499, -18.550097656250003],
              [-69.026806640625, -18.65625],
              [-68.97885742187499, -18.81298828125],
              [-68.96909179687499, -18.90966796875],
              [-68.96831054687499, -18.96796875000001],
              [-68.93100585937499, -19.025195312500003],
              [-68.85795898437499, -19.093359375],
              [-68.75908203124999, -19.162207031250006],
              [-68.6806640625, -19.24238281250001],
              [-68.620556640625, -19.296679687500003],
              [-68.5478515625, -19.341113281250003],
              [-68.49199218749999, -19.381933593750006],
              [-68.470166015625, -19.409960937500003],
              [-68.462890625, -19.43281250000001],
              [-68.48701171875, -19.454394531250003],
              [-68.57529296874999, -19.56015625],
              [-68.69829101562499, -19.72109375],
              [-68.69619140625, -19.74072265625],
              [-68.578271484375, -19.856542968750006],
              [-68.559375, -19.90234375],
              [-68.560693359375, -19.967089843750003],
              [-68.60019531249999, -20.044921875],
              [-68.727490234375, -20.069628906250003],
              [-68.755810546875, -20.0908203125],
              [-68.75932617187499, -20.115527343750003],
              [-68.73002929687499, -20.1484375],
              [-68.7345703125, -20.225195312500006],
              [-68.68857421874999, -20.31005859375],
              [-68.7123046875, -20.338964843750006],
              [-68.759228515625, -20.378027343750006],
              [-68.76054687499999, -20.41621093750001],
              [-68.74516601562499, -20.45859375],
              [-68.69580078125, -20.49296875],
              [-68.49985351562499, -20.61201171875001],
              [-68.484326171875, -20.62841796875],
              [-68.48740234374999, -20.640722656250006],
              [-68.56318359375, -20.72011718750001],
              [-68.571044921875, -20.769140625],
              [-68.56894531249999, -20.84980468750001],
              [-68.558251953125, -20.901953125],
              [-68.533837890625, -20.923632812500003],
              [-68.435498046875, -20.9482421875],
              [-68.3138671875, -21.1296875],
              [-68.197021484375, -21.30029296875],
              [-68.19853515624999, -21.447265625],
              [-68.18642578125, -21.61855468750001],
              [-68.11215820312499, -21.753027343750006],
              [-68.101806640625, -21.860644531250003],
              [-68.07675781249999, -21.9828125],
              [-67.98837890624999, -22.05712890625],
              [-67.95390624999999, -22.20400390625001],
              [-67.944921875, -22.2822265625],
              [-67.950390625, -22.33369140625001],
              [-67.88173828125, -22.49335937500001],
              [-67.87373046875, -22.63056640625001],
              [-67.88999023437499, -22.72919921875001],
              [-67.88916015625, -22.7841796875],
              [-67.87944335937499, -22.82294921875001],
              [-67.8205078125, -22.857714843750003],
              [-67.79443359375, -22.879492187500006],
              [-67.70732421874999, -22.88916015625],
              [-67.57993164062499, -22.891699218750006],
              [-67.362255859375, -22.85517578125001],
              [-67.19487304687499, -22.82167968750001],
              [-67.0087890625, -23.00136718750001],
              [-67.08974609375, -23.2451171875],
              [-67.219140625, -23.63398437500001],
              [-67.31914062499999, -23.934667968750006],
              [-67.335595703125, -23.97480468750001],
              [-67.356201171875, -24.033789062500006],
              [-67.57177734375, -24.118945312500003],
              [-67.88623046875, -24.24335937500001],
              [-68.04736328125, -24.30830078125001],
              [-68.25029296874999, -24.39199218750001],
              [-68.29951171875, -24.46035156250001],
              [-68.35810546875, -24.49726562500001],
              [-68.42255859375, -24.54511718750001],
              [-68.44711914062499, -24.596972656250003],
              [-68.507275390625, -24.62978515625001],
              [-68.56201171875, -24.747363281250003],
              [-68.56201171875, -24.837695312500003],
              [-68.52705078125, -24.89921875],
              [-68.46630859375, -24.92519531250001],
              [-68.44711914062499, -24.99892578125001],
              [-68.42802734374999, -25.05097656250001],
              [-68.384228515625, -25.091894531250006],
              [-68.39521484375, -25.12470703125001],
              [-68.43071289062499, -25.14931640625001],
              [-68.496337890625, -25.16298828125001],
              [-68.54082031249999, -25.23671875],
              [-68.59208984374999, -25.420019531250006],
              [-68.60029296875, -25.485644531250003],
              [-68.54189453125, -25.65156250000001],
              [-68.51083984374999, -25.741015625],
              [-68.4267578125, -26.0654296875],
              [-68.414501953125, -26.15371093750001],
              [-68.52983398437499, -26.276953125],
              [-68.57578125, -26.351953125],
              [-68.5921875, -26.418066406250006],
              [-68.59160156249999, -26.47041015625001],
              [-68.58115234374999, -26.518359375],
              [-68.485107421875, -26.6703125],
              [-68.37333984374999, -26.806445312500003],
              [-68.31865234374999, -26.877539062500006],
              [-68.31865234374999, -26.973242187500006],
              [-68.34599609374999, -27.027929687500006],
              [-68.40537109374999, -27.048144531250003],
              [-68.537353515625, -27.08535156250001],
              [-68.59208984374999, -27.14003906250001],
              [-68.65219726562499, -27.14833984375001],
              [-68.709619140625, -27.1044921875],
              [-68.769775390625, -27.11542968750001],
              [-68.846337890625, -27.15371093750001],
              [-68.87509765624999, -27.246679687500006],
              [-68.94199218749999, -27.40517578125001],
              [-68.9994140625, -27.449023437500003],
              [-69.0421875, -27.57001953125001],
              [-69.11850585937499, -27.74355468750001],
              [-69.1552734375, -27.84814453125],
              [-69.17441406249999, -27.92470703125001],
              [-69.251220703125, -27.9736328125],
              [-69.34072265625, -28.07080078125],
              [-69.4095703125, -28.165332031250003],
              [-69.4369140625, -28.192675781250003],
              [-69.48886718749999, -28.20087890625001],
              [-69.52714843749999, -28.28564453125],
              [-69.65693359375, -28.41357421875],
              [-69.687890625, -28.56201171875],
              [-69.73491210937499, -28.64111328125],
              [-69.7431640625, -28.78388671875001],
              [-69.81484375, -29.045507812500006],
              [-69.827880859375, -29.10322265625001],
              [-69.900341796875, -29.148828125],
              [-69.99560546875, -29.25],
              [-70.026806640625, -29.324023437500003],
              [-69.9826171875, -29.54541015625],
              [-69.92763671875, -29.769140625],
              [-69.92412109374999, -29.8740234375],
              [-69.945458984375, -30.01640625],
              [-69.9599609375, -30.07832031250001],
              [-69.92353515625, -30.10390625],
              [-69.86337890624999, -30.12031250000001],
              [-69.84428710937499, -30.175],
              [-69.88803710937499, -30.21328125],
              [-69.90712890625, -30.281640625],
              [-69.95634765624999, -30.358203125],
              [-70.102001953125, -30.38828125],
              [-70.15322265625, -30.3609375],
              [-70.16962890625, -30.385546875],
              [-70.16142578124999, -30.440234375],
              [-70.19394531249999, -30.5046875],
              [-70.26938476562499, -30.67724609375],
              [-70.31923828125, -30.833984375],
              [-70.34814453125, -30.90234375],
              [-70.33642578125, -30.959765625],
              [-70.31181640624999, -30.992578125],
              [-70.30908203125, -31.02265625000001],
              [-70.3505859375, -31.06044921875001],
              [-70.38837890625, -31.12109375],
              [-70.42939453125, -31.129296875],
              [-70.473095703125, -31.11279296875],
              [-70.51958007812499, -31.1484375],
              [-70.529052734375, -31.22285156250001],
              [-70.5546875, -31.3173828125],
              [-70.56640625, -31.42792968750001],
              [-70.585205078125, -31.569433593750006],
              [-70.525634765625, -31.66640625],
              [-70.450146484375, -31.841894531250006],
              [-70.39384765624999, -31.8837890625],
              [-70.33095703125, -31.88105468750001],
              [-70.28173828125, -31.916601562500006],
              [-70.25439453125, -31.95771484375001],
              [-70.29091796875, -32.0310546875],
              [-70.35556640624999, -32.04238281250001],
              [-70.36376953125, -32.08349609375],
              [-70.34462890625, -32.17646484375001],
              [-70.32001953125, -32.266699218750006],
              [-70.2578125, -32.30996093750001],
              [-70.22978515624999, -32.4306640625],
              [-70.16962890625, -32.4716796875],
              [-70.176953125, -32.626074218750006],
              [-70.116162109375, -32.807421875],
              [-70.05205078124999, -32.859960937500006],
              [-70.02197265625, -32.8845703125],
              [-70.042138671875, -32.963671875],
              [-70.09306640624999, -33.02675781250001],
              [-70.10400390625, -33.1279296875],
              [-70.08486328125, -33.201757812500006],
              [-70.01982421874999, -33.271484375],
              [-69.96904296874999, -33.279394531250006],
              [-69.89619140625, -33.2509765625],
              [-69.81962890624999, -33.283789062500006],
              [-69.80869140624999, -33.34394531250001],
              [-69.79775390625, -33.39863281250001],
              [-69.83876953125, -33.4697265625],
              [-69.882568359375, -33.60097656250001],
              [-69.89433593749999, -33.73134765625001],
              [-69.881494140625, -33.929785156250006],
              [-69.8615234375, -34.08359375],
              [-69.857373046875, -34.18046875],
              [-69.85244140625, -34.22431640625001],
              [-69.87978515625, -34.25439453125],
              [-69.94633789062499, -34.269921875],
              [-70.00283203125, -34.27626953125001],
              [-70.05205078124999, -34.30078125],
              [-70.06298828125, -34.35],
              [-70.10146484375, -34.43203125],
              [-70.141259765625, -34.49287109375001],
              [-70.210693359375, -34.58125],
              [-70.25468749999999, -34.67265625],
              [-70.28994140625, -34.7328125],
              [-70.286767578125, -34.774511718750006],
              [-70.312109375, -34.85498046875],
              [-70.338134765625, -34.92177734375001],
              [-70.39316406249999, -35.146875],
              [-70.46660156249999, -35.19365234375],
              [-70.52509765625, -35.216796875],
              [-70.55517578125, -35.246875],
              [-70.53232421874999, -35.30791015625],
              [-70.47041015625, -35.326171875],
              [-70.44853515624999, -35.375390625],
              [-70.45673828125, -35.451953125],
              [-70.41572265625, -35.523046875],
              [-70.41972656249999, -35.6091796875],
              [-70.38017578124999, -35.771875],
              [-70.41572265625, -35.878515625],
              [-70.40366210937499, -35.9705078125],
              [-70.40478515625, -36.06171875000001],
              [-70.45673828125, -36.13271484375001],
              [-70.56337890625, -36.14638671875001],
              [-70.621875, -36.2119140625],
              [-70.721923828125, -36.283203125],
              [-70.732861328125, -36.340625],
              [-70.749267578125, -36.392578125],
              [-70.790283203125, -36.41171875],
              [-70.853173828125, -36.41171875],
              [-70.90512695312499, -36.419921875],
              [-70.9779296875, -36.4873046875],
              [-71.05551757812499, -36.52373046875],
              [-71.0732421875, -36.57802734375001],
              [-71.06640625, -36.64404296875],
              [-71.107421875, -36.68505859375],
              [-71.159375, -36.76162109375001],
              [-71.19218749999999, -36.84365234375001],
              [-71.159375, -36.92021484375],
              [-71.12382812499999, -37.05693359375],
              [-71.118408203125, -37.114355468750006],
              [-71.1634765625, -37.22744140625001],
              [-71.200390625, -37.30029296875],
              [-71.16489257812499, -37.39326171875001],
              [-71.13481445312499, -37.4451171875],
              [-71.162841796875, -37.559179687500006],
              [-71.18671875, -37.63105468750001],
              [-71.167578125, -37.762304687500006],
              [-71.09619140625, -37.9099609375],
              [-71.02817382812499, -38.04121093750001],
              [-71.01816406249999, -38.193945312500006],
              [-71.00048828125, -38.31484375],
              [-70.96796875, -38.445898437500006],
              [-70.899658203125, -38.49785156250002],
              [-70.84765625, -38.541601562500006],
              [-70.858642578125, -38.6044921875],
              [-70.896923828125, -38.681054687499994],
              [-70.951611328125, -38.73847656250001],
              [-71.087109375, -38.75751953125001],
              [-71.197265625, -38.809375],
              [-71.28574218749999, -38.84541015625001],
              [-71.353173828125, -38.88886718750001],
              [-71.4015625, -38.93505859375],
              [-71.42558593749999, -38.98564453125002],
              [-71.409375, -39.205957031249994],
              [-71.42001953124999, -39.287207031250006],
              [-71.46538085937499, -39.40234375],
              [-71.507763671875, -39.495214843750006],
              [-71.52578125, -39.52314453125001],
              [-71.53125, -39.56416015625001],
              [-71.539453125, -39.602441406249994],
              [-71.58701171874999, -39.61113281250002],
              [-71.654296875, -39.59423828125],
              [-71.692578125, -39.60517578125001],
              [-71.719921875, -39.63525390625],
              [-71.69682617187499, -39.70703125],
              [-71.6720703125, -39.83330078125002],
              [-71.637890625, -39.886816406250006],
              [-71.647119140625, -39.92919921875],
              [-71.659765625, -40.02080078125002],
              [-71.70439453124999, -40.09492187500001],
              [-71.763671875, -40.094628906249994],
              [-71.801953125, -40.12470703125001],
              [-71.818310546875, -40.176660156249994],
              [-71.80058593749999, -40.24433593750001],
              [-71.72265625, -40.299707031249994],
              [-71.6953125, -40.33525390625002],
              [-71.708984375, -40.381738281249994],
              [-71.769140625, -40.40087890625],
              [-71.804638671875, -40.43916015625001],
              [-71.838525390625, -40.5244140625],
              [-71.8837890625, -40.62060546875],
              [-71.93212890625, -40.69169921875002],
              [-71.941357421875, -40.789160156250006],
              [-71.873046875, -40.89296875],
              [-71.88071289062499, -40.99462890625],
              [-71.88559570312499, -41.292382812499994],
              [-71.8921875, -41.39335937500002],
              [-71.87114257812499, -41.560546875],
              [-71.897607421875, -41.60664062500001],
              [-71.911279296875, -41.650390625],
              [-71.844482421875, -41.77197265625],
              [-71.77001953125, -41.96855468750002],
              [-71.75, -42.046777343749994],
              [-71.7609375, -42.101464843749994],
              [-71.860791015625, -42.147851562499994],
              [-71.944091796875, -42.167089843750006],
              [-71.993310546875, -42.13427734375],
              [-72.026123046875, -42.14794921875],
              [-72.064404296875, -42.20537109375002],
              [-72.10820312499999, -42.251855468749994],
              [-72.124609375, -42.29833984375],
              [-72.078125, -42.358496093750006],
              [-72.053466796875, -42.473242187500006],
              [-72.105419921875, -42.5224609375],
              [-72.143701171875, -42.5771484375],
              [-72.130029296875, -42.64824218750002],
              [-72.11362304687499, -42.776757812499994],
              [-72.146435546875, -42.99003906250002],
              [-72.10239257812499, -43.065625],
              [-72.0546875, -43.101953125],
              [-71.898583984375, -43.14531250000002],
              [-71.781494140625, -43.16679687500002],
              [-71.750634765625, -43.2373046875],
              [-71.7638671875, -43.29462890625001],
              [-71.82021484375, -43.32294921875001],
              [-71.90498046875, -43.347558593749994],
              [-71.90498046875, -43.44013671875001],
              [-71.832421875, -43.52714843750002],
              [-71.750634765625, -43.59013671875002],
              [-71.73276367187499, -43.64677734375002],
              [-71.73740234374999, -43.7046875],
              [-71.79472656249999, -43.75322265625002],
              [-71.715966796875, -43.8583984375],
              [-71.680078125, -43.929589843749994],
              [-71.71616210937499, -43.984472656250006],
              [-71.7671875, -44.06669921875002],
              [-71.81235351562499, -44.106054687500006],
              [-71.812109375, -44.15078125],
              [-71.83076171875, -44.24140625000001],
              [-71.83505859374999, -44.330175781250006],
              [-71.82001953125, -44.38310546875002],
              [-71.32573242187499, -44.424902343750006],
              [-71.21259765625, -44.44121093750002],
              [-71.15087890625, -44.494042968749994],
              [-71.15971679687499, -44.56025390625001],
              [-71.22148437499999, -44.630761718749994],
              [-71.2611328125, -44.763085937499994],
              [-71.358154296875, -44.78515625],
              [-71.45517578124999, -44.74980468750002],
              [-71.56040039062499, -44.76201171875002],
              [-71.65166015624999, -44.770410156249994],
              [-71.7828125, -44.7744140625],
              [-71.95703125, -44.79150390625],
              [-72.063720703125, -44.771875],
              [-72.072509765625, -44.820410156250006],
              [-72.04169921875, -44.904199218749994],
              [-71.81235351562499, -44.9306640625],
              [-71.59628906249999, -44.97919921875001],
              [-71.53129882812499, -45.06787109375],
              [-71.44345703124999, -45.16826171875002],
              [-71.353759765625, -45.23046875],
              [-71.34931640625, -45.331933593749994],
              [-71.4904296875, -45.43769531250001],
              [-71.50810546874999, -45.5126953125],
              [-71.693310546875, -45.534765625],
              [-71.74619140624999, -45.57890625000002],
              [-71.77265625, -45.72441406250002],
              [-71.750634765625, -45.83906250000001],
              [-71.680078125, -45.87871093750002],
              [-71.63154296875, -45.953710937500006],
              [-71.68447265625, -46.041894531249994],
              [-71.80927734375, -46.10273437500001],
              [-71.87568359375, -46.160546875],
              [-71.834130859375, -46.20673828125001],
              [-71.77763671874999, -46.27998046875001],
              [-71.762109375, -46.31982421875],
              [-71.73129882812499, -46.427832031250006],
              [-71.69521484375, -46.57841796875002],
              [-71.699658203125, -46.6513671875],
              [-71.73271484374999, -46.70585937500002],
              [-71.8564453125, -46.791601562500006],
              [-71.94023437499999, -46.83125],
              [-71.956640625, -46.93681640625002],
              [-71.96298828124999, -47.016015625],
              [-71.954248046875, -47.0875],
              [-71.900537109375, -47.14433593750002],
              [-71.90498046875, -47.20166015625],
              [-71.978515625, -47.2138671875],
              [-72.04169921875, -47.24140625000001],
              [-72.10341796875, -47.3427734375],
              [-72.28291015625, -47.4462890625],
              [-72.345947265625, -47.49267578125],
              [-72.34150390625, -47.57207031250002],
              [-72.41259765625, -47.685546875],
              [-72.47221679687499, -47.7841796875],
              [-72.51791992187499, -47.876367187499994],
              [-72.50908203124999, -47.97333984375001],
              [-72.40791015625, -48.01591796875002],
              [-72.3283203125, -48.11005859375001],
              [-72.29301757812499, -48.229101562500006],
              [-72.354736328125, -48.365820312500006],
              [-72.49814453124999, -48.417382812499994],
              [-72.58286132812499, -48.47539062500002],
              [-72.6083984375, -48.51933593750002],
              [-72.5859375, -48.6625],
              [-72.591748046875, -48.72968750000001],
              [-72.614404296875, -48.792871093749994],
              [-72.65126953125, -48.84160156250002],
              [-72.728466796875, -48.89628906250002],
              [-72.8654296875, -48.943945312500006],
              [-72.98173828124999, -48.97675781250001],
              [-73.033642578125, -49.01435546875001],
              [-73.09458007812499, -49.096875],
              [-73.148876953125, -49.18798828125],
              [-73.13525390625, -49.300683593749994],
              [-73.461572265625, -49.313867187499994],
              [-73.483642578125, -49.39765625000001],
              [-73.55419921875, -49.4638671875],
              [-73.57626953124999, -49.582910156249994],
              [-73.504541015625, -49.69804687500002],
              [-73.47041015625, -49.79453125],
              [-73.52890625, -49.91093750000002],
              [-73.50771484375, -50.0302734375],
              [-73.50126953124999, -50.12529296875002],
              [-73.38662109375, -50.23115234375001],
              [-73.31171875, -50.361914062500006],
              [-73.274169921875, -50.472558593749994],
              [-73.251611328125, -50.558496093749994],
              [-73.221630859375, -50.610742187499994],
              [-73.17451171875, -50.670019531250006],
              [-73.15292968749999, -50.73828125],
              [-73.08237304687499, -50.760351562500006],
              [-72.95556640625, -50.69648437500001],
              [-72.86591796875, -50.653125],
              [-72.80361328125, -50.6376953125],
              [-72.62041015624999, -50.64765625000001],
              [-72.50981445312499, -50.607519531250006],
              [-72.46015625, -50.61171875],
              [-72.392578125, -50.63427734375],
              [-72.340234375, -50.68183593750001],
              [-72.30063476562499, -50.78955078125],
              [-72.276318359375, -50.910253906250006],
              [-72.307373046875, -51.03339843750001],
              [-72.35917968749999, -51.06015625],
              [-72.37680664062499, -51.09541015625001],
              [-72.35917968749999, -51.17041015625],
              [-72.30185546874999, -51.22333984375001],
              [-72.30322265625, -51.298925781250006],
              [-72.36640625, -51.4703125],
              [-72.407666015625, -51.54082031250002],
              [-72.33452148437499, -51.62031250000001],
              [-72.268994140625, -51.69111328125001],
              [-72.136962890625, -51.744042968749994],
              [-72.02841796874999, -51.81865234375002],
              [-71.95346679687499, -51.88037109375],
              [-71.97109375, -51.96416015625002],
              [-71.91865234375, -51.98955078125002],
              [-71.71660156249999, -51.991308593750006],
              [-71.41474609375, -51.99394531250002],
              [-70.94316406249999, -51.998144531250006],
              [-70.482861328125, -52.00224609375002],
              [-69.96025390624999, -52.008203125],
              [-69.71259765625, -52.07539062500001],
              [-69.488427734375, -52.136132812499994],
              [-69.206201171875, -52.136132812499994],
              [-68.924560546875, -52.208105468750006],
              [-68.71518554687499, -52.25546875],
              [-68.589794921875, -52.273339843749994],
              [-68.46098632812499, -52.290429687499994],
              [-68.443359375, -52.35664062500001],
              [-69.0072265625, -52.2626953125],
              [-69.1337890625, -52.21142578125],
              [-69.24101562499999, -52.20546875],
              [-69.446875, -52.269433593749994],
              [-69.56059570312499, -52.42158203125001],
              [-69.6203125, -52.464746093749994],
              [-69.763330078125, -52.50556640625001],
              [-69.9072265625, -52.513574218749994],
              [-70.39096679687499, -52.66083984375001],
              [-70.562939453125, -52.6734375],
              [-70.680322265625, -52.7125],
              [-70.7951171875, -52.76875],
              [-70.8390625, -52.889550781249994],
              [-70.82119140625, -52.96308593750001],
              [-70.95205078125, -53.226953125],
              [-70.984326171875, -53.373632812500006],
              [-70.985107421875, -53.448339843750006],
              [-70.94780273437499, -53.570410156250006],
              [-70.995849609375, -53.779296875],
              [-71.08281249999999, -53.825],
              [-71.29775390625, -53.883398437500006],
              [-71.44389648437499, -53.840917968750006],
              [-71.693798828125, -53.803125],
              [-71.871875, -53.72265625],
              [-72.10092773437499, -53.66582031250002],
              [-72.17441406249999, -53.63232421875],
              [-72.37680664062499, -53.47119140625],
              [-72.39824218749999, -53.41777343750002],
              [-72.41289062499999, -53.350195312500006],
              [-72.3060546875, -53.25371093750002],
              [-72.24863281249999, -53.246679687500006],
              [-72.08115234374999, -53.249609375],
              [-71.94169921874999, -53.23408203125001],
              [-71.852734375, -53.285742187500006],
              [-71.82822265624999, -53.398339843749994],
              [-71.867333984375, -53.458398437499994],
              [-71.902783203125, -53.495507812499994],
              [-71.89169921874999, -53.523535156250006],
              [-71.791455078125, -53.48457031250001],
              [-71.74052734374999, -53.23261718750001],
              [-71.400341796875, -53.10703125],
              [-71.28896484375, -53.03369140625],
              [-71.18022460937499, -52.920507812500006],
              [-71.16328125, -52.888085937499994],
              [-71.15507812499999, -52.845605468749994],
              [-71.22714843749999, -52.810644531250006],
              [-71.38774414062499, -52.764257812500006],
              [-71.89799804687499, -53.00175781250002],
              [-72.1291015625, -53.064355468749994],
              [-72.27802734375, -53.13232421875],
              [-72.45830078124999, -53.254492187500006],
              [-72.492578125, -53.290625],
              [-72.53081054687499, -53.371679687500006],
              [-72.54892578124999, -53.46074218750002],
              [-72.726806640625, -53.420019531250006],
              [-72.99838867187499, -53.29072265625001],
              [-73.052734375, -53.24345703125002],
              [-72.9982421875, -53.17724609375],
              [-72.91552734375, -53.121972656249994],
              [-72.909912109375, -52.9365234375],
              [-72.88916015625, -52.87158203125],
              [-72.83188476562499, -52.81953125000001],
              [-72.727685546875, -52.762304687500006],
              [-72.6759765625, -52.7490234375],
              [-72.632080078125, -52.773828125],
              [-72.626611328125, -52.81757812500001],
              [-72.45346679687499, -52.814453125],
              [-72.117578125, -52.65],
              [-71.97929687499999, -52.64609375],
              [-71.7970703125, -52.68281250000001],
              [-71.5912109375, -52.660742187500006],
              [-71.554150390625, -52.643945312499994],
              [-71.51127929687499, -52.605371093749994],
              [-71.66474609375, -52.56005859375],
              [-71.8119140625, -52.537011718749994],
              [-72.22568359374999, -52.52099609375],
              [-72.315380859375, -52.53857421875],
              [-72.4376953125, -52.62578125000002],
              [-72.478271484375, -52.60400390625],
              [-72.50439453125, -52.56005859375],
              [-72.64482421874999, -52.52910156250002],
              [-72.712109375, -52.535546875],
              [-72.7765625, -52.57744140625002],
              [-72.766015625, -52.642578125],
              [-72.801904296875, -52.71240234375],
              [-72.931884765625, -52.781640625],
              [-73.020263671875, -52.89179687500001],
              [-73.01611328125, -52.977441406249994],
              [-73.02299804687499, -53.022070312500006],
              [-73.05546874999999, -53.04560546875001],
              [-73.1224609375, -53.07392578125001],
              [-73.33818359374999, -53.0546875],
              [-73.45986328125, -52.96484375],
              [-73.50751953125, -52.90351562500001],
              [-73.64521484375, -52.837011718750006],
              [-73.345947265625, -52.754296875],
              [-73.24082031249999, -52.707128906250006],
              [-73.14482421874999, -52.601953125],
              [-73.073193359375, -52.535058593749994],
              [-73.12392578125, -52.48798828125001],
              [-73.1837890625, -52.487890625],
              [-73.178173828125, -52.56269531250001],
              [-73.244140625, -52.6240234375],
              [-73.38212890624999, -52.595117187499994],
              [-73.585693359375, -52.68574218750001],
              [-73.71083984375, -52.661523437499994],
              [-73.914697265625, -52.68818359375001],
              [-74.01445312499999, -52.63935546875001],
              [-74.03583984375, -52.577246093750006],
              [-73.99990234375, -52.512597656249994],
              [-74.037353515625, -52.402929687500006],
              [-74.093505859375, -52.37626953125002],
              [-74.150830078125, -52.38251953125001],
              [-74.17656249999999, -52.31718750000002],
              [-74.2384765625, -52.20234375000001],
              [-74.26596679687499, -52.171289062499994],
              [-74.295654296875, -52.11787109375001],
              [-74.26494140624999, -52.104882812499994],
              [-74.194921875, -52.120214843750006],
              [-74.133544921875, -52.15478515625],
              [-74.040234375, -52.1591796875],
              [-73.83447265625, -52.233984375],
              [-73.74912109374999, -52.21601562500001],
              [-73.702783203125, -52.198828125],
              [-73.68540039062499, -52.13671875],
              [-73.684326171875, -52.077734375],
              [-73.64902343749999, -52.077734375],
              [-73.5322265625, -52.153125],
              [-73.457958984375, -52.14599609375],
              [-73.32675781249999, -52.165917968749994],
              [-73.26044921875, -52.1578125],
              [-73.137353515625, -52.12968750000002],
              [-72.94370117187499, -52.046875],
              [-72.843212890625, -51.96113281250001],
              [-72.79501953124999, -51.94951171875002],
              [-72.73540039062499, -51.960546875],
              [-72.695458984375, -51.98515625000002],
              [-72.69482421875, -52.04472656250002],
              [-72.649560546875, -52.09990234375002],
              [-72.58798828124999, -52.145117187500006],
              [-72.57084960937499, -52.200097656249994],
              [-72.583447265625, -52.25419921875002],
              [-72.693603515625, -52.33027343750001],
              [-72.714013671875, -52.35673828125002],
              [-72.67705078124999, -52.384667968749994],
              [-72.631494140625, -52.37158203125],
              [-72.56870117187499, -52.333984375],
              [-72.53291015625, -52.282324218750006],
              [-72.52333984375, -52.25546875],
              [-72.51933593749999, -52.21708984375002],
              [-72.52412109375, -52.1703125],
              [-72.61357421874999, -52.037011718749994],
              [-72.624755859375, -52.006933593750006],
              [-72.624609375, -51.94648437500001],
              [-72.5228515625, -51.89091796875002],
              [-72.494140625, -51.847558593749994],
              [-72.4896484375, -51.763671875],
              [-72.54252929687499, -51.706152343750006],
              [-72.76123046875, -51.5732421875],
              [-73.12675781249999, -51.43994140625],
              [-73.16875, -51.45390625000002],
              [-73.197021484375, -51.47802734375],
              [-73.16337890624999, -51.49560546875],
              [-73.114990234375, -51.504492187500006],
              [-72.78935546874999, -51.6142578125],
              [-72.70458984375, -51.67792968750001],
              [-72.649072265625, -51.69501953125001],
              [-72.58330078124999, -51.7373046875],
              [-72.600048828125, -51.79912109375002],
              [-72.928369140625, -51.85986328125],
              [-73.188671875, -51.990625],
              [-73.383251953125, -52.07001953125001],
              [-73.51816406249999, -52.041015625],
              [-73.58232421874999, -51.960351562499994],
              [-73.65029296875, -51.85625],
              [-73.75263671875, -51.795507812500006],
              [-73.81064453124999, -51.801171875],
              [-73.857568359375, -51.789941406249994],
              [-73.89443359375, -51.7578125],
              [-73.97324218749999, -51.78447265625002],
              [-74.146435546875, -51.71210937500001],
              [-74.1966796875, -51.680566406249994],
              [-74.069580078125, -51.578710937500006],
              [-73.92978515624999, -51.61787109375001],
              [-73.8958984375, -51.331445312499994],
              [-73.93950195312499, -51.26630859375001],
              [-74.121240234375, -51.195410156250006],
              [-74.21049804687499, -51.20458984375],
              [-74.33232421874999, -51.19501953125001],
              [-74.41435546874999, -51.1625],
              [-74.50786132812499, -51.14960937500001],
              [-74.586865234375, -51.13066406250002],
              [-74.690087890625, -51.086523437500006],
              [-74.81474609374999, -51.062890625],
              [-74.98310546875, -50.88105468750001],
              [-75.055322265625, -50.785546875],
              [-75.094677734375, -50.68125],
              [-74.83662109375, -50.67890625000001],
              [-74.6857421875, -50.662011718749994],
              [-74.64892578125, -50.61845703125002],
              [-74.70205078125, -50.53535156250001],
              [-74.77587890625, -50.46992187500001],
              [-74.7216796875, -50.40849609375002],
              [-74.644482421875, -50.3609375],
              [-74.564111328125, -50.38203125000001],
              [-74.36557617187499, -50.487890625],
              [-74.3314453125, -50.5595703125],
              [-74.190185546875, -50.77802734375001],
              [-74.15610351562499, -50.797460937500006],
              [-74.139404296875, -50.817773437499994],
              [-73.84746093749999, -50.940039062500006],
              [-73.80654296875, -50.938378906249994],
              [-73.824609375, -50.835839843749994],
              [-73.74057617187499, -50.696679687499994],
              [-73.65903320312499, -50.65068359375002],
              [-73.6181640625, -50.65117187500002],
              [-73.61396484375, -50.6279296875],
              [-73.69326171875, -50.57001953125001],
              [-73.654443359375, -50.49267578125],
              [-73.679931640625, -50.490234375],
              [-73.7501953125, -50.53984375000002],
              [-73.89150390625, -50.78271484375],
              [-73.97802734375, -50.827050781249994],
              [-74.09672851562499, -50.71708984375002],
              [-74.16411132812499, -50.63789062500001],
              [-74.197216796875, -50.609765625],
              [-74.18559570312499, -50.4853515625],
              [-73.950341796875, -50.51054687500002],
              [-74.03105468749999, -50.469824218750006],
              [-74.30556640625, -50.398046875],
              [-74.37412109374999, -50.36298828125001],
              [-74.42509765624999, -50.350195312500006],
              [-74.51640624999999, -50.265625],
              [-74.62958984375, -50.19404296875001],
              [-74.434326171875, -50.06523437500002],
              [-74.333740234375, -49.974609375],
              [-74.01943359375, -50.02275390625002],
              [-73.95859375, -49.994726562500006],
              [-74.01123046875, -49.92851562500002],
              [-74.07329101562499, -49.94853515625002],
              [-74.171337890625, -49.907324218750006],
              [-74.32392578125, -49.78339843750001],
              [-74.31875, -49.720117187499994],
              [-74.29082031249999, -49.604101562500006],
              [-74.23037109375, -49.57929687500001],
              [-74.102001953125, -49.55537109375001],
              [-73.955517578125, -49.59306640625002],
              [-73.891552734375, -49.62373046875001],
              [-73.836376953125, -49.609375],
              [-73.89248046875, -49.5234375],
              [-73.988037109375, -49.49091796875001],
              [-74.09443359375, -49.4296875],
              [-74.08349609375, -49.36181640625],
              [-74.04921875, -49.3056640625],
              [-74.0234375, -49.244140625],
              [-74.005615234375, -49.15800781250002],
              [-74.015380859375, -49.090917968750006],
              [-73.984765625, -49.059960937499994],
              [-73.937890625, -49.04609375000001],
              [-73.9349609375, -49.020898437499994],
              [-74.027734375, -49.02617187500002],
              [-74.06132812499999, -49.11103515625001],
              [-74.073876953125, -49.188378906249994],
              [-74.139794921875, -49.25048828125],
              [-74.16787109375, -49.32050781250001],
              [-74.1845703125, -49.404394531250006],
              [-74.22128906249999, -49.500585937500006],
              [-74.301513671875, -49.463964843750006],
              [-74.34853515625, -49.42626953125],
              [-74.36655273437499, -49.400488281250006],
              [-74.358154296875, -49.35136718750002],
              [-74.37998046874999, -49.0478515625],
              [-74.38212890624999, -48.79365234375001],
              [-74.341015625, -48.595703125],
              [-74.227685546875, -48.516992187499994],
              [-74.176220703125, -48.494140625],
              [-74.129296875, -48.50419921875002],
              [-74.05693359374999, -48.50361328125001],
              [-74.00908203124999, -48.475],
              [-74.171533203125, -48.42744140625001],
              [-74.27006835937499, -48.45458984375],
              [-74.34296875, -48.492578125],
              [-74.47441406249999, -48.463964843750006],
              [-74.4994140625, -48.3623046875],
              [-74.57719726562499, -48.2744140625],
              [-74.59072265625, -48.16191406250002],
              [-74.58466796875, -47.9990234375],
              [-74.40048828124999, -48.013085937499994],
              [-74.250439453125, -48.044921875],
              [-73.85380859374999, -48.04218750000001],
              [-73.52817382812499, -48.1982421875],
              [-73.38447265625, -48.17734375],
              [-73.391064453125, -48.145898437499994],
              [-73.5009765625, -48.10664062500001],
              [-73.569580078125, -48.01933593750002],
              [-73.60991210937499, -47.99394531250002],
              [-73.62890625, -47.941503906250006],
              [-73.63510742187499, -47.88037109375],
              [-73.71586914062499, -47.65546875000001],
              [-73.7482421875, -47.66132812500001],
              [-73.779248046875, -47.73847656250001],
              [-73.8466796875, -47.86699218750002],
              [-73.940869140625, -47.92939453125001],
              [-74.08476562499999, -47.9546875],
              [-74.22705078125, -47.96894531250001],
              [-74.3505859375, -47.9443359375],
              [-74.379345703125, -47.891210937500006],
              [-74.376220703125, -47.8296875],
              [-74.4296875, -47.79960937500002],
              [-74.56923828125, -47.77294921875],
              [-74.60888671875, -47.75800781250001],
              [-74.654931640625, -47.702246093750006],
              [-74.58842773437499, -47.61796875000002],
              [-74.53378906249999, -47.56767578125002],
              [-74.46689453124999, -47.57763671875],
              [-74.403564453125, -47.60039062500002],
              [-74.32255859374999, -47.66669921875001],
              [-74.24296874999999, -47.679296875],
              [-74.151953125, -47.62666015625001],
              [-74.13408203124999, -47.5908203125],
              [-74.191015625, -47.568359375],
              [-74.242919921875, -47.559667968750006],
              [-74.323681640625, -47.53144531250001],
              [-74.482666015625, -47.43046875000002],
              [-74.403271484375, -47.327539062499994],
              [-74.21567382812499, -47.209570312500006],
              [-74.1583984375, -47.182519531249994],
              [-74.20805664062499, -47.083105468750006],
              [-74.15190429687499, -46.97441406250002],
              [-74.20947265625, -46.884765625],
              [-74.31357421874999, -46.788183593750006],
              [-74.45419921874999, -46.76679687500001],
              [-74.48442382812499, -46.795019531250006],
              [-74.48935546874999, -46.834570312500006],
              [-74.4669921875, -46.864355468750006],
              [-74.48017578125, -46.885839843750006],
              [-74.51225585937499, -46.88515625],
              [-74.6908203125, -46.86396484375001],
              [-74.81064453124999, -46.799707031249994],
              [-75.00595703124999, -46.741113281249994],
              [-75.0314453125, -46.6953125],
              [-75.05253906249999, -46.628027343750006],
              [-74.98417968749999, -46.512109375],
              [-75.01875, -46.51054687500002],
              [-75.145751953125, -46.60009765625],
              [-75.33740234375, -46.647070312500006],
              [-75.47841796875, -46.66240234375002],
              [-75.54033203124999, -46.69873046875],
              [-75.565087890625, -46.72871093750001],
              [-75.527587890625, -46.74638671875002],
              [-75.44599609375, -46.75078125000002],
              [-75.38642578125, -46.862695312499994],
              [-75.40122070312499, -46.905664062499994],
              [-75.43037109375, -46.9345703125],
              [-75.49663085937499, -46.94013671875001],
              [-75.63525390625, -46.86279296875],
              [-75.70810546874999, -46.775],
              [-75.70639648437499, -46.70527343750001],
              [-75.65678710937499, -46.6103515625],
              [-75.4369140625, -46.483007812500006],
              [-75.37602539062499, -46.429101562499994],
              [-75.247021484375, -46.36933593750001],
              [-75.074853515625, -46.23457031250001],
              [-74.924462890625, -46.15966796875],
              [-74.99765625, -46.09765625],
              [-75.07451171874999, -46.004492187500006],
              [-75.06669921874999, -45.874902343749994],
              [-74.763134765625, -45.823632812499994],
              [-74.63066406249999, -45.8447265625],
              [-74.46279296875, -45.840722656249994],
              [-74.369140625, -45.809667968750006],
              [-74.301171875, -45.80302734375002],
              [-74.157861328125, -45.7671875],
              [-74.09619140625, -45.716796875],
              [-74.08183593749999, -45.678320312500006],
              [-74.08251953125, -45.64472656250001],
              [-74.099267578125, -45.603417968749994],
              [-74.122705078125, -45.496191406250006],
              [-74.09892578124999, -45.460351562499994],
              [-74.037548828125, -45.41767578125001],
              [-73.957177734375, -45.404394531250006],
              [-73.9203125, -45.40771484375],
              [-73.825, -45.446875],
              [-73.844140625, -45.50244140625],
              [-73.88232421875, -45.5693359375],
              [-73.96025390624999, -45.83525390625002],
              [-73.99951171875, -45.89531250000002],
              [-74.06103515625, -45.947363281250006],
              [-74.019921875, -46.05585937500001],
              [-74.08154296875, -46.1318359375],
              [-74.35678710937499, -46.21269531250002],
              [-74.39296875, -46.217382812500006],
              [-74.3724609375, -46.24628906250001],
              [-74.213134765625, -46.23945312500001],
              [-74.08974609375, -46.22236328125001],
              [-73.96757812499999, -46.15410156250002],
              [-73.92919921875, -46.049902343750006],
              [-73.87871093749999, -45.846875],
              [-73.81254882812499, -45.81816406250002],
              [-73.73525390625, -45.81171875000001],
              [-73.69487304687499, -45.85957031250001],
              [-73.70791015625, -45.966699218749994],
              [-73.708154296875, -46.0703125],
              [-73.81064453124999, -46.37734375],
              [-73.934814453125, -46.50068359375001],
              [-73.9486328125, -46.533105468749994],
              [-73.94375, -46.57158203125002],
              [-73.845361328125, -46.566015625],
              [-73.770263671875, -46.49980468750002],
              [-73.7162109375, -46.41523437500001],
              [-73.662060546875, -46.297460937500006],
              [-73.668212890625, -46.21210937500001],
              [-73.65166015624999, -46.159277343750006],
              [-73.62944335937499, -45.98652343750001],
              [-73.59184570312499, -45.89912109375001],
              [-73.59433593749999, -45.77685546875],
              [-73.66196289062499, -45.73076171875002],
              [-73.756591796875, -45.70283203125001],
              [-73.78037109374999, -45.6279296875],
              [-73.73076171874999, -45.47998046875],
              [-73.54990234374999, -45.483789062499994],
              [-73.378564453125, -45.3828125],
              [-73.26621093749999, -45.34619140625],
              [-73.20234375, -45.35380859375002],
              [-72.978173828125, -45.451171875],
              [-72.933837890625, -45.45234375000001],
              [-72.9408203125, -45.41728515625002],
              [-72.975537109375, -45.392578125],
              [-73.0638671875, -45.359765625],
              [-73.22636718749999, -45.25517578125002],
              [-73.444970703125, -45.238183593749994],
              [-73.40488281249999, -45.10234375000002],
              [-73.362451171875, -44.97822265625001],
              [-73.25644531249999, -44.961035156250006],
              [-73.07841796874999, -44.92021484375002],
              [-72.73896484375, -44.73417968750002],
              [-72.680078125, -44.59394531250001],
              [-72.66386718749999, -44.436425781249994],
              [-72.8275390625, -44.395410156249994],
              [-73.00102539062499, -44.292382812499994],
              [-73.14096679687499, -44.2375],
              [-73.265087890625, -44.16865234375001],
              [-73.24072265625, -44.06572265625002],
              [-73.22446289062499, -43.89794921875],
              [-73.068798828125, -43.86201171875001],
              [-72.99658203125, -43.63154296875001],
              [-73.1009765625, -43.455175781250006],
              [-73.07597656249999, -43.323632812499994],
              [-72.93999023437499, -43.211328125],
              [-72.915478515625, -43.13359375000002],
              [-72.87802734374999, -43.04814453125002],
              [-72.7580078125, -43.039453125],
              [-72.75537109375, -42.99296875000002],
              [-72.766015625, -42.908203125],
              [-72.844970703125, -42.808007812499994],
              [-72.848046875, -42.66914062500001],
              [-72.77392578125, -42.50517578125002],
              [-72.65483398437499, -42.5166015625],
              [-72.6318359375, -42.509667968749994],
              [-72.71630859375, -42.41044921875002],
              [-72.78515625, -42.30126953125],
              [-72.77324218749999, -42.257714843749994],
              [-72.70737304687499, -42.22050781250002],
              [-72.6310546875, -42.199804687500006],
              [-72.5484375, -42.255761718749994],
              [-72.43027343749999, -42.43388671875002],
              [-72.412353515625, -42.38818359375],
              [-72.460107421875, -42.206640625],
              [-72.4994140625, -41.980859375],
              [-72.623974609375, -42.01054687500002],
              [-72.73818359375, -41.99462890625],
              [-72.781201171875, -41.959570312500006],
              [-72.82407226562499, -41.908789062500006],
              [-72.783837890625, -41.846777343750006],
              [-72.74370117187499, -41.80058593750002],
              [-72.65986328125, -41.74248046875002],
              [-72.48603515625, -41.722070312499994],
              [-72.36040039062499, -41.64912109375001],
              [-72.31826171875, -41.4990234375],
              [-72.35947265624999, -41.51386718750001],
              [-72.427734375, -41.645898437499994],
              [-72.5423828125, -41.690625],
              [-72.600830078125, -41.68408203125],
              [-72.66977539062499, -41.659375],
              [-72.805126953125, -41.544335937499994],
              [-72.87998046874999, -41.517578125],
              [-72.95283203125, -41.514746093750006],
              [-73.01499023437499, -41.543847656249994],
              [-73.174072265625, -41.74658203125],
              [-73.241796875, -41.780859375],
              [-73.52128906249999, -41.79707031250001],
              [-73.6240234375, -41.77363281250001],
              [-73.73515624999999, -41.74248046875002],
              [-73.721875, -41.692480468750006],
              [-73.68808593749999, -41.639257812500006],
              [-73.62504882812499, -41.611914062500006],
              [-73.62392578125, -41.58134765625002],
              [-73.71064453125, -41.573632812499994],
              [-73.8107421875, -41.517480468749994],
              [-73.85502929687499, -41.446386718750006],
              [-73.876171875, -41.3193359375],
              [-73.96586914062499, -41.118261718750006],
              [-73.98359375, -40.97431640625001],
              [-73.9203125, -40.87158203125],
              [-73.78403320312499, -40.46845703125001],
              [-73.74248046874999, -40.26298828125002],
              [-73.66943359375, -40.08232421875002],
              [-73.67099609374999, -39.96318359375002],
              [-73.48222656249999, -39.85429687500002],
              [-73.410400390625, -39.789160156250006],
              [-73.24990234375, -39.42236328125],
              [-73.22646484375, -39.22441406250002],
              [-73.48076171874999, -38.6240234375],
              [-73.52021484375, -38.509375],
              [-73.53256835937499, -38.366796875],
              [-73.471875, -38.13007812500001],
              [-73.464794921875, -38.04033203125002],
              [-73.516748046875, -37.910546875],
              [-73.66181640625, -37.69853515625],
              [-73.66459960937499, -37.590429687500006],
              [-73.60341796875, -37.479101562500006],
              [-73.66240234374999, -37.34101562500001],
              [-73.63364257812499, -37.25546875],
              [-73.60166015624999, -37.1884765625],
              [-73.37456054687499, -37.22431640625001],
              [-73.27109375, -37.207421875],
              [-73.215966796875, -37.16689453125001],
              [-73.1728515625, -37.053515625],
              [-73.15126953125, -36.87617187500001],
              [-73.13779296874999, -36.799902343750006],
              [-73.11806640625, -36.68837890625001],
              [-73.006591796875, -36.64345703125001],
              [-72.96782226562499, -36.53779296875001],
              [-72.87456054687499, -36.3904296875],
              [-72.77841796874999, -35.978515625],
              [-72.68339843749999, -35.876953125],
              [-72.587353515625, -35.75966796875001],
              [-72.62392578125, -35.5857421875],
              [-72.56206054687499, -35.50537109375],
              [-72.50517578124999, -35.44697265625001],
              [-72.45498046875, -35.3408203125],
              [-72.38671875, -35.24042968750001],
              [-72.223779296875, -35.09619140625],
              [-72.18242187499999, -34.92021484375],
              [-72.05595703124999, -34.615820312500006],
              [-72.03076171875, -34.420507812500006],
              [-71.99150390624999, -34.28847656250001],
              [-72.00283203125, -34.16533203125],
              [-71.92685546874999, -34.015625],
              [-71.853955078125, -33.88955078125001],
              [-71.83095703125, -33.81953125000001],
              [-71.66435546874999, -33.65263671875],
              [-71.63627929687499, -33.51923828125001],
              [-71.6955078125, -33.42900390625],
              [-71.69658203124999, -33.2890625],
              [-71.74296874999999, -33.09511718750001],
              [-71.63554687499999, -33.022558593750006],
              [-71.592041015625, -32.96953125],
              [-71.45224609374999, -32.65957031250001],
              [-71.46142578125, -32.537890625],
              [-71.4212890625, -32.386816406250006],
              [-71.51303710937499, -32.20791015625001],
              [-71.52587890625, -31.80585937500001],
              [-71.577294921875, -31.496386718750003],
              [-71.66196289062499, -31.16953125],
              [-71.65390625, -30.986621093750003],
              [-71.70566406249999, -30.75927734375],
              [-71.708935546875, -30.628027343750006],
              [-71.66948242187499, -30.330371093750003],
              [-71.400390625, -30.14296875],
              [-71.348046875, -29.933203125],
              [-71.31572265624999, -29.649707031250003],
              [-71.32670898437499, -29.443164062500003],
              [-71.353271484375, -29.350390625],
              [-71.48583984375, -29.1982421875],
              [-71.51923828125, -28.92646484375001],
              [-71.493603515625, -28.855273437500003],
              [-71.38408203124999, -28.77871093750001],
              [-71.30673828124999, -28.672460937500006],
              [-71.266845703125, -28.50751953125001],
              [-71.18642578125, -28.37783203125001],
              [-71.1544921875, -28.0640625],
              [-71.08652343749999, -27.814453125],
              [-71.05263671875, -27.72734375],
              [-70.94580078125, -27.61787109375001],
              [-70.92578125, -27.588671875],
              [-70.90927734374999, -27.505175781250003],
              [-70.9142578125, -27.307910156250003],
              [-70.897900390625, -27.1875],
              [-70.812744140625, -26.95058593750001],
              [-70.8029296875, -26.840917968750006],
              [-70.7083984375, -26.596972656250003],
              [-70.686962890625, -26.42177734375001],
              [-70.64658203124999, -26.329394531250003],
              [-70.662255859375, -26.225390625],
              [-70.63544921875, -25.99267578125],
              [-70.699609375, -25.861132812500003],
              [-70.71372070312499, -25.7841796875],
              [-70.6330078125, -25.54560546875001],
              [-70.578125, -25.4875],
              [-70.489501953125, -25.37646484375],
              [-70.45219726562499, -25.25185546875001],
              [-70.44536132812499, -25.17265625],
              [-70.55864257812499, -24.77851562500001],
              [-70.57412109375, -24.644335937500003],
              [-70.54643554687499, -24.331640625],
              [-70.507421875, -24.1296875],
              [-70.52006835937499, -23.968554687500003],
              [-70.507421875, -23.8857421875],
              [-70.48779296875, -23.78173828125],
              [-70.40996093749999, -23.655566406250003],
              [-70.392333984375, -23.56591796875],
              [-70.41962890625, -23.52851562500001],
              [-70.511962890625, -23.4828125],
              [-70.588134765625, -23.36835937500001],
              [-70.593359375, -23.25546875],
              [-70.56884765625, -23.17333984375],
              [-70.56318359375, -23.05703125],
              [-70.449658203125, -23.0341796875],
              [-70.38916015625, -22.96962890625001],
              [-70.331689453125, -22.8486328125],
              [-70.259521484375, -22.55605468750001],
              [-70.228515625, -22.193164062500003],
              [-70.18544921875, -21.974609375],
              [-70.15507812499999, -21.86660156250001],
              [-70.12958984375, -21.64082031250001],
              [-70.087548828125, -21.49306640625001],
              [-70.080029296875, -21.35683593750001],
              [-70.08837890625, -21.253222656250003],
              [-70.197021484375, -20.725390625],
              [-70.19365234374999, -20.53144531250001],
              [-70.1474609375, -20.229785156250003],
              [-70.14814453125, -19.805078125],
              [-70.157421875, -19.705859375],
              [-70.19833984374999, -19.61298828125001],
              [-70.210400390625, -19.486914062500006],
              [-70.27578125, -19.267578125],
              [-70.33486328125, -18.82753906250001],
              [-70.336083984375, -18.59521484375],
              [-70.36162109374999, -18.398046875],
              [-70.41826171874999, -18.34560546875001],
            ],
          ],
          [
            [
              [-68.62993164062499, -52.65263671875002],
              [-68.63168945312499, -52.94951171875002],
              [-68.633447265625, -53.24189453125001],
              [-68.63505859374999, -53.51542968750002],
              [-68.636669921875, -53.78886718750002],
              [-68.63823242187499, -54.05292968750001],
              [-68.639794921875, -54.32402343750002],
              [-68.64750976562499, -54.627832031249994],
              [-68.65322265625, -54.853613281250006],
              [-68.803857421875, -54.853613281250006],
              [-68.84355468749999, -54.87675781250002],
              [-69.081640625, -54.90986328125001],
              [-69.486279296875, -54.85888671875],
              [-69.587548828125, -54.81279296875002],
              [-69.72343749999999, -54.71210937500001],
              [-69.77182617187499, -54.739160156249994],
              [-69.89946289062499, -54.781835937500006],
              [-70.030517578125, -54.815527343750006],
              [-70.1380859375, -54.819238281249994],
              [-70.23779296875, -54.77753906250001],
              [-70.25908203124999, -54.75634765625],
              [-70.28173828125, -54.75175781250002],
              [-70.49716796874999, -54.8095703125],
              [-70.73515624999999, -54.750585937500006],
              [-70.92470703125, -54.71435546875],
              [-71.229248046875, -54.69414062500002],
              [-71.44091796875, -54.61962890625],
              [-71.83154296875, -54.62617187500001],
              [-71.9015625, -54.6015625],
              [-71.927734375, -54.528710937499994],
              [-71.906982421875, -54.49482421875001],
              [-71.8234375, -54.47441406250002],
              [-71.80014648437499, -54.433984375],
              [-71.7158203125, -54.44365234375002],
              [-71.60629882812499, -54.497167968750006],
              [-71.57275390625, -54.49531250000001],
              [-71.500390625, -54.444921875],
              [-71.39340820312499, -54.40019531250002],
              [-71.355224609375, -54.395410156249994],
              [-71.158837890625, -54.450585937499994],
              [-71.07993164062499, -54.444238281249994],
              [-70.966455078125, -54.41953125],
              [-70.94619140625, -54.398046875],
              [-70.928173828125, -54.36005859375001],
              [-70.89824218749999, -54.337890625],
              [-70.797265625, -54.327246093750006],
              [-70.698828125, -54.34882812500001],
              [-70.68754882812499, -54.41474609375001],
              [-70.701123046875, -54.485449218750006],
              [-70.572998046875, -54.50439453125],
              [-70.417919921875, -54.50224609375002],
              [-70.310986328125, -54.52851562500001],
              [-70.29765624999999, -54.48554687500001],
              [-70.46831054687499, -54.37324218750001],
              [-70.539990234375, -54.30341796875001],
              [-70.6361328125, -54.262304687500006],
              [-70.75986328124999, -54.241308593750006],
              [-70.86308593749999, -54.110449218750006],
              [-70.85673828124999, -53.99580078125001],
              [-70.86772460937499, -53.884179687499994],
              [-70.644482421875, -53.822851562500006],
              [-70.69560546874999, -53.727441406249994],
              [-70.61875, -53.65507812500002],
              [-70.53129882812499, -53.62734375],
              [-70.44316406249999, -53.893457031249994],
              [-70.37973632812499, -53.98671875],
              [-70.460546875, -54.00566406250002],
              [-70.629833984375, -54.00556640625001],
              [-70.535302734375, -54.136132812499994],
              [-70.37998046874999, -54.1806640625],
              [-70.24609375, -54.277441406250006],
              [-70.243359375, -54.34765625],
              [-70.16899414062499, -54.379296875],
              [-69.99013671875, -54.38134765625],
              [-69.86699218749999, -54.36748046875002],
              [-69.80908203125, -54.32080078125],
              [-69.741845703125, -54.30585937500001],
              [-69.62167968749999, -54.36406250000002],
              [-69.41928710937499, -54.407128906249994],
              [-69.36479492187499, -54.437597656250006],
              [-69.32509765625, -54.488183593749994],
              [-69.3224609375, -54.54267578125001],
              [-69.31206054687499, -54.57148437500001],
              [-69.253173828125, -54.55742187500002],
              [-69.169189453125, -54.483300781249994],
              [-69.127880859375, -54.457617187500006],
              [-69.07724609374999, -54.44501953125001],
              [-69.04521484374999, -54.42841796875001],
              [-69.0443359375, -54.40673828125],
              [-69.19565429687499, -54.354394531249994],
              [-69.98813476562499, -54.10908203125001],
              [-70.085595703125, -54.011132812499994],
              [-70.151123046875, -53.888085937499994],
              [-70.148828125, -53.761132812499994],
              [-70.09111328124999, -53.721777343750006],
              [-69.94970703125, -53.67158203125001],
              [-69.68974609374999, -53.60087890625002],
              [-69.38994140624999, -53.499414062499994],
              [-69.35244140625, -53.47998046875],
              [-69.35595703125, -53.41630859375002],
              [-69.3935546875, -53.3734375],
              [-69.51254882812499, -53.34199218750001],
              [-69.63701171874999, -53.334082031250006],
              [-69.755615234375, -53.33720703125002],
              [-69.87412109374999, -53.350488281249994],
              [-70.09038085937499, -53.41816406250001],
              [-70.212841796875, -53.413964843749994],
              [-70.329296875, -53.37763671875001],
              [-70.415673828125, -53.304785156250006],
              [-70.46025390624999, -53.20625],
              [-70.4599609375, -53.14335937500002],
              [-70.443359375, -53.085546875],
              [-70.39067382812499, -53.026464843750006],
              [-70.32001953125, -53.00068359375001],
              [-70.25634765625, -53.00410156250001],
              [-70.196484375, -52.990234375],
              [-70.160888671875, -52.96992187500001],
              [-70.130615234375, -52.942773437499994],
              [-70.13955078125, -52.919335937499994],
              [-70.162744140625, -52.899023437500006],
              [-70.2591796875, -52.85722656250002],
              [-70.29736328125, -52.816992187500006],
              [-70.380126953125, -52.751953125],
              [-70.334912109375, -52.733789062499994],
              [-70.1896484375, -52.7236328125],
              [-70.08823242187499, -52.7685546875],
              [-69.9935546875, -52.8212890625],
              [-69.93544921875, -52.82109375000002],
              [-69.883203125, -52.79902343750001],
              [-69.76357421875, -52.731347656249994],
              [-69.66328125, -52.64628906250002],
              [-69.571875, -52.54931640625],
              [-69.49838867187499, -52.49140625000001],
              [-69.4140625, -52.486230468749994],
              [-69.16704101562499, -52.667578125],
              [-69.07993164062499, -52.67431640625],
              [-68.789794921875, -52.576757812500006],
              [-68.75751953125, -52.58203125],
              [-68.65922851562499, -52.63154296875001],
              [-68.62993164062499, -52.65263671875002],
            ],
          ],
          [
            [
              [-67.07993164062499, -55.15380859375],
              [-67.10947265624999, -55.19208984375001],
              [-67.17255859375, -55.242578125],
              [-67.257421875, -55.281835937500006],
              [-67.33969726562499, -55.292578125],
              [-67.39926757812499, -55.27226562500002],
              [-67.42939453125, -55.23652343750001],
              [-67.44326171875, -55.201171875],
              [-67.46347656249999, -55.181738281250006],
              [-67.49472656249999, -55.17744140625001],
              [-67.53525390624999, -55.17851562500002],
              [-67.585205078125, -55.191992187500006],
              [-67.69145507812499, -55.24296875000002],
              [-67.736962890625, -55.256445312500006],
              [-67.7677734375, -55.25957031250002],
              [-68.07001953125, -55.22109375],
              [-68.09951171875, -55.20683593750002],
              [-68.13510742187499, -55.17265625000002],
              [-68.17431640625, -55.0712890625],
              [-68.30136718749999, -54.98066406250001],
              [-68.10693359375, -54.92939453125001],
              [-67.87412109374999, -54.9296875],
              [-67.424560546875, -54.96894531250001],
              [-67.245263671875, -54.977636718750006],
              [-67.10732421875, -55.063574218750006],
              [-67.08549804687499, -55.115234375],
              [-67.07993164062499, -55.15380859375],
            ],
          ],
          [
            [
              [-73.773388671875, -43.34589843750001],
              [-73.848583984375, -43.366796875],
              [-73.918701171875, -43.371972656249994],
              [-73.98994140625, -43.35664062500001],
              [-74.114404296875, -43.35791015625],
              [-74.23857421874999, -43.31884765625],
              [-74.354931640625, -43.263574218749994],
              [-74.387353515625, -43.23164062500001],
              [-74.37314453124999, -43.18574218750001],
              [-74.28935546874999, -43.079492187499994],
              [-74.20947265625, -42.87871093750002],
              [-74.15629882812499, -42.59052734375001],
              [-74.198828125, -42.481347656249994],
              [-74.1935546875, -42.43603515625],
              [-74.174072265625, -42.38154296875001],
              [-74.16435546874999, -42.32548828125002],
              [-74.1703125, -42.268945312499994],
              [-74.16020507812499, -42.21640625],
              [-74.07231445312499, -42.105859375],
              [-74.059375, -42.05625],
              [-74.0568359375, -42.00234375],
              [-74.018798828125, -41.89091796875002],
              [-74.030517578125, -41.85400390625],
              [-74.06303710937499, -41.82275390625],
              [-74.03666992187499, -41.795507812500006],
              [-73.73095703125, -41.87724609375002],
              [-73.52783203125, -41.89628906250002],
              [-73.51694335937499, -41.980859375],
              [-73.477783203125, -42.04716796875002],
              [-73.4544921875, -42.165917968749994],
              [-73.42290039062499, -42.19287109375],
              [-73.43925781249999, -42.27783203125],
              [-73.5328125, -42.314453125],
              [-73.524560546875, -42.392578125],
              [-73.47080078124999, -42.46630859375],
              [-73.549267578125, -42.492578125],
              [-73.63388671874999, -42.508203125],
              [-73.653466796875, -42.528710937499994],
              [-73.71474609375, -42.54472656250002],
              [-73.7892578125, -42.58574218750002],
              [-73.766845703125, -42.621875],
              [-73.673046875, -42.70439453125002],
              [-73.56826171875, -42.761621093749994],
              [-73.5107421875, -42.84716796875],
              [-73.43632812499999, -42.9365234375],
              [-73.47265625, -42.993261718750006],
              [-73.54082031249999, -43.07373046875],
              [-73.649609375, -43.12714843750001],
              [-73.749658203125, -43.159082031249994],
              [-73.737890625, -43.29140625],
              [-73.773388671875, -43.34589843750001],
            ],
          ],
          [
            [
              [-74.476171875, -49.147851562499994],
              [-74.466796875, -49.29453125],
              [-74.48359375, -49.44189453125],
              [-74.52207031249999, -49.622949218749994],
              [-74.515771484375, -49.659570312499994],
              [-74.470849609375, -49.668554687500006],
              [-74.45883789062499, -49.69111328125001],
              [-74.47197265624999, -49.786230468750006],
              [-74.49609375, -49.859472656250006],
              [-74.542578125, -49.91914062500001],
              [-74.56982421875, -49.99072265625],
              [-74.5947265625, -50.00664062500002],
              [-74.703369140625, -50.01923828125001],
              [-74.76298828124999, -50.01142578125001],
              [-74.81083984374999, -49.9296875],
              [-74.82470703125, -49.879492187500006],
              [-74.821923828125, -49.813867187499994],
              [-74.88041992187499, -49.72587890625002],
              [-74.8822265625, -49.69218750000002],
              [-74.859326171875, -49.634179687499994],
              [-74.81201171875, -49.60527343750002],
              [-74.804833984375, -49.516015625],
              [-74.781005859375, -49.4892578125],
              [-74.72705078125, -49.45234375000001],
              [-74.71884765624999, -49.43701171875],
              [-74.723828125, -49.423828125],
              [-74.74384765625, -49.422460937500006],
              [-74.960107421875, -49.53300781250002],
              [-74.98129882812499, -49.56416015625001],
              [-74.99082031249999, -49.60566406250001],
              [-74.99350585937499, -49.75175781250002],
              [-75.03154296874999, -49.83623046875002],
              [-75.066015625, -49.85234375000002],
              [-75.166943359375, -49.85595703125],
              [-75.30009765624999, -49.84746093750002],
              [-75.451171875, -49.769921875],
              [-75.5498046875, -49.79130859375002],
              [-75.57011718749999, -49.69707031250002],
              [-75.520751953125, -49.621679687500006],
              [-75.337060546875, -49.62822265625002],
              [-75.305859375, -49.494042968749994],
              [-75.364208984375, -49.4625],
              [-75.428857421875, -49.40839843750001],
              [-75.46748046875, -49.35888671875],
              [-75.43315429687499, -49.32207031250002],
              [-75.32666015625, -49.268652343750006],
              [-75.26962890624999, -49.26289062500001],
              [-75.21684570312499, -49.29277343750002],
              [-75.08603515624999, -49.27021484375001],
              [-75.093701171875, -49.18535156250002],
              [-75.21015625, -49.148046875],
              [-75.184228515625, -49.08359375],
              [-75.037109375, -49.022070312500006],
              [-74.94921875, -48.96015625000001],
              [-74.94521484375, -48.88945312500002],
              [-74.98076171874999, -48.81884765625],
              [-74.96953124999999, -48.79130859375002],
              [-74.896240234375, -48.73320312500002],
              [-74.79345703125, -48.705078125],
              [-74.74667968749999, -48.708886718749994],
              [-74.6515625, -48.749902343749994],
              [-74.5666015625, -48.754785156249994],
              [-74.54609375, -48.76689453125002],
              [-74.5306640625, -48.812597656250006],
              [-74.476171875, -49.147851562499994],
            ],
          ],
          [
            [
              [-75.51025390625, -48.76347656250002],
              [-75.62285156249999, -48.7646484375],
              [-75.65092773437499, -48.586328125],
              [-75.51845703125, -48.32880859375001],
              [-75.509033203125, -48.23066406250001],
              [-75.55351562499999, -48.15673828125],
              [-75.571484375, -48.09589843750001],
              [-75.560693359375, -48.070898437500006],
              [-75.39140624999999, -48.01972656250001],
              [-75.33837890625, -48.07402343750002],
              [-75.27548828124999, -48.21845703125001],
              [-75.155517578125, -48.425195312499994],
              [-75.15849609374999, -48.62265625],
              [-75.22509765625, -48.67138671875],
              [-75.433984375, -48.72119140625],
              [-75.51025390625, -48.76347656250002],
            ],
          ],
          [
            [
              [-74.3857421875, -52.92236328125],
              [-74.3693359375, -52.93144531250002],
              [-74.32998046875, -52.929296875],
              [-74.274609375, -52.94550781250001],
              [-74.06596679687499, -52.96533203125],
              [-73.87919921874999, -53.01220703125],
              [-73.78178710937499, -53.056054687499994],
              [-73.65400390625, -53.06982421875],
              [-73.549267578125, -53.12568359375001],
              [-73.504541015625, -53.140039062499994],
              [-73.4505859375, -53.14433593750002],
              [-73.31035156249999, -53.24765625],
              [-73.302490234375, -53.25947265625001],
              [-73.14335937499999, -53.340917968750006],
              [-73.135205078125, -53.35390625],
              [-73.225732421875, -53.3583984375],
              [-73.409375, -53.32050781250001],
              [-73.50102539062499, -53.318457031250006],
              [-73.56728515625, -53.30683593750001],
              [-73.58281249999999, -53.300195312499994],
              [-73.595947265625, -53.2529296875],
              [-73.61708984375, -53.22968750000001],
              [-73.79350585937499, -53.120703125],
              [-73.866943359375, -53.096875],
              [-73.99399414062499, -53.07578125],
              [-74.13857421875, -53.09052734375001],
              [-74.23637695312499, -53.07646484375002],
              [-74.27021484375, -53.08154296875],
              [-74.41440429687499, -52.99492187500002],
              [-74.55830078125, -52.921875],
              [-74.61992187499999, -52.83476562500002],
              [-74.71152343749999, -52.768164062500006],
              [-74.71201171874999, -52.74873046875001],
              [-74.66997070312499, -52.73388671875],
              [-74.571533203125, -52.77128906250002],
              [-74.474560546875, -52.83564453125001],
              [-74.422265625, -52.86005859375001],
              [-74.3857421875, -52.92236328125],
            ],
          ],
          [
            [
              [-74.56728515625, -48.59199218750001],
              [-74.58627929687499, -48.61572265625],
              [-74.70957031249999, -48.601171875],
              [-74.923046875, -48.62646484375],
              [-75.012841796875, -48.535742187500006],
              [-75.0521484375, -48.39140625000002],
              [-75.07890624999999, -48.36152343750001],
              [-75.13193359374999, -48.279296875],
              [-75.15849609374999, -48.22529296875001],
              [-75.212890625, -48.141699218750006],
              [-75.23388671875, -48.05341796875001],
              [-75.247265625, -48.026757812499994],
              [-75.19829101562499, -47.974609375],
              [-74.97509765625, -47.9228515625],
              [-74.895654296875, -47.83935546875],
              [-74.82744140624999, -47.85039062500002],
              [-74.84619140625, -48.02080078125002],
              [-74.80522460937499, -48.078222656250006],
              [-74.72929687499999, -48.125878906249994],
              [-74.715234375, -48.1455078125],
              [-74.702392578125, -48.20585937500002],
              [-74.66435546874999, -48.29931640625],
              [-74.61513671875, -48.34306640625002],
              [-74.60244140625, -48.37031250000001],
              [-74.60014648437499, -48.39306640625],
              [-74.61821289062499, -48.425195312499994],
              [-74.56728515625, -48.59199218750001],
            ],
          ],
          [
            [
              [-72.9232421875, -53.48164062500001],
              [-72.89628906249999, -53.56279296875002],
              [-72.8822265625, -53.57832031250001],
              [-72.809375, -53.565332031249994],
              [-72.685498046875, -53.55791015625002],
              [-72.48227539062499, -53.58808593750001],
              [-72.459228515625, -53.59882812500001],
              [-72.37290039062499, -53.6875],
              [-72.30668945312499, -53.72539062500002],
              [-72.20541992187499, -53.80742187500002],
              [-72.30625, -53.86210937500002],
              [-72.365966796875, -53.940820312499994],
              [-72.369140625, -53.98076171875002],
              [-72.40854492187499, -54.003808593749994],
              [-72.47050781249999, -54.027734375],
              [-72.562890625, -54.07373046875],
              [-72.67656249999999, -54.07890625000002],
              [-72.78862304687499, -54.103125],
              [-72.84038085937499, -54.125097656250006],
              [-72.87099609375, -54.1265625],
              [-72.90727539062499, -54.114648437499994],
              [-72.94609374999999, -54.09208984375002],
              [-72.95859375, -54.06591796875],
              [-72.88173828125, -54.041601562500006],
              [-72.781689453125, -53.95478515625001],
              [-72.76376953124999, -53.86484375],
              [-72.871728515625, -53.848535156249994],
              [-72.93613281249999, -53.86083984375],
              [-72.98422851562499, -53.86054687500001],
              [-73.039453125, -53.83281250000002],
              [-73.07304687499999, -53.87529296875002],
              [-73.085546875, -53.915917968749994],
              [-73.07084960937499, -53.97802734375],
              [-73.08076171875, -53.998046875],
              [-73.11997070312499, -54.009375],
              [-73.21064453125, -53.98583984375],
              [-73.30473632812499, -53.943945312500006],
              [-73.312158203125, -53.91962890625001],
              [-73.29287109375, -53.835839843749994],
              [-73.294921875, -53.792089843750006],
              [-73.31435546875, -53.72919921875001],
              [-73.32480468749999, -53.72265625],
              [-73.360107421875, -53.724023437499994],
              [-73.470947265625, -53.73613281250002],
              [-73.581640625, -53.65546875000001],
              [-73.64150390625, -53.5703125],
              [-73.845458984375, -53.545800781249994],
              [-73.6865234375, -53.426855468750006],
              [-73.44707031249999, -53.410058593749994],
              [-73.365869140625, -53.47021484375],
              [-73.099365234375, -53.51191406250001],
              [-73.11533203124999, -53.44804687500002],
              [-73.11088867187499, -53.425195312499994],
              [-73.07431640624999, -53.39677734375002],
              [-73.05361328125, -53.394433593749994],
              [-73.02207031249999, -53.41455078125],
              [-72.970947265625, -53.42304687500001],
              [-72.947265625, -53.442480468750006],
              [-72.9232421875, -53.48164062500001],
            ],
          ],
          [
            [
              [-74.82294921875, -51.63017578125002],
              [-74.78012695312499, -51.82470703125],
              [-74.74951171875, -51.85185546875002],
              [-74.6474609375, -51.8662109375],
              [-74.53681640625, -51.96513671875002],
              [-74.53183593749999, -51.99199218750002],
              [-74.665966796875, -52.160058593749994],
              [-74.694482421875, -52.279199218749994],
              [-74.851806640625, -52.27070312500001],
              [-74.917724609375, -52.152246093749994],
              [-75.01713867187499, -52.03789062500002],
              [-75.05068359375, -51.90390625],
              [-75.10537109375, -51.78886718750002],
              [-75.00810546874999, -51.723730468750006],
              [-74.915185546875, -51.73828125],
              [-74.90966796875, -51.65],
              [-74.82294921875, -51.63017578125002],
            ],
          ],
          [
            [
              [-69.70297851562499, -54.919042968750006],
              [-68.90078125, -55.01777343750001],
              [-68.653515625, -54.957910156249994],
              [-68.4580078125, -54.95966796875001],
              [-68.3998046875, -55.0419921875],
              [-68.598095703125, -55.128320312499994],
              [-68.61328125, -55.15556640625002],
              [-68.585546875, -55.177734375],
              [-68.38173828125, -55.19160156250001],
              [-68.330078125, -55.21943359375001],
              [-68.282666015625, -55.25517578125002],
              [-68.32275390625, -55.308203125],
              [-68.3265625, -55.33271484375001],
              [-68.305419921875, -55.35664062500001],
              [-68.152587890625, -55.436914062499994],
              [-68.08989257812499, -55.47832031250002],
              [-68.05830078125, -55.51796875],
              [-68.045556640625, -55.5875],
              [-68.04833984375, -55.643164062500006],
              [-68.082666015625, -55.65058593750001],
              [-68.15708007812499, -55.633691406249994],
              [-68.229638671875, -55.6015625],
              [-68.293359375, -55.521386718749994],
              [-68.338037109375, -55.505273437499994],
              [-68.46669921875, -55.48906250000002],
              [-68.594189453125, -55.45],
              [-68.6935546875, -55.452246093750006],
              [-68.78500976562499, -55.435644531250006],
              [-68.86704101562499, -55.4501953125],
              [-68.896142578125, -55.423828125],
              [-68.931298828125, -55.37060546875],
              [-68.932080078125, -55.34736328125001],
              [-68.88896484374999, -55.26328125],
              [-68.890087890625, -55.2412109375],
              [-68.91264648437499, -55.23857421875002],
              [-69.008203125, -55.255761718749994],
              [-69.046826171875, -55.24433593750001],
              [-69.15078125, -55.18339843750002],
              [-69.192626953125, -55.171875],
              [-69.2970703125, -55.16582031250002],
              [-69.35615234375, -55.27392578125],
              [-69.35922851562499, -55.300683593749994],
              [-69.2990234375, -55.36933593750001],
              [-69.180859375, -55.47480468750001],
              [-69.24082031249999, -55.47675781250001],
              [-69.41181640625, -55.444238281249994],
              [-69.455712890625, -55.42402343750001],
              [-69.50869140625, -55.37089843750002],
              [-69.61025390625, -55.339941406250006],
              [-69.6458984375, -55.320898437500006],
              [-69.65629882812499, -55.2984375],
              [-69.657373046875, -55.22900390625],
              [-69.679833984375, -55.21894531250001],
              [-69.82402343749999, -55.23652343750001],
              [-69.8537109375, -55.219824218750006],
              [-69.86577148437499, -55.190625],
              [-69.88676757812499, -55.17412109375002],
              [-69.97978515624999, -55.1474609375],
              [-69.98798828125, -55.130761718749994],
              [-69.946533203125, -55.11103515625001],
              [-69.920849609375, -55.061132812500006],
              [-69.884423828125, -54.88203125000001],
              [-69.70297851562499, -54.919042968750006],
            ],
          ],
          [
            [
              [-71.39047851562499, -54.0328125],
              [-71.16875, -54.11259765625002],
              [-71.021923828125, -54.11181640625],
              [-71.0228515625, -54.16171875],
              [-71.0048828125, -54.246679687500006],
              [-71.02802734375, -54.281152343749994],
              [-71.082958984375, -54.316308593749994],
              [-71.11752929687499, -54.366308593750006],
              [-71.14326171875, -54.3740234375],
              [-71.304638671875, -54.313574218750006],
              [-71.473291015625, -54.23115234375001],
              [-71.55810546875, -54.24560546875],
              [-71.67060546875, -54.22539062500002],
              [-71.76123046875, -54.22978515625002],
              [-71.817578125, -54.276464843750006],
              [-71.94853515624999, -54.300878906250006],
              [-71.97236328125, -54.20722656250001],
              [-72.091552734375, -54.11875],
              [-72.21044921875, -54.047753906249994],
              [-72.14604492187499, -53.938867187499994],
              [-72.06894531249999, -53.921289062499994],
              [-71.996484375, -53.884863281250006],
              [-71.70512695312499, -53.92333984375],
              [-71.554150390625, -53.9560546875],
              [-71.39047851562499, -54.0328125],
            ],
          ],
          [
            [
              [-73.7353515625, -44.39453125],
              [-73.7845703125, -44.4375],
              [-73.8623046875, -44.44511718750002],
              [-73.98330078125, -44.49482421875001],
              [-73.996044921875, -44.537988281249994],
              [-74.00205078124999, -44.590917968750006],
              [-73.91855468749999, -44.6546875],
              [-73.877392578125, -44.72880859375002],
              [-73.827880859375, -44.83984375],
              [-73.792138671875, -44.94580078125],
              [-73.795361328125, -44.978613281250006],
              [-73.78647460937499, -45.03359375],
              [-73.72714843749999, -45.119042968749994],
              [-73.7216796875, -45.157617187499994],
              [-73.728173828125, -45.195898437500006],
              [-73.752099609375, -45.26679687500001],
              [-73.77099609375, -45.27656250000001],
              [-73.82988281249999, -45.28349609375002],
              [-73.83447265625, -45.3265625],
              [-73.84897460937499, -45.340625],
              [-74.016259765625, -45.34492187500001],
              [-74.099072265625, -45.32539062500001],
              [-74.0892578125, -45.195703125],
              [-74.19521484375, -45.14482421875002],
              [-74.26796875, -45.058984375],
              [-74.34990234374999, -44.91083984375001],
              [-74.41875, -44.865234375],
              [-74.49882812499999, -44.748144531250006],
              [-74.61777343749999, -44.64794921875],
              [-74.48051757812499, -44.584570312500006],
              [-74.50180664062499, -44.473535156249994],
              [-74.42167968749999, -44.435449218749994],
              [-74.301220703125, -44.39570312500001],
              [-74.2125, -44.42695312500001],
              [-74.1328125, -44.415917968749994],
              [-74.09721679687499, -44.38935546875001],
              [-74.10810546875, -44.27587890625],
              [-74.08281249999999, -44.186425781249994],
              [-73.99492187499999, -44.140234375],
              [-73.90019531249999, -44.134863281250006],
              [-73.86455078124999, -44.18535156250002],
              [-73.8177734375, -44.234960937500006],
              [-73.70322265624999, -44.27412109375001],
              [-73.70371093749999, -44.32539062500001],
              [-73.7353515625, -44.39453125],
            ],
          ],
          [
            [
              [-72.98613281249999, -44.78007812500002],
              [-73.228466796875, -44.859960937500006],
              [-73.35, -44.83320312500001],
              [-73.39707031249999, -44.774316406249994],
              [-73.420068359375, -44.72480468750001],
              [-73.445068359375, -44.641015625],
              [-73.40366210937499, -44.59609375],
              [-73.31494140625, -44.531347656250006],
              [-73.281982421875, -44.48955078125002],
              [-73.266015625, -44.44023437500002],
              [-73.27158203124999, -44.394140625],
              [-73.260009765625, -44.35029296875001],
              [-73.20771484375, -44.3349609375],
              [-73.02841796874999, -44.38408203125002],
              [-72.842431640625, -44.45771484375001],
              [-72.7763671875, -44.50859375000002],
              [-72.7640625, -44.54902343750001],
              [-72.8453125, -44.63847656250002],
              [-72.89716796875, -44.712011718750006],
              [-72.98613281249999, -44.78007812500002],
            ],
          ],
          [
            [
              [-75.04248046875, -44.89013671875],
              [-75.06748046874999, -44.90654296875002],
              [-75.09873046874999, -44.901757812499994],
              [-75.12421875, -44.86992187500002],
              [-75.14213867187499, -44.815625],
              [-75.107421875, -44.79511718750001],
              [-75.0794921875, -44.79511718750001],
              [-75.0484375, -44.82392578125001],
              [-75.0322265625, -44.870507812499994],
              [-75.04248046875, -44.89013671875],
            ],
          ],
          [
            [
              [-75.302001953125, -50.67998046875002],
              [-75.33046875, -50.772363281249994],
              [-75.411376953125, -50.76435546875001],
              [-75.43852539062499, -50.741113281249994],
              [-75.45263671875, -50.682519531249994],
              [-75.47739257812499, -50.654199218749994],
              [-75.44267578124999, -50.59550781250002],
              [-75.41977539062499, -50.530371093750006],
              [-75.42763671875, -50.480566406250006],
              [-75.3037109375, -50.483984375],
              [-75.15615234375, -50.49677734375001],
              [-75.11533203124999, -50.51044921875001],
              [-75.16044921874999, -50.55439453125001],
              [-75.20341796874999, -50.580664062500006],
              [-75.29233398437499, -50.596875],
              [-75.302001953125, -50.67998046875002],
            ],
          ],
          [
            [
              [-75.106689453125, -48.836523437500006],
              [-75.11508789062499, -48.916015625],
              [-75.2626953125, -49.068945312500006],
              [-75.38994140624999, -49.1591796875],
              [-75.506103515625, -49.23066406250001],
              [-75.58037109374999, -49.22998046875],
              [-75.64116210937499, -49.195410156250006],
              [-75.57285156249999, -49.13886718750001],
              [-75.48764648437499, -49.082421875],
              [-75.51455078125, -49.00957031250002],
              [-75.54013671874999, -48.98847656250001],
              [-75.576171875, -48.98076171875002],
              [-75.637841796875, -48.94257812500001],
              [-75.619140625, -48.88593750000001],
              [-75.58310546874999, -48.85888671875],
              [-75.53525390624999, -48.83818359375002],
              [-75.490478515625, -48.850488281249994],
              [-75.297265625, -48.810644531250006],
              [-75.23618164062499, -48.77861328125002],
              [-75.118603515625, -48.77294921875],
              [-75.106689453125, -48.836523437500006],
            ],
          ],
          [
            [
              [-75.11220703125, -47.83769531250002],
              [-75.18583984374999, -47.850683593750006],
              [-75.1943359375, -47.81806640625001],
              [-75.26103515624999, -47.76386718750001],
              [-75.203125, -47.72802734375],
              [-75.08984375, -47.690625],
              [-75.00395507812499, -47.694726562499994],
              [-74.92646484375, -47.72314453125],
              [-74.916015625, -47.75664062500002],
              [-75.05126953125, -47.80048828125001],
              [-75.08447265625, -47.82451171875002],
              [-75.11220703125, -47.83769531250002],
            ],
          ],
          [
            [
              [-74.66875, -43.6078125],
              [-74.81044921875, -43.625390625],
              [-74.84267578125, -43.59550781250002],
              [-74.8419921875, -43.5703125],
              [-74.81767578124999, -43.549414062500006],
              [-74.74501953125, -43.53593750000002],
              [-74.6974609375, -43.55302734375002],
              [-74.67265624999999, -43.57744140625002],
              [-74.664794921875, -43.599609375],
              [-74.66875, -43.6078125],
            ],
          ],
          [
            [
              [-73.63217773437499, -44.82148437500001],
              [-73.66484374999999, -44.832910156249994],
              [-73.694580078125, -44.831152343750006],
              [-73.724755859375, -44.796875],
              [-73.73486328125, -44.75166015625001],
              [-73.80014648437499, -44.68408203125],
              [-73.81845703124999, -44.65214843750002],
              [-73.81699218749999, -44.61396484375001],
              [-73.7794921875, -44.559179687500006],
              [-73.72392578124999, -44.54423828125002],
              [-73.686474609375, -44.546289062499994],
              [-73.64121093749999, -44.61083984375],
              [-73.62822265624999, -44.680761718750006],
              [-73.6166015625, -44.7529296875],
              [-73.63217773437499, -44.82148437500001],
            ],
          ],
          [
            [
              [-74.55864257812499, -51.27705078125001],
              [-74.56088867187499, -51.36083984375],
              [-74.59257812499999, -51.3875],
              [-74.620361328125, -51.39570312500001],
              [-74.69072265624999, -51.370214843750006],
              [-74.730908203125, -51.36738281250001],
              [-74.79736328125, -51.41171875],
              [-74.85332031249999, -51.434179687500006],
              [-74.936669921875, -51.428320312500006],
              [-75.04736328125, -51.398339843749994],
              [-75.14628906249999, -51.524316406249994],
              [-75.19243164062499, -51.56669921875002],
              [-75.28911132812499, -51.625390625],
              [-75.300048828125, -51.55644531250002],
              [-75.2384765625, -51.453515625],
              [-75.21000976562499, -51.38330078125],
              [-75.15366210937499, -51.27880859375],
              [-75.04033203124999, -51.31816406250002],
              [-74.88144531249999, -51.27949218750001],
              [-74.73666992187499, -51.207617187500006],
              [-74.611572265625, -51.207128906250006],
              [-74.5705078125, -51.24541015625002],
              [-74.55864257812499, -51.27705078125001],
            ],
          ],
          [
            [
              [-75.05478515624999, -50.29609375000001],
              [-75.250390625, -50.37626953125002],
              [-75.307861328125, -50.34306640625002],
              [-75.44912109375, -50.343359375],
              [-75.412109375, -50.25664062500002],
              [-75.3978515625, -50.19267578125002],
              [-75.376708984375, -50.16796875],
              [-75.36884765625, -50.112695312499994],
              [-75.32666015625, -50.011816406250006],
              [-75.20966796875, -50.04541015625],
              [-75.12255859375, -50.055273437500006],
              [-75.00424804687499, -50.08867187500002],
              [-74.8759765625, -50.109960937500006],
              [-74.83857421875, -50.197265625],
              [-74.96337890625, -50.2373046875],
              [-75.05478515624999, -50.29609375000001],
            ],
          ],
          [
            [
              [-73.81064453124999, -43.827246093750006],
              [-73.78964843749999, -43.87646484375],
              [-73.833642578125, -43.883203125],
              [-73.90415039062499, -43.875390625],
              [-73.93828124999999, -43.91425781250001],
              [-73.95566406249999, -43.921972656250006],
              [-74.11777343749999, -43.8875],
              [-74.14296875, -43.872167968750006],
              [-74.13994140624999, -43.82099609375001],
              [-73.9671875, -43.816503906250006],
              [-73.85693359375, -43.783789062500006],
              [-73.84140625, -43.788964843749994],
              [-73.81064453124999, -43.827246093750006],
            ],
          ],
          [
            [
              [-74.1421875, -51.931054687499994],
              [-74.1720703125, -51.94208984375001],
              [-74.28310546875, -51.91875],
              [-74.33867187499999, -51.89794921875],
              [-74.42363281249999, -51.845117187499994],
              [-74.437109375, -51.790625],
              [-74.47539062499999, -51.725683593750006],
              [-74.45078125, -51.72490234375002],
              [-74.36210937499999, -51.75068359375001],
              [-74.32568359375, -51.77021484375001],
              [-74.27705078125, -51.811621093750006],
              [-74.13339843749999, -51.87089843750002],
              [-74.1154296875, -51.88847656250002],
              [-74.118896484375, -51.9111328125],
              [-74.1421875, -51.931054687499994],
            ],
          ],
          [
            [
              [-74.312890625, -45.691503906250006],
              [-74.36845703124999, -45.73583984375],
              [-74.46552734375, -45.757226562499994],
              [-74.56162109374999, -45.72246093750002],
              [-74.677734375, -45.73857421875002],
              [-74.68984375, -45.66259765625],
              [-74.646435546875, -45.6],
              [-74.55839843749999, -45.52558593750001],
              [-74.49467773437499, -45.425878906250006],
              [-74.50234375, -45.28515625],
              [-74.45, -45.2529296875],
              [-74.421875, -45.203222656250006],
              [-74.310546875, -45.17265625000002],
              [-74.285400390625, -45.277246093749994],
              [-74.3154296875, -45.46406250000001],
              [-74.24003906249999, -45.57451171875002],
              [-74.22919921875, -45.611328125],
              [-74.243896484375, -45.65361328125002],
              [-74.312890625, -45.691503906250006],
            ],
          ],
          [
            [
              [-67.5751953125, -55.8896484375],
              [-67.61142578124999, -55.891699218750006],
              [-67.69951171874999, -55.873144531250006],
              [-67.83154296875, -55.86484375],
              [-67.846435546875, -55.85722656250002],
              [-67.84970703124999, -55.84257812500002],
              [-67.83408203124999, -55.827539062499994],
              [-67.76206054687499, -55.81611328125001],
              [-67.54482421875, -55.82597656250002],
              [-67.51728515625, -55.83281250000002],
              [-67.50981445312499, -55.844335937500006],
              [-67.54526367187499, -55.87744140625],
              [-67.5751953125, -55.8896484375],
            ],
          ],
          [
            [
              [-66.472119140625, -55.229101562500006],
              [-66.551708984375, -55.272851562499994],
              [-66.61113281249999, -55.269921875],
              [-66.63017578124999, -55.25410156250001],
              [-66.63662109375, -55.234375],
              [-66.624755859375, -55.21308593750001],
              [-66.59970703124999, -55.19365234375002],
              [-66.54155273437499, -55.16943359375],
              [-66.52314453125, -55.16552734375],
              [-66.435791015625, -55.18974609375002],
              [-66.472119140625, -55.229101562500006],
            ],
          ],
          [
            [
              [-70.9916015625, -54.86796875000002],
              [-70.94511718749999, -54.93134765625001],
              [-70.9279296875, -54.94296875],
              [-70.804833984375, -54.967675781249994],
              [-70.74931640624999, -54.952734375],
              [-70.61528320312499, -54.94560546875002],
              [-70.534765625, -54.921289062499994],
              [-70.41752929687499, -54.90888671875001],
              [-70.28305664062499, -55.06591796875],
              [-70.2978515625, -55.11376953125],
              [-70.40415039062499, -55.165625],
              [-70.4755859375, -55.17705078125002],
              [-70.54345703125, -55.16132812500001],
              [-70.538720703125, -55.13496093750001],
              [-70.55107421874999, -55.111914062500006],
              [-70.59746093749999, -55.08203125],
              [-70.64091796874999, -55.084863281249994],
              [-70.71098632812499, -55.10693359375],
              [-70.74443359374999, -55.10419921875001],
              [-70.81547851562499, -55.07988281250002],
              [-70.93984375, -55.061914062499994],
              [-70.964501953125, -55.039648437500006],
              [-70.96728515625, -55.0068359375],
              [-70.99072265625, -54.99042968750001],
              [-71.120361328125, -54.93779296875002],
              [-71.2033203125, -54.89296875],
              [-71.2736328125, -54.88691406250001],
              [-71.29931640625, -54.89228515625001],
              [-71.325341796875, -54.91376953125001],
              [-71.38857421875, -54.93427734375001],
              [-71.406640625, -54.93085937500001],
              [-71.426904296875, -54.91376953125001],
              [-71.43720703125, -54.889257812500006],
              [-71.410546875, -54.83935546875],
              [-71.374267578125, -54.834570312500006],
              [-71.19707031249999, -54.84443359375001],
              [-71.088623046875, -54.86748046875002],
              [-70.9916015625, -54.86796875000002],
            ],
          ],
          [
            [
              [-67.28886718749999, -55.77685546875],
              [-67.32529296874999, -55.784765625],
              [-67.35224609375, -55.766015625],
              [-67.39335937499999, -55.75273437500002],
              [-67.5599609375, -55.72480468750001],
              [-67.5634765625, -55.70781250000002],
              [-67.546142578125, -55.683691406250006],
              [-67.51279296874999, -55.662011718749994],
              [-67.448828125, -55.640625],
              [-67.39736328125, -55.58515625000001],
              [-67.37407226562499, -55.58935546875],
              [-67.3505859375, -55.61210937500002],
              [-67.31044921875, -55.68867187500001],
              [-67.262451171875, -55.74375],
              [-67.26728515625, -55.762792968750006],
              [-67.28886718749999, -55.77685546875],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 2,
        sovereignt: "Peru",
        sov_a3: "PER",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Peru",
        adm0_a3: "PER",
        geou_dif: 0,
        geounit: "Peru",
        gu_a3: "PER",
        su_dif: 0,
        subunit: "Peru",
        su_a3: "PER",
        brk_diff: 0,
        name: "Peru",
        name_long: "Peru",
        brk_a3: "PER",
        brk_name: "Peru",
        brk_group: null,
        abbrev: "Peru",
        postal: "PE",
        formal_en: "Republic of Peru",
        formal_fr: null,
        name_ciawf: "Peru",
        note_adm0: null,
        note_brk: null,
        name_sort: "Peru",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 4,
        mapcolor9: 4,
        mapcolor13: 11,
        pop_est: 32510453,
        pop_rank: 15,
        pop_year: 2019,
        gdp_md: 226848,
        gdp_year: 2019,
        economy: "5. Emerging region: G20",
        income_grp: "3. Upper middle income",
        fips_10: "PE",
        iso_a2: "PE",
        iso_a2_eh: "PE",
        iso_a3: "PER",
        iso_a3_eh: "PER",
        iso_n3: "604",
        iso_n3_eh: "604",
        un_a3: "604",
        wb_a2: "PE",
        wb_a3: "PER",
        woe_id: 23424919,
        woe_id_eh: 23424919,
        woe_note: "Exact WOE match as country",
        adm0_iso: "PER",
        adm0_diff: null,
        adm0_tlc: "PER",
        adm0_a3_us: "PER",
        adm0_a3_fr: "PER",
        adm0_a3_ru: "PER",
        adm0_a3_es: "PER",
        adm0_a3_cn: "PER",
        adm0_a3_tw: "PER",
        adm0_a3_in: "PER",
        adm0_a3_np: "PER",
        adm0_a3_pk: "PER",
        adm0_a3_de: "PER",
        adm0_a3_gb: "PER",
        adm0_a3_br: "PER",
        adm0_a3_il: "PER",
        adm0_a3_ps: "PER",
        adm0_a3_sa: "PER",
        adm0_a3_eg: "PER",
        adm0_a3_ma: "PER",
        adm0_a3_pt: "PER",
        adm0_a3_ar: "PER",
        adm0_a3_jp: "PER",
        adm0_a3_ko: "PER",
        adm0_a3_vn: "PER",
        adm0_a3_tr: "PER",
        adm0_a3_id: "PER",
        adm0_a3_pl: "PER",
        adm0_a3_gr: "PER",
        adm0_a3_it: "PER",
        adm0_a3_nl: "PER",
        adm0_a3_se: "PER",
        adm0_a3_bd: "PER",
        adm0_a3_ua: "PER",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "South America",
        region_un: "Americas",
        subregion: "South America",
        region_wb: "Latin America & Caribbean",
        name_len: 4,
        long_len: 4,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 2,
        max_label: 7,
        label_x: -72.90016,
        label_y: -12.976679,
        ne_id: 1159321163,
        wikidataid: "Q419",
        name_ar: "بيرو",
        name_bn: "পেরু",
        name_de: "Peru",
        name_en: "Peru",
        name_es: "Perú",
        name_fa: "پرو",
        name_fr: "Pérou",
        name_el: "Περού",
        name_he: "פרו",
        name_hi: "पेरू",
        name_hu: "Peru",
        name_id: "Peru",
        name_it: "Perù",
        name_ja: "ペルー",
        name_ko: "페루",
        name_nl: "Peru",
        name_pl: "Peru",
        name_pt: "Peru",
        name_ru: "Перу",
        name_sv: "Peru",
        name_tr: "Peru",
        name_uk: "Перу",
        name_ur: "پیرو",
        name_vi: "Peru",
        name_zh: "秘鲁",
        name_zht: "秘魯",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "PER.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-69.96591796874999, -4.2359375],
            [-69.97202148437499, -4.301171875],
            [-70.00395507812499, -4.327246093750006],
            [-70.05332031249999, -4.333105468750006],
            [-70.12880859375, -4.28662109375],
            [-70.183984375, -4.298144531250003],
            [-70.23916015625, -4.301171875],
            [-70.31689453125, -4.246972656250009],
            [-70.34365234375, -4.193652343750003],
            [-70.40463867187499, -4.150097656250011],
            [-70.5306640625, -4.167578125],
            [-70.63457031249999, -4.168652343750011],
            [-70.72158203125, -4.158886718750011],
            [-70.79951171875, -4.17333984375],
            [-70.86601562499999, -4.229589843750006],
            [-70.915625, -4.2953125],
            [-70.97368164062499, -4.350488281250009],
            [-71.14423828125, -4.38720703125],
            [-71.235009765625, -4.38818359375],
            [-71.316796875, -4.42431640625],
            [-71.43828124999999, -4.437597656250006],
            [-71.52133789062499, -4.4697265625],
            [-71.668359375, -4.4873046875],
            [-71.8447265625, -4.50439453125],
            [-71.94316406249999, -4.553320312500006],
            [-71.982421875, -4.574609375],
            [-72.08251953125, -4.642285156250011],
            [-72.256787109375, -4.748925781250009],
            [-72.35283203124999, -4.786035156250009],
            [-72.468994140625, -4.901269531250009],
            [-72.608349609375, -5.009570312500003],
            [-72.69873046875, -5.0671875],
            [-72.83193359375, -5.09375],
            [-72.88706054687499, -5.122753906250011],
            [-72.907470703125, -5.15771484375],
            [-72.89580078124999, -5.1982421875],
            [-72.91826171874999, -5.302539062500003],
            [-72.958935546875, -5.495214843750006],
            [-72.97021484375, -5.589648437500003],
            [-72.9798828125, -5.634863281250006],
            [-73.06806640625, -5.78955078125],
            [-73.16289062499999, -5.933398437500003],
            [-73.209375, -6.028710937500009],
            [-73.235546875, -6.0984375],
            [-73.206494140625, -6.156445312500011],
            [-73.167724609375, -6.260644531250009],
            [-73.13535156249999, -6.344335937500006],
            [-73.12631835937499, -6.40087890625],
            [-73.137353515625, -6.4658203125],
            [-73.17744140625, -6.525195312500003],
            [-73.24033203124999, -6.5640625],
            [-73.32548828124999, -6.57470703125],
            [-73.49990234375, -6.679492187500003],
            [-73.69453125, -6.833789062500003],
            [-73.75810546874999, -6.90576171875],
            [-73.77626953125, -6.973535156250009],
            [-73.804638671875, -7.079882812500003],
            [-73.79301757812499, -7.135058593750003],
            [-73.758203125, -7.172753906250009],
            [-73.72333984375, -7.262792968750006],
            [-73.72041015625, -7.309277343750011],
            [-73.749462890625, -7.335351562500009],
            [-73.804638671875, -7.341210937500009],
            [-73.85400390625, -7.349902343750003],
            [-73.891748046875, -7.373144531250006],
            [-73.929443359375, -7.367285156250006],
            [-73.964306640625, -7.37890625],
            [-73.964306640625, -7.416699218750011],
            [-73.95268554687499, -7.460253906250003],
            [-73.95849609375, -7.506640625],
            [-73.98173828124999, -7.535742187500006],
            [-74.00205078124999, -7.556054687500009],
            [-73.98173828124999, -7.585058593750006],
            [-73.946875, -7.611230468750009],
            [-73.89462890624999, -7.65478515625],
            [-73.82207031249999, -7.738964843750011],
            [-73.76689453124999, -7.753515625],
            [-73.72041015625, -7.782519531250003],
            [-73.714599609375, -7.829003906250009],
            [-73.73203125, -7.875390625],
            [-73.772705078125, -7.895703125000011],
            [-73.7755859375, -7.936425781250009],
            [-73.72041015625, -7.985742187500009],
            [-73.68266601562499, -8.020605468750006],
            [-73.644921875, -8.072851562500006],
            [-73.610107421875, -8.145410156250009],
            [-73.610107421875, -8.19189453125],
            [-73.57236328124999, -8.249902343750009],
            [-73.54912109374999, -8.29931640625],
            [-73.54912109374999, -8.345800781250006],
            [-73.48813476562499, -8.3921875],
            [-73.43588867187499, -8.427050781250003],
            [-73.39814453125, -8.458984375],
            [-73.36040039062499, -8.479296875],
            [-73.351708984375, -8.51416015625],
            [-73.35673828124999, -8.566992187500006],
            [-73.30244140625, -8.654003906250011],
            [-73.203125, -8.719335937500006],
            [-73.12255859375, -8.8140625],
            [-73.0705078125, -8.8828125],
            [-72.9740234375, -8.9931640625],
            [-72.970361328125, -9.1201171875],
            [-73.08984375, -9.265722656250006],
            [-73.209423828125, -9.411425781250003],
            [-73.01376953124999, -9.407421875000011],
            [-72.81425781249999, -9.410351562500011],
            [-72.60546875, -9.452050781250009],
            [-72.46474609375, -9.4921875],
            [-72.379052734375, -9.51015625],
            [-72.31806640625, -9.556640625],
            [-72.289013671875, -9.629199218750003],
            [-72.2658203125, -9.6884765625],
            [-72.2599609375, -9.774316406250009],
            [-72.1728515625, -9.844042968750003],
            [-72.1791015625, -9.91015625],
            [-72.181591796875, -10.003710937500003],
            [-72.14296875, -10.005175781250003],
            [-71.887451171875, -10.005566406250011],
            [-71.60800781249999, -10.006054687500011],
            [-71.33940429687499, -9.988574218750003],
            [-71.237939453125, -9.966015625000011],
            [-71.11528320312499, -9.852441406250009],
            [-71.041748046875, -9.81875],
            [-70.97075195312499, -9.765722656250006],
            [-70.884521484375, -9.669042968750006],
            [-70.81625976562499, -9.625292968750003],
            [-70.75849609375, -9.571679687500009],
            [-70.67246093749999, -9.51796875],
            [-70.6369140625, -9.478222656250011],
            [-70.60791015625, -9.463671875],
            [-70.54111328124999, -9.4375],
            [-70.57016601562499, -9.48984375],
            [-70.592236328125, -9.54345703125],
            [-70.59916992187499, -9.620507812500009],
            [-70.56723632812499, -9.70458984375],
            [-70.59379882812499, -9.767480468750009],
            [-70.6369140625, -9.82373046875],
            [-70.63759765625, -9.971777343750006],
            [-70.63852539062499, -10.181542968750009],
            [-70.63935546875, -10.361328125],
            [-70.64033203125, -10.586035156250006],
            [-70.641552734375, -10.8408203125],
            [-70.642333984375, -11.01025390625],
            [-70.59653320312499, -10.976855468750003],
            [-70.53325195312499, -10.946875],
            [-70.45087890625, -11.024804687500009],
            [-70.39228515625, -11.05859375],
            [-70.3419921875, -11.066699218750003],
            [-70.29038085937499, -11.064257812500003],
            [-70.22006835937499, -11.04765625],
            [-70.06630859375, -10.982421875],
            [-69.9603515625, -10.929882812500011],
            [-69.839794921875, -10.933398437500003],
            [-69.6740234375, -10.9541015625],
            [-69.57861328125, -10.951757812500006],
            [-69.45361328125, -11.16875],
            [-69.36201171875, -11.327539062500009],
            [-69.25771484375, -11.50859375],
            [-69.17373046875, -11.654296875],
            [-69.04619140624999, -11.875683593750011],
            [-68.93603515625, -12.066796875],
            [-68.81870117187499, -12.270410156250009],
            [-68.68525390625, -12.501953125],
            [-68.728125, -12.560742187500011],
            [-68.762890625, -12.607714843750003],
            [-68.75908203124999, -12.687207031250011],
            [-68.81181640624999, -12.729589843750006],
            [-68.86767578125, -12.755175781250003],
            [-68.933740234375, -12.822070312500003],
            [-68.97861328124999, -12.880078125000011],
            [-68.98051757812499, -12.962597656250011],
            [-68.972265625, -13.38232421875],
            [-68.98344726562499, -13.496386718750003],
            [-69.017529296875, -13.594433593750011],
            [-69.05283203124999, -13.643945312500009],
            [-69.07412109375, -13.682812500000011],
            [-69.023046875, -13.7802734375],
            [-68.974267578125, -13.975976562500009],
            [-68.937451171875, -14.0146484375],
            [-68.89169921874999, -14.094335937500006],
            [-68.87089843749999, -14.169726562500003],
            [-68.880322265625, -14.198828125],
            [-68.97177734374999, -14.234375],
            [-69.00449218749999, -14.265039062500009],
            [-69.013134765625, -14.377246093750003],
            [-69.05278320312499, -14.417578125],
            [-69.11972656249999, -14.4703125],
            [-69.16269531249999, -14.530957031250011],
            [-69.19926757812499, -14.572558593750003],
            [-69.23491210937499, -14.597070312500009],
            [-69.25234375, -14.671093750000011],
            [-69.276025390625, -14.745898437500003],
            [-69.35947265624999, -14.7953125],
            [-69.37373046875, -14.8875],
            [-69.37470703125, -14.962988281250006],
            [-69.330712890625, -15.038964843750009],
            [-69.187109375, -15.19873046875],
            [-69.17246093749999, -15.236621093750003],
            [-69.254296875, -15.332910156250009],
            [-69.301904296875, -15.3994140625],
            [-69.41850585937499, -15.603417968750009],
            [-69.4208984375, -15.640625],
            [-69.39189453124999, -15.736914062500006],
            [-69.21757812499999, -16.14912109375001],
            [-69.18798828125, -16.18281250000001],
            [-69.1341796875, -16.221972656250003],
            [-69.04624023437499, -16.21767578125001],
            [-68.9134765625, -16.26191406250001],
            [-68.848828125, -16.312792968750003],
            [-68.8427734375, -16.337890625],
            [-68.8578125, -16.354785156250003],
            [-68.92802734374999, -16.3890625],
            [-69.00625, -16.433691406250006],
            [-69.03291015625, -16.47597656250001],
            [-69.03837890624999, -16.54267578125001],
            [-69.020703125, -16.6421875],
            [-69.05454101562499, -16.67431640625],
            [-69.13251953125, -16.71308593750001],
            [-69.19980468749999, -16.76845703125001],
            [-69.267236328125, -16.8609375],
            [-69.38154296875, -17.00136718750001],
            [-69.42109375, -17.0400390625],
            [-69.43833007812499, -17.08837890625],
            [-69.5033203125, -17.104785156250003],
            [-69.62485351562499, -17.2001953125],
            [-69.645703125, -17.24853515625],
            [-69.62587890625, -17.29443359375],
            [-69.56381835937499, -17.33291015625001],
            [-69.521923828125, -17.388964843750003],
            [-69.510986328125, -17.46035156250001],
            [-69.51108398437499, -17.5048828125],
            [-69.5109375, -17.50605468750001],
            [-69.58642578125, -17.5732421875],
            [-69.684765625, -17.64980468750001],
            [-69.806103515625, -17.66494140625001],
            [-69.85209960937499, -17.70380859375001],
            [-69.84150390625, -17.78515625],
            [-69.80244140625, -17.9],
            [-69.80258789062499, -17.990234375],
            [-69.83969726562499, -18.09345703125001],
            [-69.92636718749999, -18.2060546875],
            [-70.05908203125, -18.283496093750003],
            [-70.1837890625, -18.3251953125],
            [-70.28227539062499, -18.32539062500001],
            [-70.37749023437499, -18.33359375],
            [-70.41826171874999, -18.34560546875001],
            [-70.4916015625, -18.277734375],
            [-70.81748046874999, -18.052539062500003],
            [-70.94169921874999, -17.93203125],
            [-71.056591796875, -17.87568359375001],
            [-71.33696289062499, -17.68251953125001],
            [-71.36494140625, -17.62050781250001],
            [-71.3994140625, -17.421972656250006],
            [-71.43588867187499, -17.366015625],
            [-71.5322265625, -17.29433593750001],
            [-71.77446289062499, -17.198828125],
            [-71.868359375, -17.15107421875001],
            [-71.96689453124999, -17.0640625],
            [-72.111279296875, -17.002539062500006],
            [-72.26860351562499, -16.87617187500001],
            [-72.3625, -16.775],
            [-72.46767578125, -16.708105468750006],
            [-72.7939453125, -16.614550781250003],
            [-72.95771484375, -16.52089843750001],
            [-73.26376953124999, -16.38857421875001],
            [-73.400048828125, -16.304296875],
            [-73.727685546875, -16.20166015625],
            [-73.824951171875, -16.15283203125],
            [-74.14707031249999, -15.9125],
            [-74.37290039062499, -15.833984375],
            [-74.5548828125, -15.699023437500003],
            [-75.104248046875, -15.411914062500003],
            [-75.19052734374999, -15.320117187500003],
            [-75.274560546875, -15.178125],
            [-75.39658203124999, -15.093554687500003],
            [-75.533642578125, -14.89921875],
            [-75.7376953125, -14.784960937500003],
            [-75.93388671874999, -14.63359375],
            [-76.006298828125, -14.495800781250011],
            [-76.136474609375, -14.3203125],
            [-76.17514648437499, -14.226660156250006],
            [-76.289013671875, -14.133105468750003],
            [-76.297021484375, -13.948437500000011],
            [-76.37646484375, -13.863085937500003],
            [-76.319482421875, -13.821484375000011],
            [-76.259228515625, -13.802832031250006],
            [-76.18393554687499, -13.515234375],
            [-76.2236328125, -13.371191406250006],
            [-76.42734375, -13.109960937500006],
            [-76.5021484375, -12.984375],
            [-76.55522460937499, -12.823437500000011],
            [-76.637109375, -12.72802734375],
            [-76.7580078125, -12.527148437500003],
            [-76.83212890624999, -12.348730468750006],
            [-76.994091796875, -12.21923828125],
            [-77.03813476562499, -12.172753906250009],
            [-77.0626953125, -12.106835937500009],
            [-77.152734375, -12.060351562500003],
            [-77.1576171875, -11.9234375],
            [-77.2203125, -11.663378906250003],
            [-77.30991210937499, -11.532421875000011],
            [-77.633203125, -11.287792968750011],
            [-77.63857421875, -11.193554687500011],
            [-77.664306640625, -11.022070312500006],
            [-77.736083984375, -10.83671875],
            [-78.095458984375, -10.260644531250009],
            [-78.18559570312499, -10.089062500000011],
            [-78.2755859375, -9.810351562500003],
            [-78.35649414062499, -9.652050781250011],
            [-78.44565429687499, -9.37060546875],
            [-78.58012695312499, -9.156640625],
            [-78.66459960937499, -8.97109375],
            [-78.75458984375, -8.740429687500011],
            [-78.76225585937499, -8.616992187500003],
            [-78.925390625, -8.404589843750003],
            [-79.01225585937499, -8.210156250000011],
            [-79.16440429687499, -8.047167968750003],
            [-79.31284179687499, -7.923242187500009],
            [-79.37724609374999, -7.835546875],
            [-79.5888671875, -7.4189453125],
            [-79.61772460937499, -7.295605468750011],
            [-79.761962890625, -7.066503906250006],
            [-79.9046875, -6.901660156250003],
            [-79.99497070312499, -6.768945312500009],
            [-80.11025390625, -6.649609375000011],
            [-80.81162109374999, -6.2822265625],
            [-81.05844726562499, -6.12939453125],
            [-81.142041015625, -6.056738281250006],
            [-81.18051757812499, -5.9423828125],
            [-81.164306640625, -5.875292968750003],
            [-81.09184570312499, -5.812402343750009],
            [-80.991650390625, -5.8609375],
            [-80.9306640625, -5.8408203125],
            [-80.88271484375, -5.758984375000011],
            [-80.88193359374999, -5.635058593750003],
            [-80.943115234375, -5.475390625],
            [-81.16767578125, -5.167089843750006],
            [-81.15073242187499, -5.101855468750003],
            [-81.10849609374999, -5.02783203125],
            [-81.195068359375, -4.879492187500006],
            [-81.28940429687499, -4.7607421875],
            [-81.33662109375, -4.66953125],
            [-81.283203125, -4.322265625],
            [-81.23203125, -4.234277343750009],
            [-80.89194335937499, -3.881640625],
            [-80.798583984375, -3.731054687500006],
            [-80.652734375, -3.63818359375],
            [-80.503662109375, -3.49609375],
            [-80.324658203125, -3.387890625000011],
            [-80.29833984375, -3.406445312500011],
            [-80.27353515624999, -3.424609375],
            [-80.271875, -3.461035156250006],
            [-80.265234375, -3.492480468750003],
            [-80.24541015624999, -3.522167968750011],
            [-80.24375, -3.576757812500006],
            [-80.22060546875, -3.613183593750009],
            [-80.2189453125, -3.654492187500011],
            [-80.21728515625, -3.710742187500003],
            [-80.22885742187499, -3.738867187500006],
            [-80.21757812499999, -3.787695312500006],
            [-80.17924804687499, -3.877734375],
            [-80.19414062499999, -3.905859375],
            [-80.23051757812499, -3.924023437500011],
            [-80.26689453124999, -3.948828125],
            [-80.30327148437499, -4.005078125000011],
            [-80.357861328125, -4.00341796875],
            [-80.43720703125, -3.978613281250006],
            [-80.49013671875, -4.010058593750003],
            [-80.510009765625, -4.069531250000011],
            [-80.49345703124999, -4.119140625],
            [-80.4884765625, -4.16552734375],
            [-80.453759765625, -4.205175781250006],
            [-80.35288085937499, -4.20849609375],
            [-80.44384765625, -4.335839843750009],
            [-80.4884765625, -4.393652343750006],
            [-80.47856445312499, -4.430078125],
            [-80.42416992187499, -4.46142578125],
            [-80.38349609375, -4.463671875],
            [-80.293359375, -4.416796875],
            [-80.232177734375, -4.349023437500009],
            [-80.1974609375, -4.31103515625],
            [-80.13955078125, -4.296093750000011],
            [-80.06352539062499, -4.327539062500009],
            [-79.962890625, -4.390332031250011],
            [-79.8451171875, -4.445898437500006],
            [-79.797265625, -4.476367187500003],
            [-79.71098632812499, -4.467578125],
            [-79.63852539062499, -4.454882812500003],
            [-79.57768554687499, -4.500585937500006],
            [-79.51616210937499, -4.539160156250006],
            [-79.501904296875, -4.670605468750011],
            [-79.45576171875, -4.766210937500006],
            [-79.3994140625, -4.840039062500011],
            [-79.33095703125, -4.927832031250006],
            [-79.26811523437499, -4.957617187500006],
            [-79.186669921875, -4.958203125000011],
            [-79.07626953124999, -4.990625],
            [-79.03330078124999, -4.969140625],
            [-78.995263671875, -4.908007812500003],
            [-78.97539062499999, -4.873242187500011],
            [-78.919189453125, -4.8583984375],
            [-78.914208984375, -4.818652343750003],
            [-78.92578125, -4.770703125000011],
            [-78.9076171875, -4.714453125],
            [-78.8615234375, -4.6650390625],
            [-78.74306640625, -4.592675781250009],
            [-78.68603515625, -4.562402343750009],
            [-78.674462890625, -4.517675781250006],
            [-78.65292968749999, -4.458203125000011],
            [-78.66123046874999, -4.425097656250003],
            [-78.68515625, -4.383984375000011],
            [-78.67939453125, -4.325878906250011],
            [-78.64799804687499, -4.248144531250006],
            [-78.60336914062499, -4.157324218750006],
            [-78.56513671875, -4.041601562500006],
            [-78.550439453125, -3.986914062500006],
            [-78.50908203124999, -3.9521484375],
            [-78.49345703124999, -3.902050781250011],
            [-78.47104492187499, -3.843066406250003],
            [-78.41977539062499, -3.77685546875],
            [-78.42143554687499, -3.705761718750011],
            [-78.39995117187499, -3.67431640625],
            [-78.398046875, -3.594824218750006],
            [-78.347265625, -3.43125],
            [-78.345361328125, -3.397363281250009],
            [-78.32304687499999, -3.38828125],
            [-78.2841796875, -3.399023437500006],
            [-78.250732421875, -3.436132812500006],
            [-78.240380859375, -3.472558593750009],
            [-78.226318359375, -3.489160156250009],
            [-78.19487304687499, -3.48583984375],
            [-78.15849609374999, -3.465136718750003],
            [-78.16098632812499, -3.43212890625],
            [-78.187451171875, -3.399804687500009],
            [-78.19462890624999, -3.38046875],
            [-78.18330078125, -3.350195312500006],
            [-78.12822265624999, -3.283886718750011],
            [-78.06791992187499, -3.206835937500003],
            [-77.9384765625, -3.046972656250006],
            [-77.860595703125, -2.981640625000011],
            [-77.65898437499999, -2.912402343750003],
            [-77.506494140625, -2.859960937500006],
            [-77.36005859375, -2.809667968750006],
            [-77.16147460937499, -2.737695312500009],
            [-76.88076171875, -2.635937500000011],
            [-76.6791015625, -2.562597656250006],
            [-76.49936523437499, -2.432324218750011],
            [-76.36015624999999, -2.331347656250003],
            [-76.24091796875, -2.243945312500003],
            [-76.089794921875, -2.133105468750003],
            [-75.88544921875, -1.893457031250009],
            [-75.74453125, -1.728125],
            [-75.64165039062499, -1.607324218750009],
            [-75.570556640625, -1.53125],
            [-75.5138671875, -1.316308593750009],
            [-75.449169921875, -1.071191406250009],
            [-75.42041015625, -0.962207031250003],
            [-75.40805664062499, -0.92431640625],
            [-75.380126953125, -0.940234375],
            [-75.34819335937499, -0.966796875],
            [-75.30917968749999, -0.968066406250003],
            [-75.272412109375, -0.966796875],
            [-75.249609375, -0.951855468750011],
            [-75.28359375, -0.707128906250006],
            [-75.2787109375, -0.65390625],
            [-75.259375, -0.590136718750003],
            [-75.26323242187499, -0.555371093750011],
            [-75.32524414062499, -0.506542968750011],
            [-75.42470703125, -0.408886718750011],
            [-75.465966796875, -0.32177734375],
            [-75.49106445312499, -0.248339843750003],
            [-75.56059570312499, -0.200097656250009],
            [-75.63203125, -0.157617187500009],
            [-75.62626953124999, -0.122851562500003],
            [-75.583740234375, -0.122851562500003],
            [-75.4759765625, -0.157128906250009],
            [-75.398388671875, -0.14599609375],
            [-75.340478515625, -0.1421875],
            [-75.28447265624999, -0.106542968750006],
            [-75.224609375, -0.041748046875],
            [-75.18408203125, -0.041748046875],
            [-75.13837890625, -0.050488281250011],
            [-75.0546875, -0.11669921875],
            [-75.00498046874999, -0.155859375],
            [-74.9453125, -0.188183593750011],
            [-74.888818359375, -0.199414062500011],
            [-74.8375, -0.203320312500011],
            [-74.8017578125, -0.200097656250009],
            [-74.78046875, -0.24453125],
            [-74.75537109375, -0.298632812500003],
            [-74.691650390625, -0.335253906250003],
            [-74.616357421875, -0.370019531250009],
            [-74.555078125, -0.429882812500011],
            [-74.5138671875, -0.470117187500009],
            [-74.46518554687499, -0.517675781250006],
            [-74.41787109375, -0.580664062500006],
            [-74.37490234375, -0.69140625],
            [-74.353125, -0.7666015625],
            [-74.32861328125, -0.808398437500003],
            [-74.334423828125, -0.850878906250003],
            [-74.28388671875, -0.927832031250006],
            [-74.24638671874999, -0.970605468750009],
            [-74.18076171874999, -0.997753906250011],
            [-74.05439453125, -1.028613281250003],
            [-73.98681640625, -1.09814453125],
            [-73.926953125, -1.125195312500011],
            [-73.86318359375, -1.196679687500009],
            [-73.80717773437499, -1.217968750000011],
            [-73.7357421875, -1.214160156250003],
            [-73.664306640625, -1.248828125],
            [-73.61025390625, -1.31640625],
            [-73.57548828124999, -1.4013671875],
            [-73.52138671875, -1.44970703125],
            [-73.4943359375, -1.53662109375],
            [-73.52524414062499, -1.638867187500011],
            [-73.4962890625, -1.693066406250011],
            [-73.44028320312499, -1.737402343750006],
            [-73.34951171875, -1.783886718750011],
            [-73.26645507812499, -1.772265625],
            [-73.22397460937499, -1.787695312500006],
            [-73.19697265625, -1.830273437500011],
            [-73.18149414062499, -1.88037109375],
            [-73.14521484375, -2.003320312500009],
            [-73.12651367187499, -2.0810546875],
            [-73.16020507812499, -2.156347656250006],
            [-73.17265624999999, -2.208398437500009],
            [-73.1544921875, -2.278222656250009],
            [-73.06816406249999, -2.31201171875],
            [-72.9896484375, -2.339746093750009],
            [-72.94111328125, -2.39404296875],
            [-72.887158203125, -2.408496093750003],
            [-72.81123046875, -2.405468750000011],
            [-72.71416015624999, -2.3921875],
            [-72.66015625, -2.361035156250011],
            [-72.62534179687499, -2.351660156250006],
            [-72.58671874999999, -2.365136718750009],
            [-72.50068359375, -2.39501953125],
            [-72.39560546874999, -2.428906250000011],
            [-72.300732421875, -2.409277343750006],
            [-72.21845703125, -2.400488281250006],
            [-72.13681640624999, -2.380664062500003],
            [-72.05380859374999, -2.324609375],
            [-71.98427734375, -2.3265625],
            [-71.93247070312499, -2.288671875],
            [-71.86728515624999, -2.227734375000011],
            [-71.802734375, -2.166308593750003],
            [-71.75253906249999, -2.152734375],
            [-71.671484375, -2.18212890625],
            [-71.55947265625, -2.22421875],
            [-71.49609375, -2.279199218750009],
            [-71.4474609375, -2.29375],
            [-71.39697265625, -2.334082031250006],
            [-71.30009765624999, -2.334863281250009],
            [-71.19638671874999, -2.313085937500006],
            [-71.11337890624999, -2.245410156250003],
            [-71.027294921875, -2.225781250000011],
            [-70.96855468749999, -2.206835937500003],
            [-70.91455078125, -2.218554687500003],
            [-70.70537109374999, -2.341992187500011],
            [-70.64799804687499, -2.40576171875],
            [-70.57587890625, -2.418261718750003],
            [-70.516796875, -2.453125],
            [-70.418212890625, -2.49072265625],
            [-70.36416015625, -2.529296875],
            [-70.29462890625, -2.552539062500003],
            [-70.24443359374999, -2.606542968750006],
            [-70.16474609375, -2.639843750000011],
            [-70.095849609375, -2.658203125],
            [-70.06474609374999, -2.70166015625],
            [-70.064453125, -2.730761718750003],
            [-70.07402343749999, -2.750195312500011],
            [-70.14707031249999, -2.8640625],
            [-70.29013671874999, -3.087304687500009],
            [-70.41899414062499, -3.288281250000011],
            [-70.62167968749999, -3.604589843750006],
            [-70.735107421875, -3.781542968750003],
            [-70.706201171875, -3.788964843750009],
            [-70.5296875, -3.866406250000011],
            [-70.48583984375, -3.869335937500011],
            [-70.42109375, -3.849609375],
            [-70.37919921874999, -3.81875],
            [-70.339501953125, -3.814355468750009],
            [-70.2984375, -3.84423828125],
            [-70.24028320312499, -3.882714843750009],
            [-70.198388671875, -3.9951171875],
            [-70.16752929687499, -4.050195312500009],
            [-70.09477539062499, -4.0921875],
            [-70.0171875, -4.162011718750009],
            [-69.96591796874999, -4.2359375],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 4,
        sovereignt: "Suriname",
        sov_a3: "SUR",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Suriname",
        adm0_a3: "SUR",
        geou_dif: 0,
        geounit: "Suriname",
        gu_a3: "SUR",
        su_dif: 0,
        subunit: "Suriname",
        su_a3: "SUR",
        brk_diff: 0,
        name: "Suriname",
        name_long: "Suriname",
        brk_a3: "SUR",
        brk_name: "Suriname",
        brk_group: null,
        abbrev: "Sur.",
        postal: "SR",
        formal_en: "Republic of Suriname",
        formal_fr: null,
        name_ciawf: "Suriname",
        note_adm0: null,
        note_brk: null,
        name_sort: "Suriname",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 4,
        mapcolor9: 7,
        mapcolor13: 6,
        pop_est: 581363,
        pop_rank: 11,
        pop_year: 2019,
        gdp_md: 3697,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        fips_10: "NS",
        iso_a2: "SR",
        iso_a2_eh: "SR",
        iso_a3: "SUR",
        iso_a3_eh: "SUR",
        iso_n3: "740",
        iso_n3_eh: "740",
        un_a3: "740",
        wb_a2: "SR",
        wb_a3: "SUR",
        woe_id: 23424913,
        woe_id_eh: 23424913,
        woe_note: "Exact WOE match as country",
        adm0_iso: "SUR",
        adm0_diff: null,
        adm0_tlc: "SUR",
        adm0_a3_us: "SUR",
        adm0_a3_fr: "SUR",
        adm0_a3_ru: "SUR",
        adm0_a3_es: "SUR",
        adm0_a3_cn: "SUR",
        adm0_a3_tw: "SUR",
        adm0_a3_in: "SUR",
        adm0_a3_np: "SUR",
        adm0_a3_pk: "SUR",
        adm0_a3_de: "SUR",
        adm0_a3_gb: "SUR",
        adm0_a3_br: "SUR",
        adm0_a3_il: "SUR",
        adm0_a3_ps: "SUR",
        adm0_a3_sa: "SUR",
        adm0_a3_eg: "SUR",
        adm0_a3_ma: "SUR",
        adm0_a3_pt: "SUR",
        adm0_a3_ar: "SUR",
        adm0_a3_jp: "SUR",
        adm0_a3_ko: "SUR",
        adm0_a3_vn: "SUR",
        adm0_a3_tr: "SUR",
        adm0_a3_id: "SUR",
        adm0_a3_pl: "SUR",
        adm0_a3_gr: "SUR",
        adm0_a3_it: "SUR",
        adm0_a3_nl: "SUR",
        adm0_a3_se: "SUR",
        adm0_a3_bd: "SUR",
        adm0_a3_ua: "SUR",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "South America",
        region_un: "Americas",
        subregion: "South America",
        region_wb: "Latin America & Caribbean",
        name_len: 8,
        long_len: 8,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: -55.91094,
        label_y: 4.143987,
        ne_id: 1159321281,
        wikidataid: "Q730",
        name_ar: "سورينام",
        name_bn: "সুরিনাম",
        name_de: "Suriname",
        name_en: "Suriname",
        name_es: "Surinam",
        name_fa: "سورینام",
        name_fr: "Suriname",
        name_el: "Σουρινάμ",
        name_he: "סורינאם",
        name_hi: "सूरीनाम",
        name_hu: "Suriname",
        name_id: "Suriname",
        name_it: "Suriname",
        name_ja: "スリナム",
        name_ko: "수리남",
        name_nl: "Suriname",
        name_pl: "Surinam",
        name_pt: "Suriname",
        name_ru: "Суринам",
        name_sv: "Surinam",
        name_tr: "Surinam",
        name_uk: "Суринам",
        name_ur: "سرینام",
        name_vi: "Suriname",
        name_zh: "苏里南",
        name_zht: "蘇利南",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "SUR.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-54.15595703125, 5.358984375],
            [-54.240185546875, 5.288232421874994],
            [-54.331640625, 5.187402343749994],
            [-54.45219726562499, 5.013476562499989],
            [-54.446875, 4.958789062499989],
            [-54.47333984375, 4.914697265624994],
            [-54.4796875, 4.836523437499991],
            [-54.471142578125, 4.749316406249989],
            [-54.44023437499999, 4.691992187499991],
            [-54.42607421874999, 4.5830078125],
            [-54.449609375, 4.485009765624994],
            [-54.440673828125, 4.428027343749989],
            [-54.416015625, 4.337646484375],
            [-54.396240234375, 4.24140625],
            [-54.398388671875, 4.202490234374991],
            [-54.369140625, 4.170947265624989],
            [-54.34213867187499, 4.140039062499994],
            [-54.350732421874994, 4.054101562499994],
            [-54.255517578124994, 3.901074218749997],
            [-54.1974609375, 3.834423828124997],
            [-54.11279296875, 3.769433593749994],
            [-54.081982421875, 3.705957031249994],
            [-54.03422851562499, 3.62939453125],
            [-54.00590820312499, 3.620410156249989],
            [-53.990478515625, 3.589550781249997],
            [-54.00595703124999, 3.530517578125],
            [-54.00957031249999, 3.448535156249989],
            [-54.06318359375, 3.353320312499989],
            [-54.18803710937499, 3.178759765624989],
            [-54.203125, 3.13818359375],
            [-54.17070312499999, 2.993603515624997],
            [-54.18808593749999, 2.874853515624991],
            [-54.1955078125, 2.81787109375],
            [-54.256738281249994, 2.713720703124991],
            [-54.402001953124994, 2.461523437499991],
            [-54.485546875, 2.416113281249991],
            [-54.53593749999999, 2.343310546874989],
            [-54.56840820312499, 2.342578124999989],
            [-54.604736328125, 2.335791015624991],
            [-54.61625976562499, 2.326757812499991],
            [-54.661865234375, 2.327539062499994],
            [-54.697412109374994, 2.359814453124997],
            [-54.70292968749999, 2.39794921875],
            [-54.72221679687499, 2.441650390625],
            [-54.766845703125, 2.454736328124994],
            [-54.85166015624999, 2.439550781249991],
            [-54.87607421874999, 2.450390625],
            [-54.92656249999999, 2.497363281249989],
            [-54.968408203124994, 2.54833984375],
            [-54.978662109374994, 2.59765625],
            [-55.005810546875, 2.59296875],
            [-55.0703125, 2.54833984375],
            [-55.11411132812499, 2.539208984374994],
            [-55.148828125, 2.55078125],
            [-55.1876953125, 2.547509765624994],
            [-55.286035156249994, 2.499658203124994],
            [-55.343994140625, 2.48876953125],
            [-55.38535156249999, 2.440625],
            [-55.658935546875, 2.41875],
            [-55.73056640624999, 2.406152343749994],
            [-55.89375, 2.489501953125],
            [-55.9359375, 2.5166015625],
            [-55.957470703125, 2.520458984374997],
            [-55.9755859375, 2.515966796874991],
            [-55.99350585937499, 2.497509765624997],
            [-56.02036132812499, 2.392773437499997],
            [-56.0451171875, 2.364404296874994],
            [-56.087792968749994, 2.34130859375],
            [-56.12939453125, 2.299511718749997],
            [-56.1376953125, 2.259033203125],
            [-56.073632812499994, 2.236767578124997],
            [-56.02006835937499, 2.158154296874997],
            [-55.96196289062499, 2.095117187499994],
            [-55.91533203124999, 2.03955078125],
            [-55.921630859375, 1.976660156249991],
            [-55.929638671875, 1.8875],
            [-55.963330078125, 1.857080078124994],
            [-56.019921875, 1.842236328124997],
            [-56.22714843749999, 1.885351562499991],
            [-56.38583984374999, 1.923876953124989],
            [-56.45283203125, 1.932324218749997],
            [-56.4828125, 1.942138671875],
            [-56.522363281249994, 1.974804687499997],
            [-56.5626953125, 2.005078125],
            [-56.627197265625, 2.016015625],
            [-56.704345703125, 2.036474609374991],
            [-56.761132812499994, 2.114892578124994],
            [-56.81982421875, 2.226660156249991],
            [-56.84052734375, 2.277148437499989],
            [-56.88642578125, 2.325976562499989],
            [-56.93149414062499, 2.395361328124991],
            [-56.945214843749994, 2.456835937499989],
            [-56.97929687499999, 2.513232421874989],
            [-56.99711914062499, 2.532177734374997],
            [-57.02348632812499, 2.608984375],
            [-57.028955078124994, 2.6375],
            [-57.041943359375, 2.64111328125],
            [-57.060449218749994, 2.665673828124994],
            [-57.096875, 2.747851562499989],
            [-57.105126953124994, 2.768261718749997],
            [-57.12114257812499, 2.775537109374994],
            [-57.16362304687499, 2.833251953125],
            [-57.19736328124999, 2.853271484375],
            [-57.20981445312499, 2.8828125],
            [-57.206933593749994, 2.96337890625],
            [-57.225, 3.003076171874994],
            [-57.23056640624999, 3.078564453124997],
            [-57.231640625, 3.10888671875],
            [-57.248974609375, 3.142285156249997],
            [-57.27792968749999, 3.164306640625],
            [-57.28291015625, 3.218847656249991],
            [-57.289941406249994, 3.353613281249991],
            [-57.303662109375, 3.377099609374994],
            [-57.42558593749999, 3.375439453124997],
            [-57.437890625, 3.362255859374997],
            [-57.49057617187499, 3.354296874999989],
            [-57.54960937499999, 3.352832031249989],
            [-57.602734375, 3.370947265624991],
            [-57.646728515625, 3.39453125],
            [-57.65610351562499, 3.423730468749994],
            [-57.64946289062499, 3.517382812499989],
            [-57.720361328124994, 3.58828125],
            [-57.832666015624994, 3.675976562499997],
            [-57.86655273437499, 3.787255859374994],
            [-57.90771484375, 3.856689453125],
            [-58.0322265625, 4.001953125],
            [-58.054296875, 4.101660156249991],
            [-58.05449218749999, 4.171923828124989],
            [-58.0107421875, 4.236474609374994],
            [-57.94975585937499, 4.349951171874991],
            [-57.924707031249994, 4.453125],
            [-57.90625, 4.506787109374997],
            [-57.87470703125, 4.577099609374997],
            [-57.84599609374999, 4.668164062499997],
            [-57.86787109375, 4.724316406249997],
            [-57.90488281249999, 4.779296875],
            [-57.91704101562499, 4.820410156249991],
            [-57.881103515625, 4.880615234375],
            [-57.844921875, 4.923046875],
            [-57.804101562499994, 4.929052734374991],
            [-57.75200195312499, 4.954492187499994],
            [-57.711083984374994, 4.991064453124991],
            [-57.648828125, 5.000683593749997],
            [-57.57089843749999, 5.004492187499991],
            [-57.41215820312499, 5.004589843749997],
            [-57.331005859375, 5.020166015624994],
            [-57.30576171874999, 5.049560546875],
            [-57.3095703125, 5.105859375],
            [-57.269287109375, 5.157031249999989],
            [-57.22685546874999, 5.178515624999989],
            [-57.20981445312499, 5.195410156249991],
            [-57.20732421874999, 5.214208984374991],
            [-57.21845703125, 5.231542968749991],
            [-57.235302734375, 5.242871093749997],
            [-57.27963867187499, 5.246777343749997],
            [-57.3185546875, 5.335351562499994],
            [-57.291894531249994, 5.373974609374997],
            [-57.25751953125, 5.445166015624991],
            [-57.24790039062499, 5.485253906249994],
            [-57.194775390625, 5.5484375],
            [-57.18212890625, 5.52890625],
            [-57.1408203125, 5.643798828125],
            [-57.13603515624999, 5.737207031249994],
            [-57.10458984374999, 5.829394531249989],
            [-57.056640625, 5.938671875],
            [-56.96982421874999, 5.992871093749997],
            [-56.466015625, 5.937744140625],
            [-56.235595703125, 5.885351562499991],
            [-55.93955078124999, 5.795458984374989],
            [-55.897607421874994, 5.699316406249991],
            [-55.8955078125, 5.795458984374989],
            [-55.909912109375, 5.892626953124989],
            [-55.82817382812499, 5.961669921875],
            [-55.648339843749994, 5.985888671874989],
            [-55.379296875, 5.95263671875],
            [-55.14829101562499, 5.993457031249989],
            [-54.83369140625, 5.988330078124989],
            [-54.35615234375, 5.909863281249997],
            [-54.142333984375, 5.856347656249994],
            [-54.05419921875, 5.807910156249989],
            [-54.03740234374999, 5.720507812499989],
            [-54.04594726562499, 5.60888671875],
            [-54.08046875, 5.502246093749989],
            [-54.15595703125, 5.358984375],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 4,
        sovereignt: "Guyana",
        sov_a3: "GUY",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Guyana",
        adm0_a3: "GUY",
        geou_dif: 0,
        geounit: "Guyana",
        gu_a3: "GUY",
        su_dif: 0,
        subunit: "Guyana",
        su_a3: "GUY",
        brk_diff: 0,
        name: "Guyana",
        name_long: "Guyana",
        brk_a3: "GUY",
        brk_name: "Guyana",
        brk_group: null,
        abbrev: "Guy.",
        postal: "GY",
        formal_en: "Co-operative Republic of Guyana",
        formal_fr: null,
        name_ciawf: "Guyana",
        note_adm0: null,
        note_brk: null,
        name_sort: "Guyana",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 1,
        mapcolor9: 4,
        mapcolor13: 8,
        pop_est: 782766,
        pop_rank: 11,
        pop_year: 2019,
        gdp_md: 5173,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        fips_10: "GY",
        iso_a2: "GY",
        iso_a2_eh: "GY",
        iso_a3: "GUY",
        iso_a3_eh: "GUY",
        iso_n3: "328",
        iso_n3_eh: "328",
        un_a3: "328",
        wb_a2: "GY",
        wb_a3: "GUY",
        woe_id: 23424836,
        woe_id_eh: 23424836,
        woe_note: "Exact WOE match as country",
        adm0_iso: "GUY",
        adm0_diff: null,
        adm0_tlc: "GUY",
        adm0_a3_us: "GUY",
        adm0_a3_fr: "GUY",
        adm0_a3_ru: "GUY",
        adm0_a3_es: "GUY",
        adm0_a3_cn: "GUY",
        adm0_a3_tw: "GUY",
        adm0_a3_in: "GUY",
        adm0_a3_np: "GUY",
        adm0_a3_pk: "GUY",
        adm0_a3_de: "GUY",
        adm0_a3_gb: "GUY",
        adm0_a3_br: "GUY",
        adm0_a3_il: "GUY",
        adm0_a3_ps: "GUY",
        adm0_a3_sa: "GUY",
        adm0_a3_eg: "GUY",
        adm0_a3_ma: "GUY",
        adm0_a3_pt: "GUY",
        adm0_a3_ar: "GUY",
        adm0_a3_jp: "GUY",
        adm0_a3_ko: "GUY",
        adm0_a3_vn: "GUY",
        adm0_a3_tr: "GUY",
        adm0_a3_id: "GUY",
        adm0_a3_pl: "GUY",
        adm0_a3_gr: "GUY",
        adm0_a3_it: "GUY",
        adm0_a3_nl: "GUY",
        adm0_a3_se: "GUY",
        adm0_a3_bd: "GUY",
        adm0_a3_ua: "GUY",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "South America",
        region_un: "Americas",
        subregion: "South America",
        region_wb: "Latin America & Caribbean",
        name_len: 6,
        long_len: 6,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: -58.942643,
        label_y: 5.124317,
        ne_id: 1159320817,
        wikidataid: "Q734",
        name_ar: "غيانا",
        name_bn: "গায়ানা",
        name_de: "Guyana",
        name_en: "Guyana",
        name_es: "Guyana",
        name_fa: "گویان",
        name_fr: "Guyana",
        name_el: "Γουιάνα",
        name_he: "גיאנה",
        name_hi: "गयाना",
        name_hu: "Guyana",
        name_id: "Guyana",
        name_it: "Guyana",
        name_ja: "ガイアナ",
        name_ko: "가이아나",
        name_nl: "Guyana",
        name_pl: "Gujana",
        name_pt: "Guiana",
        name_ru: "Гайана",
        name_sv: "Guyana",
        name_tr: "Guyana",
        name_uk: "Гаяна",
        name_ur: "گیانا",
        name_vi: "Guyana",
        name_zh: "圭亚那",
        name_zht: "圭亞那",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "GUY.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-60.742138671875, 5.202050781249994],
            [-60.954003906249994, 5.437402343749994],
            [-61.1671875, 5.67421875],
            [-61.37680664062499, 5.906982421875],
            [-61.3908203125, 5.938769531249989],
            [-61.303125, 6.049511718749997],
            [-61.22495117187499, 6.129199218749989],
            [-61.15947265624999, 6.174414062499991],
            [-61.12871093749999, 6.214306640624997],
            [-61.152294921875, 6.385107421874991],
            [-61.151025390624994, 6.446533203125],
            [-61.181591796875, 6.513378906249997],
            [-61.20361328125, 6.58837890625],
            [-61.17724609375, 6.650927734374989],
            [-61.14560546874999, 6.69453125],
            [-61.10478515624999, 6.711376953124997],
            [-61.007080078125, 6.726611328124989],
            [-60.93798828125, 6.732763671874991],
            [-60.91357421875, 6.7578125],
            [-60.87333984374999, 6.786914062499989],
            [-60.82084960937499, 6.788476562499994],
            [-60.717919921874994, 6.768310546875],
            [-60.671044921874994, 6.805957031249989],
            [-60.586083984374994, 6.857080078124994],
            [-60.39501953125, 6.945361328124989],
            [-60.35209960937499, 7.002880859374997],
            [-60.32207031249999, 7.092041015625],
            [-60.32548828124999, 7.133984375],
            [-60.34506835937499, 7.15],
            [-60.39238281249999, 7.16455078125],
            [-60.46494140624999, 7.166552734374989],
            [-60.523193359375, 7.143701171874994],
            [-60.583203125, 7.156201171874997],
            [-60.63330078125, 7.211083984374994],
            [-60.636181640625, 7.256591796875],
            [-60.60654296874999, 7.320849609374989],
            [-60.62373046875, 7.363330078124989],
            [-60.71923828125, 7.498681640624994],
            [-60.718652343749994, 7.535937499999989],
            [-60.64946289062499, 7.596630859374997],
            [-60.610107421875, 7.648339843749994],
            [-60.55634765625, 7.772021484374989],
            [-60.513623046875, 7.813183593749997],
            [-60.380615234375, 7.82763671875],
            [-60.34677734374999, 7.85400390625],
            [-60.27890625, 7.91943359375],
            [-60.178173828125, 7.994042968749994],
            [-60.032421875, 8.053564453124991],
            [-59.99072265625, 8.162011718749994],
            [-59.96484375, 8.191601562499997],
            [-59.849072265625, 8.248681640624994],
            [-59.82890624999999, 8.279150390624991],
            [-59.831640625, 8.305957031249989],
            [-60.017529296875, 8.54931640625],
            [-59.980615234374994, 8.532617187499994],
            [-59.83652343749999, 8.373828124999989],
            [-59.756738281249994, 8.339501953124994],
            [-59.73994140625, 8.338720703124991],
            [-59.73930664062499, 8.379980468749991],
            [-59.66611328124999, 8.362597656249989],
            [-59.47690429687499, 8.254003906249991],
            [-59.20024414062499, 8.074609375],
            [-58.81157226562499, 7.735595703125],
            [-58.701074218749994, 7.606640625],
            [-58.626611328124994, 7.5458984375],
            [-58.51108398437499, 7.398046875],
            [-58.477294921875, 7.32578125],
            [-58.48056640624999, 7.038134765624989],
            [-58.582910156249994, 6.843652343749994],
            [-58.60791015625, 6.697314453124989],
            [-58.6134765625, 6.502539062499991],
            [-58.67294921874999, 6.390771484374994],
            [-58.593994140625, 6.451513671874991],
            [-58.569482421874994, 6.627246093749989],
            [-58.50229492187499, 6.733984375],
            [-58.414990234375, 6.851171875],
            [-58.2984375, 6.879296875],
            [-58.1728515625, 6.829394531249989],
            [-58.07177734375, 6.820605468749989],
            [-57.982568359374994, 6.785888671875],
            [-57.792871093749994, 6.598535156249994],
            [-57.607568359374994, 6.450390625],
            [-57.54013671874999, 6.33154296875],
            [-57.343652343749994, 6.272119140624994],
            [-57.2275390625, 6.178417968749997],
            [-57.19023437499999, 6.097314453124994],
            [-57.167236328125, 5.885009765625],
            [-57.2052734375, 5.564599609374994],
            [-57.194775390625, 5.5484375],
            [-57.24790039062499, 5.485253906249994],
            [-57.25751953125, 5.445166015624991],
            [-57.291894531249994, 5.373974609374997],
            [-57.3185546875, 5.335351562499994],
            [-57.27963867187499, 5.246777343749997],
            [-57.235302734375, 5.242871093749997],
            [-57.21845703125, 5.231542968749991],
            [-57.20732421874999, 5.214208984374991],
            [-57.20981445312499, 5.195410156249991],
            [-57.22685546874999, 5.178515624999989],
            [-57.269287109375, 5.157031249999989],
            [-57.3095703125, 5.105859375],
            [-57.30576171874999, 5.049560546875],
            [-57.331005859375, 5.020166015624994],
            [-57.41215820312499, 5.004589843749997],
            [-57.57089843749999, 5.004492187499991],
            [-57.648828125, 5.000683593749997],
            [-57.711083984374994, 4.991064453124991],
            [-57.75200195312499, 4.954492187499994],
            [-57.804101562499994, 4.929052734374991],
            [-57.844921875, 4.923046875],
            [-57.881103515625, 4.880615234375],
            [-57.91704101562499, 4.820410156249991],
            [-57.90488281249999, 4.779296875],
            [-57.86787109375, 4.724316406249997],
            [-57.84599609374999, 4.668164062499997],
            [-57.87470703125, 4.577099609374997],
            [-57.90625, 4.506787109374997],
            [-57.924707031249994, 4.453125],
            [-57.94975585937499, 4.349951171874991],
            [-58.0107421875, 4.236474609374994],
            [-58.05449218749999, 4.171923828124989],
            [-58.054296875, 4.101660156249991],
            [-58.0322265625, 4.001953125],
            [-57.90771484375, 3.856689453125],
            [-57.86655273437499, 3.787255859374994],
            [-57.832666015624994, 3.675976562499997],
            [-57.720361328124994, 3.58828125],
            [-57.64946289062499, 3.517382812499989],
            [-57.65610351562499, 3.423730468749994],
            [-57.646728515625, 3.39453125],
            [-57.602734375, 3.370947265624991],
            [-57.54960937499999, 3.352832031249989],
            [-57.49057617187499, 3.354296874999989],
            [-57.437890625, 3.362255859374997],
            [-57.42558593749999, 3.375439453124997],
            [-57.303662109375, 3.377099609374994],
            [-57.289941406249994, 3.353613281249991],
            [-57.28291015625, 3.218847656249991],
            [-57.27792968749999, 3.164306640625],
            [-57.248974609375, 3.142285156249997],
            [-57.231640625, 3.10888671875],
            [-57.23056640624999, 3.078564453124997],
            [-57.225, 3.003076171874994],
            [-57.206933593749994, 2.96337890625],
            [-57.20981445312499, 2.8828125],
            [-57.19736328124999, 2.853271484375],
            [-57.16362304687499, 2.833251953125],
            [-57.12114257812499, 2.775537109374994],
            [-57.105126953124994, 2.768261718749997],
            [-57.096875, 2.747851562499989],
            [-57.060449218749994, 2.665673828124994],
            [-57.041943359375, 2.64111328125],
            [-57.028955078124994, 2.6375],
            [-57.02348632812499, 2.608984375],
            [-56.99711914062499, 2.532177734374997],
            [-56.97929687499999, 2.513232421874989],
            [-56.945214843749994, 2.456835937499989],
            [-56.93149414062499, 2.395361328124991],
            [-56.88642578125, 2.325976562499989],
            [-56.84052734375, 2.277148437499989],
            [-56.81982421875, 2.226660156249991],
            [-56.761132812499994, 2.114892578124994],
            [-56.704345703125, 2.036474609374991],
            [-56.627197265625, 2.016015625],
            [-56.5626953125, 2.005078125],
            [-56.522363281249994, 1.974804687499997],
            [-56.4828125, 1.942138671875],
            [-56.52548828124999, 1.92724609375],
            [-56.56357421874999, 1.9072265625],
            [-56.616455078125, 1.922656249999989],
            [-56.68984375, 1.914306640625],
            [-56.766259765624994, 1.8921875],
            [-56.83671874999999, 1.88125],
            [-56.96953124999999, 1.91640625],
            [-57.01005859374999, 1.921240234374991],
            [-57.03759765625, 1.936474609374997],
            [-57.092675781249994, 2.005810546874997],
            [-57.118896484375, 2.013964843749989],
            [-57.189599609374994, 1.981591796874994],
            [-57.2755859375, 1.959228515625],
            [-57.31748046874999, 1.963476562499991],
            [-57.366796875, 1.940136718749997],
            [-57.41269531249999, 1.908935546875],
            [-57.500439453125, 1.773828125],
            [-57.54575195312499, 1.72607421875],
            [-57.59443359375, 1.7041015625],
            [-57.69174804687499, 1.704785156249997],
            [-57.795654296875, 1.7],
            [-57.8734375, 1.667285156249989],
            [-57.94633789062499, 1.650585937499997],
            [-57.9828125, 1.6484375],
            [-57.9951171875, 1.574316406249991],
            [-58.01176757812499, 1.539941406249994],
            [-58.03466796875, 1.520263671875],
            [-58.09130859375, 1.514355468749997],
            [-58.142236328124994, 1.516992187499994],
            [-58.173095703125, 1.5478515625],
            [-58.230419921875, 1.563281249999989],
            [-58.281152343749994, 1.574316406249991],
            [-58.314208984375, 1.591943359374994],
            [-58.34067382812499, 1.587548828124994],
            [-58.362695312499994, 1.556689453124989],
            [-58.38037109375, 1.530224609374997],
            [-58.39580078124999, 1.481738281249989],
            [-58.47294921874999, 1.466259765624997],
            [-58.5060546875, 1.438671875],
            [-58.48686523437499, 1.347753906249991],
            [-58.495703125, 1.312255859375],
            [-58.511865234374994, 1.28466796875],
            [-58.605078125, 1.279150390624991],
            [-58.68461914062499, 1.281054687499989],
            [-58.73032226562499, 1.247509765624997],
            [-58.78720703124999, 1.20849609375],
            [-58.82177734375, 1.201220703124989],
            [-58.8625, 1.20361328125],
            [-58.91660156249999, 1.248876953124991],
            [-58.968505859375, 1.304589843749994],
            [-59.10039062499999, 1.343652343749994],
            [-59.231201171875, 1.376025390624989],
            [-59.31699218749999, 1.464599609375],
            [-59.33725585937499, 1.508203125],
            [-59.377685546875, 1.52734375],
            [-59.479443359375, 1.632421875],
            [-59.53569335937499, 1.7],
            [-59.596630859375, 1.718017578125],
            [-59.66660156249999, 1.746289062499997],
            [-59.66376953125, 1.795214843749989],
            [-59.66850585937499, 1.842333984374989],
            [-59.69853515624999, 1.861474609374994],
            [-59.74072265625, 1.874169921874994],
            [-59.75620117187499, 1.900634765625],
            [-59.75175781249999, 1.96240234375],
            [-59.74350585937499, 2.121630859374989],
            [-59.75522460937499, 2.274121093749997],
            [-59.84912109375, 2.327050781249994],
            [-59.8896484375, 2.362939453124994],
            [-59.96079101562499, 2.58837890625],
            [-59.9943359375, 2.689990234374989],
            [-59.99589843749999, 2.765429687499989],
            [-59.972314453124994, 2.990478515625],
            [-59.94565429687499, 3.087841796874997],
            [-59.873046875, 3.283105468749994],
            [-59.83115234374999, 3.34921875],
            [-59.82880859375, 3.398583984374994],
            [-59.83305664062499, 3.462158203125],
            [-59.854394531249994, 3.5875],
            [-59.731640625, 3.666552734374989],
            [-59.67900390624999, 3.699804687499991],
            [-59.67021484374999, 3.752734374999989],
            [-59.604443359375, 3.819677734374991],
            [-59.575390625, 3.883447265624994],
            [-59.55112304687499, 3.933544921874997],
            [-59.557763671874994, 3.960009765624989],
            [-59.58642578125, 3.975390624999989],
            [-59.62021484374999, 4.023144531249997],
            [-59.69121093749999, 4.160400390625],
            [-59.71689453124999, 4.188183593749997],
            [-59.73857421874999, 4.226757812499997],
            [-59.727490234375, 4.287646484374989],
            [-59.69970703125, 4.353515625],
            [-59.703271484374994, 4.381103515625],
            [-59.74580078125, 4.416650390624994],
            [-59.83334960937499, 4.475927734374991],
            [-59.90610351562499, 4.480322265624991],
            [-59.962353515625, 4.501708984375],
            [-60.04501953124999, 4.504589843749997],
            [-60.11113281249999, 4.511181640624997],
            [-60.1486328125, 4.533251953124989],
            [-60.14091796874999, 4.569628906249989],
            [-60.12456054687499, 4.59765625],
            [-60.06894531249999, 4.666650390624994],
            [-60.03178710937499, 4.740527343749989],
            [-60.026757812499994, 4.812695312499997],
            [-60.01547851562499, 4.907519531249989],
            [-59.99936523437499, 4.98984375],
            [-59.990673828125, 5.082861328124991],
            [-60.078076171875, 5.143994140624997],
            [-60.10595703125, 5.194238281249994],
            [-60.142041015625, 5.238818359374989],
            [-60.18173828124999, 5.238818359374989],
            [-60.241650390625, 5.257958984374994],
            [-60.335205078125, 5.199316406249991],
            [-60.40878906249999, 5.21015625],
            [-60.45952148437499, 5.188085937499991],
            [-60.576416015625, 5.192480468749991],
            [-60.6513671875, 5.221142578124997],
            [-60.742138671875, 5.202050781249994],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 2,
        sovereignt: "Argentina",
        sov_a3: "ARG",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Argentina",
        adm0_a3: "ARG",
        geou_dif: 0,
        geounit: "Argentina",
        gu_a3: "ARG",
        su_dif: 0,
        subunit: "Argentina",
        su_a3: "ARG",
        brk_diff: 0,
        name: "Argentina",
        name_long: "Argentina",
        brk_a3: "ARG",
        brk_name: "Argentina",
        brk_group: null,
        abbrev: "Arg.",
        postal: "AR",
        formal_en: "Argentine Republic",
        formal_fr: null,
        name_ciawf: "Argentina",
        note_adm0: null,
        note_brk: null,
        name_sort: "Argentina",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 1,
        mapcolor9: 3,
        mapcolor13: 13,
        pop_est: 44938712,
        pop_rank: 15,
        pop_year: 2019,
        gdp_md: 445445,
        gdp_year: 2019,
        economy: "5. Emerging region: G20",
        income_grp: "3. Upper middle income",
        fips_10: "AR",
        iso_a2: "AR",
        iso_a2_eh: "AR",
        iso_a3: "ARG",
        iso_a3_eh: "ARG",
        iso_n3: "032",
        iso_n3_eh: "032",
        un_a3: "032",
        wb_a2: "AR",
        wb_a3: "ARG",
        woe_id: 23424747,
        woe_id_eh: 23424747,
        woe_note: "Exact WOE match as country",
        adm0_iso: "ARG",
        adm0_diff: null,
        adm0_tlc: "ARG",
        adm0_a3_us: "ARG",
        adm0_a3_fr: "ARG",
        adm0_a3_ru: "ARG",
        adm0_a3_es: "ARG",
        adm0_a3_cn: "ARG",
        adm0_a3_tw: "ARG",
        adm0_a3_in: "ARG",
        adm0_a3_np: "ARG",
        adm0_a3_pk: "ARG",
        adm0_a3_de: "ARG",
        adm0_a3_gb: "ARG",
        adm0_a3_br: "ARG",
        adm0_a3_il: "ARG",
        adm0_a3_ps: "ARG",
        adm0_a3_sa: "ARG",
        adm0_a3_eg: "ARG",
        adm0_a3_ma: "ARG",
        adm0_a3_pt: "ARG",
        adm0_a3_ar: "ARG",
        adm0_a3_jp: "ARG",
        adm0_a3_ko: "ARG",
        adm0_a3_vn: "ARG",
        adm0_a3_tr: "ARG",
        adm0_a3_id: "ARG",
        adm0_a3_pl: "ARG",
        adm0_a3_gr: "ARG",
        adm0_a3_it: "ARG",
        adm0_a3_nl: "ARG",
        adm0_a3_se: "ARG",
        adm0_a3_bd: "ARG",
        adm0_a3_ua: "ARG",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "South America",
        region_un: "Americas",
        subregion: "South America",
        region_wb: "Latin America & Caribbean",
        name_len: 9,
        long_len: 9,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 2,
        max_label: 7,
        label_x: -64.173331,
        label_y: -33.501159,
        ne_id: 1159320331,
        wikidataid: "Q414",
        name_ar: "الأرجنتين",
        name_bn: "আর্জেন্টিনা",
        name_de: "Argentinien",
        name_en: "Argentina",
        name_es: "Argentina",
        name_fa: "آرژانتین",
        name_fr: "Argentine",
        name_el: "Αργεντινή",
        name_he: "ארגנטינה",
        name_hi: "अर्जेण्टीना",
        name_hu: "Argentína",
        name_id: "Argentina",
        name_it: "Argentina",
        name_ja: "アルゼンチン",
        name_ko: "아르헨티나",
        name_nl: "Argentinië",
        name_pl: "Argentyna",
        name_pt: "Argentina",
        name_ru: "Аргентина",
        name_sv: "Argentina",
        name_tr: "Arjantin",
        name_uk: "Аргентина",
        name_ur: "ارجنٹائن",
        name_vi: "Argentina",
        name_zh: "阿根廷",
        name_zht: "阿根廷",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "ARG.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-57.60888671875, -30.187792968750003],
              [-57.645751953125, -30.226953125],
              [-57.65087890625, -30.295019531250006],
              [-57.71269531249999, -30.38447265625001],
              [-57.831201171874994, -30.495214843750006],
              [-57.872509765625, -30.59101562500001],
              [-57.8185546875, -30.712011718750006],
              [-57.81059570312499, -30.85859375000001],
              [-57.83408203124999, -30.91748046875],
              [-57.886328125, -30.93740234375001],
              [-57.89829101562499, -30.975195312500006],
              [-57.870068359375, -31.031054687500003],
              [-57.868408203125, -31.10439453125001],
              [-57.89335937499999, -31.1953125],
              [-57.94833984374999, -31.299414062500006],
              [-58.0333984375, -31.416601562500006],
              [-58.053857421874994, -31.494921875],
              [-58.009667968749994, -31.534375],
              [-57.98798828125, -31.576171875],
              [-57.98886718749999, -31.62060546875],
              [-58.006982421874994, -31.68496093750001],
              [-58.04233398437499, -31.76923828125001],
              [-58.095849609374994, -31.831835937500003],
              [-58.16748046875, -31.87265625],
              [-58.18901367187499, -31.92421875],
              [-58.160400390625, -31.98652343750001],
              [-58.15634765624999, -32.0515625],
              [-58.177001953125, -32.11904296875001],
              [-58.164794921875, -32.18486328125],
              [-58.11972656249999, -32.24892578125001],
              [-58.123046875, -32.321875],
              [-58.201171875, -32.4716796875],
              [-58.219970703125, -32.56396484375],
              [-58.17099609374999, -32.95927734375],
              [-58.20078125, -33.0146484375],
              [-58.250390625, -33.07832031250001],
              [-58.30888671874999, -33.08291015625001],
              [-58.3759765625, -33.071875],
              [-58.424462890624994, -33.11152343750001],
              [-58.454833984375, -33.2859375],
              [-58.54721679687499, -33.66347656250001],
              [-58.53056640624999, -33.753027343750006],
              [-58.45659179687499, -33.89833984375001],
              [-58.42949218749999, -33.99091796875001],
              [-58.40903320312499, -34.06074218750001],
              [-58.392480468749994, -34.19296875],
              [-58.435498046875, -34.252539062500006],
              [-58.475244140624994, -34.26298828125],
              [-58.52548828124999, -34.29619140625],
              [-58.466210937499994, -34.457421875],
              [-58.4189453125, -34.531640625],
              [-58.283349609374994, -34.68349609375001],
              [-57.763574218749994, -34.89453125],
              [-57.5478515625, -35.01894531250001],
              [-57.303662109375, -35.1884765625],
              [-57.170654296875, -35.3625],
              [-57.15888671875, -35.505957031250006],
              [-57.35390625, -35.7203125],
              [-57.37548828125, -35.90029296875001],
              [-57.33544921875, -36.02675781250001],
              [-57.26499023437499, -36.144140625],
              [-57.076171875, -36.29677734375001],
              [-56.937158203124994, -36.3525390625],
              [-56.749462890625, -36.346484375],
              [-56.71738281249999, -36.3890625],
              [-56.69809570312499, -36.42646484375001],
              [-56.66826171874999, -36.73525390625001],
              [-56.672021484374994, -36.85126953125001],
              [-56.72714843749999, -36.95771484375001],
              [-57.08769531249999, -37.446386718750006],
              [-57.395751953125, -37.74462890625],
              [-57.507275390625, -37.909277343750006],
              [-57.54697265624999, -38.08564453125001],
              [-57.64560546874999, -38.16962890625001],
              [-58.17919921875, -38.43583984375002],
              [-59.007226562499994, -38.67333984375],
              [-59.67626953125, -38.79667968750002],
              [-59.8283203125, -38.83818359375002],
              [-60.903955078124994, -38.97392578125002],
              [-61.112207031249994, -38.99296875000002],
              [-61.38286132812499, -38.980859375],
              [-61.6025390625, -38.99882812500002],
              [-61.847900390625, -38.961816406249994],
              [-62.06689453125, -38.91914062500001],
              [-62.18925781249999, -38.81328125000002],
              [-62.33476562499999, -38.80009765625002],
              [-62.374462890625, -38.852929687499994],
              [-62.303613281249994, -38.98808593750002],
              [-62.3380859375, -39.15058593750001],
              [-62.295068359374994, -39.243261718750006],
              [-62.20908203124999, -39.261816406250006],
              [-62.12646484375, -39.30976562500001],
              [-62.053662109375, -39.37382812500002],
              [-62.179345703124994, -39.38046875],
              [-62.13056640625, -39.431542968749994],
              [-62.076806640624994, -39.461523437500006],
              [-62.082763671875, -39.568359375],
              [-62.13154296875, -39.82539062500001],
              [-62.25395507812499, -39.88046875],
              [-62.28691406249999, -39.89531250000002],
              [-62.323974609375, -39.95068359375],
              [-62.40185546875, -40.19658203125002],
              [-62.427001953125, -40.35595703125],
              [-62.39360351562499, -40.45878906250002],
              [-62.246337890625, -40.67460937500002],
              [-62.30185546874999, -40.81464843750001],
              [-62.39501953125, -40.89082031250001],
              [-62.797998046874994, -41.04716796875002],
              [-62.95903320312499, -41.10966796875002],
              [-63.212841796875, -41.152441406250006],
              [-63.62177734375, -41.159765625],
              [-63.77299804687499, -41.15],
              [-64.123193359375, -41.0078125],
              [-64.383447265625, -40.922460937500006],
              [-64.621484375, -40.8544921875],
              [-64.852978515625, -40.81376953125002],
              [-64.81987304687499, -40.79326171875002],
              [-64.80439453125, -40.75654296875001],
              [-64.86948242187499, -40.73583984375],
              [-64.91689453125, -40.731347656249994],
              [-65.06943359374999, -40.805273437500006],
              [-65.13339843749999, -40.88066406250002],
              [-65.15185546875, -40.94697265625001],
              [-65.15498046875, -41.10566406250001],
              [-65.127880859375, -41.23876953125],
              [-65.01826171875, -41.56689453125],
              [-65.00703125, -41.7451171875],
              [-65.05908203125, -41.96992187500001],
              [-64.98637695312499, -42.10205078125],
              [-64.898046875, -42.16181640625001],
              [-64.69951171874999, -42.220800781250006],
              [-64.6224609375, -42.26103515625002],
              [-64.537744140625, -42.25458984375001],
              [-64.51171875, -42.27021484375001],
              [-64.52421874999999, -42.29921875],
              [-64.57412109375, -42.35595703125],
              [-64.57099609375, -42.416015625],
              [-64.42041015625, -42.43378906250001],
              [-64.264599609375, -42.42167968750002],
              [-64.10087890624999, -42.395117187500006],
              [-64.06220703125, -42.353417968749994],
              [-64.061181640625, -42.26611328125],
              [-64.2529296875, -42.25078125000002],
              [-64.228515625, -42.21826171875],
              [-64.083251953125, -42.18281250000001],
              [-63.89287109374999, -42.124609375],
              [-63.795556640624994, -42.113867187500006],
              [-63.7294921875, -42.152929687500006],
              [-63.684765625, -42.18867187500001],
              [-63.6298828125, -42.28271484375],
              [-63.5958984375, -42.40654296875002],
              [-63.59443359375, -42.555566406249994],
              [-63.617333984374994, -42.69580078125],
              [-63.644482421875, -42.745703125],
              [-63.69248046874999, -42.805273437500006],
              [-64.034765625, -42.88125],
              [-64.13066406249999, -42.861425781250006],
              [-64.219921875, -42.75556640625001],
              [-64.24794921875, -42.64609375],
              [-64.32426757812499, -42.572265625],
              [-64.48784179687499, -42.51347656250002],
              [-64.65048828124999, -42.53144531250001],
              [-64.811962890625, -42.633203125],
              [-64.970703125, -42.66630859375002],
              [-65.02690429687499, -42.758886718750006],
              [-64.62919921874999, -42.90898437500002],
              [-64.441552734375, -42.95068359375],
              [-64.38037109375, -42.94921875],
              [-64.31914062499999, -42.96894531250001],
              [-64.37568359375, -43.02460937500001],
              [-64.43222656249999, -43.059179687500006],
              [-64.715234375, -43.13554687500002],
              [-64.83994140624999, -43.188867187499994],
              [-64.985546875, -43.293554687500006],
              [-65.18974609374999, -43.522070312500006],
              [-65.25234375, -43.571875],
              [-65.28359375, -43.629980468750006],
              [-65.3046875, -43.7875],
              [-65.23857421874999, -44.048730468749994],
              [-65.30839843749999, -44.158203125],
              [-65.26552734375, -44.2796875],
              [-65.28984374999999, -44.360742187499994],
              [-65.361279296875, -44.47734375000002],
              [-65.647607421875, -44.66142578125002],
              [-65.69833984374999, -44.79619140625002],
              [-65.59912109375, -44.875585937500006],
              [-65.605712890625, -44.94501953125001],
              [-65.63876953124999, -45.0078125],
              [-65.75771484375, -45.00712890625002],
              [-66.19013671875, -44.964746093749994],
              [-66.34775390624999, -45.03359375],
              [-66.493603515625, -45.117578125],
              [-66.533447265625, -45.1578125],
              [-66.58505859374999, -45.18291015625002],
              [-66.882470703125, -45.227636718750006],
              [-66.94140625, -45.25732421875],
              [-67.25761718749999, -45.577246093750006],
              [-67.393017578125, -45.77558593750001],
              [-67.556640625, -45.970117187499994],
              [-67.599560546875, -46.05253906250002],
              [-67.60888671875, -46.16679687500002],
              [-67.586083984375, -46.26953125],
              [-67.56337890625, -46.34541015625001],
              [-67.50644531249999, -46.442773437499994],
              [-67.38662109375, -46.553808593750006],
              [-66.77685546875, -47.005859375],
              [-66.650390625, -47.0453125],
              [-65.99853515625, -47.09375],
              [-65.853662109375, -47.15673828125],
              [-65.76909179687499, -47.256738281249994],
              [-65.73808593749999, -47.34492187500001],
              [-65.775390625, -47.568359375],
              [-65.81430664062499, -47.63818359375],
              [-65.886328125, -47.7015625],
              [-66.040625, -47.783300781250006],
              [-66.225244140625, -47.826757812500006],
              [-66.17236328125, -47.85761718750001],
              [-66.09736328125, -47.85322265625001],
              [-65.934228515625, -47.826757812500006],
              [-65.863671875, -47.85322265625001],
              [-65.81005859375, -47.94111328125001],
              [-65.91215820312499, -47.97675781250001],
              [-65.94340820312499, -48.01933593750002],
              [-66.0171875, -48.08427734375002],
              [-66.39335937499999, -48.342382812500006],
              [-66.59628906249999, -48.41953125],
              [-66.7828125, -48.52294921875],
              [-67.03310546875, -48.62773437500002],
              [-67.13095703124999, -48.687890625],
              [-67.263330078125, -48.81425781250002],
              [-67.46630859375, -48.951757812500006],
              [-67.68486328124999, -49.246679687500006],
              [-67.69370117187499, -49.30400390625002],
              [-67.66196289062499, -49.3421875],
              [-67.78349609374999, -49.85888671875],
              [-67.82597656249999, -49.91962890625001],
              [-67.91396484375, -49.984472656250006],
              [-68.145654296875, -50.09140625],
              [-68.2572265625, -50.104589843750006],
              [-68.40463867187499, -50.04267578125001],
              [-68.487890625, -49.977929687499994],
              [-68.569287109375, -49.86699218750002],
              [-68.667578125, -49.752539062500006],
              [-68.67265624999999, -49.79345703125],
              [-68.63847656249999, -49.86298828125001],
              [-68.66162109375, -49.93574218750001],
              [-68.91298828125, -49.96875],
              [-68.97958984374999, -50.003027343750006],
              [-68.752685546875, -49.987695312499994],
              [-68.59794921874999, -50.00947265625001],
              [-68.53256835937499, -50.0361328125],
              [-68.47373046874999, -50.09140625],
              [-68.421875, -50.15791015625001],
              [-68.46542968749999, -50.194726562499994],
              [-68.58935546875, -50.225195312500006],
              [-68.74985351562499, -50.281152343749994],
              [-68.939453125, -50.38232421875],
              [-69.04477539062499, -50.499121093750006],
              [-69.09018554687499, -50.583105468750006],
              [-69.14140624999999, -50.752539062500006],
              [-69.15498046875, -50.86445312500001],
              [-69.23515624999999, -50.950585937499994],
              [-69.35859375, -51.028125],
              [-69.3517578125, -51.045800781249994],
              [-69.26796875, -51.00615234375002],
              [-69.20102539062499, -50.99365234375],
              [-69.143505859375, -51.09697265625002],
              [-69.06572265624999, -51.30351562500002],
              [-69.02958984374999, -51.44648437500001],
              [-69.035302734375, -51.48896484375001],
              [-69.05830078125, -51.54716796875002],
              [-69.21806640624999, -51.56123046875001],
              [-69.360546875, -51.559472656249994],
              [-69.46542968749999, -51.58447265625],
              [-69.40908203125, -51.610253906249994],
              [-69.31303710937499, -51.60107421875],
              [-69.180126953125, -51.66230468750001],
              [-69.03251953124999, -51.63623046875],
              [-68.96533203125, -51.677148437499994],
              [-68.91679687499999, -51.71464843750002],
              [-68.6908203125, -52.013085937499994],
              [-68.49350585937499, -52.19755859375002],
              [-68.39375, -52.30703125],
              [-68.443359375, -52.35664062500001],
              [-68.46098632812499, -52.290429687499994],
              [-68.589794921875, -52.273339843749994],
              [-68.71518554687499, -52.25546875],
              [-68.924560546875, -52.208105468750006],
              [-69.206201171875, -52.136132812499994],
              [-69.488427734375, -52.136132812499994],
              [-69.71259765625, -52.07539062500001],
              [-69.96025390624999, -52.008203125],
              [-70.482861328125, -52.00224609375002],
              [-70.94316406249999, -51.998144531250006],
              [-71.41474609375, -51.99394531250002],
              [-71.71660156249999, -51.991308593750006],
              [-71.91865234375, -51.98955078125002],
              [-71.97109375, -51.96416015625002],
              [-71.95346679687499, -51.88037109375],
              [-72.02841796874999, -51.81865234375002],
              [-72.136962890625, -51.744042968749994],
              [-72.268994140625, -51.69111328125001],
              [-72.33452148437499, -51.62031250000001],
              [-72.407666015625, -51.54082031250002],
              [-72.36640625, -51.4703125],
              [-72.30322265625, -51.298925781250006],
              [-72.30185546874999, -51.22333984375001],
              [-72.35917968749999, -51.17041015625],
              [-72.37680664062499, -51.09541015625001],
              [-72.35917968749999, -51.06015625],
              [-72.307373046875, -51.03339843750001],
              [-72.276318359375, -50.910253906250006],
              [-72.30063476562499, -50.78955078125],
              [-72.340234375, -50.68183593750001],
              [-72.392578125, -50.63427734375],
              [-72.46015625, -50.61171875],
              [-72.50981445312499, -50.607519531250006],
              [-72.62041015624999, -50.64765625000001],
              [-72.80361328125, -50.6376953125],
              [-72.86591796875, -50.653125],
              [-72.95556640625, -50.69648437500001],
              [-73.08237304687499, -50.760351562500006],
              [-73.15292968749999, -50.73828125],
              [-73.17451171875, -50.670019531250006],
              [-73.221630859375, -50.610742187499994],
              [-73.251611328125, -50.558496093749994],
              [-73.274169921875, -50.472558593749994],
              [-73.31171875, -50.361914062500006],
              [-73.38662109375, -50.23115234375001],
              [-73.50126953124999, -50.12529296875002],
              [-73.50771484375, -50.0302734375],
              [-73.52890625, -49.91093750000002],
              [-73.47041015625, -49.79453125],
              [-73.504541015625, -49.69804687500002],
              [-73.57626953124999, -49.582910156249994],
              [-73.55419921875, -49.4638671875],
              [-73.483642578125, -49.39765625000001],
              [-73.461572265625, -49.313867187499994],
              [-73.13525390625, -49.300683593749994],
              [-73.148876953125, -49.18798828125],
              [-73.09458007812499, -49.096875],
              [-73.033642578125, -49.01435546875001],
              [-72.98173828124999, -48.97675781250001],
              [-72.8654296875, -48.943945312500006],
              [-72.728466796875, -48.89628906250002],
              [-72.65126953125, -48.84160156250002],
              [-72.614404296875, -48.792871093749994],
              [-72.591748046875, -48.72968750000001],
              [-72.5859375, -48.6625],
              [-72.6083984375, -48.51933593750002],
              [-72.58286132812499, -48.47539062500002],
              [-72.49814453124999, -48.417382812499994],
              [-72.354736328125, -48.365820312500006],
              [-72.29301757812499, -48.229101562500006],
              [-72.3283203125, -48.11005859375001],
              [-72.40791015625, -48.01591796875002],
              [-72.50908203124999, -47.97333984375001],
              [-72.51791992187499, -47.876367187499994],
              [-72.47221679687499, -47.7841796875],
              [-72.41259765625, -47.685546875],
              [-72.34150390625, -47.57207031250002],
              [-72.345947265625, -47.49267578125],
              [-72.28291015625, -47.4462890625],
              [-72.10341796875, -47.3427734375],
              [-72.04169921875, -47.24140625000001],
              [-71.978515625, -47.2138671875],
              [-71.90498046875, -47.20166015625],
              [-71.900537109375, -47.14433593750002],
              [-71.954248046875, -47.0875],
              [-71.96298828124999, -47.016015625],
              [-71.956640625, -46.93681640625002],
              [-71.94023437499999, -46.83125],
              [-71.8564453125, -46.791601562500006],
              [-71.73271484374999, -46.70585937500002],
              [-71.699658203125, -46.6513671875],
              [-71.69521484375, -46.57841796875002],
              [-71.73129882812499, -46.427832031250006],
              [-71.762109375, -46.31982421875],
              [-71.77763671874999, -46.27998046875001],
              [-71.834130859375, -46.20673828125001],
              [-71.87568359375, -46.160546875],
              [-71.80927734375, -46.10273437500001],
              [-71.68447265625, -46.041894531249994],
              [-71.63154296875, -45.953710937500006],
              [-71.680078125, -45.87871093750002],
              [-71.750634765625, -45.83906250000001],
              [-71.77265625, -45.72441406250002],
              [-71.74619140624999, -45.57890625000002],
              [-71.693310546875, -45.534765625],
              [-71.50810546874999, -45.5126953125],
              [-71.4904296875, -45.43769531250001],
              [-71.34931640625, -45.331933593749994],
              [-71.353759765625, -45.23046875],
              [-71.44345703124999, -45.16826171875002],
              [-71.53129882812499, -45.06787109375],
              [-71.59628906249999, -44.97919921875001],
              [-71.81235351562499, -44.9306640625],
              [-72.04169921875, -44.904199218749994],
              [-72.072509765625, -44.820410156250006],
              [-72.063720703125, -44.771875],
              [-71.95703125, -44.79150390625],
              [-71.7828125, -44.7744140625],
              [-71.65166015624999, -44.770410156249994],
              [-71.56040039062499, -44.76201171875002],
              [-71.45517578124999, -44.74980468750002],
              [-71.358154296875, -44.78515625],
              [-71.2611328125, -44.763085937499994],
              [-71.22148437499999, -44.630761718749994],
              [-71.15971679687499, -44.56025390625001],
              [-71.15087890625, -44.494042968749994],
              [-71.21259765625, -44.44121093750002],
              [-71.32573242187499, -44.424902343750006],
              [-71.82001953125, -44.38310546875002],
              [-71.83505859374999, -44.330175781250006],
              [-71.83076171875, -44.24140625000001],
              [-71.812109375, -44.15078125],
              [-71.81235351562499, -44.106054687500006],
              [-71.7671875, -44.06669921875002],
              [-71.71616210937499, -43.984472656250006],
              [-71.680078125, -43.929589843749994],
              [-71.715966796875, -43.8583984375],
              [-71.79472656249999, -43.75322265625002],
              [-71.73740234374999, -43.7046875],
              [-71.73276367187499, -43.64677734375002],
              [-71.750634765625, -43.59013671875002],
              [-71.832421875, -43.52714843750002],
              [-71.90498046875, -43.44013671875001],
              [-71.90498046875, -43.347558593749994],
              [-71.82021484375, -43.32294921875001],
              [-71.7638671875, -43.29462890625001],
              [-71.750634765625, -43.2373046875],
              [-71.781494140625, -43.16679687500002],
              [-71.898583984375, -43.14531250000002],
              [-72.0546875, -43.101953125],
              [-72.10239257812499, -43.065625],
              [-72.146435546875, -42.99003906250002],
              [-72.11362304687499, -42.776757812499994],
              [-72.130029296875, -42.64824218750002],
              [-72.143701171875, -42.5771484375],
              [-72.105419921875, -42.5224609375],
              [-72.053466796875, -42.473242187500006],
              [-72.078125, -42.358496093750006],
              [-72.124609375, -42.29833984375],
              [-72.10820312499999, -42.251855468749994],
              [-72.064404296875, -42.20537109375002],
              [-72.026123046875, -42.14794921875],
              [-71.993310546875, -42.13427734375],
              [-71.944091796875, -42.167089843750006],
              [-71.860791015625, -42.147851562499994],
              [-71.7609375, -42.101464843749994],
              [-71.75, -42.046777343749994],
              [-71.77001953125, -41.96855468750002],
              [-71.844482421875, -41.77197265625],
              [-71.911279296875, -41.650390625],
              [-71.897607421875, -41.60664062500001],
              [-71.87114257812499, -41.560546875],
              [-71.8921875, -41.39335937500002],
              [-71.88559570312499, -41.292382812499994],
              [-71.88071289062499, -40.99462890625],
              [-71.873046875, -40.89296875],
              [-71.941357421875, -40.789160156250006],
              [-71.93212890625, -40.69169921875002],
              [-71.8837890625, -40.62060546875],
              [-71.838525390625, -40.5244140625],
              [-71.804638671875, -40.43916015625001],
              [-71.769140625, -40.40087890625],
              [-71.708984375, -40.381738281249994],
              [-71.6953125, -40.33525390625002],
              [-71.72265625, -40.299707031249994],
              [-71.80058593749999, -40.24433593750001],
              [-71.818310546875, -40.176660156249994],
              [-71.801953125, -40.12470703125001],
              [-71.763671875, -40.094628906249994],
              [-71.70439453124999, -40.09492187500001],
              [-71.659765625, -40.02080078125002],
              [-71.647119140625, -39.92919921875],
              [-71.637890625, -39.886816406250006],
              [-71.6720703125, -39.83330078125002],
              [-71.69682617187499, -39.70703125],
              [-71.719921875, -39.63525390625],
              [-71.692578125, -39.60517578125001],
              [-71.654296875, -39.59423828125],
              [-71.58701171874999, -39.61113281250002],
              [-71.539453125, -39.602441406249994],
              [-71.53125, -39.56416015625001],
              [-71.52578125, -39.52314453125001],
              [-71.507763671875, -39.495214843750006],
              [-71.46538085937499, -39.40234375],
              [-71.42001953124999, -39.287207031250006],
              [-71.409375, -39.205957031249994],
              [-71.42558593749999, -38.98564453125002],
              [-71.4015625, -38.93505859375],
              [-71.353173828125, -38.88886718750001],
              [-71.28574218749999, -38.84541015625001],
              [-71.197265625, -38.809375],
              [-71.087109375, -38.75751953125001],
              [-70.951611328125, -38.73847656250001],
              [-70.896923828125, -38.681054687499994],
              [-70.858642578125, -38.6044921875],
              [-70.84765625, -38.541601562500006],
              [-70.899658203125, -38.49785156250002],
              [-70.96796875, -38.445898437500006],
              [-71.00048828125, -38.31484375],
              [-71.01816406249999, -38.193945312500006],
              [-71.02817382812499, -38.04121093750001],
              [-71.09619140625, -37.9099609375],
              [-71.167578125, -37.762304687500006],
              [-71.18671875, -37.63105468750001],
              [-71.162841796875, -37.559179687500006],
              [-71.13481445312499, -37.4451171875],
              [-71.16489257812499, -37.39326171875001],
              [-71.200390625, -37.30029296875],
              [-71.1634765625, -37.22744140625001],
              [-71.118408203125, -37.114355468750006],
              [-71.12382812499999, -37.05693359375],
              [-71.159375, -36.92021484375],
              [-71.19218749999999, -36.84365234375001],
              [-71.159375, -36.76162109375001],
              [-71.107421875, -36.68505859375],
              [-71.06640625, -36.64404296875],
              [-71.0732421875, -36.57802734375001],
              [-71.05551757812499, -36.52373046875],
              [-70.9779296875, -36.4873046875],
              [-70.90512695312499, -36.419921875],
              [-70.853173828125, -36.41171875],
              [-70.790283203125, -36.41171875],
              [-70.749267578125, -36.392578125],
              [-70.732861328125, -36.340625],
              [-70.721923828125, -36.283203125],
              [-70.621875, -36.2119140625],
              [-70.56337890625, -36.14638671875001],
              [-70.45673828125, -36.13271484375001],
              [-70.40478515625, -36.06171875000001],
              [-70.40366210937499, -35.9705078125],
              [-70.41572265625, -35.878515625],
              [-70.38017578124999, -35.771875],
              [-70.41972656249999, -35.6091796875],
              [-70.41572265625, -35.523046875],
              [-70.45673828125, -35.451953125],
              [-70.44853515624999, -35.375390625],
              [-70.47041015625, -35.326171875],
              [-70.53232421874999, -35.30791015625],
              [-70.55517578125, -35.246875],
              [-70.52509765625, -35.216796875],
              [-70.46660156249999, -35.19365234375],
              [-70.39316406249999, -35.146875],
              [-70.338134765625, -34.92177734375001],
              [-70.312109375, -34.85498046875],
              [-70.286767578125, -34.774511718750006],
              [-70.28994140625, -34.7328125],
              [-70.25468749999999, -34.67265625],
              [-70.210693359375, -34.58125],
              [-70.141259765625, -34.49287109375001],
              [-70.10146484375, -34.43203125],
              [-70.06298828125, -34.35],
              [-70.05205078124999, -34.30078125],
              [-70.00283203125, -34.27626953125001],
              [-69.94633789062499, -34.269921875],
              [-69.87978515625, -34.25439453125],
              [-69.85244140625, -34.22431640625001],
              [-69.857373046875, -34.18046875],
              [-69.8615234375, -34.08359375],
              [-69.881494140625, -33.929785156250006],
              [-69.89433593749999, -33.73134765625001],
              [-69.882568359375, -33.60097656250001],
              [-69.83876953125, -33.4697265625],
              [-69.79775390625, -33.39863281250001],
              [-69.80869140624999, -33.34394531250001],
              [-69.81962890624999, -33.283789062500006],
              [-69.89619140625, -33.2509765625],
              [-69.96904296874999, -33.279394531250006],
              [-70.01982421874999, -33.271484375],
              [-70.08486328125, -33.201757812500006],
              [-70.10400390625, -33.1279296875],
              [-70.09306640624999, -33.02675781250001],
              [-70.042138671875, -32.963671875],
              [-70.02197265625, -32.8845703125],
              [-70.05205078124999, -32.859960937500006],
              [-70.116162109375, -32.807421875],
              [-70.176953125, -32.626074218750006],
              [-70.16962890625, -32.4716796875],
              [-70.22978515624999, -32.4306640625],
              [-70.2578125, -32.30996093750001],
              [-70.32001953125, -32.266699218750006],
              [-70.34462890625, -32.17646484375001],
              [-70.36376953125, -32.08349609375],
              [-70.35556640624999, -32.04238281250001],
              [-70.29091796875, -32.0310546875],
              [-70.25439453125, -31.95771484375001],
              [-70.28173828125, -31.916601562500006],
              [-70.33095703125, -31.88105468750001],
              [-70.39384765624999, -31.8837890625],
              [-70.450146484375, -31.841894531250006],
              [-70.525634765625, -31.66640625],
              [-70.585205078125, -31.569433593750006],
              [-70.56640625, -31.42792968750001],
              [-70.5546875, -31.3173828125],
              [-70.529052734375, -31.22285156250001],
              [-70.51958007812499, -31.1484375],
              [-70.473095703125, -31.11279296875],
              [-70.42939453125, -31.129296875],
              [-70.38837890625, -31.12109375],
              [-70.3505859375, -31.06044921875001],
              [-70.30908203125, -31.02265625000001],
              [-70.31181640624999, -30.992578125],
              [-70.33642578125, -30.959765625],
              [-70.34814453125, -30.90234375],
              [-70.31923828125, -30.833984375],
              [-70.26938476562499, -30.67724609375],
              [-70.19394531249999, -30.5046875],
              [-70.16142578124999, -30.440234375],
              [-70.16962890625, -30.385546875],
              [-70.15322265625, -30.3609375],
              [-70.102001953125, -30.38828125],
              [-69.95634765624999, -30.358203125],
              [-69.90712890625, -30.281640625],
              [-69.88803710937499, -30.21328125],
              [-69.84428710937499, -30.175],
              [-69.86337890624999, -30.12031250000001],
              [-69.92353515625, -30.10390625],
              [-69.9599609375, -30.07832031250001],
              [-69.945458984375, -30.01640625],
              [-69.92412109374999, -29.8740234375],
              [-69.92763671875, -29.769140625],
              [-69.9826171875, -29.54541015625],
              [-70.026806640625, -29.324023437500003],
              [-69.99560546875, -29.25],
              [-69.900341796875, -29.148828125],
              [-69.827880859375, -29.10322265625001],
              [-69.81484375, -29.045507812500006],
              [-69.7431640625, -28.78388671875001],
              [-69.73491210937499, -28.64111328125],
              [-69.687890625, -28.56201171875],
              [-69.65693359375, -28.41357421875],
              [-69.52714843749999, -28.28564453125],
              [-69.48886718749999, -28.20087890625001],
              [-69.4369140625, -28.192675781250003],
              [-69.4095703125, -28.165332031250003],
              [-69.34072265625, -28.07080078125],
              [-69.251220703125, -27.9736328125],
              [-69.17441406249999, -27.92470703125001],
              [-69.1552734375, -27.84814453125],
              [-69.11850585937499, -27.74355468750001],
              [-69.0421875, -27.57001953125001],
              [-68.9994140625, -27.449023437500003],
              [-68.94199218749999, -27.40517578125001],
              [-68.87509765624999, -27.246679687500006],
              [-68.846337890625, -27.15371093750001],
              [-68.769775390625, -27.11542968750001],
              [-68.709619140625, -27.1044921875],
              [-68.65219726562499, -27.14833984375001],
              [-68.59208984374999, -27.14003906250001],
              [-68.537353515625, -27.08535156250001],
              [-68.40537109374999, -27.048144531250003],
              [-68.34599609374999, -27.027929687500006],
              [-68.31865234374999, -26.973242187500006],
              [-68.31865234374999, -26.877539062500006],
              [-68.37333984374999, -26.806445312500003],
              [-68.485107421875, -26.6703125],
              [-68.58115234374999, -26.518359375],
              [-68.59160156249999, -26.47041015625001],
              [-68.5921875, -26.418066406250006],
              [-68.57578125, -26.351953125],
              [-68.52983398437499, -26.276953125],
              [-68.414501953125, -26.15371093750001],
              [-68.4267578125, -26.0654296875],
              [-68.51083984374999, -25.741015625],
              [-68.54189453125, -25.65156250000001],
              [-68.60029296875, -25.485644531250003],
              [-68.59208984374999, -25.420019531250006],
              [-68.54082031249999, -25.23671875],
              [-68.496337890625, -25.16298828125001],
              [-68.43071289062499, -25.14931640625001],
              [-68.39521484375, -25.12470703125001],
              [-68.384228515625, -25.091894531250006],
              [-68.42802734374999, -25.05097656250001],
              [-68.44711914062499, -24.99892578125001],
              [-68.46630859375, -24.92519531250001],
              [-68.52705078125, -24.89921875],
              [-68.56201171875, -24.837695312500003],
              [-68.56201171875, -24.747363281250003],
              [-68.507275390625, -24.62978515625001],
              [-68.44711914062499, -24.596972656250003],
              [-68.42255859375, -24.54511718750001],
              [-68.35810546875, -24.49726562500001],
              [-68.29951171875, -24.46035156250001],
              [-68.25029296874999, -24.39199218750001],
              [-68.04736328125, -24.30830078125001],
              [-67.88623046875, -24.24335937500001],
              [-67.57177734375, -24.118945312500003],
              [-67.356201171875, -24.033789062500006],
              [-67.335595703125, -23.97480468750001],
              [-67.31914062499999, -23.934667968750006],
              [-67.219140625, -23.63398437500001],
              [-67.08974609375, -23.2451171875],
              [-67.0087890625, -23.00136718750001],
              [-67.19487304687499, -22.82167968750001],
              [-67.16191406249999, -22.773828125],
              [-67.055419921875, -22.65087890625],
              [-67.03354492187499, -22.55224609375],
              [-66.99111328125, -22.509863281250006],
              [-66.80029296875, -22.40966796875],
              [-66.76748046875, -22.343066406250003],
              [-66.750634765625, -22.269335937500003],
              [-66.71171874999999, -22.21630859375],
              [-66.63901367187499, -22.205371093750003],
              [-66.506982421875, -22.15839843750001],
              [-66.365185546875, -22.11376953125],
              [-66.3224609375, -22.053125],
              [-66.28212890625, -21.94746093750001],
              [-66.24760742187499, -21.83046875],
              [-66.220166015625, -21.802539062500003],
              [-66.17465820312499, -21.8056640625],
              [-66.098583984375, -21.835058593750006],
              [-66.05859375, -21.879492187500006],
              [-65.86015624999999, -22.01972656250001],
              [-65.77104492187499, -22.099609375],
              [-65.686181640625, -22.11025390625001],
              [-65.518798828125, -22.09453125],
              [-65.48486328125, -22.09814453125],
              [-65.0578125, -22.10273437500001],
              [-64.992626953125, -22.109667968750003],
              [-64.84306640624999, -22.14394531250001],
              [-64.75864257812499, -22.17128906250001],
              [-64.70009765625, -22.185546875],
              [-64.60551757812499, -22.228808593750003],
              [-64.5236328125, -22.37158203125],
              [-64.477734375, -22.4853515625],
              [-64.4455078125, -22.58535156250001],
              [-64.373974609375, -22.761035156250003],
              [-64.32529296874999, -22.82763671875],
              [-64.30791015624999, -22.7953125],
              [-64.26640624999999, -22.603320312500003],
              [-64.20908203124999, -22.491308593750006],
              [-64.1318359375, -22.365820312500006],
              [-63.97612304687499, -22.072558593750003],
              [-63.92167968749999, -22.028613281250003],
              [-63.86103515625, -22.00722656250001],
              [-63.81865234374999, -22.00546875],
              [-63.7755859375, -22.02724609375001],
              [-63.716943359374994, -22.02753906250001],
              [-63.67534179687499, -22.004296875],
              [-63.2671875, -22.000585937500006],
              [-62.843359375, -21.99726562500001],
              [-62.83427734374999, -21.999121093750006],
              [-62.815087890624994, -22.049609375],
              [-62.744580078125, -22.15986328125001],
              [-62.665283203125, -22.21796875000001],
              [-62.65097656249999, -22.233691406250003],
              [-62.62568359375, -22.261523437500003],
              [-62.6259765625, -22.29042968750001],
              [-62.54155273437499, -22.349609375],
              [-62.372509765625, -22.43916015625001],
              [-62.21416015624999, -22.612402343750006],
              [-62.0666015625, -22.869433593750003],
              [-61.92802734374999, -23.05927734375001],
              [-61.79853515625, -23.18203125],
              [-61.67949218749999, -23.26875],
              [-61.57099609375, -23.319433593750006],
              [-61.51303710937499, -23.360449218750006],
              [-61.505517578124994, -23.39199218750001],
              [-61.403955078124994, -23.45751953125],
              [-61.208398437499994, -23.55703125],
              [-61.084716796875, -23.65644531250001],
              [-61.03291015625, -23.755664062500003],
              [-60.83984375, -23.85810546875001],
              [-60.50537109375, -23.96357421875001],
              [-60.26220703125, -24.013964843750003],
              [-60.110302734375, -24.00917968750001],
              [-59.892480468749994, -24.093554687500003],
              [-59.60859375, -24.26679687500001],
              [-59.43540039062499, -24.387011718750003],
              [-59.372949218749994, -24.45390625],
              [-59.187255859375, -24.562304687500003],
              [-58.724023437499994, -24.78662109375],
              [-58.51962890624999, -24.842871093750006],
              [-58.422802734375, -24.894140625],
              [-58.365380859374994, -24.959277343750003],
              [-58.30869140624999, -24.979101562500006],
              [-58.25278320312499, -24.95380859375001],
              [-58.136474609375, -24.977148437500006],
              [-57.95981445312499, -25.04921875],
              [-57.821679687499994, -25.13642578125001],
              [-57.64389648437499, -25.328417968750003],
              [-57.587158203125, -25.405078125],
              [-57.563134765624994, -25.473730468750006],
              [-57.571679687499994, -25.5341796875],
              [-57.62583007812499, -25.598730468750006],
              [-57.725488281249994, -25.66718750000001],
              [-57.754785156249994, -25.697070312500003],
              [-57.757080078125, -25.72597656250001],
              [-57.782470703125, -25.78369140625],
              [-57.865234375, -25.90693359375001],
              [-57.88623046875, -25.96425781250001],
              [-57.890625, -26.00654296875001],
              [-57.943115234375, -26.05292968750001],
              [-58.082421875, -26.13857421875001],
              [-58.11113281249999, -26.18017578125],
              [-58.118066406249994, -26.224902343750003],
              [-58.135644531249994, -26.25146484375],
              [-58.1546875, -26.26259765625001],
              [-58.18149414062499, -26.307421875],
              [-58.203027343749994, -26.381445312500006],
              [-58.20517578124999, -26.4765625],
              [-58.187939453125, -26.592578125],
              [-58.191308593749994, -26.629980468750006],
              [-58.222070312499994, -26.65],
              [-58.23935546874999, -26.676855468750006],
              [-58.245556640625, -26.731054687500006],
              [-58.2716796875, -26.77070312500001],
              [-58.31767578124999, -26.7958984375],
              [-58.33466796875, -26.82490234375001],
              [-58.32255859374999, -26.85761718750001],
              [-58.3564453125, -26.89003906250001],
              [-58.43632812499999, -26.921972656250006],
              [-58.485253906249994, -26.96845703125001],
              [-58.50322265624999, -27.02949218750001],
              [-58.54770507812499, -27.083984375],
              [-58.618603515625, -27.132128906250003],
              [-58.641748046874994, -27.19609375],
              [-58.60483398437499, -27.31435546875001],
              [-58.16826171874999, -27.2734375],
              [-57.81220703125, -27.31660156250001],
              [-57.391259765624994, -27.43046875],
              [-57.11181640625, -27.47011718750001],
              [-56.97397460937499, -27.43574218750001],
              [-56.871728515624994, -27.440625],
              [-56.80517578125, -27.484667968750003],
              [-56.715722656249994, -27.49375],
              [-56.60336914062499, -27.467871093750006],
              [-56.51054687499999, -27.487890625],
              [-56.437158203124994, -27.553808593750006],
              [-56.370507812499994, -27.537402343750003],
              [-56.310546875, -27.438769531250003],
              [-56.24169921875, -27.366796875],
              [-56.1640625, -27.32148437500001],
              [-56.067333984375, -27.307714843750006],
              [-55.95146484374999, -27.32568359375],
              [-55.859033203124994, -27.361914062500006],
              [-55.789990234375, -27.41640625],
              [-55.71464843749999, -27.41484375],
              [-55.63291015624999, -27.35712890625001],
              [-55.59379882812499, -27.2880859375],
              [-55.597265625, -27.207617187500006],
              [-55.564892578125, -27.15],
              [-55.496728515624994, -27.115332031250006],
              [-55.450634765625, -27.068359375],
              [-55.426660156249994, -27.00927734375],
              [-55.34580078124999, -26.97314453125],
              [-55.2080078125, -26.96015625000001],
              [-55.1359375, -26.93115234375],
              [-55.129638671875, -26.886035156250003],
              [-55.0888671875, -26.84453125],
              [-55.013623046875, -26.806640625],
              [-54.962158203125, -26.759375],
              [-54.934472656249994, -26.70253906250001],
              [-54.888916015625, -26.666796875],
              [-54.82548828124999, -26.65224609375001],
              [-54.755078125, -26.53291015625001],
              [-54.677734375, -26.30878906250001],
              [-54.63193359374999, -26.00576171875001],
              [-54.615869140624994, -25.57607421875001],
              [-54.537841796875, -25.576464843750003],
              [-54.50151367187499, -25.60830078125001],
              [-54.44394531249999, -25.625],
              [-54.38334960937499, -25.588671875],
              [-54.33188476562499, -25.571875],
              [-54.25009765624999, -25.570410156250006],
              [-54.20615234374999, -25.529589843750003],
              [-54.15458984374999, -25.523046875],
              [-54.11923828124999, -25.545214843750003],
              [-54.08500976562499, -25.571875],
              [-54.01230468749999, -25.577929687500003],
              [-53.95478515625, -25.64765625000001],
              [-53.89116210937499, -25.66884765625001],
              [-53.864208984375, -25.748828125],
              [-53.8232421875, -25.959570312500006],
              [-53.74692382812499, -26.08369140625001],
              [-53.671289062499994, -26.22509765625],
              [-53.66855468749999, -26.288183593750006],
              [-53.7109375, -26.351855468750003],
              [-53.718164062499994, -26.443164062500003],
              [-53.744580078125, -26.66650390625],
              [-53.753320312499994, -26.748632812500006],
              [-53.72714843749999, -26.8046875],
              [-53.71728515625, -26.8828125],
              [-53.75849609375, -26.978320312500003],
              [-53.83818359374999, -27.12109375],
              [-53.915625, -27.15957031250001],
              [-53.93535156249999, -27.1611328125],
              [-54.04013671874999, -27.24375],
              [-54.11381835937499, -27.274707031250003],
              [-54.1564453125, -27.25380859375001],
              [-54.205224609374994, -27.289648437500006],
              [-54.26015625, -27.38203125000001],
              [-54.32700195312499, -27.42353515625001],
              [-54.448144531249994, -27.44648437500001],
              [-54.484326171875, -27.457324218750003],
              [-54.554931640625, -27.4541015625],
              [-54.6154296875, -27.477148437500006],
              [-54.66586914062499, -27.52656250000001],
              [-54.7197265625, -27.544921875],
              [-54.777099609375, -27.532519531250003],
              [-54.8291015625, -27.550585937500003],
              [-54.875732421875, -27.59921875],
              [-54.902783203125, -27.651953125],
              [-54.91020507812499, -27.70859375],
              [-54.95590820312499, -27.747167968750006],
              [-55.039941406249994, -27.76777343750001],
              [-55.068994140624994, -27.79628906250001],
              [-55.063867187499994, -27.8359375],
              [-55.101513671875, -27.866796875],
              [-55.24375, -27.898828125],
              [-55.34648437499999, -27.95595703125001],
              [-55.409814453124994, -28.03779296875001],
              [-55.47666015624999, -28.08935546875],
              [-55.58237304687499, -28.12099609375001],
              [-55.725488281249994, -28.2041015625],
              [-55.745996093749994, -28.25546875],
              [-55.69150390624999, -28.302832031250006],
              [-55.67197265624999, -28.34492187500001],
              [-55.687255859375, -28.381640625],
              [-55.73198242187499, -28.38662109375001],
              [-55.806054687499994, -28.359765625],
              [-55.85888671875, -28.35419921875001],
              [-55.890527343749994, -28.37001953125001],
              [-55.905419921874994, -28.39960937500001],
              [-55.90366210937499, -28.44326171875001],
              [-55.93017578125, -28.47285156250001],
              [-55.98491210937499, -28.488574218750003],
              [-56.01962890624999, -28.52460937500001],
              [-56.03422851562499, -28.580859375],
              [-56.10288085937499, -28.65175781250001],
              [-56.225537109375, -28.73720703125001],
              [-56.32236328124999, -28.85244140625001],
              [-56.39326171875, -28.99726562500001],
              [-56.475976562499994, -29.09248046875001],
              [-56.570703125, -29.13808593750001],
              [-56.63583984374999, -29.20302734375001],
              [-56.671533203124994, -29.28730468750001],
              [-56.7724609375, -29.41787109375001],
              [-56.938623046874994, -29.594824218750006],
              [-57.08935546875, -29.71621093750001],
              [-57.22465820312499, -29.78212890625001],
              [-57.300683593749994, -29.856542968750006],
              [-57.31748046874999, -29.939453125],
              [-57.405224609375, -30.03388671875001],
              [-57.563867187499994, -30.139941406250003],
              [-57.60888671875, -30.187792968750003],
            ],
          ],
          [
            [
              [-68.65322265625, -54.853613281250006],
              [-68.64750976562499, -54.627832031249994],
              [-68.639794921875, -54.32402343750002],
              [-68.63823242187499, -54.05292968750001],
              [-68.636669921875, -53.78886718750002],
              [-68.63505859374999, -53.51542968750002],
              [-68.633447265625, -53.24189453125001],
              [-68.63168945312499, -52.94951171875002],
              [-68.62993164062499, -52.65263671875002],
              [-68.57119140625, -52.694921875],
              [-68.33876953125, -52.90009765625001],
              [-68.27822265625, -52.983984375],
              [-68.24013671875, -53.08183593750002],
              [-68.3330078125, -53.019628906250006],
              [-68.43115234375, -53.055273437500006],
              [-68.4794921875, -53.11376953125],
              [-68.52080078124999, -53.17724609375],
              [-68.5205078125, -53.221875],
              [-68.488525390625, -53.26093750000001],
              [-68.39311523437499, -53.294921875],
              [-68.1611328125, -53.30644531250002],
              [-68.14409179687499, -53.31904296875001],
              [-68.00849609375, -53.5640625],
              [-67.94028320312499, -53.61875],
              [-67.861083984375, -53.662207031250006],
              [-67.678125, -53.787109375],
              [-67.502587890625, -53.921972656250006],
              [-67.29423828124999, -54.0498046875],
              [-67.069482421875, -54.148046875],
              [-66.86513671875, -54.222558593749994],
              [-66.670068359375, -54.313574218750006],
              [-66.46201171874999, -54.441015625],
              [-66.23564453124999, -54.53349609375002],
              [-65.992578125, -54.59892578125002],
              [-65.7470703125, -54.653417968750006],
              [-65.369287109375, -54.63212890625002],
              [-65.251953125, -54.638085937499994],
              [-65.17900390624999, -54.678125],
              [-65.25234375, -54.78886718750002],
              [-65.34599609374999, -54.8779296875],
              [-65.471142578125, -54.914648437500006],
              [-65.60332031249999, -54.928125],
              [-65.72275390624999, -54.926367187500006],
              [-65.8419921875, -54.90996093750002],
              [-65.953759765625, -54.919335937499994],
              [-66.06064453124999, -54.95673828125001],
              [-66.172021484375, -54.97529296875001],
              [-66.286767578125, -54.97773437500001],
              [-66.398681640625, -55.009375],
              [-66.5111328125, -55.032128906249994],
              [-66.627685546875, -55.01328125],
              [-66.93046874999999, -54.924902343750006],
              [-67.127099609375, -54.90380859375],
              [-67.79326171874999, -54.86865234375],
              [-68.00712890624999, -54.84843750000002],
              [-68.2201171875, -54.81757812500001],
              [-68.331689453125, -54.816308593749994],
              [-68.49101562499999, -54.83623046875002],
              [-68.61865234375, -54.83378906250002],
              [-68.65322265625, -54.853613281250006],
            ],
          ],
          [
            [
              [-64.54916992187499, -54.716210937499994],
              [-64.43881835937499, -54.739355468750006],
              [-64.22050781249999, -54.72197265625002],
              [-64.10532226562499, -54.7216796875],
              [-64.054931640625, -54.729882812499994],
              [-64.032421875, -54.74238281250001],
              [-63.88193359374999, -54.72294921875002],
              [-63.8154296875, -54.72509765625],
              [-63.83256835937499, -54.76796875],
              [-63.97124023437499, -54.810644531250006],
              [-64.0283203125, -54.792578125],
              [-64.322900390625, -54.796484375],
              [-64.453271484375, -54.84033203125],
              [-64.50869140625, -54.839941406250006],
              [-64.637353515625, -54.90253906250001],
              [-64.7314453125, -54.86298828125001],
              [-64.75732421875, -54.8265625],
              [-64.689208984375, -54.77470703125002],
              [-64.62509765624999, -54.77363281250001],
              [-64.58134765624999, -54.75273437500002],
              [-64.54916992187499, -54.716210937499994],
            ],
          ],
          [
            [
              [-61.87578124999999, -39.171875],
              [-61.865966796875, -39.23486328125],
              [-61.91801757812499, -39.227441406249994],
              [-62.04160156249999, -39.166894531249994],
              [-62.08330078124999, -39.11015625000002],
              [-62.093017578125, -39.08623046875002],
              [-61.96665039062499, -39.112207031249994],
              [-61.907128906249994, -39.135644531249994],
              [-61.87578124999999, -39.171875],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 2,
        sovereignt: "Brazil",
        sov_a3: "BRA",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Brazil",
        adm0_a3: "BRA",
        geou_dif: 0,
        geounit: "Brazil",
        gu_a3: "BRA",
        su_dif: 0,
        subunit: "Brazil",
        su_a3: "BRA",
        brk_diff: 0,
        name: "Brazil",
        name_long: "Brazil",
        brk_a3: "BRA",
        brk_name: "Brazil",
        brk_group: null,
        abbrev: "Brazil",
        postal: "BR",
        formal_en: "Federative Republic of Brazil",
        formal_fr: null,
        name_ciawf: "Brazil",
        note_adm0: null,
        note_brk: null,
        name_sort: "Brazil",
        name_alt: null,
        mapcolor7: 5,
        mapcolor8: 6,
        mapcolor9: 5,
        mapcolor13: 7,
        pop_est: 211049527,
        pop_rank: 17,
        pop_year: 2019,
        gdp_md: 1839758,
        gdp_year: 2019,
        economy: "3. Emerging region: BRIC",
        income_grp: "3. Upper middle income",
        fips_10: "BR",
        iso_a2: "BR",
        iso_a2_eh: "BR",
        iso_a3: "BRA",
        iso_a3_eh: "BRA",
        iso_n3: "076",
        iso_n3_eh: "076",
        un_a3: "076",
        wb_a2: "BR",
        wb_a3: "BRA",
        woe_id: 23424768,
        woe_id_eh: 23424768,
        woe_note: "Exact WOE match as country",
        adm0_iso: "BRA",
        adm0_diff: null,
        adm0_tlc: "BRA",
        adm0_a3_us: "BRA",
        adm0_a3_fr: "BRA",
        adm0_a3_ru: "BRA",
        adm0_a3_es: "BRA",
        adm0_a3_cn: "BRA",
        adm0_a3_tw: "BRA",
        adm0_a3_in: "BRA",
        adm0_a3_np: "BRA",
        adm0_a3_pk: "BRA",
        adm0_a3_de: "BRA",
        adm0_a3_gb: "BRA",
        adm0_a3_br: "BRA",
        adm0_a3_il: "BRA",
        adm0_a3_ps: "BRA",
        adm0_a3_sa: "BRA",
        adm0_a3_eg: "BRA",
        adm0_a3_ma: "BRA",
        adm0_a3_pt: "BRA",
        adm0_a3_ar: "BRA",
        adm0_a3_jp: "BRA",
        adm0_a3_ko: "BRA",
        adm0_a3_vn: "BRA",
        adm0_a3_tr: "BRA",
        adm0_a3_id: "BRA",
        adm0_a3_pl: "BRA",
        adm0_a3_gr: "BRA",
        adm0_a3_it: "BRA",
        adm0_a3_nl: "BRA",
        adm0_a3_se: "BRA",
        adm0_a3_bd: "BRA",
        adm0_a3_ua: "BRA",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "South America",
        region_un: "Americas",
        subregion: "South America",
        region_wb: "Latin America & Caribbean",
        name_len: 6,
        long_len: 6,
        abbrev_len: 6,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 1.7,
        max_label: 5.7,
        label_x: -49.55945,
        label_y: -12.098687,
        ne_id: 1159320441,
        wikidataid: "Q155",
        name_ar: "البرازيل",
        name_bn: "ব্রাজিল",
        name_de: "Brasilien",
        name_en: "Brazil",
        name_es: "Brasil",
        name_fa: "برزیل",
        name_fr: "Brésil",
        name_el: "Βραζιλία",
        name_he: "ברזיל",
        name_hi: "ब्राज़ील",
        name_hu: "Brazília",
        name_id: "Brasil",
        name_it: "Brasile",
        name_ja: "ブラジル",
        name_ko: "브라질",
        name_nl: "Brazilië",
        name_pl: "Brazylia",
        name_pt: "Brasil",
        name_ru: "Бразилия",
        name_sv: "Brasilien",
        name_tr: "Brezilya",
        name_uk: "Бразилія",
        name_ur: "برازیل",
        name_vi: "Brasil",
        name_zh: "巴西",
        name_zht: "巴西",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "BRA.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-66.87602539062499, 1.223046875],
              [-66.61904296875, 0.992138671874997],
              [-66.42924804687499, 0.821679687499994],
              [-66.347119140625, 0.7671875],
              [-66.30166015625, 0.751953125],
              [-66.19121093749999, 0.76328125],
              [-66.06005859375, 0.785351562499997],
              [-65.996337890625, 0.809765625],
              [-65.92587890624999, 0.863134765624991],
              [-65.81132812499999, 0.937255859375],
              [-65.71811523437499, 0.97802734375],
              [-65.68144531249999, 0.983447265624989],
              [-65.644677734375, 0.970361328124994],
              [-65.566015625, 0.926074218749989],
              [-65.52299804687499, 0.843408203124994],
              [-65.5626953125, 0.747509765624997],
              [-65.5560546875, 0.68798828125],
              [-65.473388671875, 0.691259765624991],
              [-65.4072265625, 0.790478515624997],
              [-65.36083984375, 0.86865234375],
              [-65.26396484374999, 0.931884765625],
              [-65.16962890625, 1.022216796875],
              [-65.103759765625, 1.108105468749997],
              [-65.0265625, 1.158447265625],
              [-64.910107421875, 1.2197265625],
              [-64.81796875, 1.257128906249989],
              [-64.73154296874999, 1.253320312499994],
              [-64.667431640625, 1.293847656249994],
              [-64.584375, 1.369873046875],
              [-64.52626953125, 1.431005859374991],
              [-64.48603515625, 1.452783203124994],
              [-64.40512695312499, 1.446875],
              [-64.30419921875, 1.455273437499997],
              [-64.205029296875, 1.529492187499997],
              [-64.11484375, 1.619287109374994],
              [-64.067041015625, 1.7705078125],
              [-64.03544921874999, 1.904443359374994],
              [-64.00849609375, 1.931591796874997],
              [-63.97578125, 1.953027343749994],
              [-63.937158203124994, 1.966992187499997],
              [-63.844482421875, 1.976708984374994],
              [-63.68212890625, 2.048144531249989],
              [-63.570263671875, 2.120507812499994],
              [-63.46391601562499, 2.136035156249989],
              [-63.432519531249994, 2.155566406249989],
              [-63.393945312499994, 2.222509765624991],
              [-63.37485351562499, 2.340429687499991],
              [-63.38925781249999, 2.411914062499989],
              [-63.584619140624994, 2.433935546874991],
              [-63.712548828124994, 2.434033203124997],
              [-63.92416992187499, 2.452441406249989],
              [-64.02490234375, 2.481884765624997],
              [-64.04658203125, 2.502392578124997],
              [-64.048828125, 2.525097656249997],
              [-64.0287109375, 2.576074218749994],
              [-64.009033203125, 2.671875],
              [-64.03779296875, 2.801513671875],
              [-64.1435546875, 3.0048828125],
              [-64.21884765624999, 3.2046875],
              [-64.228759765625, 3.343994140625],
              [-64.22705078125, 3.4912109375],
              [-64.22109375, 3.58740234375],
              [-64.27529296875, 3.662695312499991],
              [-64.56791992187499, 3.899804687499994],
              [-64.66899414062499, 4.011816406249991],
              [-64.702587890625, 4.089306640624997],
              [-64.81787109375, 4.232275390624991],
              [-64.788671875, 4.276025390624994],
              [-64.722265625, 4.2744140625],
              [-64.66552734375, 4.237109374999989],
              [-64.613671875, 4.15771484375],
              [-64.5763671875, 4.139892578125],
              [-64.525537109375, 4.139990234374991],
              [-64.25566406249999, 4.140332031249997],
              [-64.19248046874999, 4.126855468749994],
              [-64.154296875, 4.100146484374989],
              [-64.121728515625, 4.066992187499991],
              [-64.073388671875, 3.974414062499989],
              [-64.021484375, 3.929101562499994],
              [-63.91464843749999, 3.9306640625],
              [-63.746972656249994, 3.932568359374997],
              [-63.65292968749999, 3.940820312499994],
              [-63.596630859375, 3.9150390625],
              [-63.526806640625, 3.893701171874994],
              [-63.379785156249994, 3.94287109375],
              [-63.33867187499999, 3.943896484374989],
              [-63.29472656249999, 3.922265625],
              [-63.13623046875, 3.756445312499991],
              [-63.0453125, 3.686474609374997],
              [-62.968652343749994, 3.593945312499997],
              [-62.85698242187499, 3.593457031249997],
              [-62.764599609375, 3.672949218749991],
              [-62.73994140625, 3.940332031249994],
              [-62.712109375, 4.017919921874991],
              [-62.66533203124999, 4.039648437499991],
              [-62.609765625, 4.042285156249989],
              [-62.5439453125, 4.084326171874991],
              [-62.472558593749994, 4.138525390624991],
              [-62.41064453125, 4.15673828125],
              [-62.153125, 4.098388671875],
              [-62.08159179687499, 4.126318359374991],
              [-61.82084960937499, 4.197021484375],
              [-61.55424804687499, 4.287792968749997],
              [-61.479394531249994, 4.402246093749994],
              [-61.36752929687499, 4.433007812499994],
              [-61.28007812499999, 4.516894531249989],
              [-61.209423828125, 4.508056640625],
              [-61.102441406249994, 4.504687499999989],
              [-61.036279296874994, 4.519335937499989],
              [-61.002832031249994, 4.535253906249991],
              [-60.96640625, 4.57470703125],
              [-60.90625, 4.686816406249989],
              [-60.833398437499994, 4.729199218749997],
              [-60.74174804687499, 4.774121093749997],
              [-60.679150390625, 4.827099609374997],
              [-60.627587890624994, 4.892529296874997],
              [-60.60385742187499, 4.949365234374994],
              [-60.6044921875, 4.994580078124997],
              [-60.635009765625, 5.081982421874997],
              [-60.67197265624999, 5.164355468749989],
              [-60.71196289062499, 5.191552734374994],
              [-60.742138671875, 5.202050781249994],
              [-60.6513671875, 5.221142578124997],
              [-60.576416015625, 5.192480468749991],
              [-60.45952148437499, 5.188085937499991],
              [-60.40878906249999, 5.21015625],
              [-60.335205078125, 5.199316406249991],
              [-60.241650390625, 5.257958984374994],
              [-60.18173828124999, 5.238818359374989],
              [-60.142041015625, 5.238818359374989],
              [-60.10595703125, 5.194238281249994],
              [-60.078076171875, 5.143994140624997],
              [-59.990673828125, 5.082861328124991],
              [-59.99936523437499, 4.98984375],
              [-60.01547851562499, 4.907519531249989],
              [-60.026757812499994, 4.812695312499997],
              [-60.03178710937499, 4.740527343749989],
              [-60.06894531249999, 4.666650390624994],
              [-60.12456054687499, 4.59765625],
              [-60.14091796874999, 4.569628906249989],
              [-60.1486328125, 4.533251953124989],
              [-60.11113281249999, 4.511181640624997],
              [-60.04501953124999, 4.504589843749997],
              [-59.962353515625, 4.501708984375],
              [-59.90610351562499, 4.480322265624991],
              [-59.83334960937499, 4.475927734374991],
              [-59.74580078125, 4.416650390624994],
              [-59.703271484374994, 4.381103515625],
              [-59.69970703125, 4.353515625],
              [-59.727490234375, 4.287646484374989],
              [-59.73857421874999, 4.226757812499997],
              [-59.71689453124999, 4.188183593749997],
              [-59.69121093749999, 4.160400390625],
              [-59.62021484374999, 4.023144531249997],
              [-59.58642578125, 3.975390624999989],
              [-59.557763671874994, 3.960009765624989],
              [-59.55112304687499, 3.933544921874997],
              [-59.575390625, 3.883447265624994],
              [-59.604443359375, 3.819677734374991],
              [-59.67021484374999, 3.752734374999989],
              [-59.67900390624999, 3.699804687499991],
              [-59.731640625, 3.666552734374989],
              [-59.854394531249994, 3.5875],
              [-59.83305664062499, 3.462158203125],
              [-59.82880859375, 3.398583984374994],
              [-59.83115234374999, 3.34921875],
              [-59.873046875, 3.283105468749994],
              [-59.94565429687499, 3.087841796874997],
              [-59.972314453124994, 2.990478515625],
              [-59.99589843749999, 2.765429687499989],
              [-59.9943359375, 2.689990234374989],
              [-59.96079101562499, 2.58837890625],
              [-59.8896484375, 2.362939453124994],
              [-59.84912109375, 2.327050781249994],
              [-59.75522460937499, 2.274121093749997],
              [-59.74350585937499, 2.121630859374989],
              [-59.75175781249999, 1.96240234375],
              [-59.75620117187499, 1.900634765625],
              [-59.74072265625, 1.874169921874994],
              [-59.69853515624999, 1.861474609374994],
              [-59.66850585937499, 1.842333984374989],
              [-59.66376953125, 1.795214843749989],
              [-59.66660156249999, 1.746289062499997],
              [-59.596630859375, 1.718017578125],
              [-59.53569335937499, 1.7],
              [-59.479443359375, 1.632421875],
              [-59.377685546875, 1.52734375],
              [-59.33725585937499, 1.508203125],
              [-59.31699218749999, 1.464599609375],
              [-59.231201171875, 1.376025390624989],
              [-59.10039062499999, 1.343652343749994],
              [-58.968505859375, 1.304589843749994],
              [-58.91660156249999, 1.248876953124991],
              [-58.8625, 1.20361328125],
              [-58.82177734375, 1.201220703124989],
              [-58.78720703124999, 1.20849609375],
              [-58.73032226562499, 1.247509765624997],
              [-58.68461914062499, 1.281054687499989],
              [-58.605078125, 1.279150390624991],
              [-58.511865234374994, 1.28466796875],
              [-58.495703125, 1.312255859375],
              [-58.48686523437499, 1.347753906249991],
              [-58.5060546875, 1.438671875],
              [-58.47294921874999, 1.466259765624997],
              [-58.39580078124999, 1.481738281249989],
              [-58.38037109375, 1.530224609374997],
              [-58.362695312499994, 1.556689453124989],
              [-58.34067382812499, 1.587548828124994],
              [-58.314208984375, 1.591943359374994],
              [-58.281152343749994, 1.574316406249991],
              [-58.230419921875, 1.563281249999989],
              [-58.173095703125, 1.5478515625],
              [-58.142236328124994, 1.516992187499994],
              [-58.09130859375, 1.514355468749997],
              [-58.03466796875, 1.520263671875],
              [-58.01176757812499, 1.539941406249994],
              [-57.9951171875, 1.574316406249991],
              [-57.9828125, 1.6484375],
              [-57.94633789062499, 1.650585937499997],
              [-57.8734375, 1.667285156249989],
              [-57.795654296875, 1.7],
              [-57.69174804687499, 1.704785156249997],
              [-57.59443359375, 1.7041015625],
              [-57.54575195312499, 1.72607421875],
              [-57.500439453125, 1.773828125],
              [-57.41269531249999, 1.908935546875],
              [-57.366796875, 1.940136718749997],
              [-57.31748046874999, 1.963476562499991],
              [-57.2755859375, 1.959228515625],
              [-57.189599609374994, 1.981591796874994],
              [-57.118896484375, 2.013964843749989],
              [-57.092675781249994, 2.005810546874997],
              [-57.03759765625, 1.936474609374997],
              [-57.01005859374999, 1.921240234374991],
              [-56.96953124999999, 1.91640625],
              [-56.83671874999999, 1.88125],
              [-56.766259765624994, 1.8921875],
              [-56.68984375, 1.914306640625],
              [-56.616455078125, 1.922656249999989],
              [-56.56357421874999, 1.9072265625],
              [-56.52548828124999, 1.92724609375],
              [-56.4828125, 1.942138671875],
              [-56.45283203125, 1.932324218749997],
              [-56.38583984374999, 1.923876953124989],
              [-56.22714843749999, 1.885351562499991],
              [-56.019921875, 1.842236328124997],
              [-55.963330078125, 1.857080078124994],
              [-55.929638671875, 1.8875],
              [-55.921630859375, 1.976660156249991],
              [-55.91533203124999, 2.03955078125],
              [-55.96196289062499, 2.095117187499994],
              [-56.02006835937499, 2.158154296874997],
              [-56.073632812499994, 2.236767578124997],
              [-56.1376953125, 2.259033203125],
              [-56.12939453125, 2.299511718749997],
              [-56.087792968749994, 2.34130859375],
              [-56.0451171875, 2.364404296874994],
              [-56.02036132812499, 2.392773437499997],
              [-55.99350585937499, 2.497509765624997],
              [-55.9755859375, 2.515966796874991],
              [-55.957470703125, 2.520458984374997],
              [-55.9359375, 2.5166015625],
              [-55.89375, 2.489501953125],
              [-55.73056640624999, 2.406152343749994],
              [-55.658935546875, 2.41875],
              [-55.38535156249999, 2.440625],
              [-55.343994140625, 2.48876953125],
              [-55.286035156249994, 2.499658203124994],
              [-55.1876953125, 2.547509765624994],
              [-55.148828125, 2.55078125],
              [-55.11411132812499, 2.539208984374994],
              [-55.0703125, 2.54833984375],
              [-55.005810546875, 2.59296875],
              [-54.978662109374994, 2.59765625],
              [-54.968408203124994, 2.54833984375],
              [-54.92656249999999, 2.497363281249989],
              [-54.87607421874999, 2.450390625],
              [-54.85166015624999, 2.439550781249991],
              [-54.766845703125, 2.454736328124994],
              [-54.72221679687499, 2.441650390625],
              [-54.70292968749999, 2.39794921875],
              [-54.697412109374994, 2.359814453124997],
              [-54.661865234375, 2.327539062499994],
              [-54.61625976562499, 2.326757812499991],
              [-54.591943359374994, 2.313769531249989],
              [-54.55048828125, 2.293066406249991],
              [-54.515087890625, 2.245458984374991],
              [-54.43310546875, 2.20751953125],
              [-54.29306640624999, 2.154248046874997],
              [-54.227978515625, 2.1533203125],
              [-54.167382812499994, 2.137060546874991],
              [-54.130078125, 2.121044921874997],
              [-54.089746093749994, 2.150488281249991],
              [-53.946435546874994, 2.232568359374994],
              [-53.876611328124994, 2.278271484374997],
              [-53.829541015625, 2.312939453124997],
              [-53.79423828124999, 2.345996093749989],
              [-53.7677734375, 2.354833984374991],
              [-53.750146484374994, 2.335009765624989],
              [-53.73471679687499, 2.308544921874997],
              [-53.68369140624999, 2.292919921874997],
              [-53.56396484375, 2.261914062499997],
              [-53.508984375, 2.253125],
              [-53.4318359375, 2.279443359374994],
              [-53.36601562499999, 2.32421875],
              [-53.334423828125, 2.339746093749994],
              [-53.28549804687499, 2.295214843749989],
              [-53.252197265625, 2.232275390624991],
              [-53.22978515624999, 2.204882812499989],
              [-53.180078125, 2.211328125],
              [-53.082275390625, 2.201708984374989],
              [-53.009716796875, 2.181738281249991],
              [-52.96484375, 2.183544921874997],
              [-52.903466796874994, 2.211523437499991],
              [-52.87041015624999, 2.266650390624989],
              [-52.7833984375, 2.317187499999989],
              [-52.700634765625, 2.363671875],
              [-52.65317382812499, 2.425732421874997],
              [-52.5830078125, 2.52890625],
              [-52.559472656249994, 2.573144531249994],
              [-52.5546875, 2.64765625],
              [-52.45585937499999, 2.864160156249994],
              [-52.418408203125, 2.903857421874989],
              [-52.396386718749994, 2.972216796874989],
              [-52.356640625, 3.051562499999989],
              [-52.356640625, 3.117724609374989],
              [-52.327880859375, 3.181738281249991],
              [-52.271240234375, 3.237109374999989],
              [-52.229443359375, 3.271679687499997],
              [-52.16259765625, 3.364697265624997],
              [-52.116113281249994, 3.452294921874994],
              [-51.99951171875, 3.646875],
              [-51.990625, 3.702001953124991],
              [-51.9443359375, 3.735107421875],
              [-51.92890624999998, 3.776953125],
              [-51.879492187500006, 3.828564453124997],
              [-51.827490234375006, 3.869580078124997],
              [-51.805273437500006, 3.929931640625],
              [-51.76708984375, 3.99267578125],
              [-51.683447265625006, 4.039697265624994],
              [-51.65253906249998, 4.061279296875],
              [-51.55781249999998, 4.233789062499994],
              [-51.54707031249998, 4.310888671874991],
              [-51.461523437500006, 4.313769531249989],
              [-51.32709960937498, 4.224755859374994],
              [-51.21992187499998, 4.093603515624991],
              [-51.076269531250006, 3.671679687499989],
              [-51.052392578124994, 3.281835937499991],
              [-50.994140625, 3.077539062499994],
              [-50.82719726562499, 2.65185546875],
              [-50.816503906250006, 2.573046874999989],
              [-50.789697265624994, 2.477783203125],
              [-50.736962890624994, 2.376757812499989],
              [-50.67875976562499, 2.210351562499994],
              [-50.67656249999999, 2.179443359375],
              [-50.71440429687499, 2.134033203125],
              [-50.658935546875, 2.130957031249991],
              [-50.60869140624999, 2.104101562499991],
              [-50.57587890624998, 1.998583984374989],
              [-50.534423828125, 1.92724609375],
              [-50.458886718749994, 1.82958984375],
              [-50.304296875, 1.797656249999989],
              [-50.187597656250006, 1.785986328124991],
              [-50.0546875, 1.730712890625],
              [-49.957128906250006, 1.659863281249997],
              [-49.881591796875, 1.419921875],
              [-49.90625, 1.26904296875],
              [-49.89887695312498, 1.162988281249994],
              [-49.93793945312498, 1.121435546874991],
              [-50.047216796875006, 1.051953125],
              [-50.07099609374998, 1.015087890624997],
              [-50.29443359375, 0.835742187499989],
              [-50.34326171875, 0.751025390624989],
              [-50.462988281250006, 0.637304687499991],
              [-50.58154296875, 0.420507812499991],
              [-50.75507812499998, 0.222558593749994],
              [-50.81635742187498, 0.172558593749997],
              [-50.91015625, 0.160986328124991],
              [-50.96708984374999, 0.130273437499994],
              [-51.101953125, -0.03125],
              [-51.28291015624998, -0.085205078125],
              [-51.299560546875, -0.178808593750006],
              [-51.404150390625006, -0.392675781250006],
              [-51.49628906249998, -0.509472656250011],
              [-51.555029296875006, -0.549121093750003],
              [-51.70263671875, -0.762304687500006],
              [-51.721533203125006, -0.85546875],
              [-51.720605468749994, -1.018457031250009],
              [-51.81914062499999, -1.117773437500006],
              [-51.921630859375, -1.180859375000011],
              [-51.934472656249994, -1.3203125],
              [-51.980810546875006, -1.36796875],
              [-52.020458984375, -1.399023437500006],
              [-52.229248046875, -1.3625],
              [-52.55341796875, -1.5140625],
              [-52.66416015624999, -1.5517578125],
              [-52.310302734375, -1.5595703125],
              [-52.196679687499994, -1.64013671875],
              [-51.94755859374999, -1.58671875],
              [-51.64628906249999, -1.394335937500003],
              [-51.53120117187498, -1.354101562500006],
              [-51.29736328125, -1.223535156250009],
              [-51.20234374999998, -1.136523437500003],
              [-51.028955078124994, -1.032128906250009],
              [-50.992041015625006, -0.986328125],
              [-50.894921875, -0.937597656250006],
              [-50.84228515625, -0.999609375],
              [-50.83818359374999, -1.038867187500003],
              [-50.917871093749994, -1.115234375],
              [-50.89716796874998, -1.164453125],
              [-50.844580078125006, -1.226269531250011],
              [-50.825537109375006, -1.311425781250009],
              [-50.81865234374999, -1.376269531250003],
              [-50.7861328125, -1.489941406250011],
              [-50.678955078125, -1.643847656250003],
              [-50.67529296875, -1.694726562500009],
              [-50.690039062500006, -1.76171875],
              [-50.638769531250006, -1.817089843750011],
              [-50.585595703124994, -1.849902343750003],
              [-50.403222656249994, -2.015527343750009],
              [-50.26044921874998, -1.922949218750006],
              [-50.172705078125006, -1.896191406250011],
              [-50.116601562499994, -1.857519531250006],
              [-49.99921874999998, -1.831835937500003],
              [-49.902978515624994, -1.87060546875],
              [-49.71953124999999, -1.926367187500006],
              [-49.585351562499994, -1.8671875],
              [-49.31367187499998, -1.731738281250003],
              [-49.39863281249998, -1.971582031250009],
              [-49.46015624999998, -2.191503906250006],
              [-49.506982421874994, -2.2802734375],
              [-49.553369140624994, -2.519921875],
              [-49.59931640624998, -2.583886718750009],
              [-49.63652343749999, -2.656933593750011],
              [-49.57587890624998, -2.631445312500006],
              [-49.52392578125, -2.596875],
              [-49.45751953125, -2.504589843750011],
              [-49.40766601562498, -2.344335937500006],
              [-49.211035156250006, -1.91650390625],
              [-49.15478515625, -1.878515625],
              [-48.991308593750006, -1.829785156250011],
              [-48.71000976562499, -1.487695312500009],
              [-48.6, -1.48876953125],
              [-48.52958984374999, -1.567480468750006],
              [-48.46293945312499, -1.613964843750011],
              [-48.44584960937499, -1.520410156250009],
              [-48.34980468749998, -1.482128906250011],
              [-48.45146484374999, -1.435839843750003],
              [-48.46806640624999, -1.393847656250003],
              [-48.47773437499998, -1.323828125],
              [-48.40859375, -1.229199218750011],
              [-48.449804687500006, -1.1455078125],
              [-48.306494140625006, -1.03984375],
              [-48.31757812499998, -0.960546875],
              [-48.266455078125006, -0.895117187500006],
              [-48.201757812500006, -0.827929687500003],
              [-48.12846679687499, -0.795214843750003],
              [-48.115087890625006, -0.7375],
              [-48.06884765625, -0.713671875],
              [-48.032568359375006, -0.705078125],
              [-47.9609375, -0.769628906250006],
              [-47.883398437500006, -0.693359375],
              [-47.80766601562499, -0.663476562500009],
              [-47.77373046874999, -0.6767578125],
              [-47.73149414062499, -0.71044921875],
              [-47.687109375, -0.724804687500011],
              [-47.65107421874998, -0.71875],
              [-47.55732421874998, -0.669921875],
              [-47.470703125, -0.74853515625],
              [-47.41865234374998, -0.765917968750003],
              [-47.43291015624999, -0.721875],
              [-47.460351562499994, -0.680957031250003],
              [-47.4390625, -0.647656250000011],
              [-47.398095703124994, -0.626660156250011],
              [-47.26860351562499, -0.645410156250009],
              [-47.200537109375006, -0.68046875],
              [-47.12690429687498, -0.745410156250003],
              [-47.02460937499998, -0.750195312500011],
              [-46.9443359375, -0.743359375000011],
              [-46.893652343750006, -0.779882812500006],
              [-46.81123046874998, -0.7796875],
              [-46.769921875, -0.836523437500006],
              [-46.644433593749994, -0.91640625],
              [-46.61723632812499, -0.970605468750009],
              [-46.51630859374998, -0.996875],
              [-46.421728515625006, -1.030078125],
              [-46.32084960937499, -1.039160156250006],
              [-46.219140625, -1.03125],
              [-46.214990234374994, -1.099804687500011],
              [-46.140380859375, -1.118359375000011],
              [-46.04462890624998, -1.10302734375],
              [-45.972265625, -1.187402343750009],
              [-45.77880859375, -1.25078125],
              [-45.644775390625, -1.347851562500011],
              [-45.55693359374999, -1.330664062500006],
              [-45.45859375, -1.35625],
              [-45.35302734375, -1.5673828125],
              [-45.32915039062499, -1.71728515625],
              [-45.282128906249994, -1.696582031250003],
              [-45.23857421874999, -1.629492187500006],
              [-45.18208007812498, -1.507031250000011],
              [-45.07636718749998, -1.46640625],
              [-45.02578125, -1.513476562500003],
              [-44.919775390625006, -1.5888671875],
              [-44.828369140625, -1.671679687500003],
              [-44.78984374999999, -1.724804687500011],
              [-44.72114257812498, -1.733496093750006],
              [-44.77851562499998, -1.798828125],
              [-44.720947265625, -1.792285156250003],
              [-44.651269531249994, -1.745800781250011],
              [-44.591650390625006, -1.841796875],
              [-44.546777343749994, -1.9462890625],
              [-44.53779296874998, -2.052734375],
              [-44.58002929687498, -2.113867187500006],
              [-44.617285156250006, -2.152148437500003],
              [-44.65864257812498, -2.2275390625],
              [-44.70751953125, -2.241113281250009],
              [-44.75634765625, -2.265527343750009],
              [-44.70063476562498, -2.320410156250006],
              [-44.66240234374999, -2.373242187500011],
              [-44.579003906249994, -2.23046875],
              [-44.520361328125006, -2.190332031250009],
              [-44.435449218749994, -2.168066406250006],
              [-44.39130859374998, -2.269628906250006],
              [-44.3818359375, -2.365527343750003],
              [-44.520117187500006, -2.405468750000011],
              [-44.520654296874994, -2.48125],
              [-44.56201171875, -2.52421875],
              [-44.589013671874994, -2.573437500000011],
              [-44.61079101562498, -2.676855468750006],
              [-44.63896484374999, -2.7625],
              [-44.72138671874998, -3.142285156250011],
              [-44.723046875, -3.204785156250011],
              [-44.62265625, -3.137890625000011],
              [-44.43754882812499, -2.944433593750006],
              [-44.38115234374999, -2.738378906250006],
              [-44.30815429687499, -2.53515625],
              [-44.228613281250006, -2.471289062500006],
              [-44.17939453124998, -2.47119140625],
              [-44.105566406250006, -2.493457031250003],
              [-44.10136718749999, -2.56005859375],
              [-44.112646484375006, -2.598535156250009],
              [-44.19160156249998, -2.699609375],
              [-44.225195312500006, -2.754980468750006],
              [-44.19267578124999, -2.8095703125],
              [-44.01323242187499, -2.6421875],
              [-43.93291015624999, -2.58349609375],
              [-43.86445312499998, -2.595410156250011],
              [-43.728613281250006, -2.518164062500006],
              [-43.45512695312499, -2.502050781250006],
              [-43.43461914062499, -2.413671875],
              [-43.38007812499998, -2.376074218750006],
              [-43.22968749999998, -2.386035156250003],
              [-42.93671874999998, -2.465039062500011],
              [-42.832275390625, -2.529589843750003],
              [-42.675878906250006, -2.589648437500003],
              [-42.59355468749999, -2.661035156250009],
              [-42.249609375, -2.7919921875],
              [-41.999853515625006, -2.806054687500009],
              [-41.87617187499998, -2.74658203125],
              [-41.721875, -2.808886718750003],
              [-41.64013671875, -2.878613281250011],
              [-41.47993164062498, -2.91650390625],
              [-41.318212890625006, -2.936230468750011],
              [-41.19453124999998, -2.886132812500009],
              [-40.875585937500006, -2.86962890625],
              [-40.47456054687498, -2.795605468750011],
              [-40.2353515625, -2.813183593750011],
              [-39.964697265625006, -2.861523437500011],
              [-39.771826171875006, -2.98583984375],
              [-39.60942382812499, -3.05625],
              [-39.51118164062498, -3.125585937500006],
              [-39.352685546874994, -3.197363281250006],
              [-39.01435546874998, -3.390234375],
              [-38.89599609375, -3.501757812500003],
              [-38.68623046874998, -3.653710937500009],
              [-38.47578124999998, -3.717480468750011],
              [-38.361914062500006, -3.87646484375],
              [-38.271875, -3.948046875],
              [-38.048828125, -4.21640625],
              [-37.795654296875, -4.404296875],
              [-37.626318359375006, -4.592089843750003],
              [-37.30146484374998, -4.713085937500011],
              [-37.174658203125006, -4.912402343750003],
              [-36.95488281249999, -4.936718750000011],
              [-36.86113281249999, -4.966601562500003],
              [-36.74736328124999, -5.050683593750009],
              [-36.590722656249994, -5.097558593750009],
              [-36.38671875, -5.084277343750003],
              [-36.161767578124994, -5.09375],
              [-35.979882812499994, -5.054394531250011],
              [-35.549414062500006, -5.12939453125],
              [-35.481689453125, -5.166015625],
              [-35.392578125, -5.250878906250009],
              [-35.235449218750006, -5.566699218750003],
              [-35.141748046874994, -5.917187500000011],
              [-35.095458984375, -6.185351562500003],
              [-34.988183593749994, -6.39375],
              [-34.929589843749994, -6.785058593750009],
              [-34.8798828125, -6.908203125],
              [-34.8759765625, -7.0029296875],
              [-34.833886718749994, -7.0244140625],
              [-34.80546874999999, -7.288378906250003],
              [-34.81660156249998, -7.394824218750003],
              [-34.857763671875006, -7.533300781250006],
              [-34.86083984375, -7.595019531250003],
              [-34.85478515624999, -7.63427734375],
              [-34.87299804687498, -7.692089843750011],
              [-34.87861328124998, -7.747460937500009],
              [-34.8369140625, -7.871777343750011],
              [-34.83466796874998, -7.971484375],
              [-34.890527343749994, -8.0921875],
              [-34.966650390625006, -8.407617187500009],
              [-35.15776367187499, -8.930566406250009],
              [-35.34086914062499, -9.230664062500011],
              [-35.597070312499994, -9.540625],
              [-35.76396484374999, -9.702539062500009],
              [-35.83012695312499, -9.719042968750003],
              [-35.89082031249998, -9.68701171875],
              [-35.847753906250006, -9.7724609375],
              [-35.88544921874998, -9.84765625],
              [-36.05498046874999, -10.07578125],
              [-36.223535156249994, -10.22509765625],
              [-36.398339843749994, -10.484082031250011],
              [-36.41162109375, -10.489941406250011],
              [-36.6357421875, -10.589941406250006],
              [-36.768310546875, -10.671679687500003],
              [-36.93779296874999, -10.820410156250006],
              [-37.093359375, -11.054785156250006],
              [-37.12548828125, -11.0849609375],
              [-37.18281249999998, -11.068457031250006],
              [-37.18120117187499, -11.1875],
              [-37.31513671874998, -11.3759765625],
              [-37.35600585937499, -11.40390625],
              [-37.354882812499994, -11.350488281250009],
              [-37.331640625, -11.309863281250003],
              [-37.32080078125, -11.2666015625],
              [-37.32177734375, -11.215136718750003],
              [-37.359228515625006, -11.252539062500006],
              [-37.4384765625, -11.39375],
              [-37.41181640624998, -11.497265625000011],
              [-37.469335937500006, -11.653613281250003],
              [-37.688720703125, -12.1],
              [-37.95732421874999, -12.475488281250009],
              [-38.01923828124998, -12.59130859375],
              [-38.23974609375, -12.84423828125],
              [-38.401757812499994, -12.966210937500009],
              [-38.44731445312499, -12.967089843750003],
              [-38.498925781249994, -12.956640625],
              [-38.52490234375, -12.762304687500006],
              [-38.65400390624998, -12.644628906250006],
              [-38.69096679687499, -12.623925781250009],
              [-38.743896484375, -12.74853515625],
              [-38.787988281249994, -12.78271484375],
              [-38.85175781249998, -12.790136718750006],
              [-38.78359375, -12.844433593750011],
              [-38.76372070312499, -12.9072265625],
              [-38.833154296874994, -13.032910156250011],
              [-38.835302734375006, -13.147167968750011],
              [-38.95917968749998, -13.273046875],
              [-39.030908203124994, -13.365136718750009],
              [-39.0673828125, -13.48046875],
              [-39.08935546875, -13.588183593750003],
              [-39.034912109375, -13.558789062500011],
              [-39.00908203124999, -13.581445312500009],
              [-38.988623046875006, -13.615039062500003],
              [-39.001220703125, -13.66455078125],
              [-39.041113281250006, -13.758105468750003],
              [-39.034912109375, -13.991015625],
              [-39.04814453124999, -14.0439453125],
              [-39.00849609374998, -14.101171875],
              [-38.96650390624998, -14.00341796875],
              [-38.94233398437498, -14.030664062500009],
              [-39.0595703125, -14.65478515625],
              [-39.01337890624998, -14.935644531250006],
              [-38.996191406250006, -15.253808593750009],
              [-38.943212890625006, -15.564355468750009],
              [-38.88525390625, -15.841992187500011],
              [-38.880615234375, -15.8642578125],
              [-38.960791015625006, -16.1865234375],
              [-39.063232421875, -16.50439453125],
              [-39.12504882812499, -16.76357421875001],
              [-39.163964843749994, -17.043554687500006],
              [-39.202880859375, -17.178125],
              [-39.215234375, -17.31582031250001],
              [-39.17060546874998, -17.64208984375],
              [-39.15400390624998, -17.70390625],
              [-39.27836914062499, -17.849414062500003],
              [-39.41259765625, -17.920019531250006],
              [-39.48676757812498, -17.99013671875001],
              [-39.65078125, -18.25234375],
              [-39.73979492187499, -18.63984375000001],
              [-39.741943359375, -18.845996093750003],
              [-39.699853515624994, -19.27783203125],
              [-39.7314453125, -19.45390625],
              [-39.783300781250006, -19.57177734375],
              [-39.8447265625, -19.64912109375001],
              [-40.001367187499994, -19.741992187500003],
              [-40.141699218750006, -19.96826171875],
              [-40.202734375, -20.2060546875],
              [-40.29887695312499, -20.29267578125001],
              [-40.31855468749998, -20.42578125],
              [-40.39594726562498, -20.569433593750006],
              [-40.596582031249994, -20.783789062500006],
              [-40.72705078125, -20.84619140625],
              [-40.78925781249998, -20.906054687500003],
              [-40.828759765624994, -21.031347656250006],
              [-40.95454101562498, -21.237890625],
              [-41.047265625, -21.505664062500003],
              [-41.02314453124998, -21.596875],
              [-41.021582031250006, -21.61083984375],
              [-40.98784179687499, -21.9203125],
              [-41.00029296874999, -21.9990234375],
              [-41.12250976562498, -22.084375],
              [-41.582910156249994, -22.24365234375],
              [-41.70551757812498, -22.309667968750006],
              [-41.98041992187498, -22.580664062500006],
              [-41.99755859375, -22.644628906250006],
              [-41.98613281249999, -22.73583984375],
              [-41.94091796875, -22.78828125000001],
              [-41.9875, -22.84511718750001],
              [-42.042382812499994, -22.947070312500003],
              [-42.122460937499994, -22.94082031250001],
              [-42.5810546875, -22.941015625],
              [-42.82929687499998, -22.97333984375001],
              [-42.95830078124999, -22.967089843750003],
              [-43.016210937500006, -22.94257812500001],
              [-43.081152343750006, -22.90253906250001],
              [-43.100683593750006, -22.85009765625],
              [-43.0654296875, -22.77070312500001],
              [-43.086279296875006, -22.72333984375001],
              [-43.154296875, -22.725195312500006],
              [-43.22900390625, -22.74765625],
              [-43.241943359375, -22.79511718750001],
              [-43.23662109374999, -22.82880859375001],
              [-43.208837890625006, -22.878125],
              [-43.193603515625, -22.938574218750006],
              [-43.22416992187499, -22.9912109375],
              [-43.369482421875006, -22.998046875],
              [-43.5328125, -23.04638671875],
              [-43.73652343749998, -23.06660156250001],
              [-43.898828125, -23.10146484375001],
              [-43.97382812499998, -23.05732421875001],
              [-43.898828125, -23.035253906250006],
              [-43.79140625, -23.045996093750006],
              [-43.67597656249998, -23.00947265625001],
              [-43.70292968749999, -22.96630859375],
              [-43.86616210937498, -22.910546875],
              [-44.047460937500006, -22.94472656250001],
              [-44.14799804687499, -23.011035156250003],
              [-44.367919921875, -23.004980468750006],
              [-44.63725585937499, -23.05546875],
              [-44.68115234375, -23.10693359375],
              [-44.673828125, -23.206640625],
              [-44.62109375, -23.228515625],
              [-44.569677734375006, -23.274023437500006],
              [-44.61909179687498, -23.31640625],
              [-44.66718749999998, -23.33515625000001],
              [-44.95166015625, -23.381445312500006],
              [-45.215429687500006, -23.57558593750001],
              [-45.32539062499998, -23.599707031250006],
              [-45.42329101562498, -23.685351562500003],
              [-45.43339843749999, -23.75849609375001],
              [-45.46430664062498, -23.802539062500003],
              [-45.527099609375, -23.804785156250006],
              [-45.664648437500006, -23.76484375000001],
              [-45.843164062499994, -23.763671875],
              [-45.972070312499994, -23.795507812500006],
              [-46.630761718749994, -24.1103515625],
              [-46.867285156250006, -24.236328125],
              [-47.13720703125, -24.4931640625],
              [-47.5921875, -24.781054687500003],
              [-47.831152343750006, -24.952929687500003],
              [-47.8765625, -24.99746093750001],
              [-47.914306640625, -24.99990234375001],
              [-47.989160156249994, -25.035742187500006],
              [-47.959375, -25.0654296875],
              [-47.908349609374994, -25.068164062500003],
              [-47.92939453124998, -25.168261718750003],
              [-48.02436523437498, -25.23671875],
              [-48.202734375, -25.41650390625],
              [-48.242431640625, -25.4033203125],
              [-48.1859375, -25.309863281250003],
              [-48.27348632812499, -25.30634765625001],
              [-48.402490234374994, -25.272070312500006],
              [-48.45849609375, -25.31074218750001],
              [-48.427636718749994, -25.4033203125],
              [-48.47612304687499, -25.44296875],
              [-48.56416015624998, -25.44746093750001],
              [-48.64399414062498, -25.4365234375],
              [-48.73173828124999, -25.36875],
              [-48.69218749999999, -25.491503906250003],
              [-48.50703124999998, -25.521289062500003],
              [-48.42988281249998, -25.55019531250001],
              [-48.40117187499999, -25.59736328125001],
              [-48.545166015625, -25.81591796875],
              [-48.665771484375, -25.844335937500006],
              [-48.67900390624999, -25.87519531250001],
              [-48.61284179687499, -25.875],
              [-48.576318359374994, -25.93544921875001],
              [-48.61943359374999, -26.17939453125001],
              [-48.67900390624999, -26.22578125000001],
              [-48.713769531249994, -26.226953125],
              [-48.748291015625, -26.268652343750006],
              [-48.70068359375, -26.34833984375001],
              [-48.651611328125, -26.40644531250001],
              [-48.65815429687498, -26.519140625],
              [-48.676513671875, -26.612402343750006],
              [-48.677734375, -26.702929687500003],
              [-48.61567382812498, -26.878125],
              [-48.593408203124994, -27.05800781250001],
              [-48.568359375, -27.1234375],
              [-48.55415039062498, -27.19599609375001],
              [-48.59550781249999, -27.26386718750001],
              [-48.57197265624998, -27.37275390625001],
              [-48.642578125, -27.557910156250003],
              [-48.60566406249998, -27.8251953125],
              [-48.62080078124998, -28.07558593750001],
              [-48.6484375, -28.20722656250001],
              [-48.693212890625006, -28.31015625],
              [-48.797265625, -28.442675781250003],
              [-48.799658203125006, -28.575292968750006],
              [-49.023583984374994, -28.69863281250001],
              [-49.27128906249999, -28.871191406250006],
              [-49.499902343749994, -29.07539062500001],
              [-49.745996093749994, -29.36318359375001],
              [-50.033349609374994, -29.80097656250001],
              [-50.29951171874998, -30.42578125],
              [-50.619970703125006, -30.89765625000001],
              [-50.748144531250006, -31.06806640625001],
              [-50.92138671875, -31.258398437500006],
              [-51.151757812499994, -31.48037109375001],
              [-51.46040039062498, -31.702441406250003],
              [-51.79814453124999, -31.90029296875001],
              [-51.92021484374999, -31.989550781250003],
              [-52.039208984374994, -32.11484375],
              [-52.06894531249999, -32.063085937500006],
              [-52.0431640625, -31.9775390625],
              [-52.0595703125, -31.91347656250001],
              [-52.063232421875, -31.830371093750003],
              [-51.9951171875, -31.815039062500006],
              [-51.893164062500006, -31.867773437500006],
              [-51.841210937499994, -31.83203125],
              [-51.80341796874998, -31.796679687500003],
              [-51.6806640625, -31.77460937500001],
              [-51.446191406249994, -31.55732421875001],
              [-51.27216796874998, -31.476953125],
              [-51.17431640625, -31.33974609375001],
              [-51.15751953124999, -31.26679687500001],
              [-51.16142578124999, -31.11884765625001],
              [-51.10595703125, -31.081347656250003],
              [-50.980078125, -31.09423828125],
              [-50.95439453124999, -31.05214843750001],
              [-50.96533203125, -31.00546875],
              [-50.940820312499994, -30.90371093750001],
              [-50.770166015624994, -30.81337890625001],
              [-50.689306640625006, -30.704199218750006],
              [-50.71630859375, -30.42597656250001],
              [-50.68505859375, -30.41347656250001],
              [-50.61484375, -30.456835937500003],
              [-50.581933593749994, -30.43886718750001],
              [-50.546533203124994, -30.31689453125],
              [-50.56352539062499, -30.25361328125001],
              [-50.64619140624998, -30.23681640625],
              [-50.931884765625, -30.374316406250003],
              [-51.02495117187499, -30.36865234375],
              [-51.040380859375006, -30.26064453125001],
              [-51.179296875, -30.211035156250006],
              [-51.23359374999998, -30.121386718750003],
              [-51.249853515625006, -30.05996093750001],
              [-51.29804687499998, -30.03486328125001],
              [-51.295019531250006, -30.141015625],
              [-51.28178710937499, -30.244140625],
              [-51.15727539062499, -30.3642578125],
              [-51.18754882812499, -30.411914062500003],
              [-51.24658203125, -30.467578125],
              [-51.287695312500006, -30.59121093750001],
              [-51.283056640625006, -30.7515625],
              [-51.31640625, -30.702734375],
              [-51.35908203124998, -30.67451171875001],
              [-51.37646484375, -30.846875],
              [-51.459130859374994, -30.91279296875001],
              [-51.485253906249994, -30.9775390625],
              [-51.46367187499999, -31.05263671875001],
              [-51.50629882812498, -31.1044921875],
              [-51.716894531250006, -31.24375],
              [-51.92680664062499, -31.3388671875],
              [-51.97246093749999, -31.3837890625],
              [-51.994873046875, -31.48994140625001],
              [-52.026953125, -31.59902343750001],
              [-52.11982421875, -31.694921875],
              [-52.1935546875, -31.885546875],
              [-52.191552734374994, -31.967578125],
              [-52.16708984374999, -32.088476562500006],
              [-52.127392578125, -32.1677734375],
              [-52.190185546875, -32.220800781250006],
              [-52.274609375, -32.32373046875],
              [-52.34165039062499, -32.43974609375],
              [-52.50849609375, -32.87529296875],
              [-52.652246093749994, -33.137792968750006],
              [-52.762890625, -33.26640625],
              [-52.920849609375, -33.401953125],
              [-53.37060546875, -33.7421875],
              [-53.39755859374999, -33.7373046875],
              [-53.46357421875, -33.70986328125001],
              [-53.51884765624999, -33.67724609375],
              [-53.53134765624999, -33.65546875000001],
              [-53.53764648437499, -33.6228515625],
              [-53.53037109374999, -33.50029296875],
              [-53.53134765624999, -33.1708984375],
              [-53.511865234374994, -33.10869140625],
              [-53.482861328125, -33.06855468750001],
              [-53.39521484375, -33.010351562500006],
              [-53.31010742187499, -32.92705078125],
              [-53.2140625, -32.82109375],
              [-53.12558593749999, -32.73671875],
              [-53.15727539062499, -32.680078125],
              [-53.23125, -32.625390625],
              [-53.362744140625, -32.581152343750006],
              [-53.489404296874994, -32.50322265625],
              [-53.601708984374994, -32.40302734375001],
              [-53.65361328124999, -32.29873046875001],
              [-53.701123046875, -32.186328125],
              [-53.74658203125, -32.0974609375],
              [-53.76171875, -32.05683593750001],
              [-53.806103515625, -32.03994140625001],
              [-53.87651367187499, -31.99453125],
              [-53.92060546875, -31.95234375000001],
              [-53.98515624999999, -31.928125],
              [-54.10043945312499, -31.90156250000001],
              [-54.22055664062499, -31.85517578125001],
              [-54.36992187499999, -31.74501953125001],
              [-54.477685546874994, -31.62275390625001],
              [-54.530908203124994, -31.5419921875],
              [-54.587646484375, -31.48515625],
              [-54.89599609375, -31.391210937500006],
              [-55.036035156249994, -31.27900390625001],
              [-55.09116210937499, -31.31396484375],
              [-55.17353515625, -31.279589843750003],
              [-55.254638671875, -31.2255859375],
              [-55.278955078124994, -31.184179687500006],
              [-55.31328124999999, -31.141699218750006],
              [-55.34550781249999, -31.09296875000001],
              [-55.36606445312499, -31.046191406250003],
              [-55.44956054687499, -30.964453125],
              [-55.55732421875, -30.8759765625],
              [-55.60302734375, -30.85078125000001],
              [-55.6271484375, -30.85810546875001],
              [-55.65048828124999, -30.89208984375],
              [-55.665234375, -30.924902343750006],
              [-55.705957031249994, -30.946582031250003],
              [-55.75634765625, -30.987109375],
              [-55.807763671874994, -31.03671875],
              [-55.873681640624994, -31.069628906250003],
              [-55.95200195312499, -31.080859375],
              [-56.00468749999999, -31.079199218750006],
              [-56.015527343749994, -31.059667968750006],
              [-56.018457031249994, -30.99189453125001],
              [-55.998974609375, -30.837207031250003],
              [-56.044824218749994, -30.777636718750003],
              [-56.105859375, -30.71376953125001],
              [-56.176171875, -30.62841796875],
              [-56.4072265625, -30.44746093750001],
              [-56.7216796875, -30.18691406250001],
              [-56.83271484375, -30.107226562500003],
              [-56.937255859375, -30.10107421875],
              [-57.03271484375, -30.109960937500006],
              [-57.120507812499994, -30.14443359375001],
              [-57.186914062499994, -30.26484375000001],
              [-57.214453125, -30.28339843750001],
              [-57.38383789062499, -30.28066406250001],
              [-57.55229492187499, -30.26123046875],
              [-57.60888671875, -30.187792968750003],
              [-57.563867187499994, -30.139941406250003],
              [-57.405224609375, -30.03388671875001],
              [-57.31748046874999, -29.939453125],
              [-57.300683593749994, -29.856542968750006],
              [-57.22465820312499, -29.78212890625001],
              [-57.08935546875, -29.71621093750001],
              [-56.938623046874994, -29.594824218750006],
              [-56.7724609375, -29.41787109375001],
              [-56.671533203124994, -29.28730468750001],
              [-56.63583984374999, -29.20302734375001],
              [-56.570703125, -29.13808593750001],
              [-56.475976562499994, -29.09248046875001],
              [-56.39326171875, -28.99726562500001],
              [-56.32236328124999, -28.85244140625001],
              [-56.225537109375, -28.73720703125001],
              [-56.10288085937499, -28.65175781250001],
              [-56.03422851562499, -28.580859375],
              [-56.01962890624999, -28.52460937500001],
              [-55.98491210937499, -28.488574218750003],
              [-55.93017578125, -28.47285156250001],
              [-55.90366210937499, -28.44326171875001],
              [-55.905419921874994, -28.39960937500001],
              [-55.890527343749994, -28.37001953125001],
              [-55.85888671875, -28.35419921875001],
              [-55.806054687499994, -28.359765625],
              [-55.73198242187499, -28.38662109375001],
              [-55.687255859375, -28.381640625],
              [-55.67197265624999, -28.34492187500001],
              [-55.69150390624999, -28.302832031250006],
              [-55.745996093749994, -28.25546875],
              [-55.725488281249994, -28.2041015625],
              [-55.58237304687499, -28.12099609375001],
              [-55.47666015624999, -28.08935546875],
              [-55.409814453124994, -28.03779296875001],
              [-55.34648437499999, -27.95595703125001],
              [-55.24375, -27.898828125],
              [-55.101513671875, -27.866796875],
              [-55.063867187499994, -27.8359375],
              [-55.068994140624994, -27.79628906250001],
              [-55.039941406249994, -27.76777343750001],
              [-54.95590820312499, -27.747167968750006],
              [-54.91020507812499, -27.70859375],
              [-54.902783203125, -27.651953125],
              [-54.875732421875, -27.59921875],
              [-54.8291015625, -27.550585937500003],
              [-54.777099609375, -27.532519531250003],
              [-54.7197265625, -27.544921875],
              [-54.66586914062499, -27.52656250000001],
              [-54.6154296875, -27.477148437500006],
              [-54.554931640625, -27.4541015625],
              [-54.484326171875, -27.457324218750003],
              [-54.448144531249994, -27.44648437500001],
              [-54.32700195312499, -27.42353515625001],
              [-54.26015625, -27.38203125000001],
              [-54.205224609374994, -27.289648437500006],
              [-54.1564453125, -27.25380859375001],
              [-54.11381835937499, -27.274707031250003],
              [-54.04013671874999, -27.24375],
              [-53.93535156249999, -27.1611328125],
              [-53.915625, -27.15957031250001],
              [-53.83818359374999, -27.12109375],
              [-53.75849609375, -26.978320312500003],
              [-53.71728515625, -26.8828125],
              [-53.72714843749999, -26.8046875],
              [-53.753320312499994, -26.748632812500006],
              [-53.744580078125, -26.66650390625],
              [-53.718164062499994, -26.443164062500003],
              [-53.7109375, -26.351855468750003],
              [-53.66855468749999, -26.288183593750006],
              [-53.671289062499994, -26.22509765625],
              [-53.74692382812499, -26.08369140625001],
              [-53.8232421875, -25.959570312500006],
              [-53.864208984375, -25.748828125],
              [-53.89116210937499, -25.66884765625001],
              [-53.95478515625, -25.64765625000001],
              [-54.01230468749999, -25.577929687500003],
              [-54.08500976562499, -25.571875],
              [-54.11923828124999, -25.545214843750003],
              [-54.15458984374999, -25.523046875],
              [-54.20615234374999, -25.529589843750003],
              [-54.25009765624999, -25.570410156250006],
              [-54.33188476562499, -25.571875],
              [-54.38334960937499, -25.588671875],
              [-54.44394531249999, -25.625],
              [-54.50151367187499, -25.60830078125001],
              [-54.537841796875, -25.576464843750003],
              [-54.615869140624994, -25.57607421875001],
              [-54.610546875, -25.432714843750006],
              [-54.47314453125, -25.22021484375],
              [-54.43623046875, -25.12128906250001],
              [-54.4541015625, -25.065234375],
              [-54.412988281249994, -24.867480468750003],
              [-54.312939453125, -24.528125],
              [-54.281005859375, -24.30605468750001],
              [-54.317285156249994, -24.201269531250006],
              [-54.318261718749994, -24.128125],
              [-54.26689453124999, -24.06582031250001],
              [-54.241796875, -24.047265625],
              [-54.37080078125, -23.97119140625],
              [-54.44023437499999, -23.90175781250001],
              [-54.52958984374999, -23.852148437500006],
              [-54.62548828125, -23.8125],
              [-54.671777343749994, -23.82900390625001],
              [-54.72138671875, -23.852148437500006],
              [-54.817285156249994, -23.888476562500003],
              [-54.92646484375, -23.95136718750001],
              [-54.982666015625, -23.97451171875001],
              [-55.08188476562499, -23.99765625],
              [-55.1943359375, -24.01748046875001],
              [-55.28691406249999, -24.004296875],
              [-55.36630859374999, -23.991015625],
              [-55.415917968749994, -23.95136718750001],
              [-55.4423828125, -23.865332031250006],
              [-55.4423828125, -23.792578125],
              [-55.458886718749994, -23.68671875000001],
              [-55.518457031249994, -23.627246093750003],
              [-55.53828125, -23.58095703125001],
              [-55.54160156249999, -23.524707031250003],
              [-55.53496093749999, -23.4619140625],
              [-55.518457031249994, -23.415625],
              [-55.52836914062499, -23.359375],
              [-55.554833984374994, -23.319628906250003],
              [-55.54819335937499, -23.25019531250001],
              [-55.561425781249994, -23.154296875],
              [-55.60112304687499, -23.0947265625],
              [-55.620996093749994, -23.02529296875001],
              [-55.620996093749994, -22.955859375],
              [-55.65073242187499, -22.88642578125001],
              [-55.654052734375, -22.810351562500003],
              [-55.627587890624994, -22.74091796875001],
              [-55.61767578125, -22.671484375],
              [-55.647412109375, -22.621875],
              [-55.70366210937499, -22.592089843750003],
              [-55.74663085937499, -22.5126953125],
              [-55.75327148437499, -22.41015625],
              [-55.799560546875, -22.35390625],
              [-55.84916992187499, -22.3076171875],
              [-55.90537109374999, -22.3076171875],
              [-55.99140625, -22.28115234375001],
              [-56.06748046874999, -22.284472656250003],
              [-56.18984375, -22.28115234375001],
              [-56.246044921875, -22.2646484375],
              [-56.27578125, -22.22822265625001],
              [-56.35185546874999, -22.17861328125001],
              [-56.39487304687499, -22.09267578125001],
              [-56.44780273437499, -22.076171875],
              [-56.523828125, -22.1025390625],
              [-56.55029296875, -22.13564453125001],
              [-56.580078125, -22.181933593750003],
              [-56.6330078125, -22.23486328125],
              [-56.70244140624999, -22.231542968750006],
              [-56.77519531249999, -22.261328125],
              [-56.844677734375, -22.2646484375],
              [-56.937255859375, -22.271289062500003],
              [-57.02988281249999, -22.24482421875001],
              [-57.142333984375, -22.21503906250001],
              [-57.238232421875, -22.19521484375001],
              [-57.33085937499999, -22.21503906250001],
              [-57.39365234374999, -22.19843750000001],
              [-57.47636718749999, -22.188574218750006],
              [-57.56894531249999, -22.181933593750003],
              [-57.64169921874999, -22.129003906250006],
              [-57.72109375, -22.09921875],
              [-57.7640625, -22.109179687500003],
              [-57.8203125, -22.14228515625001],
              [-57.879833984375, -22.13564453125001],
              [-57.95590820312499, -22.109179687500003],
              [-57.98569335937499, -22.04638671875],
              [-57.97905273437499, -22.006640625],
              [-57.9625, -21.96699218750001],
              [-57.932763671874994, -21.910742187500006],
              [-57.94931640624999, -21.851171875],
              [-57.94267578124999, -21.79833984375],
              [-57.929443359375, -21.751953125],
              [-57.9162109375, -21.69912109375001],
              [-57.926171875, -21.649511718750006],
              [-57.929443359375, -21.59658203125001],
              [-57.93608398437499, -21.546972656250006],
              [-57.94599609375, -21.49404296875001],
              [-57.90629882812499, -21.41796875],
              [-57.8732421875, -21.355078125],
              [-57.89306640625, -21.30224609375],
              [-57.886474609375, -21.26582031250001],
              [-57.860009765624994, -21.20625],
              [-57.82695312499999, -21.13359375],
              [-57.830224609374994, -20.99794921875001],
              [-57.860009765624994, -20.918554687500006],
              [-57.892236328124994, -20.897070312500006],
              [-57.90048828124999, -20.873046875],
              [-57.88481445312499, -20.84169921875001],
              [-57.90190429687499, -20.809375],
              [-57.90849609374999, -20.7763671875],
              [-57.89140624999999, -20.74746093750001],
              [-57.91513671874999, -20.69033203125001],
              [-57.9625, -20.673828125],
              [-57.97905273437499, -20.657324218750006],
              [-57.99560546875, -20.59443359375001],
              [-58.00883789062499, -20.52167968750001],
              [-58.00224609374999, -20.465429687500006],
              [-58.025390625, -20.415820312500003],
              [-58.05844726562499, -20.38613281250001],
              [-58.09150390625, -20.33320312500001],
              [-58.124609375, -20.29345703125],
              [-58.13779296874999, -20.2373046875],
              [-58.159765625, -20.164648437500006],
              [-58.09375, -20.15107421875001],
              [-58.067626953125, -20.1103515625],
              [-58.021142578124994, -20.05517578125],
              [-57.96015625, -20.04072265625001],
              [-57.887597656249994, -20.02041015625001],
              [-57.860742187499994, -19.979589843750006],
              [-58.029931640624994, -19.83271484375001],
              [-58.131494140624994, -19.74453125],
              [-58.072021484375, -19.625292968750003],
              [-57.9716796875, -19.42421875],
              [-57.87451171875, -19.2294921875],
              [-57.800390625, -19.08095703125001],
              [-57.7814453125, -19.053515625],
              [-57.716796875, -19.044042968750006],
              [-57.72861328124999, -18.967382812500006],
              [-57.730859375, -18.91718750000001],
              [-57.783105468749994, -18.91425781250001],
              [-57.725, -18.733203125],
              [-57.63916015625, -18.475],
              [-57.57402343749999, -18.279296875],
              [-57.553125, -18.246484375],
              [-57.50615234374999, -18.2373046875],
              [-57.49565429687499, -18.214648437500003],
              [-57.55205078124999, -18.18310546875],
              [-57.58647460937499, -18.12226562500001],
              [-57.66166992187499, -17.947363281250006],
              [-57.780175781249994, -17.67177734375001],
              [-57.78886718749999, -17.573046875],
              [-57.832470703125, -17.512109375],
              [-57.905029296875, -17.532324218750006],
              [-57.99091796875, -17.51289062500001],
              [-58.20556640625, -17.363085937500003],
              [-58.34775390624999, -17.28212890625001],
              [-58.39599609375, -17.23427734375001],
              [-58.417382812499994, -17.08056640625],
              [-58.45981445312499, -16.910742187500006],
              [-58.478125, -16.70068359375],
              [-58.470605468749994, -16.650195312500003],
              [-58.35039062499999, -16.490820312500006],
              [-58.35078125, -16.410253906250006],
              [-58.340576171875, -16.339941406250006],
              [-58.345605468749994, -16.284375],
              [-58.375390625, -16.28359375],
              [-58.42368164062499, -16.307910156250003],
              [-58.49658203125, -16.32666015625],
              [-58.53793945312499, -16.328222656250006],
              [-58.957275390625, -16.31318359375001],
              [-59.43427734375, -16.295996093750006],
              [-59.83115234374999, -16.28173828125],
              [-60.17558593749999, -16.269335937500003],
              [-60.18720703125, -16.132128906250003],
              [-60.206640625, -15.901953125],
              [-60.22041015625, -15.738671875],
              [-60.242333984374994, -15.479589843750006],
              [-60.38046875, -15.318261718750009],
              [-60.53046875, -15.143164062500006],
              [-60.583203125, -15.098339843750011],
              [-60.402001953124994, -15.0927734375],
              [-60.273339843749994, -15.088769531250009],
              [-60.29887695312499, -14.618554687500009],
              [-60.338037109374994, -14.570507812500011],
              [-60.372705078124994, -14.41875],
              [-60.396240234375, -14.3328125],
              [-60.46015625, -14.263085937500009],
              [-60.47465820312499, -14.184765625000011],
              [-60.46298828124999, -14.132421875],
              [-60.42807617187499, -14.1],
              [-60.40498046875, -14.019238281250011],
              [-60.42236328125, -13.93798828125],
              [-60.46015625, -13.862402343750006],
              [-60.506591796875, -13.78984375],
              [-60.5953125, -13.745312500000011],
              [-60.72236328125, -13.664355468750003],
              [-60.914501953125, -13.561425781250009],
              [-61.07700195312499, -13.48974609375],
              [-61.129150390625, -13.49853515625],
              [-61.41606445312499, -13.526562500000011],
              [-61.51157226562499, -13.541210937500011],
              [-61.57568359375, -13.524804687500009],
              [-61.789941406249994, -13.525585937500011],
              [-61.87412109374999, -13.470410156250011],
              [-61.944726562499994, -13.40625],
              [-62.09477539062499, -13.241992187500003],
              [-62.11801757812499, -13.159765625],
              [-62.17607421874999, -13.133691406250009],
              [-62.263916015625, -13.143652343750006],
              [-62.35283203124999, -13.132421875],
              [-62.525537109374994, -13.064257812500003],
              [-62.68706054687499, -12.994335937500011],
              [-62.76547851562499, -12.997265625000011],
              [-62.83515625, -12.953710937500006],
              [-62.957910156249994, -12.847070312500009],
              [-63.01518554687499, -12.805566406250009],
              [-63.04135742187499, -12.750390625],
              [-63.06748046874999, -12.669140625000011],
              [-63.116796875, -12.651660156250003],
              [-63.1806640625, -12.666210937500011],
              [-63.249755859375, -12.707910156250009],
              [-63.3466796875, -12.680078125],
              [-63.465234375, -12.605175781250011],
              [-63.541894531249994, -12.546679687500003],
              [-63.58564453125, -12.518945312500009],
              [-63.68857421874999, -12.47802734375],
              [-63.7880859375, -12.469433593750011],
              [-63.93857421874999, -12.5296875],
              [-64.06162109374999, -12.505078125000011],
              [-64.255029296875, -12.483300781250009],
              [-64.42050781249999, -12.439746093750003],
              [-64.48076171874999, -12.326171875],
              [-64.513427734375, -12.2509765625],
              [-64.61166992187499, -12.20390625],
              [-64.69003906249999, -12.146484375],
              [-64.783447265625, -12.059375],
              [-64.82988281249999, -12.0302734375],
              [-64.91435546874999, -12.005957031250006],
              [-64.99252929687499, -11.975195312500006],
              [-65.001220703125, -11.920019531250006],
              [-65.0302734375, -11.847363281250011],
              [-65.037109375, -11.829394531250003],
              [-65.090283203125, -11.7412109375],
              [-65.11513671875, -11.735058593750011],
              [-65.14267578124999, -11.75234375],
              [-65.16337890624999, -11.76513671875],
              [-65.1857421875, -11.74951171875],
              [-65.18974609374999, -11.710058593750006],
              [-65.175390625, -11.646875],
              [-65.206201171875, -11.58056640625],
              [-65.28227539062499, -11.511035156250003],
              [-65.322021484375, -11.439160156250011],
              [-65.32548828124999, -11.36474609375],
              [-65.34238281249999, -11.315039062500006],
              [-65.37285156249999, -11.289941406250009],
              [-65.389892578125, -11.246289062500011],
              [-65.39360351562499, -11.184277343750011],
              [-65.37158203125, -11.1103515625],
              [-65.32377929687499, -11.024804687500009],
              [-65.33403320312499, -10.892773437500011],
              [-65.402294921875, -10.714746093750009],
              [-65.43999023437499, -10.586230468750003],
              [-65.44711914062499, -10.507421875],
              [-65.4369140625, -10.449023437500003],
              [-65.395458984375, -10.392285156250011],
              [-65.31308593749999, -10.253027343750006],
              [-65.298583984375, -10.146777343750003],
              [-65.32456054687499, -10.026953125],
              [-65.328125, -9.935546875],
              [-65.309326171875, -9.87265625],
              [-65.337890625, -9.790234375000011],
              [-65.396142578125, -9.71240234375],
              [-65.436767578125, -9.71044921875],
              [-65.49199218749999, -9.731738281250003],
              [-65.55869140624999, -9.797460937500006],
              [-65.637109375, -9.80908203125],
              [-65.706787109375, -9.768457031250009],
              [-65.92470703125, -9.785449218750003],
              [-66.26357421875, -9.826074218750009],
              [-66.39921874999999, -9.8681640625],
              [-66.47890625, -9.886132812500009],
              [-66.575341796875, -9.89990234375],
              [-66.72998046875, -9.975488281250009],
              [-67.1115234375, -10.268945312500009],
              [-67.19047851562499, -10.311425781250009],
              [-67.28046875, -10.317285156250009],
              [-67.33271484375, -10.35791015625],
              [-67.416943359375, -10.389843750000011],
              [-67.582421875, -10.505957031250006],
              [-67.666650390625, -10.598925781250003],
              [-67.72177734374999, -10.68310546875],
              [-67.78569335937499, -10.68603515625],
              [-67.83500976562499, -10.662792968750011],
              [-67.99169921875, -10.674414062500006],
              [-68.0716796875, -10.703125],
              [-68.158642578125, -10.785058593750009],
              [-68.2666015625, -10.93310546875],
              [-68.31113281249999, -10.975195312500006],
              [-68.39799804687499, -11.01875],
              [-68.49833984374999, -11.054785156250006],
              [-68.62265625, -11.109179687500003],
              [-68.678369140625, -11.11279296875],
              [-68.727490234375, -11.122460937500009],
              [-68.769921875, -11.09765625],
              [-68.78408203125, -11.044628906250011],
              [-68.84833984375, -11.011132812500009],
              [-69.00166015625, -10.994335937500011],
              [-69.228515625, -10.955664062500006],
              [-69.462548828125, -10.948144531250009],
              [-69.57861328125, -10.951757812500006],
              [-69.6740234375, -10.9541015625],
              [-69.839794921875, -10.933398437500003],
              [-69.9603515625, -10.929882812500011],
              [-70.06630859375, -10.982421875],
              [-70.22006835937499, -11.04765625],
              [-70.29038085937499, -11.064257812500003],
              [-70.3419921875, -11.066699218750003],
              [-70.39228515625, -11.05859375],
              [-70.45087890625, -11.024804687500009],
              [-70.53325195312499, -10.946875],
              [-70.59653320312499, -10.976855468750003],
              [-70.642333984375, -11.01025390625],
              [-70.641552734375, -10.8408203125],
              [-70.64033203125, -10.586035156250006],
              [-70.63935546875, -10.361328125],
              [-70.63852539062499, -10.181542968750009],
              [-70.63759765625, -9.971777343750006],
              [-70.6369140625, -9.82373046875],
              [-70.59379882812499, -9.767480468750009],
              [-70.56723632812499, -9.70458984375],
              [-70.59916992187499, -9.620507812500009],
              [-70.592236328125, -9.54345703125],
              [-70.57016601562499, -9.48984375],
              [-70.54111328124999, -9.4375],
              [-70.60791015625, -9.463671875],
              [-70.6369140625, -9.478222656250011],
              [-70.67246093749999, -9.51796875],
              [-70.75849609375, -9.571679687500009],
              [-70.81625976562499, -9.625292968750003],
              [-70.884521484375, -9.669042968750006],
              [-70.97075195312499, -9.765722656250006],
              [-71.041748046875, -9.81875],
              [-71.11528320312499, -9.852441406250009],
              [-71.237939453125, -9.966015625000011],
              [-71.33940429687499, -9.988574218750003],
              [-71.60800781249999, -10.006054687500011],
              [-71.887451171875, -10.005566406250011],
              [-72.14296875, -10.005175781250003],
              [-72.181591796875, -10.003710937500003],
              [-72.1791015625, -9.91015625],
              [-72.1728515625, -9.844042968750003],
              [-72.2599609375, -9.774316406250009],
              [-72.2658203125, -9.6884765625],
              [-72.289013671875, -9.629199218750003],
              [-72.31806640625, -9.556640625],
              [-72.379052734375, -9.51015625],
              [-72.46474609375, -9.4921875],
              [-72.60546875, -9.452050781250009],
              [-72.81425781249999, -9.410351562500011],
              [-73.01376953124999, -9.407421875000011],
              [-73.209423828125, -9.411425781250003],
              [-73.08984375, -9.265722656250006],
              [-72.970361328125, -9.1201171875],
              [-72.9740234375, -8.9931640625],
              [-73.0705078125, -8.8828125],
              [-73.12255859375, -8.8140625],
              [-73.203125, -8.719335937500006],
              [-73.30244140625, -8.654003906250011],
              [-73.35673828124999, -8.566992187500006],
              [-73.351708984375, -8.51416015625],
              [-73.36040039062499, -8.479296875],
              [-73.39814453125, -8.458984375],
              [-73.43588867187499, -8.427050781250003],
              [-73.48813476562499, -8.3921875],
              [-73.54912109374999, -8.345800781250006],
              [-73.54912109374999, -8.29931640625],
              [-73.57236328124999, -8.249902343750009],
              [-73.610107421875, -8.19189453125],
              [-73.610107421875, -8.145410156250009],
              [-73.644921875, -8.072851562500006],
              [-73.68266601562499, -8.020605468750006],
              [-73.72041015625, -7.985742187500009],
              [-73.7755859375, -7.936425781250009],
              [-73.772705078125, -7.895703125000011],
              [-73.73203125, -7.875390625],
              [-73.714599609375, -7.829003906250009],
              [-73.72041015625, -7.782519531250003],
              [-73.76689453124999, -7.753515625],
              [-73.82207031249999, -7.738964843750011],
              [-73.89462890624999, -7.65478515625],
              [-73.946875, -7.611230468750009],
              [-73.98173828124999, -7.585058593750006],
              [-74.00205078124999, -7.556054687500009],
              [-73.98173828124999, -7.535742187500006],
              [-73.95849609375, -7.506640625],
              [-73.95268554687499, -7.460253906250003],
              [-73.964306640625, -7.416699218750011],
              [-73.964306640625, -7.37890625],
              [-73.929443359375, -7.367285156250006],
              [-73.891748046875, -7.373144531250006],
              [-73.85400390625, -7.349902343750003],
              [-73.804638671875, -7.341210937500009],
              [-73.749462890625, -7.335351562500009],
              [-73.72041015625, -7.309277343750011],
              [-73.72333984375, -7.262792968750006],
              [-73.758203125, -7.172753906250009],
              [-73.79301757812499, -7.135058593750003],
              [-73.804638671875, -7.079882812500003],
              [-73.77626953125, -6.973535156250009],
              [-73.75810546874999, -6.90576171875],
              [-73.69453125, -6.833789062500003],
              [-73.49990234375, -6.679492187500003],
              [-73.32548828124999, -6.57470703125],
              [-73.24033203124999, -6.5640625],
              [-73.17744140625, -6.525195312500003],
              [-73.137353515625, -6.4658203125],
              [-73.12631835937499, -6.40087890625],
              [-73.13535156249999, -6.344335937500006],
              [-73.167724609375, -6.260644531250009],
              [-73.206494140625, -6.156445312500011],
              [-73.235546875, -6.0984375],
              [-73.209375, -6.028710937500009],
              [-73.16289062499999, -5.933398437500003],
              [-73.06806640625, -5.78955078125],
              [-72.9798828125, -5.634863281250006],
              [-72.97021484375, -5.589648437500003],
              [-72.958935546875, -5.495214843750006],
              [-72.91826171874999, -5.302539062500003],
              [-72.89580078124999, -5.1982421875],
              [-72.907470703125, -5.15771484375],
              [-72.88706054687499, -5.122753906250011],
              [-72.83193359375, -5.09375],
              [-72.69873046875, -5.0671875],
              [-72.608349609375, -5.009570312500003],
              [-72.468994140625, -4.901269531250009],
              [-72.35283203124999, -4.786035156250009],
              [-72.256787109375, -4.748925781250009],
              [-72.08251953125, -4.642285156250011],
              [-71.982421875, -4.574609375],
              [-71.94316406249999, -4.553320312500006],
              [-71.8447265625, -4.50439453125],
              [-71.668359375, -4.4873046875],
              [-71.52133789062499, -4.4697265625],
              [-71.43828124999999, -4.437597656250006],
              [-71.316796875, -4.42431640625],
              [-71.235009765625, -4.38818359375],
              [-71.14423828125, -4.38720703125],
              [-70.97368164062499, -4.350488281250009],
              [-70.915625, -4.2953125],
              [-70.86601562499999, -4.229589843750006],
              [-70.79951171875, -4.17333984375],
              [-70.72158203125, -4.158886718750011],
              [-70.63457031249999, -4.168652343750011],
              [-70.5306640625, -4.167578125],
              [-70.40463867187499, -4.150097656250011],
              [-70.34365234375, -4.193652343750003],
              [-70.31689453125, -4.246972656250009],
              [-70.23916015625, -4.301171875],
              [-70.183984375, -4.298144531250003],
              [-70.12880859375, -4.28662109375],
              [-70.05332031249999, -4.333105468750006],
              [-70.00395507812499, -4.327246093750006],
              [-69.97202148437499, -4.301171875],
              [-69.96591796874999, -4.2359375],
              [-69.948193359375, -4.200585937500009],
              [-69.91103515625, -3.99658203125],
              [-69.849755859375, -3.659863281250011],
              [-69.794140625, -3.354589843750006],
              [-69.7326171875, -3.016699218750006],
              [-69.66904296874999, -2.667675781250011],
              [-69.6046875, -2.314257812500003],
              [-69.55185546874999, -2.02421875],
              [-69.50644531249999, -1.77490234375],
              [-69.47861328124999, -1.621972656250009],
              [-69.43491210937499, -1.421679687500003],
              [-69.41787109375, -1.245703125],
              [-69.40024414062499, -1.194921875],
              [-69.41142578124999, -1.152246093750009],
              [-69.44912109375, -1.091601562500003],
              [-69.44873046875, -1.06494140625],
              [-69.4443359375, -1.029589843750003],
              [-69.44873046875, -0.998730468750011],
              [-69.488427734375, -0.965722656250009],
              [-69.519287109375, -0.94580078125],
              [-69.54355468749999, -0.917187500000011],
              [-69.55458984375, -0.87744140625],
              [-69.5744140625, -0.837792968750009],
              [-69.583251953125, -0.7958984375],
              [-69.61191406249999, -0.762792968750006],
              [-69.620703125, -0.720898437500011],
              [-69.60087890624999, -0.68125],
              [-69.592041015625, -0.639355468750011],
              [-69.60087890624999, -0.599609375],
              [-69.61191406249999, -0.553320312500006],
              [-69.633984375, -0.50927734375],
              [-69.66748046875, -0.482421875],
              [-69.7474609375, -0.452539062500009],
              [-69.82792968749999, -0.38134765625],
              [-69.92275390625, -0.317480468750006],
              [-70.04404296874999, -0.196191406250009],
              [-70.0705078125, -0.138867187500011],
              [-70.070947265625, 0.0185546875],
              [-70.06572265624999, 0.189355468749994],
              [-70.05791015624999, 0.447363281249991],
              [-70.05390625, 0.57861328125],
              [-69.98544921874999, 0.585839843749994],
              [-69.92509765624999, 0.589404296874989],
              [-69.862060546875, 0.598486328124991],
              [-69.80712890625, 0.607470703124989],
              [-69.75673828125, 0.626367187499994],
              [-69.718896484375, 0.649804687499994],
              [-69.673828125, 0.665087890624989],
              [-69.63872070312499, 0.65966796875],
              [-69.60361328124999, 0.680371093749997],
              [-69.56484375, 0.7001953125],
              [-69.52705078125, 0.71640625],
              [-69.472119140625, 0.729931640624997],
              [-69.42080078125, 0.698388671874994],
              [-69.3919921875, 0.666894531249994],
              [-69.358642578125, 0.6515625],
              [-69.3271484375, 0.655175781249994],
              [-69.30551757812499, 0.652441406249991],
              [-69.28300781249999, 0.627246093749989],
              [-69.25419921874999, 0.625439453124997],
              [-69.21279296875, 0.629931640624989],
              [-69.174072265625, 0.635351562499991],
              [-69.15605468749999, 0.642529296874997],
              [-69.1533203125, 0.658789062499991],
              [-69.163232421875, 0.686669921874994],
              [-69.1767578125, 0.712841796874997],
              [-69.165966796875, 0.753320312499994],
              [-69.1650390625, 0.801953125],
              [-69.163232421875, 0.864062499999989],
              [-69.19384765625, 0.898291015624991],
              [-69.22446289062499, 0.963134765625],
              [-69.25869140625, 1.015380859375],
              [-69.31181640624999, 1.050488281249997],
              [-69.36137695312499, 1.064013671874989],
              [-69.402783203125, 1.042382812499994],
              [-69.44150390624999, 1.038818359375],
              [-69.4703125, 1.05859375],
              [-69.51713867187499, 1.059472656249994],
              [-69.567578125, 1.065771484374991],
              [-69.62089843749999, 1.0732421875],
              [-69.7169921875, 1.05908203125],
              [-69.75131835937499, 1.076611328124997],
              [-69.79814453124999, 1.078417968749989],
              [-69.85214843749999, 1.059521484374997],
              [-69.85078125, 1.308789062499997],
              [-69.84946289062499, 1.543896484374997],
              [-69.848583984375, 1.708740234375],
              [-69.799951171875, 1.705175781249991],
              [-69.73959960937499, 1.73486328125],
              [-69.650048828125, 1.739453125],
              [-69.58125, 1.770751953125],
              [-69.542919921875, 1.773242187499989],
              [-69.470166015625, 1.757910156249991],
              [-69.39433593749999, 1.72578125],
              [-69.3197265625, 1.721240234374989],
              [-69.124267578125, 1.721289062499991],
              [-68.91318359374999, 1.721386718749997],
              [-68.678466796875, 1.721484374999989],
              [-68.44345703124999, 1.721582031249994],
              [-68.23955078124999, 1.7216796875],
              [-68.17656249999999, 1.719824218749991],
              [-68.21328125, 1.774560546874994],
              [-68.25595703124999, 1.845507812499989],
              [-68.239453125, 1.9013671875],
              [-68.218359375, 1.957617187499991],
              [-68.193798828125, 1.987011718749997],
              [-68.1302734375, 1.955761718749997],
              [-68.07705078125, 1.860107421875],
              [-68.032861328125, 1.788037109374997],
              [-67.98974609375, 1.752539062499991],
              [-67.93623046875, 1.748486328124997],
              [-67.87553710937499, 1.760595703124991],
              [-67.815087890625, 1.790087890624989],
              [-67.711865234375, 1.922119140625],
              [-67.60922851562499, 2.035058593749994],
              [-67.5560546875, 2.072998046875],
              [-67.499658203125, 2.10791015625],
              [-67.457763671875, 2.121142578124989],
              [-67.40043945312499, 2.11669921875],
              [-67.351953125, 2.085839843749994],
              [-67.32060546874999, 2.032080078124991],
              [-67.205810546875, 1.844824218749991],
              [-67.11923828124999, 1.70361328125],
              [-67.09013671874999, 1.615576171874991],
              [-67.08828125, 1.400585937499997],
              [-67.09365234375, 1.210009765624989],
              [-67.082275390625, 1.185400390624991],
              [-67.06523437499999, 1.178369140624994],
              [-66.87602539062499, 1.223046875],
            ],
          ],
          [
            [
              [-49.628662109375, -0.229199218750011],
              [-49.53520507812499, -0.233593750000011],
              [-49.40288085937499, -0.214648437500003],
              [-49.31425781249999, -0.167871093750009],
              [-49.215087890625, -0.15869140625],
              [-49.11699218749999, -0.16357421875],
              [-48.78657226562498, -0.215527343750011],
              [-48.588037109374994, -0.231640625000011],
              [-48.51542968749999, -0.248242187500011],
              [-48.444482421874994, -0.271875],
              [-48.392675781250006, -0.29736328125],
              [-48.37968749999999, -0.352832031250003],
              [-48.42802734374999, -0.441503906250006],
              [-48.463964843750006, -0.534765625],
              [-48.497460937499994, -0.664941406250009],
              [-48.523339843749994, -0.69140625],
              [-48.566650390625, -0.684472656250009],
              [-48.539697265624994, -0.800976562500011],
              [-48.54951171874998, -0.847558593750009],
              [-48.570947265624994, -0.892871093750003],
              [-48.62407226562499, -0.986914062500006],
              [-48.70458984375, -1.106640625000011],
              [-48.728515625, -1.131738281250009],
              [-48.78984374999999, -1.17333984375],
              [-48.839697265625006, -1.2265625],
              [-48.829003906249994, -1.276562500000011],
              [-48.804052734375006, -1.326953125],
              [-48.83359375, -1.390039062500009],
              [-48.92890624999998, -1.482324218750009],
              [-48.9859375, -1.5046875],
              [-49.038476562499994, -1.5140625],
              [-49.08686523437498, -1.505078125000011],
              [-49.172705078125006, -1.41259765625],
              [-49.18168945312499, -1.484960937500006],
              [-49.20478515624998, -1.558984375],
              [-49.233984375, -1.599511718750009],
              [-49.344824218750006, -1.59521484375],
              [-49.406591796875006, -1.555566406250009],
              [-49.50664062499999, -1.511621093750009],
              [-49.52568359374999, -1.63046875],
              [-49.587890625, -1.71240234375],
              [-49.65058593749998, -1.738085937500003],
              [-49.748779296875, -1.75537109375],
              [-49.80512695312498, -1.790234375000011],
              [-49.91132812499998, -1.762988281250003],
              [-50.00996093749998, -1.70849609375],
              [-50.06572265624999, -1.703808593750011],
              [-50.109277343749994, -1.747851562500003],
              [-50.33842773437499, -1.755957031250006],
              [-50.443457031250006, -1.800683593750009],
              [-50.50761718749999, -1.787988281250009],
              [-50.60205078125, -1.69775390625],
              [-50.6171875, -1.6376953125],
              [-50.67338867187499, -1.516015625],
              [-50.72382812499998, -1.371484375],
              [-50.759765625, -1.240234375],
              [-50.7294921875, -1.126757812500003],
              [-50.668310546875006, -1.130566406250011],
              [-50.59589843749998, -1.1474609375],
              [-50.58051757812498, -1.139453125],
              [-50.57695312499999, -1.103125],
              [-50.592919921874994, -1.072949218750011],
              [-50.709619140624994, -1.077734375],
              [-50.783300781250006, -1.010351562500006],
              [-50.79609374999998, -0.90625],
              [-50.78095703124998, -0.68984375],
              [-50.771386718749994, -0.645410156250009],
              [-50.71992187499998, -0.583398437500009],
              [-50.70307617187498, -0.528515625000011],
              [-50.7158203125, -0.47021484375],
              [-50.693701171875006, -0.364453125000011],
              [-50.6455078125, -0.272851562500009],
              [-50.461572265624994, -0.157421875000011],
              [-50.24824218749998, -0.116406250000011],
              [-49.628662109375, -0.229199218750011],
            ],
          ],
          [
            [
              [-44.129296875, -23.141894531250003],
              [-44.098046875, -23.16933593750001],
              [-44.15576171875, -23.166601562500006],
              [-44.22050781249999, -23.19082031250001],
              [-44.320068359375, -23.21230468750001],
              [-44.36015624999999, -23.17207031250001],
              [-44.27412109374998, -23.1162109375],
              [-44.24287109374998, -23.07412109375001],
              [-44.22041015624998, -23.08291015625001],
              [-44.19160156249998, -23.11328125],
              [-44.129296875, -23.141894531250003],
            ],
          ],
          [
            [
              [-48.58442382812498, -26.40156250000001],
              [-48.60307617187499, -26.41376953125001],
              [-48.665771484375, -26.289648437500006],
              [-48.53974609374998, -26.1703125],
              [-48.49760742187499, -26.21875],
              [-48.531103515625006, -26.31318359375001],
              [-48.56806640624998, -26.3796875],
              [-48.58442382812498, -26.40156250000001],
            ],
          ],
          [
            [
              [-45.26025390625, -23.88916015625],
              [-45.260888671874994, -23.94130859375001],
              [-45.30253906249999, -23.91474609375001],
              [-45.412841796875, -23.93496093750001],
              [-45.451416015625, -23.895605468750006],
              [-45.30234375, -23.7275390625],
              [-45.27226562499999, -23.751953125],
              [-45.24907226562499, -23.78261718750001],
              [-45.23310546874998, -23.82539062500001],
              [-45.25029296874999, -23.85302734375],
              [-45.26025390625, -23.88916015625],
            ],
          ],
          [
            [
              [-44.49931640624999, -2.939648437500011],
              [-44.597753906250006, -3.03759765625],
              [-44.565332031249994, -2.923925781250006],
              [-44.581884765625006, -2.845605468750009],
              [-44.569091796875, -2.784960937500003],
              [-44.501953125, -2.726269531250011],
              [-44.4814453125, -2.717578125],
              [-44.4873046875, -2.789746093750011],
              [-44.482568359374994, -2.811914062500009],
              [-44.49931640624999, -2.939648437500011],
            ],
          ],
          [
            [
              [-38.74384765624998, -13.097070312500009],
              [-38.78300781249999, -13.11865234375],
              [-38.786962890625006, -13.055078125],
              [-38.68486328124999, -12.974902343750003],
              [-38.668115234374994, -12.880175781250003],
              [-38.61455078124999, -12.924023437500011],
              [-38.60029296874998, -12.972460937500003],
              [-38.601171875, -12.992578125],
              [-38.74384765624998, -13.097070312500009],
            ],
          ],
          [
            [
              [-38.903564453125, -13.4734375],
              [-38.937890625, -13.532324218750006],
              [-38.97758789062499, -13.523535156250006],
              [-38.99321289062499, -13.484082031250011],
              [-39.02216796874998, -13.445605468750003],
              [-39.006591796875, -13.41552734375],
              [-38.980126953124994, -13.3984375],
              [-38.907128906249994, -13.401074218750011],
              [-38.903564453125, -13.4734375],
            ],
          ],
          [
            [
              [-44.88310546874999, -1.31787109375],
              [-44.947119140625006, -1.366015625],
              [-44.967871093750006, -1.390820312500011],
              [-45.020849609375006, -1.372363281250003],
              [-45.01123046875, -1.3447265625],
              [-44.99560546875, -1.347558593750009],
              [-44.978662109374994, -1.267285156250011],
              [-44.88828125, -1.27685546875],
              [-44.88310546874999, -1.31787109375],
            ],
          ],
          [
            [
              [-49.73823242187498, 0.268164062499991],
              [-49.697265625, 0.215966796874994],
              [-49.838964843750006, 0.006884765624989],
              [-49.917089843750006, -0.023193359375],
              [-50.00249023437499, -0.029296875],
              [-50.113134765625006, 0.033007812499989],
              [-50.28559570312498, 0.028564453125],
              [-50.339453125, 0.043359375],
              [-50.345117187499994, 0.134472656249997],
              [-50.27265624999998, 0.231738281249989],
              [-50.12797851562499, 0.226513671874997],
              [-49.879003906250006, 0.304541015624991],
              [-49.73823242187498, 0.268164062499991],
            ],
          ],
          [
            [
              [-50.298974609374994, 1.938525390624989],
              [-50.398779296875006, 1.892871093749989],
              [-50.45610351562499, 1.910498046874991],
              [-50.50898437499998, 2.029541015625],
              [-50.49101562499999, 2.128613281249997],
              [-50.41875, 2.161474609374991],
              [-50.362646484375006, 2.154443359374994],
              [-50.34199218749998, 2.141748046874994],
              [-50.292089843750006, 1.979589843749991],
              [-50.298974609374994, 1.938525390624989],
            ],
          ],
          [
            [
              [-50.65288085937499, -0.131640625],
              [-50.926367187500006, -0.327343750000011],
              [-51.01899414062498, -0.263085937500009],
              [-51.0380859375, -0.225878906250003],
              [-51.022363281249994, -0.188378906250009],
              [-51.025732421875006, -0.17236328125],
              [-50.99506835937498, -0.105273437500003],
              [-50.8421875, -0.050195312500009],
              [-50.765283203124994, -0.040869140625006],
              [-50.6669921875, -0.058007812500009],
              [-50.65058593749998, -0.105859375],
              [-50.65288085937499, -0.131640625],
            ],
          ],
          [
            [
              [-49.44389648437499, -0.112402343750006],
              [-49.708837890625006, -0.14375],
              [-49.830078125, -0.093896484375009],
              [-49.80268554687498, -0.051855468750006],
              [-49.712304687499994, 0.01513671875],
              [-49.602197265624994, 0.062695312499997],
              [-49.50346679687499, 0.083691406249997],
              [-49.400488281250006, 0.057226562499991],
              [-49.372314453125, 0.001074218749991],
              [-49.380859375, -0.05546875],
              [-49.44389648437499, -0.112402343750006],
            ],
          ],
          [
            [
              [-50.426123046875006, 0.139257812499991],
              [-50.443945312500006, -0.007666015625006],
              [-50.623925781249994, 0.054394531249997],
              [-50.610449218750006, 0.204785156249997],
              [-50.526220703125006, 0.246923828124991],
              [-50.4515625, 0.326904296875],
              [-50.42607421874999, 0.424951171874994],
              [-50.424560546875, 0.558251953124994],
              [-50.396875, 0.581396484374991],
              [-50.37275390624998, 0.590869140624989],
              [-50.350976562499994, 0.581738281249997],
              [-50.342529296875, 0.381591796875],
              [-50.332275390625, 0.259033203125],
              [-50.426123046875006, 0.139257812499991],
            ],
          ],
          [
            [
              [-50.152929687500006, 0.393017578124997],
              [-50.261328125, 0.359179687499989],
              [-50.28154296874999, 0.390820312499997],
              [-50.28168945312498, 0.516503906249994],
              [-50.25117187499998, 0.58544921875],
              [-50.11279296875, 0.604736328125],
              [-50.0986328125, 0.625],
              [-50.058837890625, 0.638037109374991],
              [-50.03681640624998, 0.594824218749991],
              [-50.0400390625, 0.522802734374991],
              [-50.152929687500006, 0.393017578124997],
            ],
          ],
          [
            [
              [-51.83251953125, -1.433789062500011],
              [-51.938378906249994, -1.45263671875],
              [-51.80205078124999, -1.202539062500009],
              [-51.680029296875006, -1.086132812500011],
              [-51.67827148437499, -0.855078125],
              [-51.546044921874994, -0.649609375000011],
              [-51.424462890624994, -0.56591796875],
              [-51.254003906250006, -0.54140625],
              [-51.160742187500006, -0.666699218750011],
              [-51.27631835937498, -1.021777343750003],
              [-51.31010742187499, -1.023828125],
              [-51.46513671874999, -1.211132812500011],
              [-51.6376953125, -1.341894531250006],
              [-51.83251953125, -1.433789062500011],
            ],
          ],
          [
            [
              [-48.48588867187499, -27.76699218750001],
              [-48.554589843749994, -27.81220703125001],
              [-48.54218749999998, -27.574804687500006],
              [-48.50517578124999, -27.49550781250001],
              [-48.464746093749994, -27.436328125],
              [-48.414892578125006, -27.39960937500001],
              [-48.3779296875, -27.451464843750003],
              [-48.409570312499994, -27.56630859375001],
              [-48.49677734374998, -27.70703125],
              [-48.48588867187499, -27.76699218750001],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 4,
        sovereignt: "Uruguay",
        sov_a3: "URY",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Uruguay",
        adm0_a3: "URY",
        geou_dif: 0,
        geounit: "Uruguay",
        gu_a3: "URY",
        su_dif: 0,
        subunit: "Uruguay",
        su_a3: "URY",
        brk_diff: 0,
        name: "Uruguay",
        name_long: "Uruguay",
        brk_a3: "URY",
        brk_name: "Uruguay",
        brk_group: null,
        abbrev: "Ury.",
        postal: "UY",
        formal_en: "Oriental Republic of Uruguay",
        formal_fr: null,
        name_ciawf: "Uruguay",
        note_adm0: null,
        note_brk: null,
        name_sort: "Uruguay",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 2,
        mapcolor9: 2,
        mapcolor13: 10,
        pop_est: 3461734,
        pop_rank: 12,
        pop_year: 2019,
        gdp_md: 56045,
        gdp_year: 2019,
        economy: "5. Emerging region: G20",
        income_grp: "3. Upper middle income",
        fips_10: "UY",
        iso_a2: "UY",
        iso_a2_eh: "UY",
        iso_a3: "URY",
        iso_a3_eh: "URY",
        iso_n3: "858",
        iso_n3_eh: "858",
        un_a3: "858",
        wb_a2: "UY",
        wb_a3: "URY",
        woe_id: 23424979,
        woe_id_eh: 23424979,
        woe_note: "Exact WOE match as country",
        adm0_iso: "URY",
        adm0_diff: null,
        adm0_tlc: "URY",
        adm0_a3_us: "URY",
        adm0_a3_fr: "URY",
        adm0_a3_ru: "URY",
        adm0_a3_es: "URY",
        adm0_a3_cn: "URY",
        adm0_a3_tw: "URY",
        adm0_a3_in: "URY",
        adm0_a3_np: "URY",
        adm0_a3_pk: "URY",
        adm0_a3_de: "URY",
        adm0_a3_gb: "URY",
        adm0_a3_br: "URY",
        adm0_a3_il: "URY",
        adm0_a3_ps: "URY",
        adm0_a3_sa: "URY",
        adm0_a3_eg: "URY",
        adm0_a3_ma: "URY",
        adm0_a3_pt: "URY",
        adm0_a3_ar: "URY",
        adm0_a3_jp: "URY",
        adm0_a3_ko: "URY",
        adm0_a3_vn: "URY",
        adm0_a3_tr: "URY",
        adm0_a3_id: "URY",
        adm0_a3_pl: "URY",
        adm0_a3_gr: "URY",
        adm0_a3_it: "URY",
        adm0_a3_nl: "URY",
        adm0_a3_se: "URY",
        adm0_a3_bd: "URY",
        adm0_a3_ua: "URY",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "South America",
        region_un: "Americas",
        subregion: "South America",
        region_wb: "Latin America & Caribbean",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: -55.966942,
        label_y: -32.961127,
        ne_id: 1159321353,
        wikidataid: "Q77",
        name_ar: "الأوروغواي",
        name_bn: "উরুগুয়ে",
        name_de: "Uruguay",
        name_en: "Uruguay",
        name_es: "Uruguay",
        name_fa: "اروگوئه",
        name_fr: "Uruguay",
        name_el: "Ουρουγουάη",
        name_he: "אורוגוואי",
        name_hi: "उरुग्वे",
        name_hu: "Uruguay",
        name_id: "Uruguay",
        name_it: "Uruguay",
        name_ja: "ウルグアイ",
        name_ko: "우루과이",
        name_nl: "Uruguay",
        name_pl: "Urugwaj",
        name_pt: "Uruguai",
        name_ru: "Уругвай",
        name_sv: "Uruguay",
        name_tr: "Uruguay",
        name_uk: "Уругвай",
        name_ur: "یوراگوئے",
        name_vi: "Uruguay",
        name_zh: "乌拉圭",
        name_zht: "烏拉圭",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "URY.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-53.37060546875, -33.7421875],
            [-53.419580078124994, -33.77919921875001],
            [-53.47246093749999, -33.84931640625001],
            [-53.53452148437499, -34.01748046875001],
            [-53.742919921875, -34.24951171875],
            [-53.785302734374994, -34.38037109375],
            [-54.01025390625, -34.51699218750001],
            [-54.16855468749999, -34.670703125],
            [-54.272119140624994, -34.66689453125001],
            [-54.36533203124999, -34.73271484375],
            [-54.902294921875, -34.93281250000001],
            [-55.095117187499994, -34.895117187500006],
            [-55.237890625, -34.89580078125],
            [-55.37060546875, -34.8076171875],
            [-55.67314453124999, -34.77568359375],
            [-55.862939453124994, -34.8109375],
            [-56.117919921875, -34.90791015625001],
            [-56.19462890624999, -34.90644531250001],
            [-56.249951171875, -34.90126953125001],
            [-56.387841796874994, -34.86103515625001],
            [-56.4630859375, -34.775390625],
            [-56.85517578125, -34.67666015625001],
            [-57.17070312499999, -34.45234375000001],
            [-57.54345703125, -34.448046875],
            [-57.8291015625, -34.47734375],
            [-57.8732421875, -34.44765625],
            [-57.90214843749999, -34.39013671875],
            [-57.96123046874999, -34.30693359375],
            [-58.20703125, -34.10908203125001],
            [-58.40019531249999, -33.91240234375],
            [-58.438134765624994, -33.719140625],
            [-58.411328125, -33.508886718750006],
            [-58.35336914062499, -33.26005859375],
            [-58.363525390625, -33.18232421875001],
            [-58.2921875, -33.13798828125],
            [-58.221582031249994, -33.12910156250001],
            [-58.153564453125, -33.06464843750001],
            [-58.092675781249994, -32.967382812500006],
            [-58.08232421874999, -32.893652343750006],
            [-58.12958984375, -32.75722656250001],
            [-58.16220703124999, -32.566503906250006],
            [-58.201171875, -32.4716796875],
            [-58.123046875, -32.321875],
            [-58.11972656249999, -32.24892578125001],
            [-58.164794921875, -32.18486328125],
            [-58.177001953125, -32.11904296875001],
            [-58.15634765624999, -32.0515625],
            [-58.160400390625, -31.98652343750001],
            [-58.18901367187499, -31.92421875],
            [-58.16748046875, -31.87265625],
            [-58.095849609374994, -31.831835937500003],
            [-58.04233398437499, -31.76923828125001],
            [-58.006982421874994, -31.68496093750001],
            [-57.98886718749999, -31.62060546875],
            [-57.98798828125, -31.576171875],
            [-58.009667968749994, -31.534375],
            [-58.053857421874994, -31.494921875],
            [-58.0333984375, -31.416601562500006],
            [-57.94833984374999, -31.299414062500006],
            [-57.89335937499999, -31.1953125],
            [-57.868408203125, -31.10439453125001],
            [-57.870068359375, -31.031054687500003],
            [-57.89829101562499, -30.975195312500006],
            [-57.886328125, -30.93740234375001],
            [-57.83408203124999, -30.91748046875],
            [-57.81059570312499, -30.85859375000001],
            [-57.8185546875, -30.712011718750006],
            [-57.872509765625, -30.59101562500001],
            [-57.831201171874994, -30.495214843750006],
            [-57.71269531249999, -30.38447265625001],
            [-57.65087890625, -30.295019531250006],
            [-57.645751953125, -30.226953125],
            [-57.60888671875, -30.187792968750003],
            [-57.55229492187499, -30.26123046875],
            [-57.38383789062499, -30.28066406250001],
            [-57.214453125, -30.28339843750001],
            [-57.186914062499994, -30.26484375000001],
            [-57.120507812499994, -30.14443359375001],
            [-57.03271484375, -30.109960937500006],
            [-56.937255859375, -30.10107421875],
            [-56.83271484375, -30.107226562500003],
            [-56.7216796875, -30.18691406250001],
            [-56.4072265625, -30.44746093750001],
            [-56.176171875, -30.62841796875],
            [-56.105859375, -30.71376953125001],
            [-56.044824218749994, -30.777636718750003],
            [-55.998974609375, -30.837207031250003],
            [-56.018457031249994, -30.99189453125001],
            [-56.015527343749994, -31.059667968750006],
            [-56.00468749999999, -31.079199218750006],
            [-55.95200195312499, -31.080859375],
            [-55.873681640624994, -31.069628906250003],
            [-55.807763671874994, -31.03671875],
            [-55.75634765625, -30.987109375],
            [-55.705957031249994, -30.946582031250003],
            [-55.665234375, -30.924902343750006],
            [-55.65048828124999, -30.89208984375],
            [-55.6271484375, -30.85810546875001],
            [-55.60302734375, -30.85078125000001],
            [-55.55732421875, -30.8759765625],
            [-55.44956054687499, -30.964453125],
            [-55.36606445312499, -31.046191406250003],
            [-55.34550781249999, -31.09296875000001],
            [-55.31328124999999, -31.141699218750006],
            [-55.278955078124994, -31.184179687500006],
            [-55.254638671875, -31.2255859375],
            [-55.17353515625, -31.279589843750003],
            [-55.09116210937499, -31.31396484375],
            [-55.036035156249994, -31.27900390625001],
            [-54.89599609375, -31.391210937500006],
            [-54.587646484375, -31.48515625],
            [-54.530908203124994, -31.5419921875],
            [-54.477685546874994, -31.62275390625001],
            [-54.36992187499999, -31.74501953125001],
            [-54.22055664062499, -31.85517578125001],
            [-54.10043945312499, -31.90156250000001],
            [-53.98515624999999, -31.928125],
            [-53.92060546875, -31.95234375000001],
            [-53.87651367187499, -31.99453125],
            [-53.806103515625, -32.03994140625001],
            [-53.76171875, -32.05683593750001],
            [-53.74658203125, -32.0974609375],
            [-53.701123046875, -32.186328125],
            [-53.65361328124999, -32.29873046875001],
            [-53.601708984374994, -32.40302734375001],
            [-53.489404296874994, -32.50322265625],
            [-53.362744140625, -32.581152343750006],
            [-53.23125, -32.625390625],
            [-53.15727539062499, -32.680078125],
            [-53.12558593749999, -32.73671875],
            [-53.2140625, -32.82109375],
            [-53.31010742187499, -32.92705078125],
            [-53.39521484375, -33.010351562500006],
            [-53.482861328125, -33.06855468750001],
            [-53.511865234374994, -33.10869140625],
            [-53.53134765624999, -33.1708984375],
            [-53.53037109374999, -33.50029296875],
            [-53.53764648437499, -33.6228515625],
            [-53.53134765624999, -33.65546875000001],
            [-53.51884765624999, -33.67724609375],
            [-53.46357421875, -33.70986328125001],
            [-53.39755859374999, -33.7373046875],
            [-53.37060546875, -33.7421875],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Ecuador",
        sov_a3: "ECU",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Ecuador",
        adm0_a3: "ECU",
        geou_dif: 0,
        geounit: "Ecuador",
        gu_a3: "ECU",
        su_dif: 0,
        subunit: "Ecuador",
        su_a3: "ECU",
        brk_diff: 0,
        name: "Ecuador",
        name_long: "Ecuador",
        brk_a3: "ECU",
        brk_name: "Ecuador",
        brk_group: null,
        abbrev: "Ecu.",
        postal: "EC",
        formal_en: "Republic of Ecuador",
        formal_fr: null,
        name_ciawf: "Ecuador",
        note_adm0: null,
        note_brk: null,
        name_sort: "Ecuador",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 5,
        mapcolor9: 2,
        mapcolor13: 12,
        pop_est: 17373662,
        pop_rank: 14,
        pop_year: 2019,
        gdp_md: 107435,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        fips_10: "EC",
        iso_a2: "EC",
        iso_a2_eh: "EC",
        iso_a3: "ECU",
        iso_a3_eh: "ECU",
        iso_n3: "218",
        iso_n3_eh: "218",
        un_a3: "218",
        wb_a2: "EC",
        wb_a3: "ECU",
        woe_id: 23424801,
        woe_id_eh: 23424801,
        woe_note: "Exact WOE match as country",
        adm0_iso: "ECU",
        adm0_diff: null,
        adm0_tlc: "ECU",
        adm0_a3_us: "ECU",
        adm0_a3_fr: "ECU",
        adm0_a3_ru: "ECU",
        adm0_a3_es: "ECU",
        adm0_a3_cn: "ECU",
        adm0_a3_tw: "ECU",
        adm0_a3_in: "ECU",
        adm0_a3_np: "ECU",
        adm0_a3_pk: "ECU",
        adm0_a3_de: "ECU",
        adm0_a3_gb: "ECU",
        adm0_a3_br: "ECU",
        adm0_a3_il: "ECU",
        adm0_a3_ps: "ECU",
        adm0_a3_sa: "ECU",
        adm0_a3_eg: "ECU",
        adm0_a3_ma: "ECU",
        adm0_a3_pt: "ECU",
        adm0_a3_ar: "ECU",
        adm0_a3_jp: "ECU",
        adm0_a3_ko: "ECU",
        adm0_a3_vn: "ECU",
        adm0_a3_tr: "ECU",
        adm0_a3_id: "ECU",
        adm0_a3_pl: "ECU",
        adm0_a3_gr: "ECU",
        adm0_a3_it: "ECU",
        adm0_a3_nl: "ECU",
        adm0_a3_se: "ECU",
        adm0_a3_bd: "ECU",
        adm0_a3_ua: "ECU",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "South America",
        region_un: "Americas",
        subregion: "South America",
        region_wb: "Latin America & Caribbean",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: -78.188375,
        label_y: -1.259076,
        ne_id: 1159320567,
        wikidataid: "Q736",
        name_ar: "الإكوادور",
        name_bn: "ইকুয়েডর",
        name_de: "Ecuador",
        name_en: "Ecuador",
        name_es: "Ecuador",
        name_fa: "اکوادور",
        name_fr: "Équateur",
        name_el: "Εκουαδόρ",
        name_he: "אקוודור",
        name_hi: "ईक्वाडोर",
        name_hu: "Ecuador",
        name_id: "Ekuador",
        name_it: "Ecuador",
        name_ja: "エクアドル",
        name_ko: "에콰도르",
        name_nl: "Ecuador",
        name_pl: "Ekwador",
        name_pt: "Equador",
        name_ru: "Эквадор",
        name_sv: "Ecuador",
        name_tr: "Ekvador",
        name_uk: "Еквадор",
        name_ur: "ایکواڈور",
        name_vi: "Ecuador",
        name_zh: "厄瓜多尔",
        name_zht: "厄瓜多爾",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "ECU.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-75.28447265624999, -0.106542968750006],
              [-75.340478515625, -0.1421875],
              [-75.398388671875, -0.14599609375],
              [-75.4759765625, -0.157128906250009],
              [-75.583740234375, -0.122851562500003],
              [-75.62626953124999, -0.122851562500003],
              [-75.63203125, -0.157617187500009],
              [-75.56059570312499, -0.200097656250009],
              [-75.49106445312499, -0.248339843750003],
              [-75.465966796875, -0.32177734375],
              [-75.42470703125, -0.408886718750011],
              [-75.32524414062499, -0.506542968750011],
              [-75.26323242187499, -0.555371093750011],
              [-75.259375, -0.590136718750003],
              [-75.2787109375, -0.65390625],
              [-75.28359375, -0.707128906250006],
              [-75.249609375, -0.951855468750011],
              [-75.272412109375, -0.966796875],
              [-75.30917968749999, -0.968066406250003],
              [-75.34819335937499, -0.966796875],
              [-75.380126953125, -0.940234375],
              [-75.40805664062499, -0.92431640625],
              [-75.42041015625, -0.962207031250003],
              [-75.449169921875, -1.071191406250009],
              [-75.5138671875, -1.316308593750009],
              [-75.570556640625, -1.53125],
              [-75.64165039062499, -1.607324218750009],
              [-75.74453125, -1.728125],
              [-75.88544921875, -1.893457031250009],
              [-76.089794921875, -2.133105468750003],
              [-76.24091796875, -2.243945312500003],
              [-76.36015624999999, -2.331347656250003],
              [-76.49936523437499, -2.432324218750011],
              [-76.6791015625, -2.562597656250006],
              [-76.88076171875, -2.635937500000011],
              [-77.16147460937499, -2.737695312500009],
              [-77.36005859375, -2.809667968750006],
              [-77.506494140625, -2.859960937500006],
              [-77.65898437499999, -2.912402343750003],
              [-77.860595703125, -2.981640625000011],
              [-77.9384765625, -3.046972656250006],
              [-78.06791992187499, -3.206835937500003],
              [-78.12822265624999, -3.283886718750011],
              [-78.18330078125, -3.350195312500006],
              [-78.19462890624999, -3.38046875],
              [-78.187451171875, -3.399804687500009],
              [-78.16098632812499, -3.43212890625],
              [-78.15849609374999, -3.465136718750003],
              [-78.19487304687499, -3.48583984375],
              [-78.226318359375, -3.489160156250009],
              [-78.240380859375, -3.472558593750009],
              [-78.250732421875, -3.436132812500006],
              [-78.2841796875, -3.399023437500006],
              [-78.32304687499999, -3.38828125],
              [-78.345361328125, -3.397363281250009],
              [-78.347265625, -3.43125],
              [-78.398046875, -3.594824218750006],
              [-78.39995117187499, -3.67431640625],
              [-78.42143554687499, -3.705761718750011],
              [-78.41977539062499, -3.77685546875],
              [-78.47104492187499, -3.843066406250003],
              [-78.49345703124999, -3.902050781250011],
              [-78.50908203124999, -3.9521484375],
              [-78.550439453125, -3.986914062500006],
              [-78.56513671875, -4.041601562500006],
              [-78.60336914062499, -4.157324218750006],
              [-78.64799804687499, -4.248144531250006],
              [-78.67939453125, -4.325878906250011],
              [-78.68515625, -4.383984375000011],
              [-78.66123046874999, -4.425097656250003],
              [-78.65292968749999, -4.458203125000011],
              [-78.674462890625, -4.517675781250006],
              [-78.68603515625, -4.562402343750009],
              [-78.74306640625, -4.592675781250009],
              [-78.8615234375, -4.6650390625],
              [-78.9076171875, -4.714453125],
              [-78.92578125, -4.770703125000011],
              [-78.914208984375, -4.818652343750003],
              [-78.919189453125, -4.8583984375],
              [-78.97539062499999, -4.873242187500011],
              [-78.995263671875, -4.908007812500003],
              [-79.03330078124999, -4.969140625],
              [-79.07626953124999, -4.990625],
              [-79.186669921875, -4.958203125000011],
              [-79.26811523437499, -4.957617187500006],
              [-79.33095703125, -4.927832031250006],
              [-79.3994140625, -4.840039062500011],
              [-79.45576171875, -4.766210937500006],
              [-79.501904296875, -4.670605468750011],
              [-79.51616210937499, -4.539160156250006],
              [-79.57768554687499, -4.500585937500006],
              [-79.63852539062499, -4.454882812500003],
              [-79.71098632812499, -4.467578125],
              [-79.797265625, -4.476367187500003],
              [-79.8451171875, -4.445898437500006],
              [-79.962890625, -4.390332031250011],
              [-80.06352539062499, -4.327539062500009],
              [-80.13955078125, -4.296093750000011],
              [-80.1974609375, -4.31103515625],
              [-80.232177734375, -4.349023437500009],
              [-80.293359375, -4.416796875],
              [-80.38349609375, -4.463671875],
              [-80.42416992187499, -4.46142578125],
              [-80.47856445312499, -4.430078125],
              [-80.4884765625, -4.393652343750006],
              [-80.44384765625, -4.335839843750009],
              [-80.35288085937499, -4.20849609375],
              [-80.453759765625, -4.205175781250006],
              [-80.4884765625, -4.16552734375],
              [-80.49345703124999, -4.119140625],
              [-80.510009765625, -4.069531250000011],
              [-80.49013671875, -4.010058593750003],
              [-80.43720703125, -3.978613281250006],
              [-80.357861328125, -4.00341796875],
              [-80.30327148437499, -4.005078125000011],
              [-80.26689453124999, -3.948828125],
              [-80.23051757812499, -3.924023437500011],
              [-80.19414062499999, -3.905859375],
              [-80.17924804687499, -3.877734375],
              [-80.21757812499999, -3.787695312500006],
              [-80.22885742187499, -3.738867187500006],
              [-80.21728515625, -3.710742187500003],
              [-80.2189453125, -3.654492187500011],
              [-80.22060546875, -3.613183593750009],
              [-80.24375, -3.576757812500006],
              [-80.24541015624999, -3.522167968750011],
              [-80.265234375, -3.492480468750003],
              [-80.271875, -3.461035156250006],
              [-80.27353515624999, -3.424609375],
              [-80.29833984375, -3.406445312500011],
              [-80.324658203125, -3.387890625000011],
              [-80.303125, -3.374804687500003],
              [-80.15986328125, -3.324316406250006],
              [-80.100341796875, -3.274023437500006],
              [-80.02666015624999, -3.228125],
              [-79.963330078125, -3.15771484375],
              [-79.92158203125, -3.090136718750003],
              [-79.822705078125, -2.776953125],
              [-79.7298828125, -2.5791015625],
              [-79.7455078125, -2.484667968750003],
              [-79.822412109375, -2.356542968750006],
              [-79.83974609375, -2.167871093750009],
              [-79.83261718749999, -2.110546875000011],
              [-79.84213867187499, -2.0673828125],
              [-79.89340820312499, -2.145703125000011],
              [-79.880322265625, -2.423632812500003],
              [-79.92558593749999, -2.548535156250011],
              [-79.989013671875, -2.578710937500006],
              [-80.03017578125, -2.556738281250006],
              [-80.00664062499999, -2.353808593750003],
              [-80.05307617187499, -2.390722656250006],
              [-80.1271484375, -2.528417968750006],
              [-80.24863281249999, -2.630566406250011],
              [-80.2552734375, -2.664648437500006],
              [-80.28471679687499, -2.706738281250011],
              [-80.378564453125, -2.66796875],
              [-80.45009765625, -2.6259765625],
              [-80.68486328124999, -2.396875],
              [-80.8390625, -2.349023437500009],
              [-80.93217773437499, -2.269140625],
              [-80.951611328125, -2.235449218750006],
              [-80.96279296875, -2.189257812500003],
              [-80.867626953125, -2.141210937500006],
              [-80.77036132812499, -2.07666015625],
              [-80.76059570312499, -1.9345703125],
              [-80.763134765625, -1.822949218750011],
              [-80.83500976562499, -1.632421875],
              [-80.801416015625, -1.383398437500006],
              [-80.82001953125, -1.285839843750011],
              [-80.90239257812499, -1.07890625],
              [-80.84140625, -0.974707031250006],
              [-80.623681640625, -0.898730468750003],
              [-80.55390625, -0.847949218750003],
              [-80.505078125, -0.683789062500011],
              [-80.45546875, -0.58544921875],
              [-80.3583984375, -0.625097656250006],
              [-80.282373046875, -0.620507812500009],
              [-80.384765625, -0.583984375],
              [-80.46831054687499, -0.43603515625],
              [-80.48227539062499, -0.368261718750006],
              [-80.3212890625, -0.165820312500003],
              [-80.23701171875, -0.113085937500003],
              [-80.13339843749999, -0.006054687500011],
              [-80.046142578125, 0.155371093749991],
              [-80.025, 0.41015625],
              [-80.06103515625, 0.59228515625],
              [-80.08828125, 0.784765625],
              [-80.03593749999999, 0.834570312499991],
              [-79.903515625, 0.860205078124991],
              [-79.795849609375, 0.922265625],
              [-79.7412109375, 0.979785156249989],
              [-79.61318359375, 0.971142578124997],
              [-79.46538085937499, 1.06005859375],
              [-79.22905273437499, 1.104589843749991],
              [-78.899658203125, 1.20625],
              [-78.82705078125, 1.295947265624989],
              [-78.85966796874999, 1.455371093749989],
              [-78.828857421875, 1.434667968749991],
              [-78.73710937499999, 1.358691406249989],
              [-78.681640625, 1.283447265625],
              [-78.587646484375, 1.236669921874991],
              [-78.51152343749999, 1.198828125],
              [-78.312109375, 1.04609375],
              [-78.1806640625, 0.968554687499989],
              [-78.03701171875, 0.898730468749989],
              [-77.829541015625, 0.825390625],
              [-77.702880859375, 0.837841796874997],
              [-77.67319335937499, 0.7822265625],
              [-77.6486328125, 0.7236328125],
              [-77.601318359375, 0.689501953124989],
              [-77.526123046875, 0.660351562499997],
              [-77.481396484375, 0.651171874999989],
              [-77.46767578125, 0.636523437499989],
              [-77.42275390625, 0.424853515624989],
              [-77.39633789062499, 0.393896484374991],
              [-77.29267578125, 0.360400390624989],
              [-77.16572265625, 0.347753906249991],
              [-77.11411132812499, 0.355078125],
              [-77.00244140625, 0.296240234374991],
              [-76.9201171875, 0.268505859374997],
              [-76.829345703125, 0.247753906249997],
              [-76.767724609375, 0.241650390624997],
              [-76.73930664062499, 0.250830078124991],
              [-76.72900390625, 0.272119140624994],
              [-76.67851562499999, 0.268164062499991],
              [-76.60302734375, 0.240966796875],
              [-76.49462890625, 0.235449218749991],
              [-76.42729492187499, 0.26123046875],
              [-76.41796875, 0.30390625],
              [-76.41337890624999, 0.378857421874997],
              [-76.38818359375, 0.404980468749997],
              [-76.31103515625, 0.448486328125],
              [-76.27060546874999, 0.439404296874997],
              [-76.06791992187499, 0.345556640624991],
              [-76.02617187499999, 0.313085937499991],
              [-75.974853515625, 0.247753906249997],
              [-75.87978515625, 0.150976562499991],
              [-75.77666015624999, 0.089257812499994],
              [-75.617333984375, 0.062890625],
              [-75.46396484374999, -0.038427734375006],
              [-75.28447265624999, -0.106542968750006],
            ],
          ],
          [
            [
              [-80.131591796875, -2.97314453125],
              [-80.15068359374999, -3.01171875],
              [-80.245703125, -3.00830078125],
              [-80.27294921875, -2.995898437500003],
              [-80.27216796875, -2.951757812500006],
              [-80.24980468749999, -2.811914062500009],
              [-80.22368164062499, -2.753125],
              [-80.145703125, -2.6962890625],
              [-80.08076171875, -2.668847656250009],
              [-79.997265625, -2.673828125],
              [-79.90903320312499, -2.7255859375],
              [-80.01323242187499, -2.819531250000011],
              [-80.07119140625, -2.833789062500003],
              [-80.093408203125, -2.845898437500011],
              [-80.131591796875, -2.97314453125],
            ],
          ],
          [
            [
              [-78.90922851562499, 1.252783203124991],
              [-78.965625, 1.245361328125],
              [-78.99169921875, 1.293212890625],
              [-78.9232421875, 1.348925781249989],
              [-78.8998046875, 1.359765625],
              [-78.90922851562499, 1.252783203124991],
            ],
          ],
          [
            [
              [-90.33486328125, -0.771582031250006],
              [-90.387109375, -0.773339843750009],
              [-90.542138671875, -0.676464843750011],
              [-90.531689453125, -0.581445312500009],
              [-90.4697265625, -0.517382812500003],
              [-90.26938476562499, -0.484667968750003],
              [-90.185302734375, -0.544824218750009],
              [-90.19272460937499, -0.658789062500006],
              [-90.26108398437499, -0.741992187500003],
              [-90.3154296875, -0.757226562500009],
              [-90.33486328125, -0.771582031250006],
            ],
          ],
          [
            [
              [-89.418896484375, -0.911035156250009],
              [-89.53662109375, -0.952343750000011],
              [-89.577294921875, -0.933789062500011],
              [-89.60263671874999, -0.913476562500009],
              [-89.60859375, -0.888574218750009],
              [-89.54345703125, -0.826855468750011],
              [-89.479931640625, -0.793359375],
              [-89.42314453124999, -0.722265625],
              [-89.31840820312499, -0.680078125],
              [-89.287841796875, -0.68984375],
              [-89.26743164062499, -0.70458984375],
              [-89.259375, -0.728417968750009],
              [-89.294873046875, -0.7859375],
              [-89.358349609375, -0.826074218750009],
              [-89.418896484375, -0.911035156250009],
            ],
          ],
          [
            [
              [-91.4259765625, -0.460839843750009],
              [-91.5263671875, -0.478222656250011],
              [-91.6107421875, -0.443945312500006],
              [-91.64658203124999, -0.390820312500011],
              [-91.65415039062499, -0.3109375],
              [-91.6466796875, -0.284472656250003],
              [-91.46015625, -0.255664062500003],
              [-91.399365234375, -0.322460937500011],
              [-91.39995117187499, -0.4208984375],
              [-91.4259765625, -0.460839843750009],
            ],
          ],
          [
            [
              [-90.42392578124999, -1.339941406250006],
              [-90.46440429687499, -1.341992187500011],
              [-90.51953125, -1.299121093750003],
              [-90.477197265625, -1.220996093750003],
              [-90.43198242187499, -1.23984375],
              [-90.39873046874999, -1.262304687500006],
              [-90.379150390625, -1.292285156250003],
              [-90.42392578124999, -1.339941406250006],
            ],
          ],
          [
            [
              [-91.27216796875, 0.025146484375],
              [-91.21005859374999, -0.039306640625],
              [-91.176220703125, -0.223046875],
              [-90.975537109375, -0.416894531250009],
              [-90.950634765625, -0.525195312500003],
              [-90.96845703125, -0.575585937500009],
              [-90.958935546875, -0.5953125],
              [-90.862548828125, -0.671777343750009],
              [-90.79965820312499, -0.752050781250006],
              [-90.905517578125, -0.940527343750006],
              [-91.1310546875, -1.019628906250006],
              [-91.371533203125, -1.016992187500009],
              [-91.41904296874999, -0.996679687500006],
              [-91.483544921875, -0.924609375],
              [-91.49541015624999, -0.8609375],
              [-91.45830078124999, -0.799511718750011],
              [-91.33408203124999, -0.70625],
              [-91.144677734375, -0.622851562500003],
              [-91.12094726562499, -0.55908203125],
              [-91.197021484375, -0.496972656250009],
              [-91.24951171875, -0.373632812500006],
              [-91.36918945312499, -0.287207031250006],
              [-91.428857421875, -0.023388671875011],
              [-91.468701171875, -0.010302734375003],
              [-91.55, -0.046679687500003],
              [-91.590087890625, -0.014794921875009],
              [-91.596826171875, 0.002099609374994],
              [-91.5091796875, 0.062255859375],
              [-91.49101562499999, 0.105175781249997],
              [-91.36137695312499, 0.125830078124991],
              [-91.30576171874999, 0.09140625],
              [-91.27216796875, 0.025146484375],
            ],
          ],
          [
            [
              [-90.57392578125, -0.333984375],
              [-90.62045898437499, -0.3642578125],
              [-90.809033203125, -0.329394531250003],
              [-90.86777343749999, -0.271386718750009],
              [-90.82036132812499, -0.1921875],
              [-90.78037109374999, -0.160449218750003],
              [-90.66752929687499, -0.18984375],
              [-90.55332031249999, -0.278417968750006],
              [-90.57392578125, -0.333984375],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 5,
        labelrank: 3,
        sovereignt: "Venezuela",
        sov_a3: "VEN",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Venezuela",
        adm0_a3: "VEN",
        geou_dif: 0,
        geounit: "Venezuela",
        gu_a3: "VEN",
        su_dif: 0,
        subunit: "Venezuela",
        su_a3: "VEN",
        brk_diff: 0,
        name: "Venezuela",
        name_long: "Venezuela",
        brk_a3: "VEN",
        brk_name: "Venezuela",
        brk_group: null,
        abbrev: "Ven.",
        postal: "VE",
        formal_en: "Bolivarian Republic of Venezuela",
        formal_fr: "República Bolivariana de Venezuela",
        name_ciawf: "Venezuela",
        note_adm0: null,
        note_brk: null,
        name_sort: "Venezuela, RB",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 3,
        mapcolor9: 1,
        mapcolor13: 4,
        pop_est: 28515829,
        pop_rank: 15,
        pop_year: 2019,
        gdp_md: 482359,
        gdp_year: 2014,
        economy: "5. Emerging region: G20",
        income_grp: "3. Upper middle income",
        fips_10: "VE",
        iso_a2: "VE",
        iso_a2_eh: "VE",
        iso_a3: "VEN",
        iso_a3_eh: "VEN",
        iso_n3: "862",
        iso_n3_eh: "862",
        un_a3: "862",
        wb_a2: "VE",
        wb_a3: "VEN",
        woe_id: 23424982,
        woe_id_eh: 23424982,
        woe_note: "Exact WOE match as country",
        adm0_iso: "VEN",
        adm0_diff: null,
        adm0_tlc: "VEN",
        adm0_a3_us: "VEN",
        adm0_a3_fr: "VEN",
        adm0_a3_ru: "VEN",
        adm0_a3_es: "VEN",
        adm0_a3_cn: "VEN",
        adm0_a3_tw: "VEN",
        adm0_a3_in: "VEN",
        adm0_a3_np: "VEN",
        adm0_a3_pk: "VEN",
        adm0_a3_de: "VEN",
        adm0_a3_gb: "VEN",
        adm0_a3_br: "VEN",
        adm0_a3_il: "VEN",
        adm0_a3_ps: "VEN",
        adm0_a3_sa: "VEN",
        adm0_a3_eg: "VEN",
        adm0_a3_ma: "VEN",
        adm0_a3_pt: "VEN",
        adm0_a3_ar: "VEN",
        adm0_a3_jp: "VEN",
        adm0_a3_ko: "VEN",
        adm0_a3_vn: "VEN",
        adm0_a3_tr: "VEN",
        adm0_a3_id: "VEN",
        adm0_a3_pl: "VEN",
        adm0_a3_gr: "VEN",
        adm0_a3_it: "VEN",
        adm0_a3_nl: "VEN",
        adm0_a3_se: "VEN",
        adm0_a3_bd: "VEN",
        adm0_a3_ua: "VEN",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "South America",
        region_un: "Americas",
        subregion: "South America",
        region_wb: "Latin America & Caribbean",
        name_len: 9,
        long_len: 9,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 2.5,
        max_label: 7.5,
        label_x: -64.599381,
        label_y: 7.182476,
        ne_id: 1159321411,
        wikidataid: "Q717",
        name_ar: "فنزويلا",
        name_bn: "ভেনেজুয়েলা",
        name_de: "Venezuela",
        name_en: "Venezuela",
        name_es: "Venezuela",
        name_fa: "ونزوئلا",
        name_fr: "Venezuela",
        name_el: "Βενεζουέλα",
        name_he: "ונצואלה",
        name_hi: "वेनेज़ुएला",
        name_hu: "Venezuela",
        name_id: "Venezuela",
        name_it: "Venezuela",
        name_ja: "ベネズエラ",
        name_ko: "베네수엘라",
        name_nl: "Venezuela",
        name_pl: "Wenezuela",
        name_pt: "Venezuela",
        name_ru: "Венесуэла",
        name_sv: "Venezuela",
        name_tr: "Venezuela",
        name_uk: "Венесуела",
        name_ur: "وینیزویلا",
        name_vi: "Venezuela",
        name_zh: "委内瑞拉",
        name_zht: "委內瑞拉",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "VEN.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-60.821191406249994, 9.138378906249997],
              [-60.94140625, 9.105566406249991],
              [-60.939453125, 9.13232421875],
              [-60.90727539062499, 9.1787109375],
              [-60.844873046874994, 9.191796875],
              [-60.82138671874999, 9.207666015624994],
              [-60.78159179687499, 9.218359375],
              [-60.75888671874999, 9.216455078124994],
              [-60.73583984375, 9.203320312499997],
              [-60.79038085937499, 9.177197265624997],
              [-60.821191406249994, 9.138378906249997],
            ],
          ],
          [
            [
              [-63.849365234375, 11.131005859374994],
              [-63.817285156249994, 11.000341796874991],
              [-63.827099609375, 10.975830078125],
              [-63.917626953124994, 10.887548828124991],
              [-63.993554687499994, 10.881201171874991],
              [-64.0546875, 10.884326171874989],
              [-64.101171875, 10.901416015624989],
              [-64.160888671875, 10.958789062499989],
              [-64.2189453125, 10.941601562499997],
              [-64.3625, 10.961523437499991],
              [-64.40234375, 10.981591796874994],
              [-64.3486328125, 11.051904296874994],
              [-64.249755859375, 11.080322265625],
              [-64.21367187499999, 11.086132812499997],
              [-64.184814453125, 11.04296875],
              [-64.11279296875, 11.005664062499989],
              [-64.0283203125, 11.001855468749994],
              [-64.00732421875, 11.068457031249991],
              [-63.89311523437499, 11.167236328125],
              [-63.849365234375, 11.131005859374994],
            ],
          ],
          [
            [
              [-65.2125, 10.906445312499997],
              [-65.26640624999999, 10.883984375],
              [-65.365234375, 10.906445312499997],
              [-65.41464843749999, 10.937890625],
              [-65.383203125, 10.973828125],
              [-65.30234375, 10.973828125],
              [-65.2265625, 10.930224609374989],
              [-65.2125, 10.906445312499997],
            ],
          ],
          [
            [
              [-60.99790039062499, 8.867333984374994],
              [-61.059960937499994, 8.847021484374991],
              [-61.06918945312499, 8.947314453124989],
              [-61.05048828125, 8.974365234375],
              [-60.944775390625, 9.055029296874991],
              [-60.91582031249999, 9.0703125],
              [-60.89458007812499, 9.053369140624994],
              [-60.89990234375, 9.031884765624994],
              [-60.84916992187499, 8.995703125],
              [-60.86142578124999, 8.949609375],
              [-60.91640625, 8.899267578124991],
              [-60.99790039062499, 8.867333984374994],
            ],
          ],
          [
            [
              [-60.017529296875, 8.54931640625],
              [-59.831640625, 8.305957031249989],
              [-59.82890624999999, 8.279150390624991],
              [-59.849072265625, 8.248681640624994],
              [-59.96484375, 8.191601562499997],
              [-59.99072265625, 8.162011718749994],
              [-60.032421875, 8.053564453124991],
              [-60.178173828125, 7.994042968749994],
              [-60.27890625, 7.91943359375],
              [-60.34677734374999, 7.85400390625],
              [-60.380615234375, 7.82763671875],
              [-60.513623046875, 7.813183593749997],
              [-60.55634765625, 7.772021484374989],
              [-60.610107421875, 7.648339843749994],
              [-60.64946289062499, 7.596630859374997],
              [-60.718652343749994, 7.535937499999989],
              [-60.71923828125, 7.498681640624994],
              [-60.62373046875, 7.363330078124989],
              [-60.60654296874999, 7.320849609374989],
              [-60.636181640625, 7.256591796875],
              [-60.63330078125, 7.211083984374994],
              [-60.583203125, 7.156201171874997],
              [-60.523193359375, 7.143701171874994],
              [-60.46494140624999, 7.166552734374989],
              [-60.39238281249999, 7.16455078125],
              [-60.34506835937499, 7.15],
              [-60.32548828124999, 7.133984375],
              [-60.32207031249999, 7.092041015625],
              [-60.35209960937499, 7.002880859374997],
              [-60.39501953125, 6.945361328124989],
              [-60.586083984374994, 6.857080078124994],
              [-60.671044921874994, 6.805957031249989],
              [-60.717919921874994, 6.768310546875],
              [-60.82084960937499, 6.788476562499994],
              [-60.87333984374999, 6.786914062499989],
              [-60.91357421875, 6.7578125],
              [-60.93798828125, 6.732763671874991],
              [-61.007080078125, 6.726611328124989],
              [-61.10478515624999, 6.711376953124997],
              [-61.14560546874999, 6.69453125],
              [-61.17724609375, 6.650927734374989],
              [-61.20361328125, 6.58837890625],
              [-61.181591796875, 6.513378906249997],
              [-61.151025390624994, 6.446533203125],
              [-61.152294921875, 6.385107421874991],
              [-61.12871093749999, 6.214306640624997],
              [-61.15947265624999, 6.174414062499991],
              [-61.22495117187499, 6.129199218749989],
              [-61.303125, 6.049511718749997],
              [-61.3908203125, 5.938769531249989],
              [-61.37680664062499, 5.906982421875],
              [-61.1671875, 5.67421875],
              [-60.954003906249994, 5.437402343749994],
              [-60.742138671875, 5.202050781249994],
              [-60.71196289062499, 5.191552734374994],
              [-60.67197265624999, 5.164355468749989],
              [-60.635009765625, 5.081982421874997],
              [-60.6044921875, 4.994580078124997],
              [-60.60385742187499, 4.949365234374994],
              [-60.627587890624994, 4.892529296874997],
              [-60.679150390625, 4.827099609374997],
              [-60.74174804687499, 4.774121093749997],
              [-60.833398437499994, 4.729199218749997],
              [-60.90625, 4.686816406249989],
              [-60.96640625, 4.57470703125],
              [-61.002832031249994, 4.535253906249991],
              [-61.036279296874994, 4.519335937499989],
              [-61.102441406249994, 4.504687499999989],
              [-61.209423828125, 4.508056640625],
              [-61.28007812499999, 4.516894531249989],
              [-61.36752929687499, 4.433007812499994],
              [-61.479394531249994, 4.402246093749994],
              [-61.55424804687499, 4.287792968749997],
              [-61.82084960937499, 4.197021484375],
              [-62.08159179687499, 4.126318359374991],
              [-62.153125, 4.098388671875],
              [-62.41064453125, 4.15673828125],
              [-62.472558593749994, 4.138525390624991],
              [-62.5439453125, 4.084326171874991],
              [-62.609765625, 4.042285156249989],
              [-62.66533203124999, 4.039648437499991],
              [-62.712109375, 4.017919921874991],
              [-62.73994140625, 3.940332031249994],
              [-62.764599609375, 3.672949218749991],
              [-62.85698242187499, 3.593457031249997],
              [-62.968652343749994, 3.593945312499997],
              [-63.0453125, 3.686474609374997],
              [-63.13623046875, 3.756445312499991],
              [-63.29472656249999, 3.922265625],
              [-63.33867187499999, 3.943896484374989],
              [-63.379785156249994, 3.94287109375],
              [-63.526806640625, 3.893701171874994],
              [-63.596630859375, 3.9150390625],
              [-63.65292968749999, 3.940820312499994],
              [-63.746972656249994, 3.932568359374997],
              [-63.91464843749999, 3.9306640625],
              [-64.021484375, 3.929101562499994],
              [-64.073388671875, 3.974414062499989],
              [-64.121728515625, 4.066992187499991],
              [-64.154296875, 4.100146484374989],
              [-64.19248046874999, 4.126855468749994],
              [-64.25566406249999, 4.140332031249997],
              [-64.525537109375, 4.139990234374991],
              [-64.5763671875, 4.139892578125],
              [-64.613671875, 4.15771484375],
              [-64.66552734375, 4.237109374999989],
              [-64.722265625, 4.2744140625],
              [-64.788671875, 4.276025390624994],
              [-64.81787109375, 4.232275390624991],
              [-64.702587890625, 4.089306640624997],
              [-64.66899414062499, 4.011816406249991],
              [-64.56791992187499, 3.899804687499994],
              [-64.27529296875, 3.662695312499991],
              [-64.22109375, 3.58740234375],
              [-64.22705078125, 3.4912109375],
              [-64.228759765625, 3.343994140625],
              [-64.21884765624999, 3.2046875],
              [-64.1435546875, 3.0048828125],
              [-64.03779296875, 2.801513671875],
              [-64.009033203125, 2.671875],
              [-64.0287109375, 2.576074218749994],
              [-64.048828125, 2.525097656249997],
              [-64.04658203125, 2.502392578124997],
              [-64.02490234375, 2.481884765624997],
              [-63.92416992187499, 2.452441406249989],
              [-63.712548828124994, 2.434033203124997],
              [-63.584619140624994, 2.433935546874991],
              [-63.38925781249999, 2.411914062499989],
              [-63.37485351562499, 2.340429687499991],
              [-63.393945312499994, 2.222509765624991],
              [-63.432519531249994, 2.155566406249989],
              [-63.46391601562499, 2.136035156249989],
              [-63.570263671875, 2.120507812499994],
              [-63.68212890625, 2.048144531249989],
              [-63.844482421875, 1.976708984374994],
              [-63.937158203124994, 1.966992187499997],
              [-63.97578125, 1.953027343749994],
              [-64.00849609375, 1.931591796874997],
              [-64.03544921874999, 1.904443359374994],
              [-64.067041015625, 1.7705078125],
              [-64.11484375, 1.619287109374994],
              [-64.205029296875, 1.529492187499997],
              [-64.30419921875, 1.455273437499997],
              [-64.40512695312499, 1.446875],
              [-64.48603515625, 1.452783203124994],
              [-64.52626953125, 1.431005859374991],
              [-64.584375, 1.369873046875],
              [-64.667431640625, 1.293847656249994],
              [-64.73154296874999, 1.253320312499994],
              [-64.81796875, 1.257128906249989],
              [-64.910107421875, 1.2197265625],
              [-65.0265625, 1.158447265625],
              [-65.103759765625, 1.108105468749997],
              [-65.16962890625, 1.022216796875],
              [-65.26396484374999, 0.931884765625],
              [-65.36083984375, 0.86865234375],
              [-65.4072265625, 0.790478515624997],
              [-65.473388671875, 0.691259765624991],
              [-65.5560546875, 0.68798828125],
              [-65.5626953125, 0.747509765624997],
              [-65.52299804687499, 0.843408203124994],
              [-65.566015625, 0.926074218749989],
              [-65.644677734375, 0.970361328124994],
              [-65.68144531249999, 0.983447265624989],
              [-65.71811523437499, 0.97802734375],
              [-65.81132812499999, 0.937255859375],
              [-65.92587890624999, 0.863134765624991],
              [-65.996337890625, 0.809765625],
              [-66.06005859375, 0.785351562499997],
              [-66.19121093749999, 0.76328125],
              [-66.30166015625, 0.751953125],
              [-66.347119140625, 0.7671875],
              [-66.42924804687499, 0.821679687499994],
              [-66.61904296875, 0.992138671874997],
              [-66.87602539062499, 1.223046875],
              [-66.8955078125, 1.289892578124991],
              [-66.88447265625, 1.358251953124991],
              [-66.931103515625, 1.4580078125],
              [-66.95834960937499, 1.564208984375],
              [-66.98154296874999, 1.60078125],
              [-66.98813476562499, 1.68017578125],
              [-67.043896484375, 1.823193359374997],
              [-67.08955078125, 1.940332031249994],
              [-67.13144531249999, 1.999853515624991],
              [-67.11381835937499, 2.050585937499989],
              [-67.13144531249999, 2.101269531249997],
              [-67.165478515625, 2.142578125],
              [-67.215234375, 2.275488281249991],
              [-67.19760742187499, 2.332763671875],
              [-67.21083984375, 2.39013671875],
              [-67.25273437499999, 2.429443359375],
              [-67.312255859375, 2.4716796875],
              [-67.3916015625, 2.559912109374991],
              [-67.48642578124999, 2.643652343749991],
              [-67.53496093749999, 2.6767578125],
              [-67.568017578125, 2.68994140625],
              [-67.5966796875, 2.769335937499989],
              [-67.61870117187499, 2.793603515624994],
              [-67.667236328125, 2.800195312499994],
              [-67.76645507812499, 2.833300781249989],
              [-67.85908203125, 2.793603515624994],
              [-67.86123046875, 2.855322265624991],
              [-67.83476562499999, 2.892822265625],
              [-67.51484375, 3.187255859375],
              [-67.35361328124999, 3.32265625],
              [-67.33627929687499, 3.342626953124991],
              [-67.32216796875, 3.373974609374997],
              [-67.31113281249999, 3.415869140624991],
              [-67.34770507812499, 3.463769531249994],
              [-67.498681640625, 3.691113281249997],
              [-67.55112304687499, 3.733837890624997],
              [-67.6025390625, 3.768798828125],
              [-67.66162109375, 3.8642578125],
              [-67.73232421875, 4.086523437499991],
              [-67.783203125, 4.1982421875],
              [-67.79863281249999, 4.283886718749997],
              [-67.79541015625, 4.380712890624991],
              [-67.81430664062499, 4.455078125],
              [-67.85527343749999, 4.506884765624989],
              [-67.85527343749999, 4.665478515624997],
              [-67.81430664062499, 4.930810546874994],
              [-67.80419921875, 5.132519531249997],
              [-67.82490234375, 5.270458984374997],
              [-67.78842773437499, 5.37548828125],
              [-67.69462890624999, 5.447509765625],
              [-67.64228515625, 5.558789062499997],
              [-67.63134765625, 5.709375],
              [-67.5751953125, 5.833105468749991],
              [-67.473876953125, 5.929980468749989],
              [-67.43935546875, 6.025537109374994],
              [-67.47158203125, 6.119775390624994],
              [-67.48198242187499, 6.180273437499991],
              [-67.56806640625, 6.241796875],
              [-67.72714843749999, 6.284960937499989],
              [-67.85917968749999, 6.289892578124991],
              [-67.9388671875, 6.241943359375],
              [-68.14306640625, 6.197509765625],
              [-68.47177734374999, 6.156542968749989],
              [-68.736474609375, 6.156787109374989],
              [-68.93720703125, 6.198193359374997],
              [-69.08994140624999, 6.184375],
              [-69.19453125, 6.115332031249991],
              [-69.26816406249999, 6.099707031249991],
              [-69.31083984374999, 6.137597656249994],
              [-69.357080078125, 6.147998046874989],
              [-69.4271484375, 6.123974609374997],
              [-69.43925781249999, 6.134912109374994],
              [-69.59482421874999, 6.321484375],
              [-69.73896484375, 6.494384765625],
              [-69.90419921875, 6.700244140624989],
              [-70.09501953124999, 6.937939453124997],
              [-70.12919921874999, 6.95361328125],
              [-70.188134765625, 6.952050781249994],
              [-70.26611328125, 6.947949218749997],
              [-70.3875, 6.972607421874997],
              [-70.470654296875, 7.007128906249989],
              [-70.535546875, 7.04052734375],
              [-70.65507812499999, 7.082763671875],
              [-70.73715820312499, 7.090039062499997],
              [-70.810693359375, 7.077587890624997],
              [-71.01328125, 6.994433593749989],
              [-71.12861328125, 6.98671875],
              [-71.21782226562499, 6.985205078124991],
              [-71.45712890624999, 7.0263671875],
              [-71.62089843749999, 7.032910156249997],
              [-71.811279296875, 7.005810546874997],
              [-71.89267578124999, 6.990332031249991],
              [-72.00664062499999, 7.032617187499994],
              [-72.08427734374999, 7.096875],
              [-72.156689453125, 7.249707031249997],
              [-72.20771484375, 7.370263671874994],
              [-72.296337890625, 7.39453125],
              [-72.39462890624999, 7.415087890624989],
              [-72.44296875, 7.454882812499989],
              [-72.47197265624999, 7.524267578124991],
              [-72.478955078125, 7.613232421874997],
              [-72.468896484375, 7.757958984374994],
              [-72.45957031249999, 7.809863281249989],
              [-72.446044921875, 7.966113281249989],
              [-72.39169921874999, 8.047705078124991],
              [-72.3576171875, 8.087304687499994],
              [-72.36416015625, 8.152783203124997],
              [-72.39033203125, 8.287060546874997],
              [-72.41655273437499, 8.381982421874994],
              [-72.52573242187499, 8.489697265624997],
              [-72.6654296875, 8.627587890624994],
              [-72.725537109375, 8.848291015624994],
              [-72.79638671875, 9.108984375],
              [-72.85209960937499, 9.13515625],
              [-72.904443359375, 9.1220703125],
              [-72.96015625, 9.13515625],
              [-73.00927734375, 9.239941406249997],
              [-73.05839843749999, 9.259570312499989],
              [-73.13671875, 9.222802734374994],
              [-73.19316406249999, 9.194140624999989],
              [-73.33671874999999, 9.167919921874997],
              [-73.3662109375, 9.194140624999989],
              [-73.35634765625, 9.226855468749989],
              [-73.295654296875, 9.322021484375],
              [-73.224267578125, 9.443603515625],
              [-73.141259765625, 9.554638671874997],
              [-73.0640625, 9.668212890625],
              [-73.00654296875, 9.789160156249991],
              [-72.96738281249999, 10.029736328124997],
              [-72.940380859375, 10.195751953124997],
              [-72.8693359375, 10.491259765624989],
              [-72.73916015625, 10.727197265624994],
              [-72.690087890625, 10.835839843749994],
              [-72.572265625, 10.977148437499991],
              [-72.518017578125, 11.05390625],
              [-72.44609374999999, 11.1142578125],
              [-72.248486328125, 11.196435546874994],
              [-72.01230468749999, 11.601953125],
              [-71.95810546874999, 11.66640625],
              [-71.719482421875, 11.726855468749989],
              [-71.536083984375, 11.774072265624994],
              [-71.40019531249999, 11.823535156249989],
              [-71.35556640624999, 11.849755859374994],
              [-71.3197265625, 11.861914062499991],
              [-71.34941406249999, 11.81494140625],
              [-71.41455078125, 11.755175781249989],
              [-71.48837890624999, 11.71875],
              [-71.86865234375, 11.62734375],
              [-71.90751953124999, 11.607958984374989],
              [-71.95693359375, 11.569921875],
              [-71.9572265625, 11.4828125],
              [-71.94697265625, 11.414453125],
              [-71.835107421875, 11.190332031249994],
              [-71.791455078125, 11.135058593749989],
              [-71.6416015625, 11.013525390624991],
              [-71.67568359375, 10.996728515624994],
              [-71.730908203125, 10.994677734374989],
              [-71.6904296875, 10.835498046874989],
              [-71.59843749999999, 10.726220703124994],
              [-71.59433593749999, 10.657373046874994],
              [-71.66484374999999, 10.44375],
              [-71.79350585937499, 10.315966796874989],
              [-71.884765625, 10.167236328125],
              [-71.955712890625, 10.108056640624994],
              [-72.11284179687499, 9.815576171874994],
              [-71.99326171874999, 9.641503906249994],
              [-71.97626953125, 9.55322265625],
              [-71.873046875, 9.427636718749994],
              [-71.8056640625, 9.386425781249997],
              [-71.7607421875, 9.335742187499989],
              [-71.78134765624999, 9.25],
              [-71.74013671875, 9.133886718749991],
              [-71.68671875, 9.072509765625],
              [-71.61953125, 9.047949218749991],
              [-71.53662109375, 9.048291015624997],
              [-71.29794921874999, 9.125634765624994],
              [-71.24140625, 9.160449218749989],
              [-71.20537109374999, 9.222460937499989],
              [-71.08583984375, 9.348242187499991],
              [-71.07841796874999, 9.510791015624989],
              [-71.052685546875, 9.705810546875],
              [-71.08173828125, 9.833203125],
              [-71.2072265625, 10.014599609374997],
              [-71.26220703125, 10.143603515624989],
              [-71.38662109375, 10.263769531249991],
              [-71.46279296875, 10.46923828125],
              [-71.49423828124999, 10.533203125],
              [-71.51787109374999, 10.621826171875],
              [-71.54462890625, 10.778710937499994],
              [-71.4611328125, 10.835644531249997],
              [-71.46953124999999, 10.964160156249989],
              [-71.26435546875, 10.99951171875],
              [-70.8205078125, 11.208447265624997],
              [-70.54560546875, 11.261376953124994],
              [-70.23251953124999, 11.372998046874997],
              [-70.15996093749999, 11.428076171874991],
              [-70.097119140625, 11.519775390625],
              [-70.04853515625, 11.530322265624989],
              [-69.88535156249999, 11.4443359375],
              [-69.80478515624999, 11.47421875],
              [-69.772900390625, 11.541308593749989],
              [-69.817333984375, 11.672070312499997],
              [-69.91093749999999, 11.672119140625],
              [-70.192578125, 11.624609375],
              [-70.2201171875, 11.680859375],
              [-70.22001953124999, 11.730078125],
              [-70.2865234375, 11.886035156249989],
              [-70.2451171875, 12.003515625],
              [-70.202783203125, 12.098388671875],
              [-70.122021484375, 12.136621093749994],
              [-70.00395507812499, 12.177880859374994],
              [-69.91435546874999, 12.114599609374991],
              [-69.860107421875, 12.05419921875],
              [-69.83061523437499, 11.99560546875],
              [-69.810546875, 11.836865234374997],
              [-69.76240234375, 11.676025390625],
              [-69.7119140625, 11.564208984375],
              [-69.631591796875, 11.479931640624997],
              [-69.56982421875, 11.485449218749991],
              [-69.52573242187499, 11.49951171875],
              [-69.232568359375, 11.518457031249994],
              [-69.05458984375, 11.461035156249991],
              [-68.82797851562499, 11.431738281249991],
              [-68.6162109375, 11.309375],
              [-68.3986328125, 11.160986328124991],
              [-68.3431640625, 11.052832031249991],
              [-68.32480468749999, 10.949316406249991],
              [-68.27207031249999, 10.880029296874994],
              [-68.32470703125, 10.808740234374994],
              [-68.2962890625, 10.689355468749994],
              [-68.23408203125, 10.569140624999989],
              [-68.13994140624999, 10.492724609374989],
              [-67.87163085937499, 10.472070312499994],
              [-67.58134765624999, 10.523730468749989],
              [-67.13330078125, 10.570410156249991],
              [-66.98906249999999, 10.610644531249989],
              [-66.247216796875, 10.632226562499994],
              [-66.105859375, 10.574609375],
              [-66.09213867187499, 10.51708984375],
              [-66.090478515625, 10.472949218749989],
              [-65.8517578125, 10.257763671874997],
              [-65.655859375, 10.228466796874997],
              [-65.48935546874999, 10.159423828125],
              [-65.3173828125, 10.122363281249989],
              [-65.1291015625, 10.070068359375],
              [-65.02329101562499, 10.07666015625],
              [-64.94404296875, 10.095019531249989],
              [-64.85048828125, 10.098095703124997],
              [-64.18833007812499, 10.457812499999989],
              [-63.83369140625, 10.448535156249989],
              [-63.779052734375, 10.471923828125],
              [-63.731884765625, 10.50341796875],
              [-63.862695312499994, 10.558154296874989],
              [-64.15791015625, 10.579248046874994],
              [-64.247509765625, 10.542578125],
              [-64.29819335937499, 10.63515625],
              [-64.20195312499999, 10.632666015624991],
              [-63.8734375, 10.663769531249997],
              [-63.49677734375, 10.643261718749997],
              [-63.189892578125, 10.709179687499997],
              [-63.03549804687499, 10.720117187499994],
              [-62.946728515625, 10.707080078124989],
              [-62.70234375, 10.749804687499989],
              [-62.24228515624999, 10.699560546874991],
              [-61.87949218749999, 10.741015624999989],
              [-61.92138671875, 10.681445312499989],
              [-62.040429687499994, 10.645361328124991],
              [-62.23291015625, 10.633984375],
              [-62.37998046874999, 10.546875],
              [-62.6935546875, 10.56298828125],
              [-62.91357421875, 10.531494140625],
              [-62.84296875, 10.507226562499994],
              [-62.843017578125, 10.417919921874997],
              [-62.812939453125, 10.39990234375],
              [-62.78125, 10.399218749999989],
              [-62.706298828125, 10.333056640624989],
              [-62.68583984374999, 10.289794921875],
              [-62.66162109375, 10.198583984374991],
              [-62.69467773437499, 10.10009765625],
              [-62.74057617187499, 10.05615234375],
              [-62.65117187499999, 10.070654296874991],
              [-62.600488281249994, 10.116943359375],
              [-62.60791015625, 10.163427734374991],
              [-62.600488281249994, 10.21728515625],
              [-62.55034179687499, 10.200439453125],
              [-62.51513671875, 10.176123046874991],
              [-62.40092773437499, 9.918408203124997],
              [-62.32041015624999, 9.783056640624991],
              [-62.2998046875, 9.788183593749991],
              [-62.280664062499994, 9.79296875],
              [-62.256738281249994, 9.818896484374989],
              [-62.221142578125, 9.882568359375],
              [-62.1904296875, 9.8421875],
              [-62.17197265624999, 9.826708984374989],
              [-62.15336914062499, 9.82177734375],
              [-62.1703125, 9.879492187499991],
              [-62.1474609375, 9.953417968749989],
              [-62.15532226562499, 9.979248046875],
              [-62.11962890625, 9.98486328125],
              [-62.077099609375, 9.975048828124997],
              [-62.016503906249994, 9.9546875],
              [-61.90859375, 9.869921874999989],
              [-61.83725585937499, 9.782080078124991],
              [-61.83115234374999, 9.733056640624994],
              [-61.80537109375, 9.705517578124997],
              [-61.758740234375, 9.676513671875],
              [-61.7359375, 9.631201171874991],
              [-61.73173828124999, 9.702490234374991],
              [-61.759179687499994, 9.754443359374989],
              [-61.76591796874999, 9.813818359374991],
              [-61.625390625, 9.816455078124989],
              [-61.5888671875, 9.89453125],
              [-61.51230468749999, 9.847509765624991],
              [-61.309375, 9.633056640625],
              [-61.23442382812499, 9.597607421874997],
              [-61.01337890625, 9.556445312499989],
              [-60.87407226562499, 9.453320312499997],
              [-60.79248046875, 9.360742187499994],
              [-60.840966796874994, 9.263671875],
              [-60.97104492187499, 9.215185546874991],
              [-61.02314453125, 9.154589843749989],
              [-61.05307617187499, 9.095117187499994],
              [-61.05356445312499, 9.035253906249991],
              [-61.09296875, 8.965771484374997],
              [-61.098828125, 8.941308593749994],
              [-61.12236328124999, 8.843359375],
              [-61.17587890624999, 8.725390624999989],
              [-61.247265625, 8.600341796875],
              [-61.61870117187499, 8.597460937499989],
              [-61.52690429687499, 8.546142578125],
              [-61.442578125, 8.508691406249994],
              [-61.30400390624999, 8.410400390625],
              [-61.19375, 8.487597656249989],
              [-61.03598632812499, 8.493115234374997],
              [-60.865234375, 8.578808593749997],
              [-60.8009765625, 8.592138671874991],
              [-60.48149414062499, 8.547265625],
              [-60.4044921875, 8.610253906249994],
              [-60.340234375, 8.628759765624991],
              [-60.16748046875, 8.616992187499989],
              [-60.017529296875, 8.54931640625],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 2,
        sovereignt: "Colombia",
        sov_a3: "COL",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Colombia",
        adm0_a3: "COL",
        geou_dif: 0,
        geounit: "Colombia",
        gu_a3: "COL",
        su_dif: 0,
        subunit: "Colombia",
        su_a3: "COL",
        brk_diff: 0,
        name: "Colombia",
        name_long: "Colombia",
        brk_a3: "COL",
        brk_name: "Colombia",
        brk_group: null,
        abbrev: "Col.",
        postal: "CO",
        formal_en: "Republic of Colombia",
        formal_fr: null,
        name_ciawf: "Colombia",
        note_adm0: null,
        note_brk: null,
        name_sort: "Colombia",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 1,
        mapcolor9: 3,
        mapcolor13: 1,
        pop_est: 50339443,
        pop_rank: 16,
        pop_year: 2019,
        gdp_md: 323615,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        fips_10: "CO",
        iso_a2: "CO",
        iso_a2_eh: "CO",
        iso_a3: "COL",
        iso_a3_eh: "COL",
        iso_n3: "170",
        iso_n3_eh: "170",
        un_a3: "170",
        wb_a2: "CO",
        wb_a3: "COL",
        woe_id: 23424787,
        woe_id_eh: 23424787,
        woe_note: "Exact WOE match as country",
        adm0_iso: "COL",
        adm0_diff: null,
        adm0_tlc: "COL",
        adm0_a3_us: "COL",
        adm0_a3_fr: "COL",
        adm0_a3_ru: "COL",
        adm0_a3_es: "COL",
        adm0_a3_cn: "COL",
        adm0_a3_tw: "COL",
        adm0_a3_in: "COL",
        adm0_a3_np: "COL",
        adm0_a3_pk: "COL",
        adm0_a3_de: "COL",
        adm0_a3_gb: "COL",
        adm0_a3_br: "COL",
        adm0_a3_il: "COL",
        adm0_a3_ps: "COL",
        adm0_a3_sa: "COL",
        adm0_a3_eg: "COL",
        adm0_a3_ma: "COL",
        adm0_a3_pt: "COL",
        adm0_a3_ar: "COL",
        adm0_a3_jp: "COL",
        adm0_a3_ko: "COL",
        adm0_a3_vn: "COL",
        adm0_a3_tr: "COL",
        adm0_a3_id: "COL",
        adm0_a3_pl: "COL",
        adm0_a3_gr: "COL",
        adm0_a3_it: "COL",
        adm0_a3_nl: "COL",
        adm0_a3_se: "COL",
        adm0_a3_bd: "COL",
        adm0_a3_ua: "COL",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "South America",
        region_un: "Americas",
        subregion: "South America",
        region_wb: "Latin America & Caribbean",
        name_len: 8,
        long_len: 8,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 7,
        label_x: -73.174347,
        label_y: 3.373111,
        ne_id: 1159320517,
        wikidataid: "Q739",
        name_ar: "كولومبيا",
        name_bn: "কলম্বিয়া",
        name_de: "Kolumbien",
        name_en: "Colombia",
        name_es: "Colombia",
        name_fa: "کلمبیا",
        name_fr: "Colombie",
        name_el: "Κολομβία",
        name_he: "קולומביה",
        name_hi: "कोलम्बिया",
        name_hu: "Kolumbia",
        name_id: "Kolombia",
        name_it: "Colombia",
        name_ja: "コロンビア",
        name_ko: "콜롬비아",
        name_nl: "Colombia",
        name_pl: "Kolumbia",
        name_pt: "Colômbia",
        name_ru: "Колумбия",
        name_sv: "Colombia",
        name_tr: "Kolombiya",
        name_uk: "Колумбія",
        name_ur: "کولمبیا",
        name_vi: "Colombia",
        name_zh: "哥伦比亚",
        name_zht: "哥倫比亞",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "COL.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-71.3197265625, 11.861914062499991],
              [-71.35556640624999, 11.849755859374994],
              [-71.40019531249999, 11.823535156249989],
              [-71.536083984375, 11.774072265624994],
              [-71.719482421875, 11.726855468749989],
              [-71.95810546874999, 11.66640625],
              [-72.01230468749999, 11.601953125],
              [-72.248486328125, 11.196435546874994],
              [-72.44609374999999, 11.1142578125],
              [-72.518017578125, 11.05390625],
              [-72.572265625, 10.977148437499991],
              [-72.690087890625, 10.835839843749994],
              [-72.73916015625, 10.727197265624994],
              [-72.8693359375, 10.491259765624989],
              [-72.940380859375, 10.195751953124997],
              [-72.96738281249999, 10.029736328124997],
              [-73.00654296875, 9.789160156249991],
              [-73.0640625, 9.668212890625],
              [-73.141259765625, 9.554638671874997],
              [-73.224267578125, 9.443603515625],
              [-73.295654296875, 9.322021484375],
              [-73.35634765625, 9.226855468749989],
              [-73.3662109375, 9.194140624999989],
              [-73.33671874999999, 9.167919921874997],
              [-73.19316406249999, 9.194140624999989],
              [-73.13671875, 9.222802734374994],
              [-73.05839843749999, 9.259570312499989],
              [-73.00927734375, 9.239941406249997],
              [-72.96015625, 9.13515625],
              [-72.904443359375, 9.1220703125],
              [-72.85209960937499, 9.13515625],
              [-72.79638671875, 9.108984375],
              [-72.725537109375, 8.848291015624994],
              [-72.6654296875, 8.627587890624994],
              [-72.52573242187499, 8.489697265624997],
              [-72.41655273437499, 8.381982421874994],
              [-72.39033203125, 8.287060546874997],
              [-72.36416015625, 8.152783203124997],
              [-72.3576171875, 8.087304687499994],
              [-72.39169921874999, 8.047705078124991],
              [-72.446044921875, 7.966113281249989],
              [-72.45957031249999, 7.809863281249989],
              [-72.468896484375, 7.757958984374994],
              [-72.478955078125, 7.613232421874997],
              [-72.47197265624999, 7.524267578124991],
              [-72.44296875, 7.454882812499989],
              [-72.39462890624999, 7.415087890624989],
              [-72.296337890625, 7.39453125],
              [-72.20771484375, 7.370263671874994],
              [-72.156689453125, 7.249707031249997],
              [-72.08427734374999, 7.096875],
              [-72.00664062499999, 7.032617187499994],
              [-71.89267578124999, 6.990332031249991],
              [-71.811279296875, 7.005810546874997],
              [-71.62089843749999, 7.032910156249997],
              [-71.45712890624999, 7.0263671875],
              [-71.21782226562499, 6.985205078124991],
              [-71.12861328125, 6.98671875],
              [-71.01328125, 6.994433593749989],
              [-70.810693359375, 7.077587890624997],
              [-70.73715820312499, 7.090039062499997],
              [-70.65507812499999, 7.082763671875],
              [-70.535546875, 7.04052734375],
              [-70.470654296875, 7.007128906249989],
              [-70.3875, 6.972607421874997],
              [-70.26611328125, 6.947949218749997],
              [-70.188134765625, 6.952050781249994],
              [-70.12919921874999, 6.95361328125],
              [-70.09501953124999, 6.937939453124997],
              [-69.90419921875, 6.700244140624989],
              [-69.73896484375, 6.494384765625],
              [-69.59482421874999, 6.321484375],
              [-69.43925781249999, 6.134912109374994],
              [-69.4271484375, 6.123974609374997],
              [-69.357080078125, 6.147998046874989],
              [-69.31083984374999, 6.137597656249994],
              [-69.26816406249999, 6.099707031249991],
              [-69.19453125, 6.115332031249991],
              [-69.08994140624999, 6.184375],
              [-68.93720703125, 6.198193359374997],
              [-68.736474609375, 6.156787109374989],
              [-68.47177734374999, 6.156542968749989],
              [-68.14306640625, 6.197509765625],
              [-67.9388671875, 6.241943359375],
              [-67.85917968749999, 6.289892578124991],
              [-67.72714843749999, 6.284960937499989],
              [-67.56806640625, 6.241796875],
              [-67.48198242187499, 6.180273437499991],
              [-67.47158203125, 6.119775390624994],
              [-67.43935546875, 6.025537109374994],
              [-67.473876953125, 5.929980468749989],
              [-67.5751953125, 5.833105468749991],
              [-67.63134765625, 5.709375],
              [-67.64228515625, 5.558789062499997],
              [-67.69462890624999, 5.447509765625],
              [-67.78842773437499, 5.37548828125],
              [-67.82490234375, 5.270458984374997],
              [-67.80419921875, 5.132519531249997],
              [-67.81430664062499, 4.930810546874994],
              [-67.85527343749999, 4.665478515624997],
              [-67.85527343749999, 4.506884765624989],
              [-67.81430664062499, 4.455078125],
              [-67.79541015625, 4.380712890624991],
              [-67.79863281249999, 4.283886718749997],
              [-67.783203125, 4.1982421875],
              [-67.73232421875, 4.086523437499991],
              [-67.66162109375, 3.8642578125],
              [-67.6025390625, 3.768798828125],
              [-67.55112304687499, 3.733837890624997],
              [-67.498681640625, 3.691113281249997],
              [-67.34770507812499, 3.463769531249994],
              [-67.31113281249999, 3.415869140624991],
              [-67.32216796875, 3.373974609374997],
              [-67.33627929687499, 3.342626953124991],
              [-67.35361328124999, 3.32265625],
              [-67.51484375, 3.187255859375],
              [-67.83476562499999, 2.892822265625],
              [-67.86123046875, 2.855322265624991],
              [-67.85908203125, 2.793603515624994],
              [-67.76645507812499, 2.833300781249989],
              [-67.667236328125, 2.800195312499994],
              [-67.61870117187499, 2.793603515624994],
              [-67.5966796875, 2.769335937499989],
              [-67.568017578125, 2.68994140625],
              [-67.53496093749999, 2.6767578125],
              [-67.48642578124999, 2.643652343749991],
              [-67.3916015625, 2.559912109374991],
              [-67.312255859375, 2.4716796875],
              [-67.25273437499999, 2.429443359375],
              [-67.21083984375, 2.39013671875],
              [-67.19760742187499, 2.332763671875],
              [-67.215234375, 2.275488281249991],
              [-67.165478515625, 2.142578125],
              [-67.13144531249999, 2.101269531249997],
              [-67.11381835937499, 2.050585937499989],
              [-67.13144531249999, 1.999853515624991],
              [-67.08955078125, 1.940332031249994],
              [-67.043896484375, 1.823193359374997],
              [-66.98813476562499, 1.68017578125],
              [-66.98154296874999, 1.60078125],
              [-66.95834960937499, 1.564208984375],
              [-66.931103515625, 1.4580078125],
              [-66.88447265625, 1.358251953124991],
              [-66.8955078125, 1.289892578124991],
              [-66.87602539062499, 1.223046875],
              [-67.06523437499999, 1.178369140624994],
              [-67.082275390625, 1.185400390624991],
              [-67.09365234375, 1.210009765624989],
              [-67.08828125, 1.400585937499997],
              [-67.09013671874999, 1.615576171874991],
              [-67.11923828124999, 1.70361328125],
              [-67.205810546875, 1.844824218749991],
              [-67.32060546874999, 2.032080078124991],
              [-67.351953125, 2.085839843749994],
              [-67.40043945312499, 2.11669921875],
              [-67.457763671875, 2.121142578124989],
              [-67.499658203125, 2.10791015625],
              [-67.5560546875, 2.072998046875],
              [-67.60922851562499, 2.035058593749994],
              [-67.711865234375, 1.922119140625],
              [-67.815087890625, 1.790087890624989],
              [-67.87553710937499, 1.760595703124991],
              [-67.93623046875, 1.748486328124997],
              [-67.98974609375, 1.752539062499991],
              [-68.032861328125, 1.788037109374997],
              [-68.07705078125, 1.860107421875],
              [-68.1302734375, 1.955761718749997],
              [-68.193798828125, 1.987011718749997],
              [-68.218359375, 1.957617187499991],
              [-68.239453125, 1.9013671875],
              [-68.25595703124999, 1.845507812499989],
              [-68.21328125, 1.774560546874994],
              [-68.17656249999999, 1.719824218749991],
              [-68.23955078124999, 1.7216796875],
              [-68.44345703124999, 1.721582031249994],
              [-68.678466796875, 1.721484374999989],
              [-68.91318359374999, 1.721386718749997],
              [-69.124267578125, 1.721289062499991],
              [-69.3197265625, 1.721240234374989],
              [-69.39433593749999, 1.72578125],
              [-69.470166015625, 1.757910156249991],
              [-69.542919921875, 1.773242187499989],
              [-69.58125, 1.770751953125],
              [-69.650048828125, 1.739453125],
              [-69.73959960937499, 1.73486328125],
              [-69.799951171875, 1.705175781249991],
              [-69.848583984375, 1.708740234375],
              [-69.84946289062499, 1.543896484374997],
              [-69.85078125, 1.308789062499997],
              [-69.85214843749999, 1.059521484374997],
              [-69.79814453124999, 1.078417968749989],
              [-69.75131835937499, 1.076611328124997],
              [-69.7169921875, 1.05908203125],
              [-69.62089843749999, 1.0732421875],
              [-69.567578125, 1.065771484374991],
              [-69.51713867187499, 1.059472656249994],
              [-69.4703125, 1.05859375],
              [-69.44150390624999, 1.038818359375],
              [-69.402783203125, 1.042382812499994],
              [-69.36137695312499, 1.064013671874989],
              [-69.31181640624999, 1.050488281249997],
              [-69.25869140625, 1.015380859375],
              [-69.22446289062499, 0.963134765625],
              [-69.19384765625, 0.898291015624991],
              [-69.163232421875, 0.864062499999989],
              [-69.1650390625, 0.801953125],
              [-69.165966796875, 0.753320312499994],
              [-69.1767578125, 0.712841796874997],
              [-69.163232421875, 0.686669921874994],
              [-69.1533203125, 0.658789062499991],
              [-69.15605468749999, 0.642529296874997],
              [-69.174072265625, 0.635351562499991],
              [-69.21279296875, 0.629931640624989],
              [-69.25419921874999, 0.625439453124997],
              [-69.28300781249999, 0.627246093749989],
              [-69.30551757812499, 0.652441406249991],
              [-69.3271484375, 0.655175781249994],
              [-69.358642578125, 0.6515625],
              [-69.3919921875, 0.666894531249994],
              [-69.42080078125, 0.698388671874994],
              [-69.472119140625, 0.729931640624997],
              [-69.52705078125, 0.71640625],
              [-69.56484375, 0.7001953125],
              [-69.60361328124999, 0.680371093749997],
              [-69.63872070312499, 0.65966796875],
              [-69.673828125, 0.665087890624989],
              [-69.718896484375, 0.649804687499994],
              [-69.75673828125, 0.626367187499994],
              [-69.80712890625, 0.607470703124989],
              [-69.862060546875, 0.598486328124991],
              [-69.92509765624999, 0.589404296874989],
              [-69.98544921874999, 0.585839843749994],
              [-70.05390625, 0.57861328125],
              [-70.05791015624999, 0.447363281249991],
              [-70.06572265624999, 0.189355468749994],
              [-70.070947265625, 0.0185546875],
              [-70.0705078125, -0.138867187500011],
              [-70.04404296874999, -0.196191406250009],
              [-69.92275390625, -0.317480468750006],
              [-69.82792968749999, -0.38134765625],
              [-69.7474609375, -0.452539062500009],
              [-69.66748046875, -0.482421875],
              [-69.633984375, -0.50927734375],
              [-69.61191406249999, -0.553320312500006],
              [-69.60087890624999, -0.599609375],
              [-69.592041015625, -0.639355468750011],
              [-69.60087890624999, -0.68125],
              [-69.620703125, -0.720898437500011],
              [-69.61191406249999, -0.762792968750006],
              [-69.583251953125, -0.7958984375],
              [-69.5744140625, -0.837792968750009],
              [-69.55458984375, -0.87744140625],
              [-69.54355468749999, -0.917187500000011],
              [-69.519287109375, -0.94580078125],
              [-69.488427734375, -0.965722656250009],
              [-69.44873046875, -0.998730468750011],
              [-69.4443359375, -1.029589843750003],
              [-69.44873046875, -1.06494140625],
              [-69.44912109375, -1.091601562500003],
              [-69.41142578124999, -1.152246093750009],
              [-69.40024414062499, -1.194921875],
              [-69.41787109375, -1.245703125],
              [-69.43491210937499, -1.421679687500003],
              [-69.47861328124999, -1.621972656250009],
              [-69.50644531249999, -1.77490234375],
              [-69.55185546874999, -2.02421875],
              [-69.6046875, -2.314257812500003],
              [-69.66904296874999, -2.667675781250011],
              [-69.7326171875, -3.016699218750006],
              [-69.794140625, -3.354589843750006],
              [-69.849755859375, -3.659863281250011],
              [-69.91103515625, -3.99658203125],
              [-69.948193359375, -4.200585937500009],
              [-69.96591796874999, -4.2359375],
              [-70.0171875, -4.162011718750009],
              [-70.09477539062499, -4.0921875],
              [-70.16752929687499, -4.050195312500009],
              [-70.198388671875, -3.9951171875],
              [-70.24028320312499, -3.882714843750009],
              [-70.2984375, -3.84423828125],
              [-70.339501953125, -3.814355468750009],
              [-70.37919921874999, -3.81875],
              [-70.42109375, -3.849609375],
              [-70.48583984375, -3.869335937500011],
              [-70.5296875, -3.866406250000011],
              [-70.706201171875, -3.788964843750009],
              [-70.735107421875, -3.781542968750003],
              [-70.62167968749999, -3.604589843750006],
              [-70.41899414062499, -3.288281250000011],
              [-70.29013671874999, -3.087304687500009],
              [-70.14707031249999, -2.8640625],
              [-70.07402343749999, -2.750195312500011],
              [-70.064453125, -2.730761718750003],
              [-70.06474609374999, -2.70166015625],
              [-70.095849609375, -2.658203125],
              [-70.16474609375, -2.639843750000011],
              [-70.24443359374999, -2.606542968750006],
              [-70.29462890625, -2.552539062500003],
              [-70.36416015625, -2.529296875],
              [-70.418212890625, -2.49072265625],
              [-70.516796875, -2.453125],
              [-70.57587890625, -2.418261718750003],
              [-70.64799804687499, -2.40576171875],
              [-70.70537109374999, -2.341992187500011],
              [-70.91455078125, -2.218554687500003],
              [-70.96855468749999, -2.206835937500003],
              [-71.027294921875, -2.225781250000011],
              [-71.11337890624999, -2.245410156250003],
              [-71.19638671874999, -2.313085937500006],
              [-71.30009765624999, -2.334863281250009],
              [-71.39697265625, -2.334082031250006],
              [-71.4474609375, -2.29375],
              [-71.49609375, -2.279199218750009],
              [-71.55947265625, -2.22421875],
              [-71.671484375, -2.18212890625],
              [-71.75253906249999, -2.152734375],
              [-71.802734375, -2.166308593750003],
              [-71.86728515624999, -2.227734375000011],
              [-71.93247070312499, -2.288671875],
              [-71.98427734375, -2.3265625],
              [-72.05380859374999, -2.324609375],
              [-72.13681640624999, -2.380664062500003],
              [-72.21845703125, -2.400488281250006],
              [-72.300732421875, -2.409277343750006],
              [-72.39560546874999, -2.428906250000011],
              [-72.50068359375, -2.39501953125],
              [-72.58671874999999, -2.365136718750009],
              [-72.62534179687499, -2.351660156250006],
              [-72.66015625, -2.361035156250011],
              [-72.71416015624999, -2.3921875],
              [-72.81123046875, -2.405468750000011],
              [-72.887158203125, -2.408496093750003],
              [-72.94111328125, -2.39404296875],
              [-72.9896484375, -2.339746093750009],
              [-73.06816406249999, -2.31201171875],
              [-73.1544921875, -2.278222656250009],
              [-73.17265624999999, -2.208398437500009],
              [-73.16020507812499, -2.156347656250006],
              [-73.12651367187499, -2.0810546875],
              [-73.14521484375, -2.003320312500009],
              [-73.18149414062499, -1.88037109375],
              [-73.19697265625, -1.830273437500011],
              [-73.22397460937499, -1.787695312500006],
              [-73.26645507812499, -1.772265625],
              [-73.34951171875, -1.783886718750011],
              [-73.44028320312499, -1.737402343750006],
              [-73.4962890625, -1.693066406250011],
              [-73.52524414062499, -1.638867187500011],
              [-73.4943359375, -1.53662109375],
              [-73.52138671875, -1.44970703125],
              [-73.57548828124999, -1.4013671875],
              [-73.61025390625, -1.31640625],
              [-73.664306640625, -1.248828125],
              [-73.7357421875, -1.214160156250003],
              [-73.80717773437499, -1.217968750000011],
              [-73.86318359375, -1.196679687500009],
              [-73.926953125, -1.125195312500011],
              [-73.98681640625, -1.09814453125],
              [-74.05439453125, -1.028613281250003],
              [-74.18076171874999, -0.997753906250011],
              [-74.24638671874999, -0.970605468750009],
              [-74.28388671875, -0.927832031250006],
              [-74.334423828125, -0.850878906250003],
              [-74.32861328125, -0.808398437500003],
              [-74.353125, -0.7666015625],
              [-74.37490234375, -0.69140625],
              [-74.41787109375, -0.580664062500006],
              [-74.46518554687499, -0.517675781250006],
              [-74.5138671875, -0.470117187500009],
              [-74.555078125, -0.429882812500011],
              [-74.616357421875, -0.370019531250009],
              [-74.691650390625, -0.335253906250003],
              [-74.75537109375, -0.298632812500003],
              [-74.78046875, -0.24453125],
              [-74.8017578125, -0.200097656250009],
              [-74.8375, -0.203320312500011],
              [-74.888818359375, -0.199414062500011],
              [-74.9453125, -0.188183593750011],
              [-75.00498046874999, -0.155859375],
              [-75.0546875, -0.11669921875],
              [-75.13837890625, -0.050488281250011],
              [-75.18408203125, -0.041748046875],
              [-75.224609375, -0.041748046875],
              [-75.28447265624999, -0.106542968750006],
              [-75.46396484374999, -0.038427734375006],
              [-75.617333984375, 0.062890625],
              [-75.77666015624999, 0.089257812499994],
              [-75.87978515625, 0.150976562499991],
              [-75.974853515625, 0.247753906249997],
              [-76.02617187499999, 0.313085937499991],
              [-76.06791992187499, 0.345556640624991],
              [-76.27060546874999, 0.439404296874997],
              [-76.31103515625, 0.448486328125],
              [-76.38818359375, 0.404980468749997],
              [-76.41337890624999, 0.378857421874997],
              [-76.41796875, 0.30390625],
              [-76.42729492187499, 0.26123046875],
              [-76.49462890625, 0.235449218749991],
              [-76.60302734375, 0.240966796875],
              [-76.67851562499999, 0.268164062499991],
              [-76.72900390625, 0.272119140624994],
              [-76.73930664062499, 0.250830078124991],
              [-76.767724609375, 0.241650390624997],
              [-76.829345703125, 0.247753906249997],
              [-76.9201171875, 0.268505859374997],
              [-77.00244140625, 0.296240234374991],
              [-77.11411132812499, 0.355078125],
              [-77.16572265625, 0.347753906249991],
              [-77.29267578125, 0.360400390624989],
              [-77.39633789062499, 0.393896484374991],
              [-77.42275390625, 0.424853515624989],
              [-77.46767578125, 0.636523437499989],
              [-77.481396484375, 0.651171874999989],
              [-77.526123046875, 0.660351562499997],
              [-77.601318359375, 0.689501953124989],
              [-77.6486328125, 0.7236328125],
              [-77.67319335937499, 0.7822265625],
              [-77.702880859375, 0.837841796874997],
              [-77.829541015625, 0.825390625],
              [-78.03701171875, 0.898730468749989],
              [-78.1806640625, 0.968554687499989],
              [-78.312109375, 1.04609375],
              [-78.51152343749999, 1.198828125],
              [-78.587646484375, 1.236669921874991],
              [-78.681640625, 1.283447265625],
              [-78.73710937499999, 1.358691406249989],
              [-78.828857421875, 1.434667968749991],
              [-78.85966796874999, 1.455371093749989],
              [-78.88847656249999, 1.524072265624994],
              [-79.02543945312499, 1.623681640624994],
              [-78.957666015625, 1.752197265625],
              [-78.79296875, 1.848730468749991],
              [-78.576904296875, 1.773779296874991],
              [-78.550439453125, 1.923632812499989],
              [-78.62861328125, 2.05625],
              [-78.61704101562499, 2.306787109374994],
              [-78.59169921875, 2.356640625],
              [-78.53471679687499, 2.423681640624991],
              [-78.46044921875, 2.470068359374991],
              [-78.41689453125, 2.483496093749991],
              [-78.34287109374999, 2.460546875],
              [-78.296142578125, 2.510498046875],
              [-78.12001953125, 2.488183593749994],
              [-78.066650390625, 2.509130859374991],
              [-78.03017578125, 2.543066406249991],
              [-77.98720703125, 2.568994140624994],
              [-77.932275390625, 2.629248046874991],
              [-77.90078125, 2.698828125],
              [-77.87451171875, 2.725878906249989],
              [-77.81357421874999, 2.716357421874989],
              [-77.80795898437499, 2.746386718749989],
              [-77.77666015624999, 2.787304687499997],
              [-77.67001953124999, 2.878857421874997],
              [-77.67109375, 2.919335937499994],
              [-77.70097656249999, 3.007568359375],
              [-77.69365234374999, 3.039941406249994],
              [-77.63203125, 3.051171875],
              [-77.55913085937499, 3.075976562499989],
              [-77.520263671875, 3.160253906249991],
              [-77.47221679687499, 3.233789062499994],
              [-77.417138671875, 3.341796875],
              [-77.35654296874999, 3.348583984374997],
              [-77.3244140625, 3.474755859374994],
              [-77.24277343749999, 3.585351562499994],
              [-77.076806640625, 3.91328125],
              [-77.12685546875, 3.906054687499989],
              [-77.16660156249999, 3.862255859374997],
              [-77.21201171874999, 3.867431640625],
              [-77.26352539062499, 3.893212890624994],
              [-77.24838867187499, 4.040966796874997],
              [-77.27802734375, 4.058496093749994],
              [-77.35820312499999, 3.944726562499994],
              [-77.42729492187499, 4.060449218749994],
              [-77.433544921875, 4.130957031249991],
              [-77.4044921875, 4.20078125],
              [-77.40874023437499, 4.247753906249997],
              [-77.520703125, 4.212792968749994],
              [-77.51552734375, 4.256298828124997],
              [-77.44584960937499, 4.301025390625],
              [-77.4142578125, 4.347607421874997],
              [-77.353515625, 4.398291015624991],
              [-77.3283203125, 4.475],
              [-77.313671875, 4.593847656249991],
              [-77.286328125, 4.721728515624989],
              [-77.30654296875, 4.78466796875],
              [-77.339453125, 4.838525390624994],
              [-77.36674804687499, 5.0765625],
              [-77.35917968749999, 5.215185546874991],
              [-77.373291015625, 5.323974609375],
              [-77.4017578125, 5.416162109374994],
              [-77.534423828125, 5.537109375],
              [-77.324609375, 5.675634765624991],
              [-77.249267578125, 5.780175781249994],
              [-77.344677734375, 5.995361328125],
              [-77.46943359375, 6.1767578125],
              [-77.473046875, 6.28564453125],
              [-77.440087890625, 6.271728515625],
              [-77.39824218749999, 6.275],
              [-77.35986328125, 6.504492187499991],
              [-77.368798828125, 6.575585937499994],
              [-77.4388671875, 6.690332031249994],
              [-77.52597656249999, 6.693115234375],
              [-77.60214843749999, 6.837304687499994],
              [-77.64584960937499, 6.86962890625],
              [-77.68095703124999, 6.960400390624997],
              [-77.8037109375, 7.137255859374989],
              [-77.90117187499999, 7.229345703124991],
              [-77.8283203125, 7.442822265624997],
              [-77.76469726562499, 7.483691406249989],
              [-77.743896484375, 7.536962890624991],
              [-77.76875, 7.668066406249991],
              [-77.7619140625, 7.698828125],
              [-77.74692382812499, 7.711865234374997],
              [-77.73203125, 7.7109375],
              [-77.70634765624999, 7.691210937499989],
              [-77.65859375, 7.634619140624991],
              [-77.618603515625, 7.564550781249991],
              [-77.586572265625, 7.543066406249991],
              [-77.53828125, 7.566259765624991],
              [-77.35078125, 7.705859374999989],
              [-77.362744140625, 7.749072265624989],
              [-77.34560546875, 7.836523437499991],
              [-77.282958984375, 7.908154296874997],
              [-77.215966796875, 7.932519531249994],
              [-77.19599609375, 7.972460937499989],
              [-77.2123046875, 8.033886718749997],
              [-77.2826171875, 8.187060546874989],
              [-77.34550781249999, 8.26953125],
              [-77.385888671875, 8.351660156249991],
              [-77.40727539062499, 8.42724609375],
              [-77.478515625, 8.4984375],
              [-77.44833984374999, 8.565869140624997],
              [-77.39306640625, 8.644677734374994],
              [-77.37421875, 8.658300781249991],
              [-77.344140625, 8.63671875],
              [-77.26157226562499, 8.493701171874989],
              [-77.130126953125, 8.400585937499997],
              [-76.99228515624999, 8.250341796874991],
              [-76.93583984374999, 8.146826171874991],
              [-76.89096679687499, 8.127978515624989],
              [-76.85185546874999, 8.090478515624994],
              [-76.869091796875, 8.062695312499997],
              [-76.91220703124999, 8.033398437499997],
              [-76.92465820312499, 7.973193359374989],
              [-76.896630859375, 7.939453125],
              [-76.86689453125, 7.91796875],
              [-76.786572265625, 7.931591796874997],
              [-76.742333984375, 8.002148437499997],
              [-76.77207031249999, 8.310546875],
              [-76.818603515625, 8.464697265624991],
              [-76.872216796875, 8.512744140624989],
              [-76.92045898437499, 8.57373046875],
              [-76.88798828124999, 8.619873046875],
              [-76.80224609375, 8.640673828124989],
              [-76.68935546875, 8.694726562499994],
              [-76.27685546875, 8.989111328124991],
              [-76.135498046875, 9.265625],
              [-76.02724609375, 9.365771484374989],
              [-75.905029296875, 9.430908203125],
              [-75.75556640625, 9.415625],
              [-75.63935546875, 9.450439453125],
              [-75.60361328124999, 9.538476562499994],
              [-75.63535156249999, 9.6578125],
              [-75.68002929687499, 9.729785156249989],
              [-75.637109375, 9.834277343749989],
              [-75.59267578125, 9.992724609374989],
              [-75.5958984375, 10.125830078124991],
              [-75.53857421875, 10.205175781249991],
              [-75.55839843749999, 10.236425781249991],
              [-75.6421875, 10.172167968749989],
              [-75.70834960937499, 10.143408203124991],
              [-75.6708984375, 10.196337890624989],
              [-75.5537109375, 10.327734375],
              [-75.49277343749999, 10.527636718749989],
              [-75.44599609375, 10.610888671874989],
              [-75.28061523437499, 10.727197265624994],
              [-75.24794921875, 10.783251953124989],
              [-75.123046875, 10.870410156249989],
              [-74.92158203125, 11.057568359374997],
              [-74.84458007812499, 11.109716796874991],
              [-74.454248046875, 10.989062499999989],
              [-74.330224609375, 10.996679687499991],
              [-74.35239257812499, 10.974658203124989],
              [-74.4095703125, 10.9671875],
              [-74.49228515624999, 10.934472656249994],
              [-74.516259765625, 10.8625],
              [-74.46025390624999, 10.787060546874997],
              [-74.40087890625, 10.765234375],
              [-74.35019531249999, 10.813720703125],
              [-74.299951171875, 10.952246093749991],
              [-74.219140625, 11.105322265624991],
              [-74.2001953125, 11.265722656249991],
              [-74.14291992187499, 11.320849609374989],
              [-74.05913085937499, 11.340625],
              [-73.9095703125, 11.308886718749989],
              [-73.79570312499999, 11.275683593749989],
              [-73.676904296875, 11.271484375],
              [-73.31337890625, 11.295751953124991],
              [-72.721826171875, 11.712158203125],
              [-72.44707031249999, 11.801708984374997],
              [-72.275, 11.889257812499991],
              [-72.165234375, 12.060205078124994],
              [-72.1357421875, 12.188574218749991],
              [-72.055078125, 12.238427734374994],
              [-71.9701171875, 12.23828125],
              [-71.93125, 12.26953125],
              [-71.919140625, 12.30908203125],
              [-71.71455078125, 12.419970703124989],
              [-71.59746093749999, 12.434375],
              [-71.49399414062499, 12.432275390624994],
              [-71.262109375, 12.335302734374991],
              [-71.155029296875, 12.164160156249991],
              [-71.13730468749999, 12.046337890624997],
              [-71.2841796875, 11.918310546874991],
              [-71.3197265625, 11.861914062499991],
            ],
          ],
          [
            [
              [-78.113720703125, 2.541748046875],
              [-78.1408203125, 2.519677734374994],
              [-78.19248046874999, 2.559277343749997],
              [-78.210107421875, 2.609179687499989],
              [-78.17841796875, 2.646337890624991],
              [-78.137646484375, 2.634179687499994],
              [-78.119140625, 2.603613281249991],
              [-78.113720703125, 2.541748046875],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 4,
        sovereignt: "Paraguay",
        sov_a3: "PRY",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Paraguay",
        adm0_a3: "PRY",
        geou_dif: 0,
        geounit: "Paraguay",
        gu_a3: "PRY",
        su_dif: 0,
        subunit: "Paraguay",
        su_a3: "PRY",
        brk_diff: 0,
        name: "Paraguay",
        name_long: "Paraguay",
        brk_a3: "PRY",
        brk_name: "Paraguay",
        brk_group: null,
        abbrev: "Para.",
        postal: "PY",
        formal_en: "Republic of Paraguay",
        formal_fr: null,
        name_ciawf: "Paraguay",
        note_adm0: null,
        note_brk: null,
        name_sort: "Paraguay",
        name_alt: null,
        mapcolor7: 6,
        mapcolor8: 3,
        mapcolor9: 6,
        mapcolor13: 2,
        pop_est: 7044636,
        pop_rank: 13,
        pop_year: 2019,
        gdp_md: 38145,
        gdp_year: 2019,
        economy: "5. Emerging region: G20",
        income_grp: "4. Lower middle income",
        fips_10: "PA",
        iso_a2: "PY",
        iso_a2_eh: "PY",
        iso_a3: "PRY",
        iso_a3_eh: "PRY",
        iso_n3: "600",
        iso_n3_eh: "600",
        un_a3: "600",
        wb_a2: "PY",
        wb_a3: "PRY",
        woe_id: 23424917,
        woe_id_eh: 23424917,
        woe_note: "Exact WOE match as country",
        adm0_iso: "PRY",
        adm0_diff: null,
        adm0_tlc: "PRY",
        adm0_a3_us: "PRY",
        adm0_a3_fr: "PRY",
        adm0_a3_ru: "PRY",
        adm0_a3_es: "PRY",
        adm0_a3_cn: "PRY",
        adm0_a3_tw: "PRY",
        adm0_a3_in: "PRY",
        adm0_a3_np: "PRY",
        adm0_a3_pk: "PRY",
        adm0_a3_de: "PRY",
        adm0_a3_gb: "PRY",
        adm0_a3_br: "PRY",
        adm0_a3_il: "PRY",
        adm0_a3_ps: "PRY",
        adm0_a3_sa: "PRY",
        adm0_a3_eg: "PRY",
        adm0_a3_ma: "PRY",
        adm0_a3_pt: "PRY",
        adm0_a3_ar: "PRY",
        adm0_a3_jp: "PRY",
        adm0_a3_ko: "PRY",
        adm0_a3_vn: "PRY",
        adm0_a3_tr: "PRY",
        adm0_a3_id: "PRY",
        adm0_a3_pl: "PRY",
        adm0_a3_gr: "PRY",
        adm0_a3_it: "PRY",
        adm0_a3_nl: "PRY",
        adm0_a3_se: "PRY",
        adm0_a3_bd: "PRY",
        adm0_a3_ua: "PRY",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "South America",
        region_un: "Americas",
        subregion: "South America",
        region_wb: "Latin America & Caribbean",
        name_len: 8,
        long_len: 8,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: -60.146394,
        label_y: -21.674509,
        ne_id: 1159321195,
        wikidataid: "Q733",
        name_ar: "باراغواي",
        name_bn: "প্যারাগুয়ে",
        name_de: "Paraguay",
        name_en: "Paraguay",
        name_es: "Paraguay",
        name_fa: "پاراگوئه",
        name_fr: "Paraguay",
        name_el: "Παραγουάη",
        name_he: "פרגוואי",
        name_hi: "पैराग्वे",
        name_hu: "Paraguay",
        name_id: "Paraguay",
        name_it: "Paraguay",
        name_ja: "パラグアイ",
        name_ko: "파라과이",
        name_nl: "Paraguay",
        name_pl: "Paragwaj",
        name_pt: "Paraguai",
        name_ru: "Парагвай",
        name_sv: "Paraguay",
        name_tr: "Paraguay",
        name_uk: "Парагвай",
        name_ur: "پیراگوئے",
        name_vi: "Paraguay",
        name_zh: "巴拉圭",
        name_zht: "巴拉圭",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "PRY.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-58.159765625, -20.164648437500006],
            [-58.13779296874999, -20.2373046875],
            [-58.124609375, -20.29345703125],
            [-58.09150390625, -20.33320312500001],
            [-58.05844726562499, -20.38613281250001],
            [-58.025390625, -20.415820312500003],
            [-58.00224609374999, -20.465429687500006],
            [-58.00883789062499, -20.52167968750001],
            [-57.99560546875, -20.59443359375001],
            [-57.97905273437499, -20.657324218750006],
            [-57.9625, -20.673828125],
            [-57.91513671874999, -20.69033203125001],
            [-57.89140624999999, -20.74746093750001],
            [-57.90849609374999, -20.7763671875],
            [-57.90190429687499, -20.809375],
            [-57.88481445312499, -20.84169921875001],
            [-57.90048828124999, -20.873046875],
            [-57.892236328124994, -20.897070312500006],
            [-57.860009765624994, -20.918554687500006],
            [-57.830224609374994, -20.99794921875001],
            [-57.82695312499999, -21.13359375],
            [-57.860009765624994, -21.20625],
            [-57.886474609375, -21.26582031250001],
            [-57.89306640625, -21.30224609375],
            [-57.8732421875, -21.355078125],
            [-57.90629882812499, -21.41796875],
            [-57.94599609375, -21.49404296875001],
            [-57.93608398437499, -21.546972656250006],
            [-57.929443359375, -21.59658203125001],
            [-57.926171875, -21.649511718750006],
            [-57.9162109375, -21.69912109375001],
            [-57.929443359375, -21.751953125],
            [-57.94267578124999, -21.79833984375],
            [-57.94931640624999, -21.851171875],
            [-57.932763671874994, -21.910742187500006],
            [-57.9625, -21.96699218750001],
            [-57.97905273437499, -22.006640625],
            [-57.98569335937499, -22.04638671875],
            [-57.95590820312499, -22.109179687500003],
            [-57.879833984375, -22.13564453125001],
            [-57.8203125, -22.14228515625001],
            [-57.7640625, -22.109179687500003],
            [-57.72109375, -22.09921875],
            [-57.64169921874999, -22.129003906250006],
            [-57.56894531249999, -22.181933593750003],
            [-57.47636718749999, -22.188574218750006],
            [-57.39365234374999, -22.19843750000001],
            [-57.33085937499999, -22.21503906250001],
            [-57.238232421875, -22.19521484375001],
            [-57.142333984375, -22.21503906250001],
            [-57.02988281249999, -22.24482421875001],
            [-56.937255859375, -22.271289062500003],
            [-56.844677734375, -22.2646484375],
            [-56.77519531249999, -22.261328125],
            [-56.70244140624999, -22.231542968750006],
            [-56.6330078125, -22.23486328125],
            [-56.580078125, -22.181933593750003],
            [-56.55029296875, -22.13564453125001],
            [-56.523828125, -22.1025390625],
            [-56.44780273437499, -22.076171875],
            [-56.39487304687499, -22.09267578125001],
            [-56.35185546874999, -22.17861328125001],
            [-56.27578125, -22.22822265625001],
            [-56.246044921875, -22.2646484375],
            [-56.18984375, -22.28115234375001],
            [-56.06748046874999, -22.284472656250003],
            [-55.99140625, -22.28115234375001],
            [-55.90537109374999, -22.3076171875],
            [-55.84916992187499, -22.3076171875],
            [-55.799560546875, -22.35390625],
            [-55.75327148437499, -22.41015625],
            [-55.74663085937499, -22.5126953125],
            [-55.70366210937499, -22.592089843750003],
            [-55.647412109375, -22.621875],
            [-55.61767578125, -22.671484375],
            [-55.627587890624994, -22.74091796875001],
            [-55.654052734375, -22.810351562500003],
            [-55.65073242187499, -22.88642578125001],
            [-55.620996093749994, -22.955859375],
            [-55.620996093749994, -23.02529296875001],
            [-55.60112304687499, -23.0947265625],
            [-55.561425781249994, -23.154296875],
            [-55.54819335937499, -23.25019531250001],
            [-55.554833984374994, -23.319628906250003],
            [-55.52836914062499, -23.359375],
            [-55.518457031249994, -23.415625],
            [-55.53496093749999, -23.4619140625],
            [-55.54160156249999, -23.524707031250003],
            [-55.53828125, -23.58095703125001],
            [-55.518457031249994, -23.627246093750003],
            [-55.458886718749994, -23.68671875000001],
            [-55.4423828125, -23.792578125],
            [-55.4423828125, -23.865332031250006],
            [-55.415917968749994, -23.95136718750001],
            [-55.36630859374999, -23.991015625],
            [-55.28691406249999, -24.004296875],
            [-55.1943359375, -24.01748046875001],
            [-55.08188476562499, -23.99765625],
            [-54.982666015625, -23.97451171875001],
            [-54.92646484375, -23.95136718750001],
            [-54.817285156249994, -23.888476562500003],
            [-54.72138671875, -23.852148437500006],
            [-54.671777343749994, -23.82900390625001],
            [-54.62548828125, -23.8125],
            [-54.52958984374999, -23.852148437500006],
            [-54.44023437499999, -23.90175781250001],
            [-54.37080078125, -23.97119140625],
            [-54.241796875, -24.047265625],
            [-54.26689453124999, -24.06582031250001],
            [-54.318261718749994, -24.128125],
            [-54.317285156249994, -24.201269531250006],
            [-54.281005859375, -24.30605468750001],
            [-54.312939453125, -24.528125],
            [-54.412988281249994, -24.867480468750003],
            [-54.4541015625, -25.065234375],
            [-54.43623046875, -25.12128906250001],
            [-54.47314453125, -25.22021484375],
            [-54.610546875, -25.432714843750006],
            [-54.615869140624994, -25.57607421875001],
            [-54.63193359374999, -26.00576171875001],
            [-54.677734375, -26.30878906250001],
            [-54.755078125, -26.53291015625001],
            [-54.82548828124999, -26.65224609375001],
            [-54.888916015625, -26.666796875],
            [-54.934472656249994, -26.70253906250001],
            [-54.962158203125, -26.759375],
            [-55.013623046875, -26.806640625],
            [-55.0888671875, -26.84453125],
            [-55.129638671875, -26.886035156250003],
            [-55.1359375, -26.93115234375],
            [-55.2080078125, -26.96015625000001],
            [-55.34580078124999, -26.97314453125],
            [-55.426660156249994, -27.00927734375],
            [-55.450634765625, -27.068359375],
            [-55.496728515624994, -27.115332031250006],
            [-55.564892578125, -27.15],
            [-55.597265625, -27.207617187500006],
            [-55.59379882812499, -27.2880859375],
            [-55.63291015624999, -27.35712890625001],
            [-55.71464843749999, -27.41484375],
            [-55.789990234375, -27.41640625],
            [-55.859033203124994, -27.361914062500006],
            [-55.95146484374999, -27.32568359375],
            [-56.067333984375, -27.307714843750006],
            [-56.1640625, -27.32148437500001],
            [-56.24169921875, -27.366796875],
            [-56.310546875, -27.438769531250003],
            [-56.370507812499994, -27.537402343750003],
            [-56.437158203124994, -27.553808593750006],
            [-56.51054687499999, -27.487890625],
            [-56.60336914062499, -27.467871093750006],
            [-56.715722656249994, -27.49375],
            [-56.80517578125, -27.484667968750003],
            [-56.871728515624994, -27.440625],
            [-56.97397460937499, -27.43574218750001],
            [-57.11181640625, -27.47011718750001],
            [-57.391259765624994, -27.43046875],
            [-57.81220703125, -27.31660156250001],
            [-58.16826171874999, -27.2734375],
            [-58.60483398437499, -27.31435546875001],
            [-58.641748046874994, -27.19609375],
            [-58.618603515625, -27.132128906250003],
            [-58.54770507812499, -27.083984375],
            [-58.50322265624999, -27.02949218750001],
            [-58.485253906249994, -26.96845703125001],
            [-58.43632812499999, -26.921972656250006],
            [-58.3564453125, -26.89003906250001],
            [-58.32255859374999, -26.85761718750001],
            [-58.33466796875, -26.82490234375001],
            [-58.31767578124999, -26.7958984375],
            [-58.2716796875, -26.77070312500001],
            [-58.245556640625, -26.731054687500006],
            [-58.23935546874999, -26.676855468750006],
            [-58.222070312499994, -26.65],
            [-58.191308593749994, -26.629980468750006],
            [-58.187939453125, -26.592578125],
            [-58.20517578124999, -26.4765625],
            [-58.203027343749994, -26.381445312500006],
            [-58.18149414062499, -26.307421875],
            [-58.1546875, -26.26259765625001],
            [-58.135644531249994, -26.25146484375],
            [-58.118066406249994, -26.224902343750003],
            [-58.11113281249999, -26.18017578125],
            [-58.082421875, -26.13857421875001],
            [-57.943115234375, -26.05292968750001],
            [-57.890625, -26.00654296875001],
            [-57.88623046875, -25.96425781250001],
            [-57.865234375, -25.90693359375001],
            [-57.782470703125, -25.78369140625],
            [-57.757080078125, -25.72597656250001],
            [-57.754785156249994, -25.697070312500003],
            [-57.725488281249994, -25.66718750000001],
            [-57.62583007812499, -25.598730468750006],
            [-57.571679687499994, -25.5341796875],
            [-57.563134765624994, -25.473730468750006],
            [-57.587158203125, -25.405078125],
            [-57.64389648437499, -25.328417968750003],
            [-57.821679687499994, -25.13642578125001],
            [-57.95981445312499, -25.04921875],
            [-58.136474609375, -24.977148437500006],
            [-58.25278320312499, -24.95380859375001],
            [-58.30869140624999, -24.979101562500006],
            [-58.365380859374994, -24.959277343750003],
            [-58.422802734375, -24.894140625],
            [-58.51962890624999, -24.842871093750006],
            [-58.724023437499994, -24.78662109375],
            [-59.187255859375, -24.562304687500003],
            [-59.372949218749994, -24.45390625],
            [-59.43540039062499, -24.387011718750003],
            [-59.60859375, -24.26679687500001],
            [-59.892480468749994, -24.093554687500003],
            [-60.110302734375, -24.00917968750001],
            [-60.26220703125, -24.013964843750003],
            [-60.50537109375, -23.96357421875001],
            [-60.83984375, -23.85810546875001],
            [-61.03291015625, -23.755664062500003],
            [-61.084716796875, -23.65644531250001],
            [-61.208398437499994, -23.55703125],
            [-61.403955078124994, -23.45751953125],
            [-61.505517578124994, -23.39199218750001],
            [-61.51303710937499, -23.360449218750006],
            [-61.57099609375, -23.319433593750006],
            [-61.67949218749999, -23.26875],
            [-61.79853515625, -23.18203125],
            [-61.92802734374999, -23.05927734375001],
            [-62.0666015625, -22.869433593750003],
            [-62.21416015624999, -22.612402343750006],
            [-62.372509765625, -22.43916015625001],
            [-62.54155273437499, -22.349609375],
            [-62.6259765625, -22.29042968750001],
            [-62.62568359375, -22.261523437500003],
            [-62.65097656249999, -22.233691406250003],
            [-62.628515625, -22.183984375],
            [-62.56694335937499, -21.988671875],
            [-62.47783203124999, -21.70527343750001],
            [-62.38544921875, -21.41171875],
            [-62.27666015624999, -21.066015625],
            [-62.276513671874994, -20.82080078125],
            [-62.276318359375, -20.5625],
            [-62.12163085937499, -20.349902343750003],
            [-62.01181640624999, -20.199023437500003],
            [-61.916943359375, -20.05537109375001],
            [-61.82089843749999, -19.80947265625001],
            [-61.7568359375, -19.6453125],
            [-61.51181640624999, -19.6064453125],
            [-61.09599609374999, -19.52099609375],
            [-60.88876953124999, -19.478515625],
            [-60.451611328125, -19.388769531250006],
            [-60.00737304687499, -19.29755859375001],
            [-59.54086914062499, -19.291796875],
            [-59.09052734375, -19.286230468750006],
            [-58.741113281249994, -19.490234375],
            [-58.47421875, -19.64609375],
            [-58.18017578125, -19.81787109375],
            [-58.160058593749994, -19.85488281250001],
            [-58.13994140624999, -19.998828125],
            [-58.159765625, -20.164648437500006],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 5,
        sovereignt: "Nicaragua",
        sov_a3: "NIC",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Nicaragua",
        adm0_a3: "NIC",
        geou_dif: 0,
        geounit: "Nicaragua",
        gu_a3: "NIC",
        su_dif: 0,
        subunit: "Nicaragua",
        su_a3: "NIC",
        brk_diff: 0,
        name: "Nicaragua",
        name_long: "Nicaragua",
        brk_a3: "NIC",
        brk_name: "Nicaragua",
        brk_group: null,
        abbrev: "Nic.",
        postal: "NI",
        formal_en: "Republic of Nicaragua",
        formal_fr: null,
        name_ciawf: "Nicaragua",
        note_adm0: null,
        note_brk: null,
        name_sort: "Nicaragua",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 4,
        mapcolor9: 1,
        mapcolor13: 9,
        pop_est: 6545502,
        pop_rank: 13,
        pop_year: 2019,
        gdp_md: 12520,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        fips_10: "NU",
        iso_a2: "NI",
        iso_a2_eh: "NI",
        iso_a3: "NIC",
        iso_a3_eh: "NIC",
        iso_n3: "558",
        iso_n3_eh: "558",
        un_a3: "558",
        wb_a2: "NI",
        wb_a3: "NIC",
        woe_id: 23424915,
        woe_id_eh: 23424915,
        woe_note: "Exact WOE match as country",
        adm0_iso: "NIC",
        adm0_diff: null,
        adm0_tlc: "NIC",
        adm0_a3_us: "NIC",
        adm0_a3_fr: "NIC",
        adm0_a3_ru: "NIC",
        adm0_a3_es: "NIC",
        adm0_a3_cn: "NIC",
        adm0_a3_tw: "NIC",
        adm0_a3_in: "NIC",
        adm0_a3_np: "NIC",
        adm0_a3_pk: "NIC",
        adm0_a3_de: "NIC",
        adm0_a3_gb: "NIC",
        adm0_a3_br: "NIC",
        adm0_a3_il: "NIC",
        adm0_a3_ps: "NIC",
        adm0_a3_sa: "NIC",
        adm0_a3_eg: "NIC",
        adm0_a3_ma: "NIC",
        adm0_a3_pt: "NIC",
        adm0_a3_ar: "NIC",
        adm0_a3_jp: "NIC",
        adm0_a3_ko: "NIC",
        adm0_a3_vn: "NIC",
        adm0_a3_tr: "NIC",
        adm0_a3_id: "NIC",
        adm0_a3_pl: "NIC",
        adm0_a3_gr: "NIC",
        adm0_a3_it: "NIC",
        adm0_a3_nl: "NIC",
        adm0_a3_se: "NIC",
        adm0_a3_bd: "NIC",
        adm0_a3_ua: "NIC",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "North America",
        region_un: "Americas",
        subregion: "Central America",
        region_wb: "Latin America & Caribbean",
        name_len: 9,
        long_len: 9,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: -85.069347,
        label_y: 12.670697,
        ne_id: 1159321091,
        wikidataid: "Q811",
        name_ar: "نيكاراغوا",
        name_bn: "নিকারাগুয়া",
        name_de: "Nicaragua",
        name_en: "Nicaragua",
        name_es: "Nicaragua",
        name_fa: "نیکاراگوئه",
        name_fr: "Nicaragua",
        name_el: "Νικαράγουα",
        name_he: "ניקרגואה",
        name_hi: "निकारागुआ",
        name_hu: "Nicaragua",
        name_id: "Nikaragua",
        name_it: "Nicaragua",
        name_ja: "ニカラグア",
        name_ko: "니카라과",
        name_nl: "Nicaragua",
        name_pl: "Nikaragua",
        name_pt: "Nicarágua",
        name_ru: "Никарагуа",
        name_sv: "Nicaragua",
        name_tr: "Nikaragua",
        name_uk: "Нікарагуа",
        name_ur: "نکاراگوا",
        name_vi: "Nicaragua",
        name_zh: "尼加拉瓜",
        name_zht: "尼加拉瓜",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "NIC.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.15751953124999, 14.993066406249994],
            [-83.18535156249999, 14.956396484374991],
            [-83.21591796874999, 14.932373046875],
            [-83.27988281249999, 14.812792968750003],
            [-83.302001953125, 14.802099609374991],
            [-83.30634765625, 14.890527343749994],
            [-83.344384765625, 14.902099609375],
            [-83.38901367187499, 14.870654296875003],
            [-83.413720703125, 14.825341796874994],
            [-83.37485351562499, 14.76611328125],
            [-83.34072265625, 14.765283203124994],
            [-83.29921875, 14.7490234375],
            [-83.187744140625, 14.340087890625],
            [-83.21171874999999, 14.267138671875003],
            [-83.28081054687499, 14.153613281250003],
            [-83.34658203125, 14.056982421874991],
            [-83.4123046875, 13.996484375],
            [-83.49375, 13.738818359375003],
            [-83.567333984375, 13.3203125],
            [-83.51445312499999, 12.943945312499991],
            [-83.5412109375, 12.596289062499991],
            [-83.51796875, 12.514111328124997],
            [-83.5109375, 12.411816406249997],
            [-83.56523437499999, 12.393408203124991],
            [-83.5958984375, 12.396484375],
            [-83.627197265625, 12.459326171874991],
            [-83.623681640625, 12.514550781249994],
            [-83.5912109375, 12.579345703125],
            [-83.578076171875, 12.667138671874994],
            [-83.593359375, 12.713085937499997],
            [-83.62534179687499, 12.612890625],
            [-83.681640625, 12.568115234375],
            [-83.718359375, 12.552636718749994],
            [-83.75424804687499, 12.501953125],
            [-83.7162109375, 12.40673828125],
            [-83.66733398437499, 12.337060546874994],
            [-83.65126953125, 12.287060546874997],
            [-83.66923828124999, 12.2275390625],
            [-83.680419921875, 12.024316406249994],
            [-83.697705078125, 12.029980468749997],
            [-83.715576171875, 12.057421874999989],
            [-83.7671875, 12.059277343749997],
            [-83.77333984375, 11.977392578124991],
            [-83.76933593749999, 11.931640625],
            [-83.81318359375, 11.896386718749994],
            [-83.82890624999999, 11.861035156249997],
            [-83.79296875, 11.836181640625],
            [-83.753369140625, 11.8212890625],
            [-83.70458984375, 11.824560546874991],
            [-83.664306640625, 11.723876953125],
            [-83.6517578125, 11.642041015624997],
            [-83.74497070312499, 11.566503906249991],
            [-83.776611328125, 11.503955078124989],
            [-83.82939453124999, 11.428173828124997],
            [-83.85908203125, 11.353662109374994],
            [-83.86787109375, 11.300048828125],
            [-83.83183593749999, 11.130517578124994],
            [-83.76791992187499, 11.01025390625],
            [-83.7140625, 10.933837890625],
            [-83.6419921875, 10.917236328125],
            [-83.658935546875, 10.836865234374997],
            [-83.71293945312499, 10.785888671875],
            [-83.811181640625, 10.743261718749991],
            [-83.91928710937499, 10.7353515625],
            [-84.09619140625, 10.775683593749989],
            [-84.168359375, 10.780371093749991],
            [-84.19658203124999, 10.801708984374997],
            [-84.20498046875, 10.84130859375],
            [-84.25556640625, 10.900732421874991],
            [-84.348291015625, 10.979882812499994],
            [-84.40185546875, 10.974462890624991],
            [-84.48916015625, 10.991650390624997],
            [-84.6341796875, 11.045605468749997],
            [-84.701171875, 11.052197265624997],
            [-84.79736328125, 11.005908203124989],
            [-84.9091796875, 10.9453125],
            [-85.178955078125, 11.039941406249994],
            [-85.368359375, 11.1064453125],
            [-85.538720703125, 11.166308593749989],
            [-85.5841796875, 11.189453125],
            [-85.62138671874999, 11.184472656249994],
            [-85.65366210937499, 11.153076171875],
            [-85.69052734374999, 11.097460937499989],
            [-85.70263671875, 11.08154296875],
            [-85.722265625, 11.066259765624991],
            [-85.7443359375, 11.062109375],
            [-85.74521484374999, 11.088574218749997],
            [-85.828515625, 11.19873046875],
            [-85.9611328125, 11.331347656249989],
            [-86.468896484375, 11.73828125],
            [-86.655517578125, 11.981542968749991],
            [-86.755615234375, 12.156640625],
            [-86.8509765625, 12.247753906249997],
            [-87.1251953125, 12.434130859374989],
            [-87.188427734375, 12.508349609374989],
            [-87.46015625, 12.757568359375],
            [-87.66752929687499, 12.903564453125],
            [-87.670166015625, 12.965673828124991],
            [-87.58505859374999, 13.043310546874991],
            [-87.54331054687499, 13.039697265624994],
            [-87.49794921875, 12.984179687499989],
            [-87.42436523437499, 12.921142578125],
            [-87.3896484375, 12.920654296875],
            [-87.33857421875, 12.949951171875],
            [-87.33725585937499, 12.979248046875],
            [-87.05917968749999, 12.991455078125],
            [-87.00932617187499, 13.0078125],
            [-86.95888671875, 13.0537109375],
            [-86.93315429687499, 13.117529296874991],
            [-86.92880859374999, 13.179394531249997],
            [-86.918212890625, 13.223583984374997],
            [-86.87353515625, 13.266503906249994],
            [-86.792138671875, 13.27978515625],
            [-86.72929687499999, 13.284375],
            [-86.710693359375, 13.313378906249994],
            [-86.72958984374999, 13.4072265625],
            [-86.76352539062499, 13.63525390625],
            [-86.77060546874999, 13.69873046875],
            [-86.758984375, 13.746142578125003],
            [-86.733642578125, 13.763476562500003],
            [-86.61025390625, 13.774853515624997],
            [-86.376953125, 13.755664062500003],
            [-86.33173828125, 13.770068359375003],
            [-86.238232421875, 13.899462890625003],
            [-86.15122070312499, 13.994580078124997],
            [-86.0892578125, 14.037207031249991],
            [-86.04038085937499, 14.050146484374991],
            [-85.9837890625, 13.965673828124991],
            [-85.78671875, 13.844433593749997],
            [-85.75341796875, 13.85205078125],
            [-85.73393554687499, 13.858691406250003],
            [-85.727734375, 13.876074218749991],
            [-85.731201171875, 13.931835937499997],
            [-85.68193359374999, 13.982568359374994],
            [-85.57978515625, 14.028222656249994],
            [-85.47705078125, 14.108691406250003],
            [-85.373779296875, 14.223876953125],
            [-85.2841796875, 14.291650390624994],
            [-85.20834960937499, 14.311816406250003],
            [-85.17949218749999, 14.343310546875003],
            [-85.19755859374999, 14.385986328125],
            [-85.19150390624999, 14.446630859374991],
            [-85.161328125, 14.525146484375],
            [-85.11728515624999, 14.570605468750003],
            [-85.059375, 14.582958984374997],
            [-85.0365234375, 14.607666015625],
            [-85.04863281249999, 14.644726562499997],
            [-85.037353515625, 14.685546875],
            [-84.98515624999999, 14.75244140625],
            [-84.86044921874999, 14.809765625],
            [-84.78916015624999, 14.790380859374991],
            [-84.72978515624999, 14.71337890625],
            [-84.645947265625, 14.661083984374997],
            [-84.53764648437499, 14.633398437499991],
            [-84.453564453125, 14.643701171874994],
            [-84.39365234374999, 14.691748046874991],
            [-84.339794921875, 14.706347656250003],
            [-84.291943359375, 14.687353515624991],
            [-84.26665039062499, 14.698144531249994],
            [-84.26396484374999, 14.738525390625],
            [-84.239208984375, 14.747851562500003],
            [-84.1923828125, 14.726025390624997],
            [-84.15078125, 14.720410156249997],
            [-84.114404296875, 14.731005859375003],
            [-84.10029296875, 14.750634765624994],
            [-84.09296875, 14.770898437499994],
            [-84.0658203125, 14.786083984374997],
            [-83.972265625, 14.77109375],
            [-83.86728515624999, 14.794482421875003],
            [-83.750927734375, 14.85625],
            [-83.67363281249999, 14.883544921875],
            [-83.635498046875, 14.876416015624997],
            [-83.58974609375, 14.907568359374991],
            [-83.5365234375, 14.977001953124997],
            [-83.4150390625, 15.008056640625],
            [-83.15751953124999, 14.993066406249994],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 5,
        sovereignt: "United Kingdom",
        sov_a3: "GB1",
        adm0_dif: 1,
        level: 2,
        type: "Disputed",
        tlc: "1",
        admin: "Falkland Islands",
        adm0_a3: "FLK",
        geou_dif: 0,
        geounit: "Falkland Islands",
        gu_a3: "FLK",
        su_dif: 0,
        subunit: "Falkland Islands",
        su_a3: "FLK",
        brk_diff: 1,
        name: "Falkland Is.",
        name_long: "Falkland Islands / Malvinas",
        brk_a3: "B12",
        brk_name: "Falkland Is.",
        brk_group: null,
        abbrev: "Flk. Is.",
        postal: "FK",
        formal_en: "Falkland Islands",
        formal_fr: null,
        name_ciawf: "Falkland Islands (Islas Malvinas)",
        note_adm0: "U.K.",
        note_brk: "Admin. by U.K.; Claimed by Argentina",
        name_sort: "Falkland Islands",
        name_alt: "Islas Malvinas",
        mapcolor7: 6,
        mapcolor8: 6,
        mapcolor9: 6,
        mapcolor13: 3,
        pop_est: 3398,
        pop_rank: 4,
        pop_year: 2016,
        gdp_md: 282,
        gdp_year: 2012,
        economy: "2. Developed region: nonG7",
        income_grp: "1. High income: OECD",
        fips_10: "FK",
        iso_a2: "FK",
        iso_a2_eh: "FK",
        iso_a3: "FLK",
        iso_a3_eh: "FLK",
        iso_n3: "238",
        iso_n3_eh: "238",
        un_a3: "238",
        wb_a2: "-99",
        wb_a3: "-99",
        woe_id: 23424814,
        woe_id_eh: 23424814,
        woe_note: "Exact WOE match as country",
        adm0_iso: "B12",
        adm0_diff: null,
        adm0_tlc: "B12",
        adm0_a3_us: "FLK",
        adm0_a3_fr: "FLK",
        adm0_a3_ru: "FLK",
        adm0_a3_es: "FLK",
        adm0_a3_cn: "FLK",
        adm0_a3_tw: "FLK",
        adm0_a3_in: "FLK",
        adm0_a3_np: "FLK",
        adm0_a3_pk: "FLK",
        adm0_a3_de: "FLK",
        adm0_a3_gb: "FLK",
        adm0_a3_br: "FLK",
        adm0_a3_il: "FLK",
        adm0_a3_ps: "FLK",
        adm0_a3_sa: "FLK",
        adm0_a3_eg: "FLK",
        adm0_a3_ma: "FLK",
        adm0_a3_pt: "FLK",
        adm0_a3_ar: "ARG",
        adm0_a3_jp: "FLK",
        adm0_a3_ko: "FLK",
        adm0_a3_vn: "FLK",
        adm0_a3_tr: "FLK",
        adm0_a3_id: "FLK",
        adm0_a3_pl: "FLK",
        adm0_a3_gr: "FLK",
        adm0_a3_it: "FLK",
        adm0_a3_nl: "FLK",
        adm0_a3_se: "FLK",
        adm0_a3_bd: "FLK",
        adm0_a3_ua: "FLK",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "South America",
        region_un: "Americas",
        subregion: "South America",
        region_wb: "Latin America & Caribbean",
        name_len: 12,
        long_len: 27,
        abbrev_len: 8,
        tiny: -99,
        homepart: -99,
        min_zoom: 0,
        min_label: 4.5,
        max_label: 9,
        label_x: -58.738602,
        label_y: -51.608913,
        ne_id: 1159320711,
        wikidataid: "Q9648",
        name_ar: "جزر فوكلاند",
        name_bn: "ফকল্যান্ড দ্বীপপুঞ্জ",
        name_de: "Falklandinseln",
        name_en: "Falkland Islands",
        name_es: "Islas Malvinas",
        name_fa: "جزایر فالکلند",
        name_fr: "îles Malouines",
        name_el: "Νήσοι Φώκλαντ",
        name_he: "איי פוקלנד",
        name_hi: "फ़ॉकलैंड द्वीपसमूह",
        name_hu: "Falkland-szigetek",
        name_id: "Kepulauan Falkland",
        name_it: "Isole Falkland",
        name_ja: "フォークランド諸島",
        name_ko: "포클랜드 제도",
        name_nl: "Falklandeilanden",
        name_pl: "Falklandy",
        name_pt: "Ilhas Malvinas",
        name_ru: "Фолклендские острова",
        name_sv: "Falklandsöarna",
        name_tr: "Falkland Adaları",
        name_uk: "Фолклендські острови",
        name_ur: "جزائر فاکلینڈ",
        name_vi: "Quần đảo Falkland",
        name_zh: "福克兰群岛",
        name_zht: "福克蘭群島",
        fclass_iso: "Admin-0 dependency",
        tlc_diff: null,
        fclass_tlc: "Admin-0 dependency",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: "Unrecognized",
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "FLK.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-58.85019531249999, -51.269921875],
              [-58.697509765625, -51.328515625],
              [-58.50625, -51.30810546875],
              [-58.42583007812499, -51.32421875],
              [-58.37871093749999, -51.373046875],
              [-58.40673828125, -51.418359375],
              [-58.467431640624994, -51.41181640625001],
              [-58.51923828125, -51.423925781250006],
              [-58.508935546874994, -51.48359375000001],
              [-58.47373046874999, -51.50908203125002],
              [-58.27158203124999, -51.57470703125],
              [-58.234521484374994, -51.57861328125],
              [-58.241113281249994, -51.55107421875002],
              [-58.27622070312499, -51.50605468750001],
              [-58.289306640625, -51.45751953125],
              [-58.259228515625, -51.417089843750006],
              [-58.206445312499994, -51.4046875],
              [-57.976513671875, -51.384375],
              [-57.922509765624994, -51.40351562500001],
              [-57.808496093749994, -51.51796875],
              [-57.915429687499994, -51.533789062500006],
              [-57.96044921875, -51.58320312500001],
              [-57.866357421874994, -51.604589843750006],
              [-57.79179687499999, -51.636132812499994],
              [-57.83115234374999, -51.6845703125],
              [-57.83818359374999, -51.70917968750001],
              [-58.00395507812499, -51.74345703125002],
              [-58.15092773437499, -51.76542968750002],
              [-58.21762695312499, -51.82246093750001],
              [-58.33598632812499, -51.86376953125],
              [-58.683496093749994, -51.93623046875001],
              [-58.64306640625, -51.99482421875001],
              [-58.6376953125, -52.023046875],
              [-58.652783203125, -52.09921875],
              [-59.13125, -52.007910156250006],
              [-59.19584960937499, -52.017675781250006],
              [-59.068017578124994, -52.17304687500001],
              [-59.16279296875, -52.201757812500006],
              [-59.25634765625, -52.18310546875],
              [-59.34150390625, -52.19599609375001],
              [-59.395654296874994, -52.308007812499994],
              [-59.5322265625, -52.236425781250006],
              [-59.64873046874999, -52.134375],
              [-59.649169921875, -52.077246093750006],
              [-59.53666992187499, -51.970605468749994],
              [-59.57080078125, -51.925390625],
              [-59.308740234374994, -51.78046875000001],
              [-59.26176757812499, -51.7373046875],
              [-59.18002929687499, -51.7125],
              [-59.09541015625, -51.7041015625],
              [-59.059521484375, -51.685449218749994],
              [-59.065380859375, -51.65019531250002],
              [-59.09946289062499, -51.589746093749994],
              [-59.096630859375, -51.49140625000001],
              [-58.886669921875, -51.35791015625],
              [-58.91748046875, -51.272070312500006],
              [-58.85019531249999, -51.269921875],
            ],
          ],
          [
            [
              [-60.28623046874999, -51.4619140625],
              [-60.141552734375, -51.48095703125],
              [-60.008691406249994, -51.410546875],
              [-59.91708984374999, -51.388085937499994],
              [-59.84160156249999, -51.4033203125],
              [-59.78842773437499, -51.44599609375001],
              [-59.711328125, -51.43925781250002],
              [-59.49345703124999, -51.39570312500001],
              [-59.465087890625, -51.410546875],
              [-59.387597656249994, -51.359960937500006],
              [-59.32084960937499, -51.38359375000002],
              [-59.26806640625, -51.42753906250002],
              [-59.2939453125, -51.478515625],
              [-59.35419921875, -51.51093750000001],
              [-59.39243164062499, -51.55615234375],
              [-59.43701171875, -51.592675781249994],
              [-59.51420898437499, -51.6265625],
              [-59.573193359375, -51.68085937500001],
              [-59.71489257812499, -51.807714843750006],
              [-59.92138671875, -51.96953125000002],
              [-59.98974609375, -51.98408203125001],
              [-60.132275390625, -51.99384765625001],
              [-60.19375, -51.98271484375002],
              [-60.246337890625, -51.986425781250006],
              [-60.28828125, -52.07373046875],
              [-60.353466796875, -52.13994140625002],
              [-60.384228515625, -52.15400390625001],
              [-60.45200195312499, -52.160253906250006],
              [-60.48408203125, -52.1703125],
              [-60.50839843749999, -52.194726562499994],
              [-60.68637695312499, -52.188378906249994],
              [-60.81220703125, -52.14775390625002],
              [-60.96142578125, -52.05732421875001],
              [-60.7625, -51.94648437500001],
              [-60.591064453125, -51.9515625],
              [-60.44975585937499, -51.87714843750001],
              [-60.33447265625, -51.83955078125001],
              [-60.288671875, -51.80126953125],
              [-60.2384765625, -51.77197265625],
              [-60.23813476562499, -51.733789062499994],
              [-60.276513671874994, -51.71660156250002],
              [-60.3283203125, -51.718359375],
              [-60.37958984375, -51.73515625000002],
              [-60.50009765624999, -51.75654296875001],
              [-60.58251953125, -51.71269531250002],
              [-60.528076171875, -51.696386718750006],
              [-60.467236328125, -51.697167968749994],
              [-60.28095703125, -51.65605468750002],
              [-60.24516601562499, -51.63886718750001],
              [-60.302636718749994, -51.58046875],
              [-60.414941406249994, -51.545019531250006],
              [-60.505810546875, -51.485449218750006],
              [-60.52275390624999, -51.46318359375002],
              [-60.51826171875, -51.427832031250006],
              [-60.56845703124999, -51.3578125],
              [-60.51572265624999, -51.35429687500002],
              [-60.445458984374994, -51.3994140625],
              [-60.28623046874999, -51.4619140625],
            ],
          ],
          [
            [
              [-61.01875, -51.785742187500006],
              [-60.947265625, -51.79951171875001],
              [-60.8759765625, -51.79423828125002],
              [-60.916162109374994, -51.89697265625],
              [-60.94755859374999, -51.9462890625],
              [-61.031982421875, -51.942480468750006],
              [-61.11577148437499, -51.87529296875002],
              [-61.14501953125, -51.839453125],
              [-61.051660156249994, -51.81396484375],
              [-61.01875, -51.785742187500006],
            ],
          ],
          [
            [
              [-58.43881835937499, -52.01103515625002],
              [-58.43271484374999, -52.099023437499994],
              [-58.512841796874994, -52.07109375000002],
              [-58.54140625, -52.028417968750006],
              [-58.4970703125, -51.999414062499994],
              [-58.460546875, -52.0015625],
              [-58.43881835937499, -52.01103515625002],
            ],
          ],
          [
            [
              [-60.11171875, -51.395898437499994],
              [-60.24882812499999, -51.39599609375],
              [-60.27587890625, -51.363183593749994],
              [-60.275341796875, -51.28056640625002],
              [-60.17138671875, -51.2734375],
              [-60.06982421875, -51.30791015625002],
              [-60.07646484374999, -51.34257812500002],
              [-60.11171875, -51.395898437499994],
            ],
          ],
          [
            [
              [-59.68266601562499, -52.23164062500001],
              [-59.74658203125, -52.250878906249994],
              [-59.76445312499999, -52.2421875],
              [-59.78486328125, -52.2046875],
              [-59.78593749999999, -52.156152343749994],
              [-59.79331054687499, -52.134179687499994],
              [-59.75322265624999, -52.14140625000002],
              [-59.68100585937499, -52.180078125],
              [-59.68266601562499, -52.23164062500001],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 5,
        sovereignt: "Costa Rica",
        sov_a3: "CRI",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Costa Rica",
        adm0_a3: "CRI",
        geou_dif: 0,
        geounit: "Costa Rica",
        gu_a3: "CRI",
        su_dif: 0,
        subunit: "Costa Rica",
        su_a3: "CRI",
        brk_diff: 0,
        name: "Costa Rica",
        name_long: "Costa Rica",
        brk_a3: "CRI",
        brk_name: "Costa Rica",
        brk_group: null,
        abbrev: "C.R.",
        postal: "CR",
        formal_en: "Republic of Costa Rica",
        formal_fr: null,
        name_ciawf: "Costa Rica",
        note_adm0: null,
        note_brk: null,
        name_sort: "Costa Rica",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 2,
        mapcolor9: 4,
        mapcolor13: 2,
        pop_est: 5047561,
        pop_rank: 13,
        pop_year: 2019,
        gdp_md: 61801,
        gdp_year: 2019,
        economy: "5. Emerging region: G20",
        income_grp: "3. Upper middle income",
        fips_10: "CS",
        iso_a2: "CR",
        iso_a2_eh: "CR",
        iso_a3: "CRI",
        iso_a3_eh: "CRI",
        iso_n3: "188",
        iso_n3_eh: "188",
        un_a3: "188",
        wb_a2: "CR",
        wb_a3: "CRI",
        woe_id: 23424791,
        woe_id_eh: 23424791,
        woe_note: "Exact WOE match as country",
        adm0_iso: "CRI",
        adm0_diff: null,
        adm0_tlc: "CRI",
        adm0_a3_us: "CRI",
        adm0_a3_fr: "CRI",
        adm0_a3_ru: "CRI",
        adm0_a3_es: "CRI",
        adm0_a3_cn: "CRI",
        adm0_a3_tw: "CRI",
        adm0_a3_in: "CRI",
        adm0_a3_np: "CRI",
        adm0_a3_pk: "CRI",
        adm0_a3_de: "CRI",
        adm0_a3_gb: "CRI",
        adm0_a3_br: "CRI",
        adm0_a3_il: "CRI",
        adm0_a3_ps: "CRI",
        adm0_a3_sa: "CRI",
        adm0_a3_eg: "CRI",
        adm0_a3_ma: "CRI",
        adm0_a3_pt: "CRI",
        adm0_a3_ar: "CRI",
        adm0_a3_jp: "CRI",
        adm0_a3_ko: "CRI",
        adm0_a3_vn: "CRI",
        adm0_a3_tr: "CRI",
        adm0_a3_id: "CRI",
        adm0_a3_pl: "CRI",
        adm0_a3_gr: "CRI",
        adm0_a3_it: "CRI",
        adm0_a3_nl: "CRI",
        adm0_a3_se: "CRI",
        adm0_a3_bd: "CRI",
        adm0_a3_ua: "CRI",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "North America",
        region_un: "Americas",
        subregion: "Central America",
        region_wb: "Latin America & Caribbean",
        name_len: 10,
        long_len: 10,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 2.5,
        max_label: 8,
        label_x: -84.077922,
        label_y: 10.0651,
        ne_id: 1159320525,
        wikidataid: "Q800",
        name_ar: "كوستاريكا",
        name_bn: "কোস্টা রিকা",
        name_de: "Costa Rica",
        name_en: "Costa Rica",
        name_es: "Costa Rica",
        name_fa: "کاستاریکا",
        name_fr: "Costa Rica",
        name_el: "Κόστα Ρίκα",
        name_he: "קוסטה ריקה",
        name_hi: "कोस्टा रीका",
        name_hu: "Costa Rica",
        name_id: "Kosta Rika",
        name_it: "Costa Rica",
        name_ja: "コスタリカ",
        name_ko: "코스타리카",
        name_nl: "Costa Rica",
        name_pl: "Kostaryka",
        name_pt: "Costa Rica",
        name_ru: "Коста-Рика",
        name_sv: "Costa Rica",
        name_tr: "Kosta Rika",
        name_uk: "Коста-Рика",
        name_ur: "کوسٹاریکا",
        name_vi: "Costa Rica",
        name_zh: "哥斯达黎加",
        name_zht: "哥斯大黎加",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "CRI.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.56357421874999, 9.57666015625],
            [-82.56923828125, 9.558203125],
            [-82.58652343749999, 9.538818359375],
            [-82.611279296875, 9.519238281249997],
            [-82.64409179687499, 9.505859375],
            [-82.723388671875, 9.54609375],
            [-82.801025390625, 9.591796875],
            [-82.843994140625, 9.57080078125],
            [-82.86015624999999, 9.511474609375],
            [-82.88896484374999, 9.481005859374989],
            [-82.925048828125, 9.469042968749989],
            [-82.93984375, 9.449169921874997],
            [-82.942822265625, 9.248876953124991],
            [-82.94033203125, 9.060107421874989],
            [-82.88134765625, 9.055859375],
            [-82.78305664062499, 8.990283203124989],
            [-82.741162109375, 8.951708984374989],
            [-82.72783203124999, 8.916064453124989],
            [-82.739990234375, 8.898583984374994],
            [-82.8119140625, 8.857421875],
            [-82.881982421875, 8.805322265624994],
            [-82.91704101562499, 8.740332031249991],
            [-82.855712890625, 8.635302734374989],
            [-82.84262695312499, 8.56396484375],
            [-82.84477539062499, 8.489355468749991],
            [-82.86162109374999, 8.453515625],
            [-82.99755859375, 8.367773437499991],
            [-83.02734375, 8.337744140624991],
            [-83.023388671875, 8.316015625],
            [-82.9484375, 8.2568359375],
            [-82.91289062499999, 8.199609375],
            [-82.88330078125, 8.130566406249997],
            [-82.879345703125, 8.070654296874991],
            [-82.947265625, 8.181738281249991],
            [-83.041455078125, 8.287744140624994],
            [-83.12333984374999, 8.353076171874989],
            [-83.12958984375, 8.50546875],
            [-83.16240234374999, 8.588183593749989],
            [-83.285791015625, 8.664355468749989],
            [-83.39140624999999, 8.717724609374997],
            [-83.4697265625, 8.706835937499989],
            [-83.42177734375, 8.619238281249991],
            [-83.29775390625, 8.506884765624989],
            [-83.28955078125, 8.463818359374997],
            [-83.29150390625, 8.406005859375],
            [-83.37680664062499, 8.414892578124991],
            [-83.45205078125, 8.4384765625],
            [-83.54375, 8.445849609374989],
            [-83.604736328125, 8.480322265624991],
            [-83.73408203125, 8.614453125],
            [-83.6421875, 8.72890625],
            [-83.613720703125, 8.804052734374991],
            [-83.616162109375, 8.959814453124991],
            [-83.63725585937499, 9.035351562499997],
            [-83.73691406249999, 9.150292968749994],
            [-83.89555664062499, 9.276416015624989],
            [-84.11787109375, 9.379443359374989],
            [-84.22236328125, 9.4625],
            [-84.482666015625, 9.526171874999989],
            [-84.58159179687499, 9.568359375],
            [-84.65888671875, 9.646679687499997],
            [-84.67045898437499, 9.702880859375],
            [-84.64306640625, 9.789404296874991],
            [-84.71494140624999, 9.8994140625],
            [-85.025048828125, 10.11572265625],
            [-85.1984375, 10.1953125],
            [-85.23564453124999, 10.242089843749994],
            [-85.26318359375, 10.256640624999989],
            [-85.2365234375, 10.107373046874997],
            [-85.16074218749999, 10.017431640624991],
            [-84.96279296875, 9.933447265624991],
            [-84.908349609375, 9.884570312499989],
            [-84.88642578125, 9.820947265624994],
            [-85.00126953124999, 9.699267578124989],
            [-85.059716796875, 9.668310546874991],
            [-85.07705078125, 9.601953125],
            [-85.114501953125, 9.581787109375],
            [-85.15400390625, 9.620068359374997],
            [-85.31455078124999, 9.8109375],
            [-85.62485351562499, 9.902441406249991],
            [-85.68100585937499, 9.95859375],
            [-85.796484375, 10.132861328124989],
            [-85.84965820312499, 10.292041015624989],
            [-85.83061523437499, 10.398144531249997],
            [-85.703125, 10.5634765625],
            [-85.663330078125, 10.635449218749997],
            [-85.67143554687499, 10.679785156249991],
            [-85.667236328125, 10.745019531249994],
            [-85.71484375, 10.790576171874989],
            [-85.83286132812499, 10.849951171874991],
            [-85.90800781249999, 10.897558593749991],
            [-85.88740234375, 10.921289062499994],
            [-85.75224609374999, 10.985253906249994],
            [-85.74370117187499, 11.04296875],
            [-85.7443359375, 11.062109375],
            [-85.722265625, 11.066259765624991],
            [-85.70263671875, 11.08154296875],
            [-85.69052734374999, 11.097460937499989],
            [-85.65366210937499, 11.153076171875],
            [-85.62138671874999, 11.184472656249994],
            [-85.5841796875, 11.189453125],
            [-85.538720703125, 11.166308593749989],
            [-85.368359375, 11.1064453125],
            [-85.178955078125, 11.039941406249994],
            [-84.9091796875, 10.9453125],
            [-84.79736328125, 11.005908203124989],
            [-84.701171875, 11.052197265624997],
            [-84.6341796875, 11.045605468749997],
            [-84.48916015625, 10.991650390624997],
            [-84.40185546875, 10.974462890624991],
            [-84.348291015625, 10.979882812499994],
            [-84.25556640625, 10.900732421874991],
            [-84.20498046875, 10.84130859375],
            [-84.19658203124999, 10.801708984374997],
            [-84.168359375, 10.780371093749991],
            [-84.09619140625, 10.775683593749989],
            [-83.91928710937499, 10.7353515625],
            [-83.811181640625, 10.743261718749991],
            [-83.71293945312499, 10.785888671875],
            [-83.658935546875, 10.836865234374997],
            [-83.6419921875, 10.917236328125],
            [-83.61728515624999, 10.877490234374989],
            [-83.58818359374999, 10.814990234374989],
            [-83.57529296874999, 10.734716796874991],
            [-83.4482421875, 10.465917968749991],
            [-83.346826171875, 10.315380859374997],
            [-83.124609375, 10.041601562499991],
            [-83.028515625, 9.991259765624989],
            [-82.86630859374999, 9.770947265624997],
            [-82.810302734375, 9.734570312499997],
            [-82.77841796874999, 9.66953125],
            [-82.61015624999999, 9.616015624999989],
            [-82.56357421874999, 9.57666015625],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 3,
        labelrank: 6,
        sovereignt: "France",
        sov_a3: "FR1",
        adm0_dif: 1,
        level: 2,
        type: "Dependency",
        tlc: "1",
        admin: "Saint Martin",
        adm0_a3: "MAF",
        geou_dif: 0,
        geounit: "Saint Martin",
        gu_a3: "MAF",
        su_dif: 0,
        subunit: "Saint Martin",
        su_a3: "MAF",
        brk_diff: 0,
        name: "St-Martin",
        name_long: "Saint-Martin",
        brk_a3: "MAF",
        brk_name: "Saint-Martin",
        brk_group: null,
        abbrev: "St. M.",
        postal: "MF",
        formal_en: "Saint-Martin (French part)",
        formal_fr: null,
        name_ciawf: "Saint Martin",
        note_adm0: "Fr.",
        note_brk: null,
        name_sort: "St. Martin (French part)",
        name_alt: null,
        mapcolor7: 7,
        mapcolor8: 5,
        mapcolor9: 9,
        mapcolor13: 11,
        pop_est: 38002,
        pop_rank: 7,
        pop_year: 2019,
        gdp_md: 562,
        gdp_year: 2016,
        economy: "6. Developing region",
        income_grp: "2. High income: nonOECD",
        fips_10: "RN",
        iso_a2: "MF",
        iso_a2_eh: "MF",
        iso_a3: "MAF",
        iso_a3_eh: "MAF",
        iso_n3: "663",
        iso_n3_eh: "663",
        un_a3: "663",
        wb_a2: "MF",
        wb_a3: "MAF",
        woe_id: 56042305,
        woe_id_eh: 56042305,
        woe_note: "Exact WOE match as country",
        adm0_iso: "MAF",
        adm0_diff: null,
        adm0_tlc: "MAF",
        adm0_a3_us: "MAF",
        adm0_a3_fr: "MAF",
        adm0_a3_ru: "MAF",
        adm0_a3_es: "MAF",
        adm0_a3_cn: "MAF",
        adm0_a3_tw: "MAF",
        adm0_a3_in: "MAF",
        adm0_a3_np: "MAF",
        adm0_a3_pk: "MAF",
        adm0_a3_de: "MAF",
        adm0_a3_gb: "MAF",
        adm0_a3_br: "MAF",
        adm0_a3_il: "MAF",
        adm0_a3_ps: "MAF",
        adm0_a3_sa: "MAF",
        adm0_a3_eg: "MAF",
        adm0_a3_ma: "MAF",
        adm0_a3_pt: "MAF",
        adm0_a3_ar: "MAF",
        adm0_a3_jp: "MAF",
        adm0_a3_ko: "MAF",
        adm0_a3_vn: "MAF",
        adm0_a3_tr: "MAF",
        adm0_a3_id: "MAF",
        adm0_a3_pl: "MAF",
        adm0_a3_gr: "MAF",
        adm0_a3_it: "MAF",
        adm0_a3_nl: "MAF",
        adm0_a3_se: "MAF",
        adm0_a3_bd: "MAF",
        adm0_a3_ua: "MAF",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "North America",
        region_un: "Americas",
        subregion: "Caribbean",
        region_wb: "Latin America & Caribbean",
        name_len: 9,
        long_len: 12,
        abbrev_len: 6,
        tiny: 4,
        homepart: -99,
        min_zoom: 0,
        min_label: 5,
        max_label: 10,
        label_x: -63.049399,
        label_y: 18.081302,
        ne_id: 1159320639,
        wikidataid: "Q126125",
        name_ar: "تجمع سان مارتين",
        name_bn: "সেন্ট-মার্টিন",
        name_de: "Saint-Martin",
        name_en: "Saint Martin",
        name_es: "San Martín",
        name_fa: "سنت مارتین فرانسه",
        name_fr: "Saint-Martin",
        name_el: "Άγιος Μαρτίνος",
        name_he: "סן מרטן",
        name_hi: "सेंट मार्टिन की सामूहिकता",
        name_hu: "Saint-Martin",
        name_id: "Saint Martin",
        name_it: "Saint-Martin",
        name_ja: "サン・マルタン",
        name_ko: "생마르탱",
        name_nl: "Sint-Maarten",
        name_pl: "Saint-Martin",
        name_pt: "São Martinho",
        name_ru: "Сен-Мартен",
        name_sv: "Saint Martin",
        name_tr: "Saint Martin",
        name_uk: "Сен-Мартен",
        name_ur: "سینٹ مارٹن",
        name_vi: "Saint-Martin",
        name_zh: "法属圣马丁",
        name_zht: "法屬聖馬丁",
        fclass_iso: "Admin-0 dependency",
        tlc_diff: null,
        fclass_tlc: "Admin-0 dependency",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "MAF.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-63.011181640625, 18.06894531249999],
            [-63.123046875, 18.06894531249999],
            [-63.114990234375, 18.090722656249994],
            [-63.06308593749999, 18.11533203124999],
            [-63.024804687499994, 18.113085937500003],
            [-63.009423828124994, 18.104296875],
            [-63.011181640625, 18.06894531249999],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 3,
        labelrank: 6,
        sovereignt: "Netherlands",
        sov_a3: "NL1",
        adm0_dif: 1,
        level: 2,
        type: "Country",
        tlc: "1",
        admin: "Sint Maarten",
        adm0_a3: "SXM",
        geou_dif: 0,
        geounit: "Sint Maarten",
        gu_a3: "SXM",
        su_dif: 0,
        subunit: "Sint Maarten",
        su_a3: "SXM",
        brk_diff: 0,
        name: "Sint Maarten",
        name_long: "Sint Maarten",
        brk_a3: "SXM",
        brk_name: "Sint Maarten",
        brk_group: null,
        abbrev: "St. M.",
        postal: "SX",
        formal_en: "Sint Maarten (Dutch part)",
        formal_fr: null,
        name_ciawf: "Sint Maarten",
        note_adm0: "Neth.",
        note_brk: null,
        name_sort: "St. Maarten (Dutch part)",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 2,
        mapcolor9: 2,
        mapcolor13: 9,
        pop_est: 40733,
        pop_rank: 7,
        pop_year: 2019,
        gdp_md: 1185,
        gdp_year: 2018,
        economy: "6. Developing region",
        income_grp: "2. High income: nonOECD",
        fips_10: "NT",
        iso_a2: "SX",
        iso_a2_eh: "SX",
        iso_a3: "SXM",
        iso_a3_eh: "SXM",
        iso_n3: "534",
        iso_n3_eh: "534",
        un_a3: "534",
        wb_a2: "SX",
        wb_a3: "SXM",
        woe_id: -90,
        woe_id_eh: 23425000,
        woe_note: "Expired subunits of Netherlands Antilles (23424914).",
        adm0_iso: "SXM",
        adm0_diff: null,
        adm0_tlc: "SXM",
        adm0_a3_us: "SXM",
        adm0_a3_fr: "SXM",
        adm0_a3_ru: "SXM",
        adm0_a3_es: "SXM",
        adm0_a3_cn: "SXM",
        adm0_a3_tw: "SXM",
        adm0_a3_in: "SXM",
        adm0_a3_np: "SXM",
        adm0_a3_pk: "SXM",
        adm0_a3_de: "SXM",
        adm0_a3_gb: "SXM",
        adm0_a3_br: "SXM",
        adm0_a3_il: "SXM",
        adm0_a3_ps: "SXM",
        adm0_a3_sa: "SXM",
        adm0_a3_eg: "SXM",
        adm0_a3_ma: "SXM",
        adm0_a3_pt: "SXM",
        adm0_a3_ar: "SXM",
        adm0_a3_jp: "SXM",
        adm0_a3_ko: "SXM",
        adm0_a3_vn: "SXM",
        adm0_a3_tr: "SXM",
        adm0_a3_id: "SXM",
        adm0_a3_pl: "SXM",
        adm0_a3_gr: "SXM",
        adm0_a3_it: "SXM",
        adm0_a3_nl: "SXM",
        adm0_a3_se: "SXM",
        adm0_a3_bd: "SXM",
        adm0_a3_ua: "SXM",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "North America",
        region_un: "Americas",
        subregion: "Caribbean",
        region_wb: "Latin America & Caribbean",
        name_len: 12,
        long_len: 12,
        abbrev_len: 6,
        tiny: 4,
        homepart: -99,
        min_zoom: 0,
        min_label: 5,
        max_label: 10,
        label_x: -63.070133,
        label_y: 18.04088,
        ne_id: 1159321103,
        wikidataid: "Q26273",
        name_ar: "سينت مارتن",
        name_bn: "সিন্ট মার্টেন",
        name_de: "Sint Maarten",
        name_en: "Sint Maarten",
        name_es: "San Martín",
        name_fa: "سینت مارتن",
        name_fr: "Saint-Martin",
        name_el: "Άγιος Μαρτίνος",
        name_he: "סנט מארטן",
        name_hi: "सिंट मार्टेन",
        name_hu: "Sint Maarten",
        name_id: "Sint Maarten",
        name_it: "Sint Maarten",
        name_ja: "シント・マールテン",
        name_ko: "신트마르턴",
        name_nl: "Sint Maarten",
        name_pl: "Sint Maarten",
        name_pt: "São Martinho",
        name_ru: "Синт-Мартен",
        name_sv: "Sint Maarten",
        name_tr: "Sint Maarten",
        name_uk: "Сінт-Мартен",
        name_ur: "سنٹ مارٹن",
        name_vi: "Sint Maarten",
        name_zh: "荷属圣马丁",
        name_zht: "荷屬聖馬丁",
        fclass_iso: "Admin-0 dependency",
        tlc_diff: null,
        fclass_tlc: "Admin-0 dependency",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "SXM.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-63.123046875, 18.06894531249999],
            [-63.011181640625, 18.06894531249999],
            [-63.01230468749999, 18.04541015625],
            [-63.023046875, 18.019189453124994],
            [-63.09042968749999, 18.04140625],
            [-63.12470703125, 18.06430664062499],
            [-63.123046875, 18.06894531249999],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 5,
        sovereignt: "Haiti",
        sov_a3: "HTI",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Haiti",
        adm0_a3: "HTI",
        geou_dif: 0,
        geounit: "Haiti",
        gu_a3: "HTI",
        su_dif: 0,
        subunit: "Haiti",
        su_a3: "HTI",
        brk_diff: 0,
        name: "Haiti",
        name_long: "Haiti",
        brk_a3: "HTI",
        brk_name: "Haiti",
        brk_group: null,
        abbrev: "Haiti",
        postal: "HT",
        formal_en: "Republic of Haiti",
        formal_fr: null,
        name_ciawf: "Haiti",
        note_adm0: null,
        note_brk: null,
        name_sort: "Haiti",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 1,
        mapcolor9: 7,
        mapcolor13: 2,
        pop_est: 11263077,
        pop_rank: 14,
        pop_year: 2019,
        gdp_md: 14332,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        fips_10: "HA",
        iso_a2: "HT",
        iso_a2_eh: "HT",
        iso_a3: "HTI",
        iso_a3_eh: "HTI",
        iso_n3: "332",
        iso_n3_eh: "332",
        un_a3: "332",
        wb_a2: "HT",
        wb_a3: "HTI",
        woe_id: 23424839,
        woe_id_eh: 23424839,
        woe_note: "Exact WOE match as country",
        adm0_iso: "HTI",
        adm0_diff: null,
        adm0_tlc: "HTI",
        adm0_a3_us: "HTI",
        adm0_a3_fr: "HTI",
        adm0_a3_ru: "HTI",
        adm0_a3_es: "HTI",
        adm0_a3_cn: "HTI",
        adm0_a3_tw: "HTI",
        adm0_a3_in: "HTI",
        adm0_a3_np: "HTI",
        adm0_a3_pk: "HTI",
        adm0_a3_de: "HTI",
        adm0_a3_gb: "HTI",
        adm0_a3_br: "HTI",
        adm0_a3_il: "HTI",
        adm0_a3_ps: "HTI",
        adm0_a3_sa: "HTI",
        adm0_a3_eg: "HTI",
        adm0_a3_ma: "HTI",
        adm0_a3_pt: "HTI",
        adm0_a3_ar: "HTI",
        adm0_a3_jp: "HTI",
        adm0_a3_ko: "HTI",
        adm0_a3_vn: "HTI",
        adm0_a3_tr: "HTI",
        adm0_a3_id: "HTI",
        adm0_a3_pl: "HTI",
        adm0_a3_gr: "HTI",
        adm0_a3_it: "HTI",
        adm0_a3_nl: "HTI",
        adm0_a3_se: "HTI",
        adm0_a3_bd: "HTI",
        adm0_a3_ua: "HTI",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "North America",
        region_un: "Americas",
        subregion: "Caribbean",
        region_wb: "Latin America & Caribbean",
        name_len: 5,
        long_len: 5,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: -72.224051,
        label_y: 19.263784,
        ne_id: 1159320839,
        wikidataid: "Q790",
        name_ar: "هايتي",
        name_bn: "হাইতি",
        name_de: "Haiti",
        name_en: "Haiti",
        name_es: "Haití",
        name_fa: "هائیتی",
        name_fr: "Haïti",
        name_el: "Αϊτή",
        name_he: "האיטי",
        name_hi: "हैती",
        name_hu: "Haiti",
        name_id: "Haiti",
        name_it: "Haiti",
        name_ja: "ハイチ",
        name_ko: "아이티",
        name_nl: "Haïti",
        name_pl: "Haiti",
        name_pt: "Haiti",
        name_ru: "Республика Гаити",
        name_sv: "Haiti",
        name_tr: "Haiti",
        name_uk: "Гаїті",
        name_ur: "ہیٹی",
        name_vi: "Haiti",
        name_zh: "海地",
        name_zht: "海地",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "HTI.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-71.779248046875, 19.718164062499994],
              [-71.757421875, 19.688183593749997],
              [-71.71147460937499, 19.486572265625],
              [-71.70693359375, 19.42197265624999],
              [-71.753173828125, 19.324462890625],
              [-71.746484375, 19.285839843749997],
              [-71.647216796875, 19.195947265624994],
              [-71.64531249999999, 19.163525390624997],
              [-71.65703124999999, 19.130761718749994],
              [-71.74204101562499, 19.04550781249999],
              [-71.80712890625, 18.987011718749997],
              [-71.786376953125, 18.92001953124999],
              [-71.733642578125, 18.856396484374997],
              [-71.72705078125, 18.80322265625],
              [-71.74321289062499, 18.73291015625],
              [-71.82421875, 18.6455078125],
              [-71.86650390624999, 18.614160156249994],
              [-71.98686523437499, 18.6103515625],
              [-72.000390625, 18.597900390625],
              [-71.940380859375, 18.512597656249994],
              [-71.87255859375, 18.416210937499997],
              [-71.7619140625, 18.34130859375],
              [-71.737255859375, 18.270800781250003],
              [-71.76376953124999, 18.20395507812499],
              [-71.768310546875, 18.03916015624999],
              [-71.8529296875, 18.119140625],
              [-71.94609374999999, 18.186083984375003],
              [-72.00205078124999, 18.21201171874999],
              [-72.05986328124999, 18.228564453125003],
              [-72.503564453125, 18.219921875],
              [-72.55322265625, 18.208398437499994],
              [-72.59189453124999, 18.186914062499994],
              [-72.63330078125, 18.176220703124997],
              [-72.7552734375, 18.156152343749994],
              [-72.87666015625, 18.151757812499994],
              [-73.16005859375, 18.205615234375003],
              [-73.27226562499999, 18.233544921874994],
              [-73.38515625, 18.251171875],
              [-73.51484375, 18.245361328125],
              [-73.64404296875, 18.229052734375003],
              [-73.747314453125, 18.190234375],
              [-73.82470703125, 18.121777343749997],
              [-73.83916015624999, 18.058203125],
              [-73.8849609375, 18.041894531249994],
              [-73.989453125, 18.14316406249999],
              [-74.085400390625, 18.215136718750003],
              [-74.19462890624999, 18.269189453124994],
              [-74.41904296874999, 18.34619140625],
              [-74.4599609375, 18.39306640625],
              [-74.478125, 18.45],
              [-74.3875, 18.624707031249997],
              [-74.28447265624999, 18.656689453124997],
              [-74.227734375, 18.66269531249999],
              [-74.100341796875, 18.64111328125],
              [-73.9759765625, 18.60141601562499],
              [-73.8625, 18.575439453125],
              [-73.68701171875, 18.565332031249994],
              [-73.59160156249999, 18.522363281249994],
              [-72.91728515624999, 18.455712890624994],
              [-72.78935546874999, 18.434814453125],
              [-72.739453125, 18.442138671875],
              [-72.69599609375, 18.468212890624997],
              [-72.659765625, 18.515332031249997],
              [-72.61806640625, 18.55078125],
              [-72.418115234375, 18.55869140624999],
              [-72.37607421874999, 18.574462890625],
              [-72.34672851562499, 18.623730468749997],
              [-72.34765625, 18.674951171874994],
              [-72.465234375, 18.743554687499994],
              [-72.64912109375, 18.894140625],
              [-72.81108398437499, 19.071582031250003],
              [-72.7412109375, 19.13134765625],
              [-72.76796875, 19.240625],
              [-72.741796875, 19.341845703125003],
              [-72.70322265624999, 19.441064453124994],
              [-72.863427734375, 19.526074218749997],
              [-73.052734375, 19.610742187499994],
              [-73.31552734374999, 19.63730468749999],
              [-73.39633789062499, 19.65869140625],
              [-73.43837890625, 19.722119140624997],
              [-73.400537109375, 19.807421875],
              [-73.31533203125, 19.85458984374999],
              [-73.2177734375, 19.883691406249994],
              [-73.11777343749999, 19.90380859375],
              [-72.87651367187499, 19.92807617187499],
              [-72.63701171874999, 19.90087890625],
              [-72.429931640625, 19.81328125],
              [-72.21982421874999, 19.74462890625],
              [-71.954296875, 19.7216796875],
              [-71.834716796875, 19.696728515624997],
              [-71.779248046875, 19.718164062499994],
            ],
          ],
          [
            [
              [-72.80458984375, 18.77768554687499],
              [-72.822216796875, 18.70712890624999],
              [-73.07797851562499, 18.790917968749994],
              [-73.28525390624999, 18.896728515625],
              [-73.27641601562499, 18.954052734374997],
              [-73.17060546875, 18.96728515625],
              [-73.06914062499999, 18.93203125],
              [-72.91923828124999, 18.861474609374994],
              [-72.80458984375, 18.77768554687499],
            ],
          ],
          [
            [
              [-72.6640625, 20.0375],
              [-72.623486328125, 20.01416015625],
              [-72.6388671875, 19.98583984375],
              [-72.73979492187499, 20.00341796875],
              [-72.84423828125, 20.035449218750003],
              [-72.87841796875, 20.02744140624999],
              [-72.89931640625, 20.031445312499997],
              [-72.9603515625, 20.062255859375],
              [-72.90673828125, 20.085839843749994],
              [-72.85146484375, 20.093652343749994],
              [-72.791015625, 20.09189453124999],
              [-72.6640625, 20.0375],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 5,
        sovereignt: "Dominican Republic",
        sov_a3: "DOM",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Dominican Republic",
        adm0_a3: "DOM",
        geou_dif: 0,
        geounit: "Dominican Republic",
        gu_a3: "DOM",
        su_dif: 0,
        subunit: "Dominican Republic",
        su_a3: "DOM",
        brk_diff: 0,
        name: "Dominican Rep.",
        name_long: "Dominican Republic",
        brk_a3: "DOM",
        brk_name: "Dominican Rep.",
        brk_group: null,
        abbrev: "Dom. Rep.",
        postal: "DO",
        formal_en: "Dominican Republic",
        formal_fr: null,
        name_ciawf: "Dominican Republic",
        note_adm0: null,
        note_brk: null,
        name_sort: "Dominican Republic",
        name_alt: null,
        mapcolor7: 5,
        mapcolor8: 2,
        mapcolor9: 5,
        mapcolor13: 7,
        pop_est: 10738958,
        pop_rank: 14,
        pop_year: 2019,
        gdp_md: 88941,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        fips_10: "DR",
        iso_a2: "DO",
        iso_a2_eh: "DO",
        iso_a3: "DOM",
        iso_a3_eh: "DOM",
        iso_n3: "214",
        iso_n3_eh: "214",
        un_a3: "214",
        wb_a2: "DO",
        wb_a3: "DOM",
        woe_id: 23424800,
        woe_id_eh: 23424800,
        woe_note: "Exact WOE match as country",
        adm0_iso: "DOM",
        adm0_diff: null,
        adm0_tlc: "DOM",
        adm0_a3_us: "DOM",
        adm0_a3_fr: "DOM",
        adm0_a3_ru: "DOM",
        adm0_a3_es: "DOM",
        adm0_a3_cn: "DOM",
        adm0_a3_tw: "DOM",
        adm0_a3_in: "DOM",
        adm0_a3_np: "DOM",
        adm0_a3_pk: "DOM",
        adm0_a3_de: "DOM",
        adm0_a3_gb: "DOM",
        adm0_a3_br: "DOM",
        adm0_a3_il: "DOM",
        adm0_a3_ps: "DOM",
        adm0_a3_sa: "DOM",
        adm0_a3_eg: "DOM",
        adm0_a3_ma: "DOM",
        adm0_a3_pt: "DOM",
        adm0_a3_ar: "DOM",
        adm0_a3_jp: "DOM",
        adm0_a3_ko: "DOM",
        adm0_a3_vn: "DOM",
        adm0_a3_tr: "DOM",
        adm0_a3_id: "DOM",
        adm0_a3_pl: "DOM",
        adm0_a3_gr: "DOM",
        adm0_a3_it: "DOM",
        adm0_a3_nl: "DOM",
        adm0_a3_se: "DOM",
        adm0_a3_bd: "DOM",
        adm0_a3_ua: "DOM",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "North America",
        region_un: "Americas",
        subregion: "Caribbean",
        region_wb: "Latin America & Caribbean",
        name_len: 14,
        long_len: 18,
        abbrev_len: 9,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4.5,
        max_label: 9.5,
        label_x: -70.653998,
        label_y: 19.104137,
        ne_id: 1159320563,
        wikidataid: "Q786",
        name_ar: "جمهورية الدومينيكان",
        name_bn: "ডোমিনিকান প্রজাতন্ত্র",
        name_de: "Dominikanische Republik",
        name_en: "Dominican Republic",
        name_es: "República Dominicana",
        name_fa: "جمهوری دومینیکن",
        name_fr: "République dominicaine",
        name_el: "Δομινικανή Δημοκρατία",
        name_he: "הרפובליקה הדומיניקנית",
        name_hi: "डोमिनिकन गणराज्य",
        name_hu: "Dominikai Köztársaság",
        name_id: "Republik Dominika",
        name_it: "Repubblica Dominicana",
        name_ja: "ドミニカ共和国",
        name_ko: "도미니카 공화국",
        name_nl: "Dominicaanse Republiek",
        name_pl: "Dominikana",
        name_pt: "República Dominicana",
        name_ru: "Доминиканская Республика",
        name_sv: "Dominikanska republiken",
        name_tr: "Dominik Cumhuriyeti",
        name_uk: "Домініканська Республіка",
        name_ur: "جمہوریہ ڈومینیکن",
        name_vi: "Cộng hòa Dominica",
        name_zh: "多米尼加",
        name_zht: "多明尼加",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "DOM.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-71.768310546875, 18.03916015624999],
            [-71.76376953124999, 18.20395507812499],
            [-71.737255859375, 18.270800781250003],
            [-71.7619140625, 18.34130859375],
            [-71.87255859375, 18.416210937499997],
            [-71.940380859375, 18.512597656249994],
            [-72.000390625, 18.597900390625],
            [-71.98686523437499, 18.6103515625],
            [-71.86650390624999, 18.614160156249994],
            [-71.82421875, 18.6455078125],
            [-71.74321289062499, 18.73291015625],
            [-71.72705078125, 18.80322265625],
            [-71.733642578125, 18.856396484374997],
            [-71.786376953125, 18.92001953124999],
            [-71.80712890625, 18.987011718749997],
            [-71.74204101562499, 19.04550781249999],
            [-71.65703124999999, 19.130761718749994],
            [-71.64531249999999, 19.163525390624997],
            [-71.647216796875, 19.195947265624994],
            [-71.746484375, 19.285839843749997],
            [-71.753173828125, 19.324462890625],
            [-71.70693359375, 19.42197265624999],
            [-71.71147460937499, 19.486572265625],
            [-71.757421875, 19.688183593749997],
            [-71.779248046875, 19.718164062499994],
            [-71.735107421875, 19.735107421875],
            [-71.7060546875, 19.795166015625],
            [-71.6673828125, 19.8486328125],
            [-71.615966796875, 19.87744140625],
            [-71.557763671875, 19.89536132812499],
            [-71.44169921874999, 19.893994140624997],
            [-71.28134765624999, 19.847363281249997],
            [-71.2359375, 19.84814453125],
            [-71.08193359375, 19.89047851562499],
            [-70.95415039062499, 19.913964843749994],
            [-70.83388671875, 19.887255859375003],
            [-70.78525390624999, 19.850878906250003],
            [-70.6859375, 19.793261718750003],
            [-70.636181640625, 19.775634765625],
            [-70.47934570312499, 19.776953125],
            [-70.43642578125, 19.771240234375],
            [-70.30473632812499, 19.676074218750003],
            [-70.19384765625, 19.63803710937499],
            [-70.12944335937499, 19.636132812499994],
            [-70.0140625, 19.67294921874999],
            [-69.95683593749999, 19.671875],
            [-69.89121093749999, 19.589746093749994],
            [-69.8783203125, 19.473291015624994],
            [-69.8234375, 19.367138671874997],
            [-69.739404296875, 19.29921875],
            [-69.324951171875, 19.327734375],
            [-69.23247070312499, 19.27182617187499],
            [-69.26425781249999, 19.22568359374999],
            [-69.32275390625, 19.201074218749994],
            [-69.5197265625, 19.21201171874999],
            [-69.60595703125, 19.206494140624997],
            [-69.6232421875, 19.16049804687499],
            [-69.62363281249999, 19.117822265624994],
            [-69.50834960937499, 19.107617187499997],
            [-69.395263671875, 19.086083984374994],
            [-69.280224609375, 19.051904296874994],
            [-69.163037109375, 19.028466796874994],
            [-69.03129882812499, 19.01318359375],
            [-68.9013671875, 18.988476562499997],
            [-68.684765625, 18.90478515625],
            [-68.44541015624999, 18.714453125],
            [-68.38139648437499, 18.671142578125],
            [-68.33916015624999, 18.611523437499997],
            [-68.35927734375, 18.5380859375],
            [-68.44482421875, 18.417724609375],
            [-68.49321289062499, 18.37900390624999],
            [-68.56376953124999, 18.35546875],
            [-68.61220703125, 18.30625],
            [-68.658837890625, 18.22202148437499],
            [-68.68740234375, 18.21494140624999],
            [-68.72099609374999, 18.218408203124994],
            [-68.778466796875, 18.26611328125],
            [-68.81953125, 18.339306640624997],
            [-68.9349609375, 18.408007812500003],
            [-69.072265625, 18.39921875],
            [-69.27451171874999, 18.43984375],
            [-69.39697265625, 18.420117187499997],
            [-69.51943359375, 18.415673828124994],
            [-69.6447265625, 18.43637695312499],
            [-69.770654296875, 18.443554687499997],
            [-69.89638671875, 18.417724609375],
            [-70.018310546875, 18.37363281249999],
            [-70.06333007812499, 18.345654296874997],
            [-70.1416015625, 18.277099609375],
            [-70.18310546875, 18.251757812500003],
            [-70.479931640625, 18.21728515625],
            [-70.5654296875, 18.267578125],
            [-70.644677734375, 18.336230468750003],
            [-70.75883789062499, 18.345605468749994],
            [-70.92431640625, 18.29248046875],
            [-71.02783203125, 18.273193359375],
            [-71.069970703125, 18.25034179687499],
            [-71.0822265625, 18.224365234375],
            [-71.08261718749999, 18.128369140624997],
            [-71.10600585937499, 18.070019531249997],
            [-71.26728515625, 17.849609375],
            [-71.35830078125, 17.694140625],
            [-71.395703125, 17.64609375],
            [-71.43896484375, 17.63559570312499],
            [-71.51835937499999, 17.725],
            [-71.56904296875, 17.757373046875003],
            [-71.63173828125, 17.773632812499997],
            [-71.65830078124999, 17.82114257812499],
            [-71.6572265625, 17.888671875],
            [-71.67373046875, 17.9541015625],
            [-71.71245117187499, 18.00546875],
            [-71.768310546875, 18.03916015624999],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 6,
        sovereignt: "El Salvador",
        sov_a3: "SLV",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "El Salvador",
        adm0_a3: "SLV",
        geou_dif: 0,
        geounit: "El Salvador",
        gu_a3: "SLV",
        su_dif: 0,
        subunit: "El Salvador",
        su_a3: "SLV",
        brk_diff: 0,
        name: "El Salvador",
        name_long: "El Salvador",
        brk_a3: "SLV",
        brk_name: "El Salvador",
        brk_group: null,
        abbrev: "El. S.",
        postal: "SV",
        formal_en: "Republic of El Salvador",
        formal_fr: null,
        name_ciawf: "El Salvador",
        note_adm0: null,
        note_brk: null,
        name_sort: "El Salvador",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 4,
        mapcolor9: 6,
        mapcolor13: 8,
        pop_est: 6453553,
        pop_rank: 13,
        pop_year: 2019,
        gdp_md: 27022,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        fips_10: "ES",
        iso_a2: "SV",
        iso_a2_eh: "SV",
        iso_a3: "SLV",
        iso_a3_eh: "SLV",
        iso_n3: "222",
        iso_n3_eh: "222",
        un_a3: "222",
        wb_a2: "SV",
        wb_a3: "SLV",
        woe_id: 23424807,
        woe_id_eh: 23424807,
        woe_note: "Exact WOE match as country",
        adm0_iso: "SLV",
        adm0_diff: null,
        adm0_tlc: "SLV",
        adm0_a3_us: "SLV",
        adm0_a3_fr: "SLV",
        adm0_a3_ru: "SLV",
        adm0_a3_es: "SLV",
        adm0_a3_cn: "SLV",
        adm0_a3_tw: "SLV",
        adm0_a3_in: "SLV",
        adm0_a3_np: "SLV",
        adm0_a3_pk: "SLV",
        adm0_a3_de: "SLV",
        adm0_a3_gb: "SLV",
        adm0_a3_br: "SLV",
        adm0_a3_il: "SLV",
        adm0_a3_ps: "SLV",
        adm0_a3_sa: "SLV",
        adm0_a3_eg: "SLV",
        adm0_a3_ma: "SLV",
        adm0_a3_pt: "SLV",
        adm0_a3_ar: "SLV",
        adm0_a3_jp: "SLV",
        adm0_a3_ko: "SLV",
        adm0_a3_vn: "SLV",
        adm0_a3_tr: "SLV",
        adm0_a3_id: "SLV",
        adm0_a3_pl: "SLV",
        adm0_a3_gr: "SLV",
        adm0_a3_it: "SLV",
        adm0_a3_nl: "SLV",
        adm0_a3_se: "SLV",
        adm0_a3_bd: "SLV",
        adm0_a3_ua: "SLV",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "North America",
        region_un: "Americas",
        subregion: "Central America",
        region_wb: "Latin America & Caribbean",
        name_len: 11,
        long_len: 11,
        abbrev_len: 6,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 5,
        max_label: 10,
        label_x: -88.890124,
        label_y: 13.685371,
        ne_id: 1159321253,
        wikidataid: "Q792",
        name_ar: "السلفادور",
        name_bn: "এল সালভাদোর",
        name_de: "El Salvador",
        name_en: "El Salvador",
        name_es: "El Salvador",
        name_fa: "السالوادور",
        name_fr: "Salvador",
        name_el: "Ελ Σαλβαδόρ",
        name_he: "אל סלוודור",
        name_hi: "अल साल्वाडोर",
        name_hu: "Salvador",
        name_id: "El Salvador",
        name_it: "El Salvador",
        name_ja: "エルサルバドル",
        name_ko: "엘살바도르",
        name_nl: "El Salvador",
        name_pl: "Salwador",
        name_pt: "El Salvador",
        name_ru: "Сальвадор",
        name_sv: "El Salvador",
        name_tr: "El Salvador",
        name_uk: "Сальвадор",
        name_ur: "ایل سیلواڈور",
        name_vi: "El Salvador",
        name_zh: "萨尔瓦多",
        name_zht: "薩爾瓦多",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "SLV.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.36259765624999, 14.416015625],
            [-89.33725585937499, 14.411376953125],
            [-89.1701171875, 14.360302734374997],
            [-89.1205078125, 14.370214843749991],
            [-89.05712890625, 14.329150390625003],
            [-89.02685546875, 14.296972656249991],
            [-89.0001953125, 14.252734375],
            [-88.868310546875, 14.163671875],
            [-88.845947265625, 14.124755859375],
            [-88.74736328124999, 14.072265625],
            [-88.70761718749999, 14.032080078124991],
            [-88.665625, 14.015527343749994],
            [-88.583154296875, 14.000146484374994],
            [-88.51254882812499, 13.978955078124997],
            [-88.504345703125, 13.964208984374991],
            [-88.49765625, 13.904541015625],
            [-88.482666015625, 13.854248046875],
            [-88.44912109375, 13.850976562499994],
            [-88.40849609374999, 13.875390625],
            [-88.27622070312499, 13.942675781250003],
            [-88.151025390625, 13.987353515625003],
            [-88.08046875, 13.960595703124994],
            [-88.038720703125, 13.904638671874991],
            [-87.99101562499999, 13.879638671875],
            [-87.8919921875, 13.894970703124997],
            [-87.80224609375, 13.889990234374991],
            [-87.7314453125, 13.841064453125],
            [-87.71533203125, 13.812695312499997],
            [-87.758544921875, 13.649951171875003],
            [-87.77421874999999, 13.580322265625],
            [-87.781884765625, 13.521386718749994],
            [-87.75644531249999, 13.506005859374994],
            [-87.731640625, 13.483105468749997],
            [-87.73701171875, 13.451367187499997],
            [-87.814208984375, 13.399169921875],
            [-87.83837890625, 13.385791015625003],
            [-87.820703125, 13.28515625],
            [-87.878076171875, 13.224414062500003],
            [-87.930859375, 13.1806640625],
            [-88.0234375, 13.16875],
            [-88.1806640625, 13.164013671874997],
            [-88.417138671875, 13.213525390624994],
            [-88.591552734375, 13.281054687500003],
            [-88.68564453124999, 13.281494140625],
            [-88.655859375, 13.259179687499994],
            [-88.58154296875, 13.244970703124991],
            [-88.48388671875, 13.197167968749994],
            [-88.51201171874999, 13.183935546874991],
            [-88.86704101562499, 13.283251953125003],
            [-89.27763671874999, 13.478076171875003],
            [-89.52324218749999, 13.509130859374991],
            [-89.80419921875, 13.560107421875003],
            [-89.970458984375, 13.683154296875003],
            [-90.09521484375, 13.736523437499997],
            [-90.105908203125, 13.783007812500003],
            [-90.104736328125, 13.834765625],
            [-90.04814453124999, 13.904052734375],
            [-89.94267578124999, 13.997363281250003],
            [-89.872705078125, 14.045605468749997],
            [-89.83994140624999, 14.055078125],
            [-89.793701171875, 14.050097656250003],
            [-89.74936523437499, 14.077001953124991],
            [-89.7111328125, 14.141308593749997],
            [-89.6712890625, 14.182714843749991],
            [-89.570263671875, 14.224658203125003],
            [-89.54716796874999, 14.241259765625003],
            [-89.55502929687499, 14.277246093749994],
            [-89.57695312499999, 14.347070312499994],
            [-89.5736328125, 14.390087890624997],
            [-89.54052734375, 14.409912109375],
            [-89.50087890625, 14.413769531249997],
            [-89.41884765625, 14.431103515624997],
            [-89.383251953125, 14.427636718749994],
            [-89.36259765624999, 14.416015625],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Guatemala",
        sov_a3: "GTM",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Guatemala",
        adm0_a3: "GTM",
        geou_dif: 0,
        geounit: "Guatemala",
        gu_a3: "GTM",
        su_dif: 0,
        subunit: "Guatemala",
        su_a3: "GTM",
        brk_diff: 0,
        name: "Guatemala",
        name_long: "Guatemala",
        brk_a3: "GTM",
        brk_name: "Guatemala",
        brk_group: null,
        abbrev: "Guat.",
        postal: "GT",
        formal_en: "Republic of Guatemala",
        formal_fr: null,
        name_ciawf: "Guatemala",
        note_adm0: null,
        note_brk: null,
        name_sort: "Guatemala",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 3,
        mapcolor9: 3,
        mapcolor13: 6,
        pop_est: 16604026,
        pop_rank: 14,
        pop_year: 2019,
        gdp_md: 76710,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        fips_10: "GT",
        iso_a2: "GT",
        iso_a2_eh: "GT",
        iso_a3: "GTM",
        iso_a3_eh: "GTM",
        iso_n3: "320",
        iso_n3_eh: "320",
        un_a3: "320",
        wb_a2: "GT",
        wb_a3: "GTM",
        woe_id: 23424834,
        woe_id_eh: 23424834,
        woe_note: "Exact WOE match as country",
        adm0_iso: "GTM",
        adm0_diff: null,
        adm0_tlc: "GTM",
        adm0_a3_us: "GTM",
        adm0_a3_fr: "GTM",
        adm0_a3_ru: "GTM",
        adm0_a3_es: "GTM",
        adm0_a3_cn: "GTM",
        adm0_a3_tw: "GTM",
        adm0_a3_in: "GTM",
        adm0_a3_np: "GTM",
        adm0_a3_pk: "GTM",
        adm0_a3_de: "GTM",
        adm0_a3_gb: "GTM",
        adm0_a3_br: "GTM",
        adm0_a3_il: "GTM",
        adm0_a3_ps: "GTM",
        adm0_a3_sa: "GTM",
        adm0_a3_eg: "GTM",
        adm0_a3_ma: "GTM",
        adm0_a3_pt: "GTM",
        adm0_a3_ar: "GTM",
        adm0_a3_jp: "GTM",
        adm0_a3_ko: "GTM",
        adm0_a3_vn: "GTM",
        adm0_a3_tr: "GTM",
        adm0_a3_id: "GTM",
        adm0_a3_pl: "GTM",
        adm0_a3_gr: "GTM",
        adm0_a3_it: "GTM",
        adm0_a3_nl: "GTM",
        adm0_a3_se: "GTM",
        adm0_a3_bd: "GTM",
        adm0_a3_ua: "GTM",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "North America",
        region_un: "Americas",
        subregion: "Central America",
        region_wb: "Latin America & Caribbean",
        name_len: 9,
        long_len: 9,
        abbrev_len: 5,
        tiny: 4,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: -90.497134,
        label_y: 14.982133,
        ne_id: 1159320815,
        wikidataid: "Q774",
        name_ar: "غواتيمالا",
        name_bn: "গুয়াতেমালা",
        name_de: "Guatemala",
        name_en: "Guatemala",
        name_es: "Guatemala",
        name_fa: "گواتمالا",
        name_fr: "Guatemala",
        name_el: "Γουατεμάλα",
        name_he: "גואטמלה",
        name_hi: "ग्वाटेमाला",
        name_hu: "Guatemala",
        name_id: "Guatemala",
        name_it: "Guatemala",
        name_ja: "グアテマラ",
        name_ko: "과테말라",
        name_nl: "Guatemala",
        name_pl: "Gwatemala",
        name_pt: "Guatemala",
        name_ru: "Гватемала",
        name_sv: "Guatemala",
        name_tr: "Guatemala",
        name_uk: "Гватемала",
        name_ur: "گواتیمالا",
        name_vi: "Guatemala",
        name_zh: "危地马拉",
        name_zht: "瓜地馬拉",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "GTM.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.23515624999999, 14.54541015625],
            [-92.20903320312499, 14.570996093749997],
            [-92.18706054687499, 14.630078125],
            [-92.159912109375, 14.691015625],
            [-92.17646484375, 14.761328125],
            [-92.18637695312499, 14.818359375],
            [-92.1556640625, 14.901318359374997],
            [-92.15854492187499, 14.963574218749997],
            [-92.14423828125, 15.001953125],
            [-92.09873046874999, 15.026757812499994],
            [-92.07480468749999, 15.07421875],
            [-92.204345703125, 15.237695312499994],
            [-92.204248046875, 15.275],
            [-92.187158203125, 15.320898437499991],
            [-92.08212890624999, 15.495556640624997],
            [-91.9572265625, 15.703222656249991],
            [-91.81943359374999, 15.932373046875],
            [-91.736572265625, 16.07016601562499],
            [-91.433984375, 16.070458984374994],
            [-91.2337890625, 16.07065429687499],
            [-90.97958984374999, 16.07080078125],
            [-90.70322265624999, 16.071044921875],
            [-90.52197265625, 16.071191406249994],
            [-90.44716796875, 16.072705078124997],
            [-90.45986328125, 16.162353515625],
            [-90.450146484375, 16.261376953124994],
            [-90.4169921875, 16.351318359375],
            [-90.4169921875, 16.391015625],
            [-90.47109375, 16.43955078124999],
            [-90.57578125, 16.467822265625003],
            [-90.63408203124999, 16.5107421875],
            [-90.634375, 16.565136718749997],
            [-90.65996093749999, 16.630908203125003],
            [-90.710693359375, 16.70810546874999],
            [-90.816015625, 16.787109375],
            [-90.975830078125, 16.867822265624994],
            [-91.11186523437499, 16.976171875],
            [-91.22416992187499, 17.112255859374997],
            [-91.31918945312499, 17.19980468749999],
            [-91.392333984375, 17.23642578124999],
            [-91.409619140625, 17.255859375],
            [-91.1955078125, 17.254101562499997],
            [-90.99296874999999, 17.25244140625],
            [-90.9916015625, 17.447460937499997],
            [-90.9904296875, 17.620751953124994],
            [-90.98916015625, 17.81640625],
            [-90.622021484375, 17.816113281249997],
            [-90.18359375, 17.815722656250003],
            [-89.72880859374999, 17.815332031249994],
            [-89.371533203125, 17.814990234375003],
            [-89.16147460937499, 17.81484375],
            [-89.17109375, 17.572265625],
            [-89.18217773437499, 17.291210937499997],
            [-89.190380859375, 17.084667968749997],
            [-89.20126953124999, 16.808984375],
            [-89.21245117187499, 16.527148437500003],
            [-89.22763671874999, 16.142822265625],
            [-89.2375, 15.894433593749994],
            [-89.2328125, 15.888671875],
            [-89.11357421874999, 15.900683593750003],
            [-88.937158203125, 15.88984375],
            [-88.89404296875, 15.890625],
            [-88.839990234375, 15.868994140624991],
            [-88.79833984375, 15.8625],
            [-88.708642578125, 15.806542968749994],
            [-88.60336914062499, 15.76416015625],
            [-88.53623046874999, 15.849609375],
            [-88.57158203124999, 15.901074218749997],
            [-88.59799804687499, 15.92734375],
            [-88.5939453125, 15.950292968749991],
            [-88.22832031249999, 15.72900390625],
            [-88.271435546875, 15.694873046875003],
            [-88.36455078124999, 15.616015625],
            [-88.53330078124999, 15.481201171875],
            [-88.68447265625, 15.360498046874994],
            [-88.82993164062499, 15.251025390625003],
            [-88.96098632812499, 15.152441406249991],
            [-88.97641601562499, 15.142675781249991],
            [-89.142578125, 15.072314453125003],
            [-89.1703125, 15.039892578124991],
            [-89.20610351562499, 14.900585937499997],
            [-89.22236328125, 14.866064453124991],
            [-89.19223632812499, 14.788720703124994],
            [-89.16220703124999, 14.669238281250003],
            [-89.17177734375, 14.606884765624997],
            [-89.28671875, 14.529980468749997],
            [-89.33940429687499, 14.460742187500003],
            [-89.36259765624999, 14.416015625],
            [-89.383251953125, 14.427636718749994],
            [-89.41884765625, 14.431103515624997],
            [-89.50087890625, 14.413769531249997],
            [-89.54052734375, 14.409912109375],
            [-89.5736328125, 14.390087890624997],
            [-89.57695312499999, 14.347070312499994],
            [-89.55502929687499, 14.277246093749994],
            [-89.54716796874999, 14.241259765625003],
            [-89.570263671875, 14.224658203125003],
            [-89.6712890625, 14.182714843749991],
            [-89.7111328125, 14.141308593749997],
            [-89.74936523437499, 14.077001953124991],
            [-89.793701171875, 14.050097656250003],
            [-89.83994140624999, 14.055078125],
            [-89.872705078125, 14.045605468749997],
            [-89.94267578124999, 13.997363281250003],
            [-90.04814453124999, 13.904052734375],
            [-90.104736328125, 13.834765625],
            [-90.105908203125, 13.783007812500003],
            [-90.09521484375, 13.736523437499997],
            [-90.47910156249999, 13.900927734375003],
            [-90.60693359375, 13.929003906250003],
            [-91.14604492187499, 13.925585937500003],
            [-91.37734375, 13.990185546874997],
            [-91.64091796874999, 14.114941406249997],
            [-91.819091796875, 14.228222656249997],
            [-92.23515624999999, 14.54541015625],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 5,
        labelrank: 2,
        sovereignt: "United States of America",
        sov_a3: "US1",
        adm0_dif: 1,
        level: 2,
        type: "Country",
        tlc: "1",
        admin: "United States of America",
        adm0_a3: "USA",
        geou_dif: 0,
        geounit: "United States of America",
        gu_a3: "USA",
        su_dif: 0,
        subunit: "United States",
        su_a3: "USA",
        brk_diff: 0,
        name: "United States of America",
        name_long: "United States",
        brk_a3: "USA",
        brk_name: "United States",
        brk_group: null,
        abbrev: "U.S.A.",
        postal: "US",
        formal_en: "United States of America",
        formal_fr: null,
        name_ciawf: "United States",
        note_adm0: null,
        note_brk: null,
        name_sort: "United States of America",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 5,
        mapcolor9: 1,
        mapcolor13: 1,
        pop_est: 328239523,
        pop_rank: 17,
        pop_year: 2019,
        gdp_md: 21433226,
        gdp_year: 2019,
        economy: "1. Developed region: G7",
        income_grp: "1. High income: OECD",
        fips_10: "US",
        iso_a2: "US",
        iso_a2_eh: "US",
        iso_a3: "USA",
        iso_a3_eh: "USA",
        iso_n3: "840",
        iso_n3_eh: "840",
        un_a3: "840",
        wb_a2: "US",
        wb_a3: "USA",
        woe_id: 23424977,
        woe_id_eh: 23424977,
        woe_note: "Exact WOE match as country",
        adm0_iso: "USA",
        adm0_diff: null,
        adm0_tlc: "USA",
        adm0_a3_us: "USA",
        adm0_a3_fr: "USA",
        adm0_a3_ru: "USA",
        adm0_a3_es: "USA",
        adm0_a3_cn: "USA",
        adm0_a3_tw: "USA",
        adm0_a3_in: "USA",
        adm0_a3_np: "USA",
        adm0_a3_pk: "USA",
        adm0_a3_de: "USA",
        adm0_a3_gb: "USA",
        adm0_a3_br: "USA",
        adm0_a3_il: "USA",
        adm0_a3_ps: "USA",
        adm0_a3_sa: "USA",
        adm0_a3_eg: "USA",
        adm0_a3_ma: "USA",
        adm0_a3_pt: "USA",
        adm0_a3_ar: "USA",
        adm0_a3_jp: "USA",
        adm0_a3_ko: "USA",
        adm0_a3_vn: "USA",
        adm0_a3_tr: "USA",
        adm0_a3_id: "USA",
        adm0_a3_pl: "USA",
        adm0_a3_gr: "USA",
        adm0_a3_it: "USA",
        adm0_a3_nl: "USA",
        adm0_a3_se: "USA",
        adm0_a3_bd: "USA",
        adm0_a3_ua: "USA",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "North America",
        region_un: "Americas",
        subregion: "Northern America",
        region_wb: "North America",
        name_len: 24,
        long_len: 13,
        abbrev_len: 6,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 1.7,
        max_label: 5.7,
        label_x: -97.482602,
        label_y: 39.538479,
        ne_id: 1159321369,
        wikidataid: "Q30",
        name_ar: "الولايات المتحدة",
        name_bn: "মার্কিন যুক্তরাষ্ট্র",
        name_de: "Vereinigte Staaten",
        name_en: "United States of America",
        name_es: "Estados Unidos",
        name_fa: "ایالات متحده آمریکا",
        name_fr: "États-Unis",
        name_el: "Ηνωμένες Πολιτείες Αμερικής",
        name_he: "ארצות הברית",
        name_hi: "संयुक्त राज्य अमेरिका",
        name_hu: "Amerikai Egyesült Államok",
        name_id: "Amerika Serikat",
        name_it: "Stati Uniti d'America",
        name_ja: "アメリカ合衆国",
        name_ko: "미국",
        name_nl: "Verenigde Staten van Amerika",
        name_pl: "Stany Zjednoczone",
        name_pt: "Estados Unidos",
        name_ru: "США",
        name_sv: "USA",
        name_tr: "Amerika Birleşik Devletleri",
        name_uk: "Сполучені Штати Америки",
        name_ur: "ریاستہائے متحدہ امریکا",
        name_vi: "Hoa Kỳ",
        name_zh: "美国",
        name_zht: "美國",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "USA.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-132.746875, 56.525683593749996],
              [-132.7576171875, 56.511035156249996],
              [-132.884716796875, 56.512451171875],
              [-132.930810546875, 56.524462890624996],
              [-132.948046875, 56.567236328125],
              [-132.93623046875, 56.6068359375],
              [-132.90654296875, 56.63740234375],
              [-132.870654296875, 56.69638671875],
              [-132.842529296875, 56.794775390625],
              [-132.655859375, 56.684716796875],
              [-132.598681640625, 56.6357421875],
              [-132.56796875, 56.575830078125],
              [-132.634228515625, 56.553466796875],
              [-132.714453125, 56.542529296874996],
              [-132.746875, 56.525683593749996],
            ],
          ],
          [
            [
              [-132.7798828125, 56.247265625],
              [-132.83095703125, 56.244140625],
              [-132.891455078125, 56.259423828125],
              [-133.035009765625, 56.34091796875],
              [-133.037646484375, 56.36484375],
              [-133.01708984375, 56.3919921875],
              [-132.935498046875, 56.441796875],
              [-132.90205078125, 56.453759765625],
              [-132.7060546875, 56.448486328125],
              [-132.643359375, 56.43515625],
              [-132.6291015625, 56.411914062499996],
              [-132.632275390625, 56.38828125],
              [-132.65283203125, 56.36435546875],
              [-132.657568359375, 56.339306640625],
              [-132.64658203125, 56.31318359375],
              [-132.669384765625, 56.2873046875],
              [-132.7798828125, 56.247265625],
            ],
          ],
          [
            [
              [-134.312744140625, 58.22890625],
              [-134.319873046875, 58.2041015625],
              [-134.45625, 58.20654296875],
              [-134.593994140625, 58.243115234375],
              [-134.661572265625, 58.290917968749994],
              [-134.647998046875, 58.312402343749994],
              [-134.519970703125, 58.33251953125],
              [-134.398876953125, 58.28720703125],
              [-134.312744140625, 58.22890625],
            ],
          ],
          [
            [
              [-145.118505859375, 60.337109375],
              [-145.15048828125, 60.312646484374994],
              [-145.237646484375, 60.321337890625],
              [-145.28427734375, 60.336816406249994],
              [-145.128125, 60.401123046875],
              [-145.10244140625, 60.388232421875],
              [-145.118505859375, 60.337109375],
            ],
          ],
          [
            [
              [-144.565625, 59.818408203125],
              [-144.61357421875, 59.812646484374994],
              [-144.541552734375, 59.87822265625],
              [-144.444921875, 59.95068359375],
              [-144.353955078125, 59.99619140625],
              [-144.2357421875, 60.015185546875],
              [-144.248974609375, 59.98212890625],
              [-144.40322265625, 59.92109375],
              [-144.565625, 59.818408203125],
            ],
          ],
          [
            [
              [-148.02177734375, 60.065332031249994],
              [-148.074169921875, 60.034716796875],
              [-148.271875, 60.053271484375],
              [-148.2306640625, 60.113525390625],
              [-148.07958984375, 60.15166015625],
              [-147.914208984375, 60.092333984375],
              [-148.02177734375, 60.065332031249994],
            ],
          ],
          [
            [
              [-152.020751953125, 60.36171875],
              [-152.06904296875, 60.358056640624994],
              [-152.0044921875, 60.407421875],
              [-151.959716796875, 60.503759765625],
              [-151.8994140625, 60.490380859374994],
              [-151.8873046875, 60.472705078125],
              [-151.9869140625, 60.373974609375],
              [-152.020751953125, 60.36171875],
            ],
          ],
          [
            [
              [-160.329296875, 55.337695312499996],
              [-160.343310546875, 55.2587890625],
              [-160.48076171875, 55.308984375],
              [-160.51748046875, 55.333837890625],
              [-160.492919921875, 55.35234375],
              [-160.3623046875, 55.356982421874996],
              [-160.329296875, 55.337695312499996],
            ],
          ],
          [
            [
              [-159.36201171875, 54.972412109375],
              [-159.394482421875, 54.967333984374996],
              [-159.421337890625, 54.978125],
              [-159.45849609375, 55.034960937499996],
              [-159.4619140625, 55.0587890625],
              [-159.3904296875, 55.040869140625],
              [-159.36318359375, 54.99951171875],
              [-159.36201171875, 54.972412109375],
            ],
          ],
          [
            [
              [-159.51513671875, 55.15185546875],
              [-159.52041015625, 55.07216796875],
              [-159.5349609375, 55.059619140624996],
              [-159.561474609375, 55.080908203125],
              [-159.617724609375, 55.05732421875],
              [-159.648486328125, 55.074560546875],
              [-159.635400390625, 55.10234375],
              [-159.6396484375, 55.123974609375],
              [-159.59794921875, 55.12568359375],
              [-159.588037109375, 55.165332031249996],
              [-159.595263671875, 55.18203125],
              [-159.574755859375, 55.217724609375],
              [-159.545068359375, 55.2259765625],
              [-159.51513671875, 55.15185546875],
            ],
          ],
          [
            [
              [-166.209765625, 53.723291015625],
              [-166.223828125, 53.72041015625],
              [-166.2494140625, 53.745166015624996],
              [-166.250732421875, 53.7677734375],
              [-166.234375, 53.7841796875],
              [-166.187744140625, 53.8224609375],
              [-166.154541015625, 53.8361328125],
              [-166.113720703125, 53.843066406249996],
              [-166.102685546875, 53.8328125],
              [-166.138623046875, 53.787402343749996],
              [-166.183740234375, 53.756884765624996],
              [-166.209765625, 53.723291015625],
            ],
          ],
          [
            [
              [-176.008984375, 51.812353515625],
              [-176.093359375, 51.790478515625],
              [-176.204443359375, 51.834814453125],
              [-176.19365234375, 51.886279296874996],
              [-176.071630859375, 51.843310546874996],
              [-176.008984375, 51.812353515625],
            ],
          ],
          [
            [
              [-166.10986328125, 66.22744140625],
              [-166.1486328125, 66.221826171875],
              [-166.146484375, 66.237158203125],
              [-166.03251953125, 66.277734375],
              [-165.822216796875, 66.328076171875],
              [-165.8298828125, 66.317138671875],
              [-165.94228515625, 66.278173828125],
              [-166.10986328125, 66.22744140625],
            ],
          ],
          [
            [
              [-171.463037109375, 63.640039062499994],
              [-171.4478515625, 63.615673828125],
              [-171.343359375, 63.61962890625],
              [-171.196923828125, 63.609130859375],
              [-171.03486328125, 63.585498046875],
              [-170.874609375, 63.593994140625],
              [-170.672509765625, 63.668847656249994],
              [-170.55185546875, 63.6884765625],
              [-170.430419921875, 63.698828125],
              [-170.299365234375, 63.680615234375],
              [-170.1712890625, 63.64091796875],
              [-170.121826171875, 63.617529296875],
              [-170.082421875, 63.57666015625],
              [-170.056298828125, 63.527197265625],
              [-170.0173828125, 63.491748046875],
              [-169.77744140625, 63.447998046875],
              [-169.62412109375, 63.430566406249994],
              [-169.58720703125, 63.406591796875],
              [-169.554541015625, 63.373486328125],
              [-169.427587890625, 63.34833984375],
              [-169.295068359375, 63.35751953125],
              [-169.22109375, 63.348583984375],
              [-168.996044921875, 63.347314453124994],
              [-168.716015625, 63.310595703125],
              [-168.761328125, 63.213769531249994],
              [-168.852392578125, 63.171240234375],
              [-169.109033203125, 63.184912109375],
              [-169.364697265625, 63.171142578125],
              [-169.470849609375, 63.1212890625],
              [-169.55927734375, 63.058203125],
              [-169.5712890625, 62.99677734375],
              [-169.6228515625, 62.9685546875],
              [-169.6763671875, 62.956103515625],
              [-169.71982421875, 62.990087890625],
              [-169.777783203125, 63.09375],
              [-169.818603515625, 63.12236328125],
              [-169.863427734375, 63.140380859375],
              [-169.9884765625, 63.17314453125],
              [-170.115380859375, 63.19384765625],
              [-170.189599609375, 63.196337890625],
              [-170.243115234375, 63.232275390625],
              [-170.272705078125, 63.28427734375],
              [-170.32353515625, 63.3111328125],
              [-170.424169921875, 63.349267578124994],
              [-170.527099609375, 63.379296875],
              [-170.848388671875, 63.444384765625],
              [-170.954052734375, 63.4529296875],
              [-171.06123046875, 63.4458984375],
              [-171.176025390625, 63.4162109375],
              [-171.2873046875, 63.37216796875],
              [-171.401171875, 63.339257812499994],
              [-171.519140625, 63.331982421875],
              [-171.6318359375, 63.351220703124994],
              [-171.737841796875, 63.39423828125],
              [-171.790966796875, 63.424707031249994],
              [-171.819384765625, 63.47724609375],
              [-171.817919921875, 63.529833984375],
              [-171.803515625, 63.580517578125],
              [-171.74638671875, 63.703076171875],
              [-171.646484375, 63.727001953125],
              [-171.463037109375, 63.640039062499994],
            ],
          ],
          [
            [
              [-166.13544921875, 60.383544921875],
              [-166.04365234375, 60.333935546875],
              [-165.994921875, 60.33115234375],
              [-165.84091796875, 60.346240234375],
              [-165.78447265625, 60.335595703124994],
              [-165.7296875, 60.314208984375],
              [-165.69580078125, 60.28154296875],
              [-165.68935546875, 60.22412109375],
              [-165.714404296875, 60.1728515625],
              [-165.70693359375, 60.1005859375],
              [-165.712353515625, 60.0693359375],
              [-165.63056640625, 60.028369140625],
              [-165.605029296875, 59.972802734374994],
              [-165.591796875, 59.913134765625],
              [-165.769287109375, 59.893212890624994],
              [-165.946728515625, 59.890039062499994],
              [-166.099853515625, 59.849609375],
              [-166.131201171875, 59.819775390625],
              [-166.106689453125, 59.775439453125],
              [-166.14873046875, 59.764111328125],
              [-166.187548828125, 59.773828125],
              [-166.26162109375, 59.814892578125],
              [-166.34296875, 59.834423828125],
              [-166.62763671875, 59.864648437499994],
              [-166.98505859375, 59.98388671875],
              [-167.1388671875, 60.008544921875],
              [-167.2951171875, 60.095703125],
              [-167.43642578125, 60.206640625],
              [-167.3443359375, 60.224462890625],
              [-167.251708984375, 60.233544921874994],
              [-166.836328125, 60.2169921875],
              [-166.784375, 60.296435546875],
              [-166.73095703125, 60.316259765625],
              [-166.598974609375, 60.338769531249994],
              [-166.47568359375, 60.382763671875],
              [-166.420361328125, 60.381689453125],
              [-166.3638671875, 60.36474609375],
              [-166.24697265625, 60.391162109375],
              [-166.1849609375, 60.39677734375],
              [-166.13544921875, 60.383544921875],
            ],
          ],
          [
            [
              [-152.898046875, 57.82392578125],
              [-152.8908203125, 57.768994140625],
              [-152.850146484375, 57.775683593749996],
              [-152.696240234375, 57.832275390625],
              [-152.616015625, 57.848876953125],
              [-152.511572265625, 57.85146484375],
              [-152.428759765625, 57.82568359375],
              [-152.4119140625, 57.805908203125],
              [-152.419140625, 57.78232421875],
              [-152.485400390625, 57.734423828124996],
              [-152.4826171875, 57.7033203125],
              [-152.411474609375, 57.64609375],
              [-152.2365234375, 57.614892578125],
              [-152.215283203125, 57.597705078124996],
              [-152.2162109375, 57.577001953125],
              [-152.336669921875, 57.482226562499996],
              [-152.380859375, 57.460107421875],
              [-152.41220703125, 57.45478515625],
              [-152.63095703125, 57.471826171875],
              [-152.83115234375, 57.502880859375],
              [-152.912158203125, 57.508154296875],
              [-152.940771484375, 57.498095703124996],
              [-152.9974609375, 57.4689453125],
              [-152.9568359375, 57.4603515625],
              [-152.78134765625, 57.453417968749996],
              [-152.71953125, 57.41083984375],
              [-152.692529296875, 57.37958984375],
              [-152.679052734375, 57.3451171875],
              [-152.7140625, 57.33095703125],
              [-152.789111328125, 57.320654296875],
              [-152.879052734375, 57.32080078125],
              [-152.990283203125, 57.281982421875],
              [-153.051611328125, 57.237646484375],
              [-153.274365234375, 57.226367187499996],
              [-153.443701171875, 57.1671875],
              [-153.503564453125, 57.137988281249996],
              [-153.5244140625, 57.103076171874996],
              [-153.58828125, 57.077685546874996],
              [-153.732568359375, 57.05234375],
              [-153.646533203125, 57.029589843749996],
              [-153.633056640625, 57.0103515625],
              [-153.6314453125, 56.983691406249996],
              [-153.643310546875, 56.960742187499996],
              [-153.7572265625, 56.858349609375],
              [-153.972705078125, 56.77421875],
              [-154.02734375, 56.777978515625],
              [-154.05078125, 56.7884765625],
              [-154.07001953125, 56.804541015625],
              [-154.070849609375, 56.820654296875],
              [-153.793212890625, 56.989501953125],
              [-153.80419921875, 56.997802734375],
              [-153.879736328125, 57.003515625],
              [-153.999365234375, 57.049951171875],
              [-154.0837890625, 57.020068359374996],
              [-154.102978515625, 57.021240234375],
              [-154.08046875, 57.06103515625],
              [-154.025439453125, 57.10849609375],
              [-154.03505859375, 57.121826171875],
              [-154.06533203125, 57.13369140625],
              [-154.13486328125, 57.140771484375],
              [-154.24375, 57.143017578125],
              [-154.3244140625, 57.131787109375],
              [-154.376806640625, 57.10703125],
              [-154.381103515625, 57.096533203125],
              [-154.26953125, 57.099462890625],
              [-154.239208984375, 57.086865234375],
              [-154.209130859375, 57.063330078125],
              [-154.1908203125, 57.0361328125],
              [-154.184326171875, 57.005322265625],
              [-154.20771484375, 56.963818359375],
              [-154.2609375, 56.911767578125],
              [-154.33896484375, 56.9208984375],
              [-154.498779296875, 57.036572265625],
              [-154.5693359375, 57.205908203125],
              [-154.70595703125, 57.3353515625],
              [-154.71220703125, 57.366259765624996],
              [-154.673193359375, 57.44609375],
              [-154.535302734375, 57.559423828125],
              [-154.387060546875, 57.590478515625],
              [-154.2814453125, 57.6380859375],
              [-154.179345703125, 57.65244140625],
              [-154.116162109375, 57.651220703125],
              [-154.029833984375, 57.630712890625],
              [-153.99501953125, 57.5873046875],
              [-154.015869140625, 57.56689453125],
              [-154.00791015625, 57.55615234375],
              [-153.94736328125, 57.530078125],
              [-153.881884765625, 57.439013671874996],
              [-153.805419921875, 57.358203125],
              [-153.75458984375, 57.325341796875],
              [-153.6876953125, 57.305126953125],
              [-153.75693359375, 57.366845703125],
              [-153.797802734375, 57.44326171875],
              [-153.818359375, 57.59560546875],
              [-153.838134765625, 57.63583984375],
              [-153.799462890625, 57.6466796875],
              [-153.69013671875, 57.64072265625],
              [-153.6931640625, 57.663427734375],
              [-153.80849609375, 57.71474609375],
              [-153.879443359375, 57.757177734375],
              [-153.906103515625, 57.790771484375],
              [-153.904443359375, 57.819873046874996],
              [-153.841552734375, 57.862841796874996],
              [-153.805810546875, 57.87509765625],
              [-153.768994140625, 57.88037109375],
              [-153.69560546875, 57.871240234375],
              [-153.662646484375, 57.8578125],
              [-153.5685546875, 57.761083984375],
              [-153.524462890625, 57.731005859374996],
              [-153.487939453125, 57.73095703125],
              [-153.454052734375, 57.747021484375],
              [-153.422705078125, 57.779150390625],
              [-153.3904296875, 57.798388671874996],
              [-153.35712890625, 57.8046875],
              [-153.252392578125, 57.790478515625],
              [-153.21748046875, 57.795751953125],
              [-153.20029296875, 57.82001953125],
              [-153.201025390625, 57.86328125],
              [-153.1751953125, 57.878857421875],
              [-153.16884765625, 57.91064453125],
              [-153.225927734375, 57.9576171875],
              [-153.16044921875, 57.971972656249996],
              [-152.94326171875, 57.93603515625],
              [-152.850390625, 57.896777343749996],
              [-152.898046875, 57.82392578125],
            ],
          ],
          [
            [
              [-130.02509765625, 55.8882324218751],
              [-130.074658203125, 55.83603515625],
              [-130.111962890625, 55.77978515625],
              [-130.137060546875, 55.719384765625],
              [-130.146533203125, 55.6544921875],
              [-130.1404296875, 55.585009765624996],
              [-130.12041015625, 55.5244140625],
              [-130.05947265625, 55.4123046875],
              [-130.0392578125, 55.343603515625],
              [-130.036572265625, 55.297900390624996],
              [-130.171826171875, 55.137011718749996],
              [-130.218505859375, 55.06025390625],
              [-130.2140625, 55.02587890625],
              [-130.312548828125, 54.945947265625],
              [-130.49326171875, 54.8341796875],
              [-130.575341796875, 54.769677734375],
              [-130.6158203125, 54.79091796875],
              [-130.849609375, 54.8076171875],
              [-130.934619140625, 54.950390625],
              [-130.9796875, 55.061181640625],
              [-131.0478515625, 55.157666015625],
              [-131.0458984375, 55.17958984375],
              [-130.983935546875, 55.243945312499996],
              [-130.750390625, 55.29697265625],
              [-130.748193359375, 55.318017578125],
              [-130.83505859375, 55.332080078124996],
              [-130.85595703125, 55.355126953125],
              [-130.87978515625, 55.459521484374996],
              [-130.873388671875, 55.551123046875],
              [-130.879638671875, 55.61181640625],
              [-130.9185546875, 55.735986328125],
              [-130.977001953125, 55.811962890625],
              [-131.127685546875, 55.96015625],
              [-131.140380859375, 55.997509765625],
              [-131.0740234375, 56.044384765625],
              [-131.032763671875, 56.0880859375],
              [-131.28759765625, 56.012109375],
              [-131.63525390625, 55.9322265625],
              [-131.7841796875, 55.8765625],
              [-131.815478515625, 55.85419921875],
              [-131.826171875, 55.8353515625],
              [-131.799072265625, 55.7828125],
              [-131.803271484375, 55.765966796875],
              [-131.83359375, 55.734912109374996],
              [-131.86943359375, 55.64716796875],
              [-131.94501953125, 55.554150390625],
              [-131.9833984375, 55.535009765625],
              [-132.118994140625, 55.569775390625],
              [-132.155419921875, 55.599560546875],
              [-132.2234375, 55.721044921875],
              [-132.20751953125, 55.75341796875],
              [-132.157958984375, 55.7806640625],
              [-132.090673828125, 55.83955078125],
              [-132.005712890625, 55.930078125],
              [-131.84384765625, 56.160107421875],
              [-131.738037109375, 56.16123046875],
              [-131.5513671875, 56.206787109375],
              [-131.84423828125, 56.229638671875],
              [-131.887890625, 56.241650390625],
              [-131.927294921875, 56.272998046874996],
              [-131.9623046875, 56.323681640625],
              [-132.021923828125, 56.380078125],
              [-132.133251953125, 56.399853515625],
              [-132.18203125, 56.420654296875],
              [-132.25556640625, 56.489111328125],
              [-132.30498046875, 56.519873046875],
              [-132.33203125, 56.557910156249996],
              [-132.336669921875, 56.603125],
              [-132.357666015625, 56.62587890625],
              [-132.434423828125, 56.634130859375],
              [-132.475927734375, 56.649658203125],
              [-132.487109375, 56.76640625],
              [-132.639501953125, 56.796435546874996],
              [-132.701953125, 56.822265625],
              [-132.802197265625, 56.895166015625],
              [-132.8298828125, 56.930615234375],
              [-132.838818359375, 56.960205078125],
              [-132.8142578125, 57.04072265625],
              [-132.824609375, 57.055810546875],
              [-132.913427734375, 57.0474609375],
              [-133.465869140625, 57.172167968749996],
              [-133.436669921875, 57.336865234375],
              [-133.53896484375, 57.554150390625],
              [-133.64873046875, 57.64228515625],
              [-133.626953125, 57.676513671875],
              [-133.603369140625, 57.694677734375],
              [-133.55419921875, 57.695068359375],
              [-133.342333984375, 57.631103515625],
              [-133.142822265625, 57.555126953125],
              [-133.117041015625, 57.566210937499996],
              [-133.4357421875, 57.72705078125],
              [-133.515478515625, 57.775146484375],
              [-133.535205078125, 57.832958984375],
              [-133.53642578125, 57.8638671875],
              [-133.5111328125, 57.880126953125],
              [-133.212060546875, 57.865673828125],
              [-133.1943359375, 57.877685546875],
              [-133.497412109375, 57.924658203125],
              [-133.559375, 57.924462890625],
              [-133.625732421875, 57.856982421874996],
              [-133.657275390625, 57.841015625],
              [-133.722314453125, 57.84423828125],
              [-133.744140625, 57.85458984375],
              [-133.82138671875, 57.936376953125],
              [-133.894482421875, 57.99326171875],
              [-134.031103515625, 58.072167968749994],
              [-134.05673828125, 58.128369140625],
              [-134.063330078125, 58.211083984374994],
              [-134.045263671875, 58.2892578125],
              [-133.933642578125, 58.46787109375],
              [-133.888525390625, 58.49873046875],
              [-133.8767578125, 58.5181640625],
              [-133.9111328125, 58.515234375],
              [-133.94384765625, 58.498291015625],
              [-134.0361328125, 58.41533203125],
              [-134.131201171875, 58.279345703125],
              [-134.208837890625, 58.232958984375],
              [-134.2576171875, 58.244189453125],
              [-134.3314453125, 58.299609375],
              [-134.48544921875, 58.3671875],
              [-134.663623046875, 58.384716796875],
              [-134.776123046875, 58.453857421875],
              [-134.942529296875, 58.6462890625],
              [-134.964794921875, 58.7421875],
              [-134.9861328125, 58.765625],
              [-135.07646484375, 58.796777343749994],
              [-135.1318359375, 58.84287109375],
              [-135.2173828125, 59.076611328125],
              [-135.330322265625, 59.2390625],
              [-135.358447265625, 59.32490234375],
              [-135.34892578125, 59.410058593749994],
              [-135.363671875, 59.41943359375],
              [-135.4025390625, 59.353076171875],
              [-135.412744140625, 59.31845703125],
              [-135.48408203125, 59.30869140625],
              [-135.416943359375, 59.24150390625],
              [-135.400146484375, 59.207910156249994],
              [-135.433740234375, 59.210693359375],
              [-135.50234375, 59.202294921874994],
              [-135.3861328125, 59.087548828124994],
              [-135.33408203125, 58.909619140625],
              [-135.25703125, 58.777734375],
              [-135.207080078125, 58.6708984375],
              [-135.1845703125, 58.589746093749994],
              [-135.151904296875, 58.51220703125],
              [-135.06201171875, 58.340869140625],
              [-135.04970703125, 58.306787109374994],
              [-135.060498046875, 58.27890625],
              [-135.090234375, 58.245849609375],
              [-135.141552734375, 58.2333984375],
              [-135.3025390625, 58.255908203125],
              [-135.363134765625, 58.298291015625],
              [-135.449951171875, 58.376123046874994],
              [-135.57177734375, 58.412060546875],
              [-135.8734375, 58.39423828125],
              [-135.89755859375, 58.4001953125],
              [-135.896337890625, 58.463818359375],
              [-135.86171875, 58.577050781249994],
              [-135.88955078125, 58.622705078124994],
              [-136.0455078125, 58.789111328125],
              [-136.043115234375, 58.821630859375],
              [-135.8263671875, 58.89794921875],
              [-135.931689453125, 58.903759765625],
              [-136.0166015625, 58.873974609375],
              [-136.049365234375, 58.893212890624994],
              [-136.100634765625, 58.999853515625],
              [-136.13369140625, 59.03955078125],
              [-136.150048828125, 59.048095703125],
              [-136.15947265625, 58.94677734375],
              [-136.12353515625, 58.893457031249994],
              [-136.118408203125, 58.86259765625],
              [-136.124169921875, 58.81962890625],
              [-136.146826171875, 58.788818359375],
              [-136.186328125, 58.770166015624994],
              [-136.225830078125, 58.765478515625],
              [-136.2990234375, 58.7869140625],
              [-136.3802734375, 58.827294921874994],
              [-136.451171875, 58.846337890624994],
              [-136.477587890625, 58.8625],
              [-136.511181640625, 58.907080078125],
              [-136.5662109375, 58.94091796875],
              [-136.83095703125, 58.983837890625],
              [-136.989013671875, 59.03447265625],
              [-137.0021484375, 59.021142578124994],
              [-136.95283203125, 58.966943359374994],
              [-136.948046875, 58.934912109375],
              [-136.987890625, 58.925146484375],
              [-137.059033203125, 58.87373046875],
              [-137.03837890625, 58.866650390625],
              [-136.963037109375, 58.883544921875],
              [-136.8791015625, 58.88154296875],
              [-136.74013671875, 58.8501953125],
              [-136.613916015625, 58.80927734375],
              [-136.568212890625, 58.786328125],
              [-136.54931640625, 58.752392578125],
              [-136.53349609375, 58.740234375],
              [-136.410107421875, 58.700634765625],
              [-136.40419921875, 58.67978515625],
              [-136.483740234375, 58.61767578125],
              [-136.319873046875, 58.624462890625],
              [-136.224609375, 58.60224609375],
              [-136.102880859375, 58.506298828125],
              [-136.061474609375, 58.452734375],
              [-136.05595703125, 58.384179687499994],
              [-136.08125, 58.364208984375],
              [-136.129638671875, 58.350390625],
              [-136.46240234375, 58.327978515625],
              [-136.5826171875, 58.24521484375],
              [-136.607421875, 58.243994140625],
              [-136.69892578125, 58.266455078125],
              [-136.864990234375, 58.332421875],
              [-137.071923828125, 58.39521484375],
              [-137.543994140625, 58.581201171874994],
              [-137.55693359375, 58.58994140625],
              [-137.564599609375, 58.625878906249994],
              [-137.5970703125, 58.64423828125],
              [-137.661083984375, 58.659912109375],
              [-137.75, 58.707080078125],
              [-137.863720703125, 58.785546875],
              [-137.933984375, 58.846875],
              [-137.960888671875, 58.891015625],
              [-138.026904296875, 58.941455078125],
              [-138.24072265625, 59.046826171875],
              [-138.352490234375, 59.087304687499994],
              [-138.451318359375, 59.110107421875],
              [-138.537158203125, 59.115087890625],
              [-138.560302734375, 59.129150390625],
              [-138.520703125, 59.152246093749994],
              [-138.514892578125, 59.165917968749994],
              [-138.70419921875, 59.187548828125],
              [-138.884326171875, 59.2369140625],
              [-139.340966796875, 59.375634765624994],
              [-139.576806640625, 59.462451171875],
              [-139.714453125, 59.503955078125],
              [-139.773291015625, 59.527294921875],
              [-139.79912109375, 59.546240234375],
              [-139.766064453125, 59.566064453124994],
              [-139.67412109375, 59.586816406249994],
              [-139.61162109375, 59.610302734375],
              [-139.513037109375, 59.698095703125],
              [-139.50556640625, 59.726318359375],
              [-139.55849609375, 59.790185546874994],
              [-139.582177734375, 59.848291015624994],
              [-139.58115234375, 59.880517578124994],
              [-139.569140625, 59.912353515625],
              [-139.5541015625, 59.93330078125],
              [-139.5123046875, 59.953564453125],
              [-139.4830078125, 59.963769531249994],
              [-139.446875, 59.9568359375],
              [-139.33095703125, 59.877001953125],
              [-139.3146484375, 59.84794921875],
              [-139.32001953125, 59.738720703125],
              [-139.28671875, 59.6109375],
              [-139.27626953125, 59.620361328125],
              [-139.265625, 59.66259765625],
              [-139.258740234375, 59.743310546874994],
              [-139.245703125, 59.782080078125],
              [-139.22080078125, 59.819873046875],
              [-139.178857421875, 59.83984375],
              [-139.048291015625, 59.82822265625],
              [-138.9880859375, 59.835009765625],
              [-139.24248046875, 59.8927734375],
              [-139.402490234375, 60.0009765625],
              [-139.4314453125, 60.012255859375],
              [-139.5189453125, 60.01708984375],
              [-139.611669921875, 59.9734375],
              [-139.8501953125, 59.830712890624994],
              [-139.91689453125, 59.8056640625],
              [-140.216748046875, 59.72666015625],
              [-140.41982421875, 59.7107421875],
              [-140.648388671875, 59.723193359375],
              [-140.8431640625, 59.748876953125],
              [-141.33193359375, 59.873779296875],
              [-141.40830078125, 59.902783203125],
              [-141.29462890625, 59.980029296875],
              [-141.28994140625, 60.004150390625],
              [-141.329541015625, 60.0828125],
              [-141.362158203125, 60.1052734375],
              [-141.408740234375, 60.11767578125],
              [-141.4216796875, 60.108837890625],
              [-141.42216796875, 60.085498046875],
              [-141.409716796875, 60.04228515625],
              [-141.4470703125, 60.019433593749994],
              [-141.53017578125, 59.994775390624994],
              [-141.670166015625, 59.969873046874994],
              [-142.1041015625, 60.033447265625],
              [-142.548583984375, 60.08603515625],
              [-142.945654296875, 60.09697265625],
              [-143.506103515625, 60.055029296875],
              [-143.805078125, 60.012890625],
              [-143.9794921875, 60.0087890625],
              [-144.147216796875, 60.01640625],
              [-144.1609375, 60.045800781249994],
              [-144.08427734375, 60.063037109375],
              [-144.088525390625, 60.084326171875],
              [-144.185498046875, 60.150732421875],
              [-144.3326171875, 60.191015625],
              [-144.52998046875, 60.205224609374994],
              [-144.64296875, 60.224658203125],
              [-144.67158203125, 60.24921875],
              [-144.74140625, 60.272705078125],
              [-144.85244140625, 60.295068359374994],
              [-144.901318359375, 60.33515625],
              [-144.862451171875, 60.4591796875],
              [-144.8244140625, 60.53359375],
              [-144.786572265625, 60.584619140624994],
              [-144.69111328125, 60.669091796874994],
              [-144.7244140625, 60.662841796875],
              [-144.8630859375, 60.60087890625],
              [-144.984033203125, 60.5369140625],
              [-145.09599609375, 60.453662109375],
              [-145.1626953125, 60.415380859375],
              [-145.248291015625, 60.380126953125],
              [-145.381787109375, 60.388574218749994],
              [-145.563134765625, 60.44072265625],
              [-145.71845703125, 60.467578125],
              [-145.84775390625, 60.46923828125],
              [-145.898876953125, 60.478173828124994],
              [-145.81064453125, 60.524658203125],
              [-145.759814453125, 60.56201171875],
              [-145.690234375, 60.621972656249994],
              [-145.67490234375, 60.651123046875],
              [-146.1490234375, 60.660693359375],
              [-146.16640625, 60.692285156249994],
              [-146.16708984375, 60.71552734375],
              [-146.18232421875, 60.734765625],
              [-146.251025390625, 60.749072265625],
              [-146.34716796875, 60.738134765625],
              [-146.502978515625, 60.70078125],
              [-146.570458984375, 60.729150390624994],
              [-146.54638671875, 60.7451171875],
              [-146.4955078125, 60.756787109375],
              [-146.3919921875, 60.81083984375],
              [-146.53193359375, 60.8388671875],
              [-146.603564453125, 60.870947265625],
              [-146.638427734375, 60.897314453125],
              [-146.63603515625, 60.992529296875],
              [-146.59912109375, 61.053515625],
              [-146.284912109375, 61.112646484375],
              [-146.384375, 61.13583984375],
              [-146.58271484375, 61.127832031249994],
              [-146.71591796875, 61.077539062499994],
              [-146.8740234375, 61.0048828125],
              [-146.98017578125, 60.977783203125],
              [-147.034326171875, 60.99619140625],
              [-147.10595703125, 61.0025390625],
              [-147.19501953125, 60.996826171875],
              [-147.2548828125, 60.978271484375],
              [-147.285595703125, 60.94677734375],
              [-147.32109375, 60.92548828125],
              [-147.361376953125, 60.914501953125],
              [-147.390576171875, 60.918017578125],
              [-147.4333984375, 60.95029296875],
              [-147.523291015625, 60.9703125],
              [-147.56728515625, 60.994921875],
              [-147.592578125, 60.979443359375],
              [-147.623291015625, 60.933007812499994],
              [-147.6556640625, 60.909521484375],
              [-147.8076171875, 60.885400390624994],
              [-147.89111328125, 60.889892578125],
              [-147.990771484375, 60.948291015625],
              [-148.005126953125, 60.9685546875],
              [-147.97119140625, 61.01904296875],
              [-147.75185546875, 61.2189453125],
              [-147.773779296875, 61.217822265625],
              [-147.84482421875, 61.186376953125],
              [-147.986376953125, 61.106494140625],
              [-148.0494140625, 61.082666015624994],
              [-148.15791015625, 61.0796875],
              [-148.20869140625, 61.08828125],
              [-148.27001953125, 61.081787109375],
              [-148.34189453125, 61.060400390625],
              [-148.38876953125, 61.036962890625],
              [-148.4107421875, 61.011474609375],
              [-148.395849609375, 61.00712890625],
              [-148.28740234375, 61.03623046875],
              [-148.22587890625, 61.04404296875],
              [-148.20869140625, 61.029931640624994],
              [-148.2931640625, 60.939697265625],
              [-148.34443359375, 60.853564453125],
              [-148.393310546875, 60.831884765625],
              [-148.471044921875, 60.835498046875],
              [-148.55615234375, 60.827001953125],
              [-148.557373046875, 60.8029296875],
              [-148.398681640625, 60.734033203124994],
              [-148.341259765625, 60.72431640625],
              [-148.26787109375, 60.69970703125],
              [-148.25673828125, 60.67529296875],
              [-148.284228515625, 60.609326171875],
              [-148.30498046875, 60.583349609375],
              [-148.338427734375, 60.56982421875],
              [-148.4677734375, 60.5720703125],
              [-148.5095703125, 60.565234375],
              [-148.596630859375, 60.523779296875],
              [-148.64013671875, 60.489453125],
              [-148.624267578125, 60.48642578125],
              [-148.54912109375, 60.514794921874994],
              [-148.43984375, 60.52998046875],
              [-148.29638671875, 60.532080078125],
              [-148.189453125, 60.547119140625],
              [-148.119189453125, 60.575146484375],
              [-148.05068359375, 60.5671875],
              [-147.984033203125, 60.523339843749994],
              [-147.964111328125, 60.48486328125],
              [-147.990966796875, 60.45185546875],
              [-148.04599609375, 60.4283203125],
              [-148.12919921875, 60.414208984374994],
              [-148.181689453125, 60.39306640625],
              [-148.203564453125, 60.36494140625],
              [-148.215869140625, 60.323144531249994],
              [-148.21865234375, 60.26767578125],
              [-148.197607421875, 60.1677734375],
              [-148.21376953125, 60.154248046875],
              [-148.24501953125, 60.146826171875],
              [-148.291357421875, 60.145458984375],
              [-148.33310546875, 60.122021484375],
              [-148.430712890625, 59.989111328125],
              [-148.465087890625, 59.97470703125],
              [-148.5060546875, 59.98896484375],
              [-148.5423828125, 59.98740234375],
              [-148.574072265625, 59.970068359375],
              [-148.643603515625, 59.9568359375],
              [-148.75087890625, 59.94775390625],
              [-148.842724609375, 59.951220703125],
              [-149.004248046875, 59.97998046875],
              [-149.0701171875, 60.000244140625],
              [-149.12158203125, 60.03349609375],
              [-149.2666015625, 59.998291015625],
              [-149.304931640625, 60.013671875],
              [-149.395263671875, 60.10576171875],
              [-149.41484375, 60.100244140624994],
              [-149.4322265625, 60.001025390625],
              [-149.459716796875, 59.966259765625],
              [-149.549169921875, 59.8943359375],
              [-149.598046875, 59.770458984375],
              [-149.612890625, 59.766845703125],
              [-149.629638671875, 59.78466796875],
              [-149.68466796875, 59.8953125],
              [-149.7138671875, 59.919580078124994],
              [-149.794775390625, 59.855810546875],
              [-149.803662109375, 59.83271484375],
              [-149.782470703125, 59.750341796875],
              [-149.80126953125, 59.737939453124994],
              [-149.964990234375, 59.782275390625],
              [-150.005322265625, 59.784423828125],
              [-150.015966796875, 59.776953125],
              [-149.96015625, 59.713037109374994],
              [-149.96650390625, 59.6900390625],
              [-150.198046875, 59.566552734374994],
              [-150.25849609375, 59.570947265624994],
              [-150.296484375, 59.583251953125],
              [-150.338134765625, 59.58134765625],
              [-150.4853515625, 59.535302734374994],
              [-150.5259765625, 59.5373046875],
              [-150.58154296875, 59.564599609374994],
              [-150.607373046875, 59.563378906249994],
              [-150.621142578125, 59.535058593749994],
              [-150.622900390625, 59.479638671874994],
              [-150.67744140625, 59.426953125],
              [-150.852783203125, 59.341845703125],
              [-150.89931640625, 59.302685546875],
              [-150.934521484375, 59.24912109375],
              [-150.9607421875, 59.243994140625],
              [-151.06357421875, 59.27841796875],
              [-151.182763671875, 59.30078125],
              [-151.19921875, 59.2896484375],
              [-151.163037109375, 59.25693359375],
              [-151.170703125, 59.2369140625],
              [-151.222265625, 59.229394531249994],
              [-151.2875, 59.232324218749994],
              [-151.366357421875, 59.24560546875],
              [-151.477001953125, 59.23056640625],
              [-151.619384765625, 59.1873046875],
              [-151.73818359375, 59.188525390625],
              [-151.903857421875, 59.259765625],
              [-151.94951171875, 59.265087890625],
              [-151.9640625, 59.285107421875],
              [-151.931689453125, 59.342724609375],
              [-151.884619140625, 59.386328125],
              [-151.849951171875, 59.40634765625],
              [-151.692578125, 59.46220703125],
              [-151.5126953125, 59.48271484375],
              [-151.399609375, 59.51630859375],
              [-151.262109375, 59.585595703124994],
              [-151.189404296875, 59.6376953125],
              [-151.046484375, 59.771826171875],
              [-151.05732421875, 59.782177734375],
              [-151.089453125, 59.789404296875],
              [-151.403662109375, 59.662255859374994],
              [-151.45009765625, 59.650390625],
              [-151.51259765625, 59.651269531249994],
              [-151.763818359375, 59.7],
              [-151.816943359375, 59.7208984375],
              [-151.85322265625, 59.782080078125],
              [-151.783447265625, 59.921142578125],
              [-151.734521484375, 59.988330078125],
              [-151.611865234375, 60.092041015625],
              [-151.45146484375, 60.20263671875],
              [-151.39599609375, 60.274462890625],
              [-151.3126953125, 60.466455078124994],
              [-151.317529296875, 60.553564453125],
              [-151.355029296875, 60.65986328125],
              [-151.3564453125, 60.72294921875],
              [-151.32177734375, 60.742919921875],
              [-150.953759765625, 60.841210937499994],
              [-150.7794921875, 60.914794921875],
              [-150.441259765625, 61.023583984374994],
              [-150.34912109375, 61.02265625],
              [-150.281494140625, 60.985205078125],
              [-150.202783203125, 60.955224609374994],
              [-150.113037109375, 60.9328125],
              [-149.99755859375, 60.93515625],
              [-149.85625, 60.962255859375],
              [-149.632470703125, 60.952001953125],
              [-149.1728515625, 60.880419921875],
              [-149.07509765625, 60.876416015625],
              [-149.0712890625, 60.885546875],
              [-149.142236328125, 60.935693359374994],
              [-149.459130859375, 60.964746093749994],
              [-149.59248046875, 60.99384765625],
              [-149.967724609375, 61.121728515624994],
              [-150.053271484375, 61.17109375],
              [-150.0185546875, 61.194238281249994],
              [-149.9267578125, 61.21328125],
              [-149.8953125, 61.23173828125],
              [-149.88203125, 61.263720703125],
              [-149.82919921875, 61.307519531249994],
              [-149.7369140625, 61.363330078125],
              [-149.59599609375, 61.41728515625],
              [-149.329052734375, 61.49736328125],
              [-149.433544921875, 61.50078125],
              [-149.625439453125, 61.48603515625],
              [-149.695263671875, 61.470703125],
              [-149.82373046875, 61.41337890625],
              [-149.873681640625, 61.372998046875],
              [-149.94521484375, 61.29423828125],
              [-149.97568359375, 61.279345703125],
              [-150.108935546875, 61.267919921875],
              [-150.47177734375, 61.2599609375],
              [-150.533203125, 61.300244140625],
              [-150.567236328125, 61.306787109374994],
              [-150.612255859375, 61.301123046875],
              [-150.9455078125, 61.1982421875],
              [-151.064990234375, 61.145703125],
              [-151.150146484375, 61.085839843749994],
              [-151.281884765625, 61.041943359375],
              [-151.460107421875, 61.014111328125],
              [-151.593505859375, 60.979638671874994],
              [-151.733984375, 60.9107421875],
              [-151.781640625, 60.857958984375],
              [-151.784423828125, 60.833154296874994],
              [-151.75048828125, 60.7548828125],
              [-151.785107421875, 60.740234375],
              [-151.866162109375, 60.73408203125],
              [-151.996240234375, 60.6822265625],
              [-152.270703125, 60.528125],
              [-152.306591796875, 60.472216796875],
              [-152.305078125, 60.453027343749994],
              [-152.260302734375, 60.409423828125],
              [-152.29150390625, 60.381103515625],
              [-152.36884765625, 60.336328125],
              [-152.54091796875, 60.2654296875],
              [-152.653955078125, 60.238427734374994],
              [-152.727294921875, 60.237060546875],
              [-152.797900390625, 60.24716796875],
              [-152.923388671875, 60.292871093749994],
              [-153.025, 60.295654296875],
              [-153.03125, 60.2892578125],
              [-152.892919921875, 60.240380859374994],
              [-152.752392578125, 60.177490234375],
              [-152.66474609375, 60.12529296875],
              [-152.630126953125, 60.0837890625],
              [-152.628564453125, 60.04111328125],
              [-152.660107421875, 59.997216796874994],
              [-152.75947265625, 59.9208984375],
              [-152.85693359375, 59.898095703124994],
              [-153.1060546875, 59.875048828125],
              [-153.186376953125, 59.856884765625],
              [-153.21123046875, 59.842724609375],
              [-153.040087890625, 59.810498046875],
              [-153.024609375, 59.793994140625],
              [-153.04814453125, 59.730029296875],
              [-153.093603515625, 59.709130859374994],
              [-153.236181640625, 59.670947265625],
              [-153.364013671875, 59.65986328125],
              [-153.38349609375, 59.6671875],
              [-153.359619140625, 59.71748046875],
              [-153.366455078125, 59.729833984375],
              [-153.414404296875, 59.740136718749994],
              [-153.4826171875, 59.720947265625],
              [-153.6525390625, 59.647021484375],
              [-153.670703125, 59.634814453125],
              [-153.609375, 59.6150390625],
              [-153.622265625, 59.598486328125],
              [-153.71435546875, 59.545263671875],
              [-153.752587890625, 59.50986328125],
              [-153.81416015625, 59.47373046875],
              [-154.088330078125, 59.36328125],
              [-154.06748046875, 59.336376953125],
              [-154.138818359375, 59.240136718749994],
              [-154.1783203125, 59.15556640625],
              [-154.129833984375, 59.119873046875],
              [-153.899560546875, 59.078027343749994],
              [-153.787939453125, 59.067919921875],
              [-153.656396484375, 59.038671875],
              [-153.41826171875, 58.9599609375],
              [-153.33896484375, 58.908544921875],
              [-153.32705078125, 58.884326171875],
              [-153.334423828125, 58.857861328125],
              [-153.362939453125, 58.822216796875],
              [-153.43759765625, 58.754833984375],
              [-153.617333984375, 58.654736328125],
              [-153.698583984375, 58.626367187499994],
              [-153.821484375, 58.6041015625],
              [-153.861962890625, 58.587841796875],
              [-154.019873046875, 58.49296875],
              [-154.062451171875, 58.441748046875],
              [-154.055712890625, 58.39716796875],
              [-154.085888671875, 58.3658203125],
              [-154.289013671875, 58.304345703124994],
              [-154.281787109375, 58.29345703125],
              [-154.208056640625, 58.28876953125],
              [-154.235107421875, 58.234619140625],
              [-154.247021484375, 58.159423828125],
              [-154.282275390625, 58.14677734375],
              [-154.409228515625, 58.147314453125],
              [-154.57060546875, 58.118066406249994],
              [-154.58193359375, 58.109765625],
              [-154.584912109375, 58.0556640625],
              [-155.006884765625, 58.016064453125],
              [-155.099267578125, 57.913330078125],
              [-155.14736328125, 57.8818359375],
              [-155.312744140625, 57.80712890625],
              [-155.41396484375, 57.77705078125],
              [-155.529638671875, 57.75888671875],
              [-155.590234375, 57.73359375],
              [-155.595849609375, 57.70107421875],
              [-155.6287109375, 57.673046875],
              [-155.728955078125, 57.626611328125],
              [-155.777978515625, 57.568212890625],
              [-155.813671875, 57.559033203125],
              [-156.0001953125, 57.544970703124996],
              [-156.037353515625, 57.526513671875],
              [-156.05537109375, 57.447558593749996],
              [-156.089892578125, 57.445068359375],
              [-156.156005859375, 57.463427734374996],
              [-156.2421875, 57.44921875],
              [-156.435888671875, 57.3599609375],
              [-156.47841796875, 57.327880859375],
              [-156.473681640625, 57.310693359375],
              [-156.4435546875, 57.29365234375],
              [-156.39765625, 57.240576171875],
              [-156.40048828125, 57.204833984375],
              [-156.475146484375, 57.10517578125],
              [-156.501318359375, 57.089794921875],
              [-156.592041015625, 57.065087890625],
              [-156.62900390625, 57.0099609375],
              [-156.712646484375, 57.016064453125],
              [-156.7798828125, 57.005615234375],
              [-156.823876953125, 56.96884765625],
              [-156.871728515625, 56.94765625],
              [-156.9234375, 56.94208984375],
              [-156.988427734375, 56.912939453125],
              [-157.06669921875, 56.860205078125],
              [-157.13916015625, 56.8265625],
              [-157.20576171875, 56.812060546874996],
              [-157.270556640625, 56.80849609375],
              [-157.33359375, 56.815869140625],
              [-157.390234375, 56.809814453125],
              [-157.440576171875, 56.790332031249996],
              [-157.4896484375, 56.759765625],
              [-157.5287109375, 56.673193359375],
              [-157.578369140625, 56.63447265625],
              [-157.609765625, 56.627685546875],
              [-157.673876953125, 56.633447265625],
              [-157.770703125, 56.651660156249996],
              [-157.869091796875, 56.64521484375],
              [-158.027880859375, 56.592138671875],
              [-158.0783203125, 56.552050781249996],
              [-157.978271484375, 56.5431640625],
              [-157.9287109375, 56.531689453125],
              [-157.92998046875, 56.520458984375],
              [-157.982177734375, 56.509570312499996],
              [-158.070947265625, 56.5103515625],
              [-158.124365234375, 56.501025390624996],
              [-158.189404296875, 56.478173828125],
              [-158.352490234375, 56.453515625],
              [-158.414404296875, 56.435839843749996],
              [-158.53740234375, 56.33544921875],
              [-158.5521484375, 56.3126953125],
              [-158.536376953125, 56.307666015624996],
              [-158.467333984375, 56.31826171875],
              [-158.3861328125, 56.3015625],
              [-158.343994140625, 56.280322265624996],
              [-158.3169921875, 56.254150390625],
              [-158.29140625, 56.203662109374996],
              [-158.275634765625, 56.196240234375],
              [-158.4318359375, 56.111474609375],
              [-158.476123046875, 56.075488281249996],
              [-158.5046875, 56.062109375],
              [-158.52333984375, 56.0724609375],
              [-158.54267578125, 56.166845703125],
              [-158.554443359375, 56.182861328125],
              [-158.591162109375, 56.184521484375],
              [-158.6267578125, 56.1546875],
              [-158.7048828125, 56.043115234375],
              [-158.78984375, 55.9869140625],
              [-159.429443359375, 55.842724609375],
              [-159.5232421875, 55.810009765625],
              [-159.54130859375, 55.748486328125],
              [-159.567626953125, 55.69521484375],
              [-159.61005859375, 55.652783203125],
              [-159.65966796875, 55.625927734375],
              [-159.670263671875, 55.64501953125],
              [-159.66533203125, 55.794873046875],
              [-159.678515625, 55.824658203125],
              [-159.743017578125, 55.84375],
              [-159.77138671875, 55.841113281249996],
              [-159.810400390625, 55.83271484375],
              [-159.874365234375, 55.80029296875],
              [-159.913525390625, 55.7921875],
              [-159.9623046875, 55.794873046875],
              [-160.045654296875, 55.762939453125],
              [-160.243798828125, 55.660546875],
              [-160.373193359375, 55.635107421875],
              [-160.407421875, 55.613818359374996],
              [-160.4626953125, 55.5578125],
              [-160.49931640625, 55.5373046875],
              [-160.553515625, 55.535498046875],
              [-160.625244140625, 55.552392578125],
              [-160.68291015625, 55.5404296875],
              [-160.726513671875, 55.499658203125],
              [-160.770849609375, 55.483544921875],
              [-160.896728515625, 55.513623046875],
              [-160.952197265625, 55.49306640625],
              [-161.02421875, 55.4404296875],
              [-161.09951171875, 55.405712890625],
              [-161.17802734375, 55.3888671875],
              [-161.38193359375, 55.3712890625],
              [-161.4638671875, 55.38251953125],
              [-161.480517578125, 55.397802734375],
              [-161.476708984375, 55.464892578124996],
              [-161.443798828125, 55.51328125],
              [-161.413330078125, 55.5361328125],
              [-161.372705078125, 55.556298828125],
              [-161.31328125, 55.558642578124996],
              [-161.202099609375, 55.5435546875],
              [-161.214697265625, 55.559765625],
              [-161.255126953125, 55.57900390625],
              [-161.357470703125, 55.61220703125],
              [-161.4587890625, 55.629150390625],
              [-161.516943359375, 55.618408203125],
              [-161.598779296875, 55.592822265624996],
              [-161.654296875, 55.56337890625],
              [-161.683544921875, 55.529931640625],
              [-161.720361328125, 55.420703125],
              [-161.741552734375, 55.391162109374996],
              [-161.980322265625, 55.1986328125],
              [-162.073974609375, 55.139306640625],
              [-162.1666015625, 55.14375],
              [-162.211474609375, 55.121337890625],
              [-162.274658203125, 55.0732421875],
              [-162.33291015625, 55.050244140625],
              [-162.386376953125, 55.05234375],
              [-162.4279296875, 55.061474609375],
              [-162.457470703125, 55.077685546874996],
              [-162.452392578125, 55.092822265624996],
              [-162.412548828125, 55.106884765625],
              [-162.426806640625, 55.14541015625],
              [-162.495263671875, 55.208447265625],
              [-162.54189453125, 55.242724609374996],
              [-162.63037109375, 55.2466796875],
              [-162.644140625, 55.218017578125],
              [-162.614306640625, 55.071484375],
              [-162.618896484375, 55.038427734375],
              [-162.674365234375, 54.99658203125],
              [-162.819580078125, 54.95],
              [-162.8650390625, 54.954541015625],
              [-162.9958984375, 55.046484375],
              [-163.11962890625, 55.064697265625],
              [-163.12783203125, 55.034765625],
              [-163.1001953125, 54.9736328125],
              [-163.131103515625, 54.916552734374996],
              [-163.220556640625, 54.86337890625],
              [-163.288623046875, 54.83759765625],
              [-163.335302734375, 54.839160156249996],
              [-163.337890625, 54.8763671875],
              [-163.296337890625, 54.949267578124996],
              [-163.285693359375, 55.0099609375],
              [-163.30595703125, 55.058544921875],
              [-163.303662109375, 55.095849609375],
              [-163.27880859375, 55.121826171875],
              [-163.114501953125, 55.1939453125],
              [-163.045361328125, 55.204736328125],
              [-163.008251953125, 55.186865234375],
              [-162.961962890625, 55.183837890625],
              [-162.906591796875, 55.195556640625],
              [-162.87158203125, 55.218603515625],
              [-162.85712890625, 55.25302734375],
              [-162.78623046875, 55.2970703125],
              [-162.658984375, 55.35078125],
              [-162.51337890625, 55.45],
              [-162.349365234375, 55.5947265625],
              [-162.15712890625, 55.71943359375],
              [-161.93662109375, 55.824169921875],
              [-161.697314453125, 55.9072265625],
              [-161.215625, 56.021435546875],
              [-161.17861328125, 56.014453125],
              [-161.22255859375, 55.97744140625],
              [-161.192529296875, 55.954296875],
              [-161.145166015625, 55.951318359375],
              [-160.96865234375, 55.96962890625],
              [-160.8986328125, 55.99365234375],
              [-160.87783203125, 55.970507812499996],
              [-160.902392578125, 55.94130859375],
              [-161.0083984375, 55.91171875],
              [-161.00537109375, 55.887158203125],
              [-160.851318359375, 55.771875],
              [-160.80283203125, 55.754443359374996],
              [-160.76259765625, 55.756591796875],
              [-160.7455078125, 55.771484375],
              [-160.7583984375, 55.854638671875],
              [-160.70634765625, 55.870458984375],
              [-160.59970703125, 55.874316406249996],
              [-160.530224609375, 55.8634765625],
              [-160.497900390625, 55.837890625],
              [-160.4369140625, 55.816699218749996],
              [-160.347314453125, 55.79990234375],
              [-160.29169921875, 55.805078125],
              [-160.2701171875, 55.832177734375],
              [-160.30849609375, 55.864453125],
              [-160.4798828125, 55.93544921875],
              [-160.52744140625, 55.9650390625],
              [-160.5390625, 56.006298828125],
              [-160.514697265625, 56.059130859374996],
              [-160.46083984375, 56.1375],
              [-160.377490234375, 56.241455078125],
              [-160.30205078125, 56.314111328125],
              [-160.149267578125, 56.396337890625],
              [-160.046240234375, 56.43701171875],
              [-159.78505859375, 56.56162109375],
              [-159.28310546875, 56.68857421875],
              [-159.159033203125, 56.770068359374996],
              [-158.990380859375, 56.86005859375],
              [-158.918017578125, 56.882177734375],
              [-158.918017578125, 56.847412109375],
              [-158.894873046875, 56.81640625],
              [-158.782080078125, 56.795751953125],
              [-158.708837890625, 56.78857421875],
              [-158.675146484375, 56.794873046875],
              [-158.66591796875, 56.827929687499996],
              [-158.6810546875, 56.887744140624996],
              [-158.684814453125, 56.94423828125],
              [-158.67724609375, 56.997363281249996],
              [-158.660791015625, 57.039404296875],
              [-158.585595703125, 57.1140625],
              [-158.47373046875, 57.199072265625],
              [-158.320947265625, 57.297900390624996],
              [-158.22451171875, 57.34267578125],
              [-158.133544921875, 57.36640625],
              [-158.045703125, 57.409472656249996],
              [-157.8943359375, 57.511376953125],
              [-157.845751953125, 57.528076171875],
              [-157.73720703125, 57.548144531249996],
              [-157.697412109375, 57.5392578125],
              [-157.6740234375, 57.513720703124996],
              [-157.645556640625, 57.497802734375],
              [-157.535302734375, 57.483447265624996],
              [-157.4619140625, 57.506201171875],
              [-157.473876953125, 57.518212890625],
              [-157.53349609375, 57.52587890625],
              [-157.571630859375, 57.540673828125],
              [-157.607568359375, 57.60146484375],
              [-157.6806640625, 57.6380859375],
              [-157.697216796875, 57.679443359375],
              [-157.683984375, 57.743896484375],
              [-157.62119140625, 57.89521484375],
              [-157.610888671875, 58.050830078125],
              [-157.555029296875, 58.13994140625],
              [-157.44267578125, 58.17216796875],
              [-157.193701171875, 58.194189453125],
              [-157.339404296875, 58.234521484374994],
              [-157.393603515625, 58.234814453125],
              [-157.48837890625, 58.2537109375],
              [-157.5244140625, 58.350732421874994],
              [-157.5236328125, 58.421337890625],
              [-157.460888671875, 58.50302734375],
              [-157.228857421875, 58.64091796875],
              [-156.974658203125, 58.736328125],
              [-157.009033203125, 58.744189453125],
              [-157.040478515625, 58.77255859375],
              [-156.9232421875, 58.963671875],
              [-156.80888671875, 59.13427734375],
              [-156.96337890625, 58.9888671875],
              [-157.142041015625, 58.87763671875],
              [-157.66572265625, 58.748486328125],
              [-158.021923828125, 58.640185546875],
              [-158.19091796875, 58.6142578125],
              [-158.302587890625, 58.641796875],
              [-158.3896484375, 58.745654296875],
              [-158.439306640625, 58.782617187499994],
              [-158.503173828125, 58.850341796875],
              [-158.47626953125, 58.938378906249994],
              [-158.425634765625, 58.99931640625],
              [-158.314501953125, 59.009326171875],
              [-158.189208984375, 58.979931640625],
              [-158.080517578125, 58.977441406249994],
              [-158.22060546875, 59.0375],
              [-158.422802734375, 59.08984375],
              [-158.514404296875, 59.0728515625],
              [-158.58447265625, 58.98779296875],
              [-158.678271484375, 58.92939453125],
              [-158.760595703125, 58.950097656249994],
              [-158.80947265625, 58.973876953125],
              [-158.775537109375, 58.9025390625],
              [-158.837744140625, 58.7939453125],
              [-158.861376953125, 58.71875],
              [-158.772119140625, 58.5203125],
              [-158.788623046875, 58.440966796875],
              [-158.95068359375, 58.404541015625],
              [-159.082666015625, 58.469775390625],
              [-159.358203125, 58.7212890625],
              [-159.45419921875, 58.792919921875],
              [-159.670263671875, 58.9111328125],
              [-159.741455078125, 58.894287109375],
              [-159.8322265625, 58.835986328125],
              [-159.92021484375, 58.819873046875],
              [-160.152587890625, 58.905908203124994],
              [-160.260791015625, 58.971533203125],
              [-160.363134765625, 59.051171875],
              [-160.519921875, 59.00732421875],
              [-160.656640625, 58.955078125],
              [-160.81708984375, 58.8716796875],
              [-160.924267578125, 58.872412109375],
              [-161.21591796875, 58.8009765625],
              [-161.246826171875, 58.799462890624994],
              [-161.287890625, 58.7609375],
              [-161.328125, 58.743701171875],
              [-161.361328125, 58.66953125],
              [-161.75546875, 58.61201171875],
              [-162.144921875, 58.64423828125],
              [-162.00869140625, 58.685009765625],
              [-161.856494140625, 58.71708984375],
              [-161.724365234375, 58.794287109375],
              [-161.780517578125, 58.897412109375],
              [-161.790283203125, 58.949951171875],
              [-161.788671875, 59.01640625],
              [-161.644384765625, 59.10966796875],
              [-161.794482421875, 59.10947265625],
              [-161.890771484375, 59.076074218749994],
              [-161.9810546875, 59.146142578124994],
              [-162.023291015625, 59.283984375],
              [-161.9201171875, 59.365478515625],
              [-161.872216796875, 59.428271484375],
              [-161.831689453125, 59.514501953125],
              [-161.8287109375, 59.588623046875],
              [-161.908642578125, 59.714111328125],
              [-162.138134765625, 59.980029296875],
              [-162.24248046875, 60.1783203125],
              [-162.421337890625, 60.283984375],
              [-162.28779296875, 60.456884765625],
              [-162.1388671875, 60.61435546875],
              [-161.94658203125, 60.684814453125],
              [-161.96201171875, 60.695361328125],
              [-162.06826171875, 60.694873046875],
              [-162.138037109375, 60.685546875],
              [-162.19990234375, 60.634326171875],
              [-162.2650390625, 60.59521484375],
              [-162.468701171875, 60.394677734374994],
              [-162.59970703125, 60.29697265625],
              [-162.6849609375, 60.268945312499994],
              [-162.547705078125, 60.2310546875],
              [-162.526953125, 60.199121093749994],
              [-162.50048828125, 60.1265625],
              [-162.53564453125, 60.03837890625],
              [-162.570751953125, 59.98974609375],
              [-162.7326171875, 59.99365234375],
              [-162.87783203125, 59.922753906249994],
              [-163.219384765625, 59.845605468749994],
              [-163.68037109375, 59.801513671875],
              [-163.906884765625, 59.806787109374994],
              [-164.142822265625, 59.89677734375],
              [-164.14111328125, 59.948876953124994],
              [-164.13154296875, 59.99423828125],
              [-164.4705078125, 60.149316406249994],
              [-164.662255859375, 60.30380859375],
              [-164.799951171875, 60.3072265625],
              [-164.9197265625, 60.3484375],
              [-165.0611328125, 60.412548828125],
              [-165.04873046875, 60.464257812499994],
              [-165.026513671875, 60.500634765624994],
              [-165.11328125, 60.52607421875],
              [-165.22451171875, 60.523583984374994],
              [-165.35380859375, 60.5412109375],
              [-165.016015625, 60.7400390625],
              [-164.8998046875, 60.87314453125],
              [-164.80517578125, 60.892041015625],
              [-164.682373046875, 60.871533203125],
              [-164.512939453125, 60.81904296875],
              [-164.370068359375, 60.7958984375],
              [-164.318505859375, 60.7712890625],
              [-164.265673828125, 60.724658203125],
              [-164.32138671875, 60.646630859374994],
              [-164.37236328125, 60.591845703125],
              [-164.30966796875, 60.60673828125],
              [-164.1318359375, 60.69150390625],
              [-163.999560546875, 60.766064453125],
              [-163.9361328125, 60.75830078125],
              [-163.894921875, 60.745166015625],
              [-163.82138671875, 60.66826171875],
              [-163.72998046875, 60.589990234374994],
              [-163.5287109375, 60.66455078125],
              [-163.420947265625, 60.757421875],
              [-163.511865234375, 60.79814453125],
              [-163.623046875, 60.822216796875],
              [-163.90654296875, 60.85380859375],
              [-163.8373046875, 60.880419921875],
              [-163.655419921875, 60.877490234375],
              [-163.5869140625, 60.902978515624994],
              [-163.658935546875, 60.938232421875],
              [-163.7490234375, 60.9697265625],
              [-163.99462890625, 60.864697265625],
              [-164.441552734375, 60.869970703125],
              [-164.753955078125, 60.931298828124994],
              [-165.065625, 60.920654296875],
              [-165.11484375, 60.9328125],
              [-165.17548828125, 60.965673828125],
              [-164.99990234375, 61.04365234375],
              [-164.8755859375, 61.086767578125],
              [-164.868994140625, 61.111767578125],
              [-164.9412109375, 61.114892578124994],
              [-165.077099609375, 61.094189453125],
              [-165.1376953125, 61.130126953125],
              [-165.127783203125, 61.192431640625],
              [-165.150048828125, 61.186865234375],
              [-165.203759765625, 61.15283203125],
              [-165.27978515625, 61.16962890625],
              [-165.344873046875, 61.197705078125],
              [-165.310791015625, 61.22763671875],
              [-165.2439453125, 61.26875],
              [-165.2736328125, 61.274853515625],
              [-165.33369140625, 61.26611328125],
              [-165.392041015625, 61.212304687499994],
              [-165.379296875, 61.16875],
              [-165.38076171875, 61.106298828125],
              [-165.48046875, 61.094873046874994],
              [-165.565869140625, 61.10234375],
              [-165.627587890625, 61.165185546874994],
              [-165.691357421875, 61.29990234375],
              [-165.86396484375, 61.335693359375],
              [-165.906298828125, 61.40380859375],
              [-165.797119140625, 61.491162109375],
              [-165.8453125, 61.53623046875],
              [-165.961328125, 61.55087890625],
              [-166.093994140625, 61.506738281249994],
              [-166.152734375, 61.545947265625],
              [-166.163525390625, 61.589013671874994],
              [-166.168115234375, 61.650830078125],
              [-166.13115234375, 61.65732421875],
              [-166.10048828125, 61.645068359375],
              [-165.8345703125, 61.67939453125],
              [-165.808935546875, 61.69609375],
              [-166.019921875, 61.748291015625],
              [-166.07880859375, 61.803125],
              [-165.99140625, 61.8341796875],
              [-165.833837890625, 61.836816406249994],
              [-165.61279296875, 61.869287109374994],
              [-165.705810546875, 61.92744140625],
              [-165.725244140625, 61.959375],
              [-165.7439453125, 62.01171875],
              [-165.707275390625, 62.100439453125],
              [-165.44765625, 62.30390625],
              [-165.19453125, 62.473535156249994],
              [-165.115625, 62.5126953125],
              [-164.99970703125, 62.5337890625],
              [-164.891845703125, 62.517578125],
              [-164.77919921875, 62.48115234375],
              [-164.757861328125, 62.496728515624994],
              [-164.79609375, 62.511621093749994],
              [-164.844384765625, 62.5810546875],
              [-164.68798828125, 62.608251953125],
              [-164.5962890625, 62.686669921874994],
              [-164.589453125, 62.709375],
              [-164.68896484375, 62.6767578125],
              [-164.79267578125, 62.623193359374994],
              [-164.81865234375, 62.67705078125],
              [-164.84541015625, 62.8009765625],
              [-164.799658203125, 62.91806640625],
              [-164.7640625, 62.970605468749994],
              [-164.67744140625, 63.020458984375],
              [-164.428125, 63.040429687499994],
              [-164.384228515625, 63.03046875],
              [-164.37509765625, 63.05400390625],
              [-164.5251953125, 63.12763671875],
              [-164.46328125, 63.185205078124994],
              [-164.409033203125, 63.2150390625],
              [-164.1076171875, 63.26171875],
              [-163.94287109375, 63.247216796874994],
              [-163.73623046875, 63.192822265625],
              [-163.61630859375, 63.125146484374994],
              [-163.633740234375, 63.0904296875],
              [-163.66357421875, 63.0703125],
              [-163.725732421875, 63.047802734375],
              [-163.748974609375, 63.030322265625],
              [-163.737841796875, 63.01640625],
              [-163.649365234375, 63.056787109374994],
              [-163.504345703125, 63.105859375],
              [-163.423193359375, 63.084521484375],
              [-163.358837890625, 63.045751953125],
              [-163.287841796875, 63.046435546875],
              [-163.062255859375, 63.079736328124994],
              [-162.947705078125, 63.114990234375],
              [-162.807763671875, 63.206591796875],
              [-162.621484375, 63.2658203125],
              [-162.359814453125, 63.452587890625],
              [-162.2828125, 63.529199218749994],
              [-162.193310546875, 63.540966796875],
              [-162.1125, 63.5341796875],
              [-162.05625, 63.471337890624994],
              [-161.973974609375, 63.4529296875],
              [-161.505419921875, 63.468164062499994],
              [-161.266015625, 63.496972656249994],
              [-161.09970703125, 63.55791015625],
              [-160.926708984375, 63.660546875],
              [-160.826513671875, 63.729345703125],
              [-160.778564453125, 63.8189453125],
              [-160.840478515625, 63.934912109375],
              [-160.903955078125, 64.031201171875],
              [-160.987548828125, 64.25126953125],
              [-161.2201171875, 64.39658203125],
              [-161.385693359375, 64.43994140625],
              [-161.49072265625, 64.4337890625],
              [-161.414599609375, 64.5263671875],
              [-161.19306640625, 64.51640625],
              [-161.048779296875, 64.53447265625],
              [-160.93193359375, 64.5791015625],
              [-160.893701171875, 64.612890625],
              [-160.83603515625, 64.68193359375],
              [-160.855908203125, 64.755615234375],
              [-160.886962890625, 64.795556640625],
              [-160.96748046875, 64.83955078125],
              [-161.063232421875, 64.90400390625],
              [-161.13017578125, 64.925439453125],
              [-161.1869140625, 64.9240234375],
              [-161.466357421875, 64.794873046875],
              [-161.633984375, 64.79248046875],
              [-161.759375, 64.816259765625],
              [-161.868310546875, 64.74267578125],
              [-162.172265625, 64.678076171875],
              [-162.334619140625, 64.612841796875],
              [-162.6357421875, 64.450830078125],
              [-162.711083984375, 64.3775390625],
              [-162.80703125, 64.37421875],
              [-162.876416015625, 64.51640625],
              [-163.20390625, 64.652001953125],
              [-163.30283203125, 64.605908203125],
              [-163.248291015625, 64.56328125],
              [-163.174072265625, 64.532958984375],
              [-163.0517578125, 64.5197265625],
              [-163.1044921875, 64.47861328125],
              [-163.1443359375, 64.423828125],
              [-163.267041015625, 64.4751953125],
              [-163.486181640625, 64.5498046875],
              [-163.7130859375, 64.588232421875],
              [-164.303955078125, 64.583935546875],
              [-164.691845703125, 64.507421875],
              [-164.727490234375, 64.523291015625],
              [-164.76494140625, 64.529638671875],
              [-164.829541015625, 64.511376953125],
              [-164.857275390625, 64.480322265625],
              [-164.89951171875, 64.46064453125],
              [-164.978759765625, 64.453662109375],
              [-165.138134765625, 64.465234375],
              [-165.44619140625, 64.512841796875],
              [-166.1427734375, 64.582763671875],
              [-166.32509765625, 64.625732421875],
              [-166.481396484375, 64.728076171875],
              [-166.478125, 64.79755859375],
              [-166.40869140625, 64.826953125],
              [-166.415234375, 64.926513671875],
              [-166.55087890625, 64.952978515625],
              [-166.826953125, 65.09609375],
              [-166.92841796875, 65.157080078125],
              [-166.906396484375, 65.163818359375],
              [-166.856787109375, 65.147265625],
              [-166.762548828125, 65.134912109375],
              [-166.531005859375, 65.154736328125],
              [-166.45166015625, 65.247314453125],
              [-166.2796875, 65.273779296875],
              [-166.121484375, 65.2607421875],
              [-166.15703125, 65.28583984375],
              [-166.197412109375, 65.30556640625],
              [-166.609375, 65.352734375],
              [-166.665380859375, 65.33828125],
              [-167.40400390625, 65.422119140625],
              [-167.987255859375, 65.5677734375],
              [-168.035009765625, 65.59560546875],
              [-168.08837890625, 65.657763671875],
              [-168.00966796875, 65.719140625],
              [-167.93056640625, 65.74814453125],
              [-167.927001953125, 65.71435546875],
              [-167.91435546875, 65.681201171875],
              [-167.580029296875, 65.75830078125],
              [-167.405322265625, 65.859326171875],
              [-167.07421875, 65.87705078125],
              [-166.997216796875, 65.904931640625],
              [-166.89443359375, 65.9591796875],
              [-166.74765625, 66.05185546875],
              [-166.54013671875, 66.100634765625],
              [-166.39873046875, 66.14443359375],
              [-166.214599609375, 66.170263671875],
              [-166.057421875, 66.12724609375],
              [-166.008935546875, 66.121337890625],
              [-165.723681640625, 66.112548828125],
              [-165.629931640625, 66.131201171875],
              [-165.589990234375, 66.1451171875],
              [-165.560205078125, 66.16708984375],
              [-165.840234375, 66.245068359375],
              [-165.811865234375, 66.2884765625],
              [-165.776171875, 66.31904296875],
              [-165.4494140625, 66.409912109375],
              [-165.198291015625, 66.43994140625],
              [-165.06396484375, 66.437841796875],
              [-164.67412109375, 66.555029296875],
              [-164.460498046875, 66.588427734375],
              [-164.058251953125, 66.6107421875],
              [-163.727685546875, 66.616455078125],
              [-163.638232421875, 66.574658203125],
              [-163.81572265625, 66.58349609375],
              [-163.8939453125, 66.57587890625],
              [-163.838232421875, 66.561572265625],
              [-163.77548828125, 66.531103515625],
              [-163.793701171875, 66.492626953125],
              [-163.902880859375, 66.378369140625],
              [-163.8939453125, 66.2869140625],
              [-163.964990234375, 66.25732421875],
              [-164.033740234375, 66.21552734375],
              [-163.695361328125, 66.083837890625],
              [-163.171435546875, 66.075439453125],
              [-162.886474609375, 66.09921875],
              [-162.72177734375, 66.059814453125],
              [-162.586865234375, 66.050830078125],
              [-162.2142578125, 66.071044921875],
              [-161.93369140625, 66.04287109375],
              [-161.81630859375, 66.053662109375],
              [-161.5568359375, 66.250537109375],
              [-161.455419921875, 66.281396484375],
              [-161.345068359375, 66.24716796875],
              [-161.20107421875, 66.219384765625],
              [-161.109228515625, 66.239501953125],
              [-161.03427734375, 66.188818359375],
              [-161.06953125, 66.29462890625],
              [-161.1203125, 66.334326171875],
              [-161.54443359375, 66.40703125],
              [-161.828173828125, 66.370849609375],
              [-161.91689453125, 66.41181640625],
              [-161.88759765625, 66.49306640625],
              [-162.191162109375, 66.693115234375],
              [-162.317724609375, 66.73369140625],
              [-162.467431640625, 66.73564453125],
              [-162.54365234375, 66.805126953125],
              [-162.607421875, 66.894384765625],
              [-162.4783203125, 66.930810546875],
              [-162.36162109375, 66.947314453125],
              [-162.253564453125, 66.91865234375],
              [-162.131396484375, 66.8013671875],
              [-162.017626953125, 66.784130859375],
              [-162.050732421875, 66.66728515625],
              [-161.9095703125, 66.559619140625],
              [-161.591015625, 66.459521484375],
              [-161.3359375, 66.496337890625],
              [-161.155810546875, 66.4953125],
              [-161.04814453125, 66.47421875],
              [-160.78447265625, 66.384375],
              [-160.650537109375, 66.373095703125],
              [-160.231689453125, 66.420263671875],
              [-160.22734375, 66.508544921875],
              [-160.262548828125, 66.5724609375],
              [-160.360888671875, 66.6125],
              [-160.643798828125, 66.60498046875],
              [-160.864013671875, 66.670849609375],
              [-161.05146484375, 66.652783203125],
              [-161.398046875, 66.55185546875],
              [-161.571728515625, 66.5916015625],
              [-161.680908203125, 66.6455078125],
              [-161.856689453125, 66.700341796875],
              [-161.878759765625, 66.803955078125],
              [-161.731298828125, 66.922802734375],
              [-161.622216796875, 66.979345703125],
              [-161.719921875, 67.020556640625],
              [-161.9654296875, 67.049560546875],
              [-162.391552734375, 67.019873046875],
              [-162.411572265625, 67.060302734375],
              [-162.409423828125, 67.103955078125],
              [-162.58310546875, 67.018505859375],
              [-162.76142578125, 67.03642578125],
              [-163.001708984375, 67.027294921875],
              [-163.5318359375, 67.102587890625],
              [-163.720556640625, 67.195556640625],
              [-163.7998046875, 67.27099609375],
              [-163.94267578125, 67.477587890625],
              [-164.1251953125, 67.60673828125],
              [-165.38603515625, 68.04560546875],
              [-165.9595703125, 68.155908203125],
              [-166.2359375, 68.2779296875],
              [-166.409130859375, 68.307958984375],
              [-166.574462890625, 68.320263671875],
              [-166.786279296875, 68.359619140625],
              [-166.643896484375, 68.4080078125],
              [-166.5458984375, 68.424365234375],
              [-166.6478515625, 68.373828125],
              [-166.57041015625, 68.361083984375],
              [-166.447021484375, 68.390234375],
              [-166.380517578125, 68.425146484375],
              [-166.282958984375, 68.5732421875],
              [-166.18203125, 68.797216796875],
              [-166.20908203125, 68.8853515625],
              [-165.50947265625, 68.867578125],
              [-165.0439453125, 68.882470703125],
              [-164.889697265625, 68.90244140625],
              [-164.30234375, 68.936474609375],
              [-164.1501953125, 68.961181640625],
              [-163.867919921875, 69.036669921875],
              [-163.535693359375, 69.1701171875],
              [-163.250537109375, 69.345361328125],
              [-163.20517578125, 69.392529296875],
              [-163.187109375, 69.38046875],
              [-163.161474609375, 69.387939453125],
              [-163.131005859375, 69.454345703125],
              [-163.0935546875, 69.610693359375],
              [-162.952099609375, 69.75810546875],
              [-162.350390625, 70.094140625],
              [-162.071142578125, 70.227197265625],
              [-161.977978515625, 70.287646484375],
              [-161.88095703125, 70.33173828125],
              [-161.81259765625, 70.28984375],
              [-161.779931640625, 70.27734375],
              [-161.761083984375, 70.257666015625],
              [-161.818408203125, 70.2484375],
              [-161.911962890625, 70.20546875],
              [-162.0423828125, 70.17666015625],
              [-162.073876953125, 70.161962890625],
              [-161.997412109375, 70.165234375],
              [-161.7681640625, 70.196533203125],
              [-161.639013671875, 70.234521484375],
              [-160.9962890625, 70.30458984375],
              [-160.64765625, 70.420556640625],
              [-160.634130859375, 70.44638671875],
              [-160.117138671875, 70.5912109375],
              [-160.04560546875, 70.585595703125],
              [-159.963134765625, 70.5681640625],
              [-160.106396484375, 70.47255859375],
              [-160.00556640625, 70.44755859375],
              [-160.095068359375, 70.33330078125],
              [-159.907568359375, 70.3314453125],
              [-159.865673828125, 70.278857421875],
              [-159.855224609375, 70.324169921875],
              [-159.85751953125, 70.3892578125],
              [-159.842626953125, 70.45302734375],
              [-159.814990234375, 70.4970703125],
              [-159.68330078125, 70.4771484375],
              [-159.386767578125, 70.52451171875],
              [-159.74619140625, 70.53046875],
              [-159.96181640625, 70.63408203125],
              [-160.081591796875, 70.63486328125],
              [-159.680908203125, 70.786767578125],
              [-159.314501953125, 70.878515625],
              [-159.23173828125, 70.8767578125],
              [-159.191748046875, 70.85966796875],
              [-159.183154296875, 70.83193359375],
              [-159.26220703125, 70.8138671875],
              [-159.33984375, 70.78125],
              [-159.304150390625, 70.7525390625],
              [-159.251171875, 70.7484375],
              [-159.075048828125, 70.7720703125],
              [-158.9962890625, 70.801611328125],
              [-158.620947265625, 70.7990234375],
              [-158.51083984375, 70.8201171875],
              [-158.484375, 70.841064453125],
              [-157.998486328125, 70.8453125],
              [-157.909375, 70.860107421875],
              [-157.605615234375, 70.941259765625],
              [-157.324755859375, 71.039599609375],
              [-157.1953125, 71.09326171875],
              [-156.97333984375, 71.230029296875],
              [-156.78330078125, 71.3189453125],
              [-156.47021484375, 71.407666015625],
              [-156.395263671875, 71.3966796875],
              [-156.4966796875, 71.3791015625],
              [-156.567236328125, 71.341552734375],
              [-156.469970703125, 71.291552734375],
              [-155.8111328125, 71.188427734375],
              [-155.64560546875, 71.182763671875],
              [-155.579443359375, 71.12109375],
              [-155.6345703125, 71.061572265625],
              [-155.804345703125, 70.99541015625],
              [-156.14658203125, 70.92783203125],
              [-156.041943359375, 70.90224609375],
              [-155.97353515625, 70.8419921875],
              [-155.872216796875, 70.83466796875],
              [-155.708056640625, 70.857275390625],
              [-155.57939453125, 70.8943359375],
              [-155.31337890625, 71.014990234375],
              [-155.229736328125, 71.0822265625],
              [-155.166845703125, 71.09921875],
              [-154.943798828125, 71.083056640625],
              [-154.817529296875, 71.048486328125],
              [-154.673681640625, 70.987109375],
              [-154.726318359375, 70.927783203125],
              [-154.785205078125, 70.894287109375],
              [-154.5986328125, 70.847998046875],
              [-154.3921875, 70.838330078125],
              [-154.19521484375, 70.801123046875],
              [-153.918212890625, 70.87734375],
              [-153.7013671875, 70.893603515625],
              [-153.497705078125, 70.891064453125],
              [-153.23291015625, 70.932568359375],
              [-152.784912109375, 70.876025390625],
              [-152.670849609375, 70.89072265625],
              [-152.4912109375, 70.88095703125],
              [-152.300390625, 70.84677734375],
              [-152.23291015625, 70.8103515625],
              [-152.437255859375, 70.733251953125],
              [-152.47060546875, 70.65361328125],
              [-152.39921875, 70.620458984375],
              [-152.269677734375, 70.61474609375],
              [-152.253369140625, 70.56826171875],
              [-152.17294921875, 70.556640625],
              [-151.76904296875, 70.56015625],
              [-151.79990234375, 70.538037109375],
              [-151.81962890625, 70.511328125],
              [-151.944677734375, 70.452099609375],
              [-151.2248046875, 70.41875],
              [-151.12802734375, 70.451611328125],
              [-150.979052734375, 70.464697265625],
              [-150.662646484375, 70.509912109375],
              [-150.543505859375, 70.49013671875],
              [-150.40322265625, 70.443896484375],
              [-150.2736328125, 70.434326171875],
              [-150.152490234375, 70.443701171875],
              [-149.8701171875, 70.50966796875],
              [-149.54404296875, 70.512890625],
              [-149.410595703125, 70.49140625],
              [-149.26943359375, 70.50078125],
              [-148.844775390625, 70.4251953125],
              [-148.68837890625, 70.41630859375],
              [-148.47919921875, 70.317919921875],
              [-148.371142578125, 70.314990234375],
              [-148.248779296875, 70.35673828125],
              [-148.142724609375, 70.35546875],
              [-148.0390625, 70.315478515625],
              [-147.86953125, 70.303271484375],
              [-147.790576171875, 70.24013671875],
              [-147.70537109375, 70.217236328125],
              [-147.062939453125, 70.17041015625],
              [-146.744873046875, 70.191748046875],
              [-146.28125, 70.1861328125],
              [-146.057666015625, 70.15625],
              [-145.82314453125, 70.16005859375],
              [-145.440087890625, 70.050927734375],
              [-145.23681640625, 70.033935546875],
              [-145.19736328125, 70.00869140625],
              [-144.619189453125, 69.98212890625],
              [-144.41689453125, 70.039013671875],
              [-144.064111328125, 70.0541015625],
              [-143.746435546875, 70.101953125],
              [-143.56640625, 70.10146484375],
              [-143.35703125, 70.08955078125],
              [-143.27646484375, 70.0953125],
              [-143.218310546875, 70.116259765625],
              [-142.707861328125, 70.0337890625],
              [-142.422119140625, 69.939501953125],
              [-142.29697265625, 69.869873046875],
              [-141.69921875, 69.770361328125],
              [-141.5263671875, 69.714697265625],
              [-141.40791015625, 69.653369140625],
              [-141.338623046875, 69.64677734375],
              [-141.2896484375, 69.664697265625],
              [-141.080810546875, 69.659423828125],
              [-141.0021484375, 69.65078125],
              [-141.0021484375, 69.35859375],
              [-141.0021484375, 69.066357421875],
              [-141.0021484375, 68.774169921875],
              [-141.0021484375, 68.481982421875],
              [-141.0021484375, 68.18974609375],
              [-141.0021484375, 67.89755859375],
              [-141.0021484375, 67.60537109375],
              [-141.0021484375, 67.313134765625],
              [-141.0021484375, 67.020947265625],
              [-141.0021484375, 66.728759765625],
              [-141.0021484375, 66.4365234375],
              [-141.0021484375, 66.1443359375],
              [-141.0021484375, 65.85214843750003],
              [-141.0021484375, 65.55991210937498],
              [-141.0021484375, 65.26772460937514],
              [-141.0021484375, 64.97553710937513],
              [-141.0021484375, 64.68330078125013],
              [-141.0021484375, 64.39111328125006],
              [-141.0021484375, 64.09887695312506],
              [-141.0021484375, 63.806689453125],
              [-141.0021484375, 63.51445312499999],
              [-141.0021484375, 63.22226562499998],
              [-141.0021484375, 62.930078125000136],
              [-141.0021484375, 62.637890625000125],
              [-141.0021484375, 62.345703125000114],
              [-141.0021484375, 62.05346679687506],
              [-141.0021484375, 61.761279296875045],
              [-141.0021484375, 61.469042968750045],
              [-141.0021484375, 61.176855468750034],
              [-141.0021484375, 60.88466796875002],
              [-141.0021484375, 60.592431640625136],
              [-141.0021484375, 60.300244140625125],
              [-140.762744140625, 60.25913085937509],
              [-140.525439453125, 60.218359375],
              [-140.45283203125, 60.299707031249994],
              [-140.196923828125, 60.2375],
              [-139.973291015625, 60.183154296875],
              [-139.8306640625, 60.252880859375],
              [-139.676318359375, 60.32832031250001],
              [-139.46796875, 60.33369140625],
              [-139.234765625, 60.339746093749994],
              [-139.07924804687502, 60.343701171875125],
              [-139.07924804687502, 60.279443359375136],
              [-139.136962890625, 60.172705078125],
              [-139.18515625, 60.08359375],
              [-139.04345703125, 59.99326171875],
              [-138.86875, 59.945751953125],
              [-138.70546875, 59.901318359375],
              [-138.632275390625, 59.77827148437501],
              [-138.45361328125, 59.683398437500045],
              [-138.317626953125, 59.611132812500045],
              [-138.187451171875, 59.541943359375],
              [-138.00112304687502, 59.4429199218751],
              [-137.870556640625, 59.373583984375],
              [-137.696630859375, 59.281152343749994],
              [-137.593310546875, 59.22626953125001],
              [-137.543701171875, 59.1194335937501],
              [-137.4841796875, 58.9912109375],
              [-137.5208984375, 58.915380859375],
              [-137.43857421875, 58.903125],
              [-137.2775390625, 58.988183593749994],
              [-137.126220703125, 59.040966796875],
              [-136.939306640625, 59.106103515624994],
              [-136.81328125, 59.15004882812508],
              [-136.578759765625, 59.152246093749994],
              [-136.466748046875, 59.279931640624994],
              [-136.466357421875, 59.45908203125],
              [-136.3478515625, 59.456054687500114],
              [-136.277978515625, 59.480322265625],
              [-136.247119140625, 59.532910156250125],
              [-136.321826171875, 59.604833984375],
              [-136.09716796875, 59.63837890625],
              [-135.93466796875, 59.662646484375],
              [-135.702587890625, 59.728759765625],
              [-135.475927734375, 59.79326171875],
              [-135.36787109375, 59.743310546874994],
              [-135.260791015625, 59.695019531250125],
              [-135.051025390625, 59.578662109375],
              [-135.036669921875, 59.550683593749994],
              [-135.050830078125, 59.49604492187507],
              [-135.0712890625, 59.441455078125],
              [-134.94375, 59.28828125000001],
              [-134.9072265625, 59.27119140625],
              [-134.80239257812502, 59.25],
              [-134.67724609375, 59.199267578125],
              [-134.62197265625002, 59.1553222656251],
              [-134.440771484375, 59.085351562499994],
              [-134.410205078125, 59.05625],
              [-134.39306640625, 59.009179687499994],
              [-134.363525390625, 58.96875],
              [-134.329638671875, 58.93969726562506],
              [-134.29697265625, 58.898486328125],
              [-134.218505859375, 58.84990234375],
              [-134.069189453125, 58.7955078125],
              [-133.96572265625002, 58.75786132812499],
              [-133.820751953125, 58.705029296875],
              [-133.67392578125, 58.59716796875],
              [-133.54638671875, 58.50346679687499],
              [-133.401123046875, 58.410888671875],
              [-133.42255859375, 58.33706054687502],
              [-133.27529296875002, 58.22285156250001],
              [-133.12041015625, 58.077734375],
              [-133.001416015625, 57.948974609375],
              [-132.916845703125, 57.877001953124996],
              [-132.81552734375, 57.772705078125],
              [-132.69150390625, 57.6451171875],
              [-132.55048828125, 57.49990234375],
              [-132.44248046875, 57.40673828125],
              [-132.30166015625002, 57.276318359375125],
              [-132.232177734375, 57.198535156249996],
              [-132.27939453125, 57.145361328125],
              [-132.33798828125, 57.079443359375],
              [-132.15703125, 57.048193359375],
              [-132.03154296875, 57.0265625],
              [-132.06289062500002, 56.95336914062506],
              [-132.104296875, 56.856787109375],
              [-131.9625, 56.818701171875],
              [-131.866162109375, 56.792822265625],
              [-131.885986328125, 56.742138671875],
              [-131.83310546875, 56.684814453125],
              [-131.824267578125, 56.589990234375],
              [-131.651513671875, 56.59609375],
              [-131.57509765625002, 56.598828125000125],
              [-131.471875, 56.55673828125],
              [-131.335791015625, 56.501220703125],
              [-131.1994140625, 56.44921875],
              [-131.08291015625, 56.404833984374996],
              [-130.930224609375, 56.37861328125],
              [-130.74169921875, 56.3408203125],
              [-130.64907226562502, 56.26367187500006],
              [-130.477099609375, 56.230566406250034],
              [-130.413134765625, 56.122509765625],
              [-130.214697265625, 56.0828125],
              [-130.0978515625, 56.10927734375],
              [-130.05595703125, 56.065234375],
              [-130.022900390625, 56.014501953125],
              [-130.0140625, 55.950537109375],
              [-130.02509765625, 55.8882324218751],
            ],
          ],
          [
            [
              [-163.476025390625, 54.980712890625],
              [-163.378955078125, 54.81552734375],
              [-163.3369140625, 54.783203125],
              [-163.27451171875, 54.765576171875],
              [-163.187109375, 54.74775390625],
              [-163.13505859375, 54.723291015625],
              [-163.0892578125, 54.686083984374996],
              [-163.083251953125, 54.668994140624996],
              [-163.35810546875, 54.735693359375],
              [-163.530859375, 54.638330078125],
              [-163.5830078125, 54.62568359375],
              [-164.073291015625, 54.62099609375],
              [-164.1712890625, 54.60302734375],
              [-164.234619140625, 54.571337890624996],
              [-164.3466796875, 54.482421875],
              [-164.403515625, 54.4478515625],
              [-164.4634765625, 54.42734375],
              [-164.5908203125, 54.404345703124996],
              [-164.743798828125, 54.407470703125],
              [-164.8234375, 54.419091796875],
              [-164.866162109375, 54.461376953125],
              [-164.903955078125, 54.544775390625],
              [-164.9037109375, 54.56796875],
              [-164.887646484375, 54.6078125],
              [-164.75146484375, 54.662939453125],
              [-164.706201171875, 54.6919921875],
              [-164.52978515625, 54.880859375],
              [-164.47861328125, 54.9068359375],
              [-164.42431640625, 54.91318359375],
              [-164.273681640625, 54.900048828125],
              [-164.145068359375, 54.955126953124996],
              [-163.86796875, 55.039111328124996],
              [-163.80712890625, 55.049072265625],
              [-163.607470703125, 55.050830078124996],
              [-163.55302734375, 55.037841796875],
              [-163.510888671875, 55.014306640625],
              [-163.476025390625, 54.980712890625],
            ],
          ],
          [
            [
              [-133.305078125, 55.54375],
              [-133.283203125, 55.515625],
              [-133.281689453125, 55.497851562499996],
              [-133.42646484375, 55.431445312499996],
              [-133.4291015625, 55.417724609375],
              [-133.4630859375, 55.37666015625],
              [-133.49345703125, 55.361669921875],
              [-133.54736328125, 55.317236328125],
              [-133.6501953125, 55.269287109375],
              [-133.635009765625, 55.413330078125],
              [-133.737109375, 55.496923828125],
              [-133.634228515625, 55.5392578125],
              [-133.56669921875, 55.527197265625],
              [-133.45478515625, 55.522314453125],
              [-133.345556640625, 55.55908203125],
              [-133.305078125, 55.54375],
            ],
          ],
          [
            [
              [-131.33974609375, 55.079833984375],
              [-131.237451171875, 54.949511718749996],
              [-131.23203125, 54.903759765625],
              [-131.329541015625, 54.887744140624996],
              [-131.406201171875, 54.894287109375],
              [-131.445703125, 54.909326171875],
              [-131.456103515625, 54.93056640625],
              [-131.43134765625, 54.996484375],
              [-131.48173828125, 55.03525390625],
              [-131.5400390625, 55.048486328125],
              [-131.592236328125, 55.025683593749996],
              [-131.5951171875, 55.09072265625],
              [-131.556005859375, 55.13740234375],
              [-131.57783203125, 55.200830078125],
              [-131.578466796875, 55.248779296875],
              [-131.5654296875, 55.264111328125],
              [-131.512646484375, 55.262744140624996],
              [-131.404638671875, 55.213330078125],
              [-131.33974609375, 55.079833984375],
            ],
          ],
          [
            [
              [-132.112353515625, 56.109375],
              [-132.132958984375, 55.94326171875],
              [-132.172607421875, 55.95263671875],
              [-132.210302734375, 55.952978515625],
              [-132.2873046875, 55.92939453125],
              [-132.368603515625, 55.939746093749996],
              [-132.406591796875, 55.958203125],
              [-132.42060546875, 55.979541015624996],
              [-132.4060546875, 56.028857421874996],
              [-132.451171875, 56.05634765625],
              [-132.602978515625, 56.06640625],
              [-132.659912109375, 56.078173828124996],
              [-132.691357421875, 56.130078125],
              [-132.6990234375, 56.198193359375],
              [-132.6751953125, 56.2236328125],
              [-132.59873046875, 56.241650390625],
              [-132.539013671875, 56.324169921875],
              [-132.50595703125, 56.335253906249996],
              [-132.379833984375, 56.498779296875],
              [-132.31650390625, 56.4875],
              [-132.205615234375, 56.387939453125],
              [-132.06689453125, 56.24423828125],
              [-132.112353515625, 56.109375],
            ],
          ],
          [
            [
              [-130.979150390625, 55.48916015625],
              [-131.013916015625, 55.379296875],
              [-131.082763671875, 55.266796875],
              [-131.187890625, 55.206298828125],
              [-131.261865234375, 55.219775390624996],
              [-131.31630859375, 55.268505859375],
              [-131.366845703125, 55.2658203125],
              [-131.420703125, 55.27587890625],
              [-131.450927734375, 55.31630859375],
              [-131.42236328125, 55.368408203125],
              [-131.44755859375, 55.4087890625],
              [-131.47451171875, 55.373486328125],
              [-131.521826171875, 55.341064453125],
              [-131.64130859375, 55.29892578125],
              [-131.723681640625, 55.218359375],
              [-131.7625, 55.165820312499996],
              [-131.810986328125, 55.223095703125],
              [-131.8419921875, 55.358691406249996],
              [-131.84609375, 55.416259765625],
              [-131.75947265625, 55.503076171875],
              [-131.64755859375, 55.585546875],
              [-131.624951171875, 55.831689453125],
              [-131.26923828125, 55.955371093749996],
              [-131.236181640625, 55.948974609375],
              [-131.120654296875, 55.856640625],
              [-130.997802734375, 55.72763671875],
              [-130.965966796875, 55.66953125],
              [-130.9650390625, 55.568017578125],
              [-130.979150390625, 55.48916015625],
            ],
          ],
          [
            [
              [-133.3662109375, 57.003515625],
              [-133.29970703125, 56.97216796875],
              [-133.263525390625, 57.00498046875],
              [-133.19599609375, 57.003466796874996],
              [-133.07080078125, 56.974267578125],
              [-132.996240234375, 56.930419921875],
              [-132.954150390625, 56.8802734375],
              [-132.9505859375, 56.850439453125],
              [-132.963330078125, 56.782568359375],
              [-132.95400390625, 56.7130859375],
              [-132.9591796875, 56.677050781249996],
              [-132.97587890625, 56.647265625],
              [-133.0041015625, 56.62373046875],
              [-133.034912109375, 56.620751953125],
              [-133.132373046875, 56.683251953125],
              [-133.243994140625, 56.795849609375],
              [-133.328955078125, 56.830078125],
              [-133.332421875, 56.818505859375],
              [-133.30908203125, 56.78623046875],
              [-133.239697265625, 56.72568359375],
              [-133.22724609375, 56.689257812499996],
              [-133.178466796875, 56.644824218749996],
              [-133.156640625, 56.611132812499996],
              [-133.14423828125, 56.56689453125],
              [-133.1447265625, 56.52822265625],
              [-133.158154296875, 56.495166015624996],
              [-133.180810546875, 56.473974609375],
              [-133.212646484375, 56.464599609375],
              [-133.382763671875, 56.473876953125],
              [-133.4841796875, 56.4517578125],
              [-133.602783203125, 56.464111328125],
              [-133.63134765625, 56.484033203125],
              [-133.649267578125, 56.516796875],
              [-133.65830078125, 56.5962890625],
              [-133.68818359375, 56.710009765624996],
              [-133.68095703125, 56.797509765625],
              [-133.75751953125, 56.87666015625],
              [-133.823046875, 56.924365234374996],
              [-133.91728515625, 56.967089843749996],
              [-133.979443359375, 57.009570312499996],
              [-133.962353515625, 57.04345703125],
              [-133.865966796875, 57.068701171875],
              [-133.70771484375, 57.062841796875],
              [-133.3662109375, 57.003515625],
            ],
          ],
          [
            [
              [-132.862255859375, 54.89443359375],
              [-132.837744140625, 54.88095703125],
              [-132.812890625, 54.890429687499996],
              [-132.772314453125, 54.926074218749996],
              [-132.700634765625, 54.91904296875],
              [-132.648876953125, 54.907080078125],
              [-132.617236328125, 54.892431640625],
              [-132.634033203125, 54.840478515625],
              [-132.64697265625, 54.756152343749996],
              [-132.67666015625, 54.726220703125],
              [-132.705810546875, 54.6841796875],
              [-132.807275390625, 54.709130859375],
              [-132.889599609375, 54.762646484375],
              [-133.008935546875, 54.854833984375],
              [-133.075390625, 54.921337890625],
              [-133.08056640625, 54.9494140625],
              [-133.122705078125, 54.96982421875],
              [-133.204638671875, 55.08447265625],
              [-133.251171875, 55.175146484375],
              [-133.324853515625, 55.185498046875],
              [-133.41796875, 55.210693359375],
              [-133.45380859375, 55.2603515625],
              [-133.429052734375, 55.30380859375],
              [-133.29658203125, 55.325732421874996],
              [-133.097412109375, 55.213720703125],
              [-133.06708984375, 55.1662109375],
              [-132.995751953125, 55.110595703125],
              [-132.982177734375, 55.033007812499996],
              [-132.94599609375, 55.002587890625],
              [-132.862255859375, 54.89443359375],
            ],
          ],
          [
            [
              [-146.3939453125, 60.449658203125],
              [-146.3716796875, 60.42216796875],
              [-146.179541015625, 60.428759765625],
              [-146.124267578125, 60.42392578125],
              [-146.10224609375, 60.411181640625],
              [-146.128271484375, 60.392529296875],
              [-146.202392578125, 60.368017578125],
              [-146.419189453125, 60.325048828125],
              [-146.5953125, 60.268457031249994],
              [-146.618310546875, 60.273681640625],
              [-146.650439453125, 60.33564453125],
              [-146.6830078125, 60.360693359375],
              [-146.702880859375, 60.39560546875],
              [-146.7025390625, 60.408544921875],
              [-146.670263671875, 60.4326171875],
              [-146.605908203125, 60.467822265625],
              [-146.560302734375, 60.48056640625],
              [-146.3939453125, 60.449658203125],
            ],
          ],
          [
            [
              [-147.735888671875, 59.813232421875],
              [-147.846337890625, 59.798828125],
              [-147.8724609375, 59.828369140625],
              [-147.81435546875, 59.901953125],
              [-147.76806640625, 59.94375],
              [-147.733642578125, 59.95361328125],
              [-147.606689453125, 60.03662109375],
              [-147.4658203125, 60.097021484375],
              [-147.3365234375, 60.1853515625],
              [-147.205224609375, 60.311328125],
              [-147.180859375, 60.358251953125],
              [-147.12001953125, 60.3630859375],
              [-147.019873046875, 60.3322265625],
              [-146.957861328125, 60.2888671875],
              [-146.98671875, 60.254345703125],
              [-147.31845703125, 60.07529296875],
              [-147.346337890625, 60.051953125],
              [-147.376513671875, 59.991162109375],
              [-147.40380859375, 59.969970703125],
              [-147.44755859375, 59.96025390625],
              [-147.47939453125, 59.93369140625],
              [-147.49931640625, 59.890185546875],
              [-147.540234375, 59.867529296875],
              [-147.60205078125, 59.865576171875],
              [-147.644921875, 59.85361328125],
              [-147.66875, 59.83154296875],
              [-147.735888671875, 59.813232421875],
            ],
          ],
          [
            [
              [-147.658251953125, 60.45048828125],
              [-147.65869140625, 60.42412109375],
              [-147.6900390625, 60.398876953125],
              [-147.6599609375, 60.352490234375],
              [-147.71201171875, 60.27275390625],
              [-147.73212890625, 60.222070312499994],
              [-147.759912109375, 60.190234375],
              [-147.787841796875, 60.1779296875],
              [-147.8158203125, 60.18515625],
              [-147.8216796875, 60.202734375],
              [-147.8052734375, 60.2306640625],
              [-147.871337890625, 60.22978515625],
              [-147.891455078125, 60.2994140625],
              [-147.8548828125, 60.321435546874994],
              [-147.84169921875, 60.35126953125],
              [-147.83759765625, 60.3712890625],
              [-147.79453125, 60.459863281249994],
              [-147.779150390625, 60.466064453125],
              [-147.774169921875, 60.444970703124994],
              [-147.760205078125, 60.43876953125],
              [-147.7373046875, 60.447412109374994],
              [-147.702978515625, 60.48681640625],
              [-147.68857421875, 60.49140625],
              [-147.658251953125, 60.45048828125],
            ],
          ],
          [
            [
              [-147.930712890625, 60.826171875],
              [-148.057421875, 60.817919921875],
              [-148.1154296875, 60.830615234375],
              [-148.123779296875, 60.8443359375],
              [-148.09970703125, 60.89482421875],
              [-148.10166015625, 60.91611328125],
              [-148.037744140625, 60.92412109375],
              [-147.964404296875, 60.900146484375],
              [-147.943115234375, 60.875390625],
              [-147.930712890625, 60.826171875],
            ],
          ],
          [
            [
              [-153.007080078125, 57.124853515625],
              [-153.134228515625, 57.092578125],
              [-153.1568359375, 57.0939453125],
              [-153.235400390625, 57.028613281249996],
              [-153.29541015625, 57.000439453125],
              [-153.374609375, 57.051904296875],
              [-153.354345703125, 57.13193359375],
              [-153.285205078125, 57.18505859375],
              [-152.93544921875, 57.167333984375],
              [-152.9083984375, 57.15244140625],
              [-152.907763671875, 57.13974609375],
              [-152.933447265625, 57.129248046875],
              [-153.007080078125, 57.124853515625],
            ],
          ],
          [
            [
              [-152.486083984375, 58.485009765624994],
              [-152.51552734375, 58.47861328125],
              [-152.588623046875, 58.509228515625],
              [-152.63662109375, 58.54169921875],
              [-152.6048828125, 58.56640625],
              [-152.46318359375, 58.618505859375],
              [-152.3955078125, 58.619384765625],
              [-152.367919921875, 58.611083984375],
              [-152.3568359375, 58.594970703125],
              [-152.362255859375, 58.570849609375],
              [-152.392822265625, 58.540869140625],
              [-152.486083984375, 58.485009765624994],
            ],
          ],
          [
            [
              [-153.240625, 57.85009765625],
              [-153.2685546875, 57.82236328125],
              [-153.294970703125, 57.8294921875],
              [-153.350830078125, 57.861962890625],
              [-153.4650390625, 57.909375],
              [-153.51708984375, 57.94189453125],
              [-153.520068359375, 57.95576171875],
              [-153.4810546875, 57.971044921875],
              [-153.34697265625, 57.9328125],
              [-153.2900390625, 57.897900390625],
              [-153.240625, 57.85009765625],
            ],
          ],
          [
            [
              [-155.566015625, 55.82119140625],
              [-155.6048828125, 55.78955078125],
              [-155.680615234375, 55.791845703125],
              [-155.723193359375, 55.802197265625],
              [-155.737353515625, 55.82978515625],
              [-155.62060546875, 55.9130859375],
              [-155.5939453125, 55.92431640625],
              [-155.5732421875, 55.92109375],
              [-155.563916015625, 55.886669921875],
              [-155.566015625, 55.82119140625],
            ],
          ],
          [
            [
              [-154.6828125, 56.435791015625],
              [-154.751220703125, 56.412158203124996],
              [-154.77392578125, 56.420263671875],
              [-154.7771484375, 56.439892578125],
              [-154.7609375, 56.471142578125],
              [-154.729345703125, 56.5021484375],
              [-154.62373046875, 56.561328125],
              [-154.517529296875, 56.600537109375],
              [-154.46337890625, 56.598193359374996],
              [-154.444873046875, 56.573193359375],
              [-154.511181640625, 56.521435546875],
              [-154.6828125, 56.435791015625],
            ],
          ],
          [
            [
              [-154.208642578125, 56.514892578125],
              [-154.2578125, 56.5126953125],
              [-154.33212890625, 56.539013671875],
              [-154.322216796875, 56.570605468749996],
              [-154.216748046875, 56.608740234375],
              [-154.110400390625, 56.6029296875],
              [-154.10224609375, 56.581640625],
              [-154.107177734375, 56.5578125],
              [-154.115966796875, 56.543896484375],
              [-154.1498046875, 56.529589843749996],
              [-154.208642578125, 56.514892578125],
            ],
          ],
          [
            [
              [-160.684912109375, 55.314794921875],
              [-160.6697265625, 55.314257812499996],
              [-160.638818359375, 55.321923828125],
              [-160.573974609375, 55.378271484375],
              [-160.552783203125, 55.38076171875],
              [-160.552490234375, 55.36337890625],
              [-160.583154296875, 55.3076171875],
              [-160.531201171875, 55.233203125],
              [-160.482666015625, 55.197412109375],
              [-160.487548828125, 55.184863281249996],
              [-160.60908203125, 55.159033203125],
              [-160.701806640625, 55.17763671875],
              [-160.750634765625, 55.171191406249996],
              [-160.795068359375, 55.14521484375],
              [-160.82548828125, 55.173974609375],
              [-160.846533203125, 55.311328125],
              [-160.8396484375, 55.335400390625],
              [-160.789208984375, 55.383105468749996],
              [-160.72392578125, 55.404638671875],
              [-160.695654296875, 55.39833984375],
              [-160.67216796875, 55.37939453125],
              [-160.666357421875, 55.359423828124996],
              [-160.684912109375, 55.314794921875],
            ],
          ],
          [
            [
              [-162.29814453125, 54.847021484375],
              [-162.321923828125, 54.8423828125],
              [-162.390771484375, 54.872998046875],
              [-162.415771484375, 54.8958984375],
              [-162.43388671875, 54.93154296875],
              [-162.29365234375, 54.982861328125],
              [-162.264599609375, 54.98349609375],
              [-162.23837890625, 54.954736328125],
              [-162.233740234375, 54.93203125],
              [-162.27255859375, 54.8671875],
              [-162.29814453125, 54.847021484375],
            ],
          ],
          [
            [
              [-162.55439453125, 54.4013671875],
              [-162.64111328125, 54.379541015625],
              [-162.73310546875, 54.402294921875],
              [-162.81171875, 54.444384765624996],
              [-162.820556640625, 54.49453125],
              [-162.64541015625, 54.462060546875],
              [-162.607958984375, 54.446630859375],
              [-162.55439453125, 54.4013671875],
            ],
          ],
          [
            [
              [-159.872998046875, 55.128759765625],
              [-159.933935546875, 55.1068359375],
              [-159.953076171875, 55.078955078125],
              [-159.9994140625, 55.0671875],
              [-160.038427734375, 55.044482421874996],
              [-160.169580078125, 54.941699218749996],
              [-160.22705078125, 54.922705078125],
              [-160.16357421875, 55.01044921875],
              [-160.15361328125, 55.038330078125],
              [-160.152392578125, 55.056884765625],
              [-160.1720703125, 55.123046875],
              [-160.133740234375, 55.120166015624996],
              [-160.102197265625, 55.13388671875],
              [-160.03876953125, 55.192529296875],
              [-159.981640625, 55.19775390625],
              [-159.920458984375, 55.267529296875],
              [-159.887353515625, 55.272998046874996],
              [-159.871044921875, 55.26357421875],
              [-159.8982421875, 55.2212890625],
              [-159.839404296875, 55.182373046875],
              [-159.8541015625, 55.144677734375],
              [-159.872998046875, 55.128759765625],
            ],
          ],
          [
            [
              [-165.841552734375, 54.070654296875],
              [-165.87939453125, 54.053027343749996],
              [-165.90986328125, 54.049169921875],
              [-165.93291015625, 54.0591796875],
              [-166.03642578125, 54.047167968749996],
              [-166.056640625, 54.054345703125],
              [-166.10283203125, 54.11396484375],
              [-166.105810546875, 54.144824218749996],
              [-166.087744140625, 54.169140625],
              [-166.041259765625, 54.191259765625],
              [-165.96640625, 54.21103515625],
              [-165.89287109375, 54.206982421875],
              [-165.764453125, 54.152099609375],
              [-165.704248046875, 54.119921875],
              [-165.69287109375, 54.099902343749996],
              [-165.737890625, 54.081103515624996],
              [-165.841552734375, 54.070654296875],
            ],
          ],
          [
            [
              [-165.5611328125, 54.13671875],
              [-165.604833984375, 54.129150390625],
              [-165.615380859375, 54.13955078125],
              [-165.6205078125, 54.183544921875],
              [-165.654150390625, 54.2533203125],
              [-165.59033203125, 54.278662109375],
              [-165.550634765625, 54.284521484375],
              [-165.5337890625, 54.273876953125],
              [-165.4876953125, 54.221875],
              [-165.441748046875, 54.2080078125],
              [-165.407861328125, 54.196826171874996],
              [-165.467578125, 54.180908203125],
              [-165.5611328125, 54.13671875],
            ],
          ],
          [
            [
              [-160.918994140625, 58.577099609375],
              [-160.9923828125, 58.56103515625],
              [-161.070263671875, 58.569140625],
              [-161.131494140625, 58.668212890625],
              [-161.0845703125, 58.671289062499994],
              [-160.98623046875, 58.73642578125],
              [-160.768603515625, 58.789208984374994],
              [-160.71513671875, 58.79521484375],
              [-160.918994140625, 58.577099609375],
            ],
          ],
          [
            [
              [-172.742236328125, 60.457373046875],
              [-172.52607421875, 60.391748046874994],
              [-172.3875, 60.398486328125],
              [-172.2775390625, 60.343652343749994],
              [-172.232080078125, 60.29912109375],
              [-172.39716796875, 60.331103515625],
              [-172.6357421875, 60.328857421875],
              [-172.9583984375, 60.462792968749994],
              [-173.0740234375, 60.493212890625],
              [-173.04765625, 60.568310546875],
              [-172.923876953125, 60.606835937499994],
              [-172.860205078125, 60.5056640625],
              [-172.742236328125, 60.457373046875],
            ],
          ],
          [
            [
              [-170.160546875, 57.183935546875],
              [-170.264013671875, 57.136767578124996],
              [-170.3580078125, 57.15419921875],
              [-170.385888671875, 57.18857421875],
              [-170.38662109375, 57.20302734375],
              [-170.116162109375, 57.241796875],
              [-170.160546875, 57.183935546875],
            ],
          ],
          [
            [
              [-169.691943359375, 52.84736328125],
              [-169.70810546875, 52.80712890625],
              [-169.72275390625, 52.792333984375],
              [-169.87734375, 52.813769531249996],
              [-169.98056640625, 52.806005859375],
              [-169.991845703125, 52.829833984375],
              [-169.982568359375, 52.851025390625],
              [-169.820654296875, 52.8833984375],
              [-169.7548828125, 52.883642578125],
              [-169.710986328125, 52.866748046874996],
              [-169.691943359375, 52.84736328125],
            ],
          ],
          [
            [
              [-170.7333984375, 52.581494140625],
              [-170.79736328125, 52.549755859375],
              [-170.816064453125, 52.5615234375],
              [-170.82705078125, 52.600732421875],
              [-170.791162109375, 52.63125],
              [-170.682080078125, 52.697558593749996],
              [-170.608056640625, 52.68505859375],
              [-170.584619140625, 52.667578125],
              [-170.58662109375, 52.642431640625],
              [-170.614013671875, 52.609619140625],
              [-170.649267578125, 52.593115234375],
              [-170.69228515625, 52.59296875],
              [-170.7333984375, 52.581494140625],
            ],
          ],
          [
            [
              [-172.464794921875, 52.272265625],
              [-172.539111328125, 52.257470703125],
              [-172.61982421875, 52.2728515625],
              [-172.582177734375, 52.325634765625],
              [-172.54365234375, 52.353808593749996],
              [-172.47041015625, 52.388037109375],
              [-172.38310546875, 52.37294921875],
              [-172.313623046875, 52.32958984375],
              [-172.464794921875, 52.272265625],
            ],
          ],
          [
            [
              [-169.755224609375, 56.635058593749996],
              [-169.62392578125, 56.61513671875],
              [-169.55048828125, 56.628125],
              [-169.485693359375, 56.617724609374996],
              [-169.47431640625, 56.594042968749996],
              [-169.586865234375, 56.542431640625],
              [-169.6326171875, 56.545703125],
              [-169.766162109375, 56.607958984374996],
              [-169.755224609375, 56.635058593749996],
            ],
          ],
          [
            [
              [-176.28671875, 51.7919921875],
              [-176.349658203125, 51.73330078125],
              [-176.39609375, 51.75986328125],
              [-176.413720703125, 51.840576171875],
              [-176.378564453125, 51.861132812499996],
              [-176.280224609375, 51.80283203125],
              [-176.28671875, 51.7919921875],
            ],
          ],
          [
            [
              [-176.021533203125, 52.00244140625],
              [-176.045068359375, 51.972998046875],
              [-176.14287109375, 52.004296875],
              [-176.1775390625, 52.029833984374996],
              [-176.184521484375, 52.0560546875],
              [-176.1556640625, 52.099414062499996],
              [-176.077392578125, 52.099951171875],
              [-176.031201171875, 52.082324218749996],
              [-175.9880859375, 52.049462890625],
              [-175.97529296875, 52.028955078125],
              [-176.021533203125, 52.00244140625],
            ],
          ],
          [
            [
              [-177.148193359375, 51.716748046875],
              [-177.177001953125, 51.7037109375],
              [-177.2298828125, 51.6935546875],
              [-177.382373046875, 51.704833984375],
              [-177.474658203125, 51.70126953125],
              [-177.577587890625, 51.694189453125],
              [-177.6548828125, 51.6765625],
              [-177.67021484375, 51.70107421875],
              [-177.667626953125, 51.72119140625],
              [-177.334716796875, 51.776220703125],
              [-177.257275390625, 51.804931640625],
              [-177.209765625, 51.841259765625],
              [-177.16640625, 51.909423828125],
              [-177.131494140625, 51.92978515625],
              [-177.11005859375, 51.928759765624996],
              [-177.063037109375, 51.901904296874996],
              [-177.079541015625, 51.866552734375],
              [-177.12138671875, 51.835791015625],
              [-177.135107421875, 51.806933593749996],
              [-177.148193359375, 51.716748046875],
            ],
          ],
          [
            [
              [178.57548828125005, 51.916259765625],
              [178.51181640625003, 51.89912109375],
              [178.477734375, 51.942529296875],
              [178.475, 51.967724609375],
              [178.509375, 51.994677734374996],
              [178.57060546875005, 51.9775390625],
              [178.60732421875002, 51.95302734375],
              [178.57548828125005, 51.916259765625],
            ],
          ],
          [
            [
              [179.45156250000002, 51.372607421874996],
              [179.278125, 51.372216796874994],
              [178.92587890625003, 51.535058593749994],
              [178.7470703125, 51.58671875],
              [178.64794921875, 51.643896484375],
              [178.69218750000005, 51.65595703125],
              [178.90800781250005, 51.615576171875],
              [179.08427734375005, 51.527685546875],
              [179.18173828125003, 51.469921875],
              [179.29433593750002, 51.420849609375],
              [179.41552734375, 51.40087890625],
              [179.45156250000002, 51.372607421874996],
            ],
          ],
          [
            [
              [173.72275390625003, 52.3595703125],
              [173.65781250000003, 52.356640625],
              [173.6162109375, 52.391259765625],
              [173.40234375, 52.40478515625],
              [173.42451171875, 52.437646484375],
              [173.51650390625002, 52.451416015625],
              [173.65761718750002, 52.5041015625],
              [173.77607421875, 52.4951171875],
              [173.74472656250003, 52.446630859375],
              [173.72275390625003, 52.3595703125],
            ],
          ],
          [
            [
              [-134.969775390625, 57.351416015625],
              [-134.88486328125, 57.24169921875],
              [-134.823193359375, 57.156542968749996],
              [-134.768505859375, 57.05419921875],
              [-134.67685546875, 56.84228515625],
              [-134.63408203125, 56.762109375],
              [-134.620703125, 56.718310546874996],
              [-134.610546875, 56.60341796875],
              [-134.62431640625, 56.5787109375],
              [-134.651708984375, 56.5560546875],
              [-134.657080078125, 56.523242187499996],
              [-134.631689453125, 56.43564453125],
              [-134.630029296875, 56.30244140625],
              [-134.65400390625, 56.227490234375],
              [-134.681884765625, 56.216162109375],
              [-134.75029296875, 56.240771484374996],
              [-134.8064453125, 56.28125],
              [-134.847998046875, 56.323486328125],
              [-134.950146484375, 56.456835937499996],
              [-134.98056640625, 56.5189453125],
              [-134.982421875, 56.563623046875],
              [-134.966650390625, 56.596142578125],
              [-134.933203125, 56.616357421875],
              [-134.87509765625, 56.670458984374996],
              [-134.883447265625, 56.679052734375],
              [-134.927587890625, 56.6669921875],
              [-135.017822265625, 56.66015625],
              [-135.09716796875, 56.70283203125],
              [-135.159033203125, 56.725390625],
              [-135.14658203125, 56.80234375],
              [-135.163134765625, 56.82412109375],
              [-135.284814453125, 56.800341796874996],
              [-135.330615234375, 56.821875],
              [-135.340625, 56.85078125],
              [-135.33837890625, 56.893994140625],
              [-135.31513671875, 56.9318359375],
              [-135.199609375, 57.02734375],
              [-135.21123046875, 57.044921875],
              [-135.2673828125, 57.048876953124996],
              [-135.341357421875, 57.081591796874996],
              [-135.37529296875, 57.188427734375],
              [-135.454931640625, 57.2494140625],
              [-135.501953125, 57.24384765625],
              [-135.608935546875, 57.071435546875],
              [-135.661865234375, 57.033740234374996],
              [-135.8123046875, 57.009521484375],
              [-135.781640625, 57.05751953125],
              [-135.767724609375, 57.100390625],
              [-135.821142578125, 57.230419921875],
              [-135.82275390625, 57.280419921875],
              [-135.787109375, 57.31728515625],
              [-135.680908203125, 57.332568359374996],
              [-135.62451171875, 57.35439453125],
              [-135.58056640625, 57.389990234375],
              [-135.56962890625, 57.42470703125],
              [-135.4873046875, 57.51650390625],
              [-135.448681640625, 57.534375],
              [-135.3462890625, 57.53310546875],
              [-135.1306640625, 57.431640625],
              [-135.065234375, 57.41669921875],
              [-134.969775390625, 57.351416015625],
            ],
          ],
          [
            [
              [-134.6802734375, 58.161669921875],
              [-134.426123046875, 58.138818359374994],
              [-134.240087890625, 58.143994140625],
              [-134.070166015625, 57.99453125],
              [-133.96552734375, 57.873779296875],
              [-133.9041015625, 57.789208984375],
              [-133.869287109375, 57.70751953125],
              [-133.82275390625, 57.628662109375],
              [-133.826904296875, 57.617578125],
              [-133.925, 57.67080078125],
              [-133.995556640625, 57.778466796875],
              [-134.031640625, 57.820605468749996],
              [-134.067236328125, 57.839599609375],
              [-134.104736328125, 57.879345703125],
              [-134.1775390625, 57.982177734375],
              [-134.1802734375, 58.011132812499994],
              [-134.21259765625, 58.037939453125],
              [-134.249951171875, 58.049169921875],
              [-134.292333984375, 58.0447265625],
              [-134.306884765625, 58.034375],
              [-134.300390625, 57.963427734374996],
              [-134.26708984375, 57.884521484375],
              [-134.08369140625, 57.712255859375],
              [-133.9611328125, 57.61416015625],
              [-133.93701171875, 57.581591796874996],
              [-133.920849609375, 57.491992187499996],
              [-133.97373046875, 57.4513671875],
              [-133.908837890625, 57.368701171874996],
              [-133.9111328125, 57.3525390625],
              [-133.92529296875, 57.336767578125],
              [-134.100048828125, 57.300097656249996],
              [-134.26015625, 57.146777343749996],
              [-134.435302734375, 57.056982421875],
              [-134.516015625, 57.042578125],
              [-134.55478515625, 57.057568359375],
              [-134.59150390625, 57.0919921875],
              [-134.6130859375, 57.137939453125],
              [-134.61953125, 57.1955078125],
              [-134.57587890625, 57.231738281249996],
              [-134.489208984375, 57.420166015625],
              [-134.486767578125, 57.48203125],
              [-134.59482421875, 57.567822265625],
              [-134.65986328125, 57.6380859375],
              [-134.6951171875, 57.73603515625],
              [-134.7541015625, 57.99501953125],
              [-134.781494140625, 58.07783203125],
              [-134.8201171875, 58.146875],
              [-134.869970703125, 58.202099609375],
              [-134.907666015625, 58.26279296875],
              [-134.93310546875, 58.328955078125],
              [-134.923486328125, 58.354638671874994],
              [-134.836962890625, 58.320166015625],
              [-134.733203125, 58.225],
              [-134.6802734375, 58.161669921875],
            ],
          ],
          [
            [
              [-135.73037109375, 58.24423828125],
              [-135.5875, 58.14677734375],
              [-135.586279296875, 58.124414062499994],
              [-135.615380859375, 58.057470703125],
              [-135.693115234375, 58.038525390625],
              [-135.671142578125, 58.0119140625],
              [-135.613232421875, 57.991845703125],
              [-135.572021484375, 58.008544921875],
              [-135.42119140625, 58.102392578125],
              [-135.37470703125, 58.122119140625],
              [-135.346630859375, 58.12412109375],
              [-135.162841796875, 58.095849609374994],
              [-135.002099609375, 58.05107421875],
              [-134.9546875, 58.01533203125],
              [-134.927978515625, 57.952783203125],
              [-134.970654296875, 57.817236328125],
              [-135.102587890625, 57.79365234375],
              [-135.16474609375, 57.79609375],
              [-135.231201171875, 57.8158203125],
              [-135.3384765625, 57.76865234375],
              [-135.249560546875, 57.732568359375],
              [-134.978857421875, 57.724365234375],
              [-134.896630859375, 57.647998046874996],
              [-134.873095703125, 57.589208984375],
              [-134.931494140625, 57.48115234375],
              [-135.08486328125, 57.511035156249996],
              [-135.22021484375, 57.5736328125],
              [-135.4978515625, 57.662255859375],
              [-135.564208984375, 57.66640625],
              [-135.608544921875, 57.650732421875],
              [-135.620654296875, 57.596972656249996],
              [-135.617822265625, 57.48037109375],
              [-135.691943359375, 57.419921875],
              [-135.910791015625, 57.446582031249996],
              [-135.9966796875, 57.53486328125],
              [-136.076611328125, 57.674560546875],
              [-136.37822265625, 57.839990234375],
              [-136.459912109375, 57.873095703124996],
              [-136.568603515625, 57.97216796875],
              [-136.52509765625, 58.0505859375],
              [-136.5123046875, 58.09599609375],
              [-136.45439453125, 58.1080078125],
              [-136.36953125, 58.14306640625],
              [-136.32197265625, 58.218896484374994],
              [-136.245703125, 58.157470703125],
              [-136.14375, 58.098486328125],
              [-136.142333984375, 58.15390625],
              [-136.094384765625, 58.198144531249994],
              [-135.994384765625, 58.196533203125],
              [-135.947412109375, 58.205810546875],
              [-135.88173828125, 58.24716796875],
              [-135.787060546875, 58.268505859375],
              [-135.73037109375, 58.24423828125],
            ],
          ],
          [
            [
              [-133.56611328125, 56.339208984375],
              [-133.376611328125, 56.3177734375],
              [-133.202978515625, 56.31982421875],
              [-133.143701171875, 56.278564453125],
              [-133.1044921875, 56.235107421875],
              [-133.08173828125, 56.194189453125],
              [-133.075439453125, 56.155859375],
              [-133.080126953125, 56.128710937499996],
              [-133.101220703125, 56.0998046875],
              [-133.096630859375, 56.0900390625],
              [-132.757568359375, 55.99501953125],
              [-132.597607421875, 55.89501953125],
              [-132.5337890625, 55.84248046875],
              [-132.49697265625, 55.798095703125],
              [-132.43017578125, 55.68701171875],
              [-132.2888671875, 55.55810546875],
              [-132.21474609375, 55.518847656249996],
              [-132.172705078125, 55.480615234375],
              [-132.196337890625, 55.479150390625],
              [-132.2958984375, 55.507470703125],
              [-132.511279296875, 55.5939453125],
              [-132.528857421875, 55.590478515625],
              [-132.54833984375, 55.543701171875],
              [-132.58173828125, 55.50263671875],
              [-132.631298828125, 55.473193359374996],
              [-132.5916015625, 55.46435546875],
              [-132.41787109375, 55.48291015625],
              [-132.272021484375, 55.3986328125],
              [-132.215283203125, 55.383544921875],
              [-132.16025390625, 55.322998046875],
              [-132.1583984375, 55.2998046875],
              [-132.1904296875, 55.25498046875],
              [-132.214892578125, 55.236767578125],
              [-132.206689453125, 55.224414062499996],
              [-132.165966796875, 55.218017578125],
              [-132.005078125, 55.230615234375],
              [-131.976416015625, 55.20859375],
              [-132.000390625, 55.033837890625],
              [-131.977587890625, 54.969482421875],
              [-131.9779296875, 54.940234375],
              [-131.99658203125, 54.901416015624996],
              [-131.997216796875, 54.868603515625],
              [-131.98271484375, 54.834912109375],
              [-131.980859375, 54.804833984375],
              [-132.0216796875, 54.726318359375],
              [-132.06474609375, 54.713134765625],
              [-132.134326171875, 54.712548828125],
              [-132.1892578125, 54.73486328125],
              [-132.26630859375, 54.80234375],
              [-132.34130859375, 54.9072265625],
              [-132.37021484375, 54.922216796875],
              [-132.46865234375, 54.937939453125],
              [-132.486474609375, 54.950390625],
              [-132.549365234375, 54.952587890625],
              [-132.59384765625, 54.995751953125],
              [-132.5884765625, 55.05234375],
              [-132.626953125, 55.11005859375],
              [-132.62216796875, 55.1359375],
              [-132.66533203125, 55.146777343749996],
              [-132.7017578125, 55.130517578125],
              [-132.682861328125, 55.07392578125],
              [-132.704150390625, 55.030078125],
              [-132.78232421875, 55.048486328125],
              [-132.91259765625, 55.1884765625],
              [-133.060595703125, 55.300927734375],
              [-133.1185546875, 55.32763671875],
              [-133.10302734375, 55.36025390625],
              [-133.030029296875, 55.3775390625],
              [-132.97080078125, 55.376171875],
              [-132.95888671875, 55.395556640624996],
              [-133.082470703125, 55.5041015625],
              [-133.07841796875, 55.534912109375],
              [-133.0333984375, 55.589697265625],
              [-133.0896484375, 55.612597656249996],
              [-133.24375, 55.59541015625],
              [-133.2982421875, 55.606884765625],
              [-133.342822265625, 55.650830078125],
              [-133.368994140625, 55.68896484375],
              [-133.502734375, 55.6958984375],
              [-133.553271484375, 55.691162109375],
              [-133.640478515625, 55.748779296875],
              [-133.68017578125, 55.78515625],
              [-133.664404296875, 55.80380859375],
              [-133.58408203125, 55.8365234375],
              [-133.537158203125, 55.83193359375],
              [-133.44697265625, 55.797021484375],
              [-133.41171875, 55.79833984375],
              [-133.322119140625, 55.84462890625],
              [-133.30849609375, 55.886474609375],
              [-133.24150390625, 55.92080078125],
              [-133.2521484375, 55.957080078124996],
              [-133.289208984375, 56.018701171875],
              [-133.371240234375, 56.035888671875],
              [-133.538623046875, 55.999267578125],
              [-133.684228515625, 55.9427734375],
              [-133.742529296875, 55.96484375],
              [-133.75517578125, 55.999462890625],
              [-133.59921875, 56.09365234375],
              [-133.530859375, 56.145654296875],
              [-133.544091796875, 56.176513671875],
              [-133.59443359375, 56.216357421874996],
              [-133.5986328125, 56.316259765625],
              [-133.56611328125, 56.339208984375],
            ],
          ],
          [
            [
              [-133.989599609375, 56.844970703125],
              [-133.9248046875, 56.775683593749996],
              [-133.830859375, 56.781298828124996],
              [-133.778125, 56.72890625],
              [-133.73837890625, 56.650439453124996],
              [-133.76728515625, 56.60009765625],
              [-133.809033203125, 56.611328125],
              [-133.8552734375, 56.582177734375],
              [-133.88359375, 56.485498046875],
              [-133.870458984375, 56.388671875],
              [-133.884619140625, 56.292138671875],
              [-133.938525390625, 56.193652343749996],
              [-133.94970703125, 56.127734375],
              [-133.97080078125, 56.10791015625],
              [-133.993994140625, 56.101123046874996],
              [-134.0240234375, 56.118994140625],
              [-134.06748046875, 56.1330078125],
              [-134.122412109375, 56.077392578125],
              [-134.189599609375, 56.076953125],
              [-134.245068359375, 56.203271484375],
              [-134.195458984375, 56.413525390625],
              [-134.084375, 56.456347656249996],
              [-134.15048828125, 56.513476562499996],
              [-134.290234375, 56.580029296875],
              [-134.278369140625, 56.61708984375],
              [-134.384423828125, 56.7240234375],
              [-134.390625, 56.749462890625],
              [-134.373681640625, 56.838671875],
              [-134.2744140625, 56.9181640625],
              [-134.14326171875, 56.93232421875],
              [-134.051806640625, 56.898291015625],
              [-134.0005859375, 56.869189453124996],
              [-133.989599609375, 56.844970703125],
            ],
          ],
          [
            [
              [-152.416943359375, 58.360205078125],
              [-152.38076171875, 58.352099609375],
              [-152.343017578125, 58.41162109375],
              [-152.316259765625, 58.413476562499994],
              [-152.19794921875, 58.3630859375],
              [-152.125244140625, 58.374267578125],
              [-152.078515625, 58.312353515625],
              [-152.03662109375, 58.306689453125],
              [-151.99775390625, 58.314208984375],
              [-151.974365234375, 58.30986328125],
              [-151.98251953125, 58.2443359375],
              [-152.068896484375, 58.1779296875],
              [-152.10908203125, 58.1611328125],
              [-152.165478515625, 58.178271484375],
              [-152.1865234375, 58.18466796875],
              [-152.223583984375, 58.214013671874994],
              [-152.25166015625, 58.251123046874994],
              [-152.268359375, 58.251708984375],
              [-152.334375, 58.208056640625],
              [-152.332666015625, 58.1865234375],
              [-152.305224609375, 58.154052734375],
              [-152.309228515625, 58.13388671875],
              [-152.38115234375, 58.124267578125],
              [-152.451611328125, 58.129248046875],
              [-152.537646484375, 58.100976562499994],
              [-152.558203125, 58.118603515625],
              [-152.571337890625, 58.168212890625],
              [-152.5982421875, 58.16259765625],
              [-152.63876953125, 58.101806640625],
              [-152.683056640625, 58.063330078125],
              [-152.7638671875, 58.031396484374994],
              [-152.78154296875, 58.01591796875],
              [-152.84072265625, 58.013818359374994],
              [-152.92841796875, 57.993701171874996],
              [-152.982568359375, 57.9970703125],
              [-153.30546875, 58.0630859375],
              [-153.38134765625, 58.08720703125],
              [-153.1158203125, 58.238525390625],
              [-152.976123046875, 58.297021484374994],
              [-152.895361328125, 58.293847656249994],
              [-152.81455078125, 58.275634765625],
              [-152.771875, 58.278564453125],
              [-152.768701171875, 58.345605468749994],
              [-152.8439453125, 58.39560546875],
              [-152.84111328125, 58.41640625],
              [-152.674658203125, 58.450585937499994],
              [-152.6123046875, 58.445703125],
              [-152.5435546875, 58.428173828125],
              [-152.478466796875, 58.39970703125],
              [-152.416943359375, 58.360205078125],
            ],
          ],
          [
            [
              [-167.96435546875, 53.3451171875],
              [-168.270703125, 53.238037109375],
              [-168.3701171875, 53.159765625],
              [-168.44599609375, 53.084423828125],
              [-168.505615234375, 53.0431640625],
              [-168.5490234375, 53.036083984375],
              [-168.597412109375, 53.01611328125],
              [-168.69853515625, 52.963427734374996],
              [-168.741015625, 52.956884765625],
              [-169.06591796875, 52.833935546875],
              [-169.088916015625, 52.83203125],
              [-169.073095703125, 52.86416015625],
              [-168.973876953125, 52.90966796875],
              [-168.9091796875, 52.951171875],
              [-168.836083984375, 53.0197265625],
              [-168.795849609375, 53.044921875],
              [-168.7830078125, 53.079345703125],
              [-168.777783203125, 53.148779296875],
              [-168.759619140625, 53.175048828125],
              [-168.68984375, 53.22724609375],
              [-168.639013671875, 53.25576171875],
              [-168.57216796875, 53.265625],
              [-168.43662109375, 53.256884765624996],
              [-168.380419921875, 53.283447265625],
              [-168.36298828125, 53.303564453125],
              [-168.397265625, 53.321923828125],
              [-168.405322265625, 53.353808593749996],
              [-168.396435546875, 53.4087890625],
              [-168.3572265625, 53.457568359374996],
              [-168.2876953125, 53.500146484375],
              [-168.19306640625, 53.53330078125],
              [-168.073291015625, 53.556982421875],
              [-167.985693359375, 53.558203125],
              [-167.828076171875, 53.507958984375],
              [-167.8046875, 53.4849609375],
              [-167.843115234375, 53.4345703125],
              [-167.86513671875, 53.3873046875],
              [-167.96435546875, 53.3451171875],
            ],
          ],
          [
            [
              [-166.61533203125, 53.900927734374996],
              [-166.57216796875, 53.853466796875],
              [-166.4974609375, 53.883544921875],
              [-166.4427734375, 53.9248046875],
              [-166.400048828125, 53.978125],
              [-166.372314453125, 53.998974609375],
              [-166.33564453125, 53.9708984375],
              [-166.230859375, 53.9326171875],
              [-166.318994140625, 53.873779296875],
              [-166.48876953125, 53.785498046875],
              [-166.54560546875, 53.72646484375],
              [-166.54921875, 53.700976562499996],
              [-166.384716796875, 53.720507812499996],
              [-166.33876953125, 53.71767578125],
              [-166.30947265625, 53.697509765625],
              [-166.354541015625, 53.67353515625],
              [-166.444189453125, 53.651806640625],
              [-166.522021484375, 53.609667968749996],
              [-166.702197265625, 53.536669921874996],
              [-166.77041015625, 53.476025390625],
              [-166.8509765625, 53.452880859375],
              [-166.9607421875, 53.44736328125],
              [-167.153662109375, 53.407861328125],
              [-167.27080078125, 53.37060546875],
              [-167.300439453125, 53.35048828125],
              [-167.337255859375, 53.340966796875],
              [-167.381298828125, 53.3419921875],
              [-167.42880859375, 53.32568359375],
              [-167.479833984375, 53.2919921875],
              [-167.5224609375, 53.276220703125],
              [-167.5921875, 53.272705078125],
              [-167.62861328125, 53.259423828125],
              [-167.66943359375, 53.2599609375],
              [-167.780859375, 53.300244140625],
              [-167.8087890625, 53.323779296874996],
              [-167.710107421875, 53.370898437499996],
              [-167.638720703125, 53.386572265625],
              [-167.53017578125, 53.393701171875],
              [-167.42353515625, 53.437255859375],
              [-167.2041015625, 53.494970703125],
              [-167.136083984375, 53.52646484375],
              [-167.092333984375, 53.6359375],
              [-167.042431640625, 53.654589843749996],
              [-167.01572265625, 53.698388671875],
              [-166.894140625, 53.697119140625],
              [-166.838330078125, 53.648046875],
              [-166.81875, 53.641357421875],
              [-166.808984375, 53.646142578125],
              [-166.803662109375, 53.685400390625],
              [-166.741259765625, 53.712939453124996],
              [-166.77724609375, 53.733154296875],
              [-166.889599609375, 53.75859375],
              [-166.97294921875, 53.770556640624996],
              [-167.02724609375, 53.769140625],
              [-167.071484375, 53.7833984375],
              [-167.105615234375, 53.81337890625],
              [-167.121142578125, 53.843115234375],
              [-167.1181640625, 53.872607421874996],
              [-167.090478515625, 53.9056640625],
              [-167.0380859375, 53.9421875],
              [-166.978076171875, 53.962939453124996],
              [-166.848681640625, 53.977880859375],
              [-166.734033203125, 54.002197265625],
              [-166.673291015625, 54.00595703125],
              [-166.627392578125, 53.995654296874996],
              [-166.61533203125, 53.900927734374996],
            ],
          ],
          [
            [
              [-173.5533203125, 52.136279296874996],
              [-173.3572265625, 52.095654296875],
              [-173.11328125, 52.100390625],
              [-173.02431640625, 52.09052734375],
              [-173.022900390625, 52.079150390624996],
              [-173.178857421875, 52.0625],
              [-173.2322265625, 52.06796875],
              [-173.368408203125, 52.04560546875],
              [-173.460986328125, 52.041552734374996],
              [-173.67255859375, 52.062646484375],
              [-173.835791015625, 52.048193359375],
              [-173.878955078125, 52.053662109375],
              [-173.930224609375, 52.07216796875],
              [-173.989599609375, 52.10361328125],
              [-173.99248046875, 52.123339843749996],
              [-173.938916015625, 52.131298828125],
              [-173.794091796875, 52.104296875],
              [-173.77900390625, 52.118359375],
              [-173.6568359375, 52.14375],
              [-173.5533203125, 52.136279296874996],
            ],
          ],
          [
            [
              [-174.677392578125, 52.035009765625],
              [-175.2138671875, 51.993896484375],
              [-175.295556640625, 52.02216796875],
              [-175.21416015625, 52.038232421875],
              [-175.11767578125, 52.047119140625],
              [-174.91591796875, 52.094189453125],
              [-174.6677734375, 52.1349609375],
              [-174.474267578125, 52.184033203125],
              [-174.30615234375, 52.216162109375],
              [-174.258837890625, 52.26904296875],
              [-174.406494140625, 52.29599609375],
              [-174.435546875, 52.317236328125],
              [-174.3654296875, 52.341943359375],
              [-174.306884765625, 52.3779296875],
              [-174.168896484375, 52.420166015625],
              [-174.04560546875, 52.367236328124996],
              [-174.018359375, 52.331787109375],
              [-174.030078125, 52.289794921875],
              [-174.0548828125, 52.24599609375],
              [-174.163232421875, 52.223388671875],
              [-174.17939453125, 52.200341796875],
              [-174.120654296875, 52.135205078125],
              [-174.3435546875, 52.077783203125],
              [-174.677392578125, 52.035009765625],
            ],
          ],
          [
            [
              [-176.593310546875, 51.86669921875],
              [-176.587939453125, 51.833203125],
              [-176.473388671875, 51.83740234375],
              [-176.437451171875, 51.820117187499996],
              [-176.437353515625, 51.754296875],
              [-176.45234375, 51.735693359375],
              [-176.469775390625, 51.73115234375],
              [-176.510986328125, 51.74560546875],
              [-176.55751953125, 51.712060546875],
              [-176.770947265625, 51.629931640624996],
              [-176.837109375, 51.67587890625],
              [-176.96162109375, 51.603662109375],
              [-176.8744140625, 51.790478515625],
              [-176.7736328125, 51.81875],
              [-176.73642578125, 51.83994140625],
              [-176.7451171875, 51.894677734375],
              [-176.69833984375, 51.98603515625],
              [-176.596826171875, 51.981787109375],
              [-176.54990234375, 51.94404296875],
              [-176.551611328125, 51.919580078125],
              [-176.593310546875, 51.86669921875],
            ],
          ],
          [
            [
              [-177.879052734375, 51.649707031249996],
              [-177.90126953125, 51.61640625],
              [-177.925341796875, 51.6173828125],
              [-178.05888671875, 51.672607421875],
              [-178.078466796875, 51.691259765625],
              [-178.000048828125, 51.71748046875],
              [-177.97724609375, 51.73779296875],
              [-177.986376953125, 51.7642578125],
              [-178.0451171875, 51.801074218749996],
              [-178.153466796875, 51.8482421875],
              [-178.19453125, 51.8822265625],
              [-178.16826171875, 51.90302734375],
              [-178.1166015625, 51.915869140625],
              [-177.95380859375, 51.91845703125],
              [-177.865869140625, 51.860400390624996],
              [-177.799609375, 51.8400390625],
              [-177.644482421875, 51.82626953125],
              [-177.724951171875, 51.80166015625],
              [-177.770654296875, 51.777880859374996],
              [-177.826953125, 51.685888671875],
              [-177.879052734375, 51.649707031249996],
            ],
          ],
          [
            [
              [179.727734375, 51.905419921875],
              [179.64521484375, 51.880224609375],
              [179.54960937500005, 51.89404296875],
              [179.49765625000003, 51.9328125],
              [179.50390625, 51.97958984375],
              [179.6271484375, 52.030419921875],
              [179.77998046875, 51.966845703124996],
              [179.727734375, 51.905419921875],
            ],
          ],
          [
            [
              [177.41542968750002, 51.8828125],
              [177.32851562500002, 51.841064453125],
              [177.26064453125002, 51.88369140625],
              [177.25029296875005, 51.9029296875],
              [177.38066406250005, 51.97578125],
              [177.47841796875002, 51.9916015625],
              [177.5205078125, 52.018212890625],
              [177.56376953125005, 52.110498046875],
              [177.63652343750005, 52.113818359374996],
              [177.66962890625, 52.10302734375],
              [177.65302734375, 52.059765625],
              [177.59599609375005, 51.99384765625],
              [177.59414062500002, 51.947558593749996],
              [177.41542968750002, 51.8828125],
            ],
          ],
          [
            [
              [172.81181640625005, 53.012988281249996],
              [172.98398437500003, 52.980273437499996],
              [173.10214843750003, 52.99560546875],
              [173.25166015625, 52.942675781249996],
              [173.43603515625, 52.85205078125],
              [173.3947265625, 52.834765625],
              [173.34824218750003, 52.824853515625],
              [173.30253906250005, 52.825927734375],
              [173.15869140625, 52.810791015625],
              [173.0802734375, 52.814453125],
              [172.93515625000003, 52.752099609375],
              [172.7755859375, 52.796923828124996],
              [172.72177734375003, 52.885546875],
              [172.59511718750002, 52.907421875],
              [172.49482421875, 52.937890625],
              [172.6779296875, 53.007568359375],
              [172.81181640625005, 53.012988281249996],
            ],
          ],
          [
            [
              [-155.58134765625, 19.012011718750003],
              [-155.625634765625, 18.963916015625003],
              [-155.68076171875, 18.967675781249994],
              [-155.881298828125, 19.070507812499997],
              [-155.905615234375, 19.12583007812499],
              [-155.89072265625, 19.382519531249997],
              [-155.9658203125, 19.5908203125],
              [-156.048681640625, 19.749951171874997],
              [-155.988427734375, 19.831591796875003],
              [-155.90888671875, 19.894726562499997],
              [-155.8203125, 20.01416015625],
              [-155.8927734375, 20.167382812499994],
              [-155.874267578125, 20.259814453125003],
              [-155.831640625, 20.275830078124997],
              [-155.6220703125, 20.16342773437499],
              [-155.198779296875, 19.994384765625],
              [-155.086083984375, 19.875634765624994],
              [-155.06591796875, 19.748193359374994],
              [-154.989013671875, 19.731982421875003],
              [-154.952587890625, 19.64462890624999],
              [-154.841357421875, 19.568164062500003],
              [-154.80419921875, 19.524462890625003],
              [-154.85029296875, 19.4541015625],
              [-155.053466796875, 19.31918945312499],
              [-155.309619140625, 19.26015625],
              [-155.53525390625, 19.109082031249997],
              [-155.58134765625, 19.012011718750003],
            ],
          ],
          [
            [
              [-157.213623046875, 21.215380859375003],
              [-157.002294921875, 21.187939453124997],
              [-156.95234375, 21.19970703125],
              [-156.9171875, 21.177294921875003],
              [-156.7421875, 21.163525390624997],
              [-156.712158203125, 21.155078125],
              [-156.747900390625, 21.103564453125003],
              [-156.85986328125, 21.056347656249997],
              [-157.0208984375, 21.097802734374994],
              [-157.29033203125, 21.112597656250003],
              [-157.2794921875, 21.15234375],
              [-157.25380859375, 21.180566406249994],
              [-157.249951171875, 21.229785156250003],
              [-157.213623046875, 21.215380859375003],
            ],
          ],
          [
            [
              [-156.48681640625, 20.932568359374997],
              [-156.46083984375, 20.914746093749997],
              [-156.35439453125, 20.941455078125003],
              [-156.2775390625, 20.95126953124999],
              [-156.14833984375, 20.885498046875],
              [-156.103515625, 20.84033203125],
              [-156.01865234375, 20.79208984374999],
              [-155.98984375, 20.757128906250003],
              [-156.01357421875, 20.714794921874997],
              [-156.10712890625, 20.644775390625],
              [-156.234765625, 20.628613281249997],
              [-156.3099609375, 20.598779296874994],
              [-156.4087890625, 20.605175781249997],
              [-156.438232421875, 20.617871093749997],
              [-156.448876953125, 20.70625],
              [-156.480078125, 20.801220703124997],
              [-156.54384765625, 20.789990234374997],
              [-156.6154296875, 20.821826171875003],
              [-156.689697265625, 20.901416015625003],
              [-156.69775390625, 20.94907226562499],
              [-156.656884765625, 21.02451171874999],
              [-156.585400390625, 21.034326171874994],
              [-156.53232421875, 20.99267578125],
              [-156.48681640625, 20.932568359374997],
            ],
          ],
          [
            [
              [-157.799365234375, 21.456640625],
              [-157.764990234375, 21.450927734375],
              [-157.7208984375, 21.457714843749997],
              [-157.705517578125, 21.378076171874994],
              [-157.654150390625, 21.333935546874997],
              [-157.635400390625, 21.3076171875],
              [-157.690869140625, 21.279736328124997],
              [-157.798779296875, 21.268603515625003],
              [-157.84931640625, 21.290820312500003],
              [-157.9017578125, 21.340576171875],
              [-157.958447265625, 21.326904296875],
              [-157.968310546875, 21.366894531249997],
              [-157.97841796875, 21.378515625],
              [-158.01728515625, 21.367724609375003],
              [-157.98095703125, 21.316113281249997],
              [-158.079150390625, 21.312255859375],
              [-158.1103515625, 21.318603515625],
              [-158.137841796875, 21.377148437499997],
              [-158.239111328125, 21.48935546874999],
              [-158.238671875, 21.53305664062499],
              [-158.27314453125, 21.585253906250003],
              [-158.123095703125, 21.600244140624994],
              [-158.020361328125, 21.691796875],
              [-157.9625, 21.701367187499997],
              [-157.851513671875, 21.553369140624994],
              [-157.854345703125, 21.511914062499997],
              [-157.82958984375, 21.471435546875],
              [-157.799365234375, 21.456640625],
            ],
          ],
          [
            [
              [-159.37275390625, 21.932373046875],
              [-159.460693359375, 21.876123046874994],
              [-159.511865234375, 21.900390625],
              [-159.608837890625, 21.90952148437499],
              [-159.64638671875, 21.95175781249999],
              [-159.747998046875, 21.98984375],
              [-159.78916015625, 22.041796875],
              [-159.726611328125, 22.140185546875003],
              [-159.57919921875, 22.22314453125],
              [-159.35205078125, 22.21958007812499],
              [-159.30478515625, 22.154052734375],
              [-159.30068359375, 22.105273437500003],
              [-159.33017578125, 22.050683593749994],
              [-159.34375, 21.9736328125],
              [-159.37275390625, 21.932373046875],
            ],
          ],
          [
            [
              [-160.180029296875, 21.841064453125],
              [-160.200244140625, 21.796875],
              [-160.234716796875, 21.803662109374997],
              [-160.24345703125, 21.843066406250003],
              [-160.2208984375, 21.897265625],
              [-160.1638671875, 21.944042968749997],
              [-160.100634765625, 22.015234375],
              [-160.04873046875, 22.004638671875],
              [-160.076708984375, 21.95810546874999],
              [-160.080029296875, 21.907421875],
              [-160.15341796875, 21.87875976562499],
              [-160.180029296875, 21.841064453125],
            ],
          ],
          [
            [
              [-156.849609375, 20.77265625],
              [-156.90888671875, 20.74448242187499],
              [-156.973388671875, 20.757519531249997],
              [-156.988427734375, 20.82568359375],
              [-157.0505859375, 20.91245117187499],
              [-156.941796875, 20.93002929687499],
              [-156.88056640625, 20.904833984375003],
              [-156.848291015625, 20.87778320312499],
              [-156.809375, 20.83115234374999],
              [-156.849609375, 20.77265625],
            ],
          ],
          [
            [
              [-74.70888671875002, 45.003857421875125],
              [-74.663232421875, 45.00390625],
              [-74.43037109375, 45.004199218749996],
              [-74.01425781249999, 45.0046875],
              [-73.59814453125, 45.005175781249996],
              [-73.18203125, 45.005615234375],
              [-72.76591796874999, 45.006103515625],
              [-72.349755859375, 45.006591796875],
              [-71.93364257812499, 45.007080078125],
              [-71.517529296875, 45.007568359375],
              [-71.41904296874999, 45.200341796874994],
              [-71.327294921875, 45.290087890624996],
              [-71.201611328125, 45.2603515625],
              [-71.13466796875, 45.262841796874994],
              [-71.06025390625, 45.309130859374996],
              [-70.99990234375, 45.337255859375],
              [-70.96015625, 45.33310546875],
              [-70.926220703125, 45.29072265625],
              [-70.89799804687499, 45.262451171875],
              [-70.86503906249999, 45.270703125],
              [-70.83681640625, 45.310693359374994],
              [-70.83779296875, 45.366162109375],
              [-70.79916992187499, 45.40478515625],
              [-70.7533203125, 45.410693359374996],
              [-70.7109375, 45.409472656249996],
              [-70.68979492187499, 45.4283203125],
              [-70.692138671875, 45.455371093749996],
              [-70.707421875, 45.498925781249994],
              [-70.70224609374999, 45.5513671875],
              [-70.59638671875, 45.643994140625],
              [-70.46660156249999, 45.706835937499996],
              [-70.42109375, 45.738232421875],
              [-70.407861328125, 45.801904296874994],
              [-70.333447265625, 45.868066406249994],
              [-70.29624023437499, 45.906103515625],
              [-70.28715820312499, 45.93916015625],
              [-70.30644531249999, 45.979833984375],
              [-70.30449218749999, 46.057373046875],
              [-70.27890625, 46.15],
              [-70.248291015625, 46.250878906249994],
              [-70.1796875, 46.341845703124996],
              [-70.06718749999999, 46.441064453124994],
              [-70.038232421875, 46.571435546874994],
              [-70.00771484375, 46.708935546875],
              [-69.871728515625, 46.842919921874994],
              [-69.717529296875, 46.994873046875],
              [-69.62978515625, 47.081347656249996],
              [-69.47148437499999, 47.238671875],
              [-69.35888671875, 47.350634765624996],
              [-69.3021484375, 47.402001953124994],
              [-69.24287109375, 47.46298828125],
              [-69.14628906249999, 47.444775390625],
              [-69.0501953125, 47.426611328125],
              [-69.06425781249999, 47.338134765625],
              [-69.048583984375, 47.2736328125],
              [-69.003125, 47.23642578125],
              [-68.93720703125, 47.211230468749996],
              [-68.88740234375, 47.20283203125],
              [-68.82871093749999, 47.2033203125],
              [-68.66855468749999, 47.253466796874996],
              [-68.48037109375, 47.285791015624994],
              [-68.376904296875, 47.316162109375],
              [-68.35800781249999, 47.34453125],
              [-68.31088867187499, 47.3544921875],
              [-68.235498046875, 47.345947265625],
              [-68.09677734374999, 47.274853515625],
              [-67.93486328124999, 47.167626953124994],
              [-67.806787109375, 47.0828125],
              [-67.80283203124999, 46.9357421875],
              [-67.80034179687499, 46.7798828125],
              [-67.79770507812499, 46.615625],
              [-67.79580078125, 46.498388671875],
              [-67.79252929687499, 46.33740234375],
              [-67.78994140625, 46.209326171875],
              [-67.78647460937499, 46.042138671874994],
              [-67.78466796875, 45.952783203124994],
              [-67.767041015625, 45.927001953125],
              [-67.77763671874999, 45.891796875],
              [-67.78227539062499, 45.874169921874994],
              [-67.78115234375, 45.86015625],
              [-67.77412109375, 45.842529296875],
              [-67.77529296875, 45.81787109375],
              [-67.79169921875, 45.795556640624994],
              [-67.79990234374999, 45.769775390625],
              [-67.80224609375, 45.7275390625],
              [-67.78466796875, 45.701708984374996],
              [-67.755322265625, 45.686474609375],
              [-67.7306640625, 45.686474609375],
              [-67.698974609375, 45.671191406249996],
              [-67.65791015625, 45.644189453124994],
              [-67.59575195312499, 45.620751953124994],
              [-67.531201171875, 45.612548828125],
              [-67.48662109374999, 45.618408203125],
              [-67.43266601562499, 45.603125],
              [-67.41386718749999, 45.565576171874994],
              [-67.42441406249999, 45.530419921874994],
              [-67.45493164062499, 45.513964843749996],
              [-67.48779296875, 45.501025390624996],
              [-67.49365234375, 45.474072265625],
              [-67.47724609375, 45.4458984375],
              [-67.453759765625, 45.421240234375],
              [-67.4279296875, 45.3779296875],
              [-67.43852539062499, 45.340380859374996],
              [-67.46196289062499, 45.30869140625],
              [-67.47255859375, 45.27587890625],
              [-67.452587890625, 45.24765625],
              [-67.3998046875, 45.21015625],
              [-67.366943359375, 45.173779296875],
              [-67.31528320312499, 45.15380859375],
              [-67.290673828125, 45.167919921875],
              [-67.270703125, 45.18671875],
              [-67.249609375, 45.20078125],
              [-67.21323242187499, 45.192529296874994],
              [-67.17099609374999, 45.181982421875],
              [-67.12485351562499, 45.16943359375],
              [-67.13037109375, 45.139013671875],
              [-67.10224609375, 45.087744140625],
              [-67.08046875, 44.989160156249994],
              [-67.113916015625, 44.944384765624996],
              [-67.10673828124999, 44.885058593749996],
              [-67.01401367187499, 44.8677734375],
              [-66.991455078125, 44.849609375],
              [-66.98701171875, 44.827685546874996],
              [-67.19125976562499, 44.675585937499996],
              [-67.36406249999999, 44.696875],
              [-67.45781249999999, 44.656542968749996],
              [-67.55600585937499, 44.644775390625],
              [-67.599072265625, 44.576806640624994],
              [-67.652978515625, 44.562402343749994],
              [-67.726806640625, 44.56650390625],
              [-67.790478515625, 44.585693359375],
              [-67.8390625, 44.57626953125],
              [-67.90703124999999, 44.45361328125],
              [-67.96269531249999, 44.464306640625],
              [-67.98486328125, 44.420166015625],
              [-68.01396484374999, 44.40087890625],
              [-68.056640625, 44.384326171874996],
              [-68.093701171875, 44.438818359375],
              [-68.11728515624999, 44.490625],
              [-68.15205078125, 44.502001953124996],
              [-68.1982421875, 44.515234375],
              [-68.245751953125, 44.514794921874994],
              [-68.27744140624999, 44.507373046874996],
              [-68.31674804687499, 44.473876953125],
              [-68.37373046875, 44.445117187499996],
              [-68.41684570312499, 44.469091796875],
              [-68.4505859375, 44.507617187499996],
              [-68.479443359375, 44.445654296875],
              [-68.521435546875, 44.380224609375],
              [-68.51445312499999, 44.30390625],
              [-68.53251953124999, 44.258642578125],
              [-68.57236328124999, 44.270849609375],
              [-68.61201171875, 44.310546875],
              [-68.723291015625, 44.34228515625],
              [-68.8119140625, 44.33935546875],
              [-68.793896484375, 44.381738281249994],
              [-68.710107421875, 44.442578125],
              [-68.73588867187499, 44.454492187499994],
              [-68.777001953125, 44.446044921875],
              [-68.794921875, 44.454492187499994],
              [-68.76552734375, 44.509765625],
              [-68.7626953125, 44.570751953125],
              [-68.8001953125, 44.5494140625],
              [-68.84736328125, 44.48505859375],
              [-68.96147460937499, 44.433837890625],
              [-68.95615234374999, 44.348095703125],
              [-69.06357421874999, 44.17236328125],
              [-69.068359375, 44.097558593749994],
              [-69.13725585937499, 44.037841796875],
              [-69.22607421875, 43.986474609374994],
              [-69.34453124999999, 44.000927734375],
              [-69.4349609375, 43.956298828125],
              [-69.480859375, 43.905078125],
              [-69.520751953125, 43.897363281249994],
              [-69.54155273437499, 43.96259765625],
              [-69.55668945312499, 43.982763671875],
              [-69.589990234375, 43.886572265625],
              [-69.62392578125, 43.880615234375],
              [-69.63676757812499, 43.948828125],
              [-69.65288085937499, 43.993896484375],
              [-69.69912109375, 43.955029296875],
              [-69.72983398437499, 43.852001953125],
              [-69.76201171874999, 43.860693359375],
              [-69.77226562499999, 43.8990234375],
              [-69.7953125, 43.91064453125],
              [-69.80322265625, 43.866845703124994],
              [-69.79160156249999, 43.805224609374996],
              [-69.808349609375, 43.772314453125],
              [-69.84033203125, 43.789892578125],
              [-69.872509765625, 43.81953125],
              [-69.92558593749999, 43.797021484374994],
              [-69.97431640625, 43.787890625],
              [-69.97451171875, 43.81806640625],
              [-69.965234375, 43.855078125],
              [-70.06235351562499, 43.834619140624994],
              [-70.17880859374999, 43.766357421875],
              [-70.26923828125, 43.671923828124996],
              [-70.237890625, 43.656201171875],
              [-70.202587890625, 43.626123046874994],
              [-70.35966796874999, 43.480224609375],
              [-70.520703125, 43.348828125],
              [-70.642333984375, 43.134423828124994],
              [-70.691162109375, 43.109326171875],
              [-70.73310546875, 43.07001953125],
              [-70.77763671874999, 42.940576171874994],
              [-70.829052734375, 42.825341796874994],
              [-70.80029296875, 42.7740234375],
              [-70.78134765624999, 42.721240234374996],
              [-70.73569335937499, 42.669287109375],
              [-70.696875, 42.664599609374996],
              [-70.65483398437499, 42.673974609374994],
              [-70.623974609375, 42.671777343749994],
              [-70.604150390625, 42.649707031249996],
              [-70.612939453125, 42.6232421875],
              [-70.66142578124999, 42.616650390625],
              [-70.75185546875, 42.570361328124996],
              [-70.83115234374999, 42.552587890625],
              [-70.87089843749999, 42.496630859374996],
              [-70.93046874999999, 42.431982421875],
              [-71.04619140624999, 42.331103515624996],
              [-70.996728515625, 42.3],
              [-70.81796875, 42.264941406249996],
              [-70.73828125, 42.228857421875],
              [-70.61767578125, 42.040429687499994],
              [-70.64521484375, 42.02158203125],
              [-70.65615234375, 41.987060546875],
              [-70.54892578124999, 41.938623046874994],
              [-70.51469726562499, 41.8033203125],
              [-70.42666015625, 41.757275390625],
              [-70.29545898437499, 41.728955078125],
              [-70.135009765625, 41.769873046875],
              [-70.001416015625, 41.826171875],
              [-70.006103515625, 41.872314453125],
              [-70.0900390625, 41.9796875],
              [-70.11025390625, 42.030126953125],
              [-70.17255859375, 42.062792968749996],
              [-70.196240234375, 42.035107421875],
              [-70.2365234375, 42.071044921875],
              [-70.24106445312499, 42.091210937499994],
              [-70.203515625, 42.101025390625],
              [-70.15986328125, 42.097119140625],
              [-70.10893554687499, 42.0783203125],
              [-69.97788085937499, 41.961279296875],
              [-69.9416015625, 41.807861328125],
              [-69.933837890625, 41.71044921875],
              [-69.9486328125, 41.677148437499994],
              [-69.986767578125, 41.683984375],
              [-70.059521484375, 41.67734375],
              [-70.4046875, 41.626904296875],
              [-70.48134765625, 41.582470703125],
              [-70.65712890625, 41.534228515624996],
              [-70.66806640624999, 41.55830078125],
              [-70.65537109374999, 41.60810546875],
              [-70.666455078125, 41.710107421874994],
              [-70.701123046875, 41.71484375],
              [-70.97421875, 41.54853515625],
              [-71.07978515625, 41.5380859375],
              [-71.16855468749999, 41.489404296874994],
              [-71.188427734375, 41.51640625],
              [-71.204296875, 41.64111328125],
              [-71.14873046874999, 41.745703125],
              [-71.17832031249999, 41.744042968749994],
              [-71.27109375, 41.68125],
              [-71.3107421875, 41.719873046874994],
              [-71.33061523437499, 41.762255859374996],
              [-71.35917968749999, 41.78623046875],
              [-71.39013671875, 41.7953125],
              [-71.363671875, 41.702734375],
              [-71.42656249999999, 41.63330078125],
              [-71.443798828125, 41.4537109375],
              [-71.5228515625, 41.378955078124996],
              [-71.769287109375, 41.330908203125],
              [-71.929931640625, 41.341064453125],
              [-72.073876953125, 41.326123046875],
              [-72.265283203125, 41.291650390624994],
              [-72.371044921875, 41.312158203124994],
              [-72.47939453125, 41.27578125],
              [-72.84716796875, 41.265869140625],
              [-72.92470703125, 41.28515625],
              [-73.02373046874999, 41.216455078124994],
              [-73.182275390625, 41.175830078124996],
              [-73.5830078125, 41.021875],
              [-73.67138671875, 40.965869140624996],
              [-73.77900390625, 40.87841796875],
              [-73.85126953125, 40.831396484375],
              [-73.91069335937499, 40.81611328125],
              [-73.947216796875, 40.776953125],
              [-73.98710937499999, 40.751367187499994],
              [-73.94858398437499, 40.838769531249994],
              [-73.90673828125, 40.912451171875],
              [-73.87197265625, 41.05517578125],
              [-73.8822265625, 41.17060546875],
              [-73.92534179687499, 41.218066406249996],
              [-73.969921875, 41.24970703125],
              [-73.91767578125, 41.135791015624996],
              [-73.90922851562499, 40.99609375],
              [-73.927197265625, 40.9142578125],
              [-74.02548828124999, 40.756396484374996],
              [-74.067333984375, 40.719628906249994],
              [-74.11625976562499, 40.687304687499996],
              [-74.153125, 40.673242187499994],
              [-74.187158203125, 40.647998046874996],
              [-74.226708984375, 40.6080078125],
              [-74.26420898437499, 40.528613281249996],
              [-74.24150390624999, 40.45625],
              [-74.04985351562499, 40.429833984374994],
              [-73.9984375, 40.4521484375],
              [-73.972265625, 40.400341796875],
              [-73.95761718749999, 40.328369140625],
              [-73.97197265624999, 40.250537109374996],
              [-74.00400390624999, 40.171337890625],
              [-74.0283203125, 40.072998046875],
              [-74.04892578124999, 39.923046875],
              [-74.07993164062499, 39.788134765624996],
              [-74.083984375, 39.8291015625],
              [-74.064599609375, 39.993115234375],
              [-74.09599609374999, 39.975976562499994],
              [-74.117626953125, 39.938134765624994],
              [-74.17612304687499, 39.726611328124996],
              [-74.25654296875, 39.6138671875],
              [-74.33061523437499, 39.535888671875],
              [-74.40703124999999, 39.548779296875],
              [-74.38984375, 39.48681640625],
              [-74.41083984375, 39.454541015625],
              [-74.42880859374999, 39.38720703125],
              [-74.474365234375, 39.342578125],
              [-74.5171875, 39.346875],
              [-74.57871093749999, 39.31611328125],
              [-74.602978515625, 39.292578125],
              [-74.60478515624999, 39.247509765625],
              [-74.645947265625, 39.207861328125],
              [-74.79448242187499, 39.001904296875],
              [-74.9234375, 38.94111328125],
              [-74.954296875, 38.949951171875],
              [-74.9203125, 39.047167968749996],
              [-74.89702148437499, 39.145458984375],
              [-74.97529296875, 39.188232421875],
              [-75.0501953125, 39.210839843749994],
              [-75.1361328125, 39.207861328125],
              [-75.23105468749999, 39.28427734375],
              [-75.35341796875, 39.33984375],
              [-75.52421874999999, 39.490185546875],
              [-75.51923828125, 39.531884765624994],
              [-75.52353515624999, 39.601855468749996],
              [-75.471630859375, 39.71240234375],
              [-75.421875, 39.789697265624994],
              [-75.353173828125, 39.829736328124994],
              [-75.15380859375, 39.870507812499994],
              [-75.10380859374999, 39.9318359375],
              [-75.074169921875, 39.98349609375],
              [-75.17294921874999, 39.894775390625],
              [-75.32089843749999, 39.864697265625],
              [-75.400634765625, 39.831591796874996],
              [-75.46440429687499, 39.78095703125],
              [-75.5021484375, 39.7173828125],
              [-75.58759765625, 39.640771484374994],
              [-75.58159179687499, 39.589453125],
              [-75.56728515625, 39.552978515625],
              [-75.573876953125, 39.476953125],
              [-75.51982421874999, 39.40283203125],
              [-75.41264648437499, 39.281396484374994],
              [-75.3921875, 39.0927734375],
              [-75.31040039062499, 38.966552734375],
              [-75.18505859375, 38.819384765624996],
              [-75.08867187499999, 38.7775390625],
              [-75.083984375, 38.722802734374994],
              [-75.12846679687499, 38.632421875],
              [-75.187109375, 38.591113281249996],
              [-75.11083984375, 38.599365234375],
              [-75.07285156249999, 38.5787109375],
              [-75.035888671875, 38.503320312499994],
              [-75.03876953125, 38.4263671875],
              [-75.05126953125, 38.3830078125],
              [-75.074365234375, 38.36572265625],
              [-75.073388671875, 38.410009765625],
              [-75.08974609375, 38.425390625],
              [-75.11674804687499, 38.406201171875],
              [-75.134228515625, 38.384326171874996],
              [-75.14150390625, 38.298144531249996],
              [-75.16000976562499, 38.255078125],
              [-75.22543945312499, 38.24228515625],
              [-75.29179687499999, 38.129199218749996],
              [-75.353515625, 38.0650390625],
              [-75.59638671875, 37.631201171875],
              [-75.587109375, 37.55869140625],
              [-75.63154296875, 37.5353515625],
              [-75.698828125, 37.516357421875],
              [-75.76689453124999, 37.472998046875],
              [-75.81206054687499, 37.425195312499994],
              [-75.85400390625, 37.296630859375],
              [-75.934375, 37.151904296874996],
              [-75.984521484375, 37.212207031249996],
              [-75.99736328124999, 37.263818359374994],
              [-75.975048828125, 37.3984375],
              [-75.888134765625, 37.619140625],
              [-75.7923828125, 37.75634765625],
              [-75.71933593749999, 37.82138671875],
              [-75.65927734374999, 37.953955078125],
              [-75.73515624999999, 37.97373046875],
              [-75.850830078125, 37.971582031249994],
              [-75.829052734375, 38.032763671874996],
              [-75.7953125, 38.086669921875],
              [-75.855615234375, 38.140380859375],
              [-75.89130859375, 38.147216796875],
              [-75.92807617187499, 38.169238281249996],
              [-75.8849609375, 38.21396484375],
              [-75.863916015625, 38.26123046875],
              [-75.87675781249999, 38.31875],
              [-75.85869140624999, 38.362060546875],
              [-75.888818359375, 38.355517578124996],
              [-75.937255859375, 38.30966796875],
              [-75.96738281249999, 38.291357421875],
              [-75.9857421875, 38.331933593749994],
              [-76.00668945312499, 38.32275390625],
              [-76.02031249999999, 38.294873046875],
              [-76.051220703125, 38.279541015625],
              [-76.11650390624999, 38.317675781249996],
              [-76.211669921875, 38.361328125],
              [-76.2646484375, 38.436425781249994],
              [-76.294873046875, 38.49462890625],
              [-76.26416015625, 38.599951171875],
              [-76.198388671875, 38.61865234375],
              [-76.112939453125, 38.6015625],
              [-76.000927734375, 38.601708984374994],
              [-76.01694335937499, 38.62509765625],
              [-76.05693359374999, 38.621240234374994],
              [-76.175, 38.706689453124994],
              [-76.21298828124999, 38.75830078125],
              [-76.2783203125, 38.7724609375],
              [-76.30810546875, 38.7228515625],
              [-76.34116210937499, 38.70966796875],
              [-76.30034179687499, 38.818212890625],
              [-76.24697265625, 38.82265625],
              [-76.1681640625, 38.852734375],
              [-76.191064453125, 38.915576171874996],
              [-76.24082031249999, 38.94306640625],
              [-76.33066406249999, 38.90859375],
              [-76.32958984375, 38.952783203124994],
              [-76.312744140625, 39.009375],
              [-76.24501953125, 39.009179687499994],
              [-76.185693359375, 38.99072265625],
              [-76.135205078125, 39.08212890625],
              [-76.132958984375, 39.122949218749994],
              [-76.21684570312499, 39.063623046874994],
              [-76.23569335937499, 39.1916015625],
              [-76.153125, 39.3150390625],
              [-76.074365234375, 39.36884765625],
              [-75.9759765625, 39.36728515625],
              [-75.8759765625, 39.3759765625],
              [-75.938720703125, 39.398583984374994],
              [-76.003125, 39.41083984375],
              [-75.954736328125, 39.459619140624994],
              [-75.9134765625, 39.468359375],
              [-75.87294921875, 39.510888671874994],
              [-75.97041015625, 39.50458984375],
              [-75.958935546875, 39.58505859375],
              [-76.006298828125, 39.568701171875],
              [-76.06298828125, 39.5611328125],
              [-76.08505859374999, 39.527001953124994],
              [-76.080712890625, 39.4703125],
              [-76.097265625, 39.43310546875],
              [-76.141357421875, 39.403222656249994],
              [-76.2158203125, 39.379931640624996],
              [-76.223046875, 39.4203125],
              [-76.24765625, 39.438623046874994],
              [-76.2568359375, 39.3521484375],
              [-76.2763671875, 39.32275390625],
              [-76.330810546875, 39.40390625],
              [-76.34716796875, 39.387548828125],
              [-76.34506835937499, 39.364501953125],
              [-76.358984375, 39.324658203125],
              [-76.4056640625, 39.30390625],
              [-76.402783203125, 39.252832031249994],
              [-76.4208984375, 39.225],
              [-76.57041015624999, 39.269335937499996],
              [-76.57392578125, 39.254296875],
              [-76.48935546874999, 39.15869140625],
              [-76.42758789062499, 39.126025390624996],
              [-76.420068359375, 39.073876953124994],
              [-76.473095703125, 39.030615234375],
              [-76.54624023437499, 39.06796875],
              [-76.558544921875, 39.065234375],
              [-76.518798828125, 39.001171875],
              [-76.49375, 38.945214843749994],
              [-76.51953125, 38.898339843749994],
              [-76.51552734375, 38.840625],
              [-76.52109375, 38.78828125],
              [-76.536865234375, 38.742626953125],
              [-76.50131835937499, 38.532177734375],
              [-76.45849609375, 38.474951171875],
              [-76.41640625, 38.420214843749996],
              [-76.39409179687499, 38.368994140625],
              [-76.43876953124999, 38.3615234375],
              [-76.509912109375, 38.403662109375],
              [-76.572412109375, 38.435791015625],
              [-76.646875, 38.538525390625],
              [-76.6591796875, 38.579541015625],
              [-76.67734375, 38.611962890624994],
              [-76.66855468749999, 38.5375],
              [-76.6419921875, 38.454345703125],
              [-76.40878906249999, 38.26826171875],
              [-76.36572265625, 38.196875],
              [-76.33291015625, 38.140771484374994],
              [-76.34116210937499, 38.08701171875],
              [-76.401953125, 38.125048828124996],
              [-76.45439453124999, 38.17353515625],
              [-76.59360351562499, 38.228320312499996],
              [-76.769140625, 38.262939453125],
              [-76.868115234375, 38.390283203124994],
              [-76.86777343749999, 38.337158203125],
              [-76.88974609374999, 38.29208984375],
              [-76.95024414062499, 38.347021484375],
              [-76.98837890624999, 38.393896484375],
              [-77.001171875, 38.445263671875],
              [-77.07670898437499, 38.441748046875],
              [-77.155908203125, 38.397119140624994],
              [-77.23251953124999, 38.40771484375],
              [-77.2416015625, 38.49482421875],
              [-77.2208984375, 38.540966796875],
              [-77.134912109375, 38.65009765625],
              [-77.05390625, 38.705810546875],
              [-77.01816406249999, 38.777734375],
              [-77.03037109374999, 38.8892578125],
              [-77.04560546875, 38.77578125],
              [-77.09189453124999, 38.71953125],
              [-77.16464843749999, 38.6765625],
              [-77.260400390625, 38.6],
              [-77.28378906249999, 38.529199218749994],
              [-77.313671875, 38.396630859374994],
              [-77.27324218749999, 38.3517578125],
              [-77.23193359375, 38.3400390625],
              [-77.10991210937499, 38.3701171875],
              [-77.04677734375, 38.356689453125],
              [-76.90634765624999, 38.197070312499996],
              [-76.64487304687499, 38.133935546874994],
              [-76.54951171875, 38.094482421875],
              [-76.47177734374999, 38.011181640625],
              [-76.354931640625, 37.963232421875],
              [-76.26425781249999, 37.8935546875],
              [-76.26181640624999, 37.848095703125],
              [-76.293212890625, 37.794335937499994],
              [-76.305615234375, 37.721582031249994],
              [-76.344140625, 37.675683593749994],
              [-76.43662109374999, 37.67041015625],
              [-76.49248046874999, 37.6822265625],
              [-76.79277343749999, 37.93798828125],
              [-76.82861328125, 37.9615234375],
              [-76.93999023437499, 38.095458984375],
              [-77.07065429687499, 38.1671875],
              [-77.111083984375, 38.165673828124994],
              [-76.92509765624999, 38.033007812499996],
              [-76.84916992187499, 37.940234375],
              [-76.71542968749999, 37.81015625],
              [-76.61982421875, 37.755078125],
              [-76.549462890625, 37.669140625],
              [-76.48408203125, 37.628857421875],
              [-76.30556640625, 37.571484375],
              [-76.367626953125, 37.5302734375],
              [-76.2685546875, 37.495166015624996],
              [-76.25439453125, 37.430615234375],
              [-76.26347656249999, 37.35703125],
              [-76.40097656249999, 37.386132812499994],
              [-76.40546875, 37.331933593749994],
              [-76.39316406249999, 37.299951171874994],
              [-76.45390624999999, 37.27353515625],
              [-76.53837890624999, 37.309375],
              [-76.75771484375, 37.505419921874996],
              [-76.755859375, 37.47919921875],
              [-76.73808593749999, 37.448779296874996],
              [-76.61088867187499, 37.322558593749996],
              [-76.49736328124999, 37.246875],
              [-76.401123046875, 37.212695312499996],
              [-76.32695312499999, 37.149267578125],
              [-76.30078125, 37.110888671874996],
              [-76.28330078124999, 37.052685546875],
              [-76.33828125, 37.013134765625],
              [-76.40087890625, 36.99130859375],
              [-76.46201171874999, 37.03076171875],
              [-76.5068359375, 37.072314453124996],
              [-76.602294921875, 37.142871093749996],
              [-76.63090820312499, 37.221728515624996],
              [-76.703515625, 37.217675781249994],
              [-77.006982421875, 37.317675781249996],
              [-77.25087890625, 37.32919921875],
              [-77.22705078125, 37.30908203125],
              [-77.19619140625, 37.295703125],
              [-77.001953125, 37.271044921874996],
              [-76.9251953125, 37.225],
              [-76.76542968749999, 37.184130859374996],
              [-76.671875, 37.17294921875],
              [-76.633935546875, 37.047412109374996],
              [-76.504638671875, 36.96103515625],
              [-76.48784179687499, 36.897021484374996],
              [-76.399560546875, 36.88984375],
              [-76.24423828124999, 36.95263671875],
              [-76.143994140625, 36.930615234375],
              [-75.9994140625, 36.912646484374996],
              [-75.96635742187499, 36.861962890624994],
              [-75.941552734375, 36.765527343749994],
              [-75.89042968749999, 36.65703125],
              [-75.75786132812499, 36.229248046875],
              [-75.55869140624999, 35.879345703125],
              [-75.5341796875, 35.819091796875],
              [-75.58046875, 35.871972656249994],
              [-75.72822265625, 36.1037109375],
              [-75.809765625, 36.271044921874996],
              [-75.8935546875, 36.56650390625],
              [-75.91787109375, 36.632666015625],
              [-75.946484375, 36.659082031249994],
              [-75.96533203125, 36.637597656249994],
              [-75.97343749999999, 36.599951171875],
              [-75.95976562499999, 36.571044921875],
              [-75.99277343749999, 36.473779296874994],
              [-75.978466796875, 36.429150390625],
              [-75.92485351562499, 36.3830078125],
              [-75.8666015625, 36.267871093749996],
              [-75.820068359375, 36.112841796874996],
              [-75.8830078125, 36.175683593749994],
              [-75.9501953125, 36.208984375],
              [-76.05473632812499, 36.234521484374994],
              [-76.1478515625, 36.279296875],
              [-76.141064453125, 36.215087890625],
              [-76.15, 36.145751953125],
              [-76.22177734374999, 36.166894531249994],
              [-76.27060546874999, 36.189892578125],
              [-76.22739257812499, 36.116015625],
              [-76.32119140625, 36.13818359375],
              [-76.38369140625, 36.133544921875],
              [-76.42431640625, 36.06796875],
              [-76.47880859374999, 36.028173828125],
              [-76.559375, 36.01533203125],
              [-76.67890625, 36.07529296875],
              [-76.71762695312499, 36.148095703124994],
              [-76.733642578125, 36.229150390624994],
              [-76.74003906249999, 36.13330078125],
              [-76.71875, 36.033496093749996],
              [-76.726220703125, 35.9576171875],
              [-76.61113281249999, 35.943652343749996],
              [-76.503515625, 35.9560546875],
              [-76.35830078125, 35.952880859375],
              [-76.26357421875, 35.967089843749996],
              [-76.20654296875, 35.9912109375],
              [-76.069775390625, 35.9703125],
              [-76.06005859375, 35.878662109375],
              [-76.07568359375, 35.787548828125],
              [-76.08359375, 35.69052734375],
              [-76.04570312499999, 35.691162109375],
              [-76.001171875, 35.72216796875],
              [-75.97890625, 35.895947265625],
              [-75.85390625, 35.96015625],
              [-75.81201171875, 35.95576171875],
              [-75.772216796875, 35.89990234375],
              [-75.75883789062499, 35.84326171875],
              [-75.74472656249999, 35.765478515625],
              [-75.77392578125, 35.64697265625],
              [-75.965966796875, 35.5083984375],
              [-76.103515625, 35.380273437499994],
              [-76.173828125, 35.354150390624994],
              [-76.27524414062499, 35.369042968749994],
              [-76.390234375, 35.401269531249994],
              [-76.44663085937499, 35.407763671874996],
              [-76.489501953125, 35.397021484374996],
              [-76.515625, 35.436474609375],
              [-76.532470703125, 35.508447265624994],
              [-76.57719726562499, 35.53232421875],
              [-76.61103515625, 35.5296875],
              [-76.63413085937499, 35.45322265625],
              [-76.74140625, 35.431494140625],
              [-76.88725585937499, 35.4630859375],
              [-77.039990234375, 35.527392578124996],
              [-76.97446289062499, 35.458398437499994],
              [-76.595458984375, 35.3296875],
              [-76.55278320312499, 35.305615234375],
              [-76.512939453125, 35.270410156249994],
              [-76.56596679687499, 35.215185546875],
              [-76.60751953124999, 35.152978515624994],
              [-76.61337890624999, 35.104150390624994],
              [-76.62802734374999, 35.07333984375],
              [-76.77915039062499, 34.99033203125],
              [-76.86103515625, 35.00498046875],
              [-77.070263671875, 35.154638671875],
              [-76.97495117187499, 35.025195312499996],
              [-76.8986328125, 34.970263671874996],
              [-76.74497070312499, 34.940966796874996],
              [-76.45673828125, 34.98935546875],
              [-76.36220703125, 34.9365234375],
              [-76.43979492187499, 34.842919921874994],
              [-76.51689453124999, 34.777246093749994],
              [-76.61801757812499, 34.769921875],
              [-76.70708007812499, 34.7521484375],
              [-76.73320312499999, 34.706982421875],
              [-76.79667968749999, 34.704150390624996],
              [-76.8958984375, 34.701464843749996],
              [-77.04951171875, 34.69736328125],
              [-77.13388671874999, 34.707910156249994],
              [-77.25175781249999, 34.615625],
              [-77.29624023437499, 34.602929687499994],
              [-77.3583984375, 34.620263671874994],
              [-77.38447265625, 34.694384765624996],
              [-77.412255859375, 34.730810546875],
              [-77.41293945312499, 34.592138671875],
              [-77.40205078125, 34.55478515625],
              [-77.37978515625, 34.526611328125],
              [-77.51767578124999, 34.4513671875],
              [-77.649658203125, 34.35751953125],
              [-77.69697265625, 34.331982421875],
              [-77.750732421875, 34.284960937499996],
              [-77.86083984375, 34.149169921875],
              [-77.88803710937499, 34.050146484375],
              [-77.92783203124999, 33.939746093749996],
              [-77.932861328125, 33.989453125],
              [-77.926025390625, 34.073144531249994],
              [-77.953271484375, 34.168994140624996],
              [-77.97055664062499, 33.993408203125],
              [-78.013330078125, 33.91181640625],
              [-78.405859375, 33.917578125],
              [-78.57768554687499, 33.8732421875],
              [-78.841455078125, 33.724072265625],
              [-78.9203125, 33.65869140625],
              [-79.13818359375, 33.405908203124994],
              [-79.193798828125, 33.244140625],
              [-79.23837890624999, 33.312158203124994],
              [-79.22734374999999, 33.363183593749994],
              [-79.22646484375, 33.4048828125],
              [-79.28134765624999, 33.3154296875],
              [-79.229248046875, 33.18515625],
              [-79.276025390625, 33.135400390624994],
              [-79.419921875, 33.042529296874996],
              [-79.498681640625, 33.027294921875],
              [-79.587109375, 33.000878906249994],
              [-79.61494140625, 32.90927734375],
              [-79.735009765625, 32.8248046875],
              [-79.80498046874999, 32.787402343749996],
              [-79.93310546875, 32.81005859375],
              [-79.89365234374999, 32.7287109375],
              [-79.94072265624999, 32.667138671874994],
              [-80.02177734374999, 32.619921875],
              [-80.12255859375, 32.589111328125],
              [-80.180322265625, 32.59287109375],
              [-80.2296875, 32.576513671875],
              [-80.26835937499999, 32.537353515625],
              [-80.36284179687499, 32.500732421875],
              [-80.46098632812499, 32.521337890625],
              [-80.572216796875, 32.53369140625],
              [-80.6341796875, 32.51171875],
              [-80.530029296875, 32.475390625],
              [-80.474267578125, 32.422753906249994],
              [-80.4857421875, 32.351806640625],
              [-80.513623046875, 32.3244140625],
              [-80.579345703125, 32.2873046875],
              [-80.60820312499999, 32.292822265625],
              [-80.62583007812499, 32.32626953125],
              [-80.647216796875, 32.395947265625],
              [-80.67778320312499, 32.381103515625],
              [-80.683056640625, 32.3486328125],
              [-80.70932617187499, 32.337060546874994],
              [-80.80253906249999, 32.448046875],
              [-80.79790039062499, 32.36337890625],
              [-80.76533203125, 32.29833984375],
              [-80.733837890625, 32.26533203125],
              [-80.70205078125, 32.245898437499996],
              [-80.69423828125, 32.215722656249994],
              [-80.7580078125, 32.1421875],
              [-80.79082031249999, 32.125830078125],
              [-80.84921875, 32.113916015624994],
              [-80.882080078125, 32.068603515625],
              [-80.87236328124999, 32.029589843749996],
              [-80.9234375, 31.944921875],
              [-81.045556640625, 31.892041015624997],
              [-81.08286132812499, 31.894091796874996],
              [-81.11328125, 31.878613281249997],
              [-81.09550781249999, 31.84091796875],
              [-81.06503906249999, 31.8134765625],
              [-81.06611328125, 31.787988281249994],
              [-81.098388671875, 31.753369140624997],
              [-81.162109375, 31.743701171874996],
              [-81.197900390625, 31.70419921875],
              [-81.18657226562499, 31.666943359374997],
              [-81.16552734375, 31.646142578124994],
              [-81.169921875, 31.610302734374997],
              [-81.2423828125, 31.57431640625],
              [-81.259375, 31.538916015625],
              [-81.223388671875, 31.528466796874994],
              [-81.195703125, 31.538916015625],
              [-81.175439453125, 31.531298828124996],
              [-81.218896484375, 31.472119140624997],
              [-81.25791015624999, 31.43603515625],
              [-81.29497070312499, 31.37119140625],
              [-81.38095703124999, 31.353271484375],
              [-81.37773437499999, 31.332324218749996],
              [-81.32915039062499, 31.313769531249996],
              [-81.2884765625, 31.263916015625],
              [-81.364892578125, 31.171875],
              [-81.41259765625, 31.179443359375],
              [-81.44174804687499, 31.19970703125],
              [-81.4603515625, 31.12705078125],
              [-81.45322265624999, 31.08828125],
              [-81.47138671875, 31.009033203125],
              [-81.50058593749999, 30.913769531249997],
              [-81.52041015625, 30.874658203124994],
              [-81.51621093749999, 30.801806640624996],
              [-81.50395507812499, 30.7314453125],
              [-81.457177734375, 30.640771484374994],
              [-81.3857421875, 30.269970703124997],
              [-81.337109375, 30.1412109375],
              [-81.24951171875, 29.793798828125],
              [-81.10454101562499, 29.456982421874997],
              [-80.9, 29.049853515624996],
              [-80.56430664062499, 28.556396484375],
              [-80.52412109375, 28.486083984375],
              [-80.567822265625, 28.426464843749997],
              [-80.58115234374999, 28.364697265624997],
              [-80.5849609375, 28.27158203125],
              [-80.57285156249999, 28.180859375],
              [-80.533154296875, 28.070068359375],
              [-80.45688476562499, 27.900683593749996],
              [-80.49956054687499, 27.934472656249994],
              [-80.610009765625, 28.177587890625],
              [-80.62285156249999, 28.320361328124996],
              [-80.60693359375, 28.522900390624997],
              [-80.63286132812499, 28.518017578124997],
              [-80.65390625, 28.452197265624996],
              [-80.665478515625, 28.374902343749994],
              [-80.693505859375, 28.344970703125],
              [-80.73173828124999, 28.462890625],
              [-80.72905273437499, 28.5162109375],
              [-80.6884765625, 28.578515625],
              [-80.70024414062499, 28.600927734375],
              [-80.76591796874999, 28.6328125],
              [-80.77988281249999, 28.682958984375],
              [-80.77099609375, 28.732470703124996],
              [-80.80869140624999, 28.758935546874994],
              [-80.83818359374999, 28.757666015625],
              [-80.81840820312499, 28.635595703125],
              [-80.78720703124999, 28.56064453125],
              [-80.74863281249999, 28.381005859374994],
              [-80.68637695312499, 28.272167968749997],
              [-80.65009765625, 28.180908203125],
              [-80.22612304687499, 27.20703125],
              [-80.12578124999999, 27.0830078125],
              [-80.08867187499999, 26.993945312499996],
              [-80.050048828125, 26.80771484375],
              [-80.04130859374999, 26.568603515625],
              [-80.110595703125, 26.131591796875],
              [-80.1263671875, 25.83349609375],
              [-80.13627929687499, 25.84262695312499],
              [-80.14291992187499, 25.8740234375],
              [-80.158935546875, 25.878320312499994],
              [-80.21909179687499, 25.741748046875003],
              [-80.30083007812499, 25.618554687499994],
              [-80.327734375, 25.42709960937499],
              [-80.366943359375, 25.33125],
              [-80.48466796874999, 25.22983398437499],
              [-80.5576171875, 25.232421875],
              [-80.7365234375, 25.15634765624999],
              [-80.86220703125, 25.176171875],
              [-81.011962890625, 25.133251953124997],
              [-81.110498046875, 25.13803710937499],
              [-81.1673828125, 25.228515625],
              [-81.15869140625, 25.268994140624997],
              [-81.13603515624999, 25.30966796874999],
              [-81.09765625, 25.319140625],
              [-80.96538085937499, 25.224316406249997],
              [-80.9404296875, 25.264208984375003],
              [-80.98037109375, 25.311669921874994],
              [-81.0568359375, 25.338134765625],
              [-81.11333007812499, 25.367236328125003],
              [-81.22714843749999, 25.583398437499994],
              [-81.34506835937499, 25.731835937499994],
              [-81.36494140625, 25.8310546875],
              [-81.56826171875, 25.891552734374997],
              [-81.715478515625, 25.983154296875],
              [-81.811474609375, 26.14609375],
              [-81.86655273437499, 26.435009765624997],
              [-81.93149414062499, 26.467480468749997],
              [-81.958935546875, 26.489941406249997],
              [-81.8955078125, 26.59716796875],
              [-81.82866210937499, 26.687060546874996],
              [-81.88154296875, 26.664697265624994],
              [-81.920556640625, 26.6314453125],
              [-81.970166015625, 26.552050781249996],
              [-82.00639648437499, 26.53984375],
              [-82.03959960937499, 26.552050781249996],
              [-82.077880859375, 26.704345703125],
              [-82.06694335937499, 26.891552734374997],
              [-82.01328125, 26.961572265624994],
              [-82.095703125, 26.963427734374996],
              [-82.181103515625, 26.936767578125],
              [-82.168603515625, 26.874365234375],
              [-82.1806640625, 26.840087890625],
              [-82.24287109375, 26.848876953125],
              [-82.2900390625, 26.870800781249997],
              [-82.35405273437499, 26.935742187499997],
              [-82.441357421875, 27.05966796875],
              [-82.62045898437499, 27.401074218749997],
              [-82.65537109374999, 27.44921875],
              [-82.714599609375, 27.499609375],
              [-82.68671875, 27.515283203124994],
              [-82.63583984374999, 27.524560546874994],
              [-82.52084960937499, 27.678271484374996],
              [-82.43051757812499, 27.771142578124994],
              [-82.400537109375, 27.835400390624997],
              [-82.40576171875, 27.862890625],
              [-82.445703125, 27.90283203125],
              [-82.49814453124999, 27.867919921875],
              [-82.52060546874999, 27.877880859374997],
              [-82.57958984375, 27.958447265624997],
              [-82.6359375, 27.981201171875],
              [-82.6751953125, 27.963769531249994],
              [-82.6337890625, 27.897753906249996],
              [-82.59658203125, 27.873242187499997],
              [-82.610986328125, 27.777246093749994],
              [-82.62602539062499, 27.745996093749994],
              [-82.660888671875, 27.718408203124994],
              [-82.71533203125, 27.733105468749997],
              [-82.74287109375, 27.709375],
              [-82.77529296875, 27.734375],
              [-82.807568359375, 27.7765625],
              [-82.843505859375, 27.845996093749996],
              [-82.74853515625, 28.23681640625],
              [-82.66064453125, 28.48583984375],
              [-82.6505859375, 28.769921875],
              [-82.64404296875, 28.81201171875],
              [-82.65146484374999, 28.8875],
              [-82.76933593749999, 29.0515625],
              [-83.290478515625, 29.451904296875],
              [-83.69438476562499, 29.925976562499997],
              [-84.04423828124999, 30.103808593749996],
              [-84.30966796874999, 30.064746093749996],
              [-84.355615234375, 30.029003906249997],
              [-84.37534179687499, 29.982275390625],
              [-84.35869140624999, 29.929394531249997],
              [-84.3828125, 29.907373046874994],
              [-84.454052734375, 29.91015625],
              [-84.55, 29.897851562499994],
              [-84.800537109375, 29.773046875],
              [-84.888916015625, 29.777636718749996],
              [-84.969189453125, 29.7453125],
              [-85.029296875, 29.72109375],
              [-85.18603515625, 29.707910156249994],
              [-85.31894531249999, 29.680224609374996],
              [-85.3763671875, 29.695214843749994],
              [-85.413818359375, 29.767578125],
              [-85.413818359375, 29.842480468749997],
              [-85.383447265625, 29.785058593749994],
              [-85.33642578125, 29.740136718749994],
              [-85.314892578125, 29.758105468749996],
              [-85.3068359375, 29.7978515625],
              [-85.35361328124999, 29.875732421875],
              [-85.504296875, 29.97578125],
              [-85.67578125, 30.121923828125],
              [-85.623486328125, 30.117089843749994],
              [-85.61025390625, 30.148388671874997],
              [-85.66342773437499, 30.189453125],
              [-85.64096679687499, 30.2369140625],
              [-85.603515625, 30.286767578124994],
              [-85.67587890624999, 30.279296875],
              [-85.74082031249999, 30.244384765625],
              [-85.74296874999999, 30.20126953125],
              [-85.755810546875, 30.1669921875],
              [-85.790771484375, 30.17197265625],
              [-85.8556640625, 30.214404296874996],
              [-86.17514648437499, 30.33251953125],
              [-86.45444335937499, 30.399121093749997],
              [-86.24008789062499, 30.429101562499994],
              [-86.12382812499999, 30.405810546874996],
              [-86.1376953125, 30.441552734374994],
              [-86.165673828125, 30.464257812499994],
              [-86.25737304687499, 30.493017578125],
              [-86.374169921875, 30.482080078124994],
              [-86.44794921875, 30.49560546875],
              [-86.523388671875, 30.467089843749996],
              [-86.60605468749999, 30.424707031249994],
              [-86.679638671875, 30.402880859374996],
              [-86.96762695312499, 30.372363281249996],
              [-87.201171875, 30.339257812499994],
              [-87.163720703125, 30.37421875],
              [-87.123779296875, 30.396679687499997],
              [-86.985791015625, 30.430859375],
              [-86.96513671874999, 30.501904296874997],
              [-86.99755859375, 30.5703125],
              [-87.03388671875, 30.55390625],
              [-87.072021484375, 30.500439453124997],
              [-87.118798828125, 30.538964843749994],
              [-87.17060546875, 30.538769531249997],
              [-87.18466796874999, 30.4537109375],
              [-87.25107421874999, 30.396679687499997],
              [-87.28105468749999, 30.339257812499994],
              [-87.47578125, 30.294287109375],
              [-87.500732421875, 30.309277343749997],
              [-87.44375, 30.363818359374996],
              [-87.44829101562499, 30.394140625],
              [-87.51328125, 30.368115234374997],
              [-87.622265625, 30.26474609375],
              [-88.00595703124999, 30.230908203124997],
              [-87.985009765625, 30.25439453125],
              [-87.90400390625, 30.259082031249996],
              [-87.790283203125, 30.291796875],
              [-87.81328124999999, 30.346875],
              [-87.85712890625, 30.407421875],
              [-87.897607421875, 30.41416015625],
              [-87.92431640625, 30.449658203124997],
              [-87.922998046875, 30.5615234375],
              [-87.948876953125, 30.626904296874997],
              [-88.011328125, 30.694189453125],
              [-88.032421875, 30.68125],
              [-88.078369140625, 30.566210937499996],
              [-88.11655273437499, 30.415332031249996],
              [-88.13544921875, 30.366601562499994],
              [-88.24921875, 30.363183593749994],
              [-88.34990234374999, 30.373486328124997],
              [-88.69208984375, 30.355371093749994],
              [-88.819921875, 30.406494140625],
              [-88.87294921875, 30.416308593749996],
              [-88.905224609375, 30.41513671875],
              [-89.05405273437499, 30.36826171875],
              [-89.2236328125, 30.332373046875],
              [-89.26357421875, 30.343652343749994],
              [-89.320556640625, 30.3453125],
              [-89.443505859375, 30.22314453125],
              [-89.58847656249999, 30.165966796874997],
              [-89.954248046875, 30.26875],
              [-90.04521484374999, 30.351416015625],
              [-90.1259765625, 30.369091796874997],
              [-90.22529296875, 30.379296875],
              [-90.331982421875, 30.277587890625],
              [-90.413037109375, 30.140332031249997],
              [-90.28496093749999, 30.065087890624994],
              [-90.17534179687499, 30.029101562499996],
              [-89.99418945312499, 30.059277343749997],
              [-89.89404296875, 30.125878906249994],
              [-89.812255859375, 30.123681640624994],
              [-89.77314453125, 30.13720703125],
              [-89.737451171875, 30.17197265625],
              [-89.66752929687499, 30.14453125],
              [-89.6650390625, 30.117041015625],
              [-89.71469726562499, 30.078320312499997],
              [-89.77724609375, 30.045703125],
              [-89.815185546875, 30.007275390624997],
              [-89.743798828125, 29.929833984374994],
              [-89.63168945312499, 29.90380859375],
              [-89.589501953125, 29.9150390625],
              [-89.56337890625, 30.002099609374994],
              [-89.49443359374999, 30.058154296874996],
              [-89.40073242187499, 30.046044921874994],
              [-89.4140625, 30.010888671874994],
              [-89.40092773437499, 29.977685546874994],
              [-89.357861328125, 29.92099609375],
              [-89.36279296875, 29.839794921874997],
              [-89.354443359375, 29.820214843749994],
              [-89.45541992187499, 29.784375],
              [-89.5306640625, 29.772216796875],
              [-89.59086914062499, 29.725292968749997],
              [-89.559326171875, 29.698046875],
              [-89.62065429687499, 29.674121093749996],
              [-89.662109375, 29.68369140625],
              [-89.68295898437499, 29.674853515624996],
              [-89.689208984375, 29.646044921874996],
              [-89.7208984375, 29.619287109374994],
              [-89.6748046875, 29.538671875],
              [-89.580322265625, 29.486035156249997],
              [-89.513671875, 29.420068359374994],
              [-89.245703125, 29.333203125],
              [-89.18076171874999, 29.335693359375],
              [-89.116845703125, 29.248242187499997],
              [-89.06533203125, 29.218164062499994],
              [-89.01572265624999, 29.202880859375],
              [-89.02138671875, 29.142724609374994],
              [-89.109521484375, 29.098681640624996],
              [-89.13334960937499, 29.046142578125],
              [-89.155517578125, 29.0166015625],
              [-89.195263671875, 29.054003906249996],
              [-89.236083984375, 29.081103515624996],
              [-89.33056640625, 28.998681640624994],
              [-89.376123046875, 28.981347656249994],
              [-89.353515625, 29.070214843749994],
              [-89.38920898437499, 29.105029296874996],
              [-89.44316406249999, 29.194140625],
              [-89.52177734374999, 29.249267578125],
              [-89.5771484375, 29.267529296874997],
              [-89.620263671875, 29.302392578124994],
              [-89.67246093749999, 29.31650390625],
              [-89.7169921875, 29.312890625],
              [-89.7923828125, 29.333203125],
              [-89.79736328125, 29.380615234375],
              [-89.81826171875, 29.41611328125],
              [-89.87724609374999, 29.4580078125],
              [-90.15908203125, 29.537158203124996],
              [-90.160791015625, 29.50439453125],
              [-90.141259765625, 29.479736328125],
              [-90.10078125, 29.463330078124997],
              [-90.05234375, 29.431396484375],
              [-90.05278320312499, 29.336816406249994],
              [-90.07373046875, 29.296777343749994],
              [-90.08271484375, 29.23974609375],
              [-90.10136718749999, 29.181787109374994],
              [-90.13583984374999, 29.136083984375],
              [-90.21279296875, 29.104931640624997],
              [-90.246728515625, 29.131005859374994],
              [-90.30161132812499, 29.255810546874997],
              [-90.37919921874999, 29.295117187499997],
              [-90.50249023437499, 29.299755859374997],
              [-90.58623046874999, 29.271533203124996],
              [-90.677490234375, 29.150634765625],
              [-90.75102539062499, 29.130859375],
              [-91.00273437499999, 29.193505859374994],
              [-91.29013671874999, 29.288964843749994],
              [-91.28271484375, 29.320751953124997],
              [-91.2375, 29.330957031249994],
              [-91.15078125, 29.317919921874996],
              [-91.15537109374999, 29.35068359375],
              [-91.24399414062499, 29.457324218749996],
              [-91.26025390625, 29.50546875],
              [-91.24882812499999, 29.564208984375],
              [-91.277734375, 29.562890625],
              [-91.33095703125, 29.513574218749994],
              [-91.51420898437499, 29.555371093749997],
              [-91.56479492187499, 29.605322265625],
              [-91.67246093749999, 29.74609375],
              [-91.8244140625, 29.750683593749997],
              [-91.89316406249999, 29.83603515625],
              [-92.017333984375, 29.80029296875],
              [-92.080224609375, 29.7607421875],
              [-92.135498046875, 29.699462890625],
              [-92.11396484375, 29.667675781249997],
              [-92.05888671874999, 29.6171875],
              [-92.08403320312499, 29.592822265624996],
              [-92.26083984374999, 29.556835937499997],
              [-92.6712890625, 29.597070312499994],
              [-92.79130859374999, 29.634667968749994],
              [-92.952392578125, 29.714160156249996],
              [-93.17568359375, 29.778955078124994],
              [-93.283203125, 29.789404296875],
              [-93.38847656249999, 29.7765625],
              [-93.69482421875, 29.769921875],
              [-93.76591796874999, 29.752685546875],
              [-93.82646484374999, 29.725146484374996],
              [-93.86572265625, 29.755615234375],
              [-93.88388671874999, 29.810009765624997],
              [-93.84833984375, 29.81884765625],
              [-93.8087890625, 29.850830078125],
              [-93.773095703125, 29.9140625],
              [-93.76904296875, 29.952294921874994],
              [-93.79399414062499, 29.977246093749997],
              [-93.841455078125, 29.979736328125],
              [-93.9462890625, 29.814990234374996],
              [-93.886376953125, 29.72265625],
              [-93.89047851562499, 29.689355468749994],
              [-94.09965820312499, 29.67041015625],
              [-94.574462890625, 29.484521484374994],
              [-94.75961914062499, 29.38427734375],
              [-94.750146484375, 29.418017578124996],
              [-94.52626953125, 29.54794921875],
              [-94.60532226562499, 29.567822265624997],
              [-94.7326171875, 29.535351562499997],
              [-94.778271484375, 29.5478515625],
              [-94.724365234375, 29.6552734375],
              [-94.741943359375, 29.75],
              [-94.83232421874999, 29.752587890624994],
              [-94.889892578125, 29.676953125],
              [-94.9298828125, 29.68017578125],
              [-94.98227539062499, 29.712597656249997],
              [-95.0228515625, 29.70234375],
              [-94.992822265625, 29.530957031249997],
              [-94.93588867187499, 29.46044921875],
              [-94.88828125, 29.370556640624997],
              [-95.018310546875, 29.259472656249997],
              [-95.1390625, 29.167822265625],
              [-95.15214843749999, 29.079248046874994],
              [-95.27348632812499, 28.9638671875],
              [-95.387646484375, 28.8984375],
              [-95.655859375, 28.74462890625],
              [-95.732373046875, 28.71171875],
              [-95.85341796875, 28.640332031249997],
              [-96.02041015625, 28.586816406249994],
              [-96.18051757812499, 28.501855468749994],
              [-96.234521484375, 28.488964843749997],
              [-96.132275390625, 28.560888671875],
              [-96.01103515624999, 28.63193359375],
              [-96.11503906249999, 28.622216796874994],
              [-96.275341796875, 28.655126953125],
              [-96.3734375, 28.65703125],
              [-96.37412109374999, 28.631103515625],
              [-96.44873046875, 28.594482421875],
              [-96.526025390625, 28.648291015625],
              [-96.559716796875, 28.684472656249994],
              [-96.57568359375, 28.715722656249994],
              [-96.60849609374999, 28.723291015624994],
              [-96.6400390625, 28.708789062499996],
              [-96.524658203125, 28.488720703124997],
              [-96.47548828125, 28.479199218749997],
              [-96.42109375, 28.457324218749996],
              [-96.48881835937499, 28.406054687499996],
              [-96.56171875, 28.367138671874997],
              [-96.67636718749999, 28.34130859375],
              [-96.77353515624999, 28.421630859375],
              [-96.794580078125, 28.320849609374996],
              [-96.806884765625, 28.22021484375],
              [-96.839501953125, 28.194384765624996],
              [-96.8916015625, 28.157568359375],
              [-96.919873046875, 28.185351562499996],
              [-96.93330078125, 28.224267578124994],
              [-96.96665039062499, 28.18955078125],
              [-97.01547851562499, 28.163476562499994],
              [-97.09604492187499, 28.158251953124996],
              [-97.156494140625, 28.144335937499996],
              [-97.15507812499999, 28.10263671875],
              [-97.141259765625, 28.060742187499997],
              [-97.034326171875, 28.09384765625],
              [-97.07309570312499, 27.986083984375],
              [-97.17143554687499, 27.879589843749997],
              [-97.2515625, 27.854443359374997],
              [-97.37412109374999, 27.870019531249994],
              [-97.40439453124999, 27.859326171874997],
              [-97.43149414062499, 27.837207031249996],
              [-97.288720703125, 27.670605468749997],
              [-97.38046875, 27.419335937499994],
              [-97.439111328125, 27.328271484374994],
              [-97.47978515624999, 27.316601562499997],
              [-97.523876953125, 27.31396484375],
              [-97.68212890625, 27.394921875],
              [-97.76845703125, 27.45751953125],
              [-97.6923828125, 27.287158203124996],
              [-97.485107421875, 27.23740234375],
              [-97.47451171875, 27.17294921875],
              [-97.47568359374999, 27.117871093749997],
              [-97.51650390625, 27.05322265625],
              [-97.5546875, 26.967333984374996],
              [-97.526513671875, 26.907519531249996],
              [-97.493798828125, 26.759619140625],
              [-97.4658203125, 26.691748046875],
              [-97.43505859375, 26.48583984375],
              [-97.40234375, 26.396533203124996],
              [-97.21391601562499, 26.06787109375],
              [-97.150390625, 26.065332031249994],
              [-97.14018554687499, 26.029736328124997],
              [-97.146240234375, 25.961474609375003],
              [-97.28178710937499, 25.941601562499997],
              [-97.33867187499999, 25.911181640625003],
              [-97.349755859375, 25.884765625],
              [-97.358154296875, 25.870507812499994],
              [-97.375634765625, 25.871826171875],
              [-97.44028320312499, 25.890820312499997],
              [-97.58725585937499, 25.984179687500003],
              [-97.801416015625, 26.042041015624996],
              [-98.08281249999999, 26.064453125],
              [-98.275048828125, 26.111181640625],
              [-98.378125, 26.182373046875],
              [-98.48588867187499, 26.224560546874997],
              [-98.598291015625, 26.237841796874996],
              [-98.69140625, 26.27646484375],
              [-98.765234375, 26.3404296875],
              [-98.873193359375, 26.38125],
              [-99.015283203125, 26.398974609374996],
              [-99.10776367187499, 26.446923828124994],
              [-99.1720703125, 26.564160156249997],
              [-99.17236328125, 26.56591796875],
              [-99.229931640625, 26.761914062499997],
              [-99.30244140625, 26.884716796874997],
              [-99.4435546875, 27.036669921874996],
              [-99.456494140625, 27.056640625],
              [-99.45654296875, 27.056689453124996],
              [-99.45771484375, 27.081689453124994],
              [-99.44023437499999, 27.170117187499997],
              [-99.45512695312499, 27.233691406249996],
              [-99.49980468749999, 27.285498046875],
              [-99.51005859374999, 27.34033203125],
              [-99.48583984375, 27.398046875],
              [-99.48427734375, 27.4673828125],
              [-99.505322265625, 27.54833984375],
              [-99.5953125, 27.635888671874994],
              [-99.75424804687499, 27.729931640624997],
              [-99.8896484375, 27.86728515625],
              [-100.001416015625, 28.0478515625],
              [-100.111962890625, 28.17294921875],
              [-100.22128906249999, 28.242626953124997],
              [-100.296044921875, 28.327685546874996],
              [-100.33627929687499, 28.428125],
              [-100.34814453125, 28.48642578125],
              [-100.33173828125, 28.5025390625],
              [-100.39892578125, 28.614208984374997],
              [-100.54970703125, 28.821337890624996],
              [-100.636328125, 28.972802734374994],
              [-100.658642578125, 29.068554687499997],
              [-100.75458984375, 29.182519531249994],
              [-100.92412109374999, 29.314697265625],
              [-101.01630859375, 29.400683593749996],
              [-101.03862304687499, 29.460302734375],
              [-101.03896484375, 29.460400390624997],
              [-101.30351562499999, 29.634082031249996],
              [-101.38037109375, 29.742578125],
              [-101.440380859375, 29.77685546875],
              [-101.50927734375, 29.773144531249997],
              [-101.54462890625, 29.783544921875],
              [-101.54638671875, 29.808056640624997],
              [-101.56870117187499, 29.809228515624994],
              [-101.61162109374999, 29.786962890625],
              [-101.75234375, 29.782470703125],
              [-101.99091796875, 29.795703125],
              [-102.1630859375, 29.825244140624996],
              [-102.2689453125, 29.87119140625],
              [-102.34306640624999, 29.864990234375],
              [-102.38564453125, 29.806640625],
              [-102.47626953125, 29.769091796874996],
              [-102.61494140625, 29.75234375],
              [-102.73417968749999, 29.643945312499994],
              [-102.833984375, 29.4439453125],
              [-102.87783203125, 29.315332031249994],
              [-102.865673828125, 29.258007812499997],
              [-102.8919921875, 29.21640625],
              [-102.95683593749999, 29.190380859374997],
              [-103.0228515625, 29.132226562499994],
              [-103.089990234375, 29.041894531249994],
              [-103.16831054687499, 28.998193359374994],
              [-103.25771484375, 29.001123046874994],
              [-103.42294921874999, 29.070703125],
              [-103.66396484375, 29.206884765625],
              [-103.8529296875, 29.291064453124996],
              [-103.98974609375, 29.323144531249994],
              [-104.110595703125, 29.386132812499994],
              [-104.21552734375, 29.479882812499994],
              [-104.31220703125, 29.542431640624997],
              [-104.400634765625, 29.57373046875],
              [-104.50400390624999, 29.677685546874997],
              [-104.622216796875, 29.854296875],
              [-104.68134765625, 29.990527343749996],
              [-104.68134765625, 30.134375],
              [-104.835888671875, 30.44765625],
              [-104.91787109375, 30.583349609375],
              [-104.97880859375, 30.645947265624997],
              [-105.09814453125, 30.720556640625],
              [-105.275830078125, 30.807275390624994],
              [-105.51401367187499, 30.980761718749996],
              [-105.8126953125, 31.241015625],
              [-106.024072265625, 31.397753906249996],
              [-106.148046875, 31.450927734375],
              [-106.25571289062499, 31.544677734375],
              [-106.34697265625, 31.679003906249996],
              [-106.43603515625, 31.764453125],
              [-106.44541015624999, 31.768408203125],
              [-106.45322265624999, 31.770166015624994],
              [-106.673046875, 31.771337890625],
              [-106.89287109375, 31.7724609375],
              [-107.1126953125, 31.773632812499997],
              [-107.33251953125, 31.774755859375],
              [-107.55234375, 31.77587890625],
              [-107.772216796875, 31.777050781249997],
              [-107.99204101562499, 31.778173828125],
              [-108.21181640625, 31.779345703124996],
              [-108.2125, 31.666845703125],
              [-108.21318359375, 31.554394531249997],
              [-108.213818359375, 31.44189453125],
              [-108.214453125, 31.329443359375],
              [-108.56787109375, 31.328808593749997],
              [-108.921337890625, 31.328125],
              [-109.27475585937499, 31.327441406249996],
              [-109.62822265625, 31.326806640624994],
              [-109.981640625, 31.326171875],
              [-110.335107421875, 31.325537109375],
              [-110.688525390625, 31.324853515624994],
              [-111.0419921875, 31.32421875],
              [-111.51621093749999, 31.472265625],
              [-111.990478515625, 31.62021484375],
              [-112.46474609375, 31.768261718749997],
              [-112.93896484375, 31.916259765625],
              [-113.41318359374999, 32.064306640625],
              [-113.887451171875, 32.212304687499994],
              [-114.36171875, 32.360302734375],
              [-114.8359375, 32.50830078125],
              [-114.78798828125, 32.564794921875],
              [-114.724755859375, 32.71533203125],
              [-114.8390625, 32.704736328124994],
              [-115.1251953125, 32.68330078125],
              [-115.411376953125, 32.661865234375],
              [-115.697509765625, 32.640478515625],
              [-115.98369140625, 32.619042968749994],
              [-116.26982421874999, 32.597607421875],
              [-116.55595703124999, 32.576220703124996],
              [-116.84208984374999, 32.55478515625],
              [-117.128271484375, 32.533349609374994],
              [-117.13046875, 32.53974609375],
              [-117.13740234375, 32.649169921875],
              [-117.183740234375, 32.687890625],
              [-117.24345703124999, 32.664013671875],
              [-117.270703125, 32.80625],
              [-117.25576171875, 32.873388671875],
              [-117.26298828124999, 32.938867187499994],
              [-117.31884765625, 33.100048828125],
              [-117.467431640625, 33.2955078125],
              [-117.788525390625, 33.538476562499994],
              [-117.952099609375, 33.61962890625],
              [-118.080517578125, 33.72216796875],
              [-118.16191406249999, 33.75068359375],
              [-118.264404296875, 33.75859375],
              [-118.294189453125, 33.712304687499994],
              [-118.41044921874999, 33.743945312499996],
              [-118.39296875, 33.858300781249994],
              [-118.506201171875, 34.017382812499996],
              [-118.598828125, 34.035009765625],
              [-118.83203125, 34.024462890624996],
              [-119.14375, 34.11201171875],
              [-119.23583984375, 34.164111328124996],
              [-119.26767578125, 34.257421875],
              [-119.413671875, 34.33857421875],
              [-119.6060546875, 34.418017578124996],
              [-119.71318359374999, 34.399658203125],
              [-119.85332031249999, 34.411962890625],
              [-120.052978515625, 34.469287109374996],
              [-120.16953125, 34.476464843749994],
              [-120.396484375, 34.4595703125],
              [-120.481201171875, 34.471630859375],
              [-120.559814453125, 34.543896484375],
              [-120.644677734375, 34.579980468749994],
              [-120.626708984375, 34.6689453125],
              [-120.63759765625, 34.749365234375],
              [-120.62490234375, 34.811962890625],
              [-120.663037109375, 34.949267578124996],
              [-120.63359374999999, 35.076464843749996],
              [-120.65908203125, 35.122412109375],
              [-120.70703125, 35.157666015625],
              [-120.857373046875, 35.20966796875],
              [-120.88486328125, 35.274951171874996],
              [-120.860302734375, 35.3654296875],
              [-120.899609375, 35.425097656249996],
              [-121.0228515625, 35.480761718749996],
              [-121.137939453125, 35.60712890625],
              [-121.283837890625, 35.676318359374996],
              [-121.34384765625, 35.792236328125],
              [-121.433740234375, 35.8638671875],
              [-121.464990234375, 35.927392578124994],
              [-121.66435546874999, 36.154052734375],
              [-121.877392578125, 36.3310546875],
              [-121.91015625, 36.432910156249996],
              [-121.91865234375, 36.57236328125],
              [-121.83515625, 36.657470703125],
              [-121.789990234375, 36.732275390625],
              [-121.79453125, 36.8009765625],
              [-121.80742187499999, 36.851220703124994],
              [-121.88066406249999, 36.938916015625],
              [-122.164208984375, 36.990966796875],
              [-122.394921875, 37.20751953125],
              [-122.408447265625, 37.37314453125],
              [-122.49921875, 37.542626953124994],
              [-122.500439453125, 37.652783203125],
              [-122.51420898437499, 37.77197265625],
              [-122.44560546874999, 37.797998046874994],
              [-122.38408203124999, 37.788525390625],
              [-122.390283203125, 37.741064453125],
              [-122.3697265625, 37.655859375],
              [-122.297607421875, 37.591845703124996],
              [-122.228662109375, 37.563916015625],
              [-122.166015625, 37.50166015625],
              [-122.11904296875, 37.4828125],
              [-122.0705078125, 37.478271484375],
              [-122.096533203125, 37.518212890624994],
              [-122.12412109375, 37.543798828125],
              [-122.158056640625, 37.62646484375],
              [-122.22221679687499, 37.73203125],
              [-122.29599609375, 37.790332031249996],
              [-122.333447265625, 37.89658203125],
              [-122.365478515625, 37.921191406249996],
              [-122.38544921875, 37.960595703124994],
              [-122.31425781249999, 38.00732421875],
              [-122.217041015625, 38.040625],
              [-122.08671874999999, 38.049609375],
              [-121.71684570312499, 38.034082031249994],
              [-121.6380859375, 38.061279296875],
              [-121.572998046875, 38.052392578124994],
              [-121.525341796875, 38.055908203125],
              [-121.625732421875, 38.083935546875],
              [-121.6822265625, 38.0748046875],
              [-121.7486328125, 38.08046875],
              [-121.88076171875, 38.075],
              [-121.9341796875, 38.086816406249994],
              [-121.993115234375, 38.1201171875],
              [-122.031494140625, 38.12353515625],
              [-122.153759765625, 38.06552734375],
              [-122.20830078124999, 38.072558593749996],
              [-122.337109375, 38.135888671874994],
              [-122.39335937499999, 38.144824218749996],
              [-122.48388671875, 38.108837890625],
              [-122.49492187499999, 37.953564453125],
              [-122.46689453125, 37.838183593749996],
              [-122.521337890625, 37.826416015625],
              [-122.5841796875, 37.874072265624996],
              [-122.68071289062499, 37.90234375],
              [-122.760400390625, 37.945654296875],
              [-122.87294921875, 38.02607421875],
              [-122.931982421875, 38.05546875],
              [-122.998779296875, 37.988623046875],
              [-123.00146484375, 38.019287109375],
              [-122.9681640625, 38.097021484375],
              [-122.97758789062499, 38.22734375],
              [-122.876806640625, 38.123339843749996],
              [-122.908154296875, 38.196582031249996],
              [-122.9865234375, 38.277099609375],
              [-123.04619140624999, 38.305078125],
              [-123.12114257812499, 38.449267578124996],
              [-123.28974609375, 38.53583984375],
              [-123.4248046875, 38.675634765625],
              [-123.701123046875, 38.907275390624996],
              [-123.71953124999999, 39.110986328124994],
              [-123.8203125, 39.368408203125],
              [-123.777783203125, 39.514941406249996],
              [-123.78349609374999, 39.618701171874996],
              [-123.83291015625, 39.77548828125],
              [-123.88447265625, 39.860791015625],
              [-124.10849609375, 40.09453125],
              [-124.32402343749999, 40.251953125],
              [-124.35654296875, 40.37109375],
              [-124.3716796875, 40.4912109375],
              [-124.32451171874999, 40.598095703125],
              [-124.28369140625, 40.710546875],
              [-124.25390625, 40.740283203124996],
              [-124.242333984375, 40.727880859375],
              [-124.2505859375, 40.70390625],
              [-124.22001953124999, 40.696484375],
              [-124.208447265625, 40.74609375],
              [-124.19023437499999, 40.771728515625],
              [-124.222509765625, 40.775048828124994],
              [-124.219189453125, 40.79072265625],
              [-124.19990234375, 40.822070312499996],
              [-124.13310546874999, 40.969775390624996],
              [-124.1400390625, 41.155908203124994],
              [-124.068505859375, 41.384179687499994],
              [-124.071923828125, 41.459521484374996],
              [-124.11767578125, 41.621728515624994],
              [-124.163232421875, 41.718994140625],
              [-124.24462890625, 41.787939453125],
              [-124.208740234375, 41.888574218749994],
              [-124.211669921875, 41.984619140625],
              [-124.35527343749999, 42.122900390625],
              [-124.410009765625, 42.304345703124994],
              [-124.4205078125, 42.381005859374994],
              [-124.40615234375, 42.58369140625],
              [-124.443798828125, 42.670214843749996],
              [-124.5396484375, 42.812890625],
              [-124.49858398437499, 42.936865234375],
              [-124.454443359375, 43.012353515624994],
              [-124.34658203125, 43.341650390625],
              [-124.32060546874999, 43.368212890624996],
              [-124.27548828125, 43.3673828125],
              [-124.196923828125, 43.42333984375],
              [-124.233154296875, 43.436376953125],
              [-124.28798828125, 43.409716796874996],
              [-124.239208984375, 43.5400390625],
              [-124.184375, 43.6515625],
              [-124.14873046874999, 43.691748046875],
              [-124.13066406249999, 44.0556640625],
              [-124.09916992187499, 44.333789062499996],
              [-124.0474609375, 44.42548828125],
              [-124.0654296875, 44.520068359374996],
              [-124.04453125, 44.648242187499996],
              [-124.0591796875, 44.777734375],
              [-123.948583984375, 45.400830078125],
              [-123.9630859375, 45.47607421875],
              [-123.929345703125, 45.576953125],
              [-123.96123046874999, 45.84296875],
              [-123.947119140625, 46.140576171875],
              [-123.975244140625, 46.1783203125],
              [-123.98930664062499, 46.219384765624994],
              [-123.96293945312499, 46.225439453125],
              [-123.91166992187499, 46.182177734374996],
              [-123.67363281249999, 46.1826171875],
              [-123.521630859375, 46.22265625],
              [-123.46635742187499, 46.209423828125],
              [-123.402294921875, 46.15498046875],
              [-123.32158203124999, 46.143994140625],
              [-123.22060546875, 46.153613281249996],
              [-123.251318359375, 46.167285156249996],
              [-123.298681640625, 46.170849609375],
              [-123.404736328125, 46.220996093749996],
              [-123.46484375, 46.27109375],
              [-123.650341796875, 46.267724609374994],
              [-123.68837890625, 46.299853515624996],
              [-123.895703125, 46.2677734375],
              [-123.95976562499999, 46.300732421875],
              [-124.07275390625, 46.279443359374994],
              [-124.0451171875, 46.372900390625],
              [-124.0501953125, 46.490527343749996],
              [-124.0443359375, 46.605078125],
              [-124.01640624999999, 46.521386718749994],
              [-123.946142578125, 46.432568359375],
              [-123.91240234374999, 46.533349609374994],
              [-123.88916015625, 46.660009765625],
              [-123.95771484375, 46.70869140625],
              [-124.0716796875, 46.744775390624994],
              [-124.112548828125, 46.862695312499994],
              [-123.84287109375, 46.963183593749996],
              [-123.98603515625, 46.98447265625],
              [-124.042236328125, 47.0296875],
              [-124.11171875, 47.035205078124996],
              [-124.116796875, 47.000341796875],
              [-124.1392578125, 46.9546875],
              [-124.16357421875, 47.01533203125],
              [-124.1705078125, 47.086669921875],
              [-124.198828125, 47.208544921874996],
              [-124.30927734375, 47.404589843749996],
              [-124.37602539062499, 47.658642578125],
              [-124.46005859375, 47.784228515624996],
              [-124.62109375, 47.904150390625],
              [-124.6630859375, 47.97412109375],
              [-124.70166015625, 48.151660156249996],
              [-124.67998046874999, 48.285888671875],
              [-124.7099609375, 48.38037109375],
              [-124.63261718749999, 48.375048828124996],
              [-124.429052734375, 48.30078125],
              [-124.17548828125, 48.242431640625],
              [-124.098779296875, 48.2],
              [-123.97578125, 48.16845703125],
              [-123.29443359375, 48.11953125],
              [-123.24990234375, 48.12421875],
              [-123.161865234375, 48.154541015625],
              [-123.1244140625, 48.150927734374996],
              [-123.02421874999999, 48.081591796874996],
              [-122.973876953125, 48.073291015624996],
              [-122.90888671875, 48.076904296875],
              [-122.86088867187499, 48.0900390625],
              [-122.77861328124999, 48.137597656249994],
              [-122.767529296875, 48.120019531249994],
              [-122.76909179687499, 48.075976562499996],
              [-122.73974609375, 48.013232421874996],
              [-122.67949218749999, 47.931787109374994],
              [-122.656640625, 47.881152343749996],
              [-122.77841796875, 47.738427734374994],
              [-122.8017578125, 47.7353515625],
              [-122.80537109375, 47.783642578125],
              [-122.82138671875, 47.7931640625],
              [-123.050634765625, 47.551953125],
              [-123.1310546875, 47.437744140625],
              [-123.1390625, 47.386083984375],
              [-123.136328125, 47.355810546875],
              [-123.10419921875, 47.348388671875],
              [-123.030908203125, 47.360205078125],
              [-122.92216796874999, 47.407666015625],
              [-122.91689453125, 47.41796875],
              [-123.018212890625, 47.40107421875],
              [-123.066796875, 47.399658203125],
              [-123.06015625, 47.453662109374996],
              [-123.04863281249999, 47.479345703125],
              [-122.98247070312499, 47.559375],
              [-122.91289062499999, 47.607373046875],
              [-122.8140625, 47.658544921875],
              [-122.75712890624999, 47.700537109375],
              [-122.71787109375, 47.762109375],
              [-122.608154296875, 47.835498046874996],
              [-122.587890625, 47.85595703125],
              [-122.59267578125, 47.91640625],
              [-122.58574218749999, 47.927880859374994],
              [-122.5328125, 47.919726562499996],
              [-122.51079101562499, 47.815722656249996],
              [-122.52392578125, 47.769335937499996],
              [-122.618408203125, 47.712792968749994],
              [-122.63017578124999, 47.692822265625],
              [-122.613623046875, 47.615625],
              [-122.628271484375, 47.608154296875],
              [-122.664306640625, 47.617236328124996],
              [-122.67548828125, 47.612353515624996],
              [-122.58583984375, 47.52841796875],
              [-122.55742187499999, 47.463183593749996],
              [-122.553564453125, 47.404931640624994],
              [-122.577880859375, 47.2931640625],
              [-122.60390625, 47.274609375],
              [-122.6486328125, 47.2814453125],
              [-122.70771484375, 47.31640625],
              [-122.7208984375, 47.305126953125],
              [-122.7677734375, 47.218359375],
              [-122.78330078125, 47.225976562499994],
              [-122.81254882812499, 47.328955078125],
              [-122.828466796875, 47.336572265624994],
              [-122.91953125, 47.2896484375],
              [-122.956201171875, 47.244580078125],
              [-122.987646484375, 47.17255859375],
              [-123.027587890625, 47.138916015625],
              [-122.91416015625, 47.131494140624994],
              [-122.811962890625, 47.14599609375],
              [-122.7298828125, 47.11181640625],
              [-122.70195312499999, 47.110888671874996],
              [-122.62705078125, 47.14423828125],
              [-122.604150390625, 47.1669921875],
              [-122.5421875, 47.2755859375],
              [-122.511083984375, 47.29501953125],
              [-122.46484375, 47.295800781249994],
              [-122.4201171875, 47.312109375],
              [-122.35380859374999, 47.37158203125],
              [-122.35112304687499, 47.39521484375],
              [-122.375244140625, 47.528369140624996],
              [-122.368359375, 47.60390625],
              [-122.38076171875, 47.627832031249994],
              [-122.410498046875, 47.652636718749996],
              [-122.40678710937499, 47.6767578125],
              [-122.383642578125, 47.716455078124994],
              [-122.381982421875, 47.75234375],
              [-122.401806640625, 47.78427734375],
              [-122.39287109374999, 47.820556640625],
              [-122.330322265625, 47.8986328125],
              [-122.31845703125, 47.933056640625],
              [-122.24199218749999, 48.0107421875],
              [-122.26127929687499, 48.042041015624996],
              [-122.31748046875, 48.08017578125],
              [-122.352978515625, 48.113818359374996],
              [-122.388671875, 48.166357421875],
              [-122.41582031249999, 48.183935546875],
              [-122.42470703125, 48.175927734374994],
              [-122.38662109375, 48.08994140625],
              [-122.394775390625, 48.084130859374994],
              [-122.49404296875, 48.13046875],
              [-122.5169921875, 48.15966796875],
              [-122.529150390625, 48.19931640625],
              [-122.52031249999999, 48.2291015625],
              [-122.467041015625, 48.25849609375],
              [-122.40336914062499, 48.269189453124994],
              [-122.408544921875, 48.293896484375],
              [-122.488427734375, 48.374316406249996],
              [-122.541650390625, 48.4109375],
              [-122.58256835937499, 48.428662109375],
              [-122.63779296875, 48.43330078125],
              [-122.6625, 48.44638671875],
              [-122.66899414062499, 48.465234375],
              [-122.65727539062499, 48.489990234375],
              [-122.62797851562499, 48.497900390625],
              [-122.54267578125, 48.48798828125],
              [-122.49677734375, 48.50556640625],
              [-122.50107421875, 48.5375],
              [-122.514794921875, 48.55517578125],
              [-122.51274414062499, 48.66943359375],
              [-122.5451171875, 48.7623046875],
              [-122.56201171875, 48.777978515624994],
              [-122.58017578125, 48.779589843749996],
              [-122.59941406249999, 48.76708984375],
              [-122.65302734375, 48.7638671875],
              [-122.6859375, 48.794287109375],
              [-122.72246093749999, 48.85302734375],
              [-122.78876953125, 48.993017578125],
              [-122.686376953125, 48.993017578125],
              [-122.260009765625, 48.993017578125],
              [-121.83359375, 48.993017578125],
              [-121.4072265625, 48.99301757812492],
              [-120.980859375, 48.993017578125],
              [-120.55449218749999, 48.99301757812492],
              [-120.128076171875, 48.993017578125],
              [-119.70170898437499, 48.993017578125],
              [-119.275341796875, 48.993066406249994],
              [-118.84892578124999, 48.993066406249994],
              [-118.42255859375, 48.993066406249994],
              [-117.99619140625, 48.993066406249994],
              [-117.569775390625, 48.993066406249994],
              [-117.143408203125, 48.993066406249994],
              [-116.717041015625, 48.993066406249994],
              [-116.290625, 48.993066406249994],
              [-115.8642578125, 48.993066406249994],
              [-115.437890625, 48.993066406249994],
              [-115.0115234375, 48.993066406249994],
              [-114.585107421875, 48.993066406249994],
              [-114.158740234375, 48.993066406249994],
              [-113.732373046875, 48.993066406249994],
              [-113.30595703125, 48.993066406249994],
              [-112.87958984375, 48.993066406249994],
              [-112.45322265624999, 48.993066406249994],
              [-112.026806640625, 48.993066406249994],
              [-111.60043945312499, 48.993066406249994],
              [-111.174072265625, 48.993066406249994],
              [-110.74765625, 48.993066406249994],
              [-110.3212890625, 48.993066406249994],
              [-109.89492187500002, 48.993066406250136],
              [-109.4685546875, 48.993066406249994],
              [-109.04213867187502, 48.993115234375125],
              [-108.615771484375, 48.993115234375],
              [-108.18940429687501, 48.993115234375125],
              [-107.76298828125, 48.993115234375],
              [-107.33662109375001, 48.993115234375125],
              [-106.91025390624999, 48.993115234375],
              [-106.48383789062501, 48.993115234375125],
              [-106.05747070312499, 48.993115234375],
              [-105.631103515625, 48.993115234375125],
              [-105.2046875, 48.993115234375],
              [-104.7783203125, 48.993115234375125],
              [-104.35195312500002, 48.993115234375125],
              [-103.9255859375, 48.993115234375],
              [-103.49916992187502, 48.993115234375125],
              [-103.07280273437499, 48.993115234375],
              [-102.64643554687501, 48.993115234375125],
              [-102.22001953124999, 48.993115234375125],
              [-101.79365234375001, 48.993115234375125],
              [-101.36728515624999, 48.993115234375],
              [-100.94086914062501, 48.993115234375125],
              [-100.51450195312499, 48.993115234375],
              [-100.088134765625, 48.993115234375125],
              [-99.66171875, 48.993115234375],
              [-99.2353515625, 48.993115234375],
              [-98.808984375, 48.9931640625],
              [-98.38261718749999, 48.9931640625],
              [-97.956201171875, 48.9931640625],
              [-97.52983398437499, 48.9931640625],
              [-97.10346679687501, 48.9931640625],
              [-96.67705078124999, 48.9931640625],
              [-96.25068359375, 48.9931640625],
              [-95.82431640624999, 48.9931640625],
              [-95.397900390625, 48.9931640625],
              [-95.162060546875, 48.991748046874996],
              [-95.15825195312499, 49.203076171875],
              [-95.1552734375, 49.3696777343751],
              [-94.93935546875, 49.349414062499996],
              [-94.87480468749999, 49.31904296875],
              [-94.85434570312499, 49.304589843749994],
              [-94.86040039062499, 49.25859375],
              [-94.84257812499999, 49.119189453125045],
              [-94.803466796875, 49.0029296875],
              [-94.71279296875002, 48.86342773437502],
              [-94.712548828125, 48.86298828125],
              [-94.705078125, 48.808496093749994],
              [-94.67534179687499, 48.7744140625],
              [-94.62089843749999, 48.742626953125],
              [-94.41416015624999, 48.7041015625],
              [-94.05517578125, 48.659033203125],
              [-93.85161132812499, 48.607275390625034],
              [-93.80356445312499, 48.54892578125],
              [-93.70771484375, 48.525439453124996],
              [-93.56425781249999, 48.536914062499996],
              [-93.463623046875, 48.561279296875114],
              [-93.37788085937501, 48.61655273437498],
              [-93.25795898437502, 48.62885742187501],
              [-93.155224609375, 48.625341796875],
              [-93.05170898437501, 48.619873046875114],
              [-92.996240234375, 48.61181640625],
              [-92.83671874999999, 48.567773437499994],
              [-92.732666015625, 48.5318359375],
              [-92.583251953125, 48.465087890625],
              [-92.50058593749999, 48.435351562499996],
              [-92.46088867187501, 48.36586914062508],
              [-92.41459960937499, 48.276611328125],
              [-92.34843749999999, 48.276611328125],
              [-92.29868164062499, 48.32890625],
              [-92.17177734375, 48.33837890625],
              [-92.00517578124999, 48.30185546875],
              [-91.8583984375, 48.197558593750045],
              [-91.64731445312499, 48.10458984375],
              [-91.518310546875, 48.058300781250125],
              [-91.38720703125, 48.058544921875],
              [-91.22065429687501, 48.10458984374998],
              [-91.04345703125, 48.193701171875],
              [-90.91606445312499, 48.209130859374994],
              [-90.84033203125, 48.20053710937498],
              [-90.79731445312501, 48.13105468750001],
              [-90.744384765625, 48.10458984375],
              [-90.607080078125, 48.112597656249996],
              [-90.32011718749999, 48.099169921874996],
              [-90.091796875, 48.11811523437507],
              [-90.03994140625, 48.078173828124996],
              [-89.99365234375, 48.01533203125],
              [-89.90102539062502, 47.99545898437509],
              [-89.775390625, 48.01533203125],
              [-89.55058593749999, 47.999902343749994],
              [-89.45566406249999, 47.996240234374994],
              [-89.273193359375, 48.019970703125125],
              [-89.18564453124999, 48.047412109374996],
              [-89.06259765624999, 48.093798828124996],
              [-88.898681640625, 48.155712890625],
              [-88.61176757812501, 48.26401367187509],
              [-88.378173828125, 48.30307617187509],
              [-88.16064453125, 48.225390625000045],
              [-87.987451171875, 48.156884765624994],
              [-87.92050781249999, 48.13037109375],
              [-87.743896484375, 48.060546875],
              [-87.49423828124999, 47.961767578125],
              [-87.2080078125, 47.848486328125],
              [-86.921826171875, 47.735205078125],
              [-86.67216796874999, 47.63642578125],
              [-86.49555664062501, 47.566601562500125],
              [-86.42856445312499, 47.540087890624996],
              [-86.23447265624999, 47.46005859375],
              [-86.04038085937499, 47.380029296874994],
              [-85.846337890625, 47.3],
              [-85.65224609375002, 47.21997070312506],
              [-85.458203125, 47.139941406249996],
              [-85.26411132812501, 47.059960937500136],
              [-85.070068359375, 46.979931640625],
              [-84.8759765625, 46.89990234375],
              [-84.82705078125, 46.766845703125],
              [-84.77939453124999, 46.6373046875],
              [-84.665771484375, 46.543261718750045],
              [-84.561767578125, 46.457373046875],
              [-84.5015625, 46.461865234375],
              [-84.44047851562499, 46.49814453125],
              [-84.40170898437499, 46.515625],
              [-84.33671874999999, 46.518505859375],
              [-84.19218749999999, 46.549560546875],
              [-84.14946289062499, 46.542773437499996],
              [-84.1251953125, 46.527246093749994],
              [-84.12319335937502, 46.5029296875],
              [-84.128125, 46.48359375],
              [-84.15048828124999, 46.444775390625],
              [-84.11518554687501, 46.37080078125007],
              [-84.10776367187499, 46.288623046874996],
              [-84.08837890625, 46.226513671875],
              [-84.02919921875, 46.147021484374996],
              [-83.977783203125, 46.084912109375],
              [-83.91303710937501, 46.072900390625136],
              [-83.76318359375, 46.10908203125],
              [-83.66928710937499, 46.12275390625],
              [-83.615966796875, 46.11684570312502],
              [-83.52475585937499, 46.05869140625],
              [-83.48012695312502, 46.023730468750045],
              [-83.469482421875, 45.99467773437499],
              [-83.59267578125002, 45.81713867187506],
              [-83.39731445312499, 45.729052734374996],
              [-83.179296875, 45.632763671875],
              [-82.9193359375, 45.51796875],
              [-82.76040039062502, 45.447705078125125],
              [-82.55107421874999, 45.34736328125],
              [-82.515234375, 45.204394531249996],
              [-82.48505859375, 45.083740234375],
              [-82.44658203124999, 44.91552734375],
              [-82.407373046875, 44.743945312499996],
              [-82.36826171874999, 44.572998046875],
              [-82.326806640625, 44.391552734375],
              [-82.28125, 44.192236328125],
              [-82.24077148437499, 44.01533203125],
              [-82.19658203124999, 43.822216796875],
              [-82.13784179687502, 43.570898437500034],
              [-82.190380859375, 43.474072265625],
              [-82.30478515624999, 43.263232421874996],
              [-82.408203125, 43.07265625],
              [-82.417236328125, 43.017382812500045],
              [-82.48833007812499, 42.739501953125],
              [-82.5453125, 42.62470703125],
              [-82.64511718749999, 42.558056640625],
              [-82.74418945312499, 42.4934570312501],
              [-82.86777343749999, 42.385205078125],
              [-83.00371093749999, 42.33173828125],
              [-83.07314453125, 42.30029296875],
              [-83.10952148437502, 42.25068359375001],
              [-83.149658203125, 42.141943359375],
              [-83.14194335937499, 41.975878906249996],
              [-83.02998046875, 41.832958984375],
              [-82.8662109375, 41.75302734375],
              [-82.69003906249999, 41.675195312499994],
              [-82.4390625, 41.674853515624996],
              [-82.213330078125, 41.778710937499994],
              [-81.97416992187499, 41.88872070312499],
              [-81.7609375, 41.98681640625],
              [-81.50732421875, 42.10346679687501],
              [-81.27763671874999, 42.2091796875],
              [-81.02822265625002, 42.24716796874998],
              [-80.6826171875, 42.299755859375],
              [-80.24755859375, 42.366015625],
              [-80.03574218749999, 42.441455078124996],
              [-79.76201171874999, 42.538964843750136],
              [-79.446240234375, 42.65146484375],
              [-79.17373046875002, 42.74853515625],
              [-79.03671875, 42.80234375],
              [-78.93925781249999, 42.863720703125],
              [-78.91508789062499, 42.90913085937501],
              [-78.92084960937501, 42.93520507812502],
              [-78.94599609375001, 42.96132812500002],
              [-78.98076171874999, 42.980615234374994],
              [-79.011669921875, 42.997021484375],
              [-79.02617187499999, 43.017333984375],
              [-79.029052734375, 43.061767578125],
              [-79.047998046875, 43.087304687499994],
              [-79.06606445312502, 43.10610351562502],
              [-79.059228515625, 43.278076171875],
              [-79.08305664062499, 43.331396484375],
              [-79.171875, 43.466552734375114],
              [-79.00249023437499, 43.527148437499996],
              [-78.84555664062499, 43.583349609375],
              [-78.72041015625, 43.624951171875],
              [-78.458251953125, 43.631494140624994],
              [-78.214794921875, 43.630664062499996],
              [-77.87924804687499, 43.629541015624994],
              [-77.59653320312499, 43.62861328125],
              [-77.26669921874999, 43.627490234374996],
              [-77.07333984374999, 43.626855468749994],
              [-76.81997070312502, 43.62880859375008],
              [-76.69648437500001, 43.78481445312508],
              [-76.58613281250001, 43.924316406250114],
              [-76.464599609375, 44.0576171875],
              [-76.24853515625, 44.214111328125],
              [-76.185791015625, 44.242236328124996],
              [-76.15117187499999, 44.303955078125],
              [-76.02021484375001, 44.362597656250045],
              [-75.87592773437501, 44.416992187500114],
              [-75.8193359375, 44.468017578125],
              [-75.79194335937501, 44.49707031250006],
              [-75.40126953125002, 44.77226562499999],
              [-75.17939453125001, 44.899365234375125],
              [-74.99614257812499, 44.970117187499966],
              [-74.85664062500001, 45.003906250000114],
              [-74.762451171875, 44.999072265624996],
              [-74.70888671875002, 45.003857421875125],
            ],
          ],
          [
            [
              [-72.509765625, 40.98603515625],
              [-72.58085937499999, 40.921337890625],
              [-72.5166015625, 40.914794921875],
              [-72.461328125, 40.9337890625],
              [-72.40898437499999, 40.97216796875],
              [-72.28745117187499, 41.024072265624994],
              [-72.18388671874999, 41.046777343749994],
              [-72.15126953125, 41.05146484375],
              [-72.10190429687499, 41.015039062499994],
              [-72.00395507812499, 41.044287109375],
              [-71.90322265625, 41.060693359374994],
              [-72.33896484374999, 40.894140625],
              [-72.42807617187499, 40.875390625],
              [-72.55556640625, 40.82578125],
              [-72.67607421874999, 40.790625],
              [-72.762841796875, 40.77783203125],
              [-73.194287109375, 40.654199218749994],
              [-73.228515625, 40.651513671874994],
              [-73.26552734375, 40.66357421875],
              [-73.62089843749999, 40.599902343749996],
              [-73.766748046875, 40.592724609375],
              [-73.899560546875, 40.5705078125],
              [-73.80131835937499, 40.62177734375],
              [-73.79916992187499, 40.640966796875],
              [-73.82265625, 40.65595703125],
              [-73.8751953125, 40.651611328125],
              [-73.92900390624999, 40.598828125],
              [-74.014892578125, 40.581201171874994],
              [-74.03203124999999, 40.638671875],
              [-74.003369140625, 40.683154296874996],
              [-73.96455078125, 40.725341796875],
              [-73.87924804687499, 40.791650390624994],
              [-73.7572265625, 40.83369140625],
              [-73.69521484375, 40.870019531249994],
              [-73.65224609375, 40.838037109374994],
              [-73.642822265625, 40.88125],
              [-73.609765625, 40.906201171875],
              [-73.573828125, 40.91962890625],
              [-73.48740234374999, 40.919970703124996],
              [-73.440869140625, 40.9267578125],
              [-73.4072265625, 40.94111328125],
              [-73.372705078125, 40.943798828125],
              [-73.27817382812499, 40.92421875],
              [-73.18583984374999, 40.929833984374994],
              [-73.111279296875, 40.956884765625],
              [-73.03378906249999, 40.965966796874994],
              [-72.82880859375, 40.972070312499994],
              [-72.62509765624999, 40.991845703124994],
              [-72.54365234375, 41.027001953124994],
              [-72.37255859375, 41.125537109374996],
              [-72.27412109375, 41.15302734375],
              [-72.427392578125, 41.038525390625],
              [-72.509765625, 40.98603515625],
            ],
          ],
          [
            [
              [-68.187255859375, 44.332470703125],
              [-68.24545898437499, 44.31298828125],
              [-68.30927734375, 44.321484375],
              [-68.30795898437499, 44.268701171874994],
              [-68.315087890625, 44.24970703125],
              [-68.38579101562499, 44.27685546875],
              [-68.41171875, 44.294335937499994],
              [-68.40947265624999, 44.3642578125],
              [-68.34702148437499, 44.43037109375],
              [-68.29941406249999, 44.456494140625],
              [-68.238037109375, 44.438378906249994],
              [-68.19091796875, 44.36435546875],
              [-68.187255859375, 44.332470703125],
            ],
          ],
          [
            [
              [-74.188134765625, 40.522851562499994],
              [-74.23588867187499, 40.518701171874994],
              [-74.18818359375, 40.614599609375],
              [-74.10048828125, 40.658447265625],
              [-74.06875, 40.649316406249994],
              [-74.0673828125, 40.6154296875],
              [-74.0796875, 40.586474609374996],
              [-74.13852539062499, 40.541845703125],
              [-74.188134765625, 40.522851562499994],
            ],
          ],
          [
            [
              [-70.509912109375, 41.376318359375],
              [-70.785302734375, 41.327441406249996],
              [-70.82919921874999, 41.358984375],
              [-70.760498046875, 41.373583984374996],
              [-70.67373046875, 41.448535156249996],
              [-70.61601562499999, 41.4572265625],
              [-70.525341796875, 41.414794921875],
              [-70.509912109375, 41.376318359375],
            ],
          ],
          [
            [
              [-71.24140625, 41.491943359375],
              [-71.29091796875, 41.464599609375],
              [-71.34624023437499, 41.469384765624994],
              [-71.31816406249999, 41.506298828125],
              [-71.30747070312499, 41.560498046875],
              [-71.28017578125, 41.620019531249994],
              [-71.26445312499999, 41.638232421874996],
              [-71.23203125, 41.654296875],
              [-71.24140625, 41.491943359375],
            ],
          ],
          [
            [
              [-69.9779296875, 41.265576171875],
              [-70.055078125, 41.249462890625],
              [-70.233056640625, 41.286328125],
              [-70.08662109375, 41.317578125],
              [-70.0626953125, 41.328466796875],
              [-70.043603515625, 41.374414062499994],
              [-70.0412109375, 41.3974609375],
              [-69.985595703125, 41.298632812499996],
              [-69.9779296875, 41.265576171875],
            ],
          ],
          [
            [
              [-75.635693359375, 35.855908203125],
              [-75.65078125, 35.835595703124994],
              [-75.7171875, 35.946142578125],
              [-75.648876953125, 35.910400390625],
              [-75.636669921875, 35.880664062499996],
              [-75.635693359375, 35.855908203125],
            ],
          ],
          [
            [
              [-75.544140625, 35.240087890625],
              [-75.67827148437499, 35.212841796875],
              [-75.690087890625, 35.221582031249994],
              [-75.536376953125, 35.278613281249996],
              [-75.487890625, 35.4794921875],
              [-75.48125, 35.572119140625],
              [-75.504296875, 35.735400390624996],
              [-75.503515625, 35.769140625],
              [-75.478515625, 35.71650390625],
              [-75.4564453125, 35.56416015625],
              [-75.46474609375, 35.448632812499994],
              [-75.50932617187499, 35.280322265624996],
              [-75.544140625, 35.240087890625],
            ],
          ],
          [
            [
              [-75.78193359375, 35.190185546875],
              [-75.96367187499999, 35.11884765625],
              [-75.98417968749999, 35.123095703124996],
              [-75.86494140625, 35.174121093749996],
              [-75.78193359375, 35.190185546875],
            ],
          ],
          [
            [
              [-76.503662109375, 34.64296875],
              [-76.528564453125, 34.631494140624994],
              [-76.43701171875, 34.75634765625],
              [-76.25620117187499, 34.914697265624994],
              [-76.20737304687499, 34.938916015625],
              [-76.35771484374999, 34.803662109375],
              [-76.503662109375, 34.64296875],
            ],
          ],
          [
            [
              [-82.03720703124999, 26.45361328125],
              [-82.07285156249999, 26.427539062499996],
              [-82.144970703125, 26.446679687499994],
              [-82.184375, 26.48095703125],
              [-82.2013671875, 26.548046875],
              [-82.13857421875, 26.477001953124997],
              [-82.11606445312499, 26.4609375],
              [-82.03720703124999, 26.45361328125],
            ],
          ],
          [
            [
              [-82.08378906249999, 26.55234375],
              [-82.085205078125, 26.493603515624997],
              [-82.13559570312499, 26.591992187499997],
              [-82.169140625, 26.700732421874996],
              [-82.12114257812499, 26.66552734375],
              [-82.08378906249999, 26.55234375],
            ],
          ],
          [
            [
              [-80.3818359375, 25.142285156249997],
              [-80.58056640625, 24.954248046874994],
              [-80.558544921875, 25.00131835937499],
              [-80.48105468749999, 25.101953125],
              [-80.456005859375, 25.149316406249994],
              [-80.40366210937499, 25.179345703124994],
              [-80.354931640625, 25.233642578125],
              [-80.35126953125, 25.29697265624999],
              [-80.28046875, 25.341259765624997],
              [-80.257080078125, 25.347607421874997],
              [-80.3818359375, 25.142285156249997],
            ],
          ],
          [
            [
              [-91.793701171875, 29.500732421875],
              [-91.83085937499999, 29.486474609374994],
              [-91.996240234375, 29.573095703125],
              [-92.00664062499999, 29.610302734374997],
              [-91.925048828125, 29.643945312499994],
              [-91.875244140625, 29.640966796875],
              [-91.796484375, 29.596972656249996],
              [-91.76767578124999, 29.584716796875],
              [-91.754296875, 29.56689453125],
              [-91.7619140625, 29.539013671874997],
              [-91.793701171875, 29.500732421875],
            ],
          ],
          [
            [
              [-97.01435546875, 27.901611328125],
              [-97.03603515625, 27.899169921875],
              [-96.98764648437499, 27.9810546875],
              [-96.978662109375, 28.013867187499997],
              [-96.89931640625, 28.117480468749996],
              [-96.857421875, 28.13291015625],
              [-96.83974609375, 28.088818359374997],
              [-96.921337890625, 28.016015625],
              [-97.01435546875, 27.901611328125],
            ],
          ],
          [
            [
              [-96.764404296875, 28.152587890625],
              [-96.80112304687499, 28.1484375],
              [-96.755615234375, 28.202441406249996],
              [-96.681640625, 28.2296875],
              [-96.51933593749999, 28.333447265624997],
              [-96.453125, 28.340576171875],
              [-96.41865234375, 28.376318359375],
              [-96.403564453125, 28.381591796875],
              [-96.413330078125, 28.337792968749994],
              [-96.543896484375, 28.275585937499997],
              [-96.764404296875, 28.152587890625],
            ],
          ],
          [
            [
              [-97.35361328124999, 27.300048828125],
              [-97.38481445312499, 27.242529296875],
              [-97.376220703125, 27.328271484374994],
              [-97.29501953124999, 27.523095703124994],
              [-97.130029296875, 27.779150390625],
              [-97.060546875, 27.822021484375],
              [-97.25087890625, 27.541210937499997],
              [-97.35361328124999, 27.300048828125],
            ],
          ],
          [
            [
              [-95.039697265625, 29.145898437499994],
              [-95.08964843749999, 29.136328125],
              [-94.87167968749999, 29.29013671875],
              [-94.82597656249999, 29.34130859375],
              [-94.76762695312499, 29.3390625],
              [-94.86494140625, 29.252880859374997],
              [-95.039697265625, 29.145898437499994],
            ],
          ],
          [
            [
              [-97.17070312499999, 26.159375],
              [-97.184521484375, 26.112939453124994],
              [-97.267333984375, 26.329785156249997],
              [-97.402099609375, 26.820507812499997],
              [-97.407177734375, 27.1001953125],
              [-97.385986328125, 27.196484375],
              [-97.351220703125, 26.801464843749997],
              [-97.20224609374999, 26.2998046875],
              [-97.17070312499999, 26.159375],
            ],
          ],
          [
            [
              [-120.306591796875, 34.024853515625],
              [-120.359716796875, 34.022265625],
              [-120.441552734375, 34.03291015625],
              [-120.412939453125, 34.056298828124994],
              [-120.36772460937499, 34.073291015624996],
              [-120.35332031249999, 34.060595703124996],
              [-120.306591796875, 34.024853515625],
            ],
          ],
          [
            [
              [-119.43803710937499, 33.2171875],
              [-119.48251953125, 33.21533203125],
              [-119.54365234375, 33.224609375],
              [-119.5751953125, 33.2783203125],
              [-119.525146484375, 33.28203125],
              [-119.47880859374999, 33.274609375],
              [-119.442041015625, 33.232421875],
              [-119.43803710937499, 33.2171875],
            ],
          ],
          [
            [
              [-118.35039062499999, 32.827587890625],
              [-118.40859375, 32.818505859374994],
              [-118.47319335937499, 32.838916015624996],
              [-118.52890625, 32.935595703124996],
              [-118.590185546875, 33.011181640625],
              [-118.557080078125, 33.032666015625],
              [-118.507470703125, 32.959912109375],
              [-118.383203125, 32.849462890625],
              [-118.35039062499999, 32.827587890625],
            ],
          ],
          [
            [
              [-120.0435546875, 33.918847656249994],
              [-120.113916015625, 33.9048828125],
              [-120.167138671875, 33.91806640625],
              [-120.251904296875, 34.0138671875],
              [-120.07182617187499, 34.026513671874994],
              [-119.994384765625, 33.984912109374996],
              [-119.98393554687499, 33.97333984375],
              [-120.0435546875, 33.918847656249994],
            ],
          ],
          [
            [
              [-119.88237304687499, 34.0796875],
              [-119.678857421875, 34.028466796874994],
              [-119.56914062499999, 34.052978515625],
              [-119.549267578125, 34.028173828125],
              [-119.56220703125, 34.006591796875],
              [-119.8095703125, 33.9677734375],
              [-119.885498046875, 33.994921875],
              [-119.892431640625, 34.032177734375],
              [-119.91806640625, 34.067822265625],
              [-119.88237304687499, 34.0796875],
            ],
          ],
          [
            [
              [-118.34794921874999, 33.3857421875],
              [-118.2974609375, 33.312109375],
              [-118.37021484375, 33.321240234375],
              [-118.4462890625, 33.31708984375],
              [-118.4693359375, 33.35712890625],
              [-118.492041015625, 33.41279296875],
              [-118.50732421875, 33.427001953125],
              [-118.559423828125, 33.431982421875],
              [-118.563330078125, 33.437060546874996],
              [-118.56943359375, 33.464160156249996],
              [-118.554833984375, 33.477099609374996],
              [-118.39169921875, 33.415087890624996],
              [-118.34794921874999, 33.3857421875],
            ],
          ],
          [
            [
              [-122.78212890625, 48.672705078125],
              [-122.76884765624999, 48.6509765625],
              [-122.808984375, 48.629833984375],
              [-122.83759765625, 48.6265625],
              [-122.88310546874999, 48.66064453125],
              [-122.90302734375, 48.664697265624994],
              [-122.88701171874999, 48.6123046875],
              [-122.892529296875, 48.594482421875],
              [-122.98564453124999, 48.626708984375],
              [-123.00283203125, 48.652197265625],
              [-122.97666015625, 48.679150390625],
              [-122.91801757812499, 48.706982421875],
              [-122.897705078125, 48.710351562499994],
              [-122.78212890625, 48.672705078125],
            ],
          ],
          [
            [
              [-123.013134765625, 48.500878906249994],
              [-122.986767578125, 48.468017578125],
              [-123.09443359375, 48.4890625],
              [-123.13994140624999, 48.507958984374994],
              [-123.15341796875, 48.526318359375],
              [-123.169580078125, 48.58671875],
              [-123.16215820312499, 48.606396484375],
              [-123.11416015625, 48.61328125],
              [-123.024169921875, 48.538476562499994],
              [-123.013134765625, 48.500878906249994],
            ],
          ],
          [
            [
              [-122.57275390625, 48.156640625],
              [-122.523828125, 48.025439453124996],
              [-122.50283203125, 48.080078125],
              [-122.36674804687499, 47.98544921875],
              [-122.3666015625, 47.938818359375],
              [-122.383154296875, 47.923193359375],
              [-122.41142578124999, 47.917724609375],
              [-122.43759765625, 47.93134765625],
              [-122.46162109375, 47.964013671874994],
              [-122.49228515625, 47.981298828125],
              [-122.55751953125, 47.992480468749996],
              [-122.59135742187499, 48.029638671875],
              [-122.603173828125, 48.055029296875],
              [-122.606298828125, 48.128564453124994],
              [-122.62265625, 48.151416015624996],
              [-122.65727539062499, 48.156494140625],
              [-122.690380859375, 48.173876953124996],
              [-122.74150390624999, 48.22529296875],
              [-122.74873046875, 48.239013671875],
              [-122.72451171875, 48.280908203124994],
              [-122.66899414062499, 48.351025390625],
              [-122.62861328125, 48.384228515625],
              [-122.603515625, 48.380615234375],
              [-122.5724609375, 48.3595703125],
              [-122.535546875, 48.321191406249994],
              [-122.542431640625, 48.293994140624996],
              [-122.692138671875, 48.241064453125],
              [-122.697021484375, 48.228662109374994],
              [-122.6244140625, 48.213769531249994],
              [-122.597607421875, 48.200439453125],
              [-122.57275390625, 48.156640625],
            ],
          ],
          [
            [
              [-71.36533203124999, 41.485253906249994],
              [-71.39306640625, 41.466748046875],
              [-71.40341796874999, 41.515039062499994],
              [-71.383984375, 41.570556640625],
              [-71.36430664062499, 41.571826171874996],
              [-71.3544921875, 41.542285156249996],
              [-71.36533203124999, 41.485253906249994],
            ],
          ],
          [
            [
              [-74.133203125, 39.68076171875],
              [-74.25048828125, 39.52939453125],
              [-74.253173828125, 39.558496093749994],
              [-74.10673828124999, 39.746435546875],
              [-74.133203125, 39.68076171875],
            ],
          ],
          [
            [
              [-75.33305664062499, 37.88828125],
              [-75.378515625, 37.8720703125],
              [-75.2259765625, 38.072314453124996],
              [-75.13740234375, 38.240087890625],
              [-75.097900390625, 38.298095703125],
              [-75.13623046875, 38.180517578125],
              [-75.20322265624999, 38.072412109374994],
              [-75.33305664062499, 37.88828125],
            ],
          ],
          [
            [
              [-76.54624023437499, 34.6548828125],
              [-76.568505859375, 34.6525390625],
              [-76.6078125, 34.66357421875],
              [-76.66196289062499, 34.68466796875],
              [-76.67392578124999, 34.700146484375],
              [-76.622265625, 34.69453125],
              [-76.54624023437499, 34.6548828125],
            ],
          ],
          [
            [
              [-81.33481445312499, 24.65048828124999],
              [-81.36479492187499, 24.629931640625003],
              [-81.379052734375, 24.636279296875003],
              [-81.379052734375, 24.666259765625],
              [-81.42167968749999, 24.732617187499997],
              [-81.420068359375, 24.75],
              [-81.32231445312499, 24.68505859375],
              [-81.31982421875, 24.667626953124994],
              [-81.33481445312499, 24.65048828124999],
            ],
          ],
          [
            [
              [-80.82939453124999, 24.803662109374997],
              [-80.84833984375, 24.803662109374997],
              [-80.8388671875, 24.81787109375],
              [-80.79941406249999, 24.84628906249999],
              [-80.78520507812499, 24.835253906250003],
              [-80.786767578125, 24.821044921875],
              [-80.82939453124999, 24.803662109374997],
            ],
          ],
          [
            [
              [-80.63828125, 24.90317382812499],
              [-80.66513671874999, 24.8984375],
              [-80.62568359375, 24.941113281249997],
              [-80.61459960937499, 24.937939453124997],
              [-80.63828125, 24.90317382812499],
            ],
          ],
          [
            [
              [-81.044189453125, 24.716796875],
              [-81.089990234375, 24.693115234375],
              [-81.137353515625, 24.710498046875003],
              [-81.08525390624999, 24.734179687500003],
              [-80.93046874999999, 24.759472656249997],
              [-80.988916015625, 24.72788085937499],
              [-81.044189453125, 24.716796875],
            ],
          ],
          [
            [
              [-81.41899414062499, 30.971435546875],
              [-81.46347656249999, 30.727783203125],
              [-81.48271484374999, 30.8140625],
              [-81.484619140625, 30.897851562499994],
              [-81.450927734375, 30.947412109374994],
              [-81.41899414062499, 30.971435546875],
            ],
          ],
          [
            [
              [-81.56669921874999, 24.599902343750003],
              [-81.631494140625, 24.590039062499997],
              [-81.579248046875, 24.62939453125],
              [-81.56230468749999, 24.689160156249997],
              [-81.531640625, 24.642480468749994],
              [-81.5322265625, 24.614160156249994],
              [-81.56669921874999, 24.599902343750003],
            ],
          ],
          [
            [
              [-80.186767578125, 27.27841796875],
              [-80.17050781249999, 27.204785156249997],
              [-80.262451171875, 27.3755859375],
              [-80.37607421874999, 27.643408203125],
              [-80.4369140625, 27.850537109374997],
              [-80.395751953125, 27.79453125],
              [-80.35551757812499, 27.678613281249994],
              [-80.186767578125, 27.27841796875],
            ],
          ],
          [
            [
              [-81.783837890625, 24.544580078124994],
              [-81.809228515625, 24.54233398437499],
              [-81.81142578125, 24.5578125],
              [-81.76767578124999, 24.576708984375003],
              [-81.738671875, 24.575439453125],
              [-81.73974609375, 24.554492187500003],
              [-81.783837890625, 24.544580078124994],
            ],
          ],
          [
            [
              [-88.889306640625, 29.712597656249997],
              [-88.943603515625, 29.66025390625],
              [-88.94111328125, 29.680224609374996],
              [-88.90117187499999, 29.732617187499997],
              [-88.87265625, 29.752978515624996],
              [-88.889306640625, 29.712597656249997],
            ],
          ],
          [
            [
              [-88.55810546875, 30.21591796875],
              [-88.57065429687499, 30.204785156249997],
              [-88.65922851562499, 30.2255859375],
              [-88.7130859375, 30.244921875],
              [-88.7228515625, 30.2642578125],
              [-88.573974609375, 30.229150390624994],
              [-88.55810546875, 30.21591796875],
            ],
          ],
          [
            [
              [-88.07133789062499, 30.25234375],
              [-88.159326171875, 30.230908203124997],
              [-88.28974609375, 30.23291015625],
              [-88.31625976562499, 30.240429687499997],
              [-88.263916015625, 30.254736328125],
              [-88.109375, 30.273730468749996],
              [-88.07133789062499, 30.25234375],
            ],
          ],
          [
            [
              [-89.22397460937499, 30.08408203125],
              [-89.220458984375, 30.03759765625],
              [-89.26943359375, 30.060742187499997],
              [-89.3419921875, 30.062841796875],
              [-89.31005859375, 30.0787109375],
              [-89.28764648437499, 30.094189453124997],
              [-89.27646484374999, 30.11083984375],
              [-89.18466796874999, 30.168652343749997],
              [-89.210693359375, 30.126220703125],
              [-89.22397460937499, 30.08408203125],
            ],
          ],
          [
            [
              [-88.82744140624999, 29.80771484375],
              [-88.8556640625, 29.77587890625],
              [-88.82797851562499, 29.928369140624994],
              [-88.86689453125, 30.05673828125],
              [-88.82587890625, 30.000390625],
              [-88.81259765624999, 29.933349609375],
              [-88.82744140624999, 29.80771484375],
            ],
          ],
          [
            [
              [-84.90791015625, 29.642626953124996],
              [-85.008251953125, 29.606640625],
              [-85.11674804687499, 29.6328125],
              [-85.04931640625, 29.63779296875],
              [-85.00053710937499, 29.627197265625],
              [-84.87700195312499, 29.678662109374997],
              [-84.81220703125, 29.717626953125],
              [-84.73715820312499, 29.732421875],
              [-84.90791015625, 29.642626953124996],
            ],
          ],
          [
            [
              [-122.85307617187499, 47.204736328124994],
              [-122.86259765624999, 47.18505859375],
              [-122.87675781249999, 47.1861328125],
              [-122.907958984375, 47.226123046874996],
              [-122.91191406249999, 47.254345703125],
              [-122.885107421875, 47.274707031249996],
              [-122.84916992187499, 47.21630859375],
              [-122.85307617187499, 47.204736328124994],
            ],
          ],
          [
            [
              [-122.394140625, 47.395263671875],
              [-122.39873046874999, 47.372509765625],
              [-122.437109375, 47.354785156249996],
              [-122.456982421875, 47.359326171875],
              [-122.458203125, 47.386132812499994],
              [-122.46855468749999, 47.390234375],
              [-122.509912109375, 47.3580078125],
              [-122.5068359375, 47.421679687499996],
              [-122.486474609375, 47.48876953125],
              [-122.468603515625, 47.489990234375],
              [-122.44208984375, 47.446142578125],
              [-122.394140625, 47.395263671875],
            ],
          ],
          [
            [
              [-122.497265625, 47.594580078125],
              [-122.50263671875, 47.575439453125],
              [-122.5578125, 47.598291015624994],
              [-122.575927734375, 47.619482421875],
              [-122.57373046875, 47.666845703125],
              [-122.56010742187499, 47.69775390625],
              [-122.549755859375, 47.703955078125],
              [-122.517236328125, 47.690576171874994],
              [-122.50786132812499, 47.682666015624996],
              [-122.497265625, 47.594580078125],
            ],
          ],
          [
            [
              [-122.8208984375, 48.43134765625],
              [-122.836572265625, 48.421533203124994],
              [-122.8900390625, 48.43466796875],
              [-122.921630859375, 48.456933593749994],
              [-122.932275390625, 48.484765625],
              [-122.91220703125, 48.537988281249994],
              [-122.885498046875, 48.551611328125],
              [-122.868896484375, 48.548632812499996],
              [-122.8619140625, 48.501855468749994],
              [-122.814599609375, 48.45234375],
              [-122.8208984375, 48.43134765625],
            ],
          ],
          [
            [
              [-68.623193359375, 44.196044921875],
              [-68.66118164062499, 44.17626953125],
              [-68.70170898437499, 44.182666015624996],
              [-68.70302734375, 44.231982421874996],
              [-68.69077148437499, 44.24873046875],
              [-68.6767578125, 44.256201171875],
              [-68.65595703125, 44.242333984374994],
              [-68.623193359375, 44.196044921875],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Cuba",
        sov_a3: "CU1",
        adm0_dif: 1,
        level: 1,
        type: "Sovereignty",
        tlc: "1",
        admin: "Cuba",
        adm0_a3: "CUB",
        geou_dif: 0,
        geounit: "Cuba",
        gu_a3: "CUB",
        su_dif: 0,
        subunit: "Cuba",
        su_a3: "CUB",
        brk_diff: 0,
        name: "Cuba",
        name_long: "Cuba",
        brk_a3: "CUB",
        brk_name: "Cuba",
        brk_group: null,
        abbrev: "Cuba",
        postal: "CU",
        formal_en: "Republic of Cuba",
        formal_fr: null,
        name_ciawf: "Cuba",
        note_adm0: null,
        note_brk: null,
        name_sort: "Cuba",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 5,
        mapcolor9: 3,
        mapcolor13: 4,
        pop_est: 11333483,
        pop_rank: 14,
        pop_year: 2019,
        gdp_md: 100023,
        gdp_year: 2018,
        economy: "5. Emerging region: G20",
        income_grp: "3. Upper middle income",
        fips_10: "CU",
        iso_a2: "CU",
        iso_a2_eh: "CU",
        iso_a3: "CUB",
        iso_a3_eh: "CUB",
        iso_n3: "192",
        iso_n3_eh: "192",
        un_a3: "192",
        wb_a2: "CU",
        wb_a3: "CUB",
        woe_id: 23424793,
        woe_id_eh: 23424793,
        woe_note: "Exact WOE match as country",
        adm0_iso: "CUB",
        adm0_diff: null,
        adm0_tlc: "CUB",
        adm0_a3_us: "CUB",
        adm0_a3_fr: "CUB",
        adm0_a3_ru: "CUB",
        adm0_a3_es: "CUB",
        adm0_a3_cn: "CUB",
        adm0_a3_tw: "CUB",
        adm0_a3_in: "CUB",
        adm0_a3_np: "CUB",
        adm0_a3_pk: "CUB",
        adm0_a3_de: "CUB",
        adm0_a3_gb: "CUB",
        adm0_a3_br: "CUB",
        adm0_a3_il: "CUB",
        adm0_a3_ps: "CUB",
        adm0_a3_sa: "CUB",
        adm0_a3_eg: "CUB",
        adm0_a3_ma: "CUB",
        adm0_a3_pt: "CUB",
        adm0_a3_ar: "CUB",
        adm0_a3_jp: "CUB",
        adm0_a3_ko: "CUB",
        adm0_a3_vn: "CUB",
        adm0_a3_tr: "CUB",
        adm0_a3_id: "CUB",
        adm0_a3_pl: "CUB",
        adm0_a3_gr: "CUB",
        adm0_a3_it: "CUB",
        adm0_a3_nl: "CUB",
        adm0_a3_se: "CUB",
        adm0_a3_bd: "CUB",
        adm0_a3_ua: "CUB",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "North America",
        region_un: "Americas",
        subregion: "Caribbean",
        region_wb: "Latin America & Caribbean",
        name_len: 4,
        long_len: 4,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 2.7,
        max_label: 8,
        label_x: -77.975855,
        label_y: 21.334024,
        ne_id: 1159320527,
        wikidataid: "Q241",
        name_ar: "كوبا",
        name_bn: "কিউবা",
        name_de: "Kuba",
        name_en: "Cuba",
        name_es: "Cuba",
        name_fa: "کوبا",
        name_fr: "Cuba",
        name_el: "Κούβα",
        name_he: "קובה",
        name_hi: "क्यूबा",
        name_hu: "Kuba",
        name_id: "Kuba",
        name_it: "Cuba",
        name_ja: "キューバ",
        name_ko: "쿠바",
        name_nl: "Cuba",
        name_pl: "Kuba",
        name_pt: "Cuba",
        name_ru: "Куба",
        name_sv: "Kuba",
        name_tr: "Küba",
        name_uk: "Куба",
        name_ur: "کیوبا",
        name_vi: "Cuba",
        name_zh: "古巴",
        name_zht: "古巴",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "CUB.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-81.83745117187499, 23.163037109374997],
              [-81.575439453125, 23.116503906250003],
              [-81.36362304687499, 23.1296875],
              [-81.262353515625, 23.15683593749999],
              [-81.271630859375, 23.128613281249997],
              [-81.17861328125, 23.05966796874999],
              [-81.14462890624999, 23.054931640625],
              [-81.00766601562499, 23.08984375],
              [-80.650146484375, 23.103076171875003],
              [-80.613427734375, 23.083740234375],
              [-80.55048828125, 23.0166015625],
              [-80.459228515625, 22.975],
              [-80.364892578125, 22.943408203125003],
              [-80.26616210937499, 22.934960937499994],
              [-80.167626953125, 22.949365234374994],
              [-80.07524414062499, 22.942333984374997],
              [-79.959912109375, 22.876904296874997],
              [-79.92353515625, 22.869140625],
              [-79.820263671875, 22.887011718750003],
              [-79.850732421875, 22.827197265625003],
              [-79.67666015625, 22.743066406249994],
              [-79.54921875, 22.577783203124994],
              [-79.456494140625, 22.50986328124999],
              [-79.35830078125, 22.448925781249997],
              [-79.27568359374999, 22.407617187499994],
              [-79.1830078125, 22.387890625],
              [-78.90190429687499, 22.39599609375],
              [-78.83544921875, 22.390917968750003],
              [-78.77597656249999, 22.367333984374994],
              [-78.71923828125, 22.358056640624994],
              [-78.686474609375, 22.366845703124994],
              [-78.14311523437499, 22.109423828125003],
              [-77.97050781249999, 21.971972656250003],
              [-77.86503906249999, 21.900585937499997],
              [-77.63681640624999, 21.79736328125],
              [-77.5451171875, 21.774609375],
              [-77.49711914062499, 21.788330078125],
              [-77.50654296875, 21.81103515625],
              [-77.57333984374999, 21.868310546874994],
              [-77.583154296875, 21.88925781249999],
              [-77.497265625, 21.871630859375003],
              [-77.34213867187499, 21.755273437499994],
              [-77.29951171875, 21.71225585937499],
              [-77.2220703125, 21.672412109375003],
              [-77.144140625, 21.643603515625003],
              [-77.18125, 21.59765625],
              [-77.24453125, 21.59375],
              [-77.366162109375, 21.61264648437499],
              [-77.26958007812499, 21.537890625],
              [-77.252880859375, 21.48349609374999],
              [-77.20791015625, 21.47885742187499],
              [-77.14096679687499, 21.538623046875003],
              [-77.0986328125, 21.589013671874994],
              [-76.92807617187499, 21.458984375],
              [-76.836328125, 21.39951171874999],
              [-76.859814453125, 21.364794921875003],
              [-76.867431640625, 21.33041992187499],
              [-76.76499023437499, 21.36240234374999],
              [-76.72607421875, 21.35888671875],
              [-76.68852539062499, 21.34042968749999],
              [-76.647412109375, 21.28452148437499],
              [-76.551708984375, 21.272119140624994],
              [-76.45517578124999, 21.273632812499997],
              [-76.259228515625, 21.22739257812499],
              [-76.0736328125, 21.133447265624994],
              [-75.89902343749999, 21.1142578125],
              [-75.72294921874999, 21.111035156249997],
              [-75.633740234375, 21.061328125],
              [-75.59580078124999, 20.994677734375003],
              [-75.63852539062499, 20.947460937499997],
              [-75.66293945312499, 20.898144531249997],
              [-75.597265625, 20.837646484375],
              [-75.740234375, 20.811962890624997],
              [-75.760400390625, 20.775537109374994],
              [-75.75297851562499, 20.73618164062499],
              [-75.724560546875, 20.714550781249997],
              [-75.6427734375, 20.73349609374999],
              [-75.524609375, 20.71665039062499],
              [-75.338134765625, 20.701611328124997],
              [-75.21328125, 20.7138671875],
              [-74.959716796875, 20.67265625],
              [-74.882568359375, 20.650634765625],
              [-74.732080078125, 20.573193359374997],
              [-74.66245117187499, 20.522119140624994],
              [-74.513134765625, 20.384570312500003],
              [-74.384375, 20.33046875],
              [-74.27280273437499, 20.3173828125],
              [-74.23388671875, 20.326416015625],
              [-74.198486328125, 20.311474609374997],
              [-74.16748046875, 20.2921875],
              [-74.13681640624999, 20.23193359375],
              [-74.1537109375, 20.16855468749999],
              [-74.217431640625, 20.117138671874997],
              [-74.25283203125, 20.0796875],
              [-74.41215820312499, 20.075341796874994],
              [-74.634765625, 20.058154296875003],
              [-74.850048828125, 20.00229492187499],
              [-74.95512695312499, 19.957910156249994],
              [-75.003173828125, 19.92856445312499],
              [-75.11640625, 19.901416015625003],
              [-75.12412109374999, 19.92465820312499],
              [-75.12197265625, 19.95390625],
              [-75.151611328125, 20.008349609375003],
              [-75.17729492187499, 19.959375],
              [-75.21943359375, 19.923632812500003],
              [-75.290478515625, 19.893115234375003],
              [-75.551953125, 19.89111328125],
              [-75.6572265625, 19.93222656249999],
              [-75.76513671875, 19.960400390624997],
              [-76.158447265625, 19.98974609375],
              [-76.25283203125, 19.98715820312499],
              [-76.515625, 19.956689453124994],
              [-76.779736328125, 19.940185546875],
              [-76.890234375, 19.921337890624997],
              [-76.999462890625, 19.892822265625],
              [-77.21196289062499, 19.89375],
              [-77.46318359374999, 19.861376953125003],
              [-77.715087890625, 19.85546875],
              [-77.55375976562499, 20.08212890624999],
              [-77.21337890625, 20.300390625],
              [-77.1494140625, 20.347265625],
              [-77.10380859374999, 20.407519531250003],
              [-77.093017578125, 20.452929687500003],
              [-77.10791015625, 20.491650390624997],
              [-77.18896484375, 20.559960937499994],
              [-77.20546875, 20.61083984375],
              [-77.22958984374999, 20.64375],
              [-77.34755859375, 20.67236328125],
              [-77.467041015625, 20.689501953125003],
              [-77.592724609375, 20.690087890624994],
              [-77.856884765625, 20.713623046875],
              [-77.997314453125, 20.715380859375003],
              [-78.116357421875, 20.761865234374994],
              [-78.3138671875, 20.927490234375],
              [-78.40634765624999, 20.973876953125],
              [-78.453857421875, 21.010986328125],
              [-78.49077148437499, 21.0537109375],
              [-78.537255859375, 21.296826171874997],
              [-78.5765625, 21.413818359375],
              [-78.636474609375, 21.515527343749994],
              [-78.727685546875, 21.592724609374997],
              [-78.82294921875, 21.618945312500003],
              [-79.189208984375, 21.55283203124999],
              [-79.2744140625, 21.562646484374994],
              [-79.357421875, 21.58515625],
              [-79.910302734375, 21.742578125],
              [-80.138330078125, 21.829248046874994],
              [-80.23134765625, 21.87216796874999],
              [-80.310693359375, 21.933398437500003],
              [-80.39291992187499, 22.033740234375003],
              [-80.48544921874999, 22.1234375],
              [-80.484814453125, 22.087158203125],
              [-80.49907226562499, 22.063525390625003],
              [-80.9619140625, 22.052880859374994],
              [-81.03564453125, 22.07358398437499],
              [-81.08310546874999, 22.097949218750003],
              [-81.116650390625, 22.134228515624997],
              [-81.14140624999999, 22.206933593749994],
              [-81.185498046875, 22.26796875],
              [-81.19956054687499, 22.202929687500003],
              [-81.222412109375, 22.14291992187499],
              [-81.284375, 22.109423828125003],
              [-81.35527343749999, 22.10410156249999],
              [-81.44111328125, 22.183789062499997],
              [-81.81621093749999, 22.2001953125],
              [-81.84941406249999, 22.213671875],
              [-81.972607421875, 22.29086914062499],
              [-82.077734375, 22.3876953125],
              [-81.973046875, 22.421826171874997],
              [-81.757080078125, 22.466748046874997],
              [-81.7103515625, 22.49667968749999],
              [-81.683251953125, 22.534814453124994],
              [-81.702734375, 22.59189453124999],
              [-81.74565429687499, 22.63291015624999],
              [-81.78989257812499, 22.65703125],
              [-81.838818359375, 22.67246093749999],
              [-81.90341796874999, 22.679003906250003],
              [-82.738037109375, 22.689257812500003],
              [-82.786376953125, 22.658349609374994],
              [-82.86123046875, 22.595117187499994],
              [-83.009423828125, 22.51401367187499],
              [-83.10712890625, 22.429882812499997],
              [-83.14375, 22.386474609375],
              [-83.189404296875, 22.355419921874997],
              [-83.292138671875, 22.30322265625],
              [-83.379638671875, 22.22299804687499],
              [-83.4859375, 22.187109375],
              [-83.54404296874999, 22.208935546874997],
              [-83.601513671875, 22.208740234375],
              [-83.64306640625, 22.18896484375],
              [-83.68662109374999, 22.179931640625],
              [-83.90073242187499, 22.170117187499997],
              [-83.93271484374999, 22.149658203125],
              [-83.963330078125, 22.092089843750003],
              [-83.998046875, 21.980126953124994],
              [-84.03095703125, 21.943115234375],
              [-84.138330078125, 21.929003906250003],
              [-84.240673828125, 21.898339843749994],
              [-84.448828125, 21.791650390624994],
              [-84.502587890625, 21.776171875],
              [-84.49091796875, 21.854296875],
              [-84.5013671875, 21.93027343749999],
              [-84.560009765625, 21.933007812499994],
              [-84.626904296875, 21.920361328124997],
              [-84.68266601562499, 21.899072265624994],
              [-84.78583984375, 21.84228515625],
              [-84.83823242187499, 21.827929687500003],
              [-84.88720703125, 21.856982421875003],
              [-84.87724609374999, 21.894140625],
              [-84.53276367187499, 22.031152343749994],
              [-84.49423828124999, 22.04160156249999],
              [-84.433056640625, 22.031298828125003],
              [-84.37314453124999, 22.0359375],
              [-84.3263671875, 22.07431640624999],
              [-84.3830078125, 22.255566406249997],
              [-84.361279296875, 22.37890625],
              [-84.28134765624999, 22.47421875],
              [-84.12177734375, 22.618554687499994],
              [-84.044921875, 22.666015625],
              [-83.2578125, 22.967578125],
              [-83.17724609375, 22.98300781249999],
              [-82.66582031249999, 23.04355468749999],
              [-82.58779296875, 23.06455078124999],
              [-82.350537109375, 23.153955078124994],
              [-82.10136718749999, 23.1904296875],
              [-81.83745117187499, 23.163037109374997],
            ],
          ],
          [
            [
              [-77.66899414062499, 21.951953125],
              [-77.71005859374999, 21.921337890624997],
              [-77.755029296875, 21.965576171875],
              [-77.783642578125, 21.970410156249997],
              [-77.823193359375, 21.987939453124994],
              [-77.9, 22.037158203125003],
              [-77.91855468749999, 22.088085937499997],
              [-77.854736328125, 22.091943359374994],
              [-77.7744140625, 22.082958984374997],
              [-77.63369140625, 22.054003906250003],
              [-77.64599609375, 21.996484375],
              [-77.66899414062499, 21.951953125],
            ],
          ],
          [
            [
              [-78.027099609375, 22.28515625],
              [-78.047509765625, 22.268505859374997],
              [-78.10166015624999, 22.30576171874999],
              [-78.18002929687499, 22.321972656249997],
              [-78.22612304687499, 22.37998046874999],
              [-78.27001953125, 22.402246093749994],
              [-78.27353515624999, 22.423583984375],
              [-78.20097656249999, 22.437646484374994],
              [-78.1505859375, 22.43149414062499],
              [-78.094140625, 22.38720703125],
              [-78.061669921875, 22.305908203125],
              [-78.027099609375, 22.28515625],
            ],
          ],
          [
            [
              [-78.630126953125, 22.55224609375],
              [-78.49287109375, 22.531054687500003],
              [-78.4453125, 22.54375],
              [-78.399560546875, 22.54746093749999],
              [-78.351220703125, 22.538623046875003],
              [-78.28388671875, 22.45546875],
              [-78.343017578125, 22.445117187500003],
              [-78.38994140624999, 22.445117187500003],
              [-78.424560546875, 22.460107421874994],
              [-78.54765624999999, 22.464013671874994],
              [-78.62900390624999, 22.488183593749994],
              [-78.67363281249999, 22.508837890625003],
              [-78.6955078125, 22.533984375],
              [-78.630126953125, 22.55224609375],
            ],
          ],
          [
            [
              [-77.87939453125, 22.12753906249999],
              [-77.912353515625, 22.124707031249997],
              [-78.0119140625, 22.16640625],
              [-78.041650390625, 22.20126953124999],
              [-78.00668945312499, 22.247998046874997],
              [-77.99921875, 22.298730468749994],
              [-77.98564453124999, 22.30209960937499],
              [-77.96958007812499, 22.240673828124997],
              [-77.89365234374999, 22.214550781249997],
              [-77.889111328125, 22.201074218749994],
              [-77.84248046875, 22.148974609375003],
              [-77.87939453125, 22.12753906249999],
            ],
          ],
          [
            [
              [-82.561767578125, 21.571679687499994],
              [-82.65483398437499, 21.51865234374999],
              [-82.853173828125, 21.443896484375003],
              [-82.959619140625, 21.441308593749994],
              [-83.06728515625, 21.469384765624994],
              [-83.14150390625, 21.531884765624994],
              [-83.1837890625, 21.593457031249997],
              [-83.18022460937499, 21.623046875],
              [-83.112939453125, 21.573681640624997],
              [-83.0548828125, 21.54941406249999],
              [-83.0072265625, 21.565576171874994],
              [-82.973583984375, 21.59228515625],
              [-83.08251953125, 21.79140625],
              [-83.077734375, 21.83349609375],
              [-82.9912109375, 21.94272460937499],
              [-82.75576171875, 21.90952148437499],
              [-82.71455078125, 21.890283203124994],
              [-82.6818359375, 21.82114257812499],
              [-82.62939453125, 21.766894531250003],
              [-82.567822265625, 21.621826171875],
              [-82.561767578125, 21.571679687499994],
            ],
          ],
          [
            [
              [-79.349560546875, 22.66391601562499],
              [-79.347900390625, 22.6376953125],
              [-79.52275390624999, 22.711132812499997],
              [-79.5978515625, 22.787646484375003],
              [-79.628173828125, 22.805224609375003],
              [-79.57915039062499, 22.80673828124999],
              [-79.38217773437499, 22.681347656249997],
              [-79.349560546875, 22.66391601562499],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 5,
        sovereignt: "Honduras",
        sov_a3: "HND",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Honduras",
        adm0_a3: "HND",
        geou_dif: 0,
        geounit: "Honduras",
        gu_a3: "HND",
        su_dif: 0,
        subunit: "Honduras",
        su_a3: "HND",
        brk_diff: 0,
        name: "Honduras",
        name_long: "Honduras",
        brk_a3: "HND",
        brk_name: "Honduras",
        brk_group: null,
        abbrev: "Hond.",
        postal: "HN",
        formal_en: "Republic of Honduras",
        formal_fr: null,
        name_ciawf: "Honduras",
        note_adm0: null,
        note_brk: null,
        name_sort: "Honduras",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 5,
        mapcolor9: 2,
        mapcolor13: 5,
        pop_est: 9746117,
        pop_rank: 13,
        pop_year: 2019,
        gdp_md: 25095,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        fips_10: "HO",
        iso_a2: "HN",
        iso_a2_eh: "HN",
        iso_a3: "HND",
        iso_a3_eh: "HND",
        iso_n3: "340",
        iso_n3_eh: "340",
        un_a3: "340",
        wb_a2: "HN",
        wb_a3: "HND",
        woe_id: 23424841,
        woe_id_eh: 23424841,
        woe_note: "Exact WOE match as country",
        adm0_iso: "HND",
        adm0_diff: null,
        adm0_tlc: "HND",
        adm0_a3_us: "HND",
        adm0_a3_fr: "HND",
        adm0_a3_ru: "HND",
        adm0_a3_es: "HND",
        adm0_a3_cn: "HND",
        adm0_a3_tw: "HND",
        adm0_a3_in: "HND",
        adm0_a3_np: "HND",
        adm0_a3_pk: "HND",
        adm0_a3_de: "HND",
        adm0_a3_gb: "HND",
        adm0_a3_br: "HND",
        adm0_a3_il: "HND",
        adm0_a3_ps: "HND",
        adm0_a3_sa: "HND",
        adm0_a3_eg: "HND",
        adm0_a3_ma: "HND",
        adm0_a3_pt: "HND",
        adm0_a3_ar: "HND",
        adm0_a3_jp: "HND",
        adm0_a3_ko: "HND",
        adm0_a3_vn: "HND",
        adm0_a3_tr: "HND",
        adm0_a3_id: "HND",
        adm0_a3_pl: "HND",
        adm0_a3_gr: "HND",
        adm0_a3_it: "HND",
        adm0_a3_nl: "HND",
        adm0_a3_se: "HND",
        adm0_a3_bd: "HND",
        adm0_a3_ua: "HND",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "North America",
        region_un: "Americas",
        subregion: "Central America",
        region_wb: "Latin America & Caribbean",
        name_len: 8,
        long_len: 8,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4.5,
        max_label: 9.5,
        label_x: -86.887604,
        label_y: 14.794801,
        ne_id: 1159320827,
        wikidataid: "Q783",
        name_ar: "هندوراس",
        name_bn: "হন্ডুরাস",
        name_de: "Honduras",
        name_en: "Honduras",
        name_es: "Honduras",
        name_fa: "هندوراس",
        name_fr: "Honduras",
        name_el: "Ονδούρα",
        name_he: "הונדורס",
        name_hi: "हौण्डुरस",
        name_hu: "Honduras",
        name_id: "Honduras",
        name_it: "Honduras",
        name_ja: "ホンジュラス",
        name_ko: "온두라스",
        name_nl: "Honduras",
        name_pl: "Honduras",
        name_pt: "Honduras",
        name_ru: "Гондурас",
        name_sv: "Honduras",
        name_tr: "Honduras",
        name_uk: "Гондурас",
        name_ur: "ہونڈوراس",
        name_vi: "Honduras",
        name_zh: "洪都拉斯",
        name_zht: "宏都拉斯",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "HND.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-83.15751953124999, 14.993066406249994],
              [-83.4150390625, 15.008056640625],
              [-83.5365234375, 14.977001953124997],
              [-83.58974609375, 14.907568359374991],
              [-83.635498046875, 14.876416015624997],
              [-83.67363281249999, 14.883544921875],
              [-83.750927734375, 14.85625],
              [-83.86728515624999, 14.794482421875003],
              [-83.972265625, 14.77109375],
              [-84.0658203125, 14.786083984374997],
              [-84.09296875, 14.770898437499994],
              [-84.10029296875, 14.750634765624994],
              [-84.114404296875, 14.731005859375003],
              [-84.15078125, 14.720410156249997],
              [-84.1923828125, 14.726025390624997],
              [-84.239208984375, 14.747851562500003],
              [-84.26396484374999, 14.738525390625],
              [-84.26665039062499, 14.698144531249994],
              [-84.291943359375, 14.687353515624991],
              [-84.339794921875, 14.706347656250003],
              [-84.39365234374999, 14.691748046874991],
              [-84.453564453125, 14.643701171874994],
              [-84.53764648437499, 14.633398437499991],
              [-84.645947265625, 14.661083984374997],
              [-84.72978515624999, 14.71337890625],
              [-84.78916015624999, 14.790380859374991],
              [-84.86044921874999, 14.809765625],
              [-84.98515624999999, 14.75244140625],
              [-85.037353515625, 14.685546875],
              [-85.04863281249999, 14.644726562499997],
              [-85.0365234375, 14.607666015625],
              [-85.059375, 14.582958984374997],
              [-85.11728515624999, 14.570605468750003],
              [-85.161328125, 14.525146484375],
              [-85.19150390624999, 14.446630859374991],
              [-85.19755859374999, 14.385986328125],
              [-85.17949218749999, 14.343310546875003],
              [-85.20834960937499, 14.311816406250003],
              [-85.2841796875, 14.291650390624994],
              [-85.373779296875, 14.223876953125],
              [-85.47705078125, 14.108691406250003],
              [-85.57978515625, 14.028222656249994],
              [-85.68193359374999, 13.982568359374994],
              [-85.731201171875, 13.931835937499997],
              [-85.727734375, 13.876074218749991],
              [-85.73393554687499, 13.858691406250003],
              [-85.75341796875, 13.85205078125],
              [-85.78671875, 13.844433593749997],
              [-85.9837890625, 13.965673828124991],
              [-86.04038085937499, 14.050146484374991],
              [-86.0892578125, 14.037207031249991],
              [-86.15122070312499, 13.994580078124997],
              [-86.238232421875, 13.899462890625003],
              [-86.33173828125, 13.770068359375003],
              [-86.376953125, 13.755664062500003],
              [-86.61025390625, 13.774853515624997],
              [-86.733642578125, 13.763476562500003],
              [-86.758984375, 13.746142578125003],
              [-86.77060546874999, 13.69873046875],
              [-86.76352539062499, 13.63525390625],
              [-86.72958984374999, 13.4072265625],
              [-86.710693359375, 13.313378906249994],
              [-86.72929687499999, 13.284375],
              [-86.792138671875, 13.27978515625],
              [-86.87353515625, 13.266503906249994],
              [-86.918212890625, 13.223583984374997],
              [-86.92880859374999, 13.179394531249997],
              [-86.93315429687499, 13.117529296874991],
              [-86.95888671875, 13.0537109375],
              [-87.00932617187499, 13.0078125],
              [-87.05917968749999, 12.991455078125],
              [-87.33725585937499, 12.979248046875],
              [-87.33251953125, 13.084716796875],
              [-87.41279296875, 13.12744140625],
              [-87.458447265625, 13.215429687499991],
              [-87.49838867187499, 13.27490234375],
              [-87.48515624999999, 13.310595703125003],
              [-87.48911132812499, 13.352929687499994],
              [-87.60224609375, 13.385595703124991],
              [-87.70834960937499, 13.360058593749997],
              [-87.76938476562499, 13.376660156249997],
              [-87.814208984375, 13.399169921875],
              [-87.73701171875, 13.451367187499997],
              [-87.731640625, 13.483105468749997],
              [-87.75644531249999, 13.506005859374994],
              [-87.781884765625, 13.521386718749994],
              [-87.77421874999999, 13.580322265625],
              [-87.758544921875, 13.649951171875003],
              [-87.71533203125, 13.812695312499997],
              [-87.7314453125, 13.841064453125],
              [-87.80224609375, 13.889990234374991],
              [-87.8919921875, 13.894970703124997],
              [-87.99101562499999, 13.879638671875],
              [-88.038720703125, 13.904638671874991],
              [-88.08046875, 13.960595703124994],
              [-88.151025390625, 13.987353515625003],
              [-88.27622070312499, 13.942675781250003],
              [-88.40849609374999, 13.875390625],
              [-88.44912109375, 13.850976562499994],
              [-88.482666015625, 13.854248046875],
              [-88.49765625, 13.904541015625],
              [-88.504345703125, 13.964208984374991],
              [-88.51254882812499, 13.978955078124997],
              [-88.583154296875, 14.000146484374994],
              [-88.665625, 14.015527343749994],
              [-88.70761718749999, 14.032080078124991],
              [-88.74736328124999, 14.072265625],
              [-88.845947265625, 14.124755859375],
              [-88.868310546875, 14.163671875],
              [-89.0001953125, 14.252734375],
              [-89.02685546875, 14.296972656249991],
              [-89.05712890625, 14.329150390625003],
              [-89.1205078125, 14.370214843749991],
              [-89.1701171875, 14.360302734374997],
              [-89.33725585937499, 14.411376953125],
              [-89.36259765624999, 14.416015625],
              [-89.33940429687499, 14.460742187500003],
              [-89.28671875, 14.529980468749997],
              [-89.17177734375, 14.606884765624997],
              [-89.16220703124999, 14.669238281250003],
              [-89.19223632812499, 14.788720703124994],
              [-89.22236328125, 14.866064453124991],
              [-89.20610351562499, 14.900585937499997],
              [-89.1703125, 15.039892578124991],
              [-89.142578125, 15.072314453125003],
              [-88.97641601562499, 15.142675781249991],
              [-88.96098632812499, 15.152441406249991],
              [-88.82993164062499, 15.251025390625003],
              [-88.68447265625, 15.360498046874994],
              [-88.53330078124999, 15.481201171875],
              [-88.36455078124999, 15.616015625],
              [-88.271435546875, 15.694873046875003],
              [-88.22832031249999, 15.72900390625],
              [-88.131103515625, 15.701025390624991],
              [-88.05458984375, 15.76484375],
              [-88.010400390625, 15.786181640625003],
              [-87.90703124999999, 15.862597656250003],
              [-87.874951171875, 15.879345703124997],
              [-87.70185546875, 15.91064453125],
              [-87.6181640625, 15.909863281249997],
              [-87.54497070312499, 15.832373046874991],
              [-87.48691406249999, 15.790185546874994],
              [-87.37749023437499, 15.826464843750003],
              [-87.285888671875, 15.834423828124997],
              [-86.9072265625, 15.762353515624994],
              [-86.75703125, 15.794238281250003],
              [-86.48081054687499, 15.801074218750003],
              [-86.356640625, 15.783203125],
              [-86.18120117187499, 15.88515625],
              [-86.0685546875, 15.905664062499994],
              [-85.936279296875, 15.953417968750003],
              [-85.95390624999999, 16.002246093750003],
              [-85.98564453124999, 16.024169921875],
              [-85.78398437499999, 16.002832031249994],
              [-85.48369140624999, 15.899511718749991],
              [-85.163671875, 15.918164062499997],
              [-85.0482421875, 15.973974609374991],
              [-84.97373046874999, 15.989892578124994],
              [-84.64609375, 15.88359375],
              [-84.55966796874999, 15.802001953125],
              [-84.49228515624999, 15.7939453125],
              [-84.44003906249999, 15.812597656249991],
              [-84.4259765625, 15.829492187499994],
              [-84.490380859375, 15.847265625],
              [-84.51962890624999, 15.872753906249997],
              [-84.26142578125, 15.822607421874991],
              [-83.77548828124999, 15.436865234374991],
              [-83.765283203125, 15.40546875],
              [-83.972802734375, 15.519628906249991],
              [-84.082763671875, 15.510888671874994],
              [-84.111328125, 15.492431640625],
              [-84.10517578125, 15.430126953124997],
              [-84.09506835937499, 15.400927734375003],
              [-84.04794921874999, 15.397607421874994],
              [-84.01318359375, 15.414404296874991],
              [-83.92744140625, 15.39404296875],
              [-83.87065429687499, 15.352734375],
              [-83.80166015625, 15.289257812499997],
              [-83.76044921875, 15.220361328124994],
              [-83.71591796874999, 15.21923828125],
              [-83.67216796874999, 15.2607421875],
              [-83.58964843749999, 15.265771484374994],
              [-83.53593749999999, 15.219384765624994],
              [-83.49794921875, 15.222119140624997],
              [-83.55107421874999, 15.293994140625003],
              [-83.67612304687499, 15.365429687499997],
              [-83.64638671875, 15.368408203125],
              [-83.36918945312499, 15.239990234375],
              [-83.29086914062499, 15.07890625],
              [-83.2255859375, 15.042285156250003],
              [-83.15751953124999, 14.993066406249994],
            ],
          ],
          [
            [
              [-86.419921875, 16.378369140624997],
              [-86.5802734375, 16.300244140624997],
              [-86.63041992187499, 16.3017578125],
              [-86.55693359374999, 16.362109375],
              [-86.43828124999999, 16.413867187500003],
              [-86.337841796875, 16.439208984375],
              [-86.255517578125, 16.42822265625],
              [-86.419921875, 16.378369140624997],
            ],
          ],
          [
            [
              [-85.87094726562499, 16.46152343749999],
              [-85.947216796875, 16.403613281250003],
              [-85.96098632812499, 16.4296875],
              [-85.92421875, 16.483300781249994],
              [-85.87822265624999, 16.513964843750003],
              [-85.83378906249999, 16.510888671874994],
              [-85.84443359375, 16.487744140624997],
              [-85.87094726562499, 16.46152343749999],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 6,
        sovereignt: "Belize",
        sov_a3: "BLZ",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Belize",
        adm0_a3: "BLZ",
        geou_dif: 0,
        geounit: "Belize",
        gu_a3: "BLZ",
        su_dif: 0,
        subunit: "Belize",
        su_a3: "BLZ",
        brk_diff: 0,
        name: "Belize",
        name_long: "Belize",
        brk_a3: "BLZ",
        brk_name: "Belize",
        brk_group: null,
        abbrev: "Belize",
        postal: "BZ",
        formal_en: "Belize",
        formal_fr: null,
        name_ciawf: "Belize",
        note_adm0: null,
        note_brk: null,
        name_sort: "Belize",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 4,
        mapcolor9: 5,
        mapcolor13: 7,
        pop_est: 390353,
        pop_rank: 10,
        pop_year: 2019,
        gdp_md: 1879,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        fips_10: "BH",
        iso_a2: "BZ",
        iso_a2_eh: "BZ",
        iso_a3: "BLZ",
        iso_a3_eh: "BLZ",
        iso_n3: "084",
        iso_n3_eh: "084",
        un_a3: "084",
        wb_a2: "BZ",
        wb_a3: "BLZ",
        woe_id: 23424760,
        woe_id_eh: 23424760,
        woe_note: "Exact WOE match as country",
        adm0_iso: "BLZ",
        adm0_diff: null,
        adm0_tlc: "BLZ",
        adm0_a3_us: "BLZ",
        adm0_a3_fr: "BLZ",
        adm0_a3_ru: "BLZ",
        adm0_a3_es: "BLZ",
        adm0_a3_cn: "BLZ",
        adm0_a3_tw: "BLZ",
        adm0_a3_in: "BLZ",
        adm0_a3_np: "BLZ",
        adm0_a3_pk: "BLZ",
        adm0_a3_de: "BLZ",
        adm0_a3_gb: "BLZ",
        adm0_a3_br: "BLZ",
        adm0_a3_il: "BLZ",
        adm0_a3_ps: "BLZ",
        adm0_a3_sa: "BLZ",
        adm0_a3_eg: "BLZ",
        adm0_a3_ma: "BLZ",
        adm0_a3_pt: "BLZ",
        adm0_a3_ar: "BLZ",
        adm0_a3_jp: "BLZ",
        adm0_a3_ko: "BLZ",
        adm0_a3_vn: "BLZ",
        adm0_a3_tr: "BLZ",
        adm0_a3_id: "BLZ",
        adm0_a3_pl: "BLZ",
        adm0_a3_gr: "BLZ",
        adm0_a3_it: "BLZ",
        adm0_a3_nl: "BLZ",
        adm0_a3_se: "BLZ",
        adm0_a3_bd: "BLZ",
        adm0_a3_ua: "BLZ",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "North America",
        region_un: "Americas",
        subregion: "Central America",
        region_wb: "Latin America & Caribbean",
        name_len: 6,
        long_len: 6,
        abbrev_len: 6,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 5,
        max_label: 10,
        label_x: -88.712962,
        label_y: 17.202068,
        ne_id: 1159320431,
        wikidataid: "Q242",
        name_ar: "بليز",
        name_bn: "বেলিজ",
        name_de: "Belize",
        name_en: "Belize",
        name_es: "Belice",
        name_fa: "بلیز",
        name_fr: "Belize",
        name_el: "Μπελίζ",
        name_he: "בליז",
        name_hi: "बेलीज़",
        name_hu: "Belize",
        name_id: "Belize",
        name_it: "Belize",
        name_ja: "ベリーズ",
        name_ko: "벨리즈",
        name_nl: "Belize",
        name_pl: "Belize",
        name_pt: "Belize",
        name_ru: "Белиз",
        name_sv: "Belize",
        name_tr: "Belize",
        name_uk: "Беліз",
        name_ur: "بیلیز",
        name_vi: "Belize",
        name_zh: "伯利兹",
        name_zht: "貝里斯",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "BLZ.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-89.16147460937499, 17.81484375],
              [-89.162353515625, 17.901953125],
              [-89.133544921875, 17.97080078124999],
              [-89.050439453125, 17.999707031249997],
              [-88.942626953125, 17.939648437499997],
              [-88.89780273437499, 17.91455078125],
              [-88.857373046875, 17.92880859374999],
              [-88.80634765625, 17.965527343749997],
              [-88.743603515625, 18.07163085937499],
              [-88.586181640625, 18.29052734375],
              [-88.52299804687499, 18.44589843749999],
              [-88.46127929687499, 18.476757812499997],
              [-88.37241210937499, 18.482324218749994],
              [-88.295654296875, 18.472412109375],
              [-88.349267578125, 18.358837890624997],
              [-88.295654296875, 18.34409179687499],
              [-88.247265625, 18.3546875],
              [-88.1302734375, 18.350732421874994],
              [-88.08525390624999, 18.226123046875003],
              [-88.09721679687499, 18.121630859375003],
              [-88.207470703125, 17.84609375],
              [-88.221435546875, 17.751367187499994],
              [-88.271728515625, 17.60986328125],
              [-88.20346679687499, 17.5166015625],
              [-88.2671875, 17.392578125],
              [-88.288818359375, 17.312695312499997],
              [-88.29399414062499, 17.192138671875],
              [-88.26181640624999, 16.963037109374994],
              [-88.313427734375, 16.632763671874997],
              [-88.404541015625, 16.48862304687499],
              [-88.4611328125, 16.433789062499997],
              [-88.56230468749999, 16.290429687499994],
              [-88.69516601562499, 16.24765625],
              [-88.8791015625, 16.016650390625003],
              [-88.91171875, 15.956005859374997],
              [-88.89404296875, 15.890625],
              [-88.937158203125, 15.88984375],
              [-89.11357421874999, 15.900683593750003],
              [-89.2328125, 15.888671875],
              [-89.2375, 15.894433593749994],
              [-89.22763671874999, 16.142822265625],
              [-89.21245117187499, 16.527148437500003],
              [-89.20126953124999, 16.808984375],
              [-89.190380859375, 17.084667968749997],
              [-89.18217773437499, 17.291210937499997],
              [-89.17109375, 17.572265625],
              [-89.16147460937499, 17.81484375],
            ],
          ],
          [
            [
              [-87.9505859375, 17.924951171874994],
              [-87.99809570312499, 17.90634765624999],
              [-87.95903320312499, 17.964013671874994],
              [-87.9533203125, 18.00107421874999],
              [-87.89833984375, 18.154931640624994],
              [-87.85893554687499, 18.154052734375],
              [-87.84853515625, 18.140380859375],
              [-87.9505859375, 17.924951171874994],
            ],
          ],
          [
            [
              [-87.8529296875, 17.4228515625],
              [-87.92998046874999, 17.283007812500003],
              [-87.93486328124999, 17.322949218749997],
              [-87.90283203125, 17.426464843749997],
              [-87.85942382812499, 17.462792968749994],
              [-87.83251953125, 17.50107421874999],
              [-87.826416015625, 17.546289062499994],
              [-87.78862304687499, 17.52421875],
              [-87.79814453124999, 17.47958984374999],
              [-87.8529296875, 17.4228515625],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 2,
        sovereignt: "Mexico",
        sov_a3: "MEX",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Mexico",
        adm0_a3: "MEX",
        geou_dif: 0,
        geounit: "Mexico",
        gu_a3: "MEX",
        su_dif: 0,
        subunit: "Mexico",
        su_a3: "MEX",
        brk_diff: 0,
        name: "Mexico",
        name_long: "Mexico",
        brk_a3: "MEX",
        brk_name: "Mexico",
        brk_group: null,
        abbrev: "Mex.",
        postal: "MX",
        formal_en: "United Mexican States",
        formal_fr: null,
        name_ciawf: "Mexico",
        note_adm0: null,
        note_brk: null,
        name_sort: "Mexico",
        name_alt: null,
        mapcolor7: 6,
        mapcolor8: 1,
        mapcolor9: 7,
        mapcolor13: 3,
        pop_est: 127575529,
        pop_rank: 17,
        pop_year: 2019,
        gdp_md: 1268870,
        gdp_year: 2019,
        economy: "4. Emerging region: MIKT",
        income_grp: "3. Upper middle income",
        fips_10: "MX",
        iso_a2: "MX",
        iso_a2_eh: "MX",
        iso_a3: "MEX",
        iso_a3_eh: "MEX",
        iso_n3: "484",
        iso_n3_eh: "484",
        un_a3: "484",
        wb_a2: "MX",
        wb_a3: "MEX",
        woe_id: 23424900,
        woe_id_eh: 23424900,
        woe_note: "Exact WOE match as country",
        adm0_iso: "MEX",
        adm0_diff: null,
        adm0_tlc: "MEX",
        adm0_a3_us: "MEX",
        adm0_a3_fr: "MEX",
        adm0_a3_ru: "MEX",
        adm0_a3_es: "MEX",
        adm0_a3_cn: "MEX",
        adm0_a3_tw: "MEX",
        adm0_a3_in: "MEX",
        adm0_a3_np: "MEX",
        adm0_a3_pk: "MEX",
        adm0_a3_de: "MEX",
        adm0_a3_gb: "MEX",
        adm0_a3_br: "MEX",
        adm0_a3_il: "MEX",
        adm0_a3_ps: "MEX",
        adm0_a3_sa: "MEX",
        adm0_a3_eg: "MEX",
        adm0_a3_ma: "MEX",
        adm0_a3_pt: "MEX",
        adm0_a3_ar: "MEX",
        adm0_a3_jp: "MEX",
        adm0_a3_ko: "MEX",
        adm0_a3_vn: "MEX",
        adm0_a3_tr: "MEX",
        adm0_a3_id: "MEX",
        adm0_a3_pl: "MEX",
        adm0_a3_gr: "MEX",
        adm0_a3_it: "MEX",
        adm0_a3_nl: "MEX",
        adm0_a3_se: "MEX",
        adm0_a3_bd: "MEX",
        adm0_a3_ua: "MEX",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "North America",
        region_un: "Americas",
        subregion: "Central America",
        region_wb: "Latin America & Caribbean",
        name_len: 6,
        long_len: 6,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 2,
        max_label: 6.7,
        label_x: -102.289448,
        label_y: 23.919988,
        ne_id: 1159321055,
        wikidataid: "Q96",
        name_ar: "المكسيك",
        name_bn: "মেক্সিকো",
        name_de: "Mexiko",
        name_en: "Mexico",
        name_es: "México",
        name_fa: "مکزیک",
        name_fr: "Mexique",
        name_el: "Μεξικό",
        name_he: "מקסיקו",
        name_hi: "मेक्सिको",
        name_hu: "Mexikó",
        name_id: "Meksiko",
        name_it: "Messico",
        name_ja: "メキシコ",
        name_ko: "멕시코",
        name_nl: "Mexico",
        name_pl: "Meksyk",
        name_pt: "México",
        name_ru: "Мексика",
        name_sv: "Mexiko",
        name_tr: "Meksika",
        name_uk: "Мексика",
        name_ur: "میکسیکو",
        name_vi: "México",
        name_zh: "墨西哥",
        name_zht: "墨西哥",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "MEX.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-117.128271484375, 32.533349609374994],
              [-116.84208984374999, 32.55478515625],
              [-116.55595703124999, 32.576220703124996],
              [-116.26982421874999, 32.597607421875],
              [-115.98369140625, 32.619042968749994],
              [-115.697509765625, 32.640478515625],
              [-115.411376953125, 32.661865234375],
              [-115.1251953125, 32.68330078125],
              [-114.8390625, 32.704736328124994],
              [-114.724755859375, 32.71533203125],
              [-114.78798828125, 32.564794921875],
              [-114.8359375, 32.50830078125],
              [-114.36171875, 32.360302734375],
              [-113.887451171875, 32.212304687499994],
              [-113.41318359374999, 32.064306640625],
              [-112.93896484375, 31.916259765625],
              [-112.46474609375, 31.768261718749997],
              [-111.990478515625, 31.62021484375],
              [-111.51621093749999, 31.472265625],
              [-111.0419921875, 31.32421875],
              [-110.688525390625, 31.324853515624994],
              [-110.335107421875, 31.325537109375],
              [-109.981640625, 31.326171875],
              [-109.62822265625, 31.326806640624994],
              [-109.27475585937499, 31.327441406249996],
              [-108.921337890625, 31.328125],
              [-108.56787109375, 31.328808593749997],
              [-108.214453125, 31.329443359375],
              [-108.213818359375, 31.44189453125],
              [-108.21318359375, 31.554394531249997],
              [-108.2125, 31.666845703125],
              [-108.21181640625, 31.779345703124996],
              [-107.99204101562499, 31.778173828125],
              [-107.772216796875, 31.777050781249997],
              [-107.55234375, 31.77587890625],
              [-107.33251953125, 31.774755859375],
              [-107.1126953125, 31.773632812499997],
              [-106.89287109375, 31.7724609375],
              [-106.673046875, 31.771337890625],
              [-106.45322265624999, 31.770166015624994],
              [-106.44541015624999, 31.768408203125],
              [-106.43603515625, 31.764453125],
              [-106.34697265625, 31.679003906249996],
              [-106.25571289062499, 31.544677734375],
              [-106.148046875, 31.450927734375],
              [-106.024072265625, 31.397753906249996],
              [-105.8126953125, 31.241015625],
              [-105.51401367187499, 30.980761718749996],
              [-105.275830078125, 30.807275390624994],
              [-105.09814453125, 30.720556640625],
              [-104.97880859375, 30.645947265624997],
              [-104.91787109375, 30.583349609375],
              [-104.835888671875, 30.44765625],
              [-104.68134765625, 30.134375],
              [-104.68134765625, 29.990527343749996],
              [-104.622216796875, 29.854296875],
              [-104.50400390624999, 29.677685546874997],
              [-104.400634765625, 29.57373046875],
              [-104.31220703125, 29.542431640624997],
              [-104.21552734375, 29.479882812499994],
              [-104.110595703125, 29.386132812499994],
              [-103.98974609375, 29.323144531249994],
              [-103.8529296875, 29.291064453124996],
              [-103.66396484375, 29.206884765625],
              [-103.42294921874999, 29.070703125],
              [-103.25771484375, 29.001123046874994],
              [-103.16831054687499, 28.998193359374994],
              [-103.089990234375, 29.041894531249994],
              [-103.0228515625, 29.132226562499994],
              [-102.95683593749999, 29.190380859374997],
              [-102.8919921875, 29.21640625],
              [-102.865673828125, 29.258007812499997],
              [-102.87783203125, 29.315332031249994],
              [-102.833984375, 29.4439453125],
              [-102.73417968749999, 29.643945312499994],
              [-102.61494140625, 29.75234375],
              [-102.47626953125, 29.769091796874996],
              [-102.38564453125, 29.806640625],
              [-102.34306640624999, 29.864990234375],
              [-102.2689453125, 29.87119140625],
              [-102.1630859375, 29.825244140624996],
              [-101.99091796875, 29.795703125],
              [-101.75234375, 29.782470703125],
              [-101.61162109374999, 29.786962890625],
              [-101.56870117187499, 29.809228515624994],
              [-101.54638671875, 29.808056640624997],
              [-101.54462890625, 29.783544921875],
              [-101.50927734375, 29.773144531249997],
              [-101.440380859375, 29.77685546875],
              [-101.38037109375, 29.742578125],
              [-101.30351562499999, 29.634082031249996],
              [-101.03896484375, 29.460400390624997],
              [-101.03862304687499, 29.460302734375],
              [-101.01630859375, 29.400683593749996],
              [-100.92412109374999, 29.314697265625],
              [-100.75458984375, 29.182519531249994],
              [-100.658642578125, 29.068554687499997],
              [-100.636328125, 28.972802734374994],
              [-100.54970703125, 28.821337890624996],
              [-100.39892578125, 28.614208984374997],
              [-100.33173828125, 28.5025390625],
              [-100.34814453125, 28.48642578125],
              [-100.33627929687499, 28.428125],
              [-100.296044921875, 28.327685546874996],
              [-100.22128906249999, 28.242626953124997],
              [-100.111962890625, 28.17294921875],
              [-100.001416015625, 28.0478515625],
              [-99.8896484375, 27.86728515625],
              [-99.75424804687499, 27.729931640624997],
              [-99.5953125, 27.635888671874994],
              [-99.505322265625, 27.54833984375],
              [-99.48427734375, 27.4673828125],
              [-99.48583984375, 27.398046875],
              [-99.51005859374999, 27.34033203125],
              [-99.49980468749999, 27.285498046875],
              [-99.45512695312499, 27.233691406249996],
              [-99.44023437499999, 27.170117187499997],
              [-99.45771484375, 27.081689453124994],
              [-99.45654296875, 27.056689453124996],
              [-99.456494140625, 27.056640625],
              [-99.4435546875, 27.036669921874996],
              [-99.30244140625, 26.884716796874997],
              [-99.229931640625, 26.761914062499997],
              [-99.17236328125, 26.56591796875],
              [-99.1720703125, 26.564160156249997],
              [-99.10776367187499, 26.446923828124994],
              [-99.015283203125, 26.398974609374996],
              [-98.873193359375, 26.38125],
              [-98.765234375, 26.3404296875],
              [-98.69140625, 26.27646484375],
              [-98.598291015625, 26.237841796874996],
              [-98.48588867187499, 26.224560546874997],
              [-98.378125, 26.182373046875],
              [-98.275048828125, 26.111181640625],
              [-98.08281249999999, 26.064453125],
              [-97.801416015625, 26.042041015624996],
              [-97.58725585937499, 25.984179687500003],
              [-97.44028320312499, 25.890820312499997],
              [-97.375634765625, 25.871826171875],
              [-97.358154296875, 25.870507812499994],
              [-97.349755859375, 25.884765625],
              [-97.33867187499999, 25.911181640625003],
              [-97.28178710937499, 25.941601562499997],
              [-97.146240234375, 25.961474609375003],
              [-97.164453125, 25.754931640625003],
              [-97.22490234374999, 25.58544921875],
              [-97.424072265625, 25.233105468749997],
              [-97.507080078125, 25.014550781249994],
              [-97.66767578125, 24.38999023437499],
              [-97.717041015625, 23.980615234374994],
              [-97.72861328124999, 23.78793945312499],
              [-97.74267578125, 23.760644531249994],
              [-97.72739257812499, 23.732226562500003],
              [-97.765869140625, 23.30615234375],
              [-97.74521484374999, 22.9423828125],
              [-97.75834960937499, 22.886035156250003],
              [-97.81669921874999, 22.776318359374997],
              [-97.8578125, 22.62451171875],
              [-97.84160156249999, 22.557080078124997],
              [-97.84248046875, 22.510302734375003],
              [-97.782373046875, 22.279296875],
              [-97.76328125, 22.105859375],
              [-97.58476562499999, 21.808544921874997],
              [-97.484521484375, 21.704833984375],
              [-97.36015624999999, 21.614941406249997],
              [-97.31450195312499, 21.564208984375],
              [-97.336865234375, 21.437890625],
              [-97.38754882812499, 21.373925781249994],
              [-97.4091796875, 21.27255859374999],
              [-97.43413085937499, 21.356494140625003],
              [-97.42441406249999, 21.46533203125],
              [-97.38481445312499, 21.523828125],
              [-97.383447265625, 21.566699218750003],
              [-97.45659179687499, 21.61240234374999],
              [-97.59038085937499, 21.762011718750003],
              [-97.75380859375, 22.026660156250003],
              [-97.63754882812499, 21.603662109374994],
              [-97.597607421875, 21.535888671875],
              [-97.566552734375, 21.507714843749994],
              [-97.51455078125, 21.477978515624997],
              [-97.50107421874999, 21.43203125],
              [-97.50058593749999, 21.398046875],
              [-97.35712890625, 21.10400390625],
              [-97.194970703125, 20.800097656250003],
              [-97.18632812499999, 20.717041015625],
              [-97.12143554687499, 20.614990234375],
              [-96.70869140625, 20.18828125],
              [-96.4560546875, 19.869775390624994],
              [-96.368359375, 19.56723632812499],
              [-96.31533203125, 19.472851562499997],
              [-96.28955078125, 19.34375],
              [-96.123974609375, 19.19907226562499],
              [-96.073388671875, 19.105664062499997],
              [-95.98466796874999, 19.053759765625003],
              [-95.913037109375, 18.897167968749997],
              [-95.778125, 18.80551757812499],
              [-95.81035156249999, 18.803857421874994],
              [-95.92822265625, 18.85009765625],
              [-95.920361328125, 18.819580078125],
              [-95.82109374999999, 18.754638671875],
              [-95.62680664062499, 18.690576171874994],
              [-95.5783203125, 18.6904296875],
              [-95.654931640625, 18.723681640625003],
              [-95.71982421874999, 18.768359375],
              [-95.69711914062499, 18.77490234375],
              [-95.56142578125, 18.719140625],
              [-95.1818359375, 18.700732421875003],
              [-95.01469726562499, 18.570605468750003],
              [-94.79814453124999, 18.514599609374997],
              [-94.681640625, 18.34848632812499],
              [-94.54619140624999, 18.174853515625003],
              [-94.45976562499999, 18.166650390624994],
              [-94.39228515625, 18.165966796874997],
              [-94.18901367187499, 18.195263671874997],
              [-93.87314453124999, 18.304443359375],
              [-93.764404296875, 18.35791015625],
              [-93.55234375, 18.43046875],
              [-93.2279296875, 18.443798828124997],
              [-93.12734375, 18.4234375],
              [-92.884765625, 18.468652343749994],
              [-92.76909179687499, 18.524121093749997],
              [-92.728955078125, 18.574511718750003],
              [-92.710107421875, 18.61166992187499],
              [-92.485302734375, 18.664794921875],
              [-92.441015625, 18.67529296875],
              [-92.21318359374999, 18.684863281250003],
              [-92.10322265625, 18.704394531250003],
              [-91.973779296875, 18.715869140625003],
              [-91.88037109375, 18.63779296874999],
              [-91.88046875, 18.599658203125003],
              [-91.94267578124999, 18.563427734374997],
              [-91.91357421875, 18.528515625],
              [-91.802978515625, 18.470605468749994],
              [-91.59970703124999, 18.447167968749994],
              [-91.53398437499999, 18.45654296875],
              [-91.44047851562499, 18.54184570312499],
              [-91.27524414062499, 18.624462890624997],
              [-91.278759765625, 18.720654296874997],
              [-91.30830078125, 18.77329101562499],
              [-91.35629882812499, 18.7765625],
              [-91.36777343749999, 18.806103515624997],
              [-91.334228515625, 18.87680664062499],
              [-91.34306640624999, 18.900585937499997],
              [-91.445556640625, 18.8328125],
              [-91.469189453125, 18.8330078125],
              [-91.45786132812499, 18.864648437499994],
              [-91.436669921875, 18.889794921874994],
              [-91.1359375, 19.0375],
              [-91.05893554687499, 19.098193359375003],
              [-90.955029296875, 19.151660156250003],
              [-90.7392578125, 19.352246093749997],
              [-90.69316406249999, 19.729882812499994],
              [-90.65009765625, 19.795947265625003],
              [-90.507080078125, 19.911865234375],
              [-90.49169921875, 19.94677734375],
              [-90.482421875, 20.02573242187499],
              [-90.48637695312499, 20.224023437499994],
              [-90.47832031249999, 20.37998046874999],
              [-90.484130859375, 20.556347656249997],
              [-90.458447265625, 20.71372070312499],
              [-90.43515625, 20.757519531249997],
              [-90.353125, 21.009423828124994],
              [-90.18291015624999, 21.120898437500003],
              [-89.887646484375, 21.252636718749997],
              [-89.819775390625, 21.274609375],
              [-88.87871093749999, 21.414111328125003],
              [-88.74667968749999, 21.448144531249994],
              [-88.584912109375, 21.538671875],
              [-88.46669921875, 21.569384765625003],
              [-88.25102539062499, 21.56689453125],
              [-88.184765625, 21.57895507812499],
              [-88.17172851562499, 21.591455078124994],
              [-88.17138671875, 21.603515625],
              [-88.13164062499999, 21.615869140624994],
              [-88.0068359375, 21.604052734375003],
              [-87.77373046874999, 21.549511718749997],
              [-87.68881835937499, 21.535839843749997],
              [-87.48046875, 21.472460937500003],
              [-87.25087890625, 21.446972656249997],
              [-87.217919921875, 21.4580078125],
              [-87.18759765624999, 21.477294921875],
              [-87.164306640625, 21.514208984375003],
              [-87.18828124999999, 21.546435546875003],
              [-87.210595703125, 21.5439453125],
              [-87.249462890625, 21.526611328125],
              [-87.29575195312499, 21.524951171875003],
              [-87.386669921875, 21.551464843749997],
              [-87.36850585937499, 21.57373046875],
              [-87.27573242187499, 21.57163085937499],
              [-87.216455078125, 21.582421875],
              [-87.12846679687499, 21.621484375],
              [-87.034765625, 21.592236328124997],
              [-86.91171875, 21.462841796874997],
              [-86.82407226562499, 21.421679687500003],
              [-86.81708984375, 21.23422851562499],
              [-86.803857421875, 21.20004882812499],
              [-86.77177734374999, 21.150537109374994],
              [-86.81552734374999, 21.00522460937499],
              [-86.864697265625, 20.885058593750003],
              [-86.926220703125, 20.78647460937499],
              [-87.0595703125, 20.63125],
              [-87.22124023437499, 20.507275390624997],
              [-87.42138671875, 20.231396484374997],
              [-87.4671875, 20.10214843749999],
              [-87.4658203125, 19.99853515625],
              [-87.43193359374999, 19.898486328125003],
              [-87.44174804687499, 19.861523437499997],
              [-87.4662109375, 19.824169921874997],
              [-87.50688476562499, 19.82749023437499],
              [-87.58579101562499, 19.779492187499997],
              [-87.6876953125, 19.637109375],
              [-87.690087890625, 19.593701171874997],
              [-87.64531249999999, 19.55390625],
              [-87.5873046875, 19.572998046875],
              [-87.511669921875, 19.57470703125],
              [-87.469384765625, 19.586474609375003],
              [-87.424755859375, 19.58334960937499],
              [-87.434716796875, 19.501708984375],
              [-87.482666015625, 19.44375],
              [-87.512890625, 19.425585937500003],
              [-87.56699218749999, 19.415722656249997],
              [-87.62753906249999, 19.382714843749994],
              [-87.65869140625, 19.35234375],
              [-87.65576171875, 19.257861328125003],
              [-87.6220703125, 19.25048828125],
              [-87.55078125, 19.320947265624994],
              [-87.50947265625, 19.31748046874999],
              [-87.50107421874999, 19.287792968749997],
              [-87.59355468749999, 19.04638671875],
              [-87.65302734375, 18.798535156249997],
              [-87.733544921875, 18.655029296875],
              [-87.76181640624999, 18.44614257812499],
              [-87.8041015625, 18.357080078124994],
              [-87.85322265625, 18.268994140624997],
              [-87.881982421875, 18.273876953124997],
              [-87.95966796875, 18.440869140624997],
              [-88.0390625, 18.48388671875],
              [-88.05644531249999, 18.524462890625003],
              [-88.0111328125, 18.726855468750003],
              [-88.03173828125, 18.838916015625003],
              [-88.07377929687499, 18.83447265625],
              [-88.12675781249999, 18.773046875],
              [-88.19677734375, 18.719677734374997],
              [-88.1953125, 18.642626953125003],
              [-88.27573242187499, 18.514550781249994],
              [-88.295654296875, 18.472412109375],
              [-88.37241210937499, 18.482324218749994],
              [-88.46127929687499, 18.476757812499997],
              [-88.52299804687499, 18.44589843749999],
              [-88.586181640625, 18.29052734375],
              [-88.743603515625, 18.07163085937499],
              [-88.80634765625, 17.965527343749997],
              [-88.857373046875, 17.92880859374999],
              [-88.89780273437499, 17.91455078125],
              [-88.942626953125, 17.939648437499997],
              [-89.050439453125, 17.999707031249997],
              [-89.133544921875, 17.97080078124999],
              [-89.162353515625, 17.901953125],
              [-89.16147460937499, 17.81484375],
              [-89.371533203125, 17.814990234375003],
              [-89.72880859374999, 17.815332031249994],
              [-90.18359375, 17.815722656250003],
              [-90.622021484375, 17.816113281249997],
              [-90.98916015625, 17.81640625],
              [-90.9904296875, 17.620751953124994],
              [-90.9916015625, 17.447460937499997],
              [-90.99296874999999, 17.25244140625],
              [-91.1955078125, 17.254101562499997],
              [-91.409619140625, 17.255859375],
              [-91.392333984375, 17.23642578124999],
              [-91.31918945312499, 17.19980468749999],
              [-91.22416992187499, 17.112255859374997],
              [-91.11186523437499, 16.976171875],
              [-90.975830078125, 16.867822265624994],
              [-90.816015625, 16.787109375],
              [-90.710693359375, 16.70810546874999],
              [-90.65996093749999, 16.630908203125003],
              [-90.634375, 16.565136718749997],
              [-90.63408203124999, 16.5107421875],
              [-90.57578125, 16.467822265625003],
              [-90.47109375, 16.43955078124999],
              [-90.4169921875, 16.391015625],
              [-90.4169921875, 16.351318359375],
              [-90.450146484375, 16.261376953124994],
              [-90.45986328125, 16.162353515625],
              [-90.44716796875, 16.072705078124997],
              [-90.52197265625, 16.071191406249994],
              [-90.70322265624999, 16.071044921875],
              [-90.97958984374999, 16.07080078125],
              [-91.2337890625, 16.07065429687499],
              [-91.433984375, 16.070458984374994],
              [-91.736572265625, 16.07016601562499],
              [-91.81943359374999, 15.932373046875],
              [-91.9572265625, 15.703222656249991],
              [-92.08212890624999, 15.495556640624997],
              [-92.187158203125, 15.320898437499991],
              [-92.204248046875, 15.275],
              [-92.204345703125, 15.237695312499994],
              [-92.07480468749999, 15.07421875],
              [-92.09873046874999, 15.026757812499994],
              [-92.14423828125, 15.001953125],
              [-92.15854492187499, 14.963574218749997],
              [-92.1556640625, 14.901318359374997],
              [-92.18637695312499, 14.818359375],
              [-92.17646484375, 14.761328125],
              [-92.159912109375, 14.691015625],
              [-92.18706054687499, 14.630078125],
              [-92.20903320312499, 14.570996093749997],
              [-92.23515624999999, 14.54541015625],
              [-92.26455078125, 14.567773437499994],
              [-92.53095703125, 14.839648437500003],
              [-92.80893554687499, 15.138574218749994],
              [-92.918408203125, 15.236132812500003],
              [-93.0244140625, 15.310253906249997],
              [-93.16689453125, 15.448046875],
              [-93.541162109375, 15.750390625],
              [-93.734375, 15.888476562500003],
              [-93.91606445312499, 16.05356445312499],
              [-94.07895507812499, 16.145263671875],
              [-94.239892578125, 16.205078125],
              [-94.311279296875, 16.23935546874999],
              [-94.374169921875, 16.284765625],
              [-94.40903320312499, 16.287353515625],
              [-94.426416015625, 16.226269531249997],
              [-94.37016601562499, 16.19541015624999],
              [-94.30283203124999, 16.169335937499994],
              [-94.24951171875, 16.167529296875003],
              [-94.19340820312499, 16.14560546874999],
              [-94.0283203125, 16.062060546875003],
              [-94.00126953124999, 16.018945312499994],
              [-94.47075195312499, 16.186572265625003],
              [-94.6615234375, 16.201904296875],
              [-94.682275390625, 16.228222656249997],
              [-94.587109375, 16.315820312499994],
              [-94.616845703125, 16.347558593749994],
              [-94.65078125, 16.351806640625],
              [-94.75283203125, 16.291210937499997],
              [-94.79082031249999, 16.287158203125003],
              [-94.79746093749999, 16.327050781249994],
              [-94.792919921875, 16.36459960937499],
              [-94.85869140624999, 16.419726562500003],
              [-94.90043945312499, 16.41748046875],
              [-94.934716796875, 16.379101562499997],
              [-95.02353515624999, 16.30625],
              [-95.02084960937499, 16.277636718750003],
              [-94.84604492187499, 16.24658203125],
              [-94.785791015625, 16.22910156249999],
              [-94.79941406249999, 16.209667968749997],
              [-94.94931640624999, 16.210009765625003],
              [-95.134375, 16.176953125],
              [-95.46440429687499, 15.974707031249991],
              [-95.77177734374999, 15.887792968749991],
              [-96.21357421875, 15.693066406249997],
              [-96.408642578125, 15.68310546875],
              [-96.51083984374999, 15.651904296875003],
              [-96.80795898437499, 15.726416015624991],
              [-97.18466796874999, 15.909277343749991],
              [-97.75478515625, 15.966845703125003],
              [-98.13896484374999, 16.206298828125],
              [-98.52031249999999, 16.304833984374994],
              [-98.76220703125, 16.534765625],
              [-98.907958984375, 16.544580078124994],
              [-99.00166015625, 16.581445312499994],
              [-99.348046875, 16.664746093749997],
              [-99.690673828125, 16.719628906249994],
              [-100.02451171874999, 16.92050781249999],
              [-100.24301757812499, 16.984179687500003],
              [-100.431884765625, 17.0640625],
              [-100.847802734375, 17.200488281250003],
              [-101.001953125, 17.276123046875],
              [-101.1478515625, 17.393115234375003],
              [-101.385498046875, 17.514208984375003],
              [-101.487060546875, 17.61533203124999],
              [-101.60029296875, 17.6515625],
              [-101.76240234375, 17.841992187499997],
              [-101.8470703125, 17.922265625],
              [-101.918701171875, 17.959765625],
              [-101.9955078125, 17.972705078125003],
              [-102.21660156249999, 17.957421875],
              [-102.54697265624999, 18.04140625],
              [-102.69956054687499, 18.06284179687499],
              [-103.018505859375, 18.18686523437499],
              [-103.4416015625, 18.325390625],
              [-103.5802734375, 18.484375],
              [-103.69892578125, 18.632958984374994],
              [-103.91245117187499, 18.82846679687499],
              [-104.045654296875, 18.911816406249997],
              [-104.277001953125, 19.010986328125],
              [-104.40517578125, 19.091210937499994],
              [-104.602978515625, 19.152880859375003],
              [-104.9384765625, 19.309375],
              [-105.04521484375, 19.443261718749994],
              [-105.107666015625, 19.562207031249997],
              [-105.286376953125, 19.706494140624997],
              [-105.482080078125, 19.97607421875],
              [-105.532421875, 20.075390625],
              [-105.57041015624999, 20.227832031250003],
              [-105.61591796875, 20.316308593749994],
              [-105.66943359375, 20.38559570312499],
              [-105.642138671875, 20.435986328124997],
              [-105.542578125, 20.497949218749994],
              [-105.37705078124999, 20.511865234374994],
              [-105.26015625, 20.579052734374997],
              [-105.244677734375, 20.634179687499994],
              [-105.25229492187499, 20.668505859375003],
              [-105.32705078125, 20.752978515625003],
              [-105.4201171875, 20.775390625],
              [-105.4923828125, 20.776611328125],
              [-105.51083984374999, 20.808740234374994],
              [-105.45634765625, 20.843798828125003],
              [-105.393994140625, 20.92612304687499],
              [-105.301953125, 21.0265625],
              [-105.237060546875, 21.119189453125003],
              [-105.225, 21.249707031249997],
              [-105.23325195312499, 21.380419921875003],
              [-105.20869140625, 21.49082031249999],
              [-105.4314453125, 21.61826171874999],
              [-105.457421875, 21.67246093749999],
              [-105.52744140624999, 21.81845703124999],
              [-105.64912109375, 21.988085937500003],
              [-105.6455078125, 22.326904296875],
              [-105.791796875, 22.627490234375003],
              [-105.943359375, 22.777001953124994],
              [-106.021728515625, 22.829052734374997],
              [-106.2345703125, 23.0609375],
              [-106.40224609375, 23.195605468750003],
              [-106.56650390624999, 23.449462890625],
              [-106.728759765625, 23.61069335937499],
              [-106.935498046875, 23.88125],
              [-107.08486328125, 24.01611328125],
              [-107.76494140625, 24.471923828125],
              [-107.726611328125, 24.471923828125],
              [-107.52724609375, 24.360058593749997],
              [-107.493701171875, 24.369384765625],
              [-107.488916015625, 24.423974609374994],
              [-107.5119140625, 24.489160156249994],
              [-107.548876953125, 24.504785156249994],
              [-107.602001953125, 24.490136718749994],
              [-107.67368164062499, 24.503564453124994],
              [-107.709521484375, 24.525048828124994],
              [-107.81669921875, 24.539013671874997],
              [-107.951171875, 24.614892578124994],
              [-108.0087890625, 24.693554687499997],
              [-108.015087890625, 24.783398437499997],
              [-108.207666015625, 24.974804687499997],
              [-108.28076171875, 25.08154296875],
              [-108.243310546875, 25.073681640624997],
              [-108.192041015625, 25.030664062499994],
              [-108.140087890625, 25.01840820312499],
              [-108.079638671875, 25.01806640625],
              [-108.035693359375, 25.035351562499997],
              [-108.05146484375, 25.067041015624994],
              [-108.092822265625, 25.093505859375],
              [-108.373681640625, 25.1943359375],
              [-108.466259765625, 25.26513671875],
              [-108.69638671874999, 25.38291015624999],
              [-108.7509765625, 25.42421875],
              [-108.787255859375, 25.538037109374997],
              [-108.84360351562499, 25.54331054687499],
              [-108.89316406249999, 25.51157226562499],
              [-109.02880859375, 25.48046875],
              [-109.0634765625, 25.51669921874999],
              [-109.06845703124999, 25.5515625],
              [-108.97275390624999, 25.58847656249999],
              [-108.88486328124999, 25.696044921875],
              [-108.88657226562499, 25.733447265625003],
              [-108.93515625, 25.69028320312499],
              [-109.008349609375, 25.641992187499994],
              [-109.08408203124999, 25.615039062500003],
              [-109.196484375, 25.592529296875],
              [-109.253955078125, 25.608789062499994],
              [-109.304296875, 25.63315429687499],
              [-109.3849609375, 25.72714843749999],
              [-109.42563476562499, 26.032568359375],
              [-109.354150390625, 26.138476562499996],
              [-109.270654296875, 26.243115234374997],
              [-109.19970703125, 26.305224609374996],
              [-109.15878906249999, 26.258349609374996],
              [-109.11669921875, 26.252734375],
              [-109.14633789062499, 26.305712890624996],
              [-109.216015625, 26.355273437499996],
              [-109.240625, 26.4046875],
              [-109.24326171874999, 26.449951171875],
              [-109.27626953125, 26.533886718749997],
              [-109.482861328125, 26.710351562499994],
              [-109.67607421875, 26.696826171874996],
              [-109.75478515625, 26.702929687499996],
              [-109.828369140625, 26.7701171875],
              [-109.89091796875, 26.8833984375],
              [-109.92172851562499, 26.978173828124994],
              [-109.92563476562499, 27.028662109375],
              [-109.943994140625, 27.079345703125],
              [-110.2771484375, 27.16220703125],
              [-110.37729492187499, 27.233300781249994],
              [-110.477783203125, 27.32265625],
              [-110.51938476562499, 27.39560546875],
              [-110.56064453124999, 27.450146484374997],
              [-110.59267578125, 27.544335937499994],
              [-110.615478515625, 27.65390625],
              [-110.578271484375, 27.795654296875],
              [-110.52988281249999, 27.864208984374997],
              [-110.759033203125, 27.915185546874994],
              [-110.8486328125, 27.917578125],
              [-110.92080078125, 27.888867187499997],
              [-110.986083984375, 27.925976562499997],
              [-111.12138671875, 27.966992187499997],
              [-111.282421875, 28.115234375],
              [-111.4716796875, 28.383984375],
              [-111.680078125, 28.470556640625],
              [-111.747216796875, 28.56396484375],
              [-111.832421875, 28.648144531249997],
              [-111.90703125, 28.752490234374996],
              [-111.918603515625, 28.797900390624996],
              [-111.9408203125, 28.823193359374997],
              [-112.044873046875, 28.895898437499994],
              [-112.161767578125, 29.018896484375],
              [-112.192041015625, 29.11796875],
              [-112.22348632812499, 29.269482421874997],
              [-112.301416015625, 29.322900390624994],
              [-112.37822265625, 29.347705078124996],
              [-112.393212890625, 29.419726562499996],
              [-112.388671875, 29.460107421874994],
              [-112.41455078125, 29.536425781249996],
              [-112.572900390625, 29.71953125],
              [-112.653125, 29.870068359374997],
              [-112.69716796875, 29.916845703125],
              [-112.73837890624999, 29.98544921875],
              [-112.759228515625, 30.125683593749997],
              [-112.82480468749999, 30.300146484375],
              [-112.95175781249999, 30.510009765625],
              [-113.057666015625, 30.651025390624994],
              [-113.11044921874999, 30.793310546875],
              [-113.08701171874999, 30.9380859375],
              [-113.10498046875, 31.027197265625],
              [-113.118603515625, 31.048095703125],
              [-113.107958984375, 31.077294921874994],
              [-113.072802734375, 31.060888671875],
              [-113.042919921875, 31.08701171875],
              [-113.04672851562499, 31.179248046874996],
              [-113.083642578125, 31.207177734374994],
              [-113.186181640625, 31.236035156249997],
              [-113.2314453125, 31.25595703125],
              [-113.48081054687499, 31.293603515624994],
              [-113.623486328125, 31.345898437499997],
              [-113.6330078125, 31.467626953125],
              [-113.699951171875, 31.523339843749994],
              [-113.759423828125, 31.557763671874994],
              [-113.94775390625, 31.629345703124997],
              [-113.977490234375, 31.592724609374997],
              [-114.002685546875, 31.525146484375],
              [-114.08090820312499, 31.5103515625],
              [-114.14931640625, 31.507373046874996],
              [-114.2640625, 31.554443359375],
              [-114.54868164062499, 31.733544921874994],
              [-114.6087890625, 31.762255859374996],
              [-114.69760742187499, 31.77744140625],
              [-114.74130859374999, 31.806494140625],
              [-114.93359375, 31.900732421875],
              [-114.895068359375, 31.850634765624996],
              [-114.839501953125, 31.798535156249997],
              [-114.78989257812499, 31.647119140624994],
              [-114.84814453125, 31.537939453125],
              [-114.881884765625, 31.156396484374994],
              [-114.844677734375, 31.08046875],
              [-114.76103515625, 30.958740234375],
              [-114.703369140625, 30.765185546874996],
              [-114.68544921875, 30.62119140625],
              [-114.63330078125, 30.506884765624996],
              [-114.64975585937499, 30.238134765625],
              [-114.629931640625, 30.156298828124996],
              [-114.55048828125, 30.022265625],
              [-114.40341796874999, 29.896484375],
              [-114.372607421875, 29.830224609374994],
              [-114.17919921875, 29.734326171874997],
              [-114.0619140625, 29.609521484374994],
              [-113.82895507812499, 29.439453125],
              [-113.75546875, 29.367480468749996],
              [-113.5453125, 29.102246093749997],
              [-113.5384765625, 29.023388671874997],
              [-113.49970703125, 28.926708984374997],
              [-113.3818359375, 28.946679687499994],
              [-113.32890625, 28.873046875],
              [-113.335009765625, 28.8390625],
              [-113.320703125, 28.813134765624994],
              [-113.25888671874999, 28.81884765625],
              [-113.20556640625, 28.798779296874997],
              [-113.09365234375, 28.511767578124996],
              [-113.03359375, 28.472607421874997],
              [-112.956640625, 28.455859375],
              [-112.870849609375, 28.42421875],
              [-112.865234375, 28.350634765624996],
              [-112.86845703125, 28.2919921875],
              [-112.795703125, 28.20712890625],
              [-112.808056640625, 28.0921875],
              [-112.74931640625, 27.994873046875],
              [-112.758203125, 27.900634765625],
              [-112.734033203125, 27.825976562499996],
              [-112.55263671875, 27.657470703125],
              [-112.32919921874999, 27.5234375],
              [-112.2826171875, 27.347460937499996],
              [-112.191455078125, 27.186669921874994],
              [-112.09814453125, 27.145947265624997],
              [-112.003955078125, 27.0791015625],
              [-112.015576171875, 27.009716796874997],
              [-112.00908203125, 26.967089843749996],
              [-111.88315429687499, 26.840185546875],
              [-111.86264648437499, 26.678515625],
              [-111.75400390624999, 26.572705078124997],
              [-111.723388671875, 26.564404296874997],
              [-111.6994140625, 26.580957031249994],
              [-111.778515625, 26.687255859375],
              [-111.816845703125, 26.75625],
              [-111.82177734375, 26.865087890625],
              [-111.79526367187499, 26.8796875],
              [-111.56967773437499, 26.7076171875],
              [-111.5458984375, 26.57919921875],
              [-111.470166015625, 26.506640625],
              [-111.464501953125, 26.408447265625],
              [-111.418505859375, 26.349951171875],
              [-111.40458984375, 26.265039062499994],
              [-111.33212890624999, 26.125439453124997],
              [-111.33037109375, 25.931347656249997],
              [-111.29160156249999, 25.789794921875],
              [-111.149560546875, 25.57260742187499],
              [-111.034423828125, 25.526953125],
              [-111.013623046875, 25.4203125],
              [-110.8939453125, 25.144238281249997],
              [-110.7556640625, 24.994580078124997],
              [-110.686767578125, 24.86767578125],
              [-110.67724609375, 24.788525390624997],
              [-110.72900390625, 24.671533203124994],
              [-110.734521484375, 24.58984375],
              [-110.659326171875, 24.341455078124994],
              [-110.54697265624999, 24.214160156250003],
              [-110.421484375, 24.183398437500003],
              [-110.399658203125, 24.16513671874999],
              [-110.409619140625, 24.13095703124999],
              [-110.367431640625, 24.100488281249994],
              [-110.319970703125, 24.139453125],
              [-110.296826171875, 24.194873046875003],
              [-110.32089843749999, 24.259179687499994],
              [-110.32509765625, 24.305957031250003],
              [-110.303759765625, 24.339453125],
              [-110.262890625, 24.34453125],
              [-110.02280273437499, 24.174609375],
              [-109.98251953124999, 24.109375],
              [-109.89316406249999, 24.033007812500003],
              [-109.811328125, 23.939013671875003],
              [-109.77597656249999, 23.864892578124994],
              [-109.710546875, 23.80380859374999],
              [-109.6765625, 23.661572265624997],
              [-109.50961914062499, 23.597900390625],
              [-109.420849609375, 23.480126953124994],
              [-109.414990234375, 23.405566406250003],
              [-109.458056640625, 23.214746093749994],
              [-109.495703125, 23.159814453124994],
              [-109.63046875, 23.078662109375003],
              [-109.72841796875, 22.981835937499994],
              [-109.823046875, 22.922167968750003],
              [-109.9234375, 22.885888671874994],
              [-110.00625, 22.89404296875],
              [-110.08603515624999, 23.00546875],
              [-110.180615234375, 23.341503906249997],
              [-110.244091796875, 23.412255859374994],
              [-110.28876953125, 23.51767578124999],
              [-110.3626953125, 23.604931640624997],
              [-110.62998046874999, 23.7373046875],
              [-110.764892578125, 23.877001953125003],
              [-110.895556640625, 23.970263671875003],
              [-111.036181640625, 24.105273437500003],
              [-111.4193359375, 24.329003906249994],
              [-111.57822265624999, 24.443017578124994],
              [-111.68291015625, 24.555810546874994],
              [-111.750390625, 24.554150390624997],
              [-111.802490234375, 24.542529296875003],
              [-111.822265625, 24.573388671874994],
              [-111.8251953125, 24.631787109374997],
              [-111.84824218749999, 24.670068359374994],
              [-112.07255859375, 24.840039062499997],
              [-112.11904296875, 24.934033203124997],
              [-112.128515625, 25.043115234374994],
              [-112.07797851562499, 25.323974609375],
              [-112.05576171874999, 25.488232421874997],
              [-112.069873046875, 25.57285156249999],
              [-112.093359375, 25.584375],
              [-112.11459960937499, 25.63037109375],
              [-112.119775390625, 25.765527343749994],
              [-112.173828125, 25.91259765625],
              [-112.37724609375, 26.213916015624996],
              [-112.52607421875, 26.273486328124996],
              [-112.6583984375, 26.316748046875],
              [-113.020751953125, 26.583251953125],
              [-113.11923828124999, 26.716503906249997],
              [-113.143212890625, 26.7921875],
              [-113.155810546875, 26.946240234374997],
              [-113.20576171875, 26.856982421874996],
              [-113.272265625, 26.790966796874997],
              [-113.42587890624999, 26.795800781249994],
              [-113.59853515625, 26.7212890625],
              [-113.70126953124999, 26.791357421875],
              [-113.756640625, 26.870849609375],
              [-113.840966796875, 26.966503906249997],
              [-113.9359375, 26.985253906249994],
              [-113.996484375, 26.987695312499994],
              [-114.11005859375, 27.10595703125],
              [-114.20185546875, 27.143505859374997],
              [-114.3333984375, 27.158007812499996],
              [-114.445263671875, 27.218164062499994],
              [-114.4796875, 27.28359375],
              [-114.498291015625, 27.376220703125],
              [-114.53989257812499, 27.431103515624997],
              [-114.715625, 27.53955078125],
              [-114.85874023437499, 27.6591796875],
              [-114.99350585937499, 27.736035156249997],
              [-115.033203125, 27.798876953124996],
              [-115.03647460937499, 27.841845703124996],
              [-114.82353515625, 27.829931640625],
              [-114.57001953125, 27.783935546875],
              [-114.448486328125, 27.796875],
              [-114.372705078125, 27.841210937499994],
              [-114.3005859375, 27.872998046874997],
              [-114.2890625, 27.838574218749997],
              [-114.30224609375, 27.775732421875],
              [-114.232666015625, 27.718115234375],
              [-114.13720703125, 27.671435546874996],
              [-114.0693359375, 27.675683593749994],
              [-114.13505859375, 27.726611328124996],
              [-114.175390625, 27.83056640625],
              [-114.15732421874999, 27.86796875],
              [-114.1583984375, 27.919677734375],
              [-114.25263671875, 27.908007812499996],
              [-114.265869140625, 27.934472656249994],
              [-114.18525390625, 28.01328125],
              [-114.092724609375, 28.221337890624994],
              [-114.048486328125, 28.426171875],
              [-114.1455078125, 28.605419921874997],
              [-114.309228515625, 28.729931640624997],
              [-114.664013671875, 29.094580078125],
              [-114.875927734375, 29.281884765624994],
              [-114.9373046875, 29.351611328124996],
              [-114.99350585937499, 29.384423828124994],
              [-115.16635742187499, 29.42724609375],
              [-115.311181640625, 29.531933593749997],
              [-115.56528320312499, 29.680029296875],
              [-115.673828125, 29.756396484374996],
              [-115.748681640625, 29.935742187499997],
              [-115.80830078125, 29.960205078125],
              [-115.78955078125, 30.084179687499997],
              [-115.815625, 30.303613281249994],
              [-115.858203125, 30.359814453124997],
              [-115.99580078125, 30.414453125],
              [-116.028564453125, 30.56357421875],
              [-116.0353515625, 30.70546875],
              [-116.062158203125, 30.804150390624997],
              [-116.2962890625, 30.970507812499996],
              [-116.30961914062499, 31.050976562499997],
              [-116.30966796875, 31.12734375],
              [-116.333447265625, 31.202783203124994],
              [-116.45849609375, 31.360986328124994],
              [-116.6095703125, 31.499072265624996],
              [-116.66215820312499, 31.564892578124997],
              [-116.66845703125, 31.698632812499994],
              [-116.7220703125, 31.734570312499997],
              [-116.70170898437499, 31.74365234375],
              [-116.652099609375, 31.74033203125],
              [-116.623876953125, 31.758007812499997],
              [-116.62080078125, 31.85107421875],
              [-116.847998046875, 31.997363281249996],
              [-116.913671875, 32.198535156249996],
              [-117.034765625, 32.305029296875],
              [-117.063134765625, 32.343603515625],
              [-117.128271484375, 32.533349609374994],
            ],
          ],
          [
            [
              [-86.9396484375, 20.30332031249999],
              [-86.99140625, 20.272167968749997],
              [-87.01943359375, 20.38232421875],
              [-86.977978515625, 20.489794921875003],
              [-86.92783203124999, 20.551513671875],
              [-86.828564453125, 20.558789062499997],
              [-86.76328125, 20.579052734374997],
              [-86.755029296875, 20.5517578125],
              [-86.8087890625, 20.468457031249997],
              [-86.9396484375, 20.30332031249999],
            ],
          ],
          [
            [
              [-106.50224609375, 21.61083984375],
              [-106.53134765624999, 21.528515625],
              [-106.60703125, 21.561474609374997],
              [-106.6341796875, 21.61313476562499],
              [-106.63935546875, 21.69785156249999],
              [-106.59736328125, 21.712158203125],
              [-106.53642578125, 21.67636718749999],
              [-106.523828125, 21.65234375],
              [-106.50224609375, 21.61083984375],
            ],
          ],
          [
            [
              [-110.914453125, 18.741455078125],
              [-110.9748046875, 18.720361328124994],
              [-111.063671875, 18.781640625],
              [-111.03994140625, 18.830126953125003],
              [-110.989404296875, 18.86313476562499],
              [-110.94208984375, 18.801708984374997],
              [-110.914453125, 18.741455078125],
            ],
          ],
          [
            [
              [-110.5673828125, 25.003466796875003],
              [-110.5388671875, 24.891552734374997],
              [-110.59018554687499, 24.90805664062499],
              [-110.657421875, 24.96884765624999],
              [-110.70341796875, 25.046630859375],
              [-110.699267578125, 25.081445312499994],
              [-110.690234375, 25.087841796874997],
              [-110.59521484375, 25.042138671874994],
              [-110.5673828125, 25.003466796875003],
            ],
          ],
          [
            [
              [-113.15561523437499, 29.05224609375],
              [-113.16279296875, 29.034765625],
              [-113.26474609374999, 29.096728515624996],
              [-113.496337890625, 29.3076171875],
              [-113.580615234375, 29.413232421874994],
              [-113.594384765625, 29.462695312499996],
              [-113.58720703125, 29.573046875],
              [-113.507958984375, 29.559912109375],
              [-113.41591796875, 29.4859375],
              [-113.37583007812499, 29.41748046875],
              [-113.373828125, 29.338916015624996],
              [-113.2021484375, 29.30185546875],
              [-113.1779296875, 29.13193359375],
              [-113.15561523437499, 29.05224609375],
            ],
          ],
          [
            [
              [-115.17060546875, 28.069384765624996],
              [-115.18427734375, 28.037255859374994],
              [-115.3529296875, 28.103955078124997],
              [-115.260400390625, 28.220556640625],
              [-115.273974609375, 28.3427734375],
              [-115.233544921875, 28.368359375],
              [-115.19697265625, 28.327880859375],
              [-115.14853515624999, 28.172119140625],
              [-115.17060546875, 28.069384765624996],
            ],
          ],
          [
            [
              [-112.203076171875, 29.005322265624997],
              [-112.27841796874999, 28.769335937499996],
              [-112.3552734375, 28.773144531249997],
              [-112.5140625, 28.847607421874997],
              [-112.531005859375, 28.893994140624997],
              [-112.46982421874999, 29.167724609375],
              [-112.42353515625, 29.203662109374996],
              [-112.28505859375, 29.240429687499997],
              [-112.263427734375, 29.206787109375],
              [-112.24873046875, 29.1259765625],
              [-112.203076171875, 29.005322265624997],
            ],
          ],
          [
            [
              [-118.2427734375, 28.941943359374996],
              [-118.285498046875, 28.903759765624997],
              [-118.40009765625, 29.1123046875],
              [-118.4013671875, 29.162744140624994],
              [-118.367822265625, 29.18759765625],
              [-118.31230468749999, 29.182861328125],
              [-118.31206054687499, 29.130517578124994],
              [-118.26552734375, 29.08642578125],
              [-118.24736328124999, 29.043359375],
              [-118.2427734375, 28.941943359374996],
            ],
          ],
          [
            [
              [-86.714013671875, 21.239306640625003],
              [-86.6962890625, 21.191015625],
              [-86.713623046875, 21.19677734375],
              [-86.73637695312499, 21.233300781249994],
              [-86.752880859375, 21.27880859375],
              [-86.73906249999999, 21.279980468749997],
              [-86.72690429687499, 21.264306640624994],
              [-86.714013671875, 21.239306640625003],
            ],
          ],
          [
            [
              [-91.68369140624999, 18.67734375],
              [-91.796142578125, 18.654199218749994],
              [-91.81611328125, 18.67587890624999],
              [-91.589111328125, 18.778027343749997],
              [-91.55029296875, 18.773681640625],
              [-91.53671875, 18.760009765625],
              [-91.654248046875, 18.711474609375003],
              [-91.68369140624999, 18.67734375],
            ],
          ],
          [
            [
              [-109.805078125, 24.151074218749997],
              [-109.82675781249999, 24.14755859374999],
              [-109.8779296875, 24.200634765624997],
              [-109.90048828124999, 24.33090820312499],
              [-109.89033203125, 24.34482421874999],
              [-109.79379882812499, 24.183398437500003],
              [-109.79560546875, 24.16357421875],
              [-109.805078125, 24.151074218749997],
            ],
          ],
          [
            [
              [-114.694140625, 31.705615234374996],
              [-114.72724609375, 31.701367187499997],
              [-114.789208984375, 31.747412109375],
              [-114.7845703125, 31.789794921875],
              [-114.77109375, 31.794091796874994],
              [-114.70908203124999, 31.756884765624996],
              [-114.687939453125, 31.72421875],
              [-114.694140625, 31.705615234374996],
            ],
          ],
          [
            [
              [-111.10029296875, 26.02060546875],
              [-111.08774414062499, 25.984521484374994],
              [-111.09443359375, 25.974072265624997],
              [-111.13525390625, 25.999169921874994],
              [-111.2044921875, 25.84970703124999],
              [-111.224658203125, 25.835888671874997],
              [-111.18291015625, 26.040625],
              [-111.13925781249999, 26.06982421875],
              [-111.090869140625, 26.07568359375],
              [-111.10029296875, 26.02060546875],
            ],
          ],
          [
            [
              [-111.698876953125, 24.393603515625003],
              [-111.7123046875, 24.346386718749997],
              [-112.01328125, 24.533398437499997],
              [-111.940869140625, 24.55112304687499],
              [-111.8568359375, 24.537988281249994],
              [-111.698876953125, 24.393603515625003],
            ],
          ],
          [
            [
              [-112.057275390625, 24.545703125],
              [-112.07734375, 24.534570312499994],
              [-112.162890625, 24.650292968749994],
              [-112.17548828125, 24.72958984374999],
              [-112.210498046875, 24.763134765624997],
              [-112.29677734375, 24.78964843749999],
              [-112.222314453125, 24.951123046874997],
              [-112.159423828125, 25.28564453125],
              [-112.13168945312499, 25.224365234375],
              [-112.198388671875, 24.885449218749997],
              [-112.19501953125, 24.841064453125],
              [-112.16376953125, 24.79965820312499],
              [-112.13022460937499, 24.72958984374999],
              [-112.12626953125, 24.654003906249997],
              [-112.06748046874999, 24.583642578124994],
              [-112.057275390625, 24.545703125],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Denmark",
        sov_a3: "DN1",
        adm0_dif: 1,
        level: 2,
        type: "Country",
        tlc: "1",
        admin: "Greenland",
        adm0_a3: "GRL",
        geou_dif: 0,
        geounit: "Greenland",
        gu_a3: "GRL",
        su_dif: 0,
        subunit: "Greenland",
        su_a3: "GRL",
        brk_diff: 0,
        name: "Greenland",
        name_long: "Greenland",
        brk_a3: "GRL",
        brk_name: "Greenland",
        brk_group: null,
        abbrev: "Grlnd.",
        postal: "GL",
        formal_en: "Greenland",
        formal_fr: null,
        name_ciawf: "Greenland",
        note_adm0: "Den.",
        note_brk: null,
        name_sort: "Greenland",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 1,
        mapcolor9: 3,
        mapcolor13: 12,
        pop_est: 56225,
        pop_rank: 8,
        pop_year: 2019,
        gdp_md: 3051,
        gdp_year: 2018,
        economy: "2. Developed region: nonG7",
        income_grp: "2. High income: nonOECD",
        fips_10: "GL",
        iso_a2: "GL",
        iso_a2_eh: "GL",
        iso_a3: "GRL",
        iso_a3_eh: "GRL",
        iso_n3: "304",
        iso_n3_eh: "304",
        un_a3: "304",
        wb_a2: "GL",
        wb_a3: "GRL",
        woe_id: 23424828,
        woe_id_eh: 23424828,
        woe_note: "Exact WOE match as country",
        adm0_iso: "GRL",
        adm0_diff: null,
        adm0_tlc: "GRL",
        adm0_a3_us: "GRL",
        adm0_a3_fr: "GRL",
        adm0_a3_ru: "GRL",
        adm0_a3_es: "GRL",
        adm0_a3_cn: "GRL",
        adm0_a3_tw: "GRL",
        adm0_a3_in: "GRL",
        adm0_a3_np: "GRL",
        adm0_a3_pk: "GRL",
        adm0_a3_de: "GRL",
        adm0_a3_gb: "GRL",
        adm0_a3_br: "GRL",
        adm0_a3_il: "GRL",
        adm0_a3_ps: "GRL",
        adm0_a3_sa: "GRL",
        adm0_a3_eg: "GRL",
        adm0_a3_ma: "GRL",
        adm0_a3_pt: "GRL",
        adm0_a3_ar: "GRL",
        adm0_a3_jp: "GRL",
        adm0_a3_ko: "GRL",
        adm0_a3_vn: "GRL",
        adm0_a3_tr: "GRL",
        adm0_a3_id: "GRL",
        adm0_a3_pl: "GRL",
        adm0_a3_gr: "GRL",
        adm0_a3_it: "GRL",
        adm0_a3_nl: "GRL",
        adm0_a3_se: "GRL",
        adm0_a3_bd: "GRL",
        adm0_a3_ua: "GRL",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "North America",
        region_un: "Americas",
        subregion: "Northern America",
        region_wb: "Europe & Central Asia",
        name_len: 9,
        long_len: 9,
        abbrev_len: 6,
        tiny: -99,
        homepart: -99,
        min_zoom: 0,
        min_label: 1.7,
        max_label: 6.7,
        label_x: -39.335251,
        label_y: 74.319387,
        ne_id: 1159320551,
        wikidataid: "Q223",
        name_ar: "جرينلاند",
        name_bn: "গ্রিনল্যান্ড",
        name_de: "Grönland",
        name_en: "Greenland",
        name_es: "Groenlandia",
        name_fa: "گرینلند",
        name_fr: "Groenland",
        name_el: "Γροιλανδία",
        name_he: "גרינלנד",
        name_hi: "ग्रीनलैण्ड",
        name_hu: "Grönland",
        name_id: "Greenland",
        name_it: "Groenlandia",
        name_ja: "グリーンランド",
        name_ko: "그린란드",
        name_nl: "Groenland",
        name_pl: "Grenlandia",
        name_pt: "Groenlândia",
        name_ru: "Гренландия",
        name_sv: "Grönland",
        name_tr: "Grönland",
        name_uk: "Гренландія",
        name_ur: "گرین لینڈ",
        name_vi: "Greenland",
        name_zh: "格陵兰",
        name_zht: "格陵蘭",
        fclass_iso: "Admin-0 dependency",
        tlc_diff: null,
        fclass_tlc: "Admin-0 dependency",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "GRL.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-29.952880859375, 83.56484375],
              [-28.99199218749999, 83.50478515625],
              [-28.483789062499994, 83.434912109375],
              [-28.377050781250006, 83.43720703125],
              [-27.688378906249994, 83.410400390625],
              [-27.034423828125, 83.376904296875],
              [-25.947412109374994, 83.2896484375],
              [-25.795068359374994, 83.260986328125],
              [-25.912451171874977, 83.2375],
              [-26.182714843749977, 83.22138671875],
              [-27.571875, 83.192626953125],
              [-30.091992187499983, 83.157421875],
              [-31.53398437499999, 83.088916015625],
              [-31.99267578125, 83.0853515625],
              [-32.03271484375, 82.983447265625],
              [-31.836767578125006, 82.977880859375],
              [-31.515576171874983, 82.991650390625],
              [-30.38603515624999, 83.093701171875],
              [-29.963574218749983, 83.110498046875],
              [-29.175, 83.102001953125],
              [-28.151464843750006, 83.063720703125],
              [-27.738525390625, 83.077197265625],
              [-27.002050781250006, 83.0671875],
              [-26.140820312499983, 83.096435546875],
              [-25.123388671874977, 83.159619140625],
              [-24.845166015624983, 83.0185546875],
              [-24.470312499999977, 82.877392578125],
              [-24.17363281249999, 82.893017578125],
              [-23.919531249999977, 82.885107421875],
              [-23.83354492187499, 82.83876953125],
              [-23.69462890624999, 82.819140625],
              [-23.406933593749983, 82.8296875],
              [-22.52490234375, 82.78916015625],
              [-21.919677734375, 82.71640625],
              [-21.691796875, 82.68251953125],
              [-21.58251953125, 82.6341796875],
              [-21.520654296874994, 82.59541015625],
              [-21.61577148437499, 82.547705078125],
              [-21.99394531249999, 82.46279296875],
              [-22.472558593749994, 82.384716796875],
              [-23.118066406249994, 82.32470703125],
              [-23.862207031249994, 82.287060546875],
              [-29.57939453124999, 82.161181640625],
              [-29.77275390624999, 82.13125],
              [-29.887402343749983, 82.054833984375],
              [-29.810986328124983, 81.95546875],
              [-29.543847656249994, 81.93994140625],
              [-28.91943359375, 81.9958984375],
              [-27.839501953124994, 82.048876953125],
              [-27.04594726562499, 82.046337890625],
              [-25.148828125, 82.001123046875],
              [-24.58916015624999, 81.8828125],
              [-24.293066406249977, 81.7009765625],
              [-23.63652343749999, 81.741845703125],
              [-23.49614257812499, 81.773046875],
              [-23.39296875, 81.827197265625],
              [-23.310546875, 81.885302734375],
              [-23.248779296875, 81.947265625],
              [-23.179833984374994, 81.989453125],
              [-23.103710937499983, 82.01181640625],
              [-22.940087890624994, 82.030517578125],
              [-22.563378906249994, 82.05302734375],
              [-21.575537109374977, 82.074951171875],
              [-21.337988281249977, 82.068701171875],
              [-21.167382812499994, 81.983837890625],
              [-21.130322265624983, 81.934228515625],
              [-21.11796874999999, 81.86962890625],
              [-21.1234375, 81.78994140625],
              [-21.146582031249977, 81.695166015625],
              [-21.23051757812499, 81.6013671875],
              [-21.50390625, 81.4375],
              [-21.7236328125, 81.3482421875],
              [-21.96074218749999, 81.283935546875],
              [-22.415283203125, 81.137109375],
              [-22.57275390625, 81.097900390625],
              [-23.072460937499983, 80.926708984375],
              [-23.196386718749977, 80.84736328125],
              [-23.20366210937499, 80.7892578125],
              [-23.11772460937499, 80.778173828125],
              [-22.972851562499983, 80.8328125],
              [-22.9189453125, 80.871826171875],
              [-22.82568359375, 80.912646484375],
              [-22.08940429687499, 81.02021484375],
              [-21.931347656249983, 81.0501953125],
              [-21.44975585937499, 81.178173828125],
              [-21.142431640624977, 81.226171875],
              [-20.889746093749977, 81.2763671875],
              [-20.755859375, 81.31201171875],
              [-20.015722656249977, 81.56435546875],
              [-19.62993164062499, 81.639892578125],
              [-19.224755859374994, 81.6400390625],
              [-19.152978515624994, 81.51220703125],
              [-18.667382812499994, 81.492431640625],
              [-18.45654296875, 81.49794921875],
              [-18.117871093749983, 81.466845703125],
              [-17.969384765624994, 81.441162109375],
              [-17.716650390624977, 81.428173828125],
              [-17.4560546875, 81.397705078125],
              [-17.226220703124994, 81.430419921875],
              [-17.159033203124977, 81.450927734375],
              [-16.93706054687499, 81.543896484375],
              [-16.637109375, 81.626220703125],
              [-16.358984374999977, 81.729052734375],
              [-16.266796874999983, 81.753955078125],
              [-16.120703124999977, 81.776611328125],
              [-15.968896484374994, 81.785498046875],
              [-15.555517578124977, 81.83359375],
              [-15.450634765624983, 81.836962890625],
              [-15.227490234374983, 81.82177734375],
              [-14.241992187499989, 81.8138671875],
              [-13.704492187499994, 81.7890625],
              [-12.956005859374983, 81.72021484375],
              [-12.434423828124977, 81.68251953125],
              [-12.19287109375, 81.64912109375],
              [-11.841113281249989, 81.5775390625],
              [-11.557470703124977, 81.50263671875],
              [-11.425537109375, 81.480615234375],
              [-11.4306640625, 81.4568359375],
              [-11.52880859375, 81.4240234375],
              [-12.231347656249994, 81.309228515625],
              [-12.461230468749989, 81.23251953125],
              [-13.126220703125, 81.08779296875],
              [-13.451171875, 81.0380859375],
              [-13.804296874999977, 81.018603515625],
              [-14.197363281249977, 81.013916015625],
              [-14.452343749999983, 80.993115234375],
              [-14.490136718749994, 80.973291015625],
              [-14.308496093749994, 80.913232421875],
              [-14.228564453124989, 80.870458984375],
              [-14.240185546874983, 80.832421875],
              [-14.43125, 80.77607421875],
              [-14.503564453124994, 80.76328125],
              [-15.194238281249994, 80.721435546875],
              [-15.542675781249983, 80.650390625],
              [-15.997509765624983, 80.64169921875],
              [-16.318945312499977, 80.6498046875],
              [-16.760595703124977, 80.573388671875],
              [-16.587792968749994, 80.51123046875],
              [-16.429443359375, 80.484228515625],
              [-15.937255859375, 80.42763671875],
              [-15.9326171875, 80.3951171875],
              [-16.16777343749999, 80.32939453125],
              [-16.48876953125, 80.251953125],
              [-16.868408203125, 80.1982421875],
              [-17.011132812499994, 80.190185546875],
              [-17.191162109375, 80.203662109375],
              [-17.35722656249999, 80.20078125],
              [-17.722851562499983, 80.176025390625],
              [-18.070947265624994, 80.1720703125],
              [-18.692578124999983, 80.207080078125],
              [-19.029003906249983, 80.247607421875],
              [-19.206005859374983, 80.26162109375],
              [-19.42919921875, 80.25771484375],
              [-19.515039062499994, 80.24140625],
              [-19.866796874999977, 80.1447265625],
              [-20.039501953124983, 80.0787109375],
              [-20.150146484375, 80.01123046875],
              [-20.197412109374994, 79.937646484375],
              [-20.181347656249983, 79.857958984375],
              [-20.13847656249999, 79.803369140625],
              [-20.06884765625, 79.773779296875],
              [-19.98540039062499, 79.755859375],
              [-19.839306640624983, 79.746484375],
              [-19.51787109374999, 79.75537109375],
              [-19.391503906249994, 79.750341796875],
              [-19.35302734375, 79.7341796875],
              [-19.28359375, 79.683154296875],
              [-19.295996093749977, 79.635009765625],
              [-19.354199218749983, 79.567333984375],
              [-19.399316406249994, 79.48837890625],
              [-19.43120117187499, 79.39814453125],
              [-19.414013671874983, 79.348828125],
              [-19.28398437499999, 79.338037109375],
              [-19.22294921874999, 79.3416015625],
              [-19.152197265624977, 79.325390625],
              [-19.07177734375, 79.289453125],
              [-19.011328124999977, 79.25146484375],
              [-18.970800781249977, 79.211376953125],
              [-18.99199218749999, 79.178369140625],
              [-19.074951171875, 79.15234375],
              [-19.26220703125, 79.122998046875],
              [-19.723046875, 79.0650390625],
              [-19.76982421874999, 79.04736328125],
              [-19.806054687499994, 79.012109375],
              [-19.83159179687499, 78.959130859375],
              [-19.88720703125, 78.9109375],
              [-19.972900390625, 78.867626953125],
              [-20.050488281249983, 78.841796875],
              [-20.199902343749983, 78.830322265625],
              [-20.395703124999983, 78.82880859375],
              [-20.615576171874977, 78.80390625],
              [-21.133740234374983, 78.658642578125],
              [-21.141455078124977, 78.642529296875],
              [-20.947460937499983, 78.5958984375],
              [-20.955664062499977, 78.555029296875],
              [-21.194775390624983, 78.379833984375],
              [-21.260205078124983, 78.293017578125],
              [-21.31201171875, 78.173974609375],
              [-21.39726562499999, 78.073583984375],
              [-21.515966796874977, 77.991845703125],
              [-21.632666015624977, 77.8974609375],
              [-21.74755859375, 77.790625],
              [-21.729589843749977, 77.708544921875],
              [-21.57890624999999, 77.6513671875],
              [-21.37968749999999, 77.69755859375],
              [-21.13188476562499, 77.847216796875],
              [-20.86259765624999, 77.911865234375],
              [-20.57182617187499, 77.891552734375],
              [-20.318603515625, 77.861962890625],
              [-19.9951171875, 77.80341796875],
              [-19.724316406249983, 77.766943359375],
              [-19.490429687499983, 77.718896484375],
              [-19.393994140624983, 77.678369140625],
              [-19.296875, 77.6212890625],
              [-19.296093749999983, 77.58525390625],
              [-19.467529296875, 77.5658203125],
              [-19.524121093749983, 77.57197265625],
              [-19.953222656249977, 77.666357421875],
              [-20.162060546874983, 77.68984375],
              [-20.439208984375, 77.66162109375],
              [-20.680810546874994, 77.618994140625],
              [-20.463769531249994, 77.447314453125],
              [-20.23193359375, 77.368408203125],
              [-19.80864257812499, 77.332373046875],
              [-19.587597656249983, 77.29443359375],
              [-19.426416015624994, 77.24599609375],
              [-19.30029296875, 77.22236328125],
              [-19.131005859374994, 77.232763671875],
              [-18.903417968749977, 77.28046875],
              [-18.585888671874983, 77.283056640625],
              [-18.442626953125, 77.259375],
              [-18.339013671874994, 77.215283203125],
              [-18.292382812499994, 77.132861328125],
              [-18.302734375, 77.012109375],
              [-18.337255859374977, 76.92119140625],
              [-18.39604492187499, 76.86005859375],
              [-18.51030273437499, 76.778173828125],
              [-18.60576171874999, 76.76328125],
              [-18.74028320312499, 76.767724609375],
              [-18.865332031249977, 76.784521484375],
              [-18.98100585937499, 76.81376953125],
              [-19.156347656249977, 76.836572265625],
              [-19.5087890625, 76.861083984375],
              [-19.864941406249983, 76.914404296875],
              [-20.064355468749994, 76.927587890625],
              [-20.48671875, 76.92080078125],
              [-20.942089843749983, 76.88701171875],
              [-20.959912109374983, 76.84267578125],
              [-21.614697265624983, 76.687890625],
              [-21.7490234375, 76.689990234375],
              [-21.930810546874994, 76.7431640625],
              [-22.185253906249983, 76.794091796875],
              [-22.334326171874977, 76.793701171875],
              [-22.554541015624977, 76.729248046875],
              [-22.609326171874983, 76.704296875],
              [-22.606640624999983, 76.68076171875],
              [-22.444433593749977, 76.625048828125],
              [-22.378613281249983, 76.61220703125],
              [-22.294873046874983, 76.60146484375],
              [-22.00371093749999, 76.5880859375],
              [-21.87734375, 76.573486328125],
              [-21.75810546874999, 76.400537109375],
              [-21.569091796875, 76.293701171875],
              [-21.488232421874983, 76.271875],
              [-21.416845703124977, 76.264013671875],
              [-21.185449218749994, 76.26796875],
              [-20.887402343749983, 76.30400390625],
              [-20.783300781249977, 76.275146484375],
              [-20.563818359374977, 76.23984375],
              [-20.435400390624977, 76.2310546875],
              [-20.279296875, 76.232470703125],
              [-20.103613281249977, 76.219091796875],
              [-19.862890624999977, 76.120654296875],
              [-19.957714843749983, 75.9966796875],
              [-19.806884765625, 75.89736328125],
              [-19.566015624999977, 75.794970703125],
              [-19.508984374999983, 75.75751953125],
              [-19.485693359374977, 75.689599609375],
              [-19.48027343749999, 75.644775390625],
              [-19.462158203125, 75.603857421875],
              [-19.43144531249999, 75.56689453125],
              [-19.399511718749977, 75.49443359375],
              [-19.366455078125, 75.38642578125],
              [-19.37529296874999, 75.298193359375],
              [-19.425976562499983, 75.229833984375],
              [-19.5263671875, 75.180224609375],
              [-19.67626953125, 75.149365234375],
              [-19.798486328124994, 75.157470703125],
              [-19.893164062499977, 75.204541015625],
              [-20.026562499999983, 75.2546875],
              [-20.198681640624983, 75.307958984375],
              [-20.484960937499977, 75.3142578125],
              [-20.905859374999977, 75.15693359375],
              [-21.093847656249977, 75.149072265625],
              [-21.246533203124983, 75.1333984375],
              [-21.409423828125, 75.064794921875],
              [-21.649316406249994, 75.0234375],
              [-21.861035156249983, 75.03984375],
              [-22.232861328124983, 75.1197265625],
              [-22.097753906249977, 75.066357421875],
              [-21.90434570312499, 75.00390625],
              [-21.783935546875, 74.971484375],
              [-21.69511718749999, 74.964453125],
              [-21.59765625, 74.97197265625],
              [-21.45732421874999, 74.99755859375],
              [-21.140576171874983, 75.0685546875],
              [-21.05668945312499, 75.07939453125],
              [-20.985791015624983, 75.074365234375],
              [-20.92778320312499, 75.05341796875],
              [-20.861083984375, 74.992578125],
              [-20.78569335937499, 74.891748046875],
              [-20.7953125, 74.80595703125],
              [-20.889990234374977, 74.735205078125],
              [-20.97099609374999, 74.68984375],
              [-21.038281249999983, 74.669873046875],
              [-21.038476562499994, 74.654150390625],
              [-20.861572265625, 74.6359375],
              [-20.61113281249999, 74.72822265625],
              [-20.53173828125, 74.842919921875],
              [-20.417089843749977, 74.9751953125],
              [-20.214257812499994, 75.01923828125],
              [-19.98491210937499, 74.9751953125],
              [-19.799707031249994, 74.851708984375],
              [-19.537792968749983, 74.624560546875],
              [-19.427343749999977, 74.600927734375],
              [-19.287011718749994, 74.54638671875],
              [-19.22509765625, 74.4794921875],
              [-19.241650390624983, 74.4001953125],
              [-19.271582031249977, 74.342626953125],
              [-19.31494140625, 74.306787109375],
              [-19.369140625, 74.284033203125],
              [-19.466748046874983, 74.269482421875],
              [-19.646240234375, 74.257958984375],
              [-20.047558593749983, 74.282275390625],
              [-20.256445312499977, 74.2828125],
              [-20.230566406249977, 74.204638671875],
              [-20.653125, 74.137353515625],
              [-21.12944335937499, 74.110888671875],
              [-21.58056640625, 74.1634765625],
              [-21.954931640624977, 74.244287109375],
              [-21.83203125, 74.357275390625],
              [-21.761962890625, 74.482763671875],
              [-21.94291992187499, 74.56572265625],
              [-21.982617187499983, 74.56748046875],
              [-21.920166015625, 74.439208984375],
              [-21.97270507812499, 74.3900390625],
              [-22.177197265624983, 74.33017578125],
              [-22.32158203124999, 74.3025390625],
              [-22.334326171874977, 74.286376953125],
              [-22.263525390624977, 74.272412109375],
              [-22.21733398437499, 74.2455078125],
              [-22.195654296874977, 74.205712890625],
              [-22.22001953124999, 74.16552734375],
              [-22.29057617187499, 74.125],
              [-22.328955078124977, 74.090966796875],
              [-22.33525390624999, 74.063427734375],
              [-22.27055664062499, 74.0298828125],
              [-22.13481445312499, 73.990478515625],
              [-21.987695312499994, 73.97099609375],
              [-21.298291015624983, 73.962451171875],
              [-21.022216796875, 73.941259765625],
              [-20.367285156249977, 73.8482421875],
              [-20.337988281249977, 73.819677734375],
              [-20.448925781249983, 73.65302734375],
              [-20.509667968749994, 73.49287109375],
              [-20.63671875, 73.46357421875],
              [-21.325878906249983, 73.456640625],
              [-21.547998046874994, 73.431689453125],
              [-21.87285156249999, 73.35810546875],
              [-22.18505859375, 73.269873046875],
              [-22.346875, 73.26923828125],
              [-22.9875, 73.346240234375],
              [-23.23320312499999, 73.397705078125],
              [-23.760595703124977, 73.543115234375],
              [-24.15771484375, 73.764453125],
              [-24.33989257812499, 73.672412109375],
              [-24.451269531249977, 73.628515625],
              [-24.566308593749994, 73.60576171875],
              [-24.67724609375, 73.602197265625],
              [-24.7841796875, 73.617919921875],
              [-24.905468749999983, 73.652783203125],
              [-25.108837890624983, 73.73369140625],
              [-25.351464843749994, 73.813623046875],
              [-25.52128906249999, 73.851611328125],
              [-25.527734375, 73.8408203125],
              [-25.427441406249983, 73.793798828125],
              [-25.280517578125, 73.739599609375],
              [-24.908886718749983, 73.58017578125],
              [-24.7783203125, 73.539892578125],
              [-24.791259765625, 73.511279296875],
              [-25.02587890625, 73.485791015625],
              [-25.310742187499983, 73.431005859375],
              [-25.450097656249994, 73.390673828125],
              [-25.665429687499994, 73.292822265625],
              [-25.740185546874983, 73.27763671875],
              [-26.06230468749999, 73.25302734375],
              [-26.168554687499977, 73.259033203125],
              [-26.40673828125, 73.312939453125],
              [-26.765478515625006, 73.348193359375],
              [-26.976708984374994, 73.379541015625],
              [-27.270410156249994, 73.436279296875],
              [-27.169384765624983, 73.374169921875],
              [-26.603613281250006, 73.2794921875],
              [-26.541845703125006, 73.248974609375],
              [-26.657617187499994, 73.192138671875],
              [-26.728613281250006, 73.17138671875],
              [-26.86333007812499, 73.1669921875],
              [-27.061865234375006, 73.17890625],
              [-27.264892578125, 73.17646484375],
              [-27.472363281249983, 73.159814453125],
              [-27.561621093750006, 73.1384765625],
              [-27.532568359375006, 73.112548828125],
              [-27.483154296875, 73.088916015625],
              [-27.413330078125, 73.067626953125],
              [-27.348046875, 73.067822265625],
              [-27.189892578124983, 73.132421875],
              [-27.070019531249983, 73.13701171875],
              [-26.75322265624999, 73.12109375],
              [-26.432861328125, 73.171484375],
              [-26.202001953124977, 73.193212890625],
              [-26.028759765624983, 73.198779296875],
              [-25.399023437499977, 73.27578125],
              [-25.268310546875, 73.361962890625],
              [-25.05703125, 73.396484375],
              [-24.58720703124999, 73.42294921875],
              [-24.132666015624977, 73.409375],
              [-23.89897460937499, 73.398291015625],
              [-23.709619140624994, 73.316796875],
              [-23.455761718749983, 73.25908203125],
              [-23.244091796874983, 73.19326171875],
              [-22.996044921874983, 73.17158203125],
              [-22.852294921875, 73.083984375],
              [-22.4501953125, 72.986083984375],
              [-22.194238281249994, 72.9650390625],
              [-22.036328124999983, 72.91845703125],
              [-22.02348632812499, 72.72080078125],
              [-22.006738281249994, 72.63544921875],
              [-22.074804687499977, 72.39921875],
              [-22.280224609374983, 72.344775390625],
              [-22.2392578125, 72.220263671875],
              [-22.293212890625, 72.11953125],
              [-22.497509765624983, 72.157763671875],
              [-22.70683593749999, 72.22392578125],
              [-23.2080078125, 72.3265625],
              [-23.67436523437499, 72.392578125],
              [-23.855566406249977, 72.45244140625],
              [-24.069042968749983, 72.49873046875],
              [-24.358593749999983, 72.6873046875],
              [-24.547216796874977, 72.921728515625],
              [-24.629980468749977, 73.037646484375],
              [-24.78857421875, 73.044140625],
              [-24.99248046874999, 73.0130859375],
              [-25.170556640624994, 72.9802734375],
              [-25.255859375, 72.92412109375],
              [-25.86083984375, 72.846875],
              [-26.08046875, 72.793994140625],
              [-26.205761718749983, 72.795556640625],
              [-26.657617187499994, 72.7158203125],
              [-26.4765625, 72.67763671875],
              [-26.39208984375, 72.672802734375],
              [-26.20947265625, 72.694384765625],
              [-26.099804687499983, 72.721923828125],
              [-25.68798828125, 72.79736328125],
              [-25.357421875, 72.81025390625],
              [-25.2375, 72.8427734375],
              [-24.984814453124983, 72.889208984375],
              [-24.813330078124977, 72.901513671875],
              [-24.789453125, 72.88974609375],
              [-24.77104492187499, 72.86865234375],
              [-24.65, 72.58251953125],
              [-24.70068359375, 72.50634765625],
              [-24.8369140625, 72.47333984375],
              [-25.128027343749977, 72.419189453125],
              [-25.203710937499977, 72.39296875],
              [-25.117871093749983, 72.34697265625],
              [-24.844189453124983, 72.39033203125],
              [-24.666845703124977, 72.437353515625],
              [-24.572363281249977, 72.42021484375],
              [-24.417187499999983, 72.3482421875],
              [-24.242285156249977, 72.311328125],
              [-23.797705078124977, 72.200732421875],
              [-23.587109374999983, 72.139794921875],
              [-23.290917968749994, 72.081005859375],
              [-22.955761718749983, 71.9994140625],
              [-22.868505859374977, 71.970654296875],
              [-22.562158203124994, 71.928271484375],
              [-22.496875, 71.913818359375],
              [-22.370214843749977, 71.76982421875],
              [-22.264501953124977, 71.75380859375],
              [-21.959667968749983, 71.744677734375],
              [-22.013330078124994, 71.688818359375],
              [-22.31103515625, 71.56455078125],
              [-22.464990234374994, 71.52490234375],
              [-22.50322265624999, 71.500439453125],
              [-22.48857421874999, 71.456689453125],
              [-22.479638671874994, 71.383447265625],
              [-22.417578124999977, 71.248681640625],
              [-22.347753906249977, 71.373486328125],
              [-22.299023437499983, 71.43232421875],
              [-22.233789062499994, 71.449951171875],
              [-22.169580078124994, 71.4525390625],
              [-21.96142578125, 71.508203125],
              [-21.75224609374999, 71.4783203125],
              [-21.697949218749983, 71.337451171875],
              [-21.67119140624999, 71.20595703125],
              [-21.689648437499983, 71.0923828125],
              [-21.666601562499977, 70.915869140625],
              [-21.674511718749983, 70.856298828125],
              [-21.625146484374994, 70.804638671875],
              [-21.573925781249983, 70.590478515625],
              [-21.522656249999983, 70.526220703125],
              [-21.62553710937499, 70.4685546875],
              [-21.943505859374994, 70.44345703125],
              [-22.069287109374983, 70.471875],
              [-22.384130859374977, 70.46240234375],
              [-22.384521484375, 70.513134765625],
              [-22.399853515624983, 70.5712890625],
              [-22.401123046875, 70.6119140625],
              [-22.422119140625, 70.648681640625],
              [-22.43701171875, 70.860009765625],
              [-22.526074218749983, 70.8078125],
              [-22.531347656249977, 70.764990234375],
              [-22.555029296874977, 70.721435546875],
              [-22.60966796874999, 70.493310546875],
              [-22.690673828125, 70.4373046875],
              [-22.942578124999983, 70.45078125],
              [-23.190625, 70.44248046875],
              [-23.327832031249983, 70.4509765625],
              [-23.79179687499999, 70.55517578125],
              [-23.971386718749983, 70.649462890625],
              [-24.13037109375, 70.791064453125],
              [-24.228515625, 70.923388671875],
              [-24.265722656249977, 71.046337890625],
              [-24.37700195312499, 71.14638671875],
              [-24.562207031249983, 71.22353515625],
              [-24.781005859375, 71.286083984375],
              [-25.033398437499983, 71.333935546875],
              [-25.254980468749977, 71.395703125],
              [-25.44580078125, 71.471240234375],
              [-25.655859374999977, 71.530029296875],
              [-25.88515625, 71.571923828125],
              [-26.21142578125, 71.58994140625],
              [-26.68852539062499, 71.583349609375],
              [-27.010644531249994, 71.63056640625],
              [-27.08720703124999, 71.6265625],
              [-27.162304687499983, 71.602197265625],
              [-27.10703125, 71.532666015625],
              [-26.737207031249994, 71.50078125],
              [-26.452001953125006, 71.493505859375],
              [-26.074072265624977, 71.498046875],
              [-25.842724609374983, 71.48017578125],
              [-25.7578125, 71.43994140625],
              [-25.699414062499983, 71.368310546875],
              [-25.667578124999977, 71.26533203125],
              [-25.74223632812499, 71.18359375],
              [-26.014111328124983, 71.092822265625],
              [-26.15751953124999, 71.05029296875],
              [-26.57597656249999, 70.968701171875],
              [-26.717919921874994, 70.95048828125],
              [-27.067333984374983, 70.944921875],
              [-27.335693359375, 70.952783203125],
              [-27.68876953124999, 70.99345703125],
              [-27.888916015625, 71.001708984375],
              [-28.303125, 71.007177734375],
              [-28.3984375, 70.992919921875],
              [-28.29155273437499, 70.94931640625],
              [-28.115869140624994, 70.924609375],
              [-27.9921875, 70.89521484375],
              [-27.97929687499999, 70.839501953125],
              [-28.023876953124983, 70.756787109375],
              [-28.06987304687499, 70.6990234375],
              [-28.145654296874994, 70.6556640625],
              [-28.41748046875, 70.57353515625],
              [-28.53007812499999, 70.54755859375],
              [-29.036816406249983, 70.4615234375],
              [-29.07207031249999, 70.444970703125],
              [-28.953466796875006, 70.447216796875],
              [-28.63310546874999, 70.477783203125],
              [-28.540917968749994, 70.476904296875],
              [-28.015039062499994, 70.40224609375],
              [-27.59609375, 70.406689453125],
              [-26.747265624999983, 70.475537109375],
              [-26.677490234375, 70.47421875],
              [-26.621777343749983, 70.46337890625],
              [-26.5654296875, 70.437548828125],
              [-26.508398437500006, 70.396630859375],
              [-26.576806640624994, 70.357080078125],
              [-26.770654296874994, 70.318896484375],
              [-27.072509765625, 70.281201171875],
              [-27.203222656250006, 70.255712890625],
              [-27.328125, 70.217138671875],
              [-27.56083984374999, 70.124462890625],
              [-27.628857421874983, 70.02822265625],
              [-27.384179687499994, 69.9916015625],
              [-27.27421874999999, 70.037939453125],
              [-27.144482421874983, 70.1408203125],
              [-27.027734375, 70.201220703125],
              [-26.752148437499983, 70.2421875],
              [-26.415673828124994, 70.221337890625],
              [-26.155712890624983, 70.24560546875],
              [-25.624853515624977, 70.34697265625],
              [-25.529882812499977, 70.353173828125],
              [-24.74882812499999, 70.295068359375],
              [-24.041015625, 70.181201171875],
              [-23.667333984374977, 70.139306640625],
              [-23.173242187499994, 70.114599609375],
              [-22.28447265624999, 70.125830078125],
              [-22.20659179687499, 70.10791015625],
              [-22.235449218749977, 70.067578125],
              [-22.287060546874983, 70.0333984375],
              [-22.43510742187499, 69.9857421875],
              [-22.614941406249983, 69.954248046875],
              [-22.726220703124994, 69.945361328125],
              [-22.820898437499977, 69.9228515625],
              [-23.033642578124983, 69.900830078125],
              [-23.088232421874977, 69.882958984375],
              [-23.014550781249994, 69.804833984375],
              [-23.049560546875, 69.792724609375],
              [-23.236962890624994, 69.791455078125],
              [-23.55253906249999, 69.74052734375],
              [-23.811621093749977, 69.744189453125],
              [-23.86572265625, 69.73671875],
              [-23.816552734374994, 69.717822265625],
              [-23.764257812499977, 69.68134765625],
              [-23.708984375, 69.62724609375],
              [-23.739404296874994, 69.588623046875],
              [-23.855566406249977, 69.565576171875],
              [-23.94365234374999, 69.558056640625],
              [-24.247509765624983, 69.590380859375],
              [-24.29667968749999, 69.585546875],
              [-24.252294921874977, 69.562353515625],
              [-24.22705078125, 69.526953125],
              [-24.220898437499983, 69.479296875],
              [-24.295556640624994, 69.439306640625],
              [-24.451074218749994, 69.407080078125],
              [-24.740576171874977, 69.318408203125],
              [-24.866601562499994, 69.29306640625],
              [-25.132519531249983, 69.272119140625],
              [-25.188574218749977, 69.260546875],
              [-25.08046875, 69.19248046875],
              [-25.092431640624994, 69.165185546875],
              [-25.272216796875, 69.0916015625],
              [-25.544042968749977, 69.045703125],
              [-25.581152343749977, 69.020947265625],
              [-25.606347656249994, 68.954443359375],
              [-25.626123046874994, 68.927978515625],
              [-25.697998046875, 68.889892578125],
              [-25.95585937499999, 68.81728515625],
              [-26.138623046874983, 68.78115234375],
              [-26.229248046875, 68.7515625],
              [-26.341406249999977, 68.7021484375],
              [-26.48291015625, 68.675927734375],
              [-26.653710937499994, 68.6728515625],
              [-26.815332031249994, 68.654345703125],
              [-27.081152343750006, 68.601806640625],
              [-27.266259765624994, 68.584326171875],
              [-27.851220703124994, 68.493505859375],
              [-28.12646484375, 68.47900390625],
              [-28.36455078124999, 68.446533203125],
              [-28.854345703125006, 68.359814453125],
              [-29.08769531249999, 68.33193359375],
              [-29.24951171875, 68.298779296875],
              [-29.426220703124983, 68.289306640625],
              [-29.713574218749983, 68.31083984375],
              [-29.868505859375006, 68.311572265625],
              [-29.963769531249994, 68.29853515625],
              [-30.051123046875006, 68.271923828125],
              [-30.195507812499983, 68.198974609375],
              [-30.318115234375, 68.193310546875],
              [-30.72001953124999, 68.251171875],
              [-30.711865234374983, 68.224951171875],
              [-30.605664062499983, 68.162353515625],
              [-30.610742187499994, 68.117919921875],
              [-30.849755859374994, 68.0728515625],
              [-30.97856445312499, 68.061328125],
              [-31.16845703125, 68.079833984375],
              [-31.41948242187499, 68.128466796875],
              [-31.74199218749999, 68.22998046875],
              [-32.13725585937499, 68.384912109375],
              [-32.32744140624999, 68.4373046875],
              [-32.31367187499998, 68.38759765625],
              [-32.269628906250006, 68.339013671875],
              [-32.195214843749994, 68.291650390625],
              [-32.18012695312498, 68.257275390625],
              [-32.22421875, 68.235986328125],
              [-32.282373046874994, 68.225244140625],
              [-32.354589843750006, 68.22509765625],
              [-32.366845703124994, 68.213037109375],
              [-32.248925781249994, 68.139111328125],
              [-32.15595703124998, 68.06318359375],
              [-32.16455078125, 67.99111328125],
              [-32.274804687499994, 67.9228515625],
              [-32.36953125, 67.882763671875],
              [-32.44873046875, 67.870947265625],
              [-32.91801757812499, 67.70068359375],
              [-33.048730468749994, 67.679248046875],
              [-33.108154296875, 67.658203125],
              [-33.156982421875, 67.626708984375],
              [-33.293603515624994, 67.4857421875],
              [-33.348876953125, 67.442724609375],
              [-33.45849609375, 67.38671875],
              [-33.50444335937499, 67.377001953125],
              [-33.517578125, 67.35419921875],
              [-33.49775390624998, 67.3181640625],
              [-33.527978515624994, 67.258154296875],
              [-33.60820312499999, 67.17421875],
              [-33.88134765625, 66.94228515625],
              [-34.101660156250006, 66.725927734375],
              [-34.1982421875, 66.655078125],
              [-34.268896484375006, 66.625048828125],
              [-34.313623046874994, 66.635791015625],
              [-34.4228515625, 66.63017578125],
              [-34.47587890624999, 66.592138671875],
              [-34.52392578125, 66.52333984375],
              [-34.576269531250006, 66.4708984375],
              [-34.6328125, 66.434765625],
              [-35.07465820312498, 66.279150390625],
              [-35.188574218750006, 66.25029296875],
              [-35.290869140625006, 66.2685546875],
              [-35.41171875, 66.2615234375],
              [-35.66206054687498, 66.34375],
              [-35.70546875, 66.373974609375],
              [-35.86723632812499, 66.44140625],
              [-35.86186523437499, 66.40625],
              [-35.834716796875, 66.386865234375],
              [-35.812109375, 66.3583984375],
              [-35.755517578124994, 66.32353515625],
              [-35.63007812499998, 66.13994140625],
              [-35.72929687499999, 66.10224609375],
              [-35.81791992187499, 66.059228515625],
              [-36.044189453125, 65.98662109375],
              [-36.288720703124994, 65.86484375],
              [-36.37919921874999, 65.830810546875],
              [-36.39921874999999, 65.930078125],
              [-36.38896484374999, 65.959716796875],
              [-36.527246093749994, 66.00771484375],
              [-36.52275390624999, 65.97314453125],
              [-36.537011718749994, 65.940869140625],
              [-36.63725585937499, 65.8123046875],
              [-36.665185546874994, 65.790087890625],
              [-36.714501953124994, 65.795068359375],
              [-36.822167968749994, 65.771337890625],
              [-36.93242187499999, 65.782568359375],
              [-37.02587890625, 65.84111328125],
              [-37.06279296874999, 65.871435546875],
              [-37.23320312499999, 65.7880859375],
              [-37.316015625, 65.790234375],
              [-37.329833984375, 65.720166015625],
              [-37.410058593749994, 65.65634765625],
              [-37.51606445312498, 65.6287109375],
              [-37.66376953124998, 65.630859375],
              [-37.75419921874999, 65.59306640625],
              [-37.95478515624998, 65.63359375],
              [-38.00126953124999, 65.709619140625],
              [-37.84228515625, 65.813818359375],
              [-37.79736328125, 65.856787109375],
              [-37.826513671875006, 65.90966796875],
              [-37.787841796875, 65.977978515625],
              [-37.484472656250006, 66.19462890625],
              [-37.278710937499994, 66.30439453125],
              [-37.290673828124994, 66.32392578125],
              [-37.569921875, 66.3478515625],
              [-37.81391601562498, 66.385498046875],
              [-38.051660156249994, 66.3984375],
              [-38.156640625, 66.385595703125],
              [-37.989160156249994, 66.32265625],
              [-37.75234375, 66.2615234375],
              [-37.868896484375, 66.203125],
              [-37.96943359374998, 66.14111328125],
              [-38.07343749999998, 65.97255859375],
              [-38.13994140624999, 65.903515625],
              [-38.39814453124998, 65.982861328125],
              [-38.520361328125006, 66.00966796875],
              [-38.44267578124999, 65.9216796875],
              [-38.21635742187499, 65.838330078125],
              [-38.20185546874998, 65.810888671875],
              [-38.203369140625, 65.71171875],
              [-38.63671875, 65.624365234375],
              [-39.088964843750006, 65.6111328125],
              [-39.41337890624999, 65.586279296875],
              [-39.9609375, 65.556201171875],
              [-40.17353515624998, 65.55615234375],
              [-40.191552734374994, 65.522509765625],
              [-39.65595703124998, 65.368896484375],
              [-39.57792968749999, 65.340771484375],
              [-39.65253906249998, 65.287841796875],
              [-39.76318359375, 65.254931640625],
              [-39.937255859375, 65.1416015625],
              [-40.02802734374998, 65.1025390625],
              [-40.253125, 65.048876953125],
              [-40.667578125, 65.108740234375],
              [-40.88056640624998, 65.081982421875],
              [-41.08442382812498, 65.100830078125],
              [-41.08867187499999, 65.0353515625],
              [-41.027734375, 64.987548828125],
              [-40.96601562499998, 64.86884765625],
              [-40.82929687499998, 64.878076171875],
              [-40.65546874999998, 64.91533203125],
              [-40.52109375, 64.82548828125],
              [-40.432714843750006, 64.673193359375],
              [-40.278417968750006, 64.595947265625],
              [-40.209863281249994, 64.536279296875],
              [-40.182226562500006, 64.479931640625],
              [-40.278466796874994, 64.423828125],
              [-40.477636718750006, 64.34443359375],
              [-40.69853515624999, 64.329736328125],
              [-40.686425781249994, 64.266943359375],
              [-40.78173828125, 64.22177734375],
              [-40.98457031249998, 64.235009765625],
              [-41.07939453124999, 64.26650390625],
              [-41.177734375, 64.2814453125],
              [-41.58100585937498, 64.29833984375],
              [-41.175, 64.177392578125],
              [-41.03056640624999, 64.121044921875],
              [-40.96630859375, 64.154443359375],
              [-40.82568359375, 64.162548828125],
              [-40.617773437500006, 64.13173828125],
              [-40.65234375, 63.927734375],
              [-40.561279296875, 63.762353515624994],
              [-40.550390625, 63.725244140624994],
              [-40.77153320312499, 63.626171875],
              [-40.77519531249999, 63.533642578125],
              [-40.906835937500006, 63.507861328125],
              [-41.048730468749994, 63.513818359374994],
              [-41.05615234375, 63.412255859374994],
              [-41.152246093749994, 63.34892578125],
              [-41.13520507812498, 63.30927734375],
              [-41.10771484374999, 63.273779296875],
              [-41.195458984374994, 63.209228515625],
              [-41.27470703124999, 63.1306640625],
              [-41.38789062499998, 63.061865234375],
              [-41.447851562500006, 63.0689453125],
              [-41.6279296875, 63.064501953125],
              [-41.844482421875, 63.070263671875],
              [-42.01972656249998, 63.159619140625],
              [-42.092382812500006, 63.189355468749994],
              [-42.17451171874998, 63.2087890625],
              [-42.14296875, 63.151318359375],
              [-42.093994140625, 63.116748046875],
              [-41.932275390624994, 63.05224609375],
              [-41.63447265624998, 62.9724609375],
              [-41.64360351562499, 62.915869140625],
              [-41.723242187500006, 62.891259765624994],
              [-41.90898437499999, 62.737109375],
              [-41.97490234374999, 62.733789062499994],
              [-42.058251953124994, 62.693994140624994],
              [-42.315625, 62.70732421875],
              [-42.423730468749994, 62.72314453125],
              [-42.741113281249994, 62.713037109374994],
              [-42.84907226562498, 62.72666015625],
              [-42.941650390625, 62.72021484375],
              [-42.85527343749999, 62.676708984375],
              [-42.673681640625006, 62.6375],
              [-42.467138671875006, 62.598193359375],
              [-42.152978515624994, 62.56845703125],
              [-42.16435546874999, 62.51220703125],
              [-42.2431640625, 62.466064453125],
              [-42.19794921874998, 62.397119140624994],
              [-42.23310546874998, 62.347705078125],
              [-42.248144531250006, 62.2890625],
              [-42.32148437499998, 62.152734375],
              [-42.23613281249999, 62.0591796875],
              [-42.14306640625, 62.013525390625],
              [-42.15385742187499, 61.95341796875],
              [-42.110205078125006, 61.8572265625],
              [-42.24970703124998, 61.771386718749994],
              [-42.36542968749998, 61.774609375],
              [-42.530419921874994, 61.755322265625],
              [-42.585302734375006, 61.71748046875],
              [-42.323632812499994, 61.68173828125],
              [-42.34736328124998, 61.617431640625],
              [-42.41875, 61.537011718749994],
              [-42.49375, 61.36279296875],
              [-42.64599609375, 61.064111328124994],
              [-42.717041015625, 60.767480468749994],
              [-43.044091796874994, 60.523681640625],
              [-43.15996093749999, 60.516943359375],
              [-43.1890625, 60.507275390625],
              [-43.34833984374998, 60.519775390625],
              [-43.59833984374998, 60.576025390625],
              [-43.7919921875, 60.594580078125],
              [-43.922705078125006, 60.595361328124994],
              [-43.939550781250006, 60.5673828125],
              [-43.831152343750006, 60.52197265625],
              [-43.66547851562498, 60.502978515625],
              [-43.533056640625006, 60.472998046875],
              [-43.295654296875, 60.444970703124994],
              [-43.212988281250006, 60.390673828125],
              [-43.156494140625, 60.332861328125],
              [-43.16484374999999, 60.301025390625],
              [-43.16533203124999, 60.263427734375],
              [-43.122900390625006, 60.06123046875],
              [-43.23481445312498, 59.99130859375],
              [-43.32011718749999, 59.928125],
              [-43.61689453124998, 59.936914062499994],
              [-43.66850585937499, 59.958935546875],
              [-43.95502929687498, 60.02548828125],
              [-43.937402343749994, 59.99423828125],
              [-43.730126953124994, 59.903759765625],
              [-43.65791015624998, 59.858642578125],
              [-43.706201171874994, 59.84931640625],
              [-43.78984374999999, 59.845947265625],
              [-43.90654296874999, 59.815478515625],
              [-44.11699218749999, 59.831933593749994],
              [-44.10541992187498, 59.877734375],
              [-44.06547851562499, 59.9248046875],
              [-44.16171875, 59.916796875],
              [-44.268945312499994, 59.892919921875],
              [-44.33125, 59.901708984375],
              [-44.38359374999999, 59.899072265624994],
              [-44.412939453125006, 59.922607421875],
              [-44.453466796875006, 60.014550781249994],
              [-44.404931640624994, 60.060791015625],
              [-44.231347656249994, 60.1802734375],
              [-44.176123046875006, 60.244384765625],
              [-44.224365234375, 60.27353515625],
              [-44.34833984374998, 60.20478515625],
              [-44.47636718749999, 60.0955078125],
              [-44.53315429687498, 60.0294921875],
              [-44.61328125, 60.016650390625],
              [-44.81220703124998, 60.04990234375],
              [-45.379248046875006, 60.2029296875],
              [-45.36235351562499, 60.295947265625],
              [-45.367773437500006, 60.372949218749994],
              [-45.202490234375006, 60.382714843749994],
              [-45.082275390625, 60.4162109375],
              [-44.974707031250006, 60.4572265625],
              [-44.853515625, 60.53193359375],
              [-44.742431640625, 60.6552734375],
              [-44.756738281249994, 60.664599609375],
              [-45.08271484374998, 60.507177734375],
              [-45.283300781250006, 60.454541015625],
              [-45.380517578124994, 60.444921875],
              [-45.428857421874994, 60.46826171875],
              [-45.59028320312498, 60.51884765625],
              [-45.695214843749994, 60.541845703125],
              [-45.934326171875, 60.579443359375],
              [-45.97651367187498, 60.59970703125],
              [-46.046630859375, 60.61572265625],
              [-46.141943359375006, 60.776513671874994],
              [-46.018652343750006, 60.97177734375],
              [-45.933740234374994, 61.02841796875],
              [-45.8798828125, 61.094140625],
              [-45.84941406249999, 61.18115234375],
              [-45.870214843750006, 61.218310546875],
              [-45.942285156249994, 61.20556640625],
              [-45.975683593750006, 61.17578125],
              [-45.97041015624998, 61.12919921875],
              [-46.01171875, 61.096826171874994],
              [-46.29667968749999, 61.022363281249994],
              [-46.582421875, 60.962060546874994],
              [-46.7177734375, 60.904931640624994],
              [-46.8056640625, 60.860302734375],
              [-46.87446289062498, 60.81640625],
              [-46.97968749999998, 60.820361328125],
              [-47.124853515625006, 60.811328125],
              [-47.22441406249999, 60.782861328124994],
              [-47.369726562500006, 60.800341796875],
              [-47.46464843749999, 60.842626953125],
              [-47.57905273437498, 60.8474609375],
              [-47.70747070312498, 60.827099609375],
              [-47.796240234375006, 60.828857421875],
              [-47.78886718749999, 60.800146484375],
              [-47.72993164062498, 60.7294921875],
              [-47.82792968749999, 60.724755859374994],
              [-48.01396484374999, 60.72197265625],
              [-48.107519531250006, 60.742431640625],
              [-48.180810546874994, 60.76923828125],
              [-48.241943359375, 60.8068359375],
              [-48.205175781250006, 60.855908203125],
              [-47.90595703124998, 60.945751953125],
              [-47.77031249999999, 60.99775390625],
              [-47.858789062499994, 61.015673828125],
              [-48.146142578124994, 60.999462890625],
              [-48.193945312500006, 61.012939453125],
              [-48.38642578124998, 61.004736328125],
              [-48.378125, 61.1384765625],
              [-48.424951171874994, 61.1716796875],
              [-48.42817382812498, 61.187402343749994],
              [-48.49482421874998, 61.22470703125],
              [-48.55791015624999, 61.233984375],
              [-48.59716796875, 61.247412109375],
              [-48.92207031249998, 61.27744140625],
              [-48.964501953124994, 61.352001953125],
              [-48.987207031249994, 61.4287109375],
              [-49.04921875, 61.523876953125],
              [-49.204736328124994, 61.548681640625],
              [-49.2890625, 61.58994140625],
              [-49.222265625, 61.63212890625],
              [-49.193115234375, 61.68564453125],
              [-49.265234375, 61.71005859375],
              [-49.31123046874998, 61.747802734375],
              [-49.30449218749999, 61.772314453125],
              [-49.362890625, 61.838525390624994],
              [-49.380273437499994, 61.890185546875],
              [-49.3134765625, 61.938623046874994],
              [-49.129785156249994, 61.993408203125],
              [-49.070556640625, 62.015478515625],
              [-49.039648437500006, 62.03935546875],
              [-48.828710937500006, 62.0796875],
              [-49.008154296875006, 62.108203125],
              [-49.120458984375006, 62.11259765625],
              [-49.202294921874994, 62.09931640625],
              [-49.277929687500006, 62.045751953125],
              [-49.348535156249994, 62.010205078125],
              [-49.623779296875, 61.998583984375],
              [-49.66425781249998, 62.016943359375],
              [-49.68339843749999, 62.092578125],
              [-49.667724609375, 62.15087890625],
              [-49.553466796875, 62.23271484375],
              [-49.68525390624998, 62.273339843749994],
              [-49.806054687499994, 62.286523437499994],
              [-49.943359375, 62.324462890625],
              [-50.070214843749994, 62.364501953125],
              [-50.17915039062498, 62.4111328125],
              [-50.28520507812499, 62.466210937499994],
              [-50.319238281249994, 62.473193359375],
              [-50.280908203124994, 62.53076171875],
              [-50.25932617187499, 62.578076171875],
              [-50.256005859374994, 62.67978515625],
              [-50.298730468749994, 62.72197265625],
              [-50.203759765624994, 62.8087890625],
              [-50.076025390625006, 62.903759765625],
              [-49.793115234374994, 63.04462890625],
              [-50.09223632812498, 62.9767578125],
              [-50.33833007812498, 62.828759765624994],
              [-50.39008789062498, 62.822021484375],
              [-50.408203125, 62.848828125],
              [-50.5015625, 62.944921875],
              [-50.572021484375, 62.971142578125],
              [-50.603515625, 63.000048828125],
              [-50.743505859375006, 63.05126953125],
              [-50.804296875, 63.090771484375],
              [-50.890478515625006, 63.166943359375],
              [-51.013085937499994, 63.257568359375],
              [-51.187597656250006, 63.436425781249994],
              [-51.468847656250006, 63.64228515625],
              [-51.538183593750006, 63.7580078125],
              [-51.451074218749994, 63.90478515625],
              [-51.547509765624994, 64.006103515625],
              [-51.28007812499999, 64.052978515625],
              [-50.897558593750006, 64.10556640625],
              [-50.699365234374994, 64.149267578125],
              [-50.58500976562499, 64.162353515625],
              [-50.341894531250006, 64.170361328125],
              [-50.26069335937498, 64.2142578125],
              [-50.39594726562498, 64.203173828125],
              [-50.48662109374999, 64.20888671875],
              [-50.492285156250006, 64.229345703125],
              [-50.458740234375, 64.2658203125],
              [-50.43706054687499, 64.312841796875],
              [-50.4833984375, 64.304345703125],
              [-50.721044921875006, 64.22333984375],
              [-51.07231445312499, 64.159033203125],
              [-51.3466796875, 64.123095703125],
              [-51.39111328125, 64.125],
              [-51.48710937499999, 64.103271484375],
              [-51.54228515624999, 64.097021484375],
              [-51.58491210937498, 64.103173828125],
              [-51.68203125, 64.16474609375],
              [-51.707861328125006, 64.205078125],
              [-51.533789062500006, 64.314208984375],
              [-51.40375976562498, 64.46318359375],
              [-51.231542968750006, 64.560595703125],
              [-51.10991210937499, 64.572802734375],
              [-50.90654296874999, 64.567578125],
              [-50.83491210937498, 64.558984375],
              [-50.85771484374999, 64.616796875],
              [-50.84921875, 64.644677734375],
              [-50.684326171875, 64.678173828125],
              [-50.492089843749994, 64.6931640625],
              [-50.355126953124994, 64.682568359375],
              [-50.268945312499994, 64.61474609375],
              [-50.158203125, 64.48955078125],
              [-50.00898437499998, 64.447265625],
              [-50.015527343749994, 64.507421875],
              [-50.09296874999998, 64.584912109375],
              [-50.12163085937499, 64.703759765625],
              [-50.21992187499998, 64.753857421875],
              [-50.29887695312499, 64.778564453125],
              [-50.516992187499994, 64.76650390625],
              [-50.64814453124998, 64.8533203125],
              [-50.677880859374994, 64.885205078125],
              [-50.681298828124994, 64.9275390625],
              [-50.812158203124994, 65.05185546875],
              [-50.854248046875, 65.11396484375],
              [-50.923730468749994, 65.196728515625],
              [-50.96064453124998, 65.201123046875],
              [-50.913720703124994, 65.09697265625],
              [-50.85234374999999, 65.023681640625],
              [-50.76484374999998, 64.862548828125],
              [-50.721582031249994, 64.797607421875],
              [-50.780175781249994, 64.746142578125],
              [-50.89106445312498, 64.69521484375],
              [-50.98906249999999, 64.66484375],
              [-51.220605468749994, 64.628466796875],
              [-51.17084960937498, 64.707763671875],
              [-51.13896484374999, 64.7857421875],
              [-51.25537109375, 64.75810546875],
              [-51.363623046875006, 64.7015625],
              [-51.40092773437499, 64.623095703125],
              [-51.470458984375, 64.551806640625],
              [-51.6767578125, 64.37705078125],
              [-51.75810546874999, 64.279931640625],
              [-51.8349609375, 64.231982421875],
              [-51.922607421875, 64.21875],
              [-51.998681640624994, 64.256787109375],
              [-52.06318359375, 64.34609375],
              [-52.093408203124994, 64.41591796875],
              [-52.09702148437499, 64.5970703125],
              [-52.0888671875, 64.68154296875],
              [-52.1240234375, 64.79541015625],
              [-52.23544921874999, 65.060546875],
              [-52.259033203125, 65.154931640625],
              [-52.44760742187499, 65.205126953125],
              [-52.450341796874994, 65.221337890625],
              [-52.49970703125, 65.275048828125],
              [-52.53769531249999, 65.32880859375],
              [-52.50625, 65.348486328125],
              [-52.46142578125, 65.3626953125],
              [-52.179589843749994, 65.441943359375],
              [-51.970703125, 65.530712890625],
              [-51.72109375, 65.669921875],
              [-51.619140625, 65.71318359375],
              [-51.2529296875, 65.746484375],
              [-51.09038085937499, 65.751025390625],
              [-51.091894531250006, 65.77578125],
              [-51.146386718749994, 65.78564453125],
              [-51.393798828125, 65.779150390625],
              [-51.72343749999999, 65.723486328125],
              [-51.779882812500006, 65.70341796875],
              [-51.92412109374999, 65.616796875],
              [-52.0353515625, 65.569482421875],
              [-52.34824218749999, 65.461328125],
              [-52.55126953125, 65.461376953125],
              [-52.7609375, 65.5908203125],
              [-52.99492187499999, 65.566015625],
              [-53.15292968749999, 65.574560546875],
              [-53.198974609375, 65.59404296875],
              [-53.23374023437499, 65.770849609375],
              [-53.106347656249994, 65.9771484375],
              [-53.24375, 65.979052734375],
              [-53.272216796875, 65.98740234375],
              [-53.3447265625, 66.034375],
              [-53.392041015625, 66.04833984375],
              [-53.35693359375, 66.073291015625],
              [-53.01787109374999, 66.1708984375],
              [-52.510888671874994, 66.36240234375],
              [-52.292626953124994, 66.437646484375],
              [-52.15791015625, 66.4701171875],
              [-52.056103515625, 66.50732421875],
              [-51.93217773437499, 66.587890625],
              [-51.891210937500006, 66.62314453125],
              [-51.822119140625006, 66.6515625],
              [-51.676367187500006, 66.68359375],
              [-51.51708984375, 66.73203125],
              [-51.25859374999999, 66.8412109375],
              [-51.225, 66.88154296875],
              [-51.28105468749999, 66.890966796875],
              [-51.401953125, 66.853759765625],
              [-51.647705078125, 66.75400390625],
              [-51.82304687499999, 66.6978515625],
              [-52.42124023437499, 66.4466796875],
              [-52.67587890624999, 66.355224609375],
              [-52.814453125, 66.296875],
              [-52.92192382812499, 66.24111328125],
              [-53.035791015624994, 66.201416015625],
              [-53.15605468749999, 66.177734375],
              [-53.412744140624994, 66.1599609375],
              [-53.53876953125, 66.13935546875],
              [-53.614697265625, 66.1544921875],
              [-53.648095703124994, 66.27353515625],
              [-53.62260742187499, 66.34404296875],
              [-53.634716796875, 66.413671875],
              [-53.570703125, 66.51328125],
              [-53.47568359374999, 66.583837890625],
              [-53.435791015625, 66.62216796875],
              [-53.41875, 66.64853515625],
              [-53.22270507812499, 66.721435546875],
              [-53.114648437499994, 66.75380859375],
              [-53.03828125, 66.826806640625],
              [-52.603125, 66.852734375],
              [-52.49106445312499, 66.850146484375],
              [-52.43144531249999, 66.859912109375],
              [-52.386865234374994, 66.88115234375],
              [-52.42973632812499, 66.89755859375],
              [-52.56010742187499, 66.90908203125],
              [-52.906689453125, 66.906884765625],
              [-53.226953125, 66.919384765625],
              [-53.37309570312499, 66.93193359375],
              [-53.443603515625, 66.924658203125],
              [-53.560009765625, 66.945947265625],
              [-53.687158203124994, 66.986474609375],
              [-53.884423828124994, 67.135546875],
              [-53.80546874999999, 67.326904296875],
              [-53.798583984375, 67.4181640625],
              [-53.54790039062499, 67.498193359375],
              [-53.41386718749999, 67.52470703125],
              [-53.223583984375, 67.5849609375],
              [-52.96953124999999, 67.687255859375],
              [-52.666455078125, 67.74970703125],
              [-52.51201171874999, 67.761279296875],
              [-52.38359374999999, 67.75234375],
              [-51.909082031249994, 67.663720703125],
              [-51.6650390625, 67.64638671875],
              [-51.450585937499994, 67.667724609375],
              [-51.18144531249999, 67.6365234375],
              [-50.70537109374999, 67.50888671875],
              [-50.61347656249998, 67.5279296875],
              [-50.64013671875, 67.558837890625],
              [-51.171044921874994, 67.693603515625],
              [-51.16796875, 67.733837890625],
              [-51.032080078125006, 67.744384765625],
              [-50.88701171874999, 67.783544921875],
              [-50.968847656250006, 67.806640625],
              [-51.32148437499998, 67.786572265625],
              [-51.423242187499994, 67.7544921875],
              [-51.765234375, 67.737841796875],
              [-51.94384765625, 67.765185546875],
              [-52.10419921875, 67.7787109375],
              [-52.34482421874999, 67.8369140625],
              [-52.54619140624999, 67.817919921875],
              [-52.673242187499994, 67.794970703125],
              [-52.898339843749994, 67.7732421875],
              [-52.97958984374999, 67.757763671875],
              [-53.41879882812499, 67.574560546875],
              [-53.60361328124999, 67.536474609375],
              [-53.73520507812499, 67.5490234375],
              [-53.642822265625, 67.66826171875],
              [-53.6162109375, 67.71533203125],
              [-53.616357421874994, 67.7666015625],
              [-53.57797851562499, 67.83681640625],
              [-53.352929687499994, 67.9705078125],
              [-53.211376953125, 68.116943359375],
              [-53.1515625, 68.207763671875],
              [-53.040966796875, 68.217919921875],
              [-52.88984375, 68.204541015625],
              [-52.43608398437499, 68.145654296875],
              [-52.058496093749994, 68.07548828125],
              [-51.77998046874998, 68.05673828125],
              [-51.596875, 68.05478515625],
              [-51.51835937499999, 68.0771484375],
              [-51.45649414062498, 68.116064453125],
              [-51.43266601562499, 68.143017578125],
              [-51.414697265624994, 68.198193359375],
              [-51.393701171874994, 68.2177734375],
              [-51.33251953125, 68.241845703125],
              [-51.207275390625, 68.325537109375],
              [-51.16914062499998, 68.385205078125],
              [-51.21015624999998, 68.419921875],
              [-51.29345703125, 68.416357421875],
              [-51.45610351562499, 68.393505859375],
              [-51.47802734375, 68.383984375],
              [-51.47504882812498, 68.365380859375],
              [-51.632421875, 68.273046875],
              [-51.80400390624999, 68.251806640625],
              [-52.19853515624999, 68.22080078125],
              [-52.378515625, 68.218603515625],
              [-52.698388671874994, 68.2615234375],
              [-52.74677734375, 68.278369140625],
              [-52.780029296875, 68.30986328125],
              [-53.172509765624994, 68.302734375],
              [-53.28984374999999, 68.29326171875],
              [-53.38315429687499, 68.29736328125],
              [-53.33740234375, 68.3521484375],
              [-53.21328125, 68.41298828125],
              [-53.039453125, 68.610888671875],
              [-52.89384765624999, 68.6615234375],
              [-52.60458984374999, 68.708740234375],
              [-52.30278320312499, 68.701123046875],
              [-51.780664062499994, 68.548193359375],
              [-51.623144531250006, 68.534814453125],
              [-51.47871093749998, 68.54716796875],
              [-51.13330078125, 68.5984375],
              [-51.069921875, 68.619189453125],
              [-50.945703125, 68.682666015625],
              [-50.800634765625006, 68.791259765625],
              [-50.807714843750006, 68.8169921875],
              [-51.03022460937498, 68.756298828125],
              [-51.14887695312498, 68.73994140625],
              [-51.249414062499994, 68.73994140625],
              [-51.15605468749999, 68.938427734375],
              [-51.119726562500006, 69.09052734375],
              [-51.084863281249994, 69.128271484375],
              [-50.79228515624999, 69.116845703125],
              [-50.392675781250006, 69.13740234375],
              [-50.29736328125, 69.17060546875],
              [-50.29887695312499, 69.1853515625],
              [-50.459375, 69.205517578125],
              [-50.53662109375, 69.2478515625],
              [-50.671044921874994, 69.23447265625],
              [-50.85107421875, 69.20625],
              [-51.07695312499999, 69.20947265625],
              [-51.05781249999998, 69.2748046875],
              [-50.892236328124994, 69.411767578125],
              [-50.87519531249998, 69.47421875],
              [-50.81059570312499, 69.5990234375],
              [-50.804101562499994, 69.663037109375],
              [-50.72026367187499, 69.725341796875],
              [-50.459082031250006, 69.7697265625],
              [-50.349462890625006, 69.796240234375],
              [-50.34345703124998, 69.825244140625],
              [-50.5, 69.935791015625],
              [-50.46025390624999, 69.96630859375],
              [-50.3375, 69.994140625],
              [-50.29169921874998, 70.014453125],
              [-50.32294921874998, 70.0271484375],
              [-50.43608398437499, 70.03935546875],
              [-50.60986328125, 70.01494140625],
              [-50.80234375, 70.00322265625],
              [-50.97270507812499, 70.039892578125],
              [-51.105712890625, 70.057421875],
              [-51.18994140625, 70.051904296875],
              [-51.418847656249994, 69.989208984375],
              [-51.49907226562499, 69.987158203125],
              [-51.59809570312498, 70.004541015625],
              [-52.254638671875, 70.058935546875],
              [-52.33603515624999, 70.078125],
              [-52.571240234375, 70.172119140625],
              [-52.765039062499994, 70.234130859375],
              [-53.023046875, 70.301904296875],
              [-53.35751953124999, 70.3533203125],
              [-53.768505859375, 70.388525390625],
              [-54.01445312499999, 70.4216796875],
              [-54.135644531249994, 70.468408203125],
              [-54.34331054687499, 70.57119140625],
              [-54.501171875, 70.656884765625],
              [-54.53076171875, 70.699267578125],
              [-54.43798828125, 70.751611328125],
              [-54.34355468749999, 70.789208984375],
              [-54.16582031249999, 70.8201171875],
              [-53.85917968749999, 70.809912109375],
              [-53.69443359374999, 70.79609375],
              [-53.513085937499994, 70.7666015625],
              [-53.37602539062499, 70.76103515625],
              [-53.09130859375, 70.769384765625],
              [-52.801953125, 70.7505859375],
              [-52.63041992187499, 70.729931640625],
              [-52.405224609375, 70.686767578125],
              [-51.783789062500006, 70.50322265625],
              [-51.52446289062499, 70.439453125],
              [-51.41171875, 70.431787109375],
              [-50.946875, 70.363623046875],
              [-50.87236328124999, 70.364892578125],
              [-50.68212890625, 70.396875],
              [-50.66328124999998, 70.417578125],
              [-50.7275390625, 70.43798828125],
              [-50.93266601562499, 70.453857421875],
              [-51.17333984375, 70.5291015625],
              [-51.322851562500006, 70.58876953125],
              [-51.33989257812499, 70.687548828125],
              [-51.320410156250006, 70.74287109375],
              [-51.2828125, 70.768017578125],
              [-51.256591796875, 70.852685546875],
              [-51.39609375, 70.90302734375],
              [-51.493554687499994, 70.918896484375],
              [-51.752685546875, 70.992236328125],
              [-51.774316406249994, 71.01044921875],
              [-51.65009765624998, 71.01904296875],
              [-51.528417968750006, 71.014013671875],
              [-51.26708984375, 70.97685546875],
              [-51.13007812499998, 70.971728515625],
              [-51.030419921874994, 70.986279296875],
              [-51.018945312499994, 71.001318359375],
              [-51.17778320312499, 71.04345703125],
              [-51.37666015624998, 71.11904296875],
              [-51.791894531249994, 71.130126953125],
              [-52.06137695312499, 71.121630859375],
              [-52.23359375, 71.14755859375],
              [-52.416894531249994, 71.189697265625],
              [-52.534570312499994, 71.200439453125],
              [-52.775, 71.1740234375],
              [-52.8966796875, 71.170703125],
              [-53.007568359375, 71.17998046875],
              [-53.11704101562499, 71.312890625],
              [-53.087890625, 71.352734375],
              [-53.002099609374994, 71.369970703125],
              [-52.93730468749999, 71.412841796875],
              [-52.891845703125, 71.457666015625],
              [-52.749414062499994, 71.501513671875],
              [-51.96728515625, 71.59912109375],
              [-51.769921875, 71.671728515625],
              [-51.77861328124999, 71.68291015625],
              [-51.91171875, 71.66943359375],
              [-52.081933593749994, 71.63671875],
              [-52.19580078125, 71.62998046875],
              [-52.65629882812499, 71.672265625],
              [-52.72807617187499, 71.662646484375],
              [-52.91455078125, 71.601904296875],
              [-53.16752929687499, 71.5359375],
              [-53.284082031249994, 71.53994140625],
              [-53.440087890624994, 71.57900390625],
              [-53.46484375, 71.606787109375],
              [-53.476025390625, 71.640185546875],
              [-53.30473632812499, 71.685888671875],
              [-53.24970703125, 71.71015625],
              [-53.1388671875, 71.7751953125],
              [-53.14453125, 71.807421875],
              [-53.33369140625, 71.78974609375],
              [-53.358349609375, 71.81962890625],
              [-53.35527343749999, 71.8708984375],
              [-53.37363281249999, 71.9357421875],
              [-53.4201171875, 71.999755859375],
              [-53.575390625, 72.098046875],
              [-53.639794921874994, 72.12333984375],
              [-53.69287109375, 72.15966796875],
              [-53.80986328124999, 72.292578125],
              [-53.77597656249999, 72.325830078125],
              [-53.672021484374994, 72.351025390625],
              [-53.65214843749999, 72.362646484375],
              [-53.900537109374994, 72.341748046875],
              [-53.927734375, 72.318798828125],
              [-53.88090820312499, 72.2849609375],
              [-53.84746093749999, 72.23984375],
              [-53.827539062499994, 72.183447265625],
              [-53.792871093749994, 72.13408203125],
              [-53.70292968749999, 72.080029296875],
              [-53.63095703124999, 72.051513671875],
              [-53.513671875, 71.97626953125],
              [-53.4625, 71.8935546875],
              [-53.47758789062499, 71.849951171875],
              [-53.56865234374999, 71.80556640625],
              [-53.71542968749999, 71.757666015625],
              [-53.75986328124999, 71.718017578125],
              [-53.7796875, 71.678515625],
              [-53.89409179687499, 71.6419921875],
              [-53.96435546875, 71.6556640625],
              [-54.019921875, 71.657861328125],
              [-53.954296875, 71.59267578125],
              [-53.912060546875, 71.525927734375],
              [-53.96298828124999, 71.458984375],
              [-54.09892578124999, 71.418505859375],
              [-54.17270507812499, 71.41728515625],
              [-54.31772460937499, 71.38447265625],
              [-54.6890625, 71.367236328125],
              [-54.818310546875, 71.37529296875],
              [-55.05537109375, 71.40859375],
              [-55.33642578125, 71.4267578125],
              [-55.447900390624994, 71.47177734375],
              [-55.59404296874999, 71.553515625],
              [-55.66782226562499, 71.6267578125],
              [-55.669335937499994, 71.69150390625],
              [-55.629785156249994, 71.738623046875],
              [-55.54921875, 71.76826171875],
              [-55.45244140624999, 71.957666015625],
              [-55.315576171874994, 72.110693359375],
              [-54.9708984375, 72.268408203125],
              [-54.87260742187499, 72.325439453125],
              [-54.84013671874999, 72.356103515625],
              [-54.840625, 72.37939453125],
              [-54.89633789062499, 72.394189453125],
              [-55.32011718749999, 72.199560546875],
              [-55.581445312499994, 72.178857421875],
              [-55.65947265624999, 72.222607421875],
              [-55.63583984374999, 72.300439453125],
              [-55.589306640625, 72.318505859375],
              [-55.3779296875, 72.3111328125],
              [-55.29570312499999, 72.35439453125],
              [-55.427978515625, 72.419873046875],
              [-55.56875, 72.43701171875],
              [-55.601708984374994, 72.453466796875],
              [-55.456787109375, 72.503271484375],
              [-55.121875, 72.499609375],
              [-55.04624023437499, 72.534423828125],
              [-54.924951171874994, 72.57197265625],
              [-54.79038085937499, 72.6416015625],
              [-54.74003906249999, 72.7001953125],
              [-54.7287109375, 72.75048828125],
              [-54.757714843749994, 72.791064453125],
              [-54.76083984374999, 72.83173828125],
              [-54.737939453124994, 72.872509765625],
              [-54.773095703124994, 72.917578125],
              [-54.8662109375, 72.966845703125],
              [-55.07309570312499, 73.01513671875],
              [-55.133984375, 72.96064453125],
              [-55.1984375, 72.938232421875],
              [-55.28891601562499, 72.933203125],
              [-55.37241210937499, 72.95615234375],
              [-55.45952148437499, 72.964404296875],
              [-55.5451171875, 72.984912109375],
              [-55.633984375, 72.99140625],
              [-55.66855468749999, 73.00791015625],
              [-55.690820312499994, 73.0541015625],
              [-55.692773437499994, 73.112841796875],
              [-55.59228515625, 73.140283203125],
              [-55.45234375, 73.1619140625],
              [-55.35869140624999, 73.2029296875],
              [-55.29716796874999, 73.2623046875],
              [-55.28828125, 73.327099609375],
              [-55.33203125, 73.39736328125],
              [-55.445703125, 73.460498046875],
              [-55.656201171875, 73.399072265625],
              [-55.73886718749999, 73.383984375],
              [-55.75791015624999, 73.4279296875],
              [-55.787060546875, 73.460498046875],
              [-55.87553710937499, 73.504638671875],
              [-55.99199218749999, 73.53681640625],
              [-56.10405273437499, 73.558154296875],
              [-56.10917968749999, 73.590771484375],
              [-56.08261718749999, 73.627490234375],
              [-56.03300781249999, 73.6703125],
              [-55.968408203124994, 73.7595703125],
              [-55.89731445312499, 73.751611328125],
              [-55.83828125, 73.759716796875],
              [-55.87241210937499, 73.833447265625],
              [-55.92949218749999, 73.89541015625],
              [-55.996533203125, 73.930615234375],
              [-55.998925781249994, 73.945947265625],
              [-56.01445312499999, 73.9638671875],
              [-56.06621093749999, 74.007275390625],
              [-56.12421875, 74.0390625],
              [-56.22539062499999, 74.1291015625],
              [-56.298486328124994, 74.163427734375],
              [-56.3921875, 74.181201171875],
              [-56.4931640625, 74.182177734375],
              [-56.655175781249994, 74.158544921875],
              [-56.954296875, 74.131201171875],
              [-57.19111328125, 74.118212890625],
              [-57.23056640624999, 74.12529296875],
              [-57.11210937499999, 74.15947265625],
              [-56.9375, 74.195068359375],
              [-56.70634765624999, 74.219189453125],
              [-56.63896484374999, 74.278369140625],
              [-56.66391601562499, 74.32958984375],
              [-56.654296875, 74.378125],
              [-56.717675781249994, 74.429248046875],
              [-56.656005859375, 74.457568359375],
              [-56.445556640625, 74.486083984375],
              [-56.35029296875, 74.490478515625],
              [-56.25546875, 74.526806640625],
              [-56.52207031249999, 74.614306640625],
              [-56.80131835937499, 74.6716796875],
              [-56.87114257812499, 74.694970703125],
              [-56.932568359375, 74.733349609375],
              [-56.985546875, 74.786767578125],
              [-57.071679687499994, 74.840234375],
              [-57.190869140625, 74.89375],
              [-57.36479492187499, 74.945458984375],
              [-57.81318359375, 75.039990234375],
              [-57.96708984374999, 75.10517578125],
              [-58.108837890625, 75.204931640625],
              [-58.1796875, 75.2474609375],
              [-58.253320312499994, 75.278955078125],
              [-58.56552734374999, 75.352734375],
              [-58.603466796875, 75.385302734375],
              [-58.281201171875, 75.4720703125],
              [-58.249658203124994, 75.506689453125],
              [-58.381298828125, 75.61201171875],
              [-58.51621093749999, 75.6890625],
              [-58.6630859375, 75.71640625],
              [-58.88144531249999, 75.73046875],
              [-59.08159179687499, 75.764697265625],
              [-59.263623046875, 75.818896484375],
              [-59.4453125, 75.85859375],
              [-59.717431640624994, 75.8962890625],
              [-60.172753906249994, 75.993310546875],
              [-60.874609375, 76.09716796875],
              [-61.188232421875, 76.157861328125],
              [-61.37480468749999, 76.17998046875],
              [-61.620849609375, 76.18564453125],
              [-62.0966796875, 76.242333984375],
              [-62.49619140624999, 76.26044921875],
              [-62.74287109375, 76.2521484375],
              [-62.8234375, 76.2615234375],
              [-63.005810546875, 76.319091796875],
              [-63.29130859374999, 76.35205078125],
              [-63.438867187499994, 76.339453125],
              [-63.621972656249994, 76.277880859375],
              [-63.84306640624999, 76.217138671875],
              [-63.960351562499994, 76.208935546875],
              [-64.135205078125, 76.264501953125],
              [-64.22319335937499, 76.3033203125],
              [-64.307275390625, 76.31650390625],
              [-64.38730468749999, 76.30400390625],
              [-64.543408203125, 76.253076171875],
              [-64.69208984375, 76.216259765625],
              [-64.91196289062499, 76.172509765625],
              [-65.087646484375, 76.151513671875],
              [-65.313232421875, 76.14638671875],
              [-65.36992187499999, 76.13056640625],
              [-65.456787109375, 76.129833984375],
              [-65.57373046875, 76.14423828125],
              [-65.68330078125, 76.172705078125],
              [-65.78544921874999, 76.21533203125],
              [-65.875732421875, 76.238330078125],
              [-65.954052734375, 76.24169921875],
              [-66.134033203125, 76.21962890625],
              [-66.361767578125, 76.15478515625],
              [-66.465771484375, 76.13916015625],
              [-66.55322265625, 76.145947265625],
              [-66.65996093749999, 76.166162109375],
              [-66.8740234375, 76.21787109375],
              [-66.992578125, 76.212939453125],
              [-67.07871093749999, 76.19482421875],
              [-67.05478515624999, 76.15185546875],
              [-66.85390625, 76.05],
              [-66.6748046875, 75.977392578125],
              [-66.826171875, 75.968798828125],
              [-68.14873046874999, 76.067041015625],
              [-68.31728515625, 76.090771484375],
              [-68.56064453124999, 76.1501953125],
              [-68.7630859375, 76.18662109375],
              [-69.107568359375, 76.280859375],
              [-69.37290039062499, 76.331884765625],
              [-69.460888671875, 76.371728515625],
              [-69.48408203125, 76.399169921875],
              [-69.399658203125, 76.436279296875],
              [-68.864990234375, 76.561376953125],
              [-68.66074218749999, 76.58662109375],
              [-68.24541015624999, 76.616748046875],
              [-68.147216796875, 76.63564453125],
              [-68.1142578125, 76.650634765625],
              [-68.223388671875, 76.677685546875],
              [-68.76738281249999, 76.668017578125],
              [-69.25205078124999, 76.6861328125],
              [-69.673828125, 76.735888671875],
              [-69.747216796875, 76.752392578125],
              [-69.81865234374999, 76.782763671875],
              [-69.8880859375, 76.82705078125],
              [-69.872216796875, 76.876611328125],
              [-69.77104492187499, 76.9314453125],
              [-69.71171874999999, 76.96904296875],
              [-69.69423828125, 76.989453125],
              [-70.22446289062499, 76.85458984375],
              [-70.44130859375, 76.807373046875],
              [-70.61313476562499, 76.821826171875],
              [-70.73369140624999, 76.844189453125],
              [-70.79282226562499, 76.869091796875],
              [-70.790625, 76.896484375],
              [-70.771240234375, 76.91650390625],
              [-70.73466796874999, 76.92900390625],
              [-71.0150390625, 76.98486328125],
              [-71.14145507812499, 77.028662109375],
              [-71.15488281249999, 77.073876953125],
              [-71.05546874999999, 77.1205078125],
              [-70.95810546874999, 77.154345703125],
              [-70.86284179687499, 77.175439453125],
              [-70.6037109375, 77.19384765625],
              [-69.65654296874999, 77.22900390625],
              [-68.97832031249999, 77.1953125],
              [-68.7474609375, 77.30693359375],
              [-68.59160156249999, 77.342529296875],
              [-68.13554687499999, 77.37958984375],
              [-67.4337890625, 77.38466796875],
              [-66.93798828125, 77.364208984375],
              [-66.70576171875, 77.338037109375],
              [-66.38945312499999, 77.2802734375],
              [-66.3712890625, 77.297705078125],
              [-66.44765625, 77.3498046875],
              [-66.453076171875, 77.39306640625],
              [-66.32529296874999, 77.468212890625],
              [-66.26645507812499, 77.515380859375],
              [-66.30644531249999, 77.564501953125],
              [-66.44536132812499, 77.615673828125],
              [-66.69121093749999, 77.681201171875],
              [-66.82353515624999, 77.68662109375],
              [-66.970654296875, 77.670849609375],
              [-67.14736328125, 77.634521484375],
              [-67.5146484375, 77.542919921875],
              [-67.68808593749999, 77.523779296875],
              [-67.97734374999999, 77.518896484375],
              [-68.13730468749999, 77.53046875],
              [-68.29189453125, 77.544189453125],
              [-68.53349609374999, 77.5927734375],
              [-68.621533203125, 77.60185546875],
              [-68.72822265625, 77.58056640625],
              [-68.853662109375, 77.528857421875],
              [-68.974560546875, 77.492626953125],
              [-69.09091796874999, 77.471923828125],
              [-69.199658203125, 77.462939453125],
              [-69.35136718749999, 77.467138671875],
              [-69.9767578125, 77.54765625],
              [-70.1181640625, 77.58349609375],
              [-70.1263671875, 77.63779296875],
              [-70.318310546875, 77.690380859375],
              [-70.535400390625, 77.699560546875],
              [-70.5619140625, 77.7171875],
              [-70.28662109375, 77.7982421875],
              [-70.081494140625, 77.831396484375],
              [-70.114453125, 77.841357421875],
              [-70.41240234374999, 77.843115234375],
              [-70.613525390625, 77.8],
              [-70.7287109375, 77.792724609375],
              [-70.993603515625, 77.791552734375],
              [-71.271630859375, 77.813134765625],
              [-71.38984375, 77.83203125],
              [-71.51240234375, 77.875390625],
              [-71.64990234375, 77.8998046875],
              [-72.06494140625, 77.93681640625],
              [-72.15854492187499, 77.95693359375],
              [-72.247265625, 77.9904296875],
              [-72.586328125, 78.085205078125],
              [-72.79150390625, 78.1548828125],
              [-72.81806640625, 78.1943359375],
              [-72.581298828125, 78.2791015625],
              [-72.570947265625, 78.29873046875],
              [-72.67246093749999, 78.335302734375],
              [-72.714794921875, 78.3623046875],
              [-72.67973632812499, 78.399560546875],
              [-72.47250976562499, 78.48203125],
              [-72.39560546874999, 78.504345703125],
              [-72.023681640625, 78.552783203125],
              [-71.651318359375, 78.62314453125],
              [-71.515625, 78.63896484375],
              [-71.394775390625, 78.642626953125],
              [-70.90576171875, 78.6384765625],
              [-70.7541015625, 78.655810546875],
              [-70.625390625, 78.69013671875],
              [-70.414208984375, 78.72490234375],
              [-69.97353515625, 78.777685546875],
              [-68.99345703124999, 78.857421875],
              [-68.929638671875, 78.866796875],
              [-68.92392578124999, 78.88193359375],
              [-69.011962890625, 78.923046875],
              [-69.030517578125, 78.94287109375],
              [-68.829833984375, 78.979736328125],
              [-68.37705078124999, 79.037841796875],
              [-68.067529296875, 79.0658203125],
              [-67.868359375, 79.06787109375],
              [-67.707763671875, 79.08037109375],
              [-67.48222656249999, 79.11689453125],
              [-67.35454101562499, 79.12333984375],
              [-66.583740234375, 79.1376953125],
              [-66.24277343749999, 79.117822265625],
              [-66.075341796875, 79.118212890625],
              [-65.96787109374999, 79.132373046875],
              [-65.82553710937499, 79.17373046875],
              [-65.559765625, 79.27646484375],
              [-65.419873046875, 79.340234375],
              [-65.28779296875, 79.4373046875],
              [-65.116943359375, 79.589013671875],
              [-64.9892578125, 79.736962890625],
              [-64.90463867187499, 79.88125],
              [-64.83896484374999, 79.969189453125],
              [-64.79228515624999, 80.000634765625],
              [-64.632421875, 80.040576171875],
              [-64.46572265625, 80.0716796875],
              [-64.179150390625, 80.099267578125],
              [-64.2052734375, 80.112109375],
              [-64.326806640625, 80.13359375],
              [-64.43994140625, 80.141845703125],
              [-64.54462890625, 80.1369140625],
              [-64.73525390625, 80.104443359375],
              [-64.98222656249999, 80.082470703125],
              [-65.222119140625, 80.0859375],
              [-65.394921875, 80.077734375],
              [-65.55341796875, 80.047998046875],
              [-65.81044921875, 80.024072265625],
              [-65.98193359375, 80.0294921875],
              [-66.29150390625, 80.072265625],
              [-66.447705078125, 80.0802734375],
              [-66.84365234375, 80.076220703125],
              [-66.95947265625, 80.092041015625],
              [-67.06064453124999, 80.12314453125],
              [-67.14130859375, 80.166455078125],
              [-67.20146484374999, 80.22216796875],
              [-67.19316406249999, 80.280078125],
              [-67.05063476562499, 80.384521484375],
              [-66.99589843749999, 80.41298828125],
              [-66.61005859375, 80.52958984375],
              [-66.372314453125, 80.5841796875],
              [-66.135693359375, 80.625],
              [-65.96328125, 80.648974609375],
              [-65.8009765625, 80.659716796875],
              [-65.64521484375, 80.68505859375],
              [-65.35820312499999, 80.76650390625],
              [-65.062158203125, 80.836328125],
              [-64.693798828125, 80.966015625],
              [-64.51552734375, 81],
              [-63.891552734375, 81.0564453125],
              [-63.72197265624999, 81.05732421875],
              [-63.578027343749994, 81.04326171875],
              [-63.44169921874999, 81.0138671875],
              [-63.05859375, 80.885595703125],
              [-63.02866210937499, 80.88955078125],
              [-63.095458984375, 80.9380859375],
              [-63.23520507812499, 81.083349609375],
              [-63.2125, 81.143115234375],
              [-62.99326171874999, 81.206982421875],
              [-62.90336914062499, 81.218359375],
              [-62.67192382812499, 81.214111328125],
              [-62.29887695312499, 81.194384765625],
              [-62.04941406249999, 81.17275390625],
              [-61.8603515625, 81.13759765625],
              [-61.63559570312499, 81.11572265625],
              [-61.51909179687499, 81.116796875],
              [-61.435986328125, 81.13359375],
              [-61.31699218749999, 81.1884765625],
              [-61.162060546875, 81.281494140625],
              [-61.1, 81.39609375],
              [-61.130761718749994, 81.53232421875],
              [-61.1759765625, 81.631884765625],
              [-61.23569335937499, 81.694580078125],
              [-61.20292968749999, 81.746875],
              [-61.015039062499994, 81.8095703125],
              [-60.84287109374999, 81.85537109375],
              [-60.432373046875, 81.920166015625],
              [-60.09946289062499, 81.937353515625],
              [-59.90190429687499, 81.9330078125],
              [-59.64228515625, 81.90263671875],
              [-59.28193359375, 81.884033203125],
              [-58.956787109375, 81.8251953125],
              [-58.42978515624999, 81.6900390625],
              [-58.07993164062499, 81.622216796875],
              [-57.79033203124999, 81.591748046875],
              [-57.5048828125, 81.539892578125],
              [-57.08286132812499, 81.429931640625],
              [-56.862060546875, 81.38271484375],
              [-56.7306640625, 81.365625],
              [-56.615136718749994, 81.362890625],
              [-56.658154296875, 81.394287109375],
              [-56.85966796874999, 81.4599609375],
              [-57.168408203125, 81.532177734375],
              [-57.85302734375, 81.66201171875],
              [-58.230078125, 81.753662109375],
              [-58.56821289062499, 81.858203125],
              [-58.81674804687499, 81.92041015625],
              [-59.268017578125, 81.982080078125],
              [-59.26181640624999, 82.006640625],
              [-58.71738281249999, 82.09306640625],
              [-57.71689453124999, 82.168310546875],
              [-56.58935546875, 82.2271484375],
              [-56.21196289062499, 82.221142578125],
              [-55.54868164062499, 82.245751953125],
              [-55.486230468749994, 82.282861328125],
              [-55.34360351562499, 82.299560546875],
              [-54.72587890624999, 82.3513671875],
              [-54.54887695312499, 82.350634765625],
              [-54.27705078125, 82.32607421875],
              [-53.9873046875, 82.279248046875],
              [-53.85322265625, 82.236865234375],
              [-53.671337890625, 82.1640625],
              [-53.58203125, 82.061572265625],
              [-53.59550781249999, 81.738037109375],
              [-53.590771484375, 81.67685546875],
              [-53.5556640625, 81.653271484375],
              [-53.430126953125, 81.68837890625],
              [-53.27998046875, 81.75361328125],
              [-53.14501953125, 81.799755859375],
              [-53.0412109375, 81.87099609375],
              [-52.968505859375, 81.967138671875],
              [-52.925537109375, 82.03837890625],
              [-53.101953125, 82.1189453125],
              [-53.110742187499994, 82.251220703125],
              [-53.02255859374999, 82.321728515625],
              [-52.7755859375, 82.321728515625],
              [-51.754003906250006, 82.07822265625],
              [-51.35185546874999, 82.025634765625],
              [-50.894433593749994, 81.89521484375],
              [-50.36005859374998, 81.90908203125],
              [-49.867041015625006, 81.893017578125],
              [-49.648828125, 81.897802734375],
              [-49.54106445312499, 81.91806640625],
              [-49.69428710937498, 81.972119140625],
              [-50.39482421874999, 82.120703125],
              [-50.713134765625, 82.237353515625],
              [-50.935546875, 82.3828125],
              [-50.98994140624998, 82.46015625],
              [-50.81953124999998, 82.474072265625],
              [-50.037109375, 82.472412109375],
              [-48.861181640625006, 82.405419921875],
              [-47.357421875, 82.1736328125],
              [-46.617333984374994, 82.09697265625],
              [-45.29106445312499, 81.82880859375],
              [-44.890966796875006, 81.78828125],
              [-44.7294921875, 81.779833984375],
              [-44.60761718749998, 81.812939453125],
              [-44.53242187499998, 81.84892578125],
              [-44.52690429687499, 81.896826171875],
              [-44.59101562499998, 81.956689453125],
              [-44.62773437499999, 82.02587890625],
              [-44.637109375, 82.104443359375],
              [-44.54707031249998, 82.26005859375],
              [-44.33320312499998, 82.310791015625],
              [-44.238867187500006, 82.3681640625],
              [-44.3265625, 82.471728515625],
              [-44.577246093750006, 82.542626953125],
              [-45.55244140624998, 82.725244140625],
              [-45.556542968749994, 82.747021484375],
              [-45.359619140625, 82.770947265625],
              [-45.06743164062499, 82.7849609375],
              [-42.650732421875006, 82.741455078125],
              [-42.23295898437499, 82.72548828125],
              [-42.05463867187498, 82.709814453125],
              [-41.9765625, 82.68916015625],
              [-41.87646484375, 82.680322265625],
              [-41.357275390625006, 82.70498046875],
              [-41.36962890625, 82.75],
              [-41.434423828125006, 82.77861328125],
              [-44.23920898437498, 82.856787109375],
              [-44.761962890625, 82.883544921875],
              [-45.027929687500006, 82.885595703125],
              [-45.302978515625, 82.865087890625],
              [-45.87333984374999, 82.8548828125],
              [-46.136816406250006, 82.858837890625],
              [-46.478173828124994, 82.951904296875],
              [-46.169042968750006, 83.0638671875],
              [-45.90888671874998, 83.061328125],
              [-45.41459960937499, 83.01767578125],
              [-45.12177734374998, 83.078662109375],
              [-44.65693359374998, 83.129052734375],
              [-44.19731445312499, 83.146826171875],
              [-43.194580078125, 83.255126953125],
              [-43.00927734375, 83.264599609375],
              [-42.775537109374994, 83.2587890625],
              [-42.259521484375, 83.231982421875],
              [-42.054589843749994, 83.20517578125],
              [-41.81977539062498, 83.14775390625],
              [-41.683496093749994, 83.130029296875],
              [-41.52197265625, 83.1267578125],
              [-41.300146484375006, 83.10078125],
              [-40.979394531249994, 83.18486328125],
              [-40.689453125, 83.2751953125],
              [-40.356835937499994, 83.332177734375],
              [-39.886328125, 83.29892578125],
              [-39.58842773437499, 83.25556640625],
              [-39.316015625, 83.20390625],
              [-38.93110351562498, 83.175341796875],
              [-38.27836914062499, 82.998876953125],
              [-38.15625, 82.9986328125],
              [-38.09858398437498, 83.01357421875],
              [-38.037011718749994, 83.0462890625],
              [-38.014892578125, 83.09482421875],
              [-37.93476562499998, 83.1607421875],
              [-37.992773437500006, 83.185107421875],
              [-38.53955078125, 83.258154296875],
              [-38.642919921875006, 83.286279296875],
              [-38.747802734375, 83.332568359375],
              [-38.74956054687499, 83.370849609375],
              [-38.64824218749999, 83.401025390625],
              [-38.54145507812498, 83.414794921875],
              [-38.18793945312498, 83.402294921875],
              [-38.07109374999999, 83.412109375],
              [-37.960839843749994, 83.437646484375],
              [-37.828027343749994, 83.485546875],
              [-37.72333984374998, 83.49775390625],
              [-37.486914062500006, 83.49912109375],
              [-37.12299804687498, 83.468408203125],
              [-36.80449218749999, 83.4658203125],
              [-36.689599609374994, 83.479931640625],
              [-36.672119140625, 83.509912109375],
              [-36.644433593749994, 83.528955078125],
              [-36.60649414062499, 83.536962890625],
              [-35.45185546874998, 83.538623046875],
              [-35.16552734375, 83.545751953125],
              [-34.941650390625, 83.56845703125],
              [-34.66777343749999, 83.571142578125],
              [-34.428320312500006, 83.557568359375],
              [-34.131933593750006, 83.528662109375],
              [-33.83735351562498, 83.52998046875],
              [-33.398339843749994, 83.57724609375],
              [-32.98442382812499, 83.599609375],
              [-30.70292968749999, 83.593408203125],
              [-29.952880859375, 83.56484375],
            ],
          ],
          [
            [
              [-52.73115234375, 69.9447265625],
              [-52.39824218749999, 69.863427734375],
              [-52.0453125, 69.8072265625],
              [-52.01079101562499, 69.78154296875],
              [-51.9833984375, 69.74267578125],
              [-51.97705078125, 69.722412109375],
              [-51.985107421875, 69.70361328125],
              [-52.007470703124994, 69.686279296875],
              [-51.981689453125, 69.66396484375],
              [-51.90776367187499, 69.636669921875],
              [-51.90019531249999, 69.60478515625],
              [-51.988427734374994, 69.55],
              [-52.11259765624999, 69.489111328125],
              [-52.770458984375, 69.363916015625],
              [-53.003125, 69.342626953125],
              [-53.57841796874999, 69.256640625],
              [-53.754345703125, 69.26015625],
              [-53.7931640625, 69.264208984375],
              [-53.90205078125, 69.302001953125],
              [-54.051171875, 69.337158203125],
              [-54.121044921875, 69.364404296875],
              [-54.18271484374999, 69.403515625],
              [-54.158154296875, 69.427783203125],
              [-54.04736328125, 69.4373046875],
              [-53.889599609375, 69.436669921875],
              [-53.65830078124999, 69.46513671875],
              [-53.722265625, 69.49072265625],
              [-53.78305664062499, 69.506298828125],
              [-53.825, 69.54033203125],
              [-53.921484375, 69.53369140625],
              [-53.99375, 69.553173828125],
              [-54.133203125, 69.5654296875],
              [-54.496972656249994, 69.577197265625],
              [-54.734130859375, 69.610546875],
              [-54.804101562499994, 69.630517578125],
              [-54.86577148437499, 69.6650390625],
              [-54.919140625, 69.713623046875],
              [-54.841259765625, 69.901904296875],
              [-54.78789062499999, 69.949853515625],
              [-54.66459960937499, 69.965673828125],
              [-54.36308593749999, 69.923828125],
              [-54.32260742187499, 69.94189453125],
              [-54.65244140624999, 70.011181640625],
              [-54.7736328125, 70.0525390625],
              [-54.809326171875, 70.085107421875],
              [-54.83076171875, 70.132958984375],
              [-54.83046875, 70.161083984375],
              [-54.815576171874994, 70.189404296875],
              [-54.78623046874999, 70.2177734375],
              [-54.705957031249994, 70.25615234375],
              [-54.37163085937499, 70.31728515625],
              [-54.007226562499994, 70.296435546875],
              [-53.375146484374994, 70.2212890625],
              [-53.29672851562499, 70.20537109375],
              [-53.102929687499994, 70.140869140625],
              [-52.73115234375, 69.9447265625],
            ],
          ],
          [
            [
              [-51.013671875, 69.552490234375],
              [-51.17041015625, 69.517138671875],
              [-51.202050781249994, 69.525],
              [-51.233984375, 69.55185546875],
              [-51.31489257812498, 69.674072265625],
              [-51.3388671875, 69.73203125],
              [-51.318945312500006, 69.804052734375],
              [-51.35029296874998, 69.85478515625],
              [-51.208886718749994, 69.913916015625],
              [-51.094580078125006, 69.924169921875],
              [-50.94023437499999, 69.90869140625],
              [-50.67900390624999, 69.84853515625],
              [-50.697900390624994, 69.829052734375],
              [-50.75439453125, 69.79765625],
              [-50.91171875, 69.756689453125],
              [-50.96723632812498, 69.6642578125],
              [-50.977880859375006, 69.617822265625],
              [-50.97041015624998, 69.5830078125],
              [-51.013671875, 69.552490234375],
            ],
          ],
          [
            [
              [-53.53520507812499, 71.0408203125],
              [-53.628808593749994, 71.03427734375],
              [-53.89755859374999, 71.08515625],
              [-53.941162109375, 71.104296875],
              [-53.95781249999999, 71.127734375],
              [-53.9474609375, 71.155517578125],
              [-53.86186523437499, 71.2072265625],
              [-53.70097656249999, 71.2830078125],
              [-53.58447265625, 71.2970703125],
              [-53.512353515624994, 71.249609375],
              [-53.44140625, 71.18583984375],
              [-53.43212890625, 71.15341796875],
              [-53.436914062499994, 71.115234375],
              [-53.45546875, 71.08291015625],
              [-53.48779296875, 71.056298828125],
              [-53.53520507812499, 71.0408203125],
            ],
          ],
          [
            [
              [-55.01689453124999, 72.79111328125],
              [-55.158203125, 72.723291015625],
              [-55.273583984374994, 72.684326171875],
              [-55.5236328125, 72.568408203125],
              [-55.5666015625, 72.56435546875],
              [-55.63413085937499, 72.579443359375],
              [-55.6865234375, 72.609912109375],
              [-55.781005859375, 72.617236328125],
              [-55.813916015625, 72.636474609375],
              [-55.8271484375, 72.6521484375],
              [-55.869042968749994, 72.662109375],
              [-55.935693359374994, 72.668359375],
              [-56.04267578125, 72.6564453125],
              [-56.140869140625, 72.66845703125],
              [-56.214794921875, 72.719189453125],
              [-56.078076171875, 72.75322265625],
              [-55.993554687499994, 72.782275390625],
              [-55.666455078125, 72.793701171875],
              [-55.57421875, 72.78037109375],
              [-55.516259765624994, 72.780712890625],
              [-55.4279296875, 72.788623046875],
              [-55.23466796874999, 72.8248046875],
              [-55.205810546875, 72.841650390625],
              [-55.03300781249999, 72.8205078125],
              [-55.01689453124999, 72.79111328125],
            ],
          ],
          [
            [
              [-71.66733398437499, 77.32529296875],
              [-72.02353515624999, 77.316455078125],
              [-72.3744140625, 77.355419921875],
              [-72.49492187499999, 77.385546875],
              [-72.48955078124999, 77.431640625],
              [-72.43642578125, 77.44755859375],
              [-72.24677734375, 77.463525390625],
              [-72.0890625, 77.46708984375],
              [-71.98276367187499, 77.4599609375],
              [-71.73291015625, 77.431640625],
              [-71.5521484375, 77.403271484375],
              [-71.43344726562499, 77.394384765625],
              [-71.46708984374999, 77.353662109375],
              [-71.66733398437499, 77.32529296875],
            ],
          ],
          [
            [
              [-44.86455078124999, 82.083642578125],
              [-45.06743164062499, 82.066015625],
              [-45.49077148437499, 82.171826171875],
              [-46.161035156249994, 82.277685546875],
              [-46.75190429687498, 82.348193359375],
              [-47.307519531249994, 82.5333984375],
              [-47.351220703124994, 82.59921875],
              [-47.27226562499999, 82.65693359375],
              [-46.787207031250006, 82.66572265625],
              [-46.399169921875, 82.692138671875],
              [-45.411376953125, 82.5775390625],
              [-44.91748046875, 82.480517578125],
              [-44.749902343749994, 82.401123046875],
              [-44.7763671875, 82.2423828125],
              [-44.86455078124999, 82.083642578125],
            ],
          ],
          [
            [
              [-25.432324218749983, 70.921337890625],
              [-25.397216796875, 70.862451171875],
              [-25.393652343749977, 70.83466796875],
              [-25.4013671875, 70.811279296875],
              [-25.420800781249994, 70.794580078125],
              [-25.4677734375, 70.7796875],
              [-25.380126953125, 70.740576171875],
              [-25.351660156249977, 70.714306640625],
              [-25.346337890624994, 70.693310546875],
              [-25.402246093749994, 70.652685546875],
              [-25.80058593749999, 70.59892578125],
              [-25.911328124999983, 70.573046875],
              [-26.049707031249994, 70.509130859375],
              [-26.217871093749977, 70.454052734375],
              [-26.273876953124983, 70.454345703125],
              [-26.33916015624999, 70.51142578125],
              [-26.604687499999983, 70.553369140625],
              [-27.10478515624999, 70.531494140625],
              [-27.690039062500006, 70.478662109375],
              [-27.89799804687499, 70.45400390625],
              [-28.003027343750006, 70.467138671875],
              [-28.035253906250006, 70.48681640625],
              [-28.036816406249983, 70.51435546875],
              [-27.967529296875, 70.59482421875],
              [-27.939550781250006, 70.615283203125],
              [-27.805273437500006, 70.642041015625],
              [-27.714208984375006, 70.71279296875],
              [-27.743994140625006, 70.78974609375],
              [-27.708935546874983, 70.897119140625],
              [-27.61723632812499, 70.91376953125],
              [-27.3875, 70.875634765625],
              [-27.238867187500006, 70.867578125],
              [-26.9755859375, 70.8626953125],
              [-26.621777343749983, 70.875634765625],
              [-26.33745117187499, 70.91923828125],
              [-25.81889648437499, 71.04365234375],
              [-25.726806640625, 71.042041015625],
              [-25.660839843749983, 70.99794921875],
              [-25.6123046875, 70.976318359375],
              [-25.458251953125, 70.942529296875],
              [-25.432324218749983, 70.921337890625],
            ],
          ],
          [
            [
              [-18.664746093749983, 81.846484375],
              [-18.767675781249977, 81.814306640625],
              [-19.031445312499983, 81.827197265625],
              [-19.369287109374994, 81.91728515625],
              [-19.594482421875, 81.991259765625],
              [-19.610546874999983, 82.078125],
              [-19.494726562499977, 82.11669921875],
              [-19.314550781249977, 82.123193359375],
              [-19.06689453125, 82.049169921875],
              [-18.812695312499983, 81.949462890625],
              [-18.664746093749983, 81.846484375],
            ],
          ],
          [
            [
              [-17.6125, 79.82587890625],
              [-18.035839843749983, 79.71123046875],
              [-18.662011718749994, 79.72001953125],
              [-19.032421874999983, 79.77294921875],
              [-19.138281249999977, 79.85234375],
              [-18.997167968749977, 79.940478515625],
              [-18.54736328125, 80.011083984375],
              [-17.98291015625, 80.05517578125],
              [-17.471386718749983, 80.0287109375],
              [-17.400830078124983, 79.940478515625],
              [-17.6125, 79.82587890625],
            ],
          ],
          [
            [
              [-18.997167968749977, 77.973779296875],
              [-19.129492187499977, 77.938525390625],
              [-19.217626953124977, 78.0443359375],
              [-19.297021484374994, 78.18544921875],
              [-19.314697265625, 78.344189453125],
              [-19.11181640625, 78.423583984375],
              [-19.005957031249977, 78.4412109375],
              [-18.935400390624977, 78.423583984375],
              [-18.953027343749994, 78.352978515625],
              [-18.953027343749994, 78.2119140625],
              [-18.882470703124994, 78.114892578125],
              [-18.997167968749977, 77.973779296875],
            ],
          ],
          [
            [
              [-18.582617187499977, 76.042333984375],
              [-18.697265625, 76.015869140625],
              [-19.085351562499994, 76.43037109375],
              [-19.085351562499994, 76.5802734375],
              [-19.05888671874999, 76.694970703125],
              [-18.882470703124994, 76.70380859375],
              [-18.732617187499983, 76.642041015625],
              [-18.662011718749994, 76.40390625],
              [-18.582617187499977, 76.042333984375],
            ],
          ],
          [
            [
              [-18.00053710937499, 75.40732421875],
              [-17.92119140624999, 75.301513671875],
              [-17.885888671874994, 75.204443359375],
              [-17.762402343749983, 75.1427734375],
              [-17.49785156249999, 75.151513671875],
              [-17.391992187499994, 75.0369140625],
              [-17.586035156249977, 74.9927734375],
              [-18.35332031249999, 75.01044921875],
              [-18.670800781249994, 75.00166015625],
              [-18.891308593749983, 75.07216796875],
              [-18.882470703124994, 75.195654296875],
              [-18.856054687499977, 75.319140625],
              [-18.63554687499999, 75.3896484375],
              [-18.450341796874994, 75.327978515625],
              [-18.229882812499994, 75.3720703125],
              [-18.00053710937499, 75.40732421875],
            ],
          ],
          [
            [
              [-17.953710937499977, 77.642333984375],
              [-18.14799804687499, 77.642333984375],
              [-18.22001953124999, 77.668359375],
              [-18.174023437499983, 77.71435546875],
              [-17.903710937499994, 77.86259765625],
              [-17.813574218749977, 77.874609375],
              [-17.68144531249999, 77.85859375],
              [-17.641357421875, 77.782470703125],
              [-17.7294921875, 77.706396484375],
              [-17.953710937499977, 77.642333984375],
            ],
          ],
          [
            [
              [-37.03125, 65.531982421875],
              [-37.18681640624999, 65.53134765625],
              [-37.238427734374994, 65.60986328125],
              [-37.222900390625, 65.695458984375],
              [-37.047509765624994, 65.722265625],
              [-36.95307617187498, 65.663330078125],
              [-36.986914062500006, 65.5755859375],
              [-37.03125, 65.531982421875],
            ],
          ],
          [
            [
              [-46.266699218750006, 60.781396484374994],
              [-46.38154296874998, 60.660302734374994],
              [-46.496337890625, 60.686669921874994],
              [-46.553125, 60.740771484375],
              [-46.66621093749998, 60.76591796875],
              [-46.7880859375, 60.7583984375],
              [-46.78999023437498, 60.779833984375],
              [-46.393896484375006, 60.9087890625],
              [-46.205224609374994, 60.943505859374994],
              [-46.218603515625006, 60.88916015625],
              [-46.254492187500006, 60.841552734375],
              [-46.266699218750006, 60.781396484374994],
            ],
          ],
          [
            [
              [-51.675146484375006, 70.855224609375],
              [-51.808691406250006, 70.8525390625],
              [-52.119384765625, 70.870654296875],
              [-52.144189453124994, 70.882275390625],
              [-52.148046875, 70.90439453125],
              [-52.10673828124999, 70.968017578125],
              [-51.969824218750006, 70.97646484375],
              [-51.80693359374999, 70.941650390625],
              [-51.63134765625, 70.892138671875],
              [-51.60693359375, 70.86884765625],
              [-51.675146484375006, 70.855224609375],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 3,
        labelrank: 5,
        sovereignt: "Netherlands",
        sov_a3: "NL1",
        adm0_dif: 1,
        level: 2,
        type: "Country",
        tlc: "1",
        admin: "Curaçao",
        adm0_a3: "CUW",
        geou_dif: 0,
        geounit: "Curaçao",
        gu_a3: "CUW",
        su_dif: 0,
        subunit: "Curaçao",
        su_a3: "CUW",
        brk_diff: 0,
        name: "Curaçao",
        name_long: "Curaçao",
        brk_a3: "CUW",
        brk_name: "Curaçao",
        brk_group: null,
        abbrev: "Cur.",
        postal: "CW",
        formal_en: "Curaçao",
        formal_fr: null,
        name_ciawf: "Curacao",
        note_adm0: "Neth.",
        note_brk: null,
        name_sort: "Curaçao",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 2,
        mapcolor9: 2,
        mapcolor13: 9,
        pop_est: 157538,
        pop_rank: 9,
        pop_year: 2019,
        gdp_md: 3101,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "2. High income: nonOECD",
        fips_10: "NT",
        iso_a2: "CW",
        iso_a2_eh: "CW",
        iso_a3: "CUW",
        iso_a3_eh: "CUW",
        iso_n3: "531",
        iso_n3_eh: "531",
        un_a3: "531",
        wb_a2: "CW",
        wb_a3: "CUW",
        woe_id: -90,
        woe_id_eh: 24549810,
        woe_note: "Expired subunits of Netherlands Antilles (23424914).",
        adm0_iso: "CUW",
        adm0_diff: null,
        adm0_tlc: "CUW",
        adm0_a3_us: "CUW",
        adm0_a3_fr: "CUW",
        adm0_a3_ru: "CUW",
        adm0_a3_es: "CUW",
        adm0_a3_cn: "CUW",
        adm0_a3_tw: "CUW",
        adm0_a3_in: "CUW",
        adm0_a3_np: "CUW",
        adm0_a3_pk: "CUW",
        adm0_a3_de: "CUW",
        adm0_a3_gb: "CUW",
        adm0_a3_br: "CUW",
        adm0_a3_il: "CUW",
        adm0_a3_ps: "CUW",
        adm0_a3_sa: "CUW",
        adm0_a3_eg: "CUW",
        adm0_a3_ma: "CUW",
        adm0_a3_pt: "CUW",
        adm0_a3_ar: "CUW",
        adm0_a3_jp: "CUW",
        adm0_a3_ko: "CUW",
        adm0_a3_vn: "CUW",
        adm0_a3_tr: "CUW",
        adm0_a3_id: "CUW",
        adm0_a3_pl: "CUW",
        adm0_a3_gr: "CUW",
        adm0_a3_it: "CUW",
        adm0_a3_nl: "CUW",
        adm0_a3_se: "CUW",
        adm0_a3_bd: "CUW",
        adm0_a3_ua: "CUW",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "North America",
        region_un: "Americas",
        subregion: "Caribbean",
        region_wb: "Latin America & Caribbean",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: 4,
        homepart: -99,
        min_zoom: 0,
        min_label: 5,
        max_label: 10,
        label_x: -68.920578,
        label_y: 12.145039,
        ne_id: 1159321099,
        wikidataid: "Q25279",
        name_ar: "كوراساو",
        name_bn: "কিউরাসাও",
        name_de: "Curaçao",
        name_en: "Curaçao",
        name_es: "Curazao",
        name_fa: "کوراسائو",
        name_fr: "Curaçao",
        name_el: "Κουρασάο",
        name_he: "קוראסאו",
        name_hi: "कुराकाओ",
        name_hu: "Curaçao",
        name_id: "Curaçao",
        name_it: "Curaçao",
        name_ja: "キュラソー",
        name_ko: "퀴라소",
        name_nl: "Curaçao",
        name_pl: "Curaçao",
        name_pt: "Curaçao",
        name_ru: "Кюрасао",
        name_sv: "Curaçao",
        name_tr: "Curaçao",
        name_uk: "Кюрасао",
        name_ur: "کیوراساؤ",
        name_vi: "Curaçao",
        name_zh: "库拉索",
        name_zht: "古拉索",
        fclass_iso: "Admin-0 dependency",
        tlc_diff: null,
        fclass_tlc: "Admin-0 dependency",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "CUW.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-68.75107421874999, 12.059765625],
            [-68.80332031249999, 12.045458984374989],
            [-68.9951171875, 12.141845703125],
            [-69.15380859375, 12.2984375],
            [-69.15888671875, 12.380273437499994],
            [-69.11845703124999, 12.373242187499997],
            [-69.07675781249999, 12.342041015625],
            [-69.013134765625, 12.231347656249994],
            [-68.827392578125, 12.158544921874991],
            [-68.75107421874999, 12.059765625],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 4,
        sovereignt: "Panama",
        sov_a3: "PAN",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Panama",
        adm0_a3: "PAN",
        geou_dif: 0,
        geounit: "Panama",
        gu_a3: "PAN",
        su_dif: 0,
        subunit: "Panama",
        su_a3: "PAN",
        brk_diff: 0,
        name: "Panama",
        name_long: "Panama",
        brk_a3: "PAN",
        brk_name: "Panama",
        brk_group: null,
        abbrev: "Pan.",
        postal: "PA",
        formal_en: "Republic of Panama",
        formal_fr: null,
        name_ciawf: "Panama",
        note_adm0: null,
        note_brk: null,
        name_sort: "Panama",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 4,
        mapcolor9: 6,
        mapcolor13: 3,
        pop_est: 4246439,
        pop_rank: 12,
        pop_year: 2019,
        gdp_md: 66800,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        fips_10: "PM",
        iso_a2: "PA",
        iso_a2_eh: "PA",
        iso_a3: "PAN",
        iso_a3_eh: "PAN",
        iso_n3: "591",
        iso_n3_eh: "591",
        un_a3: "591",
        wb_a2: "PA",
        wb_a3: "PAN",
        woe_id: 23424924,
        woe_id_eh: 23424924,
        woe_note: "Exact WOE match as country",
        adm0_iso: "PAN",
        adm0_diff: null,
        adm0_tlc: "PAN",
        adm0_a3_us: "PAN",
        adm0_a3_fr: "PAN",
        adm0_a3_ru: "PAN",
        adm0_a3_es: "PAN",
        adm0_a3_cn: "PAN",
        adm0_a3_tw: "PAN",
        adm0_a3_in: "PAN",
        adm0_a3_np: "PAN",
        adm0_a3_pk: "PAN",
        adm0_a3_de: "PAN",
        adm0_a3_gb: "PAN",
        adm0_a3_br: "PAN",
        adm0_a3_il: "PAN",
        adm0_a3_ps: "PAN",
        adm0_a3_sa: "PAN",
        adm0_a3_eg: "PAN",
        adm0_a3_ma: "PAN",
        adm0_a3_pt: "PAN",
        adm0_a3_ar: "PAN",
        adm0_a3_jp: "PAN",
        adm0_a3_ko: "PAN",
        adm0_a3_vn: "PAN",
        adm0_a3_tr: "PAN",
        adm0_a3_id: "PAN",
        adm0_a3_pl: "PAN",
        adm0_a3_gr: "PAN",
        adm0_a3_it: "PAN",
        adm0_a3_nl: "PAN",
        adm0_a3_se: "PAN",
        adm0_a3_bd: "PAN",
        adm0_a3_ua: "PAN",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "North America",
        region_un: "Americas",
        subregion: "Central America",
        region_wb: "Latin America & Caribbean",
        name_len: 6,
        long_len: 6,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: -80.352106,
        label_y: 8.72198,
        ne_id: 1159321161,
        wikidataid: "Q804",
        name_ar: "بنما",
        name_bn: "পানামা",
        name_de: "Panama",
        name_en: "Panama",
        name_es: "Panamá",
        name_fa: "پاناما",
        name_fr: "Panama",
        name_el: "Παναμάς",
        name_he: "פנמה",
        name_hi: "पनामा",
        name_hu: "Panama",
        name_id: "Panama",
        name_it: "Panama",
        name_ja: "パナマ",
        name_ko: "파나마",
        name_nl: "Panama",
        name_pl: "Panama",
        name_pt: "Panamá",
        name_ru: "Панама",
        name_sv: "Panama",
        name_tr: "Panama",
        name_uk: "Панама",
        name_ur: "پاناما",
        name_vi: "Panama",
        name_zh: "巴拿马",
        name_zht: "巴拿馬",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "PAN.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-77.37421875, 8.658300781249991],
              [-77.39306640625, 8.644677734374994],
              [-77.44833984374999, 8.565869140624997],
              [-77.478515625, 8.4984375],
              [-77.40727539062499, 8.42724609375],
              [-77.385888671875, 8.351660156249991],
              [-77.34550781249999, 8.26953125],
              [-77.2826171875, 8.187060546874989],
              [-77.2123046875, 8.033886718749997],
              [-77.19599609375, 7.972460937499989],
              [-77.215966796875, 7.932519531249994],
              [-77.282958984375, 7.908154296874997],
              [-77.34560546875, 7.836523437499991],
              [-77.362744140625, 7.749072265624989],
              [-77.35078125, 7.705859374999989],
              [-77.53828125, 7.566259765624991],
              [-77.586572265625, 7.543066406249991],
              [-77.618603515625, 7.564550781249991],
              [-77.65859375, 7.634619140624991],
              [-77.70634765624999, 7.691210937499989],
              [-77.73203125, 7.7109375],
              [-77.74692382812499, 7.711865234374997],
              [-77.7619140625, 7.698828125],
              [-77.76875, 7.668066406249991],
              [-77.743896484375, 7.536962890624991],
              [-77.76469726562499, 7.483691406249989],
              [-77.8283203125, 7.442822265624997],
              [-77.90117187499999, 7.229345703124991],
              [-77.92978515624999, 7.25634765625],
              [-78.1701171875, 7.543798828124991],
              [-78.37822265624999, 7.89990234375],
              [-78.42158203125, 8.060986328124997],
              [-78.367626953125, 8.070556640625],
              [-78.3154296875, 8.066943359374989],
              [-78.287353515625, 8.091796875],
              [-78.2548828125, 8.138623046874997],
              [-78.281201171875, 8.24755859375],
              [-78.18002929687499, 8.330273437499997],
              [-78.14189453124999, 8.386083984374991],
              [-78.11386718749999, 8.379589843749997],
              [-78.04775390625, 8.284765625],
              [-77.95166015625, 8.230273437499989],
              [-77.833642578125, 8.151171874999989],
              [-77.76054687499999, 8.133251953124997],
              [-77.8529296875, 8.216210937499994],
              [-78.0125, 8.325390625],
              [-78.05717773437499, 8.397119140624994],
              [-78.09946289062499, 8.496972656249994],
              [-78.16181640625, 8.453710937499991],
              [-78.19077148437499, 8.417333984374991],
              [-78.223046875, 8.396630859374994],
              [-78.251123046875, 8.421435546874989],
              [-78.256103515625, 8.453710937499991],
              [-78.35014648437499, 8.460009765624989],
              [-78.37431640624999, 8.4892578125],
              [-78.39921874999999, 8.505664062499989],
              [-78.387890625, 8.443408203124989],
              [-78.369384765625, 8.404931640624994],
              [-78.379296875, 8.35859375],
              [-78.40986328125, 8.355322265624991],
              [-78.43603515625, 8.4033203125],
              [-78.46943359375, 8.446679687499994],
              [-78.5140625, 8.628173828125],
              [-78.62089843749999, 8.713720703124991],
              [-78.66982421875, 8.7421875],
              [-78.710205078125, 8.7529296875],
              [-78.769677734375, 8.811083984374989],
              [-78.84824218749999, 8.8421875],
              [-78.95517578124999, 8.932519531249994],
              [-79.086376953125, 8.997167968749991],
              [-79.24667968749999, 9.020068359374989],
              [-79.44150390624999, 9.006005859374994],
              [-79.507080078125, 8.970068359374991],
              [-79.55166015625, 8.924462890624994],
              [-79.57236328124999, 8.903271484374997],
              [-79.687451171875, 8.850976562499994],
              [-79.73105468749999, 8.775341796874997],
              [-79.758544921875, 8.711572265624994],
              [-79.81591796875, 8.639208984374989],
              [-79.750439453125, 8.595507812499989],
              [-80.12578124999999, 8.349658203124989],
              [-80.20009765625, 8.31396484375],
              [-80.36870117187499, 8.288769531249997],
              [-80.40756835937499, 8.262451171875],
              [-80.458984375, 8.2138671875],
              [-80.46586914062499, 8.139941406249989],
              [-80.45810546874999, 8.077050781249994],
              [-80.409130859375, 8.028564453125],
              [-80.36557617187499, 7.997998046874997],
              [-80.2609375, 7.851660156249991],
              [-80.0751953125, 7.667041015624989],
              [-80.0400390625, 7.599804687499997],
              [-80.01123046875, 7.500048828124989],
              [-80.06728515625, 7.453222656249991],
              [-80.110595703125, 7.433447265624991],
              [-80.2873046875, 7.425634765624991],
              [-80.34824218749999, 7.385693359374997],
              [-80.37294921875, 7.324658203124997],
              [-80.4388671875, 7.274951171874989],
              [-80.66669921875, 7.225683593749991],
              [-80.84555664062499, 7.220068359374991],
              [-80.90122070312499, 7.277148437499989],
              [-80.91464843749999, 7.4375],
              [-81.035107421875, 7.711132812499997],
              [-81.0638671875, 7.899755859374991],
              [-81.0939453125, 7.876318359374991],
              [-81.1578125, 7.854394531249994],
              [-81.17939453125, 7.807519531249994],
              [-81.195458984375, 7.668408203124997],
              [-81.21904296874999, 7.620947265624991],
              [-81.26840820312499, 7.62548828125],
              [-81.369580078125, 7.67529296875],
              [-81.504150390625, 7.72119140625],
              [-81.67568359375, 8.015917968749989],
              [-81.694287109375, 8.071386718749991],
              [-81.72763671874999, 8.137548828124991],
              [-81.86025390625, 8.165429687499994],
              [-81.973291015625, 8.215087890625],
              [-82.09672851562499, 8.222753906249991],
              [-82.15986328125, 8.19482421875],
              [-82.22431640625, 8.230371093749994],
              [-82.23544921874999, 8.31103515625],
              [-82.36484375, 8.274853515624997],
              [-82.53095703125, 8.287402343749989],
              [-82.67954101562499, 8.321972656249997],
              [-82.78115234375, 8.303515624999989],
              [-82.86611328125, 8.246337890625],
              [-82.85434570312499, 8.099511718749994],
              [-82.879345703125, 8.070654296874991],
              [-82.88330078125, 8.130566406249997],
              [-82.91289062499999, 8.199609375],
              [-82.9484375, 8.2568359375],
              [-83.023388671875, 8.316015625],
              [-83.02734375, 8.337744140624991],
              [-82.99755859375, 8.367773437499991],
              [-82.86162109374999, 8.453515625],
              [-82.84477539062499, 8.489355468749991],
              [-82.84262695312499, 8.56396484375],
              [-82.855712890625, 8.635302734374989],
              [-82.91704101562499, 8.740332031249991],
              [-82.881982421875, 8.805322265624994],
              [-82.8119140625, 8.857421875],
              [-82.739990234375, 8.898583984374994],
              [-82.72783203124999, 8.916064453124989],
              [-82.741162109375, 8.951708984374989],
              [-82.78305664062499, 8.990283203124989],
              [-82.88134765625, 9.055859375],
              [-82.94033203125, 9.060107421874989],
              [-82.942822265625, 9.248876953124991],
              [-82.93984375, 9.449169921874997],
              [-82.925048828125, 9.469042968749989],
              [-82.88896484374999, 9.481005859374989],
              [-82.86015624999999, 9.511474609375],
              [-82.843994140625, 9.57080078125],
              [-82.801025390625, 9.591796875],
              [-82.723388671875, 9.54609375],
              [-82.64409179687499, 9.505859375],
              [-82.611279296875, 9.519238281249997],
              [-82.58652343749999, 9.538818359375],
              [-82.56923828125, 9.558203125],
              [-82.56357421874999, 9.57666015625],
              [-82.50034179687499, 9.523242187499989],
              [-82.37080078125, 9.428564453124991],
              [-82.36318359375, 9.381933593749991],
              [-82.375390625, 9.337255859374991],
              [-82.33974609375, 9.209179687499997],
              [-82.2724609375, 9.190625],
              [-82.20488281249999, 9.215429687499991],
              [-82.188134765625, 9.191748046874991],
              [-82.20068359375, 9.168115234374994],
              [-82.23544921874999, 9.141650390624989],
              [-82.24418945312499, 9.031494140625],
              [-82.13330078125, 8.980078125],
              [-82.077880859375, 8.934863281249989],
              [-81.894140625, 8.956103515624989],
              [-81.826416015625, 8.944091796875],
              [-81.780224609375, 8.957226562499997],
              [-81.831494140625, 9.045605468749997],
              [-81.900146484375, 9.111035156249997],
              [-81.894482421875, 9.140429687499989],
              [-81.84238281249999, 9.118701171874989],
              [-81.80258789062499, 9.074121093749994],
              [-81.71220703124999, 9.018945312499994],
              [-81.54560546875, 8.827001953124991],
              [-81.35478515624999, 8.780566406249989],
              [-81.203759765625, 8.78671875],
              [-81.06308593749999, 8.812646484374994],
              [-80.83867187499999, 8.88720703125],
              [-80.67646484375, 9.021875],
              [-80.546875, 9.081933593749994],
              [-80.127099609375, 9.209912109374997],
              [-79.977978515625, 9.343701171874997],
              [-79.91508789062499, 9.361328125],
              [-79.855078125, 9.378076171874994],
              [-79.723095703125, 9.479296874999989],
              [-79.65224609375, 9.558203125],
              [-79.577294921875, 9.597851562499997],
              [-79.35546875, 9.569238281249994],
              [-79.21162109375, 9.531933593749997],
              [-79.112255859375, 9.536767578124994],
              [-79.01669921874999, 9.510449218749997],
              [-78.975, 9.452978515624991],
              [-78.931640625, 9.428466796875],
              [-78.696923828125, 9.434765625],
              [-78.504345703125, 9.406298828124989],
              [-78.082763671875, 9.236279296874997],
              [-77.830810546875, 9.068115234375],
              [-77.697216796875, 8.889453124999989],
              [-77.37421875, 8.658300781249991],
            ],
          ],
          [
            [
              [-78.89833984375, 8.274267578124991],
              [-78.918115234375, 8.23193359375],
              [-78.96494140624999, 8.326269531249991],
              [-78.957421875, 8.3505859375],
              [-78.960595703125, 8.435839843749989],
              [-78.916015625, 8.458251953125],
              [-78.883251953125, 8.460253906249989],
              [-78.85615234375, 8.4482421875],
              [-78.83916015624999, 8.347900390625],
              [-78.85322265625, 8.302441406249997],
              [-78.89833984375, 8.274267578124991],
            ],
          ],
          [
            [
              [-81.603271484375, 7.332812499999989],
              [-81.65810546875, 7.327539062499994],
              [-81.77011718749999, 7.370361328125],
              [-81.85205078125, 7.453320312499997],
              [-81.85859375, 7.480175781249997],
              [-81.85693359375, 7.507666015624991],
              [-81.812158203125, 7.592382812499991],
              [-81.75229492187499, 7.621630859374989],
              [-81.728759765625, 7.621191406249991],
              [-81.67143554687499, 7.5234375],
              [-81.71044921875, 7.485546875],
              [-81.6947265625, 7.425],
              [-81.613427734375, 7.380175781249989],
              [-81.603271484375, 7.332812499999989],
            ],
          ],
          [
            [
              [-82.23349609374999, 9.380712890624991],
              [-82.24443359374999, 9.334082031249991],
              [-82.321728515625, 9.418115234374994],
              [-82.27578125, 9.431884765625],
              [-82.259423828125, 9.430273437499991],
              [-82.23349609374999, 9.380712890624991],
            ],
          ],
          [
            [
              [-79.0654296875, 8.254199218749989],
              [-79.1103515625, 8.209814453124991],
              [-79.12753906249999, 8.251855468749994],
              [-79.09628906249999, 8.29541015625],
              [-79.08530273437499, 8.295800781249994],
              [-79.0654296875, 8.254199218749989],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 3,
        labelrank: 5,
        sovereignt: "Trinidad and Tobago",
        sov_a3: "TTO",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Trinidad and Tobago",
        adm0_a3: "TTO",
        geou_dif: 0,
        geounit: "Trinidad and Tobago",
        gu_a3: "TTO",
        su_dif: 0,
        subunit: "Trinidad and Tobago",
        su_a3: "TTO",
        brk_diff: 0,
        name: "Trinidad and Tobago",
        name_long: "Trinidad and Tobago",
        brk_a3: "TTO",
        brk_name: "Trinidad and Tobago",
        brk_group: null,
        abbrev: "Tr.T.",
        postal: "TT",
        formal_en: "Republic of Trinidad and Tobago",
        formal_fr: null,
        name_ciawf: "Trinidad and Tobago",
        note_adm0: null,
        note_brk: null,
        name_sort: "Trinidad and Tobago",
        name_alt: null,
        mapcolor7: 5,
        mapcolor8: 6,
        mapcolor9: 2,
        mapcolor13: 5,
        pop_est: 1394973,
        pop_rank: 12,
        pop_year: 2019,
        gdp_md: 24269,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "2. High income: nonOECD",
        fips_10: "TD",
        iso_a2: "TT",
        iso_a2_eh: "TT",
        iso_a3: "TTO",
        iso_a3_eh: "TTO",
        iso_n3: "780",
        iso_n3_eh: "780",
        un_a3: "780",
        wb_a2: "TT",
        wb_a3: "TTO",
        woe_id: 23424958,
        woe_id_eh: 23424958,
        woe_note: "Exact WOE match as country",
        adm0_iso: "TTO",
        adm0_diff: null,
        adm0_tlc: "TTO",
        adm0_a3_us: "TTO",
        adm0_a3_fr: "TTO",
        adm0_a3_ru: "TTO",
        adm0_a3_es: "TTO",
        adm0_a3_cn: "TTO",
        adm0_a3_tw: "TTO",
        adm0_a3_in: "TTO",
        adm0_a3_np: "TTO",
        adm0_a3_pk: "TTO",
        adm0_a3_de: "TTO",
        adm0_a3_gb: "TTO",
        adm0_a3_br: "TTO",
        adm0_a3_il: "TTO",
        adm0_a3_ps: "TTO",
        adm0_a3_sa: "TTO",
        adm0_a3_eg: "TTO",
        adm0_a3_ma: "TTO",
        adm0_a3_pt: "TTO",
        adm0_a3_ar: "TTO",
        adm0_a3_jp: "TTO",
        adm0_a3_ko: "TTO",
        adm0_a3_vn: "TTO",
        adm0_a3_tr: "TTO",
        adm0_a3_id: "TTO",
        adm0_a3_pl: "TTO",
        adm0_a3_gr: "TTO",
        adm0_a3_it: "TTO",
        adm0_a3_nl: "TTO",
        adm0_a3_se: "TTO",
        adm0_a3_bd: "TTO",
        adm0_a3_ua: "TTO",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "North America",
        region_un: "Americas",
        subregion: "Caribbean",
        region_wb: "Latin America & Caribbean",
        name_len: 19,
        long_len: 19,
        abbrev_len: 5,
        tiny: 2,
        homepart: 1,
        min_zoom: 0,
        min_label: 4.5,
        max_label: 9.5,
        label_x: -60.9184,
        label_y: 10.9989,
        ne_id: 1159321321,
        wikidataid: "Q754",
        name_ar: "ترينيداد وتوباغو",
        name_bn: "ত্রিনিদাদ ও টোবাগো",
        name_de: "Trinidad und Tobago",
        name_en: "Trinidad and Tobago",
        name_es: "Trinidad y Tobago",
        name_fa: "ترینیداد و توباگو",
        name_fr: "Trinité-et-Tobago",
        name_el: "Τρινιντάντ και Τομπάγκο",
        name_he: "טרינידד וטובגו",
        name_hi: "त्रिनिदाद और टोबैगो",
        name_hu: "Trinidad és Tobago",
        name_id: "Trinidad dan Tobago",
        name_it: "Trinidad e Tobago",
        name_ja: "トリニダード・トバゴ",
        name_ko: "트리니다드 토바고",
        name_nl: "Trinidad en Tobago",
        name_pl: "Trynidad i Tobago",
        name_pt: "Trinidad e Tobago",
        name_ru: "Тринидад и Тобаго",
        name_sv: "Trinidad och Tobago",
        name_tr: "Trinidad ve Tobago",
        name_uk: "Тринідад і Тобаго",
        name_ur: "ٹرینیڈاڈ و ٹوباگو",
        name_vi: "Trinidad và Tobago",
        name_zh: "特立尼达和多巴哥",
        name_zht: "千里達及托巴哥",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "TTO.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-60.756298828125, 11.178515624999989],
              [-60.81064453124999, 11.168603515624994],
              [-60.804296875, 11.208398437499994],
              [-60.708935546875, 11.277246093749994],
              [-60.56279296874999, 11.323535156249989],
              [-60.52548828124999, 11.325390625],
              [-60.546484375, 11.263720703124989],
              [-60.756298828125, 11.178515624999989],
            ],
          ],
          [
            [
              [-61.012109375, 10.134326171874989],
              [-61.174267578125, 10.078027343749994],
              [-61.5966796875, 10.064648437499997],
              [-61.7716796875, 10.085058593749991],
              [-61.90610351562499, 10.069140624999989],
              [-61.66147460937499, 10.191699218749989],
              [-61.632714843749994, 10.243408203125],
              [-61.52885742187499, 10.253125],
              [-61.49931640624999, 10.2685546875],
              [-61.464746093749994, 10.538964843749994],
              [-61.478271484375, 10.603369140624991],
              [-61.49882812499999, 10.638867187499997],
              [-61.540917968749994, 10.664453125],
              [-61.63530273437499, 10.699365234374994],
              [-61.65117187499999, 10.718066406249989],
              [-61.59184570312499, 10.747949218749994],
              [-61.46484375, 10.764453124999989],
              [-61.370019531249994, 10.796826171874997],
              [-61.173730468749994, 10.803320312499991],
              [-61.078515625, 10.831933593749994],
              [-60.917626953124994, 10.840234375],
              [-60.996728515624994, 10.716162109374991],
              [-61.03374023437499, 10.669873046874997],
              [-61.01933593749999, 10.55810546875],
              [-61.0375, 10.482275390624991],
              [-61.01640624999999, 10.386376953124994],
              [-60.96845703125, 10.323388671874994],
              [-60.999609375, 10.261474609375],
              [-61.0041015625, 10.167822265624991],
              [-61.012109375, 10.134326171874989],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 4,
        labelrank: 4,
        sovereignt: "France",
        sov_a3: "FR1",
        adm0_dif: 1,
        level: 2,
        type: "Dependency",
        tlc: "1",
        admin: "Saint Pierre and Miquelon",
        adm0_a3: "SPM",
        geou_dif: 0,
        geounit: "Saint Pierre and Miquelon",
        gu_a3: "SPM",
        su_dif: 0,
        subunit: "Saint Pierre and Miquelon",
        su_a3: "SPM",
        brk_diff: 0,
        name: "St. Pierre and Miquelon",
        name_long: "Saint Pierre and Miquelon",
        brk_a3: "SPM",
        brk_name: "St. Pierre and Miquelon",
        brk_group: null,
        abbrev: "St. P.M.",
        postal: "PM",
        formal_en: "Saint Pierre and Miquelon",
        formal_fr: null,
        name_ciawf: "Saint Pierre and Miquelon",
        note_adm0: "Fr.",
        note_brk: null,
        name_sort: "St. Pierre and Miquelon",
        name_alt: null,
        mapcolor7: 7,
        mapcolor8: 5,
        mapcolor9: 9,
        mapcolor13: 11,
        pop_est: 5997,
        pop_rank: 5,
        pop_year: 2017,
        gdp_md: 215,
        gdp_year: 2016,
        economy: "2. Developed region: nonG7",
        income_grp: "3. Upper middle income",
        fips_10: "SB",
        iso_a2: "PM",
        iso_a2_eh: "PM",
        iso_a3: "SPM",
        iso_a3_eh: "SPM",
        iso_n3: "666",
        iso_n3_eh: "666",
        un_a3: "666",
        wb_a2: "-99",
        wb_a3: "-99",
        woe_id: 23424939,
        woe_id_eh: 23424939,
        woe_note: "Exact WOE match as country",
        adm0_iso: "SPM",
        adm0_diff: null,
        adm0_tlc: "SPM",
        adm0_a3_us: "SPM",
        adm0_a3_fr: "SPM",
        adm0_a3_ru: "SPM",
        adm0_a3_es: "SPM",
        adm0_a3_cn: "SPM",
        adm0_a3_tw: "SPM",
        adm0_a3_in: "SPM",
        adm0_a3_np: "SPM",
        adm0_a3_pk: "SPM",
        adm0_a3_de: "SPM",
        adm0_a3_gb: "SPM",
        adm0_a3_br: "SPM",
        adm0_a3_il: "SPM",
        adm0_a3_ps: "SPM",
        adm0_a3_sa: "SPM",
        adm0_a3_eg: "SPM",
        adm0_a3_ma: "SPM",
        adm0_a3_pt: "SPM",
        adm0_a3_ar: "SPM",
        adm0_a3_jp: "SPM",
        adm0_a3_ko: "SPM",
        adm0_a3_vn: "SPM",
        adm0_a3_tr: "SPM",
        adm0_a3_id: "SPM",
        adm0_a3_pl: "SPM",
        adm0_a3_gr: "SPM",
        adm0_a3_it: "SPM",
        adm0_a3_nl: "SPM",
        adm0_a3_se: "SPM",
        adm0_a3_bd: "SPM",
        adm0_a3_ua: "SPM",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "North America",
        region_un: "Americas",
        subregion: "Northern America",
        region_wb: "North America",
        name_len: 23,
        long_len: 25,
        abbrev_len: 8,
        tiny: 3,
        homepart: -99,
        min_zoom: 0,
        min_label: 5,
        max_label: 10,
        label_x: -56.332352,
        label_y: 47.040344,
        ne_id: 1159320647,
        wikidataid: "Q34617",
        name_ar: "سان بيير وميكلون",
        name_bn: "সাঁ পিয়ের ও মিকলোঁ",
        name_de: "Saint-Pierre und Miquelon",
        name_en: "Saint Pierre and Miquelon",
        name_es: "San Pedro y Miquelón",
        name_fa: "سن پیر و میکلن",
        name_fr: "Saint-Pierre-et-Miquelon",
        name_el: "Σαιν-Πιερ και Μικελόν",
        name_he: "סן-פייר ומיקלון",
        name_hi: "सन्त पियर और मिकलान",
        name_hu: "Saint-Pierre és Miquelon",
        name_id: "Saint Pierre dan Miquelon",
        name_it: "Saint-Pierre e Miquelon",
        name_ja: "サンピエール島・ミクロン島",
        name_ko: "생피에르 미클롱",
        name_nl: "Saint-Pierre en Miquelon",
        name_pl: "Saint-Pierre i Miquelon",
        name_pt: "Saint-Pierre e Miquelon",
        name_ru: "Сен-Пьер и Микелон",
        name_sv: "Saint-Pierre och Miquelon",
        name_tr: "Saint Pierre ve Miquelon",
        name_uk: "Сен-П'єр і Мікелон",
        name_ur: "سینٹ پیئر و میکیلون",
        name_vi: "Saint-Pierre và Miquelon",
        name_zh: "圣皮埃尔和密克隆",
        name_zht: "聖皮埃與密克隆群島",
        fclass_iso: "Admin-0 dependency",
        tlc_diff: null,
        fclass_tlc: "Admin-0 dependency",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "SPM.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-56.15073242187499, 46.76240234375],
              [-56.17167968749999, 46.752832031249994],
              [-56.24326171874999, 46.7671875],
              [-56.2091796875, 46.798242187499994],
              [-56.18505859375, 46.807275390624994],
              [-56.15263671874999, 46.811083984374996],
              [-56.137353515624994, 46.8015625],
              [-56.13925781249999, 46.778662109375],
              [-56.15073242187499, 46.76240234375],
            ],
          ],
          [
            [
              [-56.26708984375, 46.8384765625],
              [-56.35419921875, 46.7953125],
              [-56.384765625, 46.81943359375],
              [-56.37724609374999, 46.84765625],
              [-56.33256835937499, 46.915966796875],
              [-56.333935546875, 46.93564453125],
              [-56.3869140625, 47.06796875],
              [-56.3779296875, 47.08955078125],
              [-56.364648437499994, 47.098974609375],
              [-56.287353515625, 47.07099609375],
              [-56.27836914062499, 47.035009765625],
              [-56.314892578125, 46.953857421875],
              [-56.289794921875, 46.89990234375],
              [-56.25546875, 46.860986328124994],
              [-56.26708984375, 46.8384765625],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 6,
        sovereignt: "Grenada",
        sov_a3: "GRD",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Grenada",
        adm0_a3: "GRD",
        geou_dif: 0,
        geounit: "Grenada",
        gu_a3: "GRD",
        su_dif: 0,
        subunit: "Grenada",
        su_a3: "GRD",
        brk_diff: 0,
        name: "Grenada",
        name_long: "Grenada",
        brk_a3: "GRD",
        brk_name: "Grenada",
        brk_group: null,
        abbrev: "Gren.",
        postal: "GD",
        formal_en: "Grenada",
        formal_fr: null,
        name_ciawf: "Grenada",
        note_adm0: null,
        note_brk: null,
        name_sort: "Grenada",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 4,
        mapcolor9: 3,
        mapcolor13: 6,
        pop_est: 112003,
        pop_rank: 9,
        pop_year: 2019,
        gdp_md: 1210,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        fips_10: "GJ",
        iso_a2: "GD",
        iso_a2_eh: "GD",
        iso_a3: "GRD",
        iso_a3_eh: "GRD",
        iso_n3: "308",
        iso_n3_eh: "308",
        un_a3: "308",
        wb_a2: "GD",
        wb_a3: "GRD",
        woe_id: 23424826,
        woe_id_eh: 23424826,
        woe_note: "Exact WOE match as country",
        adm0_iso: "GRD",
        adm0_diff: null,
        adm0_tlc: "GRD",
        adm0_a3_us: "GRD",
        adm0_a3_fr: "GRD",
        adm0_a3_ru: "GRD",
        adm0_a3_es: "GRD",
        adm0_a3_cn: "GRD",
        adm0_a3_tw: "GRD",
        adm0_a3_in: "GRD",
        adm0_a3_np: "GRD",
        adm0_a3_pk: "GRD",
        adm0_a3_de: "GRD",
        adm0_a3_gb: "GRD",
        adm0_a3_br: "GRD",
        adm0_a3_il: "GRD",
        adm0_a3_ps: "GRD",
        adm0_a3_sa: "GRD",
        adm0_a3_eg: "GRD",
        adm0_a3_ma: "GRD",
        adm0_a3_pt: "GRD",
        adm0_a3_ar: "GRD",
        adm0_a3_jp: "GRD",
        adm0_a3_ko: "GRD",
        adm0_a3_vn: "GRD",
        adm0_a3_tr: "GRD",
        adm0_a3_id: "GRD",
        adm0_a3_pl: "GRD",
        adm0_a3_gr: "GRD",
        adm0_a3_it: "GRD",
        adm0_a3_nl: "GRD",
        adm0_a3_se: "GRD",
        adm0_a3_bd: "GRD",
        adm0_a3_ua: "GRD",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "North America",
        region_un: "Americas",
        subregion: "Caribbean",
        region_wb: "Latin America & Caribbean",
        name_len: 7,
        long_len: 7,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: -61.680461,
        label_y: 12.113156,
        ne_id: 1159320813,
        wikidataid: "Q769",
        name_ar: "غرينادا",
        name_bn: "গ্রেনাডা",
        name_de: "Grenada",
        name_en: "Grenada",
        name_es: "Granada",
        name_fa: "گرنادا",
        name_fr: "Grenade",
        name_el: "Γρενάδα",
        name_he: "גרנדה",
        name_hi: "ग्रेनाडा",
        name_hu: "Grenada",
        name_id: "Grenada",
        name_it: "Grenada",
        name_ja: "グレナダ",
        name_ko: "그레나다",
        name_nl: "Grenada",
        name_pl: "Grenada",
        name_pt: "Granada",
        name_ru: "Гренада",
        name_sv: "Grenada",
        name_tr: "Grenada",
        name_uk: "Гренада",
        name_ur: "گریناڈا",
        name_vi: "Grenada",
        name_zh: "格林纳达",
        name_zht: "格瑞那達",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "GRD.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-61.71552734375, 12.012646484374997],
            [-61.782177734375, 12.008447265624994],
            [-61.755761718749994, 12.045458984374989],
            [-61.749902343749994, 12.108447265624989],
            [-61.714990234374994, 12.18515625],
            [-61.66044921874999, 12.237011718749997],
            [-61.60703125, 12.223291015624994],
            [-61.6271484375, 12.053955078125],
            [-61.71552734375, 12.012646484374997],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 3,
        labelrank: 5,
        sovereignt: "Netherlands",
        sov_a3: "NL1",
        adm0_dif: 1,
        level: 2,
        type: "Country",
        tlc: "1",
        admin: "Aruba",
        adm0_a3: "ABW",
        geou_dif: 0,
        geounit: "Aruba",
        gu_a3: "ABW",
        su_dif: 0,
        subunit: "Aruba",
        su_a3: "ABW",
        brk_diff: 0,
        name: "Aruba",
        name_long: "Aruba",
        brk_a3: "ABW",
        brk_name: "Aruba",
        brk_group: null,
        abbrev: "Aruba",
        postal: "AW",
        formal_en: "Aruba",
        formal_fr: null,
        name_ciawf: "Aruba",
        note_adm0: "Neth.",
        note_brk: null,
        name_sort: "Aruba",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 2,
        mapcolor9: 2,
        mapcolor13: 9,
        pop_est: 106314,
        pop_rank: 9,
        pop_year: 2019,
        gdp_md: 3056,
        gdp_year: 2017,
        economy: "6. Developing region",
        income_grp: "2. High income: nonOECD",
        fips_10: "AA",
        iso_a2: "AW",
        iso_a2_eh: "AW",
        iso_a3: "ABW",
        iso_a3_eh: "ABW",
        iso_n3: "533",
        iso_n3_eh: "533",
        un_a3: "533",
        wb_a2: "AW",
        wb_a3: "ABW",
        woe_id: 23424736,
        woe_id_eh: 23424736,
        woe_note: "Exact WOE match as country",
        adm0_iso: "ABW",
        adm0_diff: null,
        adm0_tlc: "ABW",
        adm0_a3_us: "ABW",
        adm0_a3_fr: "ABW",
        adm0_a3_ru: "ABW",
        adm0_a3_es: "ABW",
        adm0_a3_cn: "ABW",
        adm0_a3_tw: "ABW",
        adm0_a3_in: "ABW",
        adm0_a3_np: "ABW",
        adm0_a3_pk: "ABW",
        adm0_a3_de: "ABW",
        adm0_a3_gb: "ABW",
        adm0_a3_br: "ABW",
        adm0_a3_il: "ABW",
        adm0_a3_ps: "ABW",
        adm0_a3_sa: "ABW",
        adm0_a3_eg: "ABW",
        adm0_a3_ma: "ABW",
        adm0_a3_pt: "ABW",
        adm0_a3_ar: "ABW",
        adm0_a3_jp: "ABW",
        adm0_a3_ko: "ABW",
        adm0_a3_vn: "ABW",
        adm0_a3_tr: "ABW",
        adm0_a3_id: "ABW",
        adm0_a3_pl: "ABW",
        adm0_a3_gr: "ABW",
        adm0_a3_it: "ABW",
        adm0_a3_nl: "ABW",
        adm0_a3_se: "ABW",
        adm0_a3_bd: "ABW",
        adm0_a3_ua: "ABW",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "North America",
        region_un: "Americas",
        subregion: "Caribbean",
        region_wb: "Latin America & Caribbean",
        name_len: 5,
        long_len: 5,
        abbrev_len: 5,
        tiny: 4,
        homepart: -99,
        min_zoom: 0,
        min_label: 5,
        max_label: 10,
        label_x: -69.972795,
        label_y: 12.5174,
        ne_id: 1159321097,
        wikidataid: "Q21203",
        name_ar: "أروبا",
        name_bn: "আরুবা",
        name_de: "Aruba",
        name_en: "Aruba",
        name_es: "Aruba",
        name_fa: "آروبا",
        name_fr: "Aruba",
        name_el: "Αρούμπα",
        name_he: "ארובה",
        name_hi: "अरूबा",
        name_hu: "Aruba",
        name_id: "Aruba",
        name_it: "Aruba",
        name_ja: "アルバ",
        name_ko: "아루바",
        name_nl: "Aruba",
        name_pl: "Aruba",
        name_pt: "Aruba",
        name_ru: "Аруба",
        name_sv: "Aruba",
        name_tr: "Aruba",
        name_uk: "Аруба",
        name_ur: "اروبا",
        name_vi: "Aruba",
        name_zh: "阿鲁巴",
        name_zht: "阿魯巴",
        fclass_iso: "Admin-0 dependency",
        tlc_diff: null,
        fclass_tlc: "Admin-0 dependency",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "ABW.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-69.89912109375, 12.452001953124991],
            [-69.895703125, 12.422998046874994],
            [-69.94218749999999, 12.438525390624989],
            [-70.004150390625, 12.50048828125],
            [-70.06611328125, 12.546972656249991],
            [-70.05087890624999, 12.597070312499994],
            [-70.035107421875, 12.614111328124991],
            [-69.97314453125, 12.567626953125],
            [-69.91181640625, 12.48046875],
            [-69.89912109375, 12.452001953124991],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 4,
        sovereignt: "The Bahamas",
        sov_a3: "BHS",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "The Bahamas",
        adm0_a3: "BHS",
        geou_dif: 0,
        geounit: "The Bahamas",
        gu_a3: "BHS",
        su_dif: 0,
        subunit: "The Bahamas",
        su_a3: "BHS",
        brk_diff: 0,
        name: "Bahamas",
        name_long: "Bahamas",
        brk_a3: "BHS",
        brk_name: "Bahamas",
        brk_group: null,
        abbrev: "Bhs.",
        postal: "BS",
        formal_en: "Commonwealth of the Bahamas",
        formal_fr: null,
        name_ciawf: "Bahamas, The",
        note_adm0: null,
        note_brk: null,
        name_sort: "Bahamas, The",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 1,
        mapcolor9: 2,
        mapcolor13: 5,
        pop_est: 389482,
        pop_rank: 10,
        pop_year: 2019,
        gdp_md: 13578,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "2. High income: nonOECD",
        fips_10: "BF",
        iso_a2: "BS",
        iso_a2_eh: "BS",
        iso_a3: "BHS",
        iso_a3_eh: "BHS",
        iso_n3: "044",
        iso_n3_eh: "044",
        un_a3: "044",
        wb_a2: "BS",
        wb_a3: "BHS",
        woe_id: 23424758,
        woe_id_eh: 23424758,
        woe_note: "Exact WOE match as country",
        adm0_iso: "BHS",
        adm0_diff: null,
        adm0_tlc: "BHS",
        adm0_a3_us: "BHS",
        adm0_a3_fr: "BHS",
        adm0_a3_ru: "BHS",
        adm0_a3_es: "BHS",
        adm0_a3_cn: "BHS",
        adm0_a3_tw: "BHS",
        adm0_a3_in: "BHS",
        adm0_a3_np: "BHS",
        adm0_a3_pk: "BHS",
        adm0_a3_de: "BHS",
        adm0_a3_gb: "BHS",
        adm0_a3_br: "BHS",
        adm0_a3_il: "BHS",
        adm0_a3_ps: "BHS",
        adm0_a3_sa: "BHS",
        adm0_a3_eg: "BHS",
        adm0_a3_ma: "BHS",
        adm0_a3_pt: "BHS",
        adm0_a3_ar: "BHS",
        adm0_a3_jp: "BHS",
        adm0_a3_ko: "BHS",
        adm0_a3_vn: "BHS",
        adm0_a3_tr: "BHS",
        adm0_a3_id: "BHS",
        adm0_a3_pl: "BHS",
        adm0_a3_gr: "BHS",
        adm0_a3_it: "BHS",
        adm0_a3_nl: "BHS",
        adm0_a3_se: "BHS",
        adm0_a3_bd: "BHS",
        adm0_a3_ua: "BHS",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "North America",
        region_un: "Americas",
        subregion: "Caribbean",
        region_wb: "Latin America & Caribbean",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: -77.146688,
        label_y: 26.401789,
        ne_id: 1159320415,
        wikidataid: "Q778",
        name_ar: "باهاماس",
        name_bn: "বাহামা দ্বীপপুঞ্জ",
        name_de: "Bahamas",
        name_en: "The Bahamas",
        name_es: "Bahamas",
        name_fa: "باهاما",
        name_fr: "Bahamas",
        name_el: "Μπαχάμες",
        name_he: "איי בהאמה",
        name_hi: "बहामास",
        name_hu: "Bahama-szigetek",
        name_id: "Bahama",
        name_it: "Bahamas",
        name_ja: "バハマ",
        name_ko: "바하마",
        name_nl: "Bahama's",
        name_pl: "Bahamy",
        name_pt: "Bahamas",
        name_ru: "Багамские Острова",
        name_sv: "Bahamas",
        name_tr: "Bahamalar",
        name_uk: "Багамські Острови",
        name_ur: "بہاماس",
        name_vi: "Bahamas",
        name_zh: "巴哈马",
        name_zht: "巴哈馬",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "BHS.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-77.65771484375, 24.249462890624997],
              [-77.65615234375, 24.2265625],
              [-77.7552734375, 24.163476562499994],
              [-77.683251953125, 24.118457031250003],
              [-77.615380859375, 24.216357421875003],
              [-77.5615234375, 24.13681640624999],
              [-77.53203124999999, 23.98764648437499],
              [-77.53681640625, 23.961669921875],
              [-77.531884765625, 23.939404296874997],
              [-77.52133789062499, 23.910839843749997],
              [-77.51875, 23.869433593750003],
              [-77.57373046875, 23.739160156249994],
              [-77.77128906249999, 23.75253906249999],
              [-77.77578125, 23.862353515625003],
              [-77.806298828125, 23.883544921875],
              [-77.85224609375, 24.04038085937499],
              [-77.9140625, 24.09091796874999],
              [-77.99990234375, 24.21982421874999],
              [-77.95004882812499, 24.253076171874994],
              [-77.88359374999999, 24.241992187500003],
              [-77.849560546875, 24.257519531249997],
              [-77.757421875, 24.269921875],
              [-77.70146484374999, 24.287548828124997],
              [-77.65771484375, 24.249462890624997],
            ],
          ],
          [
            [
              [-77.22563476562499, 25.904199218749994],
              [-77.24643554687499, 25.895458984374997],
              [-77.333251953125, 25.99560546875],
              [-77.40317382812499, 26.024707031249996],
              [-77.2939453125, 26.095507812499996],
              [-77.24677734375, 26.15634765625],
              [-77.24775390625, 26.2890625],
              [-77.22109375, 26.361767578124997],
              [-77.230126953125, 26.424707031249994],
              [-77.2060546875, 26.488964843749997],
              [-77.23862304687499, 26.5611328125],
              [-77.32993164062499, 26.618359375],
              [-77.51059570312499, 26.845996093749996],
              [-77.79599609374999, 26.901269531249994],
              [-77.94375, 26.903564453125],
              [-77.862548828125, 26.940087890624994],
              [-77.787548828125, 26.93564453125],
              [-77.672119140625, 26.913916015625],
              [-77.53388671875, 26.90341796875],
              [-77.4494140625, 26.83642578125],
              [-77.36875, 26.747607421874996],
              [-77.2958984375, 26.711669921875],
              [-77.26591796874999, 26.688818359375],
              [-77.269287109375, 26.663037109374997],
              [-77.25717773437499, 26.638818359374994],
              [-77.162109375, 26.597265625],
              [-77.066357421875, 26.530175781249994],
              [-77.03828125, 26.333447265624997],
              [-77.16728515624999, 26.24033203125],
              [-77.191015625, 25.95546875],
              [-77.22563476562499, 25.904199218749994],
            ],
          ],
          [
            [
              [-73.02685546875, 21.1923828125],
              [-73.058740234375, 21.119042968749994],
              [-73.16455078125, 20.979150390624994],
              [-73.40078125, 20.943896484375003],
              [-73.66103515625, 20.937402343749994],
              [-73.68115234375, 20.9755859375],
              [-73.68681640624999, 21.00913085937499],
              [-73.66782226562499, 21.061572265625003],
              [-73.669580078125, 21.082226562499997],
              [-73.68037109375, 21.103320312500003],
              [-73.58505859374999, 21.125927734374997],
              [-73.523095703125, 21.190820312499994],
              [-73.42451171875, 21.20175781249999],
              [-73.30156249999999, 21.156152343749994],
              [-73.2353515625, 21.154492187499997],
              [-73.13730468749999, 21.204785156249997],
              [-73.05849609375, 21.313378906249994],
              [-73.011669921875, 21.299511718749997],
              [-73.02685546875, 21.1923828125],
            ],
          ],
          [
            [
              [-77.74384765625, 24.707421875],
              [-77.746044921875, 24.586328125],
              [-77.735107421875, 24.495751953124994],
              [-77.74521484374999, 24.46347656249999],
              [-77.85341796875, 24.40292968749999],
              [-77.88120117187499, 24.369091796874997],
              [-77.98320312499999, 24.3349609375],
              [-78.044921875, 24.28745117187499],
              [-78.075830078125, 24.364648437499994],
              [-78.1357421875, 24.412353515625],
              [-78.14580078124999, 24.493457031250003],
              [-78.1916015625, 24.466064453125],
              [-78.25761718749999, 24.48276367187499],
              [-78.36650390624999, 24.544189453125],
              [-78.435302734375, 24.627587890624994],
              [-78.33891601562499, 24.642041015624997],
              [-78.318994140625, 24.590234375],
              [-78.24272460937499, 24.65380859375],
              [-78.26005859374999, 24.687304687500003],
              [-78.273828125, 24.691601562499997],
              [-78.298828125, 24.75390625],
              [-78.18408203125, 24.91708984374999],
              [-78.159326171875, 25.022363281249994],
              [-78.211376953125, 25.19125976562499],
              [-78.16279296875, 25.20234375],
              [-78.03330078124999, 25.143115234375003],
              [-77.97529296875, 25.08481445312499],
              [-77.973388671875, 25.004785156249994],
              [-77.9189453125, 24.942822265624997],
              [-77.84013671874999, 24.794384765624997],
              [-77.74384765625, 24.707421875],
            ],
          ],
          [
            [
              [-78.49287109375, 26.729052734374996],
              [-78.371728515625, 26.697949218749997],
              [-78.3068359375, 26.702197265624996],
              [-78.26791992187499, 26.72265625],
              [-78.08867187499999, 26.714306640624997],
              [-77.94394531249999, 26.74423828125],
              [-77.92246093749999, 26.691113281249997],
              [-77.92612304687499, 26.663378906249996],
              [-78.23388671875, 26.637353515624994],
              [-78.51621093749999, 26.559375],
              [-78.67094726562499, 26.506542968749997],
              [-78.74365234375, 26.500683593749997],
              [-78.79921875, 26.528466796874994],
              [-78.98564453124999, 26.689501953124996],
              [-78.935791015625, 26.6734375],
              [-78.798046875, 26.582421875],
              [-78.7125, 26.599023437499994],
              [-78.633251953125, 26.6591796875],
              [-78.62114257812499, 26.704638671874996],
              [-78.632958984375, 26.726171875],
              [-78.597119140625, 26.79794921875],
              [-78.49287109375, 26.729052734374996],
            ],
          ],
          [
            [
              [-77.34755859375, 25.013867187499997],
              [-77.46049804687499, 24.993115234374997],
              [-77.5412109375, 25.013574218749994],
              [-77.5619140625, 25.030029296875],
              [-77.52734375, 25.057666015625003],
              [-77.45126953124999, 25.080712890624994],
              [-77.3291015625, 25.0830078125],
              [-77.2755859375, 25.05576171874999],
              [-77.269140625, 25.043847656249994],
              [-77.34755859375, 25.013867187499997],
            ],
          ],
          [
            [
              [-74.05751953125, 22.72348632812499],
              [-74.034765625, 22.70556640625],
              [-74.098583984375, 22.665429687499994],
              [-74.24223632812499, 22.715087890625],
              [-74.274609375, 22.711669921875],
              [-74.303125, 22.764453125],
              [-74.31396484375, 22.80356445312499],
              [-74.30703125, 22.839599609375],
              [-74.22148437499999, 22.811572265625003],
              [-74.175390625, 22.759912109374994],
              [-74.05751953125, 22.72348632812499],
            ],
          ],
          [
            [
              [-74.429443359375, 24.068066406249997],
              [-74.50869140625, 23.959716796875],
              [-74.550927734375, 23.968945312499997],
              [-74.52690429687499, 24.105078125],
              [-74.47202148437499, 24.126660156249997],
              [-74.45048828124999, 24.12548828125],
              [-74.429443359375, 24.068066406249997],
            ],
          ],
          [
            [
              [-74.20673828125, 22.213769531249994],
              [-74.27690429687499, 22.18369140624999],
              [-74.261328125, 22.235546875],
              [-74.12675781249999, 22.323388671874994],
              [-74.05234375, 22.400634765625],
              [-74.01005859374999, 22.427978515625],
              [-73.99497070312499, 22.44921875],
              [-73.935986328125, 22.477734375],
              [-73.906396484375, 22.52744140624999],
              [-73.91455078125, 22.568017578124994],
              [-73.97636718749999, 22.635058593750003],
              [-73.97548828125, 22.682275390624994],
              [-73.95419921874999, 22.715527343749997],
              [-73.84995117187499, 22.73105468749999],
              [-73.87749023437499, 22.68076171874999],
              [-73.83652343749999, 22.53842773437499],
              [-73.974609375, 22.36118164062499],
              [-74.092919921875, 22.30625],
              [-74.20673828125, 22.213769531249994],
            ],
          ],
          [
            [
              [-76.648828125, 25.48740234374999],
              [-76.48422851562499, 25.374609375],
              [-76.34379882812499, 25.33203125],
              [-76.19199218749999, 25.190820312499994],
              [-76.126611328125, 25.140527343749994],
              [-76.11494140625, 25.0947265625],
              [-76.14052734375, 24.885644531249994],
              [-76.17465820312499, 24.759765625],
              [-76.16953125, 24.6494140625],
              [-76.20517578124999, 24.682080078124997],
              [-76.2412109375, 24.754345703124997],
              [-76.30029296875, 24.7958984375],
              [-76.319970703125, 24.817675781250003],
              [-76.21376953125, 24.822460937499997],
              [-76.204345703125, 24.936230468749997],
              [-76.1525390625, 25.02597656249999],
              [-76.160400390625, 25.119335937499997],
              [-76.284326171875, 25.222119140624997],
              [-76.369287109375, 25.31259765624999],
              [-76.49990234375, 25.341552734375],
              [-76.620703125, 25.431640625],
              [-76.6927734375, 25.44272460937499],
              [-76.7806640625, 25.42685546874999],
              [-76.74892578125, 25.48056640624999],
              [-76.726953125, 25.55161132812499],
              [-76.71083984375, 25.564892578124997],
              [-76.648828125, 25.48740234374999],
            ],
          ],
          [
            [
              [-75.66455078125, 23.450146484374997],
              [-75.70634765624999, 23.444238281249994],
              [-75.781005859375, 23.470654296874997],
              [-75.95595703125, 23.59228515625],
              [-76.037109375, 23.602783203125],
              [-76.01044921875, 23.67138671875],
              [-75.9486328125, 23.647412109374997],
              [-75.80751953125, 23.542529296875003],
              [-75.75424804687499, 23.489990234375],
              [-75.66455078125, 23.450146484374997],
            ],
          ],
          [
            [
              [-74.840478515625, 22.894335937500003],
              [-74.846875, 22.868701171875003],
              [-74.97333984375, 23.068554687499997],
              [-75.13212890624999, 23.117089843749994],
              [-75.22333984375, 23.165332031250003],
              [-75.20439453124999, 23.19272460937499],
              [-75.14111328125, 23.204638671875003],
              [-75.13056640625, 23.26791992187499],
              [-75.15756835937499, 23.336376953124997],
              [-75.24125976562499, 23.474609375],
              [-75.288232421875, 23.568261718749994],
              [-75.309814453125, 23.58984375],
              [-75.31596679687499, 23.668359375],
              [-75.21660156249999, 23.546777343749994],
              [-75.17529296875, 23.438671875],
              [-75.1087890625, 23.3328125],
              [-75.064208984375, 23.150195312500003],
              [-74.937109375, 23.088134765625],
              [-74.84560546875, 22.999902343749994],
              [-74.840478515625, 22.894335937500003],
            ],
          ],
          [
            [
              [-75.30839843749999, 24.2],
              [-75.3017578125, 24.149169921875],
              [-75.36875, 24.159472656250003],
              [-75.46762695312499, 24.139599609374997],
              [-75.50322265624999, 24.1390625],
              [-75.48105468749999, 24.173876953125003],
              [-75.41240234374999, 24.220947265625],
              [-75.408935546875, 24.265771484374994],
              [-75.493896484375, 24.33041992187499],
              [-75.5927734375, 24.491259765625003],
              [-75.6390625, 24.52939453124999],
              [-75.66103515625, 24.58984375],
              [-75.74399414062499, 24.6546875],
              [-75.72666015624999, 24.689355468749994],
              [-75.709619140625, 24.697509765625],
              [-75.653515625, 24.680859375],
              [-75.52646484374999, 24.449511718750003],
              [-75.51816406249999, 24.42734375],
              [-75.30839843749999, 24.2],
            ],
          ],
          [
            [
              [-73.041015625, 22.42905273437499],
              [-72.978955078125, 22.414599609375003],
              [-72.94521484375, 22.415625],
              [-72.83076171875, 22.38559570312499],
              [-72.76259765625, 22.344384765624994],
              [-72.747265625, 22.327392578125],
              [-72.78388671875, 22.290625],
              [-72.88916015625, 22.360253906249994],
              [-72.98105468749999, 22.36923828124999],
              [-73.11020507812499, 22.367578125],
              [-73.16191406249999, 22.38071289062499],
              [-73.127392578125, 22.455322265625],
              [-73.041015625, 22.42905273437499],
            ],
          ],
          [
            [
              [-72.91611328124999, 21.50668945312499],
              [-73.04931640625, 21.45761718749999],
              [-73.0626953125, 21.515332031249997],
              [-72.994775390625, 21.56162109374999],
              [-72.91611328124999, 21.50668945312499],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 3,
        labelrank: 6,
        sovereignt: "United Kingdom",
        sov_a3: "GB1",
        adm0_dif: 1,
        level: 2,
        type: "Dependency",
        tlc: "1",
        admin: "Turks and Caicos Islands",
        adm0_a3: "TCA",
        geou_dif: 0,
        geounit: "Turks and Caicos Islands",
        gu_a3: "TCA",
        su_dif: 0,
        subunit: "Turks and Caicos Islands",
        su_a3: "TCA",
        brk_diff: 0,
        name: "Turks and Caicos Is.",
        name_long: "Turks and Caicos Islands",
        brk_a3: "TCA",
        brk_name: "Turks and Caicos Is.",
        brk_group: null,
        abbrev: "T.C. Is.",
        postal: "TC",
        formal_en: "Turks and Caicos Islands",
        formal_fr: null,
        name_ciawf: "Turks and Caicos Islands",
        note_adm0: "U.K.",
        note_brk: null,
        name_sort: "Turks and Caicos Islands",
        name_alt: null,
        mapcolor7: 6,
        mapcolor8: 6,
        mapcolor9: 6,
        mapcolor13: 3,
        pop_est: 38191,
        pop_rank: 7,
        pop_year: 2019,
        gdp_md: 1197,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "2. High income: nonOECD",
        fips_10: "TK",
        iso_a2: "TC",
        iso_a2_eh: "TC",
        iso_a3: "TCA",
        iso_a3_eh: "TCA",
        iso_n3: "796",
        iso_n3_eh: "796",
        un_a3: "796",
        wb_a2: "TC",
        wb_a3: "TCA",
        woe_id: 23424962,
        woe_id_eh: 23424962,
        woe_note: "Exact WOE match as country",
        adm0_iso: "TCA",
        adm0_diff: null,
        adm0_tlc: "TCA",
        adm0_a3_us: "TCA",
        adm0_a3_fr: "TCA",
        adm0_a3_ru: "TCA",
        adm0_a3_es: "TCA",
        adm0_a3_cn: "TCA",
        adm0_a3_tw: "TCA",
        adm0_a3_in: "TCA",
        adm0_a3_np: "TCA",
        adm0_a3_pk: "TCA",
        adm0_a3_de: "TCA",
        adm0_a3_gb: "TCA",
        adm0_a3_br: "TCA",
        adm0_a3_il: "TCA",
        adm0_a3_ps: "TCA",
        adm0_a3_sa: "TCA",
        adm0_a3_eg: "TCA",
        adm0_a3_ma: "TCA",
        adm0_a3_pt: "TCA",
        adm0_a3_ar: "TCA",
        adm0_a3_jp: "TCA",
        adm0_a3_ko: "TCA",
        adm0_a3_vn: "TCA",
        adm0_a3_tr: "TCA",
        adm0_a3_id: "TCA",
        adm0_a3_pl: "TCA",
        adm0_a3_gr: "TCA",
        adm0_a3_it: "TCA",
        adm0_a3_nl: "TCA",
        adm0_a3_se: "TCA",
        adm0_a3_bd: "TCA",
        adm0_a3_ua: "TCA",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "North America",
        region_un: "Americas",
        subregion: "Caribbean",
        region_wb: "Latin America & Caribbean",
        name_len: 20,
        long_len: 24,
        abbrev_len: 8,
        tiny: -99,
        homepart: -99,
        min_zoom: 0,
        min_label: 5,
        max_label: 10,
        label_x: -71.752704,
        label_y: 21.81663,
        ne_id: 1159320737,
        wikidataid: "Q18221",
        name_ar: "جزر توركس وكايكوس",
        name_bn: "টার্কস ও কেইকোস দ্বীপপুঞ্জ",
        name_de: "Turks- und Caicosinseln",
        name_en: "Turks and Caicos Islands",
        name_es: "Islas Turcas y Caicos",
        name_fa: "جزایر تورکس و کایکوس",
        name_fr: "îles Turques-et-Caïques",
        name_el: "Τερκ και Κάικος",
        name_he: "איי טרקס וקייקוס",
        name_hi: "तुर्क और केकोस द्वीपसमूह",
        name_hu: "Turks- és Caicos-szigetek",
        name_id: "Kepulauan Turks dan Caicos",
        name_it: "Turks e Caicos",
        name_ja: "タークス・カイコス諸島",
        name_ko: "터크스 케이커스 제도",
        name_nl: "Turks- en Caicoseilanden",
        name_pl: "Turks i Caicos",
        name_pt: "Turks e Caicos",
        name_ru: "Тёркс и Кайкос",
        name_sv: "Turks- och Caicosöarna",
        name_tr: "Turks ve Caicos Adaları",
        name_uk: "Острови Теркс і Кайкос",
        name_ur: "جزائر کیکس و ترکیہ",
        name_vi: "Quần đảo Turks và Caicos",
        name_zh: "特克斯和凯科斯群岛",
        name_zht: "土克凱可群島",
        fclass_iso: "Admin-0 dependency",
        tlc_diff: null,
        fclass_tlc: "Admin-0 dependency",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "TCA.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-71.66142578124999, 21.765234375],
              [-71.66538085937499, 21.751708984375],
              [-71.72177734374999, 21.790234375],
              [-71.83041992187499, 21.790625],
              [-71.84765625, 21.843457031249997],
              [-71.80615234375, 21.852099609375003],
              [-71.668359375, 21.833447265624997],
              [-71.6369140625, 21.787548828124997],
              [-71.66142578124999, 21.765234375],
            ],
          ],
          [
            [
              [-71.87993164062499, 21.84042968749999],
              [-71.8974609375, 21.829882812500003],
              [-71.95546875, 21.864404296874994],
              [-71.96376953125, 21.892041015624997],
              [-71.984521484375, 21.89340820312499],
              [-72.01904296875, 21.918261718750003],
              [-72.01064453125, 21.950439453125],
              [-71.93154296875, 21.951904296875],
              [-71.899609375, 21.8625],
              [-71.87993164062499, 21.84042968749999],
            ],
          ],
          [
            [
              [-72.33281249999999, 21.851367187500003],
              [-72.21865234375, 21.796289062499994],
              [-72.1498046875, 21.804492187500003],
              [-72.14433593749999, 21.792724609375],
              [-72.18154296875, 21.780029296875],
              [-72.190673828125, 21.769775390625],
              [-72.30087890624999, 21.75522460937499],
              [-72.33544921875, 21.758007812499997],
              [-72.34238281249999, 21.7953125],
              [-72.33281249999999, 21.851367187500003],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 6,
        sovereignt: "Saint Vincent and the Grenadines",
        sov_a3: "VCT",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Saint Vincent and the Grenadines",
        adm0_a3: "VCT",
        geou_dif: 0,
        geounit: "Saint Vincent and the Grenadines",
        gu_a3: "VCT",
        su_dif: 0,
        subunit: "Saint Vincent and the Grenadines",
        su_a3: "VCT",
        brk_diff: 0,
        name: "St. Vin. and Gren.",
        name_long: "Saint Vincent and the Grenadines",
        brk_a3: "VCT",
        brk_name: "St. Vin. and Gren.",
        brk_group: null,
        abbrev: "St.V.G.",
        postal: "VC",
        formal_en: "Saint Vincent and the Grenadines",
        formal_fr: null,
        name_ciawf: "Saint Vincent and the Grenadines",
        note_adm0: null,
        note_brk: null,
        name_sort: "St. Vincent and the Grenadines",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 1,
        mapcolor9: 5,
        mapcolor13: 7,
        pop_est: 110589,
        pop_rank: 9,
        pop_year: 2019,
        gdp_md: 824,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        fips_10: "VC",
        iso_a2: "VC",
        iso_a2_eh: "VC",
        iso_a3: "VCT",
        iso_a3_eh: "VCT",
        iso_n3: "670",
        iso_n3_eh: "670",
        un_a3: "670",
        wb_a2: "VC",
        wb_a3: "VCT",
        woe_id: 23424981,
        woe_id_eh: 23424981,
        woe_note: "Exact WOE match as country",
        adm0_iso: "VCT",
        adm0_diff: null,
        adm0_tlc: "VCT",
        adm0_a3_us: "VCT",
        adm0_a3_fr: "VCT",
        adm0_a3_ru: "VCT",
        adm0_a3_es: "VCT",
        adm0_a3_cn: "VCT",
        adm0_a3_tw: "VCT",
        adm0_a3_in: "VCT",
        adm0_a3_np: "VCT",
        adm0_a3_pk: "VCT",
        adm0_a3_de: "VCT",
        adm0_a3_gb: "VCT",
        adm0_a3_br: "VCT",
        adm0_a3_il: "VCT",
        adm0_a3_ps: "VCT",
        adm0_a3_sa: "VCT",
        adm0_a3_eg: "VCT",
        adm0_a3_ma: "VCT",
        adm0_a3_pt: "VCT",
        adm0_a3_ar: "VCT",
        adm0_a3_jp: "VCT",
        adm0_a3_ko: "VCT",
        adm0_a3_vn: "VCT",
        adm0_a3_tr: "VCT",
        adm0_a3_id: "VCT",
        adm0_a3_pl: "VCT",
        adm0_a3_gr: "VCT",
        adm0_a3_it: "VCT",
        adm0_a3_nl: "VCT",
        adm0_a3_se: "VCT",
        adm0_a3_bd: "VCT",
        adm0_a3_ua: "VCT",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "North America",
        region_un: "Americas",
        subregion: "Caribbean",
        region_wb: "Latin America & Caribbean",
        name_len: 18,
        long_len: 32,
        abbrev_len: 7,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 5,
        max_label: 10,
        label_x: -61.3359,
        label_y: 13.0879,
        ne_id: 1159321409,
        wikidataid: "Q757",
        name_ar: "سانت فينسنت والغرينادين",
        name_bn: "সেন্ট ভিনসেন্ট ও গ্রেনাডাইন",
        name_de: "St. Vincent und die Grenadinen",
        name_en: "Saint Vincent and the Grenadines",
        name_es: "San Vicente y las Granadinas",
        name_fa: "سنت وینسنت و گرنادینها",
        name_fr: "Saint-Vincent-et-les-Grenadines",
        name_el: "Άγιος Βικέντιος και Γρεναδίνες",
        name_he: "סנט וינסנט והגרנדינים",
        name_hi: "सन्त विन्सेण्ट और ग्रेनाडाइन्स",
        name_hu: "Saint Vincent és a Grenadine-szigetek",
        name_id: "Saint Vincent dan Grenadine",
        name_it: "Saint Vincent e Grenadine",
        name_ja: "セントビンセント・グレナディーン",
        name_ko: "세인트빈센트 그레나딘",
        name_nl: "Saint Vincent en de Grenadines",
        name_pl: "Saint Vincent i Grenadyny",
        name_pt: "São Vicente e Granadinas",
        name_ru: "Сент-Винсент и Гренадины",
        name_sv: "Saint Vincent och Grenadinerna",
        name_tr: "Saint Vincent ve Grenadinler",
        name_uk: "Сент-Вінсент і Гренадини",
        name_ur: "سینٹ وینسینٹ و گریناڈائنز",
        name_vi: "Saint Vincent và Grenadines",
        name_zh: "圣文森特和格林纳丁斯",
        name_zht: "聖文森及格瑞那丁",
        fclass_iso: "Admin-0 dependency",
        tlc_diff: null,
        fclass_tlc: "Admin-0 dependency",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "VCT.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-61.17451171875, 13.158105468749994],
              [-61.20390624999999, 13.142285156249997],
              [-61.277294921875, 13.209570312499991],
              [-61.268457031249994, 13.287695312499991],
              [-61.224072265625, 13.330664062499991],
              [-61.18212890625, 13.35595703125],
              [-61.13896484374999, 13.358740234374991],
              [-61.1240234375, 13.294042968749991],
              [-61.134521484375, 13.202880859375],
              [-61.17451171875, 13.158105468749994],
            ],
          ],
          [
            [
              [-61.226220703124994, 12.99462890625],
              [-61.24223632812499, 12.983691406249989],
              [-61.23471679687499, 12.983691406249989],
              [-61.24223632812499, 12.976269531249997],
              [-61.247265625, 12.983642578125],
              [-61.25522460937499, 12.988183593749994],
              [-61.26533203125, 12.990185546874997],
              [-61.276953125, 12.989892578124994],
              [-61.276953125, 12.997363281249989],
              [-61.26206054687499, 12.992773437499991],
              [-61.23935546874999, 12.997265625],
              [-61.2392578125, 13.00537109375],
              [-61.24594726562499, 13.0126953125],
              [-61.2490234375, 13.017822265625],
              [-61.240625, 13.025732421874991],
              [-61.212841796875, 13.043261718749989],
              [-61.201171875, 13.052539062499989],
              [-61.19980468749999, 13.048730468749994],
              [-61.19931640624999, 13.045117187499997],
              [-61.19794921875, 13.041699218749997],
              [-61.19375, 13.03828125],
              [-61.20805664062499, 13.024707031249989],
              [-61.226220703124994, 12.99462890625],
            ],
          ],
          [
            [
              [-61.334375, 12.695214843749994],
              [-61.34453124999999, 12.694726562499994],
              [-61.353515625, 12.698144531249994],
              [-61.35112304687499, 12.701171875],
              [-61.339746093749994, 12.70361328125],
              [-61.334423828125, 12.710205078125],
              [-61.336328125, 12.719042968749989],
              [-61.335839843749994, 12.728808593749989],
              [-61.326806640624994, 12.734814453124997],
              [-61.31987304687499, 12.735449218749991],
              [-61.316796875, 12.731689453125],
              [-61.31484375, 12.722558593749994],
              [-61.32011718749999, 12.715527343749997],
              [-61.325927734375, 12.709863281249994],
              [-61.32880859375, 12.701123046874997],
              [-61.334375, 12.695214843749994],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 5,
        sovereignt: "Barbados",
        sov_a3: "BRB",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Barbados",
        adm0_a3: "BRB",
        geou_dif: 0,
        geounit: "Barbados",
        gu_a3: "BRB",
        su_dif: 0,
        subunit: "Barbados",
        su_a3: "BRB",
        brk_diff: 0,
        name: "Barbados",
        name_long: "Barbados",
        brk_a3: "BRB",
        brk_name: "Barbados",
        brk_group: null,
        abbrev: "Barb.",
        postal: "BB",
        formal_en: "Barbados",
        formal_fr: null,
        name_ciawf: "Barbados",
        note_adm0: null,
        note_brk: null,
        name_sort: "Barbados",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 1,
        mapcolor9: 5,
        mapcolor13: 3,
        pop_est: 287025,
        pop_rank: 10,
        pop_year: 2019,
        gdp_md: 5209,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "2. High income: nonOECD",
        fips_10: "BB",
        iso_a2: "BB",
        iso_a2_eh: "BB",
        iso_a3: "BRB",
        iso_a3_eh: "BRB",
        iso_n3: "052",
        iso_n3_eh: "052",
        un_a3: "052",
        wb_a2: "BB",
        wb_a3: "BRB",
        woe_id: 23424754,
        woe_id_eh: 23424754,
        woe_note: "Exact WOE match as country",
        adm0_iso: "BRB",
        adm0_diff: null,
        adm0_tlc: "BRB",
        adm0_a3_us: "BRB",
        adm0_a3_fr: "BRB",
        adm0_a3_ru: "BRB",
        adm0_a3_es: "BRB",
        adm0_a3_cn: "BRB",
        adm0_a3_tw: "BRB",
        adm0_a3_in: "BRB",
        adm0_a3_np: "BRB",
        adm0_a3_pk: "BRB",
        adm0_a3_de: "BRB",
        adm0_a3_gb: "BRB",
        adm0_a3_br: "BRB",
        adm0_a3_il: "BRB",
        adm0_a3_ps: "BRB",
        adm0_a3_sa: "BRB",
        adm0_a3_eg: "BRB",
        adm0_a3_ma: "BRB",
        adm0_a3_pt: "BRB",
        adm0_a3_ar: "URY",
        adm0_a3_jp: "BRB",
        adm0_a3_ko: "BRB",
        adm0_a3_vn: "BRB",
        adm0_a3_tr: "BRB",
        adm0_a3_id: "BRB",
        adm0_a3_pl: "BRB",
        adm0_a3_gr: "BRB",
        adm0_a3_it: "BRB",
        adm0_a3_nl: "BRB",
        adm0_a3_se: "BRB",
        adm0_a3_bd: "BRB",
        adm0_a3_ua: "BRB",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "North America",
        region_un: "Americas",
        subregion: "Caribbean",
        region_wb: "Latin America & Caribbean",
        name_len: 8,
        long_len: 8,
        abbrev_len: 5,
        tiny: 3,
        homepart: 1,
        min_zoom: 0,
        min_label: 4.5,
        max_label: 9.5,
        label_x: -59.568966,
        label_y: 13.163709,
        ne_id: 1159320449,
        wikidataid: "Q244",
        name_ar: "باربادوس",
        name_bn: "বার্বাডোস",
        name_de: "Barbados",
        name_en: "Barbados",
        name_es: "Barbados",
        name_fa: "باربادوس",
        name_fr: "Barbade",
        name_el: "Μπαρμπάντος",
        name_he: "ברבדוס",
        name_hi: "बारबाडोस",
        name_hu: "Barbados",
        name_id: "Barbados",
        name_it: "Barbados",
        name_ja: "バルバドス",
        name_ko: "바베이도스",
        name_nl: "Barbados",
        name_pl: "Barbados",
        name_pt: "Barbados",
        name_ru: "Барбадос",
        name_sv: "Barbados",
        name_tr: "Barbados",
        name_uk: "Барбадос",
        name_ur: "بارباڈوس",
        name_vi: "Barbados",
        name_zh: "巴巴多斯",
        name_zht: "巴貝多",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "BRB.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-59.493310546874994, 13.081982421874997],
            [-59.521875, 13.062207031249997],
            [-59.611328125, 13.102099609374989],
            [-59.6427734375, 13.150292968749994],
            [-59.6466796875, 13.303125],
            [-59.59160156249999, 13.317675781250003],
            [-59.487890625, 13.196826171874989],
            [-59.427636718749994, 13.152783203124997],
            [-59.493310546874994, 13.081982421874997],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 6,
        sovereignt: "Saint Lucia",
        sov_a3: "LCA",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Saint Lucia",
        adm0_a3: "LCA",
        geou_dif: 0,
        geounit: "Saint Lucia",
        gu_a3: "LCA",
        su_dif: 0,
        subunit: "Saint Lucia",
        su_a3: "LCA",
        brk_diff: 0,
        name: "Saint Lucia",
        name_long: "Saint Lucia",
        brk_a3: "LCA",
        brk_name: "Saint Lucia",
        brk_group: null,
        abbrev: "S.L.",
        postal: "LC",
        formal_en: "Saint Lucia",
        formal_fr: null,
        name_ciawf: "Saint Lucia",
        note_adm0: null,
        note_brk: null,
        name_sort: "St. Lucia",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 4,
        mapcolor9: 3,
        mapcolor13: 4,
        pop_est: 182790,
        pop_rank: 9,
        pop_year: 2019,
        gdp_md: 2122,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        fips_10: "ST",
        iso_a2: "LC",
        iso_a2_eh: "LC",
        iso_a3: "LCA",
        iso_a3_eh: "LCA",
        iso_n3: "662",
        iso_n3_eh: "662",
        un_a3: "662",
        wb_a2: "LC",
        wb_a3: "LCA",
        woe_id: 23424951,
        woe_id_eh: 23424951,
        woe_note: "Exact WOE match as country",
        adm0_iso: "LCA",
        adm0_diff: null,
        adm0_tlc: "LCA",
        adm0_a3_us: "LCA",
        adm0_a3_fr: "LCA",
        adm0_a3_ru: "LCA",
        adm0_a3_es: "LCA",
        adm0_a3_cn: "LCA",
        adm0_a3_tw: "LCA",
        adm0_a3_in: "LCA",
        adm0_a3_np: "LCA",
        adm0_a3_pk: "LCA",
        adm0_a3_de: "LCA",
        adm0_a3_gb: "LCA",
        adm0_a3_br: "LCA",
        adm0_a3_il: "LCA",
        adm0_a3_ps: "LCA",
        adm0_a3_sa: "LCA",
        adm0_a3_eg: "LCA",
        adm0_a3_ma: "LCA",
        adm0_a3_pt: "LCA",
        adm0_a3_ar: "LCA",
        adm0_a3_jp: "LCA",
        adm0_a3_ko: "LCA",
        adm0_a3_vn: "LCA",
        adm0_a3_tr: "LCA",
        adm0_a3_id: "LCA",
        adm0_a3_pl: "LCA",
        adm0_a3_gr: "LCA",
        adm0_a3_it: "LCA",
        adm0_a3_nl: "LCA",
        adm0_a3_se: "LCA",
        adm0_a3_bd: "LCA",
        adm0_a3_ua: "LCA",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "North America",
        region_un: "Americas",
        subregion: "Caribbean",
        region_wb: "Latin America & Caribbean",
        name_len: 11,
        long_len: 11,
        abbrev_len: 4,
        tiny: 4,
        homepart: 1,
        min_zoom: 0,
        min_label: 5,
        max_label: 9.5,
        label_x: -60.980094,
        label_y: 13.892371,
        ne_id: 1159321019,
        wikidataid: "Q760",
        name_ar: "سانت لوسيا",
        name_bn: "সেন্ট লুসিয়া",
        name_de: "St. Lucia",
        name_en: "Saint Lucia",
        name_es: "Santa Lucía",
        name_fa: "سنت لوسیا",
        name_fr: "Sainte-Lucie",
        name_el: "Αγία Λουκία",
        name_he: "סנט לוסיה",
        name_hi: "सेंट लूसिया",
        name_hu: "Saint Lucia",
        name_id: "Saint Lucia",
        name_it: "Saint Lucia",
        name_ja: "セントルシア",
        name_ko: "세인트루시아",
        name_nl: "Saint Lucia",
        name_pl: "Saint Lucia",
        name_pt: "Santa Lúcia",
        name_ru: "Сент-Люсия",
        name_sv: "Saint Lucia",
        name_tr: "Saint Lucia",
        name_uk: "Сент-Люсія",
        name_ur: "سینٹ لوسیا",
        name_vi: "Saint Lucia",
        name_zh: "圣卢西亚",
        name_zht: "聖露西亞",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "LCA.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-60.89521484375, 13.821972656249997],
            [-60.951416015625, 13.717578125],
            [-61.06064453124999, 13.783105468749994],
            [-61.073144531249994, 13.865576171874991],
            [-61.06357421874999, 13.915576171875003],
            [-60.99667968749999, 14.0109375],
            [-60.944580078125, 14.072851562499991],
            [-60.908105468749994, 14.093359375],
            [-60.88676757812499, 14.011132812499994],
            [-60.89521484375, 13.821972656249997],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 6,
        sovereignt: "Dominica",
        sov_a3: "DMA",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Dominica",
        adm0_a3: "DMA",
        geou_dif: 0,
        geounit: "Dominica",
        gu_a3: "DMA",
        su_dif: 0,
        subunit: "Dominica",
        su_a3: "DMA",
        brk_diff: 0,
        name: "Dominica",
        name_long: "Dominica",
        brk_a3: "DMA",
        brk_name: "Dominica",
        brk_group: null,
        abbrev: "D'inca",
        postal: "DM",
        formal_en: "Commonwealth of Dominica",
        formal_fr: null,
        name_ciawf: "Dominica",
        note_adm0: null,
        note_brk: null,
        name_sort: "Dominica",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 5,
        mapcolor9: 2,
        mapcolor13: 12,
        pop_est: 71808,
        pop_rank: 8,
        pop_year: 2019,
        gdp_md: 582,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        fips_10: "DO",
        iso_a2: "DM",
        iso_a2_eh: "DM",
        iso_a3: "DMA",
        iso_a3_eh: "DMA",
        iso_n3: "212",
        iso_n3_eh: "212",
        un_a3: "212",
        wb_a2: "DM",
        wb_a3: "DMA",
        woe_id: 23424798,
        woe_id_eh: 23424798,
        woe_note: "Exact WOE match as country",
        adm0_iso: "DMA",
        adm0_diff: null,
        adm0_tlc: "DMA",
        adm0_a3_us: "DMA",
        adm0_a3_fr: "DMA",
        adm0_a3_ru: "DMA",
        adm0_a3_es: "DMA",
        adm0_a3_cn: "DMA",
        adm0_a3_tw: "DMA",
        adm0_a3_in: "DMA",
        adm0_a3_np: "DMA",
        adm0_a3_pk: "DMA",
        adm0_a3_de: "DMA",
        adm0_a3_gb: "DMA",
        adm0_a3_br: "DMA",
        adm0_a3_il: "DMA",
        adm0_a3_ps: "DMA",
        adm0_a3_sa: "DMA",
        adm0_a3_eg: "DMA",
        adm0_a3_ma: "DMA",
        adm0_a3_pt: "DMA",
        adm0_a3_ar: "DMA",
        adm0_a3_jp: "DMA",
        adm0_a3_ko: "DMA",
        adm0_a3_vn: "DMA",
        adm0_a3_tr: "DMA",
        adm0_a3_id: "DMA",
        adm0_a3_pl: "DMA",
        adm0_a3_gr: "DMA",
        adm0_a3_it: "DMA",
        adm0_a3_nl: "DMA",
        adm0_a3_se: "DMA",
        adm0_a3_bd: "DMA",
        adm0_a3_ua: "DMA",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "North America",
        region_un: "Americas",
        subregion: "Caribbean",
        region_wb: "Latin America & Caribbean",
        name_len: 8,
        long_len: 8,
        abbrev_len: 6,
        tiny: 4,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: -61.344958,
        label_y: 15.458829,
        ne_id: 1159320543,
        wikidataid: "Q784",
        name_ar: "دومينيكا",
        name_bn: "ডোমিনিকা",
        name_de: "Dominica",
        name_en: "Dominica",
        name_es: "Dominica",
        name_fa: "دومینیکا",
        name_fr: "Dominique",
        name_el: "Δομινίκα",
        name_he: "דומיניקה",
        name_hi: "डोमिनिका",
        name_hu: "Dominikai Közösség",
        name_id: "Dominika",
        name_it: "Dominica",
        name_ja: "ドミニカ国",
        name_ko: "도미니카 연방",
        name_nl: "Dominica",
        name_pl: "Dominika",
        name_pt: "Dominica",
        name_ru: "Доминика",
        name_sv: "Dominica",
        name_tr: "Dominika",
        name_uk: "Домініка",
        name_ur: "ڈومینیکا",
        name_vi: "Dominica",
        name_zh: "多米尼克",
        name_zht: "多米尼克",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "DMA.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-61.281689453125, 15.2490234375],
            [-61.375390625, 15.227294921875],
            [-61.41572265625, 15.399853515624997],
            [-61.48115234375, 15.525146484375],
            [-61.469921875, 15.603466796874997],
            [-61.45810546874999, 15.633105468750003],
            [-61.32001953125, 15.585058593749991],
            [-61.277246093749994, 15.526708984374991],
            [-61.25107421874999, 15.373144531249991],
            [-61.281689453125, 15.2490234375],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 3,
        labelrank: 6,
        sovereignt: "United Kingdom",
        sov_a3: "GB1",
        adm0_dif: 1,
        level: 2,
        type: "Dependency",
        tlc: "1",
        admin: "Montserrat",
        adm0_a3: "MSR",
        geou_dif: 0,
        geounit: "Montserrat",
        gu_a3: "MSR",
        su_dif: 0,
        subunit: "Montserrat",
        su_a3: "MSR",
        brk_diff: 0,
        name: "Montserrat",
        name_long: "Montserrat",
        brk_a3: "MSR",
        brk_name: "Montserrat",
        brk_group: null,
        abbrev: "Monts.",
        postal: "MS",
        formal_en: null,
        formal_fr: null,
        name_ciawf: "Montserrat",
        note_adm0: "U.K.",
        note_brk: null,
        name_sort: "Montserrat",
        name_alt: null,
        mapcolor7: 6,
        mapcolor8: 6,
        mapcolor9: 6,
        mapcolor13: 3,
        pop_est: 4649,
        pop_rank: 4,
        pop_year: 2019,
        gdp_md: 44,
        gdp_year: 2006,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        fips_10: "MH",
        iso_a2: "MS",
        iso_a2_eh: "MS",
        iso_a3: "MSR",
        iso_a3_eh: "MSR",
        iso_n3: "500",
        iso_n3_eh: "500",
        un_a3: "500",
        wb_a2: "-99",
        wb_a3: "-99",
        woe_id: 23424888,
        woe_id_eh: 23424888,
        woe_note: "Exact WOE match as country",
        adm0_iso: "MSR",
        adm0_diff: null,
        adm0_tlc: "MSR",
        adm0_a3_us: "MSR",
        adm0_a3_fr: "MSR",
        adm0_a3_ru: "MSR",
        adm0_a3_es: "MSR",
        adm0_a3_cn: "MSR",
        adm0_a3_tw: "MSR",
        adm0_a3_in: "MSR",
        adm0_a3_np: "MSR",
        adm0_a3_pk: "MSR",
        adm0_a3_de: "MSR",
        adm0_a3_gb: "MSR",
        adm0_a3_br: "MSR",
        adm0_a3_il: "MSR",
        adm0_a3_ps: "MSR",
        adm0_a3_sa: "MSR",
        adm0_a3_eg: "MSR",
        adm0_a3_ma: "MSR",
        adm0_a3_pt: "MSR",
        adm0_a3_ar: "MSR",
        adm0_a3_jp: "MSR",
        adm0_a3_ko: "MSR",
        adm0_a3_vn: "MSR",
        adm0_a3_tr: "MSR",
        adm0_a3_id: "MSR",
        adm0_a3_pl: "MSR",
        adm0_a3_gr: "MSR",
        adm0_a3_it: "MSR",
        adm0_a3_nl: "MSR",
        adm0_a3_se: "MSR",
        adm0_a3_bd: "MSR",
        adm0_a3_ua: "MSR",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "North America",
        region_un: "Americas",
        subregion: "Caribbean",
        region_wb: "Latin America & Caribbean",
        name_len: 10,
        long_len: 10,
        abbrev_len: 6,
        tiny: 6,
        homepart: -99,
        min_zoom: 0,
        min_label: 5,
        max_label: 10,
        label_x: -62.188252,
        label_y: 16.73717,
        ne_id: 1159320727,
        wikidataid: "Q13353",
        name_ar: "مونتسرات",
        name_bn: "মন্টসেরাট",
        name_de: "Montserrat",
        name_en: "Montserrat",
        name_es: "Montserrat",
        name_fa: "مونتسرات",
        name_fr: "Montserrat",
        name_el: "Μοντσερράτ",
        name_he: "מונטסראט",
        name_hi: "मॉण्टसेराट",
        name_hu: "Montserrat",
        name_id: "Montserrat",
        name_it: "Montserrat",
        name_ja: "モントセラト",
        name_ko: "몬트세랫",
        name_nl: "Montserrat",
        name_pl: "Montserrat",
        name_pt: "Montserrat",
        name_ru: "Монтсеррат",
        name_sv: "Montserrat",
        name_tr: "Montserrat",
        name_uk: "Монтсеррат",
        name_ur: "مانٹسریٹ",
        name_vi: "Montserrat",
        name_zh: "蒙特塞拉特",
        name_zht: "蒙哲臘",
        fclass_iso: "Admin-0 dependency",
        tlc_diff: null,
        fclass_tlc: "Admin-0 dependency",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "MSR.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-62.1484375, 16.74033203124999],
            [-62.154248046875, 16.681201171875003],
            [-62.221630859375, 16.699511718750003],
            [-62.223046875, 16.7515625],
            [-62.191357421875, 16.804394531249997],
            [-62.17578125, 16.8095703125],
            [-62.1484375, 16.74033203124999],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 6,
        sovereignt: "Antigua and Barbuda",
        sov_a3: "ATG",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Antigua and Barbuda",
        adm0_a3: "ATG",
        geou_dif: 0,
        geounit: "Antigua and Barbuda",
        gu_a3: "ATG",
        su_dif: 0,
        subunit: "Antigua and Barbuda",
        su_a3: "ATG",
        brk_diff: 0,
        name: "Antigua and Barb.",
        name_long: "Antigua and Barbuda",
        brk_a3: "ATG",
        brk_name: "Antigua and Barb.",
        brk_group: null,
        abbrev: "Ant.B.",
        postal: "AG",
        formal_en: "Antigua and Barbuda",
        formal_fr: null,
        name_ciawf: "Antigua and Barbuda",
        note_adm0: null,
        note_brk: null,
        name_sort: "Antigua and Barbuda",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 2,
        mapcolor9: 5,
        mapcolor13: 5,
        pop_est: 97118,
        pop_rank: 8,
        pop_year: 2019,
        gdp_md: 1661,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        fips_10: "AC",
        iso_a2: "AG",
        iso_a2_eh: "AG",
        iso_a3: "ATG",
        iso_a3_eh: "ATG",
        iso_n3: "028",
        iso_n3_eh: "028",
        un_a3: "028",
        wb_a2: "AG",
        wb_a3: "ATG",
        woe_id: 23424737,
        woe_id_eh: 23424737,
        woe_note: "Exact WOE match as country",
        adm0_iso: "ATG",
        adm0_diff: null,
        adm0_tlc: "ATG",
        adm0_a3_us: "ATG",
        adm0_a3_fr: "ATG",
        adm0_a3_ru: "ATG",
        adm0_a3_es: "ATG",
        adm0_a3_cn: "ATG",
        adm0_a3_tw: "ATG",
        adm0_a3_in: "ATG",
        adm0_a3_np: "ATG",
        adm0_a3_pk: "ATG",
        adm0_a3_de: "ATG",
        adm0_a3_gb: "ATG",
        adm0_a3_br: "ATG",
        adm0_a3_il: "ATG",
        adm0_a3_ps: "ATG",
        adm0_a3_sa: "ATG",
        adm0_a3_eg: "ATG",
        adm0_a3_ma: "ATG",
        adm0_a3_pt: "ATG",
        adm0_a3_ar: "ATG",
        adm0_a3_jp: "ATG",
        adm0_a3_ko: "ATG",
        adm0_a3_vn: "ATG",
        adm0_a3_tr: "ATG",
        adm0_a3_id: "ATG",
        adm0_a3_pl: "ATG",
        adm0_a3_gr: "ATG",
        adm0_a3_it: "ATG",
        adm0_a3_nl: "ATG",
        adm0_a3_se: "ATG",
        adm0_a3_bd: "ATG",
        adm0_a3_ua: "ATG",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "North America",
        region_un: "Americas",
        subregion: "Caribbean",
        region_wb: "Latin America & Caribbean",
        name_len: 17,
        long_len: 19,
        abbrev_len: 6,
        tiny: 4,
        homepart: 1,
        min_zoom: 0,
        min_label: 5,
        max_label: 9.5,
        label_x: -61.790612,
        label_y: 17.352249,
        ne_id: 1159320345,
        wikidataid: "Q781",
        name_ar: "أنتيغوا وباربودا",
        name_bn: "অ্যান্টিগুয়া ও বার্বুডা",
        name_de: "Antigua und Barbuda",
        name_en: "Antigua and Barbuda",
        name_es: "Antigua y Barbuda",
        name_fa: "آنتیگوا و باربودا",
        name_fr: "Antigua-et-Barbuda",
        name_el: "Αντίγκουα και Μπαρμπούντα",
        name_he: "אנטיגואה וברבודה",
        name_hi: "अण्टीगुआ और बारबूडा",
        name_hu: "Antigua és Barbuda",
        name_id: "Antigua dan Barbuda",
        name_it: "Antigua e Barbuda",
        name_ja: "アンティグア・バーブーダ",
        name_ko: "앤티가 바부다",
        name_nl: "Antigua en Barbuda",
        name_pl: "Antigua i Barbuda",
        name_pt: "Antígua e Barbuda",
        name_ru: "Антигуа и Барбуда",
        name_sv: "Antigua och Barbuda",
        name_tr: "Antigua ve Barbuda",
        name_uk: "Антигуа і Барбуда",
        name_ur: "اینٹیگوا و باربوڈا",
        name_vi: "Antigua và Barbuda",
        name_zh: "安提瓜和巴布达",
        name_zht: "安地卡及巴布達",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "ATG.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-61.716064453125, 17.037011718749994],
              [-61.74814453124999, 16.99716796874999],
              [-61.85966796874999, 17.013330078124994],
              [-61.88203125, 17.063134765624994],
              [-61.887109375, 17.09814453125],
              [-61.817285156249994, 17.1689453125],
              [-61.73857421874999, 17.138476562500003],
              [-61.708203125, 17.105078125],
              [-61.68603515625, 17.0984375],
              [-61.686474609375, 17.06982421875],
              [-61.694970703124994, 17.04892578124999],
              [-61.716064453125, 17.037011718749994],
            ],
          ],
          [
            [
              [-61.74711914062499, 17.574951171875],
              [-61.76201171874999, 17.54868164062499],
              [-61.84379882812499, 17.596142578124997],
              [-61.86875, 17.685449218749994],
              [-61.866162109375, 17.704296875],
              [-61.852441406249994, 17.7140625],
              [-61.819921875, 17.696875],
              [-61.776757812499994, 17.690478515625003],
              [-61.749609375, 17.661328125],
              [-61.74711914062499, 17.574951171875],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 3,
        labelrank: 6,
        sovereignt: "Saint Kitts and Nevis",
        sov_a3: "KNA",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Saint Kitts and Nevis",
        adm0_a3: "KNA",
        geou_dif: 0,
        geounit: "Saint Kitts and Nevis",
        gu_a3: "KNA",
        su_dif: 0,
        subunit: "Saint Kitts and Nevis",
        su_a3: "KNA",
        brk_diff: 0,
        name: "St. Kitts and Nevis",
        name_long: "Saint Kitts and Nevis",
        brk_a3: "KNA",
        brk_name: "Saint Kitts and Nevis",
        brk_group: null,
        abbrev: "St.K.N.",
        postal: "KN",
        formal_en: "Federation of Saint Kitts and Nevis",
        formal_fr: null,
        name_ciawf: "Saint Kitts and Nevis",
        note_adm0: null,
        note_brk: null,
        name_sort: "St. Kitts and Nevis",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 4,
        mapcolor9: 1,
        mapcolor13: 8,
        pop_est: 52834,
        pop_rank: 8,
        pop_year: 2019,
        gdp_md: 1053,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "2. High income: nonOECD",
        fips_10: "SC",
        iso_a2: "KN",
        iso_a2_eh: "KN",
        iso_a3: "KNA",
        iso_a3_eh: "KNA",
        iso_n3: "659",
        iso_n3_eh: "659",
        un_a3: "659",
        wb_a2: "KN",
        wb_a3: "KNA",
        woe_id: 23424940,
        woe_id_eh: 23424940,
        woe_note: "Exact WOE match as country",
        adm0_iso: "KNA",
        adm0_diff: null,
        adm0_tlc: "KNA",
        adm0_a3_us: "KNA",
        adm0_a3_fr: "KNA",
        adm0_a3_ru: "KNA",
        adm0_a3_es: "KNA",
        adm0_a3_cn: "KNA",
        adm0_a3_tw: "KNA",
        adm0_a3_in: "KNA",
        adm0_a3_np: "KNA",
        adm0_a3_pk: "KNA",
        adm0_a3_de: "KNA",
        adm0_a3_gb: "KNA",
        adm0_a3_br: "KNA",
        adm0_a3_il: "KNA",
        adm0_a3_ps: "KNA",
        adm0_a3_sa: "KNA",
        adm0_a3_eg: "KNA",
        adm0_a3_ma: "KNA",
        adm0_a3_pt: "KNA",
        adm0_a3_ar: "KNA",
        adm0_a3_jp: "KNA",
        adm0_a3_ko: "KNA",
        adm0_a3_vn: "KNA",
        adm0_a3_tr: "KNA",
        adm0_a3_id: "KNA",
        adm0_a3_pl: "KNA",
        adm0_a3_gr: "KNA",
        adm0_a3_it: "KNA",
        adm0_a3_nl: "KNA",
        adm0_a3_se: "KNA",
        adm0_a3_bd: "KNA",
        adm0_a3_ua: "KNA",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "North America",
        region_un: "Americas",
        subregion: "Caribbean",
        region_wb: "Latin America & Caribbean",
        name_len: 19,
        long_len: 21,
        abbrev_len: 7,
        tiny: 4,
        homepart: 1,
        min_zoom: 0,
        min_label: 5,
        max_label: 10,
        label_x: -62.757975,
        label_y: 17.336558,
        ne_id: 1159320983,
        wikidataid: "Q763",
        name_ar: "سانت كيتس ونيفيس",
        name_bn: "সেন্ট কিট্স ও নেভিস",
        name_de: "St. Kitts und Nevis",
        name_en: "Saint Kitts and Nevis",
        name_es: "San Cristóbal y Nieves",
        name_fa: "سنت کیتس و نویس",
        name_fr: "Saint-Christophe-et-Niévès",
        name_el: "Άγιος Χριστόφορος και Νέβις",
        name_he: "סנט קיטס ונוויס",
        name_hi: "सन्त किट्स और नेविस",
        name_hu: "Saint Kitts és Nevis",
        name_id: "Saint Kitts dan Nevis",
        name_it: "Saint Kitts e Nevis",
        name_ja: "セントクリストファー・ネイビス",
        name_ko: "세인트키츠 네비스",
        name_nl: "Saint Kitts en Nevis",
        name_pl: "Saint Kitts i Nevis",
        name_pt: "São Cristóvão e Nevis",
        name_ru: "Сент-Китс и Невис",
        name_sv: "Saint Kitts och Nevis",
        name_tr: "Saint Kitts ve Nevis",
        name_uk: "Сент-Кіттс і Невіс",
        name_ur: "سینٹ کیٹز و ناویس",
        name_vi: "Saint Kitts và Nevis",
        name_zh: "圣基茨和尼维斯",
        name_zht: "聖克里斯多福與尼維斯",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "KNA.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-62.5322265625, 17.121875],
              [-62.582421875, 17.1005859375],
              [-62.624902343749994, 17.129589843749997],
              [-62.61528320312499, 17.199121093749994],
              [-62.57470703125, 17.20102539062499],
              [-62.5341796875, 17.170117187499997],
              [-62.5322265625, 17.121875],
            ],
          ],
          [
            [
              [-62.63066406249999, 17.239990234375],
              [-62.656494140625, 17.224414062500003],
              [-62.70200195312499, 17.286035156249994],
              [-62.775537109374994, 17.30283203124999],
              [-62.83891601562499, 17.339257812499994],
              [-62.840478515624994, 17.347070312499994],
              [-62.83940429687499, 17.36533203124999],
              [-62.827050781249994, 17.386425781249997],
              [-62.79462890625, 17.402587890625],
              [-62.71372070312499, 17.353271484375],
              [-62.67578125, 17.290917968749994],
              [-62.640527343749994, 17.26230468749999],
              [-62.63066406249999, 17.239990234375],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 3,
        labelrank: 6,
        sovereignt: "United States of America",
        sov_a3: "US1",
        adm0_dif: 1,
        level: 2,
        type: "Dependency",
        tlc: "1",
        admin: "United States Virgin Islands",
        adm0_a3: "VIR",
        geou_dif: 0,
        geounit: "United States Virgin Islands",
        gu_a3: "VIR",
        su_dif: 0,
        subunit: "United States Virgin Islands",
        su_a3: "VIR",
        brk_diff: 0,
        name: "U.S. Virgin Is.",
        name_long: "United States Virgin Islands",
        brk_a3: "VIR",
        brk_name: "U.S. Virgin Is.",
        brk_group: null,
        abbrev: "V.I. (U.S.)",
        postal: "VI",
        formal_en: "Virgin Islands of the United States",
        formal_fr: null,
        name_ciawf: "Virgin Islands",
        note_adm0: "U.S.A.",
        note_brk: null,
        name_sort: "Virgin Islands (U.S.)",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 5,
        mapcolor9: 1,
        mapcolor13: 1,
        pop_est: 106631,
        pop_rank: 9,
        pop_year: 2019,
        gdp_md: 3855,
        gdp_year: 2017,
        economy: "6. Developing region",
        income_grp: "2. High income: nonOECD",
        fips_10: "VQ",
        iso_a2: "VI",
        iso_a2_eh: "VI",
        iso_a3: "VIR",
        iso_a3_eh: "VIR",
        iso_n3: "850",
        iso_n3_eh: "850",
        un_a3: "850",
        wb_a2: "VI",
        wb_a3: "VIR",
        woe_id: 23424985,
        woe_id_eh: 23424985,
        woe_note: "Exact WOE match as country",
        adm0_iso: "VIR",
        adm0_diff: null,
        adm0_tlc: "VIR",
        adm0_a3_us: "VIR",
        adm0_a3_fr: "VIR",
        adm0_a3_ru: "VIR",
        adm0_a3_es: "VIR",
        adm0_a3_cn: "VIR",
        adm0_a3_tw: "VIR",
        adm0_a3_in: "VIR",
        adm0_a3_np: "VIR",
        adm0_a3_pk: "VIR",
        adm0_a3_de: "VIR",
        adm0_a3_gb: "VIR",
        adm0_a3_br: "VIR",
        adm0_a3_il: "VIR",
        adm0_a3_ps: "VIR",
        adm0_a3_sa: "VIR",
        adm0_a3_eg: "VIR",
        adm0_a3_ma: "VIR",
        adm0_a3_pt: "VIR",
        adm0_a3_ar: "VIR",
        adm0_a3_jp: "VIR",
        adm0_a3_ko: "VIR",
        adm0_a3_vn: "VIR",
        adm0_a3_tr: "VIR",
        adm0_a3_id: "VIR",
        adm0_a3_pl: "VIR",
        adm0_a3_gr: "VIR",
        adm0_a3_it: "VIR",
        adm0_a3_nl: "VIR",
        adm0_a3_se: "VIR",
        adm0_a3_bd: "VIR",
        adm0_a3_ua: "VIR",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "North America",
        region_un: "Americas",
        subregion: "Caribbean",
        region_wb: "Latin America & Caribbean",
        name_len: 15,
        long_len: 28,
        abbrev_len: 11,
        tiny: 3,
        homepart: -99,
        min_zoom: 0,
        min_label: 5,
        max_label: 10,
        label_x: -64.779172,
        label_y: 17.746706,
        ne_id: 1159321371,
        wikidataid: "Q11703",
        name_ar: "جزر العذراء الأمريكية",
        name_bn: "মার্কিন ভার্জিন দ্বীপপুঞ্জ",
        name_de: "Amerikanische Jungferninseln",
        name_en: "United States Virgin Islands",
        name_es: "Islas Vírgenes de los Estados Unidos",
        name_fa: "جزایر ویرجین ایالات متحده",
        name_fr: "îles Vierges des États-Unis",
        name_el: "Αμερικανικές Παρθένοι Νήσοι",
        name_he: "איי הבתולה של ארצות הברית",
        name_hi: "संयुक्त राज्य वर्जिन द्वीपसमूह",
        name_hu: "Amerikai Virgin-szigetek",
        name_id: "Kepulauan Virgin Amerika Serikat",
        name_it: "Isole Vergini americane",
        name_ja: "アメリカ領ヴァージン諸島",
        name_ko: "미국령 버진아일랜드",
        name_nl: "Amerikaanse Maagdeneilanden",
        name_pl: "Wyspy Dziewicze Stanów Zjednoczonych",
        name_pt: "Ilhas Virgens Americanas",
        name_ru: "Американские Виргинские острова",
        name_sv: "Amerikanska Jungfruöarna",
        name_tr: "ABD Virjin Adaları",
        name_uk: "Американські Віргінські Острови",
        name_ur: "امریکی جزائر ورجن",
        name_vi: "Quần đảo Virgin thuộc Mỹ",
        name_zh: "美属维尔京群岛",
        name_zht: "美屬維京群島",
        fclass_iso: "Admin-0 dependency",
        tlc_diff: null,
        fclass_tlc: "Admin-0 dependency",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "VIR.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-64.84501953124999, 18.330078125],
              [-64.91997070312499, 18.3212890625],
              [-65.0236328125, 18.367578125],
              [-64.942041015625, 18.385205078124997],
              [-64.889111328125, 18.37421875],
              [-64.84501953124999, 18.330078125],
            ],
          ],
          [
            [
              [-64.659814453125, 18.35434570312499],
              [-64.7259765625, 18.327880859375],
              [-64.77060546874999, 18.331591796875003],
              [-64.78769531249999, 18.341113281250003],
              [-64.75244140625, 18.371972656249994],
              [-64.659814453125, 18.35434570312499],
            ],
          ],
          [
            [
              [-64.765625, 17.794335937499994],
              [-64.6818359375, 17.750195312499997],
              [-64.58046875, 17.750195312499997],
              [-64.686279296875, 17.706103515625003],
              [-64.889111328125, 17.701708984375003],
              [-64.884716796875, 17.772265625],
              [-64.765625, 17.794335937499994],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 3,
        labelrank: 6,
        sovereignt: "France",
        sov_a3: "FR1",
        adm0_dif: 1,
        level: 2,
        type: "Dependency",
        tlc: "1",
        admin: "Saint Barthelemy",
        adm0_a3: "BLM",
        geou_dif: 0,
        geounit: "Saint Barthelemy",
        gu_a3: "BLM",
        su_dif: 0,
        subunit: "Saint Barthelemy",
        su_a3: "BLM",
        brk_diff: 0,
        name: "St-Barthélemy",
        name_long: "Saint-Barthélemy",
        brk_a3: "BLM",
        brk_name: "St-Barthélemy",
        brk_group: null,
        abbrev: "St. B.",
        postal: "BL",
        formal_en: "Saint-Barthélemy",
        formal_fr: null,
        name_ciawf: "Saint Barthelemy",
        note_adm0: "Fr.",
        note_brk: null,
        name_sort: "St-Barthélemy",
        name_alt: null,
        mapcolor7: 7,
        mapcolor8: 5,
        mapcolor9: 9,
        mapcolor13: 11,
        pop_est: 9961,
        pop_rank: 5,
        pop_year: 2017,
        gdp_md: 255,
        gdp_year: 2016,
        economy: "2. Developed region: nonG7",
        income_grp: "1. High income: OECD",
        fips_10: "TB",
        iso_a2: "BL",
        iso_a2_eh: "BL",
        iso_a3: "BLM",
        iso_a3_eh: "BLM",
        iso_n3: "652",
        iso_n3_eh: "652",
        un_a3: "652",
        wb_a2: "-99",
        wb_a3: "-99",
        woe_id: 56042304,
        woe_id_eh: 56042304,
        woe_note: "Exact WOE match as country",
        adm0_iso: "BLM",
        adm0_diff: null,
        adm0_tlc: "BLM",
        adm0_a3_us: "BLM",
        adm0_a3_fr: "BLM",
        adm0_a3_ru: "BLM",
        adm0_a3_es: "BLM",
        adm0_a3_cn: "BLM",
        adm0_a3_tw: "BLM",
        adm0_a3_in: "BLM",
        adm0_a3_np: "BLM",
        adm0_a3_pk: "BLM",
        adm0_a3_de: "BLM",
        adm0_a3_gb: "BLM",
        adm0_a3_br: "BLM",
        adm0_a3_il: "BLM",
        adm0_a3_ps: "BLM",
        adm0_a3_sa: "BLM",
        adm0_a3_eg: "BLM",
        adm0_a3_ma: "BLM",
        adm0_a3_pt: "BLM",
        adm0_a3_ar: "BLM",
        adm0_a3_jp: "BLM",
        adm0_a3_ko: "BLM",
        adm0_a3_vn: "BLM",
        adm0_a3_tr: "BLM",
        adm0_a3_id: "BLM",
        adm0_a3_pl: "BLM",
        adm0_a3_gr: "BLM",
        adm0_a3_it: "BLM",
        adm0_a3_nl: "BLM",
        adm0_a3_se: "BLM",
        adm0_a3_bd: "BLM",
        adm0_a3_ua: "BLM",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "North America",
        region_un: "Americas",
        subregion: "Caribbean",
        region_wb: "Latin America & Caribbean",
        name_len: 13,
        long_len: 16,
        abbrev_len: 6,
        tiny: 4,
        homepart: -99,
        min_zoom: 0,
        min_label: 5.7,
        max_label: 10,
        label_x: -62.833193,
        label_y: 17.901987,
        ne_id: 1159320633,
        wikidataid: "Q25362",
        name_ar: "سان بارتيلمي",
        name_bn: "সেন্ট-বার্থেলেমি",
        name_de: "Saint-Barthélemy",
        name_en: "Saint Barthélemy",
        name_es: "San Bartolomé",
        name_fa: "سنت بارثلمی",
        name_fr: "Saint-Barthélemy",
        name_el: "Άγιος Βαρθολομαίος",
        name_he: "סן ברתלמי",
        name_hi: "सेंट बार्थेलेमी",
        name_hu: "Saint-Barthélemy",
        name_id: "Saint-Barthélemy",
        name_it: "Saint-Barthélemy",
        name_ja: "サン・バルテルミー島",
        name_ko: "생바르텔레미",
        name_nl: "Saint-Barthélemy",
        name_pl: "Saint-Barthélemy",
        name_pt: "Coletividade de São Bartolomeu",
        name_ru: "Сен-Бартелеми",
        name_sv: "Saint-Barthélemy",
        name_tr: "Saint Barthélemy",
        name_uk: "Сен-Бартельмі",
        name_ur: "سینٹ بارتھیملے",
        name_vi: "Saint-Barthélemy",
        name_zh: "圣巴泰勒米",
        name_zht: "聖巴瑟米",
        fclass_iso: "Admin-0 dependency",
        tlc_diff: null,
        fclass_tlc: "Admin-0 dependency",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "BLM.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-62.831933593749994, 17.87646484375],
            [-62.846923828125, 17.875195312499997],
            [-62.85893554687499, 17.88364257812499],
            [-62.869384765625, 17.898583984374994],
            [-62.875439453125, 17.91357421875],
            [-62.87421875, 17.922265625],
            [-62.8654296875, 17.918261718750003],
            [-62.799707031249994, 17.90869140625],
            [-62.80703125, 17.89765625],
            [-62.81816406249999, 17.885449218749997],
            [-62.831933593749994, 17.87646484375],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 6,
        sovereignt: "United Kingdom",
        sov_a3: "GB1",
        adm0_dif: 1,
        level: 2,
        type: "Dependency",
        tlc: "1",
        admin: "Anguilla",
        adm0_a3: "AIA",
        geou_dif: 0,
        geounit: "Anguilla",
        gu_a3: "AIA",
        su_dif: 0,
        subunit: "Anguilla",
        su_a3: "AIA",
        brk_diff: 0,
        name: "Anguilla",
        name_long: "Anguilla",
        brk_a3: "AIA",
        brk_name: "Anguilla",
        brk_group: null,
        abbrev: "Ang.",
        postal: "AI",
        formal_en: null,
        formal_fr: null,
        name_ciawf: "Anguilla",
        note_adm0: "U.K.",
        note_brk: null,
        name_sort: "Anguilla",
        name_alt: null,
        mapcolor7: 6,
        mapcolor8: 6,
        mapcolor9: 6,
        mapcolor13: 3,
        pop_est: 14731,
        pop_rank: 6,
        pop_year: 2018,
        gdp_md: 175,
        gdp_year: 2009,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        fips_10: "AV",
        iso_a2: "AI",
        iso_a2_eh: "AI",
        iso_a3: "AIA",
        iso_a3_eh: "AIA",
        iso_n3: "660",
        iso_n3_eh: "660",
        un_a3: "660",
        wb_a2: "-99",
        wb_a3: "-99",
        woe_id: 23424751,
        woe_id_eh: 23424751,
        woe_note: "Exact WOE match as country",
        adm0_iso: "AIA",
        adm0_diff: null,
        adm0_tlc: "AIA",
        adm0_a3_us: "AIA",
        adm0_a3_fr: "AIA",
        adm0_a3_ru: "AIA",
        adm0_a3_es: "AIA",
        adm0_a3_cn: "AIA",
        adm0_a3_tw: "AIA",
        adm0_a3_in: "AIA",
        adm0_a3_np: "AIA",
        adm0_a3_pk: "AIA",
        adm0_a3_de: "AIA",
        adm0_a3_gb: "AIA",
        adm0_a3_br: "AIA",
        adm0_a3_il: "AIA",
        adm0_a3_ps: "AIA",
        adm0_a3_sa: "AIA",
        adm0_a3_eg: "AIA",
        adm0_a3_ma: "AIA",
        adm0_a3_pt: "AIA",
        adm0_a3_ar: "AIA",
        adm0_a3_jp: "AIA",
        adm0_a3_ko: "AIA",
        adm0_a3_vn: "AIA",
        adm0_a3_tr: "AIA",
        adm0_a3_id: "AIA",
        adm0_a3_pl: "AIA",
        adm0_a3_gr: "AIA",
        adm0_a3_it: "AIA",
        adm0_a3_nl: "AIA",
        adm0_a3_se: "AIA",
        adm0_a3_bd: "AIA",
        adm0_a3_ua: "AIA",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "North America",
        region_un: "Americas",
        subregion: "Caribbean",
        region_wb: "Latin America & Caribbean",
        name_len: 8,
        long_len: 8,
        abbrev_len: 4,
        tiny: -99,
        homepart: -99,
        min_zoom: 0,
        min_label: 5,
        max_label: 10,
        label_x: -63.026361,
        label_y: 18.242979,
        ne_id: 1159320703,
        wikidataid: "Q25228",
        name_ar: "أنغويلا",
        name_bn: "এ্যাঙ্গুইলা",
        name_de: "Anguilla",
        name_en: "Anguilla",
        name_es: "Anguila",
        name_fa: "آنگویلا",
        name_fr: "Anguilla",
        name_el: "Ανγκουίλα",
        name_he: "אנגווילה",
        name_hi: "अंगुइला",
        name_hu: "Anguilla",
        name_id: "Anguilla",
        name_it: "Anguilla",
        name_ja: "アンギラ",
        name_ko: "앵귈라",
        name_nl: "Anguilla",
        name_pl: "Anguilla",
        name_pt: "Anguilla",
        name_ru: "Ангилья",
        name_sv: "Anguilla",
        name_tr: "Anguilla",
        name_uk: "Ангілья",
        name_ur: "اینگویلا",
        name_vi: "Anguilla",
        name_zh: "安圭拉",
        name_zht: "安吉拉",
        fclass_iso: "Admin-0 dependency",
        tlc_diff: null,
        fclass_tlc: "Admin-0 dependency",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "AIA.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-63.001220703125, 18.22177734374999],
            [-63.16000976562499, 18.17138671875],
            [-63.1533203125, 18.20029296874999],
            [-63.026025390624994, 18.269726562499997],
            [-62.97958984374999, 18.264794921874994],
            [-63.001220703125, 18.22177734374999],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 5,
        sovereignt: "United States of America",
        sov_a3: "US1",
        adm0_dif: 1,
        level: 2,
        type: "Dependency",
        tlc: "1",
        admin: "Puerto Rico",
        adm0_a3: "PRI",
        geou_dif: 0,
        geounit: "Puerto Rico",
        gu_a3: "PRI",
        su_dif: 0,
        subunit: "Puerto Rico",
        su_a3: "PRI",
        brk_diff: 0,
        name: "Puerto Rico",
        name_long: "Puerto Rico",
        brk_a3: "PRI",
        brk_name: "Puerto Rico",
        brk_group: null,
        abbrev: "P.R.",
        postal: "PR",
        formal_en: "Commonwealth of Puerto Rico",
        formal_fr: null,
        name_ciawf: "Puerto Rico",
        note_adm0: "U.S.A.",
        note_brk: null,
        name_sort: "Puerto Rico",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 5,
        mapcolor9: 1,
        mapcolor13: 1,
        pop_est: 3193694,
        pop_rank: 12,
        pop_year: 2019,
        gdp_md: 104988,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "2. High income: nonOECD",
        fips_10: "RQ",
        iso_a2: "PR",
        iso_a2_eh: "PR",
        iso_a3: "PRI",
        iso_a3_eh: "PRI",
        iso_n3: "630",
        iso_n3_eh: "630",
        un_a3: "630",
        wb_a2: "PR",
        wb_a3: "PRI",
        woe_id: 23424935,
        woe_id_eh: 23424935,
        woe_note: "Exact WOE match as country",
        adm0_iso: "PRI",
        adm0_diff: null,
        adm0_tlc: "PRI",
        adm0_a3_us: "PRI",
        adm0_a3_fr: "PRI",
        adm0_a3_ru: "PRI",
        adm0_a3_es: "PRI",
        adm0_a3_cn: "PRI",
        adm0_a3_tw: "PRI",
        adm0_a3_in: "PRI",
        adm0_a3_np: "PRI",
        adm0_a3_pk: "PRI",
        adm0_a3_de: "PRI",
        adm0_a3_gb: "PRI",
        adm0_a3_br: "PRI",
        adm0_a3_il: "PRI",
        adm0_a3_ps: "PRI",
        adm0_a3_sa: "PRI",
        adm0_a3_eg: "PRI",
        adm0_a3_ma: "PRI",
        adm0_a3_pt: "PRI",
        adm0_a3_ar: "PRI",
        adm0_a3_jp: "PRI",
        adm0_a3_ko: "PRI",
        adm0_a3_vn: "PRI",
        adm0_a3_tr: "PRI",
        adm0_a3_id: "PRI",
        adm0_a3_pl: "PRI",
        adm0_a3_gr: "PRI",
        adm0_a3_it: "PRI",
        adm0_a3_nl: "PRI",
        adm0_a3_se: "PRI",
        adm0_a3_bd: "PRI",
        adm0_a3_ua: "PRI",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "North America",
        region_un: "Americas",
        subregion: "Caribbean",
        region_wb: "Latin America & Caribbean",
        name_len: 11,
        long_len: 11,
        abbrev_len: 4,
        tiny: -99,
        homepart: -99,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: -66.481065,
        label_y: 18.234668,
        ne_id: 1159321363,
        wikidataid: "Q1183",
        name_ar: "بورتوريكو",
        name_bn: "পুয়ের্তো রিকো",
        name_de: "Puerto Rico",
        name_en: "Puerto Rico",
        name_es: "Puerto Rico",
        name_fa: "پورتوریکو",
        name_fr: "Porto Rico",
        name_el: "Πουέρτο Ρίκο",
        name_he: "פוארטו ריקו",
        name_hi: "पोर्टो रीको",
        name_hu: "Puerto Rico",
        name_id: "Puerto Riko",
        name_it: "Porto Rico",
        name_ja: "プエルトリコ",
        name_ko: "푸에르토리코",
        name_nl: "Puerto Rico",
        name_pl: "Portoryko",
        name_pt: "Porto Rico",
        name_ru: "Пуэрто-Рико",
        name_sv: "Puerto Rico",
        name_tr: "Porto Riko",
        name_uk: "Пуерто-Рико",
        name_ur: "پورٹو ریکو",
        name_vi: "Puerto Rico",
        name_zh: "波多黎各",
        name_zht: "波多黎各",
        fclass_iso: "Admin-0 dependency",
        tlc_diff: null,
        fclass_tlc: "Admin-0 dependency",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "PRI.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-66.12939453125, 18.444921875],
              [-66.09848632812499, 18.425195312499994],
              [-66.06840820312499, 18.428027343750003],
              [-66.09267578125, 18.468994140625],
              [-66.07041015624999, 18.468994140625],
              [-65.87875976562499, 18.44384765625],
              [-65.75556640625, 18.401611328125],
              [-65.62880859375, 18.381396484375003],
              [-65.620849609375, 18.242333984374994],
              [-65.718408203125, 18.186669921874994],
              [-65.7822265625, 18.128613281249997],
              [-65.834130859375, 18.057324218749997],
              [-65.97080078124999, 17.974365234375],
              [-66.135498046875, 17.949462890625],
              [-66.24501953125, 17.947265625],
              [-66.285888671875, 17.949951171875],
              [-66.32578125, 17.964160156250003],
              [-66.40854492187499, 17.950585937499994],
              [-66.51079101562499, 17.987011718749997],
              [-66.59843749999999, 17.97788085937499],
              [-66.772412109375, 17.986572265625],
              [-66.83759765625, 17.955078125],
              [-66.9, 17.947900390624994],
              [-66.96123046874999, 17.953759765624994],
              [-67.013330078125, 17.96787109374999],
              [-67.14238281249999, 17.966699218749994],
              [-67.196875, 17.994189453125003],
              [-67.17431640625, 18.152539062499997],
              [-67.17246093749999, 18.22421875],
              [-67.20415039062499, 18.283398437499997],
              [-67.23896484375, 18.32065429687499],
              [-67.2640625, 18.36459960937499],
              [-67.21337890625, 18.393603515625003],
              [-67.17177734375, 18.435791015625],
              [-67.158642578125, 18.49921875],
              [-67.113037109375, 18.514794921874994],
              [-67.05961914062499, 18.522167968749997],
              [-66.812890625, 18.49252929687499],
              [-66.18857421874999, 18.47578125],
              [-66.153076171875, 18.470654296874997],
              [-66.12939453125, 18.444921875],
            ],
          ],
          [
            [
              [-65.42558593749999, 18.105615234374994],
              [-65.50400390624999, 18.099511718749994],
              [-65.555078125, 18.107666015625],
              [-65.572216796875, 18.13730468749999],
              [-65.47714843749999, 18.1650390625],
              [-65.3662109375, 18.161083984374997],
              [-65.302685546875, 18.14438476562499],
              [-65.294873046875, 18.133349609375003],
              [-65.42558593749999, 18.105615234374994],
            ],
          ],
          [
            [
              [-67.8724609375, 18.059863281250003],
              [-67.8818359375, 18.05893554687499],
              [-67.89116210937499, 18.05991210937499],
              [-67.895458984375, 18.062792968750003],
              [-67.90190429687499, 18.071875],
              [-67.93037109375, 18.0869140625],
              [-67.93706054687499, 18.100634765625003],
              [-67.930615234375, 18.115136718749994],
              [-67.9189453125, 18.120898437500003],
              [-67.861083984375, 18.12255859375],
              [-67.85517578125, 18.121142578125003],
              [-67.84365234375, 18.111035156249997],
              [-67.843359375, 18.103955078124997],
              [-67.849072265625, 18.09702148437499],
              [-67.85917968749999, 18.07958984375],
              [-67.863427734375, 18.0751953125],
              [-67.866796875, 18.07065429687499],
              [-67.868115234375, 18.062792968750003],
              [-67.8724609375, 18.059863281250003],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 4,
        sovereignt: "Jamaica",
        sov_a3: "JAM",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Jamaica",
        adm0_a3: "JAM",
        geou_dif: 0,
        geounit: "Jamaica",
        gu_a3: "JAM",
        su_dif: 0,
        subunit: "Jamaica",
        su_a3: "JAM",
        brk_diff: 0,
        name: "Jamaica",
        name_long: "Jamaica",
        brk_a3: "JAM",
        brk_name: "Jamaica",
        brk_group: null,
        abbrev: "Jam.",
        postal: "J",
        formal_en: "Jamaica",
        formal_fr: null,
        name_ciawf: "Jamaica",
        note_adm0: null,
        note_brk: null,
        name_sort: "Jamaica",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 2,
        mapcolor9: 4,
        mapcolor13: 10,
        pop_est: 2948279,
        pop_rank: 12,
        pop_year: 2019,
        gdp_md: 16458,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        fips_10: "JM",
        iso_a2: "JM",
        iso_a2_eh: "JM",
        iso_a3: "JAM",
        iso_a3_eh: "JAM",
        iso_n3: "388",
        iso_n3_eh: "388",
        un_a3: "388",
        wb_a2: "JM",
        wb_a3: "JAM",
        woe_id: 23424858,
        woe_id_eh: 23424858,
        woe_note: "Exact WOE match as country",
        adm0_iso: "JAM",
        adm0_diff: null,
        adm0_tlc: "JAM",
        adm0_a3_us: "JAM",
        adm0_a3_fr: "JAM",
        adm0_a3_ru: "JAM",
        adm0_a3_es: "JAM",
        adm0_a3_cn: "JAM",
        adm0_a3_tw: "JAM",
        adm0_a3_in: "JAM",
        adm0_a3_np: "JAM",
        adm0_a3_pk: "JAM",
        adm0_a3_de: "JAM",
        adm0_a3_gb: "JAM",
        adm0_a3_br: "JAM",
        adm0_a3_il: "JAM",
        adm0_a3_ps: "JAM",
        adm0_a3_sa: "JAM",
        adm0_a3_eg: "JAM",
        adm0_a3_ma: "JAM",
        adm0_a3_pt: "JAM",
        adm0_a3_ar: "JAM",
        adm0_a3_jp: "JAM",
        adm0_a3_ko: "JAM",
        adm0_a3_vn: "JAM",
        adm0_a3_tr: "JAM",
        adm0_a3_id: "JAM",
        adm0_a3_pl: "JAM",
        adm0_a3_gr: "JAM",
        adm0_a3_it: "JAM",
        adm0_a3_nl: "JAM",
        adm0_a3_se: "JAM",
        adm0_a3_bd: "JAM",
        adm0_a3_ua: "JAM",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "North America",
        region_un: "Americas",
        subregion: "Caribbean",
        region_wb: "Latin America & Caribbean",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: -77.318767,
        label_y: 18.137124,
        ne_id: 1159320931,
        wikidataid: "Q766",
        name_ar: "جامايكا",
        name_bn: "জ্যামাইকা",
        name_de: "Jamaika",
        name_en: "Jamaica",
        name_es: "Jamaica",
        name_fa: "جامائیکا",
        name_fr: "Jamaïque",
        name_el: "Τζαμάικα",
        name_he: "ג'מייקה",
        name_hi: "जमैका",
        name_hu: "Jamaica",
        name_id: "Jamaika",
        name_it: "Giamaica",
        name_ja: "ジャマイカ",
        name_ko: "자메이카",
        name_nl: "Jamaica",
        name_pl: "Jamajka",
        name_pt: "Jamaica",
        name_ru: "Ямайка",
        name_sv: "Jamaica",
        name_tr: "Jamaika",
        name_uk: "Ямайка",
        name_ur: "جمیکا",
        name_vi: "Jamaica",
        name_zh: "牙买加",
        name_zht: "牙買加",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "JAM.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.261474609375, 18.457421875],
            [-77.13955078125, 18.421484375],
            [-77.01376953124999, 18.40292968749999],
            [-76.959375, 18.40185546875],
            [-76.908203125, 18.390429687500003],
            [-76.79326171874999, 18.304296875],
            [-76.70073242187499, 18.25717773437499],
            [-76.349853515625, 18.15185546875],
            [-76.23276367187499, 17.9703125],
            [-76.21079101562499, 17.913525390624997],
            [-76.30146484375, 17.879833984374997],
            [-76.41552734375, 17.868212890625003],
            [-76.524609375, 17.8662109375],
            [-76.625390625, 17.90097656249999],
            [-76.669384765625, 17.927636718749994],
            [-76.77431640625, 17.9404296875],
            [-76.748291015625, 17.964892578125003],
            [-76.79482421875, 17.976318359375],
            [-76.85322265625, 17.97373046874999],
            [-76.896240234375, 17.904101562500003],
            [-76.94414062499999, 17.848779296874994],
            [-77.03593749999999, 17.85410156249999],
            [-77.0712890625, 17.901269531249994],
            [-77.11948242187499, 17.880078125],
            [-77.1583984375, 17.84506835937499],
            [-77.20498046875, 17.71494140624999],
            [-77.27988281249999, 17.779541015625],
            [-77.36142578124999, 17.833691406249997],
            [-77.4638671875, 17.85605468749999],
            [-77.67075195312499, 17.85971679687499],
            [-77.76816406249999, 17.877392578124997],
            [-77.84941406249999, 17.9875],
            [-77.881298828125, 18.01904296875],
            [-77.96298828124999, 18.047558593749997],
            [-78.04448242187499, 18.173828125],
            [-78.0736328125, 18.191162109375],
            [-78.294091796875, 18.218066406250003],
            [-78.339501953125, 18.28720703124999],
            [-78.32597656249999, 18.349755859374994],
            [-78.25244140625, 18.42626953125],
            [-78.21669921875, 18.44809570312499],
            [-78.09453124999999, 18.44482421875],
            [-77.978173828125, 18.467822265625003],
            [-77.92685546874999, 18.500683593749997],
            [-77.8734375, 18.522216796875],
            [-77.451611328125, 18.467041015625],
            [-77.354248046875, 18.466455078124994],
            [-77.261474609375, 18.457421875],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 3,
        labelrank: 6,
        sovereignt: "United Kingdom",
        sov_a3: "GB1",
        adm0_dif: 1,
        level: 2,
        type: "Dependency",
        tlc: "1",
        admin: "British Virgin Islands",
        adm0_a3: "VGB",
        geou_dif: 0,
        geounit: "British Virgin Islands",
        gu_a3: "VGB",
        su_dif: 0,
        subunit: "British Virgin Islands",
        su_a3: "VGB",
        brk_diff: 0,
        name: "British Virgin Is.",
        name_long: "British Virgin Islands",
        brk_a3: "VGB",
        brk_name: "British Virgin Is.",
        brk_group: null,
        abbrev: "V.I. (Br.)",
        postal: "VG",
        formal_en: "British Virgin Islands",
        formal_fr: null,
        name_ciawf: "British Virgin Islands",
        note_adm0: "U.K.",
        note_brk: null,
        name_sort: "British Virgin Islands",
        name_alt: null,
        mapcolor7: 6,
        mapcolor8: 6,
        mapcolor9: 6,
        mapcolor13: 3,
        pop_est: 30030,
        pop_rank: 7,
        pop_year: 2019,
        gdp_md: 500,
        gdp_year: 2017,
        economy: "2. Developed region: nonG7",
        income_grp: "1. High income: OECD",
        fips_10: "VI",
        iso_a2: "VG",
        iso_a2_eh: "VG",
        iso_a3: "VGB",
        iso_a3_eh: "VGB",
        iso_n3: "092",
        iso_n3_eh: "092",
        un_a3: "092",
        wb_a2: "-99",
        wb_a3: "-99",
        woe_id: 23424983,
        woe_id_eh: 23424983,
        woe_note: "Exact WOE match as country",
        adm0_iso: "VGB",
        adm0_diff: null,
        adm0_tlc: "VGB",
        adm0_a3_us: "VGB",
        adm0_a3_fr: "VGB",
        adm0_a3_ru: "VGB",
        adm0_a3_es: "VGB",
        adm0_a3_cn: "VGB",
        adm0_a3_tw: "VGB",
        adm0_a3_in: "VGB",
        adm0_a3_np: "VGB",
        adm0_a3_pk: "VGB",
        adm0_a3_de: "VGB",
        adm0_a3_gb: "VGB",
        adm0_a3_br: "VGB",
        adm0_a3_il: "VGB",
        adm0_a3_ps: "VGB",
        adm0_a3_sa: "VGB",
        adm0_a3_eg: "VGB",
        adm0_a3_ma: "VGB",
        adm0_a3_pt: "VGB",
        adm0_a3_ar: "VGB",
        adm0_a3_jp: "VGB",
        adm0_a3_ko: "VGB",
        adm0_a3_vn: "VGB",
        adm0_a3_tr: "VGB",
        adm0_a3_id: "VGB",
        adm0_a3_pl: "VGB",
        adm0_a3_gr: "VGB",
        adm0_a3_it: "VGB",
        adm0_a3_nl: "VGB",
        adm0_a3_se: "VGB",
        adm0_a3_bd: "VGB",
        adm0_a3_ua: "VGB",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "North America",
        region_un: "Americas",
        subregion: "Caribbean",
        region_wb: "Latin America & Caribbean",
        name_len: 18,
        long_len: 22,
        abbrev_len: 10,
        tiny: 3,
        homepart: -99,
        min_zoom: 0,
        min_label: 5,
        max_label: 9.5,
        label_x: -64.63661,
        label_y: 18.426606,
        ne_id: 1159320739,
        wikidataid: "Q25305",
        name_ar: "جزر العذراء البريطانية",
        name_bn: "ব্রিটিশ ভার্জিন দ্বীপপুঞ্জ",
        name_de: "Britische Jungferninseln",
        name_en: "British Virgin Islands",
        name_es: "Islas Vírgenes Británicas",
        name_fa: "جزایر ویرجین بریتانیا",
        name_fr: "îles Vierges britanniques",
        name_el: "Βρετανικές Παρθένοι Νήσοι",
        name_he: "איי הבתולה הבריטיים",
        name_hi: "ब्रिटिश वर्जिन द्वीपसमूह",
        name_hu: "Brit Virgin-szigetek",
        name_id: "Kepulauan Virgin Britania Raya",
        name_it: "Isole Vergini britanniche",
        name_ja: "イギリス領ヴァージン諸島",
        name_ko: "영국령 버진아일랜드",
        name_nl: "Britse Maagdeneilanden",
        name_pl: "Brytyjskie Wyspy Dziewicze",
        name_pt: "Ilhas Virgens Britânicas",
        name_ru: "Британские Виргинские острова",
        name_sv: "Brittiska Jungfruöarna",
        name_tr: "Britanya Virjin Adaları",
        name_uk: "Британські Віргінські острови",
        name_ur: "برطانوی جزائر ورجن",
        name_vi: "Quần đảo Virgin thuộc Anh",
        name_zh: "英属维尔京群岛",
        name_zht: "英屬維京群島",
        fclass_iso: "Admin-0 dependency",
        tlc_diff: null,
        fclass_tlc: "Admin-0 dependency",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "VGB.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-64.39521484375, 18.464599609375],
              [-64.421142578125, 18.457421875],
              [-64.43803710937499, 18.458984375],
              [-64.44375, 18.473388671875],
              [-64.42607421874999, 18.513085937499994],
              [-64.324658203125, 18.517480468749994],
              [-64.39521484375, 18.464599609375],
            ],
          ],
          [
            [
              [-64.59365234375, 18.40283203125],
              [-64.671826171875, 18.399121093749997],
              [-64.69511718749999, 18.411669921875003],
              [-64.65097656249999, 18.442529296874994],
              [-64.56914062499999, 18.4462890625],
              [-64.545166015625, 18.438134765624994],
              [-64.59365234375, 18.40283203125],
            ],
          ],
          [
            [
              [-64.28789062499999, 18.74057617187499],
              [-64.273583984375, 18.70712890624999],
              [-64.28232421874999, 18.707714843749997],
              [-64.339453125, 18.730712890625],
              [-64.38339843749999, 18.732617187499997],
              [-64.40146484374999, 18.738574218750003],
              [-64.41142578124999, 18.751171875],
              [-64.32309570312499, 18.752685546875],
              [-64.28789062499999, 18.74057617187499],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 5,
        sovereignt: "United Kingdom",
        sov_a3: "GB1",
        adm0_dif: 1,
        level: 2,
        type: "Dependency",
        tlc: "1",
        admin: "Cayman Islands",
        adm0_a3: "CYM",
        geou_dif: 0,
        geounit: "Cayman Islands",
        gu_a3: "CYM",
        su_dif: 0,
        subunit: "Cayman Islands",
        su_a3: "CYM",
        brk_diff: 0,
        name: "Cayman Is.",
        name_long: "Cayman Islands",
        brk_a3: "CYM",
        brk_name: "Cayman Is.",
        brk_group: null,
        abbrev: "Cym. Is.",
        postal: "KY",
        formal_en: "Cayman Islands",
        formal_fr: null,
        name_ciawf: "Cayman Islands",
        note_adm0: "U.K.",
        note_brk: null,
        name_sort: "Cayman Islands",
        name_alt: null,
        mapcolor7: 6,
        mapcolor8: 6,
        mapcolor9: 6,
        mapcolor13: 3,
        pop_est: 64948,
        pop_rank: 8,
        pop_year: 2019,
        gdp_md: 5517,
        gdp_year: 2018,
        economy: "5. Emerging region: G20",
        income_grp: "2. High income: nonOECD",
        fips_10: "CJ",
        iso_a2: "KY",
        iso_a2_eh: "KY",
        iso_a3: "CYM",
        iso_a3_eh: "CYM",
        iso_n3: "136",
        iso_n3_eh: "136",
        un_a3: "136",
        wb_a2: "KY",
        wb_a3: "CYM",
        woe_id: 23424783,
        woe_id_eh: 23424783,
        woe_note: "Exact WOE match as country",
        adm0_iso: "CYM",
        adm0_diff: null,
        adm0_tlc: "CYM",
        adm0_a3_us: "CYM",
        adm0_a3_fr: "CYM",
        adm0_a3_ru: "CYM",
        adm0_a3_es: "CYM",
        adm0_a3_cn: "CYM",
        adm0_a3_tw: "CYM",
        adm0_a3_in: "CYM",
        adm0_a3_np: "CYM",
        adm0_a3_pk: "CYM",
        adm0_a3_de: "CYM",
        adm0_a3_gb: "CYM",
        adm0_a3_br: "CYM",
        adm0_a3_il: "CYM",
        adm0_a3_ps: "CYM",
        adm0_a3_sa: "CYM",
        adm0_a3_eg: "CYM",
        adm0_a3_ma: "CYM",
        adm0_a3_pt: "CYM",
        adm0_a3_ar: "CYM",
        adm0_a3_jp: "CYM",
        adm0_a3_ko: "CYM",
        adm0_a3_vn: "CYM",
        adm0_a3_tr: "CYM",
        adm0_a3_id: "CYM",
        adm0_a3_pl: "CYM",
        adm0_a3_gr: "CYM",
        adm0_a3_it: "CYM",
        adm0_a3_nl: "CYM",
        adm0_a3_se: "CYM",
        adm0_a3_bd: "CYM",
        adm0_a3_ua: "CYM",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "North America",
        region_un: "Americas",
        subregion: "Caribbean",
        region_wb: "Latin America & Caribbean",
        name_len: 10,
        long_len: 14,
        abbrev_len: 8,
        tiny: 2,
        homepart: -99,
        min_zoom: 0,
        min_label: 5,
        max_label: 9.5,
        label_x: -81.24055,
        label_y: 19.319862,
        ne_id: 1159320707,
        wikidataid: "Q5785",
        name_ar: "جزر كايمان",
        name_bn: "কেইম্যান দ্বীপপুঞ্জ",
        name_de: "Cayman Islands",
        name_en: "Cayman Islands",
        name_es: "Islas Caimán",
        name_fa: "جزایر کیمن",
        name_fr: "îles Caïmans",
        name_el: "Νησιά Καϋμάν",
        name_he: "איי קיימן",
        name_hi: "केमन द्वीपसमूह",
        name_hu: "Kajmán-szigetek",
        name_id: "Kepulauan Cayman",
        name_it: "Isole Cayman",
        name_ja: "ケイマン諸島",
        name_ko: "케이맨 제도",
        name_nl: "Kaaimaneilanden",
        name_pl: "Kajmany",
        name_pt: "Ilhas Caimão",
        name_ru: "острова Кайман",
        name_sv: "Caymanöarna",
        name_tr: "Cayman Adaları",
        name_uk: "Кайманові острови",
        name_ur: "جزائر کیمین",
        name_vi: "Quần đảo Cayman",
        name_zh: "开曼群岛",
        name_zht: "開曼群島",
        fclass_iso: "Admin-0 dependency",
        tlc_diff: null,
        fclass_tlc: "Admin-0 dependency",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "CYM.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-81.36953125, 19.348876953125],
              [-81.33725585937499, 19.329492187499994],
              [-81.296484375, 19.341357421875003],
              [-81.284814453125, 19.362548828125],
              [-81.13046875, 19.34677734374999],
              [-81.10712890625, 19.30517578125],
              [-81.224609375, 19.304101562499994],
              [-81.277294921875, 19.277392578125003],
              [-81.3037109375, 19.271875],
              [-81.40478515625, 19.27841796874999],
              [-81.419091796875, 19.374755859375],
              [-81.391015625, 19.384912109374994],
              [-81.36953125, 19.348876953125],
            ],
          ],
          [
            [
              [-79.97900390625, 19.708203125],
              [-79.98876953125, 19.702539062499994],
              [-80.020751953125, 19.706835937500003],
              [-80.094189453125, 19.665917968749994],
              [-80.12587890625, 19.668359375],
              [-80.11611328125, 19.682666015625003],
              [-80.10092773437499, 19.69609375],
              [-80.083642578125, 19.706103515625003],
              [-80.067578125, 19.7099609375],
              [-80.01621093749999, 19.71826171875],
              [-79.991845703125, 19.719287109375003],
              [-79.97509765625, 19.7099609375],
              [-79.97900390625, 19.708203125],
            ],
          ],
          [
            [
              [-79.823388671875, 19.7119140625],
              [-79.870068359375, 19.696679687499994],
              [-79.906201171875, 19.702539062499994],
              [-79.82421875, 19.744091796874997],
              [-79.803125, 19.758105468750003],
              [-79.78515625, 19.765625],
              [-79.766357421875, 19.76572265624999],
              [-79.74228515624999, 19.757128906250003],
              [-79.74228515624999, 19.750878906249994],
              [-79.823388671875, 19.7119140625],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 3,
        labelrank: 6,
        sovereignt: "United Kingdom",
        sov_a3: "GB1",
        adm0_dif: 1,
        level: 2,
        type: "Dependency",
        tlc: "1",
        admin: "Bermuda",
        adm0_a3: "BMU",
        geou_dif: 0,
        geounit: "Bermuda",
        gu_a3: "BMU",
        su_dif: 0,
        subunit: "Bermuda",
        su_a3: "BMU",
        brk_diff: 0,
        name: "Bermuda",
        name_long: "Bermuda",
        brk_a3: "BMU",
        brk_name: "Bermuda",
        brk_group: null,
        abbrev: "Berm.",
        postal: "BM",
        formal_en: "The Bermudas or Somers Isles",
        formal_fr: null,
        name_ciawf: "Bermuda",
        note_adm0: "U.K.",
        note_brk: null,
        name_sort: "Bermuda",
        name_alt: null,
        mapcolor7: 6,
        mapcolor8: 6,
        mapcolor9: 6,
        mapcolor13: 3,
        pop_est: 63918,
        pop_rank: 8,
        pop_year: 2019,
        gdp_md: 7484,
        gdp_year: 2019,
        economy: "2. Developed region: nonG7",
        income_grp: "2. High income: nonOECD",
        fips_10: "BD",
        iso_a2: "BM",
        iso_a2_eh: "BM",
        iso_a3: "BMU",
        iso_a3_eh: "BMU",
        iso_n3: "060",
        iso_n3_eh: "060",
        un_a3: "060",
        wb_a2: "BM",
        wb_a3: "BMU",
        woe_id: 23424756,
        woe_id_eh: 23424756,
        woe_note: "Exact WOE match as country",
        adm0_iso: "BMU",
        adm0_diff: null,
        adm0_tlc: "BMU",
        adm0_a3_us: "BMU",
        adm0_a3_fr: "BMU",
        adm0_a3_ru: "BMU",
        adm0_a3_es: "BMU",
        adm0_a3_cn: "BMU",
        adm0_a3_tw: "BMU",
        adm0_a3_in: "BMU",
        adm0_a3_np: "BMU",
        adm0_a3_pk: "BMU",
        adm0_a3_de: "BMU",
        adm0_a3_gb: "BMU",
        adm0_a3_br: "BMU",
        adm0_a3_il: "BMU",
        adm0_a3_ps: "BMU",
        adm0_a3_sa: "BMU",
        adm0_a3_eg: "BMU",
        adm0_a3_ma: "BMU",
        adm0_a3_pt: "BMU",
        adm0_a3_ar: "BMU",
        adm0_a3_jp: "BMU",
        adm0_a3_ko: "BMU",
        adm0_a3_vn: "BMU",
        adm0_a3_tr: "BMU",
        adm0_a3_id: "BMU",
        adm0_a3_pl: "BMU",
        adm0_a3_gr: "BMU",
        adm0_a3_it: "BMU",
        adm0_a3_nl: "BMU",
        adm0_a3_se: "BMU",
        adm0_a3_bd: "BMU",
        adm0_a3_ua: "BMU",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "North America",
        region_un: "Americas",
        subregion: "Northern America",
        region_wb: "North America",
        name_len: 7,
        long_len: 7,
        abbrev_len: 5,
        tiny: 4,
        homepart: -99,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: -64.763573,
        label_y: 32.296592,
        ne_id: 1159320705,
        wikidataid: "Q23635",
        name_ar: "برمودا",
        name_bn: "বারমুডা",
        name_de: "Bermuda",
        name_en: "Bermuda",
        name_es: "Bermudas",
        name_fa: "برمودا",
        name_fr: "Bermudes",
        name_el: "Βερμούδες",
        name_he: "ברמודה",
        name_hi: "बरमूडा",
        name_hu: "Bermuda",
        name_id: "Bermuda",
        name_it: "Bermuda",
        name_ja: "バミューダ諸島",
        name_ko: "버뮤다",
        name_nl: "Bermuda",
        name_pl: "Bermudy",
        name_pt: "Bermudas",
        name_ru: "Бермудские Острова",
        name_sv: "Bermuda",
        name_tr: "Bermuda",
        name_uk: "Бермудські острови",
        name_ur: "برمودا",
        name_vi: "Bermuda",
        name_zh: "百慕大",
        name_zht: "百慕達",
        fclass_iso: "Admin-0 dependency",
        tlc_diff: null,
        fclass_tlc: "Admin-0 dependency",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "BMU.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-64.73027343749999, 32.29345703125],
            [-64.82011718749999, 32.259619140625],
            [-64.84506835937499, 32.2623046875],
            [-64.86284179687499, 32.273876953125],
            [-64.77119140625, 32.30771484375],
            [-64.69462890624999, 32.3869140625],
            [-64.66831054687499, 32.38193359375],
            [-64.73027343749999, 32.29345703125],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 2,
        sovereignt: "Ethiopia",
        sov_a3: "ETH",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Ethiopia",
        adm0_a3: "ETH",
        geou_dif: 0,
        geounit: "Ethiopia",
        gu_a3: "ETH",
        su_dif: 0,
        subunit: "Ethiopia",
        su_a3: "ETH",
        brk_diff: 0,
        name: "Ethiopia",
        name_long: "Ethiopia",
        brk_a3: "ETH",
        brk_name: "Ethiopia",
        brk_group: null,
        abbrev: "Eth.",
        postal: "ET",
        formal_en: "Federal Democratic Republic of Ethiopia",
        formal_fr: null,
        name_ciawf: "Ethiopia",
        note_adm0: null,
        note_brk: null,
        name_sort: "Ethiopia",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 4,
        mapcolor9: 1,
        mapcolor13: 13,
        pop_est: 112078730,
        pop_rank: 17,
        pop_year: 2019,
        gdp_md: 95912,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        fips_10: "ET",
        iso_a2: "ET",
        iso_a2_eh: "ET",
        iso_a3: "ETH",
        iso_a3_eh: "ETH",
        iso_n3: "231",
        iso_n3_eh: "231",
        un_a3: "231",
        wb_a2: "ET",
        wb_a3: "ETH",
        woe_id: 23424808,
        woe_id_eh: 23424808,
        woe_note: "Exact WOE match as country",
        adm0_iso: "ETH",
        adm0_diff: null,
        adm0_tlc: "ETH",
        adm0_a3_us: "ETH",
        adm0_a3_fr: "ETH",
        adm0_a3_ru: "ETH",
        adm0_a3_es: "ETH",
        adm0_a3_cn: "ETH",
        adm0_a3_tw: "ETH",
        adm0_a3_in: "ETH",
        adm0_a3_np: "ETH",
        adm0_a3_pk: "ETH",
        adm0_a3_de: "ETH",
        adm0_a3_gb: "ETH",
        adm0_a3_br: "ETH",
        adm0_a3_il: "ETH",
        adm0_a3_ps: "ETH",
        adm0_a3_sa: "ETH",
        adm0_a3_eg: "ETH",
        adm0_a3_ma: "ETH",
        adm0_a3_pt: "ETH",
        adm0_a3_ar: "ETH",
        adm0_a3_jp: "ETH",
        adm0_a3_ko: "ETH",
        adm0_a3_vn: "ETH",
        adm0_a3_tr: "ETH",
        adm0_a3_id: "ETH",
        adm0_a3_pl: "ETH",
        adm0_a3_gr: "ETH",
        adm0_a3_it: "ETH",
        adm0_a3_nl: "ETH",
        adm0_a3_se: "ETH",
        adm0_a3_bd: "ETH",
        adm0_a3_ua: "ETH",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Eastern Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 8,
        long_len: 8,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 2,
        max_label: 7,
        label_x: 39.0886,
        label_y: 8.032795,
        ne_id: 1159320617,
        wikidataid: "Q115",
        name_ar: "إثيوبيا",
        name_bn: "ইথিওপিয়া",
        name_de: "Äthiopien",
        name_en: "Ethiopia",
        name_es: "Etiopía",
        name_fa: "اتیوپی",
        name_fr: "Éthiopie",
        name_el: "Αιθιοπία",
        name_he: "אתיופיה",
        name_hi: "इथियोपिया",
        name_hu: "Etiópia",
        name_id: "Ethiopia",
        name_it: "Etiopia",
        name_ja: "エチオピア",
        name_ko: "에티오피아",
        name_nl: "Ethiopië",
        name_pl: "Etiopia",
        name_pt: "Etiópia",
        name_ru: "Эфиопия",
        name_sv: "Etiopien",
        name_tr: "Etiyopya",
        name_uk: "Ефіопія",
        name_ur: "ایتھوپیا",
        name_vi: "Ethiopia",
        name_zh: "埃塞俄比亚",
        name_zht: "衣索比亞",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "ETH.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [35.26835937500002, 5.492285156249991],
            [35.25244140625, 5.511035156249989],
            [35.16445312500002, 5.581201171874994],
            [35.08193359375002, 5.673144531249989],
            [35.03193359375001, 5.77490234375],
            [34.98359375000001, 5.858300781249994],
            [34.958984375, 6.045068359374994],
            [34.89785156250002, 6.159814453124994],
            [34.83808593750001, 6.300146484374991],
            [34.74921875000001, 6.56787109375],
            [34.71064453125001, 6.660302734374994],
            [34.638769531250006, 6.72216796875],
            [34.56279296875002, 6.779833984374989],
            [34.484375, 6.898388671874997],
            [34.279296875, 7.002832031249994],
            [34.20039062500001, 7.084570312499991],
            [34.06425781250002, 7.225732421874994],
            [34.03017578125002, 7.296972656249991],
            [34.02041015625002, 7.367968749999989],
            [33.97792968750002, 7.4345703125],
            [33.902441406250006, 7.509521484375],
            [33.666113281250006, 7.670996093749991],
            [33.60097656250002, 7.6904296875],
            [33.51630859375001, 7.707763671875],
            [33.39228515625001, 7.723730468749991],
            [33.22597656250002, 7.760644531249994],
            [33.08076171875001, 7.82373046875],
            [33.0146484375, 7.868554687499994],
            [32.99892578125002, 7.899511718749991],
            [33.01259765625002, 7.951513671874991],
            [33.06523437500002, 8.040478515624997],
            [33.16523437500001, 8.251074218749991],
            [33.23427734375002, 8.396386718749994],
            [33.28105468750002, 8.437255859375],
            [33.409375, 8.44775390625],
            [33.54531250000002, 8.443408203124989],
            [33.64482421875002, 8.432568359374997],
            [33.78505859375002, 8.431103515624997],
            [33.95332031250001, 8.443505859374994],
            [34.01972656250001, 8.492089843749994],
            [34.07275390625, 8.545263671874991],
            [34.09453125000002, 8.582226562499997],
            [34.10175781250001, 8.676367187499991],
            [34.1015625, 8.751855468749994],
            [34.09101562500001, 9.041259765625],
            [34.084570312500006, 9.218505859375],
            [34.0771484375, 9.420996093749991],
            [34.078125, 9.461523437499991],
            [34.07929687500001, 9.513476562499989],
            [34.12031250000001, 9.7296875],
            [34.15908203125002, 9.853417968749994],
            [34.18525390625001, 9.918554687499991],
            [34.29150390625, 10.124755859375],
            [34.31123046875001, 10.190869140624997],
            [34.31484375000002, 10.2515625],
            [34.27568359375002, 10.528125],
            [34.34394531250001, 10.658642578124997],
            [34.43144531250002, 10.787841796875],
            [34.50800781250001, 10.842871093749991],
            [34.571875, 10.880175781249989],
            [34.60175781250001, 10.864550781249989],
            [34.675, 10.804931640625],
            [34.77128906250002, 10.746191406249991],
            [34.81621093750002, 10.759179687499994],
            [34.88232421875, 10.810546875],
            [34.93144531250002, 10.864794921874989],
            [34.924902343750006, 10.962109375],
            [34.96914062500002, 11.161767578124994],
            [34.96074218750002, 11.276757812499994],
            [35.007910156250006, 11.419873046874997],
            [35.059667968750006, 11.621044921874997],
            [35.08271484375001, 11.748291015625],
            [35.1123046875, 11.816552734374994],
            [35.25244140625, 11.95703125],
            [35.37275390625001, 12.155566406249989],
            [35.44960937500002, 12.300585937499989],
            [35.59609375000002, 12.537304687499997],
            [35.67021484375002, 12.623730468749997],
            [35.730566406250006, 12.661035156249994],
            [35.82060546875002, 12.684863281249989],
            [35.98759765625002, 12.706298828125],
            [36.107519531250006, 12.726464843749994],
            [36.12519531250001, 12.75703125],
            [36.135351562500006, 12.805322265624994],
            [36.13710937500002, 12.9111328125],
            [36.16015625, 13.093310546874989],
            [36.21220703125002, 13.27109375],
            [36.273535156250006, 13.40576171875],
            [36.30683593750001, 13.466845703125003],
            [36.346289062500006, 13.526269531249994],
            [36.390625, 13.626074218749991],
            [36.44707031250002, 13.842041015625],
            [36.443945312500006, 13.988427734374994],
            [36.52431640625002, 14.2568359375],
            [36.54238281250002, 14.258203125],
            [36.67910156250002, 14.307568359374997],
            [36.81191406250002, 14.315039062499991],
            [36.94072265625002, 14.280566406250003],
            [37.024511718750006, 14.27197265625],
            [37.0634765625, 14.289257812499997],
            [37.09941406250002, 14.333984375],
            [37.13261718750002, 14.406054687500003],
            [37.18515625, 14.445996093749997],
            [37.25722656250002, 14.453759765624994],
            [37.35371093750001, 14.372460937499994],
            [37.50722656250002, 14.156396484374994],
            [37.54677734375002, 14.143847656250003],
            [37.57119140625002, 14.149072265624994],
            [37.6484375, 14.322558593750003],
            [37.70839843750002, 14.457226562499997],
            [37.8203125, 14.70849609375],
            [37.88417968750002, 14.852294921875],
            [37.943457031250006, 14.810546875],
            [38.002539062500006, 14.737109375],
            [38.069921875, 14.702734375],
            [38.14199218750002, 14.681494140624991],
            [38.17705078125002, 14.678808593749991],
            [38.22148437500002, 14.649658203125],
            [38.376953125, 14.470410156249997],
            [38.43144531250002, 14.428613281249994],
            [38.50439453125, 14.424414062499991],
            [38.81201171875, 14.482324218749994],
            [38.995703125, 14.586865234374997],
            [39.02382812500002, 14.628222656250003],
            [39.07421875, 14.628222656250003],
            [39.13544921875001, 14.581884765624991],
            [39.15859375000002, 14.5375],
            [39.19804687500002, 14.479394531249994],
            [39.270117187500006, 14.4703125],
            [39.44609375000002, 14.511865234374994],
            [39.531835937500006, 14.53671875],
            [39.60488281250002, 14.516064453124997],
            [39.69794921875001, 14.4990234375],
            [39.75615234375002, 14.4990234375],
            [39.895117187500006, 14.440673828125],
            [40.062109375, 14.459130859374994],
            [40.140625, 14.4560546875],
            [40.22148437500002, 14.43115234375],
            [40.353125, 14.338085937499997],
            [40.5244140625, 14.225195312499991],
            [40.76953125, 14.144482421874997],
            [40.82011718750002, 14.111669921874991],
            [40.938574218750006, 13.983105468749997],
            [41.12236328125002, 13.736132812500003],
            [41.362890625, 13.499804687500003],
            [41.625, 13.313232421875],
            [41.76503906250002, 13.183935546874991],
            [41.85957031250001, 13.02587890625],
            [41.9521484375, 12.88232421875],
            [42.04658203125001, 12.820605468749989],
            [42.13427734375, 12.771435546874997],
            [42.225, 12.661962890624991],
            [42.28994140625002, 12.570214843749994],
            [42.378515625, 12.46640625],
            [42.280371093750006, 12.324267578124989],
            [42.14912109375001, 12.134130859374991],
            [41.99589843750002, 11.912353515625],
            [41.94960937500002, 11.857861328124997],
            [41.815625, 11.723779296874994],
            [41.79267578125001, 11.68603515625],
            [41.76650390625002, 11.589111328125],
            [41.7646484375, 11.412890624999989],
            [41.78203125000002, 11.187792968749989],
            [41.79824218750002, 10.98046875],
            [41.872167968750006, 10.955810546875],
            [41.95742187500002, 10.941015625],
            [42.05214843750002, 10.968359375],
            [42.16621093750001, 10.991601562499994],
            [42.30810546875, 11.005224609374991],
            [42.46513671875002, 11.047070312499997],
            [42.557714843750006, 11.080761718749997],
            [42.65498046875001, 11.078320312499997],
            [42.7412109375, 11.042382812499994],
            [42.78300781250002, 11.00927734375],
            [42.84414062500002, 10.997949218749994],
            [42.92275390625002, 10.999316406249989],
            [42.90615234375002, 10.960253906249989],
            [42.862890625, 10.903222656249994],
            [42.80976562500001, 10.845996093749989],
            [42.76308593750002, 10.786914062499989],
            [42.65957031250002, 10.621386718749989],
            [42.65644531250001, 10.6],
            [42.66923828125002, 10.567578125],
            [42.725195312500006, 10.491748046874989],
            [42.78369140625, 10.36962890625],
            [42.81640625, 10.257373046874989],
            [42.84160156250002, 10.203076171874997],
            [42.9125, 10.140820312499997],
            [43.014746093750006, 10.012597656249994],
            [43.068945312500006, 9.926220703124997],
            [43.181640625, 9.879980468749991],
            [43.21845703125001, 9.770166015624994],
            [43.303125, 9.609082031249997],
            [43.39433593750002, 9.480273437499989],
            [43.482519531250006, 9.379492187499991],
            [43.5810546875, 9.340722656249994],
            [43.62050781250002, 9.33740234375],
            [43.826757812500006, 9.15078125],
            [43.98378906250002, 9.008837890624989],
            [44.02285156250002, 8.986035156249997],
            [44.30625, 8.89306640625],
            [44.63203125000001, 8.786083984374997],
            [44.8935546875, 8.7001953125],
            [45.22695312500002, 8.5908203125],
            [45.55546875000002, 8.483007812499991],
            [45.86328125, 8.3798828125],
            [46.295996093750006, 8.234960937499991],
            [46.64472656250001, 8.1181640625],
            [46.91953125, 8.026123046875],
            [46.97822265625001, 7.9970703125],
            [47.3056640625, 7.9970703125],
            [47.6376953125, 7.9970703125],
            [47.97822265625001, 7.9970703125],
            [47.73164062500001, 7.759326171874989],
            [47.45283203125001, 7.490478515625],
            [47.159765625, 7.207861328124991],
            [46.97119140625, 7.026025390624994],
            [46.67177734375002, 6.737255859374997],
            [46.422949218750006, 6.497265625],
            [46.16679687500002, 6.234667968749989],
            [45.93496093750002, 5.997216796874994],
            [45.63359375000002, 5.668261718749989],
            [45.4384765625, 5.455419921874991],
            [45.1328125, 5.121679687499991],
            [44.940527343750006, 4.912011718749994],
            [44.91162109375, 4.89990234375],
            [44.63662109375002, 4.915771484375],
            [44.36953125000002, 4.931201171874989],
            [44.028125, 4.950976562499989],
            [43.988867187500006, 4.950537109374991],
            [43.88945312500002, 4.930761718749991],
            [43.829199218750006, 4.911425781249989],
            [43.58349609375, 4.85498046875],
            [43.53828125000001, 4.84033203125],
            [43.333984375, 4.750390625],
            [43.12568359375001, 4.644482421874997],
            [43.01601562500002, 4.563330078124991],
            [42.93095703125002, 4.4453125],
            [42.89472656250001, 4.361083984375],
            [42.85664062500001, 4.32421875],
            [42.791601562500006, 4.2919921875],
            [42.35517578125001, 4.212255859374991],
            [42.22841796875002, 4.20166015625],
            [42.02412109375001, 4.137939453125],
            [41.91533203125002, 4.031298828124989],
            [41.88398437500001, 3.977734375],
            [41.73769531250002, 3.979052734374989],
            [41.48193359375, 3.96328125],
            [41.37246093750002, 3.946191406249994],
            [41.318945312500006, 3.943066406249997],
            [41.22089843750001, 3.943554687499997],
            [41.14042968750002, 3.962988281249991],
            [41.08720703125002, 3.991943359375],
            [41.02080078125002, 4.057470703124991],
            [40.87265625, 4.190332031249994],
            [40.765234375, 4.273046875],
            [40.52871093750002, 4.177636718749994],
            [40.316015625, 4.082714843749997],
            [40.01416015625, 3.947949218749997],
            [39.84218750000002, 3.851464843749994],
            [39.79033203125002, 3.754248046874991],
            [39.65751953125002, 3.577832031249997],
            [39.53886718750002, 3.469189453124997],
            [39.49443359375002, 3.456103515624989],
            [39.22548828125002, 3.478759765625],
            [39.12832031250002, 3.500878906249994],
            [38.9677734375, 3.520605468749991],
            [38.75273437500002, 3.558984375],
            [38.608007812500006, 3.60009765625],
            [38.45156250000002, 3.604833984374991],
            [38.22529296875001, 3.618994140624991],
            [38.08613281250001, 3.648828125],
            [37.944921875, 3.746728515624994],
            [37.76289062500001, 3.864648437499994],
            [37.57548828125002, 3.9859375],
            [37.38251953125001, 4.11083984375],
            [37.15458984375002, 4.254541015624994],
            [36.90556640625002, 4.411474609374991],
            [36.848242187500006, 4.42734375],
            [36.82363281250002, 4.430126953124997],
            [36.55302734375002, 4.437255859375],
            [36.271875, 4.444726562499994],
            [36.08193359375002, 4.44970703125],
            [36.02197265625, 4.468115234374991],
            [35.97871093750001, 4.503808593749994],
            [35.91982421875002, 4.619824218749997],
            [35.84560546875002, 4.70263671875],
            [35.76308593750002, 4.808007812499994],
            [35.75615234375002, 4.950488281249989],
            [35.779296875, 5.105566406249991],
            [35.80029296875, 5.156933593749997],
            [35.78847656250002, 5.208105468749991],
            [35.79140625000002, 5.278564453125],
            [35.74501953125002, 5.343994140625],
            [35.46865234375002, 5.419091796874994],
            [35.42402343750001, 5.41328125],
            [35.3779296875, 5.38515625],
            [35.325292968750006, 5.364892578124994],
            [35.28759765625, 5.384082031249989],
            [35.2646484375, 5.412060546874997],
            [35.26386718750001, 5.457910156249994],
            [35.26835937500002, 5.492285156249991],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "South Sudan",
        sov_a3: "SDS",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "South Sudan",
        adm0_a3: "SDS",
        geou_dif: 0,
        geounit: "South Sudan",
        gu_a3: "SDS",
        su_dif: 0,
        subunit: "South Sudan",
        su_a3: "SDS",
        brk_diff: 0,
        name: "S. Sudan",
        name_long: "South Sudan",
        brk_a3: "SDS",
        brk_name: "S. Sudan",
        brk_group: null,
        abbrev: "S. Sud.",
        postal: "SS",
        formal_en: "Republic of South Sudan",
        formal_fr: null,
        name_ciawf: "South Sudan",
        note_adm0: null,
        note_brk: null,
        name_sort: "South Sudan",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 3,
        mapcolor9: 3,
        mapcolor13: 5,
        pop_est: 11062113,
        pop_rank: 14,
        pop_year: 2019,
        gdp_md: 11998,
        gdp_year: 2015,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        fips_10: "-99",
        iso_a2: "SS",
        iso_a2_eh: "SS",
        iso_a3: "SSD",
        iso_a3_eh: "SSD",
        iso_n3: "728",
        iso_n3_eh: "728",
        un_a3: "728",
        wb_a2: "SS",
        wb_a3: "SSD",
        woe_id: -99,
        woe_id_eh: -99,
        woe_note:
          "Includes states of 20069899, 20069897, 20069898, 20069901, 20069909, and 20069908 but maybe more?",
        adm0_iso: "SSD",
        adm0_diff: "1",
        adm0_tlc: "SDS",
        adm0_a3_us: "SDS",
        adm0_a3_fr: "SDS",
        adm0_a3_ru: "SDS",
        adm0_a3_es: "SDS",
        adm0_a3_cn: "SDS",
        adm0_a3_tw: "SDS",
        adm0_a3_in: "SDS",
        adm0_a3_np: "SDS",
        adm0_a3_pk: "SDS",
        adm0_a3_de: "SDS",
        adm0_a3_gb: "SDS",
        adm0_a3_br: "SDS",
        adm0_a3_il: "SDS",
        adm0_a3_ps: "SDS",
        adm0_a3_sa: "SDS",
        adm0_a3_eg: "SDS",
        adm0_a3_ma: "SDS",
        adm0_a3_pt: "SDS",
        adm0_a3_ar: "SDS",
        adm0_a3_jp: "SDS",
        adm0_a3_ko: "SDS",
        adm0_a3_vn: "SDS",
        adm0_a3_tr: "SDS",
        adm0_a3_id: "SDS",
        adm0_a3_pl: "SDS",
        adm0_a3_gr: "SDS",
        adm0_a3_it: "SDS",
        adm0_a3_nl: "SDS",
        adm0_a3_se: "SDS",
        adm0_a3_bd: "SDS",
        adm0_a3_ua: "SDS",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Eastern Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 8,
        long_len: 11,
        abbrev_len: 7,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: 30.390151,
        label_y: 7.230477,
        ne_id: 1159321235,
        wikidataid: "Q958",
        name_ar: "جنوب السودان",
        name_bn: "দক্ষিণ সুদান",
        name_de: "Südsudan",
        name_en: "South Sudan",
        name_es: "Sudán del Sur",
        name_fa: "سودان جنوبی",
        name_fr: "Soudan du Sud",
        name_el: "Νότιο Σουδάν",
        name_he: "דרום סודאן",
        name_hi: "दक्षिण सूडान",
        name_hu: "Dél-Szudán",
        name_id: "Sudan Selatan",
        name_it: "Sudan del Sud",
        name_ja: "南スーダン",
        name_ko: "남수단",
        name_nl: "Zuid-Soedan",
        name_pl: "Sudan Południowy",
        name_pt: "Sudão do Sul",
        name_ru: "Южный Судан",
        name_sv: "Sydsudan",
        name_tr: "Güney Sudan",
        name_uk: "Південний Судан",
        name_ur: "جنوبی سوڈان",
        name_vi: "Nam Sudan",
        name_zh: "南苏丹",
        name_zht: "南蘇丹",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "SSD.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [33.97607421875, 4.22021484375],
            [33.74160156250002, 3.985253906249994],
            [33.568457031250006, 3.81171875],
            [33.53955078125, 3.787109375],
            [33.489355468750006, 3.755078125],
            [33.324316406250006, 3.754345703124997],
            [33.15410156250002, 3.774707031249989],
            [32.99726562500001, 3.880175781249989],
            [32.83808593750001, 3.798486328124994],
            [32.73710937500002, 3.772705078125],
            [32.67695312500001, 3.76318359375],
            [32.534765625, 3.749951171874997],
            [32.33574218750002, 3.706201171874994],
            [32.24550781250002, 3.651318359374997],
            [32.19667968750002, 3.6078125],
            [32.15625, 3.528027343749997],
            [32.13593750000001, 3.519726562499997],
            [32.09941406250002, 3.529199218749994],
            [32.04824218750002, 3.561181640624994],
            [31.941796875000023, 3.607568359374994],
            [31.88828125, 3.709082031249991],
            [31.838671875000017, 3.770458984374997],
            [31.79804687500001, 3.802636718749994],
            [31.62890625, 3.701464843749989],
            [31.547167968750017, 3.677587890624991],
            [31.47998046875, 3.680468749999989],
            [31.357421875, 3.737597656249989],
            [31.221972656250017, 3.785937499999989],
            [31.15234375, 3.785595703124997],
            [31.04804687500001, 3.725],
            [30.92939453125001, 3.634082031249989],
            [30.8681640625, 3.544140625],
            [30.83857421875001, 3.49072265625],
            [30.81689453125, 3.533349609374994],
            [30.796972656250006, 3.573144531249994],
            [30.757226562500023, 3.62421875],
            [30.69990234375001, 3.644091796874989],
            [30.64765625000001, 3.634130859374991],
            [30.586718750000017, 3.62421875],
            [30.559375, 3.652783203124997],
            [30.553515625000017, 3.722949218749989],
            [30.536914062500017, 3.787207031249991],
            [30.50830078125, 3.835693359375],
            [30.420703125000017, 3.883886718749991],
            [30.194921875, 3.98193359375],
            [30.021386718750023, 4.177636718749994],
            [29.933984375000023, 4.268505859374997],
            [29.870214843750006, 4.3271484375],
            [29.779882812500006, 4.48095703125],
            [29.676855468750006, 4.5869140625],
            [29.552050781250017, 4.636035156249989],
            [29.469628906250023, 4.61181640625],
            [29.384863281250006, 4.498388671874991],
            [29.224902343750017, 4.391894531249989],
            [29.151464843750006, 4.38818359375],
            [29.057421875000017, 4.445947265624994],
            [28.939355468750023, 4.487060546875],
            [28.72705078125, 4.504980468749991],
            [28.639550781250023, 4.454492187499994],
            [28.524804687500023, 4.372851562499989],
            [28.427539062500017, 4.324169921874997],
            [28.3671875, 4.318652343749989],
            [28.31103515625, 4.338037109374994],
            [28.24726562500001, 4.348535156249994],
            [28.19208984375001, 4.350244140624994],
            [28.07861328125, 4.4248046875],
            [28.019824218750017, 4.479394531249994],
            [27.98066406250001, 4.532080078124991],
            [27.916601562500006, 4.567919921874989],
            [27.841601562500017, 4.597753906249991],
            [27.7880859375, 4.644677734374994],
            [27.76142578125001, 4.703222656249991],
            [27.71923828125, 4.7783203125],
            [27.664160156250006, 4.845996093749989],
            [27.491015625000017, 4.967578124999989],
            [27.439257812500017, 5.039208984374994],
            [27.4033203125, 5.109179687499989],
            [27.332421875000023, 5.186328124999989],
            [27.256738281250023, 5.289648437499991],
            [27.232519531250006, 5.440771484374991],
            [27.229101562500006, 5.5625],
            [27.21337890625, 5.618798828124994],
            [27.18125, 5.675146484374991],
            [27.143945312500023, 5.722949218749989],
            [27.083398437500023, 5.77685546875],
            [26.942285156250023, 5.854931640624997],
            [26.796484375, 5.945507812499997],
            [26.726367187500017, 5.998242187499997],
            [26.593652343750023, 6.017529296874997],
            [26.514257812500006, 6.069238281249994],
            [26.44746093750001, 6.183007812499994],
            [26.420507812500006, 6.274169921875],
            [26.353320312500017, 6.344921875],
            [26.324609375000023, 6.396240234375],
            [26.30859375, 6.455322265625],
            [26.36181640625, 6.635302734374989],
            [26.284570312500023, 6.699023437499989],
            [26.169335937500023, 6.78173828125],
            [26.0869140625, 6.872119140624989],
            [26.036523437500023, 6.955224609374994],
            [25.888964843750017, 7.06494140625],
            [25.56660156250001, 7.228710937499997],
            [25.380664062500017, 7.333398437499994],
            [25.27890625, 7.427490234375],
            [25.19013671875001, 7.519335937499989],
            [25.18134765625001, 7.557226562499991],
            [25.238671875000023, 7.648974609374989],
            [25.247363281250017, 7.724560546874997],
            [25.20039062500001, 7.807910156249989],
            [25.007226562500023, 7.96484375],
            [24.853320312500017, 8.137548828124991],
            [24.736718750000023, 8.191552734374994],
            [24.4560546875, 8.239453125],
            [24.37548828125, 8.258447265624994],
            [24.291406250000023, 8.29140625],
            [24.208398437500023, 8.369140625],
            [24.179980468750017, 8.461132812499997],
            [24.22089843750001, 8.608251953124991],
            [24.19482421875, 8.653369140624989],
            [24.147363281250023, 8.665625],
            [24.160449218750017, 8.6962890625],
            [24.21357421875001, 8.767822265625],
            [24.300195312500023, 8.814257812499989],
            [24.53193359375001, 8.886914062499997],
            [24.544824218750023, 8.914843749999989],
            [24.549414062500006, 9.006787109374997],
            [24.568261718750023, 9.051708984374997],
            [24.648046875, 9.179101562499994],
            [24.659375, 9.229931640624997],
            [24.662890625000017, 9.338134765625],
            [24.673632812500017, 9.389306640624994],
            [24.696679687500023, 9.425683593749994],
            [24.760351562500006, 9.488916015624994],
            [24.782617187500023, 9.52734375],
            [24.79218750000001, 9.610302734374997],
            [24.785253906250006, 9.774658203125],
            [24.817675781250017, 9.839599609375],
            [24.9638671875, 9.988867187499991],
            [25.0029296875, 10.055273437499991],
            [25.016210937500006, 10.115234375],
            [25.01484375000001, 10.175878906249991],
            [25.02363281250001, 10.235791015624997],
            [25.066992187500006, 10.293798828124991],
            [25.10400390625, 10.311816406249989],
            [25.211718750000017, 10.329931640624991],
            [25.28515625, 10.318505859374994],
            [25.79804687500001, 10.420507812499991],
            [25.858203125000017, 10.406494140625],
            [25.88525390625, 10.34609375],
            [25.8828125, 10.249609375],
            [25.891503906250023, 10.202734375],
            [25.91914062500001, 10.169335937499994],
            [26.000585937500006, 10.1234375],
            [26.057031250000023, 10.046777343749994],
            [26.087011718750006, 10.018457031249994],
            [26.16953125, 9.965917968749991],
            [26.551367187500006, 9.525830078124997],
            [26.65869140625, 9.484130859375],
            [26.76318359375, 9.49921875],
            [26.970507812500017, 9.590625],
            [27.07421875, 9.613818359374989],
            [27.7998046875, 9.587890625],
            [27.880859375, 9.601611328124989],
            [27.885839843750006, 9.599658203124989],
            [27.99628906250001, 9.378808593749994],
            [28.048925781250006, 9.32861328125],
            [28.844531250000017, 9.326074218749994],
            [28.829394531250017, 9.388818359374994],
            [28.839453125, 9.459082031249991],
            [28.93232421875001, 9.549462890624994],
            [28.979589843750006, 9.594189453124997],
            [28.979589843750006, 9.593994140625],
            [28.999609375, 9.610156249999989],
            [29.122363281250017, 9.674658203124991],
            [29.24238281250001, 9.718066406249989],
            [29.47314453125, 9.768603515624989],
            [29.557421875000017, 9.848291015624994],
            [29.603906250000023, 9.92138671875],
            [29.60546875, 10.065087890624994],
            [29.63593750000001, 10.088623046875],
            [29.691015625, 10.121923828124991],
            [29.957910156250023, 10.250244140625],
            [30.003027343750006, 10.277392578124989],
            [30.474609375, 9.978955078124997],
            [30.739355468750006, 9.74267578125],
            [30.75537109375, 9.731201171875],
            [30.769140625, 9.726806640625],
            [30.783105468750023, 9.734960937499991],
            [30.794921875, 9.745849609375],
            [30.81416015625001, 9.753125],
            [30.827050781250023, 9.756298828124997],
            [30.940332031250023, 9.759375],
            [31.15449218750001, 9.770947265624997],
            [31.224902343750017, 9.799267578124997],
            [31.654882812500006, 10.221142578124997],
            [31.764257812500006, 10.355712890625],
            [31.7919921875, 10.383154296874991],
            [31.854296875000017, 10.479052734374989],
            [31.919921875, 10.643847656249989],
            [31.933007812500023, 10.6625],
            [32.40410156250002, 11.057763671874994],
            [32.42080078125002, 11.089111328125],
            [32.425390625, 11.113964843749997],
            [32.35419921875001, 11.246923828124991],
            [32.3388671875, 11.314501953124989],
            [32.33574218750002, 11.418554687499991],
            [32.34990234375002, 11.580419921874991],
            [32.34492187500001, 11.682714843749991],
            [32.34306640625002, 11.694287109374997],
            [32.338476562500006, 11.710107421874994],
            [32.33535156250002, 11.716015625],
            [32.072265625, 12.006738281249994],
            [32.73671875000002, 12.009667968749994],
            [32.73828125, 12.033740234374989],
            [32.73769531250002, 12.046435546874989],
            [32.73564453125002, 12.058056640624997],
            [32.72304687500002, 12.092919921874994],
            [32.7158203125, 12.139257812499991],
            [32.71533203125, 12.152197265624991],
            [32.71630859375, 12.164843749999989],
            [32.72011718750002, 12.188818359374991],
            [32.72050781250002, 12.201806640624994],
            [32.719824218750006, 12.2080078125],
            [32.71855468750002, 12.213769531249994],
            [32.71894531250001, 12.218847656249991],
            [32.721875, 12.223095703124997],
            [33.199316406250006, 12.21728515625],
            [33.19306640625001, 12.135009765625],
            [33.13505859375002, 11.941601562499997],
            [33.13613281250002, 11.825585937499994],
            [33.12246093750002, 11.693164062499989],
            [33.119140625, 11.682421874999989],
            [33.106054687500006, 11.653857421874989],
            [33.09462890625002, 11.6375],
            [33.08154296875, 11.621728515624994],
            [33.07783203125001, 11.615771484374989],
            [33.073339843750006, 11.606103515624994],
            [33.07304687500002, 11.591503906249997],
            [33.17216796875002, 10.850146484374989],
            [33.16845703125, 10.831445312499994],
            [33.16474609375001, 10.819189453124991],
            [33.13828125, 10.77294921875],
            [33.131445312500006, 10.757714843749994],
            [33.13007812500001, 10.745947265624991],
            [33.14082031250001, 10.737890625],
            [33.36074218750002, 10.6578125],
            [33.37138671875002, 10.652734375],
            [33.3798828125, 10.646191406249997],
            [33.459082031250006, 10.550830078124989],
            [33.8921875, 10.198974609375],
            [33.90703125000002, 10.181445312499989],
            [33.95185546875001, 10.070947265624994],
            [33.95683593750002, 10.05419921875],
            [33.95839843750002, 10.027734375],
            [33.95732421875002, 10.007177734374991],
            [33.94619140625002, 9.94091796875],
            [33.94990234375001, 9.9111328125],
            [33.95732421875002, 9.891455078124991],
            [33.96328125000002, 9.868701171874989],
            [33.96328125000002, 9.861767578124997],
            [33.9625, 9.855810546874991],
            [33.95917968750001, 9.845263671874989],
            [33.89492187500002, 9.717626953124991],
            [33.8740234375, 9.626757812499989],
            [33.867773437500006, 9.550341796874989],
            [33.87148437500002, 9.506152343749989],
            [33.87880859375002, 9.477734375],
            [33.88212890625002, 9.47119140625],
            [33.884863281250006, 9.46640625],
            [33.88789062500001, 9.463525390624994],
            [33.89091796875002, 9.462207031249989],
            [34.076757812500006, 9.461523437499991],
            [34.078125, 9.461523437499991],
            [34.0771484375, 9.420996093749991],
            [34.084570312500006, 9.218505859375],
            [34.09101562500001, 9.041259765625],
            [34.1015625, 8.751855468749994],
            [34.10175781250001, 8.676367187499991],
            [34.09453125000002, 8.582226562499997],
            [34.07275390625, 8.545263671874991],
            [34.01972656250001, 8.492089843749994],
            [33.95332031250001, 8.443505859374994],
            [33.78505859375002, 8.431103515624997],
            [33.64482421875002, 8.432568359374997],
            [33.54531250000002, 8.443408203124989],
            [33.409375, 8.44775390625],
            [33.28105468750002, 8.437255859375],
            [33.23427734375002, 8.396386718749994],
            [33.16523437500001, 8.251074218749991],
            [33.06523437500002, 8.040478515624997],
            [33.01259765625002, 7.951513671874991],
            [32.99892578125002, 7.899511718749991],
            [33.0146484375, 7.868554687499994],
            [33.08076171875001, 7.82373046875],
            [33.22597656250002, 7.760644531249994],
            [33.39228515625001, 7.723730468749991],
            [33.51630859375001, 7.707763671875],
            [33.60097656250002, 7.6904296875],
            [33.666113281250006, 7.670996093749991],
            [33.902441406250006, 7.509521484375],
            [33.97792968750002, 7.4345703125],
            [34.02041015625002, 7.367968749999989],
            [34.03017578125002, 7.296972656249991],
            [34.06425781250002, 7.225732421874994],
            [34.20039062500001, 7.084570312499991],
            [34.279296875, 7.002832031249994],
            [34.484375, 6.898388671874997],
            [34.56279296875002, 6.779833984374989],
            [34.638769531250006, 6.72216796875],
            [34.71064453125001, 6.660302734374994],
            [34.74921875000001, 6.56787109375],
            [34.83808593750001, 6.300146484374991],
            [34.89785156250002, 6.159814453124994],
            [34.958984375, 6.045068359374994],
            [34.98359375000001, 5.858300781249994],
            [35.03193359375001, 5.77490234375],
            [35.08193359375002, 5.673144531249989],
            [35.16445312500002, 5.581201171874994],
            [35.25244140625, 5.511035156249989],
            [35.26835937500002, 5.492285156249991],
            [35.08447265625, 5.311865234374991],
            [34.87832031250002, 5.109570312499997],
            [34.63984375000001, 4.87548828125],
            [34.38017578125002, 4.620654296874989],
            [34.176855468750006, 4.419091796874994],
            [33.97607421875, 4.22021484375],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 6,
        sovereignt: "Somalia",
        sov_a3: "SOM",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Somalia",
        adm0_a3: "SOM",
        geou_dif: 0,
        geounit: "Somalia",
        gu_a3: "SOM",
        su_dif: 0,
        subunit: "Somalia",
        su_a3: "SOM",
        brk_diff: 0,
        name: "Somalia",
        name_long: "Somalia",
        brk_a3: "SOM",
        brk_name: "Somalia",
        brk_group: null,
        abbrev: "Som.",
        postal: "SO",
        formal_en: "Federal Republic of Somalia",
        formal_fr: null,
        name_ciawf: "Somalia",
        note_adm0: null,
        note_brk: null,
        name_sort: "Somalia",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 8,
        mapcolor9: 6,
        mapcolor13: 7,
        pop_est: 10192317.3,
        pop_rank: 14,
        pop_year: 2019,
        gdp_md: 4719,
        gdp_year: 2016,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        fips_10: "SO",
        iso_a2: "SO",
        iso_a2_eh: "SO",
        iso_a3: "SOM",
        iso_a3_eh: "SOM",
        iso_n3: "706",
        iso_n3_eh: "706",
        un_a3: "706",
        wb_a2: "SO",
        wb_a3: "SOM",
        woe_id: -90,
        woe_id_eh: 23424949,
        woe_note:
          "Includes Somaliland (2347021, 2347020, 2347017 and portion of 2347016)",
        adm0_iso: "SOM",
        adm0_diff: null,
        adm0_tlc: "SOM",
        adm0_a3_us: "SOM",
        adm0_a3_fr: "SOM",
        adm0_a3_ru: "SOM",
        adm0_a3_es: "SOM",
        adm0_a3_cn: "SOM",
        adm0_a3_tw: "SOM",
        adm0_a3_in: "SOM",
        adm0_a3_np: "SOM",
        adm0_a3_pk: "SOM",
        adm0_a3_de: "SOM",
        adm0_a3_gb: "SOM",
        adm0_a3_br: "SOM",
        adm0_a3_il: "SOM",
        adm0_a3_ps: "SOM",
        adm0_a3_sa: "SOM",
        adm0_a3_eg: "SOM",
        adm0_a3_ma: "SOM",
        adm0_a3_pt: "SOM",
        adm0_a3_ar: "SOM",
        adm0_a3_jp: "SOM",
        adm0_a3_ko: "SOM",
        adm0_a3_vn: "SOM",
        adm0_a3_tr: "SOM",
        adm0_a3_id: "SOM",
        adm0_a3_pl: "SOM",
        adm0_a3_gr: "SOM",
        adm0_a3_it: "SOM",
        adm0_a3_nl: "SOM",
        adm0_a3_se: "SOM",
        adm0_a3_bd: "SOM",
        adm0_a3_ua: "SOM",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Eastern Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: 45.19238,
        label_y: 3.568925,
        ne_id: 1159321261,
        wikidataid: "Q1045",
        name_ar: "الصومال",
        name_bn: "সোমালিয়া",
        name_de: "Somalia",
        name_en: "Somalia",
        name_es: "Somalia",
        name_fa: "سومالی",
        name_fr: "Somalie",
        name_el: "Σομαλία",
        name_he: "סומליה",
        name_hi: "सोमालिया",
        name_hu: "Szomália",
        name_id: "Somalia",
        name_it: "Somalia",
        name_ja: "ソマリア",
        name_ko: "소말리아",
        name_nl: "Somalië",
        name_pl: "Somalia",
        name_pt: "Somália",
        name_ru: "Сомали",
        name_sv: "Somalia",
        name_tr: "Somali",
        name_uk: "Сомалі",
        name_ur: "صومالیہ",
        name_vi: "Somalia",
        name_zh: "索马里",
        name_zht: "索馬利亞",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "SOM.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [41.53271484375, -1.6953125],
            [41.53759765625, -1.613183593750009],
            [41.521875, -1.572265625],
            [41.42695312500001, -1.449511718750003],
            [41.24980468750002, -1.220507812500003],
            [41.115820312500006, -1.047460937500006],
            [40.97871093750001, -0.870312500000011],
            [40.97822265625001, -0.728710937500011],
            [40.9765625, -0.307324218750011],
            [40.973242187500006, 0.535400390625],
            [40.97001953125002, 1.378173828125],
            [40.96669921875002, 2.220947265625],
            [40.96503906250001, 2.642333984375],
            [40.964453125, 2.814648437499997],
            [40.97871093750001, 2.842431640624994],
            [41.13496093750001, 2.9970703125],
            [41.341796875, 3.20166015625],
            [41.61347656250001, 3.590478515624994],
            [41.76093750000001, 3.801611328124991],
            [41.88398437500001, 3.977734375],
            [41.91533203125002, 4.031298828124989],
            [42.02412109375001, 4.137939453125],
            [42.22841796875002, 4.20166015625],
            [42.35517578125001, 4.212255859374991],
            [42.791601562500006, 4.2919921875],
            [42.85664062500001, 4.32421875],
            [42.89472656250001, 4.361083984375],
            [42.93095703125002, 4.4453125],
            [43.01601562500002, 4.563330078124991],
            [43.12568359375001, 4.644482421874997],
            [43.333984375, 4.750390625],
            [43.53828125000001, 4.84033203125],
            [43.58349609375, 4.85498046875],
            [43.829199218750006, 4.911425781249989],
            [43.88945312500002, 4.930761718749991],
            [43.988867187500006, 4.950537109374991],
            [44.028125, 4.950976562499989],
            [44.36953125000002, 4.931201171874989],
            [44.63662109375002, 4.915771484375],
            [44.91162109375, 4.89990234375],
            [44.940527343750006, 4.912011718749994],
            [45.1328125, 5.121679687499991],
            [45.4384765625, 5.455419921874991],
            [45.63359375000002, 5.668261718749989],
            [45.93496093750002, 5.997216796874994],
            [46.16679687500002, 6.234667968749989],
            [46.422949218750006, 6.497265625],
            [46.67177734375002, 6.737255859374997],
            [46.97119140625, 7.026025390624994],
            [47.159765625, 7.207861328124991],
            [47.45283203125001, 7.490478515625],
            [47.73164062500001, 7.759326171874989],
            [47.97822265625001, 7.9970703125],
            [48.12675781250002, 8.22216796875],
            [48.27275390625002, 8.443359375],
            [48.42861328125002, 8.679589843749994],
            [48.61660156250002, 8.964599609375],
            [48.793554687500006, 9.232714843749989],
            [48.938085937500006, 9.451757812499991],
            [48.938085937500006, 9.564111328124994],
            [48.93828125000002, 9.8076171875],
            [48.93828125000002, 9.973486328124991],
            [48.93837890625002, 10.433251953124994],
            [48.9384765625, 10.714208984374991],
            [48.9384765625, 10.982324218749994],
            [48.938574218750006, 11.258447265624994],
            [49.062109375, 11.270849609374991],
            [49.38828125, 11.342724609374997],
            [49.64208984375, 11.450927734375],
            [50.11005859375001, 11.529296875],
            [50.46621093750002, 11.7275390625],
            [50.5283203125, 11.823193359374997],
            [50.63593750000001, 11.943798828124997],
            [50.79228515625002, 11.983691406249989],
            [51.19130859375002, 11.841992187499997],
            [51.2548828125, 11.830712890624994],
            [51.23183593750002, 11.745019531249994],
            [51.21816406250002, 11.657666015624997],
            [51.136328125, 11.505126953125],
            [51.08427734375002, 11.335644531249997],
            [51.12226562500001, 11.076757812499991],
            [51.140625, 10.656884765624994],
            [51.13125, 10.595898437499997],
            [51.10488281250002, 10.535839843749997],
            [51.093847656250006, 10.488525390625],
            [51.05078125, 10.471972656249989],
            [51.031835937500006, 10.444775390624997],
            [51.06318359375001, 10.433935546874991],
            [51.18828125000002, 10.479736328125],
            [51.185546875, 10.529833984374989],
            [51.19296875, 10.554638671874997],
            [51.29570312500002, 10.498681640624994],
            [51.369140625, 10.475244140624994],
            [51.390234375, 10.422607421875],
            [51.38457031250002, 10.386523437499989],
            [51.268164062500006, 10.403125],
            [51.20878906250002, 10.431054687499994],
            [51.03593750000002, 10.38515625],
            [50.93007812500002, 10.335546875],
            [50.8984375, 10.253125],
            [50.87373046875001, 9.924169921874991],
            [50.83281250000002, 9.710498046874989],
            [50.825, 9.428173828124997],
            [50.68515625, 9.241162109374997],
            [50.63798828125002, 9.109277343749994],
            [50.429785156250006, 8.845263671874989],
            [50.32119140625002, 8.619580078124997],
            [50.285742187500006, 8.509423828124994],
            [50.10283203125002, 8.199804687499991],
            [49.85205078125, 7.962548828124994],
            [49.76123046875, 7.659521484374991],
            [49.67119140625002, 7.469531249999989],
            [49.57001953125001, 7.296972656249991],
            [49.34853515625002, 6.990527343749989],
            [49.234960937500006, 6.77734375],
            [49.09267578125002, 6.407861328124994],
            [49.04931640625, 6.173632812499989],
            [48.649023437500006, 5.494384765625],
            [48.233984375, 4.952685546874989],
            [47.97529296875001, 4.497021484374997],
            [47.51142578125001, 3.96826171875],
            [46.87880859375002, 3.28564453125],
            [46.05117187500002, 2.475146484374989],
            [45.826269531250006, 2.309863281249989],
            [44.92021484375002, 1.81015625],
            [44.33271484375001, 1.390966796874991],
            [44.03271484375, 1.105908203124997],
            [43.71757812500002, 0.857861328124997],
            [43.46767578125002, 0.621630859374989],
            [42.71210937500001, -0.175683593750009],
            [42.63417968750002, -0.25078125],
            [42.56074218750001, -0.321484375000011],
            [42.465625, -0.45654296875],
            [42.3994140625, -0.510058593750003],
            [42.21894531250001, -0.737988281250011],
            [42.10625, -0.856152343750011],
            [41.97988281250002, -0.973046875],
            [41.92626953125, -1.055566406250009],
            [41.88828125, -1.150585937500011],
            [41.84619140625, -1.203417968750003],
            [41.73222656250002, -1.430078125],
            [41.63203125000001, -1.578515625],
            [41.53271484375, -1.6953125],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 5,
        labelrank: 6,
        sovereignt: "Malawi",
        sov_a3: "MWI",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Malawi",
        adm0_a3: "MWI",
        geou_dif: 0,
        geounit: "Malawi",
        gu_a3: "MWI",
        su_dif: 0,
        subunit: "Malawi",
        su_a3: "MWI",
        brk_diff: 0,
        name: "Malawi",
        name_long: "Malawi",
        brk_a3: "MWI",
        brk_name: "Malawi",
        brk_group: null,
        abbrev: "Mal.",
        postal: "MW",
        formal_en: "Republic of Malawi",
        formal_fr: null,
        name_ciawf: "Malawi",
        note_adm0: null,
        note_brk: null,
        name_sort: "Malawi",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 3,
        mapcolor9: 4,
        mapcolor13: 5,
        pop_est: 18628747,
        pop_rank: 14,
        pop_year: 2019,
        gdp_md: 7666,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        fips_10: "MI",
        iso_a2: "MW",
        iso_a2_eh: "MW",
        iso_a3: "MWI",
        iso_a3_eh: "MWI",
        iso_n3: "454",
        iso_n3_eh: "454",
        un_a3: "454",
        wb_a2: "MW",
        wb_a3: "MWI",
        woe_id: 23424889,
        woe_id_eh: 23424889,
        woe_note: "Exact WOE match as country",
        adm0_iso: "MWI",
        adm0_diff: null,
        adm0_tlc: "MWI",
        adm0_a3_us: "MWI",
        adm0_a3_fr: "MWI",
        adm0_a3_ru: "MWI",
        adm0_a3_es: "MWI",
        adm0_a3_cn: "MWI",
        adm0_a3_tw: "MWI",
        adm0_a3_in: "MWI",
        adm0_a3_np: "MWI",
        adm0_a3_pk: "MWI",
        adm0_a3_de: "MWI",
        adm0_a3_gb: "MWI",
        adm0_a3_br: "MWI",
        adm0_a3_il: "MWI",
        adm0_a3_ps: "MWI",
        adm0_a3_sa: "MWI",
        adm0_a3_eg: "MWI",
        adm0_a3_ma: "MWI",
        adm0_a3_pt: "MWI",
        adm0_a3_ar: "MWI",
        adm0_a3_jp: "MWI",
        adm0_a3_ko: "MWI",
        adm0_a3_vn: "MWI",
        adm0_a3_tr: "MWI",
        adm0_a3_id: "MWI",
        adm0_a3_pl: "MWI",
        adm0_a3_gr: "MWI",
        adm0_a3_it: "MWI",
        adm0_a3_nl: "MWI",
        adm0_a3_se: "MWI",
        adm0_a3_bd: "MWI",
        adm0_a3_ua: "MWI",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Eastern Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 6,
        long_len: 6,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: 33.608082,
        label_y: -13.386737,
        ne_id: 1159321081,
        wikidataid: "Q1020",
        name_ar: "مالاوي",
        name_bn: "মালাউই",
        name_de: "Malawi",
        name_en: "Malawi",
        name_es: "Malaui",
        name_fa: "مالاوی",
        name_fr: "Malawi",
        name_el: "Μαλάουι",
        name_he: "מלאווי",
        name_hi: "मलावी",
        name_hu: "Malawi",
        name_id: "Malawi",
        name_it: "Malawi",
        name_ja: "マラウイ",
        name_ko: "말라위",
        name_nl: "Malawi",
        name_pl: "Malawi",
        name_pt: "Malawi",
        name_ru: "Малави",
        name_sv: "Malawi",
        name_tr: "Malavi",
        name_uk: "Малаві",
        name_ur: "ملاوی",
        name_vi: "Malawi",
        name_zh: "马拉维",
        name_zht: "馬拉威",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "MWI.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [34.719335937500006, -12.110644531250003],
              [34.684179687500006, -12.11865234375],
              [34.662109375, -12.100781250000011],
              [34.66748046875, -12.047558593750011],
              [34.67988281250001, -12.008886718750006],
              [34.714941406250006, -12.002734375],
              [34.73896484375001, -12.013085937500009],
              [34.755957031250006, -12.03076171875],
              [34.75625, -12.05908203125],
              [34.74599609375002, -12.08837890625],
              [34.719335937500006, -12.110644531250003],
            ],
          ],
          [
            [
              [34.6416015625, -12.013671875],
              [34.62177734375001, -12.066601562500011],
              [34.58046875000002, -12.065820312500009],
              [34.541601562500006, -12.018652343750006],
              [34.55400390625002, -11.982226562500003],
              [34.59140625, -11.97109375],
              [34.62421875000001, -11.984765625],
              [34.6416015625, -12.013671875],
            ],
          ],
          [
            [
              [33.201757812500006, -14.013378906250011],
              [33.148046875, -13.94091796875],
              [33.103613281250006, -13.959179687500011],
              [33.04238281250002, -14.010058593750003],
              [33.00927734375, -14.023730468750003],
              [32.99208984375002, -14.022167968750011],
              [32.98125, -14.009375],
              [32.96757812500002, -13.976855468750003],
              [32.92031250000002, -13.883886718750006],
              [32.8671875, -13.8173828125],
              [32.81103515625, -13.791601562500006],
              [32.76513671875, -13.761035156250003],
              [32.78535156250001, -13.7314453125],
              [32.806738281250006, -13.710253906250003],
              [32.797460937500006, -13.6884765625],
              [32.77177734375002, -13.656542968750003],
              [32.67207031250001, -13.6103515625],
              [32.67041015625, -13.590429687500006],
              [32.758398437500006, -13.55029296875],
              [32.8140625, -13.502734375],
              [32.85185546875002, -13.45703125],
              [32.89970703125002, -13.35703125],
              [32.938574218750006, -13.257421875],
              [32.96757812500002, -13.225],
              [32.977636718750006, -13.158886718750011],
              [32.97109375000002, -13.084277343750003],
              [32.99042968750001, -12.989453125000011],
              [33, -12.899609375000011],
              [32.97050781250002, -12.86474609375],
              [32.94560546875002, -12.804394531250011],
              [32.975195312500006, -12.701367187500011],
              [33.021582031250006, -12.63046875],
              [33.24345703125002, -12.556542968750009],
              [33.39794921875, -12.48984375],
              [33.4306640625, -12.46044921875],
              [33.48320312500002, -12.403417968750006],
              [33.512304687500006, -12.347753906250006],
              [33.49140625000001, -12.3310546875],
              [33.37001953125002, -12.3296875],
              [33.34013671875002, -12.308300781250011],
              [33.25234375000002, -12.112597656250003],
              [33.30097656250001, -11.88818359375],
              [33.30507812500002, -11.8],
              [33.30390625000001, -11.690820312500009],
              [33.28828125000001, -11.611132812500003],
              [33.25, -11.57763671875],
              [33.22636718750002, -11.534863281250011],
              [33.23271484375002, -11.417675781250011],
              [33.26835937500002, -11.40390625],
              [33.34550781250002, -11.249121093750006],
              [33.37978515625002, -11.157910156250011],
              [33.33867187500002, -11.085156250000011],
              [33.29326171875002, -10.981152343750011],
              [33.27275390625002, -10.9150390625],
              [33.261328125, -10.893359375],
              [33.29277343750002, -10.85234375],
              [33.34492187500001, -10.812695312500011],
              [33.403125, -10.8017578125],
              [33.46474609375002, -10.783105468750009],
              [33.65908203125002, -10.590527343750011],
              [33.66152343750002, -10.553125],
              [33.62617187500001, -10.488574218750003],
              [33.5537109375, -10.391308593750011],
              [33.53759765625, -10.3515625],
              [33.52890625, -10.234667968750003],
              [33.500097656250006, -10.19970703125],
              [33.3935546875, -10.120898437500003],
              [33.3115234375, -10.037988281250009],
              [33.33710937500001, -9.954003906250009],
              [33.35097656250002, -9.862207031250009],
              [33.31044921875002, -9.811816406250003],
              [33.25, -9.759570312500003],
              [33.21269531250002, -9.683007812500009],
              [33.19570312500002, -9.626171875000011],
              [33.148046875, -9.603515625],
              [33.1044921875, -9.602636718750006],
              [33.07246093750001, -9.63818359375],
              [33.03779296875001, -9.635058593750003],
              [32.99599609375002, -9.622851562500003],
              [32.98212890625001, -9.573632812500009],
              [32.97988281250002, -9.5203125],
              [32.95107421875002, -9.484179687500003],
              [32.92333984375, -9.433984375],
              [32.919921875, -9.407421875000011],
              [32.93730468750002, -9.399707031250003],
              [32.97402343750002, -9.39501953125],
              [33.13046875, -9.495898437500003],
              [33.22529296875001, -9.50048828125],
              [33.33085937500002, -9.519140625],
              [33.4208984375, -9.608007812500006],
              [33.4677734375, -9.619726562500006],
              [33.52753906250001, -9.607519531250006],
              [33.69765625000002, -9.59814453125],
              [33.766210937500006, -9.6109375],
              [33.85419921875001, -9.662988281250009],
              [33.88886718750001, -9.670117187500011],
              [33.943945312500006, -9.672167968750003],
              [33.953710937500006, -9.658203125],
              [33.959375, -9.62734375],
              [33.94960937500002, -9.565332031250009],
              [33.96210937500001, -9.53173828125],
              [33.99560546875, -9.495410156250003],
              [34.08857421875001, -9.537792968750011],
              [34.320898437500006, -9.731542968750006],
              [34.32783203125001, -9.756542968750011],
              [34.47597656250002, -9.948828125],
              [34.52421875000002, -10.030175781250009],
              [34.52421875000002, -10.073144531250009],
              [34.569921875, -10.241113281250009],
              [34.57998046875002, -10.31982421875],
              [34.56972656250002, -10.3796875],
              [34.57158203125002, -10.427636718750009],
              [34.58955078125001, -10.496191406250006],
              [34.58359375, -10.525097656250011],
              [34.63652343750002, -10.625585937500006],
              [34.66181640625001, -10.710058593750006],
              [34.667089843750006, -10.79248046875],
              [34.65234375, -10.872851562500003],
              [34.60566406250001, -10.990234375],
              [34.59765625, -11.0375],
              [34.60791015625, -11.08046875],
              [34.63808593750002, -11.127148437500011],
              [34.6884765625, -11.177441406250011],
              [34.72646484375002, -11.238183593750009],
              [34.75214843750001, -11.309472656250009],
              [34.77382812500002, -11.341699218750009],
              [34.800878906250006, -11.340917968750006],
              [34.8505859375, -11.351953125],
              [34.890625, -11.3935546875],
              [34.93701171875, -11.463476562500006],
              [34.95263671875, -11.54375],
              [34.95947265625, -11.578125],
              [34.82656250000002, -11.57568359375],
              [34.65957031250002, -11.588671875],
              [34.61855468750002, -11.620214843750006],
              [34.60625, -11.690039062500006],
              [34.55390625000001, -11.834082031250006],
              [34.52480468750002, -11.887011718750003],
              [34.462890625, -11.983789062500009],
              [34.3759765625, -12.120214843750006],
              [34.35781250000002, -12.164746093750011],
              [34.36083984375, -12.210546875],
              [34.412109375, -12.395898437500009],
              [34.4658203125, -12.590722656250009],
              [34.48291015625, -12.666796875],
              [34.52128906250002, -12.92578125],
              [34.542578125, -13.108691406250003],
              [34.54570312500002, -13.21630859375],
              [34.56367187500001, -13.36015625],
              [34.61152343750001, -13.437890625],
              [34.66162109375, -13.48671875],
              [34.85048828125002, -13.516015625],
              [34.906835937500006, -13.551660156250009],
              [35.01386718750001, -13.643457031250009],
              [35.24746093750002, -13.896875],
              [35.37578125000002, -14.058691406250006],
              [35.48847656250001, -14.201074218750009],
              [35.6904296875, -14.465527343750011],
              [35.84716796875, -14.6708984375],
              [35.86669921875, -14.86376953125],
              [35.89277343750001, -14.891796875000011],
              [35.839941406250006, -15.03466796875],
              [35.80537109375001, -15.265625],
              [35.83027343750001, -15.4189453125],
              [35.819921875, -15.680371093750011],
              [35.79121093750001, -15.958691406250011],
              [35.75527343750002, -16.05830078125001],
              [35.70888671875002, -16.095800781250006],
              [35.59931640625001, -16.12587890625001],
              [35.358496093750006, -16.160546875],
              [35.32246093750001, -16.193164062500003],
              [35.29150390625, -16.247167968750006],
              [35.242773437500006, -16.375390625],
              [35.18525390625001, -16.5048828125],
              [35.16718750000001, -16.56025390625001],
              [35.178320312500006, -16.573339843750006],
              [35.22978515625002, -16.639257812500006],
              [35.28115234375002, -16.80781250000001],
              [35.29042968750002, -17.096972656250003],
              [35.272558593750006, -17.118457031250003],
              [35.20136718750001, -17.13105468750001],
              [35.124609375, -17.127246093750003],
              [35.09306640625002, -17.1109375],
              [35.06464843750001, -17.07861328125],
              [35.0439453125, -17.016894531250003],
              [35.09423828125, -16.97382812500001],
              [35.11210937500002, -16.898535156250006],
              [35.07988281250002, -16.83388671875001],
              [35.01533203125001, -16.81953125000001],
              [34.93339843750002, -16.760351562500006],
              [34.7587890625, -16.56708984375001],
              [34.61269531250002, -16.43154296875001],
              [34.528125, -16.319140625],
              [34.44130859375002, -16.2744140625],
              [34.41640625000002, -16.24677734375001],
              [34.3955078125, -16.19921875],
              [34.395117187500006, -16.130859375],
              [34.40302734375001, -16.08027343750001],
              [34.3759765625, -16.023730468750003],
              [34.28828125000001, -15.936132812500006],
              [34.24824218750001, -15.8875],
              [34.24609375, -15.829394531250003],
              [34.28300781250002, -15.7734375],
              [34.358007812500006, -15.705273437500011],
              [34.41474609375001, -15.566796875],
              [34.43496093750002, -15.477148437500006],
              [34.54082031250002, -15.297265625],
              [34.55546875000002, -15.140917968750003],
              [34.5576171875, -15.015917968750003],
              [34.55117187500002, -14.92236328125],
              [34.52412109375001, -14.730761718750003],
              [34.50527343750002, -14.59814453125],
              [34.375, -14.4248046875],
              [34.33251953125, -14.40859375],
              [34.20878906250002, -14.423730468750009],
              [34.10185546875002, -14.449316406250006],
              [34.049414062500006, -14.485253906250009],
              [33.969824218750006, -14.487109375],
              [33.76142578125001, -14.517285156250011],
              [33.69609375000002, -14.5302734375],
              [33.658300781250006, -14.561621093750006],
              [33.63642578125001, -14.568164062500003],
              [33.50527343750002, -14.43408203125],
              [33.38994140625002, -14.289453125],
              [33.24355468750002, -14.043066406250006],
              [33.201757812500006, -14.013378906250011],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 3,
        labelrank: 3,
        sovereignt: "United Republic of Tanzania",
        sov_a3: "TZA",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "United Republic of Tanzania",
        adm0_a3: "TZA",
        geou_dif: 0,
        geounit: "Tanzania",
        gu_a3: "TZA",
        su_dif: 0,
        subunit: "Tanzania",
        su_a3: "TZA",
        brk_diff: 0,
        name: "Tanzania",
        name_long: "Tanzania",
        brk_a3: "TZA",
        brk_name: "Tanzania",
        brk_group: null,
        abbrev: "Tanz.",
        postal: "TZ",
        formal_en: "United Republic of Tanzania",
        formal_fr: null,
        name_ciawf: "Tanzania",
        note_adm0: null,
        note_brk: null,
        name_sort: "Tanzania",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 6,
        mapcolor9: 2,
        mapcolor13: 2,
        pop_est: 58005463,
        pop_rank: 16,
        pop_year: 2019,
        gdp_md: 63177,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        fips_10: "TZ",
        iso_a2: "TZ",
        iso_a2_eh: "TZ",
        iso_a3: "TZA",
        iso_a3_eh: "TZA",
        iso_n3: "834",
        iso_n3_eh: "834",
        un_a3: "834",
        wb_a2: "TZ",
        wb_a3: "TZA",
        woe_id: 23424973,
        woe_id_eh: 23424973,
        woe_note: "Exact WOE match as country",
        adm0_iso: "TZA",
        adm0_diff: null,
        adm0_tlc: "TZA",
        adm0_a3_us: "TZA",
        adm0_a3_fr: "TZA",
        adm0_a3_ru: "TZA",
        adm0_a3_es: "TZA",
        adm0_a3_cn: "TZA",
        adm0_a3_tw: "TZA",
        adm0_a3_in: "TZA",
        adm0_a3_np: "TZA",
        adm0_a3_pk: "TZA",
        adm0_a3_de: "TZA",
        adm0_a3_gb: "TZA",
        adm0_a3_br: "TZA",
        adm0_a3_il: "TZA",
        adm0_a3_ps: "TZA",
        adm0_a3_sa: "TZA",
        adm0_a3_eg: "TZA",
        adm0_a3_ma: "TZA",
        adm0_a3_pt: "TZA",
        adm0_a3_ar: "TZA",
        adm0_a3_jp: "TZA",
        adm0_a3_ko: "TZA",
        adm0_a3_vn: "TZA",
        adm0_a3_tr: "TZA",
        adm0_a3_id: "TZA",
        adm0_a3_pl: "TZA",
        adm0_a3_gr: "TZA",
        adm0_a3_it: "TZA",
        adm0_a3_nl: "TZA",
        adm0_a3_se: "TZA",
        adm0_a3_bd: "TZA",
        adm0_a3_ua: "TZA",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Eastern Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 8,
        long_len: 8,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: 34.959183,
        label_y: -6.051866,
        ne_id: 1159321337,
        wikidataid: "Q924",
        name_ar: "تنزانيا",
        name_bn: "তানজানিয়া",
        name_de: "Tansania",
        name_en: "Tanzania",
        name_es: "Tanzania",
        name_fa: "تانزانیا",
        name_fr: "Tanzanie",
        name_el: "Τανζανία",
        name_he: "טנזניה",
        name_hi: "तंज़ानिया",
        name_hu: "Tanzánia",
        name_id: "Tanzania",
        name_it: "Tanzania",
        name_ja: "タンザニア",
        name_ko: "탄자니아",
        name_nl: "Tanzania",
        name_pl: "Tanzania",
        name_pt: "Tanzânia",
        name_ru: "Танзания",
        name_sv: "Tanzania",
        name_tr: "Tanzanya",
        name_uk: "Танзанія",
        name_ur: "تنزانیہ",
        name_vi: "Tanzania",
        name_zh: "坦桑尼亚",
        name_zht: "坦尚尼亞",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "TZA.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [39.49648437500002, -6.174609375],
              [39.57304687500002, -6.387402343750011],
              [39.56318359375001, -6.42724609375],
              [39.50917968750002, -6.45166015625],
              [39.48095703125, -6.453710937500006],
              [39.447363281250006, -6.419726562500003],
              [39.42363281250002, -6.347851562500011],
              [39.38261718750002, -6.364941406250011],
              [39.31269531250001, -6.279101562500003],
              [39.24345703125002, -6.275],
              [39.18232421875001, -6.172558593750011],
              [39.20625, -6.083203125000011],
              [39.1923828125, -5.931054687500009],
              [39.26699218750002, -5.853125],
              [39.30898437500002, -5.721972656250003],
              [39.35722656250002, -5.8115234375],
              [39.368261718750006, -5.951171875],
              [39.43330078125001, -6.115429687500011],
              [39.487890625, -6.166210937500011],
              [39.49648437500002, -6.174609375],
            ],
          ],
          [
            [
              [39.86503906250002, -4.906152343750009],
              [39.87099609375002, -4.95654296875],
              [39.85566406250001, -5.004003906250006],
              [39.858984375, -5.155175781250009],
              [39.85302734375, -5.25546875],
              [39.7958984375, -5.394433593750009],
              [39.74931640625002, -5.44384765625],
              [39.707617187500006, -5.429492187500003],
              [39.6734375, -5.406640625],
              [39.64677734375002, -5.368554687500009],
              [39.70107421875002, -5.113671875],
              [39.6734375, -4.927050781250003],
              [39.78076171875, -4.944921875],
              [39.86503906250002, -4.906152343750009],
            ],
          ],
          [
            [
              [32.919921875, -9.407421875000011],
              [32.86328125, -9.380859375],
              [32.75664062500002, -9.322265625],
              [32.6083984375, -9.2705078125],
              [32.48710937500002, -9.212695312500003],
              [32.433203125, -9.156347656250006],
              [32.3193359375, -9.134863281250006],
              [32.22089843750001, -9.125585937500006],
              [32.12978515625002, -9.073339843750006],
              [32.03535156250001, -9.0673828125],
              [31.94257812500001, -9.054003906250003],
              [31.921875, -9.019433593750009],
              [31.91865234375001, -8.9421875],
              [31.886132812500023, -8.921972656250006],
              [31.81806640625001, -8.902246093750009],
              [31.744726562500006, -8.903222656250009],
              [31.7, -8.914355468750003],
              [31.673632812500017, -8.908789062500006],
              [31.61279296875, -8.86328125],
              [31.55625, -8.80546875],
              [31.53486328125001, -8.71328125],
              [31.44921875, -8.65390625],
              [31.3505859375, -8.60703125],
              [31.07636718750001, -8.611914062500006],
              [31.03339843750001, -8.59765625],
              [30.968359375, -8.550976562500011],
              [30.891992187500023, -8.473730468750006],
              [30.830664062500006, -8.385546875],
              [30.776757812500023, -8.265820312500011],
              [30.75117187500001, -8.193652343750003],
              [30.72089843750001, -8.104394531250009],
              [30.65380859375, -7.970898437500011],
              [30.558886718750017, -7.781933593750011],
              [30.485644531250017, -7.627148437500011],
              [30.40673828125, -7.460644531250011],
              [30.37451171875, -7.338671875],
              [30.31318359375001, -7.203710937500006],
              [30.212695312500017, -7.037890625],
              [30.16181640625001, -6.973046875],
              [30.10625, -6.9150390625],
              [29.961816406250023, -6.803125],
              [29.798144531250017, -6.69189453125],
              [29.70966796875001, -6.616894531250011],
              [29.590625, -6.394433593750009],
              [29.540820312500017, -6.313867187500009],
              [29.50625, -6.172070312500011],
              [29.480078125, -6.025],
              [29.490820312500006, -5.965429687500006],
              [29.59638671875001, -5.775976562500006],
              [29.60703125, -5.72265625],
              [29.594140625000023, -5.65078125],
              [29.542382812500023, -5.499804687500003],
              [29.503710937500017, -5.400976562500006],
              [29.476464843750023, -5.316601562500011],
              [29.42011718750001, -5.176171875],
              [29.3427734375, -4.983105468750011],
              [29.32343750000001, -4.898828125],
              [29.32568359375, -4.835644531250011],
              [29.367578125000023, -4.668847656250009],
              [29.404199218750023, -4.496679687500006],
              [29.403222656250023, -4.449316406250006],
              [29.7177734375, -4.455859375],
              [29.76953125, -4.418066406250006],
              [29.947265625, -4.307324218750011],
              [30.14716796875001, -4.085351562500009],
              [30.187109375, -3.992871093750011],
              [30.2685546875, -3.850488281250009],
              [30.348437500000017, -3.77978515625],
              [30.37910156250001, -3.730761718750003],
              [30.4, -3.65390625],
              [30.425, -3.5888671875],
              [30.529882812500006, -3.492480468750003],
              [30.631933593750006, -3.418652343750011],
              [30.624609375, -3.388671875],
              [30.6109375, -3.366406250000011],
              [30.626074218750006, -3.347363281250011],
              [30.68183593750001, -3.309375],
              [30.79023437500001, -3.274609375000011],
              [30.811425781250023, -3.200585937500009],
              [30.811132812500006, -3.116406250000011],
              [30.793554687500006, -3.0693359375],
              [30.796875, -3.01513671875],
              [30.7802734375, -2.98486328125],
              [30.70947265625, -2.977246093750011],
              [30.604296875000017, -2.935253906250011],
              [30.515039062500023, -2.917578125],
              [30.45556640625, -2.893164062500006],
              [30.433496093750023, -2.87451171875],
              [30.42402343750001, -2.824023437500003],
              [30.441308593750023, -2.76904296875],
              [30.450488281250017, -2.753222656250003],
              [30.47333984375001, -2.6943359375],
              [30.434375, -2.658886718750011],
              [30.424218750000023, -2.6416015625],
              [30.441992187500006, -2.613476562500011],
              [30.53369140625, -2.42626953125],
              [30.553613281250023, -2.400097656250011],
              [30.593359375, -2.396777343750003],
              [30.656640625000023, -2.373828125],
              [30.71484375, -2.363476562500011],
              [30.7625, -2.371679687500006],
              [30.797656250000017, -2.362695312500009],
              [30.828710937500006, -2.338476562500006],
              [30.85498046875, -2.265429687500003],
              [30.8765625, -2.143359375],
              [30.864648437500023, -2.044042968750006],
              [30.819140625000017, -1.967480468750011],
              [30.806738281250006, -1.850683593750006],
              [30.827539062500023, -1.693652343750003],
              [30.812597656250006, -1.563085937500006],
              [30.76220703125, -1.458691406250011],
              [30.710742187500017, -1.396777343750003],
              [30.631933593750006, -1.367480468750003],
              [30.508105468750017, -1.208203125000011],
              [30.47021484375, -1.131152343750003],
              [30.47705078125, -1.0830078125],
              [30.50996093750001, -1.067285156250009],
              [30.519921875000023, -1.0625],
              [30.598730468750006, -1.069726562500009],
              [30.672753906250023, -1.051367187500006],
              [30.741992187500017, -1.007519531250011],
              [30.809179687500006, -0.994921875],
              [30.823632812500023, -0.9990234375],
              [30.8447265625, -1.002050781250006],
              [30.94970703125, -1.002050781250006],
              [31.127539062500006, -1.002050781250006],
              [31.305273437500006, -1.002050781250006],
              [31.48310546875001, -1.002050781250006],
              [31.66083984375001, -1.002050781250006],
              [31.83857421875001, -1.002050781250006],
              [32.01640625000002, -1.002050781250006],
              [32.19414062500002, -1.002050781250006],
              [32.371875, -1.002050781250006],
              [32.54970703125002, -1.002050781250006],
              [32.72744140625002, -1.002050781250006],
              [32.90517578125002, -1.002050781250006],
              [33.0830078125, -1.002050781250006],
              [33.2607421875, -1.002050781250006],
              [33.4384765625, -1.002050781250006],
              [33.616308593750006, -1.002050781250006],
              [33.794042968750006, -1.002050781250006],
              [33.90322265625002, -1.002050781250006],
              [33.97939453125002, -1.002050781250006],
              [34.05156250000002, -1.03984375],
              [34.13164062500002, -1.084570312500006],
              [34.3447265625, -1.20361328125],
              [34.55791015625002, -1.322558593750003],
              [34.77109375, -1.441601562500011],
              [34.98427734375002, -1.560546875],
              [35.19746093750001, -1.679589843750009],
              [35.41054687500002, -1.798632812500003],
              [35.62373046875001, -1.917578125],
              [35.8369140625, -2.03662109375],
              [36.05, -2.155664062500009],
              [36.26308593750002, -2.274609375000011],
              [36.47636718750002, -2.3935546875],
              [36.689453125, -2.512597656250009],
              [36.90263671875002, -2.631640625],
              [37.115820312500006, -2.750585937500006],
              [37.32900390625002, -2.86962890625],
              [37.54218750000001, -2.988574218750003],
              [37.64384765625002, -3.04541015625],
              [37.6591796875, -3.070019531250011],
              [37.676855468750006, -3.178417968750011],
              [37.68798828125, -3.246191406250006],
              [37.68183593750001, -3.305761718750006],
              [37.62539062500002, -3.4072265625],
              [37.60869140625002, -3.460253906250003],
              [37.60820312500002, -3.4970703125],
              [37.6220703125, -3.511523437500003],
              [37.67011718750001, -3.516796875000011],
              [37.711035156250006, -3.540820312500003],
              [37.726171875, -3.559765625000011],
              [37.757421875, -3.636132812500009],
              [37.79726562500002, -3.674414062500006],
              [37.887304687500006, -3.7392578125],
              [38.04082031250002, -3.849804687500011],
              [38.1943359375, -3.960351562500009],
              [38.34785156250001, -4.070898437500006],
              [38.50136718750002, -4.181445312500003],
              [38.654882812500006, -4.291894531250009],
              [38.80839843750002, -4.402441406250006],
              [38.9619140625, -4.512988281250003],
              [39.11542968750001, -4.62353515625],
              [39.19013671875001, -4.67724609375],
              [39.221777343750006, -4.6923828125],
              [39.20185546875001, -4.776464843750006],
              [39.12324218750001, -4.98046875],
              [39.11875, -5.0654296875],
              [39.087988281250006, -5.165429687500009],
              [39.05830078125001, -5.231542968750006],
              [38.97822265625001, -5.5185546875],
              [38.91103515625002, -5.6259765625],
              [38.81923828125002, -5.877636718750011],
              [38.8046875, -6.070117187500003],
              [38.85527343750002, -6.204882812500003],
              [38.8740234375, -6.33125],
              [38.9814453125, -6.455078125],
              [39.0673828125, -6.499316406250003],
              [39.12548828125, -6.555957031250003],
              [39.22841796875002, -6.685253906250011],
              [39.28730468750001, -6.81494140625],
              [39.47236328125001, -6.878613281250011],
              [39.54609375000001, -7.024023437500006],
              [39.51923828125001, -7.124121093750006],
              [39.43339843750002, -7.20703125],
              [39.353125, -7.34140625],
              [39.28847656250002, -7.517871093750003],
              [39.28701171875002, -7.787695312500006],
              [39.33046875000002, -7.746679687500006],
              [39.42841796875001, -7.812792968750003],
              [39.441015625, -8.011523437500003],
              [39.34003906250001, -8.242871093750011],
              [39.30898437500002, -8.350976562500009],
              [39.30400390625002, -8.44384765625],
              [39.37734375000002, -8.720800781250006],
              [39.488378906250006, -8.86181640625],
              [39.480078125, -8.905957031250011],
              [39.451269531250006, -8.94296875],
              [39.64130859375001, -9.192480468750006],
              [39.62548828125, -9.409472656250003],
              [39.69667968750002, -9.578417968750003],
              [39.72792968750002, -9.724804687500011],
              [39.77480468750002, -9.837109375000011],
              [39.783789062500006, -9.91455078125],
              [39.725195312500006, -10.00048828125],
              [39.86376953125, -10.02197265625],
              [39.94521484375002, -10.09228515625],
              [39.98359375000001, -10.159570312500009],
              [40.08369140625001, -10.156640625],
              [40.13789062500001, -10.20263671875],
              [40.21601562500001, -10.240625],
              [40.388769531250006, -10.353515625],
              [40.435546875, -10.410253906250006],
              [40.45253906250002, -10.44296875],
              [40.46357421875001, -10.46435546875],
              [40.34746093750002, -10.5515625],
              [40.16621093750001, -10.6875],
              [39.98867187500002, -10.82080078125],
              [39.81708984375001, -10.912402343750003],
              [39.694433593750006, -10.954785156250011],
              [39.5634765625, -10.978515625],
              [39.43916015625001, -11.034570312500009],
              [39.32158203125002, -11.12255859375],
              [39.170996093750006, -11.166894531250009],
              [38.9875, -11.167285156250003],
              [38.79472656250002, -11.22890625],
              [38.60332031250002, -11.3453125],
              [38.491796875, -11.413281250000011],
              [38.31513671875001, -11.311132812500006],
              [38.17656250000002, -11.278710937500009],
              [38.01728515625001, -11.282128906250009],
              [37.92021484375002, -11.294726562500003],
              [37.885351562500006, -11.316699218750003],
              [37.855078125, -11.379101562500011],
              [37.82929687500001, -11.48193359375],
              [37.72480468750001, -11.580664062500006],
              [37.54169921875001, -11.675097656250003],
              [37.37285156250002, -11.71044921875],
              [37.218359375, -11.6865234375],
              [37.113867187500006, -11.647167968750011],
              [37.059179687500006, -11.5921875],
              [36.97890625000002, -11.566992187500006],
              [36.87265625, -11.5712890625],
              [36.77109375, -11.6103515625],
              [36.673828125, -11.684277343750011],
              [36.518652343750006, -11.716210937500009],
              [36.3056640625, -11.706347656250003],
              [36.19130859375002, -11.670703125],
              [36.17548828125001, -11.609277343750009],
              [36.08222656250001, -11.537304687500011],
              [35.91132812500001, -11.4546875],
              [35.78544921875002, -11.452929687500003],
              [35.7046875, -11.532128906250009],
              [35.630957031250006, -11.58203125],
              [35.56435546875002, -11.60234375],
              [35.50439453125, -11.604785156250003],
              [35.45136718750001, -11.589550781250011],
              [35.41826171875002, -11.583203125000011],
              [35.1826171875, -11.574804687500006],
              [34.95947265625, -11.578125],
              [34.95263671875, -11.54375],
              [34.93701171875, -11.463476562500006],
              [34.890625, -11.3935546875],
              [34.8505859375, -11.351953125],
              [34.800878906250006, -11.340917968750006],
              [34.77382812500002, -11.341699218750009],
              [34.75214843750001, -11.309472656250009],
              [34.72646484375002, -11.238183593750009],
              [34.6884765625, -11.177441406250011],
              [34.63808593750002, -11.127148437500011],
              [34.60791015625, -11.08046875],
              [34.59765625, -11.0375],
              [34.60566406250001, -10.990234375],
              [34.65234375, -10.872851562500003],
              [34.667089843750006, -10.79248046875],
              [34.66181640625001, -10.710058593750006],
              [34.63652343750002, -10.625585937500006],
              [34.58359375, -10.525097656250011],
              [34.58955078125001, -10.496191406250006],
              [34.57158203125002, -10.427636718750009],
              [34.56972656250002, -10.3796875],
              [34.57998046875002, -10.31982421875],
              [34.569921875, -10.241113281250009],
              [34.52421875000002, -10.073144531250009],
              [34.52421875000002, -10.030175781250009],
              [34.47597656250002, -9.948828125],
              [34.32783203125001, -9.756542968750011],
              [34.320898437500006, -9.731542968750006],
              [34.08857421875001, -9.537792968750011],
              [33.99560546875, -9.495410156250003],
              [33.96210937500001, -9.53173828125],
              [33.94960937500002, -9.565332031250009],
              [33.959375, -9.62734375],
              [33.953710937500006, -9.658203125],
              [33.943945312500006, -9.672167968750003],
              [33.88886718750001, -9.670117187500011],
              [33.85419921875001, -9.662988281250009],
              [33.766210937500006, -9.6109375],
              [33.69765625000002, -9.59814453125],
              [33.52753906250001, -9.607519531250006],
              [33.4677734375, -9.619726562500006],
              [33.4208984375, -9.608007812500006],
              [33.33085937500002, -9.519140625],
              [33.22529296875001, -9.50048828125],
              [33.13046875, -9.495898437500003],
              [32.97402343750002, -9.39501953125],
              [32.93730468750002, -9.399707031250003],
              [32.919921875, -9.407421875000011],
            ],
          ],
          [
            [
              [39.71132812500002, -7.977441406250009],
              [39.6572265625, -7.990527343750003],
              [39.63613281250002, -7.977832031250003],
              [39.60292968750002, -7.936132812500006],
              [39.66064453125, -7.900585937500011],
              [39.71660156250002, -7.83154296875],
              [39.84658203125002, -7.730273437500003],
              [39.89091796875002, -7.663476562500009],
              [39.90712890625002, -7.64921875],
              [39.89775390625002, -7.728125],
              [39.82441406250001, -7.900683593750003],
              [39.761816406250006, -7.911914062500003],
              [39.71132812500002, -7.977441406250009],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 4,
        labelrank: 2,
        sovereignt: "Kenya",
        sov_a3: "KEN",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Kenya",
        adm0_a3: "KEN",
        geou_dif: 0,
        geounit: "Kenya",
        gu_a3: "KEN",
        su_dif: 0,
        subunit: "Kenya",
        su_a3: "KEN",
        brk_diff: 0,
        name: "Kenya",
        name_long: "Kenya",
        brk_a3: "KEN",
        brk_name: "Kenya",
        brk_group: null,
        abbrev: "Ken.",
        postal: "KE",
        formal_en: "Republic of Kenya",
        formal_fr: null,
        name_ciawf: "Kenya",
        note_adm0: null,
        note_brk: null,
        name_sort: "Kenya",
        name_alt: null,
        mapcolor7: 5,
        mapcolor8: 2,
        mapcolor9: 7,
        mapcolor13: 3,
        pop_est: 52573973,
        pop_rank: 16,
        pop_year: 2019,
        gdp_md: 95503,
        gdp_year: 2019,
        economy: "5. Emerging region: G20",
        income_grp: "5. Low income",
        fips_10: "KE",
        iso_a2: "KE",
        iso_a2_eh: "KE",
        iso_a3: "KEN",
        iso_a3_eh: "KEN",
        iso_n3: "404",
        iso_n3_eh: "404",
        un_a3: "404",
        wb_a2: "KE",
        wb_a3: "KEN",
        woe_id: 23424863,
        woe_id_eh: 23424863,
        woe_note: "Exact WOE match as country",
        adm0_iso: "KEN",
        adm0_diff: null,
        adm0_tlc: "KEN",
        adm0_a3_us: "KEN",
        adm0_a3_fr: "KEN",
        adm0_a3_ru: "KEN",
        adm0_a3_es: "KEN",
        adm0_a3_cn: "KEN",
        adm0_a3_tw: "KEN",
        adm0_a3_in: "KEN",
        adm0_a3_np: "KEN",
        adm0_a3_pk: "KEN",
        adm0_a3_de: "KEN",
        adm0_a3_gb: "KEN",
        adm0_a3_br: "KEN",
        adm0_a3_il: "KEN",
        adm0_a3_ps: "KEN",
        adm0_a3_sa: "KEN",
        adm0_a3_eg: "KEN",
        adm0_a3_ma: "KEN",
        adm0_a3_pt: "KEN",
        adm0_a3_ar: "KEN",
        adm0_a3_jp: "KEN",
        adm0_a3_ko: "KEN",
        adm0_a3_vn: "KEN",
        adm0_a3_tr: "KEN",
        adm0_a3_id: "KEN",
        adm0_a3_pl: "KEN",
        adm0_a3_gr: "KEN",
        adm0_a3_it: "KEN",
        adm0_a3_nl: "KEN",
        adm0_a3_se: "KEN",
        adm0_a3_bd: "KEN",
        adm0_a3_ua: "KEN",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Eastern Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 5,
        long_len: 5,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 1.7,
        max_label: 6.7,
        label_x: 37.907632,
        label_y: 0.549043,
        ne_id: 1159320971,
        wikidataid: "Q114",
        name_ar: "كينيا",
        name_bn: "কেনিয়া",
        name_de: "Kenia",
        name_en: "Kenya",
        name_es: "Kenia",
        name_fa: "کنیا",
        name_fr: "Kenya",
        name_el: "Κένυα",
        name_he: "קניה",
        name_hi: "कीनिया",
        name_hu: "Kenya",
        name_id: "Kenya",
        name_it: "Kenya",
        name_ja: "ケニア",
        name_ko: "케냐",
        name_nl: "Kenia",
        name_pl: "Kenia",
        name_pt: "Quénia",
        name_ru: "Кения",
        name_sv: "Kenya",
        name_tr: "Kenya",
        name_uk: "Кенія",
        name_ur: "کینیا",
        name_vi: "Kenya",
        name_zh: "肯尼亚",
        name_zht: "肯亞",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "KEN.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [40.99443359375002, -2.158398437500011],
              [40.95732421875002, -2.167285156250003],
              [40.97646484375002, -2.109765625],
              [41.086035156250006, -2.036523437500009],
              [41.13066406250002, -2.053027343750003],
              [41.139257812500006, -2.06982421875],
              [41.136816406250006, -2.085058593750006],
              [41.1181640625, -2.10009765625],
              [40.99443359375002, -2.158398437500011],
            ],
          ],
          [
            [
              [33.90322265625002, -1.002050781250006],
              [33.9, -0.831640625],
              [33.924414062500006, -0.397851562500009],
              [33.921484375, -0.016992187500009],
              [33.94316406250002, 0.173779296874997],
              [34.037207031250006, 0.29453125],
              [34.08056640625, 0.382470703124994],
              [34.11171875000002, 0.505126953125],
              [34.16093750000002, 0.605175781249997],
              [34.272558593750006, 0.686425781249994],
              [34.292578125, 0.73125],
              [34.41083984375001, 0.867285156249991],
              [34.48173828125002, 1.042138671874994],
              [34.535253906250006, 1.1015625],
              [34.60195312500002, 1.156445312499997],
              [34.64912109375001, 1.185302734375],
              [34.72675781250001, 1.214257812499994],
              [34.78759765625, 1.230712890625],
              [34.79863281250002, 1.24453125],
              [34.803808593750006, 1.272851562499994],
              [34.78359375000002, 1.381152343749989],
              [34.8095703125, 1.416699218749997],
              [34.85097656250002, 1.489013671875],
              [34.89833984375002, 1.556494140624991],
              [34.94121093750002, 1.599267578124994],
              [34.96523437500002, 1.643359374999989],
              [34.97646484375002, 1.719628906249994],
              [34.97822265625001, 1.773632812499997],
              [34.9775390625, 1.861914062499991],
              [34.96406250000001, 2.062402343749994],
              [34.91396484375002, 2.230175781249997],
              [34.88300781250001, 2.417919921874997],
              [34.90576171875, 2.4796875],
              [34.8662109375, 2.589697265624991],
              [34.8466796875, 2.595751953124989],
              [34.814453125, 2.619824218749997],
              [34.7734375, 2.723437499999989],
              [34.74248046875002, 2.818115234375],
              [34.723242187500006, 2.841943359374994],
              [34.58916015625002, 2.924755859374997],
              [34.522558593750006, 3.119970703124991],
              [34.447851562500006, 3.163476562499994],
              [34.4072265625, 3.357519531249991],
              [34.3994140625, 3.412695312499991],
              [34.44179687500002, 3.60625],
              [34.43769531250001, 3.650585937499997],
              [34.39287109375002, 3.691503906249991],
              [34.26708984375, 3.733154296875],
              [34.1650390625, 3.81298828125],
              [34.17822265625, 3.840869140624989],
              [34.18574218750001, 3.869775390624994],
              [34.13203125000001, 3.88916015625],
              [33.97607421875, 4.22021484375],
              [34.176855468750006, 4.419091796874994],
              [34.38017578125002, 4.620654296874989],
              [34.63984375000001, 4.87548828125],
              [34.87832031250002, 5.109570312499997],
              [35.08447265625, 5.311865234374991],
              [35.26835937500002, 5.492285156249991],
              [35.26386718750001, 5.457910156249994],
              [35.2646484375, 5.412060546874997],
              [35.28759765625, 5.384082031249989],
              [35.325292968750006, 5.364892578124994],
              [35.3779296875, 5.38515625],
              [35.42402343750001, 5.41328125],
              [35.46865234375002, 5.419091796874994],
              [35.74501953125002, 5.343994140625],
              [35.79140625000002, 5.278564453125],
              [35.78847656250002, 5.208105468749991],
              [35.80029296875, 5.156933593749997],
              [35.779296875, 5.105566406249991],
              [35.75615234375002, 4.950488281249989],
              [35.76308593750002, 4.808007812499994],
              [35.84560546875002, 4.70263671875],
              [35.91982421875002, 4.619824218749997],
              [35.97871093750001, 4.503808593749994],
              [36.02197265625, 4.468115234374991],
              [36.08193359375002, 4.44970703125],
              [36.271875, 4.444726562499994],
              [36.55302734375002, 4.437255859375],
              [36.82363281250002, 4.430126953124997],
              [36.848242187500006, 4.42734375],
              [36.90556640625002, 4.411474609374991],
              [37.15458984375002, 4.254541015624994],
              [37.38251953125001, 4.11083984375],
              [37.57548828125002, 3.9859375],
              [37.76289062500001, 3.864648437499994],
              [37.944921875, 3.746728515624994],
              [38.08613281250001, 3.648828125],
              [38.22529296875001, 3.618994140624991],
              [38.45156250000002, 3.604833984374991],
              [38.608007812500006, 3.60009765625],
              [38.75273437500002, 3.558984375],
              [38.9677734375, 3.520605468749991],
              [39.12832031250002, 3.500878906249994],
              [39.22548828125002, 3.478759765625],
              [39.49443359375002, 3.456103515624989],
              [39.53886718750002, 3.469189453124997],
              [39.65751953125002, 3.577832031249997],
              [39.79033203125002, 3.754248046874991],
              [39.84218750000002, 3.851464843749994],
              [40.01416015625, 3.947949218749997],
              [40.316015625, 4.082714843749997],
              [40.52871093750002, 4.177636718749994],
              [40.765234375, 4.273046875],
              [40.87265625, 4.190332031249994],
              [41.02080078125002, 4.057470703124991],
              [41.08720703125002, 3.991943359375],
              [41.14042968750002, 3.962988281249991],
              [41.22089843750001, 3.943554687499997],
              [41.318945312500006, 3.943066406249997],
              [41.37246093750002, 3.946191406249994],
              [41.48193359375, 3.96328125],
              [41.73769531250002, 3.979052734374989],
              [41.88398437500001, 3.977734375],
              [41.76093750000001, 3.801611328124991],
              [41.61347656250001, 3.590478515624994],
              [41.341796875, 3.20166015625],
              [41.13496093750001, 2.9970703125],
              [40.97871093750001, 2.842431640624994],
              [40.964453125, 2.814648437499997],
              [40.96503906250001, 2.642333984375],
              [40.96669921875002, 2.220947265625],
              [40.97001953125002, 1.378173828125],
              [40.973242187500006, 0.535400390625],
              [40.9765625, -0.307324218750011],
              [40.97822265625001, -0.728710937500011],
              [40.97871093750001, -0.870312500000011],
              [41.115820312500006, -1.047460937500006],
              [41.24980468750002, -1.220507812500003],
              [41.42695312500001, -1.449511718750003],
              [41.521875, -1.572265625],
              [41.53759765625, -1.613183593750009],
              [41.53271484375, -1.6953125],
              [41.38691406250001, -1.866992187500003],
              [41.26748046875002, -1.945019531250011],
              [41.10683593750002, -1.982324218750009],
              [41.058691406250006, -1.975195312500006],
              [40.99550781250002, -1.950585937500009],
              [40.970703125, -1.991796875],
              [40.9521484375, -2.055957031250003],
              [40.916601562500006, -2.04248046875],
              [40.889746093750006, -2.023535156250006],
              [40.905859375, -2.1375],
              [40.92236328125, -2.19375],
              [40.89824218750002, -2.269921875],
              [40.82011718750002, -2.336328125],
              [40.81318359375001, -2.392382812500003],
              [40.644140625, -2.539453125],
              [40.40449218750001, -2.5556640625],
              [40.27851562500001, -2.628613281250011],
              [40.22246093750002, -2.688378906250009],
              [40.179785156250006, -2.819042968750011],
              [40.19472656250002, -3.019238281250011],
              [40.128125, -3.17333984375],
              [40.11542968750001, -3.250585937500006],
              [39.99169921875, -3.350683593750006],
              [39.93681640625002, -3.442480468750006],
              [39.89628906250002, -3.535839843750011],
              [39.8609375, -3.576757812500006],
              [39.81914062500002, -3.786035156250009],
              [39.76142578125001, -3.9130859375],
              [39.74580078125001, -3.955175781250006],
              [39.73164062500001, -3.993261718750006],
              [39.68691406250002, -4.06787109375],
              [39.65800781250002, -4.119140625],
              [39.63710937500002, -4.15283203125],
              [39.49091796875001, -4.478417968750009],
              [39.376953125, -4.62548828125],
              [39.2875, -4.608593750000011],
              [39.228125, -4.66552734375],
              [39.221777343750006, -4.6923828125],
              [39.19013671875001, -4.67724609375],
              [39.11542968750001, -4.62353515625],
              [38.9619140625, -4.512988281250003],
              [38.80839843750002, -4.402441406250006],
              [38.654882812500006, -4.291894531250009],
              [38.50136718750002, -4.181445312500003],
              [38.34785156250001, -4.070898437500006],
              [38.1943359375, -3.960351562500009],
              [38.04082031250002, -3.849804687500011],
              [37.887304687500006, -3.7392578125],
              [37.79726562500002, -3.674414062500006],
              [37.757421875, -3.636132812500009],
              [37.726171875, -3.559765625000011],
              [37.711035156250006, -3.540820312500003],
              [37.67011718750001, -3.516796875000011],
              [37.6220703125, -3.511523437500003],
              [37.60820312500002, -3.4970703125],
              [37.60869140625002, -3.460253906250003],
              [37.62539062500002, -3.4072265625],
              [37.68183593750001, -3.305761718750006],
              [37.68798828125, -3.246191406250006],
              [37.676855468750006, -3.178417968750011],
              [37.6591796875, -3.070019531250011],
              [37.64384765625002, -3.04541015625],
              [37.54218750000001, -2.988574218750003],
              [37.32900390625002, -2.86962890625],
              [37.115820312500006, -2.750585937500006],
              [36.90263671875002, -2.631640625],
              [36.689453125, -2.512597656250009],
              [36.47636718750002, -2.3935546875],
              [36.26308593750002, -2.274609375000011],
              [36.05, -2.155664062500009],
              [35.8369140625, -2.03662109375],
              [35.62373046875001, -1.917578125],
              [35.41054687500002, -1.798632812500003],
              [35.19746093750001, -1.679589843750009],
              [34.98427734375002, -1.560546875],
              [34.77109375, -1.441601562500011],
              [34.55791015625002, -1.322558593750003],
              [34.3447265625, -1.20361328125],
              [34.13164062500002, -1.084570312500006],
              [34.05156250000002, -1.03984375],
              [33.97939453125002, -1.002050781250006],
              [33.90322265625002, -1.002050781250006],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 5,
        sovereignt: "Somaliland",
        sov_a3: "SOL",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Somaliland",
        adm0_a3: "SOL",
        geou_dif: 0,
        geounit: "Somaliland",
        gu_a3: "SOL",
        su_dif: 0,
        subunit: "Somaliland",
        su_a3: "SOL",
        brk_diff: 0,
        name: "Somaliland",
        name_long: "Somaliland",
        brk_a3: "SOL",
        brk_name: "Somaliland",
        brk_group: null,
        abbrev: "Solnd.",
        postal: "SL",
        formal_en: "Republic of Somaliland",
        formal_fr: null,
        name_ciawf: null,
        note_adm0: "Disputed",
        note_brk: "Self admin.; Claimed by Somalia",
        name_sort: "Somaliland",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 6,
        mapcolor9: 5,
        mapcolor13: 2,
        pop_est: 5096159,
        pop_rank: 13,
        pop_year: 2014,
        gdp_md: 17836,
        gdp_year: 2013,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        fips_10: "-99",
        iso_a2: "-99",
        iso_a2_eh: "-99",
        iso_a3: "-99",
        iso_a3_eh: "-99",
        iso_n3: "-99",
        iso_n3_eh: "-99",
        un_a3: "-099",
        wb_a2: "-99",
        wb_a3: "-99",
        woe_id: -99,
        woe_id_eh: -99,
        woe_note:
          "Includes old states of 2347021, 2347020, 2347017 and portion of 2347016.",
        adm0_iso: "SOM",
        adm0_diff: "1",
        adm0_tlc: "SOL",
        adm0_a3_us: "SOM",
        adm0_a3_fr: "SOM",
        adm0_a3_ru: "SOM",
        adm0_a3_es: "SOM",
        adm0_a3_cn: "SOM",
        adm0_a3_tw: "SOL",
        adm0_a3_in: "SOM",
        adm0_a3_np: "SOM",
        adm0_a3_pk: "SOM",
        adm0_a3_de: "SOM",
        adm0_a3_gb: "SOM",
        adm0_a3_br: "SOM",
        adm0_a3_il: "SOM",
        adm0_a3_ps: "SOM",
        adm0_a3_sa: "SOM",
        adm0_a3_eg: "SOM",
        adm0_a3_ma: "SOM",
        adm0_a3_pt: "SOM",
        adm0_a3_ar: "SOM",
        adm0_a3_jp: "SOM",
        adm0_a3_ko: "SOM",
        adm0_a3_vn: "SOM",
        adm0_a3_tr: "SOM",
        adm0_a3_id: "SOM",
        adm0_a3_pl: "SOM",
        adm0_a3_gr: "SOM",
        adm0_a3_it: "SOM",
        adm0_a3_nl: "SOM",
        adm0_a3_se: "SOM",
        adm0_a3_bd: "SOM",
        adm0_a3_ua: "SOM",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Eastern Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 10,
        long_len: 10,
        abbrev_len: 6,
        tiny: -99,
        homepart: 1,
        min_zoom: 4,
        min_label: 4.5,
        max_label: 9,
        label_x: 46.731595,
        label_y: 9.443889,
        ne_id: 1159321259,
        wikidataid: "Q34754",
        name_ar: "صوماليلاند",
        name_bn: "সোমালিল্যান্ড",
        name_de: "Somaliland",
        name_en: "Somaliland",
        name_es: "Somalilandia",
        name_fa: "سومالیلند",
        name_fr: "Somaliland",
        name_el: "Σομαλιλάνδη",
        name_he: "סומלילנד",
        name_hi: "सोमालीदेश",
        name_hu: "Szomáliföld",
        name_id: "Somaliland",
        name_it: "Somaliland",
        name_ja: "ソマリランド",
        name_ko: "소말릴란드",
        name_nl: "Somaliland",
        name_pl: "Somaliland",
        name_pt: "Somalilândia",
        name_ru: "Сомалиленд",
        name_sv: "Somaliland",
        name_tr: "Somaliland",
        name_uk: "Сомаліленд",
        name_ur: "صومالی لینڈ",
        name_vi: "Somaliland",
        name_zh: "索马里兰",
        name_zht: "索馬利蘭",
        fclass_iso: "Unrecognized",
        tlc_diff: "1",
        fclass_tlc: "Admin-0 country",
        fclass_us: "Unrecognized",
        fclass_fr: "Unrecognized",
        fclass_ru: "Unrecognized",
        fclass_es: "Unrecognized",
        fclass_cn: "Unrecognized",
        fclass_tw: "Admin-0 country",
        fclass_in: "Unrecognized",
        fclass_np: "Unrecognized",
        fclass_pk: "Unrecognized",
        fclass_de: "Unrecognized",
        fclass_gb: "Unrecognized",
        fclass_br: "Unrecognized",
        fclass_il: "Unrecognized",
        fclass_ps: "Unrecognized",
        fclass_sa: "Unrecognized",
        fclass_eg: "Unrecognized",
        fclass_ma: "Unrecognized",
        fclass_pt: "Unrecognized",
        fclass_ar: "Unrecognized",
        fclass_jp: "Unrecognized",
        fclass_ko: "Unrecognized",
        fclass_vn: "Unrecognized",
        fclass_tr: "Unrecognized",
        fclass_id: "Unrecognized",
        fclass_pl: "Unrecognized",
        fclass_gr: "Unrecognized",
        fclass_it: "Unrecognized",
        fclass_nl: "Unrecognized",
        fclass_se: "Unrecognized",
        fclass_bd: "Unrecognized",
        fclass_ua: "Unrecognized",
        filename: "SOL.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [48.938574218750006, 11.258447265624994],
            [48.9384765625, 10.982324218749994],
            [48.9384765625, 10.714208984374991],
            [48.93837890625002, 10.433251953124994],
            [48.93828125000002, 9.973486328124991],
            [48.93828125000002, 9.8076171875],
            [48.938085937500006, 9.564111328124994],
            [48.938085937500006, 9.451757812499991],
            [48.793554687500006, 9.232714843749989],
            [48.61660156250002, 8.964599609375],
            [48.42861328125002, 8.679589843749994],
            [48.27275390625002, 8.443359375],
            [48.12675781250002, 8.22216796875],
            [47.97822265625001, 7.9970703125],
            [47.6376953125, 7.9970703125],
            [47.3056640625, 7.9970703125],
            [46.97822265625001, 7.9970703125],
            [46.91953125, 8.026123046875],
            [46.64472656250001, 8.1181640625],
            [46.295996093750006, 8.234960937499991],
            [45.86328125, 8.3798828125],
            [45.55546875000002, 8.483007812499991],
            [45.22695312500002, 8.5908203125],
            [44.8935546875, 8.7001953125],
            [44.63203125000001, 8.786083984374997],
            [44.30625, 8.89306640625],
            [44.02285156250002, 8.986035156249997],
            [43.98378906250002, 9.008837890624989],
            [43.826757812500006, 9.15078125],
            [43.62050781250002, 9.33740234375],
            [43.5810546875, 9.340722656249994],
            [43.482519531250006, 9.379492187499991],
            [43.39433593750002, 9.480273437499989],
            [43.303125, 9.609082031249997],
            [43.21845703125001, 9.770166015624994],
            [43.181640625, 9.879980468749991],
            [43.068945312500006, 9.926220703124997],
            [43.014746093750006, 10.012597656249994],
            [42.9125, 10.140820312499997],
            [42.84160156250002, 10.203076171874997],
            [42.81640625, 10.257373046874989],
            [42.78369140625, 10.36962890625],
            [42.725195312500006, 10.491748046874989],
            [42.66923828125002, 10.567578125],
            [42.65644531250001, 10.6],
            [42.65957031250002, 10.621386718749989],
            [42.76308593750002, 10.786914062499989],
            [42.80976562500001, 10.845996093749989],
            [42.862890625, 10.903222656249994],
            [42.90615234375002, 10.960253906249989],
            [42.92275390625002, 10.999316406249989],
            [43.04863281250002, 11.1943359375],
            [43.159375, 11.36572265625],
            [43.24599609375002, 11.499804687499989],
            [43.44121093750002, 11.346435546875],
            [43.63115234375002, 11.035449218749989],
            [43.85273437500001, 10.784277343749991],
            [44.158203125, 10.55078125],
            [44.279296875, 10.471875],
            [44.38652343750002, 10.430224609374989],
            [44.94296875, 10.43671875],
            [45.33769531250002, 10.649755859374991],
            [45.695898437500006, 10.80390625],
            [45.81669921875002, 10.835888671874997],
            [46.024511718750006, 10.793701171875],
            [46.25390625, 10.781103515624991],
            [46.46025390625002, 10.734179687499989],
            [46.565039062500006, 10.745996093749994],
            [46.97343750000002, 10.925390625],
            [47.230078125, 11.099902343749989],
            [47.40498046875001, 11.174023437499997],
            [47.47382812500001, 11.1748046875],
            [47.7125, 11.112011718749997],
            [48.01923828125001, 11.139355468749997],
            [48.43886718750002, 11.290136718749991],
            [48.57255859375002, 11.320507812499997],
            [48.674414062500006, 11.32265625],
            [48.903125, 11.2548828125],
            [48.938574218750006, 11.258447265624994],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Morocco",
        sov_a3: "MAR",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Morocco",
        adm0_a3: "MAR",
        geou_dif: 0,
        geounit: "Morocco",
        gu_a3: "MAR",
        su_dif: 0,
        subunit: "Morocco",
        su_a3: "MAR",
        brk_diff: 0,
        name: "Morocco",
        name_long: "Morocco",
        brk_a3: "MAR",
        brk_name: "Morocco",
        brk_group: null,
        abbrev: "Mor.",
        postal: "MA",
        formal_en: "Kingdom of Morocco",
        formal_fr: null,
        name_ciawf: "Morocco",
        note_adm0: null,
        note_brk: null,
        name_sort: "Morocco",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 2,
        mapcolor9: 3,
        mapcolor13: 9,
        pop_est: 36471769,
        pop_rank: 15,
        pop_year: 2019,
        gdp_md: 119700,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        fips_10: "MO",
        iso_a2: "MA",
        iso_a2_eh: "MA",
        iso_a3: "MAR",
        iso_a3_eh: "MAR",
        iso_n3: "504",
        iso_n3_eh: "504",
        un_a3: "504",
        wb_a2: "MA",
        wb_a3: "MAR",
        woe_id: 23424893,
        woe_id_eh: 23424893,
        woe_note: "Exact WOE match as country",
        adm0_iso: "MAR",
        adm0_diff: null,
        adm0_tlc: "MAR",
        adm0_a3_us: "MAR",
        adm0_a3_fr: "MAR",
        adm0_a3_ru: "MAR",
        adm0_a3_es: "MAR",
        adm0_a3_cn: "MAR",
        adm0_a3_tw: "MAR",
        adm0_a3_in: "MAR",
        adm0_a3_np: "MAR",
        adm0_a3_pk: "MAR",
        adm0_a3_de: "MAR",
        adm0_a3_gb: "MAR",
        adm0_a3_br: "MAR",
        adm0_a3_il: "MAR",
        adm0_a3_ps: "MAR",
        adm0_a3_sa: "MAR",
        adm0_a3_eg: "MAR",
        adm0_a3_ma: "MAR",
        adm0_a3_pt: "MAR",
        adm0_a3_ar: "MAR",
        adm0_a3_jp: "MAR",
        adm0_a3_ko: "MAR",
        adm0_a3_vn: "MAR",
        adm0_a3_tr: "MAR",
        adm0_a3_id: "MAR",
        adm0_a3_pl: "MAR",
        adm0_a3_gr: "MAR",
        adm0_a3_it: "MAR",
        adm0_a3_nl: "MAR",
        adm0_a3_se: "MAR",
        adm0_a3_bd: "MAR",
        adm0_a3_ua: "MAR",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Northern Africa",
        region_wb: "Middle East & North Africa",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 2.7,
        max_label: 8,
        label_x: -7.187296,
        label_y: 31.650723,
        ne_id: 1159321035,
        wikidataid: "Q1028",
        name_ar: "المغرب",
        name_bn: "মরক্কো",
        name_de: "Marokko",
        name_en: "Morocco",
        name_es: "Marruecos",
        name_fa: "مراکش",
        name_fr: "Maroc",
        name_el: "Μαρόκο",
        name_he: "מרוקו",
        name_hi: "मोरक्को",
        name_hu: "Marokkó",
        name_id: "Maroko",
        name_it: "Marocco",
        name_ja: "モロッコ",
        name_ko: "모로코",
        name_nl: "Marokko",
        name_pl: "Maroko",
        name_pt: "Marrocos",
        name_ru: "Марокко",
        name_sv: "Marocko",
        name_tr: "Fas",
        name_uk: "Марокко",
        name_ur: "مراکش",
        name_vi: "Maroc",
        name_zh: "摩洛哥",
        name_zht: "摩洛哥",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "MAR.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.219628906249994, 35.10419921875],
            [-2.190771484374977, 35.02978515625],
            [-2.131787109374983, 34.970849609374994],
            [-1.9208984375, 34.835546875],
            [-1.795605468749983, 34.751904296875],
            [-1.792187499999983, 34.723193359374996],
            [-1.832421875, 34.654638671875],
            [-1.849658203124989, 34.607324218749994],
            [-1.816601562499983, 34.557080078125],
            [-1.739453124999983, 34.49609375],
            [-1.733300781249994, 34.467041015625],
            [-1.751855468749994, 34.433251953124994],
            [-1.791796874999989, 34.367919921875],
            [-1.706933593749994, 34.176074218749996],
            [-1.692675781249989, 33.990283203124996],
            [-1.714697265624977, 33.858203125],
            [-1.714111328125, 33.7818359375],
            [-1.702978515624977, 33.716845703124996],
            [-1.63125, 33.566748046875],
            [-1.67919921875, 33.318652343749996],
            [-1.625097656249977, 33.183349609375],
            [-1.550732421874983, 33.073583984375],
            [-1.510009765625, 32.87763671875],
            [-1.45, 32.784814453124994],
            [-1.352148437499977, 32.703369140625],
            [-1.29638671875, 32.675683593749994],
            [-1.188232421875, 32.60849609375],
            [-1.111035156249983, 32.552294921874996],
            [-1.065527343749977, 32.468310546874996],
            [-1.16259765625, 32.399169921875],
            [-1.240332031249977, 32.33759765625],
            [-1.262109375, 32.271142578124994],
            [-1.225927734374977, 32.16455078125],
            [-1.225927734374977, 32.107226562499996],
            [-1.275341796874983, 32.089013671874994],
            [-1.47705078125, 32.094873046874994],
            [-1.63515625, 32.099560546875],
            [-1.816992187499977, 32.104785156249996],
            [-2.072802734374989, 32.115039062499996],
            [-2.23125, 32.121337890625],
            [-2.448388671874994, 32.12998046875],
            [-2.523242187499989, 32.12568359375],
            [-2.722607421874983, 32.095751953124996],
            [-2.863427734374994, 32.07470703125],
            [-2.88720703125, 32.06884765625],
            [-2.930859374999983, 32.042529296874996],
            [-2.961132812499983, 31.96396484375],
            [-2.988232421874983, 31.87421875],
            [-3.017382812499989, 31.834277343749996],
            [-3.439794921874977, 31.704541015624997],
            [-3.604589843749977, 31.686767578125],
            [-3.700244140624989, 31.700097656249994],
            [-3.768164062499977, 31.68955078125],
            [-3.826757812499977, 31.661914062499996],
            [-3.8466796875, 31.619873046875],
            [-3.849560546874983, 31.56640625],
            [-3.837109374999983, 31.512353515624994],
            [-3.796435546874989, 31.437109375],
            [-3.789160156249977, 31.36181640625],
            [-3.815136718749983, 31.308837890625],
            [-3.821386718749977, 31.25546875],
            [-3.833398437499994, 31.197802734374996],
            [-3.811816406249989, 31.1666015625],
            [-3.77099609375, 31.161816406249997],
            [-3.730175781249983, 31.135400390624994],
            [-3.672509765624994, 31.111376953124996],
            [-3.62451171875, 31.065771484375],
            [-3.626904296874983, 31.000927734374997],
            [-3.666796874999989, 30.964013671874994],
            [-3.702001953124977, 30.944482421874994],
            [-3.860058593749983, 30.92724609375],
            [-3.9853515625, 30.913525390624997],
            [-4.148779296874977, 30.8095703125],
            [-4.322851562499977, 30.698876953124994],
            [-4.529150390624977, 30.625537109374996],
            [-4.61962890625, 30.604785156249996],
            [-4.778515624999983, 30.552392578124994],
            [-4.96826171875, 30.465380859374996],
            [-5.061914062499994, 30.326416015625],
            [-5.180126953124983, 30.166162109374994],
            [-5.293652343749983, 30.058642578124996],
            [-5.448779296874989, 29.956933593749994],
            [-5.593310546874989, 29.91796875],
            [-5.775, 29.869042968749994],
            [-6.004296875, 29.83125],
            [-6.16650390625, 29.8189453125],
            [-6.214794921874983, 29.810693359374994],
            [-6.357617187499983, 29.808300781249997],
            [-6.427636718749994, 29.816113281249997],
            [-6.479736328125, 29.820361328124996],
            [-6.500878906249994, 29.809130859374996],
            [-6.507910156249977, 29.7837890625],
            [-6.510693359374983, 29.726025390624997],
            [-6.520556640624989, 29.659863281249997],
            [-6.565673828125, 29.603857421875],
            [-6.597753906249977, 29.578955078125],
            [-6.635351562499977, 29.568798828124997],
            [-6.755126953125, 29.583837890625],
            [-6.855566406249977, 29.601611328124996],
            [-7.094921874999983, 29.625195312499997],
            [-7.142431640624977, 29.619580078124997],
            [-7.160205078124989, 29.612646484375],
            [-7.234912109374989, 29.574902343749997],
            [-7.349755859374994, 29.4947265625],
            [-7.427685546874983, 29.425],
            [-7.485742187499994, 29.392236328124994],
            [-7.624609374999977, 29.375195312499997],
            [-7.685156249999977, 29.349511718749994],
            [-7.94384765625, 29.174755859374997],
            [-7.998925781249994, 29.132421875],
            [-8.265185546874989, 28.980517578124996],
            [-8.340478515624994, 28.93017578125],
            [-8.399316406249994, 28.880175781249996],
            [-8.558349609375, 28.767871093749996],
            [-8.659912109375, 28.718603515625],
            [-8.678417968749983, 28.689404296874997],
            [-8.683349609375, 28.620751953124994],
            [-8.683349609375, 28.46923828125],
            [-8.683349609375, 28.323681640624997],
            [-8.683349609375, 28.112011718749997],
            [-8.683349609375, 27.900390625],
            [-8.683349609375, 27.656445312499997],
            [-8.817822265624983, 27.656445312499997],
            [-8.817773437499994, 27.655908203124994],
            [-8.813916015624983, 27.6138671875],
            [-8.784570312499994, 27.530859375],
            [-8.774365234374983, 27.460546875],
            [-8.788964843749994, 27.416552734374996],
            [-8.802685546874983, 27.3609375],
            [-8.796826171874983, 27.308203125],
            [-8.774365234374983, 27.2505859375],
            [-8.753857421874983, 27.191015625],
            [-8.753857421874983, 27.1509765625],
            [-8.794873046874983, 27.120703125],
            [-8.8890625, 27.1041015625],
            [-9.001904296874983, 27.0904296875],
            [-9.084423828124983, 27.0904296875],
            [-9.208447265624983, 27.1001953125],
            [-9.285595703124983, 27.0982421875],
            [-9.352978515624983, 27.0982421875],
            [-9.413037109374983, 27.0884765625],
            [-9.4873046875, 27.050390625],
            [-9.56982421875, 26.9908203125],
            [-9.67333984375, 26.9107421875],
            [-9.7353515625, 26.8609375],
            [-9.81787109375, 26.8501953125],
            [-9.900341796874983, 26.8501953125],
            [-9.980908203124983, 26.890234375],
            [-10.03271484375, 26.9107421875],
            [-10.066845703124983, 26.9087890625],
            [-10.123046875, 26.88046875],
            [-10.189453125, 26.8609375],
            [-10.25146484375, 26.8609375],
            [-10.354931640624983, 26.9009765625],
            [-10.478955078124983, 26.960546875],
            [-10.55126953125, 26.9908203125],
            [-10.654248046874983, 27.0005859375],
            [-10.757763671874983, 27.0201171875],
            [-10.830078125, 27.0103515625],
            [-10.922802734374983, 27.0103515625],
            [-11.046826171874983, 26.9703125],
            [-11.150341796874983, 26.941015625],
            [-11.263623046874983, 26.9107421875],
            [-11.392578125, 26.8833984375],
            [-11.361279296874983, 26.7935546875],
            [-11.316845703124983, 26.7447265625],
            [-11.316845703124983, 26.6841796875],
            [-11.337890625, 26.6333984375],
            [-11.39990234375, 26.58359375],
            [-11.470703125, 26.5201171875],
            [-11.511669921874983, 26.4703125],
            [-11.553173828124983, 26.4009765625],
            [-11.583984375, 26.3609375],
            [-11.63720703125, 26.2955078125],
            [-11.684521484374983, 26.2134765625],
            [-11.69921875, 26.1626953125],
            [-11.718212890624983, 26.1041015625],
            [-11.7548828125, 26.0865234375],
            [-11.880859375, 26.0708984375],
            [-11.960888671874983, 26.050390625],
            [-12.03076171875, 26.030859375],
            [-12.056787109374994, 25.996337890625],
            [-12.060986328124983, 25.99082031249999],
            [-12.0810546875, 25.92050781249999],
            [-12.0810546875, 25.870703125],
            [-12.101025390624983, 25.83066406249999],
            [-12.130859375, 25.73105468749999],
            [-12.170849609374983, 25.640234375],
            [-12.201123046874983, 25.52011718749999],
            [-12.23095703125, 25.42050781249999],
            [-12.270947265624983, 25.260302734375003],
            [-12.310986328124983, 25.1109375],
            [-12.36083984375, 24.9703125],
            [-12.40087890625, 24.88046875],
            [-12.43115234375, 24.83066406249999],
            [-12.5009765625, 24.77011718749999],
            [-12.56103515625, 24.73105468749999],
            [-12.630810546874983, 24.68027343749999],
            [-12.7109375, 24.63046875],
            [-12.820751953124983, 24.57089843749999],
            [-12.9111328125, 24.52011718749999],
            [-12.947851562499977, 24.497265625],
            [-12.991162109374983, 24.4703125],
            [-13.06103515625, 24.40097656249999],
            [-13.12109375, 24.300390625],
            [-13.1611328125, 24.2203125],
            [-13.23095703125, 24.09042968749999],
            [-13.28076171875, 24.02011718749999],
            [-13.310986328124983, 23.98105468749999],
            [-13.39111328125, 23.941015625],
            [-13.48095703125, 23.91074218749999],
            [-13.5810546875, 23.870703125],
            [-13.661083984374983, 23.83066406249999],
            [-13.770947265624983, 23.790625],
            [-13.840771484374983, 23.75058593749999],
            [-13.89111328125, 23.691015625],
            [-13.931103515624983, 23.620703125],
            [-13.980908203124983, 23.52011718749999],
            [-14.02099609375, 23.41074218749999],
            [-14.040966796874983, 23.34042968749999],
            [-14.10107421875, 23.10019531249999],
            [-14.12109375, 22.960546875],
            [-14.141064453124983, 22.870703125],
            [-14.1708984375, 22.76035156249999],
            [-14.190869140624983, 22.59042968749999],
            [-14.190869140624983, 22.45078125],
            [-14.2109375, 22.370703125],
            [-14.22119140625, 22.31015625],
            [-14.27099609375, 22.24082031249999],
            [-14.31103515625, 22.191015625],
            [-14.380810546874983, 22.120703125],
            [-14.44091796875, 22.08066406249999],
            [-14.460888671874983, 22.040625],
            [-14.52099609375, 21.990869140624994],
            [-14.581005859374983, 21.91074218749999],
            [-14.630859375, 21.8609375],
            [-14.62109375, 21.82089843749999],
            [-14.610791015624983, 21.75058593749999],
            [-14.64111328125, 21.68027343749999],
            [-14.670849609374983, 21.60019531249999],
            [-14.7509765625, 21.50058593749999],
            [-14.8408203125, 21.45078125],
            [-14.971142578124983, 21.441015625],
            [-15.15087890625, 21.441015625],
            [-15.290966796874983, 21.45078125],
            [-15.4609375, 21.45078125],
            [-15.610791015624983, 21.4703125],
            [-15.750927734374983, 21.49082031249999],
            [-15.920849609374983, 21.50058593749999],
            [-16.041015625, 21.50058593749999],
            [-16.190869140624983, 21.48105468749999],
            [-16.581005859374983, 21.48105468749999],
            [-16.73095703125, 21.4703125],
            [-16.951123046874983, 21.43027343749999],
            [-17.00297851562499, 21.42075195312499],
            [-17.003076171874994, 21.420703125],
            [-16.930859374999983, 21.9],
            [-16.79326171874999, 22.159716796875003],
            [-16.683984375, 22.274365234374997],
            [-16.514404296875, 22.33349609375],
            [-16.358740234374977, 22.59453125],
            [-16.304296874999977, 22.83481445312499],
            [-16.201855468749983, 22.945361328125003],
            [-16.16972656249999, 23.031933593749997],
            [-16.21025390624999, 23.097900390625],
            [-16.113671875, 23.2275390625],
            [-15.996728515624994, 23.425488281249997],
            [-15.942626953125, 23.552636718749994],
            [-15.805957031249989, 23.74951171875],
            [-15.789257812499983, 23.792871093749994],
            [-15.801660156249994, 23.842236328124997],
            [-15.855175781249983, 23.800341796875003],
            [-15.912548828124983, 23.727587890625003],
            [-15.980712890625, 23.6703125],
            [-15.952832031249983, 23.74082031249999],
            [-15.899316406249994, 23.844433593749997],
            [-15.777783203124983, 23.952929687500003],
            [-15.586328125, 24.07275390625],
            [-15.188623046874994, 24.478808593750003],
            [-15.038867187499989, 24.548828125],
            [-14.904296875, 24.719775390625003],
            [-14.856054687499977, 24.87158203125],
            [-14.842919921874994, 25.220117187499994],
            [-14.794921875, 25.40415039062499],
            [-14.70703125, 25.54770507812499],
            [-14.602294921875, 25.808544921874997],
            [-14.522753906249989, 25.925244140624997],
            [-14.470556640624977, 26.163037109374997],
            [-14.413867187499989, 26.253710937499996],
            [-14.312451171874983, 26.296728515625],
            [-14.168359375, 26.415429687499994],
            [-13.952099609374983, 26.48876953125],
            [-13.695898437499977, 26.642919921875],
            [-13.575781249999977, 26.735107421875],
            [-13.495751953124994, 26.87265625],
            [-13.409814453124994, 27.146630859374994],
            [-13.256152343749989, 27.434619140624996],
            [-13.177392578124994, 27.65185546875],
            [-13.175976562499983, 27.655712890624997],
            [-13.040722656249983, 27.769824218749996],
            [-12.948925781249983, 27.91416015625],
            [-12.793652343749983, 27.978417968749994],
            [-12.468896484374994, 28.009423828124994],
            [-11.986083984375, 28.129296875],
            [-11.552685546874983, 28.310107421874996],
            [-11.43017578125, 28.38203125],
            [-11.299072265625, 28.526074218749997],
            [-11.080957031249994, 28.713769531249994],
            [-10.673828125, 28.939208984375],
            [-10.486474609374994, 29.06494140625],
            [-10.200585937499994, 29.38037109375],
            [-10.010498046875, 29.64140625],
            [-9.852636718749977, 29.809228515624994],
            [-9.743457031249989, 29.958203125],
            [-9.667089843749977, 30.109277343749994],
            [-9.623828124999989, 30.35263671875],
            [-9.652929687499977, 30.447558593749996],
            [-9.773144531249983, 30.603125],
            [-9.85390625, 30.644580078124996],
            [-9.87548828125, 30.717919921874994],
            [-9.832421875, 30.847265625],
            [-9.833349609374977, 31.069628906249996],
            [-9.808691406249977, 31.424609375],
            [-9.674951171874994, 31.710986328124996],
            [-9.347460937499989, 32.086376953125],
            [-9.286572265624983, 32.240576171875],
            [-9.249121093749977, 32.48583984375],
            [-9.245849609375, 32.5724609375],
            [-8.836230468749989, 32.920458984374996],
            [-8.596289062499977, 33.187158203124994],
            [-8.512841796874994, 33.25244140625],
            [-8.301171875, 33.374365234375],
            [-7.562353515624977, 33.640283203124994],
            [-7.144677734374994, 33.830322265625],
            [-6.900976562499977, 33.969042968749996],
            [-6.755761718749994, 34.13291015625],
            [-6.353125, 34.77607421875],
            [-5.957568359374989, 35.68115234375],
            [-5.9248046875, 35.785791015624994],
            [-5.747949218749994, 35.815966796874996],
            [-5.622851562499989, 35.82890625],
            [-5.522265624999989, 35.86201171875],
            [-5.397363281249994, 35.9298828125],
            [-5.27783203125, 35.902734375],
            [-5.337646484375, 35.85654296875],
            [-5.337646484375, 35.74521484375],
            [-5.252685546875, 35.61474609375],
            [-5.105371093749994, 35.4677734375],
            [-4.837207031249989, 35.281298828124996],
            [-4.628320312499994, 35.206396484375],
            [-4.329980468749994, 35.161474609375],
            [-3.982421875, 35.243408203125],
            [-3.787988281249994, 35.244921875],
            [-3.693261718749994, 35.27998046875],
            [-3.590625, 35.228320312499996],
            [-3.394726562499983, 35.211816406249994],
            [-3.206005859374983, 35.239111328125],
            [-3.063085937499977, 35.317236328125],
            [-2.972216796874989, 35.407275390624996],
            [-2.957958984374983, 35.363085937499996],
            [-2.95361328125, 35.31513671875],
            [-2.925976562499983, 35.287109375],
            [-2.86953125, 35.17265625],
            [-2.839941406249977, 35.127832031249994],
            [-2.731396484374983, 35.135205078125],
            [-2.636816406249977, 35.112695312499994],
            [-2.423730468749994, 35.123486328125],
            [-2.219628906249994, 35.10419921875],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 7,
        sovereignt: "Western Sahara",
        sov_a3: "SAH",
        adm0_dif: 0,
        level: 2,
        type: "Indeterminate",
        tlc: "1",
        admin: "Western Sahara",
        adm0_a3: "SAH",
        geou_dif: 0,
        geounit: "Western Sahara",
        gu_a3: "SAH",
        su_dif: 0,
        subunit: "Western Sahara",
        su_a3: "SAH",
        brk_diff: 1,
        name: "W. Sahara",
        name_long: "Western Sahara",
        brk_a3: "B28",
        brk_name: "W. Sahara",
        brk_group: null,
        abbrev: "W. Sah.",
        postal: "WS",
        formal_en: "Sahrawi Arab Democratic Republic",
        formal_fr: null,
        name_ciawf: "Western Sahara",
        note_adm0: null,
        note_brk: "Self admin.; Claimed by Morocco",
        name_sort: "Western Sahara",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 7,
        mapcolor9: 4,
        mapcolor13: 4,
        pop_est: 603253,
        pop_rank: 11,
        pop_year: 2017,
        gdp_md: 907,
        gdp_year: 2007,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        fips_10: "WI",
        iso_a2: "EH",
        iso_a2_eh: "EH",
        iso_a3: "ESH",
        iso_a3_eh: "ESH",
        iso_n3: "732",
        iso_n3_eh: "732",
        un_a3: "732",
        wb_a2: "-99",
        wb_a3: "-99",
        woe_id: 23424990,
        woe_id_eh: 23424990,
        woe_note: "Exact WOE match as country",
        adm0_iso: "B28",
        adm0_diff: null,
        adm0_tlc: "B28",
        adm0_a3_us: "SAH",
        adm0_a3_fr: "MAR",
        adm0_a3_ru: "SAH",
        adm0_a3_es: "SAH",
        adm0_a3_cn: "SAH",
        adm0_a3_tw: "SAH",
        adm0_a3_in: "MAR",
        adm0_a3_np: "SAH",
        adm0_a3_pk: "SAH",
        adm0_a3_de: "SAH",
        adm0_a3_gb: "SAH",
        adm0_a3_br: "SAH",
        adm0_a3_il: "SAH",
        adm0_a3_ps: "MAR",
        adm0_a3_sa: "MAR",
        adm0_a3_eg: "SAH",
        adm0_a3_ma: "MAR",
        adm0_a3_pt: "SAH",
        adm0_a3_ar: "SAH",
        adm0_a3_jp: "SAH",
        adm0_a3_ko: "SAH",
        adm0_a3_vn: "SAH",
        adm0_a3_tr: "MAR",
        adm0_a3_id: "MAR",
        adm0_a3_pl: "MAR",
        adm0_a3_gr: "SAH",
        adm0_a3_it: "SAH",
        adm0_a3_nl: "MAR",
        adm0_a3_se: "SAH",
        adm0_a3_bd: "SAH",
        adm0_a3_ua: "SAH",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Northern Africa",
        region_wb: "Middle East & North Africa",
        name_len: 9,
        long_len: 14,
        abbrev_len: 7,
        tiny: -99,
        homepart: 1,
        min_zoom: 4.7,
        min_label: 6,
        max_label: 11,
        label_x: -12.630304,
        label_y: 23.967592,
        ne_id: 1159321223,
        wikidataid: "Q6250",
        name_ar: "الصحراء الغربية",
        name_bn: "পশ্চিম সাহারা",
        name_de: "Westsahara",
        name_en: "Western Sahara",
        name_es: "Sahara Occidental",
        name_fa: "صحرای غربی",
        name_fr: "Sahara occidental",
        name_el: "Δυτική Σαχάρα",
        name_he: "סהרה המערבית",
        name_hi: "पश्चिमी सहारा",
        name_hu: "Nyugat-Szahara",
        name_id: "Sahara Barat",
        name_it: "Sahara Occidentale",
        name_ja: "西サハラ",
        name_ko: "서사하라",
        name_nl: "Westelijke Sahara",
        name_pl: "Sahara Zachodnia",
        name_pt: "Sara Ocidental",
        name_ru: "Западная Сахара",
        name_sv: "Västsahara",
        name_tr: "Batı Sahra",
        name_uk: "Західна Сахара",
        name_ur: "مغربی صحارا",
        name_vi: "Tây Sahara",
        name_zh: "西撒哈拉",
        name_zht: "西撒哈拉",
        fclass_iso: "Admin-0 dependency",
        tlc_diff: null,
        fclass_tlc: "Admin-0 dependency",
        fclass_us: null,
        fclass_fr: "Unrecognized",
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: "Unrecognized",
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: "Unrecognized",
        fclass_sa: "Unrecognized",
        fclass_eg: null,
        fclass_ma: "Unrecognized",
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: "Unrecognized",
        fclass_id: "Unrecognized",
        fclass_pl: "Unrecognized",
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: "Unrecognized",
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "ESH.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-8.817773437499994, 27.655908203124994],
            [-8.817822265624983, 27.656445312499997],
            [-8.683349609375, 27.656445312499997],
            [-8.683349609375, 27.490234375],
            [-8.683349609375, 27.2859375],
            [-8.68310546875, 27.119287109374994],
            [-8.682861328125, 26.921337890624997],
            [-8.6826171875, 26.72314453125],
            [-8.682324218749983, 26.497705078124994],
            [-8.68212890625, 26.273193359375],
            [-8.68212890625, 26.10947265625],
            [-8.682226562499977, 25.995507812499994],
            [-8.885644531249994, 25.995507812499994],
            [-9.071923828124994, 25.995507812499994],
            [-9.258203125, 25.995507812499994],
            [-9.444531249999983, 25.995507812499994],
            [-9.630859375, 25.995507812499994],
            [-9.817187499999989, 25.99545898437499],
            [-10.003515624999977, 25.99545898437499],
            [-10.189794921874977, 25.99545898437499],
            [-10.376123046874994, 25.99545898437499],
            [-10.562451171874983, 25.99545898437499],
            [-10.748779296875, 25.99545898437499],
            [-10.935107421874989, 25.99545898437499],
            [-11.121386718749989, 25.99545898437499],
            [-11.307714843749977, 25.995410156250003],
            [-11.494042968749994, 25.995410156250003],
            [-11.680371093749983, 25.995410156250003],
            [-11.866650390624983, 25.995410156250003],
            [-12.016308593749983, 25.995410156250003],
            [-12.016308593749983, 25.87631835937499],
            [-12.016308593749983, 25.740136718749994],
            [-12.016308593749983, 25.60400390625],
            [-12.016308593749983, 25.46787109374999],
            [-12.016308593749983, 25.331689453124994],
            [-12.016308593749983, 25.195556640625],
            [-12.016308593749983, 25.059375],
            [-12.016308593749983, 24.923242187499994],
            [-12.016308593749983, 24.787109375],
            [-12.016308593749983, 24.65097656249999],
            [-12.016308593749983, 24.514794921874994],
            [-12.016308593749983, 24.378662109375],
            [-12.016308593749983, 24.242480468750003],
            [-12.016308593749983, 24.106347656249994],
            [-12.016308593749983, 23.97021484375],
            [-12.016308593749983, 23.834033203125003],
            [-12.016308593749983, 23.697900390624994],
            [-12.016308593749983, 23.576464843750003],
            [-12.0234375, 23.467578125],
            [-12.083349609374977, 23.435449218749994],
            [-12.226171874999977, 23.377490234375003],
            [-12.372900390624977, 23.318017578124994],
            [-12.559375, 23.290820312500003],
            [-12.620410156249989, 23.27133789062499],
            [-12.739599609374977, 23.19272460937499],
            [-12.89599609375, 23.089550781249997],
            [-13.031494140625, 23.000244140625],
            [-13.120898437499989, 22.884082031250003],
            [-13.153271484374983, 22.820507812499997],
            [-13.16650390625, 22.753222656250003],
            [-13.155957031249983, 22.68930664062499],
            [-13.107324218749994, 22.560742187499997],
            [-13.094335937499977, 22.495996093749994],
            [-13.086767578124977, 22.383251953124997],
            [-13.078466796874977, 22.260449218749997],
            [-13.069580078125, 22.128173828125],
            [-13.060644531249977, 21.995751953124994],
            [-13.051220703124983, 21.854785156250003],
            [-13.041748046875, 21.713818359374997],
            [-13.0322265625, 21.572070312500003],
            [-13.025097656249983, 21.466796875],
            [-13.016210937499977, 21.333935546874997],
            [-13.167431640624983, 21.333789062500003],
            [-13.396728515625, 21.333544921875003],
            [-13.626025390624989, 21.333251953125],
            [-13.855371093749994, 21.332958984374997],
            [-14.084667968749983, 21.332714843749997],
            [-14.31396484375, 21.332421875],
            [-14.543261718749989, 21.33212890624999],
            [-14.772607421874994, 21.33188476562499],
            [-15.001904296874983, 21.331591796875003],
            [-15.231201171875, 21.331298828125],
            [-15.460546874999977, 21.3310546875],
            [-15.689794921874977, 21.330761718749997],
            [-15.919140624999983, 21.33046875],
            [-16.1484375, 21.330224609374994],
            [-16.37773437499999, 21.32993164062499],
            [-16.607031249999977, 21.329638671875003],
            [-16.836328125, 21.329394531250003],
            [-16.964550781249983, 21.329248046874994],
            [-17.00590820312499, 21.14243164062499],
            [-17.042382812499994, 21.008007812499997],
            [-17.06396484375, 20.898828125],
            [-17.048046874999983, 20.80615234375],
            [-17.098779296874994, 20.856884765624997],
            [-17.009619140624977, 21.377099609374994],
            [-17.003076171874994, 21.420703125],
            [-17.00297851562499, 21.42075195312499],
            [-16.951123046874983, 21.43027343749999],
            [-16.73095703125, 21.4703125],
            [-16.581005859374983, 21.48105468749999],
            [-16.190869140624983, 21.48105468749999],
            [-16.041015625, 21.50058593749999],
            [-15.920849609374983, 21.50058593749999],
            [-15.750927734374983, 21.49082031249999],
            [-15.610791015624983, 21.4703125],
            [-15.4609375, 21.45078125],
            [-15.290966796874983, 21.45078125],
            [-15.15087890625, 21.441015625],
            [-14.971142578124983, 21.441015625],
            [-14.8408203125, 21.45078125],
            [-14.7509765625, 21.50058593749999],
            [-14.670849609374983, 21.60019531249999],
            [-14.64111328125, 21.68027343749999],
            [-14.610791015624983, 21.75058593749999],
            [-14.62109375, 21.82089843749999],
            [-14.630859375, 21.8609375],
            [-14.581005859374983, 21.91074218749999],
            [-14.52099609375, 21.990869140624994],
            [-14.460888671874983, 22.040625],
            [-14.44091796875, 22.08066406249999],
            [-14.380810546874983, 22.120703125],
            [-14.31103515625, 22.191015625],
            [-14.27099609375, 22.24082031249999],
            [-14.22119140625, 22.31015625],
            [-14.2109375, 22.370703125],
            [-14.190869140624983, 22.45078125],
            [-14.190869140624983, 22.59042968749999],
            [-14.1708984375, 22.76035156249999],
            [-14.141064453124983, 22.870703125],
            [-14.12109375, 22.960546875],
            [-14.10107421875, 23.10019531249999],
            [-14.040966796874983, 23.34042968749999],
            [-14.02099609375, 23.41074218749999],
            [-13.980908203124983, 23.52011718749999],
            [-13.931103515624983, 23.620703125],
            [-13.89111328125, 23.691015625],
            [-13.840771484374983, 23.75058593749999],
            [-13.770947265624983, 23.790625],
            [-13.661083984374983, 23.83066406249999],
            [-13.5810546875, 23.870703125],
            [-13.48095703125, 23.91074218749999],
            [-13.39111328125, 23.941015625],
            [-13.310986328124983, 23.98105468749999],
            [-13.28076171875, 24.02011718749999],
            [-13.23095703125, 24.09042968749999],
            [-13.1611328125, 24.2203125],
            [-13.12109375, 24.300390625],
            [-13.06103515625, 24.40097656249999],
            [-12.991162109374983, 24.4703125],
            [-12.947851562499977, 24.497265625],
            [-12.9111328125, 24.52011718749999],
            [-12.820751953124983, 24.57089843749999],
            [-12.7109375, 24.63046875],
            [-12.630810546874983, 24.68027343749999],
            [-12.56103515625, 24.73105468749999],
            [-12.5009765625, 24.77011718749999],
            [-12.43115234375, 24.83066406249999],
            [-12.40087890625, 24.88046875],
            [-12.36083984375, 24.9703125],
            [-12.310986328124983, 25.1109375],
            [-12.270947265624983, 25.260302734375003],
            [-12.23095703125, 25.42050781249999],
            [-12.201123046874983, 25.52011718749999],
            [-12.170849609374983, 25.640234375],
            [-12.130859375, 25.73105468749999],
            [-12.101025390624983, 25.83066406249999],
            [-12.0810546875, 25.870703125],
            [-12.0810546875, 25.92050781249999],
            [-12.060986328124983, 25.99082031249999],
            [-12.056787109374994, 25.996337890625],
            [-12.03076171875, 26.030859375],
            [-11.960888671874983, 26.050390625],
            [-11.880859375, 26.0708984375],
            [-11.7548828125, 26.0865234375],
            [-11.718212890624983, 26.1041015625],
            [-11.69921875, 26.1626953125],
            [-11.684521484374983, 26.2134765625],
            [-11.63720703125, 26.2955078125],
            [-11.583984375, 26.3609375],
            [-11.553173828124983, 26.4009765625],
            [-11.511669921874983, 26.4703125],
            [-11.470703125, 26.5201171875],
            [-11.39990234375, 26.58359375],
            [-11.337890625, 26.6333984375],
            [-11.316845703124983, 26.6841796875],
            [-11.316845703124983, 26.7447265625],
            [-11.361279296874983, 26.7935546875],
            [-11.392578125, 26.8833984375],
            [-11.263623046874983, 26.9107421875],
            [-11.150341796874983, 26.941015625],
            [-11.046826171874983, 26.9703125],
            [-10.922802734374983, 27.0103515625],
            [-10.830078125, 27.0103515625],
            [-10.757763671874983, 27.0201171875],
            [-10.654248046874983, 27.0005859375],
            [-10.55126953125, 26.9908203125],
            [-10.478955078124983, 26.960546875],
            [-10.354931640624983, 26.9009765625],
            [-10.25146484375, 26.8609375],
            [-10.189453125, 26.8609375],
            [-10.123046875, 26.88046875],
            [-10.066845703124983, 26.9087890625],
            [-10.03271484375, 26.9107421875],
            [-9.980908203124983, 26.890234375],
            [-9.900341796874983, 26.8501953125],
            [-9.81787109375, 26.8501953125],
            [-9.7353515625, 26.8609375],
            [-9.67333984375, 26.9107421875],
            [-9.56982421875, 26.9908203125],
            [-9.4873046875, 27.050390625],
            [-9.413037109374983, 27.0884765625],
            [-9.352978515624983, 27.0982421875],
            [-9.285595703124983, 27.0982421875],
            [-9.208447265624983, 27.1001953125],
            [-9.084423828124983, 27.0904296875],
            [-9.001904296874983, 27.0904296875],
            [-8.8890625, 27.1041015625],
            [-8.794873046874983, 27.120703125],
            [-8.753857421874983, 27.1509765625],
            [-8.753857421874983, 27.191015625],
            [-8.774365234374983, 27.2505859375],
            [-8.796826171874983, 27.308203125],
            [-8.802685546874983, 27.3609375],
            [-8.788964843749994, 27.416552734374996],
            [-8.774365234374983, 27.460546875],
            [-8.784570312499994, 27.530859375],
            [-8.813916015624983, 27.6138671875],
            [-8.817773437499994, 27.655908203124994],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 4,
        sovereignt: "Republic of the Congo",
        sov_a3: "COG",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Republic of the Congo",
        adm0_a3: "COG",
        geou_dif: 0,
        geounit: "Republic of the Congo",
        gu_a3: "COG",
        su_dif: 0,
        subunit: "Republic of the Congo",
        su_a3: "COG",
        brk_diff: 0,
        name: "Congo",
        name_long: "Republic of the Congo",
        brk_a3: "COG",
        brk_name: "Republic of the Congo",
        brk_group: null,
        abbrev: "Rep. Congo",
        postal: "CG",
        formal_en: "Republic of the Congo",
        formal_fr: null,
        name_ciawf: "Congo, Republic of the",
        note_adm0: null,
        note_brk: null,
        name_sort: "Congo, Rep.",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 1,
        mapcolor9: 3,
        mapcolor13: 10,
        pop_est: 5380508,
        pop_rank: 13,
        pop_year: 2019,
        gdp_md: 12267,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        fips_10: "CF",
        iso_a2: "CG",
        iso_a2_eh: "CG",
        iso_a3: "COG",
        iso_a3_eh: "COG",
        iso_n3: "178",
        iso_n3_eh: "178",
        un_a3: "178",
        wb_a2: "CG",
        wb_a3: "COG",
        woe_id: 23424779,
        woe_id_eh: 23424779,
        woe_note: "Exact WOE match as country",
        adm0_iso: "COG",
        adm0_diff: null,
        adm0_tlc: "COG",
        adm0_a3_us: "COG",
        adm0_a3_fr: "COG",
        adm0_a3_ru: "COG",
        adm0_a3_es: "COG",
        adm0_a3_cn: "COG",
        adm0_a3_tw: "COG",
        adm0_a3_in: "COG",
        adm0_a3_np: "COG",
        adm0_a3_pk: "COG",
        adm0_a3_de: "COG",
        adm0_a3_gb: "COG",
        adm0_a3_br: "COG",
        adm0_a3_il: "COG",
        adm0_a3_ps: "COG",
        adm0_a3_sa: "COG",
        adm0_a3_eg: "COG",
        adm0_a3_ma: "COG",
        adm0_a3_pt: "COG",
        adm0_a3_ar: "COG",
        adm0_a3_jp: "COG",
        adm0_a3_ko: "COG",
        adm0_a3_vn: "COG",
        adm0_a3_tr: "COG",
        adm0_a3_id: "COG",
        adm0_a3_pl: "COG",
        adm0_a3_gr: "COG",
        adm0_a3_it: "COG",
        adm0_a3_nl: "COG",
        adm0_a3_se: "COG",
        adm0_a3_bd: "COG",
        adm0_a3_ua: "COG",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Middle Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 5,
        long_len: 21,
        abbrev_len: 10,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: 15.9005,
        label_y: 0.142331,
        ne_id: 1159320515,
        wikidataid: "Q971",
        name_ar: "جمهورية الكونغو",
        name_bn: "কঙ্গো প্রজাতন্ত্র",
        name_de: "Republik Kongo",
        name_en: "Republic of the Congo",
        name_es: "República del Congo",
        name_fa: "جمهوری کنگو",
        name_fr: "République du Congo",
        name_el: "Δημοκρατία του Κονγκό",
        name_he: "הרפובליקה של קונגו",
        name_hi: "कांगो गणराज्य",
        name_hu: "Kongói Köztársaság",
        name_id: "Republik Kongo",
        name_it: "Repubblica del Congo",
        name_ja: "コンゴ共和国",
        name_ko: "콩고 공화국",
        name_nl: "Congo-Brazzaville",
        name_pl: "Kongo",
        name_pt: "República do Congo",
        name_ru: "Республика Конго",
        name_sv: "Kongo-Brazzaville",
        name_tr: "Kongo Cumhuriyeti",
        name_uk: "Республіка Конго",
        name_ur: "جمہوریہ کانگو",
        name_vi: "Cộng hòa Congo",
        name_zh: "刚果共和国",
        name_zht: "剛果共和國",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "COG.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [11.130175781250017, -3.916308593750003],
            [11.190039062500006, -3.762011718750003],
            [11.234472656250006, -3.690820312500009],
            [11.288281250000011, -3.64111328125],
            [11.504296875000023, -3.5203125],
            [11.536816406250011, -3.525],
            [11.685742187500011, -3.68203125],
            [11.7333984375, -3.694531250000011],
            [11.786425781250017, -3.690234375],
            [11.84912109375, -3.696679687500009],
            [11.8798828125, -3.665917968750009],
            [11.884765625, -3.625390625],
            [11.839453125, -3.580078125],
            [11.832910156250023, -3.531445312500011],
            [11.86474609375, -3.478613281250006],
            [11.8828125, -3.420214843750003],
            [11.929296875, -3.350976562500009],
            [11.934179687500006, -3.318554687500011],
            [11.885058593750017, -3.283203125],
            [11.784375, -3.229101562500006],
            [11.715429687500006, -3.176953125000011],
            [11.6890625, -3.126953125],
            [11.7080078125, -3.063085937500006],
            [11.763476562500017, -3.01123046875],
            [11.760156250000023, -2.983105468750011],
            [11.711328125000023, -2.9365234375],
            [11.675683593750023, -2.886621093750009],
            [11.639062500000023, -2.855371093750009],
            [11.537792968750011, -2.83671875],
            [11.55712890625, -2.769628906250006],
            [11.594531250000017, -2.670996093750006],
            [11.603417968750023, -2.595410156250011],
            [11.5751953125, -2.397070312500006],
            [11.577734375, -2.3609375],
            [11.60546875, -2.342578125],
            [11.665917968750023, -2.364550781250003],
            [11.726757812500011, -2.394726562500011],
            [11.892382812500017, -2.351464843750009],
            [11.950292968750006, -2.344824218750006],
            [11.998242187500011, -2.3828125],
            [12.064453125, -2.41259765625],
            [12.446386718750006, -2.329980468750009],
            [12.453808593750011, -2.24560546875],
            [12.475683593750006, -2.169238281250003],
            [12.478515625, -2.112011718750011],
            [12.462597656250011, -2.075292968750006],
            [12.44375, -2.047558593750011],
            [12.432421875000017, -1.990332031250006],
            [12.43212890625, -1.928906250000011],
            [12.468652343750023, -1.9],
            [12.590429687500006, -1.826855468750011],
            [12.62841796875, -1.82958984375],
            [12.713671875000017, -1.869433593750003],
            [12.793554687500006, -1.931835937500011],
            [12.864453125000011, -2.06328125],
            [12.91357421875, -2.17626953125],
            [12.991992187500017, -2.313378906250009],
            [13.158593750000023, -2.369140625],
            [13.357324218750023, -2.40478515625],
            [13.464941406250006, -2.395410156250009],
            [13.618554687500023, -2.278613281250003],
            [13.70556640625, -2.1875],
            [13.733789062500023, -2.138476562500003],
            [13.784375, -2.163769531250011],
            [13.841601562500017, -2.28369140625],
            [13.878515625, -2.330175781250006],
            [13.8876953125, -2.37451171875],
            [13.86181640625, -2.429882812500011],
            [13.886914062500011, -2.465429687500006],
            [13.993847656250011, -2.490625],
            [14.08740234375, -2.466894531250006],
            [14.129785156250023, -2.41796875],
            [14.199804687500006, -2.354199218750011],
            [14.200390625000011, -2.300585937500003],
            [14.162890625000017, -2.265527343750009],
            [14.162890625000017, -2.217578125],
            [14.201757812500006, -2.179882812500011],
            [14.239648437500023, -2.076757812500006],
            [14.25146484375, -2.00146484375],
            [14.288378906250017, -1.953515625],
            [14.358593750000011, -1.920214843750003],
            [14.383984375000011, -1.890039062500009],
            [14.423242187500023, -1.711523437500006],
            [14.402929687500006, -1.64697265625],
            [14.402929687500006, -1.593359375],
            [14.447265625, -1.525097656250011],
            [14.45556640625, -1.413183593750006],
            [14.436914062500023, -1.229785156250003],
            [14.424023437500011, -1.10390625],
            [14.41064453125, -0.972070312500009],
            [14.444921875, -0.798828125],
            [14.480566406250006, -0.618359375000011],
            [14.47412109375, -0.573437500000011],
            [14.424707031250023, -0.518652343750006],
            [14.36376953125, -0.468554687500003],
            [14.206738281250011, -0.42734375],
            [14.148339843750023, -0.361914062500006],
            [14.102832031250017, -0.292382812500009],
            [14.069433593750006, -0.270117187500006],
            [13.898046875, -0.242578125],
            [13.860058593750011, -0.203320312500011],
            [13.87548828125, -0.0908203125],
            [13.890625, 0.075292968749991],
            [13.884570312500017, 0.190820312499994],
            [13.915136718750006, 0.283984374999989],
            [13.949609375000023, 0.353808593749989],
            [14.025292968750023, 0.427734375],
            [14.065527343750006, 0.514990234374991],
            [14.0875, 0.536572265624997],
            [14.23095703125, 0.551123046874991],
            [14.283105468750023, 0.587451171874989],
            [14.32421875, 0.62421875],
            [14.341503906250011, 0.673828125],
            [14.390625, 0.755712890624991],
            [14.434472656250023, 0.811474609374997],
            [14.439160156250011, 0.84912109375],
            [14.429882812500011, 0.901464843749991],
            [14.386425781250011, 1.004443359374989],
            [14.33447265625, 1.090234375],
            [14.303027343750017, 1.120849609375],
            [14.23974609375, 1.322558593749989],
            [14.180859375000011, 1.370214843749991],
            [14.066210937500017, 1.395898437499994],
            [13.851367187500017, 1.41875],
            [13.72119140625, 1.382275390624997],
            [13.523339843750023, 1.314599609374994],
            [13.372363281250017, 1.267773437499997],
            [13.274121093750011, 1.241015624999989],
            [13.21630859375, 1.2484375],
            [13.190136718750011, 1.279248046874997],
            [13.228320312500017, 1.305419921875],
            [13.247363281250017, 1.36669921875],
            [13.222753906250006, 1.45458984375],
            [13.1845703125, 1.535058593749994],
            [13.162695312500006, 1.648095703124994],
            [13.172167968750017, 1.78857421875],
            [13.20947265625, 1.92041015625],
            [13.288671875, 2.091699218749994],
            [13.293554687500006, 2.161572265624997],
            [13.533496093750017, 2.159521484374991],
            [13.772753906250017, 2.157421875],
            [14.034375, 2.158886718749997],
            [14.287011718750023, 2.160351562499997],
            [14.484082031250011, 2.154736328124997],
            [14.578906250000017, 2.199121093749994],
            [14.669140625000011, 2.132080078125],
            [14.713281250000023, 2.117138671874997],
            [14.728320312500017, 2.122412109374991],
            [14.762890625000011, 2.0751953125],
            [14.875, 2.08046875],
            [14.892773437500011, 2.0693359375],
            [14.902441406250006, 2.012304687499991],
            [15.006445312500006, 2.013769531249991],
            [15.057812500000011, 2.000878906249994],
            [15.099609375, 2.00234375],
            [15.160058593750023, 2.035595703124997],
            [15.203515625000023, 2.024462890624989],
            [15.282421875000011, 1.981738281249989],
            [15.338769531250023, 1.944726562499994],
            [15.41748046875, 1.956738281249997],
            [15.600292968750011, 1.950390625],
            [15.741601562500023, 1.914990234374997],
            [15.881640625000017, 1.816601562499997],
            [15.975195312500006, 1.760009765625],
            [16.059375, 1.676220703124997],
            [16.09033203125, 1.691259765624991],
            [16.119531250000023, 1.714111328125],
            [16.136132812500023, 1.72421875],
            [16.13496093750001, 1.795947265624989],
            [16.087890625, 1.918066406249991],
            [16.069628906250017, 2.021679687499997],
            [16.080078125, 2.106787109374991],
            [16.11572265625, 2.167822265624991],
            [16.176562500000017, 2.204785156249997],
            [16.1826171875, 2.262451171875],
            [16.183398437500017, 2.270068359374989],
            [16.251757812500017, 2.406787109374989],
            [16.319628906250017, 2.542773437499989],
            [16.401269531250023, 2.701025390624991],
            [16.468554687500017, 2.831738281249997],
            [16.459570312500006, 2.896533203124989],
            [16.466210937500023, 2.993212890624989],
            [16.480078125, 3.100976562499994],
            [16.47675781250001, 3.165136718749991],
            [16.49628906250001, 3.208837890624991],
            [16.543066406250006, 3.36953125],
            [16.570410156250006, 3.463085937499997],
            [16.610742187500023, 3.50537109375],
            [16.67333984375, 3.535205078124989],
            [16.76435546875001, 3.536279296874994],
            [17.002539062500006, 3.556689453124989],
            [17.224707031250006, 3.598437499999989],
            [17.2984375, 3.6171875],
            [17.43798828125, 3.684619140624989],
            [17.491601562500023, 3.687304687499989],
            [17.537695312500006, 3.66162109375],
            [17.806640625, 3.584179687499997],
            [17.88037109375, 3.553857421874994],
            [17.907128906250023, 3.558398437499989],
            [17.94794921875001, 3.5517578125],
            [18.0107421875, 3.550830078124989],
            [18.072265625, 3.560302734375],
            [18.111328125, 3.551074218749989],
            [18.160937500000017, 3.499804687499989],
            [18.193945312500006, 3.505419921874989],
            [18.237109375000017, 3.542675781249997],
            [18.318164062500017, 3.580810546875],
            [18.474414062500017, 3.622998046874997],
            [18.499804687500017, 3.604101562499991],
            [18.553808593750006, 3.510205078124997],
            [18.6103515625, 3.478417968749994],
            [18.622167968750006, 3.304052734374991],
            [18.54707031250001, 3.087011718749991],
            [18.49091796875001, 2.924414062499991],
            [18.34345703125001, 2.655419921874994],
            [18.21162109375001, 2.414941406249994],
            [18.072167968750023, 2.01328125],
            [18.072851562500006, 1.719384765624994],
            [18.05781250000001, 1.534863281249997],
            [18.01171875, 1.422119140625],
            [17.902441406250006, 1.118066406249994],
            [17.8857421875, 0.856884765624997],
            [17.925195312500023, 0.537304687499997],
            [17.8876953125, 0.234130859375],
            [17.77314453125001, -0.052392578125009],
            [17.72412109375, -0.277539062500011],
            [17.752832031250023, -0.549023437500011],
            [17.542871093750023, -0.775],
            [17.27880859375, -0.999609375],
            [17.10761718750001, -1.064453125],
            [16.974707031250006, -1.139941406250003],
            [16.8798828125, -1.225878906250003],
            [16.84912109375, -1.2724609375],
            [16.780078125000017, -1.376367187500009],
            [16.622460937500023, -1.698925781250011],
            [16.54072265625001, -1.840136718750003],
            [16.43359375, -1.960839843750009],
            [16.27392578125, -2.108203125],
            [16.21533203125, -2.177832031250006],
            [16.19160156250001, -2.279101562500003],
            [16.20185546875001, -2.464746093750009],
            [16.217382812500006, -3.0302734375],
            [16.190625, -3.194433593750006],
            [16.146777343750017, -3.464160156250003],
            [15.990039062500017, -3.766210937500006],
            [15.872460937500023, -3.934277343750011],
            [15.754589843750011, -3.985546875000011],
            [15.60009765625, -4.030957031250011],
            [15.525976562500006, -4.087988281250006],
            [15.48095703125, -4.171777343750009],
            [15.394628906250006, -4.244921875],
            [15.2671875, -4.3076171875],
            [15.10625, -4.461035156250006],
            [14.912109375, -4.70556640625],
            [14.779296875, -4.845703125],
            [14.707910156250023, -4.881738281250009],
            [14.633984375000011, -4.885058593750003],
            [14.5576171875, -4.855761718750003],
            [14.493945312500017, -4.851660156250006],
            [14.461621093750011, -4.864941406250011],
            [14.44091796875, -4.854101562500006],
            [14.410742187500006, -4.83125],
            [14.411914062500017, -4.775],
            [14.402929687500006, -4.681640625],
            [14.365429687500011, -4.585546875],
            [14.409960937500017, -4.508105468750003],
            [14.449804687500006, -4.449511718750003],
            [14.442773437500023, -4.419042968750006],
            [14.402929687500006, -4.369726562500006],
            [14.358300781250023, -4.299414062500006],
            [14.316210937500017, -4.304101562500009],
            [14.22705078125, -4.358105468750011],
            [14.133886718750006, -4.4],
            [14.046875, -4.41748046875],
            [13.978417968750023, -4.461230468750003],
            [13.94091796875, -4.484667968750003],
            [13.88232421875, -4.484667968750003],
            [13.849511718750023, -4.458886718750009],
            [13.778027343750011, -4.433886718750003],
            [13.739062500000017, -4.442480468750006],
            [13.717089843750017, -4.454492187500009],
            [13.707617187500006, -4.543261718750003],
            [13.699414062500011, -4.618359375000011],
            [13.685351562500017, -4.688671875000011],
            [13.659570312500023, -4.721484375],
            [13.551660156250023, -4.756738281250009],
            [13.478417968750023, -4.804980468750003],
            [13.414941406250023, -4.83740234375],
            [13.375781250000017, -4.829394531250003],
            [13.297265625000023, -4.765234375],
            [13.219628906250023, -4.705859375],
            [13.176464843750011, -4.655859375],
            [13.15234375, -4.620312500000011],
            [13.136621093750023, -4.604296875],
            [13.08740234375, -4.601953125],
            [13.07275390625, -4.634765625],
            [13.048046875000011, -4.619238281250006],
            [12.971386718750011, -4.5517578125],
            [12.881054687500011, -4.445117187500003],
            [12.84814453125, -4.428906250000011],
            [12.798242187500023, -4.430566406250009],
            [12.719433593750011, -4.4697265625],
            [12.641699218750006, -4.531152343750009],
            [12.50146484375, -4.5875],
            [12.384570312500017, -4.619140625],
            [12.3740234375, -4.65771484375],
            [12.3466796875, -4.72412109375],
            [12.307910156250017, -4.765527343750009],
            [12.204296875000011, -4.778613281250003],
            [12.167089843750006, -4.837695312500003],
            [12.077539062500023, -4.9521484375],
            [12.018359375000017, -5.004296875],
            [12.002734375000017, -4.98203125],
            [11.966796875, -4.954394531250003],
            [11.893261718750011, -4.86572265625],
            [11.820703125000023, -4.75546875],
            [11.80126953125, -4.705175781250006],
            [11.780859375, -4.6765625],
            [11.777539062500011, -4.565820312500009],
            [11.668066406250006, -4.434277343750011],
            [11.393847656250017, -4.200292968750006],
            [11.364453125000011, -4.130566406250011],
            [11.130175781250017, -3.916308593750003],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 2,
        sovereignt: "Democratic Republic of the Congo",
        sov_a3: "COD",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Democratic Republic of the Congo",
        adm0_a3: "COD",
        geou_dif: 0,
        geounit: "Democratic Republic of the Congo",
        gu_a3: "COD",
        su_dif: 0,
        subunit: "Democratic Republic of the Congo",
        su_a3: "COD",
        brk_diff: 0,
        name: "Dem. Rep. Congo",
        name_long: "Democratic Republic of the Congo",
        brk_a3: "COD",
        brk_name: "Democratic Republic of the Congo",
        brk_group: null,
        abbrev: "D.R.C.",
        postal: "DRC",
        formal_en: "Democratic Republic of the Congo",
        formal_fr: null,
        name_ciawf: "Congo, Democratic Republic of the",
        note_adm0: null,
        note_brk: null,
        name_sort: "Congo, Dem. Rep.",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 4,
        mapcolor9: 4,
        mapcolor13: 7,
        pop_est: 86790567,
        pop_rank: 16,
        pop_year: 2019,
        gdp_md: 50400,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        fips_10: "CG",
        iso_a2: "CD",
        iso_a2_eh: "CD",
        iso_a3: "COD",
        iso_a3_eh: "COD",
        iso_n3: "180",
        iso_n3_eh: "180",
        un_a3: "180",
        wb_a2: "ZR",
        wb_a3: "ZAR",
        woe_id: 23424780,
        woe_id_eh: 23424780,
        woe_note: "Exact WOE match as country",
        adm0_iso: "COD",
        adm0_diff: null,
        adm0_tlc: "COD",
        adm0_a3_us: "COD",
        adm0_a3_fr: "COD",
        adm0_a3_ru: "COD",
        adm0_a3_es: "COD",
        adm0_a3_cn: "COD",
        adm0_a3_tw: "COD",
        adm0_a3_in: "COD",
        adm0_a3_np: "COD",
        adm0_a3_pk: "COD",
        adm0_a3_de: "COD",
        adm0_a3_gb: "COD",
        adm0_a3_br: "COD",
        adm0_a3_il: "COD",
        adm0_a3_ps: "COD",
        adm0_a3_sa: "COD",
        adm0_a3_eg: "COD",
        adm0_a3_ma: "COD",
        adm0_a3_pt: "COD",
        adm0_a3_ar: "COD",
        adm0_a3_jp: "COD",
        adm0_a3_ko: "COD",
        adm0_a3_vn: "COD",
        adm0_a3_tr: "COD",
        adm0_a3_id: "COD",
        adm0_a3_pl: "COD",
        adm0_a3_gr: "COD",
        adm0_a3_it: "COD",
        adm0_a3_nl: "COD",
        adm0_a3_se: "COD",
        adm0_a3_bd: "COD",
        adm0_a3_ua: "COD",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Middle Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 15,
        long_len: 32,
        abbrev_len: 6,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 2,
        max_label: 7,
        label_x: 23.458829,
        label_y: -1.858167,
        ne_id: 1159320513,
        wikidataid: "Q974",
        name_ar: "جمهورية الكونغو الديمقراطية",
        name_bn: "গণতান্ত্রিক কঙ্গো প্রজাতন্ত্র",
        name_de: "Demokratische Republik Kongo",
        name_en: "Democratic Republic of the Congo",
        name_es: "República Democrática del Congo",
        name_fa: "جمهوری دموکراتیک کنگو",
        name_fr: "République démocratique du Congo",
        name_el: "Λαϊκή Δημοκρατία του Κονγκό",
        name_he: "הרפובליקה הדמוקרטית של קונגו",
        name_hi: "कांगो लोकतान्त्रिक गणराज्य",
        name_hu: "Kongói Demokratikus Köztársaság",
        name_id: "Republik Demokratik Kongo",
        name_it: "Repubblica Democratica del Congo",
        name_ja: "コンゴ民主共和国",
        name_ko: "콩고 민주 공화국",
        name_nl: "Congo-Kinshasa",
        name_pl: "Demokratyczna Republika Konga",
        name_pt: "República Democrática do Congo",
        name_ru: "Демократическая Республика Конго",
        name_sv: "Kongo-Kinshasa",
        name_tr: "Demokratik Kongo Cumhuriyeti",
        name_uk: "Демократична Республіка Конго",
        name_ur: "جمہوری جمہوریہ کانگو",
        name_vi: "Cộng hòa Dân chủ Congo",
        name_zh: "刚果民主共和国",
        name_zht: "剛果民主共和國",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "COD.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [30.75117187500001, -8.193652343750003],
            [30.577929687500017, -8.220019531250003],
            [30.327539062500023, -8.258203125],
            [30.051367187500006, -8.30029296875],
            [29.766210937500006, -8.34375],
            [29.483789062500023, -8.386914062500011],
            [29.215625, -8.427832031250006],
            [28.972265625, -8.464941406250006],
            [28.89814453125001, -8.485449218750006],
            [28.934472656250023, -8.590234375],
            [28.917773437500017, -8.700585937500009],
            [28.869531250000023, -8.785839843750011],
            [28.793554687500006, -8.891015625],
            [28.7587890625, -8.9326171875],
            [28.68125, -9.0146484375],
            [28.616503906250017, -9.072265625],
            [28.484277343750023, -9.16943359375],
            [28.400683593750017, -9.224804687500011],
            [28.400195312500017, -9.275],
            [28.54052734375, -9.510058593750003],
            [28.60419921875001, -9.678808593750006],
            [28.63007812500001, -9.83125],
            [28.62890625, -9.91875],
            [28.62353515625, -10.098828125000011],
            [28.6171875, -10.31298828125],
            [28.607421875, -10.397363281250009],
            [28.6455078125, -10.550195312500009],
            [28.63886718750001, -10.669238281250003],
            [28.544238281250017, -10.80234375],
            [28.517968750000023, -10.933203125],
            [28.4703125, -11.109570312500011],
            [28.404199218750023, -11.354394531250009],
            [28.357226562500017, -11.483007812500006],
            [28.383398437500006, -11.566699218750003],
            [28.407031250000017, -11.622851562500003],
            [28.43183593750001, -11.698339843750006],
            [28.482519531250006, -11.812109375],
            [28.541601562500006, -11.879199218750003],
            [28.574609375000023, -11.908105468750009],
            [28.769433593750023, -12.05126953125],
            [28.85, -12.120507812500009],
            [28.973437500000017, -12.2578125],
            [29.064355468750023, -12.348828125000011],
            [29.191210937500017, -12.370214843750006],
            [29.34375, -12.40478515625],
            [29.427539062500017, -12.43125],
            [29.48554687500001, -12.41845703125],
            [29.5048828125, -12.386132812500009],
            [29.502246093750017, -12.317578125000011],
            [29.491992187500017, -12.266894531250003],
            [29.508203125000023, -12.228222656250011],
            [29.55976562500001, -12.202441406250003],
            [29.691992187500006, -12.198339843750006],
            [29.749609375, -12.1640625],
            [29.79511718750001, -12.155468750000011],
            [29.795312500000023, -12.30615234375],
            [29.795507812500006, -12.450585937500009],
            [29.79560546875001, -12.625878906250009],
            [29.795800781250023, -12.827050781250009],
            [29.79609375000001, -12.992089843750009],
            [29.796289062500023, -13.16748046875],
            [29.796484375, -13.369726562500006],
            [29.795312500000023, -13.392773437500011],
            [29.775195312500017, -13.438085937500006],
            [29.72265625, -13.453808593750011],
            [29.651757812500023, -13.414355468750003],
            [29.64765625000001, -13.372949218750009],
            [29.630273437500023, -13.298535156250011],
            [29.59716796875, -13.260546875],
            [29.55419921875, -13.248925781250009],
            [29.4814453125, -13.26796875],
            [29.3818359375, -13.322851562500006],
            [29.253710937500017, -13.370800781250011],
            [29.20185546875001, -13.398339843750009],
            [29.111621093750017, -13.395117187500006],
            [29.014257812500006, -13.368847656250011],
            [28.942285156250023, -13.30712890625],
            [28.921679687500017, -13.214648437500003],
            [28.858789062500023, -13.119433593750003],
            [28.77314453125001, -12.98193359375],
            [28.730078125, -12.925488281250011],
            [28.672949218750006, -12.861328125],
            [28.61542968750001, -12.854101562500006],
            [28.550878906250006, -12.836132812500011],
            [28.51123046875, -12.7421875],
            [28.474414062500017, -12.623339843750003],
            [28.451464843750017, -12.577441406250003],
            [28.412890625000017, -12.51806640625],
            [28.357714843750017, -12.48203125],
            [28.2373046875, -12.4345703125],
            [28.06884765625, -12.3681640625],
            [27.857421875, -12.284863281250011],
            [27.7568359375, -12.280859375],
            [27.644335937500017, -12.266796875000011],
            [27.573828125, -12.22705078125],
            [27.53339843750001, -12.1953125],
            [27.48701171875001, -12.0796875],
            [27.423632812500017, -11.944531250000011],
            [27.238085937500017, -11.783496093750003],
            [27.196386718750006, -11.605078125],
            [27.1591796875, -11.579199218750006],
            [27.09541015625001, -11.59375],
            [27.04609375000001, -11.615917968750011],
            [27.026660156250017, -11.663769531250011],
            [26.976855468750017, -11.824609375],
            [26.949609375000023, -11.898828125],
            [26.93085937500001, -11.919335937500009],
            [26.890429687500017, -11.943554687500011],
            [26.824023437500017, -11.965234375],
            [26.72968750000001, -11.975976562500009],
            [26.59638671875001, -11.972070312500009],
            [26.4296875, -11.947851562500006],
            [26.339648437500017, -11.929882812500011],
            [26.09638671875001, -11.903222656250009],
            [26.025976562500006, -11.89013671875],
            [25.926562500000017, -11.855273437500003],
            [25.854882812500023, -11.820117187500003],
            [25.61884765625001, -11.744140625],
            [25.51191406250001, -11.75341796875],
            [25.4599609375, -11.699804687500006],
            [25.413378906250017, -11.673535156250011],
            [25.349414062500017, -11.623046875],
            [25.320703125000023, -11.553515625],
            [25.282617187500023, -11.404980468750011],
            [25.291796875000017, -11.325488281250003],
            [25.3193359375, -11.236914062500006],
            [25.28876953125001, -11.21240234375],
            [25.245996093750023, -11.21240234375],
            [25.184863281250017, -11.24296875],
            [25.075976562500017, -11.260058593750003],
            [24.876855468750023, -11.299121093750003],
            [24.80634765625001, -11.321191406250009],
            [24.728125, -11.337792968750009],
            [24.668261718750017, -11.352929687500009],
            [24.5185546875, -11.4384765625],
            [24.466601562500017, -11.44765625],
            [24.3779296875, -11.417089843750006],
            [24.33515625000001, -11.371289062500011],
            [24.3779296875, -11.3193359375],
            [24.396289062500017, -11.255175781250003],
            [24.36572265625, -11.1298828125],
            [24.319921875, -11.07177734375],
            [24.18720703125001, -11.029980468750011],
            [24.136523437500017, -11.025976562500006],
            [24.115136718750023, -10.955664062500006],
            [24.078417968750017, -10.891503906250009],
            [24.002734375000017, -10.879101562500011],
            [23.96650390625001, -10.871777343750011],
            [23.9287109375, -10.891503906250009],
            [23.907324218750006, -10.943457031250006],
            [23.901171875000017, -10.983203125],
            [23.833886718750023, -11.013671875],
            [23.696386718750006, -11.007617187500003],
            [23.559960937500023, -10.978613281250006],
            [23.463964843750006, -10.969335937500006],
            [23.400195312500017, -10.976464843750009],
            [23.15673828125, -11.074804687500006],
            [23.076269531250006, -11.087890625],
            [22.814746093750017, -11.080273437500011],
            [22.66650390625, -11.059765625000011],
            [22.56103515625, -11.055859375000011],
            [22.486132812500017, -11.08671875],
            [22.392968750000023, -11.159472656250003],
            [22.31494140625, -11.198632812500009],
            [22.27880859375, -11.194140625],
            [22.256640625000017, -11.163671875],
            [22.226171875, -11.121972656250009],
            [22.216699218750023, -11.0126953125],
            [22.17792968750001, -10.892285156250011],
            [22.203515625000023, -10.829492187500009],
            [22.28046875000001, -10.783984375],
            [22.307031250000023, -10.691308593750009],
            [22.283203125, -10.5515625],
            [22.281640625000023, -10.453320312500011],
            [22.30244140625001, -10.396679687500011],
            [22.274511718750006, -10.259082031250003],
            [22.19775390625, -10.040625],
            [22.089160156250017, -9.86279296875],
            [21.948632812500023, -9.7255859375],
            [21.85664062500001, -9.59423828125],
            [21.81318359375001, -9.46875],
            [21.829492187500023, -9.16845703125],
            [21.871875, -8.903515625000011],
            [21.905371093750006, -8.693359375],
            [21.895898437500023, -8.341113281250003],
            [21.800878906250006, -8.111914062500006],
            [21.780078125000017, -7.865429687500011],
            [21.83359375, -7.601660156250006],
            [21.841601562500017, -7.420996093750006],
            [21.806054687500023, -7.32861328125],
            [21.781640625000023, -7.314648437500011],
            [21.751074218750006, -7.30546875],
            [21.510839843750006, -7.296679687500003],
            [21.190332031250023, -7.284960937500003],
            [20.910937500000017, -7.281445312500011],
            [20.607812500000023, -7.277734375],
            [20.558398437500017, -7.244433593750003],
            [20.53583984375001, -7.182812500000011],
            [20.536914062500017, -7.121777343750011],
            [20.598730468750006, -6.93515625],
            [20.59003906250001, -6.919921875],
            [20.482226562500017, -6.915820312500003],
            [20.190039062500006, -6.9462890625],
            [19.997460937500023, -6.976464843750009],
            [19.87519531250001, -6.986328125],
            [19.66035156250001, -7.037109375],
            [19.527636718750017, -7.144433593750009],
            [19.483789062500023, -7.279492187500011],
            [19.487402343750006, -7.390722656250006],
            [19.479882812500023, -7.47216796875],
            [19.419335937500023, -7.557324218750011],
            [19.371679687500006, -7.655078125],
            [19.369921875000017, -7.70654296875],
            [19.3408203125, -7.966601562500003],
            [19.142675781250006, -8.00146484375],
            [18.944433593750006, -8.00146484375],
            [18.898339843750023, -7.998144531250006],
            [18.653417968750006, -7.93603515625],
            [18.56269531250001, -7.9359375],
            [18.484667968750017, -7.968554687500003],
            [18.334863281250023, -8.000292968750003],
            [18.191503906250006, -8.023828125],
            [18.047167968750017, -8.100781250000011],
            [18.0087890625, -8.107617187500011],
            [17.9130859375, -8.067675781250003],
            [17.77880859375, -8.071386718750006],
            [17.643359375000017, -8.090722656250009],
            [17.57958984375, -8.099023437500009],
            [17.536035156250023, -8.075878906250011],
            [17.41132812500001, -7.881933593750006],
            [17.245019531250023, -7.623339843750003],
            [17.155078125000017, -7.461328125],
            [17.12158203125, -7.419042968750006],
            [17.063769531250017, -7.363085937500003],
            [16.984765625000023, -7.257421875],
            [16.952050781250023, -7.15703125],
            [16.9658203125, -7.062109375],
            [16.91943359375, -6.933984375],
            [16.813085937500006, -6.772558593750006],
            [16.742968750000017, -6.618457031250003],
            [16.709375, -6.4716796875],
            [16.700976562500017, -6.345996093750003],
            [16.7177734375, -6.241406250000011],
            [16.697265625, -6.164257812500011],
            [16.639550781250023, -6.114550781250003],
            [16.608007812500006, -6.0515625],
            [16.58515625000001, -6.025292968750009],
            [16.537109375, -5.9658203125],
            [16.431445312500017, -5.900195312500003],
            [16.315234375000017, -5.865625],
            [16.06015625, -5.864941406250011],
            [15.726953125000023, -5.863867187500006],
            [15.425, -5.868847656250011],
            [15.08935546875, -5.87451171875],
            [14.749414062500023, -5.880078125000011],
            [14.657910156250011, -5.888867187500011],
            [14.398632812500011, -5.892675781250006],
            [14.190820312500023, -5.8759765625],
            [14.11376953125, -5.865136718750009],
            [13.978515625, -5.857226562500003],
            [13.764550781250023, -5.855175781250011],
            [13.649023437500006, -5.86171875],
            [13.371484375000023, -5.86181640625],
            [13.346484375000017, -5.863378906250006],
            [13.302636718750023, -5.8818359375],
            [13.184375, -5.85625],
            [13.068164062500017, -5.86484375],
            [13.003320312500023, -5.836132812500011],
            [12.86083984375, -5.854101562500006],
            [12.791601562500006, -5.877734375],
            [12.6806640625, -5.960839843750009],
            [12.514550781250023, -6.004199218750003],
            [12.452929687500017, -6.00048828125],
            [12.41171875, -5.986328125],
            [12.315039062500006, -5.8953125],
            [12.240429687500011, -5.807324218750011],
            [12.213671875000017, -5.758691406250009],
            [12.255273437500023, -5.746484375],
            [12.386035156250017, -5.727734375000011],
            [12.484570312500011, -5.71875],
            [12.503710937500017, -5.69580078125],
            [12.518945312500023, -5.424609375],
            [12.522363281250023, -5.14892578125],
            [12.487402343750006, -5.112695312500009],
            [12.453222656250006, -5.090625],
            [12.451464843750017, -5.071484375000011],
            [12.502734375000017, -5.036914062500003],
            [12.573535156250017, -4.99658203125],
            [12.59619140625, -4.978417968750009],
            [12.6748046875, -4.905371093750006],
            [12.8296875, -4.736621093750003],
            [12.947460937500011, -4.6953125],
            [13.057324218750011, -4.651074218750011],
            [13.07275390625, -4.634765625],
            [13.08740234375, -4.601953125],
            [13.136621093750023, -4.604296875],
            [13.15234375, -4.620312500000011],
            [13.176464843750011, -4.655859375],
            [13.219628906250023, -4.705859375],
            [13.297265625000023, -4.765234375],
            [13.375781250000017, -4.829394531250003],
            [13.414941406250023, -4.83740234375],
            [13.478417968750023, -4.804980468750003],
            [13.551660156250023, -4.756738281250009],
            [13.659570312500023, -4.721484375],
            [13.685351562500017, -4.688671875000011],
            [13.699414062500011, -4.618359375000011],
            [13.707617187500006, -4.543261718750003],
            [13.717089843750017, -4.454492187500009],
            [13.739062500000017, -4.442480468750006],
            [13.778027343750011, -4.433886718750003],
            [13.849511718750023, -4.458886718750009],
            [13.88232421875, -4.484667968750003],
            [13.94091796875, -4.484667968750003],
            [13.978417968750023, -4.461230468750003],
            [14.046875, -4.41748046875],
            [14.133886718750006, -4.4],
            [14.22705078125, -4.358105468750011],
            [14.316210937500017, -4.304101562500009],
            [14.358300781250023, -4.299414062500006],
            [14.402929687500006, -4.369726562500006],
            [14.442773437500023, -4.419042968750006],
            [14.449804687500006, -4.449511718750003],
            [14.409960937500017, -4.508105468750003],
            [14.365429687500011, -4.585546875],
            [14.402929687500006, -4.681640625],
            [14.411914062500017, -4.775],
            [14.410742187500006, -4.83125],
            [14.44091796875, -4.854101562500006],
            [14.461621093750011, -4.864941406250011],
            [14.493945312500017, -4.851660156250006],
            [14.5576171875, -4.855761718750003],
            [14.633984375000011, -4.885058593750003],
            [14.707910156250023, -4.881738281250009],
            [14.779296875, -4.845703125],
            [14.912109375, -4.70556640625],
            [15.10625, -4.461035156250006],
            [15.2671875, -4.3076171875],
            [15.394628906250006, -4.244921875],
            [15.48095703125, -4.171777343750009],
            [15.525976562500006, -4.087988281250006],
            [15.60009765625, -4.030957031250011],
            [15.754589843750011, -3.985546875000011],
            [15.872460937500023, -3.934277343750011],
            [15.990039062500017, -3.766210937500006],
            [16.146777343750017, -3.464160156250003],
            [16.190625, -3.194433593750006],
            [16.217382812500006, -3.0302734375],
            [16.20185546875001, -2.464746093750009],
            [16.19160156250001, -2.279101562500003],
            [16.21533203125, -2.177832031250006],
            [16.27392578125, -2.108203125],
            [16.43359375, -1.960839843750009],
            [16.54072265625001, -1.840136718750003],
            [16.622460937500023, -1.698925781250011],
            [16.780078125000017, -1.376367187500009],
            [16.84912109375, -1.2724609375],
            [16.8798828125, -1.225878906250003],
            [16.974707031250006, -1.139941406250003],
            [17.10761718750001, -1.064453125],
            [17.27880859375, -0.999609375],
            [17.542871093750023, -0.775],
            [17.752832031250023, -0.549023437500011],
            [17.72412109375, -0.277539062500011],
            [17.77314453125001, -0.052392578125009],
            [17.8876953125, 0.234130859375],
            [17.925195312500023, 0.537304687499997],
            [17.8857421875, 0.856884765624997],
            [17.902441406250006, 1.118066406249994],
            [18.01171875, 1.422119140625],
            [18.05781250000001, 1.534863281249997],
            [18.072851562500006, 1.719384765624994],
            [18.072167968750023, 2.01328125],
            [18.21162109375001, 2.414941406249994],
            [18.34345703125001, 2.655419921874994],
            [18.49091796875001, 2.924414062499991],
            [18.54707031250001, 3.087011718749991],
            [18.622167968750006, 3.304052734374991],
            [18.6103515625, 3.478417968749994],
            [18.5966796875, 3.6787109375],
            [18.633691406250023, 3.954296875],
            [18.619921875000017, 4.116601562499994],
            [18.567480468750006, 4.257568359375],
            [18.594140625000023, 4.346240234374989],
            [18.69990234375001, 4.382617187499989],
            [18.83173828125001, 4.5234375],
            [19.06855468750001, 4.891406249999989],
            [19.32343750000001, 5.070751953124997],
            [19.5009765625, 5.127490234374989],
            [19.68603515625, 5.121386718749989],
            [19.806542968750023, 5.089306640624997],
            [19.8625, 5.031298828124989],
            [20.002343750000023, 4.944726562499994],
            [20.226367187500017, 4.829638671874989],
            [20.3935546875, 4.686181640624994],
            [20.48652343750001, 4.541552734374989],
            [20.55810546875, 4.462695312499989],
            [20.6474609375, 4.435644531249991],
            [20.79296875, 4.447314453124989],
            [20.95576171875001, 4.413134765624989],
            [21.125585937500006, 4.332177734374994],
            [21.229785156250017, 4.302197265624997],
            [21.268359375000017, 4.323095703124991],
            [21.350195312500006, 4.311376953124991],
            [21.53759765625, 4.244824218749997],
            [21.68701171875, 4.281396484374994],
            [21.908203125, 4.25390625],
            [22.422167968750017, 4.134960937499997],
            [22.44970703125, 4.155126953124991],
            [22.461816406250023, 4.159765625],
            [22.505664062500017, 4.207666015624994],
            [22.6171875, 4.445556640625],
            [22.711718750000017, 4.591748046874997],
            [22.755761718750023, 4.646679687499997],
            [22.864550781250017, 4.723876953125],
            [22.99287109375001, 4.743847656249997],
            [23.11591796875001, 4.736914062499991],
            [23.218847656250006, 4.702978515624991],
            [23.312890625000023, 4.663525390624997],
            [23.41718750000001, 4.663134765624989],
            [23.52363281250001, 4.701269531249991],
            [23.68183593750001, 4.770800781249989],
            [23.848437500000017, 4.816357421874997],
            [23.99169921875, 4.866259765624989],
            [24.22773437500001, 4.953857421875],
            [24.31982421875, 4.994140625],
            [24.437109375, 5.009960937499997],
            [24.765527343750023, 4.930078125],
            [24.978417968750023, 4.982958984374989],
            [25.065234375000017, 4.967431640624994],
            [25.249316406250017, 5.024560546874994],
            [25.283105468750023, 5.062695312499997],
            [25.400195312500017, 5.255908203124989],
            [25.52509765625001, 5.312109375],
            [25.7138671875, 5.28369140625],
            [25.819921875, 5.253710937499989],
            [26.17353515625001, 5.171142578125],
            [26.632617187500017, 5.085205078125],
            [26.767578125, 5.071923828124994],
            [26.822070312500017, 5.062402343749994],
            [26.8701171875, 5.07568359375],
            [27.020605468750006, 5.184375],
            [27.071875, 5.199755859374989],
            [27.11494140625001, 5.197851562499991],
            [27.4033203125, 5.109179687499989],
            [27.439257812500017, 5.039208984374994],
            [27.491015625000017, 4.967578124999989],
            [27.664160156250006, 4.845996093749989],
            [27.71923828125, 4.7783203125],
            [27.76142578125001, 4.703222656249991],
            [27.7880859375, 4.644677734374994],
            [27.841601562500017, 4.597753906249991],
            [27.916601562500006, 4.567919921874989],
            [27.98066406250001, 4.532080078124991],
            [28.019824218750017, 4.479394531249994],
            [28.07861328125, 4.4248046875],
            [28.19208984375001, 4.350244140624994],
            [28.24726562500001, 4.348535156249994],
            [28.31103515625, 4.338037109374994],
            [28.3671875, 4.318652343749989],
            [28.427539062500017, 4.324169921874997],
            [28.524804687500023, 4.372851562499989],
            [28.639550781250023, 4.454492187499994],
            [28.72705078125, 4.504980468749991],
            [28.939355468750023, 4.487060546875],
            [29.057421875000017, 4.445947265624994],
            [29.151464843750006, 4.38818359375],
            [29.224902343750017, 4.391894531249989],
            [29.384863281250006, 4.498388671874991],
            [29.469628906250023, 4.61181640625],
            [29.552050781250017, 4.636035156249989],
            [29.676855468750006, 4.5869140625],
            [29.779882812500006, 4.48095703125],
            [29.870214843750006, 4.3271484375],
            [29.933984375000023, 4.268505859374997],
            [30.021386718750023, 4.177636718749994],
            [30.194921875, 3.98193359375],
            [30.420703125000017, 3.883886718749991],
            [30.50830078125, 3.835693359375],
            [30.536914062500017, 3.787207031249991],
            [30.553515625000017, 3.722949218749989],
            [30.559375, 3.652783203124997],
            [30.586718750000017, 3.62421875],
            [30.64765625000001, 3.634130859374991],
            [30.69990234375001, 3.644091796874989],
            [30.757226562500023, 3.62421875],
            [30.796972656250006, 3.573144531249994],
            [30.81689453125, 3.533349609374994],
            [30.83857421875001, 3.49072265625],
            [30.895312500000017, 3.463671874999989],
            [30.90644531250001, 3.408935546875],
            [30.867578125000023, 3.342138671874991],
            [30.82783203125001, 3.282617187499994],
            [30.779296875, 3.163378906249989],
            [30.754003906250006, 3.041796874999989],
            [30.786523437500023, 3.001367187499994],
            [30.821386718750006, 2.967578124999989],
            [30.839941406250006, 2.933496093749994],
            [30.85078125000001, 2.893652343749991],
            [30.8466796875, 2.847021484374991],
            [30.76953125, 2.677978515625],
            [30.729882812500023, 2.5302734375],
            [30.728613281250006, 2.455371093749989],
            [30.830078125, 2.400439453124989],
            [30.9619140625, 2.403271484374997],
            [31.00361328125001, 2.369384765625],
            [31.045312500000023, 2.315527343749991],
            [31.082128906250006, 2.2880859375],
            [31.137597656250023, 2.288867187499989],
            [31.176367187500006, 2.270068359374989],
            [31.19140625, 2.232275390624991],
            [31.236328125, 2.191357421874997],
            [31.274023437500006, 2.146289062499989],
            [31.25605468750001, 2.088476562499991],
            [31.252734375000017, 2.044580078124994],
            [31.158789062500006, 1.922021484374994],
            [30.94257812500001, 1.6828125],
            [30.478125, 1.239062499999989],
            [30.477832031250017, 1.238818359374989],
            [30.321093750000017, 1.185302734375],
            [30.240136718750023, 1.102783203125],
            [30.182910156250017, 0.973486328124991],
            [30.04736328125, 0.863525390625],
            [29.94287109375, 0.819238281249994],
            [29.931640625, 0.792871093749994],
            [29.923828125, 0.673925781249991],
            [29.934472656250023, 0.4990234375],
            [29.88544921875001, 0.4189453125],
            [29.81464843750001, 0.263623046874997],
            [29.77783203125, 0.166357421874991],
            [29.74970703125001, 0.147216796875],
            [29.717675781250023, 0.098339843749997],
            [29.697851562500006, -0.060205078125009],
            [29.684375, -0.113574218750003],
            [29.633203125000023, -0.441699218750003],
            [29.647851562500023, -0.535253906250006],
            [29.608203125000017, -0.691308593750009],
            [29.6064453125, -0.783105468750009],
            [29.59003906250001, -0.887109375],
            [29.561914062500023, -0.97734375],
            [29.5640625, -1.121386718750003],
            [29.579980468750023, -1.356738281250003],
            [29.576953125000017, -1.387890625000011],
            [29.53779296875001, -1.409765625],
            [29.46796875000001, -1.468066406250003],
            [29.401953125, -1.507421875],
            [29.351660156250006, -1.517578125],
            [29.268164062500006, -1.62158203125],
            [29.196582031250017, -1.719921875000011],
            [29.14326171875001, -1.816015625],
            [29.12939453125, -1.860253906250009],
            [29.140625, -1.984570312500011],
            [29.148046875, -2.1318359375],
            [29.13154296875001, -2.195117187500003],
            [29.1064453125, -2.233203125],
            [28.989550781250017, -2.312792968750003],
            [28.912695312500006, -2.370312500000011],
            [28.876367187500023, -2.400292968750009],
            [28.85761718750001, -2.446679687500009],
            [28.891406250000017, -2.555566406250009],
            [28.893945312500023, -2.635058593750003],
            [28.921777343750023, -2.68203125],
            [29.01435546875001, -2.72021484375],
            [29.01416015625, -2.75830078125],
            [29.0166015625, -2.799609375],
            [29.064746093750017, -2.850781250000011],
            [29.153222656250023, -2.955273437500011],
            [29.224414062500017, -3.053515625],
            [29.22607421875, -3.138671875],
            [29.212304687500023, -3.28125],
            [29.210058593750006, -3.36328125],
            [29.217187500000023, -3.475683593750006],
            [29.216796875, -3.684960937500009],
            [29.211816406250023, -3.833789062500003],
            [29.223242187500006, -3.910839843750011],
            [29.331347656250017, -4.095410156250011],
            [29.379199218750017, -4.299707031250009],
            [29.403222656250023, -4.449316406250006],
            [29.404199218750023, -4.496679687500006],
            [29.367578125000023, -4.668847656250009],
            [29.32568359375, -4.835644531250011],
            [29.32343750000001, -4.898828125],
            [29.3427734375, -4.983105468750011],
            [29.42011718750001, -5.176171875],
            [29.476464843750023, -5.316601562500011],
            [29.503710937500017, -5.400976562500006],
            [29.542382812500023, -5.499804687500003],
            [29.594140625000023, -5.65078125],
            [29.60703125, -5.72265625],
            [29.59638671875001, -5.775976562500006],
            [29.490820312500006, -5.965429687500006],
            [29.480078125, -6.025],
            [29.50625, -6.172070312500011],
            [29.540820312500017, -6.313867187500009],
            [29.590625, -6.394433593750009],
            [29.70966796875001, -6.616894531250011],
            [29.798144531250017, -6.69189453125],
            [29.961816406250023, -6.803125],
            [30.10625, -6.9150390625],
            [30.16181640625001, -6.973046875],
            [30.212695312500017, -7.037890625],
            [30.31318359375001, -7.203710937500006],
            [30.37451171875, -7.338671875],
            [30.40673828125, -7.460644531250011],
            [30.485644531250017, -7.627148437500011],
            [30.558886718750017, -7.781933593750011],
            [30.65380859375, -7.970898437500011],
            [30.72089843750001, -8.104394531250009],
            [30.75117187500001, -8.193652343750003],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Tunisia",
        sov_a3: "TUN",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Tunisia",
        adm0_a3: "TUN",
        geou_dif: 0,
        geounit: "Tunisia",
        gu_a3: "TUN",
        su_dif: 0,
        subunit: "Tunisia",
        su_a3: "TUN",
        brk_diff: 0,
        name: "Tunisia",
        name_long: "Tunisia",
        brk_a3: "TUN",
        brk_name: "Tunisia",
        brk_group: null,
        abbrev: "Tun.",
        postal: "TN",
        formal_en: "Republic of Tunisia",
        formal_fr: null,
        name_ciawf: "Tunisia",
        note_adm0: null,
        note_brk: null,
        name_sort: "Tunisia",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 3,
        mapcolor9: 3,
        mapcolor13: 2,
        pop_est: 11694719,
        pop_rank: 14,
        pop_year: 2019,
        gdp_md: 38796,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        fips_10: "TS",
        iso_a2: "TN",
        iso_a2_eh: "TN",
        iso_a3: "TUN",
        iso_a3_eh: "TUN",
        iso_n3: "788",
        iso_n3_eh: "788",
        un_a3: "788",
        wb_a2: "TN",
        wb_a3: "TUN",
        woe_id: 23424967,
        woe_id_eh: 23424967,
        woe_note: "Exact WOE match as country",
        adm0_iso: "TUN",
        adm0_diff: null,
        adm0_tlc: "TUN",
        adm0_a3_us: "TUN",
        adm0_a3_fr: "TUN",
        adm0_a3_ru: "TUN",
        adm0_a3_es: "TUN",
        adm0_a3_cn: "TUN",
        adm0_a3_tw: "TUN",
        adm0_a3_in: "TUN",
        adm0_a3_np: "TUN",
        adm0_a3_pk: "TUN",
        adm0_a3_de: "TUN",
        adm0_a3_gb: "TUN",
        adm0_a3_br: "TUN",
        adm0_a3_il: "TUN",
        adm0_a3_ps: "TUN",
        adm0_a3_sa: "TUN",
        adm0_a3_eg: "TUN",
        adm0_a3_ma: "TUN",
        adm0_a3_pt: "TUN",
        adm0_a3_ar: "TUN",
        adm0_a3_jp: "TUN",
        adm0_a3_ko: "TUN",
        adm0_a3_vn: "TUN",
        adm0_a3_tr: "TUN",
        adm0_a3_id: "TUN",
        adm0_a3_pl: "TUN",
        adm0_a3_gr: "TUN",
        adm0_a3_it: "TUN",
        adm0_a3_nl: "TUN",
        adm0_a3_se: "TUN",
        adm0_a3_bd: "TUN",
        adm0_a3_ua: "TUN",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Northern Africa",
        region_wb: "Middle East & North Africa",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: 9.007881,
        label_y: 33.687263,
        ne_id: 1159321327,
        wikidataid: "Q948",
        name_ar: "تونس",
        name_bn: "তিউনিসিয়া",
        name_de: "Tunesien",
        name_en: "Tunisia",
        name_es: "Túnez",
        name_fa: "تونس",
        name_fr: "Tunisie",
        name_el: "Τυνησία",
        name_he: "תוניסיה",
        name_hi: "ट्यूनिशिया",
        name_hu: "Tunézia",
        name_id: "Tunisia",
        name_it: "Tunisia",
        name_ja: "チュニジア",
        name_ko: "튀니지",
        name_nl: "Tunesië",
        name_pl: "Tunezja",
        name_pt: "Tunísia",
        name_ru: "Тунис",
        name_sv: "Tunisien",
        name_tr: "Tunus",
        name_uk: "Туніс",
        name_ur: "تونس",
        name_vi: "Tuy-ni-di",
        name_zh: "突尼斯",
        name_zht: "突尼西亞",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "TUN.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [11.504589843750011, 33.181933593749996],
              [11.50244140625, 33.155566406249996],
              [11.467187500000023, 32.965722656249994],
              [11.459179687500011, 32.897363281249994],
              [11.453906250000017, 32.781689453125],
              [11.453906250000017, 32.642578125],
              [11.533789062500006, 32.524951171874996],
              [11.535937500000017, 32.47333984375],
              [11.504980468750006, 32.413671875],
              [11.358007812500006, 32.34521484375],
              [11.168261718750017, 32.256738281249994],
              [11.005175781250017, 32.172705078125],
              [10.826367187500011, 32.0806640625],
              [10.771582031250006, 32.02119140625],
              [10.683007812500023, 31.975390625],
              [10.60888671875, 31.929541015625],
              [10.595507812500017, 31.8857421875],
              [10.543652343750011, 31.802539062499996],
              [10.475781250000011, 31.736035156249997],
              [10.306054687500023, 31.704833984375],
              [10.274609375000011, 31.684960937499994],
              [10.195996093750011, 31.585107421874994],
              [10.159863281250011, 31.545800781249994],
              [10.114941406250011, 31.463769531249994],
              [10.172656250000017, 31.2509765625],
              [10.243359375000011, 31.032128906249994],
              [10.257031250000011, 30.940820312499994],
              [10.256054687500011, 30.864941406249997],
              [10.21640625, 30.783203125],
              [10.1259765625, 30.665966796874997],
              [10.059765625000011, 30.580078125],
              [9.932519531250023, 30.425341796874996],
              [9.89501953125, 30.3873046875],
              [9.807421875000017, 30.342236328124997],
              [9.637988281250017, 30.28232421875],
              [9.51875, 30.229394531249994],
              [9.4580078125, 30.465380859374996],
              [9.406054687500017, 30.666796875],
              [9.36328125, 30.832910156249994],
              [9.287890625000017, 31.125341796875],
              [9.224023437500023, 31.373681640624994],
              [9.160253906250006, 31.621337890625],
              [9.102343750000017, 31.846142578124997],
              [9.044042968750006, 32.07236328125],
              [9.018945312500023, 32.105371093749994],
              [8.844042968750017, 32.212109375],
              [8.682910156250017, 32.310449218749994],
              [8.51513671875, 32.422314453125],
              [8.333398437500023, 32.543603515624994],
              [8.30419921875, 32.6962890625],
              [8.2109375, 32.926708984375],
              [8.1125, 33.055322265624994],
              [8.075585937500023, 33.0890625],
              [7.877246093750017, 33.172119140625],
              [7.7626953125, 33.23310546875],
              [7.731347656250023, 33.268505859375],
              [7.709179687500011, 33.3623046875],
              [7.627539062500006, 33.548632812499996],
              [7.534375, 33.717919921874994],
              [7.500195312500011, 33.832470703125],
              [7.49560546875, 33.976513671875],
              [7.513867187500011, 34.080517578125],
              [7.554492187500017, 34.125],
              [7.74853515625, 34.2544921875],
              [7.838281250000023, 34.410302734374994],
              [7.949414062500011, 34.468701171875],
              [8.045605468750011, 34.5126953125],
              [8.123437500000023, 34.563916015625],
              [8.192773437500023, 34.646289062499996],
              [8.24560546875, 34.73408203125],
              [8.254687500000017, 34.828955078125],
              [8.27685546875, 34.9794921875],
              [8.312109375, 35.084619140624994],
              [8.394238281250011, 35.203857421875],
              [8.35986328125, 35.299609375],
              [8.31640625, 35.403125],
              [8.329003906250023, 35.5822265625],
              [8.318066406250011, 35.654931640624994],
              [8.282910156250011, 35.719287109374996],
              [8.2470703125, 35.801806640624996],
              [8.245703125, 35.870556640625],
              [8.2802734375, 36.0509765625],
              [8.306738281250006, 36.188769531249996],
              [8.348730468750006, 36.36796875],
              [8.333984375, 36.4181640625],
              [8.302734375, 36.455615234374996],
              [8.208789062500017, 36.4951171875],
              [8.207617187500006, 36.518945312499994],
              [8.230761718750017, 36.545263671875],
              [8.36962890625, 36.63251953125],
              [8.444238281250023, 36.7607421875],
              [8.506738281250023, 36.7875],
              [8.601269531250011, 36.833935546875],
              [8.59765625, 36.88388671875],
              [8.576562500000023, 36.93720703125],
              [8.823535156250017, 36.997607421874996],
              [9.058886718750017, 37.155859375],
              [9.141992187500023, 37.194628906249996],
              [9.68798828125, 37.340380859374996],
              [9.758886718750006, 37.3302734375],
              [9.838476562500006, 37.308984375],
              [9.815527343750006, 37.254638671875],
              [9.783984375000017, 37.21142578125],
              [9.830273437500011, 37.1353515625],
              [9.896386718750023, 37.181640625],
              [9.87939453125, 37.212841796875],
              [9.875585937500006, 37.254150390625],
              [9.988085937500017, 37.257763671875],
              [10.08740234375, 37.251269531249996],
              [10.196386718750006, 37.205859375],
              [10.188769531250017, 37.03388671875],
              [10.334082031250006, 36.865380859374994],
              [10.293261718750017, 36.781494140625],
              [10.412304687500011, 36.731835937499994],
              [10.518164062500006, 36.791357421875],
              [10.5712890625, 36.879443359374996],
              [10.766210937500006, 36.9302734375],
              [10.951367187500011, 37.05927734375],
              [11.053906250000011, 37.072509765625],
              [11.077050781250023, 36.966699218749994],
              [11.126660156250011, 36.874072265624996],
              [11.056542968750023, 36.841455078124994],
              [10.967187500000023, 36.743017578125],
              [10.798144531250017, 36.493115234375],
              [10.642382812500017, 36.41962890625],
              [10.525683593750017, 36.32333984375],
              [10.487988281250011, 36.2548828125],
              [10.4765625, 36.175146484375],
              [10.505761718750023, 36.032421875],
              [10.5908203125, 35.887255859374996],
              [10.68896484375, 35.79951171875],
              [10.78369140625, 35.7720703125],
              [11.004296875000023, 35.633837890624996],
              [11.000683593750011, 35.551611328125],
              [11.031542968750017, 35.453857421875],
              [11.043261718750017, 35.335107421874994],
              [11.1201171875, 35.240283203124996],
              [10.955859375000017, 35.033642578125],
              [10.8662109375, 34.884326171874996],
              [10.69091796875, 34.678466796875],
              [10.534863281250011, 34.544726562499996],
              [10.200390625000011, 34.346044921875],
              [10.118359375000011, 34.280078125],
              [10.064843750000023, 34.21162109375],
              [10.0400390625, 34.14033203125],
              [10.049023437500011, 34.056298828124994],
              [10.158984375000017, 33.850048828125],
              [10.305273437500006, 33.728271484375],
              [10.454296875000011, 33.6625],
              [10.713183593750017, 33.689013671874996],
              [10.704296875000011, 33.609667968749996],
              [10.722753906250006, 33.514404296875],
              [10.828125, 33.518896484375],
              [10.8984375, 33.53369140625],
              [10.9580078125, 33.626318359375],
              [11.084570312500006, 33.562890625],
              [11.150292968750023, 33.36923828125],
              [11.257421875, 33.308837890625],
              [11.269921875000023, 33.286328125],
              [11.232128906250011, 33.27158203125],
              [11.20263671875, 33.24921875],
              [11.234277343750023, 33.23359375],
              [11.338085937500011, 33.20947265625],
              [11.400585937500011, 33.224902343749996],
              [11.504589843750011, 33.181933593749996],
            ],
          ],
          [
            [
              [11.278027343750011, 34.753808593749994],
              [11.123632812500006, 34.681689453124996],
              [11.153027343750011, 34.744580078125],
              [11.2548828125, 34.8203125],
              [11.281054687500017, 34.802197265625],
              [11.278027343750011, 34.753808593749994],
            ],
          ],
          [
            [
              [10.957617187500006, 33.722070312499994],
              [10.931347656250011, 33.717431640624994],
              [10.883007812500011, 33.690185546875],
              [10.857421875, 33.687158203124994],
              [10.784765625, 33.717675781249994],
              [10.757031250000011, 33.71748046875],
              [10.722070312500023, 33.738916015624994],
              [10.73388671875, 33.855615234374994],
              [10.745214843750006, 33.888671875],
              [10.921972656250006, 33.893115234374996],
              [11.017871093750017, 33.82333984375],
              [11.033593750000023, 33.805029296875],
              [11.03759765625, 33.785058593749994],
              [10.993066406250023, 33.745947265625],
              [10.957617187500006, 33.722070312499994],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Libya",
        sov_a3: "LBY",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Libya",
        adm0_a3: "LBY",
        geou_dif: 0,
        geounit: "Libya",
        gu_a3: "LBY",
        su_dif: 0,
        subunit: "Libya",
        su_a3: "LBY",
        brk_diff: 0,
        name: "Libya",
        name_long: "Libya",
        brk_a3: "LBY",
        brk_name: "Libya",
        brk_group: null,
        abbrev: "Libya",
        postal: "LY",
        formal_en: "Libya",
        formal_fr: null,
        name_ciawf: "Libya",
        note_adm0: null,
        note_brk: null,
        name_sort: "Libya",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 2,
        mapcolor9: 2,
        mapcolor13: 11,
        pop_est: 6777452,
        pop_rank: 13,
        pop_year: 2019,
        gdp_md: 52091,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        fips_10: "LY",
        iso_a2: "LY",
        iso_a2_eh: "LY",
        iso_a3: "LBY",
        iso_a3_eh: "LBY",
        iso_n3: "434",
        iso_n3_eh: "434",
        un_a3: "434",
        wb_a2: "LY",
        wb_a3: "LBY",
        woe_id: 23424882,
        woe_id_eh: 23424882,
        woe_note: "Exact WOE match as country",
        adm0_iso: "LBY",
        adm0_diff: null,
        adm0_tlc: "LBY",
        adm0_a3_us: "LBY",
        adm0_a3_fr: "LBY",
        adm0_a3_ru: "LBY",
        adm0_a3_es: "LBY",
        adm0_a3_cn: "LBY",
        adm0_a3_tw: "LBY",
        adm0_a3_in: "LBY",
        adm0_a3_np: "LBY",
        adm0_a3_pk: "LBY",
        adm0_a3_de: "LBY",
        adm0_a3_gb: "LBY",
        adm0_a3_br: "LBY",
        adm0_a3_il: "LBY",
        adm0_a3_ps: "LBY",
        adm0_a3_sa: "LBY",
        adm0_a3_eg: "LBY",
        adm0_a3_ma: "LBY",
        adm0_a3_pt: "LBY",
        adm0_a3_ar: "LBY",
        adm0_a3_jp: "LBY",
        adm0_a3_ko: "LBY",
        adm0_a3_vn: "LBY",
        adm0_a3_tr: "LBY",
        adm0_a3_id: "LBY",
        adm0_a3_pl: "LBY",
        adm0_a3_gr: "LBY",
        adm0_a3_it: "LBY",
        adm0_a3_nl: "LBY",
        adm0_a3_se: "LBY",
        adm0_a3_bd: "LBY",
        adm0_a3_ua: "LBY",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Northern Africa",
        region_wb: "Middle East & North Africa",
        name_len: 5,
        long_len: 5,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: 18.011015,
        label_y: 26.638944,
        ne_id: 1159321017,
        wikidataid: "Q1016",
        name_ar: "ليبيا",
        name_bn: "লিবিয়া",
        name_de: "Libyen",
        name_en: "Libya",
        name_es: "Libia",
        name_fa: "لیبی",
        name_fr: "Libye",
        name_el: "Λιβύη",
        name_he: "לוב",
        name_hi: "लीबिया",
        name_hu: "Líbia",
        name_id: "Libya",
        name_it: "Libia",
        name_ja: "リビア",
        name_ko: "리비아",
        name_nl: "Libië",
        name_pl: "Libia",
        name_pt: "Líbia",
        name_ru: "Ливия",
        name_sv: "Libyen",
        name_tr: "Libya",
        name_uk: "Лівія",
        name_ur: "لیبیا",
        name_vi: "Libya",
        name_zh: "利比亚",
        name_zht: "利比亞",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "LBY.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [9.51875, 30.229394531249994],
            [9.637988281250017, 30.28232421875],
            [9.807421875000017, 30.342236328124997],
            [9.89501953125, 30.3873046875],
            [9.932519531250023, 30.425341796874996],
            [10.059765625000011, 30.580078125],
            [10.1259765625, 30.665966796874997],
            [10.21640625, 30.783203125],
            [10.256054687500011, 30.864941406249997],
            [10.257031250000011, 30.940820312499994],
            [10.243359375000011, 31.032128906249994],
            [10.172656250000017, 31.2509765625],
            [10.114941406250011, 31.463769531249994],
            [10.159863281250011, 31.545800781249994],
            [10.195996093750011, 31.585107421874994],
            [10.274609375000011, 31.684960937499994],
            [10.306054687500023, 31.704833984375],
            [10.475781250000011, 31.736035156249997],
            [10.543652343750011, 31.802539062499996],
            [10.595507812500017, 31.8857421875],
            [10.60888671875, 31.929541015625],
            [10.683007812500023, 31.975390625],
            [10.771582031250006, 32.02119140625],
            [10.826367187500011, 32.0806640625],
            [11.005175781250017, 32.172705078125],
            [11.168261718750017, 32.256738281249994],
            [11.358007812500006, 32.34521484375],
            [11.504980468750006, 32.413671875],
            [11.535937500000017, 32.47333984375],
            [11.533789062500006, 32.524951171874996],
            [11.453906250000017, 32.642578125],
            [11.453906250000017, 32.781689453125],
            [11.459179687500011, 32.897363281249994],
            [11.467187500000023, 32.965722656249994],
            [11.50244140625, 33.155566406249996],
            [11.504589843750011, 33.181933593749996],
            [11.657128906250023, 33.118896484375],
            [11.8134765625, 33.093701171875],
            [12.279882812500006, 32.858544921874994],
            [12.427050781250017, 32.8291015625],
            [12.753515625, 32.801074218749996],
            [13.138085937500023, 32.897363281249994],
            [13.283496093750017, 32.9146484375],
            [13.536328125000011, 32.824267578124996],
            [13.647753906250017, 32.798828125],
            [13.835351562500023, 32.791796875],
            [14.155664062500023, 32.709765625],
            [14.237109375000017, 32.68125],
            [14.423828125, 32.55029296875],
            [14.513378906250011, 32.511083984375],
            [15.176562500000017, 32.391162109374996],
            [15.266894531250017, 32.311669921874994],
            [15.359082031250011, 32.15966796875],
            [15.363085937500017, 31.97119140625],
            [15.4140625, 31.834228515625],
            [15.496386718750017, 31.656787109374996],
            [15.595800781250006, 31.531103515625],
            [15.705957031250023, 31.426416015624994],
            [15.832226562500011, 31.360986328124994],
            [16.123046875, 31.264453125],
            [16.450976562500017, 31.227294921875],
            [16.781542968750017, 31.214746093749994],
            [17.34921875, 31.081494140624997],
            [17.830468750000023, 30.927587890625],
            [17.949316406250006, 30.851904296875],
            [18.1904296875, 30.777294921874997],
            [18.669824218750023, 30.415673828124994],
            [18.936425781250023, 30.290429687499994],
            [19.12373046875001, 30.26611328125],
            [19.29169921875001, 30.2880859375],
            [19.58984375, 30.413769531249997],
            [19.713281250000023, 30.48837890625],
            [20.01318359375, 30.800683593749994],
            [20.11152343750001, 30.963720703125],
            [20.150976562500006, 31.07861328125],
            [20.14111328125, 31.195507812499997],
            [20.103808593750017, 31.300537109375],
            [20.02001953125, 31.41064453125],
            [19.961230468750017, 31.556005859375],
            [19.926367187500006, 31.817529296874994],
            [19.973437500000017, 31.999072265624996],
            [20.03095703125001, 32.107861328125],
            [20.121484375000023, 32.21875],
            [20.37060546875, 32.43076171875],
            [20.62109375, 32.58017578125],
            [21.062304687500017, 32.775537109374994],
            [21.31875, 32.777685546875],
            [21.424707031250023, 32.799169921875],
            [21.63593750000001, 32.937304687499996],
            [21.72138671875001, 32.94248046875],
            [21.839453125, 32.908642578125],
            [22.187402343750023, 32.918261718749996],
            [22.340625, 32.8798828125],
            [22.5234375, 32.7939453125],
            [22.75410156250001, 32.740527343749996],
            [22.916894531250023, 32.687158203124994],
            [23.090625, 32.61875],
            [23.129687500000017, 32.448144531249994],
            [23.110449218750006, 32.397412109375],
            [23.10625, 32.331445312499994],
            [23.28632812500001, 32.213818359375],
            [23.797656250000017, 32.15869140625],
            [23.8984375, 32.127197265625],
            [24.038964843750023, 32.037011718749994],
            [24.129687500000017, 32.009228515625],
            [24.479785156250017, 31.996533203124997],
            [24.683886718750017, 32.015966796875],
            [24.878515625, 31.984277343749994],
            [24.95068359375, 31.9537109375],
            [25.025, 31.883349609374996],
            [25.115039062500017, 31.712304687499994],
            [25.150488281250006, 31.654980468749997],
            [25.11201171875001, 31.626904296874997],
            [25.057226562500006, 31.5671875],
            [25.02265625000001, 31.514013671875],
            [24.929980468750017, 31.427490234375],
            [24.85273437500001, 31.334814453125],
            [24.859960937500006, 31.199169921874997],
            [24.877539062500006, 31.061230468749997],
            [24.929492187500017, 30.926464843749997],
            [24.973925781250017, 30.7765625],
            [24.96142578125, 30.678515625],
            [24.92304687500001, 30.558007812499994],
            [24.877539062500006, 30.45751953125],
            [24.726464843750023, 30.2505859375],
            [24.703222656250006, 30.201074218749994],
            [24.71162109375001, 30.131542968749997],
            [24.8037109375, 29.886035156249996],
            [24.810839843750017, 29.808740234374994],
            [24.86591796875001, 29.570263671874997],
            [24.916113281250006, 29.376269531249996],
            [24.9716796875, 29.223828125],
            [24.980273437500017, 29.181884765625],
            [24.980273437500017, 28.957324218749996],
            [24.980273437500017, 28.732763671875],
            [24.980273437500017, 28.508203125],
            [24.980273437500017, 28.283642578124997],
            [24.980273437500017, 28.05908203125],
            [24.980273437500017, 27.834521484374996],
            [24.980273437500017, 27.6099609375],
            [24.980273437500017, 27.385400390624994],
            [24.980273437500017, 27.160839843749997],
            [24.980273437500017, 26.936230468749997],
            [24.980273437500017, 26.711669921875],
            [24.980273437500017, 26.487109375],
            [24.980273437500017, 26.262548828125],
            [24.980273437500017, 26.037988281249994],
            [24.980273437500017, 25.813427734374997],
            [24.980273437500017, 25.5888671875],
            [24.980273437500017, 25.364306640625003],
            [24.980273437500017, 25.13974609374999],
            [24.980273437500017, 24.915185546874994],
            [24.980273437500017, 24.690625],
            [24.980273437500017, 24.466064453125],
            [24.980273437500017, 24.241503906250003],
            [24.980273437500017, 24.01694335937499],
            [24.980273437500017, 23.792382812499994],
            [24.980273437500017, 23.567822265624997],
            [24.980273437500017, 23.343212890624997],
            [24.980273437500017, 23.11865234375],
            [24.980273437500017, 22.894091796875003],
            [24.980273437500017, 22.66953125],
            [24.980273437500017, 22.444970703124994],
            [24.980273437500017, 22.220410156249997],
            [24.980273437500017, 21.995849609375],
            [24.980078125, 21.49755859375],
            [24.979882812500023, 20.99921875],
            [24.97968750000001, 20.500927734374997],
            [24.9794921875, 20.002587890624994],
            [24.976367187500017, 20.00078125],
            [24.973242187500006, 19.9990234375],
            [24.97021484375, 19.997265625],
            [24.96699218750001, 19.99545898437499],
            [24.72041015625001, 19.995556640624997],
            [24.4736328125, 19.995703125],
            [24.226953125000023, 19.995849609375],
            [23.980273437500017, 19.99594726562499],
            [23.980273437500017, 19.87109375],
            [23.980273437500017, 19.746289062499997],
            [23.980273437500017, 19.621484375],
            [23.980273437500017, 19.496630859375003],
            [23.501269531250017, 19.733203125],
            [23.02216796875001, 19.969775390625003],
            [22.543066406250006, 20.206347656250003],
            [22.0640625, 20.442919921875003],
            [21.5849609375, 20.679492187500003],
            [21.105859375000023, 20.91611328124999],
            [20.626757812500017, 21.152636718750003],
            [20.14765625000001, 21.38925781249999],
            [19.668554687500006, 21.62583007812499],
            [19.189453125, 21.86240234374999],
            [18.71044921875, 22.09897460937499],
            [18.231347656250023, 22.335546875],
            [17.752246093750017, 22.57211914062499],
            [17.273242187500017, 22.80869140624999],
            [16.79414062500001, 23.04526367187499],
            [16.315039062500006, 23.28183593749999],
            [15.984082031250011, 23.445214843749994],
            [15.627148437500011, 23.28574218749999],
            [15.347460937500017, 23.160693359375003],
            [14.97900390625, 22.99619140624999],
            [14.978906250000023, 22.996289062499997],
            [14.5556640625, 22.782519531250003],
            [14.230761718750017, 22.618457031250003],
            [14.215527343750011, 22.619677734375003],
            [14.20068359375, 22.623730468749997],
            [13.862695312500023, 22.902099609375],
            [13.5986328125, 23.11953125],
            [13.48125, 23.18017578125],
            [12.983593750000011, 23.291259765625],
            [12.48876953125, 23.401660156250003],
            [11.967871093750006, 23.517871093750003],
            [11.873046875, 23.69482421875],
            [11.766992187500023, 23.892578125],
            [11.624218750000011, 24.139697265625003],
            [11.536914062500017, 24.290820312500003],
            [11.507617187500017, 24.314355468749994],
            [11.108203125000017, 24.434033203124997],
            [10.686132812500006, 24.55136718749999],
            [10.43896484375, 24.480224609375],
            [10.395898437500023, 24.485595703125],
            [10.32578125, 24.530224609374997],
            [10.255859375, 24.591015625],
            [10.218652343750023, 24.676220703124997],
            [10.119531250000023, 24.790234375],
            [10.028125, 25.051025390625],
            [10.01904296875, 25.258544921875],
            [10.000683593750011, 25.332080078125003],
            [9.781054687500017, 25.624267578125],
            [9.58125, 25.89013671875],
            [9.4482421875, 26.067138671875],
            [9.42236328125, 26.1470703125],
            [9.437890625000023, 26.245507812499994],
            [9.491406250000011, 26.333740234375],
            [9.684960937500023, 26.438232421875],
            [9.859375, 26.551953125],
            [9.883203125000023, 26.630810546874997],
            [9.894433593750023, 26.847949218749996],
            [9.837109375000011, 26.915820312499996],
            [9.79541015625, 27.044775390625],
            [9.752539062500006, 27.2193359375],
            [9.74755859375, 27.330859375],
            [9.825292968750006, 27.552978515625],
            [9.916015625, 27.785693359374996],
            [9.858203125000017, 28.043310546875],
            [9.815625, 28.560205078124994],
            [9.842578125000017, 28.966992187499997],
            [9.820703125000023, 29.114794921874996],
            [9.805273437500006, 29.176953125],
            [9.745898437500017, 29.368945312499996],
            [9.672656250000017, 29.5669921875],
            [9.64013671875, 29.636425781249997],
            [9.546191406250017, 29.795947265624996],
            [9.391015625000023, 29.99365234375],
            [9.310253906250011, 30.115234375],
            [9.420996093750006, 30.179296875],
            [9.51875, 30.229394531249994],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Namibia",
        sov_a3: "NAM",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Namibia",
        adm0_a3: "NAM",
        geou_dif: 0,
        geounit: "Namibia",
        gu_a3: "NAM",
        su_dif: 0,
        subunit: "Namibia",
        su_a3: "NAM",
        brk_diff: 0,
        name: "Namibia",
        name_long: "Namibia",
        brk_a3: "NAM",
        brk_name: "Namibia",
        brk_group: null,
        abbrev: "Nam.",
        postal: "NA",
        formal_en: "Republic of Namibia",
        formal_fr: null,
        name_ciawf: "Namibia",
        note_adm0: null,
        note_brk: null,
        name_sort: "Namibia",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 1,
        mapcolor9: 1,
        mapcolor13: 7,
        pop_est: 2494530,
        pop_rank: 12,
        pop_year: 2019,
        gdp_md: 12366,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        fips_10: "WA",
        iso_a2: "NA",
        iso_a2_eh: "NA",
        iso_a3: "NAM",
        iso_a3_eh: "NAM",
        iso_n3: "516",
        iso_n3_eh: "516",
        un_a3: "516",
        wb_a2: "NA",
        wb_a3: "NAM",
        woe_id: 23424987,
        woe_id_eh: 23424987,
        woe_note: "Exact WOE match as country",
        adm0_iso: "NAM",
        adm0_diff: null,
        adm0_tlc: "NAM",
        adm0_a3_us: "NAM",
        adm0_a3_fr: "NAM",
        adm0_a3_ru: "NAM",
        adm0_a3_es: "NAM",
        adm0_a3_cn: "NAM",
        adm0_a3_tw: "NAM",
        adm0_a3_in: "NAM",
        adm0_a3_np: "NAM",
        adm0_a3_pk: "NAM",
        adm0_a3_de: "NAM",
        adm0_a3_gb: "NAM",
        adm0_a3_br: "NAM",
        adm0_a3_il: "NAM",
        adm0_a3_ps: "NAM",
        adm0_a3_sa: "NAM",
        adm0_a3_eg: "NAM",
        adm0_a3_ma: "NAM",
        adm0_a3_pt: "NAM",
        adm0_a3_ar: "NAM",
        adm0_a3_jp: "NAM",
        adm0_a3_ko: "NAM",
        adm0_a3_vn: "NAM",
        adm0_a3_tr: "NAM",
        adm0_a3_id: "NAM",
        adm0_a3_pl: "NAM",
        adm0_a3_gr: "NAM",
        adm0_a3_it: "NAM",
        adm0_a3_nl: "NAM",
        adm0_a3_se: "NAM",
        adm0_a3_bd: "NAM",
        adm0_a3_ua: "NAM",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Southern Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 7.5,
        label_x: 17.108166,
        label_y: -20.575298,
        ne_id: 1159321085,
        wikidataid: "Q1030",
        name_ar: "ناميبيا",
        name_bn: "নামিবিয়া",
        name_de: "Namibia",
        name_en: "Namibia",
        name_es: "Namibia",
        name_fa: "نامیبیا",
        name_fr: "Namibie",
        name_el: "Ναμίμπια",
        name_he: "נמיביה",
        name_hi: "नामीबिया",
        name_hu: "Namíbia",
        name_id: "Namibia",
        name_it: "Namibia",
        name_ja: "ナミビア",
        name_ko: "나미비아",
        name_nl: "Namibië",
        name_pl: "Namibia",
        name_pt: "Namíbia",
        name_ru: "Намибия",
        name_sv: "Namibia",
        name_tr: "Namibya",
        name_uk: "Намібія",
        name_ur: "نمیبیا",
        name_vi: "Namibia",
        name_zh: "纳米比亚",
        name_zht: "納米比亞",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "NAM.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [23.380664062500017, -17.640625],
            [23.59492187500001, -17.599414062500003],
            [23.799218750000023, -17.56015625],
            [24.036914062500017, -17.52089843750001],
            [24.227148437500006, -17.489550781250003],
            [24.27490234375, -17.481054687500006],
            [24.73291015625, -17.51777343750001],
            [24.932421875000017, -17.54345703125],
            [25.001757812500017, -17.56855468750001],
            [25.092187500000023, -17.634375],
            [25.2587890625, -17.793554687500006],
            [25.21601562500001, -17.78759765625],
            [24.909082031250023, -17.821386718750006],
            [24.79218750000001, -17.86464843750001],
            [24.530566406250017, -18.052734375],
            [24.474902343750017, -18.02851562500001],
            [24.412207031250006, -17.98945312500001],
            [24.358984375, -17.97822265625001],
            [24.243945312500017, -18.0234375],
            [24.129296875000023, -18.07753906250001],
            [24.00263671875001, -18.154101562500003],
            [23.898339843750023, -18.22919921875001],
            [23.8642578125, -18.26953125],
            [23.700488281250017, -18.42431640625],
            [23.64716796875001, -18.44941406250001],
            [23.599707031250006, -18.4599609375],
            [23.58056640625, -18.452929687500003],
            [23.56015625, -18.38642578125001],
            [23.459765625000017, -18.231054687500006],
            [23.298632812500017, -18.02734375],
            [23.2515625, -18.00751953125001],
            [23.219335937500006, -17.99970703125001],
            [23.099902343750017, -18.009570312500003],
            [22.752734375000017, -18.0671875],
            [22.460058593750006, -18.11572265625],
            [22.01142578125001, -18.19863281250001],
            [21.529687500000023, -18.265625],
            [21.232519531250006, -18.30683593750001],
            [20.97412109375, -18.31884765625],
            [20.97431640625001, -18.5205078125],
            [20.975, -18.928515625],
            [20.9755859375, -19.33642578125],
            [20.976171875, -19.74433593750001],
            [20.976855468750017, -20.15234375],
            [20.977441406250023, -20.56025390625001],
            [20.978125, -20.96816406250001],
            [20.97871093750001, -21.376074218750006],
            [20.979296875000017, -21.78408203125001],
            [20.9794921875, -21.9619140625],
            [20.970996093750017, -22.00019531250001],
            [20.82275390625, -22.00019531250001],
            [20.4875, -22.00019531250001],
            [20.205371093750017, -22.00019531250001],
            [19.977343750000017, -22.00019531250001],
            [19.977636718750006, -22.242578125],
            [19.977929687500023, -22.529296875],
            [19.97822265625001, -22.81591796875],
            [19.978515625, -23.1025390625],
            [19.978906250000023, -23.38916015625],
            [19.979296875000017, -23.67578125],
            [19.979589843750006, -23.96240234375],
            [19.979882812500023, -24.2490234375],
            [19.98017578125001, -24.535742187500006],
            [19.98046875, -24.751953125],
            [19.98046875, -24.77675781250001],
            [19.98046875, -25.19677734375],
            [19.98046875, -25.6416015625],
            [19.98046875, -26.086328125],
            [19.98046875, -26.53115234375001],
            [19.98046875, -26.97597656250001],
            [19.98046875, -27.420703125],
            [19.98046875, -27.865527343750003],
            [19.98046875, -28.310351562500003],
            [19.98046875, -28.451269531250006],
            [19.877832031250023, -28.44941406250001],
            [19.671484375, -28.50390625],
            [19.539843750000017, -28.574609375],
            [19.48291015625, -28.66162109375],
            [19.4072265625, -28.714453125],
            [19.31269531250001, -28.73330078125001],
            [19.27099609375, -28.777734375],
            [19.2822265625, -28.847949218750003],
            [19.24580078125001, -28.901660156250003],
            [19.16171875, -28.938769531250003],
            [19.02607421875001, -28.92792968750001],
            [18.838769531250023, -28.869140625],
            [18.600390625000017, -28.855273437500003],
            [18.310839843750017, -28.88623046875],
            [18.10273437500001, -28.871679687500006],
            [17.97607421875, -28.811328125],
            [17.841601562500017, -28.776953125],
            [17.699316406250006, -28.768359375],
            [17.616796875, -28.74306640625001],
            [17.44794921875001, -28.69814453125001],
            [17.41572265625001, -28.62109375],
            [17.395898437500023, -28.56269531250001],
            [17.34785156250001, -28.50117187500001],
            [17.342578125000017, -28.45166015625],
            [17.380273437500023, -28.41396484375001],
            [17.3857421875, -28.35322265625001],
            [17.358691406250017, -28.26943359375001],
            [17.31201171875, -28.228613281250006],
            [17.24580078125001, -28.230859375],
            [17.20458984375, -28.198828125],
            [17.1884765625, -28.13251953125001],
            [17.1494140625, -28.08222656250001],
            [17.05625, -28.031054687500003],
            [16.93330078125001, -28.069628906250003],
            [16.875292968750017, -28.1279296875],
            [16.841210937500023, -28.21894531250001],
            [16.81015625, -28.26455078125001],
            [16.79453125, -28.3408203125],
            [16.7875, -28.39472656250001],
            [16.755761718750023, -28.4521484375],
            [16.723046875000023, -28.47548828125001],
            [16.689453125, -28.464941406250006],
            [16.62617187500001, -28.487890625],
            [16.487109375000017, -28.572851562500006],
            [16.447558593750017, -28.617578125],
            [16.335058593750006, -28.53652343750001],
            [16.007128906250017, -28.231738281250003],
            [15.890917968750017, -28.15253906250001],
            [15.719042968750017, -27.9658203125],
            [15.341503906250011, -27.386523437500003],
            [15.28759765625, -27.275],
            [15.215722656250023, -26.9951171875],
            [15.1328125, -26.78759765625],
            [15.123730468750011, -26.66787109375001],
            [15.163281250000011, -26.600195312500006],
            [15.139062500000023, -26.50800781250001],
            [15.096582031250023, -26.42578125],
            [14.9677734375, -26.31806640625001],
            [14.93125, -25.95820312500001],
            [14.84521484375, -25.725683593750006],
            [14.863671875000023, -25.53359375],
            [14.822558593750017, -25.35859375000001],
            [14.818554687500011, -25.246386718750003],
            [14.837109375000011, -25.033203125],
            [14.767968750000023, -24.78798828125001],
            [14.6279296875, -24.54804687500001],
            [14.5015625, -24.201953125],
            [14.4833984375, -24.050390625],
            [14.496875, -23.642871093750003],
            [14.472460937500017, -23.476660156250006],
            [14.473828125000011, -23.28115234375001],
            [14.423828125, -23.07861328125],
            [14.4033203125, -22.968066406250003],
            [14.4384765625, -22.88056640625001],
            [14.459277343750017, -22.908203125],
            [14.495703125, -22.92138671875],
            [14.519921875000023, -22.80517578125],
            [14.525976562500006, -22.70253906250001],
            [14.462792968750023, -22.44912109375001],
            [14.321875, -22.18994140625],
            [13.973242187500006, -21.767578125],
            [13.888085937500023, -21.60664062500001],
            [13.83935546875, -21.473242187500006],
            [13.450585937500023, -20.91669921875001],
            [13.284375, -20.52392578125],
            [13.168359375000023, -20.184667968750006],
            [13.042089843750006, -20.02822265625001],
            [12.458203125000011, -18.9267578125],
            [12.328710937500006, -18.751074218750006],
            [12.095703125, -18.54091796875001],
            [12.041210937500011, -18.470703125],
            [11.951367187500011, -18.2705078125],
            [11.77587890625, -18.001757812500003],
            [11.733496093750006, -17.7509765625],
            [11.7216796875, -17.466796875],
            [11.743066406250023, -17.24921875000001],
            [11.902539062500011, -17.2265625],
            [12.013964843750017, -17.168554687500006],
            [12.114355468750006, -17.16455078125],
            [12.21337890625, -17.2099609375],
            [12.318457031250006, -17.21337890625],
            [12.359277343750023, -17.205859375],
            [12.548144531250017, -17.212695312500003],
            [12.656542968750017, -17.160546875],
            [12.78515625, -17.108203125],
            [12.859277343750023, -17.062597656250006],
            [12.963183593750017, -17.015429687500003],
            [13.101171875, -16.96767578125001],
            [13.179492187500017, -16.9716796875],
            [13.275683593750017, -16.989550781250003],
            [13.403710937500023, -17.0078125],
            [13.475976562500023, -17.0400390625],
            [13.561718750000011, -17.141210937500006],
            [13.6943359375, -17.233496093750006],
            [13.7919921875, -17.288378906250003],
            [13.904199218750023, -17.36074218750001],
            [13.93798828125, -17.388769531250006],
            [13.987402343750006, -17.40419921875001],
            [14.017480468750023, -17.40888671875001],
            [14.225878906250017, -17.397753906250003],
            [14.414746093750011, -17.3876953125],
            [14.617968750000017, -17.387988281250003],
            [15.000585937500006, -17.38857421875001],
            [15.383203125000023, -17.38916015625],
            [15.765820312500011, -17.3896484375],
            [16.1484375, -17.390234375],
            [16.531054687500017, -17.39082031250001],
            [16.913671875, -17.39140625],
            [17.296289062500023, -17.39199218750001],
            [17.678808593750006, -17.392578125],
            [17.835351562500023, -17.39277343750001],
            [18.108789062500023, -17.39599609375],
            [18.396386718750023, -17.3994140625],
            [18.42822265625, -17.40517578125001],
            [18.460351562500023, -17.424609375],
            [18.486621093750017, -17.44277343750001],
            [18.588183593750017, -17.57001953125001],
            [18.718066406250017, -17.703222656250006],
            [18.825976562500017, -17.76630859375001],
            [18.95527343750001, -17.803515625],
            [19.076464843750017, -17.817675781250003],
            [19.189453125, -17.80849609375001],
            [19.37714843750001, -17.825488281250003],
            [19.63935546875001, -17.86865234375],
            [19.91181640625001, -17.88134765625],
            [20.1943359375, -17.863671875],
            [20.392968750000023, -17.88740234375001],
            [20.507617187500017, -17.95253906250001],
            [20.625097656250006, -17.996679687500006],
            [20.745507812500023, -18.01972656250001],
            [20.908300781250006, -18.00605468750001],
            [21.11347656250001, -17.95576171875001],
            [21.287890625000017, -17.962988281250006],
            [21.36875, -17.99951171875],
            [21.416894531250023, -18.00068359375001],
            [21.71845703125001, -17.94775390625],
            [21.960839843750023, -17.90517578125001],
            [22.32421875, -17.8375],
            [22.6240234375, -17.781640625],
            [23.068261718750023, -17.698828125],
            [23.380664062500017, -17.640625],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 2,
        sovereignt: "South Africa",
        sov_a3: "ZAF",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "South Africa",
        adm0_a3: "ZAF",
        geou_dif: 0,
        geounit: "South Africa",
        gu_a3: "ZAF",
        su_dif: 0,
        subunit: "South Africa",
        su_a3: "ZAF",
        brk_diff: 0,
        name: "South Africa",
        name_long: "South Africa",
        brk_a3: "ZAF",
        brk_name: "South Africa",
        brk_group: null,
        abbrev: "S.Af.",
        postal: "ZA",
        formal_en: "Republic of South Africa",
        formal_fr: null,
        name_ciawf: "South Africa",
        note_adm0: null,
        note_brk: null,
        name_sort: "South Africa",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 3,
        mapcolor9: 4,
        mapcolor13: 2,
        pop_est: 58558270,
        pop_rank: 16,
        pop_year: 2019,
        gdp_md: 351431,
        gdp_year: 2019,
        economy: "5. Emerging region: G20",
        income_grp: "3. Upper middle income",
        fips_10: "SF",
        iso_a2: "ZA",
        iso_a2_eh: "ZA",
        iso_a3: "ZAF",
        iso_a3_eh: "ZAF",
        iso_n3: "710",
        iso_n3_eh: "710",
        un_a3: "710",
        wb_a2: "ZA",
        wb_a3: "ZAF",
        woe_id: 23424942,
        woe_id_eh: 23424942,
        woe_note: "Exact WOE match as country",
        adm0_iso: "ZAF",
        adm0_diff: null,
        adm0_tlc: "ZAF",
        adm0_a3_us: "ZAF",
        adm0_a3_fr: "ZAF",
        adm0_a3_ru: "ZAF",
        adm0_a3_es: "ZAF",
        adm0_a3_cn: "ZAF",
        adm0_a3_tw: "ZAF",
        adm0_a3_in: "ZAF",
        adm0_a3_np: "ZAF",
        adm0_a3_pk: "ZAF",
        adm0_a3_de: "ZAF",
        adm0_a3_gb: "ZAF",
        adm0_a3_br: "ZAF",
        adm0_a3_il: "ZAF",
        adm0_a3_ps: "ZAF",
        adm0_a3_sa: "ZAF",
        adm0_a3_eg: "ZAF",
        adm0_a3_ma: "ZAF",
        adm0_a3_pt: "ZAF",
        adm0_a3_ar: "ZAF",
        adm0_a3_jp: "ZAF",
        adm0_a3_ko: "ZAF",
        adm0_a3_vn: "ZAF",
        adm0_a3_tr: "ZAF",
        adm0_a3_id: "ZAF",
        adm0_a3_pl: "ZAF",
        adm0_a3_gr: "ZAF",
        adm0_a3_it: "ZAF",
        adm0_a3_nl: "ZAF",
        adm0_a3_se: "ZAF",
        adm0_a3_bd: "ZAF",
        adm0_a3_ua: "ZAF",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Southern Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 12,
        long_len: 12,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 1.7,
        max_label: 6.7,
        label_x: 23.665734,
        label_y: -29.708776,
        ne_id: 1159321431,
        wikidataid: "Q258",
        name_ar: "جنوب أفريقيا",
        name_bn: "দক্ষিণ আফ্রিকা",
        name_de: "Südafrika",
        name_en: "South Africa",
        name_es: "Sudáfrica",
        name_fa: "آفریقای جنوبی",
        name_fr: "Afrique du Sud",
        name_el: "Νότια Αφρική",
        name_he: "דרום אפריקה",
        name_hi: "दक्षिण अफ़्रीका",
        name_hu: "Dél-afrikai Köztársaság",
        name_id: "Afrika Selatan",
        name_it: "Sudafrica",
        name_ja: "南アフリカ共和国",
        name_ko: "남아프리카 공화국",
        name_nl: "Zuid-Afrika",
        name_pl: "Południowa Afryka",
        name_pt: "África do Sul",
        name_ru: "ЮАР",
        name_sv: "Sydafrika",
        name_tr: "Güney Afrika Cumhuriyeti",
        name_uk: "Південно-Африканська Республіка",
        name_ur: "جنوبی افریقا",
        name_vi: "Cộng hòa Nam Phi",
        name_zh: "南非",
        name_zht: "南非",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "ZAF.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [29.36484375, -22.193945312500006],
              [29.37744140625, -22.19277343750001],
              [29.6630859375, -22.146289062500003],
              [29.90234375, -22.184179687500006],
              [30.1904296875, -22.291113281250006],
              [30.46015625000001, -22.32900390625001],
              [30.71162109375001, -22.2978515625],
              [30.916113281250006, -22.29072265625001],
              [31.07343750000001, -22.30781250000001],
              [31.197265625, -22.34492187500001],
              [31.287890625000017, -22.40205078125001],
              [31.29316406250001, -22.4546875],
              [31.300195312500023, -22.478613281250006],
              [31.348046875000023, -22.617578125],
              [31.419335937500023, -22.82509765625001],
              [31.466699218750023, -23.016699218750006],
              [31.53173828125, -23.27949218750001],
              [31.529687500000023, -23.42578125],
              [31.54560546875001, -23.48232421875001],
              [31.604101562500006, -23.55292968750001],
              [31.675585937500017, -23.67421875],
              [31.7, -23.74306640625001],
              [31.724023437500023, -23.79453125],
              [31.799609375000017, -23.8921875],
              [31.858300781250023, -24.04023437500001],
              [31.908007812500017, -24.23623046875001],
              [31.950585937500023, -24.33027343750001],
              [31.966601562500017, -24.37646484375],
              [31.98583984375, -24.46064453125001],
              [31.983203125000017, -24.63828125],
              [31.984375, -24.844042968750003],
              [31.985742187500023, -25.073828125],
              [31.98701171875001, -25.263476562500003],
              [31.979394531250023, -25.359472656250006],
              [31.98457031250001, -25.631933593750006],
              [31.920312500000023, -25.77392578125],
              [31.928320312500006, -25.885351562500006],
              [31.9482421875, -25.957617187500006],
              [31.921679687500017, -25.96875],
              [31.871484375000023, -25.98164062500001],
              [31.640429687500017, -25.867285156250006],
              [31.415136718750006, -25.74658203125],
              [31.382617187500017, -25.74296875],
              [31.33515625000001, -25.75556640625001],
              [31.207324218750017, -25.843359375],
              [31.08808593750001, -25.98066406250001],
              [31.033300781250006, -26.097753906250006],
              [30.945214843750023, -26.21875],
              [30.803320312500006, -26.41347656250001],
              [30.7890625, -26.45546875],
              [30.7875, -26.613671875],
              [30.794335937500023, -26.764257812500006],
              [30.806738281250006, -26.785253906250006],
              [30.88330078125, -26.79238281250001],
              [30.938085937500006, -26.915820312500003],
              [31.063378906250023, -27.1123046875],
              [31.274023437500006, -27.238378906250006],
              [31.469531250000017, -27.295507812500006],
              [31.742578125000023, -27.30996093750001],
              [31.958398437500023, -27.30585937500001],
              [31.946093750000017, -27.173632812500003],
              [31.967187500000023, -26.96064453125001],
              [31.994726562500006, -26.817480468750006],
              [32.02480468750002, -26.811132812500006],
              [32.081640625, -26.824804687500006],
              [32.112890625, -26.839453125],
              [32.19960937500002, -26.83349609375],
              [32.353515625, -26.861621093750003],
              [32.47773437500001, -26.858496093750006],
              [32.58876953125002, -26.855761718750003],
              [32.77656250000001, -26.85097656250001],
              [32.88613281250002, -26.84931640625001],
              [32.84912109375, -27.080175781250006],
              [32.70585937500002, -27.44160156250001],
              [32.65703125000002, -27.60732421875001],
              [32.534765625, -28.19970703125],
              [32.37519531250001, -28.49824218750001],
              [32.285742187500006, -28.621484375],
              [32.02724609375002, -28.83955078125001],
              [31.955371093750017, -28.8837890625],
              [31.891503906250023, -28.912109375],
              [31.778222656250023, -28.937109375],
              [31.33515625000001, -29.378125],
              [31.169921875, -29.5908203125],
              [31.023339843750023, -29.90087890625],
              [30.87763671875001, -30.07109375],
              [30.66357421875, -30.434179687500006],
              [30.472265625, -30.71455078125001],
              [30.288671875, -30.97011718750001],
              [29.97119140625, -31.322070312500003],
              [29.83027343750001, -31.423828125],
              [29.735156250000017, -31.47041015625001],
              [29.48291015625, -31.67470703125001],
              [29.127832031250023, -32.003125],
              [28.85595703125, -32.29423828125],
              [28.44941406250001, -32.624609375],
              [28.21406250000001, -32.76923828125001],
              [27.860644531250017, -33.05390625000001],
              [27.762109375000023, -33.09599609375],
              [27.36376953125, -33.36054687500001],
              [27.077441406250017, -33.52119140625001],
              [26.613671875000023, -33.707421875],
              [26.429492187500017, -33.7595703125],
              [25.989550781250017, -33.711328125],
              [25.80585937500001, -33.737109375],
              [25.652441406250006, -33.849609375],
              [25.63818359375, -34.01113281250001],
              [25.57421875, -34.03535156250001],
              [25.47724609375001, -34.028125],
              [25.169726562500017, -33.9607421875],
              [25.0029296875, -33.9736328125],
              [24.905566406250017, -34.05976562500001],
              [24.8271484375, -34.1689453125],
              [24.595507812500017, -34.17451171875001],
              [24.183007812500023, -34.0615234375],
              [23.697851562500006, -33.992773437500006],
              [23.585546875, -33.98515625],
              [23.350390625000017, -34.068945312500006],
              [23.268164062500006, -34.081152343750006],
              [22.925585937500017, -34.06318359375001],
              [22.73554687500001, -34.01025390625],
              [22.553808593750006, -34.01005859375],
              [22.414453125000023, -34.053808593750006],
              [22.245507812500023, -34.069140625],
              [21.788964843750023, -34.37265625],
              [21.55322265625, -34.373046875],
              [21.34980468750001, -34.408203125],
              [21.248925781250023, -34.40703125],
              [21.06015625, -34.36464843750001],
              [20.98984375, -34.36748046875],
              [20.882421875, -34.3865234375],
              [20.774804687500023, -34.43994140625],
              [20.529882812500006, -34.46308593750001],
              [20.434667968750006, -34.50859375],
              [20.020605468750006, -34.785742187500006],
              [19.92626953125, -34.77470703125],
              [19.85, -34.756640625],
              [19.63496093750001, -34.75332031250001],
              [19.391503906250023, -34.60566406250001],
              [19.298242187500023, -34.6150390625],
              [19.3232421875, -34.57080078125],
              [19.33076171875001, -34.49238281250001],
              [19.279394531250006, -34.43701171875],
              [19.24462890625, -34.41230468750001],
              [19.14912109375001, -34.41689453125001],
              [19.09833984375001, -34.35009765625],
              [18.9521484375, -34.34375],
              [18.90156250000001, -34.36064453125],
              [18.831347656250017, -34.3640625],
              [18.825097656250023, -34.296484375],
              [18.830664062500006, -34.25390625],
              [18.82636718750001, -34.1884765625],
              [18.80878906250001, -34.108203125],
              [18.75214843750001, -34.082617187500006],
              [18.70869140625001, -34.071875],
              [18.60517578125001, -34.07734375000001],
              [18.53388671875001, -34.0859375],
              [18.500390625000023, -34.10927734375001],
              [18.46210937500001, -34.168066406250006],
              [18.46162109375001, -34.346875],
              [18.41035156250001, -34.29560546875001],
              [18.35205078125, -34.1884765625],
              [18.333398437500023, -34.07421875],
              [18.354394531250023, -33.9390625],
              [18.46503906250001, -33.887792968750006],
              [18.456445312500023, -33.796484375],
              [18.433007812500023, -33.71728515625],
              [18.309472656250023, -33.514453125],
              [18.26123046875, -33.4216796875],
              [18.156347656250006, -33.35878906250001],
              [18.074804687500006, -33.20732421875],
              [17.992578125000023, -33.15234375],
              [17.958398437500023, -33.04638671875],
              [17.878222656250017, -32.961523437500006],
              [17.85107421875, -32.82744140625],
              [17.895312500000017, -32.75048828125],
              [17.965234375000023, -32.70859375],
              [18.036523437500023, -32.77509765625001],
              [18.125, -32.749121093750006],
              [18.250878906250023, -32.6521484375],
              [18.325292968750006, -32.504980468750006],
              [18.329882812500017, -32.26953125],
              [18.31074218750001, -32.12246093750001],
              [18.210839843750023, -31.742480468750003],
              [18.163671875, -31.65517578125001],
              [17.938574218750006, -31.383203125],
              [17.67744140625001, -31.01904296875],
              [17.347070312500023, -30.44482421875],
              [17.1890625, -30.09980468750001],
              [16.95, -29.403417968750006],
              [16.73945312500001, -29.009375],
              [16.480761718750017, -28.64150390625001],
              [16.447558593750017, -28.617578125],
              [16.487109375000017, -28.572851562500006],
              [16.62617187500001, -28.487890625],
              [16.689453125, -28.464941406250006],
              [16.723046875000023, -28.47548828125001],
              [16.755761718750023, -28.4521484375],
              [16.7875, -28.39472656250001],
              [16.79453125, -28.3408203125],
              [16.81015625, -28.26455078125001],
              [16.841210937500023, -28.21894531250001],
              [16.875292968750017, -28.1279296875],
              [16.93330078125001, -28.069628906250003],
              [17.05625, -28.031054687500003],
              [17.1494140625, -28.08222656250001],
              [17.1884765625, -28.13251953125001],
              [17.20458984375, -28.198828125],
              [17.24580078125001, -28.230859375],
              [17.31201171875, -28.228613281250006],
              [17.358691406250017, -28.26943359375001],
              [17.3857421875, -28.35322265625001],
              [17.380273437500023, -28.41396484375001],
              [17.342578125000017, -28.45166015625],
              [17.34785156250001, -28.50117187500001],
              [17.395898437500023, -28.56269531250001],
              [17.41572265625001, -28.62109375],
              [17.44794921875001, -28.69814453125001],
              [17.616796875, -28.74306640625001],
              [17.699316406250006, -28.768359375],
              [17.841601562500017, -28.776953125],
              [17.97607421875, -28.811328125],
              [18.10273437500001, -28.871679687500006],
              [18.310839843750017, -28.88623046875],
              [18.600390625000017, -28.855273437500003],
              [18.838769531250023, -28.869140625],
              [19.02607421875001, -28.92792968750001],
              [19.16171875, -28.938769531250003],
              [19.24580078125001, -28.901660156250003],
              [19.2822265625, -28.847949218750003],
              [19.27099609375, -28.777734375],
              [19.31269531250001, -28.73330078125001],
              [19.4072265625, -28.714453125],
              [19.48291015625, -28.66162109375],
              [19.539843750000017, -28.574609375],
              [19.671484375, -28.50390625],
              [19.877832031250023, -28.44941406250001],
              [19.98046875, -28.451269531250006],
              [19.98046875, -28.310351562500003],
              [19.98046875, -27.865527343750003],
              [19.98046875, -27.420703125],
              [19.98046875, -26.97597656250001],
              [19.98046875, -26.53115234375001],
              [19.98046875, -26.086328125],
              [19.98046875, -25.6416015625],
              [19.98046875, -25.19677734375],
              [19.98046875, -24.77675781250001],
              [20.028613281250017, -24.80703125],
              [20.34521484375, -25.029882812500006],
              [20.4306640625, -25.147070312500006],
              [20.47314453125, -25.221289062500006],
              [20.609277343750023, -25.4912109375],
              [20.710742187500017, -25.733203125],
              [20.79316406250001, -25.915625],
              [20.799414062500006, -25.9990234375],
              [20.81103515625, -26.08056640625],
              [20.822656250000023, -26.12060546875],
              [20.815039062500006, -26.16494140625001],
              [20.75703125000001, -26.26416015625],
              [20.697851562500006, -26.340136718750003],
              [20.626757812500017, -26.44384765625],
              [20.619921875000017, -26.580859375],
              [20.641406250000017, -26.7421875],
              [20.68505859375, -26.82246093750001],
              [20.73984375, -26.84882812500001],
              [20.870898437500017, -26.80878906250001],
              [20.953906250000017, -26.82109375],
              [21.07099609375001, -26.85175781250001],
              [21.454980468750023, -26.8328125],
              [21.501367187500023, -26.84267578125001],
              [21.646289062500017, -26.85419921875001],
              [21.694726562500023, -26.840917968750006],
              [21.738085937500017, -26.80683593750001],
              [21.78828125000001, -26.710058593750006],
              [21.83320312500001, -26.678320312500006],
              [21.91455078125, -26.661914062500003],
              [22.01093750000001, -26.635839843750006],
              [22.090917968750006, -26.580175781250006],
              [22.217578125000017, -26.38886718750001],
              [22.47089843750001, -26.219042968750003],
              [22.548632812500017, -26.17841796875001],
              [22.59765625, -26.13271484375001],
              [22.640234375, -26.07119140625001],
              [22.72900390625, -25.85732421875001],
              [22.79609375000001, -25.67910156250001],
              [22.818945312500006, -25.59511718750001],
              [22.878808593750023, -25.45791015625001],
              [22.951269531250006, -25.37031250000001],
              [23.022070312500006, -25.32412109375001],
              [23.057519531250023, -25.312304687500003],
              [23.148730468750017, -25.288671875],
              [23.266015625000023, -25.2666015625],
              [23.389257812500006, -25.29140625],
              [23.521484375, -25.34443359375001],
              [23.670703125000017, -25.433984375],
              [23.82343750000001, -25.54462890625001],
              [23.89375, -25.600878906250003],
              [23.969531250000017, -25.626074218750006],
              [24.1044921875, -25.634863281250006],
              [24.19296875, -25.632910156250006],
              [24.33056640625, -25.74287109375001],
              [24.400195312500017, -25.749804687500003],
              [24.55585937500001, -25.78310546875001],
              [24.748144531250006, -25.8173828125],
              [24.869238281250006, -25.8134765625],
              [24.998925781250023, -25.754003906250006],
              [25.09248046875001, -25.75146484375],
              [25.21337890625, -25.75625],
              [25.34619140625, -25.73994140625001],
              [25.443652343750017, -25.714453125],
              [25.518164062500006, -25.66279296875001],
              [25.583789062500017, -25.60625],
              [25.6591796875, -25.437890625],
              [25.70263671875, -25.30234375],
              [25.769921875000023, -25.146484375],
              [25.852441406250023, -24.93525390625001],
              [25.8818359375, -24.78798828125001],
              [25.912109375, -24.74746093750001],
              [26.031835937500006, -24.702441406250003],
              [26.130859375, -24.671484375],
              [26.39716796875001, -24.613574218750003],
              [26.451757812500006, -24.58271484375001],
              [26.5015625, -24.51328125],
              [26.617773437500006, -24.3955078125],
              [26.761132812500023, -24.297167968750003],
              [26.835058593750006, -24.240820312500006],
              [26.970605468750023, -23.763476562500003],
              [26.98701171875001, -23.70458984375],
              [27.085546875, -23.577929687500003],
              [27.146386718750023, -23.5244140625],
              [27.185546875, -23.5234375],
              [27.2412109375, -23.490039062500003],
              [27.313378906250023, -23.42421875],
              [27.399218750000017, -23.38359375],
              [27.49873046875001, -23.36835937500001],
              [27.56318359375001, -23.324609375],
              [27.592675781250023, -23.25263671875001],
              [27.643847656250017, -23.21767578125001],
              [27.716796875, -23.21962890625001],
              [27.75830078125, -23.19677734375],
              [27.7685546875, -23.14892578125],
              [27.812597656250006, -23.108007812500006],
              [27.890527343750023, -23.07392578125001],
              [27.93134765625001, -23.03359375],
              [27.93505859375, -22.98701171875001],
              [28.027929687500006, -22.87373046875001],
              [28.21015625000001, -22.693652343750003],
              [28.381738281250023, -22.593359375],
              [28.542871093750023, -22.57294921875001],
              [28.69550781250001, -22.535449218750003],
              [28.83984375, -22.480859375],
              [28.94580078125, -22.395117187500006],
              [29.013476562500017, -22.278417968750006],
              [29.1298828125, -22.21328125],
              [29.36484375, -22.193945312500006],
            ],
            [
              [28.736914062500006, -30.101953125],
              [28.90107421875001, -30.03847656250001],
              [28.97529296875001, -29.99941406250001],
              [29.02900390625001, -29.967578125],
              [29.098046875000023, -29.919042968750006],
              [29.121972656250023, -29.801171875],
              [29.1421875, -29.700976562500003],
              [29.195117187500017, -29.651660156250003],
              [29.24921875000001, -29.61884765625001],
              [29.293554687500006, -29.56689453125],
              [29.34882812500001, -29.441992187500006],
              [29.38671875, -29.31972656250001],
              [29.390722656250006, -29.26972656250001],
              [29.370898437500017, -29.21845703125001],
              [29.3359375, -29.163671875],
              [29.301367187500006, -29.08984375],
              [29.259765625, -29.07832031250001],
              [29.178027343750017, -29.036914062500003],
              [29.058007812500023, -28.953710937500006],
              [28.953710937500006, -28.881445312500006],
              [28.85625, -28.77607421875001],
              [28.816210937500017, -28.758886718750006],
              [28.721777343750006, -28.68769531250001],
              [28.68115234375, -28.646777343750003],
              [28.652636718750017, -28.59785156250001],
              [28.625781250000017, -28.58173828125001],
              [28.583398437500023, -28.594140625],
              [28.471875, -28.615820312500006],
              [28.23261718750001, -28.701269531250006],
              [28.084375, -28.77998046875001],
              [27.959863281250023, -28.873339843750003],
              [27.830371093750017, -28.90908203125001],
              [27.73554687500001, -28.940039062500006],
              [27.660449218750017, -29.046972656250006],
              [27.590234375000023, -29.146484375],
              [27.527148437500017, -29.236132812500003],
              [27.491015625000017, -29.27656250000001],
              [27.4580078125, -29.302734375],
              [27.424902343750006, -29.36005859375001],
              [27.356835937500023, -29.45527343750001],
              [27.29453125, -29.519335937500003],
              [27.207421875000023, -29.55419921875],
              [27.09521484375, -29.59931640625001],
              [27.056933593750017, -29.625585937500006],
              [27.0517578125, -29.6640625],
              [27.091796875, -29.753710937500003],
              [27.13046875, -29.840234375],
              [27.19355468750001, -29.94130859375001],
              [27.23974609375, -30.01533203125001],
              [27.31269531250001, -30.10566406250001],
              [27.355371093750023, -30.15859375],
              [27.349707031250006, -30.247363281250003],
              [27.364062500000017, -30.27919921875001],
              [27.388476562500017, -30.31591796875],
              [27.408593750000023, -30.325292968750006],
              [27.431445312500017, -30.338476562500006],
              [27.491992187500017, -30.36396484375001],
              [27.50654296875001, -30.380957031250006],
              [27.54902343750001, -30.411230468750006],
              [27.589648437500017, -30.46640625],
              [27.666601562500006, -30.542285156250003],
              [27.753125, -30.6],
              [27.90185546875, -30.623828125],
              [28.018164062500006, -30.64228515625001],
              [28.05683593750001, -30.63105468750001],
              [28.09638671875001, -30.584570312500006],
              [28.128710937500017, -30.52509765625001],
              [28.139062500000023, -30.44990234375001],
              [28.176171875000023, -30.40986328125001],
              [28.3154296875, -30.21845703125001],
              [28.39208984375, -30.147558593750006],
              [28.4390625, -30.14248046875001],
              [28.499609375, -30.12890625],
              [28.57666015625, -30.123046875],
              [28.634375, -30.128710937500003],
              [28.646875, -30.1265625],
              [28.736914062500006, -30.101953125],
            ],
          ],
          [
            [
              [37.85693359375, -46.944238281249994],
              [37.81396484375, -46.962890625],
              [37.61181640625, -46.94648437500001],
              [37.59003906250001, -46.90800781250002],
              [37.64970703125002, -46.84892578125002],
              [37.68486328125002, -46.82402343750002],
              [37.78955078125, -46.8375],
              [37.87285156250002, -46.88544921875001],
              [37.8876953125, -46.90166015625002],
              [37.85693359375, -46.944238281249994],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 4,
        sovereignt: "Liberia",
        sov_a3: "LBR",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Liberia",
        adm0_a3: "LBR",
        geou_dif: 0,
        geounit: "Liberia",
        gu_a3: "LBR",
        su_dif: 0,
        subunit: "Liberia",
        su_a3: "LBR",
        brk_diff: 0,
        name: "Liberia",
        name_long: "Liberia",
        brk_a3: "LBR",
        brk_name: "Liberia",
        brk_group: null,
        abbrev: "Liberia",
        postal: "LR",
        formal_en: "Republic of Liberia",
        formal_fr: null,
        name_ciawf: "Liberia",
        note_adm0: null,
        note_brk: null,
        name_sort: "Liberia",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 3,
        mapcolor9: 4,
        mapcolor13: 9,
        pop_est: 4937374,
        pop_rank: 12,
        pop_year: 2019,
        gdp_md: 3070,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        fips_10: "LI",
        iso_a2: "LR",
        iso_a2_eh: "LR",
        iso_a3: "LBR",
        iso_a3_eh: "LBR",
        iso_n3: "430",
        iso_n3_eh: "430",
        un_a3: "430",
        wb_a2: "LR",
        wb_a3: "LBR",
        woe_id: 23424876,
        woe_id_eh: 23424876,
        woe_note: "Exact WOE match as country",
        adm0_iso: "LBR",
        adm0_diff: null,
        adm0_tlc: "LBR",
        adm0_a3_us: "LBR",
        adm0_a3_fr: "LBR",
        adm0_a3_ru: "LBR",
        adm0_a3_es: "LBR",
        adm0_a3_cn: "LBR",
        adm0_a3_tw: "LBR",
        adm0_a3_in: "LBR",
        adm0_a3_np: "LBR",
        adm0_a3_pk: "LBR",
        adm0_a3_de: "LBR",
        adm0_a3_gb: "LBR",
        adm0_a3_br: "LBR",
        adm0_a3_il: "LBR",
        adm0_a3_ps: "LBR",
        adm0_a3_sa: "LBR",
        adm0_a3_eg: "LBR",
        adm0_a3_ma: "LBR",
        adm0_a3_pt: "LBR",
        adm0_a3_ar: "LBR",
        adm0_a3_jp: "LBR",
        adm0_a3_ko: "LBR",
        adm0_a3_vn: "LBR",
        adm0_a3_tr: "LBR",
        adm0_a3_id: "LBR",
        adm0_a3_pl: "LBR",
        adm0_a3_gr: "LBR",
        adm0_a3_it: "LBR",
        adm0_a3_nl: "LBR",
        adm0_a3_se: "LBR",
        adm0_a3_bd: "LBR",
        adm0_a3_ua: "LBR",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Western Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 7,
        long_len: 7,
        abbrev_len: 7,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: -9.460379,
        label_y: 6.447177,
        ne_id: 1159321015,
        wikidataid: "Q1014",
        name_ar: "ليبيريا",
        name_bn: "লাইবেরিয়া",
        name_de: "Liberia",
        name_en: "Liberia",
        name_es: "Liberia",
        name_fa: "لیبریا",
        name_fr: "Liberia",
        name_el: "Λιβερία",
        name_he: "ליבריה",
        name_hi: "लाइबेरिया",
        name_hu: "Libéria",
        name_id: "Liberia",
        name_it: "Liberia",
        name_ja: "リベリア",
        name_ko: "라이베리아",
        name_nl: "Liberia",
        name_pl: "Liberia",
        name_pt: "Libéria",
        name_ru: "Либерия",
        name_sv: "Liberia",
        name_tr: "Liberya",
        name_uk: "Ліберія",
        name_ur: "لائبیریا",
        name_vi: "Liberia",
        name_zh: "利比里亚",
        name_zht: "賴比瑞亞",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "LBR.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-11.507519531249983, 6.906542968749989],
            [-11.454541015624983, 6.951220703124989],
            [-11.376660156249983, 7.094677734374997],
            [-11.267675781249977, 7.232617187499997],
            [-11.166113281249977, 7.314404296874997],
            [-11.085400390624983, 7.398583984374994],
            [-11.000244140625, 7.463037109374994],
            [-10.878076171874994, 7.538232421874994],
            [-10.691308593749994, 7.736425781249991],
            [-10.6474609375, 7.759375],
            [-10.617578125, 7.896435546874997],
            [-10.570849609374989, 8.071142578124991],
            [-10.516748046874994, 8.125292968749989],
            [-10.389550781249994, 8.157617187499994],
            [-10.359814453124983, 8.187939453124997],
            [-10.314648437499983, 8.310839843749989],
            [-10.285742187499977, 8.4541015625],
            [-10.283203125, 8.485156249999989],
            [-10.233056640624994, 8.488818359374989],
            [-10.147412109374983, 8.519726562499997],
            [-10.09765625, 8.505859375],
            [-10.07568359375, 8.464599609375],
            [-10.064355468749994, 8.429882812499997],
            [-9.804736328124989, 8.519189453124994],
            [-9.781982421875, 8.537695312499991],
            [-9.768261718749983, 8.534570312499994],
            [-9.735595703125, 8.453955078124991],
            [-9.716894531249977, 8.458886718749994],
            [-9.701171875, 8.482177734375],
            [-9.683886718749989, 8.484423828124989],
            [-9.66357421875, 8.473535156249994],
            [-9.643212890624994, 8.43603515625],
            [-9.610156249999989, 8.40234375],
            [-9.553906249999983, 8.378613281249997],
            [-9.518261718749983, 8.34609375],
            [-9.522216796875, 8.260009765625],
            [-9.508496093749983, 8.17626953125],
            [-9.484130859375, 8.156982421875],
            [-9.471142578124983, 8.106982421874989],
            [-9.464550781249983, 8.052099609374991],
            [-9.451123046874983, 8.023242187499989],
            [-9.441552734374994, 7.967919921874994],
            [-9.446386718749977, 7.908496093749989],
            [-9.436328124999989, 7.86669921875],
            [-9.394921875, 7.794628906249997],
            [-9.369140625, 7.703808593749997],
            [-9.368945312499989, 7.639550781249994],
            [-9.383984374999983, 7.571875],
            [-9.411474609374977, 7.509960937499997],
            [-9.459765624999989, 7.442529296874994],
            [-9.463818359374983, 7.415869140624991],
            [-9.435107421874989, 7.3984375],
            [-9.391650390624989, 7.394921875],
            [-9.355322265624977, 7.40869140625],
            [-9.263281249999977, 7.377734374999989],
            [-9.215185546874977, 7.333300781249989],
            [-9.1728515625, 7.278417968749991],
            [-9.134814453124989, 7.250585937499991],
            [-9.117578125, 7.215917968749991],
            [-9.052343749999977, 7.225488281249994],
            [-8.9765625, 7.258886718749991],
            [-8.960986328124989, 7.274609375],
            [-8.938427734374983, 7.266162109374989],
            [-8.8896484375, 7.2626953125],
            [-8.855517578124989, 7.322802734374989],
            [-8.827929687499989, 7.391943359374991],
            [-8.769140624999977, 7.466796875],
            [-8.740234375, 7.495703125],
            [-8.732617187499983, 7.543554687499991],
            [-8.729443359374983, 7.605273437499989],
            [-8.708300781249989, 7.658886718749997],
            [-8.659765624999977, 7.688378906249994],
            [-8.607324218749994, 7.687939453124997],
            [-8.578857421875, 7.677050781249989],
            [-8.564404296874983, 7.625097656249991],
            [-8.522265624999989, 7.585546875],
            [-8.486425781249977, 7.558496093749994],
            [-8.46728515625, 7.547021484374994],
            [-8.437158203124994, 7.516406249999989],
            [-8.408740234374989, 7.411816406249997],
            [-8.296630859375, 7.074023437499989],
            [-8.302343749999977, 6.98095703125],
            [-8.324511718749989, 6.920019531249991],
            [-8.325097656249994, 6.860400390624989],
            [-8.332568359374989, 6.801562499999989],
            [-8.401220703124977, 6.705126953124989],
            [-8.603564453124989, 6.5078125],
            [-8.587890625, 6.490527343749989],
            [-8.53955078125, 6.468066406249989],
            [-8.490332031249977, 6.456396484374991],
            [-8.449902343749983, 6.4625],
            [-8.399316406249994, 6.413183593749991],
            [-8.344873046874994, 6.351269531249997],
            [-8.287109375, 6.319042968749997],
            [-8.203857421875, 6.290722656249997],
            [-8.131005859374994, 6.287548828124997],
            [-8.068945312499977, 6.298388671874989],
            [-7.981591796874994, 6.2861328125],
            [-7.888623046874983, 6.23486328125],
            [-7.855517578124989, 6.150146484375],
            [-7.833251953125, 6.076367187499997],
            [-7.800927734374994, 6.038916015624991],
            [-7.796533203124994, 5.97509765625],
            [-7.730371093749994, 5.919042968749991],
            [-7.636132812499994, 5.90771484375],
            [-7.513916015625, 5.842041015625],
            [-7.4828125, 5.845507812499989],
            [-7.469433593749983, 5.853710937499997],
            [-7.454394531249989, 5.84130859375],
            [-7.423730468749994, 5.651318359374997],
            [-7.39990234375, 5.550585937499989],
            [-7.412451171874977, 5.509912109374994],
            [-7.428906249999983, 5.477880859374991],
            [-7.429833984374994, 5.324511718749989],
            [-7.485205078124977, 5.236425781249991],
            [-7.494140625, 5.139794921874994],
            [-7.509765625, 5.108496093749991],
            [-7.568896484374989, 5.080664062499991],
            [-7.5693359375, 5.006445312499991],
            [-7.585058593749977, 4.916748046875],
            [-7.591210937499994, 4.821533203125],
            [-7.574658203124983, 4.572314453124989],
            [-7.571582031249989, 4.386425781249997],
            [-7.544970703124989, 4.351318359375],
            [-7.660009765624977, 4.366796875],
            [-7.998242187499983, 4.508691406249994],
            [-8.259033203125, 4.589990234374994],
            [-9.132177734374977, 5.054638671874997],
            [-9.374755859375, 5.241064453124991],
            [-9.654394531249977, 5.518701171874994],
            [-10.2763671875, 6.07763671875],
            [-10.418164062499983, 6.167333984374991],
            [-10.597070312499994, 6.2109375],
            [-10.707617187499977, 6.258496093749997],
            [-10.785595703124983, 6.31015625],
            [-10.849023437499994, 6.465087890625],
            [-11.004541015624994, 6.557373046875],
            [-11.291601562499977, 6.688232421875],
            [-11.507519531249983, 6.906542968749989],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 4,
        sovereignt: "Central African Republic",
        sov_a3: "CAF",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Central African Republic",
        adm0_a3: "CAF",
        geou_dif: 0,
        geounit: "Central African Republic",
        gu_a3: "CAF",
        su_dif: 0,
        subunit: "Central African Republic",
        su_a3: "CAF",
        brk_diff: 0,
        name: "Central African Rep.",
        name_long: "Central African Republic",
        brk_a3: "CAF",
        brk_name: "Central African Rep.",
        brk_group: null,
        abbrev: "C.A.R.",
        postal: "CF",
        formal_en: "Central African Republic",
        formal_fr: null,
        name_ciawf: "Central African Republic",
        note_adm0: null,
        note_brk: null,
        name_sort: "Central African Republic",
        name_alt: null,
        mapcolor7: 5,
        mapcolor8: 6,
        mapcolor9: 6,
        mapcolor13: 9,
        pop_est: 4745185,
        pop_rank: 12,
        pop_year: 2019,
        gdp_md: 2220,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        fips_10: "CT",
        iso_a2: "CF",
        iso_a2_eh: "CF",
        iso_a3: "CAF",
        iso_a3_eh: "CAF",
        iso_n3: "140",
        iso_n3_eh: "140",
        un_a3: "140",
        wb_a2: "CF",
        wb_a3: "CAF",
        woe_id: 23424792,
        woe_id_eh: 23424792,
        woe_note: "Exact WOE match as country",
        adm0_iso: "CAF",
        adm0_diff: null,
        adm0_tlc: "CAF",
        adm0_a3_us: "CAF",
        adm0_a3_fr: "CAF",
        adm0_a3_ru: "CAF",
        adm0_a3_es: "CAF",
        adm0_a3_cn: "CAF",
        adm0_a3_tw: "CAF",
        adm0_a3_in: "CAF",
        adm0_a3_np: "CAF",
        adm0_a3_pk: "CAF",
        adm0_a3_de: "CAF",
        adm0_a3_gb: "CAF",
        adm0_a3_br: "CAF",
        adm0_a3_il: "CAF",
        adm0_a3_ps: "CAF",
        adm0_a3_sa: "CAF",
        adm0_a3_eg: "CAF",
        adm0_a3_ma: "CAF",
        adm0_a3_pt: "CAF",
        adm0_a3_ar: "CAF",
        adm0_a3_jp: "CAF",
        adm0_a3_ko: "CAF",
        adm0_a3_vn: "CAF",
        adm0_a3_tr: "CAF",
        adm0_a3_id: "CAF",
        adm0_a3_pl: "CAF",
        adm0_a3_gr: "CAF",
        adm0_a3_it: "CAF",
        adm0_a3_nl: "CAF",
        adm0_a3_se: "CAF",
        adm0_a3_bd: "CAF",
        adm0_a3_ua: "CAF",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Middle Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 20,
        long_len: 24,
        abbrev_len: 6,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: 20.906897,
        label_y: 6.989681,
        ne_id: 1159320463,
        wikidataid: "Q929",
        name_ar: "جمهورية أفريقيا الوسطى",
        name_bn: "মধ্য আফ্রিকান প্রজাতন্ত্র",
        name_de: "Zentralafrikanische Republik",
        name_en: "Central African Republic",
        name_es: "República Centroafricana",
        name_fa: "جمهوری آفریقای مرکزی",
        name_fr: "République centrafricaine",
        name_el: "Κεντροαφρικανική Δημοκρατία",
        name_he: "הרפובליקה המרכז-אפריקאית",
        name_hi: "मध्य अफ़्रीकी गणराज्य",
        name_hu: "Közép-afrikai Köztársaság",
        name_id: "Republik Afrika Tengah",
        name_it: "Repubblica Centrafricana",
        name_ja: "中央アフリカ共和国",
        name_ko: "중앙아프리카 공화국",
        name_nl: "Centraal-Afrikaanse Republiek",
        name_pl: "Republika Środkowoafrykańska",
        name_pt: "República Centro-Africana",
        name_ru: "Центральноафриканская Республика",
        name_sv: "Centralafrikanska republiken",
        name_tr: "Orta Afrika Cumhuriyeti",
        name_uk: "Центральноафриканська Республіка",
        name_ur: "وسطی افریقی جمہوریہ",
        name_vi: "Cộng hòa Trung Phi",
        name_zh: "中非共和国",
        name_zht: "中非共和國",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "CAF.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [24.147363281250023, 8.665625],
            [24.19482421875, 8.653369140624989],
            [24.22089843750001, 8.608251953124991],
            [24.179980468750017, 8.461132812499997],
            [24.208398437500023, 8.369140625],
            [24.291406250000023, 8.29140625],
            [24.37548828125, 8.258447265624994],
            [24.4560546875, 8.239453125],
            [24.736718750000023, 8.191552734374994],
            [24.853320312500017, 8.137548828124991],
            [25.007226562500023, 7.96484375],
            [25.20039062500001, 7.807910156249989],
            [25.247363281250017, 7.724560546874997],
            [25.238671875000023, 7.648974609374989],
            [25.18134765625001, 7.557226562499991],
            [25.19013671875001, 7.519335937499989],
            [25.27890625, 7.427490234375],
            [25.380664062500017, 7.333398437499994],
            [25.56660156250001, 7.228710937499997],
            [25.888964843750017, 7.06494140625],
            [26.036523437500023, 6.955224609374994],
            [26.0869140625, 6.872119140624989],
            [26.169335937500023, 6.78173828125],
            [26.284570312500023, 6.699023437499989],
            [26.36181640625, 6.635302734374989],
            [26.30859375, 6.455322265625],
            [26.324609375000023, 6.396240234375],
            [26.353320312500017, 6.344921875],
            [26.420507812500006, 6.274169921875],
            [26.44746093750001, 6.183007812499994],
            [26.514257812500006, 6.069238281249994],
            [26.593652343750023, 6.017529296874997],
            [26.726367187500017, 5.998242187499997],
            [26.796484375, 5.945507812499997],
            [26.942285156250023, 5.854931640624997],
            [27.083398437500023, 5.77685546875],
            [27.143945312500023, 5.722949218749989],
            [27.18125, 5.675146484374991],
            [27.21337890625, 5.618798828124994],
            [27.229101562500006, 5.5625],
            [27.232519531250006, 5.440771484374991],
            [27.256738281250023, 5.289648437499991],
            [27.332421875000023, 5.186328124999989],
            [27.4033203125, 5.109179687499989],
            [27.11494140625001, 5.197851562499991],
            [27.071875, 5.199755859374989],
            [27.020605468750006, 5.184375],
            [26.8701171875, 5.07568359375],
            [26.822070312500017, 5.062402343749994],
            [26.767578125, 5.071923828124994],
            [26.632617187500017, 5.085205078125],
            [26.17353515625001, 5.171142578125],
            [25.819921875, 5.253710937499989],
            [25.7138671875, 5.28369140625],
            [25.52509765625001, 5.312109375],
            [25.400195312500017, 5.255908203124989],
            [25.283105468750023, 5.062695312499997],
            [25.249316406250017, 5.024560546874994],
            [25.065234375000017, 4.967431640624994],
            [24.978417968750023, 4.982958984374989],
            [24.765527343750023, 4.930078125],
            [24.437109375, 5.009960937499997],
            [24.31982421875, 4.994140625],
            [24.22773437500001, 4.953857421875],
            [23.99169921875, 4.866259765624989],
            [23.848437500000017, 4.816357421874997],
            [23.68183593750001, 4.770800781249989],
            [23.52363281250001, 4.701269531249991],
            [23.41718750000001, 4.663134765624989],
            [23.312890625000023, 4.663525390624997],
            [23.218847656250006, 4.702978515624991],
            [23.11591796875001, 4.736914062499991],
            [22.99287109375001, 4.743847656249997],
            [22.864550781250017, 4.723876953125],
            [22.755761718750023, 4.646679687499997],
            [22.711718750000017, 4.591748046874997],
            [22.6171875, 4.445556640625],
            [22.505664062500017, 4.207666015624994],
            [22.461816406250023, 4.159765625],
            [22.44970703125, 4.155126953124991],
            [22.422167968750017, 4.134960937499997],
            [21.908203125, 4.25390625],
            [21.68701171875, 4.281396484374994],
            [21.53759765625, 4.244824218749997],
            [21.350195312500006, 4.311376953124991],
            [21.268359375000017, 4.323095703124991],
            [21.229785156250017, 4.302197265624997],
            [21.125585937500006, 4.332177734374994],
            [20.95576171875001, 4.413134765624989],
            [20.79296875, 4.447314453124989],
            [20.6474609375, 4.435644531249991],
            [20.55810546875, 4.462695312499989],
            [20.48652343750001, 4.541552734374989],
            [20.3935546875, 4.686181640624994],
            [20.226367187500017, 4.829638671874989],
            [20.002343750000023, 4.944726562499994],
            [19.8625, 5.031298828124989],
            [19.806542968750023, 5.089306640624997],
            [19.68603515625, 5.121386718749989],
            [19.5009765625, 5.127490234374989],
            [19.32343750000001, 5.070751953124997],
            [19.06855468750001, 4.891406249999989],
            [18.83173828125001, 4.5234375],
            [18.69990234375001, 4.382617187499989],
            [18.594140625000023, 4.346240234374989],
            [18.567480468750006, 4.257568359375],
            [18.619921875000017, 4.116601562499994],
            [18.633691406250023, 3.954296875],
            [18.5966796875, 3.6787109375],
            [18.6103515625, 3.478417968749994],
            [18.553808593750006, 3.510205078124997],
            [18.499804687500017, 3.604101562499991],
            [18.474414062500017, 3.622998046874997],
            [18.318164062500017, 3.580810546875],
            [18.237109375000017, 3.542675781249997],
            [18.193945312500006, 3.505419921874989],
            [18.160937500000017, 3.499804687499989],
            [18.111328125, 3.551074218749989],
            [18.072265625, 3.560302734375],
            [18.0107421875, 3.550830078124989],
            [17.94794921875001, 3.5517578125],
            [17.907128906250023, 3.558398437499989],
            [17.88037109375, 3.553857421874994],
            [17.806640625, 3.584179687499997],
            [17.537695312500006, 3.66162109375],
            [17.491601562500023, 3.687304687499989],
            [17.43798828125, 3.684619140624989],
            [17.2984375, 3.6171875],
            [17.224707031250006, 3.598437499999989],
            [17.002539062500006, 3.556689453124989],
            [16.76435546875001, 3.536279296874994],
            [16.67333984375, 3.535205078124989],
            [16.610742187500023, 3.50537109375],
            [16.570410156250006, 3.463085937499997],
            [16.543066406250006, 3.36953125],
            [16.49628906250001, 3.208837890624991],
            [16.47675781250001, 3.165136718749991],
            [16.480078125, 3.100976562499994],
            [16.466210937500023, 2.993212890624989],
            [16.459570312500006, 2.896533203124989],
            [16.468554687500017, 2.831738281249997],
            [16.401269531250023, 2.701025390624991],
            [16.319628906250017, 2.542773437499989],
            [16.251757812500017, 2.406787109374989],
            [16.183398437500017, 2.270068359374989],
            [16.136132812500023, 2.36376953125],
            [16.106738281250017, 2.473486328124991],
            [16.095507812500017, 2.59921875],
            [16.101855468750017, 2.632666015624991],
            [16.08349609375, 2.670019531249991],
            [16.082128906250006, 2.678173828124997],
            [16.05927734375001, 2.772998046874989],
            [16.082421875000023, 2.839111328125],
            [16.0634765625, 2.90859375],
            [16.008203125000023, 2.976660156249991],
            [15.9580078125, 3.028710937499994],
            [15.9287109375, 3.07578125],
            [15.904882812500006, 3.095849609374994],
            [15.849316406250011, 3.103076171874989],
            [15.775, 3.127197265625],
            [15.676562500000017, 3.2296875],
            [15.580859375000017, 3.329296875],
            [15.458398437500023, 3.456835937499989],
            [15.360156250000017, 3.567138671875],
            [15.23984375, 3.7021484375],
            [15.128710937500017, 3.826904296875],
            [15.062109375, 3.947216796874997],
            [15.034863281250011, 4.016357421875],
            [15.0673828125, 4.02294921875],
            [15.115429687500011, 4.024462890624989],
            [15.135839843750006, 4.036914062499989],
            [15.136914062500011, 4.069140624999989],
            [15.0875, 4.163964843749994],
            [15.063574218750006, 4.284863281249997],
            [15.022753906250017, 4.358544921874994],
            [14.8935546875, 4.471875],
            [14.770410156250023, 4.55810546875],
            [14.73125, 4.602392578124991],
            [14.708984375, 4.665576171874989],
            [14.66171875, 5.065527343749991],
            [14.640625, 5.179052734374991],
            [14.601757812500011, 5.228808593749989],
            [14.573535156250017, 5.251708984375],
            [14.56298828125, 5.279931640624994],
            [14.568066406250011, 5.35107421875],
            [14.584375, 5.414746093749997],
            [14.58359375, 5.439648437499997],
            [14.616894531250011, 5.495507812499994],
            [14.616894531250011, 5.865136718749994],
            [14.598828125000011, 5.883984375],
            [14.577246093750006, 5.916015625],
            [14.54248046875, 5.91357421875],
            [14.503125, 5.916894531249994],
            [14.4638671875, 5.970703125],
            [14.43115234375, 6.038720703124994],
            [14.440722656250017, 6.086718749999989],
            [14.475, 6.126806640624991],
            [14.512109375000023, 6.161914062499989],
            [14.559375, 6.191210937499989],
            [14.699511718750017, 6.250244140625],
            [14.7392578125, 6.27978515625],
            [14.764062500000023, 6.316357421874997],
            [14.780371093750006, 6.36572265625],
            [14.861914062500006, 6.555712890624989],
            [14.982714843750017, 6.7453125],
            [15.034570312500023, 6.784423828125],
            [15.086328125000023, 6.909912109375],
            [15.157128906250023, 7.063574218749991],
            [15.185839843750017, 7.134912109374994],
            [15.206738281250011, 7.206152343749991],
            [15.245898437500017, 7.263574218749994],
            [15.379101562500011, 7.358154296875],
            [15.480078125, 7.523779296874991],
            [15.589257812500023, 7.515039062499994],
            [15.701269531250006, 7.488427734374994],
            [15.845019531250017, 7.475292968749997],
            [15.957617187500006, 7.507568359375],
            [16.030664062500023, 7.572119140624991],
            [16.19111328125001, 7.6234375],
            [16.37890625, 7.683544921874997],
            [16.404394531250006, 7.772363281249994],
            [16.459375, 7.818994140624994],
            [16.523242187500017, 7.859960937499991],
            [16.545312500000023, 7.865478515625],
            [16.550195312500023, 7.835888671874997],
            [16.588964843750006, 7.743359375],
            [16.668359375000023, 7.651757812499994],
            [16.784765625, 7.550976562499997],
            [16.818164062500017, 7.557324218749997],
            [16.89033203125001, 7.633691406249994],
            [17.07197265625001, 7.680810546874994],
            [17.117968750000017, 7.701904296875],
            [17.246972656250023, 7.81298828125],
            [17.402148437500017, 7.884570312499989],
            [17.436425781250023, 7.890917968749989],
            [17.49267578125, 7.909814453124994],
            [17.6494140625, 7.98359375],
            [17.760839843750006, 7.973828125],
            [17.94013671875001, 7.985449218749991],
            [18.238867187500006, 8.020361328124991],
            [18.455078125, 8.032031249999989],
            [18.56416015625001, 8.0458984375],
            [18.591601562500017, 8.060791015625],
            [18.633593750000017, 8.167724609375],
            [18.66621093750001, 8.197705078124997],
            [18.747460937500023, 8.243798828124994],
            [18.90644531250001, 8.405078124999989],
            [19.010839843750006, 8.541210937499997],
            [19.039843750000017, 8.5869140625],
            [19.042382812500023, 8.590283203124997],
            [19.063867187500023, 8.598828125],
            [19.108691406250017, 8.656152343749994],
            [19.06416015625001, 8.715429687499991],
            [18.886035156250017, 8.836035156249991],
            [18.888574218750023, 8.852490234374997],
            [18.878320312500023, 8.873193359374994],
            [18.88828125, 8.889746093749991],
            [18.95625, 8.938867187499994],
            [19.0478515625, 8.995019531249994],
            [19.1455078125, 9.015966796874991],
            [19.400292968750023, 9.011621093749994],
            [19.617480468750017, 9.023583984374994],
            [19.668359375000023, 9.020898437499994],
            [19.837695312500017, 9.049365234374989],
            [19.953515625000023, 9.075146484374997],
            [20.072656250000023, 9.133203125],
            [20.342089843750017, 9.127099609374994],
            [20.56689453125, 9.274951171874989],
            [20.631445312500006, 9.301367187499991],
            [20.65966796875, 9.324511718749989],
            [20.66816406250001, 9.347119140624997],
            [20.773242187500017, 9.405664062499994],
            [20.891015625000023, 9.527148437499989],
            [20.984179687500017, 9.636279296874989],
            [21.00947265625001, 9.713232421874991],
            [21.26386718750001, 9.974609375],
            [21.352441406250023, 9.969140625],
            [21.39599609375, 10.001367187499994],
            [21.496875, 10.175683593749994],
            [21.52802734375001, 10.207812499999989],
            [21.57578125, 10.218554687499989],
            [21.632714843750023, 10.23828125],
            [21.682714843750006, 10.289843749999989],
            [21.72578125000001, 10.366552734374991],
            [21.726171875, 10.461621093749997],
            [21.70654296875, 10.537890624999989],
            [21.70654296875, 10.574804687499991],
            [21.73066406250001, 10.608691406249989],
            [21.771484375, 10.642822265625],
            [21.96484375, 10.736669921874991],
            [22.013769531250006, 10.782031249999989],
            [22.04316406250001, 10.822705078124997],
            [22.09716796875, 10.830078125],
            [22.15625, 10.826074218749994],
            [22.193652343750017, 10.851367187499989],
            [22.2359375, 10.894140625],
            [22.36982421875001, 10.951513671874991],
            [22.49384765625001, 10.996240234374994],
            [22.6240234375, 10.977343749999989],
            [22.73017578125001, 10.954052734374997],
            [22.8173828125, 10.927197265624997],
            [22.86005859375001, 10.919677734375],
            [22.930761718750006, 10.7953125],
            [22.96435546875, 10.751806640624991],
            [23.255859375, 10.457812499999989],
            [23.312304687500017, 10.387939453125],
            [23.456640625, 10.174267578124997],
            [23.545019531250006, 10.030078124999989],
            [23.646289062500017, 9.822900390624994],
            [23.65625, 9.710351562499994],
            [23.64277343750001, 9.613916015624994],
            [23.62265625, 9.340625],
            [23.596093750000023, 9.261914062499997],
            [23.46826171875, 9.11474609375],
            [23.462792968750023, 9.048486328124994],
            [23.489062500000017, 8.993310546874994],
            [23.52802734375001, 8.970605468749994],
            [23.551855468750006, 8.943212890624991],
            [23.53730468750001, 8.815820312499994],
            [23.58320312500001, 8.765820312499997],
            [23.679296875, 8.732470703124989],
            [23.921972656250006, 8.709716796875],
            [24.048144531250017, 8.691308593749994],
            [24.147363281250023, 8.665625],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Zambia",
        sov_a3: "ZMB",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Zambia",
        adm0_a3: "ZMB",
        geou_dif: 0,
        geounit: "Zambia",
        gu_a3: "ZMB",
        su_dif: 0,
        subunit: "Zambia",
        su_a3: "ZMB",
        brk_diff: 0,
        name: "Zambia",
        name_long: "Zambia",
        brk_a3: "ZMB",
        brk_name: "Zambia",
        brk_group: null,
        abbrev: "Zambia",
        postal: "ZM",
        formal_en: "Republic of Zambia",
        formal_fr: null,
        name_ciawf: "Zambia",
        note_adm0: null,
        note_brk: null,
        name_sort: "Zambia",
        name_alt: null,
        mapcolor7: 5,
        mapcolor8: 8,
        mapcolor9: 5,
        mapcolor13: 13,
        pop_est: 17861030,
        pop_rank: 14,
        pop_year: 2019,
        gdp_md: 23309,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "4. Lower middle income",
        fips_10: "ZA",
        iso_a2: "ZM",
        iso_a2_eh: "ZM",
        iso_a3: "ZMB",
        iso_a3_eh: "ZMB",
        iso_n3: "894",
        iso_n3_eh: "894",
        un_a3: "894",
        wb_a2: "ZM",
        wb_a3: "ZMB",
        woe_id: 23425003,
        woe_id_eh: 23425003,
        woe_note: "Exact WOE match as country",
        adm0_iso: "ZMB",
        adm0_diff: null,
        adm0_tlc: "ZMB",
        adm0_a3_us: "ZMB",
        adm0_a3_fr: "ZMB",
        adm0_a3_ru: "ZMB",
        adm0_a3_es: "ZMB",
        adm0_a3_cn: "ZMB",
        adm0_a3_tw: "ZMB",
        adm0_a3_in: "ZMB",
        adm0_a3_np: "ZMB",
        adm0_a3_pk: "ZMB",
        adm0_a3_de: "ZMB",
        adm0_a3_gb: "ZMB",
        adm0_a3_br: "ZMB",
        adm0_a3_il: "ZMB",
        adm0_a3_ps: "ZMB",
        adm0_a3_sa: "ZMB",
        adm0_a3_eg: "ZMB",
        adm0_a3_ma: "ZMB",
        adm0_a3_pt: "ZMB",
        adm0_a3_ar: "ZMB",
        adm0_a3_jp: "ZMB",
        adm0_a3_ko: "ZMB",
        adm0_a3_vn: "ZMB",
        adm0_a3_tr: "ZMB",
        adm0_a3_id: "ZMB",
        adm0_a3_pl: "ZMB",
        adm0_a3_gr: "ZMB",
        adm0_a3_it: "ZMB",
        adm0_a3_nl: "ZMB",
        adm0_a3_se: "ZMB",
        adm0_a3_bd: "ZMB",
        adm0_a3_ua: "ZMB",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Eastern Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 6,
        long_len: 6,
        abbrev_len: 6,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: 26.395298,
        label_y: -14.660804,
        ne_id: 1159321439,
        wikidataid: "Q953",
        name_ar: "زامبيا",
        name_bn: "জাম্বিয়া",
        name_de: "Sambia",
        name_en: "Zambia",
        name_es: "Zambia",
        name_fa: "زامبیا",
        name_fr: "Zambie",
        name_el: "Ζάμπια",
        name_he: "זמביה",
        name_hi: "ज़ाम्बिया",
        name_hu: "Zambia",
        name_id: "Zambia",
        name_it: "Zambia",
        name_ja: "ザンビア",
        name_ko: "잠비아",
        name_nl: "Zambia",
        name_pl: "Zambia",
        name_pt: "Zâmbia",
        name_ru: "Замбия",
        name_sv: "Zambia",
        name_tr: "Zambiya",
        name_uk: "Замбія",
        name_ur: "زیمبیا",
        name_vi: "Zambia",
        name_zh: "赞比亚",
        name_zht: "尚比亞",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "ZMB.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [30.39609375, -15.64306640625],
            [30.25068359375001, -15.643457031250009],
            [29.994921875000017, -15.64404296875],
            [29.729589843750006, -15.644628906250006],
            [29.4873046875, -15.69677734375],
            [29.287890625000017, -15.776464843750006],
            [29.050585937500017, -15.901171875],
            [28.973046875000023, -15.950097656250009],
            [28.9130859375, -15.98779296875],
            [28.875585937500006, -16.0361328125],
            [28.856738281250017, -16.14228515625001],
            [28.856738281250017, -16.30615234375],
            [28.83271484375001, -16.424121093750003],
            [28.760546875000017, -16.53212890625001],
            [28.760644531250023, -16.53193359375001],
            [28.399804687500023, -16.66279296875001],
            [28.16376953125001, -16.76972656250001],
            [27.932226562500006, -16.89619140625001],
            [27.75654296875001, -17.060351562500003],
            [27.63671875, -17.262109375],
            [27.437890625000023, -17.51191406250001],
            [27.235742187500023, -17.728320312500003],
            [27.020800781250017, -17.95839843750001],
            [26.779882812500006, -18.04150390625],
            [26.577539062500023, -18.022558593750006],
            [26.333398437500023, -17.929296875],
            [26.139550781250023, -17.91171875],
            [25.995898437500017, -17.969824218750006],
            [25.86328125, -17.951953125],
            [25.741601562500023, -17.858203125],
            [25.6396484375, -17.82412109375001],
            [25.55712890625, -17.84951171875001],
            [25.451757812500006, -17.84511718750001],
            [25.2587890625, -17.793554687500006],
            [25.092187500000023, -17.634375],
            [25.001757812500017, -17.56855468750001],
            [24.932421875000017, -17.54345703125],
            [24.73291015625, -17.51777343750001],
            [24.27490234375, -17.481054687500006],
            [24.227148437500006, -17.489550781250003],
            [24.036914062500017, -17.52089843750001],
            [23.799218750000023, -17.56015625],
            [23.59492187500001, -17.599414062500003],
            [23.380664062500017, -17.640625],
            [23.181640625, -17.474414062500003],
            [22.955859375000017, -17.285742187500006],
            [22.721972656250017, -17.075292968750006],
            [22.545996093750006, -16.910253906250006],
            [22.45947265625, -16.81513671875001],
            [22.305078125000023, -16.689550781250006],
            [22.193945312500006, -16.628125],
            [22.150683593750017, -16.59716796875],
            [22.04023437500001, -16.262792968750006],
            [21.979785156250017, -15.95556640625],
            [21.979785156250017, -15.72412109375],
            [21.97968750000001, -15.403222656250009],
            [21.979589843750006, -15.082324218750003],
            [21.9794921875, -14.761425781250011],
            [21.979394531250023, -14.440527343750006],
            [21.979296875000017, -14.11962890625],
            [21.979101562500006, -13.798730468750009],
            [21.979101562500006, -13.477734375000011],
            [21.97900390625, -13.156835937500006],
            [21.978906250000023, -13.0009765625],
            [22.209570312500006, -13.0009765625],
            [22.470996093750017, -13.0009765625],
            [22.74433593750001, -13.0009765625],
            [23.04150390625, -13.0009765625],
            [23.338671875000017, -13.0009765625],
            [23.635839843750006, -13.0009765625],
            [23.843164062500023, -13.0009765625],
            [23.8974609375, -12.998242187500011],
            [23.962988281250006, -12.988476562500011],
            [23.968066406250017, -12.956933593750009],
            [23.882421875, -12.799023437500011],
            [23.886523437500017, -12.743261718750006],
            [23.909375, -12.636132812500009],
            [23.944726562500023, -12.54375],
            [23.991308593750006, -12.422167968750003],
            [23.996484375000023, -12.350683593750006],
            [23.958886718750023, -12.117773437500006],
            [23.962304687500023, -11.987890625],
            [23.973437500000017, -11.852929687500009],
            [23.98388671875, -11.725],
            [23.970996093750017, -11.635839843750006],
            [23.98681640625, -11.587207031250003],
            [24.0146484375, -11.517675781250006],
            [24.029296875, -11.439160156250011],
            [24.046679687500017, -11.405371093750006],
            [24.041406250000023, -11.374121093750006],
            [24.02558593750001, -11.315625],
            [24.010058593750017, -11.184765625000011],
            [23.98828125, -11.002832031250009],
            [23.96650390625001, -10.871777343750011],
            [24.002734375000017, -10.879101562500011],
            [24.078417968750017, -10.891503906250009],
            [24.115136718750023, -10.955664062500006],
            [24.136523437500017, -11.025976562500006],
            [24.18720703125001, -11.029980468750011],
            [24.319921875, -11.07177734375],
            [24.36572265625, -11.1298828125],
            [24.396289062500017, -11.255175781250003],
            [24.3779296875, -11.3193359375],
            [24.33515625000001, -11.371289062500011],
            [24.3779296875, -11.417089843750006],
            [24.466601562500017, -11.44765625],
            [24.5185546875, -11.4384765625],
            [24.668261718750017, -11.352929687500009],
            [24.728125, -11.337792968750009],
            [24.80634765625001, -11.321191406250009],
            [24.876855468750023, -11.299121093750003],
            [25.075976562500017, -11.260058593750003],
            [25.184863281250017, -11.24296875],
            [25.245996093750023, -11.21240234375],
            [25.28876953125001, -11.21240234375],
            [25.3193359375, -11.236914062500006],
            [25.291796875000017, -11.325488281250003],
            [25.282617187500023, -11.404980468750011],
            [25.320703125000023, -11.553515625],
            [25.349414062500017, -11.623046875],
            [25.413378906250017, -11.673535156250011],
            [25.4599609375, -11.699804687500006],
            [25.51191406250001, -11.75341796875],
            [25.61884765625001, -11.744140625],
            [25.854882812500023, -11.820117187500003],
            [25.926562500000017, -11.855273437500003],
            [26.025976562500006, -11.89013671875],
            [26.09638671875001, -11.903222656250009],
            [26.339648437500017, -11.929882812500011],
            [26.4296875, -11.947851562500006],
            [26.59638671875001, -11.972070312500009],
            [26.72968750000001, -11.975976562500009],
            [26.824023437500017, -11.965234375],
            [26.890429687500017, -11.943554687500011],
            [26.93085937500001, -11.919335937500009],
            [26.949609375000023, -11.898828125],
            [26.976855468750017, -11.824609375],
            [27.026660156250017, -11.663769531250011],
            [27.04609375000001, -11.615917968750011],
            [27.09541015625001, -11.59375],
            [27.1591796875, -11.579199218750006],
            [27.196386718750006, -11.605078125],
            [27.238085937500017, -11.783496093750003],
            [27.423632812500017, -11.944531250000011],
            [27.48701171875001, -12.0796875],
            [27.53339843750001, -12.1953125],
            [27.573828125, -12.22705078125],
            [27.644335937500017, -12.266796875000011],
            [27.7568359375, -12.280859375],
            [27.857421875, -12.284863281250011],
            [28.06884765625, -12.3681640625],
            [28.2373046875, -12.4345703125],
            [28.357714843750017, -12.48203125],
            [28.412890625000017, -12.51806640625],
            [28.451464843750017, -12.577441406250003],
            [28.474414062500017, -12.623339843750003],
            [28.51123046875, -12.7421875],
            [28.550878906250006, -12.836132812500011],
            [28.61542968750001, -12.854101562500006],
            [28.672949218750006, -12.861328125],
            [28.730078125, -12.925488281250011],
            [28.77314453125001, -12.98193359375],
            [28.858789062500023, -13.119433593750003],
            [28.921679687500017, -13.214648437500003],
            [28.942285156250023, -13.30712890625],
            [29.014257812500006, -13.368847656250011],
            [29.111621093750017, -13.395117187500006],
            [29.20185546875001, -13.398339843750009],
            [29.253710937500017, -13.370800781250011],
            [29.3818359375, -13.322851562500006],
            [29.4814453125, -13.26796875],
            [29.55419921875, -13.248925781250009],
            [29.59716796875, -13.260546875],
            [29.630273437500023, -13.298535156250011],
            [29.64765625000001, -13.372949218750009],
            [29.651757812500023, -13.414355468750003],
            [29.72265625, -13.453808593750011],
            [29.775195312500017, -13.438085937500006],
            [29.795312500000023, -13.392773437500011],
            [29.796484375, -13.369726562500006],
            [29.796289062500023, -13.16748046875],
            [29.79609375000001, -12.992089843750009],
            [29.795800781250023, -12.827050781250009],
            [29.79560546875001, -12.625878906250009],
            [29.795507812500006, -12.450585937500009],
            [29.795312500000023, -12.30615234375],
            [29.79511718750001, -12.155468750000011],
            [29.749609375, -12.1640625],
            [29.691992187500006, -12.198339843750006],
            [29.55976562500001, -12.202441406250003],
            [29.508203125000023, -12.228222656250011],
            [29.491992187500017, -12.266894531250003],
            [29.502246093750017, -12.317578125000011],
            [29.5048828125, -12.386132812500009],
            [29.48554687500001, -12.41845703125],
            [29.427539062500017, -12.43125],
            [29.34375, -12.40478515625],
            [29.191210937500017, -12.370214843750006],
            [29.064355468750023, -12.348828125000011],
            [28.973437500000017, -12.2578125],
            [28.85, -12.120507812500009],
            [28.769433593750023, -12.05126953125],
            [28.574609375000023, -11.908105468750009],
            [28.541601562500006, -11.879199218750003],
            [28.482519531250006, -11.812109375],
            [28.43183593750001, -11.698339843750006],
            [28.407031250000017, -11.622851562500003],
            [28.383398437500006, -11.566699218750003],
            [28.357226562500017, -11.483007812500006],
            [28.404199218750023, -11.354394531250009],
            [28.4703125, -11.109570312500011],
            [28.517968750000023, -10.933203125],
            [28.544238281250017, -10.80234375],
            [28.63886718750001, -10.669238281250003],
            [28.6455078125, -10.550195312500009],
            [28.607421875, -10.397363281250009],
            [28.6171875, -10.31298828125],
            [28.62353515625, -10.098828125000011],
            [28.62890625, -9.91875],
            [28.63007812500001, -9.83125],
            [28.60419921875001, -9.678808593750006],
            [28.54052734375, -9.510058593750003],
            [28.400195312500017, -9.275],
            [28.400683593750017, -9.224804687500011],
            [28.484277343750023, -9.16943359375],
            [28.616503906250017, -9.072265625],
            [28.68125, -9.0146484375],
            [28.7587890625, -8.9326171875],
            [28.793554687500006, -8.891015625],
            [28.869531250000023, -8.785839843750011],
            [28.917773437500017, -8.700585937500009],
            [28.934472656250023, -8.590234375],
            [28.89814453125001, -8.485449218750006],
            [28.972265625, -8.464941406250006],
            [29.215625, -8.427832031250006],
            [29.483789062500023, -8.386914062500011],
            [29.766210937500006, -8.34375],
            [30.051367187500006, -8.30029296875],
            [30.327539062500023, -8.258203125],
            [30.577929687500017, -8.220019531250003],
            [30.75117187500001, -8.193652343750003],
            [30.776757812500023, -8.265820312500011],
            [30.830664062500006, -8.385546875],
            [30.891992187500023, -8.473730468750006],
            [30.968359375, -8.550976562500011],
            [31.03339843750001, -8.59765625],
            [31.07636718750001, -8.611914062500006],
            [31.3505859375, -8.60703125],
            [31.44921875, -8.65390625],
            [31.53486328125001, -8.71328125],
            [31.55625, -8.80546875],
            [31.61279296875, -8.86328125],
            [31.673632812500017, -8.908789062500006],
            [31.7, -8.914355468750003],
            [31.744726562500006, -8.903222656250009],
            [31.81806640625001, -8.902246093750009],
            [31.886132812500023, -8.921972656250006],
            [31.91865234375001, -8.9421875],
            [31.921875, -9.019433593750009],
            [31.94257812500001, -9.054003906250003],
            [32.03535156250001, -9.0673828125],
            [32.12978515625002, -9.073339843750006],
            [32.22089843750001, -9.125585937500006],
            [32.3193359375, -9.134863281250006],
            [32.433203125, -9.156347656250006],
            [32.48710937500002, -9.212695312500003],
            [32.6083984375, -9.2705078125],
            [32.75664062500002, -9.322265625],
            [32.86328125, -9.380859375],
            [32.919921875, -9.407421875000011],
            [32.92333984375, -9.433984375],
            [32.95107421875002, -9.484179687500003],
            [32.97988281250002, -9.5203125],
            [32.98212890625001, -9.573632812500009],
            [32.99599609375002, -9.622851562500003],
            [33.03779296875001, -9.635058593750003],
            [33.07246093750001, -9.63818359375],
            [33.1044921875, -9.602636718750006],
            [33.148046875, -9.603515625],
            [33.19570312500002, -9.626171875000011],
            [33.21269531250002, -9.683007812500009],
            [33.25, -9.759570312500003],
            [33.31044921875002, -9.811816406250003],
            [33.35097656250002, -9.862207031250009],
            [33.33710937500001, -9.954003906250009],
            [33.3115234375, -10.037988281250009],
            [33.3935546875, -10.120898437500003],
            [33.500097656250006, -10.19970703125],
            [33.52890625, -10.234667968750003],
            [33.53759765625, -10.3515625],
            [33.5537109375, -10.391308593750011],
            [33.62617187500001, -10.488574218750003],
            [33.66152343750002, -10.553125],
            [33.65908203125002, -10.590527343750011],
            [33.46474609375002, -10.783105468750009],
            [33.403125, -10.8017578125],
            [33.34492187500001, -10.812695312500011],
            [33.29277343750002, -10.85234375],
            [33.261328125, -10.893359375],
            [33.27275390625002, -10.9150390625],
            [33.29326171875002, -10.981152343750011],
            [33.33867187500002, -11.085156250000011],
            [33.37978515625002, -11.157910156250011],
            [33.34550781250002, -11.249121093750006],
            [33.26835937500002, -11.40390625],
            [33.23271484375002, -11.417675781250011],
            [33.22636718750002, -11.534863281250011],
            [33.25, -11.57763671875],
            [33.28828125000001, -11.611132812500003],
            [33.30390625000001, -11.690820312500009],
            [33.30507812500002, -11.8],
            [33.30097656250001, -11.88818359375],
            [33.25234375000002, -12.112597656250003],
            [33.34013671875002, -12.308300781250011],
            [33.37001953125002, -12.3296875],
            [33.49140625000001, -12.3310546875],
            [33.512304687500006, -12.347753906250006],
            [33.48320312500002, -12.403417968750006],
            [33.4306640625, -12.46044921875],
            [33.39794921875, -12.48984375],
            [33.24345703125002, -12.556542968750009],
            [33.021582031250006, -12.63046875],
            [32.975195312500006, -12.701367187500011],
            [32.94560546875002, -12.804394531250011],
            [32.97050781250002, -12.86474609375],
            [33, -12.899609375000011],
            [32.99042968750001, -12.989453125000011],
            [32.97109375000002, -13.084277343750003],
            [32.977636718750006, -13.158886718750011],
            [32.96757812500002, -13.225],
            [32.938574218750006, -13.257421875],
            [32.89970703125002, -13.35703125],
            [32.85185546875002, -13.45703125],
            [32.8140625, -13.502734375],
            [32.758398437500006, -13.55029296875],
            [32.67041015625, -13.590429687500006],
            [32.67207031250001, -13.6103515625],
            [32.77177734375002, -13.656542968750003],
            [32.797460937500006, -13.6884765625],
            [32.806738281250006, -13.710253906250003],
            [32.78535156250001, -13.7314453125],
            [32.76513671875, -13.761035156250003],
            [32.81103515625, -13.791601562500006],
            [32.8671875, -13.8173828125],
            [32.92031250000002, -13.883886718750006],
            [32.96757812500002, -13.976855468750003],
            [32.98125, -14.009375],
            [32.99208984375002, -14.022167968750011],
            [33.00927734375, -14.023730468750003],
            [33.04238281250002, -14.010058593750003],
            [33.103613281250006, -13.959179687500011],
            [33.148046875, -13.94091796875],
            [33.201757812500006, -14.013378906250011],
            [32.98710937500002, -14.0849609375],
            [32.87451171875, -14.122460937500009],
            [32.55322265625, -14.229589843750006],
            [32.27285156250002, -14.323046875],
            [32.19990234375001, -14.3408203125],
            [32.05449218750002, -14.386523437500003],
            [31.98212890625001, -14.414453125],
            [31.728906250000023, -14.49609375],
            [31.623046875, -14.53671875],
            [31.537890625000017, -14.5771484375],
            [31.328515625000023, -14.6376953125],
            [31.130859375, -14.694628906250003],
            [30.915136718750006, -14.753320312500009],
            [30.67333984375, -14.819140625],
            [30.537695312500006, -14.866503906250003],
            [30.446093750000017, -14.907519531250003],
            [30.231835937500023, -14.990332031250006],
            [30.221777343750006, -15.010546875],
            [30.225, -15.06689453125],
            [30.25214843750001, -15.183203125],
            [30.3056640625, -15.288867187500003],
            [30.3505859375, -15.349707031250006],
            [30.3798828125, -15.505859375],
            [30.39609375, -15.64306640625],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 4,
        sovereignt: "Sierra Leone",
        sov_a3: "SLE",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Sierra Leone",
        adm0_a3: "SLE",
        geou_dif: 0,
        geounit: "Sierra Leone",
        gu_a3: "SLE",
        su_dif: 0,
        subunit: "Sierra Leone",
        su_a3: "SLE",
        brk_diff: 0,
        name: "Sierra Leone",
        name_long: "Sierra Leone",
        brk_a3: "SLE",
        brk_name: "Sierra Leone",
        brk_group: null,
        abbrev: "S.L.",
        postal: "SL",
        formal_en: "Republic of Sierra Leone",
        formal_fr: null,
        name_ciawf: "Sierra Leone",
        note_adm0: null,
        note_brk: null,
        name_sort: "Sierra Leone",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 4,
        mapcolor9: 1,
        mapcolor13: 7,
        pop_est: 7813215,
        pop_rank: 13,
        pop_year: 2019,
        gdp_md: 4121,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        fips_10: "SL",
        iso_a2: "SL",
        iso_a2_eh: "SL",
        iso_a3: "SLE",
        iso_a3_eh: "SLE",
        iso_n3: "694",
        iso_n3_eh: "694",
        un_a3: "694",
        wb_a2: "SL",
        wb_a3: "SLE",
        woe_id: 23424946,
        woe_id_eh: 23424946,
        woe_note: "Exact WOE match as country",
        adm0_iso: "SLE",
        adm0_diff: null,
        adm0_tlc: "SLE",
        adm0_a3_us: "SLE",
        adm0_a3_fr: "SLE",
        adm0_a3_ru: "SLE",
        adm0_a3_es: "SLE",
        adm0_a3_cn: "SLE",
        adm0_a3_tw: "SLE",
        adm0_a3_in: "SLE",
        adm0_a3_np: "SLE",
        adm0_a3_pk: "SLE",
        adm0_a3_de: "SLE",
        adm0_a3_gb: "SLE",
        adm0_a3_br: "SLE",
        adm0_a3_il: "SLE",
        adm0_a3_ps: "SLE",
        adm0_a3_sa: "SLE",
        adm0_a3_eg: "SLE",
        adm0_a3_ma: "SLE",
        adm0_a3_pt: "SLE",
        adm0_a3_ar: "SLE",
        adm0_a3_jp: "SLE",
        adm0_a3_ko: "SLE",
        adm0_a3_vn: "SLE",
        adm0_a3_tr: "SLE",
        adm0_a3_id: "SLE",
        adm0_a3_pl: "SLE",
        adm0_a3_gr: "SLE",
        adm0_a3_it: "SLE",
        adm0_a3_nl: "SLE",
        adm0_a3_se: "SLE",
        adm0_a3_bd: "SLE",
        adm0_a3_ua: "SLE",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Western Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 12,
        long_len: 12,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: -11.763677,
        label_y: 8.617449,
        ne_id: 1159321251,
        wikidataid: "Q1044",
        name_ar: "سيراليون",
        name_bn: "সিয়েরা লিওন",
        name_de: "Sierra Leone",
        name_en: "Sierra Leone",
        name_es: "Sierra Leona",
        name_fa: "سیرالئون",
        name_fr: "Sierra Leone",
        name_el: "Σιέρα Λεόνε",
        name_he: "סיירה לאון",
        name_hi: "सिएरा लियोन",
        name_hu: "Sierra Leone",
        name_id: "Sierra Leone",
        name_it: "Sierra Leone",
        name_ja: "シエラレオネ",
        name_ko: "시에라리온",
        name_nl: "Sierra Leone",
        name_pl: "Sierra Leone",
        name_pt: "Serra Leoa",
        name_ru: "Сьерра-Леоне",
        name_sv: "Sierra Leone",
        name_tr: "Sierra Leone",
        name_uk: "Сьєрра-Леоне",
        name_ur: "سیرالیون",
        name_vi: "Sierra Leone",
        name_zh: "塞拉利昂",
        name_zht: "獅子山",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "SLE.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-10.283203125, 8.485156249999989],
              [-10.285742187499977, 8.4541015625],
              [-10.314648437499983, 8.310839843749989],
              [-10.359814453124983, 8.187939453124997],
              [-10.389550781249994, 8.157617187499994],
              [-10.516748046874994, 8.125292968749989],
              [-10.570849609374989, 8.071142578124991],
              [-10.617578125, 7.896435546874997],
              [-10.6474609375, 7.759375],
              [-10.691308593749994, 7.736425781249991],
              [-10.878076171874994, 7.538232421874994],
              [-11.000244140625, 7.463037109374994],
              [-11.085400390624983, 7.398583984374994],
              [-11.166113281249977, 7.314404296874997],
              [-11.267675781249977, 7.232617187499997],
              [-11.376660156249983, 7.094677734374997],
              [-11.454541015624983, 6.951220703124989],
              [-11.507519531249983, 6.906542968749989],
              [-11.547509765624994, 6.946972656249997],
              [-11.733447265624989, 7.088574218749997],
              [-11.92919921875, 7.183544921874997],
              [-12.346630859374983, 7.341796875],
              [-12.485644531249989, 7.386279296874989],
              [-12.480664062499983, 7.442480468749991],
              [-12.432714843749977, 7.545019531249991],
              [-12.510449218749983, 7.665722656249997],
              [-12.480273437499989, 7.753271484374991],
              [-12.510449218749983, 7.753369140624997],
              [-12.570214843749994, 7.700585937499994],
              [-12.697607421874977, 7.715869140624989],
              [-12.781933593749983, 7.791113281249991],
              [-12.850878906249989, 7.818701171874991],
              [-12.880957031249977, 7.856640625],
              [-12.925146484374977, 8.05517578125],
              [-12.956933593749994, 8.145312499999989],
              [-13.020800781249989, 8.200927734375],
              [-13.148974609374989, 8.214599609375],
              [-13.201757812499977, 8.335839843749994],
              [-13.272753906249989, 8.429736328124989],
              [-13.26123046875, 8.487597656249989],
              [-13.203320312499983, 8.484277343749994],
              [-13.157958984375, 8.442285156249994],
              [-13.085009765624989, 8.424755859374997],
              [-12.994238281249977, 8.526464843749991],
              [-12.912939453124977, 8.58154296875],
              [-12.894091796874989, 8.629785156249994],
              [-12.904003906249983, 8.65625],
              [-12.953369140625, 8.615136718749994],
              [-13.088232421874977, 8.625732421875],
              [-13.121630859374989, 8.588769531249994],
              [-13.181835937499983, 8.576904296875],
              [-13.228417968749994, 8.695898437499991],
              [-13.226171874999977, 8.765966796874991],
              [-13.206933593749994, 8.843115234374991],
              [-13.071044921875, 8.856347656249994],
              [-13.059472656249994, 8.881152343749989],
              [-13.153710937499994, 8.897705078125],
              [-13.271630859374994, 8.987402343749991],
              [-13.292675781249983, 9.04921875],
              [-13.234228515624977, 9.070117187499989],
              [-13.178369140624994, 9.060888671874991],
              [-13.1298828125, 9.047558593749997],
              [-13.077294921874994, 9.069628906249989],
              [-13.028027343749983, 9.103564453124989],
              [-12.998632812499977, 9.146923828124997],
              [-12.958789062499989, 9.263330078124994],
              [-12.831103515624989, 9.30224609375],
              [-12.755859375, 9.373583984374989],
              [-12.684423828124977, 9.484179687499989],
              [-12.651660156249989, 9.561914062499994],
              [-12.622167968749977, 9.600634765624989],
              [-12.603613281249977, 9.634228515624997],
              [-12.58984375, 9.671142578125],
              [-12.557861328125, 9.704980468749994],
              [-12.524365234374983, 9.787207031249991],
              [-12.50146484375, 9.862158203124991],
              [-12.427978515625, 9.898144531249997],
              [-12.277734375, 9.929785156249991],
              [-12.142333984375, 9.875390625],
              [-11.922753906249994, 9.922753906249994],
              [-11.911083984374983, 9.993017578124991],
              [-11.710058593749977, 9.994189453124989],
              [-11.471923828125, 9.995458984374991],
              [-11.273632812499983, 9.996533203124997],
              [-11.205664062499977, 9.977734375],
              [-11.180859374999983, 9.925341796874989],
              [-11.115673828124983, 9.843164062499994],
              [-11.047460937499977, 9.786328125],
              [-10.963085937499983, 9.66162109375],
              [-10.864794921874989, 9.516455078124991],
              [-10.758593749999989, 9.385351562499991],
              [-10.690527343749977, 9.314257812499989],
              [-10.682714843749977, 9.289355468749989],
              [-10.687646484374994, 9.261132812499994],
              [-10.721240234374989, 9.194482421874994],
              [-10.749951171874983, 9.122363281249989],
              [-10.747021484374983, 9.095263671874989],
              [-10.726855468749989, 9.081689453124994],
              [-10.615966796875, 9.059179687499991],
              [-10.605761718749989, 8.978808593749989],
              [-10.605615234374994, 8.867578125],
              [-10.5517578125, 8.763769531249991],
              [-10.500537109374989, 8.687548828124989],
              [-10.503125, 8.660302734374994],
              [-10.628466796874989, 8.529980468749997],
              [-10.677343749999977, 8.400585937499997],
              [-10.7021484375, 8.364208984374997],
              [-10.712109374999983, 8.335253906249989],
              [-10.686962890624983, 8.321679687499994],
              [-10.652636718749989, 8.330273437499997],
              [-10.60400390625, 8.319482421874994],
              [-10.557714843749977, 8.315673828125],
              [-10.496435546874977, 8.362109374999989],
              [-10.394433593749994, 8.48095703125],
              [-10.360058593749983, 8.495507812499994],
              [-10.283203125, 8.485156249999989],
            ],
          ],
          [
            [
              [-12.526074218749983, 7.436328124999989],
              [-12.540625, 7.410253906249991],
              [-12.607177734375, 7.474511718749994],
              [-12.951611328124983, 7.570849609374989],
              [-12.854394531249994, 7.622021484374997],
              [-12.615234375, 7.63720703125],
              [-12.544189453125, 7.607373046874997],
              [-12.5125, 7.582421875],
              [-12.500634765624994, 7.535107421874997],
              [-12.526074218749983, 7.436328124999989],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Guinea",
        sov_a3: "GIN",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Guinea",
        adm0_a3: "GIN",
        geou_dif: 0,
        geounit: "Guinea",
        gu_a3: "GIN",
        su_dif: 0,
        subunit: "Guinea",
        su_a3: "GIN",
        brk_diff: 0,
        name: "Guinea",
        name_long: "Guinea",
        brk_a3: "GIN",
        brk_name: "Guinea",
        brk_group: null,
        abbrev: "Gin.",
        postal: "GN",
        formal_en: "Republic of Guinea",
        formal_fr: null,
        name_ciawf: "Guinea",
        note_adm0: null,
        note_brk: null,
        name_sort: "Guinea",
        name_alt: null,
        mapcolor7: 6,
        mapcolor8: 3,
        mapcolor9: 7,
        mapcolor13: 2,
        pop_est: 12771246,
        pop_rank: 14,
        pop_year: 2019,
        gdp_md: 12296,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        fips_10: "GV",
        iso_a2: "GN",
        iso_a2_eh: "GN",
        iso_a3: "GIN",
        iso_a3_eh: "GIN",
        iso_n3: "324",
        iso_n3_eh: "324",
        un_a3: "324",
        wb_a2: "GN",
        wb_a3: "GIN",
        woe_id: 23424835,
        woe_id_eh: 23424835,
        woe_note: "Exact WOE match as country",
        adm0_iso: "GIN",
        adm0_diff: null,
        adm0_tlc: "GIN",
        adm0_a3_us: "GIN",
        adm0_a3_fr: "GIN",
        adm0_a3_ru: "GIN",
        adm0_a3_es: "GIN",
        adm0_a3_cn: "GIN",
        adm0_a3_tw: "GIN",
        adm0_a3_in: "GIN",
        adm0_a3_np: "GIN",
        adm0_a3_pk: "GIN",
        adm0_a3_de: "GIN",
        adm0_a3_gb: "GIN",
        adm0_a3_br: "GIN",
        adm0_a3_il: "GIN",
        adm0_a3_ps: "GIN",
        adm0_a3_sa: "GIN",
        adm0_a3_eg: "GIN",
        adm0_a3_ma: "GIN",
        adm0_a3_pt: "GIN",
        adm0_a3_ar: "GIN",
        adm0_a3_jp: "GIN",
        adm0_a3_ko: "GIN",
        adm0_a3_vn: "GIN",
        adm0_a3_tr: "GIN",
        adm0_a3_id: "GIN",
        adm0_a3_pl: "GIN",
        adm0_a3_gr: "GIN",
        adm0_a3_it: "GIN",
        adm0_a3_nl: "GIN",
        adm0_a3_se: "GIN",
        adm0_a3_bd: "GIN",
        adm0_a3_ua: "GIN",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Western Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 6,
        long_len: 6,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: -10.016402,
        label_y: 10.618516,
        ne_id: 1159320795,
        wikidataid: "Q1006",
        name_ar: "غينيا",
        name_bn: "গিনি",
        name_de: "Guinea",
        name_en: "Guinea",
        name_es: "Guinea",
        name_fa: "گینه",
        name_fr: "Guinée",
        name_el: "Γουινέα",
        name_he: "גינאה",
        name_hi: "गिनी",
        name_hu: "Guinea",
        name_id: "Guinea",
        name_it: "Guinea",
        name_ja: "ギニア",
        name_ko: "기니",
        name_nl: "Guinee",
        name_pl: "Gwinea",
        name_pt: "Guiné",
        name_ru: "Гвинея",
        name_sv: "Guinea",
        name_tr: "Gine",
        name_uk: "Гвінея",
        name_ur: "جمہوریہ گنی",
        name_vi: "Guinée",
        name_zh: "几内亚",
        name_zht: "幾內亞",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "GIN.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-10.283203125, 8.485156249999989],
            [-10.360058593749983, 8.495507812499994],
            [-10.394433593749994, 8.48095703125],
            [-10.496435546874977, 8.362109374999989],
            [-10.557714843749977, 8.315673828125],
            [-10.60400390625, 8.319482421874994],
            [-10.652636718749989, 8.330273437499997],
            [-10.686962890624983, 8.321679687499994],
            [-10.712109374999983, 8.335253906249989],
            [-10.7021484375, 8.364208984374997],
            [-10.677343749999977, 8.400585937499997],
            [-10.628466796874989, 8.529980468749997],
            [-10.503125, 8.660302734374994],
            [-10.500537109374989, 8.687548828124989],
            [-10.5517578125, 8.763769531249991],
            [-10.605615234374994, 8.867578125],
            [-10.605761718749989, 8.978808593749989],
            [-10.615966796875, 9.059179687499991],
            [-10.726855468749989, 9.081689453124994],
            [-10.747021484374983, 9.095263671874989],
            [-10.749951171874983, 9.122363281249989],
            [-10.721240234374989, 9.194482421874994],
            [-10.687646484374994, 9.261132812499994],
            [-10.682714843749977, 9.289355468749989],
            [-10.690527343749977, 9.314257812499989],
            [-10.758593749999989, 9.385351562499991],
            [-10.864794921874989, 9.516455078124991],
            [-10.963085937499983, 9.66162109375],
            [-11.047460937499977, 9.786328125],
            [-11.115673828124983, 9.843164062499994],
            [-11.180859374999983, 9.925341796874989],
            [-11.205664062499977, 9.977734375],
            [-11.273632812499983, 9.996533203124997],
            [-11.471923828125, 9.995458984374991],
            [-11.710058593749977, 9.994189453124989],
            [-11.911083984374983, 9.993017578124991],
            [-11.922753906249994, 9.922753906249994],
            [-12.142333984375, 9.875390625],
            [-12.277734375, 9.929785156249991],
            [-12.427978515625, 9.898144531249997],
            [-12.50146484375, 9.862158203124991],
            [-12.524365234374983, 9.787207031249991],
            [-12.557861328125, 9.704980468749994],
            [-12.58984375, 9.671142578125],
            [-12.603613281249977, 9.634228515624997],
            [-12.622167968749977, 9.600634765624989],
            [-12.651660156249989, 9.561914062499994],
            [-12.684423828124977, 9.484179687499989],
            [-12.755859375, 9.373583984374989],
            [-12.831103515624989, 9.30224609375],
            [-12.958789062499989, 9.263330078124994],
            [-12.998632812499977, 9.146923828124997],
            [-13.028027343749983, 9.103564453124989],
            [-13.077294921874994, 9.069628906249989],
            [-13.1298828125, 9.047558593749997],
            [-13.178369140624994, 9.060888671874991],
            [-13.234228515624977, 9.070117187499989],
            [-13.292675781249983, 9.04921875],
            [-13.302636718749994, 9.078369140625],
            [-13.269482421874983, 9.170556640624994],
            [-13.2958984375, 9.218505859375],
            [-13.396093749999977, 9.314306640624991],
            [-13.405566406249989, 9.360644531249989],
            [-13.436279296875, 9.4203125],
            [-13.568261718749994, 9.543408203124997],
            [-13.691357421874983, 9.535791015624994],
            [-13.657128906249994, 9.639111328124997],
            [-13.65869140625, 9.7763671875],
            [-13.700488281249989, 9.851269531249997],
            [-13.689794921874977, 9.927783203124989],
            [-13.712646484375, 9.922949218749991],
            [-13.753710937499989, 9.870263671874994],
            [-13.820117187499989, 9.88720703125],
            [-13.954638671874989, 9.968701171874997],
            [-14.021875, 10.0478515625],
            [-14.029931640624994, 10.115136718749994],
            [-14.045019531249977, 10.141259765624994],
            [-14.086279296874977, 10.127246093749989],
            [-14.17041015625, 10.128613281249997],
            [-14.426904296874994, 10.248339843749989],
            [-14.609570312499983, 10.549853515624989],
            [-14.613623046874977, 10.617822265624994],
            [-14.58740234375, 10.734912109374989],
            [-14.593505859375, 10.766699218749991],
            [-14.677343749999977, 10.68896484375],
            [-14.693359375, 10.741015624999989],
            [-14.757373046874989, 10.862060546875],
            [-14.775927734374989, 10.931640625],
            [-14.837451171874989, 10.962548828124994],
            [-14.88671875, 10.968066406249989],
            [-14.9248046875, 10.944921875],
            [-14.975, 10.803417968749997],
            [-15.012402343749983, 10.804345703124994],
            [-15.051220703124983, 10.834570312499991],
            [-15.043017578124989, 10.940136718749997],
            [-14.9990234375, 10.9921875],
            [-14.944433593749977, 11.072167968749994],
            [-14.779296875, 11.405517578125],
            [-14.720263671874989, 11.48193359375],
            [-14.682958984374977, 11.508496093749997],
            [-14.604785156249989, 11.511621093749994],
            [-14.452441406249989, 11.556201171874989],
            [-14.327832031249983, 11.629785156249994],
            [-14.265576171874983, 11.659912109375],
            [-14.122314453125, 11.651953125],
            [-13.953222656249977, 11.664599609374989],
            [-13.732763671874977, 11.736035156249997],
            [-13.728564453124989, 11.834130859374994],
            [-13.730664062499983, 11.959863281249994],
            [-13.737988281249983, 12.009667968749994],
            [-13.816308593749994, 12.054492187499989],
            [-13.861914062499977, 12.093310546874989],
            [-13.901171874999989, 12.142871093749989],
            [-13.948876953124994, 12.178173828124997],
            [-13.947314453124989, 12.215234375],
            [-13.8875, 12.246875],
            [-13.849462890624977, 12.262988281249989],
            [-13.759765625, 12.262353515624994],
            [-13.730078124999977, 12.280810546874989],
            [-13.707910156249994, 12.312695312499997],
            [-13.682373046875, 12.393408203124991],
            [-13.673535156249983, 12.478515625],
            [-13.732617187499983, 12.592822265624989],
            [-13.729248046875, 12.673925781249991],
            [-13.40576171875, 12.662255859374994],
            [-13.37255859375, 12.653613281249989],
            [-13.228076171874989, 12.639599609374997],
            [-13.138476562499989, 12.639746093749991],
            [-13.082910156249994, 12.633544921875],
            [-13.059765624999983, 12.615039062499989],
            [-13.064404296874983, 12.5810546875],
            [-13.079833984375, 12.536279296874994],
            [-13.061279296875, 12.489990234375],
            [-13.011914062499983, 12.477636718749991],
            [-12.985644531249989, 12.491650390624997],
            [-12.960546874999977, 12.514355468749997],
            [-12.930712890624989, 12.532275390624989],
            [-12.88818359375, 12.52001953125],
            [-12.797314453124983, 12.451904296875],
            [-12.713037109374994, 12.433154296874989],
            [-12.620800781249983, 12.396191406249997],
            [-12.534228515624989, 12.375781249999989],
            [-12.457373046874977, 12.378369140624997],
            [-12.399072265624994, 12.340087890625],
            [-12.291210937499983, 12.328027343749994],
            [-12.151953124999977, 12.376611328124994],
            [-12.042382812499994, 12.398046875],
            [-11.888574218749994, 12.4033203125],
            [-11.80810546875, 12.387304687499991],
            [-11.573681640624983, 12.426318359374989],
            [-11.456738281249983, 12.417578125],
            [-11.389404296875, 12.404394531249991],
            [-11.418066406249977, 12.377685546875],
            [-11.447558593749989, 12.319238281249994],
            [-11.474560546874983, 12.247167968749991],
            [-11.502197265625, 12.198632812499994],
            [-11.492431640625, 12.166943359374997],
            [-11.414648437499977, 12.10400390625],
            [-11.30517578125, 12.015429687499989],
            [-11.260693359374983, 12.004052734374994],
            [-11.209667968749983, 12.024853515624997],
            [-11.129248046874977, 12.095019531249989],
            [-11.065820312499994, 12.170800781249994],
            [-11.004541015624994, 12.20751953125],
            [-10.933203124999977, 12.205175781249991],
            [-10.876171874999983, 12.15185546875],
            [-10.806494140624977, 12.034277343749991],
            [-10.743017578124977, 11.92724609375],
            [-10.734912109374989, 11.916455078124997],
            [-10.709228515625, 11.898730468749989],
            [-10.677343749999977, 11.8994140625],
            [-10.643701171874994, 11.925537109375],
            [-10.618994140624977, 11.941210937499989],
            [-10.589501953124994, 11.990283203124989],
            [-10.4658203125, 12.138671875],
            [-10.372753906249983, 12.179541015624991],
            [-10.339892578124989, 12.190283203124991],
            [-10.274853515624983, 12.212646484375],
            [-10.167089843749977, 12.177441406249997],
            [-10.010644531249994, 12.116455078125],
            [-9.820703125, 12.04248046875],
            [-9.754003906249977, 12.029931640624994],
            [-9.714746093749994, 12.04248046875],
            [-9.658300781249977, 12.143115234374989],
            [-9.587744140624977, 12.182470703124991],
            [-9.48681640625, 12.228662109374994],
            [-9.404980468749983, 12.25244140625],
            [-9.358105468749983, 12.255419921874989],
            [-9.340185546874977, 12.282763671874989],
            [-9.33154296875, 12.32373046875],
            [-9.3408203125, 12.366015624999989],
            [-9.393652343749977, 12.442236328124991],
            [-9.395361328124977, 12.464648437499989],
            [-9.365185546874983, 12.479296874999989],
            [-9.3, 12.490283203124989],
            [-9.215527343749983, 12.482861328124997],
            [-9.120458984374977, 12.449951171875],
            [-9.043066406249977, 12.40234375],
            [-8.998925781249994, 12.345898437499997],
            [-8.950830078124994, 12.2255859375],
            [-8.913867187499989, 12.108544921874994],
            [-8.818310546874983, 11.922509765624994],
            [-8.820068359375, 11.80712890625],
            [-8.822021484375, 11.673242187499994],
            [-8.779736328124983, 11.648242187499989],
            [-8.733105468749983, 11.6375],
            [-8.71142578125, 11.617773437499991],
            [-8.664941406249994, 11.514990234374991],
            [-8.621142578124989, 11.485107421875],
            [-8.56875, 11.478076171874989],
            [-8.470703125, 11.412207031249991],
            [-8.407470703125, 11.386279296874989],
            [-8.398535156249977, 11.366552734374991],
            [-8.400683593749989, 11.339404296874989],
            [-8.42529296875, 11.304736328124989],
            [-8.463525390624994, 11.280712890624997],
            [-8.520312499999989, 11.2359375],
            [-8.567285156249994, 11.177001953125],
            [-8.663916015624977, 11.035839843749997],
            [-8.666699218749983, 11.009472656249997],
            [-8.646191406249983, 10.990478515625],
            [-8.606201171875, 10.986962890624994],
            [-8.563525390624989, 10.996679687499991],
            [-8.474707031249977, 11.048388671874989],
            [-8.404492187499983, 11.029931640624994],
            [-8.33740234375, 10.990625],
            [-8.312744140625, 10.949755859374989],
            [-8.306347656249983, 10.89609375],
            [-8.321679687499994, 10.826953124999989],
            [-8.324121093749994, 10.74951171875],
            [-8.301562499999989, 10.617578125],
            [-8.266650390624989, 10.485986328124994],
            [-8.231494140624989, 10.43798828125],
            [-8.007275390624983, 10.321875],
            [-7.985693359374977, 10.278417968749991],
            [-7.974462890624977, 10.229541015624989],
            [-7.990625, 10.1625],
            [-8.013525390624977, 10.125292968749989],
            [-8.077832031249983, 10.067089843749997],
            [-8.136621093749994, 10.022070312499991],
            [-8.155175781249994, 9.973193359374989],
            [-8.145849609374977, 9.881738281249994],
            [-8.146044921874989, 9.6748046875],
            [-8.136962890625, 9.495703125],
            [-8.088671874999989, 9.4306640625],
            [-8.031005859375, 9.39765625],
            [-7.962695312499989, 9.403857421874989],
            [-7.896191406249983, 9.415869140624991],
            [-7.9, 9.308691406249991],
            [-7.918066406249977, 9.188525390624989],
            [-7.839404296874989, 9.151611328125],
            [-7.7998046875, 9.115039062499989],
            [-7.777978515624994, 9.080859374999989],
            [-7.902099609375, 9.01708984375],
            [-7.938183593749983, 8.979785156249989],
            [-7.954980468749994, 8.879443359374989],
            [-7.950976562499989, 8.786816406249997],
            [-7.784033203124977, 8.720605468749994],
            [-7.719580078124977, 8.643017578124997],
            [-7.690966796874989, 8.5625],
            [-7.681201171874989, 8.410351562499997],
            [-7.696093749999989, 8.375585937499991],
            [-7.738964843749983, 8.375244140625],
            [-7.787402343749989, 8.421972656249991],
            [-7.823583984374977, 8.467675781249994],
            [-7.86875, 8.467529296875],
            [-7.953125, 8.477734375],
            [-8.049121093749989, 8.4953125],
            [-8.167773437499989, 8.490673828124997],
            [-8.2099609375, 8.483251953124991],
            [-8.236962890624994, 8.455664062499991],
            [-8.244140625, 8.407910156249997],
            [-8.256103515625, 8.253710937499989],
            [-8.217138671874977, 8.219677734374997],
            [-8.140625, 8.181445312499989],
            [-8.090527343749983, 8.165136718749991],
            [-8.048583984375, 8.169726562499989],
            [-8.016748046874994, 8.144921875],
            [-8.009863281249977, 8.078515625],
            [-8.03173828125, 8.029736328124997],
            [-8.073828124999977, 7.984423828124989],
            [-8.126855468749994, 7.867724609374989],
            [-8.117822265624994, 7.824023437499989],
            [-8.115429687499983, 7.7607421875],
            [-8.205957031249994, 7.590234375],
            [-8.231884765624983, 7.556738281249991],
            [-8.351757812499983, 7.590576171875],
            [-8.429980468749989, 7.601855468749989],
            [-8.486425781249977, 7.558496093749994],
            [-8.522265624999989, 7.585546875],
            [-8.564404296874983, 7.625097656249991],
            [-8.578857421875, 7.677050781249989],
            [-8.607324218749994, 7.687939453124997],
            [-8.659765624999977, 7.688378906249994],
            [-8.708300781249989, 7.658886718749997],
            [-8.729443359374983, 7.605273437499989],
            [-8.732617187499983, 7.543554687499991],
            [-8.740234375, 7.495703125],
            [-8.769140624999977, 7.466796875],
            [-8.827929687499989, 7.391943359374991],
            [-8.855517578124989, 7.322802734374989],
            [-8.8896484375, 7.2626953125],
            [-8.938427734374983, 7.266162109374989],
            [-8.960986328124989, 7.274609375],
            [-8.9765625, 7.258886718749991],
            [-9.052343749999977, 7.225488281249994],
            [-9.117578125, 7.215917968749991],
            [-9.134814453124989, 7.250585937499991],
            [-9.1728515625, 7.278417968749991],
            [-9.215185546874977, 7.333300781249989],
            [-9.263281249999977, 7.377734374999989],
            [-9.355322265624977, 7.40869140625],
            [-9.391650390624989, 7.394921875],
            [-9.435107421874989, 7.3984375],
            [-9.463818359374983, 7.415869140624991],
            [-9.459765624999989, 7.442529296874994],
            [-9.411474609374977, 7.509960937499997],
            [-9.383984374999983, 7.571875],
            [-9.368945312499989, 7.639550781249994],
            [-9.369140625, 7.703808593749997],
            [-9.394921875, 7.794628906249997],
            [-9.436328124999989, 7.86669921875],
            [-9.446386718749977, 7.908496093749989],
            [-9.441552734374994, 7.967919921874994],
            [-9.451123046874983, 8.023242187499989],
            [-9.464550781249983, 8.052099609374991],
            [-9.471142578124983, 8.106982421874989],
            [-9.484130859375, 8.156982421875],
            [-9.508496093749983, 8.17626953125],
            [-9.522216796875, 8.260009765625],
            [-9.518261718749983, 8.34609375],
            [-9.553906249999983, 8.378613281249997],
            [-9.610156249999989, 8.40234375],
            [-9.643212890624994, 8.43603515625],
            [-9.66357421875, 8.473535156249994],
            [-9.683886718749989, 8.484423828124989],
            [-9.701171875, 8.482177734375],
            [-9.716894531249977, 8.458886718749994],
            [-9.735595703125, 8.453955078124991],
            [-9.768261718749983, 8.534570312499994],
            [-9.781982421875, 8.537695312499991],
            [-9.804736328124989, 8.519189453124994],
            [-10.064355468749994, 8.429882812499997],
            [-10.07568359375, 8.464599609375],
            [-10.09765625, 8.505859375],
            [-10.147412109374983, 8.519726562499997],
            [-10.233056640624994, 8.488818359374989],
            [-10.283203125, 8.485156249999989],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 5,
        sovereignt: "Djibouti",
        sov_a3: "DJI",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Djibouti",
        adm0_a3: "DJI",
        geou_dif: 0,
        geounit: "Djibouti",
        gu_a3: "DJI",
        su_dif: 0,
        subunit: "Djibouti",
        su_a3: "DJI",
        brk_diff: 0,
        name: "Djibouti",
        name_long: "Djibouti",
        brk_a3: "DJI",
        brk_name: "Djibouti",
        brk_group: null,
        abbrev: "Dji.",
        postal: "DJ",
        formal_en: "Republic of Djibouti",
        formal_fr: null,
        name_ciawf: "Djibouti",
        note_adm0: null,
        note_brk: null,
        name_sort: "Djibouti",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 2,
        mapcolor9: 4,
        mapcolor13: 8,
        pop_est: 973560,
        pop_rank: 11,
        pop_year: 2019,
        gdp_md: 3324,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "4. Lower middle income",
        fips_10: "DJ",
        iso_a2: "DJ",
        iso_a2_eh: "DJ",
        iso_a3: "DJI",
        iso_a3_eh: "DJI",
        iso_n3: "262",
        iso_n3_eh: "262",
        un_a3: "262",
        wb_a2: "DJ",
        wb_a3: "DJI",
        woe_id: 23424797,
        woe_id_eh: 23424797,
        woe_note: "Exact WOE match as country",
        adm0_iso: "DJI",
        adm0_diff: null,
        adm0_tlc: "DJI",
        adm0_a3_us: "DJI",
        adm0_a3_fr: "DJI",
        adm0_a3_ru: "DJI",
        adm0_a3_es: "DJI",
        adm0_a3_cn: "DJI",
        adm0_a3_tw: "DJI",
        adm0_a3_in: "DJI",
        adm0_a3_np: "DJI",
        adm0_a3_pk: "DJI",
        adm0_a3_de: "DJI",
        adm0_a3_gb: "DJI",
        adm0_a3_br: "DJI",
        adm0_a3_il: "DJI",
        adm0_a3_ps: "DJI",
        adm0_a3_sa: "DJI",
        adm0_a3_eg: "DJI",
        adm0_a3_ma: "DJI",
        adm0_a3_pt: "DJI",
        adm0_a3_ar: "DJI",
        adm0_a3_jp: "DJI",
        adm0_a3_ko: "DJI",
        adm0_a3_vn: "DJI",
        adm0_a3_tr: "DJI",
        adm0_a3_id: "DJI",
        adm0_a3_pl: "DJI",
        adm0_a3_gr: "DJI",
        adm0_a3_it: "DJI",
        adm0_a3_nl: "DJI",
        adm0_a3_se: "DJI",
        adm0_a3_bd: "DJI",
        adm0_a3_ua: "DJI",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Eastern Africa",
        region_wb: "Middle East & North Africa",
        name_len: 8,
        long_len: 8,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: 42.498825,
        label_y: 11.976343,
        ne_id: 1159320541,
        wikidataid: "Q977",
        name_ar: "جيبوتي",
        name_bn: "জিবুতি",
        name_de: "Dschibuti",
        name_en: "Djibouti",
        name_es: "Yibuti",
        name_fa: "جیبوتی",
        name_fr: "Djibouti",
        name_el: "Τζιμπουτί",
        name_he: "ג'יבוטי",
        name_hi: "जिबूती",
        name_hu: "Dzsibuti",
        name_id: "Djibouti",
        name_it: "Gibuti",
        name_ja: "ジブチ",
        name_ko: "지부티",
        name_nl: "Djibouti",
        name_pl: "Dżibuti",
        name_pt: "Djibouti",
        name_ru: "Джибути",
        name_sv: "Djibouti",
        name_tr: "Cibuti",
        name_uk: "Джибуті",
        name_ur: "جبوتی",
        name_vi: "Djibouti",
        name_zh: "吉布提",
        name_zht: "吉布地",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "DJI.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [43.24599609375002, 11.499804687499989],
            [43.159375, 11.36572265625],
            [43.04863281250002, 11.1943359375],
            [42.92275390625002, 10.999316406249989],
            [42.84414062500002, 10.997949218749994],
            [42.78300781250002, 11.00927734375],
            [42.7412109375, 11.042382812499994],
            [42.65498046875001, 11.078320312499997],
            [42.557714843750006, 11.080761718749997],
            [42.46513671875002, 11.047070312499997],
            [42.30810546875, 11.005224609374991],
            [42.16621093750001, 10.991601562499994],
            [42.05214843750002, 10.968359375],
            [41.95742187500002, 10.941015625],
            [41.872167968750006, 10.955810546875],
            [41.79824218750002, 10.98046875],
            [41.78203125000002, 11.187792968749989],
            [41.7646484375, 11.412890624999989],
            [41.76650390625002, 11.589111328125],
            [41.79267578125001, 11.68603515625],
            [41.815625, 11.723779296874994],
            [41.94960937500002, 11.857861328124997],
            [41.99589843750002, 11.912353515625],
            [42.14912109375001, 12.134130859374991],
            [42.280371093750006, 12.324267578124989],
            [42.378515625, 12.46640625],
            [42.40859375000002, 12.494384765625],
            [42.45, 12.521337890624991],
            [42.47939453125002, 12.513623046874997],
            [42.67011718750001, 12.3765625],
            [42.703710937500006, 12.380322265624997],
            [42.76748046875002, 12.4228515625],
            [42.825292968750006, 12.5693359375],
            [42.86591796875001, 12.622802734375],
            [42.88330078125, 12.621289062499997],
            [43.00566406250002, 12.662304687499997],
            [43.11669921875, 12.70859375],
            [43.130859375, 12.660449218749989],
            [43.29863281250002, 12.4638671875],
            [43.353515625, 12.367041015624991],
            [43.409765625, 12.18994140625],
            [43.38027343750002, 12.091259765624997],
            [43.33671875000002, 12.027001953124994],
            [43.272070312500006, 11.969531249999989],
            [43.04804687500001, 11.829052734374997],
            [42.79902343750001, 11.739404296874994],
            [42.64003906250002, 11.560107421874989],
            [42.52177734375002, 11.572167968749994],
            [42.53974609375001, 11.504296875],
            [42.58378906250002, 11.496777343749997],
            [42.65273437500002, 11.509570312499989],
            [42.78974609375001, 11.56171875],
            [42.91152343750002, 11.586621093749997],
            [43.04277343750002, 11.588476562499991],
            [43.16171875, 11.566015625],
            [43.24599609375002, 11.499804687499989],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Sudan",
        sov_a3: "SDN",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Sudan",
        adm0_a3: "SDN",
        geou_dif: 0,
        geounit: "Sudan",
        gu_a3: "SDN",
        su_dif: 0,
        subunit: "Sudan",
        su_a3: "SDN",
        brk_diff: 0,
        name: "Sudan",
        name_long: "Sudan",
        brk_a3: "SDN",
        brk_name: "Sudan",
        brk_group: null,
        abbrev: "Sudan",
        postal: "SD",
        formal_en: "Republic of the Sudan",
        formal_fr: null,
        name_ciawf: "Sudan",
        note_adm0: null,
        note_brk: null,
        name_sort: "Sudan",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 6,
        mapcolor9: 4,
        mapcolor13: 1,
        pop_est: 42813238,
        pop_rank: 15,
        pop_year: 2019,
        gdp_md: 30513,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        fips_10: "SU",
        iso_a2: "SD",
        iso_a2_eh: "SD",
        iso_a3: "SDN",
        iso_a3_eh: "SDN",
        iso_n3: "729",
        iso_n3_eh: "729",
        un_a3: "729",
        wb_a2: "SD",
        wb_a3: "SDN",
        woe_id: -90,
        woe_id_eh: 23424952,
        woe_note: "Almost all FLickr photos are in the north.",
        adm0_iso: "SDZ",
        adm0_diff: null,
        adm0_tlc: "SDZ",
        adm0_a3_us: "SDN",
        adm0_a3_fr: "SDN",
        adm0_a3_ru: "SDN",
        adm0_a3_es: "SDN",
        adm0_a3_cn: "SDN",
        adm0_a3_tw: "SDN",
        adm0_a3_in: "SDN",
        adm0_a3_np: "SDN",
        adm0_a3_pk: "SDN",
        adm0_a3_de: "SDN",
        adm0_a3_gb: "SDN",
        adm0_a3_br: "SDN",
        adm0_a3_il: "SDN",
        adm0_a3_ps: "SDN",
        adm0_a3_sa: "SDN",
        adm0_a3_eg: "SDN",
        adm0_a3_ma: "SDN",
        adm0_a3_pt: "SDN",
        adm0_a3_ar: "SDN",
        adm0_a3_jp: "SDN",
        adm0_a3_ko: "SDN",
        adm0_a3_vn: "SDN",
        adm0_a3_tr: "SDN",
        adm0_a3_id: "SDN",
        adm0_a3_pl: "SDN",
        adm0_a3_gr: "SDN",
        adm0_a3_it: "SDN",
        adm0_a3_nl: "SDN",
        adm0_a3_se: "SDN",
        adm0_a3_bd: "SDN",
        adm0_a3_ua: "SDN",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Northern Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 5,
        long_len: 5,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 2.5,
        max_label: 8,
        label_x: 29.260657,
        label_y: 16.330746,
        ne_id: 1159321229,
        wikidataid: "Q1049",
        name_ar: "السودان",
        name_bn: "সুদান",
        name_de: "Sudan",
        name_en: "Sudan",
        name_es: "Sudán",
        name_fa: "سودان",
        name_fr: "Soudan",
        name_el: "Σουδάν",
        name_he: "סודאן",
        name_hi: "सूडान",
        name_hu: "Szudán",
        name_id: "Sudan",
        name_it: "Sudan",
        name_ja: "スーダン",
        name_ko: "수단",
        name_nl: "Soedan",
        name_pl: "Sudan",
        name_pt: "Sudão",
        name_ru: "Судан",
        name_sv: "Sudan",
        name_tr: "Sudan",
        name_uk: "Судан",
        name_ur: "سوڈان",
        name_vi: "Sudan",
        name_zh: "苏丹",
        name_zht: "蘇丹",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "SDN.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [34.078125, 9.461523437499991],
            [34.076757812500006, 9.461523437499991],
            [33.89091796875002, 9.462207031249989],
            [33.88789062500001, 9.463525390624994],
            [33.884863281250006, 9.46640625],
            [33.88212890625002, 9.47119140625],
            [33.87880859375002, 9.477734375],
            [33.87148437500002, 9.506152343749989],
            [33.867773437500006, 9.550341796874989],
            [33.8740234375, 9.626757812499989],
            [33.89492187500002, 9.717626953124991],
            [33.95917968750001, 9.845263671874989],
            [33.9625, 9.855810546874991],
            [33.96328125000002, 9.861767578124997],
            [33.96328125000002, 9.868701171874989],
            [33.95732421875002, 9.891455078124991],
            [33.94990234375001, 9.9111328125],
            [33.94619140625002, 9.94091796875],
            [33.95732421875002, 10.007177734374991],
            [33.95839843750002, 10.027734375],
            [33.95683593750002, 10.05419921875],
            [33.95185546875001, 10.070947265624994],
            [33.90703125000002, 10.181445312499989],
            [33.8921875, 10.198974609375],
            [33.459082031250006, 10.550830078124989],
            [33.3798828125, 10.646191406249997],
            [33.37138671875002, 10.652734375],
            [33.36074218750002, 10.6578125],
            [33.14082031250001, 10.737890625],
            [33.13007812500001, 10.745947265624991],
            [33.131445312500006, 10.757714843749994],
            [33.13828125, 10.77294921875],
            [33.16474609375001, 10.819189453124991],
            [33.16845703125, 10.831445312499994],
            [33.17216796875002, 10.850146484374989],
            [33.07304687500002, 11.591503906249997],
            [33.073339843750006, 11.606103515624994],
            [33.07783203125001, 11.615771484374989],
            [33.08154296875, 11.621728515624994],
            [33.09462890625002, 11.6375],
            [33.106054687500006, 11.653857421874989],
            [33.119140625, 11.682421874999989],
            [33.12246093750002, 11.693164062499989],
            [33.13613281250002, 11.825585937499994],
            [33.13505859375002, 11.941601562499997],
            [33.19306640625001, 12.135009765625],
            [33.199316406250006, 12.21728515625],
            [32.721875, 12.223095703124997],
            [32.71894531250001, 12.218847656249991],
            [32.71855468750002, 12.213769531249994],
            [32.719824218750006, 12.2080078125],
            [32.72050781250002, 12.201806640624994],
            [32.72011718750002, 12.188818359374991],
            [32.71630859375, 12.164843749999989],
            [32.71533203125, 12.152197265624991],
            [32.7158203125, 12.139257812499991],
            [32.72304687500002, 12.092919921874994],
            [32.73564453125002, 12.058056640624997],
            [32.73769531250002, 12.046435546874989],
            [32.73828125, 12.033740234374989],
            [32.73671875000002, 12.009667968749994],
            [32.072265625, 12.006738281249994],
            [32.33535156250002, 11.716015625],
            [32.338476562500006, 11.710107421874994],
            [32.34306640625002, 11.694287109374997],
            [32.34492187500001, 11.682714843749991],
            [32.34990234375002, 11.580419921874991],
            [32.33574218750002, 11.418554687499991],
            [32.3388671875, 11.314501953124989],
            [32.35419921875001, 11.246923828124991],
            [32.425390625, 11.113964843749997],
            [32.42080078125002, 11.089111328125],
            [32.40410156250002, 11.057763671874994],
            [31.933007812500023, 10.6625],
            [31.919921875, 10.643847656249989],
            [31.854296875000017, 10.479052734374989],
            [31.7919921875, 10.383154296874991],
            [31.764257812500006, 10.355712890625],
            [31.654882812500006, 10.221142578124997],
            [31.224902343750017, 9.799267578124997],
            [31.15449218750001, 9.770947265624997],
            [30.940332031250023, 9.759375],
            [30.827050781250023, 9.756298828124997],
            [30.81416015625001, 9.753125],
            [30.794921875, 9.745849609375],
            [30.783105468750023, 9.734960937499991],
            [30.769140625, 9.726806640625],
            [30.75537109375, 9.731201171875],
            [30.739355468750006, 9.74267578125],
            [30.474609375, 9.978955078124997],
            [30.003027343750006, 10.277392578124989],
            [29.957910156250023, 10.250244140625],
            [29.691015625, 10.121923828124991],
            [29.63593750000001, 10.088623046875],
            [29.60546875, 10.065087890624994],
            [29.603906250000023, 9.92138671875],
            [29.557421875000017, 9.848291015624994],
            [29.47314453125, 9.768603515624989],
            [29.24238281250001, 9.718066406249989],
            [29.122363281250017, 9.674658203124991],
            [28.999609375, 9.610156249999989],
            [28.979589843750006, 9.593994140625],
            [28.979589843750006, 9.594189453124997],
            [28.93232421875001, 9.549462890624994],
            [28.839453125, 9.459082031249991],
            [28.829394531250017, 9.388818359374994],
            [28.844531250000017, 9.326074218749994],
            [28.048925781250006, 9.32861328125],
            [27.99628906250001, 9.378808593749994],
            [27.885839843750006, 9.599658203124989],
            [27.880859375, 9.601611328124989],
            [27.7998046875, 9.587890625],
            [27.07421875, 9.613818359374989],
            [26.970507812500017, 9.590625],
            [26.76318359375, 9.49921875],
            [26.65869140625, 9.484130859375],
            [26.551367187500006, 9.525830078124997],
            [26.16953125, 9.965917968749991],
            [26.087011718750006, 10.018457031249994],
            [26.057031250000023, 10.046777343749994],
            [26.000585937500006, 10.1234375],
            [25.91914062500001, 10.169335937499994],
            [25.891503906250023, 10.202734375],
            [25.8828125, 10.249609375],
            [25.88525390625, 10.34609375],
            [25.858203125000017, 10.406494140625],
            [25.79804687500001, 10.420507812499991],
            [25.28515625, 10.318505859374994],
            [25.211718750000017, 10.329931640624991],
            [25.10400390625, 10.311816406249989],
            [25.066992187500006, 10.293798828124991],
            [25.02363281250001, 10.235791015624997],
            [25.01484375000001, 10.175878906249991],
            [25.016210937500006, 10.115234375],
            [25.0029296875, 10.055273437499991],
            [24.9638671875, 9.988867187499991],
            [24.817675781250017, 9.839599609375],
            [24.785253906250006, 9.774658203125],
            [24.79218750000001, 9.610302734374997],
            [24.782617187500023, 9.52734375],
            [24.760351562500006, 9.488916015624994],
            [24.696679687500023, 9.425683593749994],
            [24.673632812500017, 9.389306640624994],
            [24.662890625000017, 9.338134765625],
            [24.659375, 9.229931640624997],
            [24.648046875, 9.179101562499994],
            [24.568261718750023, 9.051708984374997],
            [24.549414062500006, 9.006787109374997],
            [24.544824218750023, 8.914843749999989],
            [24.53193359375001, 8.886914062499997],
            [24.300195312500023, 8.814257812499989],
            [24.21357421875001, 8.767822265625],
            [24.160449218750017, 8.6962890625],
            [24.147363281250023, 8.665625],
            [24.048144531250017, 8.691308593749994],
            [23.921972656250006, 8.709716796875],
            [23.679296875, 8.732470703124989],
            [23.58320312500001, 8.765820312499997],
            [23.53730468750001, 8.815820312499994],
            [23.551855468750006, 8.943212890624991],
            [23.52802734375001, 8.970605468749994],
            [23.489062500000017, 8.993310546874994],
            [23.462792968750023, 9.048486328124994],
            [23.46826171875, 9.11474609375],
            [23.596093750000023, 9.261914062499997],
            [23.62265625, 9.340625],
            [23.64277343750001, 9.613916015624994],
            [23.65625, 9.710351562499994],
            [23.646289062500017, 9.822900390624994],
            [23.545019531250006, 10.030078124999989],
            [23.456640625, 10.174267578124997],
            [23.312304687500017, 10.387939453125],
            [23.255859375, 10.457812499999989],
            [22.96435546875, 10.751806640624991],
            [22.930761718750006, 10.7953125],
            [22.86005859375001, 10.919677734375],
            [22.894824218750017, 11.029003906249997],
            [22.93769531250001, 11.192041015624994],
            [22.942773437500023, 11.2671875],
            [22.922656250000017, 11.344873046874994],
            [22.849023437500023, 11.403271484374997],
            [22.78339843750001, 11.409960937499989],
            [22.754003906250006, 11.43984375],
            [22.697363281250006, 11.482666015625],
            [22.641015625000023, 11.515917968749989],
            [22.591113281250017, 11.579882812499989],
            [22.55634765625001, 11.66953125],
            [22.580957031250023, 11.990136718749994],
            [22.564355468750023, 12.032958984375],
            [22.48984375, 12.044726562499989],
            [22.472460937500017, 12.067773437499994],
            [22.475488281250023, 12.129248046874991],
            [22.43525390625001, 12.311914062499994],
            [22.390234375, 12.462988281249991],
            [22.414453125000023, 12.54638671875],
            [22.352343750000017, 12.660449218749989],
            [22.2333984375, 12.70947265625],
            [22.121191406250006, 12.694580078125],
            [22.00068359375001, 12.671875],
            [21.928125, 12.678125],
            [21.878125, 12.699365234374994],
            [21.843359375, 12.7412109375],
            [21.825292968750006, 12.79052734375],
            [21.841796875, 12.86474609375],
            [21.90771484375, 13.0009765625],
            [21.990234375, 13.113085937499989],
            [22.158007812500017, 13.215039062499997],
            [22.20263671875, 13.269335937500003],
            [22.228125, 13.32958984375],
            [22.23261718750001, 13.398779296874991],
            [22.22138671875001, 13.471630859374997],
            [22.20234375000001, 13.5380859375],
            [22.152929687500006, 13.626416015624997],
            [22.10761718750001, 13.730322265624991],
            [22.1064453125, 13.7998046875],
            [22.128222656250017, 13.850146484375003],
            [22.173144531250017, 13.910595703124997],
            [22.262109375000023, 13.978710937499997],
            [22.283496093750017, 13.992333984374994],
            [22.33935546875, 14.028857421875003],
            [22.38818359375, 14.055517578124991],
            [22.50996093750001, 14.12744140625],
            [22.53857421875, 14.161865234375],
            [22.528222656250023, 14.203222656249991],
            [22.498339843750017, 14.237060546875],
            [22.449316406250006, 14.284228515625003],
            [22.439355468750023, 14.342138671874991],
            [22.425, 14.441210937500003],
            [22.399707031250017, 14.504199218750003],
            [22.38154296875001, 14.550488281249997],
            [22.41621093750001, 14.585205078125],
            [22.4677734375, 14.633349609375003],
            [22.532031250000017, 14.662744140624994],
            [22.6318359375, 14.688085937499991],
            [22.6708984375, 14.722460937500003],
            [22.682421875000017, 14.788623046875003],
            [22.67919921875, 14.851464843749994],
            [22.714941406250006, 14.898388671874997],
            [22.76328125, 14.998681640624994],
            [22.802148437500023, 15.04443359375],
            [22.8671875, 15.096630859374997],
            [22.93232421875001, 15.162109375],
            [22.961328125000023, 15.238134765624991],
            [22.969531250000017, 15.311328125],
            [22.933886718750017, 15.533105468749994],
            [23.009179687500023, 15.625830078124991],
            [23.10517578125001, 15.702539062499994],
            [23.243457031250017, 15.697216796874997],
            [23.4580078125, 15.713964843749991],
            [23.60400390625, 15.745996093749994],
            [23.70820312500001, 15.744970703124991],
            [23.94599609375001, 15.703515625],
            [23.965234375000023, 15.713427734375003],
            [23.970800781250006, 15.721533203124991],
            [23.9833984375, 15.780175781249994],
            [23.983300781250023, 15.928125],
            [23.98291015625, 16.37421875],
            [23.982519531250006, 16.820263671874997],
            [23.982226562500017, 17.266357421875],
            [23.981835937500023, 17.71240234375],
            [23.9814453125, 18.158496093750003],
            [23.981054687500006, 18.604541015625003],
            [23.98066406250001, 19.050585937500003],
            [23.980273437500017, 19.496630859375003],
            [23.980273437500017, 19.621484375],
            [23.980273437500017, 19.746289062499997],
            [23.980273437500017, 19.87109375],
            [23.980273437500017, 19.99594726562499],
            [24.226953125000023, 19.995849609375],
            [24.4736328125, 19.995703125],
            [24.72041015625001, 19.995556640624997],
            [24.96699218750001, 19.99545898437499],
            [24.97021484375, 19.997265625],
            [24.973242187500006, 19.9990234375],
            [24.976367187500017, 20.00078125],
            [24.9794921875, 20.002587890624994],
            [24.97968750000001, 20.500927734374997],
            [24.979882812500023, 20.99921875],
            [24.980078125, 21.49755859375],
            [24.980273437500017, 21.995849609375],
            [25.3623046875, 21.995800781249997],
            [25.74433593750001, 21.995751953124994],
            [26.126367187500023, 21.995654296875003],
            [26.508398437500006, 21.99560546875],
            [26.890429687500017, 21.995556640624997],
            [27.2724609375, 21.995507812499994],
            [27.65449218750001, 21.99545898437499],
            [28.036425781250017, 21.995361328125],
            [28.418554687500006, 21.9953125],
            [28.800585937500017, 21.995263671874994],
            [29.182519531250023, 21.99521484374999],
            [29.564550781250006, 21.9951171875],
            [29.946679687500023, 21.9951171875],
            [30.32861328125, 21.995019531249994],
            [30.71064453125001, 21.994921875],
            [31.092675781250023, 21.994873046875],
            [31.20917968750001, 21.994873046875],
            [31.260644531250023, 22.00229492187499],
            [31.358496093750006, 22.188623046874994],
            [31.400292968750023, 22.202441406250003],
            [31.464257812500023, 22.19150390624999],
            [31.486132812500017, 22.14780273437499],
            [31.46640625, 22.084667968749997],
            [31.434472656250023, 21.995849609375],
            [31.621679687500006, 21.995849609375],
            [31.949804687500006, 21.995898437500003],
            [32.27783203125, 21.995996093749994],
            [32.606054687500006, 21.995996093749994],
            [32.93408203125, 21.99609375],
            [33.26220703125, 21.996142578125003],
            [33.59033203125, 21.99619140624999],
            [33.91845703125, 21.996240234374994],
            [34.24648437500002, 21.996289062499997],
            [34.57460937500002, 21.996337890625],
            [34.90273437500002, 21.996386718750003],
            [35.23085937500002, 21.99643554687499],
            [35.55898437500002, 21.996484375],
            [35.88701171875002, 21.996533203124997],
            [36.21523437500002, 21.99658203125],
            [36.54326171875002, 21.996630859375003],
            [36.87138671875002, 21.996728515624994],
            [36.88261718750002, 21.768798828125],
            [36.92695312500001, 21.58652343749999],
            [37.081152343750006, 21.32602539062499],
            [37.21171875000002, 21.185839843750003],
            [37.25859375000002, 21.108544921874994],
            [37.26318359375, 21.07265625],
            [37.25722656250002, 21.03940429687499],
            [37.21748046875001, 21.07763671875],
            [37.15058593750001, 21.103759765625],
            [37.14111328125, 20.98178710937499],
            [37.156835937500006, 20.894921875],
            [37.17265625000002, 20.731982421875003],
            [37.2275390625, 20.55673828124999],
            [37.18789062500002, 20.394921875],
            [37.19316406250002, 20.120703125],
            [37.26259765625002, 19.791894531249994],
            [37.24843750000002, 19.58188476562499],
            [37.36152343750001, 19.091992187499997],
            [37.471289062500006, 18.820117187500003],
            [37.53164062500002, 18.753125],
            [37.59941406250002, 18.717431640624994],
            [37.72978515625002, 18.6943359375],
            [37.921875, 18.555908203125],
            [38.07402343750002, 18.409765625],
            [38.128125, 18.333300781250003],
            [38.201757812500006, 18.249414062499994],
            [38.25214843750001, 18.264404296875],
            [38.28310546875002, 18.28671875],
            [38.33291015625002, 18.219042968750003],
            [38.57402343750002, 18.072949218749997],
            [38.609472656250006, 18.005078125],
            [38.52285156250002, 17.938525390625003],
            [38.422460937500006, 17.823925781249997],
            [38.39716796875001, 17.778369140625003],
            [38.38554687500002, 17.751269531250003],
            [38.37373046875001, 17.717333984375003],
            [38.34736328125001, 17.68359375],
            [38.28984375000002, 17.637011718750003],
            [38.26728515625001, 17.61669921875],
            [38.253515625, 17.584765625],
            [38.21904296875002, 17.56396484375],
            [38.18154296875002, 17.56284179687499],
            [38.148535156250006, 17.548535156249997],
            [38.09892578125002, 17.52646484374999],
            [38.02529296875002, 17.537792968749997],
            [37.95009765625002, 17.51767578124999],
            [37.92255859375001, 17.492333984374994],
            [37.86298828125001, 17.470263671875003],
            [37.803320312500006, 17.465527343749997],
            [37.78242187500001, 17.4580078125],
            [37.72597656250002, 17.42050781249999],
            [37.65673828125, 17.36826171874999],
            [37.57597656250002, 17.335009765625003],
            [37.547460937500006, 17.324121093749994],
            [37.51015625000002, 17.288134765625003],
            [37.45292968750002, 17.108691406250003],
            [37.41103515625002, 17.06171875],
            [37.340429687500006, 17.057080078124997],
            [37.24882812500002, 17.056884765625],
            [37.16953125, 17.04140625],
            [37.0615234375, 17.061279296875],
            [37.00898437500001, 17.058886718750003],
            [36.995214843750006, 17.020556640625003],
            [36.97578125000001, 16.86655273437499],
            [36.97871093750001, 16.800585937500003],
            [36.93574218750001, 16.722363281249997],
            [36.887792968750006, 16.624658203124994],
            [36.90546875000001, 16.459521484375003],
            [36.91376953125001, 16.296191406250003],
            [36.82587890625001, 16.05029296875],
            [36.8134765625, 15.993945312500003],
            [36.72451171875002, 15.798876953125003],
            [36.67919921875, 15.726367187500003],
            [36.566015625, 15.362109375],
            [36.52177734375002, 15.250146484374994],
            [36.4267578125, 15.132080078125],
            [36.44814453125002, 14.940087890624994],
            [36.470800781250006, 14.736474609374994],
            [36.492285156250006, 14.544335937499994],
            [36.52431640625002, 14.2568359375],
            [36.443945312500006, 13.988427734374994],
            [36.44707031250002, 13.842041015625],
            [36.390625, 13.626074218749991],
            [36.346289062500006, 13.526269531249994],
            [36.30683593750001, 13.466845703125003],
            [36.273535156250006, 13.40576171875],
            [36.21220703125002, 13.27109375],
            [36.16015625, 13.093310546874989],
            [36.13710937500002, 12.9111328125],
            [36.135351562500006, 12.805322265624994],
            [36.12519531250001, 12.75703125],
            [36.107519531250006, 12.726464843749994],
            [35.98759765625002, 12.706298828125],
            [35.82060546875002, 12.684863281249989],
            [35.730566406250006, 12.661035156249994],
            [35.67021484375002, 12.623730468749997],
            [35.59609375000002, 12.537304687499997],
            [35.44960937500002, 12.300585937499989],
            [35.37275390625001, 12.155566406249989],
            [35.25244140625, 11.95703125],
            [35.1123046875, 11.816552734374994],
            [35.08271484375001, 11.748291015625],
            [35.059667968750006, 11.621044921874997],
            [35.007910156250006, 11.419873046874997],
            [34.96074218750002, 11.276757812499994],
            [34.96914062500002, 11.161767578124994],
            [34.924902343750006, 10.962109375],
            [34.93144531250002, 10.864794921874989],
            [34.88232421875, 10.810546875],
            [34.81621093750002, 10.759179687499994],
            [34.77128906250002, 10.746191406249991],
            [34.675, 10.804931640625],
            [34.60175781250001, 10.864550781249989],
            [34.571875, 10.880175781249989],
            [34.50800781250001, 10.842871093749991],
            [34.43144531250002, 10.787841796875],
            [34.34394531250001, 10.658642578124997],
            [34.27568359375002, 10.528125],
            [34.31484375000002, 10.2515625],
            [34.31123046875001, 10.190869140624997],
            [34.29150390625, 10.124755859375],
            [34.18525390625001, 9.918554687499991],
            [34.15908203125002, 9.853417968749994],
            [34.12031250000001, 9.7296875],
            [34.07929687500001, 9.513476562499989],
            [34.078125, 9.461523437499991],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 4,
        sovereignt: "Eritrea",
        sov_a3: "ERI",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Eritrea",
        adm0_a3: "ERI",
        geou_dif: 0,
        geounit: "Eritrea",
        gu_a3: "ERI",
        su_dif: 0,
        subunit: "Eritrea",
        su_a3: "ERI",
        brk_diff: 0,
        name: "Eritrea",
        name_long: "Eritrea",
        brk_a3: "ERI",
        brk_name: "Eritrea",
        brk_group: null,
        abbrev: "Erit.",
        postal: "ER",
        formal_en: "State of Eritrea",
        formal_fr: null,
        name_ciawf: "Eritrea",
        note_adm0: null,
        note_brk: null,
        name_sort: "Eritrea",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 1,
        mapcolor9: 2,
        mapcolor13: 12,
        pop_est: 6081196,
        pop_rank: 13,
        pop_year: 2020,
        gdp_md: 2065,
        gdp_year: 2011,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        fips_10: "ER",
        iso_a2: "ER",
        iso_a2_eh: "ER",
        iso_a3: "ERI",
        iso_a3_eh: "ERI",
        iso_n3: "232",
        iso_n3_eh: "232",
        un_a3: "232",
        wb_a2: "ER",
        wb_a3: "ERI",
        woe_id: 23424806,
        woe_id_eh: 23424806,
        woe_note: "Exact WOE match as country",
        adm0_iso: "ERI",
        adm0_diff: null,
        adm0_tlc: "ERI",
        adm0_a3_us: "ERI",
        adm0_a3_fr: "ERI",
        adm0_a3_ru: "ERI",
        adm0_a3_es: "ERI",
        adm0_a3_cn: "ERI",
        adm0_a3_tw: "ERI",
        adm0_a3_in: "ERI",
        adm0_a3_np: "ERI",
        adm0_a3_pk: "ERI",
        adm0_a3_de: "ERI",
        adm0_a3_gb: "ERI",
        adm0_a3_br: "ERI",
        adm0_a3_il: "ERI",
        adm0_a3_ps: "ERI",
        adm0_a3_sa: "ERI",
        adm0_a3_eg: "ERI",
        adm0_a3_ma: "ERI",
        adm0_a3_pt: "ERI",
        adm0_a3_ar: "ERI",
        adm0_a3_jp: "ERI",
        adm0_a3_ko: "ERI",
        adm0_a3_vn: "ERI",
        adm0_a3_tr: "ERI",
        adm0_a3_id: "ERI",
        adm0_a3_pl: "ERI",
        adm0_a3_gr: "ERI",
        adm0_a3_it: "ERI",
        adm0_a3_nl: "ERI",
        adm0_a3_se: "ERI",
        adm0_a3_bd: "ERI",
        adm0_a3_ua: "ERI",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Eastern Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 7,
        long_len: 7,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: 38.285566,
        label_y: 15.787401,
        ne_id: 1159320581,
        wikidataid: "Q986",
        name_ar: "إريتريا",
        name_bn: "ইরিত্রিয়া",
        name_de: "Eritrea",
        name_en: "Eritrea",
        name_es: "Eritrea",
        name_fa: "اریتره",
        name_fr: "Érythrée",
        name_el: "Ερυθραία",
        name_he: "אריתריאה",
        name_hi: "इरित्रिया",
        name_hu: "Eritrea",
        name_id: "Eritrea",
        name_it: "Eritrea",
        name_ja: "エリトリア",
        name_ko: "에리트레아",
        name_nl: "Eritrea",
        name_pl: "Erytrea",
        name_pt: "Eritreia",
        name_ru: "Эритрея",
        name_sv: "Eritrea",
        name_tr: "Eritre",
        name_uk: "Еритрея",
        name_ur: "اریتریا",
        name_vi: "Eritrea",
        name_zh: "厄立特里亚",
        name_zht: "厄利垂亞",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "ERI.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [36.52431640625002, 14.2568359375],
              [36.492285156250006, 14.544335937499994],
              [36.470800781250006, 14.736474609374994],
              [36.44814453125002, 14.940087890624994],
              [36.4267578125, 15.132080078125],
              [36.52177734375002, 15.250146484374994],
              [36.566015625, 15.362109375],
              [36.67919921875, 15.726367187500003],
              [36.72451171875002, 15.798876953125003],
              [36.8134765625, 15.993945312500003],
              [36.82587890625001, 16.05029296875],
              [36.91376953125001, 16.296191406250003],
              [36.90546875000001, 16.459521484375003],
              [36.887792968750006, 16.624658203124994],
              [36.93574218750001, 16.722363281249997],
              [36.97871093750001, 16.800585937500003],
              [36.97578125000001, 16.86655273437499],
              [36.995214843750006, 17.020556640625003],
              [37.00898437500001, 17.058886718750003],
              [37.0615234375, 17.061279296875],
              [37.16953125, 17.04140625],
              [37.24882812500002, 17.056884765625],
              [37.340429687500006, 17.057080078124997],
              [37.41103515625002, 17.06171875],
              [37.45292968750002, 17.108691406250003],
              [37.51015625000002, 17.288134765625003],
              [37.547460937500006, 17.324121093749994],
              [37.57597656250002, 17.335009765625003],
              [37.65673828125, 17.36826171874999],
              [37.72597656250002, 17.42050781249999],
              [37.78242187500001, 17.4580078125],
              [37.803320312500006, 17.465527343749997],
              [37.86298828125001, 17.470263671875003],
              [37.92255859375001, 17.492333984374994],
              [37.95009765625002, 17.51767578124999],
              [38.02529296875002, 17.537792968749997],
              [38.09892578125002, 17.52646484374999],
              [38.148535156250006, 17.548535156249997],
              [38.18154296875002, 17.56284179687499],
              [38.21904296875002, 17.56396484375],
              [38.253515625, 17.584765625],
              [38.26728515625001, 17.61669921875],
              [38.28984375000002, 17.637011718750003],
              [38.34736328125001, 17.68359375],
              [38.37373046875001, 17.717333984375003],
              [38.38554687500002, 17.751269531250003],
              [38.39716796875001, 17.778369140625003],
              [38.422460937500006, 17.823925781249997],
              [38.52285156250002, 17.938525390625003],
              [38.609472656250006, 18.005078125],
              [38.91171875, 17.427148437499994],
              [39.03447265625002, 17.085546875],
              [39.142578125, 16.729150390624994],
              [39.22255859375002, 16.19370117187499],
              [39.298925781250006, 15.92109375],
              [39.42226562500002, 15.786669921875003],
              [39.50654296875001, 15.532128906249994],
              [39.57880859375001, 15.522509765625003],
              [39.63125, 15.452539062499994],
              [39.720800781250006, 15.213671875],
              [39.78554687500002, 15.124853515624991],
              [39.819433593750006, 15.201269531249991],
              [39.815625, 15.2453125],
              [39.79033203125002, 15.31884765625],
              [39.8134765625, 15.41357421875],
              [39.86376953125, 15.4703125],
              [39.97832031250002, 15.393115234375003],
              [40.041015625, 15.334521484375003],
              [40.05781250000001, 15.217089843750003],
              [40.084082031250006, 15.151953125],
              [40.2041015625, 15.014111328124997],
              [40.305273437500006, 14.974023437499994],
              [40.4365234375, 14.963964843749991],
              [40.54628906250002, 14.93359375],
              [40.634375, 14.883007812499997],
              [40.79931640625, 14.743017578124991],
              [41.17646484375001, 14.6203125],
              [41.47968750000001, 14.243896484375],
              [41.658203125, 13.983056640624994],
              [42.2451171875, 13.587646484375],
              [42.34648437500002, 13.398095703124994],
              [42.39931640625002, 13.212597656249997],
              [42.52285156250002, 13.221484375],
              [42.734472656250006, 13.018603515624989],
              [42.79619140625002, 12.8642578125],
              [42.96953125000002, 12.808349609375],
              [42.9990234375, 12.899511718749991],
              [43.08291015625002, 12.824609375],
              [43.11669921875, 12.70859375],
              [43.00566406250002, 12.662304687499997],
              [42.88330078125, 12.621289062499997],
              [42.86591796875001, 12.622802734375],
              [42.825292968750006, 12.5693359375],
              [42.76748046875002, 12.4228515625],
              [42.703710937500006, 12.380322265624997],
              [42.67011718750001, 12.3765625],
              [42.47939453125002, 12.513623046874997],
              [42.45, 12.521337890624991],
              [42.40859375000002, 12.494384765625],
              [42.378515625, 12.46640625],
              [42.28994140625002, 12.570214843749994],
              [42.225, 12.661962890624991],
              [42.13427734375, 12.771435546874997],
              [42.04658203125001, 12.820605468749989],
              [41.9521484375, 12.88232421875],
              [41.85957031250001, 13.02587890625],
              [41.76503906250002, 13.183935546874991],
              [41.625, 13.313232421875],
              [41.362890625, 13.499804687500003],
              [41.12236328125002, 13.736132812500003],
              [40.938574218750006, 13.983105468749997],
              [40.82011718750002, 14.111669921874991],
              [40.76953125, 14.144482421874997],
              [40.5244140625, 14.225195312499991],
              [40.353125, 14.338085937499997],
              [40.22148437500002, 14.43115234375],
              [40.140625, 14.4560546875],
              [40.062109375, 14.459130859374994],
              [39.895117187500006, 14.440673828125],
              [39.75615234375002, 14.4990234375],
              [39.69794921875001, 14.4990234375],
              [39.60488281250002, 14.516064453124997],
              [39.531835937500006, 14.53671875],
              [39.44609375000002, 14.511865234374994],
              [39.270117187500006, 14.4703125],
              [39.19804687500002, 14.479394531249994],
              [39.15859375000002, 14.5375],
              [39.13544921875001, 14.581884765624991],
              [39.07421875, 14.628222656250003],
              [39.02382812500002, 14.628222656250003],
              [38.995703125, 14.586865234374997],
              [38.81201171875, 14.482324218749994],
              [38.50439453125, 14.424414062499991],
              [38.43144531250002, 14.428613281249994],
              [38.376953125, 14.470410156249997],
              [38.22148437500002, 14.649658203125],
              [38.17705078125002, 14.678808593749991],
              [38.14199218750002, 14.681494140624991],
              [38.069921875, 14.702734375],
              [38.002539062500006, 14.737109375],
              [37.943457031250006, 14.810546875],
              [37.88417968750002, 14.852294921875],
              [37.8203125, 14.70849609375],
              [37.70839843750002, 14.457226562499997],
              [37.6484375, 14.322558593750003],
              [37.57119140625002, 14.149072265624994],
              [37.54677734375002, 14.143847656250003],
              [37.50722656250002, 14.156396484374994],
              [37.35371093750001, 14.372460937499994],
              [37.25722656250002, 14.453759765624994],
              [37.18515625, 14.445996093749997],
              [37.13261718750002, 14.406054687500003],
              [37.09941406250002, 14.333984375],
              [37.0634765625, 14.289257812499997],
              [37.024511718750006, 14.27197265625],
              [36.94072265625002, 14.280566406250003],
              [36.81191406250002, 14.315039062499991],
              [36.67910156250002, 14.307568359374997],
              [36.54238281250002, 14.258203125],
              [36.52431640625002, 14.2568359375],
            ],
          ],
          [
            [
              [40.07646484375002, 16.082421875],
              [40.11005859375001, 15.985742187499994],
              [40.01240234375001, 16.02265625],
              [39.99609375, 16.042675781249997],
              [40.0390625, 16.080957031249994],
              [40.04814453125002, 16.1044921875],
              [40.07646484375002, 16.082421875],
            ],
          ],
          [
            [
              [40.141210937500006, 15.696142578124991],
              [40.18251953125002, 15.642919921874991],
              [40.21142578125, 15.648144531249997],
              [40.23408203125001, 15.665869140624991],
              [40.250097656250006, 15.703466796874991],
              [40.408203125, 15.629199218750003],
              [40.399023437500006, 15.579882812500003],
              [40.3046875, 15.57734375],
              [40.19580078125, 15.59814453125],
              [40.09511718750002, 15.590917968749991],
              [39.975195312500006, 15.612451171874994],
              [39.94746093750001, 15.696142578124991],
              [40.02392578125, 15.655615234374991],
              [40.0634765625, 15.665869140624991],
              [40.07050781250001, 15.676611328124991],
              [40.01630859375001, 15.733251953124991],
              [39.93994140625, 15.74453125],
              [39.94521484375002, 15.7890625],
              [39.97939453125002, 15.806591796874997],
              [40.00048828125, 15.828271484374994],
              [39.95673828125001, 15.889404296875],
              [40.042578125, 15.87548828125],
              [40.096777343750006, 15.838476562499991],
              [40.132421875, 15.795263671874991],
              [40.141210937500006, 15.696142578124991],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 5,
        labelrank: 3,
        sovereignt: "Ivory Coast",
        sov_a3: "CIV",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Ivory Coast",
        adm0_a3: "CIV",
        geou_dif: 0,
        geounit: "Ivory Coast",
        gu_a3: "CIV",
        su_dif: 0,
        subunit: "Ivory Coast",
        su_a3: "CIV",
        brk_diff: 0,
        name: "Côte d'Ivoire",
        name_long: "Côte d'Ivoire",
        brk_a3: "CIV",
        brk_name: "Côte d'Ivoire",
        brk_group: null,
        abbrev: "I.C.",
        postal: "CI",
        formal_en: "Republic of Ivory Coast",
        formal_fr: "Republic of Cote D'Ivoire",
        name_ciawf: "Cote D'ivoire",
        note_adm0: null,
        note_brk: null,
        name_sort: "Côte d'Ivoire",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 6,
        mapcolor9: 3,
        mapcolor13: 3,
        pop_est: 25716544,
        pop_rank: 15,
        pop_year: 2019,
        gdp_md: 58539,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        fips_10: "IV",
        iso_a2: "CI",
        iso_a2_eh: "CI",
        iso_a3: "CIV",
        iso_a3_eh: "CIV",
        iso_n3: "384",
        iso_n3_eh: "384",
        un_a3: "384",
        wb_a2: "CI",
        wb_a3: "CIV",
        woe_id: 23424854,
        woe_id_eh: 23424854,
        woe_note: "Exact WOE match as country",
        adm0_iso: "CIV",
        adm0_diff: null,
        adm0_tlc: "CIV",
        adm0_a3_us: "CIV",
        adm0_a3_fr: "CIV",
        adm0_a3_ru: "CIV",
        adm0_a3_es: "CIV",
        adm0_a3_cn: "CIV",
        adm0_a3_tw: "CIV",
        adm0_a3_in: "CIV",
        adm0_a3_np: "CIV",
        adm0_a3_pk: "CIV",
        adm0_a3_de: "CIV",
        adm0_a3_gb: "CIV",
        adm0_a3_br: "CIV",
        adm0_a3_il: "CIV",
        adm0_a3_ps: "CIV",
        adm0_a3_sa: "CIV",
        adm0_a3_eg: "CIV",
        adm0_a3_ma: "CIV",
        adm0_a3_pt: "CIV",
        adm0_a3_ar: "CIV",
        adm0_a3_jp: "CIV",
        adm0_a3_ko: "CIV",
        adm0_a3_vn: "CIV",
        adm0_a3_tr: "CIV",
        adm0_a3_id: "CIV",
        adm0_a3_pl: "CIV",
        adm0_a3_gr: "CIV",
        adm0_a3_it: "CIV",
        adm0_a3_nl: "CIV",
        adm0_a3_se: "CIV",
        adm0_a3_bd: "CIV",
        adm0_a3_ua: "CIV",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Western Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 13,
        long_len: 13,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 2.5,
        max_label: 8,
        label_x: -5.568618,
        label_y: 7.49139,
        ne_id: 1159320507,
        wikidataid: "Q1008",
        name_ar: "ساحل العاج",
        name_bn: "কোত দিভোয়ার",
        name_de: "Elfenbeinküste",
        name_en: "Ivory Coast",
        name_es: "Costa de Marfil",
        name_fa: "ساحل عاج",
        name_fr: "Côte d'Ivoire",
        name_el: "Ακτή Ελεφαντοστού",
        name_he: "חוף השנהב",
        name_hi: "कोत दिव्वार",
        name_hu: "Elefántcsontpart",
        name_id: "Pantai Gading",
        name_it: "Costa d'Avorio",
        name_ja: "コートジボワール",
        name_ko: "코트디부아르",
        name_nl: "Ivoorkust",
        name_pl: "Wybrzeże Kości Słoniowej",
        name_pt: "Costa do Marfim",
        name_ru: "Кот-д’Ивуар",
        name_sv: "Elfenbenskusten",
        name_tr: "Fildişi Sahili",
        name_uk: "Кот-д'Івуар",
        name_ur: "کوت داوواغ",
        name_vi: "Bờ Biển Ngà",
        name_zh: "科特迪瓦",
        name_zht: "象牙海岸",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "CIV.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-3.086718749999989, 5.128320312499994],
              [-3.114013671875, 5.088671874999989],
              [-3.246386718749989, 5.114062499999989],
              [-3.214892578124989, 5.147216796875],
              [-3.086718749999989, 5.128320312499994],
            ],
          ],
          [
            [
              [-7.990625, 10.1625],
              [-7.9609375, 10.163476562499994],
              [-7.884082031249989, 10.185742187499997],
              [-7.814208984375, 10.236572265625],
              [-7.749072265624989, 10.34228515625],
              [-7.6611328125, 10.427441406249997],
              [-7.562109374999977, 10.421240234374991],
              [-7.532812499999977, 10.436816406249989],
              [-7.497949218749994, 10.439794921874991],
              [-7.45654296875, 10.383935546874994],
              [-7.414794921875, 10.34130859375],
              [-7.385058593749989, 10.340136718749989],
              [-7.363183593749994, 10.259375],
              [-7.182324218749983, 10.225683593749991],
              [-7.104882812499994, 10.203515625],
              [-7.039746093749983, 10.144775390625],
              [-7.01708984375, 10.143261718749997],
              [-6.989453124999983, 10.155664062499994],
              [-6.968164062499994, 10.176220703124997],
              [-6.963818359374983, 10.19873046875],
              [-6.991748046874989, 10.251855468749994],
              [-6.9794921875, 10.299560546875],
              [-6.950341796874994, 10.342333984374989],
              [-6.90380859375, 10.345068359374991],
              [-6.833642578124994, 10.356982421874989],
              [-6.753222656249989, 10.357128906249997],
              [-6.693261718749994, 10.349462890624991],
              [-6.669335937499994, 10.3921875],
              [-6.691992187499977, 10.512011718749989],
              [-6.686132812499977, 10.578027343749994],
              [-6.676367187499977, 10.6337890625],
              [-6.654150390624977, 10.656445312499997],
              [-6.564599609374994, 10.58642578125],
              [-6.482617187499983, 10.561230468749997],
              [-6.423925781249977, 10.559130859374989],
              [-6.407519531249989, 10.572363281249991],
              [-6.4326171875, 10.648730468749989],
              [-6.425878906249977, 10.671777343749994],
              [-6.404150390624977, 10.685107421874989],
              [-6.365625, 10.692822265624997],
              [-6.261132812499994, 10.724072265624997],
              [-6.250244140625, 10.717919921874994],
              [-6.230664062499983, 10.597509765624991],
              [-6.23974609375, 10.55810546875],
              [-6.2177734375, 10.476269531249997],
              [-6.190673828125, 10.400292968749994],
              [-6.192626953125, 10.369433593749989],
              [-6.214990234374994, 10.322363281249991],
              [-6.241308593749977, 10.279199218749994],
              [-6.238378906249977, 10.261621093749994],
              [-6.196875, 10.232128906249997],
              [-6.1171875, 10.201904296875],
              [-6.034570312499994, 10.19482421875],
              [-5.988671875, 10.239111328124991],
              [-5.940673828125, 10.275097656249997],
              [-5.907568359374977, 10.307226562499991],
              [-5.896191406249983, 10.354736328125],
              [-5.843847656249977, 10.389550781249994],
              [-5.694287109374983, 10.433203125],
              [-5.556591796874983, 10.43994140625],
              [-5.523535156249977, 10.426025390625],
              [-5.461279296874977, 10.359570312499997],
              [-5.382275390624983, 10.314013671874989],
              [-5.262304687499977, 10.319677734374991],
              [-5.17529296875, 10.292626953124994],
              [-5.099853515625, 10.241601562499994],
              [-5.04931640625, 10.128320312499994],
              [-4.994042968749994, 10.046484375],
              [-4.969921874999983, 9.930078125],
              [-4.882714843749994, 9.868945312499989],
              [-4.814453125, 9.841162109374991],
              [-4.721777343749977, 9.756542968749997],
              [-4.625830078124977, 9.713574218749997],
              [-4.526611328125, 9.723486328124991],
              [-4.480273437499989, 9.679248046874989],
              [-4.406201171874983, 9.647998046874989],
              [-4.332226562499983, 9.645703125],
              [-4.267187499999977, 9.743261718749991],
              [-4.18115234375, 9.78173828125],
              [-3.963476562499977, 9.859619140625],
              [-3.877636718749983, 9.894921875],
              [-3.790625, 9.9171875],
              [-3.581152343749977, 9.92431640625],
              [-3.386279296874989, 9.900292968749994],
              [-3.289697265624994, 9.882226562499994],
              [-3.223535156249994, 9.895458984374997],
              [-3.160693359374989, 9.849169921874989],
              [-3.095800781249977, 9.752099609374994],
              [-3.042626953124994, 9.720898437499997],
              [-2.98828125, 9.687353515624991],
              [-2.948144531249994, 9.610742187499994],
              [-2.90087890625, 9.534619140624997],
              [-2.875146484374994, 9.500927734374997],
              [-2.816748046874977, 9.425830078124989],
              [-2.7666015625, 9.424707031249994],
              [-2.7171875, 9.457128906249991],
              [-2.695849609374989, 9.481347656249994],
              [-2.686132812499977, 9.431738281249991],
              [-2.705761718749983, 9.351367187499989],
              [-2.701806640624994, 9.301660156249994],
              [-2.67421875, 9.282617187499994],
              [-2.689208984375, 9.218603515624991],
              [-2.746679687499977, 9.109619140625],
              [-2.746923828124977, 9.045117187499997],
              [-2.689892578124983, 9.025097656249997],
              [-2.649218749999989, 8.956591796874989],
              [-2.624902343749994, 8.839599609375],
              [-2.600390624999989, 8.800439453124994],
              [-2.597998046874977, 8.7763671875],
              [-2.556884765625, 8.493017578124991],
              [-2.505859375, 8.208740234375],
              [-2.538281249999983, 8.171630859375],
              [-2.582763671875, 8.160791015624994],
              [-2.61171875, 8.147558593749991],
              [-2.619970703124977, 8.12109375],
              [-2.600976562499994, 8.082226562499997],
              [-2.613378906249977, 8.046679687499989],
              [-2.668847656249994, 8.022216796875],
              [-2.789746093749983, 7.931933593749989],
              [-2.798144531249989, 7.89599609375],
              [-2.830126953124989, 7.819042968749997],
              [-2.856884765624983, 7.772070312499991],
              [-2.896337890624977, 7.685009765624997],
              [-2.959082031249977, 7.454541015624997],
              [-2.982324218749994, 7.263623046874997],
              [-2.985791015624983, 7.204882812499989],
              [-3.01015625, 7.163769531249997],
              [-3.037695312499977, 7.104589843749991],
              [-3.168896484374983, 6.940966796874989],
              [-3.235791015624983, 6.807226562499991],
              [-3.227148437499977, 6.749121093749991],
              [-3.22412109375, 6.690771484374991],
              [-3.243896484375, 6.648681640625],
              [-3.240283203124989, 6.53564453125],
              [-3.224023437499994, 6.441064453124994],
              [-3.200585937499994, 6.348242187499991],
              [-3.105566406249977, 6.085644531249997],
              [-3.05615234375, 5.92626953125],
              [-3.025292968749994, 5.797753906249994],
              [-2.998291015625, 5.711328125],
              [-2.972802734374994, 5.67626953125],
              [-2.962255859374977, 5.643017578124997],
              [-2.821191406249994, 5.619189453124989],
              [-2.793652343749983, 5.60009765625],
              [-2.754980468749977, 5.432519531249994],
              [-2.761914062499983, 5.35693359375],
              [-2.789599609374989, 5.328222656249991],
              [-2.788671874999977, 5.264111328124997],
              [-2.795214843749989, 5.184521484374997],
              [-2.815673828125, 5.153027343749997],
              [-2.894726562499983, 5.149023437499991],
              [-2.948339843749977, 5.118847656249997],
              [-3.019140624999977, 5.130810546874997],
              [-3.02587890625, 5.150537109374994],
              [-3.06396484375, 5.15771484375],
              [-3.168701171875, 5.203027343749994],
              [-3.151416015624989, 5.348291015624994],
              [-3.199951171875, 5.3544921875],
              [-3.237597656249989, 5.335400390624997],
              [-3.31201171875, 5.160791015624994],
              [-3.347558593749994, 5.130664062499989],
              [-3.87060546875, 5.220703125],
              [-3.984179687499989, 5.293164062499997],
              [-4.120166015624989, 5.309716796874994],
              [-4.357275390624977, 5.301416015624994],
              [-4.552832031249977, 5.279882812499991],
              [-4.60888671875, 5.235888671874989],
              [-4.115185546874983, 5.261621093749994],
              [-4.062060546874989, 5.256640624999989],
              [-4.037207031249977, 5.230126953124994],
              [-4.661523437499994, 5.172558593749997],
              [-4.899707031249989, 5.138330078124994],
              [-4.970117187499994, 5.147753906249989],
              [-5.023681640625, 5.20361328125],
              [-5.282373046874994, 5.210253906249989],
              [-5.33544921875, 5.191992187499991],
              [-5.367529296874977, 5.15078125],
              [-5.265771484374994, 5.159716796874989],
              [-5.104882812499994, 5.162158203124989],
              [-5.061816406249989, 5.130664062499989],
              [-5.564746093749989, 5.089453125],
              [-5.913769531249983, 5.0109375],
              [-6.061718749999983, 4.952832031249997],
              [-6.548437499999977, 4.761767578124989],
              [-6.845166015624983, 4.671484375],
              [-6.922900390624989, 4.638330078124994],
              [-7.057958984374977, 4.544726562499989],
              [-7.231396484374983, 4.485986328124994],
              [-7.426074218749989, 4.376025390624989],
              [-7.544970703124989, 4.351318359375],
              [-7.571582031249989, 4.386425781249997],
              [-7.574658203124983, 4.572314453124989],
              [-7.591210937499994, 4.821533203125],
              [-7.585058593749977, 4.916748046875],
              [-7.5693359375, 5.006445312499991],
              [-7.568896484374989, 5.080664062499991],
              [-7.509765625, 5.108496093749991],
              [-7.494140625, 5.139794921874994],
              [-7.485205078124977, 5.236425781249991],
              [-7.429833984374994, 5.324511718749989],
              [-7.428906249999983, 5.477880859374991],
              [-7.412451171874977, 5.509912109374994],
              [-7.39990234375, 5.550585937499989],
              [-7.423730468749994, 5.651318359374997],
              [-7.454394531249989, 5.84130859375],
              [-7.469433593749983, 5.853710937499997],
              [-7.4828125, 5.845507812499989],
              [-7.513916015625, 5.842041015625],
              [-7.636132812499994, 5.90771484375],
              [-7.730371093749994, 5.919042968749991],
              [-7.796533203124994, 5.97509765625],
              [-7.800927734374994, 6.038916015624991],
              [-7.833251953125, 6.076367187499997],
              [-7.855517578124989, 6.150146484375],
              [-7.888623046874983, 6.23486328125],
              [-7.981591796874994, 6.2861328125],
              [-8.068945312499977, 6.298388671874989],
              [-8.131005859374994, 6.287548828124997],
              [-8.203857421875, 6.290722656249997],
              [-8.287109375, 6.319042968749997],
              [-8.344873046874994, 6.351269531249997],
              [-8.399316406249994, 6.413183593749991],
              [-8.449902343749983, 6.4625],
              [-8.490332031249977, 6.456396484374991],
              [-8.53955078125, 6.468066406249989],
              [-8.587890625, 6.490527343749989],
              [-8.603564453124989, 6.5078125],
              [-8.401220703124977, 6.705126953124989],
              [-8.332568359374989, 6.801562499999989],
              [-8.325097656249994, 6.860400390624989],
              [-8.324511718749989, 6.920019531249991],
              [-8.302343749999977, 6.98095703125],
              [-8.296630859375, 7.074023437499989],
              [-8.408740234374989, 7.411816406249997],
              [-8.437158203124994, 7.516406249999989],
              [-8.46728515625, 7.547021484374994],
              [-8.486425781249977, 7.558496093749994],
              [-8.429980468749989, 7.601855468749989],
              [-8.351757812499983, 7.590576171875],
              [-8.231884765624983, 7.556738281249991],
              [-8.205957031249994, 7.590234375],
              [-8.115429687499983, 7.7607421875],
              [-8.117822265624994, 7.824023437499989],
              [-8.126855468749994, 7.867724609374989],
              [-8.073828124999977, 7.984423828124989],
              [-8.03173828125, 8.029736328124997],
              [-8.009863281249977, 8.078515625],
              [-8.016748046874994, 8.144921875],
              [-8.048583984375, 8.169726562499989],
              [-8.090527343749983, 8.165136718749991],
              [-8.140625, 8.181445312499989],
              [-8.217138671874977, 8.219677734374997],
              [-8.256103515625, 8.253710937499989],
              [-8.244140625, 8.407910156249997],
              [-8.236962890624994, 8.455664062499991],
              [-8.2099609375, 8.483251953124991],
              [-8.167773437499989, 8.490673828124997],
              [-8.049121093749989, 8.4953125],
              [-7.953125, 8.477734375],
              [-7.86875, 8.467529296875],
              [-7.823583984374977, 8.467675781249994],
              [-7.787402343749989, 8.421972656249991],
              [-7.738964843749983, 8.375244140625],
              [-7.696093749999989, 8.375585937499991],
              [-7.681201171874989, 8.410351562499997],
              [-7.690966796874989, 8.5625],
              [-7.719580078124977, 8.643017578124997],
              [-7.784033203124977, 8.720605468749994],
              [-7.950976562499989, 8.786816406249997],
              [-7.954980468749994, 8.879443359374989],
              [-7.938183593749983, 8.979785156249989],
              [-7.902099609375, 9.01708984375],
              [-7.777978515624994, 9.080859374999989],
              [-7.7998046875, 9.115039062499989],
              [-7.839404296874989, 9.151611328125],
              [-7.918066406249977, 9.188525390624989],
              [-7.9, 9.308691406249991],
              [-7.896191406249983, 9.415869140624991],
              [-7.962695312499989, 9.403857421874989],
              [-8.031005859375, 9.39765625],
              [-8.088671874999989, 9.4306640625],
              [-8.136962890625, 9.495703125],
              [-8.146044921874989, 9.6748046875],
              [-8.145849609374977, 9.881738281249994],
              [-8.155175781249994, 9.973193359374989],
              [-8.136621093749994, 10.022070312499991],
              [-8.077832031249983, 10.067089843749997],
              [-8.013525390624977, 10.125292968749989],
              [-7.990625, 10.1625],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Mali",
        sov_a3: "MLI",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Mali",
        adm0_a3: "MLI",
        geou_dif: 0,
        geounit: "Mali",
        gu_a3: "MLI",
        su_dif: 0,
        subunit: "Mali",
        su_a3: "MLI",
        brk_diff: 0,
        name: "Mali",
        name_long: "Mali",
        brk_a3: "MLI",
        brk_name: "Mali",
        brk_group: null,
        abbrev: "Mali",
        postal: "ML",
        formal_en: "Republic of Mali",
        formal_fr: null,
        name_ciawf: "Mali",
        note_adm0: null,
        note_brk: null,
        name_sort: "Mali",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 4,
        mapcolor9: 1,
        mapcolor13: 7,
        pop_est: 19658031,
        pop_rank: 14,
        pop_year: 2019,
        gdp_md: 17279,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        fips_10: "ML",
        iso_a2: "ML",
        iso_a2_eh: "ML",
        iso_a3: "MLI",
        iso_a3_eh: "MLI",
        iso_n3: "466",
        iso_n3_eh: "466",
        un_a3: "466",
        wb_a2: "ML",
        wb_a3: "MLI",
        woe_id: 23424891,
        woe_id_eh: 23424891,
        woe_note: "Exact WOE match as country",
        adm0_iso: "MLI",
        adm0_diff: null,
        adm0_tlc: "MLI",
        adm0_a3_us: "MLI",
        adm0_a3_fr: "MLI",
        adm0_a3_ru: "MLI",
        adm0_a3_es: "MLI",
        adm0_a3_cn: "MLI",
        adm0_a3_tw: "MLI",
        adm0_a3_in: "MLI",
        adm0_a3_np: "MLI",
        adm0_a3_pk: "MLI",
        adm0_a3_de: "MLI",
        adm0_a3_gb: "MLI",
        adm0_a3_br: "MLI",
        adm0_a3_il: "MLI",
        adm0_a3_ps: "MLI",
        adm0_a3_sa: "MLI",
        adm0_a3_eg: "MLI",
        adm0_a3_ma: "MLI",
        adm0_a3_pt: "MLI",
        adm0_a3_ar: "MLI",
        adm0_a3_jp: "MLI",
        adm0_a3_ko: "MLI",
        adm0_a3_vn: "MLI",
        adm0_a3_tr: "MLI",
        adm0_a3_id: "MLI",
        adm0_a3_pl: "MLI",
        adm0_a3_gr: "MLI",
        adm0_a3_it: "MLI",
        adm0_a3_nl: "MLI",
        adm0_a3_se: "MLI",
        adm0_a3_bd: "MLI",
        adm0_a3_ua: "MLI",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Western Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 4,
        long_len: 4,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 7,
        label_x: -2.038455,
        label_y: 18.692713,
        ne_id: 1159321063,
        wikidataid: "Q912",
        name_ar: "مالي",
        name_bn: "মালি",
        name_de: "Mali",
        name_en: "Mali",
        name_es: "Malí",
        name_fa: "مالی",
        name_fr: "Mali",
        name_el: "Μάλι",
        name_he: "מאלי",
        name_hi: "माली",
        name_hu: "Mali",
        name_id: "Mali",
        name_it: "Mali",
        name_ja: "マリ共和国",
        name_ko: "말리",
        name_nl: "Mali",
        name_pl: "Mali",
        name_pt: "Mali",
        name_ru: "Мали",
        name_sv: "Mali",
        name_tr: "Mali",
        name_uk: "Малі",
        name_ur: "مالی",
        name_vi: "Mali",
        name_zh: "马里",
        name_zht: "馬利共和國",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "MLI.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-11.389404296875, 12.404394531249991],
            [-11.382421874999977, 12.479248046875],
            [-11.448779296874989, 12.531933593749997],
            [-11.450585937499994, 12.557714843749991],
            [-11.444091796875, 12.627587890624994],
            [-11.414355468749989, 12.775488281249991],
            [-11.417431640624983, 12.831884765624991],
            [-11.390380859375, 12.941992187499991],
            [-11.433935546874977, 12.991601562499994],
            [-11.444140624999989, 13.028222656249994],
            [-11.492822265624994, 13.086962890624989],
            [-11.548779296874983, 13.170263671874991],
            [-11.561669921874994, 13.236962890624994],
            [-11.581347656249989, 13.2900390625],
            [-11.634960937499983, 13.369873046875],
            [-11.674462890624994, 13.382373046875003],
            [-11.758251953124983, 13.39453125],
            [-11.772216796875, 13.367089843749994],
            [-11.803369140624994, 13.327294921874994],
            [-11.831689453124994, 13.315820312499994],
            [-11.877783203124977, 13.364550781250003],
            [-11.895214843749983, 13.406298828125003],
            [-11.894580078124989, 13.444433593749991],
            [-11.957080078124989, 13.510888671874994],
            [-12.05419921875, 13.633056640625],
            [-12.044140624999983, 13.73388671875],
            [-11.984179687499989, 13.7880859375],
            [-11.966357421874989, 13.828955078124991],
            [-11.960888671874983, 13.875292968750003],
            [-11.988085937499989, 13.930761718749991],
            [-12.020117187499977, 13.974658203125003],
            [-12.011181640624983, 14.071826171875003],
            [-12.019189453124994, 14.206494140624997],
            [-12.068359375, 14.27421875],
            [-12.112890624999977, 14.323291015625003],
            [-12.175244140624983, 14.376660156249997],
            [-12.228417968749994, 14.45859375],
            [-12.206835937499989, 14.571142578124991],
            [-12.1865234375, 14.648144531249997],
            [-12.280615234374977, 14.809033203124997],
            [-12.104687499999983, 14.745361328125],
            [-12.08154296875, 14.766357421875],
            [-12.021582031249977, 14.804931640625],
            [-11.94091796875, 14.886914062499997],
            [-11.872851562499989, 14.995166015625003],
            [-11.842236328124983, 15.12939453125],
            [-11.828759765624994, 15.244873046875],
            [-11.798437499999977, 15.342724609374997],
            [-11.76015625, 15.425537109375],
            [-11.675878906249977, 15.512060546874991],
            [-11.596728515624989, 15.5732421875],
            [-11.502685546875, 15.636816406249991],
            [-11.455224609374994, 15.625390625],
            [-11.365625, 15.536767578124994],
            [-11.169335937499994, 15.358642578125],
            [-11.007421874999977, 15.222900390625],
            [-10.9482421875, 15.151123046875],
            [-10.895605468749977, 15.150488281249991],
            [-10.815087890624994, 15.28173828125],
            [-10.731982421874989, 15.394921875],
            [-10.696582031249989, 15.42265625],
            [-10.586572265624994, 15.434863281250003],
            [-10.4931640625, 15.439794921874991],
            [-10.411816406249983, 15.437939453124997],
            [-10.262109375, 15.416015625],
            [-10.19375, 15.396044921875003],
            [-10.129541015624994, 15.383691406249994],
            [-9.94140625, 15.373779296875],
            [-9.755078124999983, 15.401464843749991],
            [-9.577832031249983, 15.437255859375],
            [-9.446923828124994, 15.458203125],
            [-9.440332031249994, 15.511669921874997],
            [-9.447705078124983, 15.574853515624994],
            [-9.426562499999989, 15.623046875],
            [-9.385351562499977, 15.667626953124994],
            [-9.3505859375, 15.677392578124994],
            [-9.33544921875, 15.525683593750003],
            [-9.293701171875, 15.502832031249994],
            [-9.176806640624989, 15.49609375],
            [-8.987060546875, 15.49609375],
            [-8.783105468749994, 15.49609375],
            [-8.579150390624989, 15.496142578125003],
            [-8.375195312499983, 15.496142578125003],
            [-8.171240234374977, 15.496142578125003],
            [-7.96728515625, 15.496142578125003],
            [-7.763378906249983, 15.496142578125003],
            [-7.559375, 15.496142578125003],
            [-7.35546875, 15.496191406249991],
            [-7.151513671874994, 15.496191406249991],
            [-6.947558593749989, 15.496191406249991],
            [-6.743603515624983, 15.496191406249991],
            [-6.539648437499977, 15.496191406249991],
            [-6.335742187499989, 15.496191406249991],
            [-6.131787109374983, 15.496240234374994],
            [-5.927832031249977, 15.496289062499997],
            [-5.723876953125, 15.496289062499997],
            [-5.5125, 15.496289062499997],
            [-5.455615234374989, 15.789404296874991],
            [-5.403564453125, 16.057910156250003],
            [-5.359912109374989, 16.282861328124994],
            [-5.509619140624977, 16.442041015624994],
            [-5.628662109375, 16.568652343750003],
            [-5.65625, 16.8095703125],
            [-5.684765624999983, 17.058251953124994],
            [-5.713183593749989, 17.306884765625],
            [-5.74169921875, 17.555566406249994],
            [-5.770166015624994, 17.804248046875003],
            [-5.798632812499989, 18.052929687499997],
            [-5.827099609374983, 18.3015625],
            [-5.855566406249977, 18.550244140624997],
            [-5.884082031249989, 18.798876953125003],
            [-5.9125, 19.047509765624994],
            [-5.941015624999977, 19.296191406250003],
            [-5.969482421875, 19.544873046874997],
            [-5.997949218749994, 19.793505859375003],
            [-6.026416015624989, 20.0421875],
            [-6.054882812499983, 20.29086914062499],
            [-6.083398437499994, 20.539501953124997],
            [-6.11181640625, 20.78818359374999],
            [-6.140332031249983, 21.036865234375],
            [-6.168798828124977, 21.285546875],
            [-6.197265625, 21.5341796875],
            [-6.225732421874994, 21.782861328124994],
            [-6.254199218749989, 22.031542968750003],
            [-6.28271484375, 22.280175781249994],
            [-6.311132812499977, 22.528857421875003],
            [-6.339648437499989, 22.777490234374994],
            [-6.368115234374983, 23.026123046875],
            [-6.396582031249977, 23.274804687499994],
            [-6.425048828125, 23.523486328125003],
            [-6.453515625, 23.772167968749997],
            [-6.482031249999977, 24.020800781250003],
            [-6.510449218749983, 24.269482421874997],
            [-6.538964843749994, 24.51816406249999],
            [-6.5673828125, 24.766796875],
            [-6.594091796874977, 24.99462890625],
            [-6.287207031249977, 24.994824218749997],
            [-5.959814453124977, 24.99497070312499],
            [-5.640771484374994, 24.995166015625003],
            [-5.172900390624989, 24.995410156250003],
            [-4.822607421874977, 24.99560546875],
            [-4.516992187499994, 24.804492187500003],
            [-4.240332031249977, 24.62353515625],
            [-3.912792968749983, 24.409472656250003],
            [-3.585351562499994, 24.195361328125003],
            [-3.257861328124989, 23.98125],
            [-2.930371093749983, 23.767138671875003],
            [-2.602929687499994, 23.553027343750003],
            [-2.275390625, 23.3388671875],
            [-1.947900390624994, 23.124804687500003],
            [-1.620410156249989, 22.91064453125],
            [-1.29296875, 22.696533203125],
            [-0.965478515624994, 22.482470703125003],
            [-0.637988281249989, 22.268310546875],
            [-0.310546875, 22.05419921875],
            [0.016992187500023, 21.840136718750003],
            [0.344433593750011, 21.6259765625],
            [0.671875, 21.411865234375],
            [0.999414062500023, 21.19775390625],
            [1.1455078125, 21.102246093749997],
            [1.1591796875, 21.0625],
            [1.172753906250023, 20.981982421875003],
            [1.1640625, 20.891308593749997],
            [1.165722656250011, 20.817431640625003],
            [1.208886718750023, 20.767285156249997],
            [1.290234375000011, 20.713574218749997],
            [1.610644531250017, 20.555566406249994],
            [1.636035156250017, 20.524365234374997],
            [1.647363281250023, 20.45883789062499],
            [1.685449218750023, 20.378369140624997],
            [1.753222656250017, 20.331591796875003],
            [1.832421875000023, 20.296875],
            [1.928808593750006, 20.272705078125],
            [2.219335937500006, 20.247802734375],
            [2.280859375, 20.21030273437499],
            [2.406152343750023, 20.063867187499994],
            [2.47421875, 20.03500976562499],
            [2.667773437500017, 19.992919921875],
            [2.807910156250017, 19.969433593749997],
            [2.86572265625, 19.955957031249994],
            [2.992480468750017, 19.91660156249999],
            [3.130273437500023, 19.85019531249999],
            [3.203710937500006, 19.789697265624994],
            [3.203417968750017, 19.770751953125],
            [3.202734375, 19.718310546875003],
            [3.20166015625, 19.56040039062499],
            [3.22705078125, 19.473583984374997],
            [3.255859375, 19.4109375],
            [3.25439453125, 19.372607421875003],
            [3.219628906250023, 19.345410156249997],
            [3.1923828125, 19.312060546875003],
            [3.17724609375, 19.26816406249999],
            [3.137890625000011, 19.212158203125],
            [3.106054687500006, 19.150097656249997],
            [3.119726562500006, 19.103173828124994],
            [3.174218750000023, 19.072900390624994],
            [3.255957031250006, 19.01328125],
            [3.323437500000011, 18.98837890624999],
            [3.3564453125, 18.986621093750003],
            [3.40087890625, 18.988427734374994],
            [3.438769531250017, 18.996142578125003],
            [3.683496093750023, 19.04160156249999],
            [3.91015625, 19.083740234375],
            [4.227636718750006, 19.142773437499997],
            [4.228222656250011, 18.968066406250003],
            [4.22900390625, 18.704345703125],
            [4.22998046875, 18.410595703124997],
            [4.230859375000023, 18.139453125],
            [4.23193359375, 17.830517578124997],
            [4.232714843750017, 17.582177734374994],
            [4.233691406250017, 17.28842773437499],
            [4.234667968750017, 16.996386718750003],
            [4.202929687500017, 16.962695312500003],
            [4.191210937500017, 16.79819335937499],
            [4.18212890625, 16.581787109375],
            [4.121289062500011, 16.357714843750003],
            [4.014843750000011, 16.19272460937499],
            [3.976171875, 16.035546875],
            [3.947070312500017, 15.945654296874991],
            [3.9072265625, 15.896826171874991],
            [3.89794921875, 15.837988281249991],
            [3.876953125, 15.755273437499994],
            [3.842968750000011, 15.701708984375003],
            [3.816503906250006, 15.674023437499997],
            [3.709570312500006, 15.641699218749991],
            [3.5205078125, 15.483105468749997],
            [3.504296875000023, 15.356347656249994],
            [3.2890625, 15.39111328125],
            [3.06015625, 15.427197265624997],
            [3.029394531250006, 15.424853515625003],
            [3.010546875000017, 15.408300781249991],
            [3.001074218750006, 15.340966796874994],
            [2.689648437500011, 15.329882812500003],
            [2.420800781250023, 15.320410156249991],
            [2.088183593750017, 15.309375],
            [1.859375, 15.301708984374997],
            [1.569140625000017, 15.286474609374991],
            [1.300195312500023, 15.272265625],
            [1.121289062500011, 15.126123046874994],
            [0.960058593750006, 14.986914062499991],
            [0.947460937500011, 14.982128906249997],
            [0.718652343750023, 14.954882812500003],
            [0.433007812500023, 14.97900390625],
            [0.286230468750006, 14.980175781249997],
            [0.228710937500011, 14.963671875],
            [0.217480468750011, 14.911474609374991],
            [0.00732421875, 14.984814453124997],
            [-0.235888671874989, 15.059423828124991],
            [-0.405419921874994, 15.0125],
            [-0.432275390624994, 15.028515625],
            [-0.454492187499994, 15.059667968749991],
            [-0.536523437499994, 15.077880859375],
            [-0.666455078124983, 15.069775390624997],
            [-0.760449218749983, 15.047753906249994],
            [-0.907958984375, 14.937402343749994],
            [-1.019189453124994, 14.841357421875003],
            [-1.049560546875, 14.81953125],
            [-1.204980468749994, 14.761523437500003],
            [-1.49365234375, 14.626074218749991],
            [-1.657324218749977, 14.526806640624997],
            [-1.695068359375, 14.508496093749997],
            [-1.767773437499983, 14.486035156249997],
            [-1.879785156249994, 14.481494140625003],
            [-1.973046875, 14.45654296875],
            [-2.05712890625, 14.194628906250003],
            [-2.113232421874983, 14.16845703125],
            [-2.457226562499983, 14.274121093749997],
            [-2.526904296874989, 14.25830078125],
            [-2.586718749999989, 14.227587890625003],
            [-2.778857421874989, 14.07373046875],
            [-2.873925781249994, 13.950732421875003],
            [-2.925878906249977, 13.786767578124994],
            [-2.918505859374989, 13.736376953125003],
            [-2.917089843749977, 13.679492187500003],
            [-2.950830078124994, 13.6484375],
            [-2.997216796874994, 13.637109375],
            [-3.038671874999977, 13.639111328124997],
            [-3.198437499999983, 13.6728515625],
            [-3.248632812499977, 13.658349609374994],
            [-3.270166015624994, 13.577441406250003],
            [-3.266748046874994, 13.40078125],
            [-3.3017578125, 13.28076171875],
            [-3.396728515625, 13.243701171875003],
            [-3.469921874999983, 13.196386718749991],
            [-3.527636718749989, 13.182714843749991],
            [-3.575781249999977, 13.194189453124991],
            [-3.853466796874983, 13.37353515625],
            [-3.947314453124989, 13.402197265624991],
            [-4.051171875, 13.382421875],
            [-4.151025390624994, 13.306201171875003],
            [-4.196191406249994, 13.256152343750003],
            [-4.258691406249994, 13.197314453124989],
            [-4.328710937499977, 13.119042968749994],
            [-4.310253906249983, 13.052490234375],
            [-4.260644531249994, 12.975341796875],
            [-4.225244140624994, 12.879492187499991],
            [-4.227099609374989, 12.793701171875],
            [-4.480615234374994, 12.672216796874991],
            [-4.459863281249994, 12.63037109375],
            [-4.421923828124989, 12.581591796874989],
            [-4.421582031249983, 12.493066406249994],
            [-4.4287109375, 12.337597656249997],
            [-4.479882812499994, 12.281787109374989],
            [-4.546044921874994, 12.226464843749994],
            [-4.5869140625, 12.155029296875],
            [-4.627246093749989, 12.120214843749991],
            [-4.699316406249977, 12.076171875],
            [-4.797949218749977, 12.032128906249994],
            [-4.968994140625, 11.993310546874994],
            [-5.105908203124983, 11.967529296875],
            [-5.157519531249989, 11.9423828125],
            [-5.230175781249983, 11.890283203124994],
            [-5.288134765624989, 11.827929687499989],
            [-5.302001953125, 11.760449218749997],
            [-5.29052734375, 11.683300781249997],
            [-5.270312499999989, 11.619873046875],
            [-5.244775390624994, 11.576757812499991],
            [-5.229394531249994, 11.5224609375],
            [-5.250244140625, 11.375781249999989],
            [-5.299853515624989, 11.205957031249994],
            [-5.347412109375, 11.130273437499994],
            [-5.42421875, 11.088720703124991],
            [-5.490478515625, 11.042382812499994],
            [-5.468554687499989, 10.931054687499994],
            [-5.457080078124989, 10.771386718749994],
            [-5.475683593749977, 10.643945312499994],
            [-5.47900390625, 10.565087890624994],
            [-5.507031249999983, 10.483447265624989],
            [-5.523535156249977, 10.426025390625],
            [-5.556591796874983, 10.43994140625],
            [-5.694287109374983, 10.433203125],
            [-5.843847656249977, 10.389550781249994],
            [-5.896191406249983, 10.354736328125],
            [-5.907568359374977, 10.307226562499991],
            [-5.940673828125, 10.275097656249997],
            [-5.988671875, 10.239111328124991],
            [-6.034570312499994, 10.19482421875],
            [-6.1171875, 10.201904296875],
            [-6.196875, 10.232128906249997],
            [-6.238378906249977, 10.261621093749994],
            [-6.241308593749977, 10.279199218749994],
            [-6.214990234374994, 10.322363281249991],
            [-6.192626953125, 10.369433593749989],
            [-6.190673828125, 10.400292968749994],
            [-6.2177734375, 10.476269531249997],
            [-6.23974609375, 10.55810546875],
            [-6.230664062499983, 10.597509765624991],
            [-6.250244140625, 10.717919921874994],
            [-6.261132812499994, 10.724072265624997],
            [-6.365625, 10.692822265624997],
            [-6.404150390624977, 10.685107421874989],
            [-6.425878906249977, 10.671777343749994],
            [-6.4326171875, 10.648730468749989],
            [-6.407519531249989, 10.572363281249991],
            [-6.423925781249977, 10.559130859374989],
            [-6.482617187499983, 10.561230468749997],
            [-6.564599609374994, 10.58642578125],
            [-6.654150390624977, 10.656445312499997],
            [-6.676367187499977, 10.6337890625],
            [-6.686132812499977, 10.578027343749994],
            [-6.691992187499977, 10.512011718749989],
            [-6.669335937499994, 10.3921875],
            [-6.693261718749994, 10.349462890624991],
            [-6.753222656249989, 10.357128906249997],
            [-6.833642578124994, 10.356982421874989],
            [-6.90380859375, 10.345068359374991],
            [-6.950341796874994, 10.342333984374989],
            [-6.9794921875, 10.299560546875],
            [-6.991748046874989, 10.251855468749994],
            [-6.963818359374983, 10.19873046875],
            [-6.968164062499994, 10.176220703124997],
            [-6.989453124999983, 10.155664062499994],
            [-7.01708984375, 10.143261718749997],
            [-7.039746093749983, 10.144775390625],
            [-7.104882812499994, 10.203515625],
            [-7.182324218749983, 10.225683593749991],
            [-7.363183593749994, 10.259375],
            [-7.385058593749989, 10.340136718749989],
            [-7.414794921875, 10.34130859375],
            [-7.45654296875, 10.383935546874994],
            [-7.497949218749994, 10.439794921874991],
            [-7.532812499999977, 10.436816406249989],
            [-7.562109374999977, 10.421240234374991],
            [-7.6611328125, 10.427441406249997],
            [-7.749072265624989, 10.34228515625],
            [-7.814208984375, 10.236572265625],
            [-7.884082031249989, 10.185742187499997],
            [-7.9609375, 10.163476562499994],
            [-7.990625, 10.1625],
            [-7.974462890624977, 10.229541015624989],
            [-7.985693359374977, 10.278417968749991],
            [-8.007275390624983, 10.321875],
            [-8.231494140624989, 10.43798828125],
            [-8.266650390624989, 10.485986328124994],
            [-8.301562499999989, 10.617578125],
            [-8.324121093749994, 10.74951171875],
            [-8.321679687499994, 10.826953124999989],
            [-8.306347656249983, 10.89609375],
            [-8.312744140625, 10.949755859374989],
            [-8.33740234375, 10.990625],
            [-8.404492187499983, 11.029931640624994],
            [-8.474707031249977, 11.048388671874989],
            [-8.563525390624989, 10.996679687499991],
            [-8.606201171875, 10.986962890624994],
            [-8.646191406249983, 10.990478515625],
            [-8.666699218749983, 11.009472656249997],
            [-8.663916015624977, 11.035839843749997],
            [-8.567285156249994, 11.177001953125],
            [-8.520312499999989, 11.2359375],
            [-8.463525390624994, 11.280712890624997],
            [-8.42529296875, 11.304736328124989],
            [-8.400683593749989, 11.339404296874989],
            [-8.398535156249977, 11.366552734374991],
            [-8.407470703125, 11.386279296874989],
            [-8.470703125, 11.412207031249991],
            [-8.56875, 11.478076171874989],
            [-8.621142578124989, 11.485107421875],
            [-8.664941406249994, 11.514990234374991],
            [-8.71142578125, 11.617773437499991],
            [-8.733105468749983, 11.6375],
            [-8.779736328124983, 11.648242187499989],
            [-8.822021484375, 11.673242187499994],
            [-8.820068359375, 11.80712890625],
            [-8.818310546874983, 11.922509765624994],
            [-8.913867187499989, 12.108544921874994],
            [-8.950830078124994, 12.2255859375],
            [-8.998925781249994, 12.345898437499997],
            [-9.043066406249977, 12.40234375],
            [-9.120458984374977, 12.449951171875],
            [-9.215527343749983, 12.482861328124997],
            [-9.3, 12.490283203124989],
            [-9.365185546874983, 12.479296874999989],
            [-9.395361328124977, 12.464648437499989],
            [-9.393652343749977, 12.442236328124991],
            [-9.3408203125, 12.366015624999989],
            [-9.33154296875, 12.32373046875],
            [-9.340185546874977, 12.282763671874989],
            [-9.358105468749983, 12.255419921874989],
            [-9.404980468749983, 12.25244140625],
            [-9.48681640625, 12.228662109374994],
            [-9.587744140624977, 12.182470703124991],
            [-9.658300781249977, 12.143115234374989],
            [-9.714746093749994, 12.04248046875],
            [-9.754003906249977, 12.029931640624994],
            [-9.820703125, 12.04248046875],
            [-10.010644531249994, 12.116455078125],
            [-10.167089843749977, 12.177441406249997],
            [-10.274853515624983, 12.212646484375],
            [-10.339892578124989, 12.190283203124991],
            [-10.372753906249983, 12.179541015624991],
            [-10.4658203125, 12.138671875],
            [-10.589501953124994, 11.990283203124989],
            [-10.618994140624977, 11.941210937499989],
            [-10.643701171874994, 11.925537109375],
            [-10.677343749999977, 11.8994140625],
            [-10.709228515625, 11.898730468749989],
            [-10.734912109374989, 11.916455078124997],
            [-10.743017578124977, 11.92724609375],
            [-10.806494140624977, 12.034277343749991],
            [-10.876171874999983, 12.15185546875],
            [-10.933203124999977, 12.205175781249991],
            [-11.004541015624994, 12.20751953125],
            [-11.065820312499994, 12.170800781249994],
            [-11.129248046874977, 12.095019531249989],
            [-11.209667968749983, 12.024853515624997],
            [-11.260693359374983, 12.004052734374994],
            [-11.30517578125, 12.015429687499989],
            [-11.414648437499977, 12.10400390625],
            [-11.492431640625, 12.166943359374997],
            [-11.502197265625, 12.198632812499994],
            [-11.474560546874983, 12.247167968749991],
            [-11.447558593749989, 12.319238281249994],
            [-11.418066406249977, 12.377685546875],
            [-11.389404296875, 12.404394531249991],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Senegal",
        sov_a3: "SEN",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Senegal",
        adm0_a3: "SEN",
        geou_dif: 0,
        geounit: "Senegal",
        gu_a3: "SEN",
        su_dif: 0,
        subunit: "Senegal",
        su_a3: "SEN",
        brk_diff: 0,
        name: "Senegal",
        name_long: "Senegal",
        brk_a3: "SEN",
        brk_name: "Senegal",
        brk_group: null,
        abbrev: "Sen.",
        postal: "SN",
        formal_en: "Republic of Senegal",
        formal_fr: null,
        name_ciawf: "Senegal",
        note_adm0: null,
        note_brk: null,
        name_sort: "Senegal",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 6,
        mapcolor9: 5,
        mapcolor13: 5,
        pop_est: 16296364,
        pop_rank: 14,
        pop_year: 2019,
        gdp_md: 23578,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "4. Lower middle income",
        fips_10: "SG",
        iso_a2: "SN",
        iso_a2_eh: "SN",
        iso_a3: "SEN",
        iso_a3_eh: "SEN",
        iso_n3: "686",
        iso_n3_eh: "686",
        un_a3: "686",
        wb_a2: "SN",
        wb_a3: "SEN",
        woe_id: 23424943,
        woe_id_eh: 23424943,
        woe_note: "Exact WOE match as country",
        adm0_iso: "SEN",
        adm0_diff: null,
        adm0_tlc: "SEN",
        adm0_a3_us: "SEN",
        adm0_a3_fr: "SEN",
        adm0_a3_ru: "SEN",
        adm0_a3_es: "SEN",
        adm0_a3_cn: "SEN",
        adm0_a3_tw: "SEN",
        adm0_a3_in: "SEN",
        adm0_a3_np: "SEN",
        adm0_a3_pk: "SEN",
        adm0_a3_de: "SEN",
        adm0_a3_gb: "SEN",
        adm0_a3_br: "SEN",
        adm0_a3_il: "SEN",
        adm0_a3_ps: "SEN",
        adm0_a3_sa: "SEN",
        adm0_a3_eg: "SEN",
        adm0_a3_ma: "SEN",
        adm0_a3_pt: "SEN",
        adm0_a3_ar: "SEN",
        adm0_a3_jp: "SEN",
        adm0_a3_ko: "SEN",
        adm0_a3_vn: "SEN",
        adm0_a3_tr: "SEN",
        adm0_a3_id: "SEN",
        adm0_a3_pl: "SEN",
        adm0_a3_gr: "SEN",
        adm0_a3_it: "SEN",
        adm0_a3_nl: "SEN",
        adm0_a3_se: "SEN",
        adm0_a3_bd: "SEN",
        adm0_a3_ua: "SEN",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Western Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 2.7,
        max_label: 8,
        label_x: -14.778586,
        label_y: 15.138125,
        ne_id: 1159321243,
        wikidataid: "Q1041",
        name_ar: "السنغال",
        name_bn: "সেনেগাল",
        name_de: "Senegal",
        name_en: "Senegal",
        name_es: "Senegal",
        name_fa: "سنگال",
        name_fr: "Sénégal",
        name_el: "Σενεγάλη",
        name_he: "סנגל",
        name_hi: "सेनेगल",
        name_hu: "Szenegál",
        name_id: "Senegal",
        name_it: "Senegal",
        name_ja: "セネガル",
        name_ko: "세네갈",
        name_nl: "Senegal",
        name_pl: "Senegal",
        name_pt: "Senegal",
        name_ru: "Сенегал",
        name_sv: "Senegal",
        name_tr: "Senegal",
        name_uk: "Сенегал",
        name_ur: "سینیگال",
        name_vi: "Sénégal",
        name_zh: "塞内加尔",
        name_zht: "塞內加爾",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "SEN.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-12.280615234374977, 14.809033203124997],
            [-12.1865234375, 14.648144531249997],
            [-12.206835937499989, 14.571142578124991],
            [-12.228417968749994, 14.45859375],
            [-12.175244140624983, 14.376660156249997],
            [-12.112890624999977, 14.323291015625003],
            [-12.068359375, 14.27421875],
            [-12.019189453124994, 14.206494140624997],
            [-12.011181640624983, 14.071826171875003],
            [-12.020117187499977, 13.974658203125003],
            [-11.988085937499989, 13.930761718749991],
            [-11.960888671874983, 13.875292968750003],
            [-11.966357421874989, 13.828955078124991],
            [-11.984179687499989, 13.7880859375],
            [-12.044140624999983, 13.73388671875],
            [-12.05419921875, 13.633056640625],
            [-11.957080078124989, 13.510888671874994],
            [-11.894580078124989, 13.444433593749991],
            [-11.895214843749983, 13.406298828125003],
            [-11.877783203124977, 13.364550781250003],
            [-11.831689453124994, 13.315820312499994],
            [-11.803369140624994, 13.327294921874994],
            [-11.772216796875, 13.367089843749994],
            [-11.758251953124983, 13.39453125],
            [-11.674462890624994, 13.382373046875003],
            [-11.634960937499983, 13.369873046875],
            [-11.581347656249989, 13.2900390625],
            [-11.561669921874994, 13.236962890624994],
            [-11.548779296874983, 13.170263671874991],
            [-11.492822265624994, 13.086962890624989],
            [-11.444140624999989, 13.028222656249994],
            [-11.433935546874977, 12.991601562499994],
            [-11.390380859375, 12.941992187499991],
            [-11.417431640624983, 12.831884765624991],
            [-11.414355468749989, 12.775488281249991],
            [-11.444091796875, 12.627587890624994],
            [-11.450585937499994, 12.557714843749991],
            [-11.448779296874989, 12.531933593749997],
            [-11.382421874999977, 12.479248046875],
            [-11.389404296875, 12.404394531249991],
            [-11.456738281249983, 12.417578125],
            [-11.573681640624983, 12.426318359374989],
            [-11.80810546875, 12.387304687499991],
            [-11.888574218749994, 12.4033203125],
            [-12.042382812499994, 12.398046875],
            [-12.151953124999977, 12.376611328124994],
            [-12.291210937499983, 12.328027343749994],
            [-12.399072265624994, 12.340087890625],
            [-12.457373046874977, 12.378369140624997],
            [-12.534228515624989, 12.375781249999989],
            [-12.620800781249983, 12.396191406249997],
            [-12.713037109374994, 12.433154296874989],
            [-12.797314453124983, 12.451904296875],
            [-12.88818359375, 12.52001953125],
            [-12.930712890624989, 12.532275390624989],
            [-12.960546874999977, 12.514355468749997],
            [-12.985644531249989, 12.491650390624997],
            [-13.011914062499983, 12.477636718749991],
            [-13.061279296875, 12.489990234375],
            [-13.079833984375, 12.536279296874994],
            [-13.064404296874983, 12.5810546875],
            [-13.059765624999983, 12.615039062499989],
            [-13.082910156249994, 12.633544921875],
            [-13.138476562499989, 12.639746093749991],
            [-13.228076171874989, 12.639599609374997],
            [-13.37255859375, 12.653613281249989],
            [-13.40576171875, 12.662255859374994],
            [-13.729248046875, 12.673925781249991],
            [-14.06484375, 12.67529296875],
            [-14.349218749999977, 12.676416015624994],
            [-14.708154296874994, 12.677978515625],
            [-14.960595703124994, 12.678955078125],
            [-15.196093749999989, 12.679931640625],
            [-15.3779296875, 12.588964843749991],
            [-15.574804687499977, 12.490380859374994],
            [-15.839550781249983, 12.437890625],
            [-16.144189453124994, 12.457421875],
            [-16.24150390624999, 12.443310546874997],
            [-16.34228515625, 12.399511718749991],
            [-16.41630859374999, 12.36767578125],
            [-16.521337890624977, 12.3486328125],
            [-16.656933593749983, 12.364355468749991],
            [-16.711816406249994, 12.354833984374991],
            [-16.745849609375, 12.399707031249989],
            [-16.784863281249983, 12.472509765624991],
            [-16.76030273437499, 12.52578125],
            [-16.677636718749994, 12.56005859375],
            [-16.55322265625, 12.604882812499994],
            [-16.48808593749999, 12.581835937499989],
            [-16.449951171875, 12.580712890624994],
            [-16.44287109375, 12.609472656249991],
            [-16.455029296874983, 12.624804687499989],
            [-16.548828125, 12.663818359375],
            [-16.59765625, 12.715283203124997],
            [-16.637841796874994, 12.68515625],
            [-16.672558593749983, 12.622021484374997],
            [-16.701416015625, 12.603173828124994],
            [-16.743896484375, 12.58544921875],
            [-16.76796875, 12.62841796875],
            [-16.778417968749977, 12.670166015625],
            [-16.758984374999983, 12.70234375],
            [-16.768945312499994, 12.88330078125],
            [-16.75737304687499, 12.979785156249989],
            [-16.763330078124994, 13.064160156249997],
            [-16.704541015624983, 13.119726562499991],
            [-16.648779296874977, 13.154150390624991],
            [-16.430859374999983, 13.157324218749991],
            [-16.22832031249999, 13.160302734374994],
            [-16.033056640624977, 13.158349609374994],
            [-15.834277343749989, 13.156445312499997],
            [-15.814404296874983, 13.325146484374997],
            [-15.751562499999977, 13.33837890625],
            [-15.657324218749977, 13.355810546874991],
            [-15.481835937499994, 13.376367187499994],
            [-15.286230468749977, 13.39599609375],
            [-15.24453125, 13.429101562499994],
            [-15.212109374999983, 13.485058593749997],
            [-15.191601562499983, 13.535253906249991],
            [-15.151123046875, 13.556494140624991],
            [-15.096386718749983, 13.539648437499991],
            [-15.024609374999983, 13.513330078124994],
            [-14.950292968749977, 13.472607421874997],
            [-14.865039062499989, 13.434863281250003],
            [-14.808251953124994, 13.4111328125],
            [-14.671923828124989, 13.351708984374994],
            [-14.438574218749977, 13.268896484374991],
            [-14.246777343749983, 13.23583984375],
            [-14.014892578125, 13.29638671875],
            [-13.847509765624977, 13.335302734374991],
            [-13.826708984374989, 13.4078125],
            [-13.852832031249989, 13.478564453125003],
            [-13.977392578124977, 13.54345703125],
            [-14.14697265625, 13.5361328125],
            [-14.199023437499989, 13.51875],
            [-14.278027343749983, 13.497167968749991],
            [-14.325537109374977, 13.488574218750003],
            [-14.405468749999983, 13.503710937500003],
            [-14.506982421874994, 13.559716796874994],
            [-14.570849609374989, 13.616162109374997],
            [-14.66015625, 13.642626953125003],
            [-14.766015625, 13.669091796874994],
            [-14.935791015625, 13.785205078125003],
            [-15.024462890624989, 13.806005859374991],
            [-15.108349609374983, 13.812109375],
            [-15.26953125, 13.789111328125003],
            [-15.426855468749977, 13.727001953124997],
            [-15.509667968749994, 13.586230468750003],
            [-15.667187499999983, 13.58828125],
            [-16.001611328124994, 13.5927734375],
            [-16.308740234374994, 13.596875],
            [-16.56230468749999, 13.587304687499994],
            [-16.587792968749994, 13.689550781249991],
            [-16.647851562499994, 13.77099609375],
            [-16.74541015624999, 13.840429687499991],
            [-16.766943359374977, 13.904931640624994],
            [-16.73388671875, 13.961181640625],
            [-16.639599609374983, 14.007470703124994],
            [-16.618115234374983, 14.04052734375],
            [-16.66748046875, 14.035595703124997],
            [-16.742138671874983, 14.005810546874997],
            [-16.791748046875, 14.004150390625],
            [-16.797753906249994, 14.09326171875],
            [-16.880517578124994, 14.208349609374991],
            [-16.973828124999983, 14.403222656249994],
            [-17.07939453124999, 14.483056640624994],
            [-17.168066406249977, 14.640625],
            [-17.260644531249994, 14.701074218749994],
            [-17.345800781249977, 14.729296875],
            [-17.41845703125, 14.723486328124991],
            [-17.445019531249983, 14.651611328125],
            [-17.53564453125, 14.755126953125],
            [-17.411816406249983, 14.7921875],
            [-17.147167968749983, 14.922021484374994],
            [-16.843408203124994, 15.293994140625003],
            [-16.570751953124983, 15.734423828125003],
            [-16.535253906249977, 15.83837890625],
            [-16.502050781249977, 15.917333984374991],
            [-16.480078124999977, 16.097216796875003],
            [-16.441015624999977, 16.204541015624997],
            [-16.40434570312499, 16.224902343750003],
            [-16.358105468749983, 16.307177734375003],
            [-16.30229492187499, 16.451318359374994],
            [-16.239013671875, 16.531298828125003],
            [-16.168359375, 16.547070312499997],
            [-16.11328125, 16.54013671874999],
            [-16.07402343749999, 16.510449218749997],
            [-15.958984375, 16.492138671874997],
            [-15.768212890624994, 16.485107421875],
            [-15.620800781249983, 16.506591796875],
            [-15.516699218749977, 16.556591796874997],
            [-15.379980468749977, 16.581982421874997],
            [-15.210546874999977, 16.58261718749999],
            [-15.121435546874977, 16.60361328124999],
            [-15.112646484374977, 16.644921875],
            [-15.090576171875, 16.657373046874994],
            [-15.055224609374989, 16.64096679687499],
            [-15.021923828124983, 16.6474609375],
            [-14.990625, 16.676904296874994],
            [-14.959521484374989, 16.67890625],
            [-14.928613281249994, 16.653515625],
            [-14.786718749999977, 16.645898437499994],
            [-14.533740234374989, 16.655957031249997],
            [-14.300097656249989, 16.580273437499997],
            [-14.085644531249983, 16.418847656249994],
            [-13.975048828124983, 16.31113281249999],
            [-13.968164062499994, 16.257226562499994],
            [-13.9326171875, 16.202880859375],
            [-13.868457031249989, 16.148144531249997],
            [-13.809814453125, 16.13803710937499],
            [-13.756640624999989, 16.172509765624994],
            [-13.714941406249977, 16.16879882812499],
            [-13.684667968749977, 16.126904296874997],
            [-13.62353515625, 16.118310546874994],
            [-13.555517578124977, 16.14404296875],
            [-13.506982421874994, 16.135205078124997],
            [-13.498144531249977, 16.110302734374997],
            [-13.486962890624994, 16.09702148437499],
            [-13.4541015625, 16.091113281250003],
            [-13.40966796875, 16.05917968749999],
            [-13.347558593749994, 15.973486328124991],
            [-13.297021484374994, 15.853857421874991],
            [-13.258007812499983, 15.700390625],
            [-13.206445312499994, 15.616894531249997],
            [-13.142382812499989, 15.603320312500003],
            [-13.105273437499989, 15.57177734375],
            [-13.097900390625, 15.535253906249991],
            [-13.079296874999983, 15.510449218749997],
            [-13.048535156249983, 15.496630859375003],
            [-12.994335937499983, 15.5048828125],
            [-12.930859374999983, 15.453027343749994],
            [-12.862695312499994, 15.340429687499991],
            [-12.851904296874977, 15.289648437499991],
            [-12.862646484374977, 15.262402343749997],
            [-12.858496093749977, 15.242529296874991],
            [-12.813183593749983, 15.223535156249994],
            [-12.770312499999989, 15.186669921874994],
            [-12.735253906249994, 15.13125],
            [-12.659619140624983, 15.082080078125003],
            [-12.543554687499977, 15.039013671874997],
            [-12.459863281249994, 14.974658203125003],
            [-12.40869140625, 14.889013671874991],
            [-12.302539062499989, 14.816992187499991],
            [-12.280615234374977, 14.809033203124997],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 2,
        sovereignt: "Nigeria",
        sov_a3: "NGA",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Nigeria",
        adm0_a3: "NGA",
        geou_dif: 0,
        geounit: "Nigeria",
        gu_a3: "NGA",
        su_dif: 0,
        subunit: "Nigeria",
        su_a3: "NGA",
        brk_diff: 0,
        name: "Nigeria",
        name_long: "Nigeria",
        brk_a3: "NGA",
        brk_name: "Nigeria",
        brk_group: null,
        abbrev: "Nigeria",
        postal: "NG",
        formal_en: "Federal Republic of Nigeria",
        formal_fr: null,
        name_ciawf: "Nigeria",
        note_adm0: null,
        note_brk: null,
        name_sort: "Nigeria",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 2,
        mapcolor9: 5,
        mapcolor13: 2,
        pop_est: 200963599,
        pop_rank: 17,
        pop_year: 2019,
        gdp_md: 448120,
        gdp_year: 2019,
        economy: "5. Emerging region: G20",
        income_grp: "4. Lower middle income",
        fips_10: "NI",
        iso_a2: "NG",
        iso_a2_eh: "NG",
        iso_a3: "NGA",
        iso_a3_eh: "NGA",
        iso_n3: "566",
        iso_n3_eh: "566",
        un_a3: "566",
        wb_a2: "NG",
        wb_a3: "NGA",
        woe_id: 23424908,
        woe_id_eh: 23424908,
        woe_note: "Exact WOE match as country",
        adm0_iso: "NGA",
        adm0_diff: null,
        adm0_tlc: "NGA",
        adm0_a3_us: "NGA",
        adm0_a3_fr: "NGA",
        adm0_a3_ru: "NGA",
        adm0_a3_es: "NGA",
        adm0_a3_cn: "NGA",
        adm0_a3_tw: "NGA",
        adm0_a3_in: "NGA",
        adm0_a3_np: "NGA",
        adm0_a3_pk: "NGA",
        adm0_a3_de: "NGA",
        adm0_a3_gb: "NGA",
        adm0_a3_br: "NGA",
        adm0_a3_il: "NGA",
        adm0_a3_ps: "NGA",
        adm0_a3_sa: "NGA",
        adm0_a3_eg: "NGA",
        adm0_a3_ma: "NGA",
        adm0_a3_pt: "NGA",
        adm0_a3_ar: "NGA",
        adm0_a3_jp: "NGA",
        adm0_a3_ko: "NGA",
        adm0_a3_vn: "NGA",
        adm0_a3_tr: "NGA",
        adm0_a3_id: "NGA",
        adm0_a3_pl: "NGA",
        adm0_a3_gr: "NGA",
        adm0_a3_it: "NGA",
        adm0_a3_nl: "NGA",
        adm0_a3_se: "NGA",
        adm0_a3_bd: "NGA",
        adm0_a3_ua: "NGA",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Western Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 7,
        long_len: 7,
        abbrev_len: 7,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 1.7,
        max_label: 6.7,
        label_x: 7.50322,
        label_y: 9.439799,
        ne_id: 1159321089,
        wikidataid: "Q1033",
        name_ar: "نيجيريا",
        name_bn: "নাইজেরিয়া",
        name_de: "Nigeria",
        name_en: "Nigeria",
        name_es: "Nigeria",
        name_fa: "نیجریه",
        name_fr: "Nigeria",
        name_el: "Νιγηρία",
        name_he: "ניגריה",
        name_hi: "नाईजीरिया",
        name_hu: "Nigéria",
        name_id: "Nigeria",
        name_it: "Nigeria",
        name_ja: "ナイジェリア",
        name_ko: "나이지리아",
        name_nl: "Nigeria",
        name_pl: "Nigeria",
        name_pt: "Nigéria",
        name_ru: "Нигерия",
        name_sv: "Nigeria",
        name_tr: "Nijerya",
        name_uk: "Нігерія",
        name_ur: "نائجیریا",
        name_vi: "Nigeria",
        name_zh: "尼日利亚",
        name_zht: "奈及利亞",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "NGA.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [7.30078125, 4.418164062499997],
              [7.203906250000017, 4.387646484374997],
              [7.140429687500017, 4.395117187499991],
              [7.227343750000017, 4.52734375],
              [7.271386718750023, 4.498925781249994],
              [7.327929687500017, 4.487207031249994],
              [7.30078125, 4.418164062499997],
            ],
          ],
          [
            [
              [13.606347656250023, 13.70458984375],
              [13.763476562500017, 13.489550781250003],
              [13.932324218750011, 13.258496093749997],
              [14.06396484375, 13.078515625],
              [14.160058593750023, 12.61279296875],
              [14.170312500000023, 12.524072265624994],
              [14.177636718750023, 12.484082031249997],
              [14.184863281250017, 12.447216796874997],
              [14.197460937500011, 12.3837890625],
              [14.272851562500023, 12.356494140624989],
              [14.415429687500023, 12.344140625],
              [14.518945312500023, 12.298242187499994],
              [14.580957031250023, 12.222070312499994],
              [14.587011718750006, 12.209423828124997],
              [14.619726562500006, 12.150976562499991],
              [14.627148437500011, 12.108691406249989],
              [14.6181640625, 11.986621093749989],
              [14.597363281250011, 11.829833984375],
              [14.561816406250017, 11.728710937499997],
              [14.581640625, 11.591162109374991],
              [14.575390625000011, 11.532421875],
              [14.559765625000011, 11.492285156249991],
              [14.49609375, 11.446142578124991],
              [14.409472656250017, 11.401171874999989],
              [14.202343750000011, 11.268164062499991],
              [14.143261718750011, 11.24853515625],
              [14.056738281250006, 11.245019531249994],
              [13.9814453125, 11.211865234374997],
              [13.89208984375, 11.140087890624997],
              [13.699902343750011, 10.873144531249991],
              [13.535351562500011, 10.605078125],
              [13.478515625, 10.383251953124997],
              [13.41455078125, 10.171435546874989],
              [13.269921875000023, 10.036181640624989],
              [13.249804687500017, 9.960058593749991],
              [13.24375, 9.915917968749994],
              [13.23876953125, 9.814013671874989],
              [13.22119140625, 9.645166015624994],
              [13.19873046875, 9.563769531249989],
              [13.175488281250011, 9.539648437499991],
              [13.019433593750023, 9.488330078124989],
              [12.929492187500017, 9.42626953125],
              [12.875683593750011, 9.303515624999989],
              [12.85595703125, 9.170751953124991],
              [12.824414062500011, 9.019433593749994],
              [12.806542968750023, 8.886621093749994],
              [12.7822265625, 8.81787109375],
              [12.731152343750011, 8.745654296874989],
              [12.651562500000011, 8.667773437499989],
              [12.582714843750011, 8.624121093749991],
              [12.403515625000011, 8.595556640624991],
              [12.311328125000017, 8.419726562499989],
              [12.2333984375, 8.282324218749991],
              [12.231152343750011, 8.227392578124991],
              [12.155957031250011, 7.942480468749991],
              [12.025195312500017, 7.727783203125],
              [12.0166015625, 7.652001953124994],
              [12.016015625000023, 7.589746093749994],
              [11.852441406250023, 7.400732421874991],
              [11.809179687500006, 7.345068359374991],
              [11.767382812500017, 7.272265624999989],
              [11.80859375, 7.201953124999989],
              [11.854785156250017, 7.137988281249989],
              [11.861425781250006, 7.11640625],
              [11.787011718750023, 7.056201171874989],
              [11.657519531250017, 6.9515625],
              [11.580078125, 6.888867187499997],
              [11.56298828125, 6.854638671874994],
              [11.551660156250023, 6.697265625],
              [11.529101562500017, 6.655029296875],
              [11.4775390625, 6.597412109375],
              [11.401757812500023, 6.533935546875],
              [11.324609375000023, 6.484667968749989],
              [11.2373046875, 6.450537109374991],
              [11.1533203125, 6.437939453124997],
              [11.1064453125, 6.457714843749997],
              [11.0796875, 6.505517578124994],
              [11.032519531250017, 6.697900390624994],
              [11.008691406250023, 6.739111328124991],
              [10.954199218750006, 6.7765625],
              [10.846484375000017, 6.881787109374997],
              [10.737597656250017, 6.98828125],
              [10.60625, 7.063085937499991],
              [10.578125, 7.057714843749991],
              [10.556347656250011, 7.037451171874991],
              [10.51904296875, 6.930468749999989],
              [10.482324218750023, 6.891259765624994],
              [10.413183593750006, 6.877734374999989],
              [10.293066406250006, 6.876757812499989],
              [10.205468750000023, 6.8916015625],
              [10.185546875, 6.912792968749997],
              [10.167773437500017, 6.959179687499997],
              [10.1435546875, 6.996435546874991],
              [10.038867187500017, 6.92138671875],
              [9.874218750000011, 6.803271484374989],
              [9.820703125000023, 6.783935546875],
              [9.779882812500006, 6.76015625],
              [9.7255859375, 6.65],
              [9.659960937500017, 6.531982421875],
              [9.574023437500017, 6.470410156249997],
              [9.490234375, 6.418652343749997],
              [9.442187500000017, 6.373388671874991],
              [9.373339843750017, 6.319628906249989],
              [9.23876953125, 6.186132812499991],
              [9.06015625, 6.009082031249989],
              [8.997167968750006, 5.917724609375],
              [8.93505859375, 5.781005859375],
              [8.898828125000023, 5.629687499999989],
              [8.859179687500017, 5.463769531249994],
              [8.800976562500011, 5.197460937499997],
              [8.715625, 5.046875],
              [8.640527343750023, 4.927001953125],
              [8.585156250000011, 4.832812499999989],
              [8.555859375000011, 4.755224609374991],
              [8.54375, 4.7578125],
              [8.514843750000011, 4.724707031249991],
              [8.431347656250011, 4.746240234374994],
              [8.393652343750006, 4.813769531249989],
              [8.342089843750017, 4.824755859374989],
              [8.252734375000017, 4.923974609374994],
              [8.233789062500023, 4.907470703125],
              [8.328027343750023, 4.656103515624991],
              [8.293066406250006, 4.5576171875],
              [8.028515625000011, 4.555371093749997],
              [7.80078125, 4.522265624999989],
              [7.644238281250011, 4.525341796874997],
              [7.565625, 4.5609375],
              [7.53076171875, 4.655175781249994],
              [7.517382812500017, 4.645458984374997],
              [7.509472656250011, 4.594921875],
              [7.459863281250023, 4.555224609374989],
              [7.284375, 4.547656249999989],
              [7.206738281250011, 4.612060546875],
              [7.143847656250017, 4.68408203125],
              [7.076562500000023, 4.716162109374991],
              [7.0869140625, 4.685839843749989],
              [7.164160156250006, 4.615576171874991],
              [7.154687500000023, 4.514404296875],
              [7.013378906250011, 4.397314453124991],
              [6.923242187500023, 4.390673828124989],
              [6.867871093750011, 4.441113281249997],
              [6.839160156250017, 4.523486328124989],
              [6.82470703125, 4.645263671875],
              [6.78759765625, 4.724707031249991],
              [6.767675781250006, 4.724707031249991],
              [6.786035156250023, 4.652001953124994],
              [6.792187500000011, 4.592626953124991],
              [6.793066406250006, 4.469140625],
              [6.8603515625, 4.373339843749989],
              [6.757031250000011, 4.343554687499989],
              [6.715136718750017, 4.342431640624994],
              [6.633007812500011, 4.340234375],
              [6.617285156250006, 4.375781249999989],
              [6.6015625, 4.455175781249991],
              [6.579980468750023, 4.475976562499994],
              [6.554589843750023, 4.34140625],
              [6.5, 4.331933593749994],
              [6.462109375000011, 4.333154296874994],
              [6.2998046875, 4.303857421874994],
              [6.263671875, 4.309423828124991],
              [6.255957031250006, 4.33447265625],
              [6.275292968750023, 4.371679687499991],
              [6.27099609375, 4.43212890625],
              [6.214648437500017, 4.385498046875],
              [6.20556640625, 4.292285156249989],
              [6.17333984375, 4.277392578124989],
              [6.076562500000023, 4.290625],
              [5.970703125, 4.338574218749997],
              [5.906445312500011, 4.387744140624989],
              [5.798632812500017, 4.455957031249994],
              [5.587792968750023, 4.647216796875],
              [5.553613281250023, 4.733203124999989],
              [5.493261718750006, 4.838769531249994],
              [5.448144531250023, 4.945849609374989],
              [5.38330078125, 5.129003906249991],
              [5.403222656250023, 5.142285156249997],
              [5.4521484375, 5.1265625],
              [5.475976562500023, 5.153857421874989],
              [5.38828125, 5.173779296874997],
              [5.370019531250023, 5.195019531249997],
              [5.364160156250023, 5.25927734375],
              [5.367968750000017, 5.337744140624991],
              [5.439257812500017, 5.365332031249991],
              [5.500878906250023, 5.378613281249997],
              [5.531835937500006, 5.426367187499991],
              [5.549707031250023, 5.47421875],
              [5.385839843750006, 5.401757812499994],
              [5.232421875, 5.483789062499994],
              [5.19921875, 5.533544921874991],
              [5.2158203125, 5.571679687499994],
              [5.2890625, 5.577490234374991],
              [5.393847656250017, 5.574511718749989],
              [5.456640625, 5.61171875],
              [5.418066406250006, 5.624707031249997],
              [5.350292968750011, 5.623291015625],
              [5.325292968750006, 5.64794921875],
              [5.327343750000011, 5.70751953125],
              [5.305371093750011, 5.6943359375],
              [5.276269531250023, 5.641552734374997],
              [5.1728515625, 5.602734375],
              [5.112402343750006, 5.641552734374997],
              [5.10625, 5.728125],
              [5.093066406250017, 5.76708984375],
              [5.042089843750006, 5.797509765624994],
              [4.861035156250011, 6.026318359374997],
              [4.633593750000017, 6.2171875],
              [4.431347656250011, 6.348583984374997],
              [4.125878906250023, 6.411376953125],
              [3.486621093750017, 6.408935546875],
              [3.45078125, 6.427050781249989],
              [3.489941406250011, 6.457275390625],
              [3.546093750000011, 6.477441406249994],
              [3.751660156250011, 6.583837890624991],
              [3.716992187500011, 6.597949218749989],
              [3.503320312500023, 6.531347656249991],
              [3.43017578125, 6.525],
              [3.335546875, 6.396923828124997],
              [2.7724609375, 6.375732421875],
              [2.706445312500023, 6.369238281249991],
              [2.7080078125, 6.427685546874997],
              [2.735644531250017, 6.595703125],
              [2.753710937500017, 6.661767578124994],
              [2.774609375000011, 6.711718749999989],
              [2.7529296875, 6.771630859374994],
              [2.731738281250017, 6.852832031249989],
              [2.721386718750011, 6.980273437499989],
              [2.747753906250011, 7.019824218749989],
              [2.756738281250023, 7.067919921874989],
              [2.750585937500006, 7.143212890624994],
              [2.75048828125, 7.395068359374989],
              [2.765820312500011, 7.422509765624994],
              [2.783984375000017, 7.443408203124989],
              [2.78515625, 7.476855468749989],
              [2.7509765625, 7.541894531249994],
              [2.719335937500006, 7.616259765624989],
              [2.720410156250011, 7.723095703124997],
              [2.707714843750011, 7.826611328124997],
              [2.68603515625, 7.873730468749997],
              [2.702343750000011, 8.0498046875],
              [2.711523437500006, 8.272998046874989],
              [2.703125, 8.371826171875],
              [2.7236328125, 8.44189453125],
              [2.734667968750017, 8.614013671875],
              [2.73291015625, 8.782519531249989],
              [2.774804687500023, 9.048535156249997],
              [2.898046875, 9.061376953124991],
              [3.044921875, 9.083837890624991],
              [3.110449218750006, 9.188281249999989],
              [3.148046875, 9.320605468749989],
              [3.136132812500023, 9.451611328124997],
              [3.164648437500006, 9.494677734374989],
              [3.223437500000017, 9.565625],
              [3.329492187500023, 9.667041015624989],
              [3.3251953125, 9.778466796874994],
              [3.3544921875, 9.812792968749989],
              [3.40478515625, 9.838623046875],
              [3.476757812500011, 9.851904296874991],
              [3.557226562500006, 9.907324218749991],
              [3.60205078125, 10.004541015624994],
              [3.645898437500023, 10.16015625],
              [3.576562500000023, 10.268359374999989],
              [3.577929687500017, 10.29248046875],
              [3.604101562500006, 10.350683593749991],
              [3.646582031250006, 10.408984374999989],
              [3.680273437500006, 10.427783203124989],
              [3.758496093750011, 10.412695312499991],
              [3.771777343750017, 10.417626953124994],
              [3.783789062500006, 10.435888671874991],
              [3.83447265625, 10.607421875],
              [3.8296875, 10.653759765624997],
              [3.7568359375, 10.76875],
              [3.744921875000017, 10.850439453124991],
              [3.734179687500017, 10.971923828125],
              [3.71640625, 11.07958984375],
              [3.6953125, 11.1203125],
              [3.65625, 11.154589843749989],
              [3.638867187500011, 11.176855468749991],
              [3.48779296875, 11.395410156249994],
              [3.490527343750017, 11.49921875],
              [3.553906250000011, 11.631884765624989],
              [3.595410156250011, 11.6962890625],
              [3.653125, 11.731835937499994],
              [3.664746093750011, 11.762451171875],
              [3.647363281250023, 11.799658203124991],
              [3.618457031250017, 11.827734375],
              [3.61181640625, 11.887304687499991],
              [3.6201171875, 11.926953125],
              [3.640625, 11.970361328124994],
              [3.632519531250011, 12.061572265624989],
              [3.634179687500023, 12.201611328124997],
              [3.643847656250017, 12.4052734375],
              [3.646679687500011, 12.529980468749997],
              [3.769238281250011, 12.622167968749991],
              [3.947851562500006, 12.775048828124994],
              [4.038769531250011, 12.934667968749991],
              [4.08740234375, 13.055468749999989],
              [4.147558593750006, 13.457714843749997],
              [4.190820312500023, 13.482128906249997],
              [4.2421875, 13.501074218749991],
              [4.42138671875, 13.647509765625003],
              [4.559472656250023, 13.701806640624994],
              [4.664843750000017, 13.733203125],
              [4.823339843750006, 13.759765625],
              [4.921679687500017, 13.749121093749991],
              [5.100878906250017, 13.742724609375003],
              [5.241894531250011, 13.757226562499994],
              [5.361621093750017, 13.836865234374997],
              [5.415820312500017, 13.859179687500003],
              [5.491992187500017, 13.872851562500003],
              [5.838183593750017, 13.765380859375],
              [6.184277343750011, 13.663671875],
              [6.247167968750006, 13.672998046874994],
              [6.2998046875, 13.658789062499991],
              [6.386328125, 13.603613281249991],
              [6.514062500000023, 13.485400390625003],
              [6.589941406250006, 13.409130859374997],
              [6.6265625, 13.3642578125],
              [6.804296875, 13.107666015625],
              [6.87060546875, 13.043261718749989],
              [6.937207031250011, 13.008203125],
              [7.005078125000011, 12.995556640624997],
              [7.056738281250006, 13.000195312499997],
              [7.106054687500006, 13.029101562499989],
              [7.173046875000011, 13.086328125],
              [7.274707031250017, 13.112255859374997],
              [7.357812500000023, 13.107177734375],
              [7.788671875, 13.337890625],
              [7.830468750000023, 13.340917968749991],
              [7.955761718750011, 13.32275390625],
              [8.095019531250017, 13.291162109374994],
              [8.4560546875, 13.059667968749991],
              [8.750585937500006, 12.908154296874997],
              [8.957617187500006, 12.857470703124989],
              [9.201562500000023, 12.821484375],
              [9.615917968750011, 12.810644531249991],
              [9.929296875, 13.13525390625],
              [10.045117187500011, 13.206152343749991],
              [10.184667968750006, 13.270117187499991],
              [10.229589843750006, 13.281005859375],
              [10.475878906250017, 13.330224609374994],
              [10.958886718750023, 13.371533203124997],
              [11.411914062500017, 13.353613281249991],
              [11.501074218750006, 13.340527343749997],
              [11.693359375, 13.297705078124991],
              [11.990039062500017, 13.191796875],
              [12.117968750000017, 13.090429687499991],
              [12.319042968750011, 13.073681640624997],
              [12.463183593750017, 13.09375],
              [12.510156250000023, 13.1943359375],
              [12.65478515625, 13.3265625],
              [12.759960937500011, 13.38037109375],
              [12.871679687500006, 13.449023437500003],
              [13.0484375, 13.534521484374991],
              [13.19384765625, 13.573046875],
              [13.323828125, 13.670849609374997],
              [13.426953125000011, 13.701757812499991],
              [13.606347656250023, 13.70458984375],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 5,
        sovereignt: "Benin",
        sov_a3: "BEN",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Benin",
        adm0_a3: "BEN",
        geou_dif: 0,
        geounit: "Benin",
        gu_a3: "BEN",
        su_dif: 0,
        subunit: "Benin",
        su_a3: "BEN",
        brk_diff: 0,
        name: "Benin",
        name_long: "Benin",
        brk_a3: "BEN",
        brk_name: "Benin",
        brk_group: null,
        abbrev: "Benin",
        postal: "BJ",
        formal_en: "Republic of Benin",
        formal_fr: null,
        name_ciawf: "Benin",
        note_adm0: null,
        note_brk: null,
        name_sort: "Benin",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 2,
        mapcolor9: 2,
        mapcolor13: 12,
        pop_est: 11801151,
        pop_rank: 14,
        pop_year: 2019,
        gdp_md: 14390,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        fips_10: "BN",
        iso_a2: "BJ",
        iso_a2_eh: "BJ",
        iso_a3: "BEN",
        iso_a3_eh: "BEN",
        iso_n3: "204",
        iso_n3_eh: "204",
        un_a3: "204",
        wb_a2: "BJ",
        wb_a3: "BEN",
        woe_id: 23424764,
        woe_id_eh: 23424764,
        woe_note: "Exact WOE match as country",
        adm0_iso: "BEN",
        adm0_diff: null,
        adm0_tlc: "BEN",
        adm0_a3_us: "BEN",
        adm0_a3_fr: "BEN",
        adm0_a3_ru: "BEN",
        adm0_a3_es: "BEN",
        adm0_a3_cn: "BEN",
        adm0_a3_tw: "BEN",
        adm0_a3_in: "BEN",
        adm0_a3_np: "BEN",
        adm0_a3_pk: "BEN",
        adm0_a3_de: "BEN",
        adm0_a3_gb: "BEN",
        adm0_a3_br: "BEN",
        adm0_a3_il: "BEN",
        adm0_a3_ps: "BEN",
        adm0_a3_sa: "BEN",
        adm0_a3_eg: "BEN",
        adm0_a3_ma: "BEN",
        adm0_a3_pt: "BEN",
        adm0_a3_ar: "BEN",
        adm0_a3_jp: "BEN",
        adm0_a3_ko: "BEN",
        adm0_a3_vn: "BEN",
        adm0_a3_tr: "BEN",
        adm0_a3_id: "BEN",
        adm0_a3_pl: "BEN",
        adm0_a3_gr: "BEN",
        adm0_a3_it: "BEN",
        adm0_a3_nl: "BEN",
        adm0_a3_se: "BEN",
        adm0_a3_bd: "BEN",
        adm0_a3_ua: "BEN",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Western Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 5,
        long_len: 5,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: 2.352018,
        label_y: 10.324775,
        ne_id: 1159320399,
        wikidataid: "Q962",
        name_ar: "بنين",
        name_bn: "বেনিন",
        name_de: "Benin",
        name_en: "Benin",
        name_es: "Benín",
        name_fa: "بنین",
        name_fr: "Bénin",
        name_el: "Μπενίν",
        name_he: "בנין",
        name_hi: "बेनिन",
        name_hu: "Benin",
        name_id: "Benin",
        name_it: "Benin",
        name_ja: "ベナン",
        name_ko: "베냉",
        name_nl: "Benin",
        name_pl: "Benin",
        name_pt: "Benim",
        name_ru: "Бенин",
        name_sv: "Benin",
        name_tr: "Benin",
        name_uk: "Бенін",
        name_ur: "بینن",
        name_vi: "Bénin",
        name_zh: "贝宁",
        name_zht: "貝南",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "BEN.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [1.62265625, 6.216796875],
            [1.6109375, 6.250830078124991],
            [1.777929687500006, 6.294628906249997],
            [1.7431640625, 6.42626953125],
            [1.639257812500006, 6.58154296875],
            [1.598535156250023, 6.610205078124991],
            [1.577539062500023, 6.687402343749994],
            [1.602929687500023, 6.738085937499989],
            [1.5908203125, 6.772265624999989],
            [1.58203125, 6.877001953124989],
            [1.530957031250011, 6.992431640625],
            [1.624707031250011, 6.997314453125],
            [1.624707031250011, 7.369189453124989],
            [1.624609375, 7.725878906249989],
            [1.624609375, 8.030224609374997],
            [1.624609375, 8.27099609375],
            [1.606640625000011, 8.559277343749997],
            [1.603808593750017, 8.77099609375],
            [1.600195312500006, 9.050048828125],
            [1.566308593750023, 9.137255859374989],
            [1.42431640625, 9.285009765624991],
            [1.3857421875, 9.361669921874991],
            [1.37890625, 9.462988281249991],
            [1.347070312500023, 9.567529296874994],
            [1.345117187500023, 9.750195312499997],
            [1.342871093750006, 9.962939453124989],
            [1.330078125, 9.996972656249994],
            [1.176171875000023, 10.098388671875],
            [0.958300781250017, 10.242041015624991],
            [0.792187500000011, 10.3515625],
            [0.779980468750011, 10.359570312499997],
            [0.763378906250011, 10.386669921874997],
            [0.7875, 10.710253906249989],
            [0.821875, 10.752587890624994],
            [0.874804687500017, 10.8857421875],
            [0.900488281250006, 10.993261718749991],
            [0.924609375000017, 10.992822265624994],
            [0.9580078125, 11.027783203124997],
            [0.985058593750011, 11.079003906249994],
            [1.013867187500011, 11.068115234375],
            [1.062304687500017, 11.058203125],
            [1.084570312500006, 11.076367187499997],
            [1.08154296875, 11.116015624999989],
            [1.097558593750023, 11.156347656249991],
            [1.135546875000017, 11.174365234374989],
            [1.1455078125, 11.210400390624997],
            [1.145800781250017, 11.251904296874997],
            [1.1787109375, 11.262744140624989],
            [1.234667968750017, 11.261035156249989],
            [1.280468750000011, 11.273974609374989],
            [1.3173828125, 11.295263671874991],
            [1.36484375, 11.37890625],
            [1.391503906250023, 11.408007812499989],
            [1.399707031250017, 11.4287109375],
            [1.4267578125, 11.447119140624991],
            [1.501367187500023, 11.45556640625],
            [1.561425781250023, 11.449121093749994],
            [1.6, 11.400634765625],
            [1.857617187500011, 11.443359375],
            [1.980371093750023, 11.418408203124997],
            [2.230859375000023, 11.629150390625],
            [2.287207031250006, 11.691259765624991],
            [2.36328125, 11.840087890625],
            [2.38916015625, 11.897070312499991],
            [2.412695312500006, 11.999316406249989],
            [2.36328125, 12.188427734374997],
            [2.366015625000017, 12.221923828125],
            [2.469335937500006, 12.262792968749991],
            [2.598437500000017, 12.294335937499994],
            [2.6484375, 12.296777343749994],
            [2.681347656250011, 12.312792968749989],
            [2.728515625, 12.353613281249991],
            [2.805273437500006, 12.383837890624989],
            [2.850195312500006, 12.373681640624994],
            [2.878125, 12.367724609374989],
            [3.149609375000011, 12.118066406249994],
            [3.267382812500017, 11.991894531249997],
            [3.299121093750017, 11.927148437499994],
            [3.359960937500006, 11.88046875],
            [3.449804687500006, 11.851953125],
            [3.53173828125, 11.787451171874991],
            [3.595410156250011, 11.6962890625],
            [3.553906250000011, 11.631884765624989],
            [3.490527343750017, 11.49921875],
            [3.48779296875, 11.395410156249994],
            [3.638867187500011, 11.176855468749991],
            [3.65625, 11.154589843749989],
            [3.6953125, 11.1203125],
            [3.71640625, 11.07958984375],
            [3.734179687500017, 10.971923828125],
            [3.744921875000017, 10.850439453124991],
            [3.7568359375, 10.76875],
            [3.8296875, 10.653759765624997],
            [3.83447265625, 10.607421875],
            [3.783789062500006, 10.435888671874991],
            [3.771777343750017, 10.417626953124994],
            [3.758496093750011, 10.412695312499991],
            [3.680273437500006, 10.427783203124989],
            [3.646582031250006, 10.408984374999989],
            [3.604101562500006, 10.350683593749991],
            [3.577929687500017, 10.29248046875],
            [3.576562500000023, 10.268359374999989],
            [3.645898437500023, 10.16015625],
            [3.60205078125, 10.004541015624994],
            [3.557226562500006, 9.907324218749991],
            [3.476757812500011, 9.851904296874991],
            [3.40478515625, 9.838623046875],
            [3.3544921875, 9.812792968749989],
            [3.3251953125, 9.778466796874994],
            [3.329492187500023, 9.667041015624989],
            [3.223437500000017, 9.565625],
            [3.164648437500006, 9.494677734374989],
            [3.136132812500023, 9.451611328124997],
            [3.148046875, 9.320605468749989],
            [3.110449218750006, 9.188281249999989],
            [3.044921875, 9.083837890624991],
            [2.898046875, 9.061376953124991],
            [2.774804687500023, 9.048535156249997],
            [2.73291015625, 8.782519531249989],
            [2.734667968750017, 8.614013671875],
            [2.7236328125, 8.44189453125],
            [2.703125, 8.371826171875],
            [2.711523437500006, 8.272998046874989],
            [2.702343750000011, 8.0498046875],
            [2.68603515625, 7.873730468749997],
            [2.707714843750011, 7.826611328124997],
            [2.720410156250011, 7.723095703124997],
            [2.719335937500006, 7.616259765624989],
            [2.7509765625, 7.541894531249994],
            [2.78515625, 7.476855468749989],
            [2.783984375000017, 7.443408203124989],
            [2.765820312500011, 7.422509765624994],
            [2.75048828125, 7.395068359374989],
            [2.750585937500006, 7.143212890624994],
            [2.756738281250023, 7.067919921874989],
            [2.747753906250011, 7.019824218749989],
            [2.721386718750011, 6.980273437499989],
            [2.731738281250017, 6.852832031249989],
            [2.7529296875, 6.771630859374994],
            [2.774609375000011, 6.711718749999989],
            [2.753710937500017, 6.661767578124994],
            [2.735644531250017, 6.595703125],
            [2.7080078125, 6.427685546874997],
            [2.706445312500023, 6.369238281249991],
            [2.286914062500017, 6.328076171874997],
            [1.818164062500017, 6.260644531249994],
            [1.62265625, 6.216796875],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Angola",
        sov_a3: "AGO",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Angola",
        adm0_a3: "AGO",
        geou_dif: 0,
        geounit: "Angola",
        gu_a3: "AGO",
        su_dif: 0,
        subunit: "Angola",
        su_a3: "AGO",
        brk_diff: 0,
        name: "Angola",
        name_long: "Angola",
        brk_a3: "AGO",
        brk_name: "Angola",
        brk_group: null,
        abbrev: "Ang.",
        postal: "AO",
        formal_en: "People's Republic of Angola",
        formal_fr: null,
        name_ciawf: "Angola",
        note_adm0: null,
        note_brk: null,
        name_sort: "Angola",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 2,
        mapcolor9: 6,
        mapcolor13: 1,
        pop_est: 31825295,
        pop_rank: 15,
        pop_year: 2019,
        gdp_md: 88815,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "3. Upper middle income",
        fips_10: "AO",
        iso_a2: "AO",
        iso_a2_eh: "AO",
        iso_a3: "AGO",
        iso_a3_eh: "AGO",
        iso_n3: "024",
        iso_n3_eh: "024",
        un_a3: "024",
        wb_a2: "AO",
        wb_a3: "AGO",
        woe_id: 23424745,
        woe_id_eh: 23424745,
        woe_note: "Exact WOE match as country",
        adm0_iso: "AGO",
        adm0_diff: null,
        adm0_tlc: "AGO",
        adm0_a3_us: "AGO",
        adm0_a3_fr: "AGO",
        adm0_a3_ru: "AGO",
        adm0_a3_es: "AGO",
        adm0_a3_cn: "AGO",
        adm0_a3_tw: "AGO",
        adm0_a3_in: "AGO",
        adm0_a3_np: "AGO",
        adm0_a3_pk: "AGO",
        adm0_a3_de: "AGO",
        adm0_a3_gb: "AGO",
        adm0_a3_br: "AGO",
        adm0_a3_il: "AGO",
        adm0_a3_ps: "AGO",
        adm0_a3_sa: "AGO",
        adm0_a3_eg: "AGO",
        adm0_a3_ma: "AGO",
        adm0_a3_pt: "AGO",
        adm0_a3_ar: "AGO",
        adm0_a3_jp: "AGO",
        adm0_a3_ko: "AGO",
        adm0_a3_vn: "AGO",
        adm0_a3_tr: "AGO",
        adm0_a3_id: "AGO",
        adm0_a3_pl: "AGO",
        adm0_a3_gr: "AGO",
        adm0_a3_it: "AGO",
        adm0_a3_nl: "AGO",
        adm0_a3_se: "AGO",
        adm0_a3_bd: "AGO",
        adm0_a3_ua: "AGO",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Middle Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 6,
        long_len: 6,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 7,
        label_x: 17.984249,
        label_y: -12.182762,
        ne_id: 1159320323,
        wikidataid: "Q916",
        name_ar: "أنغولا",
        name_bn: "অ্যাঙ্গোলা",
        name_de: "Angola",
        name_en: "Angola",
        name_es: "Angola",
        name_fa: "آنگولا",
        name_fr: "Angola",
        name_el: "Ανγκόλα",
        name_he: "אנגולה",
        name_hi: "अंगोला",
        name_hu: "Angola",
        name_id: "Angola",
        name_it: "Angola",
        name_ja: "アンゴラ",
        name_ko: "앙골라",
        name_nl: "Angola",
        name_pl: "Angola",
        name_pt: "Angola",
        name_ru: "Ангола",
        name_sv: "Angola",
        name_tr: "Angola",
        name_uk: "Ангола",
        name_ur: "انگولا",
        name_vi: "Angola",
        name_zh: "安哥拉",
        name_zht: "安哥拉",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "AGO.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [13.07275390625, -4.634765625],
              [13.057324218750011, -4.651074218750011],
              [12.947460937500011, -4.6953125],
              [12.8296875, -4.736621093750003],
              [12.6748046875, -4.905371093750006],
              [12.59619140625, -4.978417968750009],
              [12.573535156250017, -4.99658203125],
              [12.502734375000017, -5.036914062500003],
              [12.451464843750017, -5.071484375000011],
              [12.453222656250006, -5.090625],
              [12.487402343750006, -5.112695312500009],
              [12.522363281250023, -5.14892578125],
              [12.518945312500023, -5.424609375],
              [12.503710937500017, -5.69580078125],
              [12.484570312500011, -5.71875],
              [12.386035156250017, -5.727734375000011],
              [12.255273437500023, -5.746484375],
              [12.213671875000017, -5.758691406250009],
              [12.199023437500017, -5.73193359375],
              [12.155468750000011, -5.632714843750009],
              [12.180078125000023, -5.538671875],
              [12.20654296875, -5.46826171875],
              [12.177148437500023, -5.324804687500006],
              [12.110546875000011, -5.197167968750009],
              [12.039941406250023, -5.03515625],
              [12.018359375000017, -5.004296875],
              [12.077539062500023, -4.9521484375],
              [12.167089843750006, -4.837695312500003],
              [12.204296875000011, -4.778613281250003],
              [12.307910156250017, -4.765527343750009],
              [12.3466796875, -4.72412109375],
              [12.3740234375, -4.65771484375],
              [12.384570312500017, -4.619140625],
              [12.50146484375, -4.5875],
              [12.641699218750006, -4.531152343750009],
              [12.719433593750011, -4.4697265625],
              [12.798242187500023, -4.430566406250009],
              [12.84814453125, -4.428906250000011],
              [12.881054687500011, -4.445117187500003],
              [12.971386718750011, -4.5517578125],
              [13.048046875000011, -4.619238281250006],
              [13.07275390625, -4.634765625],
            ],
          ],
          [
            [
              [23.96650390625001, -10.871777343750011],
              [23.98828125, -11.002832031250009],
              [24.010058593750017, -11.184765625000011],
              [24.02558593750001, -11.315625],
              [24.041406250000023, -11.374121093750006],
              [24.046679687500017, -11.405371093750006],
              [24.029296875, -11.439160156250011],
              [24.0146484375, -11.517675781250006],
              [23.98681640625, -11.587207031250003],
              [23.970996093750017, -11.635839843750006],
              [23.98388671875, -11.725],
              [23.973437500000017, -11.852929687500009],
              [23.962304687500023, -11.987890625],
              [23.958886718750023, -12.117773437500006],
              [23.996484375000023, -12.350683593750006],
              [23.991308593750006, -12.422167968750003],
              [23.944726562500023, -12.54375],
              [23.909375, -12.636132812500009],
              [23.886523437500017, -12.743261718750006],
              [23.882421875, -12.799023437500011],
              [23.968066406250017, -12.956933593750009],
              [23.962988281250006, -12.988476562500011],
              [23.8974609375, -12.998242187500011],
              [23.843164062500023, -13.0009765625],
              [23.635839843750006, -13.0009765625],
              [23.338671875000017, -13.0009765625],
              [23.04150390625, -13.0009765625],
              [22.74433593750001, -13.0009765625],
              [22.470996093750017, -13.0009765625],
              [22.209570312500006, -13.0009765625],
              [21.978906250000023, -13.0009765625],
              [21.97900390625, -13.156835937500006],
              [21.979101562500006, -13.477734375000011],
              [21.979101562500006, -13.798730468750009],
              [21.979296875000017, -14.11962890625],
              [21.979394531250023, -14.440527343750006],
              [21.9794921875, -14.761425781250011],
              [21.979589843750006, -15.082324218750003],
              [21.97968750000001, -15.403222656250009],
              [21.979785156250017, -15.72412109375],
              [21.979785156250017, -15.95556640625],
              [22.04023437500001, -16.262792968750006],
              [22.150683593750017, -16.59716796875],
              [22.193945312500006, -16.628125],
              [22.305078125000023, -16.689550781250006],
              [22.45947265625, -16.81513671875001],
              [22.545996093750006, -16.910253906250006],
              [22.721972656250017, -17.075292968750006],
              [22.955859375000017, -17.285742187500006],
              [23.181640625, -17.474414062500003],
              [23.380664062500017, -17.640625],
              [23.068261718750023, -17.698828125],
              [22.6240234375, -17.781640625],
              [22.32421875, -17.8375],
              [21.960839843750023, -17.90517578125001],
              [21.71845703125001, -17.94775390625],
              [21.416894531250023, -18.00068359375001],
              [21.36875, -17.99951171875],
              [21.287890625000017, -17.962988281250006],
              [21.11347656250001, -17.95576171875001],
              [20.908300781250006, -18.00605468750001],
              [20.745507812500023, -18.01972656250001],
              [20.625097656250006, -17.996679687500006],
              [20.507617187500017, -17.95253906250001],
              [20.392968750000023, -17.88740234375001],
              [20.1943359375, -17.863671875],
              [19.91181640625001, -17.88134765625],
              [19.63935546875001, -17.86865234375],
              [19.37714843750001, -17.825488281250003],
              [19.189453125, -17.80849609375001],
              [19.076464843750017, -17.817675781250003],
              [18.95527343750001, -17.803515625],
              [18.825976562500017, -17.76630859375001],
              [18.718066406250017, -17.703222656250006],
              [18.588183593750017, -17.57001953125001],
              [18.486621093750017, -17.44277343750001],
              [18.460351562500023, -17.424609375],
              [18.42822265625, -17.40517578125001],
              [18.396386718750023, -17.3994140625],
              [18.108789062500023, -17.39599609375],
              [17.835351562500023, -17.39277343750001],
              [17.678808593750006, -17.392578125],
              [17.296289062500023, -17.39199218750001],
              [16.913671875, -17.39140625],
              [16.531054687500017, -17.39082031250001],
              [16.1484375, -17.390234375],
              [15.765820312500011, -17.3896484375],
              [15.383203125000023, -17.38916015625],
              [15.000585937500006, -17.38857421875001],
              [14.617968750000017, -17.387988281250003],
              [14.414746093750011, -17.3876953125],
              [14.225878906250017, -17.397753906250003],
              [14.017480468750023, -17.40888671875001],
              [13.987402343750006, -17.40419921875001],
              [13.93798828125, -17.388769531250006],
              [13.904199218750023, -17.36074218750001],
              [13.7919921875, -17.288378906250003],
              [13.6943359375, -17.233496093750006],
              [13.561718750000011, -17.141210937500006],
              [13.475976562500023, -17.0400390625],
              [13.403710937500023, -17.0078125],
              [13.275683593750017, -16.989550781250003],
              [13.179492187500017, -16.9716796875],
              [13.101171875, -16.96767578125001],
              [12.963183593750017, -17.015429687500003],
              [12.859277343750023, -17.062597656250006],
              [12.78515625, -17.108203125],
              [12.656542968750017, -17.160546875],
              [12.548144531250017, -17.212695312500003],
              [12.359277343750023, -17.205859375],
              [12.318457031250006, -17.21337890625],
              [12.21337890625, -17.2099609375],
              [12.114355468750006, -17.16455078125],
              [12.013964843750017, -17.168554687500006],
              [11.902539062500011, -17.2265625],
              [11.743066406250023, -17.24921875000001],
              [11.780078125000017, -16.87128906250001],
              [11.818945312500006, -16.7041015625],
              [11.819921875, -16.504296875],
              [11.796972656250006, -15.986425781250006],
              [11.769433593750023, -15.915332031250003],
              [11.750878906250023, -15.831933593750009],
              [11.849707031250006, -15.768359375],
              [11.89990234375, -15.719824218750006],
              [11.967871093750006, -15.633984375000011],
              [12.01611328125, -15.513671875],
              [12.0732421875, -15.248242187500011],
              [12.280468750000011, -14.6375],
              [12.37890625, -14.0390625],
              [12.503710937500017, -13.75546875],
              [12.550488281250011, -13.437792968750003],
              [12.897656250000011, -13.027734375],
              [12.983203125000017, -12.775683593750003],
              [13.162695312500006, -12.652148437500003],
              [13.4169921875, -12.520410156250009],
              [13.597949218750017, -12.2861328125],
              [13.685546875, -12.123828125],
              [13.785351562500011, -11.812792968750003],
              [13.784277343750006, -11.487988281250011],
              [13.847460937500017, -11.054394531250011],
              [13.83359375, -10.9296875],
              [13.738964843750011, -10.757128906250003],
              [13.721386718750011, -10.63359375],
              [13.633496093750011, -10.512304687500006],
              [13.539453125000023, -10.420703125],
              [13.495410156250017, -10.257128906250003],
              [13.332226562500011, -9.998925781250009],
              [13.2875, -9.826757812500006],
              [13.209375, -9.703222656250006],
              [13.196875, -9.550683593750009],
              [13.155664062500023, -9.3896484375],
              [13.075976562500017, -9.230371093750009],
              [12.99853515625, -9.048046875000011],
              [12.99853515625, -8.991015625],
              [13.046777343750023, -8.922265625],
              [13.0927734375, -8.899707031250003],
              [13.077246093750006, -8.934277343750011],
              [13.046582031250011, -8.975195312500006],
              [13.053808593750006, -9.0068359375],
              [13.358984375, -8.687207031250011],
              [13.378320312500023, -8.624707031250011],
              [13.368066406250023, -8.554785156250006],
              [13.366406250000011, -8.46923828125],
              [13.378515625, -8.369726562500006],
              [13.0908203125, -7.780175781250009],
              [12.8623046875, -7.231835937500009],
              [12.823437500000011, -6.954785156250011],
              [12.521289062500017, -6.59033203125],
              [12.402148437500017, -6.353417968750009],
              [12.334277343750017, -6.187304687500003],
              [12.283300781250006, -6.124316406250003],
              [12.302539062500017, -6.092578125],
              [12.38037109375, -6.084277343750003],
              [12.553515625000017, -6.0458984375],
              [12.790625, -6.00390625],
              [13.009765625, -5.907617187500009],
              [13.068164062500017, -5.86484375],
              [13.184375, -5.85625],
              [13.302636718750023, -5.8818359375],
              [13.346484375000017, -5.863378906250006],
              [13.371484375000023, -5.86181640625],
              [13.649023437500006, -5.86171875],
              [13.764550781250023, -5.855175781250011],
              [13.978515625, -5.857226562500003],
              [14.11376953125, -5.865136718750009],
              [14.190820312500023, -5.8759765625],
              [14.398632812500011, -5.892675781250006],
              [14.657910156250011, -5.888867187500011],
              [14.749414062500023, -5.880078125000011],
              [15.08935546875, -5.87451171875],
              [15.425, -5.868847656250011],
              [15.726953125000023, -5.863867187500006],
              [16.06015625, -5.864941406250011],
              [16.315234375000017, -5.865625],
              [16.431445312500017, -5.900195312500003],
              [16.537109375, -5.9658203125],
              [16.58515625000001, -6.025292968750009],
              [16.608007812500006, -6.0515625],
              [16.639550781250023, -6.114550781250003],
              [16.697265625, -6.164257812500011],
              [16.7177734375, -6.241406250000011],
              [16.700976562500017, -6.345996093750003],
              [16.709375, -6.4716796875],
              [16.742968750000017, -6.618457031250003],
              [16.813085937500006, -6.772558593750006],
              [16.91943359375, -6.933984375],
              [16.9658203125, -7.062109375],
              [16.952050781250023, -7.15703125],
              [16.984765625000023, -7.257421875],
              [17.063769531250017, -7.363085937500003],
              [17.12158203125, -7.419042968750006],
              [17.155078125000017, -7.461328125],
              [17.245019531250023, -7.623339843750003],
              [17.41132812500001, -7.881933593750006],
              [17.536035156250023, -8.075878906250011],
              [17.57958984375, -8.099023437500009],
              [17.643359375000017, -8.090722656250009],
              [17.77880859375, -8.071386718750006],
              [17.9130859375, -8.067675781250003],
              [18.0087890625, -8.107617187500011],
              [18.047167968750017, -8.100781250000011],
              [18.191503906250006, -8.023828125],
              [18.334863281250023, -8.000292968750003],
              [18.484667968750017, -7.968554687500003],
              [18.56269531250001, -7.9359375],
              [18.653417968750006, -7.93603515625],
              [18.898339843750023, -7.998144531250006],
              [18.944433593750006, -8.00146484375],
              [19.142675781250006, -8.00146484375],
              [19.3408203125, -7.966601562500003],
              [19.369921875000017, -7.70654296875],
              [19.371679687500006, -7.655078125],
              [19.419335937500023, -7.557324218750011],
              [19.479882812500023, -7.47216796875],
              [19.487402343750006, -7.390722656250006],
              [19.483789062500023, -7.279492187500011],
              [19.527636718750017, -7.144433593750009],
              [19.66035156250001, -7.037109375],
              [19.87519531250001, -6.986328125],
              [19.997460937500023, -6.976464843750009],
              [20.190039062500006, -6.9462890625],
              [20.482226562500017, -6.915820312500003],
              [20.59003906250001, -6.919921875],
              [20.598730468750006, -6.93515625],
              [20.536914062500017, -7.121777343750011],
              [20.53583984375001, -7.182812500000011],
              [20.558398437500017, -7.244433593750003],
              [20.607812500000023, -7.277734375],
              [20.910937500000017, -7.281445312500011],
              [21.190332031250023, -7.284960937500003],
              [21.510839843750006, -7.296679687500003],
              [21.751074218750006, -7.30546875],
              [21.781640625000023, -7.314648437500011],
              [21.806054687500023, -7.32861328125],
              [21.841601562500017, -7.420996093750006],
              [21.83359375, -7.601660156250006],
              [21.780078125000017, -7.865429687500011],
              [21.800878906250006, -8.111914062500006],
              [21.895898437500023, -8.341113281250003],
              [21.905371093750006, -8.693359375],
              [21.871875, -8.903515625000011],
              [21.829492187500023, -9.16845703125],
              [21.81318359375001, -9.46875],
              [21.85664062500001, -9.59423828125],
              [21.948632812500023, -9.7255859375],
              [22.089160156250017, -9.86279296875],
              [22.19775390625, -10.040625],
              [22.274511718750006, -10.259082031250003],
              [22.30244140625001, -10.396679687500011],
              [22.281640625000023, -10.453320312500011],
              [22.283203125, -10.5515625],
              [22.307031250000023, -10.691308593750009],
              [22.28046875000001, -10.783984375],
              [22.203515625000023, -10.829492187500009],
              [22.17792968750001, -10.892285156250011],
              [22.216699218750023, -11.0126953125],
              [22.226171875, -11.121972656250009],
              [22.256640625000017, -11.163671875],
              [22.27880859375, -11.194140625],
              [22.31494140625, -11.198632812500009],
              [22.392968750000023, -11.159472656250003],
              [22.486132812500017, -11.08671875],
              [22.56103515625, -11.055859375000011],
              [22.66650390625, -11.059765625000011],
              [22.814746093750017, -11.080273437500011],
              [23.076269531250006, -11.087890625],
              [23.15673828125, -11.074804687500006],
              [23.400195312500017, -10.976464843750009],
              [23.463964843750006, -10.969335937500006],
              [23.559960937500023, -10.978613281250006],
              [23.696386718750006, -11.007617187500003],
              [23.833886718750023, -11.013671875],
              [23.901171875000017, -10.983203125],
              [23.907324218750006, -10.943457031250006],
              [23.9287109375, -10.891503906250009],
              [23.96650390625001, -10.871777343750011],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 4,
        sovereignt: "Botswana",
        sov_a3: "BWA",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Botswana",
        adm0_a3: "BWA",
        geou_dif: 0,
        geounit: "Botswana",
        gu_a3: "BWA",
        su_dif: 0,
        subunit: "Botswana",
        su_a3: "BWA",
        brk_diff: 0,
        name: "Botswana",
        name_long: "Botswana",
        brk_a3: "BWA",
        brk_name: "Botswana",
        brk_group: null,
        abbrev: "Bwa.",
        postal: "BW",
        formal_en: "Republic of Botswana",
        formal_fr: null,
        name_ciawf: "Botswana",
        note_adm0: null,
        note_brk: null,
        name_sort: "Botswana",
        name_alt: null,
        mapcolor7: 6,
        mapcolor8: 5,
        mapcolor9: 7,
        mapcolor13: 3,
        pop_est: 2303697,
        pop_rank: 12,
        pop_year: 2019,
        gdp_md: 18340,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        fips_10: "BC",
        iso_a2: "BW",
        iso_a2_eh: "BW",
        iso_a3: "BWA",
        iso_a3_eh: "BWA",
        iso_n3: "072",
        iso_n3_eh: "072",
        un_a3: "072",
        wb_a2: "BW",
        wb_a3: "BWA",
        woe_id: 23424755,
        woe_id_eh: 23424755,
        woe_note: "Exact WOE match as country",
        adm0_iso: "BWA",
        adm0_diff: null,
        adm0_tlc: "BWA",
        adm0_a3_us: "BWA",
        adm0_a3_fr: "BWA",
        adm0_a3_ru: "BWA",
        adm0_a3_es: "BWA",
        adm0_a3_cn: "BWA",
        adm0_a3_tw: "BWA",
        adm0_a3_in: "BWA",
        adm0_a3_np: "BWA",
        adm0_a3_pk: "BWA",
        adm0_a3_de: "BWA",
        adm0_a3_gb: "BWA",
        adm0_a3_br: "BWA",
        adm0_a3_il: "BWA",
        adm0_a3_ps: "BWA",
        adm0_a3_sa: "BWA",
        adm0_a3_eg: "BWA",
        adm0_a3_ma: "BWA",
        adm0_a3_pt: "BWA",
        adm0_a3_ar: "BWA",
        adm0_a3_jp: "BWA",
        adm0_a3_ko: "BWA",
        adm0_a3_vn: "BWA",
        adm0_a3_tr: "BWA",
        adm0_a3_id: "BWA",
        adm0_a3_pl: "BWA",
        adm0_a3_gr: "BWA",
        adm0_a3_it: "BWA",
        adm0_a3_nl: "BWA",
        adm0_a3_se: "BWA",
        adm0_a3_bd: "BWA",
        adm0_a3_ua: "BWA",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Southern Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 8,
        long_len: 8,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: 24.179216,
        label_y: -22.102634,
        ne_id: 1159320461,
        wikidataid: "Q963",
        name_ar: "بوتسوانا",
        name_bn: "বতসোয়ানা",
        name_de: "Botswana",
        name_en: "Botswana",
        name_es: "Botsuana",
        name_fa: "بوتسوانا",
        name_fr: "Botswana",
        name_el: "Μποτσουάνα",
        name_he: "בוטסואנה",
        name_hi: "बोत्सवाना",
        name_hu: "Botswana",
        name_id: "Botswana",
        name_it: "Botswana",
        name_ja: "ボツワナ",
        name_ko: "보츠와나",
        name_nl: "Botswana",
        name_pl: "Botswana",
        name_pt: "Botsuana",
        name_ru: "Ботсвана",
        name_sv: "Botswana",
        name_tr: "Botsvana",
        name_uk: "Ботсвана",
        name_ur: "بوٹسوانا",
        name_vi: "Botswana",
        name_zh: "博茨瓦纳",
        name_zht: "波札那",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "BWA.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [25.2587890625, -17.793554687500006],
            [25.239062500000017, -17.843066406250003],
            [25.224023437500023, -17.91523437500001],
            [25.242285156250006, -17.969042968750003],
            [25.28242187500001, -18.04121093750001],
            [25.340234375000023, -18.1044921875],
            [25.384375, -18.14199218750001],
            [25.43671875000001, -18.234960937500006],
            [25.4892578125, -18.35126953125001],
            [25.55830078125001, -18.441796875],
            [25.76123046875, -18.64921875],
            [25.78369140625, -18.72353515625001],
            [25.811914062500023, -18.79707031250001],
            [25.939355468750023, -18.93867187500001],
            [25.95917968750001, -18.985644531250003],
            [25.95068359375, -19.08173828125001],
            [26.081933593750023, -19.369921875],
            [26.168066406250006, -19.53828125000001],
            [26.241015625000017, -19.5693359375],
            [26.474609375, -19.748632812500006],
            [26.67822265625, -19.89277343750001],
            [26.91669921875001, -19.99013671875001],
            [27.091796875, -20.05419921875],
            [27.17822265625, -20.10097656250001],
            [27.221484375000017, -20.145800781250003],
            [27.256738281250023, -20.23203125],
            [27.27460937500001, -20.3818359375],
            [27.28076171875, -20.47871093750001],
            [27.46894531250001, -20.47480468750001],
            [27.624609375, -20.48359375000001],
            [27.679296875, -20.503027343750006],
            [27.699609375000023, -20.53066406250001],
            [27.69482421875, -20.59453125],
            [27.69697265625001, -20.689746093750003],
            [27.70429687500001, -20.76640625],
            [27.688085937500006, -20.84833984375001],
            [27.67695312500001, -20.94482421875],
            [27.66943359375, -21.064257812500003],
            [27.693457031250006, -21.11103515625001],
            [27.844140625000023, -21.261523437500003],
            [27.90742187500001, -21.35908203125001],
            [27.974609375, -21.50673828125001],
            [28.014062500000023, -21.55419921875],
            [28.04560546875001, -21.573046875],
            [28.181640625, -21.58935546875],
            [28.532031250000017, -21.65126953125001],
            [28.74775390625001, -21.707617187500006],
            [28.919335937500023, -21.766015625],
            [28.99072265625, -21.78144531250001],
            [29.02558593750001, -21.796875],
            [29.03730468750001, -21.811328125],
            [29.01582031250001, -21.93994140625],
            [29.023339843750023, -21.98125],
            [29.042382812500023, -22.018359375],
            [29.07148437500001, -22.047460937500006],
            [29.106835937500023, -22.065722656250003],
            [29.237207031250023, -22.07949218750001],
            [29.315234375000017, -22.15771484375],
            [29.36484375, -22.193945312500006],
            [29.1298828125, -22.21328125],
            [29.013476562500017, -22.278417968750006],
            [28.94580078125, -22.395117187500006],
            [28.83984375, -22.480859375],
            [28.69550781250001, -22.535449218750003],
            [28.542871093750023, -22.57294921875001],
            [28.381738281250023, -22.593359375],
            [28.21015625000001, -22.693652343750003],
            [28.027929687500006, -22.87373046875001],
            [27.93505859375, -22.98701171875001],
            [27.93134765625001, -23.03359375],
            [27.890527343750023, -23.07392578125001],
            [27.812597656250006, -23.108007812500006],
            [27.7685546875, -23.14892578125],
            [27.75830078125, -23.19677734375],
            [27.716796875, -23.21962890625001],
            [27.643847656250017, -23.21767578125001],
            [27.592675781250023, -23.25263671875001],
            [27.56318359375001, -23.324609375],
            [27.49873046875001, -23.36835937500001],
            [27.399218750000017, -23.38359375],
            [27.313378906250023, -23.42421875],
            [27.2412109375, -23.490039062500003],
            [27.185546875, -23.5234375],
            [27.146386718750023, -23.5244140625],
            [27.085546875, -23.577929687500003],
            [26.98701171875001, -23.70458984375],
            [26.970605468750023, -23.763476562500003],
            [26.835058593750006, -24.240820312500006],
            [26.761132812500023, -24.297167968750003],
            [26.617773437500006, -24.3955078125],
            [26.5015625, -24.51328125],
            [26.451757812500006, -24.58271484375001],
            [26.39716796875001, -24.613574218750003],
            [26.130859375, -24.671484375],
            [26.031835937500006, -24.702441406250003],
            [25.912109375, -24.74746093750001],
            [25.8818359375, -24.78798828125001],
            [25.852441406250023, -24.93525390625001],
            [25.769921875000023, -25.146484375],
            [25.70263671875, -25.30234375],
            [25.6591796875, -25.437890625],
            [25.583789062500017, -25.60625],
            [25.518164062500006, -25.66279296875001],
            [25.443652343750017, -25.714453125],
            [25.34619140625, -25.73994140625001],
            [25.21337890625, -25.75625],
            [25.09248046875001, -25.75146484375],
            [24.998925781250023, -25.754003906250006],
            [24.869238281250006, -25.8134765625],
            [24.748144531250006, -25.8173828125],
            [24.55585937500001, -25.78310546875001],
            [24.400195312500017, -25.749804687500003],
            [24.33056640625, -25.74287109375001],
            [24.19296875, -25.632910156250006],
            [24.1044921875, -25.634863281250006],
            [23.969531250000017, -25.626074218750006],
            [23.89375, -25.600878906250003],
            [23.82343750000001, -25.54462890625001],
            [23.670703125000017, -25.433984375],
            [23.521484375, -25.34443359375001],
            [23.389257812500006, -25.29140625],
            [23.266015625000023, -25.2666015625],
            [23.148730468750017, -25.288671875],
            [23.057519531250023, -25.312304687500003],
            [23.022070312500006, -25.32412109375001],
            [22.951269531250006, -25.37031250000001],
            [22.878808593750023, -25.45791015625001],
            [22.818945312500006, -25.59511718750001],
            [22.79609375000001, -25.67910156250001],
            [22.72900390625, -25.85732421875001],
            [22.640234375, -26.07119140625001],
            [22.59765625, -26.13271484375001],
            [22.548632812500017, -26.17841796875001],
            [22.47089843750001, -26.219042968750003],
            [22.217578125000017, -26.38886718750001],
            [22.090917968750006, -26.580175781250006],
            [22.01093750000001, -26.635839843750006],
            [21.91455078125, -26.661914062500003],
            [21.83320312500001, -26.678320312500006],
            [21.78828125000001, -26.710058593750006],
            [21.738085937500017, -26.80683593750001],
            [21.694726562500023, -26.840917968750006],
            [21.646289062500017, -26.85419921875001],
            [21.501367187500023, -26.84267578125001],
            [21.454980468750023, -26.8328125],
            [21.07099609375001, -26.85175781250001],
            [20.953906250000017, -26.82109375],
            [20.870898437500017, -26.80878906250001],
            [20.73984375, -26.84882812500001],
            [20.68505859375, -26.82246093750001],
            [20.641406250000017, -26.7421875],
            [20.619921875000017, -26.580859375],
            [20.626757812500017, -26.44384765625],
            [20.697851562500006, -26.340136718750003],
            [20.75703125000001, -26.26416015625],
            [20.815039062500006, -26.16494140625001],
            [20.822656250000023, -26.12060546875],
            [20.81103515625, -26.08056640625],
            [20.799414062500006, -25.9990234375],
            [20.79316406250001, -25.915625],
            [20.710742187500017, -25.733203125],
            [20.609277343750023, -25.4912109375],
            [20.47314453125, -25.221289062500006],
            [20.4306640625, -25.147070312500006],
            [20.34521484375, -25.029882812500006],
            [20.028613281250017, -24.80703125],
            [19.98046875, -24.77675781250001],
            [19.98046875, -24.751953125],
            [19.98017578125001, -24.535742187500006],
            [19.979882812500023, -24.2490234375],
            [19.979589843750006, -23.96240234375],
            [19.979296875000017, -23.67578125],
            [19.978906250000023, -23.38916015625],
            [19.978515625, -23.1025390625],
            [19.97822265625001, -22.81591796875],
            [19.977929687500023, -22.529296875],
            [19.977636718750006, -22.242578125],
            [19.977343750000017, -22.00019531250001],
            [20.205371093750017, -22.00019531250001],
            [20.4875, -22.00019531250001],
            [20.82275390625, -22.00019531250001],
            [20.970996093750017, -22.00019531250001],
            [20.9794921875, -21.9619140625],
            [20.979296875000017, -21.78408203125001],
            [20.97871093750001, -21.376074218750006],
            [20.978125, -20.96816406250001],
            [20.977441406250023, -20.56025390625001],
            [20.976855468750017, -20.15234375],
            [20.976171875, -19.74433593750001],
            [20.9755859375, -19.33642578125],
            [20.975, -18.928515625],
            [20.97431640625001, -18.5205078125],
            [20.97412109375, -18.31884765625],
            [21.232519531250006, -18.30683593750001],
            [21.529687500000023, -18.265625],
            [22.01142578125001, -18.19863281250001],
            [22.460058593750006, -18.11572265625],
            [22.752734375000017, -18.0671875],
            [23.099902343750017, -18.009570312500003],
            [23.219335937500006, -17.99970703125001],
            [23.2515625, -18.00751953125001],
            [23.298632812500017, -18.02734375],
            [23.459765625000017, -18.231054687500006],
            [23.56015625, -18.38642578125001],
            [23.58056640625, -18.452929687500003],
            [23.599707031250006, -18.4599609375],
            [23.64716796875001, -18.44941406250001],
            [23.700488281250017, -18.42431640625],
            [23.8642578125, -18.26953125],
            [23.898339843750023, -18.22919921875001],
            [24.00263671875001, -18.154101562500003],
            [24.129296875000023, -18.07753906250001],
            [24.243945312500017, -18.0234375],
            [24.358984375, -17.97822265625001],
            [24.412207031250006, -17.98945312500001],
            [24.474902343750017, -18.02851562500001],
            [24.530566406250017, -18.052734375],
            [24.79218750000001, -17.86464843750001],
            [24.909082031250023, -17.821386718750006],
            [25.21601562500001, -17.78759765625],
            [25.2587890625, -17.793554687500006],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Zimbabwe",
        sov_a3: "ZWE",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Zimbabwe",
        adm0_a3: "ZWE",
        geou_dif: 0,
        geounit: "Zimbabwe",
        gu_a3: "ZWE",
        su_dif: 0,
        subunit: "Zimbabwe",
        su_a3: "ZWE",
        brk_diff: 0,
        name: "Zimbabwe",
        name_long: "Zimbabwe",
        brk_a3: "ZWE",
        brk_name: "Zimbabwe",
        brk_group: null,
        abbrev: "Zimb.",
        postal: "ZW",
        formal_en: "Republic of Zimbabwe",
        formal_fr: null,
        name_ciawf: "Zimbabwe",
        note_adm0: null,
        note_brk: null,
        name_sort: "Zimbabwe",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 5,
        mapcolor9: 3,
        mapcolor13: 9,
        pop_est: 14645468,
        pop_rank: 14,
        pop_year: 2019,
        gdp_md: 21440,
        gdp_year: 2019,
        economy: "5. Emerging region: G20",
        income_grp: "5. Low income",
        fips_10: "ZI",
        iso_a2: "ZW",
        iso_a2_eh: "ZW",
        iso_a3: "ZWE",
        iso_a3_eh: "ZWE",
        iso_n3: "716",
        iso_n3_eh: "716",
        un_a3: "716",
        wb_a2: "ZW",
        wb_a3: "ZWE",
        woe_id: 23425004,
        woe_id_eh: 23425004,
        woe_note: "Exact WOE match as country",
        adm0_iso: "ZWE",
        adm0_diff: null,
        adm0_tlc: "ZWE",
        adm0_a3_us: "ZWE",
        adm0_a3_fr: "ZWE",
        adm0_a3_ru: "ZWE",
        adm0_a3_es: "ZWE",
        adm0_a3_cn: "ZWE",
        adm0_a3_tw: "ZWE",
        adm0_a3_in: "ZWE",
        adm0_a3_np: "ZWE",
        adm0_a3_pk: "ZWE",
        adm0_a3_de: "ZWE",
        adm0_a3_gb: "ZWE",
        adm0_a3_br: "ZWE",
        adm0_a3_il: "ZWE",
        adm0_a3_ps: "ZWE",
        adm0_a3_sa: "ZWE",
        adm0_a3_eg: "ZWE",
        adm0_a3_ma: "ZWE",
        adm0_a3_pt: "ZWE",
        adm0_a3_ar: "ZWE",
        adm0_a3_jp: "ZWE",
        adm0_a3_ko: "ZWE",
        adm0_a3_vn: "ZWE",
        adm0_a3_tr: "ZWE",
        adm0_a3_id: "ZWE",
        adm0_a3_pl: "ZWE",
        adm0_a3_gr: "ZWE",
        adm0_a3_it: "ZWE",
        adm0_a3_nl: "ZWE",
        adm0_a3_se: "ZWE",
        adm0_a3_bd: "ZWE",
        adm0_a3_ua: "ZWE",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Eastern Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 8,
        long_len: 8,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 2.5,
        max_label: 8,
        label_x: 29.925444,
        label_y: -18.91164,
        ne_id: 1159321441,
        wikidataid: "Q954",
        name_ar: "زيمبابوي",
        name_bn: "জিম্বাবুয়ে",
        name_de: "Simbabwe",
        name_en: "Zimbabwe",
        name_es: "Zimbabue",
        name_fa: "زیمبابوه",
        name_fr: "Zimbabwe",
        name_el: "Ζιμπάμπουε",
        name_he: "זימבבואה",
        name_hi: "ज़िम्बाब्वे",
        name_hu: "Zimbabwe",
        name_id: "Zimbabwe",
        name_it: "Zimbabwe",
        name_ja: "ジンバブエ",
        name_ko: "짐바브웨",
        name_nl: "Zimbabwe",
        name_pl: "Zimbabwe",
        name_pt: "Zimbábue",
        name_ru: "Зимбабве",
        name_sv: "Zimbabwe",
        name_tr: "Zimbabve",
        name_uk: "Зімбабве",
        name_ur: "زمبابوے",
        name_vi: "Zimbabwe",
        name_zh: "津巴布韦",
        name_zht: "辛巴威",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "ZWE.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [31.287890625000017, -22.40205078125001],
            [31.197265625, -22.34492187500001],
            [31.07343750000001, -22.30781250000001],
            [30.916113281250006, -22.29072265625001],
            [30.71162109375001, -22.2978515625],
            [30.46015625000001, -22.32900390625001],
            [30.1904296875, -22.291113281250006],
            [29.90234375, -22.184179687500006],
            [29.6630859375, -22.146289062500003],
            [29.37744140625, -22.19277343750001],
            [29.36484375, -22.193945312500006],
            [29.315234375000017, -22.15771484375],
            [29.237207031250023, -22.07949218750001],
            [29.106835937500023, -22.065722656250003],
            [29.07148437500001, -22.047460937500006],
            [29.042382812500023, -22.018359375],
            [29.023339843750023, -21.98125],
            [29.01582031250001, -21.93994140625],
            [29.03730468750001, -21.811328125],
            [29.02558593750001, -21.796875],
            [28.99072265625, -21.78144531250001],
            [28.919335937500023, -21.766015625],
            [28.74775390625001, -21.707617187500006],
            [28.532031250000017, -21.65126953125001],
            [28.181640625, -21.58935546875],
            [28.04560546875001, -21.573046875],
            [28.014062500000023, -21.55419921875],
            [27.974609375, -21.50673828125001],
            [27.90742187500001, -21.35908203125001],
            [27.844140625000023, -21.261523437500003],
            [27.693457031250006, -21.11103515625001],
            [27.66943359375, -21.064257812500003],
            [27.67695312500001, -20.94482421875],
            [27.688085937500006, -20.84833984375001],
            [27.70429687500001, -20.76640625],
            [27.69697265625001, -20.689746093750003],
            [27.69482421875, -20.59453125],
            [27.699609375000023, -20.53066406250001],
            [27.679296875, -20.503027343750006],
            [27.624609375, -20.48359375000001],
            [27.46894531250001, -20.47480468750001],
            [27.28076171875, -20.47871093750001],
            [27.27460937500001, -20.3818359375],
            [27.256738281250023, -20.23203125],
            [27.221484375000017, -20.145800781250003],
            [27.17822265625, -20.10097656250001],
            [27.091796875, -20.05419921875],
            [26.91669921875001, -19.99013671875001],
            [26.67822265625, -19.89277343750001],
            [26.474609375, -19.748632812500006],
            [26.241015625000017, -19.5693359375],
            [26.168066406250006, -19.53828125000001],
            [26.081933593750023, -19.369921875],
            [25.95068359375, -19.08173828125001],
            [25.95917968750001, -18.985644531250003],
            [25.939355468750023, -18.93867187500001],
            [25.811914062500023, -18.79707031250001],
            [25.78369140625, -18.72353515625001],
            [25.76123046875, -18.64921875],
            [25.55830078125001, -18.441796875],
            [25.4892578125, -18.35126953125001],
            [25.43671875000001, -18.234960937500006],
            [25.384375, -18.14199218750001],
            [25.340234375000023, -18.1044921875],
            [25.28242187500001, -18.04121093750001],
            [25.242285156250006, -17.969042968750003],
            [25.224023437500023, -17.91523437500001],
            [25.239062500000017, -17.843066406250003],
            [25.2587890625, -17.793554687500006],
            [25.451757812500006, -17.84511718750001],
            [25.55712890625, -17.84951171875001],
            [25.6396484375, -17.82412109375001],
            [25.741601562500023, -17.858203125],
            [25.86328125, -17.951953125],
            [25.995898437500017, -17.969824218750006],
            [26.139550781250023, -17.91171875],
            [26.333398437500023, -17.929296875],
            [26.577539062500023, -18.022558593750006],
            [26.779882812500006, -18.04150390625],
            [27.020800781250017, -17.95839843750001],
            [27.235742187500023, -17.728320312500003],
            [27.437890625000023, -17.51191406250001],
            [27.63671875, -17.262109375],
            [27.75654296875001, -17.060351562500003],
            [27.932226562500006, -16.89619140625001],
            [28.16376953125001, -16.76972656250001],
            [28.399804687500023, -16.66279296875001],
            [28.760644531250023, -16.53193359375001],
            [28.760546875000017, -16.53212890625001],
            [28.83271484375001, -16.424121093750003],
            [28.856738281250017, -16.30615234375],
            [28.856738281250017, -16.14228515625001],
            [28.875585937500006, -16.0361328125],
            [28.9130859375, -15.98779296875],
            [28.973046875000023, -15.950097656250009],
            [29.050585937500017, -15.901171875],
            [29.287890625000017, -15.776464843750006],
            [29.4873046875, -15.69677734375],
            [29.729589843750006, -15.644628906250006],
            [29.994921875000017, -15.64404296875],
            [30.25068359375001, -15.643457031250009],
            [30.39609375, -15.64306640625],
            [30.39814453125001, -15.80078125],
            [30.409375, -15.978222656250011],
            [30.437792968750017, -15.995312500000011],
            [30.630175781250017, -15.999218750000011],
            [30.938769531250017, -16.01171875],
            [31.236230468750023, -16.02363281250001],
            [31.426171875000023, -16.15234375],
            [31.48984375, -16.1796875],
            [31.687597656250006, -16.214160156250003],
            [31.939843750000023, -16.428808593750006],
            [32.243261718750006, -16.44873046875],
            [32.45195312500002, -16.515722656250006],
            [32.635839843750006, -16.589453125],
            [32.741796875, -16.67763671875001],
            [32.81025390625001, -16.69765625],
            [32.902929687500006, -16.704199218750006],
            [32.94804687500002, -16.71230468750001],
            [32.93789062500002, -16.775976562500006],
            [32.87626953125002, -16.88359375],
            [32.884375, -17.03779296875001],
            [32.969335937500006, -17.2515625],
            [32.98076171875002, -17.4375],
            [32.9546875, -17.765429687500003],
            [32.95556640625, -18.08291015625001],
            [32.96464843750002, -18.1962890625],
            [32.978515625, -18.271484375],
            [32.99638671875002, -18.312597656250006],
            [32.99306640625002, -18.35957031250001],
            [32.942480468750006, -18.49267578125],
            [32.90166015625002, -18.632910156250006],
            [32.90029296875002, -18.6890625],
            [32.88457031250002, -18.728515625],
            [32.8544921875, -18.763671875],
            [32.72197265625002, -18.828417968750003],
            [32.69921875, -18.868457031250003],
            [32.69970703125, -18.94091796875],
            [32.71650390625001, -19.00185546875001],
            [32.766210937500006, -19.02431640625001],
            [32.826171875, -19.05878906250001],
            [32.84980468750001, -19.10439453125001],
            [32.85, -19.152441406250006],
            [32.83095703125002, -19.24140625000001],
            [32.77763671875002, -19.388769531250006],
            [32.83076171875001, -19.558203125],
            [32.89042968750002, -19.668066406250006],
            [32.97265625, -19.79541015625],
            [33.00673828125002, -19.873828125],
            [33.0048828125, -19.93017578125],
            [32.992773437500006, -19.98486328125],
            [32.86962890625, -20.2171875],
            [32.780859375, -20.36152343750001],
            [32.67255859375001, -20.51611328125],
            [32.529296875, -20.613085937500003],
            [32.49238281250001, -20.659765625],
            [32.477636718750006, -20.712988281250006],
            [32.48281250000002, -20.82890625],
            [32.476171875, -20.95009765625001],
            [32.353613281250006, -21.136523437500003],
            [32.429785156250006, -21.29707031250001],
            [32.41240234375002, -21.311816406250003],
            [32.37109375, -21.33486328125001],
            [32.19472656250002, -21.515429687500003],
            [32.01630859375001, -21.698046875],
            [31.88593750000001, -21.83154296875],
            [31.737695312500023, -21.9833984375],
            [31.57148437500001, -22.15351562500001],
            [31.429492187500017, -22.298828125],
            [31.287890625000017, -22.40205078125001],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Algeria",
        sov_a3: "DZA",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Algeria",
        adm0_a3: "DZA",
        geou_dif: 0,
        geounit: "Algeria",
        gu_a3: "DZA",
        su_dif: 0,
        subunit: "Algeria",
        su_a3: "DZA",
        brk_diff: 0,
        name: "Algeria",
        name_long: "Algeria",
        brk_a3: "DZA",
        brk_name: "Algeria",
        brk_group: null,
        abbrev: "Alg.",
        postal: "DZ",
        formal_en: "People's Democratic Republic of Algeria",
        formal_fr: null,
        name_ciawf: "Algeria",
        note_adm0: null,
        note_brk: null,
        name_sort: "Algeria",
        name_alt: null,
        mapcolor7: 5,
        mapcolor8: 1,
        mapcolor9: 6,
        mapcolor13: 3,
        pop_est: 43053054,
        pop_rank: 15,
        pop_year: 2019,
        gdp_md: 171091,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        fips_10: "AG",
        iso_a2: "DZ",
        iso_a2_eh: "DZ",
        iso_a3: "DZA",
        iso_a3_eh: "DZA",
        iso_n3: "012",
        iso_n3_eh: "012",
        un_a3: "012",
        wb_a2: "DZ",
        wb_a3: "DZA",
        woe_id: 23424740,
        woe_id_eh: 23424740,
        woe_note: "Exact WOE match as country",
        adm0_iso: "DZA",
        adm0_diff: null,
        adm0_tlc: "DZA",
        adm0_a3_us: "DZA",
        adm0_a3_fr: "DZA",
        adm0_a3_ru: "DZA",
        adm0_a3_es: "DZA",
        adm0_a3_cn: "DZA",
        adm0_a3_tw: "DZA",
        adm0_a3_in: "DZA",
        adm0_a3_np: "DZA",
        adm0_a3_pk: "DZA",
        adm0_a3_de: "DZA",
        adm0_a3_gb: "DZA",
        adm0_a3_br: "DZA",
        adm0_a3_il: "DZA",
        adm0_a3_ps: "DZA",
        adm0_a3_sa: "DZA",
        adm0_a3_eg: "DZA",
        adm0_a3_ma: "DZA",
        adm0_a3_pt: "DZA",
        adm0_a3_ar: "DZA",
        adm0_a3_jp: "DZA",
        adm0_a3_ko: "DZA",
        adm0_a3_vn: "DZA",
        adm0_a3_tr: "DZA",
        adm0_a3_id: "DZA",
        adm0_a3_pl: "DZA",
        adm0_a3_gr: "DZA",
        adm0_a3_it: "DZA",
        adm0_a3_nl: "DZA",
        adm0_a3_se: "DZA",
        adm0_a3_bd: "DZA",
        adm0_a3_ua: "DZA",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Northern Africa",
        region_wb: "Middle East & North Africa",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 2.5,
        max_label: 7,
        label_x: 2.808241,
        label_y: 27.397406,
        ne_id: 1159320565,
        wikidataid: "Q262",
        name_ar: "الجزائر",
        name_bn: "আলজেরিয়া",
        name_de: "Algerien",
        name_en: "Algeria",
        name_es: "Argelia",
        name_fa: "الجزایر",
        name_fr: "Algérie",
        name_el: "Αλγερία",
        name_he: "אלג'יריה",
        name_hi: "अल्जीरिया",
        name_hu: "Algéria",
        name_id: "Aljazair",
        name_it: "Algeria",
        name_ja: "アルジェリア",
        name_ko: "알제리",
        name_nl: "Algerije",
        name_pl: "Algieria",
        name_pt: "Argélia",
        name_ru: "Алжир",
        name_sv: "Algeriet",
        name_tr: "Cezayir",
        name_uk: "Алжир",
        name_ur: "الجزائر",
        name_vi: "Algérie",
        name_zh: "阿尔及利亚",
        name_zht: "阿爾及利亞",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "DZA.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [8.576562500000023, 36.93720703125],
            [8.59765625, 36.88388671875],
            [8.601269531250011, 36.833935546875],
            [8.506738281250023, 36.7875],
            [8.444238281250023, 36.7607421875],
            [8.36962890625, 36.63251953125],
            [8.230761718750017, 36.545263671875],
            [8.207617187500006, 36.518945312499994],
            [8.208789062500017, 36.4951171875],
            [8.302734375, 36.455615234374996],
            [8.333984375, 36.4181640625],
            [8.348730468750006, 36.36796875],
            [8.306738281250006, 36.188769531249996],
            [8.2802734375, 36.0509765625],
            [8.245703125, 35.870556640625],
            [8.2470703125, 35.801806640624996],
            [8.282910156250011, 35.719287109374996],
            [8.318066406250011, 35.654931640624994],
            [8.329003906250023, 35.5822265625],
            [8.31640625, 35.403125],
            [8.35986328125, 35.299609375],
            [8.394238281250011, 35.203857421875],
            [8.312109375, 35.084619140624994],
            [8.27685546875, 34.9794921875],
            [8.254687500000017, 34.828955078125],
            [8.24560546875, 34.73408203125],
            [8.192773437500023, 34.646289062499996],
            [8.123437500000023, 34.563916015625],
            [8.045605468750011, 34.5126953125],
            [7.949414062500011, 34.468701171875],
            [7.838281250000023, 34.410302734374994],
            [7.74853515625, 34.2544921875],
            [7.554492187500017, 34.125],
            [7.513867187500011, 34.080517578125],
            [7.49560546875, 33.976513671875],
            [7.500195312500011, 33.832470703125],
            [7.534375, 33.717919921874994],
            [7.627539062500006, 33.548632812499996],
            [7.709179687500011, 33.3623046875],
            [7.731347656250023, 33.268505859375],
            [7.7626953125, 33.23310546875],
            [7.877246093750017, 33.172119140625],
            [8.075585937500023, 33.0890625],
            [8.1125, 33.055322265624994],
            [8.2109375, 32.926708984375],
            [8.30419921875, 32.6962890625],
            [8.333398437500023, 32.543603515624994],
            [8.51513671875, 32.422314453125],
            [8.682910156250017, 32.310449218749994],
            [8.844042968750017, 32.212109375],
            [9.018945312500023, 32.105371093749994],
            [9.044042968750006, 32.07236328125],
            [9.102343750000017, 31.846142578124997],
            [9.160253906250006, 31.621337890625],
            [9.224023437500023, 31.373681640624994],
            [9.287890625000017, 31.125341796875],
            [9.36328125, 30.832910156249994],
            [9.406054687500017, 30.666796875],
            [9.4580078125, 30.465380859374996],
            [9.51875, 30.229394531249994],
            [9.420996093750006, 30.179296875],
            [9.310253906250011, 30.115234375],
            [9.391015625000023, 29.99365234375],
            [9.546191406250017, 29.795947265624996],
            [9.64013671875, 29.636425781249997],
            [9.672656250000017, 29.5669921875],
            [9.745898437500017, 29.368945312499996],
            [9.805273437500006, 29.176953125],
            [9.820703125000023, 29.114794921874996],
            [9.842578125000017, 28.966992187499997],
            [9.815625, 28.560205078124994],
            [9.858203125000017, 28.043310546875],
            [9.916015625, 27.785693359374996],
            [9.825292968750006, 27.552978515625],
            [9.74755859375, 27.330859375],
            [9.752539062500006, 27.2193359375],
            [9.79541015625, 27.044775390625],
            [9.837109375000011, 26.915820312499996],
            [9.894433593750023, 26.847949218749996],
            [9.883203125000023, 26.630810546874997],
            [9.859375, 26.551953125],
            [9.684960937500023, 26.438232421875],
            [9.491406250000011, 26.333740234375],
            [9.437890625000023, 26.245507812499994],
            [9.42236328125, 26.1470703125],
            [9.4482421875, 26.067138671875],
            [9.58125, 25.89013671875],
            [9.781054687500017, 25.624267578125],
            [10.000683593750011, 25.332080078125003],
            [10.01904296875, 25.258544921875],
            [10.028125, 25.051025390625],
            [10.119531250000023, 24.790234375],
            [10.218652343750023, 24.676220703124997],
            [10.255859375, 24.591015625],
            [10.32578125, 24.530224609374997],
            [10.395898437500023, 24.485595703125],
            [10.43896484375, 24.480224609375],
            [10.686132812500006, 24.55136718749999],
            [11.108203125000017, 24.434033203124997],
            [11.507617187500017, 24.314355468749994],
            [11.536914062500017, 24.290820312500003],
            [11.624218750000011, 24.139697265625003],
            [11.766992187500023, 23.892578125],
            [11.873046875, 23.69482421875],
            [11.967871093750006, 23.517871093750003],
            [11.45, 23.212597656249997],
            [10.932226562500006, 22.907275390625003],
            [10.414355468750017, 22.602001953124997],
            [9.896484375, 22.29672851562499],
            [9.378710937500017, 21.99140625],
            [8.8609375, 21.68613281249999],
            [8.343066406250017, 21.380859375],
            [7.8251953125, 21.075585937499994],
            [7.481738281250017, 20.873095703125003],
            [7.263378906250011, 20.694482421874994],
            [6.989355468750006, 20.470507812500003],
            [6.730664062500011, 20.248046875],
            [6.527050781250011, 20.072949218749997],
            [6.263378906250011, 19.846142578124997],
            [6.130664062500017, 19.731982421875003],
            [5.836621093750011, 19.479150390624994],
            [5.748339843750017, 19.434228515624994],
            [5.358691406250017, 19.359521484374994],
            [5.001367187500023, 19.291064453125003],
            [4.671289062500023, 19.227783203125],
            [4.445703125000023, 19.184521484374997],
            [4.227636718750006, 19.142773437499997],
            [3.91015625, 19.083740234375],
            [3.683496093750023, 19.04160156249999],
            [3.438769531250017, 18.996142578125003],
            [3.40087890625, 18.988427734374994],
            [3.3564453125, 18.986621093750003],
            [3.323437500000011, 18.98837890624999],
            [3.255957031250006, 19.01328125],
            [3.174218750000023, 19.072900390624994],
            [3.119726562500006, 19.103173828124994],
            [3.106054687500006, 19.150097656249997],
            [3.137890625000011, 19.212158203125],
            [3.17724609375, 19.26816406249999],
            [3.1923828125, 19.312060546875003],
            [3.219628906250023, 19.345410156249997],
            [3.25439453125, 19.372607421875003],
            [3.255859375, 19.4109375],
            [3.22705078125, 19.473583984374997],
            [3.20166015625, 19.56040039062499],
            [3.202734375, 19.718310546875003],
            [3.203417968750017, 19.770751953125],
            [3.203710937500006, 19.789697265624994],
            [3.130273437500023, 19.85019531249999],
            [2.992480468750017, 19.91660156249999],
            [2.86572265625, 19.955957031249994],
            [2.807910156250017, 19.969433593749997],
            [2.667773437500017, 19.992919921875],
            [2.47421875, 20.03500976562499],
            [2.406152343750023, 20.063867187499994],
            [2.280859375, 20.21030273437499],
            [2.219335937500006, 20.247802734375],
            [1.928808593750006, 20.272705078125],
            [1.832421875000023, 20.296875],
            [1.753222656250017, 20.331591796875003],
            [1.685449218750023, 20.378369140624997],
            [1.647363281250023, 20.45883789062499],
            [1.636035156250017, 20.524365234374997],
            [1.610644531250017, 20.555566406249994],
            [1.290234375000011, 20.713574218749997],
            [1.208886718750023, 20.767285156249997],
            [1.165722656250011, 20.817431640625003],
            [1.1640625, 20.891308593749997],
            [1.172753906250023, 20.981982421875003],
            [1.1591796875, 21.0625],
            [1.1455078125, 21.102246093749997],
            [0.999414062500023, 21.19775390625],
            [0.671875, 21.411865234375],
            [0.344433593750011, 21.6259765625],
            [0.016992187500023, 21.840136718750003],
            [-0.310546875, 22.05419921875],
            [-0.637988281249989, 22.268310546875],
            [-0.965478515624994, 22.482470703125003],
            [-1.29296875, 22.696533203125],
            [-1.620410156249989, 22.91064453125],
            [-1.947900390624994, 23.124804687500003],
            [-2.275390625, 23.3388671875],
            [-2.602929687499994, 23.553027343750003],
            [-2.930371093749983, 23.767138671875003],
            [-3.257861328124989, 23.98125],
            [-3.585351562499994, 24.195361328125003],
            [-3.912792968749983, 24.409472656250003],
            [-4.240332031249977, 24.62353515625],
            [-4.516992187499994, 24.804492187500003],
            [-4.822607421874977, 24.99560546875],
            [-5.049511718749983, 25.135449218749997],
            [-5.275, 25.27451171874999],
            [-5.516943359374977, 25.423779296874997],
            [-5.674511718749983, 25.51640625],
            [-5.862548828125, 25.627001953125003],
            [-6.050585937499989, 25.737597656250003],
            [-6.238671875, 25.848193359375003],
            [-6.426708984374983, 25.958789062500003],
            [-6.61474609375, 26.06943359375],
            [-6.802832031249977, 26.179980468749996],
            [-6.990869140624994, 26.290576171874996],
            [-7.178906249999983, 26.401171875],
            [-7.366992187499989, 26.511767578124996],
            [-7.555078125, 26.622363281249996],
            [-7.743115234374983, 26.732958984374996],
            [-7.93115234375, 26.843554687499996],
            [-8.119238281249977, 26.954150390624996],
            [-8.307275390624994, 27.064746093749996],
            [-8.495312499999983, 27.175341796874996],
            [-8.683349609375, 27.2859375],
            [-8.683349609375, 27.490234375],
            [-8.683349609375, 27.656445312499997],
            [-8.683349609375, 27.900390625],
            [-8.683349609375, 28.112011718749997],
            [-8.683349609375, 28.323681640624997],
            [-8.683349609375, 28.46923828125],
            [-8.683349609375, 28.620751953124994],
            [-8.678417968749983, 28.689404296874997],
            [-8.659912109375, 28.718603515625],
            [-8.558349609375, 28.767871093749996],
            [-8.399316406249994, 28.880175781249996],
            [-8.340478515624994, 28.93017578125],
            [-8.265185546874989, 28.980517578124996],
            [-7.998925781249994, 29.132421875],
            [-7.94384765625, 29.174755859374997],
            [-7.685156249999977, 29.349511718749994],
            [-7.624609374999977, 29.375195312499997],
            [-7.485742187499994, 29.392236328124994],
            [-7.427685546874983, 29.425],
            [-7.349755859374994, 29.4947265625],
            [-7.234912109374989, 29.574902343749997],
            [-7.160205078124989, 29.612646484375],
            [-7.142431640624977, 29.619580078124997],
            [-7.094921874999983, 29.625195312499997],
            [-6.855566406249977, 29.601611328124996],
            [-6.755126953125, 29.583837890625],
            [-6.635351562499977, 29.568798828124997],
            [-6.597753906249977, 29.578955078125],
            [-6.565673828125, 29.603857421875],
            [-6.520556640624989, 29.659863281249997],
            [-6.510693359374983, 29.726025390624997],
            [-6.507910156249977, 29.7837890625],
            [-6.500878906249994, 29.809130859374996],
            [-6.479736328125, 29.820361328124996],
            [-6.427636718749994, 29.816113281249997],
            [-6.357617187499983, 29.808300781249997],
            [-6.214794921874983, 29.810693359374994],
            [-6.16650390625, 29.8189453125],
            [-6.004296875, 29.83125],
            [-5.775, 29.869042968749994],
            [-5.593310546874989, 29.91796875],
            [-5.448779296874989, 29.956933593749994],
            [-5.293652343749983, 30.058642578124996],
            [-5.180126953124983, 30.166162109374994],
            [-5.061914062499994, 30.326416015625],
            [-4.96826171875, 30.465380859374996],
            [-4.778515624999983, 30.552392578124994],
            [-4.61962890625, 30.604785156249996],
            [-4.529150390624977, 30.625537109374996],
            [-4.322851562499977, 30.698876953124994],
            [-4.148779296874977, 30.8095703125],
            [-3.9853515625, 30.913525390624997],
            [-3.860058593749983, 30.92724609375],
            [-3.702001953124977, 30.944482421874994],
            [-3.666796874999989, 30.964013671874994],
            [-3.626904296874983, 31.000927734374997],
            [-3.62451171875, 31.065771484375],
            [-3.672509765624994, 31.111376953124996],
            [-3.730175781249983, 31.135400390624994],
            [-3.77099609375, 31.161816406249997],
            [-3.811816406249989, 31.1666015625],
            [-3.833398437499994, 31.197802734374996],
            [-3.821386718749977, 31.25546875],
            [-3.815136718749983, 31.308837890625],
            [-3.789160156249977, 31.36181640625],
            [-3.796435546874989, 31.437109375],
            [-3.837109374999983, 31.512353515624994],
            [-3.849560546874983, 31.56640625],
            [-3.8466796875, 31.619873046875],
            [-3.826757812499977, 31.661914062499996],
            [-3.768164062499977, 31.68955078125],
            [-3.700244140624989, 31.700097656249994],
            [-3.604589843749977, 31.686767578125],
            [-3.439794921874977, 31.704541015624997],
            [-3.017382812499989, 31.834277343749996],
            [-2.988232421874983, 31.87421875],
            [-2.961132812499983, 31.96396484375],
            [-2.930859374999983, 32.042529296874996],
            [-2.88720703125, 32.06884765625],
            [-2.863427734374994, 32.07470703125],
            [-2.722607421874983, 32.095751953124996],
            [-2.523242187499989, 32.12568359375],
            [-2.448388671874994, 32.12998046875],
            [-2.23125, 32.121337890625],
            [-2.072802734374989, 32.115039062499996],
            [-1.816992187499977, 32.104785156249996],
            [-1.63515625, 32.099560546875],
            [-1.47705078125, 32.094873046874994],
            [-1.275341796874983, 32.089013671874994],
            [-1.225927734374977, 32.107226562499996],
            [-1.225927734374977, 32.16455078125],
            [-1.262109375, 32.271142578124994],
            [-1.240332031249977, 32.33759765625],
            [-1.16259765625, 32.399169921875],
            [-1.065527343749977, 32.468310546874996],
            [-1.111035156249983, 32.552294921874996],
            [-1.188232421875, 32.60849609375],
            [-1.29638671875, 32.675683593749994],
            [-1.352148437499977, 32.703369140625],
            [-1.45, 32.784814453124994],
            [-1.510009765625, 32.87763671875],
            [-1.550732421874983, 33.073583984375],
            [-1.625097656249977, 33.183349609375],
            [-1.67919921875, 33.318652343749996],
            [-1.63125, 33.566748046875],
            [-1.702978515624977, 33.716845703124996],
            [-1.714111328125, 33.7818359375],
            [-1.714697265624977, 33.858203125],
            [-1.692675781249989, 33.990283203124996],
            [-1.706933593749994, 34.176074218749996],
            [-1.791796874999989, 34.367919921875],
            [-1.751855468749994, 34.433251953124994],
            [-1.733300781249994, 34.467041015625],
            [-1.739453124999983, 34.49609375],
            [-1.816601562499983, 34.557080078125],
            [-1.849658203124989, 34.607324218749994],
            [-1.832421875, 34.654638671875],
            [-1.792187499999983, 34.723193359374996],
            [-1.795605468749983, 34.751904296875],
            [-1.9208984375, 34.835546875],
            [-2.131787109374983, 34.970849609374994],
            [-2.190771484374977, 35.02978515625],
            [-2.219628906249994, 35.10419921875],
            [-2.017773437499983, 35.08505859375],
            [-1.913281249999983, 35.09423828125],
            [-1.673632812499989, 35.18310546875],
            [-1.483740234374977, 35.303076171875],
            [-1.335839843749994, 35.3642578125],
            [-1.205371093749989, 35.495751953124994],
            [-1.087695312499989, 35.578857421875],
            [-0.91748046875, 35.668408203125],
            [-0.426123046874977, 35.8615234375],
            [-0.350781249999983, 35.863183593749994],
            [-0.189160156249983, 35.819091796875],
            [-0.048242187499994, 35.8328125],
            [0.047949218750006, 35.900537109374994],
            [0.151660156250017, 36.063134765624994],
            [0.312207031250011, 36.162353515625],
            [0.514941406250017, 36.26181640625],
            [0.790820312500017, 36.35654296875],
            [0.9716796875, 36.4439453125],
            [1.257226562500023, 36.519580078124996],
            [1.974511718750023, 36.567578125],
            [2.342871093750006, 36.610302734375],
            [2.593359375, 36.60068359375],
            [2.846484375000017, 36.7388671875],
            [2.972851562500011, 36.784472656249996],
            [3.5205078125, 36.7951171875],
            [3.779003906250011, 36.89619140625],
            [4.758105468750017, 36.896337890625],
            [4.877832031250023, 36.86240234375],
            [4.995410156250017, 36.808056640625],
            [5.195605468750017, 36.676806640624996],
            [5.29541015625, 36.648242187499996],
            [5.424609375000017, 36.675439453124994],
            [5.725488281250023, 36.799609375],
            [6.064746093750017, 36.8642578125],
            [6.249121093750006, 36.938330078125],
            [6.327832031250011, 37.046044921874994],
            [6.486523437500011, 37.085742187499996],
            [6.575878906250011, 37.00302734375],
            [6.927539062500017, 36.91943359375],
            [7.143457031250023, 36.943359375],
            [7.238476562500011, 36.968505859375],
            [7.204296875000011, 37.0923828125],
            [7.432421875000017, 37.05927734375],
            [7.607714843750017, 36.999755859375],
            [7.791601562500006, 36.880273437499994],
            [7.910449218750017, 36.856347656249994],
            [8.127148437500011, 36.9103515625],
            [8.576562500000023, 36.93720703125],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Chad",
        sov_a3: "TCD",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Chad",
        adm0_a3: "TCD",
        geou_dif: 0,
        geounit: "Chad",
        gu_a3: "TCD",
        su_dif: 0,
        subunit: "Chad",
        su_a3: "TCD",
        brk_diff: 0,
        name: "Chad",
        name_long: "Chad",
        brk_a3: "TCD",
        brk_name: "Chad",
        brk_group: null,
        abbrev: "Chad",
        postal: "TD",
        formal_en: "Republic of Chad",
        formal_fr: null,
        name_ciawf: "Chad",
        note_adm0: null,
        note_brk: null,
        name_sort: "Chad",
        name_alt: null,
        mapcolor7: 6,
        mapcolor8: 1,
        mapcolor9: 8,
        mapcolor13: 6,
        pop_est: 15946876,
        pop_rank: 14,
        pop_year: 2019,
        gdp_md: 11314,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        fips_10: "CD",
        iso_a2: "TD",
        iso_a2_eh: "TD",
        iso_a3: "TCD",
        iso_a3_eh: "TCD",
        iso_n3: "148",
        iso_n3_eh: "148",
        un_a3: "148",
        wb_a2: "TD",
        wb_a3: "TCD",
        woe_id: 23424777,
        woe_id_eh: 23424777,
        woe_note: "Exact WOE match as country",
        adm0_iso: "TCD",
        adm0_diff: null,
        adm0_tlc: "TCD",
        adm0_a3_us: "TCD",
        adm0_a3_fr: "TCD",
        adm0_a3_ru: "TCD",
        adm0_a3_es: "TCD",
        adm0_a3_cn: "TCD",
        adm0_a3_tw: "TCD",
        adm0_a3_in: "TCD",
        adm0_a3_np: "TCD",
        adm0_a3_pk: "TCD",
        adm0_a3_de: "TCD",
        adm0_a3_gb: "TCD",
        adm0_a3_br: "TCD",
        adm0_a3_il: "TCD",
        adm0_a3_ps: "TCD",
        adm0_a3_sa: "TCD",
        adm0_a3_eg: "TCD",
        adm0_a3_ma: "TCD",
        adm0_a3_pt: "TCD",
        adm0_a3_ar: "TCD",
        adm0_a3_jp: "TCD",
        adm0_a3_ko: "TCD",
        adm0_a3_vn: "TCD",
        adm0_a3_tr: "TCD",
        adm0_a3_id: "TCD",
        adm0_a3_pl: "TCD",
        adm0_a3_gr: "TCD",
        adm0_a3_it: "TCD",
        adm0_a3_nl: "TCD",
        adm0_a3_se: "TCD",
        adm0_a3_bd: "TCD",
        adm0_a3_ua: "TCD",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Middle Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 4,
        long_len: 4,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: 18.645041,
        label_y: 15.142959,
        ne_id: 1159321301,
        wikidataid: "Q657",
        name_ar: "تشاد",
        name_bn: "চাদ",
        name_de: "Tschad",
        name_en: "Chad",
        name_es: "Chad",
        name_fa: "چاد",
        name_fr: "Tchad",
        name_el: "Τσαντ",
        name_he: "צ'אד",
        name_hi: "चाड",
        name_hu: "Csád",
        name_id: "Chad",
        name_it: "Ciad",
        name_ja: "チャド",
        name_ko: "차드",
        name_nl: "Tsjaad",
        name_pl: "Czad",
        name_pt: "Chade",
        name_ru: "Чад",
        name_sv: "Tchad",
        name_tr: "Çad",
        name_uk: "Чад",
        name_ur: "چاڈ",
        name_vi: "Tchad",
        name_zh: "乍得",
        name_zht: "查德",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "TCD.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [23.980273437500017, 19.496630859375003],
            [23.98066406250001, 19.050585937500003],
            [23.981054687500006, 18.604541015625003],
            [23.9814453125, 18.158496093750003],
            [23.981835937500023, 17.71240234375],
            [23.982226562500017, 17.266357421875],
            [23.982519531250006, 16.820263671874997],
            [23.98291015625, 16.37421875],
            [23.983300781250023, 15.928125],
            [23.9833984375, 15.780175781249994],
            [23.970800781250006, 15.721533203124991],
            [23.965234375000023, 15.713427734375003],
            [23.94599609375001, 15.703515625],
            [23.70820312500001, 15.744970703124991],
            [23.60400390625, 15.745996093749994],
            [23.4580078125, 15.713964843749991],
            [23.243457031250017, 15.697216796874997],
            [23.10517578125001, 15.702539062499994],
            [23.009179687500023, 15.625830078124991],
            [22.933886718750017, 15.533105468749994],
            [22.969531250000017, 15.311328125],
            [22.961328125000023, 15.238134765624991],
            [22.93232421875001, 15.162109375],
            [22.8671875, 15.096630859374997],
            [22.802148437500023, 15.04443359375],
            [22.76328125, 14.998681640624994],
            [22.714941406250006, 14.898388671874997],
            [22.67919921875, 14.851464843749994],
            [22.682421875000017, 14.788623046875003],
            [22.6708984375, 14.722460937500003],
            [22.6318359375, 14.688085937499991],
            [22.532031250000017, 14.662744140624994],
            [22.4677734375, 14.633349609375003],
            [22.41621093750001, 14.585205078125],
            [22.38154296875001, 14.550488281249997],
            [22.399707031250017, 14.504199218750003],
            [22.425, 14.441210937500003],
            [22.439355468750023, 14.342138671874991],
            [22.449316406250006, 14.284228515625003],
            [22.498339843750017, 14.237060546875],
            [22.528222656250023, 14.203222656249991],
            [22.53857421875, 14.161865234375],
            [22.50996093750001, 14.12744140625],
            [22.38818359375, 14.055517578124991],
            [22.33935546875, 14.028857421875003],
            [22.283496093750017, 13.992333984374994],
            [22.262109375000023, 13.978710937499997],
            [22.173144531250017, 13.910595703124997],
            [22.128222656250017, 13.850146484375003],
            [22.1064453125, 13.7998046875],
            [22.10761718750001, 13.730322265624991],
            [22.152929687500006, 13.626416015624997],
            [22.20234375000001, 13.5380859375],
            [22.22138671875001, 13.471630859374997],
            [22.23261718750001, 13.398779296874991],
            [22.228125, 13.32958984375],
            [22.20263671875, 13.269335937500003],
            [22.158007812500017, 13.215039062499997],
            [21.990234375, 13.113085937499989],
            [21.90771484375, 13.0009765625],
            [21.841796875, 12.86474609375],
            [21.825292968750006, 12.79052734375],
            [21.843359375, 12.7412109375],
            [21.878125, 12.699365234374994],
            [21.928125, 12.678125],
            [22.00068359375001, 12.671875],
            [22.121191406250006, 12.694580078125],
            [22.2333984375, 12.70947265625],
            [22.352343750000017, 12.660449218749989],
            [22.414453125000023, 12.54638671875],
            [22.390234375, 12.462988281249991],
            [22.43525390625001, 12.311914062499994],
            [22.475488281250023, 12.129248046874991],
            [22.472460937500017, 12.067773437499994],
            [22.48984375, 12.044726562499989],
            [22.564355468750023, 12.032958984375],
            [22.580957031250023, 11.990136718749994],
            [22.55634765625001, 11.66953125],
            [22.591113281250017, 11.579882812499989],
            [22.641015625000023, 11.515917968749989],
            [22.697363281250006, 11.482666015625],
            [22.754003906250006, 11.43984375],
            [22.78339843750001, 11.409960937499989],
            [22.849023437500023, 11.403271484374997],
            [22.922656250000017, 11.344873046874994],
            [22.942773437500023, 11.2671875],
            [22.93769531250001, 11.192041015624994],
            [22.894824218750017, 11.029003906249997],
            [22.86005859375001, 10.919677734375],
            [22.8173828125, 10.927197265624997],
            [22.73017578125001, 10.954052734374997],
            [22.6240234375, 10.977343749999989],
            [22.49384765625001, 10.996240234374994],
            [22.36982421875001, 10.951513671874991],
            [22.2359375, 10.894140625],
            [22.193652343750017, 10.851367187499989],
            [22.15625, 10.826074218749994],
            [22.09716796875, 10.830078125],
            [22.04316406250001, 10.822705078124997],
            [22.013769531250006, 10.782031249999989],
            [21.96484375, 10.736669921874991],
            [21.771484375, 10.642822265625],
            [21.73066406250001, 10.608691406249989],
            [21.70654296875, 10.574804687499991],
            [21.70654296875, 10.537890624999989],
            [21.726171875, 10.461621093749997],
            [21.72578125000001, 10.366552734374991],
            [21.682714843750006, 10.289843749999989],
            [21.632714843750023, 10.23828125],
            [21.57578125, 10.218554687499989],
            [21.52802734375001, 10.207812499999989],
            [21.496875, 10.175683593749994],
            [21.39599609375, 10.001367187499994],
            [21.352441406250023, 9.969140625],
            [21.26386718750001, 9.974609375],
            [21.00947265625001, 9.713232421874991],
            [20.984179687500017, 9.636279296874989],
            [20.891015625000023, 9.527148437499989],
            [20.773242187500017, 9.405664062499994],
            [20.66816406250001, 9.347119140624997],
            [20.65966796875, 9.324511718749989],
            [20.631445312500006, 9.301367187499991],
            [20.56689453125, 9.274951171874989],
            [20.342089843750017, 9.127099609374994],
            [20.072656250000023, 9.133203125],
            [19.953515625000023, 9.075146484374997],
            [19.837695312500017, 9.049365234374989],
            [19.668359375000023, 9.020898437499994],
            [19.617480468750017, 9.023583984374994],
            [19.400292968750023, 9.011621093749994],
            [19.1455078125, 9.015966796874991],
            [19.0478515625, 8.995019531249994],
            [18.95625, 8.938867187499994],
            [18.88828125, 8.889746093749991],
            [18.878320312500023, 8.873193359374994],
            [18.888574218750023, 8.852490234374997],
            [18.886035156250017, 8.836035156249991],
            [19.06416015625001, 8.715429687499991],
            [19.108691406250017, 8.656152343749994],
            [19.063867187500023, 8.598828125],
            [19.042382812500023, 8.590283203124997],
            [19.039843750000017, 8.5869140625],
            [19.010839843750006, 8.541210937499997],
            [18.90644531250001, 8.405078124999989],
            [18.747460937500023, 8.243798828124994],
            [18.66621093750001, 8.197705078124997],
            [18.633593750000017, 8.167724609375],
            [18.591601562500017, 8.060791015625],
            [18.56416015625001, 8.0458984375],
            [18.455078125, 8.032031249999989],
            [18.238867187500006, 8.020361328124991],
            [17.94013671875001, 7.985449218749991],
            [17.760839843750006, 7.973828125],
            [17.6494140625, 7.98359375],
            [17.49267578125, 7.909814453124994],
            [17.436425781250023, 7.890917968749989],
            [17.402148437500017, 7.884570312499989],
            [17.246972656250023, 7.81298828125],
            [17.117968750000017, 7.701904296875],
            [17.07197265625001, 7.680810546874994],
            [16.89033203125001, 7.633691406249994],
            [16.818164062500017, 7.557324218749997],
            [16.784765625, 7.550976562499997],
            [16.668359375000023, 7.651757812499994],
            [16.588964843750006, 7.743359375],
            [16.550195312500023, 7.835888671874997],
            [16.545312500000023, 7.865478515625],
            [16.523242187500017, 7.859960937499991],
            [16.459375, 7.818994140624994],
            [16.404394531250006, 7.772363281249994],
            [16.37890625, 7.683544921874997],
            [16.19111328125001, 7.6234375],
            [16.030664062500023, 7.572119140624991],
            [15.957617187500006, 7.507568359375],
            [15.845019531250017, 7.475292968749997],
            [15.701269531250006, 7.488427734374994],
            [15.589257812500023, 7.515039062499994],
            [15.480078125, 7.523779296874991],
            [15.532421875000011, 7.604394531249994],
            [15.552636718750023, 7.664501953124997],
            [15.557812500000011, 7.738037109375],
            [15.5498046875, 7.787890624999989],
            [15.484472656250006, 7.812744140625],
            [15.44296875, 7.851855468749989],
            [15.349023437500023, 8.083837890624991],
            [15.252343750000023, 8.322363281249991],
            [15.1162109375, 8.557324218749997],
            [14.967968750000011, 8.707275390625],
            [14.860742187500023, 8.798632812499989],
            [14.826269531250006, 8.810302734375],
            [14.771289062500017, 8.839160156249989],
            [14.732812500000023, 8.865673828124997],
            [14.5361328125, 9.025244140624991],
            [14.332324218750017, 9.203515625],
            [14.280078125000017, 9.285058593749994],
            [14.177929687500011, 9.406494140625],
            [14.064160156250011, 9.53173828125],
            [14.004980468750006, 9.588720703124991],
            [13.977246093750011, 9.691552734374994],
            [14.055957031250017, 9.784375],
            [14.139746093750006, 9.901806640624997],
            [14.243261718750006, 9.979736328125],
            [14.377246093750017, 9.985058593749997],
            [14.597949218750017, 9.953076171874997],
            [14.835839843750023, 9.941699218749989],
            [15.071582031250017, 9.965966796874994],
            [15.132714843750023, 9.982861328124997],
            [15.193164062500017, 9.981494140624989],
            [15.320019531250011, 9.954296875],
            [15.540917968750023, 9.960302734374991],
            [15.654882812500006, 10.0078125],
            [15.531933593750011, 10.088476562499991],
            [15.39990234375, 10.216894531249991],
            [15.276074218750011, 10.357373046874997],
            [15.200976562500017, 10.484521484374994],
            [15.132226562500023, 10.648486328124989],
            [15.068652343750017, 10.85107421875],
            [15.029882812500006, 11.113671875],
            [15.035742187500006, 11.2625],
            [15.055468750000017, 11.368554687499994],
            [15.121972656250023, 11.541259765625],
            [15.078027343750023, 11.642578125],
            [15.087695312500017, 11.724365234375],
            [15.08125, 11.845507812499989],
            [15.059863281250017, 11.907128906249994],
            [14.973828125000011, 12.108349609374997],
            [14.956738281250011, 12.13037109375],
            [14.880664062500017, 12.269384765624991],
            [14.847070312500023, 12.502099609374994],
            [14.76123046875, 12.655615234374991],
            [14.623242187500011, 12.729931640624997],
            [14.544726562500017, 12.820214843749994],
            [14.516210937500006, 12.979736328125],
            [14.461718750000017, 13.021777343749989],
            [14.244824218750011, 13.07734375],
            [14.06396484375, 13.078515625],
            [13.932324218750011, 13.258496093749997],
            [13.763476562500017, 13.489550781250003],
            [13.606347656250023, 13.70458984375],
            [13.505761718750023, 14.134423828124994],
            [13.4482421875, 14.380664062500003],
            [13.513671875, 14.455517578124997],
            [13.642382812500017, 14.630761718749994],
            [13.80712890625, 14.966113281250003],
            [14.17822265625, 15.484765625],
            [14.367968750000017, 15.750146484374994],
            [14.746679687500006, 16.146630859374994],
            [15.212109375000011, 16.63388671874999],
            [15.474316406250011, 16.908398437499997],
            [15.516699218750006, 17.408496093750003],
            [15.5615234375, 17.937255859375],
            [15.595507812500017, 18.337060546874994],
            [15.637597656250023, 18.810839843750003],
            [15.672949218750006, 19.206787109375],
            [15.698632812500023, 19.49521484374999],
            [15.735058593750011, 19.904052734375],
            [15.766210937500006, 19.982568359374994],
            [15.948828125, 20.303173828124997],
            [15.963183593750017, 20.34619140625],
            [15.929296875, 20.399853515624997],
            [15.66845703125, 20.67236328125],
            [15.587109375000011, 20.733300781249994],
            [15.540332031250017, 20.874902343749994],
            [15.607324218750023, 20.954394531250003],
            [15.293652343750011, 21.411523437499994],
            [15.2158203125, 21.467431640624994],
            [15.181835937500011, 21.523388671874997],
            [15.177832031250006, 21.60581054687499],
            [15.172265625000023, 21.922070312499997],
            [15.088964843750006, 22.418359375],
            [14.97900390625, 22.99619140624999],
            [15.347460937500017, 23.160693359375003],
            [15.627148437500011, 23.28574218749999],
            [15.984082031250011, 23.445214843749994],
            [16.315039062500006, 23.28183593749999],
            [16.79414062500001, 23.04526367187499],
            [17.273242187500017, 22.80869140624999],
            [17.752246093750017, 22.57211914062499],
            [18.231347656250023, 22.335546875],
            [18.71044921875, 22.09897460937499],
            [19.189453125, 21.86240234374999],
            [19.668554687500006, 21.62583007812499],
            [20.14765625000001, 21.38925781249999],
            [20.626757812500017, 21.152636718750003],
            [21.105859375000023, 20.91611328124999],
            [21.5849609375, 20.679492187500003],
            [22.0640625, 20.442919921875003],
            [22.543066406250006, 20.206347656250003],
            [23.02216796875001, 19.969775390625003],
            [23.501269531250017, 19.733203125],
            [23.980273437500017, 19.496630859375003],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Rwanda",
        sov_a3: "RWA",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Rwanda",
        adm0_a3: "RWA",
        geou_dif: 0,
        geounit: "Rwanda",
        gu_a3: "RWA",
        su_dif: 0,
        subunit: "Rwanda",
        su_a3: "RWA",
        brk_diff: 0,
        name: "Rwanda",
        name_long: "Rwanda",
        brk_a3: "RWA",
        brk_name: "Rwanda",
        brk_group: null,
        abbrev: "Rwa.",
        postal: "RW",
        formal_en: "Republic of Rwanda",
        formal_fr: null,
        name_ciawf: "Rwanda",
        note_adm0: null,
        note_brk: null,
        name_sort: "Rwanda",
        name_alt: null,
        mapcolor7: 5,
        mapcolor8: 2,
        mapcolor9: 3,
        mapcolor13: 10,
        pop_est: 12626950,
        pop_rank: 14,
        pop_year: 2019,
        gdp_md: 10354,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        fips_10: "RW",
        iso_a2: "RW",
        iso_a2_eh: "RW",
        iso_a3: "RWA",
        iso_a3_eh: "RWA",
        iso_n3: "646",
        iso_n3_eh: "646",
        un_a3: "646",
        wb_a2: "RW",
        wb_a3: "RWA",
        woe_id: 23424937,
        woe_id_eh: 23424937,
        woe_note: "Exact WOE match as country",
        adm0_iso: "RWA",
        adm0_diff: null,
        adm0_tlc: "RWA",
        adm0_a3_us: "RWA",
        adm0_a3_fr: "RWA",
        adm0_a3_ru: "RWA",
        adm0_a3_es: "RWA",
        adm0_a3_cn: "RWA",
        adm0_a3_tw: "RWA",
        adm0_a3_in: "RWA",
        adm0_a3_np: "RWA",
        adm0_a3_pk: "RWA",
        adm0_a3_de: "RWA",
        adm0_a3_gb: "RWA",
        adm0_a3_br: "RWA",
        adm0_a3_il: "RWA",
        adm0_a3_ps: "RWA",
        adm0_a3_sa: "RWA",
        adm0_a3_eg: "RWA",
        adm0_a3_ma: "RWA",
        adm0_a3_pt: "RWA",
        adm0_a3_ar: "RWA",
        adm0_a3_jp: "RWA",
        adm0_a3_ko: "RWA",
        adm0_a3_vn: "RWA",
        adm0_a3_tr: "RWA",
        adm0_a3_id: "RWA",
        adm0_a3_pl: "RWA",
        adm0_a3_gr: "RWA",
        adm0_a3_it: "RWA",
        adm0_a3_nl: "RWA",
        adm0_a3_se: "RWA",
        adm0_a3_bd: "RWA",
        adm0_a3_ua: "RWA",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Eastern Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 6,
        long_len: 6,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: 30.103894,
        label_y: -1.897196,
        ne_id: 1159321219,
        wikidataid: "Q1037",
        name_ar: "رواندا",
        name_bn: "রুয়ান্ডা",
        name_de: "Ruanda",
        name_en: "Rwanda",
        name_es: "Ruanda",
        name_fa: "رواندا",
        name_fr: "Rwanda",
        name_el: "Ρουάντα",
        name_he: "רואנדה",
        name_hi: "रवाण्डा",
        name_hu: "Ruanda",
        name_id: "Rwanda",
        name_it: "Ruanda",
        name_ja: "ルワンダ",
        name_ko: "르완다",
        name_nl: "Rwanda",
        name_pl: "Rwanda",
        name_pt: "Ruanda",
        name_ru: "Руанда",
        name_sv: "Rwanda",
        name_tr: "Ruanda",
        name_uk: "Руанда",
        name_ur: "روانڈا",
        name_vi: "Rwanda",
        name_zh: "卢旺达",
        name_zht: "盧旺達",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "RWA.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [29.576953125000017, -1.387890625000011],
            [29.609667968750017, -1.387109375],
            [29.82539062500001, -1.335546875],
            [29.846875, -1.351660156250006],
            [29.881640625000017, -1.451757812500006],
            [29.9, -1.46630859375],
            [29.930078125000023, -1.469921875000011],
            [29.990527343750017, -1.446972656250011],
            [30.1015625, -1.36865234375],
            [30.15, -1.32109375],
            [30.20703125, -1.254199218750003],
            [30.279882812500006, -1.178808593750006],
            [30.32050781250001, -1.113085937500003],
            [30.360253906250023, -1.074609375],
            [30.41230468750001, -1.063085937500006],
            [30.46992187500001, -1.066015625],
            [30.50996093750001, -1.067285156250009],
            [30.47705078125, -1.0830078125],
            [30.47021484375, -1.131152343750003],
            [30.508105468750017, -1.208203125000011],
            [30.631933593750006, -1.367480468750003],
            [30.710742187500017, -1.396777343750003],
            [30.76220703125, -1.458691406250011],
            [30.812597656250006, -1.563085937500006],
            [30.827539062500023, -1.693652343750003],
            [30.806738281250006, -1.850683593750006],
            [30.819140625000017, -1.967480468750011],
            [30.864648437500023, -2.044042968750006],
            [30.8765625, -2.143359375],
            [30.85498046875, -2.265429687500003],
            [30.828710937500006, -2.338476562500006],
            [30.797656250000017, -2.362695312500009],
            [30.7625, -2.371679687500006],
            [30.71484375, -2.363476562500011],
            [30.656640625000023, -2.373828125],
            [30.593359375, -2.396777343750003],
            [30.553613281250023, -2.400097656250011],
            [30.52890625, -2.395605468750006],
            [30.482226562500017, -2.376074218750006],
            [30.408496093750017, -2.31298828125],
            [30.27099609375, -2.347851562500011],
            [30.233789062500023, -2.347070312500009],
            [30.18330078125001, -2.377050781250006],
            [30.14228515625001, -2.413964843750009],
            [30.117285156250006, -2.416601562500006],
            [30.091894531250006, -2.411523437500009],
            [29.973437500000017, -2.337109375000011],
            [29.93017578125, -2.339550781250011],
            [29.912402343750017, -2.548632812500003],
            [29.892578125, -2.664648437500006],
            [29.8681640625, -2.71640625],
            [29.78339843750001, -2.76640625],
            [29.698046875000017, -2.794726562500003],
            [29.6513671875, -2.792773437500003],
            [29.463671875000017, -2.808398437500003],
            [29.390234375, -2.80859375],
            [29.34980468750001, -2.79150390625],
            [29.29707031250001, -2.673046875000011],
            [29.197558593750017, -2.620312500000011],
            [29.10205078125, -2.595703125],
            [29.06318359375001, -2.6025390625],
            [29.028613281250017, -2.66455078125],
            [29.01435546875001, -2.72021484375],
            [28.921777343750023, -2.68203125],
            [28.893945312500023, -2.635058593750003],
            [28.891406250000017, -2.555566406250009],
            [28.85761718750001, -2.446679687500009],
            [28.876367187500023, -2.400292968750009],
            [28.912695312500006, -2.370312500000011],
            [28.989550781250017, -2.312792968750003],
            [29.1064453125, -2.233203125],
            [29.13154296875001, -2.195117187500003],
            [29.148046875, -2.1318359375],
            [29.140625, -1.984570312500011],
            [29.12939453125, -1.860253906250009],
            [29.14326171875001, -1.816015625],
            [29.196582031250017, -1.719921875000011],
            [29.268164062500006, -1.62158203125],
            [29.351660156250006, -1.517578125],
            [29.401953125, -1.507421875],
            [29.46796875000001, -1.468066406250003],
            [29.53779296875001, -1.409765625],
            [29.576953125000017, -1.387890625000011],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Uganda",
        sov_a3: "UGA",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Uganda",
        adm0_a3: "UGA",
        geou_dif: 0,
        geounit: "Uganda",
        gu_a3: "UGA",
        su_dif: 0,
        subunit: "Uganda",
        su_a3: "UGA",
        brk_diff: 0,
        name: "Uganda",
        name_long: "Uganda",
        brk_a3: "UGA",
        brk_name: "Uganda",
        brk_group: null,
        abbrev: "Uga.",
        postal: "UG",
        formal_en: "Republic of Uganda",
        formal_fr: null,
        name_ciawf: "Uganda",
        note_adm0: null,
        note_brk: null,
        name_sort: "Uganda",
        name_alt: null,
        mapcolor7: 6,
        mapcolor8: 3,
        mapcolor9: 6,
        mapcolor13: 4,
        pop_est: 44269594,
        pop_rank: 15,
        pop_year: 2019,
        gdp_md: 35165,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        fips_10: "UG",
        iso_a2: "UG",
        iso_a2_eh: "UG",
        iso_a3: "UGA",
        iso_a3_eh: "UGA",
        iso_n3: "800",
        iso_n3_eh: "800",
        un_a3: "800",
        wb_a2: "UG",
        wb_a3: "UGA",
        woe_id: 23424974,
        woe_id_eh: 23424974,
        woe_note: "Exact WOE match as country",
        adm0_iso: "UGA",
        adm0_diff: null,
        adm0_tlc: "UGA",
        adm0_a3_us: "UGA",
        adm0_a3_fr: "UGA",
        adm0_a3_ru: "UGA",
        adm0_a3_es: "UGA",
        adm0_a3_cn: "UGA",
        adm0_a3_tw: "UGA",
        adm0_a3_in: "UGA",
        adm0_a3_np: "UGA",
        adm0_a3_pk: "UGA",
        adm0_a3_de: "UGA",
        adm0_a3_gb: "UGA",
        adm0_a3_br: "UGA",
        adm0_a3_il: "UGA",
        adm0_a3_ps: "UGA",
        adm0_a3_sa: "UGA",
        adm0_a3_eg: "UGA",
        adm0_a3_ma: "UGA",
        adm0_a3_pt: "UGA",
        adm0_a3_ar: "UGA",
        adm0_a3_jp: "UGA",
        adm0_a3_ko: "UGA",
        adm0_a3_vn: "UGA",
        adm0_a3_tr: "UGA",
        adm0_a3_id: "UGA",
        adm0_a3_pl: "UGA",
        adm0_a3_gr: "UGA",
        adm0_a3_it: "UGA",
        adm0_a3_nl: "UGA",
        adm0_a3_se: "UGA",
        adm0_a3_bd: "UGA",
        adm0_a3_ua: "UGA",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Eastern Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 6,
        long_len: 6,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: 32.948555,
        label_y: 1.972589,
        ne_id: 1159321343,
        wikidataid: "Q1036",
        name_ar: "أوغندا",
        name_bn: "উগান্ডা",
        name_de: "Uganda",
        name_en: "Uganda",
        name_es: "Uganda",
        name_fa: "اوگاندا",
        name_fr: "Ouganda",
        name_el: "Ουγκάντα",
        name_he: "אוגנדה",
        name_hi: "युगाण्डा",
        name_hu: "Uganda",
        name_id: "Uganda",
        name_it: "Uganda",
        name_ja: "ウガンダ",
        name_ko: "우간다",
        name_nl: "Oeganda",
        name_pl: "Uganda",
        name_pt: "Uganda",
        name_ru: "Уганда",
        name_sv: "Uganda",
        name_tr: "Uganda",
        name_uk: "Уганда",
        name_ur: "یوگنڈا",
        name_vi: "Uganda",
        name_zh: "乌干达",
        name_zht: "烏干達",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "UGA.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [33.90322265625002, -1.002050781250006],
            [33.794042968750006, -1.002050781250006],
            [33.616308593750006, -1.002050781250006],
            [33.4384765625, -1.002050781250006],
            [33.2607421875, -1.002050781250006],
            [33.0830078125, -1.002050781250006],
            [32.90517578125002, -1.002050781250006],
            [32.72744140625002, -1.002050781250006],
            [32.54970703125002, -1.002050781250006],
            [32.371875, -1.002050781250006],
            [32.19414062500002, -1.002050781250006],
            [32.01640625000002, -1.002050781250006],
            [31.83857421875001, -1.002050781250006],
            [31.66083984375001, -1.002050781250006],
            [31.48310546875001, -1.002050781250006],
            [31.305273437500006, -1.002050781250006],
            [31.127539062500006, -1.002050781250006],
            [30.94970703125, -1.002050781250006],
            [30.8447265625, -1.002050781250006],
            [30.823632812500023, -0.9990234375],
            [30.809179687500006, -0.994921875],
            [30.741992187500017, -1.007519531250011],
            [30.672753906250023, -1.051367187500006],
            [30.598730468750006, -1.069726562500009],
            [30.519921875000023, -1.0625],
            [30.50996093750001, -1.067285156250009],
            [30.46992187500001, -1.066015625],
            [30.41230468750001, -1.063085937500006],
            [30.360253906250023, -1.074609375],
            [30.32050781250001, -1.113085937500003],
            [30.279882812500006, -1.178808593750006],
            [30.20703125, -1.254199218750003],
            [30.15, -1.32109375],
            [30.1015625, -1.36865234375],
            [29.990527343750017, -1.446972656250011],
            [29.930078125000023, -1.469921875000011],
            [29.9, -1.46630859375],
            [29.881640625000017, -1.451757812500006],
            [29.846875, -1.351660156250006],
            [29.82539062500001, -1.335546875],
            [29.609667968750017, -1.387109375],
            [29.576953125000017, -1.387890625000011],
            [29.579980468750023, -1.356738281250003],
            [29.5640625, -1.121386718750003],
            [29.561914062500023, -0.97734375],
            [29.59003906250001, -0.887109375],
            [29.6064453125, -0.783105468750009],
            [29.608203125000017, -0.691308593750009],
            [29.647851562500023, -0.535253906250006],
            [29.633203125000023, -0.441699218750003],
            [29.684375, -0.113574218750003],
            [29.697851562500006, -0.060205078125009],
            [29.717675781250023, 0.098339843749997],
            [29.74970703125001, 0.147216796875],
            [29.77783203125, 0.166357421874991],
            [29.81464843750001, 0.263623046874997],
            [29.88544921875001, 0.4189453125],
            [29.934472656250023, 0.4990234375],
            [29.923828125, 0.673925781249991],
            [29.931640625, 0.792871093749994],
            [29.94287109375, 0.819238281249994],
            [30.04736328125, 0.863525390625],
            [30.182910156250017, 0.973486328124991],
            [30.240136718750023, 1.102783203125],
            [30.321093750000017, 1.185302734375],
            [30.477832031250017, 1.238818359374989],
            [30.478125, 1.239062499999989],
            [30.94257812500001, 1.6828125],
            [31.158789062500006, 1.922021484374994],
            [31.252734375000017, 2.044580078124994],
            [31.25605468750001, 2.088476562499991],
            [31.274023437500006, 2.146289062499989],
            [31.236328125, 2.191357421874997],
            [31.19140625, 2.232275390624991],
            [31.176367187500006, 2.270068359374989],
            [31.137597656250023, 2.288867187499989],
            [31.082128906250006, 2.2880859375],
            [31.045312500000023, 2.315527343749991],
            [31.00361328125001, 2.369384765625],
            [30.9619140625, 2.403271484374997],
            [30.830078125, 2.400439453124989],
            [30.728613281250006, 2.455371093749989],
            [30.729882812500023, 2.5302734375],
            [30.76953125, 2.677978515625],
            [30.8466796875, 2.847021484374991],
            [30.85078125000001, 2.893652343749991],
            [30.839941406250006, 2.933496093749994],
            [30.821386718750006, 2.967578124999989],
            [30.786523437500023, 3.001367187499994],
            [30.754003906250006, 3.041796874999989],
            [30.779296875, 3.163378906249989],
            [30.82783203125001, 3.282617187499994],
            [30.867578125000023, 3.342138671874991],
            [30.90644531250001, 3.408935546875],
            [30.895312500000017, 3.463671874999989],
            [30.83857421875001, 3.49072265625],
            [30.8681640625, 3.544140625],
            [30.92939453125001, 3.634082031249989],
            [31.04804687500001, 3.725],
            [31.15234375, 3.785595703124997],
            [31.221972656250017, 3.785937499999989],
            [31.357421875, 3.737597656249989],
            [31.47998046875, 3.680468749999989],
            [31.547167968750017, 3.677587890624991],
            [31.62890625, 3.701464843749989],
            [31.79804687500001, 3.802636718749994],
            [31.838671875000017, 3.770458984374997],
            [31.88828125, 3.709082031249991],
            [31.941796875000023, 3.607568359374994],
            [32.04824218750002, 3.561181640624994],
            [32.09941406250002, 3.529199218749994],
            [32.13593750000001, 3.519726562499997],
            [32.15625, 3.528027343749997],
            [32.19667968750002, 3.6078125],
            [32.24550781250002, 3.651318359374997],
            [32.33574218750002, 3.706201171874994],
            [32.534765625, 3.749951171874997],
            [32.67695312500001, 3.76318359375],
            [32.73710937500002, 3.772705078125],
            [32.83808593750001, 3.798486328124994],
            [32.99726562500001, 3.880175781249989],
            [33.15410156250002, 3.774707031249989],
            [33.324316406250006, 3.754345703124997],
            [33.489355468750006, 3.755078125],
            [33.53955078125, 3.787109375],
            [33.568457031250006, 3.81171875],
            [33.74160156250002, 3.985253906249994],
            [33.97607421875, 4.22021484375],
            [34.13203125000001, 3.88916015625],
            [34.18574218750001, 3.869775390624994],
            [34.17822265625, 3.840869140624989],
            [34.1650390625, 3.81298828125],
            [34.26708984375, 3.733154296875],
            [34.39287109375002, 3.691503906249991],
            [34.43769531250001, 3.650585937499997],
            [34.44179687500002, 3.60625],
            [34.3994140625, 3.412695312499991],
            [34.4072265625, 3.357519531249991],
            [34.447851562500006, 3.163476562499994],
            [34.522558593750006, 3.119970703124991],
            [34.58916015625002, 2.924755859374997],
            [34.723242187500006, 2.841943359374994],
            [34.74248046875002, 2.818115234375],
            [34.7734375, 2.723437499999989],
            [34.814453125, 2.619824218749997],
            [34.8466796875, 2.595751953124989],
            [34.8662109375, 2.589697265624991],
            [34.90576171875, 2.4796875],
            [34.88300781250001, 2.417919921874997],
            [34.91396484375002, 2.230175781249997],
            [34.96406250000001, 2.062402343749994],
            [34.9775390625, 1.861914062499991],
            [34.97822265625001, 1.773632812499997],
            [34.97646484375002, 1.719628906249994],
            [34.96523437500002, 1.643359374999989],
            [34.94121093750002, 1.599267578124994],
            [34.89833984375002, 1.556494140624991],
            [34.85097656250002, 1.489013671875],
            [34.8095703125, 1.416699218749997],
            [34.78359375000002, 1.381152343749989],
            [34.803808593750006, 1.272851562499994],
            [34.79863281250002, 1.24453125],
            [34.78759765625, 1.230712890625],
            [34.72675781250001, 1.214257812499994],
            [34.64912109375001, 1.185302734375],
            [34.60195312500002, 1.156445312499997],
            [34.535253906250006, 1.1015625],
            [34.48173828125002, 1.042138671874994],
            [34.41083984375001, 0.867285156249991],
            [34.292578125, 0.73125],
            [34.272558593750006, 0.686425781249994],
            [34.16093750000002, 0.605175781249997],
            [34.11171875000002, 0.505126953125],
            [34.08056640625, 0.382470703124994],
            [34.037207031250006, 0.29453125],
            [33.94316406250002, 0.173779296874997],
            [33.921484375, -0.016992187500009],
            [33.924414062500006, -0.397851562500009],
            [33.9, -0.831640625],
            [33.90322265625002, -1.002050781250006],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Mozambique",
        sov_a3: "MOZ",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Mozambique",
        adm0_a3: "MOZ",
        geou_dif: 0,
        geounit: "Mozambique",
        gu_a3: "MOZ",
        su_dif: 0,
        subunit: "Mozambique",
        su_a3: "MOZ",
        brk_diff: 0,
        name: "Mozambique",
        name_long: "Mozambique",
        brk_a3: "MOZ",
        brk_name: "Mozambique",
        brk_group: null,
        abbrev: "Moz.",
        postal: "MZ",
        formal_en: "Republic of Mozambique",
        formal_fr: null,
        name_ciawf: "Mozambique",
        note_adm0: null,
        note_brk: null,
        name_sort: "Mozambique",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 2,
        mapcolor9: 1,
        mapcolor13: 4,
        pop_est: 30366036,
        pop_rank: 15,
        pop_year: 2019,
        gdp_md: 15291,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        fips_10: "MZ",
        iso_a2: "MZ",
        iso_a2_eh: "MZ",
        iso_a3: "MOZ",
        iso_a3_eh: "MOZ",
        iso_n3: "508",
        iso_n3_eh: "508",
        un_a3: "508",
        wb_a2: "MZ",
        wb_a3: "MOZ",
        woe_id: 23424902,
        woe_id_eh: 23424902,
        woe_note: "Exact WOE match as country",
        adm0_iso: "MOZ",
        adm0_diff: null,
        adm0_tlc: "MOZ",
        adm0_a3_us: "MOZ",
        adm0_a3_fr: "MOZ",
        adm0_a3_ru: "MOZ",
        adm0_a3_es: "MOZ",
        adm0_a3_cn: "MOZ",
        adm0_a3_tw: "MOZ",
        adm0_a3_in: "MOZ",
        adm0_a3_np: "MOZ",
        adm0_a3_pk: "MOZ",
        adm0_a3_de: "MOZ",
        adm0_a3_gb: "MOZ",
        adm0_a3_br: "MOZ",
        adm0_a3_il: "MOZ",
        adm0_a3_ps: "MOZ",
        adm0_a3_sa: "MOZ",
        adm0_a3_eg: "MOZ",
        adm0_a3_ma: "MOZ",
        adm0_a3_pt: "MOZ",
        adm0_a3_ar: "MOZ",
        adm0_a3_jp: "MOZ",
        adm0_a3_ko: "MOZ",
        adm0_a3_vn: "MOZ",
        adm0_a3_tr: "MOZ",
        adm0_a3_id: "MOZ",
        adm0_a3_pl: "MOZ",
        adm0_a3_gr: "MOZ",
        adm0_a3_it: "MOZ",
        adm0_a3_nl: "MOZ",
        adm0_a3_se: "MOZ",
        adm0_a3_bd: "MOZ",
        adm0_a3_ua: "MOZ",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Eastern Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 10,
        long_len: 10,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: 37.83789,
        label_y: -13.94323,
        ne_id: 1159321073,
        wikidataid: "Q1029",
        name_ar: "موزمبيق",
        name_bn: "মোজাম্বিক",
        name_de: "Mosambik",
        name_en: "Mozambique",
        name_es: "Mozambique",
        name_fa: "موزامبیک",
        name_fr: "Mozambique",
        name_el: "Μοζαμβίκη",
        name_he: "מוזמביק",
        name_hi: "मोज़ाम्बीक",
        name_hu: "Mozambik",
        name_id: "Mozambik",
        name_it: "Mozambico",
        name_ja: "モザンビーク",
        name_ko: "모잠비크",
        name_nl: "Mozambique",
        name_pl: "Mozambik",
        name_pt: "Moçambique",
        name_ru: "Мозамбик",
        name_sv: "Moçambique",
        name_tr: "Mozambik",
        name_uk: "Мозамбік",
        name_ur: "موزمبیق",
        name_vi: "Mozambique",
        name_zh: "莫桑比克",
        name_zht: "莫三比克",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "MOZ.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [31.287890625000017, -22.40205078125001],
            [31.429492187500017, -22.298828125],
            [31.57148437500001, -22.15351562500001],
            [31.737695312500023, -21.9833984375],
            [31.88593750000001, -21.83154296875],
            [32.01630859375001, -21.698046875],
            [32.19472656250002, -21.515429687500003],
            [32.37109375, -21.33486328125001],
            [32.41240234375002, -21.311816406250003],
            [32.429785156250006, -21.29707031250001],
            [32.353613281250006, -21.136523437500003],
            [32.476171875, -20.95009765625001],
            [32.48281250000002, -20.82890625],
            [32.477636718750006, -20.712988281250006],
            [32.49238281250001, -20.659765625],
            [32.529296875, -20.613085937500003],
            [32.67255859375001, -20.51611328125],
            [32.780859375, -20.36152343750001],
            [32.86962890625, -20.2171875],
            [32.992773437500006, -19.98486328125],
            [33.0048828125, -19.93017578125],
            [33.00673828125002, -19.873828125],
            [32.97265625, -19.79541015625],
            [32.89042968750002, -19.668066406250006],
            [32.83076171875001, -19.558203125],
            [32.77763671875002, -19.388769531250006],
            [32.83095703125002, -19.24140625000001],
            [32.85, -19.152441406250006],
            [32.84980468750001, -19.10439453125001],
            [32.826171875, -19.05878906250001],
            [32.766210937500006, -19.02431640625001],
            [32.71650390625001, -19.00185546875001],
            [32.69970703125, -18.94091796875],
            [32.69921875, -18.868457031250003],
            [32.72197265625002, -18.828417968750003],
            [32.8544921875, -18.763671875],
            [32.88457031250002, -18.728515625],
            [32.90029296875002, -18.6890625],
            [32.90166015625002, -18.632910156250006],
            [32.942480468750006, -18.49267578125],
            [32.99306640625002, -18.35957031250001],
            [32.99638671875002, -18.312597656250006],
            [32.978515625, -18.271484375],
            [32.96464843750002, -18.1962890625],
            [32.95556640625, -18.08291015625001],
            [32.9546875, -17.765429687500003],
            [32.98076171875002, -17.4375],
            [32.969335937500006, -17.2515625],
            [32.884375, -17.03779296875001],
            [32.87626953125002, -16.88359375],
            [32.93789062500002, -16.775976562500006],
            [32.94804687500002, -16.71230468750001],
            [32.902929687500006, -16.704199218750006],
            [32.81025390625001, -16.69765625],
            [32.741796875, -16.67763671875001],
            [32.635839843750006, -16.589453125],
            [32.45195312500002, -16.515722656250006],
            [32.243261718750006, -16.44873046875],
            [31.939843750000023, -16.428808593750006],
            [31.687597656250006, -16.214160156250003],
            [31.48984375, -16.1796875],
            [31.426171875000023, -16.15234375],
            [31.236230468750023, -16.02363281250001],
            [30.938769531250017, -16.01171875],
            [30.630175781250017, -15.999218750000011],
            [30.437792968750017, -15.995312500000011],
            [30.409375, -15.978222656250011],
            [30.39814453125001, -15.80078125],
            [30.39609375, -15.64306640625],
            [30.3798828125, -15.505859375],
            [30.3505859375, -15.349707031250006],
            [30.3056640625, -15.288867187500003],
            [30.25214843750001, -15.183203125],
            [30.225, -15.06689453125],
            [30.221777343750006, -15.010546875],
            [30.231835937500023, -14.990332031250006],
            [30.446093750000017, -14.907519531250003],
            [30.537695312500006, -14.866503906250003],
            [30.67333984375, -14.819140625],
            [30.915136718750006, -14.753320312500009],
            [31.130859375, -14.694628906250003],
            [31.328515625000023, -14.6376953125],
            [31.537890625000017, -14.5771484375],
            [31.623046875, -14.53671875],
            [31.728906250000023, -14.49609375],
            [31.98212890625001, -14.414453125],
            [32.05449218750002, -14.386523437500003],
            [32.19990234375001, -14.3408203125],
            [32.27285156250002, -14.323046875],
            [32.55322265625, -14.229589843750006],
            [32.87451171875, -14.122460937500009],
            [32.98710937500002, -14.0849609375],
            [33.201757812500006, -14.013378906250011],
            [33.24355468750002, -14.043066406250006],
            [33.38994140625002, -14.289453125],
            [33.50527343750002, -14.43408203125],
            [33.63642578125001, -14.568164062500003],
            [33.658300781250006, -14.561621093750006],
            [33.69609375000002, -14.5302734375],
            [33.76142578125001, -14.517285156250011],
            [33.969824218750006, -14.487109375],
            [34.049414062500006, -14.485253906250009],
            [34.10185546875002, -14.449316406250006],
            [34.20878906250002, -14.423730468750009],
            [34.33251953125, -14.40859375],
            [34.375, -14.4248046875],
            [34.50527343750002, -14.59814453125],
            [34.52412109375001, -14.730761718750003],
            [34.55117187500002, -14.92236328125],
            [34.5576171875, -15.015917968750003],
            [34.55546875000002, -15.140917968750003],
            [34.54082031250002, -15.297265625],
            [34.43496093750002, -15.477148437500006],
            [34.41474609375001, -15.566796875],
            [34.358007812500006, -15.705273437500011],
            [34.28300781250002, -15.7734375],
            [34.24609375, -15.829394531250003],
            [34.24824218750001, -15.8875],
            [34.28828125000001, -15.936132812500006],
            [34.3759765625, -16.023730468750003],
            [34.40302734375001, -16.08027343750001],
            [34.395117187500006, -16.130859375],
            [34.3955078125, -16.19921875],
            [34.41640625000002, -16.24677734375001],
            [34.44130859375002, -16.2744140625],
            [34.528125, -16.319140625],
            [34.61269531250002, -16.43154296875001],
            [34.7587890625, -16.56708984375001],
            [34.93339843750002, -16.760351562500006],
            [35.01533203125001, -16.81953125000001],
            [35.07988281250002, -16.83388671875001],
            [35.11210937500002, -16.898535156250006],
            [35.09423828125, -16.97382812500001],
            [35.0439453125, -17.016894531250003],
            [35.06464843750001, -17.07861328125],
            [35.09306640625002, -17.1109375],
            [35.124609375, -17.127246093750003],
            [35.20136718750001, -17.13105468750001],
            [35.272558593750006, -17.118457031250003],
            [35.29042968750002, -17.096972656250003],
            [35.28115234375002, -16.80781250000001],
            [35.22978515625002, -16.639257812500006],
            [35.178320312500006, -16.573339843750006],
            [35.16718750000001, -16.56025390625001],
            [35.18525390625001, -16.5048828125],
            [35.242773437500006, -16.375390625],
            [35.29150390625, -16.247167968750006],
            [35.32246093750001, -16.193164062500003],
            [35.358496093750006, -16.160546875],
            [35.59931640625001, -16.12587890625001],
            [35.70888671875002, -16.095800781250006],
            [35.75527343750002, -16.05830078125001],
            [35.79121093750001, -15.958691406250011],
            [35.819921875, -15.680371093750011],
            [35.83027343750001, -15.4189453125],
            [35.80537109375001, -15.265625],
            [35.839941406250006, -15.03466796875],
            [35.89277343750001, -14.891796875000011],
            [35.86669921875, -14.86376953125],
            [35.84716796875, -14.6708984375],
            [35.6904296875, -14.465527343750011],
            [35.48847656250001, -14.201074218750009],
            [35.37578125000002, -14.058691406250006],
            [35.24746093750002, -13.896875],
            [35.01386718750001, -13.643457031250009],
            [34.906835937500006, -13.551660156250009],
            [34.85048828125002, -13.516015625],
            [34.66162109375, -13.48671875],
            [34.61152343750001, -13.437890625],
            [34.56367187500001, -13.36015625],
            [34.54570312500002, -13.21630859375],
            [34.542578125, -13.108691406250003],
            [34.52128906250002, -12.92578125],
            [34.48291015625, -12.666796875],
            [34.4658203125, -12.590722656250009],
            [34.412109375, -12.395898437500009],
            [34.36083984375, -12.210546875],
            [34.35781250000002, -12.164746093750011],
            [34.3759765625, -12.120214843750006],
            [34.462890625, -11.983789062500009],
            [34.52480468750002, -11.887011718750003],
            [34.55390625000001, -11.834082031250006],
            [34.60625, -11.690039062500006],
            [34.61855468750002, -11.620214843750006],
            [34.65957031250002, -11.588671875],
            [34.82656250000002, -11.57568359375],
            [34.95947265625, -11.578125],
            [35.1826171875, -11.574804687500006],
            [35.41826171875002, -11.583203125000011],
            [35.45136718750001, -11.589550781250011],
            [35.50439453125, -11.604785156250003],
            [35.56435546875002, -11.60234375],
            [35.630957031250006, -11.58203125],
            [35.7046875, -11.532128906250009],
            [35.78544921875002, -11.452929687500003],
            [35.91132812500001, -11.4546875],
            [36.08222656250001, -11.537304687500011],
            [36.17548828125001, -11.609277343750009],
            [36.19130859375002, -11.670703125],
            [36.3056640625, -11.706347656250003],
            [36.518652343750006, -11.716210937500009],
            [36.673828125, -11.684277343750011],
            [36.77109375, -11.6103515625],
            [36.87265625, -11.5712890625],
            [36.97890625000002, -11.566992187500006],
            [37.059179687500006, -11.5921875],
            [37.113867187500006, -11.647167968750011],
            [37.218359375, -11.6865234375],
            [37.37285156250002, -11.71044921875],
            [37.54169921875001, -11.675097656250003],
            [37.72480468750001, -11.580664062500006],
            [37.82929687500001, -11.48193359375],
            [37.855078125, -11.379101562500011],
            [37.885351562500006, -11.316699218750003],
            [37.92021484375002, -11.294726562500003],
            [38.01728515625001, -11.282128906250009],
            [38.17656250000002, -11.278710937500009],
            [38.31513671875001, -11.311132812500006],
            [38.491796875, -11.413281250000011],
            [38.60332031250002, -11.3453125],
            [38.79472656250002, -11.22890625],
            [38.9875, -11.167285156250003],
            [39.170996093750006, -11.166894531250009],
            [39.32158203125002, -11.12255859375],
            [39.43916015625001, -11.034570312500009],
            [39.5634765625, -10.978515625],
            [39.694433593750006, -10.954785156250011],
            [39.81708984375001, -10.912402343750003],
            [39.98867187500002, -10.82080078125],
            [40.16621093750001, -10.6875],
            [40.34746093750002, -10.5515625],
            [40.46357421875001, -10.46435546875],
            [40.516699218750006, -10.5673828125],
            [40.61171875000002, -10.661523437500009],
            [40.55507812500002, -10.716210937500009],
            [40.48662109375002, -10.76513671875],
            [40.59716796875, -10.830664062500006],
            [40.51611328125, -10.929589843750009],
            [40.50625, -10.9984375],
            [40.52685546875, -11.025390625],
            [40.54453125, -11.065625],
            [40.49140625000001, -11.178906250000011],
            [40.420996093750006, -11.265625],
            [40.40283203125, -11.33203125],
            [40.46513671875002, -11.449414062500011],
            [40.43310546875, -11.657324218750006],
            [40.49355468750002, -11.844433593750011],
            [40.51044921875001, -11.9404296875],
            [40.53154296875002, -12.004589843750011],
            [40.50146484375, -12.119433593750003],
            [40.50917968750002, -12.312890625],
            [40.52314453125001, -12.392773437500011],
            [40.48710937500002, -12.4921875],
            [40.54833984375, -12.526562500000011],
            [40.58085937500002, -12.635546875],
            [40.57207031250002, -12.758398437500006],
            [40.553320312500006, -12.824609375],
            [40.44765625000002, -12.90478515625],
            [40.43515625, -12.9359375],
            [40.43681640625002, -12.983105468750011],
            [40.56875, -12.984667968750003],
            [40.5732421875, -13.057714843750006],
            [40.564453125, -13.115234375],
            [40.56953125000001, -13.2234375],
            [40.55195312500001, -13.29375],
            [40.58291015625002, -13.3740234375],
            [40.54511718750001, -13.462890625],
            [40.558203125, -13.531445312500011],
            [40.55986328125002, -13.620312500000011],
            [40.59052734375001, -13.845019531250003],
            [40.595703125, -14.122851562500003],
            [40.6025390625, -14.167382812500009],
            [40.649511718750006, -14.198828125],
            [40.715625, -14.214453125],
            [40.71308593750001, -14.290625],
            [40.63994140625002, -14.390039062500009],
            [40.63554687500002, -14.451855468750011],
            [40.64609375, -14.538671875],
            [40.726660156250006, -14.420703125],
            [40.775, -14.421289062500009],
            [40.81816406250002, -14.467578125],
            [40.812109375, -14.535546875],
            [40.82695312500002, -14.569042968750011],
            [40.82060546875002, -14.634960937500011],
            [40.84453125000002, -14.718652343750009],
            [40.83515625000001, -14.79150390625],
            [40.775976562500006, -14.842480468750011],
            [40.70068359375, -14.929785156250006],
            [40.68740234375002, -15.011621093750009],
            [40.6943359375, -15.065234375],
            [40.6421875, -15.082421875],
            [40.617773437500006, -15.115527343750003],
            [40.653125, -15.192675781250003],
            [40.650976562500006, -15.260937500000011],
            [40.55898437500002, -15.4734375],
            [40.31386718750002, -15.763964843750003],
            [40.2080078125, -15.867089843750009],
            [40.10878906250002, -15.979296875],
            [40.10888671875, -16.02529296875001],
            [40.09921875, -16.06533203125001],
            [39.98359375000001, -16.22548828125001],
            [39.85976562500002, -16.251757812500003],
            [39.79091796875002, -16.29453125],
            [39.84462890625002, -16.435644531250006],
            [39.76455078125002, -16.46816406250001],
            [39.62539062500002, -16.579394531250003],
            [39.242285156250006, -16.792578125],
            [39.181738281250006, -16.84199218750001],
            [39.084375, -16.97285156250001],
            [38.9560546875, -17.00458984375001],
            [38.884765625, -17.041601562500006],
            [38.75761718750002, -17.05517578125],
            [38.71328125000002, -17.045703125],
            [38.669921875, -17.05029296875],
            [38.63330078125, -17.07832031250001],
            [38.38076171875002, -17.17011718750001],
            [38.14492187500002, -17.242773437500006],
            [38.0869140625, -17.275976562500006],
            [38.04824218750002, -17.321386718750006],
            [37.839453125, -17.393164062500006],
            [37.512304687500006, -17.570703125],
            [37.24453125000002, -17.73994140625001],
            [37.05058593750002, -17.909277343750006],
            [36.99951171875, -17.93496093750001],
            [36.93935546875002, -17.993457031250003],
            [36.91923828125002, -18.080078125],
            [36.89960937500001, -18.129003906250006],
            [36.75615234375002, -18.30732421875001],
            [36.540136718750006, -18.518164062500006],
            [36.498046875, -18.57578125],
            [36.412207031250006, -18.69296875],
            [36.40371093750002, -18.76972656250001],
            [36.327246093750006, -18.79316406250001],
            [36.26289062500001, -18.71962890625001],
            [36.23564453125002, -18.861328125],
            [36.183203125, -18.871386718750003],
            [36.125, -18.842382812500006],
            [35.980078125, -18.9125],
            [35.85371093750001, -18.99335937500001],
            [35.65126953125002, -19.163867187500003],
            [35.365332031250006, -19.493945312500003],
            [34.947851562500006, -19.81269531250001],
            [34.89082031250001, -19.82177734375],
            [34.85234375000002, -19.82050781250001],
            [34.72099609375002, -19.709570312500006],
            [34.6494140625, -19.70136718750001],
            [34.713476562500006, -19.7671875],
            [34.75576171875002, -19.82197265625001],
            [34.74501953125002, -19.929492187500003],
            [34.75, -20.0908203125],
            [34.69814453125002, -20.404394531250006],
            [34.705078125, -20.473046875],
            [34.764746093750006, -20.56191406250001],
            [34.877050781250006, -20.67080078125001],
            [34.98232421875002, -20.80625],
            [35.11757812500002, -21.19521484375001],
            [35.128027343750006, -21.3953125],
            [35.267675781250006, -21.650976562500006],
            [35.27294921875, -21.76171875],
            [35.32929687500001, -22.037402343750003],
            [35.32558593750002, -22.260351562500006],
            [35.31572265625002, -22.396875],
            [35.38300781250001, -22.45458984375],
            [35.4078125, -22.40253906250001],
            [35.40087890625, -22.316210937500003],
            [35.41884765625002, -22.17763671875001],
            [35.45634765625002, -22.11591796875001],
            [35.49375, -22.12470703125001],
            [35.5048828125, -22.19013671875001],
            [35.53007812500002, -22.248144531250006],
            [35.54023437500001, -22.30263671875001],
            [35.5419921875, -22.3765625],
            [35.490234375, -22.65771484375],
            [35.50576171875002, -22.772070312500006],
            [35.57539062500001, -22.96308593750001],
            [35.49443359375002, -23.18515625],
            [35.376953125, -23.7078125],
            [35.37041015625002, -23.79824218750001],
            [35.39882812500002, -23.837695312500003],
            [35.46210937500001, -23.85107421875],
            [35.4853515625, -23.784472656250003],
            [35.5224609375, -23.784960937500003],
            [35.5419921875, -23.82441406250001],
            [35.48964843750002, -24.065527343750006],
            [35.438085937500006, -24.171191406250003],
            [35.2548828125, -24.430273437500006],
            [35.15595703125001, -24.54140625],
            [34.99208984375002, -24.65058593750001],
            [34.60732421875002, -24.8212890625],
            [33.836035156250006, -25.06796875],
            [33.53007812500002, -25.18886718750001],
            [33.34746093750002, -25.26093750000001],
            [32.96113281250001, -25.49042968750001],
            [32.79218750000001, -25.644335937500003],
            [32.72255859375002, -25.820898437500006],
            [32.655859375, -25.90175781250001],
            [32.590429687500006, -26.00410156250001],
            [32.6474609375, -26.09199218750001],
            [32.70351562500002, -26.158496093750003],
            [32.769628906250006, -26.20302734375001],
            [32.80390625000001, -26.24140625000001],
            [32.84882812500001, -26.26806640625],
            [32.89404296875, -26.1298828125],
            [32.91640625000002, -26.0869140625],
            [32.95488281250002, -26.08359375],
            [32.93359375, -26.25234375],
            [32.88916015625, -26.83046875],
            [32.88613281250002, -26.84931640625001],
            [32.77656250000001, -26.85097656250001],
            [32.58876953125002, -26.855761718750003],
            [32.47773437500001, -26.858496093750006],
            [32.353515625, -26.861621093750003],
            [32.19960937500002, -26.83349609375],
            [32.112890625, -26.839453125],
            [32.10595703125, -26.52001953125],
            [32.07792968750002, -26.449804687500006],
            [32.04833984375, -26.34716796875],
            [32.04140625000002, -26.28125],
            [32.05996093750002, -26.21503906250001],
            [32.06884765625, -26.11015625],
            [32.060546875, -26.018359375],
            [31.96845703125001, -25.972265625],
            [31.9482421875, -25.957617187500006],
            [31.928320312500006, -25.885351562500006],
            [31.920312500000023, -25.77392578125],
            [31.98457031250001, -25.631933593750006],
            [31.979394531250023, -25.359472656250006],
            [31.98701171875001, -25.263476562500003],
            [31.985742187500023, -25.073828125],
            [31.984375, -24.844042968750003],
            [31.983203125000017, -24.63828125],
            [31.98583984375, -24.46064453125001],
            [31.966601562500017, -24.37646484375],
            [31.950585937500023, -24.33027343750001],
            [31.908007812500017, -24.23623046875001],
            [31.858300781250023, -24.04023437500001],
            [31.799609375000017, -23.8921875],
            [31.724023437500023, -23.79453125],
            [31.7, -23.74306640625001],
            [31.675585937500017, -23.67421875],
            [31.604101562500006, -23.55292968750001],
            [31.54560546875001, -23.48232421875001],
            [31.529687500000023, -23.42578125],
            [31.53173828125, -23.27949218750001],
            [31.466699218750023, -23.016699218750006],
            [31.419335937500023, -22.82509765625001],
            [31.348046875000023, -22.617578125],
            [31.300195312500023, -22.478613281250006],
            [31.29316406250001, -22.4546875],
            [31.287890625000017, -22.40205078125001],
          ],
          [
            [34.6416015625, -12.013671875],
            [34.62421875000001, -11.984765625],
            [34.59140625, -11.97109375],
            [34.55400390625002, -11.982226562500003],
            [34.541601562500006, -12.018652343750006],
            [34.58046875000002, -12.065820312500009],
            [34.62177734375001, -12.066601562500011],
            [34.6416015625, -12.013671875],
          ],
          [
            [34.719335937500006, -12.110644531250003],
            [34.74599609375002, -12.08837890625],
            [34.75625, -12.05908203125],
            [34.755957031250006, -12.03076171875],
            [34.73896484375001, -12.013085937500009],
            [34.714941406250006, -12.002734375],
            [34.67988281250001, -12.008886718750006],
            [34.66748046875, -12.047558593750011],
            [34.662109375, -12.100781250000011],
            [34.684179687500006, -12.11865234375],
            [34.719335937500006, -12.110644531250003],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 4,
        sovereignt: "eSwatini",
        sov_a3: "SWZ",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "eSwatini",
        adm0_a3: "SWZ",
        geou_dif: 0,
        geounit: "eSwatini",
        gu_a3: "SWZ",
        su_dif: 0,
        subunit: "eSwatini",
        su_a3: "SWZ",
        brk_diff: 0,
        name: "eSwatini",
        name_long: "Kingdom of eSwatini",
        brk_a3: "SWZ",
        brk_name: "eSwatini",
        brk_group: null,
        abbrev: "eSw.",
        postal: "ES",
        formal_en: "Kingdom of eSwatini",
        formal_fr: null,
        name_ciawf: "eSwatini",
        note_adm0: null,
        note_brk: null,
        name_sort: "eSwatini",
        name_alt: "Swaziland",
        mapcolor7: 3,
        mapcolor8: 6,
        mapcolor9: 2,
        mapcolor13: 5,
        pop_est: 1148130,
        pop_rank: 12,
        pop_year: 2019,
        gdp_md: 4471,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        fips_10: "WZ",
        iso_a2: "SZ",
        iso_a2_eh: "SZ",
        iso_a3: "SWZ",
        iso_a3_eh: "SWZ",
        iso_n3: "748",
        iso_n3_eh: "748",
        un_a3: "748",
        wb_a2: "SZ",
        wb_a3: "SWZ",
        woe_id: 23424993,
        woe_id_eh: 23424993,
        woe_note: "Exact WOE match as country",
        adm0_iso: "SWZ",
        adm0_diff: null,
        adm0_tlc: "SWZ",
        adm0_a3_us: "SWZ",
        adm0_a3_fr: "SWZ",
        adm0_a3_ru: "SWZ",
        adm0_a3_es: "SWZ",
        adm0_a3_cn: "SWZ",
        adm0_a3_tw: "SWZ",
        adm0_a3_in: "SWZ",
        adm0_a3_np: "SWZ",
        adm0_a3_pk: "SWZ",
        adm0_a3_de: "SWZ",
        adm0_a3_gb: "SWZ",
        adm0_a3_br: "SWZ",
        adm0_a3_il: "SWZ",
        adm0_a3_ps: "SWZ",
        adm0_a3_sa: "SWZ",
        adm0_a3_eg: "SWZ",
        adm0_a3_ma: "SWZ",
        adm0_a3_pt: "SWZ",
        adm0_a3_ar: "SWZ",
        adm0_a3_jp: "SWZ",
        adm0_a3_ko: "SWZ",
        adm0_a3_vn: "SWZ",
        adm0_a3_tr: "SWZ",
        adm0_a3_id: "SWZ",
        adm0_a3_pl: "SWZ",
        adm0_a3_gr: "SWZ",
        adm0_a3_it: "SWZ",
        adm0_a3_nl: "SWZ",
        adm0_a3_se: "SWZ",
        adm0_a3_bd: "SWZ",
        adm0_a3_ua: "SWZ",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Southern Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 8,
        long_len: 19,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: 31.467264,
        label_y: -26.533676,
        ne_id: 1159321289,
        wikidataid: "Q1050",
        name_ar: "إسواتيني",
        name_bn: "ইসোয়াতিনি",
        name_de: "Eswatini",
        name_en: "Eswatini",
        name_es: "Suazilandia",
        name_fa: "اسواتینی",
        name_fr: "Eswatini",
        name_el: "Εσουατίνι",
        name_he: "אסוואטיני",
        name_hi: "एस्वातीनी",
        name_hu: "Szváziföld",
        name_id: "Eswatini",
        name_it: "eSwatini",
        name_ja: "エスワティニ",
        name_ko: "에스와티니",
        name_nl: "Swaziland",
        name_pl: "Eswatini",
        name_pt: "Essuatíni",
        name_ru: "Эсватини",
        name_sv: "Swaziland",
        name_tr: "Esvatini",
        name_uk: "Есватіні",
        name_ur: "اسواتینی",
        name_vi: "Eswatini",
        name_zh: "斯威士兰",
        name_zht: "史瓦帝尼",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "SWZ.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [31.9482421875, -25.957617187500006],
            [31.96845703125001, -25.972265625],
            [32.060546875, -26.018359375],
            [32.06884765625, -26.11015625],
            [32.05996093750002, -26.21503906250001],
            [32.04140625000002, -26.28125],
            [32.04833984375, -26.34716796875],
            [32.07792968750002, -26.449804687500006],
            [32.10595703125, -26.52001953125],
            [32.112890625, -26.839453125],
            [32.081640625, -26.824804687500006],
            [32.02480468750002, -26.811132812500006],
            [31.994726562500006, -26.817480468750006],
            [31.967187500000023, -26.96064453125001],
            [31.946093750000017, -27.173632812500003],
            [31.958398437500023, -27.30585937500001],
            [31.742578125000023, -27.30996093750001],
            [31.469531250000017, -27.295507812500006],
            [31.274023437500006, -27.238378906250006],
            [31.063378906250023, -27.1123046875],
            [30.938085937500006, -26.915820312500003],
            [30.88330078125, -26.79238281250001],
            [30.806738281250006, -26.785253906250006],
            [30.794335937500023, -26.764257812500006],
            [30.7875, -26.613671875],
            [30.7890625, -26.45546875],
            [30.803320312500006, -26.41347656250001],
            [30.945214843750023, -26.21875],
            [31.033300781250006, -26.097753906250006],
            [31.08808593750001, -25.98066406250001],
            [31.207324218750017, -25.843359375],
            [31.33515625000001, -25.75556640625001],
            [31.382617187500017, -25.74296875],
            [31.415136718750006, -25.74658203125],
            [31.640429687500017, -25.867285156250006],
            [31.871484375000023, -25.98164062500001],
            [31.921679687500017, -25.96875],
            [31.9482421875, -25.957617187500006],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 6,
        sovereignt: "Burundi",
        sov_a3: "BDI",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Burundi",
        adm0_a3: "BDI",
        geou_dif: 0,
        geounit: "Burundi",
        gu_a3: "BDI",
        su_dif: 0,
        subunit: "Burundi",
        su_a3: "BDI",
        brk_diff: 0,
        name: "Burundi",
        name_long: "Burundi",
        brk_a3: "BDI",
        brk_name: "Burundi",
        brk_group: null,
        abbrev: "Bur.",
        postal: "BI",
        formal_en: "Republic of Burundi",
        formal_fr: null,
        name_ciawf: "Burundi",
        note_adm0: null,
        note_brk: null,
        name_sort: "Burundi",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 2,
        mapcolor9: 5,
        mapcolor13: 8,
        pop_est: 11530580,
        pop_rank: 14,
        pop_year: 2019,
        gdp_md: 3012,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        fips_10: "BY",
        iso_a2: "BI",
        iso_a2_eh: "BI",
        iso_a3: "BDI",
        iso_a3_eh: "BDI",
        iso_n3: "108",
        iso_n3_eh: "108",
        un_a3: "108",
        wb_a2: "BI",
        wb_a3: "BDI",
        woe_id: 23424774,
        woe_id_eh: 23424774,
        woe_note: "Exact WOE match as country",
        adm0_iso: "BDI",
        adm0_diff: null,
        adm0_tlc: "BDI",
        adm0_a3_us: "BDI",
        adm0_a3_fr: "BDI",
        adm0_a3_ru: "BDI",
        adm0_a3_es: "BDI",
        adm0_a3_cn: "BDI",
        adm0_a3_tw: "BDI",
        adm0_a3_in: "BDI",
        adm0_a3_np: "BDI",
        adm0_a3_pk: "BDI",
        adm0_a3_de: "BDI",
        adm0_a3_gb: "BDI",
        adm0_a3_br: "BDI",
        adm0_a3_il: "BDI",
        adm0_a3_ps: "BDI",
        adm0_a3_sa: "BDI",
        adm0_a3_eg: "BDI",
        adm0_a3_ma: "BDI",
        adm0_a3_pt: "BDI",
        adm0_a3_ar: "BDI",
        adm0_a3_jp: "BDI",
        adm0_a3_ko: "BDI",
        adm0_a3_vn: "BDI",
        adm0_a3_tr: "BDI",
        adm0_a3_id: "BDI",
        adm0_a3_pl: "BDI",
        adm0_a3_gr: "BDI",
        adm0_a3_it: "BDI",
        adm0_a3_nl: "BDI",
        adm0_a3_se: "BDI",
        adm0_a3_bd: "BDI",
        adm0_a3_ua: "BDI",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Eastern Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: 29.917086,
        label_y: -3.332836,
        ne_id: 1159320387,
        wikidataid: "Q967",
        name_ar: "بوروندي",
        name_bn: "বুরুন্ডি",
        name_de: "Burundi",
        name_en: "Burundi",
        name_es: "Burundi",
        name_fa: "بوروندی",
        name_fr: "Burundi",
        name_el: "Μπουρούντι",
        name_he: "בורונדי",
        name_hi: "बुरुण्डी",
        name_hu: "Burundi",
        name_id: "Burundi",
        name_it: "Burundi",
        name_ja: "ブルンジ",
        name_ko: "부룬디",
        name_nl: "Burundi",
        name_pl: "Burundi",
        name_pt: "Burundi",
        name_ru: "Бурунди",
        name_sv: "Burundi",
        name_tr: "Burundi",
        name_uk: "Бурунді",
        name_ur: "برونڈی",
        name_vi: "Burundi",
        name_zh: "布隆迪",
        name_zht: "蒲隆地",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "BDI.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [30.553613281250023, -2.400097656250011],
            [30.53369140625, -2.42626953125],
            [30.441992187500006, -2.613476562500011],
            [30.424218750000023, -2.6416015625],
            [30.434375, -2.658886718750011],
            [30.47333984375001, -2.6943359375],
            [30.450488281250017, -2.753222656250003],
            [30.441308593750023, -2.76904296875],
            [30.42402343750001, -2.824023437500003],
            [30.433496093750023, -2.87451171875],
            [30.45556640625, -2.893164062500006],
            [30.515039062500023, -2.917578125],
            [30.604296875000017, -2.935253906250011],
            [30.70947265625, -2.977246093750011],
            [30.7802734375, -2.98486328125],
            [30.796875, -3.01513671875],
            [30.793554687500006, -3.0693359375],
            [30.811132812500006, -3.116406250000011],
            [30.811425781250023, -3.200585937500009],
            [30.79023437500001, -3.274609375000011],
            [30.68183593750001, -3.309375],
            [30.626074218750006, -3.347363281250011],
            [30.6109375, -3.366406250000011],
            [30.624609375, -3.388671875],
            [30.631933593750006, -3.418652343750011],
            [30.529882812500006, -3.492480468750003],
            [30.425, -3.5888671875],
            [30.4, -3.65390625],
            [30.37910156250001, -3.730761718750003],
            [30.348437500000017, -3.77978515625],
            [30.2685546875, -3.850488281250009],
            [30.187109375, -3.992871093750011],
            [30.14716796875001, -4.085351562500009],
            [29.947265625, -4.307324218750011],
            [29.76953125, -4.418066406250006],
            [29.7177734375, -4.455859375],
            [29.403222656250023, -4.449316406250006],
            [29.379199218750017, -4.299707031250009],
            [29.331347656250017, -4.095410156250011],
            [29.223242187500006, -3.910839843750011],
            [29.211816406250023, -3.833789062500003],
            [29.216796875, -3.684960937500009],
            [29.217187500000023, -3.475683593750006],
            [29.210058593750006, -3.36328125],
            [29.212304687500023, -3.28125],
            [29.22607421875, -3.138671875],
            [29.224414062500017, -3.053515625],
            [29.153222656250023, -2.955273437500011],
            [29.064746093750017, -2.850781250000011],
            [29.0166015625, -2.799609375],
            [29.01416015625, -2.75830078125],
            [29.01435546875001, -2.72021484375],
            [29.028613281250017, -2.66455078125],
            [29.06318359375001, -2.6025390625],
            [29.10205078125, -2.595703125],
            [29.197558593750017, -2.620312500000011],
            [29.29707031250001, -2.673046875000011],
            [29.34980468750001, -2.79150390625],
            [29.390234375, -2.80859375],
            [29.463671875000017, -2.808398437500003],
            [29.6513671875, -2.792773437500003],
            [29.698046875000017, -2.794726562500003],
            [29.78339843750001, -2.76640625],
            [29.8681640625, -2.71640625],
            [29.892578125, -2.664648437500006],
            [29.912402343750017, -2.548632812500003],
            [29.93017578125, -2.339550781250011],
            [29.973437500000017, -2.337109375000011],
            [30.091894531250006, -2.411523437500009],
            [30.117285156250006, -2.416601562500006],
            [30.14228515625001, -2.413964843750009],
            [30.18330078125001, -2.377050781250006],
            [30.233789062500023, -2.347070312500009],
            [30.27099609375, -2.347851562500011],
            [30.408496093750017, -2.31298828125],
            [30.482226562500017, -2.376074218750006],
            [30.52890625, -2.395605468750006],
            [30.553613281250023, -2.400097656250011],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 4,
        sovereignt: "Gabon",
        sov_a3: "GAB",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Gabon",
        adm0_a3: "GAB",
        geou_dif: 0,
        geounit: "Gabon",
        gu_a3: "GAB",
        su_dif: 0,
        subunit: "Gabon",
        su_a3: "GAB",
        brk_diff: 0,
        name: "Gabon",
        name_long: "Gabon",
        brk_a3: "GAB",
        brk_name: "Gabon",
        brk_group: null,
        abbrev: "Gabon",
        postal: "GA",
        formal_en: "Gabonese Republic",
        formal_fr: null,
        name_ciawf: "Gabon",
        note_adm0: null,
        note_brk: null,
        name_sort: "Gabon",
        name_alt: null,
        mapcolor7: 6,
        mapcolor8: 2,
        mapcolor9: 5,
        mapcolor13: 5,
        pop_est: 2172579,
        pop_rank: 12,
        pop_year: 2019,
        gdp_md: 16874,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        fips_10: "GB",
        iso_a2: "GA",
        iso_a2_eh: "GA",
        iso_a3: "GAB",
        iso_a3_eh: "GAB",
        iso_n3: "266",
        iso_n3_eh: "266",
        un_a3: "266",
        wb_a2: "GA",
        wb_a3: "GAB",
        woe_id: 23424822,
        woe_id_eh: 23424822,
        woe_note: "Exact WOE match as country",
        adm0_iso: "GAB",
        adm0_diff: null,
        adm0_tlc: "GAB",
        adm0_a3_us: "GAB",
        adm0_a3_fr: "GAB",
        adm0_a3_ru: "GAB",
        adm0_a3_es: "GAB",
        adm0_a3_cn: "GAB",
        adm0_a3_tw: "GAB",
        adm0_a3_in: "GAB",
        adm0_a3_np: "GAB",
        adm0_a3_pk: "GAB",
        adm0_a3_de: "GAB",
        adm0_a3_gb: "GAB",
        adm0_a3_br: "GAB",
        adm0_a3_il: "GAB",
        adm0_a3_ps: "GAB",
        adm0_a3_sa: "GAB",
        adm0_a3_eg: "GAB",
        adm0_a3_ma: "GAB",
        adm0_a3_pt: "GAB",
        adm0_a3_ar: "GAB",
        adm0_a3_jp: "GAB",
        adm0_a3_ko: "GAB",
        adm0_a3_vn: "GAB",
        adm0_a3_tr: "GAB",
        adm0_a3_id: "GAB",
        adm0_a3_pl: "GAB",
        adm0_a3_gr: "GAB",
        adm0_a3_it: "GAB",
        adm0_a3_nl: "GAB",
        adm0_a3_se: "GAB",
        adm0_a3_bd: "GAB",
        adm0_a3_ua: "GAB",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Middle Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 5,
        long_len: 5,
        abbrev_len: 5,
        tiny: 3,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: 11.835939,
        label_y: -0.437739,
        ne_id: 1159320693,
        wikidataid: "Q1000",
        name_ar: "الغابون",
        name_bn: "গ্যাবন",
        name_de: "Gabun",
        name_en: "Gabon",
        name_es: "Gabón",
        name_fa: "گابن",
        name_fr: "Gabon",
        name_el: "Γκαμπόν",
        name_he: "גבון",
        name_hi: "गबॉन",
        name_hu: "Gabon",
        name_id: "Gabon",
        name_it: "Gabon",
        name_ja: "ガボン",
        name_ko: "가봉",
        name_nl: "Gabon",
        name_pl: "Gabon",
        name_pt: "Gabão",
        name_ru: "Габон",
        name_sv: "Gabon",
        name_tr: "Gabon",
        name_uk: "Габон",
        name_ur: "گیبون",
        name_vi: "Gabon",
        name_zh: "加蓬",
        name_zht: "加彭",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "GAB.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [13.293554687500006, 2.161572265624997],
            [13.288671875, 2.091699218749994],
            [13.20947265625, 1.92041015625],
            [13.172167968750017, 1.78857421875],
            [13.162695312500006, 1.648095703124994],
            [13.1845703125, 1.535058593749994],
            [13.222753906250006, 1.45458984375],
            [13.247363281250017, 1.36669921875],
            [13.228320312500017, 1.305419921875],
            [13.190136718750011, 1.279248046874997],
            [13.21630859375, 1.2484375],
            [13.274121093750011, 1.241015624999989],
            [13.372363281250017, 1.267773437499997],
            [13.523339843750023, 1.314599609374994],
            [13.72119140625, 1.382275390624997],
            [13.851367187500017, 1.41875],
            [14.066210937500017, 1.395898437499994],
            [14.180859375000011, 1.370214843749991],
            [14.23974609375, 1.322558593749989],
            [14.303027343750017, 1.120849609375],
            [14.33447265625, 1.090234375],
            [14.386425781250011, 1.004443359374989],
            [14.429882812500011, 0.901464843749991],
            [14.439160156250011, 0.84912109375],
            [14.434472656250023, 0.811474609374997],
            [14.390625, 0.755712890624991],
            [14.341503906250011, 0.673828125],
            [14.32421875, 0.62421875],
            [14.283105468750023, 0.587451171874989],
            [14.23095703125, 0.551123046874991],
            [14.0875, 0.536572265624997],
            [14.065527343750006, 0.514990234374991],
            [14.025292968750023, 0.427734375],
            [13.949609375000023, 0.353808593749989],
            [13.915136718750006, 0.283984374999989],
            [13.884570312500017, 0.190820312499994],
            [13.890625, 0.075292968749991],
            [13.87548828125, -0.0908203125],
            [13.860058593750011, -0.203320312500011],
            [13.898046875, -0.242578125],
            [14.069433593750006, -0.270117187500006],
            [14.102832031250017, -0.292382812500009],
            [14.148339843750023, -0.361914062500006],
            [14.206738281250011, -0.42734375],
            [14.36376953125, -0.468554687500003],
            [14.424707031250023, -0.518652343750006],
            [14.47412109375, -0.573437500000011],
            [14.480566406250006, -0.618359375000011],
            [14.444921875, -0.798828125],
            [14.41064453125, -0.972070312500009],
            [14.424023437500011, -1.10390625],
            [14.436914062500023, -1.229785156250003],
            [14.45556640625, -1.413183593750006],
            [14.447265625, -1.525097656250011],
            [14.402929687500006, -1.593359375],
            [14.402929687500006, -1.64697265625],
            [14.423242187500023, -1.711523437500006],
            [14.383984375000011, -1.890039062500009],
            [14.358593750000011, -1.920214843750003],
            [14.288378906250017, -1.953515625],
            [14.25146484375, -2.00146484375],
            [14.239648437500023, -2.076757812500006],
            [14.201757812500006, -2.179882812500011],
            [14.162890625000017, -2.217578125],
            [14.162890625000017, -2.265527343750009],
            [14.200390625000011, -2.300585937500003],
            [14.199804687500006, -2.354199218750011],
            [14.129785156250023, -2.41796875],
            [14.08740234375, -2.466894531250006],
            [13.993847656250011, -2.490625],
            [13.886914062500011, -2.465429687500006],
            [13.86181640625, -2.429882812500011],
            [13.8876953125, -2.37451171875],
            [13.878515625, -2.330175781250006],
            [13.841601562500017, -2.28369140625],
            [13.784375, -2.163769531250011],
            [13.733789062500023, -2.138476562500003],
            [13.70556640625, -2.1875],
            [13.618554687500023, -2.278613281250003],
            [13.464941406250006, -2.395410156250009],
            [13.357324218750023, -2.40478515625],
            [13.158593750000023, -2.369140625],
            [12.991992187500017, -2.313378906250009],
            [12.91357421875, -2.17626953125],
            [12.864453125000011, -2.06328125],
            [12.793554687500006, -1.931835937500011],
            [12.713671875000017, -1.869433593750003],
            [12.62841796875, -1.82958984375],
            [12.590429687500006, -1.826855468750011],
            [12.468652343750023, -1.9],
            [12.43212890625, -1.928906250000011],
            [12.432421875000017, -1.990332031250006],
            [12.44375, -2.047558593750011],
            [12.462597656250011, -2.075292968750006],
            [12.478515625, -2.112011718750011],
            [12.475683593750006, -2.169238281250003],
            [12.453808593750011, -2.24560546875],
            [12.446386718750006, -2.329980468750009],
            [12.064453125, -2.41259765625],
            [11.998242187500011, -2.3828125],
            [11.950292968750006, -2.344824218750006],
            [11.892382812500017, -2.351464843750009],
            [11.726757812500011, -2.394726562500011],
            [11.665917968750023, -2.364550781250003],
            [11.60546875, -2.342578125],
            [11.577734375, -2.3609375],
            [11.5751953125, -2.397070312500006],
            [11.603417968750023, -2.595410156250011],
            [11.594531250000017, -2.670996093750006],
            [11.55712890625, -2.769628906250006],
            [11.537792968750011, -2.83671875],
            [11.639062500000023, -2.855371093750009],
            [11.675683593750023, -2.886621093750009],
            [11.711328125000023, -2.9365234375],
            [11.760156250000023, -2.983105468750011],
            [11.763476562500017, -3.01123046875],
            [11.7080078125, -3.063085937500006],
            [11.6890625, -3.126953125],
            [11.715429687500006, -3.176953125000011],
            [11.784375, -3.229101562500006],
            [11.885058593750017, -3.283203125],
            [11.934179687500006, -3.318554687500011],
            [11.929296875, -3.350976562500009],
            [11.8828125, -3.420214843750003],
            [11.86474609375, -3.478613281250006],
            [11.832910156250023, -3.531445312500011],
            [11.839453125, -3.580078125],
            [11.884765625, -3.625390625],
            [11.8798828125, -3.665917968750009],
            [11.84912109375, -3.696679687500009],
            [11.786425781250017, -3.690234375],
            [11.7333984375, -3.694531250000011],
            [11.685742187500011, -3.68203125],
            [11.536816406250011, -3.525],
            [11.504296875000023, -3.5203125],
            [11.288281250000011, -3.64111328125],
            [11.234472656250006, -3.690820312500009],
            [11.190039062500006, -3.762011718750003],
            [11.130175781250017, -3.916308593750003],
            [11.032031250000017, -3.826464843750003],
            [10.947265625, -3.662109375],
            [10.848535156250023, -3.561328125],
            [10.640722656250006, -3.398046875],
            [10.58544921875, -3.278027343750011],
            [10.34765625, -3.013085937500009],
            [10.006152343750017, -2.748339843750003],
            [9.759472656250011, -2.5185546875],
            [9.722070312500023, -2.467578125],
            [9.763671875, -2.473828125000011],
            [10.001953125, -2.58837890625],
            [10.034472656250017, -2.575585937500009],
            [10.06201171875, -2.549902343750006],
            [9.959082031250006, -2.48984375],
            [9.86083984375, -2.442578125000011],
            [9.768652343750006, -2.4130859375],
            [9.676367187500006, -2.415625],
            [9.624609375, -2.367089843750009],
            [9.591015625000011, -2.293164062500011],
            [9.574023437500017, -2.22998046875],
            [9.533203125, -2.163867187500003],
            [9.402246093750023, -2.027636718750003],
            [9.370507812500023, -1.975],
            [9.298925781250006, -1.903027343750011],
            [9.342480468750011, -1.893652343750006],
            [9.482812500000023, -1.962304687500009],
            [9.495312500000011, -1.934960937500009],
            [9.483203125000017, -1.894628906250006],
            [9.342187500000023, -1.829394531250003],
            [9.265625, -1.825097656250009],
            [9.247949218750023, -1.779296875],
            [9.258398437500006, -1.726269531250011],
            [9.157519531250017, -1.527734375],
            [9.052832031250006, -1.379101562500011],
            [9.036328125000011, -1.308886718750003],
            [9.31884765625, -1.632031250000011],
            [9.356640625000011, -1.637597656250009],
            [9.406347656250006, -1.634570312500003],
            [9.523339843750023, -1.598339843750011],
            [9.501074218750006, -1.55517578125],
            [9.448339843750006, -1.508886718750006],
            [9.397167968750011, -1.530175781250009],
            [9.330664062500006, -1.534570312500009],
            [9.295800781250023, -1.515234375],
            [9.280175781250023, -1.48193359375],
            [9.3466796875, -1.325],
            [9.31787109375, -1.332910156250009],
            [9.296679687500017, -1.3609375],
            [9.260156250000023, -1.374218750000011],
            [9.203808593750011, -1.382421875],
            [9.064648437500011, -1.29833984375],
            [8.94189453125, -1.071484375000011],
            [8.909375, -1.025],
            [8.8765625, -0.94609375],
            [8.84423828125, -0.91357421875],
            [8.703125, -0.591015625000011],
            [8.757226562500023, -0.614941406250011],
            [8.821386718750006, -0.708398437500009],
            [8.946386718750006, -0.688769531250003],
            [8.995214843750006, -0.634667968750009],
            [9.037890625000017, -0.63671875],
            [9.08154296875, -0.624316406250003],
            [9.136523437500017, -0.573339843750006],
            [9.296679687500017, -0.351269531250011],
            [9.339062500000011, -0.058251953125009],
            [9.325292968750006, 0.115820312499991],
            [9.301855468750006, 0.288525390624997],
            [9.354882812500023, 0.343603515624991],
            [9.375781250000017, 0.307226562499991],
            [9.386132812500023, 0.245898437499989],
            [9.4111328125, 0.200439453125],
            [9.468164062500023, 0.159765625],
            [9.574316406250006, 0.14892578125],
            [9.738378906250006, 0.0849609375],
            [9.796777343750023, 0.044238281249989],
            [9.812695312500011, 0.125585937499991],
            [10.00146484375, 0.194970703124994],
            [9.944433593750006, 0.219873046874994],
            [9.776660156250017, 0.192480468749991],
            [9.546484375, 0.295947265624989],
            [9.470117187500023, 0.361914062499991],
            [9.398828125000023, 0.48671875],
            [9.324804687500006, 0.552099609374991],
            [9.329980468750023, 0.61083984375],
            [9.495312500000011, 0.664843749999989],
            [9.538964843750023, 0.65869140625],
            [9.556640625, 0.594189453124997],
            [9.60107421875, 0.567724609374991],
            [9.617968750000017, 0.576513671874991],
            [9.625292968750017, 0.631640624999989],
            [9.625878906250023, 0.779443359374994],
            [9.575390625000011, 0.991308593749991],
            [9.5908203125, 1.031982421875],
            [9.636132812500023, 1.046679687499989],
            [9.676464843750011, 1.07470703125],
            [9.70458984375, 1.079980468749994],
            [9.760546875000017, 1.07470703125],
            [9.788671875, 1.025683593749989],
            [9.803906250000011, 0.998730468749997],
            [9.8603515625, 0.986230468749994],
            [9.90673828125, 0.960107421874994],
            [9.946679687500023, 0.967138671874991],
            [9.979785156250017, 0.997705078124994],
            [10.028515625000011, 1.004003906249991],
            [10.178906250000011, 1.003564453124994],
            [10.3154296875, 1.003076171874994],
            [10.587207031250017, 1.002148437499997],
            [10.85888671875, 1.001269531249989],
            [11.130664062500017, 1.000390625],
            [11.335351562500023, 0.999707031249997],
            [11.334667968750011, 1.120751953124994],
            [11.33359375, 1.3076171875],
            [11.332324218750017, 1.528369140624989],
            [11.331152343750006, 1.740185546874997],
            [11.330078125, 1.935888671874991],
            [11.328710937500006, 2.167431640624997],
            [11.339941406250006, 2.233837890624997],
            [11.353320312500017, 2.261425781249997],
            [11.348437500000017, 2.299707031249994],
            [11.558984375000023, 2.302197265624997],
            [11.939746093750017, 2.28515625],
            [12.106152343750011, 2.2875],
            [12.153417968750006, 2.284375],
            [12.361328125, 2.295996093749991],
            [12.52978515625, 2.281347656249991],
            [12.601367187500017, 2.265039062499994],
            [12.665722656250011, 2.256787109374997],
            [12.867480468750017, 2.246777343749997],
            [13.130859375, 2.259423828124994],
            [13.2203125, 2.256445312499991],
            [13.269921875000023, 2.22421875],
            [13.293554687500006, 2.161572265624997],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Cameroon",
        sov_a3: "CMR",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Cameroon",
        adm0_a3: "CMR",
        geou_dif: 0,
        geounit: "Cameroon",
        gu_a3: "CMR",
        su_dif: 0,
        subunit: "Cameroon",
        su_a3: "CMR",
        brk_diff: 0,
        name: "Cameroon",
        name_long: "Cameroon",
        brk_a3: "CMR",
        brk_name: "Cameroon",
        brk_group: null,
        abbrev: "Cam.",
        postal: "CM",
        formal_en: "Republic of Cameroon",
        formal_fr: null,
        name_ciawf: "Cameroon",
        note_adm0: null,
        note_brk: null,
        name_sort: "Cameroon",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 4,
        mapcolor9: 1,
        mapcolor13: 3,
        pop_est: 25876380,
        pop_rank: 15,
        pop_year: 2019,
        gdp_md: 39007,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        fips_10: "CM",
        iso_a2: "CM",
        iso_a2_eh: "CM",
        iso_a3: "CMR",
        iso_a3_eh: "CMR",
        iso_n3: "120",
        iso_n3_eh: "120",
        un_a3: "120",
        wb_a2: "CM",
        wb_a3: "CMR",
        woe_id: 23424785,
        woe_id_eh: 23424785,
        woe_note: "Exact WOE match as country",
        adm0_iso: "CMR",
        adm0_diff: null,
        adm0_tlc: "CMR",
        adm0_a3_us: "CMR",
        adm0_a3_fr: "CMR",
        adm0_a3_ru: "CMR",
        adm0_a3_es: "CMR",
        adm0_a3_cn: "CMR",
        adm0_a3_tw: "CMR",
        adm0_a3_in: "CMR",
        adm0_a3_np: "CMR",
        adm0_a3_pk: "CMR",
        adm0_a3_de: "CMR",
        adm0_a3_gb: "CMR",
        adm0_a3_br: "CMR",
        adm0_a3_il: "CMR",
        adm0_a3_ps: "CMR",
        adm0_a3_sa: "CMR",
        adm0_a3_eg: "CMR",
        adm0_a3_ma: "CMR",
        adm0_a3_pt: "CMR",
        adm0_a3_ar: "CMR",
        adm0_a3_jp: "CMR",
        adm0_a3_ko: "CMR",
        adm0_a3_vn: "CMR",
        adm0_a3_tr: "CMR",
        adm0_a3_id: "CMR",
        adm0_a3_pl: "CMR",
        adm0_a3_gr: "CMR",
        adm0_a3_it: "CMR",
        adm0_a3_nl: "CMR",
        adm0_a3_se: "CMR",
        adm0_a3_bd: "CMR",
        adm0_a3_ua: "CMR",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Middle Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 8,
        long_len: 8,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: 12.473488,
        label_y: 4.585041,
        ne_id: 1159320509,
        wikidataid: "Q1009",
        name_ar: "الكاميرون",
        name_bn: "ক্যামেরুন",
        name_de: "Kamerun",
        name_en: "Cameroon",
        name_es: "Camerún",
        name_fa: "کامرون",
        name_fr: "Cameroun",
        name_el: "Καμερούν",
        name_he: "קמרון",
        name_hi: "कैमरुन",
        name_hu: "Kamerun",
        name_id: "Kamerun",
        name_it: "Camerun",
        name_ja: "カメルーン",
        name_ko: "카메룬",
        name_nl: "Kameroen",
        name_pl: "Kamerun",
        name_pt: "Camarões",
        name_ru: "Камерун",
        name_sv: "Kamerun",
        name_tr: "Kamerun",
        name_uk: "Камерун",
        name_ur: "کیمرون",
        name_vi: "Cameroon",
        name_zh: "喀麦隆",
        name_zht: "喀麥隆",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "CMR.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [8.555859375000011, 4.755224609374991],
            [8.585156250000011, 4.832812499999989],
            [8.640527343750023, 4.927001953125],
            [8.715625, 5.046875],
            [8.800976562500011, 5.197460937499997],
            [8.859179687500017, 5.463769531249994],
            [8.898828125000023, 5.629687499999989],
            [8.93505859375, 5.781005859375],
            [8.997167968750006, 5.917724609375],
            [9.06015625, 6.009082031249989],
            [9.23876953125, 6.186132812499991],
            [9.373339843750017, 6.319628906249989],
            [9.442187500000017, 6.373388671874991],
            [9.490234375, 6.418652343749997],
            [9.574023437500017, 6.470410156249997],
            [9.659960937500017, 6.531982421875],
            [9.7255859375, 6.65],
            [9.779882812500006, 6.76015625],
            [9.820703125000023, 6.783935546875],
            [9.874218750000011, 6.803271484374989],
            [10.038867187500017, 6.92138671875],
            [10.1435546875, 6.996435546874991],
            [10.167773437500017, 6.959179687499997],
            [10.185546875, 6.912792968749997],
            [10.205468750000023, 6.8916015625],
            [10.293066406250006, 6.876757812499989],
            [10.413183593750006, 6.877734374999989],
            [10.482324218750023, 6.891259765624994],
            [10.51904296875, 6.930468749999989],
            [10.556347656250011, 7.037451171874991],
            [10.578125, 7.057714843749991],
            [10.60625, 7.063085937499991],
            [10.737597656250017, 6.98828125],
            [10.846484375000017, 6.881787109374997],
            [10.954199218750006, 6.7765625],
            [11.008691406250023, 6.739111328124991],
            [11.032519531250017, 6.697900390624994],
            [11.0796875, 6.505517578124994],
            [11.1064453125, 6.457714843749997],
            [11.1533203125, 6.437939453124997],
            [11.2373046875, 6.450537109374991],
            [11.324609375000023, 6.484667968749989],
            [11.401757812500023, 6.533935546875],
            [11.4775390625, 6.597412109375],
            [11.529101562500017, 6.655029296875],
            [11.551660156250023, 6.697265625],
            [11.56298828125, 6.854638671874994],
            [11.580078125, 6.888867187499997],
            [11.657519531250017, 6.9515625],
            [11.787011718750023, 7.056201171874989],
            [11.861425781250006, 7.11640625],
            [11.854785156250017, 7.137988281249989],
            [11.80859375, 7.201953124999989],
            [11.767382812500017, 7.272265624999989],
            [11.809179687500006, 7.345068359374991],
            [11.852441406250023, 7.400732421874991],
            [12.016015625000023, 7.589746093749994],
            [12.0166015625, 7.652001953124994],
            [12.025195312500017, 7.727783203125],
            [12.155957031250011, 7.942480468749991],
            [12.231152343750011, 8.227392578124991],
            [12.2333984375, 8.282324218749991],
            [12.311328125000017, 8.419726562499989],
            [12.403515625000011, 8.595556640624991],
            [12.582714843750011, 8.624121093749991],
            [12.651562500000011, 8.667773437499989],
            [12.731152343750011, 8.745654296874989],
            [12.7822265625, 8.81787109375],
            [12.806542968750023, 8.886621093749994],
            [12.824414062500011, 9.019433593749994],
            [12.85595703125, 9.170751953124991],
            [12.875683593750011, 9.303515624999989],
            [12.929492187500017, 9.42626953125],
            [13.019433593750023, 9.488330078124989],
            [13.175488281250011, 9.539648437499991],
            [13.19873046875, 9.563769531249989],
            [13.22119140625, 9.645166015624994],
            [13.23876953125, 9.814013671874989],
            [13.24375, 9.915917968749994],
            [13.249804687500017, 9.960058593749991],
            [13.269921875000023, 10.036181640624989],
            [13.41455078125, 10.171435546874989],
            [13.478515625, 10.383251953124997],
            [13.535351562500011, 10.605078125],
            [13.699902343750011, 10.873144531249991],
            [13.89208984375, 11.140087890624997],
            [13.9814453125, 11.211865234374997],
            [14.056738281250006, 11.245019531249994],
            [14.143261718750011, 11.24853515625],
            [14.202343750000011, 11.268164062499991],
            [14.409472656250017, 11.401171874999989],
            [14.49609375, 11.446142578124991],
            [14.559765625000011, 11.492285156249991],
            [14.575390625000011, 11.532421875],
            [14.581640625, 11.591162109374991],
            [14.561816406250017, 11.728710937499997],
            [14.597363281250011, 11.829833984375],
            [14.6181640625, 11.986621093749989],
            [14.627148437500011, 12.108691406249989],
            [14.619726562500006, 12.150976562499991],
            [14.587011718750006, 12.209423828124997],
            [14.580957031250023, 12.222070312499994],
            [14.518945312500023, 12.298242187499994],
            [14.415429687500023, 12.344140625],
            [14.272851562500023, 12.356494140624989],
            [14.197460937500011, 12.3837890625],
            [14.184863281250017, 12.447216796874997],
            [14.177636718750023, 12.484082031249997],
            [14.170312500000023, 12.524072265624994],
            [14.160058593750023, 12.61279296875],
            [14.06396484375, 13.078515625],
            [14.244824218750011, 13.07734375],
            [14.461718750000017, 13.021777343749989],
            [14.516210937500006, 12.979736328125],
            [14.544726562500017, 12.820214843749994],
            [14.623242187500011, 12.729931640624997],
            [14.76123046875, 12.655615234374991],
            [14.847070312500023, 12.502099609374994],
            [14.880664062500017, 12.269384765624991],
            [14.956738281250011, 12.13037109375],
            [14.973828125000011, 12.108349609374997],
            [15.059863281250017, 11.907128906249994],
            [15.08125, 11.845507812499989],
            [15.087695312500017, 11.724365234375],
            [15.078027343750023, 11.642578125],
            [15.121972656250023, 11.541259765625],
            [15.055468750000017, 11.368554687499994],
            [15.035742187500006, 11.2625],
            [15.029882812500006, 11.113671875],
            [15.068652343750017, 10.85107421875],
            [15.132226562500023, 10.648486328124989],
            [15.200976562500017, 10.484521484374994],
            [15.276074218750011, 10.357373046874997],
            [15.39990234375, 10.216894531249991],
            [15.531933593750011, 10.088476562499991],
            [15.654882812500006, 10.0078125],
            [15.540917968750023, 9.960302734374991],
            [15.320019531250011, 9.954296875],
            [15.193164062500017, 9.981494140624989],
            [15.132714843750023, 9.982861328124997],
            [15.071582031250017, 9.965966796874994],
            [14.835839843750023, 9.941699218749989],
            [14.597949218750017, 9.953076171874997],
            [14.377246093750017, 9.985058593749997],
            [14.243261718750006, 9.979736328125],
            [14.139746093750006, 9.901806640624997],
            [14.055957031250017, 9.784375],
            [13.977246093750011, 9.691552734374994],
            [14.004980468750006, 9.588720703124991],
            [14.064160156250011, 9.53173828125],
            [14.177929687500011, 9.406494140625],
            [14.280078125000017, 9.285058593749994],
            [14.332324218750017, 9.203515625],
            [14.5361328125, 9.025244140624991],
            [14.732812500000023, 8.865673828124997],
            [14.771289062500017, 8.839160156249989],
            [14.826269531250006, 8.810302734375],
            [14.860742187500023, 8.798632812499989],
            [14.967968750000011, 8.707275390625],
            [15.1162109375, 8.557324218749997],
            [15.252343750000023, 8.322363281249991],
            [15.349023437500023, 8.083837890624991],
            [15.44296875, 7.851855468749989],
            [15.484472656250006, 7.812744140625],
            [15.5498046875, 7.787890624999989],
            [15.557812500000011, 7.738037109375],
            [15.552636718750023, 7.664501953124997],
            [15.532421875000011, 7.604394531249994],
            [15.480078125, 7.523779296874991],
            [15.379101562500011, 7.358154296875],
            [15.245898437500017, 7.263574218749994],
            [15.206738281250011, 7.206152343749991],
            [15.185839843750017, 7.134912109374994],
            [15.157128906250023, 7.063574218749991],
            [15.086328125000023, 6.909912109375],
            [15.034570312500023, 6.784423828125],
            [14.982714843750017, 6.7453125],
            [14.861914062500006, 6.555712890624989],
            [14.780371093750006, 6.36572265625],
            [14.764062500000023, 6.316357421874997],
            [14.7392578125, 6.27978515625],
            [14.699511718750017, 6.250244140625],
            [14.559375, 6.191210937499989],
            [14.512109375000023, 6.161914062499989],
            [14.475, 6.126806640624991],
            [14.440722656250017, 6.086718749999989],
            [14.43115234375, 6.038720703124994],
            [14.4638671875, 5.970703125],
            [14.503125, 5.916894531249994],
            [14.54248046875, 5.91357421875],
            [14.577246093750006, 5.916015625],
            [14.598828125000011, 5.883984375],
            [14.616894531250011, 5.865136718749994],
            [14.616894531250011, 5.495507812499994],
            [14.58359375, 5.439648437499997],
            [14.584375, 5.414746093749997],
            [14.568066406250011, 5.35107421875],
            [14.56298828125, 5.279931640624994],
            [14.573535156250017, 5.251708984375],
            [14.601757812500011, 5.228808593749989],
            [14.640625, 5.179052734374991],
            [14.66171875, 5.065527343749991],
            [14.708984375, 4.665576171874989],
            [14.73125, 4.602392578124991],
            [14.770410156250023, 4.55810546875],
            [14.8935546875, 4.471875],
            [15.022753906250017, 4.358544921874994],
            [15.063574218750006, 4.284863281249997],
            [15.0875, 4.163964843749994],
            [15.136914062500011, 4.069140624999989],
            [15.135839843750006, 4.036914062499989],
            [15.115429687500011, 4.024462890624989],
            [15.0673828125, 4.02294921875],
            [15.034863281250011, 4.016357421875],
            [15.062109375, 3.947216796874997],
            [15.128710937500017, 3.826904296875],
            [15.23984375, 3.7021484375],
            [15.360156250000017, 3.567138671875],
            [15.458398437500023, 3.456835937499989],
            [15.580859375000017, 3.329296875],
            [15.676562500000017, 3.2296875],
            [15.775, 3.127197265625],
            [15.849316406250011, 3.103076171874989],
            [15.904882812500006, 3.095849609374994],
            [15.9287109375, 3.07578125],
            [15.9580078125, 3.028710937499994],
            [16.008203125000023, 2.976660156249991],
            [16.0634765625, 2.90859375],
            [16.082421875000023, 2.839111328125],
            [16.05927734375001, 2.772998046874989],
            [16.082128906250006, 2.678173828124997],
            [16.08349609375, 2.670019531249991],
            [16.101855468750017, 2.632666015624991],
            [16.095507812500017, 2.59921875],
            [16.106738281250017, 2.473486328124991],
            [16.136132812500023, 2.36376953125],
            [16.183398437500017, 2.270068359374989],
            [16.1826171875, 2.262451171875],
            [16.176562500000017, 2.204785156249997],
            [16.11572265625, 2.167822265624991],
            [16.080078125, 2.106787109374991],
            [16.069628906250017, 2.021679687499997],
            [16.087890625, 1.918066406249991],
            [16.13496093750001, 1.795947265624989],
            [16.136132812500023, 1.72421875],
            [16.119531250000023, 1.714111328125],
            [16.09033203125, 1.691259765624991],
            [16.059375, 1.676220703124997],
            [15.975195312500006, 1.760009765625],
            [15.881640625000017, 1.816601562499997],
            [15.741601562500023, 1.914990234374997],
            [15.600292968750011, 1.950390625],
            [15.41748046875, 1.956738281249997],
            [15.338769531250023, 1.944726562499994],
            [15.282421875000011, 1.981738281249989],
            [15.203515625000023, 2.024462890624989],
            [15.160058593750023, 2.035595703124997],
            [15.099609375, 2.00234375],
            [15.057812500000011, 2.000878906249994],
            [15.006445312500006, 2.013769531249991],
            [14.902441406250006, 2.012304687499991],
            [14.892773437500011, 2.0693359375],
            [14.875, 2.08046875],
            [14.762890625000011, 2.0751953125],
            [14.728320312500017, 2.122412109374991],
            [14.713281250000023, 2.117138671874997],
            [14.669140625000011, 2.132080078125],
            [14.578906250000017, 2.199121093749994],
            [14.484082031250011, 2.154736328124997],
            [14.287011718750023, 2.160351562499997],
            [14.034375, 2.158886718749997],
            [13.772753906250017, 2.157421875],
            [13.533496093750017, 2.159521484374991],
            [13.293554687500006, 2.161572265624997],
            [13.269921875000023, 2.22421875],
            [13.2203125, 2.256445312499991],
            [13.130859375, 2.259423828124994],
            [12.867480468750017, 2.246777343749997],
            [12.665722656250011, 2.256787109374997],
            [12.601367187500017, 2.265039062499994],
            [12.52978515625, 2.281347656249991],
            [12.361328125, 2.295996093749991],
            [12.153417968750006, 2.284375],
            [12.106152343750011, 2.2875],
            [11.939746093750017, 2.28515625],
            [11.558984375000023, 2.302197265624997],
            [11.348437500000017, 2.299707031249994],
            [11.353320312500017, 2.261425781249997],
            [11.339941406250006, 2.233837890624997],
            [11.328710937500006, 2.167431640624997],
            [11.096582031250023, 2.16748046875],
            [10.790917968750023, 2.167578125],
            [10.502246093750017, 2.167626953124994],
            [10.307031250000023, 2.167724609375],
            [9.979882812500023, 2.167773437499989],
            [9.8701171875, 2.21328125],
            [9.8369140625, 2.242382812499997],
            [9.830371093750017, 2.275488281249991],
            [9.826171875, 2.297802734374997],
            [9.80078125, 2.304443359375],
            [9.82177734375, 2.539257812499997],
            [9.867578125000023, 2.734960937499991],
            [9.885449218750011, 2.916552734374989],
            [9.948437500000011, 3.079052734374997],
            [9.9150390625, 3.239648437499994],
            [9.876171875000011, 3.309765625],
            [9.672070312500011, 3.53759765625],
            [9.765722656250006, 3.623828124999989],
            [9.642382812500017, 3.611767578124997],
            [9.615917968750011, 3.696484375],
            [9.55615234375, 3.798046875],
            [9.5927734375, 3.814306640624991],
            [9.628125, 3.870019531249994],
            [9.739648437500023, 3.852929687499994],
            [9.736132812500017, 3.880126953125],
            [9.639941406250017, 3.96533203125],
            [9.649218750000017, 4.008349609374989],
            [9.688867187500023, 4.056396484375],
            [9.66953125, 4.07666015625],
            [9.600390625000017, 4.026904296874989],
            [9.550585937500017, 4.028417968749991],
            [9.511816406250006, 4.060644531249991],
            [9.483691406250017, 4.066113281249997],
            [9.500781250000017, 4.000732421875],
            [9.462011718750006, 3.942529296874994],
            [9.42529296875, 3.922314453124997],
            [9.3623046875, 3.925732421874997],
            [9.310937500000023, 3.940380859374997],
            [9.29736328125, 3.972949218749989],
            [9.249121093750006, 3.997851562499989],
            [9.113867187500006, 4.041064453124989],
            [9.000097656250006, 4.091601562499989],
            [8.97705078125, 4.230419921874997],
            [8.932031250000023, 4.290234375],
            [8.91357421875, 4.3578125],
            [8.90283203125, 4.43515625],
            [8.918261718750017, 4.553759765624989],
            [8.889453125000017, 4.57275390625],
            [8.8564453125, 4.579248046874994],
            [8.80712890625, 4.5734375],
            [8.761914062500011, 4.580029296874997],
            [8.707910156250023, 4.645703125],
            [8.660351562500011, 4.670996093749991],
            [8.689648437500011, 4.550244140624997],
            [8.65625, 4.516357421875],
            [8.574414062500011, 4.526220703124991],
            [8.53955078125, 4.571875],
            [8.5328125, 4.605859375],
            [8.570507812500011, 4.752099609374994],
            [8.555859375000011, 4.755224609374991],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 6,
        sovereignt: "Lesotho",
        sov_a3: "LSO",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Lesotho",
        adm0_a3: "LSO",
        geou_dif: 0,
        geounit: "Lesotho",
        gu_a3: "LSO",
        su_dif: 0,
        subunit: "Lesotho",
        su_a3: "LSO",
        brk_diff: 0,
        name: "Lesotho",
        name_long: "Lesotho",
        brk_a3: "LSO",
        brk_name: "Lesotho",
        brk_group: null,
        abbrev: "Les.",
        postal: "LS",
        formal_en: "Kingdom of Lesotho",
        formal_fr: null,
        name_ciawf: "Lesotho",
        note_adm0: null,
        note_brk: null,
        name_sort: "Lesotho",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 5,
        mapcolor9: 2,
        mapcolor13: 8,
        pop_est: 2125268,
        pop_rank: 12,
        pop_year: 2019,
        gdp_md: 2376,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "4. Lower middle income",
        fips_10: "LT",
        iso_a2: "LS",
        iso_a2_eh: "LS",
        iso_a3: "LSO",
        iso_a3_eh: "LSO",
        iso_n3: "426",
        iso_n3_eh: "426",
        un_a3: "426",
        wb_a2: "LS",
        wb_a3: "LSO",
        woe_id: 23424880,
        woe_id_eh: 23424880,
        woe_note: "Exact WOE match as country",
        adm0_iso: "LSO",
        adm0_diff: null,
        adm0_tlc: "LSO",
        adm0_a3_us: "LSO",
        adm0_a3_fr: "LSO",
        adm0_a3_ru: "LSO",
        adm0_a3_es: "LSO",
        adm0_a3_cn: "LSO",
        adm0_a3_tw: "LSO",
        adm0_a3_in: "LSO",
        adm0_a3_np: "LSO",
        adm0_a3_pk: "LSO",
        adm0_a3_de: "LSO",
        adm0_a3_gb: "LSO",
        adm0_a3_br: "LSO",
        adm0_a3_il: "LSO",
        adm0_a3_ps: "LSO",
        adm0_a3_sa: "LSO",
        adm0_a3_eg: "LSO",
        adm0_a3_ma: "LSO",
        adm0_a3_pt: "LSO",
        adm0_a3_ar: "LSO",
        adm0_a3_jp: "LSO",
        adm0_a3_ko: "LSO",
        adm0_a3_vn: "LSO",
        adm0_a3_tr: "LSO",
        adm0_a3_id: "LSO",
        adm0_a3_pl: "LSO",
        adm0_a3_gr: "LSO",
        adm0_a3_it: "LSO",
        adm0_a3_nl: "LSO",
        adm0_a3_se: "LSO",
        adm0_a3_bd: "LSO",
        adm0_a3_ua: "LSO",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Southern Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: 28.246639,
        label_y: -29.480158,
        ne_id: 1159321027,
        wikidataid: "Q1013",
        name_ar: "ليسوتو",
        name_bn: "লেসোথো",
        name_de: "Lesotho",
        name_en: "Lesotho",
        name_es: "Lesoto",
        name_fa: "لسوتو",
        name_fr: "Lesotho",
        name_el: "Λεσότο",
        name_he: "לסוטו",
        name_hi: "लेसोथो",
        name_hu: "Lesotho",
        name_id: "Lesotho",
        name_it: "Lesotho",
        name_ja: "レソト",
        name_ko: "레소토",
        name_nl: "Lesotho",
        name_pl: "Lesotho",
        name_pt: "Lesoto",
        name_ru: "Лесото",
        name_sv: "Lesotho",
        name_tr: "Lesotho",
        name_uk: "Лесото",
        name_ur: "لیسوتھو",
        name_vi: "Lesotho",
        name_zh: "莱索托",
        name_zht: "賴索托",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "LSO.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [28.736914062500006, -30.101953125],
            [28.646875, -30.1265625],
            [28.634375, -30.128710937500003],
            [28.57666015625, -30.123046875],
            [28.499609375, -30.12890625],
            [28.4390625, -30.14248046875001],
            [28.39208984375, -30.147558593750006],
            [28.3154296875, -30.21845703125001],
            [28.176171875000023, -30.40986328125001],
            [28.139062500000023, -30.44990234375001],
            [28.128710937500017, -30.52509765625001],
            [28.09638671875001, -30.584570312500006],
            [28.05683593750001, -30.63105468750001],
            [28.018164062500006, -30.64228515625001],
            [27.90185546875, -30.623828125],
            [27.753125, -30.6],
            [27.666601562500006, -30.542285156250003],
            [27.589648437500017, -30.46640625],
            [27.54902343750001, -30.411230468750006],
            [27.50654296875001, -30.380957031250006],
            [27.491992187500017, -30.36396484375001],
            [27.431445312500017, -30.338476562500006],
            [27.408593750000023, -30.325292968750006],
            [27.388476562500017, -30.31591796875],
            [27.364062500000017, -30.27919921875001],
            [27.349707031250006, -30.247363281250003],
            [27.355371093750023, -30.15859375],
            [27.31269531250001, -30.10566406250001],
            [27.23974609375, -30.01533203125001],
            [27.19355468750001, -29.94130859375001],
            [27.13046875, -29.840234375],
            [27.091796875, -29.753710937500003],
            [27.0517578125, -29.6640625],
            [27.056933593750017, -29.625585937500006],
            [27.09521484375, -29.59931640625001],
            [27.207421875000023, -29.55419921875],
            [27.29453125, -29.519335937500003],
            [27.356835937500023, -29.45527343750001],
            [27.424902343750006, -29.36005859375001],
            [27.4580078125, -29.302734375],
            [27.491015625000017, -29.27656250000001],
            [27.527148437500017, -29.236132812500003],
            [27.590234375000023, -29.146484375],
            [27.660449218750017, -29.046972656250006],
            [27.73554687500001, -28.940039062500006],
            [27.830371093750017, -28.90908203125001],
            [27.959863281250023, -28.873339843750003],
            [28.084375, -28.77998046875001],
            [28.23261718750001, -28.701269531250006],
            [28.471875, -28.615820312500006],
            [28.583398437500023, -28.594140625],
            [28.625781250000017, -28.58173828125001],
            [28.652636718750017, -28.59785156250001],
            [28.68115234375, -28.646777343750003],
            [28.721777343750006, -28.68769531250001],
            [28.816210937500017, -28.758886718750006],
            [28.85625, -28.77607421875001],
            [28.953710937500006, -28.881445312500006],
            [29.058007812500023, -28.953710937500006],
            [29.178027343750017, -29.036914062500003],
            [29.259765625, -29.07832031250001],
            [29.301367187500006, -29.08984375],
            [29.3359375, -29.163671875],
            [29.370898437500017, -29.21845703125001],
            [29.390722656250006, -29.26972656250001],
            [29.38671875, -29.31972656250001],
            [29.34882812500001, -29.441992187500006],
            [29.293554687500006, -29.56689453125],
            [29.24921875000001, -29.61884765625001],
            [29.195117187500017, -29.651660156250003],
            [29.1421875, -29.700976562500003],
            [29.121972656250023, -29.801171875],
            [29.098046875000023, -29.919042968750006],
            [29.02900390625001, -29.967578125],
            [28.97529296875001, -29.99941406250001],
            [28.90107421875001, -30.03847656250001],
            [28.736914062500006, -30.101953125],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Niger",
        sov_a3: "NER",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Niger",
        adm0_a3: "NER",
        geou_dif: 0,
        geounit: "Niger",
        gu_a3: "NER",
        su_dif: 0,
        subunit: "Niger",
        su_a3: "NER",
        brk_diff: 0,
        name: "Niger",
        name_long: "Niger",
        brk_a3: "NER",
        brk_name: "Niger",
        brk_group: null,
        abbrev: "Niger",
        postal: "NE",
        formal_en: "Republic of Niger",
        formal_fr: null,
        name_ciawf: "Niger",
        note_adm0: null,
        note_brk: null,
        name_sort: "Niger",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 5,
        mapcolor9: 3,
        mapcolor13: 13,
        pop_est: 23310715,
        pop_rank: 15,
        pop_year: 2019,
        gdp_md: 12911,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        fips_10: "NG",
        iso_a2: "NE",
        iso_a2_eh: "NE",
        iso_a3: "NER",
        iso_a3_eh: "NER",
        iso_n3: "562",
        iso_n3_eh: "562",
        un_a3: "562",
        wb_a2: "NE",
        wb_a3: "NER",
        woe_id: 23424906,
        woe_id_eh: 23424906,
        woe_note: "Exact WOE match as country",
        adm0_iso: "NER",
        adm0_diff: null,
        adm0_tlc: "NER",
        adm0_a3_us: "NER",
        adm0_a3_fr: "NER",
        adm0_a3_ru: "NER",
        adm0_a3_es: "NER",
        adm0_a3_cn: "NER",
        adm0_a3_tw: "NER",
        adm0_a3_in: "NER",
        adm0_a3_np: "NER",
        adm0_a3_pk: "NER",
        adm0_a3_de: "NER",
        adm0_a3_gb: "NER",
        adm0_a3_br: "NER",
        adm0_a3_il: "NER",
        adm0_a3_ps: "NER",
        adm0_a3_sa: "NER",
        adm0_a3_eg: "NER",
        adm0_a3_ma: "NER",
        adm0_a3_pt: "NER",
        adm0_a3_ar: "NER",
        adm0_a3_jp: "NER",
        adm0_a3_ko: "NER",
        adm0_a3_vn: "NER",
        adm0_a3_tr: "NER",
        adm0_a3_id: "NER",
        adm0_a3_pl: "NER",
        adm0_a3_gr: "NER",
        adm0_a3_it: "NER",
        adm0_a3_nl: "NER",
        adm0_a3_se: "NER",
        adm0_a3_bd: "NER",
        adm0_a3_ua: "NER",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Western Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 5,
        long_len: 5,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: 9.504356,
        label_y: 17.446195,
        ne_id: 1159321087,
        wikidataid: "Q1032",
        name_ar: "النيجر",
        name_bn: "নাইজার",
        name_de: "Niger",
        name_en: "Niger",
        name_es: "Níger",
        name_fa: "نیجر",
        name_fr: "Niger",
        name_el: "Νίγηρας",
        name_he: "ניז'ר",
        name_hi: "नाइजर",
        name_hu: "Niger",
        name_id: "Niger",
        name_it: "Niger",
        name_ja: "ニジェール",
        name_ko: "니제르",
        name_nl: "Niger",
        name_pl: "Niger",
        name_pt: "Níger",
        name_ru: "Нигер",
        name_sv: "Niger",
        name_tr: "Nijer",
        name_uk: "Нігер",
        name_ur: "نائجر",
        name_vi: "Niger",
        name_zh: "尼日尔",
        name_zht: "尼日",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "NER.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [13.606347656250023, 13.70458984375],
            [13.426953125000011, 13.701757812499991],
            [13.323828125, 13.670849609374997],
            [13.19384765625, 13.573046875],
            [13.0484375, 13.534521484374991],
            [12.871679687500006, 13.449023437500003],
            [12.759960937500011, 13.38037109375],
            [12.65478515625, 13.3265625],
            [12.510156250000023, 13.1943359375],
            [12.463183593750017, 13.09375],
            [12.319042968750011, 13.073681640624997],
            [12.117968750000017, 13.090429687499991],
            [11.990039062500017, 13.191796875],
            [11.693359375, 13.297705078124991],
            [11.501074218750006, 13.340527343749997],
            [11.411914062500017, 13.353613281249991],
            [10.958886718750023, 13.371533203124997],
            [10.475878906250017, 13.330224609374994],
            [10.229589843750006, 13.281005859375],
            [10.184667968750006, 13.270117187499991],
            [10.045117187500011, 13.206152343749991],
            [9.929296875, 13.13525390625],
            [9.615917968750011, 12.810644531249991],
            [9.201562500000023, 12.821484375],
            [8.957617187500006, 12.857470703124989],
            [8.750585937500006, 12.908154296874997],
            [8.4560546875, 13.059667968749991],
            [8.095019531250017, 13.291162109374994],
            [7.955761718750011, 13.32275390625],
            [7.830468750000023, 13.340917968749991],
            [7.788671875, 13.337890625],
            [7.357812500000023, 13.107177734375],
            [7.274707031250017, 13.112255859374997],
            [7.173046875000011, 13.086328125],
            [7.106054687500006, 13.029101562499989],
            [7.056738281250006, 13.000195312499997],
            [7.005078125000011, 12.995556640624997],
            [6.937207031250011, 13.008203125],
            [6.87060546875, 13.043261718749989],
            [6.804296875, 13.107666015625],
            [6.6265625, 13.3642578125],
            [6.589941406250006, 13.409130859374997],
            [6.514062500000023, 13.485400390625003],
            [6.386328125, 13.603613281249991],
            [6.2998046875, 13.658789062499991],
            [6.247167968750006, 13.672998046874994],
            [6.184277343750011, 13.663671875],
            [5.838183593750017, 13.765380859375],
            [5.491992187500017, 13.872851562500003],
            [5.415820312500017, 13.859179687500003],
            [5.361621093750017, 13.836865234374997],
            [5.241894531250011, 13.757226562499994],
            [5.100878906250017, 13.742724609375003],
            [4.921679687500017, 13.749121093749991],
            [4.823339843750006, 13.759765625],
            [4.664843750000017, 13.733203125],
            [4.559472656250023, 13.701806640624994],
            [4.42138671875, 13.647509765625003],
            [4.2421875, 13.501074218749991],
            [4.190820312500023, 13.482128906249997],
            [4.147558593750006, 13.457714843749997],
            [4.08740234375, 13.055468749999989],
            [4.038769531250011, 12.934667968749991],
            [3.947851562500006, 12.775048828124994],
            [3.769238281250011, 12.622167968749991],
            [3.646679687500011, 12.529980468749997],
            [3.643847656250017, 12.4052734375],
            [3.634179687500023, 12.201611328124997],
            [3.632519531250011, 12.061572265624989],
            [3.640625, 11.970361328124994],
            [3.6201171875, 11.926953125],
            [3.61181640625, 11.887304687499991],
            [3.618457031250017, 11.827734375],
            [3.647363281250023, 11.799658203124991],
            [3.664746093750011, 11.762451171875],
            [3.653125, 11.731835937499994],
            [3.595410156250011, 11.6962890625],
            [3.53173828125, 11.787451171874991],
            [3.449804687500006, 11.851953125],
            [3.359960937500006, 11.88046875],
            [3.299121093750017, 11.927148437499994],
            [3.267382812500017, 11.991894531249997],
            [3.149609375000011, 12.118066406249994],
            [2.878125, 12.367724609374989],
            [2.850195312500006, 12.373681640624994],
            [2.805273437500006, 12.383837890624989],
            [2.728515625, 12.353613281249991],
            [2.681347656250011, 12.312792968749989],
            [2.6484375, 12.296777343749994],
            [2.598437500000017, 12.294335937499994],
            [2.469335937500006, 12.262792968749991],
            [2.366015625000017, 12.221923828125],
            [2.36328125, 12.188427734374997],
            [2.412695312500006, 11.999316406249989],
            [2.38916015625, 11.897070312499991],
            [2.343359375, 11.945996093749997],
            [2.194433593750006, 12.136474609375],
            [2.09140625, 12.277978515624994],
            [2.072949218750011, 12.309375],
            [2.058398437500017, 12.357958984374989],
            [2.068554687500011, 12.379150390625],
            [2.109375, 12.393847656249989],
            [2.203808593750011, 12.41259765625],
            [2.221386718750011, 12.42724609375],
            [2.226269531250011, 12.466064453125],
            [2.211523437500006, 12.538427734374991],
            [2.159765625, 12.636425781249997],
            [2.104589843750006, 12.701269531249991],
            [2.073828125, 12.713964843749991],
            [2.017382812500017, 12.716210937499994],
            [1.956152343750006, 12.707421875],
            [1.840917968750006, 12.627880859374997],
            [1.789843750000017, 12.61328125],
            [1.671093750000011, 12.619824218749997],
            [1.56494140625, 12.635400390624994],
            [1.50048828125, 12.676464843749997],
            [1.308691406250006, 12.834277343749989],
            [1.096777343750006, 13.001123046874994],
            [1.007910156250006, 13.024804687499994],
            [0.9873046875, 13.041894531249994],
            [0.973046875000023, 13.170361328124997],
            [0.976757812500011, 13.324511718750003],
            [0.988476562500011, 13.36484375],
            [1.076855468750011, 13.340771484374997],
            [1.1708984375, 13.32958984375],
            [1.201171875, 13.357519531249991],
            [1.1259765625, 13.412353515625],
            [1.017871093750017, 13.467871093749991],
            [0.977734375000011, 13.551953125],
            [0.946582031250017, 13.581152343749991],
            [0.89794921875, 13.6109375],
            [0.84228515625, 13.626416015624997],
            [0.786035156250023, 13.650048828124994],
            [0.747753906250011, 13.674511718749997],
            [0.6845703125, 13.685400390624991],
            [0.6181640625, 13.703417968750003],
            [0.522363281250023, 13.839746093749994],
            [0.42919921875, 13.972119140624997],
            [0.3740234375, 14.076367187499997],
            [0.354882812500023, 14.139013671874991],
            [0.382519531250011, 14.245800781249997],
            [0.354589843750006, 14.288037109374997],
            [0.250585937500006, 14.396435546874997],
            [0.163867187500017, 14.497216796874994],
            [0.18505859375, 14.652929687499991],
            [0.202734375, 14.7828125],
            [0.203808593750011, 14.865039062500003],
            [0.217480468750011, 14.911474609374991],
            [0.228710937500011, 14.963671875],
            [0.286230468750006, 14.980175781249997],
            [0.433007812500023, 14.97900390625],
            [0.718652343750023, 14.954882812500003],
            [0.947460937500011, 14.982128906249997],
            [0.960058593750006, 14.986914062499991],
            [1.121289062500011, 15.126123046874994],
            [1.300195312500023, 15.272265625],
            [1.569140625000017, 15.286474609374991],
            [1.859375, 15.301708984374997],
            [2.088183593750017, 15.309375],
            [2.420800781250023, 15.320410156249991],
            [2.689648437500011, 15.329882812500003],
            [3.001074218750006, 15.340966796874994],
            [3.010546875000017, 15.408300781249991],
            [3.029394531250006, 15.424853515625003],
            [3.06015625, 15.427197265624997],
            [3.2890625, 15.39111328125],
            [3.504296875000023, 15.356347656249994],
            [3.5205078125, 15.483105468749997],
            [3.709570312500006, 15.641699218749991],
            [3.816503906250006, 15.674023437499997],
            [3.842968750000011, 15.701708984375003],
            [3.876953125, 15.755273437499994],
            [3.89794921875, 15.837988281249991],
            [3.9072265625, 15.896826171874991],
            [3.947070312500017, 15.945654296874991],
            [3.976171875, 16.035546875],
            [4.014843750000011, 16.19272460937499],
            [4.121289062500011, 16.357714843750003],
            [4.18212890625, 16.581787109375],
            [4.191210937500017, 16.79819335937499],
            [4.202929687500017, 16.962695312500003],
            [4.234667968750017, 16.996386718750003],
            [4.233691406250017, 17.28842773437499],
            [4.232714843750017, 17.582177734374994],
            [4.23193359375, 17.830517578124997],
            [4.230859375000023, 18.139453125],
            [4.22998046875, 18.410595703124997],
            [4.22900390625, 18.704345703125],
            [4.228222656250011, 18.968066406250003],
            [4.227636718750006, 19.142773437499997],
            [4.445703125000023, 19.184521484374997],
            [4.671289062500023, 19.227783203125],
            [5.001367187500023, 19.291064453125003],
            [5.358691406250017, 19.359521484374994],
            [5.748339843750017, 19.434228515624994],
            [5.836621093750011, 19.479150390624994],
            [6.130664062500017, 19.731982421875003],
            [6.263378906250011, 19.846142578124997],
            [6.527050781250011, 20.072949218749997],
            [6.730664062500011, 20.248046875],
            [6.989355468750006, 20.470507812500003],
            [7.263378906250011, 20.694482421874994],
            [7.481738281250017, 20.873095703125003],
            [7.8251953125, 21.075585937499994],
            [8.343066406250017, 21.380859375],
            [8.8609375, 21.68613281249999],
            [9.378710937500017, 21.99140625],
            [9.896484375, 22.29672851562499],
            [10.414355468750017, 22.602001953124997],
            [10.932226562500006, 22.907275390625003],
            [11.45, 23.212597656249997],
            [11.967871093750006, 23.517871093750003],
            [12.48876953125, 23.401660156250003],
            [12.983593750000011, 23.291259765625],
            [13.48125, 23.18017578125],
            [13.5986328125, 23.11953125],
            [13.862695312500023, 22.902099609375],
            [14.20068359375, 22.623730468749997],
            [14.215527343750011, 22.619677734375003],
            [14.230761718750017, 22.618457031250003],
            [14.5556640625, 22.782519531250003],
            [14.978906250000023, 22.996289062499997],
            [14.97900390625, 22.99619140624999],
            [15.088964843750006, 22.418359375],
            [15.172265625000023, 21.922070312499997],
            [15.177832031250006, 21.60581054687499],
            [15.181835937500011, 21.523388671874997],
            [15.2158203125, 21.467431640624994],
            [15.293652343750011, 21.411523437499994],
            [15.607324218750023, 20.954394531250003],
            [15.540332031250017, 20.874902343749994],
            [15.587109375000011, 20.733300781249994],
            [15.66845703125, 20.67236328125],
            [15.929296875, 20.399853515624997],
            [15.963183593750017, 20.34619140625],
            [15.948828125, 20.303173828124997],
            [15.766210937500006, 19.982568359374994],
            [15.735058593750011, 19.904052734375],
            [15.698632812500023, 19.49521484374999],
            [15.672949218750006, 19.206787109375],
            [15.637597656250023, 18.810839843750003],
            [15.595507812500017, 18.337060546874994],
            [15.5615234375, 17.937255859375],
            [15.516699218750006, 17.408496093750003],
            [15.474316406250011, 16.908398437499997],
            [15.212109375000011, 16.63388671874999],
            [14.746679687500006, 16.146630859374994],
            [14.367968750000017, 15.750146484374994],
            [14.17822265625, 15.484765625],
            [13.80712890625, 14.966113281250003],
            [13.642382812500017, 14.630761718749994],
            [13.513671875, 14.455517578124997],
            [13.4482421875, 14.380664062500003],
            [13.505761718750023, 14.134423828124994],
            [13.606347656250023, 13.70458984375],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Burkina Faso",
        sov_a3: "BFA",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Burkina Faso",
        adm0_a3: "BFA",
        geou_dif: 0,
        geounit: "Burkina Faso",
        gu_a3: "BFA",
        su_dif: 0,
        subunit: "Burkina Faso",
        su_a3: "BFA",
        brk_diff: 0,
        name: "Burkina Faso",
        name_long: "Burkina Faso",
        brk_a3: "BFA",
        brk_name: "Burkina Faso",
        brk_group: null,
        abbrev: "B.F.",
        postal: "BF",
        formal_en: "Burkina Faso",
        formal_fr: null,
        name_ciawf: "Burkina Faso",
        note_adm0: null,
        note_brk: null,
        name_sort: "Burkina Faso",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 1,
        mapcolor9: 5,
        mapcolor13: 11,
        pop_est: 20321378,
        pop_rank: 15,
        pop_year: 2019,
        gdp_md: 15990,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        fips_10: "UV",
        iso_a2: "BF",
        iso_a2_eh: "BF",
        iso_a3: "BFA",
        iso_a3_eh: "BFA",
        iso_n3: "854",
        iso_n3_eh: "854",
        un_a3: "854",
        wb_a2: "BF",
        wb_a3: "BFA",
        woe_id: 23424978,
        woe_id_eh: 23424978,
        woe_note: "Exact WOE match as country",
        adm0_iso: "BFA",
        adm0_diff: null,
        adm0_tlc: "BFA",
        adm0_a3_us: "BFA",
        adm0_a3_fr: "BFA",
        adm0_a3_ru: "BFA",
        adm0_a3_es: "BFA",
        adm0_a3_cn: "BFA",
        adm0_a3_tw: "BFA",
        adm0_a3_in: "BFA",
        adm0_a3_np: "BFA",
        adm0_a3_pk: "BFA",
        adm0_a3_de: "BFA",
        adm0_a3_gb: "BFA",
        adm0_a3_br: "BFA",
        adm0_a3_il: "BFA",
        adm0_a3_ps: "BFA",
        adm0_a3_sa: "BFA",
        adm0_a3_eg: "BFA",
        adm0_a3_ma: "BFA",
        adm0_a3_pt: "BFA",
        adm0_a3_ar: "BFA",
        adm0_a3_jp: "BFA",
        adm0_a3_ko: "BFA",
        adm0_a3_vn: "BFA",
        adm0_a3_tr: "BFA",
        adm0_a3_id: "BFA",
        adm0_a3_pl: "BFA",
        adm0_a3_gr: "BFA",
        adm0_a3_it: "BFA",
        adm0_a3_nl: "BFA",
        adm0_a3_se: "BFA",
        adm0_a3_bd: "BFA",
        adm0_a3_ua: "BFA",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Western Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 12,
        long_len: 12,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: -1.36388,
        label_y: 12.673048,
        ne_id: 1159320405,
        wikidataid: "Q965",
        name_ar: "بوركينا فاسو",
        name_bn: "বুর্কিনা ফাসো",
        name_de: "Burkina Faso",
        name_en: "Burkina Faso",
        name_es: "Burkina Faso",
        name_fa: "بورکینافاسو",
        name_fr: "Burkina Faso",
        name_el: "Μπουρκίνα Φάσο",
        name_he: "בורקינה פאסו",
        name_hi: "बुर्किना फासो",
        name_hu: "Burkina Faso",
        name_id: "Burkina Faso",
        name_it: "Burkina Faso",
        name_ja: "ブルキナファソ",
        name_ko: "부르키나파소",
        name_nl: "Burkina Faso",
        name_pl: "Burkina Faso",
        name_pt: "Burkina Faso",
        name_ru: "Буркина-Фасо",
        name_sv: "Burkina Faso",
        name_tr: "Burkina Faso",
        name_uk: "Буркіна-Фасо",
        name_ur: "برکینا فاسو",
        name_vi: "Burkina Faso",
        name_zh: "布基纳法索",
        name_zht: "布基納法索",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "BFA.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.900488281250006, 10.993261718749991],
            [0.642968750000023, 10.983056640624994],
            [0.549121093750017, 10.955419921874991],
            [0.49267578125, 10.954980468749994],
            [0.49072265625, 10.978173828124994],
            [0.484179687500017, 10.991992187499989],
            [0.159277343750006, 11.069628906249989],
            [-0.068603515625, 11.115625],
            [-0.299462890624994, 11.166894531249994],
            [-0.312548828124989, 11.118896484375],
            [-0.345751953124989, 11.087939453124989],
            [-0.395605468749977, 11.085693359375],
            [-0.430322265624994, 11.09326171875],
            [-0.453515625, 11.056298828124994],
            [-0.49169921875, 11.007617187499989],
            [-0.545214843749989, 10.983691406249989],
            [-0.59765625, 10.953662109374989],
            [-0.627148437499983, 10.927392578124994],
            [-0.648535156249977, 10.9267578125],
            [-0.701416015625, 10.988964843749997],
            [-0.771582031249977, 10.995263671874994],
            [-0.902929687499977, 10.984716796874991],
            [-0.961816406249994, 11.001708984375],
            [-1.04248046875, 11.010058593749989],
            [-1.232617187499983, 10.997216796874994],
            [-1.536767578124994, 11.02265625],
            [-1.586474609374989, 11.008886718749991],
            [-1.599658203124989, 10.99765625],
            [-1.900634765625, 10.994677734374989],
            [-2.23193359375, 10.99140625],
            [-2.509179687499994, 10.988720703124997],
            [-2.751660156249983, 10.986376953124989],
            [-2.752099609374994, 10.996972656249994],
            [-2.829931640624977, 10.998388671874991],
            [-2.838574218749983, 10.977490234374997],
            [-2.907324218749977, 10.727978515624997],
            [-2.914892578124977, 10.592333984374989],
            [-2.87841796875, 10.507958984374994],
            [-2.837207031249989, 10.454638671874989],
            [-2.791162109374994, 10.432421874999989],
            [-2.78662109375, 10.401904296874989],
            [-2.823437499999983, 10.362939453124994],
            [-2.8203125, 10.322851562499991],
            [-2.777099609375, 10.281591796874991],
            [-2.766503906249994, 10.238183593749994],
            [-2.788476562499994, 10.192578125],
            [-2.783203125, 10.083105468749991],
            [-2.750732421875, 9.90966796875],
            [-2.749804687499989, 9.797216796874991],
            [-2.780517578125, 9.745849609375],
            [-2.765966796874977, 9.658056640624991],
            [-2.706201171874994, 9.533935546875],
            [-2.695849609374989, 9.481347656249994],
            [-2.7171875, 9.457128906249991],
            [-2.7666015625, 9.424707031249994],
            [-2.816748046874977, 9.425830078124989],
            [-2.875146484374994, 9.500927734374997],
            [-2.90087890625, 9.534619140624997],
            [-2.948144531249994, 9.610742187499994],
            [-2.98828125, 9.687353515624991],
            [-3.042626953124994, 9.720898437499997],
            [-3.095800781249977, 9.752099609374994],
            [-3.160693359374989, 9.849169921874989],
            [-3.223535156249994, 9.895458984374997],
            [-3.289697265624994, 9.882226562499994],
            [-3.386279296874989, 9.900292968749994],
            [-3.581152343749977, 9.92431640625],
            [-3.790625, 9.9171875],
            [-3.877636718749983, 9.894921875],
            [-3.963476562499977, 9.859619140625],
            [-4.18115234375, 9.78173828125],
            [-4.267187499999977, 9.743261718749991],
            [-4.332226562499983, 9.645703125],
            [-4.406201171874983, 9.647998046874989],
            [-4.480273437499989, 9.679248046874989],
            [-4.526611328125, 9.723486328124991],
            [-4.625830078124977, 9.713574218749997],
            [-4.721777343749977, 9.756542968749997],
            [-4.814453125, 9.841162109374991],
            [-4.882714843749994, 9.868945312499989],
            [-4.969921874999983, 9.930078125],
            [-4.994042968749994, 10.046484375],
            [-5.04931640625, 10.128320312499994],
            [-5.099853515625, 10.241601562499994],
            [-5.17529296875, 10.292626953124994],
            [-5.262304687499977, 10.319677734374991],
            [-5.382275390624983, 10.314013671874989],
            [-5.461279296874977, 10.359570312499997],
            [-5.523535156249977, 10.426025390625],
            [-5.507031249999983, 10.483447265624989],
            [-5.47900390625, 10.565087890624994],
            [-5.475683593749977, 10.643945312499994],
            [-5.457080078124989, 10.771386718749994],
            [-5.468554687499989, 10.931054687499994],
            [-5.490478515625, 11.042382812499994],
            [-5.42421875, 11.088720703124991],
            [-5.347412109375, 11.130273437499994],
            [-5.299853515624989, 11.205957031249994],
            [-5.250244140625, 11.375781249999989],
            [-5.229394531249994, 11.5224609375],
            [-5.244775390624994, 11.576757812499991],
            [-5.270312499999989, 11.619873046875],
            [-5.29052734375, 11.683300781249997],
            [-5.302001953125, 11.760449218749997],
            [-5.288134765624989, 11.827929687499989],
            [-5.230175781249983, 11.890283203124994],
            [-5.157519531249989, 11.9423828125],
            [-5.105908203124983, 11.967529296875],
            [-4.968994140625, 11.993310546874994],
            [-4.797949218749977, 12.032128906249994],
            [-4.699316406249977, 12.076171875],
            [-4.627246093749989, 12.120214843749991],
            [-4.5869140625, 12.155029296875],
            [-4.546044921874994, 12.226464843749994],
            [-4.479882812499994, 12.281787109374989],
            [-4.4287109375, 12.337597656249997],
            [-4.421582031249983, 12.493066406249994],
            [-4.421923828124989, 12.581591796874989],
            [-4.459863281249994, 12.63037109375],
            [-4.480615234374994, 12.672216796874991],
            [-4.227099609374989, 12.793701171875],
            [-4.225244140624994, 12.879492187499991],
            [-4.260644531249994, 12.975341796875],
            [-4.310253906249983, 13.052490234375],
            [-4.328710937499977, 13.119042968749994],
            [-4.258691406249994, 13.197314453124989],
            [-4.196191406249994, 13.256152343750003],
            [-4.151025390624994, 13.306201171875003],
            [-4.051171875, 13.382421875],
            [-3.947314453124989, 13.402197265624991],
            [-3.853466796874983, 13.37353515625],
            [-3.575781249999977, 13.194189453124991],
            [-3.527636718749989, 13.182714843749991],
            [-3.469921874999983, 13.196386718749991],
            [-3.396728515625, 13.243701171875003],
            [-3.3017578125, 13.28076171875],
            [-3.266748046874994, 13.40078125],
            [-3.270166015624994, 13.577441406250003],
            [-3.248632812499977, 13.658349609374994],
            [-3.198437499999983, 13.6728515625],
            [-3.038671874999977, 13.639111328124997],
            [-2.997216796874994, 13.637109375],
            [-2.950830078124994, 13.6484375],
            [-2.917089843749977, 13.679492187500003],
            [-2.918505859374989, 13.736376953125003],
            [-2.925878906249977, 13.786767578124994],
            [-2.873925781249994, 13.950732421875003],
            [-2.778857421874989, 14.07373046875],
            [-2.586718749999989, 14.227587890625003],
            [-2.526904296874989, 14.25830078125],
            [-2.457226562499983, 14.274121093749997],
            [-2.113232421874983, 14.16845703125],
            [-2.05712890625, 14.194628906250003],
            [-1.973046875, 14.45654296875],
            [-1.879785156249994, 14.481494140625003],
            [-1.767773437499983, 14.486035156249997],
            [-1.695068359375, 14.508496093749997],
            [-1.657324218749977, 14.526806640624997],
            [-1.49365234375, 14.626074218749991],
            [-1.204980468749994, 14.761523437500003],
            [-1.049560546875, 14.81953125],
            [-1.019189453124994, 14.841357421875003],
            [-0.907958984375, 14.937402343749994],
            [-0.760449218749983, 15.047753906249994],
            [-0.666455078124983, 15.069775390624997],
            [-0.536523437499994, 15.077880859375],
            [-0.454492187499994, 15.059667968749991],
            [-0.432275390624994, 15.028515625],
            [-0.405419921874994, 15.0125],
            [-0.235888671874989, 15.059423828124991],
            [0.00732421875, 14.984814453124997],
            [0.217480468750011, 14.911474609374991],
            [0.203808593750011, 14.865039062500003],
            [0.202734375, 14.7828125],
            [0.18505859375, 14.652929687499991],
            [0.163867187500017, 14.497216796874994],
            [0.250585937500006, 14.396435546874997],
            [0.354589843750006, 14.288037109374997],
            [0.382519531250011, 14.245800781249997],
            [0.354882812500023, 14.139013671874991],
            [0.3740234375, 14.076367187499997],
            [0.42919921875, 13.972119140624997],
            [0.522363281250023, 13.839746093749994],
            [0.6181640625, 13.703417968750003],
            [0.6845703125, 13.685400390624991],
            [0.747753906250011, 13.674511718749997],
            [0.786035156250023, 13.650048828124994],
            [0.84228515625, 13.626416015624997],
            [0.89794921875, 13.6109375],
            [0.946582031250017, 13.581152343749991],
            [0.977734375000011, 13.551953125],
            [1.017871093750017, 13.467871093749991],
            [1.1259765625, 13.412353515625],
            [1.201171875, 13.357519531249991],
            [1.1708984375, 13.32958984375],
            [1.076855468750011, 13.340771484374997],
            [0.988476562500011, 13.36484375],
            [0.976757812500011, 13.324511718750003],
            [0.973046875000023, 13.170361328124997],
            [0.9873046875, 13.041894531249994],
            [1.007910156250006, 13.024804687499994],
            [1.096777343750006, 13.001123046874994],
            [1.308691406250006, 12.834277343749989],
            [1.50048828125, 12.676464843749997],
            [1.56494140625, 12.635400390624994],
            [1.671093750000011, 12.619824218749997],
            [1.789843750000017, 12.61328125],
            [1.840917968750006, 12.627880859374997],
            [1.956152343750006, 12.707421875],
            [2.017382812500017, 12.716210937499994],
            [2.073828125, 12.713964843749991],
            [2.104589843750006, 12.701269531249991],
            [2.159765625, 12.636425781249997],
            [2.211523437500006, 12.538427734374991],
            [2.226269531250011, 12.466064453125],
            [2.221386718750011, 12.42724609375],
            [2.203808593750011, 12.41259765625],
            [2.109375, 12.393847656249989],
            [2.068554687500011, 12.379150390625],
            [2.058398437500017, 12.357958984374989],
            [2.072949218750011, 12.309375],
            [2.09140625, 12.277978515624994],
            [2.194433593750006, 12.136474609375],
            [2.343359375, 11.945996093749997],
            [2.38916015625, 11.897070312499991],
            [2.36328125, 11.840087890625],
            [2.287207031250006, 11.691259765624991],
            [2.230859375000023, 11.629150390625],
            [1.980371093750023, 11.418408203124997],
            [1.857617187500011, 11.443359375],
            [1.6, 11.400634765625],
            [1.561425781250023, 11.449121093749994],
            [1.501367187500023, 11.45556640625],
            [1.4267578125, 11.447119140624991],
            [1.399707031250017, 11.4287109375],
            [1.391503906250023, 11.408007812499989],
            [1.36484375, 11.37890625],
            [1.3173828125, 11.295263671874991],
            [1.280468750000011, 11.273974609374989],
            [1.234667968750017, 11.261035156249989],
            [1.1787109375, 11.262744140624989],
            [1.145800781250017, 11.251904296874997],
            [1.1455078125, 11.210400390624997],
            [1.135546875000017, 11.174365234374989],
            [1.097558593750023, 11.156347656249991],
            [1.08154296875, 11.116015624999989],
            [1.084570312500006, 11.076367187499997],
            [1.062304687500017, 11.058203125],
            [1.013867187500011, 11.068115234375],
            [0.985058593750011, 11.079003906249994],
            [0.9580078125, 11.027783203124997],
            [0.924609375000017, 10.992822265624994],
            [0.900488281250006, 10.993261718749991],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 6,
        sovereignt: "Togo",
        sov_a3: "TGO",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Togo",
        adm0_a3: "TGO",
        geou_dif: 0,
        geounit: "Togo",
        gu_a3: "TGO",
        su_dif: 0,
        subunit: "Togo",
        su_a3: "TGO",
        brk_diff: 0,
        name: "Togo",
        name_long: "Togo",
        brk_a3: "TGO",
        brk_name: "Togo",
        brk_group: null,
        abbrev: "Togo",
        postal: "TG",
        formal_en: "Togolese Republic",
        formal_fr: "République Togolaise",
        name_ciawf: "Togo",
        note_adm0: null,
        note_brk: null,
        name_sort: "Togo",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 1,
        mapcolor9: 3,
        mapcolor13: 5,
        pop_est: 8082366,
        pop_rank: 13,
        pop_year: 2019,
        gdp_md: 5490,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        fips_10: "TO",
        iso_a2: "TG",
        iso_a2_eh: "TG",
        iso_a3: "TGO",
        iso_a3_eh: "TGO",
        iso_n3: "768",
        iso_n3_eh: "768",
        un_a3: "768",
        wb_a2: "TG",
        wb_a3: "TGO",
        woe_id: 23424965,
        woe_id_eh: 23424965,
        woe_note: "Exact WOE match as country",
        adm0_iso: "TGO",
        adm0_diff: null,
        adm0_tlc: "TGO",
        adm0_a3_us: "TGO",
        adm0_a3_fr: "TGO",
        adm0_a3_ru: "TGO",
        adm0_a3_es: "TGO",
        adm0_a3_cn: "TGO",
        adm0_a3_tw: "TGO",
        adm0_a3_in: "TGO",
        adm0_a3_np: "TGO",
        adm0_a3_pk: "TGO",
        adm0_a3_de: "TGO",
        adm0_a3_gb: "TGO",
        adm0_a3_br: "TGO",
        adm0_a3_il: "TGO",
        adm0_a3_ps: "TGO",
        adm0_a3_sa: "TGO",
        adm0_a3_eg: "TGO",
        adm0_a3_ma: "TGO",
        adm0_a3_pt: "TGO",
        adm0_a3_ar: "TGO",
        adm0_a3_jp: "TGO",
        adm0_a3_ko: "TGO",
        adm0_a3_vn: "TGO",
        adm0_a3_tr: "TGO",
        adm0_a3_id: "TGO",
        adm0_a3_pl: "TGO",
        adm0_a3_gr: "TGO",
        adm0_a3_it: "TGO",
        adm0_a3_nl: "TGO",
        adm0_a3_se: "TGO",
        adm0_a3_bd: "TGO",
        adm0_a3_ua: "TGO",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Western Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 4,
        long_len: 4,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 5,
        max_label: 10,
        label_x: 1.058113,
        label_y: 8.80722,
        ne_id: 1159321303,
        wikidataid: "Q945",
        name_ar: "توغو",
        name_bn: "টোগো",
        name_de: "Togo",
        name_en: "Togo",
        name_es: "Togo",
        name_fa: "توگو",
        name_fr: "Togo",
        name_el: "Τόγκο",
        name_he: "טוגו",
        name_hi: "टोगो",
        name_hu: "Togo",
        name_id: "Togo",
        name_it: "Togo",
        name_ja: "トーゴ",
        name_ko: "토고",
        name_nl: "Togo",
        name_pl: "Togo",
        name_pt: "Togo",
        name_ru: "Того",
        name_sv: "Togo",
        name_tr: "Togo",
        name_uk: "Того",
        name_ur: "ٹوگو",
        name_vi: "Togo",
        name_zh: "多哥",
        name_zht: "多哥",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "TGO.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.900488281250006, 10.993261718749991],
            [0.874804687500017, 10.8857421875],
            [0.821875, 10.752587890624994],
            [0.7875, 10.710253906249989],
            [0.763378906250011, 10.386669921874997],
            [0.779980468750011, 10.359570312499997],
            [0.792187500000011, 10.3515625],
            [0.958300781250017, 10.242041015624991],
            [1.176171875000023, 10.098388671875],
            [1.330078125, 9.996972656249994],
            [1.342871093750006, 9.962939453124989],
            [1.345117187500023, 9.750195312499997],
            [1.347070312500023, 9.567529296874994],
            [1.37890625, 9.462988281249991],
            [1.3857421875, 9.361669921874991],
            [1.42431640625, 9.285009765624991],
            [1.566308593750023, 9.137255859374989],
            [1.600195312500006, 9.050048828125],
            [1.603808593750017, 8.77099609375],
            [1.606640625000011, 8.559277343749997],
            [1.624609375, 8.27099609375],
            [1.624609375, 8.030224609374997],
            [1.624609375, 7.725878906249989],
            [1.624707031250011, 7.369189453124989],
            [1.624707031250011, 6.997314453125],
            [1.530957031250011, 6.992431640625],
            [1.58203125, 6.877001953124989],
            [1.5908203125, 6.772265624999989],
            [1.602929687500023, 6.738085937499989],
            [1.577539062500023, 6.687402343749994],
            [1.598535156250023, 6.610205078124991],
            [1.639257812500006, 6.58154296875],
            [1.7431640625, 6.42626953125],
            [1.777929687500006, 6.294628906249997],
            [1.6109375, 6.250830078124991],
            [1.62265625, 6.216796875],
            [1.310644531250006, 6.146875],
            [1.187207031250011, 6.089404296874989],
            [1.18505859375, 6.14501953125],
            [1.1396484375, 6.155029296875],
            [1.08447265625, 6.173779296874997],
            [1.049902343750006, 6.20263671875],
            [1.002148437500011, 6.2685546875],
            [0.984960937500006, 6.3203125],
            [0.912207031250006, 6.328564453124997],
            [0.822460937500011, 6.386376953124994],
            [0.736914062500006, 6.452587890624997],
            [0.707226562500011, 6.51875],
            [0.715429687500006, 6.54931640625],
            [0.702246093750006, 6.580761718749997],
            [0.672753906250023, 6.592529296875],
            [0.595703125, 6.7421875],
            [0.548046875000011, 6.802490234375],
            [0.525585937500011, 6.850927734374991],
            [0.533398437500011, 6.888330078124994],
            [0.523046875, 6.938867187499994],
            [0.5380859375, 6.9796875],
            [0.579492187500023, 7.004101562499997],
            [0.592480468750011, 7.033984374999989],
            [0.59619140625, 7.096630859374997],
            [0.619531250000023, 7.2265625],
            [0.634765625, 7.353662109374994],
            [0.591015625000011, 7.388818359374994],
            [0.537304687500011, 7.398730468749989],
            [0.509570312500017, 7.435107421874989],
            [0.498925781250023, 7.4951171875],
            [0.5, 7.546875],
            [0.605175781250011, 7.728222656249997],
            [0.58359375, 8.145800781249989],
            [0.59921875, 8.209570312499991],
            [0.647070312500006, 8.253466796874989],
            [0.688085937500006, 8.304248046874989],
            [0.686328125000017, 8.354882812499994],
            [0.6162109375, 8.479638671874994],
            [0.483300781250023, 8.575292968749991],
            [0.415332031250017, 8.652734375],
            [0.378613281250011, 8.722021484374991],
            [0.37255859375, 8.75927734375],
            [0.453125, 8.813769531249989],
            [0.48876953125, 8.851464843749994],
            [0.493261718750006, 8.894921875],
            [0.460351562500023, 8.97421875],
            [0.466113281250017, 9.115332031249991],
            [0.497167968750006, 9.221240234374989],
            [0.529003906250011, 9.358300781249994],
            [0.525683593750017, 9.398486328124989],
            [0.447558593750017, 9.480273437499989],
            [0.4052734375, 9.491455078125],
            [0.370996093750023, 9.485546875],
            [0.289355468750017, 9.431835937499997],
            [0.259960937500011, 9.426025390625],
            [0.241503906250017, 9.44189453125],
            [0.2333984375, 9.463525390624994],
            [0.261914062500011, 9.49560546875],
            [0.2515625, 9.53564453125],
            [0.275488281250006, 9.570605468749989],
            [0.327343750000011, 9.586572265624994],
            [0.342578125000017, 9.604150390624994],
            [0.272753906250017, 9.620947265624991],
            [0.264550781250023, 9.644726562499997],
            [0.26953125, 9.667919921874997],
            [0.289648437500006, 9.672314453124997],
            [0.311718750000011, 9.670996093749991],
            [0.323925781250011, 9.687597656249991],
            [0.334570312500006, 9.803955078125],
            [0.343066406250017, 9.844580078124991],
            [0.351855468750017, 9.924902343749991],
            [0.362695312500023, 10.236474609374994],
            [0.378613281250011, 10.2685546875],
            [0.380859375, 10.291845703124991],
            [0.331835937500017, 10.306933593749989],
            [0.216015625000011, 10.390527343749994],
            [0.148242187500017, 10.454785156249997],
            [0.089257812500023, 10.520605468749991],
            [0.039453125000023, 10.563867187499994],
            [-0.057714843749977, 10.630615234375],
            [-0.086328125, 10.673046875],
            [-0.090185546874977, 10.715527343749997],
            [-0.060595703124989, 10.800585937499989],
            [-0.013867187499983, 10.891357421875],
            [0.009423828125023, 11.02099609375],
            [-0.004736328124977, 11.055566406249994],
            [-0.068603515625, 11.115625],
            [0.159277343750006, 11.069628906249989],
            [0.484179687500017, 10.991992187499989],
            [0.49072265625, 10.978173828124994],
            [0.49267578125, 10.954980468749994],
            [0.549121093750017, 10.955419921874991],
            [0.642968750000023, 10.983056640624994],
            [0.900488281250006, 10.993261718749991],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 6,
        sovereignt: "Guinea-Bissau",
        sov_a3: "GNB",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Guinea-Bissau",
        adm0_a3: "GNB",
        geou_dif: 0,
        geounit: "Guinea-Bissau",
        gu_a3: "GNB",
        su_dif: 0,
        subunit: "Guinea-Bissau",
        su_a3: "GNB",
        brk_diff: 0,
        name: "Guinea-Bissau",
        name_long: "Guinea-Bissau",
        brk_a3: "GNB",
        brk_name: "Guinea-Bissau",
        brk_group: null,
        abbrev: "GnB.",
        postal: "GW",
        formal_en: "Republic of Guinea-Bissau",
        formal_fr: null,
        name_ciawf: "Guinea-Bissau",
        note_adm0: null,
        note_brk: null,
        name_sort: "Guinea-Bissau",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 5,
        mapcolor9: 3,
        mapcolor13: 4,
        pop_est: 1920922,
        pop_rank: 12,
        pop_year: 2019,
        gdp_md: 1339,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        fips_10: "PU",
        iso_a2: "GW",
        iso_a2_eh: "GW",
        iso_a3: "GNB",
        iso_a3_eh: "GNB",
        iso_n3: "624",
        iso_n3_eh: "624",
        un_a3: "624",
        wb_a2: "GW",
        wb_a3: "GNB",
        woe_id: 23424929,
        woe_id_eh: 23424929,
        woe_note: "Exact WOE match as country",
        adm0_iso: "GNB",
        adm0_diff: null,
        adm0_tlc: "GNB",
        adm0_a3_us: "GNB",
        adm0_a3_fr: "GNB",
        adm0_a3_ru: "GNB",
        adm0_a3_es: "GNB",
        adm0_a3_cn: "GNB",
        adm0_a3_tw: "GNB",
        adm0_a3_in: "GNB",
        adm0_a3_np: "GNB",
        adm0_a3_pk: "GNB",
        adm0_a3_de: "GNB",
        adm0_a3_gb: "GNB",
        adm0_a3_br: "GNB",
        adm0_a3_il: "GNB",
        adm0_a3_ps: "GNB",
        adm0_a3_sa: "GNB",
        adm0_a3_eg: "GNB",
        adm0_a3_ma: "GNB",
        adm0_a3_pt: "GNB",
        adm0_a3_ar: "GNB",
        adm0_a3_jp: "GNB",
        adm0_a3_ko: "GNB",
        adm0_a3_vn: "GNB",
        adm0_a3_tr: "GNB",
        adm0_a3_id: "GNB",
        adm0_a3_pl: "GNB",
        adm0_a3_gr: "GNB",
        adm0_a3_it: "GNB",
        adm0_a3_nl: "GNB",
        adm0_a3_se: "GNB",
        adm0_a3_bd: "GNB",
        adm0_a3_ua: "GNB",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Western Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 13,
        long_len: 13,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 5,
        max_label: 10,
        label_x: -14.52413,
        label_y: 12.163712,
        ne_id: 1159320799,
        wikidataid: "Q1007",
        name_ar: "غينيا بيساو",
        name_bn: "গিনি-বিসাউ",
        name_de: "Guinea-Bissau",
        name_en: "Guinea-Bissau",
        name_es: "Guinea-Bisáu",
        name_fa: "گینه بیسائو",
        name_fr: "Guinée-Bissau",
        name_el: "Γουινέα-Μπισσάου",
        name_he: "גינאה ביסאו",
        name_hi: "गिनी-बिसाऊ",
        name_hu: "Bissau-Guinea",
        name_id: "Guinea-Bissau",
        name_it: "Guinea-Bissau",
        name_ja: "ギニアビサウ",
        name_ko: "기니비사우",
        name_nl: "Guinee-Bissau",
        name_pl: "Gwinea Bissau",
        name_pt: "Guiné-Bissau",
        name_ru: "Гвинея-Бисау",
        name_sv: "Guinea-Bissau",
        name_tr: "Gine-Bissau",
        name_uk: "Гвінея-Бісау",
        name_ur: "گنی بساؤ",
        name_vi: "Guiné-Bissau",
        name_zh: "几内亚比绍",
        name_zht: "幾內亞比索",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "GNB.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-16.711816406249994, 12.354833984374991],
              [-16.656933593749983, 12.364355468749991],
              [-16.521337890624977, 12.3486328125],
              [-16.41630859374999, 12.36767578125],
              [-16.34228515625, 12.399511718749991],
              [-16.24150390624999, 12.443310546874997],
              [-16.144189453124994, 12.457421875],
              [-15.839550781249983, 12.437890625],
              [-15.574804687499977, 12.490380859374994],
              [-15.3779296875, 12.588964843749991],
              [-15.196093749999989, 12.679931640625],
              [-14.960595703124994, 12.678955078125],
              [-14.708154296874994, 12.677978515625],
              [-14.349218749999977, 12.676416015624994],
              [-14.06484375, 12.67529296875],
              [-13.729248046875, 12.673925781249991],
              [-13.732617187499983, 12.592822265624989],
              [-13.673535156249983, 12.478515625],
              [-13.682373046875, 12.393408203124991],
              [-13.707910156249994, 12.312695312499997],
              [-13.730078124999977, 12.280810546874989],
              [-13.759765625, 12.262353515624994],
              [-13.849462890624977, 12.262988281249989],
              [-13.8875, 12.246875],
              [-13.947314453124989, 12.215234375],
              [-13.948876953124994, 12.178173828124997],
              [-13.901171874999989, 12.142871093749989],
              [-13.861914062499977, 12.093310546874989],
              [-13.816308593749994, 12.054492187499989],
              [-13.737988281249983, 12.009667968749994],
              [-13.730664062499983, 11.959863281249994],
              [-13.728564453124989, 11.834130859374994],
              [-13.732763671874977, 11.736035156249997],
              [-13.953222656249977, 11.664599609374989],
              [-14.122314453125, 11.651953125],
              [-14.265576171874983, 11.659912109375],
              [-14.327832031249983, 11.629785156249994],
              [-14.452441406249989, 11.556201171874989],
              [-14.604785156249989, 11.511621093749994],
              [-14.682958984374977, 11.508496093749997],
              [-14.720263671874989, 11.48193359375],
              [-14.779296875, 11.405517578125],
              [-14.944433593749977, 11.072167968749994],
              [-14.9990234375, 10.9921875],
              [-15.043017578124989, 10.940136718749997],
              [-15.09375, 11.011035156249989],
              [-15.054589843749994, 11.141943359374991],
              [-15.096777343749977, 11.140039062499994],
              [-15.181054687499994, 11.034228515624989],
              [-15.222119140624983, 11.030908203124994],
              [-15.216699218749994, 11.15625],
              [-15.263378906249983, 11.160888671875],
              [-15.317480468749977, 11.152001953124994],
              [-15.393115234374989, 11.217236328124997],
              [-15.400585937499983, 11.266210937499991],
              [-15.39453125, 11.33447265625],
              [-15.348437499999989, 11.378076171874994],
              [-15.354687499999983, 11.396337890624991],
              [-15.399169921875, 11.401464843749991],
              [-15.448974609375, 11.389746093749991],
              [-15.4794921875, 11.410302734374994],
              [-15.429101562499994, 11.498876953124991],
              [-15.252587890624994, 11.573291015624989],
              [-15.163769531249983, 11.580957031249994],
              [-15.07265625, 11.597802734374994],
              [-15.122412109374977, 11.661572265624997],
              [-15.230371093749994, 11.686767578125],
              [-15.316699218749989, 11.669189453125],
              [-15.359667968749989, 11.622900390624991],
              [-15.412988281249994, 11.615234375],
              [-15.501904296874983, 11.723779296874994],
              [-15.500244140625, 11.778369140624989],
              [-15.4671875, 11.842822265624989],
              [-15.415722656249983, 11.871777343749997],
              [-15.210839843749994, 11.870947265624991],
              [-15.133105468749989, 11.907324218749991],
              [-15.101708984374994, 11.913964843749994],
              [-15.071972656249983, 11.947021484375],
              [-15.078271484374994, 11.968994140625],
              [-15.111523437499983, 11.970263671874989],
              [-15.188085937499977, 11.927294921874989],
              [-15.434765624999983, 11.943554687499997],
              [-15.513476562499989, 11.917578125],
              [-15.650683593749989, 11.818359375],
              [-15.819384765624989, 11.763476562499989],
              [-15.941748046874977, 11.78662109375],
              [-15.902734375, 11.919677734375],
              [-15.920214843749989, 11.937792968749989],
              [-15.958789062499989, 11.959619140624994],
              [-16.138427734375, 11.917285156249989],
              [-16.274316406249994, 11.978125],
              [-16.328076171874983, 12.051611328124991],
              [-16.31884765625, 12.14375],
              [-16.254736328124977, 12.2060546875],
              [-16.244580078124983, 12.237109374999989],
              [-16.31230468749999, 12.243017578124991],
              [-16.43681640624999, 12.204150390624989],
              [-16.711816406249994, 12.354833984374991],
            ],
          ],
          [
            [
              [-16.114501953125, 11.059423828124991],
              [-16.194531249999983, 11.044580078124994],
              [-16.23100585937499, 11.09423828125],
              [-16.236425781249977, 11.113427734374994],
              [-16.19462890624999, 11.130126953125],
              [-16.175878906249977, 11.130810546874997],
              [-16.14404296875, 11.166845703124991],
              [-16.10478515624999, 11.191015625],
              [-16.08745117187499, 11.198779296874989],
              [-16.067333984374983, 11.197216796874997],
              [-16.05278320312499, 11.117529296874991],
              [-16.072216796874983, 11.084082031249991],
              [-16.114501953125, 11.059423828124991],
            ],
          ],
          [
            [
              [-15.895898437499994, 11.082470703124997],
              [-15.905175781249994, 11.054736328124989],
              [-15.963964843749977, 11.058984375],
              [-15.950634765624983, 11.087109375],
              [-15.963476562499977, 11.0953125],
              [-15.946484374999983, 11.179736328124989],
              [-15.937695312499983, 11.192773437499994],
              [-15.909130859374983, 11.161328125],
              [-15.9052734375, 11.148339843749994],
              [-15.895898437499994, 11.082470703124997],
            ],
          ],
          [
            [
              [-15.725146484374989, 11.215478515624994],
              [-15.725146484374989, 11.174511718749997],
              [-15.767480468749994, 11.182275390624994],
              [-15.77978515625, 11.19453125],
              [-15.754687499999989, 11.268701171874994],
              [-15.717480468749983, 11.3017578125],
              [-15.671923828124989, 11.296484375],
              [-15.658349609374994, 11.286474609374991],
              [-15.667187499999983, 11.257861328124989],
              [-15.687109374999977, 11.234326171874997],
              [-15.725146484374989, 11.215478515624994],
            ],
          ],
          [
            [
              [-15.553417968749983, 11.537011718749994],
              [-15.562792968749989, 11.513769531249991],
              [-15.61962890625, 11.533496093749989],
              [-15.536572265624983, 11.617626953124997],
              [-15.482470703124989, 11.63232421875],
              [-15.484423828124989, 11.567529296874994],
              [-15.526220703124977, 11.553857421874994],
              [-15.553417968749983, 11.537011718749994],
            ],
          ],
          [
            [
              [-15.901806640624983, 11.4658203125],
              [-15.94873046875, 11.434423828124991],
              [-15.997216796874994, 11.449169921874997],
              [-16.023193359375, 11.477148437499991],
              [-16.01933593749999, 11.527294921874997],
              [-15.964550781249983, 11.598291015624994],
              [-15.915332031249989, 11.589111328125],
              [-15.901806640624983, 11.4658203125],
            ],
          ],
          [
            [
              [-15.986425781249977, 11.88203125],
              [-16.038330078125, 11.759716796874997],
              [-16.102441406249994, 11.773193359375],
              [-16.147363281249994, 11.845996093749989],
              [-16.152441406249977, 11.876806640624991],
              [-16.021875, 11.886669921874997],
              [-15.986425781249977, 11.88203125],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Ghana",
        sov_a3: "GHA",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Ghana",
        adm0_a3: "GHA",
        geou_dif: 0,
        geounit: "Ghana",
        gu_a3: "GHA",
        su_dif: 0,
        subunit: "Ghana",
        su_a3: "GHA",
        brk_diff: 0,
        name: "Ghana",
        name_long: "Ghana",
        brk_a3: "GHA",
        brk_name: "Ghana",
        brk_group: null,
        abbrev: "Ghana",
        postal: "GH",
        formal_en: "Republic of Ghana",
        formal_fr: null,
        name_ciawf: "Ghana",
        note_adm0: null,
        note_brk: null,
        name_sort: "Ghana",
        name_alt: null,
        mapcolor7: 5,
        mapcolor8: 3,
        mapcolor9: 1,
        mapcolor13: 4,
        pop_est: 30417856,
        pop_rank: 15,
        pop_year: 2019,
        gdp_md: 66983,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        fips_10: "GH",
        iso_a2: "GH",
        iso_a2_eh: "GH",
        iso_a3: "GHA",
        iso_a3_eh: "GHA",
        iso_n3: "288",
        iso_n3_eh: "288",
        un_a3: "288",
        wb_a2: "GH",
        wb_a3: "GHA",
        woe_id: 23424824,
        woe_id_eh: 23424824,
        woe_note: "Exact WOE match as country",
        adm0_iso: "GHA",
        adm0_diff: null,
        adm0_tlc: "GHA",
        adm0_a3_us: "GHA",
        adm0_a3_fr: "GHA",
        adm0_a3_ru: "GHA",
        adm0_a3_es: "GHA",
        adm0_a3_cn: "GHA",
        adm0_a3_tw: "GHA",
        adm0_a3_in: "GHA",
        adm0_a3_np: "GHA",
        adm0_a3_pk: "GHA",
        adm0_a3_de: "GHA",
        adm0_a3_gb: "GHA",
        adm0_a3_br: "GHA",
        adm0_a3_il: "GHA",
        adm0_a3_ps: "GHA",
        adm0_a3_sa: "GHA",
        adm0_a3_eg: "GHA",
        adm0_a3_ma: "GHA",
        adm0_a3_pt: "GHA",
        adm0_a3_ar: "GHA",
        adm0_a3_jp: "GHA",
        adm0_a3_ko: "GHA",
        adm0_a3_vn: "GHA",
        adm0_a3_tr: "GHA",
        adm0_a3_id: "GHA",
        adm0_a3_pl: "GHA",
        adm0_a3_gr: "GHA",
        adm0_a3_it: "GHA",
        adm0_a3_nl: "GHA",
        adm0_a3_se: "GHA",
        adm0_a3_bd: "GHA",
        adm0_a3_ua: "GHA",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Western Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 5,
        long_len: 5,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 2.7,
        max_label: 8,
        label_x: -1.036941,
        label_y: 7.717639,
        ne_id: 1159320793,
        wikidataid: "Q117",
        name_ar: "غانا",
        name_bn: "ঘানা",
        name_de: "Ghana",
        name_en: "Ghana",
        name_es: "Ghana",
        name_fa: "غنا",
        name_fr: "Ghana",
        name_el: "Γκάνα",
        name_he: "גאנה",
        name_hi: "घाना",
        name_hu: "Ghána",
        name_id: "Ghana",
        name_it: "Ghana",
        name_ja: "ガーナ",
        name_ko: "가나",
        name_nl: "Ghana",
        name_pl: "Ghana",
        name_pt: "Gana",
        name_ru: "Гана",
        name_sv: "Ghana",
        name_tr: "Gana",
        name_uk: "Гана",
        name_ur: "گھانا",
        name_vi: "Ghana",
        name_zh: "加纳",
        name_zht: "迦納",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "GHA.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.068603515625, 11.115625],
            [-0.004736328124977, 11.055566406249994],
            [0.009423828125023, 11.02099609375],
            [-0.013867187499983, 10.891357421875],
            [-0.060595703124989, 10.800585937499989],
            [-0.090185546874977, 10.715527343749997],
            [-0.086328125, 10.673046875],
            [-0.057714843749977, 10.630615234375],
            [0.039453125000023, 10.563867187499994],
            [0.089257812500023, 10.520605468749991],
            [0.148242187500017, 10.454785156249997],
            [0.216015625000011, 10.390527343749994],
            [0.331835937500017, 10.306933593749989],
            [0.380859375, 10.291845703124991],
            [0.378613281250011, 10.2685546875],
            [0.362695312500023, 10.236474609374994],
            [0.351855468750017, 9.924902343749991],
            [0.343066406250017, 9.844580078124991],
            [0.334570312500006, 9.803955078125],
            [0.323925781250011, 9.687597656249991],
            [0.311718750000011, 9.670996093749991],
            [0.289648437500006, 9.672314453124997],
            [0.26953125, 9.667919921874997],
            [0.264550781250023, 9.644726562499997],
            [0.272753906250017, 9.620947265624991],
            [0.342578125000017, 9.604150390624994],
            [0.327343750000011, 9.586572265624994],
            [0.275488281250006, 9.570605468749989],
            [0.2515625, 9.53564453125],
            [0.261914062500011, 9.49560546875],
            [0.2333984375, 9.463525390624994],
            [0.241503906250017, 9.44189453125],
            [0.259960937500011, 9.426025390625],
            [0.289355468750017, 9.431835937499997],
            [0.370996093750023, 9.485546875],
            [0.4052734375, 9.491455078125],
            [0.447558593750017, 9.480273437499989],
            [0.525683593750017, 9.398486328124989],
            [0.529003906250011, 9.358300781249994],
            [0.497167968750006, 9.221240234374989],
            [0.466113281250017, 9.115332031249991],
            [0.460351562500023, 8.97421875],
            [0.493261718750006, 8.894921875],
            [0.48876953125, 8.851464843749994],
            [0.453125, 8.813769531249989],
            [0.37255859375, 8.75927734375],
            [0.378613281250011, 8.722021484374991],
            [0.415332031250017, 8.652734375],
            [0.483300781250023, 8.575292968749991],
            [0.6162109375, 8.479638671874994],
            [0.686328125000017, 8.354882812499994],
            [0.688085937500006, 8.304248046874989],
            [0.647070312500006, 8.253466796874989],
            [0.59921875, 8.209570312499991],
            [0.58359375, 8.145800781249989],
            [0.605175781250011, 7.728222656249997],
            [0.5, 7.546875],
            [0.498925781250023, 7.4951171875],
            [0.509570312500017, 7.435107421874989],
            [0.537304687500011, 7.398730468749989],
            [0.591015625000011, 7.388818359374994],
            [0.634765625, 7.353662109374994],
            [0.619531250000023, 7.2265625],
            [0.59619140625, 7.096630859374997],
            [0.592480468750011, 7.033984374999989],
            [0.579492187500023, 7.004101562499997],
            [0.5380859375, 6.9796875],
            [0.523046875, 6.938867187499994],
            [0.533398437500011, 6.888330078124994],
            [0.525585937500011, 6.850927734374991],
            [0.548046875000011, 6.802490234375],
            [0.595703125, 6.7421875],
            [0.672753906250023, 6.592529296875],
            [0.702246093750006, 6.580761718749997],
            [0.715429687500006, 6.54931640625],
            [0.707226562500011, 6.51875],
            [0.736914062500006, 6.452587890624997],
            [0.822460937500011, 6.386376953124994],
            [0.912207031250006, 6.328564453124997],
            [0.984960937500006, 6.3203125],
            [1.002148437500011, 6.2685546875],
            [1.049902343750006, 6.20263671875],
            [1.08447265625, 6.173779296874997],
            [1.1396484375, 6.155029296875],
            [1.18505859375, 6.14501953125],
            [1.187207031250011, 6.089404296874989],
            [1.105566406250006, 6.051367187499991],
            [1.05029296875, 5.993994140624991],
            [1.008007812500011, 5.906396484374994],
            [0.94970703125, 5.810253906249997],
            [0.748828125000017, 5.760107421874991],
            [0.671875, 5.759716796874997],
            [0.259667968750023, 5.75732421875],
            [-0.126513671874989, 5.568164062499989],
            [-0.348730468749977, 5.500781249999989],
            [-0.485449218749977, 5.394238281249997],
            [-0.66943359375, 5.318554687499997],
            [-0.797705078124977, 5.226708984374994],
            [-1.064306640624977, 5.182666015624989],
            [-1.501660156249983, 5.037988281249994],
            [-1.638476562499989, 4.980859375],
            [-1.77685546875, 4.88037109375],
            [-2.001855468749994, 4.762451171875],
            [-2.090185546874977, 4.7640625],
            [-2.266406249999989, 4.874072265624989],
            [-2.39892578125, 4.929345703124994],
            [-2.723046875, 5.013720703124989],
            [-2.964990234374994, 5.046289062499994],
            [-3.081884765624977, 5.082470703124997],
            [-3.114013671875, 5.088671874999989],
            [-3.086718749999989, 5.128320312499994],
            [-3.019140624999977, 5.130810546874997],
            [-2.948339843749977, 5.118847656249997],
            [-2.894726562499983, 5.149023437499991],
            [-2.815673828125, 5.153027343749997],
            [-2.795214843749989, 5.184521484374997],
            [-2.788671874999977, 5.264111328124997],
            [-2.789599609374989, 5.328222656249991],
            [-2.761914062499983, 5.35693359375],
            [-2.754980468749977, 5.432519531249994],
            [-2.793652343749983, 5.60009765625],
            [-2.821191406249994, 5.619189453124989],
            [-2.962255859374977, 5.643017578124997],
            [-2.972802734374994, 5.67626953125],
            [-2.998291015625, 5.711328125],
            [-3.025292968749994, 5.797753906249994],
            [-3.05615234375, 5.92626953125],
            [-3.105566406249977, 6.085644531249997],
            [-3.200585937499994, 6.348242187499991],
            [-3.224023437499994, 6.441064453124994],
            [-3.240283203124989, 6.53564453125],
            [-3.243896484375, 6.648681640625],
            [-3.22412109375, 6.690771484374991],
            [-3.227148437499977, 6.749121093749991],
            [-3.235791015624983, 6.807226562499991],
            [-3.168896484374983, 6.940966796874989],
            [-3.037695312499977, 7.104589843749991],
            [-3.01015625, 7.163769531249997],
            [-2.985791015624983, 7.204882812499989],
            [-2.982324218749994, 7.263623046874997],
            [-2.959082031249977, 7.454541015624997],
            [-2.896337890624977, 7.685009765624997],
            [-2.856884765624983, 7.772070312499991],
            [-2.830126953124989, 7.819042968749997],
            [-2.798144531249989, 7.89599609375],
            [-2.789746093749983, 7.931933593749989],
            [-2.668847656249994, 8.022216796875],
            [-2.613378906249977, 8.046679687499989],
            [-2.600976562499994, 8.082226562499997],
            [-2.619970703124977, 8.12109375],
            [-2.61171875, 8.147558593749991],
            [-2.582763671875, 8.160791015624994],
            [-2.538281249999983, 8.171630859375],
            [-2.505859375, 8.208740234375],
            [-2.556884765625, 8.493017578124991],
            [-2.597998046874977, 8.7763671875],
            [-2.600390624999989, 8.800439453124994],
            [-2.624902343749994, 8.839599609375],
            [-2.649218749999989, 8.956591796874989],
            [-2.689892578124983, 9.025097656249997],
            [-2.746923828124977, 9.045117187499997],
            [-2.746679687499977, 9.109619140625],
            [-2.689208984375, 9.218603515624991],
            [-2.67421875, 9.282617187499994],
            [-2.701806640624994, 9.301660156249994],
            [-2.705761718749983, 9.351367187499989],
            [-2.686132812499977, 9.431738281249991],
            [-2.695849609374989, 9.481347656249994],
            [-2.706201171874994, 9.533935546875],
            [-2.765966796874977, 9.658056640624991],
            [-2.780517578125, 9.745849609375],
            [-2.749804687499989, 9.797216796874991],
            [-2.750732421875, 9.90966796875],
            [-2.783203125, 10.083105468749991],
            [-2.788476562499994, 10.192578125],
            [-2.766503906249994, 10.238183593749994],
            [-2.777099609375, 10.281591796874991],
            [-2.8203125, 10.322851562499991],
            [-2.823437499999983, 10.362939453124994],
            [-2.78662109375, 10.401904296874989],
            [-2.791162109374994, 10.432421874999989],
            [-2.837207031249989, 10.454638671874989],
            [-2.87841796875, 10.507958984374994],
            [-2.914892578124977, 10.592333984374989],
            [-2.907324218749977, 10.727978515624997],
            [-2.838574218749983, 10.977490234374997],
            [-2.829931640624977, 10.998388671874991],
            [-2.752099609374994, 10.996972656249994],
            [-2.751660156249983, 10.986376953124989],
            [-2.509179687499994, 10.988720703124997],
            [-2.23193359375, 10.99140625],
            [-1.900634765625, 10.994677734374989],
            [-1.599658203124989, 10.99765625],
            [-1.586474609374989, 11.008886718749991],
            [-1.536767578124994, 11.02265625],
            [-1.232617187499983, 10.997216796874994],
            [-1.04248046875, 11.010058593749989],
            [-0.961816406249994, 11.001708984375],
            [-0.902929687499977, 10.984716796874991],
            [-0.771582031249977, 10.995263671874994],
            [-0.701416015625, 10.988964843749997],
            [-0.648535156249977, 10.9267578125],
            [-0.627148437499983, 10.927392578124994],
            [-0.59765625, 10.953662109374989],
            [-0.545214843749989, 10.983691406249989],
            [-0.49169921875, 11.007617187499989],
            [-0.453515625, 11.056298828124994],
            [-0.430322265624994, 11.09326171875],
            [-0.395605468749977, 11.085693359375],
            [-0.345751953124989, 11.087939453124989],
            [-0.312548828124989, 11.118896484375],
            [-0.299462890624994, 11.166894531249994],
            [-0.068603515625, 11.115625],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 2,
        sovereignt: "Egypt",
        sov_a3: "EGY",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Egypt",
        adm0_a3: "EGY",
        geou_dif: 0,
        geounit: "Egypt",
        gu_a3: "EGY",
        su_dif: 0,
        subunit: "Egypt",
        su_a3: "EGY",
        brk_diff: 0,
        name: "Egypt",
        name_long: "Egypt",
        brk_a3: "EGY",
        brk_name: "Egypt",
        brk_group: null,
        abbrev: "Egypt",
        postal: "EG",
        formal_en: "Arab Republic of Egypt",
        formal_fr: null,
        name_ciawf: "Egypt",
        note_adm0: null,
        note_brk: null,
        name_sort: "Egypt, Arab Rep.",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 6,
        mapcolor9: 7,
        mapcolor13: 2,
        pop_est: 100388073,
        pop_rank: 17,
        pop_year: 2019,
        gdp_md: 303092,
        gdp_year: 2019,
        economy: "5. Emerging region: G20",
        income_grp: "4. Lower middle income",
        fips_10: "EG",
        iso_a2: "EG",
        iso_a2_eh: "EG",
        iso_a3: "EGY",
        iso_a3_eh: "EGY",
        iso_n3: "818",
        iso_n3_eh: "818",
        un_a3: "818",
        wb_a2: "EG",
        wb_a3: "EGY",
        woe_id: 23424802,
        woe_id_eh: 23424802,
        woe_note: "Exact WOE match as country",
        adm0_iso: "EGY",
        adm0_diff: null,
        adm0_tlc: "EGY",
        adm0_a3_us: "EGY",
        adm0_a3_fr: "EGY",
        adm0_a3_ru: "EGY",
        adm0_a3_es: "EGY",
        adm0_a3_cn: "EGY",
        adm0_a3_tw: "EGY",
        adm0_a3_in: "EGY",
        adm0_a3_np: "EGY",
        adm0_a3_pk: "EGY",
        adm0_a3_de: "EGY",
        adm0_a3_gb: "EGY",
        adm0_a3_br: "EGY",
        adm0_a3_il: "EGY",
        adm0_a3_ps: "EGY",
        adm0_a3_sa: "EGY",
        adm0_a3_eg: "EGY",
        adm0_a3_ma: "EGY",
        adm0_a3_pt: "EGY",
        adm0_a3_ar: "EGY",
        adm0_a3_jp: "EGY",
        adm0_a3_ko: "EGY",
        adm0_a3_vn: "EGY",
        adm0_a3_tr: "EGY",
        adm0_a3_id: "EGY",
        adm0_a3_pl: "EGY",
        adm0_a3_gr: "EGY",
        adm0_a3_it: "EGY",
        adm0_a3_nl: "EGY",
        adm0_a3_se: "EGY",
        adm0_a3_bd: "EGY",
        adm0_a3_ua: "EGY",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Northern Africa",
        region_wb: "Middle East & North Africa",
        name_len: 5,
        long_len: 5,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 1.7,
        max_label: 6.7,
        label_x: 29.445837,
        label_y: 26.186173,
        ne_id: 1159320575,
        wikidataid: "Q79",
        name_ar: "مصر",
        name_bn: "মিশর",
        name_de: "Ägypten",
        name_en: "Egypt",
        name_es: "Egipto",
        name_fa: "مصر",
        name_fr: "Égypte",
        name_el: "Αίγυπτος",
        name_he: "מצרים",
        name_hi: "मिस्र",
        name_hu: "Egyiptom",
        name_id: "Mesir",
        name_it: "Egitto",
        name_ja: "エジプト",
        name_ko: "이집트",
        name_nl: "Egypte",
        name_pl: "Egipt",
        name_pt: "Egito",
        name_ru: "Египет",
        name_sv: "Egypten",
        name_tr: "Mısır",
        name_uk: "Єгипет",
        name_ur: "مصر",
        name_vi: "Ai Cập",
        name_zh: "埃及",
        name_zht: "埃及",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "EGY.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [36.87138671875002, 21.996728515624994],
            [36.54326171875002, 21.996630859375003],
            [36.21523437500002, 21.99658203125],
            [35.88701171875002, 21.996533203124997],
            [35.55898437500002, 21.996484375],
            [35.23085937500002, 21.99643554687499],
            [34.90273437500002, 21.996386718750003],
            [34.57460937500002, 21.996337890625],
            [34.24648437500002, 21.996289062499997],
            [33.91845703125, 21.996240234374994],
            [33.59033203125, 21.99619140624999],
            [33.26220703125, 21.996142578125003],
            [32.93408203125, 21.99609375],
            [32.606054687500006, 21.995996093749994],
            [32.27783203125, 21.995996093749994],
            [31.949804687500006, 21.995898437500003],
            [31.621679687500006, 21.995849609375],
            [31.434472656250023, 21.995849609375],
            [31.46640625, 22.084667968749997],
            [31.486132812500017, 22.14780273437499],
            [31.464257812500023, 22.19150390624999],
            [31.400292968750023, 22.202441406250003],
            [31.358496093750006, 22.188623046874994],
            [31.260644531250023, 22.00229492187499],
            [31.20917968750001, 21.994873046875],
            [31.092675781250023, 21.994873046875],
            [30.71064453125001, 21.994921875],
            [30.32861328125, 21.995019531249994],
            [29.946679687500023, 21.9951171875],
            [29.564550781250006, 21.9951171875],
            [29.182519531250023, 21.99521484374999],
            [28.800585937500017, 21.995263671874994],
            [28.418554687500006, 21.9953125],
            [28.036425781250017, 21.995361328125],
            [27.65449218750001, 21.99545898437499],
            [27.2724609375, 21.995507812499994],
            [26.890429687500017, 21.995556640624997],
            [26.508398437500006, 21.99560546875],
            [26.126367187500023, 21.995654296875003],
            [25.74433593750001, 21.995751953124994],
            [25.3623046875, 21.995800781249997],
            [24.980273437500017, 21.995849609375],
            [24.980273437500017, 22.220410156249997],
            [24.980273437500017, 22.444970703124994],
            [24.980273437500017, 22.66953125],
            [24.980273437500017, 22.894091796875003],
            [24.980273437500017, 23.11865234375],
            [24.980273437500017, 23.343212890624997],
            [24.980273437500017, 23.567822265624997],
            [24.980273437500017, 23.792382812499994],
            [24.980273437500017, 24.01694335937499],
            [24.980273437500017, 24.241503906250003],
            [24.980273437500017, 24.466064453125],
            [24.980273437500017, 24.690625],
            [24.980273437500017, 24.915185546874994],
            [24.980273437500017, 25.13974609374999],
            [24.980273437500017, 25.364306640625003],
            [24.980273437500017, 25.5888671875],
            [24.980273437500017, 25.813427734374997],
            [24.980273437500017, 26.037988281249994],
            [24.980273437500017, 26.262548828125],
            [24.980273437500017, 26.487109375],
            [24.980273437500017, 26.711669921875],
            [24.980273437500017, 26.936230468749997],
            [24.980273437500017, 27.160839843749997],
            [24.980273437500017, 27.385400390624994],
            [24.980273437500017, 27.6099609375],
            [24.980273437500017, 27.834521484374996],
            [24.980273437500017, 28.05908203125],
            [24.980273437500017, 28.283642578124997],
            [24.980273437500017, 28.508203125],
            [24.980273437500017, 28.732763671875],
            [24.980273437500017, 28.957324218749996],
            [24.980273437500017, 29.181884765625],
            [24.9716796875, 29.223828125],
            [24.916113281250006, 29.376269531249996],
            [24.86591796875001, 29.570263671874997],
            [24.810839843750017, 29.808740234374994],
            [24.8037109375, 29.886035156249996],
            [24.71162109375001, 30.131542968749997],
            [24.703222656250006, 30.201074218749994],
            [24.726464843750023, 30.2505859375],
            [24.877539062500006, 30.45751953125],
            [24.92304687500001, 30.558007812499994],
            [24.96142578125, 30.678515625],
            [24.973925781250017, 30.7765625],
            [24.929492187500017, 30.926464843749997],
            [24.877539062500006, 31.061230468749997],
            [24.859960937500006, 31.199169921874997],
            [24.85273437500001, 31.334814453125],
            [24.929980468750017, 31.427490234375],
            [25.02265625000001, 31.514013671875],
            [25.057226562500006, 31.5671875],
            [25.11201171875001, 31.626904296874997],
            [25.150488281250006, 31.654980468749997],
            [25.225488281250023, 31.5337890625],
            [25.382226562500023, 31.51279296875],
            [25.89326171875001, 31.620898437499996],
            [26.457324218750017, 31.512109375],
            [26.768652343750006, 31.470361328124994],
            [27.248046875, 31.377880859374997],
            [27.5400390625, 31.212695312499996],
            [27.6201171875, 31.191748046875],
            [27.829980468750023, 31.195019531249997],
            [27.967578125000017, 31.097412109375],
            [28.51484375000001, 31.050439453124994],
            [28.806933593750017, 30.942675781249996],
            [28.972753906250006, 30.856738281249996],
            [29.072070312500017, 30.830273437499997],
            [29.159960937500017, 30.8345703125],
            [29.27890625, 30.866943359375],
            [29.428515625000017, 30.927441406249997],
            [29.591601562500017, 31.011523437499996],
            [29.929785156250006, 31.227490234374997],
            [30.049414062500006, 31.265429687499996],
            [30.127539062500006, 31.255664062499996],
            [30.22265625, 31.2583984375],
            [30.262304687500006, 31.316845703124997],
            [30.312304687500017, 31.35703125],
            [30.34375, 31.402734375],
            [30.395117187500006, 31.4576171875],
            [30.57099609375001, 31.472998046875],
            [30.92353515625001, 31.566845703124997],
            [30.884179687500023, 31.522363281249994],
            [30.56298828125, 31.4169921875],
            [30.700488281250017, 31.403857421874996],
            [30.84140625, 31.439892578124997],
            [31.001757812500017, 31.462792968749994],
            [31.030859375, 31.507568359375],
            [31.05195312500001, 31.591552734375],
            [31.082910156250023, 31.603320312499996],
            [31.193945312500006, 31.587597656249997],
            [31.5244140625, 31.458251953125],
            [31.606542968750006, 31.455761718749997],
            [31.839257812500023, 31.526318359374997],
            [31.888964843750017, 31.54140625],
            [31.964257812500023, 31.502099609374994],
            [32.13603515625002, 31.341064453125],
            [32.07607421875002, 31.344482421875],
            [31.8921875, 31.482470703124996],
            [31.875878906250023, 31.413720703124994],
            [31.77109375, 31.292578125],
            [31.90205078125001, 31.240185546874997],
            [32.00849609375001, 31.220507812499996],
            [32.065625, 31.152978515624994],
            [32.10175781250001, 31.092822265624996],
            [32.20654296875, 31.119042968749994],
            [32.281835937500006, 31.200878906249997],
            [32.242773437500006, 31.246533203124997],
            [32.21621093750002, 31.29375],
            [32.250585937500006, 31.294921875],
            [32.32353515625002, 31.256054687499997],
            [32.5328125, 31.100732421874994],
            [32.60332031250002, 31.06875],
            [32.6845703125, 31.074023437499996],
            [32.8544921875, 31.117724609374996],
            [32.90156250000001, 31.1109375],
            [33.1298828125, 31.168164062499997],
            [33.15673828125, 31.126220703125],
            [33.1943359375, 31.084521484374996],
            [33.3779296875, 31.13095703125],
            [33.66650390625, 31.130419921874996],
            [33.90253906250001, 31.180957031249996],
            [34.17626953125, 31.30390625],
            [34.19814453125002, 31.322607421875],
            [34.2125, 31.292285156249996],
            [34.24531250000001, 31.208300781249996],
            [34.32851562500002, 30.995019531249994],
            [34.400976562500006, 30.827832031249997],
            [34.48994140625001, 30.5962890625],
            [34.51777343750001, 30.507373046874996],
            [34.52968750000002, 30.446044921875],
            [34.65859375000002, 30.191455078124996],
            [34.73505859375001, 29.98203125],
            [34.791113281250006, 29.812109375],
            [34.86982421875001, 29.563916015624997],
            [34.904296875, 29.47734375],
            [34.84853515625002, 29.43212890625],
            [34.736425781250006, 29.27060546875],
            [34.6171875, 28.75791015625],
            [34.44648437500001, 28.357324218749994],
            [34.42714843750002, 28.106494140624996],
            [34.39970703125002, 28.016015625],
            [34.31855468750001, 27.888964843749996],
            [34.22011718750002, 27.764306640624994],
            [34.04511718750001, 27.828857421875],
            [33.76025390625, 28.04765625],
            [33.59414062500002, 28.255566406249997],
            [33.416113281250006, 28.38984375],
            [33.24775390625001, 28.567724609375],
            [33.20195312500002, 28.695703125],
            [33.203710937500006, 28.777783203124997],
            [33.13017578125002, 28.978271484375],
            [33.07578125, 29.073046875],
            [32.87060546875, 29.28623046875],
            [32.81171875000001, 29.4],
            [32.766699218750006, 29.45],
            [32.72148437500002, 29.521777343749996],
            [32.64716796875001, 29.7984375],
            [32.56572265625002, 29.973974609375],
            [32.47304687500002, 29.925439453124994],
            [32.48945312500001, 29.851513671874997],
            [32.40859375000002, 29.749316406249996],
            [32.35976562500002, 29.630664062499996],
            [32.39726562500002, 29.5337890625],
            [32.565039062500006, 29.386328125],
            [32.59902343750002, 29.321923828124994],
            [32.63808593750002, 29.182177734374996],
            [32.6318359375, 28.992236328124996],
            [32.65888671875001, 28.927734375],
            [32.78447265625002, 28.78662109375],
            [32.82949218750002, 28.702880859375],
            [32.856542968750006, 28.630615234375],
            [32.89824218750002, 28.565234375],
            [33.02285156250002, 28.442285156249994],
            [33.2021484375, 28.208300781249996],
            [33.37226562500001, 28.050585937499996],
            [33.49492187500002, 27.974462890625],
            [33.54707031250001, 27.898144531249997],
            [33.55878906250001, 27.701220703124996],
            [33.5498046875, 27.607373046874997],
            [33.65742187500001, 27.430566406249994],
            [33.697265625, 27.341113281249996],
            [33.80166015625002, 27.2681640625],
            [33.84931640625001, 27.184912109375],
            [33.89306640625, 27.049462890624994],
            [33.959082031250006, 26.6490234375],
            [34.04951171875001, 26.550732421874997],
            [34.32929687500001, 26.024365234374997],
            [34.56513671875001, 25.691162109375],
            [34.679296875, 25.442529296874994],
            [34.85322265625001, 25.139794921874994],
            [35.19414062500002, 24.475146484375003],
            [35.397070312500006, 24.269970703124997],
            [35.47783203125002, 24.15478515625],
            [35.62470703125001, 24.066015625],
            [35.78388671875001, 23.937792968750003],
            [35.63203125000001, 23.950341796874994],
            [35.593847656250006, 23.942578125],
            [35.54082031250002, 23.920654296875],
            [35.515234375, 23.84287109374999],
            [35.50439453125, 23.779296875],
            [35.52275390625002, 23.442529296874994],
            [35.56435546875002, 23.27109375],
            [35.697851562500006, 22.946191406249994],
            [35.79736328125, 22.84873046874999],
            [35.845800781250006, 22.785693359375003],
            [35.91337890625002, 22.739648437499994],
            [36.22968750000001, 22.628808593749994],
            [36.41455078125, 22.394189453124994],
            [36.8296875, 22.09765625],
            [36.87041015625002, 22.015771484374994],
            [36.87138671875002, 21.996728515624994],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 5,
        labelrank: 3,
        sovereignt: "Mauritania",
        sov_a3: "MRT",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Mauritania",
        adm0_a3: "MRT",
        geou_dif: 0,
        geounit: "Mauritania",
        gu_a3: "MRT",
        su_dif: 0,
        subunit: "Mauritania",
        su_a3: "MRT",
        brk_diff: 0,
        name: "Mauritania",
        name_long: "Mauritania",
        brk_a3: "MRT",
        brk_name: "Mauritania",
        brk_group: null,
        abbrev: "Mrt.",
        postal: "MR",
        formal_en: "Islamic Republic of Mauritania",
        formal_fr: null,
        name_ciawf: "Mauritania",
        note_adm0: null,
        note_brk: null,
        name_sort: "Mauritania",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 3,
        mapcolor9: 2,
        mapcolor13: 1,
        pop_est: 4525696,
        pop_rank: 12,
        pop_year: 2019,
        gdp_md: 7600,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        fips_10: "MR",
        iso_a2: "MR",
        iso_a2_eh: "MR",
        iso_a3: "MRT",
        iso_a3_eh: "MRT",
        iso_n3: "478",
        iso_n3_eh: "478",
        un_a3: "478",
        wb_a2: "MR",
        wb_a3: "MRT",
        woe_id: 23424896,
        woe_id_eh: 23424896,
        woe_note: "Exact WOE match as country",
        adm0_iso: "MRT",
        adm0_diff: null,
        adm0_tlc: "MRT",
        adm0_a3_us: "MRT",
        adm0_a3_fr: "MRT",
        adm0_a3_ru: "MRT",
        adm0_a3_es: "MRT",
        adm0_a3_cn: "MRT",
        adm0_a3_tw: "MRT",
        adm0_a3_in: "MRT",
        adm0_a3_np: "MRT",
        adm0_a3_pk: "MRT",
        adm0_a3_de: "MRT",
        adm0_a3_gb: "MRT",
        adm0_a3_br: "MRT",
        adm0_a3_il: "MRT",
        adm0_a3_ps: "MRT",
        adm0_a3_sa: "MRT",
        adm0_a3_eg: "MRT",
        adm0_a3_ma: "MRT",
        adm0_a3_pt: "MRT",
        adm0_a3_ar: "MRT",
        adm0_a3_jp: "MRT",
        adm0_a3_ko: "MRT",
        adm0_a3_vn: "MRT",
        adm0_a3_tr: "MRT",
        adm0_a3_id: "MRT",
        adm0_a3_pl: "MRT",
        adm0_a3_gr: "MRT",
        adm0_a3_it: "MRT",
        adm0_a3_nl: "MRT",
        adm0_a3_se: "MRT",
        adm0_a3_bd: "MRT",
        adm0_a3_ua: "MRT",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Western Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 10,
        long_len: 10,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: -9.740299,
        label_y: 19.587062,
        ne_id: 1159321075,
        wikidataid: "Q1025",
        name_ar: "موريتانيا",
        name_bn: "মৌরিতানিয়া",
        name_de: "Mauretanien",
        name_en: "Mauritania",
        name_es: "Mauritania",
        name_fa: "موریتانی",
        name_fr: "Mauritanie",
        name_el: "Μαυριτανία",
        name_he: "מאוריטניה",
        name_hi: "मॉरीतानिया",
        name_hu: "Mauritánia",
        name_id: "Mauritania",
        name_it: "Mauritania",
        name_ja: "モーリタニア",
        name_ko: "모리타니",
        name_nl: "Mauritanië",
        name_pl: "Mauretania",
        name_pt: "Mauritânia",
        name_ru: "Мавритания",
        name_sv: "Mauretanien",
        name_tr: "Moritanya",
        name_uk: "Мавританія",
        name_ur: "موریتانیہ",
        name_vi: "Mauritanie",
        name_zh: "毛里塔尼亚",
        name_zht: "茅利塔尼亞",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "MRT.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-16.37333984374999, 19.706445312499994],
              [-16.43754882812499, 19.609277343749994],
              [-16.465966796874994, 19.646386718749994],
              [-16.477001953124983, 19.710351562499994],
              [-16.420166015625, 19.801953125],
              [-16.393261718749983, 19.849267578124994],
              [-16.343652343749994, 19.8662109375],
              [-16.37333984374999, 19.706445312499994],
            ],
          ],
          [
            [
              [-12.280615234374977, 14.809033203124997],
              [-12.302539062499989, 14.816992187499991],
              [-12.40869140625, 14.889013671874991],
              [-12.459863281249994, 14.974658203125003],
              [-12.543554687499977, 15.039013671874997],
              [-12.659619140624983, 15.082080078125003],
              [-12.735253906249994, 15.13125],
              [-12.770312499999989, 15.186669921874994],
              [-12.813183593749983, 15.223535156249994],
              [-12.858496093749977, 15.242529296874991],
              [-12.862646484374977, 15.262402343749997],
              [-12.851904296874977, 15.289648437499991],
              [-12.862695312499994, 15.340429687499991],
              [-12.930859374999983, 15.453027343749994],
              [-12.994335937499983, 15.5048828125],
              [-13.048535156249983, 15.496630859375003],
              [-13.079296874999983, 15.510449218749997],
              [-13.097900390625, 15.535253906249991],
              [-13.105273437499989, 15.57177734375],
              [-13.142382812499989, 15.603320312500003],
              [-13.206445312499994, 15.616894531249997],
              [-13.258007812499983, 15.700390625],
              [-13.297021484374994, 15.853857421874991],
              [-13.347558593749994, 15.973486328124991],
              [-13.40966796875, 16.05917968749999],
              [-13.4541015625, 16.091113281250003],
              [-13.486962890624994, 16.09702148437499],
              [-13.498144531249977, 16.110302734374997],
              [-13.506982421874994, 16.135205078124997],
              [-13.555517578124977, 16.14404296875],
              [-13.62353515625, 16.118310546874994],
              [-13.684667968749977, 16.126904296874997],
              [-13.714941406249977, 16.16879882812499],
              [-13.756640624999989, 16.172509765624994],
              [-13.809814453125, 16.13803710937499],
              [-13.868457031249989, 16.148144531249997],
              [-13.9326171875, 16.202880859375],
              [-13.968164062499994, 16.257226562499994],
              [-13.975048828124983, 16.31113281249999],
              [-14.085644531249983, 16.418847656249994],
              [-14.300097656249989, 16.580273437499997],
              [-14.533740234374989, 16.655957031249997],
              [-14.786718749999977, 16.645898437499994],
              [-14.928613281249994, 16.653515625],
              [-14.959521484374989, 16.67890625],
              [-14.990625, 16.676904296874994],
              [-15.021923828124983, 16.6474609375],
              [-15.055224609374989, 16.64096679687499],
              [-15.090576171875, 16.657373046874994],
              [-15.112646484374977, 16.644921875],
              [-15.121435546874977, 16.60361328124999],
              [-15.210546874999977, 16.58261718749999],
              [-15.379980468749977, 16.581982421874997],
              [-15.516699218749977, 16.556591796874997],
              [-15.620800781249983, 16.506591796875],
              [-15.768212890624994, 16.485107421875],
              [-15.958984375, 16.492138671874997],
              [-16.07402343749999, 16.510449218749997],
              [-16.11328125, 16.54013671874999],
              [-16.168359375, 16.547070312499997],
              [-16.239013671875, 16.531298828125003],
              [-16.30229492187499, 16.451318359374994],
              [-16.358105468749983, 16.307177734375003],
              [-16.40434570312499, 16.224902343750003],
              [-16.441015624999977, 16.204541015624997],
              [-16.480078124999977, 16.097216796875003],
              [-16.502050781249977, 15.917333984374991],
              [-16.535253906249977, 15.83837890625],
              [-16.535742187499977, 16.286816406249997],
              [-16.481298828124977, 16.454248046874994],
              [-16.463623046875, 16.601513671874997],
              [-16.3466796875, 16.926416015624994],
              [-16.207470703124983, 17.192578125],
              [-16.07890624999999, 17.545849609374997],
              [-16.03032226562499, 17.887939453125],
              [-16.046728515624977, 18.22314453125],
              [-16.0849609375, 18.521191406249997],
              [-16.150097656249983, 18.718164062499994],
              [-16.213085937499983, 19.003320312499994],
              [-16.305908203125, 19.15380859375],
              [-16.476171874999977, 19.285058593749994],
              [-16.51445312499999, 19.361962890624994],
              [-16.474804687499983, 19.390625],
              [-16.371289062499983, 19.41025390624999],
              [-16.305273437499977, 19.512646484374997],
              [-16.44487304687499, 19.47314453125],
              [-16.283398437499983, 19.787158203125003],
              [-16.23320312499999, 20.0009765625],
              [-16.241162109374983, 20.141259765624994],
              [-16.21044921875, 20.227929687499994],
              [-16.333740234375, 20.41586914062499],
              [-16.429785156249977, 20.65234375],
              [-16.479199218749983, 20.68979492187499],
              [-16.530419921874994, 20.709521484375003],
              [-16.534912109375, 20.654003906249997],
              [-16.562695312499983, 20.604150390624994],
              [-16.622509765624983, 20.634179687499994],
              [-16.728369140624977, 20.80615234375],
              [-16.876074218749977, 21.086132812499997],
              [-16.927929687499983, 21.114794921875003],
              [-16.971142578124983, 21.076464843750003],
              [-16.998242187499983, 21.039697265624994],
              [-17.048046874999983, 20.80615234375],
              [-17.06396484375, 20.898828125],
              [-17.042382812499994, 21.008007812499997],
              [-17.00590820312499, 21.14243164062499],
              [-16.964550781249983, 21.329248046874994],
              [-16.836328125, 21.329394531250003],
              [-16.607031249999977, 21.329638671875003],
              [-16.37773437499999, 21.32993164062499],
              [-16.1484375, 21.330224609374994],
              [-15.919140624999983, 21.33046875],
              [-15.689794921874977, 21.330761718749997],
              [-15.460546874999977, 21.3310546875],
              [-15.231201171875, 21.331298828125],
              [-15.001904296874983, 21.331591796875003],
              [-14.772607421874994, 21.33188476562499],
              [-14.543261718749989, 21.33212890624999],
              [-14.31396484375, 21.332421875],
              [-14.084667968749983, 21.332714843749997],
              [-13.855371093749994, 21.332958984374997],
              [-13.626025390624989, 21.333251953125],
              [-13.396728515625, 21.333544921875003],
              [-13.167431640624983, 21.333789062500003],
              [-13.016210937499977, 21.333935546874997],
              [-13.025097656249983, 21.466796875],
              [-13.0322265625, 21.572070312500003],
              [-13.041748046875, 21.713818359374997],
              [-13.051220703124983, 21.854785156250003],
              [-13.060644531249977, 21.995751953124994],
              [-13.069580078125, 22.128173828125],
              [-13.078466796874977, 22.260449218749997],
              [-13.086767578124977, 22.383251953124997],
              [-13.094335937499977, 22.495996093749994],
              [-13.107324218749994, 22.560742187499997],
              [-13.155957031249983, 22.68930664062499],
              [-13.16650390625, 22.753222656250003],
              [-13.153271484374983, 22.820507812499997],
              [-13.120898437499989, 22.884082031250003],
              [-13.031494140625, 23.000244140625],
              [-12.89599609375, 23.089550781249997],
              [-12.739599609374977, 23.19272460937499],
              [-12.620410156249989, 23.27133789062499],
              [-12.559375, 23.290820312500003],
              [-12.372900390624977, 23.318017578124994],
              [-12.226171874999977, 23.377490234375003],
              [-12.083349609374977, 23.435449218749994],
              [-12.0234375, 23.467578125],
              [-12.016308593749983, 23.576464843750003],
              [-12.016308593749983, 23.697900390624994],
              [-12.016308593749983, 23.834033203125003],
              [-12.016308593749983, 23.97021484375],
              [-12.016308593749983, 24.106347656249994],
              [-12.016308593749983, 24.242480468750003],
              [-12.016308593749983, 24.378662109375],
              [-12.016308593749983, 24.514794921874994],
              [-12.016308593749983, 24.65097656249999],
              [-12.016308593749983, 24.787109375],
              [-12.016308593749983, 24.923242187499994],
              [-12.016308593749983, 25.059375],
              [-12.016308593749983, 25.195556640625],
              [-12.016308593749983, 25.331689453124994],
              [-12.016308593749983, 25.46787109374999],
              [-12.016308593749983, 25.60400390625],
              [-12.016308593749983, 25.740136718749994],
              [-12.016308593749983, 25.87631835937499],
              [-12.016308593749983, 25.995410156250003],
              [-11.866650390624983, 25.995410156250003],
              [-11.680371093749983, 25.995410156250003],
              [-11.494042968749994, 25.995410156250003],
              [-11.307714843749977, 25.995410156250003],
              [-11.121386718749989, 25.99545898437499],
              [-10.935107421874989, 25.99545898437499],
              [-10.748779296875, 25.99545898437499],
              [-10.562451171874983, 25.99545898437499],
              [-10.376123046874994, 25.99545898437499],
              [-10.189794921874977, 25.99545898437499],
              [-10.003515624999977, 25.99545898437499],
              [-9.817187499999989, 25.99545898437499],
              [-9.630859375, 25.995507812499994],
              [-9.444531249999983, 25.995507812499994],
              [-9.258203125, 25.995507812499994],
              [-9.071923828124994, 25.995507812499994],
              [-8.885644531249994, 25.995507812499994],
              [-8.682226562499977, 25.995507812499994],
              [-8.68212890625, 26.10947265625],
              [-8.68212890625, 26.273193359375],
              [-8.682324218749983, 26.497705078124994],
              [-8.6826171875, 26.72314453125],
              [-8.682861328125, 26.921337890624997],
              [-8.68310546875, 27.119287109374994],
              [-8.683349609375, 27.2859375],
              [-8.495312499999983, 27.175341796874996],
              [-8.307275390624994, 27.064746093749996],
              [-8.119238281249977, 26.954150390624996],
              [-7.93115234375, 26.843554687499996],
              [-7.743115234374983, 26.732958984374996],
              [-7.555078125, 26.622363281249996],
              [-7.366992187499989, 26.511767578124996],
              [-7.178906249999983, 26.401171875],
              [-6.990869140624994, 26.290576171874996],
              [-6.802832031249977, 26.179980468749996],
              [-6.61474609375, 26.06943359375],
              [-6.426708984374983, 25.958789062500003],
              [-6.238671875, 25.848193359375003],
              [-6.050585937499989, 25.737597656250003],
              [-5.862548828125, 25.627001953125003],
              [-5.674511718749983, 25.51640625],
              [-5.516943359374977, 25.423779296874997],
              [-5.275, 25.27451171874999],
              [-5.049511718749983, 25.135449218749997],
              [-4.822607421874977, 24.99560546875],
              [-5.172900390624989, 24.995410156250003],
              [-5.640771484374994, 24.995166015625003],
              [-5.959814453124977, 24.99497070312499],
              [-6.287207031249977, 24.994824218749997],
              [-6.594091796874977, 24.99462890625],
              [-6.5673828125, 24.766796875],
              [-6.538964843749994, 24.51816406249999],
              [-6.510449218749983, 24.269482421874997],
              [-6.482031249999977, 24.020800781250003],
              [-6.453515625, 23.772167968749997],
              [-6.425048828125, 23.523486328125003],
              [-6.396582031249977, 23.274804687499994],
              [-6.368115234374983, 23.026123046875],
              [-6.339648437499989, 22.777490234374994],
              [-6.311132812499977, 22.528857421875003],
              [-6.28271484375, 22.280175781249994],
              [-6.254199218749989, 22.031542968750003],
              [-6.225732421874994, 21.782861328124994],
              [-6.197265625, 21.5341796875],
              [-6.168798828124977, 21.285546875],
              [-6.140332031249983, 21.036865234375],
              [-6.11181640625, 20.78818359374999],
              [-6.083398437499994, 20.539501953124997],
              [-6.054882812499983, 20.29086914062499],
              [-6.026416015624989, 20.0421875],
              [-5.997949218749994, 19.793505859375003],
              [-5.969482421875, 19.544873046874997],
              [-5.941015624999977, 19.296191406250003],
              [-5.9125, 19.047509765624994],
              [-5.884082031249989, 18.798876953125003],
              [-5.855566406249977, 18.550244140624997],
              [-5.827099609374983, 18.3015625],
              [-5.798632812499989, 18.052929687499997],
              [-5.770166015624994, 17.804248046875003],
              [-5.74169921875, 17.555566406249994],
              [-5.713183593749989, 17.306884765625],
              [-5.684765624999983, 17.058251953124994],
              [-5.65625, 16.8095703125],
              [-5.628662109375, 16.568652343750003],
              [-5.509619140624977, 16.442041015624994],
              [-5.359912109374989, 16.282861328124994],
              [-5.403564453125, 16.057910156250003],
              [-5.455615234374989, 15.789404296874991],
              [-5.5125, 15.496289062499997],
              [-5.723876953125, 15.496289062499997],
              [-5.927832031249977, 15.496289062499997],
              [-6.131787109374983, 15.496240234374994],
              [-6.335742187499989, 15.496191406249991],
              [-6.539648437499977, 15.496191406249991],
              [-6.743603515624983, 15.496191406249991],
              [-6.947558593749989, 15.496191406249991],
              [-7.151513671874994, 15.496191406249991],
              [-7.35546875, 15.496191406249991],
              [-7.559375, 15.496142578125003],
              [-7.763378906249983, 15.496142578125003],
              [-7.96728515625, 15.496142578125003],
              [-8.171240234374977, 15.496142578125003],
              [-8.375195312499983, 15.496142578125003],
              [-8.579150390624989, 15.496142578125003],
              [-8.783105468749994, 15.49609375],
              [-8.987060546875, 15.49609375],
              [-9.176806640624989, 15.49609375],
              [-9.293701171875, 15.502832031249994],
              [-9.33544921875, 15.525683593750003],
              [-9.3505859375, 15.677392578124994],
              [-9.385351562499977, 15.667626953124994],
              [-9.426562499999989, 15.623046875],
              [-9.447705078124983, 15.574853515624994],
              [-9.440332031249994, 15.511669921874997],
              [-9.446923828124994, 15.458203125],
              [-9.577832031249983, 15.437255859375],
              [-9.755078124999983, 15.401464843749991],
              [-9.94140625, 15.373779296875],
              [-10.129541015624994, 15.383691406249994],
              [-10.19375, 15.396044921875003],
              [-10.262109375, 15.416015625],
              [-10.411816406249983, 15.437939453124997],
              [-10.4931640625, 15.439794921874991],
              [-10.586572265624994, 15.434863281250003],
              [-10.696582031249989, 15.42265625],
              [-10.731982421874989, 15.394921875],
              [-10.815087890624994, 15.28173828125],
              [-10.895605468749977, 15.150488281249991],
              [-10.9482421875, 15.151123046875],
              [-11.007421874999977, 15.222900390625],
              [-11.169335937499994, 15.358642578125],
              [-11.365625, 15.536767578124994],
              [-11.455224609374994, 15.625390625],
              [-11.502685546875, 15.636816406249991],
              [-11.596728515624989, 15.5732421875],
              [-11.675878906249977, 15.512060546874991],
              [-11.76015625, 15.425537109375],
              [-11.798437499999977, 15.342724609374997],
              [-11.828759765624994, 15.244873046875],
              [-11.842236328124983, 15.12939453125],
              [-11.872851562499989, 14.995166015625003],
              [-11.94091796875, 14.886914062499997],
              [-12.021582031249977, 14.804931640625],
              [-12.08154296875, 14.766357421875],
              [-12.104687499999983, 14.745361328125],
              [-12.280615234374977, 14.809033203124997],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 6,
        sovereignt: "Gambia",
        sov_a3: "GMB",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Gambia",
        adm0_a3: "GMB",
        geou_dif: 0,
        geounit: "Gambia",
        gu_a3: "GMB",
        su_dif: 0,
        subunit: "Gambia",
        su_a3: "GMB",
        brk_diff: 0,
        name: "Gambia",
        name_long: "The Gambia",
        brk_a3: "GMB",
        brk_name: "Gambia",
        brk_group: null,
        abbrev: "Gambia",
        postal: "GM",
        formal_en: "Republic of the Gambia",
        formal_fr: null,
        name_ciawf: "Gambia, The",
        note_adm0: null,
        note_brk: null,
        name_sort: "Gambia, The",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 4,
        mapcolor9: 1,
        mapcolor13: 8,
        pop_est: 2347706,
        pop_rank: 12,
        pop_year: 2019,
        gdp_md: 1826,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        fips_10: "GA",
        iso_a2: "GM",
        iso_a2_eh: "GM",
        iso_a3: "GMB",
        iso_a3_eh: "GMB",
        iso_n3: "270",
        iso_n3_eh: "270",
        un_a3: "270",
        wb_a2: "GM",
        wb_a3: "GMB",
        woe_id: 23424821,
        woe_id_eh: 23424821,
        woe_note: "Exact WOE match as country",
        adm0_iso: "GMB",
        adm0_diff: null,
        adm0_tlc: "GMB",
        adm0_a3_us: "GMB",
        adm0_a3_fr: "GMB",
        adm0_a3_ru: "GMB",
        adm0_a3_es: "GMB",
        adm0_a3_cn: "GMB",
        adm0_a3_tw: "GMB",
        adm0_a3_in: "GMB",
        adm0_a3_np: "GMB",
        adm0_a3_pk: "GMB",
        adm0_a3_de: "GMB",
        adm0_a3_gb: "GMB",
        adm0_a3_br: "GMB",
        adm0_a3_il: "GMB",
        adm0_a3_ps: "GMB",
        adm0_a3_sa: "GMB",
        adm0_a3_eg: "GMB",
        adm0_a3_ma: "GMB",
        adm0_a3_pt: "GMB",
        adm0_a3_ar: "GMB",
        adm0_a3_jp: "GMB",
        adm0_a3_ko: "GMB",
        adm0_a3_vn: "GMB",
        adm0_a3_tr: "GMB",
        adm0_a3_id: "GMB",
        adm0_a3_pl: "GMB",
        adm0_a3_gr: "GMB",
        adm0_a3_it: "GMB",
        adm0_a3_nl: "GMB",
        adm0_a3_se: "GMB",
        adm0_a3_bd: "GMB",
        adm0_a3_ua: "GMB",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Western Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 6,
        long_len: 10,
        abbrev_len: 6,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 5,
        max_label: 10,
        label_x: -14.998318,
        label_y: 13.641721,
        ne_id: 1159320797,
        wikidataid: "Q1005",
        name_ar: "غامبيا",
        name_bn: "গাম্বিয়া",
        name_de: "Gambia",
        name_en: "The Gambia",
        name_es: "Gambia",
        name_fa: "گامبیا",
        name_fr: "Gambie",
        name_el: "Γκάμπια",
        name_he: "גמביה",
        name_hi: "गाम्बिया",
        name_hu: "Gambia",
        name_id: "Gambia",
        name_it: "Gambia",
        name_ja: "ガンビア",
        name_ko: "감비아",
        name_nl: "Gambia",
        name_pl: "Gambia",
        name_pt: "Gâmbia",
        name_ru: "Гамбия",
        name_sv: "Gambia",
        name_tr: "Gambiya",
        name_uk: "Гамбія",
        name_ur: "گیمبیا",
        name_vi: "Gambia",
        name_zh: "冈比亚",
        name_zht: "甘比亞",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "GMB.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-16.56230468749999, 13.587304687499994],
            [-16.308740234374994, 13.596875],
            [-16.001611328124994, 13.5927734375],
            [-15.667187499999983, 13.58828125],
            [-15.509667968749994, 13.586230468750003],
            [-15.426855468749977, 13.727001953124997],
            [-15.26953125, 13.789111328125003],
            [-15.108349609374983, 13.812109375],
            [-15.024462890624989, 13.806005859374991],
            [-14.935791015625, 13.785205078125003],
            [-14.766015625, 13.669091796874994],
            [-14.66015625, 13.642626953125003],
            [-14.570849609374989, 13.616162109374997],
            [-14.506982421874994, 13.559716796874994],
            [-14.405468749999983, 13.503710937500003],
            [-14.325537109374977, 13.488574218750003],
            [-14.278027343749983, 13.497167968749991],
            [-14.199023437499989, 13.51875],
            [-14.14697265625, 13.5361328125],
            [-13.977392578124977, 13.54345703125],
            [-13.852832031249989, 13.478564453125003],
            [-13.826708984374989, 13.4078125],
            [-13.847509765624977, 13.335302734374991],
            [-14.014892578125, 13.29638671875],
            [-14.246777343749983, 13.23583984375],
            [-14.438574218749977, 13.268896484374991],
            [-14.671923828124989, 13.351708984374994],
            [-14.808251953124994, 13.4111328125],
            [-14.865039062499989, 13.434863281250003],
            [-14.950292968749977, 13.472607421874997],
            [-15.024609374999983, 13.513330078124994],
            [-15.096386718749983, 13.539648437499991],
            [-15.151123046875, 13.556494140624991],
            [-15.191601562499983, 13.535253906249991],
            [-15.212109374999983, 13.485058593749997],
            [-15.24453125, 13.429101562499994],
            [-15.286230468749977, 13.39599609375],
            [-15.481835937499994, 13.376367187499994],
            [-15.657324218749977, 13.355810546874991],
            [-15.751562499999977, 13.33837890625],
            [-15.814404296874983, 13.325146484374997],
            [-15.834277343749989, 13.156445312499997],
            [-16.033056640624977, 13.158349609374994],
            [-16.22832031249999, 13.160302734374994],
            [-16.430859374999983, 13.157324218749991],
            [-16.648779296874977, 13.154150390624991],
            [-16.704541015624983, 13.119726562499991],
            [-16.763330078124994, 13.064160156249997],
            [-16.76933593749999, 13.148486328124989],
            [-16.824804687499977, 13.341064453125],
            [-16.750390625, 13.425390625],
            [-16.669335937499994, 13.475],
            [-16.61479492187499, 13.435302734375],
            [-16.598339843749983, 13.356835937499994],
            [-16.55644531249999, 13.30322265625],
            [-16.41337890624999, 13.269726562499997],
            [-16.271679687499983, 13.293798828124991],
            [-16.18505859375, 13.28271484375],
            [-16.187890625, 13.326171875],
            [-16.158398437499983, 13.384033203125],
            [-15.986425781249977, 13.408837890624994],
            [-15.804492187499989, 13.425390625],
            [-15.61767578125, 13.460107421874994],
            [-15.471289062499977, 13.458642578124994],
            [-15.427490234375, 13.468359375],
            [-15.438134765624994, 13.483203125],
            [-15.569531249999983, 13.499853515624991],
            [-15.849902343749989, 13.4599609375],
            [-16.135449218749983, 13.4482421875],
            [-16.351806640625, 13.343359375],
            [-16.440527343749977, 13.353173828124994],
            [-16.53007812499999, 13.457958984374997],
            [-16.56230468749999, 13.587304687499994],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Madagascar",
        sov_a3: "MDG",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Madagascar",
        adm0_a3: "MDG",
        geou_dif: 0,
        geounit: "Madagascar",
        gu_a3: "MDG",
        su_dif: 0,
        subunit: "Madagascar",
        su_a3: "MDG",
        brk_diff: 0,
        name: "Madagascar",
        name_long: "Madagascar",
        brk_a3: "MDG",
        brk_name: "Madagascar",
        brk_group: null,
        abbrev: "Mad.",
        postal: "MG",
        formal_en: "Republic of Madagascar",
        formal_fr: null,
        name_ciawf: "Madagascar",
        note_adm0: null,
        note_brk: null,
        name_sort: "Madagascar",
        name_alt: null,
        mapcolor7: 6,
        mapcolor8: 5,
        mapcolor9: 2,
        mapcolor13: 3,
        pop_est: 26969307,
        pop_rank: 15,
        pop_year: 2019,
        gdp_md: 14114,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        fips_10: "MA",
        iso_a2: "MG",
        iso_a2_eh: "MG",
        iso_a3: "MDG",
        iso_a3_eh: "MDG",
        iso_n3: "450",
        iso_n3_eh: "450",
        un_a3: "450",
        wb_a2: "MG",
        wb_a3: "MDG",
        woe_id: 23424883,
        woe_id_eh: 23424883,
        woe_note: "Exact WOE match as country",
        adm0_iso: "MDG",
        adm0_diff: null,
        adm0_tlc: "MDG",
        adm0_a3_us: "MDG",
        adm0_a3_fr: "MDG",
        adm0_a3_ru: "MDG",
        adm0_a3_es: "MDG",
        adm0_a3_cn: "MDG",
        adm0_a3_tw: "MDG",
        adm0_a3_in: "MDG",
        adm0_a3_np: "MDG",
        adm0_a3_pk: "MDG",
        adm0_a3_de: "MDG",
        adm0_a3_gb: "MDG",
        adm0_a3_br: "MDG",
        adm0_a3_il: "MDG",
        adm0_a3_ps: "MDG",
        adm0_a3_sa: "MDG",
        adm0_a3_eg: "MDG",
        adm0_a3_ma: "MDG",
        adm0_a3_pt: "MDG",
        adm0_a3_ar: "MDG",
        adm0_a3_jp: "MDG",
        adm0_a3_ko: "MDG",
        adm0_a3_vn: "MDG",
        adm0_a3_tr: "MDG",
        adm0_a3_id: "MDG",
        adm0_a3_pl: "MDG",
        adm0_a3_gr: "MDG",
        adm0_a3_it: "MDG",
        adm0_a3_nl: "MDG",
        adm0_a3_se: "MDG",
        adm0_a3_bd: "MDG",
        adm0_a3_ua: "MDG",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Eastern Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 10,
        long_len: 10,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 2.7,
        max_label: 7,
        label_x: 46.704241,
        label_y: -18.628288,
        ne_id: 1159321051,
        wikidataid: "Q1019",
        name_ar: "مدغشقر",
        name_bn: "মাদাগাস্কার",
        name_de: "Madagaskar",
        name_en: "Madagascar",
        name_es: "Madagascar",
        name_fa: "ماداگاسکار",
        name_fr: "Madagascar",
        name_el: "Μαδαγασκάρη",
        name_he: "מדגסקר",
        name_hi: "मेडागास्कर",
        name_hu: "Madagaszkár",
        name_id: "Madagaskar",
        name_it: "Madagascar",
        name_ja: "マダガスカル",
        name_ko: "마다가스카르",
        name_nl: "Madagaskar",
        name_pl: "Madagaskar",
        name_pt: "Madagáscar",
        name_ru: "Мадагаскар",
        name_sv: "Madagaskar",
        name_tr: "Madagaskar",
        name_uk: "Мадагаскар",
        name_ur: "مڈغاسکر",
        name_vi: "Madagascar",
        name_zh: "马达加斯加",
        name_zht: "馬達加斯加",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "MDG.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [49.53828125000001, -12.43212890625],
              [49.58417968750001, -12.53671875],
              [49.637792968750006, -12.637109375],
              [49.80498046875002, -12.8796875],
              [49.87646484375, -12.973046875],
              [49.9375, -13.072265625],
              [49.96718750000002, -13.270214843750011],
              [50.07343750000001, -13.577929687500003],
              [50.173828125, -14.040234375000011],
              [50.20458984375, -14.514453125],
              [50.2353515625, -14.73203125],
              [50.3134765625, -14.936816406250003],
              [50.44130859375002, -15.149316406250009],
              [50.48271484375002, -15.385644531250009],
              [50.40458984375002, -15.629101562500011],
              [50.29150390625, -15.858496093750006],
              [50.262304687500006, -15.901562500000011],
              [50.208984375, -15.96044921875],
              [50.18496093750002, -15.9578125],
              [50.09443359375001, -15.898632812500011],
              [50.02041015625002, -15.8017578125],
              [49.92656250000002, -15.573535156250003],
              [49.892578125, -15.457714843750011],
              [49.85332031250002, -15.439453125],
              [49.74375, -15.449511718750003],
              [49.66435546875002, -15.521582031250006],
              [49.64990234375, -15.566992187500006],
              [49.6669921875, -15.695703125],
              [49.69707031250002, -15.811425781250009],
              [49.71044921875, -15.928906250000011],
              [49.71279296875002, -16.076757812500006],
              [49.742285156250006, -16.121484375],
              [49.78593750000002, -16.15908203125001],
              [49.8310546875, -16.255859375],
              [49.83906250000001, -16.48652343750001],
              [49.81132812500002, -16.60302734375],
              [49.733984375, -16.70302734375001],
              [49.73857421875002, -16.758398437500006],
              [49.7671875, -16.81513671875001],
              [49.73974609375, -16.849414062500003],
              [49.63691406250001, -16.892871093750003],
              [49.59521484375, -16.93115234375],
              [49.53955078125, -17.03291015625001],
              [49.449316406250006, -17.240625],
              [49.437109375, -17.3466796875],
              [49.49365234375, -17.66953125],
              [49.47783203125002, -17.898535156250006],
              [49.362890625, -18.336328125],
              [49.296875, -18.544042968750006],
              [49.20332031250001, -18.792285156250003],
              [49.06005859375, -19.11962890625],
              [48.918066406250006, -19.53046875000001],
              [48.797460937500006, -19.953222656250006],
              [48.70830078125002, -20.207324218750003],
              [48.60703125, -20.45751953125],
              [48.46855468750002, -20.9],
              [48.35078125000001, -21.34902343750001],
              [48.175878906250006, -21.843066406250003],
              [47.93447265625002, -22.39394531250001],
              [47.90839843750001, -22.4658203125],
              [47.85830078125002, -22.74726562500001],
              [47.80410156250002, -22.991503906250003],
              [47.73945312500001, -23.2333984375],
              [47.604101562500006, -23.633105468750003],
              [47.58867187500002, -23.75634765625],
              [47.55800781250002, -23.874609375],
              [47.42763671875002, -24.12519531250001],
              [47.37255859375, -24.21845703125001],
              [47.33359375, -24.31757812500001],
              [47.31171875000001, -24.443164062500003],
              [47.27285156250002, -24.56435546875001],
              [47.17734375, -24.787207031250006],
              [47.03496093750002, -24.97900390625],
              [46.93818359375001, -25.04873046875001],
              [46.728515625, -25.14990234375],
              [46.62226562500001, -25.17041015625],
              [46.38671875, -25.17275390625001],
              [46.15869140625, -25.23037109375001],
              [45.9208984375, -25.34130859375],
              [45.69218750000002, -25.46845703125001],
              [45.604589843750006, -25.52871093750001],
              [45.50800781250001, -25.56318359375001],
              [45.20576171875001, -25.57050781250001],
              [45.115234375, -25.543066406250006],
              [44.81289062500002, -25.33417968750001],
              [44.69580078125, -25.29970703125001],
              [44.47382812500001, -25.27109375],
              [44.40673828125, -25.25332031250001],
              [44.34589843750001, -25.22607421875],
              [44.25615234375002, -25.11689453125001],
              [44.078125, -25.02460937500001],
              [44.03535156250001, -24.995703125],
              [44.00830078125, -24.93203125],
              [43.98984375, -24.86347656250001],
              [43.94375, -24.78671875],
              [43.90957031250002, -24.640625],
              [43.8515625, -24.538378906250003],
              [43.6875, -24.35791015625],
              [43.670019531250006, -24.30029296875],
              [43.656835937500006, -24.10878906250001],
              [43.662109375, -23.97919921875001],
              [43.64609375, -23.74189453125001],
              [43.66474609375001, -23.63027343750001],
              [43.722265625, -23.5296875],
              [43.69873046875, -23.4208984375],
              [43.63759765625002, -23.30654296875001],
              [43.61464843750002, -23.18818359375001],
              [43.56953125000001, -23.08046875],
              [43.39785156250002, -22.886328125],
              [43.357519531250006, -22.790820312500003],
              [43.32958984375, -22.69189453125],
              [43.26484375000001, -22.38359375],
              [43.25712890625002, -22.2763671875],
              [43.2666015625, -22.04931640625],
              [43.29052734375, -21.93251953125001],
              [43.33222656250001, -21.851171875],
              [43.34267578125002, -21.79042968750001],
              [43.369726562500006, -21.73828125],
              [43.41054687500002, -21.69648437500001],
              [43.43779296875002, -21.64667968750001],
              [43.50185546875002, -21.3564453125],
              [43.583105468750006, -21.2919921875],
              [43.70361328125, -21.254980468750006],
              [43.80019531250002, -21.17919921875],
              [43.85566406250001, -21.07685546875001],
              [43.9111328125, -20.865820312500006],
              [44.063085937500006, -20.65625],
              [44.1171875, -20.54609375000001],
              [44.23964843750002, -20.3796875],
              [44.34814453125, -20.1455078125],
              [44.38105468750001, -20.03515625],
              [44.40468750000002, -19.92207031250001],
              [44.432226562500006, -19.67421875],
              [44.45292968750002, -19.550878906250006],
              [44.448828125, -19.4287109375],
              [44.38652343750002, -19.303125],
              [44.23876953125, -19.0751953125],
              [44.233984375, -19.03261718750001],
              [44.245703125, -18.86318359375001],
              [44.23310546875001, -18.740625],
              [44.1787109375, -18.61855468750001],
              [44.10878906250002, -18.503515625],
              [44.0400390625, -18.28847656250001],
              [44.00664062500002, -17.93300781250001],
              [44.013671875, -17.804492187500003],
              [43.99355468750002, -17.69033203125001],
              [43.94355468750001, -17.58144531250001],
              [43.97939453125002, -17.3916015625],
              [44.42138671875, -16.70263671875],
              [44.43574218750001, -16.621484375],
              [44.41796875, -16.41132812500001],
              [44.42705078125002, -16.2890625],
              [44.442480468750006, -16.24375],
              [44.476171875, -16.21728515625],
              [44.551855468750006, -16.20449218750001],
              [44.9091796875, -16.17451171875001],
              [44.955078125, -16.1533203125],
              [45.04423828125002, -16.09511718750001],
              [45.16679687500002, -15.9828125],
              [45.22285156250001, -15.950488281250003],
              [45.27128906250002, -15.962304687500009],
              [45.30234375, -16.01044921875001],
              [45.34218750000002, -16.03671875],
              [45.486328125, -15.98583984375],
              [45.54179687500002, -15.984277343750009],
              [45.598242187500006, -15.992578125],
              [45.62470703125001, -15.94580078125],
              [45.64052734375002, -15.883105468750003],
              [45.66152343750002, -15.8388671875],
              [45.7001953125, -15.813769531250003],
              [45.88593750000001, -15.800097656250003],
              [46.00429687500002, -15.782128906250009],
              [46.15751953125002, -15.73828125],
              [46.190527343750006, -15.746875],
              [46.3140625, -15.904589843750003],
              [46.3515625, -15.918164062500011],
              [46.39960937500001, -15.924609375],
              [46.44160156250001, -15.895898437500009],
              [46.34130859375, -15.813378906250009],
              [46.326171875, -15.766699218750006],
              [46.33144531250002, -15.713671875],
              [46.38515625000002, -15.60009765625],
              [46.47509765625, -15.513476562500003],
              [46.67470703125002, -15.3818359375],
              [46.88203125000001, -15.229589843750006],
              [46.94228515625002, -15.219042968750003],
              [46.993261718750006, -15.2431640625],
              [47.032324218750006, -15.42265625],
              [47.027441406250006, -15.452246093750006],
              [47.060546875, -15.456347656250003],
              [47.09921875, -15.434179687500006],
              [47.133398437500006, -15.36171875],
              [47.13515625000002, -15.3015625],
              [47.10732421875002, -15.243847656250011],
              [47.09375, -15.195019531250011],
              [47.09257812500002, -15.150097656250011],
              [47.19765625000002, -15.044042968750006],
              [47.28046875000001, -14.942675781250003],
              [47.31875, -14.82177734375],
              [47.35195312500002, -14.76611328125],
              [47.4390625, -14.703320312500011],
              [47.46474609375002, -14.71328125],
              [47.48505859375001, -14.764355468750011],
              [47.49638671875002, -14.818359375],
              [47.47402343750002, -14.871972656250009],
              [47.44208984375001, -14.925],
              [47.42919921875, -14.995703125],
              [47.47832031250002, -15.009375],
              [47.52470703125002, -14.9921875],
              [47.59257812500002, -14.8642578125],
              [47.670019531250006, -14.743261718750006],
              [47.71601562500001, -14.680371093750011],
              [47.774023437500006, -14.63671875],
              [47.87041015625002, -14.6455078125],
              [47.96416015625002, -14.672558593750011],
              [47.8115234375, -14.544824218750009],
              [47.77333984375002, -14.369921875],
              [47.955175781250006, -14.067285156250009],
              [47.95693359375002, -14.004296875],
              [47.98320312500002, -13.98486328125],
              [47.99550781250002, -13.96044921875],
              [47.9013671875, -13.858203125],
              [47.88359375000002, -13.807519531250009],
              [47.89599609375, -13.730664062500011],
              [47.941015625, -13.662402343750003],
              [47.98183593750002, -13.614648437500009],
              [48.03984375000002, -13.596289062500006],
              [48.0859375, -13.62255859375],
              [48.187109375, -13.70654296875],
              [48.25527343750002, -13.719335937500006],
              [48.33769531250002, -13.638671875],
              [48.40507812500002, -13.537988281250009],
              [48.506445312500006, -13.46875],
              [48.62138671875002, -13.425976562500011],
              [48.796484375, -13.267480468750009],
              [48.91035156250001, -12.935839843750003],
              [48.91943359375, -12.839062500000011],
              [48.89423828125001, -12.7216796875],
              [48.85380859375002, -12.61015625],
              [48.78632812500001, -12.470898437500011],
              [48.80390625000001, -12.440039062500006],
              [48.89960937500001, -12.45849609375],
              [48.931738281250006, -12.4390625],
              [49.035742187500006, -12.315820312500009],
              [49.20703125, -12.07958984375],
              [49.26347656250002, -12.080175781250006],
              [49.312109375, -12.123925781250009],
              [49.330175781250006, -12.188671875000011],
              [49.36396484375001, -12.236328125],
              [49.47978515625002, -12.3484375],
              [49.53828125000001, -12.43212890625],
            ],
          ],
          [
            [
              [48.34218750000002, -13.363867187500006],
              [48.34355468750002, -13.400390625],
              [48.2119140625, -13.38525390625],
              [48.19121093750002, -13.259960937500011],
              [48.25566406250002, -13.256054687500011],
              [48.26972656250001, -13.20458984375],
              [48.30888671875002, -13.1982421875],
              [48.35107421875, -13.3095703125],
              [48.34218750000002, -13.363867187500006],
            ],
          ],
          [
            [
              [49.93642578125002, -16.902929687500006],
              [49.82402343750002, -17.086523437500006],
              [49.85566406250001, -16.933203125],
              [49.9859375, -16.71240234375],
              [50.023046875, -16.6953125],
              [49.93642578125002, -16.902929687500006],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 3,
        labelrank: 6,
        sovereignt: "Comoros",
        sov_a3: "COM",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Comoros",
        adm0_a3: "COM",
        geou_dif: 0,
        geounit: "Comoros",
        gu_a3: "COM",
        su_dif: 0,
        subunit: "Comoros",
        su_a3: "COM",
        brk_diff: 0,
        name: "Comoros",
        name_long: "Comoros",
        brk_a3: "COM",
        brk_name: "Comoros",
        brk_group: null,
        abbrev: "Com.",
        postal: "KM",
        formal_en: "Union of the Comoros",
        formal_fr: null,
        name_ciawf: "Comoros",
        note_adm0: null,
        note_brk: null,
        name_sort: "Comoros",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 1,
        mapcolor9: 4,
        mapcolor13: 10,
        pop_est: 850886,
        pop_rank: 11,
        pop_year: 2019,
        gdp_md: 1165,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        fips_10: "CN",
        iso_a2: "KM",
        iso_a2_eh: "KM",
        iso_a3: "COM",
        iso_a3_eh: "COM",
        iso_n3: "174",
        iso_n3_eh: "174",
        un_a3: "174",
        wb_a2: "KM",
        wb_a3: "COM",
        woe_id: 23424786,
        woe_id_eh: 23424786,
        woe_note: "Exact WOE match as country",
        adm0_iso: "COM",
        adm0_diff: null,
        adm0_tlc: "COM",
        adm0_a3_us: "COM",
        adm0_a3_fr: "COM",
        adm0_a3_ru: "COM",
        adm0_a3_es: "COM",
        adm0_a3_cn: "COM",
        adm0_a3_tw: "COM",
        adm0_a3_in: "COM",
        adm0_a3_np: "COM",
        adm0_a3_pk: "COM",
        adm0_a3_de: "COM",
        adm0_a3_gb: "COM",
        adm0_a3_br: "COM",
        adm0_a3_il: "COM",
        adm0_a3_ps: "COM",
        adm0_a3_sa: "COM",
        adm0_a3_eg: "COM",
        adm0_a3_ma: "COM",
        adm0_a3_pt: "COM",
        adm0_a3_ar: "COM",
        adm0_a3_jp: "COM",
        adm0_a3_ko: "COM",
        adm0_a3_vn: "COM",
        adm0_a3_tr: "COM",
        adm0_a3_id: "COM",
        adm0_a3_pl: "COM",
        adm0_a3_gr: "COM",
        adm0_a3_it: "COM",
        adm0_a3_nl: "COM",
        adm0_a3_se: "COM",
        adm0_a3_bd: "COM",
        adm0_a3_ua: "COM",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Eastern Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: 2,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: 43.318094,
        label_y: -11.727683,
        ne_id: 1159320521,
        wikidataid: "Q970",
        name_ar: "جزر القمر",
        name_bn: "কোমোরোস",
        name_de: "Komoren",
        name_en: "Comoros",
        name_es: "Comoras",
        name_fa: "مجمعالجزایر قمر",
        name_fr: "Comores",
        name_el: "Κομόρες",
        name_he: "קומורו",
        name_hi: "कोमोरोस",
        name_hu: "Comore-szigetek",
        name_id: "Komoro",
        name_it: "Comore",
        name_ja: "コモロ",
        name_ko: "코모로",
        name_nl: "Comoren",
        name_pl: "Komory",
        name_pt: "Comores",
        name_ru: "Коморы",
        name_sv: "Komorerna",
        name_tr: "Komorlar",
        name_uk: "Коморські Острови",
        name_ur: "اتحاد القمری",
        name_vi: "Comoros",
        name_zh: "科摩罗",
        name_zht: "葛摩",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "COM.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [44.47636718750002, -12.08154296875],
              [44.52675781250002, -12.21953125],
              [44.52626953125002, -12.323535156250003],
              [44.504980468750006, -12.356542968750006],
              [44.46015625000001, -12.335156250000011],
              [44.37744140625, -12.252246093750003],
              [44.22011718750002, -12.17138671875],
              [44.29228515625002, -12.164746093750011],
              [44.33447265625, -12.173046875000011],
              [44.37910156250001, -12.165625],
              [44.40703125000002, -12.1201171875],
              [44.41259765625, -12.092968750000011],
              [44.45185546875001, -12.071386718750006],
              [44.47636718750002, -12.08154296875],
            ],
          ],
          [
            [
              [43.788671875, -12.30703125],
              [43.858984375, -12.368261718750006],
              [43.663671875, -12.342871093750006],
              [43.632910156250006, -12.287695312500006],
              [43.63134765625, -12.2470703125],
              [43.70429687500001, -12.255957031250006],
              [43.788671875, -12.30703125],
            ],
          ],
          [
            [
              [43.4658203125, -11.901269531250009],
              [43.44677734375, -11.91455078125],
              [43.35546875, -11.857519531250006],
              [43.303320312500006, -11.844042968750003],
              [43.226660156250006, -11.751855468750009],
              [43.25605468750001, -11.43212890625],
              [43.28066406250002, -11.391210937500006],
              [43.29902343750001, -11.37451171875],
              [43.34150390625001, -11.368457031250003],
              [43.39296875000002, -11.40859375],
              [43.37939453125, -11.614160156250009],
              [43.44765625000002, -11.752539062500006],
              [43.49150390625002, -11.862109375],
              [43.4658203125, -11.901269531250009],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 4,
        sovereignt: "Equatorial Guinea",
        sov_a3: "GNQ",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Equatorial Guinea",
        adm0_a3: "GNQ",
        geou_dif: 0,
        geounit: "Equatorial Guinea",
        gu_a3: "GNQ",
        su_dif: 0,
        subunit: "Equatorial Guinea",
        su_a3: "GNQ",
        brk_diff: 0,
        name: "Eq. Guinea",
        name_long: "Equatorial Guinea",
        brk_a3: "GNQ",
        brk_name: "Eq. Guinea",
        brk_group: null,
        abbrev: "Eq. G.",
        postal: "GQ",
        formal_en: "Republic of Equatorial Guinea",
        formal_fr: null,
        name_ciawf: "Equatorial Guinea",
        note_adm0: null,
        note_brk: null,
        name_sort: "Equatorial Guinea",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 1,
        mapcolor9: 4,
        mapcolor13: 8,
        pop_est: 1355986,
        pop_rank: 12,
        pop_year: 2019,
        gdp_md: 11026,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "2. High income: nonOECD",
        fips_10: "EK",
        iso_a2: "GQ",
        iso_a2_eh: "GQ",
        iso_a3: "GNQ",
        iso_a3_eh: "GNQ",
        iso_n3: "226",
        iso_n3_eh: "226",
        un_a3: "226",
        wb_a2: "GQ",
        wb_a3: "GNQ",
        woe_id: 23424804,
        woe_id_eh: 23424804,
        woe_note: "Exact WOE match as country",
        adm0_iso: "GNQ",
        adm0_diff: null,
        adm0_tlc: "GNQ",
        adm0_a3_us: "GNQ",
        adm0_a3_fr: "GNQ",
        adm0_a3_ru: "GNQ",
        adm0_a3_es: "GNQ",
        adm0_a3_cn: "GNQ",
        adm0_a3_tw: "GNQ",
        adm0_a3_in: "GNQ",
        adm0_a3_np: "GNQ",
        adm0_a3_pk: "GNQ",
        adm0_a3_de: "GNQ",
        adm0_a3_gb: "GNQ",
        adm0_a3_br: "GNQ",
        adm0_a3_il: "GNQ",
        adm0_a3_ps: "GNQ",
        adm0_a3_sa: "GNQ",
        adm0_a3_eg: "GNQ",
        adm0_a3_ma: "GNQ",
        adm0_a3_pt: "GNQ",
        adm0_a3_ar: "GNQ",
        adm0_a3_jp: "GNQ",
        adm0_a3_ko: "GNQ",
        adm0_a3_vn: "GNQ",
        adm0_a3_tr: "GNQ",
        adm0_a3_id: "GNQ",
        adm0_a3_pl: "GNQ",
        adm0_a3_gr: "GNQ",
        adm0_a3_it: "GNQ",
        adm0_a3_nl: "GNQ",
        adm0_a3_se: "GNQ",
        adm0_a3_bd: "GNQ",
        adm0_a3_ua: "GNQ",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Middle Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 10,
        long_len: 17,
        abbrev_len: 6,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: 8.9902,
        label_y: 2.333,
        ne_id: 1159320801,
        wikidataid: "Q983",
        name_ar: "غينيا الاستوائية",
        name_bn: "বিষুবীয় গিনি",
        name_de: "Äquatorialguinea",
        name_en: "Equatorial Guinea",
        name_es: "Guinea Ecuatorial",
        name_fa: "گینه استوایی",
        name_fr: "Guinée équatoriale",
        name_el: "Ισημερινή Γουινέα",
        name_he: "גינאה המשוונית",
        name_hi: "भूमध्यरेखीय गिनी",
        name_hu: "Egyenlítői-Guinea",
        name_id: "Guinea Khatulistiwa",
        name_it: "Guinea Equatoriale",
        name_ja: "赤道ギニア",
        name_ko: "적도 기니",
        name_nl: "Equatoriaal-Guinea",
        name_pl: "Gwinea Równikowa",
        name_pt: "Guiné Equatorial",
        name_ru: "Экваториальная Гвинея",
        name_sv: "Ekvatorialguinea",
        name_tr: "Ekvator Ginesi",
        name_uk: "Екваторіальна Гвінея",
        name_ur: "استوائی گنی",
        name_vi: "Guinea Xích Đạo",
        name_zh: "赤道几内亚",
        name_zht: "赤道幾內亞",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "GNQ.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [8.735742187500023, 3.75830078125],
              [8.760449218750011, 3.754345703124997],
              [8.910058593750023, 3.758203125],
              [8.95068359375, 3.705322265625],
              [8.946093750000017, 3.627539062499991],
              [8.792187500000011, 3.400390625],
              [8.763476562500017, 3.304638671874997],
              [8.704003906250023, 3.2236328125],
              [8.65234375, 3.217089843749989],
              [8.474902343750017, 3.2646484375],
              [8.444921875, 3.293505859374989],
              [8.434277343750011, 3.332421875],
              [8.451757812500006, 3.422900390624989],
              [8.464648437500017, 3.450585937499994],
              [8.5498046875, 3.467626953124991],
              [8.577246093750006, 3.482373046874997],
              [8.622753906250011, 3.579980468749994],
              [8.6376953125, 3.668847656249994],
              [8.675878906250006, 3.7359375],
              [8.735742187500023, 3.75830078125],
            ],
          ],
          [
            [
              [11.328710937500006, 2.167431640624997],
              [11.330078125, 1.935888671874991],
              [11.331152343750006, 1.740185546874997],
              [11.332324218750017, 1.528369140624989],
              [11.33359375, 1.3076171875],
              [11.334667968750011, 1.120751953124994],
              [11.335351562500023, 0.999707031249997],
              [11.130664062500017, 1.000390625],
              [10.85888671875, 1.001269531249989],
              [10.587207031250017, 1.002148437499997],
              [10.3154296875, 1.003076171874994],
              [10.178906250000011, 1.003564453124994],
              [10.028515625000011, 1.004003906249991],
              [9.979785156250017, 0.997705078124994],
              [9.946679687500023, 0.967138671874991],
              [9.90673828125, 0.960107421874994],
              [9.8603515625, 0.986230468749994],
              [9.803906250000011, 0.998730468749997],
              [9.788671875, 1.025683593749989],
              [9.760546875000017, 1.07470703125],
              [9.70458984375, 1.079980468749994],
              [9.676464843750011, 1.07470703125],
              [9.636132812500023, 1.046679687499989],
              [9.5908203125, 1.031982421875],
              [9.599414062500017, 1.054443359375],
              [9.509863281250006, 1.114794921874989],
              [9.4453125, 1.120654296874989],
              [9.385937500000011, 1.139257812499991],
              [9.43408203125, 1.29638671875],
              [9.494238281250006, 1.435302734375],
              [9.584277343750017, 1.540234375],
              [9.632128906250017, 1.565527343749991],
              [9.647656250000011, 1.617578125],
              [9.718847656250006, 1.788671875],
              [9.807031250000023, 1.927490234375],
              [9.779687500000023, 2.068212890624991],
              [9.80078125, 2.304443359375],
              [9.826171875, 2.297802734374997],
              [9.830371093750017, 2.275488281249991],
              [9.8369140625, 2.242382812499997],
              [9.8701171875, 2.21328125],
              [9.979882812500023, 2.167773437499989],
              [10.307031250000023, 2.167724609375],
              [10.502246093750017, 2.167626953124994],
              [10.790917968750023, 2.167578125],
              [11.096582031250023, 2.16748046875],
              [11.328710937500006, 2.167431640624997],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 4,
        sovereignt: "Cabo Verde",
        sov_a3: "CPV",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Cabo Verde",
        adm0_a3: "CPV",
        geou_dif: 0,
        geounit: "Cabo Verde",
        gu_a3: "CPV",
        su_dif: 0,
        subunit: "Cabo Verde",
        su_a3: "CPV",
        brk_diff: 0,
        name: "Cabo Verde",
        name_long: "Republic of Cabo Verde",
        brk_a3: "CPV",
        brk_name: "Cabo Verde",
        brk_group: null,
        abbrev: "C.Vd.",
        postal: "CV",
        formal_en: "Republic of Cabo Verde",
        formal_fr: null,
        name_ciawf: "Cabo Verde",
        note_adm0: null,
        note_brk: null,
        name_sort: "Cabo Verde",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 1,
        mapcolor9: 4,
        mapcolor13: 11,
        pop_est: 549935,
        pop_rank: 11,
        pop_year: 2019,
        gdp_md: 1981,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        fips_10: "CV",
        iso_a2: "CV",
        iso_a2_eh: "CV",
        iso_a3: "CPV",
        iso_a3_eh: "CPV",
        iso_n3: "132",
        iso_n3_eh: "132",
        un_a3: "132",
        wb_a2: "CV",
        wb_a3: "CPV",
        woe_id: 23424794,
        woe_id_eh: 23424794,
        woe_note: "Exact WOE match as country",
        adm0_iso: "CPV",
        adm0_diff: null,
        adm0_tlc: "CPV",
        adm0_a3_us: "CPV",
        adm0_a3_fr: "CPV",
        adm0_a3_ru: "CPV",
        adm0_a3_es: "CPV",
        adm0_a3_cn: "CPV",
        adm0_a3_tw: "CPV",
        adm0_a3_in: "CPV",
        adm0_a3_np: "CPV",
        adm0_a3_pk: "CPV",
        adm0_a3_de: "CPV",
        adm0_a3_gb: "CPV",
        adm0_a3_br: "CPV",
        adm0_a3_il: "CPV",
        adm0_a3_ps: "CPV",
        adm0_a3_sa: "CPV",
        adm0_a3_eg: "CPV",
        adm0_a3_ma: "CPV",
        adm0_a3_pt: "CPV",
        adm0_a3_ar: "CPV",
        adm0_a3_jp: "CPV",
        adm0_a3_ko: "CPV",
        adm0_a3_vn: "CPV",
        adm0_a3_tr: "CPV",
        adm0_a3_id: "CPV",
        adm0_a3_pl: "CPV",
        adm0_a3_gr: "CPV",
        adm0_a3_it: "CPV",
        adm0_a3_nl: "CPV",
        adm0_a3_se: "CPV",
        adm0_a3_bd: "CPV",
        adm0_a3_ua: "CPV",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Western Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 10,
        long_len: 22,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: -23.639434,
        label_y: 15.074761,
        ne_id: 1159320523,
        wikidataid: "Q1011",
        name_ar: "الرأس الأخضر",
        name_bn: "কাবু ভের্দি",
        name_de: "Kap Verde",
        name_en: "Cape Verde",
        name_es: "Cabo Verde",
        name_fa: "کیپ ورد",
        name_fr: "Cap-Vert",
        name_el: "Πράσινο Ακρωτήριο",
        name_he: "כף ורדה",
        name_hi: "केप वर्दे",
        name_hu: "Zöld-foki Köztársaság",
        name_id: "Tanjung Verde",
        name_it: "Capo Verde",
        name_ja: "カーボベルデ",
        name_ko: "카보베르데",
        name_nl: "Kaapverdië",
        name_pl: "Republika Zielonego Przylądka",
        name_pt: "Cabo Verde",
        name_ru: "Кабо-Верде",
        name_sv: "Kap Verde",
        name_tr: "Yeşil Burun Adaları",
        name_uk: "Кабо-Верде",
        name_ur: "کیپ ورڈی",
        name_vi: "Cabo Verde",
        name_zh: "佛得角",
        name_zht: "維德角",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "CPV.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-25.169824218749994, 16.946484375],
              [-25.267236328124994, 16.925927734374994],
              [-25.308300781249983, 16.935839843750003],
              [-25.321923828124994, 17.015380859375],
              [-25.341552734375, 17.06772460937499],
              [-25.337109374999983, 17.091015625],
              [-25.113476562499983, 17.193652343750003],
              [-25.03466796875, 17.176464843749997],
              [-24.979687499999983, 17.0947265625],
              [-25.01708984375, 17.04931640625],
              [-25.169824218749994, 16.946484375],
            ],
          ],
          [
            [
              [-23.444238281249994, 15.007958984374994],
              [-23.50468749999999, 14.916113281249991],
              [-23.63720703125, 14.923486328124994],
              [-23.70537109374999, 14.961328125],
              [-23.785009765624977, 15.076904296875],
              [-23.78251953124999, 15.166113281249991],
              [-23.754492187499977, 15.243554687499994],
              [-23.759375, 15.310791015625],
              [-23.74809570312499, 15.328515625],
              [-23.707226562499983, 15.31689453125],
              [-23.700634765624983, 15.271630859374994],
              [-23.579980468749994, 15.160888671875],
              [-23.535253906249977, 15.139257812499991],
              [-23.444238281249994, 15.007958984374994],
            ],
          ],
          [
            [
              [-24.887060546874977, 16.818115234375],
              [-24.969140625, 16.794189453125],
              [-25.019970703124983, 16.79721679687499],
              [-25.09306640624999, 16.83251953125],
              [-25.07011718749999, 16.870703125],
              [-24.99101562499999, 16.913232421874994],
              [-24.936474609374983, 16.922119140625],
              [-24.89189453124999, 16.846484375],
              [-24.887060546874977, 16.818115234375],
            ],
          ],
          [
            [
              [-22.917724609375, 16.237255859374997],
              [-22.834326171874977, 16.218994140625],
              [-22.802636718749994, 16.225537109374997],
              [-22.749414062499994, 16.22153320312499],
              [-22.692626953125, 16.16904296874999],
              [-22.681884765625, 16.11328125],
              [-22.710107421874994, 16.043359375],
              [-22.820507812499983, 15.986035156249997],
              [-22.88408203124999, 15.992724609375003],
              [-22.95927734374999, 16.045117187499997],
              [-22.916113281249977, 16.1484375],
              [-22.917724609375, 16.237255859374997],
            ],
          ],
          [
            [
              [-24.308251953124994, 14.856298828124991],
              [-24.386132812499994, 14.818212890624991],
              [-24.440527343749977, 14.834814453124991],
              [-24.4921875, 14.87421875],
              [-24.51708984375, 14.93125],
              [-24.496875, 14.980273437500003],
              [-24.391992187499994, 15.03828125],
              [-24.329492187499994, 15.019482421874997],
              [-24.295800781249994, 14.929541015624991],
              [-24.308251953124994, 14.856298828124991],
            ],
          ],
          [
            [
              [-24.08769531249999, 16.622509765624997],
              [-24.04638671875, 16.593066406250003],
              [-24.03271484375, 16.572021484375],
              [-24.094140625, 16.56103515625],
              [-24.243066406249994, 16.599414062500003],
              [-24.282812499999977, 16.575927734375],
              [-24.322363281249977, 16.493115234374997],
              [-24.398095703124994, 16.618408203125],
              [-24.392919921874977, 16.664453125],
              [-24.376708984375, 16.677783203125003],
              [-24.271093749999977, 16.64487304687499],
              [-24.08769531249999, 16.622509765624997],
            ],
          ],
          [
            [
              [-22.888330078124994, 16.659082031249994],
              [-22.920263671874977, 16.60791015625],
              [-22.959423828124983, 16.683056640624997],
              [-22.980615234374994, 16.700878906249997],
              [-22.990917968749983, 16.808837890625],
              [-22.93291015624999, 16.841015625],
              [-22.904736328124983, 16.84375],
              [-22.903906249999977, 16.732128906249997],
              [-22.888330078124994, 16.659082031249994],
            ],
          ],
          [
            [
              [-23.18212890625, 15.136767578125003],
              [-23.2099609375, 15.133105468750003],
              [-23.251806640624977, 15.178125],
              [-23.24248046874999, 15.240527343750003],
              [-23.247167968749977, 15.256982421874994],
              [-23.21025390624999, 15.323535156250003],
              [-23.13774414062499, 15.317724609374991],
              [-23.119335937499983, 15.268408203124991],
              [-23.115869140624994, 15.166650390624994],
              [-23.18212890625, 15.136767578125003],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 2,
        sovereignt: "France",
        sov_a3: "FR1",
        adm0_dif: 1,
        level: 2,
        type: "Country",
        tlc: "1",
        admin: "France",
        adm0_a3: "FRA",
        geou_dif: 0,
        geounit: "France",
        gu_a3: "FRA",
        su_dif: 0,
        subunit: "France",
        su_a3: "FRA",
        brk_diff: 0,
        name: "France",
        name_long: "France",
        brk_a3: "FRA",
        brk_name: "France",
        brk_group: null,
        abbrev: "Fr.",
        postal: "F",
        formal_en: "French Republic",
        formal_fr: null,
        name_ciawf: "France",
        note_adm0: null,
        note_brk: null,
        name_sort: "France",
        name_alt: null,
        mapcolor7: 7,
        mapcolor8: 5,
        mapcolor9: 9,
        mapcolor13: 11,
        pop_est: 67059887,
        pop_rank: 16,
        pop_year: 2019,
        gdp_md: 2715518,
        gdp_year: 2019,
        economy: "1. Developed region: G7",
        income_grp: "1. High income: OECD",
        fips_10: "FR",
        iso_a2: "-99",
        iso_a2_eh: "FR",
        iso_a3: "-99",
        iso_a3_eh: "FRA",
        iso_n3: "-99",
        iso_n3_eh: "250",
        un_a3: "250",
        wb_a2: "FR",
        wb_a3: "FRA",
        woe_id: -90,
        woe_id_eh: 23424819,
        woe_note: "Includes only Metropolitan France (including Corsica)",
        adm0_iso: "FRA",
        adm0_diff: null,
        adm0_tlc: "FRA",
        adm0_a3_us: "FRA",
        adm0_a3_fr: "FRA",
        adm0_a3_ru: "FRA",
        adm0_a3_es: "FRA",
        adm0_a3_cn: "FRA",
        adm0_a3_tw: "FRA",
        adm0_a3_in: "FRA",
        adm0_a3_np: "FRA",
        adm0_a3_pk: "FRA",
        adm0_a3_de: "FRA",
        adm0_a3_gb: "FRA",
        adm0_a3_br: "FRA",
        adm0_a3_il: "FRA",
        adm0_a3_ps: "FRA",
        adm0_a3_sa: "FRA",
        adm0_a3_eg: "FRA",
        adm0_a3_ma: "FRA",
        adm0_a3_pt: "FRA",
        adm0_a3_ar: "FRA",
        adm0_a3_jp: "FRA",
        adm0_a3_ko: "FRA",
        adm0_a3_vn: "FRA",
        adm0_a3_tr: "FRA",
        adm0_a3_id: "FRA",
        adm0_a3_pl: "FRA",
        adm0_a3_gr: "FRA",
        adm0_a3_it: "FRA",
        adm0_a3_nl: "FRA",
        adm0_a3_se: "FRA",
        adm0_a3_bd: "FRA",
        adm0_a3_ua: "FRA",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Western Europe",
        region_wb: "Europe & Central Asia",
        name_len: 6,
        long_len: 6,
        abbrev_len: 3,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 1.7,
        max_label: 6.7,
        label_x: 2.552275,
        label_y: 46.696113,
        ne_id: 1159320637,
        wikidataid: "Q142",
        name_ar: "فرنسا",
        name_bn: "ফ্রান্স",
        name_de: "Frankreich",
        name_en: "France",
        name_es: "Francia",
        name_fa: "فرانسه",
        name_fr: "France",
        name_el: "Γαλλία",
        name_he: "צרפת",
        name_hi: "फ़्रान्स",
        name_hu: "Franciaország",
        name_id: "Prancis",
        name_it: "Francia",
        name_ja: "フランス",
        name_ko: "프랑스",
        name_nl: "Frankrijk",
        name_pl: "Francja",
        name_pt: "França",
        name_ru: "Франция",
        name_sv: "Frankrike",
        name_tr: "Fransa",
        name_uk: "Франція",
        name_ur: "فرانس",
        name_vi: "Pháp",
        name_zh: "法国",
        name_zht: "法國",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "FRA.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [9.480371093750023, 42.805419921875],
              [9.454199218750006, 42.65859375],
              [9.473242187500006, 42.615576171875],
              [9.509375, 42.585595703124994],
              [9.526171875000017, 42.552636718749994],
              [9.556445312500017, 42.1609375],
              [9.550683593750023, 42.129736328125],
              [9.428417968750011, 41.972412109375],
              [9.40087890625, 41.926220703125],
              [9.394824218750017, 41.731201171875],
              [9.374218750000011, 41.67880859375],
              [9.330859375000017, 41.6271484375],
              [9.25341796875, 41.46005859375],
              [9.186132812500006, 41.384912109374994],
              [9.003027343750006, 41.4765625],
              [8.89501953125, 41.516162109374996],
              [8.842089843750017, 41.558886718749996],
              [8.807519531250023, 41.58837890625],
              [8.829785156250011, 41.627685546875],
              [8.879003906250006, 41.6685546875],
              [8.886816406250006, 41.70068359375],
              [8.77099609375, 41.737109375],
              [8.717968750000011, 41.76142578125],
              [8.718652343750023, 41.804003906249996],
              [8.758691406250023, 41.870410156249996],
              [8.740429687500011, 41.925146484375],
              [8.673632812500017, 41.92236328125],
              [8.621875, 41.930712890624996],
              [8.615136718750023, 41.959130859374994],
              [8.653417968750006, 41.995556640625],
              [8.702539062500023, 42.043115234374994],
              [8.700976562500017, 42.095605468749994],
              [8.6416015625, 42.118212890624996],
              [8.587792968750023, 42.16083984375],
              [8.566210937500017, 42.218798828124996],
              [8.60791015625, 42.258447265624994],
              [8.675488281250011, 42.284033203125],
              [8.625878906250023, 42.343408203124994],
              [8.592382812500006, 42.3447265625],
              [8.565625, 42.357714843749996],
              [8.5875, 42.385302734374996],
              [8.640039062500023, 42.4265625],
              [8.713085937500011, 42.549755859375],
              [8.814843750000023, 42.60791015625],
              [8.994921875000017, 42.6453125],
              [9.043652343750011, 42.661669921874996],
              [9.08837890625, 42.704980468749994],
              [9.137890625000011, 42.73291015625],
              [9.198046875000017, 42.72919921875],
              [9.253515625, 42.712451171874996],
              [9.287695312500006, 42.694628906249996],
              [9.313378906250023, 42.713183593749996],
              [9.33837890625, 42.766894531249996],
              [9.323046875000017, 42.8140625],
              [9.330957031250023, 42.943798828125],
              [9.363183593750023, 43.017382812499996],
              [9.415234375000011, 43.021484375],
              [9.463281250000023, 42.981005859374996],
              [9.460839843750023, 42.945214843749994],
              [9.478613281250006, 42.860498046874994],
              [9.480371093750023, 42.805419921875],
            ],
          ],
          [
            [
              [7.615625, 47.592724609375],
              [7.494921875000017, 47.54736328125],
              [7.467382812500006, 47.507666015625],
              [7.420019531250006, 47.45517578125],
              [7.343164062500023, 47.43310546875],
              [7.265722656250006, 47.42578125],
              [7.203125, 47.43271484375],
              [7.16748046875, 47.4537109375],
              [7.169238281250017, 47.4732421875],
              [7.136035156250017, 47.48984375],
              [7.053417968750011, 47.48935546875],
              [6.968359375, 47.45322265625],
              [6.900390625, 47.39423828125],
              [6.921484375, 47.361230468749994],
              [6.984082031250011, 47.3525390625],
              [7.000585937500006, 47.339453125],
              [7.000585937500006, 47.322509765625],
              [6.978515625, 47.302050781249996],
              [6.952050781250023, 47.2671875],
              [6.820703125000023, 47.16318359375],
              [6.688085937500006, 47.058251953124994],
              [6.666894531250023, 47.026513671874994],
              [6.624804687500017, 47.004345703125],
              [6.45625, 46.94833984375],
              [6.438574218750006, 46.92587890625],
              [6.429003906250017, 46.832275390625],
              [6.41015625, 46.755419921874996],
              [6.28515625, 46.683056640625],
              [6.160742187500006, 46.61103515625],
              [6.129687500000017, 46.5669921875],
              [6.10703125, 46.516064453125],
              [6.06796875, 46.458544921874996],
              [6.060253906250011, 46.428173828125],
              [6.123242187500011, 46.37861328125],
              [6.115917968750011, 46.337646484375],
              [6.095898437500011, 46.27939453125],
              [6.0361328125, 46.238085937499996],
              [5.970019531250017, 46.214697265625],
              [5.971484375000017, 46.151220703125],
              [6.006640625000017, 46.142333984375],
              [6.086621093750011, 46.147021484374996],
              [6.199414062500011, 46.19306640625],
              [6.27294921875, 46.252246093749996],
              [6.229589843750006, 46.308447265625],
              [6.22421875, 46.31943359375],
              [6.234667968750017, 46.3326171875],
              [6.321875, 46.393701171874994],
              [6.428906250000011, 46.430517578125],
              [6.578222656250006, 46.437353515625],
              [6.758105468750017, 46.415771484375],
              [6.776074218750011, 46.406640625],
              [6.767382812500017, 46.369189453124996],
              [6.7841796875, 46.31396484375],
              [6.816796875000023, 46.275195312499996],
              [6.772070312500006, 46.16513671875],
              [6.8056640625, 46.130664062499996],
              [6.858007812500006, 46.089404296874996],
              [6.897265625000017, 46.0517578125],
              [6.953710937500006, 46.017138671874996],
              [7.00390625, 45.958837890625],
              [7.02109375, 45.92578125],
              [6.940820312500023, 45.868359375],
              [6.804492187500017, 45.81455078125],
              [6.789160156250006, 45.780078125],
              [6.790917968750023, 45.740869140624994],
              [6.80625, 45.710009765624996],
              [6.881445312500006, 45.670361328125],
              [6.96240234375, 45.58056640625],
              [7.013671875, 45.50048828125],
              [7.126074218750006, 45.423681640625],
              [7.153417968750006, 45.400927734374996],
              [7.146386718750023, 45.381738281249994],
              [7.116796875, 45.349023437499994],
              [7.078320312500011, 45.23994140625],
              [7.032421875000011, 45.222607421875],
              [6.98125, 45.215576171875],
              [6.84228515625, 45.135644531249994],
              [6.780371093750006, 45.1453125],
              [6.692285156250023, 45.144287109375],
              [6.627734375000017, 45.11796875],
              [6.634765625, 45.068164062499996],
              [6.69140625, 45.022607421874994],
              [6.724707031250006, 44.972998046875],
              [6.738183593750023, 44.92138671875],
              [6.801074218750017, 44.883154296875],
              [6.889355468750011, 44.860302734375],
              [6.939843750000023, 44.858740234375],
              [6.972851562500011, 44.845019531249996],
              [6.99267578125, 44.827294921874994],
              [7.030664062500023, 44.716699218749994],
              [7.007910156250006, 44.68896484375],
              [6.960351562500023, 44.677148437499994],
              [6.931933593750017, 44.631640625],
              [6.875195312500011, 44.56455078125],
              [6.842968750000011, 44.510693359375],
              [6.878613281250011, 44.46328125],
              [6.893847656250017, 44.428173828125],
              [6.874804687500017, 44.392041015625],
              [6.900195312500017, 44.335742187499996],
              [6.96728515625, 44.280029296875],
              [7.1494140625, 44.201708984374996],
              [7.318554687500011, 44.137988281249996],
              [7.370898437500017, 44.127392578125],
              [7.599414062500017, 44.168359375],
              [7.63720703125, 44.16484375],
              [7.6650390625, 44.116015625],
              [7.677148437500023, 44.083154296874994],
              [7.651464843750006, 44.033642578125],
              [7.589648437500017, 43.9654296875],
              [7.522656250000011, 43.911083984375],
              [7.48203125, 43.864892578124994],
              [7.490527343750017, 43.82294921875],
              [7.4931640625, 43.767138671874996],
              [7.438671875000011, 43.750439453125],
              [7.436914062500023, 43.761474609375],
              [7.414453125000023, 43.770898437499994],
              [7.39501953125, 43.76533203125],
              [7.380078125000011, 43.753222656249996],
              [7.377734375000017, 43.731738281249996],
              [7.261523437500017, 43.69609375],
              [7.181445312500017, 43.659130859375],
              [6.86474609375, 43.438330078125],
              [6.716601562500017, 43.373583984374996],
              [6.687402343750023, 43.3345703125],
              [6.6572265625, 43.261669921875],
              [6.570214843750023, 43.199072265625],
              [6.494042968750023, 43.169287109375],
              [6.305371093750011, 43.138720703124996],
              [6.115917968750011, 43.07236328125],
              [6.030566406250017, 43.100976562499994],
              [5.809472656250023, 43.097900390625],
              [5.671582031250011, 43.17783203125],
              [5.406542968750017, 43.228515625],
              [5.320214843750023, 43.344921875],
              [5.199511718750017, 43.352490234375],
              [5.120410156250017, 43.348974609375],
              [5.073144531250023, 43.366601562499994],
              [5.060839843750017, 43.406298828124996],
              [5.059765625000011, 43.44453125],
              [4.975976562500023, 43.426953125],
              [4.911914062500017, 43.426953125],
              [4.873730468750011, 43.41162109375],
              [4.843554687500017, 43.393945312499994],
              [4.807910156250017, 43.405224609375],
              [4.787207031250006, 43.401416015624996],
              [4.7890625, 43.37890625],
              [4.712109375000011, 43.373291015625],
              [4.628710937500017, 43.387109375],
              [4.409765625, 43.447216796875],
              [4.376171875000011, 43.456396484375],
              [4.22421875, 43.479638671874994],
              [4.162792968750011, 43.503662109375],
              [4.113085937500017, 43.563037109374996],
              [4.075097656250023, 43.581835937499996],
              [4.052636718750023, 43.593066406249996],
              [3.910839843750011, 43.5630859375],
              [3.861621093750017, 43.516357421875],
              [3.784765625, 43.46162109375],
              [3.258886718750006, 43.193212890625],
              [3.162890625000017, 43.08076171875],
              [3.0517578125, 42.91513671875],
              [3.043066406250006, 42.837890625],
              [3.090917968750006, 42.590869140624996],
              [3.197851562500006, 42.461181640625],
              [3.21142578125, 42.43115234375],
              [3.152148437500017, 42.431005859375],
              [3.052636718750023, 42.447216796875],
              [2.970019531250017, 42.467236328125],
              [2.891406250000017, 42.4560546875],
              [2.815625, 42.429248046874996],
              [2.749414062500023, 42.413037109375],
              [2.701855468750011, 42.408496093749996],
              [2.670019531250006, 42.393017578125],
              [2.65478515625, 42.362109375],
              [2.651660156250017, 42.340478515624994],
              [2.56796875, 42.34580078125],
              [2.374414062500023, 42.390283203124994],
              [2.200390625000011, 42.420947265624996],
              [2.098339843750011, 42.386083984375],
              [2.03271484375, 42.353515625],
              [1.986523437500011, 42.35849609375],
              [1.951464843750017, 42.3927734375],
              [1.927929687500011, 42.426318359374996],
              [1.859765625000023, 42.457080078124996],
              [1.7060546875, 42.503320312499994],
              [1.713964843750006, 42.525634765625],
              [1.740234375, 42.55673828125],
              [1.739453125000011, 42.575927734375],
              [1.709863281250023, 42.604443359375],
              [1.568164062500017, 42.635009765625],
              [1.501367187500023, 42.642724609374994],
              [1.458886718750023, 42.6216796875],
              [1.428320312500006, 42.5958984375],
              [1.349414062500017, 42.690673828125],
              [1.293261718750017, 42.7099609375],
              [1.208300781250017, 42.713134765625],
              [1.111132812500017, 42.742041015625],
              [1.010058593750017, 42.778955078124994],
              [0.764453125000017, 42.838037109374994],
              [0.696875, 42.845117187499994],
              [0.669824218750023, 42.835742187499996],
              [0.651757812500023, 42.800439453124994],
              [0.641992187500023, 42.700634765625],
              [0.631640625000017, 42.689599609374994],
              [0.517675781250006, 42.686279296875],
              [0.377246093750017, 42.700146484375],
              [0.312890625000023, 42.693261718749994],
              [0.25546875, 42.692919921874996],
              [0.201367187500011, 42.7193359375],
              [-0.041162109374994, 42.689111328124994],
              [-0.081494140624983, 42.703857421875],
              [-0.140039062499994, 42.748925781249994],
              [-0.205322265625, 42.785302734374994],
              [-0.256054687499983, 42.803955078125],
              [-0.29931640625, 42.825341796874994],
              [-0.338574218749983, 42.82880859375],
              [-0.3984375, 42.80810546875],
              [-0.481152343749983, 42.79931640625],
              [-0.5498046875, 42.802001953125],
              [-0.58642578125, 42.798974609374994],
              [-0.740185546874983, 42.909521484375],
              [-0.762646484374983, 42.939794921875],
              [-0.839208984374977, 42.948193359375],
              [-0.933837890625, 42.949511718749996],
              [-1.175439453124994, 43.021142578124994],
              [-1.285449218749989, 43.059619140624996],
              [-1.301562499999989, 43.082470703125],
              [-1.300048828125, 43.100976562499994],
              [-1.31884765625, 43.096972656249996],
              [-1.352734374999983, 43.064257812499996],
              [-1.370507812499994, 43.03759765625],
              [-1.39404296875, 43.032617187499994],
              [-1.428759765624989, 43.036767578124994],
              [-1.460839843749994, 43.0517578125],
              [-1.48046875, 43.071142578125],
              [-1.459423828124983, 43.10498046875],
              [-1.422607421875, 43.14912109375],
              [-1.407324218749977, 43.197119140625],
              [-1.410693359374989, 43.240087890625],
              [-1.471728515624989, 43.26767578125],
              [-1.561474609374983, 43.279199218749994],
              [-1.627148437499983, 43.282470703125],
              [-1.712841796874983, 43.30703125],
              [-1.753271484374977, 43.32470703125],
              [-1.792724609375, 43.37255859375],
              [-1.794042968749977, 43.40732421875],
              [-1.631445312499977, 43.438037109374996],
              [-1.48486328125, 43.563769531249996],
              [-1.345996093749989, 44.02021484375],
              [-1.245507812499994, 44.559863281249996],
              [-1.170800781249994, 44.66181640625],
              [-1.076953124999989, 44.68984375],
              [-1.152880859374989, 44.764013671875],
              [-1.200390624999983, 44.726464843749994],
              [-1.220312499999977, 44.68662109375],
              [-1.245214843749977, 44.66669921875],
              [-1.189062499999977, 45.161474609375],
              [-1.149072265624994, 45.342626953125],
              [-1.081005859374983, 45.532421875],
              [-0.941748046874977, 45.457080078124996],
              [-0.826318359374994, 45.380664062499996],
              [-0.766650390624989, 45.314355468749994],
              [-0.691113281249983, 45.09345703125],
              [-0.633984374999983, 45.047119140625],
              [-0.548486328124994, 45.0005859375],
              [-0.582275390625, 45.0513671875],
              [-0.64111328125, 45.090185546875],
              [-0.733105468749983, 45.384619140625],
              [-0.790771484375, 45.468017578125],
              [-0.880664062499989, 45.53818359375],
              [-1.169970703124989, 45.6859375],
              [-1.195996093749983, 45.714453125],
              [-1.2099609375, 45.770898437499994],
              [-1.114355468749977, 45.768505859375],
              [-1.03173828125, 45.741064453125],
              [-1.04150390625, 45.77265625],
              [-1.066015624999977, 45.8056640625],
              [-1.104394531249994, 45.925341796874996],
              [-1.136376953124994, 46.204833984375],
              [-1.132031249999983, 46.252685546875],
              [-1.146289062499989, 46.311376953125],
              [-1.238818359374989, 46.324511718749996],
              [-1.312792968749989, 46.326904296875],
              [-1.392480468749994, 46.35009765625],
              [-1.786523437499994, 46.51484375],
              [-1.921435546874989, 46.684814453125],
              [-2.059375, 46.810302734375],
              [-2.092480468749983, 46.865039062499996],
              [-2.090283203124983, 46.9205078125],
              [-2.018896484374977, 47.037646484374996],
              [-2.081933593749994, 47.111621093749996],
              [-2.1435546875, 47.126318359375],
              [-2.197070312499989, 47.162939453125],
              [-2.148583984374994, 47.223925781249996],
              [-2.108300781249994, 47.262939453125],
              [-2.027587890625, 47.273583984374994],
              [-1.921728515624977, 47.260644531249994],
              [-1.8212890625, 47.225341796875],
              [-1.742529296874977, 47.215966796874994],
              [-1.975390624999989, 47.310693359374994],
              [-2.35302734375, 47.278759765625],
              [-2.434423828124977, 47.290966796875],
              [-2.503125, 47.312060546874996],
              [-2.530029296875, 47.381591796875],
              [-2.476318359375, 47.412939453125],
              [-2.427685546874983, 47.4708984375],
              [-2.482714843749989, 47.511621093749994],
              [-2.554052734374977, 47.52705078125],
              [-2.665917968749994, 47.526171875],
              [-2.770312499999989, 47.5138671875],
              [-2.796777343749994, 47.537255859374994],
              [-2.733105468749983, 47.601806640625],
              [-2.787207031249977, 47.625537109374996],
              [-2.859375, 47.614453125],
              [-2.964062499999983, 47.60107421875],
              [-3.064208984375, 47.621337890625],
              [-3.158837890624994, 47.694677734375],
              [-3.221582031249994, 47.694140625],
              [-3.264697265624989, 47.685107421874996],
              [-3.32861328125, 47.713330078125],
              [-3.395898437499994, 47.72041015625],
              [-3.443945312499977, 47.71103515625],
              [-3.5078125, 47.753125],
              [-3.900927734374989, 47.837548828124994],
              [-4.070703125, 47.8478515625],
              [-4.226416015624977, 47.809619140624996],
              [-4.312109374999977, 47.822900390624994],
              [-4.375097656249977, 47.87744140625],
              [-4.427978515625, 47.9689453125],
              [-4.678808593749977, 48.039501953125],
              [-4.629199218749989, 48.085791015625],
              [-4.512402343749983, 48.096728515624996],
              [-4.377832031249994, 48.128808593749994],
              [-4.329443359374977, 48.169970703124996],
              [-4.434619140624989, 48.21796875],
              [-4.51220703125, 48.229736328125],
              [-4.544335937499994, 48.246972656249994],
              [-4.5771484375, 48.2900390625],
              [-4.530664062499994, 48.309716796874994],
              [-4.497900390624977, 48.299267578125],
              [-4.4033203125, 48.29306640625],
              [-4.241406249999983, 48.303662109375],
              [-4.3017578125, 48.347070312499994],
              [-4.364404296874994, 48.356738281249996],
              [-4.393164062499977, 48.367626953125],
              [-4.524804687499994, 48.372314453125],
              [-4.584716796875, 48.35751953125],
              [-4.719384765624994, 48.363134765625],
              [-4.74853515625, 48.410009765625],
              [-4.7625, 48.450244140624996],
              [-4.720751953124989, 48.539892578125],
              [-4.531201171874983, 48.619970703125],
              [-4.058886718749989, 48.70751953125],
              [-3.855664062499983, 48.694726562499994],
              [-3.714794921874983, 48.710498046874996],
              [-3.545996093749977, 48.765673828124996],
              [-3.471484374999989, 48.812939453125],
              [-3.2314453125, 48.8408203125],
              [-3.003222656249989, 48.790673828124994],
              [-2.792871093749994, 48.60107421875],
              [-2.692333984374983, 48.53681640625],
              [-2.446191406249994, 48.648291015625],
              [-2.079443359374977, 48.64501953125],
              [-2.003710937499989, 48.582080078124996],
              [-1.97314453125, 48.635107421875],
              [-1.905712890624983, 48.697119140625],
              [-1.851953125, 48.668847656249994],
              [-1.82470703125, 48.630517578124994],
              [-1.437646484374994, 48.64140625],
              [-1.37646484375, 48.652587890625],
              [-1.48046875, 48.697607421875],
              [-1.565478515624989, 48.805517578125],
              [-1.583105468749977, 49.202392578125],
              [-1.690332031249994, 49.31318359375],
              [-1.813427734374983, 49.490136718749994],
              [-1.870068359374983, 49.595117187499994],
              [-1.875390625, 49.631396484374996],
              [-1.8564453125, 49.6837890625],
              [-1.705126953124989, 49.680957031249996],
              [-1.588232421874977, 49.66767578125],
              [-1.36572265625, 49.707275390625],
              [-1.258642578124977, 49.68017578125],
              [-1.264941406249989, 49.5982421875],
              [-1.232275390624977, 49.494873046875],
              [-1.194970703124994, 49.44482421875],
              [-1.138525390624977, 49.387890625],
              [-0.959130859374994, 49.3931640625],
              [-0.765527343749994, 49.359716796875],
              [-0.520898437499994, 49.354541015624996],
              [-0.163476562499994, 49.296777343749994],
              [-0.011181640624983, 49.330224609374994],
              [0.136132812500023, 49.401513671874994],
              [0.416894531250023, 49.448388671874994],
              [0.439257812500017, 49.473193359374996],
              [0.277636718750017, 49.46328125],
              [0.12939453125, 49.508447265624994],
              [0.109375, 49.557519531249994],
              [0.1265625, 49.6015625],
              [0.186718750000011, 49.703027343749994],
              [0.6162109375, 49.862939453124994],
              [0.924121093750017, 49.910205078124996],
              [1.245507812500023, 49.9982421875],
              [1.4072265625, 50.088525390624994],
              [1.514062500000023, 50.205078125],
              [1.5484375, 50.230712890625],
              [1.5927734375, 50.252197265625],
              [1.551562500000017, 50.2939453125],
              [1.579492187500023, 50.7392578125],
              [1.609570312500011, 50.819482421874994],
              [1.672265625000023, 50.885009765625],
              [1.767675781250006, 50.935693359374994],
              [1.9125, 50.990625],
              [2.445703125000023, 51.06650390625],
              [2.52490234375, 51.097119140625],
              [2.536035156250023, 51.04951171875],
              [2.574804687500006, 50.988574218749996],
              [2.601464843750023, 50.9552734375],
              [2.579296875000011, 50.911767578124994],
              [2.596777343750006, 50.875927734375],
              [2.669140625000011, 50.811425781249994],
              [2.759375, 50.750634765624994],
              [2.839746093750023, 50.711767578125],
              [2.862402343750006, 50.716015625],
              [2.921972656250006, 50.72705078125],
              [3.022851562500023, 50.766894531249996],
              [3.106835937500023, 50.779443359374994],
              [3.154882812500006, 50.748925781249994],
              [3.182031250000023, 50.731689453125],
              [3.234960937500006, 50.662939453125],
              [3.249804687500017, 50.591162109375],
              [3.273339843750023, 50.531542968749996],
              [3.316210937500017, 50.507373046874996],
              [3.476953125000023, 50.499462890625],
              [3.595410156250011, 50.47734375],
              [3.626757812500017, 50.457324218749996],
              [3.667285156250017, 50.3248046875],
              [3.689355468750023, 50.306054687499994],
              [3.718847656250006, 50.321679687499994],
              [3.748046875, 50.343505859375],
              [3.78857421875, 50.346972656249996],
              [3.858105468750011, 50.33857421875],
              [3.94970703125, 50.3359375],
              [4.044140625000011, 50.321337890624996],
              [4.174609375000017, 50.246484375],
              [4.169628906250011, 50.22177734375],
              [4.144140625, 50.17841796875],
              [4.13525390625, 50.143798828125],
              [4.15771484375, 50.1298828125],
              [4.192187500000017, 50.094140625],
              [4.183886718750017, 50.05283203125],
              [4.150292968750023, 50.023876953125],
              [4.136816406250006, 50],
              [4.137011718750017, 49.98447265625],
              [4.149316406250023, 49.971582031249994],
              [4.176074218750017, 49.960253906249996],
              [4.36875, 49.944970703124994],
              [4.545019531250006, 49.960253906249996],
              [4.656152343750023, 50.00244140625],
              [4.675097656250017, 50.046875],
              [4.706640625, 50.097070312499994],
              [4.772851562500023, 50.1390625],
              [4.818652343750017, 50.153173828125],
              [4.860546875000011, 50.135888671874994],
              [4.7900390625, 49.9595703125],
              [4.841503906250011, 49.914501953125],
              [4.84912109375, 49.847119140625],
              [4.867578125000023, 49.788134765624996],
              [4.930566406250023, 49.7892578125],
              [5.006933593750006, 49.778369140624996],
              [5.06103515625, 49.75654296875],
              [5.124121093750006, 49.721484375],
              [5.215039062500011, 49.689257812499996],
              [5.27880859375, 49.6779296875],
              [5.301953125000011, 49.6509765625],
              [5.353515625, 49.61982421875],
              [5.434667968750006, 49.554492187499996],
              [5.50732421875, 49.510888671874994],
              [5.542382812500023, 49.511035156249996],
              [5.610058593750011, 49.528222656249994],
              [5.71044921875, 49.539208984374994],
              [5.789746093750011, 49.53828125],
              [5.823437500000011, 49.505078125],
              [5.9013671875, 49.48974609375],
              [5.928906250000011, 49.4775390625],
              [5.95947265625, 49.454638671874996],
              [6.011425781250011, 49.445458984374994],
              [6.074121093750023, 49.454638671874996],
              [6.119921875000017, 49.485205078125],
              [6.181054687500023, 49.498925781249994],
              [6.2421875, 49.4943359375],
              [6.27734375, 49.4775390625],
              [6.344335937500006, 49.452734375],
              [6.382226562500023, 49.458154296874994],
              [6.458105468750006, 49.44287109375],
              [6.534277343750006, 49.394677734374994],
              [6.566308593750023, 49.34619140625],
              [6.57470703125, 49.319677734375],
              [6.607617187500011, 49.290869140625],
              [6.735449218750006, 49.160595703125],
              [6.776269531250023, 49.154150390625],
              [6.820703125000023, 49.17392578125],
              [6.849511718750023, 49.201953125],
              [6.891210937500006, 49.20751953125],
              [6.958300781250017, 49.194628906249996],
              [7.00146484375, 49.1798828125],
              [7.022167968750011, 49.1234375],
              [7.03671875, 49.112695312499994],
              [7.065722656250017, 49.124853515625],
              [7.117382812500011, 49.1275390625],
              [7.199902343750011, 49.113623046875],
              [7.313378906250023, 49.129541015624994],
              [7.404199218750023, 49.153076171875],
              [7.450585937500023, 49.152197265625],
              [7.525488281250006, 49.086376953125],
              [7.6109375, 49.061767578125],
              [7.799218750000023, 49.041894531249994],
              [8.001269531250017, 49.0109375],
              [8.080664062500006, 48.985888671874996],
              [8.134863281250006, 48.973583984375],
              [8.140332031250011, 48.88642578125],
              [8.1240234375, 48.873291015625],
              [7.922753906250023, 48.698535156249996],
              [7.837988281250006, 48.636035156249996],
              [7.794824218750023, 48.546826171875],
              [7.76513671875, 48.410009765625],
              [7.705664062500006, 48.280029296875],
              [7.616601562500023, 48.156787109374996],
              [7.584179687500011, 48.064306640625],
              [7.608496093750006, 48.002587890624994],
              [7.59326171875, 47.905664062499994],
              [7.53857421875, 47.7736328125],
              [7.529394531250006, 47.673876953124996],
              [7.5654296875, 47.60654296875],
              [7.615625, 47.592724609375],
            ],
          ],
          [
            [
              [-1.178320312499977, 45.904052734375],
              [-1.213574218749983, 45.8166015625],
              [-1.2802734375, 45.897119140624994],
              [-1.368701171874989, 45.967675781249994],
              [-1.388867187499983, 46.032958984375],
              [-1.388671875, 46.050390625],
              [-1.285058593749994, 46.002685546875],
              [-1.178320312499977, 45.904052734375],
            ],
          ],
          [
            [
              [45.180273437500006, -12.976757812500011],
              [45.11757812500002, -12.984960937500006],
              [45.08769531250002, -12.95849609375],
              [45.069433593750006, -12.895605468750006],
              [45.08828125000002, -12.835058593750006],
              [45.09355468750002, -12.7861328125],
              [45.042578125, -12.701269531250006],
              [45.092382812500006, -12.653027343750011],
              [45.134765625, -12.709179687500011],
              [45.158789062500006, -12.712988281250006],
              [45.22314453125, -12.752148437500011],
              [45.20429687500001, -12.824316406250006],
              [45.20859375, -12.847949218750003],
              [45.17939453125001, -12.920214843750003],
              [45.180273437500006, -12.976757812500011],
            ],
          ],
          [
            [
              [55.79736328125, -21.33935546875],
              [55.65615234375002, -21.36904296875001],
              [55.5576171875, -21.35830078125001],
              [55.36269531250002, -21.27363281250001],
              [55.31035156250002, -21.217382812500006],
              [55.23281250000002, -21.058398437500003],
              [55.25, -21.00244140625],
              [55.31132812500002, -20.904101562500003],
              [55.45048828125002, -20.86513671875001],
              [55.59648437500002, -20.87958984375001],
              [55.66191406250002, -20.90625],
              [55.73916015625002, -21.021484375],
              [55.83906250000001, -21.13857421875001],
              [55.82246093750001, -21.27783203125],
              [55.79736328125, -21.33935546875],
            ],
          ],
          [
            [
              [-60.82626953124999, 14.494482421874991],
              [-60.83662109375, 14.437402343749994],
              [-60.86210937499999, 14.42626953125],
              [-60.8994140625, 14.473779296874994],
              [-61.063720703125, 14.467089843750003],
              [-61.0888671875, 14.509570312500003],
              [-61.09033203125, 14.5296875],
              [-61.011328125, 14.601904296874991],
              [-61.10429687499999, 14.621240234374994],
              [-61.14111328125, 14.652392578125003],
              [-61.2197265625, 14.804394531249997],
              [-61.213330078125, 14.848583984374997],
              [-61.180810546874994, 14.871923828124991],
              [-61.127392578125, 14.875292968750003],
              [-61.027099609375, 14.826171875],
              [-60.952539062499994, 14.75625],
              [-60.927148437499994, 14.755175781250003],
              [-60.91865234375, 14.7353515625],
              [-60.93369140624999, 14.686181640624994],
              [-60.88916015625, 14.64453125],
              [-60.86997070312499, 14.613720703124997],
              [-60.82626953124999, 14.494482421874991],
            ],
          ],
          [
            [
              [-61.3271484375, 16.230419921874997],
              [-61.44482421875, 16.219287109375003],
              [-61.52216796875, 16.22802734375],
              [-61.539990234375, 16.299609375],
              [-61.50058593749999, 16.36020507812499],
              [-61.52890625, 16.433789062499997],
              [-61.510644531249994, 16.477685546874994],
              [-61.47119140625, 16.506640625],
              [-61.4064453125, 16.468310546875003],
              [-61.396142578124994, 16.41342773437499],
              [-61.35546875, 16.363183593749994],
              [-61.172607421875, 16.256103515625],
              [-61.3271484375, 16.230419921874997],
            ],
          ],
          [
            [
              [-61.58955078125, 16.00693359374999],
              [-61.67045898437499, 15.962060546874994],
              [-61.71025390624999, 15.975927734374991],
              [-61.759423828124994, 16.062060546875003],
              [-61.794091796874994, 16.300976562499997],
              [-61.76713867187499, 16.340478515624994],
              [-61.748046875, 16.355273437500003],
              [-61.641503906249994, 16.325976562500003],
              [-61.59702148437499, 16.2921875],
              [-61.55234375, 16.270898437499994],
              [-61.57504882812499, 16.22714843749999],
              [-61.563867187499994, 16.047753906249994],
              [-61.58955078125, 16.00693359374999],
            ],
          ],
          [
            [
              [-61.23046875, 15.889941406250003],
              [-61.28623046874999, 15.886035156250003],
              [-61.3107421875, 15.894677734374994],
              [-61.31840820312499, 15.954882812500003],
              [-61.275292968749994, 15.996240234374994],
              [-61.25, 16.006298828124997],
              [-61.212353515625, 15.959912109374997],
              [-61.20341796874999, 15.921240234374991],
              [-61.23046875, 15.889941406250003],
            ],
          ],
          [
            [
              [-54.61625976562499, 2.326757812499991],
              [-54.604736328125, 2.335791015624991],
              [-54.56840820312499, 2.342578124999989],
              [-54.53593749999999, 2.343310546874989],
              [-54.485546875, 2.416113281249991],
              [-54.402001953124994, 2.461523437499991],
              [-54.256738281249994, 2.713720703124991],
              [-54.1955078125, 2.81787109375],
              [-54.18808593749999, 2.874853515624991],
              [-54.17070312499999, 2.993603515624997],
              [-54.203125, 3.13818359375],
              [-54.18803710937499, 3.178759765624989],
              [-54.06318359375, 3.353320312499989],
              [-54.00957031249999, 3.448535156249989],
              [-54.00595703124999, 3.530517578125],
              [-53.990478515625, 3.589550781249997],
              [-54.00590820312499, 3.620410156249989],
              [-54.03422851562499, 3.62939453125],
              [-54.081982421875, 3.705957031249994],
              [-54.11279296875, 3.769433593749994],
              [-54.1974609375, 3.834423828124997],
              [-54.255517578124994, 3.901074218749997],
              [-54.350732421874994, 4.054101562499994],
              [-54.34213867187499, 4.140039062499994],
              [-54.369140625, 4.170947265624989],
              [-54.398388671875, 4.202490234374991],
              [-54.396240234375, 4.24140625],
              [-54.416015625, 4.337646484375],
              [-54.440673828125, 4.428027343749989],
              [-54.449609375, 4.485009765624994],
              [-54.42607421874999, 4.5830078125],
              [-54.44023437499999, 4.691992187499991],
              [-54.471142578125, 4.749316406249989],
              [-54.4796875, 4.836523437499991],
              [-54.47333984375, 4.914697265624994],
              [-54.446875, 4.958789062499989],
              [-54.45219726562499, 5.013476562499989],
              [-54.331640625, 5.187402343749994],
              [-54.240185546875, 5.288232421874994],
              [-54.15595703125, 5.358984375],
              [-54.08530273437499, 5.411816406249997],
              [-53.98959960937499, 5.676025390625],
              [-53.919921875, 5.768994140624997],
              [-53.84716796875, 5.7822265625],
              [-53.45444335937499, 5.5634765625],
              [-53.27036132812499, 5.543261718749989],
              [-52.899316406249994, 5.425048828125],
              [-52.76499023437499, 5.273486328124989],
              [-52.45395507812499, 5.021337890624991],
              [-52.29052734375, 4.942187499999989],
              [-52.28891601562499, 4.876123046874994],
              [-52.324609375, 4.770898437499994],
              [-52.219970703125, 4.86279296875],
              [-52.05810546875, 4.717382812499991],
              [-52.01230468749999, 4.64599609375],
              [-51.9619140625, 4.514404296875],
              [-51.979345703125006, 4.429882812499997],
              [-52.001708984375, 4.38623046875],
              [-52.0029296875, 4.352294921875],
              [-51.95478515624998, 4.399072265624994],
              [-51.92768554687498, 4.436132812499991],
              [-51.919580078124994, 4.524316406249994],
              [-51.880273437499994, 4.633740234374997],
              [-51.827539062499994, 4.635693359374997],
              [-51.78564453125, 4.570507812499997],
              [-51.698632812499994, 4.286816406249997],
              [-51.66582031249999, 4.228808593749989],
              [-51.65327148437498, 4.138769531249991],
              [-51.658105468749994, 4.098486328124991],
              [-51.65253906249998, 4.061279296875],
              [-51.683447265625006, 4.039697265624994],
              [-51.76708984375, 3.99267578125],
              [-51.805273437500006, 3.929931640625],
              [-51.827490234375006, 3.869580078124997],
              [-51.879492187500006, 3.828564453124997],
              [-51.92890624999998, 3.776953125],
              [-51.9443359375, 3.735107421875],
              [-51.990625, 3.702001953124991],
              [-51.99951171875, 3.646875],
              [-52.116113281249994, 3.452294921874994],
              [-52.16259765625, 3.364697265624997],
              [-52.229443359375, 3.271679687499997],
              [-52.271240234375, 3.237109374999989],
              [-52.327880859375, 3.181738281249991],
              [-52.356640625, 3.117724609374989],
              [-52.356640625, 3.051562499999989],
              [-52.396386718749994, 2.972216796874989],
              [-52.418408203125, 2.903857421874989],
              [-52.45585937499999, 2.864160156249994],
              [-52.5546875, 2.64765625],
              [-52.559472656249994, 2.573144531249994],
              [-52.5830078125, 2.52890625],
              [-52.65317382812499, 2.425732421874997],
              [-52.700634765625, 2.363671875],
              [-52.7833984375, 2.317187499999989],
              [-52.87041015624999, 2.266650390624989],
              [-52.903466796874994, 2.211523437499991],
              [-52.96484375, 2.183544921874997],
              [-53.009716796875, 2.181738281249991],
              [-53.082275390625, 2.201708984374989],
              [-53.180078125, 2.211328125],
              [-53.22978515624999, 2.204882812499989],
              [-53.252197265625, 2.232275390624991],
              [-53.28549804687499, 2.295214843749989],
              [-53.334423828125, 2.339746093749994],
              [-53.36601562499999, 2.32421875],
              [-53.4318359375, 2.279443359374994],
              [-53.508984375, 2.253125],
              [-53.56396484375, 2.261914062499997],
              [-53.68369140624999, 2.292919921874997],
              [-53.73471679687499, 2.308544921874997],
              [-53.750146484374994, 2.335009765624989],
              [-53.7677734375, 2.354833984374991],
              [-53.79423828124999, 2.345996093749989],
              [-53.829541015625, 2.312939453124997],
              [-53.876611328124994, 2.278271484374997],
              [-53.946435546874994, 2.232568359374994],
              [-54.089746093749994, 2.150488281249991],
              [-54.130078125, 2.121044921874997],
              [-54.167382812499994, 2.137060546874991],
              [-54.227978515625, 2.1533203125],
              [-54.29306640624999, 2.154248046874997],
              [-54.43310546875, 2.20751953125],
              [-54.515087890625, 2.245458984374991],
              [-54.55048828125, 2.293066406249991],
              [-54.591943359374994, 2.313769531249989],
              [-54.61625976562499, 2.326757812499991],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 6,
        sovereignt: "São Tomé and Principe",
        sov_a3: "STP",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "São Tomé and Principe",
        adm0_a3: "STP",
        geou_dif: 0,
        geounit: "São Tomé and Principe",
        gu_a3: "STP",
        su_dif: 0,
        subunit: "São Tomé and Principe",
        su_a3: "STP",
        brk_diff: 0,
        name: "São Tomé and Principe",
        name_long: "São Tomé and Principe",
        brk_a3: "STP",
        brk_name: "Sao Tome and Principe",
        brk_group: null,
        abbrev: "S.T.P.",
        postal: "ST",
        formal_en: "Democratic Republic of São Tomé and Principe",
        formal_fr: null,
        name_ciawf: "Sao Tome and Principe",
        note_adm0: null,
        note_brk: null,
        name_sort: "Sao Tome and Principe",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 6,
        mapcolor9: 1,
        mapcolor13: 7,
        pop_est: 215056,
        pop_rank: 10,
        pop_year: 2019,
        gdp_md: 418,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "4. Lower middle income",
        fips_10: "TP",
        iso_a2: "ST",
        iso_a2_eh: "ST",
        iso_a3: "STP",
        iso_a3_eh: "STP",
        iso_n3: "678",
        iso_n3_eh: "678",
        un_a3: "678",
        wb_a2: "ST",
        wb_a3: "STP",
        woe_id: 23424966,
        woe_id_eh: 23424966,
        woe_note: "Exact WOE match as country",
        adm0_iso: "STP",
        adm0_diff: null,
        adm0_tlc: "STP",
        adm0_a3_us: "STP",
        adm0_a3_fr: "STP",
        adm0_a3_ru: "STP",
        adm0_a3_es: "STP",
        adm0_a3_cn: "STP",
        adm0_a3_tw: "STP",
        adm0_a3_in: "STP",
        adm0_a3_np: "STP",
        adm0_a3_pk: "STP",
        adm0_a3_de: "STP",
        adm0_a3_gb: "STP",
        adm0_a3_br: "STP",
        adm0_a3_il: "STP",
        adm0_a3_ps: "STP",
        adm0_a3_sa: "STP",
        adm0_a3_eg: "STP",
        adm0_a3_ma: "STP",
        adm0_a3_pt: "STP",
        adm0_a3_ar: "STP",
        adm0_a3_jp: "STP",
        adm0_a3_ko: "STP",
        adm0_a3_vn: "STP",
        adm0_a3_tr: "STP",
        adm0_a3_id: "STP",
        adm0_a3_pl: "STP",
        adm0_a3_gr: "STP",
        adm0_a3_it: "STP",
        adm0_a3_nl: "STP",
        adm0_a3_se: "STP",
        adm0_a3_bd: "STP",
        adm0_a3_ua: "STP",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Middle Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 21,
        long_len: 21,
        abbrev_len: 6,
        tiny: 3,
        homepart: 1,
        min_zoom: 0,
        min_label: 5,
        max_label: 10,
        label_x: 7.021,
        label_y: 0.9709,
        ne_id: 1159321273,
        wikidataid: "Q1039",
        name_ar: "ساو تومي وبرينسيب",
        name_bn: "সাঁউ তুমি ও প্রিন্সিপি",
        name_de: "São Tomé und Príncipe",
        name_en: "São Tomé and Príncipe",
        name_es: "Santo Tomé y Príncipe",
        name_fa: "سائوتومه و پرینسیپ",
        name_fr: "Sao Tomé-et-Principe",
        name_el: "Σάο Τομέ και Πρίνσιπε",
        name_he: "סאו טומה ופרינסיפה",
        name_hi: "साओ तोमे और प्रिन्सिपी",
        name_hu: "São Tomé és Príncipe",
        name_id: "Sao Tome dan Principe",
        name_it: "São Tomé e Príncipe",
        name_ja: "サントメ・プリンシペ",
        name_ko: "상투메 프린시페",
        name_nl: "Sao Tomé en Principe",
        name_pl: "Wyspy Świętego Tomasza i Książęca",
        name_pt: "São Tomé e Príncipe",
        name_ru: "Сан-Томе и Принсипи",
        name_sv: "São Tomé och Príncipe",
        name_tr: "São Tomé ve Príncipe",
        name_uk: "Сан-Томе і Принсіпі",
        name_ur: "ساؤ ٹومے و پرنسپے",
        name_vi: "São Tomé và Príncipe",
        name_zh: "圣多美和普林西比",
        name_zht: "聖多美普林西比",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "STP.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [7.423828125, 1.567724609374991],
              [7.386621093750023, 1.541552734374989],
              [7.342382812500006, 1.563574218749991],
              [7.330664062500006, 1.603369140624991],
              [7.387597656250023, 1.68017578125],
              [7.414453125000023, 1.699121093749994],
              [7.43701171875, 1.683056640624997],
              [7.450390625000011, 1.661962890624991],
              [7.452343750000011, 1.631103515625],
              [7.423828125, 1.567724609374991],
            ],
          ],
          [
            [
              [6.659960937500017, 0.120654296874989],
              [6.556835937500011, 0.04736328125],
              [6.519726562500011, 0.066308593749994],
              [6.496972656250023, 0.117382812499997],
              [6.468164062500023, 0.227343749999989],
              [6.4775390625, 0.280126953124991],
              [6.524316406250023, 0.340283203124997],
              [6.625878906250023, 0.400244140624991],
              [6.686914062500023, 0.404394531249991],
              [6.749804687500017, 0.325634765624997],
              [6.75, 0.243457031249989],
              [6.659960937500017, 0.120654296874989],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 3,
        labelrank: 5,
        sovereignt: "Lithuania",
        sov_a3: "LTU",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Lithuania",
        adm0_a3: "LTU",
        geou_dif: 0,
        geounit: "Lithuania",
        gu_a3: "LTU",
        su_dif: 0,
        subunit: "Lithuania",
        su_a3: "LTU",
        brk_diff: 0,
        name: "Lithuania",
        name_long: "Lithuania",
        brk_a3: "LTU",
        brk_name: "Lithuania",
        brk_group: null,
        abbrev: "Lith.",
        postal: "LT",
        formal_en: "Republic of Lithuania",
        formal_fr: null,
        name_ciawf: "Lithuania",
        note_adm0: null,
        note_brk: null,
        name_sort: "Lithuania",
        name_alt: null,
        mapcolor7: 6,
        mapcolor8: 3,
        mapcolor9: 3,
        mapcolor13: 9,
        pop_est: 2786844,
        pop_rank: 12,
        pop_year: 2019,
        gdp_md: 54627,
        gdp_year: 2019,
        economy: "2. Developed region: nonG7",
        income_grp: "3. Upper middle income",
        fips_10: "LH",
        iso_a2: "LT",
        iso_a2_eh: "LT",
        iso_a3: "LTU",
        iso_a3_eh: "LTU",
        iso_n3: "440",
        iso_n3_eh: "440",
        un_a3: "440",
        wb_a2: "LT",
        wb_a3: "LTU",
        woe_id: 23424875,
        woe_id_eh: 23424875,
        woe_note: "Exact WOE match as country",
        adm0_iso: "LTU",
        adm0_diff: null,
        adm0_tlc: "LTU",
        adm0_a3_us: "LTU",
        adm0_a3_fr: "LTU",
        adm0_a3_ru: "LTU",
        adm0_a3_es: "LTU",
        adm0_a3_cn: "LTU",
        adm0_a3_tw: "LTU",
        adm0_a3_in: "LTU",
        adm0_a3_np: "LTU",
        adm0_a3_pk: "LTU",
        adm0_a3_de: "LTU",
        adm0_a3_gb: "LTU",
        adm0_a3_br: "LTU",
        adm0_a3_il: "LTU",
        adm0_a3_ps: "LTU",
        adm0_a3_sa: "LTU",
        adm0_a3_eg: "LTU",
        adm0_a3_ma: "LTU",
        adm0_a3_pt: "LTU",
        adm0_a3_ar: "LTU",
        adm0_a3_jp: "LTU",
        adm0_a3_ko: "LTU",
        adm0_a3_vn: "LTU",
        adm0_a3_tr: "LTU",
        adm0_a3_id: "LTU",
        adm0_a3_pl: "LTU",
        adm0_a3_gr: "LTU",
        adm0_a3_it: "LTU",
        adm0_a3_nl: "LTU",
        adm0_a3_se: "LTU",
        adm0_a3_bd: "LTU",
        adm0_a3_ua: "LTU",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Northern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 9,
        long_len: 9,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: 24.089932,
        label_y: 55.103703,
        ne_id: 1159321029,
        wikidataid: "Q37",
        name_ar: "ليتوانيا",
        name_bn: "লিথুয়ানিয়া",
        name_de: "Litauen",
        name_en: "Lithuania",
        name_es: "Lituania",
        name_fa: "لیتوانی",
        name_fr: "Lituanie",
        name_el: "Λιθουανία",
        name_he: "ליטא",
        name_hi: "लिथुआनिया",
        name_hu: "Litvánia",
        name_id: "Lituania",
        name_it: "Lituania",
        name_ja: "リトアニア",
        name_ko: "리투아니아",
        name_nl: "Litouwen",
        name_pl: "Litwa",
        name_pt: "Lituânia",
        name_ru: "Литва",
        name_sv: "Litauen",
        name_tr: "Litvanya",
        name_uk: "Литва",
        name_ur: "لتھووینیا",
        name_vi: "Litva",
        name_zh: "立陶宛",
        name_zht: "立陶宛",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "LTU.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [20.957812500000017, 55.27890625],
              [20.899804687500023, 55.286669921874996],
              [21.014062500000023, 55.401953125],
              [21.0576171875, 55.476806640625],
              [21.087890625, 55.58310546875],
              [21.11484375, 55.616503906249996],
              [21.11572265625, 55.568164062499996],
              [21.103906250000023, 55.487744140625],
              [21.03173828125, 55.35048828125],
              [20.957812500000017, 55.27890625],
            ],
          ],
          [
            [
              [22.766210937500006, 54.356787109375],
              [22.72431640625001, 54.405615234375],
              [22.67988281250001, 54.493017578125],
              [22.684472656250023, 54.562939453125],
              [22.70966796875001, 54.632617187499996],
              [22.83125, 54.8384765625],
              [22.82470703125, 54.8712890625],
              [22.73652343750001, 54.928857421875],
              [22.62744140625, 54.970703125],
              [22.567285156250023, 55.059130859374996],
              [22.34638671875001, 55.064257812499996],
              [22.13789062500001, 55.059375],
              [22.072363281250006, 55.063671875],
              [21.873925781250023, 55.100732421875],
              [21.682714843750006, 55.1603515625],
              [21.5546875, 55.1953125],
              [21.447070312500017, 55.234423828124996],
              [21.389257812500006, 55.275537109375],
              [21.29755859375001, 55.264453125],
              [21.235742187500023, 55.264111328125],
              [21.236328125, 55.27119140625],
              [21.201074218750023, 55.343798828124996],
              [21.237890625, 55.455029296875],
              [21.17109375000001, 55.617724609374996],
              [21.061914062500023, 55.813427734375],
              [21.053808593750006, 56.02294921875],
              [21.04609375000001, 56.070068359375],
              [21.31464843750001, 56.188134765625],
              [21.65351562500001, 56.31455078125],
              [21.730566406250006, 56.325976562499996],
              [22.042871093750023, 56.40078125],
              [22.084570312500006, 56.40673828125],
              [22.36591796875001, 56.392871093749996],
              [22.5869140625, 56.37509765625],
              [22.773242187500017, 56.377294921875],
              [22.875585937500006, 56.396435546875],
              [22.96826171875, 56.380419921874996],
              [23.04296875, 56.324072265625],
              [23.11982421875001, 56.3306640625],
              [23.195898437500006, 56.367138671875],
              [23.612695312500023, 56.333837890625],
              [23.70673828125001, 56.334619140625],
              [23.81269531250001, 56.329248046875],
              [24.008203125000023, 56.295263671875],
              [24.120703125, 56.2642578125],
              [24.36787109375001, 56.283007812499996],
              [24.4736328125, 56.28408203125],
              [24.52900390625001, 56.2962890625],
              [24.699511718750017, 56.381298828125],
              [24.773242187500017, 56.3958984375],
              [24.84101562500001, 56.411181640624996],
              [24.90302734375001, 56.398193359375],
              [24.94384765625, 56.3255859375],
              [25.069921875, 56.200390625],
              [25.206933593750023, 56.17841796875],
              [25.585742187500017, 56.130175781249996],
              [25.663183593750006, 56.104833984375],
              [25.876367187500023, 55.9943359375],
              [26.004199218750017, 55.94013671875],
              [26.085546875, 55.896875],
              [26.209570312500006, 55.812109375],
              [26.28125, 55.750439453125],
              [26.40107421875001, 55.70380859375],
              [26.542871093750023, 55.672412109374996],
              [26.593554687500017, 55.667529296874996],
              [26.5908203125, 55.62265625],
              [26.56660156250001, 55.546484375],
              [26.51923828125001, 55.44814453125],
              [26.469531250000017, 55.371923828125],
              [26.457617187500006, 55.34248046875],
              [26.49531250000001, 55.318017578125],
              [26.68125, 55.306445312499996],
              [26.760156250000023, 55.293359375],
              [26.775683593750017, 55.273095703125],
              [26.734375, 55.24677734375],
              [26.675, 55.224902343749996],
              [26.6484375, 55.20419921875],
              [26.601171875, 55.130175781249996],
              [26.291796875000017, 55.139599609375],
              [26.250781250000017, 55.12451171875],
              [26.23125, 55.090136718749996],
              [26.2158203125, 55.050390625],
              [26.175195312500023, 55.003271484375],
              [26.09296875000001, 54.9623046875],
              [25.964453125, 54.94716796875],
              [25.859277343750023, 54.919287109375],
              [25.780859375, 54.833251953125],
              [25.722460937500017, 54.71787109375],
              [25.723925781250017, 54.636035156249996],
              [25.73164062500001, 54.590380859374996],
              [25.72480468750001, 54.564257812499996],
              [25.68515625, 54.535791015625],
              [25.62031250000001, 54.460400390625],
              [25.56757812500001, 54.37705078125],
              [25.54736328125, 54.331835937499996],
              [25.557519531250023, 54.310693359375],
              [25.61689453125001, 54.310107421874996],
              [25.702539062500023, 54.29296875],
              [25.748144531250006, 54.25966796875],
              [25.765039062500023, 54.22119140625],
              [25.765234375, 54.17978515625],
              [25.74921875000001, 54.156982421875],
              [25.680566406250023, 54.140478515625],
              [25.573046875000017, 54.139892578125],
              [25.510351562500006, 54.159619140625],
              [25.497363281250017, 54.175244140625],
              [25.52734375, 54.215136718749996],
              [25.505664062500017, 54.264941406249996],
              [25.46113281250001, 54.292773437499996],
              [25.37060546875, 54.251220703125],
              [25.28369140625, 54.251269531249996],
              [25.179492187500017, 54.2142578125],
              [25.111425781250006, 54.154931640625],
              [25.04609375000001, 54.133056640625],
              [24.869531250000023, 54.145166015625],
              [24.82568359375, 54.118994140625],
              [24.78925781250001, 53.9982421875],
              [24.768164062500006, 53.974658203124996],
              [24.620703125, 53.979833984375],
              [24.478515625, 53.9318359375],
              [24.31796875, 53.89296875],
              [24.236621093750017, 53.919970703124996],
              [24.191308593750023, 53.950439453125],
              [24.103906250000023, 53.94501953125],
              [24.00849609375001, 53.931640625],
              [23.944433593750006, 53.93896484375],
              [23.87255859375, 53.935693359375],
              [23.733691406250017, 53.912255859375],
              [23.55908203125, 53.91982421875],
              [23.484667968750017, 53.939794921875],
              [23.477636718750006, 53.958935546875],
              [23.483007812500006, 54.00595703125],
              [23.481347656250023, 54.07900390625],
              [23.45361328125, 54.14345703125],
              [23.3701171875, 54.200488281249996],
              [23.282324218750006, 54.24033203125],
              [23.170312500000023, 54.2814453125],
              [23.0875, 54.299462890625],
              [23.04218750000001, 54.30419921875],
              [23.03193359375001, 54.327880859375],
              [23.015527343750023, 54.34833984375],
              [22.97675781250001, 54.366357421875],
              [22.893945312500023, 54.39052734375],
              [22.82373046875, 54.395800781249996],
              [22.766210937500006, 54.356787109375],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 5,
        sovereignt: "Czechia",
        sov_a3: "CZE",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Czechia",
        adm0_a3: "CZE",
        geou_dif: 0,
        geounit: "Czechia",
        gu_a3: "CZE",
        su_dif: 0,
        subunit: "Czechia",
        su_a3: "CZE",
        brk_diff: 0,
        name: "Czechia",
        name_long: "Czech Republic",
        brk_a3: "CZE",
        brk_name: "Czechia",
        brk_group: null,
        abbrev: "Cz.",
        postal: "CZ",
        formal_en: "Czech Republic",
        formal_fr: "la République tchèque",
        name_ciawf: "Czechia",
        note_adm0: null,
        note_brk: null,
        name_sort: "Czechia",
        name_alt: "Česko",
        mapcolor7: 1,
        mapcolor8: 1,
        mapcolor9: 2,
        mapcolor13: 6,
        pop_est: 10669709,
        pop_rank: 14,
        pop_year: 2019,
        gdp_md: 250680,
        gdp_year: 2019,
        economy: "2. Developed region: nonG7",
        income_grp: "1. High income: OECD",
        fips_10: "EZ",
        iso_a2: "CZ",
        iso_a2_eh: "CZ",
        iso_a3: "CZE",
        iso_a3_eh: "CZE",
        iso_n3: "203",
        iso_n3_eh: "203",
        un_a3: "203",
        wb_a2: "CZ",
        wb_a3: "CZE",
        woe_id: 23424810,
        woe_id_eh: 23424810,
        woe_note: "Exact WOE match as country",
        adm0_iso: "CZE",
        adm0_diff: null,
        adm0_tlc: "CZE",
        adm0_a3_us: "CZE",
        adm0_a3_fr: "CZE",
        adm0_a3_ru: "CZE",
        adm0_a3_es: "CZE",
        adm0_a3_cn: "CZE",
        adm0_a3_tw: "CZE",
        adm0_a3_in: "CZE",
        adm0_a3_np: "CZE",
        adm0_a3_pk: "CZE",
        adm0_a3_de: "CZE",
        adm0_a3_gb: "CZE",
        adm0_a3_br: "CZE",
        adm0_a3_il: "CZE",
        adm0_a3_ps: "CZE",
        adm0_a3_sa: "CZE",
        adm0_a3_eg: "CZE",
        adm0_a3_ma: "CZE",
        adm0_a3_pt: "CZE",
        adm0_a3_ar: "CZE",
        adm0_a3_jp: "CZE",
        adm0_a3_ko: "CZE",
        adm0_a3_vn: "CZE",
        adm0_a3_tr: "CZE",
        adm0_a3_id: "CZE",
        adm0_a3_pl: "CZE",
        adm0_a3_gr: "CZE",
        adm0_a3_it: "CZE",
        adm0_a3_nl: "CZE",
        adm0_a3_se: "CZE",
        adm0_a3_bd: "CZE",
        adm0_a3_ua: "CZE",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Eastern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 7,
        long_len: 14,
        abbrev_len: 3,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: 15.377555,
        label_y: 49.882364,
        ne_id: 1159320535,
        wikidataid: "Q213",
        name_ar: "التشيك",
        name_bn: "চেক প্রজাতন্ত্র",
        name_de: "Tschechien",
        name_en: "Czech Republic",
        name_es: "República Checa",
        name_fa: "جمهوری چک",
        name_fr: "Tchéquie",
        name_el: "Τσεχία",
        name_he: "צ'כיה",
        name_hi: "चेक गणराज्य",
        name_hu: "Csehország",
        name_id: "Republik Ceko",
        name_it: "Repubblica Ceca",
        name_ja: "チェコ",
        name_ko: "체코",
        name_nl: "Tsjechië",
        name_pl: "Czechy",
        name_pt: "Chéquia",
        name_ru: "Чехия",
        name_sv: "Tjeckien",
        name_tr: "Çek Cumhuriyeti",
        name_uk: "Чехія",
        name_ur: "چیک جمہوریہ",
        name_vi: "Cộng hòa Séc",
        name_zh: "捷克",
        name_zht: "捷克共和國",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "CZE.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.83222656250001, 49.510791015624996],
            [18.807031250000023, 49.509228515625],
            [18.74970703125001, 49.493994140625],
            [18.676171875000023, 49.4884765625],
            [18.596484375000017, 49.491455078125],
            [18.534570312500023, 49.464697265625],
            [18.47607421875, 49.42109375],
            [18.415820312500017, 49.390917968749996],
            [18.383105468750017, 49.363916015624994],
            [18.36484375, 49.336230468749996],
            [18.160937500000017, 49.257373046874996],
            [18.132617187500017, 49.224560546875],
            [18.109960937500006, 49.17978515625],
            [18.100390625000017, 49.1193359375],
            [18.0859375, 49.06513671875],
            [18.050878906250006, 49.036523437499994],
            [17.940722656250017, 49.011962890625],
            [17.91328125000001, 48.99873046875],
            [17.892675781250006, 48.971142578125],
            [17.830859375000017, 48.928613281249994],
            [17.75849609375001, 48.888134765625],
            [17.625390625000023, 48.841845703124996],
            [17.48261718750001, 48.827783203124994],
            [17.296875, 48.842822265624996],
            [17.1884765625, 48.8609375],
            [17.135644531250023, 48.841064453125],
            [17.063281250000017, 48.78076171875],
            [16.985253906250023, 48.676904296874994],
            [16.953125, 48.598828125],
            [16.928320312500006, 48.620898437499996],
            [16.883691406250023, 48.7037109375],
            [16.83320312500001, 48.714306640625],
            [16.764453125000017, 48.722021484375],
            [16.712695312500017, 48.734228515625],
            [16.600976562500023, 48.781884765624994],
            [16.543554687500006, 48.796240234375],
            [16.477929687500023, 48.800097656249996],
            [16.414843750000017, 48.7720703125],
            [16.367285156250006, 48.73896484375],
            [16.219335937500006, 48.739404296874994],
            [16.057226562500006, 48.754785156249994],
            [15.8251953125, 48.864453125],
            [15.765039062500023, 48.8654296875],
            [15.70078125, 48.86044921875],
            [15.599414062500017, 48.886376953124994],
            [15.402929687500006, 48.957373046875],
            [15.310937500000023, 48.974023437499994],
            [15.252734375000017, 48.9638671875],
            [15.199609375000023, 48.948144531249994],
            [15.16171875, 48.9462890625],
            [15.139746093750006, 48.9693359375],
            [15.066796875000023, 48.997851562499996],
            [14.993457031250017, 49.001123046874994],
            [14.97216796875, 48.983935546874996],
            [14.947363281250006, 48.827734375],
            [14.922558593750011, 48.771386718749994],
            [14.821875, 48.7740234375],
            [14.785937500000017, 48.747363281249996],
            [14.706640625, 48.671923828124996],
            [14.691308593750023, 48.59921875],
            [14.553906250000011, 48.613330078124996],
            [14.488671875000023, 48.625537109374996],
            [14.431054687500023, 48.616259765624996],
            [14.367578125000023, 48.576220703124996],
            [14.189843750000023, 48.578564453125],
            [14.049121093750017, 48.602490234375],
            [13.98876953125, 48.692431640624996],
            [13.92431640625, 48.72802734375],
            [13.843164062500023, 48.75986328125],
            [13.814746093750017, 48.766943359375],
            [13.769921875000023, 48.815966796874996],
            [13.684960937500023, 48.876708984375],
            [13.547656250000017, 48.95966796875],
            [13.440722656250017, 48.95556640625],
            [13.401171875000017, 48.977587890624996],
            [13.383691406250023, 49.008105468749996],
            [13.339062500000011, 49.060791015625],
            [13.288769531250011, 49.097460937499996],
            [13.227832031250017, 49.111669921875],
            [13.140527343750023, 49.158349609374994],
            [13.023730468750017, 49.260107421875],
            [12.916699218750011, 49.33046875],
            [12.813378906250023, 49.329345703125],
            [12.747851562500017, 49.3662109375],
            [12.68115234375, 49.414501953125],
            [12.632031250000011, 49.461230468749996],
            [12.555761718750006, 49.574853515624994],
            [12.500292968750017, 49.639697265624996],
            [12.45703125, 49.67978515625],
            [12.408203125, 49.713183593749996],
            [12.390527343750023, 49.739648437499994],
            [12.4501953125, 49.800146484375],
            [12.471875, 49.830078125],
            [12.49755859375, 49.853076171874996],
            [12.5125, 49.87744140625],
            [12.512011718750017, 49.895800781249996],
            [12.457617187500006, 49.955517578125],
            [12.384179687500023, 49.998583984374996],
            [12.276464843750006, 50.042333984375],
            [12.207812500000017, 50.097509765625],
            [12.182519531250023, 50.148046875],
            [12.175, 50.175830078124996],
            [12.127832031250023, 50.213427734374996],
            [12.089746093750023, 50.2685546875],
            [12.08984375, 50.3017578125],
            [12.09921875, 50.310986328125],
            [12.134863281250006, 50.3109375],
            [12.1748046875, 50.288378906249996],
            [12.231152343750011, 50.244873046875],
            [12.27734375, 50.181445312499996],
            [12.3056640625, 50.205712890624994],
            [12.358593750000011, 50.273242187499996],
            [12.45263671875, 50.3498046875],
            [12.549023437500011, 50.393408203125],
            [12.635546875000017, 50.3970703125],
            [12.706445312500023, 50.409130859375],
            [12.765429687500017, 50.430957031249996],
            [12.868261718750006, 50.422216796875],
            [12.942675781250017, 50.4064453125],
            [12.966796875, 50.4162109375],
            [12.9970703125, 50.4560546875],
            [13.016406250000017, 50.490380859374994],
            [13.18115234375, 50.510498046875],
            [13.237695312500023, 50.5767578125],
            [13.26953125, 50.576416015625],
            [13.306054687500023, 50.586328125],
            [13.341015625000011, 50.61142578125],
            [13.374609375, 50.621728515624994],
            [13.401171875000017, 50.609326171875],
            [13.436132812500006, 50.60107421875],
            [13.472558593750023, 50.616943359375],
            [13.526562500000011, 50.692822265625],
            [13.556738281250006, 50.704638671874996],
            [13.701367187500011, 50.71650390625],
            [13.898535156250006, 50.761279296874996],
            [13.998437500000023, 50.801123046875],
            [14.096484375000017, 50.82275390625],
            [14.201757812500006, 50.861230468749994],
            [14.369042968750023, 50.898730468749996],
            [14.377050781250006, 50.9140625],
            [14.299414062500006, 50.952587890625],
            [14.273339843750023, 50.976904296875],
            [14.255859375, 51.001855468749994],
            [14.283203125, 51.0294921875],
            [14.319726562500023, 51.03779296875],
            [14.367285156250006, 51.026269531249994],
            [14.50732421875, 51.00986328125],
            [14.545703125000017, 50.993945312499996],
            [14.559667968750006, 50.954931640625],
            [14.59521484375, 50.918603515624994],
            [14.623828125000017, 50.91474609375],
            [14.613574218750017, 50.85556640625],
            [14.658203125, 50.8326171875],
            [14.723339843750011, 50.814697265625],
            [14.766503906250023, 50.818310546875],
            [14.797460937500006, 50.842333984374996],
            [14.809375, 50.858984375],
            [14.895800781250017, 50.861376953124996],
            [14.98291015625, 50.886572265625],
            [14.989941406250011, 50.92724609375],
            [14.984472656250006, 51.00341796875],
            [14.99375, 51.01435546875],
            [15.1259765625, 50.99287109375],
            [15.258593750000017, 50.958544921874996],
            [15.277050781250011, 50.8830078125],
            [15.312597656250006, 50.845751953124996],
            [15.354394531250023, 50.811767578125],
            [15.394628906250006, 50.796289062499994],
            [15.463964843750006, 50.793847656249994],
            [15.643945312500023, 50.748876953125],
            [15.730566406250006, 50.739697265625],
            [15.819238281250023, 50.70869140625],
            [15.893945312500023, 50.676904296874994],
            [15.948535156250017, 50.670263671875],
            [15.973828125000011, 50.63544921875],
            [16.007226562500023, 50.611621093749996],
            [16.06640625, 50.629931640624996],
            [16.2822265625, 50.655615234375],
            [16.359960937500006, 50.621386718749996],
            [16.4125, 50.58515625],
            [16.419726562500017, 50.573632812499994],
            [16.39228515625001, 50.541650390624994],
            [16.37910156250001, 50.516894531249996],
            [16.35664062500001, 50.50048828125],
            [16.282519531250017, 50.4830078125],
            [16.24072265625, 50.4546875],
            [16.210351562500023, 50.423730468749994],
            [16.230761718750017, 50.394091796874996],
            [16.291308593750017, 50.371875],
            [16.33417968750001, 50.36689453125],
            [16.350488281250023, 50.34521484375],
            [16.487597656250017, 50.248388671875],
            [16.5966796875, 50.121923828125],
            [16.63916015625, 50.1021484375],
            [16.679101562500023, 50.097460937499996],
            [16.72529296875001, 50.116064453125],
            [16.778613281250017, 50.15703125],
            [16.841796875, 50.18671875],
            [16.895312500000017, 50.201953125],
            [16.989648437500023, 50.2369140625],
            [16.99335937500001, 50.259716796875],
            [16.91474609375001, 50.34521484375],
            [16.869140625, 50.414501953125],
            [16.88007812500001, 50.427050781249996],
            [16.980761718750017, 50.41611328125],
            [17.151953125, 50.378320312499994],
            [17.41523437500001, 50.254785156249994],
            [17.462304687500023, 50.254785156249994],
            [17.554589843750023, 50.2640625],
            [17.654687500000023, 50.284228515624996],
            [17.702246093750006, 50.307177734374996],
            [17.720117187500023, 50.298632812499996],
            [17.735449218750006, 50.230761718749996],
            [17.709277343750017, 50.1935546875],
            [17.58935546875, 50.157470703125],
            [17.596289062500006, 50.139501953125],
            [17.627050781250006, 50.11640625],
            [17.681054687500023, 50.10078125],
            [17.74658203125, 50.056787109374994],
            [17.79169921875001, 50.006591796875],
            [17.83125, 49.983300781249994],
            [17.874804687500017, 49.972265625],
            [17.983789062500023, 49.999072265624996],
            [18.0146484375, 50.020263671875],
            [18.0283203125, 50.03525390625],
            [18.04951171875001, 50.03193359375],
            [18.087695312500017, 50.007275390625],
            [18.09921875, 49.9927734375],
            [18.20527343750001, 49.964746093749994],
            [18.26630859375001, 49.9302734375],
            [18.305273437500006, 49.9140625],
            [18.348437500000017, 49.929833984374994],
            [18.516210937500006, 49.902392578124996],
            [18.562402343750023, 49.879345703125],
            [18.5771484375, 49.841113281249996],
            [18.56884765625, 49.817919921874996],
            [18.594628906250023, 49.7578125],
            [18.806933593750017, 49.613720703125],
            [18.82929687500001, 49.54013671875],
            [18.83222656250001, 49.510791015624996],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Ukraine",
        sov_a3: "UKR",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Ukraine",
        adm0_a3: "UKR",
        geou_dif: 0,
        geounit: "Ukraine",
        gu_a3: "UKR",
        su_dif: 0,
        subunit: "Ukraine",
        su_a3: "UKR",
        brk_diff: 0,
        name: "Ukraine",
        name_long: "Ukraine",
        brk_a3: "UKR",
        brk_name: "Ukraine",
        brk_group: null,
        abbrev: "Ukr.",
        postal: "UA",
        formal_en: "Ukraine",
        formal_fr: null,
        name_ciawf: "Ukraine",
        note_adm0: null,
        note_brk: null,
        name_sort: "Ukraine",
        name_alt: null,
        mapcolor7: 5,
        mapcolor8: 1,
        mapcolor9: 6,
        mapcolor13: 3,
        pop_est: 44385155,
        pop_rank: 15,
        pop_year: 2019,
        gdp_md: 153781,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        fips_10: "UP",
        iso_a2: "UA",
        iso_a2_eh: "UA",
        iso_a3: "UKR",
        iso_a3_eh: "UKR",
        iso_n3: "804",
        iso_n3_eh: "804",
        un_a3: "804",
        wb_a2: "UA",
        wb_a3: "UKR",
        woe_id: 23424976,
        woe_id_eh: 23424976,
        woe_note: "Exact WOE match as country",
        adm0_iso: "UKR",
        adm0_diff: null,
        adm0_tlc: "UKR",
        adm0_a3_us: "UKR",
        adm0_a3_fr: "UKR",
        adm0_a3_ru: "UKR",
        adm0_a3_es: "UKR",
        adm0_a3_cn: "UKR",
        adm0_a3_tw: "UKR",
        adm0_a3_in: "UKR",
        adm0_a3_np: "UKR",
        adm0_a3_pk: "UKR",
        adm0_a3_de: "UKR",
        adm0_a3_gb: "UKR",
        adm0_a3_br: "UKR",
        adm0_a3_il: "UKR",
        adm0_a3_ps: "UKR",
        adm0_a3_sa: "UKR",
        adm0_a3_eg: "UKR",
        adm0_a3_ma: "UKR",
        adm0_a3_pt: "UKR",
        adm0_a3_ar: "UKR",
        adm0_a3_jp: "UKR",
        adm0_a3_ko: "UKR",
        adm0_a3_vn: "UKR",
        adm0_a3_tr: "UKR",
        adm0_a3_id: "UKR",
        adm0_a3_pl: "UKR",
        adm0_a3_gr: "UKR",
        adm0_a3_it: "UKR",
        adm0_a3_nl: "UKR",
        adm0_a3_se: "UKR",
        adm0_a3_bd: "UKR",
        adm0_a3_ua: "UKR",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Eastern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 2.7,
        max_label: 7,
        label_x: 32.140865,
        label_y: 49.724739,
        ne_id: 1159321345,
        wikidataid: "Q212",
        name_ar: "أوكرانيا",
        name_bn: "ইউক্রেন",
        name_de: "Ukraine",
        name_en: "Ukraine",
        name_es: "Ucrania",
        name_fa: "اوکراین",
        name_fr: "Ukraine",
        name_el: "Ουκρανία",
        name_he: "אוקראינה",
        name_hi: "युक्रेन",
        name_hu: "Ukrajna",
        name_id: "Ukraina",
        name_it: "Ucraina",
        name_ja: "ウクライナ",
        name_ko: "우크라이나",
        name_nl: "Oekraïne",
        name_pl: "Ukraina",
        name_pt: "Ucrânia",
        name_ru: "Украина",
        name_sv: "Ukraina",
        name_tr: "Ukrayna",
        name_uk: "Україна",
        name_ur: "یوکرین",
        name_vi: "Ukraina",
        name_zh: "乌克兰",
        name_zht: "烏克蘭",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "UKR.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [38.21435546875, 47.091455078124994],
              [38.178320312500055, 47.080224609375136],
              [37.82871093750006, 47.09584960937512],
              [37.76114725254966, 47.0908089237766],
              [37.54335937500019, 47.07456054687505],
              [37.33984375000012, 46.91689453125008],
              [37.2185546875001, 46.91733398437517],
              [37.04755859375008, 46.87622070312514],
              [36.93203125000017, 46.82514648437522],
              [36.79482421875011, 46.71440429687504],
              [36.68867187500015, 46.76411132812522],
              [36.55878906250016, 46.76269531250011],
              [36.432031250000094, 46.732568359375186],
              [36.279492187500104, 46.658593750000215],
              [36.19462890625022, 46.64550781250021],
              [36.02490234375011, 46.66679687500011],
              [35.82714843750014, 46.62431640625016],
              [35.4001953125002, 46.38139648437505],
              [35.25664062500009, 46.20390625000013],
              [35.2043945312501, 46.16918945312516],
              [35.13232421875016, 46.12587890625022],
              [35.0552734375001, 46.104003906250206],
              [35.01455078125015, 46.10600585937508],
              [35.21777343750016, 46.232177734375206],
              [35.28017578125009, 46.279492187500075],
              [35.29091796875014, 46.31440429687508],
              [35.291992187500185, 46.37070312500018],
              [35.230371093750065, 46.44062500000022],
              [35.06406250000011, 46.267236328125065],
              [34.969531250000216, 46.242089843750094],
              [34.84960937500012, 46.18989257812512],
              [34.84375000000017, 46.07358398437505],
              [34.857324218750094, 45.98735351562507],
              [34.90664062500019, 45.87880859375016],
              [35.00167423940718, 45.73338320565324],
              [34.94642782810598, 45.72868577195129],
              [34.799725948223966, 45.79075195190131],
              [34.79408356822853, 45.89231479181956],
              [34.686878348314735, 45.97695049175155],
              [34.5232493284463, 45.97695049175155],
              [34.44989838850546, 45.965665731760566],
              [34.35397792858265, 46.061586191683546],
              [34.224203188687056, 46.10108285165177],
              [34.12828272876425, 46.089798091660725],
              [34.02671988884583, 46.10672523164715],
              [33.80666706902298, 46.20828807156539],
              [33.65996518914096, 46.21957283155638],
              [33.65432280914541, 46.14622189161537],
              [33.59414062500008, 46.09624023437499],
              [33.49882812500009, 46.07885742187506],
              [33.429882812500075, 46.05761718750017],
              [33.2634765625001, 46.12568359375018],
              [33.20224609375006, 46.175732421875075],
              [32.941796875000115, 46.12377929687515],
              [32.796875, 46.13149414062512],
              [32.476757812500054, 46.08369140625015],
              [32.32988281250015, 46.13037109375007],
              [32.03574218750009, 46.26098632812521],
              [31.925195312500133, 46.287255859375236],
              [31.83125000000018, 46.28168945312517],
              [31.779980468750214, 46.32465820312522],
              [31.84287109375006, 46.34614257812515],
              [31.915917968750197, 46.34868164062514],
              [31.99169921875018, 46.36440429687514],
              [32.013085937500094, 46.38715820312516],
              [32.00849609375005, 46.42998046875018],
              [31.8557617187502, 46.462451171875045],
              [31.713671875000205, 46.47177734375017],
              [31.62363281250016, 46.51025390625019],
              [31.554882812500185, 46.554296875000105],
              [31.71601562500015, 46.55498046875007],
              [31.877929687500096, 46.52167968750021],
              [32.13144531250005, 46.50937500000019],
              [32.36132812500016, 46.474951171875105],
              [32.41894531250018, 46.517773437500125],
              [32.552539062500216, 46.591992187500146],
              [32.57802734375008, 46.615625000000165],
              [32.35410156250011, 46.56484375000012],
              [32.12724609375008, 46.597216796875145],
              [32.044335937500065, 46.64248046875013],
              [31.974316406250193, 46.70878906250014],
              [31.944921875000066, 46.784375],
              [31.964062500000058, 46.85483398437505],
              [31.939550781250038, 46.981982421875195],
              [31.864746093750075, 47.09511718750015],
              [31.83818359375017, 47.15722656250019],
              [31.759179687500062, 47.21284179687515],
              [31.83691406250009, 47.0870117187501],
              [31.865917968750136, 47.00327148437509],
              [31.912695312500073, 46.92612304687518],
              [31.901660156250134, 46.72163085937509],
              [31.872851562500127, 46.64975585937519],
              [31.779589843750134, 46.63164062500005],
              [31.657031250000212, 46.64243164062512],
              [31.53212890625017, 46.664746093750225],
              [31.56337890625014, 46.77729492187506],
              [31.496875, 46.73837890625013],
              [31.402929687500137, 46.628808593750186],
              [31.32031250000018, 46.6125],
              [31.1368164062501, 46.62446289062519],
              [30.796289062500183, 46.55200195312517],
              [30.772851562500204, 46.47304687500007],
              [30.72167968750008, 46.366210937500156],
              [30.67226562500014, 46.304003906250095],
              [30.656738281250174, 46.266503906250094],
              [30.51152343750025, 46.10537109375013],
              [30.492968750000202, 46.090136718750045],
              [30.219042968750173, 45.86674804687519],
              [30.184179687500176, 45.84995117187514],
              [30.006640625000244, 45.79794921875003],
              [29.901660156250173, 45.75239257812516],
              [29.821191406250122, 45.73208007812511],
              [29.6850585937501, 45.754687500000095],
              [29.628417968750107, 45.7224609375001],
              [29.601660156250162, 45.682519531250136],
              [29.601171875000063, 45.6000000000002],
              [29.670312500000115, 45.54067382812519],
              [29.726953125000108, 45.34331054687513],
              [29.705859375000017, 45.259912109374994],
              [29.651953125, 45.313916015625],
              [29.567675781250017, 45.37080078125],
              [29.403710937500023, 45.419677734375],
              [29.223535156250023, 45.4029296875],
              [29.027441406250006, 45.320556640625],
              [28.894335937500017, 45.289941406249994],
              [28.824316406250006, 45.311083984374996],
              [28.78173828125, 45.309863281249996],
              [28.7666015625, 45.28623046875],
              [28.769824218750017, 45.266894531249996],
              [28.791406250000023, 45.251904296875],
              [28.78828125000001, 45.240966796875],
              [28.7607421875, 45.234130859375],
              [28.451269531250006, 45.2921875],
              [28.317675781250017, 45.347119140625],
              [28.2125, 45.450439453125],
              [28.26484375000001, 45.48388671875],
              [28.310351562500017, 45.498583984374996],
              [28.47138671875001, 45.507177734375],
              [28.4990234375, 45.517724609374994],
              [28.501757812500017, 45.541552734374996],
              [28.513769531250006, 45.572412109374994],
              [28.50947265625001, 45.617822265624994],
              [28.491601562500023, 45.665771484375],
              [28.562304687500017, 45.735791015625],
              [28.667578125, 45.793847656249994],
              [28.729296875000017, 45.852001953125],
              [28.73876953125, 45.937158203124994],
              [28.849511718750023, 45.978662109374994],
              [28.94775390625, 46.049951171874994],
              [28.971875, 46.12763671875],
              [29.00625, 46.17646484375],
              [28.94375, 46.288427734375],
              [28.930566406250023, 46.362255859375],
              [28.92744140625001, 46.424121093749996],
              [28.958398437500023, 46.45849609375],
              [29.049902343750006, 46.497021484375],
              [29.146289062500017, 46.526904296874996],
              [29.18623046875001, 46.523974609374996],
              [29.20078125, 46.50498046875],
              [29.20458984375, 46.379345703125],
              [29.22382812500001, 46.376953125],
              [29.25458984375001, 46.392626953124996],
              [29.30488281250001, 46.466601562499996],
              [29.33955078125001, 46.445068359375],
              [29.392871093750017, 46.436914062499994],
              [29.43281250000001, 46.455957031249994],
              [29.458789062500017, 46.453759765624994],
              [29.491015625000017, 46.43466796875],
              [29.555078125000023, 46.407763671874996],
              [29.61494140625001, 46.398828125],
              [29.66455078125, 46.416748046875],
              [29.706835937500017, 46.44873046875],
              [29.751953125, 46.437792968749996],
              [29.837890625, 46.350537109375],
              [29.878027343750006, 46.360205078125],
              [30.07568359375, 46.377832031249994],
              [30.107519531250006, 46.4015625],
              [30.13105468750001, 46.423095703125],
              [29.92431640625, 46.538867187499996],
              [29.93476562500001, 46.625],
              [29.942480468750006, 46.723779296874994],
              [29.918066406250006, 46.782421875],
              [29.877832031250023, 46.82890625],
              [29.7197265625, 46.88291015625],
              [29.597753906250006, 46.938818359375],
              [29.57197265625001, 46.964013671874994],
              [29.568652343750017, 46.996728515624994],
              [29.5634765625, 47.047509765624994],
              [29.515039062500023, 47.091113281249996],
              [29.51093750000001, 47.12802734375],
              [29.541796875000017, 47.185546875],
              [29.54931640625, 47.246826171875],
              [29.539160156250006, 47.27099609375],
              [29.510644531250023, 47.29072265625],
              [29.455664062500006, 47.292626953124994],
              [29.383398437500006, 47.328027343749994],
              [29.333789062500017, 47.375732421875],
              [29.200585937500023, 47.444482421874994],
              [29.159765625, 47.4556640625],
              [29.134863281250006, 47.489697265625],
              [29.122949218750023, 47.53037109375],
              [29.15087890625, 47.580859375],
              [29.18603515625, 47.65859375],
              [29.210742187500017, 47.73154296875],
              [29.21113281250001, 47.775],
              [29.19482421875, 47.882421875],
              [29.125390625000023, 47.96455078125],
              [29.09296875000001, 47.975439453125],
              [29.036914062500017, 47.95234375],
              [28.97333984375001, 47.933007812499994],
              [28.923144531250017, 47.951123046875],
              [28.865820312500006, 47.995654296874996],
              [28.773828125000023, 48.119580078125],
              [28.601660156250006, 48.144384765625],
              [28.53046875000001, 48.150292968749994],
              [28.46308593750001, 48.09052734375],
              [28.441992187500006, 48.108691406249996],
              [28.42304687500001, 48.146875],
              [28.3875, 48.162109375],
              [28.34052734375001, 48.144433593749994],
              [28.326953125000017, 48.161425781249996],
              [28.34716796875, 48.213037109374994],
              [28.291015625, 48.238574218749996],
              [28.158789062500006, 48.23798828125],
              [28.088476562500006, 48.25703125],
              [28.080078125, 48.295800781249994],
              [28.038476562500023, 48.3212890625],
              [27.96337890625, 48.333544921874996],
              [27.890625, 48.365234375],
              [27.82001953125001, 48.416259765625],
              [27.714453125, 48.449511718749996],
              [27.57373046875, 48.464892578124996],
              [27.56220703125001, 48.47041015625],
              [27.549218750000023, 48.477734375],
              [27.458398437500023, 48.44306640625],
              [27.40380859375, 48.415625],
              [27.3369140625, 48.43271484375],
              [27.228515625, 48.371435546875],
              [27.00849609375001, 48.36826171875],
              [26.90058593750001, 48.371923828125],
              [26.847070312500023, 48.387158203125],
              [26.640429687500017, 48.294140625],
              [26.618945312500017, 48.25986328125],
              [26.57246093750001, 48.248486328125],
              [26.4423828125, 48.22998046875],
              [26.3056640625, 48.203759765624994],
              [26.276953125, 48.113232421875],
              [26.236230468750023, 48.064355468749994],
              [26.162695312500006, 47.992529296875],
              [25.90869140625, 47.967578125],
              [25.689257812500017, 47.932470703125],
              [25.464257812500023, 47.910791015624994],
              [25.16962890625001, 47.823095703125],
              [25.073828125, 47.745703125000176],
              [24.979101562500006, 47.72412109375],
              [24.893359375000017, 47.7177734375],
              [24.837890625, 47.76083984375],
              [24.650976562500006, 47.876513671874996],
              [24.578906250000017, 47.931054687499994],
              [24.48408203125001, 47.947119140625],
              [24.380957031250006, 47.938037109374996],
              [24.28193359375001, 47.911181640624996],
              [24.177734375, 47.906054687499996],
              [24.05976562500001, 47.944775390625],
              [24.04736328125, 47.941015625],
              [24.001855468750023, 47.935791015625],
              [23.708984375, 47.9826171875],
              [23.682031250000023, 47.990380859374994],
              [23.669042968750006, 47.992333984374994],
              [23.628710937500017, 47.995849609375],
              [23.408203125, 47.989990234375],
              [23.20263671875, 48.084521484374996],
              [23.139453125000017, 48.08740234375],
              [23.0908203125, 48.049121093749996],
              [23.054785156250006, 48.00654296875],
              [22.912890625000017, 47.964257812499994],
              [22.87666015625001, 47.947265625],
              [22.856054687500006, 47.960302734375],
              [22.846484375000017, 47.9970703125],
              [22.857226562500017, 48.029541015625],
              [22.836230468750017, 48.060302734375],
              [22.7822265625, 48.09521484375],
              [22.769140625, 48.109619140625],
              [22.701562500000023, 48.10703125],
              [22.68310546875, 48.10361328125],
              [22.676367187500006, 48.104394531249994],
              [22.582421875000023, 48.134033203125],
              [22.520117187500006, 48.205371093749996],
              [22.423828125, 48.243310546874994],
              [22.350195312500006, 48.2560546875],
              [22.316699218750017, 48.28662109375],
              [22.29511718750001, 48.327294921874994],
              [22.27216796875001, 48.3580078125],
              [22.269433593750023, 48.360888671874996],
              [22.253710937500017, 48.407373046874994],
              [22.23115234375001, 48.412158203124996],
              [22.227148437500006, 48.413427734375],
              [22.1318359375, 48.405322265624996],
              [22.142871093750017, 48.568505859374994],
              [22.295214843750017, 48.685839843749996],
              [22.332617187500006, 48.745068359375],
              [22.389453125000017, 48.873486328125],
              [22.432031250000023, 48.933544921875],
              [22.483203125000017, 48.983251953125],
              [22.52412109375001, 49.031396484374994],
              [22.538671875, 49.072705078125],
              [22.579980468750023, 49.077197265624996],
              [22.701269531250006, 49.039941406249994],
              [22.80976562500001, 49.020751953125],
              [22.839746093750023, 49.038916015625],
              [22.85205078125, 49.062744140625],
              [22.847070312500023, 49.08125],
              [22.760156250000023, 49.13623046875],
              [22.705664062500006, 49.171191406249996],
              [22.70234375000001, 49.192724609375],
              [22.721972656250017, 49.240966796875],
              [22.732421875, 49.295166015625],
              [22.71992187500001, 49.353808593749996],
              [22.66064453125, 49.483691406249996],
              [22.6494140625, 49.539013671875],
              [22.706152343750006, 49.606201171875],
              [22.890722656250006, 49.766259765624994],
              [22.952246093750006, 49.8263671875],
              [23.03632812500001, 49.899072265624994],
              [23.264453125000017, 50.0728515625],
              [23.408593750000023, 50.17392578125],
              [23.506152343750017, 50.229833984375],
              [23.649023437500006, 50.327050781249994],
              [23.711718750000017, 50.37734375],
              [23.97265625, 50.410058593749994],
              [24.004980468750006, 50.45703125],
              [24.052636718750023, 50.508447265624994],
              [24.089941406250006, 50.53046875],
              [24.0947265625, 50.617041015625],
              [24.046289062500023, 50.722802734374994],
              [24.00732421875, 50.76015625],
              [23.978417968750023, 50.785595703125],
              [23.9970703125, 50.809375],
              [24.025976562500006, 50.816162109375],
              [24.061621093750006, 50.81953125],
              [24.105761718750017, 50.844970703125],
              [24.095800781250006, 50.87275390625],
              [23.985742187500023, 50.9404296875],
              [23.938085937500006, 50.992529296875],
              [23.86347656250001, 51.126220703125],
              [23.712207031250017, 51.26513671875],
              [23.664453125000023, 51.31005859375],
              [23.657617187500023, 51.352490234375],
              [23.6796875, 51.394921875],
              [23.65888671875001, 51.448974609375],
              [23.605273437500017, 51.517919921875],
              [23.61376953125, 51.525390625],
              [23.60859375000001, 51.610498046875],
              [23.64667968750001, 51.628857421875],
              [23.706835937500017, 51.64130859375],
              [23.79169921875001, 51.637109375],
              [23.8642578125, 51.623974609375],
              [23.951171875, 51.58505859375],
              [23.978320312500017, 51.59130859375],
              [24.126855468750023, 51.6646484375],
              [24.280078125000017, 51.774707031249996],
              [24.32373046875, 51.838427734374996],
              [24.361914062500006, 51.867529296875],
              [24.495214843750006, 51.883056640625],
              [24.611328125, 51.889501953125],
              [24.68515625, 51.88828125],
              [24.86640625000001, 51.89912109375],
              [24.97382812500001, 51.9111328125],
              [25.066699218750017, 51.930517578125],
              [25.2671875, 51.937744140625],
              [25.58027343750001, 51.924755859375],
              [25.785742187500006, 51.923828125],
              [25.92529296875, 51.913525390625],
              [26.26708984375, 51.855029296874996],
              [26.394335937500017, 51.84443359375],
              [26.453417968750017, 51.813427734375],
              [26.56689453125, 51.801904296875],
              [26.7734375, 51.770703125],
              [26.95283203125001, 51.75400390625],
              [27.074121093750023, 51.76083984375],
              [27.141992187500023, 51.75205078125],
              [27.270117187500006, 51.613574218749996],
              [27.296289062500023, 51.597412109375],
              [27.34765625, 51.594140625],
              [27.45234375000001, 51.606103515625],
              [27.601367187500017, 51.601611328124996],
              [27.689746093750017, 51.572412109374994],
              [27.6767578125, 51.48994140625],
              [27.7, 51.477978515625],
              [27.741308593750006, 51.482568359374994],
              [27.788867187500017, 51.529150390625],
              [27.82880859375001, 51.577441406249996],
              [27.85859375000001, 51.5923828125],
              [28.0107421875, 51.559765625],
              [28.08027343750001, 51.5650390625],
              [28.144433593750023, 51.60166015625],
              [28.18378906250001, 51.607861328125],
              [28.291601562500006, 51.581835937499996],
              [28.424609375000017, 51.563623046874994],
              [28.532031250000017, 51.562451171875],
              [28.599023437500023, 51.542626953124994],
              [28.647753906250017, 51.45654296875],
              [28.690234375000017, 51.438867187499994],
              [28.73125, 51.433398437499996],
              [28.793261718750017, 51.5103515625],
              [28.849511718750023, 51.540185546874994],
              [28.927539062500017, 51.562158203124994],
              [28.97773437500001, 51.57177734375],
              [29.013085937500023, 51.598925781249996],
              [29.06074218750001, 51.625439453125],
              [29.10205078125, 51.6275390625],
              [29.135644531250023, 51.61728515625],
              [29.174218750000023, 51.580615234374996],
              [29.23046875, 51.497021484375],
              [29.298828125, 51.413037109375],
              [29.346484375000017, 51.382568359375],
              [29.469628906250023, 51.408349609374994],
              [29.553125, 51.4345703125],
              [29.7060546875, 51.43955078125],
              [29.908789062500006, 51.4580078125],
              [30.063769531250017, 51.48203125],
              [30.160742187500006, 51.477880859375],
              [30.219531250000017, 51.451220703124996],
              [30.308984375000023, 51.399609375],
              [30.333398437500023, 51.325537109375],
              [30.449511718750017, 51.274316406249994],
              [30.54453125, 51.265039062499994],
              [30.576953125000017, 51.318359375],
              [30.63251953125001, 51.355419921875],
              [30.611718750000023, 51.40634765625],
              [30.602343750000017, 51.471240234374996],
              [30.56074218750001, 51.531494140625],
              [30.533007812500017, 51.596337890624994],
              [30.583886718750023, 51.68896484375],
              [30.639453125000017, 51.770068359374996],
              [30.667285156250017, 51.814111328125],
              [30.755273437500023, 51.895166015625],
              [30.845703125, 51.953076171875],
              [30.98066406250001, 52.046191406249996],
              [31.07929687500001, 52.076953125],
              [31.16845703125, 52.062939453125],
              [31.21796875000001, 52.050244140625],
              [31.345996093750017, 52.10537109375],
              [31.57373046875, 52.10810546875],
              [31.76337890625001, 52.10107421875],
              [31.78242187500001, 52.099414062499996],
              [31.875585937500006, 52.0708984375],
              [31.97382812500001, 52.046630859375],
              [32.041601562500006, 52.04501953125],
              [32.12226562500001, 52.050585937499996],
              [32.216796875, 52.082958984375],
              [32.2828125, 52.114013671875],
              [32.36298828125001, 52.272119140625],
              [32.39130859375001, 52.29482421875],
              [32.43544921875002, 52.3072265625],
              [32.507910156250006, 52.308544921875],
              [32.64541015625002, 52.279101562499996],
              [32.80644531250002, 52.25263671875],
              [32.89970703125002, 52.25634765625],
              [33.1484375, 52.3404296875],
              [33.287109375, 52.353564453124996],
              [33.45185546875001, 52.333789062499996],
              [33.613378906250006, 52.3326171875],
              [33.73525390625002, 52.344775390624996],
              [33.81884765625, 52.315625],
              [33.92207031250001, 52.25146484375],
              [34.01533203125001, 52.15595703125],
              [34.11308593750002, 51.979638671875],
              [34.39785156250002, 51.780419921875],
              [34.40273437500002, 51.741503906249996],
              [34.37929687500002, 51.71650390625],
              [34.23916015625002, 51.692236328125],
              [34.12109375, 51.679150390625],
              [34.11542968750001, 51.644970703125],
              [34.14677734375002, 51.607958984374996],
              [34.20087890625001, 51.55380859375],
              [34.20927734375002, 51.48408203125],
              [34.20654296875, 51.419921875],
              [34.22988281250002, 51.363232421875],
              [34.275, 51.340185546875],
              [34.28066406250002, 51.311669921874994],
              [34.22841796875002, 51.27685546875],
              [34.2138671875, 51.25537109375],
              [34.23417968750002, 51.243798828124994],
              [34.49101562500002, 51.237060546875],
              [34.616796875, 51.203125],
              [34.71230468750002, 51.172216796875],
              [34.760351562500006, 51.169335937499994],
              [34.86855468750002, 51.189208984375],
              [34.990234375, 51.2017578125],
              [35.0640625, 51.203417968749996],
              [35.09257812500002, 51.1806640625],
              [35.115332031250006, 51.120849609375],
              [35.15810546875002, 51.060986328125],
              [35.19804687500002, 51.043896484375],
              [35.269140625, 51.046777343749994],
              [35.31191406250002, 51.043896484375],
              [35.33476562500002, 51.021142578124994],
              [35.30908203125, 50.9869140625],
              [35.31474609375002, 50.94990234375],
              [35.34609375000002, 50.904296875],
              [35.38320312500002, 50.79892578125],
              [35.41738281250002, 50.767578125],
              [35.44013671875001, 50.727685546874994],
              [35.44013671875001, 50.682080078125],
              [35.41162109375, 50.642236328124994],
              [35.391699218750006, 50.6109375],
              [35.41162109375, 50.539697265624994],
              [35.48847656250001, 50.459912109375],
              [35.545507812500006, 50.439990234374996],
              [35.59111328125002, 50.36875],
              [35.67373046875002, 50.345996093749996],
              [35.79619140625002, 50.40576171875],
              [35.890234375, 50.437109375],
              [36.0078125, 50.419677734375],
              [36.11640625000001, 50.408544921875],
              [36.189453125, 50.367822265624994],
              [36.24335937500001, 50.311767578125],
              [36.30605468750002, 50.28046875],
              [36.36884765625001, 50.296826171875],
              [36.49980468750002, 50.28046875],
              [36.559667968750006, 50.23486328125],
              [36.61943359375002, 50.209228515625],
              [36.696386718750006, 50.246240234374994],
              [36.75908203125002, 50.291845703125],
              [36.98847656250001, 50.33955078125],
              [37.13125, 50.351513671875],
              [37.17109375000001, 50.360888671874996],
              [37.2548828125, 50.394970703125],
              [37.34316406250002, 50.417626953124994],
              [37.4228515625, 50.411474609375],
              [37.50136718750002, 50.340722656249994],
              [37.58232421875002, 50.291845703125],
              [37.605078125, 50.21494140625],
              [37.704199218750006, 50.10908203125],
              [37.950292968750006, 49.964208984375],
              [38.046875, 49.92001953125],
              [38.1125, 49.92783203125],
              [38.14677734375002, 49.939404296875],
              [38.162695312500006, 49.954541015625],
              [38.17753906250002, 50.025390625],
              [38.20869140625001, 50.05146484375],
              [38.25859375000002, 50.05234375],
              [38.451171875, 49.9640625],
              [38.55195312500001, 49.95458984375],
              [38.64775390625002, 49.952880859375],
              [38.77666015625002, 49.884326171874996],
              [38.91835937500002, 49.82470703125],
              [39.02773437500002, 49.818408203124996],
              [39.11494140625001, 49.841748046875],
              [39.1748046875, 49.85595703125],
              [39.21181640625002, 49.833203125],
              [39.24599609375002, 49.78193359375],
              [39.30292968750001, 49.742041015625],
              [39.36845703125002, 49.7306640625],
              [39.46279296875002, 49.72802734375],
              [39.6265625, 49.650683593749996],
              [39.78056640625002, 49.572021484375],
              [39.87685546875002, 49.567675781249996],
              [39.95849609375, 49.590771484375],
              [40.03066406250002, 49.596728515624996],
              [40.080664062500006, 49.57685546875],
              [40.09492187500001, 49.54267578125],
              [40.05781250000001, 49.4970703125],
              [40.05781250000001, 49.431542968749994],
              [40.12617187500001, 49.36884765625],
              [40.12832031250002, 49.3072265625],
              [40.10878906250002, 49.2515625],
              [40.07001953125001, 49.20029296875],
              [39.97636718750002, 49.129833984375],
              [39.889746093750006, 49.0640625],
              [39.75947265625001, 49.036572265625],
              [39.6865234375, 49.00791015625],
              [39.705664062500006, 48.9595703125],
              [39.75332031250002, 48.914453125],
              [39.86376953125, 48.877978515624996],
              [39.98916015625002, 48.851416015625],
              [40.00361328125001, 48.822070312499996],
              [39.984472656250006, 48.807373046875],
              [39.90410156250002, 48.79375],
              [39.79287109375002, 48.80771484375],
              [39.755859375, 48.782080078125],
              [39.70458984375, 48.73935546875],
              [39.67041015625, 48.662451171875],
              [39.64472656250001, 48.591210937499994],
              [39.76542968750002, 48.571875],
              [39.83564453125001, 48.542773437499996],
              [39.857519531250006, 48.484228515625],
              [39.88261718750002, 48.419091796874994],
              [39.88984375000001, 48.36044921875],
              [39.84990234375002, 48.331933593749994],
              [39.84746093750002, 48.302783203124996],
              [39.866308593750006, 48.288427734375],
              [39.91816406250001, 48.28193359375],
              [39.95791015625002, 48.268896484375],
              [39.961035156250006, 48.237939453124994],
              [39.88505859375002, 48.168359375],
              [39.81396484375, 48.035302734374994],
              [39.77578125000002, 47.964453125],
              [39.77871093750002, 47.887548828125],
              [39.7359375, 47.84482421875],
              [39.65849609375002, 47.841210937499994],
              [39.39101562500002, 47.833740234375],
              [39.15849609375002, 47.83740234375],
              [39.05781250000001, 47.848486328125],
              [38.90029296875002, 47.855126953124994],
              [38.822265625, 47.83701171875],
              [38.71894531250001, 47.714111328125],
              [38.640625, 47.665917968749994],
              [38.51093750000001, 47.622412109375],
              [38.36884765625001, 47.6099609375],
              [38.28740234375002, 47.5591796875],
              [38.2587890625, 47.479541015624996],
              [38.25654296875001, 47.408935546875],
              [38.243261718750006, 47.373681640624994],
              [38.21240234375, 47.3427734375],
              [38.20136718750001, 47.32080078125],
              [38.2080078125, 47.296533203124994],
              [38.24101562500002, 47.2876953125],
              [38.28076171875, 47.276660156249996],
              [38.28076171875, 47.259033203125],
              [38.26533203125001, 47.236962890624994],
              [38.22119140625, 47.212744140625],
              [38.20136718750001, 47.175244140625],
              [38.20585937500002, 47.135595703125],
              [38.21435546875, 47.091455078124994],
            ],
          ],
          [
            [
              [32.01220703125, 46.20390625],
              [32.15009765625001, 46.1546875],
              [32.009375, 46.167822265625],
              [31.7001953125, 46.2140625],
              [31.563867187500023, 46.257763671875],
              [31.528710937500023, 46.306591796875],
              [31.5087890625, 46.37314453125],
              [31.584863281250023, 46.303173828125],
              [31.638476562500017, 46.27255859375],
              [32.01220703125, 46.20390625],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 4,
        sovereignt: "Belarus",
        sov_a3: "BLR",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Belarus",
        adm0_a3: "BLR",
        geou_dif: 0,
        geounit: "Belarus",
        gu_a3: "BLR",
        su_dif: 0,
        subunit: "Belarus",
        su_a3: "BLR",
        brk_diff: 0,
        name: "Belarus",
        name_long: "Belarus",
        brk_a3: "BLR",
        brk_name: "Belarus",
        brk_group: null,
        abbrev: "Bela.",
        postal: "BY",
        formal_en: "Republic of Belarus",
        formal_fr: null,
        name_ciawf: "Belarus",
        note_adm0: null,
        note_brk: null,
        name_sort: "Belarus",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 1,
        mapcolor9: 5,
        mapcolor13: 11,
        pop_est: 9466856,
        pop_rank: 13,
        pop_year: 2019,
        gdp_md: 63080,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        fips_10: "BO",
        iso_a2: "BY",
        iso_a2_eh: "BY",
        iso_a3: "BLR",
        iso_a3_eh: "BLR",
        iso_n3: "112",
        iso_n3_eh: "112",
        un_a3: "112",
        wb_a2: "BY",
        wb_a3: "BLR",
        woe_id: 23424765,
        woe_id_eh: 23424765,
        woe_note: "Exact WOE match as country",
        adm0_iso: "BLR",
        adm0_diff: null,
        adm0_tlc: "BLR",
        adm0_a3_us: "BLR",
        adm0_a3_fr: "BLR",
        adm0_a3_ru: "BLR",
        adm0_a3_es: "BLR",
        adm0_a3_cn: "BLR",
        adm0_a3_tw: "BLR",
        adm0_a3_in: "BLR",
        adm0_a3_np: "BLR",
        adm0_a3_pk: "BLR",
        adm0_a3_de: "BLR",
        adm0_a3_gb: "BLR",
        adm0_a3_br: "BLR",
        adm0_a3_il: "BLR",
        adm0_a3_ps: "BLR",
        adm0_a3_sa: "BLR",
        adm0_a3_eg: "BLR",
        adm0_a3_ma: "BLR",
        adm0_a3_pt: "BLR",
        adm0_a3_ar: "BLR",
        adm0_a3_jp: "BLR",
        adm0_a3_ko: "BLR",
        adm0_a3_vn: "BLR",
        adm0_a3_tr: "BLR",
        adm0_a3_id: "BLR",
        adm0_a3_pl: "BLR",
        adm0_a3_gr: "BLR",
        adm0_a3_it: "BLR",
        adm0_a3_nl: "BLR",
        adm0_a3_se: "BLR",
        adm0_a3_bd: "BLR",
        adm0_a3_ua: "BLR",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Eastern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 7,
        long_len: 7,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: 28.417701,
        label_y: 53.821888,
        ne_id: 1159320427,
        wikidataid: "Q184",
        name_ar: "بيلاروسيا",
        name_bn: "বেলারুশ",
        name_de: "Belarus",
        name_en: "Belarus",
        name_es: "Bielorrusia",
        name_fa: "بلاروس",
        name_fr: "Biélorussie",
        name_el: "Λευκορωσία",
        name_he: "בלארוס",
        name_hi: "बेलारूस",
        name_hu: "Fehéroroszország",
        name_id: "Belarus",
        name_it: "Bielorussia",
        name_ja: "ベラルーシ",
        name_ko: "벨라루스",
        name_nl: "Wit-Rusland",
        name_pl: "Białoruś",
        name_pt: "Bielorrússia",
        name_ru: "Белоруссия",
        name_sv: "Belarus",
        name_tr: "Beyaz Rusya",
        name_uk: "Білорусь",
        name_ur: "بیلاروس",
        name_vi: "Belarus",
        name_zh: "白俄罗斯",
        name_zht: "白俄羅斯",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "BLR.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [31.76337890625001, 52.10107421875],
            [31.57373046875, 52.10810546875],
            [31.345996093750017, 52.10537109375],
            [31.21796875000001, 52.050244140625],
            [31.16845703125, 52.062939453125],
            [31.07929687500001, 52.076953125],
            [30.98066406250001, 52.046191406249996],
            [30.845703125, 51.953076171875],
            [30.755273437500023, 51.895166015625],
            [30.667285156250017, 51.814111328125],
            [30.639453125000017, 51.770068359374996],
            [30.583886718750023, 51.68896484375],
            [30.533007812500017, 51.596337890624994],
            [30.56074218750001, 51.531494140625],
            [30.602343750000017, 51.471240234374996],
            [30.611718750000023, 51.40634765625],
            [30.63251953125001, 51.355419921875],
            [30.576953125000017, 51.318359375],
            [30.54453125, 51.265039062499994],
            [30.449511718750017, 51.274316406249994],
            [30.333398437500023, 51.325537109375],
            [30.308984375000023, 51.399609375],
            [30.219531250000017, 51.451220703124996],
            [30.160742187500006, 51.477880859375],
            [30.063769531250017, 51.48203125],
            [29.908789062500006, 51.4580078125],
            [29.7060546875, 51.43955078125],
            [29.553125, 51.4345703125],
            [29.469628906250023, 51.408349609374994],
            [29.346484375000017, 51.382568359375],
            [29.298828125, 51.413037109375],
            [29.23046875, 51.497021484375],
            [29.174218750000023, 51.580615234374996],
            [29.135644531250023, 51.61728515625],
            [29.10205078125, 51.6275390625],
            [29.06074218750001, 51.625439453125],
            [29.013085937500023, 51.598925781249996],
            [28.97773437500001, 51.57177734375],
            [28.927539062500017, 51.562158203124994],
            [28.849511718750023, 51.540185546874994],
            [28.793261718750017, 51.5103515625],
            [28.73125, 51.433398437499996],
            [28.690234375000017, 51.438867187499994],
            [28.647753906250017, 51.45654296875],
            [28.599023437500023, 51.542626953124994],
            [28.532031250000017, 51.562451171875],
            [28.424609375000017, 51.563623046874994],
            [28.291601562500006, 51.581835937499996],
            [28.18378906250001, 51.607861328125],
            [28.144433593750023, 51.60166015625],
            [28.08027343750001, 51.5650390625],
            [28.0107421875, 51.559765625],
            [27.85859375000001, 51.5923828125],
            [27.82880859375001, 51.577441406249996],
            [27.788867187500017, 51.529150390625],
            [27.741308593750006, 51.482568359374994],
            [27.7, 51.477978515625],
            [27.6767578125, 51.48994140625],
            [27.689746093750017, 51.572412109374994],
            [27.601367187500017, 51.601611328124996],
            [27.45234375000001, 51.606103515625],
            [27.34765625, 51.594140625],
            [27.296289062500023, 51.597412109375],
            [27.270117187500006, 51.613574218749996],
            [27.141992187500023, 51.75205078125],
            [27.074121093750023, 51.76083984375],
            [26.95283203125001, 51.75400390625],
            [26.7734375, 51.770703125],
            [26.56689453125, 51.801904296875],
            [26.453417968750017, 51.813427734375],
            [26.394335937500017, 51.84443359375],
            [26.26708984375, 51.855029296874996],
            [25.92529296875, 51.913525390625],
            [25.785742187500006, 51.923828125],
            [25.58027343750001, 51.924755859375],
            [25.2671875, 51.937744140625],
            [25.066699218750017, 51.930517578125],
            [24.97382812500001, 51.9111328125],
            [24.86640625000001, 51.89912109375],
            [24.68515625, 51.88828125],
            [24.611328125, 51.889501953125],
            [24.495214843750006, 51.883056640625],
            [24.361914062500006, 51.867529296875],
            [24.32373046875, 51.838427734374996],
            [24.280078125000017, 51.774707031249996],
            [24.126855468750023, 51.6646484375],
            [23.978320312500017, 51.59130859375],
            [23.951171875, 51.58505859375],
            [23.8642578125, 51.623974609375],
            [23.79169921875001, 51.637109375],
            [23.706835937500017, 51.64130859375],
            [23.64667968750001, 51.628857421875],
            [23.60859375000001, 51.610498046875],
            [23.61376953125, 51.525390625],
            [23.605273437500017, 51.517919921875],
            [23.539648437500006, 51.618896484375],
            [23.544824218750023, 51.710253906249996],
            [23.581347656250017, 51.76240234375],
            [23.62568359375001, 51.809326171875],
            [23.607421875, 51.87978515625],
            [23.65107421875001, 51.972998046875],
            [23.652441406250006, 52.040380859375],
            [23.63330078125, 52.069580078125],
            [23.597949218750017, 52.103076171874996],
            [23.50117187500001, 52.140380859375],
            [23.458398437500023, 52.16953125],
            [23.3271484375, 52.208447265625],
            [23.19697265625001, 52.25693359375],
            [23.175097656250017, 52.28662109375],
            [23.18125, 52.306982421875],
            [23.2041015625, 52.337890625],
            [23.303320312500006, 52.428369140625],
            [23.410937500000017, 52.5162109375],
            [23.479589843750006, 52.5515625],
            [23.8447265625, 52.664208984375],
            [23.901269531250023, 52.70361328125],
            [23.915429687500023, 52.770263671875],
            [23.916308593750017, 52.81875],
            [23.909375, 52.9048828125],
            [23.887109375000023, 53.0275390625],
            [23.859179687500017, 53.112109375],
            [23.78925781250001, 53.270947265625],
            [23.598925781250017, 53.59921875],
            [23.484667968750017, 53.939794921875],
            [23.55908203125, 53.91982421875],
            [23.733691406250017, 53.912255859375],
            [23.87255859375, 53.935693359375],
            [23.944433593750006, 53.93896484375],
            [24.00849609375001, 53.931640625],
            [24.103906250000023, 53.94501953125],
            [24.191308593750023, 53.950439453125],
            [24.236621093750017, 53.919970703124996],
            [24.31796875, 53.89296875],
            [24.478515625, 53.9318359375],
            [24.620703125, 53.979833984375],
            [24.768164062500006, 53.974658203124996],
            [24.78925781250001, 53.9982421875],
            [24.82568359375, 54.118994140625],
            [24.869531250000023, 54.145166015625],
            [25.04609375000001, 54.133056640625],
            [25.111425781250006, 54.154931640625],
            [25.179492187500017, 54.2142578125],
            [25.28369140625, 54.251269531249996],
            [25.37060546875, 54.251220703125],
            [25.46113281250001, 54.292773437499996],
            [25.505664062500017, 54.264941406249996],
            [25.52734375, 54.215136718749996],
            [25.497363281250017, 54.175244140625],
            [25.510351562500006, 54.159619140625],
            [25.573046875000017, 54.139892578125],
            [25.680566406250023, 54.140478515625],
            [25.74921875000001, 54.156982421875],
            [25.765234375, 54.17978515625],
            [25.765039062500023, 54.22119140625],
            [25.748144531250006, 54.25966796875],
            [25.702539062500023, 54.29296875],
            [25.61689453125001, 54.310107421874996],
            [25.557519531250023, 54.310693359375],
            [25.54736328125, 54.331835937499996],
            [25.56757812500001, 54.37705078125],
            [25.62031250000001, 54.460400390625],
            [25.68515625, 54.535791015625],
            [25.72480468750001, 54.564257812499996],
            [25.73164062500001, 54.590380859374996],
            [25.723925781250017, 54.636035156249996],
            [25.722460937500017, 54.71787109375],
            [25.780859375, 54.833251953125],
            [25.859277343750023, 54.919287109375],
            [25.964453125, 54.94716796875],
            [26.09296875000001, 54.9623046875],
            [26.175195312500023, 55.003271484375],
            [26.2158203125, 55.050390625],
            [26.23125, 55.090136718749996],
            [26.250781250000017, 55.12451171875],
            [26.291796875000017, 55.139599609375],
            [26.601171875, 55.130175781249996],
            [26.6484375, 55.20419921875],
            [26.675, 55.224902343749996],
            [26.734375, 55.24677734375],
            [26.775683593750017, 55.273095703125],
            [26.760156250000023, 55.293359375],
            [26.68125, 55.306445312499996],
            [26.49531250000001, 55.318017578125],
            [26.457617187500006, 55.34248046875],
            [26.469531250000017, 55.371923828125],
            [26.51923828125001, 55.44814453125],
            [26.56660156250001, 55.546484375],
            [26.5908203125, 55.62265625],
            [26.593554687500017, 55.667529296874996],
            [26.620214843750006, 55.679638671875],
            [26.771875, 55.693994140625],
            [26.82246093750001, 55.709228515625],
            [26.953027343750023, 55.812939453125],
            [27.052539062500017, 55.83056640625],
            [27.309179687500006, 55.80390625],
            [27.427148437500023, 55.805957031249996],
            [27.45917968750001, 55.803515625],
            [27.576757812500006, 55.798779296875],
            [27.589453125, 55.8091796875],
            [27.64228515625001, 55.91171875],
            [27.694238281250023, 55.941552734375],
            [27.896289062500017, 56.076171875],
            [28.032031250000017, 56.13330078125],
            [28.11787109375001, 56.145800781249996],
            [28.14794921875, 56.142919921875],
            [28.284277343750006, 56.055908203125],
            [28.316308593750023, 56.052539062499996],
            [28.39208984375, 56.08671875],
            [28.407031250000017, 56.089013671875],
            [28.56396484375, 56.0919921875],
            [28.63691406250001, 56.061767578125],
            [28.690820312500023, 56.00263671875],
            [28.740820312500006, 55.955371093749996],
            [28.794726562500017, 55.942578125],
            [28.94746093750001, 56.002099609375],
            [29.03173828125, 56.021777343749996],
            [29.08740234375, 56.021142578125],
            [29.283007812500017, 55.96787109375],
            [29.375, 55.938720703125],
            [29.39609375, 55.91220703125],
            [29.39794921875, 55.8810546875],
            [29.373144531250006, 55.834716796875],
            [29.353417968750023, 55.784375],
            [29.412988281250023, 55.724853515625],
            [29.482226562500017, 55.6845703125],
            [29.63007812500001, 55.751171875],
            [29.6845703125, 55.7697265625],
            [29.744140625, 55.77041015625],
            [29.82392578125001, 55.7951171875],
            [29.881640625000017, 55.832324218749996],
            [29.93701171875, 55.845263671874996],
            [30.04267578125001, 55.83642578125],
            [30.23359375000001, 55.84521484375],
            [30.45625, 55.78681640625],
            [30.475390625000017, 55.768798828125],
            [30.586718750000017, 55.70029296875],
            [30.625585937500006, 55.666259765625],
            [30.66230468750001, 55.65546875],
            [30.7216796875, 55.622119140624996],
            [30.80078125, 55.601123046874996],
            [30.85595703125, 55.60751953125],
            [30.882226562500023, 55.59638671875],
            [30.906835937500006, 55.57001953125],
            [30.908789062500006, 55.525341796875],
            [30.90058593750001, 55.397412109375],
            [30.86181640625, 55.360400390624996],
            [30.82099609375001, 55.3302734375],
            [30.810546875, 55.306982421875],
            [30.814453125, 55.2787109375],
            [30.87744140625, 55.2234375],
            [30.958886718750023, 55.13759765625],
            [30.97773437500001, 55.08779296875],
            [30.97773437500001, 55.05048828125],
            [30.866796875, 54.940722656249996],
            [30.829882812500017, 54.914990234375],
            [30.804492187500017, 54.8609375],
            [30.791015625, 54.806005859375],
            [30.798828125, 54.783251953124996],
            [30.984179687500017, 54.6958984375],
            [31.12128906250001, 54.648486328124996],
            [31.152148437500017, 54.625341796875],
            [31.154882812500006, 54.6109375],
            [31.081933593750023, 54.51708984375],
            [31.074804687500006, 54.491796875],
            [31.18476562500001, 54.452978515625],
            [31.245507812500023, 54.391650390624996],
            [31.299121093750017, 54.29169921875],
            [31.403613281250017, 54.195947265625],
            [31.62841796875, 54.111181640625],
            [31.7919921875, 54.055908203125],
            [31.825976562500017, 54.030712890625],
            [31.837792968750023, 54.00078125],
            [31.825292968750006, 53.935009765625],
            [31.783007812500017, 53.85498046875],
            [31.754199218750017, 53.81044921875],
            [31.82080078125, 53.791943359375],
            [31.9921875, 53.796875],
            [32.20039062500001, 53.78125],
            [32.4501953125, 53.692919921874996],
            [32.45097656250002, 53.6533203125],
            [32.42519531250002, 53.61728515625],
            [32.4423828125, 53.579248046875],
            [32.46962890625002, 53.54697265625],
            [32.68574218750001, 53.44814453125],
            [32.70644531250002, 53.41943359375],
            [32.71025390625002, 53.371435546875],
            [32.70429687500001, 53.336328125],
            [32.64443359375002, 53.32890625],
            [32.57802734375002, 53.31240234375],
            [32.469335937500006, 53.2703125],
            [32.42626953125, 53.210595703125],
            [32.25068359375001, 53.128369140625],
            [32.14199218750002, 53.091162109375],
            [32.05546875000002, 53.089453125],
            [31.849707031250006, 53.106201171875],
            [31.777441406250006, 53.146875],
            [31.747460937500023, 53.1841796875],
            [31.668261718750017, 53.200927734375],
            [31.56298828125, 53.202490234375],
            [31.417871093750023, 53.196044921875],
            [31.38837890625001, 53.184814453125],
            [31.364550781250017, 53.138964843749996],
            [31.30292968750001, 53.060888671875],
            [31.2587890625, 53.01669921875],
            [31.29511718750001, 52.989794921874996],
            [31.35302734375, 52.933447265625],
            [31.442773437500023, 52.86181640625],
            [31.53515625, 52.7982421875],
            [31.564843750000023, 52.759228515625],
            [31.5634765625, 52.7314453125],
            [31.519433593750023, 52.69873046875],
            [31.526171875000017, 52.6330078125],
            [31.61591796875001, 52.546191406249996],
            [31.585546875, 52.532470703125],
            [31.576562500000023, 52.426025390625],
            [31.57734375000001, 52.312304687499996],
            [31.6015625, 52.284814453125],
            [31.64990234375, 52.26220703125],
            [31.690625, 52.220654296875],
            [31.758593750000017, 52.125830078125],
            [31.76337890625001, 52.10107421875],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 2,
        sovereignt: "Germany",
        sov_a3: "DEU",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Germany",
        adm0_a3: "DEU",
        geou_dif: 0,
        geounit: "Germany",
        gu_a3: "DEU",
        su_dif: 0,
        subunit: "Germany",
        su_a3: "DEU",
        brk_diff: 0,
        name: "Germany",
        name_long: "Germany",
        brk_a3: "DEU",
        brk_name: "Germany",
        brk_group: null,
        abbrev: "Ger.",
        postal: "D",
        formal_en: "Federal Republic of Germany",
        formal_fr: null,
        name_ciawf: "Germany",
        note_adm0: null,
        note_brk: null,
        name_sort: "Germany",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 5,
        mapcolor9: 5,
        mapcolor13: 1,
        pop_est: 83132799,
        pop_rank: 16,
        pop_year: 2019,
        gdp_md: 3861123,
        gdp_year: 2019,
        economy: "1. Developed region: G7",
        income_grp: "1. High income: OECD",
        fips_10: "GM",
        iso_a2: "DE",
        iso_a2_eh: "DE",
        iso_a3: "DEU",
        iso_a3_eh: "DEU",
        iso_n3: "276",
        iso_n3_eh: "276",
        un_a3: "276",
        wb_a2: "DE",
        wb_a3: "DEU",
        woe_id: 23424829,
        woe_id_eh: 23424829,
        woe_note: "Exact WOE match as country",
        adm0_iso: "DEU",
        adm0_diff: null,
        adm0_tlc: "DEU",
        adm0_a3_us: "DEU",
        adm0_a3_fr: "DEU",
        adm0_a3_ru: "DEU",
        adm0_a3_es: "DEU",
        adm0_a3_cn: "DEU",
        adm0_a3_tw: "DEU",
        adm0_a3_in: "DEU",
        adm0_a3_np: "DEU",
        adm0_a3_pk: "DEU",
        adm0_a3_de: "DEU",
        adm0_a3_gb: "DEU",
        adm0_a3_br: "DEU",
        adm0_a3_il: "DEU",
        adm0_a3_ps: "DEU",
        adm0_a3_sa: "DEU",
        adm0_a3_eg: "DEU",
        adm0_a3_ma: "DEU",
        adm0_a3_pt: "DEU",
        adm0_a3_ar: "DEU",
        adm0_a3_jp: "DEU",
        adm0_a3_ko: "DEU",
        adm0_a3_vn: "DEU",
        adm0_a3_tr: "DEU",
        adm0_a3_id: "DEU",
        adm0_a3_pl: "DEU",
        adm0_a3_gr: "DEU",
        adm0_a3_it: "DEU",
        adm0_a3_nl: "DEU",
        adm0_a3_se: "DEU",
        adm0_a3_bd: "DEU",
        adm0_a3_ua: "DEU",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Western Europe",
        region_wb: "Europe & Central Asia",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 1.7,
        max_label: 6.7,
        label_x: 9.678348,
        label_y: 50.961733,
        ne_id: 1159320539,
        wikidataid: "Q183",
        name_ar: "ألمانيا",
        name_bn: "জার্মানি",
        name_de: "Deutschland",
        name_en: "Germany",
        name_es: "Alemania",
        name_fa: "آلمان",
        name_fr: "Allemagne",
        name_el: "Γερμανία",
        name_he: "גרמניה",
        name_hi: "जर्मनी",
        name_hu: "Németország",
        name_id: "Jerman",
        name_it: "Germania",
        name_ja: "ドイツ",
        name_ko: "독일",
        name_nl: "Duitsland",
        name_pl: "Niemcy",
        name_pt: "Alemanha",
        name_ru: "Германия",
        name_sv: "Tyskland",
        name_tr: "Almanya",
        name_uk: "Німеччина",
        name_ur: "جرمنی",
        name_vi: "Đức",
        name_zh: "德国",
        name_zht: "德國",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "DEU.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [9.524023437500006, 47.52421875],
              [9.35, 47.598925781249996],
              [9.182812500000011, 47.670703125],
              [9.127539062500006, 47.670703125],
              [8.881152343750017, 47.656396484374994],
              [8.8740234375, 47.6626953125],
              [8.831152343750006, 47.70361328125],
              [8.793066406250006, 47.716552734375],
              [8.770117187500006, 47.709912109375],
              [8.754785156250023, 47.698046875],
              [8.728320312500017, 47.700048828125],
              [8.617871093750011, 47.76611328125],
              [8.572656250000023, 47.775634765625],
              [8.509863281250006, 47.766894531249996],
              [8.435742187500011, 47.731347656249994],
              [8.403417968750006, 47.687792968749996],
              [8.413281250000011, 47.6626953125],
              [8.451757812500006, 47.651806640625],
              [8.55234375, 47.659130859375],
              [8.567089843750011, 47.651904296874996],
              [8.570507812500011, 47.63779296875],
              [8.559472656250023, 47.6240234375],
              [8.477636718750006, 47.612695312499994],
              [8.454003906250023, 47.59619140625],
              [8.430078125000023, 47.592138671875],
              [8.414746093750011, 47.589599609375],
              [8.327832031250011, 47.60693359375],
              [8.1982421875, 47.60693359375],
              [8.09375, 47.576171875],
              [7.927050781250017, 47.563867187499994],
              [7.698046875000017, 47.569873046874996],
              [7.615625, 47.592724609375],
              [7.5654296875, 47.60654296875],
              [7.529394531250006, 47.673876953124996],
              [7.53857421875, 47.7736328125],
              [7.59326171875, 47.905664062499994],
              [7.608496093750006, 48.002587890624994],
              [7.584179687500011, 48.064306640625],
              [7.616601562500023, 48.156787109374996],
              [7.705664062500006, 48.280029296875],
              [7.76513671875, 48.410009765625],
              [7.794824218750023, 48.546826171875],
              [7.837988281250006, 48.636035156249996],
              [7.922753906250023, 48.698535156249996],
              [8.1240234375, 48.873291015625],
              [8.140332031250011, 48.88642578125],
              [8.134863281250006, 48.973583984375],
              [8.080664062500006, 48.985888671874996],
              [8.001269531250017, 49.0109375],
              [7.799218750000023, 49.041894531249994],
              [7.6109375, 49.061767578125],
              [7.525488281250006, 49.086376953125],
              [7.450585937500023, 49.152197265625],
              [7.404199218750023, 49.153076171875],
              [7.313378906250023, 49.129541015624994],
              [7.199902343750011, 49.113623046875],
              [7.117382812500011, 49.1275390625],
              [7.065722656250017, 49.124853515625],
              [7.03671875, 49.112695312499994],
              [7.022167968750011, 49.1234375],
              [7.00146484375, 49.1798828125],
              [6.958300781250017, 49.194628906249996],
              [6.891210937500006, 49.20751953125],
              [6.849511718750023, 49.201953125],
              [6.820703125000023, 49.17392578125],
              [6.776269531250023, 49.154150390625],
              [6.735449218750006, 49.160595703125],
              [6.607617187500011, 49.290869140625],
              [6.57470703125, 49.319677734375],
              [6.566308593750023, 49.34619140625],
              [6.534277343750006, 49.394677734374994],
              [6.458105468750006, 49.44287109375],
              [6.382226562500023, 49.458154296874994],
              [6.344335937500006, 49.452734375],
              [6.348437500000017, 49.5126953125],
              [6.378320312500023, 49.599609375],
              [6.40673828125, 49.644970703125],
              [6.444628906250017, 49.68203125],
              [6.484765625000023, 49.7078125],
              [6.49375, 49.75439453125],
              [6.4873046875, 49.798486328124994],
              [6.44091796875, 49.805322265624994],
              [6.324609375000023, 49.837890625],
              [6.256054687500011, 49.87216796875],
              [6.204882812500017, 49.91513671875],
              [6.13818359375, 49.97431640625],
              [6.109765625000023, 50.034375],
              [6.108300781250023, 50.09423828125],
              [6.116503906250017, 50.120996093749994],
              [6.121289062500011, 50.13935546875],
              [6.175097656250017, 50.232666015625],
              [6.364453125000011, 50.316162109375],
              [6.343652343750023, 50.400244140625],
              [6.340917968750006, 50.4517578125],
              [6.294921875, 50.485498046874994],
              [6.203027343750023, 50.49912109375],
              [6.1787109375, 50.522509765624996],
              [6.16845703125, 50.545361328125],
              [6.2359375, 50.5966796875],
              [6.154492187500011, 50.637255859374996],
              [6.119433593750017, 50.679248046874996],
              [6.005957031250006, 50.732226562499996],
              [5.993945312500017, 50.750439453125],
              [6.0484375, 50.9048828125],
              [6.0068359375, 50.949951171875],
              [5.955078125, 50.972949218749996],
              [5.894726562500011, 50.984228515625],
              [5.8671875, 51.005664062499996],
              [5.857519531250006, 51.030126953125],
              [5.868359375000011, 51.0453125],
              [5.939257812500017, 51.040820312499996],
              [5.961035156250006, 51.056689453124996],
              [6.129980468750006, 51.147412109375],
              [6.136914062500011, 51.16484375],
              [6.113378906250006, 51.174707031249994],
              [6.082421875000023, 51.179980468749996],
              [6.074804687500006, 51.199023437499996],
              [6.075878906250011, 51.22412109375],
              [6.166210937500011, 51.354833984375],
              [6.19287109375, 51.410595703125],
              [6.198828125, 51.45],
              [6.193261718750023, 51.488916015624994],
              [6.1416015625, 51.550097656249996],
              [6.091113281250017, 51.598925781249996],
              [6.08935546875, 51.63779296875],
              [6.052734375, 51.658251953124996],
              [5.948535156250017, 51.76240234375],
              [5.94873046875, 51.802685546875],
              [6.007617187500017, 51.833984375],
              [6.08984375, 51.853955078125],
              [6.1171875, 51.870410156249996],
              [6.16650390625, 51.88076171875],
              [6.297070312500011, 51.850732421875],
              [6.355664062500011, 51.824658203125],
              [6.372167968750006, 51.830029296875],
              [6.425, 51.8583984375],
              [6.517578125, 51.853955078125],
              [6.741796875, 51.910888671875],
              [6.775195312500017, 51.93828125],
              [6.800390625, 51.9673828125],
              [6.802441406250011, 51.98017578125],
              [6.715625, 52.036181640624996],
              [6.712988281250006, 52.056884765625],
              [6.724511718750023, 52.080224609375],
              [6.7490234375, 52.098681640624996],
              [6.800390625, 52.11123046875],
              [6.855078125, 52.135791015624996],
              [6.977246093750011, 52.205517578125],
              [7.019628906250006, 52.266015625],
              [7.032617187500023, 52.331494140625],
              [7.03515625, 52.380224609375],
              [7.001855468750023, 52.418994140624996],
              [6.968164062500023, 52.444091796875],
              [6.922070312500011, 52.440283203125],
              [6.83251953125, 52.44228515625],
              [6.748828125000017, 52.464013671875],
              [6.702929687500017, 52.49921875],
              [6.691601562500011, 52.53017578125],
              [6.71240234375, 52.549658203125],
              [6.71875, 52.573583984375],
              [6.705371093750017, 52.59765625],
              [6.710742187500017, 52.61787109375],
              [6.748437500000023, 52.634082031249996],
              [7.01318359375, 52.633544921875],
              [7.033007812500017, 52.6513671875],
              [7.050878906250006, 52.744775390625],
              [7.117089843750023, 52.887011718749996],
              [7.179492187500017, 52.9662109375],
              [7.18994140625, 52.99951171875],
              [7.18896484375, 53.18720703125],
              [7.197265625, 53.282275390624996],
              [7.152050781250011, 53.326953125],
              [7.053320312500006, 53.375830078125],
              [7.074316406250006, 53.47763671875],
              [7.107128906250011, 53.556982421875],
              [7.206445312500023, 53.654541015625],
              [7.285253906250006, 53.68134765625],
              [7.629199218750017, 53.697265625],
              [8.00927734375, 53.690722656249996],
              [8.167089843750006, 53.543408203125],
              [8.108496093750006, 53.46767578125],
              [8.20078125, 53.432421875],
              [8.245214843750006, 53.4453125],
              [8.279003906250011, 53.511181640625],
              [8.301562500000017, 53.584130859375],
              [8.333886718750023, 53.606201171875],
              [8.451367187500011, 53.551708984375],
              [8.49267578125, 53.51435546875],
              [8.495214843750006, 53.39423828125],
              [8.538476562500023, 53.556884765625],
              [8.50625, 53.670751953125],
              [8.528417968750006, 53.781103515625],
              [8.575585937500023, 53.8384765625],
              [8.618945312500017, 53.875],
              [8.897753906250017, 53.835693359375],
              [9.20556640625, 53.85595703125],
              [9.321972656250011, 53.8134765625],
              [9.585351562500023, 53.60048828125],
              [9.673144531250017, 53.565625],
              [9.783984375000017, 53.554638671875],
              [9.63125, 53.6001953125],
              [9.31201171875, 53.859130859375],
              [9.21640625, 53.8912109375],
              [9.069628906250017, 53.900927734374996],
              [8.978125, 53.926220703125],
              [8.92041015625, 53.96533203125],
              [8.903515625000011, 54.000292968749996],
              [8.906640625000023, 54.260791015624996],
              [8.8515625, 54.299560546875],
              [8.780371093750006, 54.313037109374996],
              [8.736035156250011, 54.295214843749996],
              [8.644921875000023, 54.294970703124996],
              [8.625781250000017, 54.353955078125],
              [8.648046875, 54.39765625],
              [8.831152343750006, 54.427539062499996],
              [8.951855468750011, 54.467578125],
              [8.957226562500011, 54.538330078125],
              [8.880957031250006, 54.5939453125],
              [8.789648437500006, 54.695947265625],
              [8.682324218750011, 54.791845703125],
              [8.670312500000023, 54.90341796875],
              [8.670703125000017, 54.9033203125],
              [8.857226562500017, 54.901123046875],
              [8.902929687500006, 54.896923828125],
              [9.185839843750017, 54.844677734375],
              [9.254980468750006, 54.8080078125],
              [9.341992187500011, 54.806298828125],
              [9.498730468750011, 54.8404296875],
              [9.615820312500006, 54.855419921875],
              [9.661230468750006, 54.834375],
              [9.725, 54.825537109375],
              [9.73974609375, 54.825537109375],
              [9.745898437500017, 54.807177734374996],
              [9.892285156250011, 54.780615234375],
              [9.953808593750011, 54.73828125],
              [10.022167968750011, 54.67392578125],
              [10.02880859375, 54.581298828125],
              [9.941308593750023, 54.5146484375],
              [9.86865234375, 54.472460937499996],
              [10.143457031250023, 54.488427734375],
              [10.170800781250023, 54.4501953125],
              [10.21240234375, 54.408935546875],
              [10.360449218750006, 54.438330078125],
              [10.731542968750006, 54.316259765625],
              [10.955957031250023, 54.37568359375],
              [11.013378906250011, 54.379150390625],
              [11.064355468750023, 54.280517578125],
              [11.008593750000017, 54.18115234375],
              [10.810742187500011, 54.075146484375],
              [10.854589843750006, 54.009814453124996],
              [10.917773437500017, 53.9953125],
              [11.104296875000017, 54.0091796875],
              [11.399609375000011, 53.944628906249996],
              [11.461132812500011, 53.96474609375],
              [11.700585937500023, 54.113525390625],
              [11.796289062500023, 54.145458984375],
              [12.111328125, 54.168310546875],
              [12.168652343750011, 54.225878906249996],
              [12.296289062500023, 54.2837890625],
              [12.378515625, 54.347021484375],
              [12.575390625000011, 54.4673828125],
              [12.779101562500017, 54.445703125],
              [12.898046875, 54.42265625],
              [13.028613281250017, 54.41103515625],
              [13.1474609375, 54.28271484375],
              [13.448046875000017, 54.140869140625],
              [13.72421875, 54.15322265625],
              [13.822265625, 54.01904296875],
              [13.865527343750017, 53.853369140625],
              [13.950390625000011, 53.8013671875],
              [14.025, 53.767431640625],
              [14.25, 53.731884765625],
              [14.258886718750006, 53.729638671875],
              [14.26611328125, 53.70712890625],
              [14.279882812500006, 53.624755859375],
              [14.298730468750023, 53.556445312499996],
              [14.41455078125, 53.283496093749996],
              [14.412304687500011, 53.216748046875],
              [14.410937500000017, 53.199023437499996],
              [14.368554687500023, 53.10556640625],
              [14.293164062500011, 53.0267578125],
              [14.193652343750017, 52.98232421875],
              [14.138867187500011, 52.932861328125],
              [14.128613281250011, 52.878222656249996],
              [14.253710937500017, 52.782519531249996],
              [14.514062500000023, 52.64560546875],
              [14.619433593750017, 52.528515625],
              [14.569726562500023, 52.431103515625],
              [14.554589843750023, 52.359667968749996],
              [14.573925781250011, 52.31416015625],
              [14.615625, 52.277636718749996],
              [14.679882812500011, 52.25],
              [14.705371093750017, 52.207470703125],
              [14.6923828125, 52.150048828125],
              [14.70458984375, 52.110205078125],
              [14.752539062500006, 52.081835937499996],
              [14.748144531250006, 52.07080078125],
              [14.724804687500011, 52.030859375],
              [14.69296875, 51.9580078125],
              [14.674902343750006, 51.904833984374996],
              [14.601660156250006, 51.832373046875],
              [14.623925781250023, 51.770800781249996],
              [14.681347656250011, 51.698193359375],
              [14.724902343750017, 51.66171875],
              [14.738671875000023, 51.6271484375],
              [14.7109375, 51.544921875],
              [14.724707031250006, 51.523876953125],
              [14.905957031250011, 51.463330078125],
              [14.935546875, 51.435351562499996],
              [14.953125, 51.3771484375],
              [15.0166015625, 51.252734375],
              [14.9638671875, 51.095117187499994],
              [14.91748046875, 51.008740234375],
              [14.814257812500017, 50.871630859374996],
              [14.809375, 50.858984375],
              [14.797460937500006, 50.842333984374996],
              [14.766503906250023, 50.818310546875],
              [14.723339843750011, 50.814697265625],
              [14.658203125, 50.8326171875],
              [14.613574218750017, 50.85556640625],
              [14.623828125000017, 50.91474609375],
              [14.59521484375, 50.918603515624994],
              [14.559667968750006, 50.954931640625],
              [14.545703125000017, 50.993945312499996],
              [14.50732421875, 51.00986328125],
              [14.367285156250006, 51.026269531249994],
              [14.319726562500023, 51.03779296875],
              [14.283203125, 51.0294921875],
              [14.255859375, 51.001855468749994],
              [14.273339843750023, 50.976904296875],
              [14.299414062500006, 50.952587890625],
              [14.377050781250006, 50.9140625],
              [14.369042968750023, 50.898730468749996],
              [14.201757812500006, 50.861230468749994],
              [14.096484375000017, 50.82275390625],
              [13.998437500000023, 50.801123046875],
              [13.898535156250006, 50.761279296874996],
              [13.701367187500011, 50.71650390625],
              [13.556738281250006, 50.704638671874996],
              [13.526562500000011, 50.692822265625],
              [13.472558593750023, 50.616943359375],
              [13.436132812500006, 50.60107421875],
              [13.401171875000017, 50.609326171875],
              [13.374609375, 50.621728515624994],
              [13.341015625000011, 50.61142578125],
              [13.306054687500023, 50.586328125],
              [13.26953125, 50.576416015625],
              [13.237695312500023, 50.5767578125],
              [13.18115234375, 50.510498046875],
              [13.016406250000017, 50.490380859374994],
              [12.9970703125, 50.4560546875],
              [12.966796875, 50.4162109375],
              [12.942675781250017, 50.4064453125],
              [12.868261718750006, 50.422216796875],
              [12.765429687500017, 50.430957031249996],
              [12.706445312500023, 50.409130859375],
              [12.635546875000017, 50.3970703125],
              [12.549023437500011, 50.393408203125],
              [12.45263671875, 50.3498046875],
              [12.358593750000011, 50.273242187499996],
              [12.3056640625, 50.205712890624994],
              [12.27734375, 50.181445312499996],
              [12.231152343750011, 50.244873046875],
              [12.1748046875, 50.288378906249996],
              [12.134863281250006, 50.3109375],
              [12.09921875, 50.310986328125],
              [12.08984375, 50.3017578125],
              [12.089746093750023, 50.2685546875],
              [12.127832031250023, 50.213427734374996],
              [12.175, 50.175830078124996],
              [12.182519531250023, 50.148046875],
              [12.207812500000017, 50.097509765625],
              [12.276464843750006, 50.042333984375],
              [12.384179687500023, 49.998583984374996],
              [12.457617187500006, 49.955517578125],
              [12.512011718750017, 49.895800781249996],
              [12.5125, 49.87744140625],
              [12.49755859375, 49.853076171874996],
              [12.471875, 49.830078125],
              [12.4501953125, 49.800146484375],
              [12.390527343750023, 49.739648437499994],
              [12.408203125, 49.713183593749996],
              [12.45703125, 49.67978515625],
              [12.500292968750017, 49.639697265624996],
              [12.555761718750006, 49.574853515624994],
              [12.632031250000011, 49.461230468749996],
              [12.68115234375, 49.414501953125],
              [12.747851562500017, 49.3662109375],
              [12.813378906250023, 49.329345703125],
              [12.916699218750011, 49.33046875],
              [13.023730468750017, 49.260107421875],
              [13.140527343750023, 49.158349609374994],
              [13.227832031250017, 49.111669921875],
              [13.288769531250011, 49.097460937499996],
              [13.339062500000011, 49.060791015625],
              [13.383691406250023, 49.008105468749996],
              [13.401171875000017, 48.977587890624996],
              [13.440722656250017, 48.95556640625],
              [13.547656250000017, 48.95966796875],
              [13.684960937500023, 48.876708984375],
              [13.769921875000023, 48.815966796874996],
              [13.814746093750017, 48.766943359375],
              [13.802929687500011, 48.747509765625],
              [13.797460937500006, 48.686425781249994],
              [13.798828125, 48.6216796875],
              [13.785351562500011, 48.587451171874996],
              [13.723925781250017, 48.542382812499994],
              [13.692187500000017, 48.532763671874996],
              [13.675195312500023, 48.523046875],
              [13.486621093750017, 48.581835937499996],
              [13.4716796875, 48.571826171874996],
              [13.459863281250023, 48.56455078125],
              [13.409375, 48.394140625],
              [13.374609375, 48.361376953124996],
              [13.322851562500006, 48.33125],
              [13.215234375000023, 48.301904296874994],
              [13.140429687500017, 48.289941406249994],
              [13.082128906250006, 48.27509765625],
              [12.8974609375, 48.2037109375],
              [12.814257812500017, 48.16083984375],
              [12.760351562500006, 48.106982421874996],
              [12.760058593750017, 48.075976562499996],
              [12.849902343750017, 47.984814453125],
              [12.953515625000023, 47.890625],
              [12.954199218750006, 47.807763671874994],
              [12.908300781250006, 47.74580078125],
              [12.897656250000011, 47.721875],
              [12.928125, 47.712841796875],
              [12.985546875000011, 47.709423828125],
              [13.033593750000023, 47.69873046875],
              [13.054101562500023, 47.655126953125],
              [13.047949218750006, 47.579150390624996],
              [13.031542968750017, 47.5080078125],
              [13.014355468750011, 47.478076171874996],
              [12.968066406250017, 47.47568359375],
              [12.87890625, 47.5064453125],
              [12.809375, 47.5421875],
              [12.7828125, 47.56416015625],
              [12.781152343750023, 47.5904296875],
              [12.796191406250017, 47.60703125],
              [12.771386718750023, 47.639404296875],
              [12.685839843750017, 47.669335937499994],
              [12.59423828125, 47.656298828124996],
              [12.526562500000011, 47.636132812499994],
              [12.48291015625, 47.6373046875],
              [12.435742187500011, 47.66611328125],
              [12.363183593750023, 47.68818359375],
              [12.268359375000017, 47.702734375],
              [12.209277343750017, 47.71826171875],
              [12.196875, 47.70908203125],
              [12.203808593750011, 47.646728515625],
              [12.185644531250006, 47.61953125],
              [11.716796875, 47.58349609375],
              [11.573925781250011, 47.549755859375],
              [11.469921875000011, 47.506103515625],
              [11.392968750000023, 47.487158203125],
              [11.374121093750006, 47.460253906249996],
              [11.297949218750006, 47.42490234375],
              [11.2119140625, 47.413623046874996],
              [11.191210937500017, 47.425195312499994],
              [11.136035156250017, 47.40888671875],
              [11.0419921875, 47.393115234374996],
              [10.980859375000023, 47.39814453125],
              [10.9521484375, 47.426708984375],
              [10.893945312500023, 47.470458984375],
              [10.87060546875, 47.50078125],
              [10.873046875, 47.52021484375],
              [10.741601562500023, 47.52412109375],
              [10.65869140625, 47.547216796875],
              [10.482812500000023, 47.541796875],
              [10.439453125, 47.5515625],
              [10.430371093750011, 47.541064453124996],
              [10.40390625, 47.4169921875],
              [10.369140625, 47.366064453125],
              [10.312792968750017, 47.313427734375],
              [10.240625, 47.284130859375],
              [10.183007812500023, 47.27880859375],
              [10.185742187500011, 47.3171875],
              [10.200292968750006, 47.363427734374994],
              [10.158789062500006, 47.374267578125],
              [10.096484375000017, 47.37958984375],
              [10.066308593750023, 47.393359375],
              [10.07421875, 47.428515625],
              [10.059863281250017, 47.449072265625],
              [10.034082031250023, 47.473583984375],
              [9.971582031250023, 47.505322265625],
              [9.839160156250017, 47.552294921874996],
              [9.748925781250023, 47.575537109375],
              [9.715136718750017, 47.55078125],
              [9.650585937500011, 47.52587890625],
              [9.548925781250006, 47.534033203125],
              [9.524023437500006, 47.52421875],
            ],
          ],
          [
            [
              [13.709179687500011, 54.38271484375],
              [13.734179687500017, 54.3154296875],
              [13.707324218750017, 54.28115234375],
              [13.594921875000011, 54.338183593749996],
              [13.48203125, 54.33740234375],
              [13.41455078125, 54.249560546874996],
              [13.364355468750006, 54.245849609375],
              [13.190039062500006, 54.325634765625],
              [13.162109375, 54.364550781249996],
              [13.156347656250006, 54.396923828125],
              [13.18125, 54.508984375],
              [13.176660156250023, 54.544238281249996],
              [13.2314453125, 54.582763671875],
              [13.239941406250011, 54.638427734375],
              [13.336816406250023, 54.697119140625],
              [13.422753906250023, 54.69931640625],
              [13.450097656250023, 54.649609375],
              [13.4912109375, 54.615380859375],
              [13.636035156250017, 54.577001953125],
              [13.657617187500023, 54.5595703125],
              [13.670703125000017, 54.535449218749996],
              [13.603320312500017, 54.48818359375],
              [13.580468750000023, 54.46396484375],
              [13.601855468750017, 54.425146484375],
              [13.709179687500011, 54.38271484375],
            ],
          ],
          [
            [
              [14.21142578125, 53.950341796875],
              [14.1982421875, 53.91904296875],
              [14.213671875000017, 53.870751953125],
              [14.172167968750017, 53.874365234375],
              [14.04833984375, 53.863085937499996],
              [13.92578125, 53.879052734375],
              [13.902148437500017, 53.93896484375],
              [13.921679687500017, 53.996630859374996],
              [13.872460937500023, 54.036279296875],
              [13.8271484375, 54.0595703125],
              [13.820410156250006, 54.092822265624996],
              [13.827734375, 54.127246093749996],
              [14.038867187500017, 54.0345703125],
              [14.21142578125, 53.950341796875],
            ],
          ],
          [
            [
              [11.2828125, 54.41796875],
              [11.129296875000023, 54.416015625],
              [11.070703125000023, 54.456005859375],
              [11.01171875, 54.466162109375],
              [11.04345703125, 54.515478515625],
              [11.0849609375, 54.5333984375],
              [11.233593750000011, 54.501269531249996],
              [11.2802734375, 54.43837890625],
              [11.2828125, 54.41796875],
            ],
          ],
          [
            [
              [8.307714843750006, 54.786962890625],
              [8.28466796875, 54.76708984375],
              [8.295703125000017, 54.90830078125],
              [8.405175781250023, 55.058740234375],
              [8.451464843750017, 55.05537109375],
              [8.404101562500017, 55.01474609375],
              [8.390429687500017, 54.986279296875],
              [8.371191406250006, 54.92939453125],
              [8.3798828125, 54.899853515625],
              [8.629589843750011, 54.891748046875],
              [8.6005859375, 54.865380859375],
              [8.347363281250011, 54.847607421875],
              [8.307714843750006, 54.786962890625],
            ],
          ],
          [
            [
              [8.587890625, 54.712695312499996],
              [8.548925781250006, 54.68818359375],
              [8.453808593750011, 54.691064453125],
              [8.400390625, 54.714111328125],
              [8.417675781250011, 54.738671875],
              [8.468164062500023, 54.757421875],
              [8.509960937500011, 54.760302734374996],
              [8.573437500000011, 54.74873046875],
              [8.587890625, 54.712695312499996],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 6,
        sovereignt: "Estonia",
        sov_a3: "EST",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Estonia",
        adm0_a3: "EST",
        geou_dif: 0,
        geounit: "Estonia",
        gu_a3: "EST",
        su_dif: 0,
        subunit: "Estonia",
        su_a3: "EST",
        brk_diff: 0,
        name: "Estonia",
        name_long: "Estonia",
        brk_a3: "EST",
        brk_name: "Estonia",
        brk_group: null,
        abbrev: "Est.",
        postal: "EST",
        formal_en: "Republic of Estonia",
        formal_fr: null,
        name_ciawf: "Estonia",
        note_adm0: null,
        note_brk: null,
        name_sort: "Estonia",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 2,
        mapcolor9: 1,
        mapcolor13: 10,
        pop_est: 1326590,
        pop_rank: 12,
        pop_year: 2019,
        gdp_md: 31471,
        gdp_year: 2019,
        economy: "2. Developed region: nonG7",
        income_grp: "1. High income: OECD",
        fips_10: "EN",
        iso_a2: "EE",
        iso_a2_eh: "EE",
        iso_a3: "EST",
        iso_a3_eh: "EST",
        iso_n3: "233",
        iso_n3_eh: "233",
        un_a3: "233",
        wb_a2: "EE",
        wb_a3: "EST",
        woe_id: 23424805,
        woe_id_eh: 23424805,
        woe_note: "Exact WOE match as country",
        adm0_iso: "EST",
        adm0_diff: null,
        adm0_tlc: "EST",
        adm0_a3_us: "EST",
        adm0_a3_fr: "EST",
        adm0_a3_ru: "EST",
        adm0_a3_es: "EST",
        adm0_a3_cn: "EST",
        adm0_a3_tw: "EST",
        adm0_a3_in: "EST",
        adm0_a3_np: "EST",
        adm0_a3_pk: "EST",
        adm0_a3_de: "EST",
        adm0_a3_gb: "EST",
        adm0_a3_br: "EST",
        adm0_a3_il: "EST",
        adm0_a3_ps: "EST",
        adm0_a3_sa: "EST",
        adm0_a3_eg: "EST",
        adm0_a3_ma: "EST",
        adm0_a3_pt: "EST",
        adm0_a3_ar: "EST",
        adm0_a3_jp: "EST",
        adm0_a3_ko: "EST",
        adm0_a3_vn: "EST",
        adm0_a3_tr: "EST",
        adm0_a3_id: "EST",
        adm0_a3_pl: "EST",
        adm0_a3_gr: "EST",
        adm0_a3_it: "EST",
        adm0_a3_nl: "EST",
        adm0_a3_se: "EST",
        adm0_a3_bd: "EST",
        adm0_a3_ua: "EST",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Northern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: 25.867126,
        label_y: 58.724865,
        ne_id: 1159320615,
        wikidataid: "Q191",
        name_ar: "إستونيا",
        name_bn: "এস্তোনিয়া",
        name_de: "Estland",
        name_en: "Estonia",
        name_es: "Estonia",
        name_fa: "استونی",
        name_fr: "Estonie",
        name_el: "Εσθονία",
        name_he: "אסטוניה",
        name_hi: "एस्टोनिया",
        name_hu: "Észtország",
        name_id: "Estonia",
        name_it: "Estonia",
        name_ja: "エストニア",
        name_ko: "에스토니아",
        name_nl: "Estland",
        name_pl: "Estonia",
        name_pt: "Estónia",
        name_ru: "Эстония",
        name_sv: "Estland",
        name_tr: "Estonya",
        name_uk: "Естонія",
        name_ur: "استونیا",
        name_vi: "Estonia",
        name_zh: "爱沙尼亚",
        name_zht: "愛沙尼亞",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "EST.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [27.351953125000023, 57.528125],
              [27.326562500000023, 57.52548828125],
              [27.187109375, 57.538330078125],
              [27.03339843750001, 57.578759765625],
              [26.96601562500001, 57.609130859375],
              [26.899804687500023, 57.6087890625],
              [26.819726562500023, 57.588720703125],
              [26.532617187500023, 57.531005859375],
              [26.46210937500001, 57.544482421874996],
              [26.29804687500001, 57.60107421875],
              [26.21503906250001, 57.662744140625],
              [26.030371093750006, 57.785546875],
              [26.015234375, 57.814746093749996],
              [25.991113281250023, 57.838183593749996],
              [25.79375, 57.8685546875],
              [25.72089843750001, 57.913818359375],
              [25.66015625, 57.920166015625],
              [25.5712890625, 57.9427734375],
              [25.34003906250001, 58.039453125],
              [25.282617187500023, 58.048486328124994],
              [25.268652343750006, 58.0322265625],
              [25.27265625000001, 58.009375],
              [25.25830078125, 57.996142578124996],
              [25.22871093750001, 57.99658203125],
              [25.175195312500023, 58.032128906249994],
              [25.11103515625001, 58.063427734375],
              [24.91132812500001, 58.00458984375],
              [24.83906250000001, 57.988720703125],
              [24.775781250000023, 57.98525390625],
              [24.458886718750023, 57.907861328125],
              [24.3625, 57.866162109375],
              [24.322558593750017, 57.87060546875],
              [24.33203125, 57.909765625],
              [24.4638671875, 58.10595703125],
              [24.4875, 58.261621093749994],
              [24.535742187500006, 58.2830078125],
              [24.549707031250023, 58.304589843749994],
              [24.529101562500017, 58.354248046875],
              [24.3921875, 58.386083984375],
              [24.3369140625, 58.381396484375],
              [24.287207031250006, 58.328027343749994],
              [24.235644531250017, 58.28955078125],
              [24.11484375, 58.26611328125],
              [24.01093750000001, 58.306640625],
              [23.767578125, 58.36083984375],
              [23.7060546875, 58.433007812499994],
              [23.691503906250006, 58.505615234375],
              [23.562792968750017, 58.575830078124994],
              [23.50927734375, 58.658544921875],
              [23.530664062500023, 58.716259765625],
              [23.6474609375, 58.754150390625],
              [23.680761718750006, 58.787158203125],
              [23.533593750000023, 58.78193359375],
              [23.50361328125001, 58.78984375],
              [23.497167968750006, 58.81953125],
              [23.432031250000023, 58.920654296875],
              [23.489648437500023, 58.960498046875],
              [23.515039062500023, 58.99921875],
              [23.4677734375, 59.032177734375],
              [23.48017578125001, 59.069677734375],
              [23.516992187500023, 59.107568359374994],
              [23.494433593750017, 59.195654296875],
              [23.640527343750023, 59.242333984374994],
              [23.782519531250017, 59.275146484375],
              [24.083398437500023, 59.291894531249994],
              [24.053613281250023, 59.372314453125],
              [24.175390625, 59.375927734375],
              [24.38037109375, 59.47265625],
              [24.58359375, 59.4556640625],
              [24.877539062500006, 59.5220703125],
              [25.44375, 59.521142578124994],
              [25.520898437500023, 59.559472656249994],
              [25.507421875, 59.597998046875],
              [25.50927734375, 59.639013671875],
              [25.61572265625, 59.6275390625],
              [25.79375, 59.634667968749994],
              [26.460839843750023, 59.55390625],
              [26.625, 59.55390625],
              [26.85205078125, 59.47177734375],
              [26.974707031250006, 59.450634765625],
              [27.335839843750023, 59.45048828125],
              [27.892578125, 59.414208984374994],
              [28.001855468750023, 59.46982421875],
              [28.0125, 59.484277343749994],
              [28.065820312500023, 59.453173828125],
              [28.13300781250001, 59.403076171875],
              [28.15107421875001, 59.374414062499994],
              [28.128320312500023, 59.357568359374994],
              [28.061328125000017, 59.34326171875],
              [28.04609375000001, 59.32783203125],
              [28.016406250000017, 59.301708984375],
              [27.93818359375001, 59.297021484374994],
              [27.89765625000001, 59.27763671875],
              [27.849511718750023, 59.19267578125],
              [27.757617187500017, 59.052001953125],
              [27.62177734375001, 58.944970703124994],
              [27.513085937500023, 58.886279296875],
              [27.464453125, 58.84130859375],
              [27.434179687500006, 58.787255859374994],
              [27.427050781250017, 58.733056640624994],
              [27.531347656250006, 58.43525390625],
              [27.530078125000017, 58.381494140624994],
              [27.50556640625001, 58.32626953125],
              [27.48779296875, 58.270068359375],
              [27.50244140625, 58.221337890624994],
              [27.571093750000017, 58.138085937499994],
              [27.644140625, 58.013916015625],
              [27.6734375, 57.934619140624996],
              [27.721972656250017, 57.90546875],
              [27.76875, 57.884130859375],
              [27.77851562500001, 57.870703125],
              [27.776953125, 57.856738281249996],
              [27.752832031250023, 57.841015625],
              [27.542089843750006, 57.7994140625],
              [27.514746093750006, 57.764208984374996],
              [27.491992187500017, 57.724951171875],
              [27.4, 57.666796875],
              [27.37177734375001, 57.612548828125],
              [27.354296875000017, 57.55029296875],
              [27.351953125000023, 57.528125],
            ],
          ],
          [
            [
              [22.61738281250001, 58.621240234374994],
              [22.688378906250023, 58.597021484375],
              [22.753808593750023, 58.6046875],
              [22.820117187500017, 58.621533203125],
              [22.964257812500023, 58.605712890625],
              [23.292871093750023, 58.48349609375],
              [23.3232421875, 58.450830078124994],
              [23.12714843750001, 58.435986328125],
              [23.082617187500006, 58.398486328125],
              [23.035449218750017, 58.372314453125],
              [22.979882812500023, 58.3638671875],
              [22.885156250000023, 58.311279296875],
              [22.75703125000001, 58.260888671874994],
              [22.730273437500017, 58.2306640625],
              [22.498437500000023, 58.236230468749994],
              [22.371679687500006, 58.217138671875],
              [22.269335937500017, 58.1607421875],
              [22.227343750000017, 58.051806640625],
              [22.152441406250006, 57.966796875],
              [22.076269531250006, 57.93603515625],
              [21.996875, 57.93134765625],
              [21.97802734375, 57.96328125],
              [21.98554687500001, 57.995166015624996],
              [22.152929687500006, 58.11533203125],
              [22.18769531250001, 58.154345703125],
              [22.104394531250023, 58.1716796875],
              [22.034570312500023, 58.21337890625],
              [21.882128906250017, 58.262353515624994],
              [21.8544921875, 58.301660156249994],
              [21.891015625000023, 58.304589843749994],
              [21.924414062500006, 58.315869140625],
              [21.96503906250001, 58.348828125],
              [21.98408203125001, 58.386669921875],
              [21.8623046875, 58.49716796875],
              [21.924414062500006, 58.5142578125],
              [22.001855468750023, 58.51025390625],
              [22.081347656250017, 58.478125],
              [22.168554687500006, 58.5158203125],
              [22.20556640625, 58.521386718749994],
              [22.2666015625, 58.507958984374994],
              [22.328125, 58.580859375],
              [22.474414062500017, 58.604882812499994],
              [22.546972656250006, 58.627392578125],
              [22.61738281250001, 58.621240234374994],
            ],
          ],
          [
            [
              [22.923730468750023, 58.826904296875],
              [22.841699218750023, 58.77744140625],
              [22.792871093750023, 58.797216796875],
              [22.76728515625001, 58.8208984375],
              [22.661425781250017, 58.7091796875],
              [22.54218750000001, 58.689990234375],
              [22.47265625, 58.712060546874994],
              [22.478906250000023, 58.753808593749994],
              [22.411035156250023, 58.86337890625],
              [22.307421875000017, 58.895458984375],
              [22.161914062500017, 58.898486328125],
              [22.05625, 58.943603515625],
              [22.46259765625001, 58.97431640625],
              [22.50458984375001, 59.02646484375],
              [22.587207031250017, 59.081201171874994],
              [22.6494140625, 59.087109375],
              [22.702246093750006, 59.0744140625],
              [22.712207031250017, 59.031982421875],
              [22.725488281250023, 59.015087890625],
              [22.90986328125001, 58.9912109375],
              [22.98164062500001, 58.919824218749994],
              [23.008691406250023, 58.833935546875],
              [22.923730468750023, 58.826904296875],
            ],
          ],
          [
            [
              [23.343554687500017, 58.550341796875],
              [23.260351562500006, 58.539990234375],
              [23.0634765625, 58.611083984375],
              [23.10908203125001, 58.659228515625],
              [23.165429687500023, 58.678125],
              [23.332812500000017, 58.648583984374994],
              [23.3564453125, 58.575537109375],
              [23.343554687500017, 58.550341796875],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 5,
        sovereignt: "Latvia",
        sov_a3: "LVA",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Latvia",
        adm0_a3: "LVA",
        geou_dif: 0,
        geounit: "Latvia",
        gu_a3: "LVA",
        su_dif: 0,
        subunit: "Latvia",
        su_a3: "LVA",
        brk_diff: 0,
        name: "Latvia",
        name_long: "Latvia",
        brk_a3: "LVA",
        brk_name: "Latvia",
        brk_group: null,
        abbrev: "Lat.",
        postal: "LV",
        formal_en: "Republic of Latvia",
        formal_fr: null,
        name_ciawf: "Latvia",
        note_adm0: null,
        note_brk: null,
        name_sort: "Latvia",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 7,
        mapcolor9: 6,
        mapcolor13: 13,
        pop_est: 1912789,
        pop_rank: 12,
        pop_year: 2019,
        gdp_md: 34102,
        gdp_year: 2019,
        economy: "2. Developed region: nonG7",
        income_grp: "3. Upper middle income",
        fips_10: "LG",
        iso_a2: "LV",
        iso_a2_eh: "LV",
        iso_a3: "LVA",
        iso_a3_eh: "LVA",
        iso_n3: "428",
        iso_n3_eh: "428",
        un_a3: "428",
        wb_a2: "LV",
        wb_a3: "LVA",
        woe_id: 23424874,
        woe_id_eh: 23424874,
        woe_note: "Exact WOE match as country",
        adm0_iso: "LVA",
        adm0_diff: null,
        adm0_tlc: "LVA",
        adm0_a3_us: "LVA",
        adm0_a3_fr: "LVA",
        adm0_a3_ru: "LVA",
        adm0_a3_es: "LVA",
        adm0_a3_cn: "LVA",
        adm0_a3_tw: "LVA",
        adm0_a3_in: "LVA",
        adm0_a3_np: "LVA",
        adm0_a3_pk: "LVA",
        adm0_a3_de: "LVA",
        adm0_a3_gb: "LVA",
        adm0_a3_br: "LVA",
        adm0_a3_il: "LVA",
        adm0_a3_ps: "LVA",
        adm0_a3_sa: "LVA",
        adm0_a3_eg: "LVA",
        adm0_a3_ma: "LVA",
        adm0_a3_pt: "LVA",
        adm0_a3_ar: "LVA",
        adm0_a3_jp: "LVA",
        adm0_a3_ko: "LVA",
        adm0_a3_vn: "LVA",
        adm0_a3_tr: "LVA",
        adm0_a3_id: "LVA",
        adm0_a3_pl: "LVA",
        adm0_a3_gr: "LVA",
        adm0_a3_it: "LVA",
        adm0_a3_nl: "LVA",
        adm0_a3_se: "LVA",
        adm0_a3_bd: "LVA",
        adm0_a3_ua: "LVA",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Northern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 6,
        long_len: 6,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: 25.458723,
        label_y: 57.066872,
        ne_id: 1159321033,
        wikidataid: "Q211",
        name_ar: "لاتفيا",
        name_bn: "লাতভিয়া",
        name_de: "Lettland",
        name_en: "Latvia",
        name_es: "Letonia",
        name_fa: "لتونی",
        name_fr: "Lettonie",
        name_el: "Λετονία",
        name_he: "לטביה",
        name_hi: "लातविया",
        name_hu: "Lettország",
        name_id: "Latvia",
        name_it: "Lettonia",
        name_ja: "ラトビア",
        name_ko: "라트비아",
        name_nl: "Letland",
        name_pl: "Łotwa",
        name_pt: "Letónia",
        name_ru: "Латвия",
        name_sv: "Lettland",
        name_tr: "Letonya",
        name_uk: "Латвія",
        name_ur: "لٹویا",
        name_vi: "Latvia",
        name_zh: "拉脱维亚",
        name_zht: "拉脫維亞",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "LVA.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [26.593554687500017, 55.667529296874996],
            [26.542871093750023, 55.672412109374996],
            [26.40107421875001, 55.70380859375],
            [26.28125, 55.750439453125],
            [26.209570312500006, 55.812109375],
            [26.085546875, 55.896875],
            [26.004199218750017, 55.94013671875],
            [25.876367187500023, 55.9943359375],
            [25.663183593750006, 56.104833984375],
            [25.585742187500017, 56.130175781249996],
            [25.206933593750023, 56.17841796875],
            [25.069921875, 56.200390625],
            [24.94384765625, 56.3255859375],
            [24.90302734375001, 56.398193359375],
            [24.84101562500001, 56.411181640624996],
            [24.773242187500017, 56.3958984375],
            [24.699511718750017, 56.381298828125],
            [24.52900390625001, 56.2962890625],
            [24.4736328125, 56.28408203125],
            [24.36787109375001, 56.283007812499996],
            [24.120703125, 56.2642578125],
            [24.008203125000023, 56.295263671875],
            [23.81269531250001, 56.329248046875],
            [23.70673828125001, 56.334619140625],
            [23.612695312500023, 56.333837890625],
            [23.195898437500006, 56.367138671875],
            [23.11982421875001, 56.3306640625],
            [23.04296875, 56.324072265625],
            [22.96826171875, 56.380419921874996],
            [22.875585937500006, 56.396435546875],
            [22.773242187500017, 56.377294921875],
            [22.5869140625, 56.37509765625],
            [22.36591796875001, 56.392871093749996],
            [22.084570312500006, 56.40673828125],
            [22.042871093750023, 56.40078125],
            [21.730566406250006, 56.325976562499996],
            [21.65351562500001, 56.31455078125],
            [21.31464843750001, 56.188134765625],
            [21.04609375000001, 56.070068359375],
            [21.014941406250017, 56.258935546875],
            [21.03144531250001, 56.636572265625],
            [21.0712890625, 56.82373046875],
            [21.257421875, 56.932763671875],
            [21.35078125000001, 57.01767578125],
            [21.405078125000017, 57.131005859375],
            [21.421484375, 57.23583984375],
            [21.45917968750001, 57.3224609375],
            [21.72871093750001, 57.57099609375],
            [21.9423828125, 57.5978515625],
            [22.2314453125, 57.666796875],
            [22.554589843750023, 57.724267578125],
            [22.616992187500017, 57.651171875],
            [22.64863281250001, 57.595361328125],
            [23.03779296875001, 57.39208984375],
            [23.136816406250006, 57.323828125],
            [23.28730468750001, 57.08974609375],
            [23.647753906250017, 56.971044921875],
            [23.93115234375, 57.00849609375],
            [24.054296875, 57.06611328125],
            [24.28125, 57.172314453125],
            [24.382617187500017, 57.250048828124996],
            [24.403222656250023, 57.325],
            [24.36298828125001, 57.6453125],
            [24.301562500000017, 57.784130859375],
            [24.322558593750017, 57.87060546875],
            [24.3625, 57.866162109375],
            [24.458886718750023, 57.907861328125],
            [24.775781250000023, 57.98525390625],
            [24.83906250000001, 57.988720703125],
            [24.91132812500001, 58.00458984375],
            [25.11103515625001, 58.063427734375],
            [25.175195312500023, 58.032128906249994],
            [25.22871093750001, 57.99658203125],
            [25.25830078125, 57.996142578124996],
            [25.27265625000001, 58.009375],
            [25.268652343750006, 58.0322265625],
            [25.282617187500023, 58.048486328124994],
            [25.34003906250001, 58.039453125],
            [25.5712890625, 57.9427734375],
            [25.66015625, 57.920166015625],
            [25.72089843750001, 57.913818359375],
            [25.79375, 57.8685546875],
            [25.991113281250023, 57.838183593749996],
            [26.015234375, 57.814746093749996],
            [26.030371093750006, 57.785546875],
            [26.21503906250001, 57.662744140625],
            [26.29804687500001, 57.60107421875],
            [26.46210937500001, 57.544482421874996],
            [26.532617187500023, 57.531005859375],
            [26.819726562500023, 57.588720703125],
            [26.899804687500023, 57.6087890625],
            [26.96601562500001, 57.609130859375],
            [27.03339843750001, 57.578759765625],
            [27.187109375, 57.538330078125],
            [27.326562500000023, 57.52548828125],
            [27.351953125000023, 57.528125],
            [27.4697265625, 57.5240234375],
            [27.511132812500023, 57.508154296875],
            [27.538671875, 57.42978515625],
            [27.672753906250023, 57.368115234375],
            [27.796875, 57.316943359374996],
            [27.82861328125, 57.293310546875],
            [27.838281250000023, 57.247705078125],
            [27.83027343750001, 57.194482421875],
            [27.814550781250006, 57.16689453125],
            [27.762792968750006, 57.135107421875],
            [27.717382812500006, 57.054638671875],
            [27.71113281250001, 56.978076171874996],
            [27.639453125000017, 56.845654296875],
            [27.655664062500023, 56.843212890625],
            [27.806054687500023, 56.86708984375],
            [27.8486328125, 56.85341796875],
            [27.88154296875001, 56.824169921875],
            [27.89208984375, 56.741064453125],
            [27.94140625, 56.7037109375],
            [27.991601562500023, 56.6453125],
            [28.00751953125001, 56.599853515625],
            [28.103125, 56.545703125],
            [28.11083984375, 56.510693359375],
            [28.169238281250017, 56.386865234375],
            [28.191699218750017, 56.315576171875],
            [28.202050781250023, 56.260400390625],
            [28.17333984375, 56.19033203125],
            [28.14794921875, 56.142919921875],
            [28.11787109375001, 56.145800781249996],
            [28.032031250000017, 56.13330078125],
            [27.896289062500017, 56.076171875],
            [27.694238281250023, 55.941552734375],
            [27.64228515625001, 55.91171875],
            [27.589453125, 55.8091796875],
            [27.576757812500006, 55.798779296875],
            [27.45917968750001, 55.803515625],
            [27.427148437500023, 55.805957031249996],
            [27.309179687500006, 55.80390625],
            [27.052539062500017, 55.83056640625],
            [26.953027343750023, 55.812939453125],
            [26.82246093750001, 55.709228515625],
            [26.771875, 55.693994140625],
            [26.620214843750006, 55.679638671875],
            [26.593554687500017, 55.667529296874996],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Norway",
        sov_a3: "NOR",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: null,
        admin: "Norway",
        adm0_a3: "NOR",
        geou_dif: 0,
        geounit: "Norway",
        gu_a3: "NOR",
        su_dif: 0,
        subunit: "Norway",
        su_a3: "NOR",
        brk_diff: 0,
        name: "Norway",
        name_long: "Norway",
        brk_a3: "NOR",
        brk_name: "Norway",
        brk_group: null,
        abbrev: "Nor.",
        postal: "N",
        formal_en: "Kingdom of Norway",
        formal_fr: null,
        name_ciawf: "Norway",
        note_adm0: null,
        note_brk: null,
        name_sort: "Norway",
        name_alt: null,
        mapcolor7: 5,
        mapcolor8: 3,
        mapcolor9: 8,
        mapcolor13: 12,
        pop_est: 5347896,
        pop_rank: 13,
        pop_year: 2019,
        gdp_md: 403336,
        gdp_year: 2019,
        economy: "2. Developed region: nonG7",
        income_grp: "1. High income: OECD",
        fips_10: "-99",
        iso_a2: "-99",
        iso_a2_eh: "NO",
        iso_a3: "-99",
        iso_a3_eh: "NOR",
        iso_n3: "-99",
        iso_n3_eh: "578",
        un_a3: "-99",
        wb_a2: "-99",
        wb_a3: "-99",
        woe_id: -90,
        woe_id_eh: 23424910,
        woe_note:
          "Does not include Svalbard, Jan Mayen, or Bouvet Islands (28289410).",
        adm0_iso: "NOR",
        adm0_diff: null,
        adm0_tlc: "NOR",
        adm0_a3_us: "NOR",
        adm0_a3_fr: "NOR",
        adm0_a3_ru: "NOR",
        adm0_a3_es: "NOR",
        adm0_a3_cn: "NOR",
        adm0_a3_tw: "NOR",
        adm0_a3_in: "NOR",
        adm0_a3_np: "NOR",
        adm0_a3_pk: "NOR",
        adm0_a3_de: "NOR",
        adm0_a3_gb: "NOR",
        adm0_a3_br: "NOR",
        adm0_a3_il: "NOR",
        adm0_a3_ps: "NOR",
        adm0_a3_sa: "NOR",
        adm0_a3_eg: "NOR",
        adm0_a3_ma: "NOR",
        adm0_a3_pt: "NOR",
        adm0_a3_ar: "NOR",
        adm0_a3_jp: "NOR",
        adm0_a3_ko: "NOR",
        adm0_a3_vn: "NOR",
        adm0_a3_tr: "NOR",
        adm0_a3_id: "NOR",
        adm0_a3_pl: "NOR",
        adm0_a3_gr: "NOR",
        adm0_a3_it: "NOR",
        adm0_a3_nl: "NOR",
        adm0_a3_se: "NOR",
        adm0_a3_bd: "NOR",
        adm0_a3_ua: "NOR",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Northern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 6,
        long_len: 6,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 7,
        label_x: 9.679975,
        label_y: 61.357092,
        ne_id: 1159321109,
        wikidataid: "Q20",
        name_ar: "النرويج",
        name_bn: "নরওয়ে",
        name_de: "Norwegen",
        name_en: "Norway",
        name_es: "Noruega",
        name_fa: "نروژ",
        name_fr: "Norvège",
        name_el: "Νορβηγία",
        name_he: "נורווגיה",
        name_hi: "नॉर्वे",
        name_hu: "Norvégia",
        name_id: "Norwegia",
        name_it: "Norvegia",
        name_ja: "ノルウェー",
        name_ko: "노르웨이",
        name_nl: "Noorwegen",
        name_pl: "Norwegia",
        name_pt: "Noruega",
        name_ru: "Норвегия",
        name_sv: "Norge",
        name_tr: "Norveç",
        name_uk: "Норвегія",
        name_ur: "ناروے",
        name_vi: "Na Uy",
        name_zh: "挪威",
        name_zht: "挪威",
        fclass_iso: "Unrecognized",
        tlc_diff: null,
        fclass_tlc: "Unrecognized",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "NOR.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [20.622167968750006, 69.036865234375],
              [20.491992187500017, 69.03330078125],
              [20.11669921875, 69.0208984375],
              [20.282324218750006, 68.934326171875],
              [20.33710937500001, 68.899658203125],
              [20.348046875000023, 68.84873046875],
              [20.319433593750006, 68.754052734375],
              [20.240039062500017, 68.67314453125],
              [20.1474609375, 68.60732421875],
              [19.968847656250006, 68.542041015625],
              [20.240039062500017, 68.4775390625],
              [20.055957031250017, 68.390380859375],
              [19.969824218750006, 68.356396484375],
              [19.870019531250023, 68.362255859375],
              [19.691210937500017, 68.392431640625],
              [19.25898437500001, 68.46533203125],
              [19.052636718750023, 68.492724609375],
              [18.868261718750006, 68.501123046875],
              [18.769824218750017, 68.500048828125],
              [18.37861328125001, 68.56240234375],
              [18.303027343750017, 68.555419921875],
              [18.16259765625, 68.52841796875],
              [18.147070312500006, 68.4677734375],
              [18.15595703125001, 68.316845703125],
              [18.176660156250023, 68.200634765625],
              [18.125, 68.133447265625],
              [18.0732421875, 68.087841796875],
              [17.91669921875001, 67.964892578125],
              [17.564746093750017, 68.0484375],
              [17.324609375000023, 68.10380859375],
              [17.170507812500006, 68.030126953125],
              [16.783593750000023, 67.89501953125],
              [16.585546875, 67.6283203125],
              [16.574121093750023, 67.619580078125],
              [16.457128906250006, 67.5517578125],
              [16.30712890625, 67.52060546875],
              [16.19355468750001, 67.50517578125],
              [16.12744140625, 67.425830078125],
              [16.281542968750017, 67.312060546875],
              [16.360644531250017, 67.252001953125],
              [16.43427734375001, 67.155078125],
              [16.420703125000017, 67.093359375],
              [16.40351562500001, 67.05498046875],
              [16.237695312500023, 66.976416015625],
              [15.884179687500023, 66.76884765625],
              [15.557031250000023, 66.552099609375],
              [15.422949218750006, 66.48984375],
              [15.483789062500023, 66.30595703125],
              [15.374902343750023, 66.25205078125],
              [15.1533203125, 66.191064453125],
              [15.0400390625, 66.167529296875],
              [14.91796875, 66.1537109375],
              [14.543261718750017, 66.129345703125],
              [14.609960937500006, 65.932275390625],
              [14.635156250000023, 65.84501953125],
              [14.634570312500017, 65.79326171875],
              [14.595800781250006, 65.74287109375],
              [14.549511718750011, 65.64638671875],
              [14.479687500000011, 65.30146484375],
              [14.42626953125, 65.26435546875],
              [14.352441406250023, 65.170849609375],
              [14.115136718750023, 64.946142578125],
              [13.9248046875, 64.79677734375],
              [13.650292968750023, 64.58154296875],
              [13.87353515625, 64.51357421875],
              [14.07763671875, 64.464013671875],
              [14.119921875000017, 64.387744140625],
              [14.148046875, 64.260302734375],
              [14.141210937500006, 64.17353515625],
              [14.063281250000017, 64.0955078125],
              [14.002734375000017, 64.04072265625],
              [13.960546875, 64.014013671875],
              [13.670703125000017, 64.040625],
              [13.299609375000017, 64.0748046875],
              [13.203515625000023, 64.07509765625],
              [12.987597656250017, 64.05048828125],
              [12.792773437500017, 64],
              [12.690039062500006, 63.957421875],
              [12.6625, 63.940478515625],
              [12.53271484375, 63.8435546875],
              [12.301953125000011, 63.67119140625],
              [12.175195312500023, 63.595947265625],
              [12.212109375000011, 63.492236328125],
              [11.999902343750023, 63.29169921875],
              [12.138671875, 63.08916015625],
              [12.144628906250006, 63.08251953125],
              [12.218164062500023, 63.000634765624994],
              [12.141015625000023, 62.9478515625],
              [12.108593750000011, 62.919482421875],
              [12.11962890625, 62.825927734375],
              [12.139843750000011, 62.721337890624994],
              [12.121875, 62.660009765625],
              [12.114550781250017, 62.59189453125],
              [12.303515625000017, 62.285595703125],
              [12.301367187500006, 62.213769531249994],
              [12.2919921875, 62.167431640625],
              [12.233691406250017, 61.97685546875],
              [12.155371093750006, 61.720751953125],
              [12.292089843750006, 61.653466796874994],
              [12.48681640625, 61.572998046875],
              [12.596093750000023, 61.54130859375],
              [12.757519531250011, 61.445703125],
              [12.880761718750023, 61.352294921875],
              [12.863671875000023, 61.290283203125],
              [12.828222656250006, 61.221826171874994],
              [12.7763671875, 61.173974609374994],
              [12.727832031250017, 61.108251953125],
              [12.7060546875, 61.05986328125],
              [12.683007812500023, 61.046826171875],
              [12.467578125000017, 61.04150390625],
              [12.353710937500011, 61.023193359375],
              [12.294140625000011, 61.002685546875],
              [12.314648437500011, 60.892138671875],
              [12.4453125, 60.6896484375],
              [12.553808593750006, 60.545654296875],
              [12.588671875000017, 60.450732421875],
              [12.552832031250006, 60.3544921875],
              [12.515820312500011, 60.305224609375],
              [12.5146484375, 60.2388671875],
              [12.486132812500017, 60.106787109375],
              [12.402050781250011, 60.0400390625],
              [12.2919921875, 59.967236328125],
              [12.169238281250017, 59.912890625],
              [12.071875, 59.897607421874994],
              [11.98828125, 59.89130859375],
              [11.93212890625, 59.863671875],
              [11.88125, 59.782470703125],
              [11.834277343750017, 59.697167968749994],
              [11.680761718750006, 59.59228515625],
              [11.684863281250017, 59.55576171875],
              [11.743359375000011, 59.4314453125],
              [11.798144531250017, 59.289892578125],
              [11.751855468750023, 59.157568359375],
              [11.712207031250017, 59.01865234375],
              [11.642773437500011, 58.92607421875],
              [11.543554687500006, 58.893017578125],
              [11.470703125, 58.909521484375],
              [11.38828125, 59.036523437499994],
              [11.386425781250011, 59.06572265625],
              [11.365917968750011, 59.104541015625],
              [11.132128906250017, 59.143212890624994],
              [11.0908203125, 59.141796875],
              [10.998925781250023, 59.164453125],
              [10.945019531250011, 59.170849609375],
              [10.83447265625, 59.183935546875],
              [10.742578125000023, 59.29599609375],
              [10.644921875000023, 59.389208984375],
              [10.631054687500011, 59.428173828125],
              [10.634375, 59.602490234375],
              [10.6044921875, 59.680029296875],
              [10.5953125, 59.764550781249994],
              [10.533886718750011, 59.69580078125],
              [10.569531250000011, 59.587109375],
              [10.49375, 59.54150390625],
              [10.398144531250011, 59.5193359375],
              [10.407128906250023, 59.4556640625],
              [10.446386718750006, 59.443603515625],
              [10.45458984375, 59.377490234375],
              [10.431347656250011, 59.279638671875],
              [10.2431640625, 59.062060546875],
              [10.205175781250006, 59.038671875],
              [10.179394531250011, 59.00927734375],
              [10.083105468750006, 59.02880859375],
              [9.959570312500006, 58.968212890625],
              [9.842578125000017, 58.95849609375],
              [9.800195312500023, 59.02705078125],
              [9.635156250000023, 59.1177734375],
              [9.557226562500006, 59.112695312499994],
              [9.627148437500011, 59.067919921875],
              [9.696093750000017, 59.009716796875],
              [9.656933593750011, 58.97119140625],
              [9.618457031250017, 58.946044921875],
              [9.551074218750017, 58.933007812499994],
              [9.309960937500023, 58.856835937499994],
              [9.395800781250017, 58.8056640625],
              [9.322949218750011, 58.74755859375],
              [9.238671875000023, 58.739013671875],
              [9.19375, 58.711865234375],
              [9.178125, 58.675],
              [8.928417968750011, 58.569970703124994],
              [8.521386718750023, 58.3005859375],
              [8.312207031250011, 58.224462890625],
              [8.166113281250006, 58.1453125],
              [8.037402343750017, 58.147265625],
              [7.875585937500006, 58.079980468749994],
              [7.465917968750006, 58.020947265625],
              [7.194140625000017, 58.04765625],
              [7.0048828125, 58.02421875],
              [6.903417968750006, 58.0705078125],
              [6.890234375, 58.102294921875],
              [6.895312500000017, 58.120751953124994],
              [6.912304687500011, 58.14287109375],
              [6.877050781250006, 58.150732421875],
              [6.802832031250006, 58.154541015625],
              [6.77109375, 58.132226562499994],
              [6.766796875000011, 58.08154296875],
              [6.7314453125, 58.068310546875],
              [6.590527343750011, 58.097314453124994],
              [6.555078125000023, 58.1234375],
              [6.605761718750017, 58.1763671875],
              [6.692480468750006, 58.224023437499994],
              [6.6767578125, 58.233789062499994],
              [6.659863281250011, 58.262744140625],
              [6.617578125000023, 58.26640625],
              [6.491503906250017, 58.259423828124994],
              [6.389062500000023, 58.26796875],
              [6.0546875, 58.375146484374994],
              [5.9765625, 58.43232421875],
              [5.706835937500017, 58.5236328125],
              [5.5859375, 58.62041015625],
              [5.517285156250011, 58.726513671875],
              [5.5224609375, 58.82265625],
              [5.555566406250023, 58.9751953125],
              [5.612207031250023, 59.012890625],
              [5.854296875000017, 58.95947265625],
              [6.099023437500023, 58.870263671874994],
              [6.137304687500006, 58.874658203124994],
              [6.214160156250017, 58.944677734375],
              [6.36328125, 59.000927734375],
              [6.321093750000017, 59.016455078125],
              [6.099414062500017, 58.951953125],
              [6.016992187500023, 58.987695312499994],
              [5.88916015625, 59.060498046875],
              [5.88916015625, 59.09794921875],
              [5.94873046875, 59.13544921875],
              [5.968554687500017, 59.1861328125],
              [5.937304687500017, 59.233984375],
              [5.951855468750011, 59.299072265625],
              [6.050683593750023, 59.3681640625],
              [6.198925781250011, 59.4380859375],
              [6.3056640625, 59.50556640625],
              [6.415332031250017, 59.547119140625],
              [6.40390625, 59.560986328125],
              [6.278515625000011, 59.534521484375],
              [6.158593750000023, 59.489648437499994],
              [6.017382812500017, 59.414453125],
              [5.84521484375, 59.353466796875],
              [5.717968750000011, 59.329833984375],
              [5.657324218750006, 59.31025390625],
              [5.5640625, 59.2912109375],
              [5.467578125000017, 59.20380859375],
              [5.3623046875, 59.166357421875],
              [5.173242187500023, 59.162548828125],
              [5.131640625000017, 59.226464843749994],
              [5.18505859375, 59.453662109375],
              [5.2421875, 59.564306640625],
              [5.304882812500011, 59.642578125],
              [5.403515625000011, 59.65576171875],
              [5.472460937500017, 59.7130859375],
              [5.529687500000023, 59.7130859375],
              [5.579492187500023, 59.68662109375],
              [5.772167968750011, 59.6609375],
              [5.867285156250006, 59.733984375],
              [5.991015625000017, 59.744677734375],
              [6.216601562500017, 59.818359375],
              [6.2119140625, 59.831787109375],
              [6.059277343750011, 59.815576171874994],
              [5.966699218750023, 59.81318359375],
              [5.833984375, 59.794677734375],
              [5.763476562500017, 59.80791015625],
              [5.73046875, 59.8630859375],
              [5.783593750000023, 59.91279296875],
              [5.996484375000023, 60.031494140625],
              [6.069921875, 60.08349609375],
              [6.11181640625, 60.132080078125],
              [6.105175781250011, 60.16513671875],
              [6.140527343750023, 60.23349609375],
              [6.348730468750006, 60.352978515625],
              [6.51806640625, 60.407568359375],
              [6.573632812500023, 60.360595703125],
              [6.5263671875, 60.213623046875],
              [6.52685546875, 60.1529296875],
              [6.660937500000017, 60.367236328125],
              [6.719921875000011, 60.4181640625],
              [6.787109375, 60.4541015625],
              [6.94970703125, 60.47822265625],
              [6.995703125, 60.511962890625],
              [6.806347656250011, 60.50078125],
              [6.346972656250017, 60.419091796874994],
              [6.1533203125, 60.346240234375],
              [6.101757812500011, 60.29013671875],
              [5.967382812500006, 60.20556640625],
              [5.904394531250006, 60.150634765625],
              [5.8765625, 60.07001953125],
              [5.800878906250006, 60.026220703125],
              [5.698828125, 60.010009765625],
              [5.557031250000023, 59.907763671875],
              [5.494531250000023, 59.825585937499994],
              [5.353417968750023, 59.760107421875],
              [5.263867187500011, 59.709765625],
              [5.234472656250006, 59.691796875],
              [5.18603515625, 59.64228515625],
              [5.145800781250017, 59.638818359374994],
              [5.110742187500023, 59.667822265625],
              [5.104882812500023, 59.731689453125],
              [5.119238281250006, 59.83369140625],
              [5.187109375, 59.907080078125],
              [5.219531250000017, 59.978759765625],
              [5.174414062500006, 60.045703125],
              [5.205664062500006, 60.087939453125],
              [5.265429687500017, 60.086474609375],
              [5.37646484375, 60.067236328125],
              [5.494531250000023, 60.0703125],
              [5.688574218750006, 60.123193359374994],
              [5.657617187500023, 60.1541015625],
              [5.573828125, 60.15849609375],
              [5.417382812500023, 60.1541015625],
              [5.285839843750011, 60.205712890624994],
              [5.18359375, 60.3083984375],
              [5.137109375000023, 60.44560546875],
              [5.168164062500011, 60.484814453125],
              [5.546484375, 60.624560546875],
              [5.648339843750023, 60.68798828125],
              [5.58935546875, 60.694287109375],
              [5.447363281250006, 60.617333984374994],
              [5.244042968750023, 60.569580078125],
              [5.115820312500006, 60.635986328125],
              [5.049121093750017, 60.70751953125],
              [5.0107421875, 60.858544921874994],
              [5.024609375000011, 60.9361328125],
              [5.008593750000017, 61.03818359375],
              [5.095410156250011, 61.071337890625],
              [5.192480468750006, 61.0537109375],
              [5.288183593750006, 61.04716796875],
              [5.505273437500023, 61.056103515625],
              [5.983984375, 61.117333984374994],
              [6.292578125, 61.080957031249994],
              [6.41796875, 61.08427734375],
              [6.60986328125, 61.13701171875],
              [6.77783203125, 61.142431640625],
              [6.903417968750006, 61.1021484375],
              [6.972070312500023, 61.05595703125],
              [6.980566406250006, 60.994140625],
              [7.038671875, 60.9529296875],
              [7.077929687500017, 60.96630859375],
              [7.046679687500017, 61.015283203124994],
              [7.040136718750006, 61.091162109375],
              [7.545019531250006, 61.177148437499994],
              [7.6044921875, 61.210546875],
              [7.40390625, 61.22216796875],
              [7.346582031250023, 61.3005859375],
              [7.452539062500023, 61.41923828125],
              [7.442578125000011, 61.434619140625],
              [7.331152343750006, 61.372021484375],
              [7.276269531250023, 61.283935546875],
              [7.298046875000011, 61.213623046875],
              [7.275976562500006, 61.18095703125],
              [7.173535156250011, 61.165966796875],
              [6.942578125000011, 61.160546875],
              [6.794335937500023, 61.190380859375],
              [6.657031250000017, 61.206591796875],
              [6.610253906250023, 61.2291015625],
              [6.625878906250023, 61.279296875],
              [6.599902343750017, 61.2896484375],
              [6.543066406250006, 61.24453125],
              [6.492578125000023, 61.15458984375],
              [6.383496093750011, 61.13388671875],
              [6.08251953125, 61.16728515625],
              [5.646777343750017, 61.147607421874994],
              [5.451269531250006, 61.10234375],
              [5.324609375000023, 61.108251953125],
              [5.106738281250017, 61.187548828125],
              [5.021679687500011, 61.2505859375],
              [4.989941406250011, 61.377685546875],
              [5.002734375000017, 61.43359375],
              [5.172460937500006, 61.45712890625],
              [5.25830078125, 61.45546875],
              [5.338671875000017, 61.485498046874994],
              [5.267578125, 61.505029296874994],
              [5.167578125, 61.543359375],
              [5.099414062500017, 61.620166015625],
              [4.996679687500006, 61.64521484375],
              [4.927832031250006, 61.710693359375],
              [4.910351562500011, 61.8095703125],
              [4.930078125000023, 61.878320312499994],
              [4.985058593750011, 61.900439453125],
              [5.116992187500017, 61.885400390624994],
              [5.46533203125, 61.896923828125],
              [5.793261718750017, 61.827099609375],
              [6.015820312500011, 61.7875],
              [6.466699218750023, 61.807421875],
              [6.730761718750017, 61.869775390624994],
              [6.682324218750011, 61.88701171875],
              [6.395898437500023, 61.850976562499994],
              [6.131152343750017, 61.852441406249994],
              [5.664453125000023, 61.922900390625],
              [5.473046875000023, 61.94560546875],
              [5.266894531250017, 61.935595703125],
              [5.159570312500023, 61.956982421875],
              [5.096484375000017, 62.02666015625],
              [5.143164062500006, 62.159912109375],
              [5.240917968750011, 62.188671875],
              [5.293847656250023, 62.15390625],
              [5.357714843750017, 62.151708984375],
              [5.42236328125, 62.207373046875],
              [5.484277343750023, 62.239111328125],
              [5.533300781250006, 62.310888671875],
              [5.718164062500023, 62.37890625],
              [5.796289062500023, 62.384667968749994],
              [5.908300781250006, 62.416015625],
              [5.979785156250017, 62.407128906249994],
              [6.025585937500011, 62.37568359375],
              [6.08349609375, 62.349609375],
              [6.208984375, 62.352783203125],
              [6.580078125, 62.407275390625],
              [6.620019531250023, 62.423291015625],
              [6.6923828125, 62.46806640625],
              [6.457128906250006, 62.448095703125],
              [6.26171875, 62.41630859375],
              [6.136132812500023, 62.407470703125],
              [6.118457031250017, 62.447167968749994],
              [6.164746093750011, 62.482421875],
              [6.2375, 62.519921875],
              [6.272851562500023, 62.583837890625],
              [6.352929687500023, 62.6111328125],
              [6.439453125, 62.60966796875],
              [6.618359375000011, 62.6212890625],
              [6.74462890625, 62.637890625],
              [6.961132812500011, 62.6267578125],
              [7.283789062500006, 62.602294921875],
              [7.491796875, 62.542822265625],
              [7.570117187500017, 62.548193359375],
              [7.653125, 62.564013671875],
              [7.690722656250017, 62.585595703124994],
              [7.527441406250006, 62.610302734375],
              [7.518164062500006, 62.6455078125],
              [7.538378906250017, 62.6720703125],
              [7.8046875, 62.72099609375],
              [8.095507812500017, 62.731835937499994],
              [8.045507812500006, 62.771240234375],
              [7.408398437500011, 62.711767578125],
              [7.242089843750023, 62.75234375],
              [7.11083984375, 62.752001953125],
              [7.02490234375, 62.72880859375],
              [6.779980468750011, 62.700732421875],
              [6.734960937500006, 62.720703125],
              [6.781542968750017, 62.7896484375],
              [6.92822265625, 62.902734375],
              [6.9404296875, 62.93046875],
              [7.008496093750011, 62.957666015624994],
              [7.389062500000023, 63.023291015625],
              [7.571875, 63.099511718749994],
              [7.654296875, 63.1091796875],
              [7.736035156250011, 63.103857421875],
              [7.8603515625, 63.11279296875],
              [8.1005859375, 63.090966796874994],
              [8.211132812500011, 62.995507812499994],
              [8.310546875, 62.96552734375],
              [8.623144531250006, 62.846240234375],
              [8.609179687500017, 62.88056640625],
              [8.338574218750011, 63.0421875],
              [8.235156250000017, 63.082177734374994],
              [8.158007812500017, 63.161523437499994],
              [8.184472656250023, 63.2365234375],
              [8.271484375, 63.286572265625],
              [8.580175781250006, 63.313378906249994],
              [8.635546875000017, 63.342333984375],
              [8.641015625000023, 63.39208984375],
              [8.59375, 63.426123046875],
              [8.480175781250011, 63.424169921875],
              [8.386523437500017, 63.445263671875],
              [8.360742187500023, 63.498876953125],
              [8.398144531250011, 63.535107421875],
              [8.576171875, 63.601171875],
              [8.673632812500017, 63.622607421875],
              [8.842382812500006, 63.645898437499994],
              [9.135839843750006, 63.593652343749994],
              [9.158105468750023, 63.566259765625],
              [9.075878906250011, 63.500390625],
              [9.084179687500011, 63.463427734375],
              [9.156054687500017, 63.459326171875],
              [9.323632812500023, 63.570361328125],
              [9.520703125000011, 63.585693359375],
              [9.602246093750011, 63.6095703125],
              [9.696875, 63.624560546875],
              [9.832226562500011, 63.524169921875],
              [9.891503906250023, 63.492041015625],
              [9.93603515625, 63.478857421875],
              [9.979199218750011, 63.395263671875],
              [10.02099609375, 63.3908203125],
              [10.08056640625, 63.43271484375],
              [10.188574218750006, 63.45478515625],
              [10.340039062500011, 63.4693359375],
              [10.590917968750006, 63.447216796875],
              [10.704492187500023, 63.46357421875],
              [10.760156250000023, 63.461279296875],
              [10.706738281250011, 63.536328125],
              [10.673632812500017, 63.558007812499994],
              [10.725292968750011, 63.625],
              [10.779199218750023, 63.651171875],
              [10.952539062500023, 63.698193359375],
              [11.117871093750011, 63.719189453125],
              [11.225781250000011, 63.763818359374994],
              [11.370703125, 63.804833984374994],
              [11.347949218750017, 63.8376953125],
              [11.3076171875, 63.875732421875],
              [11.2138671875, 63.878125],
              [11.175585937500017, 63.898876953125],
              [11.294628906250011, 63.948193359375],
              [11.457617187500006, 64.002978515625],
              [11.42919921875, 64.02451171875],
              [11.306640625, 64.048876953125],
              [11.213574218750011, 64.030517578125],
              [11.0751953125, 63.988134765625],
              [10.914257812500011, 63.92109375],
              [10.966699218750023, 63.9015625],
              [11.047265625000023, 63.84521484375],
              [10.934863281250017, 63.77021484375],
              [10.339160156250017, 63.571044921875],
              [10.055078125000023, 63.5126953125],
              [9.924023437500011, 63.52177734375],
              [9.892773437500011, 63.576220703125],
              [9.832324218750017, 63.61650390625],
              [9.767480468750023, 63.69951171875],
              [9.6572265625, 63.697314453125],
              [9.594628906250023, 63.678955078125],
              [9.567285156250023, 63.70615234375],
              [9.61474609375, 63.794824218749994],
              [9.7080078125, 63.864892578124994],
              [9.864453125000011, 63.917822265625],
              [9.939453125, 63.98173828125],
              [10.009960937500011, 64.083154296875],
              [10.236230468750023, 64.179638671875],
              [10.565625, 64.418310546875],
              [10.833984375, 64.494482421875],
              [10.932324218750011, 64.577734375],
              [11.090429687500006, 64.61455078125],
              [11.225390625000017, 64.6794921875],
              [11.331347656250017, 64.6859375],
              [11.523828125000023, 64.744384765625],
              [11.632910156250006, 64.813916015625],
              [11.561718750000011, 64.81826171875],
              [11.392480468750023, 64.772998046875],
              [11.296777343750023, 64.75478515625],
              [11.303515625000017, 64.82939453125],
              [11.349902343750017, 64.905908203125],
              [11.489355468750006, 64.975830078125],
              [12.15966796875, 65.178955078125],
              [12.2265625, 65.145361328125],
              [12.306542968750023, 65.085986328125],
              [12.508398437500006, 65.0994140625],
              [12.738378906250006, 65.214404296875],
              [12.91552734375, 65.3392578125],
              [12.81982421875, 65.31748046875],
              [12.71533203125, 65.266357421875],
              [12.51171875, 65.1953125],
              [12.417578125, 65.18408203125],
              [12.363867187500006, 65.193310546875],
              [12.333984375, 65.24072265625],
              [12.263378906250011, 65.256103515625],
              [12.199609375000023, 65.245458984375],
              [12.133886718750006, 65.279150390625],
              [12.122167968750006, 65.362353515625],
              [12.20625, 65.48623046875],
              [12.272851562500023, 65.5681640625],
              [12.344824218750006, 65.63017578125],
              [12.627734375000017, 65.80615234375],
              [12.688867187500023, 65.902197265625],
              [12.816796875000023, 65.952880859375],
              [12.983007812500006, 65.9416015625],
              [13.033105468750023, 65.95625],
              [12.97607421875, 66.019189453125],
              [12.794921875, 66.069091796875],
              [12.783789062500006, 66.100439453125],
              [13.387109375000023, 66.182763671875],
              [13.674414062500006, 66.17998046875],
              [13.759667968750023, 66.221044921875],
              [13.915820312500017, 66.24736328125],
              [14.0341796875, 66.29755859375],
              [13.97314453125, 66.3197265625],
              [13.681347656250011, 66.273583984375],
              [13.498925781250023, 66.251904296875],
              [13.416406250000023, 66.252587890625],
              [13.35205078125, 66.23671875],
              [13.118847656250011, 66.2306640625],
              [13.068164062500017, 66.430810546875],
              [13.104687500000011, 66.539404296875],
              [13.191601562500011, 66.537158203125],
              [13.21142578125, 66.6408203125],
              [13.311816406250017, 66.70185546875],
              [13.450390625000011, 66.71552734375],
              [13.520214843750011, 66.741650390625],
              [13.62109375, 66.79482421875],
              [13.787988281250023, 66.782470703125],
              [13.95947265625, 66.7943359375],
              [13.9169921875, 66.819384765625],
              [13.7041015625, 66.85166015625],
              [13.651562500000011, 66.907080078125],
              [13.726660156250006, 66.938037109375],
              [13.808398437500017, 66.960791015625],
              [13.880175781250017, 66.964892578125],
              [14.022363281250023, 67.073095703125],
              [14.108789062500023, 67.11923828125],
              [14.20556640625, 67.11123046875],
              [14.34033203125, 67.158935546875],
              [14.47265625, 67.14267578125],
              [14.600683593750006, 67.173876953125],
              [14.775585937500011, 67.194482421875],
              [15.415722656250011, 67.20244140625],
              [15.434765625000011, 67.2466796875],
              [15.300097656250017, 67.25693359375],
              [14.824414062500011, 67.268310546875],
              [14.58154296875, 67.267431640625],
              [14.479296875000017, 67.25595703125],
              [14.441699218750017, 67.27138671875],
              [14.448339843750006, 67.2978515625],
              [14.53662109375, 67.33974609375],
              [14.578515625000023, 67.38603515625],
              [14.754980468750006, 67.4990234375],
              [14.9619140625, 67.574267578125],
              [15.120507812500023, 67.555029296875],
              [15.289160156250006, 67.483154296875],
              [15.409375, 67.474169921875],
              [15.46533203125, 67.450927734375],
              [15.552929687500011, 67.3517578125],
              [15.594433593750011, 67.34853515625],
              [15.57568359375, 67.44384765625],
              [15.691503906250006, 67.52138671875],
              [15.661328125000011, 67.542822265625],
              [15.4873046875, 67.514794921875],
              [15.35400390625, 67.5439453125],
              [15.248730468750011, 67.6021484375],
              [15.218652343750023, 67.65537109375],
              [15.284082031250023, 67.707958984375],
              [15.345800781250006, 67.734423828125],
              [15.303906250000011, 67.765283203125],
              [15.040820312500017, 67.682568359375],
              [14.854687500000011, 67.663330078125],
              [14.781347656250006, 67.67490234375],
              [14.821093750000017, 67.749853515625],
              [14.798925781250006, 67.809326171875],
              [15.0484375, 67.95576171875],
              [15.13427734375, 67.972705078125],
              [15.2744140625, 67.9609375],
              [15.40087890625, 67.91962890625],
              [15.506640625000017, 67.926220703125],
              [15.621386718750017, 67.948291015625],
              [15.605761718750017, 67.987890625],
              [15.35693359375, 68.00361328125],
              [15.292871093750023, 68.036474609375],
              [15.316015625, 68.06875],
              [15.48681640625, 68.10283203125],
              [15.656640625000023, 68.16435546875],
              [15.851269531250011, 68.182177734375],
              [16.007910156250006, 68.2287109375],
              [16.0380859375, 68.2181640625],
              [16.064550781250006, 68.19990234375],
              [16.12080078125001, 68.02734375],
              [16.2607421875, 67.886572265625],
              [16.312304687500017, 67.8814453125],
              [16.258593750000017, 68.001220703125],
              [16.308691406250006, 68.03564453125],
              [16.372167968750006, 68.06181640625],
              [16.391992187500023, 68.0916015625],
              [16.319238281250023, 68.1017578125],
              [16.259765625, 68.14453125],
              [16.1748046875, 68.28125],
              [16.20380859375001, 68.316748046875],
              [16.38789062500001, 68.38955078125],
              [16.61884765625001, 68.406298828125],
              [16.86494140625001, 68.3552734375],
              [16.95136718750001, 68.3546875],
              [17.094042968750017, 68.368408203125],
              [17.33613281250001, 68.4103515625],
              [17.478515625, 68.426318359375],
              [17.552832031250006, 68.42626953125],
              [17.571191406250023, 68.4474609375],
              [17.502343750000023, 68.461083984375],
              [17.48017578125001, 68.47431640625],
              [17.426171875000023, 68.48193359375],
              [17.20234375000001, 68.45927734375],
              [16.584863281250023, 68.466455078125],
              [16.525292968750023, 68.490673828125],
              [16.51435546875001, 68.532568359375],
              [16.579882812500017, 68.59267578125],
              [16.65185546875, 68.62578125],
              [16.884667968750023, 68.685400390625],
              [17.131152343750017, 68.69345703125],
              [17.39082031250001, 68.799365234375],
              [17.490039062500017, 68.878759765625],
              [17.546289062500023, 69.001123046875],
              [17.70458984375, 69.100048828125],
              [18.101464843750023, 69.156298828125],
              [18.117480468750017, 69.181201171875],
              [18.07539062500001, 69.2326171875],
              [18.078710937500006, 69.325244140625],
              [18.1875, 69.43310546875],
              [18.259765625, 69.47060546875],
              [18.29316406250001, 69.47509765625],
              [18.378710937500017, 69.43984375],
              [18.48261718750001, 69.36484375],
              [18.6455078125, 69.321875],
              [18.858984375, 69.314453125],
              [18.915917968750023, 69.335595703125],
              [18.75, 69.37841796875],
              [18.624414062500023, 69.434375],
              [18.61445312500001, 69.490576171875],
              [18.67402343750001, 69.520361328125],
              [18.7666015625, 69.517041015625],
              [18.8828125, 69.52333984375],
              [18.991113281250023, 69.5611328125],
              [19.0068359375, 69.5876953125],
              [19.011328125, 69.62373046875],
              [19.038378906250017, 69.660400390625],
              [19.197265625, 69.7478515625],
              [19.68701171875, 69.804736328125],
              [19.722460937500017, 69.781640625],
              [19.69599609375001, 69.612939453125],
              [19.639746093750006, 69.50380859375],
              [19.641503906250023, 69.4240234375],
              [19.73681640625, 69.50380859375],
              [19.864648437500023, 69.722119140625],
              [19.960546875, 69.824609375],
              [20.068945312500006, 69.883447265625],
              [20.146386718750023, 69.896728515625],
              [20.223046875000023, 69.927197265625],
              [20.32421875, 69.9453125],
              [20.35517578125001, 69.921923828125],
              [20.38720703125, 69.867626953125],
              [20.33271484375001, 69.676953125],
              [20.338183593750017, 69.616650390625],
              [20.277148437500017, 69.53583984375],
              [20.04375, 69.3556640625],
              [20.054492187500017, 69.332666015625],
              [20.107226562500017, 69.3412109375],
              [20.197656250000023, 69.370947265625],
              [20.486718750000023, 69.54208984375],
              [20.73945312500001, 69.5205078125],
              [20.742578125000023, 69.534521484375],
              [20.661523437500023, 69.584716796875],
              [20.5625, 69.6328125],
              [20.53271484375, 69.692333984375],
              [20.545996093750006, 69.85107421875],
              [20.6220703125, 69.913916015625],
              [20.84033203125, 69.90732421875],
              [20.971093750000023, 69.916015625],
              [21.032128906250023, 69.887451171875],
              [21.1630859375, 69.889501953125],
              [21.253710937500017, 70.00322265625],
              [21.432910156250017, 70.01318359375],
              [21.590234375000023, 69.938037109375],
              [21.779589843750017, 69.887451171875],
              [21.931738281250006, 69.814697265625],
              [21.974707031250006, 69.8345703125],
              [21.892578125, 70.004248046875],
              [21.802734375, 70.066064453125],
              [21.607812500000023, 70.098193359375],
              [21.400390625, 70.174462890625],
              [21.346289062500006, 70.208251953125],
              [21.355761718750017, 70.2333984375],
              [21.53876953125001, 70.257666015625],
              [21.7802734375, 70.2298828125],
              [21.995507812500023, 70.293359375],
              [22.05439453125001, 70.2759765625],
              [22.21943359375001, 70.3091796875],
              [22.32197265625001, 70.264501953125],
              [22.384765625, 70.277734375],
              [22.421191406250017, 70.33759765625],
              [22.6845703125, 70.374755859375],
              [22.851660156250006, 70.340478515625],
              [22.941210937500017, 70.30498046875],
              [22.982812500000023, 70.236767578125],
              [23.046484375, 70.10185546875],
              [23.17695312500001, 70.029052734375],
              [23.257910156250006, 69.993310546875],
              [23.353906250000023, 69.9833984375],
              [23.400195312500017, 70.019775390625],
              [23.31025390625001, 70.06357421875],
              [23.286035156250023, 70.104833984375],
              [23.3291015625, 70.2072265625],
              [23.37939453125, 70.2474609375],
              [23.661230468750006, 70.399755859375],
              [23.89716796875001, 70.478759765625],
              [24.038476562500023, 70.4853515625],
              [24.285546875000023, 70.66240234375],
              [24.355566406250006, 70.694580078125],
              [24.420019531250006, 70.702001953125],
              [24.40351562500001, 70.7453125],
              [24.268164062500006, 70.772705078125],
              [24.263476562500017, 70.826318359375],
              [24.441796875000023, 70.891552734375],
              [24.658007812500017, 71.001025390625],
              [24.764746093750006, 71.008447265625],
              [24.831640625, 70.97802734375],
              [25.04218750000001, 70.92861328125],
              [25.171191406250017, 70.872021484375],
              [25.2646484375, 70.843505859375],
              [25.32539062500001, 70.8494140625],
              [25.375585937500006, 70.891943359375],
              [25.435937500000023, 70.911865234375],
              [25.56982421875, 70.90068359375],
              [25.649707031250017, 70.87333984375],
              [25.7119140625, 70.8697265625],
              [25.768164062500006, 70.853173828125],
              [25.78144531250001, 70.816796875],
              [25.665625, 70.7771484375],
              [25.46826171875, 70.67197265625],
              [25.273535156250006, 70.552392578125],
              [25.209277343750017, 70.489404296875],
              [25.146386718750023, 70.3240234375],
              [24.994238281250006, 70.218212890625],
              [24.982714843750017, 70.143994140625],
              [25.043847656250023, 70.109033203125],
              [25.211816406250023, 70.136474609375],
              [25.418847656250023, 70.235498046875],
              [25.470507812500017, 70.340576171875],
              [25.988085937500017, 70.625390625],
              [26.230859375000023, 70.7826171875],
              [26.506933593750006, 70.91279296875],
              [26.66132812500001, 70.93974609375],
              [26.733984375, 70.853564453125],
              [26.67548828125001, 70.740966796875],
              [26.558203125, 70.669140625],
              [26.644628906250006, 70.63623046875],
              [26.628125, 70.55087890625],
              [26.601171875, 70.503466796875],
              [26.583984375, 70.45380859375],
              [26.585058593750006, 70.410009765625],
              [26.666113281250006, 70.4216796875],
              [26.989355468750006, 70.511376953125],
              [27.0712890625, 70.608447265625],
              [27.147265625000017, 70.681201171875],
              [27.183691406250006, 70.74404296875],
              [27.309375, 70.803564453125],
              [27.546484375, 70.80400390625],
              [27.5556640625, 70.827392578125],
              [27.26904296875, 70.910009765625],
              [27.235253906250023, 70.947216796875],
              [27.331640625, 70.996728515625],
              [27.597070312500023, 71.09130859375],
              [27.733496093750006, 71.080859375],
              [27.815039062500006, 71.059375],
              [28.141699218750006, 71.043017578125],
              [28.39228515625001, 70.97529296875],
              [28.382714843750023, 70.86943359375],
              [28.32685546875001, 70.8251953125],
              [28.271875, 70.79794921875],
              [27.950976562500017, 70.717578125],
              [27.898046875, 70.6779296875],
              [27.998828125000017, 70.6642578125],
              [28.215625, 70.704345703125],
              [28.271777343750017, 70.66796875],
              [28.202734375, 70.576904296875],
              [28.191015625, 70.440185546875],
              [28.166015625, 70.360400390625],
              [28.166015625, 70.287646484375],
              [28.19296875, 70.248583984375],
              [28.280078125000017, 70.40341796875],
              [28.309863281250017, 70.44306640625],
              [28.437304687500017, 70.5013671875],
              [28.484765625000023, 70.618798828125],
              [28.609375, 70.75966796875],
              [28.749804687500017, 70.84150390625],
              [28.83154296875, 70.86396484375],
              [29.102343750000017, 70.8607421875],
              [29.218554687500017, 70.829931640625],
              [29.321093750000017, 70.761474609375],
              [29.39765625000001, 70.734130859375],
              [29.639062500000023, 70.705029296875],
              [29.721972656250017, 70.6685546875],
              [29.7375, 70.646826171875],
              [29.796484375, 70.642529296875],
              [29.959375, 70.694384765625],
              [30.06513671875001, 70.702978515625],
              [30.237695312500023, 70.62216796875],
              [30.203027343750023, 70.5623046875],
              [30.213183593750017, 70.543310546875],
              [30.42207031250001, 70.54716796875],
              [30.59589843750001, 70.523681640625],
              [30.926367187500006, 70.401123046875],
              [30.96064453125001, 70.34384765625],
              [30.944140625000017, 70.2744140625],
              [30.46894531250001, 70.1978515625],
              [30.262988281250017, 70.12470703125],
              [29.925878906250006, 70.096484375],
              [28.781152343750023, 70.14541015625],
              [28.804296875, 70.092529296875],
              [29.601367187500017, 69.9767578125],
              [29.646875, 69.943701171875],
              [29.621386718750017, 69.874072265625],
              [29.620996093750023, 69.818212890625],
              [29.63593750000001, 69.780126953125],
              [29.694628906250017, 69.744580078125],
              [29.792089843750006, 69.727880859375],
              [29.990332031250006, 69.736669921875],
              [30.088281250000023, 69.717578125],
              [30.155175781250023, 69.745947265625],
              [30.180078125000023, 69.841162109375],
              [30.237597656250017, 69.86220703125],
              [30.34882812500001, 69.8345703125],
              [30.397265625000017, 69.7328125],
              [30.428320312500006, 69.722265625],
              [30.484375, 69.794873046875],
              [30.594531250000017, 69.7896484375],
              [30.714453125, 69.795703125],
              [30.869726562500006, 69.783447265625],
              [30.924121093750017, 69.6517578125],
              [30.922460937500006, 69.605810546875],
              [30.89667968750001, 69.56123046875],
              [30.860742187500023, 69.538427734375],
              [30.788867187500017, 69.528515625],
              [30.61542968750001, 69.532568359375],
              [30.379687500000017, 69.584716796875],
              [30.2275390625, 69.6337890625],
              [30.18017578125, 69.63583984375],
              [30.159765625, 69.6298828125],
              [30.19648437500001, 69.58056640625],
              [30.18671875000001, 69.5427734375],
              [30.16376953125001, 69.501611328125],
              [30.1318359375, 69.4642578125],
              [30.087304687500023, 69.432861328125],
              [29.994042968750023, 69.39248046875],
              [29.83271484375001, 69.36044921875],
              [29.38828125, 69.29814453125],
              [29.35302734375, 69.27060546875],
              [29.2099609375, 69.097021484375],
              [29.1708984375, 69.071533203125],
              [29.118554687500023, 69.049951171875],
              [28.9658203125, 69.02197265625],
              [28.891894531250017, 69.060595703125],
              [28.832617187500006, 69.118994140625],
              [28.846289062500006, 69.176904296875],
              [29.02490234375, 69.28798828125],
              [29.191796875000023, 69.36669921875],
              [29.238867187500006, 69.3939453125],
              [29.333398437500023, 69.472998046875],
              [29.1416015625, 69.671435546875],
              [28.800390625, 69.731494140625],
              [28.41171875, 69.82275390625],
              [28.269140625, 69.871435546875],
              [28.047265625000023, 69.9716796875],
              [27.889941406250017, 70.061669921875],
              [27.747851562500017, 70.06484375],
              [27.591699218750023, 70.042236328125],
              [27.348046875000023, 69.96005859375],
              [27.205664062500006, 69.918701171875],
              [27.127539062500006, 69.906494140625],
              [27.108691406250017, 69.9046875],
              [26.93427734375001, 69.928125],
              [26.740234375, 69.933056640625],
              [26.584277343750017, 69.926318359375],
              [26.525390625, 69.9150390625],
              [26.308203125, 69.78193359375],
              [26.156152343750023, 69.714697265625],
              [26.07246093750001, 69.691552734375],
              [26.011523437500017, 69.65263671875],
              [25.961523437500006, 69.588623046875],
              [25.850195312500006, 69.36650390625],
              [25.7671875, 69.282666015625],
              [25.748632812500006, 69.2314453125],
              [25.768164062500006, 69.076123046875],
              [25.748339843750017, 68.99013671875],
              [25.64667968750001, 68.919140625],
              [25.575292968750006, 68.887158203125],
              [25.480859375000023, 68.880615234375],
              [25.35712890625001, 68.862451171875],
              [25.249121093750006, 68.821337890625],
              [25.1728515625, 68.765283203125],
              [25.0869140625, 68.639599609375],
              [24.94140625, 68.59326171875],
              [24.80244140625001, 68.606494140625],
              [24.703222656250006, 68.65283203125],
              [24.490527343750017, 68.688671875],
              [24.33203125, 68.7115234375],
              [24.154101562500017, 68.760888671875],
              [23.997363281250017, 68.7984375],
              [23.85400390625, 68.805908203125],
              [23.772558593750006, 68.7583984375],
              [23.70703125, 68.7138671875],
              [23.4625, 68.67763671875],
              [23.324023437500017, 68.648974609375],
              [23.144335937500017, 68.642578125],
              [23.071679687500023, 68.674365234375],
              [22.81103515625, 68.6953125],
              [22.50068359375001, 68.72021484375],
              [22.410937500000017, 68.719873046875],
              [22.382910156250006, 68.776611328125],
              [22.300390625, 68.855859375],
              [22.0796875, 68.9927734375],
              [21.98945312500001, 69.04111328125],
              [21.819726562500023, 69.1544921875],
              [21.62177734375001, 69.270703125],
              [21.59375, 69.273583984375],
              [21.461230468750017, 69.277490234375],
              [21.26679687500001, 69.273681640625],
              [21.14375, 69.247265625],
              [21.06611328125001, 69.214111328125],
              [21.052636718750023, 69.186572265625],
              [21.127832031250023, 69.080810546875],
              [21.1044921875, 69.054443359375],
              [21.065722656250017, 69.041748046875],
              [20.889257812500006, 69.071435546875],
              [20.675878906250006, 69.069482421875],
              [20.622167968750006, 69.036865234375],
            ],
          ],
          [
            [
              [4.958691406250011, 61.0845703125],
              [4.8701171875, 61.071923828124994],
              [4.799023437500011, 61.08271484375],
              [4.824414062500011, 61.17822265625],
              [4.861621093750017, 61.19384765625],
              [4.915429687500023, 61.199365234374994],
              [4.973242187500006, 61.1482421875],
              [4.958691406250011, 61.0845703125],
            ],
          ],
          [
            [
              [5.085839843750023, 60.307568359375],
              [5.089062500000011, 60.18876953125],
              [4.996972656250023, 60.19775390625],
              [4.95556640625, 60.243310546874994],
              [4.943554687500011, 60.272412109375],
              [4.95078125, 60.341162109375],
              [4.930078125000023, 60.412060546875],
              [4.957226562500011, 60.447265625],
              [4.990625, 60.452050781249994],
              [5.050195312500023, 60.38896484375],
              [5.085839843750023, 60.307568359375],
            ],
          ],
          [
            [
              [29.956152343750006, 69.79677734375],
              [29.766210937500006, 69.767529296875],
              [29.744238281250006, 69.7916015625],
              [29.785937500000017, 69.829052734375],
              [29.835839843750023, 69.90556640625],
              [29.913964843750023, 69.90244140625],
              [29.992968750000017, 69.8732421875],
              [30.05517578125, 69.83837890625],
              [29.956152343750006, 69.79677734375],
            ],
          ],
          [
            [
              [11.967968750000011, 65.626513671875],
              [11.90185546875, 65.595703125],
              [11.7783203125, 65.604541015625],
              [11.76513671875, 65.63095703125],
              [11.800390625, 65.68388671875],
              [11.875390625000023, 65.705908203125],
              [11.972363281250011, 65.7015625],
              [12.003222656250017, 65.679443359375],
              [11.967968750000011, 65.626513671875],
            ],
          ],
          [
            [
              [8.470800781250006, 63.667138671874994],
              [8.356152343750011, 63.664794921875],
              [8.287109375, 63.687158203124994],
              [8.451269531250006, 63.731835937499994],
              [8.708886718750023, 63.774316406249994],
              [8.7333984375, 63.801318359375],
              [8.7646484375, 63.804638671875],
              [8.809179687500006, 63.771435546875],
              [8.814843750000023, 63.725976562499994],
              [8.786523437500023, 63.703466796875],
              [8.470800781250006, 63.667138671874994],
            ],
          ],
          [
            [
              [8.102734375000011, 63.33759765625],
              [8.004687500000017, 63.3369140625],
              [7.88828125, 63.35234375],
              [7.815332031250023, 63.38505859375],
              [7.804003906250017, 63.413916015625],
              [7.938378906250023, 63.4498046875],
              [8.073535156250017, 63.47080078125],
              [8.136132812500023, 63.43134765625],
              [8.140917968750017, 63.36640625],
              [8.102734375000011, 63.33759765625],
            ],
          ],
          [
            [
              [23.440527343750006, 70.815771484375],
              [23.4208984375, 70.784423828125],
              [23.387109375000023, 70.75390625],
              [23.30517578125, 70.7216796875],
              [23.068164062500017, 70.594091796875],
              [22.92890625000001, 70.57353515625],
              [22.884765625, 70.553515625],
              [22.8291015625, 70.541552734375],
              [22.656054687500017, 70.559033203125],
              [22.605371093750023, 70.533154296875],
              [22.557519531250023, 70.515869140625],
              [22.432226562500006, 70.5091796875],
              [22.358691406250017, 70.514794921875],
              [22.16875, 70.562109375],
              [22.055761718750006, 70.613330078125],
              [21.994531250000023, 70.65712890625],
              [22.170019531250006, 70.656298828125],
              [22.23261718750001, 70.66689453125],
              [22.35029296875001, 70.657666015625],
              [22.420996093750006, 70.702587890625],
              [22.570703125000023, 70.69716796875],
              [22.85810546875001, 70.72841796875],
              [22.96357421875001, 70.710986328125],
              [23.2046875, 70.815478515625],
              [23.280175781250023, 70.812744140625],
              [23.395605468750006, 70.842578125],
              [23.440527343750006, 70.815771484375],
            ],
          ],
          [
            [
              [25.586328125000023, 71.14208984375],
              [25.853515625, 71.103857421875],
              [25.94501953125001, 71.104638671875],
              [26.07763671875, 71.033154296875],
              [26.146875, 71.039501953125],
              [26.1337890625, 70.99580078125],
              [25.99970703125001, 70.97509765625],
              [25.791308593750017, 70.9625],
              [25.760156250000023, 70.95380859375],
              [25.58203125, 70.960791015625],
              [25.48203125, 71.019580078125],
              [25.31494140625, 71.034130859375],
              [25.315234375000017, 71.052978515625],
              [25.4234375, 71.097412109375],
              [25.586328125000023, 71.14208984375],
            ],
          ],
          [
            [
              [23.615332031250006, 70.54931640625],
              [23.63398437500001, 70.5025390625],
              [23.641015625000023, 70.46396484375],
              [23.547753906250023, 70.408154296875],
              [23.332812500000017, 70.3349609375],
              [23.345117187500023, 70.315283203125],
              [23.27070312500001, 70.296484375],
              [23.1591796875, 70.2826171875],
              [23.10029296875001, 70.29609375],
              [23.1083984375, 70.358837890625],
              [23.090625, 70.37763671875],
              [23.005957031250006, 70.352783203125],
              [22.917871093750023, 70.38466796875],
              [22.917773437500017, 70.416748046875],
              [22.941015625, 70.444580078125],
              [23.0224609375, 70.4869140625],
              [23.15839843750001, 70.516064453125],
              [23.248046875, 70.505126953125],
              [23.546679687500017, 70.61708984375],
              [23.578906250000017, 70.59365234375],
              [23.615332031250006, 70.54931640625],
            ],
          ],
          [
            [
              [24.017578125, 70.5673828125],
              [23.8271484375, 70.527490234375],
              [23.716601562500017, 70.561865234375],
              [23.67011718750001, 70.5970703125],
              [23.66328125000001, 70.675244140625],
              [23.68916015625001, 70.722802734375],
              [23.778417968750006, 70.74736328125],
              [23.836523437500006, 70.72939453125],
              [23.85205078125, 70.71435546875],
              [23.956445312500023, 70.699609375],
              [24.07832031250001, 70.6505859375],
              [24.017578125, 70.5673828125],
            ],
          ],
          [
            [
              [13.872851562500017, 68.26533203125],
              [13.932324218750011, 68.2482421875],
              [14.087695312500017, 68.25322265625],
              [14.118847656250011, 68.246826171875],
              [14.096777343750006, 68.218603515625],
              [14.029296875, 68.187548828125],
              [13.8876953125, 68.168505859375],
              [13.824023437500017, 68.12109375],
              [13.778417968750006, 68.10498046875],
              [13.656152343750023, 68.10478515625],
              [13.583984375, 68.09384765625],
              [13.495214843750006, 68.05166015625],
              [13.424218750000023, 68.082763671875],
              [13.404394531250006, 68.060693359375],
              [13.391503906250023, 68.021240234375],
              [13.35205078125, 68.00966796875],
              [13.229394531250023, 67.995361328125],
              [13.199511718750017, 68.087255859375],
              [13.255957031250006, 68.12060546875],
              [13.300195312500023, 68.16044921875],
              [13.367968750000017, 68.166552734375],
              [13.4287109375, 68.163232421875],
              [13.537988281250023, 68.2490234375],
              [13.687695312500011, 68.273388671875],
              [13.784082031250023, 68.276123046875],
              [13.872851562500017, 68.26533203125],
            ],
          ],
          [
            [
              [12.971777343750006, 67.87412109375],
              [12.824023437500017, 67.821240234375],
              [12.8779296875, 67.9177734375],
              [12.957714843750011, 68.015478515625],
              [13.068066406250011, 68.071337890625],
              [13.122851562500017, 68.0494140625],
              [13.097753906250006, 68.002685546875],
              [13.098242187500006, 67.9564453125],
              [13.074609375000023, 67.9345703125],
              [12.971777343750006, 67.87412109375],
            ],
          ],
          [
            [
              [15.207128906250006, 68.943115234375],
              [15.337207031250017, 68.842431640625],
              [15.396582031250006, 68.78359375],
              [15.348437500000017, 68.672412109375],
              [15.222070312500023, 68.61630859375],
              [15.027050781250011, 68.60634765625],
              [14.890234375, 68.610986328125],
              [14.804003906250017, 68.63798828125],
              [14.793261718750017, 68.66826171875],
              [14.743457031250017, 68.677197265625],
              [14.612109375000017, 68.638330078125],
              [14.520800781250017, 68.633056640625],
              [14.404687500000023, 68.663232421875],
              [14.373437500000023, 68.71142578125],
              [14.496679687500006, 68.771875],
              [14.5537109375, 68.81884765625],
              [14.6904296875, 68.814697265625],
              [14.724609375, 68.80009765625],
              [14.801855468750006, 68.790966796875],
              [14.848828125000011, 68.84755859375],
              [14.837988281250006, 68.886669921875],
              [14.872363281250017, 68.9138671875],
              [15.0375, 68.894287109375],
              [15.037792968750011, 69.000537109375],
              [15.101855468750017, 69.0080078125],
              [15.128125, 69.003955078125],
              [15.175585937500017, 68.98154296875],
              [15.207128906250006, 68.943115234375],
            ],
          ],
          [
            [
              [19.767480468750023, 70.21669921875],
              [19.818359375, 70.20498046875],
              [19.86865234375, 70.212255859375],
              [19.910449218750017, 70.201904296875],
              [19.994140625, 70.149267578125],
              [20.084277343750017, 70.128564453125],
              [20.088476562500006, 70.10205078125],
              [20.005957031250006, 70.076220703125],
              [19.897265625000017, 70.06845703125],
              [19.780859375, 70.07744140625],
              [19.746679687500006, 70.110498046875],
              [19.710839843750023, 70.16533203125],
              [19.61347656250001, 70.219091796875],
              [19.599023437500023, 70.266162109375],
              [19.68378906250001, 70.273583984375],
              [19.767480468750023, 70.21669921875],
            ],
          ],
          [
            [
              [20.779199218750023, 70.08974609375],
              [20.72529296875001, 70.06650390625],
              [20.642578125, 70.05703125],
              [20.598046875000023, 70.071435546875],
              [20.53466796875, 70.080908203125],
              [20.464257812500023, 70.0765625],
              [20.405078125000017, 70.119140625],
              [20.41171875, 70.1548828125],
              [20.492773437500006, 70.2033203125],
              [20.654882812500006, 70.230859375],
              [20.786035156250023, 70.21953125],
              [20.819433593750006, 70.20546875],
              [20.779199218750023, 70.08974609375],
            ],
          ],
          [
            [
              [19.25507812500001, 70.06640625],
              [19.34375, 70.011962890625],
              [19.422265625000023, 70.0171875],
              [19.445898437500006, 70.037744140625],
              [19.49951171875, 70.047900390625],
              [19.607812500000023, 70.019140625],
              [19.59228515625, 69.970166015625],
              [19.4423828125, 69.9083984375],
              [19.334765625000017, 69.820263671875],
              [19.197070312500017, 69.7998046875],
              [19.130859375, 69.81044921875],
              [19.0078125, 69.7595703125],
              [18.9091796875, 69.706689453125],
              [18.806933593750017, 69.63984375],
              [18.800683593750023, 69.60537109375],
              [18.784765625, 69.57900390625],
              [18.410253906250006, 69.55283203125],
              [18.27412109375001, 69.535498046875],
              [18.1298828125, 69.557861328125],
              [18.0615234375, 69.602099609375],
              [18.08349609375, 69.626123046875],
              [18.227441406250023, 69.6357421875],
              [18.23203125, 69.6767578125],
              [18.268457031250023, 69.701806640625],
              [18.315039062500006, 69.715478515625],
              [18.34931640625001, 69.76787109375],
              [18.40625, 69.78154296875],
              [18.51240234375001, 69.76865234375],
              [18.583984375, 69.806591796875],
              [18.624316406250017, 69.813037109375],
              [18.67402343750001, 69.781640625],
              [18.69794921875001, 69.824853515625],
              [18.67402343750001, 69.864306640625],
              [18.6865234375, 69.89091796875],
              [18.823828125, 69.960107421875],
              [18.883203125000023, 70.010546875],
              [18.968652343750023, 70.043017578125],
              [19.05097656250001, 70.037841796875],
              [19.07490234375001, 70.085693359375],
              [19.05097656250001, 70.13466796875],
              [19.06005859375, 70.1666015625],
              [19.132714843750023, 70.244140625],
              [19.212695312500017, 70.2474609375],
              [19.249414062500023, 70.178564453125],
              [19.25507812500001, 70.06640625],
            ],
          ],
          [
            [
              [12.509570312500017, 65.901953125],
              [12.429492187500017, 65.899072265625],
              [12.43017578125, 65.93994140625],
              [12.47607421875, 65.977099609375],
              [12.548828125, 66.001904296875],
              [12.642382812500017, 66.008544921875],
              [12.7470703125, 66.011376953125],
              [12.77880859375, 65.99169921875],
              [12.718652343750023, 65.9638671875],
              [12.509570312500017, 65.901953125],
            ],
          ],
          [
            [
              [12.419921875, 66.04326171875],
              [12.327343750000011, 66.03662109375],
              [12.3427734375, 66.08076171875],
              [12.417675781250011, 66.12265625],
              [12.446386718750006, 66.151318359375],
              [12.461328125000023, 66.185009765625],
              [12.527441406250006, 66.210546875],
              [12.620800781250011, 66.1779296875],
              [12.62265625, 66.1224609375],
              [12.576367187500011, 66.071923828125],
              [12.419921875, 66.04326171875],
            ],
          ],
          [
            [
              [11.2314453125, 64.865869140625],
              [11.179003906250017, 64.838037109375],
              [11.0625, 64.860400390625],
              [10.83251953125, 64.843115234375],
              [10.73984375, 64.8703125],
              [10.8134765625, 64.9232421875],
              [11.02099609375, 64.9787109375],
              [11.132617187500017, 64.976171875],
              [11.246191406250006, 64.90791015625],
              [11.2314453125, 64.865869140625],
            ],
          ],
          [
            [
              [17.503027343750006, 69.596240234375],
              [17.62324218750001, 69.5390625],
              [17.67734375, 69.55654296875],
              [17.78369140625, 69.563037109375],
              [17.86279296875, 69.54296875],
              [17.92744140625001, 69.506640625],
              [18.00410156250001, 69.50498046875],
              [18.05224609375, 69.45751953125],
              [18.076757812500006, 69.395751953125],
              [18.02109375, 69.349609375],
              [17.94208984375001, 69.3287109375],
              [17.920703125000017, 69.27431640625],
              [17.95068359375, 69.19814453125],
              [17.773535156250006, 69.172021484375],
              [17.568164062500017, 69.160400390625],
              [17.487890625, 69.196826171875],
              [17.323632812500023, 69.130029296875],
              [17.160937500000017, 69.025927734375],
              [17.08251953125, 69.013671875],
              [17.077050781250023, 69.046630859375],
              [16.96015625000001, 69.069384765625],
              [16.810449218750023, 69.070703125],
              [16.8154296875, 69.0951171875],
              [16.842578125000017, 69.112353515625],
              [16.971777343750006, 69.137890625],
              [16.99755859375, 69.190625],
              [16.97412109375, 69.284716796875],
              [16.996875, 69.33037109375],
              [17.001757812500017, 69.3619140625],
              [17.0830078125, 69.398828125],
              [17.36083984375, 69.381494140625],
              [17.39453125, 69.41669921875],
              [17.373437500000023, 69.4388671875],
              [17.229882812500023, 69.477685546875],
              [17.251953125, 69.50380859375],
              [17.355566406250006, 69.5271484375],
              [17.45361328125, 69.53017578125],
              [17.48310546875001, 69.569677734375],
              [17.488183593750023, 69.586865234375],
              [17.503027343750006, 69.596240234375],
            ],
          ],
          [
            [
              [15.760351562500006, 68.56123046875],
              [15.772363281250023, 68.55419921875],
              [15.908593750000023, 68.65048828125],
              [16.0595703125, 68.680517578125],
              [16.068945312500006, 68.714013671875],
              [16.12744140625, 68.746435546875],
              [16.12080078125001, 68.799365234375],
              [16.15058593750001, 68.8423828125],
              [16.2275390625, 68.853759765625],
              [16.27558593750001, 68.868310546875],
              [16.328906250000017, 68.876318359375],
              [16.425195312500023, 68.841552734375],
              [16.47968750000001, 68.8029296875],
              [16.54736328125, 68.716552734375],
              [16.51923828125001, 68.6330078125],
              [16.337988281250006, 68.56787109375],
              [16.193945312500006, 68.5384765625],
              [16.0484375, 68.463671875],
              [15.975292968750011, 68.402490234375],
              [15.9125, 68.3892578125],
              [15.872753906250011, 68.39423828125],
              [15.83740234375, 68.409033203125],
              [15.763671875, 68.40908203125],
              [15.682519531250023, 68.356005859375],
              [15.4375, 68.312841796875],
              [15.34140625, 68.32529296875],
              [15.337011718750006, 68.37822265625],
              [15.279687500000023, 68.373828125],
              [15.187890625000023, 68.310400390625],
              [15.098046875000023, 68.289208984375],
              [15.037695312500006, 68.28271484375],
              [14.926855468750006, 68.306591796875],
              [14.62890625, 68.198486328125],
              [14.349511718750023, 68.178271484375],
              [14.257519531250011, 68.190771484375],
              [14.257226562500023, 68.25693359375],
              [14.437792968750017, 68.341552734375],
              [14.585839843750023, 68.400341796875],
              [15.0953125, 68.44140625],
              [15.41259765625, 68.6158203125],
              [15.4892578125, 68.805322265625],
              [15.564257812500017, 68.87373046875],
              [15.529003906250011, 68.91240234375],
              [15.443652343750017, 68.919189453125],
              [15.4384765625, 68.978564453125],
              [15.483007812500006, 69.04345703125],
              [15.649511718750006, 69.132568359375],
              [15.741992187500017, 69.1705078125],
              [15.892675781250006, 69.277880859375],
              [15.96533203125, 69.30205078125],
              [16.04804687500001, 69.30205078125],
              [16.129492187500006, 69.27392578125],
              [16.11484375, 69.21640625],
              [15.99267578125, 69.112646484375],
              [15.811718750000011, 69.02421875],
              [15.833789062500017, 68.9607421875],
              [15.905859375, 68.90849609375],
              [15.923535156250011, 68.819189453125],
              [15.927929687500011, 68.733203125],
              [15.790722656250011, 68.617041015625],
              [15.760351562500006, 68.56123046875],
            ],
          ],
          [
            [
              [-8.953564453124983, 70.83916015625],
              [-9.045800781249994, 70.832666015625],
              [-9.098876953125, 70.8548828125],
              [-8.964648437499989, 70.91591796875],
              [-8.520800781249989, 71.0306640625],
              [-8.343701171874983, 71.14013671875],
              [-8.001367187499994, 71.177685546875],
              [-7.978808593749989, 71.11689453125],
              [-8.002099609374994, 71.041259765625],
              [-8.302343749999977, 70.98115234375],
              [-8.635351562499977, 70.9404296875],
              [-8.953564453124983, 70.83916015625],
            ],
          ],
          [
            [
              [19.219335937500006, 74.391015625],
              [19.098535156250023, 74.3521484375],
              [18.917578125, 74.41064453125],
              [18.797460937500006, 74.485693359375],
              [18.861230468750023, 74.51416015625],
              [19.182910156250017, 74.517919921875],
              [19.261523437500017, 74.478955078125],
              [19.274707031250017, 74.45673828125],
              [19.219335937500006, 74.391015625],
            ],
          ],
          [
            [
              [26.8759765625, 78.64892578125],
              [26.7294921875, 78.646484375],
              [26.459570312500006, 78.720263671875],
              [26.40771484375, 78.784326171875],
              [26.45576171875001, 78.810498046875],
              [26.5859375, 78.811474609375],
              [26.78876953125001, 78.723974609375],
              [27.007617187500017, 78.697509765625],
              [26.8759765625, 78.64892578125],
            ],
          ],
          [
            [
              [32.525976562500006, 80.119140625],
              [31.57763671875, 80.0814453125],
              [31.48193359375, 80.10791015625],
              [33.019140625, 80.21796875],
              [33.0986328125, 80.2287109375],
              [33.38398437500001, 80.242333984375],
              [33.62929687500002, 80.217431640625],
              [33.556640625, 80.19814453125],
              [32.525976562500006, 80.119140625],
            ],
          ],
          [
            [
              [21.60810546875001, 78.595703125],
              [21.74560546875, 78.572021484375],
              [22.04316406250001, 78.576953125],
              [22.207324218750017, 78.407666015625],
              [22.29951171875001, 78.228173828125],
              [22.449316406250006, 78.215234375],
              [22.73457031250001, 78.23994140625],
              [22.988867187500006, 78.251953125],
              [23.119238281250006, 78.238623046875],
              [23.351660156250006, 78.186279296875],
              [23.451953125000017, 78.149462890625],
              [23.364648437500023, 78.1205078125],
              [23.151953125, 78.0880859375],
              [23.11669921875, 77.99150390625],
              [23.33056640625, 77.957861328125],
              [23.683984375000023, 77.875439453125],
              [23.88300781250001, 77.86474609375],
              [24.23828125, 77.89853515625],
              [24.57148437500001, 77.834423828125],
              [24.90185546875, 77.756591796875],
              [24.129785156250023, 77.658251953125],
              [24.061914062500023, 77.630615234375],
              [23.954980468750023, 77.55771484375],
              [23.841210937500023, 77.49775390625],
              [23.736132812500017, 77.462353515625],
              [23.505175781250017, 77.401416015625],
              [23.380859375, 77.380322265625],
              [23.101367187500017, 77.38505859375],
              [22.996679687500006, 77.360791015625],
              [22.899511718750006, 77.311376953125],
              [22.8017578125, 77.27578125],
              [22.5537109375, 77.266650390625],
              [22.42695312500001, 77.31591796875],
              [22.468847656250006, 77.331103515625],
              [22.486621093750017, 77.360107421875],
              [22.442480468750006, 77.429345703125],
              [22.67890625000001, 77.500146484375],
              [22.73261718750001, 77.53935546875],
              [22.685351562500017, 77.553515625],
              [22.62031250000001, 77.549609375],
              [22.4482421875, 77.571142578125],
              [22.397265625000017, 77.5701171875],
              [22.25458984375001, 77.528857421875],
              [22.05683593750001, 77.501171875],
              [21.85615234375001, 77.494140625],
              [21.049902343750006, 77.440966796875],
              [20.928125, 77.45966796875],
              [20.873144531250006, 77.56533203125],
              [21.201074218750023, 77.619482421875],
              [21.25146484375, 77.7109375],
              [21.33417968750001, 77.77177734375],
              [21.43085937500001, 77.812109375],
              [21.6083984375, 77.916064453125],
              [21.653125, 77.92353515625],
              [21.21044921875, 78.00576171875],
              [21.035449218750017, 78.0591796875],
              [20.84492187500001, 78.165869140625],
              [20.786425781250017, 78.2521484375],
              [20.5283203125, 78.3255859375],
              [20.56025390625001, 78.419384765625],
              [20.37275390625001, 78.41201171875],
              [20.227929687500023, 78.47783203125],
              [20.362695312500023, 78.514794921875],
              [21.046875, 78.55673828125],
              [21.45478515625001, 78.59755859375],
              [21.60810546875001, 78.595703125],
            ],
          ],
          [
            [
              [20.897851562500023, 80.249951171875],
              [20.998437500000023, 80.238818359375],
              [21.549218750000023, 80.242919921875],
              [21.654882812500006, 80.21845703125],
              [21.696679687500023, 80.1591796875],
              [21.780664062500023, 80.13876953125],
              [21.897753906250017, 80.132470703125],
              [22.190234375000017, 80.059716796875],
              [22.28974609375001, 80.04921875],
              [22.376367187500023, 80.0896484375],
              [22.442675781250017, 80.190283203125],
              [22.446191406250023, 80.308349609375],
              [22.417871093750023, 80.36552734375],
              [22.45078125, 80.40224609375],
              [22.548828125, 80.416455078125],
              [22.67207031250001, 80.412646484375],
              [22.792578125, 80.4330078125],
              [22.896875, 80.468994140625],
              [23.00800781250001, 80.473974609375],
              [23.251367187500023, 80.4466796875],
              [23.3154296875, 80.425244140625],
              [23.250097656250006, 80.380859375],
              [23.224609375, 80.317626953125],
              [23.114550781250017, 80.186962890625],
              [23.353320312500017, 80.178857421875],
              [23.68798828125, 80.20654296875],
              [23.77294921875, 80.244384765625],
              [23.952929687500017, 80.30458984375],
              [24.142968750000023, 80.295166015625],
              [24.23408203125001, 80.303125],
              [24.280175781250023, 80.329296875],
              [24.29755859375001, 80.360400390625],
              [24.402636718750017, 80.35517578125],
              [24.546679687500017, 80.295166015625],
              [24.613671875000023, 80.28583984375],
              [24.736328125, 80.301318359375],
              [24.785937500000017, 80.30068359375],
              [24.907031250000017, 80.27666015625],
              [25.471289062500006, 80.23310546875],
              [25.666894531250023, 80.209765625],
              [25.75117187500001, 80.188037109375],
              [25.836328125000023, 80.175146484375],
              [26.43671875000001, 80.17548828125],
              [26.86083984375, 80.160009765625],
              [27.0171875, 80.12548828125],
              [27.148339843750023, 80.059228515625],
              [27.198632812500023, 79.906591796875],
              [27.079882812500017, 79.865380859375],
              [26.221093750000023, 79.67744140625],
              [26.005859375, 79.617041015625],
              [25.90205078125001, 79.561376953125],
              [25.726367187500017, 79.43974609375],
              [25.641210937500006, 79.40302734375],
              [25.239062500000017, 79.345068359375],
              [25.145117187500006, 79.3388671875],
              [24.842871093750006, 79.367236328125],
              [24.750585937500006, 79.364599609375],
              [24.383398437500006, 79.301611328125],
              [24.2568359375, 79.2634765625],
              [24.132910156250006, 79.215478515625],
              [23.94775390625, 79.194287109375],
              [23.7587890625, 79.205615234375],
              [22.903710937500023, 79.2306640625],
              [22.789160156250006, 79.26435546875],
              [22.695703125000023, 79.329052734375],
              [22.865527343750017, 79.411865234375],
              [21.911425781250017, 79.3810546875],
              [20.861132812500017, 79.3978515625],
              [20.805566406250023, 79.409521484375],
              [20.760839843750006, 79.44150390625],
              [20.399511718750006, 79.46337890625],
              [20.128222656250017, 79.489599609375],
              [19.900195312500017, 79.5337890625],
              [19.674609375000017, 79.591162109375],
              [19.746679687500006, 79.61796875],
              [19.821093750000017, 79.633642578125],
              [20.01484375000001, 79.640234375],
              [20.187109375, 79.632275390625],
              [20.493457031250017, 79.632763671875],
              [20.564843750000023, 79.69052734375],
              [20.686816406250017, 79.707177734375],
              [20.784082031250023, 79.748583984375],
              [20.460742187500017, 79.774658203125],
              [20.123437500000023, 79.778564453125],
              [19.89863281250001, 79.744189453125],
              [19.638085937500023, 79.72861328125],
              [19.4, 79.7265625],
              [18.94208984375001, 79.736328125],
              [18.725, 79.7607421875],
              [18.428027343750017, 79.82451171875],
              [18.32470703125, 79.859716796875],
              [18.284765625, 79.887353515625],
              [18.25537109375, 79.92919921875],
              [18.594628906250023, 79.96669921875],
              [18.726464843750023, 79.996240234375],
              [18.85595703125, 80.03662109375],
              [18.343847656250006, 80.0595703125],
              [18.129492187500006, 80.093408203125],
              [17.916894531250023, 80.143115234375],
              [18.089453125, 80.171142578125],
              [18.779296875, 80.193505859375],
              [18.9619140625, 80.1748046875],
              [19.142968750000023, 80.138671875],
              [19.343359375, 80.11640625],
              [19.537109375, 80.163232421875],
              [19.35468750000001, 80.185400390625],
              [19.19140625, 80.263232421875],
              [19.15693359375001, 80.30185546875],
              [19.178320312500006, 80.33154296875],
              [19.263769531250006, 80.335986328125],
              [19.327441406250017, 80.323095703125],
              [19.568457031250006, 80.25],
              [19.751074218750006, 80.227197265625],
              [19.80224609375, 80.2947265625],
              [19.810351562500017, 80.326806640625],
              [19.777148437500017, 80.353369140625],
              [19.691308593750023, 80.40234375],
              [19.614355468750006, 80.462548828125],
              [19.733300781250023, 80.47783203125],
              [19.851171875, 80.47119140625],
              [20.104296875000017, 80.42998046875],
              [20.359375, 80.400927734375],
              [20.475878906250017, 80.371630859375],
              [20.693457031250006, 80.298681640625],
              [20.897851562500023, 80.249951171875],
            ],
          ],
          [
            [
              [16.78671875, 79.90673828125],
              [16.838476562500006, 79.90478515625],
              [16.888964843750017, 79.9154296875],
              [16.925585937500017, 79.94345703125],
              [16.96640625, 79.958935546875],
              [17.21943359375001, 79.940771484375],
              [17.578222656250006, 79.88466796875],
              [17.68476562500001, 79.85703125],
              [17.834570312500006, 79.800048828125],
              [17.956152343750006, 79.704248046875],
              [17.859765625000023, 79.635009765625],
              [17.73261718750001, 79.56953125],
              [17.6875, 79.533349609375],
              [17.733984375, 79.48134765625],
              [17.71503906250001, 79.43076171875],
              [17.66875, 79.3859375],
              [17.86103515625001, 79.437060546875],
              [18.272070312500006, 79.6005859375],
              [18.333300781250017, 79.610693359375],
              [18.397363281250023, 79.60517578125],
              [18.581445312500023, 79.57158203125],
              [18.748437500000023, 79.48818359375],
              [18.785253906250006, 79.460595703125],
              [18.815234375000017, 79.42666015625],
              [18.832421875000023, 79.384765625],
              [18.82294921875001, 79.336669921875],
              [18.807421875000017, 79.303173828125],
              [18.720019531250017, 79.281494140625],
              [18.677832031250006, 79.26171875],
              [18.772265625000017, 79.26025390625],
              [18.88007812500001, 79.23427734375],
              [18.97900390625, 79.179150390625],
              [19.089453125, 79.15703125],
              [19.490234375, 79.17568359375],
              [19.750878906250023, 79.146826171875],
              [19.8935546875, 79.056201171875],
              [20.11376953125, 79.076708984375],
              [20.11445312500001, 79.125],
              [20.162695312500006, 79.145654296875],
              [20.45820312500001, 79.129248046875],
              [20.61103515625001, 79.106640625],
              [20.7671875, 79.059130859375],
              [20.50068359375001, 78.981396484375],
              [20.7203125, 78.906689453125],
              [21.089648437500017, 78.85263671875],
              [21.31220703125001, 78.795849609375],
              [21.3525390625, 78.772021484375],
              [21.388769531250006, 78.7404296875],
              [21.243945312500017, 78.6994140625],
              [21.096289062500006, 78.67626953125],
              [20.72480468750001, 78.672314453125],
              [20.387011718750017, 78.64326171875],
              [19.76875, 78.622705078125],
              [19.6767578125, 78.6095703125],
              [19.65498046875001, 78.5978515625],
              [19.618554687500023, 78.562158203125],
              [19.380664062500017, 78.47978515625],
              [19.150488281250006, 78.37939453125],
              [19.0556640625, 78.3189453125],
              [18.983789062500023, 78.234228515625],
              [18.957617187500006, 78.182470703125],
              [19.008691406250023, 78.132275390625],
              [18.9951171875, 78.081494140625],
              [18.822070312500017, 78.04169921875],
              [18.712304687500023, 78.040087890625],
              [18.574609375000023, 78.047998046875],
              [18.439257812500017, 78.025048828125],
              [18.4306640625, 77.990576171875],
              [18.43867187500001, 77.942041015625],
              [18.40400390625001, 77.7939453125],
              [18.361914062500006, 77.682275390625],
              [18.298730468750023, 77.578564453125],
              [18.227929687500023, 77.522607421875],
              [18.13740234375001, 77.50703125],
              [17.847070312500023, 77.49677734375],
              [17.623339843750017, 77.399365234375],
              [17.442480468750006, 77.225244140625],
              [17.3486328125, 77.156884765625],
              [17.15253906250001, 77.04892578125],
              [17.187890625000023, 77.01064453125],
              [17.2490234375, 76.969189453125],
              [17.141992187500023, 76.894921875],
              [16.976660156250006, 76.81162109375],
              [16.979882812500023, 76.77939453125],
              [17.035546875000023, 76.720361328125],
              [17.06269531250001, 76.658984375],
              [16.93515625, 76.60615234375],
              [16.700488281250017, 76.579296875],
              [16.4619140625, 76.609326171875],
              [16.345800781250006, 76.644775390625],
              [16.238085937500017, 76.701513671875],
              [16.123828125000017, 76.738525390625],
              [16.004492187500006, 76.7607421875],
              [15.546777343750023, 76.88642578125],
              [15.124218750000011, 77.085107421875],
              [14.738476562500011, 77.162353515625],
              [14.486914062500006, 77.1990234375],
              [14.365820312500006, 77.23447265625],
              [14.24755859375, 77.28212890625],
              [14.145312500000017, 77.335595703125],
              [14.050390625, 77.40322265625],
              [14.004199218750017, 77.44521484375],
              [13.995703125, 77.508203125],
              [14.026074218750011, 77.545166015625],
              [14.0712890625, 77.564111328125],
              [14.377636718750011, 77.579638671875],
              [14.48779296875, 77.570849609375],
              [14.596289062500006, 77.537939453125],
              [14.695019531250011, 77.525048828125],
              [14.920800781250023, 77.688818359375],
              [16.205957031250023, 77.782470703125],
              [16.619140625, 77.798681640625],
              [17.033300781250006, 77.797705078125],
              [16.96875, 77.841943359375],
              [16.9140625, 77.897998046875],
              [16.852929687500023, 77.911572265625],
              [16.539648437500006, 77.880224609375],
              [16.06005859375, 77.847119140625],
              [15.826367187500011, 77.8470703125],
              [15.585351562500023, 77.869140625],
              [15.344824218750006, 77.856982421875],
              [15.096875, 77.809033203125],
              [14.846875, 77.778662109375],
              [14.603906250000023, 77.766455078125],
              [14.089941406250006, 77.77138671875],
              [13.9625, 77.796240234375],
              [13.791113281250006, 77.85380859375],
              [13.749609375, 77.88330078125],
              [13.714160156250017, 77.91943359375],
              [13.680566406250023, 78.028125],
              [13.717675781250023, 78.0576171875],
              [13.770117187500006, 78.074609375],
              [13.824023437500017, 78.085009765625],
              [13.936914062500023, 78.085546875],
              [14.047753906250023, 78.066845703125],
              [14.307226562500006, 78.005078125],
              [14.248144531250006, 78.07138671875],
              [14.994726562500006, 78.151220703125],
              [15.34140625, 78.220947265625],
              [15.519433593750023, 78.23271484375],
              [15.698046875000017, 78.227587890625],
              [15.65869140625, 78.264697265625],
              [15.657128906250023, 78.2990234375],
              [15.783886718750011, 78.32705078125],
              [15.875390625000023, 78.339111328125],
              [16.150292968750023, 78.352880859375],
              [16.776953125, 78.350439453125],
              [17.0029296875, 78.369384765625],
              [17.171972656250006, 78.417138671875],
              [16.991796875, 78.40048828125],
              [16.81123046875001, 78.397265625],
              [16.7265625, 78.407177734375],
              [16.53535156250001, 78.448876953125],
              [16.448632812500023, 78.503564453125],
              [16.696582031250017, 78.612890625],
              [16.782617187500023, 78.663623046875],
              [16.53046875000001, 78.656298828125],
              [16.4462890625, 78.638525390625],
              [16.157519531250017, 78.538134765625],
              [15.944042968750011, 78.493017578125],
              [15.6806640625, 78.471337890625],
              [15.417382812500023, 78.4732421875],
              [15.359960937500006, 78.487548828125],
              [15.279394531250006, 78.5541015625],
              [15.254199218750017, 78.5890625],
              [15.264941406250017, 78.60830078125],
              [15.348339843750011, 78.663134765625],
              [15.3916015625, 78.72119140625],
              [15.384179687500023, 78.77119140625],
              [15.32275390625, 78.781201171875],
              [15.225292968750011, 78.73232421875],
              [15.137304687500006, 78.6642578125],
              [15.016308593750011, 78.630126953125],
              [14.891796875000011, 78.639453125],
              [14.838671875000017, 78.665576171875],
              [14.792382812500023, 78.70556640625],
              [14.743554687500023, 78.720947265625],
              [14.689257812500017, 78.720947265625],
              [14.57763671875, 78.70498046875],
              [14.467187500000023, 78.675390625],
              [14.505273437500023, 78.630517578125],
              [14.515429687500017, 78.58056640625],
              [14.4677734375, 78.54091796875],
              [14.431835937500011, 78.49248046875],
              [14.545605468750011, 78.461962890625],
              [14.63828125, 78.414599609375],
              [14.49951171875, 78.3923828125],
              [14.36328125, 78.359912109375],
              [14.23828125, 78.30986328125],
              [14.110449218750006, 78.2708984375],
              [13.907617187500023, 78.266748046875],
              [13.654980468750011, 78.245166015625],
              [13.150195312500017, 78.2375],
              [12.912792968750011, 78.30107421875],
              [12.869531250000023, 78.33125],
              [12.822167968750023, 78.35146484375],
              [12.664648437500006, 78.384765625],
              [12.434765625000011, 78.482958984375],
              [12.257910156250006, 78.594677734375],
              [12.13828125, 78.605517578125],
              [11.961718750000017, 78.6423828125],
              [11.865527343750017, 78.67421875],
              [11.773828125000023, 78.71640625],
              [11.746289062500011, 78.766259765625],
              [11.755175781250017, 78.811669921875],
              [11.861035156250011, 78.831884765625],
              [11.611035156250011, 78.882958984375],
              [11.365429687500011, 78.950390625],
              [11.456152343750006, 78.972998046875],
              [11.547558593750011, 78.982958984375],
              [12.27490234375, 78.9044921875],
              [12.323437500000011, 78.9142578125],
              [12.4033203125, 78.95322265625],
              [12.375, 78.966357421875],
              [12.253125, 78.975341796875],
              [12.087304687500023, 78.97509765625],
              [12.045800781250023, 78.983154296875],
              [11.981835937500023, 79.02529296875],
              [11.925683593750023, 79.07724609375],
              [11.901953125, 79.111865234375],
              [11.892773437500011, 79.15234375],
              [12.01611328125, 79.2130859375],
              [12.083984375, 79.267529296875],
              [11.978320312500017, 79.29267578125],
              [11.679296875, 79.291162109375],
              [11.579785156250011, 79.28349609375],
              [11.616406250000011, 79.2052734375],
              [11.521191406250011, 79.15126953125],
              [11.3388671875, 79.109130859375],
              [11.208105468750006, 79.129638671875],
              [11.107226562500017, 79.232958984375],
              [10.975390625000017, 79.3048828125],
              [10.92578125, 79.3501953125],
              [10.888085937500023, 79.4154296875],
              [10.834375, 79.462841796875],
              [10.737597656250017, 79.520166015625],
              [10.725, 79.555517578125],
              [10.737011718750011, 79.581640625],
              [10.810742187500011, 79.64091796875],
              [10.754589843750011, 79.69033203125],
              [10.686230468750011, 79.73359375],
              [10.68212890625, 79.758251953125],
              [10.746386718750017, 79.788671875],
              [10.804003906250017, 79.798779296875],
              [10.865917968750011, 79.79658203125],
              [11.049609375000017, 79.760302734375],
              [11.150390625, 79.7169921875],
              [11.185253906250011, 79.720458984375],
              [11.250585937500006, 79.78486328125],
              [11.343652343750023, 79.7994140625],
              [11.702343750000011, 79.82060546875],
              [12.101757812500011, 79.737548828125],
              [12.205175781250006, 79.719091796875],
              [12.287792968750011, 79.713134765625],
              [12.245214843750006, 79.75],
              [12.219140625000023, 79.797900390625],
              [12.279980468750011, 79.815966796875],
              [12.602441406250023, 79.7732421875],
              [12.753515625, 79.77578125],
              [13.107519531250006, 79.83173828125],
              [13.69287109375, 79.860986328125],
              [13.914160156250006, 79.816943359375],
              [13.925683593750023, 79.793408203125],
              [13.921093750000011, 79.76171875],
              [13.907031250000017, 79.752197265625],
              [13.777539062500011, 79.715283203125],
              [13.039257812500011, 79.68515625],
              [12.555371093750011, 79.569482421875],
              [13.215136718750017, 79.5880859375],
              [13.333789062500017, 79.5748046875],
              [13.383593750000017, 79.48076171875],
              [13.431640625, 79.4708984375],
              [13.601269531250011, 79.4572265625],
              [13.716210937500023, 79.429150390625],
              [13.833691406250011, 79.37568359375],
              [13.957226562500011, 79.3396484375],
              [14.029589843750017, 79.344140625],
              [14.055859375000011, 79.38310546875],
              [14.0263671875, 79.429296875],
              [14.011132812500023, 79.48193359375],
              [14.019824218750017, 79.538671875],
              [14.039843750000017, 79.58564453125],
              [14.178417968750011, 79.618701171875],
              [14.379785156250023, 79.7259765625],
              [14.593652343750023, 79.79873046875],
              [14.831835937500017, 79.76640625],
              [15.05234375, 79.675341796875],
              [15.251269531250017, 79.545458984375],
              [15.443945312500006, 79.406787109375],
              [15.66015625, 79.23486328125],
              [15.764062500000023, 79.174267578125],
              [15.858496093750006, 79.159912109375],
              [16.29453125, 78.9810546875],
              [16.34375, 78.976123046875],
              [16.253515625, 79.112109375],
              [16.02753906250001, 79.3423828125],
              [15.875097656250006, 79.51923828125],
              [15.840722656250023, 79.586865234375],
              [15.816113281250011, 79.6818359375],
              [15.82578125, 79.709033203125],
              [15.845117187500023, 79.73359375],
              [15.955761718750011, 79.835107421875],
              [16.100195312500006, 79.884423828125],
              [16.056640625, 79.953955078125],
              [16.093847656250006, 80.00732421875],
              [16.245703125, 80.049462890625],
              [16.386621093750023, 80.052587890625],
              [16.524023437500006, 80.0205078125],
              [16.78671875, 79.90673828125],
            ],
          ],
          [
            [
              [11.250292968750017, 78.610693359375],
              [11.26171875, 78.54169921875],
              [11.424218750000023, 78.548583984375],
              [11.616308593750006, 78.47509765625],
              [11.82568359375, 78.436083984375],
              [11.884863281250006, 78.409326171875],
              [11.929394531250011, 78.37490234375],
              [12.05615234375, 78.305615234375],
              [12.116406250000011, 78.232568359375],
              [11.965039062500011, 78.224853515625],
              [11.756542968750011, 78.32900390625],
              [11.586523437500006, 78.388232421875],
              [11.372460937500023, 78.43876953125],
              [11.19921875, 78.441259765625],
              [11.121289062500011, 78.46328125],
              [10.840625, 78.6447265625],
              [10.788867187500017, 78.6865234375],
              [10.62841796875, 78.753857421875],
              [10.5576171875, 78.8375],
              [10.558203125, 78.9029296875],
              [10.772851562500023, 78.8875],
              [10.960839843750023, 78.84638671875],
              [11.123925781250023, 78.753369140625],
              [11.152929687500006, 78.724462890625],
              [11.078222656250006, 78.68603515625],
              [11.154980468750011, 78.640576171875],
              [11.250292968750017, 78.610693359375],
            ],
          ],
          [
            [
              [18.741601562500023, 80.300927734375],
              [18.525, 80.24560546875],
              [18.162207031250006, 80.28818359375],
              [18.20556640625, 80.331787109375],
              [18.29169921875001, 80.358349609375],
              [18.519335937500017, 80.34833984375],
              [18.741601562500023, 80.300927734375],
            ],
          ],
          [
            [
              [29.04707031250001, 78.912060546875],
              [29.34541015625001, 78.90576171875],
              [29.645117187500006, 78.921630859375],
              [29.696679687500023, 78.904736328125],
              [29.310546875, 78.852099609375],
              [28.881152343750017, 78.880078125],
              [28.494531250000023, 78.88720703125],
              [28.037890625000017, 78.8287109375],
              [27.889062500000023, 78.8521484375],
              [28.120996093750023, 78.908447265625],
              [28.3740234375, 78.92705078125],
              [28.41474609375001, 78.96142578125],
              [28.511132812500023, 78.967333984375],
              [28.84521484375, 78.970849609375],
              [29.04707031250001, 78.912060546875],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 3,
        labelrank: 3,
        sovereignt: "Sweden",
        sov_a3: "SWE",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Sweden",
        adm0_a3: "SWE",
        geou_dif: 0,
        geounit: "Sweden",
        gu_a3: "SWE",
        su_dif: 0,
        subunit: "Sweden",
        su_a3: "SWE",
        brk_diff: 0,
        name: "Sweden",
        name_long: "Sweden",
        brk_a3: "SWE",
        brk_name: "Sweden",
        brk_group: null,
        abbrev: "Swe.",
        postal: "S",
        formal_en: "Kingdom of Sweden",
        formal_fr: null,
        name_ciawf: "Sweden",
        note_adm0: null,
        note_brk: null,
        name_sort: "Sweden",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 4,
        mapcolor9: 2,
        mapcolor13: 4,
        pop_est: 10285453,
        pop_rank: 14,
        pop_year: 2019,
        gdp_md: 530883,
        gdp_year: 2019,
        economy: "2. Developed region: nonG7",
        income_grp: "1. High income: OECD",
        fips_10: "SW",
        iso_a2: "SE",
        iso_a2_eh: "SE",
        iso_a3: "SWE",
        iso_a3_eh: "SWE",
        iso_n3: "752",
        iso_n3_eh: "752",
        un_a3: "752",
        wb_a2: "SE",
        wb_a3: "SWE",
        woe_id: 23424954,
        woe_id_eh: 23424954,
        woe_note: "Exact WOE match as country",
        adm0_iso: "SWE",
        adm0_diff: null,
        adm0_tlc: "SWE",
        adm0_a3_us: "SWE",
        adm0_a3_fr: "SWE",
        adm0_a3_ru: "SWE",
        adm0_a3_es: "SWE",
        adm0_a3_cn: "SWE",
        adm0_a3_tw: "SWE",
        adm0_a3_in: "SWE",
        adm0_a3_np: "SWE",
        adm0_a3_pk: "SWE",
        adm0_a3_de: "SWE",
        adm0_a3_gb: "SWE",
        adm0_a3_br: "SWE",
        adm0_a3_il: "SWE",
        adm0_a3_ps: "SWE",
        adm0_a3_sa: "SWE",
        adm0_a3_eg: "SWE",
        adm0_a3_ma: "SWE",
        adm0_a3_pt: "SWE",
        adm0_a3_ar: "SWE",
        adm0_a3_jp: "SWE",
        adm0_a3_ko: "SWE",
        adm0_a3_vn: "SWE",
        adm0_a3_tr: "SWE",
        adm0_a3_id: "SWE",
        adm0_a3_pl: "SWE",
        adm0_a3_gr: "SWE",
        adm0_a3_it: "SWE",
        adm0_a3_nl: "SWE",
        adm0_a3_se: "SWE",
        adm0_a3_bd: "SWE",
        adm0_a3_ua: "SWE",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Northern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 6,
        long_len: 6,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 2,
        max_label: 7,
        label_x: 19.01705,
        label_y: 65.85918,
        ne_id: 1159321287,
        wikidataid: "Q34",
        name_ar: "السويد",
        name_bn: "সুইডেন",
        name_de: "Schweden",
        name_en: "Sweden",
        name_es: "Suecia",
        name_fa: "سوئد",
        name_fr: "Suède",
        name_el: "Σουηδία",
        name_he: "שוודיה",
        name_hi: "स्वीडन",
        name_hu: "Svédország",
        name_id: "Swedia",
        name_it: "Svezia",
        name_ja: "スウェーデン",
        name_ko: "스웨덴",
        name_nl: "Zweden",
        name_pl: "Szwecja",
        name_pt: "Suécia",
        name_ru: "Швеция",
        name_sv: "Sverige",
        name_tr: "İsveç",
        name_uk: "Швеція",
        name_ur: "سویڈن",
        name_vi: "Thụy Điển",
        name_zh: "瑞典",
        name_zht: "瑞典",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "SWE.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [19.076464843750017, 57.8359375],
              [18.99375, 57.812109375],
              [18.945117187500017, 57.7416015625],
              [18.878125, 57.7296875],
              [18.813867187500023, 57.706201171875],
              [18.790917968750023, 57.48310546875],
              [18.90791015625001, 57.39833984375],
              [18.843652343750023, 57.386474609375],
              [18.78486328125001, 57.361083984375],
              [18.74287109375001, 57.323535156249996],
              [18.69990234375001, 57.242724609374996],
              [18.538476562500023, 57.196923828125],
              [18.477343750000017, 57.163037109375],
              [18.38720703125, 57.087646484375],
              [18.340234375000023, 56.97822265625],
              [18.248925781250023, 56.93154296875],
              [18.146386718750023, 56.9205078125],
              [18.20654296875, 57.01015625],
              [18.28535156250001, 57.083203125],
              [18.209570312500006, 57.13330078125],
              [18.163964843750023, 57.21171875],
              [18.105078125, 57.271875],
              [18.151953125, 57.3390625],
              [18.12890625, 57.449169921875],
              [18.136523437500017, 57.556640625],
              [18.204882812500017, 57.610888671874996],
              [18.283203125, 57.655126953125],
              [18.405175781250023, 57.7568359375],
              [18.537402343750017, 57.83056640625],
              [18.721875, 57.863720703125],
              [18.80517578125, 57.833154296875],
              [18.841113281250017, 57.900195312499996],
              [18.90058593750001, 57.915478515625],
              [18.956445312500023, 57.9],
              [19.076464843750017, 57.8359375],
            ],
          ],
          [
            [
              [16.52851562500001, 56.29052734375],
              [16.477148437500006, 56.240185546875],
              [16.431640625, 56.24375],
              [16.401269531250023, 56.310888671875],
              [16.394140625, 56.483642578125],
              [16.41230468750001, 56.568994140625],
              [16.63037109375, 56.87685546875],
              [16.72773437500001, 56.902001953125],
              [16.864648437500023, 57.090673828125],
              [16.90156250000001, 57.174609375],
              [16.9609375, 57.2501953125],
              [16.995996093750023, 57.3177734375],
              [17.025390625, 57.345068359375],
              [17.089257812500023, 57.332275390625],
              [17.11767578125, 57.31982421875],
              [17.050390625, 57.28046875],
              [17.058203125, 57.229248046875],
              [17.053515625000017, 57.2080078125],
              [16.883691406250023, 56.985205078125],
              [16.838281250000023, 56.84052734375],
              [16.77802734375001, 56.805224609374996],
              [16.52851562500001, 56.29052734375],
            ],
          ],
          [
            [
              [11.38828125, 59.036523437499994],
              [11.470703125, 58.909521484375],
              [11.543554687500006, 58.893017578125],
              [11.642773437500011, 58.92607421875],
              [11.712207031250017, 59.01865234375],
              [11.751855468750023, 59.157568359375],
              [11.798144531250017, 59.289892578125],
              [11.743359375000011, 59.4314453125],
              [11.684863281250017, 59.55576171875],
              [11.680761718750006, 59.59228515625],
              [11.834277343750017, 59.697167968749994],
              [11.88125, 59.782470703125],
              [11.93212890625, 59.863671875],
              [11.98828125, 59.89130859375],
              [12.071875, 59.897607421874994],
              [12.169238281250017, 59.912890625],
              [12.2919921875, 59.967236328125],
              [12.402050781250011, 60.0400390625],
              [12.486132812500017, 60.106787109375],
              [12.5146484375, 60.2388671875],
              [12.515820312500011, 60.305224609375],
              [12.552832031250006, 60.3544921875],
              [12.588671875000017, 60.450732421875],
              [12.553808593750006, 60.545654296875],
              [12.4453125, 60.6896484375],
              [12.314648437500011, 60.892138671875],
              [12.294140625000011, 61.002685546875],
              [12.353710937500011, 61.023193359375],
              [12.467578125000017, 61.04150390625],
              [12.683007812500023, 61.046826171875],
              [12.7060546875, 61.05986328125],
              [12.727832031250017, 61.108251953125],
              [12.7763671875, 61.173974609374994],
              [12.828222656250006, 61.221826171874994],
              [12.863671875000023, 61.290283203125],
              [12.880761718750023, 61.352294921875],
              [12.757519531250011, 61.445703125],
              [12.596093750000023, 61.54130859375],
              [12.48681640625, 61.572998046875],
              [12.292089843750006, 61.653466796874994],
              [12.155371093750006, 61.720751953125],
              [12.233691406250017, 61.97685546875],
              [12.2919921875, 62.167431640625],
              [12.301367187500006, 62.213769531249994],
              [12.303515625000017, 62.285595703125],
              [12.114550781250017, 62.59189453125],
              [12.121875, 62.660009765625],
              [12.139843750000011, 62.721337890624994],
              [12.11962890625, 62.825927734375],
              [12.108593750000011, 62.919482421875],
              [12.141015625000023, 62.9478515625],
              [12.218164062500023, 63.000634765624994],
              [12.144628906250006, 63.08251953125],
              [12.138671875, 63.08916015625],
              [11.999902343750023, 63.29169921875],
              [12.212109375000011, 63.492236328125],
              [12.175195312500023, 63.595947265625],
              [12.301953125000011, 63.67119140625],
              [12.53271484375, 63.8435546875],
              [12.6625, 63.940478515625],
              [12.690039062500006, 63.957421875],
              [12.792773437500017, 64],
              [12.987597656250017, 64.05048828125],
              [13.203515625000023, 64.07509765625],
              [13.299609375000017, 64.0748046875],
              [13.670703125000017, 64.040625],
              [13.960546875, 64.014013671875],
              [14.002734375000017, 64.04072265625],
              [14.063281250000017, 64.0955078125],
              [14.141210937500006, 64.17353515625],
              [14.148046875, 64.260302734375],
              [14.119921875000017, 64.387744140625],
              [14.07763671875, 64.464013671875],
              [13.87353515625, 64.51357421875],
              [13.650292968750023, 64.58154296875],
              [13.9248046875, 64.79677734375],
              [14.115136718750023, 64.946142578125],
              [14.352441406250023, 65.170849609375],
              [14.42626953125, 65.26435546875],
              [14.479687500000011, 65.30146484375],
              [14.549511718750011, 65.64638671875],
              [14.595800781250006, 65.74287109375],
              [14.634570312500017, 65.79326171875],
              [14.635156250000023, 65.84501953125],
              [14.609960937500006, 65.932275390625],
              [14.543261718750017, 66.129345703125],
              [14.91796875, 66.1537109375],
              [15.0400390625, 66.167529296875],
              [15.1533203125, 66.191064453125],
              [15.374902343750023, 66.25205078125],
              [15.483789062500023, 66.30595703125],
              [15.422949218750006, 66.48984375],
              [15.557031250000023, 66.552099609375],
              [15.884179687500023, 66.76884765625],
              [16.237695312500023, 66.976416015625],
              [16.40351562500001, 67.05498046875],
              [16.420703125000017, 67.093359375],
              [16.43427734375001, 67.155078125],
              [16.360644531250017, 67.252001953125],
              [16.281542968750017, 67.312060546875],
              [16.12744140625, 67.425830078125],
              [16.19355468750001, 67.50517578125],
              [16.30712890625, 67.52060546875],
              [16.457128906250006, 67.5517578125],
              [16.574121093750023, 67.619580078125],
              [16.585546875, 67.6283203125],
              [16.783593750000023, 67.89501953125],
              [17.170507812500006, 68.030126953125],
              [17.324609375000023, 68.10380859375],
              [17.564746093750017, 68.0484375],
              [17.91669921875001, 67.964892578125],
              [18.0732421875, 68.087841796875],
              [18.125, 68.133447265625],
              [18.176660156250023, 68.200634765625],
              [18.15595703125001, 68.316845703125],
              [18.147070312500006, 68.4677734375],
              [18.16259765625, 68.52841796875],
              [18.303027343750017, 68.555419921875],
              [18.37861328125001, 68.56240234375],
              [18.769824218750017, 68.500048828125],
              [18.868261718750006, 68.501123046875],
              [19.052636718750023, 68.492724609375],
              [19.25898437500001, 68.46533203125],
              [19.691210937500017, 68.392431640625],
              [19.870019531250023, 68.362255859375],
              [19.969824218750006, 68.356396484375],
              [20.055957031250017, 68.390380859375],
              [20.240039062500017, 68.4775390625],
              [19.968847656250006, 68.542041015625],
              [20.1474609375, 68.60732421875],
              [20.240039062500017, 68.67314453125],
              [20.319433593750006, 68.754052734375],
              [20.348046875000023, 68.84873046875],
              [20.33710937500001, 68.899658203125],
              [20.282324218750006, 68.934326171875],
              [20.11669921875, 69.0208984375],
              [20.491992187500017, 69.03330078125],
              [20.622167968750006, 69.036865234375],
              [20.895117187500006, 68.979833984375],
              [20.907031250000017, 68.96748046875],
              [20.908984375000017, 68.937744140625],
              [20.918554687500006, 68.90693359375],
              [21.183398437500017, 68.82880859375],
              [21.259765625, 68.787451171875],
              [21.42236328125, 68.724609375],
              [21.465429687500006, 68.690673828125],
              [21.616015625000017, 68.6509765625],
              [21.724023437500023, 68.608544921875],
              [21.850195312500006, 68.57412109375],
              [21.997460937500023, 68.52060546875],
              [22.195117187500017, 68.477978515625],
              [22.362109375000017, 68.4640625],
              [22.78242187500001, 68.391015625],
              [22.854101562500006, 68.367333984375],
              [22.975390625000017, 68.316455078125],
              [23.09785156250001, 68.257568359375],
              [23.182519531250023, 68.13662109375],
              [23.31855468750001, 68.130322265625],
              [23.35546875, 68.088671875],
              [23.47421875, 68.017333984375],
              [23.63886718750001, 67.95439453125],
              [23.632910156250006, 67.933203125],
              [23.501855468750023, 67.8751953125],
              [23.48779296875, 67.79658203125],
              [23.50019531250001, 67.69619140625],
              [23.541308593750017, 67.614306640625],
              [23.537011718750023, 67.590380859375],
              [23.504492187500006, 67.562158203125],
              [23.465429687500006, 67.51787109375],
              [23.451464843750017, 67.47919921875],
              [23.454882812500017, 67.46025390625],
              [23.468066406250017, 67.449951171875],
              [23.537109375, 67.449169921875],
              [23.66083984375001, 67.4400390625],
              [23.73359375000001, 67.422900390625],
              [23.77490234375, 67.32861328125],
              [23.76093750000001, 67.310498046875],
              [23.656640625000023, 67.267822265625],
              [23.626074218750006, 67.233935546875],
              [23.623046875, 67.184130859375],
              [23.641503906250023, 67.12939453125],
              [23.67734375, 67.068115234375],
              [23.75898437500001, 67.002587890625],
              [23.86933593750001, 66.934033203125],
              [23.941796875000023, 66.87783203125],
              [23.97607421875, 66.838232421875],
              [23.988574218750017, 66.810546875],
              [23.938867187500023, 66.775732421875],
              [23.894140625, 66.706884765625],
              [23.885839843750006, 66.62802734375],
              [23.865527343750017, 66.576611328125],
              [23.768359375000017, 66.505859375],
              [23.701171875, 66.48076171875],
              [23.682031250000023, 66.443408203125],
              [23.673828125, 66.380712890625],
              [23.69355468750001, 66.304296875],
              [23.700292968750006, 66.25263671875],
              [23.720996093750017, 66.2154296875],
              [23.75146484375, 66.191162109375],
              [23.907324218750006, 66.1482421875],
              [23.99462890625, 66.0603515625],
              [24.04902343750001, 65.98984375],
              [24.15546875000001, 65.8052734375],
              [23.890527343750023, 65.7822265625],
              [23.69140625, 65.828515625],
              [23.592089843750017, 65.805322265625],
              [23.418359375000023, 65.804345703125],
              [23.221093750000023, 65.7861328125],
              [23.154589843750017, 65.74990234375],
              [23.102343750000017, 65.7353515625],
              [22.919335937500023, 65.786474609375],
              [22.74658203125, 65.870947265625],
              [22.62031250000001, 65.80654296875],
              [22.53857421875, 65.7943359375],
              [22.465136718750017, 65.85263671875],
              [22.400976562500006, 65.862109375],
              [22.366308593750006, 65.84267578125],
              [22.3359375, 65.791162109375],
              [22.28759765625, 65.750634765625],
              [22.275, 65.725],
              [22.2666015625, 65.621533203125],
              [22.254003906250006, 65.59755859375],
              [22.086230468750017, 65.6109375],
              [22.096289062500006, 65.5837890625],
              [22.1328125, 65.5701171875],
              [22.147558593750006, 65.552880859375],
              [22.086718750000017, 65.530224609375],
              [21.92011718750001, 65.532373046875],
              [21.903125, 65.508349609375],
              [21.95, 65.470361328125],
              [21.913476562500023, 65.437109375],
              [21.87958984375001, 65.4240234375],
              [21.6806640625, 65.403369140625],
              [21.565527343750006, 65.40810546875],
              [21.532617187500023, 65.386572265625],
              [21.5234375, 65.35859375],
              [21.545214843750017, 65.33115234375],
              [21.595996093750017, 65.316552734375],
              [21.612695312500023, 65.29912109375],
              [21.609179687500017, 65.261376953125],
              [21.56689453125, 65.254541015625],
              [21.446875, 65.320849609375],
              [21.41035156250001, 65.317431640625],
              [21.437792968750017, 65.282958984375],
              [21.50634765625, 65.245361328125],
              [21.545996093750006, 65.206982421875],
              [21.580664062500006, 65.160791015625],
              [21.57392578125001, 65.12578125],
              [21.424902343750006, 65.0126953125],
              [21.29375, 64.941259765625],
              [21.195898437500006, 64.876904296875],
              [21.13818359375, 64.80869140625],
              [21.204980468750023, 64.77431640625],
              [21.279296875, 64.72470703125],
              [21.33154296875, 64.629345703125],
              [21.393847656250017, 64.5443359375],
              [21.519628906250006, 64.4630859375],
              [21.49433593750001, 64.41611328125],
              [21.46503906250001, 64.37958984375],
              [21.255761718750023, 64.299169921875],
              [21.018457031250023, 64.177978515625],
              [20.7626953125, 63.867822265624994],
              [20.677636718750023, 63.82626953125],
              [20.453710937500006, 63.77373046875],
              [20.371386718750017, 63.722900390625],
              [20.2046875, 63.662451171875],
              [19.913671875, 63.610546875],
              [19.781640625000023, 63.53818359375],
              [19.722070312500023, 63.463330078125],
              [19.65576171875, 63.4580078125],
              [19.59003906250001, 63.487255859375],
              [19.502343750000023, 63.509033203125],
              [19.49091796875001, 63.460205078125],
              [19.49462890625, 63.424365234375],
              [19.354296875000017, 63.477490234375],
              [19.2880859375, 63.428759765625],
              [19.236328125, 63.34736328125],
              [19.034375, 63.237744140625],
              [18.816699218750017, 63.257470703124994],
              [18.792285156250017, 63.238134765625],
              [18.850195312500006, 63.22412109375],
              [18.858984375, 63.206591796875],
              [18.819433593750006, 63.197265625],
              [18.759570312500017, 63.1982421875],
              [18.66718750000001, 63.1765625],
              [18.6064453125, 63.178271484375],
              [18.57763671875, 63.126416015625],
              [18.530664062500023, 63.063525390625],
              [18.40771484375, 63.0375],
              [18.34423828125, 63.032128906249994],
              [18.312890625000023, 62.99638671875],
              [18.502050781250006, 62.9888671875],
              [18.486914062500006, 62.95859375],
              [18.48261718750001, 62.9283203125],
              [18.46308593750001, 62.895849609375],
              [18.248046875, 62.849072265625],
              [18.214941406250006, 62.81220703125],
              [18.170019531250006, 62.78935546875],
              [18.07441406250001, 62.790673828124994],
              [18.077929687500017, 62.811962890625],
              [18.093554687500017, 62.83603515625],
              [17.951074218750023, 62.833886718749994],
              [17.906640625000023, 62.886767578125],
              [17.87958984375001, 62.873193359374994],
              [17.895605468750006, 62.830517578125],
              [17.932910156250017, 62.7861328125],
              [17.974414062500017, 62.721044921875],
              [17.940722656250017, 62.6798828125],
              [17.90302734375001, 62.65947265625],
              [17.930468750000017, 62.640625],
              [18.00654296875001, 62.62626953125],
              [18.03730468750001, 62.600537109375],
              [17.947070312500017, 62.578466796875],
              [17.83447265625, 62.502734375],
              [17.7177734375, 62.500878906249994],
              [17.646386718750023, 62.45087890625],
              [17.570605468750017, 62.451025390625],
              [17.50898437500001, 62.48251953125],
              [17.410253906250006, 62.5083984375],
              [17.37841796875, 62.462792968749994],
              [17.373339843750017, 62.426513671875],
              [17.429003906250017, 62.334716796875],
              [17.535253906250006, 62.263671875],
              [17.633691406250023, 62.2330078125],
              [17.562890625000023, 62.212304687499994],
              [17.510156250000023, 62.16630859375],
              [17.446582031250017, 62.02265625],
              [17.412011718750023, 61.96611328125],
              [17.37451171875, 61.86630859375],
              [17.398242187500017, 61.782080078125],
              [17.417285156250017, 61.740673828125],
              [17.465429687500006, 61.684472656249994],
              [17.334570312500006, 61.69169921875],
              [17.196386718750006, 61.724560546875],
              [17.215625, 61.65634765625],
              [17.130761718750023, 61.575732421875],
              [17.146582031250006, 61.504638671875],
              [17.16425781250001, 61.45830078125],
              [17.137988281250017, 61.381689453125],
              [17.17792968750001, 61.3576171875],
              [17.199609375000023, 61.311962890625],
              [17.163867187500017, 61.278271484375],
              [17.179785156250006, 61.249267578125],
              [17.18574218750001, 61.146533203125],
              [17.212890625, 60.98583984375],
              [17.202929687500017, 60.95185546875],
              [17.27890625, 60.812158203124994],
              [17.26123046875, 60.76318359375],
              [17.2509765625, 60.70078125],
              [17.35986328125, 60.6408203125],
              [17.45703125, 60.641796875],
              [17.555468750000017, 60.642724609374994],
              [17.593066406250017, 60.627685546875],
              [17.630761718750023, 60.58525390625],
              [17.6611328125, 60.53515625],
              [17.7421875, 60.539306640625],
              [17.87158203125, 60.580078125],
              [17.95576171875001, 60.589794921875],
              [18.011328125, 60.51142578125],
              [18.1625, 60.40791015625],
              [18.25048828125, 60.3615234375],
              [18.4, 60.337109375],
              [18.557519531250023, 60.253564453124994],
              [18.535449218750017, 60.152880859375],
              [18.601171875, 60.11923828125],
              [18.787011718750023, 60.079492187499994],
              [18.85273437500001, 60.02587890625],
              [18.88427734375, 59.98017578125],
              [18.933203125, 59.942285156249994],
              [18.99042968750001, 59.827783203124994],
              [18.970507812500017, 59.757226562499994],
              [18.895605468750006, 59.732958984375],
              [18.71875, 59.657373046874994],
              [18.639941406250017, 59.600927734375],
              [18.578125, 59.565771484375],
              [18.402441406250006, 59.490380859374994],
              [18.33808593750001, 59.47685546875],
              [18.276464843750006, 59.437646484374994],
              [18.216894531250006, 59.4205078125],
              [18.16357421875, 59.43037109375],
              [17.964257812500023, 59.359375],
              [17.979785156250017, 59.329052734375],
              [18.132617187500017, 59.3162109375],
              [18.210546875, 59.331445312499994],
              [18.2705078125, 59.367138671875],
              [18.336035156250006, 59.375341796875],
              [18.395800781250017, 59.368603515625],
              [18.45917968750001, 59.396728515625],
              [18.508886718750006, 59.407958984375],
              [18.56025390625001, 59.394482421875],
              [18.617578125000023, 59.327050781249994],
              [18.498632812500006, 59.291943359375],
              [18.41425781250001, 59.29033203125],
              [18.373046875, 59.179736328125],
              [18.32197265625001, 59.132226562499994],
              [18.28535156250001, 59.109375],
              [18.09814453125, 59.0623046875],
              [17.974609375, 59.00263671875],
              [17.829003906250023, 58.95458984375],
              [17.765429687500017, 58.9650390625],
              [17.66962890625001, 58.9162109375],
              [17.45673828125001, 58.8583984375],
              [17.34765625, 58.780517578125],
              [17.102832031250017, 58.710839843749994],
              [16.978125, 58.654150390625],
              [16.63935546875001, 58.651171875],
              [16.315820312500023, 58.663623046875],
              [16.214257812500023, 58.636669921875],
              [16.31806640625001, 58.628320312499994],
              [16.39082031250001, 58.60185546875],
              [16.47802734375, 58.612890625],
              [16.683007812500023, 58.599658203125],
              [16.788476562500023, 58.58525390625],
              [16.923828125, 58.492578125],
              [16.824316406250006, 58.459619140624994],
              [16.651953125, 58.434326171875],
              [16.716601562500017, 58.302880859374994],
              [16.769921875000023, 58.214257812499994],
              [16.700097656250023, 58.160791015624994],
              [16.694921875, 57.917529296874996],
              [16.596972656250017, 57.912890625],
              [16.55537109375001, 57.812255859375],
              [16.586230468750017, 57.7609375],
              [16.583789062500017, 57.641748046875],
              [16.60419921875001, 57.568310546875],
              [16.652246093750023, 57.50068359375],
              [16.630859375, 57.43017578125],
              [16.475976562500023, 57.26513671875],
              [16.4794921875, 57.1876953125],
              [16.50732421875, 57.14169921875],
              [16.527929687500006, 57.068164062499996],
              [16.45751953125, 56.926806640624996],
              [16.4078125, 56.80869140625],
              [16.348730468750006, 56.709277343749996],
              [16.21650390625001, 56.589990234375],
              [16.150683593750017, 56.500830078125],
              [15.996679687500006, 56.222607421875],
              [15.920312500000023, 56.1673828125],
              [15.82666015625, 56.124951171875],
              [15.722265625, 56.164208984375],
              [15.6265625, 56.185595703124996],
              [15.509667968750023, 56.1830078125],
              [15.326562500000023, 56.150830078125],
              [15.051171875000023, 56.172216796875],
              [14.782031250000017, 56.161914062499996],
              [14.713964843750006, 56.134130859375],
              [14.754785156250023, 56.033154296875],
              [14.655566406250017, 56.019921875],
              [14.55859375, 56.048632812499996],
              [14.473242187500006, 56.01435546875],
              [14.401953125, 55.9767578125],
              [14.261914062500011, 55.887548828125],
              [14.215039062500011, 55.8326171875],
              [14.202929687500017, 55.729150390625],
              [14.276464843750006, 55.636376953125],
              [14.341699218750023, 55.527734375],
              [14.173730468750023, 55.396630859375],
              [14.079980468750023, 55.3921875],
              [13.806347656250011, 55.428564453125],
              [13.321386718750006, 55.34638671875],
              [12.885839843750006, 55.411376953125],
              [12.940625, 55.481591796875],
              [12.938769531250017, 55.533203125],
              [12.96337890625, 55.612597656249996],
              [12.97802734375, 55.693798828125],
              [12.973925781250017, 55.74814453125],
              [12.941992187500006, 55.8060546875],
              [12.834570312500006, 55.8818359375],
              [12.592578125000017, 56.13759765625],
              [12.52099609375, 56.245556640625],
              [12.47119140625, 56.29052734375],
              [12.507031250000011, 56.29296875],
              [12.706347656250017, 56.235009765625],
              [12.752832031250023, 56.242138671875],
              [12.801660156250023, 56.263916015625],
              [12.7421875, 56.346875],
              [12.691113281250011, 56.384423828125],
              [12.656445312500011, 56.440576171875],
              [12.773144531250011, 56.45576171875],
              [12.857421875, 56.452392578125],
              [12.91953125, 56.515576171875],
              [12.883691406250023, 56.617724609374996],
              [12.793164062500011, 56.649169921875],
              [12.717578125000017, 56.662841796875],
              [12.572656250000023, 56.823291015624996],
              [12.421484375, 56.906396484375],
              [12.15185546875, 57.226953125],
              [12.05322265625, 57.44697265625],
              [11.961523437500006, 57.426074218749996],
              [11.9169921875, 57.521923828125],
              [11.885058593750017, 57.6126953125],
              [11.878710937500017, 57.679443359375],
              [11.734960937500006, 57.71767578125],
              [11.729101562500006, 57.764453125],
              [11.703222656250006, 57.973193359374996],
              [11.549023437500011, 58.001220703125],
              [11.449316406250006, 58.118359375],
              [11.431542968750023, 58.339990234374994],
              [11.329980468750023, 58.380322265625],
              [11.248242187500011, 58.369140625],
              [11.252050781250006, 58.424072265625],
              [11.271582031250006, 58.475634765625],
              [11.223828125000011, 58.679931640625],
              [11.207910156250023, 58.86640625],
              [11.169140625000011, 58.922705078125],
              [11.147167968750011, 58.988623046875],
              [11.166894531250023, 59.045556640624994],
              [11.19580078125, 59.078271484374994],
              [11.295312500000023, 59.086865234375],
              [11.38828125, 59.036523437499994],
            ],
          ],
          [
            [
              [19.156347656250006, 57.922607421875],
              [19.13837890625001, 57.86025390625],
              [19.086523437500006, 57.864990234375],
              [19.03925781250001, 57.91103515625],
              [19.134863281250006, 57.98134765625],
              [19.281152343750023, 57.9775390625],
              [19.331445312500023, 57.962890625],
              [19.156347656250006, 57.922607421875],
            ],
          ],
          [
            [
              [18.41621093750001, 59.0291015625],
              [18.371875, 59.019580078125],
              [18.349902343750017, 59.022607421874994],
              [18.377246093750017, 59.06904296875],
              [18.397558593750006, 59.089111328125],
              [18.464941406250006, 59.107861328125],
              [18.48554687500001, 59.10458984375],
              [18.41621093750001, 59.0291015625],
            ],
          ],
          [
            [
              [18.59541015625001, 59.470361328124994],
              [18.5703125, 59.437255859375],
              [18.54511718750001, 59.47783203125],
              [18.55517578125, 59.485791015625],
              [18.572363281250006, 59.525830078125],
              [18.620898437500017, 59.547802734375],
              [18.69843750000001, 59.534619140625],
              [18.69794921875001, 59.524609375],
              [18.623828125000017, 59.4921875],
              [18.59541015625001, 59.470361328124994],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Finland",
        sov_a3: "FI1",
        adm0_dif: 1,
        level: 2,
        type: "Country",
        tlc: "1",
        admin: "Finland",
        adm0_a3: "FIN",
        geou_dif: 0,
        geounit: "Finland",
        gu_a3: "FIN",
        su_dif: 0,
        subunit: "Finland",
        su_a3: "FIN",
        brk_diff: 0,
        name: "Finland",
        name_long: "Finland",
        brk_a3: "FIN",
        brk_name: "Finland",
        brk_group: null,
        abbrev: "Fin.",
        postal: "FIN",
        formal_en: "Republic of Finland",
        formal_fr: null,
        name_ciawf: "Finland",
        note_adm0: null,
        note_brk: null,
        name_sort: "Finland",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 1,
        mapcolor9: 4,
        mapcolor13: 6,
        pop_est: 5520314,
        pop_rank: 13,
        pop_year: 2019,
        gdp_md: 269296,
        gdp_year: 2019,
        economy: "2. Developed region: nonG7",
        income_grp: "1. High income: OECD",
        fips_10: "FI",
        iso_a2: "FI",
        iso_a2_eh: "FI",
        iso_a3: "FIN",
        iso_a3_eh: "FIN",
        iso_n3: "246",
        iso_n3_eh: "246",
        un_a3: "246",
        wb_a2: "FI",
        wb_a3: "FIN",
        woe_id: 23424812,
        woe_id_eh: 23424812,
        woe_note: "Exact WOE match as country",
        adm0_iso: "FIN",
        adm0_diff: null,
        adm0_tlc: "FIN",
        adm0_a3_us: "FIN",
        adm0_a3_fr: "FIN",
        adm0_a3_ru: "FIN",
        adm0_a3_es: "FIN",
        adm0_a3_cn: "FIN",
        adm0_a3_tw: "FIN",
        adm0_a3_in: "FIN",
        adm0_a3_np: "FIN",
        adm0_a3_pk: "FIN",
        adm0_a3_de: "FIN",
        adm0_a3_gb: "FIN",
        adm0_a3_br: "FIN",
        adm0_a3_il: "FIN",
        adm0_a3_ps: "FIN",
        adm0_a3_sa: "FIN",
        adm0_a3_eg: "FIN",
        adm0_a3_ma: "FIN",
        adm0_a3_pt: "FIN",
        adm0_a3_ar: "FIN",
        adm0_a3_jp: "FIN",
        adm0_a3_ko: "FIN",
        adm0_a3_vn: "FIN",
        adm0_a3_tr: "FIN",
        adm0_a3_id: "FIN",
        adm0_a3_pl: "FIN",
        adm0_a3_gr: "FIN",
        adm0_a3_it: "FIN",
        adm0_a3_nl: "FIN",
        adm0_a3_se: "FIN",
        adm0_a3_bd: "FIN",
        adm0_a3_ua: "FIN",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Northern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: 27.276449,
        label_y: 63.252361,
        ne_id: 1159320623,
        wikidataid: "Q33",
        name_ar: "فنلندا",
        name_bn: "ফিনল্যান্ড",
        name_de: "Finnland",
        name_en: "Finland",
        name_es: "Finlandia",
        name_fa: "فنلاند",
        name_fr: "Finlande",
        name_el: "Φινλανδία",
        name_he: "פינלנד",
        name_hi: "फ़िनलैण्ड",
        name_hu: "Finnország",
        name_id: "Finlandia",
        name_it: "Finlandia",
        name_ja: "フィンランド",
        name_ko: "핀란드",
        name_nl: "Finland",
        name_pl: "Finlandia",
        name_pt: "Finlândia",
        name_ru: "Финляндия",
        name_sv: "Finland",
        name_tr: "Finlandiya",
        name_uk: "Фінляндія",
        name_ur: "فن لینڈ",
        name_vi: "Phần Lan",
        name_zh: "芬兰",
        name_zht: "芬蘭",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "FIN.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [24.15546875000001, 65.8052734375],
              [24.04902343750001, 65.98984375],
              [23.99462890625, 66.0603515625],
              [23.907324218750006, 66.1482421875],
              [23.75146484375, 66.191162109375],
              [23.720996093750017, 66.2154296875],
              [23.700292968750006, 66.25263671875],
              [23.69355468750001, 66.304296875],
              [23.673828125, 66.380712890625],
              [23.682031250000023, 66.443408203125],
              [23.701171875, 66.48076171875],
              [23.768359375000017, 66.505859375],
              [23.865527343750017, 66.576611328125],
              [23.885839843750006, 66.62802734375],
              [23.894140625, 66.706884765625],
              [23.938867187500023, 66.775732421875],
              [23.988574218750017, 66.810546875],
              [23.97607421875, 66.838232421875],
              [23.941796875000023, 66.87783203125],
              [23.86933593750001, 66.934033203125],
              [23.75898437500001, 67.002587890625],
              [23.67734375, 67.068115234375],
              [23.641503906250023, 67.12939453125],
              [23.623046875, 67.184130859375],
              [23.626074218750006, 67.233935546875],
              [23.656640625000023, 67.267822265625],
              [23.76093750000001, 67.310498046875],
              [23.77490234375, 67.32861328125],
              [23.73359375000001, 67.422900390625],
              [23.66083984375001, 67.4400390625],
              [23.537109375, 67.449169921875],
              [23.468066406250017, 67.449951171875],
              [23.454882812500017, 67.46025390625],
              [23.451464843750017, 67.47919921875],
              [23.465429687500006, 67.51787109375],
              [23.504492187500006, 67.562158203125],
              [23.537011718750023, 67.590380859375],
              [23.541308593750017, 67.614306640625],
              [23.50019531250001, 67.69619140625],
              [23.48779296875, 67.79658203125],
              [23.501855468750023, 67.8751953125],
              [23.632910156250006, 67.933203125],
              [23.63886718750001, 67.95439453125],
              [23.47421875, 68.017333984375],
              [23.35546875, 68.088671875],
              [23.31855468750001, 68.130322265625],
              [23.182519531250023, 68.13662109375],
              [23.09785156250001, 68.257568359375],
              [22.975390625000017, 68.316455078125],
              [22.854101562500006, 68.367333984375],
              [22.78242187500001, 68.391015625],
              [22.362109375000017, 68.4640625],
              [22.195117187500017, 68.477978515625],
              [21.997460937500023, 68.52060546875],
              [21.850195312500006, 68.57412109375],
              [21.724023437500023, 68.608544921875],
              [21.616015625000017, 68.6509765625],
              [21.465429687500006, 68.690673828125],
              [21.42236328125, 68.724609375],
              [21.259765625, 68.787451171875],
              [21.183398437500017, 68.82880859375],
              [20.918554687500006, 68.90693359375],
              [20.908984375000017, 68.937744140625],
              [20.907031250000017, 68.96748046875],
              [20.895117187500006, 68.979833984375],
              [20.622167968750006, 69.036865234375],
              [20.675878906250006, 69.069482421875],
              [20.889257812500006, 69.071435546875],
              [21.065722656250017, 69.041748046875],
              [21.1044921875, 69.054443359375],
              [21.127832031250023, 69.080810546875],
              [21.052636718750023, 69.186572265625],
              [21.06611328125001, 69.214111328125],
              [21.14375, 69.247265625],
              [21.26679687500001, 69.273681640625],
              [21.461230468750017, 69.277490234375],
              [21.59375, 69.273583984375],
              [21.62177734375001, 69.270703125],
              [21.819726562500023, 69.1544921875],
              [21.98945312500001, 69.04111328125],
              [22.0796875, 68.9927734375],
              [22.300390625, 68.855859375],
              [22.382910156250006, 68.776611328125],
              [22.410937500000017, 68.719873046875],
              [22.50068359375001, 68.72021484375],
              [22.81103515625, 68.6953125],
              [23.071679687500023, 68.674365234375],
              [23.144335937500017, 68.642578125],
              [23.324023437500017, 68.648974609375],
              [23.4625, 68.67763671875],
              [23.70703125, 68.7138671875],
              [23.772558593750006, 68.7583984375],
              [23.85400390625, 68.805908203125],
              [23.997363281250017, 68.7984375],
              [24.154101562500017, 68.760888671875],
              [24.33203125, 68.7115234375],
              [24.490527343750017, 68.688671875],
              [24.703222656250006, 68.65283203125],
              [24.80244140625001, 68.606494140625],
              [24.94140625, 68.59326171875],
              [25.0869140625, 68.639599609375],
              [25.1728515625, 68.765283203125],
              [25.249121093750006, 68.821337890625],
              [25.35712890625001, 68.862451171875],
              [25.480859375000023, 68.880615234375],
              [25.575292968750006, 68.887158203125],
              [25.64667968750001, 68.919140625],
              [25.748339843750017, 68.99013671875],
              [25.768164062500006, 69.076123046875],
              [25.748632812500006, 69.2314453125],
              [25.7671875, 69.282666015625],
              [25.850195312500006, 69.36650390625],
              [25.961523437500006, 69.588623046875],
              [26.011523437500017, 69.65263671875],
              [26.07246093750001, 69.691552734375],
              [26.156152343750023, 69.714697265625],
              [26.308203125, 69.78193359375],
              [26.525390625, 69.9150390625],
              [26.584277343750017, 69.926318359375],
              [26.740234375, 69.933056640625],
              [26.93427734375001, 69.928125],
              [27.108691406250017, 69.9046875],
              [27.127539062500006, 69.906494140625],
              [27.205664062500006, 69.918701171875],
              [27.348046875000023, 69.96005859375],
              [27.591699218750023, 70.042236328125],
              [27.747851562500017, 70.06484375],
              [27.889941406250017, 70.061669921875],
              [28.047265625000023, 69.9716796875],
              [28.269140625, 69.871435546875],
              [28.41171875, 69.82275390625],
              [28.800390625, 69.731494140625],
              [29.1416015625, 69.671435546875],
              [29.333398437500023, 69.472998046875],
              [29.238867187500006, 69.3939453125],
              [29.191796875000023, 69.36669921875],
              [29.02490234375, 69.28798828125],
              [28.846289062500006, 69.176904296875],
              [28.832617187500006, 69.118994140625],
              [28.891894531250017, 69.060595703125],
              [28.9658203125, 69.02197265625],
              [28.89892578125, 69.00966796875],
              [28.692187500000017, 68.96103515625],
              [28.566015625, 68.92822265625],
              [28.4140625, 68.904150390625],
              [28.453515625000023, 68.872265625],
              [28.705957031250023, 68.86552734375],
              [28.74482421875001, 68.8564453125],
              [28.772851562500023, 68.8400390625],
              [28.777636718750017, 68.813818359375],
              [28.752050781250006, 68.771435546875],
              [28.479296875000017, 68.537646484375],
              [28.470703125, 68.48837890625],
              [28.56015625, 68.3513671875],
              [28.68515625, 68.189794921875],
              [29.06298828125, 68.11796875],
              [29.343847656250006, 68.061865234375],
              [29.524218750000017, 67.9291015625],
              [29.821582031250017, 67.75400390625],
              [29.97919921875001, 67.68857421875],
              [29.988085937500017, 67.66826171875],
              [29.941210937500017, 67.5474609375],
              [29.750585937500006, 67.426416015625],
              [29.572265625, 67.324365234375],
              [29.3876953125, 67.201416015625],
              [29.24335937500001, 67.09658203125],
              [29.087011718750006, 66.970947265625],
              [29.06904296875001, 66.930224609375],
              [29.066210937500017, 66.891748046875],
              [29.093066406250017, 66.84921875],
              [29.293261718750017, 66.6955078125],
              [29.371191406250006, 66.617041015625],
              [29.46435546875, 66.532177734375],
              [29.544335937500023, 66.439697265625],
              [29.590722656250023, 66.3568359375],
              [29.6708984375, 66.276123046875],
              [29.720703125, 66.23486328125],
              [29.803515625000017, 66.17705078125],
              [29.903417968750006, 66.091064453125],
              [29.936621093750006, 66.02294921875],
              [30.0875, 65.7865234375],
              [30.10273437500001, 65.72626953125],
              [30.0953125, 65.681689453125],
              [30.02900390625001, 65.670703125],
              [29.882617187500017, 65.663623046875],
              [29.723925781250017, 65.634375],
              [29.715917968750006, 65.624560546875],
              [29.819433593750006, 65.56875],
              [29.72802734375, 65.4734375],
              [29.71484375, 65.336962890625],
              [29.6171875, 65.26533203125],
              [29.608007812500006, 65.248681640625],
              [29.612402343750006, 65.234765625],
              [29.629687500000017, 65.223876953125],
              [29.810546875, 65.204736328125],
              [29.826171875, 65.185302734375],
              [29.826953125000017, 65.145068359375],
              [29.810839843750017, 65.10791015625],
              [29.720019531250017, 65.080322265625],
              [29.622460937500023, 65.039501953125],
              [29.600878906250017, 65.001953125],
              [29.60419921875001, 64.968408203125],
              [29.6375, 64.911767578125],
              [29.70166015625, 64.845751953125],
              [29.783203125, 64.804296875],
              [30.072851562500006, 64.7650390625],
              [30.110253906250023, 64.732568359375],
              [30.12617187500001, 64.6880859375],
              [30.1201171875, 64.64462890625],
              [29.98554687500001, 64.55771484375],
              [29.986621093750017, 64.524267578125],
              [30.041894531250023, 64.443359375],
              [30.10810546875001, 64.36611328125],
              [30.390625, 64.282421875],
              [30.487890625, 64.2365234375],
              [30.513769531250006, 64.2],
              [30.527929687500006, 64.14111328125],
              [30.52607421875001, 64.077294921875],
              [30.50390625, 64.02060546875],
              [30.415332031250017, 63.947509765625],
              [30.210253906250017, 63.8033203125],
              [30.00410156250001, 63.747314453125],
              [29.991503906250017, 63.73515625],
              [30.05537109375001, 63.689013671875],
              [30.418554687500006, 63.504052734374994],
              [30.6552734375, 63.41748046875],
              [30.97480468750001, 63.300634765625],
              [31.18085937500001, 63.20830078125],
              [31.247460937500023, 63.14189453125],
              [31.336718750000017, 63.06806640625],
              [31.43701171875, 63.007714843749994],
              [31.50927734375, 62.955322265625],
              [31.536523437500023, 62.921630859375],
              [31.533984375000017, 62.885400390624994],
              [31.437304687500017, 62.776123046875],
              [31.382421875, 62.691650390625],
              [31.28564453125, 62.567822265625],
              [31.18671875000001, 62.481396484375],
              [30.93574218750001, 62.323779296875],
              [30.565625, 62.127587890624994],
              [30.47968750000001, 62.068212890625],
              [30.306445312500017, 61.96484375],
              [30.00996093750001, 61.757373046875],
              [29.933203125, 61.711572265624994],
              [29.69013671875001, 61.54609375],
              [29.579394531250017, 61.49345703125],
              [29.49238281250001, 61.444238281249994],
              [29.25166015625001, 61.28779296875],
              [28.992968750000017, 61.16904296875],
              [28.739062500000017, 61.058740234374994],
              [28.662890625000017, 61.002832031249994],
              [28.568164062500017, 60.960205078125],
              [28.455078125, 60.91962890625],
              [28.40742187500001, 60.896923828125],
              [28.151953125, 60.745849609375],
              [27.797656250000017, 60.5361328125],
              [27.761621093750023, 60.532861328124994],
              [27.669335937500023, 60.498974609375],
              [27.52509765625001, 60.490771484375],
              [27.46240234375, 60.46484375],
              [27.24189453125001, 60.538671875],
              [27.20527343750001, 60.54345703125],
              [27.075585937500023, 60.525146484375],
              [26.951171875, 60.471484375],
              [26.721484375000017, 60.455078125],
              [26.607421875, 60.4376953125],
              [26.53466796875, 60.412890625],
              [26.51972656250001, 60.471582031249994],
              [26.551171875000023, 60.54599609375],
              [26.60175781250001, 60.595605468749994],
              [26.6064453125, 60.6279296875],
              [26.5693359375, 60.624560546875],
              [26.49580078125001, 60.551806640625],
              [26.456445312500023, 60.466796875],
              [26.377734375000017, 60.424072265625],
              [26.2046875, 60.406591796875],
              [26.036035156250023, 60.47490234375],
              [25.955957031250023, 60.47421875],
              [26.00625, 60.42529296875],
              [26.04023437500001, 60.37158203125],
              [26.03583984375001, 60.34150390625],
              [25.945898437500006, 60.34677734375],
              [25.845800781250006, 60.314599609374994],
              [25.75800781250001, 60.267529296875],
              [25.715429687500006, 60.267431640625],
              [25.65644531250001, 60.333203125],
              [25.548242187500023, 60.302490234375],
              [25.45576171875001, 60.26123046875],
              [25.267871093750017, 60.24833984375],
              [25.155859375, 60.194091796875],
              [24.957617187500006, 60.157470703125],
              [24.848730468750006, 60.158349609374994],
              [24.600488281250023, 60.1142578125],
              [24.517968750000023, 60.046289062499994],
              [24.445605468750017, 60.0212890625],
              [24.342578125000017, 60.042333984375],
              [24.025195312500017, 60.009179687499994],
              [23.721777343750006, 59.965673828125],
              [23.592675781250023, 59.968164062499994],
              [23.46357421875001, 59.986230468749994],
              [23.326757812500006, 59.92578125],
              [23.181445312500017, 59.844921875],
              [23.021289062500017, 59.816015625],
              [22.9638671875, 59.8263671875],
              [23.009765625, 59.868798828124994],
              [23.11572265625, 59.9126953125],
              [23.1884765625, 59.972216796875],
              [23.19843750000001, 60.021826171875],
              [23.1484375, 60.04130859375],
              [23.080175781250006, 60.047265625],
              [22.994140625, 60.098535156249994],
              [22.91171875, 60.209716796875],
              [22.867089843750023, 60.2158203125],
              [22.84443359375001, 60.18662109375],
              [22.819140625000017, 60.1013671875],
              [22.79345703125, 60.076806640624994],
              [22.749804687500017, 60.057275390624994],
              [22.697363281250006, 60.03759765625],
              [22.64619140625001, 60.02802734375],
              [22.462695312500017, 60.029199218749994],
              [22.438574218750006, 60.072265625],
              [22.438574218750006, 60.090283203125],
              [22.471093750000023, 60.14697265625],
              [22.442675781250017, 60.156884765624994],
              [22.4697265625, 60.201318359374994],
              [22.512988281250017, 60.19892578125],
              [22.564257812500017, 60.205517578125],
              [22.589941406250006, 60.228369140625],
              [22.587988281250006, 60.2556640625],
              [22.516699218750006, 60.262744140625],
              [22.512304687500006, 60.28134765625],
              [22.57587890625001, 60.35908203125],
              [22.5849609375, 60.38056640625],
              [22.560351562500017, 60.385009765625],
              [22.5205078125, 60.3765625],
              [22.257910156250006, 60.400927734375],
              [21.933984375000023, 60.50029296875],
              [21.854296875000017, 60.505419921875],
              [21.805273437500006, 60.594140625],
              [21.727148437500006, 60.582910156249994],
              [21.61328125, 60.53095703125],
              [21.52783203125, 60.57041015625],
              [21.43603515625, 60.59638671875],
              [21.41064453125, 60.636962890625],
              [21.411914062500017, 60.696826171875],
              [21.40400390625001, 60.767431640625],
              [21.37890625, 60.850048828125],
              [21.36054687500001, 60.96748046875],
              [21.377734375000017, 61.059228515624994],
              [21.450976562500017, 61.1271484375],
              [21.479101562500006, 61.1705078125],
              [21.513476562500017, 61.281201171875],
              [21.52119140625001, 61.41083984375],
              [21.501757812500017, 61.454980468749994],
              [21.506640625000017, 61.484326171875],
              [21.565039062500006, 61.484326171875],
              [21.55234375, 61.509521484375],
              [21.526660156250017, 61.523291015625],
              [21.49824218750001, 61.551953125],
              [21.5224609375, 61.567138671875],
              [21.592382812500006, 61.568212890625],
              [21.598046875000023, 61.577880859375],
              [21.60595703125, 61.591552734375],
              [21.551855468750006, 61.666845703125],
              [21.54560546875001, 61.702734375],
              [21.470507812500017, 61.811669921874994],
              [21.384863281250006, 61.914941406249994],
              [21.255957031250006, 61.989648437499994],
              [21.301660156250023, 62.112646484375],
              [21.35371093750001, 62.223828125],
              [21.343359375, 62.277392578125],
              [21.32343750000001, 62.342578125],
              [21.165625, 62.4140625],
              [21.1421875, 62.514794921874994],
              [21.103613281250006, 62.622949218749994],
              [21.1181640625, 62.6892578125],
              [21.143847656250017, 62.739990234375],
              [21.195703125000023, 62.79052734375],
              [21.45751953125, 62.95],
              [21.473535156250023, 63.033251953125],
              [21.650976562500006, 63.039306640625],
              [21.568652343750017, 63.113720703125],
              [21.549218750000023, 63.155517578125],
              [21.54511718750001, 63.204296875],
              [21.800390625, 63.237695312499994],
              [21.89570312500001, 63.21025390625],
              [22.12031250000001, 63.244140625],
              [22.319726562500023, 63.310449218749994],
              [22.316210937500017, 63.345654296875],
              [22.285546875000023, 63.377197265625],
              [22.243261718750006, 63.437939453125],
              [22.273242187500017, 63.45478515625],
              [22.345996093750017, 63.4423828125],
              [22.312597656250006, 63.472558593749994],
              [22.318652343750017, 63.50439453125],
              [22.398046875, 63.491162109375],
              [22.527636718750017, 63.579980468749994],
              [22.532324218750006, 63.647851562499994],
              [22.75625, 63.683349609375],
              [23.014453125000017, 63.821826171875],
              [23.133593750000017, 63.86494140625],
              [23.24873046875001, 63.896142578124994],
              [23.493945312500017, 64.03447265625],
              [23.598925781250017, 64.04091796875],
              [23.652929687500006, 64.1341796875],
              [23.861425781250006, 64.258251953125],
              [23.9248046875, 64.27412109375],
              [24.022265625000017, 64.385986328125],
              [24.2783203125, 64.515283203125],
              [24.440625, 64.680126953125],
              [24.530175781250023, 64.738671875],
              [24.557910156250017, 64.801025390625],
              [24.657617187500023, 64.806298828125],
              [24.74755859375, 64.852099609375],
              [24.942187500000017, 64.884033203125],
              [25.13427734375, 64.8751953125],
              [25.214257812500023, 64.853466796875],
              [25.288183593750006, 64.8603515625],
              [25.28076171875, 64.91640625],
              [25.22802734375, 64.951025390625],
              [25.27109375, 64.98427734375],
              [25.37265625, 65.00947265625],
              [25.3623046875, 65.06513671875],
              [25.340234375000023, 65.0986328125],
              [25.255859375, 65.14326171875],
              [25.2978515625, 65.243212890625],
              [25.307910156250017, 65.352734375],
              [25.34785156250001, 65.479248046875],
              [25.241796875, 65.5462890625],
              [24.83935546875, 65.6603515625],
              [24.764257812500006, 65.656396484375],
              [24.674902343750006, 65.670703125],
              [24.58154296875, 65.75712890625],
              [24.62324218750001, 65.831689453125],
              [24.628027343750006, 65.8591796875],
              [24.591601562500017, 65.858349609375],
              [24.532617187500023, 65.822021484375],
              [24.404296875, 65.78046875],
              [24.2375, 65.812353515625],
              [24.15546875000001, 65.8052734375],
            ],
          ],
          [
            [
              [21.994238281250006, 60.336669921875],
              [21.921484375, 60.332275390625],
              [21.818652343750017, 60.3818359375],
              [21.8056640625, 60.401220703125],
              [21.845996093750017, 60.412451171875],
              [21.8193359375, 60.452294921874994],
              [21.827246093750006, 60.469921875],
              [21.906835937500006, 60.4384765625],
              [21.950292968750006, 60.401708984375],
              [21.9078125, 60.3931640625],
              [21.979785156250017, 60.355224609375],
              [21.994238281250006, 60.336669921875],
            ],
          ],
          [
            [
              [21.2177734375, 63.24130859375],
              [21.228515625, 63.22265625],
              [21.287109375, 63.227783203125],
              [21.366015625000017, 63.261767578125],
              [21.421972656250006, 63.2458984375],
              [21.415625, 63.19736328125],
              [21.37763671875001, 63.19921875],
              [21.3671875, 63.2072265625],
              [21.318457031250006, 63.1794921875],
              [21.30976562500001, 63.1626953125],
              [21.25341796875, 63.152001953124994],
              [21.149316406250023, 63.199462890625],
              [21.083886718750023, 63.2775390625],
              [21.236328125, 63.277734375],
              [21.221777343750006, 63.259130859375],
              [21.2177734375, 63.24130859375],
            ],
          ],
          [
            [
              [22.175097656250017, 60.370751953124994],
              [22.3017578125, 60.347558593749994],
              [22.35498046875, 60.355859375],
              [22.41552734375, 60.303369140624994],
              [22.312890625000023, 60.269970703125],
              [22.305761718750006, 60.228564453125],
              [22.346289062500006, 60.20283203125],
              [22.36054687500001, 60.165576171875],
              [22.25830078125, 60.165625],
              [22.209375, 60.19697265625],
              [22.188085937500006, 60.236767578125],
              [22.140527343750023, 60.264892578125],
              [22.0771484375, 60.286328125],
              [22.108203125000017, 60.314892578125],
              [22.125878906250023, 60.355859375],
              [22.175097656250017, 60.370751953124994],
            ],
          ],
          [
            [
              [21.45087890625001, 60.52958984375],
              [21.436914062500023, 60.483056640624994],
              [21.369042968750023, 60.488232421875],
              [21.3, 60.47978515625],
              [21.24433593750001, 60.5259765625],
              [21.21455078125001, 60.603857421875],
              [21.224707031250006, 60.62060546875],
              [21.26806640625, 60.63828125],
              [21.30126953125, 60.595556640625],
              [21.45087890625001, 60.52958984375],
            ],
          ],
          [
            [
              [21.83320312500001, 60.140527343749994],
              [21.73310546875001, 60.10615234375],
              [21.69501953125001, 60.11435546875],
              [21.70478515625001, 60.172314453125],
              [21.764257812500006, 60.198828125],
              [21.864355468750006, 60.201806640624994],
              [21.83320312500001, 60.140527343749994],
            ],
          ],
          [
            [
              [21.628320312500023, 60.1078125],
              [21.540625, 60.097900390625],
              [21.48603515625001, 60.126806640625],
              [21.506738281250023, 60.148339843749994],
              [21.56796875, 60.172314453125],
              [21.634082031250017, 60.168994140625],
              [21.64814453125001, 60.140869140625],
              [21.628320312500023, 60.1078125],
            ],
          ],
          [
            [
              [24.848242187500006, 64.991015625],
              [24.69892578125001, 64.9578125],
              [24.57861328125, 64.978564453125],
              [24.576562500000023, 65.04287109375],
              [24.651171875000017, 65.073974609375],
              [24.786035156250023, 65.08642578125],
              [24.970605468750023, 65.055322265625],
              [24.99755859375, 65.038720703125],
              [24.89179687500001, 65.02626953125],
              [24.848242187500006, 64.991015625],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 2,
        sovereignt: "Belgium",
        sov_a3: "BEL",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Belgium",
        adm0_a3: "BEL",
        geou_dif: 0,
        geounit: "Belgium",
        gu_a3: "BEL",
        su_dif: 0,
        subunit: "Belgium",
        su_a3: "BEL",
        brk_diff: 0,
        name: "Belgium",
        name_long: "Belgium",
        brk_a3: "BEL",
        brk_name: "Belgium",
        brk_group: null,
        abbrev: "Belg.",
        postal: "B",
        formal_en: "Kingdom of Belgium",
        formal_fr: null,
        name_ciawf: "Belgium",
        note_adm0: null,
        note_brk: null,
        name_sort: "Belgium",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 2,
        mapcolor9: 1,
        mapcolor13: 8,
        pop_est: 11484055,
        pop_rank: 14,
        pop_year: 2019,
        gdp_md: 533097,
        gdp_year: 2019,
        economy: "2. Developed region: nonG7",
        income_grp: "1. High income: OECD",
        fips_10: "BE",
        iso_a2: "BE",
        iso_a2_eh: "BE",
        iso_a3: "BEL",
        iso_a3_eh: "BEL",
        iso_n3: "056",
        iso_n3_eh: "056",
        un_a3: "056",
        wb_a2: "BE",
        wb_a3: "BEL",
        woe_id: 23424757,
        woe_id_eh: 23424757,
        woe_note: "Exact WOE match as country",
        adm0_iso: "BEL",
        adm0_diff: null,
        adm0_tlc: "BEL",
        adm0_a3_us: "BEL",
        adm0_a3_fr: "BEL",
        adm0_a3_ru: "BEL",
        adm0_a3_es: "BEL",
        adm0_a3_cn: "BEL",
        adm0_a3_tw: "BEL",
        adm0_a3_in: "BEL",
        adm0_a3_np: "BEL",
        adm0_a3_pk: "BEL",
        adm0_a3_de: "BEL",
        adm0_a3_gb: "BEL",
        adm0_a3_br: "BEL",
        adm0_a3_il: "BEL",
        adm0_a3_ps: "BEL",
        adm0_a3_sa: "BEL",
        adm0_a3_eg: "BEL",
        adm0_a3_ma: "BEL",
        adm0_a3_pt: "BEL",
        adm0_a3_ar: "BEL",
        adm0_a3_jp: "BEL",
        adm0_a3_ko: "BEL",
        adm0_a3_vn: "BEL",
        adm0_a3_tr: "BEL",
        adm0_a3_id: "BEL",
        adm0_a3_pl: "BEL",
        adm0_a3_gr: "BEL",
        adm0_a3_it: "BEL",
        adm0_a3_nl: "BEL",
        adm0_a3_se: "BEL",
        adm0_a3_bd: "BEL",
        adm0_a3_ua: "BEL",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Western Europe",
        region_wb: "Europe & Central Asia",
        name_len: 7,
        long_len: 7,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: 4.800448,
        label_y: 50.785392,
        ne_id: 1159320389,
        wikidataid: "Q31",
        name_ar: "بلجيكا",
        name_bn: "বেলজিয়াম",
        name_de: "Belgien",
        name_en: "Belgium",
        name_es: "Bélgica",
        name_fa: "بلژیک",
        name_fr: "Belgique",
        name_el: "Βέλγιο",
        name_he: "בלגיה",
        name_hi: "बेल्जियम",
        name_hu: "Belgium",
        name_id: "Belgia",
        name_it: "Belgio",
        name_ja: "ベルギー",
        name_ko: "벨기에",
        name_nl: "België",
        name_pl: "Belgia",
        name_pt: "Bélgica",
        name_ru: "Бельгия",
        name_sv: "Belgien",
        name_tr: "Belçika",
        name_uk: "Бельгія",
        name_ur: "بلجئیم",
        name_vi: "Bỉ",
        name_zh: "比利时",
        name_zht: "比利時",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "BEL.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [4.226171875, 51.386474609375],
            [4.304492187500017, 51.3615234375],
            [4.373730468750011, 51.356005859374996],
            [4.404003906250011, 51.367089843749994],
            [4.384765625, 51.427587890625],
            [4.44091796875, 51.459814453125],
            [4.50341796875, 51.47470703125],
            [4.531640625000023, 51.448583984375],
            [4.588769531250023, 51.421923828124996],
            [4.633984375000011, 51.421728515625],
            [4.755664062500017, 51.491113281249994],
            [4.7841796875, 51.477392578125],
            [4.810546875, 51.452734375],
            [4.816015625, 51.4328125],
            [4.820703125000023, 51.412060546875],
            [4.848046875000023, 51.403271484375],
            [4.943945312500006, 51.407763671874996],
            [4.992578125000023, 51.445361328124996],
            [5.030957031250011, 51.469091796875],
            [5.059472656250023, 51.453125],
            [5.073437500000011, 51.4068359375],
            [5.099902343750017, 51.346484375],
            [5.214160156250017, 51.278955078124994],
            [5.310839843750017, 51.259716796875],
            [5.429785156250006, 51.272998046874996],
            [5.476855468750017, 51.285058593749994],
            [5.5087890625, 51.275],
            [5.540429687500023, 51.239306640624996],
            [5.608789062500023, 51.1984375],
            [5.752343750000023, 51.169482421874996],
            [5.796484375, 51.153076171875],
            [5.8271484375, 51.125634765624994],
            [5.818261718750023, 51.08642578125],
            [5.749804687500017, 50.98876953125],
            [5.740820312500006, 50.959912109375],
            [5.75, 50.950244140624996],
            [5.736621093750017, 50.93212890625],
            [5.647558593750006, 50.866650390625],
            [5.639453125000017, 50.843603515625],
            [5.669140625000011, 50.805957031249996],
            [5.693554687500011, 50.774755859375],
            [5.693652343750017, 50.774658203125],
            [5.7470703125, 50.759570312499996],
            [5.79736328125, 50.754541015624994],
            [5.892461122495295, 50.752556857983514],
            [5.993945312500017, 50.750439453125],
            [6.005957031250006, 50.732226562499996],
            [6.119433593750017, 50.679248046874996],
            [6.154492187500011, 50.637255859374996],
            [6.2359375, 50.5966796875],
            [6.16845703125, 50.545361328125],
            [6.1787109375, 50.522509765624996],
            [6.203027343750023, 50.49912109375],
            [6.294921875, 50.485498046874994],
            [6.340917968750006, 50.4517578125],
            [6.343652343750023, 50.400244140625],
            [6.364453125000011, 50.316162109375],
            [6.175097656250017, 50.232666015625],
            [6.121289062500011, 50.13935546875],
            [6.116503906250017, 50.120996093749994],
            [6.110058593750011, 50.123779296875],
            [6.089062500000011, 50.154589843749996],
            [6.054785156250006, 50.154296875],
            [5.976269531250011, 50.1671875],
            [5.866894531250011, 50.0828125],
            [5.8173828125, 50.0126953125],
            [5.7880859375, 49.961230468749996],
            [5.744042968750023, 49.91962890625],
            [5.735253906250023, 49.875634765624994],
            [5.740820312500006, 49.857177734375],
            [5.725781250000011, 49.833349609375],
            [5.725, 49.80830078125],
            [5.787988281250023, 49.75888671875],
            [5.8037109375, 49.732177734375],
            [5.88037109375, 49.644775390625],
            [5.856542968750006, 49.612841796874996],
            [5.837597656250011, 49.5783203125],
            [5.8154296875, 49.55380859375],
            [5.789746093750011, 49.53828125],
            [5.71044921875, 49.539208984374994],
            [5.610058593750011, 49.528222656249994],
            [5.542382812500023, 49.511035156249996],
            [5.50732421875, 49.510888671874994],
            [5.434667968750006, 49.554492187499996],
            [5.353515625, 49.61982421875],
            [5.301953125000011, 49.6509765625],
            [5.27880859375, 49.6779296875],
            [5.215039062500011, 49.689257812499996],
            [5.124121093750006, 49.721484375],
            [5.06103515625, 49.75654296875],
            [5.006933593750006, 49.778369140624996],
            [4.930566406250023, 49.7892578125],
            [4.867578125000023, 49.788134765624996],
            [4.84912109375, 49.847119140625],
            [4.841503906250011, 49.914501953125],
            [4.7900390625, 49.9595703125],
            [4.860546875000011, 50.135888671874994],
            [4.818652343750017, 50.153173828125],
            [4.772851562500023, 50.1390625],
            [4.706640625, 50.097070312499994],
            [4.675097656250017, 50.046875],
            [4.656152343750023, 50.00244140625],
            [4.545019531250006, 49.960253906249996],
            [4.36875, 49.944970703124994],
            [4.176074218750017, 49.960253906249996],
            [4.149316406250023, 49.971582031249994],
            [4.137011718750017, 49.98447265625],
            [4.136816406250006, 50],
            [4.150292968750023, 50.023876953125],
            [4.183886718750017, 50.05283203125],
            [4.192187500000017, 50.094140625],
            [4.15771484375, 50.1298828125],
            [4.13525390625, 50.143798828125],
            [4.144140625, 50.17841796875],
            [4.169628906250011, 50.22177734375],
            [4.174609375000017, 50.246484375],
            [4.044140625000011, 50.321337890624996],
            [3.94970703125, 50.3359375],
            [3.858105468750011, 50.33857421875],
            [3.78857421875, 50.346972656249996],
            [3.748046875, 50.343505859375],
            [3.718847656250006, 50.321679687499994],
            [3.689355468750023, 50.306054687499994],
            [3.667285156250017, 50.3248046875],
            [3.626757812500017, 50.457324218749996],
            [3.595410156250011, 50.47734375],
            [3.476953125000023, 50.499462890625],
            [3.316210937500017, 50.507373046874996],
            [3.273339843750023, 50.531542968749996],
            [3.249804687500017, 50.591162109375],
            [3.234960937500006, 50.662939453125],
            [3.182031250000023, 50.731689453125],
            [3.154882812500006, 50.748925781249994],
            [3.106835937500023, 50.779443359374994],
            [3.022851562500023, 50.766894531249996],
            [2.921972656250006, 50.72705078125],
            [2.862402343750006, 50.716015625],
            [2.839746093750023, 50.711767578125],
            [2.759375, 50.750634765624994],
            [2.669140625000011, 50.811425781249994],
            [2.596777343750006, 50.875927734375],
            [2.579296875000011, 50.911767578124994],
            [2.601464843750023, 50.9552734375],
            [2.574804687500006, 50.988574218749996],
            [2.536035156250023, 51.04951171875],
            [2.52490234375, 51.097119140625],
            [2.960156250000011, 51.265429687499996],
            [3.225195312500006, 51.351611328124996],
            [3.35009765625, 51.377685546875],
            [3.380078125000011, 51.29111328125],
            [3.40283203125, 51.263623046875],
            [3.432519531250023, 51.245751953124994],
            [3.471972656250017, 51.242236328124996],
            [3.51708984375, 51.263623046875],
            [3.580273437500011, 51.286181640624996],
            [3.681835937500011, 51.275683593749996],
            [3.755664062500017, 51.254833984375],
            [3.781933593750011, 51.233203125],
            [3.830761718750011, 51.21259765625],
            [3.902050781250011, 51.207666015624994],
            [4.0400390625, 51.2470703125],
            [4.172558593750011, 51.307080078125],
            [4.21142578125, 51.34873046875],
            [4.226171875, 51.386474609375],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 6,
        sovereignt: "Luxembourg",
        sov_a3: "LUX",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Luxembourg",
        adm0_a3: "LUX",
        geou_dif: 0,
        geounit: "Luxembourg",
        gu_a3: "LUX",
        su_dif: 0,
        subunit: "Luxembourg",
        su_a3: "LUX",
        brk_diff: 0,
        name: "Luxembourg",
        name_long: "Luxembourg",
        brk_a3: "LUX",
        brk_name: "Luxembourg",
        brk_group: null,
        abbrev: "Lux.",
        postal: "L",
        formal_en: "Grand Duchy of Luxembourg",
        formal_fr: null,
        name_ciawf: "Luxembourg",
        note_adm0: null,
        note_brk: null,
        name_sort: "Luxembourg",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 7,
        mapcolor9: 3,
        mapcolor13: 7,
        pop_est: 619896,
        pop_rank: 11,
        pop_year: 2019,
        gdp_md: 71104,
        gdp_year: 2019,
        economy: "2. Developed region: nonG7",
        income_grp: "1. High income: OECD",
        fips_10: "LU",
        iso_a2: "LU",
        iso_a2_eh: "LU",
        iso_a3: "LUX",
        iso_a3_eh: "LUX",
        iso_n3: "442",
        iso_n3_eh: "442",
        un_a3: "442",
        wb_a2: "LU",
        wb_a3: "LUX",
        woe_id: 23424881,
        woe_id_eh: 23424881,
        woe_note: "Exact WOE match as country",
        adm0_iso: "LUX",
        adm0_diff: null,
        adm0_tlc: "LUX",
        adm0_a3_us: "LUX",
        adm0_a3_fr: "LUX",
        adm0_a3_ru: "LUX",
        adm0_a3_es: "LUX",
        adm0_a3_cn: "LUX",
        adm0_a3_tw: "LUX",
        adm0_a3_in: "LUX",
        adm0_a3_np: "LUX",
        adm0_a3_pk: "LUX",
        adm0_a3_de: "LUX",
        adm0_a3_gb: "LUX",
        adm0_a3_br: "LUX",
        adm0_a3_il: "LUX",
        adm0_a3_ps: "LUX",
        adm0_a3_sa: "LUX",
        adm0_a3_eg: "LUX",
        adm0_a3_ma: "LUX",
        adm0_a3_pt: "LUX",
        adm0_a3_ar: "LUX",
        adm0_a3_jp: "LUX",
        adm0_a3_ko: "LUX",
        adm0_a3_vn: "LUX",
        adm0_a3_tr: "LUX",
        adm0_a3_id: "LUX",
        adm0_a3_pl: "LUX",
        adm0_a3_gr: "LUX",
        adm0_a3_it: "LUX",
        adm0_a3_nl: "LUX",
        adm0_a3_se: "LUX",
        adm0_a3_bd: "LUX",
        adm0_a3_ua: "LUX",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Western Europe",
        region_wb: "Europe & Central Asia",
        name_len: 10,
        long_len: 10,
        abbrev_len: 4,
        tiny: 5,
        homepart: 1,
        min_zoom: 0,
        min_label: 5.7,
        max_label: 10,
        label_x: 6.07762,
        label_y: 49.733732,
        ne_id: 1159321031,
        wikidataid: "Q32",
        name_ar: "لوكسمبورغ",
        name_bn: "লুক্সেমবুর্গ",
        name_de: "Luxemburg",
        name_en: "Luxembourg",
        name_es: "Luxemburgo",
        name_fa: "لوکزامبورگ",
        name_fr: "Luxembourg",
        name_el: "Λουξεμβούργο",
        name_he: "לוקסמבורג",
        name_hi: "लक्ज़मबर्ग",
        name_hu: "Luxemburg",
        name_id: "Luksemburg",
        name_it: "Lussemburgo",
        name_ja: "ルクセンブルク",
        name_ko: "룩셈부르크",
        name_nl: "Luxemburg",
        name_pl: "Luksemburg",
        name_pt: "Luxemburgo",
        name_ru: "Люксембург",
        name_sv: "Luxemburg",
        name_tr: "Lüksemburg",
        name_uk: "Люксембург",
        name_ur: "لکسمبرگ",
        name_vi: "Luxembourg",
        name_zh: "卢森堡",
        name_zht: "盧森堡",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "LUX.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [6.116503906250017, 50.120996093749994],
            [6.108300781250023, 50.09423828125],
            [6.109765625000023, 50.034375],
            [6.13818359375, 49.97431640625],
            [6.204882812500017, 49.91513671875],
            [6.256054687500011, 49.87216796875],
            [6.324609375000023, 49.837890625],
            [6.44091796875, 49.805322265624994],
            [6.4873046875, 49.798486328124994],
            [6.49375, 49.75439453125],
            [6.484765625000023, 49.7078125],
            [6.444628906250017, 49.68203125],
            [6.40673828125, 49.644970703125],
            [6.378320312500023, 49.599609375],
            [6.348437500000017, 49.5126953125],
            [6.344335937500006, 49.452734375],
            [6.27734375, 49.4775390625],
            [6.2421875, 49.4943359375],
            [6.181054687500023, 49.498925781249994],
            [6.119921875000017, 49.485205078125],
            [6.074121093750023, 49.454638671874996],
            [6.011425781250011, 49.445458984374994],
            [5.95947265625, 49.454638671874996],
            [5.928906250000011, 49.4775390625],
            [5.9013671875, 49.48974609375],
            [5.823437500000011, 49.505078125],
            [5.789746093750011, 49.53828125],
            [5.8154296875, 49.55380859375],
            [5.837597656250011, 49.5783203125],
            [5.856542968750006, 49.612841796874996],
            [5.88037109375, 49.644775390625],
            [5.8037109375, 49.732177734375],
            [5.787988281250023, 49.75888671875],
            [5.725, 49.80830078125],
            [5.725781250000011, 49.833349609375],
            [5.740820312500006, 49.857177734375],
            [5.735253906250023, 49.875634765624994],
            [5.744042968750023, 49.91962890625],
            [5.7880859375, 49.961230468749996],
            [5.8173828125, 50.0126953125],
            [5.866894531250011, 50.0828125],
            [5.976269531250011, 50.1671875],
            [6.054785156250006, 50.154296875],
            [6.089062500000011, 50.154589843749996],
            [6.110058593750011, 50.123779296875],
            [6.116503906250017, 50.120996093749994],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 6,
        sovereignt: "North Macedonia",
        sov_a3: "MKD",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "North Macedonia",
        adm0_a3: "MKD",
        geou_dif: 0,
        geounit: "North Macedonia",
        gu_a3: "MKD",
        su_dif: 0,
        subunit: "North Macedonia",
        su_a3: "MKD",
        brk_diff: 0,
        name: "North Macedonia",
        name_long: "North Macedonia",
        brk_a3: "MKD",
        brk_name: "North Macedonia",
        brk_group: null,
        abbrev: "N. Mac.",
        postal: "NM",
        formal_en: "Republic of North Macedonia",
        formal_fr: null,
        name_ciawf: "North Macedonia",
        note_adm0: null,
        note_brk: null,
        name_sort: "North Macedonia",
        name_alt: null,
        mapcolor7: 5,
        mapcolor8: 3,
        mapcolor9: 7,
        mapcolor13: 3,
        pop_est: 2083459,
        pop_rank: 12,
        pop_year: 2019,
        gdp_md: 12547,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        fips_10: "MK",
        iso_a2: "MK",
        iso_a2_eh: "MK",
        iso_a3: "MKD",
        iso_a3_eh: "MKD",
        iso_n3: "807",
        iso_n3_eh: "807",
        un_a3: "807",
        wb_a2: "MK",
        wb_a3: "MKD",
        woe_id: 23424890,
        woe_id_eh: 23424890,
        woe_note: "Exact WOE match as country",
        adm0_iso: "MKD",
        adm0_diff: null,
        adm0_tlc: "MKD",
        adm0_a3_us: "MKD",
        adm0_a3_fr: "MKD",
        adm0_a3_ru: "MKD",
        adm0_a3_es: "MKD",
        adm0_a3_cn: "MKD",
        adm0_a3_tw: "MKD",
        adm0_a3_in: "MKD",
        adm0_a3_np: "MKD",
        adm0_a3_pk: "MKD",
        adm0_a3_de: "MKD",
        adm0_a3_gb: "MKD",
        adm0_a3_br: "MKD",
        adm0_a3_il: "MKD",
        adm0_a3_ps: "MKD",
        adm0_a3_sa: "MKD",
        adm0_a3_eg: "MKD",
        adm0_a3_ma: "MKD",
        adm0_a3_pt: "MKD",
        adm0_a3_ar: "MKD",
        adm0_a3_jp: "MKD",
        adm0_a3_ko: "MKD",
        adm0_a3_vn: "MKD",
        adm0_a3_tr: "MKD",
        adm0_a3_id: "MKD",
        adm0_a3_pl: "MKD",
        adm0_a3_gr: "MKD",
        adm0_a3_it: "MKD",
        adm0_a3_nl: "MKD",
        adm0_a3_se: "MKD",
        adm0_a3_bd: "MKD",
        adm0_a3_ua: "MKD",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Southern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 15,
        long_len: 15,
        abbrev_len: 7,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 5,
        max_label: 10,
        label_x: 21.555839,
        label_y: 41.558223,
        ne_id: 1159321061,
        wikidataid: "Q221",
        name_ar: "مقدونيا الشمالية",
        name_bn: "উত্তর মেসিডোনিয়া",
        name_de: "Nordmazedonien",
        name_en: "North Macedonia",
        name_es: "Macedonia del Norte",
        name_fa: "مقدونیه شمالی",
        name_fr: "Macédoine du Nord",
        name_el: "Βόρεια Μακεδονία",
        name_he: "מקדוניה הצפונית",
        name_hi: "उत्तर मैसिडोनिया",
        name_hu: "Észak-Macedónia",
        name_id: "Republik Makedonia Utara",
        name_it: "Macedonia del Nord",
        name_ja: "北マケドニア",
        name_ko: "북마케도니아",
        name_nl: "Noord-Macedonië",
        name_pl: "Macedonia Północna",
        name_pt: "Macedónia do Norte",
        name_ru: "Северная Македония",
        name_sv: "Nordmakedonien",
        name_tr: "Kuzey Makedonya",
        name_uk: "Північна Македонія",
        name_ur: "شمالی مقدونیہ",
        name_vi: "Bắc Macedonia",
        name_zh: "北马其顿",
        name_zht: "北馬其頓",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "MKD.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [21.5625, 42.247509765625],
            [21.618261718750006, 42.242138671875],
            [21.7392578125, 42.267724609374994],
            [21.81464843750001, 42.303125],
            [21.85302734375, 42.308398437499996],
            [21.904101562500017, 42.322070312499996],
            [21.9775390625, 42.320068359375],
            [22.052050781250017, 42.304638671875],
            [22.14667968750001, 42.325],
            [22.23974609375, 42.358154296875],
            [22.27705078125001, 42.349853515625],
            [22.3173828125, 42.321728515625],
            [22.344042968750017, 42.31396484375],
            [22.49824218750001, 42.165087890624996],
            [22.58271484375001, 42.104833984375],
            [22.68232421875001, 42.059130859374996],
            [22.79609375000001, 42.025683593749996],
            [22.836816406250023, 41.993603515625],
            [22.9091796875, 41.835205078125],
            [22.943945312500006, 41.77509765625],
            [22.991992187500017, 41.757177734375],
            [23.00361328125001, 41.73984375],
            [23.005664062500017, 41.716943359374994],
            [22.951464843750017, 41.605615234374994],
            [22.9296875, 41.356103515624994],
            [22.916015625, 41.336279296875],
            [22.859277343750023, 41.337353515625],
            [22.78388671875001, 41.331982421875],
            [22.75507812500001, 41.312744140625],
            [22.72480468750001, 41.178515625],
            [22.603613281250006, 41.140185546874996],
            [22.493554687500023, 41.118505859375],
            [22.400781250000023, 41.123388671875],
            [22.237695312500023, 41.155175781249994],
            [22.184472656250023, 41.158642578125],
            [22.13886718750001, 41.140527343749994],
            [21.99335937500001, 41.13095703125],
            [21.929492187500017, 41.107421875],
            [21.77949218750001, 40.950439453125],
            [21.627539062500006, 40.896337890625],
            [21.57578125, 40.868945312499996],
            [21.45966796875001, 40.903613281249996],
            [21.404101562500017, 40.907177734375],
            [21.32373046875, 40.867138671875],
            [21.147558593750006, 40.863134765625],
            [21.1, 40.85615234375],
            [20.964257812500023, 40.849902343749996],
            [20.95859375, 40.871533203125],
            [20.933496093750023, 40.903125],
            [20.870214843750006, 40.917919921875],
            [20.740820312500006, 40.9052734375],
            [20.709277343750017, 40.928369140624994],
            [20.656054687500017, 41.061669921874994],
            [20.61445312500001, 41.083056640624996],
            [20.56787109375, 41.127832031249994],
            [20.48896484375001, 41.272607421874994],
            [20.48701171875001, 41.336083984374994],
            [20.49238281250001, 41.39140625],
            [20.448632812500023, 41.521289062499996],
            [20.4755859375, 41.554101562499994],
            [20.516210937500006, 41.574755859374996],
            [20.5166015625, 41.62705078125],
            [20.505175781250017, 41.706494140625],
            [20.553125, 41.862353515624996],
            [20.566210937500017, 41.873681640624994],
            [20.578515625000023, 41.8662109375],
            [20.694921875, 41.853808593749996],
            [20.725, 41.87353515625],
            [20.744140625, 41.904296875],
            [20.750390625000023, 42.018359375],
            [20.778125, 42.071044921875],
            [21.05976562500001, 42.171289062499994],
            [21.142480468750023, 42.175],
            [21.2060546875, 42.128955078124996],
            [21.25634765625, 42.099511718749994],
            [21.28662109375, 42.100390625],
            [21.29755859375001, 42.130078125],
            [21.33173828125001, 42.187158203124994],
            [21.389550781250023, 42.21982421875],
            [21.560839843750017, 42.24765625],
            [21.5625, 42.247509765625],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 6,
        sovereignt: "Albania",
        sov_a3: "ALB",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Albania",
        adm0_a3: "ALB",
        geou_dif: 0,
        geounit: "Albania",
        gu_a3: "ALB",
        su_dif: 0,
        subunit: "Albania",
        su_a3: "ALB",
        brk_diff: 0,
        name: "Albania",
        name_long: "Albania",
        brk_a3: "ALB",
        brk_name: "Albania",
        brk_group: null,
        abbrev: "Alb.",
        postal: "AL",
        formal_en: "Republic of Albania",
        formal_fr: null,
        name_ciawf: "Albania",
        note_adm0: null,
        note_brk: null,
        name_sort: "Albania",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 4,
        mapcolor9: 1,
        mapcolor13: 6,
        pop_est: 2854191,
        pop_rank: 12,
        pop_year: 2019,
        gdp_md: 15279,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        fips_10: "AL",
        iso_a2: "AL",
        iso_a2_eh: "AL",
        iso_a3: "ALB",
        iso_a3_eh: "ALB",
        iso_n3: "008",
        iso_n3_eh: "008",
        un_a3: "008",
        wb_a2: "AL",
        wb_a3: "ALB",
        woe_id: 23424742,
        woe_id_eh: 23424742,
        woe_note: "Exact WOE match as country",
        adm0_iso: "ALB",
        adm0_diff: null,
        adm0_tlc: "ALB",
        adm0_a3_us: "ALB",
        adm0_a3_fr: "ALB",
        adm0_a3_ru: "ALB",
        adm0_a3_es: "ALB",
        adm0_a3_cn: "ALB",
        adm0_a3_tw: "ALB",
        adm0_a3_in: "ALB",
        adm0_a3_np: "ALB",
        adm0_a3_pk: "ALB",
        adm0_a3_de: "ALB",
        adm0_a3_gb: "ALB",
        adm0_a3_br: "ALB",
        adm0_a3_il: "ALB",
        adm0_a3_ps: "ALB",
        adm0_a3_sa: "ALB",
        adm0_a3_eg: "ALB",
        adm0_a3_ma: "ALB",
        adm0_a3_pt: "ALB",
        adm0_a3_ar: "ALB",
        adm0_a3_jp: "ALB",
        adm0_a3_ko: "ALB",
        adm0_a3_vn: "ALB",
        adm0_a3_tr: "ALB",
        adm0_a3_id: "ALB",
        adm0_a3_pl: "ALB",
        adm0_a3_gr: "ALB",
        adm0_a3_it: "ALB",
        adm0_a3_nl: "ALB",
        adm0_a3_se: "ALB",
        adm0_a3_bd: "ALB",
        adm0_a3_ua: "ALB",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Southern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 5,
        max_label: 10,
        label_x: 20.11384,
        label_y: 40.654855,
        ne_id: 1159320325,
        wikidataid: "Q222",
        name_ar: "ألبانيا",
        name_bn: "আলবেনিয়া",
        name_de: "Albanien",
        name_en: "Albania",
        name_es: "Albania",
        name_fa: "آلبانی",
        name_fr: "Albanie",
        name_el: "Αλβανία",
        name_he: "אלבניה",
        name_hi: "अल्बानिया",
        name_hu: "Albánia",
        name_id: "Albania",
        name_it: "Albania",
        name_ja: "アルバニア",
        name_ko: "알바니아",
        name_nl: "Albanië",
        name_pl: "Albania",
        name_pt: "Albânia",
        name_ru: "Албания",
        name_sv: "Albanien",
        name_tr: "Arnavutluk",
        name_uk: "Албанія",
        name_ur: "البانیا",
        name_vi: "Albania",
        name_zh: "阿尔巴尼亚",
        name_zht: "阿爾巴尼亞",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "ALB.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [19.342382812500006, 41.869091796875],
            [19.345507812500017, 41.918847656249994],
            [19.361132812500017, 41.99775390625],
            [19.352148437500006, 42.0240234375],
            [19.361425781250006, 42.069091796875],
            [19.330859375000017, 42.129296875],
            [19.280664062500023, 42.17255859375],
            [19.329003906250023, 42.249267578125],
            [19.39960937500001, 42.34189453125],
            [19.465136718750017, 42.415380859375],
            [19.54453125, 42.491943359375],
            [19.597460937500017, 42.5654296875],
            [19.65449218750001, 42.628564453124994],
            [19.703417968750017, 42.64794921875],
            [19.727832031250017, 42.634521484375],
            [19.74072265625, 42.60693359375],
            [19.73779296875, 42.525146484375],
            [19.754492187500006, 42.496923828125],
            [19.78828125000001, 42.476171875],
            [19.859765625000023, 42.486328125],
            [19.9390625, 42.506689453125],
            [20.045703125000017, 42.54990234375],
            [20.06396484375, 42.547265625],
            [20.103515625, 42.524658203125],
            [20.18574218750001, 42.42587890625],
            [20.240527343750017, 42.33896484375],
            [20.348242187500006, 42.3087890625],
            [20.408300781250006, 42.274951171874996],
            [20.485449218750006, 42.223388671875],
            [20.522851562500023, 42.171484375],
            [20.57539062500001, 42.013085937499994],
            [20.581445312500023, 41.917431640625],
            [20.566210937500017, 41.873681640624994],
            [20.553125, 41.862353515624996],
            [20.505175781250017, 41.706494140625],
            [20.5166015625, 41.62705078125],
            [20.516210937500006, 41.574755859374996],
            [20.4755859375, 41.554101562499994],
            [20.448632812500023, 41.521289062499996],
            [20.49238281250001, 41.39140625],
            [20.48701171875001, 41.336083984374994],
            [20.48896484375001, 41.272607421874994],
            [20.56787109375, 41.127832031249994],
            [20.61445312500001, 41.083056640624996],
            [20.656054687500017, 41.061669921874994],
            [20.709277343750017, 40.928369140624994],
            [20.740820312500006, 40.9052734375],
            [20.870214843750006, 40.917919921875],
            [20.933496093750023, 40.903125],
            [20.95859375, 40.871533203125],
            [20.964257812500023, 40.849902343749996],
            [20.95576171875001, 40.775292968749994],
            [20.987890625, 40.7177734375],
            [21.031054687500017, 40.658642578125],
            [21.030859375, 40.622460937499994],
            [21.001953125, 40.563378906249994],
            [20.9501953125, 40.494384765625],
            [20.881640625000017, 40.467919921874994],
            [20.806054687500023, 40.445458984374994],
            [20.77001953125, 40.391894531249996],
            [20.75166015625001, 40.334912109375],
            [20.717871093750006, 40.29267578125],
            [20.69697265625001, 40.246386718749996],
            [20.664941406250023, 40.151757812499994],
            [20.65742187500001, 40.1173828125],
            [20.60625, 40.082666015624994],
            [20.52705078125001, 40.068505859374994],
            [20.4560546875, 40.065576171874994],
            [20.408007812500017, 40.049462890624994],
            [20.383691406250023, 40.0171875],
            [20.338476562500006, 39.991064453125],
            [20.311132812500006, 39.979443359375],
            [20.311328125000017, 39.95078125],
            [20.34423828125, 39.890625],
            [20.381640625000017, 39.841796875],
            [20.382421875, 39.802636718749994],
            [20.364062500000017, 39.791748046875],
            [20.30615234375, 39.796679687499996],
            [20.293847656250023, 39.7822265625],
            [20.28759765625, 39.738574218749996],
            [20.272070312500006, 39.701171875],
            [20.24824218750001, 39.678369140624994],
            [20.206835937500017, 39.653515625],
            [20.13105468750001, 39.66162109375],
            [20.05976562500001, 39.699121093749994],
            [20.022558593750006, 39.710693359375],
            [20.001269531250017, 39.709423828125],
            [19.99560546875, 39.801025390625],
            [19.96484375, 39.872265625],
            [19.851855468750017, 40.0435546875],
            [19.48457031250001, 40.2099609375],
            [19.39814453125001, 40.28486328125],
            [19.360156250000017, 40.347705078124996],
            [19.322265625, 40.407080078125],
            [19.35859375000001, 40.408740234374996],
            [19.39453125, 40.393701171874994],
            [19.440527343750006, 40.37568359375],
            [19.45917968750001, 40.40537109375],
            [19.439257812500017, 40.470263671874996],
            [19.344628906250023, 40.6220703125],
            [19.3375, 40.663818359375],
            [19.383886718750006, 40.79072265625],
            [19.461230468750017, 40.93330078125],
            [19.4560546875, 41.1060546875],
            [19.480078125, 41.236376953124996],
            [19.453417968750017, 41.32099609375],
            [19.440625, 41.424755859375],
            [19.497363281250017, 41.5626953125],
            [19.545800781250023, 41.596826171874994],
            [19.57568359375, 41.640429687499996],
            [19.577539062500023, 41.7875],
            [19.46826171875, 41.85615234375],
            [19.342382812500006, 41.869091796875],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 6,
        sovereignt: "Kosovo",
        sov_a3: "KOS",
        adm0_dif: 0,
        level: 2,
        type: "Disputed",
        tlc: "1",
        admin: "Kosovo",
        adm0_a3: "KOS",
        geou_dif: 0,
        geounit: "Kosovo",
        gu_a3: "KOS",
        su_dif: 0,
        subunit: "Kosovo",
        su_a3: "KOS",
        brk_diff: 0,
        name: "Kosovo",
        name_long: "Kosovo",
        brk_a3: "KOS",
        brk_name: "Kosovo",
        brk_group: null,
        abbrev: "Kos.",
        postal: "KO",
        formal_en: "Republic of Kosovo",
        formal_fr: null,
        name_ciawf: "Kosovo",
        note_adm0: null,
        note_brk: null,
        name_sort: "Kosovo",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 2,
        mapcolor9: 3,
        mapcolor13: 11,
        pop_est: 1794248,
        pop_rank: 12,
        pop_year: 2019,
        gdp_md: 7926,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        fips_10: "KV",
        iso_a2: "-99",
        iso_a2_eh: "XK",
        iso_a3: "-99",
        iso_a3_eh: "-99",
        iso_n3: "-99",
        iso_n3_eh: "-99",
        un_a3: "-099",
        wb_a2: "KV",
        wb_a3: "KSV",
        woe_id: -90,
        woe_id_eh: 29389201,
        woe_note:
          "Subunit of Serbia in WOE still; should include 29389201, 29389207, 29389218, 29389209 and 29389214.",
        adm0_iso: "SRB",
        adm0_diff: "1",
        adm0_tlc: "KOS",
        adm0_a3_us: "KOS",
        adm0_a3_fr: "KOS",
        adm0_a3_ru: "SRB",
        adm0_a3_es: "SRB",
        adm0_a3_cn: "SRB",
        adm0_a3_tw: "KOS",
        adm0_a3_in: "SRB",
        adm0_a3_np: "SRB",
        adm0_a3_pk: "KOS",
        adm0_a3_de: "KOS",
        adm0_a3_gb: "SRB",
        adm0_a3_br: "KOS",
        adm0_a3_il: "KOS",
        adm0_a3_ps: "SRB",
        adm0_a3_sa: "KOS",
        adm0_a3_eg: "KOS",
        adm0_a3_ma: "SRB",
        adm0_a3_pt: "KOS",
        adm0_a3_ar: "SRB",
        adm0_a3_jp: "KOS",
        adm0_a3_ko: "KOS",
        adm0_a3_vn: "SRB",
        adm0_a3_tr: "KOS",
        adm0_a3_id: "SRB",
        adm0_a3_pl: "KOS",
        adm0_a3_gr: "SRB",
        adm0_a3_it: "KOS",
        adm0_a3_nl: "KOS",
        adm0_a3_se: "KOS",
        adm0_a3_bd: "KOS",
        adm0_a3_ua: "SRB",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Southern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 6,
        long_len: 6,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 5,
        max_label: 10,
        label_x: 20.860719,
        label_y: 42.593587,
        ne_id: 1159321007,
        wikidataid: "Q1246",
        name_ar: "كوسوفو",
        name_bn: "কসোভো",
        name_de: "Kosovo",
        name_en: "Kosovo",
        name_es: "Kosovo",
        name_fa: "کوزووو",
        name_fr: "Kosovo",
        name_el: "Κοσσυφοπέδιο",
        name_he: "קוסובו",
        name_hi: "कोसोवो गणराज्य",
        name_hu: "Koszovó",
        name_id: "Kosovo",
        name_it: "Kosovo",
        name_ja: "コソボ共和国",
        name_ko: "코소보",
        name_nl: "Kosovo",
        name_pl: "Kosowo",
        name_pt: "Kosovo",
        name_ru: "Республика Косово",
        name_sv: "Kosovo",
        name_tr: "Kosova",
        name_uk: "Косово",
        name_ur: "کوسووہ",
        name_vi: "Kosovo",
        name_zh: "科索沃",
        name_zht: "科索沃",
        fclass_iso: "Unrecognized",
        tlc_diff: "1",
        fclass_tlc: "Admin-0 country",
        fclass_us: "Admin-0 country",
        fclass_fr: "Admin-0 country",
        fclass_ru: "Admin-1 region",
        fclass_es: "Unrecognized",
        fclass_cn: "Unrecognized",
        fclass_tw: "Admin-0 country",
        fclass_in: "Admin-1 region",
        fclass_np: "Unrecognized",
        fclass_pk: "Admin-0 country",
        fclass_de: "Admin-0 country",
        fclass_gb: "Admin-0 country",
        fclass_br: "Unrecognized",
        fclass_il: "Admin-0 country",
        fclass_ps: "Unrecognized",
        fclass_sa: "Admin-0 country",
        fclass_eg: "Admin-0 country",
        fclass_ma: "Unrecognized",
        fclass_pt: "Admin-0 country",
        fclass_ar: "Unrecognized",
        fclass_jp: "Admin-0 country",
        fclass_ko: "Admin-0 country",
        fclass_vn: "Unrecognized",
        fclass_tr: "Admin-0 country",
        fclass_id: "Unrecognized",
        fclass_pl: "Admin-0 country",
        fclass_gr: "Unrecognized",
        fclass_it: "Admin-0 country",
        fclass_nl: "Admin-0 country",
        fclass_se: "Admin-0 country",
        fclass_bd: "Admin-0 country",
        fclass_ua: "Unrecognized",
        filename: "kosovo.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [20.344335937500006, 42.827929687499996],
            [20.468847656250006, 42.85791015625],
            [20.48681640625, 42.879052734374994],
            [20.458398437500023, 42.924560546875],
            [20.47509765625, 42.953027343749994],
            [20.6240234375, 43.0341796875],
            [20.648535156250006, 43.070947265624994],
            [20.657617187500023, 43.099853515625],
            [20.637597656250023, 43.13037109375],
            [20.609667968750017, 43.17841796875],
            [20.623144531250006, 43.198632812499994],
            [20.700585937500023, 43.226367187499996],
            [20.76337890625001, 43.25859375],
            [20.800585937500017, 43.261083984375],
            [20.823828125, 43.237939453124994],
            [20.823828125, 43.21396484375],
            [20.84443359375001, 43.1734375],
            [20.890722656250006, 43.151660156249996],
            [20.967675781250023, 43.116015625],
            [21.057031250000023, 43.091699218749994],
            [21.127050781250006, 43.043017578124996],
            [21.22265625, 42.956201171874994],
            [21.237109375000017, 42.913232421874994],
            [21.323144531250023, 42.87470703125],
            [21.40302734375001, 42.83154296875],
            [21.390625, 42.751416015625],
            [21.6625, 42.681494140625],
            [21.72382812500001, 42.681982421875],
            [21.7529296875, 42.669824218749994],
            [21.75214843750001, 42.651513671874994],
            [21.73066406250001, 42.595458984375],
            [21.619042968750023, 42.423242187499994],
            [21.60986328125, 42.387451171875],
            [21.52998046875001, 42.35],
            [21.518945312500023, 42.328417968749996],
            [21.541601562500006, 42.280810546874996],
            [21.5625, 42.247509765625],
            [21.560839843750017, 42.24765625],
            [21.389550781250023, 42.21982421875],
            [21.33173828125001, 42.187158203124994],
            [21.29755859375001, 42.130078125],
            [21.28662109375, 42.100390625],
            [21.25634765625, 42.099511718749994],
            [21.2060546875, 42.128955078124996],
            [21.142480468750023, 42.175],
            [21.05976562500001, 42.171289062499994],
            [20.778125, 42.071044921875],
            [20.750390625000023, 42.018359375],
            [20.744140625, 41.904296875],
            [20.725, 41.87353515625],
            [20.694921875, 41.853808593749996],
            [20.578515625000023, 41.8662109375],
            [20.566210937500017, 41.873681640624994],
            [20.581445312500023, 41.917431640625],
            [20.57539062500001, 42.013085937499994],
            [20.522851562500023, 42.171484375],
            [20.485449218750006, 42.223388671875],
            [20.408300781250006, 42.274951171874996],
            [20.348242187500006, 42.3087890625],
            [20.240527343750017, 42.33896484375],
            [20.18574218750001, 42.42587890625],
            [20.103515625, 42.524658203125],
            [20.06396484375, 42.547265625],
            [20.0703125, 42.557080078125],
            [20.089257812500023, 42.63154296875],
            [20.065722656250017, 42.685839843749996],
            [20.02949218750001, 42.73203125],
            [20.054296875, 42.760058593749996],
            [20.129980468750006, 42.759765625],
            [20.19257812500001, 42.754638671875],
            [20.215136718750017, 42.798828125],
            [20.344335937500006, 42.827929687499996],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 4,
        sovereignt: "Denmark",
        sov_a3: "DN1",
        adm0_dif: 1,
        level: 2,
        type: "Country",
        tlc: "1",
        admin: "Denmark",
        adm0_a3: "DNK",
        geou_dif: 0,
        geounit: "Denmark",
        gu_a3: "DNK",
        su_dif: 0,
        subunit: "Denmark",
        su_a3: "DNK",
        brk_diff: 0,
        name: "Denmark",
        name_long: "Denmark",
        brk_a3: "DNK",
        brk_name: "Denmark",
        brk_group: null,
        abbrev: "Den.",
        postal: "DK",
        formal_en: "Kingdom of Denmark",
        formal_fr: null,
        name_ciawf: "Denmark",
        note_adm0: null,
        note_brk: null,
        name_sort: "Denmark",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 1,
        mapcolor9: 3,
        mapcolor13: 12,
        pop_est: 5818553,
        pop_rank: 13,
        pop_year: 2019,
        gdp_md: 350104,
        gdp_year: 2019,
        economy: "2. Developed region: nonG7",
        income_grp: "1. High income: OECD",
        fips_10: "DA",
        iso_a2: "DK",
        iso_a2_eh: "DK",
        iso_a3: "DNK",
        iso_a3_eh: "DNK",
        iso_n3: "208",
        iso_n3_eh: "208",
        un_a3: "208",
        wb_a2: "DK",
        wb_a3: "DNK",
        woe_id: 23424796,
        woe_id_eh: 23424796,
        woe_note: "Exact WOE match as country",
        adm0_iso: "DNK",
        adm0_diff: null,
        adm0_tlc: "DNK",
        adm0_a3_us: "DNK",
        adm0_a3_fr: "DNK",
        adm0_a3_ru: "DNK",
        adm0_a3_es: "DNK",
        adm0_a3_cn: "DNK",
        adm0_a3_tw: "DNK",
        adm0_a3_in: "DNK",
        adm0_a3_np: "DNK",
        adm0_a3_pk: "DNK",
        adm0_a3_de: "DNK",
        adm0_a3_gb: "DNK",
        adm0_a3_br: "DNK",
        adm0_a3_il: "DNK",
        adm0_a3_ps: "DNK",
        adm0_a3_sa: "DNK",
        adm0_a3_eg: "DNK",
        adm0_a3_ma: "DNK",
        adm0_a3_pt: "DNK",
        adm0_a3_ar: "DNK",
        adm0_a3_jp: "DNK",
        adm0_a3_ko: "DNK",
        adm0_a3_vn: "DNK",
        adm0_a3_tr: "DNK",
        adm0_a3_id: "DNK",
        adm0_a3_pl: "DNK",
        adm0_a3_gr: "DNK",
        adm0_a3_it: "DNK",
        adm0_a3_nl: "DNK",
        adm0_a3_se: "DNK",
        adm0_a3_bd: "DNK",
        adm0_a3_ua: "DNK",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Northern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: 9.018163,
        label_y: 55.966965,
        ne_id: 1159320547,
        wikidataid: "Q35",
        name_ar: "الدنمارك",
        name_bn: "ডেনমার্ক",
        name_de: "Dänemark",
        name_en: "Denmark",
        name_es: "Dinamarca",
        name_fa: "دانمارک",
        name_fr: "Danemark",
        name_el: "Δανία",
        name_he: "דנמרק",
        name_hi: "डेनमार्क",
        name_hu: "Dánia",
        name_id: "Denmark",
        name_it: "Danimarca",
        name_ja: "デンマーク",
        name_ko: "덴마크",
        name_nl: "Denemarken",
        name_pl: "Dania",
        name_pt: "Dinamarca",
        name_ru: "Дания",
        name_sv: "Danmark",
        name_tr: "Danimarka",
        name_uk: "Данія",
        name_ur: "ڈنمارک",
        name_vi: "Đan Mạch",
        name_zh: "丹麦",
        name_zht: "丹麥",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "DNK.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [12.56875, 55.78505859375],
              [12.571191406250023, 55.6849609375],
              [12.545214843750017, 55.655810546874996],
              [12.507031250000011, 55.63662109375],
              [12.407128906250023, 55.616259765624996],
              [12.320605468750017, 55.587841796875],
              [12.243457031250017, 55.537890625],
              [12.215039062500011, 55.46650390625],
              [12.275390625, 55.4142578125],
              [12.385156250000023, 55.38564453125],
              [12.4130859375, 55.286181640624996],
              [12.322460937500011, 55.237109375],
              [12.089941406250006, 55.188134765625],
              [12.065527343750006, 55.069921875],
              [12.073046875000017, 54.9767578125],
              [12.06884765625, 54.909033203125],
              [12.050390625, 54.81533203125],
              [11.8623046875, 54.772607421875],
              [11.740917968750011, 54.915332031249996],
              [11.73984375, 54.972460937499996],
              [11.70361328125, 55.03916015625],
              [11.69677734375, 55.095996093749996],
              [11.65380859375, 55.1869140625],
              [11.475878906250017, 55.2115234375],
              [11.406835937500006, 55.21474609375],
              [11.310253906250011, 55.1978515625],
              [11.286328125000011, 55.204443359375],
              [11.170703125000017, 55.32861328125],
              [11.189746093750017, 55.465625],
              [11.128027343750006, 55.534765625],
              [11.119531250000023, 55.566064453125],
              [11.120996093750023, 55.600732421875],
              [11.0703125, 55.629296875],
              [11.0087890625, 55.64443359375],
              [10.978906250000023, 55.721533203125],
              [11.049609375000017, 55.740234375],
              [11.224414062500017, 55.731201171875],
              [11.275488281250006, 55.736474609375],
              [11.322265625, 55.7525390625],
              [11.463671875000017, 55.879296875],
              [11.459570312500006, 55.9072265625],
              [11.474707031250006, 55.94345703125],
              [11.627734375000017, 55.956884765625],
              [11.695898437500006, 55.90791015625],
              [11.682226562500006, 55.8294921875],
              [11.69091796875, 55.72900390625],
              [11.783593750000023, 55.70166015625],
              [11.819726562500023, 55.69765625],
              [11.858300781250023, 55.771875],
              [11.885351562500006, 55.807958984375],
              [11.922070312500011, 55.828076171875],
              [11.9345703125, 55.8958984375],
              [11.912792968750011, 55.937304687499996],
              [11.866406250000011, 55.9681640625],
              [12.039648437500006, 56.0521484375],
              [12.218945312500011, 56.11865234375],
              [12.3232421875, 56.122119140624996],
              [12.42822265625, 56.105859375],
              [12.525781250000023, 56.0833984375],
              [12.578710937500006, 56.0640625],
              [12.6083984375, 56.033007812499996],
              [12.54296875, 55.958984375],
              [12.524804687500023, 55.91845703125],
              [12.56875, 55.78505859375],
            ],
          ],
          [
            [
              [9.73974609375, 54.825537109375],
              [9.725, 54.825537109375],
              [9.661230468750006, 54.834375],
              [9.615820312500006, 54.855419921875],
              [9.498730468750011, 54.8404296875],
              [9.341992187500011, 54.806298828125],
              [9.254980468750006, 54.8080078125],
              [9.185839843750017, 54.844677734375],
              [8.902929687500006, 54.896923828125],
              [8.857226562500017, 54.901123046875],
              [8.670703125000017, 54.9033203125],
              [8.670312500000023, 54.90341796875],
              [8.661425781250017, 54.9859375],
              [8.63828125, 55.045556640625],
              [8.572949218750011, 55.13427734375],
              [8.669824218750023, 55.1556640625],
              [8.651074218750011, 55.328564453125],
              [8.615917968750011, 55.418212890625],
              [8.3453125, 55.510302734374996],
              [8.132128906250017, 55.5998046875],
              [8.181347656250011, 55.901171875],
              [8.202343750000011, 55.982373046875],
              [8.121484375000023, 56.139892578125],
              [8.1298828125, 56.32119140625],
              [8.163964843750023, 56.606884765625],
              [8.231738281250017, 56.61806640625],
              [8.281445312500011, 56.61669921875],
              [8.47314453125, 56.5654296875],
              [8.552929687500011, 56.560302734375],
              [8.607617187500011, 56.514501953125],
              [8.671679687500017, 56.495654296874996],
              [8.718066406250017, 56.544287109375],
              [8.736132812500017, 56.62744140625],
              [8.888085937500023, 56.73505859375],
              [8.994531250000023, 56.7748046875],
              [9.067089843750011, 56.79384765625],
              [9.140332031250011, 56.750439453125],
              [9.196386718750006, 56.70166015625],
              [9.209667968750011, 56.808398437499996],
              [9.2548828125, 57.01171875],
              [9.110449218750006, 57.04365234375],
              [8.992773437500006, 57.01611328125],
              [8.876074218750006, 56.887255859374996],
              [8.77197265625, 56.72529296875],
              [8.603125, 56.710400390625],
              [8.468359375, 56.66455078125],
              [8.3466796875, 56.712109375],
              [8.268261718750011, 56.75400390625],
              [8.266308593750011, 56.81533203125],
              [8.284082031250023, 56.85234375],
              [8.427050781250017, 56.984423828124996],
              [8.618554687500023, 57.111279296875],
              [8.8115234375, 57.11005859375],
              [8.952246093750006, 57.1505859375],
              [9.036328125000011, 57.155419921875],
              [9.298828125, 57.146533203124996],
              [9.43359375, 57.17431640625],
              [9.554296875, 57.232470703124996],
              [9.815136718750011, 57.47841796875],
              [9.962304687500023, 57.58095703125],
              [10.259082031250017, 57.617041015625],
              [10.533300781250006, 57.735400390624996],
              [10.609960937500006, 57.7369140625],
              [10.48095703125, 57.648681640625],
              [10.460253906250017, 57.614550781249996],
              [10.444628906250017, 57.56220703125],
              [10.537109375, 57.448535156249996],
              [10.517578125, 57.379345703125],
              [10.524121093750011, 57.243212890624996],
              [10.436914062500023, 57.172265625],
              [10.338476562500006, 57.021337890625],
              [10.296093750000011, 56.99912109375],
              [10.287011718750023, 56.82294921875],
              [10.296679687500017, 56.780908203125],
              [10.28271484375, 56.6205078125],
              [10.383593750000017, 56.554833984375],
              [10.490234375, 56.5205078125],
              [10.845898437500011, 56.521728515625],
              [10.8828125, 56.49287109375],
              [10.926171875000023, 56.44326171875],
              [10.894433593750023, 56.359033203125],
              [10.8564453125, 56.2955078125],
              [10.75341796875, 56.241992187499996],
              [10.621191406250006, 56.202099609375],
              [10.538964843750023, 56.200341796875],
              [10.426953125000011, 56.276171875],
              [10.373730468750011, 56.2515625],
              [10.31875, 56.212890625],
              [10.226660156250006, 56.00537109375],
              [10.183007812500023, 55.865185546875],
              [10.159375, 55.853808593749996],
              [10.107324218750023, 55.874462890625],
              [10.017382812500017, 55.87607421875],
              [9.903710937500023, 55.842822265624996],
              [9.962011718750006, 55.8130859375],
              [10.023632812500011, 55.76142578125],
              [9.9990234375, 55.735546875],
              [9.899023437500006, 55.707568359374996],
              [9.810351562500017, 55.6509765625],
              [9.773242187500017, 55.608154296875],
              [9.661425781250017, 55.557470703125],
              [9.591113281250017, 55.493212890624996],
              [9.625585937500006, 55.41357421875],
              [9.640234375, 55.34365234375],
              [9.670996093750006, 55.26640625],
              [9.643261718750011, 55.204736328125],
              [9.504785156250023, 55.116259765624996],
              [9.453710937500006, 55.03955078125],
              [9.572363281250006, 55.04052734375],
              [9.645410156250023, 55.022802734375],
              [9.688183593750011, 55.000146484375],
              [9.732324218750023, 54.968017578125],
              [9.705273437500011, 54.9283203125],
              [9.73974609375, 54.825537109375],
            ],
          ],
          [
            [
              [10.645117187500006, 55.609814453125],
              [10.686816406250017, 55.5576171875],
              [10.738085937500017, 55.446337890624996],
              [10.819238281250023, 55.321875],
              [10.785351562500011, 55.269775390625],
              [10.808398437500017, 55.20302734375],
              [10.785253906250006, 55.1333984375],
              [10.623828125000017, 55.05244140625],
              [10.442773437500023, 55.048779296875],
              [10.254589843750011, 55.087890625],
              [9.98876953125, 55.16318359375],
              [9.967382812500006, 55.20546875],
              [9.930078125000023, 55.22890625],
              [9.858984375, 55.357226562499996],
              [9.860644531250017, 55.515478515625],
              [9.994238281250006, 55.535302734375],
              [10.2861328125, 55.61083984375],
              [10.353613281250006, 55.598974609375],
              [10.424023437500011, 55.560351562499996],
              [10.505078125000011, 55.558056640625],
              [10.622753906250011, 55.612841796874996],
              [10.645117187500006, 55.609814453125],
            ],
          ],
          [
            [
              [11.361425781250006, 54.891650390624996],
              [11.538378906250017, 54.82958984375],
              [11.658105468750023, 54.833154296875],
              [11.739550781250017, 54.807421875],
              [11.758984375000011, 54.76767578125],
              [11.765917968750017, 54.679443359375],
              [11.680371093750011, 54.6537109375],
              [11.5859375, 54.662451171875],
              [11.457421875000023, 54.628857421875],
              [11.035546875000023, 54.773095703125],
              [11.041699218750011, 54.893359375],
              [11.05859375, 54.940576171875],
              [11.258496093750011, 54.951806640625],
              [11.361425781250006, 54.891650390624996],
            ],
          ],
          [
            [
              [10.734082031250011, 54.750732421875],
              [10.689746093750017, 54.745068359375],
              [10.629492187500006, 54.82607421875],
              [10.621679687500006, 54.851416015625],
              [10.692480468750006, 54.903271484375],
              [10.73828125, 54.96201171875],
              [10.856738281250017, 55.052197265625],
              [10.925, 55.157861328125],
              [10.951074218750023, 55.156201171875],
              [10.920800781250023, 55.062109375],
              [10.765234375, 54.799658203125],
              [10.734082031250011, 54.750732421875],
            ],
          ],
          [
            [
              [12.549218750000023, 54.965771484375],
              [12.511035156250017, 54.95087890625],
              [12.357519531250006, 54.96181640625],
              [12.184472656250023, 54.89248046875],
              [12.118847656250011, 54.914404296875],
              [12.143652343750006, 54.95869140625],
              [12.16171875, 54.9748046875],
              [12.219921875000011, 54.993603515625],
              [12.2587890625, 55.02109375],
              [12.274023437500006, 55.064111328125],
              [12.31005859375, 55.04091796875],
              [12.417187500000011, 55.031201171875],
              [12.469531250000017, 55.01748046875],
              [12.51328125, 54.997314453125],
              [12.549218750000023, 54.965771484375],
            ],
          ],
          [
            [
              [12.665722656250011, 55.596533203125],
              [12.571582031250017, 55.554003906249996],
              [12.550878906250006, 55.55625],
              [12.520312500000017, 55.614599609375],
              [12.569921875, 55.65009765625],
              [12.59921875, 55.680224609374996],
              [12.620019531250023, 55.679345703125],
              [12.6484375, 55.646777343749996],
              [12.665722656250011, 55.596533203125],
            ],
          ],
          [
            [
              [10.484375, 54.84755859375],
              [10.417285156250017, 54.837158203125],
              [10.340527343750011, 54.858935546874996],
              [10.215625, 54.940966796874996],
              [10.199902343750011, 54.962744140625],
              [10.265527343750023, 54.948828125],
              [10.346972656250017, 54.90595703125],
              [10.413671875, 54.896826171875],
              [10.5048828125, 54.860546875],
              [10.484375, 54.84755859375],
            ],
          ],
          [
            [
              [10.061230468750011, 54.886376953125],
              [9.957128906250006, 54.8724609375],
              [9.90390625, 54.896630859375],
              [9.80625, 54.906005859375],
              [9.771191406250011, 55.059912109375],
              [9.78125, 55.06904296875],
              [9.830371093750017, 55.058251953125],
              [9.998828125000017, 54.986474609375],
              [10.057714843750006, 54.90791015625],
              [10.061230468750011, 54.886376953125],
            ],
          ],
          [
            [
              [10.607324218750023, 55.783056640625],
              [10.59033203125, 55.765087890625],
              [10.526953125, 55.7837890625],
              [10.520312500000017, 55.848486328125],
              [10.544335937500023, 55.906591796875],
              [10.51611328125, 55.958544921874996],
              [10.547167968750017, 55.991943359375],
              [10.636328125, 55.91416015625],
              [10.66171875, 55.877587890625],
              [10.627343750000023, 55.83388671875],
              [10.607324218750023, 55.783056640625],
            ],
          ],
          [
            [
              [11.052148437500023, 57.2525390625],
              [11.011425781250011, 57.2291015625],
              [10.873828125000017, 57.262255859374996],
              [10.9345703125, 57.30859375],
              [11.085742187500017, 57.329931640625],
              [11.174511718750011, 57.322900390625],
              [11.076855468750011, 57.276904296874996],
              [11.052148437500023, 57.2525390625],
            ],
          ],
          [
            [
              [15.087695312500017, 55.021875],
              [15.05078125, 55.004931640624996],
              [14.885546875000017, 55.032958984375],
              [14.684179687500006, 55.10224609375],
              [14.713671875000017, 55.238037109375],
              [14.765332031250011, 55.296728515625],
              [15.132617187500017, 55.14453125],
              [15.137109375000023, 55.087158203125],
              [15.087695312500017, 55.021875],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 4,
        labelrank: 2,
        sovereignt: "Spain",
        sov_a3: "ESP",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Spain",
        adm0_a3: "ESP",
        geou_dif: 0,
        geounit: "Spain",
        gu_a3: "ESP",
        su_dif: 0,
        subunit: "Spain",
        su_a3: "ESP",
        brk_diff: 0,
        name: "Spain",
        name_long: "Spain",
        brk_a3: "ESP",
        brk_name: "Spain",
        brk_group: null,
        abbrev: "Sp.",
        postal: "E",
        formal_en: "Kingdom of Spain",
        formal_fr: null,
        name_ciawf: "Spain",
        note_adm0: null,
        note_brk: null,
        name_sort: "Spain",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 5,
        mapcolor9: 5,
        mapcolor13: 5,
        pop_est: 47076781,
        pop_rank: 15,
        pop_year: 2019,
        gdp_md: 1393490,
        gdp_year: 2019,
        economy: "2. Developed region: nonG7",
        income_grp: "1. High income: OECD",
        fips_10: "SP",
        iso_a2: "ES",
        iso_a2_eh: "ES",
        iso_a3: "ESP",
        iso_a3_eh: "ESP",
        iso_n3: "724",
        iso_n3_eh: "724",
        un_a3: "724",
        wb_a2: "ES",
        wb_a3: "ESP",
        woe_id: 23424950,
        woe_id_eh: 23424950,
        woe_note: "Exact WOE match as country",
        adm0_iso: "ESP",
        adm0_diff: null,
        adm0_tlc: "ESP",
        adm0_a3_us: "ESP",
        adm0_a3_fr: "ESP",
        adm0_a3_ru: "ESP",
        adm0_a3_es: "ESP",
        adm0_a3_cn: "ESP",
        adm0_a3_tw: "ESP",
        adm0_a3_in: "ESP",
        adm0_a3_np: "ESP",
        adm0_a3_pk: "ESP",
        adm0_a3_de: "ESP",
        adm0_a3_gb: "ESP",
        adm0_a3_br: "ESP",
        adm0_a3_il: "ESP",
        adm0_a3_ps: "ESP",
        adm0_a3_sa: "ESP",
        adm0_a3_eg: "ESP",
        adm0_a3_ma: "ESP",
        adm0_a3_pt: "ESP",
        adm0_a3_ar: "ESP",
        adm0_a3_jp: "ESP",
        adm0_a3_ko: "ESP",
        adm0_a3_vn: "ESP",
        adm0_a3_tr: "ESP",
        adm0_a3_id: "ESP",
        adm0_a3_pl: "ESP",
        adm0_a3_gr: "ESP",
        adm0_a3_it: "ESP",
        adm0_a3_nl: "ESP",
        adm0_a3_se: "ESP",
        adm0_a3_bd: "ESP",
        adm0_a3_ua: "ESP",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Southern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 5,
        long_len: 5,
        abbrev_len: 3,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 2,
        max_label: 7,
        label_x: -3.464718,
        label_y: 40.090953,
        ne_id: 1159320587,
        wikidataid: "Q29",
        name_ar: "إسبانيا",
        name_bn: "স্পেন",
        name_de: "Spanien",
        name_en: "Spain",
        name_es: "España",
        name_fa: "اسپانیا",
        name_fr: "Espagne",
        name_el: "Ισπανία",
        name_he: "ספרד",
        name_hi: "स्पेन",
        name_hu: "Spanyolország",
        name_id: "Spanyol",
        name_it: "Spagna",
        name_ja: "スペイン",
        name_ko: "스페인",
        name_nl: "Spanje",
        name_pl: "Hiszpania",
        name_pt: "Espanha",
        name_ru: "Испания",
        name_sv: "Spanien",
        name_tr: "İspanya",
        name_uk: "Іспанія",
        name_ur: "ہسپانیہ",
        name_vi: "Tây Ban Nha",
        name_zh: "西班牙",
        name_zht: "西班牙",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "ESP.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [1.593945312500011, 38.6720703125],
              [1.571191406250023, 38.658837890624994],
              [1.504980468750006, 38.67099609375],
              [1.40576171875, 38.67099609375],
              [1.401953125, 38.71142578125],
              [1.417187500000011, 38.739648437499994],
              [1.436328125000017, 38.768212890624994],
              [1.496875, 38.7119140625],
              [1.592675781250023, 38.701464843749996],
              [1.593945312500011, 38.6720703125],
            ],
          ],
          [
            [
              [3.145312500000017, 39.790087890624996],
              [3.241113281250023, 39.756689453125],
              [3.342187500000023, 39.78671875],
              [3.395898437500023, 39.777294921875],
              [3.448925781250011, 39.76123046875],
              [3.461816406250023, 39.69775390625],
              [3.414648437500006, 39.6271484375],
              [3.348730468750006, 39.5556640625],
              [3.29296875, 39.47705078125],
              [3.244726562500006, 39.386621093749994],
              [3.154589843750017, 39.333251953125],
              [3.072851562500006, 39.30126953125],
              [2.900097656250011, 39.368359375],
              [2.7998046875, 39.385058593749996],
              [2.769824218750017, 39.41025390625],
              [2.745996093750023, 39.51025390625],
              [2.700585937500023, 39.542138671874994],
              [2.634082031250017, 39.556201171874996],
              [2.575878906250011, 39.530664062499994],
              [2.49951171875, 39.477880859375],
              [2.458789062500017, 39.53046875],
              [2.394335937500017, 39.540380859375],
              [2.370019531250023, 39.572070312499996],
              [2.371289062500011, 39.613085937499996],
              [2.784960937500017, 39.854833984375],
              [2.90478515625, 39.90830078125],
              [3.15869140625, 39.970507812499996],
              [3.197558593750017, 39.961083984374994],
              [3.164453125000023, 39.92421875],
              [3.1669921875, 39.90771484375],
              [3.198632812500023, 39.88984375],
              [3.19091796875, 39.861376953124996],
              [3.15869140625, 39.836572265624994],
              [3.145312500000017, 39.790087890624996],
            ],
          ],
          [
            [
              [4.293652343750011, 39.841845703124996],
              [4.275292968750023, 39.8302734375],
              [3.967675781250023, 39.945849609374996],
              [3.8671875, 39.958740234375],
              [3.842675781250023, 39.976367187499996],
              [3.845410156250011, 40.036474609375],
              [3.853417968750023, 40.063037109374996],
              [4.059179687500006, 40.075097656249994],
              [4.225781250000011, 40.032373046874994],
              [4.315136718750011, 39.917236328125],
              [4.322070312500017, 39.897509765624996],
              [4.293652343750011, 39.841845703124996],
            ],
          ],
          [
            [
              [1.445214843750023, 38.918701171875],
              [1.408984375000017, 38.857275390625],
              [1.256933593750006, 38.87900390625],
              [1.223339843750011, 38.903857421874996],
              [1.25625, 38.973388671875],
              [1.2998046875, 38.981738281249996],
              [1.302539062500017, 39.031152343749994],
              [1.3486328125, 39.080810546875],
              [1.564453125, 39.121044921875],
              [1.613183593750023, 39.08740234375],
              [1.623632812500006, 39.038818359375],
              [1.494531250000023, 38.932519531249994],
              [1.445214843750023, 38.918701171875],
            ],
          ],
          [
            [
              [-1.794042968749977, 43.40732421875],
              [-1.792724609375, 43.37255859375],
              [-1.753271484374977, 43.32470703125],
              [-1.712841796874983, 43.30703125],
              [-1.627148437499983, 43.282470703125],
              [-1.561474609374983, 43.279199218749994],
              [-1.471728515624989, 43.26767578125],
              [-1.410693359374989, 43.240087890625],
              [-1.407324218749977, 43.197119140625],
              [-1.422607421875, 43.14912109375],
              [-1.459423828124983, 43.10498046875],
              [-1.48046875, 43.071142578125],
              [-1.460839843749994, 43.0517578125],
              [-1.428759765624989, 43.036767578124994],
              [-1.39404296875, 43.032617187499994],
              [-1.370507812499994, 43.03759765625],
              [-1.352734374999983, 43.064257812499996],
              [-1.31884765625, 43.096972656249996],
              [-1.300048828125, 43.100976562499994],
              [-1.301562499999989, 43.082470703125],
              [-1.285449218749989, 43.059619140624996],
              [-1.175439453124994, 43.021142578124994],
              [-0.933837890625, 42.949511718749996],
              [-0.839208984374977, 42.948193359375],
              [-0.762646484374983, 42.939794921875],
              [-0.740185546874983, 42.909521484375],
              [-0.58642578125, 42.798974609374994],
              [-0.5498046875, 42.802001953125],
              [-0.481152343749983, 42.79931640625],
              [-0.3984375, 42.80810546875],
              [-0.338574218749983, 42.82880859375],
              [-0.29931640625, 42.825341796874994],
              [-0.256054687499983, 42.803955078125],
              [-0.205322265625, 42.785302734374994],
              [-0.140039062499994, 42.748925781249994],
              [-0.081494140624983, 42.703857421875],
              [-0.041162109374994, 42.689111328124994],
              [0.201367187500011, 42.7193359375],
              [0.25546875, 42.692919921874996],
              [0.312890625000023, 42.693261718749994],
              [0.377246093750017, 42.700146484375],
              [0.517675781250006, 42.686279296875],
              [0.631640625000017, 42.689599609374994],
              [0.641992187500023, 42.700634765625],
              [0.651757812500023, 42.800439453124994],
              [0.669824218750023, 42.835742187499996],
              [0.696875, 42.845117187499994],
              [0.764453125000017, 42.838037109374994],
              [1.010058593750017, 42.778955078124994],
              [1.111132812500017, 42.742041015625],
              [1.208300781250017, 42.713134765625],
              [1.293261718750017, 42.7099609375],
              [1.349414062500017, 42.690673828125],
              [1.428320312500006, 42.5958984375],
              [1.414843750000017, 42.548388671874996],
              [1.421972656250006, 42.530810546874996],
              [1.430273437500006, 42.497851562499996],
              [1.428125, 42.461328125],
              [1.448828125, 42.437451171875],
              [1.486230468750023, 42.434472656249994],
              [1.534082031250023, 42.441699218749996],
              [1.58642578125, 42.455957031249994],
              [1.678515625000017, 42.4966796875],
              [1.7060546875, 42.503320312499994],
              [1.859765625000023, 42.457080078124996],
              [1.927929687500011, 42.426318359374996],
              [1.951464843750017, 42.3927734375],
              [1.986523437500011, 42.35849609375],
              [2.03271484375, 42.353515625],
              [2.098339843750011, 42.386083984375],
              [2.200390625000011, 42.420947265624996],
              [2.374414062500023, 42.390283203124994],
              [2.56796875, 42.34580078125],
              [2.651660156250017, 42.340478515624994],
              [2.65478515625, 42.362109375],
              [2.670019531250006, 42.393017578125],
              [2.701855468750011, 42.408496093749996],
              [2.749414062500023, 42.413037109375],
              [2.815625, 42.429248046874996],
              [2.891406250000017, 42.4560546875],
              [2.970019531250017, 42.467236328125],
              [3.052636718750023, 42.447216796875],
              [3.152148437500017, 42.431005859375],
              [3.21142578125, 42.43115234375],
              [3.23984375, 42.36787109375],
              [3.287890625000017, 42.343701171875],
              [3.306738281250006, 42.288964843749994],
              [3.218652343750023, 42.2603515625],
              [3.166406250000023, 42.256494140624994],
              [3.150390625, 42.162451171875],
              [3.175195312500023, 42.135986328125],
              [3.224609375, 42.111132812499996],
              [3.238085937500017, 42.0822265625],
              [3.248046875, 41.944238281249994],
              [3.146875, 41.86103515625],
              [3.0048828125, 41.767431640625],
              [2.310937500000023, 41.46650390625],
              [2.145605468750006, 41.320751953125],
              [2.082617187500006, 41.287402343749996],
              [1.566601562500011, 41.195605468749996],
              [1.205859375000017, 41.097558593749994],
              [1.032910156250011, 41.062060546874996],
              [0.81689453125, 40.8916015625],
              [0.714648437500017, 40.8228515625],
              [0.796093750000011, 40.80380859375],
              [0.89111328125, 40.72236328125],
              [0.859179687500017, 40.68623046875],
              [0.720605468750023, 40.63046875],
              [0.660058593750023, 40.613330078124996],
              [0.627148437500011, 40.622216796874994],
              [0.596093750000023, 40.614501953125],
              [0.363671875000023, 40.31904296875],
              [0.158398437500011, 40.106591796874994],
              [0.043066406250006, 40.013964843749996],
              [-0.075146484374983, 39.875927734375],
              [-0.327001953124977, 39.519873046875],
              [-0.328955078124977, 39.41708984375],
              [-0.204931640624977, 39.06259765625],
              [-0.1337890625, 38.969482421875],
              [-0.034130859374983, 38.8912109375],
              [0.154882812500006, 38.824658203125],
              [0.201562500000023, 38.759179687499994],
              [0.136328125, 38.69677734375],
              [-0.052734375, 38.585693359375],
              [-0.38125, 38.43564453125],
              [-0.520800781249989, 38.317285156249994],
              [-0.550683593749994, 38.203125],
              [-0.646777343749989, 38.15185546875],
              [-0.683203124999977, 37.992041015625],
              [-0.741552734374977, 37.886132812499994],
              [-0.752734374999989, 37.850244140624994],
              [-0.814648437499983, 37.769921875],
              [-0.823095703124977, 37.71162109375],
              [-0.721582031249994, 37.6310546875],
              [-0.771875, 37.596240234374996],
              [-0.822167968749994, 37.58076171875],
              [-0.938085937499977, 37.571337890624996],
              [-1.327539062499994, 37.5611328125],
              [-1.640966796874977, 37.386962890625],
              [-1.797607421875, 37.232861328125],
              [-1.939306640624977, 36.945849609374996],
              [-2.111523437499983, 36.776660156249996],
              [-2.187695312499983, 36.745458984375],
              [-2.305566406249994, 36.81982421875],
              [-2.452832031249983, 36.83115234375],
              [-2.595703125, 36.806494140625],
              [-2.670605468749983, 36.74755859375],
              [-2.787548828124983, 36.714746093749994],
              [-2.90185546875, 36.7431640625],
              [-3.149169921875, 36.75849609375],
              [-3.259130859374977, 36.755761718749994],
              [-3.43125, 36.707910156249994],
              [-3.578808593749983, 36.73984375],
              [-3.827783203124994, 36.7560546875],
              [-4.366845703124994, 36.718115234375],
              [-4.434863281249989, 36.700244140624996],
              [-4.502246093749989, 36.629150390625],
              [-4.674121093749989, 36.5064453125],
              [-4.935302734375, 36.50205078125],
              [-5.171484374999977, 36.423779296875],
              [-5.230517578124989, 36.3736328125],
              [-5.329687499999977, 36.235742187499994],
              [-5.360937499999977, 36.134912109374994],
              [-5.381591796875, 36.134082031249996],
              [-5.4072265625, 36.15888671875],
              [-5.443603515625, 36.1505859375],
              [-5.4625, 36.073779296874996],
              [-5.55126953125, 36.038818359375],
              [-5.62548828125, 36.025927734374996],
              [-5.808398437499989, 36.088330078125],
              [-5.960693359375, 36.18173828125],
              [-6.040673828124994, 36.188427734375],
              [-6.170458984374989, 36.333789062499996],
              [-6.226269531249983, 36.42646484375],
              [-6.265917968749989, 36.526513671874994],
              [-6.257714843749994, 36.56484375],
              [-6.268945312499994, 36.596728515624996],
              [-6.384130859374977, 36.637011718749996],
              [-6.412255859374994, 36.728857421875],
              [-6.328320312499983, 36.84814453125],
              [-6.259423828124994, 36.898974609374996],
              [-6.216796875, 36.91357421875],
              [-6.320947265624994, 36.908496093749996],
              [-6.396191406249983, 36.831640625],
              [-6.492431640625, 36.954638671874996],
              [-6.884619140624977, 37.194238281249994],
              [-6.859375, 37.249169921874994],
              [-6.86376953125, 37.27890625],
              [-6.929492187499989, 37.21494140625],
              [-6.974658203124989, 37.1984375],
              [-7.174951171874994, 37.208789062499996],
              [-7.406152343749994, 37.179443359375],
              [-7.4671875, 37.428027343749996],
              [-7.496044921874983, 37.523583984374994],
              [-7.503515624999977, 37.585498046874996],
              [-7.443945312499977, 37.728271484375],
              [-7.37890625, 37.786376953125],
              [-7.292236328125, 37.9064453125],
              [-7.185449218749994, 38.00634765625],
              [-7.072509765625, 38.030029296875],
              [-7.022851562499994, 38.044726562499996],
              [-6.981103515624994, 38.121972656249994],
              [-6.957568359374989, 38.187890625],
              [-6.974804687499983, 38.19443359375],
              [-7.106396484374983, 38.181005859375],
              [-7.343017578125, 38.457421875],
              [-7.335791015624977, 38.50146484375],
              [-7.305957031249989, 38.566845703125],
              [-7.286376953125, 38.649365234375],
              [-7.281542968749989, 38.71455078125],
              [-7.219921874999983, 38.7705078125],
              [-7.12548828125, 38.826953125],
              [-7.046044921874994, 38.90703125],
              [-7.00625, 38.985253906249994],
              [-6.997949218749994, 39.056445312499996],
              [-7.04296875, 39.107080078124994],
              [-7.172412109374989, 39.135205078125],
              [-7.305761718749977, 39.338134765625],
              [-7.33544921875, 39.465136718749996],
              [-7.362695312499994, 39.478320312499996],
              [-7.445117187499989, 39.536181640624996],
              [-7.524218749999989, 39.6447265625],
              [-7.535693359374989, 39.661572265625],
              [-7.4541015625, 39.6806640625],
              [-7.11767578125, 39.681689453124996],
              [-7.047412109374989, 39.70556640625],
              [-7.036718749999977, 39.71396484375],
              [-6.975390624999989, 39.798388671874996],
              [-6.911181640624989, 39.937109375],
              [-6.896093749999977, 40.021826171875],
              [-6.91640625, 40.0568359375],
              [-7.02783203125, 40.142626953124996],
              [-7.032617187499994, 40.167919921875],
              [-7.014697265624989, 40.208349609375],
              [-6.948437499999983, 40.251611328124994],
              [-6.85888671875, 40.300732421875],
              [-6.810156249999977, 40.343115234375],
              [-6.82177734375, 40.376269531249996],
              [-6.847949218749989, 40.410986328125],
              [-6.85205078125, 40.443261718749994],
              [-6.835693359375, 40.483154296875],
              [-6.829833984375, 40.619091796875],
              [-6.818359375, 40.654052734375],
              [-6.835888671874983, 40.777490234374994],
              [-6.857714843749989, 40.878320312499994],
              [-6.928466796875, 41.009130859375],
              [-6.91552734375, 41.038037109375],
              [-6.8828125, 41.062402343749994],
              [-6.77578125, 41.107714843749996],
              [-6.690136718749983, 41.214501953124994],
              [-6.56591796875, 41.3037109375],
              [-6.403125, 41.375390625],
              [-6.289355468749989, 41.455029296875],
              [-6.244335937499983, 41.515917968749996],
              [-6.2125, 41.53203125],
              [-6.2216796875, 41.560449218749994],
              [-6.243115234374983, 41.601806640625],
              [-6.308056640624983, 41.6421875],
              [-6.391699218749977, 41.665380859375],
              [-6.484667968749989, 41.664404296875],
              [-6.542187499999983, 41.672509765624994],
              [-6.558984375, 41.704052734375],
              [-6.552587890624977, 41.78955078125],
              [-6.557519531249994, 41.87412109375],
              [-6.575341796874994, 41.9130859375],
              [-6.618261718749977, 41.9423828125],
              [-6.70361328125, 41.9345703125],
              [-6.777294921874983, 41.95849609375],
              [-6.833203124999983, 41.964160156249996],
              [-6.865527343749989, 41.945263671875],
              [-7.030468749999983, 41.950634765625],
              [-7.09912109375, 41.964208984375],
              [-7.147119140624994, 41.98115234375],
              [-7.177929687499983, 41.9716796875],
              [-7.195361328124989, 41.955224609374994],
              [-7.198339843749977, 41.92939453125],
              [-7.209619140624994, 41.895263671875],
              [-7.2685546875, 41.864404296874994],
              [-7.403613281249989, 41.83369140625],
              [-7.512597656249994, 41.835986328124996],
              [-7.612597656249989, 41.857958984374996],
              [-7.644677734374994, 41.873974609375],
              [-7.693066406249983, 41.888476562499996],
              [-7.896386718749994, 41.870556640625],
              [-7.920849609374983, 41.883642578125],
              [-7.990966796875, 41.851904296875],
              [-8.094433593749983, 41.814208984375],
              [-8.152490234374994, 41.811962890625],
              [-8.173535156249983, 41.819970703124994],
              [-8.18125, 41.836962890624996],
              [-8.224755859374994, 41.895849609375],
              [-8.213330078124983, 41.927099609375],
              [-8.129980468749977, 42.0181640625],
              [-8.139306640624994, 42.039941406249994],
              [-8.173583984375, 42.069384765624996],
              [-8.204199218749977, 42.111865234374996],
              [-8.213085937499983, 42.133691406249994],
              [-8.266064453124983, 42.13740234375],
              [-8.322558593749989, 42.115087890625],
              [-8.5380859375, 42.0693359375],
              [-8.589648437499989, 42.052734375],
              [-8.682958984374977, 42.00849609375],
              [-8.777148437499989, 41.941064453124994],
              [-8.852343749999989, 41.926904296874994],
              [-8.878320312499994, 41.946875],
              [-8.88720703125, 42.105273437499996],
              [-8.7724609375, 42.210595703124994],
              [-8.69091796875, 42.274169921875],
              [-8.729199218749983, 42.287011718749994],
              [-8.815820312499994, 42.28525390625],
              [-8.809960937499994, 42.33447265625],
              [-8.769384765624977, 42.358154296875],
              [-8.730029296874989, 42.41171875],
              [-8.776171874999989, 42.434814453125],
              [-8.812109374999977, 42.470068359375],
              [-8.809912109374977, 42.562353515625],
              [-8.799902343749977, 42.599902343749996],
              [-8.8115234375, 42.64033203125],
              [-8.98779296875, 42.58564453125],
              [-9.033105468749994, 42.59384765625],
              [-9.035058593749994, 42.662353515625],
              [-8.937207031249983, 42.76669921875],
              [-8.927197265624983, 42.798583984375],
              [-9.041601562499977, 42.814013671874996],
              [-9.127197265625, 42.865234375],
              [-9.179443359375, 42.910986328125],
              [-9.235205078124977, 42.976904296875],
              [-9.235644531249989, 43.035791015624994],
              [-9.178076171874977, 43.1740234375],
              [-9.095556640624977, 43.214208984375],
              [-9.024511718749977, 43.23896484375],
              [-8.873681640624994, 43.334423828125],
              [-8.665625, 43.3166015625],
              [-8.537060546874983, 43.337060546874994],
              [-8.421582031249983, 43.38583984375],
              [-8.35546875, 43.396826171875],
              [-8.248925781249994, 43.439404296875],
              [-8.252294921874977, 43.496923828125],
              [-8.288867187499989, 43.539599609374996],
              [-8.256738281249994, 43.579882812499996],
              [-8.137158203124983, 43.629052734374994],
              [-8.004687499999989, 43.694384765624996],
              [-7.852734374999983, 43.706982421875],
              [-7.698144531249994, 43.764550781249994],
              [-7.594580078124977, 43.72734375],
              [-7.503613281249983, 43.73994140625],
              [-7.399316406249994, 43.69580078125],
              [-7.261962890625, 43.594628906249994],
              [-7.060986328124983, 43.553955078125],
              [-6.900683593749989, 43.58564453125],
              [-6.617285156249977, 43.5923828125],
              [-6.475683593749977, 43.57890625],
              [-6.22412109375, 43.603857421875],
              [-6.080126953124989, 43.594921875],
              [-5.8466796875, 43.645068359374996],
              [-5.665820312499989, 43.582470703125],
              [-5.315722656249989, 43.553173828125],
              [-5.105273437499989, 43.501855468749994],
              [-4.523046874999977, 43.41572265625],
              [-4.312792968749989, 43.41474609375],
              [-4.015332031249983, 43.4630859375],
              [-3.889355468749983, 43.499414062499994],
              [-3.774023437499977, 43.477880859375],
              [-3.604638671874994, 43.519482421875],
              [-3.523632812499983, 43.511035156249996],
              [-3.417871093749994, 43.451708984374996],
              [-3.045605468749983, 43.37158203125],
              [-2.947705078124983, 43.439697265625],
              [-2.875048828124989, 43.454443359375],
              [-2.607080078124994, 43.412744140624994],
              [-2.337109374999983, 43.328027343749994],
              [-2.196679687499994, 43.321923828124994],
              [-1.991308593749977, 43.345068359375],
              [-1.828515625, 43.400830078125],
              [-1.794042968749977, 43.40732421875],
            ],
          ],
          [
            [
              [-16.33447265625, 28.379931640624996],
              [-16.418212890625, 28.151416015624996],
              [-16.496240234374994, 28.061914062499994],
              [-16.54277343749999, 28.032080078125],
              [-16.65800781249999, 28.007177734375],
              [-16.79472656249999, 28.149169921875],
              [-16.86601562499999, 28.293261718749996],
              [-16.90532226562499, 28.339599609375],
              [-16.84306640624999, 28.376123046874994],
              [-16.752050781249977, 28.369824218749997],
              [-16.556835937499983, 28.40048828125],
              [-16.517431640624977, 28.4126953125],
              [-16.318994140624994, 28.558203125],
              [-16.123632812499977, 28.575976562499996],
              [-16.119140625, 28.528271484374997],
              [-16.33447265625, 28.379931640624996],
            ],
          ],
          [
            [
              [-13.715966796874994, 28.91123046875],
              [-13.783984374999989, 28.845458984375],
              [-13.859912109374989, 28.869091796874997],
              [-13.823632812499994, 29.013330078124994],
              [-13.788183593749977, 29.056103515624997],
              [-13.650097656249983, 29.118994140625],
              [-13.535058593749994, 29.144287109375],
              [-13.501416015624983, 29.211230468749996],
              [-13.463574218749983, 29.237207031249994],
              [-13.422949218749977, 29.197509765625],
              [-13.453759765624994, 29.1513671875],
              [-13.477929687499994, 29.006591796875],
              [-13.5546875, 28.960205078125],
              [-13.715966796874994, 28.91123046875],
            ],
          ],
          [
            [
              [-14.19677734375, 28.169287109375],
              [-14.332617187499977, 28.056005859375],
              [-14.468603515624977, 28.082373046875],
              [-14.491796874999977, 28.100927734375],
              [-14.355566406249977, 28.1296875],
              [-14.231982421874989, 28.2158203125],
              [-14.152587890625, 28.406640625],
              [-14.028369140624989, 28.617431640625],
              [-14.003369140624983, 28.706689453124994],
              [-13.954150390624989, 28.741455078125],
              [-13.886279296874989, 28.744677734374996],
              [-13.857226562499989, 28.738037109375],
              [-13.8271484375, 28.691210937499996],
              [-13.827587890624983, 28.58515625],
              [-13.862988281249983, 28.409326171874994],
              [-13.928027343749989, 28.253466796874996],
              [-14.19677734375, 28.169287109375],
            ],
          ],
          [
            [
              [-15.400585937499983, 28.147363281249994],
              [-15.406689453124983, 28.070507812499997],
              [-15.383154296874977, 27.992822265624994],
              [-15.38916015625, 27.874707031249997],
              [-15.436767578125, 27.810693359374994],
              [-15.559375, 27.746972656249994],
              [-15.65576171875, 27.7583984375],
              [-15.710302734374977, 27.784082031249994],
              [-15.807324218749983, 27.887548828125],
              [-15.809472656249994, 27.994482421875],
              [-15.720947265625, 28.064160156249997],
              [-15.682763671874994, 28.154052734375],
              [-15.452783203124994, 28.136914062499997],
              [-15.432714843749977, 28.154248046874997],
              [-15.415478515624983, 28.159326171874994],
              [-15.400585937499983, 28.147363281249994],
            ],
          ],
          [
            [
              [-17.184667968749977, 28.02197265625],
              [-17.22539062499999, 28.013525390625],
              [-17.27392578125, 28.03828125],
              [-17.324902343749983, 28.11767578125],
              [-17.29033203124999, 28.176318359374996],
              [-17.25859374999999, 28.203173828124996],
              [-17.21435546875, 28.199267578124996],
              [-17.129638671875, 28.155957031249997],
              [-17.103759765625, 28.111132812499996],
              [-17.10107421875, 28.083447265624997],
              [-17.184667968749977, 28.02197265625],
            ],
          ],
          [
            [
              [-17.887939453125, 27.8095703125],
              [-17.984765625, 27.646386718749994],
              [-18.106591796874994, 27.707470703124997],
              [-18.135937499999983, 27.727929687499994],
              [-18.160546875, 27.761474609375],
              [-18.043359375, 27.768115234374996],
              [-17.924511718749983, 27.850146484374996],
              [-17.887939453125, 27.8095703125],
            ],
          ],
          [
            [
              [-17.83427734374999, 28.493212890624996],
              [-17.859375, 28.485693359375],
              [-17.88212890624999, 28.564599609374994],
              [-18.00078124999999, 28.758251953124997],
              [-17.928808593749977, 28.844580078125],
              [-17.797558593749983, 28.84677734375],
              [-17.74453125, 28.786572265624997],
              [-17.7265625, 28.724462890625],
              [-17.751611328124994, 28.68857421875],
              [-17.744384765625, 28.616015625],
              [-17.758007812499983, 28.569091796875],
              [-17.83427734374999, 28.493212890624996],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Romania",
        sov_a3: "ROU",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Romania",
        adm0_a3: "ROU",
        geou_dif: 0,
        geounit: "Romania",
        gu_a3: "ROU",
        su_dif: 0,
        subunit: "Romania",
        su_a3: "ROU",
        brk_diff: 0,
        name: "Romania",
        name_long: "Romania",
        brk_a3: "ROU",
        brk_name: "Romania",
        brk_group: null,
        abbrev: "Rom.",
        postal: "RO",
        formal_en: "Romania",
        formal_fr: null,
        name_ciawf: "Romania",
        note_adm0: null,
        note_brk: null,
        name_sort: "Romania",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 4,
        mapcolor9: 3,
        mapcolor13: 13,
        pop_est: 19356544,
        pop_rank: 14,
        pop_year: 2019,
        gdp_md: 250077,
        gdp_year: 2019,
        economy: "2. Developed region: nonG7",
        income_grp: "3. Upper middle income",
        fips_10: "RO",
        iso_a2: "RO",
        iso_a2_eh: "RO",
        iso_a3: "ROU",
        iso_a3_eh: "ROU",
        iso_n3: "642",
        iso_n3_eh: "642",
        un_a3: "642",
        wb_a2: "RO",
        wb_a3: "ROM",
        woe_id: 23424933,
        woe_id_eh: 23424933,
        woe_note: "Exact WOE match as country",
        adm0_iso: "ROU",
        adm0_diff: null,
        adm0_tlc: "ROU",
        adm0_a3_us: "ROU",
        adm0_a3_fr: "ROU",
        adm0_a3_ru: "ROU",
        adm0_a3_es: "ROU",
        adm0_a3_cn: "ROU",
        adm0_a3_tw: "ROU",
        adm0_a3_in: "ROU",
        adm0_a3_np: "ROU",
        adm0_a3_pk: "ROU",
        adm0_a3_de: "ROU",
        adm0_a3_gb: "ROU",
        adm0_a3_br: "ROU",
        adm0_a3_il: "ROU",
        adm0_a3_ps: "ROU",
        adm0_a3_sa: "ROU",
        adm0_a3_eg: "ROU",
        adm0_a3_ma: "ROU",
        adm0_a3_pt: "ROU",
        adm0_a3_ar: "ROU",
        adm0_a3_jp: "ROU",
        adm0_a3_ko: "ROU",
        adm0_a3_vn: "ROU",
        adm0_a3_tr: "ROU",
        adm0_a3_id: "ROU",
        adm0_a3_pl: "ROU",
        adm0_a3_gr: "ROU",
        adm0_a3_it: "ROU",
        adm0_a3_nl: "ROU",
        adm0_a3_se: "ROU",
        adm0_a3_bd: "ROU",
        adm0_a3_ua: "ROU",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Eastern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: 24.972624,
        label_y: 45.733237,
        ne_id: 1159321199,
        wikidataid: "Q218",
        name_ar: "رومانيا",
        name_bn: "রোমানিয়া",
        name_de: "Rumänien",
        name_en: "Romania",
        name_es: "Rumania",
        name_fa: "رومانی",
        name_fr: "Roumanie",
        name_el: "Ρουμανία",
        name_he: "רומניה",
        name_hi: "रोमानिया",
        name_hu: "Románia",
        name_id: "Rumania",
        name_it: "Romania",
        name_ja: "ルーマニア",
        name_ko: "루마니아",
        name_nl: "Roemenië",
        name_pl: "Rumunia",
        name_pt: "Roménia",
        name_ru: "Румыния",
        name_sv: "Rumänien",
        name_tr: "Romanya",
        name_uk: "Румунія",
        name_ur: "رومانیہ",
        name_vi: "Romania",
        name_zh: "罗马尼亚",
        name_zht: "羅馬尼亞",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "ROU.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [28.2125, 45.450439453125],
            [28.317675781250017, 45.347119140625],
            [28.451269531250006, 45.2921875],
            [28.7607421875, 45.234130859375],
            [28.78828125000001, 45.240966796875],
            [28.791406250000023, 45.251904296875],
            [28.769824218750017, 45.266894531249996],
            [28.7666015625, 45.28623046875],
            [28.78173828125, 45.309863281249996],
            [28.824316406250006, 45.311083984374996],
            [28.894335937500017, 45.289941406249994],
            [29.027441406250006, 45.320556640625],
            [29.223535156250023, 45.4029296875],
            [29.403710937500023, 45.419677734375],
            [29.567675781250017, 45.37080078125],
            [29.651953125, 45.313916015625],
            [29.705859375000017, 45.259912109374994],
            [29.6890625, 45.193212890625],
            [29.678613281250023, 45.151660156249996],
            [29.635351562500006, 44.979638671874994],
            [29.60546875, 44.915478515625],
            [29.557519531250023, 44.843408203124994],
            [29.048242187500023, 44.757568359375],
            [29.0810546875, 44.798828125],
            [29.069140625000017, 44.871142578124996],
            [29.047753906250023, 44.925683593749994],
            [29.0953125, 44.975048828125],
            [28.98066406250001, 44.992919921875],
            [28.930566406250023, 44.9658203125],
            [28.891503906250023, 44.91865234375],
            [28.926171875000023, 44.810009765625],
            [28.870410156250017, 44.749951171875],
            [28.849023437500023, 44.71630859375],
            [28.846484375000017, 44.636865234374994],
            [28.813574218750006, 44.602490234375],
            [28.807031250000023, 44.5650390625],
            [28.88818359375, 44.574755859374996],
            [28.85175781250001, 44.506103515625],
            [28.69921875, 44.37421875],
            [28.645410156250023, 44.295654296875],
            [28.658593750000023, 43.983837890625],
            [28.590722656250023, 43.797412109374996],
            [28.585351562500023, 43.742236328124996],
            [28.4234375, 43.740478515625],
            [28.37519531250001, 43.744775390624994],
            [28.221972656250017, 43.772851562499994],
            [28.05, 43.822412109374994],
            [27.94892578125001, 43.918603515624994],
            [27.88427734375, 43.987353515624996],
            [27.738574218750017, 43.956298828125],
            [27.710742187500017, 43.964599609375],
            [27.6708984375, 43.997802734375],
            [27.56103515625, 44.020068359374996],
            [27.425390625, 44.0205078125],
            [27.120703125, 44.146142578124994],
            [27.0869140625, 44.167382812499994],
            [26.847753906250006, 44.14619140625],
            [26.4892578125, 44.083984375],
            [26.2158203125, 44.007275390625],
            [25.933398437500017, 43.870556640625],
            [25.81884765625, 43.766845703125],
            [25.686132812500006, 43.711767578125],
            [25.4970703125, 43.670800781249994],
            [25.15966796875, 43.686328125],
            [24.808203125, 43.738427734374994],
            [24.430566406250023, 43.794384765625],
            [24.22675781250001, 43.763476562499996],
            [23.95078125, 43.786669921874996],
            [23.534570312500023, 43.853564453124996],
            [23.224609375, 43.873876953125],
            [22.919042968750006, 43.83447265625],
            [22.86767578125, 43.864550781249996],
            [22.8564453125, 43.8990234375],
            [22.868261718750006, 43.947900390624994],
            [22.91132812500001, 43.987207031249994],
            [22.9853515625, 44.016992187499994],
            [23.0244140625, 44.047216796875],
            [23.02851562500001, 44.077978515625],
            [22.945410156250006, 44.127294921875],
            [22.775195312500017, 44.195214843749994],
            [22.705078125, 44.23779296875],
            [22.687890625000023, 44.248291015625],
            [22.68330078125001, 44.286474609375],
            [22.64794921875, 44.316455078124996],
            [22.581835937500017, 44.338330078125],
            [22.530664062500023, 44.377978515624996],
            [22.494531250000023, 44.435449218749994],
            [22.502343750000023, 44.489599609375],
            [22.554003906250017, 44.540332031249996],
            [22.6201171875, 44.562353515625],
            [22.70078125, 44.555517578125],
            [22.734375, 44.569921875],
            [22.72089843750001, 44.605517578124996],
            [22.64208984375, 44.6509765625],
            [22.49765625, 44.70625],
            [22.350683593750006, 44.676123046875],
            [22.200976562500017, 44.560693359374994],
            [22.093066406250017, 44.541943359375],
            [22.026953125, 44.619873046875],
            [21.909277343750006, 44.66611328125],
            [21.740234375, 44.6806640625],
            [21.636132812500023, 44.71044921875],
            [21.597070312500023, 44.755419921874996],
            [21.52314453125001, 44.790087890624996],
            [21.36005859375001, 44.82666015625],
            [21.35791015625, 44.86181640625],
            [21.384375, 44.870068359375],
            [21.442187500000017, 44.873388671875],
            [21.519921875000023, 44.880810546875],
            [21.532324218750006, 44.900683593749996],
            [21.533203125, 44.918847656249994],
            [21.471972656250017, 44.9419921875],
            [21.409960937500017, 44.95771484375],
            [21.377734375000017, 44.9734375],
            [21.35703125, 44.990771484374996],
            [21.352929687500023, 45.008984375],
            [21.37109375, 45.021386718749994],
            [21.395898437500023, 45.022216796875],
            [21.420703125000017, 45.032958984375],
            [21.434472656250023, 45.075146484375],
            [21.467871093750006, 45.10986328125],
            [21.491796875, 45.122265625],
            [21.490234375, 45.147900390625],
            [21.465429687500006, 45.171875],
            [21.431445312500017, 45.192529296874994],
            [21.381738281250023, 45.205078125],
            [21.226464843750023, 45.24130859375],
            [21.147851562500023, 45.291748046875],
            [21.099902343750017, 45.2935546875],
            [21.023828125000023, 45.321533203125],
            [20.941796875000023, 45.36533203125],
            [20.87080078125001, 45.427539062499996],
            [20.794042968750006, 45.46787109375],
            [20.774218750000017, 45.484423828124996],
            [20.7724609375, 45.50009765625],
            [20.786523437500023, 45.517480468749994],
            [20.786035156250023, 45.536474609375],
            [20.76582031250001, 45.597460937499996],
            [20.779296875, 45.662011718749994],
            [20.775781250000023, 45.722509765625],
            [20.775, 45.749804687499996],
            [20.760156250000023, 45.758105468749996],
            [20.746875, 45.748974609375],
            [20.727832031250017, 45.73740234375],
            [20.709277343750017, 45.735253906249994],
            [20.652734375000023, 45.77939453125],
            [20.581152343750006, 45.869482421875],
            [20.532617187500023, 45.89951171875],
            [20.43798828125, 45.940771484375],
            [20.35859375000001, 45.975488281249994],
            [20.301367187500006, 46.050683593749994],
            [20.241796875, 46.10859375],
            [20.28095703125001, 46.1330078125],
            [20.508105468750017, 46.166943359375],
            [20.613671875000023, 46.13349609375],
            [20.661035156250023, 46.145654296874994],
            [20.707421875000023, 46.172802734375],
            [20.732714843750017, 46.19443359375],
            [20.737402343750006, 46.21748046875],
            [20.76025390625, 46.246240234374994],
            [20.837011718750006, 46.259716796875],
            [21.039843750000017, 46.242236328124996],
            [21.121679687500006, 46.282421875],
            [21.151953125, 46.304345703124994],
            [21.17041015625, 46.352685546874994],
            [21.191796875000023, 46.391552734375],
            [21.264550781250023, 46.4123046875],
            [21.26328125, 46.44775390625],
            [21.252246093750017, 46.486376953124996],
            [21.29453125, 46.5724609375],
            [21.320214843750023, 46.6078125],
            [21.361328125, 46.620751953124994],
            [21.411035156250023, 46.647851562499994],
            [21.4970703125, 46.704296875],
            [21.47705078125, 46.753369140625],
            [21.494433593750017, 46.78974609375],
            [21.58417968750001, 46.878369140625],
            [21.652636718750017, 46.963769531249994],
            [21.651464843750006, 47.00654296875],
            [21.661425781250017, 47.043896484375],
            [21.721777343750006, 47.084814453125],
            [21.785449218750017, 47.138134765625],
            [21.86933593750001, 47.304589843749994],
            [21.899218750000017, 47.332568359374996],
            [21.95429687500001, 47.3642578125],
            [21.99531250000001, 47.395703125],
            [21.99970703125001, 47.505029296874994],
            [22.037988281250023, 47.53662109375],
            [22.111914062500006, 47.572021484375],
            [22.18505859375, 47.629052734374994],
            [22.24462890625, 47.69638671875],
            [22.290625, 47.727832031249996],
            [22.351464843750023, 47.736230468749994],
            [22.41748046875, 47.762646484375],
            [22.49140625000001, 47.77255859375],
            [22.562890625000023, 47.759570312499996],
            [22.6083984375, 47.76630859375],
            [22.6767578125, 47.7990234375],
            [22.85175781250001, 47.92255859375],
            [22.87666015625001, 47.947265625],
            [22.912890625000017, 47.964257812499994],
            [23.054785156250006, 48.00654296875],
            [23.0908203125, 48.049121093749996],
            [23.139453125000017, 48.08740234375],
            [23.20263671875, 48.084521484374996],
            [23.408203125, 47.989990234375],
            [23.628710937500017, 47.995849609375],
            [23.669042968750006, 47.992333984374994],
            [23.682031250000023, 47.990380859374994],
            [23.708984375, 47.9826171875],
            [24.001855468750023, 47.935791015625],
            [24.04736328125, 47.941015625],
            [24.05976562500001, 47.944775390625],
            [24.177734375, 47.906054687499996],
            [24.28193359375001, 47.911181640624996],
            [24.380957031250006, 47.938037109374996],
            [24.48408203125001, 47.947119140625],
            [24.578906250000017, 47.931054687499994],
            [24.650976562500006, 47.876513671874996],
            [24.837890625, 47.76083984375],
            [24.893359375000017, 47.7177734375],
            [24.979101562500006, 47.72412109375],
            [25.073828125, 47.745703125000176],
            [25.16962890625001, 47.823095703125],
            [25.464257812500023, 47.910791015624994],
            [25.689257812500017, 47.932470703125],
            [25.90869140625, 47.967578125],
            [26.162695312500006, 47.992529296875],
            [26.236230468750023, 48.064355468749994],
            [26.276953125, 48.113232421875],
            [26.3056640625, 48.203759765624994],
            [26.4423828125, 48.22998046875],
            [26.57246093750001, 48.248486328125],
            [26.618945312500017, 48.25986328125],
            [26.713769531250023, 48.263476562499996],
            [26.78730468750001, 48.255810546875],
            [26.900976562500006, 48.2111328125],
            [26.980761718750017, 48.155029296875],
            [27.01220703125, 48.110498046874994],
            [27.080371093750017, 48.04765625],
            [27.15205078125001, 47.959277343749996],
            [27.230859375000023, 47.841748046875],
            [27.248144531250006, 47.7822265625],
            [27.277929687500006, 47.71796875],
            [27.3369140625, 47.63974609375],
            [27.44921875, 47.553125],
            [27.46484375, 47.536669921874996],
            [27.51582031250001, 47.475634765624996],
            [27.614062500000017, 47.34052734375],
            [27.696191406250023, 47.286425781249996],
            [27.767968750000023, 47.227587890624996],
            [27.80234375, 47.168310546875],
            [27.853808593750017, 47.114501953125],
            [27.97421875, 47.043212890625],
            [28.07177734375, 46.978417968749994],
            [28.15, 46.79208984375],
            [28.2046875, 46.706396484375],
            [28.23945312500001, 46.6408203125],
            [28.22265625, 46.508056640625],
            [28.24433593750001, 46.45126953125],
            [28.199609375000023, 46.347558593749994],
            [28.119140625, 46.138671875],
            [28.099707031250006, 45.972607421875],
            [28.113574218750017, 45.883056640625],
            [28.115527343750017, 45.825537109375],
            [28.13496093750001, 45.788867187499996],
            [28.15625, 45.7130859375],
            [28.159765625, 45.647119140624994],
            [28.130859375, 45.628271484375],
            [28.09033203125, 45.612744140625],
            [28.07470703125, 45.598974609375],
            [28.111914062500006, 45.569140625],
            [28.1625, 45.51376953125],
            [28.2125, 45.450439453125],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 5,
        sovereignt: "Hungary",
        sov_a3: "HUN",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Hungary",
        adm0_a3: "HUN",
        geou_dif: 0,
        geounit: "Hungary",
        gu_a3: "HUN",
        su_dif: 0,
        subunit: "Hungary",
        su_a3: "HUN",
        brk_diff: 0,
        name: "Hungary",
        name_long: "Hungary",
        brk_a3: "HUN",
        brk_name: "Hungary",
        brk_group: null,
        abbrev: "Hun.",
        postal: "HU",
        formal_en: "Republic of Hungary",
        formal_fr: null,
        name_ciawf: "Hungary",
        note_adm0: null,
        note_brk: null,
        name_sort: "Hungary",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 6,
        mapcolor9: 1,
        mapcolor13: 5,
        pop_est: 9769949,
        pop_rank: 13,
        pop_year: 2019,
        gdp_md: 163469,
        gdp_year: 2019,
        economy: "2. Developed region: nonG7",
        income_grp: "1. High income: OECD",
        fips_10: "HU",
        iso_a2: "HU",
        iso_a2_eh: "HU",
        iso_a3: "HUN",
        iso_a3_eh: "HUN",
        iso_n3: "348",
        iso_n3_eh: "348",
        un_a3: "348",
        wb_a2: "HU",
        wb_a3: "HUN",
        woe_id: 23424844,
        woe_id_eh: 23424844,
        woe_note: "Exact WOE match as country",
        adm0_iso: "HUN",
        adm0_diff: null,
        adm0_tlc: "HUN",
        adm0_a3_us: "HUN",
        adm0_a3_fr: "HUN",
        adm0_a3_ru: "HUN",
        adm0_a3_es: "HUN",
        adm0_a3_cn: "HUN",
        adm0_a3_tw: "HUN",
        adm0_a3_in: "HUN",
        adm0_a3_np: "HUN",
        adm0_a3_pk: "HUN",
        adm0_a3_de: "HUN",
        adm0_a3_gb: "HUN",
        adm0_a3_br: "HUN",
        adm0_a3_il: "HUN",
        adm0_a3_ps: "HUN",
        adm0_a3_sa: "HUN",
        adm0_a3_eg: "HUN",
        adm0_a3_ma: "HUN",
        adm0_a3_pt: "HUN",
        adm0_a3_ar: "HUN",
        adm0_a3_jp: "HUN",
        adm0_a3_ko: "HUN",
        adm0_a3_vn: "HUN",
        adm0_a3_tr: "HUN",
        adm0_a3_id: "HUN",
        adm0_a3_pl: "HUN",
        adm0_a3_gr: "HUN",
        adm0_a3_it: "HUN",
        adm0_a3_nl: "HUN",
        adm0_a3_se: "HUN",
        adm0_a3_bd: "HUN",
        adm0_a3_ua: "HUN",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Eastern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: 19.447867,
        label_y: 47.086841,
        ne_id: 1159320841,
        wikidataid: "Q28",
        name_ar: "المجر",
        name_bn: "হাঙ্গেরি",
        name_de: "Ungarn",
        name_en: "Hungary",
        name_es: "Hungría",
        name_fa: "مجارستان",
        name_fr: "Hongrie",
        name_el: "Ουγγαρία",
        name_he: "הונגריה",
        name_hi: "हंगरी",
        name_hu: "Magyarország",
        name_id: "Hongaria",
        name_it: "Ungheria",
        name_ja: "ハンガリー",
        name_ko: "헝가리",
        name_nl: "Hongarije",
        name_pl: "Węgry",
        name_pt: "Hungria",
        name_ru: "Венгрия",
        name_sv: "Ungern",
        name_tr: "Macaristan",
        name_uk: "Угорщина",
        name_ur: "ہنگری",
        name_vi: "Hungary",
        name_zh: "匈牙利",
        name_zht: "匈牙利",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "HUN.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [22.1318359375, 48.405322265624996],
            [22.227148437500006, 48.413427734375],
            [22.23115234375001, 48.412158203124996],
            [22.253710937500017, 48.407373046874994],
            [22.269433593750023, 48.360888671874996],
            [22.27216796875001, 48.3580078125],
            [22.29511718750001, 48.327294921874994],
            [22.316699218750017, 48.28662109375],
            [22.350195312500006, 48.2560546875],
            [22.423828125, 48.243310546874994],
            [22.520117187500006, 48.205371093749996],
            [22.582421875000023, 48.134033203125],
            [22.676367187500006, 48.104394531249994],
            [22.68310546875, 48.10361328125],
            [22.701562500000023, 48.10703125],
            [22.769140625, 48.109619140625],
            [22.7822265625, 48.09521484375],
            [22.836230468750017, 48.060302734375],
            [22.857226562500017, 48.029541015625],
            [22.846484375000017, 47.9970703125],
            [22.856054687500006, 47.960302734375],
            [22.87666015625001, 47.947265625],
            [22.85175781250001, 47.92255859375],
            [22.6767578125, 47.7990234375],
            [22.6083984375, 47.76630859375],
            [22.562890625000023, 47.759570312499996],
            [22.49140625000001, 47.77255859375],
            [22.41748046875, 47.762646484375],
            [22.351464843750023, 47.736230468749994],
            [22.290625, 47.727832031249996],
            [22.24462890625, 47.69638671875],
            [22.18505859375, 47.629052734374994],
            [22.111914062500006, 47.572021484375],
            [22.037988281250023, 47.53662109375],
            [21.99970703125001, 47.505029296874994],
            [21.99531250000001, 47.395703125],
            [21.95429687500001, 47.3642578125],
            [21.899218750000017, 47.332568359374996],
            [21.86933593750001, 47.304589843749994],
            [21.785449218750017, 47.138134765625],
            [21.721777343750006, 47.084814453125],
            [21.661425781250017, 47.043896484375],
            [21.651464843750006, 47.00654296875],
            [21.652636718750017, 46.963769531249994],
            [21.58417968750001, 46.878369140625],
            [21.494433593750017, 46.78974609375],
            [21.47705078125, 46.753369140625],
            [21.4970703125, 46.704296875],
            [21.411035156250023, 46.647851562499994],
            [21.361328125, 46.620751953124994],
            [21.320214843750023, 46.6078125],
            [21.29453125, 46.5724609375],
            [21.252246093750017, 46.486376953124996],
            [21.26328125, 46.44775390625],
            [21.264550781250023, 46.4123046875],
            [21.191796875000023, 46.391552734375],
            [21.17041015625, 46.352685546874994],
            [21.151953125, 46.304345703124994],
            [21.121679687500006, 46.282421875],
            [21.039843750000017, 46.242236328124996],
            [20.837011718750006, 46.259716796875],
            [20.76025390625, 46.246240234374994],
            [20.737402343750006, 46.21748046875],
            [20.732714843750017, 46.19443359375],
            [20.707421875000023, 46.172802734375],
            [20.661035156250023, 46.145654296874994],
            [20.613671875000023, 46.13349609375],
            [20.508105468750017, 46.166943359375],
            [20.28095703125001, 46.1330078125],
            [20.241796875, 46.10859375],
            [20.21015625000001, 46.126025390624996],
            [20.161425781250017, 46.141894531249996],
            [19.93408203125, 46.161474609375],
            [19.84443359375001, 46.145898437499994],
            [19.724511718750023, 46.151904296874996],
            [19.61347656250001, 46.169189453125],
            [19.53076171875, 46.155175781249994],
            [19.45751953125, 46.087353515625],
            [19.421289062500023, 46.064453125],
            [19.392871093750017, 46.0498046875],
            [19.33027343750001, 46.028515625],
            [19.278125, 46.002880859375],
            [19.208398437500023, 45.984423828124996],
            [19.146289062500017, 45.98701171875],
            [19.087304687500023, 46.016162109374996],
            [19.066210937500017, 46.009521484375],
            [19.047656250000017, 45.982666015625],
            [19.015722656250006, 45.959716796875],
            [18.927832031250006, 45.931396484375],
            [18.905371093750006, 45.93173828125],
            [18.900292968750023, 45.93173828125],
            [18.8330078125, 45.91083984375],
            [18.721777343750006, 45.899365234375],
            [18.666015625, 45.907470703125],
            [18.56464843750001, 45.81328125],
            [18.533593750000023, 45.796142578125],
            [18.437304687500017, 45.767333984375],
            [18.358300781250023, 45.75302734375],
            [18.290625, 45.764453125],
            [18.263964843750017, 45.765478515625],
            [17.9638671875, 45.770263671875],
            [17.80712890625, 45.790429687499994],
            [17.706445312500023, 45.82724609375],
            [17.6396484375, 45.868359375],
            [17.60703125, 45.91376953125],
            [17.529199218750023, 45.941308593749994],
            [17.406347656250006, 45.951074218749994],
            [17.310644531250006, 45.996142578124996],
            [17.2421875, 46.076611328125],
            [17.14960937500001, 46.14033203125],
            [17.03271484375, 46.187304687499996],
            [16.93994140625, 46.253662109375],
            [16.871484375000023, 46.339306640625],
            [16.748046875, 46.41640625],
            [16.569921875, 46.485009765624994],
            [16.516210937500006, 46.499902343749994],
            [16.505664062500017, 46.5220703125],
            [16.41845703125, 46.607226562499996],
            [16.38125, 46.638671875],
            [16.384570312500017, 46.680810546874994],
            [16.3671875, 46.70478515625],
            [16.33544921875, 46.721630859375],
            [16.318457031250006, 46.782519531249996],
            [16.308496093750023, 46.827978515625],
            [16.283593750000023, 46.857275390625],
            [16.093066406250017, 46.86328125],
            [16.252539062500006, 46.971923828125],
            [16.331835937500017, 47.002197265625],
            [16.423925781250006, 46.996972656249994],
            [16.453417968750017, 47.006787109375],
            [16.461230468750017, 47.0224609375],
            [16.476953125000023, 47.057861328125],
            [16.484765625000023, 47.091259765625],
            [16.49267578125, 47.12265625],
            [16.482812500000023, 47.140380859375],
            [16.438378906250023, 47.145898437499994],
            [16.416894531250023, 47.2234375],
            [16.439746093750017, 47.252734375],
            [16.46259765625001, 47.27314453125],
            [16.434375, 47.367431640625],
            [16.44287109375, 47.39951171875],
            [16.514746093750006, 47.404541015625],
            [16.57441406250001, 47.424658203125],
            [16.623046875, 47.447558593749996],
            [16.636621093750023, 47.476611328124996],
            [16.676562500000017, 47.536035156249994],
            [16.639746093750006, 47.60888671875],
            [16.43212890625, 47.656298828124996],
            [16.421289062500023, 47.674462890624994],
            [16.469628906250023, 47.695068359375],
            [16.52109375, 47.724462890625],
            [16.55097656250001, 47.747363281249996],
            [16.590917968750006, 47.750537109374996],
            [16.6474609375, 47.739013671875],
            [16.74755859375, 47.686279296875],
            [16.785937500000017, 47.678662109375],
            [16.823046875000017, 47.693994140624994],
            [16.862695312500023, 47.697265625],
            [16.973437500000017, 47.6953125],
            [17.06660156250001, 47.707568359374996],
            [17.04560546875001, 47.76376953125],
            [17.0458984375, 47.804541015625],
            [17.030078125000017, 47.837109375],
            [17.039941406250023, 47.872949218749994],
            [17.077734375, 47.90087890625],
            [17.08906250000001, 47.963623046875],
            [17.147363281250023, 48.00595703125],
            [17.174609375000017, 48.012060546875],
            [17.277246093750023, 48.004345703125],
            [17.301562500000017, 47.993359375],
            [17.317285156250023, 47.99091796875],
            [17.48066406250001, 47.887597656249994],
            [17.63525390625, 47.809912109375],
            [17.76191406250001, 47.770166015624994],
            [17.94794921875001, 47.766894531249996],
            [18.145605468750006, 47.763427734375],
            [18.47626953125001, 47.777001953124994],
            [18.72421875, 47.787158203124996],
            [18.740625, 47.806494140625],
            [18.77802734375001, 47.852880859375],
            [18.748339843750017, 47.89267578125],
            [18.750097656250006, 47.939453125],
            [18.791894531250023, 48.000292968749996],
            [18.914160156250006, 48.050830078124996],
            [19.26513671875, 48.073046875],
            [19.46699218750001, 48.110693359375],
            [19.497460937500023, 48.162109375],
            [19.564257812500017, 48.212841796875],
            [19.625390625000023, 48.223095703125],
            [19.70917968750001, 48.1998046875],
            [19.81005859375, 48.155029296875],
            [19.89863281250001, 48.13134765625],
            [19.95039062500001, 48.146630859374994],
            [20.12861328125001, 48.222021484375],
            [20.333789062500017, 48.295556640624994],
            [20.475, 48.4951171875],
            [20.490039062500017, 48.526904296874996],
            [20.643164062500006, 48.549707031249994],
            [20.866601562500023, 48.545654296875],
            [20.98115234375001, 48.519677734374994],
            [21.067285156250023, 48.505908203124996],
            [21.196386718750006, 48.510595703125],
            [21.382421875, 48.553466796875],
            [21.45136718750001, 48.55224609375],
            [21.504687500000017, 48.521875],
            [21.56318359375001, 48.495703125],
            [21.602636718750006, 48.463671875],
            [21.63251953125001, 48.418505859374996],
            [21.64863281250001, 48.40146484375],
            [21.674609375000017, 48.378369140625],
            [21.721484375000017, 48.346582031249994],
            [21.766992187500023, 48.3380859375],
            [22.111328125, 48.393359375],
            [22.1318359375, 48.405322265624996],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 6,
        sovereignt: "Slovakia",
        sov_a3: "SVK",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Slovakia",
        adm0_a3: "SVK",
        geou_dif: 0,
        geounit: "Slovakia",
        gu_a3: "SVK",
        su_dif: 0,
        subunit: "Slovakia",
        su_a3: "SVK",
        brk_diff: 0,
        name: "Slovakia",
        name_long: "Slovakia",
        brk_a3: "SVK",
        brk_name: "Slovakia",
        brk_group: null,
        abbrev: "Svk.",
        postal: "SK",
        formal_en: "Slovak Republic",
        formal_fr: null,
        name_ciawf: "Slovakia",
        note_adm0: null,
        note_brk: null,
        name_sort: "Slovak Republic",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 4,
        mapcolor9: 4,
        mapcolor13: 9,
        pop_est: 5454073,
        pop_rank: 13,
        pop_year: 2019,
        gdp_md: 105079,
        gdp_year: 2019,
        economy: "2. Developed region: nonG7",
        income_grp: "1. High income: OECD",
        fips_10: "LO",
        iso_a2: "SK",
        iso_a2_eh: "SK",
        iso_a3: "SVK",
        iso_a3_eh: "SVK",
        iso_n3: "703",
        iso_n3_eh: "703",
        un_a3: "703",
        wb_a2: "SK",
        wb_a3: "SVK",
        woe_id: 23424877,
        woe_id_eh: 23424877,
        woe_note: "Exact WOE match as country",
        adm0_iso: "SVK",
        adm0_diff: null,
        adm0_tlc: "SVK",
        adm0_a3_us: "SVK",
        adm0_a3_fr: "SVK",
        adm0_a3_ru: "SVK",
        adm0_a3_es: "SVK",
        adm0_a3_cn: "SVK",
        adm0_a3_tw: "SVK",
        adm0_a3_in: "SVK",
        adm0_a3_np: "SVK",
        adm0_a3_pk: "SVK",
        adm0_a3_de: "SVK",
        adm0_a3_gb: "SVK",
        adm0_a3_br: "SVK",
        adm0_a3_il: "SVK",
        adm0_a3_ps: "SVK",
        adm0_a3_sa: "SVK",
        adm0_a3_eg: "SVK",
        adm0_a3_ma: "SVK",
        adm0_a3_pt: "SVK",
        adm0_a3_ar: "SVK",
        adm0_a3_jp: "SVK",
        adm0_a3_ko: "SVK",
        adm0_a3_vn: "SVK",
        adm0_a3_tr: "SVK",
        adm0_a3_id: "SVK",
        adm0_a3_pl: "SVK",
        adm0_a3_gr: "SVK",
        adm0_a3_it: "SVK",
        adm0_a3_nl: "SVK",
        adm0_a3_se: "SVK",
        adm0_a3_bd: "SVK",
        adm0_a3_ua: "SVK",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Eastern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 8,
        long_len: 8,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: 19.049868,
        label_y: 48.734044,
        ne_id: 1159321283,
        wikidataid: "Q214",
        name_ar: "سلوفاكيا",
        name_bn: "স্লোভাকিয়া",
        name_de: "Slowakei",
        name_en: "Slovakia",
        name_es: "Eslovaquia",
        name_fa: "اسلواکی",
        name_fr: "Slovaquie",
        name_el: "Σλοβακία",
        name_he: "סלובקיה",
        name_hi: "स्लोवाकिया",
        name_hu: "Szlovákia",
        name_id: "Slowakia",
        name_it: "Slovacchia",
        name_ja: "スロバキア",
        name_ko: "슬로바키아",
        name_nl: "Slowakije",
        name_pl: "Słowacja",
        name_pt: "Eslováquia",
        name_ru: "Словакия",
        name_sv: "Slovakien",
        name_tr: "Slovakya",
        name_uk: "Словаччина",
        name_ur: "سلوواکیہ",
        name_vi: "Slovakia",
        name_zh: "斯洛伐克",
        name_zht: "斯洛伐克",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "SVK.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [22.538671875, 49.072705078125],
            [22.52412109375001, 49.031396484374994],
            [22.483203125000017, 48.983251953125],
            [22.432031250000023, 48.933544921875],
            [22.389453125000017, 48.873486328125],
            [22.332617187500006, 48.745068359375],
            [22.295214843750017, 48.685839843749996],
            [22.142871093750017, 48.568505859374994],
            [22.1318359375, 48.405322265624996],
            [22.111328125, 48.393359375],
            [21.766992187500023, 48.3380859375],
            [21.721484375000017, 48.346582031249994],
            [21.674609375000017, 48.378369140625],
            [21.64863281250001, 48.40146484375],
            [21.63251953125001, 48.418505859374996],
            [21.602636718750006, 48.463671875],
            [21.56318359375001, 48.495703125],
            [21.504687500000017, 48.521875],
            [21.45136718750001, 48.55224609375],
            [21.382421875, 48.553466796875],
            [21.196386718750006, 48.510595703125],
            [21.067285156250023, 48.505908203124996],
            [20.98115234375001, 48.519677734374994],
            [20.866601562500023, 48.545654296875],
            [20.643164062500006, 48.549707031249994],
            [20.490039062500017, 48.526904296874996],
            [20.475, 48.4951171875],
            [20.333789062500017, 48.295556640624994],
            [20.12861328125001, 48.222021484375],
            [19.95039062500001, 48.146630859374994],
            [19.89863281250001, 48.13134765625],
            [19.81005859375, 48.155029296875],
            [19.70917968750001, 48.1998046875],
            [19.625390625000023, 48.223095703125],
            [19.564257812500017, 48.212841796875],
            [19.497460937500023, 48.162109375],
            [19.46699218750001, 48.110693359375],
            [19.26513671875, 48.073046875],
            [18.914160156250006, 48.050830078124996],
            [18.791894531250023, 48.000292968749996],
            [18.750097656250006, 47.939453125],
            [18.748339843750017, 47.89267578125],
            [18.77802734375001, 47.852880859375],
            [18.740625, 47.806494140625],
            [18.72421875, 47.787158203124996],
            [18.47626953125001, 47.777001953124994],
            [18.145605468750006, 47.763427734375],
            [17.94794921875001, 47.766894531249996],
            [17.76191406250001, 47.770166015624994],
            [17.63525390625, 47.809912109375],
            [17.48066406250001, 47.887597656249994],
            [17.317285156250023, 47.99091796875],
            [17.301562500000017, 47.993359375],
            [17.277246093750023, 48.004345703125],
            [17.174609375000017, 48.012060546875],
            [17.147363281250023, 48.00595703125],
            [17.0859375, 48.03955078125],
            [17.06787109375, 48.083251953125],
            [16.97265625, 48.198095703125],
            [16.86542968750001, 48.3869140625],
            [16.862695312500023, 48.44140625],
            [16.90449218750001, 48.503515625],
            [16.943359375, 48.550927734374994],
            [16.948828125, 48.58857421875],
            [16.953125, 48.598828125],
            [16.985253906250023, 48.676904296874994],
            [17.063281250000017, 48.78076171875],
            [17.135644531250023, 48.841064453125],
            [17.1884765625, 48.8609375],
            [17.296875, 48.842822265624996],
            [17.48261718750001, 48.827783203124994],
            [17.625390625000023, 48.841845703124996],
            [17.75849609375001, 48.888134765625],
            [17.830859375000017, 48.928613281249994],
            [17.892675781250006, 48.971142578125],
            [17.91328125000001, 48.99873046875],
            [17.940722656250017, 49.011962890625],
            [18.050878906250006, 49.036523437499994],
            [18.0859375, 49.06513671875],
            [18.100390625000017, 49.1193359375],
            [18.109960937500006, 49.17978515625],
            [18.132617187500017, 49.224560546875],
            [18.160937500000017, 49.257373046874996],
            [18.36484375, 49.336230468749996],
            [18.383105468750017, 49.363916015624994],
            [18.415820312500017, 49.390917968749996],
            [18.47607421875, 49.42109375],
            [18.534570312500023, 49.464697265625],
            [18.596484375000017, 49.491455078125],
            [18.676171875000023, 49.4884765625],
            [18.74970703125001, 49.493994140625],
            [18.807031250000023, 49.509228515625],
            [18.83222656250001, 49.510791015624996],
            [18.93818359375001, 49.498291015625],
            [18.95722656250001, 49.448291015624996],
            [18.968359375, 49.396240234375],
            [19.1494140625, 49.4],
            [19.25019531250001, 49.51142578125],
            [19.30234375, 49.524853515625],
            [19.38623046875, 49.563623046874994],
            [19.44160156250001, 49.597705078124996],
            [19.47968750000001, 49.5763671875],
            [19.534765625, 49.504785156249994],
            [19.593066406250017, 49.447119140625],
            [19.62666015625001, 49.424365234374996],
            [19.630273437500023, 49.406640625],
            [19.664160156250006, 49.396044921874996],
            [19.730078125, 49.389599609375],
            [19.77392578125, 49.37216796875],
            [19.787011718750023, 49.3185546875],
            [19.787988281250023, 49.269970703125],
            [19.767382812500017, 49.235205078125],
            [19.756640625000017, 49.204394531249996],
            [19.80224609375, 49.192333984375],
            [19.868945312500017, 49.204003906249994],
            [19.916113281250006, 49.22138671875],
            [20.0576171875, 49.181298828124994],
            [20.10761718750001, 49.270751953125],
            [20.163671875, 49.31640625],
            [20.23652343750001, 49.337646484375],
            [20.302539062500017, 49.365527343749996],
            [20.36298828125001, 49.38525390625],
            [20.404687500000023, 49.384082031249996],
            [20.422656250000017, 49.392333984375],
            [20.474511718750023, 49.390185546874996],
            [20.534570312500023, 49.381201171875],
            [20.616113281250023, 49.39169921875],
            [20.72900390625, 49.369921875],
            [20.79951171875001, 49.328662109374996],
            [20.868457031250017, 49.314697265625],
            [20.947265625, 49.31708984375],
            [21.00117187500001, 49.33984375],
            [21.079394531250017, 49.418261718749996],
            [21.136132812500023, 49.417041015624996],
            [21.225, 49.429443359375],
            [21.350488281250023, 49.428759765624996],
            [21.6396484375, 49.411962890625],
            [21.71210937500001, 49.38193359375],
            [21.89013671875, 49.34345703125],
            [21.967675781250023, 49.299072265625],
            [22.00214843750001, 49.24609375],
            [22.020117187500006, 49.209521484374996],
            [22.202539062500023, 49.153222656249994],
            [22.473046875000023, 49.081298828125],
            [22.538671875, 49.072705078125],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Poland",
        sov_a3: "POL",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Poland",
        adm0_a3: "POL",
        geou_dif: 0,
        geounit: "Poland",
        gu_a3: "POL",
        su_dif: 0,
        subunit: "Poland",
        su_a3: "POL",
        brk_diff: 0,
        name: "Poland",
        name_long: "Poland",
        brk_a3: "POL",
        brk_name: "Poland",
        brk_group: null,
        abbrev: "Pol.",
        postal: "PL",
        formal_en: "Republic of Poland",
        formal_fr: null,
        name_ciawf: "Poland",
        note_adm0: null,
        note_brk: null,
        name_sort: "Poland",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 7,
        mapcolor9: 1,
        mapcolor13: 2,
        pop_est: 37970874,
        pop_rank: 15,
        pop_year: 2019,
        gdp_md: 595858,
        gdp_year: 2019,
        economy: "2. Developed region: nonG7",
        income_grp: "1. High income: OECD",
        fips_10: "PL",
        iso_a2: "PL",
        iso_a2_eh: "PL",
        iso_a3: "POL",
        iso_a3_eh: "POL",
        iso_n3: "616",
        iso_n3_eh: "616",
        un_a3: "616",
        wb_a2: "PL",
        wb_a3: "POL",
        woe_id: 23424923,
        woe_id_eh: 23424923,
        woe_note: "Exact WOE match as country",
        adm0_iso: "POL",
        adm0_diff: null,
        adm0_tlc: "POL",
        adm0_a3_us: "POL",
        adm0_a3_fr: "POL",
        adm0_a3_ru: "POL",
        adm0_a3_es: "POL",
        adm0_a3_cn: "POL",
        adm0_a3_tw: "POL",
        adm0_a3_in: "POL",
        adm0_a3_np: "POL",
        adm0_a3_pk: "POL",
        adm0_a3_de: "POL",
        adm0_a3_gb: "POL",
        adm0_a3_br: "POL",
        adm0_a3_il: "POL",
        adm0_a3_ps: "POL",
        adm0_a3_sa: "POL",
        adm0_a3_eg: "POL",
        adm0_a3_ma: "POL",
        adm0_a3_pt: "POL",
        adm0_a3_ar: "POL",
        adm0_a3_jp: "POL",
        adm0_a3_ko: "POL",
        adm0_a3_vn: "POL",
        adm0_a3_tr: "POL",
        adm0_a3_id: "POL",
        adm0_a3_pl: "POL",
        adm0_a3_gr: "POL",
        adm0_a3_it: "POL",
        adm0_a3_nl: "POL",
        adm0_a3_se: "POL",
        adm0_a3_bd: "POL",
        adm0_a3_ua: "POL",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Eastern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 6,
        long_len: 6,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 2.5,
        max_label: 7,
        label_x: 19.490468,
        label_y: 51.990316,
        ne_id: 1159321179,
        wikidataid: "Q36",
        name_ar: "بولندا",
        name_bn: "পোল্যান্ড",
        name_de: "Polen",
        name_en: "Poland",
        name_es: "Polonia",
        name_fa: "لهستان",
        name_fr: "Pologne",
        name_el: "Πολωνία",
        name_he: "פולין",
        name_hi: "पोलैंड",
        name_hu: "Lengyelország",
        name_id: "Polandia",
        name_it: "Polonia",
        name_ja: "ポーランド",
        name_ko: "폴란드",
        name_nl: "Polen",
        name_pl: "Polska",
        name_pt: "Polónia",
        name_ru: "Польша",
        name_sv: "Polen",
        name_tr: "Polonya",
        name_uk: "Польща",
        name_ur: "پولینڈ",
        name_vi: "Ba Lan",
        name_zh: "波兰",
        name_zht: "波蘭",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "POL.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [23.605273437500017, 51.517919921875],
            [23.65888671875001, 51.448974609375],
            [23.6796875, 51.394921875],
            [23.657617187500023, 51.352490234375],
            [23.664453125000023, 51.31005859375],
            [23.712207031250017, 51.26513671875],
            [23.86347656250001, 51.126220703125],
            [23.938085937500006, 50.992529296875],
            [23.985742187500023, 50.9404296875],
            [24.095800781250006, 50.87275390625],
            [24.105761718750017, 50.844970703125],
            [24.061621093750006, 50.81953125],
            [24.025976562500006, 50.816162109375],
            [23.9970703125, 50.809375],
            [23.978417968750023, 50.785595703125],
            [24.00732421875, 50.76015625],
            [24.046289062500023, 50.722802734374994],
            [24.0947265625, 50.617041015625],
            [24.089941406250006, 50.53046875],
            [24.052636718750023, 50.508447265624994],
            [24.004980468750006, 50.45703125],
            [23.97265625, 50.410058593749994],
            [23.711718750000017, 50.37734375],
            [23.649023437500006, 50.327050781249994],
            [23.506152343750017, 50.229833984375],
            [23.408593750000023, 50.17392578125],
            [23.264453125000017, 50.0728515625],
            [23.03632812500001, 49.899072265624994],
            [22.952246093750006, 49.8263671875],
            [22.890722656250006, 49.766259765624994],
            [22.706152343750006, 49.606201171875],
            [22.6494140625, 49.539013671875],
            [22.66064453125, 49.483691406249996],
            [22.71992187500001, 49.353808593749996],
            [22.732421875, 49.295166015625],
            [22.721972656250017, 49.240966796875],
            [22.70234375000001, 49.192724609375],
            [22.705664062500006, 49.171191406249996],
            [22.760156250000023, 49.13623046875],
            [22.847070312500023, 49.08125],
            [22.85205078125, 49.062744140625],
            [22.839746093750023, 49.038916015625],
            [22.80976562500001, 49.020751953125],
            [22.701269531250006, 49.039941406249994],
            [22.579980468750023, 49.077197265624996],
            [22.538671875, 49.072705078125],
            [22.473046875000023, 49.081298828125],
            [22.202539062500023, 49.153222656249994],
            [22.020117187500006, 49.209521484374996],
            [22.00214843750001, 49.24609375],
            [21.967675781250023, 49.299072265625],
            [21.89013671875, 49.34345703125],
            [21.71210937500001, 49.38193359375],
            [21.6396484375, 49.411962890625],
            [21.350488281250023, 49.428759765624996],
            [21.225, 49.429443359375],
            [21.136132812500023, 49.417041015624996],
            [21.079394531250017, 49.418261718749996],
            [21.00117187500001, 49.33984375],
            [20.947265625, 49.31708984375],
            [20.868457031250017, 49.314697265625],
            [20.79951171875001, 49.328662109374996],
            [20.72900390625, 49.369921875],
            [20.616113281250023, 49.39169921875],
            [20.534570312500023, 49.381201171875],
            [20.474511718750023, 49.390185546874996],
            [20.422656250000017, 49.392333984375],
            [20.404687500000023, 49.384082031249996],
            [20.36298828125001, 49.38525390625],
            [20.302539062500017, 49.365527343749996],
            [20.23652343750001, 49.337646484375],
            [20.163671875, 49.31640625],
            [20.10761718750001, 49.270751953125],
            [20.0576171875, 49.181298828124994],
            [19.916113281250006, 49.22138671875],
            [19.868945312500017, 49.204003906249994],
            [19.80224609375, 49.192333984375],
            [19.756640625000017, 49.204394531249996],
            [19.767382812500017, 49.235205078125],
            [19.787988281250023, 49.269970703125],
            [19.787011718750023, 49.3185546875],
            [19.77392578125, 49.37216796875],
            [19.730078125, 49.389599609375],
            [19.664160156250006, 49.396044921874996],
            [19.630273437500023, 49.406640625],
            [19.62666015625001, 49.424365234374996],
            [19.593066406250017, 49.447119140625],
            [19.534765625, 49.504785156249994],
            [19.47968750000001, 49.5763671875],
            [19.44160156250001, 49.597705078124996],
            [19.38623046875, 49.563623046874994],
            [19.30234375, 49.524853515625],
            [19.25019531250001, 49.51142578125],
            [19.1494140625, 49.4],
            [18.968359375, 49.396240234375],
            [18.95722656250001, 49.448291015624996],
            [18.93818359375001, 49.498291015625],
            [18.83222656250001, 49.510791015624996],
            [18.82929687500001, 49.54013671875],
            [18.806933593750017, 49.613720703125],
            [18.594628906250023, 49.7578125],
            [18.56884765625, 49.817919921874996],
            [18.5771484375, 49.841113281249996],
            [18.562402343750023, 49.879345703125],
            [18.516210937500006, 49.902392578124996],
            [18.348437500000017, 49.929833984374994],
            [18.305273437500006, 49.9140625],
            [18.26630859375001, 49.9302734375],
            [18.20527343750001, 49.964746093749994],
            [18.09921875, 49.9927734375],
            [18.087695312500017, 50.007275390625],
            [18.04951171875001, 50.03193359375],
            [18.0283203125, 50.03525390625],
            [18.0146484375, 50.020263671875],
            [17.983789062500023, 49.999072265624996],
            [17.874804687500017, 49.972265625],
            [17.83125, 49.983300781249994],
            [17.79169921875001, 50.006591796875],
            [17.74658203125, 50.056787109374994],
            [17.681054687500023, 50.10078125],
            [17.627050781250006, 50.11640625],
            [17.596289062500006, 50.139501953125],
            [17.58935546875, 50.157470703125],
            [17.709277343750017, 50.1935546875],
            [17.735449218750006, 50.230761718749996],
            [17.720117187500023, 50.298632812499996],
            [17.702246093750006, 50.307177734374996],
            [17.654687500000023, 50.284228515624996],
            [17.554589843750023, 50.2640625],
            [17.462304687500023, 50.254785156249994],
            [17.41523437500001, 50.254785156249994],
            [17.151953125, 50.378320312499994],
            [16.980761718750017, 50.41611328125],
            [16.88007812500001, 50.427050781249996],
            [16.869140625, 50.414501953125],
            [16.91474609375001, 50.34521484375],
            [16.99335937500001, 50.259716796875],
            [16.989648437500023, 50.2369140625],
            [16.895312500000017, 50.201953125],
            [16.841796875, 50.18671875],
            [16.778613281250017, 50.15703125],
            [16.72529296875001, 50.116064453125],
            [16.679101562500023, 50.097460937499996],
            [16.63916015625, 50.1021484375],
            [16.5966796875, 50.121923828125],
            [16.487597656250017, 50.248388671875],
            [16.350488281250023, 50.34521484375],
            [16.33417968750001, 50.36689453125],
            [16.291308593750017, 50.371875],
            [16.230761718750017, 50.394091796874996],
            [16.210351562500023, 50.423730468749994],
            [16.24072265625, 50.4546875],
            [16.282519531250017, 50.4830078125],
            [16.35664062500001, 50.50048828125],
            [16.37910156250001, 50.516894531249996],
            [16.39228515625001, 50.541650390624994],
            [16.419726562500017, 50.573632812499994],
            [16.4125, 50.58515625],
            [16.359960937500006, 50.621386718749996],
            [16.2822265625, 50.655615234375],
            [16.06640625, 50.629931640624996],
            [16.007226562500023, 50.611621093749996],
            [15.973828125000011, 50.63544921875],
            [15.948535156250017, 50.670263671875],
            [15.893945312500023, 50.676904296874994],
            [15.819238281250023, 50.70869140625],
            [15.730566406250006, 50.739697265625],
            [15.643945312500023, 50.748876953125],
            [15.463964843750006, 50.793847656249994],
            [15.394628906250006, 50.796289062499994],
            [15.354394531250023, 50.811767578125],
            [15.312597656250006, 50.845751953124996],
            [15.277050781250011, 50.8830078125],
            [15.258593750000017, 50.958544921874996],
            [15.1259765625, 50.99287109375],
            [14.99375, 51.01435546875],
            [14.984472656250006, 51.00341796875],
            [14.989941406250011, 50.92724609375],
            [14.98291015625, 50.886572265625],
            [14.895800781250017, 50.861376953124996],
            [14.809375, 50.858984375],
            [14.814257812500017, 50.871630859374996],
            [14.91748046875, 51.008740234375],
            [14.9638671875, 51.095117187499994],
            [15.0166015625, 51.252734375],
            [14.953125, 51.3771484375],
            [14.935546875, 51.435351562499996],
            [14.905957031250011, 51.463330078125],
            [14.724707031250006, 51.523876953125],
            [14.7109375, 51.544921875],
            [14.738671875000023, 51.6271484375],
            [14.724902343750017, 51.66171875],
            [14.681347656250011, 51.698193359375],
            [14.623925781250023, 51.770800781249996],
            [14.601660156250006, 51.832373046875],
            [14.674902343750006, 51.904833984374996],
            [14.69296875, 51.9580078125],
            [14.724804687500011, 52.030859375],
            [14.748144531250006, 52.07080078125],
            [14.752539062500006, 52.081835937499996],
            [14.70458984375, 52.110205078125],
            [14.6923828125, 52.150048828125],
            [14.705371093750017, 52.207470703125],
            [14.679882812500011, 52.25],
            [14.615625, 52.277636718749996],
            [14.573925781250011, 52.31416015625],
            [14.554589843750023, 52.359667968749996],
            [14.569726562500023, 52.431103515625],
            [14.619433593750017, 52.528515625],
            [14.514062500000023, 52.64560546875],
            [14.253710937500017, 52.782519531249996],
            [14.128613281250011, 52.878222656249996],
            [14.138867187500011, 52.932861328125],
            [14.193652343750017, 52.98232421875],
            [14.293164062500011, 53.0267578125],
            [14.368554687500023, 53.10556640625],
            [14.410937500000017, 53.199023437499996],
            [14.412304687500011, 53.216748046875],
            [14.41455078125, 53.283496093749996],
            [14.298730468750023, 53.556445312499996],
            [14.279882812500006, 53.624755859375],
            [14.26611328125, 53.70712890625],
            [14.258886718750006, 53.729638671875],
            [14.487597656250017, 53.671875],
            [14.58349609375, 53.63935546875],
            [14.571582031250017, 53.67587890625],
            [14.552148437500023, 53.707324218749996],
            [14.56494140625, 53.753515625],
            [14.558398437500017, 53.823193359375],
            [14.350878906250017, 53.858740234375],
            [14.213671875000017, 53.870751953125],
            [14.1982421875, 53.91904296875],
            [14.21142578125, 53.950341796875],
            [14.249316406250017, 53.931933593749996],
            [14.384179687500023, 53.92470703125],
            [14.715722656250023, 54.018310546875],
            [15.288378906250017, 54.139892578125],
            [15.9, 54.253955078124996],
            [16.042773437500017, 54.266357421875],
            [16.186328125000017, 54.290380859375],
            [16.239355468750006, 54.333056640624996],
            [16.292285156250017, 54.361621093749996],
            [16.375585937500006, 54.436865234375],
            [16.55976562500001, 54.55380859375],
            [16.88544921875001, 54.59638671875],
            [17.00703125000001, 54.65185546875],
            [17.26191406250001, 54.729541015624996],
            [17.84296875000001, 54.816699218749996],
            [18.08564453125001, 54.83583984375],
            [18.32343750000001, 54.838183593749996],
            [18.53515625, 54.76943359375],
            [18.75927734375, 54.6845703125],
            [18.799609375000017, 54.633349609374996],
            [18.678320312500006, 54.665283203125],
            [18.5015625, 54.741503906249996],
            [18.43623046875001, 54.7447265625],
            [18.58710937500001, 54.512890625],
            [18.66962890625001, 54.430908203125],
            [18.83642578125, 54.369580078125],
            [18.97626953125001, 54.348925781249996],
            [19.407128906250023, 54.386083984375],
            [19.56015625, 54.434619140624996],
            [19.604394531250023, 54.4591796875],
            [19.64423828125001, 54.447070312499996],
            [19.92431640625, 54.433984375],
            [20.20820312500001, 54.420751953125],
            [20.66474609375001, 54.406640625],
            [21.140527343750023, 54.391796875],
            [21.634179687500023, 54.37646484375],
            [22.16845703125, 54.35986328125],
            [22.731835937500023, 54.35009765625],
            [22.766210937500006, 54.356787109375],
            [22.82373046875, 54.395800781249996],
            [22.893945312500023, 54.39052734375],
            [22.97675781250001, 54.366357421875],
            [23.015527343750023, 54.34833984375],
            [23.03193359375001, 54.327880859375],
            [23.04218750000001, 54.30419921875],
            [23.0875, 54.299462890625],
            [23.170312500000023, 54.2814453125],
            [23.282324218750006, 54.24033203125],
            [23.3701171875, 54.200488281249996],
            [23.45361328125, 54.14345703125],
            [23.481347656250023, 54.07900390625],
            [23.483007812500006, 54.00595703125],
            [23.477636718750006, 53.958935546875],
            [23.484667968750017, 53.939794921875],
            [23.598925781250017, 53.59921875],
            [23.78925781250001, 53.270947265625],
            [23.859179687500017, 53.112109375],
            [23.887109375000023, 53.0275390625],
            [23.909375, 52.9048828125],
            [23.916308593750017, 52.81875],
            [23.915429687500023, 52.770263671875],
            [23.901269531250023, 52.70361328125],
            [23.8447265625, 52.664208984375],
            [23.479589843750006, 52.5515625],
            [23.410937500000017, 52.5162109375],
            [23.303320312500006, 52.428369140625],
            [23.2041015625, 52.337890625],
            [23.18125, 52.306982421875],
            [23.175097656250017, 52.28662109375],
            [23.19697265625001, 52.25693359375],
            [23.3271484375, 52.208447265625],
            [23.458398437500023, 52.16953125],
            [23.50117187500001, 52.140380859375],
            [23.597949218750017, 52.103076171874996],
            [23.63330078125, 52.069580078125],
            [23.652441406250006, 52.040380859375],
            [23.65107421875001, 51.972998046875],
            [23.607421875, 51.87978515625],
            [23.62568359375001, 51.809326171875],
            [23.581347656250017, 51.76240234375],
            [23.544824218750023, 51.710253906249996],
            [23.539648437500006, 51.618896484375],
            [23.605273437500017, 51.517919921875],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 4,
        labelrank: 3,
        sovereignt: "Ireland",
        sov_a3: "IRL",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Ireland",
        adm0_a3: "IRL",
        geou_dif: 0,
        geounit: "Ireland",
        gu_a3: "IRL",
        su_dif: 0,
        subunit: "Ireland",
        su_a3: "IRL",
        brk_diff: 0,
        name: "Ireland",
        name_long: "Ireland",
        brk_a3: "IRL",
        brk_name: "Ireland",
        brk_group: null,
        abbrev: "Ire.",
        postal: "IRL",
        formal_en: "Ireland",
        formal_fr: null,
        name_ciawf: "Ireland",
        note_adm0: null,
        note_brk: null,
        name_sort: "Ireland",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 3,
        mapcolor9: 2,
        mapcolor13: 2,
        pop_est: 4941444,
        pop_rank: 12,
        pop_year: 2019,
        gdp_md: 388698,
        gdp_year: 2019,
        economy: "2. Developed region: nonG7",
        income_grp: "1. High income: OECD",
        fips_10: "EI",
        iso_a2: "IE",
        iso_a2_eh: "IE",
        iso_a3: "IRL",
        iso_a3_eh: "IRL",
        iso_n3: "372",
        iso_n3_eh: "372",
        un_a3: "372",
        wb_a2: "IE",
        wb_a3: "IRL",
        woe_id: 23424803,
        woe_id_eh: 23424803,
        woe_note: "Exact WOE match as country",
        adm0_iso: "IRL",
        adm0_diff: null,
        adm0_tlc: "IRL",
        adm0_a3_us: "IRL",
        adm0_a3_fr: "IRL",
        adm0_a3_ru: "IRL",
        adm0_a3_es: "IRL",
        adm0_a3_cn: "IRL",
        adm0_a3_tw: "IRL",
        adm0_a3_in: "IRL",
        adm0_a3_np: "IRL",
        adm0_a3_pk: "IRL",
        adm0_a3_de: "IRL",
        adm0_a3_gb: "IRL",
        adm0_a3_br: "IRL",
        adm0_a3_il: "IRL",
        adm0_a3_ps: "IRL",
        adm0_a3_sa: "IRL",
        adm0_a3_eg: "IRL",
        adm0_a3_ma: "IRL",
        adm0_a3_pt: "IRL",
        adm0_a3_ar: "IRL",
        adm0_a3_jp: "IRL",
        adm0_a3_ko: "IRL",
        adm0_a3_vn: "IRL",
        adm0_a3_tr: "IRL",
        adm0_a3_id: "IRL",
        adm0_a3_pl: "IRL",
        adm0_a3_gr: "IRL",
        adm0_a3_it: "IRL",
        adm0_a3_nl: "IRL",
        adm0_a3_se: "IRL",
        adm0_a3_bd: "IRL",
        adm0_a3_ua: "IRL",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Northern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: -7.798588,
        label_y: 53.078726,
        ne_id: 1159320877,
        wikidataid: "Q27",
        name_ar: "جمهورية أيرلندا",
        name_bn: "প্রজাতন্ত্রী আয়ারল্যান্ড",
        name_de: "Irland",
        name_en: "Ireland",
        name_es: "Irlanda",
        name_fa: "ایرلند",
        name_fr: "Irlande",
        name_el: "Δημοκρατία της Ιρλανδίας",
        name_he: "אירלנד",
        name_hi: "आयरलैण्ड",
        name_hu: "Írország",
        name_id: "Republik Irlandia",
        name_it: "Irlanda",
        name_ja: "アイルランド",
        name_ko: "아일랜드",
        name_nl: "Ierland",
        name_pl: "Irlandia",
        name_pt: "República da Irlanda",
        name_ru: "Ирландия",
        name_sv: "Irland",
        name_tr: "İrlanda",
        name_uk: "Ірландія",
        name_ur: "جمہوریہ آئرلینڈ",
        name_vi: "Cộng hòa Ireland",
        name_zh: "爱尔兰",
        name_zht: "愛爾蘭",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "IRL.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-9.948193359374983, 53.913134765624996],
              [-9.952441406249989, 53.884570312499996],
              [-10.026513671874994, 53.920556640625],
              [-10.0625, 53.959716796875],
              [-10.265722656249977, 53.977685546875],
              [-10.181054687499994, 54.016845703125],
              [-10.139746093749977, 54.005224609375],
              [-9.996386718749989, 54.00361328125],
              [-9.956152343749977, 53.98720703125],
              [-9.948193359374983, 53.913134765624996],
            ],
          ],
          [
            [
              [-7.218652343749994, 55.0919921875],
              [-7.376904296874983, 55.027685546875],
              [-7.401416015624989, 55.0033203125],
              [-7.445996093749983, 54.905126953125],
              [-7.451269531249977, 54.877099609375],
              [-7.502197265625, 54.825439453125],
              [-7.550390624999977, 54.76796875],
              [-7.6064453125, 54.745703125],
              [-7.689990234374989, 54.72802734375],
              [-7.7375, 54.71044921875],
              [-7.797265625, 54.719287109374996],
              [-7.872949218749994, 54.71787109375],
              [-7.910595703124983, 54.69833984375],
              [-7.908740234374989, 54.683349609375],
              [-7.886132812499994, 54.666064453124996],
              [-7.81982421875, 54.639697265624996],
              [-7.746289062499983, 54.6158203125],
              [-7.75439453125, 54.594921875],
              [-7.793798828124977, 54.571240234375],
              [-8.044335937499994, 54.512451171875],
              [-8.118945312499989, 54.476953125],
              [-8.144824218749989, 54.453515625],
              [-8.118261718749977, 54.4142578125],
              [-7.91845703125, 54.29658203125],
              [-7.884472656249983, 54.2837890625],
              [-7.854931640624983, 54.215283203125],
              [-7.678759765624989, 54.186669921875],
              [-7.606542968749977, 54.143847656249996],
              [-7.54443359375, 54.13359375],
              [-7.409423828125, 54.1373046875],
              [-7.355175781249983, 54.121240234375],
              [-7.324511718749989, 54.133447265625],
              [-7.306738281249977, 54.156005859375],
              [-7.193066406249983, 54.214111328125],
              [-7.155468749999983, 54.239501953125],
              [-7.178076171874977, 54.27490234375],
              [-7.202587890624983, 54.301806640624996],
              [-7.133496093749983, 54.35537109375],
              [-7.049707031249994, 54.408251953124996],
              [-7.007714843749994, 54.406689453125],
              [-6.936132812499977, 54.374316406249996],
              [-6.877246093749989, 54.3291015625],
              [-6.869238281249977, 54.29404296875],
              [-6.858349609374983, 54.26865234375],
              [-6.802587890624977, 54.21435546875],
              [-6.7666015625, 54.195605468749996],
              [-6.669531249999977, 54.184716796875],
              [-6.646875, 54.163427734375],
              [-6.664208984374994, 54.084765625],
              [-6.649804687499994, 54.058642578124996],
              [-6.548144531249989, 54.057275390625],
              [-6.440283203124977, 54.063623046875],
              [-6.402587890625, 54.06064453125],
              [-6.363671875, 54.077099609375],
              [-6.303662109374983, 54.094873046875],
              [-6.218017578125, 54.088720703125],
              [-6.175732421874983, 54.053515625],
              [-6.156933593749983, 54.017236328125],
              [-6.230664062499983, 54.00361328125],
              [-6.3076171875, 54.011035156249996],
              [-6.345166015624983, 53.98720703125],
              [-6.347607421874983, 53.94130859375],
              [-6.321582031249989, 53.882177734375],
              [-6.270117187499977, 53.840234375],
              [-6.22900390625, 53.745703125],
              [-6.194873046874989, 53.640869140625],
              [-6.141845703125, 53.5775390625],
              [-6.130957031249977, 53.49892578125],
              [-6.138769531249977, 53.460302734375],
              [-6.129101562499983, 53.390869140625],
              [-6.151660156249989, 53.36640625],
              [-6.134716796874983, 53.301220703125],
              [-6.072265625, 53.166308593749996],
              [-6.045019531249977, 53.091162109375],
              [-6.027392578124989, 52.927099609375],
              [-6.071484374999983, 52.865625],
              [-6.130664062499989, 52.807275390625],
              [-6.169335937499994, 52.738134765625],
              [-6.19921875, 52.6634765625],
              [-6.217236328124983, 52.543115234375],
              [-6.345410156249983, 52.402001953125],
              [-6.399951171874989, 52.366943359375],
              [-6.463183593749989, 52.345361328125],
              [-6.325, 52.2466796875],
              [-6.437939453124983, 52.202685546874996],
              [-6.561083984374989, 52.188818359375],
              [-6.697314453124989, 52.213525390625],
              [-6.7822265625, 52.210498046874996],
              [-6.859716796874977, 52.178564453125],
              [-6.890234374999977, 52.159228515624996],
              [-6.914648437499977, 52.1685546875],
              [-6.965771484374983, 52.24951171875],
              [-7.003271484374977, 52.16591796875],
              [-7.081787109375, 52.139306640625],
              [-7.216210937499994, 52.144970703125],
              [-7.440869140624983, 52.122705078125],
              [-7.527294921874983, 52.098876953125],
              [-7.563183593749983, 52.06162109375],
              [-7.58984375, 52.0185546875],
              [-7.624902343749994, 51.993115234375],
              [-7.66455078125, 51.979736328125],
              [-7.837988281249977, 51.947998046875],
              [-7.872167968749977, 51.935302734375],
              [-7.952490234374977, 51.865771484374996],
              [-8.057812499999983, 51.8255859375],
              [-8.14501953125, 51.813525390624996],
              [-8.222460937499989, 51.85400390625],
              [-8.254296875, 51.8783203125],
              [-8.290234374999983, 51.890673828124996],
              [-8.4091796875, 51.88876953125],
              [-8.371630859374989, 51.876269531249996],
              [-8.347363281249983, 51.847705078124996],
              [-8.335595703124994, 51.79296875],
              [-8.34912109375, 51.739306640624996],
              [-8.407812499999977, 51.712060546875],
              [-8.477832031249989, 51.70703125],
              [-8.58828125, 51.6513671875],
              [-8.734472656249977, 51.636181640625],
              [-8.813427734374983, 51.584912109375],
              [-9.296484374999977, 51.4982421875],
              [-9.323876953124994, 51.497216796874994],
              [-9.390576171874983, 51.519287109375],
              [-9.462890625, 51.529052734375],
              [-9.534863281249983, 51.52216796875],
              [-9.7373046875, 51.47373046875],
              [-9.835351562499994, 51.483349609375],
              [-9.710351562499994, 51.6037109375],
              [-9.542382812499994, 51.664453125],
              [-9.52490234375, 51.681103515625],
              [-9.579833984375, 51.689257812499996],
              [-9.899023437499977, 51.6470703125],
              [-10.009912109374994, 51.611132812499996],
              [-10.120751953124994, 51.60068359375],
              [-10.069433593749977, 51.655566406249996],
              [-9.926416015624994, 51.730712890625],
              [-9.849707031249977, 51.76611328125],
              [-9.802880859374994, 51.780126953125],
              [-9.74951171875, 51.824267578124996],
              [-9.598828124999983, 51.8744140625],
              [-10.084228515625, 51.77099609375],
              [-10.211718749999989, 51.78359375],
              [-10.241748046874989, 51.812451171875],
              [-10.341064453125, 51.79892578125],
              [-10.378710937499989, 51.86875],
              [-10.231591796874994, 51.97451171875],
              [-10.145849609374977, 52.02001953125],
              [-10.044042968749977, 52.044580078125],
              [-9.946044921875, 52.079833984375],
              [-9.90966796875, 52.12294921875],
              [-9.955810546875, 52.136669921875],
              [-10.24951171875, 52.125732421875],
              [-10.390234374999977, 52.134912109375],
              [-10.382617187499989, 52.169091796875],
              [-10.356689453125, 52.20693359375],
              [-10.2109375, 52.2716796875],
              [-10.132080078125, 52.282080078125],
              [-10.061767578125, 52.275927734374996],
              [-9.993115234374983, 52.259326171874996],
              [-9.937304687499989, 52.237646484375],
              [-9.772119140624994, 52.25009765625],
              [-9.841064453125, 52.291455078125],
              [-9.853222656249983, 52.37548828125],
              [-9.906054687499989, 52.4037109375],
              [-9.838476562499977, 52.442675781249996],
              [-9.761132812499994, 52.466357421874996],
              [-9.632226562499994, 52.546923828124996],
              [-9.586328125, 52.5591796875],
              [-9.33125, 52.578759765625],
              [-9.05615234375, 52.621142578124996],
              [-8.783447265625, 52.679638671875],
              [-8.923291015624983, 52.7123046875],
              [-8.990283203124989, 52.755419921874996],
              [-9.097900390625, 52.668261718749996],
              [-9.175390624999977, 52.634912109375],
              [-9.394238281249983, 52.61708984375],
              [-9.463476562499977, 52.626904296875],
              [-9.56103515625, 52.653955078125],
              [-9.591357421874989, 52.64365234375],
              [-9.61953125, 52.62275390625],
              [-9.764355468749983, 52.57998046875],
              [-9.916601562499977, 52.5697265625],
              [-9.739599609374977, 52.648193359375],
              [-9.514990234374977, 52.78115234375],
              [-9.464892578124989, 52.823193359375],
              [-9.393652343749977, 52.896240234375],
              [-9.415722656249983, 52.928759765624996],
              [-9.461962890624989, 52.947265625],
              [-9.29921875, 53.09755859375],
              [-9.241894531249983, 53.124853515625],
              [-9.137597656249994, 53.129248046875],
              [-9.061132812499977, 53.153076171875],
              [-9.027441406249977, 53.153173828125],
              [-8.997167968749977, 53.162060546875],
              [-8.930126953124983, 53.207080078124996],
              [-9.033544921874977, 53.2357421875],
              [-9.140332031249983, 53.25048828125],
              [-9.470751953124989, 53.23486328125],
              [-9.514208984374989, 53.238232421875],
              [-9.55517578125, 53.25205078125],
              [-9.581738281249983, 53.27197265625],
              [-9.601757812499983, 53.323046875],
              [-9.6259765625, 53.33447265625],
              [-9.700585937499994, 53.33447265625],
              [-9.774072265624994, 53.31884765625],
              [-9.825390624999983, 53.320361328124996],
              [-9.875781249999989, 53.342724609375],
              [-9.79541015625, 53.394970703125],
              [-9.899023437499977, 53.407275390624996],
              [-10.00390625, 53.397021484374996],
              [-10.091259765624983, 53.412841796875],
              [-10.093994140625, 53.445605468749996],
              [-10.054394531249983, 53.478320312499996],
              [-10.10625, 53.509326171874996],
              [-10.116992187499989, 53.54853515625],
              [-10.061718749999983, 53.567822265625],
              [-10.001367187499994, 53.56142578125],
              [-9.878271484374977, 53.5904296875],
              [-9.720654296874983, 53.6044921875],
              [-9.855859375, 53.633105468749996],
              [-9.909716796874989, 53.6576171875],
              [-9.912304687499983, 53.695117187499996],
              [-9.901611328125, 53.727197265625],
              [-9.745068359374983, 53.781494140625],
              [-9.578222656249977, 53.805419921875],
              [-9.590527343749983, 53.841162109375],
              [-9.578857421875, 53.879833984375],
              [-9.747509765624983, 53.891015625],
              [-9.9140625, 53.863720703125],
              [-9.896240234375, 53.93759765625],
              [-9.856347656249994, 54.004296875],
              [-9.848486328124977, 54.048291015625],
              [-9.8564453125, 54.095361328125],
              [-9.934472656249994, 54.075244140624996],
              [-9.943603515625, 54.1416015625],
              [-9.977099609374989, 54.187109375],
              [-10.092675781249994, 54.15576171875],
              [-10.089697265624977, 54.2158203125],
              [-10.056396484375, 54.2578125],
              [-9.995947265624977, 54.276025390625],
              [-9.9359375, 54.268115234374996],
              [-9.824560546874977, 54.268896484375],
              [-9.717138671874977, 54.300439453125],
              [-9.562304687499989, 54.308544921875],
              [-9.315527343749977, 54.298632812499996],
              [-9.145898437499994, 54.209619140625],
              [-9.102099609374989, 54.225537109375],
              [-9.034277343749977, 54.281787109374996],
              [-9.00244140625, 54.28798828125],
              [-8.746777343749983, 54.263476562499996],
              [-8.588037109374994, 54.231103515625],
              [-8.545556640624994, 54.2412109375],
              [-8.568457031249977, 54.30361328125],
              [-8.623144531249977, 54.346875],
              [-8.554443359375, 54.403564453125],
              [-8.470996093749989, 54.441943359374996],
              [-8.415234374999983, 54.461083984375],
              [-8.286523437499994, 54.48486328125],
              [-8.230371093749994, 54.507275390625],
              [-8.192968749999977, 54.580126953124996],
              [-8.133447265624994, 54.6408203125],
              [-8.45654296875, 54.60927734375],
              [-8.763916015625, 54.681201171874996],
              [-8.715185546874977, 54.73203125],
              [-8.650292968749994, 54.760888671875],
              [-8.538281249999983, 54.782958984375],
              [-8.527685546874977, 54.80947265625],
              [-8.470996093749989, 54.83154296875],
              [-8.377294921874977, 54.889453125],
              [-8.411718749999977, 54.965087890625],
              [-8.393261718749983, 55.02041015625],
              [-8.325781249999977, 55.056445312499996],
              [-8.3046875, 55.108203125],
              [-8.274609374999983, 55.146289062499996],
              [-8.1376953125, 55.159912109375],
              [-8.006103515625, 55.1953125],
              [-7.958593749999977, 55.19189453125],
              [-7.803173828124983, 55.200048828125],
              [-7.750537109374989, 55.185791015625],
              [-7.762548828124977, 55.248339843749996],
              [-7.667089843749977, 55.256494140625],
              [-7.629785156249994, 55.243994140625],
              [-7.613378906249977, 55.199658203125],
              [-7.570019531249983, 55.17138671875],
              [-7.556640625, 55.122216796875],
              [-7.585693359375, 55.084228515625],
              [-7.63427734375, 55.054980468749996],
              [-7.58984375, 55.025048828125],
              [-7.658740234374989, 54.970947265625],
              [-7.584375, 54.993994140625],
              [-7.478417968749994, 55.04697265625],
              [-7.483935546874989, 55.090283203125],
              [-7.501953125, 55.1447265625],
              [-7.531445312499983, 55.19384765625],
              [-7.517871093749989, 55.24794921875],
              [-7.458300781249989, 55.281787109374996],
              [-7.3017578125, 55.298779296875],
              [-7.365966796875, 55.360205078125],
              [-7.308789062499983, 55.3658203125],
              [-7.246679687499977, 55.35302734375],
              [-7.155322265624989, 55.30517578125],
              [-7.060253906249983, 55.267626953124996],
              [-6.961669921875, 55.237890625],
              [-7.056396484375, 55.1783203125],
              [-7.1728515625, 55.137011718749996],
              [-7.218652343749994, 55.0919921875],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 2,
        sovereignt: "United Kingdom",
        sov_a3: "GB1",
        adm0_dif: 1,
        level: 2,
        type: "Country",
        tlc: "1",
        admin: "United Kingdom",
        adm0_a3: "GBR",
        geou_dif: 0,
        geounit: "United Kingdom",
        gu_a3: "GBR",
        su_dif: 0,
        subunit: "United Kingdom",
        su_a3: "GBR",
        brk_diff: 0,
        name: "United Kingdom",
        name_long: "United Kingdom",
        brk_a3: "GBR",
        brk_name: "United Kingdom",
        brk_group: null,
        abbrev: "U.K.",
        postal: "GB",
        formal_en: "United Kingdom of Great Britain and Northern Ireland",
        formal_fr: null,
        name_ciawf: "United Kingdom",
        note_adm0: null,
        note_brk: null,
        name_sort: "United Kingdom",
        name_alt: null,
        mapcolor7: 6,
        mapcolor8: 6,
        mapcolor9: 6,
        mapcolor13: 3,
        pop_est: 66834405,
        pop_rank: 16,
        pop_year: 2019,
        gdp_md: 2829108,
        gdp_year: 2019,
        economy: "1. Developed region: G7",
        income_grp: "1. High income: OECD",
        fips_10: "UK",
        iso_a2: "GB",
        iso_a2_eh: "GB",
        iso_a3: "GBR",
        iso_a3_eh: "GBR",
        iso_n3: "826",
        iso_n3_eh: "826",
        un_a3: "826",
        wb_a2: "GB",
        wb_a3: "GBR",
        woe_id: -90,
        woe_id_eh: 23424975,
        woe_note:
          "Eh ID includes Channel Islands and Isle of Man. UK constituent countries of England (24554868), Wales (12578049), Scotland (12578048), and Northern Ireland (20070563).",
        adm0_iso: "GBR",
        adm0_diff: null,
        adm0_tlc: "GBR",
        adm0_a3_us: "GBR",
        adm0_a3_fr: "GBR",
        adm0_a3_ru: "GBR",
        adm0_a3_es: "GBR",
        adm0_a3_cn: "GBR",
        adm0_a3_tw: "GBR",
        adm0_a3_in: "GBR",
        adm0_a3_np: "GBR",
        adm0_a3_pk: "GBR",
        adm0_a3_de: "GBR",
        adm0_a3_gb: "GBR",
        adm0_a3_br: "GBR",
        adm0_a3_il: "GBR",
        adm0_a3_ps: "GBR",
        adm0_a3_sa: "GBR",
        adm0_a3_eg: "GBR",
        adm0_a3_ma: "GBR",
        adm0_a3_pt: "GBR",
        adm0_a3_ar: "GBR",
        adm0_a3_jp: "GBR",
        adm0_a3_ko: "GBR",
        adm0_a3_vn: "GBR",
        adm0_a3_tr: "GBR",
        adm0_a3_id: "GBR",
        adm0_a3_pl: "GBR",
        adm0_a3_gr: "GBR",
        adm0_a3_it: "GBR",
        adm0_a3_nl: "GBR",
        adm0_a3_se: "GBR",
        adm0_a3_bd: "GBR",
        adm0_a3_ua: "GBR",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Northern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 14,
        long_len: 14,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 1.7,
        max_label: 6.7,
        label_x: -2.116346,
        label_y: 54.402739,
        ne_id: 1159320713,
        wikidataid: "Q145",
        name_ar: "المملكة المتحدة",
        name_bn: "যুক্তরাজ্য",
        name_de: "Vereinigtes Königreich",
        name_en: "United Kingdom",
        name_es: "Reino Unido",
        name_fa: "بریتانیا",
        name_fr: "Royaume-Uni",
        name_el: "Ηνωμένο Βασίλειο",
        name_he: "הממלכה המאוחדת",
        name_hi: "यूनाइटेड किंगडम",
        name_hu: "Egyesült Királyság",
        name_id: "Britania Raya",
        name_it: "Regno Unito",
        name_ja: "イギリス",
        name_ko: "영국",
        name_nl: "Verenigd Koninkrijk",
        name_pl: "Wielka Brytania",
        name_pt: "Reino Unido",
        name_ru: "Великобритания",
        name_sv: "Storbritannien",
        name_tr: "Birleşik Krallık",
        name_uk: "Велика Британія",
        name_ur: "مملکت متحدہ",
        name_vi: "Vương quốc Liên hiệp Anh và Bắc Ireland",
        name_zh: "英国",
        name_zht: "英國",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "GBR.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-2.667675781249983, 51.622998046875],
              [-2.742138671874983, 51.581103515624996],
              [-2.978515625, 51.538867187499996],
              [-3.080371093749989, 51.49580078125],
              [-3.2587890625, 51.398486328124996],
              [-3.293115234374994, 51.390429687499996],
              [-3.562353515624977, 51.413818359375],
              [-3.7626953125, 51.539941406249994],
              [-3.890771484374994, 51.591650390625],
              [-3.943652343749989, 51.597509765625],
              [-3.998339843749989, 51.58212890625],
              [-4.115283203124989, 51.56640625],
              [-4.234570312499983, 51.569091796875],
              [-4.173681640624977, 51.62734375],
              [-4.091015624999983, 51.659912109375],
              [-4.276171874999989, 51.68251953125],
              [-4.32763671875, 51.700244140624996],
              [-4.386279296874989, 51.741064453125],
              [-4.531494140625, 51.748046875],
              [-4.600781249999983, 51.737646484375],
              [-4.717626953124977, 51.68369140625],
              [-4.902294921874983, 51.626269531249996],
              [-5.124755859375, 51.705859375],
              [-5.168359375, 51.74072265625],
              [-5.167236328125, 51.808056640625],
              [-5.200585937499994, 51.861376953124996],
              [-5.262304687499977, 51.880175781249996],
              [-5.183349609375, 51.949658203125],
              [-5.088085937499983, 51.995898437499996],
              [-4.878515624999977, 52.041845703125],
              [-4.561132812499977, 52.15087890625],
              [-4.383154296874977, 52.197314453124996],
              [-4.217724609374983, 52.27744140625],
              [-4.149365234374983, 52.32626953125],
              [-4.099755859374994, 52.393115234374996],
              [-4.050537109375, 52.475146484374996],
              [-3.980322265624977, 52.541748046875],
              [-4.048437499999977, 52.5576171875],
              [-4.078906249999989, 52.607861328125],
              [-4.070703125, 52.658837890625],
              [-4.039257812499983, 52.704052734375],
              [-4.067431640624989, 52.7607421875],
              [-4.117529296874977, 52.82001953125],
              [-4.11474609375, 52.866162109375],
              [-4.101464843749994, 52.915478515625],
              [-4.229150390624994, 52.912841796875],
              [-4.3564453125, 52.897412109375],
              [-4.471826171874994, 52.862451171875],
              [-4.583691406249983, 52.81494140625],
              [-4.683056640624983, 52.80615234375],
              [-4.681445312499989, 52.844140625],
              [-4.638330078124994, 52.89111328125],
              [-4.525683593749989, 52.958203125],
              [-4.405078124999989, 53.013818359375],
              [-4.362207031249994, 53.0560546875],
              [-4.328417968749989, 53.105126953125],
              [-4.2685546875, 53.14453125],
              [-4.111035156249983, 53.2189453125],
              [-3.809277343749983, 53.302685546875],
              [-3.764208984374989, 53.3076171875],
              [-3.645898437499994, 53.297900390624996],
              [-3.529589843749989, 53.310546875],
              [-3.427734375, 53.340673828125],
              [-3.326171875, 53.34716796875],
              [-3.097558593749994, 53.260302734374996],
              [-3.165576171874989, 53.394677734375],
              [-3.064746093749989, 53.42685546875],
              [-2.918554687499977, 53.30537109375],
              [-2.864160156249994, 53.292578125],
              [-2.74951171875, 53.310205078125],
              [-2.79375, 53.330712890625],
              [-2.845410156249983, 53.33193359375],
              [-2.9130859375, 53.350244140625],
              [-2.969970703125, 53.389208984374996],
              [-3.064599609374994, 53.512841796875],
              [-3.059472656249994, 53.586230468749996],
              [-2.995703124999977, 53.662548828125],
              [-2.925097656249989, 53.732763671875],
              [-2.984326171874983, 53.746728515625],
              [-3.031787109374989, 53.773583984375],
              [-3.045361328124983, 53.84384765625],
              [-3.026757812499994, 53.905908203125],
              [-2.899853515624983, 53.960693359375],
              [-2.862402343749977, 54.04384765625],
              [-2.846484374999989, 54.135302734374996],
              [-2.867578125, 54.17724609375],
              [-2.993505859374977, 54.1705078125],
              [-3.054736328124989, 54.15341796875],
              [-3.109667968749989, 54.126318359375],
              [-3.165966796874983, 54.1279296875],
              [-3.321533203125, 54.2291015625],
              [-3.410253906249977, 54.305615234375],
              [-3.569384765624989, 54.467578125],
              [-3.592041015625, 54.56435546875],
              [-3.464599609375, 54.773095703125],
              [-3.267919921874977, 54.906591796875],
              [-3.036230468749977, 54.953076171875],
              [-3.0810546875, 54.961962890624996],
              [-3.43408203125, 54.96376953125],
              [-3.550439453124994, 54.947412109375],
              [-3.658300781249977, 54.892871093749996],
              [-3.71923828125, 54.876123046875],
              [-3.783251953124989, 54.869921875],
              [-3.841601562499989, 54.8427734375],
              [-3.898583984374994, 54.805078125],
              [-3.957910156249994, 54.78095703125],
              [-4.075781249999977, 54.78720703125],
              [-4.132958984374994, 54.779248046875],
              [-4.174023437499983, 54.801074218749996],
              [-4.208398437499994, 54.837158203125],
              [-4.25341796875, 54.84677734375],
              [-4.303662109374983, 54.835693359375],
              [-4.409912109375, 54.787060546875],
              [-4.517480468749994, 54.758349609374996],
              [-4.647558593749977, 54.789013671875],
              [-4.818066406249983, 54.846142578125],
              [-4.851708984374994, 54.82529296875],
              [-4.889501953124977, 54.772265625],
              [-4.911230468749977, 54.689453125],
              [-5.032324218749977, 54.761376953125],
              [-5.135498046875, 54.85751953125],
              [-5.170117187499983, 54.917919921875],
              [-5.172705078124977, 54.985888671874996],
              [-5.11669921875, 55.012255859374996],
              [-5.055859374999983, 54.988134765625],
              [-4.965185546874977, 55.149462890624996],
              [-4.784814453124994, 55.359423828124996],
              [-4.721142578124983, 55.42099609375],
              [-4.6767578125, 55.501318359375],
              [-4.684375, 55.55390625],
              [-4.724169921874989, 55.598291015625],
              [-4.891845703125, 55.69912109375],
              [-4.8896484375, 55.781201171875],
              [-4.871679687499977, 55.87392578125],
              [-4.826074218749994, 55.929541015625],
              [-4.806835937499983, 55.94013671875],
              [-4.584082031249977, 55.938671875],
              [-4.670947265624989, 55.9673828125],
              [-4.844091796874977, 56.051171875],
              [-4.841015624999983, 56.080859375],
              [-4.80029296875, 56.158349609375],
              [-4.819140624999989, 56.15048828125],
              [-4.85625, 56.114697265625],
              [-4.927099609374977, 56.028076171875],
              [-4.970361328124994, 56.007861328124996],
              [-5.092822265624989, 55.9873046875],
              [-5.114990234375, 55.944628906249996],
              [-5.134667968749994, 55.93349609375],
              [-5.195849609374989, 55.928662109375],
              [-5.214599609375, 55.8888671875],
              [-5.228222656249983, 55.886328125],
              [-5.24560546875, 55.929248046874996],
              [-5.247314453125, 56.000390625],
              [-5.222949218749989, 56.0658203125],
              [-5.176416015624994, 56.116992187499996],
              [-4.996972656249994, 56.233349609375],
              [-5.084326171874977, 56.1974609375],
              [-5.282324218749977, 56.08994140625],
              [-5.383447265624994, 56.01923828125],
              [-5.410449218749989, 55.995361328125],
              [-5.418896484374983, 55.975244140625],
              [-5.418310546874977, 55.95205078125],
              [-5.372900390624977, 55.827685546874996],
              [-5.385839843749977, 55.7701171875],
              [-5.556445312499989, 55.389599609375],
              [-5.588769531249994, 55.351416015625],
              [-5.618457031249989, 55.3314453125],
              [-5.646533203124989, 55.32685546875],
              [-5.730664062499983, 55.334130859375],
              [-5.768212890624994, 55.362646484375],
              [-5.767871093749989, 55.394970703125],
              [-5.752099609374994, 55.44345703125],
              [-5.681347656249983, 55.623974609375],
              [-5.650634765625, 55.674121093749996],
              [-5.605029296874989, 55.720751953124996],
              [-5.504492187499977, 55.802392578125],
              [-5.506933593749977, 55.80771484375],
              [-5.573876953124994, 55.79169921875],
              [-5.602392578124977, 55.79697265625],
              [-5.622851562499989, 55.813134765625],
              [-5.609570312499983, 56.0552734375],
              [-5.555273437499977, 56.1349609375],
              [-5.534960937499989, 56.250830078125],
              [-5.487890624999977, 56.350048828125],
              [-5.433398437499989, 56.422314453125],
              [-5.391943359374977, 56.514794921875],
              [-5.329443359374977, 56.555908203125],
              [-5.312695312499983, 56.618798828125],
              [-5.242578125, 56.686865234375],
              [-5.188378906249994, 56.758056640625],
              [-5.217578124999989, 56.751025390624996],
              [-5.564208984375, 56.565722656249996],
              [-5.652441406249977, 56.531982421875],
              [-5.772802734374977, 56.541015625],
              [-5.864843749999977, 56.561865234375],
              [-5.936767578125, 56.605712890625],
              [-5.968896484374994, 56.689892578125],
              [-6.057714843749977, 56.692138671875],
              [-6.133691406249994, 56.706689453125],
              [-6.132763671874983, 56.718017578125],
              [-6.034716796874989, 56.763916015625],
              [-5.877636718749983, 56.779638671875],
              [-5.730615234374994, 56.853076171874996],
              [-5.861425781249977, 56.902685546875],
              [-5.850390624999989, 56.918408203125],
              [-5.736279296874983, 56.96064453125],
              [-5.59130859375, 57.10234375],
              [-5.561914062499994, 57.232714843749996],
              [-5.63125, 57.2939453125],
              [-5.656347656249977, 57.33408203125],
              [-5.794921875, 57.37880859375],
              [-5.818066406249983, 57.436083984374996],
              [-5.801953124999983, 57.468017578125],
              [-5.756738281249994, 57.49921875],
              [-5.688623046874994, 57.52353515625],
              [-5.581787109375, 57.54677734375],
              [-5.678759765624989, 57.5716796875],
              [-5.714941406249977, 57.60107421875],
              [-5.742382812499983, 57.64365234375],
              [-5.744921874999989, 57.668310546875],
              [-5.694726562499994, 57.77822265625],
              [-5.665478515624983, 57.823535156249996],
              [-5.608349609374983, 57.88134765625],
              [-5.349023437499994, 57.878076171875],
              [-5.319189453124977, 57.903613281249996],
              [-5.289794921875, 57.904589843749996],
              [-5.1572265625, 57.88134765625],
              [-5.176904296874994, 57.906396484375],
              [-5.39375, 58.043603515624994],
              [-5.413183593749977, 58.069726562499994],
              [-5.351367187499989, 58.143701171874994],
              [-5.346875, 58.176660156249994],
              [-5.35595703125, 58.2119140625],
              [-5.33828125, 58.238720703125],
              [-5.26953125, 58.251416015625],
              [-5.059960937499994, 58.250146484374994],
              [-5.00830078125, 58.262646484375],
              [-5.031835937499977, 58.298291015625],
              [-5.080615234374989, 58.345166015625],
              [-5.090136718749989, 58.384521484375],
              [-5.078710937499977, 58.419287109375],
              [-5.076025390624977, 58.4892578125],
              [-5.066503906249977, 58.52021484375],
              [-5.016748046874994, 58.566552734374994],
              [-4.975634765624989, 58.580322265625],
              [-4.924658203124977, 58.58837890625],
              [-4.809619140624989, 58.572900390624994],
              [-4.765771484374994, 58.55419921875],
              [-4.715429687499977, 58.510009765625],
              [-4.67822265625, 58.513574218749994],
              [-4.534960937499989, 58.561572265625],
              [-4.491894531249983, 58.56845703125],
              [-4.433251953124994, 58.512841796874994],
              [-4.188623046874994, 58.5572265625],
              [-3.859521484374994, 58.577099609375],
              [-3.661816406249983, 58.606298828125],
              [-3.453564453124983, 58.61689453125],
              [-3.259130859374977, 58.65],
              [-3.053076171874977, 58.634814453125],
              [-3.046191406249989, 58.61552734375],
              [-3.056982421874977, 58.588769531249994],
              [-3.109667968749989, 58.515478515625],
              [-3.101123046874989, 58.43369140625],
              [-3.112890624999977, 58.40888671875],
              [-3.136767578124989, 58.378320312499994],
              [-3.212353515624983, 58.321240234375],
              [-3.410986328124977, 58.239648437499994],
              [-3.775, 58.052099609375],
              [-3.990039062499989, 57.959033203124996],
              [-4.019628906249977, 57.9142578125],
              [-4.035595703124983, 57.852001953125],
              [-3.906835937499977, 57.839648437499996],
              [-3.857128906249983, 57.8185546875],
              [-3.887939453125, 57.786914062499996],
              [-4.078417968749989, 57.677050781249996],
              [-4.134521484375, 57.577734375],
              [-3.988476562499983, 57.58125],
              [-3.8681640625, 57.600341796875],
              [-3.628222656249989, 57.662255859375],
              [-3.402783203124983, 57.708251953125],
              [-3.294531249999977, 57.71015625],
              [-3.083935546874983, 57.673486328125],
              [-3.036035156249994, 57.672314453125],
              [-2.946679687499994, 57.689257812499996],
              [-2.856298828124977, 57.69228515625],
              [-2.244140625, 57.680859375],
              [-2.074072265624977, 57.702392578125],
              [-1.961523437499977, 57.67666015625],
              [-1.867382812499983, 57.612353515624996],
              [-1.777929687499977, 57.49375],
              [-1.780664062499994, 57.4740234375],
              [-1.834716796875, 57.419970703124996],
              [-1.934472656249994, 57.352197265625],
              [-2.020312499999989, 57.25888671875],
              [-2.045507812499977, 57.208544921874996],
              [-2.062353515624977, 57.153466796875],
              [-2.089550781249983, 57.1025390625],
              [-2.26025390625, 56.863330078124996],
              [-2.426660156249994, 56.730712890625],
              [-2.5009765625, 56.636572265625],
              [-2.592675781249994, 56.561572265624996],
              [-2.680957031249989, 56.514404296875],
              [-2.775195312499989, 56.482958984374996],
              [-3.047412109374989, 56.449365234375],
              [-3.123583984374989, 56.42529296875],
              [-3.214453124999977, 56.383935546875],
              [-3.309960937499994, 56.3634765625],
              [-3.197998046875, 56.366064453125],
              [-3.087011718749977, 56.3890625],
              [-2.88515625, 56.397509765624996],
              [-2.652734375, 56.31826171875],
              [-2.674267578124983, 56.25341796875],
              [-2.767578125, 56.2021484375],
              [-2.979785156249989, 56.194091796875],
              [-3.17822265625, 56.080126953124996],
              [-3.267773437499983, 56.045068359375],
              [-3.362255859374983, 56.027636718749996],
              [-3.480419921874983, 56.0328125],
              [-3.695117187499989, 56.063330078125],
              [-3.7890625, 56.09521484375],
              [-3.704150390624989, 56.0431640625],
              [-3.6078125, 56.016015625],
              [-3.048730468749994, 55.951953125],
              [-3.015087890624983, 55.95859375],
              [-2.836865234374983, 56.02626953125],
              [-2.599316406249983, 56.027294921875],
              [-2.147070312499977, 55.902978515625],
              [-2.016845703125, 55.807958984375],
              [-1.830273437499983, 55.671728515625],
              [-1.728759765625, 55.6185546875],
              [-1.655371093749977, 55.570361328124996],
              [-1.610156249999989, 55.498095703124996],
              [-1.522558593749977, 55.259521484375],
              [-1.422656249999989, 55.026416015624996],
              [-1.291748046875, 54.773876953125],
              [-1.232421875, 54.7037109375],
              [-1.154394531249977, 54.6544921875],
              [-0.759326171874989, 54.54140625],
              [-0.67138671875, 54.50390625],
              [-0.518115234374989, 54.3951171875],
              [-0.370361328125, 54.27919921875],
              [-0.232861328124983, 54.19013671875],
              [-0.084375, 54.11806640625],
              [-0.156298828124989, 54.080615234374996],
              [-0.20556640625, 54.021728515625],
              [-0.16875, 53.941650390625],
              [-0.108251953124977, 53.865185546875],
              [0.010546875000017, 53.742822265625],
              [0.115332031250006, 53.60927734375],
              [0.076708984375017, 53.629443359374996],
              [0.036083984375011, 53.64052734375],
              [-0.019433593749994, 53.63720703125],
              [-0.07373046875, 53.64365234375],
              [-0.173828125, 53.68544921875],
              [-0.27001953125, 53.736767578125],
              [-0.461376953124983, 53.716162109375],
              [-0.567675781249989, 53.725390625],
              [-0.659912109375, 53.7240234375],
              [-0.485058593749983, 53.694384765624996],
              [-0.293701171875, 53.692333984375],
              [0.128320312500023, 53.46826171875],
              [0.27099609375, 53.335498046874996],
              [0.355761718750017, 53.159960937499996],
              [0.298046875000011, 53.081103515624996],
              [0.208203125000011, 53.030029296875],
              [0.124414062500023, 52.97158203125],
              [0.0458984375, 52.905615234375],
              [0.27978515625, 52.80869140625],
              [0.330175781250006, 52.81162109375],
              [0.381933593750006, 52.8251953125],
              [0.431640625, 52.858154296875],
              [0.515527343750023, 52.93837890625],
              [0.558789062500011, 52.966943359375],
              [0.704492187500023, 52.97724609375],
              [0.826757812500006, 52.97109375],
              [0.948535156250017, 52.953369140625],
              [1.055566406250023, 52.958984375],
              [1.271289062500017, 52.924560546875],
              [1.382128906250017, 52.893505859375],
              [1.65673828125, 52.753710937499996],
              [1.716113281250017, 52.67724609375],
              [1.743359375000011, 52.578515625],
              [1.74658203125, 52.468994140625],
              [1.700390625000011, 52.368896484375],
              [1.647363281250023, 52.278515625],
              [1.614648437500023, 52.16181640625],
              [1.59140625, 52.119775390625],
              [1.558984375000023, 52.086865234375],
              [1.413476562500023, 51.994775390625],
              [1.316796875000023, 51.95693359375],
              [1.275976562500006, 51.97353515625],
              [1.232421875, 51.971240234374996],
              [1.227832031250017, 51.94912109375],
              [1.273828125000023, 51.902099609375],
              [1.2744140625, 51.845361328125],
              [1.1884765625, 51.803369140625],
              [1.101171875, 51.785449218749996],
              [0.955078125, 51.8078125],
              [0.752246093750017, 51.72958984375],
              [0.898046875, 51.689404296875],
              [0.927441406250011, 51.646630859375],
              [0.890917968750017, 51.571435546874994],
              [0.799218750000023, 51.537890625],
              [0.697558593750017, 51.523046875],
              [0.593457031250011, 51.519482421875],
              [0.507226562500023, 51.50107421875],
              [0.424511718750011, 51.465625],
              [0.5283203125, 51.48447265625],
              [0.600292968750011, 51.46796875],
              [0.6455078125, 51.4046875],
              [0.6865234375, 51.386572265625],
              [0.889355468750011, 51.359521484374994],
              [1.014941406250017, 51.359716796875],
              [1.257128906250017, 51.37509765625],
              [1.373437500000023, 51.37470703125],
              [1.414941406250023, 51.36328125],
              [1.415625, 51.310839843749996],
              [1.397558593750006, 51.18203125],
              [1.365527343750017, 51.15546875],
              [1.04443359375, 51.047265625],
              [0.978613281250006, 50.9716796875],
              [0.960156250000011, 50.92587890625],
              [0.772363281250023, 50.933984375],
              [0.684375, 50.885546875],
              [0.532324218750006, 50.853417968749994],
              [0.414746093750011, 50.819189453125],
              [0.299707031250023, 50.7759765625],
              [0.205078125, 50.763037109375],
              [-0.203906249999989, 50.814355468749994],
              [-0.450781249999977, 50.81015625],
              [-0.785253906249977, 50.765429687499996],
              [-0.871386718749989, 50.772802734375],
              [-1.000585937499977, 50.815625],
              [-1.132861328124989, 50.844580078125],
              [-1.285058593749994, 50.857324218749994],
              [-1.416455078124983, 50.896875],
              [-1.33447265625, 50.82080078125],
              [-1.516748046874994, 50.747460937499994],
              [-1.600830078125, 50.732861328125],
              [-1.687890625, 50.73515625],
              [-1.866015624999989, 50.715234375],
              [-2.031054687499989, 50.725390625],
              [-2.00625, 50.673242187499994],
              [-1.962060546874994, 50.627783203125],
              [-1.997900390624977, 50.6080078125],
              [-2.035839843749983, 50.603076171874996],
              [-2.350146484374989, 50.63740234375],
              [-2.394677734374994, 50.630908203124996],
              [-2.433447265624977, 50.59921875],
              [-2.547753906249994, 50.61630859375],
              [-2.658837890624994, 50.669726562499996],
              [-2.776953124999977, 50.70556640625],
              [-2.90087890625, 50.722412109375],
              [-2.999414062499994, 50.716601562499996],
              [-3.404589843749989, 50.632421875],
              [-3.485449218749977, 50.54794921875],
              [-3.52587890625, 50.428173828125],
              [-3.584375, 50.321826171874996],
              [-3.679785156249977, 50.23994140625],
              [-3.793359375, 50.229248046875],
              [-3.900195312499989, 50.2859375],
              [-4.103417968749994, 50.348535156249994],
              [-4.172558593749983, 50.3908203125],
              [-4.194580078125, 50.393310546875],
              [-4.21728515625, 50.378173828125],
              [-4.296972656249977, 50.35908203125],
              [-4.379492187499977, 50.358203125],
              [-4.506689453124977, 50.341357421874996],
              [-4.727978515624983, 50.290478515625],
              [-4.8173828125, 50.25595703125],
              [-5.009521484375, 50.1607421875],
              [-5.048632812499989, 50.134375],
              [-5.118505859374977, 50.038330078125],
              [-5.225244140624994, 50.021386718749994],
              [-5.322851562499977, 50.082958984375],
              [-5.433984375, 50.104443359375],
              [-5.551220703124983, 50.083398437499994],
              [-5.622119140624989, 50.050683593749994],
              [-5.655175781249994, 50.07724609375],
              [-5.65625, 50.131884765624996],
              [-5.570654296874977, 50.19697265625],
              [-5.34228515625, 50.246142578124996],
              [-5.141796874999983, 50.37373046875],
              [-5.04345703125, 50.451513671875],
              [-5.004443359374989, 50.495263671874994],
              [-4.956396484374977, 50.52314453125],
              [-4.8935546875, 50.53369140625],
              [-4.861279296874983, 50.58203125],
              [-4.582910156249994, 50.7763671875],
              [-4.559960937499994, 50.820947265624994],
              [-4.546093749999983, 50.900683593749996],
              [-4.523095703124994, 50.977441406249994],
              [-4.296484374999977, 51.027148437499996],
              [-4.188183593749983, 51.188525390624996],
              [-4.158398437499983, 51.201318359374994],
              [-3.842333984374989, 51.230908203125],
              [-3.60791015625, 51.228564453124996],
              [-3.375097656249977, 51.19697265625],
              [-3.255761718749994, 51.194140625],
              [-3.135986328125, 51.205029296875],
              [-3.042041015624989, 51.248583984374996],
              [-2.88125, 51.405664062499994],
              [-2.790820312499989, 51.4748046875],
              [-2.687207031249983, 51.537255859374994],
              [-2.590283203124983, 51.60859375],
              [-2.433056640624983, 51.74072265625],
              [-2.539355468749989, 51.69521484375],
              [-2.667675781249983, 51.622998046875],
            ],
          ],
          [
            [
              [-4.19677734375, 53.321435546875],
              [-4.154882812499977, 53.30283203125],
              [-4.049365234374989, 53.30576171875],
              [-4.084277343749989, 53.264306640625],
              [-4.200390624999983, 53.218066406249996],
              [-4.278613281249989, 53.172412109374996],
              [-4.373046875, 53.1341796875],
              [-4.418847656249994, 53.178027343749996],
              [-4.471972656249989, 53.1763671875],
              [-4.55322265625, 53.26044921875],
              [-4.56787109375, 53.386474609375],
              [-4.461718749999989, 53.419287109375],
              [-4.315087890624994, 53.417236328125],
              [-4.19677734375, 53.321435546875],
            ],
          ],
          [
            [
              [-2.548876953124989, 59.231347656249994],
              [-2.662060546874983, 59.23017578125],
              [-2.603613281249977, 59.289306640625],
              [-2.53564453125, 59.304150390625],
              [-2.406982421875, 59.29755859375],
              [-2.429833984374994, 59.271044921875],
              [-2.548876953124989, 59.231347656249994],
            ],
          ],
          [
            [
              [-1.042529296874989, 60.5138671875],
              [-1.06787109375, 60.502294921875],
              [-1.16552734375, 60.60390625],
              [-1.093310546874989, 60.72021484375],
              [-1.005615234375, 60.71650390625],
              [-0.991650390624983, 60.68603515625],
              [-1.000341796874977, 60.6580078125],
              [-1.045019531249977, 60.655517578125],
              [-1.049023437499983, 60.646923828125],
              [-1.035107421874983, 60.592919921874994],
              [-1.034228515624989, 60.530175781249994],
              [-1.042529296874989, 60.5138671875],
            ],
          ],
          [
            [
              [-1.30810546875, 60.5375],
              [-1.287402343749989, 60.467041015625],
              [-1.235742187499994, 60.485302734375],
              [-1.157763671874989, 60.417724609375],
              [-1.117968749999989, 60.417626953124994],
              [-1.052441406249983, 60.444482421874994],
              [-1.065673828125, 60.381591796875],
              [-1.133691406249994, 60.206982421875],
              [-1.152783203124983, 60.17734375],
              [-1.165722656249983, 60.124267578125],
              [-1.179248046874989, 60.113916015624994],
              [-1.199316406249977, 60.006591796875],
              [-1.245312499999983, 59.971240234375],
              [-1.283789062499977, 59.8869140625],
              [-1.299462890624994, 59.878662109375],
              [-1.355859375, 59.9111328125],
              [-1.299511718749983, 60.03984375],
              [-1.276171874999989, 60.114648437499994],
              [-1.290917968749994, 60.153466796874994],
              [-1.322802734374989, 60.188378906249994],
              [-1.409033203124977, 60.189501953125],
              [-1.481494140624989, 60.173388671875],
              [-1.496875, 60.193994140624994],
              [-1.499121093749977, 60.22177734375],
              [-1.5166015625, 60.231005859375],
              [-1.613037109375, 60.2291015625],
              [-1.641357421875, 60.236767578125],
              [-1.660058593749994, 60.262255859375],
              [-1.663769531249983, 60.28251953125],
              [-1.57666015625, 60.298388671875],
              [-1.494433593749989, 60.29248046875],
              [-1.374609374999977, 60.332910156249994],
              [-1.449560546874977, 60.4685546875],
              [-1.548828125, 60.481298828125],
              [-1.57177734375, 60.49443359375],
              [-1.552636718749994, 60.517431640625],
              [-1.498144531249977, 60.529833984375],
              [-1.414208984374994, 60.59873046875],
              [-1.363964843749983, 60.6095703125],
              [-1.301708984374983, 60.607666015625],
              [-1.30810546875, 60.5375],
            ],
          ],
          [
            [
              [-0.774267578124977, 60.811962890625],
              [-0.774316406249994, 60.80048828125],
              [-0.826171875, 60.716162109375],
              [-0.825488281249989, 60.683935546875],
              [-0.909130859374983, 60.68701171875],
              [-0.922265625, 60.697265625],
              [-0.938085937499977, 60.745654296875],
              [-0.927539062499989, 60.79716796875],
              [-0.915820312499989, 60.810449218749994],
              [-0.891406249999989, 60.81591796875],
              [-0.864941406249983, 60.805810546874994],
              [-0.823437499999983, 60.831884765625],
              [-0.801806640624989, 60.83125],
              [-0.774267578124977, 60.811962890625],
            ],
          ],
          [
            [
              [-3.164941406249994, 58.794189453125],
              [-3.222119140624983, 58.78095703125],
              [-3.27880859375, 58.78193359375],
              [-3.3671875, 58.839746093749994],
              [-3.400830078124983, 58.881787109375],
              [-3.394726562499983, 58.909619140625],
              [-3.357421875, 58.918994140625],
              [-3.271923828124983, 58.9052734375],
              [-3.227636718749977, 58.857177734375],
              [-3.222119140624983, 58.82587890625],
              [-3.211621093749983, 58.81357421875],
              [-3.158544921874977, 58.801220703125],
              [-3.164941406249994, 58.794189453125],
            ],
          ],
          [
            [
              [-2.929394531249983, 58.741601562499994],
              [-2.93896484375, 58.738623046875],
              [-2.975390624999989, 58.75693359375],
              [-3.035449218749989, 58.82265625],
              [-2.941210937499989, 58.835693359375],
              [-2.896435546874983, 58.827587890625],
              [-2.9130859375, 58.799609375],
              [-2.929394531249983, 58.741601562499994],
            ],
          ],
          [
            [
              [-3.057421874999989, 59.029638671875],
              [-3.070703125, 59.00498046875],
              [-2.994677734374989, 59.00556640625],
              [-2.884570312499989, 58.984521484374994],
              [-2.817919921874989, 58.981884765625],
              [-2.762451171875, 58.955810546875],
              [-2.793017578124989, 58.90693359375],
              [-2.826220703124989, 58.89326171875],
              [-2.86376953125, 58.890527343749994],
              [-2.994824218749983, 58.939355468749994],
              [-3.166601562499977, 58.919091796874994],
              [-3.200781249999977, 58.92529296875],
              [-3.223339843749983, 58.93876953125],
              [-3.232617187499983, 58.955517578125],
              [-3.2328125, 58.989648437499994],
              [-3.242138671874983, 58.99970703125],
              [-3.304345703124994, 58.967431640624994],
              [-3.331640624999977, 58.971240234375],
              [-3.347070312499994, 58.98671875],
              [-3.353710937499983, 59.01875],
              [-3.346826171874994, 59.064990234375],
              [-3.310351562499989, 59.130810546875],
              [-3.248583984374989, 59.143945312499994],
              [-3.156494140625, 59.136328125],
              [-3.051123046874977, 59.099023437499994],
              [-3.019238281249983, 59.076025390625],
              [-3.02001953125, 59.057666015625],
              [-3.057421874999989, 59.029638671875],
            ],
          ],
          [
            [
              [-2.729394531249994, 59.186767578125],
              [-2.815234374999989, 59.1619140625],
              [-2.851855468749989, 59.182470703125],
              [-2.861425781249977, 59.246826171875],
              [-2.963769531249994, 59.274365234375],
              [-3.013476562499989, 59.291455078125],
              [-3.052050781249989, 59.323876953124994],
              [-3.042236328125, 59.333837890625],
              [-2.975537109374983, 59.347119140625],
              [-2.861621093749989, 59.288330078125],
              [-2.815039062499977, 59.2408203125],
              [-2.730664062499983, 59.2267578125],
              [-2.719921874999983, 59.219482421875],
              [-2.729394531249994, 59.186767578125],
            ],
          ],
          [
            [
              [-6.607617187499983, 56.585009765624996],
              [-6.664453125, 56.579443359375],
              [-6.668554687499977, 56.593603515625],
              [-6.569921874999977, 56.66123046875],
              [-6.506054687499983, 56.67236328125],
              [-6.483691406249989, 56.665771484375],
              [-6.530078124999989, 56.626611328125],
              [-6.607617187499983, 56.585009765624996],
            ],
          ],
          [
            [
              [-5.105419921874983, 55.448828125],
              [-5.231494140624989, 55.448095703125],
              [-5.277050781249983, 55.45673828125],
              [-5.331494140624983, 55.4810546875],
              [-5.392675781249977, 55.618359375],
              [-5.370800781249983, 55.666943359375],
              [-5.345703125, 55.690722656249996],
              [-5.318115234375, 55.7091796875],
              [-5.251611328124994, 55.716943359375],
              [-5.185449218749994, 55.690966796874996],
              [-5.160400390625, 55.666796875],
              [-5.10498046875, 55.573974609375],
              [-5.0947265625, 55.4943359375],
              [-5.105419921874983, 55.448828125],
            ],
          ],
          [
            [
              [-5.777880859374989, 56.3443359375],
              [-6.176171875, 56.288720703125],
              [-6.313427734374983, 56.29365234375],
              [-6.325830078124994, 56.320947265625],
              [-6.298486328124994, 56.339160156249996],
              [-6.184863281249989, 56.356884765625],
              [-6.138867187499983, 56.490625],
              [-6.310644531249977, 56.5521484375],
              [-6.319677734374977, 56.56943359375],
              [-6.30625, 56.598779296875],
              [-6.286328124999983, 56.611865234374996],
              [-6.182080078124983, 56.64296875],
              [-6.138281249999977, 56.649853515625],
              [-6.102734374999983, 56.645654296875],
              [-6.029589843749989, 56.609814453125],
              [-5.946679687499994, 56.534521484375],
              [-5.836035156249977, 56.52255859375],
              [-5.760839843749977, 56.490673828125],
              [-5.777880859374989, 56.3443359375],
            ],
          ],
          [
            [
              [-6.12890625, 55.93056640625],
              [-6.092822265624989, 55.8021484375],
              [-6.0576171875, 55.722509765625],
              [-6.055322265624994, 55.6953125],
              [-6.08837890625, 55.657519531249996],
              [-6.253173828125, 55.607226562499996],
              [-6.305078125, 55.60693359375],
              [-6.307226562499977, 55.619140625],
              [-6.27001953125, 55.6703125],
              [-6.302050781249989, 55.728369140625],
              [-6.286425781249989, 55.772509765624996],
              [-6.3017578125, 55.780615234375],
              [-6.333886718749994, 55.774365234375],
              [-6.451953124999989, 55.704248046875],
              [-6.491357421874994, 55.697314453124996],
              [-6.495654296874989, 55.711572265625],
              [-6.466455078124994, 55.768994140625],
              [-6.462841796874983, 55.808251953125],
              [-6.445263671874983, 55.832373046875],
              [-6.413183593749977, 55.854638671875],
              [-6.374951171874983, 55.871337890625],
              [-6.344140625, 55.87373046875],
              [-6.311279296875, 55.856494140624996],
              [-6.215673828124977, 55.904589843749996],
              [-6.12890625, 55.93056640625],
            ],
          ],
          [
            [
              [-5.970068359374977, 55.81455078125],
              [-5.990917968749983, 55.80380859375],
              [-6.041552734374989, 55.806787109375],
              [-6.060351562499989, 55.822900390625],
              [-6.070703125, 55.84765625],
              [-6.071972656249983, 55.893115234374996],
              [-6.041308593749989, 55.925634765625],
              [-5.911767578124994, 55.974755859375],
              [-5.970312499999977, 55.9921875],
              [-5.97265625, 56.004443359374996],
              [-5.939062499999977, 56.045263671875],
              [-5.799609374999989, 56.1087890625],
              [-5.762255859374989, 56.1203125],
              [-5.725146484374989, 56.1185546875],
              [-5.797216796874977, 56.005615234375],
              [-5.970068359374977, 55.81455078125],
            ],
          ],
          [
            [
              [-6.198681640624983, 58.36328125],
              [-6.325830078124994, 58.188867187499994],
              [-6.375585937499977, 58.1845703125],
              [-6.419287109374977, 58.140966796875],
              [-6.554589843749994, 58.09287109375],
              [-6.4365234375, 58.09189453125],
              [-6.403369140624989, 58.07587890625],
              [-6.402441406249977, 58.041357421875],
              [-6.425195312499994, 58.0212890625],
              [-6.578125, 57.941357421875],
              [-6.683300781249983, 57.91103515625],
              [-6.796582031249983, 57.8275390625],
              [-6.853759765625, 57.826513671875],
              [-6.910351562499983, 57.773388671875],
              [-6.956933593749994, 57.750048828124996],
              [-6.983105468749983, 57.75],
              [-7.01318359375, 57.761767578124996],
              [-7.083447265624983, 57.813769531249996],
              [-6.955957031249994, 57.864892578125],
              [-6.944140624999989, 57.89365234375],
              [-6.856835937499994, 57.92353515625],
              [-6.864160156249994, 57.932861328125],
              [-7.002539062499977, 57.974902343749996],
              [-7.057080078124983, 58.003173828125],
              [-7.051904296874994, 58.01796875],
              [-6.985302734374983, 58.05048828125],
              [-7.016894531249989, 58.05478515625],
              [-7.038232421874994, 58.072314453125],
              [-7.076904296875, 58.079003906249994],
              [-7.088476562499977, 58.095361328124994],
              [-7.095605468749994, 58.13828125],
              [-7.085253906249989, 58.182177734375],
              [-7.044921875, 58.2015625],
              [-7.028417968749977, 58.222314453124994],
              [-7.012060546874977, 58.2287109375],
              [-6.949560546874977, 58.217675781249994],
              [-6.88623046875, 58.182568359375],
              [-6.812304687499989, 58.19609375],
              [-6.726464843749994, 58.189404296875],
              [-6.724707031249977, 58.19755859375],
              [-6.787744140624994, 58.28388671875],
              [-6.776464843749977, 58.301513671875],
              [-6.742285156249977, 58.321630859375],
              [-6.544189453125, 58.383154296875],
              [-6.297167968749989, 58.48662109375],
              [-6.237451171874994, 58.502832031249994],
              [-6.219433593749983, 58.488720703125],
              [-6.194238281249994, 58.435107421875],
              [-6.198681640624983, 58.36328125],
            ],
          ],
          [
            [
              [-6.279052734375, 56.964697265625],
              [-6.308740234374994, 56.951806640625],
              [-6.346240234374989, 56.954296875],
              [-6.383398437499977, 56.9708984375],
              [-6.4326171875, 57.017919921875],
              [-6.322363281249977, 57.050537109375],
              [-6.278222656249994, 57.031396484375],
              [-6.261279296874989, 57.009521484375],
              [-6.260546874999989, 56.98525390625],
              [-6.279052734375, 56.964697265625],
            ],
          ],
          [
            [
              [-6.144726562499983, 57.50498046875],
              [-6.146142578124994, 57.460791015625],
              [-6.163769531249983, 57.408837890625],
              [-6.140820312499983, 57.353662109375],
              [-6.135546874999989, 57.314257812499996],
              [-6.093408203124994, 57.301708984375],
              [-6.067626953125, 57.283544921875],
              [-5.880273437499994, 57.263232421874996],
              [-5.706005859374983, 57.2689453125],
              [-5.672460937499977, 57.252685546875],
              [-5.668652343749983, 57.226904296875],
              [-5.696191406249994, 57.1984375],
              [-5.79541015625, 57.146533203124996],
              [-5.913769531249983, 57.062646484375],
              [-5.949072265624977, 57.045166015625],
              [-5.9873046875, 57.04443359375],
              [-6.014746093749977, 57.051953125],
              [-6.034375, 57.201220703124996],
              [-6.162744140624994, 57.18212890625],
              [-6.26611328125, 57.184326171875],
              [-6.322705078124983, 57.202490234375],
              [-6.362402343749977, 57.2375],
              [-6.442431640624989, 57.327490234375],
              [-6.675439453124994, 57.362890625],
              [-6.741308593749977, 57.412451171875],
              [-6.761132812499994, 57.4423828125],
              [-6.752734374999989, 57.458935546875],
              [-6.704199218749977, 57.495751953125],
              [-6.643457031249994, 57.4826171875],
              [-6.605859375, 57.490673828125],
              [-6.5830078125, 57.507128906249996],
              [-6.58349609375, 57.520654296875],
              [-6.615283203124989, 57.552734375],
              [-6.616796874999977, 57.5626953125],
              [-6.378515624999977, 57.603320312499996],
              [-6.357666015625, 57.666796875],
              [-6.305957031249989, 57.67197265625],
              [-6.246923828124977, 57.651220703125],
              [-6.166064453124989, 57.585302734375],
              [-6.144726562499983, 57.50498046875],
            ],
          ],
          [
            [
              [-7.20556640625, 57.682958984375],
              [-7.0927734375, 57.62666015625],
              [-7.1826171875, 57.53330078125],
              [-7.320556640625, 57.533740234374996],
              [-7.514746093749977, 57.601953125],
              [-7.515625, 57.615869140625],
              [-7.499414062499994, 57.636328125],
              [-7.470312499999977, 57.6525390625],
              [-7.440039062499977, 57.656396484375],
              [-7.391894531249989, 57.64521484375],
              [-7.324853515624994, 57.663134765624996],
              [-7.271191406249983, 57.657470703125],
              [-7.20556640625, 57.682958984375],
            ],
          ],
          [
            [
              [-7.249853515624977, 57.11533203125],
              [-7.292041015624989, 57.109765625],
              [-7.347412109375, 57.11513671875],
              [-7.381494140624994, 57.130664062499996],
              [-7.415917968749994, 57.192138671875],
              [-7.42236328125, 57.229345703125],
              [-7.407031249999989, 57.298486328125],
              [-7.410546875, 57.381103515625],
              [-7.29638671875, 57.38369140625],
              [-7.267138671874989, 57.37177734375],
              [-7.24755859375, 57.1263671875],
              [-7.249853515624977, 57.11533203125],
            ],
          ],
          [
            [
              [-7.416894531249994, 56.9654296875],
              [-7.504785156249994, 56.95166015625],
              [-7.537402343749989, 56.959716796875],
              [-7.54296875, 56.97236328125],
              [-7.52294921875, 57.006787109375],
              [-7.45546875, 57.0189453125],
              [-7.406689453124983, 57.000292968749996],
              [-7.39892578125, 56.983349609375],
              [-7.416894531249994, 56.9654296875],
            ],
          ],
          [
            [
              [-6.218017578125, 54.088720703125],
              [-6.303662109374983, 54.094873046875],
              [-6.363671875, 54.077099609375],
              [-6.402587890625, 54.06064453125],
              [-6.440283203124977, 54.063623046875],
              [-6.548144531249989, 54.057275390625],
              [-6.649804687499994, 54.058642578124996],
              [-6.664208984374994, 54.084765625],
              [-6.646875, 54.163427734375],
              [-6.669531249999977, 54.184716796875],
              [-6.7666015625, 54.195605468749996],
              [-6.802587890624977, 54.21435546875],
              [-6.858349609374983, 54.26865234375],
              [-6.869238281249977, 54.29404296875],
              [-6.877246093749989, 54.3291015625],
              [-6.936132812499977, 54.374316406249996],
              [-7.007714843749994, 54.406689453125],
              [-7.049707031249994, 54.408251953124996],
              [-7.133496093749983, 54.35537109375],
              [-7.202587890624983, 54.301806640624996],
              [-7.178076171874977, 54.27490234375],
              [-7.155468749999983, 54.239501953125],
              [-7.193066406249983, 54.214111328125],
              [-7.306738281249977, 54.156005859375],
              [-7.324511718749989, 54.133447265625],
              [-7.355175781249983, 54.121240234375],
              [-7.409423828125, 54.1373046875],
              [-7.54443359375, 54.13359375],
              [-7.606542968749977, 54.143847656249996],
              [-7.678759765624989, 54.186669921875],
              [-7.854931640624983, 54.215283203125],
              [-7.884472656249983, 54.2837890625],
              [-7.91845703125, 54.29658203125],
              [-8.118261718749977, 54.4142578125],
              [-8.144824218749989, 54.453515625],
              [-8.118945312499989, 54.476953125],
              [-8.044335937499994, 54.512451171875],
              [-7.793798828124977, 54.571240234375],
              [-7.75439453125, 54.594921875],
              [-7.746289062499983, 54.6158203125],
              [-7.81982421875, 54.639697265624996],
              [-7.886132812499994, 54.666064453124996],
              [-7.908740234374989, 54.683349609375],
              [-7.910595703124983, 54.69833984375],
              [-7.872949218749994, 54.71787109375],
              [-7.797265625, 54.719287109374996],
              [-7.7375, 54.71044921875],
              [-7.689990234374989, 54.72802734375],
              [-7.6064453125, 54.745703125],
              [-7.550390624999977, 54.76796875],
              [-7.502197265625, 54.825439453125],
              [-7.451269531249977, 54.877099609375],
              [-7.445996093749983, 54.905126953125],
              [-7.401416015624989, 55.0033203125],
              [-7.376904296874983, 55.027685546875],
              [-7.218652343749994, 55.0919921875],
              [-7.178613281249994, 55.056884765625],
              [-7.100634765624989, 55.048291015625],
              [-7.03076171875, 55.080615234374996],
              [-6.947167968749994, 55.18251953125],
              [-6.888964843749989, 55.188916015625],
              [-6.824853515624994, 55.1806640625],
              [-6.698828124999977, 55.19345703125],
              [-6.475048828124983, 55.241015625],
              [-6.375292968749989, 55.241796875],
              [-6.234228515624977, 55.216845703124996],
              [-6.129150390625, 55.2173828125],
              [-6.035791015624994, 55.14453125],
              [-5.985742187499994, 55.0296875],
              [-5.869189453124989, 54.9162109375],
              [-5.716845703124989, 54.81748046875],
              [-5.710742187499989, 54.757080078125],
              [-5.765185546874989, 54.724658203124996],
              [-5.879101562499983, 54.684375],
              [-5.878613281249983, 54.64130859375],
              [-5.803466796875, 54.663037109375],
              [-5.738623046874977, 54.673046875],
              [-5.58251953125, 54.663427734375],
              [-5.527929687499977, 54.61962890625],
              [-5.490185546874983, 54.554052734375],
              [-5.470410156249983, 54.5001953125],
              [-5.48388671875, 54.441650390625],
              [-5.52587890625, 54.460205078125],
              [-5.568554687499983, 54.51259765625],
              [-5.615966796875, 54.53671875],
              [-5.671093749999983, 54.549755859375],
              [-5.646093749999977, 54.477880859375],
              [-5.655957031249983, 54.38173828125],
              [-5.631884765624989, 54.37265625],
              [-5.557812499999983, 54.37099609375],
              [-5.606787109374977, 54.27255859375],
              [-5.708056640624989, 54.245849609375],
              [-5.826171875, 54.23583984375],
              [-5.854638671874994, 54.200976562499996],
              [-5.876074218749977, 54.156054687499996],
              [-5.937744140625, 54.0890625],
              [-6.01904296875, 54.05126953125],
              [-6.11953125, 54.058886718749996],
              [-6.218017578125, 54.088720703125],
            ],
          ],
          [
            [
              [-1.065576171874994, 50.690234375],
              [-1.149365234374983, 50.655712890625],
              [-1.175830078124989, 50.615234375],
              [-1.196093749999989, 50.59921875],
              [-1.25146484375, 50.588818359375],
              [-1.306298828124994, 50.588525390624994],
              [-1.515332031249983, 50.669775390625],
              [-1.563427734374983, 50.66611328125],
              [-1.515673828124989, 50.7033203125],
              [-1.385839843749977, 50.733544921874994],
              [-1.312792968749989, 50.773486328124996],
              [-1.144238281249983, 50.734716796875],
              [-1.065576171874994, 50.690234375],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 3,
        labelrank: 3,
        sovereignt: "Greece",
        sov_a3: "GRC",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Greece",
        adm0_a3: "GRC",
        geou_dif: 0,
        geounit: "Greece",
        gu_a3: "GRC",
        su_dif: 0,
        subunit: "Greece",
        su_a3: "GRC",
        brk_diff: 0,
        name: "Greece",
        name_long: "Greece",
        brk_a3: "GRC",
        brk_name: "Greece",
        brk_group: null,
        abbrev: "Greece",
        postal: "GR",
        formal_en: "Hellenic Republic",
        formal_fr: null,
        name_ciawf: "Greece",
        note_adm0: null,
        note_brk: null,
        name_sort: "Greece",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 2,
        mapcolor9: 2,
        mapcolor13: 9,
        pop_est: 10716322,
        pop_rank: 14,
        pop_year: 2019,
        gdp_md: 209852,
        gdp_year: 2019,
        economy: "2. Developed region: nonG7",
        income_grp: "1. High income: OECD",
        fips_10: "GR",
        iso_a2: "GR",
        iso_a2_eh: "GR",
        iso_a3: "GRC",
        iso_a3_eh: "GRC",
        iso_n3: "300",
        iso_n3_eh: "300",
        un_a3: "300",
        wb_a2: "GR",
        wb_a3: "GRC",
        woe_id: 23424833,
        woe_id_eh: 23424833,
        woe_note: "Exact WOE match as country",
        adm0_iso: "GRC",
        adm0_diff: null,
        adm0_tlc: "GRC",
        adm0_a3_us: "GRC",
        adm0_a3_fr: "GRC",
        adm0_a3_ru: "GRC",
        adm0_a3_es: "GRC",
        adm0_a3_cn: "GRC",
        adm0_a3_tw: "GRC",
        adm0_a3_in: "GRC",
        adm0_a3_np: "GRC",
        adm0_a3_pk: "GRC",
        adm0_a3_de: "GRC",
        adm0_a3_gb: "GRC",
        adm0_a3_br: "GRC",
        adm0_a3_il: "GRC",
        adm0_a3_ps: "GRC",
        adm0_a3_sa: "GRC",
        adm0_a3_eg: "GRC",
        adm0_a3_ma: "GRC",
        adm0_a3_pt: "GRC",
        adm0_a3_ar: "GRC",
        adm0_a3_jp: "GRC",
        adm0_a3_ko: "GRC",
        adm0_a3_vn: "GRC",
        adm0_a3_tr: "GRC",
        adm0_a3_id: "GRC",
        adm0_a3_pl: "GRC",
        adm0_a3_gr: "GRC",
        adm0_a3_it: "GRC",
        adm0_a3_nl: "GRC",
        adm0_a3_se: "GRC",
        adm0_a3_bd: "GRC",
        adm0_a3_ua: "GRC",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Southern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 6,
        long_len: 6,
        abbrev_len: 6,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 2.7,
        max_label: 8,
        label_x: 21.72568,
        label_y: 39.492763,
        ne_id: 1159320811,
        wikidataid: "Q41",
        name_ar: "اليونان",
        name_bn: "গ্রিস",
        name_de: "Griechenland",
        name_en: "Greece",
        name_es: "Grecia",
        name_fa: "یونان",
        name_fr: "Grèce",
        name_el: "Ελλάδα",
        name_he: "יוון",
        name_hi: "यूनान",
        name_hu: "Görögország",
        name_id: "Yunani",
        name_it: "Grecia",
        name_ja: "ギリシャ",
        name_ko: "그리스",
        name_nl: "Griekenland",
        name_pl: "Grecja",
        name_pt: "Grécia",
        name_ru: "Греция",
        name_sv: "Grekland",
        name_tr: "Yunanistan",
        name_uk: "Греція",
        name_ur: "یونان",
        name_vi: "Hy Lạp",
        name_zh: "希腊",
        name_zht: "希臘",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "GRC.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [27.860156250000017, 36.55390625],
              [27.838281250000023, 36.537988281249994],
              [27.7880859375, 36.58369140625],
              [27.785742187500006, 36.60751953125],
              [27.836816406250023, 36.63486328125],
              [27.8625, 36.641162109374996],
              [27.86982421875001, 36.622509765625],
              [27.869042968750023, 36.582666015624994],
              [27.860156250000017, 36.55390625],
            ],
          ],
          [
            [
              [20.6123046875, 38.383349609374996],
              [20.62470703125001, 38.267871093749996],
              [20.695214843750023, 38.24619140625],
              [20.788867187500017, 38.14208984375],
              [20.78076171875, 38.088818359375],
              [20.761328125, 38.070556640625],
              [20.60615234375001, 38.1197265625],
              [20.568945312500006, 38.09765625],
              [20.523535156250006, 38.106640625],
              [20.495507812500023, 38.16416015625],
              [20.49873046875001, 38.184375],
              [20.47333984375001, 38.218798828124996],
              [20.4521484375, 38.234179687499996],
              [20.391015625000023, 38.188427734375],
              [20.3525390625, 38.1798828125],
              [20.35224609375001, 38.221728515624996],
              [20.40869140625, 38.336767578125],
              [20.43505859375, 38.356201171875],
              [20.4814453125, 38.318212890625],
              [20.519628906250006, 38.332324218749996],
              [20.54833984375, 38.39453125],
              [20.55029296875, 38.45654296875],
              [20.56318359375001, 38.474951171875],
              [20.6123046875, 38.383349609374996],
            ],
          ],
          [
            [
              [20.888476562500017, 37.80537109375],
              [20.993945312500017, 37.7080078125],
              [20.909082031250023, 37.73212890625],
              [20.81855468750001, 37.66474609375],
              [20.70380859375001, 37.743457031249996],
              [20.635058593750017, 37.823144531249994],
              [20.619531250000023, 37.855029296874996],
              [20.691503906250006, 37.929541015625],
              [20.758691406250023, 37.852978515625],
              [20.83984375, 37.840722656249994],
              [20.888476562500017, 37.80537109375],
            ],
          ],
          [
            [
              [20.68671875000001, 38.608691406249996],
              [20.647851562500023, 38.600976562499994],
              [20.614355468750006, 38.60625],
              [20.583984375, 38.601708984374994],
              [20.5546875, 38.582568359374996],
              [20.557910156250017, 38.661865234375],
              [20.59248046875001, 38.76015625],
              [20.634667968750023, 38.817578125],
              [20.694140625000017, 38.84423828125],
              [20.719628906250023, 38.799169921875],
              [20.71484375, 38.638330078124994],
              [20.68671875000001, 38.608691406249996],
            ],
          ],
          [
            [
              [20.758691406250023, 38.329443359375],
              [20.709277343750017, 38.318603515625],
              [20.646386718750023, 38.414306640625],
              [20.623632812500006, 38.480322265625],
              [20.649707031250017, 38.483984375],
              [20.6748046875, 38.476318359375],
              [20.701269531250006, 38.451416015625],
              [20.701074218750023, 38.425927734374994],
              [20.71162109375001, 38.3986328125],
              [20.739160156250023, 38.365771484374996],
              [20.758691406250023, 38.329443359375],
            ],
          ],
          [
            [
              [20.077929687500017, 39.43271484375],
              [20.099609375, 39.376611328124994],
              [19.975, 39.411425781249996],
              [19.88398437500001, 39.4615234375],
              [19.808886718750017, 39.585302734375],
              [19.64892578125, 39.726171875],
              [19.646484375, 39.76708984375],
              [19.707324218750017, 39.798095703125],
              [19.83857421875001, 39.820117187499996],
              [19.891699218750006, 39.797265625],
              [19.926074218750017, 39.773730468749996],
              [19.936816406250017, 39.746728515624994],
              [19.862207031250023, 39.692626953125],
              [19.8466796875, 39.668115234374994],
              [19.904101562500017, 39.619482421875],
              [19.903125, 39.6],
              [19.92734375, 39.505908203124996],
              [19.95527343750001, 39.47041015625],
              [20.027734375000023, 39.44208984375],
              [20.077929687500017, 39.43271484375],
            ],
          ],
          [
            [
              [23.415429687500023, 38.958642578124994],
              [23.471972656250017, 38.85009765625],
              [23.525, 38.8134765625],
              [23.63623046875, 38.77021484375],
              [23.6884765625, 38.764697265624996],
              [23.878222656250017, 38.686572265624996],
              [24.099023437500023, 38.67099609375],
              [24.127539062500006, 38.648486328124996],
              [24.154687500000023, 38.58828125],
              [24.19970703125, 38.541015625],
              [24.211035156250006, 38.504248046875],
              [24.1875, 38.463427734374996],
              [24.220117187500023, 38.338623046875],
              [24.275781250000023, 38.220019531249996],
              [24.359667968750017, 38.1625],
              [24.463964843750006, 38.1451171875],
              [24.563281250000017, 38.147509765624996],
              [24.58837890625, 38.123974609375],
              [24.578515625000023, 38.020166015624994],
              [24.536523437500023, 37.979736328125],
              [24.502343750000023, 37.969921875],
              [24.47265625, 37.980517578124996],
              [24.44580078125, 38.00498046875],
              [24.41650390625, 38.016552734375],
              [24.359472656250006, 38.0185546875],
              [24.317773437500023, 38.060351562499996],
              [24.212011718750006, 38.117529296875],
              [24.19257812500001, 38.151660156249996],
              [24.1890625, 38.204296875],
              [24.144140625, 38.243066406249994],
              [24.102832031250017, 38.316845703125],
              [24.063574218750006, 38.337207031249996],
              [24.041894531250023, 38.37412109375],
              [24.040136718750006, 38.389990234375],
              [23.88623046875, 38.400732421875],
              [23.7587890625, 38.401220703125],
              [23.650781250000023, 38.44306640625],
              [23.61738281250001, 38.552539062499996],
              [23.553320312500006, 38.581982421875],
              [23.505273437500023, 38.612939453124994],
              [23.465234375000023, 38.655859375],
              [23.364062500000017, 38.735009765624994],
              [23.25214843750001, 38.801220703125],
              [23.143945312500023, 38.84482421875],
              [23.029101562500017, 38.873388671875],
              [22.93574218750001, 38.839648437499996],
              [22.88134765625, 38.84765625],
              [22.87031250000001, 38.870507812499994],
              [22.986328125, 38.915917968749994],
              [23.145800781250017, 39.002685546875],
              [23.258203125000023, 39.03134765625],
              [23.31269531250001, 39.034912109375],
              [23.415429687500023, 38.958642578124994],
            ],
          ],
          [
            [
              [23.77978515625, 39.114404296874994],
              [23.735156250000017, 39.08056640625],
              [23.666113281250006, 39.095361328124994],
              [23.59394531250001, 39.20859375],
              [23.77978515625, 39.114404296874994],
            ],
          ],
          [
            [
              [23.887988281250017, 39.15830078125],
              [23.841210937500023, 39.14658203125],
              [23.888085937500023, 39.226367187499996],
              [23.97089843750001, 39.267724609374994],
              [23.939746093750017, 39.200537109375],
              [23.887988281250017, 39.15830078125],
            ],
          ],
          [
            [
              [24.674707031250023, 38.809228515624994],
              [24.56904296875001, 38.784814453124994],
              [24.541015625, 38.788671875],
              [24.564550781250006, 38.81943359375],
              [24.56640625, 38.832373046875],
              [24.461035156250006, 38.888623046875],
              [24.473437500000017, 38.961669921875],
              [24.485644531250017, 38.980273437499996],
              [24.5640625, 38.942236328125],
              [24.58125, 38.878857421875],
              [24.674707031250023, 38.809228515624994],
            ],
          ],
          [
            [
              [24.774218750000017, 40.615185546875],
              [24.645898437500023, 40.579443359375],
              [24.515527343750023, 40.647021484374996],
              [24.516699218750006, 40.68720703125],
              [24.585546875, 40.76875],
              [24.623339843750017, 40.792919921875],
              [24.719140625000023, 40.786279296874994],
              [24.77363281250001, 40.730273437499996],
              [24.78632812500001, 40.703857421875],
              [24.768652343750006, 40.65888671875],
              [24.774218750000017, 40.615185546875],
            ],
          ],
          [
            [
              [23.55097656250001, 37.92587890625],
              [23.51142578125001, 37.901171875],
              [23.466796875, 37.902392578124996],
              [23.43525390625001, 37.911474609375],
              [23.419335937500023, 37.93125],
              [23.4390625, 37.940673828125],
              [23.462207031250017, 37.980371093749994],
              [23.483691406250017, 37.991113281249994],
              [23.515527343750023, 37.98603515625],
              [23.53486328125001, 37.97021484375],
              [23.55097656250001, 37.92587890625],
            ],
          ],
          [
            [
              [23.053808593750006, 36.189794921875],
              [23.04218750000001, 36.146386718749994],
              [22.939453125, 36.176220703125],
              [22.91083984375001, 36.220996093749996],
              [22.905664062500023, 36.3203125],
              [22.9326171875, 36.36875],
              [22.950488281250017, 36.383935546874994],
              [22.997851562500017, 36.328125],
              [23.097070312500023, 36.24658203125],
              [23.053808593750006, 36.189794921875],
            ],
          ],
          [
            [
              [27.176074218750017, 35.465283203125],
              [27.13789062500001, 35.409082031249994],
              [27.09912109375, 35.456445312499994],
              [27.115820312500006, 35.511132812499994],
              [27.070703125000023, 35.59775390625],
              [27.156054687500017, 35.72626953125],
              [27.158007812500017, 35.788671875],
              [27.22314453125, 35.820458984374994],
              [27.20703125, 35.714453125],
              [27.1572265625, 35.6294921875],
              [27.208886718750023, 35.558935546875],
              [27.23359375000001, 35.478564453124996],
              [27.176074218750017, 35.465283203125],
            ],
          ],
          [
            [
              [27.01972656250001, 36.959033203124996],
              [26.919921875, 36.945214843749994],
              [26.93769531250001, 37.024609375],
              [26.888671875, 37.087255859375],
              [26.966601562500017, 37.052099609375],
              [27.016015625000023, 37.009667968749994],
              [27.040136718750006, 37.0015625],
              [27.034570312500023, 36.975976562499994],
              [27.01972656250001, 36.959033203124996],
            ],
          ],
          [
            [
              [26.949609375000023, 36.727099609374996],
              [26.918359375000023, 36.725927734375],
              [26.95556640625, 36.77421875],
              [27.061132812500006, 36.840380859374996],
              [27.214941406250006, 36.8986328125],
              [27.265625, 36.905126953125],
              [27.352148437500006, 36.868896484375],
              [27.193164062500017, 36.809130859374996],
              [27.150976562500006, 36.777587890625],
              [27.033593750000023, 36.770751953125],
              [26.949609375000023, 36.727099609374996],
            ],
          ],
          [
            [
              [25.5458984375, 36.967578125],
              [25.45673828125001, 36.9296875],
              [25.395898437500023, 36.984375],
              [25.361914062500006, 37.07041015625],
              [25.525292968750023, 37.19638671875],
              [25.564355468750023, 37.185107421874996],
              [25.587890625, 37.1525390625],
              [25.584277343750017, 37.039306640625],
              [25.5458984375, 36.967578125],
            ],
          ],
          [
            [
              [25.27890625, 37.068408203124996],
              [25.19941406250001, 36.99130859375],
              [25.13330078125, 36.999658203124994],
              [25.10546875, 37.034960937499996],
              [25.146484375, 37.107421875],
              [25.23505859375001, 37.14853515625],
              [25.275292968750023, 37.137841796874994],
              [25.271484375, 37.0841796875],
              [25.27890625, 37.068408203124996],
            ],
          ],
          [
            [
              [25.482421875, 36.392626953124996],
              [25.435937500000023, 36.340087890625],
              [25.370507812500023, 36.358935546874996],
              [25.39716796875001, 36.378955078124996],
              [25.412890625000017, 36.4048828125],
              [25.414648437500006, 36.442285156249994],
              [25.396875, 36.46533203125],
              [25.408984375000017, 36.47373046875],
              [25.467382812500006, 36.43505859375],
              [25.482421875, 36.392626953124996],
            ],
          ],
          [
            [
              [25.381738281250023, 36.6740234375],
              [25.364355468750006, 36.658349609374994],
              [25.288671875, 36.721533203125],
              [25.25996093750001, 36.758447265624994],
              [25.2958984375, 36.78916015625],
              [25.40693359375001, 36.717333984374996],
              [25.381738281250023, 36.6740234375],
            ],
          ],
          [
            [
              [26.82441406250001, 37.811425781249994],
              [26.947363281250006, 37.778466796874994],
              [26.981542968750006, 37.781982421875],
              [27.039648437500006, 37.77001953125],
              [27.055078125000023, 37.709277343749996],
              [26.978125, 37.700488281249996],
              [26.84492187500001, 37.6447265625],
              [26.78828125000001, 37.656982421875],
              [26.720507812500017, 37.70546875],
              [26.612890625, 37.710498046874996],
              [26.5810546875, 37.72373046875],
              [26.638671875, 37.780859375],
              [26.74335937500001, 37.809765625],
              [26.82441406250001, 37.811425781249994],
            ],
          ],
          [
            [
              [26.029296875, 37.52939453125],
              [25.982421875, 37.5255859375],
              [25.99677734375001, 37.565576171874994],
              [26.086328125000023, 37.634912109374994],
              [26.211523437500006, 37.63828125],
              [26.325585937500023, 37.673046875],
              [26.351367187500017, 37.67431640625],
              [26.296875, 37.619580078125],
              [26.204882812500017, 37.568505859374994],
              [26.029296875, 37.52939453125],
            ],
          ],
          [
            [
              [25.859375, 36.790429687499994],
              [25.77109375, 36.7822265625],
              [25.7431640625, 36.78974609375],
              [25.796777343750023, 36.80703125],
              [25.834375, 36.825390625],
              [25.852441406250023, 36.847558593749994],
              [25.941992187500006, 36.886572265625],
              [26.00068359375001, 36.937402343749994],
              [26.064453125, 36.902734375],
              [25.984667968750017, 36.8796875],
              [25.859375, 36.790429687499994],
            ],
          ],
          [
            [
              [26.46064453125001, 36.585400390625],
              [26.381640625000017, 36.5615234375],
              [26.331445312500023, 36.511376953124994],
              [26.27001953125, 36.546923828124996],
              [26.269824218750017, 36.59541015625],
              [26.337011718750006, 36.58056640625],
              [26.384179687500023, 36.607861328125],
              [26.370019531250023, 36.638574218749994],
              [26.421289062500023, 36.62421875],
              [26.46064453125001, 36.585400390625],
            ],
          ],
          [
            [
              [24.35595703125, 37.57685546875],
              [24.288964843750023, 37.528271484375],
              [24.277441406250006, 37.601123046874996],
              [24.320410156250006, 37.677734375],
              [24.37910156250001, 37.68271484375],
              [24.400781250000023, 37.6490234375],
              [24.35595703125, 37.57685546875],
            ],
          ],
          [
            [
              [24.43574218750001, 37.34443359375],
              [24.37890625, 37.314111328124994],
              [24.397753906250017, 37.383447265624994],
              [24.369726562500006, 37.41962890625],
              [24.394824218750017, 37.450390625],
              [24.43125, 37.4751953125],
              [24.448535156250017, 37.449560546875],
              [24.4814453125, 37.408007812499996],
              [24.43574218750001, 37.34443359375],
            ],
          ],
          [
            [
              [24.535742187500006, 36.76376953125],
              [24.5375, 36.705029296875],
              [24.530664062500023, 36.683984375],
              [24.325976562500017, 36.655615234375],
              [24.34492187500001, 36.722998046875],
              [24.357421875, 36.744287109374994],
              [24.425195312500023, 36.712939453124996],
              [24.4501953125, 36.728955078125],
              [24.460351562500023, 36.747460937499994],
              [24.535742187500006, 36.76376953125],
            ],
          ],
          [
            [
              [24.94287109375, 37.493505859375],
              [24.937890625000023, 37.389697265624996],
              [24.911523437500023, 37.390576171875],
              [24.89619140625001, 37.406591796875],
              [24.895312500000017, 37.446337890624996],
              [24.906542968750017, 37.50888671875],
              [24.94287109375, 37.493505859375],
            ],
          ],
          [
            [
              [24.99169921875, 37.759619140625],
              [24.962207031250017, 37.6923828125],
              [24.884082031250017, 37.7705078125],
              [24.79853515625001, 37.824023437499996],
              [24.766503906250023, 37.870703125],
              [24.71435546875, 37.898876953125],
              [24.7001953125, 37.961669921875],
              [24.76337890625001, 37.9875],
              [24.790429687500023, 37.990136718749994],
              [24.855078125, 37.913671875],
              [24.956347656250017, 37.90478515625],
              [24.94843750000001, 37.857666015625],
              [24.98046875, 37.796923828124996],
              [24.99169921875, 37.759619140625],
            ],
          ],
          [
            [
              [25.255859375, 37.599609375],
              [25.21875, 37.535107421875],
              [25.156347656250006, 37.545068359374994],
              [25.05195312500001, 37.614453125],
              [25.01630859375001, 37.645947265625],
              [24.996484375000023, 37.676904296874994],
              [25.039355468750017, 37.6806640625],
              [25.091796875, 37.647998046874996],
              [25.225390625000017, 37.630664062499996],
              [25.255859375, 37.599609375],
            ],
          ],
          [
            [
              [24.72089843750001, 36.921435546874996],
              [24.70263671875, 36.91708984375],
              [24.67646484375001, 36.959277343749996],
              [24.670996093750006, 36.998583984374996],
              [24.681445312500017, 37.021630859374994],
              [24.716113281250017, 37.023828125],
              [24.76318359375, 36.94921875],
              [24.72089843750001, 36.921435546874996],
            ],
          ],
          [
            [
              [26.094042968750017, 38.218066406249996],
              [25.99853515625, 38.161523437499994],
              [25.891894531250017, 38.243310546874994],
              [25.874316406250017, 38.26962890625],
              [25.95263671875, 38.302636718749994],
              [25.99140625000001, 38.353515625],
              [25.9599609375, 38.416015625],
              [25.85126953125001, 38.5083984375],
              [25.846093750000023, 38.574023437499996],
              [26.0125, 38.601708984374994],
              [26.110449218750006, 38.54462890625],
              [26.16035156250001, 38.54072265625],
              [26.141210937500006, 38.486181640625],
              [26.14960937500001, 38.46845703125],
              [26.157031250000017, 38.3029296875],
              [26.110742187500023, 38.279638671875],
              [26.103125, 38.234179687499996],
              [26.094042968750017, 38.218066406249996],
            ],
          ],
          [
            [
              [26.41015625, 39.329443359375],
              [26.39277343750001, 39.2701171875],
              [26.531054687500017, 39.171777343749994],
              [26.578222656250006, 39.109521484374994],
              [26.595605468750023, 39.048828125],
              [26.583984375, 39.0314453125],
              [26.531542968750017, 39.064355468749994],
              [26.488671875000023, 39.0748046875],
              [26.503125, 39.0314453125],
              [26.547167968750017, 38.994140625],
              [26.46875, 38.972802734374994],
              [26.39013671875, 38.973925781249996],
              [26.16083984375001, 39.02587890625],
              [26.10791015625, 39.0810546875],
              [26.2451171875, 39.164111328124996],
              [26.27314453125001, 39.197558593749996],
              [26.17597656250001, 39.194287109375],
              [26.072363281250006, 39.095605468749994],
              [25.90625, 39.138964843749996],
              [25.85546875, 39.178662109375],
              [25.844140625000023, 39.200048828125],
              [25.909570312500023, 39.287548828125],
              [26.026464843750006, 39.284619140625],
              [26.08837890625, 39.304296875],
              [26.164843750000017, 39.331982421875],
              [26.165429687500023, 39.37353515625],
              [26.347753906250006, 39.3830078125],
              [26.41015625, 39.329443359375],
            ],
          ],
          [
            [
              [25.68574218750001, 40.4265625],
              [25.572656250000023, 40.400439453124996],
              [25.448046875000017, 40.4828125],
              [25.56855468750001, 40.515869140625],
              [25.624316406250017, 40.491992187499996],
              [25.66425781250001, 40.4638671875],
              [25.68574218750001, 40.4265625],
            ],
          ],
          [
            [
              [25.43769531250001, 39.983300781249994],
              [25.39990234375, 39.949560546875],
              [25.3720703125, 39.89130859375],
              [25.35703125, 39.80810546875],
              [25.298730468750023, 39.806103515625],
              [25.26337890625001, 39.82294921875],
              [25.251757812500017, 39.854394531249994],
              [25.249414062500023, 39.894140625],
              [25.22382812500001, 39.892578125],
              [25.203222656250006, 39.849414062499996],
              [25.18515625, 39.829931640625],
              [25.12646484375, 39.825830078124994],
              [25.06220703125001, 39.852392578125],
              [25.065234375000017, 39.90986328125],
              [25.05234375, 39.976367187499996],
              [25.058007812500023, 39.999658203124994],
              [25.234179687500017, 40.005419921874996],
              [25.285742187500006, 39.956298828125],
              [25.348046875000023, 39.984765625],
              [25.373632812500006, 40.015527343749994],
              [25.449121093750023, 40.034814453124994],
              [25.43769531250001, 39.983300781249994],
            ],
          ],
          [
            [
              [25.402734375000023, 37.419140625],
              [25.30712890625, 37.412988281249994],
              [25.31269531250001, 37.489306640624996],
              [25.34814453125, 37.509179687499994],
              [25.462988281250006, 37.47109375],
              [25.457421875000023, 37.447070312499996],
              [25.402734375000023, 37.419140625],
            ],
          ],
          [
            [
              [24.523535156250006, 37.12509765625],
              [24.48652343750001, 37.11005859375],
              [24.4248046875, 37.131982421874994],
              [24.441210937500017, 37.186865234375],
              [24.483789062500023, 37.210205078125],
              [24.529101562500017, 37.192333984375],
              [24.535937500000017, 37.16767578125],
              [24.523535156250006, 37.12509765625],
            ],
          ],
          [
            [
              [27.8427734375, 35.929296875],
              [27.770605468750006, 35.90830078125],
              [27.745703125, 35.911035156249994],
              [27.71552734375001, 35.957324218749996],
              [27.75732421875, 36.069189453125],
              [27.718652343750023, 36.14111328125],
              [27.71630859375, 36.17158203125],
              [27.7744140625, 36.213769531249994],
              [27.815234375000017, 36.276953125],
              [27.914453125000023, 36.3453125],
              [28.171484375, 36.426220703125],
              [28.231835937500023, 36.433642578124996],
              [28.230078125, 36.370263671874994],
              [28.14404296875, 36.209863281249994],
              [28.067675781250017, 36.1296875],
              [28.087792968750023, 36.065332031249994],
              [27.96552734375001, 36.047509765624994],
              [27.8427734375, 35.929296875],
            ],
          ],
          [
            [
              [23.85224609375001, 35.535449218749996],
              [23.92060546875001, 35.528173828125],
              [24.01328125, 35.529443359374994],
              [24.034375, 35.535400390625],
              [24.093359375, 35.59384765625],
              [24.166015625, 35.59521484375],
              [24.19775390625, 35.537451171875],
              [24.1240234375, 35.51083984375],
              [24.108984375, 35.49580078125],
              [24.123144531250006, 35.483642578125],
              [24.178515625000017, 35.459521484374996],
              [24.25537109375, 35.468603515625],
              [24.257714843750023, 35.423144531249996],
              [24.27490234375, 35.385986328125],
              [24.312890625000023, 35.363818359374996],
              [24.35400390625, 35.35947265625],
              [24.444921875, 35.366015625],
              [24.534570312500023, 35.380761718749994],
              [24.626953125, 35.409912109375],
              [24.721289062500006, 35.4248046875],
              [25.003125, 35.40986328125],
              [25.104296875000017, 35.346923828125],
              [25.296777343750023, 35.33935546875],
              [25.475683593750006, 35.306201171874996],
              [25.569628906250017, 35.328076171875],
              [25.73017578125001, 35.348583984375],
              [25.755859375, 35.3263671875],
              [25.735156250000017, 35.184033203125],
              [25.745019531250023, 35.142724609374994],
              [25.791308593750017, 35.122851562499996],
              [25.83710937500001, 35.132568359375],
              [25.893359375000017, 35.17919921875],
              [26.02802734375001, 35.215283203125],
              [26.167871093750023, 35.215087890625],
              [26.285546875000023, 35.309765625],
              [26.320214843750023, 35.31513671875],
              [26.298632812500017, 35.268603515624996],
              [26.280859375, 35.159228515624996],
              [26.25556640625001, 35.095166015625],
              [26.24433593750001, 35.044677734375],
              [26.165625, 35.018603515624996],
              [26.046679687500017, 35.01416015625],
              [25.8296875, 35.025195312499996],
              [25.6109375, 35.00732421875],
              [25.20576171875001, 34.959277343749996],
              [24.7998046875, 34.934472656249994],
              [24.745214843750006, 34.950634765625],
              [24.743945312500017, 35.01435546875],
              [24.735156250000017, 35.05830078125],
              [24.708886718750023, 35.0890625],
              [24.583398437500023, 35.11533203125],
              [24.463671875000017, 35.1603515625],
              [23.99433593750001, 35.221923828125],
              [23.883593750000017, 35.24609375],
              [23.703906250000017, 35.23349609375],
              [23.638085937500023, 35.23515625],
              [23.5927734375, 35.257226562499994],
              [23.561621093750006, 35.295166015625],
              [23.54755859375001, 35.415576171874996],
              [23.56982421875, 35.534765625],
              [23.608691406250017, 35.566259765625],
              [23.6265625, 35.53037109375],
              [23.672656250000017, 35.513916015625],
              [23.715429687500006, 35.550146484375],
              [23.71503906250001, 35.604736328125],
              [23.736914062500006, 35.655517578125],
              [23.770800781250017, 35.634228515625],
              [23.793359375000023, 35.556201171874996],
              [23.85224609375001, 35.535449218749996],
            ],
          ],
          [
            [
              [26.320898437500006, 41.716552734375],
              [26.410546875000023, 41.696337890624996],
              [26.4625, 41.663378906249996],
              [26.495019531250023, 41.633251953125],
              [26.54453125, 41.607226562499996],
              [26.581347656250017, 41.60126953125],
              [26.609765625000023, 41.512158203125],
              [26.624902343750023, 41.401757812499994],
              [26.602343750000017, 41.354150390624994],
              [26.536425781250017, 41.343115234375],
              [26.330664062500006, 41.23876953125],
              [26.32568359375, 41.14326171875],
              [26.328417968750017, 41.097021484375],
              [26.332617187500006, 41.064306640625],
              [26.354101562500006, 41.036767578124994],
              [26.354101562500006, 40.9970703125],
              [26.3310546875, 40.954492187499994],
              [26.2412109375, 40.883203125],
              [26.17890625000001, 40.826513671875],
              [26.109179687500017, 40.749658203124994],
              [26.069726562500023, 40.740283203124996],
              [26.038964843750023, 40.7267578125],
              [26.0107421875, 40.769140625],
              [25.85566406250001, 40.844091796875],
              [25.49677734375001, 40.88779296875],
              [25.325292968750006, 40.943115234375],
              [25.250097656250006, 40.9328125],
              [25.1044921875, 40.9947265625],
              [25.004687500000017, 40.967529296875],
              [24.79296875, 40.85751953125],
              [24.6787109375, 40.869482421875],
              [24.556542968750023, 40.935595703124996],
              [24.47705078125, 40.94775390625],
              [24.3837890625, 40.912744140624994],
              [24.234375, 40.7861328125],
              [24.082324218750017, 40.724072265625],
              [23.946093750000017, 40.748339843749996],
              [23.762792968750006, 40.747802734375],
              [23.743261718750006, 40.677001953125],
              [23.778710937500023, 40.627978515624996],
              [23.87890625, 40.544384765625],
              [23.831933593750023, 40.48154296875],
              [23.866796875, 40.4185546875],
              [23.932031250000023, 40.40576171875],
              [24.030566406250017, 40.409326171874994],
              [24.212792968750023, 40.327783203124994],
              [24.29248046875, 40.241796875],
              [24.343359375, 40.147705078125],
              [24.232421875, 40.215185546875],
              [24.158789062500006, 40.280029296875],
              [24.056054687500023, 40.303564453125],
              [23.913183593750006, 40.358789062499994],
              [23.82343750000001, 40.368017578125],
              [23.727929687500023, 40.329736328124994],
              [23.720507812500017, 40.286279296874994],
              [23.82343750000001, 40.205126953124996],
              [23.917578125, 40.155224609375],
              [23.96748046875001, 40.114550781249996],
              [24.000781250000017, 40.024609375],
              [23.981835937500023, 39.994042968749994],
              [23.947070312500017, 39.965576171875],
              [23.835351562500023, 40.022265625],
              [23.66455078125, 40.223828125],
              [23.42626953125, 40.263964843749996],
              [23.38642578125001, 40.221972656249996],
              [23.433203125, 40.1154296875],
              [23.467089843750017, 40.07392578125],
              [23.674121093750017, 39.958886718749994],
              [23.657519531250017, 39.934472656249994],
              [23.627343750000023, 39.924072265625],
              [23.395605468750006, 39.98984375],
              [23.328222656250006, 40.08994140625],
              [23.31201171875, 40.216455078124994],
              [23.09814453125, 40.304296875],
              [22.896484375, 40.39990234375],
              [22.851367187500017, 40.490625],
              [22.892871093750017, 40.524267578125],
              [22.922265625000023, 40.590869140624996],
              [22.811425781250023, 40.57861328125],
              [22.74189453125001, 40.536474609375],
              [22.629492187500006, 40.495556640625],
              [22.624902343750023, 40.428613281249994],
              [22.642675781250006, 40.366601562499994],
              [22.60546875, 40.276416015624996],
              [22.5693359375, 40.1193359375],
              [22.592187500000023, 40.036914062499996],
              [22.835742187500017, 39.800585937499996],
              [22.919042968750006, 39.62890625],
              [22.978808593750017, 39.563818359375],
              [23.103417968750023, 39.492041015625],
              [23.2333984375, 39.358447265624996],
              [23.288476562500023, 39.288818359375],
              [23.327734375, 39.17490234375],
              [23.218359375, 39.104394531249994],
              [23.154687500000023, 39.101464843749994],
              [23.119433593750017, 39.132763671875],
              [23.16875, 39.21044921875],
              [23.16171875, 39.257763671875],
              [22.99287109375001, 39.3310546875],
              [22.92138671875, 39.30634765625],
              [22.838964843750006, 39.25859375],
              [22.886035156250017, 39.169970703124996],
              [22.93896484375, 39.1115234375],
              [22.96552734375001, 39.030908203124994],
              [23.066699218750017, 39.037939453125],
              [22.930468750000017, 38.947705078125],
              [22.802636718750023, 38.901611328125],
              [22.676855468750006, 38.89892578125],
              [22.596777343750006, 38.890576171875],
              [22.569140625000017, 38.867480468749996],
              [22.63427734375, 38.85068359375],
              [22.6875, 38.849169921874996],
              [22.774023437500006, 38.800390625],
              [23.020312500000017, 38.74189453125],
              [23.1376953125, 38.66796875],
              [23.2529296875, 38.66123046875],
              [23.368945312500017, 38.525537109374994],
              [23.569628906250017, 38.489404296874994],
              [23.683984375000023, 38.352441406249994],
              [23.836035156250006, 38.325488281249996],
              [23.96699218750001, 38.275],
              [24.00537109375, 38.226806640625],
              [24.024511718750006, 38.139794921874994],
              [24.033007812500017, 37.955322265625],
              [24.061328125000017, 37.817919921874996],
              [24.062304687500017, 37.77451171875],
              [24.05537109375001, 37.709619140624994],
              [24.01972656250001, 37.677734375],
              [23.971582031250023, 37.6767578125],
              [23.877343750000023, 37.777783203125],
              [23.732812500000023, 37.884082031249996],
              [23.580468750000023, 38.010546875],
              [23.537207031250006, 38.032763671874996],
              [23.501757812500017, 38.03486328125],
              [23.420214843750017, 37.992089843749994],
              [23.193652343750017, 37.959033203124996],
              [23.08740234375, 37.912841796875],
              [23.047460937500006, 37.902636718749996],
              [23.03632812500001, 37.878369140625],
              [23.08613281250001, 37.853125],
              [23.14716796875001, 37.7953125],
              [23.14716796875001, 37.716259765625],
              [23.197558593750017, 37.62021484375],
              [23.2626953125, 37.59541015625],
              [23.347558593750023, 37.597558593749994],
              [23.39619140625001, 37.57978515625],
              [23.408789062500006, 37.541552734374996],
              [23.458105468750006, 37.496923828125],
              [23.490625, 37.4638671875],
              [23.4892578125, 37.440185546875],
              [23.252539062500006, 37.377294921875],
              [23.203027343750023, 37.348535156249994],
              [23.161523437500023, 37.333837890625],
              [23.1, 37.36376953125],
              [23.096484375000017, 37.440576171874994],
              [23.01513671875, 37.481787109375],
              [22.940527343750006, 37.51708984375],
              [22.85107421875, 37.5322265625],
              [22.775, 37.585107421874994],
              [22.725390625000017, 37.542138671874994],
              [22.765039062500023, 37.393310546875],
              [22.85107421875, 37.290820312499996],
              [22.995019531250023, 37.015869140625],
              [23.060351562500017, 36.853515625],
              [23.073535156250017, 36.774951171874996],
              [23.041015625, 36.64453125],
              [23.111718750000023, 36.547607421875],
              [23.16015625, 36.448095703125],
              [23.106835937500023, 36.45185546875],
              [23.060546875, 36.486962890624994],
              [22.98291015625, 36.528369140624996],
              [22.832324218750017, 36.687109375],
              [22.779882812500006, 36.786181640624996],
              [22.717187500000023, 36.7939453125],
              [22.6083984375, 36.779736328125],
              [22.489062500000017, 36.568164062499996],
              [22.48945312500001, 36.446923828124994],
              [22.427734375, 36.47578125],
              [22.374804687500017, 36.513574218749994],
              [22.38125, 36.64619140625],
              [22.3759765625, 36.701904296875],
              [22.23115234375001, 36.882568359375],
              [22.16474609375001, 36.90283203125],
              [22.1337890625, 36.963916015624996],
              [22.080468750000023, 37.028955078124994],
              [22.01171875, 37.016503906249994],
              [21.95556640625, 36.990087890625],
              [21.940039062500006, 36.891796875],
              [21.93427734375001, 36.803662109375],
              [21.892382812500017, 36.7373046875],
              [21.738085937500017, 36.863232421875],
              [21.582910156250023, 37.080957031249994],
              [21.57880859375001, 37.200390625],
              [21.692480468750006, 37.30927734375],
              [21.67890625000001, 37.38720703125],
              [21.5712890625, 37.541015625],
              [21.41621093750001, 37.639941406249996],
              [21.32929687500001, 37.669335937499994],
              [21.288476562500023, 37.77451171875],
              [21.20527343750001, 37.828857421875],
              [21.137988281250017, 37.854150390624994],
              [21.12470703125001, 37.8916015625],
              [21.14501953125, 37.919287109375],
              [21.30810546875, 38.02744140625],
              [21.403710937500023, 38.196679687499994],
              [21.451171875, 38.204736328124994],
              [21.548730468750023, 38.164599609374996],
              [21.65839843750001, 38.175097656249996],
              [21.748437500000023, 38.27421875],
              [21.82470703125, 38.328125],
              [21.95332031250001, 38.321191406249994],
              [22.24375, 38.188720703125],
              [22.55585937500001, 38.113232421875],
              [22.711523437500006, 38.046923828124996],
              [22.799609375000017, 37.981201171875],
              [22.84638671875001, 37.967578125],
              [22.920312500000023, 37.958300781249996],
              [22.9169921875, 38.007470703124994],
              [22.893164062500006, 38.050927734374994],
              [22.95478515625001, 38.074609375],
              [23.1220703125, 38.07333984375],
              [23.15253906250001, 38.09638671875],
              [23.183496093750023, 38.133691406249994],
              [23.14892578125, 38.176074218749996],
              [23.093554687500017, 38.196435546874994],
              [23.034375, 38.202099609375],
              [22.995410156250017, 38.21552734375],
              [22.932519531250023, 38.201953125],
              [22.834375, 38.234716796875],
              [22.78369140625, 38.26171875],
              [22.75390625, 38.289501953125],
              [22.583398437500023, 38.344921875],
              [22.421679687500017, 38.438525390624996],
              [22.38525390625, 38.385546875],
              [22.319921875, 38.356835937499994],
              [22.226855468750017, 38.352832031249996],
              [21.96533203125, 38.412451171875],
              [21.8046875, 38.366943359375],
              [21.717089843750017, 38.355029296874996],
              [21.65009765625001, 38.35400390625],
              [21.567675781250017, 38.33359375],
              [21.472558593750023, 38.32138671875],
              [21.39013671875, 38.4078125],
              [21.35546875, 38.4748046875],
              [21.3310546875, 38.4873046875],
              [21.32978515625001, 38.424365234374996],
              [21.303320312500006, 38.373925781249994],
              [21.1826171875, 38.345556640625],
              [21.113183593750023, 38.384667968749994],
              [21.05976562500001, 38.503271484375],
              [20.9921875, 38.65400390625],
              [20.87324218750001, 38.775732421875],
              [20.77685546875, 38.807519531249994],
              [20.7685546875, 38.874414062499994],
              [20.77734375, 38.927880859374994],
              [20.893164062500006, 38.94111328125],
              [21.07421875, 38.88515625],
              [21.111621093750017, 38.896289062499996],
              [21.15234375, 38.9220703125],
              [21.14453125, 38.97919921875],
              [21.11835937500001, 39.02998046875],
              [21.06855468750001, 39.032275390624996],
              [21.034082031250023, 39.026269531249994],
              [20.922753906250023, 39.036767578124994],
              [20.779687500000023, 39.008544921875],
              [20.71337890625, 39.03515625],
              [20.691308593750023, 39.06748046875],
              [20.571679687500023, 39.147705078125],
              [20.46826171875, 39.255273437499994],
              [20.30078125, 39.327099609375],
              [20.19140625, 39.545800781249994],
              [20.099414062500017, 39.641259765624994],
              [20.001269531250017, 39.709423828125],
              [20.022558593750006, 39.710693359375],
              [20.05976562500001, 39.699121093749994],
              [20.13105468750001, 39.66162109375],
              [20.206835937500017, 39.653515625],
              [20.24824218750001, 39.678369140624994],
              [20.272070312500006, 39.701171875],
              [20.28759765625, 39.738574218749996],
              [20.293847656250023, 39.7822265625],
              [20.30615234375, 39.796679687499996],
              [20.364062500000017, 39.791748046875],
              [20.382421875, 39.802636718749994],
              [20.381640625000017, 39.841796875],
              [20.34423828125, 39.890625],
              [20.311328125000017, 39.95078125],
              [20.311132812500006, 39.979443359375],
              [20.338476562500006, 39.991064453125],
              [20.383691406250023, 40.0171875],
              [20.408007812500017, 40.049462890624994],
              [20.4560546875, 40.065576171874994],
              [20.52705078125001, 40.068505859374994],
              [20.60625, 40.082666015624994],
              [20.65742187500001, 40.1173828125],
              [20.664941406250023, 40.151757812499994],
              [20.69697265625001, 40.246386718749996],
              [20.717871093750006, 40.29267578125],
              [20.75166015625001, 40.334912109375],
              [20.77001953125, 40.391894531249996],
              [20.806054687500023, 40.445458984374994],
              [20.881640625000017, 40.467919921874994],
              [20.9501953125, 40.494384765625],
              [21.001953125, 40.563378906249994],
              [21.030859375, 40.622460937499994],
              [21.031054687500017, 40.658642578125],
              [20.987890625, 40.7177734375],
              [20.95576171875001, 40.775292968749994],
              [20.964257812500023, 40.849902343749996],
              [21.1, 40.85615234375],
              [21.147558593750006, 40.863134765625],
              [21.32373046875, 40.867138671875],
              [21.404101562500017, 40.907177734375],
              [21.45966796875001, 40.903613281249996],
              [21.57578125, 40.868945312499996],
              [21.627539062500006, 40.896337890625],
              [21.77949218750001, 40.950439453125],
              [21.929492187500017, 41.107421875],
              [21.99335937500001, 41.13095703125],
              [22.13886718750001, 41.140527343749994],
              [22.184472656250023, 41.158642578125],
              [22.237695312500023, 41.155175781249994],
              [22.400781250000023, 41.123388671875],
              [22.493554687500023, 41.118505859375],
              [22.603613281250006, 41.140185546874996],
              [22.72480468750001, 41.178515625],
              [22.75507812500001, 41.312744140625],
              [22.78388671875001, 41.331982421875],
              [22.859277343750023, 41.337353515625],
              [22.916015625, 41.336279296875],
              [23.02558593750001, 41.325634765625],
              [23.15595703125001, 41.322070312499996],
              [23.23984375, 41.3849609375],
              [23.3720703125, 41.3896484375],
              [23.433398437500017, 41.398730468749996],
              [23.53583984375001, 41.386035156249996],
              [23.635156250000023, 41.386767578124996],
              [23.762304687500006, 41.412988281249994],
              [23.880859375, 41.455957031249994],
              [23.973535156250023, 41.452294921874994],
              [24.011328125, 41.46005859375],
              [24.03291015625001, 41.469091796875],
              [24.056054687500023, 41.527246093749994],
              [24.230371093750023, 41.530810546874996],
              [24.289453125000023, 41.525048828124994],
              [24.38671875, 41.52353515625],
              [24.487890625, 41.555224609374996],
              [24.51826171875001, 41.552539062499996],
              [24.5693359375, 41.4673828125],
              [24.595996093750017, 41.442724609375],
              [24.65107421875001, 41.419970703124996],
              [24.773730468750017, 41.356103515624994],
              [24.795800781250023, 41.372900390625],
              [24.846875, 41.39423828125],
              [24.993554687500023, 41.364990234375],
              [25.133398437500006, 41.315771484375],
              [25.25117187500001, 41.243554687499994],
              [25.381933593750006, 41.26435546875],
              [25.52705078125001, 41.2998046875],
              [25.621484375000023, 41.310107421874996],
              [25.723925781250017, 41.3150390625],
              [25.784960937500017, 41.330419921875],
              [25.92333984375, 41.311914062499994],
              [26.06640625, 41.35068359375],
              [26.135351562500006, 41.3857421875],
              [26.155175781250023, 41.434863281249996],
              [26.1435546875, 41.521533203124996],
              [26.111230468750023, 41.608203125],
              [26.076953125000017, 41.640185546874996],
              [26.066015625, 41.673242187499994],
              [26.085546875, 41.704150390624996],
              [26.107421875, 41.72568359375],
              [26.200585937500023, 41.743798828124994],
              [26.320898437500006, 41.716552734375],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 4,
        sovereignt: "Austria",
        sov_a3: "AUT",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Austria",
        adm0_a3: "AUT",
        geou_dif: 0,
        geounit: "Austria",
        gu_a3: "AUT",
        su_dif: 0,
        subunit: "Austria",
        su_a3: "AUT",
        brk_diff: 0,
        name: "Austria",
        name_long: "Austria",
        brk_a3: "AUT",
        brk_name: "Austria",
        brk_group: null,
        abbrev: "Aust.",
        postal: "A",
        formal_en: "Republic of Austria",
        formal_fr: null,
        name_ciawf: "Austria",
        note_adm0: null,
        note_brk: null,
        name_sort: "Austria",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 1,
        mapcolor9: 3,
        mapcolor13: 4,
        pop_est: 8877067,
        pop_rank: 13,
        pop_year: 2019,
        gdp_md: 445075,
        gdp_year: 2019,
        economy: "2. Developed region: nonG7",
        income_grp: "1. High income: OECD",
        fips_10: "AU",
        iso_a2: "AT",
        iso_a2_eh: "AT",
        iso_a3: "AUT",
        iso_a3_eh: "AUT",
        iso_n3: "040",
        iso_n3_eh: "040",
        un_a3: "040",
        wb_a2: "AT",
        wb_a3: "AUT",
        woe_id: 23424750,
        woe_id_eh: 23424750,
        woe_note: "Exact WOE match as country",
        adm0_iso: "AUT",
        adm0_diff: null,
        adm0_tlc: "AUT",
        adm0_a3_us: "AUT",
        adm0_a3_fr: "AUT",
        adm0_a3_ru: "AUT",
        adm0_a3_es: "AUT",
        adm0_a3_cn: "AUT",
        adm0_a3_tw: "AUT",
        adm0_a3_in: "AUT",
        adm0_a3_np: "AUT",
        adm0_a3_pk: "AUT",
        adm0_a3_de: "AUT",
        adm0_a3_gb: "AUT",
        adm0_a3_br: "AUT",
        adm0_a3_il: "AUT",
        adm0_a3_ps: "AUT",
        adm0_a3_sa: "AUT",
        adm0_a3_eg: "AUT",
        adm0_a3_ma: "AUT",
        adm0_a3_pt: "AUT",
        adm0_a3_ar: "AUT",
        adm0_a3_jp: "AUT",
        adm0_a3_ko: "AUT",
        adm0_a3_vn: "AUT",
        adm0_a3_tr: "AUT",
        adm0_a3_id: "AUT",
        adm0_a3_pl: "AUT",
        adm0_a3_gr: "AUT",
        adm0_a3_it: "AUT",
        adm0_a3_nl: "AUT",
        adm0_a3_se: "AUT",
        adm0_a3_bd: "AUT",
        adm0_a3_ua: "AUT",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Western Europe",
        region_wb: "Europe & Central Asia",
        name_len: 7,
        long_len: 7,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: 14.130515,
        label_y: 47.518859,
        ne_id: 1159320379,
        wikidataid: "Q40",
        name_ar: "النمسا",
        name_bn: "অস্ট্রিয়া",
        name_de: "Österreich",
        name_en: "Austria",
        name_es: "Austria",
        name_fa: "اتریش",
        name_fr: "Autriche",
        name_el: "Αυστρία",
        name_he: "אוסטריה",
        name_hi: "ऑस्ट्रिया",
        name_hu: "Ausztria",
        name_id: "Austria",
        name_it: "Austria",
        name_ja: "オーストリア",
        name_ko: "오스트리아",
        name_nl: "Oostenrijk",
        name_pl: "Austria",
        name_pt: "Áustria",
        name_ru: "Австрия",
        name_sv: "Österrike",
        name_tr: "Avusturya",
        name_uk: "Австрія",
        name_ur: "آسٹریا",
        name_vi: "Áo",
        name_zh: "奥地利",
        name_zht: "奧地利",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "AUT.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [9.527539062500011, 47.270751953125],
            [9.609082031250011, 47.391796875],
            [9.625878906250023, 47.467041015625],
            [9.554394531250011, 47.511132812499994],
            [9.524023437500006, 47.52421875],
            [9.548925781250006, 47.534033203125],
            [9.650585937500011, 47.52587890625],
            [9.715136718750017, 47.55078125],
            [9.748925781250023, 47.575537109375],
            [9.839160156250017, 47.552294921874996],
            [9.971582031250023, 47.505322265625],
            [10.034082031250023, 47.473583984375],
            [10.059863281250017, 47.449072265625],
            [10.07421875, 47.428515625],
            [10.066308593750023, 47.393359375],
            [10.096484375000017, 47.37958984375],
            [10.158789062500006, 47.374267578125],
            [10.200292968750006, 47.363427734374994],
            [10.185742187500011, 47.3171875],
            [10.183007812500023, 47.27880859375],
            [10.240625, 47.284130859375],
            [10.312792968750017, 47.313427734375],
            [10.369140625, 47.366064453125],
            [10.40390625, 47.4169921875],
            [10.430371093750011, 47.541064453124996],
            [10.439453125, 47.5515625],
            [10.482812500000023, 47.541796875],
            [10.65869140625, 47.547216796875],
            [10.741601562500023, 47.52412109375],
            [10.873046875, 47.52021484375],
            [10.87060546875, 47.50078125],
            [10.893945312500023, 47.470458984375],
            [10.9521484375, 47.426708984375],
            [10.980859375000023, 47.39814453125],
            [11.0419921875, 47.393115234374996],
            [11.136035156250017, 47.40888671875],
            [11.191210937500017, 47.425195312499994],
            [11.2119140625, 47.413623046874996],
            [11.297949218750006, 47.42490234375],
            [11.374121093750006, 47.460253906249996],
            [11.392968750000023, 47.487158203125],
            [11.469921875000011, 47.506103515625],
            [11.573925781250011, 47.549755859375],
            [11.716796875, 47.58349609375],
            [12.185644531250006, 47.61953125],
            [12.203808593750011, 47.646728515625],
            [12.196875, 47.70908203125],
            [12.209277343750017, 47.71826171875],
            [12.268359375000017, 47.702734375],
            [12.363183593750023, 47.68818359375],
            [12.435742187500011, 47.66611328125],
            [12.48291015625, 47.6373046875],
            [12.526562500000011, 47.636132812499994],
            [12.59423828125, 47.656298828124996],
            [12.685839843750017, 47.669335937499994],
            [12.771386718750023, 47.639404296875],
            [12.796191406250017, 47.60703125],
            [12.781152343750023, 47.5904296875],
            [12.7828125, 47.56416015625],
            [12.809375, 47.5421875],
            [12.87890625, 47.5064453125],
            [12.968066406250017, 47.47568359375],
            [13.014355468750011, 47.478076171874996],
            [13.031542968750017, 47.5080078125],
            [13.047949218750006, 47.579150390624996],
            [13.054101562500023, 47.655126953125],
            [13.033593750000023, 47.69873046875],
            [12.985546875000011, 47.709423828125],
            [12.928125, 47.712841796875],
            [12.897656250000011, 47.721875],
            [12.908300781250006, 47.74580078125],
            [12.954199218750006, 47.807763671874994],
            [12.953515625000023, 47.890625],
            [12.849902343750017, 47.984814453125],
            [12.760058593750017, 48.075976562499996],
            [12.760351562500006, 48.106982421874996],
            [12.814257812500017, 48.16083984375],
            [12.8974609375, 48.2037109375],
            [13.082128906250006, 48.27509765625],
            [13.140429687500017, 48.289941406249994],
            [13.215234375000023, 48.301904296874994],
            [13.322851562500006, 48.33125],
            [13.374609375, 48.361376953124996],
            [13.409375, 48.394140625],
            [13.459863281250023, 48.56455078125],
            [13.4716796875, 48.571826171874996],
            [13.486621093750017, 48.581835937499996],
            [13.675195312500023, 48.523046875],
            [13.692187500000017, 48.532763671874996],
            [13.723925781250017, 48.542382812499994],
            [13.785351562500011, 48.587451171874996],
            [13.798828125, 48.6216796875],
            [13.797460937500006, 48.686425781249994],
            [13.802929687500011, 48.747509765625],
            [13.814746093750017, 48.766943359375],
            [13.843164062500023, 48.75986328125],
            [13.92431640625, 48.72802734375],
            [13.98876953125, 48.692431640624996],
            [14.049121093750017, 48.602490234375],
            [14.189843750000023, 48.578564453125],
            [14.367578125000023, 48.576220703124996],
            [14.431054687500023, 48.616259765624996],
            [14.488671875000023, 48.625537109374996],
            [14.553906250000011, 48.613330078124996],
            [14.691308593750023, 48.59921875],
            [14.706640625, 48.671923828124996],
            [14.785937500000017, 48.747363281249996],
            [14.821875, 48.7740234375],
            [14.922558593750011, 48.771386718749994],
            [14.947363281250006, 48.827734375],
            [14.97216796875, 48.983935546874996],
            [14.993457031250017, 49.001123046874994],
            [15.066796875000023, 48.997851562499996],
            [15.139746093750006, 48.9693359375],
            [15.16171875, 48.9462890625],
            [15.199609375000023, 48.948144531249994],
            [15.252734375000017, 48.9638671875],
            [15.310937500000023, 48.974023437499994],
            [15.402929687500006, 48.957373046875],
            [15.599414062500017, 48.886376953124994],
            [15.70078125, 48.86044921875],
            [15.765039062500023, 48.8654296875],
            [15.8251953125, 48.864453125],
            [16.057226562500006, 48.754785156249994],
            [16.219335937500006, 48.739404296874994],
            [16.367285156250006, 48.73896484375],
            [16.414843750000017, 48.7720703125],
            [16.477929687500023, 48.800097656249996],
            [16.543554687500006, 48.796240234375],
            [16.600976562500023, 48.781884765624994],
            [16.712695312500017, 48.734228515625],
            [16.764453125000017, 48.722021484375],
            [16.83320312500001, 48.714306640625],
            [16.883691406250023, 48.7037109375],
            [16.928320312500006, 48.620898437499996],
            [16.953125, 48.598828125],
            [16.948828125, 48.58857421875],
            [16.943359375, 48.550927734374994],
            [16.90449218750001, 48.503515625],
            [16.862695312500023, 48.44140625],
            [16.86542968750001, 48.3869140625],
            [16.97265625, 48.198095703125],
            [17.06787109375, 48.083251953125],
            [17.0859375, 48.03955078125],
            [17.147363281250023, 48.00595703125],
            [17.08906250000001, 47.963623046875],
            [17.077734375, 47.90087890625],
            [17.039941406250023, 47.872949218749994],
            [17.030078125000017, 47.837109375],
            [17.0458984375, 47.804541015625],
            [17.04560546875001, 47.76376953125],
            [17.06660156250001, 47.707568359374996],
            [16.973437500000017, 47.6953125],
            [16.862695312500023, 47.697265625],
            [16.823046875000017, 47.693994140624994],
            [16.785937500000017, 47.678662109375],
            [16.74755859375, 47.686279296875],
            [16.6474609375, 47.739013671875],
            [16.590917968750006, 47.750537109374996],
            [16.55097656250001, 47.747363281249996],
            [16.52109375, 47.724462890625],
            [16.469628906250023, 47.695068359375],
            [16.421289062500023, 47.674462890624994],
            [16.43212890625, 47.656298828124996],
            [16.639746093750006, 47.60888671875],
            [16.676562500000017, 47.536035156249994],
            [16.636621093750023, 47.476611328124996],
            [16.623046875, 47.447558593749996],
            [16.57441406250001, 47.424658203125],
            [16.514746093750006, 47.404541015625],
            [16.44287109375, 47.39951171875],
            [16.434375, 47.367431640625],
            [16.46259765625001, 47.27314453125],
            [16.439746093750017, 47.252734375],
            [16.416894531250023, 47.2234375],
            [16.438378906250023, 47.145898437499994],
            [16.482812500000023, 47.140380859375],
            [16.49267578125, 47.12265625],
            [16.484765625000023, 47.091259765625],
            [16.476953125000023, 47.057861328125],
            [16.461230468750017, 47.0224609375],
            [16.453417968750017, 47.006787109375],
            [16.423925781250006, 46.996972656249994],
            [16.331835937500017, 47.002197265625],
            [16.252539062500006, 46.971923828125],
            [16.093066406250017, 46.86328125],
            [16.037207031250006, 46.84482421875],
            [15.976855468750017, 46.8013671875],
            [15.98046875, 46.705859375],
            [15.972265625, 46.697216796875],
            [15.957617187500006, 46.677636718749994],
            [15.766894531250017, 46.711279296875],
            [15.76025390625, 46.710742187499996],
            [15.632617187500017, 46.6984375],
            [15.545312500000023, 46.654638671875],
            [15.439257812500017, 46.629638671875],
            [15.216992187500011, 46.64296875],
            [15.000683593750011, 46.6259765625],
            [14.949414062500011, 46.613232421875],
            [14.893261718750011, 46.605908203125],
            [14.840625, 46.58046875],
            [14.810546875, 46.544580078124994],
            [14.756738281250023, 46.49912109375],
            [14.68017578125, 46.463427734374996],
            [14.596972656250017, 46.436083984374996],
            [14.5771484375, 46.412939453125],
            [14.5498046875, 46.399707031249996],
            [14.503515625, 46.417041015624996],
            [14.465917968750006, 46.41611328125],
            [14.419921875, 46.4279296875],
            [14.267285156250011, 46.440722656249996],
            [14.099511718750023, 46.4619140625],
            [14.019628906250006, 46.482177734375],
            [13.928808593750006, 46.498193359374994],
            [13.831347656250017, 46.51123046875],
            [13.743945312500017, 46.514306640624994],
            [13.7, 46.520263671875],
            [13.490039062500017, 46.555566406249994],
            [13.3515625, 46.557910156249996],
            [13.16875, 46.57265625],
            [12.805566406250023, 46.625878906249994],
            [12.699804687500006, 46.6474609375],
            [12.5986328125, 46.654101562499996],
            [12.479199218750011, 46.672509765624994],
            [12.38828125, 46.70263671875],
            [12.330078125, 46.759814453124996],
            [12.267968750000023, 46.835888671875],
            [12.154101562500017, 46.93525390625],
            [12.130761718750023, 46.984765625],
            [12.16552734375, 47.028173828125],
            [12.201269531250006, 47.060888671875],
            [12.197167968750023, 47.075],
            [12.16943359375, 47.08212890625],
            [11.969531250000017, 47.039697265624994],
            [11.775683593750017, 46.986083984375],
            [11.699414062500011, 46.984667968749996],
            [11.62548828125, 46.99658203125],
            [11.527539062500011, 46.997412109375],
            [11.433203125, 46.983056640624994],
            [11.244433593750017, 46.97568359375],
            [11.133886718750006, 46.936181640624994],
            [11.0634765625, 46.859130859375],
            [11.025097656250011, 46.79697265625],
            [10.993261718750006, 46.777001953124994],
            [10.92734375, 46.769482421875],
            [10.828906250000017, 46.775244140625],
            [10.759765625, 46.793310546875],
            [10.689257812500017, 46.84638671875],
            [10.579785156250011, 46.8537109375],
            [10.479394531250023, 46.855126953124994],
            [10.452832031250011, 46.86494140625],
            [10.45458984375, 46.8994140625],
            [10.414941406250023, 46.964404296874996],
            [10.349414062500017, 46.984765625],
            [10.179785156250006, 46.862353515624996],
            [10.133496093750011, 46.851513671875],
            [9.996875, 46.8853515625],
            [9.877734375000017, 46.9376953125],
            [9.864648437500023, 46.975976562499994],
            [9.8453125, 47.007373046874996],
            [9.745019531250023, 47.037109375],
            [9.619921875000017, 47.057470703125],
            [9.580273437500011, 47.057373046875],
            [9.595703125, 47.075830078124994],
            [9.610546875000011, 47.10712890625],
            [9.601171875, 47.132080078125],
            [9.571875, 47.15791015625],
            [9.555761718750006, 47.185498046875],
            [9.551074218750017, 47.212255859375],
            [9.542187500000011, 47.234130859375],
            [9.536816406250011, 47.254638671875],
            [9.527539062500011, 47.270751953125],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 5,
        sovereignt: "Republic of Serbia",
        sov_a3: "SRB",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Republic of Serbia",
        adm0_a3: "SRB",
        geou_dif: 0,
        geounit: "Republic of Serbia",
        gu_a3: "SRB",
        su_dif: 0,
        subunit: "Republic of Serbia",
        su_a3: "SRB",
        brk_diff: 0,
        name: "Serbia",
        name_long: "Serbia",
        brk_a3: "SRB",
        brk_name: "Serbia",
        brk_group: null,
        abbrev: "Serb.",
        postal: "RS",
        formal_en: "Republic of Serbia",
        formal_fr: null,
        name_ciawf: "Serbia",
        note_adm0: null,
        note_brk: null,
        name_sort: "Serbia",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 3,
        mapcolor9: 2,
        mapcolor13: 10,
        pop_est: 6944975,
        pop_rank: 13,
        pop_year: 2019,
        gdp_md: 51475,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        fips_10: "RI",
        iso_a2: "RS",
        iso_a2_eh: "RS",
        iso_a3: "SRB",
        iso_a3_eh: "SRB",
        iso_n3: "688",
        iso_n3_eh: "688",
        un_a3: "688",
        wb_a2: "YF",
        wb_a3: "SRB",
        woe_id: -90,
        woe_id_eh: 20069818,
        woe_note: "Expired WOE also contains Kosovo.",
        adm0_iso: "SRB",
        adm0_diff: null,
        adm0_tlc: "SRB",
        adm0_a3_us: "SRB",
        adm0_a3_fr: "SRB",
        adm0_a3_ru: "SRB",
        adm0_a3_es: "SRB",
        adm0_a3_cn: "SRB",
        adm0_a3_tw: "SRB",
        adm0_a3_in: "SRB",
        adm0_a3_np: "SRB",
        adm0_a3_pk: "SRB",
        adm0_a3_de: "SRB",
        adm0_a3_gb: "SRB",
        adm0_a3_br: "SRB",
        adm0_a3_il: "SRB",
        adm0_a3_ps: "SRB",
        adm0_a3_sa: "SRB",
        adm0_a3_eg: "SRB",
        adm0_a3_ma: "SRB",
        adm0_a3_pt: "SRB",
        adm0_a3_ar: "SRB",
        adm0_a3_jp: "SRB",
        adm0_a3_ko: "SRB",
        adm0_a3_vn: "SRB",
        adm0_a3_tr: "SRB",
        adm0_a3_id: "SRB",
        adm0_a3_pl: "SRB",
        adm0_a3_gr: "SRB",
        adm0_a3_it: "SRB",
        adm0_a3_nl: "SRB",
        adm0_a3_se: "SRB",
        adm0_a3_bd: "SRB",
        adm0_a3_ua: "SRB",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Southern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 6,
        long_len: 6,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 7,
        label_x: 20.787989,
        label_y: 44.189919,
        ne_id: 1159321267,
        wikidataid: "Q403",
        name_ar: "صربيا",
        name_bn: "সার্বিয়া",
        name_de: "Serbien",
        name_en: "Serbia",
        name_es: "Serbia",
        name_fa: "صربستان",
        name_fr: "Serbie",
        name_el: "Σερβία",
        name_he: "סרביה",
        name_hi: "सर्बिया",
        name_hu: "Szerbia",
        name_id: "Serbia",
        name_it: "Serbia",
        name_ja: "セルビア",
        name_ko: "세르비아",
        name_nl: "Servië",
        name_pl: "Serbia",
        name_pt: "Sérvia",
        name_ru: "Сербия",
        name_sv: "Serbien",
        name_tr: "Sırbistan",
        name_uk: "Сербія",
        name_ur: "سربیا",
        name_vi: "Serbia",
        name_zh: "塞尔维亚",
        name_zht: "塞爾維亞",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "SRB.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [21.36005859375001, 44.82666015625],
            [21.52314453125001, 44.790087890624996],
            [21.597070312500023, 44.755419921874996],
            [21.636132812500023, 44.71044921875],
            [21.740234375, 44.6806640625],
            [21.909277343750006, 44.66611328125],
            [22.026953125, 44.619873046875],
            [22.093066406250017, 44.541943359375],
            [22.200976562500017, 44.560693359374994],
            [22.350683593750006, 44.676123046875],
            [22.49765625, 44.70625],
            [22.64208984375, 44.6509765625],
            [22.72089843750001, 44.605517578124996],
            [22.734375, 44.569921875],
            [22.70078125, 44.555517578125],
            [22.6201171875, 44.562353515625],
            [22.554003906250017, 44.540332031249996],
            [22.502343750000023, 44.489599609375],
            [22.494531250000023, 44.435449218749994],
            [22.530664062500023, 44.377978515624996],
            [22.581835937500017, 44.338330078125],
            [22.64794921875, 44.316455078124996],
            [22.68330078125001, 44.286474609375],
            [22.687890625000023, 44.248291015625],
            [22.705078125, 44.23779296875],
            [22.66748046875, 44.22021484375],
            [22.6265625, 44.194091796875],
            [22.603417968750023, 44.148583984374994],
            [22.597460937500017, 44.07529296875],
            [22.469042968750017, 44.018017578125],
            [22.420800781250023, 44.007421875],
            [22.399023437500006, 43.96953125],
            [22.36542968750001, 43.862109375],
            [22.36962890625, 43.781298828124996],
            [22.38691406250001, 43.740136718749994],
            [22.394824218750017, 43.706640625],
            [22.436328125000017, 43.665478515625],
            [22.47412109375, 43.60224609375],
            [22.499121093750006, 43.518847656249996],
            [22.554589843750023, 43.454492187499994],
            [22.69697265625001, 43.391064453125],
            [22.767578125, 43.354150390624994],
            [22.819726562500023, 43.300732421875],
            [22.85957031250001, 43.25234375],
            [22.976855468750017, 43.18798828125],
            [22.96796875000001, 43.142041015625],
            [22.942285156250023, 43.097070312499994],
            [22.91523437500001, 43.075976562499996],
            [22.856835937500023, 43.01826171875],
            [22.799902343750006, 42.985742187499994],
            [22.706152343750006, 42.883935546874994],
            [22.55810546875, 42.878466796874996],
            [22.522753906250017, 42.8703125],
            [22.466796875, 42.84248046875],
            [22.439257812500017, 42.791650390624994],
            [22.465625, 42.75078125],
            [22.463281250000023, 42.70947265625],
            [22.43623046875001, 42.6291015625],
            [22.472070312500023, 42.543310546875],
            [22.524218750000017, 42.50390625],
            [22.53242187500001, 42.481201171875],
            [22.523535156250006, 42.440966796874996],
            [22.445703125000023, 42.359130859375],
            [22.42207031250001, 42.328857421875],
            [22.344042968750017, 42.31396484375],
            [22.3173828125, 42.321728515625],
            [22.27705078125001, 42.349853515625],
            [22.23974609375, 42.358154296875],
            [22.14667968750001, 42.325],
            [22.052050781250017, 42.304638671875],
            [21.9775390625, 42.320068359375],
            [21.904101562500017, 42.322070312499996],
            [21.85302734375, 42.308398437499996],
            [21.81464843750001, 42.303125],
            [21.7392578125, 42.267724609374994],
            [21.618261718750006, 42.242138671875],
            [21.5625, 42.247509765625],
            [21.541601562500006, 42.280810546874996],
            [21.518945312500023, 42.328417968749996],
            [21.52998046875001, 42.35],
            [21.60986328125, 42.387451171875],
            [21.619042968750023, 42.423242187499994],
            [21.73066406250001, 42.595458984375],
            [21.75214843750001, 42.651513671874994],
            [21.7529296875, 42.669824218749994],
            [21.72382812500001, 42.681982421875],
            [21.6625, 42.681494140625],
            [21.390625, 42.751416015625],
            [21.40302734375001, 42.83154296875],
            [21.323144531250023, 42.87470703125],
            [21.237109375000017, 42.913232421874994],
            [21.22265625, 42.956201171874994],
            [21.127050781250006, 43.043017578124996],
            [21.057031250000023, 43.091699218749994],
            [20.967675781250023, 43.116015625],
            [20.890722656250006, 43.151660156249996],
            [20.84443359375001, 43.1734375],
            [20.823828125, 43.21396484375],
            [20.823828125, 43.237939453124994],
            [20.800585937500017, 43.261083984375],
            [20.76337890625001, 43.25859375],
            [20.700585937500023, 43.226367187499996],
            [20.623144531250006, 43.198632812499994],
            [20.609667968750017, 43.17841796875],
            [20.637597656250023, 43.13037109375],
            [20.657617187500023, 43.099853515625],
            [20.648535156250006, 43.070947265624994],
            [20.6240234375, 43.0341796875],
            [20.47509765625, 42.953027343749994],
            [20.458398437500023, 42.924560546875],
            [20.48681640625, 42.879052734374994],
            [20.468847656250006, 42.85791015625],
            [20.344335937500006, 42.827929687499996],
            [20.34765625, 42.852783203125],
            [20.339941406250006, 42.892871093749996],
            [20.268457031250023, 42.935449218749994],
            [20.167871093750023, 42.968505859375],
            [19.94404296875001, 43.081640625],
            [19.858007812500006, 43.096533203125],
            [19.781152343750023, 43.109765625],
            [19.670996093750006, 43.163964843749994],
            [19.61445312500001, 43.1734375],
            [19.551562500000017, 43.212255859375],
            [19.414648437500006, 43.342822265624996],
            [19.298242187500023, 43.413964843749994],
            [19.21875, 43.449951171875],
            [19.19648437500001, 43.485009765624994],
            [19.19160156250001, 43.521044921874996],
            [19.1943359375, 43.53330078125],
            [19.254492187500006, 43.584375],
            [19.30078125, 43.591796875],
            [19.3603515625, 43.59345703125],
            [19.39960937500001, 43.567578125],
            [19.451269531250006, 43.562060546874996],
            [19.47998046875, 43.595166015625],
            [19.4951171875, 43.642871093749996],
            [19.488183593750023, 43.703564453125],
            [19.364062500000017, 43.844775390624996],
            [19.257226562500023, 43.943310546875],
            [19.245019531250023, 43.9650390625],
            [19.26806640625, 43.983447265624996],
            [19.305273437500006, 43.993359375],
            [19.34521484375, 43.985107421875],
            [19.44941406250001, 43.97802734375],
            [19.54951171875001, 43.987109375],
            [19.58369140625001, 44.011083984375],
            [19.583789062500017, 44.04345703125],
            [19.547167968750017, 44.073486328125],
            [19.43017578125, 44.1544921875],
            [19.3388671875, 44.225830078125],
            [19.231542968750006, 44.280566406249996],
            [19.15185546875, 44.302539062499996],
            [19.128320312500023, 44.3302734375],
            [19.118457031250017, 44.3599609375],
            [19.127343750000023, 44.41455078125],
            [19.132421875, 44.483789062499994],
            [19.1513671875, 44.52734375],
            [19.22314453125, 44.6095703125],
            [19.291894531250023, 44.69677734375],
            [19.33447265625, 44.780664062499994],
            [19.356835937500023, 44.858544921874994],
            [19.3486328125, 44.880908203124996],
            [19.31269531250001, 44.8974609375],
            [19.23681640625, 44.9142578125],
            [19.13154296875001, 44.899609375],
            [19.042089843750006, 44.871337890625],
            [19.007128906250017, 44.869189453124996],
            [18.995507812500023, 44.90400390625],
            [19.009570312500017, 44.919384765625],
            [19.03759765625, 44.917529296874996],
            [19.060546875, 44.910986328125],
            [19.085253906250017, 44.9267578125],
            [19.1, 44.973779296874994],
            [19.062890625000023, 45.13720703125],
            [19.129687500000017, 45.151708984375],
            [19.130761718750023, 45.17548828125],
            [19.13691406250001, 45.196240234375],
            [19.205957031250023, 45.167773437499996],
            [19.303027343750017, 45.167285156249996],
            [19.388085937500023, 45.172998046874994],
            [19.400976562500006, 45.1890625],
            [19.4, 45.2125],
            [19.38232421875, 45.230615234374994],
            [19.35224609375001, 45.245410156249996],
            [19.33027343750001, 45.26806640625],
            [19.272851562500023, 45.277978515624994],
            [19.093066406250017, 45.3369140625],
            [19.004687500000017, 45.39951171875],
            [19.007617187500017, 45.4658203125],
            [19.033300781250006, 45.502197265625],
            [19.064257812500017, 45.514990234375],
            [19.055078125000023, 45.527246093749994],
            [18.953710937500006, 45.558007812499994],
            [18.917871093750023, 45.600830078125],
            [18.947265625, 45.655810546874996],
            [18.89453125, 45.76708984375],
            [18.83906250000001, 45.835742187499996],
            [18.8935546875, 45.865527343749996],
            [18.90107421875001, 45.907617187499994],
            [18.905371093750006, 45.93173828125],
            [18.927832031250006, 45.931396484375],
            [19.015722656250006, 45.959716796875],
            [19.047656250000017, 45.982666015625],
            [19.066210937500017, 46.009521484375],
            [19.087304687500023, 46.016162109374996],
            [19.146289062500017, 45.98701171875],
            [19.208398437500023, 45.984423828124996],
            [19.278125, 46.002880859375],
            [19.33027343750001, 46.028515625],
            [19.392871093750017, 46.0498046875],
            [19.421289062500023, 46.064453125],
            [19.45751953125, 46.087353515625],
            [19.53076171875, 46.155175781249994],
            [19.61347656250001, 46.169189453125],
            [19.724511718750023, 46.151904296874996],
            [19.84443359375001, 46.145898437499994],
            [19.93408203125, 46.161474609375],
            [20.161425781250017, 46.141894531249996],
            [20.21015625000001, 46.126025390624996],
            [20.241796875, 46.10859375],
            [20.301367187500006, 46.050683593749994],
            [20.35859375000001, 45.975488281249994],
            [20.43798828125, 45.940771484375],
            [20.532617187500023, 45.89951171875],
            [20.581152343750006, 45.869482421875],
            [20.652734375000023, 45.77939453125],
            [20.709277343750017, 45.735253906249994],
            [20.727832031250017, 45.73740234375],
            [20.746875, 45.748974609375],
            [20.760156250000023, 45.758105468749996],
            [20.775, 45.749804687499996],
            [20.775781250000023, 45.722509765625],
            [20.779296875, 45.662011718749994],
            [20.76582031250001, 45.597460937499996],
            [20.786035156250023, 45.536474609375],
            [20.786523437500023, 45.517480468749994],
            [20.7724609375, 45.50009765625],
            [20.774218750000017, 45.484423828124996],
            [20.794042968750006, 45.46787109375],
            [20.87080078125001, 45.427539062499996],
            [20.941796875000023, 45.36533203125],
            [21.023828125000023, 45.321533203125],
            [21.099902343750017, 45.2935546875],
            [21.147851562500023, 45.291748046875],
            [21.226464843750023, 45.24130859375],
            [21.381738281250023, 45.205078125],
            [21.431445312500017, 45.192529296874994],
            [21.465429687500006, 45.171875],
            [21.490234375, 45.147900390625],
            [21.491796875, 45.122265625],
            [21.467871093750006, 45.10986328125],
            [21.434472656250023, 45.075146484375],
            [21.420703125000017, 45.032958984375],
            [21.395898437500023, 45.022216796875],
            [21.37109375, 45.021386718749994],
            [21.352929687500023, 45.008984375],
            [21.35703125, 44.990771484374996],
            [21.377734375000017, 44.9734375],
            [21.409960937500017, 44.95771484375],
            [21.471972656250017, 44.9419921875],
            [21.533203125, 44.918847656249994],
            [21.532324218750006, 44.900683593749996],
            [21.519921875000023, 44.880810546875],
            [21.442187500000017, 44.873388671875],
            [21.384375, 44.870068359375],
            [21.35791015625, 44.86181640625],
            [21.36005859375001, 44.82666015625],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 2,
        sovereignt: "Italy",
        sov_a3: "ITA",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Italy",
        adm0_a3: "ITA",
        geou_dif: 0,
        geounit: "Italy",
        gu_a3: "ITA",
        su_dif: 0,
        subunit: "Italy",
        su_a3: "ITA",
        brk_diff: 0,
        name: "Italy",
        name_long: "Italy",
        brk_a3: "ITA",
        brk_name: "Italy",
        brk_group: null,
        abbrev: "Italy",
        postal: "I",
        formal_en: "Italian Republic",
        formal_fr: null,
        name_ciawf: "Italy",
        note_adm0: null,
        note_brk: null,
        name_sort: "Italy",
        name_alt: null,
        mapcolor7: 6,
        mapcolor8: 7,
        mapcolor9: 8,
        mapcolor13: 7,
        pop_est: 60297396,
        pop_rank: 16,
        pop_year: 2019,
        gdp_md: 2003576,
        gdp_year: 2019,
        economy: "1. Developed region: G7",
        income_grp: "1. High income: OECD",
        fips_10: "IT",
        iso_a2: "IT",
        iso_a2_eh: "IT",
        iso_a3: "ITA",
        iso_a3_eh: "ITA",
        iso_n3: "380",
        iso_n3_eh: "380",
        un_a3: "380",
        wb_a2: "IT",
        wb_a3: "ITA",
        woe_id: 23424853,
        woe_id_eh: 23424853,
        woe_note: "Exact WOE match as country",
        adm0_iso: "ITA",
        adm0_diff: null,
        adm0_tlc: "ITA",
        adm0_a3_us: "ITA",
        adm0_a3_fr: "ITA",
        adm0_a3_ru: "ITA",
        adm0_a3_es: "ITA",
        adm0_a3_cn: "ITA",
        adm0_a3_tw: "ITA",
        adm0_a3_in: "ITA",
        adm0_a3_np: "ITA",
        adm0_a3_pk: "ITA",
        adm0_a3_de: "ITA",
        adm0_a3_gb: "ITA",
        adm0_a3_br: "ITA",
        adm0_a3_il: "ITA",
        adm0_a3_ps: "ITA",
        adm0_a3_sa: "ITA",
        adm0_a3_eg: "ITA",
        adm0_a3_ma: "ITA",
        adm0_a3_pt: "ITA",
        adm0_a3_ar: "ITA",
        adm0_a3_jp: "ITA",
        adm0_a3_ko: "ITA",
        adm0_a3_vn: "ITA",
        adm0_a3_tr: "ITA",
        adm0_a3_id: "ITA",
        adm0_a3_pl: "ITA",
        adm0_a3_gr: "ITA",
        adm0_a3_it: "ITA",
        adm0_a3_nl: "ITA",
        adm0_a3_se: "ITA",
        adm0_a3_bd: "ITA",
        adm0_a3_ua: "ITA",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Southern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 5,
        long_len: 5,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 2,
        max_label: 7,
        label_x: 11.076907,
        label_y: 44.732482,
        ne_id: 1159320919,
        wikidataid: "Q38",
        name_ar: "إيطاليا",
        name_bn: "ইতালি",
        name_de: "Italien",
        name_en: "Italy",
        name_es: "Italia",
        name_fa: "ایتالیا",
        name_fr: "Italie",
        name_el: "Ιταλία",
        name_he: "איטליה",
        name_hi: "इटली",
        name_hu: "Olaszország",
        name_id: "Italia",
        name_it: "Italia",
        name_ja: "イタリア",
        name_ko: "이탈리아",
        name_nl: "Italië",
        name_pl: "Włochy",
        name_pt: "Itália",
        name_ru: "Италия",
        name_sv: "Italien",
        name_tr: "İtalya",
        name_uk: "Італія",
        name_ur: "اطالیہ",
        name_vi: "Ý",
        name_zh: "意大利",
        name_zht: "義大利",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "ITA.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [7.02109375, 45.92578125],
              [7.055761718750006, 45.90380859375],
              [7.129003906250006, 45.880419921874996],
              [7.327929687500017, 45.912353515625],
              [7.451562500000023, 45.94443359375],
              [7.53857421875, 45.978173828124994],
              [7.592578125000017, 45.972216796874996],
              [7.787890625000017, 45.921826171875],
              [7.852343750000017, 45.9474609375],
              [7.9931640625, 46.015917968749996],
              [8.014257812500006, 46.051904296874994],
              [8.125195312500011, 46.1609375],
              [8.127246093750017, 46.18759765625],
              [8.08154296875, 46.256005859374994],
              [8.095703125, 46.271044921874996],
              [8.23193359375, 46.341210937499994],
              [8.298535156250011, 46.40341796875],
              [8.370703125, 46.445117187499996],
              [8.422558593750011, 46.446044921875],
              [8.436816406250017, 46.431884765625],
              [8.44296875, 46.402783203125],
              [8.4384765625, 46.282861328124994],
              [8.458398437500023, 46.245898437499996],
              [8.5654296875, 46.159814453124994],
              [8.641699218750006, 46.110791015625],
              [8.818554687500011, 46.0771484375],
              [8.826757812500006, 46.06103515625],
              [8.778027343750011, 45.99619140625],
              [8.885156250000023, 45.918701171875],
              [8.904296875, 45.861962890624994],
              [8.953710937500006, 45.830029296875],
              [9.023730468750017, 45.845703125],
              [9.046679687500017, 45.8755859375],
              [9.019140625, 45.928125],
              [8.998925781250023, 45.98310546875],
              [9.003027343750006, 46.014892578125],
              [9.022363281250023, 46.05146484375],
              [9.070996093750011, 46.102441406249994],
              [9.203417968750017, 46.21923828125],
              [9.251074218750006, 46.286767578124994],
              [9.259765625, 46.391259765624994],
              [9.260156250000023, 46.4751953125],
              [9.304394531250011, 46.495556640625],
              [9.399316406250023, 46.4806640625],
              [9.427636718750023, 46.482324218749994],
              [9.440625, 46.430810546874994],
              [9.481054687500006, 46.348779296874994],
              [9.528710937500023, 46.306201171874996],
              [9.57958984375, 46.29609375],
              [9.639453125000017, 46.2958984375],
              [9.787792968750011, 46.346044921875],
              [9.884472656250011, 46.3677734375],
              [9.939257812500017, 46.36181640625],
              [9.9716796875, 46.327685546874996],
              [10.041015625, 46.238085937499996],
              [10.08056640625, 46.227978515625],
              [10.128320312500023, 46.238232421875],
              [10.145214843750011, 46.253515625],
              [10.1298828125, 46.287988281249994],
              [10.109667968750017, 46.362841796874996],
              [10.081933593750023, 46.420751953125],
              [10.045605468750011, 46.447900390624994],
              [10.038281250000011, 46.483203125],
              [10.061230468750011, 46.546777343749994],
              [10.087011718750006, 46.599902343749996],
              [10.1375, 46.61435546875],
              [10.195507812500011, 46.62109375],
              [10.272265625000017, 46.56484375],
              [10.363085937500017, 46.5470703125],
              [10.4306640625, 46.550048828125],
              [10.442480468750006, 46.582861328125],
              [10.438281250000017, 46.61884765625],
              [10.39794921875, 46.6650390625],
              [10.406054687500017, 46.73486328125],
              [10.452832031250011, 46.86494140625],
              [10.479394531250023, 46.855126953124994],
              [10.579785156250011, 46.8537109375],
              [10.689257812500017, 46.84638671875],
              [10.759765625, 46.793310546875],
              [10.828906250000017, 46.775244140625],
              [10.92734375, 46.769482421875],
              [10.993261718750006, 46.777001953124994],
              [11.025097656250011, 46.79697265625],
              [11.0634765625, 46.859130859375],
              [11.133886718750006, 46.936181640624994],
              [11.244433593750017, 46.97568359375],
              [11.433203125, 46.983056640624994],
              [11.527539062500011, 46.997412109375],
              [11.62548828125, 46.99658203125],
              [11.699414062500011, 46.984667968749996],
              [11.775683593750017, 46.986083984375],
              [11.969531250000017, 47.039697265624994],
              [12.16943359375, 47.08212890625],
              [12.197167968750023, 47.075],
              [12.201269531250006, 47.060888671875],
              [12.16552734375, 47.028173828125],
              [12.130761718750023, 46.984765625],
              [12.154101562500017, 46.93525390625],
              [12.267968750000023, 46.835888671875],
              [12.330078125, 46.759814453124996],
              [12.38828125, 46.70263671875],
              [12.479199218750011, 46.672509765624994],
              [12.5986328125, 46.654101562499996],
              [12.699804687500006, 46.6474609375],
              [12.805566406250023, 46.625878906249994],
              [13.16875, 46.57265625],
              [13.3515625, 46.557910156249996],
              [13.490039062500017, 46.555566406249994],
              [13.7, 46.520263671875],
              [13.6796875, 46.462890625],
              [13.637109375000023, 46.448535156249996],
              [13.563281250000017, 46.415087890624996],
              [13.478515625, 46.369189453124996],
              [13.399511718750006, 46.317529296874994],
              [13.378222656250017, 46.261621093749994],
              [13.399609375000011, 46.224951171875],
              [13.420996093750006, 46.212304687499994],
              [13.449804687500006, 46.223535156249994],
              [13.491796875, 46.216601562499996],
              [13.544726562500017, 46.196582031249996],
              [13.632519531250011, 46.177050781249996],
              [13.634960937500011, 46.157763671874996],
              [13.616601562500023, 46.133105468749996],
              [13.548046875000011, 46.089111328125],
              [13.486425781250006, 46.03955078125],
              [13.480273437500017, 46.009228515625],
              [13.487695312500023, 45.987109375],
              [13.509179687500023, 45.973779296874994],
              [13.6005859375, 45.979785156249996],
              [13.613964843750011, 45.961669921875],
              [13.569628906250017, 45.834130859374994],
              [13.583398437500023, 45.812353515625],
              [13.663476562500023, 45.7919921875],
              [13.7216796875, 45.761279296874996],
              [13.831152343750006, 45.680419921875],
              [13.874707031250011, 45.61484375],
              [13.8447265625, 45.59287109375],
              [13.775976562500006, 45.581982421875],
              [13.719824218750006, 45.58759765625],
              [13.783300781250006, 45.627246093749996],
              [13.628320312500023, 45.770947265625],
              [13.558203125, 45.770703125],
              [13.465136718750017, 45.7099609375],
              [13.206347656250017, 45.771386718749994],
              [13.15673828125, 45.74658203125],
              [13.1201171875, 45.697900390624994],
              [13.0302734375, 45.6375],
              [12.903027343750011, 45.610791015625],
              [12.76123046875, 45.544287109375],
              [12.611718750000023, 45.497216796874994],
              [12.49755859375, 45.461669921875],
              [12.43212890625, 45.467919921874994],
              [12.5361328125, 45.544921875],
              [12.491796875, 45.546289062499994],
              [12.353808593750017, 45.491992187499996],
              [12.274316406250023, 45.446044921875],
              [12.248828125000017, 45.36884765625],
              [12.225683593750006, 45.241503906249996],
              [12.286328125000011, 45.20771484375],
              [12.392480468750023, 45.039794921875],
              [12.5234375, 44.96796875],
              [12.497949218750023, 44.8994140625],
              [12.463574218750011, 44.84521484375],
              [12.384472656250011, 44.79833984375],
              [12.319042968750011, 44.83310546875],
              [12.27890625, 44.8322265625],
              [12.248339843750017, 44.722509765625],
              [12.304980468750017, 44.429443359375],
              [12.396289062500017, 44.223876953125],
              [12.48681640625, 44.134228515625],
              [12.691113281250011, 43.9947265625],
              [12.907031250000017, 43.921191406249996],
              [13.295312500000023, 43.686083984374996],
              [13.508203125000023, 43.611669921875],
              [13.564160156250011, 43.5712890625],
              [13.693261718750023, 43.389892578125],
              [13.8046875, 43.18037109375],
              [13.924902343750006, 42.8515625],
              [14.010449218750011, 42.68955078125],
              [14.182714843750006, 42.5064453125],
              [14.540722656250011, 42.244287109374994],
              [14.866113281250023, 42.052539062499996],
              [15.16875, 41.934033203125],
              [15.404980468750011, 41.913232421874994],
              [15.964062500000011, 41.939453125],
              [16.0615234375, 41.928125],
              [16.164648437500006, 41.89619140625],
              [16.18916015625001, 41.814013671874996],
              [16.151269531250023, 41.75849609375],
              [16.03369140625, 41.70078125],
              [15.913769531250011, 41.620849609375],
              [15.900488281250006, 41.512060546875],
              [16.012597656250023, 41.435400390625],
              [16.551855468750006, 41.23203125],
              [17.103417968750023, 41.062158203124994],
              [17.275195312500017, 40.975439453125],
              [17.47421875, 40.840576171875],
              [17.954980468750023, 40.655175781249994],
              [18.0361328125, 40.56494140625],
              [18.328222656250006, 40.370849609375],
              [18.46064453125001, 40.221044921875],
              [18.48583984375, 40.104833984375],
              [18.42255859375001, 39.986865234374996],
              [18.393457031250023, 39.903613281249996],
              [18.34375, 39.82138671875],
              [18.219335937500006, 39.8525390625],
              [18.077929687500017, 39.936962890625],
              [17.865039062500017, 40.280175781249994],
              [17.476171875, 40.31494140625],
              [17.395800781250017, 40.340234375],
              [17.257714843750023, 40.399072265624994],
              [17.249414062500023, 40.437890625],
              [17.21533203125, 40.48642578125],
              [17.179980468750017, 40.502783203125],
              [17.03125, 40.513476562499996],
              [16.92822265625, 40.458056640624996],
              [16.807031250000023, 40.326464843749996],
              [16.66962890625001, 40.13720703125],
              [16.52998046875001, 39.859667968749996],
              [16.521875, 39.74755859375],
              [16.597753906250006, 39.638916015625],
              [16.824316406250006, 39.5783203125],
              [16.99921875000001, 39.481591796874994],
              [17.114550781250017, 39.380615234375],
              [17.122949218750023, 39.136572265625],
              [17.174609375000017, 38.998095703124996],
              [17.098535156250023, 38.919335937499994],
              [16.951464843750017, 38.939794921875],
              [16.75546875, 38.889697265624996],
              [16.61669921875, 38.800146484375],
              [16.558984375000023, 38.714794921875],
              [16.57421875, 38.493554687499994],
              [16.54560546875001, 38.409082031249994],
              [16.28242187500001, 38.249560546874996],
              [16.144140625, 38.086376953125],
              [16.109765625000023, 38.01865234375],
              [16.05683593750001, 37.941845703125],
              [15.724511718750023, 37.939111328124994],
              [15.645800781250017, 38.034228515624996],
              [15.64306640625, 38.175390625],
              [15.7001953125, 38.2623046875],
              [15.822363281250006, 38.302978515625],
              [15.90478515625, 38.48349609375],
              [15.87890625, 38.613916015624994],
              [15.926953125000011, 38.671728515625],
              [15.972363281250011, 38.71259765625],
              [16.065527343750006, 38.73642578125],
              [16.19677734375, 38.759228515625],
              [16.2099609375, 38.94111328125],
              [16.107421875, 39.023828125],
              [16.07148437500001, 39.139453125],
              [16.02363281250001, 39.35361328125],
              [15.854394531250023, 39.626513671874996],
              [15.763671875, 39.870068359375],
              [15.692773437500023, 39.990185546875],
              [15.585156250000011, 40.05283203125],
              [15.390917968750017, 40.052148437499994],
              [15.29453125, 40.07001953125],
              [14.950878906250011, 40.239013671875],
              [14.926953125000011, 40.26474609375],
              [14.929101562500023, 40.3095703125],
              [14.986132812500017, 40.377490234374996],
              [14.947656250000023, 40.4693359375],
              [14.906933593750011, 40.556054687499994],
              [14.839550781250011, 40.62998046875],
              [14.765722656250006, 40.668408203125],
              [14.611230468750023, 40.644775390625],
              [14.556933593750017, 40.626416015625],
              [14.459375, 40.632714843749994],
              [14.382714843750023, 40.599853515625],
              [14.339941406250006, 40.598828125],
              [14.460546875, 40.7287109375],
              [14.428125, 40.759326171874996],
              [14.308886718750017, 40.812646484374994],
              [14.147167968750011, 40.820703125],
              [14.102343750000017, 40.8271484375],
              [14.075878906250011, 40.7939453125],
              [14.044335937500023, 40.812255859375],
              [14.047656250000017, 40.8703125],
              [13.859765625000023, 41.12998046875],
              [13.7333984375, 41.235644531249996],
              [13.669726562500017, 41.2544921875],
              [13.554785156250006, 41.232177734375],
              [13.361914062500006, 41.278515625],
              [13.246875, 41.288867187499996],
              [13.183398437500017, 41.277685546875],
              [13.088671875000017, 41.24384765625],
              [13.041015625, 41.2662109375],
              [13.024218750000017, 41.300927734374994],
              [12.84921875, 41.408740234374996],
              [12.630859375, 41.469677734375],
              [12.205664062500006, 41.812646484374994],
              [12.075292968750006, 41.940869140625],
              [11.807031250000023, 42.08203125],
              [11.637304687500006, 42.287548828125],
              [11.498437500000023, 42.362939453124994],
              [11.296289062500023, 42.423291015625],
              [11.249707031250011, 42.41572265625],
              [11.188867187500023, 42.393115234374996],
              [11.141210937500006, 42.389892578125],
              [11.103222656250011, 42.4166015625],
              [11.141796875000011, 42.444091796875],
              [11.184765625000011, 42.456591796874996],
              [11.167773437500017, 42.53515625],
              [10.937792968750017, 42.738720703125],
              [10.803125, 42.804296875],
              [10.76513671875, 42.844677734375],
              [10.737109375000017, 42.899951171874996],
              [10.708398437500023, 42.936328125],
              [10.644628906250006, 42.957177734374994],
              [10.590234375000023, 42.95361328125],
              [10.514843750000011, 42.967529296875],
              [10.517285156250011, 43.06513671875],
              [10.532324218750006, 43.14013671875],
              [10.520800781250017, 43.20380859375],
              [10.447558593750017, 43.37119140625],
              [10.320507812500011, 43.513085937499994],
              [10.245800781250011, 43.852099609374996],
              [10.188085937500006, 43.947509765625],
              [10.047656250000017, 44.019970703125],
              [9.730859375000023, 44.101171875],
              [9.289355468750017, 44.319238281249994],
              [9.195996093750011, 44.322998046875],
              [8.930371093750011, 44.407763671874996],
              [8.765820312500011, 44.422314453125],
              [8.551953125000011, 44.346142578125],
              [8.292382812500023, 44.136523437499996],
              [8.081640625, 43.9189453125],
              [8.004980468750006, 43.876757812499996],
              [7.733300781250023, 43.802587890625],
              [7.4931640625, 43.767138671874996],
              [7.490527343750017, 43.82294921875],
              [7.48203125, 43.864892578124994],
              [7.522656250000011, 43.911083984375],
              [7.589648437500017, 43.9654296875],
              [7.651464843750006, 44.033642578125],
              [7.677148437500023, 44.083154296874994],
              [7.6650390625, 44.116015625],
              [7.63720703125, 44.16484375],
              [7.599414062500017, 44.168359375],
              [7.370898437500017, 44.127392578125],
              [7.318554687500011, 44.137988281249996],
              [7.1494140625, 44.201708984374996],
              [6.96728515625, 44.280029296875],
              [6.900195312500017, 44.335742187499996],
              [6.874804687500017, 44.392041015625],
              [6.893847656250017, 44.428173828125],
              [6.878613281250011, 44.46328125],
              [6.842968750000011, 44.510693359375],
              [6.875195312500011, 44.56455078125],
              [6.931933593750017, 44.631640625],
              [6.960351562500023, 44.677148437499994],
              [7.007910156250006, 44.68896484375],
              [7.030664062500023, 44.716699218749994],
              [6.99267578125, 44.827294921874994],
              [6.972851562500011, 44.845019531249996],
              [6.939843750000023, 44.858740234375],
              [6.889355468750011, 44.860302734375],
              [6.801074218750017, 44.883154296875],
              [6.738183593750023, 44.92138671875],
              [6.724707031250006, 44.972998046875],
              [6.69140625, 45.022607421874994],
              [6.634765625, 45.068164062499996],
              [6.627734375000017, 45.11796875],
              [6.692285156250023, 45.144287109375],
              [6.780371093750006, 45.1453125],
              [6.84228515625, 45.135644531249994],
              [6.98125, 45.215576171875],
              [7.032421875000011, 45.222607421875],
              [7.078320312500011, 45.23994140625],
              [7.116796875, 45.349023437499994],
              [7.146386718750023, 45.381738281249994],
              [7.153417968750006, 45.400927734374996],
              [7.126074218750006, 45.423681640625],
              [7.013671875, 45.50048828125],
              [6.96240234375, 45.58056640625],
              [6.881445312500006, 45.670361328125],
              [6.80625, 45.710009765624996],
              [6.790917968750023, 45.740869140624994],
              [6.789160156250006, 45.780078125],
              [6.804492187500017, 45.81455078125],
              [6.940820312500023, 45.868359375],
              [7.02109375, 45.92578125],
            ],
            [
              [12.439160156250011, 41.898388671875],
              [12.438378906250023, 41.906201171875],
              [12.430566406250023, 41.90546875],
              [12.427539062500017, 41.900732421875],
              [12.430566406250023, 41.89755859375],
              [12.439160156250011, 41.898388671875],
            ],
            [
              [12.485253906250023, 43.901416015624996],
              [12.5146484375, 43.952978515625],
              [12.503710937500017, 43.98974609375],
              [12.441113281250011, 43.982421875],
              [12.396875, 43.9345703125],
              [12.426367187500006, 43.894091796874996],
              [12.485253906250023, 43.901416015624996],
            ],
          ],
          [
            [
              [10.395117187500006, 42.858154296875],
              [10.428320312500006, 42.819189453125],
              [10.432226562500006, 42.79658203125],
              [10.409960937500017, 42.77099609375],
              [10.419335937500023, 42.713183593749996],
              [10.335644531250011, 42.761132812499994],
              [10.208984375, 42.7369140625],
              [10.13125, 42.742041015625],
              [10.109765625000023, 42.785058593749994],
              [10.127539062500006, 42.810302734375],
              [10.248242187500011, 42.815771484375],
              [10.285742187500006, 42.828076171875],
              [10.358984375, 42.822314453124996],
              [10.395117187500006, 42.858154296875],
            ],
          ],
          [
            [
              [13.938281250000017, 40.705615234374996],
              [13.893652343750006, 40.69697265625],
              [13.86767578125, 40.708740234375],
              [13.853515625, 40.724072265625],
              [13.871191406250006, 40.76181640625],
              [13.962109375000011, 40.739404296874994],
              [13.960839843750023, 40.718164062499994],
              [13.938281250000017, 40.705615234374996],
            ],
          ],
          [
            [
              [12.05126953125, 36.75703125],
              [12.003320312500023, 36.745996093749994],
              [11.940625, 36.78037109375],
              [11.936425781250023, 36.82861328125],
              [11.948046875000017, 36.843066406249996],
              [12.024218750000017, 36.820947265624994],
              [12.048046875000011, 36.7763671875],
              [12.05126953125, 36.75703125],
            ],
          ],
          [
            [
              [15.576562500000023, 38.2203125],
              [15.508886718750006, 38.106640625],
              [15.475683593750006, 38.062939453125],
              [15.234472656250006, 37.784814453124994],
              [15.206835937500017, 37.720556640625],
              [15.189843750000023, 37.650732421875],
              [15.164843750000017, 37.58955078125],
              [15.131054687500011, 37.531884765624994],
              [15.099511718750023, 37.45859375],
              [15.105664062500011, 37.37548828125],
              [15.116992187500017, 37.334716796875],
              [15.14599609375, 37.308007812499994],
              [15.193652343750017, 37.282861328124994],
              [15.230273437500017, 37.2443359375],
              [15.174121093750017, 37.2091796875],
              [15.236035156250011, 37.138720703124996],
              [15.288671875, 37.096923828125],
              [15.295703125000017, 37.05517578125],
              [15.29453125, 37.01328125],
              [15.18515625, 36.934814453125],
              [15.142382812500017, 36.8916015625],
              [15.115820312500006, 36.839257812499994],
              [15.104296875000017, 36.78525390625],
              [15.116308593750006, 36.736474609374994],
              [15.112597656250017, 36.687841796875],
              [15.00244140625, 36.693896484374996],
              [14.8896484375, 36.723535156249994],
              [14.775976562500006, 36.710400390625],
              [14.614355468750006, 36.7666015625],
              [14.555468750000017, 36.776757812499994],
              [14.501855468750023, 36.798681640625],
              [14.367285156250006, 36.9728515625],
              [14.259082031250017, 37.046435546874996],
              [14.142968750000023, 37.103662109374994],
              [14.024316406250023, 37.10712890625],
              [13.905468750000011, 37.100634765624996],
              [13.800585937500017, 37.135888671874994],
              [13.587109375000011, 37.254150390625],
              [13.3609375, 37.34873046875],
              [13.264941406250017, 37.4103515625],
              [13.221093750000023, 37.451806640624994],
              [13.169921875, 37.479296875],
              [13.040332031250017, 37.50654296875],
              [12.924121093750017, 37.5705078125],
              [12.871191406250006, 37.5751953125],
              [12.75732421875, 37.5673828125],
              [12.699023437500017, 37.571826171874996],
              [12.640234375, 37.5943359375],
              [12.526757812500023, 37.66953125],
              [12.454394531250017, 37.773779296875],
              [12.435546875, 37.819775390625],
              [12.48681640625, 37.938720703125],
              [12.547656250000017, 38.0529296875],
              [12.601660156250006, 38.0849609375],
              [12.664355468750017, 38.10791015625],
              [12.702343750000011, 38.14169921875],
              [12.734375, 38.183056640625],
              [12.850683593750006, 38.063720703125],
              [12.902734375000023, 38.03486328125],
              [12.955468750000023, 38.041308593749996],
              [13.049023437500011, 38.08408203125],
              [13.056835937500011, 38.130908203124996],
              [13.159960937500017, 38.190332031249994],
              [13.291113281250006, 38.191455078124996],
              [13.351660156250006, 38.180517578125],
              [13.383496093750011, 38.126806640625],
              [13.433496093750023, 38.110253906249994],
              [13.491308593750006, 38.103125],
              [13.681542968750023, 38.000732421875],
              [13.73486328125, 37.984033203124994],
              [13.788867187500017, 37.981201171875],
              [13.936621093750006, 38.024169921875],
              [14.05, 38.04052734375],
              [14.287695312500006, 38.016845703125],
              [14.416210937500011, 38.042578125],
              [14.505957031250006, 38.0455078125],
              [14.63671875, 38.08505859375],
              [14.737207031250023, 38.15078125],
              [14.789648437500006, 38.1669921875],
              [14.845898437500011, 38.171679687499996],
              [14.98193359375, 38.167578125],
              [15.11875, 38.152734375],
              [15.176074218750017, 38.16806640625],
              [15.224023437500023, 38.21103515625],
              [15.279589843750017, 38.230371093749994],
              [15.340722656250023, 38.217333984374996],
              [15.498730468750011, 38.290869140625],
              [15.568359375, 38.2958984375],
              [15.634667968750023, 38.267578125],
              [15.576562500000023, 38.2203125],
            ],
          ],
          [
            [
              [9.632031250000011, 40.88203125],
              [9.682031250000023, 40.818115234375],
              [9.794335937500023, 40.556201171874996],
              [9.805273437500006, 40.499560546874996],
              [9.7828125, 40.44150390625],
              [9.754199218750017, 40.400292968749994],
              [9.642968750000023, 40.268408203125],
              [9.659472656250017, 40.159228515624996],
              [9.70078125, 40.091796875],
              [9.706738281250011, 40.017041015625],
              [9.68603515625, 39.924365234374996],
              [9.616992187500017, 39.354394531249994],
              [9.58359375, 39.253564453124994],
              [9.5625, 39.166015625],
              [9.486328125, 39.139550781249994],
              [9.388085937500023, 39.167529296874996],
              [9.26416015625, 39.216796875],
              [9.206933593750023, 39.213818359375],
              [9.149316406250023, 39.19697265625],
              [9.101757812500011, 39.211279296875],
              [9.056347656250011, 39.239160156249994],
              [9.022656250000011, 39.043261718749996],
              [8.966601562500017, 38.963720703125],
              [8.88134765625, 38.912890625],
              [8.801171875000023, 38.90966796875],
              [8.718554687500017, 38.926708984375],
              [8.648535156250006, 38.9265625],
              [8.595410156250011, 38.964306640625],
              [8.553320312500006, 39.030322265624996],
              [8.486230468750023, 39.110498046874994],
              [8.418164062500011, 39.205712890624994],
              [8.410742187500006, 39.291796875],
              [8.399121093750011, 39.481591796874994],
              [8.418652343750011, 39.523046875],
              [8.447070312500017, 39.562792968749996],
              [8.461035156250006, 39.647705078125],
              [8.451171875, 39.7216796875],
              [8.471093750000023, 39.748095703124996],
              [8.5107421875, 39.7216796875],
              [8.54052734375, 39.731591796874994],
              [8.538671875, 39.769677734374994],
              [8.547753906250023, 39.839208984375],
              [8.495898437500017, 39.8974609375],
              [8.4078125, 39.917236328125],
              [8.399316406250023, 39.978173828124994],
              [8.408593750000023, 40.037646484374996],
              [8.455078125, 40.077587890625],
              [8.470800781250006, 40.130712890625],
              [8.471289062500006, 40.29267578125],
              [8.4091796875, 40.35234375],
              [8.385351562500006, 40.442675781249996],
              [8.353222656250011, 40.500537109374996],
              [8.295507812500006, 40.558642578124996],
              [8.230273437500017, 40.60595703125],
              [8.18994140625, 40.651611328125],
              [8.180859375000011, 40.771044921874996],
              [8.203808593750011, 40.870703125],
              [8.22421875, 40.913330078125],
              [8.245214843750006, 40.90703125],
              [8.31015625, 40.85751953125],
              [8.36328125, 40.846337890624994],
              [8.468457031250011, 40.834326171875],
              [8.571875, 40.8501953125],
              [8.698925781250011, 40.895263671875],
              [8.821191406250023, 40.94990234375],
              [8.998144531250006, 41.1103515625],
              [9.107226562500017, 41.142919921875],
              [9.1630859375, 41.18515625],
              [9.18212890625, 41.2421875],
              [9.228417968750023, 41.257080078125],
              [9.283007812500017, 41.20166015625],
              [9.350781250000011, 41.1958984375],
              [9.455175781250006, 41.150146484375],
              [9.500195312500011, 41.106347656249994],
              [9.538769531250011, 41.053662109375],
              [9.57568359375, 41.030517578125],
              [9.615332031250006, 41.01728515625],
              [9.621191406250006, 41.0048828125],
              [9.589746093750023, 40.992480468749996],
              [9.5537109375, 40.93212890625],
              [9.574023437500017, 40.91474609375],
              [9.632031250000011, 40.88203125],
            ],
          ],
          [
            [
              [8.478906250000023, 39.067529296874994],
              [8.421484375, 38.968652343749994],
              [8.3609375, 39.038671875],
              [8.358593750000011, 39.098779296874994],
              [8.366796875, 39.11591796875],
              [8.440625, 39.090625],
              [8.478906250000023, 39.067529296874994],
            ],
          ],
          [
            [
              [8.286035156250023, 41.03984375],
              [8.252734375000017, 40.994140625],
              [8.205664062500006, 40.997460937499994],
              [8.224023437500023, 41.031298828124996],
              [8.267382812500017, 41.09912109375],
              [8.320214843750023, 41.121875],
              [8.34375, 41.101611328124996],
              [8.318945312500006, 41.062744140625],
              [8.286035156250023, 41.03984375],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 4,
        sovereignt: "Switzerland",
        sov_a3: "CHE",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Switzerland",
        adm0_a3: "CHE",
        geou_dif: 0,
        geounit: "Switzerland",
        gu_a3: "CHE",
        su_dif: 0,
        subunit: "Switzerland",
        su_a3: "CHE",
        brk_diff: 0,
        name: "Switzerland",
        name_long: "Switzerland",
        brk_a3: "CHE",
        brk_name: "Switzerland",
        brk_group: null,
        abbrev: "Switz.",
        postal: "CH",
        formal_en: "Swiss Confederation",
        formal_fr: null,
        name_ciawf: "Switzerland",
        note_adm0: null,
        note_brk: null,
        name_sort: "Switzerland",
        name_alt: null,
        mapcolor7: 5,
        mapcolor8: 2,
        mapcolor9: 7,
        mapcolor13: 3,
        pop_est: 8574832,
        pop_rank: 13,
        pop_year: 2019,
        gdp_md: 703082,
        gdp_year: 2019,
        economy: "2. Developed region: nonG7",
        income_grp: "1. High income: OECD",
        fips_10: "SZ",
        iso_a2: "CH",
        iso_a2_eh: "CH",
        iso_a3: "CHE",
        iso_a3_eh: "CHE",
        iso_n3: "756",
        iso_n3_eh: "756",
        un_a3: "756",
        wb_a2: "CH",
        wb_a3: "CHE",
        woe_id: 23424957,
        woe_id_eh: 23424957,
        woe_note: "Exact WOE match as country",
        adm0_iso: "CHE",
        adm0_diff: null,
        adm0_tlc: "CHE",
        adm0_a3_us: "CHE",
        adm0_a3_fr: "CHE",
        adm0_a3_ru: "CHE",
        adm0_a3_es: "CHE",
        adm0_a3_cn: "CHE",
        adm0_a3_tw: "CHE",
        adm0_a3_in: "CHE",
        adm0_a3_np: "CHE",
        adm0_a3_pk: "CHE",
        adm0_a3_de: "CHE",
        adm0_a3_gb: "CHE",
        adm0_a3_br: "CHE",
        adm0_a3_il: "CHE",
        adm0_a3_ps: "CHE",
        adm0_a3_sa: "CHE",
        adm0_a3_eg: "CHE",
        adm0_a3_ma: "CHE",
        adm0_a3_pt: "CHE",
        adm0_a3_ar: "CHE",
        adm0_a3_jp: "CHE",
        adm0_a3_ko: "CHE",
        adm0_a3_vn: "CHE",
        adm0_a3_tr: "CHE",
        adm0_a3_id: "CHE",
        adm0_a3_pl: "CHE",
        adm0_a3_gr: "CHE",
        adm0_a3_it: "CHE",
        adm0_a3_nl: "CHE",
        adm0_a3_se: "CHE",
        adm0_a3_bd: "CHE",
        adm0_a3_ua: "CHE",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Western Europe",
        region_wb: "Europe & Central Asia",
        name_len: 11,
        long_len: 11,
        abbrev_len: 6,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: 7.463965,
        label_y: 46.719114,
        ne_id: 1159320491,
        wikidataid: "Q39",
        name_ar: "سويسرا",
        name_bn: "সুইজারল্যান্ড",
        name_de: "Schweiz",
        name_en: "Switzerland",
        name_es: "Suiza",
        name_fa: "سوئیس",
        name_fr: "Suisse",
        name_el: "Ελβετία",
        name_he: "שווייץ",
        name_hi: "स्विट्ज़रलैण्ड",
        name_hu: "Svájc",
        name_id: "Swiss",
        name_it: "Svizzera",
        name_ja: "スイス",
        name_ko: "스위스",
        name_nl: "Zwitserland",
        name_pl: "Szwajcaria",
        name_pt: "Suíça",
        name_ru: "Швейцария",
        name_sv: "Schweiz",
        name_tr: "İsviçre",
        name_uk: "Швейцарія",
        name_ur: "سویٹزرلینڈ",
        name_vi: "Thụy Sĩ",
        name_zh: "瑞士",
        name_zht: "瑞士",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "CHE.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [9.524023437500006, 47.52421875],
            [9.554394531250011, 47.511132812499994],
            [9.625878906250023, 47.467041015625],
            [9.609082031250011, 47.391796875],
            [9.527539062500011, 47.270751953125],
            [9.484277343750023, 47.17265625],
            [9.4794921875, 47.097509765625],
            [9.487695312500023, 47.062255859375],
            [9.502343750000023, 47.062744140625],
            [9.580273437500011, 47.057373046875],
            [9.619921875000017, 47.057470703125],
            [9.745019531250023, 47.037109375],
            [9.8453125, 47.007373046874996],
            [9.864648437500023, 46.975976562499994],
            [9.877734375000017, 46.9376953125],
            [9.996875, 46.8853515625],
            [10.133496093750011, 46.851513671875],
            [10.179785156250006, 46.862353515624996],
            [10.349414062500017, 46.984765625],
            [10.414941406250023, 46.964404296874996],
            [10.45458984375, 46.8994140625],
            [10.452832031250011, 46.86494140625],
            [10.406054687500017, 46.73486328125],
            [10.39794921875, 46.6650390625],
            [10.438281250000017, 46.61884765625],
            [10.442480468750006, 46.582861328125],
            [10.4306640625, 46.550048828125],
            [10.363085937500017, 46.5470703125],
            [10.272265625000017, 46.56484375],
            [10.195507812500011, 46.62109375],
            [10.1375, 46.61435546875],
            [10.087011718750006, 46.599902343749996],
            [10.061230468750011, 46.546777343749994],
            [10.038281250000011, 46.483203125],
            [10.045605468750011, 46.447900390624994],
            [10.081933593750023, 46.420751953125],
            [10.109667968750017, 46.362841796874996],
            [10.1298828125, 46.287988281249994],
            [10.145214843750011, 46.253515625],
            [10.128320312500023, 46.238232421875],
            [10.08056640625, 46.227978515625],
            [10.041015625, 46.238085937499996],
            [9.9716796875, 46.327685546874996],
            [9.939257812500017, 46.36181640625],
            [9.884472656250011, 46.3677734375],
            [9.787792968750011, 46.346044921875],
            [9.639453125000017, 46.2958984375],
            [9.57958984375, 46.29609375],
            [9.528710937500023, 46.306201171874996],
            [9.481054687500006, 46.348779296874994],
            [9.440625, 46.430810546874994],
            [9.427636718750023, 46.482324218749994],
            [9.399316406250023, 46.4806640625],
            [9.304394531250011, 46.495556640625],
            [9.260156250000023, 46.4751953125],
            [9.259765625, 46.391259765624994],
            [9.251074218750006, 46.286767578124994],
            [9.203417968750017, 46.21923828125],
            [9.070996093750011, 46.102441406249994],
            [9.022363281250023, 46.05146484375],
            [9.003027343750006, 46.014892578125],
            [8.998925781250023, 45.98310546875],
            [9.019140625, 45.928125],
            [9.046679687500017, 45.8755859375],
            [9.023730468750017, 45.845703125],
            [8.953710937500006, 45.830029296875],
            [8.904296875, 45.861962890624994],
            [8.885156250000023, 45.918701171875],
            [8.778027343750011, 45.99619140625],
            [8.826757812500006, 46.06103515625],
            [8.818554687500011, 46.0771484375],
            [8.641699218750006, 46.110791015625],
            [8.5654296875, 46.159814453124994],
            [8.458398437500023, 46.245898437499996],
            [8.4384765625, 46.282861328124994],
            [8.44296875, 46.402783203125],
            [8.436816406250017, 46.431884765625],
            [8.422558593750011, 46.446044921875],
            [8.370703125, 46.445117187499996],
            [8.298535156250011, 46.40341796875],
            [8.23193359375, 46.341210937499994],
            [8.095703125, 46.271044921874996],
            [8.08154296875, 46.256005859374994],
            [8.127246093750017, 46.18759765625],
            [8.125195312500011, 46.1609375],
            [8.014257812500006, 46.051904296874994],
            [7.9931640625, 46.015917968749996],
            [7.852343750000017, 45.9474609375],
            [7.787890625000017, 45.921826171875],
            [7.592578125000017, 45.972216796874996],
            [7.53857421875, 45.978173828124994],
            [7.451562500000023, 45.94443359375],
            [7.327929687500017, 45.912353515625],
            [7.129003906250006, 45.880419921874996],
            [7.055761718750006, 45.90380859375],
            [7.02109375, 45.92578125],
            [7.00390625, 45.958837890625],
            [6.953710937500006, 46.017138671874996],
            [6.897265625000017, 46.0517578125],
            [6.858007812500006, 46.089404296874996],
            [6.8056640625, 46.130664062499996],
            [6.772070312500006, 46.16513671875],
            [6.816796875000023, 46.275195312499996],
            [6.7841796875, 46.31396484375],
            [6.767382812500017, 46.369189453124996],
            [6.776074218750011, 46.406640625],
            [6.758105468750017, 46.415771484375],
            [6.578222656250006, 46.437353515625],
            [6.428906250000011, 46.430517578125],
            [6.321875, 46.393701171874994],
            [6.234667968750017, 46.3326171875],
            [6.22421875, 46.31943359375],
            [6.229589843750006, 46.308447265625],
            [6.27294921875, 46.252246093749996],
            [6.199414062500011, 46.19306640625],
            [6.086621093750011, 46.147021484374996],
            [6.006640625000017, 46.142333984375],
            [5.971484375000017, 46.151220703125],
            [5.970019531250017, 46.214697265625],
            [6.0361328125, 46.238085937499996],
            [6.095898437500011, 46.27939453125],
            [6.115917968750011, 46.337646484375],
            [6.123242187500011, 46.37861328125],
            [6.060253906250011, 46.428173828125],
            [6.06796875, 46.458544921874996],
            [6.10703125, 46.516064453125],
            [6.129687500000017, 46.5669921875],
            [6.160742187500006, 46.61103515625],
            [6.28515625, 46.683056640625],
            [6.41015625, 46.755419921874996],
            [6.429003906250017, 46.832275390625],
            [6.438574218750006, 46.92587890625],
            [6.45625, 46.94833984375],
            [6.624804687500017, 47.004345703125],
            [6.666894531250023, 47.026513671874994],
            [6.688085937500006, 47.058251953124994],
            [6.820703125000023, 47.16318359375],
            [6.952050781250023, 47.2671875],
            [6.978515625, 47.302050781249996],
            [7.000585937500006, 47.322509765625],
            [7.000585937500006, 47.339453125],
            [6.984082031250011, 47.3525390625],
            [6.921484375, 47.361230468749994],
            [6.900390625, 47.39423828125],
            [6.968359375, 47.45322265625],
            [7.053417968750011, 47.48935546875],
            [7.136035156250017, 47.48984375],
            [7.169238281250017, 47.4732421875],
            [7.16748046875, 47.4537109375],
            [7.203125, 47.43271484375],
            [7.265722656250006, 47.42578125],
            [7.343164062500023, 47.43310546875],
            [7.420019531250006, 47.45517578125],
            [7.467382812500006, 47.507666015625],
            [7.494921875000017, 47.54736328125],
            [7.615625, 47.592724609375],
            [7.698046875000017, 47.569873046874996],
            [7.927050781250017, 47.563867187499994],
            [8.09375, 47.576171875],
            [8.1982421875, 47.60693359375],
            [8.327832031250011, 47.60693359375],
            [8.414746093750011, 47.589599609375],
            [8.430078125000023, 47.592138671875],
            [8.454003906250023, 47.59619140625],
            [8.477636718750006, 47.612695312499994],
            [8.559472656250023, 47.6240234375],
            [8.570507812500011, 47.63779296875],
            [8.567089843750011, 47.651904296874996],
            [8.55234375, 47.659130859375],
            [8.451757812500006, 47.651806640625],
            [8.413281250000011, 47.6626953125],
            [8.403417968750006, 47.687792968749996],
            [8.435742187500011, 47.731347656249994],
            [8.509863281250006, 47.766894531249996],
            [8.572656250000023, 47.775634765625],
            [8.617871093750011, 47.76611328125],
            [8.728320312500017, 47.700048828125],
            [8.754785156250023, 47.698046875],
            [8.770117187500006, 47.709912109375],
            [8.793066406250006, 47.716552734375],
            [8.831152343750006, 47.70361328125],
            [8.8740234375, 47.6626953125],
            [8.881152343750017, 47.656396484374994],
            [9.127539062500006, 47.670703125],
            [9.182812500000011, 47.670703125],
            [9.35, 47.598925781249996],
            [9.524023437500006, 47.52421875],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 5,
        sovereignt: "Netherlands",
        sov_a3: "NL1",
        adm0_dif: 1,
        level: 2,
        type: "Country",
        tlc: "1",
        admin: "Netherlands",
        adm0_a3: "NLD",
        geou_dif: 0,
        geounit: "Netherlands",
        gu_a3: "NLD",
        su_dif: 0,
        subunit: "Netherlands",
        su_a3: "NLD",
        brk_diff: 0,
        name: "Netherlands",
        name_long: "Netherlands",
        brk_a3: "NLD",
        brk_name: "Netherlands",
        brk_group: null,
        abbrev: "Neth.",
        postal: "NL",
        formal_en: "Kingdom of the Netherlands",
        formal_fr: null,
        name_ciawf: "Netherlands",
        note_adm0: null,
        note_brk: null,
        name_sort: "Netherlands",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 2,
        mapcolor9: 2,
        mapcolor13: 9,
        pop_est: 17332850,
        pop_rank: 14,
        pop_year: 2019,
        gdp_md: 907050,
        gdp_year: 2019,
        economy: "2. Developed region: nonG7",
        income_grp: "1. High income: OECD",
        fips_10: "NL",
        iso_a2: "NL",
        iso_a2_eh: "NL",
        iso_a3: "NLD",
        iso_a3_eh: "NLD",
        iso_n3: "528",
        iso_n3_eh: "528",
        un_a3: "528",
        wb_a2: "NL",
        wb_a3: "NLD",
        woe_id: -90,
        woe_id_eh: 23424909,
        woe_note:
          "Doesn't include new former units of Netherlands Antilles (24549811, 24549808, and 24549809)",
        adm0_iso: "NLD",
        adm0_diff: null,
        adm0_tlc: "NLD",
        adm0_a3_us: "NLD",
        adm0_a3_fr: "NLD",
        adm0_a3_ru: "NLD",
        adm0_a3_es: "NLD",
        adm0_a3_cn: "NLD",
        adm0_a3_tw: "NLD",
        adm0_a3_in: "NLD",
        adm0_a3_np: "NLD",
        adm0_a3_pk: "NLD",
        adm0_a3_de: "NLD",
        adm0_a3_gb: "NLD",
        adm0_a3_br: "NLD",
        adm0_a3_il: "NLD",
        adm0_a3_ps: "NLD",
        adm0_a3_sa: "NLD",
        adm0_a3_eg: "NLD",
        adm0_a3_ma: "NLD",
        adm0_a3_pt: "NLD",
        adm0_a3_ar: "NLD",
        adm0_a3_jp: "NLD",
        adm0_a3_ko: "NLD",
        adm0_a3_vn: "NLD",
        adm0_a3_tr: "NLD",
        adm0_a3_id: "NLD",
        adm0_a3_pl: "NLD",
        adm0_a3_gr: "NLD",
        adm0_a3_it: "NLD",
        adm0_a3_nl: "NLD",
        adm0_a3_se: "NLD",
        adm0_a3_bd: "NLD",
        adm0_a3_ua: "NLD",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Western Europe",
        region_wb: "Europe & Central Asia",
        name_len: 11,
        long_len: 11,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 10,
        label_x: 5.61144,
        label_y: 52.422211,
        ne_id: 1159321101,
        wikidataid: "Q55",
        name_ar: "هولندا",
        name_bn: "নেদারল্যান্ডস",
        name_de: "Niederlande",
        name_en: "Netherlands",
        name_es: "Países Bajos",
        name_fa: "هلند",
        name_fr: "Pays-Bas",
        name_el: "Ολλανδία",
        name_he: "הולנד",
        name_hi: "नीदरलैण्ड",
        name_hu: "Hollandia",
        name_id: "Belanda",
        name_it: "Paesi Bassi",
        name_ja: "オランダ",
        name_ko: "네덜란드",
        name_nl: "Nederland",
        name_pl: "Holandia",
        name_pt: "Países Baixos",
        name_ru: "Нидерланды",
        name_sv: "Nederländerna",
        name_tr: "Hollanda",
        name_uk: "Нідерланди",
        name_ur: "نیدرلینڈز",
        name_vi: "Hà Lan",
        name_zh: "荷兰",
        name_zht: "荷蘭",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "NLD.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [5.993945312500017, 50.750439453125],
              [5.892461122495295, 50.752556857983514],
              [5.79736328125, 50.754541015624994],
              [5.7470703125, 50.759570312499996],
              [5.693652343750017, 50.774658203125],
              [5.693554687500011, 50.774755859375],
              [5.669140625000011, 50.805957031249996],
              [5.639453125000017, 50.843603515625],
              [5.647558593750006, 50.866650390625],
              [5.736621093750017, 50.93212890625],
              [5.75, 50.950244140624996],
              [5.740820312500006, 50.959912109375],
              [5.749804687500017, 50.98876953125],
              [5.818261718750023, 51.08642578125],
              [5.8271484375, 51.125634765624994],
              [5.796484375, 51.153076171875],
              [5.752343750000023, 51.169482421874996],
              [5.608789062500023, 51.1984375],
              [5.540429687500023, 51.239306640624996],
              [5.5087890625, 51.275],
              [5.476855468750017, 51.285058593749994],
              [5.429785156250006, 51.272998046874996],
              [5.310839843750017, 51.259716796875],
              [5.214160156250017, 51.278955078124994],
              [5.099902343750017, 51.346484375],
              [5.073437500000011, 51.4068359375],
              [5.059472656250023, 51.453125],
              [5.030957031250011, 51.469091796875],
              [4.992578125000023, 51.445361328124996],
              [4.943945312500006, 51.407763671874996],
              [4.848046875000023, 51.403271484375],
              [4.820703125000023, 51.412060546875],
              [4.816015625, 51.4328125],
              [4.810546875, 51.452734375],
              [4.7841796875, 51.477392578125],
              [4.755664062500017, 51.491113281249994],
              [4.633984375000011, 51.421728515625],
              [4.588769531250023, 51.421923828124996],
              [4.531640625000023, 51.448583984375],
              [4.50341796875, 51.47470703125],
              [4.44091796875, 51.459814453125],
              [4.384765625, 51.427587890625],
              [4.404003906250011, 51.367089843749994],
              [4.373730468750011, 51.356005859374996],
              [4.304492187500017, 51.3615234375],
              [4.226171875, 51.386474609375],
              [4.138867187500011, 51.401513671874994],
              [4.006542968750011, 51.443212890625],
              [3.821875, 51.409375],
              [3.693554687500011, 51.44990234375],
              [3.5869140625, 51.45390625],
              [3.5205078125, 51.486181640625],
              [3.448925781250011, 51.540771484375],
              [3.499609375, 51.57666015625],
              [3.548632812500017, 51.589111328125],
              [3.743945312500017, 51.596044921875],
              [3.886035156250017, 51.57421875],
              [4.141308593750011, 51.45576171875],
              [4.205761718750011, 51.456689453124994],
              [4.274121093750011, 51.471630859375],
              [4.239355468750006, 51.50390625],
              [4.175488281250011, 51.519287109375],
              [4.080468750000023, 51.551123046875],
              [4.004785156250023, 51.595849609374994],
              [4.1826171875, 51.610302734375],
              [4.158007812500017, 51.633447265625],
              [4.134570312500017, 51.672900390624996],
              [3.946875, 51.810546875],
              [3.978906250000023, 51.847802734375],
              [4.026074218750011, 51.927734375],
              [4.084863281250023, 51.994091796875],
              [4.131738281250023, 52.0119140625],
              [4.208789062500017, 52.058984375],
              [4.376269531250017, 52.196826171874996],
              [4.482812500000023, 52.3091796875],
              [4.562109375, 52.442578125],
              [4.678320312500006, 52.809765625],
              [4.712695312500017, 52.872119140624996],
              [4.76875, 52.94130859375],
              [4.839062500000011, 52.928271484374996],
              [4.887988281250017, 52.908349609375],
              [5.061230468750011, 52.96064453125],
              [5.3583984375, 53.096484375],
              [5.445996093750011, 53.2140625],
              [5.532031250000017, 53.268701171875],
              [5.87353515625, 53.3751953125],
              [6.062207031250011, 53.407080078125],
              [6.353222656250011, 53.415283203125],
              [6.563574218750006, 53.43427734375],
              [6.816210937500017, 53.441162109375],
              [6.912402343750017, 53.375390625],
              [6.968164062500023, 53.327294921875],
              [7.058007812500023, 53.300585937499996],
              [7.197265625, 53.282275390624996],
              [7.18896484375, 53.18720703125],
              [7.18994140625, 52.99951171875],
              [7.179492187500017, 52.9662109375],
              [7.117089843750023, 52.887011718749996],
              [7.050878906250006, 52.744775390625],
              [7.033007812500017, 52.6513671875],
              [7.01318359375, 52.633544921875],
              [6.748437500000023, 52.634082031249996],
              [6.710742187500017, 52.61787109375],
              [6.705371093750017, 52.59765625],
              [6.71875, 52.573583984375],
              [6.71240234375, 52.549658203125],
              [6.691601562500011, 52.53017578125],
              [6.702929687500017, 52.49921875],
              [6.748828125000017, 52.464013671875],
              [6.83251953125, 52.44228515625],
              [6.922070312500011, 52.440283203125],
              [6.968164062500023, 52.444091796875],
              [7.001855468750023, 52.418994140624996],
              [7.03515625, 52.380224609375],
              [7.032617187500023, 52.331494140625],
              [7.019628906250006, 52.266015625],
              [6.977246093750011, 52.205517578125],
              [6.855078125, 52.135791015624996],
              [6.800390625, 52.11123046875],
              [6.7490234375, 52.098681640624996],
              [6.724511718750023, 52.080224609375],
              [6.712988281250006, 52.056884765625],
              [6.715625, 52.036181640624996],
              [6.802441406250011, 51.98017578125],
              [6.800390625, 51.9673828125],
              [6.775195312500017, 51.93828125],
              [6.741796875, 51.910888671875],
              [6.517578125, 51.853955078125],
              [6.425, 51.8583984375],
              [6.372167968750006, 51.830029296875],
              [6.355664062500011, 51.824658203125],
              [6.297070312500011, 51.850732421875],
              [6.16650390625, 51.88076171875],
              [6.1171875, 51.870410156249996],
              [6.08984375, 51.853955078125],
              [6.007617187500017, 51.833984375],
              [5.94873046875, 51.802685546875],
              [5.948535156250017, 51.76240234375],
              [6.052734375, 51.658251953124996],
              [6.08935546875, 51.63779296875],
              [6.091113281250017, 51.598925781249996],
              [6.1416015625, 51.550097656249996],
              [6.193261718750023, 51.488916015624994],
              [6.198828125, 51.45],
              [6.19287109375, 51.410595703125],
              [6.166210937500011, 51.354833984375],
              [6.075878906250011, 51.22412109375],
              [6.074804687500006, 51.199023437499996],
              [6.082421875000023, 51.179980468749996],
              [6.113378906250006, 51.174707031249994],
              [6.136914062500011, 51.16484375],
              [6.129980468750006, 51.147412109375],
              [5.961035156250006, 51.056689453124996],
              [5.939257812500017, 51.040820312499996],
              [5.868359375000011, 51.0453125],
              [5.857519531250006, 51.030126953125],
              [5.8671875, 51.005664062499996],
              [5.894726562500011, 50.984228515625],
              [5.955078125, 50.972949218749996],
              [6.0068359375, 50.949951171875],
              [6.0484375, 50.9048828125],
              [5.993945312500017, 50.750439453125],
            ],
          ],
          [
            [
              [4.226171875, 51.386474609375],
              [4.21142578125, 51.34873046875],
              [4.172558593750011, 51.307080078125],
              [4.0400390625, 51.2470703125],
              [3.902050781250011, 51.207666015624994],
              [3.830761718750011, 51.21259765625],
              [3.781933593750011, 51.233203125],
              [3.755664062500017, 51.254833984375],
              [3.681835937500011, 51.275683593749996],
              [3.580273437500011, 51.286181640624996],
              [3.51708984375, 51.263623046875],
              [3.471972656250017, 51.242236328124996],
              [3.432519531250023, 51.245751953124994],
              [3.40283203125, 51.263623046875],
              [3.380078125000011, 51.29111328125],
              [3.35009765625, 51.377685546875],
              [3.42578125, 51.393505859375],
              [3.589453125, 51.3994140625],
              [3.716503906250011, 51.369140625],
              [3.883398437500006, 51.3544921875],
              [4.011035156250017, 51.395947265625],
              [4.111523437500011, 51.360644531249996],
              [4.226171875, 51.386474609375],
            ],
          ],
          [
            [
              [6.333398437500023, 53.5107421875],
              [6.193261718750023, 53.476806640625],
              [6.159277343750006, 53.483935546874996],
              [6.167675781250011, 53.49375],
              [6.290917968750023, 53.514990234375],
              [6.333398437500023, 53.5107421875],
            ],
          ],
          [
            [
              [5.929296875, 53.458837890625],
              [5.73203125, 53.442626953125],
              [5.665332031250017, 53.454882812499996],
              [5.654296875, 53.46650390625],
              [5.708105468750006, 53.473388671875],
              [5.876269531250017, 53.47509765625],
              [5.92822265625, 53.464990234375],
              [5.929296875, 53.458837890625],
            ],
          ],
          [
            [
              [5.108593750000011, 53.3080078125],
              [4.923730468750023, 53.2345703125],
              [4.907910156250011, 53.246240234375],
              [5.027050781250011, 53.310205078125],
              [5.108593750000011, 53.3080078125],
            ],
          ],
          [
            [
              [5.32578125, 53.3857421875],
              [5.232617187500011, 53.377783203125],
              [5.190234375000017, 53.391796875],
              [5.415136718750006, 53.431445312499996],
              [5.557421875000017, 53.4435546875],
              [5.582617187500006, 53.4380859375],
              [5.32578125, 53.3857421875],
            ],
          ],
          [
            [
              [4.886132812500023, 53.070703125],
              [4.787109375, 52.999804687499996],
              [4.726757812500011, 53.01962890625],
              [4.709179687500011, 53.03603515625],
              [4.73984375, 53.09130859375],
              [4.886425781250011, 53.18330078125],
              [4.886132812500023, 53.070703125],
            ],
          ],
          [
            [
              [3.949121093750023, 51.739453125],
              [4.046777343750023, 51.684912109375],
              [4.067578125000011, 51.66748046875],
              [4.075097656250023, 51.648779296875],
              [3.950976562500017, 51.62705078125],
              [3.819042968750011, 51.693994140625],
              [3.731835937500023, 51.67822265625],
              [3.699023437500017, 51.709912109375],
              [3.698535156250017, 51.7296875],
              [3.7890625, 51.746435546875],
              [3.949121093750023, 51.739453125],
            ],
          ],
          [
            [
              [6.734765625000023, 53.58251953125],
              [6.64208984375, 53.57919921875],
              [6.668554687500006, 53.6056640625],
              [6.754589843750011, 53.62548828125],
              [6.800878906250006, 53.62548828125],
              [6.734765625000023, 53.58251953125],
            ],
          ],
          [
            [
              [-68.205810546875, 12.144580078124989],
              [-68.254345703125, 12.032080078124991],
              [-68.2822265625, 12.082275390625],
              [-68.287255859375, 12.171728515624991],
              [-68.30712890625, 12.206738281249997],
              [-68.34843749999999, 12.228076171874989],
              [-68.37109375, 12.257519531249997],
              [-68.36923828124999, 12.301953125],
              [-68.219482421875, 12.23125],
              [-68.205810546875, 12.144580078124989],
            ],
          ],
          [
            [
              [-62.9375, 17.495654296875003],
              [-62.96171874999999, 17.475048828124997],
              [-62.98310546875, 17.47690429687499],
              [-62.99716796874999, 17.496826171875],
              [-62.999609375, 17.53037109374999],
              [-62.97934570312499, 17.521191406249997],
              [-62.97177734374999, 17.516064453124997],
              [-62.96542968749999, 17.50927734375],
              [-62.9375, 17.495654296875003],
            ],
          ],
          [
            [
              [-63.232666015625, 17.62314453124999],
              [-63.241601562499994, 17.619580078124997],
              [-63.25449218749999, 17.628662109375],
              [-63.2521484375, 17.645263671875],
              [-63.241650390625, 17.651806640624997],
              [-63.23349609374999, 17.647216796875],
              [-63.22690429687499, 17.63413085937499],
              [-63.232666015625, 17.62314453124999],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 6,
        sovereignt: "Liechtenstein",
        sov_a3: "LIE",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Liechtenstein",
        adm0_a3: "LIE",
        geou_dif: 0,
        geounit: "Liechtenstein",
        gu_a3: "LIE",
        su_dif: 0,
        subunit: "Liechtenstein",
        su_a3: "LIE",
        brk_diff: 0,
        name: "Liechtenstein",
        name_long: "Liechtenstein",
        brk_a3: "LIE",
        brk_name: "Liechtenstein",
        brk_group: null,
        abbrev: "Liech.",
        postal: "FL",
        formal_en: "Principality of Liechtenstein",
        formal_fr: null,
        name_ciawf: "Liechtenstein",
        note_adm0: null,
        note_brk: null,
        name_sort: "Liechtenstein",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 4,
        mapcolor9: 2,
        mapcolor13: 9,
        pop_est: 38019,
        pop_rank: 7,
        pop_year: 2019,
        gdp_md: 6876,
        gdp_year: 2018,
        economy: "2. Developed region: nonG7",
        income_grp: "2. High income: nonOECD",
        fips_10: "LS",
        iso_a2: "LI",
        iso_a2_eh: "LI",
        iso_a3: "LIE",
        iso_a3_eh: "LIE",
        iso_n3: "438",
        iso_n3_eh: "438",
        un_a3: "438",
        wb_a2: "LI",
        wb_a3: "LIE",
        woe_id: 23424879,
        woe_id_eh: 23424879,
        woe_note: "Exact WOE match as country",
        adm0_iso: "LIE",
        adm0_diff: null,
        adm0_tlc: "LIE",
        adm0_a3_us: "LIE",
        adm0_a3_fr: "LIE",
        adm0_a3_ru: "LIE",
        adm0_a3_es: "LIE",
        adm0_a3_cn: "LIE",
        adm0_a3_tw: "LIE",
        adm0_a3_in: "LIE",
        adm0_a3_np: "LIE",
        adm0_a3_pk: "LIE",
        adm0_a3_de: "LIE",
        adm0_a3_gb: "LIE",
        adm0_a3_br: "LIE",
        adm0_a3_il: "LIE",
        adm0_a3_ps: "LIE",
        adm0_a3_sa: "LIE",
        adm0_a3_eg: "LIE",
        adm0_a3_ma: "LIE",
        adm0_a3_pt: "LIE",
        adm0_a3_ar: "LIE",
        adm0_a3_jp: "LIE",
        adm0_a3_ko: "LIE",
        adm0_a3_vn: "LIE",
        adm0_a3_tr: "LIE",
        adm0_a3_id: "LIE",
        adm0_a3_pl: "LIE",
        adm0_a3_gr: "LIE",
        adm0_a3_it: "LIE",
        adm0_a3_nl: "LIE",
        adm0_a3_se: "LIE",
        adm0_a3_bd: "LIE",
        adm0_a3_ua: "LIE",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Western Europe",
        region_wb: "Europe & Central Asia",
        name_len: 13,
        long_len: 13,
        abbrev_len: 6,
        tiny: 6,
        homepart: 1,
        min_zoom: 0,
        min_label: 5,
        max_label: 10,
        label_x: 9.559439,
        label_y: 47.111405,
        ne_id: 1159321021,
        wikidataid: "Q347",
        name_ar: "ليختنشتاين",
        name_bn: "লিশটেনস্টাইন",
        name_de: "Liechtenstein",
        name_en: "Liechtenstein",
        name_es: "Liechtenstein",
        name_fa: "لیختناشتاین",
        name_fr: "Liechtenstein",
        name_el: "Λίχτενσταϊν",
        name_he: "ליכטנשטיין",
        name_hi: "लिक्टेन्स्टाइन",
        name_hu: "Liechtenstein",
        name_id: "Liechtenstein",
        name_it: "Liechtenstein",
        name_ja: "リヒテンシュタイン",
        name_ko: "리히텐슈타인",
        name_nl: "Liechtenstein",
        name_pl: "Liechtenstein",
        name_pt: "Liechtenstein",
        name_ru: "Лихтенштейн",
        name_sv: "Liechtenstein",
        name_tr: "Lihtenştayn",
        name_uk: "Ліхтенштейн",
        name_ur: "لیختینستائن",
        name_vi: "Liechtenstein",
        name_zh: "列支敦士登",
        name_zht: "列支敦斯登",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "LIE.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [9.580273437500011, 47.057373046875],
            [9.502343750000023, 47.062744140625],
            [9.487695312500023, 47.062255859375],
            [9.4794921875, 47.097509765625],
            [9.484277343750023, 47.17265625],
            [9.527539062500011, 47.270751953125],
            [9.536816406250011, 47.254638671875],
            [9.542187500000011, 47.234130859375],
            [9.551074218750017, 47.212255859375],
            [9.555761718750006, 47.185498046875],
            [9.571875, 47.15791015625],
            [9.601171875, 47.132080078125],
            [9.610546875000011, 47.10712890625],
            [9.595703125, 47.075830078124994],
            [9.580273437500011, 47.057373046875],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 6,
        sovereignt: "Croatia",
        sov_a3: "HRV",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Croatia",
        adm0_a3: "HRV",
        geou_dif: 0,
        geounit: "Croatia",
        gu_a3: "HRV",
        su_dif: 0,
        subunit: "Croatia",
        su_a3: "HRV",
        brk_diff: 0,
        name: "Croatia",
        name_long: "Croatia",
        brk_a3: "HRV",
        brk_name: "Croatia",
        brk_group: null,
        abbrev: "Cro.",
        postal: "HR",
        formal_en: "Republic of Croatia",
        formal_fr: null,
        name_ciawf: "Croatia",
        note_adm0: null,
        note_brk: null,
        name_sort: "Croatia",
        name_alt: null,
        mapcolor7: 5,
        mapcolor8: 4,
        mapcolor9: 5,
        mapcolor13: 1,
        pop_est: 4067500,
        pop_rank: 12,
        pop_year: 2019,
        gdp_md: 60752,
        gdp_year: 2019,
        economy: "2. Developed region: nonG7",
        income_grp: "2. High income: nonOECD",
        fips_10: "HR",
        iso_a2: "HR",
        iso_a2_eh: "HR",
        iso_a3: "HRV",
        iso_a3_eh: "HRV",
        iso_n3: "191",
        iso_n3_eh: "191",
        un_a3: "191",
        wb_a2: "HR",
        wb_a3: "HRV",
        woe_id: 23424843,
        woe_id_eh: 23424843,
        woe_note: "Exact WOE match as country",
        adm0_iso: "HRV",
        adm0_diff: null,
        adm0_tlc: "HRV",
        adm0_a3_us: "HRV",
        adm0_a3_fr: "HRV",
        adm0_a3_ru: "HRV",
        adm0_a3_es: "HRV",
        adm0_a3_cn: "HRV",
        adm0_a3_tw: "HRV",
        adm0_a3_in: "HRV",
        adm0_a3_np: "HRV",
        adm0_a3_pk: "HRV",
        adm0_a3_de: "HRV",
        adm0_a3_gb: "HRV",
        adm0_a3_br: "HRV",
        adm0_a3_il: "HRV",
        adm0_a3_ps: "HRV",
        adm0_a3_sa: "HRV",
        adm0_a3_eg: "HRV",
        adm0_a3_ma: "HRV",
        adm0_a3_pt: "HRV",
        adm0_a3_ar: "HRV",
        adm0_a3_jp: "HRV",
        adm0_a3_ko: "HRV",
        adm0_a3_vn: "HRV",
        adm0_a3_tr: "HRV",
        adm0_a3_id: "HRV",
        adm0_a3_pl: "HRV",
        adm0_a3_gr: "HRV",
        adm0_a3_it: "HRV",
        adm0_a3_nl: "HRV",
        adm0_a3_se: "HRV",
        adm0_a3_bd: "HRV",
        adm0_a3_ua: "HRV",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Southern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: 16.37241,
        label_y: 45.805799,
        ne_id: 1159320833,
        wikidataid: "Q224",
        name_ar: "كرواتيا",
        name_bn: "ক্রোয়েশিয়া",
        name_de: "Kroatien",
        name_en: "Croatia",
        name_es: "Croacia",
        name_fa: "کرواسی",
        name_fr: "Croatie",
        name_el: "Κροατία",
        name_he: "קרואטיה",
        name_hi: "क्रोएशिया",
        name_hu: "Horvátország",
        name_id: "Kroasia",
        name_it: "Croazia",
        name_ja: "クロアチア",
        name_ko: "크로아티아",
        name_nl: "Kroatië",
        name_pl: "Chorwacja",
        name_pt: "Croácia",
        name_ru: "Хорватия",
        name_sv: "Kroatien",
        name_tr: "Hırvatistan",
        name_uk: "Хорватія",
        name_ur: "کروشیا",
        name_vi: "Croatia",
        name_zh: "克罗地亚",
        name_zht: "克羅地亞",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "HRV.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [13.577929687500017, 45.516894531249996],
              [13.615234375, 45.4767578125],
              [13.878710937500017, 45.428369140624994],
              [13.935644531250006, 45.4498046875],
              [13.970117187500023, 45.4826171875],
              [13.9703125, 45.503369140625],
              [13.992773437500006, 45.509423828124994],
              [14.085546875, 45.477832031249996],
              [14.161230468750006, 45.48515625],
              [14.283007812500017, 45.486621093749996],
              [14.369921875000017, 45.4814453125],
              [14.42734375, 45.505761718749994],
              [14.505175781250017, 45.59521484375],
              [14.533984375000017, 45.645263671875],
              [14.56884765625, 45.6572265625],
              [14.591796875, 45.651269531249994],
              [14.608593750000011, 45.610107421875],
              [14.649511718750006, 45.571484375],
              [14.733593750000011, 45.50849609375],
              [14.793066406250006, 45.47822265625],
              [14.847070312500023, 45.467333984374996],
              [14.9, 45.49267578125],
              [14.95458984375, 45.499902343749994],
              [15.110449218750006, 45.45078125],
              [15.242089843750023, 45.44140625],
              [15.339453125, 45.467041015625],
              [15.32666015625, 45.502294921875],
              [15.291210937500011, 45.541552734374996],
              [15.283593750000023, 45.5796875],
              [15.290136718750006, 45.612646484375],
              [15.35693359375, 45.6455078125],
              [15.353710937500011, 45.659912109375],
              [15.27294921875, 45.717724609375],
              [15.277050781250011, 45.7326171875],
              [15.4541015625, 45.797607421875],
              [15.624804687500017, 45.834033203124996],
              [15.652148437500017, 45.862158203125],
              [15.668066406250006, 45.904443359374994],
              [15.675585937500017, 45.983691406249996],
              [15.666210937500011, 46.048486328124994],
              [15.596875, 46.109228515625],
              [15.592578125000017, 46.139990234375],
              [15.608984375, 46.171923828124996],
              [15.635937500000011, 46.200732421874996],
              [15.704199218750006, 46.213232421875],
              [15.784277343750006, 46.233984375],
              [15.847558593750023, 46.257861328124996],
              [15.933300781250011, 46.277636718749996],
              [16.00068359375001, 46.30537109375],
              [16.066503906250006, 46.371337890625],
              [16.1064453125, 46.382226562499994],
              [16.227441406250023, 46.372851562499996],
              [16.253320312500023, 46.389111328125],
              [16.236718750000023, 46.483837890625],
              [16.258398437500006, 46.50791015625],
              [16.301171875000023, 46.521386718749994],
              [16.321191406250023, 46.534619140625],
              [16.427636718750023, 46.5244140625],
              [16.516210937500006, 46.499902343749994],
              [16.569921875, 46.485009765624994],
              [16.748046875, 46.41640625],
              [16.871484375000023, 46.339306640625],
              [16.93994140625, 46.253662109375],
              [17.03271484375, 46.187304687499996],
              [17.14960937500001, 46.14033203125],
              [17.2421875, 46.076611328125],
              [17.310644531250006, 45.996142578124996],
              [17.406347656250006, 45.951074218749994],
              [17.529199218750023, 45.941308593749994],
              [17.60703125, 45.91376953125],
              [17.6396484375, 45.868359375],
              [17.706445312500023, 45.82724609375],
              [17.80712890625, 45.790429687499994],
              [17.9638671875, 45.770263671875],
              [18.263964843750017, 45.765478515625],
              [18.290625, 45.764453125],
              [18.358300781250023, 45.75302734375],
              [18.437304687500017, 45.767333984375],
              [18.533593750000023, 45.796142578125],
              [18.56464843750001, 45.81328125],
              [18.666015625, 45.907470703125],
              [18.721777343750006, 45.899365234375],
              [18.8330078125, 45.91083984375],
              [18.900292968750023, 45.93173828125],
              [18.905371093750006, 45.93173828125],
              [18.90107421875001, 45.907617187499994],
              [18.8935546875, 45.865527343749996],
              [18.83906250000001, 45.835742187499996],
              [18.89453125, 45.76708984375],
              [18.947265625, 45.655810546874996],
              [18.917871093750023, 45.600830078125],
              [18.953710937500006, 45.558007812499994],
              [19.055078125000023, 45.527246093749994],
              [19.064257812500017, 45.514990234375],
              [19.033300781250006, 45.502197265625],
              [19.007617187500017, 45.4658203125],
              [19.004687500000017, 45.39951171875],
              [19.093066406250017, 45.3369140625],
              [19.272851562500023, 45.277978515624994],
              [19.33027343750001, 45.26806640625],
              [19.35224609375001, 45.245410156249996],
              [19.38232421875, 45.230615234374994],
              [19.4, 45.2125],
              [19.400976562500006, 45.1890625],
              [19.388085937500023, 45.172998046874994],
              [19.303027343750017, 45.167285156249996],
              [19.205957031250023, 45.167773437499996],
              [19.13691406250001, 45.196240234375],
              [19.130761718750023, 45.17548828125],
              [19.129687500000017, 45.151708984375],
              [19.062890625000023, 45.13720703125],
              [19.1, 44.973779296874994],
              [19.085253906250017, 44.9267578125],
              [19.060546875, 44.910986328125],
              [19.03759765625, 44.917529296874996],
              [19.009570312500017, 44.919384765625],
              [18.995507812500023, 44.90400390625],
              [19.007128906250017, 44.869189453124996],
              [18.941308593750023, 44.865185546875],
              [18.83642578125, 44.883251953125],
              [18.788378906250017, 44.914892578125],
              [18.780175781250023, 44.947216796875],
              [18.779394531250006, 44.97724609375],
              [18.74609375, 45.026513671874994],
              [18.66259765625, 45.077441406249996],
              [18.48828125, 45.085839843749994],
              [18.423925781250006, 45.102001953125],
              [18.35761718750001, 45.120556640625],
              [18.284960937500017, 45.13427734375],
              [18.21796875000001, 45.13291015625],
              [18.13720703125, 45.119384765625],
              [17.99628906250001, 45.141796875],
              [17.948632812500023, 45.111865234374996],
              [17.874414062500023, 45.07724609375],
              [17.812792968750017, 45.078125],
              [17.69013671875001, 45.1583984375],
              [17.65351562500001, 45.163476562499994],
              [17.546289062500023, 45.12255859375],
              [17.50263671875001, 45.120361328125],
              [17.469140625000023, 45.13330078125],
              [17.324121093750023, 45.163964843749994],
              [17.258691406250023, 45.170556640624994],
              [17.21064453125001, 45.156054687499996],
              [17.125390625000023, 45.171777343749994],
              [16.91865234375001, 45.2765625],
              [16.790820312500017, 45.196875],
              [16.530664062500023, 45.216699218749994],
              [16.453515625000023, 45.162011718749994],
              [16.365039062500017, 45.058349609375],
              [16.293359375000023, 45.008837890624996],
              [16.231054687500006, 45.026611328125],
              [16.157324218750006, 45.072216796875],
              [16.0283203125, 45.189599609374994],
              [15.963183593750017, 45.210791015625],
              [15.88828125, 45.215722656249994],
              [15.822851562500006, 45.202783203124994],
              [15.7880859375, 45.178955078125],
              [15.761523437500017, 45.00751953125],
              [15.737988281250011, 44.856396484375],
              [15.736621093750017, 44.7658203125],
              [15.880078125000011, 44.681933593749996],
              [16.04902343750001, 44.53759765625],
              [16.103417968750023, 44.52099609375],
              [16.130273437500023, 44.47373046875],
              [16.169824218750023, 44.352001953125],
              [16.214257812500023, 44.215136718749996],
              [16.300097656250017, 44.12451171875],
              [16.377539062500006, 44.059619140624996],
              [16.472070312500023, 44.002587890624994],
              [16.59052734375001, 43.91318359375],
              [16.68769531250001, 43.8150390625],
              [16.713476562500006, 43.77880859375],
              [16.90185546875, 43.6490234375],
              [17.084570312500006, 43.516552734375],
              [17.248046875, 43.47021484375],
              [17.273828125000023, 43.445751953125],
              [17.275292968750023, 43.34384765625],
              [17.293066406250006, 43.305615234375],
              [17.402246093750023, 43.19892578125],
              [17.624804687500017, 43.042773437499996],
              [17.650488281250006, 43.006591796875],
              [17.6578125, 42.980078125],
              [17.643457031250023, 42.959765625],
              [17.58515625000001, 42.938378906249994],
              [17.53730468750001, 42.962255859375],
              [17.329882812500017, 43.114892578124994],
              [17.12939453125, 43.2111328125],
              [16.903125, 43.392431640625],
              [16.60029296875001, 43.4640625],
              [16.393945312500023, 43.543359375],
              [16.268945312500023, 43.53125],
              [16.13105468750001, 43.506298828125],
              [16.045996093750006, 43.505517578124994],
              [15.985546875000011, 43.519775390625],
              [15.942578125000011, 43.5689453125],
              [15.949121093750023, 43.606982421874996],
              [15.941503906250006, 43.656640625],
              [15.820605468750017, 43.7359375],
              [15.655664062500023, 43.811279296875],
              [15.499414062500023, 43.9087890625],
              [15.185839843750017, 44.172119140625],
              [15.122949218750023, 44.256787109375],
              [15.184667968750006, 44.272900390625],
              [15.231347656250023, 44.271435546875],
              [15.284277343750006, 44.288818359375],
              [15.369726562500006, 44.2892578125],
              [15.470996093750017, 44.27197265625],
              [15.38134765625, 44.328271484374994],
              [15.269824218750017, 44.38349609375],
              [14.981347656250023, 44.602929687499994],
              [14.895214843750011, 44.706591796874996],
              [14.88525390625, 44.818261718749994],
              [14.906542968750017, 44.97138671875],
              [14.854589843750006, 45.081005859375],
              [14.632031250000011, 45.222900390625],
              [14.550488281250011, 45.297705078125],
              [14.386132812500023, 45.342138671875],
              [14.312695312500011, 45.337792968749994],
              [14.2685546875, 45.282519531249996],
              [14.236328125, 45.15966796875],
              [14.090625, 44.997607421874996],
              [14.0419921875, 44.927197265625],
              [13.9658203125, 44.83564453125],
              [13.899804687500023, 44.829345703125],
              [13.860742187500023, 44.83740234375],
              [13.742480468750017, 44.991503906249996],
              [13.629296875000023, 45.108203125],
              [13.613476562500011, 45.163427734375],
              [13.603320312500017, 45.231396484375],
              [13.5171875, 45.481787109375],
              [13.577929687500017, 45.516894531249996],
            ],
          ],
          [
            [
              [16.650683593750017, 42.99658203125],
              [16.835546875, 42.968652343749994],
              [16.971093750000023, 42.981494140624996],
              [17.093652343750023, 42.96435546875],
              [17.169824218750023, 42.9326171875],
              [17.188281250000017, 42.917041015624996],
              [17.08935546875, 42.914892578125],
              [16.9775390625, 42.927783203124996],
              [16.850683593750006, 42.8955078125],
              [16.738867187500006, 42.912744140624994],
              [16.696386718750006, 42.93369140625],
              [16.666308593750017, 42.959912109375],
              [16.650683593750017, 42.99658203125],
            ],
          ],
          [
            [
              [17.19404296875001, 43.12578125],
              [17.124121093750006, 43.1154296875],
              [16.67919921875, 43.12314453125],
              [16.5498046875, 43.143896484375],
              [16.405859375, 43.19736328125],
              [16.37646484375, 43.213769531249994],
              [16.521386718750023, 43.229248046875],
              [16.65595703125001, 43.213769531249994],
              [16.697265625, 43.174951171874994],
              [17.061132812500006, 43.143896484375],
              [17.19404296875001, 43.12578125],
            ],
          ],
          [
            [
              [16.785253906250006, 43.270654296874994],
              [16.62744140625, 43.26806640625],
              [16.490332031250006, 43.286181640624996],
              [16.423144531250017, 43.317236328125],
              [16.428125, 43.343408203124994],
              [16.44892578125001, 43.387060546875],
              [16.6015625, 43.381884765624996],
              [16.834375, 43.350830078125],
              [16.89130859375001, 43.3146484375],
              [16.873632812500006, 43.29794921875],
              [16.785253906250006, 43.270654296874994],
            ],
          ],
          [
            [
              [15.231054687500006, 44.062304687499996],
              [15.246679687500006, 44.02705078125],
              [15.121875, 44.093310546874996],
              [15.074609375000023, 44.137841796874994],
              [15.065820312500023, 44.157666015625],
              [15.231054687500006, 44.062304687499996],
            ],
          ],
          [
            [
              [14.810253906250011, 44.97705078125],
              [14.68701171875, 44.955615234374996],
              [14.628320312500023, 44.993945312499996],
              [14.612988281250011, 45.025439453124996],
              [14.51171875, 45.035400390625],
              [14.450390625000011, 45.07919921875],
              [14.437890625000023, 45.0986328125],
              [14.524609375000011, 45.146826171875],
              [14.571093750000017, 45.224755859374994],
              [14.629980468750006, 45.178027343749996],
              [14.701171875, 45.0900390625],
              [14.739160156250023, 45.065478515624996],
              [14.810253906250011, 44.97705078125],
            ],
          ],
          [
            [
              [14.831445312500023, 44.758935546874994],
              [14.856640625000011, 44.71484375],
              [14.7625, 44.754638671875],
              [14.67822265625, 44.769873046875],
              [14.660351562500011, 44.7998046875],
              [14.672460937500006, 44.824365234374994],
              [14.690527343750006, 44.84814453125],
              [14.754199218750017, 44.84482421875],
              [14.763769531250006, 44.82138671875],
              [14.831445312500023, 44.758935546874994],
            ],
          ],
          [
            [
              [15.1884765625, 44.335742187499996],
              [15.16259765625, 44.3091796875],
              [15.097949218750017, 44.358154296875],
              [15.03857421875, 44.393017578125],
              [14.99609375, 44.434326171875],
              [14.912792968750011, 44.48583984375],
              [14.884667968750023, 44.544726562499996],
              [14.760449218750011, 44.66474609375],
              [14.741894531250011, 44.69736328125],
              [14.803808593750006, 44.648681640625],
              [14.855371093750023, 44.61826171875],
              [14.898046875, 44.61083984375],
              [15.006445312500006, 44.534228515624996],
              [15.112988281250011, 44.4357421875],
              [15.239941406250011, 44.3501953125],
              [15.213574218750011, 44.347558593749994],
              [15.1884765625, 44.335742187499996],
            ],
          ],
          [
            [
              [15.188769531250017, 43.92236328125],
              [15.203027343750023, 43.90771484375],
              [15.20166015625, 43.897753906249996],
              [15.149804687500023, 43.91181640625],
              [15.135839843750006, 43.907275390624996],
              [14.891308593750011, 44.125537109374996],
              [14.865039062500017, 44.16796875],
              [14.952539062500023, 44.1171875],
              [15.188769531250017, 43.92236328125],
            ],
          ],
          [
            [
              [15.371386718750017, 43.973828125],
              [15.437207031250011, 43.89951171875],
              [15.374218750000011, 43.914794921875],
              [15.30859375, 43.960791015625],
              [15.27001953125, 44.0107421875],
              [15.371386718750017, 43.973828125],
            ],
          ],
          [
            [
              [14.488085937500017, 44.660058593749994],
              [14.480371093750023, 44.621240234374994],
              [14.41953125, 44.6703125],
              [14.388867187500011, 44.75830078125],
              [14.312402343750023, 44.900390625],
              [14.302539062500017, 44.9404296875],
              [14.342187500000023, 44.979931640625],
              [14.340039062500011, 45.019970703125],
              [14.285839843750011, 45.14462890625],
              [14.33125, 45.164990234375],
              [14.358203125000017, 45.167431640625],
              [14.369140625, 45.080957031249994],
              [14.39375, 45.03125],
              [14.467382812500006, 44.97021484375],
              [14.452539062500023, 44.869189453124996],
              [14.467578125000017, 44.725341796875],
              [14.482519531250006, 44.693359375],
              [14.488085937500017, 44.660058593749994],
            ],
          ],
          [
            [
              [17.607812500000023, 42.76904296875],
              [17.744238281250006, 42.700341796874994],
              [17.344140625000023, 42.790380859375],
              [17.389550781250023, 42.798632812499996],
              [17.431933593750017, 42.800390625],
              [17.607812500000023, 42.76904296875],
            ],
          ],
          [
            [
              [18.436328125000017, 42.559716796874994],
              [18.438085937500006, 42.52294921875],
              [18.476660156250006, 42.481103515624994],
              [18.517480468750023, 42.432910156249996],
              [18.3330078125, 42.527880859374996],
              [18.16064453125, 42.634033203125],
              [17.823828125, 42.797412109374996],
              [17.5849609375, 42.837158203125],
              [17.258203125000023, 42.96845703125],
              [17.04541015625, 43.014892578125],
              [17.12646484375, 43.0255859375],
              [17.219824218750006, 43.02587890625],
              [17.7236328125, 42.85068359375],
              [17.667578125, 42.897119140624994],
              [17.740234375, 42.915478515625],
              [17.80195312500001, 42.902246093749994],
              [17.84130859375, 42.845068359375],
              [17.918847656250023, 42.807421875],
              [18.04453125, 42.741259765624996],
              [18.123925781250023, 42.690576171874994],
              [18.304003906250017, 42.599414062499996],
              [18.346582031250023, 42.586669921875],
              [18.436328125000017, 42.559716796874994],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 6,
        sovereignt: "Slovenia",
        sov_a3: "SVN",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Slovenia",
        adm0_a3: "SVN",
        geou_dif: 0,
        geounit: "Slovenia",
        gu_a3: "SVN",
        su_dif: 0,
        subunit: "Slovenia",
        su_a3: "SVN",
        brk_diff: 0,
        name: "Slovenia",
        name_long: "Slovenia",
        brk_a3: "SVN",
        brk_name: "Slovenia",
        brk_group: null,
        abbrev: "Slo.",
        postal: "SLO",
        formal_en: "Republic of Slovenia",
        formal_fr: null,
        name_ciawf: "Slovenia",
        note_adm0: null,
        note_brk: null,
        name_sort: "Slovenia",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 3,
        mapcolor9: 2,
        mapcolor13: 12,
        pop_est: 2087946,
        pop_rank: 12,
        pop_year: 2019,
        gdp_md: 54174,
        gdp_year: 2019,
        economy: "2. Developed region: nonG7",
        income_grp: "1. High income: OECD",
        fips_10: "SI",
        iso_a2: "SI",
        iso_a2_eh: "SI",
        iso_a3: "SVN",
        iso_a3_eh: "SVN",
        iso_n3: "705",
        iso_n3_eh: "705",
        un_a3: "705",
        wb_a2: "SI",
        wb_a3: "SVN",
        woe_id: 23424945,
        woe_id_eh: 23424945,
        woe_note: "Exact WOE match as country",
        adm0_iso: "SVN",
        adm0_diff: null,
        adm0_tlc: "SVN",
        adm0_a3_us: "SVN",
        adm0_a3_fr: "SVN",
        adm0_a3_ru: "SVN",
        adm0_a3_es: "SVN",
        adm0_a3_cn: "SVN",
        adm0_a3_tw: "SVN",
        adm0_a3_in: "SVN",
        adm0_a3_np: "SVN",
        adm0_a3_pk: "SVN",
        adm0_a3_de: "SVN",
        adm0_a3_gb: "SVN",
        adm0_a3_br: "SVN",
        adm0_a3_il: "SVN",
        adm0_a3_ps: "SVN",
        adm0_a3_sa: "SVN",
        adm0_a3_eg: "SVN",
        adm0_a3_ma: "SVN",
        adm0_a3_pt: "SVN",
        adm0_a3_ar: "SVN",
        adm0_a3_jp: "SVN",
        adm0_a3_ko: "SVN",
        adm0_a3_vn: "SVN",
        adm0_a3_tr: "SVN",
        adm0_a3_id: "SVN",
        adm0_a3_pl: "SVN",
        adm0_a3_gr: "SVN",
        adm0_a3_it: "SVN",
        adm0_a3_nl: "SVN",
        adm0_a3_se: "SVN",
        adm0_a3_bd: "SVN",
        adm0_a3_ua: "SVN",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Southern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 8,
        long_len: 8,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 5,
        max_label: 10,
        label_x: 14.915312,
        label_y: 46.06076,
        ne_id: 1159321285,
        wikidataid: "Q215",
        name_ar: "سلوفينيا",
        name_bn: "স্লোভেনিয়া",
        name_de: "Slowenien",
        name_en: "Slovenia",
        name_es: "Eslovenia",
        name_fa: "اسلوونی",
        name_fr: "Slovénie",
        name_el: "Σλοβενία",
        name_he: "סלובניה",
        name_hi: "स्लोवेनिया",
        name_hu: "Szlovénia",
        name_id: "Slovenia",
        name_it: "Slovenia",
        name_ja: "スロベニア",
        name_ko: "슬로베니아",
        name_nl: "Slovenië",
        name_pl: "Słowenia",
        name_pt: "Eslovénia",
        name_ru: "Словения",
        name_sv: "Slovenien",
        name_tr: "Slovenya",
        name_uk: "Словенія",
        name_ur: "سلووینیا",
        name_vi: "Slovenia",
        name_zh: "斯洛文尼亚",
        name_zht: "斯洛維尼亞",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "SVN.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [16.516210937500006, 46.499902343749994],
            [16.427636718750023, 46.5244140625],
            [16.321191406250023, 46.534619140625],
            [16.301171875000023, 46.521386718749994],
            [16.258398437500006, 46.50791015625],
            [16.236718750000023, 46.483837890625],
            [16.253320312500023, 46.389111328125],
            [16.227441406250023, 46.372851562499996],
            [16.1064453125, 46.382226562499994],
            [16.066503906250006, 46.371337890625],
            [16.00068359375001, 46.30537109375],
            [15.933300781250011, 46.277636718749996],
            [15.847558593750023, 46.257861328124996],
            [15.784277343750006, 46.233984375],
            [15.704199218750006, 46.213232421875],
            [15.635937500000011, 46.200732421874996],
            [15.608984375, 46.171923828124996],
            [15.592578125000017, 46.139990234375],
            [15.596875, 46.109228515625],
            [15.666210937500011, 46.048486328124994],
            [15.675585937500017, 45.983691406249996],
            [15.668066406250006, 45.904443359374994],
            [15.652148437500017, 45.862158203125],
            [15.624804687500017, 45.834033203124996],
            [15.4541015625, 45.797607421875],
            [15.277050781250011, 45.7326171875],
            [15.27294921875, 45.717724609375],
            [15.353710937500011, 45.659912109375],
            [15.35693359375, 45.6455078125],
            [15.290136718750006, 45.612646484375],
            [15.283593750000023, 45.5796875],
            [15.291210937500011, 45.541552734374996],
            [15.32666015625, 45.502294921875],
            [15.339453125, 45.467041015625],
            [15.242089843750023, 45.44140625],
            [15.110449218750006, 45.45078125],
            [14.95458984375, 45.499902343749994],
            [14.9, 45.49267578125],
            [14.847070312500023, 45.467333984374996],
            [14.793066406250006, 45.47822265625],
            [14.733593750000011, 45.50849609375],
            [14.649511718750006, 45.571484375],
            [14.608593750000011, 45.610107421875],
            [14.591796875, 45.651269531249994],
            [14.56884765625, 45.6572265625],
            [14.533984375000017, 45.645263671875],
            [14.505175781250017, 45.59521484375],
            [14.42734375, 45.505761718749994],
            [14.369921875000017, 45.4814453125],
            [14.283007812500017, 45.486621093749996],
            [14.161230468750006, 45.48515625],
            [14.085546875, 45.477832031249996],
            [13.992773437500006, 45.509423828124994],
            [13.9703125, 45.503369140625],
            [13.970117187500023, 45.4826171875],
            [13.935644531250006, 45.4498046875],
            [13.878710937500017, 45.428369140624994],
            [13.615234375, 45.4767578125],
            [13.577929687500017, 45.516894531249996],
            [13.637304687500006, 45.5359375],
            [13.719824218750006, 45.58759765625],
            [13.775976562500006, 45.581982421875],
            [13.8447265625, 45.59287109375],
            [13.874707031250011, 45.61484375],
            [13.831152343750006, 45.680419921875],
            [13.7216796875, 45.761279296874996],
            [13.663476562500023, 45.7919921875],
            [13.583398437500023, 45.812353515625],
            [13.569628906250017, 45.834130859374994],
            [13.613964843750011, 45.961669921875],
            [13.6005859375, 45.979785156249996],
            [13.509179687500023, 45.973779296874994],
            [13.487695312500023, 45.987109375],
            [13.480273437500017, 46.009228515625],
            [13.486425781250006, 46.03955078125],
            [13.548046875000011, 46.089111328125],
            [13.616601562500023, 46.133105468749996],
            [13.634960937500011, 46.157763671874996],
            [13.632519531250011, 46.177050781249996],
            [13.544726562500017, 46.196582031249996],
            [13.491796875, 46.216601562499996],
            [13.449804687500006, 46.223535156249994],
            [13.420996093750006, 46.212304687499994],
            [13.399609375000011, 46.224951171875],
            [13.378222656250017, 46.261621093749994],
            [13.399511718750006, 46.317529296874994],
            [13.478515625, 46.369189453124996],
            [13.563281250000017, 46.415087890624996],
            [13.637109375000023, 46.448535156249996],
            [13.6796875, 46.462890625],
            [13.7, 46.520263671875],
            [13.743945312500017, 46.514306640624994],
            [13.831347656250017, 46.51123046875],
            [13.928808593750006, 46.498193359374994],
            [14.019628906250006, 46.482177734375],
            [14.099511718750023, 46.4619140625],
            [14.267285156250011, 46.440722656249996],
            [14.419921875, 46.4279296875],
            [14.465917968750006, 46.41611328125],
            [14.503515625, 46.417041015624996],
            [14.5498046875, 46.399707031249996],
            [14.5771484375, 46.412939453125],
            [14.596972656250017, 46.436083984374996],
            [14.68017578125, 46.463427734374996],
            [14.756738281250023, 46.49912109375],
            [14.810546875, 46.544580078124994],
            [14.840625, 46.58046875],
            [14.893261718750011, 46.605908203125],
            [14.949414062500011, 46.613232421875],
            [15.000683593750011, 46.6259765625],
            [15.216992187500011, 46.64296875],
            [15.439257812500017, 46.629638671875],
            [15.545312500000023, 46.654638671875],
            [15.632617187500017, 46.6984375],
            [15.76025390625, 46.710742187499996],
            [15.766894531250017, 46.711279296875],
            [15.957617187500006, 46.677636718749994],
            [15.972265625, 46.697216796875],
            [15.98046875, 46.705859375],
            [15.976855468750017, 46.8013671875],
            [16.037207031250006, 46.84482421875],
            [16.093066406250017, 46.86328125],
            [16.283593750000023, 46.857275390625],
            [16.308496093750023, 46.827978515625],
            [16.318457031250006, 46.782519531249996],
            [16.33544921875, 46.721630859375],
            [16.3671875, 46.70478515625],
            [16.384570312500017, 46.680810546874994],
            [16.38125, 46.638671875],
            [16.41845703125, 46.607226562499996],
            [16.505664062500017, 46.5220703125],
            [16.516210937500006, 46.499902343749994],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 4,
        sovereignt: "Bulgaria",
        sov_a3: "BGR",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Bulgaria",
        adm0_a3: "BGR",
        geou_dif: 0,
        geounit: "Bulgaria",
        gu_a3: "BGR",
        su_dif: 0,
        subunit: "Bulgaria",
        su_a3: "BGR",
        brk_diff: 0,
        name: "Bulgaria",
        name_long: "Bulgaria",
        brk_a3: "BGR",
        brk_name: "Bulgaria",
        brk_group: null,
        abbrev: "Bulg.",
        postal: "BG",
        formal_en: "Republic of Bulgaria",
        formal_fr: null,
        name_ciawf: "Bulgaria",
        note_adm0: null,
        note_brk: null,
        name_sort: "Bulgaria",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 5,
        mapcolor9: 1,
        mapcolor13: 8,
        pop_est: 6975761,
        pop_rank: 13,
        pop_year: 2019,
        gdp_md: 68558,
        gdp_year: 2019,
        economy: "2. Developed region: nonG7",
        income_grp: "3. Upper middle income",
        fips_10: "BU",
        iso_a2: "BG",
        iso_a2_eh: "BG",
        iso_a3: "BGR",
        iso_a3_eh: "BGR",
        iso_n3: "100",
        iso_n3_eh: "100",
        un_a3: "100",
        wb_a2: "BG",
        wb_a3: "BGR",
        woe_id: 23424771,
        woe_id_eh: 23424771,
        woe_note: "Exact WOE match as country",
        adm0_iso: "BGR",
        adm0_diff: null,
        adm0_tlc: "BGR",
        adm0_a3_us: "BGR",
        adm0_a3_fr: "BGR",
        adm0_a3_ru: "BGR",
        adm0_a3_es: "BGR",
        adm0_a3_cn: "BGR",
        adm0_a3_tw: "BGR",
        adm0_a3_in: "BGR",
        adm0_a3_np: "BGR",
        adm0_a3_pk: "BGR",
        adm0_a3_de: "BGR",
        adm0_a3_gb: "BGR",
        adm0_a3_br: "BGR",
        adm0_a3_il: "BGR",
        adm0_a3_ps: "BGR",
        adm0_a3_sa: "BGR",
        adm0_a3_eg: "BGR",
        adm0_a3_ma: "BGR",
        adm0_a3_pt: "BGR",
        adm0_a3_ar: "BGR",
        adm0_a3_jp: "BGR",
        adm0_a3_ko: "BGR",
        adm0_a3_vn: "BGR",
        adm0_a3_tr: "BGR",
        adm0_a3_id: "BGR",
        adm0_a3_pl: "BGR",
        adm0_a3_gr: "BGR",
        adm0_a3_it: "BGR",
        adm0_a3_nl: "BGR",
        adm0_a3_se: "BGR",
        adm0_a3_bd: "BGR",
        adm0_a3_ua: "BGR",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Eastern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 8,
        long_len: 8,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: 25.15709,
        label_y: 42.508785,
        ne_id: 1159320409,
        wikidataid: "Q219",
        name_ar: "بلغاريا",
        name_bn: "বুলগেরিয়া",
        name_de: "Bulgarien",
        name_en: "Bulgaria",
        name_es: "Bulgaria",
        name_fa: "بلغارستان",
        name_fr: "Bulgarie",
        name_el: "Βουλγαρία",
        name_he: "בולגריה",
        name_hi: "बुल्गारिया",
        name_hu: "Bulgária",
        name_id: "Bulgaria",
        name_it: "Bulgaria",
        name_ja: "ブルガリア",
        name_ko: "불가리아",
        name_nl: "Bulgarije",
        name_pl: "Bułgaria",
        name_pt: "Bulgária",
        name_ru: "Болгария",
        name_sv: "Bulgarien",
        name_tr: "Bulgaristan",
        name_uk: "Болгарія",
        name_ur: "بلغاریہ",
        name_vi: "Bulgaria",
        name_zh: "保加利亚",
        name_zht: "保加利亞",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "BGR.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [28.014453125000017, 41.969042968749996],
            [27.879199218750017, 41.986621093749996],
            [27.831933593750023, 41.981298828125],
            [27.801660156250023, 41.95654296875],
            [27.738867187500006, 41.9615234375],
            [27.6611328125, 41.961328125],
            [27.579882812500017, 41.932910156249996],
            [27.53486328125001, 41.920800781249994],
            [27.47480468750001, 41.946875],
            [27.362890625, 42.025048828124994],
            [27.294921875, 42.079541015625],
            [27.24433593750001, 42.09326171875],
            [27.193359375, 42.077099609375],
            [27.01171875, 42.058642578124996],
            [26.96875, 42.02685546875],
            [26.884863281250006, 41.991845703124994],
            [26.800390625, 41.975146484374996],
            [26.67919921875, 41.963330078125],
            [26.615332031250006, 41.964892578124996],
            [26.5796875, 41.94794921875],
            [26.549707031250023, 41.896728515625],
            [26.529296875, 41.8466796875],
            [26.51142578125001, 41.8263671875],
            [26.3603515625, 41.8015625],
            [26.327246093750006, 41.772802734375],
            [26.31796875, 41.744677734374996],
            [26.320898437500006, 41.716552734375],
            [26.200585937500023, 41.743798828124994],
            [26.107421875, 41.72568359375],
            [26.085546875, 41.704150390624996],
            [26.066015625, 41.673242187499994],
            [26.076953125000017, 41.640185546874996],
            [26.111230468750023, 41.608203125],
            [26.1435546875, 41.521533203124996],
            [26.155175781250023, 41.434863281249996],
            [26.135351562500006, 41.3857421875],
            [26.06640625, 41.35068359375],
            [25.92333984375, 41.311914062499994],
            [25.784960937500017, 41.330419921875],
            [25.723925781250017, 41.3150390625],
            [25.621484375000023, 41.310107421874996],
            [25.52705078125001, 41.2998046875],
            [25.381933593750006, 41.26435546875],
            [25.25117187500001, 41.243554687499994],
            [25.133398437500006, 41.315771484375],
            [24.993554687500023, 41.364990234375],
            [24.846875, 41.39423828125],
            [24.795800781250023, 41.372900390625],
            [24.773730468750017, 41.356103515624994],
            [24.65107421875001, 41.419970703124996],
            [24.595996093750017, 41.442724609375],
            [24.5693359375, 41.4673828125],
            [24.51826171875001, 41.552539062499996],
            [24.487890625, 41.555224609374996],
            [24.38671875, 41.52353515625],
            [24.289453125000023, 41.525048828124994],
            [24.230371093750023, 41.530810546874996],
            [24.056054687500023, 41.527246093749994],
            [24.03291015625001, 41.469091796875],
            [24.011328125, 41.46005859375],
            [23.973535156250023, 41.452294921874994],
            [23.880859375, 41.455957031249994],
            [23.762304687500006, 41.412988281249994],
            [23.635156250000023, 41.386767578124996],
            [23.53583984375001, 41.386035156249996],
            [23.433398437500017, 41.398730468749996],
            [23.3720703125, 41.3896484375],
            [23.23984375, 41.3849609375],
            [23.15595703125001, 41.322070312499996],
            [23.02558593750001, 41.325634765625],
            [22.916015625, 41.336279296875],
            [22.9296875, 41.356103515624994],
            [22.951464843750017, 41.605615234374994],
            [23.005664062500017, 41.716943359374994],
            [23.00361328125001, 41.73984375],
            [22.991992187500017, 41.757177734375],
            [22.943945312500006, 41.77509765625],
            [22.9091796875, 41.835205078125],
            [22.836816406250023, 41.993603515625],
            [22.79609375000001, 42.025683593749996],
            [22.68232421875001, 42.059130859374996],
            [22.58271484375001, 42.104833984375],
            [22.49824218750001, 42.165087890624996],
            [22.344042968750017, 42.31396484375],
            [22.42207031250001, 42.328857421875],
            [22.445703125000023, 42.359130859375],
            [22.523535156250006, 42.440966796874996],
            [22.53242187500001, 42.481201171875],
            [22.524218750000017, 42.50390625],
            [22.472070312500023, 42.543310546875],
            [22.43623046875001, 42.6291015625],
            [22.463281250000023, 42.70947265625],
            [22.465625, 42.75078125],
            [22.439257812500017, 42.791650390624994],
            [22.466796875, 42.84248046875],
            [22.522753906250017, 42.8703125],
            [22.55810546875, 42.878466796874996],
            [22.706152343750006, 42.883935546874994],
            [22.799902343750006, 42.985742187499994],
            [22.856835937500023, 43.01826171875],
            [22.91523437500001, 43.075976562499996],
            [22.942285156250023, 43.097070312499994],
            [22.96796875000001, 43.142041015625],
            [22.976855468750017, 43.18798828125],
            [22.85957031250001, 43.25234375],
            [22.819726562500023, 43.300732421875],
            [22.767578125, 43.354150390624994],
            [22.69697265625001, 43.391064453125],
            [22.554589843750023, 43.454492187499994],
            [22.499121093750006, 43.518847656249996],
            [22.47412109375, 43.60224609375],
            [22.436328125000017, 43.665478515625],
            [22.394824218750017, 43.706640625],
            [22.38691406250001, 43.740136718749994],
            [22.36962890625, 43.781298828124996],
            [22.36542968750001, 43.862109375],
            [22.399023437500006, 43.96953125],
            [22.420800781250023, 44.007421875],
            [22.469042968750017, 44.018017578125],
            [22.597460937500017, 44.07529296875],
            [22.603417968750023, 44.148583984374994],
            [22.6265625, 44.194091796875],
            [22.66748046875, 44.22021484375],
            [22.705078125, 44.23779296875],
            [22.775195312500017, 44.195214843749994],
            [22.945410156250006, 44.127294921875],
            [23.02851562500001, 44.077978515625],
            [23.0244140625, 44.047216796875],
            [22.9853515625, 44.016992187499994],
            [22.91132812500001, 43.987207031249994],
            [22.868261718750006, 43.947900390624994],
            [22.8564453125, 43.8990234375],
            [22.86767578125, 43.864550781249996],
            [22.919042968750006, 43.83447265625],
            [23.224609375, 43.873876953125],
            [23.534570312500023, 43.853564453124996],
            [23.95078125, 43.786669921874996],
            [24.22675781250001, 43.763476562499996],
            [24.430566406250023, 43.794384765625],
            [24.808203125, 43.738427734374994],
            [25.15966796875, 43.686328125],
            [25.4970703125, 43.670800781249994],
            [25.686132812500006, 43.711767578125],
            [25.81884765625, 43.766845703125],
            [25.933398437500017, 43.870556640625],
            [26.2158203125, 44.007275390625],
            [26.4892578125, 44.083984375],
            [26.847753906250006, 44.14619140625],
            [27.0869140625, 44.167382812499994],
            [27.120703125, 44.146142578124994],
            [27.425390625, 44.0205078125],
            [27.56103515625, 44.020068359374996],
            [27.6708984375, 43.997802734375],
            [27.710742187500017, 43.964599609375],
            [27.738574218750017, 43.956298828125],
            [27.88427734375, 43.987353515624996],
            [27.94892578125001, 43.918603515624994],
            [28.05, 43.822412109374994],
            [28.221972656250017, 43.772851562499994],
            [28.37519531250001, 43.744775390624994],
            [28.4234375, 43.740478515625],
            [28.585351562500023, 43.742236328124996],
            [28.561816406250017, 43.501318359375],
            [28.465429687500006, 43.389306640624994],
            [28.319628906250017, 43.42685546875],
            [28.133691406250023, 43.39560546875],
            [28.03515625, 43.26826171875],
            [27.979296875000017, 43.230517578124996],
            [27.92890625000001, 43.1861328125],
            [27.896484375, 43.020703125],
            [27.88886718750001, 42.74970703125],
            [27.818359375, 42.716650390625],
            [27.753710937500017, 42.70654296875],
            [27.484765625000023, 42.468066406249996],
            [27.639550781250023, 42.4009765625],
            [27.70820312500001, 42.349951171875],
            [27.821386718750006, 42.2080078125],
            [27.982714843750017, 42.047412109374996],
            [28.014453125000017, 41.969042968749996],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 6,
        labelrank: 6,
        sovereignt: "Monaco",
        sov_a3: "MCO",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Monaco",
        adm0_a3: "MCO",
        geou_dif: 0,
        geounit: "Monaco",
        gu_a3: "MCO",
        su_dif: 0,
        subunit: "Monaco",
        su_a3: "MCO",
        brk_diff: 0,
        name: "Monaco",
        name_long: "Monaco",
        brk_a3: "MCO",
        brk_name: "Monaco",
        brk_group: null,
        abbrev: "Mco.",
        postal: "MC",
        formal_en: "Principality of Monaco",
        formal_fr: null,
        name_ciawf: "Monaco",
        note_adm0: null,
        note_brk: null,
        name_sort: "Monaco",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 1,
        mapcolor9: 2,
        mapcolor13: 12,
        pop_est: 38964,
        pop_rank: 7,
        pop_year: 2019,
        gdp_md: 7188,
        gdp_year: 2018,
        economy: "2. Developed region: nonG7",
        income_grp: "2. High income: nonOECD",
        fips_10: "MN",
        iso_a2: "MC",
        iso_a2_eh: "MC",
        iso_a3: "MCO",
        iso_a3_eh: "MCO",
        iso_n3: "492",
        iso_n3_eh: "492",
        un_a3: "492",
        wb_a2: "MC",
        wb_a3: "MCO",
        woe_id: 23424892,
        woe_id_eh: 23424892,
        woe_note: "Exact WOE match as country",
        adm0_iso: "MCO",
        adm0_diff: null,
        adm0_tlc: "MCO",
        adm0_a3_us: "MCO",
        adm0_a3_fr: "MCO",
        adm0_a3_ru: "MCO",
        adm0_a3_es: "MCO",
        adm0_a3_cn: "MCO",
        adm0_a3_tw: "MCO",
        adm0_a3_in: "MCO",
        adm0_a3_np: "MCO",
        adm0_a3_pk: "MCO",
        adm0_a3_de: "MCO",
        adm0_a3_gb: "MCO",
        adm0_a3_br: "MCO",
        adm0_a3_il: "MCO",
        adm0_a3_ps: "MCO",
        adm0_a3_sa: "MCO",
        adm0_a3_eg: "MCO",
        adm0_a3_ma: "MCO",
        adm0_a3_pt: "MCO",
        adm0_a3_ar: "MCO",
        adm0_a3_jp: "MCO",
        adm0_a3_ko: "MCO",
        adm0_a3_vn: "MCO",
        adm0_a3_tr: "MCO",
        adm0_a3_id: "MCO",
        adm0_a3_pl: "MCO",
        adm0_a3_gr: "MCO",
        adm0_a3_it: "MCO",
        adm0_a3_nl: "MCO",
        adm0_a3_se: "MCO",
        adm0_a3_bd: "MCO",
        adm0_a3_ua: "MCO",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Western Europe",
        region_wb: "Europe & Central Asia",
        name_len: 6,
        long_len: 6,
        abbrev_len: 4,
        tiny: 5,
        homepart: 1,
        min_zoom: 0,
        min_label: 5,
        max_label: 10,
        label_x: 7.398291,
        label_y: 43.739652,
        ne_id: 1159321043,
        wikidataid: "Q235",
        name_ar: "موناكو",
        name_bn: "মোনাকো",
        name_de: "Monaco",
        name_en: "Monaco",
        name_es: "Mónaco",
        name_fa: "موناکو",
        name_fr: "Monaco",
        name_el: "Μονακό",
        name_he: "מונקו",
        name_hi: "मोनैको",
        name_hu: "Monaco",
        name_id: "Monako",
        name_it: "Principato di Monaco",
        name_ja: "モナコ",
        name_ko: "모나코",
        name_nl: "Monaco",
        name_pl: "Monako",
        name_pt: "Mónaco",
        name_ru: "Монако",
        name_sv: "Monaco",
        name_tr: "Monako",
        name_uk: "Монако",
        name_ur: "موناکو",
        name_vi: "Monaco",
        name_zh: "摩纳哥",
        name_zht: "摩納哥",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "MCO.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [7.438671875000011, 43.750439453125],
            [7.377734375000017, 43.731738281249996],
            [7.380078125000011, 43.753222656249996],
            [7.39501953125, 43.76533203125],
            [7.414453125000023, 43.770898437499994],
            [7.436914062500023, 43.761474609375],
            [7.438671875000011, 43.750439453125],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 3,
        labelrank: 6,
        sovereignt: "Andorra",
        sov_a3: "AND",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Andorra",
        adm0_a3: "AND",
        geou_dif: 0,
        geounit: "Andorra",
        gu_a3: "AND",
        su_dif: 0,
        subunit: "Andorra",
        su_a3: "AND",
        brk_diff: 0,
        name: "Andorra",
        name_long: "Andorra",
        brk_a3: "AND",
        brk_name: "Andorra",
        brk_group: null,
        abbrev: "And.",
        postal: "AND",
        formal_en: "Principality of Andorra",
        formal_fr: null,
        name_ciawf: "Andorra",
        note_adm0: null,
        note_brk: null,
        name_sort: "Andorra",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 4,
        mapcolor9: 1,
        mapcolor13: 8,
        pop_est: 77142,
        pop_rank: 8,
        pop_year: 2019,
        gdp_md: 3154,
        gdp_year: 2019,
        economy: "2. Developed region: nonG7",
        income_grp: "2. High income: nonOECD",
        fips_10: "AN",
        iso_a2: "AD",
        iso_a2_eh: "AD",
        iso_a3: "AND",
        iso_a3_eh: "AND",
        iso_n3: "020",
        iso_n3_eh: "020",
        un_a3: "020",
        wb_a2: "AD",
        wb_a3: "ADO",
        woe_id: 23424744,
        woe_id_eh: 23424744,
        woe_note: "Exact WOE match as country",
        adm0_iso: "AND",
        adm0_diff: null,
        adm0_tlc: "AND",
        adm0_a3_us: "AND",
        adm0_a3_fr: "AND",
        adm0_a3_ru: "AND",
        adm0_a3_es: "AND",
        adm0_a3_cn: "AND",
        adm0_a3_tw: "AND",
        adm0_a3_in: "AND",
        adm0_a3_np: "AND",
        adm0_a3_pk: "AND",
        adm0_a3_de: "AND",
        adm0_a3_gb: "AND",
        adm0_a3_br: "AND",
        adm0_a3_il: "AND",
        adm0_a3_ps: "AND",
        adm0_a3_sa: "AND",
        adm0_a3_eg: "AND",
        adm0_a3_ma: "AND",
        adm0_a3_pt: "AND",
        adm0_a3_ar: "AND",
        adm0_a3_jp: "AND",
        adm0_a3_ko: "AND",
        adm0_a3_vn: "AND",
        adm0_a3_tr: "AND",
        adm0_a3_id: "AND",
        adm0_a3_pl: "AND",
        adm0_a3_gr: "AND",
        adm0_a3_it: "AND",
        adm0_a3_nl: "AND",
        adm0_a3_se: "AND",
        adm0_a3_bd: "AND",
        adm0_a3_ua: "AND",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Southern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: 5,
        homepart: 1,
        min_zoom: 0,
        min_label: 5,
        max_label: 10,
        label_x: 1.539409,
        label_y: 42.547643,
        ne_id: 1159320327,
        wikidataid: "Q228",
        name_ar: "أندورا",
        name_bn: "অ্যান্ডোরা",
        name_de: "Andorra",
        name_en: "Andorra",
        name_es: "Andorra",
        name_fa: "آندورا",
        name_fr: "Andorre",
        name_el: "Ανδόρρα",
        name_he: "אנדורה",
        name_hi: "अण्डोरा",
        name_hu: "Andorra",
        name_id: "Andorra",
        name_it: "Andorra",
        name_ja: "アンドラ",
        name_ko: "안도라",
        name_nl: "Andorra",
        name_pl: "Andora",
        name_pt: "Andorra",
        name_ru: "Андорра",
        name_sv: "Andorra",
        name_tr: "Andorra",
        name_uk: "Андорра",
        name_ur: "انڈورا",
        name_vi: "Andorra",
        name_zh: "安道尔",
        name_zht: "安道爾",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "AND.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [1.7060546875, 42.503320312499994],
            [1.678515625000017, 42.4966796875],
            [1.58642578125, 42.455957031249994],
            [1.534082031250023, 42.441699218749996],
            [1.486230468750023, 42.434472656249994],
            [1.448828125, 42.437451171875],
            [1.428125, 42.461328125],
            [1.430273437500006, 42.497851562499996],
            [1.421972656250006, 42.530810546874996],
            [1.414843750000017, 42.548388671874996],
            [1.428320312500006, 42.5958984375],
            [1.458886718750023, 42.6216796875],
            [1.501367187500023, 42.642724609374994],
            [1.568164062500017, 42.635009765625],
            [1.709863281250023, 42.604443359375],
            [1.739453125000011, 42.575927734375],
            [1.740234375, 42.55673828125],
            [1.713964843750006, 42.525634765625],
            [1.7060546875, 42.503320312499994],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 6,
        sovereignt: "Montenegro",
        sov_a3: "MNE",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Montenegro",
        adm0_a3: "MNE",
        geou_dif: 0,
        geounit: "Montenegro",
        gu_a3: "MNE",
        su_dif: 0,
        subunit: "Montenegro",
        su_a3: "MNE",
        brk_diff: 0,
        name: "Montenegro",
        name_long: "Montenegro",
        brk_a3: "MNE",
        brk_name: "Montenegro",
        brk_group: null,
        abbrev: "Mont.",
        postal: "ME",
        formal_en: "Montenegro",
        formal_fr: null,
        name_ciawf: "Montenegro",
        note_adm0: null,
        note_brk: null,
        name_sort: "Montenegro",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 1,
        mapcolor9: 4,
        mapcolor13: 5,
        pop_est: 622137,
        pop_rank: 11,
        pop_year: 2019,
        gdp_md: 5542,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        fips_10: "MJ",
        iso_a2: "ME",
        iso_a2_eh: "ME",
        iso_a3: "MNE",
        iso_a3_eh: "MNE",
        iso_n3: "499",
        iso_n3_eh: "499",
        un_a3: "499",
        wb_a2: "ME",
        wb_a3: "MNE",
        woe_id: 20069817,
        woe_id_eh: 20069817,
        woe_note: "Exact WOE match as country",
        adm0_iso: "MNE",
        adm0_diff: null,
        adm0_tlc: "MNE",
        adm0_a3_us: "MNE",
        adm0_a3_fr: "MNE",
        adm0_a3_ru: "MNE",
        adm0_a3_es: "MNE",
        adm0_a3_cn: "MNE",
        adm0_a3_tw: "MNE",
        adm0_a3_in: "MNE",
        adm0_a3_np: "MNE",
        adm0_a3_pk: "MNE",
        adm0_a3_de: "MNE",
        adm0_a3_gb: "MNE",
        adm0_a3_br: "MNE",
        adm0_a3_il: "MNE",
        adm0_a3_ps: "MNE",
        adm0_a3_sa: "MNE",
        adm0_a3_eg: "MNE",
        adm0_a3_ma: "MNE",
        adm0_a3_pt: "MNE",
        adm0_a3_ar: "MNE",
        adm0_a3_jp: "MNE",
        adm0_a3_ko: "MNE",
        adm0_a3_vn: "MNE",
        adm0_a3_tr: "MNE",
        adm0_a3_id: "MNE",
        adm0_a3_pl: "MNE",
        adm0_a3_gr: "MNE",
        adm0_a3_it: "MNE",
        adm0_a3_nl: "MNE",
        adm0_a3_se: "MNE",
        adm0_a3_bd: "MNE",
        adm0_a3_ua: "MNE",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Southern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 10,
        long_len: 10,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 5,
        max_label: 10,
        label_x: 19.143727,
        label_y: 42.803101,
        ne_id: 1159321069,
        wikidataid: "Q236",
        name_ar: "الجبل الأسود",
        name_bn: "মন্টিনিগ্রো",
        name_de: "Montenegro",
        name_en: "Montenegro",
        name_es: "Montenegro",
        name_fa: "مونتهنگرو",
        name_fr: "Monténégro",
        name_el: "Μαυροβούνιο",
        name_he: "מונטנגרו",
        name_hi: "मॉन्टेनीग्रो",
        name_hu: "Montenegró",
        name_id: "Montenegro",
        name_it: "Montenegro",
        name_ja: "モンテネグロ",
        name_ko: "몬테네그로",
        name_nl: "Montenegro",
        name_pl: "Czarnogóra",
        name_pt: "Montenegro",
        name_ru: "Черногория",
        name_sv: "Montenegro",
        name_tr: "Karadağ",
        name_uk: "Чорногорія",
        name_ur: "مونٹینیگرو",
        name_vi: "Montenegro",
        name_zh: "黑山",
        name_zht: "蒙特內哥羅",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "MNE.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [19.1943359375, 43.53330078125],
            [19.19160156250001, 43.521044921874996],
            [19.19648437500001, 43.485009765624994],
            [19.21875, 43.449951171875],
            [19.298242187500023, 43.413964843749994],
            [19.414648437500006, 43.342822265624996],
            [19.551562500000017, 43.212255859375],
            [19.61445312500001, 43.1734375],
            [19.670996093750006, 43.163964843749994],
            [19.781152343750023, 43.109765625],
            [19.858007812500006, 43.096533203125],
            [19.94404296875001, 43.081640625],
            [20.167871093750023, 42.968505859375],
            [20.268457031250023, 42.935449218749994],
            [20.339941406250006, 42.892871093749996],
            [20.34765625, 42.852783203125],
            [20.344335937500006, 42.827929687499996],
            [20.215136718750017, 42.798828125],
            [20.19257812500001, 42.754638671875],
            [20.129980468750006, 42.759765625],
            [20.054296875, 42.760058593749996],
            [20.02949218750001, 42.73203125],
            [20.065722656250017, 42.685839843749996],
            [20.089257812500023, 42.63154296875],
            [20.0703125, 42.557080078125],
            [20.06396484375, 42.547265625],
            [20.045703125000017, 42.54990234375],
            [19.9390625, 42.506689453125],
            [19.859765625000023, 42.486328125],
            [19.78828125000001, 42.476171875],
            [19.754492187500006, 42.496923828125],
            [19.73779296875, 42.525146484375],
            [19.74072265625, 42.60693359375],
            [19.727832031250017, 42.634521484375],
            [19.703417968750017, 42.64794921875],
            [19.65449218750001, 42.628564453124994],
            [19.597460937500017, 42.5654296875],
            [19.54453125, 42.491943359375],
            [19.465136718750017, 42.415380859375],
            [19.39960937500001, 42.34189453125],
            [19.329003906250023, 42.249267578125],
            [19.280664062500023, 42.17255859375],
            [19.330859375000017, 42.129296875],
            [19.361425781250006, 42.069091796875],
            [19.352148437500006, 42.0240234375],
            [19.361132812500017, 41.99775390625],
            [19.345507812500017, 41.918847656249994],
            [19.342382812500006, 41.869091796875],
            [19.186425781250023, 41.948632812499994],
            [19.12226562500001, 42.060498046875],
            [18.89423828125001, 42.249462890625],
            [18.632910156250006, 42.378076171874994],
            [18.619042968750023, 42.398388671875],
            [18.633398437500006, 42.423144531249996],
            [18.645898437500023, 42.442724609375],
            [18.591601562500017, 42.444189453125],
            [18.553515625000017, 42.428515625],
            [18.517480468750023, 42.432910156249996],
            [18.476660156250006, 42.481103515624994],
            [18.438085937500006, 42.52294921875],
            [18.436328125000017, 42.559716796874994],
            [18.453906250000017, 42.564501953124996],
            [18.480078125, 42.57919921875],
            [18.534960937500017, 42.6201171875],
            [18.5458984375, 42.6416015625],
            [18.543261718750017, 42.674169921875],
            [18.46601562500001, 42.777246093749994],
            [18.455078125, 42.844091796875],
            [18.44384765625, 42.96845703125],
            [18.46015625000001, 42.997900390625],
            [18.48847656250001, 43.012158203125],
            [18.623632812500006, 43.027685546875],
            [18.621875, 43.124609375],
            [18.629980468750006, 43.153662109375],
            [18.656835937500006, 43.1939453125],
            [18.674218750000023, 43.230810546875],
            [18.74921875000001, 43.283544921875],
            [18.85107421875, 43.346337890624994],
            [18.895605468750006, 43.348193359374996],
            [18.934667968750006, 43.339453125],
            [18.97871093750001, 43.285400390625],
            [19.026660156250017, 43.292431640625],
            [19.03671875, 43.357324218749994],
            [18.97382812500001, 43.4423828125],
            [18.940234375000017, 43.496728515624994],
            [18.95068359375, 43.526660156249996],
            [18.97421875, 43.542333984375],
            [19.0283203125, 43.532519531249996],
            [19.080078125, 43.517724609374994],
            [19.11279296875, 43.527734375],
            [19.164355468750017, 43.535449218749996],
            [19.1943359375, 43.53330078125],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 5,
        sovereignt: "Bosnia and Herzegovina",
        sov_a3: "BIH",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Bosnia and Herzegovina",
        adm0_a3: "BIH",
        geou_dif: 0,
        geounit: "Bosnia and Herzegovina",
        gu_a3: "BIH",
        su_dif: 0,
        subunit: "Bosnia and Herzegovina",
        su_a3: "BIH",
        brk_diff: 0,
        name: "Bosnia and Herz.",
        name_long: "Bosnia and Herzegovina",
        brk_a3: "BIH",
        brk_name: "Bosnia and Herz.",
        brk_group: null,
        abbrev: "B.H.",
        postal: "BiH",
        formal_en: "Bosnia and Herzegovina",
        formal_fr: null,
        name_ciawf: "Bosnia and Herzegovina",
        note_adm0: null,
        note_brk: null,
        name_sort: "Bosnia and Herzegovina",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 1,
        mapcolor9: 1,
        mapcolor13: 2,
        pop_est: 3301000,
        pop_rank: 12,
        pop_year: 2019,
        gdp_md: 20164,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        fips_10: "BK",
        iso_a2: "BA",
        iso_a2_eh: "BA",
        iso_a3: "BIH",
        iso_a3_eh: "BIH",
        iso_n3: "070",
        iso_n3_eh: "070",
        un_a3: "070",
        wb_a2: "BA",
        wb_a3: "BIH",
        woe_id: 23424761,
        woe_id_eh: 23424761,
        woe_note: "Exact WOE match as country",
        adm0_iso: "BIH",
        adm0_diff: null,
        adm0_tlc: "BIH",
        adm0_a3_us: "BIH",
        adm0_a3_fr: "BIH",
        adm0_a3_ru: "BIH",
        adm0_a3_es: "BIH",
        adm0_a3_cn: "BIH",
        adm0_a3_tw: "BIH",
        adm0_a3_in: "BIH",
        adm0_a3_np: "BIH",
        adm0_a3_pk: "BIH",
        adm0_a3_de: "BIH",
        adm0_a3_gb: "BIH",
        adm0_a3_br: "BIH",
        adm0_a3_il: "BIH",
        adm0_a3_ps: "BIH",
        adm0_a3_sa: "BIH",
        adm0_a3_eg: "BIH",
        adm0_a3_ma: "BIH",
        adm0_a3_pt: "BIH",
        adm0_a3_ar: "BIH",
        adm0_a3_jp: "BIH",
        adm0_a3_ko: "BIH",
        adm0_a3_vn: "BIH",
        adm0_a3_tr: "BIH",
        adm0_a3_id: "BIH",
        adm0_a3_pl: "BIH",
        adm0_a3_gr: "BIH",
        adm0_a3_it: "BIH",
        adm0_a3_nl: "BIH",
        adm0_a3_se: "BIH",
        adm0_a3_bd: "BIH",
        adm0_a3_ua: "BIH",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Southern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 16,
        long_len: 22,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 4.5,
        max_label: 6.8,
        label_x: 18.06841,
        label_y: 44.091051,
        ne_id: 1159320417,
        wikidataid: "Q225",
        name_ar: "البوسنة والهرسك",
        name_bn: "বসনিয়া ও হার্জেগোভিনা",
        name_de: "Bosnien und Herzegowina",
        name_en: "Bosnia and Herzegovina",
        name_es: "Bosnia y Herzegovina",
        name_fa: "بوسنی و هرزگوین",
        name_fr: "Bosnie-Herzégovine",
        name_el: "Βοσνία και Ερζεγοβίνη",
        name_he: "בוסניה והרצגובינה",
        name_hi: "बॉस्निया और हर्ज़ेगोविना",
        name_hu: "Bosznia-Hercegovina",
        name_id: "Bosnia dan Herzegovina",
        name_it: "Bosnia ed Erzegovina",
        name_ja: "ボスニア・ヘルツェゴビナ",
        name_ko: "보스니아 헤르체고비나",
        name_nl: "Bosnië en Herzegovina",
        name_pl: "Bośnia i Hercegowina",
        name_pt: "Bósnia e Herzegovina",
        name_ru: "Босния и Герцеговина",
        name_sv: "Bosnien och Hercegovina",
        name_tr: "Bosna-Hersek",
        name_uk: "Боснія і Герцеговина",
        name_ur: "بوسنیا و ہرزیگووینا",
        name_vi: "Bosna và Hercegovina",
        name_zh: "波斯尼亚和黑塞哥维那",
        name_zht: "波士尼亞與赫塞哥維納",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "BIH.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [19.3486328125, 44.880908203124996],
            [19.356835937500023, 44.858544921874994],
            [19.33447265625, 44.780664062499994],
            [19.291894531250023, 44.69677734375],
            [19.22314453125, 44.6095703125],
            [19.1513671875, 44.52734375],
            [19.132421875, 44.483789062499994],
            [19.127343750000023, 44.41455078125],
            [19.118457031250017, 44.3599609375],
            [19.128320312500023, 44.3302734375],
            [19.15185546875, 44.302539062499996],
            [19.231542968750006, 44.280566406249996],
            [19.3388671875, 44.225830078125],
            [19.43017578125, 44.1544921875],
            [19.547167968750017, 44.073486328125],
            [19.583789062500017, 44.04345703125],
            [19.58369140625001, 44.011083984375],
            [19.54951171875001, 43.987109375],
            [19.44941406250001, 43.97802734375],
            [19.34521484375, 43.985107421875],
            [19.305273437500006, 43.993359375],
            [19.26806640625, 43.983447265624996],
            [19.245019531250023, 43.9650390625],
            [19.257226562500023, 43.943310546875],
            [19.364062500000017, 43.844775390624996],
            [19.488183593750023, 43.703564453125],
            [19.4951171875, 43.642871093749996],
            [19.47998046875, 43.595166015625],
            [19.451269531250006, 43.562060546874996],
            [19.39960937500001, 43.567578125],
            [19.3603515625, 43.59345703125],
            [19.30078125, 43.591796875],
            [19.254492187500006, 43.584375],
            [19.1943359375, 43.53330078125],
            [19.164355468750017, 43.535449218749996],
            [19.11279296875, 43.527734375],
            [19.080078125, 43.517724609374994],
            [19.0283203125, 43.532519531249996],
            [18.97421875, 43.542333984375],
            [18.95068359375, 43.526660156249996],
            [18.940234375000017, 43.496728515624994],
            [18.97382812500001, 43.4423828125],
            [19.03671875, 43.357324218749994],
            [19.026660156250017, 43.292431640625],
            [18.97871093750001, 43.285400390625],
            [18.934667968750006, 43.339453125],
            [18.895605468750006, 43.348193359374996],
            [18.85107421875, 43.346337890624994],
            [18.74921875000001, 43.283544921875],
            [18.674218750000023, 43.230810546875],
            [18.656835937500006, 43.1939453125],
            [18.629980468750006, 43.153662109375],
            [18.621875, 43.124609375],
            [18.623632812500006, 43.027685546875],
            [18.48847656250001, 43.012158203125],
            [18.46015625000001, 42.997900390625],
            [18.44384765625, 42.96845703125],
            [18.455078125, 42.844091796875],
            [18.46601562500001, 42.777246093749994],
            [18.543261718750017, 42.674169921875],
            [18.5458984375, 42.6416015625],
            [18.534960937500017, 42.6201171875],
            [18.480078125, 42.57919921875],
            [18.453906250000017, 42.564501953124996],
            [18.436328125000017, 42.559716796874994],
            [18.346582031250023, 42.586669921875],
            [18.304003906250017, 42.599414062499996],
            [18.123925781250023, 42.690576171874994],
            [18.04453125, 42.741259765624996],
            [17.918847656250023, 42.807421875],
            [17.84130859375, 42.845068359375],
            [17.80195312500001, 42.902246093749994],
            [17.740234375, 42.915478515625],
            [17.667578125, 42.897119140624994],
            [17.58515625000001, 42.938378906249994],
            [17.643457031250023, 42.959765625],
            [17.6578125, 42.980078125],
            [17.650488281250006, 43.006591796875],
            [17.624804687500017, 43.042773437499996],
            [17.402246093750023, 43.19892578125],
            [17.293066406250006, 43.305615234375],
            [17.275292968750023, 43.34384765625],
            [17.273828125000023, 43.445751953125],
            [17.248046875, 43.47021484375],
            [17.084570312500006, 43.516552734375],
            [16.90185546875, 43.6490234375],
            [16.713476562500006, 43.77880859375],
            [16.68769531250001, 43.8150390625],
            [16.59052734375001, 43.91318359375],
            [16.472070312500023, 44.002587890624994],
            [16.377539062500006, 44.059619140624996],
            [16.300097656250017, 44.12451171875],
            [16.214257812500023, 44.215136718749996],
            [16.169824218750023, 44.352001953125],
            [16.130273437500023, 44.47373046875],
            [16.103417968750023, 44.52099609375],
            [16.04902343750001, 44.53759765625],
            [15.880078125000011, 44.681933593749996],
            [15.736621093750017, 44.7658203125],
            [15.737988281250011, 44.856396484375],
            [15.761523437500017, 45.00751953125],
            [15.7880859375, 45.178955078125],
            [15.822851562500006, 45.202783203124994],
            [15.88828125, 45.215722656249994],
            [15.963183593750017, 45.210791015625],
            [16.0283203125, 45.189599609374994],
            [16.157324218750006, 45.072216796875],
            [16.231054687500006, 45.026611328125],
            [16.293359375000023, 45.008837890624996],
            [16.365039062500017, 45.058349609375],
            [16.453515625000023, 45.162011718749994],
            [16.530664062500023, 45.216699218749994],
            [16.790820312500017, 45.196875],
            [16.91865234375001, 45.2765625],
            [17.125390625000023, 45.171777343749994],
            [17.21064453125001, 45.156054687499996],
            [17.258691406250023, 45.170556640624994],
            [17.324121093750023, 45.163964843749994],
            [17.469140625000023, 45.13330078125],
            [17.50263671875001, 45.120361328125],
            [17.546289062500023, 45.12255859375],
            [17.65351562500001, 45.163476562499994],
            [17.69013671875001, 45.1583984375],
            [17.812792968750017, 45.078125],
            [17.874414062500023, 45.07724609375],
            [17.948632812500023, 45.111865234374996],
            [17.99628906250001, 45.141796875],
            [18.13720703125, 45.119384765625],
            [18.21796875000001, 45.13291015625],
            [18.284960937500017, 45.13427734375],
            [18.35761718750001, 45.120556640625],
            [18.423925781250006, 45.102001953125],
            [18.48828125, 45.085839843749994],
            [18.66259765625, 45.077441406249996],
            [18.74609375, 45.026513671874994],
            [18.779394531250006, 44.97724609375],
            [18.780175781250023, 44.947216796875],
            [18.788378906250017, 44.914892578125],
            [18.83642578125, 44.883251953125],
            [18.941308593750023, 44.865185546875],
            [19.007128906250017, 44.869189453124996],
            [19.042089843750006, 44.871337890625],
            [19.13154296875001, 44.899609375],
            [19.23681640625, 44.9142578125],
            [19.31269531250001, 44.8974609375],
            [19.3486328125, 44.880908203124996],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 3,
        labelrank: 2,
        sovereignt: "Portugal",
        sov_a3: "PRT",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Portugal",
        adm0_a3: "PRT",
        geou_dif: 0,
        geounit: "Portugal",
        gu_a3: "PRT",
        su_dif: 1,
        subunit: "Portugal",
        su_a3: "PR1",
        brk_diff: 0,
        name: "Portugal",
        name_long: "Portugal",
        brk_a3: "PR1",
        brk_name: "Portugal",
        brk_group: null,
        abbrev: "Port.",
        postal: "P",
        formal_en: "Portuguese Republic",
        formal_fr: null,
        name_ciawf: "Portugal",
        note_adm0: null,
        note_brk: null,
        name_sort: "Portugal",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 7,
        mapcolor9: 1,
        mapcolor13: 4,
        pop_est: 10269417,
        pop_rank: 14,
        pop_year: 2019,
        gdp_md: 238785,
        gdp_year: 2019,
        economy: "2. Developed region: nonG7",
        income_grp: "1. High income: OECD",
        fips_10: "PO",
        iso_a2: "PT",
        iso_a2_eh: "PT",
        iso_a3: "PRT",
        iso_a3_eh: "PRT",
        iso_n3: "620",
        iso_n3_eh: "620",
        un_a3: "620",
        wb_a2: "PT",
        wb_a3: "PRT",
        woe_id: 23424925,
        woe_id_eh: 23424925,
        woe_note: "Exact WOE match as country",
        adm0_iso: "PR1",
        adm0_diff: null,
        adm0_tlc: "PR1",
        adm0_a3_us: "PRT",
        adm0_a3_fr: "PRT",
        adm0_a3_ru: "PRT",
        adm0_a3_es: "PRT",
        adm0_a3_cn: "PRT",
        adm0_a3_tw: "PRT",
        adm0_a3_in: "PRT",
        adm0_a3_np: "PRT",
        adm0_a3_pk: "PRT",
        adm0_a3_de: "PRT",
        adm0_a3_gb: "PRT",
        adm0_a3_br: "PRT",
        adm0_a3_il: "PRT",
        adm0_a3_ps: "PRT",
        adm0_a3_sa: "PRT",
        adm0_a3_eg: "PRT",
        adm0_a3_ma: "PRT",
        adm0_a3_pt: "PRT",
        adm0_a3_ar: "PRT",
        adm0_a3_jp: "PRT",
        adm0_a3_ko: "PRT",
        adm0_a3_vn: "PRT",
        adm0_a3_tr: "PRT",
        adm0_a3_id: "PRT",
        adm0_a3_pl: "PRT",
        adm0_a3_gr: "PRT",
        adm0_a3_it: "PRT",
        adm0_a3_nl: "PRT",
        adm0_a3_se: "PRT",
        adm0_a3_bd: "PRT",
        adm0_a3_ua: "PRT",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Southern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 8,
        long_len: 8,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: -8.271754,
        label_y: 39.606675,
        ne_id: 1159321187,
        wikidataid: "Q45",
        name_ar: "البرتغال",
        name_bn: "পর্তুগাল",
        name_de: "Portugal",
        name_en: "Portugal",
        name_es: "Portugal",
        name_fa: "پرتغال",
        name_fr: "Portugal",
        name_el: "Πορτογαλία",
        name_he: "פורטוגל",
        name_hi: "पुर्तगाल",
        name_hu: "Portugália",
        name_id: "Portugal",
        name_it: "Portogallo",
        name_ja: "ポルトガル",
        name_ko: "포르투갈",
        name_nl: "Portugal",
        name_pl: "Portugalia",
        name_pt: "Portugal",
        name_ru: "Португалия",
        name_sv: "Portugal",
        name_tr: "Portekiz",
        name_uk: "Португалія",
        name_ur: "پرتگال",
        name_vi: "Bồ Đào Nha",
        name_zh: "葡萄牙",
        name_zht: "葡萄牙",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "PRT.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-17.190869140624983, 32.868603515625],
              [-17.05449218749999, 32.815869140625],
              [-16.92919921875, 32.84140625],
              [-16.77397460937499, 32.77353515625],
              [-16.693261718749994, 32.7580078125],
              [-16.765283203124994, 32.709716796875],
              [-16.83740234375, 32.648291015625],
              [-17.018261718749983, 32.66279296875],
              [-17.17119140624999, 32.721875],
              [-17.226025390624983, 32.766845703125],
              [-17.24101562499999, 32.807373046875],
              [-17.190869140624983, 32.868603515625],
            ],
          ],
          [
            [
              [-8.777148437499989, 41.941064453124994],
              [-8.682958984374977, 42.00849609375],
              [-8.589648437499989, 42.052734375],
              [-8.5380859375, 42.0693359375],
              [-8.322558593749989, 42.115087890625],
              [-8.266064453124983, 42.13740234375],
              [-8.213085937499983, 42.133691406249994],
              [-8.204199218749977, 42.111865234374996],
              [-8.173583984375, 42.069384765624996],
              [-8.139306640624994, 42.039941406249994],
              [-8.129980468749977, 42.0181640625],
              [-8.213330078124983, 41.927099609375],
              [-8.224755859374994, 41.895849609375],
              [-8.18125, 41.836962890624996],
              [-8.173535156249983, 41.819970703124994],
              [-8.152490234374994, 41.811962890625],
              [-8.094433593749983, 41.814208984375],
              [-7.990966796875, 41.851904296875],
              [-7.920849609374983, 41.883642578125],
              [-7.896386718749994, 41.870556640625],
              [-7.693066406249983, 41.888476562499996],
              [-7.644677734374994, 41.873974609375],
              [-7.612597656249989, 41.857958984374996],
              [-7.512597656249994, 41.835986328124996],
              [-7.403613281249989, 41.83369140625],
              [-7.2685546875, 41.864404296874994],
              [-7.209619140624994, 41.895263671875],
              [-7.198339843749977, 41.92939453125],
              [-7.195361328124989, 41.955224609374994],
              [-7.177929687499983, 41.9716796875],
              [-7.147119140624994, 41.98115234375],
              [-7.09912109375, 41.964208984375],
              [-7.030468749999983, 41.950634765625],
              [-6.865527343749989, 41.945263671875],
              [-6.833203124999983, 41.964160156249996],
              [-6.777294921874983, 41.95849609375],
              [-6.70361328125, 41.9345703125],
              [-6.618261718749977, 41.9423828125],
              [-6.575341796874994, 41.9130859375],
              [-6.557519531249994, 41.87412109375],
              [-6.552587890624977, 41.78955078125],
              [-6.558984375, 41.704052734375],
              [-6.542187499999983, 41.672509765624994],
              [-6.484667968749989, 41.664404296875],
              [-6.391699218749977, 41.665380859375],
              [-6.308056640624983, 41.6421875],
              [-6.243115234374983, 41.601806640625],
              [-6.2216796875, 41.560449218749994],
              [-6.2125, 41.53203125],
              [-6.244335937499983, 41.515917968749996],
              [-6.289355468749989, 41.455029296875],
              [-6.403125, 41.375390625],
              [-6.56591796875, 41.3037109375],
              [-6.690136718749983, 41.214501953124994],
              [-6.77578125, 41.107714843749996],
              [-6.8828125, 41.062402343749994],
              [-6.91552734375, 41.038037109375],
              [-6.928466796875, 41.009130859375],
              [-6.857714843749989, 40.878320312499994],
              [-6.835888671874983, 40.777490234374994],
              [-6.818359375, 40.654052734375],
              [-6.829833984375, 40.619091796875],
              [-6.835693359375, 40.483154296875],
              [-6.85205078125, 40.443261718749994],
              [-6.847949218749989, 40.410986328125],
              [-6.82177734375, 40.376269531249996],
              [-6.810156249999977, 40.343115234375],
              [-6.85888671875, 40.300732421875],
              [-6.948437499999983, 40.251611328124994],
              [-7.014697265624989, 40.208349609375],
              [-7.032617187499994, 40.167919921875],
              [-7.02783203125, 40.142626953124996],
              [-6.91640625, 40.0568359375],
              [-6.896093749999977, 40.021826171875],
              [-6.911181640624989, 39.937109375],
              [-6.975390624999989, 39.798388671874996],
              [-7.036718749999977, 39.71396484375],
              [-7.047412109374989, 39.70556640625],
              [-7.11767578125, 39.681689453124996],
              [-7.4541015625, 39.6806640625],
              [-7.535693359374989, 39.661572265625],
              [-7.524218749999989, 39.6447265625],
              [-7.445117187499989, 39.536181640624996],
              [-7.362695312499994, 39.478320312499996],
              [-7.33544921875, 39.465136718749996],
              [-7.305761718749977, 39.338134765625],
              [-7.172412109374989, 39.135205078125],
              [-7.04296875, 39.107080078124994],
              [-6.997949218749994, 39.056445312499996],
              [-7.00625, 38.985253906249994],
              [-7.046044921874994, 38.90703125],
              [-7.12548828125, 38.826953125],
              [-7.219921874999983, 38.7705078125],
              [-7.281542968749989, 38.71455078125],
              [-7.286376953125, 38.649365234375],
              [-7.305957031249989, 38.566845703125],
              [-7.335791015624977, 38.50146484375],
              [-7.343017578125, 38.457421875],
              [-7.106396484374983, 38.181005859375],
              [-6.974804687499983, 38.19443359375],
              [-6.957568359374989, 38.187890625],
              [-6.981103515624994, 38.121972656249994],
              [-7.022851562499994, 38.044726562499996],
              [-7.072509765625, 38.030029296875],
              [-7.185449218749994, 38.00634765625],
              [-7.292236328125, 37.9064453125],
              [-7.37890625, 37.786376953125],
              [-7.443945312499977, 37.728271484375],
              [-7.503515624999977, 37.585498046874996],
              [-7.496044921874983, 37.523583984374994],
              [-7.4671875, 37.428027343749996],
              [-7.406152343749994, 37.179443359375],
              [-7.493603515624983, 37.168310546875],
              [-7.834130859374994, 37.005712890625],
              [-7.939697265625, 37.005419921874996],
              [-8.136767578124989, 37.077050781249994],
              [-8.484326171874983, 37.100048828125],
              [-8.59765625, 37.121337890625],
              [-8.739111328124977, 37.074609375],
              [-8.848437499999989, 37.07568359375],
              [-8.935351562499989, 37.016015625],
              [-8.997802734375, 37.032275390624996],
              [-8.92626953125, 37.166064453124996],
              [-8.814160156249983, 37.430810546874994],
              [-8.818554687499983, 37.592431640624994],
              [-8.791845703124977, 37.7328125],
              [-8.82265625, 37.871875],
              [-8.878955078124989, 37.95869140625],
              [-8.80224609375, 38.183837890625],
              [-8.8109375, 38.299755859375],
              [-8.881103515625, 38.446679687499994],
              [-8.668310546874977, 38.42431640625],
              [-8.733984374999977, 38.482421875],
              [-8.798876953124989, 38.5181640625],
              [-8.861621093749989, 38.5099609375],
              [-8.914794921875, 38.512109375],
              [-9.095996093749989, 38.455224609374994],
              [-9.186718749999983, 38.43818359375],
              [-9.21328125, 38.448095703125],
              [-9.203369140625, 38.538964843749994],
              [-9.250390625, 38.65673828125],
              [-9.177832031249977, 38.687792968749996],
              [-9.093310546874989, 38.696679687499994],
              [-9.021484375, 38.746875],
              [-8.97705078125, 38.8029296875],
              [-9.00048828125, 38.90302734375],
              [-8.938085937499977, 38.998095703124996],
              [-8.791601562499977, 39.078173828124996],
              [-8.867480468749989, 39.065966796874996],
              [-8.954296874999983, 39.016064453125],
              [-9.091015624999983, 38.83466796875],
              [-9.135791015624989, 38.7427734375],
              [-9.252294921874977, 38.712792968749994],
              [-9.356738281249989, 38.697900390624994],
              [-9.410205078124989, 38.70751953125],
              [-9.47412109375, 38.730859375],
              [-9.479736328125, 38.798779296875],
              [-9.474755859374994, 38.852929687499994],
              [-9.431445312499989, 38.96044921875],
              [-9.414355468749989, 39.112109375],
              [-9.352832031249989, 39.24814453125],
              [-9.357226562499989, 39.28427734375],
              [-9.374755859375, 39.33828125],
              [-9.319628906249989, 39.39111328125],
              [-9.251416015624983, 39.426025390625],
              [-9.148291015624977, 39.542578125],
              [-9.004052734374994, 39.820556640625],
              [-8.837841796874983, 40.115673828125],
              [-8.851318359375, 40.151806640625],
              [-8.886621093749994, 40.179443359375],
              [-8.872656249999977, 40.259082031249996],
              [-8.772412109374983, 40.6056640625],
              [-8.731591796874994, 40.650927734374996],
              [-8.684619140624989, 40.7525390625],
              [-8.673974609374994, 40.91650390625],
              [-8.655566406249989, 41.0294921875],
              [-8.659814453124994, 41.086279296875],
              [-8.674609374999989, 41.1544921875],
              [-8.738378906249977, 41.28466796875],
              [-8.8056640625, 41.560009765625],
              [-8.810839843749989, 41.651953125],
              [-8.755419921874989, 41.698388671874994],
              [-8.846386718749983, 41.70517578125],
              [-8.887597656249994, 41.764599609375],
              [-8.878222656249989, 41.832080078124996],
              [-8.777148437499989, 41.941064453124994],
            ],
          ],
          [
            [
              [-25.02734375, 36.9599609375],
              [-25.03154296874999, 36.941552734374994],
              [-25.08837890625, 36.948876953124994],
              [-25.159912109375, 36.943359375],
              [-25.198388671874994, 36.996533203125],
              [-25.163525390624983, 37.0185546875],
              [-25.082910156249994, 37.0240234375],
              [-25.044335937499994, 37.0001953125],
              [-25.02734375, 36.9599609375],
            ],
          ],
          [
            [
              [-31.137109375, 39.40693359375],
              [-31.181347656249983, 39.358935546874996],
              [-31.25761718749999, 39.3759765625],
              [-31.282958984375, 39.394091796874996],
              [-31.260839843750006, 39.49677734375],
              [-31.199853515624994, 39.520849609375],
              [-31.138623046874983, 39.479443359375],
              [-31.137109375, 39.40693359375],
            ],
          ],
          [
            [
              [-27.07524414062499, 38.643457031249994],
              [-27.0953125, 38.634033203125],
              [-27.302832031250006, 38.661035156249994],
              [-27.361914062500006, 38.6978515625],
              [-27.385937499999983, 38.7658203125],
              [-27.351025390624983, 38.788964843749994],
              [-27.259667968749994, 38.802685546875],
              [-27.12700195312499, 38.78984375],
              [-27.041943359374983, 38.7412109375],
              [-27.0419921875, 38.67890625],
              [-27.07524414062499, 38.643457031249994],
            ],
          ],
          [
            [
              [-27.778466796874994, 38.555615234375],
              [-27.825878906249983, 38.5435546875],
              [-28.09233398437499, 38.620556640625],
              [-28.187255859375, 38.65537109375],
              [-28.310644531250006, 38.743896484375],
              [-27.962646484375, 38.636328125],
              [-27.778466796874994, 38.555615234375],
            ],
          ],
          [
            [
              [-28.641308593749983, 38.525],
              [-28.743847656249983, 38.522363281249994],
              [-28.842041015625, 38.5984375],
              [-28.69775390625, 38.638476562499996],
              [-28.655419921874994, 38.6140625],
              [-28.624218749999983, 38.586328125],
              [-28.605810546875006, 38.550732421875],
              [-28.641308593749983, 38.525],
            ],
          ],
          [
            [
              [-28.14726562499999, 38.452685546874996],
              [-28.064794921875006, 38.412744140624994],
              [-28.18974609374999, 38.404150390625],
              [-28.231152343749983, 38.384667968749994],
              [-28.332421875, 38.412890625],
              [-28.454492187499994, 38.408642578125],
              [-28.531152343749994, 38.462548828124994],
              [-28.548828125, 38.5185546875],
              [-28.51025390625, 38.553027343749996],
              [-28.40214843749999, 38.553369140624994],
              [-28.14726562499999, 38.452685546874996],
            ],
          ],
          [
            [
              [-25.64897460937499, 37.84091796875],
              [-25.58549804687499, 37.834033203124996],
              [-25.2666015625, 37.8486328125],
              [-25.18193359374999, 37.837890625],
              [-25.19072265624999, 37.76435546875],
              [-25.251123046874994, 37.735009765624994],
              [-25.43901367187499, 37.71533203125],
              [-25.734472656249977, 37.762890625],
              [-25.833691406249983, 37.826074218749994],
              [-25.847851562499983, 37.872412109375],
              [-25.845898437499983, 37.89404296875],
              [-25.78374023437499, 37.9111328125],
              [-25.64897460937499, 37.84091796875],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 6,
        sovereignt: "Moldova",
        sov_a3: "MDA",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Moldova",
        adm0_a3: "MDA",
        geou_dif: 0,
        geounit: "Moldova",
        gu_a3: "MDA",
        su_dif: 0,
        subunit: "Moldova",
        su_a3: "MDA",
        brk_diff: 0,
        name: "Moldova",
        name_long: "Moldova",
        brk_a3: "MDA",
        brk_name: "Moldova",
        brk_group: null,
        abbrev: "Mda.",
        postal: "MD",
        formal_en: "Republic of Moldova",
        formal_fr: null,
        name_ciawf: "Moldova",
        note_adm0: null,
        note_brk: null,
        name_sort: "Moldova",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 5,
        mapcolor9: 4,
        mapcolor13: 12,
        pop_est: 2657637,
        pop_rank: 12,
        pop_year: 2019,
        gdp_md: 11968,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        fips_10: "MD",
        iso_a2: "MD",
        iso_a2_eh: "MD",
        iso_a3: "MDA",
        iso_a3_eh: "MDA",
        iso_n3: "498",
        iso_n3_eh: "498",
        un_a3: "498",
        wb_a2: "MD",
        wb_a3: "MDA",
        woe_id: 23424885,
        woe_id_eh: 23424885,
        woe_note: "Exact WOE match as country",
        adm0_iso: "MDA",
        adm0_diff: null,
        adm0_tlc: "MDA",
        adm0_a3_us: "MDA",
        adm0_a3_fr: "MDA",
        adm0_a3_ru: "MDA",
        adm0_a3_es: "MDA",
        adm0_a3_cn: "MDA",
        adm0_a3_tw: "MDA",
        adm0_a3_in: "MDA",
        adm0_a3_np: "MDA",
        adm0_a3_pk: "MDA",
        adm0_a3_de: "MDA",
        adm0_a3_gb: "MDA",
        adm0_a3_br: "MDA",
        adm0_a3_il: "MDA",
        adm0_a3_ps: "MDA",
        adm0_a3_sa: "MDA",
        adm0_a3_eg: "MDA",
        adm0_a3_ma: "MDA",
        adm0_a3_pt: "MDA",
        adm0_a3_ar: "MDA",
        adm0_a3_jp: "MDA",
        adm0_a3_ko: "MDA",
        adm0_a3_vn: "MDA",
        adm0_a3_tr: "MDA",
        adm0_a3_id: "MDA",
        adm0_a3_pl: "MDA",
        adm0_a3_gr: "MDA",
        adm0_a3_it: "MDA",
        adm0_a3_nl: "MDA",
        adm0_a3_se: "MDA",
        adm0_a3_bd: "MDA",
        adm0_a3_ua: "MDA",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Eastern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 5,
        max_label: 10,
        label_x: 28.487904,
        label_y: 47.434999,
        ne_id: 1159321045,
        wikidataid: "Q217",
        name_ar: "مولدوفا",
        name_bn: "মলদোভা",
        name_de: "Republik Moldau",
        name_en: "Moldova",
        name_es: "Moldavia",
        name_fa: "مولداوی",
        name_fr: "Moldavie",
        name_el: "Μολδαβία",
        name_he: "מולדובה",
        name_hi: "मॉल्डोवा",
        name_hu: "Moldova",
        name_id: "Moldova",
        name_it: "Moldavia",
        name_ja: "モルドバ",
        name_ko: "몰도바",
        name_nl: "Moldavië",
        name_pl: "Mołdawia",
        name_pt: "Moldávia",
        name_ru: "Молдавия",
        name_sv: "Moldavien",
        name_tr: "Moldova",
        name_uk: "Молдова",
        name_ur: "مالدووا",
        name_vi: "Moldova",
        name_zh: "摩尔多瓦",
        name_zht: "摩爾多瓦",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "MDA.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [26.618945312500017, 48.25986328125],
            [26.640429687500017, 48.294140625],
            [26.847070312500023, 48.387158203125],
            [26.90058593750001, 48.371923828125],
            [27.00849609375001, 48.36826171875],
            [27.228515625, 48.371435546875],
            [27.3369140625, 48.43271484375],
            [27.40380859375, 48.415625],
            [27.458398437500023, 48.44306640625],
            [27.549218750000023, 48.477734375],
            [27.56220703125001, 48.47041015625],
            [27.57373046875, 48.464892578124996],
            [27.714453125, 48.449511718749996],
            [27.82001953125001, 48.416259765625],
            [27.890625, 48.365234375],
            [27.96337890625, 48.333544921874996],
            [28.038476562500023, 48.3212890625],
            [28.080078125, 48.295800781249994],
            [28.088476562500006, 48.25703125],
            [28.158789062500006, 48.23798828125],
            [28.291015625, 48.238574218749996],
            [28.34716796875, 48.213037109374994],
            [28.326953125000017, 48.161425781249996],
            [28.34052734375001, 48.144433593749994],
            [28.3875, 48.162109375],
            [28.42304687500001, 48.146875],
            [28.441992187500006, 48.108691406249996],
            [28.46308593750001, 48.09052734375],
            [28.53046875000001, 48.150292968749994],
            [28.601660156250006, 48.144384765625],
            [28.773828125000023, 48.119580078125],
            [28.865820312500006, 47.995654296874996],
            [28.923144531250017, 47.951123046875],
            [28.97333984375001, 47.933007812499994],
            [29.036914062500017, 47.95234375],
            [29.09296875000001, 47.975439453125],
            [29.125390625000023, 47.96455078125],
            [29.19482421875, 47.882421875],
            [29.21113281250001, 47.775],
            [29.210742187500017, 47.73154296875],
            [29.18603515625, 47.65859375],
            [29.15087890625, 47.580859375],
            [29.122949218750023, 47.53037109375],
            [29.134863281250006, 47.489697265625],
            [29.159765625, 47.4556640625],
            [29.200585937500023, 47.444482421874994],
            [29.333789062500017, 47.375732421875],
            [29.383398437500006, 47.328027343749994],
            [29.455664062500006, 47.292626953124994],
            [29.510644531250023, 47.29072265625],
            [29.539160156250006, 47.27099609375],
            [29.54931640625, 47.246826171875],
            [29.541796875000017, 47.185546875],
            [29.51093750000001, 47.12802734375],
            [29.515039062500023, 47.091113281249996],
            [29.5634765625, 47.047509765624994],
            [29.568652343750017, 46.996728515624994],
            [29.57197265625001, 46.964013671874994],
            [29.597753906250006, 46.938818359375],
            [29.7197265625, 46.88291015625],
            [29.877832031250023, 46.82890625],
            [29.918066406250006, 46.782421875],
            [29.942480468750006, 46.723779296874994],
            [29.93476562500001, 46.625],
            [29.92431640625, 46.538867187499996],
            [30.13105468750001, 46.423095703125],
            [30.107519531250006, 46.4015625],
            [30.07568359375, 46.377832031249994],
            [29.878027343750006, 46.360205078125],
            [29.837890625, 46.350537109375],
            [29.751953125, 46.437792968749996],
            [29.706835937500017, 46.44873046875],
            [29.66455078125, 46.416748046875],
            [29.61494140625001, 46.398828125],
            [29.555078125000023, 46.407763671874996],
            [29.491015625000017, 46.43466796875],
            [29.458789062500017, 46.453759765624994],
            [29.43281250000001, 46.455957031249994],
            [29.392871093750017, 46.436914062499994],
            [29.33955078125001, 46.445068359375],
            [29.30488281250001, 46.466601562499996],
            [29.25458984375001, 46.392626953124996],
            [29.22382812500001, 46.376953125],
            [29.20458984375, 46.379345703125],
            [29.20078125, 46.50498046875],
            [29.18623046875001, 46.523974609374996],
            [29.146289062500017, 46.526904296874996],
            [29.049902343750006, 46.497021484375],
            [28.958398437500023, 46.45849609375],
            [28.92744140625001, 46.424121093749996],
            [28.930566406250023, 46.362255859375],
            [28.94375, 46.288427734375],
            [29.00625, 46.17646484375],
            [28.971875, 46.12763671875],
            [28.94775390625, 46.049951171874994],
            [28.849511718750023, 45.978662109374994],
            [28.73876953125, 45.937158203124994],
            [28.729296875000017, 45.852001953125],
            [28.667578125, 45.793847656249994],
            [28.562304687500017, 45.735791015625],
            [28.491601562500023, 45.665771484375],
            [28.50947265625001, 45.617822265624994],
            [28.513769531250006, 45.572412109374994],
            [28.501757812500017, 45.541552734374996],
            [28.4990234375, 45.517724609374994],
            [28.47138671875001, 45.507177734375],
            [28.310351562500017, 45.498583984374996],
            [28.26484375000001, 45.48388671875],
            [28.2125, 45.450439453125],
            [28.1625, 45.51376953125],
            [28.111914062500006, 45.569140625],
            [28.07470703125, 45.598974609375],
            [28.09033203125, 45.612744140625],
            [28.130859375, 45.628271484375],
            [28.159765625, 45.647119140624994],
            [28.15625, 45.7130859375],
            [28.13496093750001, 45.788867187499996],
            [28.115527343750017, 45.825537109375],
            [28.113574218750017, 45.883056640625],
            [28.099707031250006, 45.972607421875],
            [28.119140625, 46.138671875],
            [28.199609375000023, 46.347558593749994],
            [28.24433593750001, 46.45126953125],
            [28.22265625, 46.508056640625],
            [28.23945312500001, 46.6408203125],
            [28.2046875, 46.706396484375],
            [28.15, 46.79208984375],
            [28.07177734375, 46.978417968749994],
            [27.97421875, 47.043212890625],
            [27.853808593750017, 47.114501953125],
            [27.80234375, 47.168310546875],
            [27.767968750000023, 47.227587890624996],
            [27.696191406250023, 47.286425781249996],
            [27.614062500000017, 47.34052734375],
            [27.51582031250001, 47.475634765624996],
            [27.46484375, 47.536669921874996],
            [27.44921875, 47.553125],
            [27.3369140625, 47.63974609375],
            [27.277929687500006, 47.71796875],
            [27.248144531250006, 47.7822265625],
            [27.230859375000023, 47.841748046875],
            [27.15205078125001, 47.959277343749996],
            [27.080371093750017, 48.04765625],
            [27.01220703125, 48.110498046874994],
            [26.980761718750017, 48.155029296875],
            [26.900976562500006, 48.2111328125],
            [26.78730468750001, 48.255810546875],
            [26.713769531250023, 48.263476562499996],
            [26.618945312500017, 48.25986328125],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 6,
        labelrank: 6,
        sovereignt: "Vatican",
        sov_a3: "VAT",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Vatican",
        adm0_a3: "VAT",
        geou_dif: 0,
        geounit: "Vatican",
        gu_a3: "VAT",
        su_dif: 0,
        subunit: "Vatican",
        su_a3: "VAT",
        brk_diff: 0,
        name: "Vatican",
        name_long: "Vatican",
        brk_a3: "VAT",
        brk_name: "Vatican",
        brk_group: null,
        abbrev: "Vat.",
        postal: "V",
        formal_en: "State of the Vatican City",
        formal_fr: null,
        name_ciawf: "Holy See (Vatican City)",
        note_adm0: null,
        note_brk: null,
        name_sort: "Vatican (Holy See)",
        name_alt: "Holy See",
        mapcolor7: 1,
        mapcolor8: 3,
        mapcolor9: 4,
        mapcolor13: 2,
        pop_est: 825,
        pop_rank: 2,
        pop_year: 2019,
        gdp_md: -99,
        gdp_year: 2019,
        economy: "2. Developed region: nonG7",
        income_grp: "2. High income: nonOECD",
        fips_10: "VT",
        iso_a2: "VA",
        iso_a2_eh: "VA",
        iso_a3: "VAT",
        iso_a3_eh: "VAT",
        iso_n3: "336",
        iso_n3_eh: "336",
        un_a3: "336",
        wb_a2: "-99",
        wb_a3: "-99",
        woe_id: 23424986,
        woe_id_eh: 23424986,
        woe_note: "Exact WOE match as country",
        adm0_iso: "VAT",
        adm0_diff: null,
        adm0_tlc: "VAT",
        adm0_a3_us: "VAT",
        adm0_a3_fr: "VAT",
        adm0_a3_ru: "VAT",
        adm0_a3_es: "VAT",
        adm0_a3_cn: "VAT",
        adm0_a3_tw: "VAT",
        adm0_a3_in: "VAT",
        adm0_a3_np: "VAT",
        adm0_a3_pk: "VAT",
        adm0_a3_de: "VAT",
        adm0_a3_gb: "VAT",
        adm0_a3_br: "VAT",
        adm0_a3_il: "VAT",
        adm0_a3_ps: "VAT",
        adm0_a3_sa: "VAT",
        adm0_a3_eg: "VAT",
        adm0_a3_ma: "VAT",
        adm0_a3_pt: "VAT",
        adm0_a3_ar: "VAT",
        adm0_a3_jp: "VAT",
        adm0_a3_ko: "VAT",
        adm0_a3_vn: "VAT",
        adm0_a3_tr: "VAT",
        adm0_a3_id: "VAT",
        adm0_a3_pl: "VAT",
        adm0_a3_gr: "VAT",
        adm0_a3_it: "VAT",
        adm0_a3_nl: "VAT",
        adm0_a3_se: "VAT",
        adm0_a3_bd: "VAT",
        adm0_a3_ua: "VAT",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Southern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: 4,
        homepart: 1,
        min_zoom: 0,
        min_label: 5,
        max_label: 10,
        label_x: 12.453418,
        label_y: 41.903323,
        ne_id: 1159321407,
        wikidataid: "Q237",
        name_ar: "الفاتيكان",
        name_bn: "ভ্যাটিকান সিটি",
        name_de: "Vatikanstadt",
        name_en: "Vatican City",
        name_es: "Ciudad del Vaticano",
        name_fa: "واتیکان",
        name_fr: "Cité du Vatican",
        name_el: "Βατικανό",
        name_he: "קריית הוותיקן",
        name_hi: "वैटिकन नगर",
        name_hu: "Vatikán",
        name_id: "Vatikan",
        name_it: "Città del Vaticano",
        name_ja: "バチカン",
        name_ko: "바티칸 시국",
        name_nl: "Vaticaanstad",
        name_pl: "Watykan",
        name_pt: "Vaticano",
        name_ru: "Ватикан",
        name_sv: "Vatikanstaten",
        name_tr: "Vatikan",
        name_uk: "Ватикан",
        name_ur: "ویٹیکن سٹی",
        name_vi: "Thành Vatican",
        name_zh: "梵蒂冈",
        name_zht: "梵蒂岡",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "VAT.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [12.439160156250011, 41.898388671875],
            [12.430566406250023, 41.89755859375],
            [12.427539062500017, 41.900732421875],
            [12.430566406250023, 41.90546875],
            [12.438378906250023, 41.906201171875],
            [12.439160156250011, 41.898388671875],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "Iceland",
        sov_a3: "ISL",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Iceland",
        adm0_a3: "ISL",
        geou_dif: 0,
        geounit: "Iceland",
        gu_a3: "ISL",
        su_dif: 0,
        subunit: "Iceland",
        su_a3: "ISL",
        brk_diff: 0,
        name: "Iceland",
        name_long: "Iceland",
        brk_a3: "ISL",
        brk_name: "Iceland",
        brk_group: null,
        abbrev: "Iceland",
        postal: "IS",
        formal_en: "Republic of Iceland",
        formal_fr: null,
        name_ciawf: "Iceland",
        note_adm0: null,
        note_brk: null,
        name_sort: "Iceland",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 4,
        mapcolor9: 4,
        mapcolor13: 9,
        pop_est: 361313,
        pop_rank: 10,
        pop_year: 2019,
        gdp_md: 24188,
        gdp_year: 2019,
        economy: "2. Developed region: nonG7",
        income_grp: "1. High income: OECD",
        fips_10: "IC",
        iso_a2: "IS",
        iso_a2_eh: "IS",
        iso_a3: "ISL",
        iso_a3_eh: "ISL",
        iso_n3: "352",
        iso_n3_eh: "352",
        un_a3: "352",
        wb_a2: "IS",
        wb_a3: "ISL",
        woe_id: 23424845,
        woe_id_eh: 23424845,
        woe_note: "Exact WOE match as country",
        adm0_iso: "ISL",
        adm0_diff: null,
        adm0_tlc: "ISL",
        adm0_a3_us: "ISL",
        adm0_a3_fr: "ISL",
        adm0_a3_ru: "ISL",
        adm0_a3_es: "ISL",
        adm0_a3_cn: "ISL",
        adm0_a3_tw: "ISL",
        adm0_a3_in: "ISL",
        adm0_a3_np: "ISL",
        adm0_a3_pk: "ISL",
        adm0_a3_de: "ISL",
        adm0_a3_gb: "ISL",
        adm0_a3_br: "ISL",
        adm0_a3_il: "ISL",
        adm0_a3_ps: "ISL",
        adm0_a3_sa: "ISL",
        adm0_a3_eg: "ISL",
        adm0_a3_ma: "ISL",
        adm0_a3_pt: "ISL",
        adm0_a3_ar: "ISL",
        adm0_a3_jp: "ISL",
        adm0_a3_ko: "ISL",
        adm0_a3_vn: "ISL",
        adm0_a3_tr: "ISL",
        adm0_a3_id: "ISL",
        adm0_a3_pl: "ISL",
        adm0_a3_gr: "ISL",
        adm0_a3_it: "ISL",
        adm0_a3_nl: "ISL",
        adm0_a3_se: "ISL",
        adm0_a3_bd: "ISL",
        adm0_a3_ua: "ISL",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Northern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 7,
        long_len: 7,
        abbrev_len: 7,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 2,
        max_label: 7,
        label_x: -18.673711,
        label_y: 64.779286,
        ne_id: 1159320917,
        wikidataid: "Q189",
        name_ar: "آيسلندا",
        name_bn: "আইসল্যান্ড",
        name_de: "Island",
        name_en: "Iceland",
        name_es: "Islandia",
        name_fa: "ایسلند",
        name_fr: "Islande",
        name_el: "Ισλανδία",
        name_he: "איסלנד",
        name_hi: "आइसलैण्ड",
        name_hu: "Izland",
        name_id: "Islandia",
        name_it: "Islanda",
        name_ja: "アイスランド",
        name_ko: "아이슬란드",
        name_nl: "IJsland",
        name_pl: "Islandia",
        name_pt: "Islândia",
        name_ru: "Исландия",
        name_sv: "Island",
        name_tr: "İzlanda",
        name_uk: "Ісландія",
        name_ur: "آئس لینڈ",
        name_vi: "Iceland",
        name_zh: "冰岛",
        name_zht: "冰島",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "ISL.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-15.543115234374994, 66.228515625],
            [-15.428466796875, 66.2248046875],
            [-15.240917968749983, 66.259130859375],
            [-15.162402343749989, 66.281689453125],
            [-14.969970703125, 66.359716796875],
            [-14.856103515624994, 66.3814453125],
            [-14.6806640625, 66.376123046875],
            [-14.595849609374994, 66.38154296875],
            [-14.593896484374994, 66.373974609375],
            [-14.70166015625, 66.34228515625],
            [-14.788232421874994, 66.3314453125],
            [-14.912207031249977, 66.28427734375],
            [-15.029980468749983, 66.177880859375],
            [-15.117382812499983, 66.125634765625],
            [-15.116406249999983, 66.10244140625],
            [-15.010302734374989, 66.061279296875],
            [-14.89404296875, 66.037890625],
            [-14.787158203124989, 66.05908203125],
            [-14.740429687499983, 66.050830078125],
            [-14.698193359374983, 66.02021484375],
            [-14.674365234374989, 65.989892578125],
            [-14.668994140624989, 65.95986328125],
            [-14.688232421875, 65.89697265625],
            [-14.752539062499977, 65.8337890625],
            [-14.839306640624983, 65.780908203125],
            [-14.827099609374983, 65.7642578125],
            [-14.757519531249983, 65.7556640625],
            [-14.426220703124983, 65.78994140625],
            [-14.391845703125, 65.78740234375],
            [-14.372802734375, 65.770361328125],
            [-14.369091796874983, 65.738720703125],
            [-14.350878906249989, 65.710107421875],
            [-14.318164062499989, 65.68447265625],
            [-14.328369140625, 65.658251953125],
            [-14.473388671875, 65.575341796875],
            [-14.302294921874989, 65.6275390625],
            [-14.166943359374983, 65.64228515625],
            [-13.935449218749994, 65.616064453125],
            [-13.840722656249994, 65.5859375],
            [-13.785253906249977, 65.5330078125],
            [-13.705126953124989, 65.550537109375],
            [-13.6703125, 65.54951171875],
            [-13.617871093749983, 65.5193359375],
            [-13.616015624999989, 65.487158203125],
            [-13.654443359374994, 65.44130859375],
            [-13.667773437499989, 65.398974609375],
            [-13.7080078125, 65.381591796875],
            [-13.783251953124989, 65.368994140625],
            [-13.804785156249977, 65.35478515625],
            [-13.771630859374994, 65.322509765625],
            [-13.722851562499983, 65.290966796875],
            [-13.653466796874994, 65.289501953125],
            [-13.64111328125, 65.275],
            [-13.639550781249994, 65.257470703125],
            [-13.64892578125, 65.236962890625],
            [-13.671582031249983, 65.2228515625],
            [-13.707421875, 65.21513671875],
            [-13.7548828125, 65.192529296875],
            [-13.580810546875, 65.143017578125],
            [-13.55859375, 65.124658203125],
            [-13.556103515624983, 65.09765625],
            [-13.569677734374977, 65.068115234375],
            [-13.599316406249983, 65.0359375],
            [-13.65185546875, 65.016845703125],
            [-13.777246093749994, 65.013720703125],
            [-13.85400390625, 64.99287109375],
            [-13.827832031249983, 64.9580078125],
            [-13.829833984375, 64.914013671875],
            [-13.852929687499994, 64.862158203125],
            [-13.95166015625, 64.783642578125],
            [-14.04443359375, 64.74189453125],
            [-14.13515625, 64.714794921875],
            [-14.296972656249977, 64.724365234375],
            [-14.385107421874977, 64.74521484375],
            [-14.375292968749989, 64.67744140625],
            [-14.465380859374989, 64.635693359375],
            [-14.448339843749977, 64.600830078125],
            [-14.4169921875, 64.58310546875],
            [-14.432568359374983, 64.538330078125],
            [-14.475390624999989, 64.493994140625],
            [-14.547070312499983, 64.445947265625],
            [-14.628222656249989, 64.415966796875],
            [-14.78955078125, 64.379833984375],
            [-14.927392578124994, 64.319677734375],
            [-15.021582031249977, 64.2958984375],
            [-15.255859375, 64.296923828125],
            [-15.494970703124977, 64.258203125],
            [-15.832910156249994, 64.17666015625],
            [-16.060449218749994, 64.11123046875],
            [-16.236035156249983, 64.03720703125],
            [-16.46806640624999, 63.916357421875],
            [-16.640332031249983, 63.865478515625],
            [-16.739697265624983, 63.8517578125],
            [-16.933056640624983, 63.84091796875],
            [-17.095117187499994, 63.80810546875],
            [-17.633447265624994, 63.74658203125],
            [-17.81572265624999, 63.71298828125],
            [-17.839257812499994, 63.682373046875],
            [-17.91484374999999, 63.636376953124994],
            [-17.919580078124994, 63.6197265625],
            [-17.886376953124994, 63.606884765625],
            [-17.880273437499994, 63.590185546875],
            [-17.946923828124994, 63.5357421875],
            [-18.080029296874983, 63.496337890625],
            [-18.142919921874977, 63.496972656249994],
            [-18.21904296874999, 63.530859375],
            [-18.252197265625, 63.5296875],
            [-18.265234374999977, 63.52451171875],
            [-18.266015625, 63.5138671875],
            [-18.222265624999977, 63.473193359375],
            [-18.302832031249977, 63.454248046874994],
            [-18.65361328124999, 63.406689453125],
            [-19.250195312499983, 63.4419921875],
            [-19.486572265625, 63.478515625],
            [-19.778271484374983, 63.536572265625],
            [-19.95195312499999, 63.55205078125],
            [-20.198144531249994, 63.555810546874994],
            [-20.40043945312499, 63.637109375],
            [-20.494042968749994, 63.687353515625],
            [-20.501562499999977, 63.708203125],
            [-20.49101562499999, 63.731982421875],
            [-20.469970703125, 63.748193359374994],
            [-20.4384765625, 63.756982421874994],
            [-20.371728515624994, 63.757861328125],
            [-20.363037109375, 63.76494140625],
            [-20.413964843749994, 63.80517578125],
            [-20.46269531249999, 63.792138671874994],
            [-20.592968749999983, 63.7353515625],
            [-20.65092773437499, 63.73740234375],
            [-20.72705078125, 63.765771484374994],
            [-20.729931640624983, 63.793359375],
            [-20.878759765624977, 63.80390625],
            [-21.00810546874999, 63.83837890625],
            [-21.136572265624977, 63.887939453125],
            [-21.15576171875, 63.9068359375],
            [-21.09404296874999, 63.934423828125],
            [-21.10595703125, 63.93984375],
            [-21.15239257812499, 63.94453125],
            [-21.246240234374994, 63.935449218749994],
            [-21.387597656249994, 63.872802734375],
            [-21.448632812499994, 63.8583984375],
            [-22.37255859375, 63.84375],
            [-22.606884765624983, 63.837255859375],
            [-22.652197265624977, 63.827734375],
            [-22.693017578124994, 63.868505859375],
            [-22.729394531249994, 63.95947265625],
            [-22.74296874999999, 64.019384765625],
            [-22.733642578125, 64.048388671875],
            [-22.701171875, 64.083203125],
            [-22.65092773437499, 64.077294921875],
            [-22.60307617187499, 64.049609375],
            [-22.559814453125, 64.0103515625],
            [-22.51005859374999, 63.991455078125],
            [-22.187597656249977, 64.039208984375],
            [-22.056640625, 64.071337890625],
            [-22.0009765625, 64.10185546875],
            [-21.935449218749994, 64.153759765625],
            [-21.865917968749983, 64.180322265625],
            [-21.832763671875, 64.205419921875],
            [-21.767578125, 64.28486328125],
            [-21.722558593749994, 64.32177734375],
            [-21.668652343749983, 64.3490234375],
            [-21.60600585937499, 64.3666015625],
            [-21.463330078124983, 64.379150390625],
            [-21.55717773437499, 64.3978515625],
            [-21.646679687499983, 64.3978515625],
            [-21.95122070312499, 64.313916015625],
            [-22.053369140624994, 64.313916015625],
            [-22.049072265625, 64.327001953125],
            [-22.006005859374994, 64.35068359375],
            [-21.901269531249994, 64.3916015625],
            [-21.97319335937499, 64.394677734375],
            [-22.000683593749983, 64.41318359375],
            [-22.003808593749994, 64.452197265625],
            [-21.950341796874994, 64.514990234375],
            [-21.702392578125, 64.597802734375],
            [-21.616650390624983, 64.610009765625],
            [-21.590625, 64.6263671875],
            [-21.623144531249977, 64.63974609375],
            [-21.674951171874994, 64.647705078125],
            [-21.924414062499977, 64.562548828125],
            [-22.10600585937499, 64.533056640625],
            [-22.15996093749999, 64.538818359375],
            [-22.25390625, 64.571875],
            [-22.2841796875, 64.586572265625],
            [-22.32470703125, 64.6244140625],
            [-22.32011718749999, 64.647216796875],
            [-22.233593749999983, 64.71396484375],
            [-22.24755859375, 64.726904296875],
            [-22.30703125, 64.73349609375],
            [-22.467041015625, 64.794970703125],
            [-22.720312499999977, 64.788818359375],
            [-23.34697265624999, 64.824365234375],
            [-23.476464843749994, 64.80927734375],
            [-23.68994140625, 64.75654296875],
            [-23.818994140624994, 64.73916015625],
            [-23.878564453124994, 64.750634765625],
            [-23.932763671874994, 64.778515625],
            [-23.98198242187499, 64.81611328125],
            [-24.02617187499999, 64.863427734375],
            [-24.007031249999983, 64.896435546875],
            [-23.924414062499977, 64.915234375],
            [-23.86381835937499, 64.924169921875],
            [-23.693212890624977, 64.912744140625],
            [-23.485302734374983, 64.945849609375],
            [-23.352685546874994, 64.952783203125],
            [-23.314599609374994, 64.9580078125],
            [-23.236523437499983, 64.99326171875],
            [-23.197998046875, 65.0021484375],
            [-23.137890624999983, 64.989794921875],
            [-23.108837890624983, 64.965869140625],
            [-22.899511718749977, 65.00302734375],
            [-22.82768554687499, 65.0216796875],
            [-22.819580078125, 65.03310546875],
            [-22.7880859375, 65.046484375],
            [-22.683984375, 65.0263671875],
            [-22.599707031249977, 65.025732421875],
            [-22.494482421874977, 65.03955078125],
            [-22.308447265624977, 65.045654296875],
            [-21.89213867187499, 65.048779296875],
            [-21.829785156249983, 65.0791015625],
            [-21.800439453124994, 65.105908203125],
            [-21.76372070312499, 65.17373046875],
            [-21.779980468749983, 65.1876953125],
            [-22.039990234374983, 65.125244140625],
            [-22.099316406249983, 65.126220703125],
            [-22.400292968749994, 65.159326171875],
            [-22.50908203124999, 65.19677734375],
            [-22.47343749999999, 65.22685546875],
            [-22.31396484375, 65.2916015625],
            [-22.149316406249994, 65.3435546875],
            [-21.906982421875, 65.39970703125],
            [-21.850244140624994, 65.421533203125],
            [-21.844384765624994, 65.44736328125],
            [-22.005761718749994, 65.49345703125],
            [-22.311474609374983, 65.480712890625],
            [-22.38969726562499, 65.535400390625],
            [-22.64360351562499, 65.5677734375],
            [-22.812646484374994, 65.547412109375],
            [-22.902490234374994, 65.58046875],
            [-23.1220703125, 65.534765625],
            [-23.60454101562499, 65.468603515625],
            [-23.796484374999977, 65.42275390625],
            [-23.89990234375, 65.407568359375],
            [-24.018994140624983, 65.44501953125],
            [-24.223974609374977, 65.48720703125],
            [-24.454785156249983, 65.500341796875],
            [-24.475683593749977, 65.5251953125],
            [-24.341064453125, 65.601220703125],
            [-24.248925781249994, 65.614990234375],
            [-24.156103515624977, 65.6080078125],
            [-23.97900390625, 65.55498046875],
            [-23.85673828124999, 65.53837890625],
            [-24.010009765625, 65.6162109375],
            [-24.006005859374994, 65.646142578125],
            [-24.017578125, 65.69091796875],
            [-24.065039062499977, 65.71015625],
            [-24.111914062499977, 65.759716796875],
            [-24.092626953124977, 65.77646484375],
            [-24.032421874999983, 65.78232421875],
            [-23.909082031249994, 65.765576171875],
            [-23.615917968749983, 65.67958984375],
            [-23.47197265624999, 65.69482421875],
            [-23.39296875, 65.726513671875],
            [-23.285351562499983, 65.75],
            [-23.31591796875, 65.762255859375],
            [-23.569287109374983, 65.763720703125],
            [-23.704736328124994, 65.781201171875],
            [-23.77324218749999, 65.80634765625],
            [-23.832617187499977, 65.84921875],
            [-23.811718749999983, 65.868896484375],
            [-23.741308593749977, 65.8845703125],
            [-23.52495117187499, 65.880029296875],
            [-23.66748046875, 65.954296875],
            [-23.766552734374983, 65.99697265625],
            [-23.77734375, 66.017578125],
            [-23.77055664062499, 66.04345703125],
            [-23.75712890624999, 66.060791015625],
            [-23.737158203124977, 66.06943359375],
            [-23.488867187499977, 66.02607421875],
            [-23.434472656249994, 66.02421875],
            [-23.48466796874999, 66.05224609375],
            [-23.59355468749999, 66.093408203125],
            [-23.598535156249994, 66.108837890625],
            [-23.552636718749994, 66.12158203125],
            [-23.529980468749983, 66.14501953125],
            [-23.527929687499977, 66.164404296875],
            [-23.452539062499994, 66.181005859375],
            [-23.376562499999977, 66.18173828125],
            [-23.3, 66.1666015625],
            [-23.06254882812499, 66.08623046875],
            [-23.028515624999983, 66.063671875],
            [-23.017285156249983, 66.033935546875],
            [-23.028906249999977, 65.9970703125],
            [-23.018994140624983, 65.98212890625],
            [-22.926220703124983, 65.99482421875],
            [-22.852246093749983, 65.979296875],
            [-22.815332031249994, 65.98349609375],
            [-22.723339843749983, 66.039013671875],
            [-22.659863281249983, 66.025927734375],
            [-22.62158203125, 65.999951171875],
            [-22.609716796874977, 65.97646484375],
            [-22.60405273437499, 65.944189453125],
            [-22.620214843749977, 65.876953125],
            [-22.61601562499999, 65.86748046875],
            [-22.55156249999999, 65.905419921875],
            [-22.44169921874999, 65.90830078125],
            [-22.42753906249999, 65.927392578125],
            [-22.42421875, 65.998095703125],
            [-22.43315429687499, 66.057666015625],
            [-22.4453125, 66.07001953125],
            [-22.80644531249999, 66.152587890625],
            [-22.869238281249977, 66.1720703125],
            [-22.947900390624994, 66.212744140625],
            [-22.931982421874977, 66.233203125],
            [-22.86162109374999, 66.25146484375],
            [-22.755517578124994, 66.258740234375],
            [-22.509375, 66.257763671875],
            [-22.48442382812499, 66.26630859375],
            [-22.532128906249994, 66.287744140625],
            [-22.646093749999977, 66.3015625],
            [-22.672753906249994, 66.313916015625],
            [-22.686230468749983, 66.3376953125],
            [-22.82133789062499, 66.32470703125],
            [-22.972021484374977, 66.324169921875],
            [-23.116943359375, 66.338720703125],
            [-23.11992187499999, 66.3572265625],
            [-23.062695312499983, 66.384375],
            [-22.9443359375, 66.429443359375],
            [-22.88920898437499, 66.440625],
            [-22.723730468749977, 66.432763671875],
            [-22.559326171875, 66.44541015625],
            [-22.426123046874977, 66.430126953125],
            [-22.320458984374994, 66.385498046875],
            [-22.17021484374999, 66.30712890625],
            [-21.966992187499983, 66.256982421875],
            [-21.948388671874994, 66.241259765625],
            [-21.840234375, 66.2001953125],
            [-21.62529296874999, 66.089697265625],
            [-21.406884765624994, 66.0255859375],
            [-21.39677734374999, 66.00927734375],
            [-21.432714843749977, 65.990087890625],
            [-21.51665039062499, 65.967578125],
            [-21.497460937499994, 65.955078125],
            [-21.387792968749977, 65.93876953125],
            [-21.308789062499983, 65.8953125],
            [-21.303466796875, 65.87646484375],
            [-21.374902343749994, 65.74189453125],
            [-21.412841796875, 65.713330078125],
            [-21.456640624999977, 65.6982421875],
            [-21.658447265625, 65.723583984375],
            [-21.6103515625, 65.68076171875],
            [-21.466259765624983, 65.63515625],
            [-21.43364257812499, 65.60966796875],
            [-21.45512695312499, 65.58466796875],
            [-21.439404296874983, 65.57890625],
            [-21.386621093749994, 65.592431640625],
            [-21.36474609375, 65.57822265625],
            [-21.373876953124977, 65.536376953125],
            [-21.396337890624977, 65.50166015625],
            [-21.43217773437499, 65.474072265625],
            [-21.421875, 65.462158203125],
            [-21.365478515625, 65.4658203125],
            [-21.31254882812499, 65.45869140625],
            [-21.22998046875, 65.42060546875],
            [-21.162988281249994, 65.304248046875],
            [-21.12968749999999, 65.2666015625],
            [-21.105712890625, 65.3],
            [-21.075585937499994, 65.3849609375],
            [-21.047314453124983, 65.428369140625],
            [-21.020849609374977, 65.4302734375],
            [-20.997998046874983, 65.44453125],
            [-20.978857421874977, 65.47119140625],
            [-20.93974609374999, 65.565185546875],
            [-20.804345703124994, 65.63642578125],
            [-20.739697265624983, 65.658251953125],
            [-20.678955078125, 65.6630859375],
            [-20.6494140625, 65.65419921875],
            [-20.54814453124999, 65.5794921875],
            [-20.486523437499983, 65.566943359375],
            [-20.454833984375, 65.571044921875],
            [-20.411523437499994, 65.621728515625],
            [-20.356640624999983, 65.71904296875],
            [-20.344091796874977, 65.827734375],
            [-20.373925781249994, 65.947705078125],
            [-20.356591796874994, 66.033251953125],
            [-20.292138671874994, 66.084375],
            [-20.20751953125, 66.10009765625],
            [-20.102685546874994, 66.08046875],
            [-20.026074218749983, 66.049267578125],
            [-19.874755859375, 65.930126953125],
            [-19.752636718749983, 65.8677734375],
            [-19.647851562499994, 65.80078125],
            [-19.59355468749999, 65.779052734375],
            [-19.489697265624983, 65.76806640625],
            [-19.461816406249994, 65.77236328125],
            [-19.443261718749994, 65.787841796875],
            [-19.43388671874999, 65.814453125],
            [-19.45625, 65.984912109375],
            [-19.42705078124999, 66.03798828125],
            [-19.382958984374994, 66.07568359375],
            [-19.1953125, 66.097900390625],
            [-19.093212890624983, 66.121533203125],
            [-18.99375, 66.1603515625],
            [-18.911328124999983, 66.18115234375],
            [-18.845898437499983, 66.183935546875],
            [-18.777539062499983, 66.168798828125],
            [-18.706201171874994, 66.1357421875],
            [-18.594921874999983, 66.071337890625],
            [-18.454931640624977, 65.96455078125],
            [-18.276953124999977, 65.884716796875],
            [-18.18364257812499, 65.7580078125],
            [-18.163720703124994, 65.736572265625],
            [-18.141943359374977, 65.73408203125],
            [-18.118408203125, 65.750537109375],
            [-18.10332031249999, 65.77392578125],
            [-18.099023437499994, 65.8302734375],
            [-18.148876953124983, 65.905029296875],
            [-18.315332031249994, 66.0931640625],
            [-18.318212890624977, 66.12880859375],
            [-18.29716796874999, 66.157421875],
            [-18.179882812499983, 66.160546875],
            [-17.906982421875, 66.143310546875],
            [-17.81982421875, 66.114111328125],
            [-17.63432617187499, 65.999169921875],
            [-17.582226562499983, 65.97138671875],
            [-17.550439453124994, 65.964404296875],
            [-17.539013671874983, 65.9783203125],
            [-17.467041015625, 65.999658203125],
            [-17.417236328125, 66.025537109375],
            [-17.33427734374999, 66.0888671875],
            [-17.153027343749983, 66.20283203125],
            [-17.115380859374994, 66.206201171875],
            [-17.062451171874983, 66.197216796875],
            [-16.96953124999999, 66.1673828125],
            [-16.925439453124994, 66.14345703125],
            [-16.838037109374994, 66.125244140625],
            [-16.7484375, 66.131640625],
            [-16.624755859375, 66.17158203125],
            [-16.485009765624994, 66.195947265625],
            [-16.437109374999977, 66.2525390625],
            [-16.428076171874977, 66.278369140625],
            [-16.540673828124994, 66.446728515625],
            [-16.493359374999983, 66.48115234375],
            [-16.24931640624999, 66.522900390625],
            [-16.035888671875, 66.52607421875],
            [-15.985400390624989, 66.5146484375],
            [-15.850927734374977, 66.432861328125],
            [-15.759765625, 66.39169921875],
            [-15.713769531249994, 66.35859375],
            [-15.702783203124994, 66.2857421875],
            [-15.647363281249994, 66.2587890625],
            [-15.543115234374994, 66.228515625],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 3,
        labelrank: 5,
        sovereignt: "Malta",
        sov_a3: "MLT",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Malta",
        adm0_a3: "MLT",
        geou_dif: 0,
        geounit: "Malta",
        gu_a3: "MLT",
        su_dif: 0,
        subunit: "Malta",
        su_a3: "MLT",
        brk_diff: 0,
        name: "Malta",
        name_long: "Malta",
        brk_a3: "MLT",
        brk_name: "Malta",
        brk_group: null,
        abbrev: "Malta",
        postal: "M",
        formal_en: "Republic of Malta",
        formal_fr: null,
        name_ciawf: "Malta",
        note_adm0: null,
        note_brk: null,
        name_sort: "Malta",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 4,
        mapcolor9: 1,
        mapcolor13: 8,
        pop_est: 502653,
        pop_rank: 11,
        pop_year: 2019,
        gdp_md: 14989,
        gdp_year: 2019,
        economy: "2. Developed region: nonG7",
        income_grp: "2. High income: nonOECD",
        fips_10: "MT",
        iso_a2: "MT",
        iso_a2_eh: "MT",
        iso_a3: "MLT",
        iso_a3_eh: "MLT",
        iso_n3: "470",
        iso_n3_eh: "470",
        un_a3: "470",
        wb_a2: "MT",
        wb_a3: "MLT",
        woe_id: 23424897,
        woe_id_eh: 23424897,
        woe_note: "Exact WOE match as country",
        adm0_iso: "MLT",
        adm0_diff: null,
        adm0_tlc: "MLT",
        adm0_a3_us: "MLT",
        adm0_a3_fr: "MLT",
        adm0_a3_ru: "MLT",
        adm0_a3_es: "MLT",
        adm0_a3_cn: "MLT",
        adm0_a3_tw: "MLT",
        adm0_a3_in: "MLT",
        adm0_a3_np: "MLT",
        adm0_a3_pk: "MLT",
        adm0_a3_de: "MLT",
        adm0_a3_gb: "MLT",
        adm0_a3_br: "MLT",
        adm0_a3_il: "MLT",
        adm0_a3_ps: "MLT",
        adm0_a3_sa: "MLT",
        adm0_a3_eg: "MLT",
        adm0_a3_ma: "MLT",
        adm0_a3_pt: "MLT",
        adm0_a3_ar: "MLT",
        adm0_a3_jp: "MLT",
        adm0_a3_ko: "MLT",
        adm0_a3_vn: "MLT",
        adm0_a3_tr: "MLT",
        adm0_a3_id: "MLT",
        adm0_a3_pl: "MLT",
        adm0_a3_gr: "MLT",
        adm0_a3_it: "MLT",
        adm0_a3_nl: "MLT",
        adm0_a3_se: "MLT",
        adm0_a3_bd: "MLT",
        adm0_a3_ua: "MLT",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Southern Europe",
        region_wb: "Middle East & North Africa",
        name_len: 5,
        long_len: 5,
        abbrev_len: 5,
        tiny: 3,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: 14.433005,
        label_y: 35.892886,
        ne_id: 1159321065,
        wikidataid: "Q233",
        name_ar: "مالطا",
        name_bn: "মাল্টা",
        name_de: "Malta",
        name_en: "Malta",
        name_es: "Malta",
        name_fa: "مالت",
        name_fr: "Malte",
        name_el: "Μάλτα",
        name_he: "מלטה",
        name_hi: "माल्टा",
        name_hu: "Málta",
        name_id: "Malta",
        name_it: "Malta",
        name_ja: "マルタ",
        name_ko: "몰타",
        name_nl: "Malta",
        name_pl: "Malta",
        name_pt: "Malta",
        name_ru: "Мальта",
        name_sv: "Malta",
        name_tr: "Malta",
        name_uk: "Мальта",
        name_ur: "مالٹا",
        name_vi: "Malta",
        name_zh: "马耳他",
        name_zht: "馬耳他",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "MLT.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [14.566210937500017, 35.852734375],
              [14.53271484375, 35.820214843749994],
              [14.436425781250023, 35.821679687499994],
              [14.352343750000017, 35.872265625],
              [14.351269531250011, 35.978417968749994],
              [14.448339843750006, 35.957421875],
              [14.537011718750023, 35.886279296874996],
              [14.566210937500017, 35.852734375],
            ],
          ],
          [
            [
              [14.3134765625, 36.027587890625],
              [14.253613281250011, 36.012158203125],
              [14.194238281250023, 36.042236328125],
              [14.180371093750011, 36.060400390625],
              [14.26328125, 36.07578125],
              [14.3037109375, 36.062304687499996],
              [14.320898437500006, 36.03623046875],
              [14.3134765625, 36.027587890625],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 4,
        labelrank: 6,
        sovereignt: "United Kingdom",
        sov_a3: "GB1",
        adm0_dif: 1,
        level: 2,
        type: "Country",
        tlc: "1",
        admin: "Jersey",
        adm0_a3: "JEY",
        geou_dif: 0,
        geounit: "Jersey",
        gu_a3: "JEY",
        su_dif: 0,
        subunit: "Jersey",
        su_a3: "JEY",
        brk_diff: 0,
        name: "Jersey",
        name_long: "Jersey",
        brk_a3: "JEY",
        brk_name: "Jersey",
        brk_group: "Channel Islands",
        abbrev: "Jey.",
        postal: "JE",
        formal_en: "Bailiwick of Jersey",
        formal_fr: null,
        name_ciawf: "Jersey",
        note_adm0: "U.K.",
        note_brk: "U.K. crown dependency",
        name_sort: "Jersey",
        name_alt: null,
        mapcolor7: 6,
        mapcolor8: 6,
        mapcolor9: 6,
        mapcolor13: 3,
        pop_est: 107800,
        pop_rank: 9,
        pop_year: 2019,
        gdp_md: 5080,
        gdp_year: 2015,
        economy: "2. Developed region: nonG7",
        income_grp: "2. High income: nonOECD",
        fips_10: "JE",
        iso_a2: "JE",
        iso_a2_eh: "JE",
        iso_a3: "JEY",
        iso_a3_eh: "JEY",
        iso_n3: "832",
        iso_n3_eh: "832",
        un_a3: "832",
        wb_a2: "JG",
        wb_a3: "CHI",
        woe_id: 23424857,
        woe_id_eh: 23424857,
        woe_note: "Exact WOE match as country",
        adm0_iso: "JEY",
        adm0_diff: null,
        adm0_tlc: "JEY",
        adm0_a3_us: "JEY",
        adm0_a3_fr: "JEY",
        adm0_a3_ru: "JEY",
        adm0_a3_es: "JEY",
        adm0_a3_cn: "JEY",
        adm0_a3_tw: "JEY",
        adm0_a3_in: "JEY",
        adm0_a3_np: "JEY",
        adm0_a3_pk: "JEY",
        adm0_a3_de: "JEY",
        adm0_a3_gb: "JEY",
        adm0_a3_br: "JEY",
        adm0_a3_il: "JEY",
        adm0_a3_ps: "JEY",
        adm0_a3_sa: "JEY",
        adm0_a3_eg: "JEY",
        adm0_a3_ma: "JEY",
        adm0_a3_pt: "JEY",
        adm0_a3_ar: "JEY",
        adm0_a3_jp: "JEY",
        adm0_a3_ko: "JEY",
        adm0_a3_vn: "JEY",
        adm0_a3_tr: "JEY",
        adm0_a3_id: "JEY",
        adm0_a3_pl: "JEY",
        adm0_a3_gr: "JEY",
        adm0_a3_it: "JEY",
        adm0_a3_nl: "JEY",
        adm0_a3_se: "JEY",
        adm0_a3_bd: "JEY",
        adm0_a3_ua: "JEY",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Northern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 6,
        long_len: 6,
        abbrev_len: 4,
        tiny: -99,
        homepart: -99,
        min_zoom: 0,
        min_label: 5,
        max_label: 10,
        label_x: -2.090146,
        label_y: 49.220808,
        ne_id: 1159320725,
        wikidataid: "Q785",
        name_ar: "جيرزي",
        name_bn: "জার্সি",
        name_de: "Jersey",
        name_en: "Jersey",
        name_es: "Jersey",
        name_fa: "جرزی",
        name_fr: "Jersey",
        name_el: "Τζέρσεϊ",
        name_he: "ג'רזי",
        name_hi: "जर्सी",
        name_hu: "Jersey",
        name_id: "Jersey",
        name_it: "baliato di Jersey",
        name_ja: "ジャージー",
        name_ko: "저지섬",
        name_nl: "Jersey",
        name_pl: "Jersey",
        name_pt: "Jersey",
        name_ru: "Джерси",
        name_sv: "Jersey",
        name_tr: "Jersey",
        name_uk: "Джерсі",
        name_ur: "جرزی",
        name_vi: "Jersey",
        name_zh: "泽西",
        name_zht: "澤西",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "JEY.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.018652343749977, 49.23125],
            [-2.009912109374994, 49.180810546874994],
            [-2.053759765624989, 49.169824218749994],
            [-2.091015624999983, 49.187402343749994],
            [-2.165673828124994, 49.187402343749994],
            [-2.23583984375, 49.1763671875],
            [-2.220507812499989, 49.266357421875],
            [-2.082226562499983, 49.25537109375],
            [-2.018652343749977, 49.23125],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 3,
        labelrank: 6,
        sovereignt: "United Kingdom",
        sov_a3: "GB1",
        adm0_dif: 1,
        level: 2,
        type: "Country",
        tlc: "1",
        admin: "Isle of Man",
        adm0_a3: "IMN",
        geou_dif: 0,
        geounit: "Isle of Man",
        gu_a3: "IMN",
        su_dif: 0,
        subunit: "Isle of Man",
        su_a3: "IMN",
        brk_diff: 0,
        name: "Isle of Man",
        name_long: "Isle of Man",
        brk_a3: "IMN",
        brk_name: "Isle of Man",
        brk_group: null,
        abbrev: "IoMan",
        postal: "IM",
        formal_en: null,
        formal_fr: null,
        name_ciawf: "Isle of Man",
        note_adm0: "U.K.",
        note_brk: "U.K. crown dependency",
        name_sort: "Isle of Man",
        name_alt: null,
        mapcolor7: 6,
        mapcolor8: 6,
        mapcolor9: 6,
        mapcolor13: 3,
        pop_est: 84584,
        pop_rank: 8,
        pop_year: 2019,
        gdp_md: 7491,
        gdp_year: 2018,
        economy: "2. Developed region: nonG7",
        income_grp: "2. High income: nonOECD",
        fips_10: "IM",
        iso_a2: "IM",
        iso_a2_eh: "IM",
        iso_a3: "IMN",
        iso_a3_eh: "IMN",
        iso_n3: "833",
        iso_n3_eh: "833",
        un_a3: "833",
        wb_a2: "IM",
        wb_a3: "IMY",
        woe_id: 23424847,
        woe_id_eh: 23424847,
        woe_note: "Exact WOE match as country",
        adm0_iso: "IMN",
        adm0_diff: null,
        adm0_tlc: "IMN",
        adm0_a3_us: "IMN",
        adm0_a3_fr: "IMN",
        adm0_a3_ru: "IMN",
        adm0_a3_es: "IMN",
        adm0_a3_cn: "IMN",
        adm0_a3_tw: "IMN",
        adm0_a3_in: "IMN",
        adm0_a3_np: "IMN",
        adm0_a3_pk: "IMN",
        adm0_a3_de: "IMN",
        adm0_a3_gb: "IMN",
        adm0_a3_br: "IMN",
        adm0_a3_il: "IMN",
        adm0_a3_ps: "IMN",
        adm0_a3_sa: "IMN",
        adm0_a3_eg: "IMN",
        adm0_a3_ma: "IMN",
        adm0_a3_pt: "IMN",
        adm0_a3_ar: "IMN",
        adm0_a3_jp: "IMN",
        adm0_a3_ko: "IMN",
        adm0_a3_vn: "IMN",
        adm0_a3_tr: "IMN",
        adm0_a3_id: "IMN",
        adm0_a3_pl: "IMN",
        adm0_a3_gr: "IMN",
        adm0_a3_it: "IMN",
        adm0_a3_nl: "IMN",
        adm0_a3_se: "IMN",
        adm0_a3_bd: "IMN",
        adm0_a3_ua: "IMN",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Northern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 11,
        long_len: 11,
        abbrev_len: 5,
        tiny: -99,
        homepart: -99,
        min_zoom: 0,
        min_label: 5,
        max_label: 10,
        label_x: -4.530069,
        label_y: 54.220833,
        ne_id: 1159320721,
        wikidataid: "Q9676",
        name_ar: "جزيرة مان",
        name_bn: "আইল অব ম্যান",
        name_de: "Isle of Man",
        name_en: "Isle of Man",
        name_es: "Isla de Man",
        name_fa: "جزیره من",
        name_fr: "île de Man",
        name_el: "Νήσος του Μαν",
        name_he: "האי מאן",
        name_hi: "आइल ऑफ़ मैन",
        name_hu: "Man",
        name_id: "Pulau Man",
        name_it: "Isola di Man",
        name_ja: "マン島",
        name_ko: "맨섬",
        name_nl: "Man",
        name_pl: "Wyspa Man",
        name_pt: "Ilha de Man",
        name_ru: "остров Мэн",
        name_sv: "Isle of Man",
        name_tr: "Man Adası",
        name_uk: "Острів Мен",
        name_ur: "آئل آف مین",
        name_vi: "Đảo Man",
        name_zh: "马恩岛",
        name_zht: "曼島",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "IMN.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-4.412060546874983, 54.185351562499996],
            [-4.6142578125, 54.05869140625],
            [-4.696093749999989, 54.0814453125],
            [-4.765771484374994, 54.06943359375],
            [-4.785351562499983, 54.073046875],
            [-4.745556640624983, 54.118798828125],
            [-4.69873046875, 54.224902343749996],
            [-4.614843749999977, 54.266943359375],
            [-4.508642578124977, 54.376708984375],
            [-4.424707031249994, 54.407177734375],
            [-4.395556640624989, 54.4029296875],
            [-4.377197265625, 54.392578125],
            [-4.337988281249977, 54.269091796874996],
            [-4.392285156249983, 54.225390625],
            [-4.412060546874983, 54.185351562499996],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 4,
        labelrank: 6,
        sovereignt: "United Kingdom",
        sov_a3: "GB1",
        adm0_dif: 1,
        level: 2,
        type: "Country",
        tlc: "1",
        admin: "Guernsey",
        adm0_a3: "GGY",
        geou_dif: 0,
        geounit: "Guernsey",
        gu_a3: "GGY",
        su_dif: 0,
        subunit: "Guernsey",
        su_a3: "GGY",
        brk_diff: 0,
        name: "Guernsey",
        name_long: "Guernsey",
        brk_a3: "GGY",
        brk_name: "Guernsey",
        brk_group: "Channel Islands",
        abbrev: "Guern.",
        postal: "GG",
        formal_en: "Bailiwick of Guernsey",
        formal_fr: null,
        name_ciawf: "Guernsey",
        note_adm0: "U.K.",
        note_brk: "U.K. crown dependency",
        name_sort: "Guernsey",
        name_alt: null,
        mapcolor7: 6,
        mapcolor8: 6,
        mapcolor9: 6,
        mapcolor13: 3,
        pop_est: 62792,
        pop_rank: 8,
        pop_year: 2019,
        gdp_md: 3465,
        gdp_year: 2015,
        economy: "2. Developed region: nonG7",
        income_grp: "2. High income: nonOECD",
        fips_10: "GK",
        iso_a2: "GG",
        iso_a2_eh: "GG",
        iso_a3: "GGY",
        iso_a3_eh: "GGY",
        iso_n3: "831",
        iso_n3_eh: "831",
        un_a3: "831",
        wb_a2: "JG",
        wb_a3: "CHI",
        woe_id: 23424827,
        woe_id_eh: 23424827,
        woe_note: "Exact WOE match as country",
        adm0_iso: "GGY",
        adm0_diff: null,
        adm0_tlc: "GGY",
        adm0_a3_us: "GGY",
        adm0_a3_fr: "GGY",
        adm0_a3_ru: "GGY",
        adm0_a3_es: "GGY",
        adm0_a3_cn: "GGY",
        adm0_a3_tw: "GGY",
        adm0_a3_in: "GGY",
        adm0_a3_np: "GGY",
        adm0_a3_pk: "GGY",
        adm0_a3_de: "GGY",
        adm0_a3_gb: "GGY",
        adm0_a3_br: "GGY",
        adm0_a3_il: "GGY",
        adm0_a3_ps: "GGY",
        adm0_a3_sa: "GGY",
        adm0_a3_eg: "GGY",
        adm0_a3_ma: "GGY",
        adm0_a3_pt: "GGY",
        adm0_a3_ar: "GGY",
        adm0_a3_jp: "GGY",
        adm0_a3_ko: "GGY",
        adm0_a3_vn: "GGY",
        adm0_a3_tr: "GGY",
        adm0_a3_id: "GGY",
        adm0_a3_pl: "GGY",
        adm0_a3_gr: "GGY",
        adm0_a3_it: "GGY",
        adm0_a3_nl: "GGY",
        adm0_a3_se: "GGY",
        adm0_a3_bd: "GGY",
        adm0_a3_ua: "GGY",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Northern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 8,
        long_len: 8,
        abbrev_len: 6,
        tiny: -99,
        homepart: -99,
        min_zoom: 0,
        min_label: 5,
        max_label: 10,
        label_x: -2.561736,
        label_y: 49.463533,
        ne_id: 1159320715,
        wikidataid: "Q25230",
        name_ar: "غيرنزي",
        name_bn: "গার্নসি",
        name_de: "Guernsey",
        name_en: "Guernsey",
        name_es: "Guernsey",
        name_fa: "گرنزی",
        name_fr: "Guernesey",
        name_el: "Γκέρνσεϊ",
        name_he: "גרנזי",
        name_hi: "ग्वेर्नसे",
        name_hu: "Guernsey Bailiffség",
        name_id: "Guernsey",
        name_it: "Guernsey",
        name_ja: "ガーンジー",
        name_ko: "건지섬",
        name_nl: "Guernsey",
        name_pl: "Guernsey",
        name_pt: "Guernsey",
        name_ru: "Гернси",
        name_sv: "Guernsey",
        name_tr: "Guernsey",
        name_uk: "Гернсі",
        name_ur: "گرنزی",
        name_vi: "Guernsey",
        name_zh: "根西",
        name_zht: "根西",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "GGY.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.512304687499977, 49.49453125],
            [-2.54736328125, 49.4287109375],
            [-2.639013671874977, 49.450927734375],
            [-2.646142578124994, 49.468212890625],
            [-2.542187499999983, 49.506591796875],
            [-2.520898437499994, 49.506298828125],
            [-2.512304687499977, 49.49453125],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 3,
        labelrank: 6,
        sovereignt: "Finland",
        sov_a3: "FI1",
        adm0_dif: 1,
        level: 2,
        type: "Country",
        tlc: "1",
        admin: "Aland",
        adm0_a3: "ALD",
        geou_dif: 0,
        geounit: "Aland",
        gu_a3: "ALD",
        su_dif: 0,
        subunit: "Aland",
        su_a3: "ALD",
        brk_diff: 0,
        name: "Åland",
        name_long: "Åland Islands",
        brk_a3: "ALD",
        brk_name: "Åland",
        brk_group: null,
        abbrev: "Åland",
        postal: "AI",
        formal_en: "Åland Islands",
        formal_fr: null,
        name_ciawf: null,
        note_adm0: "Fin.",
        note_brk: null,
        name_sort: "Aland",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 1,
        mapcolor9: 4,
        mapcolor13: 6,
        pop_est: 29884,
        pop_rank: 7,
        pop_year: 2019,
        gdp_md: 1563,
        gdp_year: 2016,
        economy: "2. Developed region: nonG7",
        income_grp: "1. High income: OECD",
        fips_10: "-99",
        iso_a2: "AX",
        iso_a2_eh: "AX",
        iso_a3: "ALA",
        iso_a3_eh: "ALA",
        iso_n3: "248",
        iso_n3_eh: "248",
        un_a3: "248",
        wb_a2: "-99",
        wb_a3: "-99",
        woe_id: 12577865,
        woe_id_eh: 12577865,
        woe_note: "Exact WOE match as country",
        adm0_iso: "ALD",
        adm0_diff: null,
        adm0_tlc: "ALD",
        adm0_a3_us: "ALD",
        adm0_a3_fr: "ALD",
        adm0_a3_ru: "ALD",
        adm0_a3_es: "ALD",
        adm0_a3_cn: "ALD",
        adm0_a3_tw: "ALD",
        adm0_a3_in: "ALD",
        adm0_a3_np: "ALD",
        adm0_a3_pk: "ALD",
        adm0_a3_de: "ALD",
        adm0_a3_gb: "ALD",
        adm0_a3_br: "ALD",
        adm0_a3_il: "ALD",
        adm0_a3_ps: "ALD",
        adm0_a3_sa: "ALD",
        adm0_a3_eg: "ALD",
        adm0_a3_ma: "ALD",
        adm0_a3_pt: "ALD",
        adm0_a3_ar: "ALD",
        adm0_a3_jp: "ALD",
        adm0_a3_ko: "ALD",
        adm0_a3_vn: "ALD",
        adm0_a3_tr: "ALD",
        adm0_a3_id: "ALD",
        adm0_a3_pl: "ALD",
        adm0_a3_gr: "ALD",
        adm0_a3_it: "ALD",
        adm0_a3_nl: "ALD",
        adm0_a3_se: "ALD",
        adm0_a3_bd: "ALD",
        adm0_a3_ua: "ALD",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Northern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 5,
        long_len: 13,
        abbrev_len: 5,
        tiny: 5,
        homepart: -99,
        min_zoom: 0,
        min_label: 5,
        max_label: 10,
        label_x: 19.869671,
        label_y: 60.156467,
        ne_id: 1159320621,
        wikidataid: "Q5689",
        name_ar: "جزر أولاند",
        name_bn: "অলান্দ দ্বীপপুঞ্জ",
        name_de: "Åland",
        name_en: "Åland",
        name_es: "Åland",
        name_fa: "جزایر الند",
        name_fr: "Åland",
        name_el: "Ώλαντ",
        name_he: "אולנד",
        name_hi: "ऑलैण्ड द्वीपसमूह",
        name_hu: "Åland",
        name_id: "Åland",
        name_it: "Isole Åland",
        name_ja: "オーランド諸島",
        name_ko: "올란드 제도",
        name_nl: "Åland",
        name_pl: "Wyspy Alandzkie",
        name_pt: "Åland",
        name_ru: "Аландские острова",
        name_sv: "Åland",
        name_tr: "Åland",
        name_uk: "Аландські острови",
        name_ur: "جزائر ایلانڈ",
        name_vi: "Åland",
        name_zh: "奥兰",
        name_zht: "奧蘭",
        fclass_iso: "Admin-0 dependency",
        tlc_diff: null,
        fclass_tlc: "Admin-0 dependency",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: "Unrecognized",
        fclass_es: null,
        fclass_cn: "Unrecognized",
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "ALA.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [19.989550781250017, 60.351171875],
              [20.02021484375001, 60.35087890625],
              [20.03388671875001, 60.359326171875],
              [20.08740234375, 60.353417968749994],
              [20.167871093750023, 60.314697265625],
              [20.18408203125, 60.29375],
              [20.239550781250017, 60.2830078125],
              [20.258886718750006, 60.261279296875],
              [20.194726562500023, 60.1935546875],
              [20.155078125000017, 60.192285156249994],
              [20.12548828125, 60.20087890625],
              [20.0732421875, 60.19345703125],
              [20.042578125, 60.1806640625],
              [20.032324218750006, 60.152490234374994],
              [20.033984375000017, 60.0935546875],
              [19.7998046875, 60.08173828125],
              [19.745996093750023, 60.098974609375],
              [19.672265625000023, 60.2330078125],
              [19.686914062500023, 60.267626953125],
              [19.73652343750001, 60.282373046874994],
              [19.77900390625001, 60.285546875],
              [19.785253906250006, 60.21337890625],
              [19.84765625, 60.220556640625],
              [19.8671875, 60.268115234375],
              [19.87158203125, 60.301611328125],
              [19.85468750000001, 60.318505859374994],
              [19.812304687500017, 60.331591796875],
              [19.78779296875001, 60.354052734375],
              [19.823046875000017, 60.390185546875],
              [19.88828125, 60.405810546875],
              [19.94453125000001, 60.35751953125],
              [19.989550781250017, 60.351171875],
            ],
          ],
          [
            [
              [19.66230468750001, 60.187158203124994],
              [19.66748046875, 60.16474609375],
              [19.629199218750017, 60.170361328125],
              [19.59980468750001, 60.1626953125],
              [19.579882812500017, 60.13505859375],
              [19.536523437500023, 60.144970703125],
              [19.51904296875, 60.1845703125],
              [19.551367187500006, 60.24384765625],
              [19.628808593750023, 60.24609375],
              [19.66230468750001, 60.187158203124994],
            ],
          ],
          [
            [
              [20.611328125, 60.040673828124994],
              [20.603417968750023, 60.016943359375],
              [20.521777343750017, 60.011669921875],
              [20.4875, 60.032763671875],
              [20.411230468750006, 60.030126953125],
              [20.39794921875, 60.040673828124994],
              [20.429589843750023, 60.06171875],
              [20.490136718750023, 60.07490234375],
              [20.569140625000017, 60.06962890625],
              [20.611328125, 60.040673828124994],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 3,
        labelrank: 6,
        sovereignt: "Denmark",
        sov_a3: "DN1",
        adm0_dif: 1,
        level: 2,
        type: "Dependency",
        tlc: "1",
        admin: "Faroe Islands",
        adm0_a3: "FRO",
        geou_dif: 0,
        geounit: "Faroe Islands",
        gu_a3: "FRO",
        su_dif: 0,
        subunit: "Faroe Islands",
        su_a3: "FRO",
        brk_diff: 0,
        name: "Faeroe Is.",
        name_long: "Faeroe Islands",
        brk_a3: "FRO",
        brk_name: "Faeroe Islands",
        brk_group: null,
        abbrev: "Faeroe Is.",
        postal: "FO",
        formal_en: "Føroyar Is. (Faeroe Is.)",
        formal_fr: null,
        name_ciawf: "Faroe Islands",
        note_adm0: "Den.",
        note_brk: null,
        name_sort: "Faeroe Islands",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 1,
        mapcolor9: 3,
        mapcolor13: 12,
        pop_est: 48678,
        pop_rank: 7,
        pop_year: 2019,
        gdp_md: 3116,
        gdp_year: 2018,
        economy: "2. Developed region: nonG7",
        income_grp: "2. High income: nonOECD",
        fips_10: "FO",
        iso_a2: "FO",
        iso_a2_eh: "FO",
        iso_a3: "FRO",
        iso_a3_eh: "FRO",
        iso_n3: "234",
        iso_n3_eh: "234",
        un_a3: "234",
        wb_a2: "FO",
        wb_a3: "FRO",
        woe_id: 23424816,
        woe_id_eh: 23424816,
        woe_note: "Exact WOE match as country",
        adm0_iso: "FRO",
        adm0_diff: null,
        adm0_tlc: "FRO",
        adm0_a3_us: "FRO",
        adm0_a3_fr: "FRO",
        adm0_a3_ru: "FRO",
        adm0_a3_es: "FRO",
        adm0_a3_cn: "FRO",
        adm0_a3_tw: "FRO",
        adm0_a3_in: "FRO",
        adm0_a3_np: "FRO",
        adm0_a3_pk: "FRO",
        adm0_a3_de: "FRO",
        adm0_a3_gb: "FRO",
        adm0_a3_br: "FRO",
        adm0_a3_il: "FRO",
        adm0_a3_ps: "FRO",
        adm0_a3_sa: "FRO",
        adm0_a3_eg: "FRO",
        adm0_a3_ma: "FRO",
        adm0_a3_pt: "FRO",
        adm0_a3_ar: "FRO",
        adm0_a3_jp: "FRO",
        adm0_a3_ko: "FRO",
        adm0_a3_vn: "FRO",
        adm0_a3_tr: "FRO",
        adm0_a3_id: "FRO",
        adm0_a3_pl: "FRO",
        adm0_a3_gr: "FRO",
        adm0_a3_it: "FRO",
        adm0_a3_nl: "FRO",
        adm0_a3_se: "FRO",
        adm0_a3_bd: "FRO",
        adm0_a3_ua: "FRO",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Northern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 10,
        long_len: 14,
        abbrev_len: 10,
        tiny: 3,
        homepart: -99,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: -7.058429,
        label_y: 62.185604,
        ne_id: 1159320549,
        wikidataid: "Q4628",
        name_ar: "جزر فارو",
        name_bn: "ফ্যারো দ্বীপপুঞ্জ",
        name_de: "Färöer",
        name_en: "Faroe Islands",
        name_es: "Islas Feroe",
        name_fa: "جزایر فارو",
        name_fr: "îles Féroé",
        name_el: "Νήσοι Φερόες",
        name_he: "איי פארו",
        name_hi: "फ़रो द्वीपसमूह",
        name_hu: "Feröer",
        name_id: "Kepulauan Faroe",
        name_it: "Fær Øer",
        name_ja: "フェロー諸島",
        name_ko: "페로 제도",
        name_nl: "Faeröer",
        name_pl: "Wyspy Owcze",
        name_pt: "Ilhas Feroe",
        name_ru: "Фарерские острова",
        name_sv: "Färöarna",
        name_tr: "Faroe Adaları",
        name_uk: "Фарерські острови",
        name_ur: "جزائرفارو",
        name_vi: "Quần đảo Faroe",
        name_zh: "法罗群岛",
        name_zht: "法羅群島",
        fclass_iso: "Admin-0 dependency",
        tlc_diff: null,
        fclass_tlc: "Admin-0 dependency",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "FRO.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-6.623193359374994, 61.80595703125],
              [-6.642773437499983, 61.768310546875],
              [-6.670166015625, 61.76865234375],
              [-6.764257812499977, 61.815332031249994],
              [-6.839160156249989, 61.840771484375],
              [-6.863964843749983, 61.862255859375],
              [-6.884765625, 61.89912109375],
              [-6.841796875, 61.903710937499994],
              [-6.790771484375, 61.895361328125],
              [-6.662109375, 61.861767578125],
              [-6.625830078124977, 61.826708984375],
              [-6.623193359374994, 61.80595703125],
            ],
          ],
          [
            [
              [-6.699462890625, 61.44462890625],
              [-6.6796875, 61.414306640625],
              [-6.703027343749994, 61.41767578125],
              [-6.7705078125, 61.45224609375],
              [-6.888134765624983, 61.534765625],
              [-6.929248046874989, 61.602929687499994],
              [-6.934863281249989, 61.634326171875],
              [-6.905908203124994, 61.630810546875],
              [-6.881640624999989, 61.602783203125],
              [-6.77001953125, 61.584375],
              [-6.740625, 61.5705078125],
              [-6.741064453124977, 61.536376953125],
              [-6.703515625, 61.495947265625],
              [-6.699462890625, 61.44462890625],
            ],
          ],
          [
            [
              [-7.186865234374977, 62.139306640624994],
              [-7.097119140624983, 62.100537109375],
              [-7.065185546875, 62.0732421875],
              [-7.116796874999977, 62.046826171875],
              [-7.179394531249983, 62.0400390625],
              [-7.254931640624989, 62.046142578125],
              [-7.379101562499983, 62.0748046875],
              [-7.422607421875, 62.140283203124994],
              [-7.336767578124977, 62.138671875],
              [-7.235302734374983, 62.151220703125],
              [-7.186865234374977, 62.139306640624994],
            ],
          ],
          [
            [
              [-6.631054687499983, 62.227880859375],
              [-6.655810546874989, 62.093603515625],
              [-6.696435546874994, 62.0943359375],
              [-6.768896484374977, 62.131494140624994],
              [-6.823437499999983, 62.139111328125],
              [-6.840527343749983, 62.119287109374994],
              [-6.837695312499989, 62.09541015625],
              [-6.809472656249994, 62.080419921875],
              [-6.722558593749994, 61.990380859374994],
              [-6.714404296874989, 61.96416015625],
              [-6.725195312499977, 61.95146484375],
              [-6.809716796874994, 61.977441406249994],
              [-7.013574218749994, 62.093994140625],
              [-7.172167968749989, 62.285595703125],
              [-6.958642578124994, 62.316259765625],
              [-6.803662109374983, 62.265966796875],
              [-6.631054687499983, 62.227880859375],
            ],
          ],
          [
            [
              [-6.406054687499989, 62.258642578125],
              [-6.453857421875, 62.1865234375],
              [-6.524707031249989, 62.1978515625],
              [-6.544140624999983, 62.205615234375],
              [-6.559472656249994, 62.224511718749994],
              [-6.552050781249989, 62.278125],
              [-6.554589843749994, 62.3556640625],
              [-6.473046875, 62.291894531249994],
              [-6.406054687499989, 62.258642578125],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 2,
        sovereignt: "Australia",
        sov_a3: "AU1",
        adm0_dif: 1,
        level: 2,
        type: "Country",
        tlc: "1",
        admin: "Australia",
        adm0_a3: "AUS",
        geou_dif: 0,
        geounit: "Australia",
        gu_a3: "AUS",
        su_dif: 0,
        subunit: "Australia",
        su_a3: "AUS",
        brk_diff: 0,
        name: "Australia",
        name_long: "Australia",
        brk_a3: "AUS",
        brk_name: "Australia",
        brk_group: null,
        abbrev: "Auz.",
        postal: "AU",
        formal_en: "Commonwealth of Australia",
        formal_fr: null,
        name_ciawf: "Australia",
        note_adm0: null,
        note_brk: null,
        name_sort: "Australia",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 2,
        mapcolor9: 2,
        mapcolor13: 7,
        pop_est: 25364307,
        pop_rank: 15,
        pop_year: 2019,
        gdp_md: 1396567,
        gdp_year: 2019,
        economy: "2. Developed region: nonG7",
        income_grp: "1. High income: OECD",
        fips_10: "AS",
        iso_a2: "AU",
        iso_a2_eh: "AU",
        iso_a3: "AUS",
        iso_a3_eh: "AUS",
        iso_n3: "036",
        iso_n3_eh: "036",
        un_a3: "036",
        wb_a2: "AU",
        wb_a3: "AUS",
        woe_id: -90,
        woe_id_eh: 23424748,
        woe_note:
          "Includes Ashmore and Cartier Islands (23424749) and Coral Sea Islands (23424790).",
        adm0_iso: "AUS",
        adm0_diff: null,
        adm0_tlc: "AUS",
        adm0_a3_us: "AUS",
        adm0_a3_fr: "AUS",
        adm0_a3_ru: "AUS",
        adm0_a3_es: "AUS",
        adm0_a3_cn: "AUS",
        adm0_a3_tw: "AUS",
        adm0_a3_in: "AUS",
        adm0_a3_np: "AUS",
        adm0_a3_pk: "AUS",
        adm0_a3_de: "AUS",
        adm0_a3_gb: "AUS",
        adm0_a3_br: "AUS",
        adm0_a3_il: "AUS",
        adm0_a3_ps: "AUS",
        adm0_a3_sa: "AUS",
        adm0_a3_eg: "AUS",
        adm0_a3_ma: "AUS",
        adm0_a3_pt: "AUS",
        adm0_a3_ar: "AUS",
        adm0_a3_jp: "AUS",
        adm0_a3_ko: "AUS",
        adm0_a3_vn: "AUS",
        adm0_a3_tr: "AUS",
        adm0_a3_id: "AUS",
        adm0_a3_pl: "AUS",
        adm0_a3_gr: "AUS",
        adm0_a3_it: "AUS",
        adm0_a3_nl: "AUS",
        adm0_a3_se: "AUS",
        adm0_a3_bd: "AUS",
        adm0_a3_ua: "AUS",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Oceania",
        region_un: "Oceania",
        subregion: "Australia and New Zealand",
        region_wb: "East Asia & Pacific",
        name_len: 9,
        long_len: 9,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 1.7,
        max_label: 5.7,
        label_x: 134.04972,
        label_y: -24.129522,
        ne_id: 1159320355,
        wikidataid: "Q408",
        name_ar: "أستراليا",
        name_bn: "অস্ট্রেলিয়া",
        name_de: "Australien",
        name_en: "Australia",
        name_es: "Australia",
        name_fa: "استرالیا",
        name_fr: "Australie",
        name_el: "Αυστραλία",
        name_he: "אוסטרליה",
        name_hi: "ऑस्ट्रेलिया",
        name_hu: "Ausztrália",
        name_id: "Australia",
        name_it: "Australia",
        name_ja: "オーストラリア",
        name_ko: "오스트레일리아",
        name_nl: "Australië",
        name_pl: "Australia",
        name_pt: "Austrália",
        name_ru: "Австралия",
        name_sv: "Australien",
        name_tr: "Avustralya",
        name_uk: "Австралія",
        name_ur: "آسٹریلیا",
        name_vi: "Úc",
        name_zh: "澳大利亚",
        name_zht: "澳大利亞",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "AUS.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [143.17890625, -11.954492187500009],
              [143.15292968750003, -12.075878906250011],
              [143.1046875, -12.169628906250011],
              [143.09902343750002, -12.225976562500009],
              [143.11025390625002, -12.303515625],
              [143.190625, -12.361230468750009],
              [143.2541015625, -12.397656250000011],
              [143.28964843750003, -12.498828125],
              [143.4015625, -12.639941406250003],
              [143.39755859375003, -12.736132812500003],
              [143.45771484375, -12.855761718750003],
              [143.51201171875005, -13.09453125],
              [143.5294921875, -13.303808593750006],
              [143.58662109375, -13.443652343750003],
              [143.54843750000003, -13.741015625],
              [143.58925781250002, -13.86279296875],
              [143.64335937500005, -13.963671875],
              [143.7072265625, -14.16455078125],
              [143.75634765625, -14.348828125000011],
              [143.82236328125003, -14.401074218750011],
              [143.96181640625002, -14.462890625],
              [144.10585937500002, -14.39453125],
              [144.20986328125002, -14.301953125000011],
              [144.32167968750002, -14.279394531250006],
              [144.47304687500002, -14.231835937500009],
              [144.58642578125, -14.354687500000011],
              [144.64804687500003, -14.492480468750003],
              [144.91572265625, -14.67431640625],
              [145.064453125, -14.791015625],
              [145.17998046875005, -14.85693359375],
              [145.28769531250003, -14.943164062500003],
              [145.27695312500003, -15.029394531250006],
              [145.25166015625, -15.097460937500003],
              [145.27617187500005, -15.20390625],
              [145.29306640625003, -15.327246093750006],
              [145.27158203125003, -15.476660156250006],
              [145.34951171875002, -15.7015625],
              [145.37539062500002, -15.881054687500011],
              [145.4580078125, -16.056445312500003],
              [145.45185546875, -16.236914062500006],
              [145.43642578125002, -16.304980468750003],
              [145.42607421875005, -16.40615234375001],
              [145.4904296875, -16.53212890625001],
              [145.54990234375003, -16.625097656250006],
              [145.63828125000003, -16.72607421875],
              [145.75478515625002, -16.879492187500006],
              [145.837890625, -16.91035156250001],
              [145.912109375, -16.9125],
              [145.90195312500003, -17.07021484375001],
              [146.0498046875, -17.38105468750001],
              [146.12587890625002, -17.63525390625],
              [146.07402343750005, -17.97734375],
              [146.02285156250002, -18.17578125],
              [146.0322265625, -18.27285156250001],
              [146.22304687500002, -18.509863281250006],
              [146.333203125, -18.5537109375],
              [146.31171875, -18.66669921875001],
              [146.296875, -18.84121093750001],
              [146.38339843750003, -18.97705078125],
              [146.48115234375, -19.078710937500006],
              [146.58730468750002, -19.139453125],
              [146.69199218750003, -19.18740234375001],
              [146.82900390625002, -19.23574218750001],
              [147.00263671875, -19.25605468750001],
              [147.0927734375, -19.332617187500006],
              [147.13876953125003, -19.393164062500006],
              [147.278125, -19.414160156250006],
              [147.34150390625, -19.402929687500006],
              [147.41855468750003, -19.378125],
              [147.4708984375, -19.41933593750001],
              [147.509765625, -19.47412109375],
              [147.58603515625003, -19.62275390625001],
              [147.7423828125, -19.770117187500006],
              [147.85322265625, -19.794726562500003],
              [147.915625, -19.869238281250006],
              [148.00449218750003, -19.88955078125001],
              [148.0810546875, -19.89863281250001],
              [148.1896484375, -19.955859375],
              [148.36689453125, -20.0875],
              [148.52675781250002, -20.10888671875],
              [148.60048828125002, -20.14521484375001],
              [148.759375, -20.28955078125],
              [148.82099609375, -20.36640625000001],
              [148.884765625, -20.480859375],
              [148.80507812500002, -20.49169921875],
              [148.72998046875, -20.4677734375],
              [148.68369140625003, -20.580175781250006],
              [148.78945312500002, -20.735644531250003],
              [148.91240234375005, -20.84521484375],
              [149.060546875, -20.96113281250001],
              [149.20488281250005, -21.125097656250006],
              [149.24140625, -21.25019531250001],
              [149.2802734375, -21.29951171875001],
              [149.329296875, -21.47607421875],
              [149.4541015625, -21.578710937500006],
              [149.46005859375003, -21.765429687500003],
              [149.52402343750003, -22.02363281250001],
              [149.595703125, -22.257617187500003],
              [149.64531250000005, -22.32832031250001],
              [149.70390625000005, -22.440527343750006],
              [149.77158203125003, -22.42626953125],
              [149.8224609375, -22.38984375000001],
              [149.92031250000002, -22.50136718750001],
              [149.97441406250005, -22.55068359375001],
              [150.00556640625, -22.521582031250006],
              [149.94189453125, -22.30810546875],
              [149.98125, -22.18427734375001],
              [150.02060546875003, -22.168359375],
              [150.076171875, -22.164453125],
              [150.14296875000002, -22.265429687500003],
              [150.23486328125, -22.37294921875001],
              [150.40507812500005, -22.46894531250001],
              [150.54130859375005, -22.55908203125],
              [150.57958984375, -22.555761718750006],
              [150.56435546875002, -22.486132812500003],
              [150.5685546875, -22.38398437500001],
              [150.62285156250005, -22.367285156250006],
              [150.67246093750003, -22.41816406250001],
              [150.7638671875, -22.576171875],
              [150.78281250000003, -22.902929687500006],
              [150.78300781250005, -23.1765625],
              [150.84316406250002, -23.4580078125],
              [150.93105468750002, -23.53193359375001],
              [150.98876953125, -23.60175781250001],
              [151.08769531250005, -23.69609375],
              [151.15380859375, -23.78408203125001],
              [151.236328125, -23.825],
              [151.50078125000005, -24.01240234375001],
              [151.575390625, -24.03359375],
              [151.69091796875, -24.038378906250003],
              [151.83164062500003, -24.12294921875001],
              [151.90273437500002, -24.200976562500003],
              [152.05537109375, -24.494433593750003],
              [152.1298828125, -24.59755859375001],
              [152.28203125000005, -24.699316406250006],
              [152.353125, -24.732519531250006],
              [152.45634765625005, -24.80244140625001],
              [152.4931640625, -24.90400390625001],
              [152.50205078125003, -24.963964843750006],
              [152.56328125000005, -25.072070312500003],
              [152.654296875, -25.201953125],
              [152.78916015625003, -25.27412109375001],
              [152.91347656250002, -25.43212890625],
              [152.92050781250003, -25.688574218750006],
              [152.98496093750003, -25.816210937500003],
              [153.02822265625002, -25.87031250000001],
              [153.12548828125, -25.92265625],
              [153.16494140625002, -25.964160156250003],
              [153.0841796875, -26.303808593750006],
              [153.162109375, -26.982714843750003],
              [153.11679687500003, -27.194433593750006],
              [153.19794921875, -27.4046875],
              [153.3857421875, -27.7685546875],
              [153.42841796875, -27.89765625000001],
              [153.45488281250005, -28.04833984375],
              [153.57568359375, -28.240527343750003],
              [153.56914062500005, -28.53339843750001],
              [153.61689453125, -28.67304687500001],
              [153.60458984375003, -28.8544921875],
              [153.46220703125005, -29.05019531250001],
              [153.34804687500002, -29.29042968750001],
              [153.34697265625005, -29.49658203125],
              [153.27236328125002, -29.89248046875001],
              [153.223828125, -29.998632812500006],
              [153.18818359375, -30.163867187500003],
              [153.03056640625005, -30.56337890625001],
              [153.02373046875005, -30.72011718750001],
              [153.0478515625, -30.90712890625001],
              [153.02158203125003, -31.08662109375001],
              [152.98222656250005, -31.208789062500003],
              [152.94394531250003, -31.434863281250003],
              [152.78583984375, -31.78632812500001],
              [152.55927734375, -32.045703125],
              [152.54531250000002, -32.24306640625001],
              [152.5166015625, -32.330175781250006],
              [152.47041015625, -32.4390625],
              [152.33125, -32.55751953125001],
              [152.24746093750002, -32.60869140625],
              [152.21572265625002, -32.678125],
              [152.13652343750005, -32.678125],
              [152.13457031250005, -32.69990234375001],
              [152.18808593750003, -32.7216796875],
              [152.1642578125, -32.757421875],
              [151.954296875, -32.8203125],
              [151.81289062500002, -32.90107421875001],
              [151.66835937500002, -33.0986328125],
              [151.60771484375005, -33.20185546875001],
              [151.53007812500005, -33.30097656250001],
              [151.48378906250002, -33.3474609375],
              [151.46337890625, -33.39736328125001],
              [151.43203125000002, -33.521582031250006],
              [151.35751953125003, -33.5439453125],
              [151.29208984375003, -33.58095703125001],
              [151.32275390625, -33.699316406250006],
              [151.28837890625005, -33.83486328125001],
              [151.2802734375, -33.92666015625001],
              [151.24462890625, -33.98505859375001],
              [151.20166015625, -33.96406250000001],
              [151.16787109375002, -33.9734375],
              [151.12480468750005, -34.00527343750001],
              [151.19121093750005, -34.015234375],
              [151.23154296875003, -34.0296875],
              [151.08994140625003, -34.1625],
              [150.96035156250002, -34.29707031250001],
              [150.92744140625, -34.38662109375001],
              [150.8712890625, -34.499121093750006],
              [150.821875, -34.74921875000001],
              [150.78105468750005, -34.8921875],
              [150.80917968750003, -34.99384765625001],
              [150.80458984375002, -35.01289062500001],
              [150.774609375, -35.02041015625001],
              [150.7560546875, -35.00712890625],
              [150.69736328125003, -35.04189453125001],
              [150.68095703125005, -35.07666015625],
              [150.70566406250003, -35.119726562500006],
              [150.72216796875, -35.1345703125],
              [150.71464843750005, -35.15517578125001],
              [150.69033203125002, -35.177734375],
              [150.63447265625, -35.17763671875001],
              [150.56748046875003, -35.21425781250001],
              [150.37412109375003, -35.58417968750001],
              [150.2921875, -35.68232421875001],
              [150.1953125, -35.83359375],
              [150.15849609375005, -35.97060546875001],
              [150.12890625, -36.12041015625],
              [150.09531250000003, -36.37265625],
              [150.06279296875005, -36.550390625],
              [149.98818359375002, -36.722753906250006],
              [149.96025390625005, -36.8455078125],
              [149.95058593750002, -37.08027343750001],
              [149.986328125, -37.258398437500006],
              [149.962890625, -37.35302734375],
              [149.96230468750002, -37.44384765625],
              [149.93271484375003, -37.52851562500001],
              [149.809375, -37.5478515625],
              [149.708984375, -37.6169921875],
              [149.5654296875, -37.72998046875],
              [149.48085937500002, -37.77119140625001],
              [149.29843750000003, -37.80214843750001],
              [148.94394531250003, -37.78847656250001],
              [148.2625, -37.830664062500006],
              [148.13066406250005, -37.856054687500006],
              [147.87675781250005, -37.934179687500006],
              [147.63144531250003, -38.0556640625],
              [147.39560546875003, -38.219140625],
              [146.85683593750002, -38.663476562499994],
              [146.4357421875, -38.711816406249994],
              [146.35625, -38.711816406249994],
              [146.29257812500003, -38.699804687500006],
              [146.21748046875, -38.727441406249994],
              [146.21621093750002, -38.78271484375],
              [146.28554687500002, -38.840234375],
              [146.33662109375, -38.89423828125001],
              [146.426953125, -38.81962890625002],
              [146.46660156250005, -38.84033203125],
              [146.481640625, -38.977929687499994],
              [146.48378906250002, -39.065039062500006],
              [146.45664062500003, -39.1123046875],
              [146.4, -39.1455078125],
              [146.3400390625, -39.12382812500002],
              [146.33203125, -39.07666015625],
              [146.25429687500002, -38.964453125],
              [146.1583984375, -38.86572265625],
              [146.06992187500003, -38.834082031250006],
              [146.01816406250003, -38.867089843749994],
              [145.93535156250005, -38.901757812499994],
              [145.86552734375005, -38.775976562500006],
              [145.79082031250005, -38.6669921875],
              [145.69189453125, -38.655664062499994],
              [145.60634765625002, -38.656835937500006],
              [145.5353515625, -38.60966796875002],
              [145.39726562500005, -38.53535156250001],
              [145.42421875000002, -38.47734375000002],
              [145.46279296875002, -38.41630859375002],
              [145.5421875, -38.39384765625002],
              [145.51835937500005, -38.311425781249994],
              [145.47578125, -38.24375],
              [145.36640625, -38.225683593750006],
              [145.29277343750005, -38.23759765625002],
              [145.24892578125002, -38.29121093750001],
              [145.19121093750005, -38.38359375000002],
              [144.95957031250003, -38.50078125000002],
              [144.84726562500003, -38.43632812500002],
              [144.7177734375, -38.34033203125],
              [144.7802734375, -38.34736328125001],
              [144.91142578125005, -38.34404296875002],
              [145.02011718750003, -38.258398437500006],
              [145.06699218750003, -38.20488281250002],
              [145.11992187500005, -38.09130859375],
              [145.04960937500005, -38.01093750000001],
              [144.98486328125, -37.952246093750006],
              [144.89130859375, -37.89980468750001],
              [144.53847656250002, -38.0771484375],
              [144.46533203125, -38.1025390625],
              [144.3955078125, -38.13691406250001],
              [144.5177734375, -38.16640625],
              [144.58945312500003, -38.157617187499994],
              [144.665234375, -38.2099609375],
              [144.54365234375, -38.284082031249994],
              [144.44785156250003, -38.3037109375],
              [144.32871093750003, -38.348242187500006],
              [144.1015625, -38.462304687499994],
              [143.81171875, -38.698828125],
              [143.68671875, -38.76689453125002],
              [143.53896484375002, -38.820898437500006],
              [143.33847656250003, -38.7578125],
              [143.22646484375002, -38.7431640625],
              [143.08261718750003, -38.645898437499994],
              [142.84023437500002, -38.58085937500002],
              [142.61210937500005, -38.45166015625],
              [142.45585937500005, -38.386328125],
              [142.34453125000005, -38.372167968750006],
              [142.1876953125, -38.3994140625],
              [141.92470703125002, -38.283789062500006],
              [141.725, -38.271386718749994],
              [141.5939453125, -38.387792968750006],
              [141.49179687500003, -38.379785156249994],
              [141.42421875000002, -38.36347656250001],
              [141.2138671875, -38.171972656250006],
              [141.0109375, -38.07695312500002],
              [140.62724609375005, -38.028417968750006],
              [140.39042968750005, -37.89667968750001],
              [140.212109375, -37.6421875],
              [139.87480468750005, -37.35205078125],
              [139.78427734375003, -37.24580078125001],
              [139.74228515625003, -37.141699218750006],
              [139.7384765625, -37.0595703125],
              [139.78388671875, -36.90263671875],
              [139.84658203125002, -36.748046875],
              [139.85732421875002, -36.662109375],
              [139.72900390625, -36.37138671875],
              [139.54873046875002, -36.0966796875],
              [139.46591796875003, -36.010351562500006],
              [139.24492187500005, -35.82734375000001],
              [139.03769531250003, -35.6892578125],
              [138.98505859375, -35.617578125],
              [138.9689453125, -35.58076171875001],
              [139.06689453125, -35.5984375],
              [139.1125, -35.54228515625],
              [139.17802734375005, -35.523046875],
              [139.23056640625003, -35.59765625],
              [139.28945312500002, -35.611328125],
              [139.29208984375003, -35.4859375],
              [139.32509765625002, -35.42666015625001],
              [139.30253906250005, -35.3994140625],
              [139.28251953125005, -35.375390625],
              [139.19277343750002, -35.347265625],
              [139.09375, -35.38955078125001],
              [139.01767578125003, -35.44326171875001],
              [138.915234375, -35.488867187500006],
              [138.87529296875005, -35.53681640625001],
              [138.77099609375, -35.53837890625],
              [138.7296875, -35.55078125],
              [138.521875, -35.6423828125],
              [138.38925781250003, -35.64472656250001],
              [138.184375, -35.61269531250001],
              [138.2521484375, -35.48652343750001],
              [138.33291015625002, -35.41171875],
              [138.39980468750002, -35.32578125],
              [138.51113281250002, -35.0244140625],
              [138.48994140625, -34.76357421875001],
              [138.43623046875, -34.65625],
              [138.26435546875, -34.44033203125001],
              [138.18623046875, -34.307226562500006],
              [138.08925781250002, -34.16982421875001],
              [138.04130859375005, -34.2498046875],
              [138.01230468750003, -34.334082031250006],
              [137.91923828125005, -34.4560546875],
              [137.87412109375003, -34.72744140625001],
              [137.69169921875005, -35.14296875],
              [137.56640625, -35.148046875],
              [137.45957031250003, -35.13134765625],
              [137.27236328125002, -35.1787109375],
              [137.14443359375002, -35.236425781250006],
              [137.02988281250003, -35.23652343750001],
              [136.96660156250005, -35.2548828125],
              [136.88359375000005, -35.23974609375],
              [137.01425781250003, -34.9158203125],
              [137.12841796875, -34.92470703125001],
              [137.25205078125003, -34.91152343750001],
              [137.30839843750005, -34.9169921875],
              [137.39101562500002, -34.91328125000001],
              [137.454296875, -34.764453125],
              [137.49296875000005, -34.597753906250006],
              [137.46855468750005, -34.490234375],
              [137.458984375, -34.37890625],
              [137.48359375, -34.25214843750001],
              [137.49384765625, -34.1611328125],
              [137.650390625, -33.85908203125001],
              [137.78085937500003, -33.703125],
              [137.9318359375, -33.5791015625],
              [137.91396484375002, -33.461328125],
              [137.86601562500005, -33.3140625],
              [137.85234375000005, -33.20078125],
              [137.92431640625, -33.165136718750006],
              [137.99257812500002, -33.09423828125],
              [137.91318359375003, -32.77070312500001],
              [137.86308593750005, -32.67373046875001],
              [137.783203125, -32.578125],
              [137.78183593750003, -32.701953125],
              [137.79091796875002, -32.8232421875],
              [137.68017578125, -32.97802734375],
              [137.53623046875003, -33.08916015625],
              [137.44228515625002, -33.19355468750001],
              [137.35419921875, -33.43017578125],
              [137.2373046875, -33.629492187500006],
              [137.13027343750002, -33.70302734375001],
              [137.03447265625005, -33.71953125],
              [136.9365234375, -33.75019531250001],
              [136.78349609375005, -33.8296875],
              [136.63554687500005, -33.896582031250006],
              [136.52587890625, -33.9841796875],
              [136.4306640625, -34.02998046875001],
              [136.12109375, -34.4287109375],
              [135.9796875, -34.56191406250001],
              [135.95058593750002, -34.61572265625],
              [135.89101562500002, -34.6609375],
              [135.90263671875005, -34.72382812500001],
              [135.95058593750002, -34.76679687500001],
              [135.99853515625, -34.94375],
              [135.9697265625, -34.98183593750001],
              [135.919140625, -34.9619140625],
              [135.79238281250002, -34.86328125],
              [135.7125, -34.89921875],
              [135.64755859375003, -34.93964843750001],
              [135.48085937500002, -34.758203125],
              [135.41171875000003, -34.71552734375001],
              [135.32421875, -34.642675781250006],
              [135.2306640625, -34.57978515625001],
              [135.19082031250002, -34.57265625],
              [135.123046875, -34.5857421875],
              [135.12958984375, -34.53652343750001],
              [135.1759765625, -34.49658203125],
              [135.216796875, -34.4873046875],
              [135.29248046875, -34.54560546875001],
              [135.37871093750005, -34.59765625],
              [135.42734375000003, -34.601953125],
              [135.45, -34.5810546875],
              [135.36796875000005, -34.375585937500006],
              [135.31201171875, -34.19550781250001],
              [135.286328125, -34.14228515625001],
              [135.2189453125, -33.959765625],
              [135.18544921875002, -33.90673828125],
              [135.04208984375003, -33.777734375],
              [134.88876953125003, -33.62636718750001],
              [134.8466796875, -33.44462890625],
              [134.791015625, -33.32832031250001],
              [134.71904296875005, -33.25517578125],
              [134.60771484375005, -33.19013671875001],
              [134.30126953125, -33.1650390625],
              [134.17353515625, -32.979101562500006],
              [134.10039062500005, -32.748632812500006],
              [134.1583984375, -32.7333984375],
              [134.22714843750003, -32.730566406250006],
              [134.24921875, -32.65869140625],
              [134.23417968750005, -32.54853515625001],
              [133.93017578125, -32.41171875],
              [133.78671875000003, -32.26884765625],
              [133.66533203125005, -32.20722656250001],
              [133.55136718750003, -32.18291015625],
              [133.4005859375, -32.1884765625],
              [133.212109375, -32.18378906250001],
              [132.75742187500003, -31.95625],
              [132.6486328125, -31.949316406250006],
              [132.32363281250002, -32.02001953125],
              [132.21464843750005, -32.00712890625],
              [131.72119140625, -31.6962890625],
              [131.39316406250003, -31.54853515625001],
              [131.28496093750005, -31.52099609375],
              [131.14365234375003, -31.495703125],
              [131.029296875, -31.531835937500006],
              [130.94814453125002, -31.56582031250001],
              [130.78300781250005, -31.60400390625],
              [130.12978515625002, -31.5791015625],
              [129.56884765625, -31.627246093750003],
              [129.1876953125, -31.659960937500003],
              [128.94619140625002, -31.70263671875],
              [128.54609375, -31.8876953125],
              [128.06767578125005, -32.066503906250006],
              [127.67802734375005, -32.15126953125001],
              [127.31982421875, -32.2640625],
              [127.08408203125003, -32.296875],
              [126.779296875, -32.3109375],
              [126.13652343749999, -32.2568359375],
              [125.91718750000001, -32.296972656250006],
              [125.56748046875003, -32.505859375],
              [125.46367187499999, -32.55654296875001],
              [125.2666015625, -32.61445312500001],
              [124.7587890625, -32.88271484375001],
              [124.52460937500001, -32.94013671875001],
              [124.37324218750001, -32.95839843750001],
              [124.24375, -33.015234375],
              [124.12607421875003, -33.12939453125],
              [123.96718750000002, -33.4462890625],
              [123.86835937500001, -33.59638671875001],
              [123.650390625, -33.836328125],
              [123.5068359375, -33.91621093750001],
              [123.36542968750001, -33.905371093750006],
              [123.20761718750003, -33.98828125],
              [123.06757812500001, -33.90058593750001],
              [122.95566406250003, -33.8837890625],
              [122.77753906250001, -33.89082031250001],
              [122.15097656250003, -33.991796875],
              [122.06113281250003, -33.87441406250001],
              [121.94638671875003, -33.85673828125],
              [121.72968750000001, -33.8625],
              [121.40507812499999, -33.826757812500006],
              [120.81455078125003, -33.87128906250001],
              [120.53056640624999, -33.9197265625],
              [120.41835937500002, -33.96308593750001],
              [120.209375, -33.93544921875001],
              [119.85410156250003, -33.974707031250006],
              [119.72910156250003, -34.04150390625],
              [119.63515625000002, -34.101171875],
              [119.45058593750002, -34.368261718750006],
              [119.24765625000003, -34.45644531250001],
              [119.08134765624999, -34.459375],
              [118.89531249999999, -34.47988281250001],
              [118.52011718750003, -34.737109375],
              [118.13554687499999, -34.98662109375],
              [118.00644531250003, -35.01328125],
              [117.86308593749999, -35.05498046875],
              [117.67539062500003, -35.07490234375001],
              [117.58193359375002, -35.097753906250006],
              [117.14394531250002, -35.03369140625],
              [116.86542968750001, -35.02656250000001],
              [116.51718750000003, -34.987890625],
              [116.21708984374999, -34.865820312500006],
              [115.98671875000002, -34.795019531250006],
              [115.72626953125001, -34.52607421875001],
              [115.56503906250003, -34.42578125],
              [115.27763671874999, -34.30390625000001],
              [115.19482421875, -34.30849609375001],
              [115.1279296875, -34.341796875],
              [115.0087890625, -34.255859375],
              [115.00566406249999, -34.145117187500006],
              [114.97343749999999, -34.051171875],
              [114.97568359375003, -33.80419921875],
              [114.99384765625001, -33.51533203125001],
              [115.09892578124999, -33.58027343750001],
              [115.181640625, -33.64345703125001],
              [115.35878906250002, -33.63994140625],
              [115.51533203125001, -33.531347656250006],
              [115.6044921875, -33.37226562500001],
              [115.68300781250002, -33.19287109375],
              [115.6708984375, -33.00214843750001],
              [115.61855468750002, -32.6669921875],
              [115.654296875, -32.59658203125001],
              [115.70791015625002, -32.56796875],
              [115.72539062499999, -32.40107421875001],
              [115.73808593749999, -31.88789062500001],
              [115.69843750000001, -31.69453125000001],
              [115.45458984375, -31.302539062500003],
              [115.29433593750002, -30.96181640625001],
              [115.17685546875003, -30.80800781250001],
              [115.07792968749999, -30.56044921875001],
              [114.99453125000002, -30.21621093750001],
              [114.96884765625003, -30.042285156250003],
              [114.94208984375001, -29.72158203125001],
              [114.97138671875001, -29.53974609375001],
              [114.958984375, -29.43359375],
              [114.85683593750002, -29.14296875],
              [114.62841796875, -28.87177734375001],
              [114.590625, -28.77167968750001],
              [114.591796875, -28.66621093750001],
              [114.53740234374999, -28.54287109375001],
              [114.353515625, -28.294921875],
              [114.16513671875003, -28.080664062500006],
              [114.13349609375001, -27.97646484375001],
              [114.09843749999999, -27.544238281250003],
              [114.028125, -27.347265625],
              [113.709375, -26.847753906250006],
              [113.3330078125, -26.41738281250001],
              [113.23105468750003, -26.24140625000001],
              [113.18476562500001, -26.182226562500006],
              [113.21074218749999, -26.17421875],
              [113.253125, -26.197265625],
              [113.30009765624999, -26.240234375],
              [113.3232421875, -26.24384765625001],
              [113.34531250000003, -26.208300781250003],
              [113.34287109375003, -26.126074218750006],
              [113.35605468750003, -26.08046875],
              [113.38896484374999, -26.105566406250006],
              [113.42744140625001, -26.198046875],
              [113.54658203125001, -26.43671875000001],
              [113.58164062500003, -26.55810546875],
              [113.73369140624999, -26.59511718750001],
              [113.78037109375003, -26.56328125],
              [113.83642578125, -26.500585937500006],
              [113.85283203124999, -26.332128906250006],
              [113.77578125000002, -26.255957031250006],
              [113.70644531250002, -26.2236328125],
              [113.58906250000001, -26.0986328125],
              [113.51337890625001, -25.89833984375001],
              [113.39531249999999, -25.71328125],
              [113.39736328125002, -25.64716796875001],
              [113.45136718750001, -25.59912109375],
              [113.53945312500002, -25.62519531250001],
              [113.62119140625003, -25.73164062500001],
              [113.71308593750001, -25.83076171875001],
              [113.69785156250003, -26.004199218750003],
              [113.68359375, -26.05166015625001],
              [113.69169921874999, -26.09169921875001],
              [113.72373046875003, -26.12978515625001],
              [113.76582031250001, -26.159765625],
              [113.81181640624999, -26.115820312500006],
              [113.85390625000002, -26.014453125],
              [113.8798828125, -26.027636718750003],
              [113.9423828125, -26.25869140625001],
              [113.99199218749999, -26.32148437500001],
              [114.09033203125, -26.393652343750006],
              [114.17597656250001, -26.3375],
              [114.21572265625002, -26.289453125],
              [114.20332031250001, -26.12636718750001],
              [114.228515625, -25.96875],
              [114.21425781250002, -25.8515625],
              [113.99277343750003, -25.54482421875001],
              [113.79238281250002, -25.16572265625001],
              [113.67080078125002, -24.97705078125],
              [113.56923828125002, -24.69296875],
              [113.50351562500003, -24.59462890625001],
              [113.41767578125001, -24.435644531250006],
              [113.41298828125002, -24.254003906250006],
              [113.42128906250002, -24.13232421875],
              [113.48984375000003, -23.86962890625],
              [113.55292968750001, -23.7328125],
              [113.75703125000001, -23.41816406250001],
              [113.76699218750002, -23.282519531250003],
              [113.76484375000001, -23.18046875],
              [113.794921875, -23.02363281250001],
              [113.79511718750001, -22.91455078125],
              [113.76787109374999, -22.812890625],
              [113.68281250000001, -22.637792968750006],
              [113.79501953125003, -22.332128906250006],
              [113.95839843750002, -21.93916015625001],
              [114.02285156250002, -21.881445312500006],
              [114.12392578125002, -21.82861328125],
              [114.142578125, -21.909765625],
              [114.0927734375, -22.18134765625001],
              [114.16386718749999, -22.323339843750006],
              [114.1416015625, -22.48310546875001],
              [114.20517578125003, -22.455859375],
              [114.30351562499999, -22.425390625],
              [114.37773437499999, -22.34150390625001],
              [114.4169921875, -22.261035156250003],
              [114.60283203124999, -21.9421875],
              [114.70927734374999, -21.82343750000001],
              [114.85908203125001, -21.7359375],
              [115.16171875000003, -21.63056640625001],
              [115.45615234375003, -21.49169921875],
              [115.59609375000002, -21.35810546875001],
              [115.771484375, -21.242285156250006],
              [115.8935546875, -21.11669921875],
              [116.01093750000001, -21.030371093750006],
              [116.60585937500002, -20.71337890625],
              [116.70673828125001, -20.65380859375],
              [116.83632812500002, -20.647070312500006],
              [116.99531250000001, -20.65761718750001],
              [117.13906250000002, -20.640917968750003],
              [117.29277343749999, -20.71308593750001],
              [117.40625, -20.72119140625],
              [117.68388671874999, -20.64277343750001],
              [117.83232421874999, -20.572558593750003],
              [118.08730468750002, -20.419042968750006],
              [118.19921875, -20.37519531250001],
              [118.45830078124999, -20.32666015625],
              [118.75146484375, -20.26191406250001],
              [119.1044921875, -19.99531250000001],
              [119.35878906250002, -20.012304687500006],
              [119.5859375, -20.03828125000001],
              [119.76777343750001, -19.95839843750001],
              [120.1962890625, -19.909472656250003],
              [120.43369140625003, -19.84199218750001],
              [120.87841796875, -19.6650390625],
              [120.99794921875002, -19.60439453125001],
              [121.17978515625003, -19.47792968750001],
              [121.33769531249999, -19.319921875],
              [121.49355468750002, -19.1064453125],
              [121.58945312500003, -18.915136718750006],
              [121.63066406249999, -18.81660156250001],
              [121.72197265624999, -18.659960937500003],
              [121.78486328125001, -18.5359375],
              [121.83378906249999, -18.47705078125],
              [122.00625, -18.393652343750006],
              [122.26210937500002, -18.15908203125001],
              [122.34541015625001, -18.111914062500006],
              [122.36093750000003, -18.036914062500003],
              [122.30576171875003, -17.994921875],
              [122.23740234375003, -17.968554687500003],
              [122.19130859375002, -17.7203125],
              [122.1474609375, -17.54902343750001],
              [122.14316406250003, -17.42841796875001],
              [122.16025390625003, -17.31367187500001],
              [122.26093750000001, -17.1357421875],
              [122.33271484375001, -17.059375],
              [122.43203125000002, -16.97041015625001],
              [122.52255859375003, -16.94287109375],
              [122.59794921874999, -16.86494140625001],
              [122.72041015625001, -16.787695312500006],
              [122.77207031250003, -16.71015625000001],
              [122.84804687500002, -16.55244140625001],
              [122.91679687499999, -16.4326171875],
              [122.970703125, -16.436816406250003],
              [123.07441406250001, -16.71533203125],
              [123.14208984375, -16.863085937500003],
              [123.26591796874999, -17.03681640625001],
              [123.38320312500002, -17.292773437500003],
              [123.47880859374999, -17.409960937500003],
              [123.52519531249999, -17.48574218750001],
              [123.56308593750003, -17.52089843750001],
              [123.57148437500001, -17.472265625],
              [123.56181640624999, -17.41542968750001],
              [123.60791015625, -17.21992187500001],
              [123.58632812500002, -17.08271484375001],
              [123.59355468749999, -17.030371093750006],
              [123.61767578125, -17.00830078125],
              [123.6640625, -17.023242187500003],
              [123.75380859375002, -17.09980468750001],
              [123.79902343750001, -17.12714843750001],
              [123.8310546875, -17.12080078125001],
              [123.82949218750002, -16.996875],
              [123.87441406250002, -16.91865234375001],
              [123.85634765625002, -16.86474609375],
              [123.778125, -16.867773437500006],
              [123.74501953125002, -16.80097656250001],
              [123.68046874999999, -16.7236328125],
              [123.60712890625001, -16.668066406250006],
              [123.51796875000002, -16.54072265625001],
              [123.49042968750001, -16.49072265625],
              [123.52509765625001, -16.467578125],
              [123.58134765624999, -16.47089843750001],
              [123.6259765625, -16.416308593750003],
              [123.646484375, -16.343066406250003],
              [123.60703125000003, -16.22402343750001],
              [123.6474609375, -16.17988281250001],
              [123.72890625000002, -16.192480468750006],
              [123.85917968749999, -16.38232421875],
              [123.91523437500001, -16.363574218750003],
              [123.96132812500002, -16.286914062500003],
              [124.04443359375, -16.264941406250003],
              [124.12978515625002, -16.27880859375],
              [124.18603515625, -16.33359375],
              [124.30039062500003, -16.38828125],
              [124.45273437500003, -16.38203125000001],
              [124.52998046875001, -16.39521484375001],
              [124.6923828125, -16.38613281250001],
              [124.77197265625, -16.402636718750003],
              [124.75703125000001, -16.373339843750003],
              [124.66923828124999, -16.33876953125001],
              [124.5703125, -16.331835937500003],
              [124.45449218750002, -16.335253906250003],
              [124.40488281250003, -16.298925781250006],
              [124.38828125000003, -16.20302734375001],
              [124.41640625000002, -16.13349609375001],
              [124.4345703125, -16.103808593750003],
              [124.50996093750001, -16.116308593750006],
              [124.57685546875001, -16.113671875],
              [124.58505859375003, -16.020117187500006],
              [124.60859375000001, -15.9375],
              [124.64853515625003, -15.870214843750006],
              [124.64833984375002, -15.80546875],
              [124.60664062500001, -15.82265625],
              [124.50429687500002, -15.972460937500003],
              [124.45527343750001, -15.8505859375],
              [124.38164062499999, -15.758203125],
              [124.39658203125003, -15.625878906250009],
              [124.43955078125003, -15.493554687500009],
              [124.50566406249999, -15.475390625],
              [124.56162109375003, -15.496289062500011],
              [124.64433593749999, -15.418847656250009],
              [124.69091796875, -15.359667968750003],
              [124.68017578125, -15.31103515625],
              [124.69257812500001, -15.273632812500011],
              [124.75048828125, -15.285253906250006],
              [124.97207031250002, -15.404296875],
              [125.01640624999999, -15.466503906250011],
              [125.06298828125, -15.442285156250009],
              [125.07792968749999, -15.37451171875],
              [125.07294921875001, -15.306738281250006],
              [125.02402343750003, -15.316992187500006],
              [124.9091796875, -15.31005859375],
              [124.88271484375002, -15.27197265625],
              [124.89267578125003, -15.240527343750003],
              [124.83906250000001, -15.160742187500006],
              [124.91416015625003, -15.109960937500006],
              [124.97871093750001, -15.106640625000011],
              [125.02333984375002, -15.071875],
              [125.02402343750003, -15.0244140625],
              [125.03818359375003, -15.004101562500011],
              [125.07294921875001, -15.032324218750006],
              [125.18867187500001, -15.04541015625],
              [125.30234375000003, -15.106835937500009],
              [125.35566406250001, -15.119824218750011],
              [125.37558593750003, -15.086816406250009],
              [125.3837890625, -15.015625],
              [125.24326171875003, -14.944531250000011],
              [125.23945312500001, -14.874609375],
              [125.18037109375001, -14.794042968750006],
              [125.1787109375, -14.714746093750009],
              [125.26650390625002, -14.6484375],
              [125.28457031250002, -14.584082031250006],
              [125.33544921875, -14.557910156250003],
              [125.43593750000002, -14.556835937500011],
              [125.50371093749999, -14.502246093750003],
              [125.57978515625001, -14.483203125],
              [125.59833984375001, -14.361621093750003],
              [125.59707031250002, -14.278125],
              [125.62773437499999, -14.256640625],
              [125.70458984375, -14.29140625],
              [125.68125, -14.387988281250003],
              [125.68095703124999, -14.480175781250011],
              [125.66162109375, -14.529492187500011],
              [125.69052734375003, -14.525390625],
              [125.70839843750002, -14.5048828125],
              [125.73847656250001, -14.4443359375],
              [125.81953125000001, -14.469140625],
              [125.83955078125001, -14.533886718750011],
              [125.85009765625, -14.597265625],
              [125.890625, -14.61796875],
              [125.94609374999999, -14.520410156250009],
              [126.02070312500001, -14.49453125],
              [126.0166015625, -14.371289062500011],
              [126.04482421875002, -14.283007812500003],
              [126.05361328125002, -14.216699218750009],
              [126.10087890624999, -14.184375],
              [126.111328125, -14.1140625],
              [126.07343750000001, -14.065527343750006],
              [126.05390625000001, -13.977246093750011],
              [126.11904296875002, -13.957714843750011],
              [126.18427734375001, -14.002050781250006],
              [126.22822265625001, -14.113378906250006],
              [126.25849609375001, -14.16357421875],
              [126.298828125, -14.13623046875],
              [126.32304687499999, -14.062109375],
              [126.403125, -14.018945312500009],
              [126.482421875, -14.07890625],
              [126.56972656250002, -14.1609375],
              [126.67910156250002, -14.08935546875],
              [126.78066406250002, -13.955175781250006],
              [126.76445312499999, -13.873046875],
              [126.77558593750001, -13.788476562500009],
              [126.90322265625002, -13.744140625],
              [127.00605468750001, -13.776757812500009],
              [127.09921875000003, -13.867382812500011],
              [127.29306640625003, -13.934765625000011],
              [127.45761718750003, -14.031445312500011],
              [127.53105468750005, -14.094628906250009],
              [127.6728515625, -14.195117187500003],
              [127.76347656250005, -14.299414062500006],
              [127.88759765625002, -14.48515625],
              [128.18046875000005, -14.711621093750011],
              [128.1994140625, -14.751757812500003],
              [128.15986328125, -14.827343750000011],
              [128.12441406250002, -14.924121093750003],
              [128.08046875000002, -15.087988281250006],
              [128.06943359375003, -15.329296875000011],
              [128.11171875000002, -15.31201171875],
              [128.15546875, -15.2255859375],
              [128.20175781250003, -15.243359375000011],
              [128.25468750000005, -15.298535156250011],
              [128.258984375, -15.24560546875],
              [128.22724609375, -15.213574218750011],
              [128.17294921875003, -15.102246093750011],
              [128.175, -15.043164062500011],
              [128.21835937500003, -14.995703125],
              [128.28515625, -14.938867187500009],
              [128.35820312500005, -14.901660156250003],
              [128.40322265625002, -14.869140625],
              [128.40986328125, -14.82890625],
              [128.47744140625002, -14.787988281250009],
              [128.57578125000003, -14.774511718750006],
              [128.63554687500005, -14.780957031250011],
              [129.05820312500003, -14.884375],
              [129.16513671875003, -14.987597656250003],
              [129.17519531250002, -15.115039062500003],
              [129.2158203125, -15.160253906250006],
              [129.23789062500003, -15.080175781250006],
              [129.23359375, -14.906054687500003],
              [129.267578125, -14.871484375],
              [129.38125, -14.8984375],
              [129.458984375, -14.933203125],
              [129.56708984375, -15.04736328125],
              [129.58769531250005, -15.103320312500003],
              [129.634765625, -15.139746093750006],
              [129.65029296875002, -15.086816406250009],
              [129.62822265625005, -15.011816406250006],
              [129.61269531250002, -14.925878906250006],
              [129.63710937500002, -14.850976562500009],
              [129.76347656250005, -14.845019531250003],
              [129.84873046875003, -14.82890625],
              [129.80839843750005, -14.799707031250009],
              [129.75351562500003, -14.78955078125],
              [129.66298828125002, -14.720898437500011],
              [129.6046875, -14.647070312500006],
              [129.69863281250002, -14.575292968750006],
              [129.69794921875, -14.557421875],
              [129.60791015625, -14.559667968750006],
              [129.48388671875, -14.48974609375],
              [129.37871093750005, -14.392480468750009],
              [129.4591796875, -14.213476562500006],
              [129.61962890625, -14.038378906250003],
              [129.70986328125002, -13.97998046875],
              [129.71835937500003, -13.9208984375],
              [129.76171875, -13.811914062500009],
              [129.7892578125, -13.719921875000011],
              [129.79716796875005, -13.6484375],
              [129.8388671875, -13.572949218750011],
              [129.93789062500002, -13.501660156250011],
              [130.07265625000002, -13.476171875],
              [130.1359375, -13.448339843750006],
              [130.19931640625003, -13.382617187500003],
              [130.259765625, -13.30224609375],
              [130.1349609375, -13.1455078125],
              [130.14531250000005, -13.059179687500006],
              [130.1681640625, -12.957421875],
              [130.31796875000003, -12.882910156250006],
              [130.39990234375, -12.687890625],
              [130.45419921875003, -12.65859375],
              [130.571875, -12.664355468750003],
              [130.61748046875005, -12.646875],
              [130.6095703125, -12.491308593750006],
              [130.62265625000003, -12.431054687500009],
              [130.67236328125, -12.406933593750011],
              [130.73613281250005, -12.427734375],
              [130.7765625, -12.495312500000011],
              [130.8673828125, -12.557812500000011],
              [130.89824218750005, -12.523632812500011],
              [130.88291015625003, -12.455078125],
              [130.87382812500005, -12.3671875],
              [130.95664062500003, -12.348242187500006],
              [131.0234375, -12.342871093750006],
              [131.03007812500005, -12.27109375],
              [131.01953125, -12.2138671875],
              [131.04570312500005, -12.189648437500011],
              [131.219921875, -12.177929687500011],
              [131.26542968750005, -12.119042968750009],
              [131.29160156250003, -12.06787109375],
              [131.31376953125005, -12.095898437500011],
              [131.34208984375005, -12.210058593750006],
              [131.43828125000005, -12.276953125],
              [131.72626953125, -12.278125],
              [131.88798828125005, -12.23193359375],
              [131.95673828125, -12.25927734375],
              [132.06406250000003, -12.28076171875],
              [132.18232421875, -12.226953125],
              [132.25322265625005, -12.18603515625],
              [132.3720703125, -12.239160156250009],
              [132.41103515625002, -12.295117187500011],
              [132.4416015625, -12.176367187500006],
              [132.51054687500005, -12.134863281250006],
              [132.58378906250005, -12.110253906250009],
              [132.67636718750003, -12.130078125000011],
              [132.71279296875002, -12.1234375],
              [132.63046875000003, -12.03515625],
              [132.63525390625, -11.9546875],
              [132.6298828125, -11.835839843750009],
              [132.6447265625, -11.727148437500006],
              [132.67421875000002, -11.649023437500006],
              [132.47519531250003, -11.491503906250003],
              [132.27792968750003, -11.467675781250009],
              [132.13359375000005, -11.500683593750011],
              [132.07285156250003, -11.474707031250006],
              [131.94462890625005, -11.348535156250009],
              [131.8224609375, -11.302441406250011],
              [131.81181640625005, -11.271386718750009],
              [131.96152343750003, -11.180859375000011],
              [132.0185546875, -11.196386718750006],
              [132.10576171875005, -11.281152343750009],
              [132.15546875, -11.311132812500006],
              [132.19775390625, -11.304980468750003],
              [132.225, -11.23876953125],
              [132.2626953125, -11.204003906250009],
              [132.333984375, -11.223535156250009],
              [132.55732421875, -11.366894531250011],
              [132.6828125, -11.505566406250011],
              [132.7470703125, -11.468945312500011],
              [132.85712890625, -11.39111328125],
              [132.96103515625003, -11.407324218750006],
              [133.02490234375, -11.452832031250011],
              [133.11435546875003, -11.621777343750011],
              [133.18525390625, -11.705664062500006],
              [133.35615234375, -11.728222656250011],
              [133.44316406250005, -11.760351562500006],
              [133.533203125, -11.816210937500003],
              [133.6544921875, -11.811328125],
              [133.90419921875002, -11.83203125],
              [134.13945312500005, -11.940136718750011],
              [134.23710937500005, -12.007714843750009],
              [134.35107421875, -12.02578125],
              [134.41738281250002, -12.052734375],
              [134.5380859375, -12.060839843750003],
              [134.73027343750005, -11.984375],
              [134.81640625, -12.0546875],
              [134.8546875, -12.1025390625],
              [135.02968750000002, -12.19375],
              [135.21796875, -12.2216796875],
              [135.35234375000005, -12.129199218750003],
              [135.54873046875002, -12.060644531250006],
              [135.685546875, -11.956152343750006],
              [135.78847656250002, -11.90703125],
              [135.88525390625, -11.821679687500009],
              [135.92246093750003, -11.82578125],
              [135.84355468750005, -11.905468750000011],
              [135.833984375, -11.95068359375],
              [135.89580078125005, -11.96953125],
              [135.88945312500005, -11.992773437500006],
              [135.80429687500003, -12.054785156250006],
              [135.70253906250002, -12.151562500000011],
              [135.70439453125005, -12.209863281250009],
              [135.74394531250005, -12.24169921875],
              [135.79082031250005, -12.2275390625],
              [135.857421875, -12.178515625],
              [135.93779296875005, -12.152148437500003],
              [136.00849609375, -12.19140625],
              [136.0314453125, -12.330859375],
              [136.08183593750005, -12.422460937500006],
              [136.19267578125005, -12.43515625],
              [136.26064453125002, -12.433789062500011],
              [136.32851562500002, -12.305566406250009],
              [136.29189453125002, -12.196386718750006],
              [136.24990234375002, -12.173046875000011],
              [136.27011718750003, -12.131640625],
              [136.443359375, -11.951464843750003],
              [136.540234375, -11.957617187500006],
              [136.60976562500002, -12.13359375],
              [136.71943359375, -12.226464843750009],
              [136.83642578125, -12.219140625],
              [136.8974609375, -12.243554687500009],
              [136.9474609375, -12.349902343750003],
              [136.53701171875002, -12.784277343750006],
              [136.5177734375, -12.8328125],
              [136.57304687500005, -12.91162109375],
              [136.59433593750003, -13.003808593750009],
              [136.46103515625003, -13.225195312500006],
              [136.41191406250005, -13.236132812500003],
              [136.36455078125005, -13.176367187500006],
              [136.294140625, -13.137988281250003],
              [136.23232421875002, -13.164941406250009],
              [136.16611328125003, -13.181054687500009],
              [135.92734375000003, -13.304296875],
              [135.92919921875, -13.62158203125],
              [135.98955078125005, -13.81015625],
              [135.95449218750002, -13.934863281250003],
              [135.88339843750003, -14.153125],
              [135.80634765625, -14.234179687500003],
              [135.74453125000002, -14.28662109375],
              [135.53886718750005, -14.5849609375],
              [135.47324218750003, -14.656640625],
              [135.40517578125002, -14.758203125],
              [135.42802734375005, -14.855664062500011],
              [135.4533203125, -14.923144531250003],
              [135.53076171875, -15.000390625],
              [135.83261718750003, -15.16015625],
              [135.96953125000005, -15.270214843750011],
              [136.20537109375005, -15.403417968750006],
              [136.25927734375, -15.495214843750006],
              [136.29140625000002, -15.570117187500003],
              [136.4619140625, -15.6552734375],
              [136.58359375000003, -15.70654296875],
              [136.61875, -15.693359375],
              [136.64414062500003, -15.675585937500003],
              [136.67460937500005, -15.675390625],
              [136.70488281250005, -15.685253906250011],
              [136.70009765625002, -15.751953125],
              [136.68671875, -15.788476562500009],
              [136.69814453125002, -15.8349609375],
              [136.78466796875, -15.894238281250011],
              [136.92265625000005, -15.892382812500003],
              [137.0021484375, -15.878320312500009],
              [137.08984375, -15.941308593750009],
              [137.1689453125, -15.982128906250011],
              [137.29931640625, -16.06630859375001],
              [137.5263671875, -16.167089843750006],
              [137.70371093750003, -16.233007812500006],
              [137.91289062500005, -16.4765625],
              [138.07158203125005, -16.616992187500003],
              [138.24501953125002, -16.718359375],
              [138.50566406250005, -16.78955078125],
              [138.62568359375, -16.77783203125],
              [138.8203125, -16.860644531250003],
              [139.00986328125003, -16.89931640625001],
              [139.1103515625, -17.0140625],
              [139.14453125, -17.10107421875],
              [139.15410156250005, -17.167773437500003],
              [139.24843750000002, -17.32861328125],
              [139.44052734375003, -17.38056640625001],
              [139.6896484375, -17.54072265625001],
              [139.89453125, -17.611328125],
              [139.94599609375, -17.653613281250003],
              [140.03583984375, -17.70263671875],
              [140.20966796875, -17.704394531250003],
              [140.51113281250002, -17.62451171875],
              [140.6484375, -17.54375],
              [140.83046875000002, -17.414453125],
              [140.91582031250005, -17.19257812500001],
              [140.966015625, -17.01455078125001],
              [141.21914062500002, -16.64619140625001],
              [141.29140625000002, -16.463476562500006],
              [141.3556640625, -16.22109375],
              [141.41191406250005, -16.06953125000001],
              [141.39316406250003, -15.9046875],
              [141.45156250000002, -15.605273437500003],
              [141.58144531250002, -15.195410156250006],
              [141.62548828125, -15.056640625],
              [141.603515625, -14.852734375000011],
              [141.52294921875, -14.470117187500009],
              [141.55898437500002, -14.337890625],
              [141.59433593750003, -14.15283203125],
              [141.53544921875005, -14.018652343750006],
              [141.4806640625, -13.9267578125],
              [141.47255859375002, -13.797558593750011],
              [141.5341796875, -13.553808593750006],
              [141.58876953125002, -13.425097656250003],
              [141.64541015625002, -13.259082031250003],
              [141.61357421875005, -12.943457031250006],
              [141.7345703125, -12.83349609375],
              [141.7822265625, -12.778710937500009],
              [141.87578125000005, -12.778222656250009],
              [141.92031250000002, -12.802929687500011],
              [141.92978515625003, -12.73984375],
              [141.89287109375005, -12.681347656250011],
              [141.87832031250002, -12.61328125],
              [141.85214843750003, -12.578710937500006],
              [141.79453125000003, -12.566601562500011],
              [141.74667968750003, -12.529394531250006],
              [141.677734375, -12.491406250000011],
              [141.68857421875003, -12.35107421875],
              [141.80576171875003, -12.080078125],
              [141.87050781250002, -11.9755859375],
              [141.91298828125002, -12.019238281250011],
              [141.9611328125, -12.054296875],
              [141.9677734375, -11.976269531250011],
              [141.95156250000002, -11.896191406250011],
              [142.04052734375, -11.631738281250009],
              [142.13896484375005, -11.273242187500003],
              [142.16835937500002, -10.946582031250003],
              [142.32646484375005, -10.884179687500009],
              [142.40683593750003, -10.80224609375],
              [142.45644531250002, -10.707324218750003],
              [142.54482421875002, -10.707324218750003],
              [142.60507812500003, -10.748242187500011],
              [142.5654296875, -10.819433593750006],
              [142.552734375, -10.874414062500009],
              [142.72304687500002, -11.010449218750011],
              [142.77968750000002, -11.115332031250006],
              [142.80332031250003, -11.213964843750006],
              [142.83681640625002, -11.306933593750003],
              [142.85292968750002, -11.432226562500006],
              [142.8505859375, -11.63232421875],
              [142.87255859375, -11.821386718750006],
              [142.93398437500002, -11.880761718750009],
              [142.9884765625, -11.919042968750006],
              [143.06640625, -11.924121093750003],
              [143.17890625, -11.954492187500009],
            ],
          ],
          [
            [
              [153.07744140625005, -25.75078125],
              [153.051953125, -25.7783203125],
              [153.00693359375003, -25.72890625],
              [152.97666015625003, -25.551367187500006],
              [152.9990234375, -25.44843750000001],
              [153.05156250000005, -25.354296875],
              [153.0607421875, -25.30224609375],
              [153.0380859375, -25.193164062500003],
              [153.18925781250005, -25.07050781250001],
              [153.2275390625, -25.00576171875001],
              [153.24199218750005, -24.92255859375001],
              [153.18632812500005, -24.832617187500006],
              [153.14375, -24.81484375],
              [153.18095703125005, -24.76484375000001],
              [153.22314453125, -24.739550781250003],
              [153.25693359375003, -24.72890625],
              [153.28212890625002, -24.73828125],
              [153.29794921875003, -24.91523437500001],
              [153.35927734375002, -24.97773437500001],
              [153.35019531250003, -25.063085937500006],
              [153.14140625000005, -25.512792968750006],
              [153.08378906250005, -25.68251953125001],
              [153.07744140625005, -25.75078125],
            ],
          ],
          [
            [
              [139.5078125, -16.573046875],
              [139.43056640625002, -16.66103515625001],
              [139.39150390625002, -16.64863281250001],
              [139.35429687500005, -16.696582031250003],
              [139.28300781250005, -16.71943359375001],
              [139.23906250000005, -16.71865234375001],
              [139.15957031250002, -16.74169921875],
              [139.14765625, -16.7138671875],
              [139.16269531250003, -16.62587890625001],
              [139.2287109375, -16.52753906250001],
              [139.29296875, -16.46728515625],
              [139.45888671875002, -16.4384765625],
              [139.587890625, -16.39521484375001],
              [139.6044921875, -16.40322265625001],
              [139.69775390625, -16.514941406250003],
              [139.55966796875003, -16.52949218750001],
              [139.5078125, -16.573046875],
            ],
          ],
          [
            [
              [136.71464843750005, -13.803906250000011],
              [136.7580078125, -13.845410156250011],
              [136.80449218750005, -13.842480468750011],
              [136.84531250000003, -13.7509765625],
              [136.87070312500003, -13.763671875],
              [136.8908203125, -13.78662109375],
              [136.90556640625005, -13.826953125],
              [136.84296875, -13.896582031250006],
              [136.81494140625, -13.907324218750006],
              [136.78818359375003, -13.94580078125],
              [136.7453125, -14.07265625],
              [136.74990234375002, -14.115234375],
              [136.78701171875002, -14.1578125],
              [136.88544921875, -14.197265625],
              [136.93388671875005, -14.179003906250003],
              [136.95078125000003, -14.184277343750011],
              [136.93134765625, -14.245996093750009],
              [136.89433593750005, -14.293066406250006],
              [136.76318359375, -14.2734375],
              [136.64970703125005, -14.280468750000011],
              [136.46054687500003, -14.234570312500011],
              [136.36328125, -14.22890625],
              [136.33544921875, -14.211816406250009],
              [136.39218750000003, -14.175488281250011],
              [136.427734375, -14.12646484375],
              [136.4111328125, -14.011132812500009],
              [136.42470703125002, -13.86484375],
              [136.53378906250003, -13.79375],
              [136.58281250000005, -13.72109375],
              [136.65566406250002, -13.675878906250006],
              [136.70195312500005, -13.681640625],
              [136.69599609375, -13.726171875],
              [136.71464843750005, -13.803906250000011],
            ],
          ],
          [
            [
              [130.45927734375005, -11.679296875],
              [130.54179687500005, -11.703125],
              [130.57988281250005, -11.737109375],
              [130.602734375, -11.773242187500003],
              [130.60625, -11.816601562500011],
              [130.50253906250003, -11.835644531250011],
              [130.31748046875003, -11.771777343750003],
              [130.13125, -11.824511718750003],
              [130.07656250000002, -11.825488281250003],
              [130.04326171875005, -11.787304687500011],
              [130.07207031250005, -11.680761718750006],
              [130.13906250000002, -11.697070312500003],
              [130.19755859375005, -11.658203125],
              [130.18710937500003, -11.541210937500011],
              [130.15283203125, -11.4775390625],
              [130.251171875, -11.360546875000011],
              [130.294921875, -11.336816406250009],
              [130.33925781250002, -11.337011718750006],
              [130.37675781250005, -11.420117187500011],
              [130.38564453125002, -11.509863281250006],
              [130.4328125, -11.5921875],
              [130.45927734375005, -11.679296875],
            ],
          ],
          [
            [
              [130.61884765625, -11.376074218750006],
              [130.75224609375005, -11.384375],
              [130.91279296875, -11.309277343750011],
              [130.98740234375003, -11.33984375],
              [131.02304687500003, -11.334375],
              [131.140625, -11.263085937500009],
              [131.21718750000002, -11.242578125],
              [131.26826171875, -11.18984375],
              [131.3205078125, -11.246875],
              [131.43691406250002, -11.313183593750011],
              [131.47333984375, -11.382519531250011],
              [131.52226562500005, -11.415234375000011],
              [131.53857421875, -11.436914062500009],
              [131.46787109375003, -11.509570312500003],
              [131.45859375000003, -11.587890625],
              [131.3828125, -11.58251953125],
              [131.29208984375003, -11.7109375],
              [130.95097656250005, -11.926464843750011],
              [130.64492187500002, -11.742382812500011],
              [130.5119140625, -11.617871093750011],
              [130.42275390625002, -11.44580078125],
              [130.40478515625, -11.304980468750003],
              [130.36855468750002, -11.214941406250006],
              [130.38457031250005, -11.1921875],
              [130.40292968750003, -11.18046875],
              [130.42666015625002, -11.18310546875],
              [130.51914062500003, -11.279492187500011],
              [130.559765625, -11.305957031250003],
              [130.61884765625, -11.376074218750006],
            ],
          ],
          [
            [
              [113.18300781250002, -26.053125],
              [113.15644531250001, -26.09453125],
              [112.96425781250002, -25.78310546875001],
              [112.908203125, -25.56982421875],
              [112.94707031249999, -25.531542968750003],
              [112.982421875, -25.52021484375001],
              [113.09628906250003, -25.815039062500006],
              [113.13154296875001, -25.882617187500003],
              [113.1318359375, -25.951953125],
              [113.14833984375002, -25.97382812500001],
              [113.18300781250002, -26.053125],
            ],
          ],
          [
            [
              [137.59648437500005, -35.738671875],
              [137.8359375, -35.762109375],
              [137.92890625, -35.72607421875],
              [138.04658203125, -35.75517578125],
              [138.12343750000002, -35.85234375],
              [138.06650390625003, -35.90058593750001],
              [138.0119140625, -35.90761718750001],
              [137.83554687500003, -35.867773437500006],
              [137.6708984375, -35.89794921875],
              [137.622265625, -35.938085937500006],
              [137.59023437500002, -36.0271484375],
              [137.4484375, -36.074804687500006],
              [137.38222656250002, -36.02089843750001],
              [137.20957031250003, -35.982421875],
              [137.14775390625005, -36.0390625],
              [137.02587890625, -36.02392578125],
              [136.91269531250003, -36.0466796875],
              [136.755078125, -36.03310546875001],
              [136.58925781250002, -35.9353515625],
              [136.540625, -35.89013671875],
              [136.5791015625, -35.808691406250006],
              [136.638671875, -35.748828125],
              [137.091796875, -35.6638671875],
              [137.33408203125003, -35.59248046875001],
              [137.53046875, -35.605078125],
              [137.5849609375, -35.620214843750006],
              [137.63544921875, -35.65644531250001],
              [137.59814453125, -35.722265625],
              [137.59648437500005, -35.738671875],
            ],
          ],
          [
            [
              [145.4865234375, -38.354882812499994],
              [145.33583984375002, -38.420996093750006],
              [145.2802734375, -38.390625],
              [145.28583984375, -38.34101562500001],
              [145.29531250000002, -38.318945312500006],
              [145.42656250000005, -38.31416015625001],
              [145.4865234375, -38.354882812499994],
            ],
          ],
          [
            [
              [145.314453125, -38.490820312500006],
              [145.34921875000003, -38.538183593750006],
              [145.35507812500003, -38.55703125],
              [145.27089843750002, -38.51972656250001],
              [145.12841796875, -38.52763671875002],
              [145.2177734375, -38.45859375],
              [145.28789062500005, -38.47216796875],
              [145.314453125, -38.490820312500006],
            ],
          ],
          [
            [
              [149.04375, -20.29150390625],
              [149.01992187500002, -20.302539062500003],
              [148.98740234375003, -20.3017578125],
              [148.93886718750002, -20.28369140625],
              [148.98105468750003, -20.15351562500001],
              [149.00439453125, -20.221484375],
              [149.04531250000002, -20.27753906250001],
              [149.04375, -20.29150390625],
            ],
          ],
          [
            [
              [148.935546875, -20.14990234375],
              [148.91347656250002, -20.154296875],
              [148.8869140625, -20.1435546875],
              [148.9064453125, -20.101953125],
              [148.931640625, -20.068945312500006],
              [148.96787109375003, -20.04433593750001],
              [148.95625, -20.13466796875001],
              [148.935546875, -20.14990234375],
            ],
          ],
          [
            [
              [151.14658203125003, -23.490820312500006],
              [151.18076171875003, -23.516210937500006],
              [151.21201171875003, -23.51308593750001],
              [151.24013671875002, -23.5296875],
              [151.22880859375005, -23.59492187500001],
              [151.27431640625002, -23.66845703125],
              [151.29580078125002, -23.7203125],
              [151.26152343750005, -23.762304687500006],
              [151.23828125, -23.77578125],
              [151.18417968750003, -23.74072265625],
              [151.03330078125003, -23.53017578125001],
              [151.05996093750002, -23.460546875],
              [151.14658203125003, -23.490820312500006],
            ],
          ],
          [
            [
              [150.51669921875003, -22.322558593750003],
              [150.4884765625, -22.32470703125],
              [150.46240234375, -22.307714843750006],
              [150.48466796875005, -22.267871093750003],
              [150.4884765625, -22.210742187500003],
              [150.521484375, -22.228320312500003],
              [150.548828125, -22.306933593750003],
              [150.51669921875003, -22.322558593750003],
            ],
          ],
          [
            [
              [149.92832031250003, -22.19306640625001],
              [149.89365234375003, -22.223242187500006],
              [149.86953125000002, -22.150390625],
              [149.87539062500002, -22.074023437500003],
              [149.9123046875, -22.04873046875001],
              [149.9279296875, -22.14931640625001],
              [149.92832031250003, -22.19306640625001],
            ],
          ],
          [
            [
              [153.53876953125, -27.43642578125001],
              [153.45273437500003, -27.71171875],
              [153.42656250000005, -27.70644531250001],
              [153.39580078125005, -27.6650390625],
              [153.40087890625, -27.505664062500003],
              [153.43544921875002, -27.405371093750006],
              [153.521875, -27.422460937500006],
              [153.53876953125, -27.43642578125001],
            ],
          ],
          [
            [
              [153.44248046875003, -27.316015625],
              [153.4208984375, -27.33095703125001],
              [153.37656250000003, -27.2353515625],
              [153.36503906250005, -27.13886718750001],
              [153.3798828125, -27.049414062500006],
              [153.43232421875, -27.029882812500006],
              [153.466796875, -27.0380859375],
              [153.42636718750003, -27.201464843750003],
              [153.44248046875003, -27.316015625],
            ],
          ],
          [
            [
              [142.27480468750002, -10.704785156250011],
              [142.19140625, -10.762011718750003],
              [142.13720703125, -10.73193359375],
              [142.12548828125, -10.66845703125],
              [142.1310546875, -10.640625],
              [142.19794921875, -10.591992187500011],
              [142.27480468750002, -10.704785156250011],
            ],
          ],
          [
            [
              [142.33896484375003, -10.1921875],
              [142.27939453125003, -10.254199218750003],
              [142.21621093750002, -10.235644531250003],
              [142.19511718750005, -10.199316406250006],
              [142.21875, -10.1494140625],
              [142.29873046875002, -10.140429687500003],
              [142.33896484375003, -10.1921875],
            ],
          ],
          [
            [
              [142.16757812500003, -10.154101562500003],
              [142.14199218750002, -10.18125],
              [142.09765625, -10.121777343750011],
              [142.14882812500002, -10.0517578125],
              [142.19199218750003, -10.085253906250003],
              [142.16757812500003, -10.154101562500003],
            ],
          ],
          [
            [
              [146.2783203125, -18.23125],
              [146.298828125, -18.32607421875001],
              [146.3419921875, -18.40009765625001],
              [146.32705078125002, -18.44863281250001],
              [146.298828125, -18.484765625],
              [146.23564453125005, -18.45078125],
              [146.19130859375002, -18.362890625],
              [146.1162109375, -18.29238281250001],
              [146.098828125, -18.251757812500003],
              [146.18671875, -18.255175781250003],
              [146.23085937500002, -18.24140625000001],
              [146.24912109375003, -18.225878906250003],
              [146.2783203125, -18.23125],
            ],
          ],
          [
            [
              [136.59853515625002, -11.37890625],
              [136.5265625, -11.438867187500009],
              [136.5216796875, -11.393847656250003],
              [136.559765625, -11.35791015625],
              [136.64902343750003, -11.211621093750011],
              [136.68798828125, -11.177636718750009],
              [136.71054687500003, -11.158398437500011],
              [136.72734375000005, -11.104785156250003],
              [136.73173828125005, -11.024609375000011],
              [136.7802734375, -11.0125],
              [136.74140625, -11.194628906250003],
              [136.59853515625002, -11.37890625],
            ],
          ],
          [
            [
              [136.33867187500005, -11.60234375],
              [136.18027343750003, -11.6767578125],
              [136.26738281250005, -11.576464843750003],
              [136.44921875, -11.487109375],
              [136.47929687500005, -11.465917968750006],
              [136.47050781250005, -11.50927734375],
              [136.37939453125, -11.583203125000011],
              [136.33867187500005, -11.60234375],
            ],
          ],
          [
            [
              [137.09365234375002, -15.778125],
              [137.05087890625003, -15.824414062500011],
              [136.99648437500002, -15.77578125],
              [136.98505859375, -15.725976562500009],
              [136.94267578125005, -15.71171875],
              [136.96337890625, -15.665722656250011],
              [136.98574218750002, -15.652441406250006],
              [137.00957031250005, -15.594824218750006],
              [137.06455078125003, -15.662890625],
              [137.07109375000005, -15.738085937500003],
              [137.09365234375002, -15.778125],
            ],
          ],
          [
            [
              [136.86269531250002, -15.619921875],
              [136.84677734375003, -15.62734375],
              [136.84560546875002, -15.544042968750006],
              [136.87685546875002, -15.502539062500006],
              [136.89023437500003, -15.5888671875],
              [136.86269531250002, -15.619921875],
            ],
          ],
          [
            [
              [136.591015625, -15.628222656250003],
              [136.53115234375002, -15.632421875],
              [136.51425781250003, -15.62734375],
              [136.50273437500005, -15.583105468750006],
              [136.52255859375003, -15.543164062500011],
              [136.58603515625003, -15.53369140625],
              [136.6123046875, -15.544140625000011],
              [136.591015625, -15.628222656250003],
            ],
          ],
          [
            [
              [139.4591796875, -17.114550781250003],
              [139.42167968750005, -17.131640625],
              [139.408203125, -17.090625],
              [139.4591796875, -17.049121093750003],
              [139.49277343750003, -16.99042968750001],
              [139.56005859375, -17.0419921875],
              [139.57089843750003, -17.09443359375001],
              [139.4591796875, -17.114550781250003],
            ],
          ],
          [
            [
              [136.23740234375003, -13.824511718750003],
              [136.21367187500005, -13.8359375],
              [136.12265625000003, -13.816601562500011],
              [136.122265625, -13.780566406250003],
              [136.134375, -13.753125],
              [136.15957031250002, -13.73671875],
              [136.21542968750003, -13.664746093750011],
              [136.25742187500003, -13.706640625],
              [136.275390625, -13.791113281250006],
              [136.23740234375003, -13.824511718750003],
            ],
          ],
          [
            [
              [132.59335937500003, -11.302832031250006],
              [132.57363281250002, -11.318359375],
              [132.49375, -11.163671875],
              [132.51630859375, -11.116015625],
              [132.48378906250002, -11.037304687500011],
              [132.53779296875, -11.028417968750006],
              [132.57880859375, -10.968847656250006],
              [132.59326171875, -10.99765625],
              [132.596875, -11.1064453125],
              [132.6291015625, -11.169140625000011],
              [132.59335937500003, -11.302832031250006],
            ],
          ],
          [
            [
              [125.19882812500003, -14.579492187500009],
              [125.134765625, -14.641699218750006],
              [125.09121093750002, -14.591699218750009],
              [125.11738281250001, -14.491992187500003],
              [125.15996093749999, -14.4560546875],
              [125.19814453125002, -14.474804687500011],
              [125.19355468750001, -14.552636718750009],
              [125.19882812500003, -14.579492187500009],
            ],
          ],
          [
            [
              [124.59726562500003, -15.401953125],
              [124.5595703125, -15.43017578125],
              [124.52421874999999, -15.421484375],
              [124.52373046874999, -15.382421875],
              [124.48281250000002, -15.34033203125],
              [124.50410156250001, -15.29248046875],
              [124.51933593749999, -15.267480468750009],
              [124.55087890625003, -15.2703125],
              [124.56455078125003, -15.310839843750003],
              [124.60507812500003, -15.356542968750006],
              [124.59726562500003, -15.401953125],
            ],
          ],
          [
            [
              [115.44619140625002, -20.78779296875001],
              [115.38808593750002, -20.866015625],
              [115.31806640625001, -20.8505859375],
              [115.30859375, -20.811132812500006],
              [115.35429687499999, -20.74628906250001],
              [115.4345703125, -20.66796875],
              [115.45761718750003, -20.71630859375],
              [115.44619140625002, -20.78779296875001],
            ],
          ],
          [
            [
              [145.04296875, -40.78671875],
              [145.15869140625, -40.790625],
              [145.22431640625, -40.76513671875],
              [145.28300781250005, -40.769921875],
              [145.34941406250005, -40.82636718750001],
              [145.42939453125, -40.85820312500002],
              [145.48515625000005, -40.85234375000002],
              [145.53349609375005, -40.86396484375001],
              [145.57646484375005, -40.90410156250002],
              [145.68603515625, -40.9390625],
              [145.73378906250002, -40.962011718750006],
              [145.775390625, -40.997167968750006],
              [145.821484375, -41.02460937500001],
              [146.11113281250005, -41.118066406249994],
              [146.31748046875003, -41.163476562499994],
              [146.5744140625, -41.14238281250002],
              [146.6505859375, -41.1162109375],
              [146.72343750000005, -41.078027343749994],
              [146.78603515625002, -41.113671875],
              [146.84814453125, -41.168066406250006],
              [146.83603515625003, -41.109375],
              [146.856640625, -41.05830078125001],
              [146.91943359375, -41.01777343750001],
              [146.98984375000003, -40.99238281250001],
              [147.10576171875005, -40.994238281250006],
              [147.21884765625003, -40.9833984375],
              [147.26894531250002, -40.95976562500002],
              [147.3205078125, -40.956445312499994],
              [147.3876953125, -40.98554687500001],
              [147.45478515625, -41.00166015625001],
              [147.50078125000005, -40.96416015625002],
              [147.579296875, -40.875585937500006],
              [147.62167968750003, -40.8447265625],
              [147.81767578125005, -40.871679687500006],
              [147.87294921875002, -40.87255859375],
              [147.96875, -40.77958984375002],
              [148.03281250000003, -40.78095703125001],
              [148.21523437500002, -40.854882812499994],
              [148.29287109375002, -40.94707031250002],
              [148.28544921875005, -41.115332031250006],
              [148.29160156250003, -41.17460937500002],
              [148.30625, -41.23310546875001],
              [148.31220703125, -41.349707031250006],
              [148.28984375000005, -41.46503906250001],
              [148.28691406250005, -41.55498046875002],
              [148.29658203125, -41.64619140625001],
              [148.28759765625, -41.81572265625002],
              [148.31572265625005, -41.927734375],
              [148.30166015625002, -42.00419921875002],
              [148.30146484375, -42.039941406249994],
              [148.32802734375002, -42.07373046875],
              [148.3408203125, -42.11113281250002],
              [148.3310546875, -42.1591796875],
              [148.34257812500005, -42.21533203125],
              [148.33125, -42.261621093749994],
              [148.29033203125005, -42.254980468750006],
              [148.27695312500003, -42.21943359375001],
              [148.28457031250002, -42.1734375],
              [148.27714843750005, -42.13642578125001],
              [148.25576171875002, -42.102636718750006],
              [148.18310546875, -42.06474609375002],
              [148.20439453125005, -42.0419921875],
              [148.24160156250002, -42.021875],
              [148.21367187500005, -41.97001953125002],
              [148.1671875, -42.012304687500006],
              [148.14121093750003, -42.06982421875],
              [148.15625, -42.08828125],
              [148.12753906250003, -42.10371093750001],
              [148.0666015625, -42.1703125],
              [148.02275390625005, -42.25947265625001],
              [148.0048828125, -42.345117187499994],
              [148.009375, -42.4359375],
              [147.97353515625002, -42.505859375],
              [147.92441406250003, -42.57246093750001],
              [147.912109375, -42.65849609375002],
              [147.9150390625, -42.81640625],
              [147.95771484375, -42.96044921875],
              [147.98085937500002, -43.15703125000002],
              [147.94541015625003, -43.18183593750001],
              [147.83857421875, -43.19511718750002],
              [147.78583984375, -43.22001953125002],
              [147.69892578125, -43.12255859375],
              [147.64794921875, -43.020605468750006],
              [147.68730468750005, -42.979882812499994],
              [147.77392578125, -43.00341796875],
              [147.80039062500003, -42.98027343750002],
              [147.80742187500005, -42.9541015625],
              [147.8, -42.928125],
              [147.69345703125003, -42.871972656249994],
              [147.57382812500003, -42.845703125],
              [147.53583984375, -42.878027343750006],
              [147.5490234375, -42.974511718749994],
              [147.53671875000003, -42.996484375],
              [147.45234375, -43.03339843750001],
              [147.40800781250005, -42.89384765625002],
              [147.29794921875003, -42.790917968749994],
              [147.301953125, -42.84052734375001],
              [147.34765625, -42.92656250000002],
              [147.34267578125002, -42.964453125],
              [147.325, -43.01347656250002],
              [147.28076171875, -43.03173828125],
              [147.259765625, -43.07109375000002],
              [147.259765625, -43.12646484375],
              [147.24501953125002, -43.215917968750006],
              [147.1728515625, -43.255859375],
              [146.99697265625002, -43.156347656250006],
              [146.98486328125, -43.18984375],
              [146.9875, -43.21875],
              [147.07734375, -43.27587890625],
              [147.03593750000005, -43.31904296875001],
              [147.00468750000005, -43.36962890625],
              [146.95468750000003, -43.50244140625],
              [146.87392578125002, -43.6125],
              [146.83427734375005, -43.61933593750001],
              [146.69921875, -43.601953125],
              [146.54853515625, -43.508886718750006],
              [146.41318359375003, -43.51953125],
              [146.18671875, -43.512792968750006],
              [146.0431640625, -43.54716796875002],
              [146.01308593750002, -43.44482421875],
              [145.98173828125005, -43.40839843750001],
              [145.99443359375005, -43.376074218750006],
              [146.10878906250002, -43.354394531249994],
              [146.22636718750005, -43.35527343750002],
              [146.2080078125, -43.31621093750002],
              [146.17646484375, -43.3017578125],
              [146.12509765625003, -43.31123046875001],
              [145.97529296875, -43.27714843750002],
              [145.8732421875, -43.292382812499994],
              [145.802734375, -43.244042968749994],
              [145.68154296875002, -43.07597656250002],
              [145.60996093750003, -42.99824218750001],
              [145.5673828125, -42.96796875000001],
              [145.517578125, -42.95136718750001],
              [145.48759765625005, -42.926660156249994],
              [145.26816406250003, -42.544335937499994],
              [145.23710937500005, -42.45556640625],
              [145.19882812500003, -42.230859375],
              [145.37294921875002, -42.338476562500006],
              [145.43486328125005, -42.40654296875002],
              [145.46826171875, -42.49287109375001],
              [145.52724609375002, -42.38818359375],
              [145.5166015625, -42.3544921875],
              [145.3603515625, -42.2275390625],
              [145.33964843750005, -42.19072265625002],
              [145.3310546875, -42.147070312500006],
              [145.29443359375, -42.191015625],
              [145.23486328125, -42.19697265625001],
              [145.258984375, -42.107324218749994],
              [145.23818359375002, -42.019628906250006],
              [145.05537109375, -41.826757812500006],
              [144.91552734375, -41.64404296875],
              [144.77792968750003, -41.418847656249994],
              [144.76611328125, -41.390039062499994],
              [144.76435546875, -41.34150390625001],
              [144.69775390625, -41.19072265625002],
              [144.66240234375005, -41.07890625000002],
              [144.64609375000003, -40.980859375],
              [144.70966796875, -40.78291015625001],
              [144.71855468750005, -40.672265625],
              [144.8185546875, -40.7216796875],
              [145.04296875, -40.78671875],
            ],
          ],
          [
            [
              [143.9279296875, -40.116113281249994],
              [143.89873046875005, -40.120214843750006],
              [143.87578125000005, -40.06396484375],
              [143.88759765625002, -39.98359375000001],
              [143.83857421875, -39.90410156250002],
              [143.865234375, -39.82421875],
              [143.86181640625, -39.73798828125001],
              [143.87939453125, -39.7],
              [143.93935546875002, -39.658105468749994],
              [143.94882812500003, -39.58369140625001],
              [144.00078125000005, -39.580175781250006],
              [144.09130859375, -39.638085937499994],
              [144.12089843750005, -39.785253906250006],
              [144.10605468750003, -39.8740234375],
              [144.14101562500002, -39.95380859375001],
              [144.11191406250003, -40.022070312500006],
              [144.03505859375002, -40.078222656250006],
              [143.9279296875, -40.116113281249994],
            ],
          ],
          [
            [
              [148.00039062500002, -39.75761718750002],
              [148.1779296875, -39.9384765625],
              [148.27001953125, -39.966699218749994],
              [148.29736328125, -39.985742187499994],
              [148.28984375000005, -40.0654296875],
              [148.25078125000005, -40.099511718749994],
              [148.3232421875, -40.144433593749994],
              [148.31357421875003, -40.17353515625001],
              [148.29941406250003, -40.172460937500006],
              [148.21035156250002, -40.23369140625002],
              [148.1056640625, -40.262109375],
              [148.07363281250002, -40.240820312500006],
              [148.046875, -40.212792968749994],
              [148.02480468750002, -40.171972656250006],
              [147.89052734375002, -40.014550781249994],
              [147.90595703125, -39.97138671875001],
              [147.87626953125005, -39.90546875000001],
              [147.81230468750005, -39.91044921875002],
              [147.76718750000003, -39.87031250000001],
              [147.83916015625005, -39.83154296875],
              [147.93300781250002, -39.725976562499994],
              [148.00039062500002, -39.75761718750002],
            ],
          ],
          [
            [
              [147.35605468750003, -43.39697265625],
              [147.30888671875005, -43.50078125000002],
              [147.2314453125, -43.48310546875001],
              [147.15380859375, -43.50019531250001],
              [147.10498046875, -43.43115234375],
              [147.1046875, -43.41289062500002],
              [147.1630859375, -43.430273437500006],
              [147.18466796875003, -43.4078125],
              [147.1984375, -43.37919921875002],
              [147.2197265625, -43.37138671875002],
              [147.23398437500003, -43.33046875],
              [147.28388671875, -43.27890625],
              [147.3125, -43.2802734375],
              [147.34248046875, -43.346289062500006],
              [147.35605468750003, -43.39697265625],
            ],
          ],
          [
            [
              [148.10429687500005, -42.71044921875],
              [148.04814453125005, -42.71923828125],
              [148.02968750000002, -42.71484375],
              [148.03085937500003, -42.66337890625002],
              [148.02275390625005, -42.64042968750002],
              [148.07255859375005, -42.593164062499994],
              [148.1427734375, -42.61591796875001],
              [148.16953125000003, -42.651757812499994],
              [148.1005859375, -42.680566406249994],
              [148.10429687500005, -42.71044921875],
            ],
          ],
          [
            [
              [147.4345703125, -43.24072265625],
              [147.371875, -43.240820312500006],
              [147.348828125, -43.232421875],
              [147.33759765625, -43.18330078125001],
              [147.29609375, -43.16171875],
              [147.31914062500005, -43.14531250000002],
              [147.32734375, -43.114648437499994],
              [147.3525390625, -43.08027343750001],
              [147.39726562500005, -43.118261718750006],
              [147.4345703125, -43.24072265625],
            ],
          ],
          [
            [
              [144.784375, -40.506738281249994],
              [144.748046875, -40.589453125],
              [144.71015625, -40.485253906249994],
              [144.751171875, -40.47021484375],
              [144.7833984375, -40.43486328125002],
              [144.79082031250005, -40.440332031249994],
              [144.784375, -40.506738281249994],
            ],
          ],
          [
            [
              [148.32626953125003, -40.30693359375002],
              [148.42070312500005, -40.3671875],
              [148.47421875000003, -40.43242187500002],
              [148.40400390625, -40.48652343750001],
              [148.352734375, -40.49726562500001],
              [148.31943359375003, -40.4345703125],
              [148.2140625, -40.45751953125],
              [148.1025390625, -40.45166015625],
              [148.02011718750003, -40.404199218749994],
              [148.01044921875, -40.38056640625001],
              [148.0587890625, -40.356835937499994],
              [148.19814453125002, -40.35791015625],
              [148.32626953125003, -40.30693359375002],
            ],
          ],
          [
            [
              [148.23691406250003, -40.51513671875],
              [148.18779296875005, -40.59257812500002],
              [148.126953125, -40.5439453125],
              [148.11728515625003, -40.521484375],
              [148.19316406250005, -40.503125],
              [148.21835937500003, -40.50507812500001],
              [148.23691406250003, -40.51513671875],
            ],
          ],
          [
            [
              [158.87880859375002, -54.70976562500002],
              [158.84521484375, -54.74921875000001],
              [158.8359375, -54.704003906249994],
              [158.89697265625, -54.50605468750001],
              [158.95888671875002, -54.47236328125001],
              [158.94560546875005, -54.575],
              [158.87880859375002, -54.70976562500002],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 2,
        sovereignt: "Papua New Guinea",
        sov_a3: "PNG",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Papua New Guinea",
        adm0_a3: "PNG",
        geou_dif: 0,
        geounit: "Papua New Guinea",
        gu_a3: "PNG",
        su_dif: 1,
        subunit: "Papua New Guinea",
        su_a3: "PN1",
        brk_diff: 0,
        name: "Papua New Guinea",
        name_long: "Papua New Guinea",
        brk_a3: "PN1",
        brk_name: "Papua New Guinea",
        brk_group: null,
        abbrev: "P.N.G.",
        postal: "PG",
        formal_en: "Independent State of Papua New Guinea",
        formal_fr: null,
        name_ciawf: "Papua New Guinea",
        note_adm0: null,
        note_brk: null,
        name_sort: "Papua New Guinea",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 2,
        mapcolor9: 3,
        mapcolor13: 1,
        pop_est: 8776109,
        pop_rank: 13,
        pop_year: 2019,
        gdp_md: 24829,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        fips_10: "PP",
        iso_a2: "PG",
        iso_a2_eh: "PG",
        iso_a3: "PNG",
        iso_a3_eh: "PNG",
        iso_n3: "598",
        iso_n3_eh: "598",
        un_a3: "598",
        wb_a2: "PG",
        wb_a3: "PNG",
        woe_id: 23424926,
        woe_id_eh: 23424926,
        woe_note: "Exact WOE match as country",
        adm0_iso: "PN1",
        adm0_diff: null,
        adm0_tlc: "PN1",
        adm0_a3_us: "PNG",
        adm0_a3_fr: "PNG",
        adm0_a3_ru: "PNG",
        adm0_a3_es: "PNG",
        adm0_a3_cn: "PNG",
        adm0_a3_tw: "PNG",
        adm0_a3_in: "PNG",
        adm0_a3_np: "PNG",
        adm0_a3_pk: "PNG",
        adm0_a3_de: "PNG",
        adm0_a3_gb: "PNG",
        adm0_a3_br: "PNG",
        adm0_a3_il: "PNG",
        adm0_a3_ps: "PNG",
        adm0_a3_sa: "PNG",
        adm0_a3_eg: "PNG",
        adm0_a3_ma: "PNG",
        adm0_a3_pt: "PNG",
        adm0_a3_ar: "PNG",
        adm0_a3_jp: "PNG",
        adm0_a3_ko: "PNG",
        adm0_a3_vn: "PNG",
        adm0_a3_tr: "PNG",
        adm0_a3_id: "PNG",
        adm0_a3_pl: "PNG",
        adm0_a3_gr: "PNG",
        adm0_a3_it: "PNG",
        adm0_a3_nl: "PNG",
        adm0_a3_se: "PNG",
        adm0_a3_bd: "PNG",
        adm0_a3_ua: "PNG",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Oceania",
        region_un: "Oceania",
        subregion: "Melanesia",
        region_wb: "East Asia & Pacific",
        name_len: 16,
        long_len: 16,
        abbrev_len: 6,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 2.5,
        max_label: 7.5,
        label_x: 143.910216,
        label_y: -5.695285,
        ne_id: 1159321173,
        wikidataid: "Q691",
        name_ar: "بابوا غينيا الجديدة",
        name_bn: "পাপুয়া নিউগিনি",
        name_de: "Papua-Neuguinea",
        name_en: "Papua New Guinea",
        name_es: "Papúa Nueva Guinea",
        name_fa: "پاپوآ گینه نو",
        name_fr: "Papouasie-Nouvelle-Guinée",
        name_el: "Παπούα Νέα Γουινέα",
        name_he: "פפואה גינאה החדשה",
        name_hi: "पापुआ न्यू गिनी",
        name_hu: "Pápua Új-Guinea",
        name_id: "Papua Nugini",
        name_it: "Papua Nuova Guinea",
        name_ja: "パプアニューギニア",
        name_ko: "파푸아뉴기니",
        name_nl: "Papoea-Nieuw-Guinea",
        name_pl: "Papua-Nowa Gwinea",
        name_pt: "Papua-Nova Guiné",
        name_ru: "Папуа — Новая Гвинея",
        name_sv: "Papua Nya Guinea",
        name_tr: "Papua Yeni Gine",
        name_uk: "Папуа Нова Гвінея",
        name_ur: "پاپوا نیو گنی",
        name_vi: "Papua New Guinea",
        name_zh: "巴布亚新几内亚",
        name_zht: "巴布亞紐幾內亞",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "PNG.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [152.9658203125, -4.75634765625],
              [152.89169921875003, -4.832421875],
              [152.84560546875002, -4.761523437500003],
              [152.78652343750002, -4.699414062500011],
              [152.73994140625, -4.635839843750006],
              [152.6806640625, -4.4984375],
              [152.677734375, -4.42919921875],
              [152.693359375, -4.35595703125],
              [152.69677734375, -4.28203125],
              [152.6681640625, -4.1318359375],
              [152.59843750000005, -3.994824218750011],
              [152.35576171875005, -3.668164062500011],
              [152.27939453125003, -3.582421875],
              [152.19218750000005, -3.505859375],
              [152.13632812500003, -3.487109375],
              [152.02324218750005, -3.46875],
              [151.97294921875005, -3.453417968750003],
              [151.87978515625002, -3.400097656250011],
              [151.7931640625, -3.337890625],
              [151.57851562500002, -3.153515625000011],
              [151.4650390625, -3.101367187500003],
              [151.40507812500005, -3.036914062500003],
              [151.06679687500002, -2.829003906250009],
              [150.96806640625005, -2.779882812500006],
              [150.8478515625, -2.77978515625],
              [150.74609375, -2.738867187500006],
              [150.82646484375005, -2.712890625],
              [150.84296875, -2.6435546875],
              [150.825390625, -2.572949218750011],
              [150.9953125, -2.68828125],
              [151.17460937500005, -2.7890625],
              [151.22646484375002, -2.870312500000011],
              [151.31474609375005, -2.875292968750003],
              [151.47539062500005, -2.942480468750006],
              [151.58574218750005, -3.003027343750006],
              [151.68984375000002, -3.072851562500006],
              [151.80712890625, -3.1728515625],
              [152.03291015625, -3.251367187500009],
              [152.06503906250003, -3.279882812500006],
              [152.17939453125, -3.410351562500011],
              [152.32949218750002, -3.52099609375],
              [152.38046875000003, -3.581933593750009],
              [153.016796875, -4.105664062500011],
              [153.12421875, -4.25234375],
              [153.13251953125, -4.352441406250009],
              [153.1115234375, -4.391699218750006],
              [153.04433593750002, -4.476367187500003],
              [153.04560546875, -4.576367187500011],
              [153.02324218750005, -4.666308593750003],
              [152.9658203125, -4.75634765625],
            ],
          ],
          [
            [
              [151.915625, -4.296777343750009],
              [151.96757812500005, -4.316992187500006],
              [152.1171875, -4.212207031250003],
              [152.197265625, -4.28515625],
              [152.29941406250003, -4.320703125],
              [152.40566406250002, -4.340722656250009],
              [152.36357421875005, -4.490820312500006],
              [152.37607421875003, -4.560253906250011],
              [152.403515625, -4.629296875],
              [152.4, -4.73125],
              [152.35117187500003, -4.822167968750009],
              [152.25761718750005, -4.9546875],
              [152.21572265625002, -4.979199218750011],
              [152.16660156250003, -4.9931640625],
              [152.01328125000003, -5.003808593750009],
              [151.98369140625005, -5.074414062500011],
              [151.99394531250005, -5.149023437500006],
              [152.07685546875, -5.2470703125],
              [152.14296875000002, -5.35703125],
              [152.07705078125002, -5.458300781250003],
              [151.96845703125, -5.52880859375],
              [151.8654296875, -5.56484375],
              [151.69492187500003, -5.543554687500006],
              [151.51513671875, -5.55234375],
              [151.4814453125, -5.590917968750006],
              [151.48046875, -5.654589843750003],
              [151.45517578125003, -5.703125],
              [151.42246093750003, -5.747363281250003],
              [151.33125, -5.839062500000011],
              [151.22929687500005, -5.919921875],
              [151.0900390625, -5.996679687500006],
              [151.0431640625, -6.015039062500009],
              [150.919921875, -6.027246093750009],
              [150.80898437500002, -6.071386718750006],
              [150.75957031250005, -6.114453125000011],
              [150.70576171875, -6.1494140625],
              [150.5880859375, -6.187792968750003],
              [150.47353515625002, -6.263378906250011],
              [150.42832031250003, -6.276171875],
              [150.19082031250002, -6.289355468750003],
              [149.85097656250002, -6.29296875],
              [149.75029296875005, -6.300878906250006],
              [149.6525390625, -6.290429687500009],
              [149.59843750000005, -6.260937500000011],
              [149.48300781250003, -6.124804687500003],
              [149.38232421875, -6.078125],
              [149.27265625, -6.079492187500009],
              [149.12656250000003, -6.127636718750011],
              [149.09902343750002, -6.116992187500003],
              [148.80751953125002, -5.91640625],
              [148.71914062500002, -5.867382812500011],
              [148.62480468750005, -5.830761718750011],
              [148.509765625, -5.805371093750011],
              [148.40117187500005, -5.765039062500009],
              [148.33720703125005, -5.66943359375],
              [148.3447265625, -5.544921875],
              [148.43203125000002, -5.471777343750006],
              [148.56494140625, -5.507910156250006],
              [148.61582031250003, -5.507421875],
              [148.66582031250005, -5.486621093750003],
              [148.72431640625, -5.493261718750006],
              [148.78349609375005, -5.511621093750009],
              [148.99921875, -5.484570312500011],
              [149.1240234375, -5.522656250000011],
              [149.2453125, -5.573046875],
              [149.35888671875, -5.583984375],
              [149.47539062500005, -5.5732421875],
              [149.63173828125002, -5.516015625],
              [149.68105468750002, -5.523535156250006],
              [149.83144531250002, -5.524121093750011],
              [149.96279296875002, -5.44775390625],
              [150.0119140625, -5.139550781250009],
              [150.04531250000002, -5.03466796875],
              [150.0900390625, -5.011816406250006],
              [150.122265625, -5.018164062500006],
              [150.17031250000002, -5.070605468750003],
              [150.10869140625005, -5.136035156250003],
              [150.08154296875, -5.186425781250009],
              [150.0724609375, -5.3095703125],
              [150.10625, -5.429003906250003],
              [150.18310546875, -5.523632812500011],
              [150.29873046875002, -5.53564453125],
              [150.40439453125003, -5.47314453125],
              [150.51943359375002, -5.460253906250003],
              [150.62578125000005, -5.520898437500009],
              [150.73447265625003, -5.510449218750011],
              [150.784375, -5.470898437500011],
              [150.84257812500005, -5.453710937500006],
              [150.90029296875002, -5.447167968750009],
              [150.95292968750005, -5.423730468750009],
              [151.02226562500005, -5.320703125],
              [151.06884765625, -5.204492187500009],
              [151.13779296875003, -5.112890625],
              [151.32656250000002, -4.960351562500009],
              [151.38095703125003, -4.941308593750009],
              [151.43984375000002, -4.930957031250003],
              [151.57255859375005, -4.9375],
              [151.67119140625005, -4.88330078125],
              [151.67890625, -4.761035156250003],
              [151.66464843750003, -4.637011718750003],
              [151.551953125, -4.345507812500003],
              [151.54423828125005, -4.29921875],
              [151.560546875, -4.247363281250003],
              [151.59306640625005, -4.20078125],
              [151.70371093750003, -4.2],
              [151.8193359375, -4.216992187500011],
              [151.86474609375, -4.260839843750006],
              [151.915625, -4.296777343750009],
            ],
          ],
          [
            [
              [140.97617187500003, -9.11875],
              [140.97597656250002, -9.105566406250006],
              [140.97597656250002, -8.902246093750009],
              [140.97587890625005, -8.698925781250011],
              [140.97578125, -8.495703125],
              [140.97568359375003, -8.292382812500009],
              [140.9755859375, -8.089062500000011],
              [140.9755859375, -7.8857421875],
              [140.97548828125002, -7.682519531250009],
              [140.97539062500005, -7.479199218750011],
              [140.97529296875, -7.27587890625],
              [140.97519531250003, -7.072558593750003],
              [140.97519531250003, -6.905371093750006],
              [140.91953125000003, -6.840039062500011],
              [140.8623046875, -6.740039062500003],
              [140.87460937500003, -6.611523437500011],
              [140.94404296875, -6.452246093750006],
              [140.975, -6.34609375],
              [140.975, -6.259375],
              [140.97490234375005, -6.05615234375],
              [140.9748046875, -5.852832031250003],
              [140.97470703125003, -5.649511718750006],
              [140.974609375, -5.446191406250009],
              [140.974609375, -5.24296875],
              [140.97451171875002, -5.039648437500006],
              [140.97441406250005, -4.836328125],
              [140.97431640625, -4.633007812500011],
              [140.97421875000003, -4.429785156250006],
              [140.97421875000003, -4.226464843750009],
              [140.97402343750002, -4.023144531250011],
              [140.97402343750002, -3.81982421875],
              [140.97392578125005, -3.616601562500009],
              [140.973828125, -3.413281250000011],
              [140.97373046875003, -3.2099609375],
              [140.9736328125, -3.006640625],
              [140.97353515625002, -2.803417968750011],
              [140.97343750000005, -2.681054687500009],
              [140.97343750000005, -2.613574218750003],
              [140.97343750000005, -2.609765625],
              [141.00332031250002, -2.61015625],
              [141.10478515625005, -2.611328125],
              [141.18564453125003, -2.627832031250009],
              [141.68681640625005, -2.845019531250003],
              [141.83652343750003, -2.93212890625],
              [141.8875, -2.952539062500009],
              [141.93779296875005, -2.953320312500011],
              [141.98574218750002, -2.963574218750011],
              [142.21152343750003, -3.08349609375],
              [142.5490234375, -3.20458984375],
              [142.90517578125002, -3.320703125],
              [143.015625, -3.344921875000011],
              [143.12998046875003, -3.355078125],
              [143.37832031250002, -3.3953125],
              [143.508984375, -3.43115234375],
              [143.70058593750002, -3.573339843750006],
              [143.79716796875005, -3.617285156250006],
              [143.8876953125, -3.697460937500011],
              [144.0158203125, -3.78359375],
              [144.06640625, -3.80517578125],
              [144.12197265625002, -3.815234375],
              [144.24794921875002, -3.818261718750009],
              [144.37441406250002, -3.802734375],
              [144.42656250000005, -3.809667968750006],
              [144.477734375, -3.825292968750006],
              [144.52451171875003, -3.855273437500003],
              [144.54824218750002, -3.9130859375],
              [144.62666015625, -3.993066406250009],
              [144.73789062500003, -4.029101562500003],
              [144.84345703125, -4.101464843750009],
              [144.9384765625, -4.188183593750011],
              [145.00839843750003, -4.275488281250006],
              [145.08779296875002, -4.34912109375],
              [145.2080078125, -4.380273437500009],
              [145.33457031250003, -4.38525390625],
              [145.76699218750002, -4.823046875],
              [145.7880859375, -4.890625],
              [145.79287109375002, -5.177929687500011],
              [145.74521484375003, -5.402441406250006],
              [145.85283203125005, -5.471289062500006],
              [145.99941406250002, -5.4970703125],
              [146.20537109375005, -5.545117187500011],
              [146.40341796875003, -5.616601562500009],
              [147.03427734375003, -5.919238281250003],
              [147.12089843750005, -5.945019531250011],
              [147.2482421875, -5.954785156250011],
              [147.37666015625, -5.95078125],
              [147.42275390625002, -5.966210937500009],
              [147.5185546875, -6.02109375],
              [147.56669921875005, -6.056933593750003],
              [147.65302734375, -6.15478515625],
              [147.73007812500003, -6.261132812500009],
              [147.762890625, -6.29150390625],
              [147.80205078125005, -6.315234375],
              [147.82451171875005, -6.373046875],
              [147.8544921875, -6.551171875],
              [147.84550781250005, -6.662402343750003],
              [147.81044921875002, -6.70361328125],
              [147.70957031250003, -6.7236328125],
              [147.35576171875005, -6.742382812500011],
              [147.119140625, -6.7216796875],
              [146.95361328125, -6.834082031250006],
              [146.94921875, -6.883105468750003],
              [146.96074218750005, -6.928808593750006],
              [147.10488281250002, -7.1669921875],
              [147.19003906250003, -7.378125],
              [147.26015625000002, -7.464062500000011],
              [147.36533203125003, -7.533789062500006],
              [147.458984375, -7.6162109375],
              [147.5451171875, -7.7109375],
              [147.72431640625, -7.876269531250003],
              [147.821875, -7.9375],
              [147.93613281250003, -7.975390625],
              [148.12675781250005, -8.103613281250006],
              [148.15195312500003, -8.160253906250006],
              [148.20644531250002, -8.338671875],
              [148.22998046875, -8.459667968750011],
              [148.23359375, -8.509570312500003],
              [148.246875, -8.554296875],
              [148.41445312500002, -8.663964843750009],
              [148.451171875, -8.694531250000011],
              [148.52587890625, -8.938574218750006],
              [148.58310546875003, -9.0517578125],
              [148.67949218750005, -9.091992187500011],
              [148.79179687500005, -9.089453125],
              [149.09746093750005, -9.016894531250003],
              [149.14169921875003, -9.014550781250009],
              [149.19833984375003, -9.03125],
              [149.24765625000003, -9.070996093750011],
              [149.26406250000002, -9.180761718750006],
              [149.21621093750002, -9.2958984375],
              [149.20302734375002, -9.406835937500006],
              [149.26318359375, -9.497851562500003],
              [149.41875, -9.56884765625],
              [149.47578125, -9.58828125],
              [149.75576171875002, -9.6109375],
              [149.865625, -9.630078125000011],
              [149.97353515625002, -9.660742187500006],
              [150.01103515625005, -9.688183593750011],
              [149.98466796875005, -9.737011718750011],
              [149.92822265625, -9.760839843750006],
              [149.86435546875003, -9.770605468750006],
              [149.76123046875, -9.805859375000011],
              [149.76308593750002, -9.86865234375],
              [149.8212890625, -9.934179687500006],
              [149.87441406250002, -10.012988281250003],
              [149.919140625, -10.041601562500006],
              [149.96757812500005, -10.060742187500011],
              [150.08857421875, -10.088085937500011],
              [150.20625, -10.125585937500006],
              [150.28388671875, -10.162890625],
              [150.36406250000005, -10.189648437500011],
              [150.53886718750005, -10.206738281250011],
              [150.6669921875, -10.257128906250003],
              [150.84951171875002, -10.236035156250011],
              [150.69130859375002, -10.31787109375],
              [150.63681640625003, -10.337988281250006],
              [150.44609375000005, -10.307324218750011],
              [150.41025390625003, -10.339257812500009],
              [150.48886718750003, -10.42578125],
              [150.60546875, -10.484082031250011],
              [150.64716796875, -10.51796875],
              [150.61796875000005, -10.5576171875],
              [150.482421875, -10.636914062500011],
              [150.42578125, -10.648535156250006],
              [150.31992187500003, -10.654882812500006],
              [150.14238281250005, -10.620703125],
              [150.016796875, -10.5771484375],
              [149.98154296875003, -10.517675781250006],
              [149.94804687500005, -10.482617187500011],
              [149.83476562500005, -10.398828125],
              [149.7541015625, -10.35302734375],
              [149.6513671875, -10.3375],
              [149.54433593750002, -10.338476562500006],
              [149.35263671875003, -10.289746093750011],
              [148.93681640625005, -10.255175781250003],
              [148.83769531250005, -10.233984375],
              [148.712890625, -10.166894531250009],
              [148.65419921875002, -10.157324218750006],
              [148.59121093750002, -10.178417968750011],
              [148.43056640625002, -10.19140625],
              [148.38339843750003, -10.185449218750009],
              [148.26875, -10.128222656250003],
              [148.15048828125003, -10.107324218750009],
              [148.10126953125, -10.12451171875],
              [148.05136718750003, -10.128320312500009],
              [147.89013671875, -10.08740234375],
              [147.76865234375003, -10.070117187500003],
              [147.66884765625002, -10.013085937500009],
              [147.61435546875003, -9.959765625],
              [147.553125, -9.912402343750003],
              [147.49648437500002, -9.790429687500009],
              [147.40830078125003, -9.674707031250009],
              [147.29892578125003, -9.57958984375],
              [147.064453125, -9.426074218750003],
              [147.01718750000003, -9.387890625000011],
              [146.92539062500003, -9.247167968750006],
              [146.93037109375, -9.15390625],
              [146.96376953125002, -9.0595703125],
              [146.91328125, -9.091699218750009],
              [146.85625, -9.087695312500003],
              [146.69658203125005, -9.025390625],
              [146.630859375, -8.951171875],
              [146.52412109375, -8.749707031250011],
              [146.45585937500005, -8.6435546875],
              [146.29648437500003, -8.45556640625],
              [146.25058593750003, -8.343945312500011],
              [146.18408203125, -8.246386718750003],
              [146.14296875000002, -8.210253906250003],
              [146.10878906250002, -8.16845703125],
              [146.07851562500002, -8.114160156250009],
              [146.033203125, -8.076367187500011],
              [145.81093750000002, -7.992773437500006],
              [145.77177734375005, -7.96640625],
              [145.7287109375, -7.952441406250003],
              [145.56337890625002, -7.94384765625],
              [145.4677734375, -7.930078125],
              [145.2875, -7.861621093750003],
              [145.1943359375, -7.841113281250003],
              [145.08232421875005, -7.828125],
              [144.973828125, -7.802148437500009],
              [144.9208984375, -7.776660156250003],
              [144.88535156250003, -7.733593750000011],
              [144.8642578125, -7.631542968750011],
              [144.7734375, -7.642480468750009],
              [144.684375, -7.624804687500003],
              [144.59794921875005, -7.588964843750006],
              [144.50986328125003, -7.5673828125],
              [144.44970703125, -7.59814453125],
              [144.43125, -7.679394531250011],
              [144.40341796875003, -7.68359375],
              [144.35185546875005, -7.6669921875],
              [144.326171875, -7.6767578125],
              [144.27021484375, -7.714257812500009],
              [144.22539062500005, -7.764941406250003],
              [144.14287109375005, -7.757226562500009],
              [143.9736328125, -7.705957031250009],
              [143.89824218750005, -7.673828125],
              [143.8341796875, -7.615917968750011],
              [143.77910156250005, -7.550097656250003],
              [143.72333984375, -7.498242187500011],
              [143.65488281250003, -7.460351562500009],
              [143.74208984375002, -7.5498046875],
              [143.94228515625002, -7.944238281250009],
              [143.89218750000003, -7.951855468750011],
              [143.840625, -7.94189453125],
              [143.88798828125005, -8.017675781250006],
              [143.83339843750002, -8.029101562500003],
              [143.779296875, -8.028222656250009],
              [143.6650390625, -7.995507812500009],
              [143.55156250000005, -7.984667968750003],
              [143.51816406250003, -8.000683593750011],
              [143.5421875, -8.029101562500003],
              [143.58203125, -8.112695312500009],
              [143.61376953125, -8.200390625000011],
              [143.45, -8.23984375],
              [143.28203125000005, -8.263867187500011],
              [143.094921875, -8.311230468750011],
              [142.90546875, -8.314453125],
              [142.80830078125, -8.2875],
              [142.70859375000003, -8.272265625],
              [142.61503906250005, -8.2875],
              [142.52412109375, -8.321679687500009],
              [142.44755859375005, -8.316210937500003],
              [142.39921875000005, -8.2546875],
              [142.37646484375, -8.2080078125],
              [142.34746093750005, -8.16748046875],
              [142.27587890625, -8.173925781250006],
              [142.20683593750005, -8.19580078125],
              [142.32509765625002, -8.198339843750006],
              [142.360546875, -8.25],
              [142.39101562500002, -8.312695312500011],
              [142.4748046875, -8.369433593750003],
              [142.57597656250005, -8.335644531250011],
              [142.79794921875003, -8.345019531250003],
              [143.013671875, -8.44384765625],
              [143.06484375000002, -8.455175781250006],
              [143.11181640625, -8.474511718750009],
              [143.22294921875005, -8.572167968750009],
              [143.30673828125003, -8.6609375],
              [143.37724609375005, -8.76220703125],
              [143.39218750000003, -8.801855468750006],
              [143.3875, -8.908203125],
              [143.3662109375, -8.961035156250006],
              [143.22685546875005, -9.0359375],
              [143.07822265625003, -9.092480468750011],
              [142.85917968750005, -9.20263671875],
              [142.64716796875, -9.327832031250011],
              [142.53574218750003, -9.303320312500006],
              [142.43525390625, -9.237011718750011],
              [142.39628906250005, -9.219042968750003],
              [142.29277343750005, -9.182910156250003],
              [142.22958984375003, -9.169921875],
              [141.97890625000002, -9.198144531250009],
              [141.72734375000005, -9.212597656250011],
              [141.62158203125, -9.211328125],
              [141.51875, -9.190136718750011],
              [141.40566406250002, -9.150683593750003],
              [141.29365234375, -9.168164062500011],
              [141.2169921875, -9.214453125],
              [141.13320312500002, -9.221289062500006],
              [140.97617187500003, -9.11875],
            ],
          ],
          [
            [
              [148.02578125000002, -5.826367187500011],
              [147.98544921875003, -5.833984375],
              [147.96796875, -5.78857421875],
              [147.87451171875, -5.749218750000011],
              [147.78105468750005, -5.627246093750003],
              [147.78251953125005, -5.5224609375],
              [147.79462890625, -5.492382812500011],
              [147.84648437500005, -5.490820312500006],
              [148.05478515625003, -5.611523437500011],
              [148.07607421875002, -5.650195312500003],
              [148.06044921875002, -5.7646484375],
              [148.02578125000002, -5.826367187500011],
            ],
          ],
          [
            [
              [146.01933593750005, -4.726171875],
              [145.95234375, -4.755761718750009],
              [145.90400390625, -4.733007812500006],
              [145.88359375000005, -4.66748046875],
              [145.90019531250005, -4.604199218750011],
              [145.95878906250005, -4.554296875],
              [145.99580078125, -4.539257812500011],
              [146.03740234375005, -4.573144531250009],
              [146.05341796875, -4.64013671875],
              [146.01933593750005, -4.726171875],
            ],
          ],
          [
            [
              [152.67060546875, -3.133398437500006],
              [152.64619140625, -3.22119140625],
              [152.58505859375003, -3.169824218750009],
              [152.54326171875005, -3.095605468750009],
              [152.56992187500003, -3.0625],
              [152.63876953125003, -3.042773437500003],
              [152.67060546875, -3.133398437500006],
            ],
          ],
          [
            [
              [152.09921875000003, -2.947363281250006],
              [152.08847656250003, -2.997851562500003],
              [152.05732421875, -2.994921875],
              [151.97109375000002, -2.89609375],
              [151.95458984375, -2.870507812500009],
              [151.97470703125003, -2.845605468750009],
              [152.07460937500002, -2.91845703125],
              [152.09921875000003, -2.947363281250006],
            ],
          ],
          [
            [
              [149.76542968750005, -1.553027343750003],
              [149.76318359375, -1.589160156250003],
              [149.69091796875, -1.570898437500006],
              [149.67109375, -1.576269531250006],
              [149.5458984375, -1.4716796875],
              [149.5478515625, -1.40771484375],
              [149.58095703125002, -1.353222656250011],
              [149.6330078125, -1.362011718750011],
              [149.72529296875, -1.4306640625],
              [149.76542968750005, -1.553027343750003],
            ],
          ],
          [
            [
              [151.08095703125002, -10.020117187500006],
              [151.12343750000002, -10.020214843750011],
              [151.1943359375, -9.945507812500011],
              [151.25566406250005, -9.92265625],
              [151.29648437500003, -9.956738281250011],
              [151.23085937500002, -10.194726562500009],
              [151.17548828125, -10.158886718750011],
              [150.9591796875, -10.092578125],
              [150.95244140625005, -9.9984375],
              [150.89609375000003, -9.968066406250003],
              [150.861328125, -9.876171875000011],
              [150.78964843750003, -9.774316406250009],
              [150.77607421875, -9.709082031250006],
              [150.81669921875005, -9.7359375],
              [150.8623046875, -9.802441406250011],
              [151.05146484375, -9.93896484375],
              [151.044140625, -9.983105468750011],
              [151.08095703125002, -10.020117187500006],
            ],
          ],
          [
            [
              [150.34541015625, -9.493847656250011],
              [150.33134765625005, -9.5185546875],
              [150.27285156250002, -9.500390625],
              [150.10976562500002, -9.361914062500006],
              [150.1349609375, -9.259570312500003],
              [150.20830078125005, -9.206347656250003],
              [150.32011718750005, -9.26416015625],
              [150.35703125000003, -9.349023437500009],
              [150.3681640625, -9.396484375],
              [150.34541015625, -9.493847656250011],
            ],
          ],
          [
            [
              [151.10683593750002, -8.733496093750006],
              [151.12412109375003, -8.804882812500011],
              [151.04619140625005, -8.728320312500003],
              [151.08076171875, -8.641796875000011],
              [151.08681640625002, -8.595019531250003],
              [151.08281250000005, -8.568652343750003],
              [151.00498046875003, -8.523828125],
              [151.04628906250002, -8.450585937500009],
              [151.09013671875005, -8.425976562500011],
              [151.11757812500002, -8.418847656250009],
              [151.11640625, -8.521875],
              [151.13857421875002, -8.568066406250011],
              [151.10683593750002, -8.733496093750006],
            ],
          ],
          [
            [
              [154.28076171875, -11.361425781250006],
              [154.26601562500002, -11.415917968750009],
              [154.22958984375003, -11.3974609375],
              [154.12119140625003, -11.425683593750009],
              [154.06406250000003, -11.419335937500009],
              [154.03115234375002, -11.370507812500009],
              [154.0234375, -11.347949218750003],
              [154.11767578125, -11.365527343750003],
              [154.1017578125, -11.311425781250009],
              [154.23789062500003, -11.3388671875],
              [154.28076171875, -11.361425781250006],
            ],
          ],
          [
            [
              [143.58681640625002, -8.481738281250003],
              [143.5431640625, -8.484765625],
              [143.36689453125, -8.416894531250009],
              [143.321875, -8.367578125],
              [143.52822265625002, -8.378515625],
              [143.58144531250002, -8.390917968750003],
              [143.59257812500005, -8.4599609375],
              [143.58681640625002, -8.481738281250003],
            ],
          ],
          [
            [
              [147.17626953125, -5.431933593750003],
              [147.12021484375003, -5.437402343750009],
              [147.02900390625, -5.342382812500006],
              [147.005859375, -5.30703125],
              [147.01474609375003, -5.257421875],
              [147.1310546875, -5.190820312500009],
              [147.20634765625005, -5.2515625],
              [147.221875, -5.381542968750011],
              [147.17626953125, -5.431933593750003],
            ],
          ],
          [
            [
              [147.067578125, -1.960156250000011],
              [147.4005859375, -2.025097656250011],
              [147.42255859375, -2.024316406250009],
              [147.41875, -2.001074218750006],
              [147.42441406250003, -1.99453125],
              [147.44414062500005, -2.011523437500003],
              [147.43808593750003, -2.058984375],
              [147.38544921875, -2.070605468750003],
              [147.33652343750003, -2.066015625],
              [147.30136718750003, -2.090429687500006],
              [147.20634765625005, -2.181933593750003],
              [147.14218750000003, -2.166601562500006],
              [147.06386718750002, -2.187109375],
              [146.92636718750003, -2.1890625],
              [146.74785156250005, -2.148828125],
              [146.69912109375002, -2.182714843750006],
              [146.63544921875, -2.17333984375],
              [146.5724609375, -2.21044921875],
              [146.54648437500003, -2.20859375],
              [146.53134765625003, -2.154101562500003],
              [146.532421875, -2.126171875000011],
              [146.60703125000003, -2.1025390625],
              [146.5958984375, -2.016894531250003],
              [146.65625, -1.974023437500009],
              [146.76005859375005, -1.977734375000011],
              [146.85712890625, -1.948535156250003],
              [147.067578125, -1.960156250000011],
            ],
          ],
          [
            [
              [150.43662109375003, -2.661816406250011],
              [150.2375, -2.675488281250011],
              [150.16572265625, -2.660253906250006],
              [150.1015625, -2.6025390625],
              [150.04345703125, -2.5125],
              [149.98515625000005, -2.491503906250003],
              [149.96162109375, -2.473828125000011],
              [150.1025390625, -2.404980468750011],
              [150.22714843750003, -2.384179687500009],
              [150.42949218750005, -2.470410156250011],
              [150.45, -2.51328125],
              [150.45156250000002, -2.541113281250006],
              [150.44609375000005, -2.63232421875],
              [150.43662109375003, -2.661816406250011],
            ],
          ],
          [
            [
              [150.52841796875003, -9.346582031250009],
              [150.66904296875003, -9.428515625],
              [150.74648437500002, -9.404492187500011],
              [150.78867187500003, -9.41796875],
              [150.8791015625, -9.5126953125],
              [150.88408203125005, -9.581933593750009],
              [150.8986328125, -9.64140625],
              [150.89404296875, -9.66748046875],
              [150.84404296875005, -9.702832031250011],
              [150.84824218750003, -9.66259765625],
              [150.80996093750002, -9.65478515625],
              [150.67832031250003, -9.656542968750003],
              [150.57626953125003, -9.631152343750003],
              [150.43623046875, -9.624609375],
              [150.4953125, -9.561718750000011],
              [150.50849609375, -9.5361328125],
              [150.43466796875003, -9.434960937500009],
              [150.43144531250005, -9.386621093750009],
              [150.43730468750005, -9.359960937500006],
              [150.49892578125002, -9.345605468750009],
              [150.52841796875003, -9.346582031250009],
            ],
          ],
          [
            [
              [152.63095703125003, -8.959375],
              [152.68925781250005, -8.974609375],
              [152.81005859375, -8.9671875],
              [152.8498046875, -9.024511718750006],
              [152.90507812500005, -9.044238281250003],
              [152.95292968750005, -9.070117187500003],
              [152.9953125, -9.1078125],
              [152.99501953125002, -9.130761718750009],
              [152.98496093750003, -9.15078125],
              [152.95927734375005, -9.168652343750011],
              [152.96689453125003, -9.208984375],
              [152.92275390625002, -9.203027343750009],
              [152.86748046875005, -9.224316406250011],
              [152.75947265625, -9.177148437500009],
              [152.72011718750002, -9.16650390625],
              [152.708203125, -9.126074218750006],
              [152.63808593750002, -9.058398437500003],
              [152.51513671875, -9.009863281250006],
              [152.57705078125002, -8.970019531250003],
              [152.63095703125003, -8.959375],
            ],
          ],
          [
            [
              [153.5361328125, -11.476171875],
              [153.70322265625003, -11.528515625000011],
              [153.75986328125003, -11.586328125],
              [153.69951171875005, -11.612597656250003],
              [153.5537109375, -11.630566406250011],
              [153.51923828125, -11.59521484375],
              [153.37900390625003, -11.5595703125],
              [153.35703125000003, -11.495019531250009],
              [153.28681640625, -11.516992187500009],
              [153.32236328125003, -11.471484375],
              [153.23447265625003, -11.4203125],
              [153.20703125, -11.351855468750003],
              [153.20361328125, -11.324121093750009],
              [153.30673828125003, -11.356347656250009],
              [153.5361328125, -11.476171875],
            ],
          ],
          [
            [
              [143.59033203125, -8.633398437500006],
              [143.60820312500005, -8.677148437500009],
              [143.46279296875002, -8.617089843750009],
              [143.32412109375002, -8.516796875000011],
              [143.25380859375002, -8.489550781250003],
              [143.20683593750005, -8.4234375],
              [143.29306640625003, -8.472753906250006],
              [143.443359375, -8.518945312500009],
              [143.59033203125, -8.633398437500006],
            ],
          ],
          [
            [
              [147.876953125, -2.283105468750009],
              [147.84453125000005, -2.335742187500003],
              [147.76894531250002, -2.33125],
              [147.735546875, -2.315527343750006],
              [147.790234375, -2.305566406250009],
              [147.81220703125, -2.262109375],
              [147.83583984375002, -2.246777343750011],
              [147.876953125, -2.283105468750009],
            ],
          ],
          [
            [
              [151.9572265625, -2.830175781250006],
              [151.93339843750005, -2.830371093750003],
              [151.92978515625003, -2.750585937500006],
              [151.94638671875003, -2.70859375],
              [152.001953125, -2.73779296875],
              [152.01132812500003, -2.809179687500006],
              [151.9572265625, -2.830175781250006],
            ],
          ],
          [
            [
              [150.89873046875005, -10.565332031250009],
              [150.88466796875002, -10.643457031250009],
              [150.80234375000003, -10.620214843750006],
              [150.78574218750003, -10.603417968750009],
              [150.79931640625, -10.554101562500009],
              [150.8720703125, -10.551855468750006],
              [150.89873046875005, -10.565332031250009],
            ],
          ],
          [
            [
              [153.65927734375003, -4.099316406250011],
              [153.65009765625, -4.123046875],
              [153.59150390625, -4.095996093750003],
              [153.63974609375003, -4.044726562500003],
              [153.66298828125002, -4.041210937500011],
              [153.65927734375003, -4.099316406250011],
            ],
          ],
          [
            [
              [155.95761718750003, -6.686816406250003],
              [155.93320312500003, -6.780468750000011],
              [155.91494140625002, -6.796679687500003],
              [155.89189453125005, -6.761523437500003],
              [155.80498046875005, -6.795605468750011],
              [155.76347656250005, -6.834375],
              [155.71933593750003, -6.86279296875],
              [155.6173828125, -6.85595703125],
              [155.52089843750002, -6.830273437500011],
              [155.42734375000003, -6.78271484375],
              [155.34404296875005, -6.7216796875],
              [155.26054687500005, -6.626074218750006],
              [155.20859375000003, -6.52685546875],
              [155.23447265625003, -6.41162109375],
              [155.2021484375, -6.3076171875],
              [155.04462890625, -6.233691406250003],
              [155.01015625000002, -6.209765625],
              [154.94023437500005, -6.106152343750011],
              [154.8703125, -6.061425781250009],
              [154.78193359375, -5.970703125],
              [154.75927734375, -5.931347656250011],
              [154.72109375000002, -5.816503906250006],
              [154.708984375, -5.7470703125],
              [154.74111328125002, -5.5453125],
              [154.72929687500005, -5.444433593750006],
              [154.77265625, -5.4541015625],
              [154.81845703125003, -5.494042968750009],
              [154.87050781250002, -5.521386718750009],
              [154.9970703125, -5.539941406250009],
              [155.09384765625003, -5.620214843750006],
              [155.18671875, -5.776953125],
              [155.19785156250003, -5.828320312500011],
              [155.2275390625, -5.865234375],
              [155.32304687500005, -5.931738281250006],
              [155.37255859375, -5.974414062500003],
              [155.4669921875, -6.145117187500006],
              [155.51933593750005, -6.181542968750009],
              [155.5810546875, -6.196191406250009],
              [155.63847656250005, -6.220800781250006],
              [155.73417968750005, -6.295703125],
              [155.82255859375005, -6.38046875],
              [155.88222656250002, -6.469628906250009],
              [155.92763671875002, -6.565039062500006],
              [155.95761718750003, -6.686816406250003],
            ],
          ],
          [
            [
              [154.64726562500005, -5.432714843750006],
              [154.62734375000002, -5.440625],
              [154.58388671875002, -5.314453125],
              [154.576171875, -5.220898437500011],
              [154.56279296875005, -5.151953125],
              [154.5400390625, -5.11083984375],
              [154.60556640625003, -5.034960937500003],
              [154.63261718750005, -5.013867187500011],
              [154.68203125000002, -5.054003906250003],
              [154.68916015625, -5.142675781250006],
              [154.72714843750003, -5.218066406250003],
              [154.6984375, -5.3828125],
              [154.64726562500005, -5.432714843750006],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 6,
        sovereignt: "Fiji",
        sov_a3: "FJI",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Fiji",
        adm0_a3: "FJI",
        geou_dif: 0,
        geounit: "Fiji",
        gu_a3: "FJI",
        su_dif: 0,
        subunit: "Fiji",
        su_a3: "FJI",
        brk_diff: 0,
        name: "Fiji",
        name_long: "Fiji",
        brk_a3: "FJI",
        brk_name: "Fiji",
        brk_group: null,
        abbrev: "Fiji",
        postal: "FJ",
        formal_en: "Republic of Fiji",
        formal_fr: null,
        name_ciawf: "Fiji",
        note_adm0: null,
        note_brk: null,
        name_sort: "Fiji",
        name_alt: null,
        mapcolor7: 5,
        mapcolor8: 1,
        mapcolor9: 2,
        mapcolor13: 2,
        pop_est: 889953,
        pop_rank: 11,
        pop_year: 2019,
        gdp_md: 5496,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        fips_10: "FJ",
        iso_a2: "FJ",
        iso_a2_eh: "FJ",
        iso_a3: "FJI",
        iso_a3_eh: "FJI",
        iso_n3: "242",
        iso_n3_eh: "242",
        un_a3: "242",
        wb_a2: "FJ",
        wb_a3: "FJI",
        woe_id: 23424813,
        woe_id_eh: 23424813,
        woe_note: "Exact WOE match as country",
        adm0_iso: "FJI",
        adm0_diff: null,
        adm0_tlc: "FJI",
        adm0_a3_us: "FJI",
        adm0_a3_fr: "FJI",
        adm0_a3_ru: "FJI",
        adm0_a3_es: "FJI",
        adm0_a3_cn: "FJI",
        adm0_a3_tw: "FJI",
        adm0_a3_in: "FJI",
        adm0_a3_np: "FJI",
        adm0_a3_pk: "FJI",
        adm0_a3_de: "FJI",
        adm0_a3_gb: "FJI",
        adm0_a3_br: "FJI",
        adm0_a3_il: "FJI",
        adm0_a3_ps: "FJI",
        adm0_a3_sa: "FJI",
        adm0_a3_eg: "FJI",
        adm0_a3_ma: "FJI",
        adm0_a3_pt: "FJI",
        adm0_a3_ar: "FJI",
        adm0_a3_jp: "FJI",
        adm0_a3_ko: "FJI",
        adm0_a3_vn: "FJI",
        adm0_a3_tr: "FJI",
        adm0_a3_id: "FJI",
        adm0_a3_pl: "FJI",
        adm0_a3_gr: "FJI",
        adm0_a3_it: "FJI",
        adm0_a3_nl: "FJI",
        adm0_a3_se: "FJI",
        adm0_a3_bd: "FJI",
        adm0_a3_ua: "FJI",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Oceania",
        region_un: "Oceania",
        subregion: "Melanesia",
        region_wb: "East Asia & Pacific",
        name_len: 4,
        long_len: 4,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: 177.975427,
        label_y: -17.826099,
        ne_id: 1159320625,
        wikidataid: "Q712",
        name_ar: "فيجي",
        name_bn: "ফিজি",
        name_de: "Fidschi",
        name_en: "Fiji",
        name_es: "Fiyi",
        name_fa: "فیجی",
        name_fr: "Fidji",
        name_el: "Φίτζι",
        name_he: "פיג'י",
        name_hi: "फ़िजी",
        name_hu: "Fidzsi-szigetek",
        name_id: "Fiji",
        name_it: "Figi",
        name_ja: "フィジー",
        name_ko: "피지",
        name_nl: "Fiji",
        name_pl: "Fidżi",
        name_pt: "Fiji",
        name_ru: "Фиджи",
        name_sv: "Fiji",
        name_tr: "Fiji",
        name_uk: "Фіджі",
        name_ur: "فجی",
        name_vi: "Fiji",
        name_zh: "斐济",
        name_zht: "斐濟",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "FJI.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [179.99921875, -16.168554687500006],
              [179.84824218750003, -16.30166015625001],
              [179.79384765625002, -16.37031250000001],
              [179.74814453125003, -16.4462890625],
              [179.619140625, -16.527734375],
              [179.56416015625, -16.63691406250001],
              [179.56816406250005, -16.74746093750001],
              [179.69707031250005, -16.631933593750006],
              [179.841015625, -16.5375],
              [179.8849609375, -16.51845703125001],
              [179.93037109375, -16.51943359375001],
              [179.92656250000005, -16.55166015625001],
              [179.90595703125, -16.58359375],
              [179.89003906250002, -16.6669921875],
              [179.9279296875, -16.744433593750003],
              [179.82080078125, -16.736914062500006],
              [179.71474609375002, -16.74355468750001],
              [179.58896484375003, -16.78701171875001],
              [179.46542968750003, -16.80605468750001],
              [179.41933593750002, -16.80654296875001],
              [179.375, -16.7919921875],
              [179.34599609375005, -16.76972656250001],
              [179.32333984375003, -16.718066406250003],
              [179.30048828125, -16.71035156250001],
              [179.20234375, -16.712695312500003],
              [179.05546875000005, -16.813574218750006],
              [179.0068359375, -16.900195312500003],
              [178.950390625, -16.90400390625001],
              [178.88369140625002, -16.886035156250003],
              [178.8029296875, -16.9521484375],
              [178.70664062500003, -16.976171875],
              [178.6650390625, -16.920019531250006],
              [178.63808593750002, -16.85126953125001],
              [178.6037109375, -16.800585937500003],
              [178.49746093750002, -16.787890625],
              [178.51376953125003, -16.72607421875],
              [178.5419921875, -16.700488281250003],
              [178.56777343750002, -16.663867187500003],
              [178.58359375000003, -16.621875],
              [178.63427734375, -16.648535156250006],
              [178.68632812500005, -16.665625],
              [178.74453125000002, -16.63417968750001],
              [178.80507812500002, -16.631445312500006],
              [178.86572265625, -16.5400390625],
              [178.96054687500003, -16.4828125],
              [179.09140625000003, -16.4375],
              [179.224609375, -16.40517578125001],
              [179.29355468750003, -16.39863281250001],
              [179.35917968750005, -16.3798828125],
              [179.47509765625, -16.29414062500001],
              [179.5517578125, -16.24990234375001],
              [179.63525390625, -16.223242187500006],
              [179.7150390625, -16.207617187500006],
              [179.78886718750005, -16.221484375],
              [179.84814453125, -16.21425781250001],
              [180, -16.152929687500006],
              [179.99921875, -16.168554687500006],
            ],
          ],
          [
            [
              [178.28017578125002, -17.37197265625001],
              [178.28017578125002, -17.41621093750001],
              [178.30947265625002, -17.435351562500003],
              [178.33857421875, -17.4384765625],
              [178.41093750000005, -17.523046875],
              [178.52324218750005, -17.595800781250006],
              [178.59160156250005, -17.651464843750006],
              [178.595703125, -17.699023437500003],
              [178.57490234375, -17.749316406250003],
              [178.60380859375005, -17.83935546875],
              [178.61787109375, -17.93281250000001],
              [178.66767578125, -18.080859375],
              [178.59736328125, -18.108984375],
              [178.48671875000002, -18.1123046875],
              [178.4611328125, -18.138964843750003],
              [178.42343750000003, -18.12421875000001],
              [178.33154296875, -18.13525390625],
              [178.24375, -18.183984375],
              [178.16015625, -18.25019531250001],
              [178.06396484375, -18.250390625],
              [177.95546875000002, -18.2640625],
              [177.84707031250002, -18.2548828125],
              [177.77080078125005, -18.21953125],
              [177.63642578125, -18.18105468750001],
              [177.45732421875005, -18.148242187500003],
              [177.38320312500002, -18.120703125],
              [177.32138671875003, -18.07753906250001],
              [177.26347656250005, -17.96865234375001],
              [177.2548828125, -17.91494140625001],
              [177.26396484375005, -17.86347656250001],
              [177.31630859375002, -17.84609375],
              [177.36015625000005, -17.82001953125001],
              [177.36640625, -17.78603515625001],
              [177.3857421875, -17.762304687500006],
              [177.41093750000005, -17.753710937500003],
              [177.42324218750002, -17.7373046875],
              [177.40556640625005, -17.68212890625],
              [177.40068359375005, -17.631640625],
              [177.50449218750003, -17.53955078125],
              [177.61796875000005, -17.461035156250006],
              [177.81796875000003, -17.388476562500003],
              [177.94023437500005, -17.395117187500006],
              [178.12763671875, -17.33925781250001],
              [178.18759765625003, -17.31298828125],
              [178.24716796875003, -17.3291015625],
              [178.28017578125002, -17.37197265625001],
            ],
          ],
          [
            [
              [-179.97490234375, -16.9248046875],
              [-180, -16.962988281250006],
              [-180, -16.9078125],
              [-179.999951171875, -16.85878906250001],
              [-180, -16.824316406250006],
              [-180, -16.785546875],
              [-179.893603515625, -16.70039062500001],
              [-179.860986328125, -16.68828125],
              [-179.822314453125, -16.76533203125001],
              [-179.8677734375, -16.85029296875001],
              [-179.97490234375, -16.9248046875],
            ],
          ],
          [
            [
              [180, -16.96308593750001],
              [179.92587890625003, -17.000292968750003],
              [179.89697265625, -16.96406250000001],
              [179.93095703125005, -16.8759765625],
              [180, -16.785742187500006],
              [179.99921875, -16.85878906250001],
              [180, -16.96308593750001],
            ],
          ],
          [
            [
              [178.48789062500003, -18.97412109375],
              [178.48769531250002, -19.01708984375],
              [178.35898437500003, -19.04560546875001],
              [178.315625, -19.01015625],
              [178.28798828125002, -19.003710937500003],
              [178.21132812500002, -19.066503906250006],
              [178.18916015625, -19.09228515625],
              [178.1818359375, -19.11171875],
              [178.162109375, -19.121484375],
              [178.02080078125005, -19.151660156250003],
              [177.95869140625, -19.12158203125],
              [178.00078125000005, -19.10107421875],
              [178.051953125, -19.06015625],
              [178.10410156250003, -19.066210937500003],
              [178.15664062500002, -19.027929687500006],
              [178.20839843750002, -18.96962890625001],
              [178.2822265625, -18.95703125],
              [178.33427734375005, -18.93447265625001],
              [178.42031250000002, -18.95078125],
              [178.48789062500003, -18.97412109375],
            ],
          ],
          [
            [
              [-178.9880859375, -17.976660156250006],
              [-179.018408203125, -17.991796875],
              [-179.039208984375, -17.988378906250006],
              [-179.063818359375, -17.97236328125001],
              [-179.07900390625, -17.944140625],
              [-179.047607421875, -17.92041015625],
              [-178.99912109375, -17.947363281250006],
              [-178.9880859375, -17.976660156250006],
            ],
          ],
          [
            [
              [-178.7619140625, -18.23388671875],
              [-178.7736328125, -18.25244140625],
              [-178.82734375, -18.22216796875],
              [-178.847900390625, -18.20205078125001],
              [-178.790869140625, -18.186328125],
              [-178.7630859375, -18.19140625],
              [-178.7619140625, -18.23388671875],
            ],
          ],
          [
            [
              [-178.251123046875, -17.952734375],
              [-178.3068359375, -17.96328125],
              [-178.3572265625, -17.9208984375],
              [-178.325390625, -17.87578125],
              [-178.280322265625, -17.88642578125001],
              [-178.25458984375, -17.929980468750003],
              [-178.251123046875, -17.952734375],
            ],
          ],
          [
            [
              [-178.956494140625, -17.27285156250001],
              [-178.9818359375, -17.30703125],
              [-179.00390625, -17.294921875],
              [-178.975537109375, -17.2375],
              [-178.971484375, -17.212695312500003],
              [-179.01494140625, -17.182421875],
              [-179.01767578125, -17.16132812500001],
              [-179.005029296875, -17.14833984375001],
              [-178.95283203125, -17.18203125],
              [-178.921142578125, -17.20839843750001],
              [-178.91484375, -17.223046875],
              [-178.924560546875, -17.248632812500006],
              [-178.956494140625, -17.27285156250001],
            ],
          ],
          [
            [
              [-178.535107421875, -19.166015625],
              [-178.546337890625, -19.175],
              [-178.57373046875, -19.16494140625001],
              [-178.595947265625, -19.1513671875],
              [-178.598681640625, -19.137109375],
              [-178.589306640625, -19.11884765625001],
              [-178.56767578125, -19.10927734375001],
              [-178.556689453125, -19.11298828125001],
              [-178.56298828125, -19.11875],
              [-178.576171875, -19.12519531250001],
              [-178.574072265625, -19.143164062500006],
              [-178.55712890625, -19.154101562500003],
              [-178.540625, -19.15703125],
              [-178.535107421875, -19.166015625],
            ],
          ],
          [
            [
              [-179.799853515625, -18.94033203125001],
              [-179.797607421875, -18.969824218750006],
              [-179.812451171875, -18.96816406250001],
              [-179.830224609375, -18.95556640625],
              [-179.83935546875, -18.96171875],
              [-179.8455078125, -18.970800781250006],
              [-179.848583984375, -18.991308593750006],
              [-179.851220703125, -19.0029296875],
              [-179.8650390625, -18.99873046875001],
              [-179.867333984375, -18.97841796875001],
              [-179.86279296875, -18.964160156250003],
              [-179.856201171875, -18.94326171875001],
              [-179.831103515625, -18.92421875],
              [-179.799853515625, -18.94033203125001],
            ],
          ],
          [
            [
              [177.23417968750005, -17.147070312500006],
              [177.1828125, -17.163867187500003],
              [177.21015625, -17.084277343750003],
              [177.2392578125, -17.059375],
              [177.25751953125, -17.05419921875],
              [177.28740234375005, -17.048632812500003],
              [177.27578125000002, -17.10488281250001],
              [177.23417968750005, -17.147070312500006],
            ],
          ],
          [
            [
              [179.42236328125, -17.366796875],
              [179.38896484375005, -17.393847656250003],
              [179.37314453125003, -17.256152343750003],
              [179.40761718750002, -17.25732421875],
              [179.4328125, -17.271582031250006],
              [179.44716796875002, -17.30625],
              [179.42236328125, -17.366796875],
            ],
          ],
          [
            [
              [179.34931640625, -18.10234375],
              [179.34042968750003, -18.110449218750006],
              [179.25351562500003, -18.030566406250003],
              [179.25644531250003, -17.9990234375],
              [179.27177734375005, -17.970703125],
              [179.30644531250005, -17.94404296875001],
              [179.337890625, -17.989550781250003],
              [179.36240234375003, -18.065234375],
              [179.34931640625, -18.10234375],
            ],
          ],
          [
            [
              [178.82753906250002, -17.72900390625],
              [178.77607421875, -17.74677734375001],
              [178.74765625000003, -17.68574218750001],
              [178.787109375, -17.62441406250001],
              [178.8310546875, -17.61884765625001],
              [178.8525390625, -17.68125],
              [178.82753906250002, -17.72900390625],
            ],
          ],
          [
            [
              [-179.929443359375, -16.50283203125001],
              [-179.999951171875, -16.5400390625],
              [-179.999951171875, -16.488867187500006],
              [-179.94365234375, -16.44140625],
              [-179.900927734375, -16.43154296875001],
              [-179.92734375, -16.479101562500006],
              [-179.929443359375, -16.50283203125001],
            ],
          ],
          [
            [
              [-179.95615234375, -16.14921875],
              [-180, -16.168261718750003],
              [-180, -16.156054687500003],
              [-180, -16.152929687500006],
              [-179.969384765625, -16.126074218750006],
              [-179.944580078125, -16.126074218750006],
              [-179.95615234375, -16.14921875],
            ],
          ],
          [
            [
              [180, -16.5400390625],
              [179.98720703125002, -16.54121093750001],
              [179.98466796875005, -16.52216796875001],
              [180, -16.488867187500006],
              [180, -16.5400390625],
            ],
          ],
          [
            [
              [177.12148437500002, -12.50546875],
              [177.08242187500002, -12.515625],
              [177.01933593750005, -12.50732421875],
              [177.00625, -12.491113281250009],
              [177.0263671875, -12.4875],
              [177.067578125, -12.476953125],
              [177.11806640625002, -12.482324218750009],
              [177.126953125, -12.492871093750011],
              [177.12148437500002, -12.50546875],
            ],
          ],
          [
            [
              [174.62968750000005, -21.69501953125001],
              [174.621875, -21.705859375],
              [174.59296875, -21.70234375000001],
              [174.58720703125005, -21.680078125],
              [174.60419921875, -21.66748046875],
              [174.62773437500005, -21.67597656250001],
              [174.62968750000005, -21.69501953125001],
            ],
          ],
          [
            [
              [-178.71162109375, -20.667773437500003],
              [-178.709521484375, -20.670507812500006],
              [-178.71494140625, -20.6703125],
              [-178.723095703125, -20.666796875],
              [-178.7291015625, -20.66015625],
              [-178.73056640625, -20.65283203125],
              [-178.7275390625, -20.64521484375001],
              [-178.724560546875, -20.64570312500001],
              [-178.719189453125, -20.65234375],
              [-178.714208984375, -20.659765625],
              [-178.71162109375, -20.667773437500003],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 3,
        sovereignt: "France",
        sov_a3: "FR1",
        adm0_dif: 1,
        level: 2,
        type: "Dependency",
        tlc: "1",
        admin: "New Caledonia",
        adm0_a3: "NCL",
        geou_dif: 0,
        geounit: "New Caledonia",
        gu_a3: "NCL",
        su_dif: 0,
        subunit: "New Caledonia",
        su_a3: "NCL",
        brk_diff: 0,
        name: "New Caledonia",
        name_long: "New Caledonia",
        brk_a3: "NCL",
        brk_name: "New Caledonia",
        brk_group: null,
        abbrev: "New C.",
        postal: "NC",
        formal_en: "New Caledonia",
        formal_fr: "Nouvelle-Calédonie",
        name_ciawf: "New Caledonia",
        note_adm0: "Fr.",
        note_brk: null,
        name_sort: "New Caledonia",
        name_alt: null,
        mapcolor7: 7,
        mapcolor8: 5,
        mapcolor9: 9,
        mapcolor13: 11,
        pop_est: 287800,
        pop_rank: 10,
        pop_year: 2019,
        gdp_md: 10770,
        gdp_year: 2016,
        economy: "6. Developing region",
        income_grp: "2. High income: nonOECD",
        fips_10: "NC",
        iso_a2: "NC",
        iso_a2_eh: "NC",
        iso_a3: "NCL",
        iso_a3_eh: "NCL",
        iso_n3: "540",
        iso_n3_eh: "540",
        un_a3: "540",
        wb_a2: "NC",
        wb_a3: "NCL",
        woe_id: 23424903,
        woe_id_eh: 23424903,
        woe_note: "Exact WOE match as country",
        adm0_iso: "NCL",
        adm0_diff: null,
        adm0_tlc: "NCL",
        adm0_a3_us: "NCL",
        adm0_a3_fr: "NCL",
        adm0_a3_ru: "NCL",
        adm0_a3_es: "NCL",
        adm0_a3_cn: "NCL",
        adm0_a3_tw: "NCL",
        adm0_a3_in: "NCL",
        adm0_a3_np: "NCL",
        adm0_a3_pk: "NCL",
        adm0_a3_de: "NCL",
        adm0_a3_gb: "NCL",
        adm0_a3_br: "NCL",
        adm0_a3_il: "NCL",
        adm0_a3_ps: "NCL",
        adm0_a3_sa: "NCL",
        adm0_a3_eg: "NCL",
        adm0_a3_ma: "NCL",
        adm0_a3_pt: "NCL",
        adm0_a3_ar: "NCL",
        adm0_a3_jp: "NCL",
        adm0_a3_ko: "NCL",
        adm0_a3_vn: "NCL",
        adm0_a3_tr: "NCL",
        adm0_a3_id: "NCL",
        adm0_a3_pl: "NCL",
        adm0_a3_gr: "NCL",
        adm0_a3_it: "NCL",
        adm0_a3_nl: "NCL",
        adm0_a3_se: "NCL",
        adm0_a3_bd: "NCL",
        adm0_a3_ua: "NCL",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Oceania",
        region_un: "Oceania",
        subregion: "Melanesia",
        region_wb: "East Asia & Pacific",
        name_len: 13,
        long_len: 13,
        abbrev_len: 6,
        tiny: -99,
        homepart: -99,
        min_zoom: 0,
        min_label: 4.6,
        max_label: 8,
        label_x: 165.084004,
        label_y: -21.064697,
        ne_id: 1159320641,
        wikidataid: "Q33788",
        name_ar: "كاليدونيا الجديدة",
        name_bn: "নতুন ক্যালিডোনিয়া",
        name_de: "Neukaledonien",
        name_en: "New Caledonia",
        name_es: "Nueva Caledonia",
        name_fa: "کالدونیای جدید",
        name_fr: "Nouvelle-Calédonie",
        name_el: "Νέα Καληδονία",
        name_he: "קלדוניה החדשה",
        name_hi: "नया कैलेडोनिया",
        name_hu: "Új-Kaledónia",
        name_id: "Kaledonia Baru",
        name_it: "Nuova Caledonia",
        name_ja: "ニューカレドニア",
        name_ko: "누벨칼레도니",
        name_nl: "Nieuw-Caledonië",
        name_pl: "Nowa Kaledonia",
        name_pt: "Nova Caledónia",
        name_ru: "Новая Каледония",
        name_sv: "Nya Kaledonien",
        name_tr: "Yeni Kaledonya",
        name_uk: "Нова Каледонія",
        name_ur: "نیو کیلیڈونیا",
        name_vi: "Nouvelle-Calédonie",
        name_zh: "新喀里多尼亚",
        name_zht: "新喀里多尼亞",
        fclass_iso: "Admin-0 dependency",
        tlc_diff: null,
        fclass_tlc: "Admin-0 dependency",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "NCL.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [164.20234375, -20.24609375],
              [164.31513671875, -20.308886718750003],
              [164.43593750000002, -20.2822265625],
              [164.5880859375, -20.381152343750003],
              [164.97568359375003, -20.68105468750001],
              [165.11191406250003, -20.74453125],
              [165.19179687500002, -20.768847656250003],
              [165.25234375000002, -20.81796875],
              [165.306640625, -20.887011718750003],
              [165.38056640625, -20.935839843750003],
              [165.4125, -20.98134765625001],
              [165.42050781250003, -21.042773437500003],
              [165.44716796875002, -21.08056640625],
              [165.58242187500002, -21.179980468750003],
              [165.66279296875, -21.2671875],
              [165.774609375, -21.31171875000001],
              [165.82285156250003, -21.36376953125],
              [165.88535156250003, -21.38916015625],
              [165.94951171875005, -21.4423828125],
              [166.0578125, -21.48388671875],
              [166.30332031250003, -21.63720703125],
              [166.49296875000005, -21.7828125],
              [166.5875, -21.872851562500003],
              [166.6896484375, -21.95302734375001],
              [166.82011718750005, -22.01699218750001],
              [166.9423828125, -22.090136718750003],
              [167.00429687500002, -22.261523437500003],
              [166.97031250000003, -22.322851562500006],
              [166.9, -22.353320312500003],
              [166.8349609375, -22.35546875],
              [166.77412109375, -22.37617187500001],
              [166.57060546875005, -22.26552734375001],
              [166.52216796875, -22.24921875000001],
              [166.46796875, -22.25605468750001],
              [166.4376953125, -22.231542968750006],
              [166.41640625000002, -22.19619140625001],
              [166.29228515625005, -22.155078125],
              [166.17666015625002, -22.089160156250003],
              [166.14316406250003, -22.04443359375],
              [166.12373046875, -21.98876953125],
              [166.09609375000002, -21.956640625],
              [165.93300781250002, -21.908007812500003],
              [165.8234375, -21.853808593750003],
              [165.74384765625, -21.77734375],
              [165.62021484375003, -21.72421875],
              [165.42763671875002, -21.615039062500003],
              [165.32861328125, -21.580078125],
              [165.24199218750005, -21.525488281250006],
              [165.01015625000002, -21.32685546875001],
              [164.92744140625, -21.28984375],
              [164.85527343750005, -21.2015625],
              [164.65566406250002, -20.99208984375001],
              [164.55947265625002, -20.905859375],
              [164.45468750000003, -20.8291015625],
              [164.37451171875, -20.7392578125],
              [164.31289062500002, -20.63271484375001],
              [164.16972656250005, -20.48017578125001],
              [164.15214843750005, -20.41494140625001],
              [164.15810546875002, -20.347949218750003],
              [164.12363281250003, -20.30488281250001],
              [164.06503906250003, -20.278613281250003],
              [164.0373046875, -20.23359375000001],
              [164.04052734375, -20.1728515625],
              [164.05966796875003, -20.14150390625001],
              [164.20234375, -20.24609375],
            ],
          ],
          [
            [
              [167.54443359375, -22.62324218750001],
              [167.5126953125, -22.6611328125],
              [167.47343750000005, -22.6533203125],
              [167.44375, -22.63916015625],
              [167.4220703125, -22.61855468750001],
              [167.44345703125003, -22.54140625],
              [167.5294921875, -22.579199218750006],
              [167.54443359375, -22.62324218750001],
            ],
          ],
          [
            [
              [159.95175781250003, -19.31171875000001],
              [159.93642578125002, -19.333105468750006],
              [159.92822265625, -19.17431640625],
              [159.95986328125002, -19.11464843750001],
              [159.97509765625, -19.23828125],
              [159.95175781250003, -19.31171875000001],
            ],
          ],
          [
            [
              [168.0109375, -21.429980468750003],
              [168.05791015625005, -21.44843750000001],
              [168.13906250000002, -21.44521484375001],
              [168.12070312500003, -21.615820312500006],
              [168.00644531250003, -21.643164062500006],
              [167.966796875, -21.6416015625],
              [167.94130859375002, -21.605761718750003],
              [167.87587890625002, -21.582128906250006],
              [167.8791015625, -21.52363281250001],
              [167.8154296875, -21.392675781250006],
              [167.9259765625, -21.372851562500003],
              [167.9884765625, -21.337890625],
              [167.98496093750003, -21.369726562500006],
              [167.99462890625, -21.40693359375001],
              [168.0109375, -21.429980468750003],
            ],
          ],
          [
            [
              [166.54677734375002, -20.69873046875],
              [166.49355468750002, -20.70859375],
              [166.5578125, -20.61708984375001],
              [166.55966796875003, -20.561132812500006],
              [166.58544921875, -20.450488281250003],
              [166.58251953125, -20.413378906250003],
              [166.62470703125, -20.418261718750003],
              [166.67080078125002, -20.4501953125],
              [166.61787109375, -20.4775390625],
              [166.60029296875, -20.525390625],
              [166.60214843750003, -20.58535156250001],
              [166.62255859375, -20.596289062500006],
              [166.5888671875, -20.661914062500003],
              [166.54677734375002, -20.69873046875],
            ],
          ],
          [
            [
              [167.40087890625, -21.16064453125],
              [167.34619140625, -21.16875],
              [167.27324218750005, -21.096777343750006],
              [167.13388671875003, -21.060644531250006],
              [167.07265625000002, -20.99726562500001],
              [167.03271484375, -20.92255859375001],
              [167.11171875000002, -20.904101562500003],
              [167.189453125, -20.803515625],
              [167.13642578125, -20.76611328125],
              [167.04501953125003, -20.75947265625001],
              [167.05576171875003, -20.72021484375],
              [167.20400390625002, -20.67353515625001],
              [167.26894531250002, -20.70058593750001],
              [167.29794921875003, -20.732519531250006],
              [167.29345703125, -20.89150390625001],
              [167.36083984375, -20.94208984375001],
              [167.43056640625002, -21.055273437500006],
              [167.43027343750003, -21.087011718750006],
              [167.40087890625, -21.16064453125],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 2,
        sovereignt: "New Zealand",
        sov_a3: "NZ1",
        adm0_dif: 1,
        level: 2,
        type: "Country",
        tlc: "1",
        admin: "New Zealand",
        adm0_a3: "NZL",
        geou_dif: 0,
        geounit: "New Zealand",
        gu_a3: "NZL",
        su_dif: 0,
        subunit: "New Zealand",
        su_a3: "NZL",
        brk_diff: 0,
        name: "New Zealand",
        name_long: "New Zealand",
        brk_a3: "NZL",
        brk_name: "New Zealand",
        brk_group: null,
        abbrev: "N.Z.",
        postal: "NZ",
        formal_en: "New Zealand",
        formal_fr: null,
        name_ciawf: "New Zealand",
        note_adm0: null,
        note_brk: null,
        name_sort: "New Zealand",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 3,
        mapcolor9: 4,
        mapcolor13: 4,
        pop_est: 4917000,
        pop_rank: 12,
        pop_year: 2019,
        gdp_md: 206928,
        gdp_year: 2019,
        economy: "2. Developed region: nonG7",
        income_grp: "1. High income: OECD",
        fips_10: "NZ",
        iso_a2: "NZ",
        iso_a2_eh: "NZ",
        iso_a3: "NZL",
        iso_a3_eh: "NZL",
        iso_n3: "554",
        iso_n3_eh: "554",
        un_a3: "554",
        wb_a2: "NZ",
        wb_a3: "NZL",
        woe_id: 23424916,
        woe_id_eh: 23424916,
        woe_note: "Exact WOE match as country",
        adm0_iso: "NZL",
        adm0_diff: null,
        adm0_tlc: "NZL",
        adm0_a3_us: "NZL",
        adm0_a3_fr: "NZL",
        adm0_a3_ru: "NZL",
        adm0_a3_es: "NZL",
        adm0_a3_cn: "NZL",
        adm0_a3_tw: "NZL",
        adm0_a3_in: "NZL",
        adm0_a3_np: "NZL",
        adm0_a3_pk: "NZL",
        adm0_a3_de: "NZL",
        adm0_a3_gb: "NZL",
        adm0_a3_br: "NZL",
        adm0_a3_il: "NZL",
        adm0_a3_ps: "NZL",
        adm0_a3_sa: "NZL",
        adm0_a3_eg: "NZL",
        adm0_a3_ma: "NZL",
        adm0_a3_pt: "NZL",
        adm0_a3_ar: "NZL",
        adm0_a3_jp: "NZL",
        adm0_a3_ko: "NZL",
        adm0_a3_vn: "NZL",
        adm0_a3_tr: "NZL",
        adm0_a3_id: "NZL",
        adm0_a3_pl: "NZL",
        adm0_a3_gr: "NZL",
        adm0_a3_it: "NZL",
        adm0_a3_nl: "NZL",
        adm0_a3_se: "NZL",
        adm0_a3_bd: "NZL",
        adm0_a3_ua: "NZL",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Oceania",
        region_un: "Oceania",
        subregion: "Australia and New Zealand",
        region_wb: "East Asia & Pacific",
        name_len: 11,
        long_len: 11,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 2,
        max_label: 6.7,
        label_x: 172.787,
        label_y: -39.759,
        ne_id: 1159321135,
        wikidataid: "Q664",
        name_ar: "نيوزيلندا",
        name_bn: "নিউজিল্যান্ড",
        name_de: "Neuseeland",
        name_en: "New Zealand",
        name_es: "Nueva Zelanda",
        name_fa: "نیوزیلند",
        name_fr: "Nouvelle-Zélande",
        name_el: "Νέα Ζηλανδία",
        name_he: "ניו זילנד",
        name_hi: "न्यूज़ीलैण्ड",
        name_hu: "Új-Zéland",
        name_id: "Selandia Baru",
        name_it: "Nuova Zelanda",
        name_ja: "ニュージーランド",
        name_ko: "뉴질랜드",
        name_nl: "Nieuw-Zeeland",
        name_pl: "Nowa Zelandia",
        name_pt: "Nova Zelândia",
        name_ru: "Новая Зеландия",
        name_sv: "Nya Zeeland",
        name_tr: "Yeni Zelanda",
        name_uk: "Нова Зеландія",
        name_ur: "نیوزی لینڈ",
        name_vi: "New Zealand",
        name_zh: "新西兰",
        name_zht: "新西蘭",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "NZL.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [173.11533203125003, -41.279296875],
              [173.23085937500002, -41.2841796875],
              [173.33779296875002, -41.2109375],
              [173.447265625, -41.1513671875],
              [173.5625, -41.10205078125],
              [173.73789062500003, -40.98896484375001],
              [173.78378906250003, -40.97236328125001],
              [173.89755859375003, -40.95078125],
              [173.94716796875002, -40.92412109375002],
              [174.00244140625, -40.91777343750002],
              [173.95283203125, -40.98486328125],
              [173.88984375, -41.007226562499994],
              [173.8798828125, -41.03144531250001],
              [173.91513671875003, -41.07011718750002],
              [173.8603515625, -41.124414062499994],
              [173.86240234375003, -41.19208984375001],
              [173.7978515625, -41.27197265625],
              [173.89707031250003, -41.239355468750006],
              [173.93339843750005, -41.18730468750002],
              [173.91464843750003, -41.15800781250002],
              [173.95761718750003, -41.09990234375002],
              [174.02402343750003, -41.072265625],
              [173.99755859375, -41.028125],
              [173.99941406250002, -40.993261718750006],
              [174.08056640625, -41.00615234375002],
              [174.12119140625003, -41.00468750000002],
              [174.15322265625002, -40.99091796875001],
              [174.21181640625002, -40.985449218750006],
              [174.223828125, -41.0244140625],
              [174.30253906250005, -41.01953125],
              [174.27392578125, -41.06875],
              [174.21367187500005, -41.125585937500006],
              [174.19951171875005, -41.16015625],
              [174.103125, -41.217382812500006],
              [174.03857421875, -41.24189453125001],
              [174.13808593750002, -41.24824218750001],
              [174.28359375000002, -41.17158203125001],
              [174.3701171875, -41.10371093750001],
              [174.36757812500002, -41.188378906249994],
              [174.29726562500002, -41.264257812500006],
              [174.23710937500005, -41.31220703125001],
              [174.16953125000003, -41.327050781249994],
              [174.10205078125, -41.36591796875001],
              [174.0693359375, -41.42949218750002],
              [174.07294921875, -41.4716796875],
              [174.09238281250003, -41.50517578125002],
              [174.1611328125, -41.56181640625002],
              [174.08369140625, -41.670800781249994],
              [174.169921875, -41.6572265625],
              [174.21708984375005, -41.677734375],
              [174.28310546875002, -41.740625],
              [174.243359375, -41.813085937500006],
              [174.21542968750003, -41.850195312500006],
              [174.04726562500002, -42.003027343750006],
              [173.97392578125005, -42.08056640625],
              [173.88798828125005, -42.13017578125002],
              [173.88916015625, -42.21162109375001],
              [173.83984375, -42.270898437499994],
              [173.58925781250002, -42.47392578125002],
              [173.5451171875, -42.51796875],
              [173.34755859375002, -42.8408203125],
              [173.22119140625, -42.9765625],
              [173.14882812500002, -43.02275390625002],
              [173.07236328125003, -43.06025390625001],
              [172.8888671875, -43.12421875000001],
              [172.80800781250002, -43.19775390625],
              [172.71855468750005, -43.2587890625],
              [172.6240234375, -43.2724609375],
              [172.626953125, -43.29951171875001],
              [172.6875, -43.31464843750001],
              [172.73476562500002, -43.35478515625002],
              [172.69970703125, -43.39970703125002],
              [172.63222656250002, -43.42792968750001],
              [172.56220703125, -43.43603515625],
              [172.52666015625005, -43.464746093749994],
              [172.69345703125003, -43.4443359375],
              [172.7404296875, -43.467871093750006],
              [172.74921875, -43.51728515625001],
              [172.7666015625, -43.561914062499994],
              [172.80703125000002, -43.620996093749994],
              [172.947265625, -43.65859375],
              [173.0732421875, -43.676171875],
              [173.09804687500002, -43.703515625],
              [173.11689453125, -43.7978515625],
              [173.0939453125, -43.844140625],
              [173.065625, -43.874609375],
              [173.02333984375002, -43.88544921875001],
              [172.92060546875, -43.89140625000002],
              [172.81767578125005, -43.8701171875],
              [172.74931640625005, -43.813085937500006],
              [172.5546875, -43.83134765625002],
              [172.50273437500005, -43.843652343749994],
              [172.47597656250002, -43.833398437499994],
              [172.58378906250005, -43.773535156250006],
              [172.52724609375002, -43.73945312500001],
              [172.48037109375002, -43.726660156250006],
              [172.4296875, -43.746484375],
              [172.39560546875003, -43.77783203125],
              [172.38525390625, -43.82958984375],
              [172.35039062500005, -43.859375],
              [172.29658203125, -43.86787109375001],
              [172.220703125, -43.825],
              [172.14580078125005, -43.763574218749994],
              [172.03554687500002, -43.701757812500006],
              [172.05224609375, -43.74003906250002],
              [172.13720703125, -43.83378906250002],
              [172.17978515625003, -43.89599609375],
              [172.08076171875, -43.94560546875002],
              [171.97763671875003, -43.984277343749994],
              [171.890625, -44.006933593750006],
              [171.80839843750005, -44.04228515625002],
              [171.71201171875003, -44.09746093750002],
              [171.65898437500005, -44.1171875],
              [171.5177734375, -44.11835937500001],
              [171.442578125, -44.135839843750006],
              [171.41748046875, -44.20869140625001],
              [171.36455078125005, -44.254980468750006],
              [171.24072265625, -44.26416015625],
              [171.2853515625, -44.278710937499994],
              [171.31298828125, -44.301855468750006],
              [171.23105468750003, -44.52119140625001],
              [171.2130859375, -44.612207031249994],
              [171.19785156250003, -44.76787109375002],
              [171.14628906250005, -44.91230468750001],
              [170.9990234375, -44.91142578125002],
              [171.02285156250002, -44.93701171875],
              [171.13417968750002, -44.97773437500001],
              [171.11328125, -45.03925781250001],
              [170.99072265625, -45.151464843750006],
              [170.9396484375, -45.21640625],
              [170.88994140625005, -45.373925781249994],
              [170.81523437500005, -45.519140625],
              [170.70058593750002, -45.68427734375001],
              [170.69970703125, -45.713964843750006],
              [170.73984375000003, -45.75605468750001],
              [170.78847656250002, -45.79248046875],
              [170.7912109375, -45.843847656250006],
              [170.77626953125002, -45.87089843750002],
              [170.72177734375003, -45.878027343750006],
              [170.67421875000002, -45.89570312500001],
              [170.419140625, -45.941015625],
              [170.33544921875, -45.991796875],
              [170.266796875, -46.082617187500006],
              [170.18613281250003, -46.16083984375001],
              [169.91826171875005, -46.334375],
              [169.7607421875, -46.47978515625002],
              [169.72910156250003, -46.521386718749994],
              [169.68662109375003, -46.551660156249994],
              [169.34228515625, -46.620507812499994],
              [169.0986328125, -46.63066406250002],
              [168.9658203125, -46.61298828125001],
              [168.83779296875002, -46.578222656250006],
              [168.766796875, -46.566308593749994],
              [168.63144531250003, -46.58759765625001],
              [168.572265625, -46.61103515625001],
              [168.46640625000003, -46.587890625],
              [168.38212890625005, -46.605371093749994],
              [168.35722656250005, -46.58837890625],
              [168.32568359375, -46.54570312500002],
              [168.34306640625005, -46.48906250000002],
              [168.31972656250002, -46.447167968749994],
              [168.26621093750003, -46.41875],
              [168.23027343750005, -46.3857421875],
              [168.18916015625, -46.362207031249994],
              [168.07734375, -46.352929687499994],
              [167.900390625, -46.367773437500006],
              [167.8419921875, -46.3662109375],
              [167.72207031250002, -46.227148437500006],
              [167.68222656250003, -46.19296875],
              [167.53945312500002, -46.148535156250006],
              [167.490625, -46.1546875],
              [167.4142578125, -46.22890625],
              [167.36894531250005, -46.24150390625002],
              [167.10029296875, -46.249414062499994],
              [166.83076171875, -46.225488281249994],
              [166.73154296875003, -46.197851562500006],
              [166.712109375, -46.133691406249994],
              [166.91669921875, -45.95722656250001],
              [166.8564453125, -45.980859375],
              [166.73027343750005, -46.052734375],
              [166.64990234375, -46.04169921875001],
              [166.72695312500002, -45.96328125],
              [166.73378906250002, -45.928320312500006],
              [166.71796875, -45.88935546875001],
              [166.61269531250002, -45.95537109375002],
              [166.4931640625, -45.9638671875],
              [166.47763671875003, -45.902734375],
              [166.48828125, -45.83183593750002],
              [166.512890625, -45.81171875000001],
              [166.83603515625003, -45.774511718750006],
              [166.95253906250002, -45.75019531250001],
              [167.00332031250002, -45.71210937500001],
              [166.80996093750002, -45.69902343750002],
              [166.79765625000005, -45.645605468750006],
              [166.82558593750002, -45.60283203125002],
              [166.99082031250003, -45.53173828125],
              [166.86904296875002, -45.549902343750006],
              [166.73398437500003, -45.543554687500006],
              [166.74306640625002, -45.46845703125001],
              [166.7783203125, -45.40966796875],
              [166.919921875, -45.40791015625001],
              [166.87558593750003, -45.367578125],
              [166.86923828125003, -45.31123046875001],
              [166.90859375000002, -45.30742187500002],
              [167.05214843750002, -45.383203125],
              [167.15566406250002, -45.41093750000002],
              [167.11210937500005, -45.35390625],
              [167.11777343750003, -45.31796875],
              [167.14531250000005, -45.301855468750006],
              [167.23007812500003, -45.29033203125002],
              [167.20683593750005, -45.2802734375],
              [167.12734375000002, -45.26582031250001],
              [167.03281250000003, -45.22246093750002],
              [167.02265625, -45.176660156249994],
              [167.02587890625, -45.123632812500006],
              [167.1279296875, -45.05078125],
              [167.18818359375, -45.094140625],
              [167.25947265625, -45.08222656250001],
              [167.205078125, -45.04814453125002],
              [167.171875, -44.9970703125],
              [167.19453125, -44.963476562500006],
              [167.41074218750003, -44.82792968750002],
              [167.46621093750002, -44.95830078125002],
              [167.47919921875, -44.9150390625],
              [167.48212890625, -44.873925781249994],
              [167.45625, -44.83828125],
              [167.4599609375, -44.80234375],
              [167.48496093750003, -44.771386718749994],
              [167.57763671875, -44.740820312500006],
              [167.69814453125002, -44.64130859375001],
              [167.78701171875002, -44.59501953125002],
              [167.859375, -44.62470703125001],
              [167.90898437500005, -44.66474609375001],
              [167.9015625, -44.625],
              [167.86640625, -44.59208984375002],
              [167.85654296875003, -44.50068359375001],
              [168.01835937500005, -44.358789062499994],
              [168.19619140625002, -44.2236328125],
              [168.36660156250002, -44.08203125],
              [168.45742187500002, -44.03056640625002],
              [168.65097656250003, -43.97216796875],
              [168.77480468750002, -43.996484375],
              [168.80644531250005, -43.99199218750002],
              [168.9904296875, -43.88994140625002],
              [169.06650390625003, -43.86347656250001],
              [169.1359375, -43.89990234375],
              [169.17890625, -43.9130859375],
              [169.1357421875, -43.81982421875],
              [169.16953125000003, -43.77705078125001],
              [169.32314453125002, -43.7015625],
              [169.51523437500003, -43.623632812500006],
              [169.66152343750002, -43.591210937499994],
              [169.76923828125, -43.538476562499994],
              [169.83388671875002, -43.537011718749994],
              [169.82402343750005, -43.497167968750006],
              [169.83505859375003, -43.458984375],
              [169.8908203125, -43.46162109375001],
              [169.90800781250005, -43.44658203125002],
              [169.85898437500003, -43.42597656250001],
              [170.017578125, -43.34941406250002],
              [170.1037109375, -43.265039062499994],
              [170.14882812500002, -43.24755859375],
              [170.1896484375, -43.222070312499994],
              [170.240234375, -43.16386718750002],
              [170.3, -43.144628906250006],
              [170.35576171875005, -43.15361328125002],
              [170.39609375000003, -43.182226562500006],
              [170.37431640625005, -43.134667968749994],
              [170.30283203125003, -43.10761718750001],
              [170.37949218750003, -43.06621093750002],
              [170.45869140625, -43.037695312500006],
              [170.53583984375, -43.058496093749994],
              [170.61181640625, -43.091796875],
              [170.53583984375, -43.04072265625001],
              [170.5236328125, -43.00898437500001],
              [170.61552734375005, -42.97246093750002],
              [170.66542968750002, -42.96123046875002],
              [170.73525390625002, -43.02978515625],
              [170.72529296875, -42.975488281249994],
              [170.74160156250002, -42.92734375],
              [170.84033203125, -42.8486328125],
              [170.969921875, -42.718359375],
              [171.01142578125, -42.763671875],
              [171.0177734375, -42.81875],
              [171.01171875, -42.88505859375002],
              [171.03837890625005, -42.86210937500002],
              [171.04755859375, -42.801855468750006],
              [171.02773437500002, -42.69609375000002],
              [171.18955078125003, -42.50048828125],
              [171.22128906250003, -42.478613281250006],
              [171.25703125, -42.46533203125],
              [171.31337890625002, -42.46015625000001],
              [171.29609375, -42.430566406249994],
              [171.25224609375005, -42.401953125],
              [171.29648437500003, -42.30253906250002],
              [171.32265625000002, -42.1890625],
              [171.36025390625002, -42.079980468749994],
              [171.42060546875, -41.973046875],
              [171.48623046875002, -41.79472656250002],
              [171.536328125, -41.75751953125001],
              [171.67216796875005, -41.744726562500006],
              [171.731640625, -41.719628906249994],
              [171.83066406250003, -41.655175781249994],
              [171.94804687500005, -41.538671875],
              [172.0107421875, -41.444726562499994],
              [172.09335937500003, -41.2015625],
              [172.13945312500005, -40.947265625],
              [172.27275390625005, -40.758691406249994],
              [172.46816406250002, -40.622167968750006],
              [172.640625, -40.51826171875001],
              [172.7111328125, -40.496679687500006],
              [172.83017578125003, -40.49003906250002],
              [172.94365234375005, -40.51875],
              [172.7326171875, -40.54375],
              [172.7111328125, -40.605371093749994],
              [172.70439453125005, -40.66777343750002],
              [172.72890625000002, -40.7236328125],
              [172.766796875, -40.7734375],
              [172.869140625, -40.8203125],
              [172.98867187500002, -40.848242187500006],
              [173.04228515625005, -40.95361328125],
              [173.05214843750002, -41.07861328125],
              [173.06865234375005, -41.18583984375002],
              [173.11533203125003, -41.279296875],
            ],
          ],
          [
            [
              [173.91464843750003, -40.863671875],
              [173.78085937500003, -40.921777343749994],
              [173.78623046875003, -40.881445312500006],
              [173.81240234375002, -40.79365234375001],
              [173.87333984375005, -40.74931640625002],
              [173.9033203125, -40.74628906250001],
              [173.96445312500003, -40.712988281250006],
              [173.9580078125, -40.78681640625001],
              [173.91464843750003, -40.863671875],
            ],
          ],
          [
            [
              [166.7462890625, -45.655859375],
              [166.74101562500005, -45.704980468749994],
              [166.72919921875, -45.72968750000001],
              [166.69453125, -45.729882812499994],
              [166.64248046875002, -45.72441406250002],
              [166.59169921875002, -45.701757812500006],
              [166.55917968750003, -45.70820312500001],
              [166.53203125000005, -45.699804687500006],
              [166.56708984375, -45.644433593749994],
              [166.68564453125003, -45.61503906250002],
              [166.7314453125, -45.638671875],
              [166.7462890625, -45.655859375],
            ],
          ],
          [
            [
              [166.9794921875, -45.1796875],
              [167.02265625, -45.2998046875],
              [166.93115234375, -45.27685546875],
              [166.89267578125003, -45.24052734375002],
              [166.96269531250005, -45.18037109375001],
              [166.9794921875, -45.1796875],
            ],
          ],
          [
            [
              [168.14492187500002, -46.862207031249994],
              [168.14531250000005, -46.90214843750002],
              [168.041015625, -46.887792968750006],
              [168.0431640625, -46.9326171875],
              [168.12548828125, -46.956152343750006],
              [168.15595703125, -46.98828125],
              [168.24140625, -46.97900390625],
              [168.26064453125002, -47.02705078125001],
              [168.24091796875, -47.07001953125001],
              [168.18388671875005, -47.1015625],
              [168.01503906250002, -47.11748046875002],
              [167.90556640625005, -47.17988281250001],
              [167.8107421875, -47.17041015625],
              [167.78496093750005, -47.17607421875002],
              [167.67636718750003, -47.24296875000002],
              [167.5548828125, -47.263671875],
              [167.52197265625, -47.258691406249994],
              [167.53876953125, -47.19902343750002],
              [167.62900390625003, -47.14228515625001],
              [167.63095703125003, -47.087792968749994],
              [167.65410156250005, -47.04423828125002],
              [167.74091796875, -47.013574218749994],
              [167.74199218750005, -46.95683593750002],
              [167.80078125, -46.90654296875002],
              [167.76523437500003, -46.79765625000002],
              [167.78398437500005, -46.699804687500006],
              [167.95576171875, -46.694433593750006],
              [168.14492187500002, -46.862207031249994],
            ],
          ],
          [
            [
              [175.5431640625, -36.279296875],
              [175.55117187500002, -36.33388671875001],
              [175.474609375, -36.314453125],
              [175.44462890625005, -36.2732421875],
              [175.35878906250002, -36.23066406250001],
              [175.34619140625, -36.2177734375],
              [175.33662109375, -36.134765625],
              [175.38164062500005, -36.094824218750006],
              [175.38955078125002, -36.077734375],
              [175.409375, -36.070898437500006],
              [175.4443359375, -36.11464843750001],
              [175.51259765625002, -36.17695312500001],
              [175.5431640625, -36.279296875],
            ],
          ],
          [
            [
              [173.26943359375002, -34.93476562500001],
              [173.28457031250002, -34.980566406250006],
              [173.33994140625003, -34.94794921875001],
              [173.38125, -34.896484375],
              [173.44785156250003, -34.844335937500006],
              [173.438671875, -34.928515625],
              [173.47265625, -34.94697265625001],
              [173.69375, -35.0056640625],
              [173.7392578125, -35.05458984375001],
              [173.78623046875003, -35.06855468750001],
              [173.81279296875005, -35.04121093750001],
              [173.8439453125, -35.02626953125001],
              [173.923828125, -35.05712890625],
              [174.10400390625, -35.14287109375],
              [174.11894531250005, -35.17236328125],
              [174.10976562500002, -35.21640625],
              [174.11875, -35.26289062500001],
              [174.14316406250003, -35.3],
              [174.20322265625003, -35.30859375],
              [174.28291015625, -35.253515625],
              [174.3203125, -35.246679687500006],
              [174.37333984375005, -35.32451171875],
              [174.39316406250003, -35.36855468750001],
              [174.3849609375, -35.36708984375001],
              [174.419140625, -35.410742187500006],
              [174.46474609375002, -35.4541015625],
              [174.54345703125, -35.58203125],
              [174.53173828125, -35.626953125],
              [174.50859375000005, -35.66738281250001],
              [174.58066406250003, -35.785546875],
              [174.53349609375005, -35.79375],
              [174.39101562500002, -35.77373046875],
              [174.39580078125005, -35.79736328125],
              [174.4787109375, -35.88408203125],
              [174.54873046875002, -36.006640625],
              [174.60488281250002, -36.08056640625],
              [174.80214843750002, -36.30947265625001],
              [174.7724609375, -36.39091796875],
              [174.77705078125, -36.44462890625],
              [174.75175781250005, -36.490820312500006],
              [174.81923828125002, -36.612109375],
              [174.77714843750005, -36.64980468750001],
              [174.74921875, -36.774023437500006],
              [174.71865234375002, -36.79580078125001],
              [174.72246093750005, -36.84121093750001],
              [174.801953125, -36.85322265625001],
              [174.84990234375005, -36.87255859375],
              [174.89140625000005, -36.909375],
              [174.9171875, -36.8650390625],
              [174.95205078125002, -36.85292968750001],
              [175.0470703125, -36.912207031250006],
              [175.2451171875, -36.971289062500006],
              [175.29951171875, -36.993261718750006],
              [175.32646484375005, -37.04091796875001],
              [175.3466796875, -37.15615234375001],
              [175.38535156250003, -37.20693359375001],
              [175.4609375, -37.21669921875001],
              [175.54248046875, -37.20136718750001],
              [175.56816406250005, -37.159375],
              [175.551953125, -37.046484375],
              [175.4931640625, -36.86572265625],
              [175.49287109375, -36.80693359375],
              [175.50126953125005, -36.748046875],
              [175.48740234375003, -36.689550781250006],
              [175.4580078125, -36.63427734375],
              [175.42636718750003, -36.591894531250006],
              [175.38554687500005, -36.55634765625001],
              [175.39980468750002, -36.50078125],
              [175.46083984375002, -36.475683593750006],
              [175.49765625000003, -36.52265625000001],
              [175.52802734375, -36.57929687500001],
              [175.68144531250005, -36.74697265625001],
              [175.77216796875, -36.73515625],
              [175.78066406250002, -36.80458984375001],
              [175.84218750000002, -36.875097656250006],
              [175.876171875, -36.95771484375001],
              [175.92109375, -37.20458984375],
              [175.99013671875002, -37.43701171875],
              [176.11455078125005, -37.53828125000001],
              [176.12900390625003, -37.58671875],
              [176.05332031250003, -37.56171875000001],
              [176.02988281250003, -37.576269531250006],
              [176.03789062500005, -37.600683593750006],
              [176.1083984375, -37.645117187500006],
              [176.19111328125, -37.6669921875],
              [176.2431640625, -37.6638671875],
              [176.29169921875, -37.680078125],
              [176.61474609375, -37.83095703125001],
              [176.77001953125, -37.8896484375],
              [177.16181640625, -37.98574218750001],
              [177.27402343750003, -37.99345703125],
              [177.3359375, -37.990820312500006],
              [177.4533203125, -37.957421875],
              [177.55830078125, -37.8974609375],
              [177.64892578125, -37.80781250000001],
              [177.72734375000005, -37.70556640625],
              [177.8126953125, -37.65595703125001],
              [177.90947265625005, -37.61689453125001],
              [177.9580078125, -37.580664062500006],
              [178.00917968750002, -37.55488281250001],
              [178.27216796875, -37.56689453125],
              [178.36074218750002, -37.61845703125],
              [178.47597656250002, -37.659765625],
              [178.53623046875003, -37.69208984375001],
              [178.51601562500002, -37.7576171875],
              [178.44707031250005, -37.85439453125001],
              [178.39394531250002, -37.96025390625],
              [178.34726562500003, -38.20087890625001],
              [178.3154296875, -38.44404296875001],
              [178.26767578125003, -38.551171875],
              [178.1806640625, -38.633691406249994],
              [178.08486328125002, -38.693945312500006],
              [177.97617187500003, -38.722265625],
              [177.93212890625, -38.860253906249994],
              [177.9103515625, -39.02177734375002],
              [177.91660156250003, -39.062402343749994],
              [177.9513671875, -39.09453125000002],
              [177.965625, -39.142480468749994],
              [177.90878906250003, -39.23955078125002],
              [177.87548828125, -39.225488281249994],
              [177.82871093750003, -39.14472656250001],
              [177.7861328125, -39.1109375],
              [177.65585937500003, -39.08574218750002],
              [177.52294921875, -39.073828125],
              [177.40751953125005, -39.081152343750006],
              [177.29658203125, -39.115820312500006],
              [177.12871093750005, -39.186132812500006],
              [177.07675781250003, -39.221777343750006],
              [177.03125, -39.26689453125002],
              [176.9541015625, -39.367578125],
              [176.9357421875, -39.49072265625],
              [176.93925781250005, -39.555273437500006],
              [176.96660156250005, -39.60517578125001],
              [177.10986328125, -39.67314453125002],
              [176.96796875, -39.910742187500006],
              [176.84218750000002, -40.1578125],
              [176.770703125, -40.228417968749994],
              [176.68876953125005, -40.29345703125],
              [176.6115234375, -40.441992187500006],
              [176.47646484375002, -40.57001953125001],
              [176.38515625000002, -40.66767578125001],
              [176.31386718750002, -40.768945312499994],
              [176.25175781250005, -40.876855468749994],
              [176.11865234375, -41.02910156250002],
              [176.05996093750002, -41.12968750000002],
              [175.98291015625, -41.21328125],
              [175.83964843750005, -41.32011718750002],
              [175.68730468750005, -41.41171875],
              [175.44707031250005, -41.53828125000001],
              [175.38027343750002, -41.580078125],
              [175.309765625, -41.61064453125002],
              [175.22216796875, -41.57441406250001],
              [175.20449218750002, -41.53496093750002],
              [175.18466796875003, -41.44902343750002],
              [175.165625, -41.417382812499994],
              [175.05390625, -41.391210937500006],
              [174.90605468750005, -41.43291015625002],
              [174.88134765625, -41.42402343750001],
              [174.8751953125, -41.404296875],
              [174.875, -41.278222656249994],
              [174.90019531250005, -41.24267578125],
              [174.865625, -41.223046875],
              [174.83154296875, -41.23076171875002],
              [174.81972656250002, -41.26289062500001],
              [174.84121093750002, -41.29072265625001],
              [174.75703125, -41.325292968750006],
              [174.66953125000003, -41.326269531250006],
              [174.64296875000002, -41.31269531250001],
              [174.63535156250003, -41.289453125],
              [174.65654296875005, -41.25126953125002],
              [174.68486328125005, -41.217675781249994],
              [174.84775390625003, -41.05878906250001],
              [175.016796875, -40.84765625],
              [175.1625, -40.62158203125],
              [175.20048828125005, -40.50537109375],
              [175.2541015625, -40.28935546875002],
              [175.21015625, -40.19941406250001],
              [175.15595703125, -40.11494140625001],
              [175.00927734375, -39.9521484375],
              [174.81376953125005, -39.86015625000002],
              [174.68730468750005, -39.84716796875],
              [174.56748046875003, -39.81298828125],
              [174.45468750000003, -39.73515625000002],
              [174.35205078125, -39.64335937500002],
              [174.1486328125, -39.56816406250002],
              [173.934375, -39.50908203125002],
              [173.81210937500003, -39.42578125],
              [173.78300781250005, -39.37617187500001],
              [173.763671875, -39.31875],
              [173.76640625000005, -39.26533203125001],
              [173.78164062500002, -39.21123046875002],
              [173.80605468750002, -39.16953125],
              [173.84433593750003, -39.13935546875001],
              [174.07138671875003, -39.03125],
              [174.31171875, -38.97109375],
              [174.35605468750003, -38.97216796875],
              [174.3984375, -38.96259765625001],
              [174.45849609375, -38.92578125],
              [174.56621093750005, -38.84160156250002],
              [174.59736328125, -38.785058593749994],
              [174.61855468750002, -38.60527343750002],
              [174.65302734375, -38.428320312500006],
              [174.71533203125, -38.2255859375],
              [174.80927734375, -38.09980468750001],
              [174.8400390625, -38.02265625000001],
              [174.80166015625002, -37.8955078125],
              [174.83681640625002, -37.84892578125],
              [174.87958984375, -37.82080078125],
              [174.92802734375005, -37.8044921875],
              [174.84599609375005, -37.68515625],
              [174.74941406250002, -37.5046875],
              [174.72919921875, -37.44873046875],
              [174.74394531250005, -37.39345703125001],
              [174.76767578125003, -37.33906250000001],
              [174.70742187500002, -37.325292968750006],
              [174.67255859375, -37.27314453125001],
              [174.58583984375002, -37.097753906250006],
              [174.60966796875005, -37.069921875],
              [174.65966796875, -37.08876953125001],
              [174.73427734375002, -37.215234375],
              [174.74638671875005, -37.15009765625001],
              [174.80361328125002, -37.11005859375001],
              [174.86386718750003, -37.08925781250001],
              [174.92890625, -37.084765625],
              [174.78203125000005, -36.94375],
              [174.73291015625, -36.94941406250001],
              [174.66796875, -36.971875],
              [174.60146484375002, -36.98574218750001],
              [174.53652343750002, -36.97333984375001],
              [174.4755859375, -36.94189453125],
              [174.44453125, -36.88251953125001],
              [174.40605468750005, -36.76826171875001],
              [174.38193359375003, -36.72597656250001],
              [174.18886718750002, -36.492285156250006],
              [174.24570312500003, -36.484960937500006],
              [174.4015625, -36.601953125],
              [174.43173828125003, -36.564550781250006],
              [174.454296875, -36.5107421875],
              [174.446875, -36.45087890625001],
              [174.40957031250002, -36.40556640625],
              [174.35410156250003, -36.3759765625],
              [174.353125, -36.322851562500006],
              [174.39541015625002, -36.27412109375001],
              [174.3927734375, -36.2400390625],
              [174.30351562500005, -36.170507812500006],
              [174.26787109375005, -36.1630859375],
              [174.25205078125003, -36.19560546875],
              [174.2775390625, -36.24375],
              [174.25371093750005, -36.249121093750006],
              [174.03642578125005, -36.12246093750001],
              [173.96933593750003, -36.020605468750006],
              [173.91445312500002, -35.90869140625],
              [173.90888671875, -35.954199218750006],
              [173.91728515625005, -36.018164062500006],
              [174.003125, -36.1462890625],
              [174.14238281250005, -36.289453125],
              [174.16640625000002, -36.32763671875],
              [174.14580078125005, -36.376953125],
              [174.09746093750005, -36.391015625],
              [174.0546875, -36.359765625],
              [173.99101562500005, -36.23720703125001],
              [173.94511718750005, -36.175878906250006],
              [173.41220703125003, -35.542578125],
              [173.48027343750005, -35.458984375],
              [173.58583984375002, -35.38857421875001],
              [173.6103515625, -35.3572265625],
              [173.626171875, -35.319140625],
              [173.58164062500003, -35.312597656250006],
              [173.54169921875, -35.3298828125],
              [173.49609375, -35.3623046875],
              [173.454296875, -35.39921875],
              [173.40166015625005, -35.48115234375001],
              [173.37636718750002, -35.500097656250006],
              [173.31396484375, -35.443359375],
              [173.290234375, -35.408300781250006],
              [173.2912109375, -35.366308593750006],
              [173.27451171875003, -35.3396484375],
              [173.228125, -35.33125],
              [173.16015625, -35.24775390625001],
              [173.11669921875, -35.20527343750001],
              [173.18876953125005, -35.12373046875001],
              [173.190625, -35.016210937500006],
              [173.11728515625003, -34.9033203125],
              [173.02958984375005, -34.799902343750006],
              [172.86074218750002, -34.63232421875],
              [172.70595703125002, -34.455175781250006],
              [172.87373046875, -34.43291015625],
              [173.0439453125, -34.42910156250001],
              [172.96376953125002, -34.53515625],
              [172.99980468750005, -34.596484375],
              [173.05439453125, -34.6482421875],
              [173.17109375, -34.80693359375],
              [173.18125, -34.85273437500001],
              [173.24052734375005, -34.899023437500006],
              [173.26943359375002, -34.93476562500001],
            ],
          ],
          [
            [
              [169.17822265625, -52.49726562500001],
              [169.23349609375003, -52.548242187499994],
              [169.12753906250003, -52.5703125],
              [169.07597656250005, -52.551855468750006],
              [169.03984375000005, -52.52851562500001],
              [169.02177734375005, -52.49541015625002],
              [169.0791015625, -52.49882812500002],
              [169.12861328125, -52.48515625000002],
              [169.17822265625, -52.49726562500001],
            ],
          ],
          [
            [
              [166.22109375000002, -50.76152343750002],
              [166.24287109375, -50.845703125],
              [166.18789062500002, -50.84609375],
              [166.0732421875, -50.82265625],
              [166.03769531250003, -50.78671875],
              [166.01328125000003, -50.777929687500006],
              [165.97138671875, -50.81953125000001],
              [165.90410156250005, -50.82148437500001],
              [165.88916015625, -50.807714843750006],
              [165.915625, -50.763085937499994],
              [166.07382812500003, -50.67900390625002],
              [166.103125, -50.57304687500002],
              [166.10136718750005, -50.538964843749994],
              [166.22509765625, -50.53095703125001],
              [166.25429687500002, -50.5439453125],
              [166.26748046875002, -50.55859375],
              [166.259375, -50.577246093750006],
              [166.20957031250003, -50.61201171875001],
              [166.20761718750003, -50.652441406250006],
              [166.22041015625, -50.6943359375],
              [166.17949218750005, -50.71464843750002],
              [166.20078125000003, -50.750878906249994],
              [166.22109375000002, -50.76152343750002],
            ],
          ],
          [
            [
              [-176.17763671875, -43.740332031250006],
              [-176.213525390625, -43.76630859375001],
              [-176.274853515625, -43.76484375000001],
              [-176.38173828125, -43.866796875],
              [-176.375244140625, -43.790625],
              [-176.407373046875, -43.76093750000001],
              [-176.49912109375, -43.76806640625],
              [-176.516552734375, -43.784765625],
              [-176.454931640625, -43.80488281250001],
              [-176.441259765625, -43.81611328125001],
              [-176.500146484375, -43.86015625000002],
              [-176.439111328125, -43.9546875],
              [-176.38544921875, -43.95146484375002],
              [-176.33359375, -44.025292968749994],
              [-176.333837890625, -44.0484375],
              [-176.452783203125, -44.07685546875001],
              [-176.51552734375, -44.116601562499994],
              [-176.571533203125, -44.11494140625001],
              [-176.597998046875, -44.10722656250002],
              [-176.629345703125, -44.0361328125],
              [-176.63154296875, -44.00625],
              [-176.562744140625, -43.9541015625],
              [-176.523779296875, -43.900976562500006],
              [-176.555126953125, -43.851953125],
              [-176.6345703125, -43.820214843749994],
              [-176.807958984375, -43.834570312500006],
              [-176.84765625, -43.82392578125001],
              [-176.761083984375, -43.757910156250006],
              [-176.667236328125, -43.76513671875],
              [-176.56611328125, -43.71757812500002],
              [-176.17763671875, -43.740332031250006],
            ],
          ],
          [
            [
              [-176.17646484375, -44.321679687499994],
              [-176.22080078125, -44.33056640625],
              [-176.214599609375, -44.273535156250006],
              [-176.229296875, -44.23671875],
              [-176.1546875, -44.224511718749994],
              [-176.12255859375, -44.268457031249994],
              [-176.17646484375, -44.321679687499994],
            ],
          ],
          [
            [
              [-172.479150390625, -8.580761718750011],
              [-172.48369140625, -8.582910156250009],
              [-172.488232421875, -8.571582031250003],
              [-172.49404296875, -8.559179687500006],
              [-172.498681640625, -8.547949218750006],
              [-172.497021484375, -8.546484375],
              [-172.487255859375, -8.55615234375],
              [-172.481103515625, -8.567480468750006],
              [-172.479150390625, -8.580761718750011],
            ],
          ],
          [
            [
              [-171.18642578125, -9.35546875],
              [-171.188623046875, -9.358300781250009],
              [-171.193017578125, -9.352441406250009],
              [-171.200048828125, -9.3447265625],
              [-171.204443359375, -9.333300781250003],
              [-171.20166015625, -9.332617187500006],
              [-171.19443359375, -9.338769531250009],
              [-171.189306640625, -9.346582031250009],
              [-171.18642578125, -9.35546875],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 3,
        labelrank: 4,
        sovereignt: "United Kingdom",
        sov_a3: "GB1",
        adm0_dif: 1,
        level: 2,
        type: "Dependency",
        tlc: "1",
        admin: "Pitcairn Islands",
        adm0_a3: "PCN",
        geou_dif: 0,
        geounit: "Pitcairn Islands",
        gu_a3: "PCN",
        su_dif: 0,
        subunit: "Pitcairn Islands",
        su_a3: "PCN",
        brk_diff: 0,
        name: "Pitcairn Is.",
        name_long: "Pitcairn Islands",
        brk_a3: "PCN",
        brk_name: "Pitcairn Is.",
        brk_group: null,
        abbrev: "Pit. Is.",
        postal: "PN",
        formal_en: "Pitcairn, Henderson, Ducie and Oeno Islands",
        formal_fr: null,
        name_ciawf: "Pitcairn Islands",
        note_adm0: "U.K.",
        note_brk: null,
        name_sort: "Pitcairn Islands",
        name_alt: null,
        mapcolor7: 6,
        mapcolor8: 6,
        mapcolor9: 6,
        mapcolor13: 3,
        pop_est: 54,
        pop_rank: 1,
        pop_year: 2016,
        gdp_md: 0,
        gdp_year: 2016,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        fips_10: "PC",
        iso_a2: "PN",
        iso_a2_eh: "PN",
        iso_a3: "PCN",
        iso_a3_eh: "PCN",
        iso_n3: "612",
        iso_n3_eh: "612",
        un_a3: "612",
        wb_a2: "-99",
        wb_a3: "-99",
        woe_id: 23424918,
        woe_id_eh: 23424918,
        woe_note: "Exact WOE match as country",
        adm0_iso: "PCN",
        adm0_diff: null,
        adm0_tlc: "PCN",
        adm0_a3_us: "PCN",
        adm0_a3_fr: "PCN",
        adm0_a3_ru: "PCN",
        adm0_a3_es: "PCN",
        adm0_a3_cn: "PCN",
        adm0_a3_tw: "PCN",
        adm0_a3_in: "PCN",
        adm0_a3_np: "PCN",
        adm0_a3_pk: "PCN",
        adm0_a3_de: "PCN",
        adm0_a3_gb: "PCN",
        adm0_a3_br: "PCN",
        adm0_a3_il: "PCN",
        adm0_a3_ps: "PCN",
        adm0_a3_sa: "PCN",
        adm0_a3_eg: "PCN",
        adm0_a3_ma: "PCN",
        adm0_a3_pt: "PCN",
        adm0_a3_ar: "PCN",
        adm0_a3_jp: "PCN",
        adm0_a3_ko: "PCN",
        adm0_a3_vn: "PCN",
        adm0_a3_tr: "PCN",
        adm0_a3_id: "PCN",
        adm0_a3_pl: "PCN",
        adm0_a3_gr: "PCN",
        adm0_a3_it: "PCN",
        adm0_a3_nl: "PCN",
        adm0_a3_se: "PCN",
        adm0_a3_bd: "PCN",
        adm0_a3_ua: "PCN",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Oceania",
        region_un: "Oceania",
        subregion: "Polynesia",
        region_wb: "East Asia & Pacific",
        name_len: 12,
        long_len: 16,
        abbrev_len: 8,
        tiny: -99,
        homepart: -99,
        min_zoom: 0,
        min_label: 5,
        max_label: 9,
        label_x: -128.317536,
        label_y: -24.364576,
        ne_id: 1159320729,
        wikidataid: "Q35672",
        name_ar: "جزر بيتكيرن",
        name_bn: "পীটকেয়ার্ন দ্বীপপুঞ্জ",
        name_de: "Pitcairninseln",
        name_en: "Pitcairn Islands",
        name_es: "Islas Pitcairn",
        name_fa: "جزایر پیتکرن",
        name_fr: "Iles Pitcairn",
        name_el: "Νήσοι Πίτκερν",
        name_he: "פיטקרן",
        name_hi: "पिटकेर्न द्वीपसमूह",
        name_hu: "Pitcairn-szigetek",
        name_id: "Kepulauan Pitcairn",
        name_it: "Isole Pitcairn",
        name_ja: "ピトケアン諸島",
        name_ko: "핏케언 제도",
        name_nl: "Pitcairneilanden",
        name_pl: "Pitcairn",
        name_pt: "Ilhas Pitcairn",
        name_ru: "Острова Питкэрн",
        name_sv: "Pitcairnöarna",
        name_tr: "Pitcairn Adaları",
        name_uk: "Піткерн",
        name_ur: "جزائر پٹکیرن",
        name_vi: "Quần đảo Pitcairn",
        name_zh: "皮特凯恩群岛",
        name_zht: "皮特肯群島",
        fclass_iso: "Admin-0 dependency",
        tlc_diff: null,
        fclass_tlc: "Admin-0 dependency",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "PCN.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-128.290087890625, -24.39736328125001],
            [-128.3, -24.41259765625],
            [-128.320654296875, -24.399707031250003],
            [-128.3421875, -24.370703125],
            [-128.3501953125, -24.340234375],
            [-128.330126953125, -24.3232421875],
            [-128.30361328125, -24.33359375],
            [-128.2908203125, -24.36464843750001],
            [-128.290087890625, -24.39736328125001],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 3,
        labelrank: 4,
        sovereignt: "France",
        sov_a3: "FR1",
        adm0_dif: 1,
        level: 2,
        type: "Dependency",
        tlc: "1",
        admin: "French Polynesia",
        adm0_a3: "PYF",
        geou_dif: 0,
        geounit: "French Polynesia",
        gu_a3: "PYF",
        su_dif: 0,
        subunit: "French Polynesia",
        su_a3: "PYF",
        brk_diff: 0,
        name: "Fr. Polynesia",
        name_long: "French Polynesia",
        brk_a3: "PYF",
        brk_name: "Fr. Polynesia",
        brk_group: null,
        abbrev: "Fr. Poly.",
        postal: "PF",
        formal_en: "French Polynesia",
        formal_fr: null,
        name_ciawf: "French Polynesia",
        note_adm0: "Fr.",
        note_brk: null,
        name_sort: "French Polynesia",
        name_alt: null,
        mapcolor7: 7,
        mapcolor8: 5,
        mapcolor9: 9,
        mapcolor13: 11,
        pop_est: 279287,
        pop_rank: 10,
        pop_year: 2019,
        gdp_md: 5490,
        gdp_year: 2016,
        economy: "6. Developing region",
        income_grp: "2. High income: nonOECD",
        fips_10: "FP",
        iso_a2: "PF",
        iso_a2_eh: "PF",
        iso_a3: "PYF",
        iso_a3_eh: "PYF",
        iso_n3: "258",
        iso_n3_eh: "258",
        un_a3: "258",
        wb_a2: "PF",
        wb_a3: "PYF",
        woe_id: 23424817,
        woe_id_eh: 23424817,
        woe_note: "Exact WOE match as country",
        adm0_iso: "PYF",
        adm0_diff: null,
        adm0_tlc: "PYF",
        adm0_a3_us: "PYF",
        adm0_a3_fr: "PYF",
        adm0_a3_ru: "PYF",
        adm0_a3_es: "PYF",
        adm0_a3_cn: "PYF",
        adm0_a3_tw: "PYF",
        adm0_a3_in: "PYF",
        adm0_a3_np: "PYF",
        adm0_a3_pk: "PYF",
        adm0_a3_de: "PYF",
        adm0_a3_gb: "PYF",
        adm0_a3_br: "PYF",
        adm0_a3_il: "PYF",
        adm0_a3_ps: "PYF",
        adm0_a3_sa: "PYF",
        adm0_a3_eg: "PYF",
        adm0_a3_ma: "PYF",
        adm0_a3_pt: "PYF",
        adm0_a3_ar: "PYF",
        adm0_a3_jp: "PYF",
        adm0_a3_ko: "PYF",
        adm0_a3_vn: "PYF",
        adm0_a3_tr: "PYF",
        adm0_a3_id: "PYF",
        adm0_a3_pl: "PYF",
        adm0_a3_gr: "PYF",
        adm0_a3_it: "PYF",
        adm0_a3_nl: "PYF",
        adm0_a3_se: "PYF",
        adm0_a3_bd: "PYF",
        adm0_a3_ua: "PYF",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Oceania",
        region_un: "Oceania",
        subregion: "Polynesia",
        region_wb: "East Asia & Pacific",
        name_len: 13,
        long_len: 16,
        abbrev_len: 9,
        tiny: 2,
        homepart: -99,
        min_zoom: 0,
        min_label: 3.5,
        max_label: 8.5,
        label_x: -149.46157,
        label_y: -17.628081,
        ne_id: 1159320643,
        wikidataid: "Q30971",
        name_ar: "بولينزيا الفرنسية",
        name_bn: "ফরাসি পলিনেশিয়া",
        name_de: "Französisch-Polynesien",
        name_en: "French Polynesia",
        name_es: "Polinesia Francesa",
        name_fa: "پلینزی فرانسه",
        name_fr: "Polynésie française",
        name_el: "Γαλλική Πολυνησία",
        name_he: "פולינזיה הצרפתית",
        name_hi: "फ़्रान्सीसी पॉलिनेशिया",
        name_hu: "Francia Polinézia",
        name_id: "Polinesia Prancis",
        name_it: "Polinesia francese",
        name_ja: "フランス領ポリネシア",
        name_ko: "프랑스령 폴리네시아",
        name_nl: "Frans-Polynesië",
        name_pl: "Polinezja Francuska",
        name_pt: "Polinésia Francesa",
        name_ru: "Французская Полинезия",
        name_sv: "Franska Polynesien",
        name_tr: "Fransız Polinezyası",
        name_uk: "Французька Полінезія",
        name_ur: "فرانسیسی پولینیشیا",
        name_vi: "Polynésie thuộc Pháp",
        name_zh: "法属波利尼西亚",
        name_zht: "法屬玻里尼西亞",
        fclass_iso: "Admin-0 dependency",
        tlc_diff: null,
        fclass_tlc: "Admin-0 dependency",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "PYF.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-151.4666015625, -16.657519531250003],
              [-151.484912109375, -16.665136718750006],
              [-151.504150390625, -16.64697265625],
              [-151.51240234375, -16.61904296875001],
              [-151.50576171875, -16.574023437500003],
              [-151.457421875, -16.60371093750001],
              [-151.4380859375, -16.6234375],
              [-151.4666015625, -16.657519531250003],
            ],
          ],
          [
            [
              [-149.813671875, -17.545019531250006],
              [-149.844921875, -17.57109375],
              [-149.886572265625, -17.552832031250006],
              [-149.905126953125, -17.527734375],
              [-149.91181640625, -17.50117187500001],
              [-149.9021484375, -17.46953125],
              [-149.8087890625, -17.473925781250003],
              [-149.782421875, -17.48779296875],
              [-149.813671875, -17.545019531250006],
            ],
          ],
          [
            [
              [-151.409814453125, -16.877734375],
              [-151.449462890625, -16.879296875],
              [-151.485498046875, -16.863671875],
              [-151.476416015625, -16.7607421875],
              [-151.466748046875, -16.73964843750001],
              [-151.411181640625, -16.7744140625],
              [-151.364501953125, -16.8642578125],
              [-151.409814453125, -16.877734375],
            ],
          ],
          [
            [
              [-149.321533203125, -17.690039062500006],
              [-149.177685546875, -17.736621093750003],
              [-149.15087890625, -17.812109375],
              [-149.181787109375, -17.8623046875],
              [-149.2544921875, -17.849902343750003],
              [-149.290478515625, -17.82246093750001],
              [-149.34111328125, -17.732421875],
              [-149.481689453125, -17.752734375],
              [-149.57890625, -17.734960937500006],
              [-149.6328125, -17.617578125],
              [-149.635009765625, -17.564257812500003],
              [-149.61142578125, -17.531640625],
              [-149.50810546875, -17.496386718750003],
              [-149.37919921875, -17.52236328125001],
              [-149.330078125, -17.588964843750006],
              [-149.321533203125, -17.690039062500006],
            ],
          ],
          [
            [
              [-139.02431640625, -9.695214843750009],
              [-138.874462890625, -9.747167968750006],
              [-138.82734375, -9.741601562500009],
              [-138.874951171875, -9.792871093750009],
              [-139.024267578125, -9.820703125],
              [-139.073681640625, -9.845703125],
              [-139.13408203125, -9.829492187500009],
              [-139.166455078125, -9.770214843750011],
              [-139.02431640625, -9.695214843750009],
            ],
          ],
          [
            [
              [-139.059716796875, -9.931347656250011],
              [-139.133984375, -10.009570312500003],
              [-139.134228515625, -9.92626953125],
              [-139.107470703125, -9.915429687500009],
              [-139.083154296875, -9.915429687500009],
              [-139.059716796875, -9.931347656250011],
            ],
          ],
          [
            [
              [-138.651123046875, -10.515332031250011],
              [-138.687744140625, -10.532421875000011],
              [-138.690380859375, -10.425585937500003],
              [-138.642919921875, -10.445898437500006],
              [-138.624462890625, -10.462988281250006],
              [-138.632373046875, -10.4921875],
              [-138.651123046875, -10.515332031250011],
            ],
          ],
          [
            [
              [-140.072607421875, -8.910449218750003],
              [-140.170556640625, -8.933984375],
              [-140.217431640625, -8.9296875],
              [-140.252685546875, -8.848046875],
              [-140.2400390625, -8.797558593750011],
              [-140.2244140625, -8.781542968750003],
              [-140.057666015625, -8.801464843750011],
              [-140.043701171875, -8.838476562500006],
              [-140.046142578125, -8.873632812500006],
              [-140.072607421875, -8.910449218750003],
            ],
          ],
          [
            [
              [-140.075634765625, -9.425976562500011],
              [-140.09736328125, -9.444140625],
              [-140.138037109375, -9.384375],
              [-140.144384765625, -9.359375],
              [-140.070947265625, -9.328125],
              [-140.031103515625, -9.3447265625],
              [-140.075634765625, -9.425976562500011],
            ],
          ],
          [
            [
              [-140.809375, -17.85664062500001],
              [-140.804443359375, -17.87568359375001],
              [-140.8408203125, -17.873144531250006],
              [-140.8515625, -17.86660156250001],
              [-140.850732421875, -17.8310546875],
              [-140.824267578125, -17.78798828125001],
              [-140.80361328125, -17.75166015625001],
              [-140.76142578125, -17.7177734375],
              [-140.686181640625, -17.68378906250001],
              [-140.6498046875, -17.669726562500003],
              [-140.638232421875, -17.678027343750003],
              [-140.65205078125, -17.68310546875],
              [-140.776318359375, -17.75410156250001],
              [-140.815185546875, -17.8037109375],
              [-140.83251953125, -17.838476562500006],
              [-140.829248046875, -17.84921875],
              [-140.809375, -17.85664062500001],
            ],
          ],
          [
            [
              [-139.556201171875, -8.940234375],
              [-139.62099609375, -8.947949218750011],
              [-139.631787109375, -8.898535156250006],
              [-139.611767578125, -8.872363281250003],
              [-139.583984375, -8.860058593750011],
              [-139.5345703125, -8.875390625],
              [-139.508349609375, -8.897070312500006],
              [-139.509912109375, -8.915625],
              [-139.556201171875, -8.940234375],
            ],
          ],
          [
            [
              [-140.6853515625, -18.3798828125],
              [-140.671875, -18.416113281250006],
              [-140.696044921875, -18.39912109375001],
              [-140.7732421875, -18.36376953125],
              [-140.78173828125, -18.33417968750001],
              [-140.6853515625, -18.3798828125],
            ],
          ],
          [
            [
              [-140.8298828125, -18.18935546875001],
              [-140.822705078125, -18.216894531250006],
              [-140.86005859375, -18.19873046875],
              [-140.895458984375, -18.14794921875],
              [-140.958642578125, -18.085058593750006],
              [-140.97353515625, -18.059179687500006],
              [-140.925146484375, -18.083789062500003],
              [-140.89326171875, -18.12050781250001],
              [-140.8298828125, -18.18935546875001],
            ],
          ],
          [
            [
              [-136.293896484375, -18.54433593750001],
              [-136.3140625, -18.56630859375001],
              [-136.316015625, -18.545214843750003],
              [-136.34404296875, -18.53486328125001],
              [-136.38291015625, -18.513671875],
              [-136.435693359375, -18.4890625],
              [-136.4642578125, -18.48505859375001],
              [-136.478515625, -18.470800781250006],
              [-136.45869140625, -18.463183593750003],
              [-136.426123046875, -18.47431640625001],
              [-136.38037109375, -18.49677734375001],
              [-136.32763671875, -18.519335937500003],
              [-136.293896484375, -18.54433593750001],
            ],
          ],
          [
            [
              [-136.971728515625, -18.34199218750001],
              [-136.971337890625, -18.3609375],
              [-137.067578125, -18.26533203125001],
              [-137.029638671875, -18.27285156250001],
              [-136.971728515625, -18.34199218750001],
            ],
          ],
          [
            [
              [-138.505859375, -20.857226562500003],
              [-138.53486328125, -20.87587890625001],
              [-138.5240234375, -20.8505859375],
              [-138.54638671875, -20.79511718750001],
              [-138.568359375, -20.787109375],
              [-138.54638671875, -20.77119140625001],
              [-138.51494140625, -20.81337890625001],
              [-138.505859375, -20.857226562500003],
            ],
          ],
          [
            [
              [-142.51181640625, -16.096289062500006],
              [-142.52958984375, -16.10712890625001],
              [-142.5068359375, -16.027734375],
              [-142.481201171875, -16.01777343750001],
              [-142.51181640625, -16.096289062500006],
            ],
          ],
          [
            [
              [-143.440576171875, -16.619726562500006],
              [-143.386181640625, -16.66884765625001],
              [-143.458544921875, -16.63544921875001],
              [-143.55068359375, -16.62109375],
              [-143.515576171875, -16.6123046875],
              [-143.464697265625, -16.613574218750003],
              [-143.440576171875, -16.619726562500006],
            ],
          ],
          [
            [
              [-143.571142578125, -16.634765625],
              [-143.61064453125, -16.640429687500003],
              [-143.707421875, -16.580859375],
              [-143.67021484375, -16.580859375],
              [-143.614794921875, -16.61806640625001],
              [-143.571142578125, -16.634765625],
            ],
          ],
          [
            [
              [-145.0513671875, -15.856054687500006],
              [-145.057666015625, -15.901074218750011],
              [-145.076416015625, -15.857617187500011],
              [-145.137939453125, -15.7880859375],
              [-145.1607421875, -15.757031250000011],
              [-145.133544921875, -15.762011718750003],
              [-145.0513671875, -15.856054687500006],
            ],
          ],
          [
            [
              [-145.486669921875, -16.32978515625001],
              [-145.4822265625, -16.346777343750006],
              [-145.502734375, -16.345800781250006],
              [-145.53984375, -16.29511718750001],
              [-145.553125, -16.25117187500001],
              [-145.576708984375, -16.201464843750003],
              [-145.609130859375, -16.16523437500001],
              [-145.61279296875, -16.1318359375],
              [-145.613818359375, -16.079199218750006],
              [-145.577099609375, -16.15986328125001],
              [-145.542333984375, -16.224609375],
              [-145.5169921875, -16.27783203125],
              [-145.486669921875, -16.32978515625001],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 5,
        labelrank: 6,
        sovereignt: "Kiribati",
        sov_a3: "KIR",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Kiribati",
        adm0_a3: "KIR",
        geou_dif: 0,
        geounit: "Kiribati",
        gu_a3: "KIR",
        su_dif: 0,
        subunit: "Kiribati",
        su_a3: "KIR",
        brk_diff: 0,
        name: "Kiribati",
        name_long: "Kiribati",
        brk_a3: "KIR",
        brk_name: "Kiribati",
        brk_group: null,
        abbrev: "Kir.",
        postal: "KI",
        formal_en: "Republic of Kiribati",
        formal_fr: null,
        name_ciawf: "Kiribati",
        note_adm0: null,
        note_brk: null,
        name_sort: "Kiribati",
        name_alt: null,
        mapcolor7: 5,
        mapcolor8: 7,
        mapcolor9: 6,
        mapcolor13: 12,
        pop_est: 117606,
        pop_rank: 9,
        pop_year: 2019,
        gdp_md: 194,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "4. Lower middle income",
        fips_10: "KR",
        iso_a2: "KI",
        iso_a2_eh: "KI",
        iso_a3: "KIR",
        iso_a3_eh: "KIR",
        iso_n3: "296",
        iso_n3_eh: "296",
        un_a3: "296",
        wb_a2: "KI",
        wb_a3: "KIR",
        woe_id: 23424867,
        woe_id_eh: 23424867,
        woe_note: "Exact WOE match as country",
        adm0_iso: "KIR",
        adm0_diff: null,
        adm0_tlc: "KIR",
        adm0_a3_us: "KIR",
        adm0_a3_fr: "KIR",
        adm0_a3_ru: "KIR",
        adm0_a3_es: "KIR",
        adm0_a3_cn: "KIR",
        adm0_a3_tw: "KIR",
        adm0_a3_in: "KIR",
        adm0_a3_np: "KIR",
        adm0_a3_pk: "KIR",
        adm0_a3_de: "KIR",
        adm0_a3_gb: "KIR",
        adm0_a3_br: "KIR",
        adm0_a3_il: "KIR",
        adm0_a3_ps: "KIR",
        adm0_a3_sa: "KIR",
        adm0_a3_eg: "KIR",
        adm0_a3_ma: "KIR",
        adm0_a3_pt: "KIR",
        adm0_a3_ar: "KIR",
        adm0_a3_jp: "KIR",
        adm0_a3_ko: "KIR",
        adm0_a3_vn: "KIR",
        adm0_a3_tr: "KIR",
        adm0_a3_id: "KIR",
        adm0_a3_pl: "KIR",
        adm0_a3_gr: "KIR",
        adm0_a3_it: "KIR",
        adm0_a3_nl: "KIR",
        adm0_a3_se: "KIR",
        adm0_a3_bd: "KIR",
        adm0_a3_ua: "KIR",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Oceania",
        region_un: "Oceania",
        subregion: "Micronesia",
        region_wb: "East Asia & Pacific",
        name_len: 8,
        long_len: 8,
        abbrev_len: 4,
        tiny: 2,
        homepart: 1,
        min_zoom: 0,
        min_label: 5,
        max_label: 10,
        label_x: -157.384577,
        label_y: 1.820437,
        ne_id: 1159320981,
        wikidataid: "Q710",
        name_ar: "كيريباتي",
        name_bn: "কিরিবাস",
        name_de: "Kiribati",
        name_en: "Kiribati",
        name_es: "Kiribati",
        name_fa: "کیریباتی",
        name_fr: "Kiribati",
        name_el: "Κιριμπάτι",
        name_he: "קיריבטי",
        name_hi: "किरिबाती",
        name_hu: "Kiribati",
        name_id: "Kiribati",
        name_it: "Kiribati",
        name_ja: "キリバス",
        name_ko: "키리바시",
        name_nl: "Kiribati",
        name_pl: "Kiribati",
        name_pt: "Kiribati",
        name_ru: "Кирибати",
        name_sv: "Kiribati",
        name_tr: "Kiribati",
        name_uk: "Кірибаті",
        name_ur: "کیریباتی",
        name_vi: "Kiribati",
        name_zh: "基里巴斯",
        name_zht: "吉里巴斯",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "KIR.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [172.96962890625002, 3.129199218749989],
              [172.90625, 3.095898437499997],
              [172.88710937500002, 3.101269531249997],
              [172.9625, 3.148779296874991],
              [172.96220703125005, 3.142919921874991],
              [172.96962890625002, 3.129199218749989],
            ],
          ],
          [
            [
              [172.84423828125, 3.051220703124997],
              [172.77031250000005, 3.012548828124991],
              [172.75048828125, 3.033056640624991],
              [172.77734375, 3.033886718749997],
              [172.82695312500005, 3.071093749999989],
              [172.88710937500002, 3.073974609375],
              [172.88027343750002, 3.053515624999989],
              [172.84423828125, 3.051220703124997],
            ],
          ],
          [
            [
              [173.01875, 1.845703125],
              [173.0236328125, 1.822558593749989],
              [172.96660156250005, 1.885400390624994],
              [172.93271484375003, 1.925927734374994],
              [172.934765625, 1.943701171874991],
              [172.95009765625002, 1.932519531249994],
              [172.96914062500002, 1.912695312499991],
              [172.98154296875003, 1.89697265625],
              [173.01875, 1.845703125],
            ],
          ],
          [
            [
              [173.02939453125003, 1.717382812499991],
              [172.99326171875003, 1.713085937499997],
              [173.02041015625002, 1.727490234374997],
              [173.02783203125, 1.747314453125],
              [173.0236328125, 1.809326171875],
              [173.03769531250003, 1.804394531249997],
              [173.04267578125, 1.778759765624997],
              [173.04521484375005, 1.741552734374991],
              [173.02939453125003, 1.717382812499991],
            ],
          ],
          [
            [
              [173.03281250000003, 1.013134765624997],
              [173.08652343750003, 0.973437499999989],
              [173.07949218750002, 0.946240234374997],
              [173.06142578125002, 0.915234375],
              [172.99111328125002, 0.83544921875],
              [172.969921875, 0.8427734375],
              [173.03857421875, 0.914746093749997],
              [173.06503906250003, 0.962695312499989],
              [173.0255859375, 0.999072265624989],
              [173.0099609375, 0.990966796875],
              [173.00371093750005, 0.990966796875],
              [172.99003906250005, 1.025097656249997],
              [173.00371093750005, 1.025097656249997],
              [173.03281250000003, 1.013134765624997],
            ],
          ],
          [
            [
              [174.50869140625002, -0.8017578125],
              [174.47636718750005, -0.829003906250009],
              [174.4640625, -0.80419921875],
              [174.4796875, -0.773632812500011],
              [174.45273437500003, -0.647070312500006],
              [174.40781250000003, -0.629785156250009],
              [174.3810546875, -0.591796875],
              [174.39404296875, -0.591796875],
              [174.43876953125005, -0.6265625],
              [174.4748046875, -0.6421875],
              [174.49541015625005, -0.725683593750006],
              [174.50869140625002, -0.8017578125],
            ],
          ],
          [
            [
              [174.77324218750005, -1.2119140625],
              [174.77890625000003, -1.263378906250011],
              [174.75595703125003, -1.256445312500006],
              [174.74843750000002, -1.236425781250006],
              [174.74101562500005, -1.1845703125],
              [174.716796875, -1.133691406250009],
              [174.744140625, -1.147363281250009],
              [174.7666015625, -1.187109375],
              [174.77324218750005, -1.2119140625],
            ],
          ],
          [
            [
              [173.03837890625005, 1.342089843749989],
              [173.01132812500003, 1.33837890625],
              [173.02861328125005, 1.358740234374991],
              [173.14335937500005, 1.38134765625],
              [173.1533203125, 1.387548828124991],
              [173.171875, 1.375146484374994],
              [173.17148437500003, 1.363378906249991],
              [173.1630859375, 1.357519531249991],
              [173.10634765625002, 1.357080078124994],
              [173.06171875, 1.346337890624994],
              [173.03837890625005, 1.342089843749989],
            ],
          ],
          [
            [
              [169.55107421875005, -0.873730468750011],
              [169.54169921875, -0.8759765625],
              [169.52294921875, -0.865625],
              [169.5255859375, -0.852636718750006],
              [169.53867187500003, -0.846875],
              [169.55527343750003, -0.856542968750006],
              [169.55107421875005, -0.873730468750011],
            ],
          ],
          [
            [
              [-172.21455078125, -4.511132812500009],
              [-172.20830078125, -4.51796875],
              [-172.193896484375, -4.516015625],
              [-172.18095703125, -4.514843750000011],
              [-172.188818359375, -4.521679687500011],
              [-172.215234375, -4.5244140625],
              [-172.2283203125, -4.507031250000011],
              [-172.21220703125, -4.493945312500003],
              [-172.1978515625, -4.49169921875],
              [-172.19638671875, -4.495410156250003],
              [-172.197216796875, -4.49951171875],
              [-172.203857421875, -4.49951171875],
              [-172.21474609375, -4.502636718750011],
              [-172.21455078125, -4.511132812500009],
            ],
          ],
          [
            [
              [-171.08515625, -3.135449218750011],
              [-171.089794921875, -3.143261718750011],
              [-171.096728515625, -3.136914062500011],
              [-171.091748046875, -3.125097656250006],
              [-171.0876953125, -3.115039062500003],
              [-171.081005859375, -3.120410156250003],
              [-171.08515625, -3.135449218750011],
            ],
          ],
          [
            [
              [-171.233203125, -4.463476562500006],
              [-171.243017578125, -4.468066406250003],
              [-171.254541015625, -4.466503906250011],
              [-171.261767578125, -4.459765625],
              [-171.261962890625, -4.44921875],
              [-171.252392578125, -4.441601562500011],
              [-171.239404296875, -4.444140625],
              [-171.231884765625, -4.453710937500006],
              [-171.233203125, -4.463476562500006],
            ],
          ],
          [
            [
              [-174.512939453125, -4.675097656250003],
              [-174.501123046875, -4.688378906250009],
              [-174.501025390625, -4.694726562500009],
              [-174.50673828125, -4.693652343750003],
              [-174.523876953125, -4.689648437500011],
              [-174.529248046875, -4.681640625],
              [-174.516748046875, -4.686816406250003],
              [-174.511474609375, -4.685644531250006],
              [-174.523046875, -4.674023437500011],
              [-174.53310546875, -4.665332031250003],
              [-174.540673828125, -4.66171875],
              [-174.5408203125, -4.657324218750006],
              [-174.531396484375, -4.659472656250003],
              [-174.512939453125, -4.675097656250003],
            ],
          ],
          [
            [
              [-154.95625, -4.087988281250006],
              [-154.959033203125, -4.093847656250006],
              [-154.97109375, -4.085839843750009],
              [-154.99462890625, -4.07109375],
              [-155.014599609375, -4.054882812500011],
              [-155.0150390625, -4.048046875000011],
              [-154.986962890625, -4.03857421875],
              [-154.951220703125, -4.031054687500003],
              [-154.943359375, -4.041601562500006],
              [-154.950048828125, -4.055957031250003],
              [-154.95625, -4.087988281250006],
            ],
          ],
          [
            [
              [-151.7826171875, -11.441015625],
              [-151.790869140625, -11.456835937500003],
              [-151.806689453125, -11.451269531250006],
              [-151.815966796875, -11.43115234375],
              [-151.819140625, -11.409277343750006],
              [-151.81328125, -11.391796875000011],
              [-151.802783203125, -11.392675781250006],
              [-151.79111328125, -11.414355468750003],
              [-151.7826171875, -11.441015625],
            ],
          ],
          [
            [
              [-155.863818359375, -5.626660156250011],
              [-155.887109375, -5.6318359375],
              [-155.91435546875, -5.631640625],
              [-155.9279296875, -5.618554687500009],
              [-155.92861328125, -5.607617187500011],
              [-155.919384765625, -5.607519531250006],
              [-155.910791015625, -5.609472656250006],
              [-155.872265625, -5.611328125],
              [-155.862353515625, -5.619140625],
              [-155.863818359375, -5.626660156250011],
            ],
          ],
          [
            [
              [-157.342138671875, 1.855566406249991],
              [-157.17578125, 1.73984375],
              [-157.246142578125, 1.731738281249989],
              [-157.4201171875, 1.787548828124997],
              [-157.578955078125, 1.902050781249997],
              [-157.531494140625, 1.926855468749991],
              [-157.508203125, 1.885693359374997],
              [-157.43583984375, 1.847265625],
              [-157.393212890625, 1.927685546874997],
              [-157.365185546875, 1.946093749999989],
              [-157.4921875, 2.029296875],
              [-157.44189453125, 2.025048828124994],
              [-157.321875, 1.968554687499989],
              [-157.342138671875, 1.855566406249991],
            ],
          ],
          [
            [
              [-159.3390625, 3.923535156249997],
              [-159.259326171875, 3.839208984374991],
              [-159.274755859375, 3.796582031249997],
              [-159.332275390625, 3.800488281249997],
              [-159.358740234375, 3.815332031249994],
              [-159.313671875, 3.82265625],
              [-159.30625, 3.83837890625],
              [-159.326806640625, 3.863183593749994],
              [-159.35419921875, 3.880517578124994],
              [-159.373193359375, 3.880517578124994],
              [-159.377783203125, 3.846630859374997],
              [-159.409033203125, 3.873242187499997],
              [-159.390966796875, 3.899560546874994],
              [-159.36904296875, 3.9169921875],
              [-159.3390625, 3.923535156249997],
            ],
          ],
          [
            [
              [-171.697607421875, -2.76640625],
              [-171.664990234375, -2.785546875],
              [-171.6396484375, -2.811230468750011],
              [-171.62763671875, -2.846972656250003],
              [-171.62841796875, -2.855859375],
              [-171.64736328125, -2.855566406250006],
              [-171.67060546875, -2.844433593750011],
              [-171.6873046875, -2.829785156250011],
              [-171.69609375, -2.82568359375],
              [-171.698291015625, -2.822265625],
              [-171.678369140625, -2.824511718750003],
              [-171.65537109375, -2.83984375],
              [-171.638525390625, -2.8466796875],
              [-171.63974609375, -2.829199218750006],
              [-171.660205078125, -2.798535156250011],
              [-171.67265625, -2.787988281250009],
              [-171.688037109375, -2.779101562500003],
              [-171.70595703125, -2.773144531250011],
              [-171.7181640625, -2.778613281250003],
              [-171.7248046875, -2.781347656250006],
              [-171.72763671875, -2.774121093750011],
              [-171.725146484375, -2.767871093750003],
              [-171.718896484375, -2.761425781250011],
              [-171.697607421875, -2.76640625],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 3,
        labelrank: 4,
        sovereignt: "Tonga",
        sov_a3: "TON",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Tonga",
        adm0_a3: "TON",
        geou_dif: 0,
        geounit: "Tonga",
        gu_a3: "TON",
        su_dif: 0,
        subunit: "Tonga",
        su_a3: "TON",
        brk_diff: 0,
        name: "Tonga",
        name_long: "Tonga",
        brk_a3: "TON",
        brk_name: "Tonga",
        brk_group: null,
        abbrev: "Tongo",
        postal: "TO",
        formal_en: "Kingdom of Tonga",
        formal_fr: null,
        name_ciawf: "Tonga",
        note_adm0: null,
        note_brk: null,
        name_sort: "Tonga",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 1,
        mapcolor9: 1,
        mapcolor13: 8,
        pop_est: 104494,
        pop_rank: 9,
        pop_year: 2019,
        gdp_md: 512,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        fips_10: "TN",
        iso_a2: "TO",
        iso_a2_eh: "TO",
        iso_a3: "TON",
        iso_a3_eh: "TON",
        iso_n3: "776",
        iso_n3_eh: "776",
        un_a3: "776",
        wb_a2: "TO",
        wb_a3: "TON",
        woe_id: 23424964,
        woe_id_eh: 23424964,
        woe_note: "Exact WOE match as country",
        adm0_iso: "TON",
        adm0_diff: null,
        adm0_tlc: "TON",
        adm0_a3_us: "TON",
        adm0_a3_fr: "TON",
        adm0_a3_ru: "TON",
        adm0_a3_es: "TON",
        adm0_a3_cn: "TON",
        adm0_a3_tw: "TON",
        adm0_a3_in: "TON",
        adm0_a3_np: "TON",
        adm0_a3_pk: "TON",
        adm0_a3_de: "TON",
        adm0_a3_gb: "TON",
        adm0_a3_br: "TON",
        adm0_a3_il: "TON",
        adm0_a3_ps: "TON",
        adm0_a3_sa: "TON",
        adm0_a3_eg: "TON",
        adm0_a3_ma: "TON",
        adm0_a3_pt: "TON",
        adm0_a3_ar: "TON",
        adm0_a3_jp: "TON",
        adm0_a3_ko: "TON",
        adm0_a3_vn: "TON",
        adm0_a3_tr: "TON",
        adm0_a3_id: "TON",
        adm0_a3_pl: "TON",
        adm0_a3_gr: "TON",
        adm0_a3_it: "TON",
        adm0_a3_nl: "TON",
        adm0_a3_se: "TON",
        adm0_a3_bd: "TON",
        adm0_a3_ua: "TON",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Oceania",
        region_un: "Oceania",
        subregion: "Polynesia",
        region_wb: "East Asia & Pacific",
        name_len: 5,
        long_len: 5,
        abbrev_len: 5,
        tiny: 3,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: -175.163014,
        label_y: -21.210026,
        ne_id: 1159321319,
        wikidataid: "Q678",
        name_ar: "تونغا",
        name_bn: "টোঙ্গা",
        name_de: "Tonga",
        name_en: "Tonga",
        name_es: "Tonga",
        name_fa: "تونگا",
        name_fr: "Tonga",
        name_el: "Τόνγκα",
        name_he: "טונגה",
        name_hi: "टोंगा",
        name_hu: "Tonga",
        name_id: "Tonga",
        name_it: "Tonga",
        name_ja: "トンガ",
        name_ko: "통가",
        name_nl: "Tonga",
        name_pl: "Tonga",
        name_pt: "Tonga",
        name_ru: "Тонга",
        name_sv: "Tonga",
        name_tr: "Tonga",
        name_uk: "Тонга",
        name_ur: "ٹونگا",
        name_vi: "Tonga",
        name_zh: "汤加",
        name_zht: "東加",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "TON.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-175.1619140625, -21.16933593750001],
              [-175.14765625, -21.16943359375],
              [-175.13193359375, -21.139746093750006],
              [-175.078173828125, -21.129003906250006],
              [-175.08408203125, -21.160742187500006],
              [-175.156591796875, -21.263671875],
              [-175.20234375, -21.2234375],
              [-175.33544921875, -21.15771484375],
              [-175.362353515625, -21.10683593750001],
              [-175.31806640625, -21.06826171875001],
              [-175.322607421875, -21.09931640625001],
              [-175.300439453125, -21.113378906250006],
              [-175.225390625, -21.11875],
              [-175.1580078125, -21.146484375],
              [-175.199755859375, -21.15566406250001],
              [-175.1619140625, -21.16933593750001],
            ],
          ],
          [
            [
              [-173.953515625, -18.63935546875001],
              [-173.99130859375, -18.69863281250001],
              [-174.009326171875, -18.69775390625],
              [-174.053125, -18.663378906250003],
              [-174.069140625, -18.640234375],
              [-174.00244140625, -18.570703125],
              [-173.96806640625, -18.56533203125001],
              [-173.921875, -18.58857421875001],
              [-173.923974609375, -18.608496093750006],
              [-173.953515625, -18.63935546875001],
            ],
          ],
          [
            [
              [-174.913134765625, -21.30048828125001],
              [-174.91865234375, -21.45058593750001],
              [-174.967529296875, -21.38173828125001],
              [-174.97294921875, -21.34980468750001],
              [-174.923486328125, -21.30341796875001],
              [-174.913134765625, -21.30048828125001],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 5,
        labelrank: 6,
        sovereignt: "Marshall Islands",
        sov_a3: "MHL",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Marshall Islands",
        adm0_a3: "MHL",
        geou_dif: 0,
        geounit: "Marshall Islands",
        gu_a3: "MHL",
        su_dif: 0,
        subunit: "Marshall Islands",
        su_a3: "MHL",
        brk_diff: 0,
        name: "Marshall Is.",
        name_long: "Marshall Islands",
        brk_a3: "MHL",
        brk_name: "Marshall Is.",
        brk_group: null,
        abbrev: "M. Is.",
        postal: "MH",
        formal_en: "Republic of the Marshall Islands",
        formal_fr: null,
        name_ciawf: "Marshall Islands",
        note_adm0: null,
        note_brk: null,
        name_sort: "Marshall Islands",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 5,
        mapcolor9: 5,
        mapcolor13: 3,
        pop_est: 58791,
        pop_rank: 8,
        pop_year: 2019,
        gdp_md: 221,
        gdp_year: 2018,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        fips_10: "RM",
        iso_a2: "MH",
        iso_a2_eh: "MH",
        iso_a3: "MHL",
        iso_a3_eh: "MHL",
        iso_n3: "584",
        iso_n3_eh: "584",
        un_a3: "584",
        wb_a2: "MH",
        wb_a3: "MHL",
        woe_id: 23424932,
        woe_id_eh: 23424932,
        woe_note: "Exact WOE match as country",
        adm0_iso: "MHL",
        adm0_diff: null,
        adm0_tlc: "MHL",
        adm0_a3_us: "MHL",
        adm0_a3_fr: "MHL",
        adm0_a3_ru: "MHL",
        adm0_a3_es: "MHL",
        adm0_a3_cn: "MHL",
        adm0_a3_tw: "MHL",
        adm0_a3_in: "MHL",
        adm0_a3_np: "MHL",
        adm0_a3_pk: "MHL",
        adm0_a3_de: "MHL",
        adm0_a3_gb: "MHL",
        adm0_a3_br: "MHL",
        adm0_a3_il: "MHL",
        adm0_a3_ps: "MHL",
        adm0_a3_sa: "MHL",
        adm0_a3_eg: "MHL",
        adm0_a3_ma: "MHL",
        adm0_a3_pt: "MHL",
        adm0_a3_ar: "MHL",
        adm0_a3_jp: "MHL",
        adm0_a3_ko: "MHL",
        adm0_a3_vn: "MHL",
        adm0_a3_tr: "MHL",
        adm0_a3_id: "MHL",
        adm0_a3_pl: "MHL",
        adm0_a3_gr: "MHL",
        adm0_a3_it: "MHL",
        adm0_a3_nl: "MHL",
        adm0_a3_se: "MHL",
        adm0_a3_bd: "MHL",
        adm0_a3_ua: "MHL",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Oceania",
        region_un: "Oceania",
        subregion: "Micronesia",
        region_wb: "East Asia & Pacific",
        name_len: 12,
        long_len: 16,
        abbrev_len: 6,
        tiny: 2,
        homepart: 1,
        min_zoom: 0,
        min_label: 5,
        max_label: 10,
        label_x: 171.193609,
        label_y: 7.082568,
        ne_id: 1159321057,
        wikidataid: "Q709",
        name_ar: "جزر مارشال",
        name_bn: "মার্শাল দ্বীপপুঞ্জ",
        name_de: "Marshallinseln",
        name_en: "Marshall Islands",
        name_es: "Islas Marshall",
        name_fa: "جزایر مارشال",
        name_fr: "Îles Marshall",
        name_el: "Νησιά Μάρσαλ",
        name_he: "איי מרשל",
        name_hi: "मार्शल द्वीपसमूह",
        name_hu: "Marshall-szigetek",
        name_id: "Kepulauan Marshall",
        name_it: "Isole Marshall",
        name_ja: "マーシャル諸島",
        name_ko: "마셜 제도",
        name_nl: "Marshalleilanden",
        name_pl: "Wyspy Marshalla",
        name_pt: "Ilhas Marshall",
        name_ru: "Маршалловы Острова",
        name_sv: "Marshallöarna",
        name_tr: "Marshall Adaları",
        name_uk: "Маршаллові Острови",
        name_ur: "جزائر مارشل",
        name_vi: "Quần đảo Marshall",
        name_zh: "马绍尔群岛",
        name_zht: "馬紹爾群島",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "MHL.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [169.63505859375005, 5.830078125],
              [169.6154296875, 5.7998046875],
              [169.59052734375, 5.801904296874994],
              [169.61220703125002, 5.824414062499997],
              [169.6271484375, 5.855810546874991],
              [169.65107421875, 5.945117187499989],
              [169.700390625, 5.97705078125],
              [169.7345703125, 6.01416015625],
              [169.72636718750005, 5.975683593749991],
              [169.67255859375, 5.935205078124994],
              [169.63505859375005, 5.830078125],
            ],
          ],
          [
            [
              [168.8302734375, 7.308984375],
              [168.8154296875, 7.293554687499991],
              [168.71923828125, 7.302734375],
              [168.67509765625005, 7.321923828124994],
              [168.67929687500003, 7.336230468749989],
              [168.75546875000003, 7.322460937499997],
              [168.8302734375, 7.308984375],
            ],
          ],
          [
            [
              [171.57734375, 7.048242187499994],
              [171.61474609375, 7.026611328125],
              [171.68837890625002, 7.028271484374997],
              [171.7568359375, 6.97314453125],
              [171.73046875, 6.976611328124989],
              [171.693359375, 7.000146484374994],
              [171.659375, 7.010058593749989],
              [171.61416015625002, 7.007177734374991],
              [171.5927734375, 7.016259765624994],
              [171.57734375, 7.048242187499994],
            ],
          ],
          [
            [
              [171.10195312500002, 7.138232421874989],
              [171.22695312500002, 7.086962890624989],
              [171.39375, 7.1109375],
              [171.36699218750005, 7.095556640624991],
              [171.3046875, 7.081152343749991],
              [171.26328125000003, 7.06875],
              [171.2353515625, 7.06875],
              [171.20234375, 7.073535156249989],
              [171.09550781250005, 7.109277343749994],
              [171.03574218750003, 7.156103515624991],
              [171.05039062500003, 7.171777343749994],
              [171.10195312500002, 7.138232421874989],
            ],
          ],
          [
            [
              [166.89033203125, 11.153076171875],
              [166.864453125, 11.146240234375],
              [166.8447265625, 11.153369140624989],
              [166.85888671875, 11.166308593749989],
              [166.88808593750002, 11.168652343749997],
              [166.8994140625, 11.1650390625],
              [166.89033203125, 11.153076171875],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 5,
        labelrank: 5,
        sovereignt: "Australia",
        sov_a3: "AU1",
        adm0_dif: 1,
        level: 2,
        type: "Dependency",
        tlc: "1",
        admin: "Norfolk Island",
        adm0_a3: "NFK",
        geou_dif: 0,
        geounit: "Norfolk Island",
        gu_a3: "NFK",
        su_dif: 0,
        subunit: "Norfolk Island",
        su_a3: "NFK",
        brk_diff: 0,
        name: "Norfolk Island",
        name_long: "Norfolk Island",
        brk_a3: "NFK",
        brk_name: "Norfolk Island",
        brk_group: null,
        abbrev: "Nfk. I.",
        postal: "NF",
        formal_en: "Territory of Norfolk Island",
        formal_fr: null,
        name_ciawf: "Norfolk Island",
        note_adm0: "Auz.",
        note_brk: null,
        name_sort: "Norfolk Island",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 2,
        mapcolor9: 2,
        mapcolor13: 7,
        pop_est: 2169,
        pop_rank: 4,
        pop_year: 2011,
        gdp_md: 32,
        gdp_year: 2016,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        fips_10: "NF",
        iso_a2: "NF",
        iso_a2_eh: "NF",
        iso_a3: "NFK",
        iso_a3_eh: "NFK",
        iso_n3: "574",
        iso_n3_eh: "574",
        un_a3: "574",
        wb_a2: "-99",
        wb_a3: "-99",
        woe_id: 23424905,
        woe_id_eh: 23424905,
        woe_note: "Exact WOE match as country",
        adm0_iso: "NFK",
        adm0_diff: null,
        adm0_tlc: "NFK",
        adm0_a3_us: "NFK",
        adm0_a3_fr: "NFK",
        adm0_a3_ru: "NFK",
        adm0_a3_es: "NFK",
        adm0_a3_cn: "NFK",
        adm0_a3_tw: "NFK",
        adm0_a3_in: "NFK",
        adm0_a3_np: "NFK",
        adm0_a3_pk: "NFK",
        adm0_a3_de: "NFK",
        adm0_a3_gb: "NFK",
        adm0_a3_br: "NFK",
        adm0_a3_il: "NFK",
        adm0_a3_ps: "NFK",
        adm0_a3_sa: "NFK",
        adm0_a3_eg: "NFK",
        adm0_a3_ma: "NFK",
        adm0_a3_pt: "NFK",
        adm0_a3_ar: "NFK",
        adm0_a3_jp: "NFK",
        adm0_a3_ko: "NFK",
        adm0_a3_vn: "NFK",
        adm0_a3_tr: "NFK",
        adm0_a3_id: "NFK",
        adm0_a3_pl: "NFK",
        adm0_a3_gr: "NFK",
        adm0_a3_it: "NFK",
        adm0_a3_nl: "NFK",
        adm0_a3_se: "NFK",
        adm0_a3_bd: "NFK",
        adm0_a3_ua: "NFK",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Oceania",
        region_un: "Oceania",
        subregion: "Australia and New Zealand",
        region_wb: "East Asia & Pacific",
        name_len: 14,
        long_len: 14,
        abbrev_len: 7,
        tiny: -99,
        homepart: -99,
        min_zoom: 0,
        min_label: 4.5,
        max_label: 9.5,
        label_x: 167.954531,
        label_y: -29.033042,
        ne_id: 1159320365,
        wikidataid: "Q31057",
        name_ar: "جزيرة نورفولك",
        name_bn: "নরফোক দ্বীপ",
        name_de: "Norfolkinsel",
        name_en: "Norfolk Island",
        name_es: "Isla Norfolk",
        name_fa: "جزیره نورفک",
        name_fr: "Île Norfolk",
        name_el: "Νόρφολκ",
        name_he: "נורפוק",
        name_hi: "नॉर्फ़ोक द्वीप",
        name_hu: "Norfolk-sziget",
        name_id: "Pulau Norfolk",
        name_it: "Isola Norfolk",
        name_ja: "ノーフォーク島",
        name_ko: "노퍽섬",
        name_nl: "Norfolk",
        name_pl: "Norfolk",
        name_pt: "Ilha Norfolk",
        name_ru: "Норфолк",
        name_sv: "Norfolkön",
        name_tr: "Norfolk Adası",
        name_uk: "острів Норфолк",
        name_ur: "جزیرہ نارفولک",
        name_vi: "Đảo Norfolk",
        name_zh: "诺福克岛",
        name_zht: "諾福克島",
        fclass_iso: "Admin-0 dependency",
        tlc_diff: null,
        fclass_tlc: "Admin-0 dependency",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "NFK.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [167.939453125, -29.017675781250006],
            [167.95976562500005, -29.0283203125],
            [167.978125, -29.034277343750006],
            [167.9904296875, -29.042089843750006],
            [167.98867187500002, -29.058984375],
            [167.97900390625, -29.07568359375],
            [167.96767578125002, -29.0828125],
            [167.96416015625005, -29.08535156250001],
            [167.9619140625, -29.088476562500006],
            [167.96064453125, -29.0921875],
            [167.96074218750005, -29.096289062500006],
            [167.95468750000003, -29.082128906250006],
            [167.94443359375003, -29.07294921875001],
            [167.9337890625, -29.07216796875001],
            [167.92656250000005, -29.0828125],
            [167.92041015625, -29.0828125],
            [167.91826171875005, -29.071875],
            [167.9142578125, -29.06191406250001],
            [167.91240234375005, -29.052832031250006],
            [167.91640625000002, -29.04511718750001],
            [167.9240234375, -29.03583984375001],
            [167.92460937500005, -29.02851562500001],
            [167.91855468750003, -29.02509765625001],
            [167.90615234375002, -29.028125],
            [167.92060546875, -29.013964843750003],
            [167.939453125, -29.017675781250006],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 3,
        labelrank: 4,
        sovereignt: "New Zealand",
        sov_a3: "NZ1",
        adm0_dif: 1,
        level: 2,
        type: "Dependency",
        tlc: "1",
        admin: "Cook Islands",
        adm0_a3: "COK",
        geou_dif: 0,
        geounit: "Cook Islands",
        gu_a3: "COK",
        su_dif: 0,
        subunit: "Cook Islands",
        su_a3: "COK",
        brk_diff: 0,
        name: "Cook Is.",
        name_long: "Cook Islands",
        brk_a3: "COK",
        brk_name: "Cook Is.",
        brk_group: null,
        abbrev: "Cook Is.",
        postal: "CK",
        formal_en: null,
        formal_fr: null,
        name_ciawf: "Cook Islands",
        note_adm0: "Assoc. with N.Z.",
        note_brk: "Assoc. with N.Z.",
        name_sort: "Cook Islands",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 3,
        mapcolor9: 4,
        mapcolor13: 4,
        pop_est: 17459,
        pop_rank: 6,
        pop_year: 2016,
        gdp_md: 244,
        gdp_year: 2010,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        fips_10: "CW",
        iso_a2: "CK",
        iso_a2_eh: "CK",
        iso_a3: "COK",
        iso_a3_eh: "COK",
        iso_n3: "184",
        iso_n3_eh: "184",
        un_a3: "184",
        wb_a2: "-99",
        wb_a3: "-99",
        woe_id: 23424795,
        woe_id_eh: 23424795,
        woe_note: "Exact WOE match as country",
        adm0_iso: "COK",
        adm0_diff: null,
        adm0_tlc: "COK",
        adm0_a3_us: "COK",
        adm0_a3_fr: "COK",
        adm0_a3_ru: "COK",
        adm0_a3_es: "COK",
        adm0_a3_cn: "COK",
        adm0_a3_tw: "COK",
        adm0_a3_in: "COK",
        adm0_a3_np: "COK",
        adm0_a3_pk: "COK",
        adm0_a3_de: "COK",
        adm0_a3_gb: "COK",
        adm0_a3_br: "COK",
        adm0_a3_il: "COK",
        adm0_a3_ps: "COK",
        adm0_a3_sa: "COK",
        adm0_a3_eg: "COK",
        adm0_a3_ma: "COK",
        adm0_a3_pt: "COK",
        adm0_a3_ar: "COK",
        adm0_a3_jp: "COK",
        adm0_a3_ko: "COK",
        adm0_a3_vn: "COK",
        adm0_a3_tr: "COK",
        adm0_a3_id: "COK",
        adm0_a3_pl: "COK",
        adm0_a3_gr: "COK",
        adm0_a3_it: "COK",
        adm0_a3_nl: "COK",
        adm0_a3_se: "COK",
        adm0_a3_bd: "COK",
        adm0_a3_ua: "COK",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Oceania",
        region_un: "Oceania",
        subregion: "Polynesia",
        region_wb: "East Asia & Pacific",
        name_len: 8,
        long_len: 12,
        abbrev_len: 8,
        tiny: 3,
        homepart: -99,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: -159.785675,
        label_y: -21.215993,
        ne_id: 1159321129,
        wikidataid: "Q26988",
        name_ar: "جزر كوك",
        name_bn: "কুক দ্বীপপুঞ্জ",
        name_de: "Cookinseln",
        name_en: "Cook Islands",
        name_es: "Islas Cook",
        name_fa: "جزایر کوک",
        name_fr: "Îles Cook",
        name_el: "Νήσοι Κουκ",
        name_he: "איי קוק",
        name_hi: "कुक द्वीपसमूह",
        name_hu: "Cook-szigetek",
        name_id: "Kepulauan Cook",
        name_it: "Isole Cook",
        name_ja: "クック諸島",
        name_ko: "쿡 제도",
        name_nl: "Cookeilanden",
        name_pl: "Wyspy Cooka",
        name_pt: "Ilhas Cook",
        name_ru: "Острова Кука",
        name_sv: "Cooköarna",
        name_tr: "Cook Adaları",
        name_uk: "Острови Кука",
        name_ur: "جزائر کک",
        name_vi: "Quần đảo Cook",
        name_zh: "库克群岛",
        name_zht: "庫克群島",
        fclass_iso: "Admin-0 dependency",
        tlc_diff: null,
        fclass_tlc: "Admin-0 dependency",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "COK.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-159.74052734375, -21.24921875000001],
            [-159.77255859375, -21.24951171875],
            [-159.8130859375, -21.24208984375001],
            [-159.839599609375, -21.238085937500003],
            [-159.84248046875, -21.229101562500006],
            [-159.83203125, -21.200488281250003],
            [-159.810595703125, -21.18642578125001],
            [-159.768359375, -21.1884765625],
            [-159.739501953125, -21.208105468750006],
            [-159.736865234375, -21.240625],
            [-159.74052734375, -21.24921875000001],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 3,
        labelrank: 4,
        sovereignt: "France",
        sov_a3: "FR1",
        adm0_dif: 1,
        level: 2,
        type: "Dependency",
        tlc: "1",
        admin: "Wallis and Futuna",
        adm0_a3: "WLF",
        geou_dif: 0,
        geounit: "Wallis and Futuna",
        gu_a3: "WLF",
        su_dif: 0,
        subunit: "Wallis and Futuna",
        su_a3: "WLF",
        brk_diff: 0,
        name: "Wallis and Futuna Is.",
        name_long: "Wallis and Futuna Islands",
        brk_a3: "WLF",
        brk_name: "Wallis and Futuna Islands",
        brk_group: null,
        abbrev: "Wlf.",
        postal: "WF",
        formal_en: "Wallis and Futuna Islands",
        formal_fr: null,
        name_ciawf: "Wallis and Futuna",
        note_adm0: "Fr.",
        note_brk: null,
        name_sort: "Wallis and Futuna",
        name_alt: null,
        mapcolor7: 7,
        mapcolor8: 5,
        mapcolor9: 9,
        mapcolor13: 11,
        pop_est: 11558,
        pop_rank: 6,
        pop_year: 2018,
        gdp_md: 60,
        gdp_year: 2016,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        fips_10: "WF",
        iso_a2: "WF",
        iso_a2_eh: "WF",
        iso_a3: "WLF",
        iso_a3_eh: "WLF",
        iso_n3: "876",
        iso_n3_eh: "876",
        un_a3: "876",
        wb_a2: "-99",
        wb_a3: "-99",
        woe_id: 23424989,
        woe_id_eh: 23424989,
        woe_note: "Exact WOE match as country",
        adm0_iso: "WLF",
        adm0_diff: null,
        adm0_tlc: "WLF",
        adm0_a3_us: "WLF",
        adm0_a3_fr: "WLF",
        adm0_a3_ru: "WLF",
        adm0_a3_es: "WLF",
        adm0_a3_cn: "WLF",
        adm0_a3_tw: "WLF",
        adm0_a3_in: "WLF",
        adm0_a3_np: "WLF",
        adm0_a3_pk: "WLF",
        adm0_a3_de: "WLF",
        adm0_a3_gb: "WLF",
        adm0_a3_br: "WLF",
        adm0_a3_il: "WLF",
        adm0_a3_ps: "WLF",
        adm0_a3_sa: "WLF",
        adm0_a3_eg: "WLF",
        adm0_a3_ma: "WLF",
        adm0_a3_pt: "WLF",
        adm0_a3_ar: "WLF",
        adm0_a3_jp: "WLF",
        adm0_a3_ko: "WLF",
        adm0_a3_vn: "WLF",
        adm0_a3_tr: "WLF",
        adm0_a3_id: "WLF",
        adm0_a3_pl: "WLF",
        adm0_a3_gr: "WLF",
        adm0_a3_it: "WLF",
        adm0_a3_nl: "WLF",
        adm0_a3_se: "WLF",
        adm0_a3_bd: "WLF",
        adm0_a3_ua: "WLF",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Oceania",
        region_un: "Oceania",
        subregion: "Polynesia",
        region_wb: "East Asia & Pacific",
        name_len: 21,
        long_len: 25,
        abbrev_len: 4,
        tiny: 3,
        homepart: -99,
        min_zoom: 0,
        min_label: 4.7,
        max_label: 9,
        label_x: -178.137436,
        label_y: -14.286415,
        ne_id: 1159320649,
        wikidataid: "Q35555",
        name_ar: "واليس وفوتونا",
        name_bn: "ওয়ালিস ও ফুটুনা",
        name_de: "Wallis und Futuna",
        name_en: "Wallis and Futuna",
        name_es: "Wallis y Futuna",
        name_fa: "والیس و فوتونا",
        name_fr: "Wallis-et-Futuna",
        name_el: "Ουαλίς και Φουτουνά",
        name_he: "ואליס ופוטונה",
        name_hi: "वालिस और फ़्यूचूना",
        name_hu: "Wallis és Futuna",
        name_id: "Wallis dan Futuna",
        name_it: "Wallis e Futuna",
        name_ja: "ウォリス・フツナ",
        name_ko: "왈리스 푸투나",
        name_nl: "Wallis en Futuna",
        name_pl: "Wallis i Futuna",
        name_pt: "Wallis e Futuna",
        name_ru: "Уоллис и Футуна",
        name_sv: "Wallis- och Futunaöarna",
        name_tr: "Wallis ve Futuna Adaları",
        name_uk: "Волліс і Футуна",
        name_ur: "والس و فتونہ",
        name_vi: "Wallis và Futuna",
        name_zh: "瓦利斯和富图纳",
        name_zht: "瓦利斯和富圖納",
        fclass_iso: "Admin-0 dependency",
        tlc_diff: null,
        fclass_tlc: "Admin-0 dependency",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "WLF.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-176.160595703125, -13.3328125],
              [-176.176904296875, -13.340917968750006],
              [-176.195361328125, -13.301660156250009],
              [-176.17119140625, -13.242578125],
              [-176.14794921875, -13.2216796875],
              [-176.128076171875, -13.268164062500006],
              [-176.160595703125, -13.3328125],
            ],
          ],
          [
            [
              [-178.0466796875, -14.318359375],
              [-178.1033203125, -14.324902343750011],
              [-178.15859375, -14.311914062500009],
              [-178.194384765625, -14.25546875],
              [-178.17802734375, -14.231640625000011],
              [-178.142236328125, -14.242578125],
              [-178.105029296875, -14.2841796875],
              [-178.04365234375, -14.30322265625],
              [-178.0466796875, -14.318359375],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 4,
        sovereignt: "Samoa",
        sov_a3: "WSM",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Samoa",
        adm0_a3: "WSM",
        geou_dif: 0,
        geounit: "Samoa",
        gu_a3: "WSM",
        su_dif: 0,
        subunit: "Samoa",
        su_a3: "WSM",
        brk_diff: 0,
        name: "Samoa",
        name_long: "Samoa",
        brk_a3: "WSM",
        brk_name: "Samoa",
        brk_group: null,
        abbrev: "Samoa",
        postal: "WS",
        formal_en: "Independent State of Samoa",
        formal_fr: null,
        name_ciawf: "Samoa",
        note_adm0: null,
        note_brk: null,
        name_sort: "Samoa",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 3,
        mapcolor9: 4,
        mapcolor13: 6,
        pop_est: 197097,
        pop_rank: 9,
        pop_year: 2019,
        gdp_md: 852,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "4. Lower middle income",
        fips_10: "WS",
        iso_a2: "WS",
        iso_a2_eh: "WS",
        iso_a3: "WSM",
        iso_a3_eh: "WSM",
        iso_n3: "882",
        iso_n3_eh: "882",
        un_a3: "882",
        wb_a2: "WS",
        wb_a3: "WSM",
        woe_id: 23424992,
        woe_id_eh: 23424992,
        woe_note: "Exact WOE match as country",
        adm0_iso: "WSM",
        adm0_diff: null,
        adm0_tlc: "WSM",
        adm0_a3_us: "WSM",
        adm0_a3_fr: "WSM",
        adm0_a3_ru: "WSM",
        adm0_a3_es: "WSM",
        adm0_a3_cn: "WSM",
        adm0_a3_tw: "WSM",
        adm0_a3_in: "WSM",
        adm0_a3_np: "WSM",
        adm0_a3_pk: "WSM",
        adm0_a3_de: "WSM",
        adm0_a3_gb: "WSM",
        adm0_a3_br: "WSM",
        adm0_a3_il: "WSM",
        adm0_a3_ps: "WSM",
        adm0_a3_sa: "WSM",
        adm0_a3_eg: "WSM",
        adm0_a3_ma: "WSM",
        adm0_a3_pt: "WSM",
        adm0_a3_ar: "WSM",
        adm0_a3_jp: "WSM",
        adm0_a3_ko: "WSM",
        adm0_a3_vn: "WSM",
        adm0_a3_tr: "WSM",
        adm0_a3_id: "WSM",
        adm0_a3_pl: "WSM",
        adm0_a3_gr: "WSM",
        adm0_a3_it: "WSM",
        adm0_a3_nl: "WSM",
        adm0_a3_se: "WSM",
        adm0_a3_bd: "WSM",
        adm0_a3_ua: "WSM",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Oceania",
        region_un: "Oceania",
        subregion: "Polynesia",
        region_wb: "East Asia & Pacific",
        name_len: 5,
        long_len: 5,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: -172.438241,
        label_y: -13.639139,
        ne_id: 1159321423,
        wikidataid: "Q683",
        name_ar: "ساموا",
        name_bn: "সামোয়া",
        name_de: "Samoa",
        name_en: "Samoa",
        name_es: "Samoa",
        name_fa: "ساموآ",
        name_fr: "Samoa",
        name_el: "Σαμόα",
        name_he: "סמואה",
        name_hi: "समोआ",
        name_hu: "Szamoa",
        name_id: "Samoa",
        name_it: "Samoa",
        name_ja: "サモア",
        name_ko: "사모아",
        name_nl: "Samoa",
        name_pl: "Samoa",
        name_pt: "Samoa",
        name_ru: "Самоа",
        name_sv: "Samoa",
        name_tr: "Samoa",
        name_uk: "Самоа",
        name_ur: "سامووا",
        name_vi: "Samoa",
        name_zh: "萨摩亚",
        name_zht: "薩摩亞",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "WSM.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-172.33349609375, -13.465234375],
              [-172.221533203125, -13.5595703125],
              [-172.17685546875, -13.684667968750006],
              [-172.224951171875, -13.804296875],
              [-172.330859375, -13.774707031250003],
              [-172.484521484375, -13.800195312500009],
              [-172.535693359375, -13.791699218750011],
              [-172.6587890625, -13.644824218750003],
              [-172.744091796875, -13.578710937500006],
              [-172.778515625, -13.516796875000011],
              [-172.66962890625, -13.523828125],
              [-172.510888671875, -13.4828125],
              [-172.33349609375, -13.465234375],
            ],
          ],
          [
            [
              [-171.4541015625, -14.046484375],
              [-171.72822265625, -14.047265625],
              [-171.86376953125, -14.002050781250006],
              [-171.9119140625, -14.001660156250011],
              [-172.028076171875, -13.906835937500006],
              [-172.0458984375, -13.857128906250011],
              [-171.98486328125, -13.824414062500011],
              [-171.858154296875, -13.80712890625],
              [-171.60390625, -13.879199218750003],
              [-171.5654296875, -13.943066406250011],
              [-171.506884765625, -13.949902343750011],
              [-171.461376953125, -13.977636718750006],
              [-171.449560546875, -14.0224609375],
              [-171.4541015625, -14.046484375],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 4,
        labelrank: 3,
        sovereignt: "Solomon Islands",
        sov_a3: "SLB",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Solomon Islands",
        adm0_a3: "SLB",
        geou_dif: 0,
        geounit: "Solomon Islands",
        gu_a3: "SLB",
        su_dif: 0,
        subunit: "Solomon Islands",
        su_a3: "SLB",
        brk_diff: 0,
        name: "Solomon Is.",
        name_long: "Solomon Islands",
        brk_a3: "SLB",
        brk_name: "Solomon Is.",
        brk_group: null,
        abbrev: "S. Is.",
        postal: "SB",
        formal_en: null,
        formal_fr: null,
        name_ciawf: "Solomon Islands",
        note_adm0: null,
        note_brk: null,
        name_sort: "Solomon Islands",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 4,
        mapcolor9: 1,
        mapcolor13: 6,
        pop_est: 669823,
        pop_rank: 11,
        pop_year: 2019,
        gdp_md: 1589,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "4. Lower middle income",
        fips_10: "BP",
        iso_a2: "SB",
        iso_a2_eh: "SB",
        iso_a3: "SLB",
        iso_a3_eh: "SLB",
        iso_n3: "090",
        iso_n3_eh: "090",
        un_a3: "090",
        wb_a2: "SB",
        wb_a3: "SLB",
        woe_id: 23424766,
        woe_id_eh: 23424766,
        woe_note: "Exact WOE match as country",
        adm0_iso: "SLB",
        adm0_diff: null,
        adm0_tlc: "SLB",
        adm0_a3_us: "SLB",
        adm0_a3_fr: "SLB",
        adm0_a3_ru: "SLB",
        adm0_a3_es: "SLB",
        adm0_a3_cn: "SLB",
        adm0_a3_tw: "SLB",
        adm0_a3_in: "SLB",
        adm0_a3_np: "SLB",
        adm0_a3_pk: "SLB",
        adm0_a3_de: "SLB",
        adm0_a3_gb: "SLB",
        adm0_a3_br: "SLB",
        adm0_a3_il: "SLB",
        adm0_a3_ps: "SLB",
        adm0_a3_sa: "SLB",
        adm0_a3_eg: "SLB",
        adm0_a3_ma: "SLB",
        adm0_a3_pt: "SLB",
        adm0_a3_ar: "SLB",
        adm0_a3_jp: "SLB",
        adm0_a3_ko: "SLB",
        adm0_a3_vn: "SLB",
        adm0_a3_tr: "SLB",
        adm0_a3_id: "SLB",
        adm0_a3_pl: "SLB",
        adm0_a3_gr: "SLB",
        adm0_a3_it: "SLB",
        adm0_a3_nl: "SLB",
        adm0_a3_se: "SLB",
        adm0_a3_bd: "SLB",
        adm0_a3_ua: "SLB",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Oceania",
        region_un: "Oceania",
        subregion: "Melanesia",
        region_wb: "East Asia & Pacific",
        name_len: 11,
        long_len: 15,
        abbrev_len: 6,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 3,
        max_label: 8,
        label_x: 159.170468,
        label_y: -8.029548,
        ne_id: 1159321249,
        wikidataid: "Q685",
        name_ar: "جزر سليمان",
        name_bn: "সলোমন দ্বীপপুঞ্জ",
        name_de: "Salomonen",
        name_en: "Solomon Islands",
        name_es: "Islas Salomón",
        name_fa: "جزایر سلیمان",
        name_fr: "Îles Salomon",
        name_el: "Νησιά Σολομώντα",
        name_he: "איי שלמה",
        name_hi: "सोलोमन द्वीपसमूह",
        name_hu: "Salamon-szigetek",
        name_id: "Kepulauan Solomon",
        name_it: "Isole Salomone",
        name_ja: "ソロモン諸島",
        name_ko: "솔로몬 제도",
        name_nl: "Salomonseilanden",
        name_pl: "Wyspy Salomona",
        name_pt: "Ilhas Salomão",
        name_ru: "Соломоновы Острова",
        name_sv: "Salomonöarna",
        name_tr: "Solomon Adaları",
        name_uk: "Соломонові Острови",
        name_ur: "جزائر سلیمان",
        name_vi: "Quần đảo Solomon",
        name_zh: "所罗门群岛",
        name_zht: "索羅門群島",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "SLB.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [159.6876953125, -8.507910156250006],
              [159.64003906250002, -8.521484375],
              [159.56923828125002, -8.484765625],
              [159.53847656250002, -8.451367187500011],
              [159.55322265625, -8.39921875],
              [159.59462890625002, -8.379492187500006],
              [159.6416015625, -8.414453125],
              [159.64628906250005, -8.450390625000011],
              [159.6876953125, -8.507910156250006],
            ],
          ],
          [
            [
              [166.92919921875, -11.665136718750006],
              [166.8408203125, -11.681347656250011],
              [166.80595703125005, -11.67734375],
              [166.74746093750002, -11.5908203125],
              [166.79091796875002, -11.5712890625],
              [166.85546875, -11.578808593750011],
              [166.87509765625003, -11.6296875],
              [166.92919921875, -11.665136718750006],
            ],
          ],
          [
            [
              [166.13320312500002, -10.7578125],
              [166.05332031250003, -10.775097656250011],
              [166.02792968750003, -10.770214843750011],
              [165.96816406250002, -10.779492187500011],
              [165.90400390625, -10.851464843750009],
              [165.85654296875003, -10.84140625],
              [165.8193359375, -10.844042968750003],
              [165.791015625, -10.784765625],
              [165.79042968750002, -10.756054687500011],
              [165.8359375, -10.760644531250009],
              [165.85986328125, -10.703027343750009],
              [165.9091796875, -10.67431640625],
              [166.02382812500002, -10.6611328125],
              [166.12568359375, -10.679882812500011],
              [166.162109375, -10.693066406250011],
              [166.1298828125, -10.745214843750006],
              [166.13320312500002, -10.7578125],
            ],
          ],
          [
            [
              [160.57626953125003, -11.7978515625],
              [160.50654296875, -11.832226562500011],
              [160.44306640625, -11.81494140625],
              [160.39453125, -11.788867187500003],
              [160.35507812500003, -11.7119140625],
              [160.27021484375, -11.663964843750009],
              [160.14951171875003, -11.643945312500009],
              [160.1, -11.610742187500009],
              [160.087109375, -11.594335937500006],
              [160.00351562500003, -11.57958984375],
              [159.97929687500005, -11.537988281250009],
              [159.986328125, -11.494726562500006],
              [160, -11.471972656250003],
              [160.07734375, -11.492871093750011],
              [160.44873046875, -11.695898437500006],
              [160.537109375, -11.7587890625],
              [160.57626953125003, -11.7978515625],
            ],
          ],
          [
            [
              [161.5478515625, -9.625683593750011],
              [161.55888671875005, -9.732714843750003],
              [161.55380859375003, -9.769726562500011],
              [161.47792968750002, -9.691113281250011],
              [161.44248046875003, -9.718945312500011],
              [161.40976562500003, -9.681640625],
              [161.41201171875002, -9.600390625],
              [161.4169921875, -9.513769531250006],
              [161.40224609375002, -9.448144531250009],
              [161.36416015625002, -9.353417968750009],
              [161.40683593750003, -9.368457031250003],
              [161.5478515625, -9.625683593750011],
            ],
          ],
          [
            [
              [159.18857421875003, -9.12353515625],
              [159.17509765625005, -9.1259765625],
              [159.128125, -9.11376953125],
              [159.07109375000005, -9.109667968750003],
              [159.036328125, -9.075],
              [159.07763671875, -9.025390625],
              [159.12978515625002, -8.993066406250009],
              [159.15371093750002, -9.001367187500009],
              [159.17607421875005, -9.022070312500006],
              [159.22841796875002, -9.029980468750011],
              [159.23398437500003, -9.09375],
              [159.18857421875003, -9.12353515625],
            ],
          ],
          [
            [
              [160.1681640625, -8.995507812500009],
              [160.22568359375003, -9.009570312500003],
              [160.25351562500003, -9.00732421875],
              [160.3193359375, -9.061132812500006],
              [160.40751953125005, -9.140332031250011],
              [160.37148437500002, -9.18125],
              [160.3, -9.160351562500011],
              [160.27597656250003, -9.168652343750011],
              [160.26816406250003, -9.163183593750006],
              [160.253125, -9.1234375],
              [160.17519531250002, -9.084082031250006],
              [160.10537109375002, -9.080761718750011],
              [160.09628906250003, -9.033984375],
              [160.1681640625, -8.995507812500009],
            ],
          ],
          [
            [
              [157.76347656250005, -8.2421875],
              [157.82626953125003, -8.324023437500003],
              [157.8984375, -8.50634765625],
              [157.88544921875, -8.569140625],
              [157.83369140625, -8.57265625],
              [157.8193359375, -8.612011718750011],
              [157.74921875, -8.523632812500011],
              [157.65595703125, -8.499707031250011],
              [157.587890625, -8.445410156250006],
              [157.56455078125003, -8.337792968750009],
              [157.55800781250002, -8.269921875],
              [157.50419921875005, -8.25830078125],
              [157.35136718750005, -8.275292968750009],
              [157.30244140625, -8.333300781250003],
              [157.232421875, -8.31484375],
              [157.21757812500005, -8.262792968750006],
              [157.228515625, -8.211621093750011],
              [157.32158203125005, -8.161230468750006],
              [157.340625, -8.096386718750011],
              [157.43339843750005, -7.984667968750003],
              [157.490625, -7.965722656250009],
              [157.598828125, -8.005957031250006],
              [157.6123046875, -8.16484375],
              [157.65126953125002, -8.216796875],
              [157.76347656250005, -8.2421875],
            ],
          ],
          [
            [
              [158.20078125000003, -8.821972656250011],
              [158.17880859375003, -8.82578125],
              [158.15537109375003, -8.7859375],
              [158.2099609375, -8.678125],
              [158.236328125, -8.764843750000011],
              [158.25341796875, -8.79736328125],
              [158.20078125000003, -8.821972656250011],
            ],
          ],
          [
            [
              [158.10791015625, -8.684179687500006],
              [158.00947265625, -8.763085937500009],
              [157.93759765625003, -8.736425781250006],
              [157.87929687500002, -8.66875],
              [157.8984375, -8.587207031250003],
              [157.90927734375003, -8.565625],
              [157.93828125000005, -8.5609375],
              [157.9669921875, -8.544238281250003],
              [157.99843750000002, -8.508203125],
              [158.10546875, -8.536816406250011],
              [158.13222656250002, -8.556640625],
              [158.068359375, -8.606640625000011],
              [158.08964843750005, -8.62265625],
              [158.103515625, -8.646484375],
              [158.10791015625, -8.684179687500006],
            ],
          ],
          [
            [
              [157.38896484375005, -8.713476562500006],
              [157.38906250000002, -8.728125],
              [157.33388671875002, -8.7],
              [157.21230468750002, -8.565039062500006],
              [157.23378906250002, -8.519921875],
              [157.34511718750002, -8.432421875],
              [157.37949218750003, -8.4208984375],
              [157.41093750000005, -8.47509765625],
              [157.38349609375, -8.555078125],
              [157.34707031250002, -8.575488281250003],
              [157.3322265625, -8.650683593750003],
              [157.38896484375005, -8.713476562500006],
            ],
          ],
          [
            [
              [156.68789062500002, -7.923046875000011],
              [156.66875, -7.936816406250003],
              [156.63535156250003, -7.8828125],
              [156.61103515625, -7.865917968750011],
              [156.61171875000002, -7.805761718750006],
              [156.5109375, -7.7078125],
              [156.50244140625, -7.640234375],
              [156.56093750000002, -7.574023437500003],
              [156.6396484375, -7.612597656250003],
              [156.71767578125002, -7.695703125],
              [156.80908203125, -7.722851562500011],
              [156.790234375, -7.777929687500006],
              [156.7080078125, -7.876953125],
              [156.68789062500002, -7.923046875000011],
            ],
          ],
          [
            [
              [156.60390625000002, -8.171582031250011],
              [156.59169921875002, -8.1962890625],
              [156.53964843750003, -8.072949218750011],
              [156.54228515625005, -8.010839843750006],
              [156.55126953125, -7.970996093750003],
              [156.5703125, -7.958789062500003],
              [156.61240234375003, -8.09619140625],
              [156.60390625000002, -8.171582031250011],
            ],
          ],
          [
            [
              [157.171875, -8.108105468750011],
              [157.15, -8.123242187500011],
              [157.0412109375, -8.117480468750003],
              [156.95830078125005, -8.014355468750011],
              [156.958984375, -7.93798828125],
              [157.02412109375, -7.867871093750011],
              [157.102734375, -7.85546875],
              [157.14580078125005, -7.882617187500003],
              [157.18613281250003, -7.941210937500003],
              [157.20058593750002, -8.015917968750003],
              [157.19150390625003, -8.081835937500003],
              [157.171875, -8.108105468750011],
            ],
          ],
          [
            [
              [155.83984375, -7.09716796875],
              [155.73935546875003, -7.12109375],
              [155.67753906250005, -7.088964843750006],
              [155.70498046875002, -7.0126953125],
              [155.73896484375, -6.972949218750003],
              [155.86464843750002, -7.043261718750003],
              [155.83984375, -7.09716796875],
            ],
          ],
          [
            [
              [157.64541015625002, -8.758886718750006],
              [157.64316406250003, -8.794042968750006],
              [157.58583984375002, -8.783105468750009],
              [157.45791015625002, -8.730175781250011],
              [157.45351562500002, -8.705957031250009],
              [157.5263671875, -8.697070312500003],
              [157.579296875, -8.703710937500006],
              [157.6232421875, -8.734570312500011],
              [157.64541015625002, -8.758886718750006],
            ],
          ],
          [
            [
              [159.75039062500002, -9.272656250000011],
              [159.97060546875002, -9.433300781250011],
              [160.06533203125002, -9.418652343750011],
              [160.35458984375003, -9.421582031250011],
              [160.52519531250005, -9.536230468750006],
              [160.62548828125, -9.5888671875],
              [160.6818359375, -9.691601562500011],
              [160.75146484375, -9.715039062500011],
              [160.79433593750002, -9.767382812500003],
              [160.81894531250003, -9.86279296875],
              [160.80166015625002, -9.878320312500009],
              [160.7130859375, -9.913867187500003],
              [160.64921875000005, -9.928613281250009],
              [160.481640625, -9.894726562500011],
              [160.32109375000005, -9.8212890625],
              [160.00234375000002, -9.812402343750009],
              [159.8537109375, -9.79150390625],
              [159.802734375, -9.763476562500003],
              [159.75546875000003, -9.72607421875],
              [159.68046875000005, -9.636816406250006],
              [159.621875, -9.532128906250009],
              [159.6123046875, -9.470703125],
              [159.607421875, -9.353808593750003],
              [159.62558593750003, -9.311230468750011],
              [159.68632812500005, -9.268652343750006],
              [159.75039062500002, -9.272656250000011],
            ],
          ],
          [
            [
              [159.8791015625, -8.534277343750006],
              [159.880859375, -8.557421875],
              [159.74648437500002, -8.473828125000011],
              [159.64453125, -8.371679687500006],
              [159.35410156250003, -8.260449218750011],
              [159.29169921875, -8.203417968750003],
              [159.2392578125, -8.1962890625],
              [159.09023437500002, -8.103320312500003],
              [158.94404296875, -8.040722656250011],
              [158.85458984375003, -7.959765625],
              [158.83183593750005, -7.926660156250009],
              [158.77802734375, -7.906933593750011],
              [158.68623046875, -7.818066406250011],
              [158.59697265625005, -7.759082031250003],
              [158.5654296875, -7.6513671875],
              [158.47880859375005, -7.5771484375],
              [158.45742187500002, -7.544726562500003],
              [158.734375, -7.604296875],
              [158.86279296875, -7.722363281250011],
              [158.97246093750005, -7.789160156250006],
              [159.01054687500005, -7.83740234375],
              [159.109375, -7.903515625000011],
              [159.19804687500005, -7.909570312500009],
              [159.28681640625, -7.976171875],
              [159.36767578125, -7.994140625],
              [159.43144531250005, -8.029003906250011],
              [159.84306640625005, -8.326953125],
              [159.7939453125, -8.406054687500003],
              [159.8486328125, -8.463476562500006],
              [159.8791015625, -8.534277343750006],
            ],
          ],
          [
            [
              [157.48671875000002, -7.330371093750003],
              [157.51865234375003, -7.365625],
              [157.44130859375002, -7.425683593750009],
              [157.33925781250002, -7.39306640625],
              [157.31728515625002, -7.359375],
              [157.3146484375, -7.341503906250011],
              [157.24345703125005, -7.35302734375],
              [157.1015625, -7.323632812500009],
              [156.904296875, -7.18046875],
              [156.69580078125, -6.9109375],
              [156.49492187500005, -6.761621093750009],
              [156.45742187500002, -6.715234375],
              [156.45253906250002, -6.63828125],
              [156.47939453125002, -6.60888671875],
              [156.60419921875, -6.641015625],
              [156.76542968750005, -6.7640625],
              [157.0302734375, -6.891992187500009],
              [157.1025390625, -6.957226562500011],
              [157.1484375, -7.11376953125],
              [157.193359375, -7.160351562500011],
              [157.3361328125, -7.280468750000011],
              [157.41162109375, -7.30859375],
              [157.45156250000002, -7.313671875000011],
              [157.48671875000002, -7.330371093750003],
            ],
          ],
          [
            [
              [160.74941406250002, -8.31396484375],
              [160.99765625000003, -8.612011718750011],
              [160.98779296875, -8.66484375],
              [160.9541015625, -8.698925781250011],
              [160.9443359375, -8.799023437500011],
              [160.9755859375, -8.8375],
              [161.04345703125, -8.855078125],
              [161.15869140625, -8.961816406250009],
              [161.20468750000003, -9.092480468750011],
              [161.20878906250005, -9.132617187500003],
              [161.25664062500005, -9.191992187500006],
              [161.25849609375, -9.31689453125],
              [161.36796875000005, -9.490332031250006],
              [161.37753906250003, -9.57373046875],
              [161.3673828125, -9.611230468750009],
              [161.321875, -9.589550781250011],
              [161.19101562500003, -9.392871093750003],
              [161.04150390625, -9.308007812500009],
              [161.0244140625, -9.271484375],
              [160.87343750000002, -9.156835937500006],
              [160.77207031250003, -8.9638671875],
              [160.66259765625, -8.62060546875],
              [160.7140625, -8.539257812500011],
              [160.59042968750003, -8.372753906250011],
              [160.59628906250003, -8.328222656250006],
              [160.64853515625003, -8.33837890625],
              [160.684765625, -8.336328125],
              [160.7021484375, -8.316503906250006],
              [160.74941406250002, -8.31396484375],
            ],
          ],
          [
            [
              [161.71533203125, -10.387304687500006],
              [161.84111328125005, -10.44609375],
              [161.91435546875005, -10.436425781250009],
              [162.02285156250002, -10.476855468750003],
              [162.10537109375002, -10.453808593750011],
              [162.15683593750003, -10.506054687500011],
              [162.28720703125003, -10.7099609375],
              [162.28798828125002, -10.776171875],
              [162.37333984375005, -10.8232421875],
              [162.30126953125, -10.832128906250006],
              [162.20126953125003, -10.807812500000011],
              [162.12363281250003, -10.824414062500011],
              [162.04267578125, -10.784863281250011],
              [161.90585937500003, -10.764355468750011],
              [161.78681640625, -10.716894531250006],
              [161.53789062500005, -10.56640625],
              [161.5392578125, -10.491308593750006],
              [161.49912109375003, -10.45458984375],
              [161.48701171875, -10.361425781250006],
              [161.39794921875, -10.331933593750009],
              [161.2939453125, -10.326464843750003],
              [161.28554687500002, -10.282421875000011],
              [161.30478515625003, -10.204394531250003],
              [161.38232421875, -10.20556640625],
              [161.47568359375003, -10.237988281250011],
              [161.65380859375, -10.351855468750003],
              [161.69794921875, -10.371289062500011],
              [161.71533203125, -10.387304687500006],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 5,
        labelrank: 6,
        sovereignt: "Tuvalu",
        sov_a3: "TUV",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Tuvalu",
        adm0_a3: "TUV",
        geou_dif: 0,
        geounit: "Tuvalu",
        gu_a3: "TUV",
        su_dif: 0,
        subunit: "Tuvalu",
        su_a3: "TUV",
        brk_diff: 0,
        name: "Tuvalu",
        name_long: "Tuvalu",
        brk_a3: "TUV",
        brk_name: "Tuvalu",
        brk_group: null,
        abbrev: "Tuv.",
        postal: "TV",
        formal_en: "Tuvalu",
        formal_fr: null,
        name_ciawf: "Tuvalu",
        note_adm0: null,
        note_brk: null,
        name_sort: "Tuvalu",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 3,
        mapcolor9: 8,
        mapcolor13: 5,
        pop_est: 11646,
        pop_rank: 6,
        pop_year: 2019,
        gdp_md: 47,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "3. Upper middle income",
        fips_10: "TV",
        iso_a2: "TV",
        iso_a2_eh: "TV",
        iso_a3: "TUV",
        iso_a3_eh: "TUV",
        iso_n3: "798",
        iso_n3_eh: "798",
        un_a3: "798",
        wb_a2: "TV",
        wb_a3: "TUV",
        woe_id: 23424970,
        woe_id_eh: 23424970,
        woe_note: "Exact WOE match as country",
        adm0_iso: "TUV",
        adm0_diff: null,
        adm0_tlc: "TUV",
        adm0_a3_us: "TUV",
        adm0_a3_fr: "TUV",
        adm0_a3_ru: "TUV",
        adm0_a3_es: "TUV",
        adm0_a3_cn: "TUV",
        adm0_a3_tw: "TUV",
        adm0_a3_in: "TUV",
        adm0_a3_np: "TUV",
        adm0_a3_pk: "TUV",
        adm0_a3_de: "TUV",
        adm0_a3_gb: "TUV",
        adm0_a3_br: "TUV",
        adm0_a3_il: "TUV",
        adm0_a3_ps: "TUV",
        adm0_a3_sa: "TUV",
        adm0_a3_eg: "TUV",
        adm0_a3_ma: "TUV",
        adm0_a3_pt: "TUV",
        adm0_a3_ar: "TUV",
        adm0_a3_jp: "TUV",
        adm0_a3_ko: "TUV",
        adm0_a3_vn: "TUV",
        adm0_a3_tr: "TUV",
        adm0_a3_id: "TUV",
        adm0_a3_pl: "TUV",
        adm0_a3_gr: "TUV",
        adm0_a3_it: "TUV",
        adm0_a3_nl: "TUV",
        adm0_a3_se: "TUV",
        adm0_a3_bd: "TUV",
        adm0_a3_ua: "TUV",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Oceania",
        region_un: "Oceania",
        subregion: "Polynesia",
        region_wb: "East Asia & Pacific",
        name_len: 6,
        long_len: 6,
        abbrev_len: 4,
        tiny: 5,
        homepart: 1,
        min_zoom: 0,
        min_label: 5,
        max_label: 10,
        label_x: 179.209587,
        label_y: -8.513717,
        ne_id: 1159321333,
        wikidataid: "Q672",
        name_ar: "توفالو",
        name_bn: "টুভালু",
        name_de: "Tuvalu",
        name_en: "Tuvalu",
        name_es: "Tuvalu",
        name_fa: "تووالو",
        name_fr: "Tuvalu",
        name_el: "Τουβαλού",
        name_he: "טובאלו",
        name_hi: "तुवालू",
        name_hu: "Tuvalu",
        name_id: "Tuvalu",
        name_it: "Tuvalu",
        name_ja: "ツバル",
        name_ko: "투발루",
        name_nl: "Tuvalu",
        name_pl: "Tuvalu",
        name_pt: "Tuvalu",
        name_ru: "Тувалу",
        name_sv: "Tuvalu",
        name_tr: "Tuvalu",
        name_uk: "Тувалу",
        name_ur: "تووالو",
        name_vi: "Tuvalu",
        name_zh: "图瓦卢",
        name_zht: "吐瓦魯",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "TUV.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [179.21367187500005, -8.52421875],
            [179.20058593750002, -8.534960937500003],
            [179.19570312500002, -8.534765625],
            [179.20087890625, -8.512109375],
            [179.19794921875, -8.488671875],
            [179.19853515625005, -8.470019531250003],
            [179.20302734375002, -8.46630859375],
            [179.21162109375, -8.488085937500003],
            [179.21660156250005, -8.514843750000011],
            [179.21367187500005, -8.52421875],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 4,
        sovereignt: "Vanuatu",
        sov_a3: "VUT",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Vanuatu",
        adm0_a3: "VUT",
        geou_dif: 0,
        geounit: "Vanuatu",
        gu_a3: "VUT",
        su_dif: 0,
        subunit: "Vanuatu",
        su_a3: "VUT",
        brk_diff: 0,
        name: "Vanuatu",
        name_long: "Vanuatu",
        brk_a3: "VUT",
        brk_name: "Vanuatu",
        brk_group: null,
        abbrev: "Van.",
        postal: "VU",
        formal_en: "Republic of Vanuatu",
        formal_fr: null,
        name_ciawf: "Vanuatu",
        note_adm0: null,
        note_brk: null,
        name_sort: "Vanuatu",
        name_alt: null,
        mapcolor7: 6,
        mapcolor8: 3,
        mapcolor9: 7,
        mapcolor13: 3,
        pop_est: 299882,
        pop_rank: 10,
        pop_year: 2019,
        gdp_md: 934,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "4. Lower middle income",
        fips_10: "NH",
        iso_a2: "VU",
        iso_a2_eh: "VU",
        iso_a3: "VUT",
        iso_a3_eh: "VUT",
        iso_n3: "548",
        iso_n3_eh: "548",
        un_a3: "548",
        wb_a2: "VU",
        wb_a3: "VUT",
        woe_id: 23424907,
        woe_id_eh: 23424907,
        woe_note: "Exact WOE match as country",
        adm0_iso: "VUT",
        adm0_diff: null,
        adm0_tlc: "VUT",
        adm0_a3_us: "VUT",
        adm0_a3_fr: "VUT",
        adm0_a3_ru: "VUT",
        adm0_a3_es: "VUT",
        adm0_a3_cn: "VUT",
        adm0_a3_tw: "VUT",
        adm0_a3_in: "VUT",
        adm0_a3_np: "VUT",
        adm0_a3_pk: "VUT",
        adm0_a3_de: "VUT",
        adm0_a3_gb: "VUT",
        adm0_a3_br: "VUT",
        adm0_a3_il: "VUT",
        adm0_a3_ps: "VUT",
        adm0_a3_sa: "VUT",
        adm0_a3_eg: "VUT",
        adm0_a3_ma: "VUT",
        adm0_a3_pt: "VUT",
        adm0_a3_ar: "VUT",
        adm0_a3_jp: "VUT",
        adm0_a3_ko: "VUT",
        adm0_a3_vn: "VUT",
        adm0_a3_tr: "VUT",
        adm0_a3_id: "VUT",
        adm0_a3_pl: "VUT",
        adm0_a3_gr: "VUT",
        adm0_a3_it: "VUT",
        adm0_a3_nl: "VUT",
        adm0_a3_se: "VUT",
        adm0_a3_bd: "VUT",
        adm0_a3_ua: "VUT",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Oceania",
        region_un: "Oceania",
        subregion: "Melanesia",
        region_wb: "East Asia & Pacific",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: 2,
        homepart: 1,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: 166.908762,
        label_y: -15.37153,
        ne_id: 1159321421,
        wikidataid: "Q686",
        name_ar: "فانواتو",
        name_bn: "ভানুয়াতু",
        name_de: "Vanuatu",
        name_en: "Vanuatu",
        name_es: "Vanuatu",
        name_fa: "وانواتو",
        name_fr: "Vanuatu",
        name_el: "Βανουάτου",
        name_he: "ונואטו",
        name_hi: "वानूआटू",
        name_hu: "Vanuatu",
        name_id: "Vanuatu",
        name_it: "Vanuatu",
        name_ja: "バヌアツ",
        name_ko: "바누아투",
        name_nl: "Vanuatu",
        name_pl: "Vanuatu",
        name_pt: "Vanuatu",
        name_ru: "Вануату",
        name_sv: "Vanuatu",
        name_tr: "Vanuatu",
        name_uk: "Вануату",
        name_ur: "وانواتو",
        name_vi: "Vanuatu",
        name_zh: "瓦努阿图",
        name_zht: "萬那杜",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "VUT.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [166.74580078125, -14.826855468750011],
              [166.81015625000003, -15.157421875000011],
              [166.88515625000002, -15.15673828125],
              [166.92343750000003, -15.13916015625],
              [166.96757812500005, -15.061718750000011],
              [166.9873046875, -14.940039062500006],
              [167.0265625, -14.92265625],
              [167.07558593750002, -14.935644531250006],
              [167.05429687500003, -14.974414062500003],
              [167.0685546875, -15.07177734375],
              [167.1064453125, -15.125585937500006],
              [167.13164062500005, -15.135351562500006],
              [167.18203125000002, -15.389746093750006],
              [167.20078125000003, -15.443066406250011],
              [167.19960937500002, -15.485742187500009],
              [167.0939453125, -15.580859375],
              [166.93662109375003, -15.578027343750009],
              [166.82578125000003, -15.634863281250006],
              [166.75830078125, -15.631152343750003],
              [166.758984375, -15.566796875],
              [166.69892578125, -15.515625],
              [166.6310546875, -15.406054687500003],
              [166.64785156250002, -15.211523437500006],
              [166.52724609375002, -14.85009765625],
              [166.52607421875, -14.759765625],
              [166.5673828125, -14.641796875000011],
              [166.60781250000002, -14.636523437500003],
              [166.66259765625, -14.735058593750011],
              [166.74580078125, -14.826855468750011],
            ],
          ],
          [
            [
              [167.4125, -16.09589843750001],
              [167.45859375000003, -16.117578125],
              [167.48369140625005, -16.117578125],
              [167.49873046875, -16.16621093750001],
              [167.64199218750002, -16.26328125],
              [167.68134765625, -16.260546875],
              [167.71445312500003, -16.31367187500001],
              [167.77597656250003, -16.34052734375001],
              [167.79257812500003, -16.394628906250006],
              [167.83662109375, -16.44970703125],
              [167.759765625, -16.51640625],
              [167.61142578125003, -16.498632812500006],
              [167.5263671875, -16.574316406250006],
              [167.44931640625003, -16.554980468750003],
              [167.43613281250003, -16.515234375],
              [167.446875, -16.501953125],
              [167.40097656250003, -16.40058593750001],
              [167.38027343750002, -16.245703125],
              [167.34921875000003, -16.15449218750001],
              [167.315625, -16.115527343750003],
              [167.24609375, -16.14960937500001],
              [167.21806640625005, -16.1552734375],
              [167.15146484375003, -16.08046875],
              [167.18300781250002, -15.928515625],
              [167.19951171875005, -15.885058593750003],
              [167.25371093750005, -15.876757812500003],
              [167.33574218750005, -15.916699218750011],
              [167.4125, -16.09589843750001],
            ],
          ],
          [
            [
              [168.44677734375, -16.77880859375],
              [168.4765625, -16.79365234375001],
              [168.46015625, -16.835058593750006],
              [168.32275390625, -16.78779296875001],
              [168.21230468750002, -16.80615234375],
              [168.18144531250005, -16.804003906250003],
              [168.14853515625003, -16.765722656250006],
              [168.12431640625005, -16.690039062500006],
              [168.13535156250003, -16.63691406250001],
              [168.1818359375, -16.599902343750003],
              [168.19921875, -16.593847656250006],
              [168.23378906250002, -16.6396484375],
              [168.26542968750005, -16.67080078125001],
              [168.29609375, -16.684179687500006],
              [168.36601562500005, -16.7587890625],
              [168.44677734375, -16.77880859375],
            ],
          ],
          [
            [
              [168.29667968750005, -16.336523437500006],
              [168.18242187500005, -16.346777343750006],
              [168.0216796875, -16.315625],
              [167.95703125, -16.272265625],
              [167.92900390625005, -16.22871093750001],
              [167.9845703125, -16.19648437500001],
              [168.06425781250005, -16.18125],
              [168.16386718750005, -16.081640625],
              [168.19833984375003, -16.11982421875001],
              [168.23544921875003, -16.23134765625001],
              [168.27568359375005, -16.264941406250003],
              [168.29794921875003, -16.29873046875001],
              [168.29667968750005, -16.336523437500006],
            ],
          ],
          [
            [
              [168.44580078125, -17.54218750000001],
              [168.54541015625, -17.684667968750006],
              [168.5849609375, -17.695898437500006],
              [168.524609375, -17.79804687500001],
              [168.3994140625, -17.807226562500006],
              [168.25166015625, -17.78076171875],
              [168.305859375, -17.745703125],
              [168.27783203125, -17.7060546875],
              [168.23320312500005, -17.698046875],
              [168.18203125000002, -17.71699218750001],
              [168.158203125, -17.710546875],
              [168.19091796875, -17.644824218750003],
              [168.27314453125, -17.55224609375],
              [168.29746093750003, -17.544921875],
              [168.31953125, -17.5439453125],
              [168.341015625, -17.552050781250003],
              [168.44580078125, -17.54218750000001],
            ],
          ],
          [
            [
              [167.911328125, -15.4359375],
              [167.84423828125, -15.481835937500009],
              [167.72021484375, -15.477441406250009],
              [167.67421875000002, -15.4515625],
              [167.82626953125003, -15.31201171875],
              [168.00253906250003, -15.283203125],
              [167.911328125, -15.4359375],
            ],
          ],
          [
            [
              [168.212890625, -15.970410156250011],
              [168.19619140625002, -15.9716796875],
              [168.17929687500003, -15.925683593750009],
              [168.12285156250005, -15.680859375000011],
              [168.15996093750005, -15.461816406250009],
              [168.18349609375002, -15.508203125],
              [168.2677734375, -15.892285156250011],
              [168.25634765625, -15.955175781250006],
              [168.212890625, -15.970410156250011],
            ],
          ],
          [
            [
              [168.18916015625, -15.328710937500006],
              [168.171875, -15.390625],
              [168.13046875000003, -15.318945312500006],
              [168.10419921875, -15.0166015625],
              [168.11494140625, -14.988574218750003],
              [168.13642578125, -14.986425781250006],
              [168.18691406250002, -15.196875],
              [168.18916015625, -15.328710937500006],
            ],
          ],
          [
            [
              [167.2189453125, -15.72412109375],
              [167.20078125000003, -15.750097656250006],
              [167.0947265625, -15.685253906250011],
              [167.11904296875002, -15.62255859375],
              [167.234375, -15.64501953125],
              [167.2189453125, -15.72412109375],
            ],
          ],
          [
            [
              [167.58486328125002, -14.260937500000011],
              [167.54326171875005, -14.311621093750006],
              [167.43027343750003, -14.294921875],
              [167.403515625, -14.281542968750003],
              [167.41074218750003, -14.197460937500011],
              [167.43906250000003, -14.16845703125],
              [167.50644531250003, -14.1421875],
              [167.59892578125005, -14.183789062500011],
              [167.58486328125002, -14.260937500000011],
            ],
          ],
          [
            [
              [167.48886718750003, -13.9072265625],
              [167.47421875000003, -13.917089843750006],
              [167.45107421875002, -13.909375],
              [167.391796875, -13.788378906250003],
              [167.40683593750003, -13.748046875],
              [167.48105468750003, -13.70947265625],
              [167.54726562500002, -13.776660156250003],
              [167.55351562500005, -13.81396484375],
              [167.55302734375005, -13.845703125],
              [167.54287109375002, -13.873144531250006],
              [167.49863281250003, -13.884570312500003],
              [167.48886718750003, -13.9072265625],
            ],
          ],
          [
            [
              [169.89628906250005, -20.186621093750006],
              [169.86113281250005, -20.241796875],
              [169.80703125000002, -20.24111328125001],
              [169.7375, -20.2021484375],
              [169.75068359375, -20.1533203125],
              [169.82949218750002, -20.14472656250001],
              [169.85234375000005, -20.14794921875],
              [169.89628906250005, -20.186621093750006],
            ],
          ],
          [
            [
              [169.49130859375003, -19.540136718750006],
              [169.4384765625, -19.648828125],
              [169.34726562500003, -19.62353515625],
              [169.2619140625, -19.545019531250006],
              [169.21748046875, -19.476367187500003],
              [169.24746093750002, -19.3447265625],
              [169.29111328125003, -19.32177734375],
              [169.33671875000005, -19.32929687500001],
              [169.35996093750003, -19.4578125],
              [169.49130859375003, -19.540136718750006],
            ],
          ],
          [
            [
              [169.334375, -18.940234375],
              [169.28828125, -18.988574218750003],
              [169.248046875, -18.98330078125001],
              [168.98691406250003, -18.87128906250001],
              [168.99785156250005, -18.8251953125],
              [168.98710937500005, -18.707617187500006],
              [169.0158203125, -18.64375],
              [169.087890625, -18.617480468750003],
              [169.14384765625005, -18.63105468750001],
              [169.17802734375005, -18.72509765625],
              [169.25576171875002, -18.76337890625001],
              [169.201171875, -18.795703125],
              [169.29619140625005, -18.866796875],
              [169.334375, -18.940234375],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 3,
        labelrank: 6,
        sovereignt: "Nauru",
        sov_a3: "NRU",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Nauru",
        adm0_a3: "NRU",
        geou_dif: 0,
        geounit: "Nauru",
        gu_a3: "NRU",
        su_dif: 0,
        subunit: "Nauru",
        su_a3: "NRU",
        brk_diff: 0,
        name: "Nauru",
        name_long: "Nauru",
        brk_a3: "NRU",
        brk_name: "Nauru",
        brk_group: null,
        abbrev: "Nauru",
        postal: "NR",
        formal_en: "Republic of Nauru",
        formal_fr: null,
        name_ciawf: "Nauru",
        note_adm0: null,
        note_brk: null,
        name_sort: "Nauru",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 7,
        mapcolor9: 6,
        mapcolor13: 9,
        pop_est: 12581,
        pop_rank: 6,
        pop_year: 2019,
        gdp_md: 118,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        fips_10: "NR",
        iso_a2: "NR",
        iso_a2_eh: "NR",
        iso_a3: "NRU",
        iso_a3_eh: "NRU",
        iso_n3: "520",
        iso_n3_eh: "520",
        un_a3: "520",
        wb_a2: "-99",
        wb_a3: "-99",
        woe_id: 23424912,
        woe_id_eh: 23424912,
        woe_note: "Exact WOE match as country",
        adm0_iso: "NRU",
        adm0_diff: null,
        adm0_tlc: "NRU",
        adm0_a3_us: "NRU",
        adm0_a3_fr: "NRU",
        adm0_a3_ru: "NRU",
        adm0_a3_es: "NRU",
        adm0_a3_cn: "NRU",
        adm0_a3_tw: "NRU",
        adm0_a3_in: "NRU",
        adm0_a3_np: "NRU",
        adm0_a3_pk: "NRU",
        adm0_a3_de: "NRU",
        adm0_a3_gb: "NRU",
        adm0_a3_br: "NRU",
        adm0_a3_il: "NRU",
        adm0_a3_ps: "NRU",
        adm0_a3_sa: "NRU",
        adm0_a3_eg: "NRU",
        adm0_a3_ma: "NRU",
        adm0_a3_pt: "NRU",
        adm0_a3_ar: "NRU",
        adm0_a3_jp: "NRU",
        adm0_a3_ko: "NRU",
        adm0_a3_vn: "NRU",
        adm0_a3_tr: "NRU",
        adm0_a3_id: "NRU",
        adm0_a3_pl: "NRU",
        adm0_a3_gr: "NRU",
        adm0_a3_it: "NRU",
        adm0_a3_nl: "NRU",
        adm0_a3_se: "NRU",
        adm0_a3_bd: "NRU",
        adm0_a3_ua: "NRU",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Oceania",
        region_un: "Oceania",
        subregion: "Micronesia",
        region_wb: "East Asia & Pacific",
        name_len: 5,
        long_len: 5,
        abbrev_len: 5,
        tiny: 3,
        homepart: 1,
        min_zoom: 0,
        min_label: 5,
        max_label: 10,
        label_x: 166.932644,
        label_y: -0.520261,
        ne_id: 1159321123,
        wikidataid: "Q697",
        name_ar: "ناورو",
        name_bn: "নাউরু",
        name_de: "Nauru",
        name_en: "Nauru",
        name_es: "Nauru",
        name_fa: "نائورو",
        name_fr: "Nauru",
        name_el: "Ναουρού",
        name_he: "נאורו",
        name_hi: "नौरु",
        name_hu: "Nauru",
        name_id: "Nauru",
        name_it: "Nauru",
        name_ja: "ナウル",
        name_ko: "나우루",
        name_nl: "Nauru",
        name_pl: "Nauru",
        name_pt: "Nauru",
        name_ru: "Науру",
        name_sv: "Nauru",
        name_tr: "Nauru",
        name_uk: "Науру",
        name_ur: "ناورو",
        name_vi: "Nauru",
        name_zh: "瑙鲁",
        name_zht: "諾魯",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "NRU.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [166.95839843750002, -0.5166015625],
            [166.93896484375, -0.55078125],
            [166.91640625000002, -0.546484375],
            [166.90703125000005, -0.523730468750003],
            [166.91357421875, -0.499121093750006],
            [166.93896484375, -0.489355468750006],
            [166.95566406250003, -0.496972656250009],
            [166.95839843750002, -0.5166015625],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 3,
        labelrank: 6,
        sovereignt: "Federated States of Micronesia",
        sov_a3: "FSM",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Federated States of Micronesia",
        adm0_a3: "FSM",
        geou_dif: 0,
        geounit: "Federated States of Micronesia",
        gu_a3: "FSM",
        su_dif: 0,
        subunit: "Federated States of Micronesia",
        su_a3: "FSM",
        brk_diff: 0,
        name: "Micronesia",
        name_long: "Federated States of Micronesia",
        brk_a3: "FSM",
        brk_name: "Micronesia",
        brk_group: null,
        abbrev: "F.S.M.",
        postal: "FSM",
        formal_en: "Federated States of Micronesia",
        formal_fr: null,
        name_ciawf: "Micronesia, Federated States of",
        note_adm0: null,
        note_brk: null,
        name_sort: "Micronesia, Federated States of",
        name_alt: null,
        mapcolor7: 5,
        mapcolor8: 2,
        mapcolor9: 4,
        mapcolor13: 13,
        pop_est: 113815,
        pop_rank: 9,
        pop_year: 2019,
        gdp_md: 401,
        gdp_year: 2018,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        fips_10: "FM",
        iso_a2: "FM",
        iso_a2_eh: "FM",
        iso_a3: "FSM",
        iso_a3_eh: "FSM",
        iso_n3: "583",
        iso_n3_eh: "583",
        un_a3: "583",
        wb_a2: "FM",
        wb_a3: "FSM",
        woe_id: 23424815,
        woe_id_eh: 23424815,
        woe_note: "Exact WOE match as country",
        adm0_iso: "FSM",
        adm0_diff: null,
        adm0_tlc: "FSM",
        adm0_a3_us: "FSM",
        adm0_a3_fr: "FSM",
        adm0_a3_ru: "FSM",
        adm0_a3_es: "FSM",
        adm0_a3_cn: "FSM",
        adm0_a3_tw: "FSM",
        adm0_a3_in: "FSM",
        adm0_a3_np: "FSM",
        adm0_a3_pk: "FSM",
        adm0_a3_de: "FSM",
        adm0_a3_gb: "FSM",
        adm0_a3_br: "FSM",
        adm0_a3_il: "FSM",
        adm0_a3_ps: "FSM",
        adm0_a3_sa: "FSM",
        adm0_a3_eg: "FSM",
        adm0_a3_ma: "FSM",
        adm0_a3_pt: "FSM",
        adm0_a3_ar: "FSM",
        adm0_a3_jp: "FSM",
        adm0_a3_ko: "FSM",
        adm0_a3_vn: "FSM",
        adm0_a3_tr: "FSM",
        adm0_a3_id: "FSM",
        adm0_a3_pl: "FSM",
        adm0_a3_gr: "FSM",
        adm0_a3_it: "FSM",
        adm0_a3_nl: "FSM",
        adm0_a3_se: "FSM",
        adm0_a3_bd: "FSM",
        adm0_a3_ua: "FSM",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Oceania",
        region_un: "Oceania",
        subregion: "Micronesia",
        region_wb: "East Asia & Pacific",
        name_len: 10,
        long_len: 30,
        abbrev_len: 6,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 5,
        max_label: 10,
        label_x: 158.234019,
        label_y: 6.887553,
        ne_id: 1159320691,
        wikidataid: "Q702",
        name_ar: "ولايات ميكرونيسيا المتحدة",
        name_bn: "মাইক্রোনেশিয়া যুক্তরাজ্য",
        name_de: "Föderierte Staaten von Mikronesien",
        name_en: "Federated States of Micronesia",
        name_es: "Estados Federados de Micronesia",
        name_fa: "میکرونزی",
        name_fr: "États fédérés de Micronésie",
        name_el: "Ομόσπονδες Πολιτείες της Μικρονησίας",
        name_he: "מיקרונזיה",
        name_hi: "माइक्रोनेशिया के संघीकृत राज्य",
        name_hu: "Mikronéziai Szövetségi Államok",
        name_id: "Mikronesia",
        name_it: "Stati Federati di Micronesia",
        name_ja: "ミクロネシア連邦",
        name_ko: "미크로네시아 연방",
        name_nl: "Micronesia",
        name_pl: "Mikronezja",
        name_pt: "Micronésia",
        name_ru: "Микронезия",
        name_sv: "Mikronesiska federationen",
        name_tr: "Mikronezya",
        name_uk: "Мікронезія",
        name_ur: "مائیکرونیشیا",
        name_vi: "Micronesia",
        name_zh: "密克罗尼西亚联邦",
        name_zht: "密克羅尼西亞聯邦",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "FSM.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [162.98320312500005, 5.325732421874989],
              [162.99345703125005, 5.277246093749994],
              [162.9298828125, 5.30078125],
              [162.92109375, 5.317919921874989],
              [162.958203125, 5.335009765624989],
              [162.98320312500005, 5.325732421874989],
            ],
          ],
          [
            [
              [138.14267578125003, 9.500683593749997],
              [138.06708984375, 9.419042968749991],
              [138.06191406250002, 9.445751953124997],
              [138.08505859375003, 9.494580078124997],
              [138.11689453125, 9.550195312499994],
              [138.14697265625, 9.58359375],
              [138.18583984375005, 9.593310546874989],
              [138.21357421875, 9.547216796874991],
              [138.18251953125002, 9.507373046874989],
              [138.14267578125003, 9.500683593749997],
            ],
          ],
          [
            [
              [151.64775390625005, 7.34619140625],
              [151.63945312500005, 7.3330078125],
              [151.5783203125, 7.338085937499997],
              [151.56972656250002, 7.345507812499989],
              [151.57509765625002, 7.351318359375],
              [151.60429687500005, 7.357226562499989],
              [151.60781250000002, 7.375390625],
              [151.59287109375003, 7.379248046874991],
              [151.6056640625, 7.388720703124989],
              [151.62949218750003, 7.390429687499989],
              [151.64326171875, 7.379248046874991],
              [151.65048828125003, 7.362841796874989],
              [151.64775390625005, 7.34619140625],
            ],
          ],
          [
            [
              [151.88144531250003, 7.43203125],
              [151.8642578125, 7.4267578125],
              [151.85595703125, 7.431787109374994],
              [151.85996093750003, 7.457373046874991],
              [151.86533203125003, 7.466162109374991],
              [151.8818359375, 7.467089843749989],
              [151.91054687500002, 7.46015625],
              [151.91259765625, 7.453857421875],
              [151.88144531250003, 7.43203125],
            ],
          ],
          [
            [
              [158.31484375000002, 6.813671875],
              [158.25654296875, 6.791015625],
              [158.18339843750005, 6.80126953125],
              [158.16083984375, 6.8828125],
              [158.12763671875, 6.904638671874991],
              [158.134765625, 6.94482421875],
              [158.18613281250003, 6.977734375],
              [158.29462890625, 6.951074218749994],
              [158.3349609375, 6.893164062499991],
              [158.309375, 6.854638671874994],
              [158.31484375000002, 6.813671875],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 3,
        labelrank: 4,
        sovereignt: "New Zealand",
        sov_a3: "NZ1",
        adm0_dif: 1,
        level: 2,
        type: "Dependency",
        tlc: "1",
        admin: "Niue",
        adm0_a3: "NIU",
        geou_dif: 0,
        geounit: "Niue",
        gu_a3: "NIU",
        su_dif: 0,
        subunit: "Niue",
        su_a3: "NIU",
        brk_diff: 0,
        name: "Niue",
        name_long: "Niue",
        brk_a3: "NIU",
        brk_name: "Niue",
        brk_group: null,
        abbrev: "Niue",
        postal: "NU",
        formal_en: null,
        formal_fr: null,
        name_ciawf: "Niue",
        note_adm0: "Assoc. with N.Z.",
        note_brk: "Assoc. with N.Z.",
        name_sort: "Niue",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 3,
        mapcolor9: 4,
        mapcolor13: 4,
        pop_est: 1620,
        pop_rank: 3,
        pop_year: 2018,
        gdp_md: 10,
        gdp_year: 2003,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        fips_10: "NE",
        iso_a2: "NU",
        iso_a2_eh: "NU",
        iso_a3: "NIU",
        iso_a3_eh: "NIU",
        iso_n3: "570",
        iso_n3_eh: "570",
        un_a3: "570",
        wb_a2: "-99",
        wb_a3: "-99",
        woe_id: 23424904,
        woe_id_eh: 23424904,
        woe_note: "Exact WOE match as country",
        adm0_iso: "NIU",
        adm0_diff: null,
        adm0_tlc: "NIU",
        adm0_a3_us: "NIU",
        adm0_a3_fr: "NIU",
        adm0_a3_ru: "NIU",
        adm0_a3_es: "NIU",
        adm0_a3_cn: "NIU",
        adm0_a3_tw: "NIU",
        adm0_a3_in: "NIU",
        adm0_a3_np: "NIU",
        adm0_a3_pk: "NIU",
        adm0_a3_de: "NIU",
        adm0_a3_gb: "NIU",
        adm0_a3_br: "NIU",
        adm0_a3_il: "NIU",
        adm0_a3_ps: "NIU",
        adm0_a3_sa: "NIU",
        adm0_a3_eg: "NIU",
        adm0_a3_ma: "NIU",
        adm0_a3_pt: "NIU",
        adm0_a3_ar: "NIU",
        adm0_a3_jp: "NIU",
        adm0_a3_ko: "NIU",
        adm0_a3_vn: "NIU",
        adm0_a3_tr: "NIU",
        adm0_a3_id: "NIU",
        adm0_a3_pl: "NIU",
        adm0_a3_gr: "NIU",
        adm0_a3_it: "NIU",
        adm0_a3_nl: "NIU",
        adm0_a3_se: "NIU",
        adm0_a3_bd: "NIU",
        adm0_a3_ua: "NIU",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Oceania",
        region_un: "Oceania",
        subregion: "Polynesia",
        region_wb: "East Asia & Pacific",
        name_len: 4,
        long_len: 4,
        abbrev_len: 4,
        tiny: 3,
        homepart: -99,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: -169.862565,
        label_y: -19.045956,
        ne_id: 1159321133,
        wikidataid: "Q34020",
        name_ar: "نييوي",
        name_bn: "নিউয়ে",
        name_de: "Niue",
        name_en: "Niue",
        name_es: "Niue",
        name_fa: "نیووی",
        name_fr: "Niue",
        name_el: "Νιούε",
        name_he: "ניואה",
        name_hi: "निउए",
        name_hu: "Niue",
        name_id: "Niue",
        name_it: "Niue",
        name_ja: "ニウエ",
        name_ko: "니우에",
        name_nl: "Niue",
        name_pl: "Niue",
        name_pt: "Niue",
        name_ru: "Ниуэ",
        name_sv: "Niue",
        name_tr: "Niue",
        name_uk: "Ніуе",
        name_ur: "نیووے",
        name_vi: "Niue",
        name_zh: "纽埃",
        name_zht: "紐埃",
        fclass_iso: "Admin-0 dependency",
        tlc_diff: null,
        fclass_tlc: "Admin-0 dependency",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "NIU.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-169.80341796875, -19.0830078125],
            [-169.90380859375, -19.13789062500001],
            [-169.94833984375, -19.072851562500006],
            [-169.908740234375, -18.990234375],
            [-169.861572265625, -18.96865234375001],
            [-169.834033203125, -18.96601562500001],
            [-169.793408203125, -19.042578125],
            [-169.80341796875, -19.0830078125],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 4,
        labelrank: 6,
        sovereignt: "Palau",
        sov_a3: "PLW",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Palau",
        adm0_a3: "PLW",
        geou_dif: 0,
        geounit: "Palau",
        gu_a3: "PLW",
        su_dif: 0,
        subunit: "Palau",
        su_a3: "PLW",
        brk_diff: 0,
        name: "Palau",
        name_long: "Palau",
        brk_a3: "PLW",
        brk_name: "Palau",
        brk_group: null,
        abbrev: "Palau",
        postal: "PW",
        formal_en: "Republic of Palau",
        formal_fr: null,
        name_ciawf: "Palau",
        note_adm0: null,
        note_brk: null,
        name_sort: "Palau",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 5,
        mapcolor9: 1,
        mapcolor13: 12,
        pop_est: 18008,
        pop_rank: 6,
        pop_year: 2019,
        gdp_md: 268,
        gdp_year: 2019,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        fips_10: "PS",
        iso_a2: "PW",
        iso_a2_eh: "PW",
        iso_a3: "PLW",
        iso_a3_eh: "PLW",
        iso_n3: "585",
        iso_n3_eh: "585",
        un_a3: "585",
        wb_a2: "PW",
        wb_a3: "PLW",
        woe_id: 23424927,
        woe_id_eh: 23424927,
        woe_note: "Exact WOE match as country",
        adm0_iso: "PLW",
        adm0_diff: null,
        adm0_tlc: "PLW",
        adm0_a3_us: "PLW",
        adm0_a3_fr: "PLW",
        adm0_a3_ru: "PLW",
        adm0_a3_es: "PLW",
        adm0_a3_cn: "PLW",
        adm0_a3_tw: "PLW",
        adm0_a3_in: "PLW",
        adm0_a3_np: "PLW",
        adm0_a3_pk: "PLW",
        adm0_a3_de: "PLW",
        adm0_a3_gb: "PLW",
        adm0_a3_br: "PLW",
        adm0_a3_il: "PLW",
        adm0_a3_ps: "PLW",
        adm0_a3_sa: "PLW",
        adm0_a3_eg: "PLW",
        adm0_a3_ma: "PLW",
        adm0_a3_pt: "PLW",
        adm0_a3_ar: "PLW",
        adm0_a3_jp: "PLW",
        adm0_a3_ko: "PLW",
        adm0_a3_vn: "PLW",
        adm0_a3_tr: "PLW",
        adm0_a3_id: "PLW",
        adm0_a3_pl: "PLW",
        adm0_a3_gr: "PLW",
        adm0_a3_it: "PLW",
        adm0_a3_nl: "PLW",
        adm0_a3_se: "PLW",
        adm0_a3_bd: "PLW",
        adm0_a3_ua: "PLW",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Oceania",
        region_un: "Oceania",
        subregion: "Micronesia",
        region_wb: "East Asia & Pacific",
        name_len: 5,
        long_len: 5,
        abbrev_len: 5,
        tiny: 2,
        homepart: 1,
        min_zoom: 0,
        min_label: 5,
        max_label: 10,
        label_x: 134.580157,
        label_y: 7.518252,
        ne_id: 1159321171,
        wikidataid: "Q695",
        name_ar: "بالاو",
        name_bn: "পালাউ",
        name_de: "Palau",
        name_en: "Palau",
        name_es: "Palaos",
        name_fa: "پالائو",
        name_fr: "Palaos",
        name_el: "Παλάου",
        name_he: "פלאו",
        name_hi: "पलाउ",
        name_hu: "Palau",
        name_id: "Palau",
        name_it: "Palau",
        name_ja: "パラオ",
        name_ko: "팔라우",
        name_nl: "Palau",
        name_pl: "Palau",
        name_pt: "Palau",
        name_ru: "Палау",
        name_sv: "Palau",
        name_tr: "Palau",
        name_uk: "Республіка Палау",
        name_ur: "پلاؤ",
        name_vi: "Palau",
        name_zh: "帕劳",
        name_zht: "帛琉",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "PLW.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [131.17236328125, 3.026220703124991],
              [131.149609375, 3.021875],
              [131.1349609375, 3.025244140624991],
              [131.13671875, 3.039453125],
              [131.1515625, 3.054101562499994],
              [131.17236328125, 3.060595703124989],
              [131.18789062500002, 3.055615234374997],
              [131.18632812500005, 3.042089843749991],
              [131.17236328125, 3.026220703124991],
            ],
          ],
          [
            [
              [134.59541015625, 7.38203125],
              [134.53466796875, 7.360644531249989],
              [134.50625, 7.437109375],
              [134.51572265625003, 7.52578125],
              [134.55595703125005, 7.593945312499997],
              [134.59970703125003, 7.615771484374989],
              [134.60869140625005, 7.623583984374989],
              [134.65117187500005, 7.712109375],
              [134.65957031250002, 7.66328125],
              [134.63271484375002, 7.501318359374991],
              [134.59824218750003, 7.438281249999989],
              [134.59541015625, 7.38203125],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 3,
        labelrank: 6,
        sovereignt: "United States of America",
        sov_a3: "US1",
        adm0_dif: 1,
        level: 2,
        type: "Dependency",
        tlc: "1",
        admin: "Guam",
        adm0_a3: "GUM",
        geou_dif: 0,
        geounit: "Guam",
        gu_a3: "GUM",
        su_dif: 0,
        subunit: "Guam",
        su_a3: "GUM",
        brk_diff: 0,
        name: "Guam",
        name_long: "Guam",
        brk_a3: "GUM",
        brk_name: "Guam",
        brk_group: null,
        abbrev: "Guam",
        postal: "GU",
        formal_en: "Territory of Guam",
        formal_fr: null,
        name_ciawf: "Guam",
        note_adm0: "U.S.A.",
        note_brk: null,
        name_sort: "Guam",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 5,
        mapcolor9: 1,
        mapcolor13: 1,
        pop_est: 167294,
        pop_rank: 9,
        pop_year: 2019,
        gdp_md: 5920,
        gdp_year: 2018,
        economy: "6. Developing region",
        income_grp: "2. High income: nonOECD",
        fips_10: "GQ",
        iso_a2: "GU",
        iso_a2_eh: "GU",
        iso_a3: "GUM",
        iso_a3_eh: "GUM",
        iso_n3: "316",
        iso_n3_eh: "316",
        un_a3: "316",
        wb_a2: "GU",
        wb_a3: "GUM",
        woe_id: 23424832,
        woe_id_eh: 23424832,
        woe_note: "Exact WOE match as country",
        adm0_iso: "GUM",
        adm0_diff: null,
        adm0_tlc: "GUM",
        adm0_a3_us: "GUM",
        adm0_a3_fr: "GUM",
        adm0_a3_ru: "GUM",
        adm0_a3_es: "GUM",
        adm0_a3_cn: "GUM",
        adm0_a3_tw: "GUM",
        adm0_a3_in: "GUM",
        adm0_a3_np: "GUM",
        adm0_a3_pk: "GUM",
        adm0_a3_de: "GUM",
        adm0_a3_gb: "GUM",
        adm0_a3_br: "GUM",
        adm0_a3_il: "GUM",
        adm0_a3_ps: "GUM",
        adm0_a3_sa: "GUM",
        adm0_a3_eg: "GUM",
        adm0_a3_ma: "GUM",
        adm0_a3_pt: "GUM",
        adm0_a3_ar: "GUM",
        adm0_a3_jp: "GUM",
        adm0_a3_ko: "GUM",
        adm0_a3_vn: "GUM",
        adm0_a3_tr: "GUM",
        adm0_a3_id: "GUM",
        adm0_a3_pl: "GUM",
        adm0_a3_gr: "GUM",
        adm0_a3_it: "GUM",
        adm0_a3_nl: "GUM",
        adm0_a3_se: "GUM",
        adm0_a3_bd: "GUM",
        adm0_a3_ua: "GUM",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Oceania",
        region_un: "Oceania",
        subregion: "Micronesia",
        region_wb: "East Asia & Pacific",
        name_len: 4,
        long_len: 4,
        abbrev_len: 4,
        tiny: 2,
        homepart: -99,
        min_zoom: 0,
        min_label: 3,
        max_label: 10,
        label_x: 144.703614,
        label_y: 13.354173,
        ne_id: 1159321359,
        wikidataid: "Q16635",
        name_ar: "غوام",
        name_bn: "গুয়াম",
        name_de: "Guam",
        name_en: "Guam",
        name_es: "Guam",
        name_fa: "گوآم",
        name_fr: "Guam",
        name_el: "Γκουάμ",
        name_he: "גואם",
        name_hi: "गुआम",
        name_hu: "Guam",
        name_id: "Guam",
        name_it: "Guam",
        name_ja: "グアム",
        name_ko: "괌",
        name_nl: "Guam",
        name_pl: "Guam",
        name_pt: "Guam",
        name_ru: "Гуам",
        name_sv: "Guam",
        name_tr: "Guam",
        name_uk: "Гуам",
        name_ur: "گوام",
        name_vi: "Guam",
        name_zh: "关岛",
        name_zht: "關島",
        fclass_iso: "Admin-0 dependency",
        tlc_diff: null,
        fclass_tlc: "Admin-0 dependency",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "GUM.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [144.74179687500003, 13.25927734375],
            [144.69951171875005, 13.257519531249997],
            [144.66279296875, 13.291064453125003],
            [144.65, 13.3134765625],
            [144.64931640625002, 13.4287109375],
            [144.79033203125005, 13.52685546875],
            [144.83671875000005, 13.622363281250003],
            [144.87539062500002, 13.614648437499994],
            [144.90966796875, 13.599023437499994],
            [144.94082031250002, 13.5703125],
            [144.77988281250003, 13.4111328125],
            [144.74179687500003, 13.25927734375],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 3,
        labelrank: 4,
        sovereignt: "United States of America",
        sov_a3: "US1",
        adm0_dif: 1,
        level: 2,
        type: "Dependency",
        tlc: "1",
        admin: "American Samoa",
        adm0_a3: "ASM",
        geou_dif: 0,
        geounit: "American Samoa",
        gu_a3: "ASM",
        su_dif: 0,
        subunit: "American Samoa",
        su_a3: "ASM",
        brk_diff: 0,
        name: "American Samoa",
        name_long: "American Samoa",
        brk_a3: "ASM",
        brk_name: "American Samoa",
        brk_group: null,
        abbrev: "Am. Samoa",
        postal: "AS",
        formal_en: "American Samoa",
        formal_fr: null,
        name_ciawf: "American Samoa",
        note_adm0: "U.S.A.",
        note_brk: null,
        name_sort: "American Samoa",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 5,
        mapcolor9: 1,
        mapcolor13: 1,
        pop_est: 55312,
        pop_rank: 8,
        pop_year: 2019,
        gdp_md: 636,
        gdp_year: 2018,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        fips_10: "AQ",
        iso_a2: "AS",
        iso_a2_eh: "AS",
        iso_a3: "ASM",
        iso_a3_eh: "ASM",
        iso_n3: "016",
        iso_n3_eh: "016",
        un_a3: "016",
        wb_a2: "AS",
        wb_a3: "ASM",
        woe_id: 23424746,
        woe_id_eh: 23424746,
        woe_note: "Exact WOE match as country",
        adm0_iso: "ASM",
        adm0_diff: null,
        adm0_tlc: "ASM",
        adm0_a3_us: "ASM",
        adm0_a3_fr: "ASM",
        adm0_a3_ru: "ASM",
        adm0_a3_es: "ASM",
        adm0_a3_cn: "ASM",
        adm0_a3_tw: "ASM",
        adm0_a3_in: "ASM",
        adm0_a3_np: "ASM",
        adm0_a3_pk: "ASM",
        adm0_a3_de: "ASM",
        adm0_a3_gb: "ASM",
        adm0_a3_br: "ASM",
        adm0_a3_il: "ASM",
        adm0_a3_ps: "ASM",
        adm0_a3_sa: "ASM",
        adm0_a3_eg: "ASM",
        adm0_a3_ma: "ASM",
        adm0_a3_pt: "ASM",
        adm0_a3_ar: "ASM",
        adm0_a3_jp: "ASM",
        adm0_a3_ko: "ASM",
        adm0_a3_vn: "ASM",
        adm0_a3_tr: "ASM",
        adm0_a3_id: "ASM",
        adm0_a3_pl: "ASM",
        adm0_a3_gr: "ASM",
        adm0_a3_it: "ASM",
        adm0_a3_nl: "ASM",
        adm0_a3_se: "ASM",
        adm0_a3_bd: "ASM",
        adm0_a3_ua: "ASM",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Oceania",
        region_un: "Oceania",
        subregion: "Polynesia",
        region_wb: "East Asia & Pacific",
        name_len: 14,
        long_len: 14,
        abbrev_len: 9,
        tiny: 3,
        homepart: -99,
        min_zoom: 0,
        min_label: 4,
        max_label: 9,
        label_x: -170.747153,
        label_y: -14.32671,
        ne_id: 1159321357,
        wikidataid: "Q16641",
        name_ar: "ساموا الأمريكية",
        name_bn: "মার্কিন সামোয়া",
        name_de: "Amerikanisch-Samoa",
        name_en: "American Samoa",
        name_es: "Samoa Estadounidense",
        name_fa: "ساموآی آمریکا",
        name_fr: "Samoa américaines",
        name_el: "Αμερικανική Σαμόα",
        name_he: "סמואה האמריקנית",
        name_hi: "अमेरिकी समोआ",
        name_hu: "Amerikai Szamoa",
        name_id: "Samoa Amerika",
        name_it: "Samoa Americane",
        name_ja: "アメリカ領サモア",
        name_ko: "아메리칸사모아",
        name_nl: "Amerikaans-Samoa",
        name_pl: "Samoa Amerykańskie",
        name_pt: "Samoa Americana",
        name_ru: "Американское Самоа",
        name_sv: "Amerikanska Samoa",
        name_tr: "Amerikan Samoası",
        name_uk: "Східне Самоа",
        name_ur: "امریکی سمووا",
        name_vi: "Samoa thuộc Mỹ",
        name_zh: "美属萨摩亚",
        name_zht: "美屬薩摩亞",
        fclass_iso: "Admin-0 dependency",
        tlc_diff: null,
        fclass_tlc: "Admin-0 dependency",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "ASM.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-170.72626953125, -14.351171875],
            [-170.76923828125, -14.359765625],
            [-170.8205078125, -14.312109375],
            [-170.720849609375, -14.275976562500006],
            [-170.68916015625, -14.257421875],
            [-170.568115234375, -14.266796875000011],
            [-170.640478515625, -14.2822265625],
            [-170.72626953125, -14.351171875],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 3,
        labelrank: 6,
        sovereignt: "United States of America",
        sov_a3: "US1",
        adm0_dif: 1,
        level: 2,
        type: "Dependency",
        tlc: "1",
        admin: "Northern Mariana Islands",
        adm0_a3: "MNP",
        geou_dif: 0,
        geounit: "Northern Mariana Islands",
        gu_a3: "MNP",
        su_dif: 0,
        subunit: "Northern Mariana Islands",
        su_a3: "MNP",
        brk_diff: 0,
        name: "N. Mariana Is.",
        name_long: "Northern Mariana Islands",
        brk_a3: "MNP",
        brk_name: "N. Mariana Is.",
        brk_group: null,
        abbrev: "N.M.I.",
        postal: "MP",
        formal_en: "Commonwealth of the Northern Mariana Islands",
        formal_fr: null,
        name_ciawf: "Northern Mariana Islands",
        note_adm0: "U.S.A.",
        note_brk: null,
        name_sort: "Northern Mariana Islands",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 5,
        mapcolor9: 1,
        mapcolor13: 1,
        pop_est: 57216,
        pop_rank: 8,
        pop_year: 2019,
        gdp_md: 1323,
        gdp_year: 2018,
        economy: "6. Developing region",
        income_grp: "2. High income: nonOECD",
        fips_10: "CQ",
        iso_a2: "MP",
        iso_a2_eh: "MP",
        iso_a3: "MNP",
        iso_a3_eh: "MNP",
        iso_n3: "580",
        iso_n3_eh: "580",
        un_a3: "580",
        wb_a2: "MP",
        wb_a3: "MNP",
        woe_id: 23424788,
        woe_id_eh: 23424788,
        woe_note: "Exact WOE match as country",
        adm0_iso: "MNP",
        adm0_diff: null,
        adm0_tlc: "MNP",
        adm0_a3_us: "MNP",
        adm0_a3_fr: "MNP",
        adm0_a3_ru: "MNP",
        adm0_a3_es: "MNP",
        adm0_a3_cn: "MNP",
        adm0_a3_tw: "MNP",
        adm0_a3_in: "MNP",
        adm0_a3_np: "MNP",
        adm0_a3_pk: "MNP",
        adm0_a3_de: "MNP",
        adm0_a3_gb: "MNP",
        adm0_a3_br: "MNP",
        adm0_a3_il: "MNP",
        adm0_a3_ps: "MNP",
        adm0_a3_sa: "MNP",
        adm0_a3_eg: "MNP",
        adm0_a3_ma: "MNP",
        adm0_a3_pt: "MNP",
        adm0_a3_ar: "MNP",
        adm0_a3_jp: "MNP",
        adm0_a3_ko: "MNP",
        adm0_a3_vn: "MNP",
        adm0_a3_tr: "MNP",
        adm0_a3_id: "MNP",
        adm0_a3_pl: "MNP",
        adm0_a3_gr: "MNP",
        adm0_a3_it: "MNP",
        adm0_a3_nl: "MNP",
        adm0_a3_se: "MNP",
        adm0_a3_bd: "MNP",
        adm0_a3_ua: "MNP",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Oceania",
        region_un: "Oceania",
        subregion: "Micronesia",
        region_wb: "East Asia & Pacific",
        name_len: 14,
        long_len: 24,
        abbrev_len: 6,
        tiny: 3,
        homepart: -99,
        min_zoom: 0,
        min_label: 5,
        max_label: 10,
        label_x: 145.734397,
        label_y: 15.188188,
        ne_id: 1159321361,
        wikidataid: "Q16644",
        name_ar: "جزر ماريانا الشمالية",
        name_bn: "উত্তর মারিয়ানা দ্বীপপুঞ্জ",
        name_de: "Nördliche Marianen",
        name_en: "Northern Mariana Islands",
        name_es: "Islas Marianas del Norte",
        name_fa: "جزایر ماریانای شمالی",
        name_fr: "îles Mariannes du Nord",
        name_el: "Βόρειες Μαριάνες Νήσοι",
        name_he: "איי מריאנה הצפוניים",
        name_hi: "उत्तरी मारियाना द्वीप",
        name_hu: "Északi-Mariana-szigetek",
        name_id: "Kepulauan Mariana Utara",
        name_it: "Isole Marianne Settentrionali",
        name_ja: "北マリアナ諸島",
        name_ko: "북마리아나 제도",
        name_nl: "Noordelijke Marianen",
        name_pl: "Mariany Północne",
        name_pt: "Ilhas Marianas do Norte",
        name_ru: "Северные Марианские острова",
        name_sv: "Nordmarianerna",
        name_tr: "Kuzey Mariana Adaları",
        name_uk: "Північні Маріанські острови",
        name_ur: "جزائر شمالی ماریانا",
        name_vi: "Quần đảo Bắc Mariana",
        name_zh: "北马里亚纳群岛",
        name_zht: "北馬里亞納群島",
        fclass_iso: "Admin-0 dependency",
        tlc_diff: null,
        fclass_tlc: "Admin-0 dependency",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "MNP.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [145.70839843750002, 18.7625],
              [145.678125, 18.725244140624994],
              [145.6525390625, 18.752636718749997],
              [145.6455078125, 18.806787109374994],
              [145.69013671875, 18.80161132812499],
              [145.70664062500003, 18.790478515624997],
              [145.70839843750002, 18.7625],
            ],
          ],
          [
            [
              [145.712109375, 16.339111328125],
              [145.69023437500005, 16.33212890624999],
              [145.65830078125003, 16.33579101562499],
              [145.63603515625005, 16.351513671874997],
              [145.6310546875, 16.377978515625003],
              [145.6955078125, 16.379638671875],
              [145.71953125000005, 16.359765625],
              [145.712109375, 16.339111328125],
            ],
          ],
          [
            [
              [145.26484375, 14.158105468749994],
              [145.21533203125, 14.111328125],
              [145.17958984375002, 14.120996093749994],
              [145.157421875, 14.136914062499997],
              [145.15214843750005, 14.163623046875003],
              [145.232421875, 14.189453125],
              [145.26542968750005, 14.180224609375003],
              [145.26484375, 14.158105468749994],
            ],
          ],
          [
            [
              [145.7775390625, 18.07895507812499],
              [145.72910156250003, 18.056933593750003],
              [145.7892578125, 18.155419921874994],
              [145.80742187500005, 18.17265625],
              [145.83544921875, 18.136767578125003],
              [145.7775390625, 18.07895507812499],
            ],
          ],
          [
            [
              [145.751953125, 15.133154296874991],
              [145.74921875, 15.107226562500003],
              [145.6982421875, 15.113525390625],
              [145.68427734375, 15.125097656249991],
              [145.71318359375005, 15.215283203124997],
              [145.786328125, 15.256884765625003],
              [145.821875, 15.265380859375],
              [145.78857421875, 15.22265625],
              [145.78232421875003, 15.174609375],
              [145.751953125, 15.133154296874991],
            ],
          ],
          [
            [
              [145.6623046875, 14.970507812500003],
              [145.62099609375002, 14.91953125],
              [145.59160156250005, 14.998828125],
              [145.58671875000005, 15.030810546875003],
              [145.62480468750005, 15.06015625],
              [145.64736328125002, 15.059472656249994],
              [145.6623046875, 14.970507812500003],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 5,
        labelrank: 5,
        sovereignt: "Australia",
        sov_a3: "AU1",
        adm0_dif: 1,
        level: 2,
        type: "Dependency",
        tlc: "1",
        admin: "Ashmore and Cartier Islands",
        adm0_a3: "ATC",
        geou_dif: 0,
        geounit: "Ashmore and Cartier Islands",
        gu_a3: "ATC",
        su_dif: 0,
        subunit: "Ashmore and Cartier Islands",
        su_a3: "ATC",
        brk_diff: 0,
        name: "Ashmore and Cartier Is.",
        name_long: "Ashmore and Cartier Islands",
        brk_a3: "ATC",
        brk_name: "Ashmore and Cartier Is.",
        brk_group: null,
        abbrev: "A.C.Is.",
        postal: "AU",
        formal_en: "Territory of Ashmore and Cartier Islands",
        formal_fr: null,
        name_ciawf: null,
        note_adm0: "Auz.",
        note_brk: null,
        name_sort: "Ashmore and Cartier Islands",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 2,
        mapcolor9: 2,
        mapcolor13: 7,
        pop_est: 0,
        pop_rank: 1,
        pop_year: 2019,
        gdp_md: 0,
        gdp_year: 2019,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        fips_10: "AT",
        iso_a2: "-99",
        iso_a2_eh: "AU",
        iso_a3: "-99",
        iso_a3_eh: "AUS",
        iso_n3: "036",
        iso_n3_eh: "036",
        un_a3: "-099",
        wb_a2: "-99",
        wb_a3: "-99",
        woe_id: 23424749,
        woe_id_eh: 23424749,
        woe_note: "WOE Admin-1 states provinces match.",
        adm0_iso: "AUS",
        adm0_diff: "1",
        adm0_tlc: "ATC",
        adm0_a3_us: "ATC",
        adm0_a3_fr: "ATC",
        adm0_a3_ru: "ATC",
        adm0_a3_es: "ATC",
        adm0_a3_cn: "ATC",
        adm0_a3_tw: "ATC",
        adm0_a3_in: "ATC",
        adm0_a3_np: "ATC",
        adm0_a3_pk: "ATC",
        adm0_a3_de: "ATC",
        adm0_a3_gb: "ATC",
        adm0_a3_br: "ATC",
        adm0_a3_il: "ATC",
        adm0_a3_ps: "ATC",
        adm0_a3_sa: "ATC",
        adm0_a3_eg: "ATC",
        adm0_a3_ma: "ATC",
        adm0_a3_pt: "ATC",
        adm0_a3_ar: "ATC",
        adm0_a3_jp: "ATC",
        adm0_a3_ko: "ATC",
        adm0_a3_vn: "ATC",
        adm0_a3_tr: "ATC",
        adm0_a3_id: "ATC",
        adm0_a3_pl: "ATC",
        adm0_a3_gr: "ATC",
        adm0_a3_it: "ATC",
        adm0_a3_nl: "ATC",
        adm0_a3_se: "ATC",
        adm0_a3_bd: "ATC",
        adm0_a3_ua: "ATC",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Oceania",
        region_un: "Oceania",
        subregion: "Australia and New Zealand",
        region_wb: "East Asia & Pacific",
        name_len: 23,
        long_len: 27,
        abbrev_len: 7,
        tiny: -99,
        homepart: -99,
        min_zoom: 0,
        min_label: 4.5,
        max_label: 9.5,
        label_x: 123.586368,
        label_y: -12.432571,
        ne_id: 1159320353,
        wikidataid: "Q133888",
        name_ar: "جزر أشمور وكارتيير",
        name_bn: "আসমর এবং কার্টিয়ে দ্বীপপুঞ্জ",
        name_de: "Ashmore- und Cartierinseln",
        name_en: "Ashmore and Cartier Islands",
        name_es: "Islas Ashmore y Cartier",
        name_fa: "جزیرههای آشمور و کارتیر",
        name_fr: "Îles Ashmore-et-Cartier",
        name_el: "Άσμορ και Καρτιέ Νησιά",
        name_he: "איי אשמור וקרטייה",
        name_hi: "एशमोर और कार्टियर द्वीप समूह",
        name_hu: "Ashmore- és Cartier-szigetek",
        name_id: "Kepulauan Ashmore dan Cartier",
        name_it: "Isole Ashmore e Cartier",
        name_ja: "アシュモア・カルティエ諸島",
        name_ko: "애시모어 카르티에 제도",
        name_nl: "Ashmore- en Cartiereilanden",
        name_pl: "Wyspy Ashmore i Cartiera",
        name_pt: "Ilhas Ashmore e Cartier",
        name_ru: "Острова Ашмор и Картье",
        name_sv: "Ashmore- och Cartieröarna",
        name_tr: "Ashmore ve Cartier Adaları",
        name_uk: "Острови Ашмор і Картьє",
        name_ur: "جزائر ایشمور و کارٹیر",
        name_vi: "Quần đảo Ashmore và Cartier",
        name_zh: "阿什莫尔和卡捷群岛",
        name_zht: "亞什摩及卡地爾群島",
        fclass_iso: "Unrecognized",
        tlc_diff: "1",
        fclass_tlc: "Admin-0 dependency",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "ashmoreandcartieris.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [123.59453124999999, -12.425683593750009],
            [123.59521484375, -12.4359375],
            [123.57314453125002, -12.434179687500006],
            [123.57246093750001, -12.423925781250006],
            [123.59453124999999, -12.425683593750009],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 3,
        labelrank: 6,
        sovereignt: "San Marino",
        sov_a3: "SMR",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "San Marino",
        adm0_a3: "SMR",
        geou_dif: 0,
        geounit: "San Marino",
        gu_a3: "SMR",
        su_dif: 0,
        subunit: "San Marino",
        su_a3: "SMR",
        brk_diff: 0,
        name: "San Marino",
        name_long: "San Marino",
        brk_a3: "SMR",
        brk_name: "San Marino",
        brk_group: null,
        abbrev: "S.M.",
        postal: "RSM",
        formal_en: "Republic of San Marino",
        formal_fr: null,
        name_ciawf: "San Marino",
        note_adm0: null,
        note_brk: null,
        name_sort: "San Marino",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 3,
        mapcolor9: 1,
        mapcolor13: 6,
        pop_est: 33860,
        pop_rank: 7,
        pop_year: 2019,
        gdp_md: 1655,
        gdp_year: 2018,
        economy: "2. Developed region: nonG7",
        income_grp: "2. High income: nonOECD",
        fips_10: "SM",
        iso_a2: "SM",
        iso_a2_eh: "SM",
        iso_a3: "SMR",
        iso_a3_eh: "SMR",
        iso_n3: "674",
        iso_n3_eh: "674",
        un_a3: "674",
        wb_a2: "SM",
        wb_a3: "SMR",
        woe_id: 23424947,
        woe_id_eh: 23424947,
        woe_note: "Exact WOE match as country",
        adm0_iso: "SMR",
        adm0_diff: null,
        adm0_tlc: "SMR",
        adm0_a3_us: "SMR",
        adm0_a3_fr: "SMR",
        adm0_a3_ru: "SMR",
        adm0_a3_es: "SMR",
        adm0_a3_cn: "SMR",
        adm0_a3_tw: "SMR",
        adm0_a3_in: "SMR",
        adm0_a3_np: "SMR",
        adm0_a3_pk: "SMR",
        adm0_a3_de: "SMR",
        adm0_a3_gb: "SMR",
        adm0_a3_br: "SMR",
        adm0_a3_il: "SMR",
        adm0_a3_ps: "SMR",
        adm0_a3_sa: "SMR",
        adm0_a3_eg: "SMR",
        adm0_a3_ma: "SMR",
        adm0_a3_pt: "SMR",
        adm0_a3_ar: "SMR",
        adm0_a3_jp: "SMR",
        adm0_a3_ko: "SMR",
        adm0_a3_vn: "SMR",
        adm0_a3_tr: "SMR",
        adm0_a3_id: "SMR",
        adm0_a3_pl: "SMR",
        adm0_a3_gr: "SMR",
        adm0_a3_it: "SMR",
        adm0_a3_nl: "SMR",
        adm0_a3_se: "SMR",
        adm0_a3_bd: "SMR",
        adm0_a3_ua: "SMR",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Southern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 10,
        long_len: 10,
        abbrev_len: 4,
        tiny: 5,
        homepart: 1,
        min_zoom: 0,
        min_label: 5,
        max_label: 10,
        label_x: 12.441206,
        label_y: 43.933916,
        ne_id: 1159321255,
        wikidataid: "Q238",
        name_ar: "سان مارينو",
        name_bn: "সান মারিনো",
        name_de: "San Marino",
        name_en: "San Marino",
        name_es: "San Marino",
        name_fa: "سان مارینو",
        name_fr: "Saint-Marin",
        name_el: "Άγιος Μαρίνος",
        name_he: "סן מרינו",
        name_hi: "सान मारिनो",
        name_hu: "San Marino",
        name_id: "San Marino",
        name_it: "San Marino",
        name_ja: "サンマリノ",
        name_ko: "산마리노",
        name_nl: "San Marino",
        name_pl: "San Marino",
        name_pt: "San Marino",
        name_ru: "Сан-Марино",
        name_sv: "San Marino",
        name_tr: "San Marino",
        name_uk: "Сан-Марино",
        name_ur: "سان مارینو",
        name_vi: "San Marino",
        name_zh: "圣马力诺",
        name_zht: "聖馬力諾",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "SMR.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [12.485253906250023, 43.901416015624996],
            [12.426367187500006, 43.894091796874996],
            [12.396875, 43.9345703125],
            [12.441113281250011, 43.982421875],
            [12.503710937500017, 43.98974609375],
            [12.5146484375, 43.952978515625],
            [12.485253906250023, 43.901416015624996],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 1,
        labelrank: 2,
        sovereignt: "Canada",
        sov_a3: "CAN",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Canada",
        adm0_a3: "CAN",
        geou_dif: 0,
        geounit: "Canada",
        gu_a3: "CAN",
        su_dif: 0,
        subunit: "Canada",
        su_a3: "CAN",
        brk_diff: 0,
        name: "Canada",
        name_long: "Canada",
        brk_a3: "CAN",
        brk_name: "Canada",
        brk_group: null,
        abbrev: "Can.",
        postal: "CA",
        formal_en: "Canada",
        formal_fr: null,
        name_ciawf: "Canada",
        note_adm0: null,
        note_brk: null,
        name_sort: "Canada",
        name_alt: null,
        mapcolor7: 6,
        mapcolor8: 6,
        mapcolor9: 2,
        mapcolor13: 2,
        pop_est: 37589262,
        pop_rank: 15,
        pop_year: 2019,
        gdp_md: 1736425,
        gdp_year: 2019,
        economy: "1. Developed region: G7",
        income_grp: "1. High income: OECD",
        fips_10: "CA",
        iso_a2: "CA",
        iso_a2_eh: "CA",
        iso_a3: "CAN",
        iso_a3_eh: "CAN",
        iso_n3: "124",
        iso_n3_eh: "124",
        un_a3: "124",
        wb_a2: "CA",
        wb_a3: "CAN",
        woe_id: 23424775,
        woe_id_eh: 23424775,
        woe_note: "Exact WOE match as country",
        adm0_iso: "CAN",
        adm0_diff: null,
        adm0_tlc: "CAN",
        adm0_a3_us: "CAN",
        adm0_a3_fr: "CAN",
        adm0_a3_ru: "CAN",
        adm0_a3_es: "CAN",
        adm0_a3_cn: "CAN",
        adm0_a3_tw: "CAN",
        adm0_a3_in: "CAN",
        adm0_a3_np: "CAN",
        adm0_a3_pk: "CAN",
        adm0_a3_de: "CAN",
        adm0_a3_gb: "CAN",
        adm0_a3_br: "CAN",
        adm0_a3_il: "CAN",
        adm0_a3_ps: "CAN",
        adm0_a3_sa: "CAN",
        adm0_a3_eg: "CAN",
        adm0_a3_ma: "CAN",
        adm0_a3_pt: "CAN",
        adm0_a3_ar: "CAN",
        adm0_a3_jp: "CAN",
        adm0_a3_ko: "CAN",
        adm0_a3_vn: "CAN",
        adm0_a3_tr: "CAN",
        adm0_a3_id: "CAN",
        adm0_a3_pl: "CAN",
        adm0_a3_gr: "CAN",
        adm0_a3_it: "CAN",
        adm0_a3_nl: "CAN",
        adm0_a3_se: "CAN",
        adm0_a3_bd: "CAN",
        adm0_a3_ua: "CAN",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "North America",
        region_un: "Americas",
        subregion: "Northern America",
        region_wb: "North America",
        name_len: 6,
        long_len: 6,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 1.7,
        max_label: 5.7,
        label_x: -101.9107,
        label_y: 60.324287,
        ne_id: 1159320467,
        wikidataid: "Q16",
        name_ar: "كندا",
        name_bn: "কানাডা",
        name_de: "Kanada",
        name_en: "Canada",
        name_es: "Canadá",
        name_fa: "کانادا",
        name_fr: "Canada",
        name_el: "Καναδάς",
        name_he: "קנדה",
        name_hi: "कनाडा",
        name_hu: "Kanada",
        name_id: "Kanada",
        name_it: "Canada",
        name_ja: "カナダ",
        name_ko: "캐나다",
        name_nl: "Canada",
        name_pl: "Kanada",
        name_pt: "Canadá",
        name_ru: "Канада",
        name_sv: "Kanada",
        name_tr: "Kanada",
        name_uk: "Канада",
        name_ur: "کینیڈا",
        name_vi: "Canada",
        name_zh: "加拿大",
        name_zht: "加拿大",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "CAN.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-132.655517578125, 54.127490234374996],
              [-132.5640625, 54.068652343749996],
              [-132.34443359375, 54.1060546875],
              [-132.303369140625, 54.098876953125],
              [-132.26162109375, 54.076318359375],
              [-132.21591796875, 54.02841796875],
              [-132.16611328125, 53.955224609375],
              [-132.155126953125, 53.8751953125],
              [-132.17509765625, 53.846533203125],
              [-132.214501953125, 53.814746093749996],
              [-132.564892578125, 53.687646484375],
              [-132.57412109375, 53.675390625],
              [-132.567138671875, 53.66396484375],
              [-132.53466796875, 53.651708984375],
              [-132.464404296875, 53.6533203125],
              [-132.186962890625, 53.684814453125],
              [-132.1716796875, 53.706835937499996],
              [-132.15224609375, 53.806982421875],
              [-132.114013671875, 53.86015625],
              [-132.110595703125, 53.90029296875],
              [-132.135888671875, 53.995849609375],
              [-132.134423828125, 54.03427734375],
              [-131.9408203125, 54.0419921875],
              [-131.81962890625, 54.07734375],
              [-131.695947265625, 54.1431640625],
              [-131.667626953125, 54.141357421875],
              [-131.685400390625, 54.022802734375],
              [-131.7025390625, 53.986376953124996],
              [-131.821142578125, 53.84150390625],
              [-131.88916015625, 53.71396484375],
              [-131.922314453125, 53.587890625],
              [-131.928076171875, 53.379199218749996],
              [-131.957421875, 53.30869140625],
              [-132.011328125, 53.265185546874996],
              [-132.347265625, 53.189208984375],
              [-132.520458984375, 53.19404296875],
              [-132.6748046875, 53.26318359375],
              [-132.747509765625, 53.310498046875],
              [-132.692578125, 53.36787109375],
              [-132.65478515625, 53.370556640625],
              [-132.546240234375, 53.35927734375],
              [-132.46240234375, 53.337890625],
              [-132.425, 53.336962890624996],
              [-132.43134765625, 53.350439453125],
              [-132.670166015625, 53.45859375],
              [-132.84501953125, 53.50771484375],
              [-132.897998046875, 53.5626953125],
              [-132.899560546875, 53.60537109375],
              [-132.91337890625, 53.629199218749996],
              [-133.05224609375, 53.778125],
              [-133.0794921875, 53.83701171875],
              [-133.09765625, 53.920263671875],
              [-133.09794921875, 54.005615234375],
              [-133.0638671875, 54.14404296875],
              [-133.048388671875, 54.158935546875],
              [-132.991455078125, 54.1578125],
              [-132.89306640625, 54.140771484375],
              [-132.655517578125, 54.127490234374996],
            ],
          ],
          [
            [
              [-131.7537109375, 53.195556640625],
              [-131.65234375, 53.102978515625],
              [-131.62216796875, 53.020068359374996],
              [-131.63466796875, 52.922167968749996],
              [-131.795263671875, 52.885058593749996],
              [-131.8796875, 52.9146484375],
              [-131.916357421875, 52.909130859375],
              [-131.97177734375, 52.879833984375],
              [-131.90439453125, 52.86669921875],
              [-131.81005859375, 52.818701171875],
              [-131.727294921875, 52.756396484374996],
              [-131.610595703125, 52.74521484375],
              [-131.455224609375, 52.701708984374996],
              [-131.572802734375, 52.623339843749996],
              [-131.590576171875, 52.57822265625],
              [-131.443896484375, 52.4533203125],
              [-131.42998046875, 52.422119140625],
              [-131.3830078125, 52.41572265625],
              [-131.2736328125, 52.425830078124996],
              [-131.259716796875, 52.41591796875],
              [-131.2599609375, 52.3900390625],
              [-131.32705078125, 52.317529296875],
              [-131.319921875, 52.303076171875],
              [-131.2591796875, 52.291650390625],
              [-131.142626953125, 52.29111328125],
              [-131.116162109375, 52.219091796875],
              [-131.221533203125, 52.153613281249996],
              [-131.421875, 52.23798828125],
              [-131.5111328125, 52.322070312499996],
              [-131.562060546875, 52.399951171874996],
              [-131.623681640625, 52.443994140625],
              [-131.80966796875, 52.54169921875],
              [-132.092236328125, 52.752783203125],
              [-132.165087890625, 52.78330078125],
              [-132.23857421875, 52.866796875],
              [-132.2599609375, 52.906982421875],
              [-132.25810546875, 52.933886718749996],
              [-132.229541015625, 52.948095703125],
              [-132.144921875, 52.957470703125],
              [-132.14375, 52.999316406249996],
              [-132.468701171875, 53.071875],
              [-132.504833984375, 53.08671875],
              [-132.54677734375, 53.1375],
              [-132.52421875, 53.144921875],
              [-132.34541015625, 53.136083984375],
              [-132.15390625, 53.160498046875],
              [-132.0359375, 53.179150390625],
              [-131.989501953125, 53.201953125],
              [-131.893115234375, 53.2314453125],
              [-131.853466796875, 53.229736328125],
              [-131.7537109375, 53.195556640625],
            ],
          ],
          [
            [
              [-127.19731445312499, 50.640380859375],
              [-126.700927734375, 50.515527343749994],
              [-126.203857421875, 50.453857421875],
              [-125.83916015624999, 50.380810546875],
              [-125.615234375, 50.358544921874994],
              [-125.534326171875, 50.34248046875],
              [-125.482080078125, 50.316796875],
              [-125.42045898437499, 50.254638671875],
              [-125.31396484375, 50.106689453125],
              [-125.23320312499999, 50.01220703125],
              [-125.06640625, 49.848193359374996],
              [-124.93466796875, 49.731640625],
              [-124.904638671875, 49.685351562499996],
              [-124.93242187499999, 49.670458984374996],
              [-124.9306640625, 49.6431640625],
              [-124.83061523437499, 49.530078125],
              [-124.64287109374999, 49.428662109375],
              [-124.495947265625, 49.380273437499994],
              [-124.185888671875, 49.300585937499996],
              [-123.99580078125, 49.224023437499994],
              [-123.937158203125, 49.170800781249994],
              [-123.8544921875, 49.119189453124996],
              [-123.82001953125, 49.08349609375],
              [-123.752294921875, 48.951220703124996],
              [-123.6265625, 48.824023437499996],
              [-123.497021484375, 48.582080078124996],
              [-123.4728515625, 48.602294921875],
              [-123.457958984375, 48.6744140625],
              [-123.44306640625, 48.690478515624996],
              [-123.415478515625, 48.698193359375],
              [-123.389892578125, 48.670214843749996],
              [-123.36630859375, 48.6064453125],
              [-123.2837890625, 48.45517578125],
              [-123.31064453125, 48.411035156249994],
              [-123.33452148437499, 48.406494140625],
              [-123.4458984375, 48.42724609375],
              [-123.4845703125, 48.40009765625],
              [-123.536474609375, 48.344970703125],
              [-123.57314453125, 48.322802734374996],
              [-123.59462890625, 48.333544921874996],
              [-123.916943359375, 48.386572265625],
              [-124.115234375, 48.436425781249994],
              [-124.376220703125, 48.515234375],
              [-124.689404296875, 48.597314453124994],
              [-124.86826171875, 48.653613281249996],
              [-125.017236328125, 48.711474609374996],
              [-125.120703125, 48.760791015624996],
              [-125.140283203125, 48.802636718749994],
              [-125.135693359375, 48.822412109374994],
              [-124.934765625, 48.956347656249996],
              [-124.84965820312499, 49.028271484375],
              [-124.817041015625, 49.083300781249996],
              [-124.800244140625, 49.141552734375],
              [-124.812646484375, 49.212646484375],
              [-124.820751953125, 49.20712890625],
              [-124.838720703125, 49.1390625],
              [-124.868310546875, 49.078515625],
              [-124.904443359375, 49.031005859375],
              [-124.92734375, 49.014208984374996],
              [-125.168212890625, 48.991015625],
              [-125.362744140625, 48.9982421875],
              [-125.460302734375, 48.941064453124994],
              [-125.489453125, 48.9337890625],
              [-125.543115234375, 48.95283203125],
              [-125.660498046875, 49.029150390625],
              [-125.828515625, 49.091845703124996],
              [-125.811962890625, 49.107226562499996],
              [-125.702294921875, 49.139208984374996],
              [-125.64423828125, 49.185791015625],
              [-125.654638671875, 49.193212890625],
              [-125.693701171875, 49.190380859375],
              [-125.72802734375, 49.199853515624994],
              [-125.79638671875, 49.260205078125],
              [-125.83544921875, 49.276660156249996],
              [-125.918359375, 49.24951171875],
              [-125.95166015625, 49.248046875],
              [-125.983837890625, 49.287890625],
              [-125.9376953125, 49.379785156249994],
              [-125.935400390625, 49.40146484375],
              [-126.02031249999999, 49.368017578125],
              [-126.04833984375, 49.37900390625],
              [-126.07490234375, 49.4087890625],
              [-126.099853515625, 49.421289062499994],
              [-126.16884765625, 49.415185546874994],
              [-126.243603515625, 49.442675781249996],
              [-126.2697265625, 49.431884765625],
              [-126.279638671875, 49.3921875],
              [-126.30449218749999, 49.38203125],
              [-126.418603515625, 49.449023437499996],
              [-126.44453125, 49.451123046875],
              [-126.499853515625, 49.399951171874996],
              [-126.519140625, 49.396777343749996],
              [-126.54853515625, 49.4189453125],
              [-126.563720703125, 49.543261718749996],
              [-126.557470703125, 49.57861328125],
              [-126.54189453125, 49.590478515624994],
              [-126.4427734375, 49.619287109374994],
              [-126.1578125, 49.650146484375],
              [-126.13408203124999, 49.672314453125],
              [-126.34755859375, 49.66083984375],
              [-126.403173828125, 49.677734375],
              [-126.46279296875, 49.72021484375],
              [-126.525244140625, 49.719580078125],
              [-126.558251953125, 49.7333984375],
              [-126.59287109375, 49.764111328125],
              [-126.68310546875, 49.87646484375],
              [-126.74462890625, 49.904931640624994],
              [-126.849365234375, 49.922802734375],
              [-126.9033203125, 49.944140625],
              [-126.92607421874999, 49.934716796874994],
              [-126.94794921875, 49.902685546875],
              [-126.97709960937499, 49.8828125],
              [-127.04873046875, 49.871533203125],
              [-127.11430664062499, 49.879736328125],
              [-127.16552734375, 49.910449218749996],
              [-127.1958984375, 49.949169921875],
              [-127.20751953125, 49.992431640625],
              [-127.1791015625, 50.05029296875],
              [-127.179638671875, 50.073144531249994],
              [-127.192333984375, 50.099902343749996],
              [-127.215673828125, 50.121484375],
              [-127.24980468749999, 50.137988281249996],
              [-127.268408203125, 50.129345703125],
              [-127.27153320312499, 50.095556640625],
              [-127.2900390625, 50.070849609374996],
              [-127.34941406249999, 50.051953125],
              [-127.397900390625, 50.085009765624996],
              [-127.42978515625, 50.130859375],
              [-127.467138671875, 50.163427734375],
              [-127.67485351562499, 50.163330078125],
              [-127.770458984375, 50.121142578124996],
              [-127.81630859375, 50.117724609374996],
              [-127.863916015625, 50.127734375],
              [-127.872998046875, 50.15009765625],
              [-127.82817382812499, 50.21142578125],
              [-127.83916015624999, 50.293212890625],
              [-127.850830078125, 50.313720703125],
              [-127.9466796875, 50.326220703124996],
              [-127.96293945312499, 50.345996093749996],
              [-127.905859375, 50.445214843749994],
              [-127.8740234375, 50.46396484375],
              [-127.83154296875, 50.471044921875],
              [-127.64140624999999, 50.4791015625],
              [-127.578125, 50.46494140625],
              [-127.4865234375, 50.404638671875],
              [-127.48935546875, 50.42734375],
              [-127.5240234375, 50.495751953124994],
              [-127.52900390625, 50.536767578124994],
              [-127.46591796875, 50.58310546875],
              [-127.526220703125, 50.5966796875],
              [-127.75146484375, 50.607373046875],
              [-127.74970703125, 50.577734375],
              [-127.73115234375, 50.5357421875],
              [-127.864697265625, 50.498876953125],
              [-127.96367187499999, 50.492626953125],
              [-128.058349609375, 50.498486328125],
              [-128.13564453125, 50.520556640624996],
              [-128.267431640625, 50.609277343749994],
              [-128.34990234375, 50.696582031249996],
              [-128.346044921875, 50.74423828125],
              [-128.300830078125, 50.794140625],
              [-128.241552734375, 50.828173828124996],
              [-128.101318359375, 50.857763671875],
              [-127.91806640625, 50.860546875],
              [-127.713037109375, 50.820751953125],
              [-127.19731445312499, 50.640380859375],
            ],
          ],
          [
            [
              [-130.02509765625, 55.8882324218751],
              [-130.0140625, 55.950537109375],
              [-130.022900390625, 56.014501953125],
              [-130.05595703125, 56.065234375],
              [-130.0978515625, 56.10927734375],
              [-130.214697265625, 56.0828125],
              [-130.413134765625, 56.122509765625],
              [-130.477099609375, 56.230566406250034],
              [-130.64907226562502, 56.26367187500006],
              [-130.74169921875, 56.3408203125],
              [-130.930224609375, 56.37861328125],
              [-131.08291015625, 56.404833984374996],
              [-131.1994140625, 56.44921875],
              [-131.335791015625, 56.501220703125],
              [-131.471875, 56.55673828125],
              [-131.57509765625002, 56.598828125000125],
              [-131.651513671875, 56.59609375],
              [-131.824267578125, 56.589990234375],
              [-131.83310546875, 56.684814453125],
              [-131.885986328125, 56.742138671875],
              [-131.866162109375, 56.792822265625],
              [-131.9625, 56.818701171875],
              [-132.104296875, 56.856787109375],
              [-132.06289062500002, 56.95336914062506],
              [-132.03154296875, 57.0265625],
              [-132.15703125, 57.048193359375],
              [-132.33798828125, 57.079443359375],
              [-132.27939453125, 57.145361328125],
              [-132.232177734375, 57.198535156249996],
              [-132.30166015625002, 57.276318359375125],
              [-132.44248046875, 57.40673828125],
              [-132.55048828125, 57.49990234375],
              [-132.69150390625, 57.6451171875],
              [-132.81552734375, 57.772705078125],
              [-132.916845703125, 57.877001953124996],
              [-133.001416015625, 57.948974609375],
              [-133.12041015625, 58.077734375],
              [-133.27529296875002, 58.22285156250001],
              [-133.42255859375, 58.33706054687502],
              [-133.401123046875, 58.410888671875],
              [-133.54638671875, 58.50346679687499],
              [-133.67392578125, 58.59716796875],
              [-133.820751953125, 58.705029296875],
              [-133.96572265625002, 58.75786132812499],
              [-134.069189453125, 58.7955078125],
              [-134.218505859375, 58.84990234375],
              [-134.29697265625, 58.898486328125],
              [-134.329638671875, 58.93969726562506],
              [-134.363525390625, 58.96875],
              [-134.39306640625, 59.009179687499994],
              [-134.410205078125, 59.05625],
              [-134.440771484375, 59.085351562499994],
              [-134.62197265625002, 59.1553222656251],
              [-134.67724609375, 59.199267578125],
              [-134.80239257812502, 59.25],
              [-134.9072265625, 59.27119140625],
              [-134.94375, 59.28828125000001],
              [-135.0712890625, 59.441455078125],
              [-135.050830078125, 59.49604492187507],
              [-135.036669921875, 59.550683593749994],
              [-135.051025390625, 59.578662109375],
              [-135.260791015625, 59.695019531250125],
              [-135.36787109375, 59.743310546874994],
              [-135.475927734375, 59.79326171875],
              [-135.702587890625, 59.728759765625],
              [-135.93466796875, 59.662646484375],
              [-136.09716796875, 59.63837890625],
              [-136.321826171875, 59.604833984375],
              [-136.247119140625, 59.532910156250125],
              [-136.277978515625, 59.480322265625],
              [-136.3478515625, 59.456054687500114],
              [-136.466357421875, 59.45908203125],
              [-136.466748046875, 59.279931640624994],
              [-136.578759765625, 59.152246093749994],
              [-136.81328125, 59.15004882812508],
              [-136.939306640625, 59.106103515624994],
              [-137.126220703125, 59.040966796875],
              [-137.2775390625, 58.988183593749994],
              [-137.43857421875, 58.903125],
              [-137.5208984375, 58.915380859375],
              [-137.4841796875, 58.9912109375],
              [-137.543701171875, 59.1194335937501],
              [-137.593310546875, 59.22626953125001],
              [-137.696630859375, 59.281152343749994],
              [-137.870556640625, 59.373583984375],
              [-138.00112304687502, 59.4429199218751],
              [-138.187451171875, 59.541943359375],
              [-138.317626953125, 59.611132812500045],
              [-138.45361328125, 59.683398437500045],
              [-138.632275390625, 59.77827148437501],
              [-138.70546875, 59.901318359375],
              [-138.86875, 59.945751953125],
              [-139.04345703125, 59.99326171875],
              [-139.18515625, 60.08359375],
              [-139.136962890625, 60.172705078125],
              [-139.07924804687502, 60.279443359375136],
              [-139.07924804687502, 60.343701171875125],
              [-139.234765625, 60.339746093749994],
              [-139.46796875, 60.33369140625],
              [-139.676318359375, 60.32832031250001],
              [-139.8306640625, 60.252880859375],
              [-139.973291015625, 60.183154296875],
              [-140.196923828125, 60.2375],
              [-140.45283203125, 60.299707031249994],
              [-140.525439453125, 60.218359375],
              [-140.762744140625, 60.25913085937509],
              [-141.0021484375, 60.300244140625125],
              [-141.0021484375, 60.592431640625136],
              [-141.0021484375, 60.88466796875002],
              [-141.0021484375, 61.176855468750034],
              [-141.0021484375, 61.469042968750045],
              [-141.0021484375, 61.761279296875045],
              [-141.0021484375, 62.05346679687506],
              [-141.0021484375, 62.345703125000114],
              [-141.0021484375, 62.637890625000125],
              [-141.0021484375, 62.930078125000136],
              [-141.0021484375, 63.22226562499998],
              [-141.0021484375, 63.51445312499999],
              [-141.0021484375, 63.806689453125],
              [-141.0021484375, 64.09887695312506],
              [-141.0021484375, 64.39111328125006],
              [-141.0021484375, 64.68330078125013],
              [-141.0021484375, 64.97553710937513],
              [-141.0021484375, 65.26772460937514],
              [-141.0021484375, 65.55991210937498],
              [-141.0021484375, 65.85214843750003],
              [-141.0021484375, 66.1443359375],
              [-141.0021484375, 66.4365234375],
              [-141.0021484375, 66.728759765625],
              [-141.0021484375, 67.020947265625],
              [-141.0021484375, 67.313134765625],
              [-141.0021484375, 67.60537109375],
              [-141.0021484375, 67.89755859375],
              [-141.0021484375, 68.18974609375],
              [-141.0021484375, 68.481982421875],
              [-141.0021484375, 68.774169921875],
              [-141.0021484375, 69.066357421875],
              [-141.0021484375, 69.35859375],
              [-141.0021484375, 69.65078125],
              [-140.86000976562497, 69.63525390625],
              [-140.40512695312498, 69.60249023437507],
              [-139.97661132812493, 69.62172851562508],
              [-139.18154296874997, 69.51552734375008],
              [-138.689892578125, 69.31679687500014],
              [-138.291015625, 69.2190429687501],
              [-138.128369140625, 69.15195312500009],
              [-137.86943359374993, 69.09282226562493],
              [-137.2599609375, 68.964111328125],
              [-137.07041015624992, 68.95087890625001],
              [-136.71733398437493, 68.88916015625006],
              [-136.49868164062502, 68.89731445312509],
              [-136.12236328125, 68.88222656250002],
              [-135.866650390625, 68.83261718750003],
              [-135.36215820312492, 68.69643554687502],
              [-135.258837890625, 68.68432617187506],
              [-135.231201171875, 68.69428710937513],
              [-135.40693359374995, 68.82895507812503],
              [-135.4345703125, 68.84199218750001],
              [-135.63798828125, 68.89223632812514],
              [-135.87631835937498, 68.9169921875],
              [-135.89472656249995, 68.92670898437507],
              [-135.93901367187493, 68.9741699218751],
              [-135.92475585937495, 68.99262695312495],
              [-135.87285156249993, 69.0010253906251],
              [-135.69521484375002, 69.00063476562502],
              [-135.58999023437497, 69.00825195312513],
              [-135.57553710937498, 69.02695312500003],
              [-135.65126953125002, 69.03129882812505],
              [-135.74262695312495, 69.04941406249998],
              [-135.84970703124992, 69.08139648437492],
              [-135.91020507812493, 69.11147460937502],
              [-135.69145507812493, 69.31118164062502],
              [-135.61494140625, 69.291015625],
              [-135.49956054687493, 69.337158203125],
              [-135.29282226562492, 69.30786132812506],
              [-135.25498046874992, 69.32382812499992],
              [-135.22978515624993, 69.42519531249997],
              [-135.1990234375, 69.44960937499997],
              [-135.14082031249995, 69.46782226562499],
              [-134.85288085937498, 69.4858886718751],
              [-134.49384765624995, 69.46791992187497],
              [-134.45683593749993, 69.47763671875003],
              [-134.49121093749994, 69.54531250000014],
              [-134.495361328125, 69.57192382812502],
              [-134.47368164062493, 69.6328125],
              [-134.45146484374993, 69.66547851562507],
              [-134.40893554687494, 69.68178710937502],
              [-134.24204101562498, 69.66884765625008],
              [-134.189892578125, 69.63881835937497],
              [-134.134033203125, 69.58725585937498],
              [-134.07749023437492, 69.557861328125],
              [-133.89995117187493, 69.52822265625002],
              [-133.87978515624997, 69.50771484375008],
              [-133.9474609375, 69.42949218749999],
              [-134.01840820312498, 69.38847656249999],
              [-134.1650390625, 69.28056640624999],
              [-134.17431640624994, 69.25283203125002],
              [-133.94804687499993, 69.30131835937499],
              [-133.69404296875, 69.36840820312494],
              [-133.47592773437498, 69.40537109375003],
              [-133.29365234374995, 69.41215820312499],
              [-133.163134765625, 69.43388671874999],
              [-133.08442382812495, 69.47065429687501],
              [-133.02827148437495, 69.50825195312501],
              [-132.91533203125, 69.62963867187506],
              [-132.84033203124994, 69.6506835937501],
              [-132.52680664062495, 69.64326171875001],
              [-132.45234374999995, 69.64692382812507],
              [-132.40390624999998, 69.65874023437499],
              [-132.41274414062497, 69.67407226562506],
              [-132.47895507812495, 69.69287109375],
              [-132.568359375, 69.69814453125002],
              [-132.57060546875, 69.70668945312514],
              [-132.537548828125, 69.72656250000011],
              [-132.48847656249995, 69.73808593749999],
              [-132.333984375, 69.75180664062509],
              [-132.232421875, 69.70815429687508],
              [-132.16342773437498, 69.70498046875014],
              [-131.934130859375, 69.7534667968751],
              [-131.5818359375, 69.88212890624999],
              [-131.44091796875, 69.91791992187501],
              [-131.31894531249998, 69.92416992187503],
              [-131.21586914062493, 69.90078125000008],
              [-131.13637695312502, 69.90688476562508],
              [-131.03183593749992, 69.97949218750011],
              [-130.990625, 70.01811523437499],
              [-130.92617187500002, 70.05161132812509],
              [-130.665478515625, 70.12705078124998],
              [-130.49843749999997, 70.14316406250009],
              [-130.39638671874997, 70.12924804687509],
              [-130.27495117187493, 70.09799804687509],
              [-130.17495117187497, 70.08588867187513],
              [-130.04331054687498, 70.09506835937503],
              [-129.94497070312502, 70.09091796875009],
              [-129.89804687499998, 70.10615234375001],
              [-129.73007812499998, 70.19208984375007],
              [-129.67563476562498, 70.19296875000009],
              [-129.622998046875, 70.16762695312508],
              [-129.53842773437498, 70.10517578125013],
              [-129.53818359374992, 70.07392578125001],
              [-129.64829101562498, 69.99775390625013],
              [-130.45883789062492, 69.77998046874995],
              [-130.708544921875, 69.68598632812501],
              [-130.83208007812493, 69.65146484375009],
              [-130.96010742187497, 69.63203125000001],
              [-131.207958984375, 69.6157714843751],
              [-131.30634765625, 69.59663085937513],
              [-131.47294921874993, 69.57949218749997],
              [-131.86279296874994, 69.54936523437505],
              [-131.93779296874993, 69.5347167968751],
              [-131.98876953124994, 69.51762695312499],
              [-132.12875976562498, 69.40234375000011],
              [-132.196826171875, 69.36469726562513],
              [-132.33076171874995, 69.30795898437509],
              [-132.481201171875, 69.27314453125013],
              [-132.68671875, 69.25986328125003],
              [-132.81748046874992, 69.20576171875007],
              [-132.96796874999995, 69.10141601562509],
              [-133.08945312499992, 69.02875976562507],
              [-133.22822265625, 68.96713867187509],
              [-133.378955078125, 68.88666992187507],
              [-133.41831054687492, 68.84428710937493],
              [-133.37338867187492, 68.78847656250014],
              [-133.348388671875, 68.76987304687509],
              [-133.19682617187493, 68.73984374999998],
              [-133.13803710937498, 68.74658203125011],
              [-133.1921875, 68.77651367187502],
              [-133.31953124999995, 68.81972656250008],
              [-133.336669921875, 68.83525390625005],
              [-133.30400390624993, 68.84741210937506],
              [-132.70600585937495, 68.81489257812501],
              [-132.57763671875, 68.84780273437514],
              [-132.532666015625, 68.87563476562514],
              [-132.542236328125, 68.88994140624999],
              [-132.704345703125, 68.89589843749997],
              [-132.73911132812498, 68.92246093750009],
              [-132.76469726562493, 68.97246093749993],
              [-132.77011718749998, 69.01215820312507],
              [-132.75546874999998, 69.04160156249998],
              [-132.7189453125, 69.07919921875003],
              [-132.54516601562494, 69.14062500000011],
              [-132.35805664062502, 69.16694335937513],
              [-132.21396484374998, 69.20166015625011],
              [-132.134375, 69.23447265625003],
              [-131.91962890624995, 69.29052734375011],
              [-131.83339843750002, 69.3359863281251],
              [-131.78691406249993, 69.37128906250001],
              [-131.7810546875, 69.38886718750007],
              [-131.82016601562498, 69.401611328125],
              [-131.78837890625, 69.43198242187498],
              [-131.63178710937495, 69.45908203124998],
              [-131.56293945312495, 69.46137695312507],
              [-131.34291992187497, 69.43540039062492],
              [-131.30302734374993, 69.41508789062505],
              [-131.32470703125, 69.36118164062509],
              [-131.29389648437495, 69.36372070312507],
              [-131.209033203125, 69.43217773437499],
              [-131.16171874999992, 69.45498046875002],
              [-131.11284179687493, 69.45947265625006],
              [-131.063427734375, 69.45068359375006],
              [-131.013427734375, 69.4287109375],
              [-130.986279296875, 69.36289062500009],
              [-130.981982421875, 69.25327148437498],
              [-130.970654296875, 69.20908203125003],
              [-130.914306640625, 69.28486328125001],
              [-130.875048828125, 69.32001953125007],
              [-130.660693359375, 69.48129882812503],
              [-130.51596679687492, 69.56967773437492],
              [-130.35361328124998, 69.6558105468751],
              [-130.11762695312495, 69.72006835937503],
              [-129.57211914062492, 69.8267089843751],
              [-129.26484375, 69.85541992187507],
              [-129.109130859375, 69.88193359374998],
              [-129.03291015625, 69.90498046875001],
              [-128.984326171875, 69.93344726562498],
              [-128.89892578125, 69.96616210937509],
              [-128.88369140625002, 69.96347656250003],
              [-128.916796875, 69.89487304687509],
              [-128.93862304687502, 69.875],
              [-129.13833007812502, 69.83251953125],
              [-129.15791015625, 69.80009765624999],
              [-129.13623046874994, 69.7500488281251],
              [-129.10170898437502, 69.71704101562511],
              [-129.05434570312502, 69.70107421875008],
              [-128.97143554687494, 69.71240234375006],
              [-128.85302734375, 69.7510253906251],
              [-128.705517578125, 69.81015625000009],
              [-128.38671875, 69.96015625000007],
              [-128.3591796875, 69.98759765624999],
              [-128.27861328124993, 70.10810546875001],
              [-128.09584960937497, 70.16132812500001],
              [-127.76494140624999, 70.22187500000013],
              [-127.68378906249995, 70.26035156249998],
              [-127.97402343750002, 70.29291992187501],
              [-128.03417968749994, 70.31533203125014],
              [-128.04365234375, 70.32875976562502],
              [-127.98891601562497, 70.36313476562492],
              [-128.12148437499997, 70.39736328124997],
              [-128.1701171875, 70.41845703125],
              [-128.16806640624998, 70.4797851562501],
              [-128.12729492187492, 70.52382812499997],
              [-128.040478515625, 70.56640624999994],
              [-127.99101562499993, 70.57382812500003],
              [-127.86162109374999, 70.549072265625],
              [-127.75283203125002, 70.51713867187505],
              [-127.37685546874997, 70.36875],
              [-127.22597656249997, 70.29614257812494],
              [-127.13847656249999, 70.23935546874992],
              [-126.92680664062493, 70.06171875000001],
              [-126.83349609374994, 69.95908203125003],
              [-126.75869140624997, 69.85336914062498],
              [-126.68491210937498, 69.77709960937511],
              [-126.61215820312498, 69.73032226562498],
              [-126.25043945312501, 69.54526367187492],
              [-126.06381835937498, 69.46708984374999],
              [-125.90742187500001, 69.41855468750003],
              [-125.727783203125, 69.37998046875003],
              [-125.52495117187499, 69.35156250000011],
              [-125.38676757812499, 69.34921874999998],
              [-125.171875, 69.427978515625],
              [-125.16684570312498, 69.4797851562501],
              [-125.26157226562498, 69.56616210937511],
              [-125.35693359374994, 69.62597656250006],
              [-125.34550781249993, 69.66245117187503],
              [-125.21938476562502, 69.73237304687507],
              [-125.22788085937498, 69.75673828125002],
              [-125.201171875, 69.82880859374995],
              [-125.11401367187494, 69.81503906250003],
              [-125.07958984374994, 69.81782226562507],
              [-125.031005859375, 69.8442871093751],
              [-124.96826171875, 69.89438476562503],
              [-124.88916015624994, 69.93579101562511],
              [-124.79365234374995, 69.968505859375],
              [-124.76791992187498, 69.99003906249993],
              [-124.86259765624999, 70.00551757812508],
              [-124.92001953124992, 70.00556640625013],
              [-124.96259765625001, 70.01259765625014],
              [-124.99038085937497, 70.02661132812511],
              [-124.95244140624999, 70.041748046875],
              [-124.7451171875, 70.08017578125003],
              [-124.70634765624993, 70.11699218750005],
              [-124.63994140624999, 70.14145507812509],
              [-124.55502929687492, 70.15122070312509],
              [-124.50258789062497, 70.14111328125],
              [-124.44448242187497, 70.110595703125],
              [-124.44150390624998, 70.06191406250002],
              [-124.46718749999997, 69.98256835937502],
              [-124.47192382812494, 69.91850585937493],
              [-124.40693359374995, 69.76743164062509],
              [-124.34936523437494, 69.73452148437514],
              [-124.12460937499998, 69.6899902343751],
              [-124.13847656249999, 69.65317382812509],
              [-124.39838867187501, 69.49384765625013],
              [-124.45390624999993, 69.454833984375],
              [-124.48134765625002, 69.42514648437498],
              [-124.47207031249997, 69.40004882812502],
              [-124.42617187499997, 69.3794433593751],
              [-124.33808593749995, 69.36484374999998],
              [-124.11171875000002, 69.35888671875],
              [-124.04965820312498, 69.37285156249999],
              [-123.60913085937494, 69.37744140625006],
              [-123.52841796874992, 69.38935546874995],
              [-123.46044921874994, 69.42001953124998],
              [-123.36147460937502, 69.49663085937499],
              [-123.24897460937501, 69.52001953125011],
              [-123.21367187499993, 69.54150390625006],
              [-123.14448242187495, 69.63247070312514],
              [-123.11040039062493, 69.73813476562498],
              [-123.07661132812501, 69.78247070312511],
              [-123.02578125000002, 69.81000976562507],
              [-122.95668945312497, 69.81884765625006],
              [-122.785400390625, 69.80844726562509],
              [-122.70488281249993, 69.81738281250011],
              [-122.3875, 69.80844726562509],
              [-122.070068359375, 69.81616210937506],
              [-121.74184570312502, 69.79750976562497],
              [-121.53110351562498, 69.77578125000002],
              [-121.33623046874999, 69.74155273437498],
              [-120.96245117187499, 69.66040039062511],
              [-120.81464843749995, 69.61684570312514],
              [-120.29252929687499, 69.42055664062514],
              [-120.13999023437492, 69.38056640625013],
              [-119.85283203124993, 69.34233398437499],
              [-118.86870117187499, 69.25717773437503],
              [-118.744873046875, 69.23427734375002],
              [-118.48559570312494, 69.14487304687509],
              [-118.30698242187498, 69.09272460937513],
              [-118.09521484375, 69.04291992187507],
              [-117.83032226562494, 68.99990234375002],
              [-117.311279296875, 68.93491210937492],
              [-117.22695312500002, 68.91342773437498],
              [-117.13173828124997, 68.90712890625014],
              [-117.02573242187498, 68.91596679687501],
              [-116.54995117187502, 68.87880859375008],
              [-116.424560546875, 68.88061523437506],
              [-116.33408203124992, 68.87363281250009],
              [-116.22270507812499, 68.84682617187514],
              [-116.05947265625002, 68.83701171875009],
              [-116.06523437499999, 68.85541992187507],
              [-116.25161132812502, 68.95791015625002],
              [-116.24340820312494, 68.97407226562513],
              [-116.16674804687494, 68.975341796875],
              [-115.93608398437493, 68.95810546875003],
              [-115.88325195312501, 68.98730468750011],
              [-115.80634765624995, 68.98662109374999],
              [-115.63115234374999, 68.97255859375014],
              [-115.44228515624997, 68.94091796875011],
              [-115.23984374999992, 68.89184570312506],
              [-114.99375, 68.85004882812507],
              [-114.62016601562499, 68.74609375],
              [-114.41386718749999, 68.65957031250014],
              [-114.21816406249997, 68.5520507812501],
              [-114.11083984374994, 68.47734374999999],
              [-114.09204101562494, 68.43540039062498],
              [-114.05112304687498, 68.41464843749998],
              [-113.98818359375002, 68.41499023437507],
              [-113.96440429687499, 68.39907226562502],
              [-114.02080078124993, 68.30649414062498],
              [-114.05322265624994, 68.28339843750007],
              [-114.09594726562494, 68.26679687500007],
              [-114.27475585937498, 68.2478515625001],
              [-114.76528320312497, 68.27021484375007],
              [-114.85219726562502, 68.19526367187507],
              [-115.12705078124998, 68.13203124999995],
              [-115.17592773437497, 68.10439453125002],
              [-115.18676757812494, 68.08417968750001],
              [-115.16708984374998, 68.01855468750011],
              [-115.20185546875001, 67.99843750000014],
              [-115.42685546874992, 67.92353515625013],
              [-115.43447265624997, 67.90234375000006],
              [-115.28847656249997, 67.87167968750003],
              [-115.13320312499997, 67.81918945312503],
              [-115.01118164062501, 67.80639648437506],
              [-114.85673828124999, 67.81357421875009],
              [-114.66289062499999, 67.7952148437501],
              [-114.42939453125001, 67.751220703125],
              [-114.26704101562501, 67.73115234375001],
              [-114.17573242187495, 67.73500976562508],
              [-114.05107421874999, 67.72690429687503],
              [-113.89321289062502, 67.70688476562503],
              [-113.68193359374999, 67.69995117187506],
              [-113.21499023437497, 67.70175781250009],
              [-113.07495117187494, 67.68666992187502],
              [-112.87944335937493, 67.67988281250007],
              [-112.50302734374998, 67.6819335937501],
              [-112.43515624999992, 67.68476562499995],
              [-112.31455078124992, 67.71958007812498],
              [-112.23671874999997, 67.73110351562502],
              [-112.10131835937494, 67.73173828124993],
              [-111.71088867187495, 67.75732421875],
              [-111.57573242187499, 67.75683593750011],
              [-111.45068359375, 67.77617187499993],
              [-111.29082031249993, 67.8152343750001],
              [-111.19218749999999, 67.82255859374999],
              [-111.15478515625, 67.79824218750002],
              [-111.08740234374994, 67.78764648437499],
              [-110.99003906249993, 67.7908203125001],
              [-110.80488281249995, 67.83232421875005],
              [-110.37197265624997, 67.95419921874998],
              [-110.21625976562495, 67.95400390625014],
              [-110.10195312500002, 67.9922363281251],
              [-110.07392578125001, 67.99291992187506],
              [-110.04248046875, 67.97719726562502],
              [-109.9365234375, 67.88789062499995],
              [-109.90424804687495, 67.87353515625006],
              [-109.83134765624993, 67.86582031250009],
              [-109.76015625000002, 67.82011718750005],
              [-109.68603515625, 67.75175781249993],
              [-109.63037109374994, 67.73271484374999],
              [-109.22431640625001, 67.7297851562501],
              [-109.08125, 67.7107421875001],
              [-109.03803710937501, 67.69116210937506],
              [-108.99448242187498, 67.63710937500002],
              [-108.96767578125002, 67.53237304687502],
              [-108.94990234374995, 67.49394531249999],
              [-108.89096679687498, 67.43808593749998],
              [-108.85200195312501, 67.42197265625009],
              [-108.815185546875, 67.4375],
              [-108.71513671874993, 67.58281249999999],
              [-108.68022460937493, 67.60620117187494],
              [-108.61333007812499, 67.59804687500008],
              [-108.59291992187497, 67.59086914062505],
              [-108.49150390624993, 67.48330078125014],
              [-108.34697265624999, 67.40341796875003],
              [-107.98872070312501, 67.2563964843751],
              [-107.93051757812498, 67.20249023437498],
              [-107.90917968749994, 67.16254882812501],
              [-107.929443359375, 67.12680664062498],
              [-107.99130859374998, 67.09516601562513],
              [-108.08842773437499, 67.06977539062513],
              [-108.22080078124992, 67.0505859375001],
              [-108.34433593749998, 67.05751953125008],
              [-108.45908203124998, 67.09042968750003],
              [-108.49604492187495, 67.09228515625006],
              [-108.45527343750001, 67.06298828124994],
              [-108.21816406250002, 66.94125976562503],
              [-108.15766601562495, 66.89262695312505],
              [-108.10146484374997, 66.86035156250006],
              [-108.04960937499999, 66.84433593749998],
              [-108.00175781249999, 66.81801757812514],
              [-107.95795898437495, 66.78129882812493],
              [-107.76093749999995, 66.68369140624998],
              [-107.70488281249999, 66.63710937500008],
              [-107.48032226562498, 66.49179687499992],
              [-107.37368164062502, 66.43466796875003],
              [-107.29135742187492, 66.40180664062507],
              [-107.25947265625001, 66.39853515624998],
              [-107.278076171875, 66.42490234374998],
              [-107.564453125, 66.61850585937498],
              [-107.71035156250002, 66.74003906250005],
              [-107.73085937499997, 66.76918945312514],
              [-107.740234375, 66.81376953124999],
              [-107.74599609375002, 66.96147460937505],
              [-107.72509765624994, 66.98413085937506],
              [-107.62617187499995, 67.003125],
              [-107.49921875000001, 66.93618164062508],
              [-107.45126953124992, 66.92675781250011],
              [-107.41884765624997, 66.93071289062499],
              [-107.40209960937494, 66.947998046875],
              [-107.32919921874992, 66.93198242187509],
              [-107.20019531249994, 66.882568359375],
              [-107.156494140625, 66.88173828124997],
              [-107.25375976562492, 66.9763671875001],
              [-107.32333984374998, 67.02255859375009],
              [-107.34785156250001, 67.05478515625009],
              [-107.28315429687495, 67.103271484375],
              [-107.31845703124998, 67.12778320312503],
              [-107.48237304687495, 67.19912109374997],
              [-107.56748046874998, 67.27304687499992],
              [-107.64404296874994, 67.38476562499994],
              [-107.65092773437493, 67.42822265625011],
              [-107.63837890624995, 67.47421875000009],
              [-107.64990234375, 67.51127929687499],
              [-107.75302734374992, 67.58686523437513],
              [-107.86508789062492, 67.6392089843751],
              [-107.95405273437495, 67.7],
              [-107.97211914062501, 67.73203125000003],
              [-107.95839843750002, 67.81860351562506],
              [-107.89091796874999, 67.85634765625014],
              [-107.76308593750002, 67.90683593750009],
              [-107.72861328124992, 67.95883789062503],
              [-107.78745117187492, 68.0125],
              [-107.79829101562501, 68.03691406249993],
              [-107.76103515624999, 68.03217773437507],
              [-107.509375, 68.05913085937505],
              [-107.44619140624997, 68.04965820312509],
              [-107.35112304687493, 68.06118164062508],
              [-107.22412109375, 68.09379882812499],
              [-107.12480468749993, 68.10844726562499],
              [-106.99365234374994, 68.10629882812503],
              [-106.92255859374995, 68.11416015625008],
              [-106.83564453125001, 68.12861328125001],
              [-106.79072265625001, 68.1448242187501],
              [-106.71098632812493, 68.20678710937511],
              [-106.66840820312495, 68.21601562500001],
              [-106.53486328125001, 68.2092773437501],
              [-106.45947265624994, 68.19565429687498],
              [-106.42426757812495, 68.20058593750008],
              [-106.42949218749993, 68.28847656250002],
              [-106.40439453124998, 68.31933593750011],
              [-106.27124023437494, 68.38320312499997],
              [-106.13212890624993, 68.38989257812511],
              [-106.03930664062494, 68.40732421875009],
              [-105.93305664062495, 68.44311523437511],
              [-105.85693359375, 68.47514648437505],
              [-105.78120117187495, 68.52656250000007],
              [-105.75019531250001, 68.59228515625011],
              [-105.77431640624997, 68.61113281250005],
              [-105.93222656249998, 68.6365234375001],
              [-106.02714843749999, 68.62333984375005],
              [-106.23730468749994, 68.57656250000014],
              [-106.45805664062499, 68.51645507812498],
              [-106.54331054687498, 68.46059570312514],
              [-106.566650390625, 68.3889648437501],
              [-106.60849609374992, 68.35737304687507],
              [-106.78041992187502, 68.38730468750009],
              [-106.85371093749995, 68.38681640625003],
              [-106.94580078125, 68.37436523437498],
              [-107.04331054687498, 68.34682617187502],
              [-107.14619140624995, 68.30419921875006],
              [-107.29814453124999, 68.29643554687505],
              [-107.49912109374998, 68.3235351562501],
              [-107.61933593749995, 68.3310546875],
              [-107.74150390624999, 68.28574218750003],
              [-107.73422851562492, 68.25205078125009],
              [-107.67763671875002, 68.20292968750005],
              [-107.73417968749993, 68.17373046875014],
              [-108.02719726562492, 68.16293945312503],
              [-108.10458984374998, 68.16928710937509],
              [-108.26103515624999, 68.14990234375006],
              [-108.32280273437493, 68.15410156250005],
              [-108.367919921875, 68.17753906249999],
              [-108.68657226562499, 68.27734375000011],
              [-108.71811523437492, 68.29746093750009],
              [-108.64091796874999, 68.37851562500009],
              [-108.34575195312499, 68.59780273437502],
              [-108.3134765625, 68.61079101562501],
              [-107.766357421875, 68.64892578125011],
              [-107.43593750000002, 68.68886718750008],
              [-106.83066406249998, 68.80947265625014],
              [-106.71347656249998, 68.81948242187502],
              [-106.32426757812493, 68.89946289062499],
              [-106.16445312499997, 68.91987304687507],
              [-106.01567382812493, 68.9060546875001],
              [-105.79794921874998, 68.86479492187505],
              [-105.68559570312493, 68.82817382812505],
              [-105.60605468749998, 68.78242187500013],
              [-105.53984374999999, 68.71865234375014],
              [-105.45693359374997, 68.57807617187507],
              [-105.42861328124997, 68.458251953125],
              [-105.37744140624994, 68.413818359375],
              [-105.19497070312497, 68.33037109375005],
              [-105.101318359375, 68.29799804687502],
              [-105.04360351562497, 68.2878906250001],
              [-104.99379882812502, 68.30742187499999],
              [-104.95981445312492, 68.31054687500011],
              [-104.93671874999995, 68.30302734374999],
              [-104.91196289062498, 68.25048828125011],
              [-104.87944335937499, 68.24526367187514],
              [-104.76962890624992, 68.25175781250005],
              [-104.65317382812492, 68.23007812500003],
              [-104.63637695312497, 68.21391601562493],
              [-104.66113281249994, 68.14877929687503],
              [-104.628173828125, 68.12148437500014],
              [-104.48681640624994, 68.06318359374995],
              [-104.35073242187502, 68.04121093750013],
              [-104.19355468749995, 68.03120117187501],
              [-103.90156250000001, 68.0410644531251],
              [-103.65722656249994, 68.06909179687511],
              [-103.47412109374994, 68.11503906250005],
              [-103.32324218749994, 68.06381835937509],
              [-103.02177734374999, 67.94023437499999],
              [-102.84155273437494, 67.85273437500007],
              [-102.69199218749998, 67.81157226562505],
              [-102.38911132812501, 67.76220703125011],
              [-102.32036132812493, 67.73564453125005],
              [-102.20976562499993, 67.73271484374999],
              [-102.05722656249998, 67.75332031250008],
              [-101.88364257812492, 67.74531250000007],
              [-101.68886718749997, 67.70864257812502],
              [-101.55498046874993, 67.6931640625001],
              [-101.09638671874995, 67.76235351562514],
              [-101.02641601562493, 67.7656738281251],
              [-100.85561523437502, 67.79897460937514],
              [-100.74560546875, 67.80908203125006],
              [-100.61611328125002, 67.80825195312508],
              [-100.51962890624998, 67.81840820312505],
              [-100.45610351562499, 67.83945312500003],
              [-100.21293945312493, 67.83857421875007],
              [-99.77294921875, 67.81484375000002],
              [-99.47226562499992, 67.78408203125014],
              [-99.29355468749992, 67.74531250000007],
              [-99.146875, 67.72363281250006],
              [-99.03217773437501, 67.71884765624998],
              [-98.92045898437499, 67.72578125000001],
              [-98.81171875000001, 67.74443359375005],
              [-98.69726562499994, 67.77973632812513],
              [-98.45278320312497, 67.7979003906251],
              [-98.41210937499994, 67.80717773437505],
              [-98.41713867187502, 67.82646484375005],
              [-98.46782226562493, 67.85581054687503],
              [-98.60649414062493, 67.91142578124999],
              [-98.70356445312501, 67.96572265625002],
              [-98.72221679687499, 68.00019531250013],
              [-98.72006835937498, 68.04199218750011],
              [-98.68984374999997, 68.06611328125007],
              [-98.63154296875001, 68.0725585937501],
              [-98.53984374999993, 68.046630859375],
              [-98.414794921875, 67.98842773437502],
              [-98.06254882812499, 67.76967773437502],
              [-97.97763671874998, 67.73862304687492],
              [-97.93076171874998, 67.71079101562498],
              [-97.607421875, 67.63105468750007],
              [-97.45493164062492, 67.61699218750005],
              [-97.27426757812498, 67.66625976562511],
              [-97.19443359374998, 67.69692382812514],
              [-97.15541992187502, 67.72641601562492],
              [-97.15717773437501, 67.75483398437507],
              [-97.13984375000001, 67.79624023437498],
              [-97.15805664062492, 67.82192382812502],
              [-97.20654296874994, 67.85507812500003],
              [-97.33613281249995, 67.90136718750006],
              [-97.54663085937494, 67.96074218750005],
              [-97.73911132812498, 67.97817382812508],
              [-97.91333007812494, 67.95356445312501],
              [-98.11049804687497, 67.90302734375001],
              [-98.19252929687497, 67.92299804687502],
              [-98.43837890624997, 68.06469726562511],
              [-98.50029296874999, 68.11767578125006],
              [-98.500244140625, 68.13227539062501],
              [-98.38608398437498, 68.11533203124992],
              [-98.380859375, 68.13247070312508],
              [-98.44916992187495, 68.20078124999992],
              [-98.49125976562499, 68.2236328125],
              [-98.63300781250001, 68.33115234375003],
              [-98.65048828124992, 68.36352539062506],
              [-98.562255859375, 68.37084960937511],
              [-98.522216796875, 68.38339843750003],
              [-98.46855468749999, 68.3821289062501],
              [-98.21855468749999, 68.3174316406251],
              [-98.09052734375001, 68.34633789062514],
              [-97.79423828124993, 68.38759765624997],
              [-97.91103515625002, 68.44951171874999],
              [-97.93886718750002, 68.51044921874995],
              [-97.92509765624993, 68.523681640625],
              [-97.82856445312501, 68.5327636718751],
              [-97.63955078125002, 68.48198242187505],
              [-97.54804687499995, 68.47495117187503],
              [-97.48110351562502, 68.49516601562505],
              [-97.41035156249995, 68.49653320312495],
              [-97.33579101562498, 68.47915039062497],
              [-97.26591796874993, 68.45292968749999],
              [-97.13598632812494, 68.37797851562499],
              [-97.07177734375, 68.33286132812503],
              [-96.99956054687493, 68.26494140625005],
              [-96.97670898437502, 68.25541992187505],
              [-96.62817382812494, 68.2502929687501],
              [-96.43066406249994, 68.3105957031251],
              [-96.43496093750002, 68.29008789062505],
              [-96.48022460937494, 68.24282226562497],
              [-96.72514648437499, 68.06123046875013],
              [-96.72207031250002, 68.03876953124995],
              [-96.59218750000002, 68.04843749999998],
              [-96.53129882812493, 68.06313476562514],
              [-96.49370117187493, 68.0849609375],
              [-96.46118164062494, 68.13583984375003],
              [-96.43935546874997, 68.15087890625006],
              [-96.07558593750002, 68.23652343750013],
              [-95.97031249999998, 68.24912109375003],
              [-96.03603515625002, 68.15776367187505],
              [-96.17133789062495, 67.83168945312508],
              [-96.19882812499998, 67.71782226562499],
              [-96.22846679687495, 67.67919921875011],
              [-96.37138671874999, 67.55385742187514],
              [-96.36914062499994, 67.50976562500006],
              [-96.21284179687501, 67.40429687500006],
              [-96.18500976562501, 67.37558593750003],
              [-96.16923828124999, 67.28896484374997],
              [-96.14145507812492, 67.27182617187503],
              [-96.01259765624997, 67.27089843750002],
              [-95.87910156249995, 67.29848632812514],
              [-95.71992187500001, 67.31679687500014],
              [-95.69516601562498, 67.29873046875002],
              [-95.78251953124999, 67.19379882812513],
              [-95.77768554687498, 67.18461914062505],
              [-95.62641601562501, 67.21157226562502],
              [-95.55703124999997, 67.21528320312507],
              [-95.52871093749997, 67.20917968750007],
              [-95.41591796875002, 67.15556640624999],
              [-95.40458984374999, 67.11557617187503],
              [-95.40698242187494, 67.05610351562495],
              [-95.41889648437501, 67.01323242187493],
              [-95.45698242187501, 66.98945312500007],
              [-95.50224609374999, 66.97988281250008],
              [-95.559375, 66.97275390625003],
              [-95.61064453124999, 66.97568359374992],
              [-95.76865234374992, 66.96669921875008],
              [-95.86181640625, 66.97817382812508],
              [-95.95405273437501, 67.01088867187502],
              [-96.01953124999994, 67.01875],
              [-96.095458984375, 66.99355468750002],
              [-96.215576171875, 66.99770507812497],
              [-96.35043945312498, 67.07001953124995],
              [-96.40424804687501, 67.063232421875],
              [-96.42255859375001, 67.0517578125],
              [-96.42026367187498, 67.03618164062499],
              [-96.35952148437502, 66.98940429687508],
              [-95.88530273437499, 66.74135742187497],
              [-95.81328124999993, 66.69013671875001],
              [-95.79736328125, 66.61655273437498],
              [-95.78754882812495, 66.61679687500003],
              [-95.74316406249994, 66.69042968750011],
              [-95.77211914062502, 66.72607421875006],
              [-96.01611328125, 66.87045898437498],
              [-96.04536132812495, 66.92314453125005],
              [-96.03686523437494, 66.9375],
              [-95.97236328125001, 66.95224609374998],
              [-95.62504882812499, 66.91625976562511],
              [-95.49038085937497, 66.9241210937501],
              [-95.399658203125, 66.94946289062511],
              [-95.35410156249992, 66.98071289062506],
              [-95.32109374999993, 67.15249023437508],
              [-95.25874023437495, 67.26254882812492],
              [-95.29560546875001, 67.36103515625013],
              [-95.38955078124997, 67.51782226562506],
              [-95.46337890625, 67.61020507812509],
              [-95.63369140624997, 67.70385742187494],
              [-95.65048828124992, 67.73745117187508],
              [-95.460693359375, 68.02138671874997],
              [-95.42651367187494, 68.04526367187503],
              [-95.38408203124999, 68.05556640625002],
              [-95.23471679687492, 68.05971679687497],
              [-95.12587890624997, 68.08330078124999],
              [-94.95522460937502, 68.05029296875],
              [-94.86103515625001, 68.04165039062502],
              [-94.74443359374999, 68.07089843749998],
              [-94.48530273437495, 68.19008789062508],
              [-94.38383789062499, 68.22700195312513],
              [-94.25478515624997, 68.29682617187495],
              [-94.09814453124994, 68.39941406250011],
              [-93.92773437499994, 68.47382812499995],
              [-93.65170898437492, 68.54311523437508],
              [-93.48300781249992, 68.59887695312506],
              [-93.44892578125001, 68.61889648437506],
              [-93.60581054687492, 68.62368164062514],
              [-93.64394531249997, 68.6331054687501],
              [-93.67617187500002, 68.68598632812501],
              [-93.65986328125001, 68.78374023437505],
              [-93.66279296874995, 68.83818359374993],
              [-93.68144531249993, 68.88725585937499],
              [-93.71577148437501, 68.93105468750002],
              [-93.76572265624998, 68.96958007812509],
              [-93.81132812499993, 68.99267578125],
              [-93.85244140624997, 69.00034179687492],
              [-93.88071289062498, 68.99682617187511],
              [-93.89609374999998, 68.982177734375],
              [-93.93808593749992, 68.88906250000002],
              [-93.99155273437498, 68.8206054687501],
              [-94.06489257812501, 68.78476562500009],
              [-94.21694335937497, 68.7605468750001],
              [-94.47832031249993, 68.74277343750003],
              [-94.58676757812492, 68.77553710937514],
              [-94.60043945312498, 68.80322265625011],
              [-94.56254882812499, 68.91166992187499],
              [-94.47563476562493, 68.95815429687508],
              [-94.23662109374999, 69.04975585937507],
              [-94.08364257812502, 69.1230957031251],
              [-94.08115234374992, 69.13583984375003],
              [-94.22182617187502, 69.13637695312514],
              [-94.25537109375, 69.15146484374998],
              [-94.28496093749999, 69.24160156250008],
              [-94.27675781250002, 69.27524414062498],
              [-94.25473632812498, 69.31376953125005],
              [-94.15634765624998, 69.34174804687507],
              [-93.85439453125002, 69.37636718749997],
              [-93.61948242187492, 69.41699218750011],
              [-93.61264648437498, 69.40283203125006],
              [-93.80097656249995, 69.28090820312508],
              [-93.82045898437502, 69.25263671875001],
              [-93.74853515624994, 69.2261230468751],
              [-93.56748046874992, 69.296875],
              [-93.45058593750002, 69.35517578125013],
              [-93.43095703124999, 69.3750488281251],
              [-93.53706054687501, 69.38232421874994],
              [-93.54287109374997, 69.40644531250007],
              [-93.52241210937495, 69.45068359375006],
              [-93.53227539062493, 69.48090820312495],
              [-93.64980468750002, 69.51904296875011],
              [-93.79438476562495, 69.49785156250005],
              [-93.91508789062493, 69.45766601562502],
              [-94.01528320312502, 69.44672851562513],
              [-94.16318359374998, 69.44594726562514],
              [-94.27080078124993, 69.45512695312505],
              [-94.338134765625, 69.47426757812502],
              [-94.419189453125, 69.51704101562507],
              [-94.513916015625, 69.58344726562507],
              [-94.63383789062499, 69.64965820312506],
              [-94.67626953124994, 69.65688476562514],
              [-94.71269531249999, 69.6494140625],
              [-94.78925781249995, 69.58544921875011],
              [-94.822509765625, 69.57778320312497],
              [-95.29208984374998, 69.66738281250014],
              [-95.49125976562499, 69.71762695312503],
              [-95.58759765624995, 69.75571289062498],
              [-95.70742187499997, 69.77822265625014],
              [-95.85068359374992, 69.78510742187513],
              [-95.96494140624992, 69.80278320312499],
              [-96.05014648437498, 69.83115234375009],
              [-96.11909179687501, 69.871875],
              [-96.17177734374997, 69.92495117187502],
              [-96.26938476562498, 69.99179687499998],
              [-96.49238281250001, 70.12490234375008],
              [-96.55136718749998, 70.21030273437509],
              [-96.55957031249994, 70.24301757812498],
              [-96.54560546875001, 70.32724609375009],
              [-96.33657226562497, 70.47016601562507],
              [-96.29770507812498, 70.51137695312514],
              [-96.22641601562492, 70.54169921875007],
              [-96.12275390625001, 70.56123046874995],
              [-96.04814453124993, 70.56708984375013],
              [-95.87861328125001, 70.54897460937514],
              [-95.98017578125001, 70.59321289062507],
              [-95.98818359374997, 70.61684570312514],
              [-95.88632812499992, 70.69428710937507],
              [-95.90639648437502, 70.69775390625006],
              [-96.18642578125002, 70.63828125000003],
              [-96.25800781249995, 70.64228515625013],
              [-96.35888671875, 70.67866210937507],
              [-96.54892578124998, 70.80874023437514],
              [-96.55107421874999, 70.88974609375009],
              [-96.49130859374998, 71.00234375000014],
              [-96.47041015624995, 71.06972656250002],
              [-96.52475585937492, 71.12705078124998],
              [-96.50444335937499, 71.14316406250003],
              [-96.44545898437497, 71.1592285156251],
              [-96.42075195312498, 71.17646484375007],
              [-96.44658203124999, 71.23989257812502],
              [-96.40566406250002, 71.27363281249995],
              [-96.27133789062492, 71.339111328125],
              [-96.1396484375, 71.39638671875014],
              [-96.06201171875, 71.41386718749993],
              [-95.99443359374999, 71.41064453125],
              [-95.92407226562494, 71.39306640625],
              [-95.85087890624999, 71.36108398437506],
              [-95.72539062499999, 71.3281738281251],
              [-95.632568359375, 71.31879882812513],
              [-95.56425781249993, 71.33676757812503],
              [-95.44750976562494, 71.46005859374992],
              [-95.40625, 71.49165039062501],
              [-95.44541015624998, 71.50537109375011],
              [-95.67421875000002, 71.50405273437502],
              [-95.77338867187501, 71.51425781249998],
              [-95.83037109374999, 71.52607421875007],
              [-95.872314453125, 71.57314453125008],
              [-95.83774414062498, 71.59824218750003],
              [-95.61591796874995, 71.68540039062503],
              [-95.51166992187495, 71.77680664062501],
              [-95.20122070312493, 71.90371093750008],
              [-94.886962890625, 71.96337890625],
              [-94.73486328125, 71.98295898437505],
              [-94.61113281249999, 71.98686523437499],
              [-94.55708007812501, 71.97895507812513],
              [-94.49106445312498, 71.91552734375],
              [-94.47880859374993, 71.84858398437507],
              [-94.30834960937494, 71.76489257812506],
              [-94.17124023437492, 71.75844726562497],
              [-94.08598632812493, 71.77114257812508],
              [-93.81020507812497, 71.76625976562514],
              [-93.74628906250001, 71.74282226562502],
              [-93.75087890624997, 71.71665039062498],
              [-93.78164062500002, 71.67431640625006],
              [-93.76284179687502, 71.63803710937498],
              [-93.57587890624995, 71.56870117187503],
              [-93.407470703125, 71.52070312500007],
              [-93.25634765624994, 71.46083984375008],
              [-93.03129882812493, 71.335693359375],
              [-92.98256835937502, 71.3003417968751],
              [-92.94868164062495, 71.26210937500014],
              [-92.89018554687499, 71.12236328125005],
              [-92.88271484375002, 71.06933593750011],
              [-92.90419921874997, 70.91606445312499],
              [-92.92197265624998, 70.88710937500008],
              [-92.9814453125, 70.85224609375013],
              [-92.96088867187495, 70.83813476562506],
              [-92.78300781249999, 70.7981445312501],
              [-92.64169921874998, 70.7187988281251],
              [-92.56748046874992, 70.69321289062503],
              [-92.38847656249999, 70.65043945312505],
              [-92.35581054687498, 70.63427734375011],
              [-92.31538085937501, 70.60751953124998],
              [-92.21445312499998, 70.49291992187511],
              [-92.04912109374993, 70.3896484375],
              [-92.03720703124992, 70.36738281250007],
              [-92.07260742187492, 70.31875000000014],
              [-92.04736328124994, 70.30332031250003],
              [-91.98354492187502, 70.28554687500014],
              [-91.92622070312495, 70.29477539062503],
              [-91.87553710937493, 70.33115234374998],
              [-91.82041015624998, 70.34165039062498],
              [-91.76083984374992, 70.32626953125009],
              [-91.715625, 70.29921875000008],
              [-91.654052734375, 70.23295898437505],
              [-91.56406249999998, 70.1782714843751],
              [-91.57163085937492, 70.16157226562507],
              [-91.61611328125002, 70.14785156250014],
              [-91.85859375000001, 70.13266601562503],
              [-91.99497070312492, 70.14321289062508],
              [-92.12104492187501, 70.169921875],
              [-92.20864257812502, 70.19750976562511],
              [-92.29033203124999, 70.23984375000003],
              [-92.32050781250001, 70.2353515625],
              [-92.36328125, 70.20083007812508],
              [-92.45468749999998, 70.15043945312493],
              [-92.51186523437497, 70.10385742187503],
              [-92.44575195312501, 70.08315429687508],
              [-92.12700195312499, 70.08452148437505],
              [-92.05771484374992, 70.07143554687502],
              [-91.97670898437502, 70.03867187500009],
              [-92.06904296875001, 69.98398437499998],
              [-92.28476562499998, 69.8921386718751],
              [-92.75092773437501, 69.71391601562499],
              [-92.88779296874992, 69.66821289062511],
              [-92.85454101562499, 69.65488281250009],
              [-92.80263671874997, 69.65146484375009],
              [-92.642822265625, 69.65927734375009],
              [-92.49345703124999, 69.68320312499998],
              [-92.31166992187497, 69.67290039062499],
              [-92.23076171874999, 69.6533691406251],
              [-92.25830078125, 69.63432617187499],
              [-92.20908203124992, 69.60332031250005],
              [-91.91196289062498, 69.53125000000011],
              [-91.72412109375, 69.54560546875001],
              [-91.53237304687502, 69.6150390625001],
              [-91.38422851562501, 69.64946289062505],
              [-91.20180664062497, 69.64477539062494],
              [-91.15087890625, 69.63715820312501],
              [-91.17031249999997, 69.62031250000013],
              [-91.30502929687498, 69.581298828125],
              [-91.42685546874992, 69.53793945312503],
              [-91.43994140625, 69.52568359375005],
              [-91.28813476562499, 69.54321289062506],
              [-90.9501953125, 69.51547851562509],
              [-90.78574218749998, 69.5085937500001],
              [-90.66665039062502, 69.51552734375008],
              [-90.554931640625, 69.50449218749998],
              [-90.45053710937498, 69.47543945312509],
              [-90.41557617187493, 69.45698242187507],
              [-90.51328124999998, 69.44511718749999],
              [-90.60556640624992, 69.4453125],
              [-90.68398437500002, 69.427734375],
              [-90.74853515625, 69.39248046875008],
              [-90.79458007812497, 69.34672851562499],
              [-90.82211914062498, 69.29047851562507],
              [-90.89228515625001, 69.26728515624995],
              [-91.00498046874992, 69.277099609375],
              [-91.04921874999997, 69.29301757812505],
              [-91.02485351562501, 69.31523437499999],
              [-91.05776367187502, 69.3184082031251],
              [-91.14785156250002, 69.30258789062503],
              [-91.21796875000001, 69.30209960937498],
              [-91.23720703125002, 69.28554687500014],
              [-90.74477539062497, 69.10590820312513],
              [-90.58730468750002, 68.946875],
              [-90.47900390625, 68.88115234374999],
              [-90.46835937499998, 68.86376953125],
              [-90.53896484375002, 68.81958007812506],
              [-90.54252929687499, 68.78598632812498],
              [-90.51015625000002, 68.68886718750008],
              [-90.52524414062498, 68.61127929687507],
              [-90.57363281250002, 68.47470703124998],
              [-90.5283203125, 68.43222656250003],
              [-90.42304687499995, 68.39472656250001],
              [-90.36005859375001, 68.34672851562499],
              [-90.31723632812498, 68.33032226562506],
              [-90.28525390624998, 68.29165039062497],
              [-90.24775390624995, 68.26743164062503],
              [-90.20478515625001, 68.25747070312514],
              [-90.17441406249992, 68.27021484375007],
              [-90.15683593749998, 68.30551757812498],
              [-90.11640625000001, 68.33857421875013],
              [-90.00532226562495, 68.39804687499998],
              [-89.89770507812494, 68.49077148437505],
              [-89.87949218749998, 68.5215332031251],
              [-89.89658203124998, 68.59438476562502],
              [-89.88422851562495, 68.62558593749998],
              [-89.78310546874997, 68.73593750000009],
              [-89.75083007812498, 68.81245117187501],
              [-89.72016601562501, 68.93159179687495],
              [-89.66660156249998, 69.01459960937501],
              [-89.55200195312494, 69.08491210937513],
              [-89.35190429687498, 69.22700195312513],
              [-89.27954101562494, 69.25546875000003],
              [-89.198486328125, 69.26948242187507],
              [-89.05673828124998, 69.26611328125011],
              [-88.95351562499997, 69.22041015625001],
              [-88.81455078124992, 69.13588867187502],
              [-88.63774414062493, 69.05883789062494],
              [-88.31552734374992, 68.95444335937503],
              [-88.22353515625002, 68.9150390625],
              [-88.04135742187498, 68.81171875000007],
              [-87.96435546875, 68.70927734374999],
              [-87.91152343749997, 68.564697265625],
              [-87.865966796875, 68.47763671875003],
              [-87.82773437499998, 68.44809570312503],
              [-87.81030273437494, 68.40415039062498],
              [-87.81357421874992, 68.345703125],
              [-87.82792968749999, 68.29995117187508],
              [-87.853271484375, 68.2668945312501],
              [-87.89267578124998, 68.24814453124998],
              [-87.990966796875, 68.24204101562498],
              [-88.11113281249999, 68.25117187500007],
              [-88.14580078124999, 68.26601562500008],
              [-88.20908203124992, 68.33486328125008],
              [-88.23525390625002, 68.33906250000007],
              [-88.34697265624993, 68.28828125000001],
              [-88.36069335937492, 68.25986328125009],
              [-88.31962890624999, 68.16577148437511],
              [-88.32509765625002, 67.98876953125011],
              [-88.31381835937498, 67.95034179687508],
              [-88.19589843749992, 67.76582031249995],
              [-87.99716796874992, 67.62568359375001],
              [-87.49912109374998, 67.35532226562503],
              [-87.47080078124998, 67.32460937500014],
              [-87.41791992187495, 67.21416015625005],
              [-87.39194335937498, 67.19106445312514],
              [-87.359375, 67.17724609374994],
              [-87.32026367187495, 67.17285156249994],
              [-87.26625976562497, 67.18383789062506],
              [-87.08320312499995, 67.26777343750013],
              [-86.92392578124992, 67.35625],
              [-86.81279296874993, 67.40239257812499],
              [-86.74985351562492, 67.40610351562503],
              [-86.68203124999997, 67.42231445312495],
              [-86.609375, 67.45083007812508],
              [-86.56079101562494, 67.48212890625007],
              [-86.53642578124999, 67.5161621093751],
              [-86.50351562499992, 67.64946289062505],
              [-86.47553710937501, 67.71313476562506],
              [-86.39804687499992, 67.80009765625005],
              [-86.36967773437499, 67.82480468750009],
              [-85.98447265624998, 68.04536132812507],
              [-85.95258789062495, 68.07246093750007],
              [-85.78886718749999, 68.32802734375014],
              [-85.73110351562502, 68.44501953125013],
              [-85.72280273437502, 68.51547851562509],
              [-85.744873046875, 68.57827148437514],
              [-85.73383789062495, 68.630126953125],
              [-85.68979492187498, 68.67094726562499],
              [-85.64316406249998, 68.69970703125],
              [-85.56245117187495, 68.72880859375005],
              [-85.51777343750001, 68.76982421875005],
              [-85.49106445312498, 68.77397460937499],
              [-85.42504882812494, 68.77426757812509],
              [-85.33808593750001, 68.74628906250007],
              [-85.27509765624995, 68.74135742187514],
              [-84.86757812499997, 68.77333984375008],
              [-84.86748046874993, 68.79038085937498],
              [-85.10664062500001, 68.8440429687501],
              [-85.10434570312498, 68.87094726562503],
              [-85.08339843749997, 68.90791015625013],
              [-85.00830078124994, 68.94921875],
              [-84.91606445312499, 68.96225585937498],
              [-84.89531249999993, 68.988525390625],
              [-84.89272460937502, 69.02099609375006],
              [-84.86220703125002, 69.073974609375],
              [-84.89003906250002, 69.09277343750011],
              [-85.113525390625, 69.16586914062509],
              [-85.24262695312501, 69.16274414062497],
              [-85.27548828124992, 69.17231445312501],
              [-85.38676757812493, 69.23188476562507],
              [-85.42753906249999, 69.3184082031251],
              [-85.43193359374993, 69.35385742187503],
              [-85.41640624999997, 69.41088867187511],
              [-85.40224609375002, 69.42675781250011],
              [-85.4091796875, 69.45249023437503],
              [-85.43720703125001, 69.48823242187507],
              [-85.43950195312493, 69.51992187500008],
              [-85.41596679687495, 69.54775390625008],
              [-85.43012695312501, 69.58066406250003],
              [-85.48203124999992, 69.61875],
              [-85.50244140625, 69.65151367187508],
              [-85.44790039062497, 69.74814453125003],
              [-85.44609374999999, 69.77778320312507],
              [-85.49746093750002, 69.81904296875013],
              [-85.53481445312502, 69.83505859374998],
              [-85.50737304687493, 69.84526367187493],
              [-85.41513671874998, 69.84951171875008],
              [-85.30498046874999, 69.83613281250007],
              [-85.17680664062499, 69.80512695312513],
              [-85.01982421874999, 69.80478515625003],
              [-84.83398437499994, 69.83505859374998],
              [-84.64511718749992, 69.84970703124998],
              [-84.31879882812501, 69.84370117187501],
              [-84.24165039062501, 69.83500976562499],
              [-83.91718749999995, 69.745361328125],
              [-83.66533203124999, 69.69970703124994],
              [-83.55170898437501, 69.70395507812492],
              [-82.99135742187502, 69.68588867187498],
              [-82.74560546874994, 69.6951171875001],
              [-82.61835937499995, 69.69106445312514],
              [-82.37416992187502, 69.64179687500007],
              [-82.39023437499992, 69.6008789062501],
              [-82.49570312499992, 69.53222656250011],
              [-82.63330078125, 69.5181152343751],
              [-82.75483398437495, 69.49438476562506],
              [-82.64204101562495, 69.45839843750002],
              [-82.30986328124999, 69.41000976562509],
              [-82.23183593749997, 69.33256835937493],
              [-82.20815429687497, 69.29702148437497],
              [-82.24677734375001, 69.26499023437503],
              [-82.2275390625, 69.24887695312498],
              [-82.15053710937502, 69.24887695312498],
              [-81.95180664062497, 69.27607421875001],
              [-81.732177734375, 69.25810546875005],
              [-81.41230468750001, 69.19814453125008],
              [-81.37783203125002, 69.18564453125003],
              [-81.32158203124999, 69.13891601562511],
              [-81.32866210937493, 69.11992187499999],
              [-81.61142578124998, 69.00302734374998],
              [-81.75834960937499, 68.95673828125013],
              [-81.95166015625, 68.90908203125002],
              [-81.95791015624997, 68.88364257812498],
              [-81.68691406250002, 68.8789550781251],
              [-81.47602539062501, 68.86557617187503],
              [-81.38090820312499, 68.85004882812507],
              [-81.33129882812494, 68.82797851562498],
              [-81.26352539062498, 68.78061523437492],
              [-81.25249023437493, 68.74316406250011],
              [-81.25913085937498, 68.6924316406251],
              [-81.28154296874993, 68.65722656250006],
              [-81.52685546875, 68.55595703124999],
              [-81.63950195312492, 68.52436523437513],
              [-81.83139648437492, 68.48686523437499],
              [-81.91484374999999, 68.4587890625001],
              [-82.00649414062502, 68.462646484375],
              [-82.10634765625002, 68.49853515625006],
              [-82.21015625000001, 68.50625],
              [-82.397216796875, 68.47758789062505],
              [-82.49873046875001, 68.47861328125003],
              [-82.54863281249993, 68.46860351562498],
              [-82.55268554687501, 68.44648437500007],
              [-82.46416015624999, 68.38242187499998],
              [-82.41298828125002, 68.357177734375],
              [-82.39287109374993, 68.33833007812507],
              [-82.4306640625, 68.30659179687501],
              [-82.42270507812498, 68.29658203125013],
              [-82.39252929687495, 68.28525390625009],
              [-82.22241210937494, 68.145263671875],
              [-82.18657226562499, 68.13442382812508],
              [-82.15131835937501, 68.1396972656251],
              [-82.07763671875, 68.17968750000011],
              [-82.03388671875001, 68.19594726562502],
              [-82.0125, 68.19389648437499],
              [-82.01337890624995, 68.17338867187505],
              [-82.09189453124998, 68.05146484375007],
              [-82.10214843749992, 68.01889648437503],
              [-82.10048828124997, 67.98984374999998],
              [-82.06254882812499, 67.92817382812501],
              [-81.97646484375002, 67.86201171875001],
              [-81.86933593749995, 67.802490234375],
              [-81.70859374999998, 67.72236328125001],
              [-81.49277343749998, 67.63691406250001],
              [-81.41230468750001, 67.59536132812508],
              [-81.29433593750002, 67.49741210937498],
              [-81.27011718749998, 67.45991210937501],
              [-81.30107421874999, 67.35698242187499],
              [-81.38720703124994, 67.18857421874998],
              [-81.44272460937498, 67.09287109375003],
              [-81.46757812499999, 67.0698730468751],
              [-81.63007812499995, 67.00200195312499],
              [-81.72236328125001, 66.98608398437511],
              [-81.87446289062501, 66.98793945312514],
              [-81.92553710937494, 66.97470703125009],
              [-82.00507812499995, 66.92041015625006],
              [-82.11318359374997, 66.82509765624997],
              [-82.19833984374992, 66.76464843750011],
              [-82.26054687499999, 66.73911132812503],
              [-82.374755859375, 66.70942382812507],
              [-82.55366210937501, 66.62138671875005],
              [-82.64150390624997, 66.5875],
              [-82.94887695312497, 66.55083007812505],
              [-83.19877929687499, 66.43149414062509],
              [-83.29838867187493, 66.39213867187505],
              [-83.40644531250001, 66.37124023437508],
              [-83.52309570312497, 66.36875],
              [-83.59028320312501, 66.38784179687508],
              [-83.62836914062501, 66.460693359375],
              [-83.65107421875001, 66.48461914062506],
              [-83.73920898437495, 66.534375],
              [-83.92021484374999, 66.67905273437509],
              [-83.998046875, 66.72851562500006],
              [-84.05, 66.73950195312494],
              [-84.15444335937497, 66.73168945312494],
              [-84.2080078125, 66.736328125],
              [-84.32436523437497, 66.78178710937505],
              [-84.36625976562499, 66.81113281249998],
              [-84.361083984375, 66.82255859374999],
              [-84.27255859374998, 66.83920898437503],
              [-84.31035156249993, 66.86274414062501],
              [-84.46606445312494, 66.92744140625007],
              [-84.53066406249997, 66.96132812500002],
              [-84.53847656249997, 66.97280273437508],
              [-84.69257812500001, 67.01660156250011],
              [-84.84575195312499, 67.02871093750008],
              [-85.0400390625, 66.9560546875],
              [-85.11372070312501, 66.90693359375013],
              [-85.11127929687495, 66.8909179687501],
              [-85.01826171875001, 66.87207031249994],
              [-84.97797851562501, 66.88125],
              [-84.89902343749998, 66.92656250000005],
              [-84.85737304687501, 66.94067382812511],
              [-84.73774414062501, 66.93359375000006],
              [-84.63857421875002, 66.90234375000011],
              [-84.6025390625, 66.87514648437508],
              [-84.58950195312497, 66.85664062500001],
              [-84.31894531249992, 66.71181640625002],
              [-84.22304687500002, 66.68247070312509],
              [-84.18310546874994, 66.64785156250014],
              [-84.15273437500002, 66.59023437500008],
              [-84.09423828125, 66.52622070312503],
              [-83.96420898437498, 66.42055664062502],
              [-83.82583007812502, 66.28999023437507],
              [-83.79755859375001, 66.23847656250001],
              [-83.86904296875002, 66.21357421875013],
              [-83.90507812499999, 66.21176757812509],
              [-84.01166992187495, 66.23120117187494],
              [-84.29306640624998, 66.29179687500005],
              [-84.32426757812493, 66.29067382812502],
              [-84.39843749999994, 66.25874023437507],
              [-84.459375, 66.18623046875007],
              [-84.47841796875002, 66.17929687500003],
              [-84.62807617187502, 66.20771484374995],
              [-84.90839843750001, 66.27133789062498],
              [-85.09619140625, 66.32534179687514],
              [-85.19150390624998, 66.3696777343751],
              [-85.30683593750001, 66.44033203125014],
              [-85.44223632812498, 66.537353515625],
              [-85.60385742187498, 66.56826171875008],
              [-85.79174804687494, 66.532958984375],
              [-86.063232421875, 66.52036132812509],
              [-86.63320312499997, 66.53134765625003],
              [-86.70815429687502, 66.52304687500009],
              [-86.73710937499999, 66.51088867187508],
              [-86.68862304687497, 66.45747070312507],
              [-86.70014648437501, 66.44277343750008],
              [-86.73837890624998, 66.432861328125],
              [-86.74697265624997, 66.41708984374998],
              [-86.68510742187499, 66.36040039062499],
              [-86.58476562499993, 66.32192382812514],
              [-86.30102539062494, 66.26992187500002],
              [-86.11308593749993, 66.22529296875001],
              [-86.00083007812492, 66.18681640624999],
              [-85.96425781250002, 66.15444335937514],
              [-85.95874023437494, 66.11904296875002],
              [-86.01225585937493, 66.04848632812502],
              [-86.04287109375002, 66.02255859375009],
              [-86.70195312499993, 65.67055664062514],
              [-86.95317382812493, 65.52827148437513],
              [-87.08110351562499, 65.44082031250008],
              [-87.19379882812495, 65.383056640625],
              [-87.29145507812495, 65.35483398437498],
              [-87.452880859375, 65.33896484375009],
              [-87.678125, 65.33535156250008],
              [-87.969970703125, 65.34892578124999],
              [-88.12099609375002, 65.39458007812505],
              [-88.39487304687492, 65.51621093749998],
              [-88.58671874999993, 65.58764648437511],
              [-88.67246093749992, 65.61157226562506],
              [-88.74394531249999, 65.67875976562505],
              [-88.80849609375002, 65.691650390625],
              [-88.94614257812498, 65.70302734375002],
              [-89.08774414062498, 65.73896484375007],
              [-89.42036132812495, 65.86079101562501],
              [-89.59267578124997, 65.90932617187514],
              [-89.74941406250002, 65.93603515625006],
              [-89.89047851562498, 65.94082031250014],
              [-89.94399414062497, 65.93359375000011],
              [-89.84775390624998, 65.87226562500001],
              [-89.88969726562493, 65.86855468749997],
              [-90.00380859374997, 65.882568359375],
              [-90.11660156249997, 65.88242187499998],
              [-90.31625976562498, 65.92636718750003],
              [-90.51328124999998, 65.92050781250009],
              [-90.65546874999995, 65.92934570312514],
              [-90.82573242187493, 65.95385742187511],
              [-91.009521484375, 65.96572265625008],
              [-91.30546874999999, 65.96455078125001],
              [-91.41152343749997, 65.959375],
              [-91.42724609375, 65.94790039062497],
              [-91.28515625, 65.89443359375008],
              [-91.04111328124992, 65.82983398437511],
              [-91.07363281249997, 65.88554687500005],
              [-91.06494140625, 65.89990234375],
              [-90.98344726562499, 65.91923828124999],
              [-90.59682617187502, 65.8848144531251],
              [-90.15864257812501, 65.81269531249995],
              [-90.04755859375001, 65.80561523437513],
              [-89.924072265625, 65.78027343750011],
              [-89.78798828124997, 65.73671875000014],
              [-89.60039062499999, 65.6477539062501],
              [-89.24174804687499, 65.44638671874998],
              [-89.12656249999998, 65.39560546875009],
              [-88.97402343749997, 65.34829101562502],
              [-88.19780273437493, 65.27988281249998],
              [-87.92954101562492, 65.28032226562505],
              [-87.39194335937498, 65.2605468750001],
              [-87.10800781249998, 65.22480468750013],
              [-87.02753906250001, 65.19809570312498],
              [-87.002685546875, 65.10859375000007],
              [-87.02851562500001, 65.06362304687514],
              [-87.18291015624993, 64.92680664062499],
              [-87.28051757812494, 64.82617187500006],
              [-87.885009765625, 64.40043945312505],
              [-87.96357421875001, 64.30249023437494],
              [-87.99755859375, 64.2439453125001],
              [-88.10561523437497, 64.18330078125001],
              [-88.37895507812499, 64.08925781250002],
              [-88.65302734374995, 64.009375],
              [-88.81772460937492, 63.99223632812499],
              [-88.96440429687499, 64.01123046874994],
              [-89.05961914062493, 64.03442382812506],
              [-89.20063476562495, 64.11376953125006],
              [-89.20942382812495, 64.10541992187513],
              [-89.10766601562494, 63.98110351562502],
              [-89.13154296875001, 63.96850585937494],
              [-89.21455078124995, 63.984130859375114],
              [-89.40351562500001, 64.03999023437513],
              [-89.46474609375002, 64.02968749999997],
              [-89.50092773437495, 64.01450195312509],
              [-89.55131835937499, 64.01479492187514],
              [-89.61582031249998, 64.03061523437498],
              [-89.73271484374999, 64.07695312500005],
              [-89.76381835937502, 64.09951171875002],
              [-89.79208984374992, 64.16826171874999],
              [-89.81132812499993, 64.18056640625002],
              [-90.04165039062497, 64.14086914062506],
              [-90.08002929687495, 64.12773437500005],
              [-89.985595703125, 64.10019531249998],
              [-89.95356445312495, 64.08061523437505],
              [-89.86059570312494, 63.9788085937501],
              [-89.855712890625, 63.956982421875125],
              [-89.921875, 63.94355468750001],
              [-90.14189453124999, 63.981982421875045],
              [-90.16816406250001, 63.978759765625114],
              [-90.05961914062499, 63.877490234375045],
              [-90.01796875000002, 63.82934570312506],
              [-90.013427734375, 63.80429687500009],
              [-90.15473632812501, 63.68964843750001],
              [-90.24531250000001, 63.641894531250045],
              [-90.36884765625001, 63.62441406250002],
              [-90.44624023437495, 63.636181640625125],
              [-90.53349609374999, 63.66542968750002],
              [-90.59638671875001, 63.66127929687508],
              [-90.635009765625, 63.62377929687506],
              [-90.70683593749993, 63.596923828125114],
              [-90.81191406249997, 63.580908203125034],
              [-90.94565429687492, 63.58784179687501],
              [-91.10805664062497, 63.61782226562508],
              [-91.538818359375, 63.72558593750006],
              [-91.67465820312498, 63.742236328125045],
              [-91.926025390625, 63.75708007812506],
              [-91.95600585937495, 63.77231445312498],
              [-91.95380859374995, 63.78681640625007],
              [-91.91943359375, 63.800585937500045],
              [-91.92890624999995, 63.812451171875125],
              [-91.98222656249999, 63.82241210937502],
              [-92.03759765625, 63.8130371093751],
              [-92.09487304687502, 63.784423828125114],
              [-92.19521484375002, 63.77597656249998],
              [-92.33842773437499, 63.787646484375045],
              [-92.55009765624993, 63.82954101562507],
              [-92.97021484375, 63.93764648437508],
              [-93.4296875, 64.02880859375],
              [-93.69633789062499, 64.14716796875013],
              [-93.59672851562493, 64.0405761718751],
              [-93.60488281249997, 64.00449218750003],
              [-93.65581054687499, 63.972802734375136],
              [-93.66416015624998, 63.941406250000114],
              [-93.55981445312494, 63.865283203125045],
              [-93.41557617187499, 63.837988281250034],
              [-93.27021484375001, 63.840869140625045],
              [-93.26621093749992, 63.8533203125001],
              [-93.32685546875001, 63.87226562500007],
              [-93.38027343749997, 63.90004882812502],
              [-93.40585937499992, 63.9412109375001],
              [-93.37851562499998, 63.94848632812494],
              [-93.25043945312501, 63.92690429687502],
              [-93.16591796875002, 63.90175781250002],
              [-92.52924804687495, 63.76123046875],
              [-92.33920898437498, 63.73491210937499],
              [-92.19648437499995, 63.70781249999999],
              [-92.15688476562497, 63.691699218750045],
              [-92.20502929687501, 63.656787109375045],
              [-92.46103515624992, 63.569433593750034],
              [-92.46508789062494, 63.555078125000136],
              [-92.28955078125, 63.562988281250114],
              [-92.07661132812495, 63.63999023437498],
              [-91.95683593749999, 63.67563476562498],
              [-91.84184570312499, 63.69755859374999],
              [-91.68603515624994, 63.6597167968751],
              [-91.48930664062493, 63.562207031250125],
              [-91.330078125, 63.50683593750006],
              [-91.10307617187493, 63.47587890624999],
              [-90.97006835937492, 63.442773437500136],
              [-90.74658203124994, 63.35156250000006],
              [-90.71127929687498, 63.30405273437498],
              [-90.69072265624993, 63.110546875000125],
              [-90.69858398437498, 63.06386718750002],
              [-90.72763671874992, 63.01748046875002],
              [-90.77788085937493, 62.97163085937507],
              [-90.87119140624998, 62.94594726562502],
              [-91.00771484374997, 62.940429687500114],
              [-91.11489257812502, 62.92158203125001],
              [-91.34946289062498, 62.818896484375045],
              [-91.448974609375, 62.804052734375034],
              [-91.86962890624994, 62.83471679687506],
              [-92.03422851562493, 62.86342773437508],
              [-92.11005859375001, 62.86171875000008],
              [-92.15209960937494, 62.83906250000007],
              [-92.19614257812498, 62.828808593750125],
              [-92.36127929687495, 62.81938476562499],
              [-92.38813476562501, 62.80087890624992],
              [-92.37773437499993, 62.77241210937501],
              [-92.34526367187499, 62.733837890625125],
              [-92.30517578125, 62.71166992187506],
              [-92.24316406249994, 62.683642578125045],
              [-92.14912109374995, 62.665283203125],
              [-91.95585937499993, 62.644775390625114],
              [-91.93583984374993, 62.59238281250009],
              [-91.94443359374992, 62.575488281250045],
              [-92.00786132812499, 62.54052734375],
              [-92.08115234374998, 62.54409179687502],
              [-92.20722656250001, 62.58535156250008],
              [-92.26953125, 62.586962890625045],
              [-92.32407226562492, 62.564599609375136],
              [-92.4, 62.557226562500034],
              [-92.49736328124993, 62.564843749999966],
              [-92.55141601562497, 62.546728515625034],
              [-92.56220703125001, 62.502880859374955],
              [-92.59497070312494, 62.470068359375034],
              [-92.70742187500002, 62.41821289062494],
              [-92.76796875000002, 62.37998046874998],
              [-92.76596679687498, 62.34995117187509],
              [-92.70146484374999, 62.32822265625009],
              [-92.62744140625, 62.27905273437506],
              [-92.54404296874998, 62.20229492187502],
              [-92.52797851562497, 62.16840820312507],
              [-92.57919921874992, 62.17734375000009],
              [-92.64809570312502, 62.20776367187506],
              [-92.73476562500002, 62.25971679687501],
              [-92.86582031249998, 62.306201171875045],
              [-93.15444335937497, 62.366845703125136],
              [-93.20537109374993, 62.364941406250125],
              [-93.17924804687499, 62.34956054687501],
              [-92.98779296874994, 62.28593749999999],
              [-92.91445312500002, 62.244970703125034],
              [-92.905517578125, 62.21513671874999],
              [-93.06586914062495, 62.14975585937498],
              [-93.07026367187495, 62.127832031250136],
              [-93.02773437500002, 62.108642578125114],
              [-93.01625976562497, 62.09267578125008],
              [-93.07338867187497, 62.060546875000114],
              [-93.16748046874994, 62.033642578125125],
              [-93.34975585937497, 62.02978515625006],
              [-93.36635742187497, 62.014550781250136],
              [-93.296875, 61.98159179687502],
              [-93.27343749999994, 61.96108398437508],
              [-93.33305664062493, 61.932910156250045],
              [-93.37202148437495, 61.928955078125114],
              [-93.581787109375, 61.942041015625136],
              [-93.52670898437498, 61.87163085937499],
              [-93.49423828124992, 61.846923828125114],
              [-93.42993164062494, 61.81210937499992],
              [-93.31440429687495, 61.779785156250114],
              [-93.31201171875, 61.76728515625007],
              [-93.35234374999999, 61.7395507812501],
              [-93.42060546875001, 61.705810546875114],
              [-93.70966796875001, 61.60253906250006],
              [-93.91274414062502, 61.48144531250006],
              [-93.94086914062495, 61.44365234374999],
              [-93.88925781249992, 61.36406249999999],
              [-93.88881835937502, 61.34404296874999],
              [-93.94199218749992, 61.30800781250008],
              [-94.06074218749995, 61.317822265625125],
              [-94.08344726562495, 61.30366210937507],
              [-94.05522460937499, 61.2661621093751],
              [-94.04995117187497, 61.21127929687509],
              [-94.06777343749997, 61.13886718750007],
              [-94.15405273437494, 61.0254394531251],
              [-94.30869140624992, 60.87099609375008],
              [-94.42719726562501, 60.73071289062506],
              [-94.509375, 60.60454101562493],
              [-94.56889648437493, 60.5419921875],
              [-94.67875976562493, 60.537695312500034],
              [-94.76171874999994, 60.498242187500125],
              [-94.70527343750001, 60.4775390625],
              [-94.67065429687494, 60.45332031250007],
              [-94.64677734374999, 60.416406249999966],
              [-94.67041015625, 60.3010742187501],
              [-94.74160156250002, 60.10737304687507],
              [-94.78579101562497, 59.953320312499955],
              [-94.77666015624999, 59.478125],
              [-94.78828125000001, 59.26787109374993],
              [-94.81953125000001, 59.15131835937501],
              [-94.87026367187502, 59.08798828125009],
              [-94.95732421874999, 59.06884765625006],
              [-94.84658203125002, 59.050341796875045],
              [-94.77617187499999, 59.020605468750034],
              [-94.74375, 58.975439453125034],
              [-94.71337890624994, 58.903320312500114],
              [-94.67338867187499, 58.870117187500114],
              [-94.62373046875001, 58.875732421875],
              [-94.57919921874998, 58.8684570312501],
              [-94.53969726562502, 58.848388671875114],
              [-94.41923828124999, 58.745507812500136],
              [-94.28706054687495, 58.716015625000125],
              [-94.28081054687493, 58.65893554687506],
              [-94.33261718749992, 58.339111328125114],
              [-94.33222656250001, 58.297363281250114],
              [-94.27216796874995, 58.378027343750034],
              [-94.20893554687495, 58.62636718750002],
              [-94.12319335937497, 58.73671875000008],
              [-94.05576171874992, 58.76000976562506],
              [-93.78002929687494, 58.77255859374992],
              [-93.48618164062498, 58.74448242187509],
              [-93.37504882812499, 58.7410156250001],
              [-93.278125, 58.756396484375045],
              [-93.17875976562493, 58.72563476562499],
              [-93.15458984374999, 58.69458007812506],
              [-93.12651367187499, 58.56440429687501],
              [-93.10019531249992, 58.48984375000009],
              [-92.92514648437498, 58.22451171875002],
              [-92.84174804687495, 58.075878906250125],
              [-92.73984374999998, 57.84404296874993],
              [-92.70166015625, 57.777783203125125],
              [-92.48964843749997, 57.468603515625034],
              [-92.44941406249995, 57.384863281250034],
              [-92.43281249999995, 57.3203125],
              [-92.43979492187498, 57.27504882812502],
              [-92.47836914062498, 57.20527343750001],
              [-92.54848632812497, 57.11093749999998],
              [-92.61411132812492, 57.03901367187507],
              [-92.67524414062495, 56.98955078124993],
              [-92.73798828124995, 56.95263671875006],
              [-92.80239257812502, 56.92832031250009],
              [-92.79814453124993, 56.921972656250034],
              [-92.72529296875001, 56.93354492187507],
              [-92.65097656249998, 56.95830078124999],
              [-92.51030273437493, 57.022314453125034],
              [-92.45629882812494, 57.03671874999998],
              [-92.30336914062497, 57.04584960937507],
              [-92.29829101562501, 57.02275390624999],
              [-92.37211914062493, 56.975146484375045],
              [-92.35571289062494, 56.97060546875002],
              [-92.2490234375, 57.00898437500001],
              [-92.01801757812501, 57.06376953124999],
              [-91.11127929687501, 57.24121093750006],
              [-90.8974609375, 57.25693359375009],
              [-90.59218749999997, 57.224462890625034],
              [-90.34482421874992, 57.149072265625136],
              [-90.07519531249994, 57.05190429687502],
              [-89.79082031249993, 56.98134765625008],
              [-89.34233398437499, 56.915429687500136],
              [-89.21157226562497, 56.8838378906251],
              [-88.94848632812494, 56.85131835937506],
              [-88.82646484374993, 56.81425781249993],
              [-88.67988281250001, 56.72504882812507],
              [-88.44707031249993, 56.60869140624999],
              [-88.27133789062492, 56.535693359375045],
              [-88.07509765624997, 56.46728515624994],
              [-87.878125, 56.3416015625001],
              [-87.56088867187498, 56.05634765625007],
              [-87.48242187499994, 56.021289062500045],
              [-87.286865234375, 55.97465820312499],
              [-86.91938476562501, 55.91455078125],
              [-86.376953125, 55.77324218749999],
              [-86.138671875, 55.717871093750034],
              [-85.98447265624998, 55.695898437500034],
              [-85.83051757812495, 55.65693359375007],
              [-85.67666015625002, 55.60107421875006],
              [-85.55932617187494, 55.54018554687508],
              [-85.47846679687501, 55.474267578124966],
              [-85.40727539062499, 55.43115234375006],
              [-85.28271484375, 55.383300781250114],
              [-85.21801757812494, 55.348974609375034],
              [-85.21201171874998, 55.297460937500034],
              [-85.36201171874995, 55.09545898437506],
              [-85.36528320312493, 55.07929687500001],
              [-85.21357421875001, 55.22436523437506],
              [-85.12885742187501, 55.266210937500034],
              [-85.06093750000002, 55.285644531250114],
              [-84.919921875, 55.283349609374966],
              [-84.70576171874995, 55.25922851562507],
              [-84.51796874999997, 55.25888671874998],
              [-84.35649414062493, 55.28251953124999],
              [-84.21894531250001, 55.29311523437502],
              [-84.10537109375002, 55.2908203125001],
              [-84.02299804687493, 55.29780273437507],
              [-83.97177734374998, 55.31416015625007],
              [-83.91059570312495, 55.314648437499955],
              [-83.66767578125001, 55.264501953125034],
              [-83.56948242187502, 55.261816406250034],
              [-83.21435546874994, 55.214599609375],
              [-82.98627929687495, 55.23139648437507],
              [-82.94702148437494, 55.22221679687499],
              [-82.86777343749998, 55.160693359375045],
              [-82.80068359374997, 55.155908203125136],
              [-82.6875, 55.16552734374994],
              [-82.57744140624999, 55.1487304687501],
              [-82.39326171875001, 55.067822265625125],
              [-82.30825195312502, 54.99814453124998],
              [-82.22661132812499, 54.855908203125125],
              [-82.21938476562502, 54.8134765625],
              [-82.37060546875, 54.48349609375009],
              [-82.41806640624992, 54.355810546875034],
              [-82.42416992187492, 54.244580078125125],
              [-82.39414062499992, 54.18046874999999],
              [-82.26357421874997, 54.07299804687494],
              [-82.23989257812502, 54.04482421875008],
              [-82.16264648437493, 53.88569335937501],
              [-82.14145507812498, 53.817626953125],
              [-82.15, 53.739550781250045],
              [-82.190625, 53.61093749999998],
              [-82.18037109375001, 53.51284179687508],
              [-82.14619140624995, 53.36459960937509],
              [-82.1591796875, 53.26416015625006],
              [-82.21923828125, 53.21147460937493],
              [-82.25991210937502, 53.15981445312508],
              [-82.29160156249998, 53.06611328125001],
              [-82.29155273437499, 53.03071289062507],
              [-82.26044921874995, 52.961132812500125],
              [-82.20268554687499, 52.921679687500045],
              [-82.10795898437499, 52.877392578125125],
              [-82.02001953125, 52.811621093750034],
              [-81.85927734375002, 52.6514160156251],
              [-81.74233398437502, 52.563623046875136],
              [-81.59941406249993, 52.432617187500114],
              [-81.57167968749997, 52.36728515625009],
              [-81.61152343750001, 52.32407226562498],
              [-81.66123046874992, 52.29389648437507],
              [-81.7763671875, 52.25361328125001],
              [-81.82788085937494, 52.22421875000009],
              [-81.81455078124998, 52.21718750000008],
              [-81.64799804687499, 52.2390625000001],
              [-81.54951171874995, 52.236767578124955],
              [-81.46621093749997, 52.204492187500136],
              [-81.39809570312502, 52.14223632812508],
              [-81.28505859375002, 52.08920898437492],
              [-81.127197265625, 52.04541015625006],
              [-80.968505859375, 51.9722167968751],
              [-80.70551757812501, 51.79833984375],
              [-80.657958984375, 51.758349609375045],
              [-80.58803710937502, 51.667236328125114],
              [-80.495849609375, 51.525097656250125],
              [-80.44760742187492, 51.432226562500034],
              [-80.44331054687495, 51.38857421875002],
              [-80.49550781250002, 51.344677734375125],
              [-80.67270507812498, 51.264746093750034],
              [-80.85122070312502, 51.125],
              [-80.79497070312499, 51.131835937500114],
              [-80.67724609375, 51.19086914062507],
              [-80.47832031249999, 51.30732421875001],
              [-80.36796874999999, 51.32988281249999],
              [-80.26567382812499, 51.31635742187507],
              [-80.10356445312493, 51.282861328125136],
              [-79.96040039062495, 51.235156250000045],
              [-79.83623046874999, 51.17333984375006],
              [-79.65151367187502, 51.00781250000006],
              [-79.45615234374992, 50.87558593749998],
              [-79.347900390625, 50.76264648437507],
              [-79.38071289062492, 50.8345214843751],
              [-79.45263671875, 50.9172851562501],
              [-79.63618164062501, 51.049023437500125],
              [-79.71445312499998, 51.11757812500008],
              [-79.73139648437495, 51.150488281250034],
              [-79.73745117187502, 51.186279296875],
              [-79.72324218749998, 51.25166015625001],
              [-79.68881835937498, 51.346582031249966],
              [-79.64296874999997, 51.413525390625125],
              [-79.58574218749993, 51.452441406250045],
              [-79.54746093749998, 51.493847656249955],
              [-79.52802734375001, 51.537695312500034],
              [-79.49755859375, 51.569921875000034],
              [-79.33867187499999, 51.628173828125],
              [-79.29697265624992, 51.622802734375114],
              [-79.26425781249998, 51.552001953125114],
              [-79.22612304687493, 51.537304687500125],
              [-79.15273437500002, 51.52622070312498],
              [-79.09086914062493, 51.501708984375],
              [-79.04052734375, 51.46376953125008],
              [-79.00502929687502, 51.425341796875045],
              [-78.98432617187501, 51.38637695312508],
              [-78.93691406250002, 51.25913085937492],
              [-78.90317382812498, 51.200292968750034],
              [-78.89750976562499, 51.27167968750001],
              [-78.85800781249992, 51.383935546875136],
              [-78.82744140624999, 51.4299804687501],
              [-78.73134765624997, 51.49746093750002],
              [-78.73642578124992, 51.52661132812506],
              [-78.77631835937495, 51.565771484375034],
              [-78.97773437499995, 51.73378906250002],
              [-78.98164062499995, 51.774560546875136],
              [-78.92788085937502, 51.798828125000114],
              [-78.89111328125, 51.845117187500136],
              [-78.87124023437502, 51.913427734375034],
              [-78.82822265624998, 51.96298828124998],
              [-78.70200195312492, 52.03271484375],
              [-78.59331054687499, 52.1396972656251],
              [-78.53735351562494, 52.213281249999966],
              [-78.49165039062495, 52.25209960937508],
              [-78.44809570312498, 52.26137695312502],
              [-78.51308593750002, 52.291113281250034],
              [-78.52607421874995, 52.310693359375136],
              [-78.52910156249999, 52.399169921875],
              [-78.55708007812501, 52.49189453125007],
              [-78.60058593749994, 52.53510742187501],
              [-78.72377929687502, 52.627734375000045],
              [-78.744140625, 52.65537109374998],
              [-78.76577148437497, 52.76005859374999],
              [-78.75361328124995, 52.81240234375002],
              [-78.72167968749994, 52.856445312500114],
              [-78.73984374999992, 52.898974609375045],
              [-78.85410156249992, 52.97607421874994],
              [-78.89824218749993, 53.043359374999966],
              [-78.94711914062498, 53.206201171875136],
              [-78.99204101562498, 53.410351562499955],
              [-79.04311523437497, 53.56049804687501],
              [-79.100341796875, 53.65664062500008],
              [-79.11313476562492, 53.717187500000136],
              [-79.08144531249997, 53.74228515625009],
              [-79.04033203124993, 53.817968750000034],
              [-79.003173828125, 53.836572265625136],
              [-78.94570312500002, 53.83159179687499],
              [-78.94438476562493, 53.840234375000136],
              [-79.03203124999993, 53.881054687500125],
              [-79.07514648437501, 53.932373046875114],
              [-79.07329101562499, 53.95141601562506],
              [-78.99604492187495, 54.00249023437499],
              [-79.00991210937497, 54.0239746093751],
              [-79.067138671875, 54.051953125000125],
              [-79.24179687499998, 54.098876953125114],
              [-79.17880859374998, 54.11694335937506],
              [-79.13881835937502, 54.15722656250006],
              [-79.14672851562494, 54.169238281250045],
              [-79.21596679687502, 54.18569335937502],
              [-79.295654296875, 54.21684570312499],
              [-79.35615234374995, 54.26337890625001],
              [-79.43056640625002, 54.33662109375001],
              [-79.47597656249997, 54.394775390625],
              [-79.52065429687502, 54.49155273437498],
              [-79.59794921874993, 54.601660156250034],
              [-79.63173828124997, 54.629101562500125],
              [-79.67041015624994, 54.64682617187498],
              [-79.71396484374998, 54.65498046875007],
              [-79.71235351562495, 54.671826171875125],
              [-79.66552734374994, 54.69746093750001],
              [-78.90922851562499, 54.88149414062502],
              [-78.84624023437493, 54.9080078125001],
              [-78.47504882812495, 55.0110351562501],
              [-78.30361328125002, 55.068554687500125],
              [-78.12885742187495, 55.15131835937507],
              [-77.89111328124994, 55.236425781250034],
              [-77.77529296874997, 55.291259765625],
              [-77.7021484375, 55.344189453125125],
              [-77.32495117187494, 55.555517578125034],
              [-77.16508789062499, 55.66352539062501],
              [-77.07255859374999, 55.75629882812507],
              [-76.93808593749992, 55.8672363281251],
              [-76.76181640624998, 55.99643554687498],
              [-76.65048828124998, 56.10722656249999],
              [-76.60405273437499, 56.19956054687498],
              [-76.54638671875, 56.35878906250008],
              [-76.52983398437499, 56.49995117187507],
              [-76.51962890624998, 56.706982421875125],
              [-76.52558593750001, 56.891796875000125],
              [-76.57285156249998, 57.1812011718751],
              [-76.60141601562492, 57.27226562499999],
              [-76.65541992187497, 57.38056640625001],
              [-76.78627929687502, 57.59858398437507],
              [-76.809814453125, 57.65795898437506],
              [-76.89091796874999, 57.758105468750045],
              [-77.15678710937499, 58.018896484375034],
              [-77.48916015625002, 58.195312500000114],
              [-77.55244140624995, 58.23959960937509],
              [-77.68408203124994, 58.29135742187498],
              [-77.88413085937492, 58.350732421875136],
              [-78.01357421875002, 58.39916992187506],
              [-78.35170898437497, 58.58066406250009],
              [-78.46298828124992, 58.60244140625008],
              [-78.50590820312493, 58.64912109375001],
              [-78.51508789062495, 58.68237304687506],
              [-78.50229492187492, 58.7690917968751],
              [-78.48261718750001, 58.829101562500114],
              [-78.45869140625001, 58.87329101562506],
              [-78.43051757812498, 58.901757812500136],
              [-78.24443359374999, 59.035058593750136],
              [-78.14023437499998, 59.14174804687502],
              [-78.06767578124993, 59.2001953125],
              [-77.98764648437498, 59.24550781250002],
              [-77.84282226562493, 59.305029296875034],
              [-77.76069335937501, 59.38002929687508],
              [-77.77944335937502, 59.41040039062506],
              [-77.84467773437495, 59.44350585937502],
              [-77.85903320312497, 59.47578125000001],
              [-77.74902343749994, 59.5581542968751],
              [-77.73349609374998, 59.580957031250136],
              [-77.74750976562501, 59.65849609374993],
              [-77.72617187499998, 59.67587890624992],
              [-77.59042968749998, 59.68051757812498],
              [-77.39667968750001, 59.56923828125008],
              [-77.34907226562501, 59.57895507812509],
              [-77.41103515625002, 59.609619140625114],
              [-77.48530273437495, 59.684570312500114],
              [-77.47456054687501, 59.715673828125034],
              [-77.33164062499992, 59.796630859375],
              [-77.32763671875, 59.83339843750008],
              [-77.36840820312494, 59.884375],
              [-77.37294921874997, 59.9250976562501],
              [-77.28920898437497, 60.0220214843751],
              [-77.31181640624993, 60.04238281250002],
              [-77.54716796874993, 60.06113281250009],
              [-77.58588867187501, 60.0881835937501],
              [-77.57216796874997, 60.10097656250002],
              [-77.46137695312495, 60.13349609375007],
              [-77.452880859375, 60.1458007812501],
              [-77.64863281250001, 60.3625],
              [-77.68144531249993, 60.427099609375034],
              [-77.59814453124994, 60.50673828125008],
              [-77.50356445312502, 60.54272460937494],
              [-77.51557617187501, 60.56318359375007],
              [-77.63945312499999, 60.566894531250114],
              [-77.71499023437502, 60.57778320312502],
              [-77.79082031249993, 60.63984375000001],
              [-77.76123046874994, 60.679052734375034],
              [-77.73422851562498, 60.696972656250125],
              [-77.66064453124994, 60.789501953124955],
              [-77.58955078124995, 60.808593750000114],
              [-77.60302734374994, 60.82519531249994],
              [-77.87153320312495, 60.785839843750125],
              [-77.99814453124998, 60.81821289062498],
              [-78.12246093750002, 60.80961914062499],
              [-78.18134765625001, 60.81914062499999],
              [-78.15966796874994, 60.852197265625136],
              [-77.93417968749992, 61.00263671875007],
              [-77.83012695312493, 61.0840332031251],
              [-77.76503906250002, 61.15751953124999],
              [-77.73061523437502, 61.20639648437498],
              [-77.72680664062494, 61.230664062500125],
              [-77.74960937499998, 61.39301757812501],
              [-77.73618164062498, 61.43735351562498],
              [-77.64887695312495, 61.47866210937502],
              [-77.51435546875001, 61.55629882812502],
              [-77.69843750000001, 61.626416015625125],
              [-77.81376953124999, 61.69477539062501],
              [-77.88989257812494, 61.72871093750001],
              [-77.94755859374999, 61.76186523437502],
              [-78.02138671875002, 61.8320800781251],
              [-78.07749023437498, 61.923388671875045],
              [-78.13715820312495, 62.10737304687507],
              [-78.14697265625, 62.20869140625007],
              [-78.13339843749992, 62.28227539062499],
              [-78.10859375000001, 62.318115234375],
              [-78.06811523437494, 62.355419921875125],
              [-77.89990234374994, 62.426562500000045],
              [-77.60395507812495, 62.53139648437508],
              [-77.37241210937498, 62.572509765625114],
              [-77.20527343750001, 62.549951171875136],
              [-76.87939453124994, 62.525390625000114],
              [-76.61635742187502, 62.465673828125034],
              [-75.81689453124994, 62.31586914062507],
              [-75.67553710937494, 62.24951171875006],
              [-75.80922851562502, 62.19340820312499],
              [-75.78984374999993, 62.17958984375002],
              [-75.48881835937499, 62.2864257812501],
              [-75.40922851562493, 62.30708007812507],
              [-75.34121093750002, 62.312109375000034],
              [-75.11401367187494, 62.270751953125114],
              [-75.02275390624999, 62.2644531250001],
              [-74.90756835937498, 62.23002929687499],
              [-74.632568359375, 62.115673828125125],
              [-74.61289062499998, 62.125195312500125],
              [-74.68989257812495, 62.18344726562509],
              [-74.64580078124993, 62.21113281250007],
              [-74.42919921874994, 62.27182617187498],
              [-74.20546875000002, 62.32138671874998],
              [-74.04648437499998, 62.37001953125008],
              [-73.87783203124997, 62.434375],
              [-73.76396484374999, 62.468750000000114],
              [-73.70507812499994, 62.47314453124994],
              [-73.62998046874992, 62.454199218750034],
              [-73.42836914062497, 62.36884765625001],
              [-73.29897460937502, 62.32504882812498],
              [-73.19516601562498, 62.279150390625034],
              [-73.04936523437493, 62.198242187500114],
              [-72.99238281249995, 62.180419921875],
              [-72.8818359375, 62.125390624999966],
              [-72.73496093749992, 62.131103515625114],
              [-72.68696289062501, 62.12456054687499],
              [-72.67080078125002, 62.11386718749998],
              [-72.64599609374994, 62.07661132812501],
              [-72.63310546874999, 62.0527832031251],
              [-72.63212890624993, 62.02724609375008],
              [-72.666015625, 61.955322265625],
              [-72.77163085937502, 61.840429687500034],
              [-72.72739257812498, 61.838623046875],
              [-72.66064453125, 61.86323242187507],
              [-72.57387695312497, 61.907128906250136],
              [-72.50556640625001, 61.9226562500001],
              [-72.36074218750002, 61.88779296875009],
              [-72.22612304687493, 61.83159179687499],
              [-72.178466796875, 61.80180664062499],
              [-72.12612304687497, 61.753222656250045],
              [-72.08144531250002, 61.728271484375114],
              [-72.04003906249994, 61.68027343750009],
              [-72.04296875, 61.66469726562508],
              [-72.08203124999994, 61.64140624999999],
              [-72.2470703125, 61.602050781250114],
              [-72.21586914062499, 61.58725585937498],
              [-72.02309570312502, 61.61196289062502],
              [-71.96440429687499, 61.63627929687499],
              [-71.92226562500002, 61.676953125000125],
              [-71.86611328125002, 61.68852539062499],
              [-71.63828124999998, 61.6171875],
              [-71.60478515624999, 61.59238281249992],
              [-71.61943359374999, 61.57290039062508],
              [-71.65620117187501, 61.55092773437502],
              [-71.75576171875002, 61.52675781250008],
              [-71.84101562500001, 61.466015625000125],
              [-71.85439453124997, 61.43979492187492],
              [-71.79365234375001, 61.421191406250045],
              [-71.64531249999999, 61.41313476562499],
              [-71.64643554687495, 61.3987304687501],
              [-71.73212890625001, 61.3720703125],
              [-71.74345703124999, 61.337255859375034],
              [-71.551513671875, 61.213281250000136],
              [-71.42270507812492, 61.158935546875114],
              [-71.34843749999999, 61.148974609375045],
              [-71.17514648437498, 61.146533203125045],
              [-71.03496093749999, 61.125537109375045],
              [-70.72324218749998, 61.055175781250114],
              [-70.54077148437494, 61.04248046875],
              [-70.38364257812498, 61.063964843750114],
              [-70.27929687499994, 61.06865234374999],
              [-70.18720703124995, 61.040527343750114],
              [-70.15795898437494, 61.020654296874966],
              [-70.14414062499998, 60.98110351562508],
              [-70.14589843749997, 60.921826171875125],
              [-70.09531249999998, 60.880273437499966],
              [-69.99243164062494, 60.8564941406251],
              [-69.90922851562499, 60.86010742187494],
              [-69.80043945312502, 60.90668945312507],
              [-69.70839843750002, 60.91464843750009],
              [-69.67758789062498, 60.94956054687509],
              [-69.65043945312493, 61.014160156250114],
              [-69.62363281249998, 61.04951171875001],
              [-69.55698242187498, 61.05966796874992],
              [-69.50332031249997, 61.040429687500136],
              [-69.471923828125, 61.010937500000125],
              [-69.41435546874999, 60.922460937500034],
              [-69.39833984374997, 60.88286132812499],
              [-69.40473632812495, 60.84677734375009],
              [-69.43344726562498, 60.814257812500045],
              [-69.48994140625001, 60.7795898437501],
              [-69.57421874999994, 60.742724609375045],
              [-69.64047851562492, 60.68979492187509],
              [-69.72138671875001, 60.567431640625045],
              [-69.75126953124993, 60.48745117187508],
              [-69.75947265625001, 60.440234375000045],
              [-69.75590820312499, 60.38852539062498],
              [-69.74057617187498, 60.33227539062506],
              [-69.70849609374994, 60.285937500000045],
              [-69.63310546874993, 60.22036132812502],
              [-69.62875976562498, 60.198583984375034],
              [-69.62314453124998, 60.14545898437501],
              [-69.62978515624997, 60.1221191406251],
              [-69.67373046874997, 60.07587890625007],
              [-69.79565429687494, 60.02973632812507],
              [-69.96284179687495, 60.01782226562494],
              [-70.50932617187493, 60.01518554687499],
              [-70.65483398437499, 60.02622070312509],
              [-70.61972656249998, 59.98427734375008],
              [-70.46665039062492, 59.970849609374966],
              [-70.32685546874995, 59.971386718750125],
              [-69.8056640625, 59.944873046875045],
              [-69.73393554687499, 59.918017578125045],
              [-69.67343749999998, 59.87075195312502],
              [-69.63022460937498, 59.82182617187499],
              [-69.58740234374994, 59.722314453124966],
              [-69.57939453124993, 59.67509765624993],
              [-69.60234374999999, 59.622705078125136],
              [-69.65620117187495, 59.56508789062508],
              [-69.69238281249994, 59.488427734375136],
              [-69.71088867187501, 59.392529296875125],
              [-69.68188476562494, 59.34174804687507],
              [-69.4, 59.337792968749966],
              [-69.34404296874993, 59.303076171875034],
              [-69.35043945312492, 59.27719726562509],
              [-69.45048828124993, 59.18002929687498],
              [-69.45976562499999, 59.152441406250034],
              [-69.41411132812499, 59.08686523437501],
              [-69.42031250000002, 59.06821289062498],
              [-69.44809570312492, 59.04916992187498],
              [-69.47465820312499, 59.000000000000114],
              [-69.50009765624992, 58.920654296875114],
              [-69.53164062499997, 58.86923828125009],
              [-69.60820312499993, 58.829492187499966],
              [-69.64838867187495, 58.82080078125],
              [-69.67734374999998, 58.83134765624999],
              [-69.75302734374998, 58.93959960937502],
              [-69.7841796875, 58.955712890625136],
              [-69.81367187499995, 58.945556640625],
              [-69.82851562499997, 58.92875976562499],
              [-69.82861328125, 58.90537109374998],
              [-69.84160156249999, 58.881152343750045],
              [-69.86757812500002, 58.85615234375007],
              [-69.97915039062502, 58.816357421874955],
              [-70.15996093749999, 58.78940429687498],
              [-70.15434570312499, 58.76059570312498],
              [-70.03300781249999, 58.745166015625045],
              [-69.87856445312502, 58.69697265625001],
              [-69.78989257812492, 58.689306640625034],
              [-69.65053710937502, 58.728271484375],
              [-69.3818359375, 58.85073242187508],
              [-69.27109374999992, 58.88393554687508],
              [-69.17348632812502, 58.896630859374966],
              [-69.06362304687502, 58.89824218749999],
              [-68.94150390624992, 58.888916015625],
              [-68.69819335937501, 58.904541015625],
              [-68.63730468749992, 58.8928710937501],
              [-68.56289062499997, 58.865917968750125],
              [-68.47490234374999, 58.823486328125],
              [-68.414306640625, 58.78271484375006],
              [-68.38115234374999, 58.743505859375034],
              [-68.32646484374993, 58.59541015625007],
              [-68.25297851562499, 58.556640625],
              [-68.23515624999999, 58.52817382812509],
              [-68.22939453124997, 58.48457031250007],
              [-68.23388671875, 58.3992187500001],
              [-68.31469726562494, 58.22690429687498],
              [-68.35654296874992, 58.163232421875136],
              [-68.46816406250002, 58.07631835937502],
              [-68.596875, 58.036865234375],
              [-68.82578124999998, 57.99985351562509],
              [-68.9453125, 57.96879882812499],
              [-69.03544921874993, 57.92602539062494],
              [-69.04082031249999, 57.902490234375136],
              [-68.78095703124995, 57.975830078125],
              [-68.49506835937501, 58.01166992187501],
              [-68.41357421875, 58.0517578125],
              [-68.35185546874993, 58.090722656250136],
              [-68.28911132812499, 58.17768554687507],
              [-68.175537109375, 58.40258789062506],
              [-68.11103515625001, 58.47333984375007],
              [-68.02104492187499, 58.48530273437501],
              [-67.98115234375001, 58.4612304687501],
              [-67.88779296874992, 58.329394531250045],
              [-67.88828124999992, 58.29575195312498],
              [-67.91142578124993, 58.26723632812502],
              [-68.06386718750002, 58.13896484374999],
              [-68.00898437500001, 58.15205078125001],
              [-67.85585937499997, 58.27260742187502],
              [-67.82333984374992, 58.31025390625007],
              [-67.80522460937499, 58.36547851562494],
              [-67.75595703124998, 58.4045898437501],
              [-67.73706054687494, 58.385449218750125],
              [-67.689697265625, 58.24379882812502],
              [-67.68818359375001, 58.14023437500009],
              [-67.68056640624997, 58.10703125000009],
              [-67.69765625000002, 58.008740234375125],
              [-67.67827148437493, 57.99111328125008],
              [-67.63222656249997, 58.07612304687501],
              [-67.61718749999994, 58.140332031250125],
              [-67.59633789062497, 58.186132812500034],
              [-67.56962890624993, 58.21347656250009],
              [-67.38198242187497, 58.300000000000125],
              [-67.162841796875, 58.37036132812506],
              [-67.01943359375002, 58.432910156250045],
              [-66.900390625, 58.46279296875008],
              [-66.72216796874994, 58.49101562499993],
              [-66.60791015624994, 58.548925781250034],
              [-66.55771484374992, 58.636621093750136],
              [-66.51503906250002, 58.697314453125045],
              [-66.47998046875, 58.730908203124955],
              [-66.36240234374992, 58.79116210937502],
              [-66.29853515624995, 58.79453124999998],
              [-66.23740234374998, 58.772265625000045],
              [-66.168212890625, 58.7270996093751],
              [-66.09091796874998, 58.65903320312509],
              [-66.04462890625001, 58.60561523437502],
              [-66.029541015625, 58.566796875000136],
              [-66.01704101562501, 58.430810546875136],
              [-66.00239257812495, 58.431201171875045],
              [-65.93125, 58.53505859375002],
              [-65.92290039062499, 58.571972656250125],
              [-65.92792968749995, 58.610937500000034],
              [-65.94965820312495, 58.64985351562501],
              [-66.02128906249999, 58.73476562500008],
              [-66.04936523437493, 58.787890625000045],
              [-66.04306640624998, 58.82065429687498],
              [-65.96704101562494, 58.839208984375034],
              [-65.85483398437498, 58.846630859375125],
              [-65.83593749999994, 58.86049804687508],
              [-65.91840820312495, 58.89560546874998],
              [-65.92070312499999, 58.91464843750009],
              [-65.84140624999992, 58.97705078125006],
              [-65.79482421875002, 58.98046875],
              [-65.70356445312501, 58.970605468750136],
              [-65.721923828125, 59.00258789062508],
              [-65.72099609374999, 59.02377929687498],
              [-65.69526367187501, 59.032031250000045],
              [-65.54399414062499, 59.01186523437502],
              [-65.52631835937495, 59.036230468750034],
              [-65.396240234375, 59.03842773437509],
              [-65.383544921875, 59.06020507812508],
              [-65.49599609374997, 59.09130859375006],
              [-65.60625, 59.11074218750008],
              [-65.63984375000001, 59.12773437499999],
              [-65.665625, 59.152783203125125],
              [-65.7, 59.21333007812501],
              [-65.69169921874999, 59.22939453125008],
              [-65.66074218749998, 59.22968750000001],
              [-65.60712890624995, 59.213134765625],
              [-65.57802734375002, 59.24497070312509],
              [-65.54531250000002, 59.31972656250002],
              [-65.51279296874992, 59.350390625000045],
              [-65.41171874999998, 59.31499023437493],
              [-65.40727539062493, 59.33022460937502],
              [-65.48935546874998, 59.44775390625],
              [-65.47509765625, 59.47031249999998],
              [-65.34970703124992, 59.4788085937501],
              [-65.27377929687492, 59.46416015624999],
              [-65.07431640624998, 59.378027343750034],
              [-65.03823242187497, 59.38789062500007],
              [-65.06884765625, 59.41147460937509],
              [-65.17094726562493, 59.46225585937492],
              [-65.26318359375, 59.49545898437498],
              [-65.34550781249999, 59.5110351562501],
              [-65.40742187499995, 59.53935546874999],
              [-65.47519531249992, 59.61679687499998],
              [-65.48647460937502, 59.64868164062506],
              [-65.48085937500002, 59.690234375000045],
              [-65.43339843749993, 59.77651367187502],
              [-65.40615234374997, 59.7952148437501],
              [-65.35791015625, 59.80908203125006],
              [-65.28876953124995, 59.818066406250125],
              [-65.21225585937492, 59.80952148437501],
              [-65.05449218749993, 59.75278320312498],
              [-65.02817382812498, 59.77070312500007],
              [-65.11328125, 59.80161132812498],
              [-65.15922851562493, 59.8301269531251],
              [-65.18139648437494, 59.86665039062507],
              [-65.17172851562492, 59.90800781249999],
              [-65.10488281249997, 59.99340820312494],
              [-65.07338867187497, 60.062207031250125],
              [-64.93125, 60.252001953125045],
              [-64.88955078124997, 60.28652343750002],
              [-64.84501953124999, 60.30830078125001],
              [-64.81733398437501, 60.3310546875],
              [-64.70585937499993, 60.336132812499955],
              [-64.49941406250002, 60.26826171875001],
              [-64.43632812499993, 60.228125],
              [-64.41958007812497, 60.17138671875],
              [-64.52773437499997, 60.09453124999999],
              [-64.71328124999997, 60.03715820312499],
              [-64.76845703125002, 60.01210937500002],
              [-64.73256835937497, 59.997558593750114],
              [-64.55917968749992, 60.04340820312501],
              [-64.40771484374994, 60.06479492187492],
              [-64.28349609374999, 60.06406249999998],
              [-64.182861328125, 59.972949218750045],
              [-64.16879882812498, 59.84653320312509],
              [-64.22631835937494, 59.741210937500114],
              [-64.15068359374999, 59.793603515625136],
              [-64.05605468750002, 59.82255859374999],
              [-63.978710937499955, 59.75371093749999],
              [-63.96948242187494, 59.69760742187509],
              [-63.92880859374992, 59.64492187500002],
              [-63.84125976562501, 59.57441406250007],
              [-63.750195312499955, 59.51259765625008],
              [-63.85039062499993, 59.44780273437499],
              [-63.970703125, 59.409082031250136],
              [-63.94545898437502, 59.3801757812501],
              [-63.78085937499998, 59.34926757812502],
              [-63.75859374999999, 59.31865234374999],
              [-63.77587890624994, 59.277148437500045],
              [-63.75200195312499, 59.277343750000114],
              [-63.6375, 59.34145507812502],
              [-63.53989257812492, 59.332861328125034],
              [-63.41513671874998, 59.1943847656251],
              [-63.50620117187498, 59.115185546875125],
              [-63.64550781249994, 59.07890624999999],
              [-63.75639648437499, 59.06347656250006],
              [-63.91049804687492, 59.065576171875136],
              [-63.97114257812501, 59.053808593750034],
              [-63.94101562499992, 59.02739257812499],
              [-63.79365234375001, 59.02700195312508],
              [-63.56787109375, 59.04702148437508],
              [-63.39897460937499, 59.07963867187499],
              [-63.32553710937492, 59.08159179687499],
              [-63.248437499999966, 59.068310546874955],
              [-63.22250976562492, 59.05717773437499],
              [-63.30371093749994, 59.034423828125],
              [-63.30986328124993, 59.02646484374998],
              [-63.279443359374966, 59.003173828125],
              [-63.21640624999992, 58.927978515625],
              [-63.221923828125, 58.91103515625008],
              [-63.282128906249966, 58.86738281250007],
              [-63.18535156249999, 58.85776367187509],
              [-63.05029296875, 58.87817382812494],
              [-63.00834960937499, 58.855419921875125],
              [-62.92607421874999, 58.76503906250002],
              [-62.87387695312492, 58.67246093749998],
              [-63.10234374999993, 58.54575195312509],
              [-63.21865234375002, 58.51953125000006],
              [-63.38994140624999, 58.452539062500136],
              [-63.43793945312501, 58.39882812500002],
              [-63.537060546874955, 58.32993164062498],
              [-63.4736328125, 58.33066406249998],
              [-63.29648437499998, 58.4412109375001],
              [-63.20996093749994, 58.46694335937502],
              [-63.14550781249994, 58.460449218750114],
              [-63.119531249999966, 58.441748046875034],
              [-63.13212890624999, 58.410839843750125],
              [-63.07568359374994, 58.41479492187506],
              [-62.83740234375, 58.47939453125008],
              [-62.7373046875, 58.4921875],
              [-62.60786132812501, 58.49638671874999],
              [-62.59384765624992, 58.47402343750002],
              [-62.67431640624994, 58.31918945312492],
              [-62.81206054687499, 58.20039062500007],
              [-63.06279296874999, 58.12709960937508],
              [-63.15166015624993, 58.08417968750001],
              [-63.26152343749993, 58.0146972656251],
              [-63.22001953124993, 58.00214843750001],
              [-62.98090820312501, 58.0933105468751],
              [-62.81752929687502, 58.12924804687498],
              [-62.58808593750001, 58.158105468749966],
              [-62.48623046875002, 58.15405273437506],
              [-62.3056640625, 57.97226562499998],
              [-62.20151367187492, 57.9546386718751],
              [-62.11743164062494, 57.96411132812506],
              [-61.95864257812502, 57.91176757812508],
              [-61.89907226562502, 57.861328125000114],
              [-61.9140625, 57.82504882812498],
              [-61.9677734375, 57.803320312500034],
              [-61.99492187499993, 57.76943359375002],
              [-61.93125, 57.66855468750009],
              [-61.967968749999955, 57.61191406250009],
              [-62.08398437499994, 57.56191406250002],
              [-62.166894531249966, 57.53657226562501],
              [-62.253613281249955, 57.52875976562501],
              [-62.338574218749955, 57.48452148437508],
              [-62.37714843750001, 57.47797851562501],
              [-62.495556640624955, 57.489208984375125],
              [-62.454980468749966, 57.4619628906251],
              [-62.39648437499994, 57.448193359375125],
              [-62.30322265624994, 57.440673828125],
              [-62.19423828125002, 57.45458984375],
              [-62.08808593750001, 57.45283203125001],
              [-61.921142578125, 57.42080078125008],
              [-61.85107421874994, 57.38129882812501],
              [-61.849804687499955, 57.3704101562501],
              [-61.88583984374992, 57.347851562500125],
              [-61.938867187499966, 57.27436523437507],
              [-61.97744140625002, 57.24794921875002],
              [-61.944531249999955, 57.228125],
              [-61.86083984375, 57.197558593750045],
              [-61.79833984374994, 57.18623046875007],
              [-61.71630859375, 57.196191406250136],
              [-61.62851562499998, 57.18315429687499],
              [-61.33374023437494, 57.01059570312498],
              [-61.34575195312493, 56.921582031250125],
              [-61.39047851562498, 56.85297851562501],
              [-61.37280273437494, 56.775830078125125],
              [-61.37163085937499, 56.680810546875136],
              [-61.531689453124955, 56.6545898437501],
              [-62.0625, 56.69907226562509],
              [-62.366113281249966, 56.76699218750008],
              [-62.381738281249966, 56.787695312500034],
              [-62.29580078125002, 56.83281249999999],
              [-62.37202148437501, 56.836181640625],
              [-62.46020507812494, 56.81845703125009],
              [-62.497265624999955, 56.80170898437507],
              [-62.3955078125, 56.730029296875045],
              [-62.11650390624993, 56.66684570312492],
              [-61.991601562499966, 56.59082031250006],
              [-61.85493164062501, 56.58427734374999],
              [-61.813378906249966, 56.57050781250001],
              [-61.73774414062501, 56.52602539062502],
              [-61.76005859374993, 56.510742187500114],
              [-61.8994140625, 56.5054199218751],
              [-62.00966796875002, 56.45385742187506],
              [-61.94042968749994, 56.423583984375114],
              [-61.69248046874998, 56.397070312500034],
              [-61.514599609374955, 56.39033203125007],
              [-61.42529296874994, 56.3606445312501],
              [-61.49868164062502, 56.327587890625125],
              [-61.70712890624992, 56.28872070312502],
              [-61.713085937499955, 56.230957031250114],
              [-61.55859374999994, 56.207812500000045],
              [-61.42109375000001, 56.22182617187502],
              [-61.364697265624955, 56.216015625000125],
              [-61.32441406250001, 56.076220703125045],
              [-61.30112304687498, 56.04716796874999],
              [-61.44892578125001, 56.02236328125008],
              [-61.44951171874993, 55.99570312499998],
              [-61.35126953125001, 55.9736816406251],
              [-61.18789062500002, 55.9553710937501],
              [-61.13388671874992, 55.93027343749998],
              [-61.12299804687501, 55.88857421875002],
              [-61.08935546875, 55.86635742187508],
              [-60.99575195312502, 55.86235351562499],
              [-60.89267578124998, 55.91420898437508],
              [-60.83183593749993, 55.95786132812509],
              [-60.74326171874992, 55.94145507812493],
              [-60.73662109374999, 55.88696289062506],
              [-60.63095703124998, 55.825],
              [-60.59257812499999, 55.81484375000008],
              [-60.56210937499998, 55.727001953125125],
              [-60.475830078125, 55.80512695312507],
              [-60.41259765625, 55.78857421875006],
              [-60.341015624999955, 55.78466796875],
              [-60.365429687499955, 55.709082031250034],
              [-60.40830078124998, 55.649560546874966],
              [-60.351953124999966, 55.612353515625045],
              [-60.308300781249955, 55.55698242187498],
              [-60.19238281249994, 55.48090820312507],
              [-60.22402343750002, 55.4443847656251],
              [-60.36093749999992, 55.366308593750034],
              [-60.43310546875, 55.24277343750009],
              [-60.450097656249966, 55.19995117187506],
              [-60.52050781249994, 55.12900390624998],
              [-60.61713867187501, 55.06020507812502],
              [-60.55654296875002, 55.06748046875009],
              [-60.34077148437501, 55.19394531250009],
              [-60.212548828124966, 55.236425781250034],
              [-59.930322265624966, 55.25942382812508],
              [-59.86210937499999, 55.29487304687501],
              [-59.7587890625, 55.3095703125],
              [-59.69550781250001, 55.26914062499992],
              [-59.68906249999992, 55.196337890625045],
              [-59.60546874999994, 55.17333984374994],
              [-59.51767578124992, 55.197363281250034],
              [-59.43789062500002, 55.175927734375136],
              [-59.48583984375, 55.130175781250045],
              [-59.74169921875, 54.94257812500001],
              [-59.81640624999994, 54.86723632812493],
              [-59.83779296875002, 54.813964843750114],
              [-59.749902343749966, 54.887011718750045],
              [-59.42856445312498, 55.05551757812492],
              [-59.394189453124966, 55.08071289062508],
              [-59.32416992187501, 55.15283203125006],
              [-59.25957031249999, 55.19995117187506],
              [-59.086328124999966, 55.18325195312502],
              [-58.99711914062499, 55.149462890625045],
              [-58.95581054687494, 55.05507812500002],
              [-58.88579101562499, 54.952246093750034],
              [-58.78017578125002, 54.838378906250114],
              [-58.499902343749966, 54.78310546875002],
              [-58.39814453125001, 54.77412109375001],
              [-58.22285156250001, 54.81269531250001],
              [-58.195263671874955, 54.86591796875001],
              [-58.05849609375002, 54.882226562499966],
              [-57.96245117187499, 54.87573242187506],
              [-57.92929687499998, 54.773144531250125],
              [-57.826855468749955, 54.71865234375008],
              [-57.72490234374999, 54.673730468750136],
              [-57.62661132812502, 54.65034179687501],
              [-57.48300781249992, 54.64028320312508],
              [-57.40449218750001, 54.59086914062493],
              [-57.404443359374966, 54.570410156250034],
              [-57.48535156249994, 54.51748046875008],
              [-57.563232421875, 54.44042968750006],
              [-57.69926757812499, 54.38657226562509],
              [-57.889111328124955, 54.3840820312501],
              [-58.1513671875, 54.35043945312498],
              [-58.16191406249993, 54.31997070312502],
              [-58.2197265625, 54.28647460937492],
              [-58.35913085937494, 54.253320312500136],
              [-58.43520507812502, 54.228125],
              [-58.55839843749999, 54.10297851562501],
              [-58.63320312500002, 54.049560546875],
              [-58.71943359375001, 54.039404296875034],
              [-58.84082031249994, 54.044482421875045],
              [-58.92021484374999, 54.03310546875002],
              [-58.97846679687501, 54.01025390625],
              [-59.012646484374955, 53.976269531249955],
              [-59.03881835937494, 53.96362304687506],
              [-59.20141601562494, 53.929101562500136],
              [-59.496533203124955, 53.83417968750001],
              [-59.65268554687498, 53.831250000000125],
              [-59.749462890624955, 53.84228515625006],
              [-59.82304687499993, 53.83442382812501],
              [-59.87333984374999, 53.807763671875136],
              [-60.01416015625, 53.76157226562492],
              [-60.05654296875002, 53.73334960937507],
              [-60.08134765624999, 53.701025390625034],
              [-60.100488281249966, 53.634228515625125],
              [-60.11728515624992, 53.610107421875],
              [-60.144921874999966, 53.59614257812501],
              [-60.26333007812502, 53.610058593749955],
              [-60.39541015625002, 53.653320312500114],
              [-60.36953125000002, 53.60747070312499],
              [-60.16025390624998, 53.52998046875001],
              [-60.100292968749955, 53.486962890625136],
              [-60.157128906249966, 53.449804687500034],
              [-60.290283203125, 53.391455078125034],
              [-60.30576171874998, 53.36010742187506],
              [-60.251171874999955, 53.343554687500045],
              [-60.272705078125, 53.317089843749955],
              [-60.34570312499994, 53.289013671875125],
              [-60.3375, 53.27744140625009],
              [-60.32949218750002, 53.26611328125006],
              [-60.148339843749966, 53.306542968750136],
              [-59.98710937499999, 53.392822265625114],
              [-59.88173828125002, 53.48007812499998],
              [-59.82905273437501, 53.504541015625136],
              [-59.62109374999994, 53.536816406249955],
              [-59.49814453124992, 53.574755859375045],
              [-59.322265625, 53.64375],
              [-59.12939453124994, 53.7439453125001],
              [-58.91958007812502, 53.87529296874999],
              [-58.652050781249955, 53.97788085937498],
              [-58.32670898437493, 54.0518066406251],
              [-58.08808593750001, 54.089501953125136],
              [-57.93598632812501, 54.09116210937492],
              [-57.92827148437493, 54.10356445312499],
              [-58.064843749999966, 54.1267578125001],
              [-58.17744140625001, 54.131298828125125],
              [-58.31748046874992, 54.11445312500007],
              [-58.360791015624955, 54.15449218750007],
              [-58.35615234375001, 54.171923828125045],
              [-58.309960937499966, 54.20166015625006],
              [-58.192089843749955, 54.228173828125136],
              [-57.614941406249955, 54.19111328125007],
              [-57.41606445312493, 54.162744140625136],
              [-57.19887695312502, 53.924365234375045],
              [-57.14897460937499, 53.8477050781251],
              [-57.134960937499955, 53.79184570312509],
              [-57.15693359375001, 53.756884765625045],
              [-57.24399414062498, 53.715478515625136],
              [-57.48945312500001, 53.633105468750045],
              [-57.52407226562502, 53.61142578125009],
              [-57.52734375, 53.599902343750045],
              [-57.42021484374999, 53.583251953125],
              [-57.386132812499966, 53.560546875],
              [-57.33173828125001, 53.469091796875034],
              [-57.22138671875001, 53.52851562500007],
              [-57.01215820312501, 53.672607421875114],
              [-56.84086914062499, 53.73945312500007],
              [-56.69658203124993, 53.757666015625034],
              [-56.52431640625002, 53.766455078125034],
              [-56.46499023437502, 53.765039062500136],
              [-56.44433593749994, 53.71831054687499],
              [-56.35400390624994, 53.62446289062507],
              [-56.27021484375001, 53.60009765625006],
              [-56.11015624999999, 53.58759765625001],
              [-55.96611328124999, 53.471142578125125],
              [-55.91123046874998, 53.39082031250007],
              [-55.859375, 53.343896484375136],
              [-55.86337890624998, 53.31225585937506],
              [-55.85478515624993, 53.28583984375001],
              [-55.81689453124994, 53.24575195312502],
              [-55.79794921874998, 53.211962890625045],
              [-55.80820312499998, 53.134667968750136],
              [-55.89233398437494, 53.000439453125125],
              [-55.82988281249993, 52.87841796874994],
              [-55.85791015624994, 52.823388671875136],
              [-55.87250976562501, 52.735693359375034],
              [-55.81865234374993, 52.677148437499966],
              [-55.80283203124992, 52.64316406249998],
              [-55.84843749999999, 52.623339843750045],
              [-56.16699218749994, 52.5747558593751],
              [-56.29238281250002, 52.573779296875045],
              [-56.324902343749955, 52.54453124999998],
              [-56.22841796875002, 52.535986328125034],
              [-56.05258789062498, 52.5374023437501],
              [-55.84018554687498, 52.5076171875001],
              [-55.746484374999966, 52.474560546875125],
              [-55.705957031249966, 52.4282714843751],
              [-55.71621093750002, 52.39150390625008],
              [-55.77714843749993, 52.3642578125],
              [-55.89667968750001, 52.36958007812501],
              [-56.01171875, 52.394482421875125],
              [-56.004638671875, 52.370410156250045],
              [-55.833642578124966, 52.310400390625034],
              [-55.78349609374999, 52.27993164062508],
              [-55.691064453124966, 52.24160156250008],
              [-55.67280273437501, 52.19013671875007],
              [-55.69521484375002, 52.13779296875009],
              [-56.01748046875002, 51.929296875000034],
              [-56.28256835937498, 51.797070312500125],
              [-56.548583984375, 51.68100585937509],
              [-56.97597656250002, 51.45766601562508],
              [-57.01826171875001, 51.44677734375],
              [-57.09560546875002, 51.44252929687502],
              [-57.29921875000002, 51.478271484375],
              [-57.461669921875, 51.46909179687509],
              [-57.76958007812493, 51.425927734374966],
              [-57.85375976562494, 51.39951171874992],
              [-58.02265625000001, 51.32207031249999],
              [-58.08940429687499, 51.31098632812501],
              [-58.27045898437501, 51.29521484374999],
              [-58.44228515625002, 51.30590820312506],
              [-58.51035156249998, 51.295068359375136],
              [-58.59326171875, 51.257128906250045],
              [-58.61474609375, 51.23706054687506],
              [-58.63759765625002, 51.171679687500045],
              [-59.05493164062494, 50.879101562500125],
              [-59.16538085937498, 50.77988281249998],
              [-59.37802734374998, 50.67543945312502],
              [-59.61191406249992, 50.492089843749966],
              [-59.815332031249966, 50.418261718750045],
              [-59.88632812499998, 50.31640625000006],
              [-60.08017578124992, 50.254589843750125],
              [-60.43808593749992, 50.23886718750009],
              [-60.60820312499993, 50.22114257812501],
              [-60.80722656249998, 50.24980468749999],
              [-60.956298828125, 50.205419921875034],
              [-61.18071289062499, 50.191503906250034],
              [-61.289746093749955, 50.201953125000045],
              [-61.724853515625, 50.10405273437499],
              [-61.83535156250002, 50.19697265625007],
              [-61.91953124999992, 50.232861328125125],
              [-62.16523437500001, 50.23891601562508],
              [-62.36166992187498, 50.277294921875125],
              [-62.540917968749966, 50.28452148437498],
              [-62.71542968749998, 50.30166015625008],
              [-62.83022460937502, 50.30146484375007],
              [-62.94975585937493, 50.29135742187498],
              [-63.135644531249966, 50.29379882812509],
              [-63.23862304687492, 50.242578125000136],
              [-63.58666992187494, 50.258203125000136],
              [-63.73359375000001, 50.30463867187501],
              [-63.853955078124955, 50.31435546875002],
              [-64.01582031249995, 50.30395507812506],
              [-64.17041015624994, 50.26943359375008],
              [-64.50893554687497, 50.30893554687498],
              [-64.86787109375001, 50.27548828125009],
              [-65.180908203125, 50.297900390625045],
              [-65.26860351562493, 50.320019531250125],
              [-65.762451171875, 50.25927734375],
              [-65.95537109374999, 50.29414062500001],
              [-66.12553710937499, 50.201025390625034],
              [-66.24218749999994, 50.22036132812508],
              [-66.36884765625001, 50.20664062500009],
              [-66.41108398437501, 50.224267578125136],
              [-66.49550781249997, 50.211865234375125],
              [-66.55004882812494, 50.1611816406251],
              [-66.62172851562502, 50.155419921875136],
              [-66.74086914062502, 50.06552734374998],
              [-66.941162109375, 49.993701171875045],
              [-67.234375, 49.601757812499955],
              [-67.26191406249995, 49.451171875],
              [-67.37202148437501, 49.348437500000045],
              [-67.46923828125, 49.33461914062508],
              [-67.54926757812495, 49.33227539062494],
              [-68.05625, 49.25678710937501],
              [-68.28193359375001, 49.197167968750136],
              [-68.22060546875002, 49.14965820312506],
              [-68.29453124999992, 49.11435546874998],
              [-68.41440429687492, 49.099511718750136],
              [-68.54384765624997, 49.05615234375],
              [-68.62788085937495, 49.00717773437498],
              [-68.66904296874992, 48.939501953125045],
              [-68.92905273437498, 48.82895507812509],
              [-69.23076171874993, 48.573632812500136],
              [-69.37495117187501, 48.38642578125001],
              [-69.55009765624999, 48.2507812500001],
              [-69.67387695312499, 48.19916992187507],
              [-69.76191406250001, 48.191162109375],
              [-69.85170898437497, 48.20737304687492],
              [-70.00102539062493, 48.270947265625125],
              [-70.11064453124999, 48.277978515625136],
              [-70.38369140625002, 48.366503906250045],
              [-71.01826171874995, 48.455615234375045],
              [-70.922607421875, 48.42231445312501],
              [-70.83876953124997, 48.36738281250001],
              [-70.67109375000001, 48.35322265625001],
              [-70.50063476562502, 48.354345703125034],
              [-70.14531249999993, 48.24355468750002],
              [-69.97119140625, 48.205761718750125],
              [-69.86552734374993, 48.17226562500008],
              [-69.775, 48.09809570312507],
              [-69.83984374999994, 47.95258789062507],
              [-69.90556640624993, 47.83222656250007],
              [-69.99443359374993, 47.73989257812508],
              [-70.30009765624993, 47.50302734374998],
              [-70.44804687499999, 47.42343750000009],
              [-70.70585937499999, 47.13979492187502],
              [-70.97270507812493, 47.00668945312509],
              [-71.115625, 46.924951171875136],
              [-71.26777343750001, 46.79594726562499],
              [-71.624755859375, 46.69838867187502],
              [-71.75727539062495, 46.673583984375114],
              [-71.87958984375001, 46.68681640624993],
              [-72.02846679687497, 46.607421875000114],
              [-72.20463867187499, 46.55888671874999],
              [-72.25664062499993, 46.48505859375007],
              [-72.68012695312495, 46.287304687500125],
              [-72.84267578125002, 46.26240234375001],
              [-72.98100585937499, 46.209716796875114],
              [-73.02192382812495, 46.12026367187502],
              [-73.14541015624997, 46.06630859375002],
              [-73.17968749999994, 46.025],
              [-73.28354492187498, 45.89985351562501],
              [-73.47661132812493, 45.738232421874955],
              [-73.71186523437501, 45.71118164062494],
              [-73.79785156249994, 45.65493164062508],
              [-73.89741210937495, 45.56416015625001],
              [-74.037841796875, 45.501855468750136],
              [-74.31508789062497, 45.531054687500045],
              [-74.24765624999992, 45.49287109375007],
              [-73.99960937499992, 45.43334960937506],
              [-73.97382812500001, 45.34511718750002],
              [-74.09809570312501, 45.32402343749999],
              [-74.35830078124997, 45.20639648437498],
              [-74.70888671875002, 45.003857421875125],
              [-74.762451171875, 44.999072265624996],
              [-74.85664062500001, 45.003906250000114],
              [-74.99614257812499, 44.970117187499966],
              [-75.17939453125001, 44.899365234375125],
              [-75.40126953125002, 44.77226562499999],
              [-75.79194335937501, 44.49707031250006],
              [-75.8193359375, 44.468017578125],
              [-75.87592773437501, 44.416992187500114],
              [-76.02021484375001, 44.362597656250045],
              [-76.15117187499999, 44.303955078125],
              [-76.185791015625, 44.242236328124996],
              [-76.24853515625, 44.214111328125],
              [-76.464599609375, 44.0576171875],
              [-76.58613281250001, 43.924316406250114],
              [-76.69648437500001, 43.78481445312508],
              [-76.81997070312502, 43.62880859375008],
              [-77.07333984374999, 43.626855468749994],
              [-77.26669921874999, 43.627490234374996],
              [-77.59653320312499, 43.62861328125],
              [-77.87924804687499, 43.629541015624994],
              [-78.214794921875, 43.630664062499996],
              [-78.458251953125, 43.631494140624994],
              [-78.72041015625, 43.624951171875],
              [-78.84555664062499, 43.583349609375],
              [-79.00249023437499, 43.527148437499996],
              [-79.171875, 43.466552734375114],
              [-79.08305664062499, 43.331396484375],
              [-79.059228515625, 43.278076171875],
              [-79.06606445312502, 43.10610351562502],
              [-79.047998046875, 43.087304687499994],
              [-79.029052734375, 43.061767578125],
              [-79.02617187499999, 43.017333984375],
              [-79.011669921875, 42.997021484375],
              [-78.98076171874999, 42.980615234374994],
              [-78.94599609375001, 42.96132812500002],
              [-78.92084960937501, 42.93520507812502],
              [-78.91508789062499, 42.90913085937501],
              [-78.93925781249999, 42.863720703125],
              [-79.03671875, 42.80234375],
              [-79.17373046875002, 42.74853515625],
              [-79.446240234375, 42.65146484375],
              [-79.76201171874999, 42.538964843750136],
              [-80.03574218749999, 42.441455078124996],
              [-80.24755859375, 42.366015625],
              [-80.6826171875, 42.299755859375],
              [-81.02822265625002, 42.24716796874998],
              [-81.27763671874999, 42.2091796875],
              [-81.50732421875, 42.10346679687501],
              [-81.7609375, 41.98681640625],
              [-81.97416992187499, 41.88872070312499],
              [-82.213330078125, 41.778710937499994],
              [-82.4390625, 41.674853515624996],
              [-82.69003906249999, 41.675195312499994],
              [-82.8662109375, 41.75302734375],
              [-83.02998046875, 41.832958984375],
              [-83.14194335937499, 41.975878906249996],
              [-83.149658203125, 42.141943359375],
              [-83.10952148437502, 42.25068359375001],
              [-83.07314453125, 42.30029296875],
              [-83.00371093749999, 42.33173828125],
              [-82.86777343749999, 42.385205078125],
              [-82.74418945312499, 42.4934570312501],
              [-82.64511718749999, 42.558056640625],
              [-82.5453125, 42.62470703125],
              [-82.48833007812499, 42.739501953125],
              [-82.417236328125, 43.017382812500045],
              [-82.408203125, 43.07265625],
              [-82.30478515624999, 43.263232421874996],
              [-82.190380859375, 43.474072265625],
              [-82.13784179687502, 43.570898437500034],
              [-82.19658203124999, 43.822216796875],
              [-82.24077148437499, 44.01533203125],
              [-82.28125, 44.192236328125],
              [-82.326806640625, 44.391552734375],
              [-82.36826171874999, 44.572998046875],
              [-82.407373046875, 44.743945312499996],
              [-82.44658203124999, 44.91552734375],
              [-82.48505859375, 45.083740234375],
              [-82.515234375, 45.204394531249996],
              [-82.55107421874999, 45.34736328125],
              [-82.76040039062502, 45.447705078125125],
              [-82.9193359375, 45.51796875],
              [-83.179296875, 45.632763671875],
              [-83.39731445312499, 45.729052734374996],
              [-83.59267578125002, 45.81713867187506],
              [-83.469482421875, 45.99467773437499],
              [-83.48012695312502, 46.023730468750045],
              [-83.52475585937499, 46.05869140625],
              [-83.615966796875, 46.11684570312502],
              [-83.66928710937499, 46.12275390625],
              [-83.76318359375, 46.10908203125],
              [-83.91303710937501, 46.072900390625136],
              [-83.977783203125, 46.084912109375],
              [-84.02919921875, 46.147021484374996],
              [-84.08837890625, 46.226513671875],
              [-84.10776367187499, 46.288623046874996],
              [-84.11518554687501, 46.37080078125007],
              [-84.15048828124999, 46.444775390625],
              [-84.128125, 46.48359375],
              [-84.12319335937502, 46.5029296875],
              [-84.1251953125, 46.527246093749994],
              [-84.14946289062499, 46.542773437499996],
              [-84.19218749999999, 46.549560546875],
              [-84.33671874999999, 46.518505859375],
              [-84.40170898437499, 46.515625],
              [-84.44047851562499, 46.49814453125],
              [-84.5015625, 46.461865234375],
              [-84.561767578125, 46.457373046875],
              [-84.665771484375, 46.543261718750045],
              [-84.77939453124999, 46.6373046875],
              [-84.82705078125, 46.766845703125],
              [-84.8759765625, 46.89990234375],
              [-85.070068359375, 46.979931640625],
              [-85.26411132812501, 47.059960937500136],
              [-85.458203125, 47.139941406249996],
              [-85.65224609375002, 47.21997070312506],
              [-85.846337890625, 47.3],
              [-86.04038085937499, 47.380029296874994],
              [-86.23447265624999, 47.46005859375],
              [-86.42856445312499, 47.540087890624996],
              [-86.49555664062501, 47.566601562500125],
              [-86.67216796874999, 47.63642578125],
              [-86.921826171875, 47.735205078125],
              [-87.2080078125, 47.848486328125],
              [-87.49423828124999, 47.961767578125],
              [-87.743896484375, 48.060546875],
              [-87.92050781249999, 48.13037109375],
              [-87.987451171875, 48.156884765624994],
              [-88.16064453125, 48.225390625000045],
              [-88.378173828125, 48.30307617187509],
              [-88.61176757812501, 48.26401367187509],
              [-88.898681640625, 48.155712890625],
              [-89.06259765624999, 48.093798828124996],
              [-89.18564453124999, 48.047412109374996],
              [-89.273193359375, 48.019970703125125],
              [-89.45566406249999, 47.996240234374994],
              [-89.55058593749999, 47.999902343749994],
              [-89.775390625, 48.01533203125],
              [-89.90102539062502, 47.99545898437509],
              [-89.99365234375, 48.01533203125],
              [-90.03994140625, 48.078173828124996],
              [-90.091796875, 48.11811523437507],
              [-90.32011718749999, 48.099169921874996],
              [-90.607080078125, 48.112597656249996],
              [-90.744384765625, 48.10458984375],
              [-90.79731445312501, 48.13105468750001],
              [-90.84033203125, 48.20053710937498],
              [-90.91606445312499, 48.209130859374994],
              [-91.04345703125, 48.193701171875],
              [-91.22065429687501, 48.10458984374998],
              [-91.38720703125, 48.058544921875],
              [-91.518310546875, 48.058300781250125],
              [-91.64731445312499, 48.10458984375],
              [-91.8583984375, 48.197558593750045],
              [-92.00517578124999, 48.30185546875],
              [-92.17177734375, 48.33837890625],
              [-92.29868164062499, 48.32890625],
              [-92.34843749999999, 48.276611328125],
              [-92.41459960937499, 48.276611328125],
              [-92.46088867187501, 48.36586914062508],
              [-92.50058593749999, 48.435351562499996],
              [-92.583251953125, 48.465087890625],
              [-92.732666015625, 48.5318359375],
              [-92.83671874999999, 48.567773437499994],
              [-92.996240234375, 48.61181640625],
              [-93.05170898437501, 48.619873046875114],
              [-93.155224609375, 48.625341796875],
              [-93.25795898437502, 48.62885742187501],
              [-93.37788085937501, 48.61655273437498],
              [-93.463623046875, 48.561279296875114],
              [-93.56425781249999, 48.536914062499996],
              [-93.70771484375, 48.525439453124996],
              [-93.80356445312499, 48.54892578125],
              [-93.85161132812499, 48.607275390625034],
              [-94.05517578125, 48.659033203125],
              [-94.41416015624999, 48.7041015625],
              [-94.62089843749999, 48.742626953125],
              [-94.67534179687499, 48.7744140625],
              [-94.705078125, 48.808496093749994],
              [-94.712548828125, 48.86298828125],
              [-94.71279296875002, 48.86342773437502],
              [-94.803466796875, 49.0029296875],
              [-94.84257812499999, 49.119189453125045],
              [-94.86040039062499, 49.25859375],
              [-94.85434570312499, 49.304589843749994],
              [-94.87480468749999, 49.31904296875],
              [-94.93935546875, 49.349414062499996],
              [-95.1552734375, 49.3696777343751],
              [-95.15825195312499, 49.203076171875],
              [-95.162060546875, 48.991748046874996],
              [-95.397900390625, 48.9931640625],
              [-95.82431640624999, 48.9931640625],
              [-96.25068359375, 48.9931640625],
              [-96.67705078124999, 48.9931640625],
              [-97.10346679687501, 48.9931640625],
              [-97.52983398437499, 48.9931640625],
              [-97.956201171875, 48.9931640625],
              [-98.38261718749999, 48.9931640625],
              [-98.808984375, 48.9931640625],
              [-99.2353515625, 48.993115234375],
              [-99.66171875, 48.993115234375],
              [-100.088134765625, 48.993115234375125],
              [-100.51450195312499, 48.993115234375],
              [-100.94086914062501, 48.993115234375125],
              [-101.36728515624999, 48.993115234375],
              [-101.79365234375001, 48.993115234375125],
              [-102.22001953124999, 48.993115234375125],
              [-102.64643554687501, 48.993115234375125],
              [-103.07280273437499, 48.993115234375],
              [-103.49916992187502, 48.993115234375125],
              [-103.9255859375, 48.993115234375],
              [-104.35195312500002, 48.993115234375125],
              [-104.7783203125, 48.993115234375125],
              [-105.2046875, 48.993115234375],
              [-105.631103515625, 48.993115234375125],
              [-106.05747070312499, 48.993115234375],
              [-106.48383789062501, 48.993115234375125],
              [-106.91025390624999, 48.993115234375],
              [-107.33662109375001, 48.993115234375125],
              [-107.76298828125, 48.993115234375],
              [-108.18940429687501, 48.993115234375125],
              [-108.615771484375, 48.993115234375],
              [-109.04213867187502, 48.993115234375125],
              [-109.4685546875, 48.993066406249994],
              [-109.89492187500002, 48.993066406250136],
              [-110.3212890625, 48.993066406249994],
              [-110.74765625, 48.993066406249994],
              [-111.174072265625, 48.993066406249994],
              [-111.60043945312499, 48.993066406249994],
              [-112.026806640625, 48.993066406249994],
              [-112.45322265624999, 48.993066406249994],
              [-112.87958984375, 48.993066406249994],
              [-113.30595703125, 48.993066406249994],
              [-113.732373046875, 48.993066406249994],
              [-114.158740234375, 48.993066406249994],
              [-114.585107421875, 48.993066406249994],
              [-115.0115234375, 48.993066406249994],
              [-115.437890625, 48.993066406249994],
              [-115.8642578125, 48.993066406249994],
              [-116.290625, 48.993066406249994],
              [-116.717041015625, 48.993066406249994],
              [-117.143408203125, 48.993066406249994],
              [-117.569775390625, 48.993066406249994],
              [-117.99619140625, 48.993066406249994],
              [-118.42255859375, 48.993066406249994],
              [-118.84892578124999, 48.993066406249994],
              [-119.275341796875, 48.993066406249994],
              [-119.70170898437499, 48.993017578125],
              [-120.128076171875, 48.993017578125],
              [-120.55449218749999, 48.99301757812492],
              [-120.980859375, 48.993017578125],
              [-121.4072265625, 48.99301757812492],
              [-121.83359375, 48.993017578125],
              [-122.260009765625, 48.993017578125],
              [-122.686376953125, 48.993017578125],
              [-122.78876953125, 48.993017578125],
              [-122.82670898437499, 49.028417968750034],
              [-122.92416992187492, 49.07465820312507],
              [-122.96269531249999, 49.07460937500002],
              [-123.00229492187498, 49.06088867187509],
              [-123.02724609374997, 49.038525390624955],
              [-123.04921875000002, 48.99301757812492],
              [-123.06328124999999, 48.977734375],
              [-123.077294921875, 48.980224609375],
              [-123.08642578125, 48.993017578125],
              [-123.11762695312501, 49.05634765625007],
              [-123.10932617187501, 49.084619140625136],
              [-123.07729492187497, 49.11835937500007],
              [-123.07954101562495, 49.130615234375114],
              [-123.15014648437494, 49.12104492187507],
              [-123.18188476562494, 49.129492187500034],
              [-123.19633789062499, 49.147705078125],
              [-123.19106445312497, 49.2195312500001],
              [-123.22944335937495, 49.26049804687506],
              [-123.18393554687492, 49.27773437500002],
              [-123.06728515624997, 49.29155273437499],
              [-122.94765625000002, 49.29326171874999],
              [-122.91298828124997, 49.32319335937507],
              [-122.87910156250001, 49.39892578125006],
              [-122.96445312499998, 49.32934570312506],
              [-123.01552734374997, 49.32216796875002],
              [-123.17426757812501, 49.34819335937499],
              [-123.27675781250002, 49.34394531250001],
              [-123.29052734374994, 49.35947265624998],
              [-123.28627929687497, 49.374951171875125],
              [-123.26406250000002, 49.39047851562509],
              [-123.24770507812502, 49.443017578125136],
              [-123.22299804687498, 49.590478515624966],
              [-123.19067382812494, 49.644287109375114],
              [-123.17958984375002, 49.67353515625001],
              [-123.1875, 49.680322265624966],
              [-123.325, 49.577685546875045],
              [-123.33666992187494, 49.545117187499955],
              [-123.32241210937497, 49.516992187500136],
              [-123.33564453125001, 49.45917968750007],
              [-123.39897460937493, 49.441894531250114],
              [-123.43696289062495, 49.45131835937508],
              [-123.50820312500002, 49.402441406250034],
              [-123.53056640624993, 49.39731445312509],
              [-123.85893554687493, 49.48286132812507],
              [-123.891845703125, 49.494726562500034],
              [-123.94838867187497, 49.53471679687499],
              [-124.02861328124999, 49.602880859375034],
              [-124.05380859374998, 49.66171875000009],
              [-124.02402343749992, 49.71132812500008],
              [-123.99262695312501, 49.73618164062498],
              [-123.95952148437493, 49.73618164062498],
              [-123.92275390624997, 49.717529296875114],
              [-123.84711914062501, 49.636669921874955],
              [-123.81718749999999, 49.58657226562508],
              [-123.73906249999993, 49.5935546875001],
              [-123.61274414062495, 49.65756835937498],
              [-123.58247070312501, 49.68125],
              [-123.70830078124999, 49.65693359375001],
              [-123.7626953125, 49.65849609374999],
              [-123.81801757812497, 49.68515625000009],
              [-123.87441406250002, 49.736816406250114],
              [-123.90380859374994, 49.79545898437499],
              [-123.90424804687495, 49.98115234375001],
              [-123.88496093750001, 50.01704101562501],
              [-123.82382812499998, 50.043701171875114],
              [-123.78466796875, 50.08798828125009],
              [-123.78769531249992, 50.10673828124999],
              [-123.82543945312494, 50.14423828125001],
              [-123.880126953125, 50.17363281249993],
              [-123.93359375, 50.1882812500001],
              [-123.94589843749998, 50.18393554687509],
              [-123.863037109375, 50.102587890625045],
              [-123.86572265625, 50.072070312500045],
              [-123.95742187500002, 49.992773437500034],
              [-123.97138671874995, 49.9695312500001],
              [-123.97211914062501, 49.892041015625125],
              [-123.98491210937493, 49.87558593749998],
              [-124.05878906250001, 49.853662109375136],
              [-124.1416015625, 49.792675781250125],
              [-124.28125, 49.77211914062502],
              [-124.41259765624994, 49.778125],
              [-124.48325195312498, 49.80820312500009],
              [-124.70229492187497, 49.95766601562502],
              [-124.78237304687497, 50.02011718749992],
              [-124.78427734374998, 50.07280273437499],
              [-124.93417968749998, 50.258056640625114],
              [-124.933349609375, 50.297900390625045],
              [-124.985595703125, 50.35561523437508],
              [-125.04360351562497, 50.36376953124994],
              [-125.05668945312499, 50.418652343750125],
              [-124.93681640624999, 50.53740234374999],
              [-124.86264648437498, 50.63730468750009],
              [-124.85424804687494, 50.66865234375007],
              [-124.85751953124998, 50.717333984375045],
              [-124.87543945312501, 50.82563476562507],
              [-124.85986328124994, 50.872412109375034],
              [-124.93359374999994, 50.810595703125045],
              [-124.94926757812493, 50.7646972656251],
              [-124.93105468749997, 50.71840820312508],
              [-124.94252929687502, 50.66567382812502],
              [-124.98540039062493, 50.59194335937508],
              [-125.05878906249995, 50.51386718750001],
              [-125.20986328125002, 50.476318359375],
              [-125.47631835937494, 50.49716796874998],
              [-125.50717773437492, 50.50727539062507],
              [-125.52597656249992, 50.53413085937501],
              [-125.53935546874999, 50.64902343749998],
              [-125.55556640625002, 50.63486328124992],
              [-125.58583984374997, 50.57363281250008],
              [-125.61015624999993, 50.48603515625001],
              [-125.64130859374995, 50.46621093750002],
              [-125.69755859374999, 50.46455078125007],
              [-125.7412109375, 50.4785644531251],
              [-125.77241210937495, 50.50820312500008],
              [-125.83959960937494, 50.51064453125002],
              [-125.96503906250001, 50.4873535156251],
              [-126.02412109375001, 50.49672851562502],
              [-126.09433593749998, 50.497607421875045],
              [-126.236572265625, 50.52329101562498],
              [-126.40449218750001, 50.529882812500034],
              [-126.44995117187494, 50.54970703125002],
              [-126.44746093750001, 50.58774414062492],
              [-126.41611328124992, 50.60698242187493],
              [-126.23891601562497, 50.62382812499999],
              [-126.06723632812498, 50.66430664062506],
              [-125.89760742187497, 50.6843750000001],
              [-125.90410156249999, 50.704931640625034],
              [-125.98071289062494, 50.71137695312507],
              [-126.37031250000001, 50.66674804687506],
              [-126.49296874999993, 50.67211914062506],
              [-126.51435546875001, 50.679394531250125],
              [-126.517333984375, 50.72446289062509],
              [-126.47221679687499, 50.767285156250125],
              [-126.39711914062502, 50.80708007812501],
              [-126.37460937499998, 50.83735351562501],
              [-126.418212890625, 50.85019531249992],
              [-126.48818359375002, 50.84184570312499],
              [-126.52177734374993, 50.86606445312498],
              [-126.484619140625, 50.960498046875045],
              [-126.517333984375, 51.056835937500125],
              [-126.56289062499997, 50.965478515625136],
              [-126.63178710937495, 50.915136718750034],
              [-126.96040039062495, 50.89370117187508],
              [-127.01406249999997, 50.86679687499992],
              [-127.05756835937501, 50.86752929687509],
              [-127.26748046874997, 50.916064453125045],
              [-127.35693359375, 50.945556640625],
              [-127.44121093749993, 50.98940429687508],
              [-127.59086914062499, 51.087548828125136],
              [-127.70810546874998, 51.15117187499999],
              [-127.71430664062495, 51.26865234375009],
              [-127.68916015625001, 51.34345703125007],
              [-127.63271484374997, 51.4272949218751],
              [-127.419677734375, 51.608056640625136],
              [-127.34658203125002, 51.642382812500045],
              [-127.28066406250002, 51.6541015625001],
              [-126.96811523437492, 51.66992187499994],
              [-126.73540039062493, 51.69262695312494],
              [-126.69145507812499, 51.703417968750045],
              [-127.03408203125002, 51.71669921875008],
              [-127.33872070312492, 51.70737304687498],
              [-127.44272460937498, 51.678955078125],
              [-127.57573242187499, 51.56293945312501],
              [-127.60957031249995, 51.51406250000002],
              [-127.64487304687498, 51.47846679687501],
              [-127.668701171875, 51.477587890625045],
              [-127.71406250000001, 51.490185546875125],
              [-127.72871093749995, 51.50551757812502],
              [-127.74750976562501, 51.54355468749998],
              [-127.81894531249998, 51.60390625000002],
              [-127.85053710937501, 51.67319335937509],
              [-127.869140625, 51.77524414062509],
              [-127.86323242187501, 51.82080078124994],
              [-127.82998046875002, 51.87900390625009],
              [-127.72763671874992, 51.9932128906251],
              [-127.85878906250002, 51.990283203125045],
              [-127.84331054687499, 52.0864746093751],
              [-127.79536132812495, 52.19101562500009],
              [-127.67333984375, 52.25292968750006],
              [-127.54970703125002, 52.297607421875114],
              [-127.43793945312501, 52.356152343750125],
              [-127.24223632812499, 52.39511718750009],
              [-127.17573242187495, 52.31484375000008],
              [-127.00795898437497, 52.290673828125136],
              [-126.95947265624994, 52.25454101562502],
              [-126.9, 52.188330078125034],
              [-126.82631835937497, 52.125146484375136],
              [-126.73857421874999, 52.06494140624994],
              [-126.71396484374992, 52.060693359374966],
              [-126.75263671875001, 52.112353515625045],
              [-126.89521484375001, 52.225488281249966],
              [-126.90141601562493, 52.265332031250125],
              [-126.93818359374995, 52.30859375000006],
              [-127.12705078124998, 52.37094726562498],
              [-127.16059570312495, 52.394873046875034],
              [-127.19399414062502, 52.45766601562502],
              [-127.208251953125, 52.498242187500125],
              [-127.18710937499998, 52.53769531249998],
              [-126.99521484374992, 52.657910156249955],
              [-126.95131835937497, 52.7212402343751],
              [-126.95136718749995, 52.7510253906251],
              [-126.96640624999992, 52.78466796875006],
              [-127.00825195312501, 52.8425781250001],
              [-127.01933593749999, 52.842480468750125],
              [-127.00639648437499, 52.754589843750125],
              [-127.01323242187499, 52.719970703125],
              [-127.03486328125001, 52.681738281250034],
              [-127.06621093749993, 52.65268554687498],
              [-127.10708007812497, 52.6328125],
              [-127.51923828125001, 52.35927734375008],
              [-127.56030273437494, 52.34321289062501],
              [-127.71337890624994, 52.318505859375136],
              [-127.79189453124997, 52.289355468750045],
              [-127.83432617187492, 52.25097656250006],
              [-127.90219726562498, 52.15087890625006],
              [-127.99541015624993, 51.95053710937509],
              [-128.10224609374995, 51.78842773437492],
              [-128.1935546875, 51.99829101562506],
              [-128.3576171875, 52.158886718750125],
              [-128.0375, 52.318164062500045],
              [-128.02915039062498, 52.34248046875001],
              [-128.06030273437494, 52.4275390625001],
              [-128.05156249999993, 52.45332031250007],
              [-128.0212890625, 52.49067382812501],
              [-127.94023437499999, 52.545166015625114],
              [-127.943359375, 52.55073242187501],
              [-128.03823242187497, 52.53115234375008],
              [-128.18398437500002, 52.40791015625001],
              [-128.240966796875, 52.36826171874992],
              [-128.271533203125, 52.362988281250125],
              [-128.275146484375, 52.435498046875125],
              [-128.19677734374994, 52.62329101562506],
              [-128.132373046875, 52.80581054687508],
              [-128.10878906249997, 52.85805664062508],
              [-128.05327148437493, 52.91069335937499],
              [-128.10595703125, 52.90688476562508],
              [-128.36503906249993, 52.82578125000009],
              [-128.451953125, 52.876611328125136],
              [-128.52470703125, 53.1406738281251],
              [-128.65234374999994, 53.243847656249955],
              [-128.86855468750002, 53.328125000000114],
              [-129.08090820312492, 53.36728515625009],
              [-129.12954101562502, 53.44228515625008],
              [-129.17158203124995, 53.53359375000002],
              [-129.114453125, 53.641113281250114],
              [-129.021435546875, 53.692138671875],
              [-128.935595703125, 53.71518554687509],
              [-128.85458984374992, 53.70454101562507],
              [-128.85043945312498, 53.66518554687502],
              [-128.90561523437492, 53.559326171875114],
              [-128.833056640625, 53.549414062500034],
              [-128.54213867187497, 53.420654296875114],
              [-128.47861328124998, 53.410302734375136],
              [-128.35805664062502, 53.45981445312509],
              [-128.291064453125, 53.457861328125034],
              [-128.13271484375002, 53.417773437500045],
              [-128.07919921874998, 53.36943359374999],
              [-127.92783203124998, 53.274707031250045],
              [-127.95004882812498, 53.329833984375114],
              [-128.11513671875002, 53.445947265625136],
              [-128.20722656249995, 53.483203125000045],
              [-128.369140625, 53.490380859375136],
              [-128.46962890625002, 53.47089843750007],
              [-128.511767578125, 53.47656249999994],
              [-128.600341796875, 53.506103515625114],
              [-128.67553710937494, 53.55458984375002],
              [-128.75078124999993, 53.66083984375001],
              [-128.76787109374993, 53.710205078125114],
              [-128.763671875, 53.746875],
              [-128.74594726562492, 53.78017578125002],
              [-128.71474609374997, 53.81000976562501],
              [-128.652880859375, 53.83164062499998],
              [-128.56044921874997, 53.84506835937509],
              [-128.53212890624997, 53.85810546875007],
              [-128.65087890625, 53.91884765625002],
              [-128.70478515624995, 53.918603515625136],
              [-128.890185546875, 53.829785156249955],
              [-128.92783203124992, 53.82280273437499],
              [-128.94399414062502, 53.840039062500125],
              [-128.959375, 53.84145507812502],
              [-129.01396484375, 53.79746093749998],
              [-129.056396484375, 53.77778320312507],
              [-129.20810546874992, 53.6416015625],
              [-129.23173828124993, 53.57641601562506],
              [-129.24033203124992, 53.4790527343751],
              [-129.25786132812493, 53.417968750000114],
              [-129.28427734374998, 53.393164062500034],
              [-129.46240234374994, 53.346582031250136],
              [-129.563720703125, 53.251464843750114],
              [-129.68671875, 53.33354492187493],
              [-129.82177734375, 53.41274414062508],
              [-129.91186523437494, 53.55136718750009],
              [-130.07436523437502, 53.57563476562507],
              [-130.26328124999998, 53.65415039062509],
              [-130.33525390625, 53.7239257812501],
              [-130.23286132812495, 53.867431640625],
              [-130.0859375, 53.97578125000007],
              [-130.06352539062493, 54.10566406250007],
              [-130.04331054687498, 54.13354492187506],
              [-129.79077148437494, 54.16577148437506],
              [-129.626025390625, 54.230273437500045],
              [-129.79497070312493, 54.23613281249999],
              [-129.89843749999994, 54.22636718749993],
              [-130.084228515625, 54.181396484375],
              [-130.29033203124993, 54.270361328125034],
              [-130.39677734375, 54.35166015625009],
              [-130.43027343749998, 54.42099609374998],
              [-130.39345703125002, 54.47963867187502],
              [-130.388623046875, 54.5393554687501],
              [-130.37001953125002, 54.62001953125002],
              [-130.35048828124997, 54.6553222656251],
              [-130.30722656249992, 54.700292968750034],
              [-130.21894531249995, 54.7302734375001],
              [-130.140869140625, 54.822753906250114],
              [-130.108642578125, 54.8872558593751],
              [-129.94853515624993, 55.0810546875],
              [-129.89013671874994, 55.16464843749998],
              [-129.78076171875, 55.280468750000125],
              [-129.56064453124998, 55.46254882812508],
              [-129.630126953125, 55.45224609374992],
              [-129.66665039062502, 55.436669921875136],
              [-129.70131835937502, 55.43857421874998],
              [-129.7341796875, 55.45800781250006],
              [-129.76547851562498, 55.49824218750007],
              [-129.795166015625, 55.559570312500114],
              [-129.81191406250002, 55.532617187500136],
              [-129.815625, 55.41757812499998],
              [-129.83774414062492, 55.319091796875],
              [-129.87714843749995, 55.25063476562508],
              [-129.98520507812498, 55.111474609375136],
              [-130.04848632812497, 55.057275390625136],
              [-130.091796875, 55.10776367187509],
              [-130.05839843749993, 55.19477539062507],
              [-129.995849609375, 55.264062500000136],
              [-129.98515624999993, 55.35883789062507],
              [-130.04404296874998, 55.47192382812506],
              [-130.07998046875002, 55.56289062500008],
              [-130.09296875, 55.631835937500114],
              [-130.09467773437495, 55.694775390625125],
              [-130.08510742187497, 55.751708984375],
              [-130.0603515625, 55.81372070312506],
              [-130.02031249999993, 55.88076171875002],
              [-130.02509765625, 55.8882324218751],
            ],
          ],
          [
            [
              [-109.815966796875, 78.650390625],
              [-109.64096679687499, 78.59208984375],
              [-109.580859375, 78.59326171875],
              [-109.504150390625, 78.582470703125],
              [-109.46728515625, 78.5671875],
              [-109.36220703125, 78.49287109375],
              [-109.34213867187499, 78.456005859375],
              [-109.33603515624999, 78.408447265625],
              [-109.352099609375, 78.36865234375],
              [-109.39052734375, 78.336669921875],
              [-109.48447265624999, 78.31640625],
              [-109.7087890625, 78.303759765625],
              [-110.021875, 78.322802734375],
              [-110.29345703125, 78.298193359375],
              [-110.418359375, 78.294970703125],
              [-110.755078125, 78.3107421875],
              [-110.8400390625, 78.322314453125],
              [-111.0267578125, 78.367626953125],
              [-111.169189453125, 78.386279296875],
              [-111.22900390625, 78.376318359375],
              [-111.30048828125, 78.336572265625],
              [-111.43505859375, 78.287353515625],
              [-111.51748046875, 78.27470703125],
              [-111.759716796875, 78.282958984375],
              [-112.13125, 78.366064453125],
              [-112.5578125, 78.34150390625],
              [-112.99990234375, 78.292919921875],
              [-113.172509765625, 78.2837890625],
              [-113.223046875, 78.297900390625],
              [-113.292578125, 78.334375],
              [-113.281689453125, 78.352783203125],
              [-113.149951171875, 78.4083984375],
              [-112.855859375, 78.466845703125],
              [-112.6408203125, 78.4998046875],
              [-112.214013671875, 78.547802734375],
              [-111.7087890625, 78.57470703125],
              [-111.51987304687499, 78.60322265625],
              [-111.400341796875, 78.64404296875],
              [-111.071484375, 78.7083984375],
              [-110.877587890625, 78.73505859375],
              [-110.61806640625, 78.7578125],
              [-110.4078125, 78.756640625],
              [-110.14047851562499, 78.704443359375],
              [-109.940869140625, 78.678466796875],
              [-109.815966796875, 78.650390625],
            ],
          ],
          [
            [
              [-110.458056640625, 78.10322265625],
              [-109.656884765625, 78.079248046875],
              [-109.622265625, 78.074755859375],
              [-109.61904296875, 78.0568359375],
              [-109.67939453125, 77.99931640625],
              [-109.77177734375, 77.957421875],
              [-110.19951171875, 77.904833984375],
              [-110.751123046875, 77.8572265625],
              [-110.865625, 77.834130859375],
              [-110.85654296874999, 77.820361328125],
              [-110.81162109374999, 77.803173828125],
              [-110.719384765625, 77.7814453125],
              [-110.2921875, 77.786376953125],
              [-110.189208984375, 77.777001953125],
              [-110.152734375, 77.762939453125],
              [-110.130859375, 77.7423828125],
              [-110.117578125, 77.715576171875],
              [-110.11689453125, 77.62470703125],
              [-110.139453125, 77.572119140625],
              [-110.198486328125, 77.52451171875],
              [-110.371533203125, 77.490625],
              [-110.682861328125, 77.4458984375],
              [-110.893994140625, 77.4259765625],
              [-111.06044921875, 77.433154296875],
              [-111.226220703125, 77.428515625],
              [-111.951953125, 77.344189453125],
              [-112.176513671875, 77.34375],
              [-112.37265625, 77.364111328125],
              [-112.643798828125, 77.443701171875],
              [-112.92563476562499, 77.474951171875],
              [-113.046044921875, 77.5107421875],
              [-113.16435546875, 77.5302734375],
              [-113.19711914062499, 77.558837890625],
              [-113.208544921875, 77.58017578125],
              [-113.188623046875, 77.599755859375],
              [-113.13740234375, 77.617529296875],
              [-113.120654296875, 77.6326171875],
              [-113.1677734375, 77.67646484375],
              [-113.189501953125, 77.718310546875],
              [-113.2712890625, 77.77841796875],
              [-113.283447265625, 77.813037109375],
              [-113.282958984375, 77.83564453125],
              [-113.2693359375, 77.86005859375],
              [-113.21518554687499, 77.903515625],
              [-113.187060546875, 77.912353515625],
              [-113.021630859375, 77.919140625],
              [-112.80454101562499, 77.9416015625],
              [-112.30458984375, 78.006787109375],
              [-111.206591796875, 78.088134765625],
              [-110.8732421875, 78.080615234375],
              [-110.72734375, 78.09658203125],
              [-110.458056640625, 78.10322265625],
            ],
          ],
          [
            [
              [-115.55126953125, 77.36328125],
              [-115.4755859375, 77.32431640625],
              [-115.47021484375, 77.308642578125],
              [-115.506640625, 77.292138671875],
              [-115.62392578125, 77.26591796875],
              [-116.213720703125, 77.17822265625],
              [-116.32919921875, 77.137060546875],
              [-116.2857421875, 77.10166015625],
              [-116.073095703125, 77.02998046875],
              [-115.8568359375, 76.96923828125],
              [-115.81005859375, 76.939111328125],
              [-115.912890625, 76.908447265625],
              [-116.109765625, 76.918212890625],
              [-116.183251953125, 76.915576171875],
              [-116.25273437499999, 76.901416015625],
              [-116.233984375, 76.87431640625],
              [-116.0162109375, 76.784521484375],
              [-115.944580078125, 76.73623046875],
              [-115.9462890625, 76.711279296875],
              [-115.984814453125, 76.6869140625],
              [-116.07622070312499, 76.653515625],
              [-116.220458984375, 76.611083984375],
              [-116.467626953125, 76.5771484375],
              [-116.99921875, 76.531591796875],
              [-117.016796875, 76.49609375],
              [-117.01328125, 76.469091796875],
              [-117.02617187499999, 76.403515625],
              [-117.04448242187499, 76.373095703125],
              [-117.107763671875, 76.321923828125],
              [-117.15390625, 76.297998046875],
              [-117.23359375, 76.28154296875],
              [-117.346923828125, 76.27255859375],
              [-117.4923828125, 76.272705078125],
              [-117.732421875, 76.316748046875],
              [-117.84140625, 76.34482421875],
              [-117.99296874999999, 76.405810546875],
              [-118.02021484375, 76.446533203125],
              [-118.00541992187499, 76.4966796875],
              [-117.9654296875, 76.5740234375],
              [-117.89951171875, 76.653076171875],
              [-117.8076171875, 76.733935546875],
              [-117.78046875, 76.78427734375],
              [-117.81791992187499, 76.8041015625],
              [-117.880810546875, 76.805078125],
              [-118.076416015625, 76.77236328125],
              [-118.202783203125, 76.760498046875],
              [-118.30058593749999, 76.736669921875],
              [-118.369873046875, 76.7009765625],
              [-118.409130859375, 76.6623046875],
              [-118.431005859375, 76.58798828125],
              [-118.4681640625, 76.54736328125],
              [-118.573681640625, 76.5251953125],
              [-118.73154296875, 76.5255859375],
              [-118.79140625, 76.51298828125],
              [-118.820751953125, 76.48583984375],
              [-118.799560546875, 76.46376953125],
              [-118.643896484375, 76.417529296875],
              [-118.62451171875, 76.365869140625],
              [-118.643408203125, 76.33466796875],
              [-118.81157226562499, 76.277099609375],
              [-118.85112304687499, 76.2578125],
              [-118.95546875, 76.16767578125],
              [-118.99394531249999, 76.144873046875],
              [-119.080712890625, 76.124072265625],
              [-119.168212890625, 76.126513671875],
              [-119.249267578125, 76.15947265625],
              [-119.367919921875, 76.22177734375],
              [-119.44780273437499, 76.275390625],
              [-119.48881835937499, 76.3203125],
              [-119.52373046874999, 76.340283203125],
              [-119.58037109374999, 76.326513671875],
              [-119.64892578125, 76.2798828125],
              [-119.65078125, 76.243701171875],
              [-119.63583984375, 76.189892578125],
              [-119.6396484375, 76.156689453125],
              [-119.7396484375, 76.117724609375],
              [-119.7251953125, 76.099951171875],
              [-119.54970703125, 76.05205078125],
              [-119.52714843749999, 76.03056640625],
              [-119.526123046875, 75.997216796875],
              [-119.537744140625, 75.982177734375],
              [-119.60795898437499, 75.9845703125],
              [-119.667138671875, 75.94599609375],
              [-119.734814453125, 75.9154296875],
              [-119.91289062499999, 75.858837890625],
              [-120.160546875, 75.851953125],
              [-120.365380859375, 75.824755859375],
              [-120.40888671875, 75.825634765625],
              [-120.45830078124999, 75.870166015625],
              [-120.513818359375, 75.958349609375],
              [-120.56328124999999, 76.008447265625],
              [-120.637158203125, 76.034033203125],
              [-120.728662109375, 76.13408203125],
              [-120.77158203125, 76.16630859375],
              [-120.81337890625, 76.179296875],
              [-120.848388671875, 76.182666015625],
              [-120.90009765625, 76.163427734375],
              [-121.019287109375, 76.020263671875],
              [-121.2134765625, 75.98369140625],
              [-121.320166015625, 75.977001953125],
              [-121.427978515625, 75.981103515625],
              [-121.69453125, 76.0203125],
              [-121.908203125, 76.034765625],
              [-122.05742187499999, 76.018212890625],
              [-122.302734375, 75.959814453125],
              [-122.40048828125, 75.94423828125],
              [-122.533056640625, 75.950927734375],
              [-122.59111328124999, 75.972998046875],
              [-122.640478515625, 76.00908203125],
              [-122.645947265625, 76.031005859375],
              [-122.607373046875, 76.038671875],
              [-122.546240234375, 76.080517578125],
              [-122.548193359375, 76.097314453125],
              [-122.608642578125, 76.121435546875],
              [-122.60947265625, 76.140283203125],
              [-122.587890625, 76.152978515625],
              [-122.592724609375, 76.162060546875],
              [-122.62392578125, 76.16748046875],
              [-122.68466796875, 76.16240234375],
              [-122.902783203125, 76.134716796875],
              [-122.878271484375, 76.164794921875],
              [-122.7740234375, 76.227685546875],
              [-122.519384765625, 76.353173828125],
              [-122.423046875, 76.390087890625],
              [-122.365380859375, 76.401220703125],
              [-121.61376953125, 76.441455078125],
              [-121.5611328125, 76.453466796875],
              [-121.20390624999999, 76.62216796875],
              [-121.102001953125, 76.6607421875],
              [-120.99760742187499, 76.691455078125],
              [-120.4853515625, 76.793212890625],
              [-120.43759765625, 76.816455078125],
              [-120.357666015625, 76.8869140625],
              [-120.3109375, 76.90458984375],
              [-120.200341796875, 76.93134765625],
              [-119.83115234375, 77.073876953125],
              [-119.49482421875, 77.176904296875],
              [-119.323974609375, 77.240673828125],
              [-119.090185546875, 77.305078125],
              [-118.82001953125, 77.33271484375],
              [-118.00517578124999, 77.381201171875],
              [-117.418603515625, 77.3173828125],
              [-117.27910156249999, 77.31337890625],
              [-117.210791015625, 77.3314453125],
              [-117.14897460937499, 77.36083984375],
              [-117.061376953125, 77.348486328125],
              [-116.84355468749999, 77.33955078125],
              [-116.7953125, 77.34658203125],
              [-116.70361328125, 77.379931640625],
              [-116.766259765625, 77.3982421875],
              [-117.02978515625, 77.431884765625],
              [-117.045751953125, 77.448974609375],
              [-117.03974609375, 77.46513671875],
              [-116.94716796875, 77.503857421875],
              [-116.835302734375, 77.528857421875],
              [-116.511328125, 77.547607421875],
              [-116.36259765624999, 77.542822265625],
              [-116.20888671875, 77.516015625],
              [-116.0080078125, 77.46064453125],
              [-115.55126953125, 77.36328125],
            ],
          ],
          [
            [
              [-108.2923828125, 76.05712890625],
              [-108.16630859375, 76.054296875],
              [-108.01875, 76.065234375],
              [-107.852294921875, 76.05771484375],
              [-107.77685546875, 76.035302734375],
              [-107.72348632812499, 75.99541015625],
              [-107.72128906249999, 75.9740234375],
              [-107.7318359375, 75.955615234375],
              [-107.75517578125, 75.940283203125],
              [-107.97041015625, 75.839599609375],
              [-108.020703125, 75.80478515625],
              [-107.95107421875, 75.7962890625],
              [-107.917529296875, 75.8021484375],
              [-107.702587890625, 75.877587890625],
              [-107.54091796875, 75.901171875],
              [-107.41826171875, 75.906591796875],
              [-107.2162109375, 75.891552734375],
              [-107.135693359375, 75.878564453125],
              [-107.08041992187499, 75.86318359375],
              [-107.050390625, 75.84541015625],
              [-106.970947265625, 75.773095703125],
              [-106.913525390625, 75.679638671875],
              [-106.90419921875, 75.6892578125],
              [-106.902783203125, 75.741650390625],
              [-106.89169921874999, 75.782421875],
              [-106.693115234375, 75.8099609375],
              [-106.68808593749999, 75.81904296875],
              [-106.759375, 75.8416015625],
              [-106.820068359375, 75.872412109375],
              [-106.862060546875, 75.930078125],
              [-106.845654296875, 75.9515625],
              [-106.8041015625, 75.974658203125],
              [-106.677001953125, 76.02373046875],
              [-106.52861328125, 76.05302734375],
              [-106.39658203124999, 76.060107421875],
              [-105.904833984375, 76.008984375],
              [-105.711328125, 75.9669921875],
              [-105.63266601562499, 75.945361328125],
              [-105.604443359375, 75.929931640625],
              [-105.56328125, 75.8806640625],
              [-105.480908203125, 75.745654296875],
              [-105.4814453125, 75.70224609375],
              [-105.519482421875, 75.632373046875],
              [-105.67841796875, 75.5013671875],
              [-105.70263671875, 75.4125],
              [-105.86259765625, 75.191552734375],
              [-105.97197265625, 75.131494140625],
              [-106.09262695312499, 75.089453125],
              [-106.58823242187499, 75.0154296875],
              [-106.9611328125, 74.940087890625],
              [-107.055615234375, 74.928173828125],
              [-107.15341796874999, 74.9271484375],
              [-107.4619140625, 74.9521484375],
              [-107.820068359375, 75.000048828125],
              [-108.0236328125, 74.986474609375],
              [-108.226611328125, 74.951904296875],
              [-108.354443359375, 74.942626953125],
              [-108.474755859375, 74.947216796875],
              [-108.594189453125, 74.9595703125],
              [-108.75131835937499, 74.991943359375],
              [-108.67026367187499, 75.00673828125],
              [-108.63330078125, 75.023291015625],
              [-108.666015625, 75.04033203125],
              [-108.831298828125, 75.064892578125],
              [-109.00253906249999, 75.010302734375],
              [-109.503125, 74.882763671875],
              [-110.17578125, 74.839990234375],
              [-110.38671875, 74.81396484375],
              [-110.543408203125, 74.78037109375],
              [-110.624755859375, 74.752685546875],
              [-110.74931640625, 74.6876953125],
              [-110.940869140625, 74.638720703125],
              [-111.287548828125, 74.58515625],
              [-111.7287109375, 74.501953125],
              [-112.5193359375, 74.416845703125],
              [-113.01689453125, 74.401904296875],
              [-113.5140625, 74.430078125],
              [-113.67158203125, 74.45302734375],
              [-113.83681640625, 74.48896484375],
              [-114.174755859375, 74.57373046875],
              [-114.26826171875, 74.604345703125],
              [-114.376953125, 74.670849609375],
              [-114.3126953125, 74.715087890625],
              [-114.132470703125, 74.76611328125],
              [-113.862890625, 74.812548828125],
              [-113.32431640624999, 74.87529296875],
              [-112.835986328125, 74.9755859375],
              [-112.663037109375, 74.99443359375],
              [-112.192822265625, 75.009765625],
              [-111.95576171875, 75.000390625],
              [-111.784228515625, 75.0056640625],
              [-111.67109375, 75.01943359375],
              [-111.50327148437499, 75.055615234375],
              [-111.257958984375, 75.127734375],
              [-111.07890625, 75.19521484375],
              [-111.03349609375, 75.2267578125],
              [-111.09345703125, 75.256298828125],
              [-111.181201171875, 75.26044921875],
              [-111.47392578125, 75.19111328125],
              [-111.620849609375, 75.1677734375],
              [-111.780859375, 75.166162109375],
              [-112.00048828125, 75.142431640625],
              [-112.21416015625, 75.13291015625],
              [-112.255517578125, 75.13369140625],
              [-112.478076171875, 75.2],
              [-112.5970703125, 75.211669921875],
              [-112.652392578125, 75.2046875],
              [-112.703125, 75.187158203125],
              [-112.799609375, 75.13818359375],
              [-112.85532226562499, 75.12060546875],
              [-112.9513671875, 75.1078125],
              [-113.3396484375, 75.09326171875],
              [-113.71176757812499, 75.068603515625],
              [-113.79462890625, 75.083837890625],
              [-113.844970703125, 75.11220703125],
              [-113.85537109375, 75.1294921875],
              [-113.8609375, 75.187744140625],
              [-113.88603515625, 75.2109375],
              [-113.8533203125, 75.259375],
              [-113.81088867187499, 75.296337890625],
              [-113.7587890625, 75.321728515625],
              [-113.50302734374999, 75.3966796875],
              [-113.46708984375, 75.41611328125],
              [-113.58896484374999, 75.412109375],
              [-113.878515625, 75.375439453125],
              [-113.91635742187499, 75.38818359375],
              [-113.984130859375, 75.430078125],
              [-114.01650390625, 75.43427734375],
              [-114.05341796875, 75.41689453125],
              [-114.07490234375, 75.3923828125],
              [-114.124658203125, 75.2912109375],
              [-114.16845703125, 75.239501953125],
              [-114.2849609375, 75.249951171875],
              [-114.42900390625, 75.28115234375],
              [-114.4828125, 75.285400390625],
              [-114.513818359375, 75.27548828125],
              [-114.503955078125, 75.2580078125],
              [-114.35776367187499, 75.1712890625],
              [-114.356103515625, 75.140966796875],
              [-114.45175781249999, 75.087890625],
              [-114.8591796875, 74.999755859375],
              [-115.02011718749999, 74.976171875],
              [-115.07705078125, 74.985302734375],
              [-115.1283203125, 75.00947265625],
              [-115.173828125, 75.048828125],
              [-115.27963867187499, 75.1015625],
              [-115.34262695312499, 75.11337890625],
              [-115.41318359374999, 75.114990234375],
              [-115.478076171875, 75.1041015625],
              [-115.5373046875, 75.080712890625],
              [-115.57407226562499, 75.055859375],
              [-115.608984375, 75.0095703125],
              [-115.683154296875, 74.974169921875],
              [-115.72885742187499, 74.968115234375],
              [-116.14262695312499, 75.041552734375],
              [-116.47607421875, 75.17177734375],
              [-116.841015625, 75.151513671875],
              [-117.004833984375, 75.156103515625],
              [-117.501953125, 75.203857421875],
              [-117.56523437499999, 75.233349609375],
              [-117.60009765625, 75.2716796875],
              [-117.59672851562499, 75.292529296875],
              [-117.57607421875, 75.3140625],
              [-117.513134765625, 75.356787109375],
              [-117.38779296875, 75.421484375],
              [-117.335546875, 75.442333984375],
              [-117.25761718749999, 75.459521484375],
              [-117.15419921875, 75.472998046875],
              [-116.890771484375, 75.480517578125],
              [-116.212744140625, 75.482958984375],
              [-116.0771484375, 75.49296875],
              [-115.3353515625, 75.61806640625],
              [-115.25068359375, 75.63857421875],
              [-115.141845703125, 75.678515625],
              [-115.117236328125, 75.69501953125],
              [-115.121875, 75.705810546875],
              [-116.034326171875, 75.606689453125],
              [-116.425634765625, 75.5853515625],
              [-117.02519531249999, 75.601513671875],
              [-117.137939453125, 75.617138671875],
              [-117.16362304687499, 75.644873046875],
              [-117.03857421875, 75.718408203125],
              [-116.97265625, 75.745751953125],
              [-116.8021484375, 75.77158203125],
              [-116.3896484375, 75.808203125],
              [-115.8380859375, 75.840576171875],
              [-115.47685546875, 75.84130859375],
              [-115.17373046875, 75.8669921875],
              [-114.99150390625, 75.896337890625],
              [-115.60224609375, 75.89482421875],
              [-116.337890625, 75.8810546875],
              [-116.44423828125, 75.890625],
              [-116.654296875, 75.929296875],
              [-116.66455078125, 75.957568359375],
              [-116.58046875, 75.991552734375],
              [-116.549658203125, 76.016845703125],
              [-116.609765625, 76.07373046875],
              [-116.59130859375, 76.09580078125],
              [-116.454248046875, 76.143212890625],
              [-116.20986328125, 76.19443359375],
              [-116.05913085937499, 76.201708984375],
              [-115.76826171875, 76.184228515625],
              [-114.939404296875, 76.16611328125],
              [-114.77861328125, 76.172607421875],
              [-114.88017578125, 76.194873046875],
              [-115.024560546875, 76.211474609375],
              [-115.664453125, 76.23984375],
              [-115.796875, 76.2525390625],
              [-115.82216796875, 76.27001953125],
              [-115.83173828125, 76.29580078125],
              [-115.8255859375, 76.329833984375],
              [-115.779296875, 76.364697265625],
              [-115.58066406249999, 76.4375],
              [-114.998486328125, 76.4974609375],
              [-114.766845703125, 76.505712890625],
              [-114.534765625, 76.5017578125],
              [-114.298974609375, 76.4748046875],
              [-114.19394531249999, 76.45146484375],
              [-114.141259765625, 76.42265625],
              [-114.115771484375, 76.395849609375],
              [-114.11279296875, 76.349462890625],
              [-114.10146484375, 76.331201171875],
              [-114.058837890625, 76.300732421875],
              [-113.923291015625, 76.229150390625],
              [-113.823486328125, 76.2068359375],
              [-113.36298828125, 76.2484375],
              [-113.1712890625, 76.257763671875],
              [-112.978466796875, 76.244677734375],
              [-112.69760742187499, 76.201708984375],
              [-112.33388671875, 76.071875],
              [-111.865234375, 75.939306640625],
              [-111.867626953125, 75.9107421875],
              [-112.04716796875, 75.86640625],
              [-112.08090820312499, 75.847412109375],
              [-112.056689453125, 75.834228515625],
              [-111.877392578125, 75.825537109375],
              [-111.709375, 75.832080078125],
              [-111.54912109375, 75.822119140625],
              [-111.513232421875, 75.810693359375],
              [-111.45444335937499, 75.762158203125],
              [-111.37275390625, 75.67646484375],
              [-111.27568359375, 75.6125],
              [-111.16328125, 75.57021484375],
              [-111.052685546875, 75.54853515625],
              [-110.889599609375, 75.546923828125],
              [-110.7255859375, 75.559521484375],
              [-110.459375, 75.555322265625],
              [-109.086376953125, 75.506494140625],
              [-109.005029296875, 75.514990234375],
              [-108.94716796875, 75.541796875],
              [-108.91259765625, 75.586962890625],
              [-108.89951171874999, 75.624072265625],
              [-108.918212890625, 75.674755859375],
              [-108.944775390625, 75.698974609375],
              [-109.796044921875, 75.863037109375],
              [-109.8705078125, 75.929052734375],
              [-109.454638671875, 76.021240234375],
              [-109.424755859375, 76.042529296875],
              [-109.41660156249999, 76.071826171875],
              [-109.43037109375, 76.109130859375],
              [-109.48681640625, 76.144677734375],
              [-109.71015625, 76.212451171875],
              [-109.9078125, 76.22265625],
              [-110.20078125, 76.289453125],
              [-110.247021484375, 76.30634765625],
              [-110.28486328125, 76.332958984375],
              [-110.314453125, 76.369384765625],
              [-110.30947265625, 76.397412109375],
              [-110.27001953125, 76.4169921875],
              [-109.981591796875, 76.484765625],
              [-109.86484375, 76.52236328125],
              [-109.505029296875, 76.691650390625],
              [-109.338525390625, 76.7599609375],
              [-109.21953125, 76.7919921875],
              [-109.09824218749999, 76.811865234375],
              [-108.831640625, 76.821142578125],
              [-108.55390625, 76.758056640625],
              [-108.4923828125, 76.75419921875],
              [-108.4669921875, 76.73759765625],
              [-108.477783203125, 76.708251953125],
              [-108.512451171875, 76.6802734375],
              [-108.61181640625, 76.629736328125],
              [-108.63515625, 76.608544921875],
              [-108.62763671875, 76.58671875],
              [-108.559521484375, 76.536328125],
              [-108.538623046875, 76.503125],
              [-108.52353515624999, 76.44716796875],
              [-108.5125, 76.438916015625],
              [-108.345458984375, 76.391650390625],
              [-108.1935546875, 76.330078125],
              [-108.123193359375, 76.233447265625],
              [-108.1779296875, 76.200048828125],
              [-108.305810546875, 76.154052734375],
              [-108.381884765625, 76.11572265625],
              [-108.40615234375, 76.08505859375],
              [-108.38681640624999, 76.066552734375],
              [-108.2923828125, 76.05712890625],
            ],
          ],
          [
            [
              [-114.521533203125, 72.592919921875],
              [-114.45810546874999, 72.58037109375],
              [-114.342431640625, 72.590771484375],
              [-114.174462890625, 72.624072265625],
              [-113.9578125, 72.65146484375],
              [-113.692431640625, 72.672802734375],
              [-113.62216796874999, 72.646826171875],
              [-113.578076171875, 72.652099609375],
              [-113.500048828125, 72.69443359375],
              [-113.4861328125, 72.722265625],
              [-113.4958984375, 72.753662109375],
              [-113.49140625, 72.8220703125],
              [-113.449853515625, 72.863232421875],
              [-113.2923828125, 72.9498046875],
              [-113.2080078125, 72.981005859375],
              [-113.07353515625, 72.995263671875],
              [-112.75361328125, 72.98603515625],
              [-112.453759765625, 72.93662109375],
              [-112.048095703125, 72.888037109375],
              [-111.45541992187499, 72.76591796875],
              [-111.2697265625, 72.713720703125],
              [-111.250390625, 72.6685546875],
              [-111.355517578125, 72.572119140625],
              [-111.610888671875, 72.435595703125],
              [-111.816015625, 72.386328125],
              [-111.895166015625, 72.356103515625],
              [-111.76162109375, 72.33525390625],
              [-111.67509765625, 72.300146484375],
              [-111.54355468749999, 72.350927734375],
              [-111.44736328124999, 72.40771484375],
              [-111.311181640625, 72.454833984375],
              [-111.264794921875, 72.459033203125],
              [-111.25341796875, 72.449072265625],
              [-111.2771484375, 72.424853515625],
              [-111.287255859375, 72.401123046875],
              [-111.283740234375, 72.377978515625],
              [-111.26806640625, 72.3638671875],
              [-111.184619140625, 72.356640625],
              [-111.139892578125, 72.36533203125],
              [-110.958984375, 72.431982421875],
              [-110.78154296875, 72.53388671875],
              [-110.51254882812499, 72.59970703125],
              [-110.43930664062499, 72.633349609375],
              [-110.205126953125, 72.661279296875],
              [-110.207958984375, 72.6810546875],
              [-110.19716796875, 72.75888671875],
              [-110.2791015625, 72.792041015625],
              [-110.55361328125, 72.86142578125],
              [-110.689404296875, 72.94453125],
              [-110.66083984375, 73.008203125],
              [-110.50927734375, 72.99892578125],
              [-110.09462890625, 72.992138671875],
              [-110.008447265625, 72.983642578125],
              [-109.60996093749999, 72.87568359375],
              [-109.46909179687499, 72.808447265625],
              [-109.35712890625, 72.775048828125],
              [-109.12192382812499, 72.726416015625],
              [-109.043017578125, 72.686865234375],
              [-108.98740234374999, 72.67080078125],
              [-108.9681640625, 72.6541015625],
              [-108.985400390625, 72.63681640625],
              [-108.99443359375, 72.59599609375],
              [-108.95078125, 72.582861328125],
              [-108.7978515625, 72.567529296875],
              [-108.75498046874999, 72.55107421875],
              [-108.698291015625, 72.499267578125],
              [-108.627734375, 72.41201171875],
              [-108.566357421875, 72.317333984375],
              [-108.46958007812499, 72.13876953125],
              [-108.276416015625, 71.900390625],
              [-108.210400390625, 71.751171875],
              [-108.188232421875, 71.723779296875],
              [-108.144677734375, 71.704931640625],
              [-108.02080078125, 71.677490234375],
              [-107.925341796875, 71.638671875],
              [-107.81284179687499, 71.626171875],
              [-107.78544921875, 71.6296875],
              [-107.757470703125, 71.663037109375],
              [-107.687255859375, 71.71611328125],
              [-107.346923828125, 71.81923828125],
              [-107.329296875, 71.83525390625],
              [-107.36943359375, 71.858984375],
              [-107.381787109375, 71.875146484375],
              [-107.37685546875, 71.886083984375],
              [-107.30600585937499, 71.894677734375],
              [-107.542626953125, 72.025341796875],
              [-107.695849609375, 72.14931640625],
              [-107.79404296874999, 72.30263671875],
              [-107.809033203125, 72.3474609375],
              [-107.82373046875, 72.4427734375],
              [-107.855615234375, 72.467822265625],
              [-107.909814453125, 72.490771484375],
              [-107.93251953125, 72.52041015625],
              [-107.92368164062499, 72.556640625],
              [-107.934375, 72.587744140625],
              [-107.99716796874999, 72.652685546875],
              [-108.238232421875, 73.105810546875],
              [-108.23740234374999, 73.14990234375],
              [-108.204150390625, 73.183056640625],
              [-108.118310546875, 73.20205078125],
              [-107.979931640625, 73.20673828125],
              [-107.936181640625, 73.217138671875],
              [-107.987060546875, 73.23310546875],
              [-108.07749023437499, 73.281396484375],
              [-108.089404296875, 73.3037109375],
              [-108.029052734375, 73.34873046875],
              [-107.72001953125, 73.329052734375],
              [-107.4962890625, 73.28837890625],
              [-107.1134765625, 73.192138671875],
              [-107.0744140625, 73.197412109375],
              [-107.03251953125, 73.2453125],
              [-106.95078125, 73.276025390625],
              [-106.828369140625, 73.26591796875],
              [-106.48212890625, 73.19619140625],
              [-106.081640625, 73.071923828125],
              [-105.8126953125, 73.01064453125],
              [-105.624169921875, 72.927490234375],
              [-105.49594726562499, 72.848974609375],
              [-105.41513671874999, 72.788330078125],
              [-105.411669921875, 72.7646484375],
              [-105.430078125, 72.740380859375],
              [-105.411083984375, 72.708740234375],
              [-105.354541015625, 72.6697265625],
              [-105.323193359375, 72.634814453125],
              [-105.29755859375, 72.56044921875],
              [-105.24692382812499, 72.46357421875],
              [-105.23408203125, 72.415087890625],
              [-104.8783203125, 71.97998046875],
              [-104.810302734375, 71.903173828125],
              [-104.7669921875, 71.867578125],
              [-104.518310546875, 71.69921875],
              [-104.3859375, 71.576953125],
              [-104.37314453124999, 71.4951171875],
              [-104.35537109375, 71.4716796875],
              [-104.349560546875, 71.433984375],
              [-104.35581054687499, 71.382080078125],
              [-104.38486328124999, 71.337548828125],
              [-104.43681640625, 71.30029296875],
              [-104.487060546875, 71.247900390625],
              [-104.56308593749999, 71.132421875],
              [-104.569580078125, 71.104052734375],
              [-104.514794921875, 71.0642578125],
              [-104.16684570312499, 70.927197265625],
              [-103.95346679687499, 70.762646484375],
              [-103.853466796875, 70.7337890625],
              [-103.58457031249999, 70.630859375],
              [-103.294677734375, 70.5724609375],
              [-103.19716796875, 70.547314453125],
              [-103.10498046875, 70.51025390625],
              [-103.07719726562499, 70.508837890625],
              [-103.02119140625, 70.5158203125],
              [-103.00517578124999, 70.525927734375],
              [-103.001220703125, 70.540966796875],
              [-103.08281249999999, 70.619091796875],
              [-103.08857421875, 70.64970703125],
              [-103.049560546875, 70.655078125],
              [-102.75048828125, 70.521875],
              [-102.58916015624999, 70.46884765625],
              [-102.36875, 70.413232421875],
              [-101.98984375, 70.28505859375],
              [-101.93720703125, 70.274560546875],
              [-101.73222656249999, 70.286376953125],
              [-101.67631835937499, 70.278271484375],
              [-101.64116210937499, 70.265576171875],
              [-101.62680664062499, 70.24833984375],
              [-101.61845703124999, 70.172412109375],
              [-101.56240234375, 70.135009765625],
              [-101.23916015625, 70.1509765625],
              [-101.14853515624999, 70.147607421875],
              [-101.090771484375, 70.135693359375],
              [-101.04267578125, 70.110791015625],
              [-100.97333984375, 70.0294921875],
              [-100.90908203125, 69.869189453125],
              [-100.905712890625, 69.81171875],
              [-100.93510742187499, 69.71533203125],
              [-100.982373046875, 69.6798828125],
              [-101.043701171875, 69.668701171875],
              [-101.2162109375, 69.679638671875],
              [-101.33725585937499, 69.71025390625],
              [-101.40009765625, 69.749267578125],
              [-101.45673828125, 69.83388671875],
              [-101.483837890625, 69.8501953125],
              [-101.50839843749999, 69.833154296875],
              [-101.565087890625, 69.7556640625],
              [-101.602490234375, 69.7212890625],
              [-101.64765625, 69.69853515625],
              [-101.73359375, 69.704150390625],
              [-101.86025390625, 69.7380859375],
              [-102.09794921874999, 69.824609375],
              [-102.18212890625, 69.845947265625],
              [-102.234326171875, 69.842236328125],
              [-102.348095703125, 69.81298828125],
              [-102.52348632812499, 69.758203125],
              [-102.5958984375, 69.717919921875],
              [-102.56523437499999, 69.6921875],
              [-102.544921875, 69.659814453125],
              [-102.53486328125, 69.62080078125],
              [-102.54091796875, 69.59208984375],
              [-102.563134765625, 69.573583984375],
              [-102.62109375, 69.551513671875],
              [-102.743603515625, 69.54775390625],
              [-102.91977539062499, 69.5646484375],
              [-103.05917968749999, 69.594677734375],
              [-103.30322265625, 69.67431640625],
              [-103.35927734375, 69.6853515625],
              [-103.434765625, 69.66767578125],
              [-103.464892578125, 69.644482421875],
              [-103.418017578125, 69.61142578125],
              [-103.29404296874999, 69.56845703125],
              [-103.14243164062499, 69.497265625],
              [-103.10185546874999, 69.483349609375],
              [-103.062744140625, 69.484912109375],
              [-103.04892578124999, 69.47177734375],
              [-103.03183593749999, 69.43349609375],
              [-103.039794921875, 69.367578125],
              [-103.1126953125, 69.235986328125],
              [-103.12021484374999, 69.20458984375],
              [-103.09033203125, 69.21201171875],
              [-102.88408203124999, 69.34130859375],
              [-102.77744140624999, 69.377587890625],
              [-102.546484375, 69.43447265625],
              [-102.44677734375, 69.476318359375],
              [-102.15141601562499, 69.4876953125],
              [-102.04594726562499, 69.46484375],
              [-101.97822265625, 69.42509765625],
              [-101.975537109375, 69.40703125],
              [-102.052880859375, 69.36044921875],
              [-102.06689453125, 69.337109375],
              [-102.07089843749999, 69.3076171875],
              [-102.06401367187499, 69.28115234375],
              [-102.04609375, 69.257666015625],
              [-101.99296874999999, 69.23603515625],
              [-101.89912109375, 69.2455078125],
              [-101.87285156249999, 69.23994140625],
              [-101.822509765625, 69.21708984375],
              [-101.7892578125, 69.181640625],
              [-101.78779296875, 69.132275390625],
              [-101.85712890625, 69.023974609375],
              [-101.98056640624999, 68.988525390625],
              [-102.3587890625, 68.9228515625],
              [-102.488427734375, 68.888916015625],
              [-102.73833007812499, 68.864990234375],
              [-102.83486328125, 68.833251953125],
              [-102.89506835937499, 68.8236328125],
              [-103.162255859375, 68.8287109375],
              [-103.468212890625, 68.808544921875],
              [-103.820361328125, 68.847998046875],
              [-104.067333984375, 68.865576171875],
              [-104.352685546875, 68.928173828125],
              [-104.46015625, 68.91240234375],
              [-104.571435546875, 68.872119140625],
              [-105.105859375, 68.92041015625],
              [-105.16928710937499, 68.95537109375],
              [-105.14833984375, 68.978125],
              [-105.021630859375, 69.052490234375],
              [-105.01357421875, 69.06806640625],
              [-105.019580078125, 69.08125],
              [-105.262353515625, 69.093994140625],
              [-105.5330078125, 69.133544921875],
              [-105.80498046875, 69.153173828125],
              [-106.00839843749999, 69.147607421875],
              [-106.140869140625, 69.16201171875],
              [-106.270166015625, 69.194580078125],
              [-106.34116210937499, 69.224365234375],
              [-106.353955078125, 69.251220703125],
              [-106.355712890625, 69.280615234375],
              [-106.34423828125, 69.3396484375],
              [-106.361376953125, 69.3810546875],
              [-106.419970703125, 69.41376953125],
              [-106.539794921875, 69.44306640625],
              [-106.65908203125, 69.439599609375],
              [-106.7599609375, 69.40712890625],
              [-106.85581054687499, 69.347314453125],
              [-107.033447265625, 69.18076171875],
              [-107.122509765625, 69.152294921875],
              [-107.35336914062499, 69.031689453125],
              [-107.439892578125, 69.0021484375],
              [-107.86337890624999, 68.954345703125],
              [-108.364990234375, 68.934765625],
              [-108.5525390625, 68.897412109375],
              [-108.730419921875, 68.82744140625],
              [-108.94589843749999, 68.759814453125],
              [-109.472119140625, 68.676708984375],
              [-109.958544921875, 68.6302734375],
              [-110.46762695312499, 68.610009765625],
              [-110.848095703125, 68.57841796875],
              [-110.9572265625, 68.594189453125],
              [-111.127587890625, 68.588330078125],
              [-111.3109375, 68.542041015625],
              [-111.51806640625, 68.533056640625],
              [-112.304931640625, 68.5162109375],
              [-112.6662109375, 68.48525390625],
              [-112.8642578125, 68.477099609375],
              [-113.01953125, 68.48134765625],
              [-113.127734375, 68.494140625],
              [-113.231396484375, 68.535400390625],
              [-113.3380859375, 68.598779296875],
              [-113.554833984375, 68.767578125],
              [-113.616845703125, 68.8384765625],
              [-113.592529296875, 68.95986328125],
              [-113.608544921875, 69.03017578125],
              [-113.6806640625, 69.181982421875],
              [-113.694140625, 69.19501953125],
              [-114.0734375, 69.251318359375],
              [-114.32294921875, 69.269140625],
              [-114.69907226562499, 69.27275390625],
              [-115.15903320312499, 69.26474609375],
              [-115.618115234375, 69.282958984375],
              [-115.8607421875, 69.303564453125],
              [-116.1015625, 69.337158203125],
              [-116.51347656249999, 69.424609375],
              [-116.53681640625, 69.433544921875],
              [-116.568798828125, 69.4626953125],
              [-116.60947265625, 69.51201171875],
              [-116.71201171875, 69.576220703125],
              [-116.9927734375, 69.719384765625],
              [-117.10400390625, 69.804248046875],
              [-117.12197265625, 69.82587890625],
              [-117.1486328125, 69.888134765625],
              [-117.184033203125, 69.991064453125],
              [-117.19541015625, 70.054052734375],
              [-117.162744140625, 70.09248046875],
              [-117.13544921875, 70.100146484375],
              [-116.55380859375, 70.175048828125],
              [-115.5291015625, 70.25712890625],
              [-114.592333984375, 70.312451171875],
              [-114.1669921875, 70.307470703125],
              [-113.91660156249999, 70.28154296875],
              [-113.66552734375, 70.269677734375],
              [-113.2107421875, 70.263818359375],
              [-112.637890625, 70.225244140625],
              [-112.52275390625, 70.228564453125],
              [-112.26596679687499, 70.2546875],
              [-112.1896484375, 70.2755859375],
              [-111.78369140625, 70.272900390625],
              [-111.7048828125, 70.2857421875],
              [-111.632568359375, 70.308837890625],
              [-111.725830078125, 70.35205078125],
              [-112.11416015625, 70.446875],
              [-113.1455078125, 70.616357421875],
              [-113.397021484375, 70.652392578125],
              [-113.757275390625, 70.69072265625],
              [-113.966064453125, 70.69619140625],
              [-114.232177734375, 70.674267578125],
              [-114.33139648437499, 70.675244140625],
              [-114.59262695312499, 70.642236328125],
              [-114.84072265625, 70.62138671875],
              [-115.31123046875, 70.601171875],
              [-115.99091796875, 70.586279296875],
              [-116.086083984375, 70.590673828125],
              [-116.22587890624999, 70.61640625],
              [-116.327294921875, 70.62373046875],
              [-116.992529296875, 70.603662109375],
              [-117.587060546875, 70.629541015625],
              [-118.2640625, 70.888330078125],
              [-118.37651367187499, 70.967724609375],
              [-118.3525390625, 71.000048828125],
              [-118.26909179687499, 71.034716796875],
              [-117.933837890625, 71.13466796875],
              [-117.8140625, 71.158447265625],
              [-117.31396484375, 71.212109375],
              [-116.815283203125, 71.276953125],
              [-116.421533203125, 71.33798828125],
              [-116.22822265625, 71.3591796875],
              [-116.04208984375, 71.361669921875],
              [-115.891650390625, 71.381787109375],
              [-115.922265625, 71.40107421875],
              [-116.0453125, 71.423095703125],
              [-116.0439453125, 71.454296875],
              [-115.9802734375, 71.469287109375],
              [-115.73374023437499, 71.485107421875],
              [-115.471875, 71.4658203125],
              [-115.341015625, 71.472412109375],
              [-115.30341796875, 71.493701171875],
              [-115.338134765625, 71.510888671875],
              [-115.586669921875, 71.54638671875],
              [-116.7802734375, 71.444189453125],
              [-117.337109375, 71.434619140625],
              [-117.72333984375, 71.390673828125],
              [-117.93564453125, 71.39208984375],
              [-118.18818359375, 71.4359375],
              [-118.221875, 71.449072265625],
              [-118.22646484375, 71.46708984375],
              [-118.14833984375, 71.525732421875],
              [-117.87841796875, 71.56083984375],
              [-117.742333984375, 71.659326171875],
              [-117.88759765625, 71.66103515625],
              [-118.371533203125, 71.63994140625],
              [-118.5830078125, 71.6490234375],
              [-118.868408203125, 71.686767578125],
              [-118.952099609375, 71.73173828125],
              [-118.9876953125, 71.7642578125],
              [-118.99375, 71.80302734375],
              [-118.98417968749999, 71.9130859375],
              [-118.959814453125, 71.972216796875],
              [-118.94462890624999, 71.985546875],
              [-118.58984375, 72.16748046875],
              [-118.36865234375, 72.20546875],
              [-118.2134765625, 72.262890625],
              [-118.207470703125, 72.2869140625],
              [-118.24589843749999, 72.31103515625],
              [-118.390478515625, 72.36953125],
              [-118.4486328125, 72.39921875],
              [-118.481298828125, 72.427685546875],
              [-118.45659179687499, 72.472509765625],
              [-118.37451171875, 72.53388671875],
              [-118.13310546874999, 72.6328125],
              [-117.551708984375, 72.831103515625],
              [-117.2564453125, 72.914404296875],
              [-116.9716796875, 72.959326171875],
              [-116.5732421875, 73.054931640625],
              [-115.552197265625, 73.2134765625],
              [-114.638232421875, 73.37265625],
              [-114.301904296875, 73.330712890625],
              [-114.20639648437499, 73.297802734375],
              [-114.16396484375, 73.26982421875],
              [-114.12705078124999, 73.230712890625],
              [-114.095458984375, 73.1802734375],
              [-114.051708984375, 73.07099609375],
              [-114.046142578125, 73.014599609375],
              [-114.053759765625, 72.958056640625],
              [-114.074755859375, 72.9068359375],
              [-114.1091796875, 72.860986328125],
              [-114.177685546875, 72.805078125],
              [-114.280322265625, 72.7390625],
              [-114.4978515625, 72.62587890625],
              [-114.521533203125, 72.592919921875],
            ],
          ],
          [
            [
              [-119.736328125, 74.112646484375],
              [-119.72856445312499, 74.108447265625],
              [-119.47109375, 74.201220703125],
              [-119.31484375, 74.20625],
              [-119.20595703125, 74.197998046875],
              [-119.17143554687499, 74.186181640625],
              [-119.149609375, 74.16787109375],
              [-119.13876953125, 74.127587890625],
              [-119.13188476562499, 74.027880859375],
              [-119.11796874999999, 74.01552734375],
              [-119.08251953125, 74.02119140625],
              [-119.02568359374999, 74.0447265625],
              [-118.744140625, 74.19208984375],
              [-118.62529296874999, 74.23251953125],
              [-118.54399414062499, 74.24462890625],
              [-118.199658203125, 74.266748046875],
              [-117.96586914062499, 74.266064453125],
              [-117.707470703125, 74.25234375],
              [-117.51484375, 74.23173828125],
              [-117.198828125, 74.171142578125],
              [-116.950390625, 74.101416015625],
              [-116.72236328125, 74.0271484375],
              [-115.95771484375, 73.74794921875],
              [-115.634326171875, 73.66552734375],
              [-115.510693359375, 73.61875],
              [-115.45566406249999, 73.58466796875],
              [-115.40751953125, 73.54189453125],
              [-115.392822265625, 73.501953125],
              [-115.411572265625, 73.464794921875],
              [-115.446875, 73.4388671875],
              [-115.524462890625, 73.416748046875],
              [-115.99228515624999, 73.3232421875],
              [-116.238623046875, 73.294580078125],
              [-116.48251953125, 73.25322265625],
              [-117.0654296875, 73.107275390625],
              [-117.464453125, 73.037744140625],
              [-117.98320312499999, 72.902197265625],
              [-118.961572265625, 72.684130859375],
              [-119.077978515625, 72.64033203125],
              [-119.13154296875, 72.608837890625],
              [-119.40776367187499, 72.360400390625],
              [-119.512841796875, 72.302685546875],
              [-119.76748046875, 72.24384765625],
              [-120.08974609375, 72.229150390625],
              [-120.1798828125, 72.212646484375],
              [-120.19443359375, 72.1267578125],
              [-120.310009765625, 71.98408203125],
              [-120.36625976562499, 71.888037109375],
              [-120.44316406249999, 71.630810546875],
              [-120.4609375, 71.605078125],
              [-120.519677734375, 71.557421875],
              [-120.6193359375, 71.50576171875],
              [-120.930322265625, 71.446240234375],
              [-121.159814453125, 71.414990234375],
              [-121.47216796875, 71.389013671875],
              [-121.546826171875, 71.406787109375],
              [-121.62216796875, 71.447607421875],
              [-121.70068359375, 71.451171875],
              [-121.749365234375, 71.444775390625],
              [-122.156640625, 71.26591796875],
              [-122.54951171875, 71.1935546875],
              [-122.71977539062499, 71.128173828125],
              [-122.83994140625, 71.0974609375],
              [-122.9365234375, 71.08798828125],
              [-123.095654296875, 71.093798828125],
              [-123.210595703125, 71.1234375],
              [-123.31474609374999, 71.169189453125],
              [-123.39335937499999, 71.21884765625],
              [-123.595166015625, 71.423193359375],
              [-123.6818359375, 71.493115234375],
              [-123.75556640625, 71.52802734375],
              [-123.953271484375, 71.652490234375],
              [-124.007763671875, 71.67744140625],
              [-124.7599609375, 71.83515625],
              [-125.126123046875, 71.9236328125],
              [-125.21464843749999, 71.95478515625],
              [-125.29667968749999, 71.973046875],
              [-125.76689453124999, 71.96083984375],
              [-125.8291015625, 71.965625],
              [-125.8453125, 71.978662109375],
              [-125.7896484375, 72.025],
              [-125.767724609375, 72.054248046875],
              [-125.760498046875, 72.08291015625],
              [-125.76860351562499, 72.129150390625],
              [-125.76259765625, 72.1375],
              [-125.58378906249999, 72.183056640625],
              [-125.61279296875, 72.192529296875],
              [-125.6337890625, 72.210302734375],
              [-125.64677734374999, 72.2365234375],
              [-125.627294921875, 72.254833984375],
              [-125.57548828124999, 72.265283203125],
              [-125.51240234375, 72.30771484375],
              [-125.4380859375, 72.382080078125],
              [-125.382763671875, 72.423828125],
              [-125.306005859375, 72.450732421875],
              [-125.168310546875, 72.522607421875],
              [-125.07021484375, 72.551611328125],
              [-124.98710937499999, 72.58798828125],
              [-124.98466796874999, 72.60439453125],
              [-125.0185546875, 72.6169921875],
              [-125.030224609375, 72.644775390625],
              [-125.01474609375, 72.7314453125],
              [-125.01542968749999, 72.77607421875],
              [-125.000390625, 72.813330078125],
              [-124.969677734375, 72.843310546875],
              [-124.930859375, 72.86318359375],
              [-124.58256835937499, 72.925927734375],
              [-124.56494140625, 72.944140625],
              [-124.56083984374999, 72.9650390625],
              [-124.57021484375, 72.988720703125],
              [-124.58828125, 73.005322265625],
              [-124.643310546875, 73.0189453125],
              [-124.73642578125, 73.022705078125],
              [-124.81708984375, 73.0587890625],
              [-124.83642578125, 73.07626953125],
              [-124.804052734375, 73.12568359375],
              [-124.646923828125, 73.204443359375],
              [-124.593994140625, 73.243310546875],
              [-124.42421875, 73.418701171875],
              [-124.11416015625, 73.527392578125],
              [-124.03017578125, 73.64423828125],
              [-123.797265625, 73.7681640625],
              [-123.797802734375, 73.785302734375],
              [-123.873046875, 73.827587890625],
              [-124.088037109375, 73.856884765625],
              [-124.19150390625, 73.902001953125],
              [-124.2607421875, 73.953271484375],
              [-124.575341796875, 74.24814453125],
              [-124.6291015625, 74.27001953125],
              [-124.64501953125, 74.304345703125],
              [-124.709326171875, 74.327001953125],
              [-124.696240234375, 74.348193359375],
              [-123.46831054687499, 74.4361328125],
              [-122.62314453125, 74.46416015625],
              [-121.747900390625, 74.540625],
              [-121.504150390625, 74.5451171875],
              [-121.31523437499999, 74.52998046875],
              [-121.12871093749999, 74.490234375],
              [-120.88164062499999, 74.420751953125],
              [-120.55449218749999, 74.3529296875],
              [-119.943603515625, 74.2537109375],
              [-119.562646484375, 74.2328125],
              [-119.71538085937499, 74.153662109375],
              [-119.7369140625, 74.129931640625],
              [-119.736328125, 74.112646484375],
            ],
          ],
          [
            [
              [-69.48886718749999, 83.016796875],
              [-68.6732421875, 82.998779296875],
              [-68.40903320312499, 83.0052734375],
              [-68.106884765625, 82.961181640625],
              [-67.92460937499999, 82.956005859375],
              [-67.624462890625, 82.964404296875],
              [-67.4056640625, 82.95390625],
              [-66.59165039062499, 82.94404296875],
              [-66.42255859375, 82.92685546875],
              [-66.4248046875, 82.90615234375],
              [-66.60039062499999, 82.86123046875],
              [-66.836328125, 82.817919921875],
              [-68.357568359375, 82.676806640625],
              [-68.46933593749999, 82.653369140625],
              [-68.1728515625, 82.645947265625],
              [-67.73588867187499, 82.65244140625],
              [-67.39707031249999, 82.668115234375],
              [-66.997705078125, 82.716064453125],
              [-66.86572265625, 82.71884765625],
              [-66.61186523437499, 82.74208984375],
              [-66.12045898437499, 82.80712890625],
              [-65.72744140625, 82.842431640625],
              [-65.54960937499999, 82.826953125],
              [-65.4, 82.802392578125],
              [-65.2990234375, 82.799609375],
              [-65.24658203125, 82.818505859375],
              [-65.16240234374999, 82.8701171875],
              [-65.11318359375, 82.888916015625],
              [-64.98388671875, 82.902294921875],
              [-64.90488281249999, 82.900830078125],
              [-64.7767578125, 82.87646484375],
              [-64.634765625, 82.818603515625],
              [-64.50400390624999, 82.77841796875],
              [-64.43339843749999, 82.777734375],
              [-64.134228515625, 82.823193359375],
              [-63.98359375, 82.8291015625],
              [-63.641015625, 82.81259765625],
              [-63.49873046875, 82.792578125],
              [-63.473046875, 82.771240234375],
              [-63.5640625, 82.74873046875],
              [-63.62060546875, 82.729296875],
              [-63.642529296875, 82.71298828125],
              [-63.592675781249994, 82.69404296875],
              [-63.385400390624994, 82.653466796875],
              [-63.085351562499994, 82.565234375],
              [-63.087060546874994, 82.5328125],
              [-63.25083007812499, 82.466845703125],
              [-63.24677734375, 82.4501953125],
              [-62.47519531249999, 82.519580078125],
              [-61.697167968749994, 82.488623046875],
              [-61.47705078125, 82.467431640625],
              [-61.392480468749994, 82.44189453125],
              [-61.302490234375, 82.399755859375],
              [-61.2072265625, 82.341064453125],
              [-61.27353515624999, 82.279833984375],
              [-61.615380859374994, 82.184423828125],
              [-61.968652343749994, 82.11025390625],
              [-62.176708984375, 82.043408203125],
              [-62.496484375, 82.006787109375],
              [-63.59228515625, 81.8455078125],
              [-64.1279296875, 81.79365234375],
              [-64.435791015625, 81.742626953125],
              [-64.57402343749999, 81.733740234375],
              [-65.226171875, 81.743505859375],
              [-65.399169921875, 81.715380859375],
              [-65.49541015624999, 81.66806640625],
              [-65.70107421875, 81.645556640625],
              [-66.00473632812499, 81.629443359375],
              [-66.625732421875, 81.61640625],
              [-66.7650390625, 81.563037109375],
              [-66.80058593749999, 81.526806640625],
              [-66.86113281249999, 81.498681640625],
              [-66.9140625, 81.485107421875],
              [-68.68852539062499, 81.293310546875],
              [-68.72119140625, 81.26123046875],
              [-68.542578125, 81.247998046875],
              [-68.31767578124999, 81.26123046875],
              [-65.73569335937499, 81.49423828125],
              [-65.239990234375, 81.50966796875],
              [-64.78007812499999, 81.49287109375],
              [-64.832763671875, 81.438623046875],
              [-65.483984375, 81.284765625],
              [-66.31284179687499, 81.146142578125],
              [-66.72685546874999, 81.04091796875],
              [-67.774365234375, 80.859423828125],
              [-68.63046875, 80.6787109375],
              [-68.959375, 80.586865234375],
              [-69.40009765625, 80.4228515625],
              [-69.55068359375, 80.383251953125],
              [-69.7337890625, 80.366943359375],
              [-69.94931640624999, 80.373779296875],
              [-70.143505859375, 80.39765625],
              [-70.40263671874999, 80.458984375],
              [-70.638671875, 80.5275390625],
              [-70.71259765625, 80.539599609375],
              [-70.66782226562499, 80.50556640625],
              [-70.21279296875, 80.277734375],
              [-70.264892578125, 80.23359375],
              [-71.10029296875, 80.187060546875],
              [-71.47006835937499, 80.1458984375],
              [-71.66083984375, 80.1359375],
              [-71.7958984375, 80.143359375],
              [-71.92763671875, 80.13916015625],
              [-72.05595703124999, 80.1232421875],
              [-72.06298828125, 80.10556640625],
              [-71.948681640625, 80.086181640625],
              [-71.61611328125, 80.071044921875],
              [-70.87705078124999, 80.122314453125],
              [-70.75849609375, 80.11865234375],
              [-70.56840820312499, 80.093701171875],
              [-70.55908203125, 80.07099609375],
              [-70.75751953125, 79.9982421875],
              [-71.35581054687499, 79.911279296875],
              [-71.27763671874999, 79.90634765625],
              [-71.10634765625, 79.875537109375],
              [-71.11015624999999, 79.847802734375],
              [-71.298583984375, 79.782568359375],
              [-71.387841796875, 79.761767578125],
              [-71.96455078125, 79.70107421875],
              [-72.21552734375, 79.68681640625],
              [-72.4365234375, 79.694384765625],
              [-73.44814453125, 79.827099609375],
              [-73.805078125, 79.8462890625],
              [-74.14423828125, 79.87978515625],
              [-74.394482421875, 79.874072265625],
              [-74.66020507812499, 79.83515625],
              [-74.54072265625, 79.815576171875],
              [-74.051025390625, 79.77822265625],
              [-73.64208984375, 79.77099609375],
              [-73.47246093749999, 79.7564453125],
              [-73.405908203125, 79.732177734375],
              [-73.22939453125, 79.643994140625],
              [-73.201123046875, 79.59658203125],
              [-73.24013671875, 79.552490234375],
              [-73.29355468749999, 79.52158203125],
              [-73.3615234375, 79.50400390625],
              [-73.466064453125, 79.495166015625],
              [-73.865966796875, 79.501416015625],
              [-74.015380859375, 79.49052734375],
              [-74.188671875, 79.46474609375],
              [-74.406005859375, 79.453564453125],
              [-74.79794921874999, 79.45869140625],
              [-75.25947265625, 79.421044921875],
              [-75.50341796875, 79.41416015625],
              [-75.773828125, 79.43115234375],
              [-76.06689453125, 79.473193359375],
              [-76.37607421874999, 79.49443359375],
              [-76.898828125, 79.5123046875],
              [-76.855078125, 79.488232421875],
              [-76.6708984375, 79.478076171875],
              [-76.29570312499999, 79.413623046875],
              [-76.116357421875, 79.326123046875],
              [-75.947509765625, 79.311328125],
              [-75.602734375, 79.23955078125],
              [-75.353662109375, 79.2283203125],
              [-75.09360351562499, 79.20390625],
              [-74.72724609375, 79.2353515625],
              [-74.481201171875, 79.2294921875],
              [-74.53232421874999, 79.052734375],
              [-74.64091796874999, 79.035546875],
              [-75.233154296875, 79.035546875],
              [-75.5146484375, 79.06123046875],
              [-75.63896484374999, 79.087744140625],
              [-75.91181640625, 79.1177734375],
              [-76.15756835937499, 79.100390625],
              [-76.38037109375, 79.104150390625],
              [-76.5314453125, 79.0865234375],
              [-76.771142578125, 79.087158203125],
              [-77.398046875, 79.057275390625],
              [-77.729248046875, 79.05693359375],
              [-77.973779296875, 79.076220703125],
              [-78.25791015624999, 79.082177734375],
              [-78.581640625, 79.075],
              [-78.558984375, 79.05458984375],
              [-78.42177734375, 79.048388671875],
              [-78.22197265624999, 79.01513671875],
              [-78.03681640625, 78.963916015625],
              [-77.882763671875, 78.9423828125],
              [-77.6982421875, 78.954541015625],
              [-77.510400390625, 78.978466796875],
              [-76.82480468749999, 79.01787109375],
              [-76.52412109375, 79.02421875],
              [-76.255859375, 79.0068359375],
              [-76.07734375, 78.98515625],
              [-75.95268554687499, 78.959033203125],
              [-75.795068359375, 78.88974609375],
              [-75.399853515625, 78.881298828125],
              [-75.09853515625, 78.85830078125],
              [-74.618408203125, 78.75771484375],
              [-74.486328125, 78.75009765625],
              [-74.43310546875, 78.72412109375],
              [-74.53505859375, 78.65927734375],
              [-74.54658203125, 78.6203125],
              [-74.87861328125, 78.54482421875],
              [-75.39658203124999, 78.5228515625],
              [-75.9658203125, 78.529833984375],
              [-76.373486328125, 78.52109375],
              [-76.41611328124999, 78.5115234375],
              [-76.13652343749999, 78.49169921875],
              [-75.48837890624999, 78.403515625],
              [-75.23720703125, 78.355712890625],
              [-75.19345703124999, 78.327734375],
              [-75.55068359375, 78.22109375],
              [-75.865966796875, 78.009814453125],
              [-75.96962890625, 77.993115234375],
              [-76.0775390625, 77.9873046875],
              [-76.35556640624999, 77.991015625],
              [-76.70810546874999, 77.937890625],
              [-76.9740234375, 77.92724609375],
              [-77.45595703125, 77.94716796875],
              [-78.01259765625, 77.946044921875],
              [-78.056396484375, 77.91171875],
              [-78.084130859375, 77.84609375],
              [-78.0810546875, 77.74736328125],
              [-78.04716796874999, 77.615478515625],
              [-78.076171875, 77.51904296875],
              [-78.16796875, 77.45810546875],
              [-78.28374023437499, 77.4130859375],
              [-78.49321289062499, 77.369384765625],
              [-78.70849609375, 77.342138671875],
              [-78.86953125, 77.33251953125],
              [-79.13759765625, 77.331005859375],
              [-79.906396484375, 77.299560546875],
              [-80.281689453125, 77.30146484375],
              [-80.57304687499999, 77.314794921875],
              [-80.874609375, 77.35859375],
              [-81.37685546875, 77.48212890625],
              [-81.519287109375, 77.5095703125],
              [-81.65908203125, 77.525439453125],
              [-81.65380859375, 77.498828125],
              [-81.503564453125, 77.42978515625],
              [-81.378173828125, 77.385205078125],
              [-81.277734375, 77.365185546875],
              [-81.30136718749999, 77.34404296875],
              [-81.52294921875, 77.31083984375],
              [-81.767333984375, 77.295947265625],
              [-82.056787109375, 77.296533203125],
              [-82.066015625, 77.283642578125],
              [-81.96782226562499, 77.2478515625],
              [-81.840234375, 77.214111328125],
              [-81.75634765625, 77.20400390625],
              [-81.53447265624999, 77.214453125],
              [-81.27744140624999, 77.257080078125],
              [-81.1171875, 77.26962890625],
              [-80.79819335937499, 77.25947265625],
              [-80.67255859375, 77.244287109375],
              [-80.27421874999999, 77.150927734375],
              [-80.218701171875, 77.14658203125],
              [-79.92373046875, 77.193603515625],
              [-79.497265625, 77.19609375],
              [-79.34086914062499, 77.1583984375],
              [-79.28110351562499, 77.08515625],
              [-79.273828125, 77.02578125],
              [-79.31894531249999, 76.98037109375],
              [-79.22075195312499, 76.93603515625],
              [-78.97919921875, 76.89287109375],
              [-78.79179687499999, 76.88359375],
              [-78.65854492187499, 76.9080078125],
              [-78.45595703125, 76.967236328125],
              [-78.37001953125, 76.98125],
              [-78.28886718749999, 76.977978515625],
              [-78.16508789062499, 76.934912109375],
              [-77.99873046875, 76.851953125],
              [-77.98330078125, 76.75498046875],
              [-78.11870117187499, 76.64404296875],
              [-78.284326171875, 76.571240234375],
              [-78.93427734375, 76.451171875],
              [-79.13071289062499, 76.403955078125],
              [-79.285888671875, 76.35478515625],
              [-79.51103515624999, 76.310498046875],
              [-79.953564453125, 76.25126953125],
              [-80.18681640624999, 76.240185546875],
              [-80.69028320312499, 76.17646484375],
              [-80.79970703125, 76.173583984375],
              [-80.96293945312499, 76.183935546875],
              [-80.99667968749999, 76.214990234375],
              [-80.95517578124999, 76.270166015625],
              [-80.90122070312499, 76.321533203125],
              [-80.83481445312499, 76.369140625],
              [-80.83237304687499, 76.408642578125],
              [-80.97451171875, 76.470068359375],
              [-81.074365234375, 76.498486328125],
              [-81.17070312499999, 76.512744140625],
              [-81.36479492187499, 76.5044921875],
              [-81.47446289062499, 76.487646484375],
              [-81.5919921875, 76.484423828125],
              [-81.71738281249999, 76.494970703125],
              [-81.82294921875, 76.520849609375],
              [-82.0341796875, 76.62939453125],
              [-82.113720703125, 76.643212890625],
              [-82.217919921875, 76.639794921875],
              [-82.31113281249999, 76.65537109375],
              [-82.39345703125, 76.689892578125],
              [-82.52983398437499, 76.723291015625],
              [-82.493408203125, 76.697802734375],
              [-82.35698242187499, 76.63603515625],
              [-82.261962890625, 76.57470703125],
              [-82.20834960937499, 76.51376953125],
              [-82.233154296875, 76.4658203125],
              [-83.38896484374999, 76.4392578125],
              [-83.885693359375, 76.453125],
              [-83.986328125, 76.49501953125],
              [-84.223779296875, 76.675341796875],
              [-84.275341796875, 76.35654296875],
              [-85.141259765625, 76.30458984375],
              [-85.34360351562499, 76.31337890625],
              [-85.68056640625, 76.3490234375],
              [-86.11582031249999, 76.434912109375],
              [-86.29619140624999, 76.491845703125],
              [-86.366845703125, 76.5486328125],
              [-86.41943359375, 76.579638671875],
              [-86.45371093749999, 76.58486328125],
              [-86.5619140625, 76.51650390625],
              [-86.68022460937499, 76.376611328125],
              [-86.977685546875, 76.412744140625],
              [-87.35419921875, 76.448046875],
              [-87.48979492187499, 76.58583984375],
              [-87.49755859375, 76.386279296875],
              [-88.10434570312499, 76.412744140625],
              [-88.39599609375, 76.4052734375],
              [-88.481640625, 76.580078125],
              [-88.495849609375, 76.7728515625],
              [-88.61411132812499, 76.65087890625],
              [-88.56254882812499, 76.547216796875],
              [-88.54580078125, 76.4208984375],
              [-88.8037109375, 76.4568359375],
              [-89.36962890625, 76.474462890625],
              [-89.570068359375, 76.491943359375],
              [-89.5443359375, 76.65966796875],
              [-89.499755859375, 76.826806640625],
              [-88.7708984375, 76.993359375],
              [-88.55620117187499, 77.072216796875],
              [-88.39814453125, 77.103955078125],
              [-88.14794921875, 77.1240234375],
              [-87.82841796874999, 77.136474609375],
              [-87.610498046875, 77.12685546875],
              [-87.36171875, 77.13623046875],
              [-87.064453125, 77.165869140625],
              [-86.852197265625, 77.1744140625],
              [-86.812255859375, 77.184912109375],
              [-86.873779296875, 77.20029296875],
              [-87.10087890624999, 77.30771484375],
              [-87.18242187499999, 77.33212890625],
              [-87.265380859375, 77.343017578125],
              [-87.4296875, 77.347802734375],
              [-87.58916015624999, 77.39482421875],
              [-87.68144531249999, 77.436376953125],
              [-87.78017578125, 77.492822265625],
              [-87.937939453125, 77.5998046875],
              [-88.094677734375, 77.719189453125],
              [-88.0169921875, 77.784716796875],
              [-87.75712890624999, 77.83623046875],
              [-87.49677734375, 77.871923828125],
              [-87.23603515625, 77.891796875],
              [-87.01796875, 77.892236328125],
              [-86.755078125, 77.863720703125],
              [-86.38510742187499, 77.80859375],
              [-86.172998046875, 77.746142578125],
              [-85.906640625, 77.613916015625],
              [-85.731201171875, 77.508642578125],
              [-85.58847656249999, 77.4611328125],
              [-84.95087890625, 77.374951171875],
              [-84.738671875, 77.36103515625],
              [-84.48701171875, 77.36796875],
              [-83.973583984375, 77.39052734375],
              [-83.72128906249999, 77.414208984375],
              [-83.608056640625, 77.442236328125],
              [-83.5498046875, 77.482568359375],
              [-83.47734374999999, 77.513623046875],
              [-83.25029296874999, 77.584814453125],
              [-82.902734375, 77.73271484375],
              [-82.7103515625, 77.84951171875],
              [-82.664697265625, 77.888818359375],
              [-82.62631835937499, 77.936328125],
              [-82.5953125, 77.992138671875],
              [-82.703564453125, 77.96240234375],
              [-83.30375976562499, 77.67373046875],
              [-83.42822265625, 77.6212890625],
              [-83.77939453124999, 77.5326171875],
              [-83.928173828125, 77.518310546875],
              [-84.16782226562499, 77.522705078125],
              [-84.48583984375, 77.561962890625],
              [-84.860546875, 77.49951171875],
              [-85.087890625, 77.515380859375],
              [-85.28935546874999, 77.559033203125],
              [-85.29204101562499, 77.7638671875],
              [-85.54755859375, 77.927685546875],
              [-85.26533203125, 78.010595703125],
              [-85.031494140625, 78.06201171875],
              [-84.6154296875, 78.195703125],
              [-84.524169921875, 78.1970703125],
              [-84.22270507812499, 78.176025390625],
              [-84.388134765625, 78.20634765625],
              [-84.55, 78.2513671875],
              [-84.9103515625, 78.239697265625],
              [-84.783203125, 78.527587890625],
              [-85.02431640625, 78.31240234375],
              [-85.270166015625, 78.19951171875],
              [-85.41899414062499, 78.142431640625],
              [-85.5859375, 78.1095703125],
              [-86.2177734375, 78.081201171875],
              [-86.06259765624999, 78.186962890625],
              [-85.920068359375, 78.34287109375],
              [-86.070947265625, 78.284619140625],
              [-86.42705078124999, 78.197021484375],
              [-86.693603515625, 78.151025390625],
              [-86.913232421875, 78.126806640625],
              [-87.33935546875, 78.132666015625],
              [-87.5517578125, 78.176611328125],
              [-87.49111328125, 78.284423828125],
              [-87.49130859374999, 78.4171875],
              [-87.361279296875, 78.4787109375],
              [-87.164306640625, 78.5576171875],
              [-86.95292968749999, 78.663916015625],
              [-86.80791015624999, 78.774365234375],
              [-86.24189453125, 78.8236328125],
              [-85.691015625, 78.843701171875],
              [-85.2296875, 78.902001953125],
              [-85.00375976562499, 78.912255859375],
              [-84.787255859375, 78.8845703125],
              [-83.90791015625, 78.83916015625],
              [-83.547021484375, 78.8044921875],
              [-83.38872070312499, 78.779345703125],
              [-83.271435546875, 78.7703125],
              [-83.147412109375, 78.807861328125],
              [-82.98979492187499, 78.844140625],
              [-82.441796875, 78.8404296875],
              [-82.290673828125, 78.8470703125],
              [-82.15107421875, 78.864111328125],
              [-81.981103515625, 78.898486328125],
              [-81.78081054687499, 78.950341796875],
              [-81.75009765624999, 78.97578125],
              [-81.889111328125, 78.974853515625],
              [-82.0283203125, 78.961865234375],
              [-82.23740234374999, 78.924072265625],
              [-82.43876953124999, 78.903662109375],
              [-82.64409179687499, 78.90751953125],
              [-83.058544921875, 78.939501953125],
              [-83.77861328124999, 78.945263671875],
              [-84.14580078124999, 78.959814453125],
              [-84.31611328125, 78.97529296875],
              [-84.41201171875, 78.99658203125],
              [-84.495849609375, 79.028564453125],
              [-84.5677734375, 79.0712890625],
              [-84.5302734375, 79.10126953125],
              [-84.38359374999999, 79.1185546875],
              [-84.25664062499999, 79.12216796875],
              [-84.05302734374999, 79.098681640625],
              [-83.824609375, 79.058837890625],
              [-83.57587890625, 79.053662109375],
              [-83.66201171875, 79.0900390625],
              [-83.978125, 79.163134765625],
              [-84.19736328124999, 79.22509765625],
              [-84.3810546875, 79.30126953125],
              [-84.522412109375, 79.376611328125],
              [-84.83642578125, 79.4947265625],
              [-85.089794921875, 79.612158203125],
              [-85.268505859375, 79.664111328125],
              [-85.45693359375, 79.68984375],
              [-86.031494140625, 79.721923828125],
              [-86.146630859375, 79.742822265625],
              [-86.42075195312499, 79.84521484375],
              [-86.4943359375, 80.0181640625],
              [-86.614501953125, 80.12353515625],
              [-86.49853515625, 80.258251953125],
              [-86.30717773437499, 80.3193359375],
              [-85.159619140625, 80.27177734375],
              [-84.675439453125, 80.27890625],
              [-84.05654296875, 80.261962890625],
              [-83.7236328125, 80.228955078125],
              [-83.34375, 80.14697265625],
              [-83.004296875, 80.05458984375],
              [-82.677490234375, 79.9927734375],
              [-82.37700195312499, 79.908251953125],
              [-82.048779296875, 79.782763671875],
              [-81.855712890625, 79.72255859375],
              [-81.68837890625, 79.685791015625],
              [-81.463037109375, 79.654150390625],
              [-81.0380859375, 79.614208984375],
              [-80.66782226562499, 79.601025390625],
              [-80.47592773437499, 79.60625],
              [-80.27060546874999, 79.635205078125],
              [-80.124462890625, 79.669482421875],
              [-80.28745117187499, 79.678955078125],
              [-80.714013671875, 79.674951171875],
              [-81.01015625, 79.693115234375],
              [-81.17905273437499, 79.733447265625],
              [-81.35869140624999, 79.78779296875],
              [-81.64423828125, 79.890234375],
              [-81.86025390625, 79.957177734375],
              [-82.33237304687499, 80.066357421875],
              [-82.681298828125, 80.17490234375],
              [-82.9611328125, 80.277880859375],
              [-82.98701171875, 80.322607421875],
              [-82.784814453125, 80.353759765625],
              [-82.5361328125, 80.375537109375],
              [-80.979638671875, 80.445263671875],
              [-80.05107421874999, 80.528564453125],
              [-79.67436523437499, 80.625244140625],
              [-79.629345703125, 80.6478515625],
              [-78.386181640625, 80.784375],
              [-77.50712890624999, 80.834765625],
              [-77.169140625, 80.842919921875],
              [-76.86298828125, 80.864794921875],
              [-76.850341796875, 80.878173828125],
              [-77.1185546875, 80.896435546875],
              [-77.38945312499999, 80.905419921875],
              [-78.00380859375, 80.904833984375],
              [-78.5509765625, 80.921435546875],
              [-78.7162109375, 80.95166015625],
              [-78.68193359374999, 81.00107421875],
              [-78.629296875, 81.04345703125],
              [-78.46396484374999, 81.11435546875],
              [-78.28681640625, 81.167626953125],
              [-77.53603515625, 81.32109375],
              [-77.030712890625, 81.385693359375],
              [-76.88510742187499, 81.4302734375],
              [-77.97236328125, 81.330810546875],
              [-78.35214843749999, 81.258935546875],
              [-78.73388671875, 81.151025390625],
              [-78.93154296875, 81.11923828125],
              [-79.0724609375, 81.12763671875],
              [-79.19833984374999, 81.117578125],
              [-79.30917968749999, 81.0890625],
              [-79.40214843749999, 81.036865234375],
              [-79.47724609375, 80.960986328125],
              [-79.54541015625, 80.909326171875],
              [-79.606640625, 80.881787109375],
              [-79.761328125, 80.841943359375],
              [-80.133544921875, 80.763916015625],
              [-81.00703125, 80.6548828125],
              [-81.3009765625, 80.627197265625],
              [-81.552685546875, 80.622802734375],
              [-82.36821289062499, 80.561328125],
              [-82.613037109375, 80.55888671875],
              [-82.88432617187499, 80.5775390625],
              [-82.768310546875, 80.6306640625],
              [-82.33676757812499, 80.728662109375],
              [-82.22236328125, 80.772314453125],
              [-82.4984375, 80.76279296875],
              [-82.77998046875, 80.73603515625],
              [-83.40141601562499, 80.71396484375],
              [-83.647119140625, 80.674072265625],
              [-83.88535156249999, 80.6017578125],
              [-84.07626953124999, 80.55625],
              [-84.21977539062499, 80.53779296875],
              [-84.41782226562499, 80.5267578125],
              [-85.14584960937499, 80.521142578125],
              [-85.30742187499999, 80.5259765625],
              [-85.726220703125, 80.58115234375],
              [-86.09716796875, 80.562109375],
              [-86.25034179687499, 80.565771484375],
              [-86.53159179687499, 80.604736328125],
              [-86.6154296875, 80.630029296875],
              [-86.60307617187499, 80.664013671875],
              [-86.44047851562499, 80.72802734375],
              [-86.252099609375, 80.78955078125],
              [-85.639306640625, 80.924609375],
              [-85.2462890625, 80.987890625],
              [-84.679931640625, 81.0423828125],
              [-83.34921875, 81.1033203125],
              [-83.288818359375, 81.14794921875],
              [-84.63544921875, 81.098095703125],
              [-85.780859375, 81.03505859375],
              [-85.966796875, 81.0119140625],
              [-86.23344726562499, 80.95009765625],
              [-87.0802734375, 80.72626953125],
              [-87.32988281249999, 80.669775390625],
              [-87.711669921875, 80.65625],
              [-88.003662109375, 80.675390625],
              [-88.23198242187499, 80.70380859375],
              [-88.62509765624999, 80.770068359375],
              [-88.92143554687499, 80.805615234375],
              [-89.061669921875, 80.829541015625],
              [-89.14458007812499, 80.853662109375],
              [-89.21176757812499, 80.88193359375],
              [-89.26328125, 80.914306640625],
              [-89.16689453125, 80.94130859375],
              [-88.4130859375, 80.999755859375],
              [-87.388671875, 80.98837890625],
              [-86.92900390624999, 81.000439453125],
              [-86.4767578125, 81.0357421875],
              [-85.8095703125, 81.123583984375],
              [-85.08330078124999, 81.246875],
              [-84.94121093749999, 81.28623046875],
              [-85.206298828125, 81.294873046875],
              [-85.402490234375, 81.285302734375],
              [-85.87504882812499, 81.2412109375],
              [-86.62275390625, 81.12265625],
              [-87.27509765625, 81.080810546875],
              [-88.88681640624999, 81.05849609375],
              [-89.398388671875, 81.025341796875],
              [-89.623046875, 81.032470703125],
              [-89.79228515624999, 81.06484375],
              [-89.98095703125, 81.12470703125],
              [-89.94731445312499, 81.17265625],
              [-89.56337890625, 81.22646484375],
              [-89.26254882812499, 81.2390625],
              [-89.20869140625, 81.25009765625],
              [-89.635693359375, 81.30205078125],
              [-89.67368164062499, 81.32861328125],
              [-89.427001953125, 81.387451171875],
              [-88.89228515625, 81.47412109375],
              [-88.62192382812499, 81.501416015625],
              [-88.12651367187499, 81.518798828125],
              [-87.61669921875, 81.509326171875],
              [-87.59702148437499, 81.525830078125],
              [-88.10136718749999, 81.558642578125],
              [-88.47905273437499, 81.5646484375],
              [-88.97836914062499, 81.54150390625],
              [-90.30351562499999, 81.401123046875],
              [-90.41630859374999, 81.40537109375],
              [-90.609033203125, 81.429541015625],
              [-90.55375976562499, 81.464208984375],
              [-89.84521484375, 81.611669921875],
              [-89.8216796875, 81.63486328125],
              [-90.33085937499999, 81.63154296875],
              [-90.48037109375, 81.638525390625],
              [-90.62631835937499, 81.656005859375],
              [-90.833740234375, 81.640478515625],
              [-91.102734375, 81.5919921875],
              [-91.2923828125, 81.571240234375],
              [-91.402783203125, 81.57822265625],
              [-91.68408203125, 81.635693359375],
              [-91.64755859374999, 81.683837890625],
              [-91.423828125, 81.74423828125],
              [-91.219482421875, 81.787744140625],
              [-90.94194335937499, 81.82744140625],
              [-90.490185546875, 81.87724609375],
              [-90.163037109375, 81.89404296875],
              [-89.63334960937499, 81.89453125],
              [-89.381005859375, 81.916748046875],
              [-89.15634765624999, 81.955419921875],
              [-88.875244140625, 82.018017578125],
              [-88.566845703125, 82.061083984375],
              [-88.06318359375, 82.096484375],
              [-87.638916015625, 82.08505859375],
              [-87.40439453124999, 82.05419921875],
              [-87.2181640625, 82.00009765625],
              [-87.018212890625, 81.958740234375],
              [-86.99921875, 81.992138671875],
              [-86.83403320312499, 82.033349609375],
              [-86.62680664062499, 82.051025390625],
              [-86.37753906249999, 82.0451171875],
              [-86.158349609375, 82.025537109375],
              [-85.87480468749999, 81.97568359375],
              [-85.645654296875, 81.953271484375],
              [-85.53798828125, 81.954638671875],
              [-85.40317382812499, 81.9822265625],
              [-85.04482421875, 81.9828125],
              [-85.05224609375, 81.99453125],
              [-85.16923828124999, 82.023388671875],
              [-85.31059570312499, 82.043994140625],
              [-86.58061523437499, 82.18720703125],
              [-86.615625, 82.2185546875],
              [-86.18759765624999, 82.24794921875],
              [-85.92001953124999, 82.283056640625],
              [-85.79443359375, 82.2916015625],
              [-85.480859375, 82.36630859375],
              [-85.27597656249999, 82.405224609375],
              [-84.89682617187499, 82.4494140625],
              [-84.74472656249999, 82.437353515625],
              [-84.553369140625, 82.39833984375],
              [-84.368115234375, 82.37392578125],
              [-83.8236328125, 82.35068359375],
              [-83.59067382812499, 82.32646484375],
              [-83.17568359375, 82.18720703125],
              [-83.01015625, 82.14169921875],
              [-82.77421874999999, 82.094921875],
              [-82.63369140625, 82.077294921875],
              [-82.35600585937499, 82.066015625],
              [-82.32744140624999, 82.09248046875],
              [-82.65708007812499, 82.15830078125],
              [-82.7474609375, 82.196435546875],
              [-82.70859375, 82.2287109375],
              [-82.63837890625, 82.245751953125],
              [-82.53691406249999, 82.247265625],
              [-82.2765625, 82.21845703125],
              [-81.58447265625, 82.120556640625],
              [-80.54990234374999, 82.00458984375],
              [-80.15336914062499, 81.97763671875],
              [-79.908642578125, 81.93623046875],
              [-79.685546875, 81.885888671875],
              [-79.465625, 81.851123046875],
              [-79.42485351562499, 81.854443359375],
              [-79.62949218749999, 81.93232421875],
              [-80.129833984375, 82.028369140625],
              [-81.46826171875, 82.1923828125],
              [-81.99760742187499, 82.278271484375],
              [-82.253662109375, 82.336328125],
              [-82.44755859374999, 82.39501953125],
              [-82.4513671875, 82.427099609375],
              [-82.26889648437499, 82.4646484375],
              [-82.02324218749999, 82.494384765625],
              [-81.7177734375, 82.50625],
              [-81.68115234375, 82.51865234375],
              [-81.95859375, 82.563232421875],
              [-82.122509765625, 82.6017578125],
              [-82.116845703125, 82.628662109375],
              [-81.7853515625, 82.64921875],
              [-81.5796875, 82.643017578125],
              [-81.1888671875, 82.594482421875],
              [-80.8625, 82.571533203125],
              [-80.80966796874999, 82.586376953125],
              [-81.146630859375, 82.715576171875],
              [-81.17807617187499, 82.744677734375],
              [-81.128173828125, 82.76171875],
              [-81.01015625, 82.779052734375],
              [-80.65712890625, 82.769091796875],
              [-80.07578125, 82.706201171875],
              [-79.03505859375, 82.674658203125],
              [-78.748779296875, 82.67939453125],
              [-78.79179687499999, 82.693896484375],
              [-79.2072265625, 82.732763671875],
              [-79.6419921875, 82.7849609375],
              [-79.83378906249999, 82.81650390625],
              [-79.97431640625, 82.858984375],
              [-80.14116210937499, 82.89423828125],
              [-80.154931640625, 82.9111328125],
              [-79.886328125, 82.938525390625],
              [-79.18056640625, 82.933203125],
              [-78.52495117187499, 82.89111328125],
              [-77.96865234375, 82.90634765625],
              [-77.61806640625, 82.895849609375],
              [-77.47958984374999, 82.883154296875],
              [-77.22587890624999, 82.83720703125],
              [-76.42099609374999, 82.6708984375],
              [-76.335546875, 82.64443359375],
              [-76.24404296875, 82.6041015625],
              [-76.146484375, 82.549853515625],
              [-76.009375, 82.53515625],
              [-75.7443359375, 82.572412109375],
              [-75.565625, 82.608544921875],
              [-75.64287109374999, 82.643505859375],
              [-76.08696289062499, 82.7236328125],
              [-76.18779296874999, 82.75791015625],
              [-76.40996093749999, 82.8158203125],
              [-76.908447265625, 82.91943359375],
              [-77.0412109375, 82.967529296875],
              [-77.12490234375, 83.008544921875],
              [-75.74492187499999, 83.04716796875],
              [-74.41416015624999, 83.013134765625],
              [-74.19775390625, 82.989013671875],
              [-74.055859375, 82.95537109375],
              [-73.91650390625, 82.90419921875],
              [-73.703125, 82.85185546875],
              [-73.27202148437499, 82.77158203125],
              [-72.65869140625, 82.721630859375],
              [-72.77592773437499, 82.7556640625],
              [-73.23466796874999, 82.84423828125],
              [-73.44189453125, 82.904833984375],
              [-73.44072265624999, 82.945849609375],
              [-73.40380859375, 82.9771484375],
              [-73.33115234374999, 82.998779296875],
              [-72.811669921875, 83.081201171875],
              [-72.06923828125, 83.1060546875],
              [-71.98320312499999, 83.101416015625],
              [-71.40595703125, 82.974853515625],
              [-71.13203125, 82.923046875],
              [-70.940380859375, 82.90224609375],
              [-70.9330078125, 82.911279296875],
              [-71.19833984374999, 82.969580078125],
              [-71.40239257812499, 83.00126953125],
              [-71.42353515625, 83.021142578125],
              [-71.08481445312499, 83.082666015625],
              [-70.870556640625, 83.09814453125],
              [-69.969921875, 83.11611328125],
              [-69.86767578125, 83.109619140625],
              [-69.78212890625, 83.092529296875],
              [-69.56938476562499, 83.02490234375],
              [-69.48886718749999, 83.016796875],
            ],
          ],
          [
            [
              [-95.484375, 77.7919921875],
              [-95.233056640625, 77.75380859375],
              [-94.959912109375, 77.774072265625],
              [-94.66679687499999, 77.776220703125],
              [-94.01474609374999, 77.759912109375],
              [-93.58286132812499, 77.770751953125],
              [-93.47109375, 77.764306640625],
              [-93.3009765625, 77.739794921875],
              [-93.21074218749999, 77.710205078125],
              [-93.12871093749999, 77.66015625],
              [-93.33916015624999, 77.6296875],
              [-93.51958007812499, 77.4744140625],
              [-93.5439453125, 77.466650390625],
              [-93.740185546875, 77.46455078125],
              [-93.836181640625, 77.45224609375],
              [-94.40898437499999, 77.47421875],
              [-95.987060546875, 77.484130859375],
              [-96.056103515625, 77.503466796875],
              [-96.2638671875, 77.59453125],
              [-96.276611328125, 77.63056640625],
              [-96.23916015625, 77.67255859375],
              [-96.194580078125, 77.700537109375],
              [-96.14296875, 77.71435546875],
              [-95.68393554687499, 77.782275390625],
              [-95.484375, 77.7919921875],
            ],
          ],
          [
            [
              [-93.542578125, 75.0279296875],
              [-93.478271484375, 74.951953125],
              [-93.46660156249999, 74.921337890625],
              [-93.46347656249999, 74.856494140625],
              [-93.490869140625, 74.77197265625],
              [-93.5091796875, 74.756494140625],
              [-93.53564453125, 74.74931640625],
              [-93.548291015625, 74.7275390625],
              [-93.54716796874999, 74.691064453125],
              [-93.57309570312499, 74.66884765625],
              [-93.626171875, 74.660888671875],
              [-93.9845703125, 74.644189453125],
              [-94.2060546875, 74.647412109375],
              [-94.53452148437499, 74.63671875],
              [-94.697265625, 74.6421875],
              [-94.803857421875, 74.660107421875],
              [-94.958740234375, 74.699951171875],
              [-95.286083984375, 74.794091796875],
              [-95.45122070312499, 74.79736328125],
              [-95.8654296875, 74.830419921875],
              [-96.09423828125, 74.93251953125],
              [-96.18173828124999, 74.95078125],
              [-96.27011718749999, 74.9203125],
              [-96.294189453125, 74.927197265625],
              [-96.3185546875, 74.947705078125],
              [-96.3431640625, 74.98193359375],
              [-96.386328125, 74.999462890625],
              [-96.55986328124999, 74.990380859375],
              [-96.59116210937499, 75.00185546875],
              [-96.599609375, 75.031787109375],
              [-96.596923828125, 75.057861328125],
              [-96.56577148437499, 75.09873046875],
              [-96.38286132812499, 75.211376953125],
              [-96.2923828125, 75.219287109375],
              [-96.18037109375, 75.240087890625],
              [-96.118408203125, 75.300927734375],
              [-96.12490234375, 75.35830078125],
              [-95.95463867187499, 75.443798828125],
              [-95.853173828125, 75.46904296875],
              [-95.67080078125, 75.528662109375],
              [-95.04951171875, 75.621826171875],
              [-94.878173828125, 75.630029296875],
              [-94.6486328125, 75.623046875],
              [-94.42724609375, 75.593359375],
              [-94.25668945312499, 75.544091796875],
              [-93.90908203125, 75.422509765625],
              [-93.75083007812499, 75.3490234375],
              [-93.66684570312499, 75.27353515625],
              [-93.5912109375, 75.230224609375],
              [-93.49755859375, 75.136865234375],
              [-93.53173828125, 75.100341796875],
              [-93.55180664062499, 75.051171875],
              [-93.542578125, 75.0279296875],
            ],
          ],
          [
            [
              [-100.001904296875, 73.9458984375],
              [-99.157958984375, 73.731591796875],
              [-99.03964843749999, 73.749267578125],
              [-98.78452148437499, 73.760546875],
              [-98.51933593749999, 73.79208984375],
              [-98.15185546875, 73.818212890625],
              [-97.927734375, 73.865771484375],
              [-97.832177734375, 73.879345703125],
              [-97.66997070312499, 73.887744140625],
              [-97.58183593749999, 73.887548828125],
              [-97.32705078125, 73.861865234375],
              [-97.224755859375, 73.843798828125],
              [-97.17050781249999, 73.824853515625],
              [-97.11171875, 73.79033203125],
              [-97.01127929687499, 73.70615234375],
              [-96.99658203125, 73.67490234375],
              [-97.001708984375, 73.66650390625],
              [-97.09458007812499, 73.61474609375],
              [-97.156396484375, 73.5921875],
              [-97.2841796875, 73.570751953125],
              [-97.394775390625, 73.564208984375],
              [-97.48979492187499, 73.526611328125],
              [-97.59697265624999, 73.53662109375],
              [-97.62587890625, 73.502294921875],
              [-97.61459960937499, 73.48134765625],
              [-97.58583984375, 73.471142578125],
              [-97.53183593749999, 73.473583984375],
              [-97.4701171875, 73.488232421875],
              [-97.35029296875, 73.48095703125],
              [-97.287109375, 73.458447265625],
              [-97.23037109375, 73.4212890625],
              [-97.27250976562499, 73.38681640625],
              [-97.48408203125, 73.339208984375],
              [-97.7958984375, 73.285302734375],
              [-98.17583007812499, 73.115771484375],
              [-98.37558593749999, 73.044677734375],
              [-98.41684570312499, 73.022509765625],
              [-98.436962890625, 73.000244140625],
              [-98.430908203125, 72.958056640625],
              [-98.42177734375, 72.941015625],
              [-98.366650390625, 72.934130859375],
              [-98.180810546875, 72.99306640625],
              [-98.06103515625, 73.0205078125],
              [-97.939404296875, 73.035595703125],
              [-97.7248046875, 73.036669921875],
              [-97.636328125, 73.02763671875],
              [-97.47568359374999, 72.99228515625],
              [-97.328759765625, 72.937841796875],
              [-97.295849609375, 72.918017578125],
              [-97.30991210937499, 72.89814453125],
              [-97.37099609375, 72.878125],
              [-97.377685546875, 72.86494140625],
              [-97.23759765624999, 72.837451171875],
              [-97.0830078125, 72.762841796875],
              [-97.072900390625, 72.717578125],
              [-97.14047851562499, 72.67275390625],
              [-97.158935546875, 72.6427734375],
              [-97.128125, 72.627587890625],
              [-97.05180664062499, 72.63681640625],
              [-96.86904296875, 72.68701171875],
              [-96.6712890625, 72.71318359375],
              [-96.59208984374999, 72.71025390625],
              [-96.54208984374999, 72.69873046875],
              [-96.489208984375, 72.6298828125],
              [-96.44560546874999, 72.55244140625],
              [-96.44013671875, 72.4873046875],
              [-96.4728515625, 72.434375],
              [-96.51987304687499, 72.393115234375],
              [-96.63828125, 72.342041015625],
              [-96.7455078125, 72.322607421875],
              [-96.80146484375, 72.322412109375],
              [-96.7958984375, 72.31376953125],
              [-96.66875, 72.271240234375],
              [-96.61557617187499, 72.237255859375],
              [-96.59287109374999, 72.2044921875],
              [-96.6005859375, 72.1728515625],
              [-96.618115234375, 72.1458984375],
              [-96.76630859375, 72.045947265625],
              [-96.75830078125, 72.031689453125],
              [-96.71728515625, 72.025146484375],
              [-96.62436523437499, 71.967578125],
              [-96.613427734375, 71.833837890625],
              [-96.946484375, 71.79189453125],
              [-97.024658203125, 71.7607421875],
              [-97.11669921875, 71.71083984375],
              [-97.22221679687499, 71.673486328125],
              [-97.46123046874999, 71.634228515625],
              [-97.582275390625, 71.6296875],
              [-98.18134765625, 71.662451171875],
              [-98.241943359375, 71.681494140625],
              [-98.28388671875, 71.71552734375],
              [-98.307080078125, 71.764501953125],
              [-98.31337890625, 71.803076171875],
              [-98.302685546875, 71.831103515625],
              [-98.305810546875, 71.84755859375],
              [-98.322705078125, 71.85234375],
              [-98.389306640625, 71.824267578125],
              [-98.45883789062499, 71.773193359375],
              [-98.42080078125, 71.71650390625],
              [-98.2314453125, 71.558935546875],
              [-98.1953125, 71.4912109375],
              [-98.19013671875, 71.462451171875],
              [-98.1986328125, 71.440869140625],
              [-98.4123046875, 71.348828125],
              [-98.53593749999999, 71.317626953125],
              [-98.66289062499999, 71.302099609375],
              [-98.783837890625, 71.313671875],
              [-98.89877929687499, 71.35234375],
              [-98.98623046875, 71.369482421875],
              [-99.167138671875, 71.3671875],
              [-99.2236328125, 71.387109375],
              [-99.27617187499999, 71.42421875],
              [-99.40366210937499, 71.557177734375],
              [-99.5814453125, 71.6515625],
              [-99.73471679687499, 71.7572265625],
              [-100.12412109374999, 71.9115234375],
              [-100.32568359375, 72.003857421875],
              [-100.594482421875, 72.15234375],
              [-100.70683593749999, 72.1859375],
              [-100.8001953125, 72.1994140625],
              [-100.983642578125, 72.21005859375],
              [-101.02622070312499, 72.228564453125],
              [-101.09311523437499, 72.279052734375],
              [-101.20854492187499, 72.3169921875],
              [-101.25068359375, 72.32177734375],
              [-101.31870117187499, 72.312841796875],
              [-101.49833984374999, 72.277880859375],
              [-101.72392578124999, 72.314892578125],
              [-101.77451171874999, 72.34091796875],
              [-101.804443359375, 72.38505859375],
              [-101.83291015625, 72.40927734375],
              [-101.909326171875, 72.4310546875],
              [-101.97368164062499, 72.4861328125],
              [-102.40224609375, 72.5947265625],
              [-102.65708007812499, 72.71943359375],
              [-102.708740234375, 72.764501953125],
              [-102.71367187499999, 72.78291015625],
              [-102.6875, 72.842822265625],
              [-102.62846679687499, 72.910791015625],
              [-102.55107421874999, 72.978271484375],
              [-102.50380859375, 73.005908203125],
              [-102.3361328125, 73.064111328125],
              [-102.20400390625, 73.077294921875],
              [-102.01962890624999, 73.069921875],
              [-101.92246093749999, 73.056982421875],
              [-101.835400390625, 73.018017578125],
              [-101.798046875, 72.973095703125],
              [-101.754541015625, 72.942822265625],
              [-101.61777343749999, 72.909716796875],
              [-101.543603515625, 72.883056640625],
              [-101.43461914062499, 72.821044921875],
              [-101.3505859375, 72.7462890625],
              [-101.273193359375, 72.7216796875],
              [-101.08759765625, 72.71328125],
              [-100.89604492187499, 72.725927734375],
              [-100.484765625, 72.77294921875],
              [-100.468017578125, 72.77880859375],
              [-100.442578125, 72.8068359375],
              [-100.395703125, 72.977001953125],
              [-100.36752929687499, 72.977734375],
              [-100.2279296875, 72.89892578125],
              [-100.18833007812499, 72.890283203125],
              [-100.128125, 72.906689453125],
              [-100.09238281249999, 72.944970703125],
              [-100.09672851562499, 72.963134765625],
              [-100.18447265625, 73.055322265625],
              [-100.23618164062499, 73.09541015625],
              [-100.282666015625, 73.1203125],
              [-100.334375, 73.128466796875],
              [-100.44619140625, 73.120556640625],
              [-100.531396484375, 73.13828125],
              [-100.5501953125, 73.163720703125],
              [-100.536376953125, 73.1978515625],
              [-100.48930664062499, 73.233935546875],
              [-100.43881835937499, 73.25458984375],
              [-100.34072265625, 73.265185546875],
              [-100.22587890624999, 73.2546875],
              [-100.06699218749999, 73.211083984375],
              [-99.96640625, 73.201416015625],
              [-99.825146484375, 73.2138671875],
              [-100.00590820312499, 73.239501953125],
              [-100.257958984375, 73.340234375],
              [-100.36611328125, 73.359033203125],
              [-100.497998046875, 73.3158203125],
              [-100.58701171874999, 73.299560546875],
              [-100.755322265625, 73.278466796875],
              [-100.88935546875, 73.275341796875],
              [-101.45087890625, 73.43095703125],
              [-101.482080078125, 73.445849609375],
              [-101.523193359375, 73.486376953125],
              [-101.51845703125, 73.505029296875],
              [-101.463037109375, 73.533837890625],
              [-101.32314453125, 73.57197265625],
              [-101.11494140625, 73.595849609375],
              [-100.97578125, 73.599755859375],
              [-100.85410156249999, 73.5712890625],
              [-100.67680664062499, 73.494287109375],
              [-100.5216796875, 73.44931640625],
              [-100.508935546875, 73.465478515625],
              [-100.536328125, 73.509716796875],
              [-100.60712890625, 73.575390625],
              [-100.65791015625, 73.593359375],
              [-100.78271484375, 73.612939453125],
              [-100.89824218749999, 73.658056640625],
              [-100.952587890625, 73.69140625],
              [-100.98154296874999, 73.727197265625],
              [-100.985107421875, 73.76533203125],
              [-100.96298828124999, 73.79140625],
              [-100.91513671874999, 73.80537109375],
              [-100.483642578125, 73.843505859375],
              [-100.18232421875, 73.80126953125],
              [-99.99111328125, 73.795166015625],
              [-99.911865234375, 73.847021484375],
              [-99.93950195312499, 73.85712890625],
              [-100.04008789062499, 73.843798828125],
              [-100.15380859375, 73.844091796875],
              [-100.2248046875, 73.872509765625],
              [-100.22705078125, 73.889111328125],
              [-100.13847656249999, 73.928857421875],
              [-100.001904296875, 73.9458984375],
            ],
          ],
          [
            [
              [-84.91962890625, 65.261083984375],
              [-84.88510742187499, 65.248974609375],
              [-84.84208984374999, 65.255908203125],
              [-84.77128906249999, 65.3052734375],
              [-84.6125, 65.447314453125],
              [-84.56791992187499, 65.46064453125],
              [-84.501123046875, 65.458447265625],
              [-84.26640624999999, 65.367236328125],
              [-84.17998046874999, 65.31630859375],
              [-84.13349609375, 65.245458984375],
              [-84.08486328125, 65.217822265625],
              [-83.90009765625, 65.18125],
              [-83.72255859375, 65.168994140625],
              [-83.49077148437499, 65.131787109375],
              [-83.40712890625, 65.10390625],
              [-83.222265625, 64.96796875],
              [-83.20097656249999, 64.95966796875],
              [-82.99057617187499, 64.9041015625],
              [-82.667626953125, 64.780322265625],
              [-82.58579101562499, 64.7619140625],
              [-82.2716796875, 64.721142578125],
              [-82.15888671875, 64.690673828125],
              [-82.05, 64.644287109375],
              [-81.92890625, 64.559423828125],
              [-81.78720703124999, 64.4259765625],
              [-81.67612304687499, 64.212646484375],
              [-81.6673828125, 64.1705078125],
              [-81.680908203125, 64.145556640625],
              [-81.720947265625, 64.118896484375],
              [-81.90263671874999, 64.03125],
              [-81.887109375, 64.01640625],
              [-81.71611328124999, 64.021875],
              [-81.33564453125, 64.07578125],
              [-81.10405273437499, 64.037109375],
              [-81.023583984375, 64.0310546875],
              [-81.005029296875, 64.03330078125],
              [-80.921142578125, 64.10048828125],
              [-80.82895507812499, 64.08994140625],
              [-80.694287109375, 64.024755859375],
              [-80.607568359375, 63.972070312499994],
              [-80.56884765625, 63.93193359375],
              [-80.57919921874999, 63.909228515625],
              [-80.66826171874999, 63.90146484375],
              [-80.4505859375, 63.862939453124994],
              [-80.261328125, 63.801953125],
              [-80.30205078124999, 63.76220703125],
              [-80.504052734375, 63.673779296875],
              [-80.71176757812499, 63.59638671875],
              [-80.953515625, 63.4802734375],
              [-81.0138671875, 63.462548828124994],
              [-81.04638671875, 63.461572265624994],
              [-81.1796875, 63.483203125],
              [-81.371728515625, 63.5380859375],
              [-81.963330078125, 63.664453125],
              [-82.14599609375, 63.691162109375],
              [-82.378125, 63.706787109375],
              [-82.41171875, 63.7365234375],
              [-82.46708984374999, 63.926953125],
              [-82.571484375, 63.960693359375],
              [-82.9296875, 64.000439453125],
              [-83.03388671875, 64.0232421875],
              [-83.038671875, 64.06142578125],
              [-83.01616210937499, 64.127001953125],
              [-83.06513671875, 64.159033203125],
              [-83.185546875, 64.15751953125],
              [-83.303955078125, 64.143798828125],
              [-83.4943359375, 64.09921875],
              [-83.58359375, 64.05810546875],
              [-83.61708984375, 64.013427734375],
              [-83.63798828124999, 63.917822265625],
              [-83.66162109375, 63.872607421875],
              [-83.728271484375, 63.813378906249994],
              [-84.022119140625, 63.65986328125],
              [-84.1416015625, 63.613720703125],
              [-84.26044921875, 63.600488281249994],
              [-84.3076171875, 63.585791015625],
              [-84.3875, 63.5291015625],
              [-84.50620117187499, 63.390039062499994],
              [-84.55458984375, 63.35],
              [-84.63291015624999, 63.309228515624994],
              [-84.795556640625, 63.246923828125],
              [-84.96152343749999, 63.197216796875],
              [-85.23813476562499, 63.139306640624994],
              [-85.39262695312499, 63.119677734375],
              [-85.4955078125, 63.139111328125],
              [-85.56611328125, 63.270898437499994],
              [-85.71416015624999, 63.657958984375],
              [-85.738720703125, 63.684130859375],
              [-85.7689453125, 63.700341796874994],
              [-85.8046875, 63.70654296875],
              [-86.30156249999999, 63.656787109375],
              [-86.57568359375, 63.6623046875],
              [-86.846875, 63.57529296875],
              [-86.915234375, 63.568994140624994],
              [-87.0529296875, 63.57177734375],
              [-87.15190429687499, 63.58564453125],
              [-87.1771484375, 63.595117187499994],
              [-87.19384765625, 63.6328125],
              [-87.188916015625, 63.672265625],
              [-87.15439453124999, 63.714892578125],
              [-87.03193359375, 63.830419921875],
              [-86.93203125, 63.90166015625],
              [-86.88603515624999, 63.923730468749994],
              [-86.42172851562499, 64.0515625],
              [-86.30859375, 64.09365234375],
              [-86.252099609375, 64.136865234375],
              [-86.252197265625, 64.18125],
              [-86.274169921875, 64.238037109375],
              [-86.3544921875, 64.376513671875],
              [-86.37490234375, 64.502978515625],
              [-86.374267578125, 64.5658203125],
              [-86.34384765624999, 64.662353515625],
              [-86.22763671874999, 64.896337890625],
              [-86.18828124999999, 65.010302734375],
              [-86.114208984375, 65.41728515625],
              [-86.074609375, 65.533837890625],
              [-86.01708984375, 65.640283203125],
              [-85.961669921875, 65.704248046875],
              [-85.81396484375, 65.83193359375],
              [-85.69907226562499, 65.883154296875],
              [-85.5546875, 65.91865234375],
              [-85.523046875, 65.91455078125],
              [-85.4955078125, 65.89970703125],
              [-85.44243164062499, 65.845556640625],
              [-85.24111328125, 65.7955078125],
              [-85.176220703125, 65.746875],
              [-85.13037109375, 65.692919921875],
              [-85.10537109375, 65.622705078125],
              [-85.130322265625, 65.59208984375],
              [-85.226318359375, 65.545751953125],
              [-85.24277343749999, 65.526220703125],
              [-85.23994140625, 65.510302734375],
              [-85.0560546875, 65.43740234375],
              [-84.91962890625, 65.261083984375],
            ],
          ],
          [
            [
              [-97.700927734375, 76.46650390625],
              [-97.68974609374999, 76.421826171875],
              [-97.70185546875, 76.38740234375],
              [-97.73710937499999, 76.363134765625],
              [-97.73876953125, 76.33525390625],
              [-97.70683593749999, 76.3037109375],
              [-97.57314453125, 76.22421875],
              [-97.5306640625, 76.18154296875],
              [-97.52426757812499, 76.138720703125],
              [-97.53105468749999, 76.109423828125],
              [-97.6134765625, 76.05263671875],
              [-97.65, 75.979150390625],
              [-97.65214843749999, 75.940185546875],
              [-97.60302734375, 75.879345703125],
              [-97.60166015624999, 75.85107421875],
              [-97.69423828125, 75.802587890625],
              [-97.89052734375, 75.7603515625],
              [-97.86279296875, 75.7380859375],
              [-97.43955078124999, 75.6845703125],
              [-97.40751953124999, 75.672509765625],
              [-97.409619140625, 75.552099609375],
              [-97.33603515624999, 75.41982421875],
              [-97.3634765625, 75.417236328125],
              [-97.465234375, 75.458642578125],
              [-97.6533203125, 75.507763671875],
              [-97.87822265624999, 75.41611328125],
              [-97.852734375, 75.260302734375],
              [-97.70488281249999, 75.1908203125],
              [-97.659912109375, 75.151171875],
              [-97.67431640625, 75.127294921875],
              [-97.79936523437499, 75.116650390625],
              [-97.842724609375, 75.121826171875],
              [-97.970849609375, 75.153271484375],
              [-98.0453125, 75.200830078125],
              [-98.06875, 75.199169921875],
              [-98.09169921875, 75.176220703125],
              [-98.07675781249999, 75.152978515625],
              [-97.989990234375, 75.110693359375],
              [-97.9533203125, 75.06015625],
              [-97.991796875, 75.04580078125],
              [-98.12094726562499, 75.03271484375],
              [-98.295166015625, 75.032177734375],
              [-98.56865234374999, 75.009326171875],
              [-98.703515625, 75.005810546875],
              [-98.83481445312499, 75.0181640625],
              [-99.01005859374999, 75.02109375],
              [-99.15581054687499, 75.01572265625],
              [-99.24492187499999, 75.02578125],
              [-99.326123046875, 75.0494140625],
              [-99.42060546875, 75.04375],
              [-99.626904296875, 74.983740234375],
              [-99.94663085937499, 75.00283203125],
              [-100.234375, 75.00771484375],
              [-100.29228515624999, 75.027734375],
              [-100.356640625, 75.066748046875],
              [-100.48349609374999, 75.188427734375],
              [-100.45947265625, 75.219091796875],
              [-100.15205078125, 75.23564453125],
              [-100.145703125, 75.246142578125],
              [-100.36411132812499, 75.28955078125],
              [-100.614892578125, 75.321435546875],
              [-100.73115234375, 75.346533203125],
              [-100.704248046875, 75.3943359375],
              [-100.7119140625, 75.40634765625],
              [-100.27963867187499, 75.460986328125],
              [-99.965283203125, 75.568505859375],
              [-99.77021484375, 75.612255859375],
              [-99.756005859375, 75.6333984375],
              [-99.59116210937499, 75.65537109375],
              [-99.209423828125, 75.668603515625],
              [-99.194580078125, 75.698388671875],
              [-99.91513671874999, 75.68125],
              [-100.9017578125, 75.62041015625],
              [-101.20683593749999, 75.5904296875],
              [-101.461328125, 75.60791015625],
              [-102.54140625, 75.513623046875],
              [-102.58740234375, 75.513671875],
              [-102.700390625, 75.543603515625],
              [-102.797509765625, 75.599658203125],
              [-102.72783203124999, 75.638720703125],
              [-102.41069335937499, 75.712841796875],
              [-102.25205078124999, 75.777734375],
              [-102.270654296875, 75.81279296875],
              [-102.1447265625, 75.875048828125],
              [-101.942822265625, 75.883837890625],
              [-101.59965820312499, 75.832666015625],
              [-101.42124023437499, 75.78193359375],
              [-101.26142578125, 75.758203125],
              [-101.119384765625, 75.762890625],
              [-100.972802734375, 75.7984375],
              [-101.009912109375, 75.802392578125],
              [-101.25883789062499, 75.783642578125],
              [-101.288037109375, 75.789111328125],
              [-101.414990234375, 75.845849609375],
              [-101.4703125, 75.88193359375],
              [-101.50590820312499, 75.91806640625],
              [-101.50786132812499, 75.943603515625],
              [-101.43134765625, 75.9919921875],
              [-101.716796875, 76.00791015625],
              [-101.823388671875, 76.041357421875],
              [-101.87211914062499, 76.08310546875],
              [-101.86137695312499, 76.10126953125],
              [-101.77138671875, 76.15009765625],
              [-101.528955078125, 76.21728515625],
              [-101.55703125, 76.23583984375],
              [-101.909814453125, 76.234375],
              [-101.987451171875, 76.243115234375],
              [-102.13774414062499, 76.28486328125],
              [-102.1046875, 76.331201171875],
              [-101.96420898437499, 76.3990234375],
              [-101.85849609374999, 76.439013671875],
              [-101.787548828125, 76.45126953125],
              [-101.67724609375, 76.451025390625],
              [-101.415185546875, 76.42490234375],
              [-101.33974609375, 76.410498046875],
              [-101.1390625, 76.345166015625],
              [-101.087890625, 76.307861328125],
              [-101.094189453125, 76.271923828125],
              [-101.055810546875, 76.245556640625],
              [-100.90009765625, 76.207080078125],
              [-100.2306640625, 76.007666015625],
              [-100.105712890625, 75.96044921875],
              [-100.02011718749999, 75.93955078125],
              [-99.865478515625, 75.92421875],
              [-99.774853515625, 75.927392578125],
              [-99.70126953124999, 75.941455078125],
              [-99.688916015625, 75.959716796875],
              [-99.97832031249999, 76.0294921875],
              [-100.0509765625, 76.0666015625],
              [-100.11284179687499, 76.117236328125],
              [-100.08579101562499, 76.133544921875],
              [-100.00175781249999, 76.139208984375],
              [-99.790185546875, 76.1326171875],
              [-99.54106445312499, 76.1462890625],
              [-99.81723632812499, 76.167578125],
              [-99.99760742187499, 76.195849609375],
              [-100.182763671875, 76.197216796875],
              [-100.414208984375, 76.242529296875],
              [-100.41435546874999, 76.256689453125],
              [-100.3576171875, 76.271142578125],
              [-100.042724609375, 76.291259765625],
              [-99.98310546875, 76.29990234375],
              [-99.977734375, 76.312451171875],
              [-100.08188476562499, 76.3427734375],
              [-100.17465820312499, 76.35927734375],
              [-100.65068359374999, 76.395947265625],
              [-100.81987304687499, 76.43701171875],
              [-100.87363281249999, 76.456591796875],
              [-100.890771484375, 76.47548828125],
              [-100.829736328125, 76.523876953125],
              [-100.57373046875, 76.584619140625],
              [-100.387939453125, 76.61357421875],
              [-100.06870117187499, 76.634765625],
              [-99.8140625, 76.6322265625],
              [-99.66904296874999, 76.62412109375],
              [-99.3294921875, 76.5212890625],
              [-99.16962890625, 76.453662109375],
              [-98.89033203125, 76.465576171875],
              [-98.97099609374999, 76.536572265625],
              [-99.0236328125, 76.61455078125],
              [-98.940869140625, 76.6431640625],
              [-98.71083984375, 76.69384765625],
              [-98.527587890625, 76.6673828125],
              [-98.288671875, 76.59873046875],
              [-98.23618164062499, 76.575341796875],
              [-97.96733398437499, 76.53291015625],
              [-97.80839843749999, 76.518798828125],
              [-97.72587890624999, 76.49609375],
              [-97.700927734375, 76.46650390625],
            ],
          ],
          [
            [
              [-103.426025390625, 79.315625],
              [-103.191650390625, 79.2953125],
              [-102.91435546874999, 79.231103515625],
              [-102.652294921875, 79.09501953125],
              [-102.63896484374999, 79.077587890625],
              [-102.63759765625, 79.05498046875],
              [-102.648193359375, 79.0271484375],
              [-102.682861328125, 78.991015625],
              [-102.73051757812499, 78.9693359375],
              [-102.59560546875, 78.94296875],
              [-102.58076171875, 78.930126953125],
              [-102.5927734375, 78.900927734375],
              [-102.576171875, 78.87939453125],
              [-102.494580078125, 78.90068359375],
              [-102.4248046875, 78.933203125],
              [-102.40732421874999, 78.9541015625],
              [-102.39316406249999, 79.010302734375],
              [-102.18857421874999, 79.03837890625],
              [-101.9736328125, 79.07919921875],
              [-101.87260742187499, 79.08837890625],
              [-101.70366210937499, 79.07890625],
              [-101.2990234375, 78.982177734375],
              [-101.14458007812499, 78.972900390625],
              [-101.08847656249999, 78.9615234375],
              [-101.03715820312499, 78.939013671875],
              [-101.03369140625, 78.914697265625],
              [-101.11591796875, 78.85830078125],
              [-101.1474609375, 78.823974609375],
              [-101.128125, 78.80166015625],
              [-100.9169921875, 78.78291015625],
              [-100.435498046875, 78.8203125],
              [-100.01474609374999, 78.72861328125],
              [-99.78183593749999, 78.61962890625],
              [-99.60942382812499, 78.583056640625],
              [-99.58212890624999, 78.56328125],
              [-99.631103515625, 78.544677734375],
              [-99.68017578125, 78.493505859375],
              [-99.818310546875, 78.45537109375],
              [-99.847802734375, 78.438232421875],
              [-99.77412109375, 78.39296875],
              [-99.76865234374999, 78.36455078125],
              [-99.77822265625, 78.32509765625],
              [-99.7513671875, 78.302978515625],
              [-99.562451171875, 78.279345703125],
              [-99.13154296875, 78.117529296875],
              [-99.053125, 78.07236328125],
              [-99.00458984375, 78.015966796875],
              [-98.999609375, 77.996875],
              [-99.061181640625, 77.965625],
              [-99.128369140625, 77.8771484375],
              [-99.16640625, 77.85693359375],
              [-99.34130859375, 77.8396484375],
              [-99.659130859375, 77.824072265625],
              [-99.95590820312499, 77.793798828125],
              [-100.274658203125, 77.83271484375],
              [-100.58603515624999, 77.891796875],
              [-100.68027343749999, 77.9306640625],
              [-100.75791015624999, 77.977685546875],
              [-100.77822265625, 77.996044921875],
              [-100.8095703125, 78.071630859375],
              [-100.826171875, 78.087744140625],
              [-100.95761718749999, 78.130224609375],
              [-101.07412109375, 78.19384765625],
              [-101.297998046875, 78.199365234375],
              [-101.8294921875, 78.264111328125],
              [-102.05698242187499, 78.279541015625],
              [-102.28447265624999, 78.275],
              [-102.60698242187499, 78.24892578125],
              [-102.66767578125, 78.255908203125],
              [-102.72270507812499, 78.275244140625],
              [-102.77207031249999, 78.306884765625],
              [-102.784326171875, 78.33017578125],
              [-102.73134765625, 78.371044921875],
              [-103.67724609375, 78.319580078125],
              [-103.94658203125, 78.260009765625],
              [-104.32421875, 78.269482421875],
              [-104.512646484375, 78.29462890625],
              [-104.76357421875, 78.35166015625],
              [-104.879345703125, 78.40126953125],
              [-104.985400390625, 78.468017578125],
              [-104.995556640625, 78.518505859375],
              [-104.909619140625, 78.55263671875],
              [-104.8201171875, 78.572900390625],
              [-104.72705078125, 78.57939453125],
              [-104.21396484374999, 78.53974609375],
              [-103.76435546875, 78.51953125],
              [-103.5705078125, 78.53984375],
              [-103.482568359375, 78.5939453125],
              [-103.58798828124999, 78.622998046875],
              [-104.02084960937499, 78.634912109375],
              [-103.928515625, 78.66337890625],
              [-103.5626953125, 78.69267578125],
              [-103.37158203125, 78.736328125],
              [-103.4083984375, 78.751611328125],
              [-103.518359375, 78.769140625],
              [-104.008740234375, 78.764013671875],
              [-104.185009765625, 78.781298828125],
              [-104.194580078125, 78.79560546875],
              [-104.15498046875, 78.81396484375],
              [-103.875634765625, 78.902685546875],
              [-103.887158203125, 78.918798828125],
              [-104.0072265625, 78.9478515625],
              [-104.112744140625, 78.985595703125],
              [-104.151953125, 78.989892578125],
              [-104.39482421875, 78.95615234375],
              [-104.73603515625, 78.825927734375],
              [-104.817431640625, 78.807080078125],
              [-104.8955078125, 78.808154296875],
              [-104.97021484375, 78.829150390625],
              [-104.96953125, 78.856494140625],
              [-104.89340820312499, 78.890185546875],
              [-104.73525390625, 78.99111328125],
              [-104.74677734375, 79.027099609375],
              [-104.9013671875, 79.051123046875],
              [-105.3087890625, 79.033203125],
              [-105.53564453125, 79.03251953125],
              [-105.570751953125, 79.060986328125],
              [-105.58017578124999, 79.114208984375],
              [-105.571044921875, 79.164208984375],
              [-105.51455078125, 79.24248046875],
              [-105.435693359375, 79.30224609375],
              [-105.3876953125, 79.323583984375],
              [-104.84736328125, 79.310986328125],
              [-103.964599609375, 79.34814453125],
              [-103.70639648437499, 79.35205078125],
              [-103.426025390625, 79.315625],
            ],
          ],
          [
            [
              [-91.88554687499999, 81.132861328125],
              [-91.75498046874999, 81.04931640625],
              [-91.2724609375, 80.85009765625],
              [-91.05390625, 80.777685546875],
              [-90.68291015624999, 80.6876953125],
              [-90.63671875, 80.655322265625],
              [-90.632470703125, 80.64169921875],
              [-90.643017578125, 80.593701171875],
              [-90.537255859375, 80.575927734375],
              [-90.21762695312499, 80.5482421875],
              [-89.86186523437499, 80.4984375],
              [-89.7978515625, 80.50126953125],
              [-89.673828125, 80.53076171875],
              [-89.5248046875, 80.538818359375],
              [-89.329052734375, 80.53173828125],
              [-89.235595703125, 80.51064453125],
              [-89.16689453125, 80.479638671875],
              [-89.13828125, 80.457421875],
              [-89.1341796875, 80.440234375],
              [-89.20439453124999, 80.40693359375],
              [-89.19658203124999, 80.39404296875],
              [-89.1546875, 80.378515625],
              [-89.14726562499999, 80.3603515625],
              [-89.21767578125, 80.2892578125],
              [-89.19833984374999, 80.26318359375],
              [-89.01923828125, 80.198486328125],
              [-88.85732421875, 80.1662109375],
              [-88.537548828125, 80.13115234375],
              [-88.329248046875, 80.13369140625],
              [-88.19990234375, 80.111474609375],
              [-88.19682617187499, 80.1251953125],
              [-88.25537109375, 80.16650390625],
              [-88.38076171875, 80.2251953125],
              [-88.6125, 80.25537109375],
              [-88.646240234375, 80.28974609375],
              [-88.66342773437499, 80.348291015625],
              [-88.64365234374999, 80.386865234375],
              [-88.5248046875, 80.418017578125],
              [-88.42436523437499, 80.428076171875],
              [-88.125244140625, 80.4294921875],
              [-87.96000976562499, 80.415625],
              [-87.675, 80.372119140625],
              [-87.6455078125, 80.3484375],
              [-87.6302734375, 80.301611328125],
              [-87.618359375, 80.207470703125],
              [-87.62548828125, 80.18720703125],
              [-87.869140625, 80.13388671875],
              [-87.922314453125, 80.097705078125],
              [-87.86069335937499, 80.0875],
              [-87.6513671875, 80.079443359375],
              [-87.328515625, 80.046533203125],
              [-87.20205078125, 80.043212890625],
              [-87.076171875, 79.966943359375],
              [-86.977197265625, 79.89423828125],
              [-87.04951171875, 79.805419921875],
              [-87.14423828125, 79.662646484375],
              [-87.22026367187499, 79.629931640625],
              [-87.295166015625, 79.58017578125],
              [-87.24287109375, 79.571142578125],
              [-86.925244140625, 79.590966796875],
              [-86.86103515625, 79.597705078125],
              [-86.648828125, 79.646240234375],
              [-86.33696289062499, 79.6349609375],
              [-86.23222656249999, 79.622412109375],
              [-86.18046874999999, 79.605419921875],
              [-86.085546875, 79.551220703125],
              [-86.00703125, 79.479443359375],
              [-85.948974609375, 79.485986328125],
              [-85.803857421875, 79.573046875],
              [-85.750927734375, 79.59453125],
              [-85.67861328125, 79.615283203125],
              [-85.6478515625, 79.61142578125],
              [-85.5013671875, 79.530322265625],
              [-85.17558593749999, 79.387255859375],
              [-85.06376953124999, 79.328173828125],
              [-85.042138671875, 79.2845703125],
              [-85.18134765625, 79.233740234375],
              [-85.28984374999999, 79.208349609375],
              [-86.09165039062499, 79.1],
              [-86.45053710937499, 79.038671875],
              [-86.62944335937499, 78.99130859375],
              [-86.72080078124999, 78.97548828125],
              [-86.9134765625, 78.9828125],
              [-86.957177734375, 78.97490234375],
              [-87.01645507812499, 78.898681640625],
              [-87.08037109374999, 78.86611328125],
              [-87.24638671874999, 78.8134765625],
              [-87.478759765625, 78.7181640625],
              [-87.6173828125, 78.676318359375],
              [-87.861474609375, 78.7068359375],
              [-87.922314453125, 78.7513671875],
              [-87.95625, 78.851611328125],
              [-87.96074218749999, 78.893115234375],
              [-87.95317382812499, 78.9150390625],
              [-87.922607421875, 78.9505859375],
              [-87.81674804687499, 79.036328125],
              [-87.82939453124999, 79.0453125],
              [-87.878369140625, 79.03818359375],
              [-88.040185546875, 78.9953125],
              [-88.10405273437499, 78.972802734375],
              [-88.163818359375, 78.93349609375],
              [-88.19023437499999, 78.867431640625],
              [-88.16660156249999, 78.7455078125],
              [-88.189697265625, 78.69638671875],
              [-88.25375976562499, 78.67197265625],
              [-88.22788085937499, 78.65302734375],
              [-88.03701171875, 78.626953125],
              [-88.003125, 78.61552734375],
              [-87.98198242187499, 78.5947265625],
              [-87.9736328125, 78.56474609375],
              [-87.982861328125, 78.537060546875],
              [-88.040234375, 78.49443359375],
              [-88.14755859374999, 78.477099609375],
              [-88.2845703125, 78.496533203125],
              [-88.58066406249999, 78.601904296875],
              [-88.70927734374999, 78.59609375],
              [-88.7416015625, 78.584033203125],
              [-88.71396484374999, 78.546435546875],
              [-88.623046875, 78.462109375],
              [-88.6064453125, 78.3919921875],
              [-88.648388671875, 78.333740234375],
              [-88.73295898437499, 78.24169921875],
              [-88.791015625, 78.192431640625],
              [-88.822412109375, 78.185888671875],
              [-88.96962890625, 78.184423828125],
              [-89.095703125, 78.209228515625],
              [-89.47001953124999, 78.37021484375],
              [-89.6552734375, 78.4388671875],
              [-89.926220703125, 78.573046875],
              [-89.995361328125, 78.60068359375],
              [-90.037109375, 78.6068359375],
              [-90.076318359375, 78.549169921875],
              [-90.00102539062499, 78.49580078125],
              [-89.757275390625, 78.37021484375],
              [-89.61166992187499, 78.27890625],
              [-89.5068359375, 78.203271484375],
              [-89.48984375, 78.171923828125],
              [-89.52568359374999, 78.159619140625],
              [-89.5794921875, 78.1666015625],
              [-89.651123046875, 78.193017578125],
              [-89.873046875, 78.23759765625],
              [-89.96518554687499, 78.262451171875],
              [-90.02543945312499, 78.291259765625],
              [-90.13608398437499, 78.3130859375],
              [-90.29721679687499, 78.32802734375],
              [-90.45903320312499, 78.330908203125],
              [-90.62158203125, 78.321728515625],
              [-90.65239257812499, 78.30771484375],
              [-90.469189453125, 78.2685546875],
              [-90.405419921875, 78.2466796875],
              [-90.35795898437499, 78.21875],
              [-90.32675781249999, 78.184765625],
              [-90.386962890625, 78.16328125],
              [-90.614404296875, 78.149853515625],
              [-90.918115234375, 78.1583984375],
              [-91.409619140625, 78.18798828125],
              [-91.899169921875, 78.236865234375],
              [-92.35126953125, 78.312890625],
              [-92.67827148437499, 78.389111328125],
              [-92.8076171875, 78.429736328125],
              [-92.84824218749999, 78.460107421875],
              [-92.7255859375, 78.486669921875],
              [-92.29672851562499, 78.52080078125],
              [-91.86689453125, 78.54267578125],
              [-91.9349609375, 78.56171875],
              [-92.71552734375, 78.605029296875],
              [-92.97250976562499, 78.612939453125],
              [-93.109375, 78.6015625],
              [-93.2666015625, 78.60830078125],
              [-93.38945312499999, 78.64267578125],
              [-93.55205078124999, 78.7078125],
              [-93.634423828125, 78.750927734375],
              [-93.62333984374999, 78.7677734375],
              [-93.56142578125, 78.77734375],
              [-93.20834960937499, 78.769189453125],
              [-93.15986328125, 78.775634765625],
              [-93.33647460937499, 78.808056640625],
              [-93.90224609375, 78.872216796875],
              [-94.11459960937499, 78.92890625],
              [-94.15361328124999, 78.951025390625],
              [-94.169677734375, 78.972802734375],
              [-94.16279296875, 78.994189453125],
              [-93.9501953125, 79.03740234375],
              [-93.293896484375, 79.139501953125],
              [-93.06845703124999, 79.15537109375],
              [-92.84160156249999, 79.156396484375],
              [-92.68364257812499, 79.185791015625],
              [-92.54721679687499, 79.2826171875],
              [-91.867578125, 79.317431640625],
              [-91.34365234375, 79.360888671875],
              [-91.29990234374999, 79.372705078125],
              [-91.692626953125, 79.36474609375],
              [-92.24794921875, 79.3734375],
              [-92.4845703125, 79.4392578125],
              [-92.6447265625, 79.450439453125],
              [-92.821923828125, 79.44990234375],
              [-93.028125, 79.429248046875],
              [-93.380859375, 79.3681640625],
              [-93.550439453125, 79.353955078125],
              [-93.93315429687499, 79.29072265625],
              [-94.03984374999999, 79.29521484375],
              [-94.093359375, 79.302734375],
              [-94.109375, 79.315087890625],
              [-94.040283203125, 79.35703125],
              [-93.939697265625, 79.385693359375],
              [-93.96025390624999, 79.3955078125],
              [-94.110302734375, 79.4015625],
              [-94.284130859375, 79.400439453125],
              [-94.40488281249999, 79.39052734375],
              [-94.84604492187499, 79.33505859375],
              [-95.043701171875, 79.2935546875],
              [-95.103173828125, 79.289892578125],
              [-95.3166015625, 79.354736328125],
              [-95.65703124999999, 79.390380859375],
              [-95.73300781249999, 79.418212890625],
              [-95.66289062499999, 79.52734375],
              [-95.5634765625, 79.549755859375],
              [-95.30234375, 79.56806640625],
              [-94.519677734375, 79.667138671875],
              [-94.475537109375, 79.686181640625],
              [-94.40185546875, 79.736328125],
              [-94.58085937499999, 79.725634765625],
              [-94.973046875, 79.677197265625],
              [-95.29697265624999, 79.653076171875],
              [-95.552490234375, 79.65322265625],
              [-95.73935546874999, 79.66015625],
              [-95.85751953124999, 79.673779296875],
              [-95.999658203125, 79.7046875],
              [-96.46274414062499, 79.847509765625],
              [-96.5890625, 79.916650390625],
              [-96.60673828124999, 79.977685546875],
              [-96.63920898437499, 80.024169921875],
              [-96.77324218749999, 80.135791015625],
              [-95.781982421875, 80.06640625],
              [-95.39384765624999, 80.053271484375],
              [-94.6458984375, 80.04873046875],
              [-94.61083984375, 80.055517578125],
              [-94.5998046875, 80.0736328125],
              [-94.6126953125, 80.102978515625],
              [-94.60698242187499, 80.1255859375],
              [-94.58261718749999, 80.14140625],
              [-94.304443359375, 80.181640625],
              [-94.26259765625, 80.194873046875],
              [-94.59013671874999, 80.201513671875],
              [-95.1923828125, 80.134375],
              [-95.40507812499999, 80.135009765625],
              [-95.646240234375, 80.23095703125],
              [-95.90400390625, 80.214111328125],
              [-96.02568359374999, 80.221728515625],
              [-96.215087890625, 80.2458984375],
              [-96.30830078125, 80.2669921875],
              [-96.368408203125, 80.29306640625],
              [-96.39409179687499, 80.3150390625],
              [-96.38535156249999, 80.332861328125],
              [-96.334375, 80.352783203125],
              [-96.11215820312499, 80.380419921875],
              [-96.011865234375, 80.383056640625],
              [-95.74736328124999, 80.365283203125],
              [-95.549072265625, 80.3666015625],
              [-95.614453125, 80.396240234375],
              [-95.90107421875, 80.470849609375],
              [-96.151806640625, 80.553466796875],
              [-96.1328125, 80.69140625],
              [-95.926953125, 80.720654296875],
              [-95.713623046875, 80.725439453125],
              [-95.5052734375, 80.690576171875],
              [-95.225830078125, 80.685791015625],
              [-95.02573242187499, 80.646435546875],
              [-94.892578125, 80.5708984375],
              [-94.73447265624999, 80.57236328125],
              [-94.48540039062499, 80.558056640625],
              [-93.9279296875, 80.5591796875],
              [-94.0287109375, 80.586181640625],
              [-94.2021484375, 80.609716796875],
              [-94.59628906249999, 80.640625],
              [-94.7884765625, 80.75126953125],
              [-95.19584960937499, 80.80830078125],
              [-95.51474609374999, 80.838134765625],
              [-95.50927734375, 80.863232421875],
              [-95.269775390625, 81.00078125],
              [-94.98051757812499, 81.049658203125],
              [-94.51943359375, 81.031201171875],
              [-94.21630859375, 81.057177734375],
              [-93.82597656249999, 81.105712890625],
              [-93.44375, 81.083251953125],
              [-93.3451171875, 81.0853515625],
              [-93.28671875, 81.10029296875],
              [-93.23564453124999, 81.128857421875],
              [-93.23540039062499, 81.155126953125],
              [-93.28593749999999, 81.179248046875],
              [-93.40654296874999, 81.20908203125],
              [-93.89443359375, 81.21328125],
              [-94.1103515625, 81.225],
              [-94.19443359374999, 81.24091796875],
              [-94.21865234375, 81.26494140625],
              [-94.23149414062499, 81.289697265625],
              [-94.23295898437499, 81.31513671875],
              [-94.2201171875, 81.33076171875],
              [-94.179345703125, 81.3392578125],
              [-94.059716796875, 81.34931640625],
              [-93.6048828125, 81.3505859375],
              [-93.332763671875, 81.364404296875],
              [-93.03466796875, 81.3462890625],
              [-92.41259765625, 81.278271484375],
              [-92.21176757812499, 81.243603515625],
              [-91.99785156249999, 81.185498046875],
              [-91.88554687499999, 81.132861328125],
            ],
          ],
          [
            [
              [-94.29497070312499, 76.912451171875],
              [-94.10795898437499, 76.903759765625],
              [-93.94804687499999, 76.91708984375],
              [-93.8109375, 76.91416015625],
              [-93.6083984375, 76.873828125],
              [-93.42075195312499, 76.81220703125],
              [-93.2765625, 76.784326171875],
              [-93.23002929687499, 76.770263671875],
              [-93.211865234375, 76.7546875],
              [-93.18925781249999, 76.7080078125],
              [-93.18999023437499, 76.686376953125],
              [-93.2005859375, 76.669091796875],
              [-93.263671875, 76.62646484375],
              [-93.31674804687499, 76.573681640625],
              [-93.42626953125, 76.5271484375],
              [-93.4845703125, 76.492041015625],
              [-93.5345703125, 76.447705078125],
              [-93.421875, 76.47412109375],
              [-92.995361328125, 76.62041015625],
              [-92.716259765625, 76.602978515625],
              [-92.297021484375, 76.616015625],
              [-91.789453125, 76.67578125],
              [-91.5484375, 76.685107421875],
              [-91.30502929687499, 76.68076171875],
              [-91.124267578125, 76.6619140625],
              [-90.738427734375, 76.58134765625],
              [-90.60478515624999, 76.54296875],
              [-90.554638671875, 76.515771484375],
              [-90.542626953125, 76.495751953125],
              [-90.62163085937499, 76.464697265625],
              [-90.86406249999999, 76.48359375],
              [-91.26303710937499, 76.500244140625],
              [-91.33598632812499, 76.510595703125],
              [-91.398095703125, 76.509765625],
              [-91.44326171875, 76.49853515625],
              [-91.41508789062499, 76.455859375],
              [-91.33388671875, 76.446484375],
              [-90.85478515624999, 76.4373046875],
              [-89.28452148437499, 76.301611328125],
              [-89.21909179687499, 76.258203125],
              [-89.2365234375, 76.239013671875],
              [-89.29208984374999, 76.217724609375],
              [-89.40659179687499, 76.18916015625],
              [-90.31206054687499, 76.1580078125],
              [-90.82734375, 76.185595703125],
              [-91.260400390625, 76.22998046875],
              [-91.40732421874999, 76.220068359375],
              [-91.279443359375, 76.159912109375],
              [-91.019775390625, 76.141552734375],
              [-90.802392578125, 76.10595703125],
              [-90.712109375, 76.076171875],
              [-90.2513671875, 76.053466796875],
              [-90.176025390625, 76.0302734375],
              [-90.03276367187499, 75.9708984375],
              [-89.912548828125, 75.96630859375],
              [-89.793408203125, 75.924853515625],
              [-89.6953125, 75.85361328125],
              [-89.650048828125, 75.844091796875],
              [-89.51123046875, 75.85693359375],
              [-89.277587890625, 75.795068359375],
              [-89.20488281249999, 75.76201171875],
              [-89.204541015625, 75.737255859375],
              [-89.25664062499999, 75.698486328125],
              [-89.36123046875, 75.64580078125],
              [-89.625439453125, 75.583740234375],
              [-89.64604492187499, 75.5650390625],
              [-89.3373046875, 75.57236328125],
              [-89.280419921875, 75.564111328125],
              [-88.91669921875, 75.453955078125],
              [-88.86884765625, 75.451953125],
              [-88.83891601562499, 75.4634765625],
              [-88.8041015625, 75.502490234375],
              [-88.81962890624999, 75.53857421875],
              [-88.86406249999999, 75.588623046875],
              [-88.85214843749999, 75.62490234375],
              [-88.783935546875, 75.6474609375],
              [-88.71489257812499, 75.658642578125],
              [-88.644970703125, 75.658447265625],
              [-88.56904296875, 75.6451171875],
              [-88.201318359375, 75.51201171875],
              [-87.729736328125, 75.575634765625],
              [-87.64365234374999, 75.5470703125],
              [-87.572412109375, 75.49365234375],
              [-87.53911132812499, 75.48486328125],
              [-87.3646484375, 75.59130859375],
              [-87.25693359374999, 75.617724609375],
              [-86.814453125, 75.491357421875],
              [-86.54472656249999, 75.46337890625],
              [-86.4365234375, 75.436279296875],
              [-86.236328125, 75.40634765625],
              [-85.95146484374999, 75.39501953125],
              [-85.904541015625, 75.441943359375],
              [-86.06875, 75.50224609375],
              [-85.97299804687499, 75.5287109375],
              [-85.58125, 75.57978515625],
              [-85.372314453125, 75.572607421875],
              [-84.986767578125, 75.644921875],
              [-84.7501953125, 75.6546875],
              [-84.6048828125, 75.653466796875],
              [-84.12763671875, 75.762646484375],
              [-84.01425781249999, 75.779931640625],
              [-83.93198242187499, 75.8189453125],
              [-83.744580078125, 75.812841796875],
              [-83.23710937499999, 75.750830078125],
              [-83.093408203125, 75.7564453125],
              [-82.553466796875, 75.81826171875],
              [-82.35385742187499, 75.833349609375],
              [-82.15366210937499, 75.8310546875],
              [-81.64736328125, 75.794921875],
              [-81.2685546875, 75.756005859375],
              [-81.15078125, 75.735546875],
              [-81.19267578124999, 75.684375],
              [-81.17353515625, 75.66923828125],
              [-81.1244140625, 75.658154296875],
              [-81.00078124999999, 75.643115234375],
              [-80.527734375, 75.642138671875],
              [-80.32197265625, 75.6291015625],
              [-80.158349609375, 75.58115234375],
              [-80.11918945312499, 75.562060546875],
              [-80.125732421875, 75.542138671875],
              [-80.28662109375, 75.490380859375],
              [-80.26044921875, 75.479443359375],
              [-80.099609375, 75.467431640625],
              [-79.7376953125, 75.461474609375],
              [-79.66020507812499, 75.44951171875],
              [-79.58574218749999, 75.38486328125],
              [-79.5078125, 75.295361328125],
              [-79.50908203124999, 75.259814453125],
              [-79.634423828125, 75.19931640625],
              [-79.97714843749999, 75.118603515625],
              [-80.357568359375, 75.0515625],
              [-80.381982421875, 75.0341796875],
              [-80.26064453125, 75.0021484375],
              [-80.13525390625, 74.9880859375],
              [-80.03642578124999, 74.99091796875],
              [-79.73300781249999, 75.021435546875],
              [-79.6640625, 75.020849609375],
              [-79.5248046875, 74.989697265625],
              [-79.460400390625, 74.9587890625],
              [-79.40141601562499, 74.917626953125],
              [-79.507958984375, 74.880126953125],
              [-79.944482421875, 74.833642578125],
              [-80.20244140624999, 74.89482421875],
              [-80.289208984375, 74.90830078125],
              [-80.34775390624999, 74.902978515625],
              [-80.31455078124999, 74.876171875],
              [-80.18974609374999, 74.827685546875],
              [-80.14892578125, 74.795703125],
              [-80.19223632812499, 74.78017578125],
              [-80.21269531249999, 74.749462890625],
              [-80.21025390624999, 74.70361328125],
              [-80.22060546875, 74.65703125],
              [-80.26274414062499, 74.58447265625],
              [-80.277734375, 74.581591796875],
              [-81.226220703125, 74.566650390625],
              [-81.340478515625, 74.553515625],
              [-81.607177734375, 74.50234375],
              [-81.808837890625, 74.476611328125],
              [-81.940185546875, 74.472705078125],
              [-82.068505859375, 74.482080078125],
              [-82.41474609375, 74.535205078125],
              [-82.64541015625, 74.5251953125],
              [-82.735791015625, 74.5302734375],
              [-82.9310546875, 74.565576171875],
              [-82.97841796875, 74.583447265625],
              [-83.0576171875, 74.62978515625],
              [-83.11699218749999, 74.693115234375],
              [-83.1123046875, 74.73212890625],
              [-83.0873046875, 74.78837890625],
              [-83.10263671874999, 74.816552734375],
              [-83.15830078124999, 74.816748046875],
              [-83.2203125, 74.82841796875],
              [-83.40703124999999, 74.884814453125],
              [-83.52207031249999, 74.90146484375],
              [-83.54355468749999, 74.89228515625],
              [-83.509765625, 74.848193359375],
              [-83.4873046875, 74.834130859375],
              [-83.364208984375, 74.801904296875],
              [-83.34130859375, 74.764599609375],
              [-83.393701171875, 74.670166015625],
              [-83.41220703124999, 74.65498046875],
              [-83.531884765625, 74.585693359375],
              [-83.621875, 74.56591796875],
              [-83.86806640625, 74.564404296875],
              [-84.24516601562499, 74.515185546875],
              [-84.425537109375, 74.50810546875],
              [-84.66708984374999, 74.519580078125],
              [-84.81826171875, 74.5419921875],
              [-84.91630859374999, 74.56767578125],
              [-85.01152343749999, 74.60419921875],
              [-85.06142578125, 74.60693359375],
              [-85.08676757812499, 74.527685546875],
              [-85.133447265625, 74.517431640625],
              [-85.214306640625, 74.51865234375],
              [-85.3392578125, 74.543310546875],
              [-85.442333984375, 74.6005859375],
              [-85.47441406249999, 74.600341796875],
              [-85.488671875, 74.5669921875],
              [-85.51171875, 74.5451171875],
              [-85.54350585937499, 74.534765625],
              [-85.8080078125, 74.498974609375],
              [-85.95561523437499, 74.498779296875],
              [-86.10986328125, 74.53974609375],
              [-86.2109375, 74.535595703125],
              [-86.340576171875, 74.5134765625],
              [-86.655419921875, 74.555419921875],
              [-86.73076171874999, 74.55703125],
              [-86.66611328124999, 74.489111328125],
              [-86.770166015625, 74.47861328125],
              [-86.99472656249999, 74.480322265625],
              [-87.36376953125, 74.502197265625],
              [-87.59257812499999, 74.470361328125],
              [-88.005859375, 74.48935546875],
              [-88.423046875, 74.494140625],
              [-88.500732421875, 74.509716796875],
              [-88.5556640625, 74.541455078125],
              [-88.557861328125, 74.5697265625],
              [-88.53764648437499, 74.6087890625],
              [-88.47661132812499, 74.66689453125],
              [-88.37470703125, 74.744140625],
              [-88.33955078125, 74.78486328125],
              [-88.43144531249999, 74.8037109375],
              [-88.48818359375, 74.82890625],
              [-88.53496093749999, 74.83173828125],
              [-88.68203125, 74.802001953125],
              [-88.77783203125, 74.715185546875],
              [-88.85107421875, 74.689990234375],
              [-88.88339843749999, 74.711083984375],
              [-88.9078125, 74.763818359375],
              [-88.94013671875, 74.789501953125],
              [-88.98037109375, 74.7880859375],
              [-89.01962890624999, 74.7740234375],
              [-89.057861328125, 74.747265625],
              [-89.11528320312499, 74.73759765625],
              [-89.19194335937499, 74.744873046875],
              [-89.219140625, 74.731787109375],
              [-89.19682617187499, 74.6982421875],
              [-89.1890625, 74.666845703125],
              [-89.19580078125, 74.637548828125],
              [-89.261865234375, 74.6091796875],
              [-89.45, 74.567919921875],
              [-89.55869140624999, 74.554736328125],
              [-89.844384765625, 74.548583984375],
              [-90.01533203125, 74.560888671875],
              [-90.36162109374999, 74.61044921875],
              [-90.55327148437499, 74.612744140625],
              [-90.78408203125, 74.6958984375],
              [-90.966796875, 74.715087890625],
              [-90.95751953125, 74.745166015625],
              [-90.87763671875, 74.80107421875],
              [-90.88022460937499, 74.8177734375],
              [-91.1298828125, 74.736279296875],
              [-91.163720703125, 74.71025390625],
              [-91.13457031249999, 74.649853515625],
              [-91.167724609375, 74.6455078125],
              [-91.339453125, 74.667236328125],
              [-91.50834960937499, 74.65068359375],
              [-91.54912109374999, 74.65556640625],
              [-91.665771484375, 74.699169921875],
              [-91.871044921875, 74.743505859375],
              [-91.961572265625, 74.793212890625],
              [-92.1025390625, 74.948388671875],
              [-92.17416992187499, 75.05107421875],
              [-92.165234375, 75.072021484375],
              [-92.060498046875, 75.1009765625],
              [-92.076318359375, 75.12353515625],
              [-92.20683593749999, 75.18125],
              [-92.34746093749999, 75.22978515625],
              [-92.38925781249999, 75.263330078125],
              [-92.408349609375, 75.297265625],
              [-92.42709960937499, 75.34638671875],
              [-92.427978515625, 75.38271484375],
              [-92.411083984375, 75.40625],
              [-92.33066406249999, 75.479443359375],
              [-92.11044921874999, 75.61064453125],
              [-92.080712890625, 75.63447265625],
              [-92.06884765625, 75.65791015625],
              [-92.09916992187499, 75.727294921875],
              [-92.141845703125, 75.796826171875],
              [-92.18510742187499, 75.846533203125],
              [-92.306591796875, 75.91513671875],
              [-92.47373046874999, 75.986474609375],
              [-92.70888671875, 76.114453125],
              [-92.88330078125, 76.21396484375],
              [-93.091748046875, 76.35400390625],
              [-93.19228515625, 76.366015625],
              [-93.30859375, 76.359619140625],
              [-93.5599609375, 76.31142578125],
              [-93.64443359375, 76.288525390625],
              [-93.665185546875, 76.27314453125],
              [-93.85234374999999, 76.269677734375],
              [-94.382568359375, 76.28232421875],
              [-94.5853515625, 76.29716796875],
              [-94.73671875, 76.29326171875],
              [-94.99663085937499, 76.25771484375],
              [-95.273876953125, 76.264404296875],
              [-95.447412109375, 76.363037109375],
              [-95.84165039062499, 76.416162109375],
              [-95.95927734374999, 76.44599609375],
              [-96.039697265625, 76.48671875],
              [-96.0130859375, 76.513330078125],
              [-95.78886718749999, 76.53720703125],
              [-95.695703125, 76.563427734375],
              [-95.65097656249999, 76.58466796875],
              [-95.873193359375, 76.56640625],
              [-95.971337890625, 76.56962890625],
              [-96.63969726562499, 76.7029296875],
              [-96.845654296875, 76.726416015625],
              [-96.88071289062499, 76.738330078125],
              [-96.89799804687499, 76.75400390625],
              [-96.89755859374999, 76.773486328125],
              [-96.87802734374999, 76.802783203125],
              [-96.67939453125, 76.765771484375],
              [-96.590283203125, 76.763037109375],
              [-96.451171875, 76.774072265625],
              [-96.4015625, 76.797216796875],
              [-96.433203125, 76.810693359375],
              [-96.66103515625, 76.85517578125],
              [-96.771142578125, 76.888916015625],
              [-96.81352539062499, 76.9134765625],
              [-96.76982421874999, 76.9482421875],
              [-96.75830078125, 76.97177734375],
              [-96.68510742187499, 76.985009765625],
              [-96.55009765624999, 76.987939453125],
              [-96.37729492187499, 77.00458984375],
              [-96.06123046875, 77.050048828125],
              [-95.84951171875, 77.0662109375],
              [-95.63823242187499, 77.06376953125],
              [-95.126416015625, 77.017333984375],
              [-94.61611328125, 76.958349609375],
              [-94.29497070312499, 76.912451171875],
            ],
          ],
          [
            [
              [-96.2044921875, 78.531298828125],
              [-95.96845703125, 78.505126953125],
              [-95.56113281249999, 78.5166015625],
              [-95.41293945312499, 78.49755859375],
              [-95.031201171875, 78.4302734375],
              [-94.91538085937499, 78.39052734375],
              [-94.88774414062499, 78.360498046875],
              [-94.887158203125, 78.34521484375],
              [-95.0138671875, 78.31259765625],
              [-95.26787109374999, 78.262646484375],
              [-95.329248046875, 78.225048828125],
              [-95.102734375, 78.178076171875],
              [-94.98779296875, 78.136279296875],
              [-94.93603515625, 78.106396484375],
              [-94.93427734375, 78.075634765625],
              [-95.08701171874999, 77.992626953125],
              [-95.19912109375, 77.9681640625],
              [-95.3705078125, 77.97080078125],
              [-95.4515625, 77.963232421875],
              [-95.67080078125, 77.924462890625],
              [-96.01157226562499, 77.88740234375],
              [-96.47685546874999, 77.87216796875],
              [-96.60302734375, 77.84931640625],
              [-96.833984375, 77.8119140625],
              [-96.9896484375, 77.806005859375],
              [-97.040478515625, 77.82744140625],
              [-97.06381835937499, 77.85908203125],
              [-97.051953125, 77.88095703125],
              [-97.01909179687499, 77.90810546875],
              [-97.09331054687499, 77.93349609375],
              [-97.42666015625, 77.982275390625],
              [-97.620849609375, 78.050244140625],
              [-97.648388671875, 78.071630859375],
              [-97.658154296875, 78.090625],
              [-97.22661132812499, 78.10322265625],
              [-97.04091796875, 78.116943359375],
              [-96.95834960937499, 78.139013671875],
              [-96.94467773437499, 78.15185546875],
              [-97.02734375, 78.157421875],
              [-97.32304687499999, 78.20322265625],
              [-97.81904296875, 78.230615234375],
              [-97.842724609375, 78.262353515625],
              [-98.04951171875, 78.325927734375],
              [-98.069287109375, 78.386328125],
              [-98.11430664062499, 78.40302734375],
              [-98.25493164062499, 78.429248046875],
              [-98.27568359374999, 78.437890625],
              [-98.317333984375, 78.47685546875],
              [-98.32373046875, 78.49814453125],
              [-98.315625, 78.51748046875],
              [-98.06035156249999, 78.558349609375],
              [-98.09599609374999, 78.586669921875],
              [-98.28989257812499, 78.6923828125],
              [-98.3408203125, 78.751220703125],
              [-98.33261718749999, 78.77353515625],
              [-98.212109375, 78.804541015625],
              [-98.04287109375, 78.805224609375],
              [-97.5958984375, 78.79580078125],
              [-97.38232421875, 78.78291015625],
              [-97.1693359375, 78.757666015625],
              [-96.935791015625, 78.720263671875],
              [-96.587060546875, 78.687109375],
              [-96.475341796875, 78.665185546875],
              [-96.265283203125, 78.595361328125],
              [-96.242626953125, 78.573193359375],
              [-96.256494140625, 78.551123046875],
              [-96.2044921875, 78.531298828125],
            ],
          ],
          [
            [
              [-93.170849609375, 74.160986328125],
              [-92.77802734375, 74.113720703125],
              [-92.58681640625, 74.08271484375],
              [-92.492822265625, 74.062060546875],
              [-92.3138671875, 73.9923828125],
              [-92.22270507812499, 73.97236328125],
              [-91.874169921875, 74.01279296875],
              [-91.63041992187499, 74.027783203125],
              [-91.08798828124999, 74.00927734375],
              [-90.62744140625, 73.951708984375],
              [-90.4580078125, 73.9083984375],
              [-90.35458984374999, 73.86865234375],
              [-90.38139648437499, 73.824755859375],
              [-90.46616210937499, 73.753857421875],
              [-90.565576171875, 73.68642578125],
              [-90.76455078125, 73.580615234375],
              [-90.93369140624999, 73.527685546875],
              [-90.97548828125, 73.502294921875],
              [-91.001953125, 73.46708984375],
              [-91.067626953125, 73.41552734375],
              [-91.24931640624999, 73.30400390625],
              [-91.297802734375, 73.284912109375],
              [-91.5537109375, 73.236083984375],
              [-91.466015625, 73.214208984375],
              [-91.425927734375, 73.194873046875],
              [-91.459619140625, 73.145361328125],
              [-91.62099609375, 73.02587890625],
              [-91.788330078125, 72.915380859375],
              [-91.90532226562499, 72.84931640625],
              [-92.117919921875, 72.75380859375],
              [-92.23491210937499, 72.726806640625],
              [-92.39194335937499, 72.71845703125],
              [-93.340625, 72.80185546875],
              [-93.57866210937499, 72.800537109375],
              [-94.211328125, 72.75693359375],
              [-94.15170898437499, 72.73564453125],
              [-93.92001953124999, 72.703369140625],
              [-93.77055664062499, 72.668212890625],
              [-93.572265625, 72.558642578125],
              [-93.546484375, 72.531298828125],
              [-93.533935546875, 72.499462890625],
              [-93.54160156249999, 72.43701171875],
              [-93.55517578125, 72.421142578125],
              [-93.87060546875, 72.25263671875],
              [-93.97255859375, 72.12998046875],
              [-94.037548828125, 72.028759765625],
              [-94.14375, 72.000830078125],
              [-94.49716796874999, 72.043603515625],
              [-94.61123046875, 72.042333984375],
              [-95.00786132812499, 72.01279296875],
              [-95.19296875, 72.02744140625],
              [-95.16679687499999, 72.180029296875],
              [-95.19267578124999, 72.344775390625],
              [-95.25102539062499, 72.501953125],
              [-95.547607421875, 72.78154296875],
              [-95.580322265625, 72.83115234375],
              [-95.60214843749999, 72.88447265625],
              [-95.61318359375, 72.9416015625],
              [-95.61220703125, 72.999072265625],
              [-95.59160156249999, 73.115283203125],
              [-95.5892578125, 73.174169921875],
              [-95.60410156249999, 73.327734375],
              [-95.64423828125, 73.557470703125],
              [-95.64799804687499, 73.638525390625],
              [-95.645263671875, 73.67080078125],
              [-95.63291015624999, 73.695458984375],
              [-95.56943359374999, 73.728173828125],
              [-95.447412109375, 73.75166015625],
              [-95.385986328125, 73.755126953125],
              [-94.99614257812499, 73.6857421875],
              [-94.816845703125, 73.662548828125],
              [-94.69760742187499, 73.66357421875],
              [-94.691015625, 73.671435546875],
              [-94.79716796874999, 73.686083984375],
              [-94.896923828125, 73.716015625],
              [-95.05947265625, 73.805078125],
              [-95.13413085937499, 73.88125],
              [-95.14902343749999, 73.906396484375],
              [-95.152587890625, 73.932763671875],
              [-95.144775390625, 73.960302734375],
              [-95.12119140624999, 73.98505859375],
              [-95.03984374999999, 74.023876953125],
              [-94.97353515625, 74.04140625],
              [-94.728955078125, 74.085986328125],
              [-94.482568359375, 74.113134765625],
              [-93.93881835937499, 74.131591796875],
              [-93.784619140625, 74.118359375],
              [-93.54921875, 74.167138671875],
              [-93.410302734375, 74.178759765625],
              [-93.170849609375, 74.160986328125],
            ],
          ],
          [
            [
              [-97.439453125, 69.64267578125],
              [-97.408642578125, 69.63076171875],
              [-97.35068359374999, 69.640869140625],
              [-97.30576171874999, 69.673486328125],
              [-97.278466796875, 69.679638671875],
              [-97.236083984375, 69.673486328125],
              [-97.096337890625, 69.614990234375],
              [-96.98906249999999, 69.55361328125],
              [-96.8751953125, 69.510009765625],
              [-96.69453125, 69.47109375],
              [-96.299951171875, 69.344384765625],
              [-96.183740234375, 69.25869140625],
              [-96.060986328125, 69.125439453125],
              [-95.9513671875, 69.02373046875],
              [-95.8548828125, 68.953564453125],
              [-95.7513671875, 68.89765625],
              [-95.58549804687499, 68.835107421875],
              [-95.43754882812499, 68.880615234375],
              [-95.374169921875, 68.892138671875],
              [-95.31953125, 68.873193359375],
              [-95.2677734375, 68.82607421875],
              [-95.295166015625, 68.805029296875],
              [-95.35947265624999, 68.778369140625],
              [-95.465576171875, 68.747265625],
              [-95.614208984375, 68.74501953125],
              [-95.68564453124999, 68.73583984375],
              [-95.8021484375, 68.686474609375],
              [-95.89462890624999, 68.62724609375],
              [-96.02402343749999, 68.607275390625],
              [-96.26762695312499, 68.50791015625],
              [-96.4015625, 68.470703125],
              [-96.598828125, 68.46083984375],
              [-97.00839843749999, 68.538671875],
              [-97.263671875, 68.527734375],
              [-97.47202148437499, 68.543701171875],
              [-97.70478515625, 68.625927734375],
              [-97.88535156249999, 68.6724609375],
              [-98.23505859375, 68.73935546875],
              [-98.257958984375, 68.749267578125],
              [-98.273046875, 68.771875],
              [-98.28017578125, 68.807177734375],
              [-98.296044921875, 68.83076171875],
              [-98.320556640625, 68.842724609375],
              [-98.37558593749999, 68.84169921875],
              [-98.4318359375, 68.818359375],
              [-98.53964843749999, 68.7982421875],
              [-98.70380859375, 68.802783203125],
              [-98.77524414062499, 68.816748046875],
              [-98.82963867187499, 68.838623046875],
              [-98.859130859375, 68.86435546875],
              [-98.863720703125, 68.893798828125],
              [-98.878857421875, 68.916455078125],
              [-98.9044921875, 68.932421875],
              [-98.964013671875, 68.932861328125],
              [-99.057373046875, 68.91767578125],
              [-99.09384765624999, 68.898876953125],
              [-99.073388671875, 68.8765625],
              [-99.090625, 68.863330078125],
              [-99.25400390624999, 68.86318359375],
              [-99.31796875, 68.87626953125],
              [-99.440869140625, 68.91767578125],
              [-99.49467773437499, 68.9595703125],
              [-99.5640625, 69.034130859375],
              [-99.557373046875, 69.054296875],
              [-99.51328125, 69.099609375],
              [-99.455712890625, 69.131201171875],
              [-99.08544921875, 69.149755859375],
              [-98.91220703124999, 69.167578125],
              [-98.7236328125, 69.219140625],
              [-98.503515625, 69.30830078125],
              [-98.45595703125, 69.33466796875],
              [-98.450390625, 69.354052734375],
              [-98.46660156249999, 69.375],
              [-98.5353515625, 69.426318359375],
              [-98.558544921875, 69.46142578125],
              [-98.53671875, 69.47802734375],
              [-98.448388671875, 69.479541015625],
              [-98.494873046875, 69.499365234375],
              [-98.534375, 69.52744140625],
              [-98.5482421875, 69.544970703125],
              [-98.54599609374999, 69.572900390625],
              [-98.475830078125, 69.579052734375],
              [-98.38935546875, 69.5650390625],
              [-98.222314453125, 69.484521484375],
              [-98.15576171875, 69.46884765625],
              [-98.04135742187499, 69.456640625],
              [-98.16298828125, 69.51220703125],
              [-98.288818359375, 69.62900390625],
              [-98.30449218749999, 69.669287109375],
              [-98.301220703125, 69.69169921875],
              [-98.268212890625, 69.754443359375],
              [-98.238671875, 69.780029296875],
              [-98.20048828124999, 69.79697265625],
              [-98.08076171875, 69.833056640625],
              [-97.88896484374999, 69.858251953125],
              [-97.79072265625, 69.86162109375],
              [-97.69121093749999, 69.841259765625],
              [-97.60434570312499, 69.802197265625],
              [-97.411376953125, 69.7384765625],
              [-97.382568359375, 69.71240234375],
              [-97.385693359375, 69.700244140625],
              [-97.46015625, 69.68271484375],
              [-97.46943359375, 69.666796875],
              [-97.439453125, 69.64267578125],
            ],
          ],
          [
            [
              [-61.10517578125, 45.944726562499994],
              [-61.07133789062499, 45.937109375],
              [-60.93657226562499, 45.985546875],
              [-60.865234375, 45.98349609375],
              [-60.868408203125, 45.948632812499994],
              [-60.984277343749994, 45.910693359374996],
              [-61.037548828125, 45.882226562499994],
              [-60.970605468749994, 45.855810546875],
              [-60.97153320312499, 45.83798828125],
              [-61.051953125, 45.79501953125],
              [-61.09208984374999, 45.748388671875],
              [-61.059033203125, 45.703369140625],
              [-60.93037109375, 45.747705078124994],
              [-60.877587890624994, 45.748095703124996],
              [-60.806103515625, 45.738085937499996],
              [-60.737890625, 45.751416015625],
              [-60.69907226562499, 45.773339843749994],
              [-60.47236328125, 45.946533203125],
              [-60.460595703124994, 45.968701171875],
              [-60.70488281249999, 45.932910156249996],
              [-60.733300781249994, 45.956591796874996],
              [-60.573193359375, 46.061425781249994],
              [-60.58574218749999, 46.116650390625],
              [-60.50493164062499, 46.203857421875],
              [-60.430859375, 46.255615234375],
              [-60.37651367187499, 46.284570312499994],
              [-60.29794921874999, 46.31123046875],
              [-60.24384765625, 46.2701171875],
              [-60.226464843749994, 46.195556640625],
              [-60.09248046875, 46.206005859375],
              [-59.96142578125, 46.190966796874996],
              [-59.86503906249999, 46.159521484375],
              [-59.85, 46.14140625],
              [-59.848779296874994, 46.112939453124994],
              [-59.88090820312499, 46.06162109375],
              [-59.934033203125, 46.019433593749994],
              [-59.828027343749994, 45.965136718749996],
              [-59.8421875, 45.941552734374994],
              [-60.0158203125, 45.88046875],
              [-60.114453125, 45.818896484374996],
              [-60.205078125, 45.743017578125],
              [-60.38608398437499, 45.654638671875],
              [-60.67294921874999, 45.5908203125],
              [-60.76372070312499, 45.5908203125],
              [-60.87158203125, 45.610693359375],
              [-60.97861328124999, 45.60615234375],
              [-61.08369140625, 45.582373046875],
              [-61.186425781249994, 45.585009765624996],
              [-61.236328125, 45.572509765625],
              [-61.28369140625, 45.573876953124994],
              [-61.3234375, 45.598486328125],
              [-61.408349609374994, 45.669091796874994],
              [-61.44980468749999, 45.716210937499994],
              [-61.4953125, 45.941455078124996],
              [-61.480615234374994, 46.059765625],
              [-61.408642578125, 46.170361328125],
              [-61.302197265625, 46.24384765625],
              [-61.24052734374999, 46.302539062499996],
              [-60.98251953124999, 46.65048828125],
              [-60.93198242187499, 46.729443359375],
              [-60.87016601562499, 46.796777343749994],
              [-60.759667968749994, 46.86337890625],
              [-60.616650390625, 46.97578125],
              [-60.571044921875, 46.998828125],
              [-60.48906249999999, 47.009716796875],
              [-60.408203125, 47.003515625],
              [-60.43134765625, 46.962939453124996],
              [-60.425439453124994, 46.923193359375],
              [-60.33173828125, 46.767822265625],
              [-60.332910156249994, 46.73701171875],
              [-60.38408203124999, 46.613330078124996],
              [-60.482421875, 46.413525390625],
              [-60.507714843749994, 46.303369140624994],
              [-60.49453125, 46.270263671875],
              [-60.534423828125, 46.21455078125],
              [-60.57685546875, 46.172167968749996],
              [-60.74482421875, 46.092675781249994],
              [-60.83056640625, 46.074121093749994],
              [-60.91220703124999, 46.044580078124994],
              [-61.10517578125, 45.944726562499994],
            ],
          ],
          [
            [
              [-67.12485351562499, 45.16943359375],
              [-67.17099609374999, 45.181982421875],
              [-67.21323242187499, 45.192529296874994],
              [-67.249609375, 45.20078125],
              [-67.270703125, 45.18671875],
              [-67.290673828125, 45.167919921875],
              [-67.31528320312499, 45.15380859375],
              [-67.366943359375, 45.173779296875],
              [-67.3998046875, 45.21015625],
              [-67.452587890625, 45.24765625],
              [-67.47255859375, 45.27587890625],
              [-67.46196289062499, 45.30869140625],
              [-67.43852539062499, 45.340380859374996],
              [-67.4279296875, 45.3779296875],
              [-67.453759765625, 45.421240234375],
              [-67.47724609375, 45.4458984375],
              [-67.49365234375, 45.474072265625],
              [-67.48779296875, 45.501025390624996],
              [-67.45493164062499, 45.513964843749996],
              [-67.42441406249999, 45.530419921874994],
              [-67.41386718749999, 45.565576171874994],
              [-67.43266601562499, 45.603125],
              [-67.48662109374999, 45.618408203125],
              [-67.531201171875, 45.612548828125],
              [-67.59575195312499, 45.620751953124994],
              [-67.65791015625, 45.644189453124994],
              [-67.698974609375, 45.671191406249996],
              [-67.7306640625, 45.686474609375],
              [-67.755322265625, 45.686474609375],
              [-67.78466796875, 45.701708984374996],
              [-67.80224609375, 45.7275390625],
              [-67.79990234374999, 45.769775390625],
              [-67.79169921875, 45.795556640624994],
              [-67.77529296875, 45.81787109375],
              [-67.77412109375, 45.842529296875],
              [-67.78115234375, 45.86015625],
              [-67.78227539062499, 45.874169921874994],
              [-67.77763671874999, 45.891796875],
              [-67.767041015625, 45.927001953125],
              [-67.78466796875, 45.952783203124994],
              [-67.78647460937499, 46.042138671874994],
              [-67.78994140625, 46.209326171875],
              [-67.79252929687499, 46.33740234375],
              [-67.79580078125, 46.498388671875],
              [-67.79770507812499, 46.615625],
              [-67.80034179687499, 46.7798828125],
              [-67.80283203124999, 46.9357421875],
              [-67.806787109375, 47.0828125],
              [-67.93486328124999, 47.167626953124994],
              [-68.09677734374999, 47.274853515625],
              [-68.235498046875, 47.345947265625],
              [-68.31088867187499, 47.3544921875],
              [-68.35800781249999, 47.34453125],
              [-68.376904296875, 47.316162109375],
              [-68.48037109375, 47.285791015624994],
              [-68.66855468749999, 47.253466796874996],
              [-68.82871093749999, 47.2033203125],
              [-68.88740234375, 47.20283203125],
              [-68.93720703125, 47.211230468749996],
              [-69.003125, 47.23642578125],
              [-69.048583984375, 47.2736328125],
              [-69.06425781249999, 47.338134765625],
              [-69.0501953125, 47.426611328125],
              [-69.14628906249999, 47.444775390625],
              [-69.24287109375, 47.46298828125],
              [-69.3021484375, 47.402001953124994],
              [-69.35888671875, 47.350634765624996],
              [-69.47148437499999, 47.238671875],
              [-69.62978515625, 47.081347656249996],
              [-69.717529296875, 46.994873046875],
              [-69.871728515625, 46.842919921874994],
              [-70.00771484375, 46.708935546875],
              [-70.038232421875, 46.571435546874994],
              [-70.06718749999999, 46.441064453124994],
              [-70.1796875, 46.341845703124996],
              [-70.248291015625, 46.250878906249994],
              [-70.27890625, 46.15],
              [-70.30449218749999, 46.057373046875],
              [-70.30644531249999, 45.979833984375],
              [-70.28715820312499, 45.93916015625],
              [-70.29624023437499, 45.906103515625],
              [-70.333447265625, 45.868066406249994],
              [-70.407861328125, 45.801904296874994],
              [-70.42109375, 45.738232421875],
              [-70.46660156249999, 45.706835937499996],
              [-70.59638671875, 45.643994140625],
              [-70.70224609374999, 45.5513671875],
              [-70.707421875, 45.498925781249994],
              [-70.692138671875, 45.455371093749996],
              [-70.68979492187499, 45.4283203125],
              [-70.7109375, 45.409472656249996],
              [-70.7533203125, 45.410693359374996],
              [-70.79916992187499, 45.40478515625],
              [-70.83779296875, 45.366162109375],
              [-70.83681640625, 45.310693359374994],
              [-70.86503906249999, 45.270703125],
              [-70.89799804687499, 45.262451171875],
              [-70.926220703125, 45.29072265625],
              [-70.96015625, 45.33310546875],
              [-70.99990234375, 45.337255859375],
              [-71.06025390625, 45.309130859374996],
              [-71.13466796875, 45.262841796874994],
              [-71.201611328125, 45.2603515625],
              [-71.327294921875, 45.290087890624996],
              [-71.41904296874999, 45.200341796874994],
              [-71.517529296875, 45.007568359375],
              [-71.93364257812499, 45.007080078125],
              [-72.349755859375, 45.006591796875],
              [-72.76591796874999, 45.006103515625],
              [-73.18203125, 45.005615234375],
              [-73.59814453125, 45.005175781249996],
              [-74.01425781249999, 45.0046875],
              [-74.43037109375, 45.004199218749996],
              [-74.663232421875, 45.00390625],
              [-74.70888671875002, 45.003857421875125],
              [-74.56630859375, 45.0416015625],
              [-74.26904296875, 45.18828125],
              [-74.0498046875, 45.24140625],
              [-73.7646484375, 45.395458984375],
              [-73.55810546875, 45.425097656249996],
              [-73.518798828125, 45.458984375],
              [-73.48417968749999, 45.586767578125],
              [-73.465283203125, 45.63232421875],
              [-73.36884765625, 45.7578125],
              [-73.25302734374999, 45.863671875],
              [-73.1595703125, 46.010058593749996],
              [-72.98994140625, 46.10361328125],
              [-72.73344726562499, 46.1818359375],
              [-72.49619140624999, 46.352685546874994],
              [-72.366162109375, 46.40478515625],
              [-72.24013671875, 46.44208984375],
              [-72.18720703125, 46.511523437499996],
              [-72.10927734375, 46.551220703125],
              [-71.90092773437499, 46.63193359375],
              [-71.6712890625, 46.653759765625],
              [-71.439208984375, 46.720751953124996],
              [-71.261181640625, 46.75625],
              [-71.152001953125, 46.819091796875],
              [-70.99326171874999, 46.852197265624994],
              [-70.519482421875, 47.032519531249996],
              [-70.3880859375, 47.116943359375],
              [-70.2177734375, 47.28984375],
              [-70.069580078125, 47.377783203125],
              [-70.01713867187499, 47.471435546875],
              [-69.80224609375, 47.6234375],
              [-69.5810546875, 47.823681640625],
              [-69.47104492187499, 47.96728515625],
              [-69.30634765625, 48.047021484374994],
              [-68.987060546875, 48.275],
              [-68.815673828125, 48.366015625],
              [-68.746044921875, 48.376416015625],
              [-68.552001953125, 48.457324218749996],
              [-68.43149414062499, 48.54169921875],
              [-68.23818359375, 48.626416015625],
              [-67.88901367187499, 48.730908203125],
              [-67.56088867187499, 48.85595703125],
              [-67.11748046874999, 48.964160156249996],
              [-66.598095703125, 49.126367187499994],
              [-66.178173828125, 49.213134765625],
              [-65.8828125, 49.22568359375],
              [-65.523388671875, 49.266162109374996],
              [-65.396142578125, 49.262060546875],
              [-64.836328125, 49.191748046875],
              [-64.56772460937499, 49.104785156249996],
              [-64.26181640624999, 48.921875],
              [-64.2162109375, 48.8736328125],
              [-64.20878906249999, 48.806201171874996],
              [-64.370751953125, 48.83896484375],
              [-64.51372070312499, 48.841113281249996],
              [-64.41455078125, 48.803613281249994],
              [-64.24609375, 48.69111328125],
              [-64.25375976562499, 48.550390625],
              [-64.348828125, 48.423193359375],
              [-64.63315429687499, 48.360498046874994],
              [-64.70576171875, 48.310595703124996],
              [-64.76450195312499, 48.228076171874996],
              [-64.82207031249999, 48.196484375],
              [-64.959912109375, 48.15986328125],
              [-65.036083984375, 48.10625],
              [-65.259423828125, 48.021240234375],
              [-65.360009765625, 48.011132812499994],
              [-65.47587890624999, 48.031494140625],
              [-65.75468749999999, 48.111669921875],
              [-65.926708984375, 48.188867187499994],
              [-66.01254882812499, 48.1466796875],
              [-66.08310546874999, 48.102685546874994],
              [-66.24863281249999, 48.117333984374994],
              [-66.32426757812499, 48.097900390625],
              [-66.448974609375, 48.11962890625],
              [-66.70439453124999, 48.0224609375],
              [-66.63154296875, 48.011083984375],
              [-66.42880859374999, 48.066943359374996],
              [-66.359619140625, 48.06064453125],
              [-66.210205078125, 47.988574218749996],
              [-65.84941406249999, 47.911035156249994],
              [-65.75571289062499, 47.859765625],
              [-65.666455078125, 47.696142578125],
              [-65.60722656249999, 47.67001953125],
              [-65.48349609374999, 47.68701171875],
              [-65.3439453125, 47.767919921875],
              [-65.228173828125, 47.811279296875],
              [-65.00166015625, 47.846826171874994],
              [-65.04638671875, 47.793017578124996],
              [-64.873974609375, 47.797216796875],
              [-64.70322265624999, 47.724853515625],
              [-64.76630859375, 47.673486328124994],
              [-64.85214843749999, 47.569873046874996],
              [-64.91220703124999, 47.36865234375],
              [-65.0861328125, 47.233789062499994],
              [-65.31889648437499, 47.101220703124994],
              [-65.260205078125, 47.069238281249994],
              [-65.19208984375, 47.049560546875],
              [-65.0423828125, 47.088818359375],
              [-64.94243164062499, 47.086181640625],
              [-64.83139648437499, 47.060791015625],
              [-64.865869140625, 46.9578125],
              [-64.90576171875, 46.887939453125],
              [-64.88251953125, 46.8228515625],
              [-64.81669921874999, 46.698681640625],
              [-64.72587890624999, 46.671435546874996],
              [-64.68950195312499, 46.5123046875],
              [-64.641357421875, 46.425585937499996],
              [-64.6478515625, 46.35595703125],
              [-64.5568359375, 46.311425781249994],
              [-64.54150390625, 46.24033203125],
              [-64.21181640625, 46.22021484375],
              [-64.14501953125, 46.19287109375],
              [-63.915917968749994, 46.165820312499996],
              [-63.87265625, 46.14619140625],
              [-63.831933593749994, 46.107177734375],
              [-64.056396484375, 46.021337890625],
              [-63.87470703125, 45.959228515625],
              [-63.702880859375, 45.8580078125],
              [-63.56767578124999, 45.8779296875],
              [-63.509228515625, 45.87470703125],
              [-63.35800781249999, 45.811279296875],
              [-63.31591796875, 45.7798828125],
              [-63.29277343749999, 45.751953125],
              [-63.21689453124999, 45.757958984374994],
              [-63.10791015625, 45.782421875],
              [-62.910791015624994, 45.7763671875],
              [-62.70068359375, 45.740576171875],
              [-62.718359375, 45.685986328125],
              [-62.75009765624999, 45.648242187499996],
              [-62.58564453125, 45.660693359374996],
              [-62.483056640624994, 45.621826171875],
              [-62.447265625, 45.640527343749994],
              [-62.421875, 45.6646484375],
              [-62.217724609375, 45.730859375],
              [-61.955517578125, 45.8681640625],
              [-61.923583984375, 45.851171875],
              [-61.91162109375, 45.799121093749996],
              [-61.87724609374999, 45.714208984375],
              [-61.776513671874994, 45.655615234375],
              [-61.656884765624994, 45.6421875],
              [-61.49228515624999, 45.68701171875],
              [-61.427636718749994, 45.648291015625],
              [-61.350488281249994, 45.573681640625],
              [-61.27705078125, 45.476025390625],
              [-61.281982421875, 45.441064453124994],
              [-61.376123046874994, 45.410595703125],
              [-61.46098632812499, 45.36669921875],
              [-61.10673828124999, 45.3486328125],
              [-61.07080078125, 45.33017578125],
              [-61.03154296874999, 45.291748046875],
              [-61.06767578124999, 45.252832031249994],
              [-61.10107421875, 45.233447265624996],
              [-61.16533203124999, 45.256103515625],
              [-61.28378906249999, 45.235498046874994],
              [-61.38725585937499, 45.18505859375],
              [-61.49790039062499, 45.15703125],
              [-61.56875, 45.15380859375],
              [-61.647412109375, 45.130517578124994],
              [-61.71923828125, 45.094482421875],
              [-61.793896484375, 45.084423828125],
              [-62.026806640625, 44.994482421875],
              [-62.26499023437499, 44.936474609375],
              [-62.51401367187499, 44.843652343749994],
              [-62.76806640625, 44.785107421875],
              [-63.03183593749999, 44.714794921875],
              [-63.08920898437499, 44.708544921874996],
              [-63.155712890625, 44.711328125],
              [-63.306298828124994, 44.642578125],
              [-63.380810546875, 44.651904296874996],
              [-63.45683593749999, 44.639941406249996],
              [-63.544335937499994, 44.655078125],
              [-63.60400390625, 44.683203125],
              [-63.558251953124994, 44.610595703125],
              [-63.544824218749994, 44.54375],
              [-63.56767578124999, 44.514453125],
              [-63.609765625, 44.47998046875],
              [-63.761132812499994, 44.48642578125],
              [-63.82065429687499, 44.510644531249994],
              [-63.89130859375, 44.546337890625],
              [-63.92368164062499, 44.603857421875],
              [-63.99970703125, 44.644921875],
              [-64.044921875, 44.587890625],
              [-64.04462890625, 44.54541015625],
              [-64.10087890624999, 44.487451171874994],
              [-64.1669921875, 44.586669921875],
              [-64.286083984375, 44.550341796874996],
              [-64.338525390625, 44.444873046874996],
              [-64.312255859375, 44.41474609375],
              [-64.27568359374999, 44.33408203125],
              [-64.33457031249999, 44.2919921875],
              [-64.37822265624999, 44.303564453125],
              [-64.46879882812499, 44.18515625],
              [-64.57846679687499, 44.142041015625],
              [-64.6916015625, 44.021337890625],
              [-64.825634765625, 43.929345703124994],
              [-64.86235351562499, 43.86787109375],
              [-65.08681640625, 43.727197265624994],
              [-65.1720703125, 43.731396484375],
              [-65.23491210937499, 43.7267578125],
              [-65.32958984375, 43.668115234374994],
              [-65.34428710937499, 43.549609375],
              [-65.38608398437499, 43.565283203125],
              [-65.42851562499999, 43.561425781249994],
              [-65.450439453125, 43.52421875],
              [-65.481689453125, 43.51806640625],
              [-65.564453125, 43.553271484374996],
              [-65.66191406249999, 43.534033203125],
              [-65.73813476562499, 43.5607421875],
              [-65.83530273437499, 43.734375],
              [-65.8869140625, 43.795214843749996],
              [-65.97841796875, 43.81484375],
              [-66.0021484375, 43.778125],
              [-66.03764648437499, 43.7421875],
              [-66.125732421875, 43.813818359375],
              [-66.192529296875, 44.0796875],
              [-66.19306640625, 44.143847656249996],
              [-66.099560546875, 44.367480468749996],
              [-65.86801757812499, 44.568798828125],
              [-65.94194335937499, 44.575537109375],
              [-66.14638671875, 44.4359375],
              [-66.12529296874999, 44.4697265625],
              [-66.090625, 44.504931640624996],
              [-66.0216796875, 44.56171875],
              [-65.91704101562499, 44.615087890625],
              [-65.77768554687499, 44.64619140625],
              [-65.6818359375, 44.650927734374996],
              [-65.61577148437499, 44.680419921875],
              [-65.52001953125, 44.732666015625],
              [-65.50224609374999, 44.760400390624994],
              [-65.587158203125, 44.728515625],
              [-65.72822265625, 44.697119140625],
              [-65.692041015625, 44.73828125],
              [-65.65673828125, 44.760302734374996],
              [-64.90292968749999, 45.12080078125],
              [-64.75126953124999, 45.180224609374996],
              [-64.448828125, 45.2560546875],
              [-64.406884765625, 45.305712890624996],
              [-64.44814453125, 45.337451171874996],
              [-64.33076171875, 45.309326171875],
              [-64.34042968749999, 45.268212890624994],
              [-64.358837890625, 45.238232421875],
              [-64.36572265625, 45.187255859375],
              [-64.354248046875, 45.138232421874996],
              [-64.235009765625, 45.114306640624996],
              [-64.135498046875, 45.023046875],
              [-64.18271484374999, 45.147021484374996],
              [-64.0931640625, 45.217089843749996],
              [-63.74833984374999, 45.310888671875],
              [-63.46025390624999, 45.32109375],
              [-63.36801757812499, 45.364794921874996],
              [-63.614453125, 45.394140625],
              [-63.9064453125, 45.378173828125],
              [-64.087158203125, 45.410888671875],
              [-64.33642578125, 45.389550781249994],
              [-64.60019531249999, 45.410058593749994],
              [-64.681103515625, 45.382958984374994],
              [-64.74667968749999, 45.324365234374994],
              [-64.83193359375, 45.350244140624994],
              [-64.87314453124999, 45.35458984375],
              [-64.91289062499999, 45.374804687499996],
              [-64.827392578125, 45.475537109375],
              [-64.56005859375, 45.62548828125],
              [-64.39707031249999, 45.755859375],
              [-64.35112304687499, 45.783203125],
              [-64.3146484375, 45.835693359375],
              [-64.404052734375, 45.826904296875],
              [-64.48222656249999, 45.80634765625],
              [-64.536328125, 45.866601562499994],
              [-64.63271484375, 45.946630859375],
              [-64.642041015625, 45.913330078125],
              [-64.59365234375, 45.813671875],
              [-64.778515625, 45.638427734375],
              [-64.897900390625, 45.6259765625],
              [-65.057275390625, 45.544238281249996],
              [-65.28232421874999, 45.473095703125],
              [-65.54501953124999, 45.337304687499994],
              [-65.88447265625, 45.222900390625],
              [-65.95561523437499, 45.222460937499996],
              [-66.109765625, 45.3166015625],
              [-66.066650390625, 45.35947265625],
              [-66.0265625, 45.417578125],
              [-66.064892578125, 45.400830078125],
              [-66.08974609375, 45.375634765624994],
              [-66.18271484374999, 45.335205078125],
              [-66.10732421875, 45.25693359375],
              [-66.14375, 45.227587890624996],
              [-66.2515625, 45.189013671874996],
              [-66.351953125, 45.133203125],
              [-66.43984375, 45.0958984375],
              [-66.5109375, 45.143359375],
              [-66.707177734375, 45.083398437499994],
              [-66.8724609375, 45.067285156249994],
              [-66.908203125, 45.09765625],
              [-66.918701171875, 45.14560546875],
              [-66.9765625, 45.157177734375],
              [-67.08408203124999, 45.143945312499994],
              [-67.12485351562499, 45.16943359375],
            ],
          ],
          [
            [
              [-55.458740234375, 51.536523437499994],
              [-55.532421875, 51.436962890625],
              [-55.583398437499994, 51.388574218749994],
              [-55.630761718749994, 51.372900390625],
              [-55.730712890625, 51.358691406249996],
              [-55.941162109375, 51.343017578125],
              [-56.03110351562499, 51.328369140625],
              [-56.0439453125, 51.261865234374994],
              [-56.030664062499994, 51.226904296875],
              [-55.999902343749994, 51.199267578124996],
              [-55.960839843749994, 51.19140625],
              [-55.87353515625, 51.207910156249994],
              [-55.84111328124999, 51.205078125],
              [-55.815087890624994, 51.191162109375],
              [-55.79550781249999, 51.166162109374994],
              [-55.7853515625, 51.1314453125],
              [-55.78471679687499, 51.087060546874994],
              [-55.8, 51.03330078125],
              [-55.87138671874999, 50.907373046874994],
              [-55.96201171874999, 50.837695312499996],
              [-56.078125, 50.78095703125],
              [-56.10654296874999, 50.75927734375],
              [-56.12119140624999, 50.733789062499994],
              [-56.135644531249994, 50.6509765625],
              [-56.195751953125, 50.584765625],
              [-56.382421875, 50.4169921875],
              [-56.454345703125, 50.380029296874994],
              [-56.45478515625, 50.350488281249994],
              [-56.48393554687499, 50.270849609375],
              [-56.53935546874999, 50.20673828125],
              [-56.693994140624994, 50.05966796875],
              [-56.732324218749994, 50.007714843749994],
              [-56.74956054687499, 49.966552734375],
              [-56.74716796874999, 49.908496093749996],
              [-56.7541015625, 49.88291015625],
              [-56.789501953125, 49.833740234375],
              [-56.8388671875, 49.787744140624994],
              [-56.8486328125, 49.76533203125],
              [-56.82919921874999, 49.724609375],
              [-56.809228515624994, 49.710400390625],
              [-56.806884765625, 49.67333984375],
              [-56.822167968749994, 49.6134765625],
              [-56.756787109375, 49.651611328125],
              [-56.61064453124999, 49.7876953125],
              [-56.500927734375, 49.86962890625],
              [-56.42758789062499, 49.897412109375],
              [-56.376416015625, 49.93369140625],
              [-56.32182617187499, 50.01376953125],
              [-56.2470703125, 50.090087890625],
              [-56.17939453125, 50.114990234375],
              [-56.148388671875, 50.100341796875],
              [-56.12216796874999, 50.062841796875],
              [-56.12744140625, 50.01513671875],
              [-56.16416015624999, 49.957275390625],
              [-56.161279296874994, 49.94013671875],
              [-56.075, 49.9826171875],
              [-55.927001953125, 50.0177734375],
              [-55.87333984374999, 50.013134765625],
              [-55.76474609374999, 49.96044921875],
              [-55.674462890624994, 49.966552734375],
              [-55.530029296875, 49.99716796875],
              [-55.5029296875, 49.983154296875],
              [-55.527001953124994, 49.936767578125],
              [-55.58369140625, 49.892382812499996],
              [-55.71762695312499, 49.829003906249994],
              [-56.039990234375, 49.7046875],
              [-56.14018554687499, 49.619140625],
              [-56.12119140624999, 49.621728515624994],
              [-56.05161132812499, 49.6583984375],
              [-55.978515625, 49.678125],
              [-55.90185546875, 49.680859375],
              [-55.86982421875, 49.670166015625],
              [-55.88232421875, 49.645947265625],
              [-55.892041015625, 49.5802734375],
              [-56.087304687499994, 49.451953125],
              [-56.0412109375, 49.456835937499996],
              [-55.81523437499999, 49.515283203124994],
              [-55.678125, 49.434619140624996],
              [-55.48974609375, 49.4625],
              [-55.375927734375, 49.48974609375],
              [-55.379150390625, 49.472900390625],
              [-55.3544921875, 49.4376953125],
              [-55.355371093749994, 49.380859375],
              [-55.34384765624999, 49.372900390625],
              [-55.289941406249994, 49.391943359375],
              [-55.280175781249994, 49.412744140624994],
              [-55.28300781249999, 49.513818359374994],
              [-55.266357421875, 49.52392578125],
              [-55.22954101562499, 49.508154296875],
              [-55.20703125, 49.48203125],
              [-55.20029296874999, 49.408496093749996],
              [-55.225, 49.33466796875],
              [-55.25932617187499, 49.266992187499994],
              [-55.34248046875, 49.168115234374994],
              [-55.331933593749994, 49.1255859375],
              [-55.353173828124994, 49.079443359375],
              [-55.33476562499999, 49.077880859375],
              [-55.25234375, 49.120898437499996],
              [-55.24736328124999, 49.138574218749994],
              [-55.253808593749994, 49.179638671875],
              [-55.24453125, 49.1998046875],
              [-55.17612304687499, 49.244433593749996],
              [-55.06318359375, 49.29736328125],
              [-55.02617187499999, 49.30537109375],
              [-55.010400390624994, 49.293017578124996],
              [-55.01591796874999, 49.2603515625],
              [-54.9826171875, 49.268115234374996],
              [-54.910546875, 49.316259765625],
              [-54.843652343749994, 49.34541015625],
              [-54.781884765624994, 49.35546875],
              [-54.71762695312499, 49.388574218749994],
              [-54.65087890625, 49.44453125],
              [-54.579052734375, 49.4908203125],
              [-54.502197265625, 49.52734375],
              [-54.469189453125, 49.52978515625],
              [-54.480615234374994, 49.4693359375],
              [-54.46542968749999, 49.400537109374994],
              [-54.46347656249999, 49.341748046875],
              [-54.4482421875, 49.329443359375],
              [-54.3890625, 49.392138671874996],
              [-54.35615234375, 49.4150390625],
              [-54.31674804687499, 49.424121093749996],
              [-54.27080078124999, 49.419287109375],
              [-53.95771484375, 49.441845703125],
              [-53.862451171874994, 49.426318359374996],
              [-53.75498046874999, 49.385302734374996],
              [-53.61943359374999, 49.321630859375],
              [-53.569580078125, 49.26416015625],
              [-53.56005859375, 49.191699218749996],
              [-53.5734375, 49.1412109375],
              [-53.671142578125, 49.077539062499994],
              [-53.758056640625, 49.035400390625],
              [-53.809326171875, 48.993408203125],
              [-53.82490234375, 48.9513671875],
              [-53.84521484375, 48.925439453124994],
              [-53.903222656249994, 48.88916015625],
              [-54.161279296874994, 48.7876953125],
              [-54.099511718749994, 48.784765625],
              [-53.95068359375, 48.806787109374994],
              [-53.85288085937499, 48.811328125],
              [-53.84775390624999, 48.796679687499996],
              [-53.88681640624999, 48.767822265625],
              [-53.96152343749999, 48.7388671875],
              [-53.96958007812499, 48.724902343749996],
              [-53.966015625, 48.706689453124994],
              [-53.886132812499994, 48.68466796875],
              [-53.784082031249994, 48.69541015625],
              [-53.69804687499999, 48.679833984374994],
              [-53.70634765624999, 48.655517578125],
              [-53.774609375, 48.576318359374994],
              [-53.79462890625, 48.5263671875],
              [-53.88554687499999, 48.4845703125],
              [-54.067773437499994, 48.418847656249994],
              [-54.114453125, 48.393603515624996],
              [-54.104248046875, 48.38837890625],
              [-53.93701171875, 48.43662109375],
              [-53.852734375, 48.448828125],
              [-53.79931640625, 48.44921875],
              [-53.73886718749999, 48.49580078125],
              [-53.644433593749994, 48.51123046875],
              [-53.55205078124999, 48.481787109375],
              [-53.411328125, 48.562158203124994],
              [-53.361083984375, 48.572607421875],
              [-53.27543945312499, 48.563330078125],
              [-53.22026367187499, 48.577880859375],
              [-53.12734375, 48.632568359375],
              [-53.057275390624994, 48.659033203125],
              [-53.04267578125, 48.656640625],
              [-53.027587890625, 48.634716796875],
              [-53.020751953125, 48.571630859375],
              [-53.0373046875, 48.515869140625],
              [-53.060205078124994, 48.480322265625],
              [-53.1357421875, 48.40185546875],
              [-53.18212890625, 48.374365234375],
              [-53.22509765625, 48.364013671875],
              [-53.301171875, 48.3681640625],
              [-53.33432617187499, 48.35595703125],
              [-53.405517578125, 48.294335937499994],
              [-53.531201171875, 48.231884765625],
              [-53.609765625, 48.20771484375],
              [-53.560205078124994, 48.173828125],
              [-53.54184570312499, 48.108447265624996],
              [-53.56943359374999, 48.0880859375],
              [-53.704296875, 48.067919921874996],
              [-53.71015625, 48.0568359375],
              [-53.758203125, 48.042382812499994],
              [-53.869580078125, 48.019677734374994],
              [-53.79355468749999, 48.009716796875],
              [-53.65302734375, 48.025732421875],
              [-53.63823242187499, 48.0146484375],
              [-53.657617187499994, 47.968652343749994],
              [-53.69501953125, 47.921191406249996],
              [-53.86166992187499, 47.799267578125],
              [-53.863671875, 47.787011718749994],
              [-53.83774414062499, 47.72724609375],
              [-53.80537109375, 47.68203125],
              [-53.76513671875, 47.65009765625],
              [-53.67236328125, 47.648242187499996],
              [-53.603759765625, 47.6623046875],
              [-53.50375976562499, 47.74384765625],
              [-53.28271484375, 47.997851562499996],
              [-53.08544921875, 48.068505859374994],
              [-52.92099609374999, 48.1470703125],
              [-52.88330078125, 48.131152343749996],
              [-52.86601562499999, 48.11298828125],
              [-52.872021484375, 48.0939453125],
              [-52.954980468749994, 48.029296875],
              [-52.9982421875, 47.975927734375],
              [-53.11083984375, 47.811914062499994],
              [-53.15385742187499, 47.7345703125],
              [-53.175537109375, 47.652978515624994],
              [-53.169824218749994, 47.512109375],
              [-53.157666015625, 47.48779296875],
              [-53.122460937499994, 47.455126953124996],
              [-53.0568359375, 47.48310546875],
              [-52.94501953125, 47.55283203125],
              [-52.873193359374994, 47.619433593749996],
              [-52.81694335937499, 47.727880859375],
              [-52.782421875, 47.769433593749994],
              [-52.74492187499999, 47.768945312499994],
              [-52.71142578125, 47.7453125],
              [-52.703271484374994, 47.693017578124994],
              [-52.67216796874999, 47.62177734375],
              [-52.65366210937499, 47.5494140625],
              [-52.66850585937499, 47.46982421875],
              [-52.68364257812499, 47.426318359374996],
              [-52.91240234374999, 47.10322265625],
              [-52.888134765625, 47.045849609375],
              [-52.882080078125, 47.011083984375],
              [-52.88920898437499, 46.97412109375],
              [-52.96171874999999, 46.81943359375],
              [-53.03193359375, 46.72275390625],
              [-53.069775390625, 46.68125],
              [-53.11484375, 46.655810546874996],
              [-53.1669921875, 46.646484375],
              [-53.21367187499999, 46.660498046875],
              [-53.2548828125, 46.697705078125],
              [-53.29130859374999, 46.717041015625],
              [-53.32304687499999, 46.718359375],
              [-53.381738281249994, 46.71142578125],
              [-53.5361328125, 46.63251953125],
              [-53.567773437499994, 46.628271484375],
              [-53.589794921875, 46.6388671875],
              [-53.616357421874994, 46.6802734375],
              [-53.595166015625, 46.888476562499996],
              [-53.58134765624999, 46.957275390625],
              [-53.61215820312499, 47.0103515625],
              [-53.57963867187499, 47.099414062499996],
              [-53.57846679687499, 47.133251953125],
              [-53.59736328125, 47.14599609375],
              [-53.636376953124994, 47.1376953125],
              [-53.69536132812499, 47.092919921874994],
              [-53.774316406249994, 47.01181640625],
              [-53.860009765624994, 46.939453125],
              [-54.00957031249999, 46.839599609375],
              [-54.07602539062499, 46.819970703124994],
              [-54.10239257812499, 46.82490234375],
              [-54.1328125, 46.83857421875],
              [-54.173730468749994, 46.88037109375],
              [-54.173291015625, 46.9171875],
              [-54.155224609375, 46.96748046875],
              [-54.092675781249994, 47.086230468749996],
              [-53.97050781249999, 47.261962890625],
              [-53.869091796875, 47.387011718749996],
              [-53.849511718749994, 47.440332031249994],
              [-53.877880859375, 47.46357421875],
              [-53.900830078125, 47.509326171874996],
              [-53.93974609374999, 47.644677734374994],
              [-53.989013671875, 47.756201171875],
              [-54.047265625, 47.805615234375],
              [-54.191845703125, 47.859814453125],
              [-54.218408203124994, 47.846728515624996],
              [-54.23388671875, 47.7716796875],
              [-54.4046875, 47.555908203125],
              [-54.434472656249994, 47.462304687499994],
              [-54.45590820312499, 47.427587890625],
              [-54.48813476562499, 47.403857421874996],
              [-54.56254882812499, 47.3751953125],
              [-54.542382812499994, 47.425097656249996],
              [-54.46323242187499, 47.53623046875],
              [-54.47392578124999, 47.5470703125],
              [-54.57451171874999, 47.457763671875],
              [-54.65117187499999, 47.408203125],
              [-54.74467773437499, 47.395458984375],
              [-54.801513671875, 47.3986328125],
              [-54.856640625, 47.385009765625],
              [-55.09042968749999, 47.17392578125],
              [-55.09921875, 47.103564453124996],
              [-55.1396484375, 47.045947265624996],
              [-55.25493164062499, 46.941748046875],
              [-55.31572265624999, 46.905712890625],
              [-55.401269531249994, 46.899267578125],
              [-55.47929687499999, 46.917285156249996],
              [-55.530712890625, 46.914013671875],
              [-55.65234375, 46.8814453125],
              [-55.788525390625, 46.867236328124996],
              [-55.8447265625, 46.873828125],
              [-55.880615234375, 46.88720703125],
              [-55.94990234375, 46.927685546875],
              [-55.958203125, 46.956396484375],
              [-55.954492187499994, 46.9732421875],
              [-55.91923828124999, 47.016894531249996],
              [-55.83837890625, 47.071630859375],
              [-55.77182617187499, 47.092089843749996],
              [-55.61005859375, 47.11962890625],
              [-55.49150390624999, 47.16064453125],
              [-55.40122070312499, 47.221484375],
              [-55.36088867187499, 47.25859375],
              [-55.190820312499994, 47.448974609375],
              [-54.97563476562499, 47.516162109374996],
              [-54.86953125, 47.5708984375],
              [-54.795361328125, 47.64033203125],
              [-54.784619140625, 47.66474609375],
              [-54.891015625, 47.6294921875],
              [-54.945947265624994, 47.620849609375],
              [-55.03500976562499, 47.63388671875],
              [-55.07456054687499, 47.657568359375],
              [-55.19658203124999, 47.650048828124994],
              [-55.36630859374999, 47.661083984375],
              [-55.390771484374994, 47.642871093749996],
              [-55.41269531249999, 47.550390625],
              [-55.43466796874999, 47.501269531249996],
              [-55.46064453125, 47.484765625],
              [-55.49863281249999, 47.475048828125],
              [-55.576123046875, 47.465234375],
              [-55.77470703124999, 47.498291015625],
              [-55.81137695312499, 47.516357421875],
              [-55.862060546875, 47.530078125],
              [-56.08134765624999, 47.499951171875],
              [-56.12724609374999, 47.502832031249994],
              [-56.08369140625, 47.52451171875],
              [-55.867089843749994, 47.592333984374996],
              [-55.844384765624994, 47.787841796875],
              [-55.85791015625, 47.819189453125],
              [-55.91845703125, 47.791894531249994],
              [-56.02011718749999, 47.763720703124996],
              [-56.08964843749999, 47.771875],
              [-56.12143554687499, 47.78916015625],
              [-56.1505859375, 47.77451171875],
              [-56.22128906249999, 47.67138671875],
              [-56.26298828124999, 47.658447265625],
              [-56.32578125, 47.6544921875],
              [-56.45957031249999, 47.616943359375],
              [-56.722314453124994, 47.59228515625],
              [-56.77412109375, 47.564990234374996],
              [-56.95249023437499, 47.574462890625],
              [-57.47343749999999, 47.631103515625],
              [-57.659814453124994, 47.625390625],
              [-57.88408203124999, 47.660009765625],
              [-57.925537109375, 47.67490234375],
              [-58.23930664062499, 47.668847656249994],
              [-58.333203125, 47.67685546875],
              [-58.32695312499999, 47.719873046874994],
              [-58.336865234375, 47.730859375],
              [-58.42802734374999, 47.683398437499996],
              [-58.50888671874999, 47.652587890625],
              [-58.61313476562499, 47.626220703125],
              [-58.941162109375, 47.58046875],
              [-59.116943359375, 47.570703125],
              [-59.21928710937499, 47.6025390625],
              [-59.259765625, 47.634179687499994],
              [-59.32065429687499, 47.7369140625],
              [-59.36240234374999, 47.865673828125],
              [-59.362060546875, 47.888964843749996],
              [-59.34086914062499, 47.933642578124996],
              [-59.27207031249999, 47.995556640625],
              [-58.960839843749994, 48.159375],
              [-58.710595703124994, 48.325048828125],
              [-58.60498046875, 48.411328125],
              [-58.50263671875, 48.442041015624994],
              [-58.335546875, 48.513671875],
              [-58.330224609374994, 48.522119140624994],
              [-58.49223632812499, 48.513037109375],
              [-58.60615234375, 48.532861328124994],
              [-58.722558593749994, 48.54072265625],
              [-58.943798828125, 48.521777343749996],
              [-59.16679687499999, 48.521777343749996],
              [-59.16767578125, 48.558496093749994],
              [-59.063427734375, 48.627685546875],
              [-58.841796875, 48.746435546875],
              [-58.81918945312499, 48.746826171875],
              [-58.887109375, 48.691552734374994],
              [-58.9064453125, 48.650195312499996],
              [-58.87724609374999, 48.622705078124994],
              [-58.843408203124994, 48.605322265625],
              [-58.716455078124994, 48.598046875],
              [-58.68735351562499, 48.6220703125],
              [-58.6416015625, 48.749414062499994],
              [-58.54560546875, 48.896875],
              [-58.49375, 49.003222656249996],
              [-58.40366210937499, 49.084326171875],
              [-58.35869140624999, 49.096533203125],
              [-58.31875, 49.081347656249996],
              [-58.18613281249999, 49.061914062499994],
              [-58.04965820312499, 48.987548828125],
              [-58.00556640625, 48.98125],
              [-57.99052734374999, 48.987939453124994],
              [-58.04057617187499, 49.009765625],
              [-58.08183593749999, 49.044726562499996],
              [-58.09892578124999, 49.077441406249996],
              [-58.049072265625, 49.179980468749996],
              [-57.990673828125, 49.20947265625],
              [-57.980078125, 49.229638671874994],
              [-58.096875, 49.230078125],
              [-58.19091796875, 49.258740234375],
              [-58.218896484374994, 49.305126953125],
              [-58.21337890625, 49.386669921875],
              [-58.18271484374999, 49.435400390625],
              [-58.107421875, 49.49970703125],
              [-58.0158203125, 49.54248046875],
              [-57.96123046874999, 49.531542968749996],
              [-57.85605468749999, 49.473828125],
              [-57.79130859374999, 49.489990234375],
              [-57.798828125, 49.508544921875],
              [-57.8974609375, 49.600390625],
              [-57.92905273437499, 49.668408203125],
              [-57.926171875, 49.700830078124994],
              [-57.7125, 50.02490234375],
              [-57.60795898437499, 50.198779296874996],
              [-57.46552734375, 50.463671875],
              [-57.4326171875, 50.505810546875],
              [-57.36044921874999, 50.583935546875],
              [-57.33056640625, 50.60517578125],
              [-57.23740234374999, 50.605371093749994],
              [-57.179589843749994, 50.61484375],
              [-57.26416015625, 50.649365234375],
              [-57.29443359375, 50.673388671874996],
              [-57.297998046874994, 50.69873046875],
              [-57.27490234375, 50.72529296875],
              [-57.242138671875, 50.744921875],
              [-57.13164062499999, 50.787402343749996],
              [-57.05327148437499, 50.857324218749994],
              [-57.00566406249999, 50.9396484375],
              [-57.01274414062499, 50.967724609375],
              [-57.0373046875, 50.995654296874996],
              [-57.03593749999999, 51.01083984375],
              [-56.97636718749999, 51.027978515624994],
              [-56.825146484375, 51.125732421875],
              [-56.80546874999999, 51.144482421875],
              [-56.7501953125, 51.27490234375],
              [-56.68242187499999, 51.332763671875],
              [-56.619042968749994, 51.362451171874994],
              [-56.51796875, 51.399316406249994],
              [-56.20737304687499, 51.488623046875],
              [-56.0255859375, 51.568359375],
              [-55.902099609375, 51.563916015625],
              [-55.86582031249999, 51.50830078125],
              [-55.6904296875, 51.471337890624994],
              [-55.659570312499994, 51.511035156249996],
              [-55.700634765625, 51.559423828125],
              [-55.66640625, 51.57890625],
              [-55.521630859374994, 51.59638671875],
              [-55.49643554687499, 51.58984375],
              [-55.45322265624999, 51.562304687499996],
              [-55.458740234375, 51.536523437499994],
            ],
          ],
          [
            [
              [-86.58935546875, 71.010791015625],
              [-86.54965820312499, 70.98876953125],
              [-86.3212890625, 71.016796875],
              [-86.1271484375, 71.048974609375],
              [-85.82456054687499, 71.125732421875],
              [-85.64384765624999, 71.15244140625],
              [-85.094873046875, 71.151953125],
              [-85.0015625, 71.137451171875],
              [-85.04277343749999, 71.0916015625],
              [-85.06577148437499, 71.07861328125],
              [-85.04765624999999, 71.05869140625],
              [-84.9884765625, 71.03173828125],
              [-84.8703125, 71.001806640625],
              [-84.82373046875, 71.02861328125],
              [-84.78959960937499, 71.09326171875],
              [-84.70859375, 71.35869140625],
              [-84.67431640625, 71.43876953125],
              [-84.65810546875, 71.514599609375],
              [-84.65996093749999, 71.5861328125],
              [-84.6994140625, 71.6314453125],
              [-84.84013671874999, 71.658642578125],
              [-85.0322265625, 71.654052734375],
              [-85.13090820312499, 71.66123046875],
              [-85.25048828125, 71.67529296875],
              [-85.3390625, 71.697265625],
              [-85.3966796875, 71.72705078125],
              [-85.51152343749999, 71.816552734375],
              [-85.59619140625, 71.86640625],
              [-85.81333007812499, 71.9564453125],
              [-85.91162109375, 71.9865234375],
              [-85.86210937499999, 72.02197265625],
              [-85.664794921875, 72.06279296875],
              [-85.54580078125, 72.1015625],
              [-85.405908203125, 72.21484375],
              [-85.321875, 72.233154296875],
              [-85.01875, 72.2181640625],
              [-84.60849609374999, 72.1294921875],
              [-84.35166015624999, 72.05263671875],
              [-84.28374023437499, 72.044482421875],
              [-84.28232421874999, 72.058447265625],
              [-84.34746093749999, 72.09443359375],
              [-84.64296875, 72.18955078125],
              [-84.7775390625, 72.2587890625],
              [-84.8419921875, 72.308154296875],
              [-84.81103515625, 72.329541015625],
              [-84.644677734375, 72.351416015625],
              [-84.623046875, 72.3765625],
              [-84.84941406249999, 72.40625],
              [-84.96416015624999, 72.405615234375],
              [-85.0568359375, 72.384375],
              [-85.156396484375, 72.38291015625],
              [-85.34111328124999, 72.421533203125],
              [-85.39130859375, 72.443994140625],
              [-85.49775390625, 72.510595703125],
              [-85.5537109375, 72.568603515625],
              [-85.61557617187499, 72.604638671875],
              [-85.637890625, 72.633203125],
              [-85.64990234375, 72.72216796875],
              [-85.64453125, 72.774462890625],
              [-85.61943359374999, 72.819189453125],
              [-85.574609375, 72.856396484375],
              [-85.45478515625, 72.925146484375],
              [-85.38759765625, 72.94501953125],
              [-85.262109375, 72.95400390625],
              [-84.98955078124999, 72.919873046875],
              [-84.25664062499999, 72.796728515625],
              [-84.27426757812499, 72.83642578125],
              [-85.09404296874999, 73.00263671875],
              [-85.38388671874999, 73.04541015625],
              [-85.454736328125, 73.10546875],
              [-85.01840820312499, 73.335498046875],
              [-84.61606445312499, 73.38955078125],
              [-84.41606445312499, 73.456494140625],
              [-84.08896484374999, 73.459375],
              [-83.781884765625, 73.41689453125],
              [-83.776513671875, 73.428466796875],
              [-83.914990234375, 73.5083984375],
              [-83.904052734375, 73.5283203125],
              [-83.72983398437499, 73.57587890625],
              [-83.4103515625, 73.631689453125],
              [-83.020458984375, 73.676025390625],
              [-82.94321289062499, 73.69912109375],
              [-82.84331054687499, 73.7154296875],
              [-82.659619140625, 73.72958984375],
              [-82.202783203125, 73.736474609375],
              [-81.94614257812499, 73.729833984375],
              [-81.60537109375, 73.69599609375],
              [-81.40615234375, 73.634521484375],
              [-81.34409179687499, 73.59775390625],
              [-81.23833007812499, 73.479541015625],
              [-81.1517578125, 73.314013671875],
              [-81.025146484375, 73.24521484375],
              [-80.821728515625, 73.207177734375],
              [-80.68115234375, 73.1658203125],
              [-80.603466796875, 73.12119140625],
              [-80.582763671875, 73.06494140625],
              [-80.619140625, 72.99716796875],
              [-80.59189453124999, 72.927685546875],
              [-80.500927734375, 72.856591796875],
              [-80.430810546875, 72.816259765625],
              [-80.27724609375, 72.770166015625],
              [-80.27470703124999, 72.745556640625],
              [-80.32265625, 72.71748046875],
              [-80.42431640625, 72.67890625],
              [-80.67509765624999, 72.558642578125],
              [-80.99873046875, 72.426220703125],
              [-81.22934570312499, 72.31171875],
              [-81.24057617187499, 72.2779296875],
              [-80.76079101562499, 72.457177734375],
              [-80.611474609375, 72.450830078125],
              [-80.6046875, 72.42578125],
              [-80.70244140624999, 72.33828125],
              [-80.821484375, 72.26025390625],
              [-80.94121093749999, 72.21015625],
              [-80.9193359375, 72.191259765625],
              [-80.69140625, 72.103466796875],
              [-80.73325195312499, 72.089013671875],
              [-80.84326171875, 72.09619140625],
              [-80.88837890625, 72.08828125],
              [-80.92109375, 72.072314453125],
              [-80.94140625, 72.0482421875],
              [-80.94267578124999, 72.01435546875],
              [-80.925048828125, 71.970703125],
              [-80.92680664062499, 71.9380859375],
              [-80.947900390625, 71.916552734375],
              [-80.92514648437499, 71.907666015625],
              [-80.85844726562499, 71.91142578125],
              [-80.80224609375, 71.92919921875],
              [-80.70541992187499, 71.988134765625],
              [-80.3861328125, 72.148779296875],
              [-80.18193359374999, 72.2087890625],
              [-80.11611328125, 72.2140625],
              [-79.92832031249999, 72.174951171875],
              [-79.884375, 72.177197265625],
              [-80.09091796874999, 72.30087890625],
              [-80.10893554687499, 72.332177734375],
              [-80.06699218749999, 72.3783203125],
              [-80.04179687499999, 72.39423828125],
              [-79.926708984375, 72.428173828125],
              [-79.831298828125, 72.4462890625],
              [-79.77788085937499, 72.438720703125],
              [-79.693310546875, 72.375927734375],
              [-79.65385742187499, 72.332177734375],
              [-79.58369140625, 72.3146484375],
              [-79.42744140625, 72.3373046875],
              [-79.32333984374999, 72.3908203125],
              [-79.19438476562499, 72.355712890625],
              [-79.000244140625, 72.272021484375],
              [-79.01796875, 72.188232421875],
              [-79.0177734375, 72.104345703125],
              [-79.0078125, 72.042919921875],
              [-78.77592773437499, 71.93037109375],
              [-78.614453125, 71.881005859375],
              [-78.585107421875, 71.880615234375],
              [-78.5888671875, 71.897509765625],
              [-78.62255859375, 71.9349609375],
              [-78.7111328125, 71.972412109375],
              [-78.79082031249999, 72.0302734375],
              [-78.862744140625, 72.100830078125],
              [-78.82011718749999, 72.2654296875],
              [-78.69926757812499, 72.351416015625],
              [-78.582470703125, 72.329345703125],
              [-78.42880859374999, 72.27978515625],
              [-78.30747070312499, 72.275146484375],
              [-78.116357421875, 72.280322265625],
              [-77.726025390625, 72.17998046875],
              [-77.51650390625, 72.177783203125],
              [-77.53574218749999, 72.21875],
              [-77.694482421875, 72.238427734375],
              [-77.926171875, 72.29384765625],
              [-78.28720703124999, 72.359814453125],
              [-78.453076171875, 72.435205078125],
              [-78.48427734375, 72.47060546875],
              [-78.4794921875, 72.508740234375],
              [-78.45883789062499, 72.542333984375],
              [-78.42241210937499, 72.571533203125],
              [-78.350244140625, 72.6001953125],
              [-78.00102539062499, 72.68759765625],
              [-77.75322265624999, 72.724755859375],
              [-77.566796875, 72.736865234375],
              [-77.25537109375, 72.735888671875],
              [-76.893505859375, 72.720654296875],
              [-76.69794921875, 72.695068359375],
              [-76.47324218749999, 72.633349609375],
              [-76.18876953124999, 72.572216796875],
              [-76.0873046875, 72.561328125],
              [-75.96875, 72.562744140625],
              [-75.833203125, 72.576513671875],
              [-75.704296875, 72.571533203125],
              [-75.29423828124999, 72.480859375],
              [-75.185791015625, 72.434228515625],
              [-75.120068359375, 72.377734375],
              [-75.071484375, 72.3228515625],
              [-75.03984374999999, 72.269580078125],
              [-75.052685546875, 72.2263671875],
              [-75.394140625, 72.039794921875],
              [-75.54277343749999, 72.007958984375],
              [-75.64096679687499, 71.937158203125],
              [-75.78740234374999, 71.803076171875],
              [-75.911279296875, 71.731298828125],
              [-75.922802734375, 71.717236328125],
              [-75.89682617187499, 71.713720703125],
              [-75.82207031249999, 71.7458984375],
              [-75.693359375, 71.83857421875],
              [-75.59990234374999, 71.91845703125],
              [-75.428369140625, 71.984375],
              [-75.14765625, 72.06298828125],
              [-74.90317382812499, 72.10048828125],
              [-74.694921875, 72.096923828125],
              [-74.519677734375, 72.08564453125],
              [-74.37744140625, 72.066552734375],
              [-74.29296875, 72.0505859375],
              [-74.266357421875, 72.0376953125],
              [-74.20932617187499, 71.978662109375],
              [-74.21259765625, 71.938671875],
              [-74.2482421875, 71.89365234375],
              [-74.31572265624999, 71.84267578125],
              [-74.621484375, 71.786279296875],
              [-74.7890625, 71.7419921875],
              [-74.89296875, 71.725537109375],
              [-75.20478515625, 71.709130859375],
              [-75.191064453125, 71.6916015625],
              [-74.95947265625, 71.66748046875],
              [-74.70078125, 71.6755859375],
              [-74.70737304687499, 71.646923828125],
              [-74.82895507812499, 71.5708984375],
              [-74.868310546875, 71.504736328125],
              [-74.83447265625, 71.4505859375],
              [-74.84072265625, 71.406591796875],
              [-74.931298828125, 71.3140625],
              [-75.0353515625, 71.230517578125],
              [-74.99619140624999, 71.218115234375],
              [-74.758935546875, 71.338134765625],
              [-74.6953125, 71.46943359375],
              [-74.599560546875, 71.58486328125],
              [-74.48808593749999, 71.648388671875],
              [-74.40410156249999, 71.672509765625],
              [-74.1390625, 71.6822265625],
              [-73.99208984375, 71.749609375],
              [-73.8666015625, 71.771044921875],
              [-73.8140625, 71.771435546875],
              [-73.7072265625, 71.746337890625],
              [-73.71357421875, 71.719873046875],
              [-73.868603515625, 71.599365234375],
              [-74.197265625, 71.404150390625],
              [-74.06333007812499, 71.42646484375],
              [-73.97250976562499, 71.4728515625],
              [-73.85087890624999, 71.519140625],
              [-73.712841796875, 71.58759765625],
              [-73.62167968749999, 71.525537109375],
              [-73.481591796875, 71.479248046875],
              [-73.39780273437499, 71.3734375],
              [-73.26240234375, 71.3224609375],
              [-73.180615234375, 71.282861328125],
              [-73.19218749999999, 71.349853515625],
              [-73.31044921875, 71.48427734375],
              [-73.27822265625, 71.53798828125],
              [-73.18681640624999, 71.564892578125],
              [-72.901953125, 71.677783203125],
              [-72.70302734375, 71.64013671875],
              [-72.58061523437499, 71.606787109375],
              [-72.519287109375, 71.615625],
              [-72.36459960937499, 71.610986328125],
              [-72.11650390624999, 71.5927734375],
              [-71.8751953125, 71.56123046875],
              [-71.64067382812499, 71.516259765625],
              [-71.459912109375, 71.463720703125],
              [-71.332958984375, 71.403466796875],
              [-71.2560546875, 71.36181640625],
              [-71.22939453125, 71.33876953125],
              [-71.1865234375, 71.2787109375],
              [-71.219384765625, 71.238818359375],
              [-71.39658203124999, 71.146875],
              [-71.49501953125, 71.105126953125],
              [-71.59306640624999, 71.086376953125],
              [-71.85615234375, 71.10478515625],
              [-71.937939453125, 71.094287109375],
              [-72.023876953125, 71.06533203125],
              [-72.29770507812499, 70.938818359375],
              [-72.44912109375, 70.88408203125],
              [-72.598046875, 70.84921875],
              [-72.63271484375, 70.83076171875],
              [-72.31254882812499, 70.83251953125],
              [-72.22392578124999, 70.870166015625],
              [-72.15, 70.940673828125],
              [-72.0091796875, 71.013427734375],
              [-71.74252929687499, 71.046875],
              [-71.370849609375, 70.975146484375],
              [-71.18623046875, 70.97802734375],
              [-71.045361328125, 71.05],
              [-70.88803710937499, 71.0990234375],
              [-70.82607421875, 71.108740234375],
              [-70.79248046875, 71.1033203125],
              [-70.67265624999999, 71.052197265625],
              [-70.636474609375, 71.006591796875],
              [-70.639111328125, 70.90244140625],
              [-70.655224609375, 70.8708984375],
              [-70.76171875, 70.792236328125],
              [-71.02177734374999, 70.67412109375],
              [-71.191796875, 70.62978515625],
              [-71.38046875, 70.60595703125],
              [-71.5859375, 70.565869140625],
              [-71.658447265625, 70.533544921875],
              [-71.72939453125, 70.4876953125],
              [-71.80014648437499, 70.45703125],
              [-71.89018554687499, 70.43154296875],
              [-71.77236328125, 70.394189453125],
              [-71.72724609375, 70.39521484375],
              [-71.68369140624999, 70.417578125],
              [-71.56499023437499, 70.5056640625],
              [-71.47666015624999, 70.54404296875],
              [-71.42666015625, 70.552099609375],
              [-71.37509765624999, 70.5484375],
              [-71.324853515625, 70.53115234375],
              [-71.27587890625, 70.50029296875],
              [-71.27958984374999, 70.4251953125],
              [-71.429443359375, 70.127783203125],
              [-71.40512695312499, 70.128662109375],
              [-71.31308593749999, 70.209326171875],
              [-71.04526367187499, 70.51904296875],
              [-70.97978515624999, 70.5810546875],
              [-70.850537109375, 70.643603515625],
              [-70.5609375, 70.73828125],
              [-70.33725585937499, 70.787841796875],
              [-70.084716796875, 70.829541015625],
              [-69.94980468749999, 70.84501953125],
              [-69.79570312499999, 70.8345703125],
              [-69.6955078125, 70.785888671875],
              [-69.56010742187499, 70.7771484375],
              [-69.39536132812499, 70.7892578125],
              [-69.2890625, 70.783447265625],
              [-69.168701171875, 70.76416015625],
              [-69.06572265624999, 70.728076171875],
              [-68.89072265624999, 70.687109375],
              [-68.495751953125, 70.61025390625],
              [-68.4466796875, 70.594091796875],
              [-68.400830078125, 70.564990234375],
              [-68.35825195312499, 70.522900390625],
              [-68.363525390625, 70.48125],
              [-68.416650390625, 70.439990234375],
              [-68.482568359375, 70.41484375],
              [-68.56132812499999, 70.4056640625],
              [-68.642822265625, 70.383203125],
              [-68.79365234375, 70.3244140625],
              [-68.842919921875, 70.314453125],
              [-69.07944335937499, 70.28916015625],
              [-69.29873046875, 70.276806640625],
              [-69.435693359375, 70.253125],
              [-69.698974609375, 70.189306640625],
              [-70.06142578125, 70.070849609375],
              [-70.05771484374999, 70.042626953125],
              [-69.9130859375, 70.029052734375],
              [-69.795849609375, 70.046923828125],
              [-69.63457031249999, 70.128759765625],
              [-69.48300781249999, 70.16005859375],
              [-69.24619140624999, 70.185107421875],
              [-68.91855468749999, 70.206982421875],
              [-68.77822265625, 70.203564453125],
              [-68.7529296875, 70.199169921875],
              [-68.734619140625, 70.179833984375],
              [-68.723291015625, 70.145654296875],
              [-68.776953125, 70.101025390625],
              [-68.839111328125, 70.079931640625],
              [-69.00830078125, 69.978955078125],
              [-68.89702148437499, 69.952734375],
              [-68.74404296875, 69.94140625],
              [-68.65673828125, 69.96845703125],
              [-68.57783203125, 70.03046875],
              [-68.48935546874999, 70.06484375],
              [-68.391259765625, 70.071630859375],
              [-68.305078125, 70.08740234375],
              [-68.2306640625, 70.11220703125],
              [-68.21044921875, 70.12841796875],
              [-68.318603515625, 70.160595703125],
              [-68.32719726562499, 70.18017578125],
              [-68.28310546875, 70.228271484375],
              [-68.203515625, 70.281494140625],
              [-68.12065429687499, 70.314599609375],
              [-68.05908203125, 70.317236328125],
              [-67.85532226562499, 70.281787109375],
              [-67.716015625, 70.21982421875],
              [-67.363671875, 70.034423828125],
              [-67.31840820312499, 69.9984375],
              [-67.19589843749999, 69.860693359375],
              [-67.17265624999999, 69.799462890625],
              [-67.1927734375, 69.7568359375],
              [-67.221630859375, 69.730712890625],
              [-67.25927734375, 69.7212890625],
              [-67.33671874999999, 69.72099609375],
              [-67.80620117187499, 69.777392578125],
              [-68.02041015625, 69.770068359375],
              [-68.11396484375, 69.754296875],
              [-68.189453125, 69.730615234375],
              [-68.24809570312499, 69.70078125],
              [-68.289794921875, 69.664697265625],
              [-68.3720703125, 69.644384765625],
              [-68.669921875, 69.64365234375],
              [-68.837109375, 69.62353515625],
              [-69.12451171875, 69.57451171875],
              [-69.227685546875, 69.547412109375],
              [-69.25078124999999, 69.5119140625],
              [-69.07490234375, 69.518115234375],
              [-68.78525390624999, 69.564208984375],
              [-68.51303710937499, 69.577294921875],
              [-68.05815429687499, 69.47587890625],
              [-67.90825195312499, 69.460107421875],
              [-67.824853515625, 69.47470703125],
              [-67.72451171875, 69.479248046875],
              [-67.3609375, 69.472509765625],
              [-67.236962890625, 69.460107421875],
              [-67.052685546875, 69.42119140625],
              [-66.77084960937499, 69.336669921875],
              [-66.716748046875, 69.311865234375],
              [-66.68525390625, 69.2857421875],
              [-66.67626953125, 69.258447265625],
              [-66.679296875, 69.191064453125],
              [-66.707421875, 69.168212890625],
              [-66.802880859375, 69.152734375],
              [-67.2080078125, 69.170654296875],
              [-67.331640625, 69.184716796875],
              [-67.4837890625, 69.1669921875],
              [-67.60722656249999, 69.173193359375],
              [-67.7650390625, 69.200244140625],
              [-67.9384765625, 69.24814453125],
              [-68.198193359375, 69.202685546875],
              [-68.40629882812499, 69.2322265625],
              [-68.618896484375, 69.206005859375],
              [-69.040625, 69.097998046875],
              [-68.99345703124999, 69.079345703125],
              [-68.41552734375, 69.1720703125],
              [-68.303955078125, 69.16640625],
              [-68.1212890625, 69.1326171875],
              [-67.83261718749999, 69.065966796875],
              [-67.751708984375, 69.038671875],
              [-67.75102539062499, 68.933837890625],
              [-67.7951171875, 68.863330078125],
              [-67.883203125, 68.783984375],
              [-68.015625, 68.794677734375],
              [-68.32421875, 68.84404296875],
              [-68.450390625, 68.850830078125],
              [-68.54277343749999, 68.8427734375],
              [-68.66669921875, 68.811328125],
              [-68.72529296875, 68.810205078125],
              [-69.21884765624999, 68.872802734375],
              [-69.32978515625, 68.87578125],
              [-69.34267578125, 68.869384765625],
              [-69.319091796875, 68.856982421875],
              [-68.87143554687499, 68.7599609375],
              [-68.540625, 68.749365234375],
              [-68.333203125, 68.732568359375],
              [-68.210400390625, 68.702978515625],
              [-68.152490234375, 68.6810546875],
              [-68.14833984375, 68.61611328125],
              [-68.03793945312499, 68.550732421875],
              [-67.9384765625, 68.524169921875],
              [-67.87504882812499, 68.52294921875],
              [-67.766015625, 68.547021484375],
              [-67.65595703125, 68.550732421875],
              [-67.56694335937499, 68.533984375],
              [-67.455517578125, 68.497900390625],
              [-67.320703125, 68.48779296875],
              [-67.20249023437499, 68.465869140625],
              [-67.11118164062499, 68.461474609375],
              [-66.85419921875, 68.471630859375],
              [-66.74272460937499, 68.457763671875],
              [-66.71391601562499, 68.445703125],
              [-66.76240234375, 68.424658203125],
              [-66.997265625, 68.374169921875],
              [-67.032958984375, 68.32607421875],
              [-66.900390625, 68.263525390625],
              [-66.83095703125, 68.215625],
              [-66.83432617187499, 68.1798828125],
              [-66.90512695312499, 68.098486328125],
              [-66.923095703125, 68.06572265625],
              [-66.8998046875, 68.0630859375],
              [-66.72900390625, 68.12900390625],
              [-66.70234375, 68.120556640625],
              [-66.6845703125, 68.029248046875],
              [-66.66269531249999, 68.034423828125],
              [-66.60546875, 68.110009765625],
              [-66.63095703124999, 68.21064453125],
              [-66.53076171875, 68.250341796875],
              [-66.21240234375, 68.280419921875],
              [-66.26630859375, 68.122705078125],
              [-66.27470703124999, 68.040771484375],
              [-66.41386718749999, 67.904296875],
              [-66.52998046875, 67.860302734375],
              [-66.52646484374999, 67.851171875],
              [-66.44394531249999, 67.833837890625],
              [-66.39238281249999, 67.83193359375],
              [-66.34296875, 67.853271484375],
              [-66.22519531249999, 67.958740234375],
              [-65.98583984375, 68.0685546875],
              [-65.9423828125, 68.070947265625],
              [-65.943994140625, 68.031201171875],
              [-65.97490234374999, 67.957421875],
              [-65.86435546874999, 67.9228515625],
              [-65.758935546875, 67.957080078125],
              [-65.70170898437499, 67.986669921875],
              [-65.5693359375, 67.98232421875],
              [-65.50908203124999, 67.96826171875],
              [-65.49111328125, 67.935693359375],
              [-65.552001953125, 67.799365234375],
              [-65.54086914062499, 67.765625],
              [-65.40126953125, 67.674853515625],
              [-65.387109375, 67.6802734375],
              [-65.4134765625, 67.724072265625],
              [-65.44223632812499, 67.83232421875],
              [-65.41533203124999, 67.879248046875],
              [-65.30034179687499, 67.939501953125],
              [-65.064404296875, 68.026220703125],
              [-64.97690429687499, 68.043408203125],
              [-64.922314453125, 68.031640625],
              [-64.83544921875, 67.989990234375],
              [-64.862548828125, 67.96513671875],
              [-64.95639648437499, 67.939111328125],
              [-65.026025390625, 67.892041015625],
              [-65.071435546875, 67.823828125],
              [-65.02109375, 67.787548828125],
              [-64.82988281249999, 67.78427734375],
              [-64.63779296874999, 67.840234375],
              [-64.5275390625, 67.8126953125],
              [-64.396435546875, 67.73994140625],
              [-64.15625, 67.622998046875],
              [-64.01943359375, 67.6548828125],
              [-63.85019531249999, 67.566064453125],
              [-64.07749023437499, 67.49560546875],
              [-64.007958984375, 67.347314453125],
              [-64.30327148437499, 67.353466796875],
              [-64.46928710937499, 67.341845703125],
              [-64.58046875, 67.35517578125],
              [-64.699951171875, 67.350537109375],
              [-64.5892578125, 67.31552734375],
              [-64.375927734375, 67.30107421875],
              [-64.3564453125, 67.25615234375],
              [-64.18896484375, 67.257275390625],
              [-64.063232421875, 67.26591796875],
              [-63.83623046874999, 67.264111328125],
              [-63.824121093749994, 67.315673828125],
              [-63.67646484375, 67.3451171875],
              [-63.59160156249999, 67.3775390625],
              [-63.52109375, 67.358349609375],
              [-63.315820312499994, 67.336328125],
              [-63.04013671874999, 67.235009765625],
              [-63.16162109375, 67.174365234375],
              [-63.19467773437499, 67.117041015625],
              [-63.235546875, 67.068505859375],
              [-63.25839843749999, 67.024658203125],
              [-63.306787109374994, 66.994482421875],
              [-63.7015625, 66.82236328125],
              [-63.63623046875, 66.82080078125],
              [-63.469189453125, 66.86240234375],
              [-63.143701171874994, 66.92431640625],
              [-62.962304687499994, 66.949267578125],
              [-62.83334960937499, 66.93271484375],
              [-62.76816406249999, 66.931982421875],
              [-62.71044921875, 66.9541015625],
              [-62.60288085937499, 66.92861328125],
              [-62.37973632812499, 66.90537109375],
              [-62.12358398437499, 67.046728515625],
              [-61.96855468749999, 67.01904296875],
              [-61.824121093749994, 66.93173828125],
              [-61.51469726562499, 66.778466796875],
              [-61.353417968749994, 66.689208984375],
              [-61.299707031249994, 66.64873046875],
              [-61.30722656249999, 66.608837890625],
              [-61.453076171875, 66.5666015625],
              [-61.52783203125, 66.55810546875],
              [-61.72412109375, 66.63779296875],
              [-61.9044921875, 66.678125],
              [-62.01425781249999, 66.673779296875],
              [-62.12333984374999, 66.64306640625],
              [-62.089306640625, 66.625927734375],
              [-61.65263671874999, 66.503125],
              [-61.576416015625, 66.4125],
              [-61.57080078125, 66.372900390625],
              [-61.862695312499994, 66.312841796875],
              [-61.95634765624999, 66.309326171875],
              [-62.158447265625, 66.33798828125],
              [-62.27690429687499, 66.39150390625],
              [-62.37451171875, 66.41083984375],
              [-62.50981445312499, 66.4171875],
              [-62.553125, 66.4068359375],
              [-62.405664062499994, 66.31591796875],
              [-62.419824218749994, 66.28857421875],
              [-62.495996093749994, 66.2708984375],
              [-62.53359375, 66.227001953125],
              [-62.242089843749994, 66.14794921875],
              [-62.02392578125, 66.067529296875],
              [-61.991601562499994, 66.035302734375],
              [-62.138671875, 66.011376953125],
              [-62.2443359375, 66.005859375],
              [-62.4677734375, 66.01748046875],
              [-62.59033203125, 66.034423828125],
              [-62.62412109374999, 66.016259765625],
              [-62.49736328124999, 65.9740234375],
              [-62.448388671874994, 65.9455078125],
              [-62.410302734374994, 65.90576171875],
              [-62.38818359375, 65.868310546875],
              [-62.381982421874994, 65.83330078125],
              [-62.48564453124999, 65.8044921875],
              [-62.610253906249994, 65.7236328125],
              [-62.65888671875, 65.63994140625],
              [-62.771728515625, 65.631982421875],
              [-62.817285156249994, 65.647705078125],
              [-62.968896484374994, 65.62236328125],
              [-63.1689453125, 65.65732421875],
              [-63.240673828125, 65.695556640625],
              [-63.458740234375, 65.85302734375],
              [-63.46435546875, 65.8353515625],
              [-63.409765625, 65.755810546875],
              [-63.4208984375, 65.70859375],
              [-63.651953125, 65.67431640625],
              [-63.65107421875, 65.660986328125],
              [-63.509228515625, 65.63603515625],
              [-63.33745117187499, 65.616748046875],
              [-63.3642578125, 65.543212890625],
              [-63.36337890624999, 65.229736328125],
              [-63.401806640625, 65.11845703125],
              [-63.48583984375, 65.021240234375],
              [-63.606591796874994, 64.928076171875],
              [-63.73715820312499, 64.989111328125],
              [-63.78935546874999, 65.0513671875],
              [-63.833203125, 65.08330078125],
              [-63.89560546874999, 65.10927734375],
              [-63.97626953125, 65.121484375],
              [-64.06142578125, 65.121923828125],
              [-64.15185546875, 65.066162109375],
              [-64.250439453125, 65.114306640625],
              [-64.345703125, 65.172412109375],
              [-64.309765625, 65.324560546875],
              [-64.269677734375, 65.40078125],
              [-64.28574218749999, 65.4001953125],
              [-64.33994140624999, 65.36416015625],
              [-64.46982421874999, 65.252734375],
              [-64.555078125, 65.1166015625],
              [-64.66533203124999, 65.1689453125],
              [-64.764794921875, 65.23408203125],
              [-64.846923828125, 65.299560546875],
              [-64.979638671875, 65.37509765625],
              [-65.10849609374999, 65.46376953125],
              [-65.17568359375, 65.5681640625],
              [-65.206982421875, 65.5896484375],
              [-65.28203124999999, 65.67666015625],
              [-65.311474609375, 65.701513671875],
              [-65.33740234375, 65.709765625],
              [-65.401611328125, 65.764013671875],
              [-65.378125, 65.8220703125],
              [-65.276953125, 65.890673828125],
              [-65.18486328124999, 65.93994140625],
              [-65.0322265625, 65.988525390625],
              [-64.8537109375, 66.01591796875],
              [-64.77250976562499, 66.078564453125],
              [-64.672998046875, 66.192724609375],
              [-64.56396484375, 66.27216796875],
              [-64.44536132812499, 66.317138671875],
              [-64.50439453125, 66.32548828125],
              [-64.65517578125, 66.28701171875],
              [-64.7611328125, 66.230908203125],
              [-64.88725585937499, 66.13740234375],
              [-65.00449218749999, 66.077734375],
              [-65.30537109375, 66.008447265625],
              [-65.41557617187499, 65.994580078125],
              [-65.543701171875, 65.98720703125],
              [-65.82573242187499, 65.996923828125],
              [-65.891064453125, 66.02021484375],
              [-65.857177734375, 66.08642578125],
              [-65.65634765624999, 66.204736328125],
              [-65.68837890625, 66.2130859375],
              [-65.758984375, 66.17119140625],
              [-65.85595703125, 66.142236328125],
              [-65.94003906249999, 66.12744140625],
              [-66.063720703125, 66.13271484375],
              [-66.20859375, 66.206396484375],
              [-66.27739257812499, 66.2291015625],
              [-66.419189453125, 66.2544921875],
              [-66.476953125, 66.279736328125],
              [-66.7123046875, 66.46044921875],
              [-66.759765625, 66.50849609375],
              [-66.78740234374999, 66.5556640625],
              [-66.862890625, 66.5953125],
              [-66.986328125, 66.627490234375],
              [-67.014794921875, 66.622216796875],
              [-66.97041015625, 66.581884765625],
              [-66.968994140625, 66.54716796875],
              [-67.07685546875, 66.52548828125],
              [-67.1896484375, 66.5330078125],
              [-67.30732421875, 66.5697265625],
              [-67.31767578124999, 66.520361328125],
              [-67.19174804687499, 66.432763671875],
              [-67.18974609374999, 66.321728515625],
              [-67.22539062499999, 66.31025390625],
              [-67.31123046875, 66.303759765625],
              [-67.36884765625, 66.31748046875],
              [-67.559765625, 66.400439453125],
              [-67.74077148437499, 66.458203125],
              [-67.86845703124999, 66.49013671875],
              [-67.88339843749999, 66.467431640625],
              [-67.80058593749999, 66.367333984375],
              [-67.7044921875, 66.268603515625],
              [-67.54721679687499, 66.18720703125],
              [-67.29672851562499, 66.090283203125],
              [-67.183203125, 66.034423828125],
              [-67.27265625, 65.95556640625],
              [-67.35043945312499, 65.929736328125],
              [-67.39877929687499, 65.921728515625],
              [-67.55078125, 65.921630859375],
              [-67.82802734375, 65.965185546875],
              [-67.958203125, 66.013818359375],
              [-68.14726562499999, 66.129833984375],
              [-68.459912109375, 66.249267578125],
              [-68.527783203125, 66.2486328125],
              [-68.74892578125, 66.200048828125],
              [-68.71420898437499, 66.192236328125],
              [-68.5716796875, 66.188720703125],
              [-68.46708984374999, 66.173193359375],
              [-68.2171875, 66.078857421875],
              [-68.19833984374999, 66.03896484375],
              [-68.260693359375, 65.994580078125],
              [-68.2568359375, 65.938623046875],
              [-68.18671875, 65.87099609375],
              [-68.11503906249999, 65.827783203125],
              [-67.96806640624999, 65.797265625],
              [-67.894189453125, 65.79326171875],
              [-67.866455078125, 65.773681640625],
              [-67.954345703125, 65.623095703125],
              [-67.96181640625, 65.58193359375],
              [-67.936767578125, 65.564892578125],
              [-67.90605468749999, 65.5634765625],
              [-67.71713867187499, 65.625341796875],
              [-67.6380859375, 65.6404296875],
              [-67.56962890624999, 65.6435546875],
              [-67.49013671875, 65.626220703125],
              [-67.39970703124999, 65.58837890625],
              [-67.34638671875, 65.549365234375],
              [-67.330322265625, 65.5091796875],
              [-67.30341796875, 65.48291015625],
              [-67.11796874999999, 65.440380859375],
              [-67.1349609375, 65.4205078125],
              [-67.32607421875, 65.356640625],
              [-67.33652343749999, 65.34658203125],
              [-67.29833984375, 65.341943359375],
              [-67.17758789062499, 65.30380859375],
              [-67.06650390624999, 65.244091796875],
              [-66.99858398437499, 65.172998046875],
              [-66.98491210937499, 65.138037109375],
              [-66.98564453124999, 65.104833984375],
              [-66.970361328125, 65.084912109375],
              [-66.9115234375, 65.08134765625],
              [-66.8875, 65.093994140625],
              [-66.86064453124999, 65.0916015625],
              [-66.83090820312499, 65.074169921875],
              [-66.79960937499999, 65.019677734375],
              [-66.73276367187499, 64.86005859375],
              [-66.697412109375, 64.815185546875],
              [-66.6771484375, 64.813671875],
              [-66.66669921875, 64.973828125],
              [-66.635498046875, 65.000341796875],
              [-66.5177734375, 64.97197265625],
              [-66.34521484375, 64.909619140625],
              [-66.22373046874999, 64.8541015625],
              [-66.209716796875, 64.828125],
              [-66.301513671875, 64.777734375],
              [-66.28212890625, 64.755322265625],
              [-66.21464843749999, 64.722412109375],
              [-66.152490234375, 64.734912109375],
              [-66.10751953124999, 64.7912109375],
              [-66.03017578125, 64.84658203125],
              [-65.93852539062499, 64.8857421875],
              [-65.76806640625, 64.853564453125],
              [-65.62675781249999, 64.770751953125],
              [-65.60527343749999, 64.742333984375],
              [-65.51318359375, 64.706494140625],
              [-65.43193359374999, 64.726416015625],
              [-65.2748046875, 64.63154296875],
              [-65.34931640625, 64.588525390625],
              [-65.51279296874999, 64.5259765625],
              [-65.52934570312499, 64.50478515625],
              [-65.489990234375, 64.509619140625],
              [-65.17861328125, 64.509716796875],
              [-65.09453124999999, 64.4845703125],
              [-65.074609375, 64.436669921875],
              [-65.21298828124999, 64.303271484375],
              [-65.33989257812499, 64.315087890625],
              [-65.507470703125, 64.318310546875],
              [-65.59365234375, 64.3111328125],
              [-65.580322265625, 64.29384765625],
              [-65.347802734375, 64.23232421875],
              [-65.281982421875, 64.181640625],
              [-65.1927734375, 64.129833984375],
              [-65.149609375, 64.087158203125],
              [-65.150634765625, 64.067529296875],
              [-65.18730468749999, 64.03798828125],
              [-65.169873046875, 64.028173828125],
              [-65.01059570312499, 64.008837890625],
              [-64.91181640625, 64.026171875],
              [-64.78779296875, 64.032763671875],
              [-64.678466796875, 64.027978515625],
              [-64.66972656249999, 64.0095703125],
              [-64.686181640625, 63.9609375],
              [-64.79814453124999, 63.915966796875],
              [-64.76816406249999, 63.905419921874994],
              [-64.63671875, 63.918359375],
              [-64.576318359375, 63.897363281249994],
              [-64.498486328125, 63.79033203125],
              [-64.41093749999999, 63.70634765625],
              [-64.48222656249999, 63.687060546875],
              [-64.56157226562499, 63.6796875],
              [-64.55029296875, 63.57255859375],
              [-64.49863281249999, 63.462792968749994],
              [-64.49809570312499, 63.357568359374994],
              [-64.51435546875, 63.26396484375],
              [-64.5869140625, 63.2431640625],
              [-64.66464843749999, 63.245361328125],
              [-64.69560546874999, 63.26884765625],
              [-64.88627929687499, 63.548730468749994],
              [-64.93330078125, 63.599267578124994],
              [-64.989697265625, 63.643359375],
              [-65.191845703125, 63.7642578125],
              [-65.18393554687499, 63.74482421875],
              [-65.13383789062499, 63.6890625],
              [-65.08940429687499, 63.60595703125],
              [-65.03134765624999, 63.44013671875],
              [-65.00478515625, 63.333398437499994],
              [-65.01669921874999, 63.292822265625],
              [-65.058056640625, 63.282861328124994],
              [-65.06894531249999, 63.2634765625],
              [-65.04931640625, 63.234619140625],
              [-64.89482421874999, 63.125634765624994],
              [-64.82016601562499, 63.060009765625],
              [-64.76738281249999, 62.991796875],
              [-64.71811523437499, 62.94580078125],
              [-64.67236328125, 62.92197265625],
              [-64.68364257812499, 62.902392578125],
              [-64.75185546875, 62.887158203125],
              [-64.86870117187499, 62.8798828125],
              [-64.9232421875, 62.88916015625],
              [-65.132958984375, 62.95234375],
              [-65.16279296875, 62.9326171875],
              [-65.04658203125, 62.70146484375],
              [-65.0501953125, 62.646142578124994],
              [-65.10849609374999, 62.62646484375],
              [-65.180322265625, 62.649462890625],
              [-65.2658203125, 62.715087890625],
              [-65.39653320312499, 62.78818359375],
              [-65.572412109375, 62.868896484375],
              [-65.740380859375, 62.931982421875],
              [-65.77988281249999, 62.9302734375],
              [-65.8056640625, 62.911572265625],
              [-65.83369140625, 62.908544921875],
              [-65.86406249999999, 62.921142578125],
              [-65.92026367187499, 62.968505859375],
              [-65.97885742187499, 63.00068359375],
              [-66.2240234375, 63.107177734375],
              [-66.24921875, 63.108251953125],
              [-66.22607421875, 63.076318359374994],
              [-66.20107421875, 63.00625],
              [-66.228662109375, 62.990966796875],
              [-66.29277343749999, 62.9966796875],
              [-66.414453125, 63.027197265625],
              [-66.49638671874999, 63.097265625],
              [-66.60048828125, 63.218896484374994],
              [-66.65498046875, 63.26474609375],
              [-66.659814453125, 63.234912109375],
              [-66.630859375, 63.119042968749994],
              [-66.63642578125, 63.080126953125],
              [-66.6974609375, 63.06953125],
              [-66.72324218749999, 63.08017578125],
              [-66.74853515625, 63.111083984375],
              [-66.77324218749999, 63.162255859374994],
              [-66.8314453125, 63.201123046875],
              [-66.923291015625, 63.227685546874994],
              [-66.97470703124999, 63.25556640625],
              [-67.000146484375, 63.305126953125],
              [-67.01791992187499, 63.31650390625],
              [-67.17978515624999, 63.305029296875],
              [-67.2609375, 63.340722656249994],
              [-67.49501953125, 63.4814453125],
              [-67.709228515625, 63.633935546874994],
              [-67.84423828125, 63.71455078125],
              [-67.89326171875, 63.733740234375],
              [-67.821435546875, 63.635009765625],
              [-67.74252929687499, 63.4892578125],
              [-67.72255859375, 63.422753906249994],
              [-67.7587890625, 63.4197265625],
              [-67.837890625, 63.44921875],
              [-68.2435546875, 63.637060546875],
              [-68.49375, 63.725488281249994],
              [-68.63286132812499, 63.741113281249994],
              [-68.85893554687499, 63.751855468749994],
              [-68.911083984375, 63.70322265625],
              [-68.7892578125, 63.595117187499994],
              [-68.670556640625, 63.513671875],
              [-68.555126953125, 63.458935546875],
              [-68.37392578125, 63.352197265624994],
              [-68.20805664062499, 63.214697265625],
              [-68.141259765625, 63.172314453125],
              [-67.91533203124999, 63.113671875],
              [-67.79746093749999, 63.098095703125],
              [-67.6759765625, 63.0935546875],
              [-67.66489257812499, 63.07265625],
              [-67.723779296875, 63.03369140625],
              [-67.736962890625, 63.0095703125],
              [-67.468212890625, 62.9482421875],
              [-67.366650390625, 62.91416015625],
              [-67.268505859375, 62.857568359374994],
              [-67.21269531249999, 62.843505859375],
              [-66.97954101562499, 62.700830078124994],
              [-66.921533203125, 62.678076171875],
              [-66.714013671875, 62.631787109375],
              [-66.64487304687499, 62.60205078125],
              [-66.530517578125, 62.5099609375],
              [-66.458740234375, 62.463134765625],
              [-66.35727539062499, 62.351904296875],
              [-66.28125, 62.302685546875],
              [-66.09501953124999, 62.24638671875],
              [-66.015625, 62.2302734375],
              [-65.98017578125, 62.208886718749994],
              [-66.004345703125, 62.15830078125],
              [-66.026953125, 62.13720703125],
              [-66.13315429687499, 62.102392578125],
              [-66.11640625, 62.05390625],
              [-66.05644531249999, 61.96748046875],
              [-66.05888671874999, 61.9138671875],
              [-66.12387695312499, 61.89306640625],
              [-66.25668945312499, 61.86826171875],
              [-66.32373046875, 61.870263671874994],
              [-66.42451171875, 61.89072265625],
              [-66.55131835937499, 61.9255859375],
              [-66.803125, 62.012597656249994],
              [-67.1810546875, 62.0728515625],
              [-67.322021484375, 62.105029296875],
              [-67.36899414062499, 62.13408203125],
              [-67.44013671875, 62.151269531249994],
              [-68.37861328125, 62.23515625],
              [-68.535888671875, 62.255615234375],
              [-68.63364257812499, 62.281298828125],
              [-68.724365234375, 62.318994140624994],
              [-69.08232421874999, 62.405175781249994],
              [-69.12558593749999, 62.423974609374994],
              [-69.36601562499999, 62.571875],
              [-69.545166015625, 62.744580078125],
              [-69.604736328125, 62.767724609374994],
              [-69.79951171875, 62.790478515625],
              [-69.962109375, 62.776171875],
              [-70.070947265625, 62.757226562499994],
              [-70.23613281249999, 62.76337890625],
              [-70.34404296874999, 62.79150390625],
              [-70.57133789062499, 62.869189453125],
              [-70.801416015625, 62.910498046875],
              [-71.0021484375, 62.978271484375],
              [-71.10576171874999, 63.00224609375],
              [-71.09619140625, 63.019677734374994],
              [-70.946044921875, 63.120703125],
              [-70.99267578125, 63.119287109374994],
              [-71.25371093749999, 63.042529296875],
              [-71.347265625, 63.06611328125],
              [-71.50126953124999, 63.126416015625],
              [-71.617138671875, 63.18720703125],
              [-71.85546875, 63.3552734375],
              [-71.99223632812499, 63.416162109374994],
              [-71.973046875, 63.4298828125],
              [-71.81918945312499, 63.435449218749994],
              [-71.696533203125, 63.430224609375],
              [-71.6142578125, 63.444091796875],
              [-71.45585937499999, 63.512255859375],
              [-71.38740234375, 63.555029296875],
              [-71.380859375, 63.580322265625],
              [-71.51347656249999, 63.586572265624994],
              [-71.54189453125, 63.598828125],
              [-71.565625, 63.6267578125],
              [-71.62675781249999, 63.66259765625],
              [-71.72529296875, 63.70615234375],
              [-71.837548828125, 63.724951171875],
              [-72.22294921874999, 63.708886718749994],
              [-72.29013671874999, 63.727978515625],
              [-72.28876953125, 63.756982421874994],
              [-72.21347656249999, 63.838720703125],
              [-72.17246093749999, 63.8716796875],
              [-72.159375, 63.889892578125],
              [-72.174267578125, 63.893408203125],
              [-72.22646484375, 63.891357421875],
              [-72.45, 63.818115234375],
              [-72.4984375, 63.823486328125],
              [-72.5861328125, 63.90078125],
              [-72.639306640625, 63.9890625],
              [-72.67807617187499, 64.02001953125],
              [-72.72958984374999, 64.03046875],
              [-72.91318359374999, 64.1171875],
              [-73.17431640625, 64.281884765625],
              [-73.27031249999999, 64.33349609375],
              [-73.377099609375, 64.37958984375],
              [-73.454541015625, 64.399267578125],
              [-73.44365234374999, 64.423486328125],
              [-73.27817382812499, 64.56025390625],
              [-73.27128906249999, 64.58251953125],
              [-73.4130859375, 64.574169921875],
              [-73.626953125, 64.6025390625],
              [-73.72841796875, 64.56826171875],
              [-73.79277343749999, 64.5662109375],
              [-73.86787109375, 64.5853515625],
              [-73.9103515625, 64.578125],
              [-73.950390625, 64.4658203125],
              [-73.981103515625, 64.437744140625],
              [-74.0255859375, 64.42265625],
              [-74.06479492187499, 64.424658203125],
              [-74.09873046874999, 64.443701171875],
              [-74.097900390625, 64.469921875],
              [-74.13046875, 64.6078125],
              [-74.205078125, 64.628125],
              [-74.41586914062499, 64.63349609375],
              [-74.46123046874999, 64.644677734375],
              [-74.512451171875, 64.670166015625],
              [-74.55625, 64.717333984375],
              [-74.59257812499999, 64.786181640625],
              [-74.63427734375, 64.82392578125],
              [-74.681396484375, 64.8306640625],
              [-74.719189453125, 64.825146484375],
              [-74.74775390625, 64.80732421875],
              [-74.813427734375, 64.796240234375],
              [-74.916259765625, 64.7919921875],
              [-74.91943359375, 64.76552734375],
              [-74.82304687499999, 64.71689453125],
              [-74.72983398437499, 64.64736328125],
              [-74.6400390625, 64.557080078125],
              [-74.6947265625, 64.49658203125],
              [-74.8939453125, 64.46572265625],
              [-75.0673828125, 64.456689453125],
              [-75.2150390625, 64.469384765625],
              [-75.32841796874999, 64.4904296875],
              [-75.48779296875, 64.540771484375],
              [-75.7150390625, 64.524365234375],
              [-75.76669921874999, 64.391943359375],
              [-75.81523437499999, 64.38466796875],
              [-76.03183593749999, 64.3880859375],
              [-76.11806640625, 64.376318359375],
              [-76.40683593749999, 64.303173828125],
              [-76.49472656249999, 64.29296875],
              [-76.5615234375, 64.301611328125],
              [-76.62651367187499, 64.283935546875],
              [-76.723828125, 64.242041015625],
              [-76.85615234375, 64.237646484375],
              [-77.02353515624999, 64.270849609375],
              [-77.165673828125, 64.28505859375],
              [-77.28251953124999, 64.28037109375],
              [-77.40288085937499, 64.29990234375],
              [-77.5267578125, 64.34375],
              [-77.62778320312499, 64.3634765625],
              [-77.760498046875, 64.36015625],
              [-77.791162109375, 64.36708984375],
              [-77.98486328125, 64.461083984375],
              [-78.04521484374999, 64.499267578125],
              [-78.174560546875, 64.617724609375],
              [-78.19755859374999, 64.6646484375],
              [-78.20087890625, 64.71474609375],
              [-78.189697265625, 64.751806640625],
              [-78.14462890624999, 64.80771484375],
              [-78.09560546875, 64.9392578125],
              [-78.05527343749999, 64.98291015625],
              [-77.994580078125, 65.022607421875],
              [-77.876171875, 65.07294921875],
              [-77.4474609375, 65.161572265625],
              [-77.36088867187499, 65.196533203125],
              [-77.36386718749999, 65.219775390625],
              [-77.46147460937499, 65.328173828125],
              [-77.460400390625, 65.355908203125],
              [-77.427685546875, 65.372119140625],
              [-77.35800781249999, 65.43544921875],
              [-77.32670898437499, 65.453125],
              [-77.251171875, 65.462890625],
              [-77.094140625, 65.430859375],
              [-76.95859375, 65.418017578125],
              [-76.77890625, 65.4138671875],
              [-76.481689453125, 65.3697265625],
              [-76.06699218749999, 65.28544921875],
              [-75.8283203125, 65.22705078125],
              [-75.64814453125, 65.1408203125],
              [-75.519921875, 65.056005859375],
              [-75.5015625, 65.0130859375],
              [-75.5609375, 64.947021484375],
              [-75.59086914062499, 64.927685546875],
              [-75.589111328125, 64.905029296875],
              [-75.55576171874999, 64.87919921875],
              [-75.452099609375, 64.8416015625],
              [-75.4271484375, 64.855859375],
              [-75.43515625, 64.90078125],
              [-75.413671875, 64.938525390625],
              [-75.36279296875, 64.96904296875],
              [-75.35712890625, 65.008740234375],
              [-75.3966796875, 65.057568359375],
              [-75.44580078125, 65.09970703125],
              [-75.50468749999999, 65.13515625],
              [-75.77294921875, 65.25703125],
              [-75.79868164062499, 65.297509765625],
              [-75.70859375, 65.31572265625],
              [-75.316650390625, 65.2748046875],
              [-75.16630859374999, 65.283935546875],
              [-75.10927734375, 65.3314453125],
              [-75.04775390625, 65.36396484375],
              [-74.98173828124999, 65.3814453125],
              [-74.849853515625, 65.3890625],
              [-74.665478515625, 65.366943359375],
              [-74.57490234375, 65.363671875],
              [-74.494775390625, 65.3716796875],
              [-74.39072265624999, 65.39755859375],
              [-74.23686523437499, 65.48388671875],
              [-74.13847656249999, 65.503466796875],
              [-73.98959960937499, 65.5169921875],
              [-73.8779296875, 65.51884765625],
              [-73.675390625, 65.484326171875],
              [-73.55078125, 65.48525390625],
              [-73.5607421875, 65.542919921875],
              [-73.64340820312499, 65.65322265625],
              [-73.74609375, 65.76669921875],
              [-73.82607421875, 65.80517578125],
              [-74.03310546875, 65.87705078125],
              [-74.27617187499999, 66.012744140625],
              [-74.40107421875, 66.09697265625],
              [-74.43393554687499, 66.139013671875],
              [-74.41640625, 66.16708984375],
              [-74.37490234375, 66.208154296875],
              [-73.93369140624999, 66.358056640625],
              [-73.584228515625, 66.50693359375],
              [-73.43095703124999, 66.583154296875],
              [-73.357373046875, 66.636279296875],
              [-73.28081054687499, 66.674951171875],
              [-73.201123046875, 66.699169921875],
              [-73.03325195312499, 66.728173828125],
              [-72.9853515625, 66.765380859375],
              [-72.974853515625, 66.828515625],
              [-72.94677734375, 66.883251953125],
              [-72.788818359375, 67.030615234375],
              [-72.667724609375, 67.070458984375],
              [-72.48515624999999, 67.098095703125],
              [-72.36494140625, 67.1333984375],
              [-72.22001953124999, 67.254296875],
              [-72.234130859375, 67.284423828125],
              [-72.30107421874999, 67.307275390625],
              [-72.35288085937499, 67.34189453125],
              [-72.57646484374999, 67.658642578125],
              [-72.72529296875, 67.81162109375],
              [-72.903955078125, 67.944775390625],
              [-73.063427734375, 68.106982421875],
              [-73.32822265624999, 68.266748046875],
              [-73.3314453125, 68.308984375],
              [-73.28447265624999, 68.356982421875],
              [-73.306884765625, 68.367822265625],
              [-73.58017578124999, 68.29775390625],
              [-73.644482421875, 68.29453125],
              [-73.749462890625, 68.325],
              [-73.8205078125, 68.362939453125],
              [-73.879345703125, 68.42939453125],
              [-73.87333984374999, 68.46416015625],
              [-73.834423828125, 68.4970703125],
              [-73.78251953124999, 68.57802734375],
              [-73.78061523437499, 68.619287109375],
              [-73.7984375, 68.658642578125],
              [-73.82211914062499, 68.685986328125],
              [-73.8515625, 68.7013671875],
              [-73.93515625, 68.710986328125],
              [-74.072998046875, 68.71494140625],
              [-74.11796874999999, 68.700927734375],
              [-73.966064453125, 68.578759765625],
              [-73.9892578125, 68.5486328125],
              [-74.1828125, 68.53544921875],
              [-74.27011718749999, 68.5412109375],
              [-74.35, 68.5560546875],
              [-74.42241210937499, 68.579931640625],
              [-74.64794921875, 68.70751953125],
              [-74.69580078125, 68.75556640625],
              [-74.68051757812499, 68.790283203125],
              [-74.699951171875, 68.808349609375],
              [-74.74599609375, 68.796728515625],
              [-74.808349609375, 68.7958984375],
              [-74.89296875, 68.808154296875],
              [-74.910400390625, 68.82314453125],
              [-74.752392578125, 68.89208984375],
              [-74.74326171874999, 68.91337890625],
              [-74.81611328125, 68.9361328125],
              [-74.92509765624999, 68.94072265625],
              [-74.95400390625, 68.961083984375],
              [-74.91728515624999, 68.982861328125],
              [-74.76933593749999, 69.020654296875],
              [-74.71669921875, 69.0455078125],
              [-74.80546874999999, 69.0642578125],
              [-74.8548828125, 69.0658203125],
              [-74.95444335937499, 69.024609375],
              [-75.104248046875, 68.940576171875],
              [-75.21328125, 68.909375],
              [-75.362744140625, 68.948291015625],
              [-75.456982421875, 68.961279296875],
              [-75.52265625, 68.952734375],
              [-75.623046875, 68.887744140625],
              [-75.842236328125, 68.840185546875],
              [-76.23471679687499, 68.72802734375],
              [-76.40341796874999, 68.692333984375],
              [-76.58505859374999, 68.69873046875],
              [-76.61943359374999, 68.72138671875],
              [-76.61625976562499, 68.75986328125],
              [-76.603662109375, 68.791552734375],
              [-76.58173828125, 68.81630859375],
              [-76.57456054687499, 68.8466796875],
              [-76.58769531249999, 68.974462890625],
              [-76.55722656249999, 69.00947265625],
              [-76.49516601562499, 69.030419921875],
              [-76.38090820312499, 69.05244140625],
              [-76.08920898437499, 69.026171875],
              [-75.95371093749999, 69.030810546875],
              [-75.85859375, 69.060302734375],
              [-75.76337890625, 69.1029296875],
              [-75.66796875, 69.158837890625],
              [-75.64775390624999, 69.212548828125],
              [-75.74907226562499, 69.299560546875],
              [-75.78715820312499, 69.31865234375],
              [-76.046484375, 69.386376953125],
              [-76.18979492187499, 69.410986328125],
              [-76.31621093749999, 69.421630859375],
              [-76.407958984375, 69.44111328125],
              [-76.46494140624999, 69.46943359375],
              [-76.52036132812499, 69.5166015625],
              [-76.52495117187499, 69.548681640625],
              [-76.51611328125, 69.59091796875],
              [-76.46328125, 69.619970703125],
              [-76.231103515625, 69.653466796875],
              [-76.23408203125, 69.662109375],
              [-76.423828125, 69.68681640625],
              [-76.51328125, 69.683935546875],
              [-76.5900390625, 69.65625],
              [-76.6865234375, 69.591259765625],
              [-76.742333984375, 69.572900390625],
              [-76.91557617187499, 69.611181640625],
              [-77.01962890624999, 69.616845703125],
              [-77.08994140624999, 69.635107421875],
              [-77.12880859375, 69.652734375],
              [-77.105078125, 69.670751953125],
              [-77.018701171875, 69.6890625],
              [-76.868603515625, 69.745166015625],
              [-76.85859375, 69.775390625],
              [-76.96225585937499, 69.824853515625],
              [-77.01596679687499, 69.8361328125],
              [-77.23247070312499, 69.85458984375],
              [-77.494287109375, 69.83623046875],
              [-77.59165039062499, 69.84560546875],
              [-77.63530273437499, 69.900439453125],
              [-77.66298828125, 69.96572265625],
              [-77.674755859375, 70.04150390625],
              [-77.721923828125, 70.17080078125],
              [-77.77402343749999, 70.238525390625],
              [-77.842529296875, 70.2470703125],
              [-78.15678710937499, 70.219140625],
              [-78.2314453125, 70.218798828125],
              [-78.2828125, 70.229150390625],
              [-78.49072265625, 70.315576171875],
              [-78.57480468749999, 70.34619140625],
              [-78.62143554687499, 70.35341796875],
              [-78.77265625, 70.4453125],
              [-78.83085937499999, 70.46318359375],
              [-78.89990234375, 70.508544921875],
              [-78.97978515624999, 70.58134765625],
              [-79.06640625, 70.603564453125],
              [-79.159765625, 70.575244140625],
              [-79.253173828125, 70.534716796875],
              [-79.346630859375, 70.481884765625],
              [-79.39731445312499, 70.437255859375],
              [-79.405224609375, 70.400732421875],
              [-79.347412109375, 70.372314453125],
              [-79.017529296875, 70.3251953125],
              [-78.933837890625, 70.293701171875],
              [-78.86284179687499, 70.24189453125],
              [-78.809814453125, 70.178564453125],
              [-78.774853515625, 70.10361328125],
              [-78.77783203125, 70.04765625],
              [-78.818798828125, 70.01044921875],
              [-78.8896484375, 69.977490234375],
              [-79.09287109374999, 69.925341796875],
              [-79.30332031249999, 69.89482421875],
              [-79.51542968749999, 69.88759765625],
              [-79.61591796875, 69.8947265625],
              [-80.162109375, 69.99599609375],
              [-80.260400390625, 69.99677734375],
              [-80.38681640624999, 70.01044921875],
              [-80.6703125, 70.052099609375],
              [-80.82578125, 70.056640625],
              [-81.098291015625, 70.091162109375],
              [-81.5595703125, 70.11123046875],
              [-81.651953125, 70.09462890625],
              [-81.529248046875, 70.048046875],
              [-81.42172851562499, 70.024609375],
              [-81.3294921875, 70.024365234375],
              [-81.19682617187499, 69.9828125],
              [-81.02373046874999, 69.9],
              [-80.9248046875, 69.8505859375],
              [-80.84287109374999, 69.791650390625],
              [-80.840283203125, 69.77138671875],
              [-80.92172851562499, 69.730908203125],
              [-81.564697265625, 69.942724609375],
              [-81.95771484375, 69.86875],
              [-82.13872070312499, 69.8412109375],
              [-82.29384765625, 69.8369140625],
              [-82.487744140625, 69.865966796875],
              [-82.925390625, 69.9681640625],
              [-83.09116210937499, 70.00390625],
              [-83.14995117187499, 70.00908203125],
              [-83.53076171875, 69.964794921875],
              [-83.85908203125, 69.962744140625],
              [-84.521875, 70.005224609375],
              [-84.76513671875, 70.033642578125],
              [-84.82919921874999, 70.063330078125],
              [-84.90908203125, 70.07822265625],
              [-85.05263671875, 70.07822265625],
              [-85.432373046875, 70.111376953125],
              [-85.780029296875, 70.036669921875],
              [-86.198193359375, 70.105126953125],
              [-86.322021484375, 70.14541015625],
              [-86.36142578124999, 70.173046875],
              [-86.48310546875, 70.28857421875],
              [-86.49980468749999, 70.350390625],
              [-86.46538085937499, 70.40625],
              [-86.43100585937499, 70.44453125],
              [-86.396875, 70.46533203125],
              [-86.62431640624999, 70.40126953125],
              [-86.70415039062499, 70.39072265625],
              [-86.80927734375, 70.38828125],
              [-87.1224609375, 70.411962890625],
              [-87.17197265624999, 70.399853515625],
              [-87.15581054687499, 70.37744140625],
              [-87.07402343749999, 70.34482421875],
              [-87.06328124999999, 70.32509765625],
              [-87.237890625, 70.309716796875],
              [-87.50244140625, 70.32568359375],
              [-87.61777343749999, 70.31875],
              [-87.67021484374999, 70.309814453125],
              [-87.789453125, 70.258251953125],
              [-87.838134765625, 70.24658203125],
              [-87.90068359374999, 70.251904296875],
              [-88.17832031249999, 70.368603515625],
              [-88.402099609375, 70.44248046875],
              [-88.66298828125, 70.470849609375],
              [-88.78271484375, 70.494482421875],
              [-88.84843749999999, 70.522900390625],
              [-89.20830078124999, 70.759716796875],
              [-89.25751953125, 70.810693359375],
              [-89.371533203125, 70.996142578125],
              [-89.409765625, 71.035693359375],
              [-89.45590820312499, 71.06171875],
              [-89.36552734374999, 71.0671875],
              [-89.025146484375, 71.04462890625],
              [-88.69565429687499, 71.04560546875],
              [-88.51665039062499, 71.03056640625],
              [-88.30908203125, 70.984326171875],
              [-88.03857421875, 70.951318359375],
              [-87.844921875, 70.944384765625],
              [-87.534423828125, 70.956591796875],
              [-87.181591796875, 70.987548828125],
              [-87.140087890625, 71.01162109375],
              [-87.368603515625, 71.05283203125],
              [-87.57231445312499, 71.107568359375],
              [-87.76025390625, 71.178515625],
              [-87.8724609375, 71.208544921875],
              [-88.06064453124999, 71.22724609375],
              [-88.589501953125, 71.240283203125],
              [-89.079345703125, 71.287939453125],
              [-89.41767578125, 71.352197265625],
              [-89.693310546875, 71.423486328125],
              [-89.80537109375, 71.4623046875],
              [-89.84575195312499, 71.49228515625],
              [-89.88852539062499, 71.5857421875],
              [-89.93369140624999, 71.742724609375],
              [-89.97734374999999, 71.848046875],
              [-90.01953125, 71.901806640625],
              [-90.02519531249999, 71.948779296875],
              [-89.93149414062499, 72.0490234375],
              [-89.663818359375, 72.157958984375],
              [-89.65727539062499, 72.175048828125],
              [-89.710546875, 72.180126953125],
              [-89.822900390625, 72.2078125],
              [-89.85869140624999, 72.24833984375],
              [-89.87309570312499, 72.312646484375],
              [-89.8740234375, 72.3671875],
              [-89.8615234375, 72.4119140625],
              [-89.816845703125, 72.467724609375],
              [-89.70151367187499, 72.56806640625],
              [-89.53642578124999, 72.68984375],
              [-89.35771484374999, 72.804150390625],
              [-89.327099609375, 72.841552734375],
              [-89.31137695312499, 72.94296875],
              [-89.28769531249999, 73.016943359375],
              [-89.26323242187499, 73.068994140625],
              [-89.225341796875, 73.108056640625],
              [-89.11474609375, 73.182177734375],
              [-88.976806640625, 73.252490234375],
              [-88.7609375, 73.31240234375],
              [-88.74252929687499, 73.3345703125],
              [-88.73959960937499, 73.365283203125],
              [-88.72714843749999, 73.38818359375],
              [-88.70517578124999, 73.403271484375],
              [-88.17001953124999, 73.5953125],
              [-87.926416015625, 73.67333984375],
              [-87.71977539062499, 73.722900390625],
              [-87.47236328125, 73.759423828125],
              [-86.76875, 73.833984375],
              [-86.406396484375, 73.85478515625],
              [-85.95078125, 73.850146484375],
              [-85.110498046875, 73.808154296875],
              [-85.00932617187499, 73.77861328125],
              [-84.98359375, 73.763720703125],
              [-84.94677734375, 73.721630859375],
              [-84.97451171875, 73.694775390625],
              [-85.204296875, 73.603564453125],
              [-85.493603515625, 73.527685546875],
              [-85.681884765625, 73.461474609375],
              [-86.00053710937499, 73.312548828125],
              [-86.08647460937499, 73.26025390625],
              [-86.481396484375, 72.96025390625],
              [-86.574658203125, 72.910546875],
              [-86.629345703125, 72.87080078125],
              [-86.66777343749999, 72.762548828125],
              [-86.65629882812499, 72.7240234375],
              [-86.59462890625, 72.6611328125],
              [-86.380322265625, 72.524658203125],
              [-86.32255859374999, 72.46083984375],
              [-86.32402343749999, 72.4021484375],
              [-86.348046875, 72.262255859375],
              [-86.3509765625, 72.19130859375],
              [-86.34135742187499, 72.123193359375],
              [-86.29716796874999, 72.02578125],
              [-86.21845703125, 71.89912109375],
              [-86.0361328125, 71.770947265625],
              [-85.75009765624999, 71.641357421875],
              [-85.53715820312499, 71.555419921875],
              [-85.32719726562499, 71.492138671875],
              [-85.07871093749999, 71.398486328125],
              [-85.023388671875, 71.35322265625],
              [-85.13759765625, 71.30341796875],
              [-85.40537109374999, 71.2267578125],
              [-85.757275390625, 71.1939453125],
              [-85.94541015624999, 71.162646484375],
              [-86.179443359375, 71.0958984375],
              [-86.47324218749999, 71.042626953125],
              [-86.58935546875, 71.010791015625],
            ],
          ],
          [
            [
              [-61.80112304687499, 49.093896484374994],
              [-62.21953124999999, 49.0791015625],
              [-62.552636718749994, 49.140869140625],
              [-62.79960937499999, 49.170703125],
              [-63.04150390625, 49.224951171875],
              [-63.565869140625, 49.399316406249994],
              [-63.625878906249994, 49.459912109375],
              [-63.676220703125, 49.534326171874994],
              [-63.776611328125, 49.602001953125],
              [-63.884912109374994, 49.65771484375],
              [-64.44003906249999, 49.827734375],
              [-64.48520507812499, 49.886962890625],
              [-64.37294921875, 49.925927734374994],
              [-64.24375, 49.944384765624996],
              [-64.13144531249999, 49.941650390625],
              [-63.76015625, 49.875244140625],
              [-63.2919921875, 49.816845703125],
              [-63.088818359375, 49.772705078125],
              [-62.858544921874994, 49.70546875],
              [-62.633447265624994, 49.623925781249994],
              [-62.1330078125, 49.407080078125],
              [-62.04306640624999, 49.389794921874994],
              [-61.817138671875, 49.283544921875],
              [-61.73583984375, 49.203759765624994],
              [-61.69614257812499, 49.139013671875],
              [-61.745507812499994, 49.105761718749996],
              [-61.80112304687499, 49.093896484374994],
            ],
          ],
          [
            [
              [-63.811279296875, 46.468701171875],
              [-63.78422851562499, 46.454638671874996],
              [-63.73701171875, 46.480517578124996],
              [-63.68144531249999, 46.561914062499994],
              [-63.534375, 46.540625],
              [-63.456494140625, 46.50390625],
              [-63.41313476562499, 46.512011718749996],
              [-63.36865234375, 46.508251953125],
              [-63.286083984375, 46.460205078125],
              [-63.12939453125, 46.422216796875],
              [-62.964013671874994, 46.427734375],
              [-62.71201171874999, 46.45029296875],
              [-62.68193359374999, 46.459423828125],
              [-62.423095703125, 46.478271484375],
              [-62.16357421875, 46.487207031249994],
              [-62.07426757812499, 46.465722656249994],
              [-62.04086914062499, 46.445703125],
              [-62.02373046874999, 46.42158203125],
              [-62.171777343749994, 46.355371093749994],
              [-62.319970703124994, 46.2783203125],
              [-62.52607421875, 46.202880859375],
              [-62.552001953125, 46.165917968749994],
              [-62.539208984374994, 46.097949218749996],
              [-62.54326171874999, 46.028662109375],
              [-62.502587890624994, 46.02294921875],
              [-62.47807617187499, 45.99970703125],
              [-62.53134765624999, 45.977294921875],
              [-62.7431640625, 45.96689453125],
              [-62.8048828125, 45.973193359374996],
              [-62.878369140625, 46.001367187499994],
              [-62.903515625, 46.068261718749994],
              [-62.99462890625, 46.058447265625],
              [-63.02207031249999, 46.0666015625],
              [-62.89453125, 46.123583984374996],
              [-62.95263671875, 46.195166015625],
              [-63.015039062499994, 46.18994140625],
              [-63.05634765625, 46.223925781249996],
              [-63.0529296875, 46.269824218749996],
              [-62.9951171875, 46.292138671874994],
              [-62.978466796875, 46.316357421875],
              [-63.056884765625, 46.295361328125],
              [-63.11699218749999, 46.252832031249994],
              [-63.194726562499994, 46.23671875],
              [-63.27080078124999, 46.2],
              [-63.152783203125, 46.188330078125],
              [-63.21347656249999, 46.15986328125],
              [-63.276611328125, 46.153271484375],
              [-63.56889648437499, 46.209228515625],
              [-63.641015625, 46.23046875],
              [-63.73178710937499, 46.2890625],
              [-63.800537109375, 46.367333984374994],
              [-63.76323242187499, 46.370361328125],
              [-63.75053710937499, 46.384375],
              [-63.75864257812499, 46.397607421874994],
              [-63.860546875, 46.408154296875],
              [-64.0197265625, 46.404833984374996],
              [-64.11083984375, 46.425439453124994],
              [-64.10654296874999, 46.562109375],
              [-64.13603515624999, 46.59970703125],
              [-64.23564453124999, 46.6314453125],
              [-64.38803710937499, 46.640869140625],
              [-64.403125, 46.6916015625],
              [-64.35458984374999, 46.76923828125],
              [-64.27998046875, 46.835742187499996],
              [-64.22324218749999, 46.901269531249994],
              [-64.15693359375, 46.954882812499996],
              [-63.993554687499994, 47.061572265624996],
              [-63.997265625, 46.981738281249996],
              [-63.98149414062499, 46.912988281249994],
              [-64.087890625, 46.775439453124996],
              [-63.90302734375, 46.639111328125],
              [-63.879296875, 46.608984375],
              [-63.863720703125, 46.57236328125],
              [-63.875634765624994, 46.538671875],
              [-63.90556640624999, 46.5087890625],
              [-63.83359375, 46.493896484375],
              [-63.811279296875, 46.468701171875],
            ],
          ],
          [
            [
              [-82.00048828125, 62.95419921875],
              [-81.960546875, 62.926220703125],
              [-81.94858398437499, 62.884033203125],
              [-81.96440429687499, 62.82763671875],
              [-81.990185546875, 62.776318359375],
              [-82.025830078125, 62.730078125],
              [-82.113720703125, 62.652246093749994],
              [-82.38803710937499, 62.519140625],
              [-82.490966796875, 62.44658203125],
              [-82.56826171875, 62.403222656249994],
              [-83.0158203125, 62.209912109375],
              [-83.07138671874999, 62.200390625],
              [-83.12968749999999, 62.2041015625],
              [-83.252392578125, 62.232958984375],
              [-83.37680664062499, 62.238134765625],
              [-83.698876953125, 62.16025390625],
              [-83.71440429687499, 62.173583984375],
              [-83.72861328124999, 62.257177734375],
              [-83.7609375, 62.303515625],
              [-83.903125, 62.402490234374994],
              [-83.91240234374999, 62.425537109375],
              [-83.91049804687499, 62.454150390625],
              [-83.89926757812499, 62.476464843749994],
              [-83.73906249999999, 62.56884765625],
              [-83.376416015625, 62.904931640624994],
              [-83.289453125, 62.92158203125],
              [-83.1109375, 62.884130859375],
              [-83.02626953125, 62.8720703125],
              [-82.965771484375, 62.873925781249994],
              [-82.7064453125, 62.94453125],
              [-82.459716796875, 62.936181640624994],
              [-82.234765625, 62.977441406249994],
              [-82.12924804687499, 62.977685546874994],
              [-82.047607421875, 62.970556640625],
              [-82.00048828125, 62.95419921875],
            ],
          ],
          [
            [
              [-79.5453125, 62.41171875],
              [-79.4662109375, 62.384521484375],
              [-79.33603515624999, 62.293701171875],
              [-79.28647460937499, 62.24765625],
              [-79.27202148437499, 62.185986328125],
              [-79.30644531249999, 62.103515625],
              [-79.32392578125, 62.02607421875],
              [-79.372265625, 61.9677734375],
              [-79.462158203125, 61.894091796875],
              [-79.54184570312499, 61.808007812499994],
              [-79.611328125, 61.709619140624994],
              [-79.66875, 61.644433593749994],
              [-79.7142578125, 61.612548828125],
              [-79.763330078125, 61.595947265625],
              [-79.81611328125, 61.594628906249994],
              [-79.89633789062499, 61.630126953125],
              [-80.004150390625, 61.702539062499994],
              [-80.0919921875, 61.746826171875],
              [-80.20493164062499, 61.777246093749994],
              [-80.26518554687499, 61.818212890625],
              [-80.27617187499999, 61.85859375],
              [-80.27983398437499, 61.989501953125],
              [-80.27509765625, 62.054638671875],
              [-80.26005859374999, 62.109033203124994],
              [-80.23466796874999, 62.152685546875],
              [-80.17856445312499, 62.212792968749994],
              [-80.02158203124999, 62.34296875],
              [-79.9267578125, 62.39287109375],
              [-79.86806640625, 62.404345703125],
              [-79.712548828125, 62.39501953125],
              [-79.649560546875, 62.398291015625],
              [-79.59765625, 62.413232421874994],
              [-79.5453125, 62.41171875],
            ],
          ],
          [
            [
              [-75.67587890624999, 68.322509765625],
              [-75.15380859375, 68.234033203125],
              [-75.103125, 68.201904296875],
              [-75.078125, 68.17314453125],
              [-75.0634765625, 68.1412109375],
              [-75.06235351562499, 68.075390625],
              [-75.07285156249999, 68.0490234375],
              [-75.12387695312499, 67.98525390625],
              [-75.12734375, 67.965234375],
              [-75.086376953125, 67.751416015625],
              [-75.09052734375, 67.634765625],
              [-75.12729492187499, 67.5373046875],
              [-75.20195312499999, 67.4591796875],
              [-75.31450195312499, 67.400439453125],
              [-75.40009765625, 67.36669921875],
              [-75.78007812499999, 67.283544921875],
              [-76.048974609375, 67.26201171875],
              [-76.332763671875, 67.25810546875],
              [-76.69394531249999, 67.23583984375],
              [-76.858837890625, 67.240478515625],
              [-76.94418945312499, 67.25029296875],
              [-77.0048828125, 67.266943359375],
              [-77.075927734375, 67.31962890625],
              [-77.15708007812499, 67.408349609375],
              [-77.22421875, 67.508203125],
              [-77.30439453125, 67.685107421875],
              [-77.305908203125, 67.706103515625],
              [-77.22856445312499, 67.85009765625],
              [-77.12587890625, 67.9470703125],
              [-76.9447265625, 68.090966796875],
              [-76.740234375, 68.23125],
              [-76.688232421875, 68.25439453125],
              [-76.59580078124999, 68.278955078125],
              [-76.364453125, 68.318701171875],
              [-76.172802734375, 68.3087890625],
              [-76.08828125, 68.313818359375],
              [-75.98276367187499, 68.33232421875],
              [-75.86650390624999, 68.33681640625],
              [-75.67587890624999, 68.322509765625],
            ],
          ],
          [
            [
              [-79.5373046875, 73.6544921875],
              [-79.366796875, 73.641357421875],
              [-78.2865234375, 73.6658203125],
              [-78.062939453125, 73.64765625],
              [-77.38212890624999, 73.536669921875],
              [-77.20654296875, 73.499560546875],
              [-77.119775390625, 73.45048828125],
              [-77.04150390625, 73.373046875],
              [-77.005322265625, 73.3560546875],
              [-76.75869140625, 73.310009765625],
              [-76.65727539062499, 73.25419921875],
              [-76.62158203125, 73.225341796875],
              [-76.569775390625, 73.15927734375],
              [-76.458447265625, 73.121826171875],
              [-76.33115234374999, 73.10048828125],
              [-76.28955078125, 73.081005859375],
              [-76.3095703125, 72.997900390625],
              [-76.2552734375, 72.959228515625],
              [-76.13505859374999, 72.91240234375],
              [-76.089990234375, 72.881201171875],
              [-76.18339843749999, 72.84306640625],
              [-76.400537109375, 72.820654296875],
              [-77.01357421875, 72.843994140625],
              [-77.8359375, 72.896826171875],
              [-78.314208984375, 72.8818359375],
              [-78.55405273437499, 72.85771484375],
              [-79.1341796875, 72.771630859375],
              [-79.3193359375, 72.75771484375],
              [-79.50053710937499, 72.75595703125],
              [-79.820703125, 72.826318359375],
              [-79.93686523437499, 72.863623046875],
              [-79.97529296875, 72.89248046875],
              [-80.05161132812499, 72.977001953125],
              [-80.114453125, 73.07822265625],
              [-80.146435546875, 73.161328125],
              [-80.18330078125, 73.224658203125],
              [-80.292724609375, 73.24560546875],
              [-80.617919921875, 73.27080078125],
              [-80.72685546874999, 73.30546875],
              [-80.77641601562499, 73.3341796875],
              [-80.824169921875, 73.3806640625],
              [-80.82294921875, 73.428955078125],
              [-80.797998046875, 73.471533203125],
              [-80.776953125, 73.481982421875],
              [-80.73583984375, 73.48310546875],
              [-80.82700195312499, 73.53466796875],
              [-80.85849609374999, 73.59140625],
              [-80.8607421875, 73.670556640625],
              [-80.848876953125, 73.721240234375],
              [-80.82285156249999, 73.74345703125],
              [-80.76274414062499, 73.757763671875],
              [-80.62138671874999, 73.767333984375],
              [-80.4123046875, 73.7654296875],
              [-80.120263671875, 73.707080078125],
              [-79.88935546875, 73.701513671875],
              [-79.5373046875, 73.6544921875],
            ],
          ],
          [
            [
              [-80.731689453125, 52.747265625],
              [-80.80234375, 52.733984375],
              [-81.00986328124999, 52.76064453125],
              [-81.09658203125, 52.7798828125],
              [-81.35224609375, 52.852001953125],
              [-81.8390625, 52.95791015625],
              [-82.005029296875, 53.010498046875],
              [-82.0392578125, 53.04990234375],
              [-81.951123046875, 53.1322265625],
              [-81.9013671875, 53.165576171874996],
              [-81.847314453125, 53.186279296875],
              [-81.3353515625, 53.224267578125],
              [-81.13559570312499, 53.205810546875],
              [-80.900390625, 53.037158203124996],
              [-80.76533203125, 52.9232421875],
              [-80.71044921875, 52.831591796874996],
              [-80.70952148437499, 52.787402343749996],
              [-80.731689453125, 52.747265625],
            ],
          ],
          [
            [
              [-78.82651367187499, 56.1453125],
              [-78.87729492187499, 56.1314453125],
              [-78.913818359375, 56.1328125],
              [-78.90703124999999, 56.166357421875],
              [-78.856884765625, 56.232080078125],
              [-78.82841796874999, 56.28984375],
              [-78.82158203124999, 56.339648437499996],
              [-78.79941406249999, 56.38330078125],
              [-78.761865234375, 56.420703125],
              [-78.72451171875, 56.439208984375],
              [-78.66875, 56.438623046875],
              [-78.657177734375, 56.3173828125],
              [-78.672802734375, 56.260498046875],
              [-78.71015625, 56.212890625],
              [-78.761376953125, 56.17451171875],
              [-78.82651367187499, 56.1453125],
            ],
          ],
          [
            [
              [-78.93559570312499, 56.266064453125],
              [-79.01796875, 56.164990234375],
              [-79.08388671875, 56.06787109375],
              [-79.17548828125, 55.885058593749996],
              [-79.22783203124999, 55.878515625],
              [-79.2736328125, 55.9224609375],
              [-79.14228515625, 56.13642578125],
              [-79.13608398437499, 56.16025390625],
              [-79.14228515625, 56.180712890624996],
              [-79.18212890625, 56.212158203125],
              [-79.221826171875, 56.1759765625],
              [-79.407421875, 55.934863281249996],
              [-79.455322265625, 55.89619140625],
              [-79.4951171875, 55.874755859375],
              [-79.5267578125, 55.870654296874996],
              [-79.605712890625, 55.87568359375],
              [-79.76474609374999, 55.806787109375],
              [-79.4974609375, 56.0931640625],
              [-79.49467773437499, 56.114990234375],
              [-79.54472656249999, 56.128369140625],
              [-79.56455078124999, 56.120947265625],
              [-79.78110351562499, 55.940576171875],
              [-79.90458984374999, 55.871044921875],
              [-79.9875, 55.892138671874996],
              [-80.008251953125, 55.91103515625],
              [-80.00078124999999, 55.932080078125],
              [-79.7900390625, 56.11416015625],
              [-79.596337890625, 56.244482421875],
              [-79.515283203125, 56.326513671875],
              [-79.482373046875, 56.40380859375],
              [-79.467919921875, 56.4603515625],
              [-79.4689453125, 56.522607421875],
              [-79.45888671875, 56.53974609375],
              [-79.44765625, 56.536572265625],
              [-79.435302734375, 56.513037109375],
              [-79.43203125, 56.4474609375],
              [-79.47626953125, 56.312841796875],
              [-79.51181640624999, 56.24658203125],
              [-79.55419921875, 56.1919921875],
              [-79.536328125, 56.180078125],
              [-79.45830078124999, 56.211083984375],
              [-79.39262695312499, 56.27646484375],
              [-79.33935546875, 56.376318359375],
              [-79.305322265625, 56.4630859375],
              [-79.272412109375, 56.600439453125],
              [-79.2611328125, 56.595654296875],
              [-79.245751953125, 56.56826171875],
              [-79.21044921875, 56.54892578125],
              [-79.15517578125, 56.53759765625],
              [-79.12353515625, 56.519970703125],
              [-79.100244140625, 56.473925781249996],
              [-79.077734375, 56.45361328125],
              [-78.99497070312499, 56.43642578125],
              [-78.96318359374999, 56.421728515625],
              [-78.94033203125, 56.371435546875],
              [-78.94243164062499, 56.344921875],
              [-78.93120117187499, 56.327929687499996],
              [-78.906640625, 56.32041015625],
              [-78.93559570312499, 56.266064453125],
            ],
          ],
          [
            [
              [-79.97758789062499, 56.20703125],
              [-80.02861328124999, 56.1994140625],
              [-80.0888671875, 56.2138671875],
              [-80.05747070312499, 56.287353515625],
              [-80.005078125, 56.317919921874996],
              [-79.874462890625, 56.3484375],
              [-79.85214843749999, 56.3671875],
              [-79.81040039062499, 56.376513671874996],
              [-79.749169921875, 56.376513671874996],
              [-79.68100585937499, 56.403955078125],
              [-79.605859375, 56.458837890625],
              [-79.579736328125, 56.466357421874996],
              [-79.632568359375, 56.386523437499996],
              [-79.687939453125, 56.326806640625],
              [-79.97758789062499, 56.20703125],
            ],
          ],
          [
            [
              [-89.833251953125, 77.267626953125],
              [-90.0947265625, 77.210400390625],
              [-90.228271484375, 77.212451171875],
              [-90.99321289062499, 77.3294921875],
              [-91.14726562499999, 77.3873046875],
              [-91.17661132812499, 77.42626953125],
              [-91.18505859375, 77.48154296875],
              [-91.18266601562499, 77.557177734375],
              [-91.14946289062499, 77.608056640625],
              [-91.109130859375, 77.625732421875],
              [-91.01904296875, 77.643896484375],
              [-90.84257812499999, 77.65498046875],
              [-90.67485351562499, 77.6486328125],
              [-90.42275390625, 77.628369140625],
              [-90.17192382812499, 77.594677734375],
              [-89.83896484374999, 77.49140625],
              [-89.719482421875, 77.442138671875],
              [-89.69418945312499, 77.378125],
              [-89.694580078125, 77.33896484375],
              [-89.71201171874999, 77.310400390625],
              [-89.74667968749999, 77.292578125],
              [-89.833251953125, 77.267626953125],
            ],
          ],
          [
            [
              [-104.558154296875, 77.141748046875],
              [-104.711376953125, 77.123974609375],
              [-105.015576171875, 77.164599609375],
              [-105.215087890625, 77.182080078125],
              [-105.379931640625, 77.254248046875],
              [-105.55634765625, 77.35263671875],
              [-105.6951171875, 77.461376953125],
              [-105.747216796875, 77.525390625],
              [-105.84814453125, 77.563427734375],
              [-105.88315429687499, 77.626513671875],
              [-106.06611328125, 77.725390625],
              [-106.035595703125, 77.73984375],
              [-105.86298828125, 77.75439453125],
              [-105.587890625, 77.735986328125],
              [-105.456103515625, 77.700927734375],
              [-105.28964843749999, 77.64208984375],
              [-105.073876953125, 77.548291015625],
              [-105.0072265625, 77.50673828125],
              [-104.994287109375, 77.449658203125],
              [-104.955322265625, 77.418701171875],
              [-104.77021484375, 77.413232421875],
              [-104.542236328125, 77.337744140625],
              [-104.50078125, 77.308544921875],
              [-104.45371093749999, 77.24912109375],
              [-104.456982421875, 77.22080078125],
              [-104.493359375, 77.162353515625],
              [-104.558154296875, 77.141748046875],
            ],
          ],
          [
            [
              [-98.79160156249999, 79.981103515625],
              [-98.7689453125, 79.85087890625],
              [-98.789794921875, 79.785400390625],
              [-98.840625, 79.737060546875],
              [-98.885205078125, 79.72568359375],
              [-98.94521484375, 79.724072265625],
              [-99.21845703125, 79.761865234375],
              [-99.3017578125, 79.78408203125],
              [-99.30625, 79.802880859375],
              [-99.3330078125, 79.83955078125],
              [-99.515625, 79.887158203125],
              [-99.85747070312499, 79.8794921875],
              [-99.99990234375, 79.884033203125],
              [-100.0568359375, 79.8982421875],
              [-100.092431640625, 79.91865234375],
              [-100.12602539062499, 80.00126953125],
              [-100.120361328125, 80.030419921875],
              [-100.078515625, 80.081103515625],
              [-100.05327148437499, 80.093359375],
              [-99.80278320312499, 80.14013671875],
              [-99.731201171875, 80.144091796875],
              [-99.42485351562499, 80.126416015625],
              [-99.15322265625, 80.12421875],
              [-99.0166015625, 80.1111328125],
              [-98.894677734375, 80.081787109375],
              [-98.823193359375, 80.037353515625],
              [-98.79160156249999, 79.981103515625],
            ],
          ],
          [
            [
              [-105.28891601562499, 72.919921875],
              [-105.33935546875, 72.914892578125],
              [-105.43408203125, 72.93798828125],
              [-105.572998046875, 72.989306640625],
              [-105.80014648437499, 73.093310546875],
              [-106.071044921875, 73.19638671875],
              [-106.11264648437499, 73.25810546875],
              [-106.18002929687499, 73.3041015625],
              [-106.52573242187499, 73.41337890625],
              [-106.750390625, 73.45771484375],
              [-106.921533203125, 73.479833984375],
              [-106.949658203125, 73.5103515625],
              [-106.831005859375, 73.599072265625],
              [-106.69482421875, 73.669921875],
              [-106.61396484375, 73.69560546875],
              [-106.362109375, 73.718603515625],
              [-105.51230468749999, 73.765771484375],
              [-105.31796875, 73.767138671875],
              [-105.114453125, 73.74443359375],
              [-104.83466796875, 73.647265625],
              [-104.71826171875, 73.636279296875],
              [-104.64877929687499, 73.614404296875],
              [-104.5875, 73.578076171875],
              [-104.555078125, 73.54111328125],
              [-104.55234375, 73.465576171875],
              [-104.58286132812499, 73.35390625],
              [-104.621728515625, 73.3111328125],
              [-104.791015625, 73.167626953125],
              [-104.96865234375, 73.088671875],
              [-105.002587890625, 73.037548828125],
              [-105.074609375, 72.997021484375],
              [-105.200634765625, 72.947314453125],
              [-105.28891601562499, 72.919921875],
            ],
          ],
          [
            [
              [-102.22734374999999, 76.014892578125],
              [-102.01787109374999, 75.953515625],
              [-102.0080078125, 75.939404296875],
              [-102.04746093749999, 75.927734375],
              [-102.318115234375, 75.895166015625],
              [-102.4234375, 75.869189453125],
              [-102.511376953125, 75.8083984375],
              [-102.57958984375, 75.780224609375],
              [-102.9435546875, 75.763427734375],
              [-103.31474609375, 75.764208984375],
              [-103.24472656249999, 75.82294921875],
              [-103.04150390625, 75.91884765625],
              [-103.2015625, 75.95849609375],
              [-103.769775390625, 75.8923828125],
              [-103.98525390625, 75.93310546875],
              [-103.80078125, 76.03701171875],
              [-103.984521484375, 76.046533203125],
              [-104.24248046875, 76.04697265625],
              [-104.4060546875, 76.10849609375],
              [-104.350634765625, 76.18232421875],
              [-104.01206054687499, 76.222998046875],
              [-103.571435546875, 76.258203125],
              [-103.09824218749999, 76.311474609375],
              [-102.72802734375, 76.30703125],
              [-102.58408203124999, 76.281640625],
              [-102.5361328125, 76.196435546875],
              [-102.49003906249999, 76.095068359375],
              [-102.42568359375, 76.08642578125],
              [-102.22734374999999, 76.014892578125],
            ],
          ],
          [
            [
              [-104.0228515625, 76.58310546875],
              [-103.97348632812499, 76.577587890625],
              [-103.82109375, 76.597509765625],
              [-103.72275390624999, 76.60107421875],
              [-103.61313476562499, 76.563427734375],
              [-103.584619140625, 76.5388671875],
              [-103.19013671875, 76.47744140625],
              [-103.05131835937499, 76.449853515625],
              [-103.03354492187499, 76.431494140625],
              [-103.082958984375, 76.40517578125],
              [-103.19951171874999, 76.370849609375],
              [-103.31137695312499, 76.34755859375],
              [-103.472216796875, 76.329052734375],
              [-104.270654296875, 76.32626953125],
              [-104.35751953124999, 76.334619140625],
              [-104.407666015625, 76.36513671875],
              [-104.50644531249999, 76.478955078125],
              [-104.576611328125, 76.540185546875],
              [-104.60302734375, 76.58271484375],
              [-104.585693359375, 76.606494140625],
              [-104.500390625, 76.63037109375],
              [-104.205126953125, 76.66611328125],
              [-104.07451171875, 76.66611328125],
              [-103.99248046875, 76.656982421875],
              [-103.95908203124999, 76.63876953125],
              [-103.969189453125, 76.61416015625],
              [-104.0228515625, 76.58310546875],
            ],
          ],
          [
            [
              [-118.328125, 75.5796875],
              [-118.6138671875, 75.5154296875],
              [-118.817138671875, 75.522119140625],
              [-119.086669921875, 75.5693359375],
              [-119.3060546875, 75.5853515625],
              [-119.383251953125, 75.601025390625],
              [-119.39458007812499, 75.617333984375],
              [-119.320166015625, 75.662548828125],
              [-119.226806640625, 75.6986328125],
              [-119.00346679687499, 75.769580078125],
              [-118.62607421875, 75.90625],
              [-118.37900390625, 75.957958984375],
              [-118.136669921875, 75.994482421875],
              [-117.88935546875, 76.07607421875],
              [-117.75249023437499, 76.112451171875],
              [-117.63369140625, 76.115087890625],
              [-117.51259765625, 76.0994140625],
              [-117.49912109375, 76.077197265625],
              [-117.6263671875, 75.965966796875],
              [-117.715966796875, 75.921142578125],
              [-117.8908203125, 75.80546875],
              [-118.226513671875, 75.611181640625],
              [-118.328125, 75.5796875],
            ],
          ],
          [
            [
              [-113.832470703125, 77.754638671875],
              [-114.105908203125, 77.720703125],
              [-114.28720703124999, 77.721484375],
              [-114.608349609375, 77.7693359375],
              [-114.980419921875, 77.9154296875],
              [-115.029345703125, 77.967529296875],
              [-114.8904296875, 77.976904296875],
              [-114.789501953125, 77.992919921875],
              [-114.72646484375, 78.01552734375],
              [-114.606884765625, 78.04033203125],
              [-114.33037109375, 78.0775390625],
              [-114.296875, 78.06318359375],
              [-114.302880859375, 78.03271484375],
              [-114.279833984375, 78.004296875],
              [-114.18095703125, 77.9982421875],
              [-114.08720703125, 77.9779296875],
              [-113.89775390625, 77.915576171875],
              [-113.768017578125, 77.903564453125],
              [-113.72138671875, 77.889892578125],
              [-113.6966796875, 77.8689453125],
              [-113.617919921875, 77.832421875],
              [-113.619384765625, 77.8134765625],
              [-113.725830078125, 77.77578125],
              [-113.832470703125, 77.754638671875],
            ],
          ],
          [
            [
              [-130.9271484375, 54.479052734374996],
              [-130.95029296875, 54.477783203125],
              [-130.959033203125, 54.498681640625],
              [-130.953466796875, 54.541845703125],
              [-130.92177734375, 54.614892578125],
              [-130.9068359375, 54.631787109375],
              [-130.77705078125, 54.618896484375],
              [-130.7580078125, 54.61376953125],
              [-130.75341796875, 54.59970703125],
              [-130.76337890625, 54.576708984374996],
              [-130.805126953125, 54.543798828125],
              [-130.9271484375, 54.479052734374996],
            ],
          ],
          [
            [
              [-131.029296875, 51.96162109375],
              [-131.047265625, 51.959716796875],
              [-131.080517578125, 51.980419921875],
              [-131.10341796875, 52.0138671875],
              [-131.117333984375, 52.101025390625],
              [-131.10712890625, 52.136572265625],
              [-131.098095703125, 52.150634765625],
              [-131.01064453125, 52.095263671874996],
              [-131.029296875, 51.96162109375],
            ],
          ],
          [
            [
              [-130.236279296875, 53.958544921874996],
              [-130.267236328125, 53.922607421875],
              [-130.337548828125, 53.866259765624996],
              [-130.384228515625, 53.8439453125],
              [-130.4072265625, 53.855517578124996],
              [-130.470263671875, 53.861767578125],
              [-130.5375, 53.91787109375],
              [-130.58984375, 53.940283203125],
              [-130.624609375, 53.94140625],
              [-130.641845703125, 53.921142578125],
              [-130.6462890625, 53.89404296875],
              [-130.637890625, 53.860009765625],
              [-130.643701171875, 53.84453125],
              [-130.66357421875, 53.84755859375],
              [-130.683447265625, 53.8634765625],
              [-130.703173828125, 53.892236328125],
              [-130.707275390625, 53.921484375],
              [-130.695703125, 53.95126953125],
              [-130.646923828125, 53.991259765624996],
              [-130.49462890625, 54.074169921875],
              [-130.447998046875, 54.089013671875],
              [-130.397314453125, 54.085693359375],
              [-130.315869140625, 54.046923828124996],
              [-130.298486328125, 54.03564453125],
              [-130.236279296875, 53.958544921874996],
            ],
          ],
          [
            [
              [-129.848583984375, 53.167919921875],
              [-129.8685546875, 53.164501953125],
              [-129.934375, 53.17666015625],
              [-130.151416015625, 53.345703125],
              [-130.3056640625, 53.407373046875],
              [-130.4107421875, 53.4908203125],
              [-130.517578125, 53.544238281249996],
              [-130.452001953125, 53.631152343749996],
              [-130.39482421875, 53.620410156249996],
              [-130.19501953125, 53.549658203125],
              [-130.035400390625, 53.481103515625],
              [-129.9447265625, 53.436376953125],
              [-129.754833984375, 53.244775390625],
              [-129.7689453125, 53.21728515625],
              [-129.848583984375, 53.167919921875],
            ],
          ],
          [
            [
              [-130.575341796875, 54.769677734375],
              [-130.49326171875, 54.8341796875],
              [-130.312548828125, 54.945947265625],
              [-130.2140625, 55.02587890625],
              [-130.20390625, 54.947021484375],
              [-130.3494140625, 54.81455078125],
              [-130.535498046875, 54.74873046875],
              [-130.575341796875, 54.769677734375],
            ],
          ],
          [
            [
              [-128.55244140625, 52.939746093749996],
              [-128.50654296875, 52.620703125],
              [-128.509912109375, 52.518603515624996],
              [-128.576806640625, 52.451806640625],
              [-128.6240234375, 52.339892578124996],
              [-128.678955078125, 52.2896484375],
              [-128.730908203125, 52.35654296875],
              [-128.735546875, 52.467724609375],
              [-128.7494140625, 52.5560546875],
              [-128.766455078125, 52.598388671875],
              [-128.746337890625, 52.76337890625],
              [-128.76962890625, 52.751220703125],
              [-128.831201171875, 52.67880859375],
              [-128.8998046875, 52.673828125],
              [-129.0228515625, 52.75595703125],
              [-129.084716796875, 52.8224609375],
              [-129.094873046875, 52.891845703125],
              [-129.175927734375, 52.96494140625],
              [-129.184326171875, 52.990673828125],
              [-129.177685546875, 53.017919921875],
              [-129.111083984375, 53.090673828125],
              [-129.08408203125, 53.139697265624996],
              [-129.0603515625, 53.240625],
              [-129.033251953125, 53.279931640625],
              [-128.97021484375, 53.274365234375],
              [-128.85771484375, 53.228564453124996],
              [-128.740380859375, 53.178857421875],
              [-128.632666015625, 53.1125],
              [-128.55244140625, 52.939746093749996],
            ],
          ],
          [
            [
              [-126.09208984374999, 49.35400390625],
              [-126.06401367187499, 49.263623046875],
              [-126.18681640624999, 49.278125],
              [-126.229638671875, 49.295654296875],
              [-126.2314453125, 49.3390625],
              [-126.20854492187499, 49.379785156249994],
              [-126.11528320312499, 49.365039062499996],
              [-126.09208984374999, 49.35400390625],
            ],
          ],
          [
            [
              [-124.153662109375, 49.531152343749994],
              [-124.139794921875, 49.5103515625],
              [-124.3623046875, 49.588183593749996],
              [-124.4572265625, 49.634228515625],
              [-124.49394531249999, 49.66748046875],
              [-124.517822265625, 49.686328125],
              [-124.63095703125, 49.735693359375],
              [-124.649853515625, 49.758349609374996],
              [-124.623291015625, 49.77509765625],
              [-124.54716796874999, 49.764941406249996],
              [-124.421484375, 49.727783203125],
              [-124.30913085937499, 49.667285156249996],
              [-124.153662109375, 49.531152343749994],
            ],
          ],
          [
            [
              [-126.6412109375, 49.605810546875],
              [-126.680419921875, 49.601367187499996],
              [-126.743408203125, 49.6134765625],
              [-126.814208984375, 49.64208984375],
              [-126.93857421875, 49.71845703125],
              [-126.95126953125, 49.735693359375],
              [-126.9400390625, 49.75048828125],
              [-126.9048828125, 49.76279296875],
              [-126.896875, 49.78291015625],
              [-126.92583007812499, 49.837744140625],
              [-126.82607421875, 49.872363281249996],
              [-126.738134765625, 49.843652343749994],
              [-126.69814453125, 49.808496093749994],
              [-126.64990234375, 49.74580078125],
              [-126.628173828125, 49.675146484375],
              [-126.62578124999999, 49.626806640625],
              [-126.6412109375, 49.605810546875],
            ],
          ],
          [
            [
              [-125.18413085937499, 50.097119140625],
              [-125.19511718749999, 50.044335937499994],
              [-125.25957031249999, 50.130029296874994],
              [-125.35844726562499, 50.3115234375],
              [-125.3453125, 50.353955078125],
              [-125.301171875, 50.4140625],
              [-125.2609375, 50.417822265625],
              [-125.19599609375, 50.38974609375],
              [-125.139501953125, 50.339697265625],
              [-125.12646484375, 50.320263671875],
              [-125.09140625, 50.2677734375],
              [-125.07402343749999, 50.220654296875],
              [-125.11298828125, 50.163476562499994],
              [-125.18413085937499, 50.097119140625],
            ],
          ],
          [
            [
              [-128.936865234375, 52.510009765625],
              [-128.968701171875, 52.4642578125],
              [-129.10234375, 52.574365234375],
              [-129.151025390625, 52.605322265625],
              [-129.25048828125, 52.72216796875],
              [-129.2677734375, 52.77236328125],
              [-129.263525390625, 52.80078125],
              [-129.245947265625, 52.81123046875],
              [-129.2150390625, 52.803857421875],
              [-129.186181640625, 52.791259765625],
              [-128.993994140625, 52.66171875],
              [-128.94033203125, 52.600732421875],
              [-128.936865234375, 52.510009765625],
            ],
          ],
          [
            [
              [-127.924658203125, 51.473876953125],
              [-127.941259765625, 51.457177734374994],
              [-127.98125, 51.4572265625],
              [-128.04453125, 51.474023437499994],
              [-128.091796875, 51.511132812499994],
              [-128.148779296875, 51.626708984375],
              [-128.1423828125, 51.64658203125],
              [-128.12275390625, 51.666796875],
              [-128.03173828125, 51.7083984375],
              [-127.998681640625, 51.70380859375],
              [-127.98681640625, 51.673583984375],
              [-127.93251953125, 51.60546875],
              [-127.916357421875, 51.58544921875],
              [-127.91630859374999, 51.506201171875],
              [-127.924658203125, 51.473876953125],
            ],
          ],
          [
            [
              [-128.36875, 52.40087890625],
              [-128.44541015625, 52.3875],
              [-128.419873046875, 52.44111328125],
              [-128.4125, 52.4728515625],
              [-128.42626953125, 52.502734375],
              [-128.4359375, 52.560351562499996],
              [-128.439794921875, 52.69638671875],
              [-128.364892578125, 52.781884765625],
              [-128.247265625, 52.784375],
              [-128.2484375, 52.7412109375],
              [-128.29814453125, 52.5482421875],
              [-128.323779296875, 52.458984375],
              [-128.3435546875, 52.426074218749996],
              [-128.36875, 52.40087890625],
            ],
          ],
          [
            [
              [-129.313720703125, 52.9921875],
              [-129.3287109375, 52.984228515625],
              [-129.37001953125, 52.997607421874996],
              [-129.409716796875, 53.023730468749996],
              [-129.477783203125, 53.09775390625],
              [-129.500146484375, 53.12890625],
              [-129.51474609375, 53.17939453125],
              [-129.50107421875, 53.188330078125],
              [-129.471435546875, 53.1830078125],
              [-129.450732421875, 53.17470703125],
              [-129.343505859375, 53.052783203124996],
              [-129.313720703125, 52.9921875],
            ],
          ],
          [
            [
              [-129.167724609375, 53.11787109375],
              [-129.1732421875, 53.1107421875],
              [-129.27685546875, 53.1109375],
              [-129.305712890625, 53.121142578124996],
              [-129.323876953125, 53.142138671874996],
              [-129.33125, 53.173974609375],
              [-129.31435546875, 53.2123046875],
              [-129.253076171875, 53.285498046875],
              [-129.251171875, 53.316699218749996],
              [-129.23818359375, 53.330078125],
              [-129.19521484375, 53.293212890625],
              [-129.177001953125, 53.259130859375],
              [-129.167724609375, 53.11787109375],
            ],
          ],
          [
            [
              [-123.435400390625, 48.754443359374996],
              [-123.47724609375, 48.728759765625],
              [-123.499609375, 48.732177734375],
              [-123.517529296875, 48.750146484374994],
              [-123.58232421874999, 48.92578125],
              [-123.5546875, 48.9220703125],
              [-123.46787109375, 48.8673828125],
              [-123.487548828125, 48.845703125],
              [-123.42275390625, 48.793359375],
              [-123.40678710937499, 48.7560546875],
              [-123.435400390625, 48.754443359374996],
            ],
          ],
          [
            [
              [-59.78759765625, 43.939599609374994],
              [-59.922265625, 43.90390625],
              [-60.037744140624994, 43.906640625],
              [-60.1142578125, 43.939111328124994],
              [-60.11748046874999, 43.953369140625],
              [-59.93603515625, 43.939599609374994],
              [-59.866357421874994, 43.947167968749994],
              [-59.72714843749999, 44.002832031249994],
              [-59.78759765625, 43.939599609374994],
            ],
          ],
          [
            [
              [-60.961572265624994, 45.48994140625],
              [-61.002880859375, 45.481738281249996],
              [-61.0125, 45.496044921875],
              [-61.076171875, 45.5373046875],
              [-61.08173828125, 45.5578125],
              [-61.02597656249999, 45.57734375],
              [-60.91245117187499, 45.567285156249994],
              [-60.953027343749994, 45.515527343749994],
              [-60.961572265624994, 45.48994140625],
            ],
          ],
          [
            [
              [-61.91411132812499, 47.284521484375],
              [-61.87871093749999, 47.265527343749994],
              [-61.81547851562499, 47.267578125],
              [-61.77255859374999, 47.259814453124996],
              [-61.833740234375, 47.222607421875],
              [-61.950830078124994, 47.218994140625],
              [-62.00830078125, 47.234277343749994],
              [-61.924707031249994, 47.425146484375],
              [-61.827294921874994, 47.469091796875],
              [-61.627832031249994, 47.59384765625],
              [-61.548046875, 47.631787109375],
              [-61.474072265625, 47.646777343749996],
              [-61.3955078125, 47.637646484375],
              [-61.475537109375, 47.56396484375],
              [-61.5822265625, 47.560009765625],
              [-61.68408203125, 47.49873046875],
              [-61.750878906249994, 47.430810546874994],
              [-61.83125, 47.392041015625],
              [-61.886621093749994, 47.344628906249994],
              [-61.91411132812499, 47.284521484375],
            ],
          ],
          [
            [
              [-64.50859374999999, 47.88671875],
              [-64.53388671875, 47.813769531249996],
              [-64.6212890625, 47.751904296875],
              [-64.66464843749999, 47.747607421874996],
              [-64.6845703125, 47.75361328125],
              [-64.66049804687499, 47.7935546875],
              [-64.66328125, 47.863037109375],
              [-64.59111328124999, 47.872460937499994],
              [-64.56484375, 47.866259765624996],
              [-64.50859374999999, 47.88671875],
            ],
          ],
          [
            [
              [-64.47607421875, 47.958886718749994],
              [-64.59130859375, 47.9072265625],
              [-64.54072265625, 47.9849609375],
              [-64.51958007812499, 48.005078125],
              [-64.5001953125, 48.01376953125],
              [-64.48125, 48.00693359375],
              [-64.47607421875, 47.958886718749994],
            ],
          ],
          [
            [
              [-66.27377929687499, 44.292285156249996],
              [-66.32412109375, 44.25732421875],
              [-66.3119140625, 44.2916015625],
              [-66.25048828125, 44.37900390625],
              [-66.2103515625, 44.392041015625],
              [-66.27377929687499, 44.292285156249996],
            ],
          ],
          [
            [
              [-66.7625, 44.681787109374994],
              [-66.89707031249999, 44.62890625],
              [-66.8447265625, 44.763916015625],
              [-66.8021484375, 44.80537109375],
              [-66.74541015624999, 44.79140625],
              [-66.753369140625, 44.709814453125],
              [-66.7625, 44.681787109374994],
            ],
          ],
          [
            [
              [-73.56650390624999, 45.469091796875],
              [-73.6435546875, 45.449121093749994],
              [-73.775341796875, 45.467626953125],
              [-73.92021484374999, 45.441943359374996],
              [-73.960546875, 45.44140625],
              [-73.8529296875, 45.51572265625],
              [-73.687451171875, 45.561425781249994],
              [-73.5224609375, 45.701171875],
              [-73.47607421875, 45.704736328124994],
              [-73.53886718749999, 45.546435546874996],
              [-73.55166015625, 45.48984375],
              [-73.56650390624999, 45.469091796875],
            ],
          ],
          [
            [
              [-73.6953125, 45.585498046874996],
              [-73.81591796875, 45.564892578125],
              [-73.85771484374999, 45.573583984375],
              [-73.72465820312499, 45.671826171875],
              [-73.57236328124999, 45.694482421874994],
              [-73.6953125, 45.585498046874996],
            ],
          ],
          [
            [
              [-71.02573242187499, 46.872949218749994],
              [-71.116650390625, 46.86484375],
              [-71.094970703125, 46.899560546874994],
              [-70.970849609375, 46.96142578125],
              [-70.879638671875, 46.99609375],
              [-70.82578125, 46.995361328125],
              [-70.9134765625, 46.91953125],
              [-71.02573242187499, 46.872949218749994],
            ],
          ],
          [
            [
              [-55.5361328125, 50.719677734375],
              [-55.56967773437499, 50.70869140625],
              [-55.60078125, 50.709033203124996],
              [-55.629345703125, 50.72080078125],
              [-55.63388671874999, 50.740185546875],
              [-55.6044921875, 50.780712890625],
              [-55.52719726562499, 50.801220703125],
              [-55.46928710937499, 50.79638671875],
              [-55.47275390624999, 50.775927734374996],
              [-55.503808593749994, 50.742138671875],
              [-55.5361328125, 50.719677734375],
            ],
          ],
          [
            [
              [-54.55439453125, 49.5888671875],
              [-54.70869140625, 49.530664062499994],
              [-54.74384765625, 49.507763671875],
              [-54.786523437499994, 49.496142578124996],
              [-54.818505859374994, 49.514453125],
              [-54.86357421874999, 49.576074218749994],
              [-54.855419921875, 49.596582031249994],
              [-54.81308593749999, 49.599365234375],
              [-54.78876953125, 49.591210937499994],
              [-54.782617187499994, 49.572070312499996],
              [-54.7640625, 49.562353515625],
              [-54.73310546875, 49.562158203124994],
              [-54.61875, 49.6220703125],
              [-54.55917968749999, 49.631494140624994],
              [-54.53769531249999, 49.619970703125],
              [-54.55439453125, 49.5888671875],
            ],
          ],
          [
            [
              [-55.361230468749994, 51.8896484375],
              [-55.40888671875, 51.888818359375],
              [-55.41962890625, 51.900048828125],
              [-55.399804687499994, 51.9384765625],
              [-55.34648437499999, 51.982861328125],
              [-55.274072265624994, 51.995166015624996],
              [-55.29355468749999, 51.929980468749996],
              [-55.361230468749994, 51.8896484375],
            ],
          ],
          [
            [
              [-54.093701171875, 49.744433593749996],
              [-54.019921875, 49.679492187499996],
              [-53.9806640625, 49.661962890625],
              [-54.23837890624999, 49.591650390625],
              [-54.26923828125, 49.58701171875],
              [-54.2861328125, 49.595361328124994],
              [-54.28876953125, 49.66083984375],
              [-54.27763671874999, 49.711474609374996],
              [-54.258984375, 49.718994140625],
              [-54.199365234374994, 49.688525390624996],
              [-54.1376953125, 49.751171875],
              [-54.093701171875, 49.744433593749996],
            ],
          ],
          [
            [
              [-54.22714843749999, 47.441357421875],
              [-54.27607421875, 47.406542968749996],
              [-54.32597656249999, 47.408105468749994],
              [-54.32011718749999, 47.438525390624996],
              [-54.258691406249994, 47.49765625],
              [-54.22739257812499, 47.539990234375],
              [-54.22626953125, 47.56552734375],
              [-54.21494140624999, 47.585107421874994],
              [-54.168359375, 47.607080078124994],
              [-54.128173828125, 47.646826171875],
              [-54.14755859374999, 47.573095703125],
              [-54.22714843749999, 47.441357421875],
            ],
          ],
          [
            [
              [-64.823828125, 62.558740234374994],
              [-64.6318359375, 62.547998046874994],
              [-64.51533203125, 62.551806640625],
              [-64.4650390625, 62.5359375],
              [-64.41806640624999, 62.48740234375],
              [-64.47832031249999, 62.417871093749994],
              [-64.546484375, 62.39140625],
              [-64.657421875, 62.38359375],
              [-64.8373046875, 62.40625],
              [-64.90122070312499, 62.421044921874994],
              [-64.956494140625, 62.458349609375],
              [-64.93076171874999, 62.485009765624994],
              [-64.841943359375, 62.494140625],
              [-64.827099609375, 62.50498046875],
              [-64.849853515625, 62.525439453125],
              [-64.848779296875, 62.543310546875],
              [-64.823828125, 62.558740234374994],
            ],
          ],
          [
            [
              [-68.2337890625, 60.24091796875],
              [-68.32412109375, 60.23291015625],
              [-68.365234375, 60.254052734374994],
              [-68.36787109375, 60.31474609375],
              [-68.33828125, 60.360595703125],
              [-68.234765625, 60.45556640625],
              [-68.14189453124999, 60.56201171875],
              [-68.08759765625, 60.587841796875],
              [-67.97802734375, 60.57041015625],
              [-67.914208984375, 60.53984375],
              [-67.84755859375, 60.488818359375],
              [-67.81884765625, 60.44951171875],
              [-67.84423828125, 60.391650390625],
              [-67.922314453125, 60.339892578125],
              [-68.01230468749999, 60.304638671875],
              [-68.2337890625, 60.24091796875],
            ],
          ],
          [
            [
              [-70.337060546875, 62.548730468749994],
              [-70.40634765624999, 62.544824218749994],
              [-70.54150390625, 62.55234375],
              [-70.68657226562499, 62.573193359375],
              [-70.766064453125, 62.596875],
              [-70.837548828125, 62.648095703124994],
              [-70.85126953125, 62.704345703125],
              [-70.98613281249999, 62.78779296875],
              [-71.1369140625, 62.81591796875],
              [-71.2201171875, 62.873925781249994],
              [-71.13486328124999, 62.877978515625],
              [-71.013671875, 62.86533203125],
              [-70.834619140625, 62.840087890625],
              [-70.67431640625, 62.80703125],
              [-70.442626953125, 62.733789062499994],
              [-70.366796875, 62.6658203125],
              [-70.29150390625, 62.615966796875],
              [-70.26884765624999, 62.578076171875],
              [-70.28857421875, 62.561572265625],
              [-70.337060546875, 62.548730468749994],
            ],
          ],
          [
            [
              [-64.83261718749999, 61.366064453125],
              [-64.8568359375, 61.354443359375],
              [-64.87978515625, 61.357080078124994],
              [-64.954248046875, 61.410400390625],
              [-65.05439453125, 61.43203125],
              [-65.09150390625, 61.452978515625],
              [-65.39389648437499, 61.562841796875],
              [-65.42680664062499, 61.61103515625],
              [-65.43212890625, 61.64951171875],
              [-65.331640625, 61.66826171875],
              [-65.12978515625, 61.685693359374994],
              [-64.95444335937499, 61.685107421875],
              [-64.78964843749999, 61.66220703125],
              [-64.75634765625, 61.637646484375],
              [-64.669580078125, 61.593017578125],
              [-64.69096679687499, 61.53935546875],
              [-64.69638671874999, 61.471484375],
              [-64.73232421875, 61.438427734375],
              [-64.78759765625, 61.41328125],
              [-64.83261718749999, 61.366064453125],
            ],
          ],
          [
            [
              [-65.03056640624999, 61.879052734374994],
              [-65.008056640625, 61.870263671874994],
              [-64.98105468749999, 61.880615234375],
              [-64.960546875, 61.8716796875],
              [-64.9466796875, 61.843359375],
              [-64.92353515625, 61.82373046875],
              [-64.86513671875, 61.79814453125],
              [-64.84550781249999, 61.7798828125],
              [-64.8470703125, 61.7615234375],
              [-64.89658203124999, 61.733300781249994],
              [-64.927734375, 61.73251953125],
              [-65.16591796875, 61.79765625],
              [-65.23027343749999, 61.864013671875],
              [-65.2353515625, 61.897705078125],
              [-65.210546875, 61.928369140624994],
              [-65.17392578124999, 61.943212890625],
              [-65.125634765625, 61.942236328125],
              [-65.068359375, 61.926025390625],
              [-65.03056640624999, 61.879052734374994],
            ],
          ],
          [
            [
              [-69.16005859375, 59.040234375],
              [-69.220849609375, 58.967578125],
              [-69.301708984375, 58.976611328125],
              [-69.330810546875, 58.96162109375],
              [-69.35283203124999, 58.9607421875],
              [-69.31630859375, 59.028955078124994],
              [-69.3115234375, 59.0748046875],
              [-69.32998046875, 59.121240234374994],
              [-69.30322265625, 59.144873046875],
              [-69.19516601562499, 59.146142578124994],
              [-69.193798828125, 59.0927734375],
              [-69.1806640625, 59.072705078125],
              [-69.15517578125, 59.06357421875],
              [-69.16005859375, 59.040234375],
            ],
          ],
          [
            [
              [-64.40703124999999, 60.367089843749994],
              [-64.44194335937499, 60.2978515625],
              [-64.558203125, 60.3232421875],
              [-64.737939453125, 60.375634765624994],
              [-64.808984375, 60.410400390625],
              [-64.83378906249999, 60.4484375],
              [-64.83642578125, 60.501025390625],
              [-64.78256835937499, 60.509619140625],
              [-64.64628906249999, 60.514599609375],
              [-64.53251953124999, 60.44140625],
              [-64.49980468749999, 60.430224609375],
              [-64.40703124999999, 60.367089843749994],
            ],
          ],
          [
            [
              [-60.99448242187499, 56.039306640625],
              [-60.98271484374999, 56.01513671875],
              [-61.13701171874999, 56.032568359375],
              [-61.191308593749994, 56.0478515625],
              [-61.19584960937499, 56.063916015625],
              [-61.18818359375, 56.08896484375],
              [-61.15756835937499, 56.118359375],
              [-61.0869140625, 56.1408203125],
              [-61.04853515625, 56.129248046875],
              [-60.96640625, 56.098828125],
              [-60.95537109374999, 56.080419921875],
              [-60.99448242187499, 56.039306640625],
            ],
          ],
          [
            [
              [-61.743603515625, 57.55458984375],
              [-61.65952148437499, 57.524951171874996],
              [-61.6375, 57.416064453124996],
              [-61.79526367187499, 57.4224609375],
              [-61.975488281249994, 57.495410156249996],
              [-62.01123046875, 57.548486328125],
              [-62.007226562499994, 57.5576171875],
              [-61.983300781249994, 57.566748046875],
              [-61.9375, 57.5541015625],
              [-61.89306640625, 57.57314453125],
              [-61.84833984375, 57.579345703125],
              [-61.743603515625, 57.55458984375],
            ],
          ],
          [
            [
              [-67.914697265625, 69.540966796875],
              [-67.94028320312499, 69.53486328125],
              [-68.20234375, 69.580419921875],
              [-68.22138671875, 69.616748046875],
              [-68.09326171875, 69.65703125],
              [-67.98911132812499, 69.678759765625],
              [-67.908837890625, 69.6818359375],
              [-67.8291015625, 69.675],
              [-67.75458984375, 69.6314453125],
              [-67.844921875, 69.591748046875],
              [-67.914697265625, 69.540966796875],
            ],
          ],
          [
            [
              [-62.681542968749994, 67.056298828125],
              [-62.805419921875, 67.02880859375],
              [-62.87163085937499, 67.06259765625],
              [-62.825097656249994, 67.072119140625],
              [-62.756982421874994, 67.112548828125],
              [-62.66440429687499, 67.1482421875],
              [-62.62529296874999, 67.176953125],
              [-62.4697265625, 67.1900390625],
              [-62.41679687499999, 67.1884765625],
              [-62.39633789062499, 67.1783203125],
              [-62.484619140625, 67.134228515625],
              [-62.681542968749994, 67.056298828125],
            ],
          ],
          [
            [
              [-79.06308593749999, 75.92587890625],
              [-79.0517578125, 75.8669921875],
              [-79.1244140625, 75.869677734375],
              [-79.3556640625, 75.83115234375],
              [-79.54453125, 75.825634765625],
              [-79.63876953124999, 75.842919921875],
              [-79.69873046875, 75.883251953125],
              [-79.55126953125, 75.958349609375],
              [-79.381787109375, 76.01083984375],
              [-79.17832031249999, 76.0923828125],
              [-79.00932617187499, 76.1458984375],
              [-78.92587890624999, 76.13466796875],
              [-78.845166015625, 76.106298828125],
              [-78.946435546875, 76.025439453125],
              [-79.056640625, 75.98515625],
              [-79.06308593749999, 75.92587890625],
            ],
          ],
          [
            [
              [-79.86699218749999, 56.774560546875],
              [-79.894482421875, 56.757128906249996],
              [-79.94365234374999, 56.7767578125],
              [-79.945703125, 56.826904296875],
              [-79.89814453125, 56.865283203124996],
              [-79.860546875, 56.863525390625],
              [-79.82666015625, 56.843115234375],
              [-79.83500976562499, 56.816015625],
              [-79.86699218749999, 56.774560546875],
            ],
          ],
          [
            [
              [-79.71650390625, 57.51552734375],
              [-79.73222656249999, 57.50751953125],
              [-79.77519531249999, 57.514453125],
              [-79.79204101562499, 57.448583984375],
              [-79.80844726562499, 57.442431640624996],
              [-79.83823242187499, 57.4830078125],
              [-79.81591796875, 57.517724609375],
              [-79.81914062499999, 57.5416015625],
              [-79.81083984374999, 57.55927734375],
              [-79.76787109374999, 57.59873046875],
              [-79.742578125, 57.607958984374996],
              [-79.726708984375, 57.60458984375],
              [-79.71347656249999, 57.555029296875],
              [-79.71650390625, 57.51552734375],
            ],
          ],
          [
            [
              [-79.51816406249999, 56.656689453125],
              [-79.553466796875, 56.643847656249996],
              [-79.577392578125, 56.644921875],
              [-79.550732421875, 56.73349609375],
              [-79.58173828125, 56.76484375],
              [-79.58354492187499, 56.78095703125],
              [-79.57011718749999, 56.795703125],
              [-79.552880859375, 56.79873046875],
              [-79.51123046875, 56.771435546875],
              [-79.49106445312499, 56.74267578125],
              [-79.482177734375, 56.714404296874996],
              [-79.4845703125, 56.6865234375],
              [-79.496533203125, 56.667285156249996],
              [-79.51816406249999, 56.656689453125],
            ],
          ],
          [
            [
              [-79.938232421875, 53.304150390625],
              [-79.93930664062499, 53.274267578125],
              [-80.0041015625, 53.280078125],
              [-80.03935546874999, 53.297167968749996],
              [-80.06787109375, 53.324072265625],
              [-80.07402343749999, 53.344287109374996],
              [-80.04970703125, 53.364453125],
              [-79.974560546875, 53.35224609375],
              [-79.938232421875, 53.304150390625],
            ],
          ],
          [
            [
              [-79.38427734375, 51.951953125],
              [-79.42558593749999, 51.944873046874996],
              [-79.52060546874999, 51.952929687499996],
              [-79.596875, 51.97802734375],
              [-79.64375, 52.010058593749996],
              [-79.33486328125, 52.09814453125],
              [-79.27128906249999, 52.08681640625],
              [-79.27021484375, 52.07109375],
              [-79.3166015625, 52.02392578125],
              [-79.32895507812499, 51.99228515625],
              [-79.351513671875, 51.968310546874996],
              [-79.38427734375, 51.951953125],
            ],
          ],
          [
            [
              [-123.37236328124999, 48.886132812499994],
              [-123.38481445312499, 48.8751953125],
              [-123.541015625, 48.945947265624994],
              [-123.64560546875, 49.038623046874996],
              [-123.68925781249999, 49.095117187499994],
              [-123.48232421875, 48.9546875],
              [-123.3779296875, 48.908251953124996],
              [-123.37236328124999, 48.886132812499994],
            ],
          ],
          [
            [
              [-124.977734375, 50.029589843749996],
              [-125.0015625, 50.020751953125],
              [-125.0259765625, 50.134082031249996],
              [-124.99565429687499, 50.175195312499994],
              [-124.98701171875, 50.195849609374996],
              [-124.9908203125, 50.217138671875],
              [-124.937841796875, 50.165917968749994],
              [-124.91640625, 50.13154296875],
              [-124.907470703125, 50.083984375],
              [-124.908447265625, 50.0712890625],
              [-124.977734375, 50.029589843749996],
            ],
          ],
          [
            [
              [-73.621728515625, 67.783837890625],
              [-74.10908203125, 67.78251953125],
              [-74.37407226562499, 67.789599609375],
              [-74.480712890625, 67.8048828125],
              [-74.573388671875, 67.828662109375],
              [-74.67861328125, 67.90556640625],
              [-74.74599609375, 67.984814453125],
              [-74.749267578125, 68.01845703125],
              [-74.7314453125, 68.048779296875],
              [-74.70654296875, 68.06708984375],
              [-74.37939453125, 68.09345703125],
              [-74.11137695312499, 68.060595703125],
              [-73.88071289062499, 68.021923828125],
              [-73.58403320312499, 68.01533203125],
              [-73.49375, 68.000634765625],
              [-73.459228515625, 67.989892578125],
              [-73.43525390625, 67.97001953125],
              [-73.4015625, 67.8787109375],
              [-73.398193359375, 67.829931640625],
              [-73.407177734375, 67.79306640625],
              [-73.621728515625, 67.783837890625],
            ],
          ],
          [
            [
              [-77.876708984375, 63.470556640625],
              [-77.79208984374999, 63.42783203125],
              [-77.70371093749999, 63.430859375],
              [-77.65478515625, 63.39599609375],
              [-77.5384765625, 63.287060546875],
              [-77.527294921875, 63.268945312499994],
              [-77.53271484375, 63.233642578125],
              [-77.593896484375, 63.188427734375],
              [-77.657666015625, 63.164599609375],
              [-77.791455078125, 63.12958984375],
              [-77.94243164062499, 63.114404296874994],
              [-78.0244140625, 63.1388671875],
              [-78.25595703124999, 63.23984375],
              [-78.46875, 63.35791015625],
              [-78.536767578125, 63.423730468749994],
              [-78.50732421875, 63.451123046875],
              [-78.41728515624999, 63.469970703125],
              [-78.23491210937499, 63.48955078125],
              [-77.93393554687499, 63.478955078125],
              [-77.876708984375, 63.470556640625],
            ],
          ],
          [
            [
              [-94.5265625, 75.74931640625],
              [-94.62436523437499, 75.748876953125],
              [-94.75146484375, 75.769677734375],
              [-94.787353515625, 75.79140625],
              [-94.81474609374999, 75.82119140625],
              [-94.833642578125, 75.858984375],
              [-94.860107421875, 75.889208984375],
              [-94.89409179687499, 75.911865234375],
              [-94.90122070312499, 75.93076171875],
              [-94.88134765625, 75.945947265625],
              [-94.839794921875, 75.954443359375],
              [-94.74482421875, 75.9572265625],
              [-94.53789062499999, 75.996435546875],
              [-94.498681640625, 75.9921875],
              [-94.47128906249999, 75.971435546875],
              [-94.443359375, 75.91708984375],
              [-94.41376953125, 75.88486328125],
              [-94.3322265625, 75.8259765625],
              [-94.2962890625, 75.7880859375],
              [-94.30400390624999, 75.776318359375],
              [-94.329541015625, 75.76591796875],
              [-94.5265625, 75.74931640625],
            ],
          ],
          [
            [
              [-80.28525390624999, 59.62412109375],
              [-80.31723632812499, 59.621044921875],
              [-80.324658203125, 59.633203125],
              [-80.298974609375, 59.674169921875],
              [-80.25664062499999, 59.679150390625],
              [-80.2099609375, 59.724609375],
              [-80.167236328125, 59.708886718749994],
              [-80.183056640625, 59.683496093749994],
              [-80.24052734374999, 59.644921875],
              [-80.28525390624999, 59.62412109375],
            ],
          ],
          [
            [
              [-80.064208984375, 59.77080078125],
              [-80.16708984374999, 59.7638671875],
              [-80.122216796875, 59.823193359375],
              [-80.083642578125, 59.85185546875],
              [-80.041162109375, 59.870166015625],
              [-79.95585937499999, 59.876953125],
              [-79.8986328125, 59.853125],
              [-79.949609375, 59.809912109375],
              [-80.064208984375, 59.77080078125],
            ],
          ],
          [
            [
              [-96.78232421874999, 72.93662109375],
              [-96.943798828125, 72.926708984375],
              [-97.0927734375, 72.996923828125],
              [-97.09765625, 73.06240234375],
              [-97.08769531249999, 73.098486328125],
              [-97.06923828125, 73.13017578125],
              [-97.01499023437499, 73.157275390625],
              [-96.86240234374999, 73.188818359375],
              [-96.7931640625, 73.165478515625],
              [-96.7677734375, 73.1373046875],
              [-96.74443359374999, 73.12626953125],
              [-96.64599609375, 73.101904296875],
              [-96.59848632812499, 73.073828125],
              [-96.603515625, 73.041552734375],
              [-96.635400390625, 72.992431640625],
              [-96.67060546875, 72.9609375],
              [-96.709228515625, 72.94697265625],
              [-96.78232421874999, 72.93662109375],
            ],
          ],
          [
            [
              [-97.35551757812499, 74.526318359375],
              [-97.65610351562499, 74.465673828125],
              [-97.72158203125, 74.489208984375],
              [-97.75, 74.510546875],
              [-97.51630859375, 74.602490234375],
              [-97.41650390625, 74.6265625],
              [-97.31821289062499, 74.597998046875],
              [-97.29130859374999, 74.5763671875],
              [-97.303857421875, 74.55966796875],
              [-97.35551757812499, 74.526318359375],
            ],
          ],
          [
            [
              [-98.27036132812499, 73.868505859375],
              [-98.558203125, 73.847412109375],
              [-98.691064453125, 73.856494140625],
              [-98.761376953125, 73.828857421875],
              [-98.8166015625, 73.817138671875],
              [-98.97392578124999, 73.812060546875],
              [-99.298046875, 73.861962890625],
              [-99.38515625, 73.879296875],
              [-99.4169921875, 73.89541015625],
              [-99.40380859375, 73.910888671875],
              [-99.34560546875, 73.925732421875],
              [-99.096875, 73.948291015625],
              [-99.00468749999999, 73.96494140625],
              [-98.96669921875, 73.98818359375],
              [-98.9044921875, 74.006884765625],
              [-98.81816406249999, 74.02099609375],
              [-98.5849609375, 74.034521484375],
              [-98.06103515625, 74.1046875],
              [-97.800439453125, 74.1146484375],
              [-97.6982421875, 74.10869140625],
              [-97.667431640625, 74.09013671875],
              [-97.659130859375, 74.071630859375],
              [-97.67333984375, 74.05302734375],
              [-97.75473632812499, 74.005517578125],
              [-97.861083984375, 73.96845703125],
              [-98.14697265625, 73.888818359375],
              [-98.27036132812499, 73.868505859375],
            ],
          ],
          [
            [
              [-90.19980468749999, 69.419091796875],
              [-90.177392578125, 69.357080078125],
              [-90.26728515625, 69.272900390625],
              [-90.29545898437499, 69.2578125],
              [-90.3302734375, 69.252197265625],
              [-90.36406249999999, 69.26259765625],
              [-90.46469726562499, 69.3287109375],
              [-90.49204101562499, 69.369873046875],
              [-90.45512695312499, 69.390478515625],
              [-90.37724609374999, 69.4162109375],
              [-90.32207031249999, 69.4287109375],
              [-90.25283203125, 69.417919921875],
              [-90.22856445312499, 69.43603515625],
              [-90.19980468749999, 69.419091796875],
            ],
          ],
          [
            [
              [-90.492578125, 69.22109375],
              [-90.5744140625, 69.209423828125],
              [-90.62578124999999, 69.250927734375],
              [-90.667431640625, 69.25947265625],
              [-90.68588867187499, 69.287158203125],
              [-90.77158203124999, 69.292578125],
              [-90.76567382812499, 69.335986328125],
              [-90.7423828125, 69.35732421875],
              [-90.66279296875, 69.374169921875],
              [-90.59970703124999, 69.367822265625],
              [-90.53984374999999, 69.324609375],
              [-90.51064453125, 69.2904296875],
              [-90.4853515625, 69.246630859375],
              [-90.492578125, 69.22109375],
            ],
          ],
          [
            [
              [-74.000439453125, 62.618408203125],
              [-74.05356445312499, 62.60966796875],
              [-74.253515625, 62.621972656249994],
              [-74.49951171875, 62.668798828125],
              [-74.62646484375, 62.712744140625],
              [-74.61997070312499, 62.726318359375],
              [-74.564208984375, 62.733300781249994],
              [-74.500927734375, 62.726513671875],
              [-74.394775390625, 62.69580078125],
              [-74.10893554687499, 62.680322265624994],
              [-74.016796875, 62.6626953125],
              [-73.98818359375, 62.636083984375],
              [-74.000439453125, 62.618408203125],
            ],
          ],
          [
            [
              [-74.880859375, 68.348681640625],
              [-74.95932617187499, 68.342236328125],
              [-75.072509765625, 68.404150390625],
              [-75.31015625, 68.474462890625],
              [-75.40024414062499, 68.52548828125],
              [-75.40341796874999, 68.550146484375],
              [-75.39619140625, 68.588818359375],
              [-75.37016601562499, 68.636083984375],
              [-75.28740234374999, 68.687744140625],
              [-75.19975585937499, 68.69609375],
              [-75.07470703125, 68.684716796875],
              [-74.983642578125, 68.647607421875],
              [-74.884765625, 68.54462890625],
              [-74.81894531249999, 68.49443359375],
              [-74.7982421875, 68.457958984375],
              [-74.83095703125, 68.44072265625],
              [-74.82792968749999, 68.423779296875],
              [-74.812890625, 68.413330078125],
              [-74.8185546875, 68.394091796875],
              [-74.844970703125, 68.365966796875],
              [-74.880859375, 68.348681640625],
            ],
          ],
          [
            [
              [-78.531640625, 60.728564453125],
              [-78.668896484375, 60.71689453125],
              [-78.669091796875, 60.731347656249994],
              [-78.61201171875, 60.772314453125],
              [-78.399560546875, 60.80810546875],
              [-78.24169921875, 60.81865234375],
              [-78.27885742187499, 60.78388671875],
              [-78.3724609375, 60.756396484375],
              [-78.531640625, 60.728564453125],
            ],
          ],
          [
            [
              [-78.98271484374999, 68.192822265625],
              [-79.0640625, 68.181787109375],
              [-79.1740234375, 68.2349609375],
              [-79.174755859375, 68.264453125],
              [-79.153466796875, 68.33525390625],
              [-78.952587890625, 68.35302734375],
              [-78.86870117187499, 68.310302734375],
              [-78.828515625, 68.2681640625],
              [-78.98271484374999, 68.192822265625],
            ],
          ],
          [
            [
              [-76.67758789062499, 63.393945312499994],
              [-76.783154296875, 63.384033203125],
              [-76.921875, 63.40634765625],
              [-77.05722656249999, 63.449755859375],
              [-77.36474609375, 63.588330078125],
              [-77.13369140625, 63.68203125],
              [-76.763623046875, 63.573583984375],
              [-76.65244140624999, 63.503564453124994],
              [-76.67758789062499, 63.393945312499994],
            ],
          ],
          [
            [
              [-79.4306640625, 69.78779296875],
              [-79.390283203125, 69.730419921875],
              [-79.364990234375, 69.712353515625],
              [-79.40244140624999, 69.68515625],
              [-79.55283203124999, 69.630859375],
              [-79.88168945312499, 69.60869140625],
              [-80.047509765625, 69.634326171875],
              [-79.971142578125, 69.55634765625],
              [-79.9544921875, 69.523486328125],
              [-79.97783203124999, 69.50966796875],
              [-80.046875, 69.5138671875],
              [-80.16147460937499, 69.5359375],
              [-80.22734374999999, 69.56240234375],
              [-80.24448242187499, 69.5931640625],
              [-80.26865234374999, 69.6],
              [-80.29970703125, 69.582861328125],
              [-80.32958984375, 69.586767578125],
              [-80.3978515625, 69.6326171875],
              [-80.44804687499999, 69.64970703125],
              [-80.77822265625, 69.677001953125],
              [-80.79477539062499, 69.6892578125],
              [-80.7775390625, 69.7103515625],
              [-80.72661132812499, 69.7404296875],
              [-80.6525390625, 69.7505859375],
              [-80.46591796874999, 69.737109375],
              [-80.45068359375, 69.744775390625],
              [-80.43833007812499, 69.78271484375],
              [-80.42421875, 69.797607421875],
              [-80.294921875, 69.793798828125],
              [-80.21367187499999, 69.801953125],
              [-80.16884765625, 69.782421875],
              [-80.124609375, 69.737255859375],
              [-80.061767578125, 69.7455078125],
              [-79.970849609375, 69.73896484375],
              [-79.869580078125, 69.755517578125],
              [-79.71484375, 69.795703125],
              [-79.593994140625, 69.810498046875],
              [-79.4306640625, 69.78779296875],
            ],
          ],
          [
            [
              [-78.02910156249999, 69.714892578125],
              [-77.97783203124999, 69.664892578125],
              [-77.969140625, 69.63896484375],
              [-78.039990234375, 69.6083984375],
              [-78.30722656249999, 69.551806640625],
              [-78.47006835937499, 69.5025390625],
              [-78.552392578125, 69.491552734375],
              [-78.662060546875, 69.50263671875],
              [-78.7953125, 69.479736328125],
              [-78.84819335937499, 69.4828125],
              [-78.789306640625, 69.52314453125],
              [-78.578564453125, 69.638818359375],
              [-78.40185546875, 69.650634765625],
              [-78.344189453125, 69.6748046875],
              [-78.29550781249999, 69.667138671875],
              [-78.267333984375, 69.687158203125],
              [-78.262451171875, 69.716845703125],
              [-78.20073242187499, 69.739501953125],
              [-78.14521484375, 69.739208984375],
              [-78.02910156249999, 69.714892578125],
            ],
          ],
          [
            [
              [-77.64208984375, 63.99189453125],
              [-77.7140625, 63.945703125],
              [-77.92880859374999, 63.96201171875],
              [-77.95791015625, 63.976025390625],
              [-77.965966796875, 63.992919921875],
              [-77.93134765625, 64.014794921875],
              [-77.71079101562499, 64.03564453125],
              [-77.61728515624999, 64.03720703125],
              [-77.56938476562499, 64.030419921875],
              [-77.563623046875, 64.0220703125],
              [-77.64208984375, 63.99189453125],
            ],
          ],
          [
            [
              [-83.123486328125, 66.2828125],
              [-83.023876953125, 66.270654296875],
              [-82.94814453125, 66.271923828125],
              [-82.93134765625, 66.25732421875],
              [-83.01083984374999, 66.208447265625],
              [-83.05986328124999, 66.199267578125],
              [-83.147900390625, 66.234228515625],
              [-83.21391601562499, 66.27705078125],
              [-83.232568359375, 66.302978515625],
              [-83.23784179687499, 66.33154296875],
              [-83.222265625, 66.336474609375],
              [-83.123486328125, 66.2828125],
            ],
          ],
          [
            [
              [-79.21064453125, 68.845458984375],
              [-79.279736328125, 68.838720703125],
              [-79.36137695312499, 68.857666015625],
              [-79.39047851562499, 68.890185546875],
              [-79.40576171875, 68.923046875],
              [-79.39116210937499, 68.93994140625],
              [-79.354736328125, 68.955908203125],
              [-79.30522460937499, 68.992333984375],
              [-79.24267578125, 69.049267578125],
              [-79.144970703125, 69.087451171875],
              [-78.93046874999999, 69.122900390625],
              [-78.9, 69.135400390625],
              [-78.8041015625, 69.235107421875],
              [-78.77182617187499, 69.252197265625],
              [-78.66201171875, 69.262353515625],
              [-78.65019531249999, 69.2751953125],
              [-78.6890625, 69.299755859375],
              [-78.6890625, 69.32509765625],
              [-78.65019531249999, 69.351220703125],
              [-78.5966796875, 69.37060546875],
              [-78.45791015625, 69.389501953125],
              [-78.33256835937499, 69.38603515625],
              [-78.30048828125, 69.3787109375],
              [-78.2724609375, 69.36123046875],
              [-78.23408203125, 69.314599609375],
              [-78.228955078125, 69.30400390625],
              [-78.28701171875, 69.2626953125],
              [-78.43896484375, 69.199169921875],
              [-78.53291015625, 69.146044921875],
              [-78.5517578125, 69.128662109375],
              [-78.560302734375, 69.10625],
              [-78.595654296875, 69.079052734375],
              [-78.70537109374999, 69.013671875],
              [-78.77919921875, 68.95048828125],
              [-78.852685546875, 68.915673828125],
              [-79.05361328125, 68.88291015625],
              [-79.21064453125, 68.845458984375],
            ],
          ],
          [
            [
              [-76.995361328125, 69.14375],
              [-77.12163085937499, 69.13212890625],
              [-77.2150390625, 69.1380859375],
              [-77.2755859375, 69.161669921875],
              [-77.321923828125, 69.193603515625],
              [-77.37939453125, 69.2740234375],
              [-77.358056640625, 69.3115234375],
              [-77.351513671875, 69.378662109375],
              [-77.34091796874999, 69.403857421875],
              [-77.31870117187499, 69.41630859375],
              [-77.18754882812499, 69.440087890625],
              [-77.10917968749999, 69.43740234375],
              [-76.994091796875, 69.411767578125],
              [-76.745703125, 69.40400390625],
              [-76.68408203125, 69.380419921875],
              [-76.66884765625, 69.366162109375],
              [-76.67001953124999, 69.348583984375],
              [-76.687451171875, 69.327685546875],
              [-76.810302734375, 69.266748046875],
              [-76.8693359375, 69.224853515625],
              [-76.91123046874999, 69.174658203125],
              [-76.995361328125, 69.14375],
            ],
          ],
          [
            [
              [-86.913037109375, 70.113232421875],
              [-86.798779296875, 70.1052734375],
              [-86.69121093749999, 70.1150390625],
              [-86.612744140625, 70.105712890625],
              [-86.56337890625, 70.07724609375],
              [-86.530908203125, 70.04765625],
              [-86.515234375, 70.017041015625],
              [-86.55766601562499, 69.9953125],
              [-86.734326171875, 69.976318359375],
              [-86.854931640625, 69.9857421875],
              [-86.983984375, 70.0111328125],
              [-87.04379882812499, 69.999853515625],
              [-87.1908203125, 70.0185546875],
              [-87.263916015625, 70.0439453125],
              [-87.3232421875, 70.080126953125],
              [-87.32314453125, 70.10224609375],
              [-87.168115234375, 70.12724609375],
              [-87.10727539062499, 70.1466796875],
              [-86.913037109375, 70.113232421875],
            ],
          ],
          [
            [
              [-83.7259765625, 65.796728515625],
              [-83.59750976562499, 65.757470703125],
              [-83.46943359375, 65.735205078125],
              [-83.26318359375, 65.723291015625],
              [-83.23374023437499, 65.7150390625],
              [-83.23393554687499, 65.69658203125],
              [-83.263671875, 65.667822265625],
              [-83.332421875, 65.6310546875],
              [-83.38144531249999, 65.62998046875],
              [-83.49541015624999, 65.65595703125],
              [-83.537109375, 65.669189453125],
              [-83.583203125, 65.6986328125],
              [-83.60654296874999, 65.7013671875],
              [-83.636376953125, 65.69150390625],
              [-83.64438476562499, 65.678515625],
              [-83.63066406249999, 65.662353515625],
              [-83.64951171874999, 65.657763671875],
              [-83.787548828125, 65.668896484375],
              [-83.809228515625, 65.6783203125],
              [-83.79819335937499, 65.710009765625],
              [-83.701904296875, 65.756201171875],
              [-83.7865234375, 65.77041015625],
              [-83.81357421874999, 65.7875],
              [-83.93896484375, 65.758447265625],
              [-84.00849609375, 65.751513671875],
              [-84.11826171874999, 65.77177734375],
              [-84.12993164062499, 65.87744140625],
              [-84.143212890625, 65.915966796875],
              [-84.19321289062499, 65.942138671875],
              [-84.22294921874999, 65.969775390625],
              [-84.2708984375, 65.990625],
              [-84.3701171875, 66.01181640625],
              [-84.4505859375, 66.064404296875],
              [-84.46738281249999, 66.08828125],
              [-84.45634765624999, 66.10625],
              [-84.407177734375, 66.131005859375],
              [-84.122265625, 66.07783203125],
              [-83.950390625, 66.027490234375],
              [-83.78696289062499, 65.965771484375],
              [-83.7013671875, 65.9201171875],
              [-83.69365234374999, 65.890380859375],
              [-83.71489257812499, 65.8607421875],
              [-83.76513671875, 65.83115234375],
              [-83.7259765625, 65.796728515625],
            ],
          ],
          [
            [
              [-86.59555664062499, 67.7359375],
              [-86.63818359375, 67.73486328125],
              [-86.70595703125, 67.750146484375],
              [-86.861083984375, 67.810498046875],
              [-86.892529296875, 67.836572265625],
              [-86.90830078124999, 67.867041015625],
              [-86.908447265625, 67.901953125],
              [-86.89458007812499, 67.9380859375],
              [-86.8470703125, 68.01025390625],
              [-86.937744140625, 68.067578125],
              [-86.95981445312499, 68.100244140625],
              [-86.949169921875, 68.118701171875],
              [-86.898681640625, 68.162890625],
              [-86.88486328124999, 68.19052734375],
              [-86.833984375, 68.2296875],
              [-86.702099609375, 68.305615234375],
              [-86.569921875, 68.2876953125],
              [-86.45195312499999, 68.22548828125],
              [-86.421142578125, 68.183447265625],
              [-86.430322265625, 68.138720703125],
              [-86.42001953124999, 68.07392578125],
              [-86.39033203125, 67.988916015625],
              [-86.382421875, 67.927294921875],
              [-86.396435546875, 67.88896484375],
              [-86.446923828125, 67.8169921875],
              [-86.4896484375, 67.78359375],
              [-86.546044921875, 67.752197265625],
              [-86.59555664062499, 67.7359375],
            ],
          ],
          [
            [
              [-84.674755859375, 65.575],
              [-84.727001953125, 65.563720703125],
              [-84.78291015625, 65.570068359375],
              [-84.8302734375, 65.598974609375],
              [-84.86894531249999, 65.650537109375],
              [-84.93115234375, 65.68916015625],
              [-85.07197265625, 65.737353515625],
              [-85.096337890625, 65.756201171875],
              [-85.13627929687499, 65.820849609375],
              [-85.14404296875, 65.8853515625],
              [-85.17416992187499, 65.94375],
              [-85.17568359375, 65.972412109375],
              [-85.149609375, 66.015380859375],
              [-85.031396484375, 66.02548828125],
              [-84.93857421874999, 66.008544921875],
              [-84.91982421875, 65.997021484375],
              [-84.88945312499999, 65.9720703125],
              [-84.86953125, 65.94150390625],
              [-84.75737304687499, 65.858935546875],
              [-84.69174804687499, 65.7931640625],
              [-84.60263671874999, 65.657373046875],
              [-84.60224609375, 65.631494140625],
              [-84.62626953124999, 65.604052734375],
              [-84.674755859375, 65.575],
            ],
          ],
          [
            [
              [-93.0439453125, 61.844091796875],
              [-93.08481445312499, 61.841699218749994],
              [-93.17656249999999, 61.892724609374994],
              [-93.1966796875, 61.9185546875],
              [-93.07578125, 61.935009765625],
              [-92.99301757812499, 61.889697265625],
              [-92.999951171875, 61.86748046875],
              [-93.0439453125, 61.844091796875],
            ],
          ],
          [
            [
              [-101.22612304687499, 76.579345703125],
              [-101.48520507812499, 76.575],
              [-101.60498046875, 76.58701171875],
              [-101.61308593749999, 76.60458984375],
              [-101.50947265625, 76.627734375],
              [-101.1650390625, 76.6654296875],
              [-100.962158203125, 76.7341796875],
              [-100.886474609375, 76.74267578125],
              [-100.62158203125, 76.752490234375],
              [-100.467236328125, 76.750341796875],
              [-100.269140625, 76.734130859375],
              [-100.74658203125, 76.649169921875],
              [-101.22612304687499, 76.579345703125],
            ],
          ],
          [
            [
              [-103.003369140625, 78.146435546875],
              [-103.11821289062499, 78.1263671875],
              [-103.25224609375, 78.138134765625],
              [-103.27099609375, 78.150634765625],
              [-103.273583984375, 78.165771484375],
              [-103.26005859375, 78.18349609375],
              [-103.11044921874999, 78.245849609375],
              [-102.973291015625, 78.267236328125],
              [-102.891796875, 78.271240234375],
              [-102.82553710937499, 78.250048828125],
              [-102.78828125, 78.2181640625],
              [-103.003369140625, 78.146435546875],
            ],
          ],
          [
            [
              [-101.6935546875, 77.69658203125],
              [-101.8310546875, 77.687353515625],
              [-102.079833984375, 77.6921875],
              [-102.37783203125, 77.728125],
              [-102.458203125, 77.770166015625],
              [-102.475048828125, 77.836669921875],
              [-102.47153320312499, 77.873486328125],
              [-102.447705078125, 77.880615234375],
              [-102.26318359375, 77.88935546875],
              [-101.91787109375, 77.899609375],
              [-101.639404296875, 77.89208984375],
              [-101.322021484375, 77.854150390625],
              [-101.19321289062499, 77.82978515625],
              [-101.127587890625, 77.81259765625],
              [-101.04624023437499, 77.77783203125],
              [-101.01958007812499, 77.762451171875],
              [-101.00205078124999, 77.735107421875],
              [-101.39765625, 77.729052734375],
              [-101.58457031249999, 77.718310546875],
              [-101.6935546875, 77.69658203125],
            ],
          ],
          [
            [
              [-89.72646484375, 76.507421875],
              [-89.77329101562499, 76.49384765625],
              [-89.92412109374999, 76.50087890625],
              [-89.97412109375, 76.487548828125],
              [-90.054296875, 76.4951171875],
              [-90.16455078125, 76.523583984375],
              [-90.29350585937499, 76.5794921875],
              [-90.44096679687499, 76.66279296875],
              [-90.55625, 76.7345703125],
              [-90.5625, 76.754296875],
              [-90.5248046875, 76.787841796875],
              [-90.40952148437499, 76.81015625],
              [-90.136328125, 76.836962890625],
              [-89.94877929687499, 76.83623046875],
              [-89.774560546875, 76.78203125],
              [-89.72529296875, 76.763427734375],
              [-89.69541015624999, 76.741162109375],
              [-89.69443359374999, 76.71982421875],
              [-89.708642578125, 76.701171875],
              [-89.787548828125, 76.659619140625],
              [-89.82211914062499, 76.630615234375],
              [-89.821923828125, 76.602197265625],
              [-89.80478515624999, 76.561083984375],
              [-89.77294921875, 76.533935546875],
              [-89.72636718749999, 76.52080078125],
              [-89.72646484375, 76.507421875],
            ],
          ],
          [
            [
              [-96.078564453125, 75.510107421875],
              [-96.156396484375, 75.47724609375],
              [-96.23662109374999, 75.4748046875],
              [-96.344482421875, 75.50595703125],
              [-96.46162109375, 75.49423828125],
              [-96.62197265625, 75.431298828125],
              [-96.67900390624999, 75.394189453125],
              [-96.7228515625, 75.38076171875],
              [-96.85712890625, 75.369140625],
              [-96.91513671874999, 75.3796875],
              [-96.96962890625, 75.412646484375],
              [-97.020654296875, 75.46806640625],
              [-96.9828125, 75.509814453125],
              [-96.85615234375, 75.537939453125],
              [-96.522900390625, 75.583642578125],
              [-96.427685546875, 75.60634765625],
              [-96.417236328125, 75.630712890625],
              [-96.39726562499999, 75.646826171875],
              [-96.367822265625, 75.654638671875],
              [-96.14541015625, 75.613525390625],
              [-96.03984374999999, 75.585791015625],
              [-95.95986328125, 75.554345703125],
              [-95.96860351562499, 75.541845703125],
              [-96.078564453125, 75.510107421875],
            ],
          ],
          [
            [
              [-95.306640625, 74.505419921875],
              [-95.35244140625, 74.500390625],
              [-95.44150390624999, 74.506103515625],
              [-95.77719726562499, 74.550732421875],
              [-95.834375, 74.56904296875],
              [-95.850732421875, 74.582470703125],
              [-95.7744140625, 74.598681640625],
              [-95.74560546875, 74.615966796875],
              [-95.66044921874999, 74.6369140625],
              [-95.510205078125, 74.636767578125],
              [-95.3525390625, 74.585693359375],
              [-95.27836914062499, 74.53955078125],
              [-95.27446289062499, 74.519189453125],
              [-95.306640625, 74.505419921875],
            ],
          ],
          [
            [
              [-121.07622070312499, 75.745263671875],
              [-121.154296875, 75.740625],
              [-121.24091796875, 75.75185546875],
              [-121.22109375, 75.777490234375],
              [-121.026318359375, 75.847509765625],
              [-121.01542968749999, 75.867529296875],
              [-121.01806640625, 75.883837890625],
              [-121.04228515624999, 75.902978515625],
              [-120.993017578125, 75.92744140625],
              [-120.91396484375, 75.9375],
              [-120.88779296875, 75.927978515625],
              [-120.87871093749999, 75.906689453125],
              [-120.896875, 75.84453125],
              [-120.921240234375, 75.814453125],
              [-120.954931640625, 75.78876953125],
              [-121.00664062499999, 75.76572265625],
              [-121.07622070312499, 75.745263671875],
            ],
          ],
          [
            [
              [-113.560693359375, 76.74326171875],
              [-113.712451171875, 76.710546875],
              [-114.75146484375, 76.75888671875],
              [-114.80830078125, 76.774072265625],
              [-114.83525390625, 76.794677734375],
              [-114.64707031249999, 76.851025390625],
              [-114.419873046875, 76.875341796875],
              [-113.891650390625, 76.894873046875],
              [-113.70751953125, 76.87294921875],
              [-113.585400390625, 76.847314453125],
              [-113.51650390625, 76.825048828125],
              [-113.48759765625, 76.783251953125],
              [-113.560693359375, 76.74326171875],
            ],
          ],
          [
            [
              [-104.119921875, 75.036328125],
              [-104.30869140624999, 75.03095703125],
              [-104.634326171875, 75.061279296875],
              [-104.828125, 75.1197265625],
              [-104.88740234375, 75.14775390625],
              [-104.881640625, 75.160498046875],
              [-104.848095703125, 75.173046875],
              [-104.801318359375, 75.21103515625],
              [-104.690380859375, 75.320703125],
              [-104.648828125, 75.349755859375],
              [-104.474169921875, 75.413037109375],
              [-104.34619140625, 75.429931640625],
              [-104.074658203125, 75.42451171875],
              [-103.9169921875, 75.391845703125],
              [-103.851171875, 75.37080078125],
              [-103.8041015625, 75.3455078125],
              [-103.75791015624999, 75.2890625],
              [-103.746484375, 75.25244140625],
              [-103.667236328125, 75.210693359375],
              [-103.643505859375, 75.186572265625],
              [-103.642138671875, 75.162939453125],
              [-103.6642578125, 75.1390625],
              [-103.709716796875, 75.114990234375],
              [-103.813916015625, 75.079736328125],
              [-104.119921875, 75.036328125],
            ],
          ],
          [
            [
              [-100.217236328125, 68.806689453125],
              [-100.248779296875, 68.775048828125],
              [-100.28793945312499, 68.766064453125],
              [-100.36572265625, 68.72880859375],
              [-100.39731445312499, 68.723828125],
              [-100.442578125, 68.74755859375],
              [-100.4806640625, 68.786181640625],
              [-100.49692382812499, 68.792236328125],
              [-100.52104492187499, 68.790673828125],
              [-100.573388671875, 68.766064453125],
              [-100.59653320312499, 68.76640625],
              [-100.615966796875, 68.78291015625],
              [-100.625390625, 68.81591796875],
              [-100.624658203125, 68.865283203125],
              [-100.59990234374999, 68.941357421875],
              [-100.59833984375, 68.969091796875],
              [-100.611572265625, 68.990185546875],
              [-100.60063476562499, 69.009423828125],
              [-100.56547851562499, 69.026806640625],
              [-100.52031249999999, 69.03505859375],
              [-100.41396484375, 69.028076171875],
              [-100.32993164062499, 68.99755859375],
              [-100.28896484375, 68.957666015625],
              [-100.20688476562499, 68.926171875],
              [-100.178466796875, 68.90390625],
              [-100.217236328125, 68.806689453125],
            ],
          ],
          [
            [
              [-99.99467773437499, 69.013525390625],
              [-100.018017578125, 68.95400390625],
              [-100.14130859375, 68.969921875],
              [-100.195703125, 68.991455078125],
              [-100.24199218749999, 69.040380859375],
              [-100.24736328124999, 69.052783203125],
              [-100.237060546875, 69.071484375],
              [-100.186962890625, 69.114013671875],
              [-100.153125, 69.1294921875],
              [-100.07280273437499, 69.111474609375],
              [-100.0353515625, 69.086572265625],
              [-100.005615234375, 69.047119140625],
              [-99.99467773437499, 69.013525390625],
            ],
          ],
          [
            [
              [-100.308349609375, 70.49580078125],
              [-100.321240234375, 70.4876953125],
              [-100.537255859375, 70.525],
              [-100.62065429687499, 70.546923828125],
              [-100.64775390624999, 70.563134765625],
              [-100.666943359375, 70.596240234375],
              [-100.67832031249999, 70.64619140625],
              [-100.63530273437499, 70.6703125],
              [-100.53793945312499, 70.668603515625],
              [-100.43393554687499, 70.6494140625],
              [-100.276123046875, 70.59462890625],
              [-100.32109374999999, 70.578369140625],
              [-100.3232421875, 70.542431640625],
              [-100.30551757812499, 70.5083984375],
              [-100.308349609375, 70.49580078125],
            ],
          ],
          [
            [
              [-95.513671875, 69.5736328125],
              [-95.38090820312499, 69.506591796875],
              [-95.382080078125, 69.474072265625],
              [-95.3994140625, 69.419775390625],
              [-95.437451171875, 69.378466796875],
              [-95.496240234375, 69.35009765625],
              [-95.578515625, 69.33583984375],
              [-95.684375, 69.335693359375],
              [-95.730126953125, 69.34755859375],
              [-95.69589843749999, 69.38955078125],
              [-95.670166015625, 69.402001953125],
              [-95.66582031249999, 69.43896484375],
              [-95.6828125, 69.50029296875],
              [-95.7041015625, 69.538037109375],
              [-95.763623046875, 69.559619140625],
              [-95.80620117187499, 69.560498046875],
              [-95.8177734375, 69.540576171875],
              [-95.79833984375, 69.4998046875],
              [-95.81181640624999, 69.447021484375],
              [-95.85820312499999, 69.3822265625],
              [-95.89345703125, 69.3517578125],
              [-95.9560546875, 69.367138671875],
              [-95.9859375, 69.39189453125],
              [-95.9779296875, 69.43271484375],
              [-95.994775390625, 69.469677734375],
              [-95.97885742187499, 69.508837890625],
              [-95.93623046875, 69.567041015625],
              [-95.87583007812499, 69.606005859375],
              [-95.79775390625, 69.625732421875],
              [-95.706640625, 69.62431640625],
              [-95.602490234375, 69.601806640625],
              [-95.513671875, 69.5736328125],
            ],
          ],
          [
            [
              [-101.17172851562499, 69.3970703125],
              [-101.253515625, 69.3884765625],
              [-101.268505859375, 69.390576171875],
              [-101.26152343749999, 69.417822265625],
              [-101.26762695312499, 69.431494140625],
              [-101.289501953125, 69.441259765625],
              [-101.2177734375, 69.462939453125],
              [-101.20732421874999, 69.479833984375],
              [-101.230126953125, 69.492822265625],
              [-101.32846679687499, 69.517431640625],
              [-101.35649414062499, 69.539697265625],
              [-101.351318359375, 69.559228515625],
              [-101.312890625, 69.57607421875],
              [-101.244873046875, 69.57353515625],
              [-101.09833984375, 69.540771484375],
              [-101.03115234375, 69.495458984375],
              [-101.000634765625, 69.4619140625],
              [-101.04916992187499, 69.45693359375],
              [-101.086865234375, 69.443359375],
              [-101.126953125, 69.414697265625],
              [-101.17172851562499, 69.3970703125],
            ],
          ],
          [
            [
              [-101.8458984375, 68.586328125],
              [-101.88720703125, 68.5849609375],
              [-101.94462890624999, 68.60283203125],
              [-102.266357421875, 68.663671875],
              [-102.30815429687499, 68.681982421875],
              [-102.2705078125, 68.707568359375],
              [-102.1533203125, 68.740478515625],
              [-102.074365234375, 68.7740234375],
              [-102.01337890625, 68.825390625],
              [-101.828369140625, 68.798974609375],
              [-101.75932617187499, 68.774609375],
              [-101.73295898437499, 68.75341796875],
              [-101.721630859375, 68.72412109375],
              [-101.73203125, 68.6521484375],
              [-101.79428710937499, 68.636865234375],
              [-101.8458984375, 68.586328125],
            ],
          ],
          [
            [
              [-104.540673828125, 68.405908203125],
              [-104.59599609375, 68.402197265625],
              [-104.699462890625, 68.41826171875],
              [-104.851123046875, 68.453955078125],
              [-104.965234375, 68.491748046875],
              [-105.041748046875, 68.53154296875],
              [-105.05136718749999, 68.559033203125],
              [-104.99399414062499, 68.57421875],
              [-104.907275390625, 68.581787109375],
              [-104.700390625, 68.576708984375],
              [-104.602001953125, 68.5615234375],
              [-104.472119140625, 68.503515625],
              [-104.44453125, 68.470703125],
              [-104.440478515625, 68.44951171875],
              [-104.45712890624999, 68.43115234375],
              [-104.540673828125, 68.405908203125],
            ],
          ],
          [
            [
              [-107.899853515625, 67.401806640625],
              [-107.950244140625, 67.318212890625],
              [-107.96953125, 67.326025390625],
              [-108.003955078125, 67.36591796875],
              [-108.07333984374999, 67.38505859375],
              [-108.15224609375, 67.429443359375],
              [-108.151123046875, 67.5248046875],
              [-108.120849609375, 67.5681640625],
              [-108.12753906249999, 67.628564453125],
              [-108.048974609375, 67.664892578125],
              [-107.990869140625, 67.622119140625],
              [-107.97490234375, 67.549365234375],
              [-107.98935546874999, 67.51357421875],
              [-107.931787109375, 67.47646484375],
              [-107.90517578125, 67.467041015625],
              [-107.89096679687499, 67.43720703125],
              [-107.899853515625, 67.401806640625],
            ],
          ],
          [
            [
              [-109.16640625, 67.982373046875],
              [-109.05390625, 67.971875],
              [-108.9705078125, 67.979736328125],
              [-108.909619140625, 67.939404296875],
              [-108.88603515625, 67.89853515625],
              [-108.89384765625, 67.88447265625],
              [-108.920166015625, 67.87880859375],
              [-109.096240234375, 67.9240234375],
              [-109.1615234375, 67.951708984375],
              [-109.18359375, 67.975],
              [-109.16640625, 67.982373046875],
            ],
          ],
          [
            [
              [-108.092724609375, 67.00517578125],
              [-107.966455078125, 66.997265625],
              [-107.80551757812499, 66.998583984375],
              [-107.83334960937499, 66.921337890625],
              [-107.895166015625, 66.871875],
              [-107.94394531249999, 66.8578125],
              [-107.96513671875, 66.88486328125],
              [-108.059716796875, 66.946875],
              [-108.092724609375, 67.00517578125],
            ],
          ],
          [
            [
              [-109.32314453125, 67.990869140625],
              [-109.36083984375, 67.98759765625],
              [-109.49794921875, 68.047021484375],
              [-109.469140625, 68.097998046875],
              [-109.34169921875, 68.045849609375],
              [-109.32353515625, 68.013330078125],
              [-109.32314453125, 67.990869140625],
            ],
          ],
          [
            [
              [-139.043115234375, 69.576904296875],
              [-139.125732421875, 69.539306640625],
              [-139.256982421875, 69.578564453125],
              [-139.29140625, 69.5978515625],
              [-139.139599609375, 69.649609375],
              [-139.07265625, 69.64765625],
              [-138.93154296875, 69.616943359375],
              [-138.878857421875, 69.589697265625],
              [-139.043115234375, 69.576904296875],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-0 country",
        scalerank: 5,
        labelrank: 2,
        sovereignt: "Russia",
        sov_a3: "RUS",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        tlc: "1",
        admin: "Russia",
        adm0_a3: "RUS",
        geou_dif: 0,
        geounit: "Russia",
        gu_a3: "RUS",
        su_dif: 0,
        subunit: "Russia",
        su_a3: "RUS",
        brk_diff: 0,
        name: "Russia",
        name_long: "Russian Federation",
        brk_a3: "RUS",
        brk_name: "Russia",
        brk_group: null,
        abbrev: "Rus.",
        postal: "RUS",
        formal_en: "Russian Federation",
        formal_fr: null,
        name_ciawf: "Russia",
        note_adm0: null,
        note_brk: null,
        name_sort: "Russian Federation",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 5,
        mapcolor9: 7,
        mapcolor13: 7,
        pop_est: 144373535,
        pop_rank: 17,
        pop_year: 2019,
        gdp_md: 1699876,
        gdp_year: 2019,
        economy: "3. Emerging region: BRIC",
        income_grp: "3. Upper middle income",
        fips_10: "RS",
        iso_a2: "RU",
        iso_a2_eh: "RU",
        iso_a3: "RUS",
        iso_a3_eh: "RUS",
        iso_n3: "643",
        iso_n3_eh: "643",
        un_a3: "643",
        wb_a2: "RU",
        wb_a3: "RUS",
        woe_id: 23424936,
        woe_id_eh: 23424936,
        woe_note: "Exact WOE match as country",
        adm0_iso: "RUS",
        adm0_diff: null,
        adm0_tlc: "RUS",
        adm0_a3_us: "RUS",
        adm0_a3_fr: "RUS",
        adm0_a3_ru: "RUS",
        adm0_a3_es: "RUS",
        adm0_a3_cn: "RUS",
        adm0_a3_tw: "RUS",
        adm0_a3_in: "RUS",
        adm0_a3_np: "RUS",
        adm0_a3_pk: "RUS",
        adm0_a3_de: "RUS",
        adm0_a3_gb: "RUS",
        adm0_a3_br: "RUS",
        adm0_a3_il: "RUS",
        adm0_a3_ps: "RUS",
        adm0_a3_sa: "RUS",
        adm0_a3_eg: "RUS",
        adm0_a3_ma: "RUS",
        adm0_a3_pt: "RUS",
        adm0_a3_ar: "RUS",
        adm0_a3_jp: "RUS",
        adm0_a3_ko: "RUS",
        adm0_a3_vn: "RUS",
        adm0_a3_tr: "RUS",
        adm0_a3_id: "RUS",
        adm0_a3_pl: "RUS",
        adm0_a3_gr: "RUS",
        adm0_a3_it: "RUS",
        adm0_a3_nl: "RUS",
        adm0_a3_se: "RUS",
        adm0_a3_bd: "RUS",
        adm0_a3_ua: "RUS",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Eastern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 6,
        long_len: 18,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        min_zoom: 0,
        min_label: 1.7,
        max_label: 5.2,
        label_x: 44.686469,
        label_y: 58.249357,
        ne_id: 1159321201,
        wikidataid: "Q159",
        name_ar: "روسيا",
        name_bn: "রাশিয়া",
        name_de: "Russland",
        name_en: "Russia",
        name_es: "Rusia",
        name_fa: "روسیه",
        name_fr: "Russie",
        name_el: "Ρωσία",
        name_he: "רוסיה",
        name_hi: "रूस",
        name_hu: "Oroszország",
        name_id: "Rusia",
        name_it: "Russia",
        name_ja: "ロシア",
        name_ko: "러시아",
        name_nl: "Rusland",
        name_pl: "Rosja",
        name_pt: "Rússia",
        name_ru: "Россия",
        name_sv: "Ryssland",
        name_tr: "Rusya",
        name_uk: "Росія",
        name_ur: "روس",
        name_vi: "Nga",
        name_zh: "俄罗斯",
        name_zht: "俄羅斯",
        fclass_iso: "Admin-0 country",
        tlc_diff: null,
        fclass_tlc: "Admin-0 country",
        fclass_us: null,
        fclass_fr: null,
        fclass_ru: null,
        fclass_es: null,
        fclass_cn: null,
        fclass_tw: null,
        fclass_in: null,
        fclass_np: null,
        fclass_pk: null,
        fclass_de: null,
        fclass_gb: null,
        fclass_br: null,
        fclass_il: null,
        fclass_ps: null,
        fclass_sa: null,
        fclass_eg: null,
        fclass_ma: null,
        fclass_pt: null,
        fclass_ar: null,
        fclass_jp: null,
        fclass_ko: null,
        fclass_vn: null,
        fclass_tr: null,
        fclass_id: null,
        fclass_pl: null,
        fclass_gr: null,
        fclass_it: null,
        fclass_nl: null,
        fclass_se: null,
        fclass_bd: null,
        fclass_ua: null,
        filename: "RUS.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [145.88154296875, 43.459521484374996],
              [145.89560546875003, 43.454541015625],
              [145.91386718750005, 43.455371093749996],
              [145.93115234375, 43.45703125],
              [145.94111328125, 43.445458984374994],
              [145.9435546875, 43.42646484375],
              [145.93115234375, 43.425634765625],
              [145.9072265625, 43.422314453125],
              [145.89394531250002, 43.419824218749994],
              [145.88652343750005, 43.433056640625],
              [145.88154296875, 43.443798828125],
              [145.869140625, 43.450439453125],
              [145.869140625, 43.457861328125],
              [145.88154296875, 43.459521484374996],
            ],
          ],
          [
            [
              [146.35878906250002, 43.625390625],
              [146.33232421875005, 43.619921875],
              [146.28818359375003, 43.625390625],
              [146.27382812500002, 43.629833984375],
              [146.28369140625, 43.638623046875],
              [146.31015625000003, 43.65185546875],
              [146.33330078125005, 43.6474609375],
              [146.3498046875, 43.644140625],
              [146.35878906250002, 43.625390625],
            ],
          ],
          [
            [
              [146.04560546875, 43.409326171874994],
              [146.03232421875003, 43.407128906249994],
              [146.02802734375, 43.420361328125],
              [146.04892578125003, 43.43359375],
              [146.08857421875, 43.449023437499996],
              [146.10078125, 43.440185546875],
              [146.08632812500002, 43.42919921875],
              [146.06992187500003, 43.421484375],
              [146.04560546875, 43.409326171874994],
            ],
          ],
          [
            [
              [137.17861328125002, 55.100439453125],
              [137.05527343750003, 54.9267578125],
              [136.96943359375, 54.923974609375],
              [136.90273437500002, 54.96064453125],
              [136.76513671875, 54.946044921875],
              [136.71464843750005, 54.95615234375],
              [136.79531250000002, 55.009375],
              [136.99570312500003, 55.092724609375],
              [137.07753906250002, 55.091748046875],
              [137.15605468750005, 55.1078125],
              [137.17861328125002, 55.100439453125],
            ],
          ],
          [
            [
              [150.58994140625003, 59.01875],
              [150.51113281250002, 59.007421875],
              [150.47177734375003, 59.034765625],
              [150.47021484375, 59.054052734375],
              [150.59248046875, 59.097216796875],
              [150.6662109375, 59.16015625],
              [150.71269531250005, 59.122460937499994],
              [150.727734375, 59.09521484375],
              [150.58994140625003, 59.01875],
            ],
          ],
          [
            [
              [120.26132812500003, 73.08984375],
              [120.00791015625003, 73.044873046875],
              [119.79208984375003, 73.04541015625],
              [119.64042968749999, 73.12431640625],
              [119.76191406250001, 73.15546875],
              [119.96445312500003, 73.16767578125],
              [120.07851562500002, 73.15673828125],
              [120.23681640625, 73.107275390625],
              [120.26132812500003, 73.08984375],
            ],
          ],
          [
            [
              [124.54296875, 73.85009765625],
              [124.48173828124999, 73.847900390625],
              [124.36640625000001, 73.874609375],
              [124.33574218749999, 73.910302734375],
              [124.33652343750003, 73.928369140625],
              [124.4296875, 73.943017578125],
              [124.54765624999999, 73.933837890625],
              [124.63691406250001, 73.900390625],
              [124.65292968750003, 73.888037109375],
              [124.54296875, 73.85009765625],
            ],
          ],
          [
            [
              [106.27041015625002, 78.206201171875],
              [106.15107421875001, 78.1986328125],
              [106.02363281250001, 78.2201171875],
              [106.05839843749999, 78.2646484375],
              [106.3505859375, 78.272607421875],
              [106.45683593749999, 78.3400390625],
              [106.64042968749999, 78.33623046875],
              [106.69121093749999, 78.316650390625],
              [106.71962890625002, 78.294189453125],
              [106.71894531250001, 78.264990234375],
              [106.67910156250002, 78.264990234375],
              [106.50468749999999, 78.261669921875],
              [106.47246093749999, 78.24501953125],
              [106.27041015625002, 78.206201171875],
            ],
          ],
          [
            [
              [107.41474609375001, 77.24267578125],
              [107.30224609375, 77.24150390625],
              [107.26953125, 77.289013671875],
              [107.36640625000001, 77.346630859375],
              [107.48642578125003, 77.347119140625],
              [107.59365234375002, 77.330029296875],
              [107.62929687500002, 77.319677734375],
              [107.66455078125, 77.2998046875],
              [107.67949218749999, 77.26826171875],
              [107.41474609375001, 77.24267578125],
            ],
          ],
          [
            [
              [97.58837890625, 76.599365234375],
              [97.53525390625003, 76.584423828125],
              [97.43037109375001, 76.59072265625],
              [97.34169921875002, 76.628857421875],
              [97.31035156249999, 76.689599609375],
              [97.38164062499999, 76.706689453125],
              [97.58837890625, 76.599365234375],
            ],
          ],
          [
            [
              [96.85390625000002, 76.199169921875],
              [96.7978515625, 76.188427734375],
              [96.75449218750003, 76.195751953125],
              [96.73935546875003, 76.20693359375],
              [96.740234375, 76.257861328125],
              [96.83291015625002, 76.324169921875],
              [96.83525390624999, 76.34482421875],
              [96.8779296875, 76.355224609375],
              [96.990234375, 76.343408203125],
              [97.04531250000002, 76.315380859375],
              [97.05302734374999, 76.302587890625],
              [96.97421875000003, 76.2365234375],
              [96.85390625000002, 76.199169921875],
            ],
          ],
          [
            [
              [100.13593750000001, 79.614208984375],
              [99.91542968750002, 79.601611328125],
              [99.94228515625002, 79.671435546875],
              [99.95576171875001, 79.69033203125],
              [100.068359375, 79.701025390625],
              [100.14150390625002, 79.68369140625],
              [100.30029296875, 79.670263671875],
              [100.13593750000001, 79.614208984375],
            ],
          ],
          [
            [
              [76.75605468750001, 73.44580078125],
              [76.659375, 73.439501953125],
              [76.23447265625003, 73.476220703125],
              [76.08310546875003, 73.523486328125],
              [76.13955078125002, 73.554296875],
              [76.25068359375001, 73.5552734375],
              [76.75605468750001, 73.44580078125],
            ],
          ],
          [
            [
              [82.7099609375, 74.090869140625],
              [82.61279296875, 74.0564453125],
              [82.478125, 74.07578125],
              [82.38154296875001, 74.09921875],
              [82.32939453124999, 74.131103515625],
              [82.38242187500003, 74.149267578125],
              [82.52558593750001, 74.16142578125],
              [82.61103515625001, 74.14853515625],
              [82.68896484375, 74.11123046875],
              [82.7099609375, 74.090869140625],
            ],
          ],
          [
            [
              [84.75898437500001, 74.459423828125],
              [84.71044921875, 74.3998046875],
              [84.42890625000001, 74.430322265625],
              [84.38945312499999, 74.454443359375],
              [84.54033203124999, 74.4904296875],
              [84.67988281250001, 74.512353515625],
              [84.87285156249999, 74.51552734375],
              [84.75898437500001, 74.459423828125],
            ],
          ],
          [
            [
              [86.653125, 74.981298828125],
              [86.73710937499999, 74.96298828125],
              [87.00058593750003, 74.991943359375],
              [87.05214843750002, 74.982568359375],
              [87.12431640624999, 74.939892578125],
              [87.01171875, 74.8619140625],
              [86.92714843750002, 74.83076171875],
              [86.69199218750003, 74.848291015625],
              [86.39052734375002, 74.85087890625],
              [86.25859374999999, 74.893505859375],
              [86.33066406250003, 74.93896484375],
              [86.50449218750003, 74.965966796875],
              [86.60546875, 74.992822265625],
              [86.653125, 74.981298828125],
            ],
          ],
          [
            [
              [59.313085937500006, 81.305224609375],
              [59.09697265625002, 81.29228515625],
              [58.71904296875002, 81.313525390625],
              [58.61015625000002, 81.337255859375],
              [58.63447265625001, 81.3603515625],
              [58.88056640625001, 81.391845703125],
              [59.075, 81.397705078125],
              [59.280859375, 81.36611328125],
              [59.374609375, 81.325048828125],
              [59.313085937500006, 81.305224609375],
            ],
          ],
          [
            [
              [-179.79853515625, 68.9404296875],
              [-179.59541015625, 68.906494140625],
              [-179.514501953125, 68.917138671875],
              [-179.470849609375, 68.91240234375],
              [-179.35595703125, 68.852978515625],
              [-179.279296875, 68.8251953125],
              [-178.873876953125, 68.7541015625],
              [-178.689306640625, 68.675146484375],
              [-178.538525390625, 68.58564453125],
              [-178.613671875, 68.603076171875],
              [-178.75146484375, 68.66044921875],
              [-178.7365234375, 68.593017578125],
              [-178.692626953125, 68.54599609375],
              [-178.47392578125, 68.5017578125],
              [-178.244482421875, 68.466650390625],
              [-178.0974609375, 68.4248046875],
              [-178.048681640625, 68.388427734375],
              [-178.018701171875, 68.32275390625],
              [-178.055810546875, 68.264892578125],
              [-177.922412109375, 68.2865234375],
              [-177.79677734375, 68.33798828125],
              [-177.86181640625, 68.37822265625],
              [-178.284521484375, 68.5185546875],
              [-178.373046875, 68.565673828125],
              [-178.249853515625, 68.54140625],
              [-177.683203125, 68.36279296875],
              [-177.52724609375, 68.294384765625],
              [-177.593212890625, 68.28115234375],
              [-177.63935546875, 68.2412109375],
              [-177.589208984375, 68.22421875],
              [-177.5208984375, 68.236865234375],
              [-177.40751953125, 68.245166015625],
              [-177.297412109375, 68.222509765625],
              [-177.171826171875, 68.174658203125],
              [-176.907275390625, 68.119140625],
              [-175.34521484375, 67.678076171875],
              [-175.30986328125, 67.60205078125],
              [-175.26591796875, 67.56650390625],
              [-175.23955078125, 67.52109375],
              [-175.23251953125, 67.4466796875],
              [-175.37470703125, 67.357373046875],
              [-175.155078125, 67.365380859375],
              [-175.122802734375, 67.376953125],
              [-175.065625, 67.413427734375],
              [-175.002685546875, 67.4375],
              [-174.91806640625, 67.407568359375],
              [-174.849853515625, 67.348876953125],
              [-174.869921875, 67.268505859375],
              [-174.930419921875, 67.203466796875],
              [-174.938134765625, 67.093017578125],
              [-174.88505859375, 67.000244140625],
              [-174.8287109375, 66.961376953125],
              [-174.783642578125, 66.916796875],
              [-174.77119140625, 66.784326171875],
              [-174.8701171875, 66.72490234375],
              [-174.92490234375, 66.62314453125],
              [-174.8642578125, 66.613134765625],
              [-174.674658203125, 66.60341796875],
              [-174.612451171875, 66.585400390625],
              [-174.503759765625, 66.537939453125],
              [-174.477734375, 66.4921875],
              [-174.453759765625, 66.4298828125],
              [-174.418701171875, 66.37197265625],
              [-174.394091796875, 66.34423828125],
              [-174.366064453125, 66.34833984375],
              [-174.256982421875, 66.428466796875],
              [-174.206005859375, 66.45234375],
              [-174.084765625, 66.473095703125],
              [-174.017724609375, 66.38251953125],
              [-174.0650390625, 66.22958984375],
              [-174.025439453125, 66.2296875],
              [-173.994482421875, 66.24580078125],
              [-173.95546875, 66.286767578125],
              [-173.899951171875, 66.310498046875],
              [-173.83203125, 66.366064453125],
              [-173.773974609375, 66.43466796875],
              [-173.842529296875, 66.48828125],
              [-173.920947265625, 66.52177734375],
              [-174.10185546875, 66.540625],
              [-174.196337890625, 66.580712890625],
              [-174.231591796875, 66.631884765625],
              [-174.139599609375, 66.65263671875],
              [-174.060595703125, 66.689794921875],
              [-174.005517578125, 66.77861328125],
              [-174.01884765625, 66.827392578125],
              [-174.041015625, 66.87548828125],
              [-174.08642578125, 66.94287109375],
              [-174.154345703125, 66.98203125],
              [-174.283544921875, 67.0015625],
              [-174.341845703125, 67.03974609375],
              [-174.430908203125, 67.037646484375],
              [-174.5189453125, 67.049072265625],
              [-174.5544921875, 67.063037109375],
              [-174.55009765625, 67.090625],
              [-174.447607421875, 67.103125],
              [-173.884033203125, 67.1064453125],
              [-173.6796875, 67.144775390625],
              [-173.586572265625, 67.132763671875],
              [-173.493994140625, 67.10517578125],
              [-173.1578125, 67.069091796875],
              [-173.167626953125, 67.05224609375],
              [-173.224169921875, 67.035107421875],
              [-173.32353515625, 66.954833984375],
              [-173.34306640625, 66.909228515625],
              [-173.34736328125, 66.8513671875],
              [-173.258935546875, 66.840087890625],
              [-173.175390625, 66.864599609375],
              [-173.216162109375, 66.91123046875],
              [-173.228271484375, 66.9685546875],
              [-173.193017578125, 66.993603515625],
              [-173.146826171875, 66.998974609375],
              [-173.05849609375, 66.955859375],
              [-172.96259765625, 66.942138671875],
              [-172.640576171875, 66.925],
              [-172.549365234375, 66.930517578125],
              [-172.5201171875, 66.952490234375],
              [-172.582958984375, 66.97783203125],
              [-173.001904296875, 67.033984375],
              [-173.00751953125, 67.064892578125],
              [-172.621044921875, 67.026806640625],
              [-172.447314453125, 66.991748046875],
              [-172.27392578125, 66.965576171875],
              [-172.031494140625, 66.973291015625],
              [-171.795556640625, 66.93173828125],
              [-171.569580078125, 66.818701171875],
              [-171.360498046875, 66.6767578125],
              [-171.149267578125, 66.592724609375],
              [-170.92666015625, 66.529736328125],
              [-170.5556640625, 66.3572265625],
              [-170.509521484375, 66.34365234375],
              [-170.473095703125, 66.320263671875],
              [-170.542822265625, 66.291064453125],
              [-170.604443359375, 66.24892578125],
              [-170.48330078125, 66.278076171875],
              [-170.3611328125, 66.297900390625],
              [-170.301220703125, 66.29404296875],
              [-170.24697265625, 66.271875],
              [-170.21162109375, 66.23642578125],
              [-170.191943359375, 66.20126953125],
              [-170.2439453125, 66.169287109375],
              [-169.888818359375, 66.1634765625],
              [-169.777880859375, 66.143115234375],
              [-169.729150390625, 66.05810546875],
              [-169.831689453125, 65.99892578125],
              [-169.89169921875, 66.006103515625],
              [-169.94931640625, 66.031005859375],
              [-170.00380859375, 66.03349609375],
              [-170.159423828125, 66.008056640625],
              [-170.401025390625, 65.928515625],
              [-170.540673828125, 65.8654296875],
              [-170.563037109375, 65.823583984375],
              [-170.54140625, 65.71025390625],
              [-170.560986328125, 65.65625],
              [-170.66630859375, 65.621533203125],
              [-170.896875, 65.642626953125],
              [-171.00146484375, 65.664892578125],
              [-171.118994140625, 65.69501953125],
              [-171.23203125, 65.736865234375],
              [-171.37685546875, 65.803955078125],
              [-171.421533203125, 65.8103515625],
              [-171.451171875, 65.79423828125],
              [-171.401708984375, 65.7517578125],
              [-171.30322265625, 65.698486328125],
              [-171.134423828125, 65.628076171875],
              [-171.054248046875, 65.549951171875],
              [-171.105859375, 65.51103515625],
              [-171.169970703125, 65.502099609375],
              [-171.216015625, 65.502783203125],
              [-171.36376953125, 65.527197265625],
              [-171.466259765625, 65.53310546875],
              [-171.790380859375, 65.51044921875],
              [-171.90712890625, 65.495947265625],
              [-171.94716796875, 65.507958984375],
              [-171.957177734375, 65.54208984375],
              [-172.131494140625, 65.566943359375],
              [-172.23388671875, 65.570458984375],
              [-172.282275390625, 65.58232421875],
              [-172.322265625, 65.617529296875],
              [-172.435693359375, 65.66962890625],
              [-172.60771484375, 65.6900390625],
              [-172.719189453125, 65.692431640625],
              [-172.78330078125, 65.6810546875],
              [-172.55654296875, 65.61201171875],
              [-172.353955078125, 65.49599609375],
              [-172.3919921875, 65.474560546875],
              [-172.4177734375, 65.449560546875],
              [-172.305712890625, 65.447802734375],
              [-172.2328125, 65.455712890625],
              [-172.211572265625, 65.4251953125],
              [-172.269873046875, 65.302734375],
              [-172.30927734375, 65.275634765625],
              [-172.6619140625, 65.24853515625],
              [-172.57314453125, 65.22822265625],
              [-172.482080078125, 65.221875],
              [-172.3787109375, 65.226708984375],
              [-172.28603515625, 65.205712890625],
              [-172.223681640625, 65.1287109375],
              [-172.21318359375, 65.04814453125],
              [-172.304345703125, 65.0021484375],
              [-172.39873046875, 64.96474609375],
              [-172.592822265625, 64.907958984375],
              [-172.79248046875, 64.88291015625],
              [-172.89736328125, 64.889208984375],
              [-172.99912109375, 64.876611328125],
              [-173.0662109375, 64.84716796875],
              [-173.085791015625, 64.817333984375],
              [-172.998046875, 64.837109375],
              [-172.896875, 64.82607421875],
              [-172.80107421875, 64.79052734375],
              [-172.811572265625, 64.761181640625],
              [-172.902587890625, 64.72919921875],
              [-172.9240234375, 64.704931640625],
              [-172.8890625, 64.664013671875],
              [-172.90087890625, 64.628857421875],
              [-172.854150390625, 64.609912109375],
              [-172.746875, 64.603271484375],
              [-172.61611328125, 64.577880859375],
              [-172.48740234375, 64.544189453125],
              [-172.43662109375, 64.51533203125],
              [-172.39384765625, 64.474658203125],
              [-172.378759765625, 64.43154296875],
              [-172.40146484375, 64.413916015625],
              [-172.694677734375, 64.407080078125],
              [-172.73916015625, 64.412255859375],
              [-172.75595703125, 64.4599609375],
              [-172.79150390625, 64.49892578125],
              [-172.903173828125, 64.52607421875],
              [-172.9490234375, 64.507373046875],
              [-172.915869140625, 64.36943359375],
              [-172.96005859375, 64.327685546875],
              [-173.009130859375, 64.2974609375],
              [-173.157421875, 64.279736328125],
              [-173.27548828125, 64.2896484375],
              [-173.37568359375, 64.3548828125],
              [-173.375537109375, 64.410400390625],
              [-173.309228515625, 64.44267578125],
              [-173.309326171875, 64.487451171875],
              [-173.327490234375, 64.53955078125],
              [-173.395654296875, 64.47900390625],
              [-173.474951171875, 64.42861328125],
              [-173.60361328125, 64.365478515625],
              [-173.665966796875, 64.35732421875],
              [-173.729736328125, 64.364501953125],
              [-173.8978515625, 64.409716796875],
              [-174.0013671875, 64.448974609375],
              [-174.204833984375, 64.577783203125],
              [-174.318017578125, 64.637646484375],
              [-174.570556640625, 64.7177734375],
              [-174.83046875, 64.7759765625],
              [-175.03603515625, 64.813671875],
              [-175.145849609375, 64.80927734375],
              [-175.255908203125, 64.793994140625],
              [-175.3951171875, 64.802392578125],
              [-175.442138671875, 64.81669921875],
              [-175.483203125, 64.848583984375],
              [-175.520654296875, 64.86708984375],
              [-175.715869140625, 64.94609375],
              [-175.853857421875, 65.01083984375],
              [-175.85947265625, 65.05419921875],
              [-175.830224609375, 65.105517578125],
              [-175.85615234375, 65.2328125],
              [-175.92294921875, 65.352490234375],
              [-176.09326171875, 65.471044921875],
              [-176.5474609375, 65.54755859375],
              [-176.922119140625, 65.6013671875],
              [-177.05625, 65.613623046875],
              [-177.175244140625, 65.60166015625],
              [-177.48876953125, 65.5037109375],
              [-177.6986328125, 65.489697265625],
              [-178.310205078125, 65.48486328125],
              [-178.4125, 65.495556640625],
              [-178.504638671875, 65.53720703125],
              [-178.525927734375, 65.593017578125],
              [-178.49931640625, 65.696630859375],
              [-178.50234375, 65.7404296875],
              [-178.526220703125, 65.755224609375],
              [-178.558544921875, 65.75400390625],
              [-178.679150390625, 65.795361328125],
              [-178.791064453125, 65.86474609375],
              [-178.879345703125, 65.936474609375],
              [-178.9390625, 66.032763671875],
              [-178.858251953125, 66.037548828125],
              [-178.746728515625, 66.013671875],
              [-178.73056640625, 66.037255859375],
              [-178.693798828125, 66.12421875],
              [-178.616259765625, 66.166015625],
              [-178.5865234375, 66.1984375],
              [-178.534130859375, 66.316552734375],
              [-178.5265625, 66.4015625],
              [-178.615771484375, 66.35517578125],
              [-178.752783203125, 66.237255859375],
              [-178.820849609375, 66.202685546875],
              [-178.868115234375, 66.187060546875],
              [-178.91552734375, 66.179931640625],
              [-179.026123046875, 66.203515625],
              [-179.105078125, 66.23193359375],
              [-179.106884765625, 66.34609375],
              [-179.143408203125, 66.375048828125],
              [-179.178369140625, 66.3533203125],
              [-179.19267578125, 66.312548828125],
              [-179.2931640625, 66.305078125],
              [-179.34013671875, 66.2875],
              [-179.3162109375, 66.21982421875],
              [-179.327197265625, 66.16259765625],
              [-179.42265625, 66.141064453125],
              [-179.616162109375, 66.127880859375],
              [-179.68330078125, 66.184130859375],
              [-179.740869140625, 66.10576171875],
              [-179.783642578125, 66.01796875],
              [-179.789697265625, 65.90087890625],
              [-179.7283203125, 65.80380859375],
              [-179.640625, 65.757568359375],
              [-179.449072265625, 65.687841796875],
              [-179.365966796875, 65.638623046875],
              [-179.344384765625, 65.575244140625],
              [-179.352099609375, 65.516748046875],
              [-179.45166015625, 65.4453125],
              [-179.5193359375, 65.386279296875],
              [-179.63515625, 65.244140625],
              [-179.70458984375, 65.18720703125],
              [-180, 65.067236328125],
              [-180, 65.311962890625],
              [-180, 65.556787109375],
              [-180, 65.8015625],
              [-180, 66.0462890625],
              [-180, 66.291064453125],
              [-180, 66.53583984375],
              [-180, 66.78056640625],
              [-180, 67.025341796875],
              [-180, 67.2701171875],
              [-180, 67.51484375],
              [-180, 67.759619140625],
              [-180, 68.00439453125],
              [-180, 68.24912109375],
              [-180, 68.493896484375],
              [-180, 68.738671875],
              [-179.999951171875, 68.983447265625],
              [-179.79853515625, 68.9404296875],
            ],
          ],
          [
            [
              [130.68730468750005, 42.302539062499996],
              [130.65800781250005, 42.327783203124994],
              [130.6515625, 42.372509765625],
              [130.61796875000005, 42.415625],
              [130.55410156250002, 42.47470703125],
              [130.52695312500003, 42.535400390625],
              [130.58447265625, 42.567333984375],
              [130.57656250000002, 42.6232421875],
              [130.52060546875003, 42.67431640625],
              [130.43925781250005, 42.685546875],
              [130.419921875, 42.699853515624994],
              [130.4248046875, 42.72705078125],
              [130.45273437500003, 42.755419921874996],
              [130.49296875000005, 42.779101562499996],
              [130.57724609375003, 42.81162109375],
              [130.72246093750005, 42.835839843749994],
              [130.80332031250003, 42.856835937499994],
              [130.86855468750002, 42.863330078124996],
              [130.94287109375, 42.8517578125],
              [131.00556640625, 42.883105468749996],
              [131.0685546875, 42.902246093749994],
              [131.08349609375, 42.956298828125],
              [131.0861328125, 43.0380859375],
              [131.10898437500003, 43.062451171875],
              [131.13554687500005, 43.097607421875],
              [131.17558593750005, 43.1421875],
              [131.2119140625, 43.257763671875],
              [131.23935546875003, 43.337646484375],
              [131.25732421875, 43.378076171874994],
              [131.26181640625003, 43.433056640625],
              [131.24394531250005, 43.469042968749996],
              [131.2091796875, 43.4904296875],
              [131.18242187500005, 43.50556640625],
              [131.18007812500002, 43.56708984375],
              [131.18359375, 43.65087890625],
              [131.17421875000002, 43.704736328124994],
              [131.21328125000002, 44.0029296875],
              [131.25527343750002, 44.071582031249996],
              [131.12578125000005, 44.469189453125],
              [131.0869140625, 44.595654296875],
              [131.06064453125003, 44.65966796875],
              [131.00390625, 44.753222656249996],
              [130.9677734375, 44.799951171874994],
              [130.981640625, 44.8443359375],
              [131.03300781250005, 44.8888671875],
              [131.08232421875005, 44.910009765625],
              [131.22792968750002, 44.920166015625],
              [131.26826171875, 44.9361328125],
              [131.446875, 44.984033203124994],
              [131.4875, 45.013134765625],
              [131.57871093750003, 45.083642578124994],
              [131.61396484375, 45.136572265625],
              [131.65400390625, 45.205371093749996],
              [131.74208984375002, 45.242626953125],
              [131.794921875, 45.3052734375],
              [131.85185546875005, 45.32685546875],
              [131.90927734375003, 45.273730468749996],
              [131.9775390625, 45.243994140625],
              [132.0673828125, 45.225976562499994],
              [132.18134765625, 45.203271484374994],
              [132.36298828125, 45.159960937499996],
              [132.5490234375, 45.122802734375],
              [132.665625, 45.093701171875],
              [132.72314453125, 45.08056640625],
              [132.83867187500005, 45.0611328125],
              [132.88876953125003, 45.046044921874994],
              [132.93603515625, 45.029931640624994],
              [133.01171875, 45.074560546875],
              [133.1134765625, 45.130712890625],
              [133.096875, 45.220458984375],
              [133.11337890625003, 45.321435546874994],
              [133.18603515625, 45.49482421875],
              [133.26699218750002, 45.545263671875],
              [133.3095703125, 45.553076171875],
              [133.35546875, 45.572216796875],
              [133.43642578125002, 45.6046875],
              [133.465625, 45.651220703125],
              [133.44912109375002, 45.705078125],
              [133.47578125, 45.757666015625],
              [133.48466796875005, 45.810449218749994],
              [133.51308593750002, 45.878808593749994],
              [133.55117187500002, 45.897802734375],
              [133.60800781250003, 45.9203125],
              [133.64785156250002, 45.955224609374994],
              [133.6857421875, 46.008935546874994],
              [133.7111328125, 46.069628906249996],
              [133.70068359375, 46.13974609375],
              [133.7501953125, 46.1859375],
              [133.83281250000005, 46.224267578124994],
              [133.861328125, 46.24775390625],
              [133.87480468750005, 46.30908203125],
              [133.88027343750002, 46.33603515625],
              [133.90273437500002, 46.366943359375],
              [133.88671875, 46.430566406249994],
              [133.86660156250002, 46.49912109375],
              [133.95751953125, 46.6142578125],
              [134.02265625, 46.713183593749996],
              [134.03857421875, 46.858154296875],
              [134.04599609375003, 46.881982421874994],
              [134.07138671875003, 46.95078125],
              [134.08642578125, 46.978125],
              [134.1369140625, 47.068994140624994],
              [134.2021484375, 47.128076171874994],
              [134.18925781250005, 47.194238281249994],
              [134.16298828125002, 47.258740234375],
              [134.16767578125, 47.302197265625],
              [134.22519531250003, 47.35263671875],
              [134.26005859375005, 47.377734375],
              [134.29082031250005, 47.41357421875],
              [134.33945312500003, 47.429492187499996],
              [134.38251953125, 47.438232421875],
              [134.48349609375003, 47.44736328125],
              [134.54189453125002, 47.48515625],
              [134.59619140625, 47.523876953125],
              [134.69580078125, 47.624853515625],
              [134.728125, 47.684472656249994],
              [134.75234375000002, 47.7154296875],
              [134.69863281250002, 47.801416015624994],
              [134.65029296875002, 47.874267578125],
              [134.59130859375, 47.9751953125],
              [134.56601562500003, 48.022509765624996],
              [134.60537109375002, 48.082910156249994],
              [134.64726562500005, 48.120166015624996],
              [134.66933593750002, 48.1533203125],
              [134.680859375, 48.21044921875],
              [134.665234375, 48.25390625],
              [134.56357421875003, 48.321728515625],
              [134.45615234375003, 48.355322265625],
              [134.3349609375, 48.36884765625],
              [134.29335937500002, 48.3734375],
              [134.20585937500005, 48.359912109374996],
              [133.84218750000002, 48.273730468749996],
              [133.67177734375002, 48.20771484375],
              [133.5732421875, 48.1330078125],
              [133.46835937500003, 48.09716796875],
              [133.30117187500002, 48.101513671875],
              [133.14404296875, 48.1056640625],
              [133.02011718750003, 48.064404296875],
              [132.8771484375, 47.9791015625],
              [132.77285156250002, 47.940087890624994],
              [132.7072265625, 47.947265625],
              [132.6369140625, 47.890087890625],
              [132.56191406250002, 47.768505859375],
              [132.47626953125, 47.714990234374994],
              [132.38017578125005, 47.7294921875],
              [132.14980468750002, 47.71796875],
              [131.78525390625003, 47.680517578125],
              [131.55673828125003, 47.68203125],
              [131.46425781250002, 47.722607421875],
              [131.3193359375, 47.727832031249996],
              [131.121875, 47.69765625],
              [131.00273437500005, 47.691455078124996],
              [130.9619140625, 47.709326171875],
              [130.9328125, 47.759814453124996],
              [130.91542968750002, 47.842919921874994],
              [130.8486328125, 47.929443359375],
              [130.7326171875, 48.01923828125],
              [130.712109375, 48.12763671875],
              [130.78720703125003, 48.25458984375],
              [130.80429687500003, 48.34150390625],
              [130.76347656250005, 48.388427734375],
              [130.746875, 48.43037109375],
              [130.6591796875, 48.4833984375],
              [130.59726562500003, 48.574658203125],
              [130.55214843750002, 48.602490234375],
              [130.565625, 48.680126953125],
              [130.6171875, 48.773193359375],
              [130.553125, 48.861181640625],
              [130.35527343750005, 48.866357421874994],
              [130.19599609375, 48.891650390624996],
              [130.037109375, 48.972265625],
              [129.79257812500003, 49.198876953124994],
              [129.67109375, 49.278515625],
              [129.59140625000003, 49.286669921874996],
              [129.53369140625, 49.3234375],
              [129.49814453125003, 49.388818359374994],
              [129.44072265625005, 49.389453125],
              [129.38466796875002, 49.389453125],
              [129.35009765625, 49.362353515624996],
              [129.30986328125005, 49.353857421875],
              [129.24843750000002, 49.378662109375],
              [129.18515625000003, 49.381396484374996],
              [129.1201171875, 49.362060546875],
              [129.06513671875, 49.374658203124994],
              [129.02031250000005, 49.419238281249996],
              [128.93828125000005, 49.44892578125],
              [128.8193359375, 49.463769531249994],
              [128.77031250000005, 49.4947265625],
              [128.791015625, 49.541845703125],
              [128.76904296875, 49.576953125],
              [128.70400390625002, 49.600146484374996],
              [128.52675781250002, 49.59423828125],
              [128.23710937500005, 49.55927734375],
              [127.99960937500003, 49.568603515625],
              [127.81425781250005, 49.622119140624996],
              [127.71113281250001, 49.671533203124994],
              [127.69013671875001, 49.716748046875],
              [127.63671875, 49.760205078125],
              [127.55078125, 49.801806640624996],
              [127.50244140625, 49.8734375],
              [127.49179687500003, 49.975048828125],
              [127.51230468750003, 50.071679687499994],
              [127.59023437500002, 50.208984375],
              [127.39531250000005, 50.298583984375],
              [127.33720703125005, 50.350146484374996],
              [127.35117187500003, 50.393603515624996],
              [127.3408203125, 50.428076171875],
              [127.30605468750002, 50.453515625],
              [127.30820312500003, 50.494189453124996],
              [127.34716796875, 50.550097656249996],
              [127.346875, 50.621337890625],
              [127.30703125000002, 50.707958984375],
              [127.1982421875, 50.829443359375],
              [127.02041015625002, 50.985888671874996],
              [126.9248046875, 51.100146484374996],
              [126.91152343750002, 51.172314453125],
              [126.8876953125, 51.230126953124994],
              [126.85439453125002, 51.261376953124994],
              [126.83378906249999, 51.314892578125],
              [126.84775390625003, 51.374169921874994],
              [126.82734375000001, 51.412255859374994],
              [126.8017578125, 51.448046875],
              [126.80546874999999, 51.505664062499996],
              [126.77451171875003, 51.545068359374994],
              [126.70917968750001, 51.566308593749994],
              [126.68867187500001, 51.609912109374996],
              [126.70078125000003, 51.70302734375],
              [126.65371093750002, 51.781298828124996],
              [126.51054687499999, 51.925830078124996],
              [126.46806640624999, 52.031298828124996],
              [126.45556640625, 52.12646484375],
              [126.39482421874999, 52.172998046875],
              [126.39150390625002, 52.214501953125],
              [126.38349609375001, 52.2865234375],
              [126.34628906250003, 52.30625],
              [126.32421875, 52.331640625],
              [126.34169921875002, 52.36201171875],
              [126.31289062500002, 52.399755859375],
              [126.23759765624999, 52.44482421875],
              [126.20292968749999, 52.483837890625],
              [126.19443359375003, 52.519140625],
              [126.15664062500002, 52.546630859375],
              [126.0458984375, 52.57333984375],
              [126.01601562500002, 52.610205078125],
              [126.02324218749999, 52.643017578125],
              [126.04707031250001, 52.673486328125],
              [126.06015625000003, 52.6919921875],
              [126.05605468750002, 52.715869140624996],
              [126.04814453124999, 52.739453125],
              [126.00429687500002, 52.767871093749996],
              [125.94160156250001, 52.80068359375],
              [125.871875, 52.871533203125],
              [125.78281250000003, 52.89072265625],
              [125.728125, 52.89072265625],
              [125.68076171875003, 52.930810546875],
              [125.6953125, 52.956298828125],
              [125.69169921874999, 53.003710937499996],
              [125.64902343750003, 53.042285156249996],
              [125.59599609374999, 53.057470703125],
              [125.54599609375003, 53.047607421875],
              [125.42246093750003, 53.083740234375],
              [125.2255859375, 53.165820312499996],
              [125.075, 53.203662109374996],
              [124.97089843750001, 53.197314453124996],
              [124.90664062500002, 53.17265625],
              [124.88212890624999, 53.129736328125],
              [124.81230468749999, 53.133837890624996],
              [124.63984375000001, 53.21064453125],
              [124.46591796875003, 53.229638671875],
              [124.369140625, 53.270947265625],
              [124.29140625000002, 53.340869140624996],
              [124.21992187500001, 53.3701171875],
              [124.154296875, 53.358691406249996],
              [123.99472656250003, 53.405615234375],
              [123.74091796875001, 53.510986328125],
              [123.60781250000002, 53.546533203125],
              [123.55976562500001, 53.526660156249996],
              [123.53476562500003, 53.52646484375],
              [123.48945312500001, 53.529443359375],
              [123.42402343750001, 53.53076171875],
              [123.3095703125, 53.555615234375],
              [123.15410156249999, 53.544580078125],
              [122.95761718750003, 53.497705078125],
              [122.74472656250003, 53.468505859375],
              [122.51582031250001, 53.456982421875],
              [122.38017578124999, 53.4625],
              [122.33779296875002, 53.485009765625],
              [122.0888671875, 53.451464843749996],
              [121.74394531249999, 53.38359375],
              [121.40546875000001, 53.317041015625],
              [120.98544921875003, 53.2845703125],
              [120.7041015625, 53.171826171875],
              [120.42128906250002, 52.968066406249996],
              [120.21816406250002, 52.839892578124996],
              [120.09453124999999, 52.78720703125],
              [120.04433593750002, 52.718212890625],
              [120.06757812500001, 52.63291015625],
              [120.17275390625002, 52.602490234375],
              [120.36005859375001, 52.627001953124996],
              [120.52109375000003, 52.615039062499996],
              [120.65615234375002, 52.566650390625],
              [120.69921875, 52.493603515625],
              [120.650390625, 52.3958984375],
              [120.66542968750002, 52.29990234375],
              [120.74453125000002, 52.20546875],
              [120.74980468749999, 52.096533203125],
              [120.68144531249999, 51.973046875],
              [120.51054687499999, 51.84853515625],
              [120.23701171875001, 51.722998046875],
              [120.06689453125, 51.60068359375],
              [119.96699218750001, 51.422119140625],
              [119.81318359375001, 51.267041015625],
              [119.75664062499999, 51.179492187499996],
              [119.74599609375002, 51.107714843749996],
              [119.68496093750002, 51.030126953125],
              [119.57343750000001, 50.94677734375],
              [119.51230468750003, 50.863134765625],
              [119.50175781249999, 50.779248046875],
              [119.44570312500002, 50.70283203125],
              [119.34404296874999, 50.63388671875],
              [119.28066406250002, 50.560986328125],
              [119.255859375, 50.484179687499996],
              [119.21669921875002, 50.432519531249994],
              [119.16367187500003, 50.406005859375],
              [119.19189453125, 50.379833984375],
              [119.30156249999999, 50.35390625],
              [119.34628906250003, 50.278955078124994],
              [119.32607421875002, 50.154931640624994],
              [119.25986328125003, 50.06640625],
              [119.1474609375, 50.01337890625],
              [118.9794921875, 49.978857421875],
              [118.75595703125003, 49.962841796875],
              [118.45156250000002, 49.844482421875],
              [118.18662109375003, 49.692773437499994],
              [117.87343750000002, 49.513476562499996],
              [117.81259765625003, 49.513525390625],
              [117.69843750000001, 49.53583984375],
              [117.47714843750003, 49.609423828124996],
              [117.24560546875, 49.624853515625],
              [117.02167968750001, 49.69296875],
              [116.88896484374999, 49.73779296875],
              [116.68330078125001, 49.823779296874996],
              [116.63154296875001, 49.87705078125],
              [116.55117187500002, 49.9203125],
              [116.35117187500003, 49.978076171874996],
              [116.216796875, 50.00927734375],
              [116.13457031249999, 50.010791015624996],
              [115.92597656250001, 49.9521484375],
              [115.79521484374999, 49.905908203124994],
              [115.7177734375, 49.880615234375],
              [115.58798828125003, 49.886035156249996],
              [115.42919921875, 49.896484375],
              [115.36503906249999, 49.911767578124994],
              [115.27451171875003, 49.948876953124994],
              [115.09804687500002, 50.059423828125],
              [115.00332031250002, 50.138574218749994],
              [114.87958984375001, 50.183056640625],
              [114.7431640625, 50.233691406249996],
              [114.67490234375003, 50.245703125],
              [114.55400390624999, 50.241455078125],
              [114.38632812500003, 50.25546875],
              [114.29707031250001, 50.2744140625],
              [114.22177734375003, 50.257275390625],
              [114.07070312500002, 50.204736328124994],
              [113.88115234374999, 50.101123046874996],
              [113.732421875, 50.0615234375],
              [113.57421875, 50.00703125],
              [113.44550781250001, 49.9416015625],
              [113.31904296875001, 49.874316406249996],
              [113.16416015625003, 49.797167968749996],
              [113.09208984374999, 49.692529296874994],
              [113.05556640625002, 49.616259765624996],
              [112.91484374999999, 49.569238281249994],
              [112.80644531249999, 49.523583984374994],
              [112.69736328125003, 49.507275390625],
              [112.49492187499999, 49.53232421875],
              [112.37519531250001, 49.514599609375],
              [112.07968750000003, 49.42421875],
              [111.93447265625002, 49.416015625],
              [111.83339843750002, 49.403613281249996],
              [111.73554687500001, 49.397753906249996],
              [111.57480468750003, 49.376416015625],
              [111.51191406250001, 49.3609375],
              [111.42929687500003, 49.342626953125],
              [111.33662109375001, 49.355859375],
              [111.20419921875003, 49.304296875],
              [110.82792968749999, 49.166162109374994],
              [110.70976562499999, 49.14296875],
              [110.63105468750001, 49.137597656249994],
              [110.52958984374999, 49.187060546874996],
              [110.42783203125003, 49.219970703125],
              [110.32138671875003, 49.215869140624996],
              [110.19990234375001, 49.17041015625],
              [109.99453125000002, 49.205615234374996],
              [109.75039062500002, 49.239306640624996],
              [109.52871093750002, 49.269873046875],
              [109.45371093750003, 49.296337890625],
              [109.23671875000002, 49.334912109375],
              [108.919921875, 49.335351562499994],
              [108.73300781250003, 49.33564453125],
              [108.61367187500002, 49.322802734374996],
              [108.5224609375, 49.34150390625],
              [108.40693359375001, 49.396386718749994],
              [108.21308593750001, 49.524804687499994],
              [108.09804687500002, 49.562646484374994],
              [108.03378906250003, 49.593994140625],
              [108.00957031249999, 49.646875],
              [107.96542968750003, 49.653515625],
              [107.93671875000001, 49.691015625],
              [107.93876953124999, 49.74072265625],
              [107.93486328124999, 49.849023437499994],
              [107.94785156250003, 49.924707031249994],
              [107.91660156250003, 49.947802734374996],
              [107.78681640625001, 49.960009765624996],
              [107.63095703125003, 49.98310546875],
              [107.34707031250002, 49.986669921875],
              [107.23330078125002, 49.989404296874994],
              [107.14306640625, 50.033007812499996],
              [107.04023437500001, 50.086474609374996],
              [106.94130859375002, 50.196679687499994],
              [106.85371093750001, 50.248291015625],
              [106.71113281250001, 50.31259765625],
              [106.57441406250001, 50.32880859375],
              [106.36845703124999, 50.317578125],
              [106.21787109375003, 50.304589843749994],
              [106.08251953125, 50.332568359374996],
              [105.99648437500002, 50.367919921875],
              [105.87519531250001, 50.40537109375],
              [105.69257812500001, 50.41416015625],
              [105.54160156250003, 50.441259765625],
              [105.38359374999999, 50.47373046875],
              [105.26669921875003, 50.460498046874996],
              [105.18593750000002, 50.429589843749994],
              [105.0947265625, 50.389941406249996],
              [104.97695312500002, 50.38291015625],
              [104.68535156249999, 50.341845703124996],
              [104.59638671875001, 50.3171875],
              [104.46630859375, 50.30615234375],
              [104.35390625000002, 50.275292968749994],
              [104.25996093750001, 50.214453125],
              [104.1796875, 50.16943359375],
              [104.07871093750003, 50.154248046875],
              [103.95849609375, 50.157275390624996],
              [103.85615234375001, 50.171826171875],
              [103.80263671875002, 50.176074218749996],
              [103.72324218750003, 50.153857421874996],
              [103.63291015625003, 50.138574218749994],
              [103.49628906250001, 50.164941406249994],
              [103.42119140624999, 50.187060546874996],
              [103.30439453125001, 50.20029296875],
              [103.23378906250002, 50.2642578125],
              [103.16171875000003, 50.29072265625],
              [103.03945312500002, 50.300634765625],
              [102.85966796874999, 50.333251953125],
              [102.76542968749999, 50.366552734375],
              [102.68330078125001, 50.387158203125],
              [102.54628906250002, 50.461328125],
              [102.46943359375001, 50.525683593749996],
              [102.40683593750003, 50.536181640624996],
              [102.33642578125, 50.544238281249996],
              [102.28837890624999, 50.585107421874994],
              [102.28574218750003, 50.634667968749994],
              [102.30332031250003, 50.66552734375],
              [102.31660156250001, 50.71845703125],
              [102.27656250000001, 50.768701171874994],
              [102.23505859375001, 50.7912109375],
              [102.21503906250001, 50.829443359375],
              [102.22617187500003, 50.90146484375],
              [102.21025390624999, 50.97431640625],
              [102.19453125000001, 51.050683593749994],
              [102.15195312500003, 51.10751953125],
              [102.14238281249999, 51.216064453125],
              [102.16005859375002, 51.26083984375],
              [102.15566406250002, 51.313769531249996],
              [102.11152343750001, 51.353466796875],
              [101.97919921875001, 51.382226562499994],
              [101.82119140625002, 51.421044921874994],
              [101.57089843750003, 51.4671875],
              [101.46435546875, 51.471484375],
              [101.38125, 51.45263671875],
              [101.30449218749999, 51.474755859374994],
              [101.22324218750003, 51.51328125],
              [101.08535156250002, 51.553027343749996],
              [100.90361328124999, 51.604248046875],
              [100.71074218749999, 51.661572265625],
              [100.53623046875003, 51.7134765625],
              [100.46894531250001, 51.72607421875],
              [100.23037109375002, 51.729833984375],
              [100.03457031250002, 51.737109375],
              [99.92167968749999, 51.755517578125],
              [99.78789062499999, 51.8275390625],
              [99.71923828125, 51.871630859374996],
              [99.61289062500003, 51.892529296875],
              [99.53232421875003, 51.89990234375],
              [99.40703124999999, 51.92353515625],
              [99.17617187500002, 51.998876953125],
              [99.09140625000003, 52.03486328125],
              [99.03427734375003, 52.035400390625],
              [98.95810546875003, 52.101708984375],
              [98.89316406250003, 52.11728515625],
              [98.8486328125, 52.070068359375],
              [98.80253906249999, 51.957470703125],
              [98.76015625000002, 51.905078125],
              [98.64052734375002, 51.801171875],
              [98.35273437500001, 51.717626953125],
              [98.303125, 51.674267578125],
              [98.27685546875, 51.634570312499996],
              [98.2375, 51.578417968749996],
              [98.21992187500001, 51.505615234375],
              [98.18466796875003, 51.485742187499994],
              [98.103125, 51.483544921874994],
              [98.03759765625, 51.449951171875],
              [97.98916015625002, 51.37705078125],
              [97.946875, 51.3484375],
              [97.92324218750002, 51.28046875],
              [97.92734375000003, 51.250732421875],
              [97.91787109375002, 51.21787109375],
              [97.91083984375001, 51.165185546874994],
              [97.83574218749999, 51.051660156249994],
              [97.82529296875003, 50.985253906249994],
              [97.85615234375001, 50.943359375],
              [97.91982421875002, 50.887158203125],
              [97.953125, 50.85517578125],
              [97.96416015624999, 50.817675781249996],
              [97.9619140625, 50.769140625],
              [98.00117187500001, 50.702050781249994],
              [98.02978515625, 50.64462890625],
              [98.07890624999999, 50.603808593749996],
              [98.14501953125, 50.5685546875],
              [98.22050781249999, 50.557177734374996],
              [98.27949218750001, 50.533251953124996],
              [98.29267578125001, 50.486962890624994],
              [98.27734375, 50.422998046874994],
              [98.25029296874999, 50.30244140625],
              [98.2, 50.227685546874994],
              [98.17011718750001, 50.180566406249994],
              [98.12197265625002, 50.106591796874994],
              [98.10341796875002, 50.07783203125],
              [98.00390625, 50.0142578125],
              [97.93662109375003, 49.99677734375],
              [97.85390625000002, 49.94677734375],
              [97.78554687500002, 49.94453125],
              [97.720703125, 49.944628906249996],
              [97.65097656250003, 49.93359375],
              [97.58935546875, 49.911474609375],
              [97.54082031249999, 49.843115234375],
              [97.41835937500002, 49.773046875],
              [97.35976562500002, 49.741455078125],
              [97.20859375000003, 49.730810546875],
              [97.13691406250001, 49.76171875],
              [97.09765625, 49.805029296875],
              [97.04912109374999, 49.829882812499996],
              [96.98574218750002, 49.8828125],
              [96.71171874999999, 49.911572265625],
              [96.64023437500003, 49.897851562499994],
              [96.59843749999999, 49.87841796875],
              [96.54326171874999, 49.892529296875],
              [96.50576171875002, 49.918701171875],
              [96.46640625000003, 49.911523437499994],
              [96.38115234374999, 49.896044921874996],
              [96.31503906250003, 49.901123046875],
              [96.22968750000001, 49.9541015625],
              [96.11171875000002, 49.982470703124996],
              [96.06552734375003, 49.99873046875],
              [96.0185546875, 49.998779296875],
              [95.98955078124999, 49.973583984375],
              [95.93574218750001, 49.960009765624996],
              [95.8994140625, 49.990576171875],
              [95.85195312500002, 50.012939453125],
              [95.78935546874999, 50.0125],
              [95.70781249999999, 49.966015625],
              [95.56718749999999, 49.94384765625],
              [95.52265625000001, 49.91123046875],
              [95.44179687500002, 49.91552734375],
              [95.38564453125002, 49.941210937499996],
              [95.32949218750002, 49.944140625],
              [95.16621093750001, 49.94384765625],
              [95.11142578125003, 49.935449218749994],
              [95.04433593750002, 49.961572265624994],
              [95.01289062500001, 50.008251953125],
              [94.93027343750003, 50.04375],
              [94.81123046875001, 50.048193359375],
              [94.71806640624999, 50.043261718749996],
              [94.67548828125001, 50.028076171875],
              [94.61474609375, 50.023730468749996],
              [94.56464843750001, 50.087939453124996],
              [94.496875, 50.1328125],
              [94.45849609375, 50.16572265625],
              [94.40019531249999, 50.179638671875],
              [94.35468750000001, 50.221826171874994],
              [94.346875, 50.30341796875],
              [94.3193359375, 50.4048828125],
              [94.28701171875002, 50.511376953124994],
              [94.25107421875003, 50.556396484375],
              [94.07578125000003, 50.5728515625],
              [93.98984375000003, 50.56884765625],
              [93.79541015625, 50.57763671875],
              [93.66201171875002, 50.58369140625],
              [93.62558593750003, 50.585546875],
              [93.50107421875003, 50.597460937499996],
              [93.38681640625003, 50.60849609375],
              [93.2705078125, 50.615576171875],
              [93.22255859375002, 50.60654296875],
              [93.103125, 50.60390625],
              [93.00986328125003, 50.654541015625],
              [92.970703125, 50.7125],
              [92.96357421875001, 50.744921875],
              [92.94130859375002, 50.778222656249994],
              [92.8564453125, 50.789111328124996],
              [92.779296875, 50.778662109375],
              [92.73867187500002, 50.7109375],
              [92.68134765625001, 50.683203125],
              [92.62666015625001, 50.68828125],
              [92.57890624999999, 50.725439453125],
              [92.48642578125003, 50.765087890625],
              [92.42636718750003, 50.803076171875],
              [92.35478515624999, 50.864160156249994],
              [92.29580078125002, 50.8498046875],
              [92.27900390625001, 50.81220703125],
              [92.26533203125001, 50.775195312499996],
              [92.1923828125, 50.700585937499994],
              [92.10400390625, 50.6919921875],
              [91.95654296875, 50.697607421875],
              [91.80429687500003, 50.693603515625],
              [91.70634765624999, 50.66552734375],
              [91.63417968750002, 50.615136718749994],
              [91.596875, 50.575537109375],
              [91.52167968750001, 50.56201171875],
              [91.44648437500001, 50.52216796875],
              [91.4150390625, 50.468017578125],
              [91.3408203125, 50.470068359375],
              [91.30058593749999, 50.46337890625],
              [91.23378906250002, 50.452392578125],
              [91.06279296874999, 50.422607421875],
              [91.02158203125003, 50.415478515625],
              [90.91718750000001, 50.364160156249994],
              [90.83808593750001, 50.32373046875],
              [90.7607421875, 50.305957031249996],
              [90.71435546875, 50.259423828124994],
              [90.65507812499999, 50.22236328125],
              [90.51689453124999, 50.213330078125],
              [90.36484375000003, 50.166894531249994],
              [90.31132812499999, 50.151171875],
              [90.22451171875002, 50.11669921875],
              [90.10371093750001, 50.103320312499996],
              [90.0537109375, 50.09375],
              [90.00498046875003, 50.069287109375],
              [89.97734374999999, 49.984326171875],
              [89.87802734375003, 49.953515625],
              [89.74423828125003, 49.948095703125],
              [89.64384765624999, 49.90302734375],
              [89.63427734375, 49.823291015624996],
              [89.66953125000003, 49.75048828125],
              [89.65410156249999, 49.71748046875],
              [89.57919921875003, 49.69970703125],
              [89.475, 49.660546875],
              [89.39560546875003, 49.6115234375],
              [89.29921875000002, 49.611132812499996],
              [89.24394531249999, 49.62705078125],
              [89.20292968749999, 49.595703125],
              [89.17998046874999, 49.5322265625],
              [89.10947265625003, 49.501367187499994],
              [89.00839843750003, 49.472802734374994],
              [88.97060546875002, 49.483740234375],
              [88.94541015625003, 49.507666015625],
              [88.90019531249999, 49.539697265624994],
              [88.86386718750003, 49.527636718749996],
              [88.8603515625, 49.48154296875],
              [88.83164062500003, 49.4484375],
              [88.74785156249999, 49.446240234375],
              [88.68271484375003, 49.46455078125],
              [88.63320312500002, 49.486132812499996],
              [88.54433593750002, 49.482568359374994],
              [88.45244140624999, 49.472705078124996],
              [88.39335937499999, 49.482861328125],
              [88.33779296875002, 49.472558593749994],
              [88.19257812500001, 49.451708984374996],
              [88.13554687499999, 49.381494140624994],
              [88.13427734375, 49.2984375],
              [88.11572265625, 49.256298828125],
              [88.02851562500001, 49.219775390624996],
              [87.98808593749999, 49.186914062499994],
              [87.93476562500001, 49.16455078125],
              [87.81826171875002, 49.162109375],
              [87.81425781249999, 49.1623046875],
              [87.7625, 49.165820312499996],
              [87.66835937500002, 49.147216796875],
              [87.57656250000002, 49.132373046874996],
              [87.51582031250001, 49.122412109375],
              [87.47617187500003, 49.091455078124994],
              [87.41669921875001, 49.076611328125],
              [87.32285156250003, 49.085791015625],
              [87.296875, 49.14765625],
              [87.23369140624999, 49.216162109375],
              [87.14804687500003, 49.239794921874996],
              [87.07060546874999, 49.25458984375],
              [87.0009765625, 49.2873046875],
              [86.95292968749999, 49.322070312499996],
              [86.81210937500003, 49.487890625],
              [86.71435546875, 49.55859375],
              [86.62646484375, 49.5626953125],
              [86.6142578125, 49.609716796875],
              [86.66533203124999, 49.656689453125],
              [86.73066406250001, 49.695556640625],
              [86.72871093750001, 49.748681640624994],
              [86.67548828125001, 49.777294921875],
              [86.61015624999999, 49.769140625],
              [86.52226562499999, 49.707763671875],
              [86.41796875, 49.638476562499996],
              [86.29238281250002, 49.5875],
              [86.2421875, 49.546337890625],
              [86.18085937500001, 49.499316406249996],
              [86.09296875000001, 49.50546875],
              [86.02958984374999, 49.50341796875],
              [85.97441406249999, 49.499316406249996],
              [85.93359375, 49.550439453124994],
              [85.88046875000003, 49.556542968749994],
              [85.49843750000002, 49.605371093749994],
              [85.37158203125, 49.623925781249994],
              [85.29189453125002, 49.599462890625],
              [85.23261718750001, 49.6158203125],
              [85.21015625000001, 49.66484375],
              [85.13652343749999, 49.750732421875],
              [85.07646484374999, 49.821630859375],
              [85.00078124999999, 49.894140625],
              [84.97519531250003, 49.951074218749994],
              [84.99970703125001, 50.010302734374996],
              [84.98945312500001, 50.061425781249994],
              [84.92402343750001, 50.08798828125],
              [84.83896484375003, 50.09130859375],
              [84.60732421875002, 50.202392578125],
              [84.4990234375, 50.21875],
              [84.40097656250003, 50.239160156249994],
              [84.3232421875, 50.239160156249994],
              [84.2578125, 50.288232421874994],
              [84.19453125000001, 50.437451171875],
              [84.17597656250001, 50.520556640624996],
              [84.09931640625001, 50.604736328125],
              [84.00234375000002, 50.67685546875],
              [83.94511718749999, 50.774658203125],
              [83.85976562500002, 50.818017578124994],
              [83.7177734375, 50.887158203125],
              [83.58144531250002, 50.9357421875],
              [83.35732421875002, 50.994580078125],
              [83.27373046874999, 50.994580078125],
              [83.16025390625003, 50.989208984375],
              [83.0927734375, 50.960595703124994],
              [83.01923828125001, 50.897265625],
              [82.91904296875003, 50.893115234374996],
              [82.76083984375003, 50.893359375],
              [82.71855468749999, 50.869482421875],
              [82.69296875000003, 50.826318359374994],
              [82.61171875000002, 50.771484375],
              [82.49394531249999, 50.727587890624996],
              [82.32636718750001, 50.74189453125],
              [82.2119140625, 50.71943359375],
              [82.09804687500002, 50.710839843749994],
              [81.93369140625003, 50.766357421875],
              [81.75205078125003, 50.764404296875],
              [81.63388671875003, 50.739111328125],
              [81.46591796875003, 50.73984375],
              [81.43144531249999, 50.771142578124994],
              [81.45156250000002, 50.823681640625],
              [81.43769531250001, 50.871044921875],
              [81.41015625, 50.909765625],
              [81.38828125000003, 50.956494140625],
              [81.31914062499999, 50.96640625],
              [81.12460937500003, 50.9462890625],
              [81.07148437500001, 50.96875],
              [81.07753906250002, 51.014941406249996],
              [81.11240234375003, 51.07236328125],
              [81.14101562500002, 51.14658203125],
              [81.12724609374999, 51.191064453124994],
              [81.02675781250002, 51.185693359374994],
              [80.965625, 51.189794921875],
              [80.93408203125, 51.2427734375],
              [80.87734375000002, 51.2814453125],
              [80.81308593750003, 51.283496093749996],
              [80.73525390625002, 51.293408203125],
              [80.65048828125003, 51.27734375],
              [80.60546875, 51.22421875],
              [80.55068359375002, 51.216601562499996],
              [80.49101562499999, 51.2017578125],
              [80.44804687499999, 51.183349609375],
              [80.42148437500003, 51.136376953124994],
              [80.43359375, 51.092626953125],
              [80.45224609375003, 50.997607421874996],
              [80.42363281249999, 50.9462890625],
              [80.34521484375, 50.919091796874994],
              [80.27041015625002, 50.924609375],
              [80.22021484375, 50.911767578124994],
              [80.12724609374999, 50.858349609375],
              [80.08632812500002, 50.839990234374994],
              [80.07207031249999, 50.807275390624994],
              [80.06591796875, 50.758203125],
              [79.98623046875002, 50.774560546874994],
              [79.85966796874999, 50.95546875],
              [79.71640625000003, 51.160009765625],
              [79.55429687500003, 51.377978515624996],
              [79.46884765625003, 51.493115234375],
              [79.14873046874999, 51.868115234375],
              [78.99208984375002, 52.047412109374996],
              [78.72148437499999, 52.35703125],
              [78.47548828125002, 52.638427734375],
              [78.19804687499999, 52.9296875],
              [78.03349609374999, 53.094970703125],
              [77.85996093750003, 53.269189453125],
              [77.79941406250003, 53.317431640624996],
              [77.70439453124999, 53.379150390625],
              [77.46923828125, 53.498779296875],
              [77.13242187500003, 53.6701171875],
              [76.82070312500002, 53.82265625],
              [76.57568359375, 53.942529296875],
              [76.51308593750002, 53.993212890624996],
              [76.48476562500002, 54.02255859375],
              [76.45859375000003, 54.0552734375],
              [76.42207031250001, 54.113525390625],
              [76.42167968749999, 54.151513671875],
              [76.65458984374999, 54.145263671875],
              [76.70302734375002, 54.182470703125],
              [76.78896484375002, 54.321875],
              [76.83730468750002, 54.4423828125],
              [76.759375, 54.436865234375],
              [76.61552734374999, 54.387109375],
              [76.53916015625003, 54.35107421875],
              [76.49648437500002, 54.335693359375],
              [76.2666015625, 54.311962890625],
              [76.14052734375002, 54.258544921875],
              [75.88066406250002, 54.16796875],
              [75.69287109375, 54.114794921874996],
              [75.6568359375, 54.106005859374996],
              [75.43720703125001, 54.089648437499996],
              [75.39814453125001, 54.068505859375],
              [75.39238281250002, 54.021728515625],
              [75.37705078125, 53.9701171875],
              [75.22021484375, 53.893798828125],
              [75.05214843750002, 53.826708984374996],
              [74.98896484375001, 53.81923828125],
              [74.88681640625, 53.834033203124996],
              [74.83417968750001, 53.82568359375],
              [74.68144531250002, 53.75439453125],
              [74.45195312500002, 53.647265625],
              [74.43046875000002, 53.6037109375],
              [74.429296875, 53.550732421875],
              [74.40273437500002, 53.504443359374996],
              [74.3515625, 53.487646484375],
              [74.27734375, 53.527734375],
              [74.2099609375, 53.576464843749996],
              [74.06865234375002, 53.61142578125],
              [73.858984375, 53.6197265625],
              [73.73115234375001, 53.602783203125],
              [73.64296875000002, 53.57626953125],
              [73.46992187500001, 53.468896484375],
              [73.40693359375001, 53.447558593749996],
              [73.371875, 53.454394531249996],
              [73.3619140625, 53.506201171875],
              [73.32685546875001, 53.5431640625],
              [73.2857421875, 53.598388671875],
              [73.3056640625, 53.7072265625],
              [73.3994140625, 53.811474609375],
              [73.55419921875, 53.868310546875],
              [73.67890625000001, 53.929443359375],
              [73.71552734375001, 53.99619140625],
              [73.71240234375, 54.0423828125],
              [73.66640625000002, 54.0634765625],
              [73.61796875000002, 54.0673828125],
              [73.58994140625, 54.044970703124996],
              [73.50566406250002, 53.999316406249996],
              [73.38066406250002, 53.962841796875],
              [73.27656250000001, 53.955615234374996],
              [73.22988281250002, 53.9578125],
              [73.11933593750001, 53.980761718749996],
              [72.9140625, 54.10732421875],
              [72.74101562500002, 54.12451171875],
              [72.62226562500001, 54.134326171874996],
              [72.58271484375001, 54.12158203125],
              [72.56425781250002, 54.0904296875],
              [72.57558593750002, 54.056494140625],
              [72.59921875, 54.023046875],
              [72.5859375, 53.995947265625],
              [72.5302734375, 53.97578125],
              [72.44677734375, 53.941845703125],
              [72.404296875, 53.964453125],
              [72.38300781250001, 54.053662109375],
              [72.3873046875, 54.123046875],
              [72.32949218750002, 54.181445312499996],
              [72.269140625, 54.272119140625],
              [72.18603515625, 54.325634765625],
              [72.10537109375002, 54.308447265625],
              [72.065625, 54.231640625],
              [72.0044921875, 54.2056640625],
              [71.88740234375001, 54.221484375],
              [71.67714843750002, 54.178027343749996],
              [71.33642578125, 54.158349609375],
              [71.09316406250002, 54.212207031249996],
              [71.052734375, 54.260498046875],
              [71.15214843750002, 54.3640625],
              [71.159765625, 54.455419921875],
              [71.1591796875, 54.538623046874996],
              [71.185546875, 54.59931640625],
              [71.12626953125002, 54.7150390625],
              [70.991796875, 54.950488281249996],
              [70.91015625, 55.127978515624996],
              [70.79033203125002, 55.2611328125],
              [70.73808593750002, 55.30517578125],
              [70.486328125, 55.282373046875],
              [70.41718750000001, 55.253173828125],
              [70.37148437500002, 55.212255859375],
              [70.29335937500002, 55.18359375],
              [70.18242187500002, 55.162451171875],
              [70.08740234375, 55.1767578125],
              [69.98173828125002, 55.199072265625],
              [69.87021484375, 55.245654296874996],
              [69.740234375, 55.307373046875],
              [69.49326171875, 55.356884765625],
              [69.24697265625002, 55.372509765625],
              [68.97724609375001, 55.389599609375],
              [68.84296875000001, 55.358349609375],
              [68.712890625, 55.30849609375],
              [68.52480468750002, 55.204833984375],
              [68.4384765625, 55.19443359375],
              [68.30195312500001, 55.1865234375],
              [68.20625, 55.1609375],
              [68.22529296875001, 55.115234375],
              [68.24404296875002, 55.05244140625],
              [68.209375, 55.00302734375],
              [68.155859375, 54.976708984375],
              [68.073828125, 54.9595703125],
              [67.93994140625, 54.9537109375],
              [67.82988281250002, 54.9435546875],
              [67.693359375, 54.872412109375],
              [67.48466796875002, 54.8544921875],
              [67.25732421875, 54.82880859375],
              [67.09833984375001, 54.78818359375],
              [66.7544921875, 54.737890625],
              [66.55537109375001, 54.7154296875],
              [66.22265625, 54.6673828125],
              [65.9546875, 54.659521484375],
              [65.91425781250001, 54.693310546875],
              [65.70781250000002, 54.618701171874996],
              [65.47695312500002, 54.623291015625],
              [65.434375, 54.593310546874996],
              [65.378125, 54.564453125],
              [65.31591796875, 54.5515625],
              [65.23740234375, 54.516064453125],
              [65.19218750000002, 54.44111328125],
              [65.1578125, 54.364404296875],
              [65.08837890625, 54.340185546875],
              [64.99541015625002, 54.36875],
              [64.9267578125, 54.396630859375],
              [64.80927734375001, 54.3685546875],
              [64.64990234375, 54.35224609375],
              [64.52509765625001, 54.362158203125],
              [64.46123046875002, 54.3841796875],
              [64.19941406250001, 54.347412109375],
              [64.06289062500002, 54.3029296875],
              [64.03740234375002, 54.279736328125],
              [64.00390625, 54.26708984375],
              [63.84707031250002, 54.236474609375],
              [63.72119140625, 54.24501953125],
              [63.70136718750001, 54.243212890624996],
              [63.58203125, 54.221923828125],
              [63.413671875, 54.183203125],
              [63.29267578125001, 54.170458984374996],
              [63.19130859375002, 54.171044921875],
              [63.1265625, 54.1392578125],
              [63.07392578125001, 54.105224609375],
              [62.63271484375002, 54.069287109375],
              [62.58828125000002, 54.04443359375],
              [62.4990234375, 54.01318359375],
              [62.04023437500001, 54.00263671875],
              [62.00234375000002, 53.979931640625],
              [61.98564453125002, 53.954394531249996],
              [61.9287109375, 53.946484375],
              [61.59814453125, 53.994921875],
              [61.33369140625001, 54.049267578125],
              [61.231054687500006, 54.019482421875],
              [61.14375, 53.963818359375],
              [61.11318359375002, 53.882470703125],
              [61.11318359375002, 53.81298828125],
              [61.11318359375002, 53.753466796874996],
              [61.07353515625002, 53.71044921875],
              [60.98554687500001, 53.657421875],
              [60.9794921875, 53.621728515625],
              [61.09853515625002, 53.58310546875],
              [61.24794921875002, 53.5509765625],
              [61.33613281250001, 53.565185546875],
              [61.40996093750002, 53.587060546875],
              [61.47412109375, 53.5802734375],
              [61.519140625, 53.554492187499996],
              [61.53496093750002, 53.523291015625],
              [61.52656250000001, 53.5015625],
              [61.49853515625, 53.484667968749996],
              [61.400976562500006, 53.455810546875],
              [61.311621093750006, 53.46572265625],
              [61.22890625000002, 53.4458984375],
              [61.18593750000002, 53.406201171875],
              [61.16279296875001, 53.336767578125],
              [61.19921875, 53.287158203124996],
              [61.31093750000002, 53.275195312499996],
              [61.43681640625002, 53.239404296875],
              [61.576171875, 53.222460937499996],
              [61.65986328125001, 53.228466796875],
              [61.766210937500006, 53.17392578125],
              [62.0146484375, 53.107861328125],
              [62.0810546875, 53.057421875],
              [62.08271484375001, 53.005419921874996],
              [62.037109375, 52.966113281249996],
              [61.97421875, 52.94375],
              [61.88857421875002, 52.955908203125],
              [61.719335937500006, 52.969384765625],
              [61.53359375000002, 52.978515625],
              [61.40078125000002, 52.99599609375],
              [61.20693359375002, 52.9890625],
              [61.047460937500006, 52.972460937499996],
              [61.00654296875001, 52.933349609375],
              [60.94472656250002, 52.86015625],
              [60.89326171875001, 52.81943359375],
              [60.80234375, 52.7447265625],
              [60.7744140625, 52.67578125],
              [60.8212890625, 52.56982421875],
              [60.9794921875, 52.394775390625],
              [60.99453125000002, 52.336865234375],
              [60.937597656250006, 52.280566406249996],
              [60.82841796875002, 52.2333984375],
              [60.67031250000002, 52.150830078125],
              [60.49931640625002, 52.146337890625],
              [60.42548828125001, 52.1255859375],
              [60.23369140625002, 52.02451171875],
              [60.065527343750006, 51.97646484375],
              [60.0302734375, 51.933251953125],
              [60.067480468750006, 51.890625],
              [60.280371093750006, 51.834619140625],
              [60.3875, 51.772998046874996],
              [60.41835937500002, 51.70390625],
              [60.46474609375002, 51.651171875],
              [60.63037109375, 51.616943359375],
              [60.97353515625002, 51.537060546875],
              [60.99335937500001, 51.528710937499994],
              [61.01484375000001, 51.4923828125],
              [61.36308593750002, 51.44189453125],
              [61.41132812500001, 51.41474609375],
              [61.5546875, 51.324609375],
              [61.585058593750006, 51.2296875],
              [61.51220703125, 51.137011718749996],
              [61.46503906250001, 50.990234375],
              [61.38945312500002, 50.86103515625],
              [61.22685546875002, 50.774804687499994],
              [60.94228515625002, 50.6955078125],
              [60.63798828125002, 50.663720703124994],
              [60.50849609375001, 50.669189453125],
              [60.4248046875, 50.679150390625],
              [60.2880859375, 50.704150390624996],
              [60.18671875000001, 50.769775390625],
              [60.11210937500002, 50.8341796875],
              [60.05859375, 50.85029296875],
              [60.00527343750002, 50.839697265625],
              [59.955175781250006, 50.799267578125],
              [59.887792968750006, 50.690185546875],
              [59.81240234375002, 50.58203125],
              [59.75117187500001, 50.5439453125],
              [59.523046875, 50.49287109375],
              [59.49785156250002, 50.511083984375],
              [59.52392578125, 50.5828125],
              [59.4951171875, 50.604296875],
              [59.45234375000001, 50.620410156249996],
              [59.1708984375, 50.647900390625],
              [59.06435546875002, 50.668212890625],
              [58.98486328125, 50.676123046875],
              [58.88369140625002, 50.69443359375],
              [58.8140625, 50.737207031249994],
              [58.66455078125, 50.868310546874994],
              [58.547460937500006, 50.971044921875],
              [58.35917968750002, 51.063818359375],
              [58.1884765625, 51.08173828125],
              [58.17470703125002, 51.072265625],
              [58.04511718750001, 51.06884765625],
              [57.8388671875, 51.091650390625],
              [57.82890625000002, 51.089013671874994],
              [57.76484375000001, 51.046875],
              [57.71699218750001, 50.98095703125],
              [57.65380859375, 50.925146484375],
              [57.55781250000001, 50.895556640624996],
              [57.44218750000002, 50.8888671875],
              [57.3125, 50.946533203125],
              [57.17900390625002, 51.036035156249994],
              [57.01171875, 51.065185546875],
              [56.849609375, 51.045556640624994],
              [56.79033203125002, 51.031591796875],
              [56.620214843750006, 50.980859375],
              [56.56689453125, 51.0044921875],
              [56.49140625000001, 51.01953125],
              [56.32558593750002, 50.936083984374996],
              [56.14394531250002, 50.844628906249994],
              [56.1044921875, 50.776269531249994],
              [56.04970703125002, 50.713525390624994],
              [55.92919921875, 50.653759765625],
              [55.79765625000002, 50.60205078125],
              [55.68623046875001, 50.582861328125],
              [55.54228515625002, 50.601806640625],
              [55.36113281250002, 50.665283203125],
              [55.19521484375002, 50.7447265625],
              [55.01484375000001, 50.869775390624994],
              [54.86796875000002, 50.941357421875],
              [54.727148437500006, 50.998095703124996],
              [54.6416015625, 51.011572265625],
              [54.57294921875001, 50.990234375],
              [54.54609375000001, 50.946044921875],
              [54.565625, 50.911279296874994],
              [54.60625, 50.8798828125],
              [54.63789062500001, 50.781054687499996],
              [54.65, 50.66015625],
              [54.63613281250002, 50.591601562499996],
              [54.59619140625, 50.550683593749994],
              [54.555273437500006, 50.535791015624994],
              [54.51738281250002, 50.541162109374994],
              [54.47148437500002, 50.583789062499996],
              [54.443359375, 50.67392578125],
              [54.421484375, 50.780322265624996],
              [54.2978515625, 50.9140625],
              [54.19111328125001, 50.995703125],
              [54.139746093750006, 51.040771484375],
              [54.04150390625, 51.115185546875],
              [53.95683593750002, 51.161181640624996],
              [53.776464843750006, 51.213720703125],
              [53.688085937500006, 51.251806640625],
              [53.53466796875, 51.399560546874994],
              [53.44863281250002, 51.44453125],
              [53.33808593750001, 51.482373046875],
              [53.24726562500001, 51.493603515625],
              [53.22734375000002, 51.4849609375],
              [53.03837890625002, 51.463720703125],
              [52.90263671875002, 51.466943359374994],
              [52.82050781250001, 51.494580078125],
              [52.73505859375001, 51.497900390625],
              [52.728125, 51.49814453125],
              [52.63515625000002, 51.479541015624996],
              [52.617773437500006, 51.480761718749996],
              [52.57119140625002, 51.481640625],
              [52.496191406250006, 51.512158203125],
              [52.42304687500001, 51.59423828125],
              [52.3310546875, 51.681298828125],
              [52.21914062500002, 51.709375],
              [52.00712890625002, 51.672705078125],
              [51.775390625, 51.554248046874996],
              [51.60908203125001, 51.483984375],
              [51.47343750000002, 51.48203125],
              [51.39599609375, 51.4712890625],
              [51.34453125000002, 51.475341796875],
              [51.30107421875002, 51.497412109375],
              [51.29072265625001, 51.540185546874994],
              [51.26992187500002, 51.594482421875],
              [51.16347656250002, 51.6474609375],
              [51.01787109375002, 51.681640625],
              [50.882421875, 51.719189453125],
              [50.7939453125, 51.72919921875],
              [50.75615234375002, 51.675146484375],
              [50.64394531250002, 51.589160156249996],
              [50.51630859375001, 51.505615234375],
              [50.35371093750001, 51.3697265625],
              [50.30927734375001, 51.321582031249996],
              [50.246875, 51.289501953125],
              [50.10488281250002, 51.25458984375],
              [49.93232421875001, 51.197167968749994],
              [49.822265625, 51.131884765624996],
              [49.66630859375002, 51.102294921875],
              [49.498046875, 51.08359375],
              [49.42460937500002, 51.027001953124994],
              [49.379492187500006, 50.93466796875],
              [49.32343750000001, 50.851708984374994],
              [49.058691406250006, 50.72607421875],
              [48.91376953125001, 50.644580078124996],
              [48.80839843750002, 50.601318359375],
              [48.73476562500002, 50.606884765625],
              [48.65517578125002, 50.619873046875],
              [48.625097656250006, 50.612695312499994],
              [48.666015625, 50.550341796874996],
              [48.70048828125002, 50.353759765625],
              [48.74941406250002, 50.228466796875],
              [48.784765625, 50.1564453125],
              [48.81796875, 50.099853515625],
              [48.84326171875, 50.013134765625],
              [48.81025390625001, 49.96240234375],
              [48.75898437500001, 49.9283203125],
              [48.6, 49.87470703125],
              [48.43427734375001, 49.828515625],
              [48.3349609375, 49.858251953125],
              [48.22480468750001, 49.931933593749996],
              [48.18134765625001, 49.970019531249996],
              [48.06074218750001, 50.093603515625],
              [47.849609375, 50.28232421875],
              [47.70576171875001, 50.377978515624996],
              [47.599609375, 50.41357421875],
              [47.50361328125001, 50.402734375],
              [47.42919921875, 50.357958984374996],
              [47.37636718750002, 50.318115234375],
              [47.32646484375002, 50.27353515625],
              [47.29472656250002, 50.21748046875],
              [47.29765625000002, 50.140234375],
              [47.29521484375002, 50.058496093749994],
              [47.24833984375002, 50.000878906249994],
              [47.12958984375001, 49.9390625],
              [46.99199218750002, 49.852734375],
              [46.88955078125002, 49.69697265625],
              [46.82314453125002, 49.502246093749996],
              [46.80205078125002, 49.367089843749994],
              [46.85292968750002, 49.303857421874994],
              [46.95341796875002, 49.252587890624994],
              [47.018164062500006, 49.19990234375],
              [47.031347656250006, 49.150292968749994],
              [47.014257812500006, 49.09833984375],
              [46.96220703125002, 49.038330078125],
              [46.85292968750002, 48.969628906249994],
              [46.70263671875, 48.805566406249994],
              [46.60917968750002, 48.573876953124994],
              [46.66093750000002, 48.412255859374994],
              [46.853125, 48.323583984375],
              [47.00429687500002, 48.284472656249996],
              [47.06464843750001, 48.232470703124996],
              [47.11904296875002, 48.127001953124996],
              [47.11152343750001, 48.0201171875],
              [47.09326171875, 47.947705078125],
              [47.13076171875002, 47.876757812499996],
              [47.20205078125002, 47.79248046875],
              [47.29238281250002, 47.74091796875],
              [47.387304687500006, 47.76865234375],
              [47.48193359375, 47.80390625],
              [47.600195312500006, 47.789990234375],
              [47.934667968750006, 47.760693359375],
              [48.109960937500006, 47.745410156249996],
              [48.1669921875, 47.708789062499996],
              [48.27568359375002, 47.58994140625],
              [48.4130859375, 47.456494140625],
              [48.55253906250002, 47.32099609375],
              [48.600683593750006, 47.2623046875],
              [48.71435546875, 47.100488281249994],
              [48.83183593750002, 46.954931640625],
              [48.959375, 46.774609375],
              [48.950292968750006, 46.72578125],
              [48.88359375000002, 46.705419921875],
              [48.7763671875, 46.710351562499994],
              [48.69355468750001, 46.73681640625],
              [48.647070312500006, 46.758691406249994],
              [48.60527343750002, 46.765917968749996],
              [48.55839843750002, 46.757128906249996],
              [48.5185546875, 46.734326171875],
              [48.50234375000002, 46.698632812499994],
              [48.50917968750002, 46.649951171874996],
              [48.54121093750001, 46.605615234374994],
              [48.586035156250006, 46.577099609375],
              [48.61015625000002, 46.566455078124996],
              [48.77431640625002, 46.507958984374994],
              [48.958984375, 46.442138671875],
              [49.18427734375001, 46.348828125],
              [49.23222656250002, 46.337158203125],
              [49.24589843750002, 46.2916015625],
              [49.12548828125, 46.28173828125],
              [49.11064453125002, 46.228466796875],
              [49.07958984375, 46.189208984375],
              [48.80996093750002, 46.100488281249994],
              [48.74257812500002, 46.100732421874994],
              [48.683691406250006, 46.086181640625],
              [48.68730468750002, 46.028759765625],
              [48.70341796875002, 45.976220703124994],
              [48.749609375, 45.920556640624994],
              [48.729589843750006, 45.896826171875],
              [48.68964843750001, 45.8888671875],
              [48.63740234375001, 45.90576171875],
              [48.58906250000001, 45.934863281249996],
              [48.53730468750001, 45.942138671875],
              [48.48701171875001, 45.934863281249996],
              [48.25761718750002, 45.777783203125],
              [48.1591796875, 45.73701171875],
              [48.052832031250006, 45.720996093749996],
              [47.830175781250006, 45.663037109375],
              [47.76396484375002, 45.665966796875],
              [47.70107421875002, 45.686181640624994],
              [47.64980468750002, 45.65673828125],
              [47.63330078125, 45.584033203124996],
              [47.57402343750002, 45.63427734375],
              [47.508398437500006, 45.674169921875],
              [47.47939453125002, 45.68759765625],
              [47.46328125000002, 45.6796875],
              [47.52421875000002, 45.601708984374994],
              [47.52949218750001, 45.530224609375],
              [47.51455078125002, 45.49091796875],
              [47.48867187500002, 45.455078125],
              [47.45449218750002, 45.433056640625],
              [47.4130859375, 45.421044921874994],
              [47.39111328125, 45.294775390625],
              [47.35126953125001, 45.217724609375],
              [47.29619140625002, 45.149462890624996],
              [47.22148437500002, 45.024267578125],
              [47.16152343750002, 44.969628906249994],
              [47.11474609375, 44.90595703125],
              [47.08378906250002, 44.8169921875],
              [47.03925781250001, 44.837890625],
              [47.0029296875, 44.87607421875],
              [46.98369140625002, 44.825585937499994],
              [46.95742187500002, 44.782568359375],
              [46.84121093750002, 44.71826171875],
              [46.75527343750002, 44.656542968749996],
              [46.71611328125002, 44.560693359374994],
              [46.70722656250001, 44.503320312499994],
              [46.72089843750001, 44.45166015625],
              [46.753027343750006, 44.420654296875],
              [46.91572265625001, 44.387158203125],
              [47.02363281250001, 44.34326171875],
              [47.12265625, 44.261669921875],
              [47.22988281250002, 44.1923828125],
              [47.30703125000002, 44.103125],
              [47.36152343750001, 43.993359375],
              [47.42919921875, 43.7798828125],
              [47.46279296875002, 43.555029296875],
              [47.562597656250006, 43.83466796875],
              [47.646484375, 43.884619140625],
              [47.62783203125002, 43.805957031249996],
              [47.56796875, 43.684960937499994],
              [47.50898437500001, 43.509716796875],
              [47.48984375, 43.381689453125],
              [47.51162109375002, 43.270751953125],
              [47.51289062500001, 43.21875],
              [47.46318359375002, 43.035058593749994],
              [47.488867187500006, 42.999755859375],
              [47.52900390625001, 42.967138671875],
              [47.634863281250006, 42.903466796874994],
              [47.709082031250006, 42.8109375],
              [47.72773437500001, 42.680712890624996],
              [47.76972656250001, 42.644775390625],
              [47.822363281250006, 42.6134765625],
              [48.080175781250006, 42.3537109375],
              [48.228613281250006, 42.180957031249996],
              [48.30302734375002, 42.080224609374994],
              [48.3837890625, 41.953417968749996],
              [48.426367187500006, 41.923974609374994],
              [48.47675781250001, 41.905126953125],
              [48.572851562500006, 41.844482421875],
              [48.518652343750006, 41.779345703124996],
              [48.4306640625, 41.663330078125],
              [48.39140625000002, 41.601904296875],
              [48.29814453125002, 41.54501953125],
              [48.14228515625001, 41.484765625],
              [48.05605468750002, 41.45869140625],
              [47.96367187500002, 41.333984375],
              [47.86113281250002, 41.212744140625],
              [47.791015625, 41.199267578124996],
              [47.591796875, 41.218115234375],
              [47.520605468750006, 41.229052734374996],
              [47.31767578125002, 41.282421875],
              [47.26113281250002, 41.315087890624994],
              [47.20527343750001, 41.455615234374996],
              [47.142578125, 41.516064453125],
              [47.06396484375, 41.5546875],
              [47.01015625000002, 41.5875],
              [46.98779296875, 41.621386718749996],
              [46.93085937500001, 41.67041015625],
              [46.82558593750002, 41.743408203125],
              [46.74931640625002, 41.81259765625],
              [46.69033203125002, 41.831347656249996],
              [46.61601562500002, 41.806933593749996],
              [46.5712890625, 41.800097656249996],
              [46.55214843750002, 41.812304687499996],
              [46.537695312500006, 41.870410156249996],
              [46.42988281250001, 41.890966796875],
              [46.41152343750002, 41.904638671875],
              [46.26777343750001, 41.960351562499994],
              [46.21269531250002, 41.989892578124994],
              [46.159765625, 41.992041015625],
              [46.0484375, 42.008740234375],
              [45.95400390625002, 42.035400390625],
              [45.91035156250001, 42.070703125],
              [45.84599609375002, 42.1099609375],
              [45.7265625, 42.15888671875],
              [45.63857421875002, 42.205078125],
              [45.63427734375, 42.234716796875],
              [45.68837890625002, 42.357373046875],
              [45.7275390625, 42.475048828125],
              [45.70527343750001, 42.498095703124996],
              [45.65556640625002, 42.51767578125],
              [45.56289062500002, 42.5357421875],
              [45.34375, 42.52978515625],
              [45.20820312500001, 42.648242187499996],
              [45.160253906250006, 42.675],
              [45.07158203125002, 42.694140625],
              [44.943359375, 42.730273437499996],
              [44.87099609375002, 42.756396484374996],
              [44.85048828125002, 42.746826171875],
              [44.77109375, 42.616796875],
              [44.69179687500002, 42.709619140624994],
              [44.64433593750002, 42.734716796875],
              [44.57646484375002, 42.748486328125],
              [44.505859375, 42.7486328125],
              [44.32949218750002, 42.703515625],
              [44.19970703125, 42.653613281249996],
              [44.10273437500001, 42.616357421874994],
              [44.00468750000002, 42.595605468749994],
              [43.95742187500002, 42.566552734374994],
              [43.82597656250002, 42.571533203125],
              [43.759863281250006, 42.59384765625],
              [43.738378906250006, 42.616992187499996],
              [43.74990234375002, 42.657519531249996],
              [43.79541015625, 42.702978515625],
              [43.79873046875002, 42.727783203125],
              [43.78261718750002, 42.747021484375],
              [43.623046875, 42.80771484375],
              [43.55781250000001, 42.844482421875],
              [43.34794921875002, 42.8966796875],
              [43.08916015625002, 42.9890625],
              [43.00019531250001, 43.049658203125],
              [42.99160156250002, 43.09150390625],
              [42.89003906250002, 43.132617187499996],
              [42.76064453125002, 43.169580078124994],
              [42.660253906250006, 43.159082031249994],
              [42.566015625, 43.155126953125],
              [42.419042968750006, 43.22421875],
              [42.27968750000002, 43.228076171874996],
              [42.12226562500001, 43.207324218749996],
              [42.08779296875002, 43.199121093749994],
              [42.05, 43.19013671875],
              [41.58056640625, 43.21923828125],
              [41.46074218750002, 43.276318359375],
              [41.35820312500002, 43.333398437499994],
              [41.083105468750006, 43.374462890625],
              [40.941992187500006, 43.41806640625],
              [40.80166015625002, 43.479931640625],
              [40.648046875, 43.53388671875],
              [40.51894531250002, 43.512011718749996],
              [40.34228515625, 43.542724609375],
              [40.15019531250002, 43.569775390625],
              [40.084570312500006, 43.553125],
              [40.02373046875002, 43.48486328125],
              [39.97832031250002, 43.419824218749994],
              [39.873632812500006, 43.472802734374994],
              [39.516699218750006, 43.727880859375],
              [39.32939453125002, 43.897265625],
              [38.71728515625, 44.2880859375],
              [38.635839843750006, 44.318017578124994],
              [38.31181640625002, 44.374462890625],
              [38.18125, 44.419677734375],
              [37.85146484375002, 44.698828125],
              [37.70488281250002, 44.661376953125],
              [37.57246093750001, 44.670849609375],
              [37.4951171875, 44.695263671875],
              [37.41132812500001, 44.7353515625],
              [37.35234375000002, 44.788378906249996],
              [37.28408203125002, 44.905029296875],
              [37.20478515625001, 44.971972656249996],
              [36.944433593750006, 45.069580078125],
              [36.65078125000002, 45.12646484375],
              [36.62763671875001, 45.151318359375],
              [36.619140625, 45.185498046875],
              [36.873046875, 45.251757812499996],
              [36.94121093750002, 45.289697265624994],
              [36.81103515625, 45.3400390625],
              [36.76162109375002, 45.34833984375],
              [36.72041015625001, 45.371875],
              [36.79375, 45.409716796874996],
              [36.86591796875001, 45.427050781249996],
              [36.97783203125002, 45.38359375],
              [37.103515625, 45.302880859374994],
              [37.21357421875001, 45.272314453125],
              [37.264257812500006, 45.3109375],
              [37.64716796875001, 45.377197265625],
              [37.672949218750006, 45.429736328124996],
              [37.671875, 45.48837890625],
              [37.634375, 45.486328125],
              [37.609960937500006, 45.49951171875],
              [37.612402343750006, 45.564697265625],
              [37.66923828125002, 45.654052734375],
              [37.840917968750006, 45.799560546875],
              [37.93310546875, 46.001708984375],
              [38.014257812500006, 46.047753906249994],
              [38.073828125, 46.01708984375],
              [38.06972656250002, 45.969873046874994],
              [38.07958984375, 45.934814453125],
              [38.1328125, 46.002832031249994],
              [38.18359375, 46.09482421875],
              [38.31181640625002, 46.095361328124994],
              [38.400390625, 46.080029296875],
              [38.492285156250006, 46.09052734375],
              [38.31523437500002, 46.241943359375],
              [38.077734375, 46.394335937499996],
              [37.9775390625, 46.382861328124996],
              [37.91386718750002, 46.406494140625],
              [37.8095703125, 46.532080078125],
              [37.76650390625002, 46.636132812499994],
              [37.86738281250001, 46.6337890625],
              [37.96796875000001, 46.618017578125],
              [38.15947265625002, 46.690673828125],
              [38.22998046875, 46.70126953125],
              [38.34345703125001, 46.6783203125],
              [38.5009765625, 46.663671875],
              [38.48798828125001, 46.732177734375],
              [38.43867187500001, 46.8130859375],
              [38.63076171875002, 46.873046875],
              [38.80107421875002, 46.906152343749994],
              [39.12675781250002, 47.0234375],
              [39.27070312500001, 47.044140625],
              [39.2890625, 47.0708984375],
              [39.29345703125, 47.105761718749996],
              [39.24453125000002, 47.199511718749996],
              [39.19570312500002, 47.268847656249996],
              [39.02373046875002, 47.272216796875],
              [38.928320312500006, 47.175683593749994],
              [38.66816406250001, 47.143945312499994],
              [38.55244140625001, 47.150341796875],
              [38.64433593750002, 47.212207031249996],
              [38.73603515625001, 47.23583984375],
              [38.76191406250001, 47.261621093749994],
              [38.577246093750006, 47.239111328125],
              [38.48476562500002, 47.175537109375],
              [38.21435546875, 47.091455078124994],
              [38.20585937500002, 47.135595703125],
              [38.20136718750001, 47.175244140625],
              [38.22119140625, 47.212744140625],
              [38.26533203125001, 47.236962890624994],
              [38.28076171875, 47.259033203125],
              [38.28076171875, 47.276660156249996],
              [38.24101562500002, 47.2876953125],
              [38.2080078125, 47.296533203124994],
              [38.20136718750001, 47.32080078125],
              [38.21240234375, 47.3427734375],
              [38.243261718750006, 47.373681640624994],
              [38.25654296875001, 47.408935546875],
              [38.2587890625, 47.479541015624996],
              [38.28740234375002, 47.5591796875],
              [38.36884765625001, 47.6099609375],
              [38.51093750000001, 47.622412109375],
              [38.640625, 47.665917968749994],
              [38.71894531250001, 47.714111328125],
              [38.822265625, 47.83701171875],
              [38.90029296875002, 47.855126953124994],
              [39.05781250000001, 47.848486328125],
              [39.15849609375002, 47.83740234375],
              [39.39101562500002, 47.833740234375],
              [39.65849609375002, 47.841210937499994],
              [39.7359375, 47.84482421875],
              [39.77871093750002, 47.887548828125],
              [39.77578125000002, 47.964453125],
              [39.81396484375, 48.035302734374994],
              [39.88505859375002, 48.168359375],
              [39.961035156250006, 48.237939453124994],
              [39.95791015625002, 48.268896484375],
              [39.91816406250001, 48.28193359375],
              [39.866308593750006, 48.288427734375],
              [39.84746093750002, 48.302783203124996],
              [39.84990234375002, 48.331933593749994],
              [39.88984375000001, 48.36044921875],
              [39.88261718750002, 48.419091796874994],
              [39.857519531250006, 48.484228515625],
              [39.83564453125001, 48.542773437499996],
              [39.76542968750002, 48.571875],
              [39.64472656250001, 48.591210937499994],
              [39.67041015625, 48.662451171875],
              [39.70458984375, 48.73935546875],
              [39.755859375, 48.782080078125],
              [39.79287109375002, 48.80771484375],
              [39.90410156250002, 48.79375],
              [39.984472656250006, 48.807373046875],
              [40.00361328125001, 48.822070312499996],
              [39.98916015625002, 48.851416015625],
              [39.86376953125, 48.877978515624996],
              [39.75332031250002, 48.914453125],
              [39.705664062500006, 48.9595703125],
              [39.6865234375, 49.00791015625],
              [39.75947265625001, 49.036572265625],
              [39.889746093750006, 49.0640625],
              [39.97636718750002, 49.129833984375],
              [40.07001953125001, 49.20029296875],
              [40.10878906250002, 49.2515625],
              [40.12832031250002, 49.3072265625],
              [40.12617187500001, 49.36884765625],
              [40.05781250000001, 49.431542968749994],
              [40.05781250000001, 49.4970703125],
              [40.09492187500001, 49.54267578125],
              [40.080664062500006, 49.57685546875],
              [40.03066406250002, 49.596728515624996],
              [39.95849609375, 49.590771484375],
              [39.87685546875002, 49.567675781249996],
              [39.78056640625002, 49.572021484375],
              [39.6265625, 49.650683593749996],
              [39.46279296875002, 49.72802734375],
              [39.36845703125002, 49.7306640625],
              [39.30292968750001, 49.742041015625],
              [39.24599609375002, 49.78193359375],
              [39.21181640625002, 49.833203125],
              [39.1748046875, 49.85595703125],
              [39.11494140625001, 49.841748046875],
              [39.02773437500002, 49.818408203124996],
              [38.91835937500002, 49.82470703125],
              [38.77666015625002, 49.884326171874996],
              [38.64775390625002, 49.952880859375],
              [38.55195312500001, 49.95458984375],
              [38.451171875, 49.9640625],
              [38.25859375000002, 50.05234375],
              [38.20869140625001, 50.05146484375],
              [38.17753906250002, 50.025390625],
              [38.162695312500006, 49.954541015625],
              [38.14677734375002, 49.939404296875],
              [38.1125, 49.92783203125],
              [38.046875, 49.92001953125],
              [37.950292968750006, 49.964208984375],
              [37.704199218750006, 50.10908203125],
              [37.605078125, 50.21494140625],
              [37.58232421875002, 50.291845703125],
              [37.50136718750002, 50.340722656249994],
              [37.4228515625, 50.411474609375],
              [37.34316406250002, 50.417626953124994],
              [37.2548828125, 50.394970703125],
              [37.17109375000001, 50.360888671874996],
              [37.13125, 50.351513671875],
              [36.98847656250001, 50.33955078125],
              [36.75908203125002, 50.291845703125],
              [36.696386718750006, 50.246240234374994],
              [36.61943359375002, 50.209228515625],
              [36.559667968750006, 50.23486328125],
              [36.49980468750002, 50.28046875],
              [36.36884765625001, 50.296826171875],
              [36.30605468750002, 50.28046875],
              [36.24335937500001, 50.311767578125],
              [36.189453125, 50.367822265624994],
              [36.11640625000001, 50.408544921875],
              [36.0078125, 50.419677734375],
              [35.890234375, 50.437109375],
              [35.79619140625002, 50.40576171875],
              [35.67373046875002, 50.345996093749996],
              [35.59111328125002, 50.36875],
              [35.545507812500006, 50.439990234374996],
              [35.48847656250001, 50.459912109375],
              [35.41162109375, 50.539697265624994],
              [35.391699218750006, 50.6109375],
              [35.41162109375, 50.642236328124994],
              [35.44013671875001, 50.682080078125],
              [35.44013671875001, 50.727685546874994],
              [35.41738281250002, 50.767578125],
              [35.38320312500002, 50.79892578125],
              [35.34609375000002, 50.904296875],
              [35.31474609375002, 50.94990234375],
              [35.30908203125, 50.9869140625],
              [35.33476562500002, 51.021142578124994],
              [35.31191406250002, 51.043896484375],
              [35.269140625, 51.046777343749994],
              [35.19804687500002, 51.043896484375],
              [35.15810546875002, 51.060986328125],
              [35.115332031250006, 51.120849609375],
              [35.09257812500002, 51.1806640625],
              [35.0640625, 51.203417968749996],
              [34.990234375, 51.2017578125],
              [34.86855468750002, 51.189208984375],
              [34.760351562500006, 51.169335937499994],
              [34.71230468750002, 51.172216796875],
              [34.616796875, 51.203125],
              [34.49101562500002, 51.237060546875],
              [34.23417968750002, 51.243798828124994],
              [34.2138671875, 51.25537109375],
              [34.22841796875002, 51.27685546875],
              [34.28066406250002, 51.311669921874994],
              [34.275, 51.340185546875],
              [34.22988281250002, 51.363232421875],
              [34.20654296875, 51.419921875],
              [34.20927734375002, 51.48408203125],
              [34.20087890625001, 51.55380859375],
              [34.14677734375002, 51.607958984374996],
              [34.11542968750001, 51.644970703125],
              [34.12109375, 51.679150390625],
              [34.23916015625002, 51.692236328125],
              [34.37929687500002, 51.71650390625],
              [34.40273437500002, 51.741503906249996],
              [34.39785156250002, 51.780419921875],
              [34.11308593750002, 51.979638671875],
              [34.01533203125001, 52.15595703125],
              [33.92207031250001, 52.25146484375],
              [33.81884765625, 52.315625],
              [33.73525390625002, 52.344775390624996],
              [33.613378906250006, 52.3326171875],
              [33.45185546875001, 52.333789062499996],
              [33.287109375, 52.353564453124996],
              [33.1484375, 52.3404296875],
              [32.89970703125002, 52.25634765625],
              [32.80644531250002, 52.25263671875],
              [32.64541015625002, 52.279101562499996],
              [32.507910156250006, 52.308544921875],
              [32.43544921875002, 52.3072265625],
              [32.39130859375001, 52.29482421875],
              [32.36298828125001, 52.272119140625],
              [32.2828125, 52.114013671875],
              [32.216796875, 52.082958984375],
              [32.12226562500001, 52.050585937499996],
              [32.041601562500006, 52.04501953125],
              [31.97382812500001, 52.046630859375],
              [31.875585937500006, 52.0708984375],
              [31.78242187500001, 52.099414062499996],
              [31.76337890625001, 52.10107421875],
              [31.758593750000017, 52.125830078125],
              [31.690625, 52.220654296875],
              [31.64990234375, 52.26220703125],
              [31.6015625, 52.284814453125],
              [31.57734375000001, 52.312304687499996],
              [31.576562500000023, 52.426025390625],
              [31.585546875, 52.532470703125],
              [31.61591796875001, 52.546191406249996],
              [31.526171875000017, 52.6330078125],
              [31.519433593750023, 52.69873046875],
              [31.5634765625, 52.7314453125],
              [31.564843750000023, 52.759228515625],
              [31.53515625, 52.7982421875],
              [31.442773437500023, 52.86181640625],
              [31.35302734375, 52.933447265625],
              [31.29511718750001, 52.989794921874996],
              [31.2587890625, 53.01669921875],
              [31.30292968750001, 53.060888671875],
              [31.364550781250017, 53.138964843749996],
              [31.38837890625001, 53.184814453125],
              [31.417871093750023, 53.196044921875],
              [31.56298828125, 53.202490234375],
              [31.668261718750017, 53.200927734375],
              [31.747460937500023, 53.1841796875],
              [31.777441406250006, 53.146875],
              [31.849707031250006, 53.106201171875],
              [32.05546875000002, 53.089453125],
              [32.14199218750002, 53.091162109375],
              [32.25068359375001, 53.128369140625],
              [32.42626953125, 53.210595703125],
              [32.469335937500006, 53.2703125],
              [32.57802734375002, 53.31240234375],
              [32.64443359375002, 53.32890625],
              [32.70429687500001, 53.336328125],
              [32.71025390625002, 53.371435546875],
              [32.70644531250002, 53.41943359375],
              [32.68574218750001, 53.44814453125],
              [32.46962890625002, 53.54697265625],
              [32.4423828125, 53.579248046875],
              [32.42519531250002, 53.61728515625],
              [32.45097656250002, 53.6533203125],
              [32.4501953125, 53.692919921874996],
              [32.20039062500001, 53.78125],
              [31.9921875, 53.796875],
              [31.82080078125, 53.791943359375],
              [31.754199218750017, 53.81044921875],
              [31.783007812500017, 53.85498046875],
              [31.825292968750006, 53.935009765625],
              [31.837792968750023, 54.00078125],
              [31.825976562500017, 54.030712890625],
              [31.7919921875, 54.055908203125],
              [31.62841796875, 54.111181640625],
              [31.403613281250017, 54.195947265625],
              [31.299121093750017, 54.29169921875],
              [31.245507812500023, 54.391650390624996],
              [31.18476562500001, 54.452978515625],
              [31.074804687500006, 54.491796875],
              [31.081933593750023, 54.51708984375],
              [31.154882812500006, 54.6109375],
              [31.152148437500017, 54.625341796875],
              [31.12128906250001, 54.648486328124996],
              [30.984179687500017, 54.6958984375],
              [30.798828125, 54.783251953124996],
              [30.791015625, 54.806005859375],
              [30.804492187500017, 54.8609375],
              [30.829882812500017, 54.914990234375],
              [30.866796875, 54.940722656249996],
              [30.97773437500001, 55.05048828125],
              [30.97773437500001, 55.08779296875],
              [30.958886718750023, 55.13759765625],
              [30.87744140625, 55.2234375],
              [30.814453125, 55.2787109375],
              [30.810546875, 55.306982421875],
              [30.82099609375001, 55.3302734375],
              [30.86181640625, 55.360400390624996],
              [30.90058593750001, 55.397412109375],
              [30.908789062500006, 55.525341796875],
              [30.906835937500006, 55.57001953125],
              [30.882226562500023, 55.59638671875],
              [30.85595703125, 55.60751953125],
              [30.80078125, 55.601123046874996],
              [30.7216796875, 55.622119140624996],
              [30.66230468750001, 55.65546875],
              [30.625585937500006, 55.666259765625],
              [30.586718750000017, 55.70029296875],
              [30.475390625000017, 55.768798828125],
              [30.45625, 55.78681640625],
              [30.23359375000001, 55.84521484375],
              [30.04267578125001, 55.83642578125],
              [29.93701171875, 55.845263671874996],
              [29.881640625000017, 55.832324218749996],
              [29.82392578125001, 55.7951171875],
              [29.744140625, 55.77041015625],
              [29.6845703125, 55.7697265625],
              [29.63007812500001, 55.751171875],
              [29.482226562500017, 55.6845703125],
              [29.412988281250023, 55.724853515625],
              [29.353417968750023, 55.784375],
              [29.373144531250006, 55.834716796875],
              [29.39794921875, 55.8810546875],
              [29.39609375, 55.91220703125],
              [29.375, 55.938720703125],
              [29.283007812500017, 55.96787109375],
              [29.08740234375, 56.021142578125],
              [29.03173828125, 56.021777343749996],
              [28.94746093750001, 56.002099609375],
              [28.794726562500017, 55.942578125],
              [28.740820312500006, 55.955371093749996],
              [28.690820312500023, 56.00263671875],
              [28.63691406250001, 56.061767578125],
              [28.56396484375, 56.0919921875],
              [28.407031250000017, 56.089013671875],
              [28.39208984375, 56.08671875],
              [28.316308593750023, 56.052539062499996],
              [28.284277343750006, 56.055908203125],
              [28.14794921875, 56.142919921875],
              [28.17333984375, 56.19033203125],
              [28.202050781250023, 56.260400390625],
              [28.191699218750017, 56.315576171875],
              [28.169238281250017, 56.386865234375],
              [28.11083984375, 56.510693359375],
              [28.103125, 56.545703125],
              [28.00751953125001, 56.599853515625],
              [27.991601562500023, 56.6453125],
              [27.94140625, 56.7037109375],
              [27.89208984375, 56.741064453125],
              [27.88154296875001, 56.824169921875],
              [27.8486328125, 56.85341796875],
              [27.806054687500023, 56.86708984375],
              [27.655664062500023, 56.843212890625],
              [27.639453125000017, 56.845654296875],
              [27.71113281250001, 56.978076171874996],
              [27.717382812500006, 57.054638671875],
              [27.762792968750006, 57.135107421875],
              [27.814550781250006, 57.16689453125],
              [27.83027343750001, 57.194482421875],
              [27.838281250000023, 57.247705078125],
              [27.82861328125, 57.293310546875],
              [27.796875, 57.316943359374996],
              [27.672753906250023, 57.368115234375],
              [27.538671875, 57.42978515625],
              [27.511132812500023, 57.508154296875],
              [27.4697265625, 57.5240234375],
              [27.351953125000023, 57.528125],
              [27.354296875000017, 57.55029296875],
              [27.37177734375001, 57.612548828125],
              [27.4, 57.666796875],
              [27.491992187500017, 57.724951171875],
              [27.514746093750006, 57.764208984374996],
              [27.542089843750006, 57.7994140625],
              [27.752832031250023, 57.841015625],
              [27.776953125, 57.856738281249996],
              [27.77851562500001, 57.870703125],
              [27.76875, 57.884130859375],
              [27.721972656250017, 57.90546875],
              [27.6734375, 57.934619140624996],
              [27.644140625, 58.013916015625],
              [27.571093750000017, 58.138085937499994],
              [27.50244140625, 58.221337890624994],
              [27.48779296875, 58.270068359375],
              [27.50556640625001, 58.32626953125],
              [27.530078125000017, 58.381494140624994],
              [27.531347656250006, 58.43525390625],
              [27.427050781250017, 58.733056640624994],
              [27.434179687500006, 58.787255859374994],
              [27.464453125, 58.84130859375],
              [27.513085937500023, 58.886279296875],
              [27.62177734375001, 58.944970703124994],
              [27.757617187500017, 59.052001953125],
              [27.849511718750023, 59.19267578125],
              [27.89765625000001, 59.27763671875],
              [27.93818359375001, 59.297021484374994],
              [28.016406250000017, 59.301708984375],
              [28.04609375000001, 59.32783203125],
              [28.061328125000017, 59.34326171875],
              [28.128320312500023, 59.357568359374994],
              [28.15107421875001, 59.374414062499994],
              [28.13300781250001, 59.403076171875],
              [28.065820312500023, 59.453173828125],
              [28.0125, 59.484277343749994],
              [28.06396484375, 59.55400390625],
              [28.046289062500023, 59.64716796875],
              [28.013964843750017, 59.724755859374994],
              [28.058007812500023, 59.78154296875],
              [28.131152343750017, 59.786523437499994],
              [28.2125, 59.724658203125],
              [28.334570312500006, 59.692529296874994],
              [28.423730468750023, 59.73408203125],
              [28.453906250000017, 59.8142578125],
              [28.518164062500006, 59.849560546875],
              [28.603906250000023, 59.81806640625],
              [28.74765625, 59.806689453125],
              [28.86689453125001, 59.811914062499994],
              [28.947265625, 59.828759765624994],
              [28.981542968750006, 59.85478515625],
              [29.01337890625001, 59.9015625],
              [29.0791015625, 59.960986328125],
              [29.147265625000017, 59.999755859375],
              [29.669726562500017, 59.9556640625],
              [30.12255859375, 59.873583984375],
              [30.156835937500006, 59.904296875],
              [30.172656250000017, 59.95712890625],
              [30.059960937500023, 60.002587890624994],
              [29.97675781250001, 60.0263671875],
              [29.87226562500001, 60.120849609375],
              [29.72119140625, 60.1953125],
              [29.5693359375, 60.20185546875],
              [29.370410156250017, 60.175927734374994],
              [29.069140625000017, 60.191455078125],
              [28.81269531250001, 60.33154296875],
              [28.643164062500006, 60.37529296875],
              [28.522265625000017, 60.482958984375],
              [28.491601562500023, 60.54013671875],
              [28.622460937500023, 60.491601562499994],
              [28.64033203125001, 60.542871093749994],
              [28.65058593750001, 60.610986328124994],
              [28.57783203125001, 60.6525390625],
              [28.512792968750006, 60.677294921875],
              [28.179296875, 60.57099609375],
              [27.797656250000017, 60.5361328125],
              [28.151953125, 60.745849609375],
              [28.40742187500001, 60.896923828125],
              [28.455078125, 60.91962890625],
              [28.568164062500017, 60.960205078125],
              [28.662890625000017, 61.002832031249994],
              [28.739062500000017, 61.058740234374994],
              [28.992968750000017, 61.16904296875],
              [29.25166015625001, 61.28779296875],
              [29.49238281250001, 61.444238281249994],
              [29.579394531250017, 61.49345703125],
              [29.69013671875001, 61.54609375],
              [29.933203125, 61.711572265624994],
              [30.00996093750001, 61.757373046875],
              [30.306445312500017, 61.96484375],
              [30.47968750000001, 62.068212890625],
              [30.565625, 62.127587890624994],
              [30.93574218750001, 62.323779296875],
              [31.18671875000001, 62.481396484375],
              [31.28564453125, 62.567822265625],
              [31.382421875, 62.691650390625],
              [31.437304687500017, 62.776123046875],
              [31.533984375000017, 62.885400390624994],
              [31.536523437500023, 62.921630859375],
              [31.50927734375, 62.955322265625],
              [31.43701171875, 63.007714843749994],
              [31.336718750000017, 63.06806640625],
              [31.247460937500023, 63.14189453125],
              [31.18085937500001, 63.20830078125],
              [30.97480468750001, 63.300634765625],
              [30.6552734375, 63.41748046875],
              [30.418554687500006, 63.504052734374994],
              [30.05537109375001, 63.689013671875],
              [29.991503906250017, 63.73515625],
              [30.00410156250001, 63.747314453125],
              [30.210253906250017, 63.8033203125],
              [30.415332031250017, 63.947509765625],
              [30.50390625, 64.02060546875],
              [30.52607421875001, 64.077294921875],
              [30.527929687500006, 64.14111328125],
              [30.513769531250006, 64.2],
              [30.487890625, 64.2365234375],
              [30.390625, 64.282421875],
              [30.10810546875001, 64.36611328125],
              [30.041894531250023, 64.443359375],
              [29.986621093750017, 64.524267578125],
              [29.98554687500001, 64.55771484375],
              [30.1201171875, 64.64462890625],
              [30.12617187500001, 64.6880859375],
              [30.110253906250023, 64.732568359375],
              [30.072851562500006, 64.7650390625],
              [29.783203125, 64.804296875],
              [29.70166015625, 64.845751953125],
              [29.6375, 64.911767578125],
              [29.60419921875001, 64.968408203125],
              [29.600878906250017, 65.001953125],
              [29.622460937500023, 65.039501953125],
              [29.720019531250017, 65.080322265625],
              [29.810839843750017, 65.10791015625],
              [29.826953125000017, 65.145068359375],
              [29.826171875, 65.185302734375],
              [29.810546875, 65.204736328125],
              [29.629687500000017, 65.223876953125],
              [29.612402343750006, 65.234765625],
              [29.608007812500006, 65.248681640625],
              [29.6171875, 65.26533203125],
              [29.71484375, 65.336962890625],
              [29.72802734375, 65.4734375],
              [29.819433593750006, 65.56875],
              [29.715917968750006, 65.624560546875],
              [29.723925781250017, 65.634375],
              [29.882617187500017, 65.663623046875],
              [30.02900390625001, 65.670703125],
              [30.0953125, 65.681689453125],
              [30.10273437500001, 65.72626953125],
              [30.0875, 65.7865234375],
              [29.936621093750006, 66.02294921875],
              [29.903417968750006, 66.091064453125],
              [29.803515625000017, 66.17705078125],
              [29.720703125, 66.23486328125],
              [29.6708984375, 66.276123046875],
              [29.590722656250023, 66.3568359375],
              [29.544335937500023, 66.439697265625],
              [29.46435546875, 66.532177734375],
              [29.371191406250006, 66.617041015625],
              [29.293261718750017, 66.6955078125],
              [29.093066406250017, 66.84921875],
              [29.066210937500017, 66.891748046875],
              [29.06904296875001, 66.930224609375],
              [29.087011718750006, 66.970947265625],
              [29.24335937500001, 67.09658203125],
              [29.3876953125, 67.201416015625],
              [29.572265625, 67.324365234375],
              [29.750585937500006, 67.426416015625],
              [29.941210937500017, 67.5474609375],
              [29.988085937500017, 67.66826171875],
              [29.97919921875001, 67.68857421875],
              [29.821582031250017, 67.75400390625],
              [29.524218750000017, 67.9291015625],
              [29.343847656250006, 68.061865234375],
              [29.06298828125, 68.11796875],
              [28.68515625, 68.189794921875],
              [28.56015625, 68.3513671875],
              [28.470703125, 68.48837890625],
              [28.479296875000017, 68.537646484375],
              [28.752050781250006, 68.771435546875],
              [28.777636718750017, 68.813818359375],
              [28.772851562500023, 68.8400390625],
              [28.74482421875001, 68.8564453125],
              [28.705957031250023, 68.86552734375],
              [28.453515625000023, 68.872265625],
              [28.4140625, 68.904150390625],
              [28.566015625, 68.92822265625],
              [28.692187500000017, 68.96103515625],
              [28.89892578125, 69.00966796875],
              [28.9658203125, 69.02197265625],
              [29.118554687500023, 69.049951171875],
              [29.1708984375, 69.071533203125],
              [29.2099609375, 69.097021484375],
              [29.35302734375, 69.27060546875],
              [29.38828125, 69.29814453125],
              [29.83271484375001, 69.36044921875],
              [29.994042968750023, 69.39248046875],
              [30.087304687500023, 69.432861328125],
              [30.1318359375, 69.4642578125],
              [30.16376953125001, 69.501611328125],
              [30.18671875000001, 69.5427734375],
              [30.19648437500001, 69.58056640625],
              [30.159765625, 69.6298828125],
              [30.18017578125, 69.63583984375],
              [30.2275390625, 69.6337890625],
              [30.379687500000017, 69.584716796875],
              [30.61542968750001, 69.532568359375],
              [30.788867187500017, 69.528515625],
              [30.860742187500023, 69.538427734375],
              [30.89667968750001, 69.56123046875],
              [30.922460937500006, 69.605810546875],
              [30.924121093750017, 69.6517578125],
              [30.869726562500006, 69.783447265625],
              [31.04951171875001, 69.76923828125],
              [31.452734375, 69.689599609375],
              [31.546972656250006, 69.696923828125],
              [31.66621093750001, 69.72099609375],
              [31.78857421875, 69.815771484375],
              [31.87939453125, 69.831982421875],
              [31.997949218750023, 69.809912109375],
              [32.03056640625002, 69.835302734375],
              [31.969335937500006, 69.913916015625],
              [31.98457031250001, 69.953662109375],
              [32.3916015625, 69.868701171875],
              [32.5654296875, 69.806494140625],
              [32.94169921875002, 69.75185546875],
              [33.0078125, 69.722119140625],
              [33.01259765625002, 69.6705078125],
              [32.99462890625, 69.626171875],
              [32.9150390625, 69.601708984375],
              [32.75429687500002, 69.605712890625],
              [32.1767578125, 69.6740234375],
              [32.09150390625001, 69.632568359375],
              [32.16132812500001, 69.596630859375],
              [32.33056640625, 69.554248046875],
              [32.37773437500002, 69.4791015625],
              [32.636816406250006, 69.489453125],
              [32.8837890625, 69.46083984375],
              [32.99980468750002, 69.4701171875],
              [33.02099609375, 69.44560546875],
              [32.94160156250001, 69.383349609375],
              [32.97890625000002, 69.367333984375],
              [33.255859375, 69.427734375],
              [33.384863281250006, 69.444287109375],
              [33.45429687500001, 69.428173828125],
              [33.46367187500002, 69.378173828125],
              [33.41796875, 69.315283203125],
              [33.41298828125002, 69.267431640625],
              [33.327734375, 69.15185546875],
              [33.196386718750006, 69.116845703125],
              [33.141210937500006, 69.068701171875],
              [33.33339843750002, 69.098193359375],
              [33.435644531250006, 69.13037109375],
              [33.627050781250006, 69.28916015625],
              [33.684375, 69.31025390625],
              [34.22939453125002, 69.313134765625],
              [34.35273437500001, 69.3029296875],
              [34.86396484375001, 69.228076171875],
              [35.00957031250002, 69.221240234375],
              [35.175878906250006, 69.230810546875],
              [35.23320312500002, 69.265576171875],
              [35.28984375000002, 69.275439453125],
              [35.85791015625, 69.191748046875],
              [36.618261718750006, 69.003466796875],
              [37.730566406250006, 68.692138671875],
              [38.35761718750001, 68.41513671875],
              [38.43017578125, 68.355615234375],
              [38.656835937500006, 68.321875],
              [38.70556640625, 68.3447265625],
              [38.83154296875, 68.32490234375],
              [39.568945312500006, 68.071728515625],
              [39.823339843750006, 68.05859375],
              [39.78974609375001, 68.112158203125],
              [39.74628906250001, 68.16220703125],
              [39.80927734375001, 68.150830078125],
              [39.895605468750006, 68.114501953125],
              [40.035742187500006, 68.015380859375],
              [40.206640625, 67.94189453125],
              [40.38066406250002, 67.831884765625],
              [40.52578125000002, 67.789697265625],
              [40.65654296875002, 67.774072265625],
              [40.76630859375001, 67.743017578125],
              [40.96640625, 67.7134765625],
              [41.06093750000002, 67.444189453125],
              [41.133886718750006, 67.38603515625],
              [41.133886718750006, 67.266943359375],
              [41.26171875, 67.21845703125],
              [41.35878906250002, 67.20966796875],
              [41.35429687500002, 67.121435546875],
              [41.27558593750001, 66.914306640625],
              [41.18896484375, 66.826171875],
              [40.521582031250006, 66.446630859375],
              [40.10332031250002, 66.299951171875],
              [39.2890625, 66.13203125],
              [38.65390625, 66.06904296875],
              [38.397558593750006, 66.064453125],
              [37.90068359375002, 66.09560546875],
              [37.62822265625002, 66.12958984375],
              [37.29482421875002, 66.225048828125],
              [36.98369140625002, 66.27255859375],
              [36.76992187500002, 66.2935546875],
              [36.37343750000002, 66.302294921875],
              [35.51347656250002, 66.39580078125],
              [35.36396484375001, 66.428662109375],
              [34.82460937500002, 66.6111328125],
              [34.61025390625002, 66.559619140625],
              [34.48261718750001, 66.550341796875],
              [34.39609375, 66.61318359375],
              [34.43085937500001, 66.62978515625],
              [34.45156250000002, 66.651220703125],
              [34.14609375, 66.703271484375],
              [33.893652343750006, 66.70673828125],
              [33.75957031250002, 66.7509765625],
              [33.59541015625001, 66.784619140625],
              [33.52294921875, 66.76435546875],
              [33.48203125, 66.76455078125],
              [33.15019531250002, 66.8439453125],
              [33.001953125, 66.90830078125],
              [32.84755859375002, 67.021533203125],
              [32.88525390625, 67.0611328125],
              [32.93046875000002, 67.08681640625],
              [32.39990234375, 67.152685546875],
              [31.895312500000017, 67.16142578125],
              [31.983007812500006, 67.129833984375],
              [32.20156250000002, 67.113232421875],
              [32.340625, 67.06787109375],
              [32.5009765625, 67.003857421875],
              [32.46367187500002, 66.91630859375],
              [32.68642578125002, 66.829541015625],
              [32.85732421875002, 66.746923828125],
              [32.862402343750006, 66.72138671875],
              [32.9287109375, 66.7041015625],
              [33.18056640625002, 66.679931640625],
              [33.22441406250002, 66.603857421875],
              [33.18291015625002, 66.573876953125],
              [33.217382812500006, 66.531640625],
              [33.4052734375, 66.48427734375],
              [33.517675781250006, 66.47138671875],
              [33.65595703125001, 66.442626953125],
              [33.59326171875, 66.3845703125],
              [33.47695312500002, 66.346875],
              [33.36054687500001, 66.329541015625],
              [33.41582031250002, 66.315625],
              [33.56669921875002, 66.32099609375],
              [34.11269531250002, 66.225244140625],
              [34.39980468750002, 66.12841796875],
              [34.69179687500002, 65.95185546875],
              [34.78632812500001, 65.86455078125],
              [34.79316406250001, 65.816357421875],
              [34.776953125, 65.76826171875],
              [34.73476562500002, 65.71630859375],
              [34.71552734375001, 65.6640625],
              [34.61572265625, 65.509912109375],
              [34.54414062500001, 65.456689453125],
              [34.40644531250001, 65.395751953125],
              [34.53593750000002, 65.2779296875],
              [34.67109375000001, 65.168115234375],
              [34.80351562500002, 64.985986328125],
              [34.8271484375, 64.9126953125],
              [34.832617187500006, 64.8001953125],
              [34.952246093750006, 64.75595703125],
              [34.90546875000001, 64.738671875],
              [34.85830078125002, 64.706689453125],
              [34.86953125000002, 64.560009765625],
              [35.03535156250001, 64.440234375],
              [35.28408203125002, 64.362548828125],
              [35.43203125000002, 64.34677734375],
              [35.647070312500006, 64.3783203125],
              [35.80205078125002, 64.3353515625],
              [36.146484375, 64.189013671875],
              [36.30195312500001, 64.034375],
              [36.36494140625001, 64.00283203125],
              [36.71376953125002, 63.945068359375],
              [36.975195312500006, 63.909521484375],
              [37.37275390625001, 63.816748046875],
              [37.44218750000002, 63.813378906249994],
              [37.635351562500006, 63.893408203125],
              [37.96796875000001, 63.949121093749994],
              [38.07080078125, 64.025830078125],
              [38.06220703125001, 64.091015625],
              [37.977148437500006, 64.20703125],
              [37.953710937500006, 64.3201171875],
              [37.84355468750002, 64.36630859375],
              [37.740625, 64.39697265625],
              [37.42958984375002, 64.373583984375],
              [37.28955078125, 64.3779296875],
              [37.183691406250006, 64.40849609375],
              [37.04042968750002, 64.48916015625],
              [36.76933593750002, 64.68525390625],
              [36.62421875000001, 64.750537109375],
              [36.578710937500006, 64.790966796875],
              [36.52822265625002, 64.84736328125],
              [36.53457031250002, 64.938623046875],
              [36.652929687500006, 64.93544921875],
              [36.78593750000002, 64.987158203125],
              [36.8828125, 65.17236328125],
              [37.05019531250002, 65.1958984375],
              [37.14082031250001, 65.194287109375],
              [37.528125, 65.108251953125],
              [38.009375, 64.878759765625],
              [38.11572265625, 64.85458984375],
              [38.22822265625001, 64.851220703125],
              [38.412109375, 64.857080078125],
              [38.441992187500006, 64.8271484375],
              [38.54091796875002, 64.791259765625],
              [38.61308593750002, 64.786669921875],
              [39.05351562500002, 64.713916015625],
              [39.5673828125, 64.570556640625],
              [39.75800781250001, 64.57705078125],
              [39.8330078125, 64.656396484375],
              [39.8486328125, 64.69052734375],
              [40.05781250000001, 64.770751953125],
              [40.203710937500006, 64.784033203125],
              [40.4078125, 64.7548828125],
              [40.444921875, 64.7787109375],
              [40.37539062500002, 64.8962890625],
              [40.28125, 64.998095703125],
              [40.142675781250006, 65.06328125],
              [39.896484375, 65.25478515625],
              [39.79804687500001, 65.349853515625],
              [39.749121093750006, 65.44794921875],
              [39.78115234375002, 65.534716796875],
              [39.816503906250006, 65.59794921875],
              [40.32783203125001, 65.751708984375],
              [40.512792968750006, 65.843798828125],
              [40.69160156250001, 65.963427734375],
              [40.7744140625, 65.987890625],
              [41.07607421875002, 66.02109375],
              [41.47578125000001, 66.1234375],
              [41.780859375, 66.259326171875],
              [42.08359375, 66.46591796875],
              [42.210546875, 66.519677734375],
              [42.31367187500001, 66.51474609375],
              [42.45078125, 66.482421875],
              [42.602148437500006, 66.422509765625],
              [42.80654296875002, 66.411328125],
              [43.005957031250006, 66.420947265625],
              [43.23320312500002, 66.41552734375],
              [43.550878906250006, 66.3212890625],
              [43.60332031250002, 66.2912109375],
              [43.653125, 66.2509765625],
              [43.550390625, 66.173388671875],
              [43.54189453125002, 66.123388671875],
              [43.62392578125002, 66.146728515625],
              [43.73701171875001, 66.1583984375],
              [43.84375, 66.1423828125],
              [43.94414062500002, 66.098681640625],
              [44.016699218750006, 66.049755859375],
              [44.10439453125002, 66.00859375],
              [44.132421875, 66.06455078125],
              [44.14531250000002, 66.112744140625],
              [44.09716796875, 66.23505859375],
              [44.220703125, 66.407080078125],
              [44.31640625, 66.481689453125],
              [44.48867187500002, 66.67177734375],
              [44.437109375, 66.79462890625],
              [44.429296875, 66.937744140625],
              [44.40390625, 67.00419921875],
              [44.29179687500002, 67.099658203125],
              [44.07441406250001, 67.167333984375],
              [43.85537109375002, 67.188623046875],
              [43.78242187500001, 67.2544921875],
              [43.79570312500002, 67.32958984375],
              [43.85634765625002, 67.439306640625],
              [44.03642578125002, 67.670654296875],
              [44.22539062500002, 67.99560546875],
              [44.231542968750006, 68.071240234375],
              [44.2138671875, 68.11259765625],
              [44.22646484375002, 68.154443359375],
              [44.2046875, 68.253759765625],
              [44.16914062500001, 68.327099609375],
              [43.40400390625001, 68.608544921875],
              [43.358007812500006, 68.635791015625],
              [43.33320312500001, 68.673388671875],
              [43.41328125000001, 68.68173828125],
              [43.47197265625002, 68.679833984375],
              [44.04804687500001, 68.548828125],
              [44.17529296875, 68.541748046875],
              [45.078125, 68.578173828125],
              [45.51943359375002, 68.546533203125],
              [45.89199218750002, 68.4796875],
              [46.15839843750001, 68.291357421875],
              [46.4296875, 68.11884765625],
              [46.68359375, 67.970458984375],
              [46.6904296875, 67.848828125],
              [46.42890625000001, 67.823681640625],
              [46.17421875000002, 67.8181640625],
              [45.52871093750002, 67.757568359375],
              [45.374121093750006, 67.6888671875],
              [44.939453125, 67.47744140625],
              [44.90214843750002, 67.413134765625],
              [44.939453125, 67.35078125],
              [45.13886718750001, 67.284716796875],
              [45.56220703125001, 67.185595703125],
              [45.752539062500006, 66.98916015625],
              [45.885351562500006, 66.891064453125],
              [45.98603515625001, 66.853125],
              [46.083984375, 66.843505859375],
              [46.29775390625002, 66.842822265625],
              [46.44853515625002, 66.818994140625],
              [46.49238281250001, 66.8001953125],
              [46.55234375, 66.818994140625],
              [46.69082031250002, 66.825537109375],
              [47.49648437500002, 66.929833984375],
              [47.655859375, 66.975927734375],
              [47.709082031250006, 67.04501953125],
              [47.76806640625, 67.275634765625],
              [47.83925781250002, 67.355712890625],
              [47.908203125, 67.4546875],
              [47.88261718750002, 67.51533203125],
              [47.87470703125001, 67.5841796875],
              [48.27871093750002, 67.650390625],
              [48.65380859375, 67.695263671875],
              [48.83320312500001, 67.681494140625],
              [48.8779296875, 67.73134765625],
              [48.7626953125, 67.827001953125],
              [48.69570312500002, 67.87421875],
              [48.75429687500002, 67.895947265625],
              [48.840625, 67.8697265625],
              [48.95390625000002, 67.85380859375],
              [49.1552734375, 67.87041015625],
              [49.93125, 68.06513671875],
              [50.23320312500002, 68.175341796875],
              [50.4140625, 68.218359375],
              [50.69941406250001, 68.317724609375],
              [50.8388671875, 68.349951171875],
              [51.07851562500002, 68.363330078125],
              [51.33613281250001, 68.40244140625],
              [51.61669921875, 68.476318359375],
              [51.994726562500006, 68.53876953125],
              [52.0556640625, 68.54130859375],
              [52.12880859375002, 68.53203125],
              [52.28535156250001, 68.459375],
              [52.22744140625002, 68.418603515625],
              [52.18349609375002, 68.374267578125],
              [52.25917968750002, 68.350927734375],
              [52.322265625, 68.339697265625],
              [52.39667968750001, 68.351708984375],
              [52.475, 68.38212890625],
              [52.66972656250002, 68.4267578125],
              [52.72265625, 68.484033203125],
              [52.64765625000001, 68.50615234375],
              [52.55009765625002, 68.592431640625],
              [52.43505859375, 68.610205078125],
              [52.34404296875002, 68.608154296875],
              [52.68359375, 68.731201171875],
              [53.41289062500002, 68.912548828125],
              [53.80195312500001, 68.9958984375],
              [54.18583984375002, 69.0033203125],
              [54.4912109375, 68.992333984375],
              [54.37626953125002, 68.96474609375],
              [53.87441406250002, 68.926611328125],
              [53.79765625000002, 68.907470703125],
              [53.79824218750002, 68.88466796875],
              [53.91953125, 68.871240234375],
              [53.97060546875002, 68.844287109375],
              [53.929296875, 68.811865234375],
              [53.891210937500006, 68.801513671875],
              [53.83388671875002, 68.708935546875],
              [53.758886718750006, 68.633984375],
              [53.91767578125001, 68.536962890625],
              [53.93085937500001, 68.435546875],
              [53.82949218750002, 68.382666015625],
              [53.690039062500006, 68.4025390625],
              [53.56669921875002, 68.36708984375],
              [53.34257812500002, 68.343212890625],
              [53.29335937500002, 68.311669921875],
              [53.26054687500002, 68.26748046875],
              [53.403125, 68.2568359375],
              [53.51513671875, 68.25966796875],
              [53.913671875, 68.231201171875],
              [53.967871093750006, 68.22734375],
              [54.09921875, 68.259033203125],
              [54.23291015625, 68.26630859375],
              [54.39394531250002, 68.27509765625],
              [54.476171875, 68.294140625],
              [54.56123046875001, 68.273046875],
              [54.71796875000001, 68.1841796875],
              [54.861328125, 68.20185546875],
              [54.92304687500001, 68.373828125],
              [55.15087890625, 68.480029296875],
              [55.418066406250006, 68.567822265625],
              [55.67529296875, 68.57587890625],
              [55.92460937500002, 68.6373046875],
              [56.04365234375001, 68.648876953125],
              [56.27568359375002, 68.624072265625],
              [56.620214843750006, 68.61904296875],
              [56.909375, 68.56669921875],
              [57.12685546875002, 68.55400390625],
              [57.4443359375, 68.64150390625],
              [58.17304687500001, 68.88974609375],
              [58.23701171875001, 68.833935546875],
              [58.35390625000002, 68.9162109375],
              [58.9189453125, 69.00380859375],
              [59.05732421875001, 69.0060546875],
              [59.05986328125002, 68.97255859375],
              [59.11015625000002, 68.8962890625],
              [59.22041015625001, 68.849609375],
              [59.37050781250002, 68.73837890625],
              [59.29833984375, 68.708447265625],
              [59.22255859375002, 68.69130859375],
              [59.1123046875, 68.61630859375],
              [59.09902343750002, 68.4443359375],
              [59.31074218750001, 68.40029296875],
              [59.60429687500002, 68.351123046875],
              [59.725683593750006, 68.351611328125],
              [59.82753906250002, 68.380322265625],
              [59.85878906250002, 68.396044921875],
              [59.89736328125002, 68.421923828125],
              [59.9228515625, 68.471337890625],
              [59.94140625, 68.510498046875],
              [59.86513671875002, 68.604931640625],
              [59.89599609375, 68.70634765625],
              [60.160253906250006, 68.69951171875],
              [60.48916015625002, 68.728955078125],
              [60.6376953125, 68.78701171875],
              [60.81513671875001, 68.89521484375],
              [60.93359375, 68.986767578125],
              [60.85859375000001, 69.1455078125],
              [60.66455078125, 69.11025390625],
              [60.33730468750002, 69.45703125],
              [60.17060546875001, 69.59091796875],
              [60.276464843750006, 69.65263671875],
              [60.558691406250006, 69.692333984375],
              [60.81298828125, 69.821142578125],
              [60.90908203125002, 69.847119140625],
              [61.01591796875002, 69.85146484375],
              [61.7705078125, 69.763037109375],
              [62.63125, 69.743115234375],
              [63.361425781250006, 69.67529296875],
              [64.1904296875, 69.53466796875],
              [64.59218750000002, 69.43564453125],
              [64.92851562500002, 69.325390625],
              [64.89628906250002, 69.247802734375],
              [65.03154296875002, 69.26982421875],
              [65.3267578125, 69.2013671875],
              [65.5279296875, 69.1734375],
              [65.73574218750002, 69.13232421875],
              [65.81269531250001, 69.077001953125],
              [66.08476562500002, 69.036328125],
              [66.365625, 68.961328125],
              [66.41611328125, 68.9478515625],
              [66.7564453125, 68.8919921875],
              [67.00244140625, 68.873583984375],
              [67.14921875000002, 68.753955078125],
              [67.6396484375, 68.579296875],
              [67.73076171875002, 68.513671875],
              [68.15693359375001, 68.403662109375],
              [68.37119140625, 68.3142578125],
              [68.50419921875002, 68.3484375],
              [68.82949218750002, 68.567431640625],
              [69.02431640625002, 68.81796875],
              [69.14052734375002, 68.950634765625],
              [68.9244140625, 68.956201171875],
              [68.76289062500001, 68.9173828125],
              [68.65957031250002, 68.927392578125],
              [68.54277343750002, 68.96708984375],
              [68.355078125, 69.067578125],
              [68.11738281250001, 69.23623046875],
              [68.07304687500002, 69.42080078125],
              [68.005859375, 69.480029296875],
              [67.77431640625002, 69.52998046875],
              [67.62412109375, 69.584423828125],
              [67.064453125, 69.693701171875],
              [66.96406250000001, 69.65556640625],
              [66.9341796875, 69.5966796875],
              [66.89667968750001, 69.55380859375],
              [66.84023437500002, 69.6091796875],
              [66.80400390625002, 69.659228515625],
              [66.80292968750001, 69.74013671875],
              [66.83222656250001, 69.8421875],
              [66.9263671875, 70.0142578125],
              [67.06904296875001, 70.005615234375],
              [67.14443359375002, 70.030615234375],
              [67.2392578125, 70.108056640625],
              [67.19746093750001, 70.171630859375],
              [67.146484375, 70.219921875],
              [67.1568359375, 70.2951171875],
              [67.246875, 70.50009765625],
              [67.284765625, 70.738720703125],
              [67.2115234375, 70.7984375],
              [67.14335937500002, 70.837548828125],
              [66.82246093750001, 70.79736328125],
              [66.70224609375, 70.818505859375],
              [66.67519531250002, 70.864697265625],
              [66.66611328125, 70.9005859375],
              [66.7587890625, 70.962353515625],
              [66.84707031250002, 71.063720703125],
              [66.69257812500001, 71.04169921875],
              [66.6396484375, 71.081396484375],
              [66.76806640625, 71.139892578125],
              [66.917578125, 71.282373046875],
              [67.27421875000002, 71.3478515625],
              [67.54179687500002, 71.41201171875],
              [67.959375, 71.548388671875],
              [68.26923828125001, 71.6828125],
              [68.46943359375001, 71.85263671875],
              [68.607421875, 72.012744140625],
              [68.8296875, 72.391552734375],
              [69.0390625, 72.669921875],
              [69.39140625000002, 72.955517578125],
              [69.61181640625, 72.98193359375],
              [69.6943359375, 72.9775390625],
              [69.708984375, 72.956396484375],
              [69.6587890625, 72.9318359375],
              [69.6451171875, 72.89755859375],
              [69.73828125, 72.8849609375],
              [69.8875, 72.882568359375],
              [70.17216796875002, 72.901171875],
              [70.65537109375, 72.890380859375],
              [71.50019531250001, 72.913671875],
              [71.61699218750002, 72.902099609375],
              [71.92958984375002, 72.819677734375],
              [72.10097656250002, 72.82900390625],
              [72.44638671875, 72.79033203125],
              [72.6337890625, 72.744482421875],
              [72.812109375, 72.69140625],
              [72.78740234375002, 72.482958984375],
              [72.7529296875, 72.3431640625],
              [72.62441406250002, 72.079443359375],
              [72.57412109375002, 72.012548828125],
              [72.375, 71.821630859375],
              [72.27949218750001, 71.6955078125],
              [72.12968750000002, 71.6091796875],
              [71.91201171875002, 71.54794921875],
              [71.884375, 71.511376953125],
              [71.86728515625, 71.457373046875],
              [72.07929687500001, 71.306689453125],
              [72.58134765625002, 71.151123046875],
              [72.70449218750002, 70.963232421875],
              [72.73164062500001, 70.8228515625],
              [72.7, 70.45732421875],
              [72.6533203125, 70.40341796875],
              [72.56191406250002, 70.345556640625],
              [72.46943359375001, 70.274951171875],
              [72.52968750000002, 70.172509765625],
              [72.59941406250002, 69.793212890625],
              [72.615625, 69.484033203125],
              [72.55732421875001, 69.37841796875],
              [72.52705078125001, 69.154248046875],
              [72.52734375, 69.080517578125],
              [72.5767578125, 68.968701171875],
              [72.6783203125, 68.874853515625],
              [72.81191406250002, 68.815234375],
              [73.19072265625002, 68.706787109375],
              [73.54804687500001, 68.57451171875],
              [73.57343750000001, 68.5326171875],
              [73.59169921875002, 68.481884765625],
              [73.46523437500002, 68.43076171875],
              [73.26640625000002, 68.294482421875],
              [73.13945312500002, 68.18134765625],
              [73.12939453125, 68.09091796875],
              [73.17304687500001, 67.973046875],
              [73.15214843750002, 67.8650390625],
              [73.06679687500002, 67.766943359375],
              [72.94873046875, 67.696240234375],
              [72.5943359375, 67.586962890625],
              [71.84746093750002, 67.0076171875],
              [71.66816406250001, 66.939697265625],
              [71.365234375, 66.9615234375],
              [71.44892578125001, 66.878955078125],
              [71.55117187500002, 66.76044921875],
              [71.53955078125, 66.68310546875],
              [71.34199218750001, 66.68671875],
              [71.065625, 66.6044921875],
              [70.939453125, 66.54814453125],
              [70.72490234375002, 66.51943359375],
              [70.56142578125002, 66.548681640625],
              [70.3828125, 66.602490234375],
              [70.40888671875001, 66.647607421875],
              [70.44257812500001, 66.66826171875],
              [70.56796875, 66.70087890625],
              [70.69072265625002, 66.7453125],
              [70.63076171875002, 66.75419921875],
              [70.5791015625, 66.753759765625],
              [70.4439453125, 66.697314453125],
              [70.28339843750001, 66.685791015625],
              [70.09375, 66.754345703125],
              [69.94863281250002, 66.82998046875],
              [69.87714843750001, 66.845458984375],
              [69.74042968750001, 66.814599609375],
              [69.2177734375, 66.82861328125],
              [69.0787109375, 66.81591796875],
              [69.01347656250002, 66.788330078125],
              [69.05117187500002, 66.766357421875],
              [69.09111328125002, 66.723583984375],
              [69.14394531250002, 66.64072265625],
              [69.1943359375, 66.578662109375],
              [69.41201171875002, 66.5107421875],
              [69.70097656250002, 66.4845703125],
              [69.982421875, 66.401416015625],
              [70.339453125, 66.3423828125],
              [71.1455078125, 66.366650390625],
              [71.3580078125, 66.359423828125],
              [71.565625, 66.333740234375],
              [71.9169921875, 66.246728515625],
              [72.06757812500001, 66.2533203125],
              [72.32158203125002, 66.33212890625],
              [72.38398437500001, 66.50654296875],
              [72.41738281250002, 66.560791015625],
              [73.34160156250002, 66.8068359375],
              [73.51357421875002, 66.861083984375],
              [73.79208984375, 66.9953125],
              [73.88330078125, 67.0849609375],
              [73.98623046875002, 67.327685546875],
              [74.07451171875002, 67.414111328125],
              [74.67607421875002, 67.69462890625],
              [74.76953125, 67.766357421875],
              [74.78730468750001, 67.897509765625],
              [74.77822265625002, 67.9859375],
              [74.74267578125, 68.07353515625],
              [74.632421875, 68.218310546875],
              [74.51123046875, 68.303076171875],
              [74.39140625000002, 68.42060546875],
              [74.48095703125, 68.65888671875],
              [74.57958984375, 68.751220703125],
              [75.124609375, 68.86171875],
              [75.58955078125001, 68.901171875],
              [76.10751953125003, 68.975732421875],
              [76.31601562500003, 68.99150390625],
              [76.45917968750001, 68.978271484375],
              [76.60576171874999, 68.897607421875],
              [76.73505859375001, 68.776904296875],
              [77.11171875000002, 68.59619140625],
              [77.23847656250001, 68.469580078125],
              [77.26103515624999, 68.315576171875],
              [77.24843750000002, 67.941015625],
              [77.17441406250003, 67.778515625],
              [77.32509765625002, 67.73564453125],
              [77.39560546875003, 67.698681640625],
              [77.57919921875003, 67.6439453125],
              [77.67509765624999, 67.589599609375],
              [77.77158203125003, 67.570263671875],
              [77.98554687500001, 67.5591796875],
              [78.58955078125001, 67.578466796875],
              [78.92246093750003, 67.589111328125],
              [78.88759765625002, 67.613134765625],
              [78.83906250000001, 67.631201171875],
              [78.55908203125, 67.639111328125],
              [78.16123046875003, 67.678369140625],
              [77.58828125000002, 67.751904296875],
              [77.52011718750003, 67.909619140625],
              [77.53593749999999, 68.007666015625],
              [77.66484374999999, 68.190380859375],
              [77.7568359375, 68.22236328125],
              [77.86826171875003, 68.234716796875],
              [77.9951171875, 68.25947265625],
              [77.95869140625001, 68.37705078125],
              [77.90683593750003, 68.482275390625],
              [77.78525390625003, 68.63046875],
              [77.65068359374999, 68.90302734375],
              [77.46630859375, 68.905126953125],
              [77.32832031250001, 68.958642578125],
              [76.64492187500002, 69.1173828125],
              [76.0009765625, 69.23505859375],
              [75.5611328125, 69.251806640625],
              [75.42001953125, 69.238623046875],
              [75.05351562500002, 69.11630859375],
              [74.81484375000002, 69.090576171875],
              [74.36259765625002, 69.144580078125],
              [73.97744140625002, 69.1146484375],
              [73.83603515625, 69.143212890625],
              [73.77568359375002, 69.1982421875],
              [73.89091796875002, 69.41796875],
              [73.83271484375001, 69.50390625],
              [73.66328125000001, 69.61708984375],
              [73.56015625, 69.7072265625],
              [73.578125, 69.802978515625],
              [73.83017578125, 70.17568359375],
              [73.93740234375002, 70.2728515625],
              [74.20673828125001, 70.445458984375],
              [74.343359375, 70.5787109375],
              [74.31093750000002, 70.65361328125],
              [73.73154296875, 71.068701171875],
              [73.57656250000002, 71.21650390625],
              [73.50722656250002, 71.263525390625],
              [73.365234375, 71.319775390625],
              [73.15048828125, 71.385205078125],
              [73.08623046875002, 71.444921875],
              [73.67177734375002, 71.845068359375],
              [73.939453125, 71.91474609375],
              [74.31123046875001, 71.9578125],
              [74.48906250000002, 71.997021484375],
              [74.80410156250002, 72.077392578125],
              [74.9921875, 72.14482421875],
              [75.05322265625, 72.19921875],
              [75.08994140625, 72.263134765625],
              [75.09707031250002, 72.420654296875],
              [75.06035156250002, 72.548779296875],
              [75.00800781250001, 72.61943359375],
              [74.896875, 72.710107421875],
              [74.78681640625001, 72.811865234375],
              [74.86494140625001, 72.838427734375],
              [74.94218750000002, 72.85380859375],
              [75.15244140625, 72.852734375],
              [75.36933593750001, 72.796630859375],
              [75.47490234375002, 72.685009765625],
              [75.603515625, 72.5810546875],
              [75.603125, 72.512158203125],
              [75.59140625, 72.4572265625],
              [75.64433593750002, 72.382275390625],
              [75.69111328125001, 72.35],
              [75.74140625000001, 72.296240234375],
              [75.69443359375, 72.253515625],
              [75.64433593750002, 72.23232421875],
              [75.55019531250002, 72.17080078125],
              [75.39453125, 71.983203125],
              [75.27382812500002, 71.958935546875],
              [75.24746093750002, 71.81337890625],
              [75.50322265625002, 71.654638671875],
              [75.46855468750002, 71.534375],
              [75.41718750000001, 71.494677734375],
              [75.2802734375, 71.430078125],
              [75.29804687500001, 71.378466796875],
              [75.33203125, 71.341748046875],
              [75.73359375000001, 71.26591796875],
              [76.11044921875003, 71.2185546875],
              [76.74199218749999, 71.20205078125],
              [76.92900390624999, 71.127880859375],
              [76.99521484375003, 71.1810546875],
              [77.58964843749999, 71.167919921875],
              [78.06826171875002, 70.986328125],
              [78.32060546874999, 70.930419921875],
              [78.52578125000002, 70.91181640625],
              [78.94218749999999, 70.9337890625],
              [79.01542968749999, 70.9501953125],
              [79.08388671875002, 71.002001953125],
              [78.888671875, 70.99716796875],
              [78.80351562499999, 70.97353515625],
              [78.72392578124999, 70.9759765625],
              [78.58769531249999, 70.993896484375],
              [78.49140625000001, 71.025390625],
              [78.38652343749999, 71.087109375],
              [78.21259765625001, 71.26630859375],
              [77.90839843750001, 71.324072265625],
              [77.70664062500003, 71.3005859375],
              [77.48105468750003, 71.311572265625],
              [77.11367187500002, 71.409375],
              [76.87119140625003, 71.44658203125],
              [76.43339843749999, 71.552490234375],
              [76.31210937500003, 71.595458984375],
              [76.21572265625002, 71.682861328125],
              [76.10361328125003, 71.82900390625],
              [76.03242187500001, 71.910400390625],
              [76.1240234375, 71.926611328125],
              [76.42167968749999, 72.006005859375],
              [76.87138671874999, 72.0330078125],
              [77.06132812499999, 72.00419921875],
              [77.55078125, 71.84208984375],
              [77.77753906250001, 71.83642578125],
              [78.18691406250002, 71.907080078125],
              [78.232421875, 71.952294921875],
              [78.14082031250001, 72.044677734375],
              [78.01640624999999, 72.092041015625],
              [77.78066406250002, 72.114306640625],
              [77.49287109375001, 72.071728515625],
              [77.41083984375001, 72.107763671875],
              [77.43974609374999, 72.15654296875],
              [77.47158203125002, 72.192138671875],
              [77.62529296874999, 72.201416015625],
              [77.73320312499999, 72.22919921875],
              [77.96816406250002, 72.3287109375],
              [78.22539062499999, 72.37744140625],
              [78.48261718750001, 72.394970703125],
              [79.42207031250001, 72.38076171875],
              [79.95390624999999, 72.223046875],
              [80.47402343750002, 72.153125],
              [80.69921875, 72.098291015625],
              [80.7625, 72.08916015625],
              [80.81474609374999, 72.054296875],
              [80.85605468750003, 71.97021484375],
              [81.51123046875, 71.746142578125],
              [81.66162109375, 71.715966796875],
              [82.07988281249999, 71.7068359375],
              [82.54726562500002, 71.75859375],
              [82.7578125, 71.764111328125],
              [82.98613281249999, 71.748681640625],
              [83.10664062500001, 71.7205078125],
              [83.23359375000001, 71.6681640625],
              [83.16552734375, 71.602197265625],
              [83.10566406250001, 71.562451171875],
              [82.97705078125, 71.4513671875],
              [82.91796875, 71.419921875],
              [82.4931640625, 71.29287109375],
              [82.32285156250003, 71.260009765625],
              [82.27695312500003, 71.09345703125],
              [82.25429687500002, 71.056201171875],
              [82.23916015625002, 70.997705078125],
              [82.31601562500003, 70.879443359375],
              [82.3359375, 70.807373046875],
              [82.27070312500001, 70.70673828125],
              [82.16318359375003, 70.59814453125],
              [82.18242187499999, 70.511474609375],
              [82.22119140625, 70.395703125],
              [82.23583984375, 70.4302734375],
              [82.2314453125, 70.48291015625],
              [82.25839843750003, 70.543603515625],
              [82.45166015625, 70.690087890625],
              [82.59248046875001, 70.88994140625],
              [82.73779296875, 70.94208984375],
              [82.869140625, 70.954833984375],
              [83.01015625000002, 70.89541015625],
              [83.05107421874999, 70.815234375],
              [83.05839843749999, 70.6947265625],
              [83.03017578125002, 70.580517578125],
              [82.91982421875002, 70.407421875],
              [82.74248046874999, 70.286474609375],
              [82.68232421875001, 70.217724609375],
              [82.76728515625001, 70.154052734375],
              [82.85654296875003, 70.104541015625],
              [82.96103515625003, 70.08828125],
              [83.08076171875001, 70.093017578125],
              [83.10957031250001, 70.1095703125],
              [83.13203125000001, 70.157177734375],
              [83.09414062500002, 70.22109375],
              [83.07382812500003, 70.276708984375],
              [83.29345703125, 70.321337890625],
              [83.4970703125, 70.345263671875],
              [83.65986328125001, 70.418359375],
              [83.70048828124999, 70.46640625],
              [83.73593750000003, 70.546484375],
              [83.65126953125002, 70.672216796875],
              [83.57890624999999, 70.76591796875],
              [83.33388671875002, 70.988525390625],
              [83.15126953125002, 71.10361328125],
              [83.26601562500002, 71.27587890625],
              [83.45761718750003, 71.467529296875],
              [83.53105468749999, 71.5142578125],
              [83.55048828125001, 71.54365234375],
              [83.5712890625, 71.594384765625],
              [83.55351562499999, 71.6498046875],
              [83.534375, 71.683935546875],
              [83.34042968750003, 71.8275390625],
              [83.20029296875003, 71.87470703125],
              [82.75507812500001, 71.90283203125],
              [82.64541015625002, 71.925244140625],
              [82.31914062499999, 72.071826171875],
              [82.28066406250002, 72.105126953125],
              [82.20927734374999, 72.211181640625],
              [82.18359375, 72.237548828125],
              [82.09365234375002, 72.2654296875],
              [81.79287109375002, 72.326611328125],
              [81.58623046874999, 72.351708984375],
              [81.28271484375, 72.358837890625],
              [81.09814453125, 72.38974609375],
              [80.82705078125002, 72.48828125],
              [80.79775390625002, 72.519970703125],
              [80.71962890625002, 72.647900390625],
              [80.65625, 72.71201171875],
              [80.67539062500003, 72.7591796875],
              [80.77373046874999, 72.860791015625],
              [80.84160156249999, 72.949169921875],
              [80.75742187500003, 73.025244140625],
              [80.638671875, 73.049169921875],
              [80.50966796875002, 73.086083984375],
              [80.45546875000002, 73.155224609375],
              [80.42451171875001, 73.23115234375],
              [80.4189453125, 73.2896484375],
              [80.39804687500003, 73.3568359375],
              [80.45830078124999, 73.413720703125],
              [80.59589843750001, 73.4740234375],
              [80.56191406250002, 73.514990234375],
              [80.58320312500001, 73.56845703125],
              [81.46884765625003, 73.6404296875],
              [81.81699218750003, 73.658837890625],
              [83.54472656249999, 73.66650390625],
              [83.6669921875, 73.686474609375],
              [84.41738281250002, 73.722021484375],
              [84.73789062500003, 73.762841796875],
              [85.07744140624999, 73.71953125],
              [85.20058593750002, 73.721533203125],
              [85.44833984375003, 73.734619140625],
              [85.61142578125003, 73.82158203125],
              [85.97929687499999, 73.85693359375],
              [86.59140625000003, 73.894287109375],
              [86.89296875000002, 73.887109375],
              [86.96132812500002, 73.8607421875],
              [87.02949218750001, 73.824169921875],
              [86.69765625000002, 73.716845703125],
              [86.36591796875001, 73.619775390625],
              [86.09414062500002, 73.5783203125],
              [85.82705078125002, 73.4927734375],
              [85.80048828125001, 73.458935546875],
              [85.79257812500003, 73.438330078125],
              [85.80244140625001, 73.3716796875],
              [85.81816406249999, 73.326953125],
              [86.09814453125, 73.272607421875],
              [86.30791015624999, 73.195751953125],
              [86.51435546875001, 73.140478515625],
              [86.67705078124999, 73.106787109375],
              [86.71503906250001, 73.125830078125],
              [86.12167968750003, 73.30673828125],
              [85.97080078125003, 73.3470703125],
              [85.91005859375002, 73.3904296875],
              [85.93896484375, 73.456494140625],
              [85.99892578125002, 73.48583984375],
              [86.09238281250003, 73.519140625],
              [86.15507812499999, 73.53466796875],
              [86.37626953124999, 73.56884765625],
              [87.1201171875, 73.6150390625],
              [87.29443359375, 73.7046875],
              [87.36953125000002, 73.755908203125],
              [87.57119140625002, 73.8107421875],
              [87.50322265624999, 73.832470703125],
              [87.3375, 73.846044921875],
              [87.20966796875001, 73.878662109375],
              [86.69707031249999, 74.1953125],
              [86.57109374999999, 74.24375],
              [86.17783203125003, 74.27939453125],
              [86.00136718750002, 74.316015625],
              [86.18291015624999, 74.423046875],
              [86.39580078124999, 74.45009765625],
              [86.53847656250002, 74.44423828125],
              [86.66474609375001, 74.4142578125],
              [86.89794921875, 74.325341796875],
              [87.22968750000001, 74.3638671875],
              [87.10615234375001, 74.403564453125],
              [86.89423828125001, 74.44970703125],
              [86.70009765625002, 74.5224609375],
              [86.42568359375002, 74.585498046875],
              [86.11611328125002, 74.628564453125],
              [85.791015625, 74.6451171875],
              [85.88076171875002, 74.740234375],
              [86.05888671874999, 74.72822265625],
              [86.11953125000002, 74.757421875],
              [86.20126953125003, 74.8162109375],
              [86.65146484375003, 74.682421875],
              [86.86289062500003, 74.71787109375],
              [87.04179687499999, 74.778857421875],
              [87.41933593750002, 74.94091796875],
              [87.46757812499999, 75.013232421875],
              [87.28740234374999, 75.0525390625],
              [87.14072265625003, 75.072265625],
              [86.93906250000003, 75.068115234375],
              [86.92167968749999, 75.11279296875],
              [87.00595703125003, 75.16982421875],
              [87.17080078125002, 75.191748046875],
              [87.67138671875, 75.12958984375],
              [88.50371093749999, 75.290478515625],
              [88.73310546875001, 75.369189453125],
              [89.31025390625001, 75.4701171875],
              [89.59511718750002, 75.458251953125],
              [90.18496093750002, 75.591064453125],
              [91.00468749999999, 75.649560546875],
              [91.4794921875, 75.649658203125],
              [91.84541015625001, 75.723681640625],
              [92.40751953124999, 75.749658203125],
              [92.6025390625, 75.7791015625],
              [93.5498046875, 75.8541015625],
              [94.0751953125, 75.912890625],
              [94.15634765625003, 75.959228515625],
              [93.68701171875, 75.92158203125],
              [93.57402343749999, 75.956298828125],
              [93.47548828125002, 75.932861328125],
              [93.40605468749999, 75.90126953125],
              [93.178125, 75.958984375],
              [93.11630859375003, 75.94462890625],
              [93.06865234374999, 75.912841796875],
              [92.98662109374999, 75.902685546875],
              [92.89042968749999, 75.9099609375],
              [92.85859375000001, 75.9794921875],
              [92.97158203125002, 76.07509765625],
              [93.10488281250002, 76.025830078125],
              [93.25927734375, 76.098779296875],
              [93.35957031250001, 76.100732421875],
              [93.6484375, 76.054150390625],
              [93.84287109375003, 76.101318359375],
              [94.10234374999999, 76.123583984375],
              [94.38828125000003, 76.102783203125],
              [94.50673828125002, 76.107958984375],
              [94.57558593750002, 76.1517578125],
              [95.03847656250002, 76.113525390625],
              [95.35927734375002, 76.139599609375],
              [95.57871093750003, 76.1373046875],
              [95.919921875, 76.113134765625],
              [96.07548828124999, 76.081982421875],
              [95.98603515625001, 76.00966796875],
              [95.6533203125, 75.8921875],
              [95.74384765625001, 75.872314453125],
              [95.93476562500001, 75.926025390625],
              [96.50859374999999, 76.00556640625],
              [96.6005859375, 75.989892578125],
              [96.53769531250003, 75.921630859375],
              [96.4970703125, 75.8912109375],
              [96.87919921874999, 75.9310546875],
              [97.20546875000002, 76.018701171875],
              [97.35068359375003, 76.0333984375],
              [97.49921875000001, 75.980224609375],
              [97.6376953125, 76.029052734375],
              [97.66982421875002, 76.07802734375],
              [97.91835937500002, 76.088671875],
              [98.02001953125, 76.13369140625],
              [98.19462890624999, 76.16640625],
              [98.34199218750001, 76.18056640625],
              [98.66201171875002, 76.24267578125],
              [98.77128906249999, 76.2240234375],
              [98.98466796874999, 76.207568359375],
              [99.18730468749999, 76.17763671875],
              [99.56269531250001, 76.109326171875],
              [99.615625, 76.08232421875],
              [99.66318359375003, 76.07802734375],
              [99.77041015625002, 76.028759765625],
              [99.68925781249999, 75.95634765625],
              [99.60234374999999, 75.85205078125],
              [99.44218749999999, 75.803173828125],
              [99.54072265625001, 75.798583984375],
              [99.609375, 75.811279296875],
              [99.7375, 75.8806640625],
              [99.85136718749999, 75.9302734375],
              [99.82539062500001, 76.1359375],
              [99.61679687500003, 76.240185546875],
              [99.46064453125001, 76.27509765625],
              [99.09384765625003, 76.384326171875],
              [98.96953124999999, 76.430810546875],
              [98.8056640625, 76.4806640625],
              [98.86943359374999, 76.5095703125],
              [99.57626953125003, 76.471435546875],
              [99.93574218750001, 76.489892578125],
              [100.32236328125003, 76.479150390625],
              [100.84375, 76.5251953125],
              [101.06074218750001, 76.47724609375],
              [101.31074218750001, 76.47890625],
              [101.59775390625003, 76.439208984375],
              [101.68378906250001, 76.485498046875],
              [101.21298828125003, 76.535693359375],
              [101.00263671875001, 76.530517578125],
              [100.92802734374999, 76.55673828125],
              [101.00625, 76.615087890625],
              [101.09931640625001, 76.70400390625],
              [101.00820312500002, 76.78134765625],
              [100.92041015625, 76.822509765625],
              [100.90585937500003, 76.90068359375],
              [100.98994140625001, 76.990478515625],
              [101.18574218750001, 77.028564453125],
              [101.29287109375002, 77.1015625],
              [101.51767578125003, 77.198095703125],
              [102.61015624999999, 77.508544921875],
              [103.13144531250003, 77.62646484375],
              [103.33125, 77.641064453125],
              [103.56074218750001, 77.63193359375],
              [104.01455078125002, 77.730419921875],
              [104.18486328124999, 77.73046875],
              [104.81425781249999, 77.652099609375],
              [104.96523437500002, 77.5947265625],
              [105.30898437500002, 77.54921875],
              [105.71025390624999, 77.525244140625],
              [105.89453125, 77.4888671875],
              [105.9833984375, 77.447607421875],
              [106.0595703125, 77.39052734375],
              [105.73417968749999, 77.352001953125],
              [105.38457031249999, 77.237841796875],
              [104.91191406249999, 77.17470703125],
              [104.32363281250002, 77.132666015625],
              [104.20244140624999, 77.101806640625],
              [105.32021484375002, 77.092333984375],
              [105.64589843750002, 77.10068359375],
              [105.71201171875003, 77.00146484375],
              [105.82216796875002, 76.997509765625],
              [106.14541015625002, 77.0453125],
              [106.33867187499999, 77.0478515625],
              [106.705078125, 77.01376953125],
              [106.78369140625, 77.031787109375],
              [106.94160156250001, 77.034375],
              [107.27890625000003, 76.990966796875],
              [107.42978515625003, 76.9265625],
              [107.19023437499999, 76.822021484375],
              [106.94091796875, 76.73046875],
              [106.63876953125003, 76.573388671875],
              [106.54550781250003, 76.586279296875],
              [106.38466796875002, 76.589453125],
              [106.41357421875, 76.512255859375],
              [106.68320312500003, 76.514697265625],
              [106.82539062500001, 76.480078125],
              [107.15771484375, 76.524072265625],
              [107.62421875000001, 76.510107421875],
              [107.72216796875, 76.522314453125],
              [107.90224609375002, 76.569677734375],
              [107.94990234375001, 76.66064453125],
              [108.02792968750003, 76.71845703125],
              [108.181640625, 76.737841796875],
              [108.35205078125, 76.71953125],
              [108.63837890625001, 76.7201171875],
              [109.36933593750001, 76.74921875],
              [109.98115234375001, 76.711865234375],
              [110.47148437499999, 76.7583984375],
              [111.11484375000003, 76.723046875],
              [111.39248046875002, 76.686669921875],
              [111.6005859375, 76.622314453125],
              [111.7861328125, 76.603564453125],
              [111.93867187500001, 76.55341796875],
              [112.09394531250001, 76.480322265625],
              [112.01679687500001, 76.420556640625],
              [111.94267578124999, 76.38046875],
              [112.14277343750001, 76.423974609375],
              [112.29707031250001, 76.43466796875],
              [112.41328125000001, 76.40830078125],
              [112.61953125000002, 76.383544921875],
              [112.68417968750003, 76.21884765625],
              [112.74257812500002, 76.1869140625],
              [112.79843750000003, 76.129638671875],
              [112.721875, 76.077197265625],
              [112.65625, 76.053564453125],
              [112.81894531250003, 76.05859375],
              [113.04667968749999, 76.114111328125],
              [113.09404296874999, 76.13291015625],
              [113.150390625, 76.17451171875],
              [113.06601562500003, 76.215234375],
              [112.98798828125001, 76.23974609375],
              [113.08603515625003, 76.25810546875],
              [113.27265625000001, 76.25166015625],
              [113.36552734374999, 76.178857421875],
              [113.427734375, 76.112109375],
              [113.56386718750002, 75.891650390625],
              [113.85722656249999, 75.9212890625],
              [113.87099609375002, 75.856005859375],
              [113.74873046875001, 75.70478515625],
              [113.61992187499999, 75.59267578125],
              [113.56757812500001, 75.568408203125],
              [113.48593750000003, 75.56396484375],
              [113.51718750000003, 75.621875],
              [113.46904296874999, 75.656689453125],
              [113.3916015625, 75.677880859375],
              [113.12636718750002, 75.698681640625],
              [112.62919921874999, 75.835400390625],
              [112.49667968750003, 75.84990234375],
              [112.46611328124999, 75.84365234375],
              [112.45302734375002, 75.83017578125],
              [112.72958984375003, 75.737646484375],
              [112.95566406250003, 75.571923828125],
              [113.16152343750002, 75.6205078125],
              [113.24296874999999, 75.61142578125],
              [113.35625, 75.53427734375],
              [113.55888671874999, 75.50205078125],
              [113.72617187500003, 75.450634765625],
              [113.61357421874999, 75.29296875],
              [112.92490234375003, 75.0150390625],
              [112.19199218750003, 74.853173828125],
              [111.86826171875003, 74.7400390625],
              [111.29902343750001, 74.658447265625],
              [110.89277343750001, 74.548095703125],
              [110.37353515625, 74.466064453125],
              [110.22587890624999, 74.378662109375],
              [109.84033203125, 74.32197265625],
              [109.86640625000001, 74.29306640625],
              [109.91132812500001, 74.261328125],
              [109.86386718750003, 74.20888671875],
              [109.81025390625001, 74.169189453125],
              [109.51083984375003, 74.088818359375],
              [109.075, 74.03232421875],
              [108.19951171874999, 73.694091796875],
              [107.76542968749999, 73.625],
              [107.27109375000003, 73.621044921875],
              [107.1669921875, 73.589404296875],
              [106.79423828124999, 73.37666015625],
              [106.67939453125001, 73.3306640625],
              [106.18867187500001, 73.3080078125],
              [105.67714843750002, 72.95927734375],
              [105.39277343750001, 72.841015625],
              [105.14394531250002, 72.77705078125],
              [105.40273437500002, 72.78994140625],
              [105.70820312500001, 72.836669921875],
              [106.06669921874999, 72.949853515625],
              [106.15957031250002, 73.002001953125],
              [106.20878906249999, 73.060546875],
              [106.31503906250003, 73.106396484375],
              [106.47792968750002, 73.139404296875],
              [107.10878906250002, 73.177294921875],
              [107.36875, 73.163134765625],
              [107.75039062500002, 73.17314453125],
              [108.00126953124999, 73.235595703125],
              [108.15097656250003, 73.25791015625],
              [108.28535156250001, 73.265869140625],
              [108.35146484375002, 73.310205078125],
              [108.57539062500001, 73.31904296875],
              [109.08994140625003, 73.37841796875],
              [109.165625, 73.399609375],
              [109.3310546875, 73.487451171875],
              [109.63710937500002, 73.45400390625],
              [109.85527343749999, 73.4724609375],
              [110.4287109375, 73.62890625],
              [110.77333984375002, 73.68916015625],
              [110.8681640625, 73.730712890625],
              [110.79921875000002, 73.759765625],
              [110.72236328125001, 73.779931640625],
              [110.38828125000003, 73.726025390625],
              [110.09121093750002, 73.708544921875],
              [109.75273437499999, 73.72255859375],
              [109.70673828125001, 73.74375],
              [109.665625, 73.800244140625],
              [109.77412109375001, 73.88125],
              [109.869140625, 73.930615234375],
              [110.08388671875002, 73.994384765625],
              [110.26142578125001, 74.017431640625],
              [110.92011718750001, 73.947900390625],
              [111.05625, 73.93935546875],
              [111.130859375, 74.05283203125],
              [111.34140625000003, 74.04736328125],
              [111.55058593749999, 74.028515625],
              [111.4599609375, 74.004833984375],
              [111.228125, 73.9685546875],
              [111.29951171875001, 73.88486328125],
              [111.400390625, 73.827734375],
              [111.8037109375, 73.745263671875],
              [112.14726562499999, 73.708935546875],
              [112.4, 73.7111328125],
              [112.79541015625, 73.74609375],
              [112.85595703125, 73.771142578125],
              [112.93964843750001, 73.83564453125],
              [112.8359375, 73.962060546875],
              [112.93496093750002, 73.945703125],
              [113.03281250000003, 73.9138671875],
              [113.18154296875002, 73.83740234375],
              [113.32685546875001, 73.707421875],
              [113.41621093750001, 73.647607421875],
              [113.36445312500001, 73.582763671875],
              [113.15693359375001, 73.4595703125],
              [113.27695312500003, 73.39150390625],
              [113.49091796875001, 73.34609375],
              [113.48759765624999, 73.1451171875],
              [113.474609375, 73.0478515625],
              [113.36933593750001, 72.94189453125],
              [113.24736328124999, 72.897216796875],
              [113.12783203125002, 72.8306640625],
              [113.158203125, 72.769482421875],
              [113.18613281250003, 72.73017578125],
              [113.31220703125001, 72.657373046875],
              [113.66455078125, 72.634521484375],
              [113.7119140625, 72.654150390625],
              [113.63007812500001, 72.677099609375],
              [113.39140624999999, 72.71103515625],
              [113.29814453124999, 72.7388671875],
              [113.21552734375001, 72.805859375],
              [113.3115234375, 72.8783203125],
              [113.41748046875, 72.932177734375],
              [113.54277343749999, 73.054345703125],
              [113.58144531250002, 73.142236328125],
              [113.55888671874999, 73.2326171875],
              [113.63916015625, 73.273583984375],
              [113.76523437500003, 73.31796875],
              [113.82929687500001, 73.3265625],
              [113.88623046875, 73.34580078125],
              [113.79511718750001, 73.367431640625],
              [113.71132812500002, 73.378564453125],
              [113.53945312500002, 73.433642578125],
              [113.51035156250003, 73.50498046875],
              [113.85693359375, 73.5333984375],
              [114.060546875, 73.58466796875],
              [114.81601562500003, 73.607177734375],
              [115.33769531249999, 73.702587890625],
              [116.49550781250002, 73.67607421875],
              [117.30859375, 73.599169921875],
              [118.4501953125, 73.589794921875],
              [118.87089843749999, 73.537890625],
              [118.91123046875003, 73.518359375],
              [118.93642578125002, 73.481201171875],
              [118.75449218750003, 73.464501953125],
              [118.45703125, 73.464404296875],
              [118.37656250000003, 73.367236328125],
              [118.43027343750003, 73.246533203125],
              [118.96035156250002, 73.11728515625],
              [119.42529296875, 73.06396484375],
              [119.75039062500002, 72.9791015625],
              [119.92167968749999, 72.971337890625],
              [120.59794921874999, 72.981103515625],
              [120.99716796875003, 72.93671875],
              [121.35429687499999, 72.970849609375],
              [121.74785156249999, 72.969677734375],
              [121.88603515624999, 72.960888671875],
              [122.02978515625, 72.897216796875],
              [122.26015625000002, 72.88056640625],
              [122.5375, 72.877783203125],
              [122.69208984375001, 72.8908203125],
              [122.751953125, 72.906494140625],
              [122.73085937500002, 72.931298828125],
              [122.501953125, 72.970654296875],
              [122.52675781250002, 73.01669921875],
              [122.615234375, 73.0279296875],
              [122.99931640624999, 72.9646484375],
              [123.16035156250001, 72.9548828125],
              [123.30117187500002, 73.001806640625],
              [123.40458984374999, 73.08564453125],
              [123.46162109375001, 73.144189453125],
              [123.521875, 73.172900390625],
              [123.57246093750001, 73.17734375],
              [123.62226562500001, 73.19326171875],
              [123.5009765625, 73.26162109375],
              [123.38388671875003, 73.347314453125],
              [123.35527343749999, 73.402490234375],
              [123.32265625000002, 73.430810546875],
              [123.30507812500002, 73.53291015625],
              [123.41621093750001, 73.636865234375],
              [123.49111328125002, 73.666357421875],
              [123.796875, 73.6267578125],
              [123.93388671874999, 73.689306640625],
              [124.01904296875, 73.7123046875],
              [124.38808593750002, 73.754833984375],
              [124.54121093750001, 73.75126953125],
              [124.79628906250002, 73.711767578125],
              [125.61708984375002, 73.52060546875],
              [125.59853515625002, 73.447412109375],
              [125.79443359375, 73.46845703125],
              [125.88789062500001, 73.498095703125],
              [126.107421875, 73.51748046875],
              [126.25449218750003, 73.548193359375],
              [126.29599609375003, 73.536669921875],
              [126.34492187500001, 73.506298828125],
              [126.30888671874999, 73.463671875],
              [126.25742187500003, 73.419775390625],
              [126.29248046875, 73.394189453125],
              [126.33544921875, 73.38876953125],
              [126.55253906249999, 73.334912109375],
              [126.83847656250003, 73.4341796875],
              [126.95517578125003, 73.52822265625],
              [127.03134765625003, 73.5474609375],
              [127.74033203125003, 73.48154296875],
              [127.955078125, 73.445556640625],
              [127.996875, 73.425634765625],
              [128.02568359375005, 73.390771484375],
              [128.14169921875003, 73.352392578125],
              [128.2814453125, 73.33056640625],
              [128.26416015625, 73.300732421875],
              [128.2578125, 73.26748046875],
              [128.58701171875003, 73.26240234375],
              [128.73046875, 73.2333984375],
              [128.888671875, 73.190234375],
              [128.87167968750003, 73.13935546875],
              [128.91337890625005, 73.110595703125],
              [129.05917968750003, 73.10751953125],
              [129.1005859375, 73.112353515625],
              [129.0537109375, 73.04541015625],
              [128.853515625, 72.972607421875],
              [128.73525390625002, 72.94326171875],
              [128.59902343750002, 72.895166015625],
              [128.6740234375, 72.885888671875],
              [129.01728515625, 72.8724609375],
              [129.22910156250003, 72.775732421875],
              [129.25039062500002, 72.70517578125],
              [129.11757812500002, 72.676953125],
              [128.81533203125002, 72.585888671875],
              [128.63339843750003, 72.550146484375],
              [128.50849609375, 72.547314453125],
              [128.41826171875005, 72.53515625],
              [128.54941406250003, 72.495849609375],
              [129.11660156250002, 72.4857421875],
              [129.28134765625003, 72.4376953125],
              [129.41171875000003, 72.315478515625],
              [129.41064453125, 72.16630859375],
              [129.28349609375005, 72.092041015625],
              [128.93496093750002, 72.0794921875],
              [128.47519531250003, 72.245556640625],
              [128.19697265625, 72.309619140625],
              [127.80341796875001, 72.434033203125],
              [127.72607421875, 72.41318359375],
              [127.84140625000003, 72.308251953125],
              [128.0265625, 72.25],
              [128.35878906250002, 72.088330078125],
              [128.91142578125005, 71.755322265625],
              [129.04013671875003, 71.782421875],
              [129.11660156250002, 71.824609375],
              [129.15419921875002, 71.878662109375],
              [129.12158203125, 71.95322265625],
              [129.21025390625005, 71.916943359375],
              [129.29179687500005, 71.8501953125],
              [129.46083984375002, 71.739306640625],
              [129.23417968750005, 71.74482421875],
              [128.94902343750005, 71.707568359375],
              [128.84326171875, 71.6634765625],
              [128.92265625000005, 71.6017578125],
              [129.13427734375, 71.59287109375],
              [129.22451171875002, 71.508837890625],
              [129.38984375, 71.4048828125],
              [129.7619140625, 71.11953125],
              [130.02597656250003, 71.065380859375],
              [130.28125, 70.947314453125],
              [130.537109375, 70.892529296875],
              [130.66845703125, 70.888330078125],
              [130.75712890625005, 70.962353515625],
              [130.83193359375002, 70.935888671875],
              [130.89804687500003, 70.803564453125],
              [131.02158203125003, 70.74609375],
              [131.157421875, 70.7421875],
              [131.26826171875, 70.76552734375],
              [131.43232421875, 70.828271484375],
              [131.56201171875, 70.901025390625],
              [131.76904296875, 71.101416015625],
              [131.9064453125, 71.20263671875],
              [132.0353515625, 71.24404296875],
              [131.99082031250003, 71.293212890625],
              [132.00371093750005, 71.3501953125],
              [132.098828125, 71.483984375],
              [132.22763671875003, 71.6427734375],
              [132.32578125000003, 71.726220703125],
              [132.56230468750005, 71.8953125],
              [132.65390625000003, 71.9259765625],
              [132.7158203125, 71.871484375],
              [132.7685546875, 71.79873046875],
              [132.80361328125002, 71.767578125],
              [132.83925781250002, 71.75517578125],
              [133.130859375, 71.606689453125],
              [133.42617187500002, 71.490966796875],
              [133.68886718750002, 71.434228515625],
              [134.10283203125005, 71.378955078125],
              [134.70273437500003, 71.38681640625],
              [134.81386718750002, 71.460595703125],
              [135.02236328125002, 71.5150390625],
              [135.359375, 71.543505859375],
              [135.55917968750003, 71.6103515625],
              [135.884765625, 71.63056640625],
              [136.09033203125, 71.619580078125],
              [136.40615234375002, 71.570751953125],
              [137.11582031250003, 71.415673828125],
              [137.3154296875, 71.359423828125],
              [137.41748046875, 71.2990234375],
              [137.6505859375, 71.208154296875],
              [137.7978515625, 71.163916015625],
              [137.9396484375, 71.1333984375],
              [137.99169921875, 71.142724609375],
              [137.97373046875003, 71.16865234375],
              [137.90195312500003, 71.19404296875],
              [137.84404296875005, 71.226806640625],
              [138.0126953125, 71.26083984375],
              [138.03251953125005, 71.28583984375],
              [138.090625, 71.307421875],
              [138.31406250000003, 71.325537109375],
              [138.09716796875, 71.35859375],
              [138.02216796875, 71.363427734375],
              [137.91835937500002, 71.38408203125],
              [137.92734375000003, 71.42978515625],
              [137.99570312500003, 71.463525390625],
              [138.04833984375, 71.5259765625],
              [138.11845703125005, 71.566162109375],
              [138.23417968750005, 71.596337890625],
              [138.31806640625, 71.60283203125],
              [138.52519531250005, 71.562744140625],
              [138.67001953125003, 71.634814453125],
              [138.78017578125002, 71.62900390625],
              [139.0048828125, 71.5560546875],
              [139.209375, 71.444775390625],
              [139.32021484375002, 71.4447265625],
              [139.63212890625005, 71.4892578125],
              [139.98417968750005, 71.49150390625],
              [139.93876953125005, 71.557666015625],
              [139.69511718750005, 71.700439453125],
              [139.72294921875005, 71.8849609375],
              [139.55234375000003, 71.926708984375],
              [139.35927734375002, 71.9513671875],
              [139.64023437500003, 71.99833984375],
              [139.84707031250002, 72.148583984375],
              [140.01406250000002, 72.162109375],
              [140.1876953125, 72.19130859375],
              [140.134375, 72.209619140625],
              [139.61699218750005, 72.22568359375],
              [139.50527343750002, 72.207666015625],
              [139.43046875000005, 72.1634765625],
              [139.17636718750003, 72.1634765625],
              [139.14501953125, 72.264404296875],
              [139.1408203125, 72.329736328125],
              [139.4736328125, 72.46650390625],
              [139.60117187500003, 72.49609375],
              [140.45058593750002, 72.493115234375],
              [140.705078125, 72.5189453125],
              [141.079296875, 72.5869140625],
              [140.98320312500005, 72.630029296875],
              [140.9728515625, 72.7169921875],
              [140.65234375, 72.842822265625],
              [140.6759765625, 72.871630859375],
              [140.70810546875003, 72.8900390625],
              [140.80820312500003, 72.890966796875],
              [141.309765625, 72.85771484375],
              [141.51835937500005, 72.788671875],
              [142.06142578125002, 72.72080078125],
              [143.5158203125, 72.6982421875],
              [143.68095703125005, 72.673193359375],
              [144.30390625, 72.643017578125],
              [144.56865234375005, 72.609912109375],
              [145.19931640625003, 72.57021484375],
              [145.48574218750002, 72.54208984375],
              [145.71416015625005, 72.49736328125],
              [146.08330078125005, 72.47138671875],
              [146.2529296875, 72.442236328125],
              [146.23476562500002, 72.34970703125],
              [145.46708984375005, 72.362060546875],
              [145.212890625, 72.39267578125],
              [144.8974609375, 72.396240234375],
              [144.7763671875, 72.382275390625],
              [144.58759765625, 72.305517578125],
              [144.36093750000003, 72.26533203125],
              [144.16923828125005, 72.2587890625],
              [144.294921875, 72.192626953125],
              [144.470703125, 72.174755859375],
              [145.03916015625003, 72.25986328125],
              [146.59414062500002, 72.30244140625],
              [146.83183593750005, 72.29541015625],
              [146.80703125000002, 72.236572265625],
              [146.59921875000003, 72.12353515625],
              [146.40166015625005, 72.035498046875],
              [146.11328125, 71.944970703125],
              [146.005859375, 71.945458984375],
              [146.23027343750005, 72.1375],
              [146.13730468750003, 72.146484375],
              [146.05146484375, 72.14228515625],
              [145.79941406250003, 72.221875],
              [145.75859375000005, 72.22587890625],
              [145.70966796875, 72.20634765625],
              [145.71015625, 72.177587890625],
              [145.6640625, 72.0669921875],
              [145.75673828125002, 72.020654296875],
              [145.75673828125002, 71.94130859375],
              [145.4072265625, 71.89013671875],
              [145.27119140625, 71.89462890625],
              [145.12578125000005, 71.9271484375],
              [145.06396484375, 71.92607421875],
              [145.046875, 71.901025390625],
              [145.07773437500003, 71.854638671875],
              [145.07373046875, 71.830859375],
              [145.01787109375005, 71.793701171875],
              [144.98964843750002, 71.753369140625],
              [145.07558593750002, 71.707373046875],
              [145.18857421875003, 71.69580078125],
              [145.80478515625003, 71.746484375],
              [146.0732421875, 71.808349609375],
              [146.36796875000005, 71.9220703125],
              [146.8947265625, 72.197509765625],
              [147.12705078125003, 72.292041015625],
              [147.26181640625003, 72.327880859375],
              [147.43398437500002, 72.34091796875],
              [148.40205078125, 72.311962890625],
              [148.96484375, 72.25234375],
              [149.50156250000003, 72.164306640625],
              [149.76621093750003, 72.091259765625],
              [149.9630859375, 71.9921875],
              [149.99814453125003, 71.95048828125],
              [150.01689453125005, 71.895654296875],
              [149.8810546875, 71.843017578125],
              [149.27968750000002, 71.825537109375],
              [149.04873046875002, 71.795751953125],
              [148.96533203125, 71.76279296875],
              [148.95488281250005, 71.744140625],
              [148.92333984375, 71.7146484375],
              [148.96816406250002, 71.690478515625],
              [149.23789062500003, 71.687939453125],
              [149.498046875, 71.664013671875],
              [149.85712890625, 71.60146484375],
              [149.91269531250003, 71.580712890625],
              [150.02646484375003, 71.521337890625],
              [150.06083984375005, 71.51083984375],
              [150.5998046875, 71.5201171875],
              [150.63486328125003, 71.498876953125],
              [150.66777343750005, 71.455224609375],
              [150.52509765625, 71.38583984375],
              [150.384765625, 71.338818359375],
              [150.09765625, 71.2265625],
              [150.24296875000005, 71.2671875],
              [150.82167968750002, 71.362890625],
              [150.9677734375, 71.38046875],
              [151.14531250000005, 71.37373046875],
              [151.58242187500002, 71.286962890625],
              [151.759765625, 71.217822265625],
              [152.0927734375, 71.023291015625],
              [151.99980468750005, 71.002490234375],
              [151.76201171875005, 70.982470703125],
              [152.5087890625, 70.83447265625],
              [152.79833984375, 70.83564453125],
              [153.46064453125, 70.87861328125],
              [153.794140625, 70.87998046875],
              [154.41396484375002, 70.974462890625],
              [155.0294921875, 71.034228515625],
              [155.5958984375, 71.038623046875],
              [155.89521484375, 71.0955078125],
              [156.6845703125, 71.09375],
              [157.44736328125003, 71.07451171875],
              [158.03701171875002, 71.0392578125],
              [158.7021484375, 70.935009765625],
              [159.35068359375003, 70.79072265625],
              [159.72792968750002, 70.649658203125],
              [159.8046875, 70.604931640625],
              [159.91181640625, 70.506103515625],
              [159.95859375000003, 70.4236328125],
              [160.00644531250003, 70.30966796875],
              [159.9833984375, 70.22138671875],
              [159.8896484375, 70.1587890625],
              [159.83144531250002, 70.0814453125],
              [159.83916015625005, 69.989990234375],
              [159.72939453125002, 69.87021484375],
              [159.83251953125, 69.7849609375],
              [160.119140625, 69.72978515625],
              [160.739453125, 69.65517578125],
              [160.91074218750003, 69.60634765625],
              [160.92890625, 69.458544921875],
              [160.98203125000003, 69.33447265625],
              [161.03554687500002, 69.098193359375],
              [161.1408203125, 69.0388671875],
              [161.30986328125005, 68.982275390625],
              [161.340625, 68.90517578125],
              [161.12900390625003, 68.653857421875],
              [160.99667968750003, 68.60751953125],
              [160.85605468750003, 68.538330078125],
              [161.1044921875, 68.5625],
              [161.23017578125, 68.65390625],
              [161.36513671875002, 68.822998046875],
              [161.4953125, 68.849853515625],
              [161.565625, 68.90517578125],
              [161.565625, 69.06396484375],
              [161.48007812500003, 69.201708984375],
              [161.48007812500003, 69.30009765625],
              [161.53691406250005, 69.379541015625],
              [161.94511718750005, 69.5451171875],
              [162.166015625, 69.611572265625],
              [162.37568359375, 69.649072265625],
              [162.94462890625005, 69.682763671875],
              [163.2013671875, 69.71474609375],
              [163.498046875, 69.69326171875],
              [163.7052734375, 69.701806640625],
              [163.94599609375, 69.73515625],
              [164.15957031250002, 69.719287109375],
              [164.51328125000003, 69.609130859375],
              [165.7607421875, 69.584423828125],
              [165.98046875, 69.54599609375],
              [166.8203125, 69.499560546875],
              [166.884375, 69.49990234375],
              [167.07314453125002, 69.554443359375],
              [167.628125, 69.74033203125],
              [167.85683593750002, 69.72822265625],
              [167.95009765625002, 69.699169921875],
              [168.04765625000005, 69.625634765625],
              [168.15, 69.577392578125],
              [168.22998046875, 69.447021484375],
              [168.30302734375005, 69.271484375],
              [168.423046875, 69.239501953125],
              [168.58759765625, 69.228369140625],
              [168.94619140625002, 69.163330078125],
              [169.31064453125003, 69.079541015625],
              [169.41464843750003, 68.91962890625],
              [169.60986328125, 68.78603515625],
              [170.065625, 68.798681640625],
              [170.53759765625, 68.825390625],
              [170.99541015625005, 69.0453125],
              [170.99667968750003, 69.134716796875],
              [170.8837890625, 69.263623046875],
              [170.71416015625005, 69.388232421875],
              [170.5822265625, 69.583349609375],
              [170.16093750000005, 69.6265625],
              [170.201171875, 69.683203125],
              [170.3595703125, 69.7509765625],
              [170.503125, 69.85654296875],
              [170.525390625, 69.937890625],
              [170.48681640625, 70.107568359375],
              [170.86796875000005, 70.096044921875],
              [171.24667968750003, 70.076123046875],
              [171.97050781250005, 70.000341796875],
              [172.5595703125, 69.968359375],
              [172.86923828125003, 69.919775390625],
              [173.05634765625, 69.86494140625],
              [173.27744140625003, 69.823828125],
              [173.35332031250005, 69.9240234375],
              [173.438671875, 69.946826171875],
              [173.7333984375, 69.89111328125],
              [173.94804687500005, 69.87412109375],
              [174.31943359375003, 69.881640625],
              [174.78554687500002, 69.8556640625],
              [175.29560546875, 69.86005859375],
              [175.751171875, 69.904150390625],
              [175.92148437500003, 69.8953125],
              [176.10751953125003, 69.860302734375],
              [176.41044921875005, 69.768505859375],
              [176.92441406250003, 69.64599609375],
              [177.39453125, 69.61162109375],
              [177.93369140625003, 69.49560546875],
              [178.44277343750002, 69.452978515625],
              [178.84833984375, 69.38720703125],
              [178.90693359375, 69.362109375],
              [178.925, 69.3259765625],
              [178.95068359375, 69.29580078125],
              [179.27265625, 69.25966796875],
              [179.86826171875003, 69.0126953125],
              [180, 68.983447265625],
              [180, 65.067236328125],
              [179.82734375, 65.0341796875],
              [179.6513671875, 64.920947265625],
              [179.4482421875, 64.822021484375],
              [179.15, 64.781591796875],
              [178.6984375, 64.631103515625],
              [178.51953125, 64.602978515625],
              [178.2853515625, 64.672265625],
              [177.74863281250003, 64.717041015625],
              [177.58164062500003, 64.777880859375],
              [177.33701171875003, 64.93134765625],
              [177.25185546875002, 64.95361328125],
              [177.17919921875, 65.014111328125],
              [176.880859375, 65.08193359375],
              [176.62480468750005, 65.03759765625],
              [176.4130859375, 65.071240234375],
              [176.341015625, 65.047314453125],
              [176.4521484375, 65.025244140625],
              [176.6455078125, 65.007177734375],
              [176.94003906250003, 65.016015625],
              [177.0373046875, 64.999658203125],
              [177.12343750000002, 64.947021484375],
              [177.2228515625, 64.861669921875],
              [177.14824218750005, 64.804833984375],
              [177.06875, 64.786669921875],
              [176.8310546875, 64.84921875],
              [176.556640625, 64.839990234375],
              [176.42949218750005, 64.85517578125],
              [176.06113281250003, 64.960888671875],
              [175.78115234375002, 64.84404296875],
              [175.396484375, 64.78369140625],
              [175.09775390625003, 64.77685546875],
              [174.548828125, 64.68388671875],
              [174.69863281250002, 64.6814453125],
              [175.09707031250002, 64.746630859375],
              [175.33066406250003, 64.746630859375],
              [175.6779296875, 64.782470703125],
              [175.85859375, 64.82529296875],
              [175.94589843750003, 64.865185546875],
              [176.05654296875002, 64.904736328125],
              [176.16923828125005, 64.884765625],
              [176.24697265625002, 64.843017578125],
              [176.30087890625003, 64.783837890625],
              [176.35097656250002, 64.705126953125],
              [176.283203125, 64.663818359375],
              [176.21943359375, 64.641943359375],
              [176.14091796875005, 64.58583984375],
              [176.50761718750005, 64.682421875],
              [176.73095703125, 64.624853515625],
              [176.84287109375003, 64.6337890625],
              [177.0498046875, 64.71923828125],
              [177.3875, 64.7740234375],
              [177.42744140625, 64.76337890625],
              [177.46718750000002, 64.73681640625],
              [177.40986328125, 64.572802734375],
              [177.43291015625005, 64.444482421875],
              [177.6875, 64.304736328125],
              [177.9533203125, 64.222265625],
              [178.04472656250005, 64.219580078125],
              [178.13056640625, 64.23525390625],
              [178.16396484375002, 64.30908203125],
              [178.2294921875, 64.364404296875],
              [178.31298828125, 64.314404296875],
              [178.38144531250003, 64.260888671875],
              [178.47714843750003, 64.127880859375],
              [178.4748046875, 64.089013671875],
              [178.4513671875, 64.011376953125],
              [178.53603515625002, 63.975634765625],
              [178.65029296875002, 63.965283203125],
              [178.69248046875003, 63.842333984375],
              [178.7314453125, 63.66708984375],
              [178.68134765625, 63.650732421875],
              [178.6259765625, 63.650732421875],
              [178.4404296875, 63.60556640625],
              [178.46611328125005, 63.574072265625],
              [178.65371093750002, 63.556640625],
              [178.70644531250002, 63.521533203125],
              [178.66884765625002, 63.43994140625],
              [178.6787109375, 63.402294921875],
              [178.74404296875002, 63.394775390625],
              [178.78671875000003, 63.442431640625],
              [178.775390625, 63.51025390625],
              [178.79296875, 63.54033203125],
              [178.91855468750003, 63.400244140625],
              [178.92148437500003, 63.34501953125],
              [179.028125, 63.282421875],
              [179.33232421875005, 63.190185546875],
              [179.38857421875002, 63.147216796875],
              [179.40507812500005, 63.077734375],
              [179.32900390625002, 63.05791015625],
              [179.25957031250005, 63.00830078125],
              [179.30214843750002, 62.93984375],
              [179.3810546875, 62.883691406249994],
              [179.5109375, 62.86279296875],
              [179.5705078125, 62.773486328125],
              [179.5705078125, 62.6875],
              [179.47724609375, 62.6130859375],
              [179.28867187500003, 62.5103515625],
              [179.176953125, 62.469189453125],
              [179.13388671875003, 62.396435546875],
              [179.12070312500003, 62.320361328125],
              [179.04462890625, 62.323681640625],
              [178.9638671875, 62.3552734375],
              [178.01923828125, 62.54697265625],
              [177.6630859375, 62.5828125],
              [177.35126953125, 62.587451171875],
              [177.29257812500003, 62.599023437499994],
              [177.2958984375, 62.644482421875],
              [177.31582031250002, 62.68525390625],
              [177.35966796875005, 62.736962890624994],
              [177.33896484375003, 62.78134765625],
              [177.29833984375, 62.784228515625],
              [177.25869140625002, 62.750439453125],
              [177.17265625000005, 62.750341796875],
              [177.09121093750002, 62.78955078125],
              [177.02353515625003, 62.777246093749994],
              [176.99003906250005, 62.722216796875],
              [176.96347656250003, 62.693261718749994],
              [176.96474609375002, 62.658642578125],
              [177.0080078125, 62.6265625],
              [177.1896484375, 62.5916015625],
              [177.15947265625005, 62.560986328125],
              [176.907421875, 62.536083984375],
              [176.70253906250002, 62.505761718749994],
              [176.4365234375, 62.41083984375],
              [176.32841796875005, 62.346044921875],
              [175.61386718750003, 62.184375],
              [175.44199218750003, 62.1279296875],
              [175.36582031250003, 62.121337890625],
              [175.26787109375005, 62.102392578125],
              [175.1923828125, 62.034423828125],
              [174.79755859375, 61.938867187499994],
              [174.7150390625, 61.947900390624994],
              [174.610546875, 61.867626953125],
              [174.51435546875, 61.823632812499994],
              [174.28496093750005, 61.817529296874994],
              [174.1388671875, 61.795166015625],
              [173.82236328125003, 61.67939453125],
              [173.62343750000002, 61.716064453125],
              [173.39072265625003, 61.55673828125],
              [173.1318359375, 61.406640625],
              [173.05458984375002, 61.406201171875],
              [172.85654296875003, 61.469189453125],
              [172.8068359375, 61.4361328125],
              [172.837890625, 61.3755859375],
              [172.90800781250005, 61.31162109375],
              [172.86777343750003, 61.29306640625],
              [172.7890625, 61.310693359374994],
              [172.7306640625, 61.314404296875],
              [172.69003906250003, 61.295166015625],
              [172.69697265625, 61.24931640625],
              [172.58476562500005, 61.1904296875],
              [172.4970703125, 61.185888671875],
              [172.39609375000003, 61.167382812499994],
              [172.36240234375003, 61.116601562499994],
              [172.3927734375, 61.061767578125],
              [172.21328125000002, 60.9978515625],
              [172.06728515625002, 60.915673828124994],
              [171.99765625000003, 60.90068359375],
              [171.91796875, 60.864111328125],
              [171.83056640625, 60.837353515625],
              [171.7294921875, 60.843115234375],
              [171.48974609375, 60.725732421874994],
              [170.94931640625003, 60.52294921875],
              [170.79931640625, 60.496484375],
              [170.60820312500005, 60.434912109375],
              [170.58974609375002, 60.393701171874994],
              [170.58857421875, 60.34287109375],
              [170.51230468750003, 60.259521484375],
              [170.42343750000003, 60.047802734375],
              [170.396484375, 60.009765625],
              [170.35097656250002, 59.96552734375],
              [170.15410156250005, 59.986083984375],
              [169.9826171875, 60.06708984375],
              [169.92724609375, 60.104248046875],
              [169.89755859375003, 60.147851562499994],
              [169.88701171875005, 60.217919921874994],
              [169.85429687500005, 60.250244140625],
              [169.81474609375005, 60.265380859375],
              [169.618359375, 60.438037109375],
              [169.27568359375005, 60.556640625],
              [169.2267578125, 60.595947265625],
              [168.78828125, 60.563818359375],
              [168.67031250000002, 60.562890625],
              [168.46279296875002, 60.592236328125],
              [168.1375, 60.57392578125],
              [167.74599609375002, 60.509326171875],
              [167.62607421875003, 60.4689453125],
              [167.2267578125, 60.406298828125],
              [166.9640625, 60.30703125],
              [166.45253906250002, 59.947021484375],
              [166.33183593750005, 59.872412109375],
              [166.27304687500003, 59.85625],
              [166.1865234375, 59.849462890625],
              [166.14892578125, 59.9220703125],
              [166.13603515625005, 59.979345703125],
              [166.16835937500002, 60.088818359375],
              [166.22978515625005, 60.1783203125],
              [166.29248046875, 60.34609375],
              [166.30810546875, 60.4142578125],
              [166.35214843750003, 60.484814453125],
              [166.18017578125, 60.480371093749994],
              [165.94199218750003, 60.356884765625],
              [165.5830078125, 60.236474609374994],
              [165.41582031250005, 60.20517578125],
              [165.28525390625003, 60.134912109374994],
              [165.192578125, 60.124755859375],
              [165.08457031250003, 60.098583984375],
              [165.07363281250002, 59.94560546875],
              [165.01894531250002, 59.860742187499994],
              [164.95371093750003, 59.843603515625],
              [164.85429687500005, 59.840966796874994],
              [164.77939453125003, 59.87421875],
              [164.66972656250005, 59.997460937499994],
              [164.52529296875002, 60.061279296875],
              [164.44003906250003, 60.072705078125],
              [164.37685546875002, 60.058056640625],
              [164.25156250000003, 59.973779296874994],
              [164.11328125, 59.89755859375],
              [164.13505859375005, 59.984375],
              [164.017578125, 60.017333984375],
              [163.91289062500005, 60.037060546875],
              [163.78007812500005, 60.04111328125],
              [163.74384765625, 60.02802734375],
              [163.69003906250003, 59.978417968749994],
              [163.57431640625003, 59.9140625],
              [163.49375, 59.886767578125],
              [163.40996093750005, 59.8349609375],
              [163.36484375000003, 59.7814453125],
              [163.32119140625002, 59.705419921875],
              [163.26904296875, 59.52001953125],
              [163.27285156250002, 59.302587890625],
              [163.08486328125002, 59.131396484375],
              [163.01015625000002, 59.148291015625],
              [162.97490234375005, 59.137060546875],
              [162.94003906250003, 59.114306640625],
              [163.00429687500002, 59.020166015624994],
              [162.96982421875003, 58.986474609374994],
              [162.9345703125, 58.96396484375],
              [162.84726562500003, 58.9392578125],
              [162.64335937500005, 58.79990234375],
              [162.45302734375002, 58.70859375],
              [162.1416015625, 58.447412109374994],
              [162.04921875000002, 58.272851562499994],
              [161.96005859375003, 58.076904296875],
              [162.001953125, 57.98095703125],
              [162.03964843750003, 57.918261718749996],
              [162.09794921875005, 57.874658203125],
              [162.1974609375, 57.829150390624996],
              [162.41142578125005, 57.778369140624996],
              [162.39218750000003, 57.74501953125],
              [162.39140625000005, 57.717236328125],
              [162.4669921875, 57.7662109375],
              [162.52197265625, 57.904101562499996],
              [162.654296875, 57.9482421875],
              [162.71835937500003, 57.94609375],
              [163.14501953125, 57.8373046875],
              [163.22578125, 57.790380859375],
              [163.2138671875, 57.686816406249996],
              [163.18789062500002, 57.63740234375],
              [163.10878906250002, 57.56484375],
              [162.95703125, 57.477490234375],
              [162.779296875, 57.3576171875],
              [162.76230468750003, 57.28408203125],
              [162.76152343750005, 57.243945312499996],
              [162.80810546875, 57.102783203125],
              [162.81484375000002, 57.023388671875],
              [162.79111328125003, 56.875390625],
              [162.80263671875002, 56.811474609375],
              [162.84990234375005, 56.7568359375],
              [162.9220703125, 56.72265625],
              [163.04638671875, 56.74130859375],
              [163.16542968750002, 56.72548828125],
              [163.25654296875, 56.688037109374996],
              [163.24326171875003, 56.56455078125],
              [163.29404296875003, 56.447705078125],
              [163.33554687500003, 56.23251953125],
              [163.26132812500003, 56.17373046875],
              [163.18925781250005, 56.137011718749996],
              [163.04736328125, 56.044677734375],
              [162.9716796875, 56.0337890625],
              [162.84033203125, 56.065625],
              [162.628125, 56.232275390625],
              [162.71318359375005, 56.330859375],
              [162.89326171875, 56.399462890624996],
              [162.97519531250003, 56.449023437499996],
              [163.03837890625005, 56.521875],
              [162.94414062500005, 56.508056640625],
              [162.87763671875, 56.476367187499996],
              [162.67148437500003, 56.490087890625],
              [162.5890625, 56.454931640625],
              [162.48867187500002, 56.39912109375],
              [162.52822265625002, 56.260693359375],
              [162.4611328125, 56.235498046875],
              [162.33408203125003, 56.187744140625],
              [162.14609375000003, 56.128271484375],
              [162.0849609375, 56.089648437499996],
              [161.9240234375, 55.840380859374996],
              [161.7755859375, 55.654833984374996],
              [161.72392578125005, 55.496142578124996],
              [161.72939453125002, 55.3580078125],
              [161.78496093750005, 55.205322265625],
              [161.82421875, 55.138916015625],
              [161.99609375, 54.997998046875],
              [162.0802734375, 54.8861328125],
              [162.10556640625003, 54.7521484375],
              [161.96689453125003, 54.688671875],
              [161.72568359375003, 54.532958984375],
              [161.62480468750005, 54.516259765625],
              [161.29404296875003, 54.520556640624996],
              [161.1298828125, 54.5982421875],
              [160.935546875, 54.578369140625],
              [160.77265625, 54.541357421875],
              [160.51718750000003, 54.430859375],
              [160.28886718750005, 54.288232421875],
              [160.0744140625, 54.18916015625],
              [160.01015625000002, 54.130859375],
              [159.92177734375002, 54.0083984375],
              [159.84375, 53.783642578125],
              [159.87089843750005, 53.67265625],
              [159.9142578125, 53.620849609375],
              [159.95585937500005, 53.552197265625],
              [159.89912109375, 53.447705078125],
              [159.89765625, 53.38076171875],
              [160.0021484375, 53.27490234375],
              [160.02509765625, 53.12958984375],
              [159.9474609375, 53.12509765625],
              [159.77158203125003, 53.2296875],
              [159.5859375, 53.2376953125],
              [159.13613281250002, 53.117138671875],
              [158.95205078125002, 53.04755859375],
              [158.74541015625005, 52.908935546875],
              [158.68369140625003, 52.935400390625],
              [158.63955078125002, 53.014794921875],
              [158.5646484375, 53.05],
              [158.47207031250002, 53.032373046875],
              [158.43232421875, 52.957421875],
              [158.56015625000003, 52.922167968749996],
              [158.60878906250002, 52.8736328125],
              [158.53369140625, 52.688427734375],
              [158.48076171875005, 52.62666015625],
              [158.50039062500002, 52.460302734375],
              [158.4931640625, 52.383154296875],
              [158.46347656250003, 52.304980468749996],
              [158.33164062500003, 52.090869140624996],
              [158.103515625, 51.809619140624996],
              [157.8232421875, 51.605322265625],
              [157.62890625, 51.534570312499994],
              [157.53095703125, 51.479882812499994],
              [157.48984375000003, 51.408935546875],
              [157.20224609375003, 51.212744140625],
              [156.84746093750005, 51.006591796875],
              [156.74775390625, 50.969287109374996],
              [156.72431640625, 51.0470703125],
              [156.71347656250003, 51.12412109375],
              [156.67080078125002, 51.226855468749996],
              [156.54345703125, 51.31162109375],
              [156.52119140625, 51.380273437499994],
              [156.50039062500002, 51.47509765625],
              [156.48984375000003, 51.913037109375],
              [156.37734375000002, 52.366552734375],
              [156.36474609375, 52.509375],
              [156.22861328125003, 52.626269531249996],
              [156.15439453125003, 52.747265625],
              [156.1103515625, 52.866162109375],
              [156.098828125, 53.006494140625],
              [155.9501953125, 53.744287109375],
              [155.90488281250003, 53.928125],
              [155.70644531250002, 54.521484375],
              [155.6203125, 54.864550781249996],
              [155.56386718750002, 55.19912109375],
              [155.5548828125, 55.348486328125],
              [155.64345703125002, 55.7935546875],
              [155.71660156250005, 56.072216796875],
              [155.98251953125003, 56.69521484375],
              [156.025390625, 56.752001953124996],
              [156.06748046875003, 56.781591796875],
              [156.529296875, 57.02119140625],
              [156.72841796875002, 57.15224609375],
              [156.848828125, 57.290185546875],
              [156.9767578125, 57.46630859375],
              [156.96357421875, 57.5609375],
              [156.9482421875, 57.615771484374996],
              [156.89990234375, 57.676904296875],
              [156.79160156250003, 57.74794921875],
              [156.82988281250005, 57.779638671875],
              [156.87197265625002, 57.803662109375],
              [156.98574218750002, 57.83017578125],
              [157.216796875, 57.776806640625],
              [157.450390625, 57.799267578125],
              [157.66640625000002, 58.019775390625],
              [157.974609375, 57.9859375],
              [158.21044921875, 58.025292968749994],
              [158.27519531250005, 58.008984375],
              [158.32109375000005, 58.083447265625],
              [158.4494140625, 58.162841796875],
              [158.68701171875, 58.28134765625],
              [159.03691406250005, 58.42392578125],
              [159.21064453125, 58.519433593749994],
              [159.30839843750005, 58.610546875],
              [159.45263671875, 58.695947265624994],
              [159.59150390625, 58.803662109375],
              [159.84736328125, 59.1271484375],
              [160.35039062500005, 59.39404296875],
              [160.54746093750003, 59.54736328125],
              [160.71142578125, 59.60166015625],
              [160.85527343750005, 59.626855468749994],
              [161.2189453125, 59.845605468749994],
              [161.44931640625003, 60.02734375],
              [161.75351562500003, 60.152294921875],
              [161.84599609375005, 60.2322265625],
              [162.00361328125, 60.420166015625],
              [162.06816406250005, 60.46640625],
              [162.26630859375, 60.53671875],
              [162.71318359375005, 60.65947265625],
              [162.97314453125, 60.78291015625],
              [163.35234375000005, 60.800439453124994],
              [163.46640625000003, 60.849755859374994],
              [163.58515625, 60.8771484375],
              [163.7099609375, 60.916796875],
              [163.55351562500005, 61.025634765625],
              [163.58925781250002, 61.084375],
              [163.61962890625, 61.111328125],
              [163.89335937500005, 61.240478515625],
              [164.00546875000003, 61.343798828125],
              [163.99208984375002, 61.388232421875],
              [163.97275390625003, 61.419873046875],
              [163.80439453125, 61.461376953125],
              [163.837109375, 61.558251953124994],
              [163.88271484375002, 61.64013671875],
              [164.01953125, 61.710693359375],
              [164.06796875000003, 61.873876953125],
              [164.07421875, 62.04501953125],
              [164.2072265625, 62.292236328125],
              [164.2875, 62.346630859375],
              [164.59833984375, 62.470556640625],
              [164.67070312500005, 62.473779296874994],
              [164.8876953125, 62.431884765625],
              [165.12412109375003, 62.411523437499994],
              [165.20810546875003, 62.373974609375],
              [165.22568359375003, 62.40576171875],
              [165.2138671875, 62.448193359375],
              [165.28037109375003, 62.46298828125],
              [165.41738281250002, 62.4470703125],
              [165.39658203125003, 62.493896484375],
              [165.04404296875003, 62.516992187499994],
              [164.79238281250002, 62.57109375],
              [164.56699218750003, 62.67548828125],
              [164.41835937500002, 62.704638671875],
              [164.25566406250005, 62.69658203125],
              [163.33173828125, 62.550927734374994],
              [163.287109375, 62.51142578125],
              [163.24423828125003, 62.45537109375],
              [163.30214843750002, 62.372998046875],
              [163.2580078125, 62.3369140625],
              [163.21328125000002, 62.313427734375],
              [163.16347656250002, 62.2595703125],
              [163.11845703125005, 62.1529296875],
              [163.1310546875, 62.04990234375],
              [163.01767578125003, 61.891064453125],
              [163.00927734375, 61.79150390625],
              [163.20761718750003, 61.736572265625],
              [163.2578125, 61.699462890625],
              [163.19785156250003, 61.644775390625],
              [163.1388671875, 61.61142578125],
              [163.08525390625005, 61.570556640625],
              [163.04726562500002, 61.554052734375],
              [162.99394531250005, 61.544189453125],
              [162.92167968750005, 61.597705078125],
              [162.85595703125, 61.705029296875],
              [162.75234375000002, 61.711279296875],
              [162.71787109375003, 61.6951171875],
              [162.69902343750005, 61.652587890625],
              [162.6076171875, 61.650048828124994],
              [162.50644531250003, 61.6701171875],
              [162.392578125, 61.662109375],
              [162.18837890625002, 61.540673828124994],
              [161.037109375, 60.962890625],
              [160.9150390625, 60.89267578125],
              [160.7666015625, 60.753320312499994],
              [160.48203125000003, 60.73984375],
              [160.3681640625, 60.708544921875],
              [160.2873046875, 60.667041015625],
              [160.17363281250005, 60.638427734375],
              [160.17734375000003, 60.69072265625],
              [160.20107421875002, 60.729638671874994],
              [160.22578125, 60.83154296875],
              [160.37890625, 61.02548828125],
              [160.28125, 61.044775390625],
              [160.18427734375, 61.04765625],
              [160.00400390625003, 61.007421875],
              [159.88310546875005, 60.943408203125],
              [159.79042968750002, 60.956640625],
              [159.83457031250003, 61.01396484375],
              [159.94921875, 61.12861328125],
              [159.91396484375002, 61.23447265625],
              [159.88310546875005, 61.291796875],
              [159.930859375, 61.32392578125],
              [160.16269531250003, 61.5375],
              [160.246875, 61.647607421874994],
              [160.3173828125, 61.793359375],
              [160.321484375, 61.83857421875],
              [160.309375, 61.894384765625],
              [160.23779296875, 61.903857421875],
              [160.18251953125002, 61.90283203125],
              [159.72216796875, 61.7583984375],
              [159.55234375000003, 61.719482421875],
              [159.4962890625, 61.7814453125],
              [159.423046875, 61.808056640625],
              [159.29501953125003, 61.91416015625],
              [159.18925781250005, 61.92939453125],
              [159.07666015625, 61.922265625],
              [158.82431640625003, 61.850244140624994],
              [158.54716796875005, 61.810888671875],
              [158.33369140625, 61.82568359375],
              [158.1515625, 61.76484375],
              [158.07011718750005, 61.75361328125],
              [157.79931640625, 61.795263671875],
              [157.46933593750003, 61.79892578125],
              [157.37070312500003, 61.7470703125],
              [157.0841796875, 61.675683593749994],
              [156.891796875, 61.565185546875],
              [156.790625, 61.529638671875],
              [156.68027343750003, 61.480615234374994],
              [156.62968750000005, 61.2724609375],
              [156.4826171875, 61.206005859375],
              [156.34414062500002, 61.155078125],
              [156.05595703125005, 60.99560546875],
              [155.85332031250005, 60.7771484375],
              [155.71611328125005, 60.682373046875],
              [155.42783203125003, 60.549853515625],
              [154.97080078125003, 60.37666015625],
              [154.57822265625003, 60.09501953125],
              [154.44072265625005, 59.8837890625],
              [154.38984375, 59.8767578125],
              [154.29306640625003, 59.833349609375],
              [154.2666015625, 59.730371093749994],
              [154.26884765625005, 59.6583984375],
              [154.2091796875, 59.600341796875],
              [154.14980468750002, 59.528515625],
              [154.212890625, 59.4833984375],
              [154.27216796875, 59.475146484375],
              [154.3576171875, 59.4814453125],
              [154.58251953125, 59.540087890625],
              [154.97128906250003, 59.449609375],
              [155.16669921875, 59.36015625],
              [155.15302734375, 59.27021484375],
              [155.16044921875005, 59.19013671875],
              [155.01669921875003, 59.19560546875],
              [154.82373046875, 59.187548828125],
              [154.70351562500002, 59.14130859375],
              [154.4580078125, 59.216552734375],
              [154.3759765625, 59.187841796875],
              [154.24667968750003, 59.10859375],
              [154.0109375, 59.075537109375],
              [153.89169921875003, 59.114160156249994],
              [153.69521484375002, 59.224755859374994],
              [153.36113281250005, 59.214794921875],
              [153.27294921875, 59.09130859375],
              [153.19609375000005, 59.09443359375],
              [153.07773437500003, 59.081884765625],
              [152.88222656250002, 58.9390625],
              [152.81787109375, 58.92626953125],
              [152.57558593750002, 58.9541015625],
              [152.40068359375005, 59.026416015625],
              [152.31962890625005, 59.03076171875],
              [152.165234375, 58.997021484375],
              [152.087890625, 58.91044921875],
              [151.70458984375, 58.86669921875],
              [151.32675781250003, 58.87509765625],
              [151.12109375, 59.08251953125],
              [151.50498046875003, 59.164013671875],
              [151.73349609375003, 59.1466796875],
              [151.99003906250005, 59.160058593749994],
              [152.26064453125002, 59.223583984375],
              [152.16953125000003, 59.2779296875],
              [152.1044921875, 59.290576171875],
              [151.9423828125, 59.284082031249994],
              [151.798046875, 59.3232421875],
              [151.48574218750002, 59.52412109375],
              [151.34824218750003, 59.5611328125],
              [151.17031250000002, 59.583251953125],
              [151.03359375000002, 59.58564453125],
              [150.98251953125003, 59.571337890625],
              [150.91191406250005, 59.523046875],
              [150.86328125, 59.475439453125],
              [150.8234375, 59.4607421875],
              [150.7294921875, 59.469140625],
              [150.615234375, 59.50654296875],
              [150.48359375, 59.494384765625],
              [150.53984375000005, 59.524951171875],
              [150.66728515625005, 59.55634765625],
              [150.4572265625, 59.590722656249994],
              [150.32558593750002, 59.6388671875],
              [150.20253906250002, 59.651269531249994],
              [149.642578125, 59.770410156249994],
              [149.42451171875, 59.760986328125],
              [149.29042968750002, 59.728466796875],
              [149.06523437500005, 59.630517578124994],
              [149.12773437500005, 59.5587890625],
              [149.17539062500003, 59.526757812499994],
              [149.20498046875002, 59.488183593749994],
              [149.1330078125, 59.480517578125],
              [148.925, 59.475],
              [148.7970703125, 59.53232421875],
              [148.70888671875002, 59.44853515625],
              [148.744140625, 59.37353515625],
              [148.8896484375, 59.4],
              [148.96464843750005, 59.369140625],
              [148.9140625, 59.28271484375],
              [148.72666015625003, 59.25791015625],
              [148.4912109375, 59.2623046875],
              [148.25742187500003, 59.414208984374994],
              [147.87460937500003, 59.388037109375],
              [147.68789062500002, 59.290673828124994],
              [147.51445312500005, 59.2685546875],
              [147.0400390625, 59.36572265625],
              [146.8037109375, 59.372949218749994],
              [146.53720703125003, 59.456982421875],
              [146.4443359375, 59.43046875],
              [146.2734375, 59.221484375],
              [146.04951171875, 59.170556640624994],
              [145.931640625, 59.198388671874994],
              [145.8291015625, 59.330322265625],
              [145.75644531250003, 59.37373046875],
              [145.55458984375002, 59.413525390625],
              [144.4833984375, 59.37626953125],
              [144.12343750000002, 59.40830078125],
              [143.86875, 59.411376953125],
              [143.52382812500002, 59.343652343749994],
              [143.19218750000005, 59.3701171875],
              [142.5802734375, 59.240136718749994],
              [142.33037109375005, 59.15263671875],
              [142.025390625, 58.999658203124994],
              [141.75468750000005, 58.745263671874994],
              [141.60292968750002, 58.6490234375],
              [141.34707031250002, 58.528076171875],
              [140.98769531250002, 58.416845703125],
              [140.790234375, 58.303466796875],
              [140.68496093750002, 58.212158203125],
              [140.4951171875, 57.8654296875],
              [140.446875, 57.813671875],
              [140.00234375000002, 57.6875],
              [139.8615234375, 57.54931640625],
              [139.80332031250003, 57.51416015625],
              [139.61923828125003, 57.455712890625],
              [139.50664062500005, 57.35830078125],
              [139.44384765625, 57.3296875],
              [139.181640625, 57.261523437499996],
              [138.96572265625002, 57.088134765625],
              [138.662109375, 56.96552734375],
              [138.2177734375, 56.62900390625],
              [138.18007812500002, 56.588525390625],
              [138.140625, 56.498681640625],
              [138.07382812500003, 56.43310546875],
              [137.69150390625003, 56.13935546875],
              [137.57294921875, 56.112109375],
              [137.38408203125005, 55.974755859375],
              [137.18984375000002, 55.89228515625],
              [137.01210937500002, 55.795263671875],
              [136.79355468750003, 55.694189453125],
              [136.46025390625005, 55.576708984374996],
              [136.35117187500003, 55.510009765625],
              [136.17519531250002, 55.35224609375],
              [135.75078125000005, 55.16064453125],
              [135.540625, 55.11376953125],
              [135.2625, 54.943310546875],
              [135.23476562500002, 54.90322265625],
              [135.21152343750003, 54.8408203125],
              [135.25771484375002, 54.731494140624996],
              [135.325390625, 54.707421875],
              [135.43779296875005, 54.69248046875],
              [135.8515625, 54.583935546875],
              [136.23798828125, 54.6140625],
              [136.5802734375, 54.613623046875],
              [136.71455078125, 54.624316406249996],
              [136.79726562500002, 54.62099609375],
              [136.82373046875, 54.561474609375],
              [136.82041015625003, 54.45234375],
              [136.77041015625002, 54.353320312499996],
              [136.72939453125002, 54.06064453125],
              [136.68300781250002, 53.931298828125],
              [136.71884765625003, 53.8041015625],
              [136.80263671875002, 53.781982421875],
              [136.88642578125, 53.83935546875],
              [137.01875, 53.84814453125],
              [137.15537109375003, 53.8216796875],
              [137.2580078125, 54.025244140625],
              [137.17246093750003, 54.056884765625],
              [137.09619140625, 54.128564453125],
              [137.1416015625, 54.1822265625],
              [137.37773437500005, 54.28232421875],
              [137.52509765625, 54.2912109375],
              [137.666015625, 54.28330078125],
              [137.51318359375, 54.156396484375],
              [137.45126953125003, 54.13046875],
              [137.40341796875003, 54.12353515625],
              [137.33925781250002, 54.100537109375],
              [137.47646484375002, 54.027587890625],
              [137.62275390625, 53.970458984375],
              [137.83476562500005, 53.946728515625],
              [137.7861328125, 53.9033203125],
              [137.64482421875005, 53.8658203125],
              [137.51699218750002, 53.707080078124996],
              [137.313671875, 53.631591796875],
              [137.22148437500005, 53.57919921875],
              [137.25371093750005, 53.546142578125],
              [137.3283203125, 53.53896484375],
              [137.73818359375002, 53.560302734375],
              [137.95048828125005, 53.603564453124996],
              [138.2529296875, 53.726416015625],
              [138.37890625, 53.90927734375],
              [138.49355468750002, 53.95966796875],
              [138.52792968750003, 53.95986328125],
              [138.56816406250005, 53.94716796875],
              [138.56914062500005, 53.818798828125],
              [138.40703125000005, 53.674169921875],
              [138.2921875, 53.592431640625],
              [138.24970703125, 53.5240234375],
              [138.3203125, 53.522900390625],
              [138.45068359375, 53.53701171875],
              [138.5109375, 53.57001953125],
              [138.66074218750003, 53.744775390625],
              [138.6994140625, 53.8697265625],
              [138.7216796875, 54.04375],
              [138.70468750000003, 54.14765625],
              [138.71591796875003, 54.22265625],
              [138.6572265625, 54.29833984375],
              [138.69570312500002, 54.32001953125],
              [139.10507812500003, 54.217822265624996],
              [139.31972656250002, 54.19296875],
              [139.70742187500002, 54.277148437499996],
              [139.79550781250003, 54.2564453125],
              [139.8583984375, 54.205322265625],
              [140.1787109375, 54.0515625],
              [140.24169921875, 54.001025390624996],
              [140.34707031250002, 53.81259765625],
              [140.68759765625003, 53.596435546875],
              [141.00566406250005, 53.494580078125],
              [141.01503906250002, 53.454248046875],
              [141.21767578125002, 53.33447265625],
              [141.37373046875, 53.292773437499996],
              [141.40205078125, 53.183984375],
              [141.32792968750005, 53.097265625],
              [141.18125, 53.015283203125],
              [140.88730468750003, 53.09150390625],
              [140.83964843750005, 53.087890625],
              [140.87451171875, 53.03984375],
              [141.08681640625002, 52.89755859375],
              [141.255859375, 52.840136718749996],
              [141.26591796875005, 52.652587890625],
              [141.24501953125002, 52.550146484375],
              [141.13242187500003, 52.435693359375],
              [141.16982421875002, 52.368408203125],
              [141.32968750000003, 52.271142578125],
              [141.40908203125002, 52.234326171875],
              [141.48525390625002, 52.178515625],
              [141.38554687500005, 52.0572265625],
              [141.36689453125, 51.920654296875],
              [141.25839843750003, 51.860693359375],
              [141.12939453125, 51.727783203125],
              [140.9326171875, 51.619921875],
              [140.83857421875, 51.41416015625],
              [140.6876953125, 51.232275390625],
              [140.67070312500005, 51.051318359374996],
              [140.64560546875003, 50.986767578125],
              [140.52089843750002, 50.800195312499994],
              [140.47636718750005, 50.54599609375],
              [140.53544921875005, 50.130761718749994],
              [140.56406250000003, 50.106689453125],
              [140.62451171875, 50.082421875],
              [140.61328125, 50.0537109375],
              [140.58457031250003, 50.033349609374994],
              [140.46269531250005, 49.911474609375],
              [140.46455078125, 49.825585937499994],
              [140.51132812500003, 49.761669921875],
              [140.51718750000003, 49.596142578125],
              [140.43105468750002, 49.331494140625],
              [140.39912109375, 49.289794921875],
              [140.36435546875003, 49.220849609374994],
              [140.3486328125, 49.1591796875],
              [140.32558593750002, 49.120019531249994],
              [140.30898437500002, 49.05390625],
              [140.33369140625, 48.99482421875],
              [140.37832031250002, 48.964111328125],
              [140.22421875000003, 48.772851562499994],
              [140.17060546875, 48.523681640625],
              [140.11328125, 48.42265625],
              [139.99843750000002, 48.323779296874996],
              [139.7607421875, 48.180566406249994],
              [139.67626953125, 48.089892578124996],
              [139.5205078125, 47.97529296875],
              [139.37265625000003, 47.887353515624994],
              [139.1669921875, 47.63486328125],
              [139.00136718750002, 47.38330078125],
              [138.58681640625002, 47.0572265625],
              [138.52968750000002, 46.976220703124994],
              [138.50048828125, 46.88984375],
              [138.391796875, 46.745068359375],
              [138.3369140625, 46.543408203125],
              [138.21015625, 46.462939453124996],
              [138.10634765625002, 46.250732421875],
              [137.76914062500003, 45.928515625],
              [137.68544921875002, 45.818359375],
              [137.42519531250002, 45.639990234375],
              [137.14697265625, 45.393505859375],
              [136.80351562500005, 45.171142578125],
              [136.73720703125002, 45.080029296875],
              [136.60410156250003, 44.978173828124994],
              [136.46044921875, 44.822119140625],
              [136.251171875, 44.666796875],
              [136.20869140625, 44.56201171875],
              [136.14228515625, 44.489111328125],
              [135.98701171875, 44.43984375],
              [135.87460937500003, 44.37353515625],
              [135.533203125, 43.971484375],
              [135.48906250000005, 43.898828125],
              [135.4833984375, 43.835009765624996],
              [135.26015625000002, 43.684619140624996],
              [135.1310546875, 43.525732421875],
              [134.9169921875, 43.4265625],
              [134.69179687500002, 43.290576171874996],
              [134.1564453125, 43.042138671874994],
              [134.01044921875, 42.9474609375],
              [133.709375, 42.829931640625],
              [133.58671875000005, 42.82822265625],
              [133.32949218750002, 42.7638671875],
              [133.15996093750005, 42.69697265625],
              [133.059375, 42.722802734374994],
              [132.99658203125, 42.808007812499994],
              [132.92392578125003, 42.8052734375],
              [132.86357421875005, 42.79375],
              [132.708984375, 42.875830078125],
              [132.57646484375005, 42.87158203125],
              [132.48134765625002, 42.909765625],
              [132.30380859375003, 42.88330078125],
              [132.334375, 43.238671875],
              [132.3095703125, 43.313525390624996],
              [132.23320312500005, 43.245068359375],
              [132.02871093750002, 43.118945312499996],
              [131.947265625, 43.09541015625],
              [131.86660156250002, 43.095166015625],
              [131.89833984375002, 43.170751953125],
              [132.01308593750002, 43.280029296875],
              [131.97626953125, 43.296044921874994],
              [131.93896484375, 43.301953125],
              [131.79472656250005, 43.255273437499994],
              [131.72207031250002, 43.20263671875],
              [131.51640625000005, 42.996435546875],
              [131.39326171875, 42.822314453124996],
              [131.29248046875, 42.772119140624994],
              [131.2453125, 42.697412109374994],
              [131.15830078125003, 42.626025390624996],
              [131.02480468750002, 42.645166015624994],
              [130.94570312500002, 42.633935546874994],
              [130.75615234375005, 42.673291015625],
              [130.709375, 42.656396484374994],
              [130.8341796875, 42.52294921875],
              [130.72988281250002, 42.32578125],
              [130.68730468750005, 42.302539062499996],
            ],
          ],
          [
            [
              [47.441992187500006, 80.853662109375],
              [47.899511718750006, 80.8126953125],
              [48.243261718750006, 80.823486328125],
              [48.34521484375, 80.818994140625],
              [48.44570312500002, 80.806005859375],
              [48.54736328125, 80.779052734375],
              [48.6865234375, 80.7177734375],
              [48.68359375, 80.633251953125],
              [48.62548828125, 80.629296875],
              [48.04433593750002, 80.6681640625],
              [47.77734375, 80.75625],
              [47.70527343750001, 80.765185546875],
              [47.60009765625, 80.741943359375],
              [47.512304687500006, 80.687939453125],
              [47.414160156250006, 80.67451171875],
              [47.30390625000001, 80.606201171875],
              [47.1982421875, 80.61494140625],
              [47.14492187500002, 80.609033203125],
              [47.01103515625002, 80.562109375],
              [46.67753906250002, 80.561328125],
              [46.62392578125002, 80.540673828125],
              [46.513671875, 80.475537109375],
              [46.378125, 80.456787109375],
              [46.14140625000002, 80.446728515625],
              [46.05986328125002, 80.4837890625],
              [46.02363281250001, 80.540869140625],
              [45.96904296875002, 80.569482421875],
              [45.64082031250001, 80.536962890625],
              [45.389257812500006, 80.560302734375],
              [45.14921875000002, 80.59873046875],
              [44.90498046875001, 80.611279296875],
              [45.12451171875, 80.65224609375],
              [46.32744140625002, 80.73515625],
              [46.79912109375002, 80.755224609375],
              [47.020605468750006, 80.814404296875],
              [47.35234375000002, 80.8529296875],
              [47.441992187500006, 80.853662109375],
            ],
          ],
          [
            [
              [50.278125, 80.92724609375],
              [50.43144531250002, 80.910888671875],
              [50.80107421875002, 80.91416015625],
              [50.91767578125001, 80.8904296875],
              [51.45478515625001, 80.744677734375],
              [51.59101562500001, 80.740771484375],
              [51.70361328125, 80.687646484375],
              [51.14619140625001, 80.603955078125],
              [50.96083984375002, 80.540478515625],
              [50.27968750000002, 80.52734375],
              [49.84599609375002, 80.49765625],
              [49.74980468750002, 80.4720703125],
              [49.79414062500001, 80.425341796875],
              [49.5859375, 80.3765625],
              [48.89609375, 80.369189453125],
              [48.81103515625, 80.3537109375],
              [48.67705078125002, 80.300048828125],
              [48.68896484375, 80.290283203125],
              [48.921972656250006, 80.276806640625],
              [48.959570312500006, 80.265673828125],
              [48.990820312500006, 80.2423828125],
              [49.0107421875, 80.207421875],
              [48.9775390625, 80.16259765625],
              [48.89189453125002, 80.155322265625],
              [48.79736328125, 80.1611328125],
              [48.58173828125001, 80.195361328125],
              [48.55458984375002, 80.18330078125],
              [48.53261718750002, 80.158251953125],
              [48.466796875, 80.110107421875],
              [48.38623046875, 80.09580078125],
              [48.16718750000001, 80.132763671875],
              [48.09589843750001, 80.122314453125],
              [48.02578125000002, 80.099462890625],
              [47.93994140625, 80.088623046875],
              [47.7373046875, 80.081689453125],
              [47.632421875, 80.111962890625],
              [47.72314453125, 80.1513671875],
              [47.9775390625, 80.212548828125],
              [47.89296875000002, 80.2392578125],
              [47.64238281250002, 80.2453125],
              [47.4443359375, 80.230126953125],
              [47.34306640625002, 80.188525390625],
              [47.248632812500006, 80.180224609375],
              [46.99101562500002, 80.182763671875],
              [46.84589843750001, 80.23720703125],
              [46.73818359375002, 80.257666015625],
              [46.64443359375002, 80.300341796875],
              [47.402929687500006, 80.444775390625],
              [47.65605468750002, 80.500537109375],
              [47.89580078125002, 80.529052734375],
              [48.20820312500001, 80.543896484375],
              [48.30615234375, 80.561572265625],
              [48.40263671875002, 80.568798828125],
              [48.46474609375002, 80.558056640625],
              [48.625097656250006, 80.50830078125],
              [49.08779296875002, 80.515771484375],
              [49.18525390625001, 80.558642578125],
              [49.19267578125002, 80.656005859375],
              [49.1474609375, 80.712109375],
              [49.24433593750001, 80.82138671875],
              [49.5078125, 80.86533203125],
              [50.12431640625002, 80.923876953125],
              [50.278125, 80.92724609375],
            ],
          ],
          [
            [
              [67.76533203125001, 76.23759765625],
              [67.365234375, 76.161279296875],
              [67.126953125, 76.108154296875],
              [66.8931640625, 76.072265625],
              [66.65742187500001, 76.047021484375],
              [66.28242187500001, 75.98369140625],
              [65.619140625, 75.904638671875],
              [65.20156250000002, 75.839453125],
              [64.74453125000002, 75.788232421875],
              [64.26259765625002, 75.719677734375],
              [63.779296875, 75.672607421875],
              [63.65947265625002, 75.66875],
              [63.31669921875002, 75.603076171875],
              [63.045996093750006, 75.575732421875],
              [62.06611328125001, 75.427734375],
              [61.6162109375, 75.31962890625],
              [61.48652343750001, 75.31083984375],
              [61.35595703125, 75.31484375],
              [61.24882812500002, 75.281005859375],
              [61.14726562500002, 75.22255859375],
              [60.935644531250006, 75.163671875],
              [60.829199218750006, 75.11083984375],
              [60.71923828125, 75.068603515625],
              [60.655371093750006, 75.055029296875],
              [60.533789062500006, 75.05927734375],
              [60.4755859375, 75.054736328125],
              [60.27685546875, 75.007568359375],
              [60.24111328125002, 74.970751953125],
              [60.45488281250002, 74.946142578125],
              [60.50136718750002, 74.904638671875],
              [60.43916015625001, 74.875341796875],
              [60.30078125, 74.83701171875],
              [60.22246093750002, 74.79658203125],
              [60.080078125, 74.755859375],
              [59.98232421875002, 74.74462890625],
              [59.74726562500001, 74.7458984375],
              [59.73466796875002, 74.695458984375],
              [59.771484375, 74.664453125],
              [59.75273437500002, 74.63701171875],
              [59.67402343750001, 74.61015625],
              [59.59599609375002, 74.613720703125],
              [59.24013671875002, 74.69296875],
              [59.18203125000002, 74.665771484375],
              [59.15703125000002, 74.61083984375],
              [59.14609375, 74.551904296875],
              [59.10097656250002, 74.50751953125],
              [59.04042968750002, 74.485546875],
              [58.92822265625, 74.4626953125],
              [58.53466796875, 74.49892578125],
              [58.50214843750001, 74.464208984375],
              [58.56201171875, 74.421826171875],
              [58.64570312500001, 74.32802734375],
              [58.6650390625, 74.2892578125],
              [58.61787109375001, 74.227392578125],
              [58.44140625, 74.128857421875],
              [57.76738281250002, 74.013818359375],
              [57.778417968750006, 73.97392578125],
              [57.85341796875002, 73.8978515625],
              [57.87226562500001, 73.850439453125],
              [57.84492187500001, 73.805078125],
              [57.755957031250006, 73.769189453125],
              [57.65742187500001, 73.7681640625],
              [57.60371093750001, 73.77548828125],
              [57.44853515625002, 73.825634765625],
              [57.313085937500006, 73.838037109375],
              [57.29091796875002, 73.81455078125],
              [57.46425781250002, 73.746044921875],
              [57.542578125, 73.658203125],
              [57.45976562500002, 73.610302734375],
              [57.134375, 73.50439453125],
              [56.9638671875, 73.366552734375],
              [56.63417968750002, 73.304296875],
              [56.43037109375001, 73.297216796875],
              [56.22832031250002, 73.314111328125],
              [56.03457031250002, 73.3458984375],
              [55.54921875000002, 73.3568359375],
              [55.28017578125002, 73.392041015625],
              [55.0068359375, 73.453857421875],
              [54.768652343750006, 73.4494140625],
              [54.56582031250002, 73.418505859375],
              [54.299902343750006, 73.3509765625],
              [54.13154296875001, 73.481005859375],
              [54.20458984375, 73.542041015625],
              [53.83867187500002, 73.697119140625],
              [53.76289062500001, 73.766162109375],
              [53.85136718750002, 73.800537109375],
              [53.963476562500006, 73.822314453125],
              [54.17402343750001, 73.8857421875],
              [54.386328125, 73.93564453125],
              [54.60566406250001, 73.951318359375],
              [54.642675781250006, 73.9595703125],
              [54.7333984375, 74.033984375],
              [54.83125, 74.095751953125],
              [54.92031250000002, 74.1291015625],
              [55.02285156250002, 74.18662109375],
              [55.340917968750006, 74.41962890625],
              [55.41640625000002, 74.4361328125],
              [56.07832031250001, 74.481298828125],
              [56.13710937500002, 74.49609375],
              [55.94746093750001, 74.5421875],
              [55.75175781250002, 74.5412109375],
              [55.66152343750002, 74.556103515625],
              [55.6103515625, 74.59052734375],
              [55.58222656250001, 74.627685546875],
              [55.65966796875, 74.656298828125],
              [55.913671875, 74.79609375],
              [56.217871093750006, 74.897509765625],
              [56.49873046875001, 74.957080078125],
              [56.42851562500002, 74.97294921875],
              [56.34003906250001, 75.0134765625],
              [55.998046875, 75.003369140625],
              [55.86318359375002, 75.058740234375],
              [55.82119140625002, 75.090625],
              [55.81005859375, 75.12490234375],
              [55.92070312500002, 75.168359375],
              [56.03554687500002, 75.19423828125],
              [56.162207031250006, 75.186572265625],
              [56.288671875, 75.164306640625],
              [56.38906250000002, 75.13818359375],
              [56.48525390625002, 75.09609375],
              [56.5703125, 75.09775390625],
              [56.87626953125002, 75.244384765625],
              [56.82929687500001, 75.277734375],
              [56.80947265625002, 75.32841796875],
              [56.84443359375001, 75.351416015625],
              [56.98945312500001, 75.37509765625],
              [57.0875, 75.383837890625],
              [57.3017578125, 75.3732421875],
              [57.60683593750002, 75.341259765625],
              [57.63154296875001, 75.3564453125],
              [57.70820312500001, 75.4544921875],
              [57.78339843750001, 75.506689453125],
              [58.09365234375002, 75.592529296875],
              [58.07255859375002, 75.618994140625],
              [58.05830078125001, 75.6630859375],
              [58.41835937500002, 75.719775390625],
              [58.65273437500002, 75.776806640625],
              [58.88125, 75.85478515625],
              [58.994726562500006, 75.871728515625],
              [59.110449218750006, 75.87373046875],
              [59.34658203125002, 75.90703125],
              [59.78193359375001, 75.945849609375],
              [60.0361328125, 75.983837890625],
              [60.1181640625, 76.066552734375],
              [60.279296875, 76.096240234375],
              [60.60615234375001, 76.108642578125],
              [60.730566406250006, 76.104052734375],
              [60.80117187500002, 76.068798828125],
              [60.94218750000002, 76.0712890625],
              [60.99775390625001, 76.0892578125],
              [61.05390625000001, 76.119873046875],
              [61.03691406250002, 76.16904296875],
              [61.034375, 76.232958984375],
              [61.15693359375001, 76.27353515625],
              [61.20166015625, 76.28203125],
              [61.569433593750006, 76.298486328125],
              [61.787109375, 76.291015625],
              [62.2373046875, 76.2416015625],
              [62.47109375000002, 76.23046875],
              [62.78203125000002, 76.24521484375],
              [62.97148437500002, 76.236669921875],
              [63.52617187500002, 76.309521484375],
              [64.4634765625, 76.378173828125],
              [64.7076171875, 76.426025390625],
              [64.95, 76.484326171875],
              [65.0728515625, 76.496728515625],
              [65.19716796875002, 76.499658203125],
              [65.30976562500001, 76.517919921875],
              [65.52841796875, 76.567822265625],
              [65.63691406250001, 76.578662109375],
              [65.75517578125002, 76.579296875],
              [65.862890625, 76.613330078125],
              [65.95888671875002, 76.687939453125],
              [66.06298828125, 76.74609375],
              [66.34521484375, 76.821044921875],
              [66.82880859375001, 76.923828125],
              [67.263671875, 76.96376953125],
              [67.53496093750002, 77.007763671875],
              [67.65185546875, 77.011572265625],
              [68.01728515625001, 76.990625],
              [68.48574218750002, 76.93369140625],
              [68.69912109375002, 76.870654296875],
              [68.87333984375002, 76.789599609375],
              [68.91171875, 76.760546875],
              [68.94169921875002, 76.707666015625],
              [68.89052734375002, 76.659716796875],
              [68.8580078125, 76.610498046875],
              [68.89980468750002, 76.57294921875],
              [68.55859375, 76.4494140625],
              [68.22236328125001, 76.3134765625],
              [68.16542968750002, 76.28486328125],
              [67.76533203125001, 76.23759765625],
            ],
          ],
          [
            [
              [55.31982421875, 73.30830078125],
              [55.78730468750001, 73.268603515625],
              [56.1376953125, 73.25615234375],
              [56.35048828125002, 73.225537109375],
              [56.42958984375002, 73.201171875],
              [56.3974609375, 73.13916015625],
              [56.33466796875001, 73.113671875],
              [56.18896484375, 73.0330078125],
              [56.1669921875, 72.983203125],
              [56.19287109375, 72.90498046875],
              [56.170507812500006, 72.848095703125],
              [56.121679687500006, 72.806591796875],
              [56.08378906250002, 72.789404296875],
              [55.81972656250002, 72.789501953125],
              [55.72343750000002, 72.76640625],
              [55.71845703125001, 72.721533203125],
              [55.70097656250002, 72.671728515625],
              [55.61640625000001, 72.599072265625],
              [55.44130859375002, 72.575390625],
              [55.4033203125, 72.549072265625],
              [55.41689453125002, 72.501318359375],
              [55.35595703125, 72.465087890625],
              [55.35957031250001, 72.40869140625],
              [55.39042968750002, 72.37783203125],
              [55.39912109375001, 72.313623046875],
              [55.51806640625, 72.220654296875],
              [55.49492187500002, 72.18232421875],
              [55.4033203125, 72.106884765625],
              [55.375, 72.014892578125],
              [55.2978515625, 71.9353515625],
              [55.47109375000002, 71.86923828125],
              [55.54667968750002, 71.783349609375],
              [55.61367187500002, 71.689892578125],
              [55.8193359375, 71.507568359375],
              [56.04316406250001, 71.34560546875],
              [56.45439453125002, 71.107373046875],
              [56.89482421875002, 70.927001953125],
              [57.065625, 70.876025390625],
              [57.48359375000001, 70.79228515625],
              [57.55644531250002, 70.7658203125],
              [57.62539062500002, 70.72880859375],
              [57.44716796875002, 70.66103515625],
              [57.263671875, 70.63603515625],
              [57.24697265625002, 70.605126953125],
              [57.14589843750002, 70.589111328125],
              [56.64882812500002, 70.646533203125],
              [56.621679687500006, 70.65537109375],
              [56.56865234375002, 70.6974609375],
              [56.51005859375002, 70.72880859375],
              [56.3857421875, 70.734130859375],
              [56.26005859375002, 70.71474609375],
              [56.33476562500002, 70.676708984375],
              [56.41718750000001, 70.66494140625],
              [56.56132812500002, 70.5935546875],
              [56.49970703125001, 70.56640625],
              [56.4345703125, 70.56298828125],
              [56.14248046875002, 70.657861328125],
              [56.11474609375, 70.646142578125],
              [56.08710937500001, 70.618359375],
              [55.94160156250001, 70.649267578125],
              [55.9072265625, 70.626318359375],
              [55.796875, 70.615576171875],
              [55.70673828125001, 70.64189453125],
              [55.70644531250002, 70.675244140625],
              [55.68730468750002, 70.6921875],
              [55.236914062500006, 70.666015625],
              [55.05166015625002, 70.666748046875],
              [54.86708984375002, 70.678125],
              [54.645117187500006, 70.741845703125],
              [54.60820312500002, 70.713232421875],
              [54.601171875, 70.680078125],
              [54.51738281250002, 70.693310546875],
              [54.332617187500006, 70.744677734375],
              [54.19941406250001, 70.764892578125],
              [53.72236328125001, 70.814453125],
              [53.38359375000002, 70.87353515625],
              [53.4677734375, 70.9005859375],
              [53.61357421875002, 70.9146484375],
              [53.615625, 70.950830078125],
              [53.59257812500002, 71.00068359375],
              [53.58779296875002, 71.052294921875],
              [53.670507812500006, 71.0869140625],
              [53.85703125, 71.07041015625],
              [53.83427734375002, 71.126708984375],
              [53.92226562500002, 71.13759765625],
              [54.09394531250001, 71.105224609375],
              [54.15566406250002, 71.12548828125],
              [53.88613281250002, 71.1962890625],
              [53.5908203125, 71.2966796875],
              [53.622167968750006, 71.332763671875],
              [53.515234375, 71.342529296875],
              [53.40996093750002, 71.34013671875],
              [53.31904296875001, 71.399169921875],
              [53.33251953125, 71.47724609375],
              [53.41162109375, 71.530126953125],
              [53.363867187500006, 71.541650390625],
              [52.90898437500002, 71.49501953125],
              [52.6787109375, 71.5056640625],
              [52.41884765625002, 71.536865234375],
              [52.17998046875002, 71.490234375],
              [51.93789062500002, 71.47470703125],
              [51.812597656250006, 71.49130859375],
              [51.69160156250001, 71.525146484375],
              [51.590429687500006, 71.571142578125],
              [51.511328125, 71.648095703125],
              [51.43867187500001, 71.776806640625],
              [51.42861328125002, 71.825537109375],
              [51.44355468750001, 71.934375],
              [51.48222656250002, 71.97978515625],
              [51.58251953125, 72.07119140625],
              [51.653125, 72.099365234375],
              [51.80546875000002, 72.142138671875],
              [51.88544921875001, 72.15322265625],
              [52.06865234375002, 72.13115234375],
              [52.252050781250006, 72.129736328125],
              [52.33232421875002, 72.153955078125],
              [52.40673828125, 72.196728515625],
              [52.4619140625, 72.25234375],
              [52.58613281250001, 72.284033203125],
              [52.6220703125, 72.3009765625],
              [52.66191406250002, 72.336865234375],
              [52.70576171875001, 72.390966796875],
              [52.7138671875, 72.436962890625],
              [52.74873046875001, 72.482958984375],
              [52.86367187500002, 72.549853515625],
              [52.8232421875, 72.591259765625],
              [52.83906250000001, 72.619287109375],
              [52.916601562500006, 72.668896484375],
              [52.68310546875, 72.68232421875],
              [52.60498046875, 72.704052734375],
              [52.52851562500001, 72.737353515625],
              [52.55058593750002, 72.7685546875],
              [52.57929687500001, 72.791357421875],
              [52.81220703125001, 72.875244140625],
              [52.913183593750006, 72.899951171875],
              [53.02421875000002, 72.91357421875],
              [53.13496093750001, 72.913232421875],
              [53.253515625, 72.903759765625],
              [53.36982421875001, 72.916748046875],
              [53.24726562500001, 72.97314453125],
              [53.23710937500002, 73.011181640625],
              [53.18896484375, 73.10400390625],
              [53.19794921875001, 73.14755859375],
              [53.25117187500001, 73.182958984375],
              [53.35761718750001, 73.224560546875],
              [53.51220703125, 73.23837890625],
              [53.63369140625002, 73.26025390625],
              [53.75322265625002, 73.29326171875],
              [53.865625, 73.298974609375],
              [54.09101562500001, 73.27646484375],
              [54.20234375000001, 73.28134765625],
              [54.32763671875, 73.299462890625],
              [54.67607421875002, 73.37001953125],
              [54.80390625000001, 73.387646484375],
              [54.940625, 73.383251953125],
              [55.12138671875002, 73.3568359375],
              [55.31982421875, 73.30830078125],
            ],
          ],
          [
            [
              [96.52656250000001, 81.0755859375],
              [96.56308593750003, 81.030078125],
              [96.69326171875002, 80.994189453125],
              [96.75498046875003, 80.957861328125],
              [97.41367187500003, 80.841845703125],
              [97.70302734375002, 80.826708984375],
              [97.83183593749999, 80.798291015625],
              [97.86992187499999, 80.76328125],
              [97.8564453125, 80.698095703125],
              [97.74716796875003, 80.698681640625],
              [97.66542968750002, 80.678076171875],
              [97.22138671875001, 80.65244140625],
              [97.11308593749999, 80.6140625],
              [97.025390625, 80.535546875],
              [97.07255859374999, 80.519873046875],
              [97.11503906249999, 80.49658203125],
              [97.25019531250001, 80.36298828125],
              [97.28681640625001, 80.342529296875],
              [97.4169921875, 80.32314453125],
              [97.29843750000003, 80.27275390625],
              [97.17519531250002, 80.241015625],
              [95.85576171874999, 80.176953125],
              [94.96132812500002, 80.150390625],
              [94.66123046875003, 80.122802734375],
              [94.56503906250003, 80.12607421875],
              [94.32841796874999, 80.076025390625],
              [93.87236328124999, 80.010107421875],
              [93.65468750000002, 80.009619140625],
              [93.00234375000002, 80.102099609375],
              [92.20156250000002, 80.179296875],
              [92.09218750000002, 80.22333984375],
              [91.8916015625, 80.249267578125],
              [91.63740234375001, 80.269921875],
              [91.52382812500002, 80.358544921875],
              [91.68779296874999, 80.418505859375],
              [91.89667968750001, 80.4775390625],
              [92.24667968750003, 80.49912109375],
              [92.57792968749999, 80.533251953125],
              [92.82675781250003, 80.6185546875],
              [92.98105468750003, 80.702978515625],
              [93.2625, 80.791259765625],
              [92.77294921875, 80.76865234375],
              [92.59257812499999, 80.780859375],
              [92.61015624999999, 80.810009765625],
              [92.71035156250002, 80.87216796875],
              [92.7646484375, 80.89306640625],
              [92.93867187500001, 80.925830078125],
              [93.06513671875001, 80.9884765625],
              [93.35869140624999, 81.031689453125],
              [93.49736328124999, 81.039208984375],
              [93.63671875, 81.038134765625],
              [93.88886718750001, 81.0583984375],
              [94.14013671875, 81.089453125],
              [94.37548828125, 81.107373046875],
              [94.61162109374999, 81.1146484375],
              [94.837890625, 81.139404296875],
              [95.06093750000002, 81.1880859375],
              [95.15957031250002, 81.27099609375],
              [95.80068359375002, 81.28046875],
              [95.90195312500003, 81.260595703125],
              [95.98398437500003, 81.21142578125],
              [96.0751953125, 81.1927734375],
              [96.18691406250002, 81.183935546875],
              [96.47109375000002, 81.099267578125],
              [96.52656250000001, 81.0755859375],
            ],
          ],
          [
            [
              [97.67451171875001, 80.158251953125],
              [97.90361328124999, 80.09501953125],
              [98.01777343750001, 80.0228515625],
              [97.90673828125, 80.003759765625],
              [97.80791015624999, 79.956298828125],
              [97.75996093750001, 79.895849609375],
              [97.626953125, 79.850439453125],
              [97.59130859375, 79.774951171875],
              [97.65166015624999, 79.76064453125],
              [97.72451171875002, 79.781396484375],
              [97.87070312500003, 79.85263671875],
              [98.06455078125003, 79.90107421875],
              [98.27324218749999, 79.87412109375],
              [98.353125, 79.884326171875],
              [98.4990234375, 79.953125],
              [98.471875, 80.009130859375],
              [98.53183593750003, 80.043603515625],
              [98.59648437499999, 80.052197265625],
              [98.86591796875001, 80.04541015625],
              [99.294921875, 80.016357421875],
              [99.37070312500003, 79.986376953125],
              [99.47304687500002, 79.970166015625],
              [99.5361328125, 79.94130859375],
              [99.7265625, 79.919921875],
              [99.818359375, 79.898193359375],
              [99.94658203124999, 79.848974609375],
              [100.06123046875001, 79.777099609375],
              [99.91582031249999, 79.738330078125],
              [99.83925781250002, 79.6689453125],
              [99.80546874999999, 79.653076171875],
              [99.78164062500002, 79.628271484375],
              [99.77109375000003, 79.567724609375],
              [99.74882812499999, 79.515185546875],
              [99.72119140625, 79.491845703125],
              [99.70625, 79.4634765625],
              [99.72158203125002, 79.385107421875],
              [99.6806640625, 79.32333984375],
              [99.53730468750001, 79.2765625],
              [99.38779296875003, 79.274755859375],
              [99.16708984375003, 79.306298828125],
              [99.10439453125002, 79.30537109375],
              [99.04179687499999, 79.293017578125],
              [99.3173828125, 79.227197265625],
              [99.51728515625001, 79.13017578125],
              [99.75078124999999, 79.107666015625],
              [99.81464843750001, 79.095849609375],
              [99.89960937500001, 79.006396484375],
              [99.92929687500003, 78.96142578125],
              [99.54082031249999, 78.852734375],
              [99.43955078125003, 78.834228515625],
              [98.81953125000001, 78.81826171875],
              [98.4111328125, 78.78779296875],
              [98.28251953124999, 78.79501953125],
              [98.05419921875, 78.82099609375],
              [97.90517578125002, 78.810205078125],
              [97.68857421875003, 78.82734375],
              [97.55546874999999, 78.8265625],
              [97.24814453125003, 78.868017578125],
              [96.93291015624999, 78.933935546875],
              [96.87119140625003, 78.963818359375],
              [96.80781250000001, 78.9849609375],
              [96.42998046874999, 79.00302734375],
              [96.34736328125001, 79.015869140625],
              [95.79648437500003, 79.001416015625],
              [95.70283203125001, 79.01201171875],
              [95.53105468749999, 79.098095703125],
              [95.43691406250002, 79.09931640625],
              [95.13320312500002, 79.049609375],
              [95.02041015625002, 79.052685546875],
              [94.791015625, 79.08662109375],
              [94.65234375, 79.127490234375],
              [94.63164062499999, 79.140869140625],
              [94.61972656250003, 79.1923828125],
              [94.48212890625001, 79.218603515625],
              [94.31376953124999, 79.30751953125],
              [94.21875, 79.40234375],
              [93.75859374999999, 79.451416015625],
              [93.47871093750001, 79.462744140625],
              [93.27226562499999, 79.4583984375],
              [93.07080078125, 79.4953125],
              [93.40468750000002, 79.631591796875],
              [93.84726562500003, 79.70166015625],
              [94.03818359375003, 79.756005859375],
              [94.25712890624999, 79.829736328125],
              [94.34726562500003, 79.941943359375],
              [94.71943359375001, 80.01123046875],
              [94.81503906250003, 80.034814453125],
              [94.94677734375, 80.0892578125],
              [94.9873046875, 80.096826171875],
              [95.28134765625003, 80.030517578125],
              [95.33798828125003, 80.042138671875],
              [95.39072265625003, 80.072802734375],
              [95.49755859375, 80.105615234375],
              [95.85781250000002, 80.110009765625],
              [96.1625, 80.096826171875],
              [96.27734375, 80.11005859375],
              [96.41660156250003, 80.104345703125],
              [97.12050781250002, 80.15302734375],
              [97.58681640625002, 80.16826171875],
              [97.67451171875001, 80.158251953125],
            ],
          ],
          [
            [
              [102.884765625, 79.253955078125],
              [102.78730468750001, 79.176416015625],
              [102.74580078125001, 79.1060546875],
              [102.44785156250003, 78.87666015625],
              [102.41230468750001, 78.83544921875],
              [102.58730468750002, 78.8712890625],
              [102.74765625000003, 78.949560546875],
              [102.84482421875003, 79.01435546875],
              [102.95039062500001, 79.05576171875],
              [103.07568359375, 79.056494140625],
              [103.19912109375002, 79.0712890625],
              [103.43339843749999, 79.126123046875],
              [103.6728515625, 79.15],
              [103.80078125, 79.149267578125],
              [103.92568359375002, 79.1232421875],
              [104.00400390625003, 79.062548828125],
              [104.09111328124999, 79.01318359375],
              [104.40419921875002, 78.977099609375],
              [104.44921875, 78.963916015625],
              [104.47695312500002, 78.92333984375],
              [104.45205078125002, 78.880029296875],
              [104.63320312500002, 78.83515625],
              [104.88105468750001, 78.8548828125],
              [105.0146484375, 78.843310546875],
              [105.14599609375, 78.81884765625],
              [105.20458984375, 78.779931640625],
              [105.25605468750001, 78.7330078125],
              [105.31015625000003, 78.666162109375],
              [105.34267578125002, 78.5939453125],
              [105.31259765625003, 78.49990234375],
              [104.83261718750003, 78.352734375],
              [104.74179687500003, 78.33974609375],
              [104.51943359375002, 78.34921875],
              [104.29746093750003, 78.33505859375],
              [103.71933593750003, 78.258251953125],
              [103.003125, 78.255859375],
              [102.79667968749999, 78.187890625],
              [102.734375, 78.189892578125],
              [102.67314453124999, 78.201708984375],
              [102.6171875, 78.224609375],
              [102.18046874999999, 78.205322265625],
              [101.6923828125, 78.1943359375],
              [101.2041015625, 78.191943359375],
              [101.03994140625002, 78.14296875],
              [100.54121093750001, 78.047509765625],
              [100.08222656250001, 77.975],
              [99.84501953124999, 77.9568359375],
              [99.50029296874999, 77.97607421875],
              [99.39169921875003, 78.00068359375],
              [99.287109375, 78.0380859375],
              [99.43867187500001, 78.083935546875],
              [99.54560546875001, 78.178564453125],
              [99.67792968750001, 78.23349609375],
              [100.01894531250002, 78.338916015625],
              [100.05751953125002, 78.38037109375],
              [100.12353515625, 78.470458984375],
              [100.16298828125002, 78.503955078125],
              [100.21503906250001, 78.535791015625],
              [100.25722656250002, 78.573828125],
              [100.2626953125, 78.631494140625],
              [100.28398437499999, 78.67919921875],
              [100.41640625000002, 78.753173828125],
              [100.515625, 78.78779296875],
              [100.61962890625, 78.797412109375],
              [100.87558593750003, 78.78359375],
              [100.95576171875001, 78.7884765625],
              [100.89794921875, 78.812451171875],
              [100.85625, 78.89775390625],
              [100.86455078124999, 78.925830078125],
              [100.9013671875, 78.980078125],
              [100.96542968750003, 79.00654296875],
              [101.03085937500003, 79.023291015625],
              [101.06816406249999, 79.096240234375],
              [101.05224609375, 79.1232421875],
              [101.14882812500002, 79.156884765625],
              [101.19609374999999, 79.204443359375],
              [101.31044921875002, 79.2326171875],
              [101.54306640625003, 79.254443359375],
              [101.55527343750003, 79.312646484375],
              [101.590625, 79.350439453125],
              [101.64335937499999, 79.361376953125],
              [101.76132812500003, 79.37197265625],
              [101.82421875, 79.37021484375],
              [101.912109375, 79.31162109375],
              [102.00527343750002, 79.263671875],
              [102.12851562500003, 79.252490234375],
              [102.25126953124999, 79.2560546875],
              [102.17724609375, 79.31259765625],
              [102.1806640625, 79.373388671875],
              [102.22509765625, 79.412939453125],
              [102.28242187500001, 79.430078125],
              [102.40488281250003, 79.433203125],
              [102.78984374999999, 79.392138671875],
              [103.04160156250003, 79.33154296875],
              [103.09794921874999, 79.29912109375],
              [103.05244140625001, 79.28251953125],
              [102.93964843750001, 79.27119140625],
              [102.884765625, 79.253955078125],
            ],
          ],
          [
            [
              [140.04873046875002, 75.828955078125],
              [140.15214843750005, 75.809814453125],
              [140.2744140625, 75.822412109375],
              [140.38906250000002, 75.795849609375],
              [140.4962890625, 75.689794921875],
              [140.54667968750005, 75.66318359375],
              [140.60214843750003, 75.6439453125],
              [140.65673828125, 75.634130859375],
              [140.81591796875, 75.630712890625],
              [140.88925781250003, 75.652001953125],
              [140.94414062500005, 75.70048828125],
              [140.9404296875, 75.74951171875],
              [140.92656250000005, 75.79892578125],
              [140.92578125, 75.866845703125],
              [140.95029296875003, 75.92734375],
              [140.9853515625, 75.964501953125],
              [141.03261718750002, 75.98896484375],
              [141.29931640625, 76.06376953125],
              [141.48544921875003, 76.137158203125],
              [141.74228515625003, 76.108056640625],
              [142.00146484375, 76.0435546875],
              [142.46035156250002, 75.90361328125],
              [142.66953125000003, 75.863427734375],
              [142.9267578125, 75.826904296875],
              [143.18515625000003, 75.813623046875],
              [143.31113281250003, 75.822314453125],
              [143.55996093750002, 75.860400390625],
              [143.68583984375005, 75.863671875],
              [145.25527343750002, 75.585595703125],
              [145.309765625, 75.5640625],
              [145.35996093750003, 75.53046875],
              [145.0234375, 75.48974609375],
              [144.803125, 75.416064453125],
              [144.7267578125, 75.365576171875],
              [144.81425781250005, 75.32451171875],
              [144.88349609375, 75.2689453125],
              [144.40781250000003, 75.102294921875],
              [144.216015625, 75.0591796875],
              [144.0197265625, 75.044677734375],
              [143.62587890625002, 75.083984375],
              [143.39609375000003, 75.082861328125],
              [143.17031250000002, 75.11689453125],
              [142.9220703125, 75.217431640625],
              [142.82011718750005, 75.267822265625],
              [142.7294921875, 75.337646484375],
              [142.69960937500002, 75.448876953125],
              [142.73447265625003, 75.544580078125],
              [142.86757812500002, 75.57177734375],
              [142.98603515625, 75.633251953125],
              [143.00244140625, 75.65986328125],
              [142.94179687500002, 75.71328125],
              [142.55156250000005, 75.7208984375],
              [142.30791015625005, 75.69169921875],
              [142.08623046875005, 75.66064453125],
              [142.15107421875, 75.457568359375],
              [142.19882812500003, 75.39267578125],
              [142.26474609375003, 75.346142578125],
              [142.61679687500003, 75.133251953125],
              [142.69697265625, 75.103076171875],
              [142.9296875, 75.06240234375],
              [143.1279296875, 74.9703125],
              [142.77822265625002, 74.8677734375],
              [142.62607421875003, 74.83740234375],
              [142.47275390625003, 74.82041015625],
              [142.37841796875, 74.828564453125],
              [142.28740234375005, 74.84990234375],
              [142.18417968750003, 74.899609375],
              [142.1, 74.9509765625],
              [141.9873046875, 74.991259765625],
              [141.74843750000002, 74.982568359375],
              [141.52998046875, 74.94716796875],
              [141.31044921875002, 74.923193359375],
              [140.66074218750003, 74.8818359375],
              [140.4638671875, 74.8560546875],
              [140.26787109375005, 74.846923828125],
              [140.01103515625005, 74.894775390625],
              [139.75820312500002, 74.96376953125],
              [139.68125, 74.9640625],
              [139.60585937500002, 74.94560546875],
              [139.548046875, 74.904052734375],
              [139.51230468750003, 74.83779296875],
              [139.43007812500002, 74.74921875],
              [139.32558593750002, 74.68681640625],
              [139.21533203125, 74.65966796875],
              [139.09912109375, 74.65654296875],
              [138.98173828125005, 74.673681640625],
              [138.865625, 74.700927734375],
              [138.09228515625, 74.7974609375],
              [138.00136718750002, 74.827001953125],
              [137.9150390625, 74.870849609375],
              [137.68300781250002, 75.008544921875],
              [137.56806640625, 75.040576171875],
              [137.44697265625, 75.05419921875],
              [137.21796875, 75.12373046875],
              [137.00625, 75.235009765625],
              [136.96230468750002, 75.270361328125],
              [136.94765625000002, 75.325537109375],
              [136.982421875, 75.36533203125],
              [137.166015625, 75.34658203125],
              [137.28974609375, 75.3486328125],
              [137.21523437500002, 75.55439453125],
              [137.26884765625005, 75.7494140625],
              [137.35849609375003, 75.781640625],
              [137.70654296875, 75.7595703125],
              [137.59355468750005, 75.823388671875],
              [137.501171875, 75.90966796875],
              [137.560546875, 75.955224609375],
              [137.62539062500002, 75.98818359375],
              [137.7744140625, 76.015673828125],
              [137.97705078125, 76.027783203125],
              [138.03867187500003, 76.047265625],
              [138.09599609375005, 76.080517578125],
              [138.20761718750003, 76.11494140625],
              [138.4306640625, 76.130078125],
              [138.81396484375, 76.19970703125],
              [138.91953125000003, 76.196728515625],
              [139.017578125, 76.160107421875],
              [139.10917968750005, 76.108349609375],
              [139.21132812500002, 76.080712890625],
              [139.528515625, 76.013427734375],
              [139.743359375, 75.953076171875],
              [140.04873046875002, 75.828955078125],
            ],
          ],
          [
            [
              [146.79521484375005, 75.370751953125],
              [147.06035156250005, 75.364306640625],
              [147.4435546875, 75.43798828125],
              [147.49697265625002, 75.44052734375],
              [148.43242187500005, 75.413525390625],
              [148.50888671875003, 75.387451171875],
              [148.51884765625005, 75.336474609375],
              [148.48916015625002, 75.309375],
              [148.475, 75.272412109375],
              [148.59013671875005, 75.236376953125],
              [148.89218750000003, 75.228125],
              [149.083203125, 75.262060546875],
              [149.64531250000005, 75.244580078125],
              [150.10390625000002, 75.21923828125],
              [150.28066406250002, 75.164013671875],
              [150.4171875, 75.134326171875],
              [150.53056640625005, 75.099853515625],
              [150.61289062500003, 75.120166015625],
              [150.69033203125002, 75.155322265625],
              [150.75693359375003, 75.16240234375],
              [150.82236328125003, 75.15654296875],
              [150.64628906250005, 74.944580078125],
              [150.5802734375, 74.9189453125],
              [150.33125, 74.866796875],
              [149.8380859375, 74.7953125],
              [149.596875, 74.772607421875],
              [149.05019531250002, 74.7724609375],
              [148.296875, 74.800439453125],
              [148.09238281250003, 74.82568359375],
              [147.971875, 74.85732421875],
              [147.74091796875, 74.931982421875],
              [147.62685546875002, 74.958935546875],
              [147.25703125, 74.98427734375],
              [147.14404296875, 74.9984375],
              [146.92490234375003, 75.0625],
              [146.7033203125, 75.114208984375],
              [146.14853515625003, 75.198291015625],
              [146.18613281250003, 75.295556640625],
              [146.25761718750005, 75.39375],
              [146.34296875, 75.480908203125],
              [146.4384765625, 75.558203125],
              [146.5375, 75.581787109375],
              [146.7509765625, 75.51044921875],
              [146.7482421875, 75.428662109375],
              [146.79521484375005, 75.370751953125],
            ],
          ],
          [
            [
              [178.8615234375, 70.826416015625],
              [178.79257812500003, 70.8220703125],
              [178.64824218750005, 71.0005859375],
              [178.62832031250002, 71.04736328125],
              [178.68388671875005, 71.1056640625],
              [178.82900390625002, 71.177880859375],
              [178.89111328125, 71.231103515625],
              [179.23505859375, 71.32451171875],
              [179.54765625000005, 71.44765625],
              [179.71591796875003, 71.4662109375],
              [179.88642578125, 71.52333984375],
              [180, 71.537744140625],
              [180, 70.993017578125],
              [179.88134765625, 70.97568359375],
              [179.64765625, 70.89892578125],
              [179.1525390625, 70.8802734375],
              [178.8615234375, 70.826416015625],
            ],
          ],
          [
            [
              [142.76103515625005, 54.3939453125],
              [142.97617187500003, 54.140966796875],
              [142.98593750000003, 54.085693359375],
              [142.96708984375005, 54.02880859375],
              [142.92656250000005, 53.955615234374996],
              [142.91142578125005, 53.878369140625],
              [142.93642578125002, 53.8109375],
              [142.91796875, 53.794238281249996],
              [143.09550781250005, 53.488671875],
              [143.2236328125, 53.296044921875],
              [143.2599609375, 53.21728515625],
              [143.28789062500005, 53.134375],
              [143.32470703125, 52.9630859375],
              [143.33261718750003, 52.700048828125],
              [143.32363281250002, 52.613574218749996],
              [143.2951171875, 52.529150390625],
              [143.26425781250003, 52.478662109375],
              [143.20097656250005, 52.442919921874996],
              [143.17226562500002, 52.349365234375],
              [143.15556640625005, 52.083740234375],
              [143.190625, 51.944482421875],
              [143.25058593750003, 51.847900390625],
              [143.29472656250005, 51.7443359375],
              [143.29951171875, 51.632373046874996],
              [143.3205078125, 51.583251953125],
              [143.41777343750005, 51.52060546875],
              [143.45546875000002, 51.471484375],
              [143.46738281250003, 51.401904296874996],
              [143.47294921875005, 51.29921875],
              [143.48876953125, 51.27705078125],
              [143.5341796875, 51.2462890625],
              [143.73603515625, 50.506738281249994],
              [143.81601562500003, 50.282617187499994],
              [144.04794921875003, 49.895751953125],
              [144.14130859375, 49.661474609375],
              [144.19960937500002, 49.549755859375],
              [144.23994140625, 49.43203125],
              [144.27207031250003, 49.311328125],
              [144.34121093750002, 49.180517578125],
              [144.43173828125003, 49.051074218749996],
              [144.60683593750002, 48.935839843749996],
              [144.685546875, 48.871240234374994],
              [144.70664062500003, 48.81953125],
              [144.71376953125002, 48.640283203124994],
              [144.67265625000005, 48.678564453125],
              [144.62099609375002, 48.81484375],
              [144.536328125, 48.8935546875],
              [144.41181640625, 48.986376953124996],
              [144.28378906250003, 49.069775390625],
              [144.12548828125, 49.208544921874996],
              [144.04873046875002, 49.249169921874994],
              [143.9677734375, 49.276318359375],
              [143.81914062500005, 49.30859375],
              [143.73232421875002, 49.31201171875],
              [143.38222656250002, 49.290673828124994],
              [143.236328125, 49.262841796874994],
              [143.10498046875, 49.198828125],
              [143.02685546875, 49.105419921875],
              [142.9716796875, 48.917773437499996],
              [142.65097656250003, 48.246875],
              [142.57421875, 48.072167968749994],
              [142.5458984375, 47.884912109374994],
              [142.55693359375005, 47.737890625],
              [142.57900390625002, 47.683984375],
              [142.6701171875, 47.536914062499996],
              [142.74541015625005, 47.452392578125],
              [142.80078125, 47.416162109374994],
              [142.86396484375, 47.391796875],
              [142.90546875, 47.361865234374996],
              [142.94033203125002, 47.32275390625],
              [143.00556640625, 47.222705078124996],
              [143.08925781250002, 47.00078125],
              [143.1779296875, 46.844042968749996],
              [143.21767578125002, 46.794873046875],
              [143.31865234375005, 46.807373046875],
              [143.384375, 46.8056640625],
              [143.447265625, 46.7919921875],
              [143.48564453125005, 46.75205078125],
              [143.54033203125005, 46.575097656249994],
              [143.57871093750003, 46.406054687499996],
              [143.58066406250003, 46.360693359375],
              [143.50859375000005, 46.23017578125],
              [143.490625, 46.174609375],
              [143.48232421875002, 46.1158203125],
              [143.46347656250003, 46.069482421874994],
              [143.431640625, 46.028662109375],
              [143.41865234375, 46.222021484375],
              [143.3703125, 46.35849609375],
              [143.35214843750003, 46.476220703124994],
              [143.28232421875003, 46.558984375],
              [143.0478515625, 46.592626953125],
              [142.829296875, 46.605273437499996],
              [142.79550781250003, 46.62021484375],
              [142.74736328125005, 46.670654296875],
              [142.69189453125, 46.710839843749994],
              [142.6357421875, 46.716210937499994],
              [142.57802734375002, 46.70078125],
              [142.47880859375005, 46.64423828125],
              [142.4064453125, 46.5546875],
              [142.35, 46.45869140625],
              [142.30400390625005, 46.357568359374994],
              [142.20859375000003, 46.0888671875],
              [142.14970703125005, 45.999267578125],
              [142.0771484375, 45.917041015624996],
              [142.015625, 45.96162109375],
              [141.96162109375, 46.013476562499996],
              [141.92998046875005, 46.08828125],
              [141.91630859375005, 46.170751953125],
              [141.83037109375005, 46.451074218749994],
              [141.86650390625005, 46.694189453125],
              [142.01103515625005, 47.030322265624996],
              [142.03867187500003, 47.140283203124994],
              [142.01689453125005, 47.244677734374996],
              [141.98417968750005, 47.347705078124996],
              [141.9625, 47.543798828125],
              [141.9640625, 47.587451171874996],
              [142.015625, 47.700634765625],
              [142.07597656250005, 47.808349609375],
              [142.14921875000005, 47.902148437499996],
              [142.18173828125003, 48.01337890625],
              [142.13535156250003, 48.290087890624996],
              [142.02871093750002, 48.477099609374996],
              [141.89726562500005, 48.6546875],
              [141.873046875, 48.701953125],
              [141.86630859375003, 48.75009765625],
              [141.97958984375003, 48.97216796875],
              [142.02011718750003, 49.078466796875],
              [142.06650390625003, 49.312060546874996],
              [142.10869140625005, 49.4396484375],
              [142.14228515625, 49.569140625],
              [142.153125, 50.216748046875],
              [142.14306640625, 50.312109375],
              [142.07109375000005, 50.514990234375],
              [142.06601562500003, 50.63046875],
              [142.10048828125002, 50.77646484375],
              [142.14726562500005, 50.890185546874996],
              [142.20791015625002, 50.998486328125],
              [142.20673828125, 51.222558593749994],
              [142.09072265625002, 51.42939453125],
              [142.00595703125003, 51.5205078125],
              [141.87294921875002, 51.630029296875],
              [141.771875, 51.690185546875],
              [141.72236328125, 51.736328125],
              [141.771875, 51.751806640625],
              [141.80810546875, 51.789208984375],
              [141.72099609375005, 51.84677734375],
              [141.66845703125, 51.933349609375],
              [141.66083984375, 52.27294921875],
              [141.68242187500005, 52.359130859375],
              [141.74755859375, 52.454833984375],
              [141.80332031250003, 52.555615234375],
              [141.85556640625003, 52.793505859374996],
              [141.87363281250003, 53.038916015625],
              [141.8388671875, 53.138476562499996],
              [141.82353515625005, 53.339501953125],
              [141.85244140625002, 53.389453125],
              [141.96445312500003, 53.456396484375],
              [142.14199218750002, 53.49560546875],
              [142.1798828125, 53.484033203125],
              [142.31894531250003, 53.40546875],
              [142.37050781250002, 53.4025390625],
              [142.4240234375, 53.4107421875],
              [142.52617187500005, 53.4474609375],
              [142.58349609375, 53.536767578125],
              [142.50917968750002, 53.58759765625],
              [142.55253906250005, 53.652636718749996],
              [142.67958984375002, 53.674365234374996],
              [142.68886718750002, 53.73017578125],
              [142.64287109375005, 53.736767578125],
              [142.68300781250002, 53.816015625],
              [142.70595703125002, 53.895703125],
              [142.67021484375005, 53.968408203125],
              [142.46660156250005, 54.14853515625],
              [142.3349609375, 54.280712890625],
              [142.55166015625002, 54.278955078125],
              [142.615625, 54.30361328125],
              [142.6662109375, 54.358203125],
              [142.69277343750002, 54.41611328125],
              [142.76103515625005, 54.3939453125],
            ],
          ],
          [
            [
              [-178.87646484375, 71.57705078125],
              [-178.43896484375, 71.541162109375],
              [-178.353564453125, 71.52919921875],
              [-178.214697265625, 71.481640625],
              [-178.13388671875, 71.465478515625],
              [-178.056640625, 71.43759765625],
              [-177.9748046875, 71.39052734375],
              [-177.8169921875, 71.339990234375],
              [-177.584130859375, 71.281689453125],
              [-177.532177734375, 71.2630859375],
              [-177.498486328125, 71.219140625],
              [-177.523583984375, 71.16689453125],
              [-177.82177734375, 71.067578125],
              [-178.0626953125, 71.041943359375],
              [-178.527978515625, 71.014794921875],
              [-179.156884765625, 70.93984375],
              [-179.415673828125, 70.918994140625],
              [-179.506689453125, 70.9234375],
              [-179.734033203125, 70.9716796875],
              [-179.999951171875, 70.993017578125],
              [-179.999951171875, 71.184228515625],
              [-179.999951171875, 71.39970703125],
              [-179.999951171875, 71.537744140625],
              [-179.844873046875, 71.5509765625],
              [-179.691015625, 71.577978515625],
              [-179.54638671875, 71.582421875],
              [-179.40205078125, 71.566650390625],
              [-179.256494140625, 71.5716796875],
              [-179.111572265625, 71.59619140625],
              [-178.99404296875, 71.593212890625],
              [-178.87646484375, 71.57705078125],
            ],
          ],
          [
            [
              [35.81611328125001, 65.182080078125],
              [35.84843750000002, 65.14267578125],
              [35.8583984375, 65.0779296875],
              [35.82734375000001, 65.036474609375],
              [35.84228515625, 65.00146484375],
              [35.77871093750002, 64.97666015625],
              [35.68007812500002, 65.0576171875],
              [35.62138671875002, 65.0587890625],
              [35.55859375, 65.093603515625],
              [35.52890625, 65.15107421875],
              [35.58574218750002, 65.16708984375],
              [35.60869140625002, 65.15712890625],
              [35.729101562500006, 65.19755859375],
              [35.81611328125001, 65.182080078125],
            ],
          ],
          [
            [
              [42.71367187500002, 66.701708984375],
              [42.67558593750002, 66.6880859375],
              [42.47734375000002, 66.73505859375],
              [42.460058593750006, 66.770361328125],
              [42.46855468750002, 66.785546875],
              [42.547460937500006, 66.7955078125],
              [42.631445312500006, 66.7822265625],
              [42.69072265625002, 66.735302734375],
              [42.71367187500002, 66.701708984375],
            ],
          ],
          [
            [
              [52.9033203125, 71.364990234375],
              [52.994140625, 71.291259765625],
              [53.07402343750002, 71.237939453125],
              [53.14140625000002, 71.24189453125],
              [53.19257812500001, 71.215283203125],
              [53.205175781250006, 71.159716796875],
              [53.07148437500001, 71.0650390625],
              [53.04814453125002, 71.03095703125],
              [53.10576171875002, 70.999267578125],
              [53.12099609375002, 70.98203125],
              [53.02265625000001, 70.968701171875],
              [53.004492187500006, 71.01162109375],
              [52.94960937500002, 71.05361328125],
              [52.83535156250002, 71.08583984375],
              [52.78896484375002, 71.11494140625],
              [52.738378906250006, 71.1806640625],
              [52.54658203125001, 71.250439453125],
              [52.42548828125001, 71.2392578125],
              [52.28945312500002, 71.270361328125],
              [52.249609375, 71.284912109375],
              [52.23984375, 71.325048828125],
              [52.29658203125001, 71.3568359375],
              [52.51259765625002, 71.38505859375],
              [52.61738281250001, 71.383349609375],
              [52.72968750000001, 71.355126953125],
              [52.7203125, 71.389794921875],
              [52.73222656250002, 71.4037109375],
              [52.77675781250002, 71.3998046875],
              [52.9033203125, 71.364990234375],
            ],
          ],
          [
            [
              [168.0390625, 54.564990234374996],
              [168.08134765625005, 54.512744140624996],
              [167.67734375000003, 54.69765625],
              [167.48808593750005, 54.794970703124996],
              [167.44150390625003, 54.855859375],
              [167.51171875, 54.85693359375],
              [167.59248046875, 54.79775390625],
              [167.71064453125, 54.770166015625],
              [167.88261718750005, 54.690478515624996],
              [168.0390625, 54.564990234374996],
            ],
          ],
          [
            [
              [160.7189453125, 70.822705078125],
              [160.6513671875, 70.805859375],
              [160.50478515625002, 70.8197265625],
              [160.43691406250002, 70.851025390625],
              [160.4404296875, 70.92265625],
              [160.44853515625005, 70.934033203125],
              [160.56582031250002, 70.923779296875],
              [160.64492187500002, 70.883544921875],
              [160.7189453125, 70.822705078125],
            ],
          ],
          [
            [
              [161.46708984375005, 68.9009765625],
              [161.42246093750003, 68.899658203125],
              [161.45625, 68.966015625],
              [161.4611328125, 68.99560546875],
              [161.36406250000005, 69.04443359375],
              [161.18251953125002, 69.081591796875],
              [161.13652343750005, 69.11025390625],
              [161.12548828125, 69.197021484375],
              [161.16455078125, 69.33359375],
              [161.08281250000005, 69.4056640625],
              [161.11074218750002, 69.46982421875],
              [161.32333984375003, 69.54091796875],
              [161.40976562500003, 69.595703125],
              [161.50517578125005, 69.639453125],
              [161.520703125, 69.634033203125],
              [161.61777343750003, 69.592431640625],
              [161.60927734375002, 69.500927734375],
              [161.54033203125005, 69.4365234375],
              [161.37441406250002, 69.413671875],
              [161.35087890625005, 69.3693359375],
              [161.37265625000003, 69.292822265625],
              [161.37753906250003, 69.19443359375],
              [161.39423828125, 69.1064453125],
              [161.49472656250003, 69.016015625],
              [161.51699218750002, 68.969580078125],
              [161.50673828125002, 68.927587890625],
              [161.46708984375005, 68.9009765625],
            ],
          ],
          [
            [
              [152.8859375, 76.121728515625],
              [152.786328125, 76.085791015625],
              [152.55859375, 76.143603515625],
              [152.6427734375, 76.1748046875],
              [152.79941406250003, 76.19482421875],
              [152.83505859375003, 76.18515625],
              [152.86376953125, 76.163427734375],
              [152.8859375, 76.121728515625],
            ],
          ],
          [
            [
              [149.15019531250005, 76.659912109375],
              [148.3986328125, 76.6482421875],
              [148.44814453125002, 76.676953125],
              [148.71962890625002, 76.74658203125],
              [149.4064453125, 76.782080078125],
              [149.26835937500005, 76.747216796875],
              [149.20478515625, 76.677001953125],
              [149.15019531250005, 76.659912109375],
            ],
          ],
          [
            [
              [137.95986328125002, 71.507666015625],
              [137.71181640625002, 71.4232421875],
              [137.61289062500003, 71.433935546875],
              [137.51181640625003, 71.474609375],
              [137.45781250000005, 71.48349609375],
              [137.40322265625002, 71.477294921875],
              [137.34423828125, 71.460546875],
              [137.26552734375002, 71.455908203125],
              [137.07871093750003, 71.502197265625],
              [137.06406250000003, 71.5298828125],
              [137.08183593750005, 71.542724609375],
              [137.12949218750003, 71.55615234375],
              [137.1681640625, 71.55712890625],
              [137.28183593750003, 71.579931640625],
              [137.81679687500002, 71.587890625],
              [137.8576171875, 71.583056640625],
              [137.9337890625, 71.5427734375],
              [137.95986328125002, 71.507666015625],
            ],
          ],
          [
            [
              [107.69550781250001, 78.130908203125],
              [107.60625, 78.082568359375],
              [107.48164062500001, 78.057763671875],
              [107.34384765625003, 78.098583984375],
              [107.00166015625001, 78.095654296875],
              [106.41552734375, 78.13984375],
              [106.58330078124999, 78.167578125],
              [107.50830078125, 78.189404296875],
              [107.5732421875, 78.185546875],
              [107.69550781250001, 78.130908203125],
            ],
          ],
          [
            [
              [112.47802734375, 76.6208984375],
              [112.63251953125001, 76.552978515625],
              [112.66083984375001, 76.5095703125],
              [112.61416015625002, 76.499267578125],
              [112.58652343750003, 76.482958984375],
              [112.57480468750003, 76.452392578125],
              [112.53164062500002, 76.450048828125],
              [112.39482421874999, 76.4837890625],
              [112.296875, 76.53798828125],
              [112.15380859375, 76.54931640625],
              [112.00273437499999, 76.602978515625],
              [111.96894531250001, 76.626171875],
              [112.01113281250002, 76.632861328125],
              [112.28144531250001, 76.618359375],
              [112.39414062500003, 76.643798828125],
              [112.47802734375, 76.6208984375],
            ],
          ],
          [
            [
              [96.53242187500001, 76.278125],
              [96.61396484375001, 76.263818359375],
              [96.58964843749999, 76.221240234375],
              [96.48671875000002, 76.233740234375],
              [96.35078125000001, 76.212158203125],
              [96.35341796875002, 76.177490234375],
              [96.30058593749999, 76.121728515625],
              [96.10878906250002, 76.15546875],
              [95.84453124999999, 76.16025390625],
              [95.67861328125002, 76.19365234375],
              [95.31113281250003, 76.21474609375],
              [95.32207031249999, 76.26162109375],
              [95.3798828125, 76.2890625],
              [95.59443359375001, 76.249609375],
              [95.78623046875003, 76.293896484375],
              [96.15097656250003, 76.271875],
              [96.27070312500001, 76.30537109375],
              [96.53242187500001, 76.278125],
            ],
          ],
          [
            [
              [96.28544921874999, 77.02666015625],
              [96.25351562500003, 77.007275390625],
              [96.20986328125002, 76.992138671875],
              [96.09140625000003, 77.0025390625],
              [95.85468750000001, 76.974951171875],
              [95.76582031250001, 76.990625],
              [95.68085937500001, 77.021337890625],
              [95.36406249999999, 77.0115234375],
              [95.27031249999999, 77.01884765625],
              [95.42070312499999, 77.056494140625],
              [95.85410156250003, 77.09755859375],
              [96.52841796875003, 77.205517578125],
              [96.56191406250002, 77.154052734375],
              [96.56132812499999, 77.12958984375],
              [96.42431640625, 77.07119140625],
              [96.28544921874999, 77.02666015625],
            ],
          ],
          [
            [
              [89.51425781250003, 77.188818359375],
              [89.29951171875001, 77.183984375],
              [89.17929687500003, 77.209912109375],
              [89.14169921875003, 77.226806640625],
              [89.20048828124999, 77.27197265625],
              [89.28154296874999, 77.30146484375],
              [89.6162109375, 77.31103515625],
              [89.67958984375002, 77.280322265625],
              [89.66582031249999, 77.2544921875],
              [89.51425781250003, 77.188818359375],
            ],
          ],
          [
            [
              [74.66054687500002, 72.8734375],
              [74.63837890625001, 72.86376953125],
              [74.58808593750001, 72.88115234375],
              [74.43476562500001, 72.907666015625],
              [74.1806640625, 72.975341796875],
              [74.1001953125, 73.021533203125],
              [74.14238281250002, 73.074365234375],
              [74.19853515625002, 73.10908203125],
              [74.4087890625, 73.13046875],
              [74.59990234375002, 73.12177734375],
              [74.72529296875001, 73.108154296875],
              [74.9615234375, 73.0625],
              [74.74257812500002, 73.03271484375],
              [74.64726562500002, 72.96904296875],
              [74.66015625, 72.929296875],
              [74.69716796875002, 72.90771484375],
              [74.66054687500002, 72.8734375],
            ],
          ],
          [
            [
              [75.50371093750002, 73.456640625],
              [75.3443359375, 73.432275390625],
              [75.375, 73.477392578125],
              [75.56972656250002, 73.540625],
              [75.93017578125, 73.5736328125],
              [76.03945312500002, 73.559912109375],
              [76.05156249999999, 73.549267578125],
              [75.9009765625, 73.481494140625],
              [75.8271484375, 73.459130859375],
              [75.50371093750002, 73.456640625],
            ],
          ],
          [
            [
              [83.54902343750001, 74.07177734375],
              [83.49580078125001, 74.0484375],
              [83.45, 74.05166015625],
              [83.41064453125, 74.03955078125],
              [83.15898437499999, 74.075341796875],
              [82.81777343750002, 74.0916015625],
              [82.90292968750003, 74.12890625],
              [83.14980468750002, 74.151611328125],
              [83.51347656249999, 74.12236328125],
              [83.61835937500001, 74.089453125],
              [83.54902343750001, 74.07177734375],
            ],
          ],
          [
            [
              [67.34492187500001, 69.529833984375],
              [67.26396484375002, 69.442529296875],
              [67.09785156250001, 69.44716796875],
              [67.04726562500002, 69.467041015625],
              [67.02587890625, 69.483203125],
              [67.21611328125002, 69.575390625],
              [67.32890625000002, 69.572119140625],
              [67.34492187500001, 69.529833984375],
            ],
          ],
          [
            [
              [66.56093750000002, 70.541748046875],
              [66.56855468750001, 70.50146484375],
              [66.51582031250001, 70.514892578125],
              [66.44863281250002, 70.56103515625],
              [66.40761718750002, 70.615771484375],
              [66.39482421875002, 70.727294921875],
              [66.41816406250001, 70.75712890625],
              [66.44023437500002, 70.77265625],
              [66.462890625, 70.7693359375],
              [66.45771484375001, 70.698779296875],
              [66.56093750000002, 70.541748046875],
            ],
          ],
          [
            [
              [70.02070312500001, 66.502197265625],
              [69.8447265625, 66.48974609375],
              [69.6513671875, 66.56533203125],
              [69.4693359375, 66.715966796875],
              [69.50273437500002, 66.75107421875],
              [69.61640625000001, 66.739013671875],
              [69.800390625, 66.736474609375],
              [69.917578125, 66.711669921875],
              [70.07666015625, 66.6958984375],
              [70.0576171875, 66.627197265625],
              [70.0572265625, 66.599462890625],
              [70.11005859375001, 66.569091796875],
              [70.0591796875, 66.517578125],
              [70.02070312500001, 66.502197265625],
            ],
          ],
          [
            [
              [50.0517578125, 80.07431640625],
              [49.97089843750001, 80.0607421875],
              [49.58828125000002, 80.1361328125],
              [49.55605468750002, 80.158935546875],
              [49.88369140625002, 80.230224609375],
              [50.2509765625, 80.219482421875],
              [50.30996093750002, 80.18564453125],
              [50.31914062500002, 80.17236328125],
              [50.072265625, 80.10947265625],
              [50.0517578125, 80.07431640625],
            ],
          ],
          [
            [
              [51.409277343750006, 79.94423828125],
              [51.43515625, 79.93193359375],
              [51.43125, 79.9205078125],
              [51.076269531250006, 79.931982421875],
              [50.4541015625, 79.9244140625],
              [50.09140625, 79.98056640625],
              [50.47265625, 80.03544921875],
              [50.67578125, 80.04853515625],
              [50.93632812500002, 80.09423828125],
              [51.25439453125, 80.0486328125],
              [51.237890625, 80.0103515625],
              [51.242773437500006, 79.991259765625],
              [51.32695312500002, 79.972314453125],
              [51.409277343750006, 79.94423828125],
            ],
          ],
          [
            [
              [50.75371093750002, 81.047412109375],
              [50.61601562500002, 81.041259765625],
              [50.518164062500006, 81.045556640625],
              [50.41191406250002, 81.084375],
              [50.37744140625, 81.102734375],
              [50.36845703125002, 81.122509765625],
              [50.464941406250006, 81.126220703125],
              [50.505957031250006, 81.14423828125],
              [50.521582031250006, 81.158203125],
              [50.591796875, 81.16943359375],
              [50.715917968750006, 81.170654296875],
              [50.87861328125001, 81.15087890625],
              [50.94619140625002, 81.108154296875],
              [50.78876953125001, 81.071826171875],
              [50.75371093750002, 81.047412109375],
            ],
          ],
          [
            [
              [55.47968750000001, 80.273828125],
              [55.19511718750002, 80.226806640625],
              [55.0484375, 80.228369140625],
              [54.97968750000001, 80.2564453125],
              [55.09160156250002, 80.295556640625],
              [55.24003906250002, 80.325390625],
              [55.35322265625001, 80.31767578125],
              [55.43476562500001, 80.30224609375],
              [55.47968750000001, 80.273828125],
            ],
          ],
          [
            [
              [54.41533203125002, 80.472802734375],
              [54.27587890625, 80.421337890625],
              [53.81191406250002, 80.476220703125],
              [53.85, 80.503857421875],
              [53.90019531250002, 80.5154296875],
              [53.90156250000001, 80.54248046875],
              [53.85888671875, 80.563037109375],
              [53.87724609375002, 80.6052734375],
              [54.1767578125, 80.574365234375],
              [54.20537109375002, 80.561767578125],
              [54.40712890625002, 80.54013671875],
              [54.43730468750002, 80.498681640625],
              [54.41533203125002, 80.472802734375],
            ],
          ],
          [
            [
              [59.68886718750002, 79.955810546875],
              [59.330664062500006, 79.923046875],
              [59.20263671875, 79.932958984375],
              [59.16923828125002, 79.948291015625],
              [59.10039062500002, 79.96416015625],
              [58.91923828125002, 79.984619140625],
              [58.94609375000002, 80.042333984375],
              [59.00146484375, 80.05390625],
              [59.54453125, 80.11884765625],
              [59.80166015625002, 80.082666015625],
              [59.91103515625002, 79.994287109375],
              [59.68886718750002, 79.955810546875],
            ],
          ],
          [
            [
              [58.62236328125002, 81.041650390625],
              [58.76152343750002, 80.990966796875],
              [58.81533203125002, 80.93359375],
              [58.90253906250001, 80.89765625],
              [58.93056640625002, 80.831689453125],
              [58.859960937500006, 80.77939453125],
              [58.64189453125002, 80.76796875],
              [58.28564453125, 80.764892578125],
              [57.93789062500002, 80.793359375],
              [57.74980468750002, 80.8890625],
              [57.40517578125002, 80.91513671875],
              [57.2109375, 81.01708984375],
              [57.410253906250006, 81.04677734375],
              [57.65625, 81.03154296875],
              [58.04951171875001, 81.11845703125],
              [58.10234375000002, 81.1142578125],
              [58.18994140625, 81.094580078125],
              [58.50761718750002, 81.061767578125],
              [58.62236328125002, 81.041650390625],
            ],
          ],
          [
            [
              [47.983007812500006, 45.488232421875],
              [47.96767578125002, 45.469970703125],
              [47.92031250000002, 45.562060546874996],
              [47.917578125, 45.6181640625],
              [47.94716796875002, 45.6470703125],
              [47.98710937500002, 45.554052734375],
              [47.983007812500006, 45.488232421875],
            ],
          ],
          [
            [
              [50.265234375, 69.185595703125],
              [50.28300781250002, 69.0888671875],
              [50.22060546875002, 69.048779296875],
              [50.16445312500002, 69.037548828125],
              [50.14091796875002, 69.09814453125],
              [50.09394531250001, 69.125537109375],
              [49.92080078125002, 69.053271484375],
              [49.83984375, 68.973779296875],
              [49.62626953125002, 68.859716796875],
              [49.18046875000002, 68.77841796875],
              [48.91035156250001, 68.74306640625],
              [48.6669921875, 68.733154296875],
              [48.4390625, 68.8048828125],
              [48.31591796875, 68.9423828125],
              [48.29443359375, 68.984228515625],
              [48.27880859375, 69.04033203125],
              [48.2802734375, 69.096630859375],
              [48.29628906250002, 69.18388671875],
              [48.319921875, 69.26923828125],
              [48.41386718750002, 69.345654296875],
              [48.63134765625, 69.43603515625],
              [48.84492187500001, 69.4947265625],
              [48.95332031250001, 69.50927734375],
              [49.225195312500006, 69.51123046875],
              [49.99628906250001, 69.309423828125],
              [50.16728515625002, 69.257080078125],
              [50.265234375, 69.185595703125],
            ],
          ],
          [
            [
              [63.37382812500002, 80.70009765625],
              [63.187597656250006, 80.697607421875],
              [63.00214843750001, 80.712841796875],
              [62.76044921875001, 80.7626953125],
              [62.52031250000002, 80.821875],
              [62.59257812500002, 80.85302734375],
              [62.8193359375, 80.893798828125],
              [63.115820312500006, 80.966796875],
              [63.61474609375, 80.980908203125],
              [63.85595703125, 80.98115234375],
              [64.095703125, 80.99833984375],
              [64.16591796875002, 81.0357421875],
              [64.21044921875, 81.10634765625],
              [64.255859375, 81.14443359375],
              [64.31015625, 81.1751953125],
              [64.57539062500001, 81.198486328125],
              [64.80205078125002, 81.197265625],
              [65.02773437500002, 81.169482421875],
              [65.17197265625, 81.14404296875],
              [65.30976562500001, 81.096435546875],
              [65.38203125000001, 81.05673828125],
              [65.36005859375001, 81.008203125],
              [65.3720703125, 80.968017578125],
              [65.43740234375002, 80.930712890625],
              [64.99746093750002, 80.818896484375],
              [64.54833984375, 80.755419921875],
              [63.37382812500002, 80.70009765625],
            ],
          ],
          [
            [
              [57.95625, 80.1232421875],
              [57.80009765625002, 80.104052734375],
              [57.39228515625001, 80.13916015625],
              [57.33232421875002, 80.15810546875],
              [57.28144531250001, 80.193896484375],
              [57.21406250000001, 80.328271484375],
              [57.21171875000002, 80.36845703125],
              [57.18623046875001, 80.396240234375],
              [57.08339843750002, 80.44521484375],
              [57.01113281250002, 80.468310546875],
              [57.075, 80.4939453125],
              [57.52197265625, 80.475390625],
              [58.48046875, 80.46474609375],
              [58.9716796875, 80.415869140625],
              [59.11591796875001, 80.388427734375],
              [59.25546875, 80.343212890625],
              [58.39794921875, 80.31875],
              [58.28388671875001, 80.297802734375],
              [58.285742187500006, 80.24814453125],
              [58.25546875, 80.201806640625],
              [58.163183593750006, 80.196533203125],
              [57.95625, 80.1232421875],
            ],
          ],
          [
            [
              [62.16777343750002, 80.834765625],
              [62.22773437500001, 80.794384765625],
              [62.19179687500002, 80.730224609375],
              [62.11455078125002, 80.68369140625],
              [62.07578125, 80.616943359375],
              [61.769140625, 80.601025390625],
              [61.68125, 80.586328125],
              [61.59746093750002, 80.5349609375],
              [61.28515625, 80.504736328125],
              [61.05126953125, 80.418603515625],
              [60.722265625, 80.43466796875],
              [60.2783203125, 80.49443359375],
              [59.90019531250002, 80.44609375],
              [59.64980468750002, 80.43125],
              [59.34638671875001, 80.505029296875],
              [59.30439453125001, 80.521533203125],
              [59.288183593750006, 80.57265625],
              [59.30625, 80.6177734375],
              [59.38652343750002, 80.712548828125],
              [59.4951171875, 80.76650390625],
              [59.549414062500006, 80.78359375],
              [59.59228515625, 80.81650390625],
              [59.7158203125, 80.836376953125],
              [60.09453125000002, 80.848583984375],
              [60.234960937500006, 80.837744140625],
              [60.27802734375001, 80.80146484375],
              [60.481542968750006, 80.804248046875],
              [60.82021484375002, 80.8265625],
              [61.31318359375001, 80.862646484375],
              [61.59746093750002, 80.892919921875],
              [61.8505859375, 80.8859375],
              [62.10292968750002, 80.8666015625],
              [62.16777343750002, 80.834765625],
            ],
          ],
          [
            [
              [61.14082031250001, 80.950341796875],
              [60.826757812500006, 80.9296875],
              [60.32109375000002, 80.955517578125],
              [60.058203125, 80.984619140625],
              [60.07832031250001, 80.999169921875],
              [60.147558593750006, 81.016650390625],
              [60.58662109375001, 81.0876953125],
              [61.45742187500002, 81.103955078125],
              [61.5673828125, 81.05029296875],
              [61.47197265625002, 81.01103515625],
              [61.14082031250001, 80.950341796875],
            ],
          ],
          [
            [
              [53.52138671875002, 80.185205078125],
              [52.85634765625002, 80.1732421875],
              [52.63593750000001, 80.178857421875],
              [52.60703125, 80.191162109375],
              [52.55048828125001, 80.20185546875],
              [52.34355468750002, 80.213232421875],
              [52.21337890625, 80.263720703125],
              [52.27021484375001, 80.276318359375],
              [52.57666015625, 80.296923828125],
              [52.68056640625002, 80.318505859375],
              [52.71601562500001, 80.34755859375],
              [52.85390625000002, 80.402392578125],
              [53.185644531250006, 80.412646484375],
              [53.329199218750006, 80.402392578125],
              [53.34589843750001, 80.36630859375],
              [53.48613281250002, 80.323388671875],
              [53.851660156250006, 80.268359375],
              [53.777929687500006, 80.2283203125],
              [53.652929687500006, 80.22255859375],
              [53.52138671875002, 80.185205078125],
            ],
          ],
          [
            [
              [57.078710937500006, 80.350927734375],
              [57.12265625, 80.3169921875],
              [57.11894531250002, 80.1939453125],
              [57.07275390625, 80.139404296875],
              [57.080175781250006, 80.094677734375],
              [56.986914062500006, 80.071484375],
              [56.20058593750002, 80.07646484375],
              [55.811621093750006, 80.087158203125],
              [55.72402343750002, 80.104736328125],
              [55.94228515625002, 80.16328125],
              [56.01220703125, 80.20390625],
              [55.98984375, 80.320068359375],
              [56.0244140625, 80.34130859375],
              [56.65507812500002, 80.330322265625],
              [56.70722656250001, 80.36328125],
              [56.94453125000001, 80.366162109375],
              [57.078710937500006, 80.350927734375],
            ],
          ],
          [
            [
              [57.81025390625001, 81.546044921875],
              [57.86269531250002, 81.5064453125],
              [58.0166015625, 81.4837890625],
              [58.43603515625, 81.46416015625],
              [58.56386718750002, 81.418408203125],
              [58.371875, 81.386962890625],
              [57.85869140625002, 81.36806640625],
              [57.91191406250002, 81.303271484375],
              [58.01533203125001, 81.254833984375],
              [57.91289062500002, 81.197509765625],
              [57.76972656250001, 81.1697265625],
              [57.45097656250002, 81.135546875],
              [57.15947265625002, 81.178466796875],
              [56.821875, 81.237939453125],
              [56.66923828125002, 81.198291015625],
              [56.5125, 81.175244140625],
              [56.36396484375001, 81.17861328125],
              [56.191992187500006, 81.223974609375],
              [55.71669921875002, 81.1884765625],
              [55.57265625000002, 81.228076171875],
              [55.46601562500001, 81.311181640625],
              [55.78193359375001, 81.329443359375],
              [56.156835937500006, 81.303076171875],
              [56.40468750000002, 81.38701171875],
              [56.71875, 81.423388671875],
              [56.97304687500002, 81.510546875],
              [57.09150390625001, 81.5412109375],
              [57.36503906250002, 81.53525390625],
              [57.45644531250002, 81.54287109375],
              [57.71660156250002, 81.5646484375],
              [57.81025390625001, 81.546044921875],
            ],
          ],
          [
            [
              [54.71894531250001, 81.115966796875],
              [55.470703125, 81.019873046875],
              [56.17011718750001, 81.029150390625],
              [56.472265625, 80.9982421875],
              [56.90966796875, 80.912890625],
              [57.56777343750002, 80.8197265625],
              [57.69414062500002, 80.79228515625],
              [57.58037109375002, 80.75546875],
              [56.81474609375002, 80.663623046875],
              [56.315527343750006, 80.632861328125],
              [55.883398437500006, 80.62841796875],
              [55.7125, 80.6373046875],
              [55.540625, 80.7033203125],
              [55.1171875, 80.751904296875],
              [54.66816406250001, 80.738671875],
              [54.62333984375002, 80.765234375],
              [54.5328125, 80.7830078125],
              [54.376074218750006, 80.786962890625],
              [54.06660156250001, 80.813623046875],
              [54.04541015625, 80.87197265625],
              [54.24052734375002, 80.90185546875],
              [54.367285156250006, 80.90380859375],
              [54.41679687500002, 80.9865234375],
              [54.63398437500001, 81.11318359375],
              [54.71894531250001, 81.115966796875],
            ],
          ],
          [
            [
              [63.650976562500006, 81.609326171875],
              [63.52851562500001, 81.59658203125],
              [62.88496093750001, 81.60888671875],
              [62.57304687500002, 81.633056640625],
              [62.53125, 81.647021484375],
              [62.515234375, 81.659130859375],
              [62.1064453125, 81.679345703125],
              [62.28398437500002, 81.70654296875],
              [62.794921875, 81.7189453125],
              [63.709570312500006, 81.6873046875],
              [63.76738281250002, 81.66416015625],
              [63.78242187500001, 81.6498046875],
              [63.650976562500006, 81.609326171875],
            ],
          ],
          [
            [
              [58.29541015625, 81.715185546875],
              [57.96484375, 81.695654296875],
              [57.92060546875001, 81.710498046875],
              [57.909277343750006, 81.721923828125],
              [57.94511718750002, 81.7478515625],
              [57.984960937500006, 81.797021484375],
              [58.13457031250002, 81.827978515625],
              [59.261816406250006, 81.85419921875],
              [59.40849609375002, 81.825439453125],
              [59.35683593750002, 81.78095703125],
              [59.3564453125, 81.758984375],
              [58.29541015625, 81.715185546875],
            ],
          ],
          [
            [
              [92.68349609375002, 79.685205078125],
              [92.440625, 79.67548828125],
              [92.15371093750002, 79.68466796875],
              [91.68359375, 79.790576171875],
              [91.37626953124999, 79.835498046875],
              [91.12607421875003, 79.904931640625],
              [91.0703125, 79.981494140625],
              [91.22929687499999, 80.030712890625],
              [91.42597656250001, 80.04921875],
              [91.751953125, 80.052294921875],
              [92.17343750000003, 80.045458984375],
              [92.5927734375, 79.996533203125],
              [93.48154296875003, 79.94111328125],
              [93.803125, 79.904541015625],
              [93.603515625, 79.816748046875],
              [93.38203125000001, 79.78388671875],
              [93.15507812499999, 79.73759765625],
              [92.92626953125, 79.7044921875],
              [92.68349609375002, 79.685205078125],
            ],
          ],
          [
            [
              [91.56718749999999, 81.1412109375],
              [91.22285156250001, 81.063818359375],
              [89.97578125000001, 81.113134765625],
              [89.91943359375, 81.14873046875],
              [89.90117187499999, 81.170703125],
              [90.06992187500003, 81.213720703125],
              [91.10898437500003, 81.19912109375],
              [91.47783203124999, 81.183935546875],
              [91.56718749999999, 81.1412109375],
            ],
          ],
          [
            [
              [141.01025390625, 73.999462890625],
              [140.50722656250002, 73.91865234375],
              [140.40947265625005, 73.9216796875],
              [140.18320312500003, 74.00458984375],
              [140.1015625, 74.18427734375],
              [140.1935546875, 74.23671875],
              [140.30029296875, 74.2572265625],
              [140.407421875, 74.266455078125],
              [140.84921875000003, 74.273779296875],
              [140.9443359375, 74.2646484375],
              [141.03857421875, 74.242724609375],
              [141.07949218750002, 74.209326171875],
              [141.09746093750005, 74.167822265625],
              [141.046875, 74.050390625],
              [141.01025390625, 73.999462890625],
            ],
          ],
          [
            [
              [142.18486328125005, 73.8958984375],
              [142.43505859375, 73.8515625],
              [142.63916015625, 73.803076171875],
              [143.34375, 73.56875],
              [143.41074218750003, 73.520849609375],
              [143.46396484375003, 73.45888671875],
              [143.49130859375003, 73.246435546875],
              [143.45146484375005, 73.231298828125],
              [143.19326171875002, 73.220751953125],
              [142.84160156250005, 73.24482421875],
              [142.5869140625, 73.2533203125],
              [142.34218750000002, 73.252880859375],
              [142.12636718750002, 73.281689453125],
              [141.5966796875, 73.31083984375],
              [141.18271484375003, 73.389208984375],
              [140.75400390625003, 73.446044921875],
              [140.66279296875, 73.452001953125],
              [140.39248046875002, 73.4353515625],
              [140.02695312500003, 73.36142578125],
              [139.92509765625005, 73.355224609375],
              [139.78554687500002, 73.355224609375],
              [139.685546875, 73.425732421875],
              [139.9201171875, 73.448583984375],
              [140.15517578125002, 73.45751953125],
              [140.38066406250005, 73.4830078125],
              [140.59355468750005, 73.56455078125],
              [140.6974609375, 73.629150390625],
              [140.8837890625, 73.7775390625],
              [140.98359375, 73.83154296875],
              [141.08476562500005, 73.865869140625],
              [141.18994140625, 73.87646484375],
              [141.31191406250002, 73.871875],
              [141.68193359375005, 73.90419921875],
              [141.9318359375, 73.91494140625],
              [142.18486328125005, 73.8958984375],
            ],
          ],
          [
            [
              [135.94863281250002, 75.4095703125],
              [135.74589843750005, 75.381982421875],
              [135.45195312500005, 75.38955078125],
              [135.47304687500002, 75.463232421875],
              [135.5234375, 75.495849609375],
              [135.59267578125002, 75.57646484375],
              [135.56123046875, 75.636474609375],
              [135.57841796875005, 75.7099609375],
              [135.61386718750003, 75.76630859375],
              [135.69863281250002, 75.845263671875],
              [135.78828125, 75.798486328125],
              [135.84921875000003, 75.729248046875],
              [135.90478515625, 75.694384765625],
              [136.12734375000002, 75.6255859375],
              [136.1689453125, 75.60556640625],
              [135.9833984375, 75.521923828125],
              [135.96513671875005, 75.4861328125],
              [136.0205078125, 75.43837890625],
              [135.94863281250002, 75.4095703125],
            ],
          ],
          [
            [
              [136.1974609375, 73.913623046875],
              [136.12167968750003, 73.885009765625],
              [136.05146484375, 73.9291015625],
              [135.71455078125, 74.059521484375],
              [135.63339843750003, 74.121435546875],
              [135.44863281250002, 74.1796875],
              [135.40244140625003, 74.201708984375],
              [135.38701171875005, 74.253369140625],
              [135.62832031250002, 74.219921875],
              [136.03681640625, 74.09033203125],
              [136.25917968750002, 73.9849609375],
              [136.1974609375, 73.913623046875],
            ],
          ],
          [
            [
              [137.94052734375003, 55.092626953125],
              [138.03125, 55.0533203125],
              [138.1720703125, 55.06005859375],
              [138.20615234375003, 55.033544921875],
              [138.09648437500005, 54.99091796875],
              [138.0166015625, 54.90087890625],
              [137.9912109375, 54.820703125],
              [137.95947265625, 54.789013671875],
              [137.8701171875, 54.749560546874996],
              [137.790234375, 54.696923828125],
              [137.72148437500005, 54.663232421875],
              [137.6611328125, 54.653271484375],
              [137.5255859375, 54.825830078125],
              [137.46269531250005, 54.873388671875],
              [137.27607421875, 54.7923828125],
              [137.23291015625, 54.790576171874996],
              [137.27519531250005, 54.891015625],
              [137.384375, 55.00068359375],
              [137.435546875, 55.016015625],
              [137.54365234375, 55.1630859375],
              [137.57734375, 55.197021484375],
              [137.91044921875005, 55.11005859375],
              [137.94052734375003, 55.092626953125],
            ],
          ],
          [
            [
              [169.20078125000003, 69.58046875],
              [168.91572265625, 69.571435546875],
              [168.34804687500002, 69.66435546875],
              [168.14433593750005, 69.713330078125],
              [167.99267578125, 69.775830078125],
              [167.8212890625, 69.81962890625],
              [167.78886718750005, 69.836865234375],
              [167.81396484375, 69.873046875],
              [167.86474609375, 69.90107421875],
              [168.0595703125, 69.97490234375],
              [168.1962890625, 70.0083984375],
              [168.35791015625, 70.015673828125],
              [169.37480468750005, 69.8826171875],
              [169.42070312500005, 69.8560546875],
              [169.43359375, 69.832177734375],
              [169.4181640625, 69.77919921875],
              [169.33242187500002, 69.769580078125],
              [169.29912109375005, 69.734765625],
              [169.26337890625, 69.6287109375],
              [169.24580078125, 69.601123046875],
              [169.20078125000003, 69.58046875],
            ],
          ],
          [
            [
              [163.63515625000002, 58.603369140625],
              [163.47138671875, 58.509375],
              [163.447265625, 58.524658203125],
              [163.4318359375, 58.546142578125],
              [163.42724609375, 58.578955078125],
              [163.57675781250003, 58.640869140625],
              [163.7265625, 58.79853515625],
              [163.78447265625005, 58.929736328125],
              [163.7666015625, 58.97236328125],
              [163.7609375, 59.015039062499994],
              [164.2021484375, 59.09619140625],
              [164.51738281250005, 59.2267578125],
              [164.57265625000002, 59.221142578125],
              [164.62929687500002, 59.112207031249994],
              [164.66162109375, 58.970751953125],
              [164.61572265625, 58.885595703125],
              [164.27880859375, 58.8380859375],
              [163.96005859375003, 58.74375],
              [163.63515625000002, 58.603369140625],
            ],
          ],
          [
            [
              [166.65029296875002, 54.8390625],
              [166.64511718750003, 54.694091796875],
              [166.52128906250005, 54.767626953124996],
              [166.46367187500005, 54.82685546875],
              [166.38173828125002, 54.8380859375],
              [166.32480468750003, 54.864550781249996],
              [166.22988281250002, 54.9365234375],
              [166.11972656250003, 55.03037109375],
              [166.08232421875005, 55.0765625],
              [166.06630859375002, 55.135693359375],
              [165.99189453125, 55.190478515624996],
              [165.75107421875003, 55.29453125],
              [165.83046875000002, 55.306933593749996],
              [165.93125, 55.35146484375],
              [166.2119140625, 55.323974609375],
              [166.27578125000002, 55.311962890625],
              [166.22998046875, 55.242333984375],
              [166.248046875, 55.1654296875],
              [166.404296875, 55.005615234375],
              [166.4794921875, 54.94990234375],
              [166.57734375, 54.90771484375],
              [166.65029296875002, 54.8390625],
            ],
          ],
          [
            [
              [154.81044921875002, 49.31201171875],
              [154.71484375, 49.26767578125],
              [154.61093750000003, 49.29404296875],
              [154.61298828125, 49.380615234375],
              [154.82490234375, 49.646923828125],
              [154.899609375, 49.63037109375],
              [154.88330078125, 49.56640625],
              [154.80234375000003, 49.46826171875],
              [154.82988281250005, 49.347900390625],
              [154.81044921875002, 49.31201171875],
            ],
          ],
          [
            [
              [156.40507812500005, 50.657617187499994],
              [156.3654296875, 50.6337890625],
              [156.32578125000003, 50.6390625],
              [156.1962890625, 50.7021484375],
              [156.16796875, 50.731884765625],
              [156.2130859375, 50.784716796874996],
              [156.37646484375, 50.862109375],
              [156.45585937500005, 50.8595703125],
              [156.4875, 50.84296875],
              [156.48310546875, 50.751220703125],
              [156.40507812500005, 50.657617187499994],
            ],
          ],
          [
            [
              [155.92109375, 50.302197265625],
              [155.79238281250002, 50.202050781249994],
              [155.60751953125003, 50.17724609375],
              [155.51640625000005, 50.14560546875],
              [155.44892578125, 50.077783203124994],
              [155.39716796875, 50.041259765625],
              [155.28867187500003, 50.061181640624994],
              [155.24306640625002, 50.094628906249994],
              [155.24306640625002, 50.212792968749994],
              [155.19511718750005, 50.264550781249994],
              [155.21835937500003, 50.2978515625],
              [155.32675781250003, 50.293261718749996],
              [155.43388671875005, 50.368945312499996],
              [155.68017578125, 50.400732421875],
              [155.77275390625005, 50.482421875],
              [155.884765625, 50.684130859374996],
              [156.00166015625, 50.75693359375],
              [156.096875, 50.771875],
              [156.12285156250005, 50.671289062499994],
              [156.1005859375, 50.55927734375],
              [156.04443359375, 50.4517578125],
              [155.92109375, 50.302197265625],
            ],
          ],
          [
            [
              [154.08125, 48.790283203125],
              [154.04296875, 48.73876953125],
              [154.00068359375, 48.755712890625],
              [153.99228515625003, 48.772509765624996],
              [154.09169921875002, 48.83212890625],
              [154.12636718750002, 48.904443359374994],
              [154.19902343750005, 48.904931640624994],
              [154.22841796875002, 48.89208984375],
              [154.20468750000003, 48.857177734375],
              [154.08125, 48.790283203125],
            ],
          ],
          [
            [
              [155.64482421875005, 50.821923828124994],
              [155.55351562500005, 50.810595703124996],
              [155.51279296875003, 50.837304687499994],
              [155.48349609375003, 50.86962890625],
              [155.46738281250003, 50.91357421875],
              [155.5685546875, 50.934472656249994],
              [155.6396484375, 50.910498046875],
              [155.65361328125005, 50.845361328124994],
              [155.64482421875005, 50.821923828124994],
            ],
          ],
          [
            [
              [153.10107421875, 47.762939453125],
              [153.05380859375003, 47.706103515624996],
              [153.0041015625, 47.7134765625],
              [152.98427734375002, 47.727929687499994],
              [153.04912109375005, 47.797021484374994],
              [153.07919921875003, 47.808740234374994],
              [153.10107421875, 47.762939453125],
            ],
          ],
          [
            [
              [152.00205078125003, 46.89716796875],
              [151.815625, 46.787109375],
              [151.7541015625, 46.788330078125],
              [151.72343750000005, 46.82880859375],
              [151.71533203125, 46.852685546874994],
              [151.86435546875003, 46.868994140625],
              [152.03984375000005, 47.014990234375],
              [152.16582031250005, 47.11044921875],
              [152.23466796875005, 47.143408203125],
              [152.28886718750005, 47.1421875],
              [152.00205078125003, 46.89716796875],
            ],
          ],
          [
            [
              [149.6876953125, 45.642041015625],
              [149.53886718750005, 45.591357421874996],
              [149.44707031250005, 45.593359375],
              [149.66591796875002, 45.839794921875],
              [149.79628906250002, 45.87607421875],
              [149.96230468750002, 46.021923828125],
              [150.3087890625, 46.200341796874994],
              [150.3486328125, 46.213427734374996],
              [150.553125, 46.208544921874996],
              [150.2345703125, 46.0123046875],
              [150.19501953125, 45.933203125],
              [150.056640625, 45.849365234375],
              [149.9541015625, 45.8224609375],
              [149.88339843750003, 45.783154296875],
              [149.6876953125, 45.642041015625],
            ],
          ],
          [
            [
              [148.59951171875002, 45.317626953125],
              [148.41464843750003, 45.24716796875],
              [148.26230468750003, 45.216845703124996],
              [148.00527343750002, 45.070166015625],
              [147.91376953125, 44.990380859374994],
              [147.78408203125002, 44.95859375],
              [147.65781250000003, 44.9771484375],
              [147.621875, 44.944726562499994],
              [147.6095703125, 44.886572265625],
              [147.56308593750003, 44.835546875],
              [147.31015625000003, 44.677636718749994],
              [147.20742187500002, 44.553564453125],
              [147.09843750000005, 44.53125],
              [146.8974609375, 44.404296875],
              [146.93349609375002, 44.513085937499994],
              [146.97421875000003, 44.565722656249996],
              [147.14091796875005, 44.663330078125],
              [147.15478515625, 44.7662109375],
              [147.24658203125, 44.8560546875],
              [147.43046875000005, 44.945214843749994],
              [147.5578125, 45.062451171875],
              [147.65791015625, 45.093017578125],
              [147.76943359375002, 45.190722656249996],
              [147.88554687500005, 45.225634765624996],
              [147.87265625000003, 45.30029296875],
              [147.9240234375, 45.38330078125],
              [147.96455078125, 45.377734375],
              [148.05605468750002, 45.262109375],
              [148.130078125, 45.258203125],
              [148.32421875, 45.282421875],
              [148.6123046875, 45.484667968749996],
              [148.70664062500003, 45.520654296874994],
              [148.77265625, 45.52646484375],
              [148.81220703125, 45.510009765625],
              [148.826171875, 45.486083984375],
              [148.825390625, 45.455908203125],
              [148.80302734375005, 45.413525390625],
              [148.837109375, 45.362695312499994],
              [148.79072265625, 45.323974609375],
              [148.59951171875002, 45.317626953125],
            ],
          ],
          [
            [
              [146.71396484375003, 43.743798828124994],
              [146.68300781250002, 43.716357421874996],
              [146.60859375, 43.740478515625],
              [146.6134765625, 43.797021484374994],
              [146.62197265625002, 43.81298828125],
              [146.82460937500002, 43.860498046874994],
              [146.88408203125005, 43.829150390624996],
              [146.89902343750003, 43.804150390625],
              [146.71396484375003, 43.743798828124994],
            ],
          ],
          [
            [
              [146.20761718750003, 44.49765625],
              [146.35595703125, 44.424609375],
              [146.56777343750002, 44.4404296875],
              [146.51621093750003, 44.374658203124994],
              [146.4365234375, 44.37568359375],
              [146.29619140625005, 44.28095703125],
              [146.17294921875003, 44.26865234375],
              [146.1123046875, 44.245947265625],
              [145.9140625, 44.1037109375],
              [145.88730468750003, 44.047753906249994],
              [145.76699218750002, 43.940722656249996],
              [145.58681640625002, 43.845117187499994],
              [145.555859375, 43.664599609374996],
              [145.43925781250005, 43.737060546875],
              [145.42617187500002, 43.810351562499996],
              [145.46171875000005, 43.870898437499996],
              [145.66630859375005, 43.999072265624996],
              [145.74833984375005, 44.071533203125],
              [145.77333984375002, 44.12900390625],
              [145.85195312500002, 44.193017578124994],
              [145.89023437500003, 44.248583984374996],
              [145.9404296875, 44.27265625],
              [146.11210937500005, 44.500146484374994],
              [146.20761718750003, 44.49765625],
            ],
          ],
          [
            [
              [113.38720703125, 74.400439453125],
              [113.353125, 74.352978515625],
              [113.29921875000002, 74.317138671875],
              [113.25888671875003, 74.272705078125],
              [113.19023437499999, 74.239306640625],
              [112.97763671875003, 74.196826171875],
              [112.81132812499999, 74.1029296875],
              [112.78242187500001, 74.095068359375],
              [112.19580078125, 74.146240234375],
              [112.10507812500003, 74.163232421875],
              [111.912109375, 74.21923828125],
              [111.64296875000002, 74.27294921875],
              [111.50341796875, 74.353076171875],
              [111.57011718749999, 74.368310546875],
              [111.6375, 74.37431640625],
              [111.87978515625002, 74.363818359375],
              [111.94921875, 74.38876953125],
              [111.98281250000002, 74.456298828125],
              [111.98935546875003, 74.496240234375],
              [112.00761718749999, 74.5267578125],
              [112.08447265625, 74.548974609375],
              [112.95175781250003, 74.47958984375],
              [113.28623046875003, 74.441015625],
              [113.38720703125, 74.400439453125],
            ],
          ],
          [
            [
              [76.24892578125002, 79.65107421875],
              [76.37255859375, 79.615234375],
              [76.46738281250003, 79.6431640625],
              [77.36015624999999, 79.5568359375],
              [77.54931640625, 79.5244140625],
              [77.58896484375003, 79.501904296875],
              [76.81015625000003, 79.489501953125],
              [76.64951171875003, 79.493408203125],
              [76.63652343749999, 79.54443359375],
              [76.45761718750003, 79.545458984375],
              [76.15371093750002, 79.578759765625],
              [76.071875, 79.625634765625],
              [76.05156249999999, 79.6447265625],
              [76.1484375, 79.664453125],
              [76.24892578125002, 79.65107421875],
            ],
          ],
          [
            [
              [80.02666015624999, 80.84814453125],
              [79.09853515625002, 80.812060546875],
              [79.0068359375, 80.834814453125],
              [78.97763671875003, 80.8482421875],
              [79.10986328125, 80.923583984375],
              [79.21738281250003, 80.9603515625],
              [79.806640625, 80.975390625],
              [80.27958984374999, 80.9498046875],
              [80.42792968750001, 80.927685546875],
              [80.37333984374999, 80.8826171875],
              [80.34482421875003, 80.867919921875],
              [80.02666015624999, 80.84814453125],
            ],
          ],
          [
            [
              [70.67392578125, 73.09501953125],
              [70.38037109375, 73.048095703125],
              [70.29833984375, 73.044482421875],
              [70.11865234375, 73.056298828125],
              [70.04072265625001, 73.037158203125],
              [69.92011718750001, 73.084521484375],
              [69.93037109375001, 73.126611328125],
              [69.98564453125002, 73.16923828125],
              [70.01875, 73.22431640625],
              [69.99589843750002, 73.359375],
              [70.14960937500001, 73.4447265625],
              [70.35, 73.47763671875],
              [70.94023437500002, 73.514404296875],
              [71.02324218750002, 73.50419921875],
              [71.1412109375, 73.477978515625],
              [71.23164062500001, 73.44775390625],
              [71.351171875, 73.372216796875],
              [71.444921875, 73.34208984375],
              [71.58955078125001, 73.283154296875],
              [71.63046875, 73.2248046875],
              [71.62617187500001, 73.173974609375],
              [71.35566406250001, 73.162451171875],
              [70.88671875, 73.11962890625],
              [70.67392578125, 73.09501953125],
            ],
          ],
          [
            [
              [77.63251953125001, 72.291259765625],
              [77.14560546875003, 72.281884765625],
              [76.90595703125001, 72.29765625],
              [76.87109375, 72.317041015625],
              [76.903125, 72.365576171875],
              [77.14951171875003, 72.439208984375],
              [77.26044921875001, 72.4861328125],
              [77.37783203125002, 72.565283203125],
              [77.57871093750003, 72.630859375],
              [77.74853515625, 72.631201171875],
              [78.27910156249999, 72.55322265625],
              [78.35292968750002, 72.504296875],
              [78.36513671875002, 72.482421875],
              [78.15449218750001, 72.4169921875],
              [78.00722656250002, 72.39248046875],
              [77.78085937500003, 72.308544921875],
              [77.63251953125001, 72.291259765625],
            ],
          ],
          [
            [
              [79.50146484375, 72.721923828125],
              [79.4306640625, 72.710693359375],
              [78.88056640625001, 72.751611328125],
              [78.69023437499999, 72.80341796875],
              [78.63320312500002, 72.850732421875],
              [78.65683593750003, 72.89228515625],
              [79.16425781250001, 73.0943359375],
              [79.35654296875003, 73.038623046875],
              [79.4125, 72.98310546875],
              [79.54130859374999, 72.91865234375],
              [79.53789062499999, 72.7693359375],
              [79.50146484375, 72.721923828125],
            ],
          ],
          [
            [
              [82.17236328125, 75.419384765625],
              [82.20878906249999, 75.386962890625],
              [82.22158203125002, 75.350537109375],
              [82.17929687500003, 75.33896484375],
              [82.05009765624999, 75.340966796875],
              [81.978515625, 75.247119140625],
              [81.90507812499999, 75.26279296875],
              [81.86054687500001, 75.31650390625],
              [81.69765625000002, 75.280517578125],
              [81.65478515625, 75.288916015625],
              [81.57929687500001, 75.33095703125],
              [81.53212890625002, 75.33955078125],
              [81.50058593750003, 75.367919921875],
              [81.71210937500001, 75.451416015625],
              [81.84218750000002, 75.40703125],
              [81.92656249999999, 75.4099609375],
              [81.90976562500003, 75.460009765625],
              [81.91279296875001, 75.497705078125],
              [82.021875, 75.5134765625],
              [82.165625, 75.515625],
              [82.17236328125, 75.419384765625],
            ],
          ],
          [
            [
              [60.45048828125002, 69.93486328125],
              [60.48066406250001, 69.885498046875],
              [60.47724609375001, 69.793701171875],
              [60.44023437500002, 69.725927734375],
              [60.3271484375, 69.715283203125],
              [60.215917968750006, 69.6876953125],
              [60.02617187500002, 69.717041015625],
              [59.91953125, 69.69697265625],
              [59.81279296875002, 69.695654296875],
              [59.724609375, 69.706201171875],
              [59.63701171875002, 69.721044921875],
              [59.578222656250006, 69.738623046875],
              [59.58125, 69.790869140625],
              [59.50263671875001, 69.8662109375],
              [59.38154296875001, 69.8904296875],
              [59.26835937500002, 69.8984375],
              [59.14423828125001, 69.921923828125],
              [59.08251953125, 69.910791015625],
              [59.004003906250006, 69.88330078125],
              [58.952734375, 69.8927734375],
              [58.68007812500002, 70.051025390625],
              [58.63417968750002, 70.088037109375],
              [58.605566406250006, 70.12919921875],
              [58.56806640625001, 70.1556640625],
              [58.47304687500002, 70.266845703125],
              [58.51992187500002, 70.318310546875],
              [58.615332031250006, 70.350830078125],
              [58.67802734375002, 70.3595703125],
              [58.79423828125002, 70.432958984375],
              [59.00527343750002, 70.465185546875],
              [59.04804687500001, 70.460498046875],
              [59.08828125000002, 70.437109375],
              [59.30986328125002, 70.361669921875],
              [59.42597656250001, 70.3109375],
              [59.52910156250002, 70.248974609375],
              [59.636328125, 70.197021484375],
              [59.95585937500002, 70.108349609375],
              [60.17226562500002, 70.0228515625],
              [60.392578125, 69.96240234375],
              [60.45048828125002, 69.93486328125],
            ],
          ],
          [
            [
              [20.957812500000017, 55.27890625],
              [20.859375, 55.183642578124996],
              [20.594824218750006, 54.982373046875],
              [20.677734375, 54.9556640625],
              [20.774023437500006, 54.947021484375],
              [20.8875, 54.909472656249996],
              [20.995898437500017, 54.902685546875],
              [21.188867187500023, 54.935205078125],
              [21.22285156250001, 55.107763671875],
              [21.235742187500023, 55.264111328125],
              [21.29755859375001, 55.264453125],
              [21.389257812500006, 55.275537109375],
              [21.447070312500017, 55.234423828124996],
              [21.5546875, 55.1953125],
              [21.682714843750006, 55.1603515625],
              [21.873925781250023, 55.100732421875],
              [22.072363281250006, 55.063671875],
              [22.13789062500001, 55.059375],
              [22.34638671875001, 55.064257812499996],
              [22.567285156250023, 55.059130859374996],
              [22.62744140625, 54.970703125],
              [22.73652343750001, 54.928857421875],
              [22.82470703125, 54.8712890625],
              [22.83125, 54.8384765625],
              [22.70966796875001, 54.632617187499996],
              [22.684472656250023, 54.562939453125],
              [22.67988281250001, 54.493017578125],
              [22.72431640625001, 54.405615234375],
              [22.766210937500006, 54.356787109375],
              [22.731835937500023, 54.35009765625],
              [22.16845703125, 54.35986328125],
              [21.634179687500023, 54.37646484375],
              [21.140527343750023, 54.391796875],
              [20.66474609375001, 54.406640625],
              [20.20820312500001, 54.420751953125],
              [19.92431640625, 54.433984375],
              [19.64423828125001, 54.447070312499996],
              [19.604394531250023, 54.4591796875],
              [19.75849609375001, 54.54482421875],
              [19.85888671875, 54.633837890624996],
              [19.944140625000017, 54.75],
              [19.953222656250006, 54.83046875],
              [19.974511718750023, 54.921191406249996],
              [20.10761718750001, 54.956494140625],
              [20.39667968750001, 54.95126953125],
              [20.520312500000017, 54.994873046875],
              [20.67890625000001, 55.10263671875],
              [20.845703125, 55.23203125],
              [20.899804687500023, 55.286669921874996],
              [20.957812500000017, 55.27890625],
            ],
          ],
          [
            [
              [33.59414062500008, 46.09624023437499],
              [33.65432280914541, 46.14622189161537],
              [33.65996518914096, 46.21957283155638],
              [33.80666706902298, 46.20828807156539],
              [34.02671988884583, 46.10672523164715],
              [34.12828272876425, 46.089798091660725],
              [34.224203188687056, 46.10108285165177],
              [34.35397792858265, 46.061586191683546],
              [34.44989838850546, 45.965665731760566],
              [34.5232493284463, 45.97695049175155],
              [34.686878348314735, 45.97695049175155],
              [34.79408356822853, 45.89231479181956],
              [34.799725948223966, 45.79075195190131],
              [34.94642782810598, 45.72868577195129],
              [35.00167423940718, 45.73338320565324],
              [35.022851562499966, 45.7009765625001],
              [35.26015625000002, 45.44692382812502],
              [35.37392578125008, 45.353613281250034],
              [35.45751953125006, 45.31630859375008],
              [35.558007812500136, 45.310888671875034],
              [35.7509765625, 45.38935546875001],
              [35.833496093750114, 45.401611328125],
              [36.01289062500007, 45.37167968749998],
              [36.0771484375, 45.42412109374999],
              [36.170507812500034, 45.45307617187501],
              [36.2903320312501, 45.45673828125007],
              [36.427050781250045, 45.43325195312508],
              [36.575, 45.3935546875],
              [36.51425781250009, 45.30375976562499],
              [36.45078125000009, 45.23232421875002],
              [36.42841796875001, 45.15327148437507],
              [36.39335937500016, 45.06538085937507],
              [36.22988281250002, 45.025976562500034],
              [36.05478515625009, 45.03081054687499],
              [35.870117187500114, 45.005322265625125],
              [35.80361328125008, 45.03959960937499],
              [35.759472656250125, 45.07084960937499],
              [35.67753906250016, 45.10200195312507],
              [35.56953125000001, 45.11933593750007],
              [35.472558593750136, 45.09848632812509],
              [35.35781250000002, 44.978417968750136],
              [35.15478515625006, 44.89633789062492],
              [35.087695312500045, 44.80263671875002],
              [34.887792968750034, 44.823583984375034],
              [34.716894531250034, 44.80712890625006],
              [34.469921875000125, 44.72167968750006],
              [34.28173828125, 44.538427734375034],
              [34.07441406250001, 44.423828125000114],
              [33.9099609375001, 44.38759765625002],
              [33.7556640625001, 44.39892578125006],
              [33.655859375000034, 44.43320312500009],
              [33.45068359375, 44.553662109375125],
              [33.4626953125001, 44.59682617187502],
              [33.49130859375009, 44.61860351562501],
              [33.53007812500016, 44.680517578125034],
              [33.61220703125002, 44.90781249999998],
              [33.60117187500009, 44.981494140625045],
              [33.555175781250114, 45.097656250000114],
              [33.39248046875002, 45.18784179687498],
              [33.26152343750016, 45.17075195312509],
              [33.18691406250008, 45.19477539062501],
              [32.918652343750125, 45.34814453124994],
              [32.77265625000007, 45.358984375000034],
              [32.61132812500006, 45.328076171875125],
              [32.55185546874998, 45.350390625000045],
              [32.508007812500125, 45.403808593750114],
              [32.828027343750136, 45.593017578125114],
              [33.14228515625001, 45.749218750000125],
              [33.280078125000045, 45.765234375000034],
              [33.466210937500136, 45.8379394531251],
              [33.6648437500001, 45.94707031249993],
              [33.63671875000006, 46.03286132812502],
              [33.59414062500008, 46.09624023437499],
            ],
          ],
        ],
      },
    },
  ],
};
