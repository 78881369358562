import { ISchema } from "../SchemaTypes";
import common from "./commonSchema";
import { formatDateString } from "../../components/common/functions/formatFunctions";

const changesSchema: ISchema = {
  programme: {
    title: "Programme",
    field: "programme",
    accessorFunction: (value: any) => value.programme?.name,
    formOptions: { required: true },
    excludeFromForm: true,
    type: "belongsTo",
  },
  programmeChangesId: {
    title: "Programme ID",
    field: "programmeChangesId",
    formOptions: {
      required: true,
      options: {
        dataSource: "programmes",
        addBlank: true,
        accessorFunction: (dataSource: any) =>
          dataSource.map((row: any) => {
            return { value: row.id, label: row.name };
          }),
      },
      order: 1,
    },
    type: "select",
  },
  statement: {
    title: "Statement",
    field: "statement",
    accessorFunction: (value: any) => value.statement?.agreement,
    formOptions: { required: true },
    excludeFromForm: true,
    type: "belongsTo",
  },
  statementChangesId: {
    title: "Statement",
    field: "statementChangesId",
    formOptions: {
      required: true,
      options: {
        dataSource: "statements",
        addBlank: true,
        accessorFunction: (dataSource: any) =>
          dataSource.map((row: any) => {
            return { value: row.id, label: row.agreement };
          }),
      },
      order: 1,
    },
    type: "select",
  },

  title: { title: "Title", field: "title", formOptions: { required: true } },

  requesterName: {
    title: "Requester Name",
    field: "requesterName",
    formOptions: { required: true },
  },
  requesterEmail: {
    title: "Requester Email",
    field: "requesterEmail",
    formOptions: { required: true },
  },
  organization: {
    title: "Organization",
    field: "organization",
    formOptions: { required: true },
  },
  requestType: {
    title: "Request Type",
    field: "requestType",
    formOptions: { required: true },
  },
  requestTitle: {
    title: "Request Title",
    field: "requestTitle",
    formOptions: { required: true },
  },
  changeRequestArea: {
    title: "Change Request Area",
    field: "changeRequestArea",
    formOptions: { required: true },
  },
  programName: {
    title: "Program Name",
    field: "programName",
    formOptions: { required: true },
  },
  requestCategory: {
    title: "Request Category",
    field: "requestCategory",
    formOptions: { required: true },
  },
  requestDetail: {
    title: "Request Detail",
    field: "requestDetail",
    formOptions: { required: true },
  },
  priority: {
    title: "Priority",
    field: "priority",
    formOptions: { required: true },
  },
  effectiveDate: {
    title: "Effective Date",
    field: "effectiveDate",
    type: "date",
    formatFunction: formatDateString,
    formOptions: { required: true },
  },
  impactDeltaDays: {
    title: "Impact Delta (Days)",
    field: "impactDeltaDays",
    type: "number",
    formOptions: { required: false },
  },
  requestTypeLocation: {
    title: "Request Type Location",
    field: "requestTypeLocation",
    formOptions: { required: false },
  },
  financialImpact: {
    title: "Financial Impact",
    field: "financialImpact",
    formOptions: { required: false },
  },
  emergencyRequest: {
    title: "Emergency Request",
    field: "emergencyRequest",
    formOptions: { required: true },
  },
  deliveryApproval: {
    title: "Delivery Approval",
    field: "deliveryApproval",
    formOptions: { required: true },
  },
  dateDeliveryTeamApproved: {
    title: "Date Delivery TeamApproved",
    field: "dateDeliveryTeamApproved",
    type: "date",
    formatFunction: formatDateString,
    formOptions: { required: false },
  },
  deliveryTeamApprovalNotes: {
    title: "Delivery Team Approval Notes",
    field: "deliveryTeamApprovalNotes",
    type: "textarea",
    formOptions: { required: false },
  },
  sltApproval: {
    title: "SLT Approval",
    field: "sltApproval",
    formOptions: { required: true },
  },
  sltApprovalDate: {
    title: "SLT Approval Date",
    field: "sltApprovalDate",
    type: "date",
    formatFunction: formatDateString,
    formOptions: { required: false },
  },
  sltApprovalNotes: {
    title: "SLT Approval Notes",
    field: "sltApprovalNotes",
    type: "textarea",
    formOptions: { required: false },
  },
  impactStatement: {
    title: "Impact Statement",
    field: "impactStatement",
    type: "textarea",
    formOptions: { required: false },
  },
  additionalInfo: {
    title: "Additional Info",
    field: "additionalInfo",
    type: "textarea",
    formOptions: { required: false },
  },
  newSow: {
    title: "New SoW",
    field: "newSow",
    formOptions: { required: false },
  },
  pmoAdmin: {
    title: "PMO Admin",
    field: "pmoAdmin",
    formOptions: { required: false },
  },
  customerNotified: {
    title: "Customer Notified",
    field: "customerNotified",
    formOptions: { required: false },
  },
  ...common,
};

export default changesSchema;
