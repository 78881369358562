import { useContext } from "react";
import CrudScreen from "../common/CrudScreen";
import AppContext from "../../context/AppContext";
import { deliverablesSchema } from "../../context/dataSchema";
// import DeliverableQuickStats from "./DeliverableQuickStats";
import {
  createDeliverable,
  updateDeliverable,
  deleteDeliverable,
} from "../../graphql/mutations";
import { Progress } from "@mantine/core";

export default function Deliverables(props: any) {
  const { dataObject } = useContext(AppContext);
  const { deliverables = [] } = dataObject;

  const screenProps = {
    data: deliverables,
    schema: deliverablesSchema,
    title: "Deliverables",
    quickStats: null,
    tableColumns: [
      "sow",
      "artifact",
      "dueDate",
      {
        title: "Progress",
        field: "progress",
        formatFunction: (value: any) => (
          <Progress
            color={value === 100 ? "green" : "blue"}
            value={value}
            label={`${value}%`}
            radius="xl"
            size={16}
          />
        ),
        disableSorting: true,
      },
    ],
    filterFields: [],
    recordName: "Deliverable",
    pagePath: "deliverables",
    dataObjectKey: "deliverables",
    formProps: {
      createQuery: createDeliverable,
      updateQuery: updateDeliverable,
      deleteQuery: deleteDeliverable,
      dataObjectKey: "deliverables",
    },
    widerTitle: true,
    modulesToLoad: [
      "Verticals",
      "Accounts",
      "Programmes",
      "Statements of Work",
      "Deliverables",
    ],
  };
  return <CrudScreen {...screenProps} />;
}
