import { useContext } from "react";
import CrudScreen from "../common/CrudScreen";
import AppContext from "../../context/AppContext";
import { tenantsSchema as schema } from "../../context/dataSchema";
import {
  createTenant as createQuery,
  updateTenant as updateQuery,
  deleteTenant as deleteQuery,
} from "../../graphql/mutations";

export default function Tenants() {
  const { dataObject } = useContext(AppContext);
  const { tenants: data = [] } = dataObject;

  const screenProps = {
    data,
    schema,
    title: "Tenants",
    quickStats: null,
    tableColumns: ["name"],
    filterFields: [],
    recordName: "Tenant",
    pagePath: "tenants",
    dataObjectKey: "tenants",
    formProps: {
      createQuery,
      updateQuery,
      deleteQuery,
      dataObjectKey: "tenants",
    },
    modulesToLoad: ["Verticals", "Tenants"],
  };
  return <CrudScreen {...screenProps} />;
}
