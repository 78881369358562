import { formatDateString } from "../../components/common/functions/formatFunctions";
import common from "./commonSchema";
import { ISchema } from "../SchemaTypes";

const deliverablesSchema: ISchema = {
  statementDeliverablesId: {
    title: "Statement of Work",
    field: "statementDeliverablesId",
    formOptions: {
      required: true,
      options: {
        dataSource: "statements",
        addBlank: true,
        accessorFunction: (dataSource: any) =>
          dataSource.map((row: any) => {
            return { value: row.id, label: row.agreement };
          }),
      },
      order: 1,
    },
    type: "select",
  },
  sow: {
    title: "Statement of Work",
    field: "sow",
    type: "belongsTo",
    accessorFunction: (row: any) => {
      return row.sow?.agreement;
    },
    excludeFromForm: true,
    formOptions: { required: false },
  },
  artifact: {
    title: "Artifact",
    field: "artifact",
    type: "textarea",
    formOptions: { required: true },
  },

  startDate: {
    title: "Start Date",
    field: "startDate",
    type: "date",
    formatFunction: formatDateString,
    formOptions: { required: false },
  },
  dueDate: {
    title: "Due Date",
    field: "dueDate",
    type: "date",
    formatFunction: formatDateString,
    formOptions: { required: false },
  },
  eta: {
    title: "ETA",
    field: "eta",
    type: "date",
    formatFunction: formatDateString,
    formOptions: { required: false },
  },
  actualDate: {
    title: "Actual Date",
    field: "actualDate",
    type: "date",
    formatFunction: formatDateString,
    formOptions: { required: false },
  },

  progress: {
    title: "Progress",
    field: "progress",
    type: "number",
    formOptions: {
      required: false,
      min: 0,
      max: 100,
      step: 1,
      defaultValue: 0,
    },
  },
  comments: {
    title: "Comments",
    field: "comments",
    type: "textarea",
    formOptions: { required: false },
  },
  ...common,
};

export default deliverablesSchema;
