import { useMantineTheme } from "@mantine/core";

function PivotTable(props: any) {
  const theme = useMantineTheme();
  const color = theme.fn
    .rgba(theme.colors[theme.primaryColor][9], 1)
    .replace("rgba(", "")
    .replace(", 1)", "");
  const { renderers, rendererName } = props;
  const Renderer =
    renderers[
      rendererName in renderers ? rendererName : Object.keys(renderers)[0]
    ];

  return <Renderer {...props} color={color} />;
}

export default PivotTable;
