import CustomPaper from "./CustomPaper";
import { createStyles, Group, Text } from "@mantine/core";

interface IWidgetFrameProps {
  title?: string | JSX.Element;
  icon?: any;
  children: any;
  controls?: string | JSX.Element;
}

const useStyles = createStyles((theme) => ({
  title: {
    fontWeight: 700,
    // textTransform: "uppercase",
  },
  icon: {
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[3]
        : theme.colors.gray[4],
  },
}));

export default function WidgetFrame(props: IWidgetFrameProps) {
  const { classes, theme } = useStyles();
  const { title, icon, children, controls } = props;
  return (
    <CustomPaper>
      <div
        style={{
          display: "grid",
          gridTemplateRows: title || controls ? "auto 1fr" : "1fr",
          height: "100%",
          width: "100%",
          position: "relative",
        }}
      >
        {title || controls ? (
          <Group position="apart" mb={"md"}>
            <Text
              size="md"
              // color="dimmed"
              className={classes.title}
              color={theme.colors[theme.primaryColor][9]}
              sx={{ fontFamily: `"Cabin", sans-serif` }}
            >
              {title}
            </Text>
            {controls || icon ? (
              <Group position="right" style={{ flexGrow: 1 }}>
                {controls ? controls : null}
                {icon ? (
                  <props.icon className={classes.icon} size={16} />
                ) : null}
              </Group>
            ) : null}
          </Group>
        ) : null}
        {children}
      </div>
    </CustomPaper>
  );
}
