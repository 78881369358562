import { Group, Loader, Skeleton, Text, createStyles } from "@mantine/core";
import { IconArrowDownRight, IconArrowUpRight } from "@tabler/icons-react";
import { useMemo } from "react";

const useStyles = createStyles((theme) => ({
  value: {
    fontWeight: 700,
    lineHeight: 1,
  },
  diff: {
    lineHeight: 1,
    display: "flex",
    alignItems: "center",
  },
  icon: {
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[3]
        : theme.colors.gray[4],
  },
  title: {
    fontWeight: 700,
    textTransform: "uppercase",
  },
  paper: {
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[6]
        : theme.colors.gray[0],
  },
}));

type ScoreCardProps = {
  value: number;
  comparisonValue: number;
  comparisonText: string;
  formatFunction: (value: number) => string;
  percentageChange?: boolean;
  positive?: "Increase" | "Decrease";
};

export default function ScoreCard({
  value,
  comparisonValue,
  comparisonText,
  formatFunction,
  percentageChange = false,
  positive = "Increase",
}: ScoreCardProps) {
  const { classes } = useStyles();

  const comparatorValue = useMemo(() => {
    if (comparisonValue || comparisonValue === 0) {
      return percentageChange
        ? Math.round(((value - comparisonValue) / comparisonValue) * 100)
        : value - comparisonValue;
    }
    if (isNaN(comparisonValue)) return NaN;
    return null;
  }, [value, comparisonValue, percentageChange]);

  const color = useMemo(() => {
    if (comparatorValue === null || comparatorValue === 0) return undefined;
    return comparatorValue > 0
      ? positive === "Increase"
        ? "teal"
        : "red"
      : positive === "Increase"
      ? "red"
      : "teal";
  }, [positive, comparatorValue]);

  const renderComparisonValue = () => {
    if (comparatorValue && isNaN(comparatorValue)) return "-";
    if (comparatorValue === null)
      return comparisonText ? <Loader size="xs" /> : null;
    if (comparatorValue === 0) return "-";
    if (!isFinite(value) || !isFinite(comparatorValue)) {
      return "∞";
    }
    return percentageChange
      ? `${comparatorValue}%`
      : formatFunction(comparatorValue);
  };

  const renderIcon = () => {
    if (comparatorValue === null || comparatorValue === 0) return null;
    return comparatorValue > 0 ? (
      <IconArrowUpRight size={16} />
    ) : (
      <IconArrowDownRight size={16} />
    );
  };

  const renderValue = () => {
    if (!isFinite(value)) {
      return value > 0 ? "∞" : "-∞";
    }
    return formatFunction(value);
  };

  return (
    <div>
      <Group spacing="xs" mt={"0.5em"}>
        {/* <Group spacing="xs"> */}
        <Text className={classes.value} style={{ fontSize: 24 }}>
          {renderValue()}
        </Text>
        {comparisonText !== "None" && (
          <Text color={color} size="sm" weight={500} className={classes.diff}>
            <span>{renderComparisonValue()}</span>
            {renderIcon()}
          </Text>
        )}
      </Group>
      {comparisonText !== "None" && (
        <Text size="xs" color="dimmed" mt={"0.75em"}>
          {comparisonText &&
          (comparatorValue !== null || comparatorValue === 0) ? (
            comparisonText
          ) : (
            <Skeleton h={12} w={comparisonText.length * 5} />
          )}
        </Text>
      )}
    </div>
  );
}
