import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { ScrollArea, Text, Timeline } from "@mantine/core";

dayjs.extend(relativeTime);

export default function RecordActivity({
  selectedRecord,
  onModalCloseFunction,
  schema,
}: any) {
  const parsedAudit = JSON.parse(selectedRecord.audit);

  return (
    <ScrollArea sx={{ height: "60vh" }} offsetScrollbars={true}>
      <Timeline
        active={parsedAudit.length + 1}
        bulletSize={24}
        lineWidth={2}
        mt={"lg"}
      >
        {parsedAudit.reverse().map((auditObject: any) => {
          const { dateOfUpdate, previousValues } = auditObject;
          return (
            <Timeline.Item title="Updated">
              {Object.keys(previousValues).map((key: any) => {
                return (
                  <>
                    <Text color="dimmed" size="sm">
                      {schema[key].title} updated from{" "}
                      {previousValues[key] === null
                        ? "blank"
                        : previousValues[key] === false
                        ? "false"
                        : previousValues[key]}
                    </Text>
                  </>
                );
              })}
              <Text size="xs" mt={4}>
                {dayjs(dateOfUpdate).fromNow()}
              </Text>
            </Timeline.Item>
          );
        })}
        <Timeline.Item title="Created">
          {/* <Text color="dimmed" size="sm">
          You&apos;ve created new branch{" "}
          <Text variant="link" component="span" inherit>
            fix-notifications
          </Text>{" "}
          from master
        </Text> */}
          <Text size="xs" mt={4}>
            {dayjs(selectedRecord.createdAt).fromNow()}
          </Text>
        </Timeline.Item>
      </Timeline>
    </ScrollArea>
  );
}
