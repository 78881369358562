import { memo, useCallback, useContext, useMemo } from "react";
import Pivot from "../PivotTable/Pivot";
import { PivotData } from "../PivotTable/Utilities";
import ColumnChart from "../ColumnChart";
import BarChart from "../BarChart";
import PieChart from "../PieChart";
import Treemap from "../Treemap/Treemap";
import { RadarChart } from "../RadarChart";
import { formatCurrency, nFormatter } from "../../functions/formatFunctions";
import ScatterChart from "../ScatterChart";
import ScoreCard from "../ScoreCard";
// import MapChart from "../MapChart";
import { Map } from "../Map";
import { createFilterFunction } from "../../functions/createFilterFunction";
import { Group, LoadingOverlay, Title, useMantineTheme } from "@mantine/core";
import Placeholder from "../../Placeholder";
import { IChartProps } from "../Types/ChartPropsTypes";
import { IPivotData } from "../Types/PivotDataTypes";
import AreaChart from "../AreaChart";
import LineChart from "../LineChart";
import { getDataSchema } from "../../functions/getDataSchema";
import { ISchema } from "../../../../context/SchemaTypes";
import DataTable from "../DataTable/DataTable";
import useLoadingStatus from "../../../../hooks/useLoadingStatus";
import AppContext from "../../../../context/AppContext";
import { BoxWhisker } from "../BoxWhisker";

function getFormatFunction(
  formatFunction: string,
  condensedNumbers: boolean,
  decimals: number
): (value: any) => number | string {
  if (condensedNumbers === true) {
    switch (formatFunction) {
      case "Format Currency ($)":
        return (value: any) => `$${nFormatter(value, decimals)}`;
      case "Format Currency (£)":
        return (value: any) => `£${nFormatter(value, decimals)}`;
      case "Format %":
        return (value: any) =>
          `${
            Math.round(value * Math.pow(10, decimals + 2)) /
            Math.pow(10, decimals)
          }%`;
      default:
        return (value: any) => nFormatter(value, decimals);
    }
  } else {
    switch (formatFunction) {
      case "Format Currency ($)":
        return (value: any) => `${formatCurrency(value, "USD")}`;
      case "Format Currency (£)":
        return (value: any) => `${formatCurrency(value, "GBP")}`;
      case "Format %":
        return (value: any) =>
          `${
            Math.round(value * Math.pow(10, decimals + 2)) /
            Math.pow(10, decimals)
          }%`;
      default:
        return (value: any) => value;
    }
  }
}

type IActualAggregatorLookup = {
  "Difference (Sum)": "Sum";
};

const actualAggregatorLookup: IActualAggregatorLookup = {
  "Difference (Sum)": "Sum",
};

export default memo(function CustomWidget(
  props: IChartProps & {
    globalFilteredData?: any;
    comparisonGlobalFilteredData?: any;
    startDate: any;
    endDate: any;
  }
) {
  const {
    chartType,
    chartTitle,
    chartVariant,
    dataSourceKey,
    period,
    comparisonPeriod,
    comparisonFieldString,
    cols,
    rows,
    aggregatorName,
    values,
    formatFunction = "None",
    limitRows = undefined,
    limitRowsEnd = 0,
    dataLabels = true,
    legend = false,
    yAxisWidth,
    dataLabelFontSize = 1,
    yAxisFontSize = 1,
    xAxisFontSize = 1,
    filterObjects = [],
    condensedNumbers = false,
    decimals = 0,
    mapFunction = (row: any) => row,
    rowOrder = "value_z_to_a",
    colOrder = "value_z_to_a",
    titleOrder,
    percentageChange = false,
    mapScale = 1,
    longitude,
    latitude,
    width,
    height,
    enableZoom,
    includeXAxis,
    includeYAxis,
    yAxisMax,
    yAxisMin,
    updateDashboardFilter: updateDashboardFilterFunction,
    grid,
    globalFilteredData = [],
    comparisonGlobalFilteredData = [],
    innerRadius,
    labelPosition,
    labelType,
    disableChartInteractions,
    disableTooltip,
    disableAnimations,
    positive = "Increase",
    dateField = "",
    startDate,
    endDate,
    lineOfBestFit,
    xAxisHeight,
  } = props;

  const { dataObject = {} } = useContext(AppContext);
  const { customDatasets = [] } = dataObject ?? {};

  const updateDashboardFilter = useCallback(
    (field: string, value: string) =>
      updateDashboardFilterFunction(field, value, dataSourceKey),
    [dataSourceKey, updateDashboardFilterFunction]
  );

  const loading = useLoadingStatus([dataSourceKey]);

  const theme = useMantineTheme();

  const schema: ISchema = useMemo(
    () => getDataSchema(dataSourceKey, customDatasets),
    [dataSourceKey, customDatasets]
  );

  const finalFormatFunction = useMemo(
    () => getFormatFunction(formatFunction, condensedNumbers, decimals),
    [formatFunction, condensedNumbers, decimals]
  );

  const customFilterFunction = useMemo(
    () =>
      filterObjects.length > 0 || (dateField && (startDate || endDate))
        ? createFilterFunction(filterObjects, dateField, startDate, endDate)
        : (value: any) => true,
    [filterObjects, dateField, startDate, endDate]
  );

  const filteredData = useMemo(
    () => globalFilteredData.filter(customFilterFunction),
    [globalFilteredData, customFilterFunction]
  );

  const realAggregatorName: string = useMemo(
    () =>
      actualAggregatorLookup[aggregatorName as keyof IActualAggregatorLookup]
        ? actualAggregatorLookup[
            aggregatorName as keyof IActualAggregatorLookup
          ]
        : aggregatorName,
    [aggregatorName]
  );

  const pivotData = useMemo(() => {
    if (filteredData.length > 0) {
      const newPivotData: { [value: string]: IPivotData } = values.reduce(
        (obj: any, value: string, index: number) => {
          obj[value] = new PivotData({
            // aggregators: aggregators,
            data: filteredData,
            cols,
            rows,
            vals: [value],
            aggregatorName: index === 0 ? realAggregatorName : "Sum",
            sorters: {},
            valueFilter: {},
            rowOrder,
            colOrder,
            // derivedAttributes,
          });

          return obj;
        },
        {}
      );

      return newPivotData;
    } else {
      return null;
    }
  }, [
    colOrder,
    // cols,
    // derivedAttributes,
    filteredData,
    realAggregatorName,
    rowOrder,
    // rows,
    // values,
  ]);

  const comparisonPivotData = useMemo(() => {
    if (
      // true
      period !== "All" &&
      comparisonPeriod !== "None"
      // ["Difference (Sum)"].includes(aggregatorName) ||
      // ["Scorecard"].includes(chartType)
    ) {
      const comparisonFilteredData =
        comparisonGlobalFilteredData.filter(customFilterFunction);

      if (comparisonFilteredData.length > 0) {
        const newComparisonPivotData: { [value: string]: IPivotData } =
          values.reduce(
            (
              obj: { [valueName: string]: any },
              value: string,
              index: number
            ) => {
              obj[value] = new PivotData({
                // aggregators: aggregators,
                data: comparisonFilteredData,
                cols,
                rows,
                vals: [value],
                aggregatorName: index === 0 ? realAggregatorName : "Sum",
                sorters: {},
                valueFilter: {},
                rowOrder,
                colOrder,
                // derivedAttributes,
              });
              return obj;
            },
            {}
          );

        return newComparisonPivotData;
      } else {
        return {};
      }
    } else {
      return null;
    }
  }, [
    colOrder,
    // cols,
    // derivedAttributes,
    realAggregatorName,
    rowOrder,
    // rows,
    // values,
    aggregatorName,
    chartType,
    comparisonPeriod,
    // currentProgramme,
    customFilterFunction,
    // data,
    // filterKey,
    mapFunction,
    period,
    // periods,
    // permittedProgrammeList,
  ]);

  if (loading && !["Title"].includes(chartType)) {
    return <LoadingOverlay visible={true} overlayOpacity={0} />;
  }

  if (
    (values.length === 0 || filteredData.length === 0) &&
    !["Title", "Data Table"].includes(chartType)
  )
    return <Placeholder placeholderText="No Data" />;
  switch (chartType) {
    case "Data Table": {
      return (
        <DataTable
          data={filteredData}
          schema={schema}
          cols={cols}
          updateDashboardFilter={updateDashboardFilter}
        />
      );
    }
    case "Box Plot": {
      if (!pivotData) return null;

      return (
        <BoxWhisker
          data={filteredData}
          rows={rows}
          values={values}
          grid={grid}
          includeXAxis={includeXAxis}
          includeYAxis={includeYAxis}
          formatCategoryAxis={(val: any) => val}
          xAxisFontSize={xAxisFontSize}
          yAxisWidth={yAxisWidth}
          yAxisFontSize={yAxisFontSize}
          formatFunction={finalFormatFunction}
          disableTooltip={disableTooltip}
          comparisonFieldString={comparisonFieldString}
        />
      );
    }
    case "Scatter Chart": {
      if (!pivotData) return null;

      return (
        <ScatterChart
          values={values}
          data={filteredData}
          schema={schema}
          rows={rows}
          yAxisFontSize={yAxisFontSize}
          yAxisWidth={yAxisWidth}
          formatFunction={finalFormatFunction}
          legend={legend}
          grid={grid}
          lineOfBestFit={lineOfBestFit}
          xAxisHeight={xAxisHeight}
          disableTooltip={disableTooltip}
          comparisonFieldString={comparisonFieldString}
        />
      );
    }
    case "Map": {
      if (!pivotData || !pivotData[values[0]]) return null;

      //@ts-ignore
      const dataObject = pivotData[values[0]].rowTotals;

      for (let key in dataObject) {
        //@ts-ignore
        dataObject[key].name = key;
        //@ts-ignore
        dataObject[key].val = dataObject[key].value(); //@ts-ignore
      }

      const data = Object.values(dataObject);

      return (
        <Map
          data={data}
          dataObject={dataObject}
          schema={schema}
          pivotData={pivotData}
          comparisonPivotData={comparisonPivotData}
          comparisonText={comparisonFieldString}
          formatFunction={finalFormatFunction}
          legend={legend}
          dataLabels={dataLabels}
          dataLabelFontSize={dataLabelFontSize}
          mapScale={mapScale}
          longitude={longitude}
          latitude={latitude}
          width={width}
          height={height}
          enableZoom={enableZoom}
          updateDashboardFilter={updateDashboardFilter}
          rows={rows}
        />
      );
    }
    case "Scorecard": {
      if (
        !pivotData ||
        // !comparisonPivotData ||
        !pivotData[values[0]] //||
        // comparisonPivotData[values[0]]
      ) {
        return null;
      }

      let value: any = 0;
      let comparisonValue: any = 0;

      switch (aggregatorName) {
        case "Sum over Sum":
          if (values[0] && values[1]) {
            value = //@ts-ignore
              pivotData[values[0]].allTotal.sumNum / //@ts-ignore
              pivotData[values[1]].allTotal.sum;
            comparisonValue =
              comparisonPivotData &&
              comparisonPivotData[values[0]] &&
              comparisonPivotData[values[1]] //@ts-ignore
                ? comparisonPivotData[values[0]].allTotal.sumNum / //@ts-ignore
                  comparisonPivotData[values[1]].allTotal.sum
                : NaN;
          }
          break;
        default: //@ts-ignore
          value = pivotData[values[0]].allTotal.value();
          comparisonValue = comparisonPivotData
            ? //@ts-ignore
              comparisonPivotData[values[0]]?.allTotal.value() ?? 0
            : NaN;
          break;
      }

      return (
        <ScoreCard
          value={value}
          comparisonValue={comparisonValue}
          comparisonText={comparisonFieldString}
          //@ts-ignore
          formatFunction={finalFormatFunction}
          percentageChange={percentageChange}
          positive={positive}
        />
      );
    }
    case "Title": {
      return (
        <Group
          //@ts-ignore
          position={chartVariant.toLowerCase()}
          p="md"
          pr={"xl"}
          sx={{ alignItems: "center", height: "100%" }}
        >
          <Title order={titleOrder} color={theme.colors[theme.primaryColor][9]}>
            {chartTitle}
          </Title>
        </Group>
      );
    }
    case "Bar Chart":
    case "Column Chart":
    case "Area Chart":
    case "Line Chart":
    case "Treemap":
    case "Radar Chart":
    case "Pie Chart": {
      if (!pivotData) return null;
      const firstPivotData = Object.values(pivotData)[0];
      if (!firstPivotData) return null;
      //@ts-ignore
      // const firstComparisonPivotData = comparisonPivotData
      //   ? Object.values(comparisonPivotData)[0]
      //   : {};

      //@ts-ignore
      const firstComparisonPivotData = comparisonPivotData
        ? Object.values(comparisonPivotData)[0]
        : null;

      let chartData: { [key: string]: string | number }[] = [];

      if (Object.keys(firstPivotData.tree).length > 0) {
        chartData = firstPivotData.getRowKeys().map((keyList: string[]) => {
          // chartData = Object.keys(firstPivotData.tree).map((key: string) => {
          const key = keyList[0];

          if (!key) return null;
          return {
            name: key,
            ...Object.keys(firstPivotData.tree[key]).reduce(
              (obj: any, colKey: any) => {
                obj[colKey] = firstPivotData.tree[key][colKey].value() ?? 0;

                if (firstComparisonPivotData) {
                  if (
                    firstComparisonPivotData.tree &&
                    firstComparisonPivotData?.tree[key] &&
                    firstComparisonPivotData?.tree[key][colKey] &&
                    firstComparisonPivotData?.tree[key][colKey].value()
                  ) {
                    obj[`${colKey}#comparison`] =
                      firstComparisonPivotData?.tree[key][colKey].value();
                  } else {
                    obj[`${colKey}#comparison`] = 0;
                  }
                }
                return obj;
              },
              {}
            ),
          };
        });

        if (limitRows) {
          chartData = chartData.slice(0, limitRows);
        }
        if (limitRowsEnd) {
          chartData = chartData.slice(chartData.length - limitRowsEnd);
        }
      } else if (Object.keys(firstPivotData.rowTotals).length > 0) {
        let keys: string[][] = firstPivotData.getRowKeys();

        if (limitRows) {
          keys = keys.slice(0, limitRows);
        }
        if (limitRowsEnd) {
          keys = keys.slice(keys.length - limitRowsEnd);
        }

        chartData = keys.map((keyList: string[]) => {
          const key: string = keyList[0];
          const returnObject: { [key: string]: string | number } = {
            name: key,
          };

          values.forEach((value: string) => {
            if (["Difference (Sum)"].includes(aggregatorName)) {
              if (
                pivotData[value] &&
                comparisonPivotData &&
                comparisonPivotData[value]
              ) {
                returnObject[value] = //@ts-ignore
                  pivotData[value].rowTotals[key].value() - //@ts-ignore
                  (comparisonPivotData[value].rowTotals[key]
                    ? //@ts-ignore
                      comparisonPivotData[value].rowTotals[key].value()
                    : 0);
                returnObject.label = schema[value].title;
              }
            } else {
              if (pivotData[value]) {
                returnObject[value] = pivotData[value].rowTotals[key]
                  ? pivotData[value].rowTotals[key].value()
                  : 0;

                if (
                  comparisonPivotData &&
                  comparisonPivotData[value] &&
                  comparisonPivotData[value].rowTotals[key] &&
                  comparisonPivotData[value].rowTotals[key].value()
                ) {
                  returnObject[`${value}#comparison`] =
                    comparisonPivotData[value].rowTotals[key].value();
                } else {
                  returnObject[`${value}#comparison`] = 0;
                }
                returnObject.label = schema[value].title;
              }
            }
          });

          return returnObject;
        });
      } else {
        if (firstPivotData.colKeys.length > 0) {
          // T&M Chart - Working
          chartData = values.map((value: string) => {
            return {
              name: schema[value].title,
              ...firstPivotData.getColKeys().reduce((obj: any, colKey: any) => {
                obj[colKey] = firstPivotData.colTotals[colKey].value() ?? 0;

                let comparatorValue = 0;

                if (firstComparisonPivotData) {
                  if (
                    firstComparisonPivotData &&
                    firstComparisonPivotData.colTotals &&
                    firstComparisonPivotData.colTotals[colKey] &&
                    firstComparisonPivotData.colTotals[colKey].value()
                  ) {
                    comparatorValue =
                      firstComparisonPivotData.colTotals[colKey].value();
                  }
                  obj[`${colKey}#comparison`] = comparatorValue;
                }
                return obj;
              }, {}),
            };
          });
          if (limitRows) {
            chartData = chartData.slice(0, limitRows);
          }
          if (limitRowsEnd) {
            chartData = chartData.slice(chartData.length - limitRowsEnd);
          }
        } else {
          chartData = values.map((value: string) => {
            return {
              name: schema[value].title,
              [value]: pivotData[value]?.allTotal.value() ?? 0,
            };
          });
          if (limitRows) {
            chartData = chartData.slice(0, limitRows);
          }
          if (limitRowsEnd) {
            chartData = chartData.slice(chartData.length - limitRowsEnd);
          }
        }
      }

      switch (chartType) {
        case "Bar Chart":
          return (
            <BarChart
              data={chartData}
              comparisonFieldString={comparisonFieldString}
              dataLabels={dataLabels}
              legend={legend}
              values={values}
              yAxisWidth={yAxisWidth}
              dataLabelFontSize={dataLabelFontSize}
              yAxisFontSize={yAxisFontSize}
              xAxisFontSize={xAxisFontSize}
              formatFunction={finalFormatFunction}
              chartVariant={chartVariant}
              //@ts-ignore
              rowKeys={firstPivotData.getRowKeys()}
              //@ts-ignore
              colKeys={firstPivotData.getColKeys()}
              includeYAxis={includeYAxis}
              includeXAxis={includeXAxis}
              rows={rows}
              cols={cols}
              updateDashboardFilter={updateDashboardFilter}
              schema={schema}
              yAxisMax={yAxisMax}
              yAxisMin={yAxisMin}
              grid={grid}
              disableChartInteractions={disableChartInteractions}
              disableTooltip={disableTooltip}
              disableAnimations={disableAnimations}
            />
          );
        case "Area Chart":
          return (
            <AreaChart
              data={chartData}
              dataLabels={dataLabels}
              comparisonFieldString={comparisonFieldString}
              legend={legend}
              values={values}
              yAxisWidth={yAxisWidth}
              dataLabelFontSize={dataLabelFontSize}
              yAxisFontSize={yAxisFontSize}
              xAxisFontSize={xAxisFontSize}
              formatFunction={finalFormatFunction}
              chartVariant={chartVariant}
              includeYAxis={includeYAxis}
              includeXAxis={includeXAxis}
              //@ts-ignore
              rowKeys={firstPivotData.getRowKeys()}
              //@ts-ignore
              colKeys={firstPivotData.getColKeys()}
              rows={rows}
              cols={cols}
              updateDashboardFilter={updateDashboardFilter}
              schema={schema}
              yAxisMax={yAxisMax}
              yAxisMin={yAxisMin}
              grid={grid}
            />
          );
        case "Line Chart":
          return (
            <LineChart
              data={chartData}
              colKeys={firstPivotData.getColKeys()}
              comparisonFieldString={comparisonFieldString}
              dataLabels={dataLabels}
              legend={legend}
              values={values}
              yAxisWidth={yAxisWidth}
              dataLabelFontSize={dataLabelFontSize}
              yAxisFontSize={yAxisFontSize}
              xAxisFontSize={xAxisFontSize}
              formatFunction={finalFormatFunction}
              chartVariant={chartVariant}
              includeYAxis={includeYAxis}
              includeXAxis={includeXAxis}
              rows={rows}
              cols={cols}
              updateDashboardFilter={updateDashboardFilter}
              schema={schema}
              yAxisMax={yAxisMax}
              yAxisMin={yAxisMin}
              grid={grid}
            />
          );
        case "Column Chart":
          return (
            <ColumnChart
              data={chartData}
              dataLabels={dataLabels}
              comparisonFieldString={comparisonFieldString}
              legend={legend}
              values={values}
              yAxisWidth={yAxisWidth}
              dataLabelFontSize={dataLabelFontSize}
              yAxisFontSize={yAxisFontSize}
              xAxisFontSize={xAxisFontSize}
              formatFunction={finalFormatFunction}
              chartVariant={chartVariant}
              //@ts-ignore
              rowKeys={firstPivotData.getRowKeys()}
              //@ts-ignore
              colKeys={firstPivotData.getColKeys()}
              includeYAxis={includeYAxis}
              includeXAxis={includeXAxis}
              rows={rows}
              cols={cols}
              updateDashboardFilter={updateDashboardFilter}
              schema={schema}
              yAxisMax={yAxisMax}
              yAxisMin={yAxisMin}
              grid={grid}
              disableChartInteractions={disableChartInteractions}
              disableTooltip={disableTooltip}
              disableAnimations={disableAnimations}
            />
          );
        case "Treemap":
          return (
            <Treemap
              //@ts-ignore
              data={chartData}
              dataLabels={dataLabels}
              dataLabelFontSize={dataLabelFontSize}
              comparisonFieldString={comparisonFieldString}
              values={values}
              yAxisWidth={yAxisWidth}
              chartVariant={chartVariant}
              legend={legend}
              updateDashboardFilter={updateDashboardFilter}
              rows={rows}
              cols={cols}
              schema={schema}
              formatFunction={finalFormatFunction}
              disableChartInteractions={disableChartInteractions}
              disableTooltip={disableTooltip}
              disableAnimations={disableAnimations}
            />
          );
        case "Radar Chart":
          return (
            <RadarChart
              //@ts-ignore
              data={chartData}
              dataLabels={dataLabels}
              dataLabelFontSize={dataLabelFontSize}
              comparisonFieldString={comparisonFieldString}
              values={values}
              yAxisWidth={yAxisWidth}
              chartVariant={chartVariant}
              legend={legend}
              updateDashboardFilter={updateDashboardFilter}
              rows={rows}
              cols={cols}
              schema={schema}
              formatFunction={finalFormatFunction}
              disableChartInteractions={disableChartInteractions}
              disableTooltip={disableTooltip}
              disableAnimations={disableAnimations}
              colKeys={firstPivotData.getColKeys()}
            />
          );
        case "Pie Chart":
          return (
            <PieChart
              //@ts-ignore
              data={chartData}
              dataLabels={dataLabels}
              comparisonFieldString={comparisonFieldString}
              values={values}
              yAxisWidth={yAxisWidth}
              chartVariant={chartVariant}
              legend={legend}
              updateDashboardFilter={updateDashboardFilter}
              rows={rows}
              schema={schema}
              formatFunction={finalFormatFunction}
              innerRadius={innerRadius}
              labelPosition={labelPosition}
              labelType={labelType}
              disableChartInteractions={disableChartInteractions}
              disableTooltip={disableTooltip}
              disableAnimations={disableAnimations}
            />
          );
        default:
          return <div>Error</div>;
      }
    }
    case "Pivot Table":
      if (!pivotData) return null;
      return (
        <Pivot
          pivotData={Object.values(pivotData)[0]}
          type={chartVariant}
          formatFunction={finalFormatFunction}
        />
      );
    default:
      return null;
  }
});
