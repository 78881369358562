import React, { useContext, useMemo } from "react";
import AppContext from "../../../../context/AppContext";

import dayjs, { Dayjs } from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import advancedFormat from "dayjs/plugin/advancedFormat";
import weekday from "dayjs/plugin/weekday";
import utc from "dayjs/plugin/utc";
import useLoadingStatus from "../../../../hooks/useLoadingStatus";
// import { Invoice, Project, ProjectRole } from "../../../../API";
import ColumnChart from "../ColumnChart";
import { LoadingOverlay, NativeSelect } from "@mantine/core";
// import { nFormatter } from "../../functions/formatFunctions";
import WidgetFrame from "../../WidgetFrame";
import { createFilterFunction } from "../../functions/createFilterFunction";

// Extend dayjs with the required plugins
dayjs.extend(isBetween);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(utc);

type Frequency = "Weekly" | "Monthly";

const formatDate = (date: Dayjs, unit: Frequency) => {
  return unit === "Weekly"
    ? `${date.format("YYYY")} ${date.week()}`
    : date.format("YYYY-MM");
};

function getDates(
  startDate: Dayjs | string,
  endDate: Dayjs | string,
  frequency: Frequency
): Dayjs[] {
  let current = dayjs(startDate).utc();
  const end = dayjs(endDate).utc();
  let dates: Dayjs[] = [];

  if (frequency === "Weekly") {
    // Find the first Monday on or after the start date
    if (current.weekday() !== 1) {
      current = current.add(1, "week").weekday(1);
    }

    // Collect all Mondays until the end date
    while (current.isBefore(end) || current.isSame(end, "day")) {
      dates.push(current);
      current = current.add(1, "week");
    }
  } else if (frequency === "Monthly") {
    // Collect the last date of each month until the end date
    while (current.isBefore(end) || current.isSame(end, "day")) {
      dates.push(current.endOf("month"));
      current = current.add(1, "month").startOf("month");
    }
  }

  //   const formatedEnd = formatDate(end, frequency);
  //   if (!dates.includes(formatedEnd)) {
  //     dates.push(formatedEnd);
  //   }

  return dates;
}

export function ProjectRoleSummary({
  dashboardFilterObjectList,
  updateDashboardFilter,
  globalFilteredData,
  comparisonGlobalFilteredData,
  comparisonFieldString,
  startDate,
  endDate,
}: any) {
  const {
    dataObject: { projectRoles = [] },
    currentProgrammes,
  } = useContext(AppContext);

  const loading = useLoadingStatus(["projectRoles"]);

  const localFilterFunction = useMemo(
    () =>
      createFilterFunction(
        [dashboardFilterObjectList],
        "startDate",
        startDate,
        endDate
      ),
    [dashboardFilterObjectList, startDate, endDate]
  );

  const filteredProjectRoles = useMemo(() => {
    return projectRoles
      .filter((projectRole: any) =>
        currentProgrammes.includes(projectRole.programmeAccessString)
      )
      .filter(localFilterFunction);
  }, [projectRoles, localFilterFunction, currentProgrammes]);

  const tableData = filteredProjectRoles.reduce(
    (array: any[], projectRole: any) => {
      const newRow: any = [];
      array.push(newRow);
      return array;
    },
    []
  );

  return (
    <WidgetFrame title={"Project Role Explorer"}>
      <LoadingOverlay visible={loading} overlayOpacity={0} />
      {!loading && ( //@ts-ignore
        <div>{JSON.stringify(tableData)}</div>
      )}
    </WidgetFrame>
  );
}
