import { useContext, useEffect } from "react";
import AppContext from "../../../context/AppContext";

export function useTenantQuestion({
  fieldName,
  form,
}: {
  fieldName: string;
  form: any;
}) {
  const { currentTenant } = useContext(AppContext);

  useEffect(() => {
    form.setValues({ [fieldName]: currentTenant });
  }, [currentTenant, fieldName]);

  return { currentTenant };
}
