import React from "react";
import styles from "./task-list-header.module.css";
import { useMantineTheme } from "@mantine/core";

export const TaskListHeaderDefault: React.FC<{
  headerHeight: number;
  rowWidth: string;
  fontFamily: string;
  fontSize: string;
}> = ({ headerHeight, fontFamily, fontSize, rowWidth }) => {
  const theme = useMantineTheme();
  return (
    <div
      className={styles.ganttTable}
      style={{
        fontFamily: fontFamily,
        fontSize: fontSize,
        borderRight: "#e6e4e4 solid 1px",
        position: "sticky",
        top: 0,
        backgroundColor: theme.colorScheme === "dark" ? "black" : "white",
      }}
    >
      <div
        className={styles.ganttTable_Header}
        style={{
          height: headerHeight - 2,
        }}
      >
        <div
          className={styles.ganttTable_HeaderItem}
          style={{
            // minWidth: rowWidth,
            // maxWidth: rowWidth,
            minWidth: 300,
            maxWidth: 300,
          }}
        >
          &nbsp;Name
        </div>
        <div
          className={styles.ganttTable_HeaderSeparator}
          style={{
            height: headerHeight * 0.5,
            marginTop: headerHeight * 0.2,
          }}
        />
        <div
          className={styles.ganttTable_HeaderItem}
          style={{
            // minWidth: rowWidth,
            // maxWidth: rowWidth,
            minWidth: 90,
            maxWidth: 90,
          }}
        >
          &nbsp;From
        </div>
        <div
          className={styles.ganttTable_HeaderSeparator}
          style={{
            height: headerHeight * 0.5,
            marginTop: headerHeight * 0.25,
          }}
        />
        <div
          className={styles.ganttTable_HeaderItem}
          style={{
            // minWidth: rowWidth,
            // maxWidth: rowWidth,
            minWidth: 90,
            maxWidth: 90,
          }}
        >
          &nbsp;To
        </div>
      </div>
    </div>
  );
};
