import dayjs from "dayjs";
import weekOfYear from "dayjs/plugin/weekOfYear";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import { networkDays } from "../../functions/dateFunctions";

dayjs.extend(weekOfYear);
dayjs.extend(quarterOfYear);

dayjs.Ls.en.weekStart = 1;

export function getPeriodsBetween(
  start: Date | null,
  end: Date | null,
  period: "week" | "month" | "quarter"
) {
  if (!start || !end) return [];
  let current = dayjs(start).startOf(period);
  const endDate = dayjs(end).endOf(period);
  const periods = [];

  while (current.isBefore(endDate)) {
    const weekStart = current.format("YYYY-MM-DD");
    const weekEnd = current.endOf(period).format("YYYY-MM-DD");

    const name = `${current.year()} ${
      current[period]() + (period === "month" ? 1 : 0)
    }`; //current.week();
    periods.push({ period: name, start: weekStart, end: weekEnd });
    //@ts-ignore
    current = current.add(1, period).startOf(period);
  }

  return periods;
}

export function calculateEmployeeSatisfaction({
  start,
  end,
  employeeSatisfactionRecords,
}: any) {
  const startObjectTime = new Date(start).getTime();
  const endObjectTime = new Date(end).getTime();

  const filtered = employeeSatisfactionRecords.filter((record: any) => {
    const { date } = record;
    const dateObjectTime = new Date(date).getTime();
    return dateObjectTime >= startObjectTime && dateObjectTime <= endObjectTime;
  });

  if (filtered.length === 0) return null;
  return (
    filtered.reduce((total: any, record: any) => {
      total += record.score;
      return total;
    }, 0) / filtered.length
  );
}

export function getUtilisation({
  person,
  start,
  end,
  status,
  includeLeave,
}: any) {
  const {
    projectAllocations: { items: projectAllocations },
    employeeSatisfactionRecords: { items: employeeSatisfactionRecords },
    annualLeave: { items: annualLeaveRecords },
    hoursPerWeek,
  } = person;

  const personWorkingHoursPerDay = hoursPerWeek / 5;

  const filteredProjectAllocations = projectAllocations.filter((item: any) => {
    return (
      item.startDate <= end &&
      item.endDate >= start &&
      item?.projectRole &&
      item?.projectRole?.unit !== "Project Outcome" &&
      (status === "" || item?.projectRole?.status === status)
    );
  });

  const filteredAnnualLeaveRecords = annualLeaveRecords.filter((item: any) => {
    return item.startDate <= end && item.endDate >= start;
  });

  const employeeSatisfaction = calculateEmployeeSatisfaction({
    start,
    end,
    employeeSatisfactionRecords,
  });

  const startObject = dayjs(start);
  const endObject = dayjs(end);

  const totalNetworkDays = networkDays({
    startDate: startObject,
    endDate: endObject,
  });

  const allocationMapFunction = (allocation: any) => {
    const {
      startDate: allocationStartDate,
      endDate: allocationEndDate,
      projectRole: {
        description,
        startDate: projectRoleStartDate,
        endDate: projectRoleEndDate,
        unit,
        plannedUnits,
        status,
        fte: baseFTE,
        project: { name },
      },
    } = allocation;

    const allocationStart = dayjs(allocationStartDate);
    const allocationEnd = dayjs(allocationEndDate);

    const maxStart = startObject.isAfter(allocationStart)
      ? startObject
      : allocationStart;

    const minEnd = endObject.isBefore(allocationEnd)
      ? endObject
      : allocationEnd;

    const projectRoleHours = (unit === "Days" ? 8 : 1) * plannedUnits;
    const projectRoleWorkingDays = networkDays({
      startDate: projectRoleStartDate,
      endDate: projectRoleEndDate,
    });
    const projectRoleHoursPerDay = projectRoleHours / projectRoleWorkingDays;

    const networkDaysInScope = networkDays({
      startDate: maxStart,
      endDate: minEnd,
    });

    const percentageInScope = networkDaysInScope / totalNetworkDays;

    const fte =
      (projectRoleHoursPerDay / personWorkingHoursPerDay) * percentageInScope;

    return {
      fte,
      projectName: name,
      description,
      status,
      hours: networkDaysInScope * projectRoleHoursPerDay,
    };
  };

  const annualLeaveMapFunction = (item: any) => {
    const { startDate, endDate, numberOfDays } = item;

    const allocationStart = dayjs(startDate);
    const allocationEnd = dayjs(endDate);

    const maxStart = startObject.isAfter(allocationStart)
      ? startObject
      : allocationStart;

    const minEnd = endObject.isBefore(allocationEnd)
      ? endObject
      : allocationEnd;

    const projectRoleHours = numberOfDays * 8;

    const projectRoleWorkingDays = networkDays({
      startDate: allocationStart,
      endDate: allocationEnd,
    });

    const projectRoleHoursPerDay = projectRoleHours / projectRoleWorkingDays;

    const networkDaysInScope = networkDays({
      startDate: maxStart,
      endDate: minEnd,
    });

    const percentageInScope = networkDaysInScope / totalNetworkDays;

    const fte =
      (projectRoleHoursPerDay / personWorkingHoursPerDay) * percentageInScope;

    return {
      fte,
      projectName: "Annual Leave",
      description: "",
      status,
      hours: networkDaysInScope * projectRoleHoursPerDay,
      startDate,
      endDate,
    };
  };

  const detail = filteredProjectAllocations.map(allocationMapFunction);

  const detailTotalObject = detail.reduce(
    (obj: any, al: any) => {
      obj.utilisation += al.fte;
      obj.totalHoursUtilised += al.hours;
      if (al.status === "Billable") {
        obj.billableHours += al.hours;
      } else {
        obj.nonBillableHours += al.hours;
      }
      return obj;
    },
    {
      utilisation: 0,
      totalHoursUtilised: 0,
      billableHours: 0,
      nonBillableHours: 0,
    }
  );

  const annualLeaveDetail = includeLeave
    ? filteredAnnualLeaveRecords.map(annualLeaveMapFunction)
    : [];

  const annualLeaveDetailTotalObject = annualLeaveDetail.reduce(
    (obj: any, al: any) => {
      obj.leaveHours += al.hours;
      return obj;
    },
    {
      leaveHours: 0,
    }
  );

  annualLeaveDetail.length > 0 && console.log({ annualLeaveDetailTotalObject });

  return {
    ...detailTotalObject,
    ...annualLeaveDetailTotalObject,
    totalHours: personWorkingHoursPerDay * totalNetworkDays,
    detail,
    annualLeaveDetail,
    employeeSatisfaction,
  };
}

export function createResourcingRow({
  person,
  periodObjectList,
  status,
  includeLeave,
}: any) {
  const object = {
    name: person.firstName + " " + person.lastName,
    jobTitle: person.position,
  };
  for (const { period, start, end } of periodObjectList) {
    //@ts-ignore
    object[period] = getUtilisation({
      person,
      start,
      end,
      status,
      includeLeave,
    });
  }
  return object;
}

export function getDefaultSort(periodObjectList: any) {
  return [{ id: `${periodObjectList[0].period}.utilisation`, desc: true }];
}
