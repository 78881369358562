import { useState, useContext } from "react";
import AppContext from "../../context/AppContext";
import { AppShell } from "@mantine/core";
import DashboardFooter from "./DashboardFooter";
import { SignIn } from "../auth/SignIn";
import Accounts from "../accounts/Accounts";
import People from "../people/People";
import Deliverables from "../deliverables/Deliverables";
import Changes from "../change-management/Changes";
import DataUpload from "../data-upload/DataUpload";
import SystemPreferences from "../system-preferences/SystemPreferences";
import { DashboardHeader } from "./DashboardHeader";
import { Navigation } from "./Navigation";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import Dashboard from "../dashboard/Dashboard/Dashboard";
// import Finance from "../finance/Finance";
import Projects from "../projects/Projects";
import Integrations from "../integrations/Integrations";
import TestDev from "../common/TestDev";
import ManageUsers from "../system-preferences/manage-users/ManageUsers";

import Verticals from "../verticals/Verticals";
import Programmes from "../programmes/Programmes";
import NewRisks from "../risks/Risks";
import Csats from "../csat/Csat";
import WeeklyUpdates from "../weekly-updates/WeeklyUpdates";
import Tenants from "../tenants/Tenants";
import Stages from "../stages/Stages";
import StatementsOfWork from "../statements-of-work/StatementsOfWork";

// import Mrr from "../temp/Mrr";
// import Cost from "../temp/Cost";
// import Pl from "../temp/Pl";

import ErrorBoundary from "../common/ErrorBoundary";
import EmployeeSatisfaction from "../employee-satisfaction/EmployeeSatisfaction";
import Invoices from "../invoices/Invoices";
import Pipelines from "../pipelines/Pipelines";
import ProjectAllocations from "../project-allocations/ProjectAllocations";
import ProjectRoles from "../project-roles/ProjectRoles";
import RoleTypes from "../role-type/RoleTypes";
import Tasks from "../task/Tasks";
import Timesheets from "../timesheets/Timesheets";
import Automations from "../automations/Automations";
import UserTypes from "../system-preferences/user-types/UserTypes";
import Home from "../home/Home";
import { CustomDatasets } from "../custom-datasets";
import AnnualLeave from "../annual-leave/AnnualLeave";

const crudScreens = [
  // { path: "integrations", Element: Integrations },
  { module: "Risks", path: "risks", Element: NewRisks },
  { module: "People", path: "people", Element: People },
  // { module: "PLs", path: "pls", Element: Pl },
  // { module: "MRRs", path: "mrrs", Element: Mrr },
  // { module: "Costs", path: "costs", Element: Cost },
  { module: "Projects", path: "projects", Element: Projects },
  { module: "Deliverables", path: "deliverables", Element: Deliverables },
  { module: "Verticals", path: "verticals", Element: Verticals },
  { module: "Accounts", path: "accounts", Element: Accounts },
  { module: "Tenants", path: "tenants", Element: Tenants },
  { module: "Programmes", path: "programmes", Element: Programmes },
  { module: "Changes", path: "change-management", Element: Changes },
  { module: "CSATs", path: "csats", Element: Csats },
  { module: "Weekly Updates", path: "weekly-updates", Element: WeeklyUpdates },
  // { module: "", path: "stages", Element: Stages },

  {
    module: "Employee Satisfaction",
    path: "employee-satisfaction",
    Element: EmployeeSatisfaction,
  },
  { module: "Invoices", path: "invoices", Element: Invoices },

  { module: "Pipelines", path: "pipeline", Element: Pipelines },
  {
    module: "Project Allocations",
    path: "project-allocations",
    Element: ProjectAllocations,
  },
  { module: "Project Roles", path: "project-roles", Element: ProjectRoles },

  { module: "Role Types", path: "role-types", Element: RoleTypes },
  { module: "Tasks", path: "tasks", Element: Tasks },
  // { module: "Tasks", path: "task-genie", Element: Automations },
  { module: "Time Sheets", path: "time-sheets", Element: Timesheets },
  { module: "Annual Leave", path: "annual-leave", Element: AnnualLeave },
  {
    module: "Statements of Work",
    path: "statements-of-work",
    Element: StatementsOfWork,
  },
];

export default function AppLayout(props: any) {
  const { signOut, user } = props;
  const {
    dashboards,
    groups,
    systemPreferenceObject = {},
  } = useContext(AppContext);
  const [opened, setOpened] = useState(false);

  const location = useLocation();

  const isSystemAdmin = groups.includes("System_Admin");

  const sortedDashboards = dashboards.sort((a: any, b: any) => {
    return a.order - b.order;
  });

  const defaultDashboardView = sortedDashboards[0]
    ? sortedDashboards[0].id
    : "";

  return (
    <AppShell
      navbarOffsetBreakpoint="sm"
      asideOffsetBreakpoint="sm"
      fixed
      navbar={<Navigation opened={opened} signOut={signOut} />}
      // aside={<div>Test</div>}
      footer={<DashboardFooter />}
      header={
        <DashboardHeader
          setOpened={setOpened}
          opened={opened}
          signOut={signOut}
          user={user}
        />
      }
    >
      {/* <div> */}
      {/* 
// @ts-ignore */}
      <ErrorBoundary key={location.pathname}>
        <Routes>
          <Route path="/about" element={<Home />} />
          {/* <Route path="/dashboard" element={<Dashboard />} /> */}
          <Route path="/dashboard/:tabValue" element={<Dashboard />} />
          <Route path="/signin" element={<SignIn />} />
          {/* <Route
              path="/customer-focus/:tabValue/*"
              element={<CustomerFocus />}
            /> */}
          <Route path="/data-upload" element={<DataUpload />} />
          {crudScreens.map((pathObject: any) => {
            const { path, Element, module } = pathObject;
            if (
              systemPreferenceObject &&
              systemPreferenceObject.modules[module] &&
              systemPreferenceObject.modules[module].active
            ) {
              return (
                <Route path={`/${path}`} element={<Element />} key={path}>
                  <Route path=":recordId" element={<Element />}>
                    <Route path=":tabPath" element={<Element />} />
                  </Route>
                </Route>
              );
            } else {
              return null;
            }
          })}
          {isSystemAdmin && (
            <Route path="/system-preferences" element={<SystemPreferences />} />
          )}
          {isSystemAdmin && (
            // <Route path="/integrations" element={<Integrations />} />
            <Route path={`/integrations`} element={<Integrations />}>
              <Route path=":recordId" element={<Integrations />}>
                <Route path=":tabPath" element={<Integrations />} />
              </Route>
            </Route>
          )}
          {isSystemAdmin && (
            <Route path={`/custom-datasets`} element={<CustomDatasets />}>
              <Route path=":recordId" element={<CustomDatasets />}>
                <Route path=":tabPath" element={<CustomDatasets />} />
              </Route>
            </Route>
          )}
          {isSystemAdmin && (
            <Route path={`/task-genie`} element={<Automations />}>
              <Route path=":recordId" element={<Automations />}>
                <Route path=":tabPath" element={<Automations />} />
              </Route>
            </Route>
          )}
          {isSystemAdmin && (
            <Route path={`/manage-users`} element={<ManageUsers />}>
              <Route path=":recordId" element={<ManageUsers />}>
                <Route path=":tabPath" element={<ManageUsers />} />
              </Route>
            </Route>
          )}
          {isSystemAdmin && (
            <Route path={`/user-types`} element={<UserTypes />}>
              <Route path=":recordId" element={<UserTypes />}>
                <Route path=":tabPath" element={<UserTypes />} />
              </Route>
            </Route>
          )}
          {isSystemAdmin && <Route path="/test-dev" element={<TestDev />} />}
          {isSystemAdmin && (
            <Route path={`/stages`} element={<Stages />}>
              <Route path=":recordId" element={<Stages />}>
                <Route path=":tabPath" element={<Stages />} />
              </Route>
            </Route>
          )}

          <Route
            path="/dashboard"
            element={
              <Navigate
                replace
                to={
                  defaultDashboardView
                    ? `/dashboard/${defaultDashboardView}`
                    : "/dashboard"
                }
              />
            }
          />
          <Route
            path="/"
            element={
              <Navigate
                replace
                to={
                  defaultDashboardView
                    ? `/dashboard/${defaultDashboardView}`
                    : "/dashboard"
                }
              />
            }
          />

          <Route path="/*" element={null} />
        </Routes>
      </ErrorBoundary>
      {/* </div> */}
    </AppShell>
  );
}
