import {
  Badge,
  Group,
  Title,
  Text,
  Card,
  SimpleGrid,
  Container,
  rem,
  createStyles,
} from "@mantine/core";
import { IconGauge, IconAffiliate, IconRobot } from "@tabler/icons-react";
// import classes from "./FeaturesCards.module.css";

const useStyles = createStyles((theme) => ({
  title: {
    fontSize: "rem(34px)",
    fontWeight: 900,

    "@media (max-width: $mantine-breakpoint-sm)": {
      fontSize: "rem(24px)",
    },
  },

  description: {
    maxWidth: "rem(600px)",
    margin: "auto",

    "&:after": {
      content: '""',
      display: "block",
      backgroundColor: "var(--mantine-color-lime-filled)",
      width: "rem(45px)",
      height: "rem(2px)",
      marginTop: "var(--mantine-spacing-sm)",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },

  card: {
    border:
      "rem(1px) solid light-dark(var(--mantine-color-gray-1), var(--mantine-color-dark-5))",
  },

  cardTitle: {
    "&::after": {
      content: '""',
      display: "block",
      backgroundColor: "var(--mantine-color-lime-filled)",
      width: "rem(45px)",
      height: "rem(2px)",
      marginTop: "var(--mantine-spacing-sm)",
    },
  },
}));

const mockdata = [
  {
    title: "Integrations",
    description:
      "With smart/sync, you are able to easily integrate with your existing tools on an asynchronous basis, ingest data from Excel/Sheets or use our purpose built in data entry for multiple PMO services and functions.",
    icon: IconAffiliate,
  },
  {
    title: "Dashboards",
    description:
      "Account level dashboards provide program and executive wide views that shine a light on your projects, and summarise across multiple geographies or industry verticals. Data-driven informed decisions, lead to Actionable Insights.",
    icon: IconGauge,
  },
  {
    title: "Automations",
    description:
      "Built in automation gives proactive governance mechanisms that enable tactical accountability whilst providing executive visibility.  Speed and accuracy are critical to accelerated growth and our automation processes give you the power to scale your business.",
    icon: IconRobot,
  },
];

export function FeatureSection() {
  //   const theme = useMantineTheme();
  const { classes, theme } = useStyles();
  const features = mockdata.map((feature) => (
    <Card
      key={feature.title}
      shadow="md"
      radius="md"
      className={classes.card}
      padding="xl"
    >
      <feature.icon
        style={{ width: rem(50), height: rem(50) }}
        stroke={2}
        color={theme.colors.lime[6]}
      />
      <Text fz="lg" fw={500} className={classes.cardTitle} mt="md">
        {feature.title}
      </Text>
      <Text fz="sm" c="dimmed" mt="sm">
        {feature.description}
      </Text>
    </Card>
  ));

  return (
    <Container size="lg" py={"5em"}>
      <Group position="center">
        <Badge variant="filled" size="lg" color="lime">
          PMO as a Service
        </Badge>
      </Group>

      <Title order={2} className={classes.title} ta="center" mt="sm">
        Turbocharge the tools you already use
      </Title>

      <Text c="dimmed" className={classes.description} ta="center" mt="md">
        Whether you have the right tools in place or not, smart/sync gives you
        the power to bring disparate or legacy systems together to create a true
        PMO tool that provides visibility, a single source of truth and will
        help you drive your business to success.
      </Text>

      <SimpleGrid
        cols={3}
        breakpoints={[
          { maxWidth: "md", cols: 3, spacing: "md" },
          { maxWidth: "sm", cols: 1, spacing: "sm" },
        ]}
        spacing="xl"
        mt={50}
      >
        {features}
      </SimpleGrid>
    </Container>
  );
}
