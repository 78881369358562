import { Chip, Group, Input, useMantineTheme } from "@mantine/core";
import { useContext } from "react";
import AppContext from "../../context/AppContext";
import { CustomDataset } from "../../API";
import useFetchData from "../../hooks/useFetchData";

export default function ModuleQuestion(props: any) {
  useFetchData(["Custom Datasets"]);
  const { form } = props;
  const { systemPreferenceObject = {}, dataObject = {} } =
    useContext(AppContext);
  const { customDatasets = [] } = dataObject ?? {};
  const { modules } = systemPreferenceObject ?? {};
  const theme = useMantineTheme();

  if (!modules) return null;
  return (
    <Input.Wrapper
      label="Module"
      required
      description={"Which module would you like to the integration to populate"}
    >
      <Chip.Group
        multiple={false}
        value={form.values.module}
        onChange={(value) => form.setValues({ module: value })}
      >
        <Group position="center" mt="md">
          {Object.keys(modules)
            .filter((key: string) => modules[key].active)
            .map((key: string) => {
              return (
                <Chip
                  value={modules[key].dataKey}
                  variant={"filled"}
                  color={theme.primaryColor}
                  key={key}
                >
                  {key}
                </Chip>
              );
            })}
          {customDatasets.map((customDataset: CustomDataset) => {
            return (
              <Chip
                value={customDataset.id}
                variant={"filled"}
                color={theme.primaryColor}
              >
                {customDataset.name}
              </Chip>
            );
          })}
        </Group>
      </Chip.Group>
    </Input.Wrapper>
  );
}
