import common from "./commonSchema";
import { ISchema } from "../SchemaTypes";
import {
  formatDateToYYYYQQ,
  formatDateToYYYYMM,
  formatDateToYYYY,
} from "../../components/common/functions/dateFunctions";
import { formatCurrency } from "../../components/common/functions/formatFunctions";

const pipelineSchema: ISchema = {
  integrationSystemId: {
    title: "Integration System ID",
    field: "integrationSystemId",
    excludeFromForm: true,
    excludeFromChartBuilder: true,
    formOptions: {
      required: false,
    },
  },

  vertical: {
    title: "Vertical",
    field: "vertical",
    accessorFunction: (value: any) => value?.vertical?.name,
    formOptions: { required: false },
    excludeFromForm: true,
    excludeFromChartBuilder: true,
    type: "belongsTo",
  },
  verticalName: {
    title: "Vertical Name",
    field: "verticalName",
    accessorFunction: (value: any) => value?.vertical?.name,
    formOptions: { required: false },
    excludeFromForm: true,
  },
  verticalPipelinesId: {
    title: "Vertical ID",
    field: "verticalPipelinesId",
    isKey: true,
    formOptions: {
      required: false,
      options: {
        dataSource: "verticals",
        addBlank: true,
        accessorFunction: (dataSource: any) =>
          dataSource.map((row: any) => {
            return { value: row.id, label: row.name };
          }),
      },
      order: 1,
    },
    type: "select",
  },

  account: {
    title: "Account",
    field: "account",
    accessorFunction: (value: any) => value?.account?.name,
    formOptions: { required: false },
    excludeFromForm: true,
    excludeFromChartBuilder: true,
    type: "belongsTo",
  },
  accountName: {
    title: "Account Name",
    field: "accountName",
    accessorFunction: (value: any) => value?.account?.name,
    formOptions: { required: false },
    excludeFromForm: true,
  },
  accountPipelinesId: {
    title: "Account ID",
    field: "accountPipelinesId",
    isKey: true,
    formOptions: {
      required: false,
      options: {
        dataSource: "accounts",
        addBlank: true,
        accessorFunction: (dataSource: any) =>
          dataSource.map((row: any) => {
            return { value: row.id, label: row.name };
          }),
      },
      order: 1,
    },
    type: "select",
  },
  stage: {
    title: "Stage",
    field: "stage",
    // accessorFunction: (value: any) => value?.stage?.name,
    // formatFunction: (value: any) => value,
    excludeFromDataView: true,
    formOptions: { required: false },
    excludeFromForm: true,
    excludeFromChartBuilder: true,
    type: "belongsTo",
  },

  stageName: {
    title: "Stage Name",
    field: "stageName",
    accessorFunction: (value: any) => value?.stage?.name,
    formOptions: { required: false },
    excludeFromForm: true,
  },

  weightedAmount: {
    title: "Weighted Amount",
    field: "weightedAmount",
    accessorFunction: (value: any) =>
      (value?.stage?.probability ?? 1) * (value?.amount ?? 0),
    formOptions: { required: false },
    excludeFromForm: true,
    type: "number",
    formatFunction: (value: any) => formatCurrency(value, "GBP", 0),
  },

  stagePipelinesId: {
    title: "Stage ID",
    field: "stagePipelinesId",
    isKey: true,
    formOptions: {
      required: false,
      options: {
        dataSource: "stages",
        addBlank: true,
        accessorFunction: (dataSource: any) =>
          dataSource
            ?.sort((a: any, b: any) => a.order - b.order)
            .map((row: any) => {
              return { value: row.id, label: row.name };
            }) ?? [],
      },
      order: 1,
    },
    type: "select",
  },

  amount: {
    title: "Amount",
    field: "amount",
    type: "number",
    formatFunction: (value: any) => formatCurrency(value, "GBP", 0),
    formOptions: {
      required: false,
    },
  },
  closeDate: {
    title: "Close Date",
    field: "closeDate",
    type: "date",
    formOptions: {
      required: false,
    },
  },
  closeMonth: {
    title: "Close Month",
    field: "closeMonth",
    accessorFunction: (row: any) => formatDateToYYYYMM(new Date(row.closeDate)),
    formOptions: { required: false },
    excludeFromForm: true,
  },
  closeQuarter: {
    title: "Close Quarter",
    field: "closeQuarter",
    accessorFunction: (row: any) => formatDateToYYYYQQ(new Date(row.closeDate)),
    formOptions: { required: false },
    excludeFromForm: true,
  },
  closeYear: {
    title: "Close Year",
    field: "closeYear",
    accessorFunction: (row: any) => formatDateToYYYY(new Date(row.closeDate)),
    formOptions: { required: false },
    excludeFromForm: true,
  },
  closedNotes: {
    title: "Closed Notes",
    field: "closedNotes",
    type: "text",
    formOptions: {
      required: false,
    },
  },
  closingDate: {
    title: "Closing Date",
    field: "closingDate",
    type: "date",
    formOptions: {
      required: false,
    },
  },
  currency: {
    title: "Currency",
    field: "currency",
    type: "text",
    formOptions: {
      required: false,
    },
  },
  dealName: {
    title: "Deal Name",
    field: "dealName",
    type: "text",
    formOptions: {
      required: false,
    },
  },
  description: {
    title: "Description",
    field: "description",
    type: "textarea",
    formOptions: {
      required: false,
    },
  },
  grossMarginLength: {
    title: "Gross Margin Length",
    field: "grossMarginLength",
    type: "number",
    formOptions: {
      required: false,
    },
  },
  grossMarginPerMonth: {
    title: "Gross Margin Per Month",
    field: "grossMarginPerMonth",
    type: "number",
    formOptions: {
      required: false,
    },
  },
  incomeType: {
    title: "Income Type",
    field: "incomeType",
    type: "text",
    formOptions: {
      required: false,
    },
  },
  intro: {
    title: "Introduction",
    field: "intro",
    type: "text",
    formOptions: {
      required: false,
    },
  },
  managingPartner: {
    title: "Managing Partner",
    field: "managingPartner",
    type: "text",
    formOptions: {
      required: false,
    },
  },
  numberOfDeliveryDays: {
    title: "Number Of Delivery Days",
    field: "numberOfDeliveryDays",
    type: "number",
    formOptions: {
      required: false,
    },
  },
  opportunityLead: {
    title: "Opportunity Lead",
    field: "opportunityLead",
    type: "text",
    formOptions: {
      required: false,
    },
  },
  pipeline: {
    title: "Pipeline",
    field: "pipeline",
    type: "text",
    formOptions: {
      required: false,
    },
  },
  serviceGoLiveDate: {
    title: "Service Go Live Date",
    field: "serviceGoLiveDate",
    type: "date",
    formOptions: {
      required: false,
    },
  },
  sourceOfferingOpportunity: {
    title: "Source Offering Opportunity",
    field: "sourceOfferingOpportunity",
    type: "text",
    formOptions: {
      required: false,
    },
  },
  totalGrossProfitValue: {
    title: "Total Gross Profit Value",
    field: "totalGrossProfitValue",
    type: "text",
    formOptions: {
      required: false,
    },
  },
  totalRevenueValue: {
    title: "Total Revenue Value",
    field: "totalRevenueValue",
    type: "text",
    formOptions: {
      required: false,
    },
  },
  typeOfSale: {
    title: "Type Of Sale",
    field: "typeOfSale",
    type: "text",
    formOptions: {
      required: false,
    },
  },

  sowRecords: {
    title: "SOW Records",
    field: "sowRecords",
    type: "hasMany",
    accessorFunction: (row: any) => row.sowRecords.length,
    excludeFromForm: true,
    formOptions: {
      required: true,
    },
  },
  ...common, // Assuming "common" is a shared schema between different types
};

export default pipelineSchema;
