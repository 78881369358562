import { useContext } from "react";
import CrudScreen from "../common/CrudScreen";
import AppContext from "../../context/AppContext";
import { accountsSchema } from "../../context/dataSchema";
import {
  createAccount,
  updateAccount,
  deleteAccount,
} from "../../graphql/mutations";
import RecordActivity from "../common/RecordActivity";
import CustomerConfigRow from "../system-preferences/CustomerConfigRow";

export default function Accounts() {
  const { dataObject } = useContext(AppContext);
  const { accounts = [] } = dataObject;

  const recordScreenTabs = {
    edit: { tabName: "Form" },
    appearance: { tabName: "Appearance", component: CustomerConfigRow },
    activity: { tabName: "Record Activity", component: RecordActivity },
  };

  const screenProps = {
    data: accounts,
    schema: accountsSchema,
    title: "Accounts",
    quickStats: null,
    tableColumns: ["name"],
    filterFields: [],
    recordName: "Account",
    pagePath: "accounts",
    dataObjectKey: "accounts",
    recordScreenTabs,
    formProps: {
      createQuery: createAccount,
      updateQuery: updateAccount,
      deleteQuery: deleteAccount,
      dataObjectKey: "accounts",
    },
    modulesToLoad: ["Verticals", "Accounts"],
  };
  return <CrudScreen {...screenProps} />;
}
