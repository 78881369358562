import { useContext } from "react";
import CrudScreen from "../common/CrudScreen";
import AppContext from "../../context/AppContext";
import { stagesSchema as schema } from "../../context/dataSchema";
import {
  createStage as createQuery,
  updateStage as updateQuery,
  deleteStage as deleteQuery,
} from "../../graphql/mutations";

export default function Stages() {
  const { dataObject } = useContext(AppContext);
  const { stages: data = [] } = dataObject;

  const screenProps = {
    data,
    schema,
    title: "Stages",
    quickStats: null,
    tableColumns: ["name", "order"],
    filterFields: [],
    recordName: "Stage",
    pagePath: "stages",
    dataObjectKey: "stages",
    formProps: {
      createQuery,
      updateQuery,
      deleteQuery,
      dataObjectKey: "stages",
    },
    modulesToLoad: ["Tenants", "Stages"],
  };
  return <CrudScreen {...screenProps} />;
}
