import React, { useCallback, useMemo } from "react";
import {
  ResponsiveContainer,
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  ZAxis,
  CartesianGrid,
  RectangleProps,
  Scatter,
  Tooltip,
} from "recharts";
import { getStatistics } from "../../functions/getStatistics";
import { useMantineTheme } from "@mantine/core";
import {
  formatCategoryAxis,
  formatNumericAxis,
  RenderCustomizedLabel,
  CustomTooltip,
  getChartColors,
} from "../ChartUtilities";
import { BoxWhiskerTooltip } from "./BoxWhiskerToolTip";

const DotBar = (props: RectangleProps) => {
  const { x, y, width, height } = props;
  const theme = useMantineTheme();

  if (x == null || y == null || width == null || height == null) {
    return null;
  }

  const color =
    theme.colorScheme === "dark" ? theme.colors.gray[6] : theme.colors.dark[1];

  return (
    <line
      x1={x + width / 2}
      y1={y + height}
      x2={x + width / 2}
      y2={y}
      stroke={color}
      strokeWidth={3}
      stroke-dasharray={"5"}
    />
  );
};

const HorizonBar = (props: RectangleProps) => {
  const { x, y, width, height } = props;
  const theme = useMantineTheme();

  if (x == null || y == null || width == null || height == null) {
    return null;
  }

  const color =
    theme.colorScheme === "dark" ? theme.colors.gray[6] : theme.colors.dark[1];

  return (
    <line x1={x} y1={y} x2={x + width} y2={y} stroke={color} strokeWidth={3} />
  );
};

type BoxPlot = {
  min: number;
  lowerQuartile: number;
  median: number;
  upperQuartile: number;
  max: number;
  average?: number;
  name: string;
};

type BoxPlotData = {
  Minimum: number;
  "Lower Quartile": number;
  Median: number;
  "Upper Quartile": number;
  Maximum: number;
  Mean?: number;
  size: number; // for average dot size
  name: string;
};

const useBoxPlot = (boxPlots: BoxPlot[]): BoxPlotData[] => {
  const data = useMemo(
    () =>
      boxPlots.map((v) => {
        return {
          Minimum: v.min,
          "Lower Quartile": v.lowerQuartile - v.min,
          Median: v.median - v.lowerQuartile,
          "Upper Quartile": v.upperQuartile - v.median,
          Maximum: v.max - v.upperQuartile,
          Mean: v.average,
          size: 200,
          name: v.name,
        };
      }),
    [boxPlots]
  );

  return data;
};

const BoxWhisker = ({
  grid,
  includeXAxis,
  formatCategoryAxis,
  xAxisFontSize,
  includeYAxis,
  yAxisWidth,
  yAxisFontSize,
  formatFunction,
  disableTooltip,
  comparisonFieldString,
  data,
  rows,
  values,
}: any) => {
  const theme = useMantineTheme();

  const group = rows[0];
  const value = values[0];

  const mapFunction = useCallback(
    (row: any) => {
      return Number(row[value]);
    },
    [value]
  );

  const processedData = useMemo(() => {
    if (group) {
      //@ts-ignore
      const grouped = Object.groupBy(data, (row: any) => row[group]);

      return Object.keys(grouped).map((key: any) => {
        const numberArray = grouped[key]?.map(mapFunction);

        //@ts-ignore
        return getStatistics(numberArray, key);
      });
    } else {
      const numberArray = data.map(mapFunction);
      return [getStatistics(numberArray, "")];
    }
  }, [data, group, mapFunction]);

  const inputData = useBoxPlot(processedData);

  return (
    <ResponsiveContainer width={"100%"} minHeight={"100px"}>
      <ComposedChart data={inputData}>
        {grid && (
          <CartesianGrid
            strokeDasharray="3 3"
            stroke={
              theme.colorScheme === "dark"
                ? theme.colors.gray[7]
                : theme.colors.gray[4]
            }
          />
        )}
        <Bar
          stackId={"a"}
          dataKey={"Minimum"}
          fill={"none"}
          activeBar={false}
        />
        <Bar
          stackId={"a"}
          dataKey={"bar"}
          shape={<HorizonBar />}
          activeBar={false}
        />
        <Bar
          stackId={"a"}
          dataKey={"Lower Quartile"}
          shape={<DotBar />}
          activeBar={false}
        />
        <Bar
          stackId={"a"}
          dataKey={"Median"}
          fill={theme.colors[theme.primaryColor][7]}
        />
        <Bar
          stackId={"a"}
          dataKey={"bar"}
          shape={<HorizonBar />}
          activeBar={false}
        />
        <Bar
          stackId={"a"}
          dataKey={"Upper Quartile"}
          fill={theme.colors[theme.primaryColor][7]}
        />
        <Bar
          stackId={"a"}
          dataKey={"Maximum"}
          shape={<DotBar />}
          activeBar={false}
        />
        <Bar
          stackId={"a"}
          dataKey={"bar"}
          shape={<HorizonBar />}
          activeBar={false}
        />
        <ZAxis type="number" dataKey="size" range={[0, 100]} />

        <Scatter
          dataKey="Mean"
          fill={theme.colors[theme.primaryColor][9]}
          stroke={theme.colors.dark[4]}
        />
        {!disableTooltip && (
          <Tooltip
            // cursor={false}
            cursor={{ fill: "transparent" }}
            content={
              <BoxWhiskerTooltip
                formatFunction={formatFunction}
                comparisonFieldString={comparisonFieldString}
                includeTotal={false}
              />
            }
          />
        )}
        {includeXAxis && (
          <XAxis
            dataKey="name"
            type="category"
            tickFormatter={formatCategoryAxis}
            tick={{
              fontSize: `${xAxisFontSize}em`,
              fill: theme.colorScheme === "dark" ? "#ffffff" : "#666",
            }}
          />
        )}
        {includeYAxis && (
          <YAxis
            type="number"
            width={yAxisWidth}
            tickFormatter={(value) => formatNumericAxis(value, formatFunction)}
            tick={{
              fontSize: `${yAxisFontSize}em`,
              fill: theme.colorScheme === "dark" ? "#ffffff" : "#666",
            }}
            // domain={[yAxisMin ?? 0, yAxisMax ?? "auto"]}
          />
        )}
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export { BoxWhisker };
