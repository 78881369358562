import { Checkbox, ArrowUpRight, ArrowDownRight } from "tabler-icons-react";
import { Grid, Group, Text, Paper, createStyles } from "@mantine/core";

function nFormatter(num: any, digits: any) {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
    : "0";
}

const useStyles = createStyles((theme) => ({
  root: {
    //padding: theme.spacing.xl * 1.5,
  },

  value: {
    fontSize: 24,
    fontWeight: 700,
    lineHeight: 1,
  },

  diff: {
    lineHeight: 1,
    display: "flex",
    alignItems: "center",
  },

  icon: {
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[3]
        : theme.colors.gray[4],
  },

  title: {
    fontWeight: 700,
    textTransform: "uppercase",
  },

  paper: {
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[6]
        : theme.colors.gray[0],
  },
}));

export default function ChangeQuickStats(props: any) {
  const { classes } = useStyles();

  const { changes } = props;

  return (
    <div>
      <Grid>
        <Grid.Col xs={12} md={4}>
          <Paper withBorder p="md" radius="md" className={classes.paper}>
            <Group position="apart">
              <Text size="xs" color="dimmed" className={classes.title}>
                Awaiting Delivery Review
              </Text>
              <Checkbox className={classes.icon} size={22} />
            </Group>

            <Group align="flex-end" spacing="xs" mt={25}>
              <Text className={classes.value}>
                {
                  changes.filter(
                    (change: any) => change.deliveryApproval === "Pending"
                  ).length
                }
              </Text>
            </Group>
          </Paper>
        </Grid.Col>
        <Grid.Col xs={12} md={4}>
          <Paper withBorder p="md" radius="md" className={classes.paper}>
            <Group position="apart">
              <Text size="xs" color="dimmed" className={classes.title}>
                Awaiting SLT Review
              </Text>
              <Checkbox className={classes.icon} size={22} />
            </Group>

            <Group align="flex-end" spacing="xs" mt={25}>
              <Text className={classes.value}>
                {
                  changes.filter(
                    (change: any) => change.sltApproval === "Pending"
                  ).length
                }
              </Text>
            </Group>
          </Paper>
        </Grid.Col>
      </Grid>
    </div>
  );
}
