import { API } from "aws-amplify";
import { updateIntegrationData } from "../../../graphql/queries";
export async function updateIntegration(
  integrationObject: any,
  actionObject: {
    action: string;
    deleteAllRecords?: boolean;
    deleteIntegration?: boolean;
  },
  data: any = null
) {
  const response = await API.graphql({
    query: updateIntegrationData,
    authMode: "AMAZON_COGNITO_USER_POOLS",
    variables: {
      input: JSON.stringify({ integrationObject, actionObject, data }),
    },
  });

  return response;
}
