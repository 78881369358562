import { DataSourceStrings } from "../types/systemPreferencesTypes";

export const dataKeyLookup: { [moduleName: string]: DataSourceStrings } = {
  MRRs: "mrrs",
  People: "people",
  Projects: "projects",
  Costs: "costs",
  PLs: "pls",
  Risks: "risks",
  CSATs: "csats",
  "Weekly Updates": "weeklyUpdates",
  Accounts: "accounts",
  Verticals: "verticals",
  Programmes: "programmes",
  //@ts-ignore
  Integrations: "integrations",
  "Statements of Work": "statements",
  "Change Requests": "changes",
  Tenants: "tenants",
  Stages: "stages",

  Invoices: "invoices",
  "Employee Satisfaction": "employeeSatisfaction",
  Pipelines: "pipelines",
  "Project Allocations": "projectAllocations",
  "Project Roles": "projectRoles",
  Regions: "regions",
  "Role Types": "roleTypes",
  Tasks: "tasks",
  "Time Sheets": "timesheets",
  Deliverables: "deliverables",
  Periods: "periods",
  "User Types": "userTypes",
  "Custom Datasets": "customDatasets",
  "Annual Leave": "annualLeaves",
};

export const dataKeyToModule: { [key: string]: string } = {
  mrrs: "MRRs",
  costs: "Costs",
  pls: "PLs",
  projects: "Projects",
  risks: "Risks",
  statements: "Statements of Work",
  csats: "CSATs",
  accounts: "Accounts",
  people: "People",
  deliverables: "Deliverables",
  verticals: "Verticals",
  programmes: "Programmes",
  invoices: "Invoices",
  employeeSatisfaction: "Employee Satisfaction",
  pipelines: "Pipelines",
  projectAllocations: "Project Allocations",
  projectRoles: "Project Roles",
  roleAllocations: "Role Allocations",
  roleTypes: "Role Types",
  tasks: "Tasks",
  tenants: "Tenants",
  timesheets: "Time Sheets",
  annualLeaves: "Annual Leave",
  customDatasets: "Custom Datasets",
};

export type IFilterKeyLookup = {
  mrrs: "programmeMrrsId";
  pls: "programmePlsId";
  costs: "programmeCostsId";
  risks: "risks";
  changes: "programmeChangesId";
  people: "people";
  csats: "programmeCsatsId";
  weeklyUpdates: "programmeWeeklyUpdatesId";
  projects: "programmeProjectsId";
  tasks: "programmeAccessString";
  statements: "programmeAccessString";
  accounts: "accounts";
  verticals: "verticals";
  deliverables: "deliverables";
  employeeSatisfaction: "employeeSatisfaction";
  pipelines: "pipelines";
  roleTypes: "roleTypes";
  timesheets: "timesheets";
};

export const filterKeyLookup: IFilterKeyLookup = {
  mrrs: "programmeMrrsId",
  pls: "programmePlsId",
  costs: "programmeCostsId",
  risks: "risks",
  changes: "programmeChangesId",
  people: "people",
  csats: "programmeCsatsId",
  weeklyUpdates: "programmeWeeklyUpdatesId",
  projects: "programmeProjectsId",
  tasks: "programmeAccessString",
  statements: "programmeAccessString",
  accounts: "accounts",
  verticals: "verticals",
  deliverables: "deliverables",
  employeeSatisfaction: "employeeSatisfaction",
  pipelines: "pipelines",
  roleTypes: "roleTypes",
  timesheets: "timesheets",
};
