import { createStyles, getStylesRef } from "@mantine/core";
import { useContext, useMemo } from "react";
import AppContext from "../../../../context/AppContext";
import { AccountTile } from "./AccountTile";
import WidgetFrame from "../../WidgetFrame";
import { Carousel } from "@mantine/carousel";
import useLoadingStatus from "../../../../hooks/useLoadingStatus";
import { createFilterFunction } from "../../functions/createFilterFunction";
import useFetchData from "../../../../hooks/useFetchData";

const useStyles = createStyles(() => ({
  controls: {
    ref: getStylesRef("controls"),
    transition: "opacity 150ms ease",
    opacity: 0,
  },

  root: {
    "&:hover": {
      [`& .${getStylesRef("controls")}`]: {
        opacity: 1,
      },
    },
  },
}));

export default function AccountSummary(props: any) {
  const { dashboardFilterObjectList, updateDashboardFilter } = props;
  const { classes } = useStyles();
  const {
    dataObject: { accounts = [], pls = [], programmes = [], risks = [] },
    currentTenant,
    currentAccount,
    setCurrentAccount,
  } = useContext(AppContext);

  useFetchData(["Accounts", "PLs", "Programmes", "Risks"], ["Live"]);

  const localFilterFunction = useMemo(
    () => createFilterFunction([dashboardFilterObjectList]),
    [dashboardFilterObjectList]
  );

  const loading = useLoadingStatus(["accounts", "pls", "programmes", "risks"]);

  const filteredAccounts =
    currentAccount === "All"
      ? accounts
      : accounts.filter((account: any) => {
          return account.id === currentAccount;
        });

  const filteredPls = useMemo(() => {
    return pls
      .filter((pl: any) => pl.period === "Live" && pl.status !== "Closed")
      .filter(localFilterFunction);
  }, [pls, localFilterFunction]);

  const plObject = useMemo(
    () =>
      filteredPls.reduce((object: any, pl: any) => {
        if (!object[pl.programme.accountProgrammesId]) {
          object[pl.programme.accountProgrammesId] = {
            total: 0,
            paid: 0,
            consumed: 0,
          };
        }
        object[pl.programme.accountProgrammesId].total += pl.revenueSoldUSD;
        object[pl.programme.accountProgrammesId].consumed +=
          pl.revenueRecognizedUSD;
        object[pl.programme.accountProgrammesId].paid += pl.billedRedeemedUSD;
        return object;
      }, {}),
    [filteredPls]
  );

  return (
    <WidgetFrame title={"Accounts Summary (Open Engagements)"}>
      <Carousel
        mx="auto"
        align="start"
        slideGap="md"
        controlsOffset="xs"
        controlSize={21}
        px={"md"}
        // withIndicators
        classNames={classes}
      >
        {filteredAccounts
          .sort((a: any, b: any) => {
            return plObject[a.id]?.total ?? 0 - plObject[b.id]?.total ?? 0;
          })
          .reverse()
          .map((account: any) => {
            return (
              <Carousel.Slide size={400} key={account.id} p={5}>
                <AccountTile
                  name={account.name}
                  id={account.id}
                  currentAccount={currentAccount}
                  setCurrentAccount={setCurrentAccount}
                  loading={loading}
                  tenant={currentTenant}
                  totalsObject={plObject[account.id] ?? null}
                  completed={7200000}
                  total={100}
                  stats={[
                    {
                      value: programmes.filter(
                        (prog: any) => prog.accountProgrammesId === account.id
                      ).length,
                      label: "Programmes",
                    },
                    {
                      value: risks.filter(
                        (risk: any) =>
                          risk.programme.accountProgrammesId === account.id
                      ).length,
                      label: "Risks",
                    },
                  ]}
                />
              </Carousel.Slide>
            );
          })}
      </Carousel>
    </WidgetFrame>
  );
}
