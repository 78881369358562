import common from "./commonSchema";
import { ISchema } from "../SchemaTypes";
import { formatDateString } from "../../components/common/functions/formatFunctions";

const weeklyUpdatesSchema: ISchema = {
  programme: {
    title: "Programme",
    field: "programme",
    formatFunction: (value: any) => value?.name,
    formOptions: { required: true },
    excludeFromForm: true,
    type: "belongsTo",
  },
  programmeWeeklyUpdatesId: {
    title: "Programme",
    field: "programmeWeeklyUpdatesId",
    formOptions: {
      required: true,
      options: {
        dataSource: "programmes",
        addBlank: true,
        accessorFunction: (dataSource: any) =>
          dataSource.map((row: any) => {
            return { value: row.id, label: row.name };
          }),
      },
      order: 1,
    },
    type: "select",
  },

  score: {
    title: "Score",
    field: "score",
    formOptions: {
      required: true,
      min: 0,
      max: 10,
      step: 1,
      description: "Between 0 and 10",
    },

    type: "number",
  },
  comments: {
    title: "Comments",
    field: "comments",
    type: "textarea",
    formOptions: { required: false },
  },
  date: {
    title: "Date",
    field: "date",
    type: "date",
    formatFunction: formatDateString,
    formOptions: { required: true, defaultValue: new Date() },
  },
  ...common,
};

export default weeklyUpdatesSchema;
