import { PricingCards } from "./PricingCards";
import {
  //   ActionIcon,
  Box,
  //   Flex,
  //   Group,
  //   Switch,
  //   Text,
  useMantineColorScheme,
  useMantineTheme,
  Image,
} from "@mantine/core";
import imgTop from "./bg-top.svg";
import imgBottom from "./bg-bottom.svg";

export function PricingSection() {
  const { colorScheme } = useMantineColorScheme();
  const theme = useMantineTheme();
  const dark = colorScheme === "dark";

  return (
    <>
      <Box
        sx={{
          backgroundColor:
            theme.colorScheme === "dark"
              ? theme.colors.dark[9]
              : "hsl(240, 78%, 98%)",
          // height: "100vh",
          position: "relative",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",

          "@media (max-width:755px)": {
            paddingBlock: "5rem",
            // height: "100%",
          },
        }}
      >
        <PricingCards />
        <Image
          src={imgBottom}
          alt=""
          width={300}
          height={250}
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            opacity: dark ? 0.2 : 1,
          }}
        />
        <Image
          src={imgTop}
          alt=""
          width={200}
          height={500}
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            opacity: dark ? 0.2 : 1,
          }}
        />
      </Box>
    </>
  );
}
