import { useContext } from "react";
import CrudScreen from "../common/CrudScreen";
import AppContext from "../../context/AppContext";
import { timeSheetSchema as schema } from "../../context/dataSchema";
import {
  createTimesheet as createQuery,
  updateTimesheet as updateQuery,
  deleteTimesheet as deleteQuery,
} from "../../graphql/mutations";

export default function Timesheets() {
  const { dataObject } = useContext(AppContext);
  const { timesheets: data = [] } = dataObject;

  const screenProps = {
    data,
    schema,
    title: "Time Sheets",
    quickStats: null,
    tableColumns: ["person", "projectRole", "startDate", "endDate"],
    filterFields: [],
    recordName: "Time Sheet",
    pagePath: "time-sheets",
    dataObjectKey: "timesheets",
    formProps: {
      createQuery,
      updateQuery,
      deleteQuery,
      dataObjectKey: "timesheets",
    },
    modulesToLoad: ["Verticals", "People", "Project Roles", "Time Sheets"],
  };
  return <CrudScreen {...screenProps} />;
}
