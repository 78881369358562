import dayjs from "dayjs";
import { formatDateString } from "../../components/common/functions/formatFunctions";
import { ISchema } from "../SchemaTypes";
import common from "./commonSchema";
import { Badge } from "@mantine/core";
import { listPersonTasks } from "../../graphql-custom/generated";
import {
  createPersonTasks,
  deletePersonTasks,
  updatePersonTasks,
} from "../../graphql/mutations";

const taskSchema: ISchema = {
  integrationSystemId: {
    title: "Integration System ID",
    field: "integrationSystemId",
    excludeFromForm: true,
    excludeFromChartBuilder: true,
    formOptions: {
      required: false,
    },
  },
  projectTasksId: {
    title: "Project",
    field: "projectTasksId",
    formOptions: {
      required: true,
      options: {
        dataSource: "projects",
        addBlank: true,
        accessorFunction: (dataSource: any) =>
          dataSource.map((row: any) => {
            return { value: row.id, label: row.name };
          }),
      },
      order: 1,
    },
    type: "select",
    excludeFromChartBuilder: true,
  },
  project: {
    title: "Project",
    field: "project",
    type: "belongsTo",
    accessorFunction: (row: any) => {
      return row.project?.name;
    },
    excludeFromForm: true,
    excludeFromChartBuilder: true,
    formOptions: { required: false },
  },
  projectName: {
    title: "Project Name",
    field: "projectName",
    accessorFunction: (value: any) => value.project?.name,
    formOptions: { required: false },
    excludeFromForm: true,
    type: "belongsTo",
  },

  name: {
    field: "name",
    title: "Name",
    formOptions: { required: false },
  },

  category: {
    field: "category",
    title: "Category",
    formOptions: { required: false },
  },

  status: {
    field: "status",
    title: "Status",
    formOptions: { required: false },
  },

  completionStatus: {
    field: "completionStatus",
    title: "Completion Status",
    formOptions: { required: false },
    excludeFromForm: true,
    formatFunction: (value: any) => (
      <Badge color={value === "Completed Late" ? "red" : "green"}>
        {value}
      </Badge>
    ),
    accessorFunction: (value: any) =>
      dayjs(value.completedDate).isAfter(value.endDate)
        ? "Completed Late"
        : "Completed On Time",
  },

  startDate: {
    title: "Start Date",
    field: "startDate",
    type: "date",
    formatFunction: formatDateString,
    formOptions: {
      required: false,
    },
  },
  endDate: {
    title: "End Date",
    field: "endDate",
    type: "date",
    formatFunction: formatDateString,
    formOptions: {
      required: false,
    },
  },

  completedDate: {
    title: "Completed Date",
    field: "completedDate",
    type: "date",
    formatFunction: formatDateString,
    formOptions: {
      required: false,
    },
  },

  complete: {
    title: "Complete",
    field: "complete",
    type: "boolean",
    formOptions: {
      required: false,
    },
  },

  assigneeNames: {
    title: "Assignees",
    field: "assigneeNames",
    formOptions: {
      required: false,
    },
    excludeFromForm: true,
    accessorFunction: (row: any) => {
      return row?.assignees?.items
        ?.map(
          (assignee: any) =>
            assignee.person.firstName + " " + assignee.person.lastName
        )
        .join(", ");
    },
  },

  assignees: {
    title: "Assignees",
    field: "assignees",
    excludeFromDataView: true,
    excludeFromChartBuilder: true,
    // accessorFunction: (row: any) => "",
    formOptions: {
      required: false,
      childIdName: "personID",
      parentIdName: "riskID",
      listQuery: listPersonTasks,
      updateQuery: updatePersonTasks,
      createQuery: createPersonTasks,
      deleteQuery: deletePersonTasks,
      listKey: "person",
      options: {
        dataSource: "people",
        addBlank: true,
        accessorFunction: (dataSource: any) =>
          dataSource
            ?.sort((a: any, b: any) => {
              return `${a.firstName} ${a.lastName}` ===
                `${b.firstName} ${b.lastName}`
                ? 0
                : `${a.firstName} ${a.lastName}` >
                  `${b.firstName} ${b.lastName}`
                ? 1
                : -1;
            })
            ?.map((row: any) => {
              return {
                value: row.id,
                label: `${row.firstName} ${row.lastName}`,
              };
            }) ?? [],
      },
      order: 2,
    },

    type: "manyToMany",
  },

  type: {
    title: "Type",
    field: "type",
    type: "select",
    formOptions: {
      required: false,
      defaultValue: "Task",
      options: {
        list: ["Task", "Milestone"],
      },
    },
  },

  // taskOwnerId: {
  //   title: "Task Owner",
  //   field: "taskOwnerId",
  //   formOptions: {
  //     required: false,
  //     options: {
  //       dataSource: "people",
  //       addBlank: true,
  //       accessorFunction: (dataSource: any) =>
  //         dataSource
  //           .sort((a: any, b: any) => {
  //             const aVal = `${a.firstName} ${a.lastName}`;
  //             const bVal = `${b.firstName} ${b.lastName}`;
  //             return aVal === bVal ? 0 : aVal > bVal ? 1 : -1;
  //           })
  //           .map((row: any) => {
  //             return {
  //               value: row.id,
  //               label: `${row.firstName} ${row.lastName}`,
  //             };
  //           }),
  //     },
  //     order: 2,
  //   },
  //   type: "select",
  // },

  period: {
    title: "Period",
    field: "period",
    formOptions: { required: false },
  },

  ...common,
};

export default taskSchema;
