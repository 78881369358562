import { useMantineTheme, Paper } from "@mantine/core";
import React from "react";
import {
  AreaChart as ReAreaChart,
  Area,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
  ReferenceLine,
} from "recharts";
import {
  formatCategoryAxis,
  formatNumericAxis,
  RenderCustomizedLabelDot,
  CustomTooltip,
  getChartColors,
} from "./ChartUtilities";

interface IAreaChartProps {
  data: { [key: string]: string | number }[];
  // dataKey: string;
  xAxis?: { visible: boolean; dataKey: string };
  yAxis?: { visible: boolean; dataKey: string };
  dataLabels?: boolean;
  legend?: boolean;
  values: string[];
  yAxisWidth: number;
  dataLabelFontSize: number;
  yAxisFontSize: number;
  xAxisFontSize: number;
  formatFunction: any;
  chartVariant: string;
  colKeys: string[];
  rowKeys: string[];
  // referenceLine: {};
  includeYAxis: boolean;
  includeXAxis: boolean;
  schema: any;
  yAxisMin: number | "";
  yAxisMax: number | "";
  grid: boolean;
  comparisonFieldString: string;
}

export default function AreaChart(props: IAreaChartProps) {
  const {
    data,
    dataLabels,
    values,
    yAxisWidth = 60,
    dataLabelFontSize,
    yAxisFontSize,
    xAxisFontSize,
    formatFunction,
    legend,
    chartVariant,
    colKeys,
    rowKeys,
    includeYAxis,
    includeXAxis,
    schema,
    yAxisMin,
    yAxisMax,
    grid,
    comparisonFieldString,
  } = props;
  const theme = useMantineTheme();
  const colors = getChartColors(theme);

  const colorField = colKeys.length > 0 ? colKeys : values;

  return (
    <ResponsiveContainer width="100%" height="99%">
      <ReAreaChart width={100} height={100} data={data} barGap={1}>
        {grid && (
          <CartesianGrid
            strokeDasharray="3 3"
            stroke={
              theme.colorScheme === "dark"
                ? theme.colors.gray[7]
                : theme.colors.gray[4]
            }
          />
        )}
        <defs>
          {colorField.map((value: string, index: number) => {
            return (
              <linearGradient
                id={`colorValue${index}`}
                x1="0"
                y1="0"
                x2="0"
                y2="1"
                key={`colorValue${index}`}
              >
                <stop offset="5%" stopColor={colors[index]} stopOpacity={0.9} />
                <stop
                  offset="95%"
                  stopColor={colors[index]}
                  stopOpacity={0.5}
                />
              </linearGradient>
            );
          })}
        </defs>
        {includeXAxis && (
          <XAxis
            dataKey="name"
            type="category"
            tickFormatter={formatCategoryAxis}
            tick={{
              fontSize: `${xAxisFontSize}em`,
              fill: theme.colorScheme === "dark" ? "#ffffff" : "#666",
            }}
          />
        )}

        {includeYAxis && (
          <YAxis
            type="number"
            width={yAxisWidth}
            tickFormatter={(value) => formatNumericAxis(value, formatFunction)}
            tick={{
              fontSize: `${yAxisFontSize}em`,
              fill: theme.colorScheme === "dark" ? "#ffffff" : "#666",
            }}
            domain={
              yAxisMin || yAxisMax
                ? [yAxisMin ?? 0, yAxisMax ?? "auto"]
                : undefined
            }
          />
        )}
        <Tooltip
          cursor={false}
          content={
            <CustomTooltip
              formatFunction={formatFunction}
              comparisonFieldString={comparisonFieldString}
            />
          }
        />
        {/* <ReferenceLine x={0} /> */}
        {legend && <Legend />}
        {values.map((value: string, index: number) => {
          if (colKeys.length > 0) {
            return colKeys.map((col: string, colIndex: number) => {
              return (
                <React.Fragment key={`${value}-${col[0]}`}>
                  <Area
                    dataKey={col[0]}
                    // name={"No Data"}
                    name={col[0] ? schema[col[0]]?.title : "No Data"}
                    fill={`url(#colorValue${colIndex})`}
                    stroke={`url(#colorValue${colIndex})`}
                    stackId={chartVariant === "Standard" ? undefined : value}
                    // name={"Test"}
                  >
                    {dataLabels && (
                      <LabelList
                        dataKey={col[0]}
                        name={col[0] ? schema[col[0]]?.title : "No Data"}
                        position="inside"
                        fill={"white"}
                        content={(props: any) => (
                          <RenderCustomizedLabelDot
                            {...props}
                            dataLabelFontSize={dataLabelFontSize}
                            formatFunction={formatFunction}
                          />
                        )}
                      />
                    )}
                  </Area>
                </React.Fragment>
              );
            });
          } else {
            return (
              <React.Fragment key={value}>
                <Area
                  dataKey={value}
                  stroke={`url(#colorValue${index})`}
                  fill={`url(#colorValue${index})`}
                  name={value ? schema[value]?.title : "No Data"}
                >
                  {dataLabels && (
                    <LabelList
                      dataKey={value}
                      name={value ? schema[value]?.title : "No Data"}
                      position="inside"
                      fill={"white"}
                      content={(props: any) => (
                        <RenderCustomizedLabelDot
                          {...props}
                          dataLabelFontSize={dataLabelFontSize}
                          formatFunction={formatFunction}
                        />
                      )}
                    />
                  )}
                </Area>
              </React.Fragment>
            );
          }
        })}
      </ReAreaChart>
    </ResponsiveContainer>
  );
}
