import maplibregl from "maplibre-gl";

export function getFeatureCenter(feature: any) {
  if (!feature.geometry) {
    throw new Error("Invalid feature: no geometry found");
  }

  // Simple center calculation for polygons
  if (feature.geometry.type === "Polygon") {
    const coords = feature.geometry.coordinates[0];
    const longitudes = coords.map((coord: number[]) => coord[0]);
    const latitudes = coords.map((coord: number[]) => coord[1]);

    const centerLong = (Math.min(...longitudes) + Math.max(...longitudes)) / 2;
    const centerLat = (Math.min(...latitudes) + Math.max(...latitudes)) / 2;

    return [centerLong, centerLat];
  }

  // For point features, return the coordinates directly
  if (feature.geometry.type === "Point") {
    return feature.geometry.coordinates;
  }

  throw new Error(`Unsupported geometry type: ${feature.geometry.type}`);
}

// Function to fly to feature center
export function flyToFeature(map: any, feature: any, options: any = {}) {
  const center = getFeatureCenter(feature);

  map.flyTo({
    center: center,
    zoom: options.zoom || 12,
    duration: options.duration || 2000,
    essential: true,
  });
}

// Function to fit bounds of a feature
export function fitFeatureBounds(map: any, feature: any, options = {}) {
  return fitFeaturesBounds(map, [feature], options);
}

export function getBounds(features: any, options: any = {}) {
  let bounds;
  // Manual bounds calculation
  bounds = features.reduce((totalBounds: any, feature: any) => {
    if (!feature.geometry) {
      throw new Error("Invalid feature: no geometry found");
    }

    let coordinates = [];

    switch (feature.geometry.type) {
      case "Point":
        coordinates = [feature.geometry.coordinates];
        break;
      case "LineString":
        coordinates = feature.geometry.coordinates;
        break;
      case "Polygon":
        coordinates = feature.geometry.coordinates.flat();
        break;
      case "MultiPolygon":
        coordinates = feature.geometry.coordinates.flat(2);
        break;
      default:
        throw new Error(`Unsupported geometry type: ${feature.geometry.type}`);
    }

    // Extract all coordinates
    const longitudes = coordinates.map((coord: number[]) => coord[0]);
    const latitudes = coordinates.map((coord: number[]) => coord[1]);

    // Create bounds for this feature
    const featureBounds = new maplibregl.LngLatBounds(
      [Math.min(...longitudes), Math.min(...latitudes)],
      [Math.max(...longitudes), Math.max(...latitudes)]
    );

    // Extend the total bounds
    return totalBounds ? totalBounds.extend(featureBounds) : featureBounds;
  }, null);

  return bounds;
}

// Function to fit bounds for multiple features
export function fitFeaturesBounds(map: any, features: any, options: any = {}) {
  if (!features || features.length === 0) {
    throw new Error("No features provided");
  }

  const bounds = getBounds(features, options);

  if (!bounds) {
    throw new Error("Could not calculate bounds for features");
  }

  // Fit the map to the bounds
  map.fitBounds(bounds, {
    padding: options.padding || 20,
    duration: options.duration || 1000,
    maxZoom: options.maxZoom || 17,
  });
}
