import { useTenantQuestion } from "./useTenantQuestion";

export type TenantQuestionProps = {
  fieldName: string;
  form: any;
  editable: boolean;
};

export function TenantQuestion({ fieldName, form }: TenantQuestionProps) {
  useTenantQuestion({ fieldName, form });
  return null;
}
