import { useContext } from "react";
import AppContext from "../../context/AppContext";
import {
  risksSchema,
  changesSchema,
  peopleSchema,
  csatsSchema,
  weeklyUpdatesSchema,
  deliverablesSchema,
  statementsSchema,
  projectsSchema,
} from "../../context/dataSchema";
import useFetchData from "../../hooks/useFetchData";
import { saveSystemPreferenceObject } from "../common/functions/savePreferenceObject";

export const useSystemPreferencesScreen = () => {
  const {
    systemPreferenceObject: preferenceObject,
    setSystemPreferenceObject: setPreferenceObject,
    currentTenant,
  } = useContext(AppContext);
  useFetchData([]);

  const { modules = [] } = preferenceObject ? preferenceObject : {};

  const onSwitchChange = ({
    moduleName,
    newValue,
  }: {
    moduleName: string;
    newValue: boolean;
  }) => {
    const preferenceObjectCopy = JSON.parse(JSON.stringify(preferenceObject));
    preferenceObjectCopy.modules[moduleName].active = newValue;
    setPreferenceObject(preferenceObjectCopy);
    saveSystemPreferenceObject(preferenceObjectCopy, currentTenant);
  };

  const dataFieldsObject: any = {
    Risks: {
      columns: Object.values(risksSchema),
    },
    "Statements of Work": {
      columns: Object.values(statementsSchema),
    },
    People: {
      columns: Object.values(peopleSchema),
    },
    Deliverables: {
      columns: Object.values(deliverablesSchema),
    },
    "Change Requests": {
      columns: Object.values(changesSchema),
    },
    CSATs: {
      columns: Object.values(csatsSchema),
    },
    "Weekly Updates": {
      columns: Object.values(weeklyUpdatesSchema),
    },
    Projects: {
      columns: Object.values(projectsSchema),
    },
  };

  const columnFilterFunction = (column: any) => {
    return !column.excludeFromDataView;
  };

  return {
    modules,
    onSwitchChange,
    dataFieldsObject,
    columnFilterFunction,
  };
};
