import React, { useState } from "react";
import { ISchema } from "../../../context/SchemaTypes";
import {
  ActionIcon,
  Group,
  Stack,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { IconPlus, IconTrash } from "@tabler/icons-react";

export function SelectOptions({
  newParsed,
  updateSchema,
  field,
}: {
  newParsed: ISchema;
  updateSchema: any;
  field: string;
}) {
  const [newOption, setNewOption] = useState("");
  const { formOptions } = newParsed[field];
  const { options = {} } = formOptions;
  const { list = [] } = options;
  //   const

  return (
    <Stack>
      <Text fw={700}>Options</Text>
      {list.map((option, index: number) => {
        return (
          <Group key={index}>
            <TextInput //@ts-ignore
              value={option}
              onChange={(e) => {
                if (!newParsed[field].formOptions.options) {
                  newParsed[field].formOptions.options = { list: [] };
                } //@ts-ignore
                if (newParsed[field].formOptions.options.list[index]) {
                  //@ts-ignore
                  newParsed[field].formOptions.options.list[index] =
                    e.target.value;
                }
                updateSchema(newParsed);
              }}
            />
            <ActionIcon
              color="red"
              variant="light"
              onClick={() => {
                //@ts-ignore
                newParsed[field].formOptions.options.list.splice(index, 1);
                updateSchema(newParsed);
              }}
            >
              <IconTrash />
            </ActionIcon>
          </Group>
        );
      })}
      <Group>
        <TextInput //@ts-ignore
          value={newOption}
          onChange={(e) => {
            setNewOption(e.target.value);
          }}
        />
        <ActionIcon
          onClick={() => {
            if (!newParsed[field].formOptions.options) {
              newParsed[field].formOptions.options = { list: [] };
            } //@ts-ignore
            if (newParsed[field].formOptions.options.list) {
              //@ts-ignore
              newParsed[field].formOptions.options.list.push(newOption);
            }
            updateSchema(newParsed);
            setNewOption("");
          }}
          color="green"
          variant="light"
        >
          <IconPlus />
        </ActionIcon>
      </Group>
    </Stack>
  );
}
