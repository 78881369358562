import { TenantQuestion } from "../../components/common/TenantQuestion";
import { ISchema } from "../SchemaTypes";
import common from "./commonSchema";
// import { formatDateString } from "../../components/common/functions/formatFunctions";

const verticalsSchema: ISchema = {
  tenant: {
    title: "Tenant",
    field: "tenant",
    accessorFunction: (value: any) => value.tenant?.name,
    formOptions: { required: true },
    excludeFromForm: true,
    type: "belongsTo",
  },
  tenantVerticalsId: {
    title: "Tenant",
    field: "tenantVerticalsId",
    formOptions: {
      required: true,
      CustomQuestion: (props: any) => (
        <TenantQuestion {...props} fieldName="tenantVerticalsId" />
      ),
      options: {
        dataSource: "tenants",
        addBlank: true,
        accessorFunction: (dataSource: any) =>
          dataSource.map((row: any) => {
            return { value: row.id, label: row.name };
          }),
      },
      order: 1,
    },
    type: "select",
  },

  name: { title: "Name", field: "name", formOptions: { required: true } },

  programmes: {
    title: "Programmes",
    field: "programmes",
    type: "hasMany",
    accessorFunction: (row: any) => row.programmes.length,
    formOptions: { required: true },
    excludeFromForm: true,
  },

  risks: {
    title: "Risks",
    field: "risks",
    type: "hasMany",
    accessorFunction: (row: any) => row.risks.length,
    formOptions: { required: true },
    excludeFromForm: true,
  },

  ...common,
};

export default verticalsSchema;
