import { useContext, useMemo, useState } from "react";
import AppContext from "../../../../context/AppContext";
import { PL } from "../../../../API";
import { Box, Center, Progress, Text } from "@mantine/core";
import {
  MRT_ColumnDef,
  type MRT_Cell,
  type MRT_Column,
  type MRT_Row,
  type MRT_TableInstance,
  type MRT_AggregationFn,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import WidgetFrame from "../../WidgetFrame";
import PercentStat from "./PercentStat";
import { createFilterFunction } from "../../functions/createFilterFunction";

const AggregatedCell = ({
  cell,
  table,
}: {
  cell: MRT_Cell<PL>;
  column: MRT_Column<PL>;
  row: MRT_Row<PL>;
  table: MRT_TableInstance<PL>;
}) => (
  <>
    <Box sx={{ fontWeight: "bold" }}>{Math.round(cell.getValue<number>())}</Box>
  </>
);

const AggregatedCellCurrency = ({
  cell,
  table,
}: {
  cell: MRT_Cell<PL>;
  column: MRT_Column<PL>;
  row: MRT_Row<PL>;
  table: MRT_TableInstance<PL>;
}) => (
  <>
    <Box sx={{ fontWeight: "bold" }}>
      {cell.getValue<number>()?.toLocaleString?.("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })}
    </Box>
  </>
);

const AggregatedCellPercent = ({
  cell,
  table,
  row,
}: {
  cell: MRT_Cell<PL>;
  column: MRT_Column<PL>;
  row: MRT_Row<PL>;
  table: MRT_TableInstance<PL>;
}) => {
  const value = cell.getValue<number>();
  const rounded = value ? Math.round(value * 100) : 0;
  const color = value > 0.1 ? "green" : value > 0.05 ? "orange" : "red";
  return (
    <>
      <Box sx={{ color, fontWeight: "bold" }}>
        <Center>
          <PercentStat value={rounded} />
        </Center>
      </Box>
    </>
  );
};

const Cell = ({
  cell,
}: {
  cell: MRT_Cell<PL>;
  // renderedCellValue: React.ReactNode;
  // column: MRT_Column<PL>;
  // row: MRT_Row<PL>;
  // rowRef?: React.RefObject<HTMLTableRowElement> | undefined;
  // table: MRT_TableInstance<...>;
}) => <>{Math.round(cell.getValue<number>())}</>;

const CellCurrency = ({
  cell,
}: {
  cell: MRT_Cell<PL>;
  // renderedCellValue: React.ReactNode;
  // column: MRT_Column<PL>;
  // row: MRT_Row<PL>;
  // rowRef?: React.RefObject<HTMLTableRowElement> | undefined;
  // table: MRT_TableInstance<...>;
}) => (
  <>
    {cell.getValue<number>()?.toLocaleString?.("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })}
  </>
);

const CellPercent = ({
  cell,
}: {
  cell: MRT_Cell<PL>;
  // renderedCellValue: React.ReactNode;
  // column: MRT_Column<PL>;
  // row: MRT_Row<PL>;
  // rowRef?: React.RefObject<HTMLTableRowElement> | undefined;
  // table: MRT_TableInstance<...>;
}) => {
  const value = cell.getValue<number>();
  const rounded = value ? Math.round(value * 100) : 0;
  const color = value > 0.1 ? "green" : value > 0.05 ? "orange" : "red";
  return (
    <Box sx={{ color }}>
      <Center>
        <PercentStat value={rounded} />
      </Center>
    </Box>
  );
};

const Footer = ({ sum }: any) => <Box pb={"xs"}>{Math.round(sum)}</Box>;

const FooterCurrency = ({ sum }: any) => (
  <Box pb={"xs"}>
    {sum?.toLocaleString?.("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })}
  </Box>
);

const FooterPercent = ({ sum }: any) => (
  <Box>
    <Center>
      <PercentStat value={sum} />
    </Center>
  </Box>
);

type IPLFields = PL & {};

export default function RemainingPayment(props: any) {
  const { dashboardFilterObjectList, updateDashboardFilter } = props;
  const { dataObject, currentProgramme, currentProgrammes, period }: any =
    useContext(AppContext);
  const { pls = [] }: { pls: PL[] } = dataObject;
  // const [view, setView] = useState("financials");
  //   const theme = useMantineTheme();

  const localFilterFunction = useMemo(
    () => createFilterFunction([dashboardFilterObjectList]),
    [dashboardFilterObjectList]
  );

  const permittedProgrammeList = useMemo(
    () => (currentProgramme === "All" ? currentProgrammes : [currentProgramme]),
    [currentProgramme, currentProgrammes]
  );

  const filteredPls: PL[] = useMemo(
    () =>
      pls
        .filter((row: any) => {
          return (
            (currentProgramme === "All" ||
              permittedProgrammeList.includes(row["programmePlsId"])) &&
            row.period === "Live" &&
            row.status !== "Closed"
          );
        })
        .filter(localFilterFunction),
    [pls, currentProgramme, period, permittedProgrammeList, localFilterFunction]
  );

  const fieldAddedPls: IPLFields[] = useMemo(
    () =>
      filteredPls.map((pl) => {
        const newPl: IPLFields = structuredClone(pl);

        return newPl;
      }),
    [filteredPls]
  );

  const totalObject: { [key: string]: number } = useMemo(() => {
    const fields: string[] = ["revenueSoldUSD", "billedRedeemedUSD"];
    return fieldAddedPls.reduce((obj, curr) => {
      fields.forEach((field) => {
        //@ts-ignore
        if (!obj[field]) obj[field] = 0;
        //@ts-ignore
        obj[field] += curr[field];
      });
      return obj;
    }, {});
  }, [fieldAddedPls]);

  const percentageUsingTotals: any = (leafRowString: any, childRows: any) => {
    const totalsObject = childRows.reduce(
      (obj: any, row: any) => {
        obj.revenueSoldUSD += row.original.revenueSoldUSD;
        obj.billedRedeemedUSD += row.original.billedRedeemedUSD;
        return obj;
      },
      {
        revenueSoldUSD: 0,
        billedRedeemedUSD: 0,
      }
    );
    const { revenueSoldUSD, billedRedeemedUSD } = totalsObject;
    return (revenueSoldUSD - billedRedeemedUSD) / revenueSoldUSD;
  };

  const columns = useMemo<MRT_ColumnDef<PL>[]>(
    () => [
      {
        header: "Quarter",
        accessorKey: "fisYear2QtrName",
        enableGrouping: true,
        size: 0,
      },
      {
        header: "",
        size: 0,
        accessorKey: "projectManager",
        enableGrouping: true,
      },
      {
        header: "",
        accessorKey: "engagementName",
        size: 0,
        enableGrouping: true,
        enableSorting: false,
        Cell: ({ cell }: { cell: MRT_Cell<PL> }) => {
          return (
            <>
              <Text size={"xs"}>{cell.getValue<number>()}</Text>
            </>
          );
        },
      },
      {
        header: "Revenue Sold",
        accessorKey: "revenueSoldUSD",
        aggregationFn: "sum",
        enableGrouping: false,
        AggregatedCell: AggregatedCellCurrency,
        Cell: CellCurrency,
        Footer: <FooterCurrency sum={totalObject["revenueSoldUSD"]} />,
      },
      {
        header: "Billed Redeemed",
        accessorKey: "billedRedeemedUSD",
        aggregationFn: "sum",
        enableGrouping: false,
        AggregatedCell: AggregatedCellCurrency,
        Cell: CellCurrency,
        Footer: <FooterCurrency sum={totalObject["billedRedeemedUSD"]} />,
      },
      {
        header: "Remaining Amount",
        accessorFn: (row) =>
          (row.revenueSoldUSD ?? 0) - (row.billedRedeemedUSD ?? 0),
        aggregationFn: "sum",
        enableGrouping: false,
        AggregatedCell: AggregatedCellCurrency,
        Cell: CellCurrency,
        Footer: (
          <FooterCurrency
            sum={
              totalObject["revenueSoldUSD"] - totalObject["billedRedeemedUSD"]
            }
          />
        ),
      },
      {
        header: "Remaining Amount %",
        accessorFn: (row) =>
          ((row.revenueSoldUSD ?? 0) - (row.billedRedeemedUSD ?? 0)) /
          (row.revenueSoldUSD ?? 0),
        aggregationFn: percentageUsingTotals,
        enableGrouping: false,
        AggregatedCell: AggregatedCellPercent,
        Cell: CellPercent,
        Footer: (
          <FooterPercent
            sum={Math.round(
              ((totalObject["revenueSoldUSD"] -
                totalObject["billedRedeemedUSD"]) *
                100) /
                totalObject["revenueSoldUSD"]
            )}
          />
        ),
      },
    ],
    [totalObject]
  );

  const table = useMantineReactTable({
    columns,
    data: fieldAddedPls,
    enableColumnResizing: false,
    enableGrouping: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    groupedColumnMode: false,
    enablePagination: false,
    enablePinning: true,
    enableColumnActions: false,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    mantineTableBodyCellProps: { sx: { textWrap: "wrap" } },
    initialState: {
      density: "xs",
      //@ts-ignore
      expanded: false,
      grouping: ["fisYear2QtrName", "projectManager"],
      //   pagination: { pageIndex: 0, pageSize: 100 },
      sorting: [{ id: "fisYear2QtrName", desc: false }],
      columnPinning: {
        left: ["fisYear2QtrName", "projectManager", "engagementName"],
        right: [],
      },
    },
    mantineToolbarAlertBannerBadgeProps: { color: "blue", variant: "outline" },
    mantineTableContainerProps: { sx: {} },
    mantineTableProps: { style: { height: "100%" } },
    mantinePaperProps: {
      style: {
        border: "none",
        boxShadow: "none",
        maxHeight: "100%",
        height: "100%",
        display: "grid",
        gridTemplateRows: "1fr 0px ",
      },
    },
  });

  return (
    <WidgetFrame title={"Outstanding Amounts"}>
      <Box
        style={{
          height: "99%",
          minHeight: "99%",
          maxHeight: "99%",
        }}
      >
        {pls.length === 0 ? null : <MantineReactTable table={table} />}
      </Box>
    </WidgetFrame>
  );
}
