import {
  Box,
  Button,
  CloseButton,
  Divider,
  Group,
  Paper,
  Title,
} from "@mantine/core";
import CustomPaper from "../common/CustomPaper";
import FilterConditionRow from "./FilterConditionRow";
import { useEffect, useState } from "react";

export default function FilterCreation({
  filterObject,
  updateIntegrationFunction,
  selectedRecord,
  sourceFieldsObject,
}: any) {
  const [filterObjects, setFilterObjects] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (filterObject) {
      setFilterObjects(filterObject);
    }
  }, [filterObject]);

  const saveFilterObject = async () => {
    setLoading(true);
    const copy = structuredClone(selectedRecord);
    const parsedDataObject = JSON.parse(copy.dataObject);
    parsedDataObject.filterObject = filterObjects;
    copy.dataObject = JSON.stringify(parsedDataObject);
    await updateIntegrationFunction(copy);
    setLoading(false);
  };

  const addFilterObject = () => {
    const filterObjectCopy = JSON.parse(JSON.stringify(filterObjects));
    filterObjectCopy.push([]);
    setFilterObjects(filterObjectCopy);
  };

  const removeFilterGroup = (groupIndex: number) => {
    const filterObjectCopy = JSON.parse(JSON.stringify(filterObjects));
    filterObjectCopy.splice(groupIndex, 1);
    setFilterObjects(filterObjectCopy);
  };

  return (
    <CustomPaper>
      <Title order={2} mb={"md"}>
        Integration Filters
      </Title>

      {filterObjects.map((filterObject: any, objectIndex: number) => {
        const updateFilterObject = (
          includeExclude: string,
          sourceField: string,
          operand: string,
          valueOne: any,
          valueTwo: any
        ) => {
          const filterObjectCopy = JSON.parse(JSON.stringify(filterObjects));
          const newObject = {
            field: sourceField,
            operand,
            includeExclude,
          };
          switch (operand) {
            case "Between":
              filterObjectCopy[objectIndex].push({
                ...newObject,
                value: [valueOne, valueTwo],
              });
              break;
            default:
              filterObjectCopy[objectIndex].push({
                ...newObject,
                value: valueOne,
              });
              break;
          }
          setFilterObjects(filterObjectCopy);
        };

        const deleteFilterRow = (rowIndex: number) => {
          const filterObjectCopy = JSON.parse(JSON.stringify(filterObjects));
          filterObjectCopy[objectIndex].splice(rowIndex, 1);
          setFilterObjects(filterObjectCopy);
        };

        return (
          <Box
            key={
              objectIndex +
              filterObject.field +
              filterObject.includeExclude +
              JSON.stringify(filterObject.value)
            }
            ml={"1em"}
          >
            <Paper p={"xl"} sx={{ borderRadius: "2em" }}>
              <Group position="apart" mb={"md"}>
                <Title order={3}>{`Filter Group ${objectIndex + 1}`}</Title>
                <CloseButton onClick={() => removeFilterGroup(objectIndex)} />
              </Group>
              {filterObject &&
                filterObject.map((filterOb: any, rowIndex: number) => {
                  const { operand, value, includeExclude, field } = filterOb;
                  return (
                    <div key={field}>
                      <FilterConditionRow
                        sourceFieldsObject={sourceFieldsObject}
                        filterObject={filterObject}
                        updateFilterObject={updateFilterObject}
                        operand={operand}
                        value={value}
                        includeExclude={includeExclude}
                        sourceField={field}
                        disabled={true}
                        deleteFilterRow={deleteFilterRow}
                        rowIndex={rowIndex}
                      />
                      <Divider my="xs" label={"And"} labelPosition="center" />
                    </div>
                  );
                })}

              <FilterConditionRow
                sourceFieldsObject={sourceFieldsObject}
                filterObject={filterObject}
                updateFilterObject={updateFilterObject}
                disabled={false}
              />
            </Paper>
            <Divider
              my="xs"
              label={
                objectIndex === filterObjects.length - 1 ? (
                  <Button onClick={addFilterObject}>Or +</Button>
                ) : (
                  "Or"
                )
              }
              labelPosition="center"
            />
          </Box>
        );
      })}
      {filterObjects.length === 0 && (
        <Divider
          my="xs"
          label={<Button onClick={addFilterObject}>Add Filter +</Button>}
          labelPosition="center"
        />
      )}
      <Group position="right" mt={"md"}>
        <Button onClick={saveFilterObject} loading={loading}>
          Save Filters
        </Button>
      </Group>
    </CustomPaper>
  );
}
