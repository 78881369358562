import { useMemo } from "react";
import { createFilterFunction } from "../../common/functions/createFilterFunction";
import { getDataSchema } from "../../common/functions/getDataSchema";
import getDatasetAtPointInTime from "../../common/functions/getDatasetAtPointInTime";
import getFinalPeriods from "../functions/getFinalPeriods";
import getComparisonPeriodString from "../functions/getComparisonPeriodString";
import useFetchData from "../../../hooks/useFetchData";
import {
  dataKeyToModule,
  filterKeyLookup,
  IFilterKeyLookup,
} from "../../../context/references";
import { useCustomDatasets } from "../../../hooks/useCustomDatasets";

export function useDashboardData({
  currentDashboard,
  dashboardFilterObject,
  currentProgrammes,
  currentProgramme,
  dataObject,
  comparisonPeriodOverride,
  opened,
  chartProps,
}: any) {
  const customDatasets = useCustomDatasets();
  const dataSourceObject = useMemo(() => {
    if (!currentDashboard) return [];
    const config = JSON.parse(currentDashboard.configuration);
    const { widgets, filters = [] } = config;

    const initialObject = filters.reduce((obj: any, filter: any) => {
      const { dataSource, field } = filter;
      if (!obj[dataSource]) {
        obj[dataSource] = {
          usedAttributes: [],
          periods: ["Live"],
          comparisonPeriods: [],
        };
      }
      obj[dataSource].usedAttributes.push(field);
      return obj;
    }, {});

    const inputs = [...widgets];

    if (opened) {
      inputs.push({ configuration: chartProps });
    }

    const widgetObject = inputs
      .filter(
        (widget: any) =>
          widget.configuration && widget.configuration.chartType !== "Title"
      )
      .reduce((obj: any, widget: any) => {
        const {
          dataSourceKey,
          filterObjects = [],
          rows = [],
          cols = [],
          values = [],
          period,
          comparisonPeriod,
        } = widget.configuration;

        const finalPeriods = getFinalPeriods(period);

        const finalComparisonPeriod =
          comparisonPeriod === "None"
            ? "None"
            : comparisonPeriodOverride === "Default"
            ? comparisonPeriod
            : comparisonPeriodOverride;

        const finalComparisonPeriods = [
          getComparisonPeriodString({ comparisonPeriod: finalComparisonPeriod })
            .date.toISOString()
            .slice(0, 10),
        ];

        if (!obj[dataSourceKey]) {
          obj[dataSourceKey] = {
            usedAttributes: [],
            periods: [],
            comparisonPeriods: [],
          };
        }

        for (let per of finalPeriods) {
          if (!obj[dataSourceKey].periods.includes(per)) {
            obj[dataSourceKey].periods.push(per);
          }
        }

        for (let per of finalComparisonPeriods) {
          if (!obj[dataSourceKey].comparisonPeriods.includes(per)) {
            obj[dataSourceKey].comparisonPeriods.push(per);
          }
        }

        const filterFields = filterObjects.reduce(
          (list: any, innerList: any) => {
            list = list.concat(innerList.map((obj: any) => obj.field));
            return list;
          },
          []
        );

        [...rows, ...cols, ...values, ...filterFields].forEach((field) => {
          if (!obj[dataSourceKey].usedAttributes.includes(field)) {
            obj[dataSourceKey].usedAttributes.push(field);
          }
        });
        return obj;
      }, initialObject);

    return widgetObject;
  }, [
    currentDashboard,
    chartProps.rows,
    chartProps.values,
    chartProps.cols,
    chartProps.dataSourceKey,
    chartProps.comparisonPeriod,
    opened,
    comparisonPeriodOverride,
  ]);

  const dashboardDataObject = useMemo(() => {
    const returnObject: any = {};

    Object.keys(dataSourceObject).forEach((dataSource: string) => {
      const schema = getDataSchema(dataSource, customDatasets);
      const { usedAttributes, periods, comparisonPeriods } =
        dataSourceObject[dataSource];

      const originalData = structuredClone(dataObject[dataSource]);

      if (!returnObject[dataSource]) {
        returnObject[dataSource] = {};
      }

      for (const period of [...periods, ...comparisonPeriods]) {
        returnObject[dataSource][period] =
          period === "Live"
            ? originalData
            : getDatasetAtPointInTime(originalData, new Date(period));

        returnObject[dataSource][period] =
          returnObject[dataSource][period]?.map((row: any) => {
            usedAttributes.forEach((field: any) => {
              if (schema[field] && schema[field].accessorFunction) {
                row[field] = schema[field].accessorFunction(row);
              }
            });
            return row;
          }) ?? [];
      }
    });

    return returnObject;
  }, [dataObject, dataSourceObject, customDatasets]);

  const permittedProgrammeList = useMemo(
    () => (currentProgramme === "All" ? currentProgrammes : [currentProgramme]),
    [currentProgramme, currentProgrammes]
  );

  const updatedDashboardFilterObject = useMemo(() => {
    return Object.values(dashboardFilterObject).reduce(
      (list: any, filterObject: any) => {
        const { dataSource, field } = filterObject;
        const schema = getDataSchema(dataSource, customDatasets);

        if (schema[field].equivalentFields) {
          Object.keys(schema[field].equivalentFields).forEach((key: any) => {
            list.push({
              field: schema[field].equivalentFields[key],
              includeExclude: "Include",
              operand: "Includes",
              value: filterObject.value,
              dataSource: key,
            });
          });
          list.push(filterObject);
        } else {
          list.push(filterObject);
        }
        return list;
      },
      []
    );
  }, [dashboardFilterObject]);

  const globalDashboardFilteredDataObject = useMemo(() => {
    const returnObject: any = {};

    // const dashboardFilterObjectList = [Object.values(dashboardFilterObject)];

    // const customFilterFunction =
    //   dashboardFilterObjectList.length > 0
    //     ? createFilterFunction(dashboardFilterObjectList)
    //     : (value: any) => true;

    for (const dataSource in dashboardDataObject) {
      const dashboardFilterObjectList = [
        //@ts-ignore
        Object.values(updatedDashboardFilterObject).filter(
          (filterOb: any) =>
            filterOb.dataSource === dataSource || filterOb.dataSource === "all"
        ),
      ];

      const customFilterFunction =
        dashboardFilterObjectList.length > 0
          ? createFilterFunction(dashboardFilterObjectList)
          : (value: any) => true;

      const filterKey = !dataKeyToModule[dataSource]
        ? "custom"
        : filterKeyLookup[dataSource as keyof IFilterKeyLookup] ??
          "programmeAccessString";

      if (!returnObject[dataSource]) {
        returnObject[dataSource] = {};
      }

      for (const period in dashboardDataObject[dataSource]) {
        returnObject[dataSource][period] = dashboardDataObject[dataSource][
          period
        ].filter((row: any) => {
          switch (filterKey) {
            case "custom":
            case "people":
            case "risks":
            // case "statements":
            case "accounts":
            case "verticals":
            case "deliverables":
            case "employeeSatisfaction":
            case "roleTypes":
            case "timesheets":
              return true && customFilterFunction(row);
            case "pipelines":
              return true && customFilterFunction(row);
            default:
              return (
                permittedProgrammeList.includes(row[filterKey]) &&
                customFilterFunction(row)
              );
          }
        });
      }
    }
    return returnObject;
  }, [
    dashboardDataObject,
    permittedProgrammeList,
    updatedDashboardFilterObject,
  ]);

  const dashboardModules = useMemo(() => {
    return Object.keys(dataSourceObject).map(
      (dataSource: any) => dataKeyToModule[dataSource] ?? dataSource
    );
  }, [dataSourceObject]);

  useFetchData(dashboardModules, []);

  return {
    globalDashboardFilteredDataObject,
    dashboardDataObject,
    dataSourceObject,
  };
}
