import common from "./commonSchema";
import { ISchema } from "../SchemaTypes";
import { formatDateString } from "../../components/common/functions/formatFunctions";

const plSchema: ISchema = {
  id: {
    title: "ID",
    field: "id",

    formOptions: { required: false },
  },
  programme: {
    title: "Programme",
    field: "programme",
    accessorFunction: (value: any) => value.programme?.name,
    formOptions: { required: true },
    excludeFromForm: true,
    type: "belongsTo",
  },
  programmePlsId: {
    title: "Programme ID",
    field: "programmePlsId",
    formOptions: {
      required: true,
      options: {
        dataSource: "programmes",
        addBlank: true,
        accessorFunction: (dataSource: any) =>
          dataSource?.map((row: any) => {
            return { value: row.id, label: row.name };
          }) ?? [],
      },
      order: 1,
    },
    type: "select",
  },
  period: {
    title: "Period",
    field: "period",
    formOptions: { required: false },
    equivalentFields: { all: "period" },
  },
  engagementId: {
    title: "Engagement ID",
    field: "engagementId",
    formOptions: { required: false },
  },
  engagementName: {
    title: "Engagement Name",
    field: "engagementName",

    formOptions: { required: false },
    equivalentFields: { mrrs: "engagementName", costs: "engagementName" },
  },
  gar: {
    title: "GAR",
    field: "gar",

    formOptions: { required: false },
  },
  projectManager: {
    title: "Project Manager",
    field: "projectManager",

    formOptions: { required: false },
    equivalentFields: { mrrs: "projectManager", costs: "projectManager" },
  },
  pmAccess: {
    title: "PM Access",
    field: "pmAccess",
    formOptions: {
      required: false,
      options: {
        dataSource: "people",
        addBlank: true,
        accessorFunction: (dataSource: any) =>
          dataSource?.map((row: any) => {
            return { value: row.id, label: `${row.firstName} ${row.lastName}` };
          }) ?? [],
      },
    },
    type: "select",
  },
  dmAccess: {
    title: "DM Access",
    field: "dmAccess",
    formOptions: {
      required: false,
      options: {
        dataSource: "people",
        addBlank: true,
        accessorFunction: (dataSource: any) =>
          dataSource?.map((row: any) => {
            return { value: row.id, label: `${row.firstName} ${row.lastName}` };
          }) ?? [],
      },
    },
    type: "select",
  },
  subcontractor: {
    title: "Subcontractor",
    field: "subcontractor",

    formOptions: { required: false },
  },
  lastInvoice: {
    title: "Last Invoice",
    field: "lastInvoice",

    formOptions: { required: false },
  },
  budgetMarginType: {
    title: "Budget Margin Type",
    field: "budgetMarginType",

    formOptions: { required: false },
  },
  impactedByAllInCostChange: {
    title: "Impacted by All-in Cost Change",
    field: "impactedByAllInCostChange",

    formOptions: { required: false },
  },
  deAuditMessageUSD: {
    title: "DE Audit Message (USD)",
    field: "deAuditMessageUSD",
    formOptions: { required: false },
  },
  billingCurrency: {
    title: "Billing Currency",
    field: "billingCurrency",

    formOptions: { required: false },
  },
  budgetComments: {
    title: "Budget Comments",
    field: "budgetComments",

    formOptions: { required: false },
  },
  budgetGar: {
    title: "Budget GAR",
    field: "budgetGar",
    formOptions: { required: false },
  },
  csatComments: {
    title: "CSAT Comments",
    field: "csatComments",
    formOptions: { required: false },
  },
  csatGar: {
    title: "CSAT GAR",
    field: "csatGar",
    formOptions: { required: false },
  },
  engagementStatus: {
    title: "Engagement Status",
    field: "engagementStatus",

    formOptions: { required: false },
    equivalentFields: { mrrs: "engagementStatus", costs: "engagementStatus" },
  },
  engagementType: {
    title: "Engagement Type",
    field: "engagementType",

    formOptions: { required: false },
  },
  fisYear2QtrName: {
    title: "Fiscal Year 2Qtr Name",
    field: "fisYear2QtrName",

    formOptions: { required: false },
  },
  lastBillingEntry: {
    title: "Last Billing Entry",
    field: "lastBillingEntry",

    formOptions: { required: false },
  },
  mitigationAction: {
    title: "Mitigation Action",
    field: "mitigationAction",

    formOptions: { required: false },
  },
  projectGarComments: {
    title: "Project GAR Comments",
    field: "projectGarComments",

    formOptions: { required: false },
  },
  geo: {
    title: "Geo",
    field: "geo",
    formOptions: { required: false },
  },
  region: {
    title: "Region",
    field: "region",

    formOptions: { required: false },
    equivalentFields: { mrrs: "region", costs: "region" },
  },
  subRegion: {
    title: "Sub Region",
    field: "subRegion",

    formOptions: { required: false },
    equivalentFields: { mrrs: "subRegion", costs: "subRegion" },
  },
  scheduleComments: {
    title: "Schedule Comments",
    field: "scheduleComments",

    formOptions: { required: false },
  },
  scheduleGar: {
    title: "Schedule GAR",
    field: "scheduleGar",

    formOptions: { required: false },
  },
  deliveryManager: {
    title: "Delivery Manager",
    field: "deliveryManager",

    formOptions: { required: false },
  },
  deAuditResultsUSD: {
    title: "DE Audit Results (USD)",
    field: "deAuditResultsUSD",
    formOptions: { required: false },
  },
  revenueSoldUSD: {
    title: "Revenue Sold (USD)",
    field: "revenueSoldUSD",
    type: "number",
    formOptions: { required: false },
  },
  revenueRecognizedUSD: {
    title: "Revenue Recognized (USD)",
    field: "revenueRecognizedUSD",
    type: "number",
    formOptions: { required: false },
  },
  billedRedeemedUSD: {
    title: "Billed Redeemed (USD)",
    field: "billedRedeemedUSD",
    type: "number",
    formOptions: { required: false },
  },
  costSoldUSD: {
    title: "Cost Sold (USD)",
    field: "costSoldUSD",
    type: "number",
    formOptions: { required: false },
  },
  marginSold: {
    title: "Margin Sold",
    field: "marginSold",
    type: "number",
    formOptions: { required: false },
  },
  revisedCostSoldUSD: {
    title: "Revised Cost Sold (USD)",
    field: "revisedCostSoldUSD",
    type: "number",
    formOptions: { required: false },
  },
  revisedMarginSold: {
    title: "Revised Margin Sold",
    field: "revisedMarginSold",
    type: "number",
    formOptions: { required: false },
  },
  percentOfHoursBeforeAllInChange: {
    title: "Percent of Hours Before All-in Change",
    field: "percentOfHoursBeforeAllInChange",
    type: "number",
    formOptions: { required: false },
  },
  revenueForecastExitUSD: {
    title: "Revenue Forecast Exit (USD)",
    field: "revenueForecastExitUSD",
    type: "number",
    formOptions: { required: false },
  },
  costForecastExitUSD: {
    title: "Cost Forecast Exit (USD)",
    field: "costForecastExitUSD",
    type: "number",
    formOptions: { required: false },
  },
  marginForecastExit: {
    title: "Margin Forecast Exit",
    field: "marginForecastExit",
    type: "number",
    formOptions: { required: false },
  },
  revenuePlannedUSD: {
    title: "Revenue Planned (USD)",
    field: "revenuePlannedUSD",
    type: "number",
    formOptions: { required: false },
  },
  costPlannedUSD: {
    title: "Cost Planned (USD)",
    field: "costPlannedUSD",
    type: "number",
    formOptions: { required: false },
  },
  marginPlanned: {
    title: "Margin Planned",
    field: "marginPlanned",
    type: "number",
    formOptions: { required: false },
  },
  revenueActualUSD: {
    title: "Revenue Actual (USD)",
    field: "revenueActualUSD",
    type: "number",
    formOptions: { required: false },
  },
  costActualUSD: {
    title: "Cost Actual (USD)",
    field: "costActualUSD",
    type: "number",
    formOptions: { required: false },
  },
  marginActual: {
    title: "Margin Actual",
    field: "marginActual",
    type: "number",
    formOptions: { required: false },
  },
  hoursSold: {
    title: "Hours Sold",
    field: "hoursSold",
    type: "number",
    formOptions: { required: false },
  },
  hoursPlanned: {
    title: "Hours Planned",
    field: "hoursPlanned",
    type: "number",
    formOptions: { required: false },
  },
  hoursActual: {
    title: "Hours Actual",
    field: "hoursActual",
    type: "number",
    formOptions: { required: false },
  },
  percentCompleteHoursSold: {
    title: "Percent Complete Hours Sold",
    field: "percentCompleteHoursSold",
    type: "number",
    formOptions: { required: false },
  },
  revenueSoldBC: {
    title: "Revenue Sold (BC)",
    field: "revenueSoldBC",
    type: "number",
    formOptions: { required: false },
  },
  maxExchangeRateUsed: {
    title: "Max Exchange Rate Used",
    field: "maxExchangeRateUsed",
    type: "number",
    formOptions: { required: false },
  },
  billedRedeemedBC: {
    title: "Billed Redeemed (BC)",
    field: "billedRedeemedBC",
    type: "number",
    formOptions: { required: false },
  },
  costForecastExitBC: {
    title: "Cost Forecast Exit (BC)",
    field: "costForecastExitBC",
    type: "number",
    formOptions: { required: false },
  },
  costSoldBC: {
    title: "Cost Sold (BC)",
    field: "costSoldBC",
    type: "number",
    formOptions: { required: false },
  },
  revenueForecastExitBC: {
    title: "Revenue Forecast Exit (BC)",
    field: "revenueForecastExitBC",
    type: "number",
    formOptions: { required: false },
  },
  revenueRecognizedBC: {
    title: "Revenue Recognized (BC)",
    field: "revenueRecognizedBC",
    type: "number",
    formOptions: { required: false },
  },
  ...common,
  audit: {
    title: "Audit",
    field: "audit",
    type: "text",
    excludeFromForm: false,
    excludeFromDataView: true,
    excludeFromChartBuilder: true,
    formOptions: { required: false },
    enableGlobalFilter: false,
  },
  createdAt: {
    title: "Created At",
    field: "createdAt",
    type: "date",
    formatFunction: formatDateString,
    excludeFromForm: false,
    formOptions: { required: false },
  },
  updatedAt: {
    title: "Updated At",
    field: "updatedAt",
    type: "date",
    formatFunction: formatDateString,
    excludeFromForm: false,
    formOptions: { required: false },
  },
};

export default plSchema;
