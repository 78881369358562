const filterKeyLookup = {
  mrrs: "programmeMrrsId",
  pls: "programmePlsId",
  costs: "programmeCostsId",
  risks: "programmeRisksId",
  changes: "programmeChangesId",
  people: "programmePeopleId",
  csats: "programmeCsatsId",
  weeklyUpdates: "programmeWeeklyUpdatesId",
  deliverables: "programmeDeliverablesId",
  statements: "programmeStatementsId",
  projects: "programmeProjectsId",
};

export function createAccessStrings({
  dataKey,
  formValues,
  tenant,
  dataObject,
}: {
  dataKey: string;
  formValues: any;
  tenant: any;
  dataObject: any;
}) {
  const {
    programmes = [],
    verticals = [],
    accounts = [],
    projects = [],
  } = dataObject;

  const returnObject: any = {
    tenantAccessString: tenant,
  };

  if (dataKey === "risks") {
    if (formValues.verticalRisksId) {
      returnObject.verticalAccessString = formValues.verticalRisksId;
    }

    if (formValues.accountRisksId) {
      returnObject.accountAccessString = formValues.accountRisksId;
    }

    if (formValues.programmeRisksId) {
      const currentProgramme = programmes.find(
        (programme: any) => programme.id === formValues.programmeRisksId
      );

      returnObject.verticalAccessString =
        currentProgramme?.verticalAccessString;
      returnObject.accountAccessString = currentProgramme?.accountAccessString;
      returnObject.programmeAccessString = formValues.programmeRisksId;
    }

    if (formValues.projectRisksId) {
      const currentProject = projects.find(
        (project: any) => project.id === formValues.projectRisksId
      );
      returnObject.verticalAccessString = currentProject?.verticalAccessString;
      returnObject.accountAccessString = currentProject?.accountAccessString;
      returnObject.programmeAccessString =
        currentProject?.programmeAccessString;
    }

    return returnObject;
  }

  if (dataKey === "verticals") {
    returnObject.verticalAccessString = formValues.id;
    return returnObject;
  }

  if (dataKey === "accounts") {
    returnObject.accountAccessString = formValues.id;
    return returnObject;
  }

  if (dataKey === "programmes") {
    const currentAccount = formValues.accountProgrammesId
      ? accounts.find(
          (account: any) => account.id === formValues.accountProgrammesId
        )
      : null;

    const currentVertical = formValues.verticalProgrammesId
      ? verticals.find(
          (vertical: any) => vertical.id === formValues.verticalProgrammesId
        )
      : null;

    const currentAccountId = currentAccount?.id;
    const currentVerticalId = currentVertical?.id;

    returnObject.verticalAccessString = currentVerticalId;
    returnObject.accountAccessString = currentAccountId;
    returnObject.programmeAccessString = formValues.id;
    return returnObject;
  }

  //@ts-ignore
  const selectedProgramme = formValues[filterKeyLookup[dataKey]]
    ? programmes.filter(
        (programme: any) =>
          //@ts-ignore
          programme.id === formValues[filterKeyLookup[dataKey]]
      )[0]
    : null;

  const currentProgrammeId = selectedProgramme?.id;
  const currentAccountId = selectedProgramme?.account.id;
  const currentVerticalId = selectedProgramme?.vertical.id;

  returnObject.verticalAccessString = currentVerticalId;
  returnObject.accountAccessString = currentAccountId;
  returnObject.programmeAccessString = currentProgrammeId;
  return returnObject;
}
