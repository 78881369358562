import {
  Box,
  Button,
  FileInput,
  Group,
  Loader,
  NativeSelect,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import FieldMapping from "./FieldMapping";
import FilterCreation from "./FilterCreation";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { API } from "aws-amplify";
import { updateIntegration } from "../../graphql/mutations";
import AppContext from "../../context/AppContext";
import CustomPaper from "../common/CustomPaper";
import fetchIntegrationData from "./systems/fetchIntegrationDataNew";
import {
  formatModuleAndSystem,
  formatIntegrationStatus,
} from "../../context/schemas/integrationsSchema";
import useFetchData from "../../hooks/useFetchData";
import { IconUpload } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
// import { dataKeyToModule as moduleToModuleLookup } from "../../context/references";
//@ts-ignore
const Papa = window.Papa;

function getModulesToLoad(module: string) {
  switch (module) {
    case "tasks":
      return ["Projects", "People"];
    case "statements":
      return ["Projects", "Pipelines"];
    case "projectRoles":
      return ["Projects", "Pipelines"];
    case "invoices":
      return ["Statements of Work"];
    case "pipelines":
      return ["Stages"];
    case "projects":
      return ["Regions", "People"];
    case "timesheets":
      return ["People", "Project Roles"];
    case "projectAllocations":
      return ["People", "Project Roles"];
    default:
      return [];
  }
}

function flattenObject(obj: any, parentKey = "") {
  let result = {};

  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      let newKey = parentKey ? `${parentKey}.${key}` : key;

      if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
        Object.assign(result, flattenObject(obj[key], newKey));
      } else {
        //@ts-ignore
        result[newKey] = obj[key];
      }
    }
  }

  return result;
}

const reduceFunction = (object: any, row: any) => {
  const flattenedRow: any = flattenObject(row);

  const returnObject = Object.keys(flattenedRow).reduce((obj, key) => {
    if (!obj[key]) obj[key] = []; //@ts-ignore
    if (flattenedRow[key] === "" || flattenedRow[key] === null)
      flattenedRow[key] = "[BLANK]";
    if (!obj[key].includes(flattenedRow[key])) obj[key].push(flattenedRow[key]);
    return obj;
  }, object);
  return returnObject;
};

export default function IntegrationConfig({
  selectedRecord,
  refresh,
  formatModule,
}: any) {
  const {
    dataObject: recordDataObject,
    module,
    name,
    system,
    status,
    active: integrationActive,
  } = selectedRecord ?? {};

  const { dataObject, setDataObject, currentTenant } = useContext(AppContext);
  // const { customDatasets = [] } = dataObject;
  const sourceFieldsLoaded = useRef(false);
  const [integrationName, setIntegrationName] = useState(name);
  const [active, setActive] = useState(integrationActive ?? "Inactive");
  const [file, setFile] = useState<File | null>(null);
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [sourceFieldsObject, setSourceFieldsObject] = useState(null);

  const parsedDataObject = recordDataObject ? JSON.parse(recordDataObject) : {};
  const { mappingObject, filterObject, systemSpecificData } =
    parsedDataObject ?? {};

  const modulesToLoad = getModulesToLoad(module);

  useFetchData(modulesToLoad);

  const updateSourceFieldsCsv = useCallback(() => {
    const reader = new FileReader();

    if (file) {
      reader.onload = function (event) {
        if (event && event.target) {
          const contents = event.target.result;
          Papa.parse(contents, {
            header: true,
            delimiter: ",",
            skipEmptyLines: true,
            complete: async function (results: any) {
              const { data: newData } = results;
              setData(newData);
              const newSourceFields = newData.reduce(reduceFunction, {});

              setSourceFieldsObject(newSourceFields);
            },
          });
        }
      };

      reader.readAsText(file);
    }
  }, [file]);

  const updateSourceFields = useCallback(async () => {
    if (
      filterObject &&
      system &&
      systemSpecificData &&
      !sourceFieldsLoaded.current
    ) {
      sourceFieldsLoaded.current = true;

      const newSourceFields = await fetchIntegrationData(
        {
          filterObject,
          system,
          currentTenant,
          systemSpecificData,
        },
        true
      );

      //@ts-ignore
      setSourceFieldsObject(newSourceFields.data);
    }
  }, [filterObject, system, systemSpecificData, currentTenant]);

  useEffect(() => {
    if (system !== "csv") {
      updateSourceFields();
    }
  }, [updateSourceFields]);

  const updateIntegrationFunction = useCallback(
    async (recordObject: any) => {
      setLoading(true);
      try {
        delete recordObject.owner;
        delete recordObject.__typename;
        delete recordObject.updatedAt;
        delete recordObject.createdAt;

        recordObject.name = integrationName;
        recordObject.active = active;

        await API.graphql({
          query: updateIntegration,
          variables: { input: recordObject },
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });

        const copyOfDataObject = structuredClone(dataObject);

        copyOfDataObject.integrations = copyOfDataObject.integrations.map(
          (record: any) => {
            if (record.id === recordObject.id) {
              record = { ...record, ...recordObject };
            }
            return record;
          }
        );
        setDataObject(copyOfDataObject);
      } catch (err) {
        console.log("error updating record:", err);
      }
      setLoading(false);
    },
    [dataObject, setDataObject, integrationName, active]
  );

  const navigate = useNavigate();

  return (
    <Box mr="xs">
      <Stack>
        <CustomPaper>
          <Group position="apart">
            <Text>
              <Text color="dimmed">Integration Name:</Text>
              <TextInput
                value={integrationName}
                onChange={(e) => setIntegrationName(e.target.value)}
              />
            </Text>
            <Text>
              <Text color="dimmed">Active:</Text>
              <NativeSelect
                value={active}
                onChange={(e) => setActive(e.target.value)}
                data={["Active", "Inactive"]}
              />
            </Text>
            <Text>
              <Text color="dimmed">Module:</Text> {formatModule(module)}
            </Text>
            <Text>
              <Text color="dimmed">System:</Text>{" "}
              {formatModuleAndSystem(system)}
            </Text>
            <Text>
              <Text color="dimmed">Status:</Text>{" "}
              {formatIntegrationStatus(status)}
            </Text>
          </Group>
        </CustomPaper>
        {!sourceFieldsObject && system === "csv" && (
          <Group position="center">
            <FileInput
              mb={"md"}
              label="Upload CSV File"
              placeholder="Your file"
              icon={<IconUpload />}
              accept="text/csv"
              value={file}
              onChange={setFile}
            />
            <Button onClick={updateSourceFieldsCsv}>Import</Button>
          </Group>
        )}
        {!sourceFieldsObject && system !== "csv" && (
          <Group position="center">
            <Text>Loading Integration Data</Text>
            <Loader />
          </Group>
        )}
        {sourceFieldsObject && (
          <Box ml={"1em"}>
            <FilterCreation
              selectedRecord={selectedRecord}
              updateIntegrationFunction={updateIntegrationFunction}
              filterObject={filterObject}
              sourceFieldsObject={sourceFieldsObject}
              loading={loading}
            />
            <FieldMapping
              selectedRecord={selectedRecord}
              updateIntegrationFunction={updateIntegrationFunction}
              mappingObject={mappingObject}
              module={module}
              sourceFieldsObject={sourceFieldsObject}
              loading={loading}
            />
          </Box>
        )}
      </Stack>
      <Group mt={"md"}>
        <Button
          onClick={async () => {
            await updateIntegrationFunction(selectedRecord);
            navigate("/integrations");
          }}
          variant="outline"
          disabled={loading}
        >
          Save
        </Button>
        <Button
          onClick={async () => {
            await updateIntegrationFunction(selectedRecord);
            refresh(selectedRecord, data);
            navigate("/integrations");
          }}
          disabled={
            loading || (selectedRecord.system === "csv" && data === null)
          }
        >
          Save & Refresh
        </Button>
      </Group>
    </Box>
  );
}
