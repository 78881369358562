import { ISchema } from "../SchemaTypes";

const usersSchema: ISchema = {
  id: {
    title: "ID",
    field: "id",
    formOptions: { required: false },
    excludeFromForm: true,
  },
  email: {
    title: "Email",
    field: "email",
    formOptions: { required: true },
  },
  preferred_username: {
    title: "Initials",
    field: "preferred_username",
    formOptions: { required: true },
  },
  "custom:viewType": {
    title: "Internal Or Customer",
    field: "custom:viewType",
    formOptions: {
      required: true,
      options: { list: ["Internal", "Customer"] },
    },
    type: "select",
  },

  "custom:userTypes": {
    title: "User Types",
    field: "custom:userTypes",
    formOptions: {
      required: false,
      defaultValue: [],
      options: {
        dataSource: "userTypes",
        addBlank: true,
        accessorFunction: (dataSource: any) =>
          dataSource?.map((row: any) => {
            return { value: row.id, label: row.name };
          }) ?? [],
      },
    },
    type: "multiSelect",
  },

  "custom:tenant": {
    title: "Tenants",
    field: "custom:tenant",
    formOptions: {
      required: true,
      defaultValue: [],
      options: {
        dataSource: "tenants",
        addBlank: true,
        accessorFunction: (dataSource: any) =>
          [{ value: "All_Tenants", label: "All Tenants" }].concat(
            dataSource?.map((row: any) => {
              return { value: row.id, label: row.name };
            }) ?? []
          ),
      },
    },
    type: "multiSelect",
  },
  "custom:verticals": {
    title: "Verticals",
    field: "custom:verticals",
    formOptions: {
      required: false,
      defaultValue: [],
      options: {
        dataSource: "verticals",
        addBlank: true,
        accessorFunction: (dataSource: any) =>
          [{ value: "All_Verticals", label: "All Verticals" }].concat(
            dataSource?.map((row: any) => {
              return { value: row.id, label: row.name };
            }) ?? []
          ),
      },
    },
    type: "multiSelect",
  },
  "custom:accounts": {
    title: "Accounts",
    field: "custom:accounts",
    type: "multiSelect",
    formOptions: {
      required: false,
      defaultValue: [],
      options: {
        dataSource: "accounts",
        addBlank: true,
        accessorFunction: (dataSource: any) =>
          [{ value: "All_Accounts", label: "All Accounts" }].concat(
            dataSource?.map((row: any) => {
              return { value: row.id, label: row.name };
            }) ?? []
          ),
      },
    },
  },
  "custom:programmes": {
    title: "Programmes",
    field: "custom:programmes",
    formOptions: {
      required: false,
      defaultValue: [],
      options: {
        dataSource: "programmes",
        addBlank: true,
        accessorFunction: (dataSource: any) =>
          [{ value: "All_Programmes", label: "All Programmes" }].concat(
            dataSource?.map((row: any) => {
              return { value: row.id, label: row.name };
            }) ?? []
          ),
      },
    },
    type: "multiSelect",
  },
  "custom:peopleIds": {
    title: "People Associations",
    field: "custom:peopleIds",
    formOptions: {
      required: false,
      defaultValue: [],
      options: {
        dataSource: "people",
        addBlank: true,
        accessorFunction: (dataSource: any) =>
          dataSource?.map((row: any) => {
            return { value: row.id, label: `${row.firstName} ${row.lastName}` };
          }) ?? [],
      },
    },
    type: "multiSelect",
  },
  "custom:peopleRecordsOnly": {
    title: "Associated People Records Only",
    field: "custom:peopleRecordsOnly",
    formOptions: {
      required: false,
      defaultValue: "No",
      options: {
        list: ["No", "Yes"],
      },
    },
    type: "select",
  },
  email_verified: {
    title: "Email Verified",
    field: "email_verified",
    formOptions: { required: false, defaultValue: false },
    type: "boolean",
  },
};

export default usersSchema;
