import { API } from "aws-amplify";
import { integrations } from "../../../../graphql/queries";

export async function getActiveDirectoryPeople({
  filterObject,
  infoOnly,
  currentTenant,
  system,
}: {
  filterObject?: any;
  infoOnly?: boolean;
  currentTenant: string;
  system: string;
}) {
  const response = await API.graphql({
    query: integrations,
    authMode: "AMAZON_COGNITO_USER_POOLS",
    variables: {
      input: JSON.stringify({
        system: "microsoft",
        operation: "getActiveDirectoryUsers",
        informationObject: {
          filterObject,
          infoOnly,
          system: "microsoft",
          currentTenant,
        },
      }),
    },
  });

  //@ts-ignore
  const parsedData = JSON.parse(response.data.integrations);
  const parsedBody = JSON.parse(parsedData.body);
  return parsedBody;
}

export async function authoriseMicrosoft({
  code,
  currentTenant,
  system,
}: {
  code: string;
  currentTenant: string;
  system: string;
}) {
  const response = await API.graphql({
    query: integrations,
    authMode: "AMAZON_COGNITO_USER_POOLS",
    variables: {
      input: JSON.stringify({
        system: "microsoft",
        operation: "authorise",
        informationObject: { code, currentTenant, system },
      }),
    },
  });

  //@ts-ignore
  const parsedResponse = JSON.parse(response.data.integrations);
  if (parsedResponse.body) {
    let parsedBody;
    parsedBody = JSON.parse(parsedResponse.body);

    const { status } = parsedBody;

    return { status };
  } else {
    return { status: "No code" };
  }
}
