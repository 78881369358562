import { useCallback, useMemo, useState } from "react";
import {
  MantineProvider,
  ColorSchemeProvider,
  ColorScheme,
} from "@mantine/core";
import { useAuthenticator } from "@aws-amplify/ui-react";
import "./App.css";
import AppContent from "./AppContent";
import SignIn from "./components/authentication/SignIn";
import { Notifications } from "@mantine/notifications";
import { Navigate, Route, Routes } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";
import Home from "./components/home/Home";

// import * as schemas from "./context/dataSchema";

function App() {
  // console.log({ schemas });
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);

  const localStorageColorScheme: string | null = useMemo(
    () => window.localStorage.getItem("vmware-cpo-color-scheme"),
    []
  );

  const localStoragePrimaryColor: string | null = useMemo(
    () => window.localStorage.getItem("vmware-cpo-primary-color"),
    []
  );

  const [color, setColor] = useState<string>(
    localStoragePrimaryColor ?? "blue"
  );

  const [colorScheme, setColorScheme] = useState<any>(
    localStorageColorScheme ?? "dark"
  );

  const toggleColorScheme = useCallback(
    (value?: ColorScheme) => {
      setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"));
    },
    [colorScheme]
  );

  const appContentProps = {
    setColor,
  };

  return (
    <ColorSchemeProvider
      colorScheme={colorScheme}
      toggleColorScheme={toggleColorScheme}
    >
      <MantineProvider
        theme={{
          colorScheme,
          primaryColor: color ?? "blue",
          headings: {
            fontFamily: `"Cabin", sans-serif`,
          },
        }}
        withGlobalStyles
        withNormalizeCSS
      >
        <Notifications />
        {authStatus === "configuring" ? null : authStatus ===
          "authenticated" ? (
          <AppContent {...appContentProps} />
        ) : (
          <Router>
            <Routes>
              <Route path="/about" element={<Home />} />
              <Route path="/signin" element={<SignIn />} />
              <Route path="/*" element={<Navigate replace to={`/signin`} />} />
            </Routes>
          </Router>
        )}
      </MantineProvider>
    </ColorSchemeProvider>
  );
}
export default App;
