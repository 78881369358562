import {
  Alert,
  Button,
  Group,
  MultiSelect,
  NativeSelect,
  Switch,
  Text,
  TextInput,
  useMantineTheme,
} from "@mantine/core";
import { useMemo } from "react";

export default function FieldMappingRow(props: any) {
  const theme = useMantineTheme();
  const {
    sourceFieldsObject,
    selectedSourceField,
    targetField,
    targetFieldTitle,
    onMappingChange,
    openRulesModal,
    setRulesProps,
    type,
    selectOptions,
    bulkAssignValue,
    autoMapValue,
    schemaObject,
    dataObject,
  } = props;

  const optionObject = useMemo(() => {
    let optionObject;

    if (schemaObject.field === "tenantCustomRecordsId") {
      optionObject = {
        dataSource: "tenants",
        addBlank: true,
        accessorFunction: (dataSource: any) =>
          dataSource.map((row: any) => {
            return { value: row.id, label: row.name };
          }),
      };
    } else {
      optionObject = schemaObject.formOptions.options ?? {};
    }
    return optionObject;
  }, [schemaObject.field, schemaObject.formOptions.options]);

  const targetValues = useMemo(() => {
    //@ts-ignore
    if (["boolean"].includes(type))
      return [
        { value: true, label: "True" },
        { value: false, label: "False" },
      ];

    if (
      schemaObject.type === "select" &&
      optionObject.dataSource &&
      !optionObject.accessorFunction
    ) {
      switch (optionObject.dataSource) {
        case "people":
          optionObject.accessorFunction = (dataSource: any) => {
            return dataSource.map((row: any) => {
              return {
                value: row.id,
                label: `${row.firstName} ${row.lastName}`,
              };
            });
          };
          break;
        default:
          break;
      }
    }

    const { dataSource, accessorFunction, list } = optionObject;

    //@ts-ignore
    if (list)
      return list //@ts-ignore
        .filter((value: string) => value) //@ts-ignore
        .map((value: string) => ({ value, label: value }));
    if (dataSource && dataObject && accessorFunction && dataObject[dataSource])
      return accessorFunction(dataObject[dataSource]);
    return [];
  }, [type, dataObject, optionObject, schemaObject.type]);

  return (
    <tr
      style={{
        backgroundColor:
          selectedSourceField && ["select", "manyToMany"].includes(type)
            ? theme.colorScheme === "light"
              ? theme.colors.red[1]
              : `${theme.colors.red[6]}20`
            : "",
      }}
    >
      <td style={{ width: "25%", minWidth: "25%", maxWidth: "25%" }}>
        <Text>{targetFieldTitle}</Text>
      </td>
      <td>
        {/* <NativeSelect
          value={selectedSourceField}
          data={["", ...selectOptions]}
          onChange={(e) => onMappingChange(targetField, e.target.value)}
          styles={(theme) => ({
            input: {
              backgroundColor:
                theme.colorScheme === "dark" ? theme.colors.dark[7] : undefined,
            },
          })}
        /> */}
        <MultiSelect
          data={["", ...selectOptions]}
          value={[selectedSourceField]}
          onChange={(values) => {
            onMappingChange(targetField, values[0] ? values[0] : null);
          }}
          searchable
          clearable
          maxSelectedValues={1}
          styles={(theme) => ({
            input: {
              backgroundColor:
                theme.colorScheme === "dark" ? theme.colors.dark[7] : undefined,
            },
          })}
        />
      </td>
      <td style={{ width: "50%", minWidth: "50%", maxWidth: "50%" }}>
        {!selectedSourceField &&
          !autoMapValue &&
          targetField !== "integrationSystemId" &&
          ["select", "boolean", "manyToMany"].includes(type) && (
            <MultiSelect
              data={[{ value: "", label: "" }, ...targetValues]}
              value={[bulkAssignValue]}
              label="Bulk Assign"
              onChange={(values) => {
                // onMappingChange(targetField, values[0] ? values[0] : null);
                onMappingChange(
                  targetField,
                  null,
                  values[0] ? values[0] : null
                );
              }}
              searchable
              clearable
              maxSelectedValues={1}
              styles={(theme) => ({
                input: {
                  backgroundColor:
                    theme.colorScheme === "dark"
                      ? theme.colors.dark[7]
                      : undefined,
                },
              })}
            />
          )}
        {!selectedSourceField &&
          !bulkAssignValue &&
          !["select", "boolean", "manyToMany"].includes(type) && (
            <TextInput
              label="Bulk Assign"
              value={bulkAssignValue}
              onChange={(e) =>
                onMappingChange(targetField, null, e.target.value)
              }
              styles={(theme) => ({
                input: {
                  backgroundColor:
                    theme.colorScheme === "dark"
                      ? theme.colors.dark[7]
                      : undefined,
                },
              })}
            />
          )}
        {selectedSourceField &&
          !bulkAssignValue &&
          optionObject.dataSource &&
          ["select", "boolean", "manyToMany"].includes(type) && (
            <Switch
              label="Auto map"
              checked={!!autoMapValue}
              onChange={(e) => {
                if (e.target.checked) {
                  onMappingChange(
                    targetField,
                    selectedSourceField,
                    null,
                    optionObject.dataSource
                  );
                } else {
                  onMappingChange(targetField, selectedSourceField, null, null);
                }
              }}
            />
          )}
      </td>
      <td style={{ width: "25%", minWidth: "25%", maxWidth: "25%" }}>
        {selectedSourceField &&
          !bulkAssignValue &&
          ["select", "boolean", "manyToMany"].includes(type) && (
            <Button
              onClick={() => {
                setRulesProps({
                  sourceField: selectedSourceField,
                  targetField,
                });
                openRulesModal();
              }}
            >
              Manual Mapping
            </Button>
          )}
      </td>
    </tr>
  );
}
