import React from "react";
import { createStyles, Footer, Text } from "@mantine/core";

import { Group } from "@mantine/core";
import logo from "./smarttasking.png";
import blackLogo from "./black_logo.png";

const useStyles = createStyles((theme) => ({
  inner: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,

    // [theme.fn.smallerThan("sm")]: {
    //   flexDirection: "column",
    // },
  },
}));

export default function DashboardFooter() {
  const { classes, theme } = useStyles();
  return (
    <Footer height={30}>
      {null}
      <div className={classes.inner}>
        {/* <Group spacing="xs" position="apart" noWrap> */}
        <Text sx={{ fontSize: "0.5em" }}>v1.0</Text>
        <Group>
          <Text sx={{ fontSize: "0.5em" }}>Powered by</Text>
          <img
            src={theme.colorScheme === "dark" ? logo : blackLogo}
            alt="Smart/tasking Logo"
            style={{
              height: "12px",
            }}
          />
        </Group>
        {/* </Group> */}
      </div>
    </Footer>
  );
}
