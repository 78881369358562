import React, { useRef, useEffect, useMemo, useCallback } from "react";
import maplibregl from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";
import "./map.css";
//@ts-ignore
// import { countries } from "./countries";
import { worldGeo as rawWorldGeo } from "../worldGeo";
import { useMantineTheme } from "@mantine/core";
import { fitFeatureBounds, fitFeaturesBounds, getBounds } from "./functions";

export function Map({
  data,
  dataObject,
  formatFunction,
  legend,
  dataLabelFontSize,
  dataLabels,
  mapScale,
  longitude,
  latitude,
  width,
  height,
  enableZoom,
  schema,
  pivotData,
  comparisonPivotData,
  comparisonText,
  updateDashboardFilter,
  rows,
}: any) {
  const theme = useMantineTheme();
  const mapContainer = useRef<any>(null);
  const map = useRef<any>(null);
  const lng = 0;
  const lat = 0;
  const zoom = 1;

  let maxValue = useRef<number>(0);

  const worldGeo: any = useMemo(() => {
    maxValue.current = 0;
    return {
      ...rawWorldGeo,
      features: rawWorldGeo.features
        .filter((feature: any) => dataObject[feature.properties.name])
        .map((feature: any, index: number) => {
          feature.id = index;
          const value = dataObject[feature.properties.name]
            ? dataObject[feature.properties.name].value()
            : 0;
          feature.properties.chartValue = value;
          if (value > maxValue.current) {
            maxValue.current = value;
          }
          return feature;
        }),
    };
  }, [dataObject]);

  const addLayer = useCallback(
    function addLayer(inputMap: any) {
      if (!inputMap.style.sourceCaches["countries"]) {
        inputMap.addSource("countries", {
          type: "geojson",
          data: worldGeo,
        });
      }

      const layers = inputMap.getStyle().layers;
      // Find the index of the first symbol layer in the map style
      let firstSymbolId: any;
      for (let i = 0; i < layers.length; i++) {
        if (layers[i].type === "symbol") {
          firstSymbolId = layers[i].id;
          break;
        }
      }

      inputMap.addLayer(
        {
          id: "countries",
          type: "fill",
          source: "countries",
          layout: {},
          paint: {
            "fill-color": theme.colors[theme.primaryColor][7],
            "fill-opacity": [
              "interpolate",
              ["linear"],
              ["get", "chartValue"],
              0,
              0.2,
              maxValue.current,
              1,
            ],
          },
        },
        firstSymbolId
      );
    },
    [theme.colors, theme.primaryColor, worldGeo]
  );

  // Map initialisation
  useEffect(() => {
    if (map.current) return;

    map.current = new maplibregl.Map({
      container: mapContainer.current,
      style:
        theme.colorScheme === "dark"
          ? "https://basemaps.cartocdn.com/gl/dark-matter-gl-style/style.json"
          : "https://basemaps.cartocdn.com/gl/positron-gl-style/style.json", // `https://basemaps.cartocdn.com/gl/positron-gl-style/style.json`, //`https://basemaps.cartocdn.com/gl/dark-matter-gl-style/style.json`,
      center: [lng, lat],
      zoom: zoom,
      //   maxBounds,
    });

    // map.current.on("load", () => {
    //   addLayer(map.current);
    // });

    if (worldGeo.features.length > 0) {
      fitFeaturesBounds(map.current, worldGeo.features);
    }
  }, [lng, lat, zoom]);

  useEffect(() => {
    // addLayer(map.current);
    // let selectedStateId: any = null;

    map.current.on("click", "countries", (e: any) => {
      if (e.features.length > 0) {
        updateDashboardFilter(rows[0], e.features[0].properties.name);

        // if (selectedStateId) {
        //   map.current.setFeatureState(
        //     { source: "countries", id: selectedStateId },
        //     { selected: false }
        //   );
        // }

        // if (selectedStateId === e.features[0].id) {
        //   fitFeaturesBounds(map.current, worldGeo.features);
        //   selectedStateId = null;
        // } else {
        //   fitFeatureBounds(map.current, e.features[0]);
        //   selectedStateId = e.features[0].id;
        //   map.current.setFeatureState(
        //     { source: "countries", id: selectedStateId },
        //     { selected: true }
        //   );
        // }
      }
    });

    // Change the cursor to a pointer when the mouse is over the states layer.
    map.current.on("mouseenter", "countries", () => {
      map.current.getCanvas().style.cursor = "pointer";
    });

    map.current.on("mouseleave", "countries", () => {
      map.current.getCanvas().style.cursor = "";
    });
  }, [worldGeo.features, rows, updateDashboardFilter]);

  useEffect(() => {
    if (map.current) {
      map.current.setStyle(
        theme.colorScheme === "dark"
          ? "https://basemaps.cartocdn.com/gl/dark-matter-gl-style/style.json"
          : "https://basemaps.cartocdn.com/gl/positron-gl-style/style.json"
      );
      setTimeout(() => addLayer(map.current), 200);
      if (worldGeo.features.length > 0) {
        fitFeaturesBounds(map.current, worldGeo.features);
      }
    }
  }, [theme.colorScheme, addLayer, worldGeo.features]);

  return (
    <div className="map-wrap">
      <div ref={mapContainer} className="map" />
    </div>
  );
}
