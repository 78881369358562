import { ActionIcon, Badge } from "@mantine/core";
import { formatDateTimeString } from "../../components/common/functions/formatFunctions";
import { ISchema } from "../SchemaTypes";
import common from "./commonSchema";
import { IconCheck, IconSettings, IconX } from "@tabler/icons-react";

export function formatIntegrationStatus(value: string) {
  switch (value) {
    case "Awaiting Configuration":
      return (
        <Badge
          color="yellow"
          leftSection={
            <ActionIcon
              size="xs"
              color="yellow"
              radius="xl"
              variant="transparent"
            >
              <IconSettings size={"1.5em"} />
            </ActionIcon>
          }
        >
          {value}
        </Badge>
      );
    case "Active":
      return (
        <Badge
          color="green"
          leftSection={
            <ActionIcon
              size="xs"
              color="green"
              radius="xl"
              variant="transparent"
            >
              <IconCheck size={"1.5em"} />
            </ActionIcon>
          }
        >
          {value}
        </Badge>
      );
    case "Error":
      return (
        <Badge
          color="red"
          leftSection={
            <ActionIcon size="xs" color="red" radius="xl" variant="transparent">
              <IconX size={"1.5em"} />
            </ActionIcon>
          }
        >
          {value}
        </Badge>
      );
    case "Updated Configuration":
      return (
        <Badge
          color="blue"
          leftSection={
            <ActionIcon
              size="xs"
              radius="xl"
              variant="transparent"
              color="blue"
            >
              <IconSettings size={"1.5em"} />
            </ActionIcon>
          }
        >
          {value}
        </Badge>
      );
    default:
      return (
        <Badge
          leftSection={
            <ActionIcon size="xs" radius="xl" variant="transparent">
              <IconSettings size={"1.5em"} />
            </ActionIcon>
          }
        >
          {value}
        </Badge>
      );
  }
}

export function formatModuleAndSystem(value: string) {
  switch (value) {
    case "csv":
      return "CSV";
    default:
      return value.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
  }
}

const integrationsSchema: ISchema = {
  score: {
    title: "Name",
    field: "name",
    formOptions: {
      required: true,
    },
  },
  module: {
    title: "Module",
    field: "module",
    formatFunction: formatModuleAndSystem,
    formOptions: {
      required: true,
    },
  },
  system: {
    title: "System",
    field: "system",
    formatFunction: formatModuleAndSystem,
    formOptions: {
      required: true,
    },
  },
  status: {
    title: "Status",
    field: "status",
    formOptions: {
      required: false,
    },
    excludeFromForm: true,
    formatFunction: formatIntegrationStatus,
  },

  dataLastUpdated: {
    title: "Data Last Updated At",
    field: "dataLastUpdated",
    type: "date",
    formatFunction: (value: string) =>
      value ? formatDateTimeString(value) : "-",
    excludeFromForm: true,
    formOptions: { required: false },
  },

  ...common,
};

export default integrationsSchema;
