import {
  Box,
  Button,
  CloseButton,
  Divider,
  Group,
  Paper,
  Title,
} from "@mantine/core";
import WidgetFilterConditionRow from "./WidgetFilterConditionRow";
import { useEffect, useState } from "react";

export default function WidgetFilterCreation({
  sourceFields = [],
  updateChartProps,
  closeFilterModal,
  currentFilters,
}: any) {
  const [filterObjects, setFilterObjects] = useState([]);

  useEffect(() => {
    if (currentFilters) {
      setFilterObjects(currentFilters);
    }
  }, [currentFilters]);

  const saveFilterObject = () => {
    updateChartProps({ filterObjects });
    closeFilterModal();
  };

  const addFilterObject = () => {
    const filterObjectCopy = structuredClone(filterObjects);
    filterObjectCopy.push([]);
    setFilterObjects(filterObjectCopy);
  };

  const removeFilterGroup = (groupIndex: number) => {
    const filterObjectCopy = structuredClone(filterObjects);
    filterObjectCopy.splice(groupIndex, 1);
    setFilterObjects(filterObjectCopy);
  };

  return (
    <>
      {filterObjects.map((filterObject: any, objectIndex: number) => {
        const updateFilterObject = (
          includeExclude: string,
          sourceField: string,
          operand: string,
          valueOne: any,
          valueTwo: any
        ) => {
          const filterObjectCopy = structuredClone(filterObjects);
          const newObject = {
            field: sourceField,
            operand,
            includeExclude,
          };
          switch (operand) {
            case "Between":
              filterObjectCopy[objectIndex].push({
                ...newObject,
                value: [valueOne, valueTwo],
              });
              break;
            default:
              filterObjectCopy[objectIndex].push({
                ...newObject,
                value: valueOne,
              });
              break;
          }
          setFilterObjects(filterObjectCopy);
        };

        const deleteFilterRow = (rowIndex: number) => {
          const filterObjectCopy = structuredClone(filterObjects);
          filterObjectCopy[objectIndex].splice(rowIndex, 1);
          setFilterObjects(filterObjectCopy);
        };

        return (
          <Box
            key={
              objectIndex +
              filterObject.field +
              filterObject.includeExclude +
              JSON.stringify(filterObject.value)
            }
          >
            <Paper
              p={"xl"}
              sx={{ borderRadius: "2em" }}
              shadow={"sm"}
              withBorder
            >
              <Group position="apart" mb={"md"}>
                <Title order={3}>{`Filter Group ${objectIndex + 1}`}</Title>
                <CloseButton onClick={() => removeFilterGroup(objectIndex)} />
              </Group>
              {filterObject &&
                filterObject.map((filterOb: any, rowIndex: number) => {
                  const { operand, value, includeExclude, field } = filterOb;
                  return (
                    <>
                      <WidgetFilterConditionRow
                        sourceFields={sourceFields}
                        filterObject={filterObject}
                        updateFilterObject={updateFilterObject}
                        operand={operand}
                        value={value}
                        includeExclude={includeExclude}
                        sourceField={field}
                        disabled={true}
                        deleteFilterRow={deleteFilterRow}
                        rowIndex={rowIndex}
                      />
                      <Divider my="xs" label={"And"} labelPosition="center" />
                    </>
                  );
                })}

              <WidgetFilterConditionRow
                sourceFields={sourceFields}
                filterObject={filterObject}
                updateFilterObject={updateFilterObject}
                disabled={false}
              />
            </Paper>
            <Divider
              my="xs"
              label={
                objectIndex === filterObjects.length - 1 ? (
                  <Button onClick={addFilterObject}>Or +</Button>
                ) : (
                  "Or"
                )
              }
              labelPosition="center"
            />
          </Box>
        );
      })}
      {filterObjects.length === 0 && (
        <Divider
          my="xs"
          label={<Button onClick={addFilterObject}>Add Filter +</Button>}
          labelPosition="center"
        />
      )}

      <Button onClick={saveFilterObject}>Save</Button>
    </>
  );
}
