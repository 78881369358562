import { Center, Text } from "@mantine/core";
export default function Placeholder({
  placeholderText = "PLACEHOLDER",
}: {
  placeholderText?: string;
}) {
  return (
    <div style={{ height: "100%", width: "100%" }}>
      <Center sx={{ height: "100%", width: "100%" }}>
        <Text color="dimmed" size={"xl"}>
          {placeholderText}
        </Text>
      </Center>
    </div>
  );
}
