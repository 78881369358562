import { Fragment } from "react";
import {
  Box,
  Loader,
  // Group,
  Paper,
  SimpleGrid,
  Text,
  // Stack,
  useMantineTheme,
} from "@mantine/core";
import { IconArrowDownRight, IconArrowUpRight } from "@tabler/icons-react";
import { IChartProps } from "./Types/ChartPropsTypes";

export const defaultChartProps: IChartProps = {
  chartTitle: "",
  chartType: "Bar Chart",
  chartVariant: "Table",
  dataSourceKey: "mrrs",
  dataLabels: false,
  legend: false,
  dataLabelFontSize: 1,
  yAxisFontSize: 1,
  xAxisFontSize: 1,
  yAxisWidth: 60,
  xAxisHeight: 60,
  decimals: 1,
  condensedNumbers: false,
  cols: [], //data.columns,
  rows: [], //data.rows,
  aggregatorName: "Sum",
  values: [], //data.values,
  limitRows: "",
  limitRowsEnd: "",
  rowOrder: "value_z_to_a",
  colOrder: "value_z_to_a",
  formatFunction: "None",
  period: "Live",
  comparisonPeriod: "None",
  filterObjects: [],
  dashboardFilterObjectList: [],
  titleOrder: 1,
  percentageChange: false,
  width: 640,
  height: 400,
  mapScale: 1,
  longitude: 0,
  latitude: 0,
  enableZoom: false,
  mapFunction: (row: any) => row,
  includeYAxis: true,
  includeXAxis: true,
  yAxisMax: "",
  yAxisMin: "",
  updateDashboardFilter: () => null,
  grid: false,
  comparisonFieldString: "None",
  innerRadius: 0,
  labelPosition: "Inside",
  labelType: "Value",
  disableChartInteractions: false,
  disableTooltip: false,
  disableAnimations: false,
  positive: "Increase",
  dateField: "",
  lineOfBestFit: false,
};

export function getChartColors(theme: any) {
  const colorKeys = Object.keys(theme.colors).slice(2);
  const themeIndex = colorKeys.indexOf(theme.primaryColor);

  const startKeys = colorKeys.slice(themeIndex);
  const endKeys = colorKeys.slice(0, themeIndex);

  const combined = [...startKeys, ...endKeys];

  const allColors = [
    ...combined.reduce((colorList: any, color: any) => {
      colorList.push(theme.colors[color][7]);
      return colorList;
    }, []),
    ...combined.reduce((colorList: any, color: any) => {
      colorList.push(theme.colors[color][4]);
      return colorList;
    }, []),
    ...combined.reduce((colorList: any, color: any) => {
      colorList.push(theme.colors[color][2]);
      return colorList;
    }, []),
  ];

  return allColors;
}

export function formatCategoryAxis(value: any) {
  return value;
}

export function formatNumericAxis(value: any, formatFunction: any) {
  return formatFunction(value);
}

export const RenderCustomizedLabel = (props: any) => {
  const { x, y, width, height, value, dataLabelFontSize, formatFunction } =
    props;
  const textValue = formatFunction(value)?.toString();
  if (!textValue) return null;
  if (Math.abs(width) < textValue.length * 10 * dataLabelFontSize) return null;
  if (Math.abs(height) < 15 * dataLabelFontSize) return null;
  return (
    <text
      x={x + width / 2}
      y={y + height / 2}
      fill="#fff"
      textAnchor="middle"
      dominantBaseline="middle"
      fontSize={`${dataLabelFontSize}em`}
    >
      {textValue}
    </text>
  );
};

export const RenderCustomizedLabelDot = (props: any) => {
  const { x, y, value, dataLabelFontSize, formatFunction } = props;
  const textValue = formatFunction(value)?.toString();
  const theme = useMantineTheme();
  if (!textValue) return null;
  return (
    <text
      x={x}
      y={y - dataLabelFontSize * 16}
      fill={theme.colorScheme === "dark" ? "#fff" : "#000"}
      textAnchor="middle"
      dominantBaseline="middle"
      fontSize={`${dataLabelFontSize}em`}
    >
      {textValue}
    </text>
  );
};

export const ComparatorValue = ({
  comparatorValue,
  percentageChange = false,
  formatFunction,
}: any) => {
  return (
    <Text
      color={
        comparatorValue ? (comparatorValue > 0 ? "teal" : "red") : undefined
      }
      size="sm"
      weight={500}
      style={{
        lineHeight: 1,
        display: "flex",
        alignItems: "center",
      }}
    >
      <span>
        {comparatorValue
          ? percentageChange
            ? `${comparatorValue}%`
            : formatFunction(comparatorValue)
          : comparatorValue === 0
          ? "-"
          : ""}
      </span>

      {comparatorValue ? (
        comparatorValue > 0 ? (
          <IconArrowUpRight size={16} />
        ) : (
          <IconArrowDownRight size={16} />
        )
      ) : comparatorValue === 0 ? null : (
        <Loader size={"xs"} />
      )}
    </Text>
  );
};

export const CustomTooltip = ({
  active,
  payload,
  label,
  formatFunction,
  comparisonFieldString,
  includeTotal = true,
}: any) => {
  if (active && payload && payload.length) {
    const finalLabel =
      typeof label === "number" || !label ? payload[0].payload.name : label;

    const payloadUpdated = payload.map((obj: any) => {
      const prevValue =
        obj.payload[`${obj.dataKey}#comparison`] ??
        obj.payload[`${obj.name}#comparison`];
      obj.comparatorValue = obj.value - prevValue;
      return obj;
    });

    const total = payloadUpdated.reduce(
      (total: number, current: any) => (total += current.value),
      0
    );

    const comparisonTotal = payloadUpdated.reduce(
      (total: number, current: any) => (total += current.comparatorValue),
      0
    );

    const includeComparison = comparisonFieldString !== "None";

    return (
      <Paper sx={{ padding: "0.5em", zIndex: 400, fontSize: "0.8em" }}>
        <SimpleGrid cols={includeComparison ? 3 : 2}>
          <Box sx={{ fontWeight: "bold" }}>{finalLabel}</Box>{" "}
          <Box sx={{ fontWeight: "bold" }}></Box>
          {includeComparison && (
            <Box sx={{ fontWeight: "bold" }}>{comparisonFieldString}</Box>
          )}
          {includeTotal && (
            <>
              <Box sx={{ fontWeight: "bold" }}>Total</Box>{" "}
              <Box sx={{ fontWeight: "bold" }}>{formatFunction(total)}</Box>
              {includeComparison && (
                <Box sx={{ fontWeight: "bold" }}>
                  <ComparatorValue
                    comparatorValue={comparisonTotal}
                    percentageChange={false}
                    formatFunction={formatFunction}
                  />
                </Box>
              )}
            </>
          )}
          {payload.length > 1 &&
            payload.map((payload: any) => {
              return (
                <Fragment key={payload.name}>
                  <Box>{payload.name}</Box>{" "}
                  <Box>{formatFunction(payload.value)}</Box>
                  {includeComparison && (
                    <Box>
                      <ComparatorValue
                        comparatorValue={payload.comparatorValue}
                        percentageChange={false}
                        formatFunction={formatFunction}
                      />
                    </Box>
                  )}
                </Fragment>
              );
            })}
        </SimpleGrid>
      </Paper>
    );
  }
  return null;
};
