import React from "react";
import useStyles from "./TemperamentsReport.styles";
import { useTemperamentsReport } from "./useTemperamentsReport";
import WidgetFrame from "../../../WidgetFrame";
import {
  Box,
  SimpleGrid,
  Title,
  Text,
  Stack,
  Group,
  SegmentedControl,
  Table,
  Badge,
  LoadingOverlay,
} from "@mantine/core";
import { CircleGroup } from "./CircleGroup";
import {
  IconAlertCircle,
  IconCircleMinus,
  IconCirclePlus,
  IconCircleX,
  IconCross,
} from "@tabler/icons-react";

export type TemperamentsReportProps = {};

const TemperamentProfile = ({
  title,
  description = [],
  color,
  drivers,
  needs,
  behaviouralTraits = [],
  potentialClashes = [],
  clashes = [],
  theme,
}: any) => {
  // const finalColor = theme.colors[color][7];
  return (
    <Stack spacing={"lg"} pl="md">
      <Title color={color}>{title}</Title>
      <Stack spacing={"xs"}>
        {description.map((text: any) => {
          return <Text key={text}>{text}</Text>;
        })}
      </Stack>
      <SimpleGrid cols={2} spacing={"xl"}>
        <Group>
          <Text color={color} fw={700}>
            Drivers:{" "}
          </Text>
          <Text>{drivers}</Text>
        </Group>
        <Group>
          <Text color={color} fw={700}>
            Needs:{" "}
          </Text>
          <Text>{needs}</Text>
        </Group>
      </SimpleGrid>
      <SimpleGrid cols={2} spacing={"xl"}>
        <Stack spacing={1}>
          <Text td="underline" color={color} fw={700}>
            Clashing Temperaments
          </Text>
          <Group my={"xs"} spacing={"xs"}>
            {clashes.map((clash: any) => {
              return (
                <Badge color={clash.color} size="sm">
                  {clash.name}
                </Badge>
              );
            })}
          </Group>
          <Text color={color} fw={700} td="underline">
            Potential Clashes
          </Text>
          {potentialClashes.map((text: any) => {
            return <Text key={text}>{text}</Text>;
          })}
        </Stack>
        <Stack spacing={1}>
          <Text color={color} fw={700} td="underline">
            Behavioural Traits
          </Text>
          {behaviouralTraits.map((text: any) => {
            return <Text key={text}>{text}</Text>;
          })}
        </Stack>
      </SimpleGrid>
    </Stack>
  );
};

function BehavioursTable({ temperaments, theme, currentResult }: any) {
  return (
    <Box>
      <Title>Optimal Behaviour</Title>
      <Table>
        <thead>
          <tr>
            <th></th>
            <th>
              <Stack align="center">
                <Text>GET ESTEEM FROM FEELING</Text>
                <IconCircleX />
              </Stack>
            </th>
            <th>
              <Stack align="center">
                <Text>WITHDRAWN BEHAVIOUR</Text>
                <IconCircleMinus />
              </Stack>
            </th>
            <th>
              <Stack align="center">
                <Text>BALANCED BEHAVIOUR</Text>
                <IconCirclePlus />
              </Stack>
            </th>
            <th>
              <Stack align="center">
                <Text>EXCESSIVE BEHAVIOUR</Text>
                <IconAlertCircle />
              </Stack>
            </th>
          </tr>
        </thead>
        <tbody style={{ color: "white" }}>
          {temperaments.map((temperament: any) => {
            const finalColor = theme.colors[temperament.color][7];
            return (
              <tr>
                <td style={{ backgroundColor: finalColor }}>
                  <Text
                    size={"lg"}
                    fw={700}
                    tt="uppercase"
                    td={
                      currentResult?.temperament === temperament.title
                        ? "underline"
                        : undefined
                    }
                  >
                    {temperament.title}
                  </Text>
                </td>
                <td
                  style={{
                    backgroundColor: finalColor,
                    textAlign: "center",
                  }}
                >
                  <Text size={"lg"}>{temperament.esteem}</Text>
                </td>
                <td
                  style={{
                    backgroundColor: finalColor,
                    textAlign: "center",
                  }}
                >
                  <Text size={"lg"}>{temperament.withdrawn}</Text>
                </td>
                <td
                  style={{
                    backgroundColor: finalColor,
                    textAlign: "center",
                  }}
                >
                  <Text size={"lg"}>{temperament.balanced}</Text>
                </td>
                <td
                  style={{
                    backgroundColor: finalColor,
                    textAlign: "center",
                  }}
                >
                  <Text size={"lg"}>{temperament.excessive}</Text>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Text mt={"md"}>
        Dysfunctional behaviour is resolved by moving behaviour back to{" "}
        <Text span fw={700}>
          BALANCED
        </Text>
        .
      </Text>
    </Box>
  );
}

export function TemperamentsReport(props: any) {
  const { classes, theme }: any = useStyles();
  const {
    temperaments,
    temperamentObject,
    setCurrentTemperament,
    view,
    setView,
    currentResult,
    loading,
  } = useTemperamentsReport({ theme, ...props });
  return (
    <WidgetFrame
      title={"Temperaments Report"}
      controls={
        <Group mr={"xl"}>
          {currentResult && currentResult.temperament !== "Not Submitted" && (
            <>
              <Text size={"xl"} fw={700}>
                {currentResult.name}'s
              </Text>
              <Text size={"md"}>temperament is</Text>
              <Badge size={"xl"} color={currentResult.color}>
                {currentResult.temperament}
              </Badge>

              <SegmentedControl
                value={view}
                // @ts-ignore
                onChange={(value) => setView(value)}
                data={[
                  { label: "Summary", value: "main" },
                  { label: "Behaviour", value: "table" },
                ]}
              />
            </>
          )}
        </Group>
      }
    >
      <LoadingOverlay visible={loading} />
      {!loading && view === "main" && (
        <SimpleGrid cols={2} spacing={"lg"} style={{ height: "100%" }}>
          <Box style={{ height: "100%" }}>
            {currentResult && currentResult.temperament !== "Not Submitted" && (
              <TemperamentProfile {...temperamentObject} theme={theme} />
            )}
            {(!currentResult ||
              currentResult.temperament === "Not Submitted") && (
              <Stack align="center" style={{ height: "100%" }} justify="center">
                <Title>
                  Your results will be uploaded here after you have taken the
                  assessment
                </Title>
              </Stack>
            )}
          </Box>
          <Box style={{ height: "100%" }}>
            <CircleGroup
              items={temperaments}
              radius={130}
              outerRadius={190}
              setCurrentTemperament={setCurrentTemperament}
              theme={theme}
              currentResult={currentResult}
            />
          </Box>
        </SimpleGrid>
      )}
      {!loading && view === "table" && (
        <BehavioursTable
          temperaments={temperaments}
          theme={theme}
          currentResult={currentResult}
        />
      )}
      <Group position="right">
        {!loading && <Text>©2024 Daryll Scott | All Rights Reserved</Text>}
      </Group>
    </WidgetFrame>
  );
}
