import { useContext, useMemo, useState } from "react";
import CrudScreen from "../common/CrudScreen";
import AppContext from "../../context/AppContext";
import { taskSchema as schema } from "../../context/dataSchema";
import {
  createTask as createQuery,
  updateTask as updateQuery,
  deleteTask as deleteQuery,
} from "../../graphql/mutations";

export default function Tasks() {
  const { dataObject } = useContext(AppContext);
  const { tasks: data = [] } = dataObject;

  const screenProps = useMemo(
    () => ({
      data,
      schema,
      title: "Tasks",
      quickStats: null,
      tableColumns: ["name", "project"],
      filterFields: [],
      recordName: "Task",
      pagePath: "tasks",
      dataObjectKey: "tasks",
      formProps: {
        createQuery,
        updateQuery,
        deleteQuery,
        dataObjectKey: "tasks",
      },
      modulesToLoad: ["Verticals", "People", "Projects", "Tasks"],
    }),
    [data]
  );
  return <CrudScreen {...screenProps} />;
}
