import { useContext, useMemo } from "react";
import CrudScreen from "../../common/CrudScreen";
import AppContext from "../../../context/AppContext";
import { userTypeSchema as schema } from "../../../context/dataSchema";
import {
  createUserType as createQuery,
  updateUserType as updateQuery,
  deleteUserType as deleteQuery,
} from "../../../graphql/mutations";

export default function UserTypes() {
  const {
    dataObject: { userTypes: data = [] },
  } = useContext(AppContext);

  const screenProps = useMemo(
    () => ({
      data,
      schema,
      title: "Manage User Types",
      quickStats: null,
      tableColumns: ["name"],
      filterFields: [],
      recordName: "User Type",
      pagePath: "user-types",
      dataObjectKey: "userTypes",
      formProps: {
        createQuery,
        updateQuery,
        deleteQuery,
        dataObjectKey: "userTypes",
      },
      modulesToLoad: [
        "Tenants",
        "Verticals",
        "Programmes",
        "Accounts",
        "User Types",
      ],
    }),
    [data]
  );
  return <CrudScreen {...screenProps} />;
}
