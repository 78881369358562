import { useEffect, useState } from "react";
import {
  Button,
  CloseButton,
  Group,
  NativeSelect,
  TextInput,
} from "@mantine/core";

export default function WidgetFilterConditionRow(props: any) {
  const {
    sourceFields,
    updateFilterObject,
    operand: inputOperand,
    sourceField: inputSourceField,
    value,
    includeExclude: inputIncludeExclude,
    disabled,
    deleteFilterRow,
    rowIndex,
  } = props;
  const [includeExclude, setIncludeExclude] = useState(
    inputIncludeExclude ? inputIncludeExclude : ""
  );
  const [sourceField, setSourceField] = useState(inputSourceField);
  const [operand, setOperand] = useState(inputOperand);
  const [valueOne, setValueOne] = useState(value);
  const [valueTwo, setValueTwo] = useState("");

  useEffect(() => {
    if (
      !["", "Is Null", "Isn't Null", "Is Blank", "Isn't Blank"].includes(
        operand
      )
    ) {
      setValueTwo("");
    }
  }, [operand]);

  return (
    <Group>
      <NativeSelect
        data={["", "Include", "Exclude"]}
        value={includeExclude}
        onChange={(e) => setIncludeExclude(e.target.value)}
        disabled={disabled}
      />
      <NativeSelect
        data={sourceFields}
        value={sourceField}
        onChange={(e) => setSourceField(e.target.value)}
        disabled={disabled}
      />
      <NativeSelect
        data={[
          "",
          "Equal To",
          "Not Equal To",
          "Contains",
          "Is Null",
          "Isn't Null",
          "Is Blank",
          "Isn't Blank",
          "Greater Than",
          "Less Than",
          "Between",
        ]}
        value={operand}
        onChange={(e) => setOperand(e.target.value)}
        disabled={disabled}
      />
      {!["", "Is Null", "Isn't Null", "Is Blank", "Isn't Blank"].includes(
        operand
      ) ? (
        <TextInput
          value={valueOne}
          onChange={(e) => setValueOne(e.target.value)}
          disabled={disabled}
        />
      ) : null}
      {["Between"].includes(operand) ? (
        <TextInput
          value={valueTwo}
          onChange={(e) => setValueTwo(e.target.value)}
          disabled={disabled}
        />
      ) : null}
      {disabled ? (
        <CloseButton
          size="xl"
          iconSize={20}
          onClick={() => deleteFilterRow(rowIndex)}
        />
      ) : null}
      {!disabled ? (
        <Button
          disabled={
            !(
              // includeExclude &&
              // sourceField &&
              // operand &&
              // valueOne &&
              // (valueTwo || !["", "Is Null", "Isn't Null"].includes(operand))
              (
                includeExclude &&
                sourceField &&
                operand &&
                ([
                  "",
                  "Is Null",
                  "Isn't Null",
                  "Is Blank",
                  "Isn't Blank",
                ].includes(operand) ||
                  valueOne) &&
                (valueTwo || !["Between"].includes(operand))
              )
            )
          }
          onClick={() => {
            updateFilterObject(
              includeExclude,
              sourceField,
              operand,
              valueOne,
              valueTwo
            );
            setIncludeExclude("");
            setSourceField("");
            setOperand("");
            setValueOne("");
            setValueTwo("");
          }}
        >
          Add
        </Button>
      ) : null}
    </Group>
  );
}
