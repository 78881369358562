import { Tabs, Group, Button, ScrollArea, Box } from "@mantine/core";
import DataView from "../dataview/DataView";
import WidgetGrid from "../WidgetGrid/WidgetGrid";
import AddWidgetModal from "../AddWidgetModal";
import AddDashboardModal from "../AddDashboardModal";
import SharingModal from "../SharingModal";
import { deleteDashboard } from "../../../graphql/mutations";
import { DashboardToolbar } from "../DashboardToolbar";
import { useDashboard } from "./useDashboard";
import { useDashboardData } from "./useDashboardData";
import useStyles from "./Dashboard.styles";
import ConfirmDeleteDashboardModal from "../ConfirmDeleteDashboardModal";

export default function Dashboard() {
  const { classes } = useStyles();
  const {
    isSystemAdmin,
    addDashboard,
    deleteDashboardFunction,
    addWidget,
    updateDashboardFunction,
    deleteWidget,
    updateWidget,
    currentDashboard,
    setCurrentDashboard,
    tabData,
    opened,
    open,
    close,
    chartProps,
    addDashboardOpened,
    openAddDashboard,
    closeAddDashboard,
    sharingModalOpened,
    openSharingModal,
    currentWidget,
    setCurrentWidget,
    currentWidgetIndex,
    setCurrentWidgetIndex,
    closeSharingModal,
    dashboardFormData,
    setDashboardFormData,
    dashboardFilterObject,
    setDashboardFilterObject,
    setChartProps,
    tabValue,
    navigate,
    comparisonPeriodOverride,
    setComparisonPeriodOverride,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    layout,
    setLayout,
    editMode,
    setEditMode,
    currentProgrammes,
    currentProgramme,
    dataObject,
    os,
    loading,
    username,
    dashboards,
    deleteModalOpened,
    openDeleteModal,
    closeDeleteModal,
  } = useDashboard();

  const {
    globalDashboardFilteredDataObject,
    dashboardDataObject,
    dataSourceObject,
  } = useDashboardData({
    currentDashboard,
    dashboardFilterObject,
    currentProgrammes,
    currentProgramme,
    dataObject,
    opened,
    chartProps,
    comparisonPeriodOverride,
  });

  return (
    <Box
      sx={{
        maxHeight:
          "calc(100vh - var(--mantine-footer-height, 0px) - var(--mantine-header-height, 0px) )",
        height:
          "calc(100vh - var(--mantine-footer-height, 0px) - var(--mantine-header-height, 0px))",
        overflowX: "hidden",
        overflowY: "scroll",
        paddingRight: "1em",
        paddingTop: "0.5em",
      }}
      // offsetScrollbars={true}
    >
      <Tabs
        value={tabValue}
        onTabChange={(value) => navigate(`/dashboard/${value}`)}
        keepMounted={false}
        // sx={{ height: "100%", maxHeight: "100%", maxWidth: "100%" }}
        // sx={{ height: "100%", maxHeight: "100%", maxWidth: "100%" }}
      >
        <div className={classes.grid}>
          {/* <Group> */}
          <ScrollArea scrollbarSize={0}>
            <Group noWrap={true}>
              <Tabs.List sx={{ flexWrap: "nowrap" }}>
                {tabData.map((tab: any) => (
                  <Tabs.Tab
                    value={tab.value}
                    key={tab.value}
                    onDoubleClick={(e) => {
                      setDashboardFormData({ ...tab.dashboard });
                      openAddDashboard();
                    }}
                  >
                    {tab.title}
                  </Tabs.Tab>
                ))}
              </Tabs.List>
              {!["ios", "android"].includes(os) &&
                isSystemAdmin &&
                !loading && (
                  <Button
                    variant={"subtle"}
                    radius={"xl"}
                    onClick={openAddDashboard}
                  >
                    +
                  </Button>
                )}
            </Group>
          </ScrollArea>
          {/* </Group> */}

          <div>
            {currentDashboard && (
              <DashboardToolbar
                currentDashboard={currentDashboard}
                editMode={editMode}
                setCurrentDashboard={setCurrentDashboard}
                updateDashboardFunction={updateDashboardFunction}
                dashboardFilterObject={dashboardFilterObject}
                setDashboardFilterObject={setDashboardFilterObject}
                comparisonPeriodOverride={comparisonPeriodOverride}
                setComparisonPeriodOverride={setComparisonPeriodOverride}
                globalDashboardFilteredDataObject={
                  globalDashboardFilteredDataObject
                }
                dashboardDataObject={dashboardDataObject}
                setEditMode={setEditMode}
                open={open}
                canEdit={isSystemAdmin || username === currentDashboard?.owner}
                openSharingModal={openSharingModal}
                layout={layout}
                os={os}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                openDeleteModal={openDeleteModal}
              />
            )}
            {dashboards.map((dashboard: any) => {
              return (
                <Tabs.Panel
                  key={dashboard.name}
                  //@ts-ignore
                  value={dashboard.id}
                  pt="xs"
                  className={classes.tabPanel}
                >
                  <WidgetGrid
                    open={open}
                    setCurrentWidget={setCurrentWidget}
                    dashboardObject={dashboard}
                    setCurrentDashboard={setCurrentDashboard}
                    currentDashboard={currentDashboard}
                    deleteWidget={deleteWidget}
                    setCurrentWidgetIndex={setCurrentWidgetIndex}
                    addWidget={addWidget}
                    dashboardFilterObject={dashboardFilterObject}
                    setDashboardFilterObject={setDashboardFilterObject}
                    globalDashboardFilteredDataObject={
                      globalDashboardFilteredDataObject
                    }
                    comparisonPeriodOverride={comparisonPeriodOverride}
                    startDate={startDate}
                    endDate={endDate}
                    setLayout={setLayout}
                    layout={layout}
                    editMode={editMode}
                  />
                </Tabs.Panel>
              );
            })}
            <Tabs.Panel value="data-view" pt="xs" className={classes.tabPanel}>
              <DataView />
            </Tabs.Panel>
          </div>
        </div>
      </Tabs>
      <AddWidgetModal
        opened={opened}
        onClose={close}
        addWidget={addWidget}
        currentWidget={currentWidget}
        setCurrentWidget={setCurrentWidget}
        updateWidget={updateWidget}
        currentWidgetIndex={currentWidgetIndex}
        setCurrentWidgetIndex={setCurrentWidgetIndex}
        chartProps={chartProps}
        setChartProps={setChartProps}
        dataSourceObject={dataSourceObject}
        globalDashboardFilteredDataObject={globalDashboardFilteredDataObject}
      />
      <AddDashboardModal
        opened={addDashboardOpened}
        onClose={closeAddDashboard}
        addDashboard={addDashboard}
        dashboardFormData={dashboardFormData}
        setDashboardFormData={setDashboardFormData}
        updateDashboardFunction={updateDashboardFunction}
      />
      <SharingModal
        opened={sharingModalOpened}
        onClose={closeSharingModal}
        currentDashboard={currentDashboard}
        updateDashboardFunction={updateDashboardFunction}
        setCurrentDashboard={setCurrentDashboard}
      />
      <ConfirmDeleteDashboardModal
        opened={deleteModalOpened}
        onClose={closeDeleteModal}
        currentDashboard={currentDashboard}
        deleteDashboardFunction={deleteDashboardFunction}
      />
    </Box>
  );
}
