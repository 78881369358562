import { useContext } from "react";
import NewCrudScreen from "../common/CrudScreen";
import AppContext from "../../context/AppContext";
import { risksSchema } from "../../context/dataSchema";
import RiskQuickStats from "./RiskQuickStats";
import { createRisk, deleteRisk } from "../../graphql/mutations";
import { updateRisk } from "../../graphql-custom/updatedMutations";

export default function Risks() {
  const {
    dataObject,
    // currentProgrammes,
    // currentVertical,
    // currentAccount,
    // currentProgramme,
  } = useContext(AppContext);
  const { risks = [] } = dataObject;

  const globalFilteredData = risks.filter((row: any) => {
    return true; //currentProgrammes.includes(row.programmeRisksId);
  });

  const screenProps = {
    data: globalFilteredData,
    schema: risksSchema,
    title: "Risks",
    quickStats: <RiskQuickStats risks={risks} />,
    tableColumns: ["name", "description", "riskFactor", "brag"],
    filterFields: ["status"],
    recordName: "Risk",
    pagePath: "risks",
    dataObjectKey: "risks",
    formProps: {
      createQuery: createRisk,
      updateQuery: updateRisk,
      deleteQuery: deleteRisk,
    },
    modulesToLoad: [
      "Verticals",
      "Accounts",
      "Programmes",
      "Projects",
      "People",
      "Risks",
    ],
  };
  return <NewCrudScreen {...screenProps} />;
}
