import { PieChart, Pie, Cell, Tooltip, Label } from "recharts";
import { getRiskBrag } from "../common/functions/riskFunctions";
import { useMantineTheme } from "@mantine/core";

export default function NewRisksChart(props: any) {
  //   const { data } = props;

  //   const colors = [
  //     "rgba(20, 21, 23, 0.7)",
  //     "rgba(224, 49, 49, 0.7)",
  //     "rgba(240, 140, 0, 0.7)",
  //     "rgba(47, 158, 68, 0.7)",
  //   ];

  const theme = useMantineTheme();

  const countObject = props.data.reduce((object: any, risk: any) => {
    const riskCrag = getRiskBrag(risk);
    if (!object[riskCrag]) object[riskCrag] = 0;
    object[riskCrag] += 1;
    return object;
  }, {});

  const data = [
    // @ts-ignore
    {
      name: "Very High",
      value: countObject["Very High"] ? countObject["Very High"] : 0,
      color: theme.colors.red[8],
    },
    // @ts-ignore
    {
      name: "High",
      value: countObject["High"] ? countObject["High"] : 0,
      color: theme.colors.orange[8],
    },
    // @ts-ignore
    {
      name: "Medium",
      value: countObject["Medium"] ? countObject["Medium"] : 0,
      color: theme.colors.yellow[8],
    },
    // @ts-ignore
    {
      name: "Low",
      value: countObject["Low"] ? countObject["Low"] : 0,
      color: theme.colors.green[8],
    },
  ];

  //   const data = ["Very High","High","Medium","Low"].reduce((list,category,index) => {
  //         return
  //   },[]);

  const filteredData = data.filter((row) => row.value > 0);

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = (props: any) => {
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      /*percent, index,*/ value,
    } = props;

    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
        fontSize="0.75em"
      >
        {/* {`${(percent * 100).toFixed(0)}%`} */}
        {value}
      </text>
    );
  };

  return (
    // <ResponsiveContainer width="100%" height="100%">
    <PieChart width={100} height={100}>
      <Tooltip />
      <Pie
        data={filteredData}
        dataKey={"value"}
        cx="50%"
        cy="50%"
        innerRadius={20}
        outerRadius={50}
        animationBegin={0}
        animationDuration={500}
        labelLine={false}
        label={renderCustomizedLabel}
      >
        {filteredData.map((entry: any, index: number) => (
          <Cell key={`cell-${index}`} fill={entry.color} />
        ))}
        <Label position="inside" />
      </Pie>
    </PieChart>
    // </ResponsiveContainer>
  );
}
