import { Badge, Box, Paper, SimpleGrid, Text } from "@mantine/core";
import { formatCurrency } from "../../functions/formatFunctions";

export const CustomTooltip = ({
  active,
  payload: payloadList,
  label,
  formatFunction,
  comparisonFieldString,
  includeTotal = true,
}: any) => {
  const payload = payloadList[0]?.payload ?? {};

  return (
    <Paper sx={{ padding: "0.5em", zIndex: 400, fontSize: "0.8em" }}>
      <SimpleGrid cols={2}>
        <Text fw={700}>{payload.groupName}</Text>
        <Badge>{payload.paretoClassification}</Badge>
        <Text>Value</Text>
        <Text>{formatCurrency(payload.value, "GBP", 2)}</Text>
        <Text>Running %</Text>
        <Text>
          {Number(
            payload.runningPercentageTotal
              ? payload.runningPercentageTotal * 100
              : 0
          )?.toFixed(0) ?? 0}
          %
        </Text>
      </SimpleGrid>
    </Paper>
  );
};
