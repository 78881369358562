import { useState } from "react";
import {
  createStyles,
  Table as MantineTable,
  ScrollArea,
  UnstyledButton,
  Group,
  Text,
  Center,
} from "@mantine/core";
import { ChevronUp, ChevronDown, Selector } from "tabler-icons-react";

const useStyles = createStyles((theme) => ({
  textColor: {
    color:
      theme.colorScheme === "dark"
        ? theme.colors[theme.primaryColor][6]
        : theme.colors[theme.primaryColor][9],
  },
  header: {
    position: "sticky",
    top: 0,
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
    transition: "box-shadow 150ms ease",

    "&::after": {
      content: '""',
      position: "absolute",
      left: 0,
      right: 0,
      bottom: 0,
      borderBottom: `1px solid ${
        theme.colorScheme === "dark"
          ? theme.colors[theme.primaryColor][4]
          : theme.colors[theme.primaryColor][8]
      }`,
      // borderBottom: `1px solid ${theme.colors[theme.primaryColor][4]}`,
    },
  },

  scrolled: {
    boxShadow: theme.shadows.sm,
  },

  th: {
    padding: "0 !important",
    minWidth: 150,
  },

  control: {
    width: "100%",
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    },
  },

  icon: {
    width: 21,
    height: 21,
    borderRadius: 21,
  },

  row: {
    cursor: "pointer",
  },
}));

interface TableProps {
  data: any[];
  columns: {
    title: String | JSX.Element;
    field: String;
    formatFunction?: any;
    sortFunction?: any;
    accessorFunction?: any;
    disableSorting?: null | boolean;
  }[];
  onClickFunction?: any;
  defaultSortColumn?: string;
  defaultSortDirection?: string;
}

function Th({ title, ascending, sorted, onSort, disableSorting }: any) {
  const { classes } = useStyles();
  const Icon = sorted ? (ascending ? ChevronUp : ChevronDown) : Selector;
  return (
    <th className={classes.th}>
      <UnstyledButton
        onClick={disableSorting ? null : onSort}
        className={classes.control}
      >
        <Group position="apart">
          <Text weight={500} size="sm">
            {title}
          </Text>
          {!disableSorting ? (
            <Center className={classes.icon}>
              <Icon size={14} />
            </Center>
          ) : null}
        </Group>
      </UnstyledButton>
    </th>
  );
}

export default function Table(props: TableProps) {
  const {
    data,
    columns,
    onClickFunction,
    defaultSortColumn,
    defaultSortDirection,
  } = props;
  const { classes, cx } = useStyles();
  const [scrolled, setScrolled] = useState(false);
  const [sortColumn, setSortColumn] = useState<any>(
    defaultSortColumn ? defaultSortColumn : null
  );
  const [ascending, setAscending] = useState<any>(
    defaultSortDirection === "asc" ? true : false
  );

  const sortColumnObject = sortColumn
    ? columns.filter((column) => {
        return column.field === sortColumn;
      })[0]
    : null;

  const accessorFunction =
    sortColumnObject && sortColumnObject.accessorFunction
      ? sortColumnObject.accessorFunction
      : (row: any) => row[sortColumn];
  const sortFunction =
    sortColumnObject && sortColumnObject.sortFunction
      ? sortColumnObject.sortFunction
      : (a: any, b: any) => {
          const aVal = accessorFunction(a);
          const bVal = accessorFunction(b);
          return aVal === bVal
            ? 0
            : aVal > bVal
            ? ascending
              ? 1
              : -1
            : ascending
            ? -1
            : 1;
        };

  const sortedData = sortColumn ? data.sort(sortFunction) : data;

  return (
    <ScrollArea
      sx={{ maxHeight: "100%", maxWidth: "100%" }}
      onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
      offsetScrollbars={true}
    >
      <MantineTable sx={{ minWidth: 600 }} highlightOnHover>
        <thead className={cx(classes.header, { [classes.scrolled]: scrolled })}>
          <tr>
            {columns.map((column, index) => (
              // @ts-ignore
              <Th
                key={column.field + index.toString()}
                title={column.title}
                ascending={ascending}
                disableSorting={column.disableSorting}
                sorted={sortColumn === column.field}
                onSort={() => {
                  const newAscending =
                    sortColumn === column.field ? !ascending : false;
                  setAscending(newAscending);
                  setSortColumn(column.field);
                }}
              ></Th>
            ))}
          </tr>
        </thead>
        <tbody>
          {sortedData.map((row: any, rowIndex: any) => {
            return (
              <tr
                key={row.integrationSystemId ? row.integrationSystemId : row.id}
                onClick={() => onClickFunction(row)}
                className={classes.row}
              >
                {columns.map((column, columnIndex: any) => {
                  const { field } = column;
                  const value = column.accessorFunction
                    ? column.accessorFunction(row)
                    : // @ts-ignore
                      row[field];
                  return (
                    // @ts-ignore
                    <td key={rowIndex.toString() + columnIndex.toString()}>
                      {column.formatFunction
                        ? // @ts-ignore
                          column.formatFunction(value)
                        : // @ts-ignore
                          value}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </MantineTable>
    </ScrollArea>
  );
}
