export default function getDatasetAtPointInTime(data: any, date: any) {
  if (!data) return [];

  const dateString = new Date(date.setHours(23, 59, 59, 999)).toISOString();
  const copyOfData = JSON.parse(JSON.stringify(data));
  const newerRecordsRemoved = copyOfData.filter((row: any) => {
    return row?.createdAt < dateString;
  });

  const final = newerRecordsRemoved.map((row: any) => {
    Object.keys(row).forEach((key) => {
      if (row[key]?.items) {
        row[key].items = getDatasetAtPointInTime(row[key].items, date);
      }
    });

    if (row.audit) {
      const parsedAudit =
        typeof row.audit === "string" ? JSON.parse(row.audit) : row.audit;
      for (const update of parsedAudit) {
        if (update.dateOfUpdate > dateString) {
          for (var field in update.previousValues) {
            row[field] = update.previousValues[field];
          }
        }
      }
    }
    row.period = dateString.slice(0, 10);
    return row;
  });

  return final;
}
