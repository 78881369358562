import { useContext } from "react";
import CrudScreen from "../common/CrudScreen";
import AppContext from "../../context/AppContext";
import { pipelineSchema as schema } from "../../context/dataSchema";
import {
  createPipeline as createQuery,
  updatePipeline as updateQuery,
  deletePipeline as deleteQuery,
} from "../../graphql/mutations";

export default function Pipelines() {
  const { dataObject } = useContext(AppContext);
  const { pipelines: data = [] } = dataObject;

  const screenProps = {
    data,
    schema,
    title: "Pipeline",
    quickStats: null,
    tableColumns: ["dealName", "stageName", "accountName", "amount"],
    filterFields: [],
    recordName: "Pipeline",
    pagePath: "pipeline",
    dataObjectKey: "pipelines",
    formProps: {
      createQuery,
      updateQuery,
      deleteQuery,
      dataObjectKey: "pipelines",
    },
    modulesToLoad: ["Accounts", "Stages", "Pipelines"],
  };
  return <CrudScreen {...screenProps} />;
}
