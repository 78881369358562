import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import quarterOfYear from "dayjs/plugin/quarterOfYear";

dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(quarterOfYear);

function getLastDayOfLastMonth(date: Date) {
  const dateCopy = new Date(date.getTime());
  dateCopy.setDate(0);
  dateCopy.setHours(23, 59, 59, 999);
  return dateCopy;
}

function getPreviousSixMonthsOfEndDates(startDate: Date) {
  let latestDate = startDate;
  const returnArray: any = [];
  [1, 2, 3, 4, 5, 6].forEach((n) => {
    latestDate = getLastDayOfLastMonth(latestDate);
    returnArray.push(latestDate);
  });
  return returnArray;
}

function getPreviousMonthsLastDay(n: number) {
  const currentDate = dayjs(); // Current date
  const lastDayOfCurrentMonth = currentDate.endOf("month"); // Last day of current month

  const result = [];
  for (let i = 0; i < n; i++) {
    const previousMonth = lastDayOfCurrentMonth.subtract(i + 1, "month"); // Subtract i + 1 months
    const lastDayOfPreviousMonth = previousMonth.endOf("month"); // Last day of previous month
    result.push(lastDayOfPreviousMonth.format("YYYY-MM-DD"));
  }

  return result;
}

function getPreviousQuartersLastDay(n: number) {
  const currentDate = dayjs(); // Current date
  const lastDayOfCurrentMonth = currentDate.endOf("month");

  const result = [];
  for (let i = 0; i < n; i++) {
    const previousQuarter = lastDayOfCurrentMonth.subtract(i + 1, "quarter");
    const lastDayOfPreviousQuarter = previousQuarter.endOf("quarter");
    result.push(lastDayOfPreviousQuarter.format("YYYY-MM-DD"));
  }

  return result;
}

function getPreviousDays(n: number) {
  const currentDate = dayjs(); // Current date

  const result = [];
  for (let i = 0; i < n; i++) {
    const previousDay = currentDate.subtract(i + 1, "day"); // Subtract i + 1 months
    result.push(previousDay.format("YYYY-MM-DD"));
  }

  return result;
}

function calculatePercentageOfDurationPassed({
  startDate,
  endDate,
  currentDate = new Date(),
}: any) {
  if (!startDate || !endDate) return 0;
  const current = dayjs(currentDate);
  const start = dayjs(startDate);
  const end = dayjs(endDate);

  if (current.isAfter(end)) return 1;
  if (current.isBefore(start)) return 0;
  const totalDuration = end.diff(start);
  const elapsedDuration = current.diff(start);

  const elapsedPercentage = elapsedDuration / totalDuration;

  return elapsedPercentage;
}

function networkDays({ startDate, endDate }: any) {
  let start = dayjs(startDate);
  let end = dayjs(endDate);
  let count = 0;

  while (start.isBefore(end) || start.isSame(end, "day")) {
    const day = start.day();
    if (day !== 0 && day !== 6) {
      count++;
    }
    start = start.add(1, "day");
  }

  return count;
}

const formatDateToYYYYMMDD = (date: Date) => {
  return dayjs(date).format("YYYY-MM-DD");
};
const formatDateToYYYYQQ = (date: Date) => {
  return `${dayjs(date).format("YYYY")} Q${dayjs(date).quarter()}`;
};
const formatDateToYYYYMM = (date: Date) => {
  return dayjs(date).format("YYYY-MM");
};
const formatDateToYYYY = (date: Date) => {
  return dayjs(date).format("YYYY");
};

export {
  getLastDayOfLastMonth,
  getPreviousSixMonthsOfEndDates,
  getPreviousMonthsLastDay,
  getPreviousQuartersLastDay,
  getPreviousDays,
  calculatePercentageOfDurationPassed,
  networkDays,
  formatDateToYYYYMMDD,
  formatDateToYYYYQQ,
  formatDateToYYYYMM,
  formatDateToYYYY,
};
