import { Paper, createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  paper: {
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[6]
        : theme.colors.gray[0],
    height: "100%",
    width: "100%",
    overflow: "hidden",
  },
}));

export default function CustomPaper(props: any) {
  const { classes } = useStyles();
  return (
    <Paper withBorder p="md" radius="md" className={classes.paper} {...props}>
      {props.children}
    </Paper>
  );
}
