import {
  useState,
  useEffect,
  useLayoutEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { API, Analytics, Auth } from "aws-amplify";
import { useUserPreferences } from "./hooks/useUserPreferences";
import { useSystemPreferences } from "./hooks/useSystemPreferences";
import AppLayout from "./components/layout/AppLayout";
import { LoadingOverlay } from "@mantine/core";
import { useAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import AppContext from "./context/AppContext";
import fetchModuleRecords from "./components/common/functions/fetchModuleRecords";
import {
  listAccounts,
  listVerticals,
  listTenants,
  listProgrammes,
  listDashboards,
  listUserTypes,
} from "./graphql-custom/generated";
import { useOs } from "@mantine/hooks";
import { listCustomDatasets, userFunctions } from "./graphql/queries";

// import CustomLoader from "./components/common/CustomLoadingOverlay/CustomLoader";

function AppContent(props: any) {
  const { setColor } = props;
  const initialRender = useRef(true);
  let { user: contextUser, signOut } = useAuthenticator((context) => [
    context.user,
  ]);

  const [user, setUser] = useState(contextUser);

  const updateUser = useCallback(async () => {
    const updatedUser = await Auth.currentAuthenticatedUser({
      bypassCache: true,
    });
    setUser(updatedUser);
  }, []);

  useEffect(() => {
    if (!user.attributes) {
      updateUser();
    }
  }, [updateUser, user]);

  const {
    //@ts-ignore
    email: ownerEmail = null, //@ts-ignore
    preferred_username = null, //@ts-ignore
    "custom:tenant": tenant = null, //@ts-ignore
    "custom:viewType": viewType = null, //@ts-ignore
    "custom:accounts": account = null, //@ts-ignore
    "custom:accounts": programmes = null, //@ts-ignore
    groups, //@ts-ignore
    username,
  } = useMemo(() => {
    if (!user) return {};
    return {
      ...user,
      ...user.attributes, //@ts-ignore
      groups: user.signInUserSession.accessToken.payload["cognito:groups"],
    };
  }, [user]);

  const os = useOs();

  const [loading, setLoading] = useState(true);
  const [dataObject, setDataObject] = useState({});
  const [dashboards, setDashboards] = useState([]);

  const [currentTenant, setCurrentTenant] = useState(null);
  const [currentVertical, setCurrentVertical] = useState(null);
  const [currentAccount, setCurrentAccount] = useState(null);
  const [currentProgrammes, setCurrentProgrammes] = useState([]);
  const [currentProgramme, setCurrentProgramme] = useState(null);

  const { preferenceObject, setPreferenceObject } = useUserPreferences({
    ownerEmail,
  });

  const {
    preferenceObject: systemPreferenceObject,
    setPreferenceObject: setSystemPreferenceObject,
  } = useSystemPreferences({
    ownerEmail: currentTenant,
    setColor,
    viewType,
    account,
  });

  useEffect(() => {
    if (!groups.includes("System_Admin")) {
      Analytics.autoTrack("session", {
        enable: true,
        attributes: {
          user: user.username,
          tenant,
        },
        provider: "AWSPinpoint",
      });

      Analytics.autoTrack("pageView", {
        enable: true,
        eventName: "pageView",
        attributes: {
          user: user.username,
          tenant,
        },
        type: "SPA",
        provider: "AWSPinpoint",
        getUrl: () => {
          return window.location.origin + window.location.pathname;
        },
      });
    }
  }, []);

  useEffect(() => {
    if (os === "ios") {
      //@ts-ignore
      document
        .querySelector("[name=viewport]")
        .setAttribute(
          "content",
          "width=device-width, initial-scale=1, maximum-scale=1"
        );
    }
  }, [os]);

  useLayoutEffect(() => {
    if (systemPreferenceObject) {
      document.title = systemPreferenceObject.appearance.systemTitle ?? "PMO";
    }
  }, [systemPreferenceObject]);

  // useEffect(() => {
  //   if (currentTenant) {
  //     updateLogo(
  //       viewType === "Internal" ? currentTenant : `${currentTenant}_${account}`
  //     );
  //   }
  // }, [currentTenant]);

  const queuedModules = useRef<string[]>([
    "Tenants#Live",
    "Verticals#Live",
    "Accounts#Live",
    "Programmes#Live",
    "Periods#Live",
  ]);

  const value = useMemo(
    () => ({
      groups: groups ? groups : ["Standard"],
      username,
      preferred_username,
      queuedModules,
      tenant,
      account,
      programmes,
      viewType,
      ownerEmail,
      preferenceObject,
      setPreferenceObject,
      systemPreferenceObject,
      setSystemPreferenceObject,
      dataObject,
      setDataObject,
      dashboards,
      setDashboards,
      currentTenant,
      setCurrentTenant,
      currentVertical,
      setCurrentVertical,
      currentAccount,
      setCurrentAccount,
      currentProgrammes,
      setCurrentProgrammes,
      currentProgramme,
      setCurrentProgramme,
      setColor,
      setLoading,
      loading,
      os,
    }),
    [
      account,
      currentAccount,
      currentProgramme,
      currentProgrammes,
      currentTenant,
      currentVertical,
      dashboards,
      dataObject,
      groups,
      loading,
      os,
      ownerEmail,
      preferenceObject,
      preferred_username,
      programmes,
      setColor,
      setPreferenceObject,
      setSystemPreferenceObject,
      systemPreferenceObject,
      tenant,
      username,
      viewType,
    ]
  );

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      loadTenants();
    }
  }, []);

  useEffect(() => {
    if (currentTenant) {
      setLoading(true);
      window.localStorage.setItem("cpo-id", currentTenant);
      setDataObject((dataObject) => {
        //@ts-ignore
        const { tenants } = dataObject;
        const newDataObject = { tenants };
        return newDataObject;
      });
      setDashboards((dashboards) => []);

      queuedModules.current = [
        "Tenants#Live",
        "Verticals#Live",
        "Accounts#Live",
        "Programmes#Live",
        "User Types#Live",
        "Custom Datasets#Live",
      ];

      initialDataLoad(currentTenant);
    }
    // }
  }, [currentTenant]);

  const loadTenants = useCallback(async () => {
    const tenants = await fetchModuleRecords({
      listQuery: { listTenants },
    });

    const storageTenant = window.localStorage.getItem("cpo-id");

    let newCurrentTenant;

    if (
      storageTenant &&
      tenants.map((tenant: any) => tenant.id).includes(storageTenant)
    ) {
      newCurrentTenant = storageTenant;
    } else {
      newCurrentTenant = tenants[0] ? tenants[0].id : null;
      window.localStorage.setItem("cpo-id", newCurrentTenant);
    }

    const newDataObject = {
      tenants,
    };

    setDataObject((dataObject) => {
      return { ...newDataObject, ...dataObject };
    });
    setCurrentTenant(newCurrentTenant);
  }, []);

  const initialDataLoad = useCallback(async (currentTenant: any) => {
    const [
      verticals,
      accounts,
      programmes,
      returnedDashboards,
      customDatasets,
      userTypes,
    ] = await Promise.all([
      await fetchModuleRecords({
        listQuery: { listVerticals },
        currentTenant: currentTenant,
      }),
      await fetchModuleRecords({
        listQuery: { listAccounts },
        currentTenant: currentTenant,
      }),
      await fetchModuleRecords({
        listQuery: { listProgrammes },
        currentTenant: currentTenant,
      }),
      await fetchModuleRecords({
        listQuery: { listDashboards },
        currentTenant: currentTenant,
      }),
      await fetchModuleRecords({
        listQuery: { listCustomDatasets },
        currentTenant: currentTenant,
      }),
      await fetchModuleRecords({
        listQuery: { listUserTypes },
        currentTenant: currentTenant,
      }),
    ]);

    setDashboards(returnedDashboards);

    const newVertical = verticals.length > 1 ? "All" : accounts[0]?.id;

    setCurrentVertical(newVertical);

    const newDataObject = {
      verticals,
      accounts,
      programmes,
      userTypes,
      customDatasets,
    };

    const newCurrentAccount = accounts.length > 1 ? "All" : accounts[0]?.id;

    setCurrentAccount(newCurrentAccount);

    setDataObject((dataObject) => {
      return { ...newDataObject, ...dataObject };
    });
    setLoading(false);

    const usersResponse = await API.graphql({
      query: userFunctions,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: {
        input: JSON.stringify({ currentTenant, action: "listUsers" }),
      },
    });
    //@ts-ignore
    const users = JSON.parse(JSON.parse(usersResponse.data.userFunctions).body);

    setDataObject((dataObject) => {
      return { users, ...dataObject };
    });
  }, []);

  return (
    <AppContext.Provider value={value}>
      <LoadingOverlay
        visible={loading}
        overlayOpacity={0.7}
        // loader={<CustomLoader />}
      />
      <Router>
        <AppLayout signOut={signOut} user={user} />
      </Router>
    </AppContext.Provider>
  );
}

export default AppContent;
