import { Button, Group, useMantineColorScheme } from "@mantine/core";
import HeroBanner from "./HeroBanner";
import { FeatureSection } from "./FeatureSection";
// import { GetInTouch } from "./GetInTouch";
import { ContactForm } from "./ContactForm/ContactForm";
// import { ColorSchemeToggle } from "../layout/ColorSchemeToggle";
import { useNavigate } from "react-router-dom";
import { PricingSection } from "./PricingSection/PricingSection";
import { Blog } from "./Blog";
import { useEffect } from "react";

export default function Home() {
  const navigate = useNavigate();
  const { toggleColorScheme } = useMantineColorScheme();
  // const theme = useMantineTheme();

  useEffect(() => {
    toggleColorScheme("dark");
  }, []);

  return (
    <div style={{ height: "100vh", overflow: "scroll", width: "100vw" }}>
      <Group
        position="right"
        p={"md"}
        sx={{ position: "absolute", zIndex: 11, width: "100%" }}
      >
        <Button
          variant="filled"
          color={"lime"}
          radius={"xl"}
          onClick={() => navigate("/signin")}
        >
          Sign In
        </Button>
        {/* <ColorSchemeToggle /> */}
      </Group>
      <HeroBanner />
      <FeatureSection />
      <PricingSection />
      {/* <GetInTouch /> */}
      <ContactForm />
      <Blog />
    </div>
  );
}
