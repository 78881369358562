import { useContext } from "react";
import AppContext from "../../../../context/AppContext";
import { Table, useMantineTheme } from "@mantine/core";
import { formatCurrency, nFormatter } from "../../functions/formatFunctions";

function Row({ row, previousObject }: any) {
  const diffInRevenueFromLastPeriod = previousObject.revenueActualUSD
    ? row.revenueActualUSD - previousObject.revenueActualUSD
    : null;

  return (
    <tr>
      <td>{row.engagementName}</td>
      {/* <td>{formatCurrency(row.revenueSoldUSD, "$", 0)}</td> */}
      <td>{row.revenueSoldUSD}</td>
      <td>
        {formatCurrency(row.revenueActualUSD, "$", 0)}{" "}
        {diffInRevenueFromLastPeriod && diffInRevenueFromLastPeriod !== 0 ? (
          <span
            style={{
              color: diffInRevenueFromLastPeriod > 0 ? "green" : "red",
              fontSize: "0.8em",
            }}
          >
            ${nFormatter(diffInRevenueFromLastPeriod, 0)}
          </span>
        ) : (
          ""
        )}
      </td>
      <td>{formatCurrency(row.costActualUSD, "$", 0)}</td>
      <td>{Math.round(row.marginActual * 100)}%</td>
    </tr>
  );
}

const reduceFunction = (object: any, row: any) => {
  object[row.engId] = row;
  return object;
};

export default function TCPFinanceVisual({
  currentPeriod = "Live",
  previousPeriod = "2023-04-30",
}: any) {
  const {
    dataObject = {},
    currentProgrammes,
    currentProgramme,
  } = useContext(AppContext);
  const { pls = [] } = dataObject;
  const theme = useMantineTheme();
  const backgroundColor =
    theme.colorScheme === "dark"
      ? theme.colors["dark"][9]
      : theme.colors["gray"][3];

  const filteredPls = pls.filter(
    (pl: any) =>
      pl.programme.name === "TCP" &&
      currentProgrammes.includes(pl.programme.id) &&
      (currentProgramme === "All" || currentProgramme === pl.programme.id)
  );

  const currentPeriodRows: any[] = [];
  const previousPeriodRows: any[] = [];

  const totalsObject = {
    revenueSoldUSD: 0,
    revenueActualUSD: 0,
    costActualUSD: 0,
  };

  const marginList: any = [];

  filteredPls.forEach((row: any) => {
    if (row.period === currentPeriod) {
      currentPeriodRows.push(row);
      totalsObject.revenueSoldUSD += row.revenueSoldUSD;
      totalsObject.revenueActualUSD += row.revenueActualUSD;
      totalsObject.costActualUSD += row.costActualUSD;

      marginList.push(row.marginActual);
    }
    if (row.period === previousPeriod) previousPeriodRows.push(row);
  });

  const previousObject = previousPeriodRows.reduce(reduceFunction, {});

  const rows = currentPeriodRows
    .sort((a, b) => {
      return a.marginActual - b.marginActual;
    })
    .map((row: any, index: number) => {
      //@ts-ignore
      const prevObject = previousObject[row.engId]
        ? //@ts-ignore
          previousObject[row.engId]
        : {};
      return <Row key={row.id} row={row} previousObject={prevObject} />;
    });

  return (
    <div
      style={{
        position: "relative",
        height: "99%",
        minHeight: "99%",
        overflow: "scroll",
      }}
    >
      <Table>
        <thead style={{ position: "sticky", top: 0, backgroundColor }}>
          <tr>
            <th>Engagement Name</th>
            <th>Revenue Sold</th>
            <th>Revenue Actual</th>
            <th>Actual Cost</th>
            <th>Actual Margin</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
        <tfoot style={{ position: "sticky", bottom: 0, backgroundColor }}>
          <tr>
            <th>Grand Total</th>
            <th>{formatCurrency(totalsObject.revenueSoldUSD, "$", 0)}</th>
            <th>{formatCurrency(totalsObject.revenueActualUSD, "$", 0)}</th>
            <th>{formatCurrency(totalsObject.costActualUSD, "$", 0)}</th>
            <th>
              {Math.round(
                (marginList.reduce((total: any, num: any) => {
                  total += num;
                  return total;
                }, 0) *
                  100) /
                  marginList.length
              )}
              %
            </th>
          </tr>
        </tfoot>
      </Table>
    </div>
  );
}
