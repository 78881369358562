import React from "react";
import useStyles from "./ContactSupport.styles";
import { useContactSupport } from "./useContactSupport";
import { ActionIcon, Popover } from "@mantine/core";
import { IconHelp } from "@tabler/icons-react";
import { ContactSupportForm } from "./ContactSupportForm";

export type ContactSupportProps = {};

export function ContactSupport(props: ContactSupportProps) {
  const { classes, theme } = useStyles();
  const vars = useContactSupport();
  return (
    <Popover position="right-end" withArrow shadow="md">
      <Popover.Target>
        <ActionIcon variant={"subtle"} size={30} color={theme.primaryColor}>
          <IconHelp />
        </ActionIcon>
      </Popover.Target>
      <Popover.Dropdown>
        <ContactSupportForm />
      </Popover.Dropdown>
    </Popover>
  );
}
