import { useContext } from "react";
import CrudScreen from "../common/CrudScreen";
import AppContext from "../../context/AppContext";
import { verticalsSchema } from "../../context/dataSchema";
import {
  createVertical,
  updateVertical,
  deleteVertical,
} from "../../graphql/mutations";

export default function Verticals() {
  const { dataObject } = useContext(AppContext);
  const { verticals = [] } = dataObject;

  const screenProps = {
    data: verticals,
    schema: verticalsSchema,
    title: "Verticals",
    quickStats: null,
    tableColumns: ["name"],
    filterFields: [],
    recordName: "Vertical",
    pagePath: "verticals",
    dataObjectKey: "verticals",
    formProps: {
      createQuery: createVertical,
      updateQuery: updateVertical,
      deleteQuery: deleteVertical,
      dataObjectKey: "verticals",
    },
    modulesToLoad: ["Accounts", "Verticals"],
  };
  return <CrudScreen {...screenProps} />;
}
