import { useCallback, useContext, useMemo } from "react";
import CrudScreen from "../common/CrudScreen";
import AppContext from "../../context/AppContext";
import { integrationsSchema } from "../../context/dataSchema";
import {
  createIntegration,
  updateIntegration,
  deleteIntegration,
} from "../../graphql/mutations";
import IntegrationsForm from "./IntegrationsForm";
import IntegrationConfig from "./IntegrationConfig";
import DeleteIntegration from "./DeleteIntegration";
import { useNavigate } from "react-router-dom";
import { ActionIcon, FileButton, Group, useMantineTheme } from "@mantine/core";
import {
  IconCheck,
  IconRefresh,
  IconSettings,
  IconTrash,
  IconUpload,
  IconX,
} from "@tabler/icons-react";
import { updateIntegration as refreshIntegration } from "./systems/updateIntegration";
import { notifications } from "@mantine/notifications";
import { dataKeyToModule as moduleToModuleLookup } from "../../context/references";
//@ts-ignore
const Papa = window.Papa;

const modulesToDeleteLookup: any = {
  tasks: ["projects"],
  invoices: ["projects"],
  projectRoles: ["projects"],
  statements: ["projects"],
};

export default function Integrations() {
  const { dataObject, setDataObject, queuedModules } = useContext(AppContext);
  const { integrations = [], customDatasets = [] } = dataObject;

  const formatModule = useCallback(
    (module: string) => {
      if (moduleToModuleLookup[module]) {
        return moduleToModuleLookup[module];
      }
      return (
        customDatasets.find((customDataset: any) => customDataset.id === module)
          ?.name ?? ""
      );
    },
    [customDatasets]
  );

  const theme = useMantineTheme();

  const navigate = useNavigate();

  const refresh: any = useCallback(
    async (integrationObject: any, data: any = null) => {
      notifications.show({
        id: integrationObject.id,
        title: `Updating Integration`,
        message: `Updating ${integrationObject.name}`,
        loading: true,
        withBorder: true,
        autoClose: false,
      });

      try {
        const response = await refreshIntegration(
          integrationObject,
          {
            action: "update",
          },
          data
        );

        const attributes = JSON.parse(
          //@ts-ignore
          JSON.parse(response.data.updateIntegrationData).body
        ).Attributes;

        const dataLastUpdated = attributes.dataLastUpdated.S;
        const status = attributes.status.S;

        const dataObjectCopy = structuredClone(dataObject);

        dataObjectCopy.integrations = dataObjectCopy.integrations.map(
          (integration: any) => {
            if (integration.id === integrationObject.id) {
              integration.dataLastUpdated = dataLastUpdated;
              integration.status = status;
            }
            return integration;
          }
        );

        //new test

        delete dataObjectCopy[integrationObject.module];

        queuedModules.current = queuedModules.current.filter(
          (module: string) =>
            !module.includes(moduleToModuleLookup[integrationObject.module])
        );

        if (modulesToDeleteLookup[integrationObject.module]) {
          for (const mod of modulesToDeleteLookup[integrationObject.module]) {
            delete dataObjectCopy[mod];

            queuedModules.current = queuedModules.current.filter(
              (module: string) => !module.includes(moduleToModuleLookup[mod])
            );
          }
        }

        //end

        setDataObject(dataObjectCopy);

        // throw Error;
        notifications.update({
          id: integrationObject.id,
          title: "Success",
          message: `${integrationObject.name} successfully updated`,
          icon: <IconCheck size="1rem" />,
          loading: false,
          withBorder: true,
        });
      } catch (e) {
        console.log({ e });
        notifications.update({
          id: integrationObject.id,
          title: "Error",
          message: `Error updating ${integrationObject.name}`,
          icon: <IconX size="1rem" />,
          loading: false,
          withBorder: true,
          color: "red",
          autoClose: false,
        });
      }
    },
    [dataObject, queuedModules, setDataObject]
  );

  const updatedSchema = useMemo(() => {
    integrationsSchema["module"].formatFunction = formatModule;
    return integrationsSchema;
  }, [formatModule]);

  const screenProps = useMemo(
    () => ({
      data: integrations,
      schema: updatedSchema,
      title: "Integrations",
      quickStats: null,
      tableColumns: ["name", "system", "module", "status", "dataLastUpdated"],
      filterFields: [],
      recordName: "Integration",
      pagePath: "integrations",
      dataObjectKey: "integrations",
      modalFullScreen: true,
      actionIconColumnWidth: 130,
      formProps: {
        createQuery: createIntegration,
        updateQuery: updateIntegration,
        deleteQuery: deleteIntegration,
      },
      recordScreenTabs: {
        edit: { tabName: "Form" },
        configure: {
          tabName: "Integration Configuration",
          component: (props: any) => (
            <IntegrationConfig {...props} formatModule={formatModule} />
          ),
          //   fullScreen: true,
          modalWidth: "70vw",
          extraComponents: { refresh },
        },
        delete: {
          tabName: "Delete Integration",
          component: DeleteIntegration,
        },
      },
      hideModalTabs: true,
      modulesToLoad: ["Verticals", "Accounts", "Programmes", "Integrations"],
      CustomForm: IntegrationsForm,
      createButtonFunction: () =>
        navigate(`/integrations/create/select-system`),
      rowActions: ({ row }: any) => {
        return (
          <Group /*noWrap */ spacing={"xs"}>
            <ActionIcon
              onClick={() => {
                navigate(`/integrations/${row.original.id}/configure`);
              }}
              color={theme.primaryColor}
            >
              <IconSettings />
            </ActionIcon>
            <ActionIcon
              onClick={() => {
                navigate(`/integrations/${row.original.id}/delete`);
              }}
            >
              <IconTrash />
            </ActionIcon>

            {row.original.system === "csv" && (
              <FileButton
                onChange={(file: any) => {
                  const reader = new FileReader();

                  if (file) {
                    reader.onload = function (event) {
                      if (event && event.target) {
                        const contents = event.target.result;
                        Papa.parse(contents, {
                          header: true,
                          delimiter: ",",
                          skipEmptyLines: true,
                          complete: async function (results: any) {
                            const { data } = results;
                            refresh(row.original, data);
                          },
                        });
                      }
                    };

                    reader.readAsText(file);
                  }
                }}
                accept="text/csv"
              >
                {(props) => (
                  <ActionIcon {...props}>
                    <IconUpload />
                  </ActionIcon>
                )}
              </FileButton>
            )}

            {row.original.system !== "csv" && (
              <ActionIcon onClick={() => refresh(row.original)}>
                <IconRefresh />
              </ActionIcon>
            )}
          </Group>
        );
      },
    }),
    [integrations]
  );
  return <CrudScreen {...screenProps} />;
}
