import React, { useCallback, useMemo } from "react";

import { Menu } from "@mantine/core";
import getPreBuiltWidget from "../functions/getPreBuiltWidget";
import {
  IconCalendar,
  IconCopy,
  IconDots,
  IconEdit,
  IconTrash,
} from "@tabler/icons-react";
import CustomWidget from "../../common/graphs/ChartBuilder/CustomWidget";
import WidgetFrame from "../../common/WidgetFrame";
import ChartErrorBoundary from "../../common/graphs/ChartBuilder/ChartErrorBoundary";
import getComparisonPeriodString from "../functions/getComparisonPeriodString";
import getFinalPeriods from "../functions/getFinalPeriods";

export function useGridItems({
  currentDashboard,
  dashboardFilterObject,
  globalDashboardFilteredDataObject,
  //   updateDashboardFilter,
  comparisonPeriodOverride,
  editMode,
  setCurrentWidget,
  addWidget,
  setCurrentWidgetIndex,
  open,
  deleteWidget,
  layout,
  setDashboardFilterObject,
  startDate,
  endDate,
}: any) {
  const updateDashboardFilter = useCallback(
    (field: string, value: string, dataSource: string) => {
      const copy = structuredClone(dashboardFilterObject);
      if (copy[field]) {
        delete copy[field];
      } else {
        copy[field] = {
          field,
          includeExclude: "Include",
          operand: "Includes",
          value: [value],
          dataSource,
        };
      }
      setDashboardFilterObject(copy);
    },
    [dashboardFilterObject, setDashboardFilterObject]
  );

  const children = useMemo(() => {
    if (!currentDashboard) return null;
    const parsedConfig = JSON.parse(currentDashboard.configuration);

    const dashboardFilterObjectList = Object.values(dashboardFilterObject);

    return parsedConfig.widgets.map((widgetObject: any, index: any) => {
      const { widget, configuration } = widgetObject;

      let widgetComponent;

      if (widget === "custom") {
        const { dataSourceKey, chartType, period, comparisonPeriod } =
          configuration ?? {};

        if (chartType === "Title") {
          widgetComponent = (
            <CustomWidget
              {...configuration}
              dashboardFilterObjectList={dashboardFilterObjectList}
              updateDashboardFilter={updateDashboardFilter}
              startDate={startDate}
              endDate={endDate}
            />
          );
        } else {
          // const { periods, comparisonPeriods } =
          //   dataSourceObject[dataSourceKey];

          const finalPeriods = getFinalPeriods(period);

          const finalComparisonPeriod =
            comparisonPeriod === "None"
              ? "None"
              : comparisonPeriodOverride === "Default"
              ? comparisonPeriod
              : comparisonPeriodOverride;

          const comparisonPeriodString = getComparisonPeriodString({
            comparisonPeriod: finalComparisonPeriod,
          });

          const finalComparisonPeriods = [
            comparisonPeriodString.date.toISOString().slice(0, 10),
          ];

          const globalFilteredData = finalPeriods.reduce(
            (data: any, period: any) => {
              data = data.concat(
                globalDashboardFilteredDataObject[dataSourceKey][period] ?? []
              );
              return data;
            },
            []
          );

          const comparisonGlobalFilteredData = finalComparisonPeriods.reduce(
            (data: any, period: any) => {
              data = data.concat(
                globalDashboardFilteredDataObject[dataSourceKey][period] ?? []
              );
              return data;
            },
            []
          );

          widgetComponent = (
            <WidgetFrame
              title={configuration.chartTitle}
              icon={configuration.dateField ? IconCalendar : undefined}
            >
              <ChartErrorBoundary>
                <CustomWidget
                  {...configuration}
                  dashboardFilterObjectList={dashboardFilterObjectList}
                  updateDashboardFilter={updateDashboardFilter}
                  globalFilteredData={globalFilteredData}
                  comparisonGlobalFilteredData={comparisonGlobalFilteredData}
                  comparisonFieldString={comparisonPeriodString.comparisonText}
                  startDate={startDate}
                  endDate={endDate}
                />
              </ChartErrorBoundary>
            </WidgetFrame>
          );
        }
      } else {
        const { dataSourceKey, period, comparisonPeriod } = configuration ?? {};

        // if (dataSourceKey && period && comparisonPeriod) {
        const finalPeriods = period ? getFinalPeriods(period) : null;

        const finalComparisonPeriod = comparisonPeriod
          ? comparisonPeriod === "None"
            ? "None"
            : comparisonPeriodOverride === "Default"
            ? comparisonPeriod
            : comparisonPeriodOverride
          : null;

        const comparisonPeriodString = finalComparisonPeriod
          ? getComparisonPeriodString({
              comparisonPeriod: finalComparisonPeriod,
            })
          : null;

        const finalComparisonPeriods = comparisonPeriodString
          ? [comparisonPeriodString.date.toISOString().slice(0, 10)]
          : null;

        const globalFilteredData = finalPeriods
          ? finalPeriods.reduce((data: any, period: any) => {
              data = data.concat(
                globalDashboardFilteredDataObject[dataSourceKey][period] ?? []
              );
              return data;
            }, [])
          : null;

        const comparisonGlobalFilteredData = finalComparisonPeriods
          ? finalComparisonPeriods.reduce((data: any, period: any) => {
              data = data.concat(
                globalDashboardFilteredDataObject[dataSourceKey][period] ?? []
              );
              return data;
            }, [])
          : null;
        // }

        widgetComponent = getPreBuiltWidget({
          widgetName: widget,
          dashboardFilterObjectList,
          updateDashboardFilter,
          globalFilteredData,
          comparisonGlobalFilteredData,
          comparisonFieldString: comparisonPeriodString
            ? comparisonPeriodString.comparisonText
            : null,
          startDate,
          endDate,
        });
      }

      return (
        <div key={index + 1} style={{ cursor: editMode ? "grab" : "default" }}>
          <div
            style={{
              display: editMode ? undefined : "none",
              position: "absolute",
              right: "1em",
              top: "0.5em",
              cursor: "pointer",
              zIndex: 400,
            }}
          >
            <Menu
              shadow="md"
              width={200}
              closeOnClickOutside={false}
              position="left-start"
            >
              <Menu.Target>
                <IconDots />
              </Menu.Target>

              <Menu.Dropdown sx={{ right: "20px" }}>
                {/* <Menu.Item icon={<Filter />}>Apply Filter</Menu.Item>
                    <Menu.Divider /> */}
                {widget === "custom" && (
                  <Menu.Item
                    icon={<IconEdit />}
                    onClick={() => {
                      setCurrentWidget(widgetObject);
                      setCurrentWidgetIndex(index);
                      open();
                    }}
                  >
                    Edit
                  </Menu.Item>
                )}
                <Menu.Item
                  icon={<IconCopy />}
                  onClick={() => {
                    addWidget(widgetObject);
                  }}
                >
                  Duplicate
                </Menu.Item>
                <Menu.Divider />
                <Menu.Item
                  color="red"
                  onClick={() => deleteWidget(index)}
                  icon={<IconTrash />}
                >
                  Delete
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </div>
          {widgetComponent}
        </div>
      );
    });
  }, [
    currentDashboard,
    layout,
    dashboardFilterObject,
    comparisonPeriodOverride,
    globalDashboardFilteredDataObject,
    startDate,
    endDate,
    // editMode,

    // addWidget,
    // deleteWidget,
    // editMode,
    // open,
    // setCurrentWidget,
    // setCurrentWidgetIndex,
    // updateDashboardFilter,
  ]);

  return children;
}
