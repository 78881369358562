import { useState, useEffect, useContext, useCallback, useMemo } from "react";
import {
  Group,
  Button,
  Text,
  LoadingOverlay,
  Divider,
  ScrollArea,
  Modal,
} from "@mantine/core";
import { Trash } from "tabler-icons-react";
import { useForm } from "@mantine/form";
import { API } from "aws-amplify";
import { IFormProps } from "./FormTypes";
import { IFieldObject } from "../../context/SchemaTypes";
import FormQuestion from "./FormQuestion";
import AppContext from "../../context/AppContext";
import { useCreateAccessString } from "../../hooks/useCreateAccessString";
// import { useWhatChanged } from "../../hooks/useWhatChanged";

export default function Form(props: IFormProps) {
  const {
    fields,
    createQuery,
    updateQuery,
    deleteQuery,
    customUpdateFunction,
    customDeleteFunction,
    customCreateFunction,
    onSubmissionFunction,
    selectedRecord,
    sortFields,
    recordTableName,
    windowHeight,
    dataObjectKey,
  } = props;

  const [loading, setLoading] = useState(false);
  const [deleteConfirmShow, setDeleteConfirmShow] = useState(false);
  const { groups, setDataObject, dataObject, currentTenant } =
    useContext(AppContext);

  const mode: "create" | "edit" =
    selectedRecord === "create" || !selectedRecord || !selectedRecord.id
      ? "create"
      : "edit";

  useEffect(() => {
    if (
      mode === "edit" ||
      (selectedRecord !== "create" && selectedRecord && !selectedRecord.id)
    ) {
      const copyOfSelectedRecord =
        prepareSelectedRecordForUpdate(selectedRecord);
      form.setValues(copyOfSelectedRecord);
    }
  }, []);

  const prepareSelectedRecordForUpdate = useCallback(
    (record: any) => {
      const copyOfSelectedRecord = { ...record };
      fields.forEach((field: IFieldObject) => {
        const { type, field: fieldName } = field;
        switch (type) {
          case "date":
            copyOfSelectedRecord[fieldName] = copyOfSelectedRecord[fieldName]
              ? new Date(copyOfSelectedRecord[fieldName])
              : "";
            break;
          case "manyToMany":
            const {
              formOptions: { listKey },
            } = field;

            copyOfSelectedRecord[fieldName] =
              copyOfSelectedRecord[fieldName]?.items?.map((item: any) => {
                //@ts-ignore
                return item[listKey]?.id;
              }) ?? [];
            break;
          default:
            break;
        }
      });

      return copyOfSelectedRecord;
    },
    [fields]
  );

  const deleteRecord = useCallback(
    async (selectedRecord: any) => {
      setLoading(true);
      if (customDeleteFunction) {
        customDeleteFunction({
          recordObject: selectedRecord,
          dataObject,
          setDataObject,
          currentTenant,
          onSubmissionFunction,
          setLoading,
        });
        return;
      }
      try {
        const { id } = selectedRecord;

        const manyToManyFields = fields.filter((field) => {
          return field.type === "manyToMany";
        });

        for (let field of manyToManyFields) {
          const items = selectedRecord[field.field].items ?? [];
          for (let item of items) {
            if (field.formOptions.deleteQuery) {
              await API.graphql({
                query: field.formOptions.deleteQuery,
                variables: { input: { id: item.id } },
                authMode: "AMAZON_COGNITO_USER_POOLS",
              });
            }
          }
        }

        const returnedRecordResponse: any = await API.graphql({
          query: deleteQuery,
          variables: { input: { id } },
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });

        const returnedRecord =
          returnedRecordResponse.data[
            Object.keys(returnedRecordResponse.data)[0]
          ];

        const copyOfDataObject = structuredClone(dataObject);
        copyOfDataObject[dataObjectKey] = copyOfDataObject[
          dataObjectKey
        ].filter((record: any) => {
          return record.id !== returnedRecord.id;
        });
        setDataObject(copyOfDataObject);

        onSubmissionFunction("delete");
      } catch (err) {
        console.log("error creating record:", err);
        setLoading(false);
      }
    },
    [
      dataObject,
      dataObjectKey,
      deleteQuery,
      onSubmissionFunction,
      setDataObject,
      fields,
    ]
  );

  let filteredFields = useMemo(() => {
    const initialFields = fields.filter((field: IFieldObject) => {
      return (
        !field.excludeFromForm &&
        (!field.formOptions.visibleModes ||
          field.formOptions.visibleModes.includes(mode))
      );
    });

    // if (sortFields) {
    const filteredFields = sortFields
      ? initialFields.sort((a: any, b: any) => {
          const aOrder = a.formOptions.order ? a.formOptions.order : 100;
          const bOrder = b.formOptions.order ? b.formOptions.order : 100;
          return aOrder - bOrder;
        })
      : initialFields;
    // }

    return filteredFields;
  }, [fields, mode, sortFields]);

  const initialValues = useMemo(() => {
    return filteredFields.reduce(
      (object: any, field: any) => {
        if (!field.formOptions.viewOnly) {
          object[field.field] =
            field.formOptions.defaultValue ||
            field.formOptions.defaultValue === 0 ||
            field.formOptions.defaultValue === false
              ? field.formOptions.defaultValue
              : //
              !field.type
              ? ""
              : ["date"].includes(field.type)
              ? undefined
              : null; //"";
        }
        return object;
      },
      { audit: "[]", period: "Live" }
    );
  }, [filteredFields]);

  const validationObject = useMemo(() => {
    return filteredFields.reduce((object: any, field: any) => {
      if (
        field.formOptions.required &&
        (!field.formOptions.groupsThatCanEdit ||
          groups.some((group: any) =>
            field.formOptions.groupsThatCanEdit.includes(group)
          ))
      ) {
        switch (field.type) {
          case "number":
          case "currency":
            object[field.field] = (value: any) =>
              value !== 0 && !value ? `Please enter a value` : null;
            break;
          default:
            object[field.field] = (value: any) =>
              !value ? `Please enter a value` : null;
            break;
        }
      }
      return object;
    }, {});
  }, [filteredFields, groups]);

  const form = useForm({
    initialValues,
    validate: validationObject,
  });

  const permissionsInfo = useCreateAccessString({
    dataKey: dataObjectKey,
    formValues: form.values,
    mode,
  });

  // useWhatChanged({
  //   dataKey: dataObjectKey,
  //   formValues: form.values,
  //   mode,
  // });

  const prepareRecordForSubmission = useCallback(
    (record: any) => {
      let newManyToManyObject: any[] = [];
      const { updatedAt, createdAt, ...remainder } = record;
      const dateFormatter = Intl.DateTimeFormat("sv-SE");

      fields.forEach((field: IFieldObject) => {
        const {
          type,
          field: fieldName,
          formOptions: { groupsThatCanEdit, viewOnly, required },
          excludeFromForm,
        } = field;

        switch (type) {
          case "manyToMany":
            const {
              formOptions: {
                createQuery,
                deleteQuery,
                listKey,
                childIdName,
                parentIdName,
              },
            } = field;

            if (Array.isArray(remainder[fieldName])) {
              if (selectedRecord === "create") {
                newManyToManyObject.push({
                  method: "create",
                  field: fieldName,
                  query: createQuery,
                  values: remainder[fieldName],
                  listKey,
                  childIdName,
                  parentIdName,
                });
              } else {
                const currentValuesObject =
                  selectedRecord[fieldName].items?.reduce(
                    (object: any, item: any) => {
                      //@ts-ignore
                      object[item[listKey].id] = item.id;
                      return object;
                    },
                    {}
                  ) ?? {};
                const currentIds = Object.keys(currentValuesObject);
                const submittedRecordValues = remainder[fieldName];

                const idsToAdd = submittedRecordValues.filter(
                  (id: string) => !currentIds.includes(id)
                );

                const matchTableIdsToDelete = currentIds
                  .filter((id) => !submittedRecordValues.includes(id))
                  .map((id) => currentValuesObject[id]);

                if (idsToAdd.length > 0) {
                  newManyToManyObject.push({
                    method: "create",
                    field: fieldName,
                    query: createQuery,
                    values: idsToAdd,
                    listKey,
                    childIdName,
                    parentIdName,
                  });
                }
                if (matchTableIdsToDelete.length > 0) {
                  newManyToManyObject.push({
                    method: "delete",
                    listKey,
                    field: fieldName,
                    query: deleteQuery,
                    values: matchTableIdsToDelete,
                  });
                }
              }
            }
            delete remainder[fieldName];
            break;
          case "belongsTo":
          case "hasMany":
            delete remainder[fieldName];
            break;
          case "date":
            if (!["createdAt", "updatedAt"].includes(fieldName)) {
              remainder[fieldName] = remainder[fieldName]
                ? dateFormatter.format(new Date(remainder[fieldName]))
                : null;
            }
            break;
          default:
            break;
        }
        if (viewOnly && !required) delete remainder[fieldName];
        // if (excludeFromForm) delete remainder[fieldName];

        if (
          groupsThatCanEdit &&
          (!groups ||
            !groups.some((group: any) => groupsThatCanEdit.includes(group)))
        )
          delete remainder[fieldName];
      });

      const accessInfoAdded = { ...remainder, ...permissionsInfo };

      return {
        preparedCopy: accessInfoAdded,
        manyToManyObject: newManyToManyObject,
      };
    },
    [fields, groups, permissionsInfo]
  );

  const updateRecord = useCallback(
    async (recordObject: any, manyToManyObject: any) => {
      if (customUpdateFunction) {
        customUpdateFunction({
          recordObject,
          dataObject,
          setDataObject,
          currentTenant,
          onSubmissionFunction,
          setLoading,
        });
        return;
      }
      const copyOfSelectedRecord = { ...selectedRecord };

      const { preparedCopy } = prepareRecordForSubmission(copyOfSelectedRecord);

      const currentAudit = JSON.parse(preparedCopy.audit);

      const newAuditObject: any = {
        dateOfUpdate: new Date(),
        previousValues: {},
      };
      let addNewAuditObject = false;

      for (var key in preparedCopy) {
        if (
          !["updatedAt", "account"].includes(key) &&
          recordObject[key] &&
          preparedCopy[key] !== recordObject[key]
        ) {
          addNewAuditObject = true;
          newAuditObject.previousValues[key] = preparedCopy[key];
        }
      }

      if (addNewAuditObject) {
        currentAudit.push(newAuditObject);
        recordObject.audit = JSON.stringify(currentAudit);
      }

      delete recordObject.__typename;

      try {
        delete recordObject.owner;

        // START NEW

        // const newItemsObject = {};

        if (manyToManyObject.length > 0) {
          for (let object of manyToManyObject) {
            const {
              method,
              field,
              query,
              values,
              childIdName,
              parentIdName,
              listKey,
            } = object;

            if (method === "create") {
              for (let value of values) {
                await API.graphql({
                  query,
                  variables: {
                    input: {
                      [parentIdName]: recordObject.id,
                      [childIdName]: value,
                    },
                  },
                  authMode: "AMAZON_COGNITO_USER_POOLS",
                });

                /*
                //@ts-ignore
                if (!newItemsObject[field]) newItemsObject[field] = [];
                //@ts-ignore
                newItemsObject[field] = newItemsObject[field].concat(
                  //@ts-ignore
                  Object.values(returnedRecord.data)
                );
                */
              }
            } else {
              for (let value of values) {
                await API.graphql({
                  query,
                  variables: {
                    input: {
                      id: value,
                    },
                  },
                  authMode: "AMAZON_COGNITO_USER_POOLS",
                });
              }
            }
          }
        }

        // END NEW

        const returnedObject = await API.graphql({
          query: updateQuery,
          variables: { input: recordObject },
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });

        //@ts-ignore
        const actualReturnedObject = Object.values(returnedObject.data)[0];

        const copyOfDataObject = structuredClone(dataObject);

        copyOfDataObject[dataObjectKey] = copyOfDataObject[dataObjectKey].map(
          (record: any) => {
            //@ts-ignore
            if (record.id === actualReturnedObject.id) {
              //@ts-ignore
              record = { ...record, ...actualReturnedObject };
            }
            return record;
          }
        );
        setDataObject(copyOfDataObject);

        onSubmissionFunction("update");
      } catch (err) {
        console.log({ err, recordObject });
        setLoading(false);
      }
    },
    [
      dataObject,
      dataObjectKey,
      onSubmissionFunction,
      setDataObject,
      prepareRecordForSubmission,
      selectedRecord,
      updateQuery,
    ]
  );

  const addRecord = useCallback(
    async function () {
      const newRecord = { ...form.values };

      if (customCreateFunction) {
        const output = await customCreateFunction({
          recordObject: newRecord,
          dataObject,
          setDataObject,
          currentTenant,
          onSubmissionFunction,
          setLoading,
        });
        return;
      }

      const { preparedCopy: preparedRecord, manyToManyObject } =
        prepareRecordForSubmission(newRecord);

      try {
        // delete preparedRecord.id;
        delete preparedRecord.__typename;

        for (let key in preparedRecord) {
          if (preparedRecord[key] === null) {
            delete preparedRecord[key];
          }
        }

        const returnedRecord: any = await API.graphql({
          query: createQuery,
          variables: { input: preparedRecord },
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });

        let actualReturnedRecord =
          returnedRecord.data[Object.keys(returnedRecord.data)[0]];
        const newItemsObject = {};

        if (manyToManyObject.length > 0) {
          for (let object of manyToManyObject) {
            const {
              method,
              field,
              query,
              values,
              childIdName,
              parentIdName,
              listKey,
            } = object;

            for (let value of values) {
              const returnedRecord: any = await API.graphql({
                query,
                variables: {
                  input: {
                    [parentIdName]: actualReturnedRecord.id,
                    [childIdName]: value,
                  },
                },
                authMode: "AMAZON_COGNITO_USER_POOLS",
              });
              //@ts-ignore
              if (!newItemsObject[field]) newItemsObject[field] = [];
              //@ts-ignore
              newItemsObject[field] = newItemsObject[field].concat(
                //@ts-ignore
                Object.values(returnedRecord.data)
              );
            }
          }
        }

        for (let key in newItemsObject) {
          //@ts-ignore
          actualReturnedRecord[key].items = newItemsObject[key];
        }

        if (preparedRecord.integrationSystemId) {
          const copyOfDataObject = structuredClone(dataObject);
          copyOfDataObject[dataObjectKey] = copyOfDataObject[dataObjectKey].map(
            (record: any) => {
              if (
                record.integrationSystemId ===
                actualReturnedRecord.integrationSystemId
              ) {
                record = { ...record, ...actualReturnedRecord };
              }
              return record;
            }
          );
          setDataObject(copyOfDataObject);
        } else {
          const copyOfDataObject = structuredClone(dataObject);
          copyOfDataObject[dataObjectKey].push(actualReturnedRecord);
          setDataObject(copyOfDataObject);
        }

        onSubmissionFunction("create");
      } catch (err) {
        console.log({ err });
        setLoading(false);
      }
    },
    [
      createQuery,
      dataObject,
      dataObjectKey,
      form.values,
      onSubmissionFunction,
      prepareRecordForSubmission,
      setDataObject,
    ]
  );

  return (
    <>
      <LoadingOverlay visible={loading} />
      {/* <Divider /> */}
      <ScrollArea
        sx={windowHeight ? { height: windowHeight } : undefined}
        offsetScrollbars={true}
      >
        <form
          id={"current-form"}
          style={{ paddingTop: 16 }}
          noValidate={true}
          onSubmit={form.onSubmit((values) => {
            setLoading(true);
            if (form.validate().hasErrors) {
              setLoading(false);
              return;
            }
            if (mode === "edit") {
              const recordUpdates: any = { ...form.values };
              recordUpdates.id = selectedRecord.id;
              const { preparedCopy, manyToManyObject } =
                prepareRecordForSubmission(recordUpdates);
              updateRecord(preparedCopy, manyToManyObject);
            } else {
              addRecord();
            }
          })}
        >
          {filteredFields.map((field: IFieldObject) => {
            return (
              <FormQuestion
                key={field.field}
                {...field}
                disabled={loading}
                formProps={form.getInputProps(field.field)}
                form={form}
                mode={mode}
              />
            );
          })}
        </form>
      </ScrollArea>
      <Divider />
      <Group mt="md">
        <Button
          type="submit"
          form={"current-form"}
          style={{ width: "100%" }}
          disabled={loading}
        >
          Submit
        </Button>
      </Group>
      <Modal
        opened={deleteConfirmShow}
        centered
        size="sm"
        onClose={() => setDeleteConfirmShow(false)}
        title={`Are you sure you want to delete this ${recordTableName.toLowerCase()}?`}
        closeOnClickOutside={false}
        withCloseButton={false}
        zIndex={300}
      >
        <Group position="center">
          <Button
            color="red"
            onClick={() => {
              setDeleteConfirmShow(false);
              deleteRecord(selectedRecord);
            }}
          >
            Delete
          </Button>
          <Button onClick={() => setDeleteConfirmShow(false)}>Cancel</Button>
        </Group>
      </Modal>
      <Group position="right" mt="md" sx={{ color: "red" }}>
        {mode === "edit" ? (
          <>
            <Text sx={{ cursor: "pointer" }}>Delete {recordTableName}</Text>
            <Trash
              style={{ cursor: "pointer" }}
              onClick={() => setDeleteConfirmShow(true)}
            />
          </>
        ) : null}
      </Group>
    </>
  );
}
