import { NativeSelect, createStyles } from "@mantine/core";
import AppContext from "../../context/AppContext";
import { useContext, useEffect, useMemo } from "react";

const useStyles = createStyles((theme) => ({
  textColor: {
    color:
      theme.colorScheme === "dark"
        ? theme.colors[theme.primaryColor][5]
        : theme.colors[theme.primaryColor][9],
  },
}));

function addAllOption(options: any) {
  return options.length > 1
    ? [{ value: "All", label: "All" }].concat(options)
    : options;
}

function produceOptionsLists({
  structureObject,
  currentTenant,
  currentVertical,
  currentAccount,
}: any) {
  let verticalOptions: any = [];
  let programmeOptions: any = [];

  for (let tenant in structureObject) {
    if (tenant === currentTenant) {
      verticalOptions = Object.values(structureObject[tenant].children);
      for (let vertical in structureObject[tenant].children) {
        if (currentVertical === "All" || vertical === currentVertical) {
          programmeOptions = programmeOptions.concat(
            Object.values(
              structureObject[tenant].children[vertical].children
            ).filter((programme: any) => {
              return (
                currentAccount === "All" ||
                programme.accountId === currentAccount
              );
            })
          );
        }
      }
    }
  }

  verticalOptions = verticalOptions.sort((a: any, b: any) => {
    return a.label === b.label ? 0 : a.label > b.label ? 1 : -1;
  });

  programmeOptions = programmeOptions.sort((a: any, b: any) => {
    return a.label === b.label ? 0 : a.label > b.label ? 1 : -1;
  });

  return { verticalOptions, programmeOptions };
}

// const liveFilterFunction = (object: any) => object.period === "Live";

export default function GlobalFilters({ setFiltersOpen }: any) {
  const {
    dataObject,
    currentAccount,
    setCurrentAccount,
    currentTenant,
    setCurrentTenant,
    currentVertical,
    setCurrentVertical,
    currentProgramme,
    setCurrentProgramme,
    // groups = [],
    // currentProgrammes,
    setCurrentProgrammes,
    // account = "",
  } = useContext(AppContext);

  const {
    tenants = [],
    verticals = [],
    accounts = [],
    programmes = [],
  } = dataObject;

  const { classes, theme } = useStyles();

  // const verticals = useMemo(
  //   () => unfilteredVerticals.filter(liveFilterFunction),
  //   [unfilteredVerticals]
  // );
  // const accounts = useMemo(
  //   () => unfilteredAccounts.filter(liveFilterFunction),
  //   [unfilteredAccounts]
  // );
  // const programmes = useMemo(
  //   () => unfilteredProgrammes.filter(liveFilterFunction),
  //   [unfilteredProgrammes]
  // );

  const structureObject = useMemo(
    () =>
      tenants.reduce((object: any, tenant: any) => {
        object[tenant.id] = {
          value: tenant.id,
          label: tenant.name,
          children: verticals
            .filter((record: any) => record.tenantVerticalsId === tenant.id)
            .reduce((object: any, vertical: any) => {
              object[vertical.id] = {
                value: vertical.id,
                label: vertical.name,
                children: programmes
                  .filter(
                    (record: any) => record.verticalProgrammesId === vertical.id
                  )
                  .reduce((object: any, programme: any) => {
                    object[programme.id] = {
                      value: programme.id,
                      label: programme.name,
                      accountId: programme.accountProgrammesId,
                    };
                    return object;
                  }, {}),
              };
              return object;
            }, {}),
        };
        return object;
      }, {}),
    [programmes, tenants, verticals]
  );

  const { verticalOptions, programmeOptions } = useMemo(
    () =>
      produceOptionsLists({
        structureObject,
        currentTenant,
        currentVertical,
        currentAccount,
      }),
    [structureObject, currentTenant, currentVertical, currentAccount]
  );

  useEffect(() => {
    const verticalValues = verticalOptions.map((value: any) => value.value);
    // const accountValues = accountOptions.map((value: any) => value.value);
    const programmeValues = programmeOptions.map((value: any) => value.value);

    if (!verticalValues.includes(currentVertical)) {
      setCurrentVertical(
        verticalValues.length > 1 ? "All" : verticalValues[0] ?? null
      );
    }
    // if (!accountValues.includes(currentAccount)) {
    //   setCurrentAccount(
    //     accountValues.length > 1 ? "All" : accountValues[0] ?? null
    //   );
    // }
    if (!programmeValues.includes(currentProgramme)) {
      setCurrentProgramme(
        programmeValues.length > 1 ? "All" : programmeValues[0] ?? null
      );
    }
  }, [verticalOptions, programmeOptions]);

  useEffect(() => {
    setCurrentProgrammes(
      currentProgramme === "All"
        ? programmeOptions.map((value: any) => value.value)
        : [currentProgramme]
    );
  }, [currentProgramme, setCurrentProgrammes, programmeOptions]);

  return (
    <>
      {tenants.length > 1 ? (
        <NativeSelect
          data={Object.values(structureObject)}
          label="Select Tenant"
          mb="md"
          value={currentTenant}
          onChange={(e) => {
            setCurrentTenant(e.target.value);
            setFiltersOpen(false);
          }}
          labelProps={{ className: classes.textColor }}
          styles={{
            input: {
              flexGrow: 1,
              backgroundColor:
                theme.colorScheme === "dark" ? theme.colors.dark[4] : undefined,
            },
          }}
        />
      ) : null}
      <NativeSelect
        data={addAllOption(
          accounts
            .map((account: any) => ({
              value: account.id,
              label: account.name,
            }))
            .sort((a: any, b: any) => {
              return a.label === b.label ? 0 : a.label > b.label ? 1 : -1;
            })
        )}
        label="Select Account"
        mb="md"
        value={currentAccount}
        onChange={(e) => {
          setCurrentAccount(e.target.value);
        }}
        labelProps={{ className: classes.textColor }}
        styles={{
          input: {
            flexGrow: 1,
            backgroundColor:
              theme.colorScheme === "dark" ? theme.colors.dark[4] : undefined,
          },
        }}
      />
      <NativeSelect
        data={addAllOption(verticalOptions)}
        label="Select Vertical"
        mb="md"
        value={currentVertical}
        onChange={(e) => setCurrentVertical(e.target.value)}
        labelProps={{ className: classes.textColor }}
        styles={{
          input: {
            flexGrow: 1,
            backgroundColor:
              theme.colorScheme === "dark" ? theme.colors.dark[4] : undefined,
          },
        }}
      />

      <NativeSelect
        data={addAllOption(programmeOptions)}
        // data={finalProgrammeOptions}
        label="Select Programme"
        mb="md"
        onChange={(e) => setCurrentProgramme(e.target.value)}
        value={currentProgramme}
        labelProps={{ className: classes.textColor }}
        styles={{
          input: {
            flexGrow: 1,
            backgroundColor:
              theme.colorScheme === "dark" ? theme.colors.dark[4] : undefined,
          },
        }}
      />
    </>
  );
}
