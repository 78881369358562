import dayjs from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
dayjs.extend(quarterOfYear);

export default function getComparisonPeriodString({
  period = "Live",
  comparisonPeriod,
}: {
  period?: string;
  comparisonPeriod: string;
}): {
  date: Date;
  comparisonText: string;
} {
  // const startIndex = periods.indexOf(period);
  switch (comparisonPeriod) {
    case "Twelve Months Ago":
      return {
        date: dayjs().subtract(12, "month").endOf("month").toDate(),
        comparisonText: "Compared to twelve months ago",
      };
    case "Six Months Ago":
      return {
        date: dayjs().subtract(6, "month").endOf("month").toDate(),
        comparisonText: "Compared to six months ago",
      };
    case "Last Quarter":
      return {
        date: dayjs().subtract(1, "quarter").endOf("quarter").toDate(),
        comparisonText: "Compared to last quarter",
      };
    case "Last Month":
      return {
        date: dayjs().subtract(1, "month").endOf("month").toDate(),
        comparisonText: "Compared to last month",
      };
    case "Last Week":
      return {
        date: dayjs().subtract(1, "week").endOf("week").toDate(),
        comparisonText: "Compared to last week",
      };
    case "Yesterday":
      return {
        date: dayjs().subtract(1, "day").endOf("day").toDate(),
        comparisonText: "Compared to yesterday",
      };
    default:
      return {
        date: dayjs().toDate(),
        comparisonText: "None",
      };
  }
}
