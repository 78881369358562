import { useContext, useMemo } from "react";
import CrudScreen from "../common/CrudScreen";
import AppContext from "../../context/AppContext";
import { peopleSchema } from "../../context/dataSchema";
import PersonQuickStats from "./PersonQuickStats";
import { createPerson, deletePerson } from "../../graphql/mutations";
import { updatePerson } from "../../graphql-custom/updatedMutations";

export default function People(props: any) {
  const { dataObject } = useContext(AppContext);
  const { people: records = [] } = dataObject;

  const filteredRecords = useMemo(
    () => records.filter((record: any) => record.period === "Live"),
    [records]
  );

  const screenProps = useMemo(
    () => ({
      data: filteredRecords,
      schema: peopleSchema,
      title: "People",
      quickStats: <PersonQuickStats people={records} />,
      tableColumns: ["firstName", "lastName", "position", "currentlyActive"],
      filterFields: ["currentlyActive"],
      recordName: "Person",
      pagePath: "people",
      dataObjectKey: "people",
      formProps: {
        createQuery: createPerson,
        updateQuery: updatePerson,
        deleteQuery: deletePerson,
      },
      modulesToLoad: [
        "Verticals",
        "Accounts",
        "Programmes",
        "Projects",
        "Tasks",
        "Risks",
        "Role Types",
        "People",
      ],
    }),
    [filteredRecords]
  );
  return <CrudScreen {...screenProps} />;
}
