import { useContext } from "react";
import CrudScreen from "../common/CrudScreen";
import AppContext from "../../context/AppContext";
import { projectAllocationSchema as schema } from "../../context/dataSchema";
import {
  createProjectAllocation as createQuery,
  updateProjectAllocation as updateQuery,
  deleteProjectAllocation as deleteQuery,
} from "../../graphql/mutations";

export default function ProjectAllocations() {
  const { dataObject } = useContext(AppContext);
  const { projectAllocations: data = [] } = dataObject;

  const screenProps = {
    data,
    schema,
    title: "Project Allocations",
    quickStats: null,
    tableColumns: ["projectRole", "organisationRole", "startDate", "endDate"],
    filterFields: [],
    recordName: "Project Allocation",
    pagePath: "project-allocations",
    dataObjectKey: "projectAllocations",
    formProps: {
      createQuery,
      updateQuery,
      deleteQuery,
      dataObjectKey: "projectAllocations",
    },
    modulesToLoad: [
      "Verticals",
      "Project Roles",
      "People",
      "Project Allocations",
    ],
  };
  return <CrudScreen {...screenProps} />;
}
