import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
  createDashboard,
  deleteDashboard,
  updateDashboard,
} from "../../../graphql/mutations";
import { API } from "aws-amplify";
import { useDisclosure } from "@mantine/hooks";
import { useNavigate, useParams } from "react-router-dom";
import { IChartProps } from "../../common/graphs/Types/ChartPropsTypes";
import { defaultChartProps } from "../../common/graphs/ChartUtilities";
import { Layout } from "../WidgetGrid/ResponsiveGrid";
import AppContext from "../../../context/AppContext";
import { Dayjs } from "dayjs";

export function useDashboard() {
  const navigate = useNavigate();
  const { tabValue } = useParams();
  const [currentDashboard, setCurrentDashboard] = useState<any>(null);
  const [opened, { open, close }] = useDisclosure(false);
  const [comparisonPeriodOverride, setComparisonPeriodOverride] =
    useState<string>("Default");
  const [startDate, setStartDate] = useState<Dayjs | undefined>(undefined);
  const [endDate, setEndDate] = useState<Dayjs | undefined>(undefined);
  const [layout, setLayout] = useState<Layout | undefined>(undefined);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [
    addDashboardOpened,
    { open: openAddDashboard, close: closeAddDashboard },
  ] = useDisclosure(false);
  const [
    sharingModalOpened,
    { open: openSharingModal, close: closeSharingModal },
  ] = useDisclosure(false);
  const [
    deleteModalOpened,
    { open: openDeleteModal, close: closeDeleteModal },
  ] = useDisclosure(false);

  const [currentWidget, setCurrentWidget] = useState<any>(null);
  const [currentWidgetIndex, setCurrentWidgetIndex] = useState<any>(null);
  const [dashboardFormData, setDashboardFormData] = useState<any>({
    id: null,
    name: "",
  });
  const [dashboardFilterObject, setDashboardFilterObject] = useState<{
    [field: string]: {
      field: string;
      includeExclude: "Include";
      operand: "Includes";
      value: string[];
      dataSource: string;
    };
  }>({});
  const [chartProps, setChartProps] = useState<IChartProps>(defaultChartProps);

  const {
    dashboards,
    setDashboards,
    username,
    groups,
    os,
    currentTenant,
    loading,
    currentProgramme,
    currentProgrammes,
    // currentAccount,
    // currentVertical,
    dataObject,
  } = useContext(AppContext);

  useEffect(() => {
    if (dashboards && dashboards[0] && tabValue) {
      if (
        !dashboards.map((dashboard: any) => dashboard.id).includes(tabValue)
      ) {
        // setCurrentDashboard(dashboards[0]);
        navigate(`/dashboard/${dashboards[0].id}`);
      }
    }
  }, [dashboards, tabValue]);

  const tabData = useMemo(
    () =>
      dashboards
        .sort((a: any, b: any) => {
          return a.order - b.order;
        })
        .map((dashboard: any) => {
          return { value: dashboard.id, title: dashboard.name, dashboard };
        }),
    [dashboards]
  );

  const isSystemAdmin = useMemo(
    () => groups.includes(`System_Admin`),
    [groups]
  );

  const addWidget = useCallback(
    (widgetObject: any) => {
      const newDashboardObject = structuredClone(currentDashboard);
      newDashboardObject.configuration = JSON.parse(
        newDashboardObject.configuration
      );
      newDashboardObject.configuration.widgets.push(widgetObject);
      newDashboardObject.configuration = JSON.stringify(
        newDashboardObject.configuration
      );
      setCurrentDashboard(newDashboardObject);
    },
    [currentDashboard]
  );

  const updateDashboardFunction = useCallback(
    async (dashboard: any) => {
      try {
        const owner = dashboard.owner;
        delete dashboard.updatedAt;
        delete dashboard.createdAt;
        delete dashboard.owner;
        delete dashboard.__typename;

        await API.graphql({
          query: updateDashboard,
          variables: { input: dashboard },
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });

        const newDashboards = structuredClone(dashboards).map((dash: any) => {
          if (dash.id === dashboard.id) {
            dashboard.owner = owner;
            return dashboard;
          } else {
            return dash;
          }
        });

        setDashboards(newDashboards);
      } catch (e) {
        console.log(`Error updating Dashboard`, e);
      }
    },
    [dashboards, setDashboards]
  );

  const updateWidget = useCallback(
    (widgetObject: any, index: number) => {
      const newDashboardObject = structuredClone(currentDashboard);
      newDashboardObject.configuration = JSON.parse(
        newDashboardObject.configuration
      );
      newDashboardObject.configuration.widgets.splice(index, 1, widgetObject);
      newDashboardObject.configuration = JSON.stringify(
        newDashboardObject.configuration
      );
      setCurrentDashboard(newDashboardObject);
      updateDashboardFunction(newDashboardObject);
    },
    [currentDashboard, updateDashboardFunction]
  );

  const deleteWidget = useCallback(
    (index: number) => {
      const newDashboardObject = structuredClone(currentDashboard);
      newDashboardObject.configuration = JSON.parse(
        newDashboardObject.configuration
      );
      newDashboardObject.configuration.widgets.splice(index, 1);
      newDashboardObject.configuration = JSON.stringify(
        newDashboardObject.configuration
      );
      setCurrentDashboard(newDashboardObject);
      updateDashboardFunction(newDashboardObject);
    },
    [currentDashboard, updateDashboardFunction]
  );

  const addDashboard = useCallback(
    async (dashboardName: string, order: number) => {
      try {
        const newDashboard = {
          name: dashboardName,
          order,
          configuration: JSON.stringify({
            widgets: [],
          }),
          tenantAccessString: currentTenant,
          userTypeAccessStrings: [],
        };
        const returnedDashboard: any = await API.graphql({
          query: createDashboard,
          variables: { input: newDashboard },
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });

        const newDashboards = structuredClone(dashboards);
        newDashboards.push(returnedDashboard.data.createDashboard);
        setDashboards(newDashboards);
      } catch (e) {
        console.log(`Error creating Dashboard`, e);
      }
    },
    [dashboards, setDashboards, currentTenant]
  );

  const deleteDashboardFunction = useCallback(
    async (id: string) => {
      try {
        await API.graphql({
          query: deleteDashboard,
          variables: { input: { id } },
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });
        const newDashboards = structuredClone(dashboards).filter(
          (dashboard: any) => dashboard.id !== id
        );
        setDashboards(newDashboards);
      } catch (e) {
        console.log(`Error deleting Dashboard`, e);
      }
    },
    [dashboards, setDashboards]
  );

  return {
    isSystemAdmin,
    addDashboard,
    deleteDashboardFunction,
    addWidget,
    updateDashboardFunction,
    deleteWidget,
    updateWidget,
    currentDashboard,
    setCurrentDashboard,
    tabData,
    opened,
    open,
    close,
    chartProps,
    addDashboardOpened,
    openAddDashboard,
    closeAddDashboard,
    sharingModalOpened,
    openSharingModal,
    currentWidget,
    setCurrentWidget,
    currentWidgetIndex,
    setCurrentWidgetIndex,
    closeSharingModal,
    dashboardFormData,
    setDashboardFormData,
    dashboardFilterObject,
    setDashboardFilterObject,
    setChartProps,
    tabValue,
    navigate,
    comparisonPeriodOverride,
    setComparisonPeriodOverride,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    layout,
    setLayout,
    editMode,
    setEditMode,
    currentProgrammes,
    currentProgramme,
    dataObject,
    os,
    loading,
    username,
    dashboards,
    deleteModalOpened,
    openDeleteModal,
    closeDeleteModal,
  };
}
