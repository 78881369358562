import {
  createStyles,
  UnstyledButton,
  Center,
  useMantineColorScheme,
  Group,
} from "@mantine/core";
import { Moon, Sun } from "tabler-icons-react";

const useStyles = createStyles((theme) => ({
  control: {
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[8]
        : theme.colors.gray[0],
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 1000,
    // paddingLeft: theme.spacing.sm,
    // paddingRight: 4,
    // width: 136,
    // height: 36,
    // [theme.fn.smallerThan("sm")]: {
    //   width: 50,
    // },
  },

  iconWrapper: {
    height: 28,
    width: 28,
    borderRadius: 28,
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.yellow[4]
        : theme.colors.dark[4],
    color: theme.colorScheme === "dark" ? theme.black : theme.colors.blue[2],
  },

  // value: {
  //   lineHeight: 1,
  //   [theme.fn.smallerThan("sm")]: {
  //     display: "none",
  //   },
  // },
}));

export function ColorSchemeToggle() {
  const { classes } = useStyles();
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const Icon = colorScheme === "dark" ? Sun : Moon;

  window.localStorage.setItem("vmware-cpo-color-scheme", colorScheme);

  return (
    <Group position="center">
      <UnstyledButton
        aria-label="Toggle theme"
        className={classes.control}
        onClick={() => toggleColorScheme()}
        title="Ctrl + J"
      >
        {/* <Text size="sm" className={classes.value}>
          {upperFirst(colorScheme === "light" ? "dark" : "light")} mode
        </Text> */}

        <Center className={classes.iconWrapper}>
          <Icon size={18} />
        </Center>
      </UnstyledButton>
    </Group>
  );
}
