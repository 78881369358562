import {
  Stack,
  TextInput,
  Title,
  FileButton,
  Group,
  ColorSwatch,
  CheckIcon,
  Text,
  Button,
  Image,
  Box,
  useMantineTheme,
} from "@mantine/core";
import { useContext, useEffect, useState } from "react";
import AppContext from "../../context/AppContext";
import { saveSystemPreferenceObject } from "../common/functions/savePreferenceObject";
import { Storage } from "aws-amplify";

export default function CustomerConfigRow(props: any) {
  const { id: accountId, name: accountName } = props.selectedRecord;

  const { systemPreferenceObject, setSystemPreferenceObject, currentTenant } =
    useContext(AppContext);

  const [selectedColor, setSelectedColor] = useState("blue");
  const [systemTitle, setSystemTitle] = useState("");
  const [logo, setLogo] = useState<string | null>(null);
  const [darkLogo, setDarkLogo] = useState<string | null>(null);
  const theme = useMantineTheme();

  useEffect(() => {
    if (
      systemPreferenceObject.customers &&
      systemPreferenceObject.customers[accountId]
    ) {
      setSelectedColor(
        systemPreferenceObject.customers[accountId].primaryColor
      );
      setSystemTitle(systemPreferenceObject.customers[accountId].systemTitle);
    }
  }, [systemPreferenceObject]);

  const updateColor = (color: string) => {
    setSelectedColor(color);
    const preferenceObjectCopy = JSON.parse(
      JSON.stringify(systemPreferenceObject)
    );
    if (!preferenceObjectCopy.customers[accountId])
      preferenceObjectCopy.customers[accountId] = {
        primaryColor: "",
        systemTitle: "",
      };
    preferenceObjectCopy.customers[accountId].primaryColor = color;
    setSystemPreferenceObject(preferenceObjectCopy);
    saveSystemPreferenceObject(preferenceObjectCopy, currentTenant);
  };

  const onSystemTitleChange = ({ newValue }: { newValue: string }) => {
    const preferenceObjectCopy = structuredClone(systemPreferenceObject);

    if (!preferenceObjectCopy.customers[accountId])
      preferenceObjectCopy.customers[accountId] = {
        primaryColor: "",
        systemTitle: "",
      };
    preferenceObjectCopy.customers[accountId].systemTitle = newValue;
    setSystemPreferenceObject(preferenceObjectCopy);
    saveSystemPreferenceObject(preferenceObjectCopy, currentTenant);
  };

  const updateLogo = async (organisation: string | null) => {
    if (organisation) {
      const newLogo = await Storage.get(organisation + "_light");
      const newDarkLogo = await Storage.get(organisation + "_dark");
      setLogo(newLogo);
      setDarkLogo(newDarkLogo);
    }
  };

  async function saveFile(file: File | null, mode: string) {
    try {
      await Storage.put(`${currentTenant}_${accountId}${mode}`, file);
      updateLogo(`${currentTenant}_${accountId}`);
    } catch (error) {
      console.log("Error uploading file: ", error);
    }
  }

  useEffect(() => {
    updateLogo(`${currentTenant}_${accountId}`);
  }, []);

  const swatches = Object.keys(theme.colors).map((color) => (
    <ColorSwatch
      key={color}
      color={theme.colors[color][6]}
      component="button"
      onClick={() => updateColor(color)}
      sx={{ cursor: "pointer" }}
    >
      {color === selectedColor && <CheckIcon width={10} color={"white"} />}
    </ColorSwatch>
  ));

  return (
    <Stack mb={"xl"} sx={{ marginLeft: "2em" }}>
      <Title order={3}>{accountName}</Title>
      <Box sx={{ marginLeft: "2em" }}>
        <Stack mb={"xl"}>
          <Title order={4}>System Title</Title>
          <TextInput
            onChange={(e) => onSystemTitleChange({ newValue: e.target.value })}
            value={systemTitle}
          />
          <Title order={4} mt={"md"}>
            Organisation Logos
          </Title>
          <Group>
            <Text>Light Mode Logo</Text>
            <FileButton onChange={(e) => saveFile(e, "_light")}>
              {(props) => <Button {...props}>Upload image</Button>}
            </FileButton>
            <Box
              sx={(theme) => ({
                maxHeight: "50px",
                height: "50px",
                overflow: "hidden",
                backgroundColor: "white",
                paddingLeft: "16px",
                paddingRight: "16px",
                display: "flex",
                alignItems: "center",
                borderRadius: "16px",
              })}
            >
              <Image src={logo} style={{ maxWidth: "200px" }} withPlaceholder />
            </Box>
          </Group>
          <Group>
            <Text>Dark Mode Logo</Text>
            <FileButton onChange={(e) => saveFile(e, "_dark")}>
              {(props) => <Button {...props}>Upload image</Button>}
            </FileButton>
            <Box
              sx={(theme) => ({
                maxHeight: "50px",
                height: "50px",
                overflow: "hidden",
                backgroundColor: theme.colors.dark[7],
                paddingLeft: "16px",
                paddingRight: "16px",
                display: "flex",
                alignItems: "center",
                borderRadius: "16px",
              })}
            >
              <Image
                src={darkLogo}
                style={{ maxWidth: "200px" }}
                withPlaceholder
              />
            </Box>
          </Group>
          <Title order={4} mt={"md"}>
            Primary Colour
          </Title>
          <Group position="center" spacing="xs">
            {swatches}
          </Group>
        </Stack>
      </Box>
    </Stack>
  );
}
