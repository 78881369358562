import { Table, createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  greenBorder: {
    borderBottom: `1px solid ${
      theme.colorScheme === "dark"
        ? theme.colors[theme.primaryColor][4]
        : theme.colors[theme.primaryColor][8]
    } !important`,
    color: `${
      theme.colorScheme === "dark"
        ? theme.colors[theme.primaryColor][4]
        : theme.colors[theme.primaryColor][8]
    } !important`,
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.fn.rgba(theme.colors[theme.primaryColor][2], 0.1)
        : theme.fn.rgba(theme.colors[theme.primaryColor][3], 0.1),
  },
  header: {
    color: `${
      theme.colorScheme === "dark"
        ? theme.colors[theme.primaryColor][4]
        : theme.colors[theme.primaryColor][8]
    } !important`,
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.fn.rgba(theme.colors[theme.primaryColor][2], 0.1)
        : theme.fn.rgba(theme.colors[theme.primaryColor][3], 0.1),
  },

  tableBorder: {
    border: `1px solid ${
      theme.colorScheme === "dark"
        ? theme.colors[theme.primaryColor][4]
        : theme.colors[theme.primaryColor][8]
    } !important`,
    borderRadius: "1em",
    borderCollapse: "collapse",
    overflow: "hidden",
    boxShadow: `0 0 0 1px ${
      theme.colorScheme === "dark"
        ? theme.colors[theme.primaryColor][4]
        : theme.colors[theme.primaryColor][8]
    }`,
  },

  totals: {
    color: `${
      theme.colorScheme === "dark"
        ? theme.colors[theme.primaryColor][4]
        : theme.colors[theme.primaryColor][8]
    } !important`,
    borderTop: `1px solid ${
      theme.colorScheme === "dark"
        ? theme.colors[theme.primaryColor][4]
        : theme.colors[theme.primaryColor][8]
    } !important`,
  },
}));

// helper function for setting row/col-span in pivotTableRenderer
const spanSize = function (arr, i, j) {
  let x;
  if (i !== 0) {
    let asc, end;
    let noDraw = true;
    for (
      x = 0, end = j, asc = end >= 0;
      asc ? x <= end : x >= end;
      asc ? x++ : x--
    ) {
      if (arr[i - 1][x] !== arr[i][x]) {
        noDraw = false;
      }
    }
    if (noDraw) {
      return -1;
    }
  }
  let len = 0;
  while (i + len < arr.length) {
    let asc1, end1;
    let stop = false;
    for (
      x = 0, end1 = j, asc1 = end1 >= 0;
      asc1 ? x <= end1 : x >= end1;
      asc1 ? x++ : x--
    ) {
      if (arr[i][x] !== arr[i + len][x]) {
        stop = true;
      }
    }
    if (stop) {
      break;
    }
    len++;
  }
  return len;
};

const colorScaleGenerator = (values, color) => {
  const min = Math.min(...values);
  const max = Math.max(...values);
  return (x) => {
    const opacity = Math.round((100 * (x - min)) / (max - min)) / 100;
    return { backgroundColor: `rgb(${color},${opacity})` };
  };
};

function makeRenderer(opts = {}) {
  const TableRenderer = (props) => {
    // const pivotData = new PivotData(props);
    const { classes } = useStyles();
    const { pivotData, formatFunction = (value) => value } = props;
    const colAttrs = pivotData.props.cols;
    const rowAttrs = pivotData.props.rows;
    const rowKeys = pivotData.getRowKeys();

    const colKeys = pivotData.getColKeys();
    const grandTotalAggregator = pivotData.getAggregator([], []);

    let valueCellColors = () => {};
    let rowTotalColors = () => {};
    let colTotalColors = () => {};
    if (opts.heatmapMode) {
      const rowTotalValues = colKeys.map((x) =>
        pivotData.getAggregator([], x).value()
      );
      rowTotalColors = colorScaleGenerator(rowTotalValues, props.color);
      const colTotalValues = rowKeys.map((x) =>
        pivotData.getAggregator(x, []).value()
      );
      colTotalColors = colorScaleGenerator(colTotalValues, props.color);

      if (opts.heatmapMode === "full") {
        const allValues = [];
        rowKeys.forEach((r) =>
          colKeys.forEach((c) =>
            allValues.push(pivotData.getAggregator(r, c).value())
          )
        );
        const colorScale = colorScaleGenerator(allValues, props.color);
        valueCellColors = (r, c, v) => colorScale(v);
      } else if (opts.heatmapMode === "row") {
        const rowColorScales = {};
        rowKeys.forEach((r) => {
          const rowValues = colKeys.map((x) =>
            pivotData.getAggregator(r, x).value()
          );
          rowColorScales[r] = colorScaleGenerator(rowValues, props.color);
        });
        valueCellColors = (r, c, v) => rowColorScales[r](v);
      } else if (opts.heatmapMode === "col") {
        const colColorScales = {};
        colKeys.forEach((c) => {
          const colValues = rowKeys.map((x) =>
            pivotData.getAggregator(x, c).value()
          );
          colColorScales[c] = colorScaleGenerator(colValues, props.color);
        });
        valueCellColors = (r, c, v) => colColorScales[c](v);
      }
    }

    const getClickHandler =
      props.tableOptions && props.tableOptions.clickCallback
        ? (value, rowValues, colValues) => {
            const filters = {};
            for (const i of Object.keys(colAttrs || {})) {
              const attr = colAttrs[i];
              if (colValues[i] !== null) {
                filters[attr] = colValues[i];
              }
            }
            for (const i of Object.keys(rowAttrs || {})) {
              const attr = rowAttrs[i];
              if (rowValues[i] !== null) {
                filters[attr] = rowValues[i];
              }
            }
            return (e) =>
              props.tableOptions.clickCallback(e, value, filters, pivotData);
          }
        : null;

    return (
      <Table
        withColumnBorders
        highlightOnHover
        withBorder
        className={classes.tableBorder}
      >
        <thead>
          {colAttrs.map(function (c, j) {
            return (
              <tr key={`colAttr${j}`}>
                {j === 0 && rowAttrs.length !== 0 && (
                  <th
                    colSpan={rowAttrs.length}
                    rowSpan={colAttrs.length}
                    className={classes.header}
                  />
                )}
                <th
                  className={
                    rowAttrs.length === 0 ? classes.greenBorder : classes.header
                  }
                >
                  {c}
                </th>
                {colKeys.map(function (colKey, i) {
                  const x = spanSize(colKeys, i, j);
                  if (x === -1) {
                    return null;
                  }
                  return (
                    <th
                      className={
                        rowAttrs.length === 0
                          ? classes.greenBorder
                          : j === colAttrs.length - 1 && rowAttrs.length !== 0
                          ? classes.greenBorder
                          : classes.header
                      }
                      key={`colKey${i}`}
                      colSpan={x}
                      rowSpan={
                        j === colAttrs.length - 1 && rowAttrs.length !== 0
                          ? 2
                          : 1
                      }
                    >
                      {colKey[j]}
                    </th>
                  );
                })}

                {j === 0 && (
                  <th
                    className={classes.greenBorder}
                    rowSpan={colAttrs.length + (rowAttrs.length === 0 ? 0 : 1)}
                  >
                    Totals
                  </th>
                )}
              </tr>
            );
          })}
          {rowAttrs.length !== 0 && (
            <tr>
              {rowAttrs.map(function (r, i) {
                return (
                  <th className={classes.greenBorder} key={`rowAttr${i}`}>
                    {r}
                  </th>
                );
              })}
              <th className={classes.greenBorder}>
                {colAttrs.length === 0 ? "Totals" : null}
              </th>
            </tr>
          )}
        </thead>

        <tbody>
          {rowKeys.map(function (rowKey, i) {
            const totalAggregator = pivotData.getAggregator(rowKey, []);
            return (
              <tr key={`rowKeyRow${i}`}>
                {rowKey.map(function (txt, j) {
                  const x = spanSize(rowKeys, i, j);
                  if (x === -1) {
                    return null;
                  }
                  return (
                    <td
                      key={`rowKeyLabel${i}-${j}`}
                      className="pvtRowLabel"
                      rowSpan={x}
                      colSpan={
                        j === rowAttrs.length - 1 && colAttrs.length !== 0
                          ? 2
                          : 1
                      }
                    >
                      {txt}
                    </td>
                  );
                })}
                {colKeys.map(function (colKey, j) {
                  const aggregator = pivotData.getAggregator(rowKey, colKey);

                  return (
                    <td
                      className="pvtVal"
                      key={`pvtVal${i}-${j}`}
                      onClick={
                        getClickHandler &&
                        getClickHandler(aggregator.value(), rowKey, colKey)
                      }
                      style={valueCellColors(
                        rowKey,
                        colKey,
                        aggregator.value()
                      )}
                    >
                      {/* {formatFunction(aggregator.format(aggregator.value()))} */}
                      {formatFunction(Number(aggregator.value()))}
                    </td>
                  );
                })}
                <td
                  className="pvtTotal"
                  onClick={
                    getClickHandler &&
                    getClickHandler(totalAggregator.value(), rowKey, [null])
                  }
                  style={colTotalColors(totalAggregator.value())}
                >
                  <b>
                    {/* {formatFunction(
                      totalAggregator.format(totalAggregator.value())
                    )} */}
                    {formatFunction(totalAggregator.value())}
                  </b>
                </td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <th
              className={classes.totals}
              colSpan={rowAttrs.length + (colAttrs.length === 0 ? 0 : 1)}
            >
              Totals
            </th>
            {colKeys.map(function (colKey, i) {
              const totalAggregator = pivotData.getAggregator([], colKey);
              return (
                <th
                  className={classes.totals}
                  key={`total${i}`}
                  onClick={
                    getClickHandler &&
                    getClickHandler(totalAggregator.value(), [null], colKey)
                  }
                  style={rowTotalColors(totalAggregator.value())}
                >
                  {/* {formatFunction(
                    totalAggregator.format(totalAggregator.value())
                  )} */}
                  {formatFunction(totalAggregator.value())}
                </th>
              );
            })}

            <th
              onClick={
                getClickHandler &&
                getClickHandler(grandTotalAggregator.value(), [null], [null])
              }
              className={classes.totals}
            >
              {/* {formatFunction(
                grandTotalAggregator.format(grandTotalAggregator.value())
              )} */}
              {formatFunction(grandTotalAggregator.value())}
            </th>
          </tr>
        </tfoot>
      </Table>
    );
  };

  return TableRenderer;
}

const renderers = {
  Table: makeRenderer(),
  "Table Heatmap": makeRenderer({ heatmapMode: "full" }),
  "Table Col Heatmap": makeRenderer({ heatmapMode: "col" }),
  "Table Row Heatmap": makeRenderer({ heatmapMode: "row" }),
};

export default renderers;
