import { useContext } from "react";
import CrudScreen from "../common/CrudScreen";
import AppContext from "../../context/AppContext";
import { statementsSchema as schema } from "../../context/dataSchema";
import {
  createStatement as createQuery,
  updateStatement as updateQuery,
  deleteStatement as deleteQuery,
} from "../../graphql/mutations";

export default function StatementsOfWork() {
  const { dataObject } = useContext(AppContext);
  const { statements: data = [] } = dataObject;

  const screenProps = {
    data,
    schema,
    title: "Statements of Work",
    quickStats: null,
    tableColumns: ["project", "pipeline", "agreement"],
    filterFields: [],
    recordName: "Statement of Work",
    pagePath: "statements-of-work",
    dataObjectKey: "statements",
    formProps: {
      createQuery,
      updateQuery,
      deleteQuery,
      dataObjectKey: "statements",
    },
    modulesToLoad: ["Accounts", "Pipelines", "Projects", "Statements of Work"],
  };
  return <CrudScreen {...screenProps} />;
}
