import { API } from "aws-amplify";

export default async function fetchModuleRecords({
  listQuery,
  currentTenant,
  period = "Live",
  dataKey,
}: any) {
  // console.log({
  //   listQuery,
  //   currentTenant,
  //   period,
  //   dataKey,
  // });

  const queryKey = Object.keys(listQuery)[0];

  const variables =
    queryKey === "fetchCustomData"
      ? {
          input: JSON.stringify({
            tenantAccessString: currentTenant,
            customDatasetId: dataKey,
          }),
        }
      : ["listRegions"].includes(queryKey)
      ? {}
      : currentTenant
      ? [
          "listDashboards",
          "listIntegrations",
          "listCustomDatasets",
          "listPeriods",
          "listUserTypes",
          "listAnnualLeaves",
        ].includes(queryKey)
        ? { filter: { tenantAccessString: { eq: currentTenant } } }
        : {
            filter: {
              and: [
                { tenantAccessString: { eq: currentTenant } },
                { period: { eq: period } },
              ],
            },
          }
      : {};

  const queryObject: any = {
    query: listQuery[queryKey],
    authMode: "AMAZON_COGNITO_USER_POOLS",
    variables,
  };
  let response: any;

  try {
    response = await API.graphql(queryObject);
  } catch (err) {
    console.log(err);
    response = err;
  }

  // fetchCustomData

  let data: any =
    queryKey === "fetchCustomData"
      ? JSON.parse(response.data[queryKey]).items
      : response.data[queryKey].items;

  while (response.data[queryKey].nextToken) {
    queryObject.variables.nextToken = response.data[queryKey].nextToken;

    try {
      response = await API.graphql(queryObject);
    } catch (err) {
      console.log(err);
      response = err;
    }
    data = data.concat(response.data[queryKey].items);
  }

  return data;
}
