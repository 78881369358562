import { Progress, useMantineTheme } from "@mantine/core";
import { ProjectRole } from "../../API";
import { calculatePercentageOfDurationPassed } from "../../components/common/functions/dateFunctions";
import {
  createRoleTypeProjectRoles,
  deleteRoleTypeProjectRoles,
  updateRoleTypeProjectRoles,
} from "../../graphql/mutations";
import { listRoleTypeProjectRoles } from "../../graphql/queries";
import { ISchema } from "../SchemaTypes";
import common from "./commonSchema";
import { formatCurrency } from "../../components/common/functions/formatFunctions";

function DurationLine({ width, children, duration }: any) {
  const theme = useMantineTheme();
  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          position: "absolute",
          left: width * duration,
          width: 1,
          height: "100%",
          backgroundColor:
            theme.colorScheme === "dark"
              ? theme.colors.gray[2]
              : theme.colors.gray[9],
          zIndex: 1,
        }}
      ></div>
      {children}
    </div>
  );
}

const calculateActualRevenue = (row: ProjectRole) => {
  if (row?.timesheets?.items?.length) {
    const totalUnits = row.timesheets.items.reduce(
      (total: number, timesheet: any) => {
        const invoices = timesheet?.invoices?.items;
        if (invoices) {
          for (const invoice of invoices) {
            if (invoice.paid) {
              total += invoice.amount;
            }
          }
        }
        return total;
      },
      0
    );
    return totalUnits;
  }
  return 0;
};

const calculateFinanceObject = (value: any) =>
  value?.timesheets?.items?.reduce(
    (object: any, timesheet: any) => {
      timesheet?.invoices?.items.forEach((invoice: any) => {
        object.paid += invoice.paid ? invoice.amount : 0;
        object.invoiced += invoice.amount;
      });
      return object;
    },
    { paid: 0, invoiced: 0, value: value.plannedUnits * value.unitRate }
  );

const projectRoleSchema: ISchema = {
  roleTypes: {
    title: "Role Types",
    field: "roleTypes",
    formOptions: {
      required: false,
      childIdName: "roleTypeID",
      parentIdName: "projectRoleID",
      listKey: "roleType",
      listQuery: listRoleTypeProjectRoles,
      updateQuery: updateRoleTypeProjectRoles,
      createQuery: createRoleTypeProjectRoles,
      deleteQuery: deleteRoleTypeProjectRoles,
      options: {
        dataSource: "roleTypes",
        addBlank: true,
        accessorFunction: (dataSource: any) =>
          (dataSource ?? []).map((row: any) => {
            return { value: row.id, label: row.name };
          }),
      },
    },
    type: "manyToMany",
    accessorFunction: (row: any) => "",
  },

  financeSummary: {
    title: "Revenue Summary",
    field: "financeSummary",
    type: "number",
    formatFunction: (value: any) => {
      return (
        <DurationLine width={300} duration={value.duration}>
          <>
            {value.paid + value.invoiced === 0 ? (
              <Progress
                radius="xl"
                size={"1.25em"}
                miw={300}
                maw={300}
                value={0}
              />
            ) : (
              <Progress
                radius="xl"
                size={"1.25em"}
                miw={300}
                maw={300}
                sections={[
                  {
                    value: (value.paid * 100) / value.value,
                    color: "green",
                    label: formatCurrency(value.paid, "GBP", 0),
                    tooltip: "Revenue Claimed",
                  },
                  {
                    value: ((value.invoiced - value.paid) * 100) / value.value,
                    color: "orange",
                    label: formatCurrency(
                      value.invoiced - value.paid,
                      "GBP",
                      0
                    ),
                    tooltip: "Revenue Recognised",
                  },
                  {
                    value: ((value.value - value.invoiced) * 100) / value.value,
                    color: "red",
                    label: formatCurrency(
                      value.value - value.invoiced,
                      "GBP",
                      0
                    ),
                    tooltip: "Revenue Forecast",
                  },
                ]}
              />
            )}
          </>
        </DurationLine>
      );
    },
    accessorFunction: (value: any) => ({
      ...calculateFinanceObject(value),
      duration: calculatePercentageOfDurationPassed({
        startDate: value.startDate,
        endDate: value.endDate,
      }),
    }),
    excludeFromForm: true,
    formOptions: {
      required: false,
    },
  },

  percentagePlannedUnitsUsed: {
    title: "Percentage of Planned Hours Used",
    field: "percentagePlannedUnitsUsed",
    type: "number",
    accessorFunction: (value: any) => {
      const totalsObject = value?.timesheets?.items.reduce(
        (object: any, timesheet: any) => {
          object.actual +=
            (timesheet.unitAmount ?? 0) * (timesheet.unit === "Days" ? 8 : 1);
          return object;
        },
        {
          planned: value.plannedUnits * (value.unit === "Days" ? 8 : 1),
          actual: 0,
        }
      );
      const { planned, actual } = totalsObject;
      return {
        value: planned === 0 ? 0 : actual / planned,
        duration: calculatePercentageOfDurationPassed({
          startDate: value.startDate,
          endDate: value.endDate,
        }),
      };
    },
    formatFunction: (value: any) => {
      const rounded = Math.round(value.value * 100);
      return (
        <DurationLine width={200} duration={value.duration}>
          <Progress
            value={rounded}
            label={`${rounded}%`}
            color={rounded > 100 ? "red" : undefined}
            // size="xl"
            radius="xl"
            size={"1.25em"}
            miw={200}
            maw={200}
          />
        </DurationLine>
      );
    },
    excludeFromForm: true,
    formOptions: {
      required: false,
    },
  },

  integrationSystemId: {
    title: "System Unique ID",
    field: "integrationSystemId",
    formOptions: { required: false },
    excludeFromForm: true,
  },
  project: {
    title: "Project",
    field: "project",
    accessorFunction: (value: any) => value.project?.name,
    formOptions: { required: true },
    excludeFromForm: true,
    type: "belongsTo",
    equivalentFields: { projects: "name" },
  },
  projectName: {
    title: "Project Name",
    field: "projectName",
    type: "belongsTo",
    accessorFunction: (row: any) => {
      return row?.project?.name;
    },
    excludeFromForm: true,
    formOptions: { required: false },
    equivalentFields: { projects: "name" },
  },

  projectProjectRolesId: {
    title: "Project",
    field: "projectProjectRolesId",
    isKey: true,
    formOptions: {
      required: true,
      options: {
        dataSource: "projects",
        addBlank: true,
        accessorFunction: (dataSource: any) =>
          (dataSource ?? []).map((row: any) => {
            return { value: row.id, label: row.name };
          }),
      },
      order: 1,
    },
    type: "select",
  },

  pipeline: {
    title: "Pipeline",
    field: "pipeline",
    accessorFunction: (value: any) => value.pipeline?.dealName,
    formOptions: { required: true },
    excludeFromForm: true,
    type: "belongsTo",
  },

  pipelineProjectRolesId: {
    title: "Pipeline",
    field: "pipelineProjectRolesId",
    isKey: true,
    formOptions: {
      required: true,
      options: {
        dataSource: "pipelines",
        addBlank: true,
        accessorFunction: (dataSource: any) => {
          return (dataSource ?? []).map((row: any) => {
            return { value: row.id, label: row.dealName };
          });
        },
      },
      order: 1,
    },
    type: "select",
  },

  description: {
    title: "Description",
    field: "description",
    formOptions: { required: false },
  },

  status: {
    title: "Status",
    field: "status",
    type: "select",
    formOptions: {
      required: false,
      defaultValue: "Billable",
      options: { list: ["Billable", "Non Billable"], addBlank: true },
    },
  },

  unit: {
    title: "Unit",
    field: "unit",
    type: "select",
    formOptions: {
      required: false,
      options: { list: ["Hours", "Days", "Project Outcome"], addBlank: true },
    },
  },

  plannedUnits: {
    title: "Planned Units",
    field: "plannedUnits",
    type: "number",
    formOptions: { required: false },
  },

  actualValue: {
    title: "Actual Value",
    field: "actualValue",
    type: "currency",
    formatFunction: (value: any) => formatCurrency(value, "GBP", 2),
    accessorFunction: calculateActualRevenue,
    formOptions: { required: false },
    excludeFromChartBuilder: true,
  },

  actualUnits: {
    title: "Actual Units",
    field: "actualUnits",
    type: "number",
    accessorFunction: (row: ProjectRole) => {
      if (row.actualUnits) return row.actualUnits;
      if (row?.timesheets?.items?.length) {
        const totalUnits = row.timesheets.items.reduce(
          (total: number, timesheet: any) => {
            if (row.unit === "Days" && timesheet.unit === "Hours") {
              total += timesheet.unitAmount / 8;
            } else if (row.unit === "Hours" && timesheet.unit === "Days") {
              total += timesheet.unitAmount * 8;
            } else {
              total += timesheet.unitAmount;
            }

            return total;
          },
          0
        );

        return totalUnits;
      }
      return 0;
    },
    formOptions: { required: false },
  },

  forecastValue: {
    title: "Forecast Value",
    field: "forecastValue",
    type: "currency",
    formatFunction: (value: any) => formatCurrency(value, "GBP", 2),
    accessorFunction: (row: any) => {
      return row.plannedUnits * row.unitRate;
    },
    formOptions: { required: false },
  },

  assigned: {
    title: "Assigned",
    field: "assigned",
    accessorFunction: (row: ProjectRole) => {
      return row.projectAllocations?.items
        ?.map((allocation) =>
          //@ts-ignore
          allocation?.person
            ? `${allocation?.person?.firstName} ${allocation?.person?.lastName}`
            : ""
        )
        .join(", ");
    },
    formOptions: { required: false },
  },

  unitRate: {
    title: "Unit Rate",
    field: "unitRate",
    type: "currency",
    formatFunction: (value: any) => formatCurrency(value, "GBP", 2),
    formOptions: { required: false },
  },

  unitCost: {
    title: "Unit Cost",
    field: "unitCost",
    type: "currency",
    formatFunction: (value: any) => formatCurrency(value, "GBP", 2),
    formOptions: { required: false },
  },

  fte: {
    title: "FTE",
    field: "fte",
    type: "number",
    formOptions: { required: false },
  },

  timesheets: {
    title: "Time Sheets",
    field: "timesheets",
    type: "hasMany",
    accessorFunction: (row: any) => row?.timesheets?.length,
    formOptions: { required: true },
    excludeFromForm: true,
  },

  projectAllocations: {
    title: "Project Allocations",
    field: "projectAllocations",
    type: "hasMany",
    accessorFunction: (row: any) => row?.projectAllocations?.length,
    formOptions: { required: true },
    excludeFromForm: true,
  },

  startDate: {
    title: "Start Date",
    field: "startDate",
    type: "date",
    formOptions: {
      required: false,
    },
  },
  endDate: {
    title: "End Date",
    field: "endDate",
    type: "date",
    formOptions: {
      required: false,
    },
  },

  billingFrequency: {
    title: "Billing Frequency",
    field: "billingFrequency",
    type: "select",
    formOptions: {
      required: false,
      defaultValue: "Weekly",
      options: { list: ["Weekly", "Monthly", "At End"] },
    },
    // filterOptions: { defaultValue: "Open" },
  },

  ...common,
};

export default projectRoleSchema;
