import { useMantineTheme } from "@mantine/core";
import { useCallback } from "react";
import { PieChart, Pie, Cell } from "recharts";

const data01 = [{ name: "Group A", value: 100 }];

const RADIAN = Math.PI / 180;
const RenderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
  value,
}: any) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx; //+ radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      //   fontSize={"2em"}
      fill="white"
      textAnchor={/*x > cx ? "start" : "end"*/ "middle"}
      dominantBaseline="central"
    >
      {`${value.toFixed(0)}%`}
    </text>
  );
};

export default function PercentStat({ value }: { value: number }) {
  const theme = useMantineTheme();
  const primaryColor = theme.primaryColor;

  const data02 = [
    { name: "A1", value: 100 - value },
    { name: "A2", value: value },
  ];

  const renderCustomizedLabelOuter = useCallback(
    (props: any) => {
      return <RenderCustomizedLabel {...props} value={value} />;
    },
    [value]
  );

  return (
    <PieChart width={60} height={60}>
      <Pie
        data={data01}
        dataKey="value"
        cx={25}
        cy={25}
        outerRadius={20}
        labelLine={false}
        label={renderCustomizedLabelOuter}
        fill={theme.colors[primaryColor][9]}
        stroke={theme.colors[primaryColor][9]}
      />
      <Pie
        data={data02}
        dataKey="value"
        cx={25}
        cy={25}
        innerRadius={21}
        outerRadius={30}
        fill={theme.colors[primaryColor][5]}
        stroke="transparent"
        // label
        startAngle={90}
        endAngle={-270}
      >
        {data02.map((row, i) => {
          return (
            <Cell
              key={row.name}
              fill={i === 0 ? "transparent" : theme.colors[primaryColor][5]}
            />
          );
        })}
      </Pie>
    </PieChart>
  );
}
