import React from "react";
import "./CircleGroup.css"; // We'll use this file for styles
import { Box, Stack, Text } from "@mantine/core";

export const CircleGroup = ({
  items,
  radius,
  outerRadius,
  setCurrentTemperament,
  theme,
  currentResult,
}: any) => {
  const numItems = items.length;
  const angleStep = (2 * Math.PI) / numItems;

  const smallCircleRadius = 2 * radius * Math.sin(angleStep / 2);

  return (
    <div className="circle-group">
      {currentResult && currentResult.temperament !== "Not Submitted" && (
        <Box style={{ position: "absolute", width: radius }}>
          <Text align="center" size={"xs"}>
            Click to view other temperaments
          </Text>
        </Box>
      )}
      <div style={{ position: "relative" }}>
        {items.map((item: any, index: any) => {
          const { title, color, Icon, outer } = item;
          const angle = index * angleStep - angleStep / 2; //0.524;
          const x = radius * Math.cos(angle) - radius / 2; // x-coordinate for circle
          const y = radius * Math.sin(angle) - radius / 2; // y-coordinate for circle

          //   const outerX = outerRadius * Math.cos(angle);
          //   const outerY = outerRadius * Math.sin(angle);

          const filter =
            currentResult?.temperament === title
              ? `drop-shadow(0px 0px 20px ${theme.colors[color][7]})`
              : undefined;

          return (
            <React.Fragment key={index}>
              <div
                className="circle small-circle"
                style={{
                  // transform: `translate(${x}px, ${y}px)`,
                  left: x,
                  top: y,
                  width: smallCircleRadius,
                  height: smallCircleRadius,
                  backgroundColor: theme.colors[color][7],
                  // transformOrigin: "bottom left",
                  //   boxShadow: "10px 10px 10px 10px rgba(0,255,255,0.7)",
                  filter,
                  border:
                    currentResult?.temperament === title
                      ? "solid 2px white"
                      : undefined,
                }}
                onClick={() => setCurrentTemperament(title)}
              >
                <Stack align="center" spacing={1}>
                  <Icon />
                  <Text size={"sm"} fw={700} tt="uppercase">
                    {title}
                  </Text>
                </Stack>
              </div>
              {/* <div
                className="outer"
                style={{
                  transform: `translate(${outerX}px, ${outerY}px)`,
                  transformOrigin: "bottom right",
                }}
              >
                {outer}
              </div> */}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};
