import { useContext } from "react";
import CrudScreen from "../common/CrudScreen";
import AppContext from "../../context/AppContext";
import { roleTypeSchema as schema } from "../../context/dataSchema";
import {
  createRoleType as createQuery,
  deleteRoleType as deleteQuery,
} from "../../graphql/mutations";
import { updateRoleType as updateQuery } from "../../graphql-custom/updatedMutations";

export default function RoleTypes() {
  const { dataObject } = useContext(AppContext);
  const { roleTypes: data = [] } = dataObject;

  const screenProps = {
    data,
    schema,
    title: "Role Types",
    quickStats: null,
    tableColumns: ["name"],
    filterFields: [],
    recordName: "Role Types",
    pagePath: "role-types",
    dataObjectKey: "roleTypes",
    formProps: {
      createQuery,
      updateQuery,
      deleteQuery,
      dataObjectKey: "roleTypes",
    },
    modulesToLoad: ["Verticals", "Project Roles", "People", "Role Types"],
  };
  return <CrudScreen {...screenProps} />;
}
