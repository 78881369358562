import { TenantQuestion } from "../../components/common/TenantQuestion";
import { SchemaQuestion } from "../../components/custom-datasets/SchemaQuestion";
import { PermissionQuestion } from "../../components/custom-datasets/PermissionsQuestion";
import { ISchema } from "../SchemaTypes";
import common from "./commonSchema";
import { Box, Title } from "@mantine/core";
import { useCallback } from "react";

const customDatasetSchema: ISchema = {
  tenant: {
    title: "Tenant",
    field: "tenant",
    accessorFunction: (value: any) => value?.tenant?.name,
    formatFunction: (value: any) => value,
    formOptions: { required: true },
    excludeFromForm: true,
    type: "belongsTo",
  },
  tenantCustomDatasetsId: {
    title: "Tenant",
    field: "tenantCustomDatasetsId",

    formOptions: {
      required: true,
      CustomQuestion: (props: any) => (
        <TenantQuestion {...props} fieldName="tenantCustomDatasetsId" />
      ),
      options: {
        dataSource: "tenants",
        addBlank: true,
        accessorFunction: (dataSource: any) =>
          dataSource.map((row: any) => {
            return { value: row.id, label: row.name };
          }),
      },
      order: 1,
    },
    type: "select",
  },
  name: {
    title: "Name",
    field: "name",
    formOptions: {
      required: true,
      defaultValue: "",
    },
  },
  schema: {
    title: "Schema",
    field: "schema",
    formOptions: {
      required: false,
      CustomQuestion: SchemaQuestion,
      defaultValue: JSON.stringify({
        tenantCustomRecordsId: {
          title: "Tenant",
          field: "tenantCustomRecordsId",
          formOptions: {
            required: true,
            options: {
              dataSource: "tenants",
              addBlank: true,
              accessorFunction: (dataSource: any) =>
                dataSource.map((row: any) => {
                  return { value: row.id, label: row.name };
                }),
            },
            order: 1,
          },
          type: "select",
        },
        ...common,
      }),
    },
  },
  permissionsConfig: {
    title: "Permissions Config",
    field: "permissionsConfig",
    formOptions: {
      required: false,
      CustomQuestion: ({ form }: any) => {
        const updateForm = useCallback(
          (updatedRules: any) => {
            const currentValue = JSON.parse(form.values.permissionsConfig);
            form.setValues({
              permissionsConfig: JSON.stringify({
                ...currentValue,
                datasetPermissions: { rules: updatedRules },
              }),
            });
          },
          [form]
        );
        return (
          <Box mt={"md"}>
            <Title order={3} mb={"md"}>
              Global Permissions
            </Title>
            <PermissionQuestion
              //@ts-ignore
              updateForm={updateForm}
              initialRules={
                JSON.parse(form.values.permissionsConfig).datasetPermissions
                  .rules
              }
            />
          </Box>
        );
      },
      defaultValue: JSON.stringify({
        datasetPermissions: {
          rules: [],
        },
        fieldPermissions: {},
      }),
    },
  },
  customRecords: {
    title: "Custom Records",
    field: "customRecords",
    type: "hasMany",
    accessorFunction: (row: any) => row.customRecords?.length,
    formOptions: { required: true },
    excludeFromForm: true,
  },
  ...common,
};

export default customDatasetSchema;
