import { API } from "aws-amplify";
import { integrations } from "../../../../graphql/queries";

export async function getBiginDeals({
  filterObject = {},
  infoOnly = false,
  currentTenant,
}: {
  filterObject?: any;
  infoOnly?: boolean;
  currentTenant: string;
}) {
  const response = await API.graphql({
    query: integrations,
    authMode: "AMAZON_COGNITO_USER_POOLS",
    variables: {
      input: JSON.stringify({
        system: "bigin",
        operation: "getDeals",
        informationObject: {
          filterObject,
          infoOnly,
          currentTenant,
          system: "bigin",
        },
      }),
    },
  });

  //@ts-ignore
  const parsedData = JSON.parse(response.data.integrations);
  const parsedBody = JSON.parse(parsedData.body);

  return parsedBody;
}

export async function authoriseBigin({
  code,
  currentTenant,
  system,
}: {
  code: string;
  currentTenant: string;
  system: string;
}) {
  const response = await API.graphql({
    query: integrations,
    authMode: "AMAZON_COGNITO_USER_POOLS",
    variables: {
      input: JSON.stringify({
        system: "bigin",
        operation: "authorise",
        informationObject: { code, currentTenant, system },
      }),
    },
  });

  //@ts-ignore
  const parsedResponse = JSON.parse(response.data.integrations);

  if (parsedResponse.body) {
    let parsedBody;
    parsedBody = JSON.parse(parsedResponse.body);

    const { status } = parsedBody;

    return { status };
  } else {
    return { status: "failed" };
  }
}
