import { useContext, useMemo } from "react";
import AppContext from "../../context/AppContext";
import {
  TextInput,
  Textarea,
  NumberInput,
  // NativeSelect,
  Switch,
  Text,
  // Group,
  Grid,
  MultiSelect,
  Select,
} from "@mantine/core";
//@ts-ignore
import { DatePickerInput } from "@mantine/dates";
import { IFieldObject } from "../../context/SchemaTypes";
import { Calendar, CurrencyPound } from "tabler-icons-react";
import { formatDateTimeString } from "./functions/formatFunctions";

export default function FormQuestion(props: IFieldObject) {
  const {
    title,
    type,
    formOptions,
    formProps,
    disabled,
    form,
    mode,
    field,
    formatFunction,
  } = props;

  const { [field]: error } = form.errors;

  const {
    required,
    min,
    max,
    precision,
    step,
    description,
    options = {},
    CustomQuestion,
    viewOnly,
    groupsThatCanEdit,
    editableModes,

    // listQuery,
    // createQuery,
    // deleteQuery,
    // updateQuery,
    // parentIdName,
    // childIdName,
  } = formOptions;
  const { dataSource, list, accessorFunction, addBlank } = options;

  const { dataObject, groups } = useContext(AppContext);
  const { [dataSource ? dataSource : ""]: dataSourceData } = dataObject;

  const editable = useMemo(
    () =>
      (!editableModes || editableModes.includes(mode)) &&
      !viewOnly &&
      (!groupsThatCanEdit ||
        groups?.some((group: any) => groupsThatCanEdit.includes(group))),
    [editableModes, groups, groupsThatCanEdit, mode, viewOnly]
  );

  const common = useMemo(() => {
    return {
      label: title,
      required,
      description,
      mb: "md",
      ...formProps,
      disabled,
      value: formProps.value === null ? "" : formProps.value,
    };
  }, [description, disabled, formProps, required, title]);

  if (CustomQuestion) return <CustomQuestion form={form} editable={editable} />;
  if (editable) {
    switch (type) {
      // case "manyToMany": {
      //   let optionsList;
      //   if (list) {
      //     optionsList = list;
      //   } else {
      //     optionsList = accessorFunction(dataSourceData);
      //   }
      //   return (
      //     <ManyToManyQuestion
      //       {...common}
      //       parentId={form.values.id}
      //       data={optionsList}
      //       error={error ? error : null}
      //       listQuery={listQuery}
      //       createQuery={createQuery}
      //       deleteQuery={deleteQuery}
      //       updateQuery={updateQuery}
      //       parentIdName={parentIdName}
      //       childIdName={childIdName}
      //     />
      //   );
      // }
      case "manyToMany":
      case "multiSelect": {
        let optionsList;
        if (list) {
          optionsList = list;
        } else {
          optionsList = accessorFunction(dataSourceData);
        }
        return (
          <MultiSelect
            {...common}
            data={optionsList}
            error={error ? error : null}
            searchable
          />
        );
      }
      case "select": {
        let optionsList;
        if (list) {
          optionsList = list;
        } else {
          // const initial = addBlank ? [{ value: "", label: "" }] : [];
          // optionsList = initial.concat(accessorFunction(dataSourceData));
          optionsList = accessorFunction(dataSourceData);
        }

        return (
          <Select
            {...common}
            data={optionsList}
            value={common.value ? common.value : null}
            onChange={(value) => {
              common.onChange(value);
            }}
            error={error ? error : null}
            searchable
            clearable
            maxSelectedValues={1}
          />
        );

        // return (
        //   <NativeSelect
        //     {...common}
        //     data={optionsList}
        //     error={error ? error : null}
        //   />
        // );
      }
      case "number":
        return (
          <NumberInput
            {...common}
            max={max}
            step={step}
            precision={precision}
            min={min}
            error={error ? error : null}
          />
        );
      case "currency":
        return (
          <NumberInput
            {...common}
            max={max}
            step={step}
            min={min}
            icon={<CurrencyPound size={18} />}
            precision={2}
            error={error ? error : null}
          />
        );
      case "date":
        return (
          <DatePickerInput
            {...common}
            value={common.value ? common.value : null}
            valueFormat="DD/MM/YYYY"
            icon={<Calendar size={16} />}
            min={min}
            max={max}
            dropdownType={"modal"}
            modalProps={{ zIndex: 1000 }}
            error={error ? error : null}
            clearable={true}
          />
        );
      case "textarea":
        return <Textarea {...common} error={error ? error : null} />;
      case "boolean":
        return (
          <Switch
            {...common}
            onChange={(e: any) => form.setValues({ [field]: e.target.checked })}
            checked={form.values[field] === true}
            error={error ? error : null}
          />
        );
      default:
        return <TextInput {...common} error={error ? error : null} />;
    }
  } else {
    switch (type) {
      case "date":
        return (
          <Grid mb={"md"}>
            <Grid.Col md={4}>
              <Text>{title}</Text>
            </Grid.Col>
            <Grid.Col md={8}>
              <Text weight={700}>{formatDateTimeString(formProps.value)}</Text>
            </Grid.Col>
          </Grid>
        );
      default:
        return (
          <Grid mb={"md"}>
            <Grid.Col md={4}>
              <Text>{title}</Text>
            </Grid.Col>
            <Grid.Col md={8}>
              <Text weight={700}>
                {formatFunction && formProps.value
                  ? formatFunction(formProps.value)
                  : formProps.value}
              </Text>
            </Grid.Col>
          </Grid>
        );
    }
  }
}
