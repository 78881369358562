import { useMantineTheme } from "@mantine/core";
import { Storage } from "aws-amplify";
import { useCallback, useContext, useEffect } from "react";
import { useDebouncedValue } from "@mantine/hooks";
import AppContext from "../../context/AppContext";
import { saveSystemPreferenceObject } from "../common/functions/savePreferenceObject";

Storage.configure({
  region: "eu-west-2",
});

export const useAppearanceSection = () => {
  const {
    systemPreferenceObject: preferenceObject,
    setSystemPreferenceObject: setPreferenceObject,
    setColor,
    logo,
    darkLogo,
    updateLogo,
    currentTenant,
  } = useContext(AppContext);
  const theme = useMantineTheme();

  const {
    appearance: { systemTitle, primaryColor },
  } = preferenceObject
    ? preferenceObject
    : { appearance: { systemTitle: "", primaryColor: "blue" } };

  const [debouncedTitle] = useDebouncedValue(systemTitle, 1000);

  useEffect(() => {
    if (preferenceObject) {
      saveSystemPreferenceObject(preferenceObject, currentTenant);
    }
  }, [debouncedTitle]);

  const onSystemTitleChange = useCallback(
    ({ newValue }: { newValue: string }) => {
      const preferenceObjectCopy = JSON.parse(JSON.stringify(preferenceObject));
      preferenceObjectCopy.appearance.systemTitle = newValue;
      setPreferenceObject(preferenceObjectCopy);
    },
    [preferenceObject, setPreferenceObject]
  );

  const saveFile = useCallback(
    async (file: File | null, mode: string) => {
      if (!file || !currentTenant) return;
      try {
        await Storage.put(currentTenant + mode, file);
        updateLogo(currentTenant);
      } catch (error) {
        console.log("Error uploading file: ", error);
      }
    },
    [currentTenant, updateLogo]
  );

  const updateColor = useCallback(
    (color: string) => {
      setColor(color);
      const preferenceObjectCopy = JSON.parse(JSON.stringify(preferenceObject));
      preferenceObjectCopy.appearance.primaryColor = color;
      setPreferenceObject(preferenceObjectCopy);
      saveSystemPreferenceObject(preferenceObjectCopy, currentTenant);
      window.localStorage.setItem("vmware-cpo-primary-color", color);
    },
    [currentTenant, preferenceObject, setColor, setPreferenceObject]
  );

  return {
    onSystemTitleChange,
    systemTitle,
    logo,
    darkLogo,
    saveFile,
    saveSystemPreferenceObject,
    theme,
    updateColor,
    primaryColor,
  };
};
