import common from "./commonSchema";
import { ISchema } from "../SchemaTypes";
import { formatDateString } from "../../components/common/functions/formatFunctions";

const costsSchema: ISchema = {
  programme: {
    title: "Programme",
    field: "programme",
    accessorFunction: (value: any) => value.programme?.name,
    formOptions: { required: true },
    excludeFromForm: true,
    type: "belongsTo",
  },
  programmeCostsId: {
    title: "Programme ID",
    field: "programmeCostsId",
    formOptions: {
      required: true,
      options: {
        dataSource: "programmes",
        addBlank: true,
        accessorFunction: (dataSource: any) =>
          dataSource?.map((row: any) => {
            return { value: row.id, label: row.name };
          }) ?? [],
      },
      order: 1,
    },
    type: "select",
  },
  id: {
    title: "ID",
    field: "id",
    formOptions: { required: false },
  },
  period: {
    title: "Period",
    field: "period",
    formOptions: { required: false },
    equivalentFields: { all: "period" },
  },
  geo: {
    title: "Geo",
    field: "geo",
    formOptions: { required: false },
  },
  region: {
    title: "Region",
    field: "region",

    formOptions: { required: false },
    equivalentFields: { pls: "region", mrrs: "region" },
  },
  subRegion: {
    title: "Sub Region",
    field: "subRegion",

    formOptions: { required: false },
    equivalentFields: { pls: "subRegion", mrrs: "subRegion" },
  },
  engagementId: {
    title: "Engagement Number",
    field: "engagementId",

    formOptions: { required: false },
  },
  engagementName: {
    title: "Engagement Name",
    field: "engagementName",

    formOptions: { required: false },
    equivalentFields: { pls: "engagementName", mrrs: "engagementName" },
  },
  revRecType: {
    title: "Rev Rec Type",
    field: "revRecType",

    formOptions: { required: false },
  },
  paymentType: {
    title: "Payment Type",
    field: "paymentType",

    formOptions: { required: false },
  },
  engagementStatus: {
    title: "Engagement Status",
    field: "engagementStatus",

    formOptions: { required: false },
  },
  engagementCreatedOn: {
    title: "Engagement Created On",
    field: "engagementCreatedOn",

    formOptions: { required: false },
  },
  engagementAmount: {
    title: "Engagement Amount",
    field: "engagementAmount",
    type: "number",
    formOptions: { required: false },
  },
  projectManager: {
    title: "Project Manager",
    field: "projectManager",
    formOptions: { required: false },
    equivalentFields: { pls: "projectManager", mrrs: "projectManager" },
  },
  engagementCurrency: {
    title: "Engagement Currency",
    field: "engagementCurrency",

    formOptions: { required: false },
  },
  roleName: {
    title: "Role Name",
    field: "roleName",

    formOptions: { required: false },
  },
  billingRateForRole: {
    title: "Billing Rate For Role",
    field: "billingRateForRole",
    type: "number",
    formOptions: { required: false },
  },
  budgetedHours: {
    title: "Budgeted Hours",
    field: "budgetedHours",
    type: "number",
    formOptions: { required: false },
  },
  costRateAverageForResources: {
    title: "Cost Rate Average For Resources",
    field: "costRateAverageForResources",
    type: "number",
    formOptions: { required: false },
  },
  budgetedCostForRole: {
    title: "Budgeted Cost For Role",
    field: "budgetedCostForRole",
    type: "number",
    formOptions: { required: false },
  },
  plannedHours: {
    title: "Planned Hours",
    field: "plannedHours",
    type: "number",
    formOptions: { required: false },
  },
  actualHours: {
    title: "Actual Hours",
    field: "actualHours",
    type: "number",
    formOptions: { required: false },
  },
  internalPlannedCost: {
    title: "Internal Planned Cost",
    field: "internalPlannedCost",
    type: "number",
    formOptions: { required: false },
  },
  internalActualCost: {
    title: "Internal Actual Cost",
    field: "internalActualCost",
    type: "number",
    formOptions: { required: false },
  },
  partnerPlannedCostForRole: {
    title: "Partner Planned Cost For Role",
    field: "partnerPlannedCostForRole",
    type: "number",
    formOptions: { required: false },
  },
  partnerActualCostForRole: {
    title: "Partner Actual Cost For Role",
    field: "partnerActualCostForRole",
    type: "number",
    formOptions: { required: false },
  },
  ...common,
  audit: {
    title: "Audit",
    field: "audit",
    type: "text",
    excludeFromForm: false,
    excludeFromDataView: true,
    excludeFromChartBuilder: true,
    formOptions: { required: false },
    enableGlobalFilter: false,
  },
  createdAt: {
    title: "Created At",
    field: "createdAt",
    type: "date",
    formatFunction: formatDateString,
    excludeFromForm: false,
    formOptions: { required: false },
  },
  updatedAt: {
    title: "Updated At",
    field: "updatedAt",
    type: "date",
    formatFunction: formatDateString,
    excludeFromForm: false,
    formOptions: { required: false },
  },
  pmAccess: {
    title: "PM Access",
    field: "pmAccess",
    formOptions: {
      required: false,
      options: {
        dataSource: "people",
        addBlank: true,
        accessorFunction: (dataSource: any) =>
          dataSource?.map((row: any) => {
            return { value: row.id, label: `${row.firstName} ${row.lastName}` };
          }) ?? [],
      },
    },
    type: "select",
  },
  dmAccess: {
    title: "DM Access",
    field: "dmAccess",
    formOptions: {
      required: false,
      options: {
        dataSource: "people",
        addBlank: true,
        accessorFunction: (dataSource: any) =>
          dataSource?.map((row: any) => {
            return { value: row.id, label: `${row.firstName} ${row.lastName}` };
          }) ?? [],
      },
    },
    type: "select",
  },
};

export default costsSchema;
