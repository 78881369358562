import { useCallback, useContext, useEffect, useRef, useState } from "react";
import {
  Stepper,
  Button,
  Group,
  TextInput,
  Stack,
  Alert,
  useMantineTheme,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import ModuleQuestion from "./ModuleQuestion";

import { useNavigate, useParams } from "react-router-dom";
import SystemQuestion from "./SystemQuestion";
import { authoriseBigin } from "./systems/bigin/bigin";
import { authoriseMicrosoft } from "./systems/microsoft/microsoft";
import { API } from "aws-amplify";
import { createIntegration } from "../../graphql/mutations";
import SystemSpecificData from "./SystemSpecificData";
import { IconSettings } from "@tabler/icons-react";
import AppContext from "../../context/AppContext";
import { authoriseMonday } from "./systems/monday/functions";
import { authoriseHubSpot } from "./systems/hubspot";
import { authoriseTimesheetPortal } from "./systems/timesheetPortal/functions";
import { authoriseExcel } from "./systems/excel/functions";

const getTokens = async (
  system: string,
  code: string,
  currentTenant: string
) => {
  switch (system) {
    case "bigin":
      if (code) {
        const { status } = await authoriseBigin({
          code,
          system,
          currentTenant,
        });
        if (status) {
          return { status };
        } else {
          return { status: "Error" };
        }
      } else {
        return { status: "Missing code" };
      }
    case "hubspot":
      if (code) {
        const { status } = await authoriseHubSpot({
          code,
          system,
          currentTenant,
        });

        if (status) {
          return { status };
        } else {
          return { status: "Error" };
        }
      } else {
        return { status: "Missing code" };
      }
    case "microsoft":
      if (code) {
        const { status } = await authoriseMicrosoft({
          code,
          system,
          currentTenant,
        });

        if (status) {
          return { status };
        } else {
          return { status: "Error" };
        }
      } else {
        return { status: "No Code" };
      }
    case "excel":
      if (code) {
        const { status } = await authoriseExcel({
          code,
          system,
          currentTenant,
        });

        if (status) {
          return { status };
        } else {
          return { status: "Error" };
        }
      } else {
        return { status: "No Code" };
      }
    case "monday":
      if (code) {
        const { status } = await authoriseMonday({
          code,
          system,
          currentTenant,
        });

        if (status) {
          return { status };
        } else {
          return { status: "Error" };
        }
      } else {
        return { status: "No Code" };
      }
    case "tsp":
      if (code) {
        const { status } = await authoriseTimesheetPortal({
          code,
          system,
          currentTenant,
        });

        if (status) {
          return { status };
        } else {
          return { status: "Error" };
        }
      } else {
        return { status: "No Code" };
      }

    default:
      return { status: "No System" };
  }
};

export default function IntegrationsForm() {
  const { dataObject, setDataObject, currentTenant } = useContext(AppContext);
  // const { integrations = [] } = dataObject;
  const [connectingSystem, setConnectingSystem] = useState(false);
  const [creatingIntegration, setCreatingIntegration] = useState(false);
  const [createdId, setCreatedId] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const initialLoad = useRef(true);
  const theme = useMantineTheme();

  const { tabPath: stage } = useParams();

  const navigate = useNavigate();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const code = urlParams.get("code");

  const form = useForm({
    initialValues: {
      system: "",
      name: "",
      module: "",
      dataObject: {
        systemSpecificData: {},
        operation: null,
        mappingObject: null,
        filterObject: [],
      },
      status: "Awaiting Configuration",
      tenantAccessString: currentTenant,
    },

    validate: (values) => {
      if (activeTab === 0) {
        return {};
      }

      if (activeTab === 1) {
        return {};
      }

      return {};
    },
  });

  const connectToSystem = useCallback(
    async (stage: string, code: string, currentTenant: string) => {
      const { status } = await getTokens(stage, code, currentTenant);

      setActiveTab(1);
      setConnectingSystem(false);
    },
    [code, stage, currentTenant]
  );

  const submitNewIntegration = useCallback(
    async (form: any) => {
      setCreatingIntegration(true);

      const submitObject = structuredClone(form.values);
      submitObject.tenantAccessString = currentTenant;
      submitObject.dataObject = JSON.stringify(submitObject.dataObject);
      submitObject.active = "Inactive";

      const response = await API.graphql({
        query: createIntegration,
        variables: { input: submitObject },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      //@ts-ignore
      const newIntegration = response.data.createIntegration;

      const newDataObject = structuredClone(dataObject);

      newDataObject.integrations = [
        newIntegration,
        ...newDataObject.integrations,
      ];

      setDataObject(newDataObject);

      setCreatedId(newIntegration.id);
      setActiveTab(2);
      setCreatingIntegration(false);
    },
    [dataObject, setDataObject]
  );

  useEffect(() => {
    if (
      initialLoad.current &&
      stage &&
      ["bigin", "microsoft", "monday", "hubspot", "tsp", "excel"].includes(
        stage
      ) &&
      code &&
      currentTenant
    ) {
      initialLoad.current = false;
      setConnectingSystem(true);
      form.setValues({ system: stage });

      // if (!["tsp"].includes(stage)) {
      connectToSystem(stage, code, currentTenant);
      // } else {
      //   setActiveTab(1);
      //   setConnectingSystem(false);
      // }
    }
  }, [currentTenant]);

  return (
    <>
      <Stepper active={activeTab} breakpoint="sm">
        <Stepper.Step
          label="Connect to System"
          loading={connectingSystem}
          description={form.values.system ?? null}
        >
          <SystemQuestion form={form} setActiveTab={setActiveTab} />
        </Stepper.Step>

        <Stepper.Step
          label="Integration Information"
          // description="Personal information"
        >
          {/* <TextInput
            label="System"
            withAsterisk
            mb={"md"}
            value={form.values.system}
            disabled
          /> */}
          <TextInput
            label="Integration Name"
            withAsterisk
            mb={"md"}
            value={form.values.name}
            onChange={(e) => form.setValues({ name: e.target.value })}
          />
          <ModuleQuestion form={form} />
          <SystemSpecificData system={stage} form={form} />
          <Group position="right" mt="xl">
            <Button
              disabled={!(form.values.name && form.values.module)}
              onClick={() => {
                submitNewIntegration(form);
              }}
            >
              Create Integration
            </Button>
          </Group>
        </Stepper.Step>

        {/* <Stepper.Step label="Configuration">Nothing here</Stepper.Step> */}
        <Stepper.Completed>
          <Stack>
            <Alert color={theme.primaryColor}>
              Integration successfully created.
            </Alert>
            <Button
              leftIcon={<IconSettings />}
              onClick={() => {
                navigate(`/integrations/${createdId}/configure`);
              }}
            >
              Configure Integration
            </Button>
          </Stack>
          {/* <Code block mt="xl">
            {JSON.stringify(form.values, null, 2)}
          </Code> */}
        </Stepper.Completed>
      </Stepper>
    </>
  );
}
