import { Box, Paper, SimpleGrid } from "@mantine/core";
import { Fragment } from "react";
import { ComparatorValue } from "../ChartUtilities";

export const BoxWhiskerTooltip = ({
  active,
  payload,
  label,
  formatFunction,
  comparisonFieldString,
  includeTotal = true,
}: any) => {
  if (active && payload && payload.length) {
    const finalLabel =
      typeof label === "number" || !label ? payload[0].payload.name : label;

    const payloadUpdated = payload.map((obj: any) => {
      switch (obj.name) {
        case "Lower Quartile":
          obj.value = obj.payload["Minimum"] + obj.payload["Lower Quartile"];
          break;
        case "Median":
          obj.value =
            obj.payload["Minimum"] +
            obj.payload["Lower Quartile"] +
            obj.payload["Median"];
          break;
        case "Upper Quartile":
          obj.value =
            obj.payload["Minimum"] +
            obj.payload["Lower Quartile"] +
            obj.payload["Median"] +
            obj.payload["Upper Quartile"];
          break;
        case "Maximum":
          obj.value =
            obj.payload["Minimum"] +
            obj.payload["Lower Quartile"] +
            obj.payload["Median"] +
            obj.payload["Upper Quartile"] +
            obj.payload["Maximum"];
          break;
        default:
          break;
      }

      const prevValue =
        obj.payload[`${obj.dataKey}#comparison`] ??
        obj.payload[`${obj.name}#comparison`];
      obj.comparatorValue = obj.value - prevValue;
      return obj;
    });

    const total = payloadUpdated.reduce(
      (total: number, current: any) => (total += current.value),
      0
    );

    const comparisonTotal = payloadUpdated.reduce(
      (total: number, current: any) => (total += current.comparatorValue),
      0
    );

    const includeComparison = comparisonFieldString !== "None";

    return (
      <Paper sx={{ padding: "0.5em", zIndex: 400, fontSize: "0.8em" }}>
        <SimpleGrid cols={includeComparison ? 3 : 2}>
          <Box sx={{ fontWeight: "bold" }}>{finalLabel}</Box>{" "}
          <Box sx={{ fontWeight: "bold" }}></Box>
          {includeComparison && (
            <Box sx={{ fontWeight: "bold" }}>{comparisonFieldString}</Box>
          )}
          {includeTotal && (
            <>
              <Box sx={{ fontWeight: "bold" }}>Total</Box>{" "}
              <Box sx={{ fontWeight: "bold" }}>{formatFunction(total)}</Box>
              {includeComparison && (
                <Box sx={{ fontWeight: "bold" }}>
                  <ComparatorValue
                    comparatorValue={comparisonTotal}
                    percentageChange={false}
                    formatFunction={formatFunction}
                  />
                </Box>
              )}
            </>
          )}
          {payload.length > 1 &&
            payload.map((payload: any) => {
              return (
                <Fragment key={payload.name}>
                  <Box>{payload.name}</Box>{" "}
                  <Box>{formatFunction(payload.value)}</Box>
                  {includeComparison && (
                    <Box>
                      <ComparatorValue
                        comparatorValue={payload.comparatorValue}
                        percentageChange={false}
                        formatFunction={formatFunction}
                      />
                    </Box>
                  )}
                </Fragment>
              );
            })}
        </SimpleGrid>
      </Paper>
    );
  }
  return null;
};
