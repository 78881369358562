import { RingProgress, Text, Center, Group } from "@mantine/core";
import { ArrowUpRight, ArrowDownRight } from "tabler-icons-react";
// import MetricContainer from "../MetricContainer";

interface StatsRingProps {
  label: string;
  stats: string;
  progress: number;
  color: string;
  icon: any;
}

// const icons = {
//   up: ArrowUpRight,
//   down: ArrowDownRight,
// };

export default function StatsRing({
  label,
  stats,
  progress,
  color,
  icon,
}: StatsRingProps) {
  const Icon = icon;
  return (
    <Center>
      <Group>
        <RingProgress
          size={90}
          roundCaps
          thickness={8}
          sections={[{ value: progress, color: color }]}
          label={
            <Center>
              <Icon size={22} />
            </Center>
          }
        />

        <div>
          <Text color="dimmed" size="xs" transform="uppercase" weight={700}>
            {label}
          </Text>
          <Text weight={700} size="xl">
            {stats}
          </Text>
        </div>
      </Group>
    </Center>
  );
}
