import { useContext, useMemo } from "react";
import AppContext from "../context/AppContext";

const filterKeyLookup = {
  mrrs: "programmeMrrsId",
  pls: "programmePlsId",
  costs: "programmeCostsId",
  risks: "programmeRisksId",
  changes: "programmeChangesId",
  people: "programmePeopleId",
  csats: "programmeCsatsId",
  weeklyUpdates: "programmeWeeklyUpdatesId",
  deliverables: "programmeDeliverablesId",
  statements: "programmeStatementsId",
  projects: "programmeProjectsId",
};

export function useCreateAccessString({
  dataKey,
  formValues,
  mode,
}: {
  dataKey: string;
  formValues: any;
  mode: "create" | "edit";
}) {
  const { dataObject, currentTenant } = useContext(AppContext);

  const returnObject = useMemo(() => {
    if (["users"].includes(dataKey)) {
      return {};
    }

    const {
      programmes = [],
      verticals = [],
      accounts = [],
      projects = [],
      projectRoles = [],
      pipelines = [],
      statements = [],
    } = dataObject;

    const returnObject: any = {
      tenantAccessString: currentTenant,
    };

    if (
      [
        "stages",
        "people",
        "annualLeaves",
        "roleTypes",
        "employeeSatisfaction",
        "periods",
        "userTypes",
        "customDatasets",
      ].includes(dataKey)
    ) {
      return returnObject;
    }

    if (dataKey === "tenants") {
      const id = mode === "create" ? crypto.randomUUID() : formValues.id;
      returnObject.tenantAccessString = id;
      returnObject.id = id;
      return returnObject;
    }

    if (dataKey === "verticals") {
      const id = mode === "create" ? crypto.randomUUID() : formValues.id;
      returnObject.id = id;
      returnObject.verticalAccessString = id;
      return returnObject;
    }

    if (dataKey === "accounts") {
      const id = mode === "create" ? crypto.randomUUID() : formValues.id;
      returnObject.id = id;
      // const currentVertical = formValues.verticalAccountsId
      //   ? verticals.filter(
      //       (vertical: any) => vertical.id === formValues.verticalAccountsId
      //     )[0]
      //   : null;
      // const currentVerticalId = currentVertical ? currentVertical.id : "";

      // returnObject.verticalAccessString = currentVerticalId;
      returnObject.accountAccessString = id;
      return returnObject;
    }

    if (dataKey === "programmes") {
      const id = mode === "create" ? crypto.randomUUID() : formValues.id;
      returnObject.id = id;

      const currentAccount = formValues.accountProgrammesId
        ? accounts.filter(
            (account: any) => account.id === formValues.accountProgrammesId
          )[0]
        : null;

      const currentVertical = formValues.verticalProgrammesId
        ? verticals.filter(
            (account: any) => account.id === formValues.verticalProgrammesId
          )[0]
        : null;

      const currentAccountId = currentAccount?.id;
      const currentVerticalId = currentVertical?.id;

      returnObject.verticalAccessString = currentVerticalId;
      returnObject.accountAccessString = currentAccountId;
      returnObject.programmeAccessString = id;

      return returnObject;
    }

    if (dataKey === "risks") {
      if (formValues.verticalRisksId) {
        returnObject.verticalAccessString = formValues.verticalRisksId;
      }

      if (formValues.accountRisksId) {
        returnObject.accountAccessString = formValues.accountRisksId;
      }

      if (formValues.programmeRisksId) {
        const currentProgramme = programmes.find(
          (programme: any) => programme.id === formValues.programmeRisksId
        );

        returnObject.verticalAccessString =
          currentProgramme?.verticalAccessString;
        returnObject.accountAccessString =
          currentProgramme?.accountAccessString;
        returnObject.programmeAccessString = formValues.programmeRisksId;
      }

      if (formValues.projectRisksId) {
        const currentProject = projects.find(
          (project: any) => project.id === formValues.projectRisksId
        );
        returnObject.verticalAccessString =
          currentProject?.verticalAccessString;
        returnObject.accountAccessString = currentProject?.accountAccessString;
        returnObject.programmeAccessString =
          currentProject?.programmeAccessString;
      }

      return returnObject;
    }

    if (dataKey === "projectRoles") {
      const project = projects.find(
        (project: any) => project.id === formValues.projectProjectRolesId
      );

      returnObject.verticalAccessString = project?.verticalAccessString;
      returnObject.accountAccessString = project?.accountAccessString;
      returnObject.programmeAccessString = project?.programmeAccessString;
      return returnObject;
    }

    if (dataKey === "tasks") {
      const project = projects.find(
        (project: any) => project.id === formValues.projectTasksId
      );

      returnObject.verticalAccessString = project?.verticalAccessString;
      returnObject.accountAccessString = project?.accountAccessString;
      returnObject.programmeAccessString = project?.programmeAccessString;
      return returnObject;
    }

    if (dataKey === "timesheets") {
      const projectRole = projectRoles.find(
        (projectRole: any) =>
          projectRole.id === formValues.projectRoleTimesheetsId
      );

      returnObject.verticalAccessString = projectRole?.verticalAccessString;
      returnObject.accountAccessString = projectRole?.accountAccessString;
      returnObject.programmeAccessString = projectRole?.programmeAccessString;
      return returnObject;
    }

    if (dataKey === "pipelines") {
      const account = accounts.find(
        (account: any) => account.id === formValues.accountPipelinesId
      );

      returnObject.verticalAccessString = account?.verticalAccessString;
      returnObject.accountAccessString = account?.accountAccessString;
      return returnObject;
    }

    if (dataKey === "statements") {
      if (formValues.projectStatementsId) {
        const project = projects.find(
          (project: any) => project.id === formValues.projectStatementsId
        );

        returnObject.verticalAccessString = project?.verticalAccessString;
        returnObject.accountAccessString = project?.accountAccessString;
        returnObject.programmeAccessString = project?.programmeAccessString;
        return returnObject;
      } else {
        const pipeline = pipelines.filter(
          (pipeline: any) => pipeline.id === formValues.pipelineSowRecordsId
        )[0];

        returnObject.verticalAccessString = pipeline?.verticalAccessString;
        returnObject.accountAccessString = pipeline?.accountAccessString;
        return returnObject;
      }
    }

    if (dataKey === "deliverables") {
      const statement = statements.find(
        (statement: any) => statement.id === formValues.statementDeliverablesId
      );

      returnObject.verticalAccessString = statement?.verticalAccessString;
      returnObject.accountAccessString = statement?.accountAccessString;
      returnObject.programmeAccessString = statement?.programmeAccessString;
      return returnObject;
    }

    if (dataKey === "invoices") {
      const statement = statements.find(
        (statement: any) => statement.id === formValues.statementInvoicesId
      );

      returnObject.verticalAccessString = statement?.verticalAccessString;
      returnObject.accountAccessString = statement?.accountAccessString;
      returnObject.programmeAccessString = statement?.programmeAccessString;
      return returnObject;
    }

    //@ts-ignore
    const selectedProgramme = formValues[filterKeyLookup[dataKey]]
      ? programmes.find(
          (programme: any) =>
            //@ts-ignore
            programme.id === formValues[filterKeyLookup[dataKey]]
        )
      : null;

    const currentProgrammeId = selectedProgramme?.id;
    const currentAccountId = selectedProgramme?.account.id;
    const currentVerticalId = selectedProgramme?.vertical.id;

    returnObject.verticalAccessString = currentVerticalId;
    returnObject.accountAccessString = currentAccountId;
    returnObject.programmeAccessString = currentProgrammeId;

    return returnObject;
  }, [currentTenant, dataKey, dataObject, formValues, mode]);

  return returnObject;
}
