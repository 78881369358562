import { API } from "aws-amplify";
import { integrations } from "../../../../graphql/queries";

export async function getHubSpotDeals({
  filterObject = {},
  infoOnly = false,
  currentTenant,
}: {
  filterObject?: any;
  infoOnly?: boolean;
  currentTenant: string;
}) {
  const response = await API.graphql({
    query: integrations,
    authMode: "AMAZON_COGNITO_USER_POOLS",
    variables: {
      input: JSON.stringify({
        system: "hubspot",
        operation: "getDeals",
        informationObject: {
          filterObject,
          infoOnly,
          currentTenant,
          system: "hubspot",
        },
      }),
    },
  });

  //@ts-ignore
  const parsedData = JSON.parse(response.data.integrations);
  const parsedBody = JSON.parse(parsedData.body);

  return parsedBody;
}

export async function getHubSpotCompanies({
  filterObject = {},
  infoOnly = false,
  currentTenant,
}: {
  filterObject?: any;
  infoOnly?: boolean;
  currentTenant: string;
}) {
  const response = await API.graphql({
    query: integrations,
    authMode: "AMAZON_COGNITO_USER_POOLS",
    variables: {
      input: JSON.stringify({
        system: "hubspot",
        operation: "getCompanies",
        informationObject: {
          filterObject,
          infoOnly,
          currentTenant,
          system: "hubspot",
        },
      }),
    },
  });

  //@ts-ignore
  const parsedData = JSON.parse(response.data.integrations);
  const parsedBody = JSON.parse(parsedData.body);

  return parsedBody;
}

export async function getHubSpotProducts({
  filterObject = {},
  infoOnly = false,
  currentTenant,
}: {
  filterObject?: any;
  infoOnly?: boolean;
  currentTenant: string;
}) {
  const response = await API.graphql({
    query: integrations,
    authMode: "AMAZON_COGNITO_USER_POOLS",
    variables: {
      input: JSON.stringify({
        system: "hubspot",
        operation: "getProducts",
        informationObject: {
          filterObject,
          infoOnly,
          currentTenant,
          system: "hubspot",
        },
      }),
    },
  });

  //@ts-ignore
  const parsedData = JSON.parse(response.data.integrations);
  const parsedBody = JSON.parse(parsedData.body);

  return parsedBody;
}

export async function authoriseHubSpot({
  code,
  currentTenant,
  system,
}: {
  code: string;
  currentTenant: string;
  system: string;
}) {
  const response = await API.graphql({
    query: integrations,
    authMode: "AMAZON_COGNITO_USER_POOLS",
    variables: {
      input: JSON.stringify({
        system: "hubspot",
        operation: "authorise",
        informationObject: { code, currentTenant, system },
      }),
    },
  });

  //@ts-ignore
  const parsedResponse = JSON.parse(response.data.integrations);

  if (parsedResponse.body) {
    let parsedBody;
    parsedBody = JSON.parse(parsedResponse.body);

    const { status } = parsedBody;

    return { status };
  } else {
    return { status: "failed" };
  }
}
