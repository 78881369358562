import { useState, useEffect } from "react";
import TableRenderers from "./TableRenderers";
import PivotTable from "./PivotTable";
import { ResponsiveContainer } from "recharts";

export default function Pivot(props: any) {
  const {
    type,
    pivotData,
    data,
    formatFunction = (value: any) => value,
  } = props;

  const initialTableProperties = {
    unusedOrientationCutoff: Infinity,
    hiddenFromAggregators: [],
    hiddenFromDragDrop: [],
    valueFilter: {},
    rendererName: type,
    type,
    pivotData,
    data,
    renderers: TableRenderers,
    formatFunction,
    sorted: true,
  };

  const [tableState, setTableState] = useState({ ...initialTableProperties });

  useEffect(() => {
    if (pivotData && pivotData.props.data.length > 0 && type) {
      setTableState({ ...initialTableProperties });
    }
  }, [type, pivotData]);

  if (!tableState.pivotData || tableState.pivotData.props.data.length === 0)
    return null;
  return (
    <div style={{ maxHeight: "100%", overflow: "scroll" }}>
      <PivotTable onChange={(s: any) => setTableState(s)} {...tableState} />
    </div>
  );
}
