import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import {
  Center,
  createStyles,
  Image,
  Text,
  Group,
  Button,
  Title,
  Stack,
} from "@mantine/core";
import smartTasking from "../layout/black_logo.png";
import { useEffect } from "react";
// import { Storage } from "aws-amplify";
// import logo from "../layout/vmware.png";
import { I18n } from "aws-amplify";
import { translations } from "@aws-amplify/ui-react";
I18n.putVocabularies(translations);
I18n.putVocabularies({
  en: {
    "Confirm TOTP Code": "Please enter your multi-factor authentication code",
  },
});

const useStyles = createStyles((theme) => ({
  wrapper: {
    minHeight: "100vh",
    backgroundSize: "cover",
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    // backgroundImage: `url("https://sjc-horizon.vmware.com/portal/webclient/icons-21414280/bg_image.jpg")`,
    backgroundImage: `url("https://www.smarttasking.com/wp-content/uploads/2019/07/slider-1.jpg")`,
  },
}));

export default function SignIn() {
  const { classes } = useStyles();
  const { route } = useAuthenticator((context) => [context.route]);

  useEffect(() => {
    const auth = document.querySelector("[data-amplify-router]");
    if (auth) {
      // @ts-ignore
      auth.style["background-color"] = "#f8f9fa";
      // @ts-ignore
      auth.style.border = "1px solid #dee2e6";
      // @ts-ignore
      auth.style.color = "black";
      // @ts-ignore
      auth.style["border-radius"] = "16px";
    }
  }, [route]);

  const components = {
    // Header() {
    //   return (
    //     <Center mb={"xs"}>
    //       <div
    //         style={{
    //           backgroundColor: "#f8f9fa",
    //           border: "1px solid #dee2e6",
    //           color: "white",
    //           borderRadius: "16px",
    //         }}
    //       >
    //         <Image
    //           height={150}
    //           fit="contain"
    //           alt={"Logo"}
    //           src={logo}
    //           sx={{ marginTop: "-30px", marginBottom: "-30px" }}
    //         />
    //       </div>
    //     </Center>
    //   );
    // },

    SetupTOTP: {
      Header() {
        return (
          <Center>
            <Stack>
              <Text
                sx={{ fontSize: "2rem" }}
                weight={700}
                color={"blue"}
                mt={"md"}
              >
                Multi-factor Authentication
              </Text>
              <Text>
                We recommend the Microsoft Authenticator or the Google
                Authenticator applications.
              </Text>
            </Stack>
          </Center>
        );
      },
    },

    SignIn: {
      Header() {
        return (
          <Center>
            <Text
              sx={{ fontSize: "2rem" }}
              weight={700}
              color={"blue"}
              mt={"md"}
            >
              PMO smart/sync
            </Text>
          </Center>
        );
      },
      Footer() {
        const { toResetPassword } = useAuthenticator();
        return (
          <>
            <Center mb={"lg"}>
              <Button onClick={toResetPassword} variant="subtle">
                Forgot your password?
              </Button>
            </Center>
            <Group position="center" mb={"md"}>
              <Text color={"dimmed"}>Powered by</Text>
              <Image
                alt={"Smart/tasking"}
                src={smartTasking}
                height={20}
                width={200}
                fit="contain"
              />
            </Group>
          </>
        );
      },
    },

    ResetPassword: {
      Header() {
        return (
          <Center>
            <Text
              sx={{ fontSize: "2rem" }}
              weight={700}
              color={"#e03131"}
              mt={"md"}
            >
              Password Reset
            </Text>
          </Center>
        );
      },
      Footer() {
        const { toSignIn } = useAuthenticator();
        return (
          <>
            {/* <Center mb={"lg"}>
              <Button onClick={toSignIn} variant="subtle">
                Return to sign in
              </Button>
            </Center> */}
            <Group position="center" mb={"md"} mt={"lg"}>
              <Text color={"dimmed"}>Powered by</Text>
              <Image
                alt={"Smart/tasking"}
                src={smartTasking}
                height={20}
                width={200}
                fit="contain"
              />
            </Group>
          </>
        );
      },
    },
  };

  //   const { route } = useAuthenticator();

  const formFields = {
    setupTOTP: {
      QR: {
        totpIssuer: "PMO smart/sync",
        // totpUsername: user?.username,
      },
    },
    confirmSignIn: {
      confirmation_code: {
        type: "text",
      },
    },
  };

  return (
    <div className={classes.wrapper}>
      <Authenticator
        hideSignUp={true}
        components={components}
        formFields={formFields}
      />
    </div>
  );
}
