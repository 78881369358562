import React, { useState, useContext, useMemo } from "react";
import {
  Group,
  Box,
  Collapse,
  ThemeIcon,
  UnstyledButton,
  createStyles,
  Menu,
  Tooltip,
  ScrollArea,
} from "@mantine/core";
import { Link } from "react-router-dom";
import {
  Icon as TablerIcon,
  ChevronLeft,
  ChevronRight,
} from "tabler-icons-react";
import { useLocation } from "react-router-dom";
import AppContext from "../../context/AppContext";
import { useNavigate } from "react-router-dom";

const useStyles = createStyles((theme) => ({
  control: {
    fontWeight: 500,
    display: "block",
    width: "100%",
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,
    color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.black,
    fontSize: theme.fontSizes.sm,

    // "&:hover": {
    //   backgroundColor:
    //     theme.colorScheme === "dark"
    //       ? theme.colors.dark[7]
    //       : theme.colors.gray[0],
    //   color: theme.colorScheme === "dark" ? theme.white : theme.black,
    // },
  },

  linkExpanded: {
    fontWeight: 500,
    display: "block",
    textDecoration: "none",
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,
    paddingLeft: 31,
    marginLeft: 30,
    fontSize: theme.fontSizes.sm,
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[7],
    borderLeft: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,

    "&:hover": {
      color: theme.colorScheme === "dark" ? theme.white : theme.black,
    },
  },

  link: {
    // fontWeight: 500,
    display: "block",
    textDecoration: "none",
    // padding: `${theme.spacing.xs}`,
    // paddingLeft: 31,
    // marginLeft: 30,
    // fontSize: theme.fontSizes.sm,
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[7],
    // borderLeft: `1px solid ${
    //   theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    // }`,

    "&:hover": {
      color: theme.colorScheme === "dark" ? theme.white : theme.black,
    },
  },

  dropdownlink: {
    color:
      theme.colorScheme === "dark"
        ? theme.colors[theme.primaryColor][5]
        : theme.colors[theme.primaryColor][9],
  },

  iconlink: {
    fontWeight: 500,
    display: "block",
    textDecoration: "none",
    fontSize: theme.fontSizes.sm,
    color:
      theme.colorScheme === "dark"
        ? theme.colors[theme.primaryColor][5]
        : theme.colors[theme.primaryColor][9],
    "&:hover": {
      color: theme.colorScheme === "dark" ? theme.white : theme.black,
    },
  },

  chevron: {
    transition: "transform 200ms ease",
    color:
      theme.colorScheme === "dark"
        ? theme.colors[theme.primaryColor][5]
        : theme.colors[theme.primaryColor][9],
  },
}));

interface LinksGroupProps {
  icon: TablerIcon;
  label: string;

  initiallyOpened?: boolean;
  links?: {
    label: string;
    link: string;
    groupsWhoCanView?: string[];
    viewTypesWhoCanView?: string[];
    module?: string;
  }[];
  link?: string;
  groupsWhoCanView?: string[];
  onClick?: any;
  className?: string;
  navExpanded: boolean;
  linkFilterFunction: any;
}

export function LinksGroup({
  icon: Icon,
  label,
  initiallyOpened,
  links,
  link,
  onClick,
  className,
  navExpanded,
  linkFilterFunction,
}: LinksGroupProps) {
  const { classes, theme, cx } = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const hasLinks = Array.isArray(links);
  const [opened, setOpened] = useState(initiallyOpened || false);
  const [menuOpened, setMenuOpened] = useState(false);
  const ChevronIcon = theme.dir === "ltr" ? ChevronRight : ChevronLeft;
  const {
    systemPreferenceObject: preferenceObject,
    viewType,
    groups,
  } = useContext(AppContext);
  const { modules = {} } = preferenceObject ? preferenceObject : {};

  const selectedColor =
    theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3];

  const items = (hasLinks ? links : []).map((link) => {
    if (
      link.viewTypesWhoCanView?.includes(viewType) &&
      (link.groupsWhoCanView?.includes("All") ||
        link.groupsWhoCanView?.some((group) => groups.includes(group)))
    ) {
      const selected = link ? location.pathname.startsWith(link.link) : false;

      if (link?.module && modules[link.module] && !modules[link.module]?.active)
        return null;
      if (link?.label && modules[link.label] && !modules[link.label]?.active)
        return null;
      return (
        <Link
          to={link.link}
          className={navExpanded ? classes.linkExpanded : classes.link}
          key={link.label}
          style={{
            backgroundColor: selected ? selectedColor : "transparent",
          }}
        >
          {link.label}
        </Link>
      );
    } else {
      return null;
    }
  });

  const selected = link ? location.pathname.startsWith(link) : false;

  if (navExpanded)
    return (
      <>
        <UnstyledButton
          onClick={onClick ? onClick : () => setOpened((o) => !o)}
          className={cx(classes.control, className)}
          sx={{ backgroundColor: selected ? selectedColor : "transparent" }}
        >
          <Group position="apart" spacing={0}>
            {link ? (
              <Link to={link} className={classes.iconlink}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <ThemeIcon variant="light" size={30}>
                    <Icon size={18} />
                  </ThemeIcon>
                  <Box ml="md">{label}</Box>
                </Box>
              </Link>
            ) : (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <ThemeIcon
                  variant="light"
                  size={30}
                  style={
                    {
                      // color: "#00ff00",
                      // backgroundColor: "rgb(140 255 193 / 20%)",
                    }
                  }
                >
                  <Icon size={18} />
                </ThemeIcon>
                <Box ml="md" className={classes.dropdownlink}>
                  {label}
                </Box>
              </Box>
            )}

            {hasLinks && (
              <ChevronIcon
                className={classes.chevron}
                size={14}
                style={{
                  transform: opened
                    ? `rotate(${theme.dir === "rtl" ? -90 : 90}deg)`
                    : "none",
                }}
              />
            )}
          </Group>
        </UnstyledButton>
        {hasLinks ? <Collapse in={opened}>{items}</Collapse> : null}
      </>
    );

  const menuSelected = links?.some((link: any) =>
    location.pathname.startsWith(link.link)
  );

  return (
    <>
      {link ? (
        <Tooltip label={label} position="right" zIndex={9000}>
          <UnstyledButton
            className={cx(classes.control, className)}
            // sx={{ backgroundColor: selected ? selectedColor : "transparent" }}
          >
            <Link to={link} className={classes.iconlink}>
              {/* <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            > */}
              <ThemeIcon variant={selected ? "filled" : "light"} size={30}>
                <Icon size={18} />
              </ThemeIcon>
              {/* </Box> */}
            </Link>
          </UnstyledButton>
        </Tooltip>
      ) : (
        <Menu
          shadow="md"
          width={200}
          position={"right-start"}
          closeOnItemClick={true}
          opened={menuOpened}
          onChange={setMenuOpened}
        >
          <Menu.Target>
            <Tooltip
              label={label}
              position="right"
              disabled={menuOpened}
              zIndex={900}
            >
              <UnstyledButton
                className={cx(classes.control, className)}
                // sx={{ backgroundColor: selected ? selectedColor : "transparent" }}
                // onClick={() => setMenuOpened(true)}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <ThemeIcon
                    variant={menuSelected ? "filled" : "light"}
                    size={30}
                  >
                    <Icon size={18} />
                  </ThemeIcon>
                </Box>
              </UnstyledButton>
            </Tooltip>
          </Menu.Target>

          <Menu.Dropdown>
            <ScrollArea.Autosize mah={400} offsetScrollbars>
              {links
                ?.filter(linkFilterFunction)
                .filter(
                  (link) =>
                    !(
                      link &&
                      link.module &&
                      modules[link.module] &&
                      !modules[link.module]?.active
                    ) &&
                    !(
                      link &&
                      link.label &&
                      modules[link.label] &&
                      !modules[link.label]?.active
                    )
                )
                .map((link: any) => {
                  return (
                    <Menu.Item
                      key={link.link}
                      onClick={() => {
                        navigate(link.link);
                      }}
                    >
                      {link.label}
                    </Menu.Item>
                  );
                })}
            </ScrollArea.Autosize>
          </Menu.Dropdown>
        </Menu>
      )}
    </>
  );
}
