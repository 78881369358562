import {
  updateSystemPreference,
  updateUserPreference,
} from "../../../graphql/mutations";
import { API } from "aws-amplify";

export const saveSystemPreferenceObject = async (
  preferenceObject: any,
  ownerEmail: string
) => {
  await API.graphql({
    query: updateSystemPreference,
    authMode: "AMAZON_COGNITO_USER_POOLS",
    variables: {
      input: {
        ownerEmail,
        preferenceObject: JSON.stringify(preferenceObject),
      },
    },
  });
};

export const saveUserPreferenceObject = async (
  preferenceObject: any,
  ownerEmail: string
) => {
  await API.graphql({
    query: updateUserPreference,
    authMode: "AMAZON_COGNITO_USER_POOLS",
    variables: {
      input: {
        ownerEmail,
        preferenceObject: JSON.stringify(preferenceObject),
      },
    },
  });
};
