import {
  createStyles,
  Text,
  Card,
  RingProgress,
  Group,
  Image,
  useMantineColorScheme,
  Skeleton,
} from "@mantine/core";
import { useState, useEffect, useMemo } from "react";
import { Storage } from "aws-amplify";
import { nFormatter } from "../../functions/formatFunctions";

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
    cursor: "pointer",
    userSelect: "none",
  },

  selected: {
    boxShadow: `0px 0px 1px 1px ${theme.colors[theme.primaryColor][9]}`,
  },

  label: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 700,
    lineHeight: 1,
  },

  lead: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 700,
    fontSize: "2rem",
    lineHeight: 1,
  },

  inner: {
    display: "flex",

    // [theme.fn.smallerThan("xs")]: {
    //   flexDirection: "column",
    // },
  },

  ring: {
    flex: 1,
    display: "flex",
    justifyContent: "flex-end",
    position: "relative",

    [theme.fn.smallerThan("xs")]: {
      justifyContent: "center",
      marginTop: theme.spacing.md,
    },
  },
}));

interface StatsRingCardProps {
  name: string;
  loading: boolean;
  tenant: string;
  id: string;
  completed: number;
  total: number;
  totalsObject: any;
  setCurrentAccount: any;
  currentAccount: any;
  showRevenue: boolean;
  stats: {
    value: number;
    label: string;
  }[];
}

export function AccountTile({
  name,
  tenant,
  id,
  completed,
  total,
  stats,
  totalsObject,
  loading,
  setCurrentAccount,
  currentAccount,
  showRevenue,
}: StatsRingCardProps) {
  const { classes } = useStyles();
  const mode = useMantineColorScheme();

  const [logo, setLogo] = useState<string | null>(null);
  const [darkLogo, setDarkLogo] = useState<string | null>(null);

  const updateLogo = async (organisation: string | null) => {
    if (organisation) {
      const newLogo = await Storage.get(organisation + "_light");
      const newDarkLogo = await Storage.get(organisation + "_dark");
      setLogo(newLogo);
      setDarkLogo(newDarkLogo);
    }
  };

  useEffect(() => {
    updateLogo(`${tenant}_${id}`);
  }, []);

  const percentagePaid = useMemo(() => {
    if (!totalsObject || totalsObject.total === 0) return "-";
    return `${Math.round((totalsObject.paid * 100) / totalsObject.total)}%`;
  }, [totalsObject]);

  const items = stats.map((stat) => (
    <div key={stat.label}>
      {loading ? (
        <Skeleton height={16} />
      ) : (
        <Text className={classes.label}>{stat.value}</Text>
      )}
      <Text size="xs" color="dimmed">
        {stat.label}
      </Text>
    </div>
  ));

  const greenPercentage = !(!totalsObject || totalsObject.total === 0)
    ? Math.min(Math.round((totalsObject.paid * 100) / totalsObject.total), 100)
    : 0;

  const orangePercentage = !(!totalsObject || totalsObject.total === 0)
    ? Math.max(
        Math.round(
          ((totalsObject.consumed - totalsObject.paid) * 100) /
            totalsObject.total
        ),
        0
      )
    : 0;

  return (
    <Card
      withBorder
      p="xl"
      radius="md"
      className={`${classes.card} ${
        currentAccount === id ? classes.selected : ""
      }`}
      onClick={(e) => {
        e.stopPropagation();
        const newAccount = currentAccount === id ? "All" : id;
        setCurrentAccount(newAccount);
      }}
    >
      <div className={classes.inner}>
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
              minHeight: "60px",
              maxHeight: "60px",
            }}
          >
            <Image
              src={mode.colorScheme === "light" ? logo : darkLogo}
              style={{ maxWidth: "150px" }}
              withPlaceholder
              placeholder={<Text align="left">{name}</Text>}
            />
          </div>
          <div>
            {loading ? (
              <Skeleton height={54} />
            ) : (
              <Text className={classes.lead} mt={30}>
                £{nFormatter(totalsObject ? totalsObject.total : 0, 1)}
              </Text>
            )}
            <Text color="dimmed">Total Value</Text>
          </div>
          <Group mt="lg">{items}</Group>
        </div>

        {showRevenue && (
          <div className={classes.ring}>
            {loading && <Skeleton circle height={150} />}
            {!loading && (
              <RingProgress
                roundCaps
                thickness={10}
                size={150}
                sections={
                  !totalsObject || totalsObject.total === 0
                    ? [] //[{ value: 100, color: "red" }]
                    : [
                        {
                          value: greenPercentage,
                          color: "green", //theme.primaryColor,
                        },
                        {
                          value: orangePercentage,
                          color: "orange",
                        },
                      ]
                }
                label={
                  <div>
                    <Text align="center" className={classes.label}>
                      {percentagePaid}
                    </Text>
                    <Text align="center" color="dimmed">
                      Revenue Claimed
                    </Text>
                  </div>
                }
              />
            )}
          </div>
        )}
      </div>
    </Card>
  );
}
