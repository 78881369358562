import { useEffect } from "react";
import { ResponsiveGrid } from "./ResponsiveGrid";
import { useGridItems } from "./GridItems";

export default function WidgetGrid(props: {
  setCurrentDashboard: any;
  open: any;
  dashboardObject: any;
  currentDashboard: any;
  deleteWidget: any;
  setCurrentWidget: any;
  setCurrentWidgetIndex: any;
  addWidget: any;
  dashboardFilterObject: any;
  setDashboardFilterObject: any;
  globalDashboardFilteredDataObject: any;
  comparisonPeriodOverride: any;
  setLayout: any;
  layout: any;
  editMode: any;
  startDate: any;
  endDate: any;
}) {
  const {
    setCurrentDashboard,
    open,
    dashboardObject,
    currentDashboard,
    deleteWidget,
    setCurrentWidget,
    setCurrentWidgetIndex,
    addWidget,
    dashboardFilterObject,
    setDashboardFilterObject,
    globalDashboardFilteredDataObject,
    comparisonPeriodOverride,
    setLayout,
    layout,
    editMode,
    startDate,
    endDate,
  } = props;

  useEffect(() => {
    setCurrentDashboard(dashboardObject);
  }, [dashboardObject, setCurrentDashboard]);

  const children = useGridItems({
    currentDashboard,
    dashboardFilterObject,
    globalDashboardFilteredDataObject,
    comparisonPeriodOverride,
    editMode,
    setCurrentWidget,
    addWidget,
    setCurrentWidgetIndex,
    open,
    deleteWidget,
    layout,
    setDashboardFilterObject,
    startDate,
    endDate,
  });

  if (!currentDashboard) return null;
  return (
    <>
      <ResponsiveGrid
        currentDashboard={currentDashboard}
        editMode={editMode}
        layout={layout}
        setLayout={setLayout}
      >
        {children}
      </ResponsiveGrid>
    </>
  );
}
