import { ISchema } from "../SchemaTypes";
import common from "./commonSchema";
import {
  listRoleTypePeople,
  listRoleTypeProjectRoles,
} from "../../graphql/queries";
import {
  updateRoleTypePeople,
  deleteRoleTypePeople,
  createRoleTypePeople,
  updateRoleTypeProjectRoles,
  createRoleTypeProjectRoles,
  deleteRoleTypeProjectRoles,
} from "../../graphql/mutations";
import { TenantQuestion } from "../../components/common/TenantQuestion";

const roleTypeSchema: ISchema = {
  tenant: {
    title: "Tenant Name",
    field: "tenant",
    accessorFunction: (value: any) => {
      return value.tenant?.name;
    },
    formOptions: { required: true },
    excludeFromForm: true,
    type: "belongsTo",
  },
  tenantRoleTypesId: {
    title: "Tenant",
    field: "tenantRoleTypesId",
    formOptions: {
      required: false,
      CustomQuestion: (props: any) => (
        <TenantQuestion {...props} fieldName="tenantRoleTypesId" />
      ),
      options: {
        dataSource: "tenants",
        addBlank: true,
        accessorFunction: (dataSource: any) =>
          dataSource?.map((row: any) => {
            return { value: row.id, label: row.name };
          }) ?? [],
      },
      order: 1,
    },
    type: "select",
  },

  name: { title: "Name", field: "name", formOptions: { required: true } },

  people: {
    title: "People",
    field: "people",
    formOptions: {
      required: false,
      parentIdName: "roleTypeID",
      childIdName: "personID",
      listQuery: listRoleTypePeople,
      updateQuery: updateRoleTypePeople,
      createQuery: createRoleTypePeople,
      deleteQuery: deleteRoleTypePeople,
      listKey: "person",
      options: {
        dataSource: "people",
        addBlank: true,
        accessorFunction: (dataSource: any) =>
          dataSource?.map((row: any) => {
            return { value: row.id, label: `${row.firstName} ${row.lastName}` };
          }) ?? [],
      },
    },
    type: "manyToMany",
    accessorFunction: (row: any) => "",
  },

  projectRoles: {
    title: "Project Roles",
    field: "projectRoles",
    excludeFromForm: true,
    formOptions: {
      required: false,
      parentIdName: "roleTypeID",
      childIdName: "projectRoleID",
      listQuery: listRoleTypeProjectRoles,
      updateQuery: updateRoleTypeProjectRoles,
      createQuery: createRoleTypeProjectRoles,
      deleteQuery: deleteRoleTypeProjectRoles,
      listKey: "roleType",
      options: {
        dataSource: "projectRoles",
        addBlank: true,
        accessorFunction: (dataSource: any) =>
          dataSource?.map((row: any) => {
            return { value: row.id, label: row.description };
          }) ?? [],
      },
    },
    type: "manyToMany",
    accessorFunction: (row: any) => "",
  },

  ...common,
};

export default roleTypeSchema;
