import {
  Modal,
  Grid,
  Group,
  Stack,
  Text,
  ActionIcon,
  useMantineTheme,
  Center,
} from "@mantine/core";
import { widgetsObject } from "./widgetsObject";
import CustomPaper from "../common/CustomPaper";
import { ChartBar } from "tabler-icons-react";
import { useEffect, useState } from "react";
import ChartBuilder from "../common/graphs/ChartBuilder/ChartBuilder";

function WidgetTile(props: any) {
  const { name, addWidget, widgetKey, close, defaults, configuration } = props;
  const { w = 6, h = 6 } = defaults;
  const theme = useMantineTheme();
  return (
    <Grid.Col
      sm={3}
      onClick={() => {
        addWidget({
          widget: widgetKey,
          dataGrid: {
            lg: { x: 0, y: 0, w, h },
            md: { x: 0, y: 0, w, h },
            sm: { x: 0, y: 0, w, h },
          },
          configuration: configuration ?? {},
        });
        close();
      }}
      sx={{ cursor: "pointer" }}
    >
      <CustomPaper>
        <Stack spacing={"xl"}>
          <Text align={"center"}>{name}</Text>
          <Center>
            <ActionIcon variant="light" color={theme.primaryColor} size={"xl"}>
              <ChartBar />
            </ActionIcon>
          </Center>
        </Stack>
      </CustomPaper>
    </Grid.Col>
  );
}

export default function AddWidgetModal({
  opened,
  onClose,
  addWidget,
  currentWidget,
  setCurrentWidget,
  currentWidgetIndex,
  setCurrentWidgetIndex,
  updateWidget,
  chartProps,
  setChartProps,
  dataSourceObject,
  globalDashboardFilteredDataObject,
}: any) {
  const [view, setView] = useState("Add Widget");

  useEffect(() => {
    if (currentWidget) {
      setView("Build Your Own");
    }
  }, [currentWidget]);

  return (
    <Modal
      opened={opened}
      onClose={() => {
        onClose();
        setCurrentWidget(null);
        setView("Add Widget");
      }}
      size={"95vw"}
      sx={{ maxHeight: "100vh" }}
      title={view}
      closeOnClickOutside={false}
      lockScroll={true}
      fullScreen={true}
      //   xOffset={0}
      //   yOffset={0}
    >
      {view === "Add Widget" && (
        <Group position="apart">
          <CustomPaper
            onClick={() => setView("Widget Library")}
            sx={{ cursor: "pointer" }}
          >
            Widget Library
          </CustomPaper>
          <CustomPaper
            onClick={() => setView("Build Your Own")}
            sx={{ cursor: "pointer" }}
          >
            Build Your Own
          </CustomPaper>
        </Group>
      )}
      {view === "Build Your Own" && (
        <ChartBuilder
          addWidget={addWidget}
          currentWidget={currentWidget}
          updateWidget={updateWidget}
          currentWidgetIndex={currentWidgetIndex}
          setCurrentWidgetIndex={setCurrentWidgetIndex}
          setCurrentWidget={setCurrentWidget}
          onClose={onClose}
          setView={setView}
          chartProps={chartProps}
          setChartProps={setChartProps}
          dataSourceObject={dataSourceObject}
          globalDashboardFilteredDataObject={globalDashboardFilteredDataObject}
        />
      )}
      {view === "Widget Library" && (
        <Grid>
          {Object.values(widgetsObject).map((widgetObject: any) => {
            return (
              <WidgetTile
                key={widgetObject.widgetKey}
                {...widgetObject}
                addWidget={addWidget}
                close={onClose}
              />
            );
          })}
        </Grid>
      )}
    </Modal>
  );
}
